import {
  Unit_instance104q5opgivhr8 as Unit_instance,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  VOID7hggqo3abtya as VOID,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  protoOf180f3jzyo7rfj as protoOf,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  DurationUnit_MILLISECONDS_getInstance15owevua4zjxe as DurationUnit_MILLISECONDS_getInstance,
  toDuration7gy6v749ektt as toDuration,
  coerceIn10f36k81le1mm as coerceIn,
  copyOfRange3alro60z4hhf8 as copyOfRange,
  coerceAtMost322komnqp70ag as coerceAtMost,
  indexOfj0u699sj4o53 as indexOf,
  copyOfwy6h3t5vzqpl as copyOf,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isInterface3d6p8outrmvmk as isInterface,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  AutoCloseable1l5p57f9lp7kv as AutoCloseable,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  Char__toInt_impl_vasixd1g1ubcha2b2yr as Char__toInt_impl_vasixd,
  toByte4i43936u611k as toByte,
  toLongw1zpgk99d84b as toLong,
  Long2qws0ah9gnpki as Long,
  coerceIn302bduskdb54x as coerceIn_0,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  Paire9pteg33gng7 as Pair,
  until1jbpn0z3f8lbg as until,
  ClosedRangehokgr73im9z3 as ClosedRange,
  contains2c50nlxg7en7o as contains,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  UnsupportedOperationException2tkumpmhredt3 as UnsupportedOperationException,
  createThis2j2avj17cvnv2 as createThis,
  printStackTrace18lnx7a39cni as printStackTrace,
  RuntimeException1r3t0zl97011n as RuntimeException,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  decodeToString1x4faah2liw2p as decodeToString,
  DoubleCompanionObject_instance2yqrcskeqd1tm as DoubleCompanionObject_instance,
  doubleFromBits153kwgwnt8ety as doubleFromBits,
  Companion_instance3fplhgf4ipvld as Companion_instance,
  _Result___init__impl__xyqfz831xktsyjq1qrq as _Result___init__impl__xyqfz8,
  createFailure8paxfkfa5dc7 as createFailure,
  _Result___get_isSuccess__impl__sndoy8jzpgybizhgc6 as _Result___get_isSuccess__impl__sndoy8,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Channel3r72atmcithql as Channel,
  delay1a7xkalnslpld as delay,
  withContext2i47m7ae4v1sd as withContext,
  CoroutineScopefcb5f5dwqcas as CoroutineScope,
  CoroutineScopelux7s7zphw7e as CoroutineScope_0,
  launch1c91vkjzdi9sd as launch,
  Mutex16li1l0asjv17 as Mutex,
} from './kotlinx-coroutines-core.mjs';
import {
  ilog23dbvpk38jf7rc as ilog2,
  nextAlignedTo3ni8b2boh3zjg as nextAlignedTo,
  toIntClampad53d0g5q5oi as toIntClamp,
  signExtend10m51xy6jou1t as signExtend,
} from './korlibs-korlibs-math-core.mjs';
import {
  SimpleBytesDeque3uob1f4dosqej as SimpleBytesDeque,
  SimpleChunkedByteDeque3cr64mogfywgm as SimpleChunkedByteDeque,
  getS83kqgdtkseh4fp as getS8,
  getU83agrw8yj0shar as getU8,
  getS16BE6c2odqehs5au as getS16BE,
  getU16BE2rszf3doox5o as getU16BE,
  getU24LEi3x1z5ajbdbv as getU24LE,
  getU24BE36pml41y2814b as getU24BE,
  getS32LE1pxm9wohin3g8 as getS32LE,
  getS32BE5aphh7sm8f2q as getS32BE,
  getS64BE1b3e93qe1xjxx as getS64BE,
  arraycopy3czrd9re36wzt as arraycopy,
  getS8Array1clq6jd2z5jh9 as getS8Array,
  getS16ArrayBE3bj6gsad0tz3k as getS16ArrayBE,
  getU16ArrayBE1cpolqhg4wxps as getU16ArrayBE,
  getS32ArrayLE2u84ix7qnexrb as getS32ArrayLE,
  getS32ArrayBE30ehj217529qp as getS32ArrayBE,
  getS16LE39jsuv9u9gi4y as getS16LE,
  getU32LE1yo0ft9yyv0wa as getU32LE,
  getU16LE2cizldw8qp53x as getU16LE,
  set16LEde5pevq9ub88 as set16LE,
  set32LE38ba9cwhp6kc as set32LE,
  setArrayLE1gvgooi4u5zru as setArrayLE,
} from './korlibs-korlibs-memory.mjs';
import {
  OutOfBoundsException1k0x58dcca6fx as OutOfBoundsException,
  Endian_LITTLE_ENDIAN_getInstancelbfxpgu7xewo as Endian_LITTLE_ENDIAN_getInstance,
  ByteArrayBuilderyrflhqjz64ju as ByteArrayBuilder,
  EOFExceptiona2wwqnj55hh5 as EOFException,
  unsupported198cz39rr1ztw as unsupported,
  invalidOp1stebxh2m3n07 as invalidOp,
} from './korlibs-korlibs-platform.mjs';
import {
  toString2x583ovdqfjci as toString_0,
  get_UTF82geyfhi1l22xl as get_UTF8,
  toByteArraybbc3ng3wgt1d as toByteArray,
} from './korlibs-korlibs-string.mjs';
import { Mixin3lmkm5hum2vdj as Mixin } from './korlibs-korlibs-datastructure-core.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class AsyncOutputStream {}
function write$default(buffer, offset, len, $completion, $super) {
  offset = offset === VOID ? 0 : offset;
  len = len === VOID ? buffer.length - offset | 0 : len;
  return $super === VOID ? this.c1q(buffer, offset, len, $completion) : $super.c1q.call(this, buffer, offset, len, $completion);
}
class AsyncRingBuffer {
  constructor(bufferSize) {
    bufferSize = bufferSize === VOID ? 1024 : bufferSize;
    this.w1p_1 = bufferSize;
    this.x1p_1 = null;
    this.y1p_1 = Channel(-1);
    this.z1p_1 = Channel(-1);
    this.a1q_1 = new SimpleBytesDeque(ilog2(this.w1p_1) + 1 | 0);
    this.b1q_1 = false;
  }
  c1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l(this, buffer, offset, len, $completion), $completion);
  }
  e1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe(this, buffer, offset, len, $completion), $completion);
  }
  f1q($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_close__nh2uv0(this, $completion), $completion);
  }
  toString() {
    return 'AsyncByteArrayDeque(' + this.x1p_1 + ')';
  }
}
class Companion {
  constructor() {
    this.g1q_1 = 8388608;
  }
}
class AsyncRingBufferChunked {
  constructor(maxSize) {
    maxSize = maxSize === VOID ? 8388608 : maxSize;
    this.h1q_1 = maxSize;
    this.i1q_1 = null;
    this.j1q_1 = new SimpleChunkedByteDeque();
    this.k1q_1 = false;
  }
  c1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l_0(this, buffer, offset, len, $completion), $completion);
  }
  e1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe_0(this, buffer, offset, len, $completion), $completion);
  }
  f1q($completion) {
    this.k1q_1 = true;
  }
  toString() {
    return 'AsyncByteArrayDequeV2(' + this.i1q_1 + ')';
  }
}
class FastByteArrayInputStream {
  constructor(ba, offset, start, end) {
    offset = offset === VOID ? 0 : offset;
    start = start === VOID ? 0 : start;
    end = end === VOID ? ba.length : end;
    this.l1q_1 = ba;
    this.m1q_1 = start;
    this.n1q_1 = end;
    this.o1q_1 = offset + this.m1q_1 | 0;
  }
  p1q(value) {
    this.o1q_1 = this.m1q_1 + value | 0;
  }
  q1q() {
    return this.o1q_1 - this.m1q_1 | 0;
  }
  a() {
    return this.n1q_1 - this.m1q_1 | 0;
  }
  oy() {
    return this.n1q_1 - this.o1q_1 | 0;
  }
  uy() {
    return this.oy() > 0;
  }
  r1q(_this__u8e3s4) {
    return coerceIn(_this__u8e3s4, this.m1q_1, this.n1q_1);
  }
  s1q(size) {
    var out = this.t1q(this.q1q(), size);
    this.o1q_1 = this.o1q_1 + size | 0;
    return out;
  }
  u1q(offset) {
    return new FastByteArrayInputStream(this.l1q_1, 0, this.r1q(this.m1q_1 + offset | 0), this.n1q_1);
  }
  v1q(offset, $super) {
    offset = offset === VOID ? 0 : offset;
    return $super === VOID ? this.u1q(offset) : $super.u1q.call(this, offset);
  }
  j13() {
    return new FastByteArrayInputStream(this.l1q_1, this.q1q(), this.m1q_1, this.n1q_1);
  }
  t1q(offset, len) {
    return new FastByteArrayInputStream(this.l1q_1, 0, this.r1q(this.m1q_1 + offset | 0), this.r1q((this.m1q_1 + offset | 0) + len | 0));
  }
  zy(count) {
    this.o1q_1 = coerceIn(this.o1q_1 + count | 0, this.m1q_1, this.n1q_1);
  }
  w1q(count) {
    return this.zy(-count | 0);
  }
  x1q(len) {
    var out = copyOfRange(this.l1q_1, this.o1q_1, this.o1q_1 + len | 0);
    this.o1q_1 = this.o1q_1 + len | 0;
    return out;
  }
  y1q() {
    return getS8(this.l1q_1, offset(this, 1));
  }
  z1q() {
    return getU8(this.l1q_1, offset(this, 1));
  }
  a1r() {
    return getS16BE(this.l1q_1, offset(this, 2));
  }
  b1r() {
    return getU16BE(this.l1q_1, offset(this, 2));
  }
  c1r() {
    return getU24LE(this.l1q_1, offset(this, 3));
  }
  d1r() {
    return getU24BE(this.l1q_1, offset(this, 3));
  }
  e1r() {
    return getS32LE(this.l1q_1, offset(this, 4));
  }
  f1r() {
    return getS32BE(this.l1q_1, offset(this, 4));
  }
  g1r() {
    return getS64BE(this.l1q_1, offset(this, 8));
  }
  k12(data, offset, count) {
    var readCount = coerceAtMost(count, this.oy());
    arraycopy(this.l1q_1, this.o1q_1, data, offset, readCount);
    this.o1q_1 = this.o1q_1 + count | 0;
    return readCount;
  }
  h1r(count) {
    return getS8Array(this.l1q_1, offset(this, count), count);
  }
  i1r(count) {
    return getS16ArrayBE(this.l1q_1, offset(this, imul(count, 2)), count);
  }
  j1r(count) {
    return getU16ArrayBE(this.l1q_1, offset(this, imul(count, 2)), count);
  }
  k1r(count) {
    return getS32ArrayLE(this.l1q_1, offset(this, imul(count, 4)), count);
  }
  l1r(count) {
    return getS32ArrayBE(this.l1q_1, offset(this, imul(count, 4)), count);
  }
  m1r(offset, length, charset) {
    var start = this.m1q_1 + offset | 0;
    var end = start + length | 0;
    return toString_0(this.l1q_1, charset, start, end);
  }
  n1r(len, charset) {
    var res = this.h1r(len);
    var index = indexOf(res, 0);
    return toString_0(copyOf(res, index < 0 ? len : index), charset);
  }
  o1r(len, charset, $super) {
    charset = charset === VOID ? get_UTF8() : charset;
    return $super === VOID ? this.n1r(len, charset) : $super.n1r.call(this, len, charset);
  }
  p1r() {
    return this.l1q_1;
  }
}
class SyncAsyncStreamBase$read$slambda {
  constructor(this$0, $position, $buffer, $offset, $len) {
    this.j1s_1 = this$0;
    this.k1s_1 = $position;
    this.l1s_1 = $buffer;
    this.m1s_1 = $offset;
    this.n1s_1 = $len;
  }
  o1s($this$withContext, $completion) {
    // Inline function 'korlibs.io.stream.SyncAsyncStreamBase.read.<anonymous>' call
    return this.j1s_1.y1r_1.h1s(this.k1s_1, this.l1s_1, this.m1s_1, this.n1s_1);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class SyncAsyncStreamBase$write$slambda {
  constructor(this$0, $position, $buffer, $offset, $len) {
    this.p1s_1 = this$0;
    this.q1s_1 = $position;
    this.r1s_1 = $buffer;
    this.s1s_1 = $offset;
    this.t1s_1 = $len;
  }
  o1s($this$withContext, $completion) {
    this.p1s_1.y1r_1.i1s(this.q1s_1, this.r1s_1, this.s1s_1, this.t1s_1);
    return Unit_instance;
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class SyncAsyncStreamBase$getLength$slambda {
  constructor(this$0) {
    this.u1s_1 = this$0;
  }
  o1s($this$withContext, $completion) {
    // Inline function 'korlibs.io.stream.SyncAsyncStreamBase.getLength.<anonymous>' call
    return this.u1s_1.y1r_1.a();
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class AsyncGetLengthStream {}
function hasLength($completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_hasLength__5f5ox8(this, $completion), $completion);
}
function getLength($completion) {
  throw UnsupportedOperationException.u8();
}
class AsyncStreamBase {
  static v1s($box) {
    return createThis(this, $box);
  }
  w1s(position, buffer, offset, len, $completion) {
    throw UnsupportedOperationException.u8();
  }
  x1s(position, buffer, offset, len, $completion) {
    throw UnsupportedOperationException.u8();
  }
  y1s($completion) {
    throw UnsupportedOperationException.u8();
  }
  f1q($completion) {
    return Unit_instance;
  }
}
class SyncAsyncStreamBase extends AsyncStreamBase {
  static e1s(sync, dispatcher) {
    dispatcher = dispatcher === VOID ? null : dispatcher;
    var $this = this.v1s();
    $this.y1r_1 = sync;
    $this.z1r_1 = dispatcher;
    return $this;
  }
  w1s(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe_1(this, position, buffer, offset, len, $completion), $completion);
  }
  x1s(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l_1(this, position, buffer, offset, len, $completion), $completion);
  }
  y1s($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_getLength__lvva94(this, $completion), $completion);
  }
}
class toAsync$o$write$slambda {
  constructor(this$0, $buffer, $offset, $len) {
    this.c1t_1 = this$0;
    this.d1t_1 = $buffer;
    this.e1t_1 = $offset;
    this.f1t_1 = $len;
  }
  o1s($this$withContext, $completion) {
    this.c1t_1.a1t_1.p12(this.d1t_1, this.e1t_1, this.f1t_1);
    return Unit_instance;
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class toAsync$o$close$slambda {
  constructor(this$0) {
    this.g1t_1 = this$0;
  }
  o1s($this$launch, $completion) {
    // Inline function 'korlibs.io.stream.<no name provided>.close.<anonymous>' call
    var tmp = this.g1t_1.a1t_1;
    var tmp7_safe_receiver = isInterface(tmp, AutoCloseable) ? tmp : null;
    if (tmp7_safe_receiver == null)
      null;
    else {
      tmp7_safe_receiver.u6();
    }
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class toAsync$1 {
  constructor($this_toAsync, $dispatcher) {
    this.b1t_1 = $dispatcher;
    this.a1t_1 = $this_toAsync;
  }
  c1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l_2(this, buffer, offset, len, $completion), $completion);
  }
  f1q($completion) {
    var dispatcher = this.b1t_1;
    if (!(dispatcher == null)) {
      var tmp = CoroutineScope_0(dispatcher);
      launch(tmp, VOID, VOID, toAsync$o$close$slambda_1(this));
    } else {
      // Inline function 'korlibs.io.stream.<no name provided>.close.<anonymous>' call
      var tmp_0 = this.a1t_1;
      var tmp7_safe_receiver = isInterface(tmp_0, AutoCloseable) ? tmp_0 : null;
      if (tmp7_safe_receiver == null)
        null;
      else {
        tmp7_safe_receiver.u6();
      }
    }
    return Unit_instance;
  }
}
class toAsync$o$read$slambda {
  constructor(this$0, $buffer, $offset, $len) {
    this.j1t_1 = this$0;
    this.k1t_1 = $buffer;
    this.l1t_1 = $offset;
    this.m1t_1 = $len;
  }
  o1s($this$withContext, $completion) {
    // Inline function 'korlibs.io.stream.<no name provided>.read.<anonymous>' call
    return this.j1t_1.h1t_1.k12(this.k1t_1, this.l1t_1, this.m1t_1);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class toAsync$o$close$slambda_0 {
  constructor(this$0) {
    this.n1t_1 = this$0;
  }
  o1s($this$launch, $completion) {
    // Inline function 'korlibs.io.stream.<no name provided>.close.<anonymous>' call
    var tmp = this.n1t_1.h1t_1;
    var tmp4_safe_receiver = isInterface(tmp, AutoCloseable) ? tmp : null;
    if (tmp4_safe_receiver == null)
      null;
    else {
      tmp4_safe_receiver.u6();
    }
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class toAsync$o$getPosition$slambda {
  constructor(this$0) {
    this.o1t_1 = this$0;
  }
  o1s($this$withContext, $completion) {
    // Inline function 'korlibs.io.stream.<no name provided>.getPosition.<anonymous>' call
    var tmp = this.o1t_1.h1t_1;
    var tmp5_safe_receiver = isInterface(tmp, SyncPositionStream) ? tmp : null;
    return tmp5_safe_receiver == null ? null : tmp5_safe_receiver.q1q();
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class toAsync$o$getLength$slambda {
  constructor(this$0) {
    this.p1t_1 = this$0;
  }
  o1s($this$withContext, $completion) {
    // Inline function 'korlibs.io.stream.<no name provided>.getLength.<anonymous>' call
    var tmp = this.p1t_1.h1t_1;
    var tmp6_safe_receiver = isInterface(tmp, SyncLengthStream) ? tmp : null;
    return tmp6_safe_receiver == null ? null : tmp6_safe_receiver.a();
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class AsyncGetPositionStream {}
function getPosition($completion) {
  throw UnsupportedOperationException.u8();
}
class toAsync$2 {
  constructor($this_toAsync, $dispatcher) {
    this.i1t_1 = $dispatcher;
    this.h1t_1 = $this_toAsync;
  }
  e1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe_2(this, buffer, offset, len, $completion), $completion);
  }
  f1q($completion) {
    var dispatcher = this.i1t_1;
    if (!(dispatcher == null)) {
      var tmp = CoroutineScope_0(dispatcher);
      launch(tmp, VOID, VOID, toAsync$o$close$slambda_2(this));
    } else {
      // Inline function 'korlibs.io.stream.<no name provided>.close.<anonymous>' call
      var tmp_0 = this.h1t_1;
      var tmp4_safe_receiver = isInterface(tmp_0, AutoCloseable) ? tmp_0 : null;
      if (tmp4_safe_receiver == null)
        null;
      else {
        tmp4_safe_receiver.u6();
      }
    }
    return Unit_instance;
  }
  q1t($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_getPosition__nbsewl(this, $completion), $completion);
  }
  y1s($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_getLength__lvva94_0(this, $completion), $completion);
  }
}
class SliceAsyncStreamBase extends AsyncStreamBase {
  static c1u(base, baseStart, baseEnd, closeParent) {
    var $this = this.v1s();
    $this.x1t_1 = base;
    $this.y1t_1 = baseStart;
    $this.z1t_1 = baseEnd;
    $this.a1u_1 = closeParent;
    $this.b1u_1 = $this.z1t_1.h2($this.y1t_1);
    return $this;
  }
  w1s(position, buffer, offset, len, $completion) {
    var _destruct__k2r9zo = clampPositionLen(this, position, len);
    var targetStartPosition = _destruct__k2r9zo.km();
    var targetLen = _destruct__k2r9zo.lm();
    return this.x1t_1.w1s(targetStartPosition, buffer, offset, targetLen, $completion);
  }
  x1s(position, buffer, offset, len, $completion) {
    var _destruct__k2r9zo = clampPositionLen(this, position, len);
    var targetStartPosition = _destruct__k2r9zo.km();
    var targetLen = _destruct__k2r9zo.lm();
    return this.x1t_1.x1s(targetStartPosition, buffer, offset, targetLen, $completion);
  }
  y1s($completion) {
    return this.b1u_1;
  }
  f1q($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_close__nh2uv0_0(this, $completion), $completion);
  }
  toString() {
    return 'SliceAsyncStreamBase(' + toString(this.x1t_1) + ', ' + this.y1t_1.toString() + ', ' + this.z1t_1.toString() + ')';
  }
}
class BufferedStreamBase extends AsyncStreamBase {
  static k1u(base, blockSize, blocksToRead) {
    blockSize = blockSize === VOID ? 2048 : blockSize;
    blocksToRead = blocksToRead === VOID ? 16 : blocksToRead;
    var $this = this.v1s();
    $this.e1u_1 = base;
    $this.f1u_1 = blockSize;
    $this.g1u_1 = blocksToRead;
    $this.h1u_1 = imul($this.f1u_1, $this.g1u_1);
    var tmp = $this;
    // Inline function 'kotlin.byteArrayOf' call
    tmp.i1u_1 = new Int8Array([]);
    $this.j1u_1 = new Long(-1, -1);
    return $this;
  }
  w1s(position, buffer, offset, len, $completion) {
    return this.l1u(position, buffer, offset, len, $completion);
  }
  toString() {
    return 'Buffered[' + this.g1u_1 + '*' + this.f1u_1 + ']:' + toString(this.e1u_1);
  }
  l1u(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator__read__lo0dlr(this, position, buffer, offset, len, $completion), $completion);
  }
  x1s(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l_3(this, position, buffer, offset, len, $completion), $completion);
  }
  y1s($completion) {
    return this.e1u_1.y1s($completion);
  }
  f1q($completion) {
    return this.e1u_1.f1q($completion);
  }
}
class MemoryAsyncStreamBase extends AsyncStreamBase {
  static f1s(data) {
    var $this = this.v1s();
    $this.a1s_1 = data;
    return $this;
  }
  static m1u(initialCapacity) {
    initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
    return this.f1s(ByteArrayBuilder.mv(initialCapacity));
  }
  n1u() {
    return this.a1s_1.j1();
  }
  y1s($completion) {
    return toLong(this.n1u());
  }
  o1u(position) {
    if (position.g2(new Long(0, 0)) < 0)
      throw IllegalArgumentException.l('Invalid position ' + position.toString());
  }
  w1s(position, buffer, offset, len, $completion) {
    this.o1u(position);
    // Inline function 'kotlin.ranges.contains' call
    var this_0 = until(0, this.n1u());
    if (!contains(isInterface(this_0, ClosedRange) ? this_0 : THROW_CCE(), position))
      return 0;
    // Inline function 'kotlin.math.min' call
    var a = toLong(this.n1u());
    // Inline function 'kotlin.Long.plus' call
    var b = position.c4(toLong(len));
    var end = a.g2(b) <= 0 ? a : b;
    // Inline function 'kotlin.math.max' call
    var a_0 = end.h2(position).q2();
    var actualLen = Math.max(a_0, 0);
    arraycopy(this.a1s_1.iv_1, position.q2(), buffer, offset, actualLen);
    return actualLen;
  }
  x1s(position, buffer, offset, len, $completion) {
    this.o1u(position);
    var tmp = this.a1s_1;
    // Inline function 'kotlin.math.max' call
    var a = this.a1s_1.j1();
    // Inline function 'kotlin.Long.plus' call
    var b = position.c4(toLong(len)).q2();
    var tmp$ret$1 = Math.max(a, b);
    tmp.nv(tmp$ret$1);
    arraycopy(buffer, offset, this.a1s_1.iv_1, position.q2(), len);
  }
  f1q($completion) {
    return Unit_instance;
  }
  toString() {
    return 'MemoryAsyncStreamBase(' + this.a1s_1.j1() + ')';
  }
}
class AsyncPositionLengthStream {}
function hasAvailable($completion) {
  return this.z1s($completion);
}
function getAvailable($completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_getAvailable__v3o1wr(this, $completion), $completion);
}
function eof($completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_eof__cbg9jc(this, $completion), $completion);
}
class AsyncStream {
  constructor(base, position, queue) {
    position = position === VOID ? new Long(0, 0) : position;
    queue = queue === VOID ? false : queue;
    this.r1t_1 = new Mixin();
    this.s1t_1 = base;
    this.t1t_1 = position;
    this.u1t_1 = queue;
    this.v1t_1 = Mutex();
    this.w1t_1 = Mutex();
  }
  n13(_set____db54di) {
    this.r1t_1.n13(_set____db54di);
  }
  o13() {
    return this.r1t_1.o13();
  }
  toString() {
    return 'AsyncStream(' + toString(this.s1t_1) + ', position=' + this.t1t_1.toString() + ')';
  }
  e1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe_3(this, buffer, offset, len, $completion), $completion);
  }
  c1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l_4(this, buffer, offset, len, $completion), $completion);
  }
  z1s($completion) {
    return this.s1t_1.z1s($completion);
  }
  d1u(value, $completion) {
    this.t1t_1 = value;
  }
  q1t($completion) {
    return this.t1t_1;
  }
  y1s($completion) {
    return this.s1t_1.y1s($completion);
  }
  f1q($completion) {
    return this.s1t_1.f1q($completion);
  }
  r1u() {
    return new AsyncStream(this.s1t_1, this.t1t_1);
  }
}
class asyncStreamWriter$1$ensureJob$2$1 {
  constructor(this$0) {
    this.a1v_1 = this$0;
  }
  c1q(buffer, offset, len, $completion) {
    return this.a1v_1.v1u_1.c1q(buffer, offset, len, $completion);
  }
  f1q($completion) {
    return this.a1v_1.v1u_1.f1q($completion);
  }
}
class asyncStreamWriter$o$ensureJob$slambda {
  constructor($process, this$0) {
    this.t1u_1 = $process;
    this.u1u_1 = this$0;
  }
  o1s($this$launch, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$launch, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class asyncStreamWriter$1 {
  constructor($lazy, $bufferSize, $name, $process) {
    this.z1u_1 = $process;
    var tmp = this;
    var tmp_0;
    if ($lazy) {
      // Inline function 'kotlin.also' call
      var this_0 = new AsyncRingBuffer($bufferSize);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.stream.<no name provided>.deque.<anonymous>' call
      this_0.x1p_1 = $name;
      tmp_0 = this_0;
    } else {
      // Inline function 'kotlin.also' call
      var this_1 = new AsyncRingBufferChunked($bufferSize);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.stream.<no name provided>.deque.<anonymous>' call
      this_1.i1q_1 = $name;
      tmp_0 = this_1;
    }
    tmp.v1u_1 = tmp_0;
    this.w1u_1 = null;
    this.x1u_1 = null;
    this.y1u_1 = new Int8Array(1);
  }
  e1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe_4(this, buffer, offset, len, $completion), $completion);
  }
  f1q($completion) {
    var tmp14_safe_receiver = this.x1u_1;
    if (tmp14_safe_receiver == null)
      null;
    else {
      tmp14_safe_receiver.l16();
    }
    this.x1u_1 = null;
  }
}
class SyncLengthStream {}
class SyncStreamBase {
  static h1v() {
    var $this = createThis(this);
    $this.g1s_1 = new Int8Array(16);
    return $this;
  }
  j1v() {
    return false;
  }
  h1s(position, buffer, offset, len) {
    throw UnsupportedOperationException.u8();
  }
  i1s(position, buffer, offset, len) {
    throw UnsupportedOperationException.u8();
  }
  i1v(_) {
    throw UnsupportedOperationException.u8();
  }
  a() {
    throw UnsupportedOperationException.u8();
  }
  u6() {
    return Unit_instance;
  }
  k1v(position) {
    return new SyncStream(this, position);
  }
  g1v(position, $super) {
    position = position === VOID ? new Long(0, 0) : position;
    return $super === VOID ? this.k1v(position) : $super.k1v.call(this, position);
  }
  l1v(position) {
    return new SyncStream(this, position);
  }
  m1v(position, $super) {
    position = position === VOID ? new Long(0, 0) : position;
    return $super === VOID ? this.l1v(position) : $super.l1v.call(this, position);
  }
}
class ReadonlySyncStreamBase extends SyncStreamBase {
  static f1v(data, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? data.length - offset | 0 : size;
    var $this = this.h1v();
    $this.c1v_1 = data;
    $this.d1v_1 = offset;
    $this.e1v_1 = size;
    return $this;
  }
  n1u() {
    return this.e1v_1;
  }
  i1v(value) {
    unsupported();
  }
  a() {
    return toLong(this.c1v_1.length);
  }
  o1u(position) {
    if (position.g2(new Long(0, 0)) < 0) {
      invalidOp('Invalid position ' + position.toString());
    }
  }
  h1s(position, buffer, offset, len) {
    this.o1u(position);
    var ipos = position.q2();
    // Inline function 'kotlin.ranges.contains' call
    var this_0 = until(0, this.n1u());
    if (!contains(isInterface(this_0, ClosedRange) ? this_0 : THROW_CCE(), position))
      return 0;
    // Inline function 'kotlin.math.min' call
    var a = this.n1u();
    var b = ipos + len | 0;
    var end = Math.min(a, b);
    // Inline function 'kotlin.math.max' call
    var a_0 = end - ipos | 0;
    var actualLen = Math.max(a_0, 0);
    arraycopy(this.c1v_1, ipos + this.d1v_1 | 0, buffer, offset, actualLen);
    return actualLen;
  }
  u6() {
    return Unit_instance;
  }
  toString() {
    return 'ReadonlySyncStreamBase(' + this.c1v_1.length + ')';
  }
}
class MemorySyncStreamBase extends SyncStreamBase {
  static d1s(data) {
    var $this = this.h1v();
    $this.c1s_1 = data;
    return $this;
  }
  static n1v(initialCapacity) {
    initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
    return this.d1s(ByteArrayBuilder.mv(initialCapacity));
  }
  n1u() {
    return this.c1s_1.j1();
  }
  i1v(value) {
    this.c1s_1.nv(value.q2());
  }
  a() {
    return toLong(this.c1s_1.j1());
  }
  o1u(position) {
    if (position.g2(new Long(0, 0)) < 0)
      throw IllegalArgumentException.l('Invalid position ' + position.toString());
  }
  h1s(position, buffer, offset, len) {
    this.o1u(position);
    var ipos = position.q2();
    // Inline function 'kotlin.ranges.contains' call
    var this_0 = until(0, this.n1u());
    if (!contains(isInterface(this_0, ClosedRange) ? this_0 : THROW_CCE(), position))
      return 0;
    // Inline function 'kotlin.math.min' call
    var a = this.n1u();
    var b = ipos + len | 0;
    var end = Math.min(a, b);
    // Inline function 'kotlin.math.max' call
    var a_0 = end - ipos | 0;
    var actualLen = Math.max(a_0, 0);
    arraycopy(this.c1s_1.iv_1, ipos, buffer, offset, actualLen);
    return actualLen;
  }
  i1s(position, buffer, offset, len) {
    this.o1u(position);
    var tmp = this.c1s_1;
    // Inline function 'kotlin.math.max' call
    var a = this.c1s_1.j1();
    // Inline function 'kotlin.Long.plus' call
    var b = position.c4(toLong(len)).q2();
    var tmp$ret$1 = Math.max(a, b);
    tmp.nv(tmp$ret$1);
    arraycopy(buffer, offset, this.c1s_1.iv_1, position.q2(), len);
  }
  u6() {
    return Unit_instance;
  }
  toString() {
    return 'MemorySyncStreamBase(' + this.c1s_1.j1() + ')';
  }
}
class SyncOutputStream {}
function write(byte) {
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  var it = new Int8Array(1);
  it[0] = toByte(byte);
  this.p12(it, 0, 1);
  return Unit_instance;
}
class SyncInputStream {}
function read() {
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.stream.SyncInputStream.read.<anonymous>' call
  var it = new Int8Array(1);
  return this.k12(it, 0, 1) > 0 ? it[0] & 255 : -1;
}
class SyncPositionStream {}
class SyncStream {
  constructor(base, position) {
    position = position === VOID ? new Long(0, 0) : position;
    this.q1r_1 = new Mixin();
    this.r1r_1 = base;
    this.s1r_1 = this.r1r_1.g1s_1;
    this.t1r_1 = this.r1r_1.j1v();
    this.u1r_1 = position;
    this.v1r_1 = position;
    this.w1r_1 = position;
    this.x1r_1 = new Long(0, 0);
  }
  n13(_set____db54di) {
    this.q1r_1.n13(_set____db54di);
  }
  o13() {
    return this.q1r_1.o13();
  }
  q1v(value) {
    if (this.t1r_1)
      this.u1r_1 = value;
    else {
      this.r1v(value);
    }
  }
  s1v() {
    return this.t1r_1 ? this.u1r_1 : this.q1q();
  }
  t1v(value) {
    if (this.t1r_1)
      this.v1r_1 = value;
    else {
      this.r1v(value);
    }
  }
  u1v() {
    return this.t1r_1 ? this.v1r_1 : this.q1q();
  }
  r1v(value) {
    var tmp;
    if (this.t1r_1) {
      this.q1v(value);
      tmp = Unit_instance;
    } else {
      this.w1r_1 = value;
      tmp = Unit_instance;
    }
    return tmp;
  }
  q1q() {
    return this.t1r_1 ? this.s1v() : this.w1r_1;
  }
  k12(buffer, offset, len) {
    var read = this.r1r_1.h1s(this.s1v(), buffer, offset, len);
    // Inline function 'kotlin.Long.plus' call
    var tmp$ret$0 = this.s1v().c4(toLong(read));
    this.q1v(tmp$ret$0);
    return read;
  }
  zy(count) {
    // Inline function 'kotlin.Long.plus' call
    var tmp$ret$0 = this.s1v().c4(toLong(count));
    this.q1v(tmp$ret$0);
  }
  p1v() {
    var size = this.k12(this.s1r_1, 0, 1);
    if (size <= 0)
      return -1;
    return this.s1r_1[0] & 255;
  }
  p12(buffer, offset, len) {
    this.r1r_1.i1s(this.u1v(), buffer, offset, len);
    // Inline function 'kotlin.Long.plus' call
    var tmp$ret$0 = this.u1v().c4(toLong(len));
    this.t1v(tmp$ret$0);
  }
  o1v(byte) {
    this.s1r_1[0] = toByte(byte);
    this.p12(this.s1r_1, 0, 1);
  }
  i1v(value) {
    this.r1r_1.i1v(value);
  }
  a() {
    return this.r1r_1.a();
  }
  u6() {
    return this.r1r_1.u6();
  }
  j13() {
    return new SyncStream(this.r1r_1, this.q1q());
  }
  i12() {
    return this.a().h2(this.s1v());
  }
  oy() {
    return this.i12();
  }
  toString() {
    return 'SyncStream(' + toString(this.r1r_1) + ', ' + this.q1q().toString() + ')';
  }
}
class SliceSyncStreamBase extends SyncStreamBase {
  static a1w(base, baseStart, baseEnd) {
    var $this = this.h1v();
    $this.w1v_1 = base;
    $this.x1v_1 = baseStart;
    $this.y1v_1 = baseEnd;
    $this.z1v_1 = $this.y1v_1.h2($this.x1v_1);
    return $this;
  }
  j1v() {
    return this.w1v_1.j1v();
  }
  i1v(value) {
    throw UnsupportedOperationException.u8();
  }
  a() {
    return this.z1v_1;
  }
  h1s(position, buffer, offset, len) {
    var _destruct__k2r9zo = clampPositionLen_0(this, position, len);
    var targetStartPosition = _destruct__k2r9zo.km();
    var targetLen = _destruct__k2r9zo.lm();
    return this.w1v_1.h1s(targetStartPosition, buffer, offset, targetLen);
  }
  i1s(position, buffer, offset, len) {
    var _destruct__k2r9zo = clampPositionLen_0(this, position, len);
    var targetStartPosition = _destruct__k2r9zo.km();
    var targetLen = _destruct__k2r9zo.lm();
    return this.w1v_1.i1s(targetStartPosition, buffer, offset, targetLen);
  }
  u6() {
    return Unit_instance;
  }
  toString() {
    return 'SliceAsyncStreamBase(' + toString(this.w1v_1) + ', ' + this.x1v_1.toString() + ', ' + this.y1v_1.toString() + ')';
  }
}
//endregion
function *_generator_write__x1694l($this, buffer, offset, len, $completion) {
  if (len <= 0)
    return Unit_instance;
  if ($this.b1q_1) {
    // Inline function 'kotlin.error' call
    var message = 'Trying to write to a completed ' + $this.toString();
    throw IllegalStateException.m5(toString(message));
  }
  var tmp = $this.y1p_1.a1k($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  $this.a1q_1.j12(buffer, offset, len);
  var tmp_0 = $this.z1p_1.w1j(Unit_instance, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function *_generator_read__qih2oe($this, buffer, offset, len, $completion) {
  if (len <= 0)
    return len;
  if ($this.a1q_1.i12() > 0) {
    return $this.a1q_1.k12(buffer, offset, len);
  }
  var tmp = $this.y1p_1.w1j(Unit_instance, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  while (!$this.b1q_1 && $this.a1q_1.i12() === 0) {
    var tmp_0 = $this.z1p_1.a1k($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
  }
  if ($this.b1q_1 && $this.a1q_1.i12() === 0)
    return -1;
  return $this.a1q_1.k12(buffer, offset, len);
}
function *_generator_close__nh2uv0($this, $completion) {
  $this.b1q_1 = true;
  var tmp = $this.z1p_1.w1j(Unit_instance, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
var Companion_instance_0;
function Companion_getInstance_0() {
  return Companion_instance_0;
}
function *_generator_waitToWriteMore__u7wb0w($this, len, $completion) {
  if ($this.j1q_1.o12_1 > $this.h1q_1) {
    while ($this.j1q_1.o12_1 > (1 + ($this.h1q_1 / 2 | 0) | 0)) {
      // Inline function 'kotlin.time.Companion.milliseconds' call
      Companion_getInstance();
      var tmp$ret$0 = toDuration(1, DurationUnit_MILLISECONDS_getInstance());
      var tmp = delay(tmp$ret$0, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
    }
  }
  return Unit_instance;
}
function waitToWriteMore($this, len, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_waitToWriteMore__u7wb0w($this, len, $completion), $completion);
}
function *_generator_write__x1694l_0($this, buffer, offset, len, $completion) {
  if (len <= 0)
    return Unit_instance;
  if ($this.k1q_1) {
    // Inline function 'kotlin.error' call
    var message = 'Trying to write to a completed ' + $this.toString();
    throw IllegalStateException.m5(toString(message));
  }
  var tmp = waitToWriteMore($this, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  $this.j1q_1.p12(buffer, offset, len);
  return Unit_instance;
}
function *_generator_read__qih2oe_0($this, buffer, offset, len, $completion) {
  if (len <= 0)
    return 0;
  if (offset < 0 || (offset + len | 0) > buffer.length)
    throw OutOfBoundsException.ev();
  $l$loop: while (true) {
    var out = $this.j1q_1.k12(buffer, offset, len);
    if (out <= 0 && !$this.k1q_1) {
      // Inline function 'kotlin.time.Companion.milliseconds' call
      Companion_getInstance();
      var tmp$ret$0 = toDuration(1, DurationUnit_MILLISECONDS_getInstance());
      var tmp = delay(tmp$ret$0, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
      continue $l$loop;
    }
    return out;
  }
}
function offset($this, count) {
  var out = $this.o1q_1;
  $this.o1q_1 = $this.o1q_1 + count | 0;
  return out;
}
function openFastStream(_this__u8e3s4, offset) {
  offset = offset === VOID ? 0 : offset;
  return new FastByteArrayInputStream(_this__u8e3s4, offset);
}
function get_EMPTY_BYTE_ARRAY() {
  _init_properties_SyncAsync_ext_kt__inwu1q();
  return EMPTY_BYTE_ARRAY;
}
var EMPTY_BYTE_ARRAY;
function toAsync(_this__u8e3s4, dispatcher) {
  dispatcher = dispatcher === VOID ? null : dispatcher;
  _init_properties_SyncAsync_ext_kt__inwu1q();
  return new toAsync$1(_this__u8e3s4, dispatcher);
}
function toAsync_0(_this__u8e3s4, dispatcher) {
  dispatcher = dispatcher === VOID ? null : dispatcher;
  _init_properties_SyncAsync_ext_kt__inwu1q();
  return new toAsync$2(_this__u8e3s4, dispatcher);
}
function toAsync_1(_this__u8e3s4, dispatcher) {
  dispatcher = dispatcher === VOID ? null : dispatcher;
  _init_properties_SyncAsync_ext_kt__inwu1q();
  return toAsyncStream(toAsync_2(_this__u8e3s4.r1r_1, dispatcher), _this__u8e3s4.q1q());
}
function toSyncOrNull(_this__u8e3s4) {
  _init_properties_SyncAsync_ext_kt__inwu1q();
  var tmp0_safe_receiver = _this__u8e3s4 instanceof SyncAsyncStreamBase ? _this__u8e3s4 : null;
  var tmp0_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.y1r_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var tmp1_safe_receiver = _this__u8e3s4 instanceof MemoryAsyncStreamBase ? _this__u8e3s4 : null;
    var tmp_0;
    if (tmp1_safe_receiver == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.stream.toSyncOrNull.<anonymous>' call
      tmp_0 = MemorySyncStreamBase.d1s(tmp1_safe_receiver.a1s_1);
    }
    tmp = tmp_0;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function toAsync_2(_this__u8e3s4, dispatcher) {
  dispatcher = dispatcher === VOID ? null : dispatcher;
  _init_properties_SyncAsync_ext_kt__inwu1q();
  var tmp;
  if (_this__u8e3s4 instanceof MemorySyncStreamBase) {
    tmp = MemoryAsyncStreamBase.f1s(_this__u8e3s4.c1s_1);
  } else {
    tmp = SyncAsyncStreamBase.e1s(_this__u8e3s4, dispatcher);
  }
  return tmp;
}
function *_generator_read__qih2oe_1($this, position, buffer, offset, len, $completion) {
  // Inline function 'korlibs.io.stream.SyncAsyncStreamBase.doIo' call
  var tmp;
  if (!($this.z1r_1 == null)) {
    var tmp_0 = withContext($this.z1r_1, SyncAsyncStreamBase$read$slambda_0($this, position, buffer, offset, len), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    // Inline function 'korlibs.io.stream.SyncAsyncStreamBase.read.<anonymous>' call
    tmp = $this.y1r_1.h1s(position, buffer, offset, len);
  }
  return tmp;
}
function *_generator_write__x1694l_1($this, position, buffer, offset, len, $completion) {
  // Inline function 'korlibs.io.stream.SyncAsyncStreamBase.doIo' call
  var tmp;
  if (!($this.z1r_1 == null)) {
    var tmp_0 = withContext($this.z1r_1, SyncAsyncStreamBase$write$slambda_0($this, position, buffer, offset, len), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    $this.y1r_1.i1s(position, buffer, offset, len);
    tmp = Unit_instance;
  }
  return Unit_instance;
}
function *_generator_getLength__lvva94($this, $completion) {
  // Inline function 'korlibs.io.stream.SyncAsyncStreamBase.doIo' call
  var tmp;
  if (!($this.z1r_1 == null)) {
    var tmp_0 = withContext($this.z1r_1, SyncAsyncStreamBase$getLength$slambda_0($this), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    // Inline function 'korlibs.io.stream.SyncAsyncStreamBase.getLength.<anonymous>' call
    tmp = $this.y1r_1.a();
  }
  return tmp;
}
function SyncAsyncStreamBase$read$slambda_0(this$0, $position, $buffer, $offset, $len) {
  var i = new SyncAsyncStreamBase$read$slambda(this$0, $position, $buffer, $offset, $len);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
function SyncAsyncStreamBase$write$slambda_0(this$0, $position, $buffer, $offset, $len) {
  var i = new SyncAsyncStreamBase$write$slambda(this$0, $position, $buffer, $offset, $len);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
function SyncAsyncStreamBase$getLength$slambda_0(this$0) {
  var i = new SyncAsyncStreamBase$getLength$slambda(this$0);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_write__x1694l_2($this, buffer, offset, len, $completion) {
  // Inline function 'korlibs.io.stream.<no name provided>.doIo' call
  // Inline function 'korlibs.io.stream.doIo' call
  var dispatcher = $this.b1t_1;
  var tmp;
  if (!(dispatcher == null)) {
    var tmp_0 = withContext(dispatcher, toAsync$o$write$slambda_0($this, buffer, offset, len), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    $this.a1t_1.p12(buffer, offset, len);
    tmp = Unit_instance;
  }
  return Unit_instance;
}
function toAsync$o$write$slambda_0(this$0, $buffer, $offset, $len) {
  var i = new toAsync$o$write$slambda(this$0, $buffer, $offset, $len);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
function toAsync$o$close$slambda_1(this$0) {
  var i = new toAsync$o$close$slambda(this$0);
  var l = function ($this$launch, $completion) {
    return i.o1s($this$launch, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_read__qih2oe_2($this, buffer, offset, len, $completion) {
  // Inline function 'korlibs.io.stream.<no name provided>.doIo' call
  // Inline function 'korlibs.io.stream.doIo' call
  var dispatcher = $this.i1t_1;
  var tmp;
  if (!(dispatcher == null)) {
    var tmp_0 = withContext(dispatcher, toAsync$o$read$slambda_0($this, buffer, offset, len), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    // Inline function 'korlibs.io.stream.<no name provided>.read.<anonymous>' call
    tmp = $this.h1t_1.k12(buffer, offset, len);
  }
  return tmp;
}
function *_generator_getPosition__nbsewl($this, $completion) {
  // Inline function 'korlibs.io.stream.<no name provided>.doIo' call
  // Inline function 'korlibs.io.stream.doIo' call
  var dispatcher = $this.i1t_1;
  var tmp;
  if (!(dispatcher == null)) {
    var tmp_0 = withContext(dispatcher, toAsync$o$getPosition$slambda_0($this), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    // Inline function 'korlibs.io.stream.<no name provided>.getPosition.<anonymous>' call
    var tmp_1 = $this.h1t_1;
    var tmp5_safe_receiver = isInterface(tmp_1, SyncPositionStream) ? tmp_1 : null;
    tmp = tmp5_safe_receiver == null ? null : tmp5_safe_receiver.q1q();
  }
  var tmp0_elvis_lhs = tmp;
  var tmp_2;
  if (tmp0_elvis_lhs == null) {
    var tmp_3 = getPosition.call($this, $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    tmp_2 = tmp_3;
  } else {
    tmp_2 = tmp0_elvis_lhs;
  }
  return tmp_2;
}
function *_generator_getLength__lvva94_0($this, $completion) {
  // Inline function 'korlibs.io.stream.<no name provided>.doIo' call
  // Inline function 'korlibs.io.stream.doIo' call
  var dispatcher = $this.i1t_1;
  var tmp;
  if (!(dispatcher == null)) {
    var tmp_0 = withContext(dispatcher, toAsync$o$getLength$slambda_0($this), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    // Inline function 'korlibs.io.stream.<no name provided>.getLength.<anonymous>' call
    var tmp_1 = $this.h1t_1;
    var tmp6_safe_receiver = isInterface(tmp_1, SyncLengthStream) ? tmp_1 : null;
    tmp = tmp6_safe_receiver == null ? null : tmp6_safe_receiver.a();
  }
  var tmp0_elvis_lhs = tmp;
  var tmp_2;
  if (tmp0_elvis_lhs == null) {
    var tmp_3 = getLength.call($this, $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    tmp_2 = tmp_3;
  } else {
    tmp_2 = tmp0_elvis_lhs;
  }
  return tmp_2;
}
function toAsync$o$read$slambda_0(this$0, $buffer, $offset, $len) {
  var i = new toAsync$o$read$slambda(this$0, $buffer, $offset, $len);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
function toAsync$o$close$slambda_2(this$0) {
  var i = new toAsync$o$close$slambda_0(this$0);
  var l = function ($this$launch, $completion) {
    return i.o1s($this$launch, $completion);
  };
  l.$arity = 1;
  return l;
}
function toAsync$o$getPosition$slambda_0(this$0) {
  var i = new toAsync$o$getPosition$slambda(this$0);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
function toAsync$o$getLength$slambda_0(this$0) {
  var i = new toAsync$o$getLength$slambda(this$0);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
var properties_initialized_SyncAsync_ext_kt_cwobuk;
function _init_properties_SyncAsync_ext_kt__inwu1q() {
  if (!properties_initialized_SyncAsync_ext_kt_cwobuk) {
    properties_initialized_SyncAsync_ext_kt_cwobuk = true;
    EMPTY_BYTE_ARRAY = new Int8Array(0);
  }
}
function *_generator_readLine__gnpvha(_this__u8e3s4, eol, charset, limit, initialCapacity, includeEnd, $completion) {
  eol = eol === VOID ? _Char___init__impl__6a9atx(10) : eol;
  charset = charset === VOID ? get_UTF8() : charset;
  limit = limit === VOID ? 2147483647 : limit;
  initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
  includeEnd = includeEnd === VOID ? false : includeEnd;
  // Inline function 'kotlin.code' call
  var tmp$ret$0 = Char__toInt_impl_vasixd(eol);
  var tmp = readUntil(_this__u8e3s4, toByte(tmp$ret$0), limit, initialCapacity, includeEnd, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return toString_0(tmp, charset);
}
function readLine(_this__u8e3s4, eol, charset, limit, initialCapacity, includeEnd, $completion) {
  eol = eol === VOID ? _Char___init__impl__6a9atx(10) : eol;
  charset = charset === VOID ? get_UTF8() : charset;
  limit = limit === VOID ? 2147483647 : limit;
  initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
  includeEnd = includeEnd === VOID ? false : includeEnd;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readLine__gnpvha(_this__u8e3s4, eol, charset, limit, initialCapacity, includeEnd, $completion), $completion);
}
function *_generator_readU16BE__ml3dd9(_this__u8e3s4, $completion) {
  // Inline function 'korlibs.io.stream.readSmallTempExact' call
  var it = new Int8Array(2);
  var tmp = _this__u8e3s4.e1q(it, 0, 2, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var read = tmp;
  if (!(read === 2)) {
    // Inline function 'kotlin.error' call
    var message = "Couldn't read exact size=" + 2 + ' but read=' + read;
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'korlibs.io.stream.readU16BE.<anonymous>' call
  return getU16BE(it, 0);
}
function readU16BE(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readU16BE__ml3dd9(_this__u8e3s4, $completion), $completion);
}
function *_generator_readS32BE__lo15th(_this__u8e3s4, $completion) {
  // Inline function 'korlibs.io.stream.readSmallTempExact' call
  var it = new Int8Array(4);
  var tmp = _this__u8e3s4.e1q(it, 0, 4, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var read = tmp;
  if (!(read === 4)) {
    // Inline function 'kotlin.error' call
    var message = "Couldn't read exact size=" + 4 + ' but read=' + read;
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'korlibs.io.stream.readS32BE.<anonymous>' call
  return getS32BE(it, 0);
}
function readS32BE(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readS32BE__lo15th(_this__u8e3s4, $completion), $completion);
}
function *_generator_readString__6uahun(_this__u8e3s4, len, charset, $completion) {
  charset = charset === VOID ? get_UTF8() : charset;
  var tmp = readBytesExact(_this__u8e3s4, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return toString_0(tmp, charset);
}
function readString(_this__u8e3s4, len, charset, $completion) {
  charset = charset === VOID ? get_UTF8() : charset;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readString__6uahun(_this__u8e3s4, len, charset, $completion), $completion);
}
function *_generator_readU16__9pf7bk(_this__u8e3s4, endian, $completion) {
  var tmp;
  if (endian.equals(Endian_LITTLE_ENDIAN_getInstance())) {
    var tmp_0 = readU16LE(_this__u8e3s4, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    var tmp_1 = readU16BE(_this__u8e3s4, $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp = tmp_1;
  }
  return tmp;
}
function readU16(_this__u8e3s4, endian, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readU16__9pf7bk(_this__u8e3s4, endian, $completion), $completion);
}
function *_generator_readS32__9pdyqg(_this__u8e3s4, endian, $completion) {
  var tmp;
  if (endian.equals(Endian_LITTLE_ENDIAN_getInstance())) {
    var tmp_0 = readS32LE(_this__u8e3s4, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    var tmp_1 = readS32BE(_this__u8e3s4, $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp = tmp_1;
  }
  return tmp;
}
function readS32(_this__u8e3s4, endian, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readS32__9pdyqg(_this__u8e3s4, endian, $completion), $completion);
}
function *_generator_readStringz__10au0x(_this__u8e3s4, len, charset, $completion) {
  charset = charset === VOID ? get_UTF8() : charset;
  var tmp = readBytesExact(_this__u8e3s4, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var res = tmp;
  var index = indexOf(res, 0);
  return toString_0(copyOf(res, index < 0 ? len : index), charset);
}
function readStringz(_this__u8e3s4, len, charset, $completion) {
  charset = charset === VOID ? get_UTF8() : charset;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readStringz__10au0x(_this__u8e3s4, len, charset, $completion), $completion);
}
function *_generator_readS16LE__ln0bzt(_this__u8e3s4, $completion) {
  // Inline function 'korlibs.io.stream.readSmallTempExact' call
  var it = new Int8Array(2);
  var tmp = _this__u8e3s4.e1q(it, 0, 2, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var read = tmp;
  if (!(read === 2)) {
    // Inline function 'kotlin.error' call
    var message = "Couldn't read exact size=" + 2 + ' but read=' + read;
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'korlibs.io.stream.readS16LE.<anonymous>' call
  return getS16LE(it, 0);
}
function readS16LE(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readS16LE__ln0bzt(_this__u8e3s4, $completion), $completion);
}
function *_generator_readS32LE__lo1d8f(_this__u8e3s4, $completion) {
  // Inline function 'korlibs.io.stream.readSmallTempExact' call
  var it = new Int8Array(4);
  var tmp = _this__u8e3s4.e1q(it, 0, 4, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var read = tmp;
  if (!(read === 4)) {
    // Inline function 'kotlin.error' call
    var message = "Couldn't read exact size=" + 4 + ' but read=' + read;
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'korlibs.io.stream.readS32LE.<anonymous>' call
  return getS32LE(it, 0);
}
function readS32LE(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readS32LE__lo1d8f(_this__u8e3s4, $completion), $completion);
}
function *_generator_readU32LE__mm4m0t(_this__u8e3s4, $completion) {
  // Inline function 'korlibs.io.stream.readSmallTempExact' call
  var it = new Int8Array(4);
  var tmp = _this__u8e3s4.e1q(it, 0, 4, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var read = tmp;
  if (!(read === 4)) {
    // Inline function 'kotlin.error' call
    var message = "Couldn't read exact size=" + 4 + ' but read=' + read;
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'korlibs.io.stream.readU32LE.<anonymous>' call
  return getU32LE(it, 0);
}
function readU32LE(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readU32LE__mm4m0t(_this__u8e3s4, $completion), $completion);
}
function *_generator_readUntil__njr8lo(_this__u8e3s4, endByte, limit, initialCapacity, includeEnd, temp, $completion) {
  limit = limit === VOID ? 2147483647 : limit;
  initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
  includeEnd = includeEnd === VOID ? false : includeEnd;
  temp = temp === VOID ? new Int8Array(1) : temp;
  var out = ByteArrayBuilder.mv(initialCapacity);
  try {
    $l$loop_0: while (true) {
      var tmp = readExact(_this__u8e3s4, temp, 0, 1, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
      var c = temp[0];
      if (c === endByte) {
        if (includeEnd) {
          // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
          out.qv(c);
        }
        break $l$loop_0;
      }
      // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
      out.qv(c);
      if (out.j1() >= limit)
        break $l$loop_0;
    }
  } catch ($p) {
    if ($p instanceof EOFException) {
      var e = $p;
    } else {
      throw $p;
    }
  }
  return out.rv();
}
function readUntil(_this__u8e3s4, endByte, limit, initialCapacity, includeEnd, temp, $completion) {
  limit = limit === VOID ? 2147483647 : limit;
  initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
  includeEnd = includeEnd === VOID ? false : includeEnd;
  temp = temp === VOID ? new Int8Array(1) : temp;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readUntil__njr8lo(_this__u8e3s4, endByte, limit, initialCapacity, includeEnd, temp, $completion), $completion);
}
function *_generator_readU16LE__ml3ks7(_this__u8e3s4, $completion) {
  // Inline function 'korlibs.io.stream.readSmallTempExact' call
  var it = new Int8Array(2);
  var tmp = _this__u8e3s4.e1q(it, 0, 2, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var read = tmp;
  if (!(read === 2)) {
    // Inline function 'kotlin.error' call
    var message = "Couldn't read exact size=" + 2 + ' but read=' + read;
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'korlibs.io.stream.readU16LE.<anonymous>' call
  return getU16LE(it, 0);
}
function readU16LE(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readU16LE__ml3ks7(_this__u8e3s4, $completion), $completion);
}
function *_generator_write16LE__jployf(_this__u8e3s4, v, $completion) {
  // Inline function 'korlibs.io.stream.smallBytesAlloc' call
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  var it = new Int8Array(16);
  set16LE(it, 0, v);
  var tmp = _this__u8e3s4.c1q(it, 0, 2, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function write16LE(_this__u8e3s4, v, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_write16LE__jployf(_this__u8e3s4, v, $completion), $completion);
}
function writeBytes(_this__u8e3s4, data, $completion) {
  return _this__u8e3s4.c1q(data, 0, data.length, $completion);
}
function writeString(_this__u8e3s4, string, charset, $completion) {
  charset = charset === VOID ? get_UTF8() : charset;
  return writeBytes(_this__u8e3s4, toByteArray(string, charset), $completion);
}
function *_generator_write32LE__jqmq71(_this__u8e3s4, v, $completion) {
  // Inline function 'korlibs.io.stream.smallBytesAlloc' call
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  var it = new Int8Array(16);
  set32LE(it, 0, v);
  var tmp = _this__u8e3s4.c1q(it, 0, 4, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function write32LE(_this__u8e3s4, v, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_write32LE__jqmq71(_this__u8e3s4, v, $completion), $completion);
}
function *_generator_writeShortArrayLE__xso7mp(_this__u8e3s4, array, $completion) {
  // Inline function 'korlibs.io.stream.writeTempBytes' call
  var size = imul(array.length, 2);
  // Inline function 'kotlin.apply' call
  var this_0 = new Int8Array(size);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.stream.writeShortArrayLE.<anonymous>' call
  setArrayLE(this_0, 0, array);
  var tmp = _this__u8e3s4.d1q(this_0, VOID, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function writeShortArrayLE(_this__u8e3s4, array, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_writeShortArrayLE__xso7mp(_this__u8e3s4, array, $completion), $completion);
}
function skipToAlign(_this__u8e3s4, alignment, offset) {
  var tmp = _this__u8e3s4;
  // Inline function 'kotlin.Long.minus' call
  // Inline function 'kotlin.Long.plus' call
  var tmp$ret$0 = _this__u8e3s4.t1t_1.c4(toLong(offset));
  tmp.t1t_1 = nextAlignedTo(tmp$ret$0, toLong(alignment)).h2(toLong(offset));
}
function *_generator_readBytes__epy2u7(_this__u8e3s4, position, count, $completion) {
  var out = new Int8Array(count);
  var tmp = _this__u8e3s4.w1s(position, out, 0, out.length, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var readLen = tmp;
  return copyOf(out, readLen);
}
function readBytes(_this__u8e3s4, position, count, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readBytes__epy2u7(_this__u8e3s4, position, count, $completion), $completion);
}
function *_generator_sliceStart__7mxlrc(_this__u8e3s4, start, closeParent, $completion) {
  start = start === VOID ? new Long(0, 0) : start;
  closeParent = closeParent === VOID ? false : closeParent;
  var tmp = _this__u8e3s4.y1s($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = sliceWithBounds(_this__u8e3s4, start, tmp, closeParent, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return tmp_0;
}
function sliceStart(_this__u8e3s4, start, closeParent, $completion) {
  start = start === VOID ? new Long(0, 0) : start;
  closeParent = closeParent === VOID ? false : closeParent;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_sliceStart__7mxlrc(_this__u8e3s4, start, closeParent, $completion), $completion);
}
function readStream(_this__u8e3s4, length, $completion) {
  return readSlice(_this__u8e3s4, toLong(length), $completion);
}
function *_generator_sliceHere__xy500i(_this__u8e3s4, closeParent, $completion) {
  closeParent = closeParent === VOID ? false : closeParent;
  var tmp = _this__u8e3s4.t1t_1;
  var tmp_0 = _this__u8e3s4.y1s($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var tmp_1 = sliceWithSize(_this__u8e3s4, tmp, tmp_0, closeParent, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return tmp_1;
}
function sliceHere(_this__u8e3s4, closeParent, $completion) {
  closeParent = closeParent === VOID ? false : closeParent;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_sliceHere__xy500i(_this__u8e3s4, closeParent, $completion), $completion);
}
function *_generator_sliceWithBounds__ahw9dz(_this__u8e3s4, start, end, closeParent, $completion) {
  closeParent = closeParent === VOID ? false : closeParent;
  var tmp;
  var tmp_0 = _this__u8e3s4.z1s($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  if (tmp_0) {
    var tmp_1 = _this__u8e3s4.y1s($completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp = tmp_1;
  } else {
    tmp = end;
  }
  var rlen = tmp;
  var len = rlen.g2(new Long(0, 0)) >= 0 ? rlen : end;
  var clampedStart = coerceIn_0(start, new Long(0, 0), len);
  var clampedEnd = coerceIn_0(end, new Long(0, 0), len);
  var base = _this__u8e3s4.s1t_1;
  var tmp_2;
  if (base instanceof SliceAsyncStreamBase) {
    tmp_2 = SliceAsyncStreamBase.c1u(base.x1t_1, base.y1t_1.c4(clampedStart), base.y1t_1.c4(clampedEnd), closeParent);
  } else {
    tmp_2 = SliceAsyncStreamBase.c1u(_this__u8e3s4.s1t_1, clampedStart, clampedEnd, closeParent);
  }
  return toAsyncStream(tmp_2);
}
function sliceWithBounds(_this__u8e3s4, start, end, closeParent, $completion) {
  closeParent = closeParent === VOID ? false : closeParent;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_sliceWithBounds__ahw9dz(_this__u8e3s4, start, end, closeParent, $completion), $completion);
}
function *_generator_readSlice__mkd95k(_this__u8e3s4, length, $completion) {
  var tmp = _this__u8e3s4.q1t($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var start = tmp;
  var tmp_0 = sliceWithSize(_this__u8e3s4, start, length, VOID, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var out = tmp_0;
  var tmp_1 = _this__u8e3s4.d1u(start.c4(length), $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return out;
}
function readSlice(_this__u8e3s4, length, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readSlice__mkd95k(_this__u8e3s4, length, $completion), $completion);
}
function sliceWithSize(_this__u8e3s4, start, length, closeParent, $completion) {
  closeParent = closeParent === VOID ? false : closeParent;
  return sliceWithBounds(_this__u8e3s4, start, start.c4(length), closeParent, $completion);
}
function clampPosition($this, position) {
  return coerceIn_0(position, $this.y1t_1, $this.z1t_1);
}
function clampPositionLen($this, position, len) {
  if (position.g2(new Long(0, 0)) < 0)
    throw IllegalArgumentException.l('Invalid position');
  var targetStartPosition = clampPosition($this, $this.y1t_1.c4(position));
  // Inline function 'kotlin.Long.plus' call
  var tmp$ret$0 = targetStartPosition.c4(toLong(len));
  var targetEndPosition = clampPosition($this, tmp$ret$0);
  var targetLen = targetEndPosition.h2(targetStartPosition).q2();
  return new Pair(targetStartPosition, targetLen);
}
function *_generator_close__nh2uv0_0($this, $completion) {
  if ($this.a1u_1) {
    var tmp = $this.x1t_1.f1q($completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function buffered(_this__u8e3s4, blockSize, blocksToRead) {
  blockSize = blockSize === VOID ? 2048 : blockSize;
  blocksToRead = blocksToRead === VOID ? 16 : blocksToRead;
  return toAsyncStream(BufferedStreamBase.k1u(_this__u8e3s4.s1t_1, blockSize, blocksToRead), _this__u8e3s4.t1t_1);
}
function *_generator__read__lo0dlr($this, position, buffer, offset, len, $completion) {
  var tmp;
  var tmp_0 = $this.e1u_1.z1s($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  if (tmp_0) {
    var tmp_1 = $this.e1u_1.y1s($completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp = position.g2(tmp_1) >= 0;
  } else {
    tmp = false;
  }
  if (tmp)
    return -1;
  // Inline function 'kotlin.Long.div' call
  var other = $this.h1u_1;
  var sector = position.e4(toLong(other));
  if (!$this.j1u_1.equals(sector)) {
    // Inline function 'kotlin.Long.times' call
    var other_0 = $this.h1u_1;
    var pos = sector.d4(toLong(other_0));
    var tmp_2;
    var tmp_3 = $this.e1u_1.z1s($completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    if (tmp_3) {
      // Inline function 'kotlin.comparisons.minOf' call
      var a = toLong($this.h1u_1);
      var tmp_4 = $this.e1u_1.y1s($completion);
      if (tmp_4 === get_COROUTINE_SUSPENDED())
        tmp_4 = yield tmp_4;
      var b = tmp_4.h2(pos);
      tmp_2 = (a.g2(b) <= 0 ? a : b).q2();
    } else {
      tmp_2 = $this.h1u_1;
    }
    var len_0 = tmp_2;
    var tmp_5 = $this;
    var tmp_6 = readBytes($this.e1u_1, pos, len_0, $completion);
    if (tmp_6 === get_COROUTINE_SUSPENDED())
      tmp_6 = yield tmp_6;
    tmp_5.i1u_1 = tmp_6;
    $this.j1u_1 = sector;
  }
  // Inline function 'kotlin.Long.rem' call
  var other_1 = $this.h1u_1;
  var soffset = position.f4(toLong(other_1)).q2();
  var available = $this.i1u_1.length - soffset | 0;
  // Inline function 'kotlin.math.min' call
  var toRead = Math.min(available, len);
  arraycopy($this.i1u_1, soffset, buffer, offset, toRead);
  return toRead;
}
function *_generator_write__x1694l_3($this, position, buffer, offset, len, $completion) {
  var tmp = $this.e1u_1.x1s(position, buffer, offset, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function openAsync(_this__u8e3s4, mode) {
  mode = mode === VOID ? 'r' : mode;
  return toAsyncStream(MemoryAsyncStreamBase.f1s(ByteArrayBuilder.lv(_this__u8e3s4, VOID, true)), new Long(0, 0));
}
function *_generator_readBytesExact__9z65vi(_this__u8e3s4, len, $completion) {
  // Inline function 'kotlin.apply' call
  var this_0 = new Int8Array(len);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.stream.readBytesExact.<anonymous>' call
  var tmp = readExact(_this__u8e3s4, this_0, 0, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return this_0;
}
function readBytesExact(_this__u8e3s4, len, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readBytesExact__9z65vi(_this__u8e3s4, len, $completion), $completion);
}
function *_generator_hasLength__5f5ox8($this, $completion) {
  var tmp;
  try {
    var tmp_0 = $this.y1s($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0.g2(new Long(0, 0)) >= 0;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof UnsupportedOperationException) {
      var t = $p;
      tmp_1 = false;
    } else {
      throw $p;
    }
    tmp = tmp_1;
  }
  return tmp;
}
function *_generator_readAll__9p3omh(_this__u8e3s4, $completion) {
  var tmp;
  try {
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (isInterface(_this__u8e3s4, AsyncGetPositionStream)) {
      tmp_2 = isInterface(_this__u8e3s4, AsyncGetLengthStream);
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      var tmp_3 = _this__u8e3s4.z1s($completion);
      if (tmp_3 === get_COROUTINE_SUSPENDED())
        tmp_3 = yield tmp_3;
      tmp_1 = tmp_3;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      var tmp_4 = _this__u8e3s4.y1s($completion);
      if (tmp_4 === get_COROUTINE_SUSPENDED())
        tmp_4 = yield tmp_4;
      var tmp_5 = _this__u8e3s4.q1t($completion);
      if (tmp_5 === get_COROUTINE_SUSPENDED())
        tmp_5 = yield tmp_5;
      var available = tmp_4.h2(tmp_5);
      var tmp_6 = readBytesExact(_this__u8e3s4, available.q2(), $completion);
      if (tmp_6 === get_COROUTINE_SUSPENDED())
        tmp_6 = yield tmp_6;
      tmp_0 = tmp_6;
    } else {
      var tmp_7;
      if (_this__u8e3s4 instanceof AsyncStream) {
        var tmp_8 = _this__u8e3s4.p1u($completion);
        if (tmp_8 === get_COROUTINE_SUSPENDED())
          tmp_8 = yield tmp_8;
        tmp_7 = tmp_8;
      } else {
        tmp_7 = false;
      }
      if (tmp_7) {
        var tmp_9 = _this__u8e3s4.q1u($completion);
        if (tmp_9 === get_COROUTINE_SUSPENDED())
          tmp_9 = yield tmp_9;
        var available_0 = tmp_9.q2();
        var tmp_10 = readBytesExact(_this__u8e3s4, available_0, $completion);
        if (tmp_10 === get_COROUTINE_SUSPENDED())
          tmp_10 = yield tmp_10;
        tmp_0 = tmp_10;
      } else {
        var out = ByteArrayBuilder.mv();
        // Inline function 'korlibs.io.stream.readChunks' call
        var temp = new Int8Array(1024);
        $l$loop: while (true) {
          var tmp_11 = _this__u8e3s4.e1q(temp, 0, temp.length, $completion);
          if (tmp_11 === get_COROUTINE_SUSPENDED())
            tmp_11 = yield tmp_11;
          var r = tmp_11;
          if (r <= 0)
            break $l$loop;
          // Inline function 'korlibs.io.stream.readAll.<anonymous>' call
          out.ov(temp, 0, r);
        }
        tmp_0 = out.rv();
      }
    }
    tmp = tmp_0;
  }finally {
    var tmp_12 = _this__u8e3s4.f1q($completion);
    if (tmp_12 === get_COROUTINE_SUSPENDED())
      tmp_12 = yield tmp_12;
  }
  return tmp;
}
function readAll(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readAll__9p3omh(_this__u8e3s4, $completion), $completion);
}
function *_generator_read__qih2oe_3($this, buffer, offset, len, $completion) {
  var tmp;
  if ($this.u1t_1) {
    // Inline function 'kotlinx.coroutines.sync.withLock' call
    var this_0 = $this.v1t_1;
    var tmp_0 = this_0.g1n(null, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    var tmp_1;
    try {
      // Inline function 'korlibs.io.stream.AsyncStream.read.<anonymous>' call
      var tmp_2 = readInternal($this, buffer, offset, len, $completion);
      if (tmp_2 === get_COROUTINE_SUSPENDED())
        tmp_2 = yield tmp_2;
      tmp_1 = tmp_2;
    }finally {
      this_0.r1m(null);
    }
    tmp = tmp_1;
  } else {
    var tmp_3 = readInternal($this, buffer, offset, len, $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    tmp = tmp_3;
  }
  return tmp;
}
function *_generator_write__x1694l_4($this, buffer, offset, len, $completion) {
  if ($this.u1t_1) {
    // Inline function 'kotlinx.coroutines.sync.withLock' call
    var this_0 = $this.w1t_1;
    var tmp = this_0.g1n(null, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var tmp_0;
    try {
      var tmp_1 = writeInternal($this, buffer, offset, len, $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      tmp_0 = Unit_instance;
    }finally {
      this_0.r1m(null);
    }
  } else {
    var tmp_2 = writeInternal($this, buffer, offset, len, $completion);
    if (tmp_2 === get_COROUTINE_SUSPENDED())
      tmp_2 = yield tmp_2;
  }
  return Unit_instance;
}
function *_generator_readInternal__sbpouj($this, buffer, offset, len, $completion) {
  var tmp = $this.s1t_1.w1s($this.t1t_1, buffer, offset, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var read = tmp;
  if (read >= 0) {
    var tmp_0 = $this;
    // Inline function 'kotlin.Long.plus' call
    tmp_0.t1t_1 = $this.t1t_1.c4(toLong(read));
  }
  return read;
}
function readInternal($this, buffer, offset, len, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readInternal__sbpouj($this, buffer, offset, len, $completion), $completion);
}
function *_generator_writeInternal__u35gg8($this, buffer, offset, len, $completion) {
  var tmp = $this.s1t_1.x1s($this.t1t_1, buffer, offset, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = $this;
  // Inline function 'kotlin.Long.plus' call
  tmp_0.t1t_1 = $this.t1t_1.c4(toLong(len));
  return Unit_instance;
}
function writeInternal($this, buffer, offset, len, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_writeInternal__u35gg8($this, buffer, offset, len, $completion), $completion);
}
function toAsyncStream(_this__u8e3s4, position) {
  position = position === VOID ? new Long(0, 0) : position;
  return new AsyncStream(_this__u8e3s4, position);
}
function *_generator_copyTo__iu4794(_this__u8e3s4, target, chunkSize, $completion) {
  chunkSize = chunkSize === VOID ? 8388608 : chunkSize;
  var tmp;
  if (_this__u8e3s4 instanceof AsyncStream) {
    var tmp_0 = _this__u8e3s4.s1t_1;
    tmp = tmp_0 instanceof MemoryAsyncStreamBase;
  } else {
    tmp = false;
  }
  if (tmp) {
    var tmp_1 = _this__u8e3s4.s1t_1;
    var base = tmp_1 instanceof MemoryAsyncStreamBase ? tmp_1 : THROW_CCE();
    var tmp_2 = target.c1q(base.a1s_1.iv_1, _this__u8e3s4.t1t_1.q2(), base.n1u() - _this__u8e3s4.t1t_1.q2() | 0, $completion);
    if (tmp_2 === get_COROUTINE_SUSPENDED())
      tmp_2 = yield tmp_2;
    return toLong(base.n1u());
  }
  var tmp_3;
  if (isInterface(_this__u8e3s4, AsyncGetLengthStream)) {
    // Inline function 'kotlin.math.min' call
    var tmp_4 = _this__u8e3s4.y1s($completion);
    if (tmp_4 === get_COROUTINE_SUSPENDED())
      tmp_4 = yield tmp_4;
    var a = tmp_4;
    var b = toLong(chunkSize);
    tmp_3 = (a.g2(b) <= 0 ? a : b).q2();
  } else {
    tmp_3 = chunkSize;
  }
  var rchunkSize = tmp_3;
  var totalCount = new Long(0, 0);
  // Inline function 'korlibs.io.stream.consume' call
  var temp = new Int8Array(rchunkSize);
  try {
    $l$loop: while (true) {
      var tmp_5 = _this__u8e3s4.e1q(temp, 0, temp.length, $completion);
      if (tmp_5 === get_COROUTINE_SUSPENDED())
        tmp_5 = yield tmp_5;
      var read = tmp_5;
      if (read <= 0)
        break $l$loop;
      // Inline function 'korlibs.io.stream.copyTo.<anonymous>' call
      var tmp_6 = target.c1q(temp, 0, read, $completion);
      if (tmp_6 === get_COROUTINE_SUSPENDED())
        tmp_6 = yield tmp_6;
      // Inline function 'kotlin.Long.plus' call
      totalCount = totalCount.c4(toLong(read));
    }
  }finally {
    if (false) {
      var tmp_7 = _this__u8e3s4.f1q($completion);
      if (tmp_7 === get_COROUTINE_SUSPENDED())
        tmp_7 = yield tmp_7;
    }
  }
  return totalCount;
}
function copyTo(_this__u8e3s4, target, chunkSize, $completion) {
  chunkSize = chunkSize === VOID ? 8388608 : chunkSize;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_copyTo__iu4794(_this__u8e3s4, target, chunkSize, $completion), $completion);
}
function *_generator_readBytesUpTo__nh3xxl(_this__u8e3s4, len, $completion) {
  var BYTES_TEMP_SIZE = 4096;
  if (len <= BYTES_TEMP_SIZE) {
    var tmp = readBytesUpToCopy(_this__u8e3s4, new Int8Array(len), $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    return tmp;
  }
  if (isInterface(_this__u8e3s4, AsyncPositionLengthStream)) {
    // Inline function 'kotlin.math.min' call
    var tmp_0 = _this__u8e3s4.q1u($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    var b = toIntClamp(tmp_0);
    var tmp$ret$0 = Math.min(len, b);
    var tmp_1 = readBytesUpToCopy(_this__u8e3s4, new Int8Array(tmp$ret$0), $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    return tmp_1;
  }
  var pending = len;
  var temp = new Int8Array(BYTES_TEMP_SIZE);
  var bout = ByteArrayBuilder.mv();
  $l$loop: while (pending > 0) {
    // Inline function 'kotlin.math.min' call
    var a = temp.length;
    var b_0 = pending;
    var tmp$ret$1 = Math.min(a, b_0);
    var tmp_2 = _this__u8e3s4.e1q(temp, 0, tmp$ret$1, $completion);
    if (tmp_2 === get_COROUTINE_SUSPENDED())
      tmp_2 = yield tmp_2;
    var read = tmp_2;
    if (read <= 0)
      break $l$loop;
    bout.ov(temp, 0, read);
    pending = pending - read | 0;
  }
  return bout.rv();
}
function readBytesUpTo(_this__u8e3s4, len, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readBytesUpTo__nh3xxl(_this__u8e3s4, len, $completion), $completion);
}
function *_generator_readBytesUpTo__nh3xxl_0(_this__u8e3s4, out, offset, len, $completion) {
  var total = 0;
  var pending = len;
  var offset_0 = offset;
  $l$loop: while (true) {
    var tmp = _this__u8e3s4.e1q(out, offset_0, pending, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var result = tmp;
    if (result <= 0)
      break $l$loop;
    offset_0 = offset_0 + result | 0;
    pending = pending - result | 0;
    total = total + result | 0;
  }
  return total;
}
function readBytesUpTo_0(_this__u8e3s4, out, offset, len, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readBytesUpTo__nh3xxl_0(_this__u8e3s4, out, offset, len, $completion), $completion);
}
function *_generator_readExact__g46z7v(_this__u8e3s4, buffer, offset, len, $completion) {
  var remaining = len;
  var coffset = offset;
  var reader = _this__u8e3s4;
  $l$loop: while (remaining > 0) {
    var tmp = reader.e1q(buffer, coffset, remaining, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var read = tmp;
    if (read < 0)
      break $l$loop;
    if (read === 0)
      throw EOFException.ox('Not enough data. Expected=' + len + ', Read=' + (len - remaining | 0) + ', Remaining=' + remaining);
    coffset = coffset + read | 0;
    remaining = remaining - read | 0;
  }
  return Unit_instance;
}
function readExact(_this__u8e3s4, buffer, offset, len, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readExact__g46z7v(_this__u8e3s4, buffer, offset, len, $completion), $completion);
}
function *_generator_getAvailable__v3o1wr($this, $completion) {
  var tmp = $this.y1s($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = $this.q1t($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return tmp.h2(tmp_0);
}
function *_generator_eof__cbg9jc($this, $completion) {
  var tmp;
  var tmp_0 = $this.p1u($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  if (tmp_0) {
    var tmp_1 = $this.q1u($completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp = tmp_1.g2(new Long(0, 0)) <= 0;
  } else {
    tmp = false;
  }
  return tmp;
}
function *_generator_readBytesUpToCopy__bi3svg(_this__u8e3s4, out, $completion) {
  var tmp = readBytesUpTo_0(_this__u8e3s4, out, 0, out.length, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var pos = tmp;
  return out.length === pos ? out : copyOf(out, pos);
}
function readBytesUpToCopy(_this__u8e3s4, out, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readBytesUpToCopy__bi3svg(_this__u8e3s4, out, $completion), $completion);
}
function asyncStreamWriter(bufferSize, name, lazy, process, $completion) {
  bufferSize = bufferSize === VOID ? 8388608 : bufferSize;
  name = name === VOID ? null : name;
  lazy = lazy === VOID ? false : lazy;
  return new asyncStreamWriter$1(lazy, bufferSize, name, process);
}
function *_generator_invoke__zhh2q8($this, $this$launch, $completion) {
  try {
    var tmp = $this.t1u_1(new asyncStreamWriter$1$ensureJob$2$1($this.u1u_1), $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      $this.u1u_1.w1u_1 = e;
      printStackTrace(e);
    } else {
      throw $p;
    }
  }
  finally {
    var tmp_0 = $this.u1u_1.v1u_1.f1q($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
  }
  return Unit_instance;
}
function checkException($this) {
  if (!($this.w1u_1 == null))
    throw RuntimeException.ye('Error in asyncStreamWriter', ensureNotNull($this.w1u_1));
}
function ensureJob($this, $completion) {
  if (!($this.x1u_1 == null))
    return Unit_instance;
  var tmp = $this;
  // Inline function 'kotlin.js.getCoroutineContext' call
  var tmp$ret$0 = $completion.gd();
  var tmp_0 = CoroutineScope_0(tmp$ret$0);
  tmp.x1u_1 = launch(tmp_0, VOID, VOID, asyncStreamWriter$o$ensureJob$slambda_0($this.z1u_1, $this));
}
function *_generator_read__qih2oe_4($this, buffer, offset, len, $completion) {
  var tmp = ensureJob($this, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  checkException($this);
  // Inline function 'kotlin.also' call
  var tmp_0 = $this.v1u_1.e1q(buffer, offset, len, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.stream.<no name provided>.read.<anonymous>' call
  return tmp_0;
}
function asyncStreamWriter$o$ensureJob$slambda_0($process, this$0) {
  var i = new asyncStreamWriter$o$ensureJob$slambda($process, this$0);
  var l = function ($this$launch, $completion) {
    return i.o1s($this$launch, $completion);
  };
  l.$arity = 1;
  return l;
}
function toSyncStream(_this__u8e3s4) {
  return ReadonlySyncStreamBase.f1v(_this__u8e3s4.l1q_1, _this__u8e3s4.m1q_1, _this__u8e3s4.n1q_1 - _this__u8e3s4.m1q_1 | 0).g1v();
}
function openSync(_this__u8e3s4, mode) {
  mode = mode === VOID ? 'r' : mode;
  return MemorySyncStreamBase.d1s(ByteArrayBuilder.lv(_this__u8e3s4)).l1v(new Long(0, 0));
}
function MemorySyncStream(data) {
  data = data === VOID ? get_EMPTY_BYTE_ARRAY() : data;
  return MemorySyncStreamBase.d1s(ByteArrayBuilder.lv(data)).m1v();
}
function MemorySyncStream_0(data) {
  return MemorySyncStreamBase.d1s(data).m1v();
}
function readS16BE(_this__u8e3s4) {
  return signExtend(readU16BE_0(_this__u8e3s4), 16);
}
function readString_0(_this__u8e3s4, len) {
  return decodeToString(readBytes_0(_this__u8e3s4, len));
}
function openAsync_0(_this__u8e3s4, charset) {
  charset = charset === VOID ? get_UTF8() : charset;
  return toAsync_1(openSync(toByteArray(_this__u8e3s4, charset), 'r'));
}
function readS32BE_0(_this__u8e3s4) {
  return readU8(_this__u8e3s4) << 24 | readU8(_this__u8e3s4) << 16 | readU8(_this__u8e3s4) << 8 | readU8(_this__u8e3s4);
}
function readStringz_0(_this__u8e3s4, len) {
  var res = readBytes_0(_this__u8e3s4, len);
  var index = indexOf(res, 0);
  return decodeToString(copyOf(res, index < 0 ? len : index));
}
function readU8(_this__u8e3s4) {
  return _this__u8e3s4.p1v();
}
function readBytes_0(_this__u8e3s4, len) {
  var bytes = new Int8Array(len);
  var out = _this__u8e3s4.k12(bytes, 0, len);
  return !(out === len) ? copyOf(bytes, out) : bytes;
}
function readString_1(_this__u8e3s4, len, charset) {
  charset = charset === VOID ? get_UTF8() : charset;
  return toString_0(readBytes_0(_this__u8e3s4, len), charset);
}
function readS16LE_0(_this__u8e3s4) {
  return signExtend(readU16LE_0(_this__u8e3s4), 16);
}
function readS32LE_0(_this__u8e3s4) {
  return readU8(_this__u8e3s4) | readU8(_this__u8e3s4) << 8 | readU8(_this__u8e3s4) << 16 | readU8(_this__u8e3s4) << 24;
}
function readS64LE(_this__u8e3s4) {
  return readU32LE_0(_this__u8e3s4).o4(readU32LE_0(_this__u8e3s4).k4(32));
}
function readS64BE(_this__u8e3s4) {
  return readU32BE(_this__u8e3s4).k4(32).o4(readU32BE(_this__u8e3s4));
}
function readF64BE(_this__u8e3s4) {
  // Inline function 'korlibs.memory.reinterpretAsDouble' call
  // Inline function 'kotlin.fromBits' call
  var bits = readS64BE(_this__u8e3s4);
  return doubleFromBits(bits);
}
function readStringz_1(_this__u8e3s4, charset, zero) {
  charset = charset === VOID ? get_UTF8() : charset;
  zero = zero === VOID ? 0 : zero;
  var buf = ByteArrayBuilder.mv();
  var temp = new Int8Array(4096);
  $l$loop_0: while (true) {
    var read = _this__u8e3s4.k12(temp, 0, 1);
    if (read <= 0)
      break $l$loop_0;
    if (temp[0] === zero)
      break $l$loop_0;
    // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
    var v = temp[0];
    buf.qv(v);
  }
  return toString_0(buf.rv(), charset);
}
function readU16LE_0(_this__u8e3s4) {
  return readU8(_this__u8e3s4) | readU8(_this__u8e3s4) << 8;
}
function readF64LE(_this__u8e3s4) {
  // Inline function 'korlibs.memory.reinterpretAsDouble' call
  // Inline function 'kotlin.fromBits' call
  var bits = readS64LE(_this__u8e3s4);
  return doubleFromBits(bits);
}
function readS8(_this__u8e3s4) {
  return toByte(_this__u8e3s4.p1v());
}
function readS24LE(_this__u8e3s4) {
  return signExtend(readU24LE(_this__u8e3s4), 24);
}
function readU16BE_0(_this__u8e3s4) {
  return readU8(_this__u8e3s4) << 8 | readU8(_this__u8e3s4);
}
function readU32LE_0(_this__u8e3s4) {
  return toLong(readU8(_this__u8e3s4) | readU8(_this__u8e3s4) << 8 | readU8(_this__u8e3s4) << 16 | readU8(_this__u8e3s4) << 24).n4(new Long(-1, 0));
}
function readU32BE(_this__u8e3s4) {
  return toLong(readU8(_this__u8e3s4) << 24 | readU8(_this__u8e3s4) << 16 | readU8(_this__u8e3s4) << 8 | readU8(_this__u8e3s4)).n4(new Long(-1, 0));
}
function readU24LE(_this__u8e3s4) {
  return readU8(_this__u8e3s4) | readU8(_this__u8e3s4) << 8 | readU8(_this__u8e3s4) << 16;
}
function readBytesExact_0(_this__u8e3s4, len) {
  // Inline function 'kotlin.apply' call
  var this_0 = new Int8Array(len);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.stream.readBytesExact.<anonymous>' call
  readExact_0(_this__u8e3s4, this_0, 0, len);
  return this_0;
}
function readExact_0(_this__u8e3s4, out, offset, len) {
  var ooffset = offset;
  var remaining = len;
  while (remaining > 0) {
    var read = _this__u8e3s4.k12(out, ooffset, remaining);
    if (read <= 0) {
      throw RuntimeException.db('EOF');
    }
    remaining = remaining - read | 0;
    ooffset = ooffset + read | 0;
  }
}
function write8(_this__u8e3s4, v) {
  return _this__u8e3s4.o1v(v);
}
function write32BE(_this__u8e3s4, v) {
  write8(_this__u8e3s4, (v >>> 24 | 0) & 255);
  write8(_this__u8e3s4, (v >>> 16 | 0) & 255);
  write8(_this__u8e3s4, (v >>> 8 | 0) & 255);
  write8(_this__u8e3s4, v & 255);
}
function writeBytes_0(_this__u8e3s4, data) {
  return _this__u8e3s4.p12(data, 0, data.length);
}
function write32LE_0(_this__u8e3s4, v) {
  write8(_this__u8e3s4, v & 255);
  write8(_this__u8e3s4, (v >>> 8 | 0) & 255);
  write8(_this__u8e3s4, (v >>> 16 | 0) & 255);
  write8(_this__u8e3s4, (v >>> 24 | 0) & 255);
}
function toByteArray_0(_this__u8e3s4) {
  if (get_hasLength(_this__u8e3s4)) {
    return readAll_0(sliceWithBounds_0(_this__u8e3s4, new Long(0, 0), _this__u8e3s4.a()));
  } else {
    return readAll_0(_this__u8e3s4.j13());
  }
}
function get_eof(_this__u8e3s4) {
  return _this__u8e3s4.oy().g2(new Long(0, 0)) <= 0;
}
function readAll_0(_this__u8e3s4) {
  return readBytes_0(_this__u8e3s4, _this__u8e3s4.oy().q2());
}
function get_hasLength(_this__u8e3s4) {
  // Inline function 'kotlin.runCatching' call
  var tmp;
  try {
    // Inline function 'kotlin.Companion.success' call
    // Inline function 'korlibs.io.stream.<get-hasLength>.<anonymous>' call
    var value = _this__u8e3s4.a();
    tmp = _Result___init__impl__xyqfz8(value);
  } catch ($p) {
    var tmp_0;
    if ($p instanceof Error) {
      var e = $p;
      // Inline function 'kotlin.Companion.failure' call
      tmp_0 = _Result___init__impl__xyqfz8(createFailure(e));
    } else {
      throw $p;
    }
    tmp = tmp_0;
  }
  var tmp$ret$3 = tmp;
  return _Result___get_isSuccess__impl__sndoy8(tmp$ret$3);
}
function readAvailable(_this__u8e3s4) {
  return readBytes_0(_this__u8e3s4, _this__u8e3s4.oy().q2());
}
function readStream_0(_this__u8e3s4, length) {
  return readSlice_0(_this__u8e3s4, length);
}
function sliceStart_0(_this__u8e3s4, start) {
  start = start === VOID ? new Long(0, 0) : start;
  return sliceWithBounds_0(_this__u8e3s4, start, _this__u8e3s4.a());
}
function sliceWithBounds_0(_this__u8e3s4, start, end) {
  var len = _this__u8e3s4.a();
  var clampedStart = coerceIn_0(start, new Long(0, 0), len);
  var clampedEnd = coerceIn_0(end, new Long(0, 0), len);
  var base = _this__u8e3s4.r1r_1;
  if (base instanceof SliceSyncStreamBase) {
    return SliceSyncStreamBase.a1w(base.w1v_1, base.x1v_1.c4(clampedStart), base.x1v_1.c4(clampedEnd)).m1v();
  } else {
    return SliceSyncStreamBase.a1w(base, clampedStart, clampedEnd).m1v();
  }
}
function readSlice_0(_this__u8e3s4, length) {
  // Inline function 'kotlin.apply' call
  var this_0 = sliceWithSize_0(_this__u8e3s4, _this__u8e3s4.q1q(), length);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.stream.readSlice.<anonymous>' call
  _this__u8e3s4.r1v(_this__u8e3s4.q1q().c4(length));
  return this_0;
}
function clampPosition_0($this, position) {
  return coerceIn_0(position, $this.x1v_1, $this.y1v_1);
}
function clampPositionLen_0($this, position, len) {
  if (position.g2(new Long(0, 0)) < 0)
    throw IllegalArgumentException.l('Invalid position');
  var targetStartPosition = clampPosition_0($this, $this.x1v_1.c4(position));
  // Inline function 'kotlin.Long.plus' call
  var tmp$ret$0 = targetStartPosition.c4(toLong(len));
  var targetEndPosition = clampPosition_0($this, tmp$ret$0);
  var targetLen = targetEndPosition.h2(targetStartPosition).q2();
  return new Pair(targetStartPosition, targetLen);
}
function sliceWithSize_0(_this__u8e3s4, position, length) {
  return sliceWithBounds_0(_this__u8e3s4, position, position.c4(length));
}
function readStream_1(_this__u8e3s4, length) {
  return readSlice_0(_this__u8e3s4, toLong(length));
}
//region block: post-declaration
initMetadataForInterface(AsyncOutputStream, 'AsyncOutputStream', VOID, VOID, VOID, [3, 1, 0]);
protoOf(AsyncRingBuffer).d1q = write$default;
initMetadataForClass(AsyncRingBuffer, 'AsyncRingBuffer', AsyncRingBuffer, VOID, [AsyncOutputStream], [3, 0, 1]);
initMetadataForCompanion(Companion);
protoOf(AsyncRingBufferChunked).d1q = write$default;
initMetadataForClass(AsyncRingBufferChunked, 'AsyncRingBufferChunked', AsyncRingBufferChunked, VOID, [AsyncOutputStream], [1, 3, 0]);
initMetadataForClass(FastByteArrayInputStream, 'FastByteArrayInputStream');
initMetadataForLambda(SyncAsyncStreamBase$read$slambda, VOID, VOID, [1]);
initMetadataForLambda(SyncAsyncStreamBase$write$slambda, VOID, VOID, [1]);
initMetadataForLambda(SyncAsyncStreamBase$getLength$slambda, VOID, VOID, [1]);
initMetadataForInterface(AsyncGetLengthStream, 'AsyncGetLengthStream', VOID, VOID, VOID, [0]);
protoOf(AsyncStreamBase).z1s = hasLength;
initMetadataForClass(AsyncStreamBase, 'AsyncStreamBase', AsyncStreamBase.v1s, VOID, [AsyncGetLengthStream], [4, 1, 0]);
initMetadataForClass(SyncAsyncStreamBase, 'SyncAsyncStreamBase', VOID, VOID, VOID, [1, 4, 0]);
initMetadataForLambda(toAsync$o$write$slambda, VOID, VOID, [1]);
initMetadataForLambda(toAsync$o$close$slambda, VOID, VOID, [1]);
protoOf(toAsync$1).d1q = write$default;
initMetadataForClass(toAsync$1, VOID, VOID, VOID, [AsyncOutputStream], [1, 3, 0]);
initMetadataForLambda(toAsync$o$read$slambda, VOID, VOID, [1]);
initMetadataForLambda(toAsync$o$close$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(toAsync$o$getPosition$slambda, VOID, VOID, [1]);
initMetadataForLambda(toAsync$o$getLength$slambda, VOID, VOID, [1]);
initMetadataForInterface(AsyncGetPositionStream, 'AsyncGetPositionStream', VOID, VOID, VOID, [0]);
protoOf(toAsync$2).z1s = hasLength;
initMetadataForClass(toAsync$2, VOID, VOID, VOID, [AsyncGetPositionStream, AsyncGetLengthStream], [1, 3, 0]);
initMetadataForClass(SliceAsyncStreamBase, 'SliceAsyncStreamBase', VOID, VOID, VOID, [4, 0, 1]);
initMetadataForClass(BufferedStreamBase, 'BufferedStreamBase', VOID, VOID, VOID, [4, 1, 0]);
initMetadataForClass(MemoryAsyncStreamBase, 'MemoryAsyncStreamBase', MemoryAsyncStreamBase.m1u, VOID, VOID, [1, 0, 4]);
initMetadataForInterface(AsyncPositionLengthStream, 'AsyncPositionLengthStream', VOID, VOID, [AsyncGetPositionStream, AsyncGetLengthStream], [0, 1]);
protoOf(AsyncStream).d1q = write$default;
protoOf(AsyncStream).p1u = hasAvailable;
protoOf(AsyncStream).q1u = getAvailable;
protoOf(AsyncStream).s1u = eof;
initMetadataForClass(AsyncStream, 'AsyncStream', VOID, VOID, [AsyncGetPositionStream, AsyncGetLengthStream, AsyncOutputStream, AsyncPositionLengthStream], [3, 0, 1]);
protoOf(asyncStreamWriter$1$ensureJob$2$1).d1q = write$default;
initMetadataForClass(asyncStreamWriter$1$ensureJob$2$1, VOID, VOID, VOID, [AsyncOutputStream], [3, 1, 0]);
initMetadataForLambda(asyncStreamWriter$o$ensureJob$slambda, VOID, VOID, [1]);
initMetadataForClass(asyncStreamWriter$1, VOID, VOID, VOID, VOID, [0, 3]);
initMetadataForInterface(SyncLengthStream, 'SyncLengthStream');
initMetadataForClass(SyncStreamBase, 'SyncStreamBase', SyncStreamBase.h1v, VOID, [AutoCloseable, SyncLengthStream]);
initMetadataForClass(ReadonlySyncStreamBase, 'ReadonlySyncStreamBase');
initMetadataForClass(MemorySyncStreamBase, 'MemorySyncStreamBase', MemorySyncStreamBase.n1v);
initMetadataForInterface(SyncOutputStream, 'SyncOutputStream', VOID, VOID, [AutoCloseable]);
initMetadataForInterface(SyncInputStream, 'SyncInputStream', VOID, VOID, [AutoCloseable]);
initMetadataForInterface(SyncPositionStream, 'SyncPositionStream');
initMetadataForClass(SyncStream, 'SyncStream', VOID, VOID, [AutoCloseable, SyncInputStream, SyncPositionStream, SyncOutputStream, SyncLengthStream]);
initMetadataForClass(SliceSyncStreamBase, 'SliceSyncStreamBase');
//endregion
//region block: init
Companion_instance_0 = new Companion();
//endregion
//region block: exports
export {
  asyncStreamWriter as asyncStreamWriter29xletsdir5u,
  copyTo as copyTo28cahkq2g6hf3,
  readAll as readAllc0x1zv8qqb58,
  readBytesExact as readBytesExactclsk6o5m4pur,
  readBytesUpTo as readBytesUpTo9uslmwqcrcrn,
  readBytesUpTo_0 as readBytesUpTo11h5prrhsqebh,
  readLine as readLine2x7tamdjml2pc,
  readS16LE as readS16LE1wixw67ayq6fo,
  readS32LE as readS32LE1tavmaszt87za,
  readS32 as readS32hadcfcfqfot3,
  readStream as readStream3jngpd9vaczbo,
  readStringz as readStringztu0bw928vdf2,
  readString as readString2uttbyypy7i6f,
  readU16 as readU163oe0yrg3omvh6,
  readU32LE as readU32LE110qshhyjnggz,
  sliceHere as sliceHere36xxn3efrum43,
  sliceStart as sliceStart2elugg4aq9q3w,
  write16LE as write16LEsn3jmb3i02ek,
  write32LE as write32LE1grgxk8hx1y6x,
  writeBytes as writeBytes1cdj8d25o06wj,
  writeShortArrayLE as writeShortArrayLE6etom8wsvr15,
  writeString as writeString1nn4mnpki4ubn,
  write$default as write$default79xrgs2e032z,
  AsyncGetLengthStream as AsyncGetLengthStream219k26r6v6js2,
  AsyncGetPositionStream as AsyncGetPositionStream2f8n5516y4rp7,
  AsyncOutputStream as AsyncOutputStreamkoco1dsjo5gm,
  AsyncStreamBase as AsyncStreamBaseze8sfl15nwf0,
  AsyncStream as AsyncStream2lau9k1bvym4m,
  FastByteArrayInputStream as FastByteArrayInputStream1jjm4ufd0tkh5,
  MemorySyncStreamBase as MemorySyncStreamBase3aid16odb3j0i,
  MemorySyncStream as MemorySyncStreamw5bwb9yy0cam,
  MemorySyncStream_0 as MemorySyncStream1uv927me2xl9s,
  SyncStream as SyncStreamce10l9wq5tt7,
  buffered as buffered29ldm4q6ez2xm,
  get_eof as get_eofaukbsoaf5jcj,
  openAsync_0 as openAsync3viasxxrmd5gg,
  openAsync as openAsync37anb1e84urmo,
  openFastStream as openFastStream2r4uuohoj0wpo,
  openSync as openSync2m3qn99fqytu0,
  readAll_0 as readAll3m5rixujsbctr,
  readAvailable as readAvailablecuh5du6kum51,
  readBytesExact_0 as readBytesExact2w7t0p09xkbt6,
  readBytes_0 as readBytes3l26albs0coyl,
  readF64BE as readF64BEb6rie7pu3y5f,
  readF64LE as readF64LEtuen41849f3m,
  readS16BE as readS16BEajefcznk5l4b,
  readS16LE_0 as readS16LE375f28fer5dhk,
  readS24LE as readS24LEt6r5k1f3n0k8,
  readS32BE_0 as readS32BE1vkzsiuy9pof1,
  readS32LE_0 as readS32LE3aqh95gxznf4n,
  readS64BE as readS64BEb4xwz284o9f2,
  readS64LE as readS64LE1ebpo9xwj4186,
  readS8 as readS81kx01977bwzhe,
  readStream_0 as readStream3s64ivrsklqly,
  readStream_1 as readStreamp6bgvjd275ek,
  readStringz_0 as readStringz3jxaogr49n5ys,
  readStringz_1 as readStringzhw4hjo42cfdw,
  readString_0 as readString20u8kjncp0xlx,
  readString_1 as readString3106ot9qwcir2,
  readU16LE_0 as readU16LE24dnvocwt4zgx,
  readU32LE_0 as readU32LE336f0p4nkvbi,
  readU8 as readU82o15i0epsnmc5,
  skipToAlign as skipToAlignusebk6ndjvs1,
  sliceStart_0 as sliceStart237tx6t7cytus,
  toAsyncStream as toAsyncStream3ow20wz4trxzb,
  toAsync_0 as toAsync26jtc2k5wi4gn,
  toAsync as toAsync2pa2d6thtq8t4,
  toAsync_1 as toAsyncc87ncfb6nti7,
  toByteArray_0 as toByteArray1sz37t46781i3,
  toSyncOrNull as toSyncOrNull2d7etdx5kh7ns,
  toSyncStream as toSyncStream3g0dpktigjx33,
  write32BE as write32BE32zetfyrv09xq,
  write32LE_0 as write32LEi7tyrlzcgsc3,
  write8 as write81fg3kayjaelgo,
  writeBytes_0 as writeBytes3aje3m4oawkwy,
};
//endregion
