import {
  Collection1k04j3hzsbod0 as Collection,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  VOID7hggqo3abtya as VOID,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  HashMap1a0ld5kgwhmhv as HashMap,
  toMutableList20rdgwi7d3cwi as toMutableList,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  Unit_instance104q5opgivhr8 as Unit_instance,
  toMutableMapr5f3w62lv8sk as toMutableMap,
  toMutableSetjdpdbr9jsqq8 as toMutableSet,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  KtMap140uvy3s5zad8 as KtMap,
  isInterface3d6p8outrmvmk as isInterface,
  KtMutableMap1kqeifoi36kpz as KtMutableMap,
  addAll21mdhg523wnoa as addAll,
  KtMutableList1beimitadwkna as KtMutableList,
  AbstractList3ck35puwbp1e9 as AbstractList,
  IndexOutOfBoundsException1qfr429iumro0 as IndexOutOfBoundsException,
  equals2au1ep9vhcato as equals,
  toString1pkumu07cwy4m as toString,
  hashCodeq5arwsb9dgti as hashCode,
  AbstractMutableList192xfq8ycgofs as AbstractMutableList,
  copyToArray2j022khrow2yi as copyToArray,
  get_lastIndex1yw0x4k50k51w as get_lastIndex,
  joinToStringxqcavsxcmh4q as joinToString,
  protoOf180f3jzyo7rfj as protoOf,
  RandomAccess1jbw8sdogqb8x as RandomAccess,
} from './kotlin-kotlin-stdlib.mjs';
import { get_atomicfu$reentrantLock3o926yhldwf1 as get_atomicfu$reentrantLock } from './kotlinx-atomicfu.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class DoubleList {}
function getAt(index) {
  return this.i1(index);
}
function toDoubleArray() {
  var tmp = 0;
  var tmp_0 = this.j1();
  var tmp_1 = new Float64Array(tmp_0);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = this.i1(tmp_2);
    tmp = tmp + 1 | 0;
  }
  return tmp_1;
}
function listIterator() {
  return this.p1(0);
}
function listIterator_0(index) {
  return this.n1(index, this.j1()).h3();
}
function subList(fromIndex, toIndex) {
  return SimpleSubList(fromIndex, toIndex, DoubleList$subList$lambda(this));
}
function contains(element) {
  return containsCheck(this, element);
}
function containsAll(elements) {
  return containsAllCheck(this, elements);
}
function isEmpty() {
  return this.j1() === 0;
}
function iterator() {
  return this.h3();
}
class Mixin {
  constructor(extra) {
    extra = extra === VOID ? null : extra;
    this.m13_1 = extra;
  }
  n13(_set____db54di) {
    this.m13_1 = _set____db54di;
  }
  o13() {
    return this.m13_1;
  }
}
class Property {
  constructor(name, defaultGen) {
    name = name === VOID ? null : name;
    this.p13_1 = name;
    this.q13_1 = defaultGen;
  }
}
class PropertyThis {
  constructor(name, defaultGen) {
    name = name === VOID ? null : name;
    this.r13_1 = name;
    this.s13_1 = defaultGen;
    var tmp = this;
    tmp.t13_1 = Extra$PropertyThis$transform$lambda;
  }
}
class ExtraObject {
  constructor() {
    var tmp = this;
    // Inline function 'kotlinx.atomicfu.locks.reentrantLock' call
    tmp.u13_1 = get_atomicfu$reentrantLock();
    this.v13_1 = HashMap.c9();
    var tmp_0 = this;
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.values.<anonymous>' call
    tmp_0.w13_1 = toMutableList(this.v13_1.m3());
  }
  j1() {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.<get-size>.<anonymous>' call
    return this.v13_1.j1();
  }
  z1() {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.<get-entries>.<anonymous>' call
    // Inline function 'kotlin.collections.associateWith' call
    var this_0 = this.v13_1.l3();
    var result = LinkedHashMap.wc(coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16));
    // Inline function 'kotlin.collections.associateWithTo' call
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.datastructure.ExtraObject.<get-entries>.<anonymous>.<anonymous>' call
      var tmp$ret$0 = this.v13_1.k3(element);
      result.r3(element, tmp$ret$0);
    }
    return toMutableSet(toMutableMap(result).z1());
  }
  l3() {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.<get-keys>.<anonymous>' call
    return toMutableSet(this.v13_1.l3());
  }
  m3() {
    return this.w13_1;
  }
  bj(key) {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.get.<anonymous>' call
    return this.v13_1.k3(key);
  }
  k3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.bj((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
  x13(key, value) {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    this.v13_1.r3(key, value);
    return Unit_instance;
  }
  y13(key) {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.contains.<anonymous>' call
    // Inline function 'kotlin.collections.contains' call
    // Inline function 'kotlin.collections.containsKey' call
    var this_0 = this.v13_1;
    return (isInterface(this_0, KtMap) ? this_0 : THROW_CCE()).i3(key);
  }
  a1() {
    return this.j1() === 0;
  }
  o3() {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    this.v13_1.o3();
    return Unit_instance;
  }
  z13(key) {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.remove.<anonymous>' call
    return this.v13_1.s3(key);
  }
  s3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.z13((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
  a14(from) {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s = from.z1().f1();
    while (_iterator__ex2g4s.g1()) {
      var _destruct__k2r9zo = _iterator__ex2g4s.h1();
      // Inline function 'kotlin.collections.component1' call
      var key = _destruct__k2r9zo.a2();
      // Inline function 'kotlin.collections.component2' call
      var value = _destruct__k2r9zo.b2();
      // Inline function 'kotlin.collections.set' call
      this.v13_1.r3(key, value);
    }
    return Unit_instance;
  }
  t3(from) {
    return this.a14(from);
  }
  b14(key, value) {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    this.v13_1.r3(key, value);
    return Unit_instance;
  }
  r3(key, value) {
    var tmp = (!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE();
    return this.b14(tmp, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  }
  c14(key) {
    // Inline function 'korlibs.datastructure.ExtraObject.lock' call
    // Inline function 'kotlinx.atomicfu.locks.withLock' call
    this.u13_1;
    // Inline function 'korlibs.datastructure.ExtraObject.containsKey.<anonymous>' call
    // Inline function 'kotlin.collections.contains' call
    // Inline function 'kotlin.collections.containsKey' call
    var this_0 = this.v13_1;
    return (isInterface(this_0, KtMap) ? this_0 : THROW_CCE()).i3(key);
  }
  i3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    return this.c14((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
}
class MutableListEx {}
function setAddAll(index, elements, offset, size) {
  // Inline function 'kotlin.math.min' call
  var b = this.j1() - index | 0;
  var setCount = Math.min(size, b);
  this.l14(index, elements, offset, setCount);
  this.m14(elements, offset + setCount | 0, size - setCount | 0);
}
function setAddAll$default(index, elements, offset, size, $super) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? elements.j1() - offset | 0 : size;
  var tmp;
  if ($super === VOID) {
    this.k14(index, elements, offset, size);
    tmp = Unit_instance;
  } else {
    setAddAll(index, elements, offset, size);
    tmp = Unit_instance;
  }
  return tmp;
}
function setAll(index, elements, offset, size) {
  var inductionVariable = 0;
  if (inductionVariable < size)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      this.p3(index + n | 0, elements.i1(offset + n | 0));
    }
     while (inductionVariable < size);
}
function addAll_0(elements, offset, size) {
  var inductionVariable = 0;
  if (inductionVariable < size)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      this.f(elements.i1(offset + n | 0));
    }
     while (inductionVariable < size);
}
function removeToSize(size) {
  this.j14(size, this.j1());
}
class SimpleSubList$1 extends AbstractList {
  static q14($toIndex, $fromIndex, $get, $box) {
    if ($box === VOID)
      $box = {};
    $box.n14_1 = $toIndex;
    $box.o14_1 = $fromIndex;
    $box.p14_1 = $get;
    return this.oj($box);
  }
  j1() {
    return this.n14_1 - this.o14_1 | 0;
  }
  i1(index) {
    if (!(0 <= index ? index < this.j1() : false))
      throw IndexOutOfBoundsException.ze();
    return this.p14_1(index + this.o14_1 | 0);
  }
}
class Fixed {
  constructor(value) {
    this.r14_1 = value;
  }
  s14() {
    return this.r14_1;
  }
  toString() {
    return 'Fixed(value=' + toString(this.r14_1) + ')';
  }
  hashCode() {
    return hashCode(this.r14_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Fixed))
      return false;
    var tmp0_other_with_cast = other instanceof Fixed ? other : THROW_CCE();
    if (!equals(this.r14_1, tmp0_other_with_cast.r14_1))
      return false;
    return true;
  }
}
class FastArrayList extends AbstractMutableList {
  static t14(__array) {
    var $this = this.q7();
    $this.e14_1 = __array;
    return $this;
  }
  static g14() {
    // Inline function 'kotlin.emptyArray' call
    var tmp$ret$0 = [];
    return this.t14(tmp$ret$0);
  }
  static f14(initialCapacity) {
    // Inline function 'kotlin.emptyArray' call
    var tmp$ret$0 = [];
    return this.t14(tmp$ret$0);
  }
  static u14(elements) {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.collections.toTypedArray' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$2 = copyToArray(elements);
    return this.t14(tmp$ret$2);
  }
  p8(minCapacity) {
  }
  j1() {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.e14_1.length;
  }
  i1(index) {
    // Inline function 'korlibs.datastructure.get' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.e14_1[rangeCheck(this, index)];
  }
  p3(index, element) {
    rangeCheck(this, index);
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.datastructure.get' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var this_0 = this.e14_1[index];
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.FastArrayList.set.<anonymous>' call
    // Inline function 'korlibs.datastructure.set' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    this.e14_1[index] = element;
    return this_0;
  }
  f(element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.e14_1.push(element);
    this.d7_1 = this.d7_1 + 1 | 0;
    return true;
  }
  r7(index, element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.e14_1.splice(insertionRangeCheck(this, index), 0, element);
    this.d7_1 = this.d7_1 + 1 | 0;
  }
  v14(elements) {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$4 = elements.e14_1;
    return _addAll(this, tmp$ret$4);
  }
  k1(elements) {
    // Inline function 'kotlin.collections.toTypedArray' call
    var tmp$ret$0 = copyToArray(elements);
    return _addAll(this, tmp$ret$0);
  }
  q3(index) {
    rangeCheck(this, index);
    this.d7_1 = this.d7_1 + 1 | 0;
    var tmp;
    if (index === 0) {
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = this.e14_1.shift();
    } else if (index === get_lastIndex(this)) {
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = this.e14_1.pop();
    } else {
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = this.e14_1.splice(index, 1)[0];
    }
    return tmp;
  }
  n3(element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.e14_1;
    var inductionVariable = 0;
    var last = array.length;
    if (inductionVariable < last)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.datastructure.get' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp$ret$4 = array[index];
        if (equals(tmp$ret$4, element)) {
          array.splice(index, 1);
          this.d7_1 = this.d7_1 + 1 | 0;
          return true;
        }
      }
       while (inductionVariable < last);
    return false;
  }
  j14(fromIndex, toIndex) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.e14_1.splice(fromIndex, toIndex - fromIndex | 0);
    this.d7_1 = this.d7_1 + 1 | 0;
  }
  o3() {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.e14_1.length = 0;
    this.d7_1 = this.d7_1 + 1 | 0;
  }
  q1(element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.e14_1.indexOf(element);
  }
  toString() {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$4 = this.e14_1;
    return '[' + joinToString(tmp$ret$4, ', ') + ']';
  }
  q8() {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.e14_1.concat();
  }
  toArray() {
    return this.q8();
  }
}
//endregion
function DoubleList$subList$lambda(this$0) {
  return function (it) {
    return this$0.i1(it);
  };
}
function Extra$PropertyThis$transform$lambda(_this__u8e3s4, it) {
  return it;
}
function getExtra(_this__u8e3s4, name) {
  var tmp3_safe_receiver = _this__u8e3s4.o13();
  return tmp3_safe_receiver == null ? null : tmp3_safe_receiver.bj(name);
}
function setExtra(_this__u8e3s4, name, value) {
  if (_this__u8e3s4.o13() == null) {
    if (value == null)
      return Unit_instance;
    _this__u8e3s4.n13(ExtraTypeCreate());
  }
  var tmp4_safe_receiver = _this__u8e3s4.o13();
  if (tmp4_safe_receiver == null)
    null;
  else {
    tmp4_safe_receiver.x13(name, value);
  }
}
function hasExtra(_this__u8e3s4, name) {
  var tmp2_safe_receiver = _this__u8e3s4.o13();
  return (tmp2_safe_receiver == null ? null : tmp2_safe_receiver.y13(name)) === true;
}
function getExtraTyped(_this__u8e3s4, name) {
  var tmp = _this__u8e3s4.o13();
  var tmp1_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
  return tmp1_safe_receiver == null ? null : tmp1_safe_receiver.k3(name);
}
function ExtraTypeCreate() {
  return new ExtraObject();
}
function fastArrayListOf(values) {
  // Inline function 'kotlin.also' call
  var this_0 = FastArrayList.f14(values.length);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.fastArrayListOf.<anonymous>' call
  addAll(this_0, values);
  return this_0;
}
function toFastList(_this__u8e3s4) {
  // Inline function 'kotlin.also' call
  var this_0 = FastArrayList.f14(_this__u8e3s4.j1());
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.toFastList.<anonymous>' call
  // Inline function 'korlibs.datastructure.fastForEach' call
  var n = 0;
  while (n < _this__u8e3s4.j1()) {
    // Inline function 'korlibs.datastructure.toFastList.<anonymous>.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var it = _this__u8e3s4.i1(_unary__edvuaz);
    this_0.f(it);
  }
  return this_0;
}
function toFastList_0(_this__u8e3s4) {
  // Inline function 'kotlin.also' call
  var this_0 = FastArrayList.f14(_this__u8e3s4.length);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.toFastList.<anonymous>' call
  // Inline function 'korlibs.datastructure.fastForEach' call
  var n = 0;
  while (n < _this__u8e3s4.length) {
    // Inline function 'korlibs.datastructure.toFastList.<anonymous>.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var it = _this__u8e3s4[_unary__edvuaz];
    this_0.f(it);
  }
  return this_0;
}
function toFastList_1(_this__u8e3s4, out) {
  out = out === VOID ? FastArrayList.g14() : out;
  out.h14(0, _this__u8e3s4);
  out.i14(_this__u8e3s4.j1());
  return out;
}
function SimpleSubList(fromIndex, toIndex, get) {
  return SimpleSubList$1.q14(toIndex, fromIndex, get);
}
function containsCheck(collection, element) {
  var _iterator__ex2g4s = collection.f1();
  while (_iterator__ex2g4s.g1()) {
    var v = _iterator__ex2g4s.h1();
    if (equals(v, element))
      return true;
  }
  return false;
}
function containsAllCheck(collection, elements) {
  var elementsMap = toMutableSet(elements);
  var _iterator__ex2g4s = collection.f1();
  while (_iterator__ex2g4s.g1()) {
    var v = _iterator__ex2g4s.h1();
    elementsMap.n3(v);
  }
  return elementsMap.a1();
}
function Resourceable(value) {
  return new Fixed(value);
}
function _addAll($this, elements) {
  // Inline function 'kotlin.collections.isEmpty' call
  if (elements.length === 0)
    return false;
  // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$3 = $this.e14_1;
  (function () {
    var $externalVarargReceiverTmp = tmp$ret$3;
    return $externalVarargReceiverTmp.push.apply($externalVarargReceiverTmp, [].slice.call(elements.slice()));
  }.call(this));
  $this.d7_1 = $this.d7_1 + 1 | 0;
  return true;
}
function rangeCheck($this, index) {
  if (index < 0 || index >= $this.j1())
    throw IndexOutOfBoundsException.x1('index: ' + index + ', size: ' + $this.j1());
  return index;
}
function insertionRangeCheck($this, index) {
  if (index < 0 || index > $this.j1())
    throw IndexOutOfBoundsException.x1('index: ' + index + ', size: ' + $this.j1());
  return index;
}
//region block: post-declaration
initMetadataForInterface(DoubleList, 'DoubleList', VOID, VOID, [Collection]);
initMetadataForClass(Mixin, 'Mixin', Mixin);
initMetadataForClass(Property, 'Property');
initMetadataForClass(PropertyThis, 'PropertyThis');
initMetadataForClass(ExtraObject, 'ExtraObject', ExtraObject, VOID, [KtMutableMap]);
initMetadataForInterface(MutableListEx, 'MutableListEx', VOID, VOID, [KtMutableList]);
initMetadataForClass(SimpleSubList$1);
initMetadataForClass(Fixed, 'Fixed', VOID, VOID, VOID, [0]);
protoOf(FastArrayList).m14 = addAll_0;
protoOf(FastArrayList).k14 = setAddAll;
protoOf(FastArrayList).h14 = setAddAll$default;
protoOf(FastArrayList).l14 = setAll;
protoOf(FastArrayList).i14 = removeToSize;
initMetadataForClass(FastArrayList, 'FastArrayList', FastArrayList.g14, VOID, [AbstractMutableList, MutableListEx, RandomAccess]);
//endregion
//region block: exports
export {
  containsAll as containsAll1oa5i6t9xmbdv,
  contains as containsteg8664aaglk,
  getAt as getAt237u54ctbxwvd,
  isEmpty as isEmptyrqh4vz39e0v6,
  iterator as iterator300ls4j24zqzz,
  listIterator as listIterator247sl90ooi4qv,
  listIterator_0 as listIterator1a2ckgeamf7jc,
  subList as subList28p829u9d5w23,
  DoubleList as DoubleList2honu8rif2roh,
  Mixin as Mixin3lmkm5hum2vdj,
  PropertyThis as PropertyThis2r0hbk1cs09wj,
  Property as Property1m5vulkepgwzd,
  FastArrayList as FastArrayListw5fr1wlp4slq,
  fastArrayListOf as fastArrayListOf1rdodhrpn0q8y,
  getExtraTyped as getExtraTyped8zexqxz5xxli,
  getExtra as getExtra307y0o9pd6mp3,
  hasExtra as hasExtra1969mplo13qgb,
  setExtra as setExtra314k0z6q35eku,
  toFastList_1 as toFastList1btvvxvv57pw,
  toFastList as toFastList32ukjb68l6wd7,
  toFastList_0 as toFastListzo2jtkrx0z5y,
  Resourceable as Resourceable38whwgli3ts8g,
};
//endregion
