import {
  numberToChar93r9buh19yek as numberToChar,
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  Char__toInt_impl_vasixd1g1ubcha2b2yr as Char__toInt_impl_vasixd,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  toByte4i43936u611k as toByte,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  StringBuildermazzzhj6kkai as StringBuilder,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  VOID7hggqo3abtya as VOID,
  until1jbpn0z3f8lbg as until,
  step18s9qzr5xwxat as step,
  maxOrNull2lr3mpfpypi46 as maxOrNull,
  Char__plus_impl_qi7pgjue8oeapgr66d as Char__plus_impl_qi7pgj,
  Char19o2r8palgjof as Char,
  fill3lmv1pckd4inv as fill,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  charArray2ujmm1qusno00 as charArray,
  concatToString2syawgu50khxi as concatToString,
  copyOfRange3alro60z4hhf8 as copyOfRange,
  ArrayList3it5z8td81qkl as ArrayList,
  coerceIn10f36k81le1mm as coerceIn,
  StringCompanionObject_instance2odz3s3zbrixa as StringCompanionObject_instance,
  substring2pnd9wgs9hwtx as substring,
  Regexxgw0gjiagf4z as Regex,
  lazy2hsh8ze7j6ikd as lazy,
  copyOf3rutauicler23 as copyOf,
  copyOfRange1v9olvyjr7c0r as copyOfRange_0,
  contentHashCode33l4yznfe2pz3 as contentHashCode,
  contentEquals2jbsaphs7gxql as contentEquals,
  startsWith1bgirhbedtv2y as startsWith,
  endsWith278181ii8uuo as endsWith,
  toString26mv3gzfo7fn as toString_0,
  toInt5qdj874w69jh as toInt,
  substringAfter1hku067gwr5ve as substringAfter,
  contains2el4s70rdq4ld as contains,
  substringBefore3n7kj60w69hju as substringBefore,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  protoOf180f3jzyo7rfj as protoOf,
  coerceAtMost322komnqp70ag as coerceAtMost,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isNumberiramasdbon0i as isNumber,
  numberToLong1a4cndvg6c52s as numberToLong,
  _ULong___init__impl__c78o9k3sq3egh1wtk64 as _ULong___init__impl__c78o9k,
  toString1ced4mxyj2b43 as toString_1,
  _UInt___init__impl__l7qpdl2soskspn8hzmt as _UInt___init__impl__l7qpdl,
  toString10m9tj9xt75j5 as toString_2,
  toIntOrNull3w2d066r9pvwm as toIntOrNull,
} from './kotlin-kotlin-stdlib.mjs';
import { ByteArrayBuilderyrflhqjz64ju as ByteArrayBuilder } from './korlibs-korlibs-platform.mjs';
import { get_atomicfu$reentrantLock3o926yhldwf1 as get_atomicfu$reentrantLock } from './kotlinx-atomicfu.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Companion {
  wx(_this__u8e3s4, codePoint) {
    if ((55296 <= codePoint ? codePoint <= 57343 : false) || codePoint > 65535) {
      var U0 = codePoint - 65536 | 0;
      var hs = extract(U0, 10, 10);
      var ls = extract(U0, 0, 10);
      _this__u8e3s4.jc(numberToChar(55296 | hs));
      _this__u8e3s4.jc(numberToChar(56320 | ls));
    } else {
      _this__u8e3s4.jc(numberToChar(codePoint));
    }
  }
}
class Charset {
  constructor(name) {
    this.xx_1 = name;
  }
  yx(nbytes) {
    return imul(nbytes, 2);
  }
  zx(nchars) {
    return imul(nchars, 2);
  }
}
class UTC8CharsetBase extends Charset {
  yx(nbytes) {
    return imul(nbytes, 2);
  }
  zx(nchars) {
    return imul(nchars, 2);
  }
  ay(out, src, start, end) {
    // Inline function 'korlibs.io.lang.Companion.decodeCodePoints' call
    var highSurrogate = 0;
    var inductionVariable = start;
    if (inductionVariable < end)
      loop: do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var char = Char__toInt_impl_vasixd(charSequenceGet(src, n));
        var tmp;
        if (55296 <= char ? char <= 57343 : false) {
          var tmp_0;
          switch (extract(char, 10, 6)) {
            case 54:
              highSurrogate = char & 1023;
              continue loop;
            case 55:
              tmp_0 = 65536 + (highSurrogate << 10 | char & 1023) | 0;
              break;
            default:
              var message = 'Unknown ' + char;
              throw IllegalStateException.m5(toString(message));
          }
          tmp = tmp_0;
        } else {
          tmp = char;
        }
        var codePoint = tmp;
        // Inline function 'korlibs.io.lang.UTC8CharsetBase.encode.<anonymous>' call
        if ((codePoint & -128) === 0) {
          // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
          var v = toByte(codePoint);
          out.qv(v);
        } else {
          if ((codePoint & -2048) === 0) {
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var v_0 = toByte(codePoint >> 6 & 31 | 192);
            out.qv(v_0);
          } else if ((codePoint & -65536) === 0) {
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var v_1 = toByte(codePoint >> 12 & 15 | 224);
            out.qv(v_1);
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var v_2 = toByte(createByte(this, codePoint, 6));
            out.qv(v_2);
          } else if ((codePoint & -2097152) === 0) {
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var v_3 = toByte(codePoint >> 18 & 7 | 240);
            out.qv(v_3);
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var v_4 = toByte(createByte(this, codePoint, 12));
            out.qv(v_4);
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var v_5 = toByte(createByte(this, codePoint, 6));
            out.qv(v_5);
          }
          // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
          var v_6 = toByte(codePoint & 63 | 128);
          out.qv(v_6);
        }
      }
       while (inductionVariable < end);
  }
  by(out, src, start, end) {
    if (start < 0 || start > src.length || (end < 0 || end > src.length)) {
      // Inline function 'kotlin.error' call
      var message = 'Out of bounds';
      throw IllegalStateException.m5(toString(message));
    }
    var i = start;
    loop: while (i < end) {
      var c = src[i] & 255;
      var tmp4_subject = c >> 4;
      if (0 <= tmp4_subject ? tmp4_subject <= 7 : false) {
        Companion_instance.wx(out, c);
        i = i + 1 | 0;
      } else if (12 <= tmp4_subject ? tmp4_subject <= 13 : false) {
        if ((i + 1 | 0) >= end)
          break loop;
        Companion_instance.wx(out, (c & 31) << 6 | src[i + 1 | 0] & 63);
        i = i + 2 | 0;
      } else if (tmp4_subject === 14) {
        if ((i + 2 | 0) >= end)
          break loop;
        Companion_instance.wx(out, (c & 15) << 12 | (src[i + 1 | 0] & 63) << 6 | src[i + 2 | 0] & 63);
        i = i + 3 | 0;
      } else if (tmp4_subject === 15) {
        if ((i + 3 | 0) >= end)
          break loop;
        Companion_instance.wx(out, insert(insert(insert(insert(0, extract(src[i + 0 | 0], 0, 3), 18, 3), extract(src[i + 1 | 0], 0, 6), 12, 6), extract(src[i + 2 | 0], 0, 6), 6, 6), extract(src[i + 3 | 0], 0, 6), 0, 6));
        i = i + 4 | 0;
      } else {
        out.jc(_Char___init__impl__6a9atx(65533));
        i = i + 1 | 0;
      }
    }
    return i - start | 0;
  }
}
class BaseSingleByteCharset extends Charset {
  yx(nbytes) {
    return nbytes;
  }
  zx(nchars) {
    return nchars;
  }
}
class SingleByteCharset extends BaseSingleByteCharset {
  constructor(name, conv) {
    super(name);
    this.ey_1 = conv;
    var tmp = this;
    var tmp5_safe_receiver = maxOrNull(this.ey_1);
    var tmp_0;
    var tmp_1 = tmp5_safe_receiver;
    if ((tmp_1 == null ? null : new Char(tmp_1)) == null) {
      tmp_0 = null;
    } else {
      tmp_0 = Char__plus_impl_qi7pgj(tmp5_safe_receiver, 1);
    }
    var tmp6_safe_receiver = tmp_0;
    var tmp_2;
    var tmp_3 = tmp6_safe_receiver;
    if ((tmp_3 == null ? null : new Char(tmp_3)) == null) {
      tmp_2 = null;
    } else {
      // Inline function 'kotlin.code' call
      tmp_2 = Char__toInt_impl_vasixd(tmp6_safe_receiver);
    }
    var tmp0_elvis_lhs = tmp_2;
    tmp.fy_1 = tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs;
    var tmp_4 = this;
    // Inline function 'kotlin.also' call
    var this_0 = new Int8Array(this.fy_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.lang.SingleByteCharset.v.<anonymous>' call
    fill(this_0, 63);
    var inductionVariable = 0;
    var last = charSequenceLength(this.ey_1) - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.code' call
        var this_1 = charSequenceGet(this.ey_1, n);
        this_0[Char__toInt_impl_vasixd(this_1)] = toByte(n);
      }
       while (inductionVariable <= last);
    tmp_4.gy_1 = this_0;
  }
  ay(out, src, start, end) {
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.code' call
        var this_0 = charSequenceGet(src, n);
        var c = Char__toInt_impl_vasixd(this_0);
        // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
        // Inline function 'kotlin.collections.getOrElse' call
        var this_1 = this.gy_1;
        // Inline function 'kotlin.contracts.contract' call
        var tmp;
        if (0 <= c ? c <= (this_1.length - 1 | 0) : false) {
          tmp = this_1[c];
        } else {
          // Inline function 'korlibs.io.lang.SingleByteCharset.encode.<anonymous>' call
          tmp = 63;
        }
        var v = tmp;
        out.qv(v);
      }
       while (inductionVariable < end);
  }
  by(out, src, start, end) {
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out.jc(charSequenceGet(this.ey_1, src[n] & 255));
      }
       while (inductionVariable < end);
    return end - start | 0;
  }
}
class ISO_8859_1 extends SingleByteCharset {
  constructor() {
    ISO_8859_1_instance = null;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.lang.ISO_8859_1.<init>.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < 256)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this_0.jc(numberToChar(n));
      }
       while (inductionVariable < 256);
    super('ISO-8859-1', this_0.toString());
    ISO_8859_1_instance = this;
  }
}
class UTF16Charset extends Charset {
  constructor(le) {
    super('UTF-16-' + (le ? 'LE' : 'BE'));
    this.jy_1 = le;
  }
  yx(nbytes) {
    return imul(nbytes, 2);
  }
  zx(nchars) {
    return imul(nchars, 2);
  }
  by(out, src, start, end) {
    var consumed = 0;
    var progression = step(until(start, end), 2);
    var inductionVariable = progression.k2_1;
    var last = progression.l2_1;
    var step_0 = progression.m2_1;
    if (step_0 > 0 && inductionVariable <= last || (step_0 < 0 && last <= inductionVariable))
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        var char = numberToChar(getS16(src, n, this.jy_1));
        out.jc(char);
        consumed = consumed + 2 | 0;
      }
       while (!(n === last));
    return consumed;
  }
  ay(out, src, start, end) {
    var temp = new Int8Array(2);
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.code' call
        var this_0 = charSequenceGet(src, n);
        var tmp$ret$0 = Char__toInt_impl_vasixd(this_0);
        set16(temp, 0, tmp$ret$0, this.jy_1);
        out.pv(temp);
      }
       while (inductionVariable < end);
  }
}
class ASCII extends SingleByteCharset {
  constructor() {
    ASCII_instance = null;
    var tmp = 0;
    var tmp_0 = charArray(128);
    while (tmp < 128) {
      var tmp_1 = tmp;
      tmp_0[tmp_1] = numberToChar(tmp_1);
      tmp = tmp + 1 | 0;
    }
    super('ASCII', concatToString(tmp_0) + '\xC7\xFC\xE9\xE2\xE4\xE0\xE5\xE7\xEA\xEB\xE8\xEF\xEE\xEC\xC4\xC5\xC9\xE6\xC6\xF4\xF6\xF2\xFB\xF9\xFF\xD6\xDC\xF8\xA3\xD8\xD7\u0192\xE1\xED\xF3\xFA\xF1\xD1\xAA\xBA\xBF\xAE\xAC\xBD\xBC\xA1\xAB\xBB\u2591\u2592\u2593\u2502\u2524\xC1\xC2\xC0\xA9\u2563\u2551\u2557\u255D\xA2\xA5\u2510\u2514\u2534\u252C\u251C\u2500\u253C\xE3\xC3\u255A\u2554\u2569\u2566\u2560\u2550\u256C\xA4\xF0\xD0\xCA\xCB\xC8\u0131\xCD\xCE\xCF\u2518\u250C\u2588\u2584\xA6\xCC\u2580\xD3\xDF\xD4\xD2\xF5\xD5\xB5\xFE\xDE\xDA\xDB\xD9\xFD\xDD\xAF\xB4\xAD\xB1\u2017\xBE\xB6\xA7\xF7\xB8\xB0\xA8\xB7\xB9\xB3\xB2\u25A0\xA0');
    ASCII_instance = this;
  }
}
class Charsets {
  ky() {
    return get_UTF8();
  }
  ly() {
    return get_LATIN1();
  }
}
class WStringReader {
  constructor(str, position) {
    position = position === VOID ? 0 : position;
    this.my_1 = str;
    this.ny_1 = position;
  }
  oy() {
    return this.my_1.a() - this.ny_1 | 0;
  }
  ty() {
    return this.ny_1 >= this.my_1.a();
  }
  uy() {
    return !this.ty();
  }
  vy(offset) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.io.lang.WString.getOrElse' call
      var this_0 = this.my_1;
      var index = this.ny_1 + offset | 0;
      if (index < 0 || index >= this_0.a()) {
        // Inline function 'korlibs.io.lang.WStringReader.peek.<anonymous>' call
        tmp$ret$1 = _WChar___init__impl__4vbl4k(0);
        break $l$block;
      }
      tmp$ret$1 = this_0.wy(index);
    }
    return tmp$ret$1;
  }
  xy(offset, $super) {
    offset = offset === VOID ? 0 : offset;
    return $super === VOID ? this.vy(offset) : $super.vy.call(this, offset).yy_1;
  }
  zy(count) {
    this.ny_1 = this.ny_1 + count | 0;
  }
  az(offset, len) {
    return substr_1(this.my_1, this.ny_1 + offset | 0, len);
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    var tmp = this;
    // Inline function 'kotlin.intArrayOf' call
    var tmp$ret$0 = new Int32Array([]);
    tmp.bz_1 = new WString(tmp$ret$0, '');
  }
  cz(codePoints) {
    return this.dz(codePoints);
  }
  ez(string) {
    return this.fz(string);
  }
  fz(string) {
    if (string === '')
      return this.bz_1;
    var codePoints = new Int32Array(string.length);
    // Inline function 'korlibs.io.lang.forEachCodePoint' call
    var string_0 = string;
    var m = 0;
    var n = 0;
    while (n < string_0.length) {
      // Inline function 'kotlin.code' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var this_0 = charSequenceGet(string_0, _unary__edvuaz);
      var value = Char__toInt_impl_vasixd(this_0);
      var error = false;
      if ((value & 63488) === 55296 && n < string_0.length) {
        // Inline function 'kotlin.code' call
        var _unary__edvuaz_0 = n;
        n = _unary__edvuaz_0 + 1 | 0;
        var this_1 = charSequenceGet(string_0, _unary__edvuaz_0);
        var extra = Char__toInt_impl_vasixd(this_1);
        if (!((extra & 64512) === 56320)) {
          n = n - 1 | 0;
          error = true;
        } else {
          var dataHigh = value & 1023;
          var dataLow = extra & 1023;
          value = (dataLow | dataHigh << 10) + 65536 | 0;
        }
      }
      // Inline function 'korlibs.io.lang.Companion.fromString.<anonymous>' call
      var _unary__edvuaz_1 = m;
      m = _unary__edvuaz_1 + 1 | 0;
      codePoints[_unary__edvuaz_1] = value;
    }
    var length = m;
    return new WString(copyOf(codePoints, length), string);
  }
  dz(codePoints) {
    // Inline function 'kotlin.collections.count' call
    var count = 0;
    var inductionVariable = 0;
    var last = codePoints.length;
    while (inductionVariable < last) {
      var element = codePoints[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.io.lang.Companion.fromCodePoints.<anonymous>' call
      if (element >= 65536) {
        count = count + 1 | 0;
      }
    }
    var surrogateCount = count;
    var out = StringBuilder.nc(codePoints.length + surrogateCount | 0);
    var inductionVariable_0 = 0;
    var last_0 = codePoints.length;
    while (inductionVariable_0 < last_0) {
      var codePoint = codePoints[inductionVariable_0];
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      if (codePoint > 65535) {
        var U1 = codePoint - 65536 | 0;
        var W1 = 55296 | (U1 >>> 10 | 0) & 1023;
        var W2 = 56320 | (U1 >>> 0 | 0) & 1023;
        out.jc(numberToChar(W1));
        out.jc(numberToChar(W2));
      } else {
        out.jc(numberToChar(codePoint));
      }
    }
    return new WString(codePoints, out.toString());
  }
}
class WString {
  constructor(codePoints, string) {
    Companion_getInstance_0();
    this.py_1 = codePoints;
    this.qy_1 = string;
    this.ry_1 = 0;
    this.sy_1 = false;
  }
  a() {
    return this.py_1.length;
  }
  wy(index) {
    return _WChar___init__impl__4vbl4k(this.py_1[index]);
  }
  uh(startIndex, endIndex) {
    var tmp = copyOfRange_0(this.py_1, startIndex, endIndex);
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = this.qy_1.substring(startIndex, endIndex);
    return new WString(tmp, tmp$ret$1);
  }
  hashCode() {
    if (!this.sy_1) {
      this.sy_1 = true;
      this.ry_1 = contentHashCode(this.py_1);
    }
    return this.ry_1;
  }
  equals(other) {
    var tmp;
    if (other instanceof WString) {
      tmp = contentEquals(this.py_1, other.py_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  toString() {
    return this.qy_1;
  }
}
class INDENTS {
  constructor() {
    INDENTS_instance = this;
    this.gz_1 = new Object();
    this.hz_1 = StringBuilder.nc(1024);
    this.iz_1 = ArrayList.w(1024);
  }
  i1(index) {
    if (this.iz_1.j1() <= index) {
      // Inline function 'kotlinx.atomicfu.locks.synchronized' call
      this.gz_1;
      while (INDENTS_getInstance().iz_1.j1() <= index) {
        INDENTS_getInstance().iz_1.f(INDENTS_getInstance().hz_1.toString());
        INDENTS_getInstance().hz_1.jc(_Char___init__impl__6a9atx(9));
      }
    }
    return this.iz_1.i1(index);
  }
}
class SimpleStrReader {}
function skip(count) {
  // Inline function 'kotlin.repeat' call
  // Inline function 'kotlin.contracts.contract' call
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var index = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.util.SimpleStrReader.skip.<anonymous>' call
      this.kz();
    }
     while (inductionVariable < count);
  return this;
}
function skip$default(count, $super) {
  count = count === VOID ? 1 : count;
  return $super === VOID ? this.oz(count) : skip(count);
}
function toStringContext() {
  return 'SimpleStrReader(pos=' + this.lz() + ", peek='" + toString_0(this.jz()) + "')";
}
class Impl {
  constructor(str, pos) {
    this.mz_1 = str;
    this.nz_1 = pos;
  }
  lz() {
    return this.nz_1;
  }
  uy() {
    return this.nz_1 < this.mz_1.length;
  }
  kz() {
    // Inline function 'kotlin.also' call
    var this_0 = new Char(this.jz());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.util.Impl.readChar.<anonymous>' call
    this_0.t2_1;
    this.nz_1 = this.nz_1 + 1 | 0;
    return this_0.t2_1;
  }
  jz() {
    // Inline function 'kotlin.text.getOrElse' call
    var this_0 = this.mz_1;
    var index = this.nz_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (0 <= index ? index <= (charSequenceLength(this_0) - 1 | 0) : false) {
      tmp = charSequenceGet(this_0, index);
    } else {
      // Inline function 'korlibs.util.Impl.peekChar.<anonymous>' call
      tmp = _Char___init__impl__6a9atx(0);
    }
    return tmp;
  }
  oz(count) {
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.util.Impl.skip.<anonymous>' call
    this.nz_1 = this.nz_1 + count | 0;
    return this;
  }
  toString() {
    return "Impl(str='" + this.mz_1 + "', pos=" + this.nz_1 + ')';
  }
  qz() {
    var tmp = this.nz_1;
    // Inline function 'kotlin.text.substring' call
    var this_0 = this.mz_1;
    var startIndex = coerceAtMost(this.nz_1, this.mz_1.length);
    var endIndex = coerceAtMost(this.nz_1 + 10 | 0, this.mz_1.length);
    // Inline function 'kotlin.js.asDynamic' call
    return 'SimpleStrReader(pos=' + tmp + ", around='" + this_0.substring(startIndex, endIndex) + "')";
  }
}
class Companion_1 {
  rz(str, pos) {
    return new Impl(str, pos);
  }
  sz(str, pos, $super) {
    pos = pos === VOID ? 0 : pos;
    return $super === VOID ? this.rz(str, pos) : $super.rz.call(this, str, pos);
  }
}
//endregion
var CHARSET_PROVIDERS;
var CHARSET_PROVIDERS_LOCK;
function get_UTF8() {
  _init_properties_Charset_kt__c47y1m();
  return UTF8;
}
var UTF8;
function get_LATIN1() {
  _init_properties_Charset_kt__c47y1m();
  return LATIN1;
}
var LATIN1;
var UTF16_LE;
function get_UTF16_BE() {
  _init_properties_Charset_kt__c47y1m();
  return UTF16_BE;
}
var UTF16_BE;
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
function createByte($this, codePoint, shift) {
  return codePoint >> shift & 63 | 128;
}
var ISO_8859_1_instance;
function ISO_8859_1_getInstance() {
  if (ISO_8859_1_instance === VOID)
    new ISO_8859_1();
  return ISO_8859_1_instance;
}
function toString_3(_this__u8e3s4, charset, start, end) {
  start = start === VOID ? 0 : start;
  end = end === VOID ? _this__u8e3s4.length : end;
  _init_properties_Charset_kt__c47y1m();
  var out = StringBuilder.nc(charset.yx(end - start | 0));
  charset.by(out, _this__u8e3s4, start, end);
  return out.toString();
}
var ASCII_instance;
function ASCII_getInstance() {
  if (ASCII_instance === VOID)
    new ASCII();
  return ASCII_instance;
}
function toByteArray(_this__u8e3s4, charset, start, end) {
  charset = charset === VOID ? get_UTF8() : charset;
  start = start === VOID ? 0 : start;
  end = end === VOID ? _this__u8e3s4.length : end;
  _init_properties_Charset_kt__c47y1m();
  var out = ByteArrayBuilder.mv(charset.zx(end - start | 0));
  charset.ay(out, _this__u8e3s4, start, end);
  return out.rv();
}
var Charsets_instance;
function Charsets_getInstance() {
  return Charsets_instance;
}
function readStringz(_this__u8e3s4, o, size, charset) {
  charset = charset === VOID ? get_UTF8() : charset;
  _init_properties_Charset_kt__c47y1m();
  var idx = o;
  // Inline function 'kotlin.math.min' call
  var a = _this__u8e3s4.length;
  var b = o + size | 0;
  var stop = Math.min(a, b);
  $l$loop: while (idx < stop && _this__u8e3s4[idx] !== 0) {
    idx = idx + 1 | 0;
  }
  return toString_3(copyOfRange(_this__u8e3s4, o, idx), charset);
}
var properties_initialized_Charset_kt_dajgec;
function _init_properties_Charset_kt__c47y1m() {
  if (!properties_initialized_Charset_kt_dajgec) {
    properties_initialized_Charset_kt_dajgec = true;
    // Inline function 'kotlin.collections.arrayListOf' call
    CHARSET_PROVIDERS = ArrayList.m1();
    // Inline function 'kotlinx.atomicfu.locks.reentrantLock' call
    CHARSET_PROVIDERS_LOCK = get_atomicfu$reentrantLock();
    UTF8 = new UTC8CharsetBase('UTF-8');
    LATIN1 = ISO_8859_1_getInstance();
    UTF16_LE = new UTF16Charset(true);
    UTF16_BE = new UTF16Charset(false);
  }
}
var replaceNonPrintableCharactersRegex$delegate;
function substr(_this__u8e3s4, start, length) {
  _init_properties_StringExt_kt__eg42u6();
  var low = coerceIn(start >= 0 ? start : _this__u8e3s4.length + start | 0, 0, _this__u8e3s4.length);
  var high = coerceIn(length >= 0 ? low + length | 0 : _this__u8e3s4.length + length | 0, 0, _this__u8e3s4.length);
  var tmp;
  if (high >= low) {
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp = _this__u8e3s4.substring(low, high);
  } else {
    tmp = '';
  }
  return tmp;
}
function substringEquals(_this__u8e3s4, a, aIndex, b, bIndex, count) {
  _init_properties_StringExt_kt__eg42u6();
  if (count === 0)
    return true;
  if (aIndex < 0 || bIndex < 0)
    return false;
  if ((aIndex + count | 0) > a.length)
    return false;
  if ((bIndex + count | 0) > b.length)
    return false;
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      if (!(charSequenceGet(a, aIndex + n | 0) === charSequenceGet(b, bIndex + n | 0)))
        return false;
    }
     while (inductionVariable < count);
  return true;
}
function substr_0(_this__u8e3s4, start) {
  _init_properties_StringExt_kt__eg42u6();
  return substr(_this__u8e3s4, start, _this__u8e3s4.length);
}
function fromIntArray(_this__u8e3s4, arrays, offset, size) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? arrays.length - offset | 0 : size;
  _init_properties_StringExt_kt__eg42u6();
  return invoke(StringCompanionObject_instance, arrays, offset, size);
}
function invoke(_this__u8e3s4, arrays, offset, size) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? arrays.length - offset | 0 : size;
  _init_properties_StringExt_kt__eg42u6();
  var sb = StringBuilder.nc(size);
  var inductionVariable = offset;
  var last = offset + size | 0;
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      sb.jc(numberToChar(arrays[n]));
    }
     while (inductionVariable < last);
  return sb.toString();
}
function splitInChunks(_this__u8e3s4, size) {
  _init_properties_StringExt_kt__eg42u6();
  // Inline function 'kotlin.collections.arrayListOf' call
  var out = ArrayList.m1();
  var pos = 0;
  while (pos < _this__u8e3s4.length) {
    // Inline function 'kotlin.collections.plusAssign' call
    // Inline function 'kotlin.text.substring' call
    var startIndex = pos;
    // Inline function 'kotlin.math.min' call
    var a = _this__u8e3s4.length;
    var b = pos + size | 0;
    var endIndex = Math.min(a, b);
    // Inline function 'kotlin.js.asDynamic' call
    var element = _this__u8e3s4.substring(startIndex, endIndex);
    out.f(element);
    pos = pos + size | 0;
  }
  return out;
}
function splitKeep(_this__u8e3s4, regex) {
  _init_properties_StringExt_kt__eg42u6();
  var str = _this__u8e3s4;
  // Inline function 'kotlin.collections.arrayListOf' call
  var out = ArrayList.m1();
  var lastPos = 0;
  var _iterator__ex2g4s = regex.ri(_this__u8e3s4).f1();
  while (_iterator__ex2g4s.g1()) {
    var part = _iterator__ex2g4s.h1();
    var prange = part.ui();
    if (!(lastPos === prange.b1())) {
      // Inline function 'kotlin.collections.plusAssign' call
      // Inline function 'kotlin.text.substring' call
      var startIndex = lastPos;
      var endIndex = prange.b1();
      // Inline function 'kotlin.js.asDynamic' call
      var element = str.substring(startIndex, endIndex);
      out.f(element);
    }
    // Inline function 'kotlin.collections.plusAssign' call
    var element_0 = substring(str, prange);
    out.f(element_0);
    lastPos = prange.c1() + 1 | 0;
  }
  if (!(lastPos === str.length)) {
    // Inline function 'kotlin.collections.plusAssign' call
    // Inline function 'kotlin.text.substring' call
    var startIndex_0 = lastPos;
    // Inline function 'kotlin.js.asDynamic' call
    var element_1 = str.substring(startIndex_0);
    out.f(element_1);
  }
  return out;
}
function withInsertion(_this__u8e3s4, index, insertedText) {
  _init_properties_StringExt_kt__eg42u6();
  var before = substr(_this__u8e3s4, 0, index);
  var after = substr(_this__u8e3s4, index, _this__u8e3s4.length);
  return before + insertedText + after;
}
function withoutRange(_this__u8e3s4, range) {
  _init_properties_StringExt_kt__eg42u6();
  return substr(_this__u8e3s4, 0, range.k2_1) + substr_0(_this__u8e3s4, range.l2_1 + 1 | 0);
}
function withoutIndex(_this__u8e3s4, index) {
  _init_properties_StringExt_kt__eg42u6();
  return substr(_this__u8e3s4, 0, index) + substr_0(_this__u8e3s4, index + 1 | 0);
}
function replaceNonPrintableCharactersRegex$delegate$lambda() {
  _init_properties_StringExt_kt__eg42u6();
  return Regex.ni('[^ -~]');
}
var properties_initialized_StringExt_kt_sd962o;
function _init_properties_StringExt_kt__eg42u6() {
  if (!properties_initialized_StringExt_kt_sd962o) {
    properties_initialized_StringExt_kt_sd962o = true;
    replaceNonPrintableCharactersRegex$delegate = lazy(replaceNonPrintableCharactersRegex$delegate$lambda);
  }
}
function _WChar___init__impl__4vbl4k(codePoint) {
  return codePoint;
}
function _WChar___get_codePoint__impl__c8brwu($this) {
  return $this;
}
var Companion_instance_0;
function Companion_getInstance_0() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function substr_1(_this__u8e3s4, start, length) {
  length = length === VOID ? _this__u8e3s4.a() : length;
  var low = coerceIn(start >= 0 ? start : _this__u8e3s4.a() + start | 0, 0, _this__u8e3s4.a());
  var high = coerceIn(length >= 0 ? low + length | 0 : _this__u8e3s4.a() + length | 0, 0, _this__u8e3s4.a());
  return high < low ? Companion_getInstance_0().ez('') : _this__u8e3s4.uh(low, high);
}
function extract(_this__u8e3s4, offset, count) {
  return (_this__u8e3s4 >>> offset | 0) & mask(count);
}
function insert(_this__u8e3s4, value, offset, count) {
  var mask_0 = mask(count) << offset;
  var ovalue = value << offset & mask_0;
  return _this__u8e3s4 & ~mask_0 | ovalue;
}
function getS16(_this__u8e3s4, offset, littleEndian) {
  return littleEndian ? getS16LE(_this__u8e3s4, offset) : getS16BE(_this__u8e3s4, offset);
}
function set16(_this__u8e3s4, offset, value, littleEndian) {
  if (littleEndian) {
    set16LE(_this__u8e3s4, offset, value);
  } else {
    set16BE(_this__u8e3s4, offset, value);
  }
}
function mask(_this__u8e3s4) {
  return (1 << _this__u8e3s4) - 1 | 0;
}
function getS16LE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.io.lang.internal.get16LE' call
  var tmp$ret$0 = u8(_this__u8e3s4, offset + 0 | 0) << 0 | u8(_this__u8e3s4, offset + 1 | 0) << 8;
  return signExtend(tmp$ret$0, 16);
}
function getS16BE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.io.lang.internal.get16BE' call
  var tmp$ret$0 = u8(_this__u8e3s4, offset + 1 | 0) << 0 | u8(_this__u8e3s4, offset + 0 | 0) << 8;
  return signExtend(tmp$ret$0, 16);
}
function set16LE(_this__u8e3s4, offset, value) {
  _this__u8e3s4[offset + 0 | 0] = extractByte(value, 0);
  _this__u8e3s4[offset + 1 | 0] = extractByte(value, 8);
}
function set16BE(_this__u8e3s4, offset, value) {
  _this__u8e3s4[offset + 1 | 0] = extractByte(value, 0);
  _this__u8e3s4[offset + 0 | 0] = extractByte(value, 8);
}
function signExtend(_this__u8e3s4, bits) {
  return _this__u8e3s4 << (32 - bits | 0) >> (32 - bits | 0);
}
function extractByte(_this__u8e3s4, offset) {
  return toByte(_this__u8e3s4 >>> offset | 0);
}
function u8(_this__u8e3s4, offset) {
  return _this__u8e3s4[offset] & 255;
}
function isWhitespaceFast(_this__u8e3s4) {
  return _this__u8e3s4 === _Char___init__impl__6a9atx(32) || _this__u8e3s4 === _Char___init__impl__6a9atx(9) || _this__u8e3s4 === _Char___init__impl__6a9atx(13) || _this__u8e3s4 === _Char___init__impl__6a9atx(10);
}
function isLetterDigitOrUnderscore(_this__u8e3s4) {
  return isLetterOrDigit(_this__u8e3s4) || _this__u8e3s4 === _Char___init__impl__6a9atx(95) || _this__u8e3s4 === _Char___init__impl__6a9atx(36);
}
function isDigit(_this__u8e3s4) {
  return _Char___init__impl__6a9atx(48) <= _this__u8e3s4 ? _this__u8e3s4 <= _Char___init__impl__6a9atx(57) : false;
}
function get_isNumeric(_this__u8e3s4) {
  return isDigit(_this__u8e3s4) || _this__u8e3s4 === _Char___init__impl__6a9atx(46) || _this__u8e3s4 === _Char___init__impl__6a9atx(101) || _this__u8e3s4 === _Char___init__impl__6a9atx(45);
}
function isLetterOrDigit(_this__u8e3s4) {
  return isLetter(_this__u8e3s4) || isDigit(_this__u8e3s4);
}
function isLetter(_this__u8e3s4) {
  return (_Char___init__impl__6a9atx(97) <= _this__u8e3s4 ? _this__u8e3s4 <= _Char___init__impl__6a9atx(122) : false) || (_Char___init__impl__6a9atx(65) <= _this__u8e3s4 ? _this__u8e3s4 <= _Char___init__impl__6a9atx(90) : false);
}
function unquote(_this__u8e3s4) {
  var tmp;
  if (isQuoted(_this__u8e3s4)) {
    // Inline function 'kotlin.text.substring' call
    var endIndex = _this__u8e3s4.length - 1 | 0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = _this__u8e3s4.substring(1, endIndex);
    tmp = unescape(tmp$ret$1);
  } else {
    tmp = _this__u8e3s4;
  }
  return tmp;
}
function isQuoted(_this__u8e3s4) {
  return startsWith(_this__u8e3s4, _Char___init__impl__6a9atx(34)) && endsWith(_this__u8e3s4, _Char___init__impl__6a9atx(34));
}
function unescape(_this__u8e3s4) {
  var out = StringBuilder.nc(_this__u8e3s4.length);
  var n = 0;
  while (n < _this__u8e3s4.length) {
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var c = charSequenceGet(_this__u8e3s4, _unary__edvuaz);
    if (c === _Char___init__impl__6a9atx(92)) {
      var _unary__edvuaz_0 = n;
      n = _unary__edvuaz_0 + 1 | 0;
      var c2 = charSequenceGet(_this__u8e3s4, _unary__edvuaz_0);
      if (c2 === _Char___init__impl__6a9atx(92))
        out.jc(_Char___init__impl__6a9atx(92));
      else if (c2 === _Char___init__impl__6a9atx(34))
        out.jc(_Char___init__impl__6a9atx(34));
      else if (c2 === _Char___init__impl__6a9atx(110))
        out.jc(_Char___init__impl__6a9atx(10));
      else if (c2 === _Char___init__impl__6a9atx(114))
        out.jc(_Char___init__impl__6a9atx(13));
      else if (c2 === _Char___init__impl__6a9atx(116))
        out.jc(_Char___init__impl__6a9atx(9));
      else if (c2 === _Char___init__impl__6a9atx(120) || c2 === _Char___init__impl__6a9atx(117)) {
        var N = c2 === _Char___init__impl__6a9atx(117) ? 4 : 2;
        // Inline function 'kotlin.text.substring' call
        var startIndex = n;
        var endIndex = n + N | 0;
        // Inline function 'kotlin.js.asDynamic' call
        var chars = _this__u8e3s4.substring(startIndex, endIndex);
        n = n + N | 0;
        out.jc(numberToChar(toInt(chars, 16)));
      } else {
        out.ic('\\' + toString_0(c2));
      }
    } else
      out.jc(c);
  }
  return out.toString();
}
function substringAfterOrNull(_this__u8e3s4, delimiter) {
  return contains(_this__u8e3s4, delimiter) ? substringAfter(_this__u8e3s4, delimiter) : null;
}
function substringBeforeOrNull(_this__u8e3s4, delimiter) {
  return contains(_this__u8e3s4, delimiter) ? substringBefore(_this__u8e3s4, delimiter) : null;
}
var INDENTS_instance;
function INDENTS_getInstance() {
  if (INDENTS_instance === VOID)
    new INDENTS();
  return INDENTS_instance;
}
function skipWhile(_this__u8e3s4, cond) {
  while (_this__u8e3s4.uy()) {
    var c = _this__u8e3s4.jz();
    if (!cond(new Char(c))) {
      return _this__u8e3s4;
    }
    _this__u8e3s4.kz();
  }
  return _this__u8e3s4;
}
function get_eof(_this__u8e3s4) {
  return !_this__u8e3s4.uy();
}
function skipSpaces(_this__u8e3s4) {
  skipWhile(_this__u8e3s4, skipSpaces$lambda);
  return _this__u8e3s4;
}
function skipExpect(_this__u8e3s4, expected) {
  var readed = _this__u8e3s4.kz();
  if (!(readed === expected)) {
    throw IllegalArgumentException.l("Expected '" + toString_0(expected) + "' but found '" + toString_0(readed) + "' at " + _this__u8e3s4.lz());
  }
}
function readUntilBuilder(_this__u8e3s4, char, out, included) {
  included = included === VOID ? false : included;
  // Inline function 'korlibs.util.readUntilBuilder' call
  $l$loop: while (_this__u8e3s4.uy()) {
    var c = _this__u8e3s4.jz();
    // Inline function 'korlibs.util.readUntilBuilder.<anonymous>' call
    if (c === char) {
      if (included) {
        _this__u8e3s4.kz();
        out.jc(c);
      }
      break $l$loop;
    }
    _this__u8e3s4.kz();
    out.jc(c);
  }
  return out;
}
function matchSingleOrDoubleQuoteString(_this__u8e3s4, out) {
  var tmp12_subject = _this__u8e3s4.jz();
  var tmp;
  if (tmp12_subject === _Char___init__impl__6a9atx(39) || tmp12_subject === _Char___init__impl__6a9atx(34)) {
    var quoteType = _this__u8e3s4.kz();
    out.jc(quoteType);
    tmp = readUntilBuilder(_this__u8e3s4, quoteType, out, true);
  } else {
    tmp = null;
  }
  return tmp;
}
function matchIdentifier(_this__u8e3s4, out) {
  // Inline function 'kotlin.takeIf' call
  // Inline function 'korlibs.util.readWhileBuilder' call
  // Inline function 'korlibs.util.readUntilBuilder' call
  $l$loop: while (_this__u8e3s4.uy()) {
    var c = _this__u8e3s4.jz();
    // Inline function 'korlibs.util.readWhileBuilder.<anonymous>' call
    // Inline function 'korlibs.util.matchIdentifier.<anonymous>' call
    if (!(isLetterDigitOrUnderscore(c) || c === _Char___init__impl__6a9atx(45) || c === _Char___init__impl__6a9atx(126) || c === _Char___init__impl__6a9atx(58))) {
      if (false) {
        _this__u8e3s4.kz();
        out.jc(c);
      }
      break $l$loop;
    }
    _this__u8e3s4.kz();
    out.jc(c);
  }
  // Inline function 'kotlin.contracts.contract' call
  var tmp;
  // Inline function 'korlibs.util.matchIdentifier.<anonymous>' call
  // Inline function 'kotlin.text.isNotEmpty' call
  if (charSequenceLength(out) > 0) {
    tmp = out;
  } else {
    tmp = null;
  }
  return tmp;
}
function tryExpect(_this__u8e3s4, char, consume) {
  consume = consume === VOID ? true : consume;
  var read = _this__u8e3s4.jz();
  var isExpected = read === char;
  if (consume && isExpected) {
    _this__u8e3s4.kz();
  }
  return isExpected;
}
function skipSpaces$lambda(it) {
  return isWhitespaceFast(it.t2_1);
}
var Companion_instance_1;
function Companion_getInstance_1() {
  return Companion_instance_1;
}
function get_formatRegex() {
  _init_properties_Stringformat_kt__9foowo();
  return formatRegex;
}
var formatRegex;
function format(_this__u8e3s4, params) {
  _init_properties_Stringformat_kt__9foowo();
  var paramIndex = {_v: 0};
  var tmp = get_formatRegex();
  return tmp.ti(_this__u8e3s4, format$lambda(params, paramIndex));
}
function format$lambda($params, $paramIndex) {
  return function (mr) {
    var _unary__edvuaz = $paramIndex._v;
    $paramIndex._v = _unary__edvuaz + 1 | 0;
    var param = $params[_unary__edvuaz];
    var size = mr.pj().i1(1);
    var type = mr.pj().i1(2);
    var tmp;
    switch (type) {
      case 'd':
        tmp = numberToLong(isNumber(param) ? param : THROW_CCE()).toString();
        break;
      case 'X':
      case 'x':
        var tmp_0;
        if (typeof param === 'number') {
          // Inline function 'kotlin.toUInt' call
          var tmp$ret$0 = _UInt___init__impl__l7qpdl(param);
          tmp_0 = toString_2(tmp$ret$0, 16);
        } else {
          // Inline function 'kotlin.toULong' call
          var this_0 = numberToLong(isNumber(param) ? param : THROW_CCE());
          var tmp$ret$1 = _ULong___init__impl__c78o9k(this_0);
          tmp_0 = toString_1(tmp$ret$1, 16);
        }

        var res = tmp_0;
        var tmp_1;
        if (type === 'X') {
          // Inline function 'kotlin.text.uppercase' call
          // Inline function 'kotlin.js.asDynamic' call
          tmp_1 = res.toUpperCase();
        } else {
          // Inline function 'kotlin.text.lowercase' call
          // Inline function 'kotlin.js.asDynamic' call
          tmp_1 = res.toLowerCase();
        }

        tmp = tmp_1;
        break;
      default:
        tmp = toString(param);
        break;
    }
    var str = tmp;
    var prefix = startsWith(size, _Char___init__impl__6a9atx(48)) ? _Char___init__impl__6a9atx(48) : _Char___init__impl__6a9atx(32);
    var asize = toIntOrNull(size);
    var str2 = str;
    var tmp_2;
    if (!(asize == null)) {
      while (str2.length < asize) {
        // Inline function 'kotlin.text.plus' call
        var other = str2;
        str2 = toString_0(prefix) + other;
      }
      tmp_2 = Unit_instance;
    }
    return str2;
  };
}
var properties_initialized_Stringformat_kt_nxjbp2;
function _init_properties_Stringformat_kt__9foowo() {
  if (!properties_initialized_Stringformat_kt_nxjbp2) {
    properties_initialized_Stringformat_kt_nxjbp2 = true;
    formatRegex = Regex.ni('%([-]?\\d+)?(\\w)');
  }
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForClass(Charset, 'Charset');
initMetadataForClass(UTC8CharsetBase, 'UTC8CharsetBase');
initMetadataForClass(BaseSingleByteCharset, 'BaseSingleByteCharset');
initMetadataForClass(SingleByteCharset, 'SingleByteCharset');
initMetadataForObject(ISO_8859_1, 'ISO_8859_1');
initMetadataForClass(UTF16Charset, 'UTF16Charset');
initMetadataForObject(ASCII, 'ASCII');
initMetadataForObject(Charsets, 'Charsets');
initMetadataForClass(WStringReader, 'WStringReader');
initMetadataForCompanion(Companion_0);
initMetadataForClass(WString, 'WString');
initMetadataForObject(INDENTS, 'INDENTS');
initMetadataForInterface(SimpleStrReader, 'SimpleStrReader');
protoOf(Impl).pz = skip$default;
initMetadataForClass(Impl, 'Impl', VOID, VOID, [SimpleStrReader]);
initMetadataForCompanion(Companion_1);
//endregion
//region block: init
Companion_instance = new Companion();
Charsets_instance = new Charsets();
Companion_instance_1 = new Companion_1();
//endregion
//region block: exports
export {
  skip$default as skip$default8gbsogngr8nx,
  _WChar___get_codePoint__impl__c8brwu as _WChar___get_codePoint__impl__c8brwu291ypbecez861,
  ASCII_getInstance as ASCII_getInstance1x38t72vrtpyt,
  Charsets_instance as Charsets_instancenv17fp98ne59,
  Companion_getInstance_0 as Companion_getInstanceyicqcjxy8shz,
  INDENTS_getInstance as INDENTS_getInstance1gue9qeyuufz7,
  Companion_instance_1 as Companion_instance29c5vc3xudisn,
  get_UTF16_BE as get_UTF16_BE3rxrt7v8707y4,
  get_UTF8 as get_UTF82geyfhi1l22xl,
  WStringReader as WStringReader2bukzqxb5clbi,
  WString as WStringambx64bqrrmq,
  fromIntArray as fromIntArray2gxq3jb1ug201,
  readStringz as readStringzinkjpjt71hyo,
  splitInChunks as splitInChunks2h1zfocl4rvqy,
  splitKeep as splitKeep2hxk3xif9u3vx,
  substringEquals as substringEqualse7vi4930fev5,
  substr as substr1q77u5lo4evr6,
  substr_0 as substr2jonrcqd8qwvx,
  toByteArray as toByteArraybbc3ng3wgt1d,
  toString_3 as toString2x583ovdqfjci,
  withInsertion as withInsertion32qy0wb7w2f46,
  withoutIndex as withoutIndex1982826lx7knb,
  withoutRange as withoutRangetvtevgxw4fe7,
  isDigit as isDigit1w4owst7y9rdz,
  isLetterOrDigit as isLetterOrDigit1ja5dr081kl50,
  get_isNumeric as get_isNumeric3g36pzg3a1w7z,
  isWhitespaceFast as isWhitespaceFast2iszcfvpi3biz,
  substringAfterOrNull as substringAfterOrNull6pqoc8ojvu1x,
  substringBeforeOrNull as substringBeforeOrNullyyd9hl5du3pj,
  unquote as unquote1kjyk5vajgaow,
  toStringContext as toStringContext20qw1vqodfj0k,
  SimpleStrReader as SimpleStrReader35udnj8g5ojo6,
  get_eof as get_eofrk1bzx8ckgtd,
  format as format1w6w3fsj4lh0e,
  matchIdentifier as matchIdentifier3b7zgfy94bf8r,
  matchSingleOrDoubleQuoteString as matchSingleOrDoubleQuoteString398sq4vhn9fz,
  readUntilBuilder as readUntilBuilderazvgts3bogti,
  skipExpect as skipExpect1tagjz9aj6y5q,
  skipSpaces as skipSpaces25iogcdi8m3q2,
  skipWhile as skipWhile2q7z6jpi2pkj6,
  tryExpect as tryExpect2a3h5rf36r8u1,
};
//endregion
