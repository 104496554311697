import {
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  Enum3alwj03lh1n41 as Enum,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  toString1pkumu07cwy4m as toString,
  coerceInlzh524ulyz3c as coerceIn,
  VOID7hggqo3abtya as VOID,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isArray1hxjqtqy632bc as isArray,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  isInterface3d6p8outrmvmk as isInterface,
  createThis2j2avj17cvnv2 as createThis,
  StringBuildermazzzhj6kkai as StringBuilder,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  equals2au1ep9vhcato as equals,
  hashCodeq5arwsb9dgti as hashCode,
  HashMap1a0ld5kgwhmhv as HashMap,
  first58ocm7j58k3q as first,
  isNaN3ixot6a1mjs5h as isNaN_0,
  last1vo29oleiqj36 as last,
  anyToString3ho3k49fc56mj as anyToString,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  RuntimeException1r3t0zl97011n as RuntimeException,
  charArray2ujmm1qusno00 as charArray,
  FloatCompanionObject_instance29smzmmxq0czz as FloatCompanionObject_instance,
  floatFromBits1n9d03e2m5i5s as floatFromBits,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toByte4i43936u611k as toByte,
  toShort36kaw0zjdq3ex as toShort,
  numberToChar93r9buh19yek as numberToChar,
  concatToString3cxf0c1gqonpo as concatToString,
  lazy2hsh8ze7j6ikd as lazy,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  DurationUnit_MILLISECONDS_getInstance15owevua4zjxe as DurationUnit_MILLISECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  KtMutableList1beimitadwkna as KtMutableList,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
} from './kotlin-kotlin-stdlib.mjs';
import {
  RGBAf__setTo_impl_x4m1oi2i8ob5z12xmk as RGBAf__setTo_impl_x4m1oi,
  _RGBAf___get_r__impl__jfq8gq22u7iwwxqf9kv as _RGBAf___get_r__impl__jfq8gq,
  _RGBAf___get_g__impl__dhoraz13ppxfta6enl4 as _RGBAf___get_g__impl__dhoraz,
  _RGBAf___get_b__impl__fjl0nq1o2dnt97u8qzk as _RGBAf___get_b__impl__fjl0nq,
  _RGBAf___get_a__impl__qnwk97nsawxd1lbayz as _RGBAf___get_a__impl__qnwk97,
  RGBAf__add_impl_uilb1e36b62lk2wc3u as RGBAf__add_impl_uilb1e,
  RGBAf2yz23qr0gb5h3 as RGBAf,
  RGBAf__setTo_impl_x4m1oi752w68zt7ajs as RGBAf__setTo_impl_x4m1oi_0,
  _RGBAf___init__impl__idjpgn3v4e95yu3zu9w as _RGBAf___init__impl__idjpgn,
  _RGBAf___set_r__impl__pz5j5e15wzi2b78qt85 as _RGBAf___set_r__impl__pz5j5e,
  _RGBAf___set_g__impl__c4jj2120t27iiarj9x0 as _RGBAf___set_g__impl__c4jj21,
  _RGBAf___set_b__impl__a2n9pahuqyycqk52np as _RGBAf___set_b__impl__a2n9pa,
  _RGBAf___set_a__impl__iqz7cx1qajwl4l0k985 as _RGBAf___set_a__impl__iqz7cx,
  Companion_getInstanceocd6ti8e4l21 as Companion_getInstance_0,
  _RGBA___get_value__impl__hhco8v1fb2bqjo5n70o as _RGBA___get_value__impl__hhco8v,
  RGBAf__setTo_impl_x4m1oi26jznhcu6qich as RGBAf__setTo_impl_x4m1oi_1,
  Colors_getInstance1qvds1isfbag2 as Colors_getInstance,
  _RGBAf___get_rgba__impl__ui2t323bpqee4gahejg as _RGBAf___get_rgba__impl__ui2t32,
} from './korlibs-korlibs-image.mjs';
import {
  arraycopy52dxe7vhzf14 as arraycopy,
  arraycopy3n7dsu1uob56f as arraycopy_0,
  Uint16Buffer__set_impl_g0kliu1yamftxrkicc6 as Uint16Buffer__set_impl_g0kliu,
} from './korlibs-korlibs-memory.mjs';
import {
  IntSet3s3k6p4gna72o as IntSet,
  IntArrayList3liuagbdnwmna as IntArrayList,
  FloatArrayListavy8wwlieh26 as FloatArrayList,
  Companion_instanceyizv3jv25o3v as Companion_instance,
  ShortArrayList24204ipipacat as ShortArrayList,
  BooleanArrayListzyiutig3135i as BooleanArrayList,
  Pool1zbsw0zhvh8ca as Pool,
} from './korlibs-korlibs-datastructure.mjs';
import { FastArrayListw5fr1wlp4slq as FastArrayList } from './korlibs-korlibs-datastructure-core.mjs';
import {
  get_fullName2a2vm7nxze3wi as get_fullName,
  _PathInfo___init__impl__vpw96a3g5f537ih73ch as _PathInfo___init__impl__vpw96a,
  get_baseNameWithoutExtension11tnoplwjp6a3 as get_baseNameWithoutExtension,
} from './korlibs-korlibs-io-vfs.mjs';
import {
  Companion_getInstance2cvx1jnjzsvrw as Companion_getInstance_1,
  MBoundsBuilder153gzhei3ukb0 as MBoundsBuilder,
} from './korlibs-korlibs-math.mjs';
import { Companion_getInstance3jgth1v8sehkm as Companion_getInstance_2 } from './korge-root-korge-core.mjs';
import {
  View11ybmmoof9vue as View,
  addUpdater1la23qj9g2d42 as addUpdater,
} from './korge-root-korge.mjs';
import { Companion_getInstance1kb6pmvl5oz92 as Companion_getInstance_3 } from './korlibs-korlibs-math-core.mjs';
import { get_secondsjblpw5qd4dzj as get_seconds } from './korlibs-korlibs-time-core.mjs';
//region block: imports
var imul = Math.imul;
var sign = Math.sign;
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    this.np5_1 = 0.0;
    this.op5_1 = 1.0;
    this.pp5_1 = 2.0;
    this.qp5_1 = 19;
  }
}
class Companion_0 {
  constructor() {
    this.rp5_1 = 2;
    this.sp5_1 = -2;
    this.tp5_1 = -1;
    this.up5_1 = 1;
  }
}
class Companion_1 {
  constructor() {
    this.vp5_1 = 3;
    this.wp5_1 = -3;
    this.xp5_1 = -2;
    this.yp5_1 = -1;
    this.zp5_1 = 1;
    this.ap6_1 = 2;
  }
}
class Companion_2 {
  constructor() {
    this.bp6_1 = 5;
    this.cp6_1 = -5;
    this.dp6_1 = -4;
    this.ep6_1 = -3;
    this.fp6_1 = -2;
    this.gp6_1 = -1;
    this.hp6_1 = 1;
    this.ip6_1 = 2;
    this.jp6_1 = 3;
    this.kp6_1 = 4;
  }
}
class Companion_3 {
  constructor() {
    this.lp6_1 = 8;
    this.mp6_1 = -8;
    this.np6_1 = -7;
    this.op6_1 = -6;
    this.pp6_1 = -5;
    this.qp6_1 = -4;
    this.rp6_1 = -3;
    this.sp6_1 = -2;
    this.tp6_1 = -1;
    this.up6_1 = 1;
    this.vp6_1 = 2;
    this.wp6_1 = 3;
    this.xp6_1 = 4;
    this.yp6_1 = 5;
    this.zp6_1 = 6;
    this.ap7_1 = 7;
  }
}
class Companion_4 {
  constructor() {
    this.dp8_1 = 6;
    this.ep8_1 = -6;
    this.fp8_1 = -5;
    this.gp8_1 = -4;
    this.hp8_1 = -3;
    this.ip8_1 = -2;
    this.jp8_1 = -1;
    this.kp8_1 = 1;
    this.lp8_1 = 2;
    this.mp8_1 = 3;
    this.np8_1 = 4;
    this.op8_1 = 5;
  }
}
class Companion_5 {
  constructor() {
    this.pp8_1 = 5;
    this.qp8_1 = -5;
    this.rp8_1 = -4;
    this.sp8_1 = -3;
    this.tp8_1 = -2;
    this.up8_1 = -1;
    this.vp8_1 = 1;
    this.wp8_1 = 2;
    this.xp8_1 = 3;
    this.yp8_1 = 4;
  }
}
class Companion_6 {
  constructor() {
    this.zp8_1 = 2;
    this.ap9_1 = -2;
    this.bp9_1 = -1;
    this.cp9_1 = 1;
  }
}
class Companion_7 {
  constructor() {
    this.dp9_1 = 3;
    this.ep9_1 = -3;
    this.fp9_1 = -2;
    this.gp9_1 = -1;
    this.hp9_1 = 1;
    this.ip9_1 = 2;
  }
}
class Timeline {}
class MixBlend extends Enum {}
class MixDirection extends Enum {}
class TimelineType extends Enum {}
class CurveTimeline {
  constructor(frameCount) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(frameCount > 0)) {
      // Inline function 'com.esotericsoftware.spine.CurveTimeline.<anonymous>' call
      var message = 'frameCount must be > 0: ' + frameCount;
      throw IllegalArgumentException.l(toString(message));
    }
    this.lp9_1 = new Float32Array(imul(frameCount - 1 | 0, Companion_instance_0.qp5_1));
  }
  mp9(frameIndex) {
    this.lp9_1[imul(frameIndex, Companion_instance_0.qp5_1)] = Companion_instance_0.op5_1;
  }
  np9(frameIndex, cx1, cy1, cx2, cy2) {
    var tmpx = (-cx1 * 2 + cx2) * 0.03;
    var tmpy = (-cy1 * 2 + cy2) * 0.03;
    var dddfx = ((cx1 - cx2) * 3 + 1) * 0.006;
    var dddfy = ((cy1 - cy2) * 3 + 1) * 0.006;
    var ddfx = tmpx * 2 + dddfx;
    var ddfy = tmpy * 2 + dddfy;
    var dfx = cx1 * 0.3 + tmpx + dddfx * 0.16666667;
    var dfy = cy1 * 0.3 + tmpy + dddfy * 0.16666667;
    var i = imul(frameIndex, Companion_instance_0.qp5_1);
    var curves = this.lp9_1;
    var _unary__edvuaz = i;
    i = _unary__edvuaz + 1 | 0;
    curves[_unary__edvuaz] = Companion_instance_0.pp5_1;
    var x = dfx;
    var y = dfy;
    var n = (i + Companion_instance_0.qp5_1 | 0) - 1 | 0;
    while (i < n) {
      curves[i] = x;
      curves[i + 1 | 0] = y;
      dfx = dfx + ddfx;
      dfy = dfy + ddfy;
      ddfx = ddfx + dddfx;
      ddfy = ddfy + dddfy;
      x = x + dfx;
      y = y + dfy;
      i = i + 2 | 0;
    }
  }
  op9(frameIndex, percent) {
    var percent_0 = percent;
    percent_0 = coerceIn(percent_0, 0.0, 1.0);
    var curves = this.lp9_1;
    var i = imul(frameIndex, Companion_instance_0.qp5_1);
    var type = curves[i];
    if (type === Companion_instance_0.np5_1)
      return percent_0;
    if (type === Companion_instance_0.op5_1)
      return 0.0;
    i = i + 1 | 0;
    var x = 0.0;
    var start = i;
    var n = (i + Companion_instance_0.qp5_1 | 0) - 1 | 0;
    while (i < n) {
      x = curves[i];
      if (x >= percent_0) {
        if (i === start)
          return curves[i + 1 | 0] * percent_0 / x;
        var prevX = curves[i - 2 | 0];
        var prevY = curves[i - 1 | 0];
        return prevY + (curves[i + 1 | 0] - prevY) * (percent_0 - prevX) / (x - prevX);
      }
      i = i + 2 | 0;
    }
    var y = curves[i - 1 | 0];
    return y + (1 - y) * (percent_0 - x) / (1 - x);
  }
}
class RotateTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.qp9_1 = 0;
    this.rp9_1 = new Float32Array(frameCount << 1);
  }
  sp9(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'com.esotericsoftware.spine.RotateTimeline.<set-boneIndex>.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.qp9_1 = index;
  }
  jp9() {
    return (TimelineType_rotate_getInstance().v3_1 << 24) + this.qp9_1 | 0;
  }
  tp9(frameIndex, time, degrees) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = frameIndex_0 << 1;
    this.rp9_1[frameIndex_0] = time;
    this.rp9_1[frameIndex_0 + Companion_instance_1.up5_1 | 0] = degrees;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var bone = skeleton.fp7_1.i1(this.qp9_1);
    if (!bone.upa_1)
      return Unit_instance;
    var frames = this.rp9_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          bone.apa_1 = bone.up9_1.bpb_1;
          return Unit_instance;
        case 1:
          var r = bone.up9_1.bpb_1 - bone.apa_1;
          bone.apa_1 = bone.apa_1 + (r - imul(16384 - numberToInt(16384.499999999996 - r / 360) | 0, 360)) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    if (time >= frames[frames.length - Companion_instance_1.rp5_1 | 0]) {
      var r_0 = frames[frames.length + Companion_instance_1.tp5_1 | 0];
      switch (blend.v3_1) {
        case 0:
          bone.apa_1 = bone.up9_1.bpb_1 + r_0 * alpha;
          break;
        case 1:
        case 2:
          r_0 = r_0 + (bone.up9_1.bpb_1 - bone.apa_1);
          r_0 = r_0 - imul(16384 - numberToInt(16384.499999999996 - r_0 / 360) | 0, 360);
          bone.apa_1 = bone.apa_1 + r_0 * alpha;
          break;
        case 3:
          bone.apa_1 = bone.apa_1 + r_0 * alpha;
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      return Unit_instance;
    }
    var frame = Companion_instance_9.jpb(frames, time, Companion_instance_1.rp5_1);
    var prevRotation = frames[frame + Companion_instance_1.tp5_1 | 0];
    var frameTime = frames[frame];
    var percent = this.op9((frame >> 1) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_1.sp5_1 | 0] - frameTime));
    var r_1 = frames[frame + Companion_instance_1.up5_1 | 0] - prevRotation;
    r_1 = prevRotation + (r_1 - imul(16384 - numberToInt(16384.499999999996 - r_1 / 360) | 0, 360)) * percent;
    switch (blend.v3_1) {
      case 0:
        bone.apa_1 = bone.up9_1.bpb_1 + (r_1 - imul(16384 - numberToInt(16384.499999999996 - r_1 / 360) | 0, 360)) * alpha;
        break;
      case 1:
      case 2:
        r_1 = r_1 + (bone.up9_1.bpb_1 - bone.apa_1);
        bone.apa_1 = bone.apa_1 + (r_1 - imul(16384 - numberToInt(16384.499999999996 - r_1 / 360) | 0, 360)) * alpha;
        break;
      case 3:
        bone.apa_1 = bone.apa_1 + (r_1 - imul(16384 - numberToInt(16384.499999999996 - r_1 / 360) | 0, 360)) * alpha;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
  }
}
class TranslateTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.lpb_1 = 0;
    this.mpb_1 = new Float32Array(imul(frameCount, Companion_instance_2.vp5_1));
  }
  sp9(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'com.esotericsoftware.spine.TranslateTimeline.<set-boneIndex>.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.lpb_1 = index;
  }
  npb() {
    return this.lpb_1;
  }
  jp9() {
    return (TimelineType_translate_getInstance().v3_1 << 24) + this.npb() | 0;
  }
  opb(frameIndex, time, x, y) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = imul(frameIndex_0, Companion_instance_2.vp5_1);
    this.mpb_1[frameIndex_0] = time;
    this.mpb_1[frameIndex_0 + Companion_instance_2.zp5_1 | 0] = x;
    this.mpb_1[frameIndex_0 + Companion_instance_2.ap6_1 | 0] = y;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var bone = skeleton.fp7_1.i1(this.npb());
    if (!bone.upa_1)
      return Unit_instance;
    var frames = this.mpb_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          bone.yp9_1 = bone.up9_1.zpa_1;
          bone.zp9_1 = bone.up9_1.apb_1;
          return Unit_instance;
        case 1:
          bone.yp9_1 = bone.yp9_1 + (bone.up9_1.zpa_1 - bone.yp9_1) * alpha;
          bone.zp9_1 = bone.zp9_1 + (bone.up9_1.apb_1 - bone.zp9_1) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var x;
    var y;
    if (time >= frames[frames.length - Companion_instance_2.vp5_1 | 0]) {
      x = frames[frames.length + Companion_instance_2.xp5_1 | 0];
      y = frames[frames.length + Companion_instance_2.yp5_1 | 0];
    } else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_2.vp5_1);
      x = frames[frame + Companion_instance_2.xp5_1 | 0];
      y = frames[frame + Companion_instance_2.yp5_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_2.vp5_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_2.wp5_1 | 0] - frameTime));
      x = x + (frames[frame + Companion_instance_2.zp5_1 | 0] - x) * percent;
      y = y + (frames[frame + Companion_instance_2.ap6_1 | 0] - y) * percent;
    }
    switch (blend.v3_1) {
      case 0:
        bone.yp9_1 = bone.up9_1.zpa_1 + x * alpha;
        bone.zp9_1 = bone.up9_1.apb_1 + y * alpha;
        break;
      case 1:
      case 2:
        bone.yp9_1 = bone.yp9_1 + (bone.up9_1.zpa_1 + x - bone.yp9_1) * alpha;
        bone.zp9_1 = bone.zp9_1 + (bone.up9_1.apb_1 + y - bone.zp9_1) * alpha;
        break;
      case 3:
        bone.yp9_1 = bone.yp9_1 + x * alpha;
        bone.zp9_1 = bone.zp9_1 + y * alpha;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
  }
}
class ScaleTimeline extends TranslateTimeline {
  jp9() {
    return (TimelineType_scale_getInstance().v3_1 << 24) + this.npb() | 0;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var bone = skeleton.fp7_1.i1(this.npb());
    if (!bone.upa_1)
      return Unit_instance;
    var frames = this.mpb_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          bone.bpa_1 = bone.up9_1.cpb_1;
          bone.cpa_1 = bone.up9_1.dpb_1;
          return Unit_instance;
        case 1:
          bone.bpa_1 = bone.bpa_1 + (bone.up9_1.cpb_1 - bone.bpa_1) * alpha;
          bone.cpa_1 = bone.cpa_1 + (bone.up9_1.dpb_1 - bone.cpa_1) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var x;
    var y;
    if (time >= frames[frames.length - Companion_instance_2.vp5_1 | 0]) {
      x = frames[frames.length + Companion_instance_2.xp5_1 | 0] * bone.up9_1.cpb_1;
      y = frames[frames.length + Companion_instance_2.yp5_1 | 0] * bone.up9_1.dpb_1;
    } else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_2.vp5_1);
      x = frames[frame + Companion_instance_2.xp5_1 | 0];
      y = frames[frame + Companion_instance_2.yp5_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_2.vp5_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_2.wp5_1 | 0] - frameTime));
      x = (x + (frames[frame + Companion_instance_2.zp5_1 | 0] - x) * percent) * bone.up9_1.cpb_1;
      y = (y + (frames[frame + Companion_instance_2.ap6_1 | 0] - y) * percent) * bone.up9_1.dpb_1;
    }
    if (alpha === 1.0) {
      if (blend.equals(MixBlend_add_getInstance())) {
        bone.bpa_1 = bone.bpa_1 + (x - bone.up9_1.cpb_1);
        bone.cpa_1 = bone.cpa_1 + (y - bone.up9_1.dpb_1);
      } else {
        bone.bpa_1 = x;
        bone.cpa_1 = y;
      }
    } else {
      var bx;
      var by;
      if (direction.equals(MixDirection_out_getInstance())) {
        switch (blend.v3_1) {
          case 0:
            bx = bone.up9_1.cpb_1;
            by = bone.up9_1.dpb_1;
            var tmp = bone;
            // Inline function 'kotlin.math.abs' call

            var x_0 = x;
            var tmp_0 = Math.abs(x_0);
            // Inline function 'kotlin.math.sign' call

            var this_0 = bx;
            tmp.bpa_1 = bx + (tmp_0 * sign(this_0) - bx) * alpha;
            var tmp_1 = bone;
            // Inline function 'kotlin.math.abs' call

            var x_1 = y;
            var tmp_2 = Math.abs(x_1);
            // Inline function 'kotlin.math.sign' call

            var this_1 = by;
            tmp_1.cpa_1 = by + (tmp_2 * sign(this_1) - by) * alpha;
            break;
          case 1:
          case 2:
            bx = bone.bpa_1;
            by = bone.cpa_1;
            var tmp_3 = bone;
            // Inline function 'kotlin.math.abs' call

            var x_2 = x;
            var tmp_4 = Math.abs(x_2);
            // Inline function 'kotlin.math.sign' call

            var this_2 = bx;
            tmp_3.bpa_1 = bx + (tmp_4 * sign(this_2) - bx) * alpha;
            var tmp_5 = bone;
            // Inline function 'kotlin.math.abs' call

            var x_3 = y;
            var tmp_6 = Math.abs(x_3);
            // Inline function 'kotlin.math.sign' call

            var this_3 = by;
            tmp_5.cpa_1 = by + (tmp_6 * sign(this_3) - by) * alpha;
            break;
          case 3:
            bx = bone.bpa_1;
            by = bone.cpa_1;
            var tmp_7 = bone;
            // Inline function 'kotlin.math.abs' call

            var x_4 = x;
            var tmp_8 = Math.abs(x_4);
            // Inline function 'kotlin.math.sign' call

            var this_4 = bx;
            tmp_7.bpa_1 = bx + (tmp_8 * sign(this_4) - bone.up9_1.cpb_1) * alpha;
            var tmp_9 = bone;
            // Inline function 'kotlin.math.abs' call

            var x_5 = y;
            var tmp_10 = Math.abs(x_5);
            // Inline function 'kotlin.math.sign' call

            var this_5 = by;
            tmp_9.cpa_1 = by + (tmp_10 * sign(this_5) - bone.up9_1.dpb_1) * alpha;
            break;
          default:
            noWhenBranchMatchedException();
            break;
        }
      } else {
        switch (blend.v3_1) {
          case 0:
            // Inline function 'kotlin.math.abs' call

            var x_6 = bone.up9_1.cpb_1;
            var tmp_11 = Math.abs(x_6);
            // Inline function 'kotlin.math.sign' call

            var this_6 = x;
            bx = tmp_11 * sign(this_6);
            // Inline function 'kotlin.math.abs' call

            var x_7 = bone.up9_1.dpb_1;
            var tmp_12 = Math.abs(x_7);
            // Inline function 'kotlin.math.sign' call

            var this_7 = y;
            by = tmp_12 * sign(this_7);
            bone.bpa_1 = bx + (x - bx) * alpha;
            bone.cpa_1 = by + (y - by) * alpha;
            break;
          case 1:
          case 2:
            // Inline function 'kotlin.math.abs' call

            var x_8 = bone.bpa_1;
            var tmp_13 = Math.abs(x_8);
            // Inline function 'kotlin.math.sign' call

            var this_8 = x;
            bx = tmp_13 * sign(this_8);
            // Inline function 'kotlin.math.abs' call

            var x_9 = bone.cpa_1;
            var tmp_14 = Math.abs(x_9);
            // Inline function 'kotlin.math.sign' call

            var this_9 = y;
            by = tmp_14 * sign(this_9);
            bone.bpa_1 = bx + (x - bx) * alpha;
            bone.cpa_1 = by + (y - by) * alpha;
            break;
          case 3:
            // Inline function 'kotlin.math.sign' call

            var this_10 = x;
            bx = sign(this_10);
            // Inline function 'kotlin.math.sign' call

            var this_11 = y;
            by = sign(this_11);
            var tmp_15 = bone;
            // Inline function 'kotlin.math.abs' call

            var x_10 = bone.bpa_1;
            var tmp_16 = Math.abs(x_10) * bx;
            var tmp_17 = x;
            // Inline function 'kotlin.math.abs' call

            var x_11 = bone.up9_1.cpb_1;
            tmp_15.bpa_1 = tmp_16 + (tmp_17 - Math.abs(x_11) * bx) * alpha;
            var tmp_18 = bone;
            // Inline function 'kotlin.math.abs' call

            var x_12 = bone.cpa_1;
            var tmp_19 = Math.abs(x_12) * by;
            var tmp_20 = y;
            // Inline function 'kotlin.math.abs' call

            var x_13 = bone.up9_1.dpb_1;
            tmp_18.cpa_1 = tmp_19 + (tmp_20 - Math.abs(x_13) * by) * alpha;
            break;
          default:
            noWhenBranchMatchedException();
            break;
        }
      }
    }
  }
}
class ShearTimeline extends TranslateTimeline {
  jp9() {
    return (TimelineType_shear_getInstance().v3_1 << 24) + this.npb() | 0;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var bone = skeleton.fp7_1.i1(this.npb());
    if (!bone.upa_1)
      return Unit_instance;
    var frames = this.mpb_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          bone.dpa_1 = bone.up9_1.epb_1;
          bone.epa_1 = bone.up9_1.fpb_1;
          return Unit_instance;
        case 1:
          bone.dpa_1 = bone.dpa_1 + (bone.up9_1.epb_1 - bone.dpa_1) * alpha;
          bone.epa_1 = bone.epa_1 + (bone.up9_1.fpb_1 - bone.epa_1) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var x;
    var y;
    if (time >= frames[frames.length - Companion_instance_2.vp5_1 | 0]) {
      x = frames[frames.length + Companion_instance_2.xp5_1 | 0];
      y = frames[frames.length + Companion_instance_2.yp5_1 | 0];
    } else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_2.vp5_1);
      x = frames[frame + Companion_instance_2.xp5_1 | 0];
      y = frames[frame + Companion_instance_2.yp5_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_2.vp5_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_2.wp5_1 | 0] - frameTime));
      x = x + (frames[frame + Companion_instance_2.zp5_1 | 0] - x) * percent;
      y = y + (frames[frame + Companion_instance_2.ap6_1 | 0] - y) * percent;
    }
    switch (blend.v3_1) {
      case 0:
        bone.dpa_1 = bone.up9_1.epb_1 + x * alpha;
        bone.epa_1 = bone.up9_1.fpb_1 + y * alpha;
        break;
      case 1:
      case 2:
        bone.dpa_1 = bone.dpa_1 + (bone.up9_1.epb_1 + x - bone.dpa_1) * alpha;
        bone.epa_1 = bone.epa_1 + (bone.up9_1.fpb_1 + y - bone.epa_1) * alpha;
        break;
      case 3:
        bone.dpa_1 = bone.dpa_1 + x * alpha;
        bone.epa_1 = bone.epa_1 + y * alpha;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
  }
}
class ColorTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.wpb_1 = 0;
    this.xpb_1 = new Float32Array(imul(frameCount, Companion_instance_3.bp6_1));
  }
  ypb(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'com.esotericsoftware.spine.ColorTimeline.<set-slotIndex>.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.wpb_1 = index;
  }
  jp9() {
    return (TimelineType_color_getInstance().v3_1 << 24) + this.wpb_1 | 0;
  }
  zpb(frameIndex, time, r, g, b, a) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = imul(frameIndex_0, Companion_instance_3.bp6_1);
    this.xpb_1[frameIndex_0] = time;
    this.xpb_1[frameIndex_0 + Companion_instance_3.hp6_1 | 0] = r;
    this.xpb_1[frameIndex_0 + Companion_instance_3.ip6_1 | 0] = g;
    this.xpb_1[frameIndex_0 + Companion_instance_3.jp6_1 | 0] = b;
    this.xpb_1[frameIndex_0 + Companion_instance_3.kp6_1 | 0] = a;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var slot = skeleton.gp7_1.i1(this.wpb_1);
    if (!slot.vp7_1.upa_1)
      return Unit_instance;
    var frames = this.xpb_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          RGBAf__setTo_impl_x4m1oi(slot.wp7_1, slot.up7_1.dpc_1);
          return Unit_instance;
        case 1:
          var color = slot.wp7_1;
          var setup = slot.up7_1.dpc_1;
          new RGBAf(RGBAf__add_impl_uilb1e(color, (_RGBAf___get_r__impl__jfq8gq(setup) - _RGBAf___get_r__impl__jfq8gq(color)) * alpha, (_RGBAf___get_g__impl__dhoraz(setup) - _RGBAf___get_g__impl__dhoraz(color)) * alpha, (_RGBAf___get_b__impl__fjl0nq(setup) - _RGBAf___get_b__impl__fjl0nq(color)) * alpha, (_RGBAf___get_a__impl__qnwk97(setup) - _RGBAf___get_a__impl__qnwk97(color)) * alpha));
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var r;
    var g;
    var b;
    var a;
    if (time >= frames[frames.length - Companion_instance_3.bp6_1 | 0]) {
      var i = frames.length;
      r = frames[i + Companion_instance_3.dp6_1 | 0];
      g = frames[i + Companion_instance_3.ep6_1 | 0];
      b = frames[i + Companion_instance_3.fp6_1 | 0];
      a = frames[i + Companion_instance_3.gp6_1 | 0];
    } else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_3.bp6_1);
      r = frames[frame + Companion_instance_3.dp6_1 | 0];
      g = frames[frame + Companion_instance_3.ep6_1 | 0];
      b = frames[frame + Companion_instance_3.fp6_1 | 0];
      a = frames[frame + Companion_instance_3.gp6_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_3.bp6_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_3.cp6_1 | 0] - frameTime));
      r = r + (frames[frame + Companion_instance_3.hp6_1 | 0] - r) * percent;
      g = g + (frames[frame + Companion_instance_3.ip6_1 | 0] - g) * percent;
      b = b + (frames[frame + Companion_instance_3.jp6_1 | 0] - b) * percent;
      a = a + (frames[frame + Companion_instance_3.kp6_1 | 0] - a) * percent;
    }
    if (alpha === 1.0)
      RGBAf__setTo_impl_x4m1oi_0(slot.wp7_1, r, g, b, a);
    else {
      var color_0 = slot.wp7_1;
      if (blend.equals(MixBlend_setup_getInstance())) {
        RGBAf__setTo_impl_x4m1oi(color_0, slot.up7_1.dpc_1);
      }
      RGBAf__add_impl_uilb1e(color_0, (r - _RGBAf___get_r__impl__jfq8gq(color_0)) * alpha, (g - _RGBAf___get_g__impl__dhoraz(color_0)) * alpha, (b - _RGBAf___get_b__impl__fjl0nq(color_0)) * alpha, (a - _RGBAf___get_a__impl__qnwk97(color_0)) * alpha);
    }
  }
}
class TwoColorTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.ipc_1 = 0;
    this.jpc_1 = new Float32Array(imul(frameCount, Companion_instance_4.lp6_1));
  }
  ypb(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'com.esotericsoftware.spine.TwoColorTimeline.<set-slotIndex>.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.ipc_1 = index;
  }
  jp9() {
    return (TimelineType_twoColor_getInstance().v3_1 << 24) + this.ipc_1 | 0;
  }
  kpc(frameIndex, time, r, g, b, a, r2, g2, b2) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = imul(frameIndex_0, Companion_instance_4.lp6_1);
    this.jpc_1[frameIndex_0] = time;
    this.jpc_1[frameIndex_0 + Companion_instance_4.up6_1 | 0] = r;
    this.jpc_1[frameIndex_0 + Companion_instance_4.vp6_1 | 0] = g;
    this.jpc_1[frameIndex_0 + Companion_instance_4.wp6_1 | 0] = b;
    this.jpc_1[frameIndex_0 + Companion_instance_4.xp6_1 | 0] = a;
    this.jpc_1[frameIndex_0 + Companion_instance_4.yp6_1 | 0] = r2;
    this.jpc_1[frameIndex_0 + Companion_instance_4.zp6_1 | 0] = g2;
    this.jpc_1[frameIndex_0 + Companion_instance_4.ap7_1 | 0] = b2;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var slot = skeleton.gp7_1.i1(this.ipc_1);
    if (!slot.vp7_1.upa_1)
      return Unit_instance;
    var frames = this.jpc_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          RGBAf__setTo_impl_x4m1oi(slot.wp7_1, slot.up7_1.dpc_1);
          var tmp = slot.xp7_1;
          var tmp_0 = ensureNotNull(tmp == null ? null : new RGBAf(tmp));
          var tmp_1 = slot.up7_1.epc_1;
          RGBAf__setTo_impl_x4m1oi(tmp_0.b5k_1, ensureNotNull(tmp_1 == null ? null : new RGBAf(tmp_1)).b5k_1);
          return Unit_instance;
        case 1:
          var light = slot.wp7_1;
          var dark = slot.xp7_1;
          var setupLight = slot.up7_1.dpc_1;
          var tmp_2 = slot.up7_1.epc_1;
          var setupDark = ensureNotNull(tmp_2 == null ? null : new RGBAf(tmp_2)).b5k_1;
          RGBAf__add_impl_uilb1e(light, (_RGBAf___get_r__impl__jfq8gq(setupLight) - _RGBAf___get_r__impl__jfq8gq(light)) * alpha, (_RGBAf___get_g__impl__dhoraz(setupLight) - _RGBAf___get_g__impl__dhoraz(light)) * alpha, (_RGBAf___get_b__impl__fjl0nq(setupLight) - _RGBAf___get_b__impl__fjl0nq(light)) * alpha, (_RGBAf___get_a__impl__qnwk97(setupLight) - _RGBAf___get_a__impl__qnwk97(light)) * alpha);
          var tmp_3 = dark;
          new RGBAf(RGBAf__add_impl_uilb1e(ensureNotNull(tmp_3 == null ? null : new RGBAf(tmp_3)).b5k_1, (_RGBAf___get_r__impl__jfq8gq(setupDark) - _RGBAf___get_r__impl__jfq8gq(dark)) * alpha, (_RGBAf___get_g__impl__dhoraz(setupDark) - _RGBAf___get_g__impl__dhoraz(dark)) * alpha, (_RGBAf___get_b__impl__fjl0nq(setupDark) - _RGBAf___get_b__impl__fjl0nq(dark)) * alpha, 0.0));
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var r;
    var g;
    var b;
    var a;
    var r2;
    var g2;
    var b2;
    if (time >= frames[frames.length - Companion_instance_4.lp6_1 | 0]) {
      var i = frames.length;
      r = frames[i + Companion_instance_4.np6_1 | 0];
      g = frames[i + Companion_instance_4.op6_1 | 0];
      b = frames[i + Companion_instance_4.pp6_1 | 0];
      a = frames[i + Companion_instance_4.qp6_1 | 0];
      r2 = frames[i + Companion_instance_4.rp6_1 | 0];
      g2 = frames[i + Companion_instance_4.sp6_1 | 0];
      b2 = frames[i + Companion_instance_4.tp6_1 | 0];
    } else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_4.lp6_1);
      r = frames[frame + Companion_instance_4.np6_1 | 0];
      g = frames[frame + Companion_instance_4.op6_1 | 0];
      b = frames[frame + Companion_instance_4.pp6_1 | 0];
      a = frames[frame + Companion_instance_4.qp6_1 | 0];
      r2 = frames[frame + Companion_instance_4.rp6_1 | 0];
      g2 = frames[frame + Companion_instance_4.sp6_1 | 0];
      b2 = frames[frame + Companion_instance_4.tp6_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_4.lp6_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_4.mp6_1 | 0] - frameTime));
      r = r + (frames[frame + Companion_instance_4.up6_1 | 0] - r) * percent;
      g = g + (frames[frame + Companion_instance_4.vp6_1 | 0] - g) * percent;
      b = b + (frames[frame + Companion_instance_4.wp6_1 | 0] - b) * percent;
      a = a + (frames[frame + Companion_instance_4.xp6_1 | 0] - a) * percent;
      r2 = r2 + (frames[frame + Companion_instance_4.yp6_1 | 0] - r2) * percent;
      g2 = g2 + (frames[frame + Companion_instance_4.zp6_1 | 0] - g2) * percent;
      b2 = b2 + (frames[frame + Companion_instance_4.ap7_1 | 0] - b2) * percent;
    }
    if (alpha === 1.0) {
      RGBAf__setTo_impl_x4m1oi_0(slot.wp7_1, r, g, b, a);
      var tmp_4 = slot.xp7_1;
      RGBAf__setTo_impl_x4m1oi_0(ensureNotNull(tmp_4 == null ? null : new RGBAf(tmp_4)).b5k_1, r2, g2, b2, 1.0);
    } else {
      var light_0 = slot.wp7_1;
      var dark_0 = slot.xp7_1;
      if (blend.equals(MixBlend_setup_getInstance())) {
        RGBAf__setTo_impl_x4m1oi(light_0, slot.up7_1.dpc_1);
        var tmp_5 = dark_0;
        var tmp_6 = ensureNotNull(tmp_5 == null ? null : new RGBAf(tmp_5));
        var tmp_7 = slot.up7_1.epc_1;
        RGBAf__setTo_impl_x4m1oi(tmp_6.b5k_1, ensureNotNull(tmp_7 == null ? null : new RGBAf(tmp_7)).b5k_1);
      }
      RGBAf__add_impl_uilb1e(light_0, (r - _RGBAf___get_r__impl__jfq8gq(light_0)) * alpha, (g - _RGBAf___get_g__impl__dhoraz(light_0)) * alpha, (b - _RGBAf___get_b__impl__fjl0nq(light_0)) * alpha, (a - _RGBAf___get_a__impl__qnwk97(light_0)) * alpha);
      var tmp_8 = dark_0;
      RGBAf__add_impl_uilb1e(ensureNotNull(tmp_8 == null ? null : new RGBAf(tmp_8)).b5k_1, (r2 - _RGBAf___get_r__impl__jfq8gq(dark_0)) * alpha, (g2 - _RGBAf___get_g__impl__dhoraz(dark_0)) * alpha, (b2 - _RGBAf___get_b__impl__fjl0nq(dark_0)) * alpha, 0.0);
    }
  }
}
class AttachmentTimeline {
  constructor(frameCount) {
    this.bp7_1 = 0;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(frameCount > 0)) {
      // Inline function 'com.esotericsoftware.spine.AttachmentTimeline.<anonymous>' call
      var message = 'frameCount must be > 0: ' + frameCount;
      throw IllegalArgumentException.l(toString(message));
    }
    this.cp7_1 = new Float32Array(frameCount);
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.dp7_1 = fillArrayVal(Array(frameCount), null);
  }
  ypb(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'com.esotericsoftware.spine.AttachmentTimeline.<set-slotIndex>.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.bp7_1 = index;
  }
  jp9() {
    return (TimelineType_attachment_getInstance().v3_1 << 24) + this.bp7_1 | 0;
  }
  lpc(frameIndex, time, attachmentName) {
    this.cp7_1[frameIndex] = time;
    this.dp7_1[frameIndex] = attachmentName;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var slot = skeleton.gp7_1.i1(this.bp7_1);
    if (!slot.vp7_1.upa_1)
      return Unit_instance;
    if (direction.equals(MixDirection_out_getInstance())) {
      if (blend.equals(MixBlend_setup_getInstance())) {
        setAttachment(this, skeleton, slot, slot.up7_1.fpc_1);
      }
      return Unit_instance;
    }
    var frames = this.cp7_1;
    if (time < frames[0]) {
      if (blend.equals(MixBlend_setup_getInstance()) || blend.equals(MixBlend_first_getInstance())) {
        setAttachment(this, skeleton, slot, slot.up7_1.fpc_1);
      }
      return Unit_instance;
    }
    var frameIndex;
    if (time >= frames[frames.length - 1 | 0])
      frameIndex = frames.length - 1 | 0;
    else
      frameIndex = Companion_instance_9.mpc(frames, time) - 1 | 0;
    setAttachment(this, skeleton, slot, this.dp7_1[frameIndex]);
  }
}
class DeformTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.opc_1 = 0;
    this.qpc_1 = new Float32Array(frameCount);
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_0 = fillArrayVal(Array(frameCount), null);
    tmp.rpc_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
  }
  ypb(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'com.esotericsoftware.spine.DeformTimeline.<set-slotIndex>.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.opc_1 = index;
  }
  spc() {
    var tmp = this.ppc_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('attachment');
    }
  }
  jp9() {
    return ((TimelineType_deform_getInstance().v3_1 << 27) + this.spc().upc_1 | 0) + this.opc_1 | 0;
  }
  zpc(frameIndex, time, vertices) {
    this.qpc_1[frameIndex] = time;
    this.rpc_1[frameIndex] = vertices;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var alpha_0 = alpha;
    var blend_0 = blend;
    var slot = skeleton.gp7_1.i1(this.opc_1);
    if (!slot.vp7_1.upa_1)
      return Unit_instance;
    var slotAttachment = slot.yp7_1;
    var tmp;
    if (!(slotAttachment instanceof VertexAttachment)) {
      tmp = true;
    } else {
      tmp = !(slotAttachment.ypc_1 === this.spc());
    }
    if (tmp)
      return Unit_instance;
    var deformArray = slot.ap8_1;
    if (deformArray.j1() === 0)
      blend_0 = MixBlend_setup_getInstance();
    var frameVertices = this.rpc_1;
    var vertexCount = frameVertices[0].length;
    var frames = this.qpc_1;
    if (time < frames[0]) {
      switch (blend_0.v3_1) {
        case 0:
          deformArray.o3();
          return Unit_instance;
        case 1:
          if (alpha_0 === 1.0) {
            deformArray.o3();
            return Unit_instance;
          }

          var deform = setSize_0(deformArray, vertexCount);
          if (slotAttachment.vpc_1 == null) {
            var setupVertices = slotAttachment.wpc_1;
            var inductionVariable = 0;
            if (inductionVariable < vertexCount)
              do {
                var i = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                deform[i] = deform[i] + (ensureNotNull(setupVertices)[i] - deform[i]) * alpha_0;
              }
               while (inductionVariable < vertexCount);
          } else {
            alpha_0 = 1 - alpha_0;
            var inductionVariable_0 = 0;
            if (inductionVariable_0 < vertexCount)
              do {
                var i_0 = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                deform[i_0] = deform[i_0] * alpha_0;
              }
               while (inductionVariable_0 < vertexCount);
          }

          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var deform_0 = setSize_0(deformArray, vertexCount);
    if (time >= frames[frames.length - 1 | 0]) {
      var lastVertices = frameVertices[frames.length - 1 | 0];
      if (alpha_0 === 1.0) {
        if (blend_0.equals(MixBlend_add_getInstance())) {
          if (slotAttachment.vpc_1 == null) {
            var setupVertices_0 = slotAttachment.wpc_1;
            var inductionVariable_1 = 0;
            if (inductionVariable_1 < vertexCount)
              do {
                var i_1 = inductionVariable_1;
                inductionVariable_1 = inductionVariable_1 + 1 | 0;
                deform_0[i_1] = deform_0[i_1] + (lastVertices[i_1] - ensureNotNull(setupVertices_0)[i_1]);
              }
               while (inductionVariable_1 < vertexCount);
          } else {
            var inductionVariable_2 = 0;
            if (inductionVariable_2 < vertexCount)
              do {
                var i_2 = inductionVariable_2;
                inductionVariable_2 = inductionVariable_2 + 1 | 0;
                deform_0[i_2] = deform_0[i_2] + lastVertices[i_2];
              }
               while (inductionVariable_2 < vertexCount);
          }
        } else {
          // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.arraycopy' call
          arraycopy(lastVertices, 0, deform_0, 0, vertexCount);
        }
      } else {
        switch (blend_0.v3_1) {
          case 0:
            if (slotAttachment.vpc_1 == null) {
              var setupVertices_1 = slotAttachment.wpc_1;
              var inductionVariable_3 = 0;
              if (inductionVariable_3 < vertexCount)
                do {
                  var i_3 = inductionVariable_3;
                  inductionVariable_3 = inductionVariable_3 + 1 | 0;
                  var setup = ensureNotNull(setupVertices_1)[i_3];
                  deform_0[i_3] = setup + (lastVertices[i_3] - setup) * alpha_0;
                }
                 while (inductionVariable_3 < vertexCount);
            } else {
              var inductionVariable_4 = 0;
              if (inductionVariable_4 < vertexCount)
                do {
                  var i_4 = inductionVariable_4;
                  inductionVariable_4 = inductionVariable_4 + 1 | 0;
                  deform_0[i_4] = lastVertices[i_4] * alpha_0;
                }
                 while (inductionVariable_4 < vertexCount);
            }

            break;
          case 1:
          case 2:
            var inductionVariable_5 = 0;
            if (inductionVariable_5 < vertexCount)
              do {
                var i_5 = inductionVariable_5;
                inductionVariable_5 = inductionVariable_5 + 1 | 0;
                deform_0[i_5] = deform_0[i_5] + (lastVertices[i_5] - deform_0[i_5]) * alpha_0;
              }
               while (inductionVariable_5 < vertexCount);
            break;
          case 3:
            if (slotAttachment.vpc_1 == null) {
              var setupVertices_2 = slotAttachment.wpc_1;
              var inductionVariable_6 = 0;
              if (inductionVariable_6 < vertexCount)
                do {
                  var i_6 = inductionVariable_6;
                  inductionVariable_6 = inductionVariable_6 + 1 | 0;
                  deform_0[i_6] = deform_0[i_6] + (lastVertices[i_6] - ensureNotNull(setupVertices_2)[i_6]) * alpha_0;
                }
                 while (inductionVariable_6 < vertexCount);
            } else {
              var inductionVariable_7 = 0;
              if (inductionVariable_7 < vertexCount)
                do {
                  var i_7 = inductionVariable_7;
                  inductionVariable_7 = inductionVariable_7 + 1 | 0;
                  deform_0[i_7] = deform_0[i_7] + lastVertices[i_7] * alpha_0;
                }
                 while (inductionVariable_7 < vertexCount);
            }

            break;
          default:
            noWhenBranchMatchedException();
            break;
        }
      }
      return Unit_instance;
    }
    var frame = Companion_instance_9.mpc(frames, time);
    var prevVertices = frameVertices[frame - 1 | 0];
    var nextVertices = frameVertices[frame];
    var frameTime = frames[frame];
    var percent = this.op9(frame - 1 | 0, 1 - (time - frameTime) / (frames[frame - 1 | 0] - frameTime));
    if (alpha_0 === 1.0) {
      if (blend_0.equals(MixBlend_add_getInstance())) {
        if (slotAttachment.vpc_1 == null) {
          var setupVertices_3 = slotAttachment.wpc_1;
          var inductionVariable_8 = 0;
          if (inductionVariable_8 < vertexCount)
            do {
              var i_8 = inductionVariable_8;
              inductionVariable_8 = inductionVariable_8 + 1 | 0;
              var prev = prevVertices[i_8];
              deform_0[i_8] = deform_0[i_8] + (prev + (nextVertices[i_8] - prev) * percent - ensureNotNull(setupVertices_3)[i_8]);
            }
             while (inductionVariable_8 < vertexCount);
        } else {
          var inductionVariable_9 = 0;
          if (inductionVariable_9 < vertexCount)
            do {
              var i_9 = inductionVariable_9;
              inductionVariable_9 = inductionVariable_9 + 1 | 0;
              var prev_0 = prevVertices[i_9];
              deform_0[i_9] = deform_0[i_9] + (prev_0 + (nextVertices[i_9] - prev_0) * percent);
            }
             while (inductionVariable_9 < vertexCount);
        }
      } else {
        var inductionVariable_10 = 0;
        if (inductionVariable_10 < vertexCount)
          do {
            var i_10 = inductionVariable_10;
            inductionVariable_10 = inductionVariable_10 + 1 | 0;
            var prev_1 = prevVertices[i_10];
            deform_0[i_10] = prev_1 + (nextVertices[i_10] - prev_1) * percent;
          }
           while (inductionVariable_10 < vertexCount);
      }
    } else {
      switch (blend_0.v3_1) {
        case 0:
          if (slotAttachment.vpc_1 == null) {
            var setupVertices_4 = slotAttachment.wpc_1;
            var inductionVariable_11 = 0;
            if (inductionVariable_11 < vertexCount)
              do {
                var i_11 = inductionVariable_11;
                inductionVariable_11 = inductionVariable_11 + 1 | 0;
                var prev_2 = prevVertices[i_11];
                var setup_0 = ensureNotNull(setupVertices_4)[i_11];
                deform_0[i_11] = setup_0 + (prev_2 + (nextVertices[i_11] - prev_2) * percent - setup_0) * alpha_0;
              }
               while (inductionVariable_11 < vertexCount);
          } else {
            var inductionVariable_12 = 0;
            if (inductionVariable_12 < vertexCount)
              do {
                var i_12 = inductionVariable_12;
                inductionVariable_12 = inductionVariable_12 + 1 | 0;
                var prev_3 = prevVertices[i_12];
                deform_0[i_12] = (prev_3 + (nextVertices[i_12] - prev_3) * percent) * alpha_0;
              }
               while (inductionVariable_12 < vertexCount);
          }

          break;
        case 1:
        case 2:
          var inductionVariable_13 = 0;
          if (inductionVariable_13 < vertexCount)
            do {
              var i_13 = inductionVariable_13;
              inductionVariable_13 = inductionVariable_13 + 1 | 0;
              var prev_4 = prevVertices[i_13];
              deform_0[i_13] = deform_0[i_13] + (prev_4 + (nextVertices[i_13] - prev_4) * percent - deform_0[i_13]) * alpha_0;
            }
             while (inductionVariable_13 < vertexCount);
          break;
        case 3:
          if (slotAttachment.vpc_1 == null) {
            var setupVertices_5 = slotAttachment.wpc_1;
            var inductionVariable_14 = 0;
            if (inductionVariable_14 < vertexCount)
              do {
                var i_14 = inductionVariable_14;
                inductionVariable_14 = inductionVariable_14 + 1 | 0;
                var prev_5 = prevVertices[i_14];
                deform_0[i_14] = deform_0[i_14] + (prev_5 + (nextVertices[i_14] - prev_5) * percent - ensureNotNull(setupVertices_5)[i_14]) * alpha_0;
              }
               while (inductionVariable_14 < vertexCount);
          } else {
            var inductionVariable_15 = 0;
            if (inductionVariable_15 < vertexCount)
              do {
                var i_15 = inductionVariable_15;
                inductionVariable_15 = inductionVariable_15 + 1 | 0;
                var prev_6 = prevVertices[i_15];
                deform_0[i_15] = deform_0[i_15] + (prev_6 + (nextVertices[i_15] - prev_6) * percent) * alpha_0;
              }
               while (inductionVariable_15 < vertexCount);
          }

          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
    }
  }
}
class EventTimeline {
  constructor(frameCount) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(frameCount > 0)) {
      // Inline function 'com.esotericsoftware.spine.EventTimeline.<anonymous>' call
      var message = 'frameCount must be > 0: ' + frameCount;
      throw IllegalArgumentException.l(toString(message));
    }
    this.apd_1 = new Float32Array(frameCount);
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_0 = fillArrayVal(Array(frameCount), null);
    tmp.bpd_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
  }
  jp9() {
    return TimelineType_event_getInstance().v3_1 << 24;
  }
  cpd(frameIndex, event) {
    this.apd_1[frameIndex] = event.dpd_1;
    this.bpd_1[frameIndex] = event;
  }
  kp9(skeleton, lastTime, time, firedEvents, alpha, blend, direction) {
    var lastTime_0 = lastTime;
    if (firedEvents == null)
      return Unit_instance;
    var frames = this.apd_1;
    var frameCount = frames.length;
    if (lastTime_0 > time) {
      this.kp9(skeleton, lastTime_0, 2147483647, firedEvents, alpha, blend, direction);
      lastTime_0 = -1.0;
    } else if (lastTime_0 >= frames[frameCount - 1 | 0])
      return Unit_instance;
    if (time < frames[0])
      return Unit_instance;
    var frame;
    if (lastTime_0 < frames[0])
      frame = 0;
    else {
      frame = Companion_instance_9.mpc(frames, lastTime_0);
      var frameTime = frames[frame];
      $l$loop: while (frame > 0 && frames[frame - 1 | 0] === frameTime) {
        frame = frame - 1 | 0;
      }
    }
    while (frame < frameCount && time >= frames[frame]) {
      firedEvents.f(this.bpd_1[frame]);
      frame = frame + 1 | 0;
    }
  }
}
class DrawOrderTimeline {
  constructor(frameCount) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(frameCount > 0)) {
      // Inline function 'com.esotericsoftware.spine.DrawOrderTimeline.<anonymous>' call
      var message = 'frameCount must be > 0: ' + frameCount;
      throw IllegalArgumentException.l(toString(message));
    }
    this.kpd_1 = new Float32Array(frameCount);
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.lpd_1 = fillArrayVal(Array(frameCount), null);
  }
  jp9() {
    return TimelineType_drawOrder_getInstance().v3_1 << 24;
  }
  mpd(frameIndex, time, drawOrder) {
    this.kpd_1[frameIndex] = time;
    this.lpd_1[frameIndex] = drawOrder;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var drawOrder = skeleton.hp7_1;
    var slots = skeleton.gp7_1;
    if (direction.equals(MixDirection_out_getInstance())) {
      if (blend.equals(MixBlend_setup_getInstance())) {
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.arraycopy' call
        var length = slots.j1();
        arraycopy_0(slots, 0, drawOrder, 0, length);
      }
      return Unit_instance;
    }
    var frames = this.kpd_1;
    if (time < frames[0]) {
      if (blend.equals(MixBlend_setup_getInstance()) || blend.equals(MixBlend_first_getInstance())) {
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.arraycopy' call
        var length_0 = slots.j1();
        arraycopy_0(slots, 0, drawOrder, 0, length_0);
      }
      return Unit_instance;
    }
    var frame;
    if (time >= frames[frames.length - 1 | 0])
      frame = frames.length - 1 | 0;
    else
      frame = Companion_instance_9.mpc(frames, time) - 1 | 0;
    var drawOrderToSetupIndex = this.lpd_1[frame];
    if (drawOrderToSetupIndex == null) {
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.arraycopy' call
      var length_1 = slots.j1();
      arraycopy_0(slots, 0, drawOrder, 0, length_1);
    } else {
      var i = 0;
      var n = drawOrderToSetupIndex.length;
      while (i < n) {
        setAndGrow(drawOrder, i, slots.i1(drawOrderToSetupIndex[i]));
        i = i + 1 | 0;
      }
    }
  }
}
class IkConstraintTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.opd_1 = 0;
    this.ppd_1 = new Float32Array(imul(frameCount, Companion_instance_5.dp8_1));
  }
  jp9() {
    return (TimelineType_ikConstraint_getInstance().v3_1 << 24) + this.opd_1 | 0;
  }
  qpd(frameIndex, time, mix, softness, bendDirection, compress, stretch) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = imul(frameIndex_0, Companion_instance_5.dp8_1);
    this.ppd_1[frameIndex_0] = time;
    this.ppd_1[frameIndex_0 + Companion_instance_5.kp8_1 | 0] = mix;
    this.ppd_1[frameIndex_0 + Companion_instance_5.lp8_1 | 0] = softness;
    this.ppd_1[frameIndex_0 + Companion_instance_5.mp8_1 | 0] = bendDirection;
    this.ppd_1[frameIndex_0 + Companion_instance_5.np8_1 | 0] = compress ? 1 : 0;
    this.ppd_1[frameIndex_0 + Companion_instance_5.op8_1 | 0] = stretch ? 1 : 0;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var constraint = skeleton.ip7_1.i1(this.opd_1);
    if (!constraint.zpd_1)
      return Unit_instance;
    var frames = this.ppd_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          constraint.xpd_1 = constraint.rpd_1.jpe_1;
          constraint.ypd_1 = constraint.rpd_1.kpe_1;
          constraint.upd_1 = constraint.rpd_1.fpe_1;
          constraint.vpd_1 = constraint.rpd_1.gpe_1;
          constraint.wpd_1 = constraint.rpd_1.hpe_1;
          return Unit_instance;
        case 1:
          constraint.xpd_1 = constraint.xpd_1 + (constraint.rpd_1.jpe_1 - constraint.xpd_1) * alpha;
          constraint.ypd_1 = constraint.ypd_1 + (constraint.rpd_1.kpe_1 - constraint.ypd_1) * alpha;
          constraint.upd_1 = constraint.rpd_1.fpe_1;
          constraint.vpd_1 = constraint.rpd_1.gpe_1;
          constraint.wpd_1 = constraint.rpd_1.hpe_1;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    if (time >= frames[frames.length - Companion_instance_5.dp8_1 | 0]) {
      if (blend.equals(MixBlend_setup_getInstance())) {
        constraint.xpd_1 = constraint.rpd_1.jpe_1 + (frames[frames.length + Companion_instance_5.fp8_1 | 0] - constraint.rpd_1.jpe_1) * alpha;
        constraint.ypd_1 = constraint.rpd_1.kpe_1 + (frames[frames.length + Companion_instance_5.gp8_1 | 0] - constraint.rpd_1.kpe_1) * alpha;
        if (direction.equals(MixDirection_out_getInstance())) {
          constraint.upd_1 = constraint.rpd_1.fpe_1;
          constraint.vpd_1 = constraint.rpd_1.gpe_1;
          constraint.wpd_1 = constraint.rpd_1.hpe_1;
        } else {
          constraint.upd_1 = numberToInt(frames[frames.length + Companion_instance_5.hp8_1 | 0]);
          constraint.vpd_1 = !(frames[frames.length + Companion_instance_5.ip8_1 | 0] === 0.0);
          constraint.wpd_1 = !(frames[frames.length + Companion_instance_5.jp8_1 | 0] === 0.0);
        }
      } else {
        constraint.xpd_1 = constraint.xpd_1 + (frames[frames.length + Companion_instance_5.fp8_1 | 0] - constraint.xpd_1) * alpha;
        constraint.ypd_1 = constraint.ypd_1 + (frames[frames.length + Companion_instance_5.gp8_1 | 0] - constraint.ypd_1) * alpha;
        if (direction.equals(MixDirection_in_getInstance())) {
          constraint.upd_1 = numberToInt(frames[frames.length + Companion_instance_5.hp8_1 | 0]);
          constraint.vpd_1 = !(frames[frames.length + Companion_instance_5.ip8_1 | 0] === 0.0);
          constraint.wpd_1 = !(frames[frames.length + Companion_instance_5.jp8_1 | 0] === 0.0);
        }
      }
      return Unit_instance;
    }
    var frame = Companion_instance_9.jpb(frames, time, Companion_instance_5.dp8_1);
    var mix = frames[frame + Companion_instance_5.fp8_1 | 0];
    var softness = frames[frame + Companion_instance_5.gp8_1 | 0];
    var frameTime = frames[frame];
    var percent = this.op9((frame / Companion_instance_5.dp8_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_5.ep8_1 | 0] - frameTime));
    if (blend.equals(MixBlend_setup_getInstance())) {
      constraint.xpd_1 = constraint.rpd_1.jpe_1 + (mix + (frames[frame + Companion_instance_5.kp8_1 | 0] - mix) * percent - constraint.rpd_1.jpe_1) * alpha;
      constraint.ypd_1 = constraint.rpd_1.kpe_1 + (softness + (frames[frame + Companion_instance_5.lp8_1 | 0] - softness) * percent - constraint.rpd_1.kpe_1) * alpha;
      if (direction.equals(MixDirection_out_getInstance())) {
        constraint.upd_1 = constraint.rpd_1.fpe_1;
        constraint.vpd_1 = constraint.rpd_1.gpe_1;
        constraint.wpd_1 = constraint.rpd_1.hpe_1;
      } else {
        constraint.upd_1 = numberToInt(frames[frame + Companion_instance_5.hp8_1 | 0]);
        constraint.vpd_1 = !(frames[frame + Companion_instance_5.ip8_1 | 0] === 0.0);
        constraint.wpd_1 = !(frames[frame + Companion_instance_5.jp8_1 | 0] === 0.0);
      }
    } else {
      constraint.xpd_1 = constraint.xpd_1 + (mix + (frames[frame + Companion_instance_5.kp8_1 | 0] - mix) * percent - constraint.xpd_1) * alpha;
      constraint.ypd_1 = constraint.ypd_1 + (softness + (frames[frame + Companion_instance_5.lp8_1 | 0] - softness) * percent - constraint.ypd_1) * alpha;
      if (direction.equals(MixDirection_in_getInstance())) {
        constraint.upd_1 = numberToInt(frames[frame + Companion_instance_5.hp8_1 | 0]);
        constraint.vpd_1 = !(frames[frame + Companion_instance_5.ip8_1 | 0] === 0.0);
        constraint.wpd_1 = !(frames[frame + Companion_instance_5.jp8_1 | 0] === 0.0);
      }
    }
  }
}
class TransformConstraintTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.mpe_1 = 0;
    this.npe_1 = new Float32Array(imul(frameCount, Companion_instance_6.pp8_1));
  }
  jp9() {
    return (TimelineType_transformConstraint_getInstance().v3_1 << 24) + this.mpe_1 | 0;
  }
  zpb(frameIndex, time, rotateMix, translateMix, scaleMix, shearMix) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = imul(frameIndex_0, Companion_instance_6.pp8_1);
    this.npe_1[frameIndex_0] = time;
    this.npe_1[frameIndex_0 + Companion_instance_6.vp8_1 | 0] = rotateMix;
    this.npe_1[frameIndex_0 + Companion_instance_6.wp8_1 | 0] = translateMix;
    this.npe_1[frameIndex_0 + Companion_instance_6.xp8_1 | 0] = scaleMix;
    this.npe_1[frameIndex_0 + Companion_instance_6.yp8_1 | 0] = shearMix;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var constraint = skeleton.jp7_1.i1(this.mpe_1);
    if (!constraint.vpe_1)
      return Unit_instance;
    var frames = this.npe_1;
    if (time < frames[0]) {
      var data = constraint.ope_1;
      switch (blend.v3_1) {
        case 0:
          constraint.rpe_1 = data.cpf_1;
          constraint.spe_1 = data.dpf_1;
          constraint.tpe_1 = data.epf_1;
          constraint.upe_1 = data.fpf_1;
          return Unit_instance;
        case 1:
          constraint.rpe_1 = constraint.rpe_1 + (data.cpf_1 - constraint.rpe_1) * alpha;
          constraint.spe_1 = constraint.spe_1 + (data.dpf_1 - constraint.spe_1) * alpha;
          constraint.tpe_1 = constraint.tpe_1 + (data.epf_1 - constraint.tpe_1) * alpha;
          constraint.upe_1 = constraint.upe_1 + (data.fpf_1 - constraint.upe_1) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var rotate;
    var translate;
    var scale;
    var shear;
    if (time >= frames[frames.length - Companion_instance_6.pp8_1 | 0]) {
      var i = frames.length;
      rotate = frames[i + Companion_instance_6.rp8_1 | 0];
      translate = frames[i + Companion_instance_6.sp8_1 | 0];
      scale = frames[i + Companion_instance_6.tp8_1 | 0];
      shear = frames[i + Companion_instance_6.up8_1 | 0];
    } else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_6.pp8_1);
      rotate = frames[frame + Companion_instance_6.rp8_1 | 0];
      translate = frames[frame + Companion_instance_6.sp8_1 | 0];
      scale = frames[frame + Companion_instance_6.tp8_1 | 0];
      shear = frames[frame + Companion_instance_6.up8_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_6.pp8_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_6.qp8_1 | 0] - frameTime));
      rotate = rotate + (frames[frame + Companion_instance_6.vp8_1 | 0] - rotate) * percent;
      translate = translate + (frames[frame + Companion_instance_6.wp8_1 | 0] - translate) * percent;
      scale = scale + (frames[frame + Companion_instance_6.xp8_1 | 0] - scale) * percent;
      shear = shear + (frames[frame + Companion_instance_6.yp8_1 | 0] - shear) * percent;
    }
    if (blend.equals(MixBlend_setup_getInstance())) {
      var data_0 = constraint.ope_1;
      constraint.rpe_1 = data_0.cpf_1 + (rotate - data_0.cpf_1) * alpha;
      constraint.spe_1 = data_0.dpf_1 + (translate - data_0.dpf_1) * alpha;
      constraint.tpe_1 = data_0.epf_1 + (scale - data_0.epf_1) * alpha;
      constraint.upe_1 = data_0.fpf_1 + (shear - data_0.fpf_1) * alpha;
    } else {
      constraint.rpe_1 = constraint.rpe_1 + (rotate - constraint.rpe_1) * alpha;
      constraint.spe_1 = constraint.spe_1 + (translate - constraint.spe_1) * alpha;
      constraint.tpe_1 = constraint.tpe_1 + (scale - constraint.tpe_1) * alpha;
      constraint.upe_1 = constraint.upe_1 + (shear - constraint.upe_1) * alpha;
    }
  }
}
class PathConstraintPositionTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.ppf_1 = 0;
    this.qpf_1 = new Float32Array(imul(frameCount, Companion_instance_7.zp8_1));
  }
  jp9() {
    return (TimelineType_pathConstraintPosition_getInstance().v3_1 << 24) + this.ppf_1 | 0;
  }
  tp9(frameIndex, time, position) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = imul(frameIndex_0, Companion_instance_7.zp8_1);
    this.qpf_1[frameIndex_0] = time;
    this.qpf_1[frameIndex_0 + Companion_instance_7.cp9_1 | 0] = position;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var constraint = skeleton.kp7_1.i1(this.ppf_1);
    if (!constraint.ypf_1)
      return Unit_instance;
    var frames = this.qpf_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          constraint.upf_1 = constraint.rpf_1.opg_1;
          return Unit_instance;
        case 1:
          constraint.upf_1 = constraint.upf_1 + (constraint.rpf_1.opg_1 - constraint.upf_1) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var position;
    if (time >= frames[frames.length - Companion_instance_7.zp8_1 | 0])
      position = frames[frames.length + Companion_instance_7.bp9_1 | 0];
    else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_7.zp8_1);
      position = frames[frame + Companion_instance_7.bp9_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_7.zp8_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_7.ap9_1 | 0] - frameTime));
      position = position + (frames[frame + Companion_instance_7.cp9_1 | 0] - position) * percent;
    }
    if (blend.equals(MixBlend_setup_getInstance()))
      constraint.upf_1 = constraint.rpf_1.opg_1 + (position - constraint.rpf_1.opg_1) * alpha;
    else {
      constraint.upf_1 = constraint.upf_1 + (position - constraint.upf_1) * alpha;
    }
  }
}
class PathConstraintSpacingTimeline extends PathConstraintPositionTimeline {
  jp9() {
    return (TimelineType_pathConstraintSpacing_getInstance().v3_1 << 24) + this.ppf_1 | 0;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var constraint = skeleton.kp7_1.i1(this.ppf_1);
    if (!constraint.ypf_1)
      return Unit_instance;
    var frames = this.qpf_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          constraint.vpf_1 = constraint.rpf_1.ppg_1;
          return Unit_instance;
        case 1:
          constraint.vpf_1 = constraint.vpf_1 + (constraint.rpf_1.ppg_1 - constraint.vpf_1) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var spacing;
    if (time >= frames[frames.length - Companion_instance_7.zp8_1 | 0])
      spacing = frames[frames.length + Companion_instance_7.bp9_1 | 0];
    else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_7.zp8_1);
      spacing = frames[frame + Companion_instance_7.bp9_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_7.zp8_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_7.ap9_1 | 0] - frameTime));
      spacing = spacing + (frames[frame + Companion_instance_7.cp9_1 | 0] - spacing) * percent;
    }
    if (blend.equals(MixBlend_setup_getInstance()))
      constraint.vpf_1 = constraint.rpf_1.ppg_1 + (spacing - constraint.rpf_1.ppg_1) * alpha;
    else {
      constraint.vpf_1 = constraint.vpf_1 + (spacing - constraint.vpf_1) * alpha;
    }
  }
}
class PathConstraintMixTimeline extends CurveTimeline {
  constructor(frameCount) {
    super(frameCount);
    this.wpg_1 = 0;
    this.xpg_1 = new Float32Array(imul(frameCount, Companion_instance_8.dp9_1));
  }
  jp9() {
    return (TimelineType_pathConstraintMix_getInstance().v3_1 << 24) + this.wpg_1 | 0;
  }
  opb(frameIndex, time, rotateMix, translateMix) {
    var frameIndex_0 = frameIndex;
    frameIndex_0 = imul(frameIndex_0, Companion_instance_8.dp9_1);
    this.xpg_1[frameIndex_0] = time;
    this.xpg_1[frameIndex_0 + Companion_instance_8.hp9_1 | 0] = rotateMix;
    this.xpg_1[frameIndex_0 + Companion_instance_8.ip9_1 | 0] = translateMix;
  }
  kp9(skeleton, lastTime, time, events, alpha, blend, direction) {
    var constraint = skeleton.kp7_1.i1(this.wpg_1);
    if (!constraint.ypf_1)
      return Unit_instance;
    var frames = this.xpg_1;
    if (time < frames[0]) {
      switch (blend.v3_1) {
        case 0:
          constraint.wpf_1 = constraint.rpf_1.qpg_1;
          constraint.xpf_1 = constraint.rpf_1.rpg_1;
          return Unit_instance;
        case 1:
          constraint.wpf_1 = constraint.wpf_1 + (constraint.rpf_1.qpg_1 - constraint.wpf_1) * alpha;
          constraint.xpf_1 = constraint.xpf_1 + (constraint.rpf_1.rpg_1 - constraint.xpf_1) * alpha;
          break;
        default:
          break;
      }
      return Unit_instance;
    }
    var rotate;
    var translate;
    if (time >= frames[frames.length - Companion_instance_8.dp9_1 | 0]) {
      rotate = frames[frames.length + Companion_instance_8.fp9_1 | 0];
      translate = frames[frames.length + Companion_instance_8.gp9_1 | 0];
    } else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_8.dp9_1);
      rotate = frames[frame + Companion_instance_8.fp9_1 | 0];
      translate = frames[frame + Companion_instance_8.gp9_1 | 0];
      var frameTime = frames[frame];
      var percent = this.op9((frame / Companion_instance_8.dp9_1 | 0) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_8.ep9_1 | 0] - frameTime));
      rotate = rotate + (frames[frame + Companion_instance_8.hp9_1 | 0] - rotate) * percent;
      translate = translate + (frames[frame + Companion_instance_8.ip9_1 | 0] - translate) * percent;
    }
    if (blend.equals(MixBlend_setup_getInstance())) {
      constraint.wpf_1 = constraint.rpf_1.qpg_1 + (rotate - constraint.rpf_1.qpg_1) * alpha;
      constraint.xpf_1 = constraint.rpf_1.rpg_1 + (translate - constraint.rpf_1.rpg_1) * alpha;
    } else {
      constraint.wpf_1 = constraint.wpf_1 + (rotate - constraint.wpf_1) * alpha;
      constraint.xpf_1 = constraint.xpf_1 + (translate - constraint.xpf_1) * alpha;
    }
  }
}
class Companion_8 {
  jpb(values, target, step) {
    var low = 0;
    var high = (values.length / step | 0) - 2 | 0;
    if (high === 0)
      return step;
    var current = high >>> 1 | 0;
    while (true) {
      if (values[imul(current + 1 | 0, step)] <= target)
        low = current + 1 | 0;
      else
        high = current;
      if (low === high)
        return imul(low + 1 | 0, step);
      current = (low + high | 0) >>> 1 | 0;
    }
  }
  mpc(values, target) {
    var low = 0;
    var high = values.length - 2 | 0;
    if (high === 0)
      return 1;
    var current = high >>> 1 | 0;
    while (true) {
      if (values[current + 1 | 0] <= target)
        low = current + 1 | 0;
      else
        high = current;
      if (low === high)
        return low + 1 | 0;
      current = (low + high | 0) >>> 1 | 0;
    }
  }
}
class Animation {
  constructor(name, timelines, duration) {
    this.ypg_1 = name;
    this.zpg_1 = duration;
    this.bph_1 = new IntSet();
    this.cph(timelines);
  }
  dph() {
    var tmp = this.aph_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('timelines');
    }
  }
  cph(timelines) {
    this.aph_1 = timelines;
    this.bph_1.o3();
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = timelines.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'com.esotericsoftware.spine.Animation.setTimelines.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var timeline = array[_unary__edvuaz];
      this.bph_1.q35(timeline.jp9());
    }
  }
  eph(id) {
    return this.bph_1.dp(id);
  }
  toString() {
    var tmp0_elvis_lhs = this.ypg_1;
    return tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs;
  }
}
class TrackEntry {
  constructor() {
    this.fph_1 = null;
    this.gph_1 = null;
    this.hph_1 = null;
    this.iph_1 = null;
    this.jph_1 = null;
    this.kph_1 = 0;
    this.lph_1 = false;
    this.mph_1 = false;
    this.nph_1 = 0;
    this.oph_1 = 0;
    this.pph_1 = 0;
    this.qph_1 = 0;
    this.rph_1 = 0;
    this.sph_1 = 0;
    this.tph_1 = 0;
    this.uph_1 = 0;
    this.vph_1 = 0;
    this.wph_1 = 0;
    this.xph_1 = 0;
    this.yph_1 = 0;
    this.zph_1 = 0;
    this.api_1 = 0;
    this.bpi_1 = 0;
    this.cpi_1 = 0;
    this.dpi_1 = 0;
    this.epi_1 = 0;
    this.fpi_1 = MixBlend_replace_getInstance();
    this.gpi_1 = IntArrayList.f31();
    this.hpi_1 = FastArrayList.g14();
    this.ipi_1 = FloatArrayList.t30();
  }
  xpi() {
    if (this.lph_1) {
      var duration = this.rph_1 - this.qph_1;
      return duration === 0.0 ? this.qph_1 : this.vph_1 % duration + this.qph_1;
    }
    // Inline function 'kotlin.math.min' call
    var a = this.vph_1 + this.qph_1;
    var b = this.rph_1;
    return Math.min(a, b);
  }
  z33() {
    this.gph_1 = null;
    this.hph_1 = null;
    this.iph_1 = null;
    this.fph_1 = null;
    this.jph_1 = null;
    this.gpi_1.o3();
    this.hpi_1.o3();
    this.ipi_1.o3();
  }
  toString() {
    return this.fph_1 == null ? '<none>' : ensureNotNull(this.fph_1).ypg_1;
  }
}
class EventQueue {
  constructor($outer) {
    this.vpi_1 = $outer;
    this.tpi_1 = FastArrayList.g14();
    this.upi_1 = false;
  }
  ipj(entry) {
    this.tpi_1.f(EventType_start_getInstance());
    this.tpi_1.f(entry);
    this.vpi_1.ppi_1 = true;
  }
  hpj(entry) {
    this.tpi_1.f(EventType_interrupt_getInstance());
    this.tpi_1.f(entry);
  }
  wpi(entry) {
    this.tpi_1.f(EventType_end_getInstance());
    this.tpi_1.f(entry);
    this.vpi_1.ppi_1 = true;
  }
  opj(entry) {
    this.tpi_1.f(EventType_dispose_getInstance());
    this.tpi_1.f(entry);
  }
  gpj(entry) {
    this.tpi_1.f(EventType_complete_getInstance());
    this.tpi_1.f(entry);
  }
  fpj(entry, event) {
    this.tpi_1.f(EventType_event_getInstance());
    this.tpi_1.f(entry);
    this.tpi_1.f(event);
  }
  ppj() {
    if (this.upi_1)
      return Unit_instance;
    this.upi_1 = true;
    var objects = this.tpi_1;
    var listeners = this.vpi_1.mpi_1;
    var i = 0;
    while (i < objects.j1()) {
      var tmp = objects.i1(i);
      var type = tmp instanceof EventType ? tmp : THROW_CCE();
      var tmp_0 = objects.i1(i + 1 | 0);
      var entry = tmp_0 instanceof TrackEntry ? tmp_0 : THROW_CCE();
      switch (type.v3_1) {
        case 0:
          if (!(entry.jph_1 == null)) {
            ensureNotNull(entry.jph_1).ipj(entry);
          }

          var inductionVariable = 0;
          var last = listeners.j1();
          if (inductionVariable < last)
            do {
              var ii = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              listeners.i1(ii).ipj(entry);
            }
             while (inductionVariable < last);
          break;
        case 1:
          if (!(entry.jph_1 == null)) {
            ensureNotNull(entry.jph_1).hpj(entry);
          }

          var inductionVariable_0 = 0;
          var last_0 = listeners.j1();
          if (inductionVariable_0 < last_0)
            do {
              var ii_0 = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              listeners.i1(ii_0).hpj(entry);
            }
             while (inductionVariable_0 < last_0);
          break;
        case 2:
          if (!(entry.jph_1 == null)) {
            ensureNotNull(entry.jph_1).wpi(entry);
          }

          var inductionVariable_1 = 0;
          var last_1 = listeners.j1();
          if (inductionVariable_1 < last_1)
            do {
              var ii_1 = inductionVariable_1;
              inductionVariable_1 = inductionVariable_1 + 1 | 0;
              listeners.i1(ii_1).wpi(entry);
            }
             while (inductionVariable_1 < last_1);
          if (!(entry.jph_1 == null)) {
            ensureNotNull(entry.jph_1).opj(entry);
          }

          var inductionVariable_2 = 0;
          var last_2 = listeners.j1();
          if (inductionVariable_2 < last_2)
            do {
              var ii_2 = inductionVariable_2;
              inductionVariable_2 = inductionVariable_2 + 1 | 0;
              listeners.i1(ii_2).opj(entry);
            }
             while (inductionVariable_2 < last_2);
          this.vpi_1.spi_1.h39(entry);
          break;
        case 3:
          if (!(entry.jph_1 == null)) {
            ensureNotNull(entry.jph_1).opj(entry);
          }

          var inductionVariable_3 = 0;
          var last_3 = listeners.j1();
          if (inductionVariable_3 < last_3)
            do {
              var ii_3 = inductionVariable_3;
              inductionVariable_3 = inductionVariable_3 + 1 | 0;
              listeners.i1(ii_3).opj(entry);
            }
             while (inductionVariable_3 < last_3);
          this.vpi_1.spi_1.h39(entry);
          break;
        case 4:
          if (!(entry.jph_1 == null)) {
            ensureNotNull(entry.jph_1).gpj(entry);
          }

          var inductionVariable_4 = 0;
          var last_4 = listeners.j1();
          if (inductionVariable_4 < last_4)
            do {
              var ii_4 = inductionVariable_4;
              inductionVariable_4 = inductionVariable_4 + 1 | 0;
              listeners.i1(ii_4).gpj(entry);
            }
             while (inductionVariable_4 < last_4);
          break;
        case 5:
          var _unary__edvuaz = i;
          i = _unary__edvuaz + 1 | 0;
          var tmp_1 = objects.i1(_unary__edvuaz + 2 | 0);
          var event = tmp_1 instanceof Event ? tmp_1 : THROW_CCE();
          if (!(entry.jph_1 == null)) {
            ensureNotNull(entry.jph_1).fpj(entry, event);
          }

          var inductionVariable_5 = 0;
          var last_5 = listeners.j1();
          if (inductionVariable_5 < last_5)
            do {
              var ii_5 = inductionVariable_5;
              inductionVariable_5 = inductionVariable_5 + 1 | 0;
              listeners.i1(ii_5).fpj(entry, event);
            }
             while (inductionVariable_5 < last_5);
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      i = i + 2 | 0;
    }
    this.o3();
    this.upi_1 = false;
  }
  o3() {
    this.tpi_1.o3();
  }
}
class EventType extends Enum {}
class Companion_9 {
  constructor() {
    Companion_instance_10 = this;
    this.ypi_1 = new Animation('<empty>', FastArrayList.f14(0), 0.0);
    this.zpi_1 = 0;
    this.apj_1 = 1;
    this.bpj_1 = 2;
    this.cpj_1 = 3;
    this.dpj_1 = 1;
    this.epj_1 = 2;
  }
}
class AnimationState {
  qpj(_set____db54di) {
    this.ppi_1 = _set____db54di;
  }
  rpj() {
    return this.ppi_1;
  }
  static spj() {
    Companion_getInstance_14();
    var $this = createThis(this);
    init_com_esotericsoftware_spine_AnimationState($this);
    return $this;
  }
  static tpj(data) {
    Companion_getInstance_14();
    var $this = createThis(this);
    init_com_esotericsoftware_spine_AnimationState($this);
    $this.jpi_1 = data;
    return $this;
  }
  upj(delta) {
    var delta_0 = delta;
    delta_0 = delta_0 * this.qpi_1;
    var i = 0;
    var n = this.kpi_1.j1();
    $l$loop_2: while (i < n) {
      var current = this.kpi_1.i1(i);
      if (current == null) {
        i = i + 1 | 0;
        continue $l$loop_2;
      }
      current.sph_1 = current.tph_1;
      current.wph_1 = current.xph_1;
      var currentDelta = delta_0 * current.zph_1;
      if (current.uph_1 > 0) {
        current.uph_1 = current.uph_1 - currentDelta;
        if (current.uph_1 > 0) {
          i = i + 1 | 0;
          continue $l$loop_2;
        }
        currentDelta = -current.uph_1;
        current.uph_1 = 0.0;
      }
      var next = current.gph_1;
      if (!(next == null)) {
        var nextTime = current.wph_1 - next.uph_1;
        if (nextTime >= 0) {
          next.uph_1 = 0.0;
          var _receiver__tnumb7 = next;
          _receiver__tnumb7.vph_1 = _receiver__tnumb7.vph_1 + (current.zph_1 === 0.0 ? 0.0 : (nextTime / current.zph_1 + delta_0) * next.zph_1);
          current.vph_1 = current.vph_1 + currentDelta;
          setCurrent(this, i, next, true);
          while (!(ensureNotNull(next).hph_1 == null)) {
            var _receiver__tnumb7_0 = next;
            _receiver__tnumb7_0.bpi_1 = _receiver__tnumb7_0.bpi_1 + delta_0;
            next = next.hph_1;
          }
          i = i + 1 | 0;
          continue $l$loop_2;
        }
      } else if (current.wph_1 >= current.yph_1 && current.hph_1 == null) {
        setAndGrow(this.kpi_1, i, null);
        this.npi_1.wpi(current);
        disposeNext(this, current);
        continue $l$loop_2;
      }
      if (!(current.hph_1 == null) && updateMixingFrom(this, current, delta_0)) {
        var from = current.hph_1;
        current.hph_1 = null;
        if (!(from == null))
          from.iph_1 = null;
        while (!(from == null)) {
          this.npi_1.wpi(from);
          from = from.hph_1;
        }
      }
      current.vph_1 = current.vph_1 + currentDelta;
      i = i + 1 | 0;
    }
    this.npi_1.ppj();
  }
  vpj(skeleton) {
    if (this.ppi_1) {
      this.wpj();
    }
    var events = this.lpi_1;
    var applied = false;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i = 0;
    var n = this.kpi_1.j1();
    $l$loop: while (i < n) {
      var current = this.kpi_1.i1(i);
      if (current == null || current.uph_1 > 0) {
        i = i + 1 | 0;
        continue $l$loop;
      }
      applied = true;
      var blend = i === 0 ? MixBlend_first_getInstance() : current.fpi_1;
      var mix = current.api_1;
      if (!(current.hph_1 == null))
        mix = mix * applyMixingFrom(this, current, skeleton, blend);
      else if (current.vph_1 >= current.yph_1 && current.gph_1 == null)
        mix = 0.0;
      var animationLast = current.sph_1;
      var animationTime = current.xpi();
      var timelineCount = ensureNotNull(current.fph_1).dph().j1();
      var timelines = ensureNotNull(current.fph_1).dph();
      if (i === 0 && mix === 1.0 || blend.equals(MixBlend_add_getInstance())) {
        var inductionVariable = 0;
        if (inductionVariable < timelineCount)
          do {
            var ii = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var timeline = timelines.i1(ii);
            if (timeline instanceof AttachmentTimeline) {
              applyAttachmentTimeline(this, timeline, skeleton, animationTime, blend, true);
            } else {
              (isInterface(timeline, Timeline) ? timeline : THROW_CCE()).kp9(skeleton, animationLast, animationTime, events, mix, blend, MixDirection_in_getInstance());
            }
          }
           while (inductionVariable < timelineCount);
      } else {
        var timelineMode = current.gpi_1.d31_1;
        var firstFrame = !(current.ipi_1.j1() === timelineCount << 1);
        if (firstFrame) {
          setSize_0(current.ipi_1, timelineCount << 1);
        }
        var timelinesRotation = current.ipi_1.r30_1;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < timelineCount)
          do {
            var ii_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var tmp = timelines.i1(ii_0);
            var timeline_0 = isInterface(tmp, Timeline) ? tmp : THROW_CCE();
            var timelineBlend = timelineMode[ii_0] === Companion_getInstance_14().zpi_1 ? blend : MixBlend_setup_getInstance();
            if (timeline_0 instanceof RotateTimeline) {
              applyRotateTimeline(this, timeline_0, skeleton, animationTime, mix, timelineBlend, timelinesRotation, ii_0 << 1, firstFrame);
            } else {
              if (timeline_0 instanceof AttachmentTimeline) {
                applyAttachmentTimeline(this, timeline_0, skeleton, animationTime, blend, true);
              } else {
                timeline_0.kp9(skeleton, animationLast, animationTime, events, mix, timelineBlend, MixDirection_in_getInstance());
              }
            }
          }
           while (inductionVariable_0 < timelineCount);
      }
      queueEvents(this, current, animationTime);
      events.o3();
      current.tph_1 = animationTime;
      current.xph_1 = current.vph_1;
      i = i + 1 | 0;
    }
    var setupState = this.rpi_1 + Companion_getInstance_14().dpj_1 | 0;
    var slots = skeleton.gp7_1;
    var i_0 = 0;
    var n_0 = skeleton.gp7_1.j1();
    while (i_0 < n_0) {
      var tmp_0 = slots.i1(i_0);
      var slot = tmp_0 instanceof Slot ? tmp_0 : THROW_CCE();
      if (slot.bp8_1 === setupState) {
        var attachmentName = slot.up7_1.fpc_1;
        slot.cp8(attachmentName == null ? null : skeleton.tp7(slot.up7_1.apc_1, attachmentName));
      }
      i_0 = i_0 + 1 | 0;
    }
    this.rpi_1 = this.rpi_1 + 2 | 0;
    this.npi_1.ppj();
    return applied;
  }
  xpj(trackIndex, animationName, loop) {
    var tmp0_elvis_lhs = ensureNotNull(this.jpi_1).jpj_1.rpk(animationName);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException.l('Animation not found: ' + animationName);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var animation = tmp;
    return this.spk(trackIndex, animation, loop);
  }
  spk(trackIndex, animation, loop) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(trackIndex >= 0)) {
      // Inline function 'com.esotericsoftware.spine.AnimationState.setAnimation.<anonymous>' call
      var message = 'trackIndex must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    var interrupt = true;
    var current = expandToIndex(this, trackIndex);
    if (!(current == null)) {
      if (current.xph_1 === -1.0) {
        setAndGrow(this.kpi_1, trackIndex, current.hph_1);
        this.npi_1.hpj(current);
        this.npi_1.wpi(current);
        disposeNext(this, current);
        current = current.hph_1;
        interrupt = false;
      } else {
        disposeNext(this, current);
      }
    }
    var entry = trackEntry(this, trackIndex, animation, loop, current);
    setCurrent(this, trackIndex, entry, interrupt);
    this.npi_1.ppj();
    return entry;
  }
  tpk(trackIndex, animationName, loop, delay) {
    var tmp0_elvis_lhs = ensureNotNull(this.jpi_1).jpj_1.rpk(animationName);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException.l('Animation not found: ' + animationName);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var animation = tmp;
    return this.upk(trackIndex, animation, loop, delay);
  }
  upk(trackIndex, animation, loop, delay) {
    var delay_0 = delay;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(trackIndex >= 0)) {
      // Inline function 'com.esotericsoftware.spine.AnimationState.addAnimation.<anonymous>' call
      var message = 'trackIndex must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    var last = expandToIndex(this, trackIndex);
    if (!(last == null)) {
      while (!(ensureNotNull(last).gph_1 == null))
        last = last.gph_1;
    }
    var entry = trackEntry(this, trackIndex, animation, loop, last);
    if (last == null) {
      setCurrent(this, trackIndex, entry, true);
      this.npi_1.ppj();
    } else {
      last.gph_1 = entry;
      if (delay_0 <= 0) {
        var duration = last.rph_1 - last.qph_1;
        if (!(duration === 0.0)) {
          if (last.lph_1)
            delay_0 = delay_0 + duration * (1 + numberToInt(last.vph_1 / duration) | 0);
          else {
            var tmp = delay_0;
            // Inline function 'kotlin.math.max' call
            var b = last.vph_1;
            delay_0 = tmp + Math.max(duration, b);
          }
          delay_0 = delay_0 - ensureNotNull(this.jpi_1).npj(last.fph_1, animation);
        } else
          delay_0 = last.vph_1;
      }
    }
    entry.uph_1 = delay_0;
    return entry;
  }
  wpj() {
    this.ppi_1 = false;
    this.opi_1.q38(2048);
    var i = 0;
    var n = this.kpi_1.j1();
    $l$loop: while (i < n) {
      var entry = this.kpi_1.i1(i);
      if (entry == null) {
        i = i + 1 | 0;
        continue $l$loop;
      }
      while (!(ensureNotNull(entry).hph_1 == null))
        entry = entry.hph_1;
      do {
        if (ensureNotNull(entry).iph_1 == null || !entry.fpi_1.equals(MixBlend_add_getInstance())) {
          computeHold(this, entry);
        }
        entry = entry.iph_1;
      }
       while (!(entry == null));
      i = i + 1 | 0;
    }
  }
  toString() {
    var buffer = StringBuilder.nc(64);
    var i = 0;
    var n = this.kpi_1.j1();
    $l$loop: while (i < n) {
      var entry = this.kpi_1.i1(i);
      if (entry == null) {
        i = i + 1 | 0;
        continue $l$loop;
      }
      if (buffer.a() > 0) {
        buffer.ic(', ');
      }
      buffer.ic(entry.toString());
      i = i + 1 | 0;
    }
    return buffer.a() === 0 ? '<none>' : buffer.toString();
  }
}
class FloatHolder {
  constructor(value) {
    this.vpk_1 = value;
  }
  toString() {
    return 'FloatHolder(value=' + this.vpk_1 + ')';
  }
  hashCode() {
    return getNumberHashCode(this.vpk_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FloatHolder))
      return false;
    var tmp0_other_with_cast = other instanceof FloatHolder ? other : THROW_CCE();
    if (!equals(this.vpk_1, tmp0_other_with_cast.vpk_1))
      return false;
    return true;
  }
}
class Key {
  constructor() {
    this.wpk_1 = null;
    this.xpk_1 = null;
  }
  hashCode() {
    return imul(31, 31 + hashCode(ensureNotNull(this.wpk_1)) | 0) + hashCode(ensureNotNull(this.xpk_1)) | 0;
  }
  equals(obj) {
    if (this === obj)
      return true;
    if (obj == null)
      return false;
    var other = (obj == null ? true : obj instanceof Key) ? obj : THROW_CCE();
    if (this.wpk_1 == null) {
      if (!(ensureNotNull(other).wpk_1 == null))
        return false;
    } else if (!equals(this.wpk_1, ensureNotNull(other).wpk_1))
      return false;
    if (this.xpk_1 == null) {
      if (!(other.xpk_1 == null))
        return false;
    } else if (!equals(this.xpk_1, other.xpk_1))
      return false;
    return true;
  }
  toString() {
    return ensureNotNull(this.wpk_1).ypg_1 + '->' + ensureNotNull(this.xpk_1).ypg_1;
  }
}
class AnimationStateData {
  constructor(skeletonData) {
    this.jpj_1 = skeletonData;
    this.kpj_1 = HashMap.o9(51, 0.8);
    this.lpj_1 = new Key();
    this.mpj_1 = 0;
  }
  ypk(fromName, toName, duration) {
    var tmp0_elvis_lhs = this.jpj_1.rpk(fromName);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException.l('Animation not found: ' + fromName);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var from = tmp;
    var tmp1_elvis_lhs = this.jpj_1.rpk(toName);
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      throw IllegalArgumentException.l('Animation not found: ' + toName);
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var to = tmp_0;
    this.zpk(from, to, duration);
  }
  zpk(from, to, duration) {
    var key = new Key();
    key.wpk_1 = from;
    key.xpk_1 = to;
    // Inline function 'kotlin.collections.getOrPut' call
    var this_0 = this.kpj_1;
    var value = this_0.k3(key);
    var tmp;
    if (value == null) {
      // Inline function 'com.esotericsoftware.spine.AnimationStateData.setMix.<anonymous>' call
      var answer = new FloatHolder(0.0);
      this_0.r3(key, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    var holder = tmp;
    holder.vpk_1 = duration;
  }
  npj(from, to) {
    this.lpj_1.wpk_1 = from;
    this.lpj_1.xpk_1 = to;
    var holder = this.kpj_1.k3(this.lpj_1);
    return !(holder == null) ? holder.vpk_1 : this.mpj_1;
  }
}
class Companion_10 {
  constructor() {
    Companion_instance_11 = this;
    this.apl_1 = values();
  }
}
class BlendMode extends Enum {}
class Bone {
  static bpl(data, skeleton, parent) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_Bone($this);
    $this.up9_1 = data;
    $this.vp9_1 = skeleton;
    $this.wp9_1 = parent;
    $this.cpl();
    return $this;
  }
  dpl() {
    this.epl(this.yp9_1, this.zp9_1, this.apa_1, this.bpa_1, this.cpa_1, this.dpa_1, this.epa_1);
  }
  epl(x, y, rotation, scaleX, scaleY, shearX, shearY) {
    this.fpa_1 = x;
    this.gpa_1 = y;
    this.hpa_1 = rotation;
    this.ipa_1 = scaleX;
    this.jpa_1 = scaleY;
    this.kpa_1 = shearX;
    this.lpa_1 = shearY;
    this.mpa_1 = true;
    var parent = this.wp9_1;
    if (parent == null) {
      var skeleton = this.vp9_1;
      var rotationY = rotation + 90.0 + shearY;
      var sx = skeleton.pp7_1;
      var sy = skeleton.qp7_1;
      var tmp = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call
      // Inline function 'kotlin.math.cos' call
      var x_0 = (rotation + shearX) * 0.017453292;
      tmp.npa_1 = Math.cos(x_0) * scaleX * sx;
      var tmp_0 = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call
      // Inline function 'kotlin.math.cos' call
      var x_1 = rotationY * 0.017453292;
      tmp_0.opa_1 = Math.cos(x_1) * scaleY * sx;
      var tmp_1 = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call
      // Inline function 'kotlin.math.sin' call
      var x_2 = (rotation + shearX) * 0.017453292;
      tmp_1.qpa_1 = Math.sin(x_2) * scaleX * sy;
      var tmp_2 = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call
      // Inline function 'kotlin.math.sin' call
      var x_3 = rotationY * 0.017453292;
      tmp_2.rpa_1 = Math.sin(x_3) * scaleY * sy;
      this.ppa_1 = x * sx + skeleton.rp7_1;
      this.spa_1 = y * sy + skeleton.sp7_1;
      return Unit_instance;
    }
    var pa = parent.npa_1;
    var pb = parent.opa_1;
    var pc = parent.qpa_1;
    var pd = parent.rpa_1;
    this.ppa_1 = pa * x + pb * y + parent.ppa_1;
    this.spa_1 = pc * x + pd * y + parent.spa_1;
    switch (this.up9_1.gpb_1.v3_1) {
      case 0:
        var rotationY_0 = rotation + 90.0 + shearY;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_4 = (rotation + shearX) * 0.017453292;
        var la = Math.cos(x_4) * scaleX;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_5 = rotationY_0 * 0.017453292;
        var lb = Math.cos(x_5) * scaleY;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_6 = (rotation + shearX) * 0.017453292;
        var lc = Math.sin(x_6) * scaleX;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_7 = rotationY_0 * 0.017453292;
        var ld = Math.sin(x_7) * scaleY;
        this.npa_1 = pa * la + pb * lc;
        this.opa_1 = pa * lb + pb * ld;
        this.qpa_1 = pc * la + pd * lc;
        this.rpa_1 = pc * lb + pd * ld;
        return Unit_instance;
      case 1:
        var rotationY_1 = rotation + 90.0 + shearY;
        var tmp_3 = this;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_8 = (rotation + shearX) * 0.017453292;
        tmp_3.npa_1 = Math.cos(x_8) * scaleX;
        var tmp_4 = this;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_9 = rotationY_1 * 0.017453292;
        tmp_4.opa_1 = Math.cos(x_9) * scaleY;
        var tmp_5 = this;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_10 = (rotation + shearX) * 0.017453292;
        tmp_5.qpa_1 = Math.sin(x_10) * scaleX;
        var tmp_6 = this;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_11 = rotationY_1 * 0.017453292;
        tmp_6.rpa_1 = Math.sin(x_11) * scaleY;
        break;
      case 2:
        var s = pa * pa + pc * pc;
        var prx;
        if (s > 1.0E-4) {
          // Inline function 'kotlin.math.abs' call
          var x_12 = pa * pd - pb * pc;
          s = Math.abs(x_12) / s;
          pa = pa / this.vp9_1.pp7_1;
          pc = pc / this.vp9_1.qp7_1;
          pb = pc * s;
          pd = pa * s;
          // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
          var y_0 = pc;
          // Inline function 'kotlin.math.atan2' call
          var x_13 = pa;
          prx = Math.atan2(y_0, x_13) * 57.295776;
        } else {
          pa = 0.0;
          pc = 0.0;
          // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
          var y_1 = pd;
          // Inline function 'kotlin.math.atan2' call
          var x_14 = pb;
          prx = 90 - Math.atan2(y_1, x_14) * 57.295776;
        }

        var rx = rotation + shearX - prx;
        var ry = rotation + shearY - prx + 90;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_15 = rx * 0.017453292;
        var la_0 = Math.cos(x_15) * scaleX;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_16 = ry * 0.017453292;
        var lb_0 = Math.cos(x_16) * scaleY;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_17 = rx * 0.017453292;
        var lc_0 = Math.sin(x_17) * scaleX;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_18 = ry * 0.017453292;
        var ld_0 = Math.sin(x_18) * scaleY;
        this.npa_1 = pa * la_0 - pb * lc_0;
        this.opa_1 = pa * lb_0 - pb * ld_0;
        this.qpa_1 = pc * la_0 + pd * lc_0;
        this.rpa_1 = pc * lb_0 + pd * ld_0;
        break;
      case 3:
      case 4:
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_19 = rotation * 0.017453292;
        var cos = Math.cos(x_19);
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_20 = rotation * 0.017453292;
        var sin = Math.sin(x_20);
        var za = (pa * cos + pb * sin) / this.vp9_1.pp7_1;
        var zc = (pc * cos + pd * sin) / this.vp9_1.qp7_1;
        // Inline function 'kotlin.math.sqrt' call

        var x_21 = za * za + zc * zc;
        var s_0 = Math.sqrt(x_21);
        if (s_0 > 1.0E-5)
          s_0 = 1 / s_0;
        za = za * s_0;
        zc = zc * s_0;
        // Inline function 'kotlin.math.sqrt' call

        var x_22 = za * za + zc * zc;
        s_0 = Math.sqrt(x_22);
        if (this.up9_1.gpb_1.equals(TransformMode_noScale_getInstance()) && !(pa * pd - pb * pc < 0 === !(this.vp9_1.pp7_1 < 0 === this.vp9_1.qp7_1 < 0)))
          s_0 = -s_0;
        var tmp_7 = 3.1415927 / 2;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call

        var y_2 = zc;
        // Inline function 'kotlin.math.atan2' call

        var x_23 = za;
        var r = tmp_7 + Math.atan2(y_2, x_23);
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cos' call

        // Inline function 'kotlin.math.cos' call

        var zb = Math.cos(r) * s_0;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sin' call

        // Inline function 'kotlin.math.sin' call

        var zd = Math.sin(r) * s_0;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_24 = shearX * 0.017453292;
        var la_1 = Math.cos(x_24) * scaleX;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cosDeg' call

        // Inline function 'kotlin.math.cos' call

        var x_25 = (90 + shearY) * 0.017453292;
        var lb_1 = Math.cos(x_25) * scaleY;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_26 = shearX * 0.017453292;
        var lc_1 = Math.sin(x_26) * scaleX;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sinDeg' call

        // Inline function 'kotlin.math.sin' call

        var x_27 = (90 + shearY) * 0.017453292;
        var ld_1 = Math.sin(x_27) * scaleY;
        this.npa_1 = za * la_1 + zb * lc_1;
        this.opa_1 = za * lb_1 + zb * ld_1;
        this.qpa_1 = zc * la_1 + zd * lc_1;
        this.rpa_1 = zc * lb_1 + zd * ld_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    this.npa_1 = this.npa_1 * this.vp9_1.pp7_1;
    this.opa_1 = this.opa_1 * this.vp9_1.pp7_1;
    this.qpa_1 = this.qpa_1 * this.vp9_1.qp7_1;
    this.rpa_1 = this.rpa_1 * this.vp9_1.qp7_1;
  }
  fpl(x, y, rotation, scaleX, scaleY, shearX, shearY, $super) {
    x = x === VOID ? this.yp9_1 : x;
    y = y === VOID ? this.zp9_1 : y;
    rotation = rotation === VOID ? this.apa_1 : rotation;
    scaleX = scaleX === VOID ? this.bpa_1 : scaleX;
    scaleY = scaleY === VOID ? this.cpa_1 : scaleY;
    shearX = shearX === VOID ? this.dpa_1 : shearX;
    shearY = shearY === VOID ? this.epa_1 : shearY;
    var tmp;
    if ($super === VOID) {
      this.epl(x, y, rotation, scaleX, scaleY, shearX, shearY);
      tmp = Unit_instance;
    } else {
      tmp = $super.epl.call(this, x, y, rotation, scaleX, scaleY, shearX, shearY);
    }
    return tmp;
  }
  cpl() {
    var data = this.up9_1;
    this.yp9_1 = data.zpa_1;
    this.zp9_1 = data.apb_1;
    this.apa_1 = data.bpb_1;
    this.bpa_1 = data.cpb_1;
    this.cpa_1 = data.dpb_1;
    this.dpa_1 = data.epb_1;
    this.epa_1 = data.fpb_1;
  }
  gpl() {
    this.mpa_1 = true;
    var parent = this.wp9_1;
    if (parent == null) {
      this.fpa_1 = this.ppa_1;
      this.gpa_1 = this.spa_1;
      var tmp = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      var y = this.qpa_1;
      // Inline function 'kotlin.math.atan2' call
      var x = this.npa_1;
      tmp.hpa_1 = Math.atan2(y, x) * 57.295776;
      var tmp_0 = this;
      // Inline function 'kotlin.math.sqrt' call
      var x_0 = this.npa_1 * this.npa_1 + this.qpa_1 * this.qpa_1;
      tmp_0.ipa_1 = Math.sqrt(x_0);
      var tmp_1 = this;
      // Inline function 'kotlin.math.sqrt' call
      var x_1 = this.opa_1 * this.opa_1 + this.rpa_1 * this.rpa_1;
      tmp_1.jpa_1 = Math.sqrt(x_1);
      this.kpa_1 = 0.0;
      var tmp_2 = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      var y_0 = this.npa_1 * this.opa_1 + this.qpa_1 * this.rpa_1;
      // Inline function 'kotlin.math.atan2' call
      var x_2 = this.npa_1 * this.rpa_1 - this.opa_1 * this.qpa_1;
      tmp_2.lpa_1 = Math.atan2(y_0, x_2) * 57.295776;
      return Unit_instance;
    }
    var pa = parent.npa_1;
    var pb = parent.opa_1;
    var pc = parent.qpa_1;
    var pd = parent.rpa_1;
    var pid = 1 / (pa * pd - pb * pc);
    var dx = this.ppa_1 - parent.ppa_1;
    var dy = this.spa_1 - parent.spa_1;
    this.fpa_1 = dx * pd * pid - dy * pb * pid;
    this.gpa_1 = dy * pa * pid - dx * pc * pid;
    var ia = pid * pd;
    var id = pid * pa;
    var ib = pid * pb;
    var ic = pid * pc;
    var ra = ia * this.npa_1 - ib * this.qpa_1;
    var rb = ia * this.opa_1 - ib * this.rpa_1;
    var rc = id * this.qpa_1 - ic * this.npa_1;
    var rd = id * this.rpa_1 - ic * this.opa_1;
    this.kpa_1 = 0.0;
    var tmp_3 = this;
    // Inline function 'kotlin.math.sqrt' call
    var x_3 = ra * ra + rc * rc;
    tmp_3.ipa_1 = Math.sqrt(x_3);
    if (this.ipa_1 > 1.0E-4) {
      var det = ra * rd - rb * rc;
      this.jpa_1 = det / this.ipa_1;
      var tmp_4 = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var y_1 = ra * rb + rc * rd;
      tmp_4.lpa_1 = Math.atan2(y_1, det) * 57.295776;
      var tmp_5 = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      tmp_5.hpa_1 = Math.atan2(rc, ra) * 57.295776;
    } else {
      this.ipa_1 = 0.0;
      var tmp_6 = this;
      // Inline function 'kotlin.math.sqrt' call
      var x_4 = rb * rb + rd * rd;
      tmp_6.jpa_1 = Math.sqrt(x_4);
      this.lpa_1 = 0.0;
      var tmp_7 = this;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      tmp_7.hpa_1 = 90 - Math.atan2(rd, rb) * 57.295776;
    }
  }
  hpl(local) {
    var x = local.ipl_1;
    var y = local.jpl_1;
    local.ipl_1 = x * this.npa_1 + y * this.opa_1 + this.ppa_1;
    local.jpl_1 = x * this.qpa_1 + y * this.rpa_1 + this.spa_1;
    return local;
  }
  toString() {
    return this.up9_1.wpa_1;
  }
}
class Companion_11 {
  constructor() {
    Companion_instance_12 = this;
    this.kpl_1 = values_0();
  }
}
class TransformMode extends Enum {}
class BoneData {
  static lpl(index, name, parent) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_BoneData($this);
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'com.esotericsoftware.spine.BoneData.<init>.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    $this.vpa_1 = index;
    $this.wpa_1 = name;
    $this.xpa_1 = parent;
    return $this;
  }
  toString() {
    return this.wpa_1;
  }
}
class ConstraintData {
  constructor(name) {
    this.mpl_1 = name;
    this.npl_1 = 0;
    this.opl_1 = false;
  }
  toString() {
    var tmp0_elvis_lhs = this.mpl_1;
    return tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs;
  }
}
class Event {
  constructor(time, data) {
    this.dpd_1 = time;
    this.epd_1 = data;
    this.fpd_1 = 0;
    this.gpd_1 = 0;
    this.ipd_1 = 0;
    this.jpd_1 = 0;
  }
  toString() {
    return this.epd_1.ppl_1;
  }
}
class EventData {
  constructor(name) {
    this.ppl_1 = name;
    this.qpl_1 = 0;
    this.rpl_1 = 0;
    this.tpl_1 = null;
    this.upl_1 = 0;
    this.vpl_1 = 0;
  }
  wpl() {
    var tmp = this.spl_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('stringValue');
    }
  }
  toString() {
    return this.ppl_1;
  }
}
class Companion_12 {
  xpl(bone, targetX, targetY, compress, stretch, uniform, alpha) {
    if (!bone.mpa_1) {
      bone.gpl();
    }
    var p = ensureNotNull(bone.wp9_1);
    var pa = p.npa_1;
    var pb = p.opa_1;
    var pc = p.qpa_1;
    var pd = p.rpa_1;
    var rotationIK = -bone.kpa_1 - bone.hpa_1;
    var tx;
    var ty;
    switch (bone.up9_1.gpb_1.v3_1) {
      case 1:
        tx = targetX - bone.ppa_1;
        ty = targetY - bone.spa_1;
        break;
      case 2:
        // Inline function 'kotlin.math.abs' call

        var x = pa * pd - pb * pc;
        var s = Math.abs(x) / (pa * pa + pc * pc);
        var sa = pa / bone.vp9_1.pp7_1;
        var sc = pc / bone.vp9_1.qp7_1;
        pb = -sc * s * bone.vp9_1.pp7_1;
        pd = sa * s * bone.vp9_1.qp7_1;
        var tmp = rotationIK;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call

        // Inline function 'kotlin.math.atan2' call

        rotationIK = tmp + Math.atan2(sc, sa) * 57.295776;
        var x_0 = targetX - p.ppa_1;
        var y = targetY - p.spa_1;
        var d = pa * pd - pb * pc;
        tx = (x_0 * pd - y * pb) / d - bone.fpa_1;
        ty = (y * pa - x_0 * pc) / d - bone.gpa_1;
        break;
      default:
        var x_1 = targetX - p.ppa_1;
        var y_0 = targetY - p.spa_1;
        var d_0 = pa * pd - pb * pc;
        tx = (x_1 * pd - y_0 * pb) / d_0 - bone.fpa_1;
        ty = (y_0 * pa - x_1 * pc) / d_0 - bone.gpa_1;
        break;
    }
    var tmp_0 = rotationIK;
    // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
    var y_1 = ty;
    // Inline function 'kotlin.math.atan2' call
    var x_2 = tx;
    rotationIK = tmp_0 + Math.atan2(y_1, x_2) * 57.295776;
    if (bone.ipa_1 < 0)
      rotationIK = rotationIK + 180.0;
    if (rotationIK > 180)
      rotationIK = rotationIK - 360.0;
    else if (rotationIK < -180)
      rotationIK = rotationIK + 360.0;
    var sx = bone.ipa_1;
    var sy = bone.jpa_1;
    if (compress || stretch) {
      switch (bone.up9_1.gpb_1.v3_1) {
        case 3:
        case 4:
          tx = targetX - bone.ppa_1;
          ty = targetY - bone.spa_1;
          break;
        default:
          break;
      }
      var b = bone.up9_1.ypa_1 * sx;
      // Inline function 'kotlin.math.sqrt' call
      var x_3 = tx * tx + ty * ty;
      var dd = Math.sqrt(x_3);
      if (compress && dd < b || (stretch && dd > b && b > 1.0E-4)) {
        var s_0 = (dd / b - 1) * alpha + 1;
        sx = sx * s_0;
        if (uniform)
          sy = sy * s_0;
      }
    }
    bone.epl(bone.fpa_1, bone.gpa_1, bone.hpa_1 + rotationIK * alpha, sx, sy, bone.kpa_1, bone.lpa_1);
  }
  ypl(parent, child, targetX, targetY, bendDir, stretch, softness, alpha) {
    var softness_0 = softness;
    if (alpha === 0.0) {
      child.fpl();
      return Unit_instance;
    }
    if (!parent.mpa_1) {
      parent.gpl();
    }
    if (!child.mpa_1) {
      child.gpl();
    }
    var px = parent.fpa_1;
    var py = parent.gpa_1;
    var psx = parent.ipa_1;
    var sx = psx;
    var psy = parent.jpa_1;
    var csx = child.ipa_1;
    var os1;
    var os2;
    var s2;
    if (psx < 0) {
      psx = -psx;
      os1 = 180;
      s2 = -1;
    } else {
      os1 = 0;
      s2 = 1;
    }
    if (psy < 0) {
      psy = -psy;
      s2 = -s2 | 0;
    }
    if (csx < 0) {
      csx = -csx;
      os2 = 180;
    } else
      os2 = 0;
    var cx = 0.0;
    var cy = 0.0;
    var cwx = 0.0;
    var cwy = 0.0;
    var a = 0.0;
    var b = 0.0;
    var c = 0.0;
    var d = 0.0;
    var u = false;
    var id = 0.0;
    var x = 0.0;
    var y = 0.0;
    var dx = 0.0;
    var dy = 0.0;
    var l1 = 0.0;
    var l2 = 0.0;
    var a1 = 0.0;
    var a2 = 0.0;
    cx = child.fpa_1;
    a = parent.npa_1;
    b = parent.opa_1;
    c = parent.qpa_1;
    d = parent.rpa_1;
    // Inline function 'kotlin.math.abs' call
    var x_0 = psx - psy;
    u = Math.abs(x_0) <= 1.0E-4;
    if (!u) {
      cy = 0.0;
      cwx = a * cx + parent.ppa_1;
      cwy = c * cx + parent.spa_1;
    } else {
      cy = child.gpa_1;
      cwx = a * cx + b * cy + parent.ppa_1;
      cwy = c * cx + d * cy + parent.spa_1;
    }
    var pp = ensureNotNull(parent.wp9_1);
    a = pp.npa_1;
    b = pp.opa_1;
    c = pp.qpa_1;
    d = pp.rpa_1;
    id = 1 / (a * d - b * c);
    x = cwx - pp.ppa_1;
    y = cwy - pp.spa_1;
    dx = (x * d - y * b) * id - px;
    dy = (y * a - x * c) * id - py;
    // Inline function 'kotlin.math.sqrt' call
    var x_1 = dx * dx + dy * dy;
    l1 = Math.sqrt(x_1);
    l2 = child.up9_1.ypa_1 * csx;
    if (l1 < 1.0E-4) {
      this.xpl(parent, targetX, targetY, false, stretch, false, alpha);
      child.epl(cx, cy, 0.0, child.ipa_1, child.jpa_1, child.kpa_1, child.lpa_1);
      return Unit_instance;
    }
    x = targetX - pp.ppa_1;
    y = targetY - pp.spa_1;
    var tx = (x * d - y * b) * id - px;
    var ty = (y * a - x * c) * id - py;
    var dd = tx * tx + ty * ty;
    if (!(softness_0 === 0.0)) {
      softness_0 = softness_0 * (psx * (csx + 1) / 2);
      // Inline function 'kotlin.math.sqrt' call
      var x_2 = dd;
      var td = Math.sqrt(x_2);
      var sd = td - l1 - l2 * psx + softness_0;
      if (sd > 0) {
        // Inline function 'kotlin.math.min' call
        var b_0 = sd / (softness_0 * 2);
        var p = Math.min(1.0, b_0) - 1;
        p = (sd - softness_0 * (1 - p * p)) / td;
        tx = tx - p * tx;
        ty = ty - p * ty;
        dd = tx * tx + ty * ty;
      }
    }
    outer: while (true) {
      if (u) {
        l2 = l2 * psx;
        var cos = (dd - l1 * l1 - l2 * l2) / (2 * l1 * l2);
        if (cos < -1)
          cos = -1.0;
        else if (cos > 1) {
          cos = 1.0;
          if (stretch) {
            var tmp = sx;
            // Inline function 'kotlin.math.sqrt' call
            var x_3 = dd;
            sx = tmp * ((Math.sqrt(x_3) / (l1 + l2) - 1) * alpha + 1);
          }
        }
        // Inline function 'kotlin.math.acos' call
        var x_4 = cos;
        a2 = Math.acos(x_4) * bendDir;
        a = l1 + l2 * cos;
        var tmp_0 = l2;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sin' call
        // Inline function 'kotlin.math.sin' call
        var x_5 = a2;
        b = tmp_0 * Math.sin(x_5);
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
        var y_0 = ty * a - tx * b;
        // Inline function 'kotlin.math.atan2' call
        var x_6 = tx * a + ty * b;
        a1 = Math.atan2(y_0, x_6);
      } else {
        a = psx * l2;
        b = psy * l2;
        var aa = a * a;
        var bb = b * b;
        // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
        var y_1 = ty;
        // Inline function 'kotlin.math.atan2' call
        var x_7 = tx;
        var ta = Math.atan2(y_1, x_7);
        c = bb * l1 * l1 + aa * dd - aa * bb;
        var c1 = -2 * bb * l1;
        var c2 = bb - aa;
        d = c1 * c1 - 4 * c2 * c;
        if (d >= 0) {
          // Inline function 'kotlin.math.sqrt' call
          var x_8 = d;
          var q = Math.sqrt(x_8);
          if (c1 < 0)
            q = -q;
          q = -(c1 + q) / 2;
          var r0 = q / c2;
          var r1 = c / q;
          var tmp_1;
          // Inline function 'kotlin.math.abs' call
          var tmp_2 = Math.abs(r0);
          // Inline function 'kotlin.math.abs' call
          if (tmp_2 < Math.abs(r1)) {
            tmp_1 = r0;
          } else {
            tmp_1 = r1;
          }
          var r = tmp_1;
          if (r * r <= dd) {
            // Inline function 'kotlin.math.sqrt' call
            var x_9 = dd - r * r;
            y = Math.sqrt(x_9) * bendDir;
            // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
            // Inline function 'kotlin.math.atan2' call
            var y_2 = y;
            a1 = ta - Math.atan2(y_2, r);
            // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
            var y_3 = y / psy;
            // Inline function 'kotlin.math.atan2' call
            var x_10 = (r - l1) / psx;
            a2 = Math.atan2(y_3, x_10);
            break outer;
          }
        }
        var minAngle = 3.1415927;
        var minX = l1 - a;
        var minDist = minX * minX;
        var minY = 0.0;
        var maxAngle = 0.0;
        var maxX = l1 + a;
        var maxDist = maxX * maxX;
        var maxY = 0.0;
        c = -a * l1 / (aa - bb);
        if (c >= -1 && c <= 1) {
          // Inline function 'kotlin.math.acos' call
          var x_11 = c;
          c = Math.acos(x_11);
          var tmp_3 = a;
          // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cos' call
          // Inline function 'kotlin.math.cos' call
          var x_12 = c;
          x = tmp_3 * Math.cos(x_12) + l1;
          var tmp_4 = b;
          // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sin' call
          // Inline function 'kotlin.math.sin' call
          var x_13 = c;
          y = tmp_4 * Math.sin(x_13);
          d = x * x + y * y;
          if (d < minDist) {
            minAngle = c;
            minDist = d;
            minX = x;
            minY = y;
          }
          if (d > maxDist) {
            maxAngle = c;
            maxDist = d;
            maxX = x;
            maxY = y;
          }
        }
        if (dd <= (minDist + maxDist) / 2) {
          // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
          var y_4 = minY * bendDir;
          // Inline function 'kotlin.math.atan2' call
          var x_14 = minX;
          a1 = ta - Math.atan2(y_4, x_14);
          a2 = minAngle * bendDir;
        } else {
          // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
          var y_5 = maxY * bendDir;
          // Inline function 'kotlin.math.atan2' call
          var x_15 = maxX;
          a1 = ta - Math.atan2(y_5, x_15);
          a2 = maxAngle * bendDir;
        }
      }
      break outer;
    }
    // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
    var y_6 = cy;
    // Inline function 'kotlin.math.atan2' call
    var x_16 = cx;
    var os = Math.atan2(y_6, x_16) * s2;
    var rotation = parent.hpa_1;
    a1 = (a1 - os) * 57.295776 + os1 - rotation;
    if (a1 > 180)
      a1 = a1 - 360.0;
    else if (a1 < -180)
      a1 = a1 + 360.0;
    parent.epl(px, py, rotation + a1 * alpha, sx, parent.jpa_1, 0.0, 0.0);
    rotation = child.hpa_1;
    a2 = ((a2 + os) * 57.295776 - child.kpa_1) * s2 + os2 - rotation;
    if (a2 > 180)
      a2 = a2 - 360.0;
    else if (a2 < -180)
      a2 = a2 + 360.0;
    child.epl(cx, cy, rotation + a2 * alpha, child.ipa_1, child.jpa_1, child.kpa_1, child.lpa_1);
  }
}
class IkConstraint {
  static zpl(data, skeleton) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_IkConstraint($this);
    $this.rpd_1 = data;
    $this.xpd_1 = data.jpe_1;
    $this.ypd_1 = data.kpe_1;
    $this.upd_1 = data.fpe_1;
    $this.vpd_1 = data.gpe_1;
    $this.wpd_1 = data.hpe_1;
    $this.spd_1 = FastArrayList.f14(data.dpe_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = data.dpe_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'com.esotericsoftware.spine.IkConstraint.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var boneData = array[_unary__edvuaz];
      var bone = skeleton.apm(boneData.wpa_1);
      $this.spd_1.f(ensureNotNull(bone));
    }
    $this.tpd_1 = skeleton.apm(data.bpm().wpa_1);
    return $this;
  }
  dpl() {
    var target = this.tpd_1;
    var bones = this.spd_1;
    var tmp24_subject = bones.j1();
    if (tmp24_subject === 1) {
      Companion_instance_13.xpl(first(bones), ensureNotNull(target).ppa_1, target.spa_1, this.vpd_1, this.wpd_1, this.rpd_1.ipe_1, this.xpd_1);
    } else if (tmp24_subject === 2) {
      Companion_instance_13.ypl(first(bones), bones.i1(1), ensureNotNull(target).ppa_1, target.spa_1, this.upd_1, this.wpd_1, this.ypd_1, this.xpd_1);
    }
  }
  toString() {
    var tmp0_elvis_lhs = this.rpd_1.mpl_1;
    return tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs;
  }
}
class IkConstraintData extends ConstraintData {
  constructor(name) {
    super(name);
    this.dpe_1 = FastArrayList.g14();
    this.fpe_1 = 1;
    this.gpe_1 = false;
    this.hpe_1 = false;
    this.ipe_1 = false;
    this.jpe_1 = 1.0;
    this.kpe_1 = 0;
  }
  bpm() {
    var tmp = this.epe_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('target');
    }
  }
}
class Companion_13 {
  constructor() {
    this.cpm_1 = -1;
    this.dpm_1 = -2;
    this.epm_1 = -3;
    this.fpm_1 = 1.0E-5;
  }
}
class PathConstraint {
  bpm() {
    var tmp = this.tpf_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('target');
    }
  }
  static gpm(data, skeleton) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_PathConstraint($this);
    $this.rpf_1 = data;
    $this.spf_1 = FastArrayList.f14(data.ipg_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = data.ipg_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'com.esotericsoftware.spine.PathConstraint.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var boneData = array[_unary__edvuaz];
      $this.spf_1.f(ensureNotNull(skeleton.apm(boneData.wpa_1)));
    }
    $this.tpf_1 = ensureNotNull(skeleton.hpm(data.bpm().bpc_1));
    $this.upf_1 = data.opg_1;
    $this.vpf_1 = data.ppg_1;
    $this.wpf_1 = data.qpg_1;
    $this.xpf_1 = data.rpg_1;
    return $this;
  }
  dpl() {
    var attachment = ensureNotNull(this.bpm()).yp7_1;
    if (!(attachment instanceof PathAttachment))
      return Unit_instance;
    var rotateMix = this.wpf_1;
    var translateMix = this.xpf_1;
    var translate = translateMix > 0;
    var rotate = rotateMix > 0;
    if (!translate && !rotate)
      return Unit_instance;
    var data = this.rpf_1;
    var percentSpacing = data.ipm().equals(SpacingMode_percent_getInstance());
    var rotateMode = data.jpm();
    var tangents = rotateMode.equals(RotateMode_tangent_getInstance());
    var scale = rotateMode.equals(RotateMode_chainScale_getInstance());
    var boneCount = this.spf_1.j1();
    var spacesCount = tangents ? boneCount : boneCount + 1 | 0;
    var bones = this.spf_1;
    var spaces = setSize_0(this.zpf_1, spacesCount);
    var lengths = null;
    var spacing = this.vpf_1;
    if (scale || !percentSpacing) {
      if (scale)
        lengths = setSize_0(this.dpg_1, boneCount);
      var lengthSpacing = data.ipm().equals(SpacingMode_length_getInstance());
      var i = 0;
      var n = spacesCount - 1 | 0;
      while (i < n) {
        var bone = bones.i1(i);
        var setupLength = bone.up9_1.ypa_1;
        if (setupLength < Companion_instance_14.fpm_1) {
          if (scale) {
            ensureNotNull(lengths)[i] = 0.0;
          }
          i = i + 1 | 0;
          spaces[i] = 0.0;
        } else if (percentSpacing) {
          if (scale) {
            var x = setupLength * bone.npa_1;
            var y = setupLength * bone.qpa_1;
            // Inline function 'kotlin.math.sqrt' call
            var x_0 = x * x + y * y;
            var length = Math.sqrt(x_0);
            ensureNotNull(lengths)[i] = length;
          }
          i = i + 1 | 0;
          spaces[i] = spacing;
        } else {
          var x_1 = setupLength * bone.npa_1;
          var y_0 = setupLength * bone.qpa_1;
          // Inline function 'kotlin.math.sqrt' call
          var x_2 = x_1 * x_1 + y_0 * y_0;
          var length_0 = Math.sqrt(x_2);
          if (scale) {
            ensureNotNull(lengths)[i] = length_0;
          }
          i = i + 1 | 0;
          spaces[i] = (lengthSpacing ? setupLength + spacing : spacing) * length_0 / setupLength;
        }
      }
    } else {
      var inductionVariable = 1;
      if (inductionVariable < spacesCount)
        do {
          var i_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          spaces[i_0] = spacing;
        }
         while (inductionVariable < spacesCount);
    }
    var positions = this.lpm(attachment, spacesCount, tangents, data.kpm().equals(PositionMode_percent_getInstance()), percentSpacing);
    var boneX = positions[0];
    var boneY = positions[1];
    var offsetRotation = data.npg_1;
    var tip;
    if (offsetRotation === 0.0)
      tip = rotateMode.equals(RotateMode_chain_getInstance());
    else {
      tip = false;
      var p = ensureNotNull(this.bpm()).vp7_1;
      offsetRotation = offsetRotation * (p.npa_1 * p.rpa_1 - p.opa_1 * p.qpa_1 > 0 ? 0.017453292 : -0.017453292);
    }
    var i_1 = 0;
    var p_0 = 3;
    while (i_1 < boneCount) {
      var bone_0 = bones.i1(i_1);
      bone_0.ppa_1 = bone_0.ppa_1 + (boneX - bone_0.ppa_1) * translateMix;
      bone_0.spa_1 = bone_0.spa_1 + (boneY - bone_0.spa_1) * translateMix;
      var x_3 = positions[p_0];
      var y_1 = positions[p_0 + 1 | 0];
      var dx = x_3 - boneX;
      var dy = y_1 - boneY;
      if (scale) {
        var length_1 = ensureNotNull(lengths)[i_1];
        if (length_1 >= Companion_instance_14.fpm_1) {
          // Inline function 'kotlin.math.sqrt' call
          var x_4 = dx * dx + dy * dy;
          var s = (Math.sqrt(x_4) / length_1 - 1) * rotateMix + 1;
          bone_0.npa_1 = bone_0.npa_1 * s;
          bone_0.qpa_1 = bone_0.qpa_1 * s;
        }
      }
      boneX = x_3;
      boneY = y_1;
      if (rotate) {
        var a = bone_0.npa_1;
        var b = bone_0.opa_1;
        var c = bone_0.qpa_1;
        var d = bone_0.rpa_1;
        var r;
        var cos;
        var sin;
        if (tangents)
          r = positions[p_0 - 1 | 0];
        else if (spaces[i_1 + 1 | 0] < Companion_instance_14.fpm_1)
          r = positions[p_0 + 2 | 0];
        else {
          // Inline function 'kotlin.math.atan2' call
          r = Math.atan2(dy, dx);
        }
        var tmp = r;
        // Inline function 'kotlin.math.atan2' call
        r = tmp - Math.atan2(c, a);
        if (tip) {
          // Inline function 'kotlin.math.cos' call
          var x_5 = r;
          cos = Math.cos(x_5);
          // Inline function 'kotlin.math.sin' call
          var x_6 = r;
          sin = Math.sin(x_6);
          var length_2 = bone_0.up9_1.ypa_1;
          boneX = boneX + (length_2 * (cos * a - sin * c) - dx) * rotateMix;
          boneY = boneY + (length_2 * (sin * a + cos * c) - dy) * rotateMix;
        } else
          r = r + offsetRotation;
        if (r > 3.1415927)
          r = r - 6.2831855;
        else if (r < -3.1415927)
          r = r + 6.2831855;
        r = r * rotateMix;
        // Inline function 'kotlin.math.cos' call
        var x_7 = r;
        cos = Math.cos(x_7);
        // Inline function 'kotlin.math.sin' call
        var x_8 = r;
        sin = Math.sin(x_8);
        bone_0.npa_1 = cos * a - sin * c;
        bone_0.opa_1 = cos * b - sin * d;
        bone_0.qpa_1 = sin * a + cos * c;
        bone_0.rpa_1 = sin * b + cos * d;
      }
      bone_0.mpa_1 = false;
      i_1 = i_1 + 1 | 0;
      p_0 = p_0 + 3 | 0;
    }
  }
  lpm(path, spacesCount, tangents, percentPosition, percentSpacing) {
    var target = this.bpm();
    var position = this.upf_1;
    var spaces = this.zpf_1.r30_1;
    var out = setSize_0(this.apg_1, imul(spacesCount, 3) + 2 | 0);
    var world;
    var closed = path.tpm_1;
    var verticesLength = path.xpc_1;
    var curveCount = verticesLength / 6 | 0;
    var prevCurve = Companion_instance_14.cpm_1;
    if (!path.upm_1) {
      var lengths = path.wpm();
      curveCount = curveCount - (closed ? 1 : 2) | 0;
      var pathLength = lengths[curveCount];
      if (percentPosition)
        position = position * pathLength;
      if (percentSpacing) {
        var inductionVariable = 1;
        if (inductionVariable < spacesCount)
          do {
            var i = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            spaces[i] = spaces[i] * pathLength;
          }
           while (inductionVariable < spacesCount);
      }
      world = setSize_0(this.bpg_1, 8);
      var i_0 = 0;
      var o = 0;
      var curve = 0;
      $l$loop_0: while (i_0 < spacesCount) {
        var space = spaces[i_0];
        position = position + space;
        var p = position;
        if (closed) {
          p = p % pathLength;
          if (p < 0)
            p = p + pathLength;
          curve = 0;
        } else if (p < 0) {
          if (!(prevCurve === Companion_instance_14.dpm_1)) {
            prevCurve = Companion_instance_14.dpm_1;
            path.xpm(ensureNotNull(target), 2, 4, world, 0, 2);
          }
          addBeforePosition(this, p, world, 0, out, o);
          i_0 = i_0 + 1 | 0;
          o = o + 3 | 0;
          continue $l$loop_0;
        } else if (p > pathLength) {
          if (!(prevCurve === Companion_instance_14.epm_1)) {
            prevCurve = Companion_instance_14.epm_1;
            path.xpm(ensureNotNull(target), verticesLength - 6 | 0, 4, world, 0, 2);
          }
          addAfterPosition(this, p - pathLength, world, 0, out, o);
          continue $l$loop_0;
        }
        $l$loop_1: while (true) {
          var length = lengths[curve];
          if (p > length) {
            curve = curve + 1 | 0;
            continue $l$loop_1;
          }
          if (curve === 0)
            p = p / length;
          else {
            var prev = lengths[curve - 1 | 0];
            p = (p - prev) / (length - prev);
          }
          break $l$loop_1;
        }
        if (!(curve === prevCurve)) {
          prevCurve = curve;
          if (closed && curve === curveCount) {
            path.xpm(ensureNotNull(target), verticesLength - 4 | 0, 4, world, 0, 2);
            path.xpm(target, 0, 4, world, 4, 2);
          } else {
            path.xpm(ensureNotNull(target), imul(curve, 6) + 2 | 0, 8, world, 0, 2);
          }
        }
        addCurvePosition(this, p, world[0], world[1], world[2], world[3], world[4], world[5], world[6], world[7], out, o, tangents || (i_0 > 0 && space < Companion_instance_14.fpm_1));
        i_0 = i_0 + 1 | 0;
        o = o + 3 | 0;
      }
      return out;
    }
    if (closed) {
      verticesLength = verticesLength + 2 | 0;
      world = setSize_0(this.bpg_1, verticesLength);
      path.xpm(ensureNotNull(target), 2, verticesLength - 4 | 0, world, 0, 2);
      path.xpm(target, 0, 2, world, verticesLength - 4 | 0, 2);
      world[verticesLength - 2 | 0] = world[0];
      world[verticesLength - 1 | 0] = world[1];
    } else {
      curveCount = curveCount - 1 | 0;
      verticesLength = verticesLength - 4 | 0;
      world = setSize_0(this.bpg_1, verticesLength);
      path.xpm(ensureNotNull(target), 2, verticesLength, world, 0, 2);
    }
    var curves = setSize_0(this.cpg_1, curveCount);
    var pathLength_0 = 0.0;
    var x1 = world[0];
    var y1 = world[1];
    var cx1 = 0.0;
    var cy1 = 0.0;
    var cx2 = 0.0;
    var cy2 = 0.0;
    var x2 = 0.0;
    var y2 = 0.0;
    var tmpx;
    var tmpy;
    var dddfx;
    var dddfy;
    var ddfx;
    var ddfy;
    var dfx;
    var dfy;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_1 = 0;
    var w = 2;
    while (i_1 < curveCount) {
      cx1 = world[w];
      cy1 = world[w + 1 | 0];
      cx2 = world[w + 2 | 0];
      cy2 = world[w + 3 | 0];
      x2 = world[w + 4 | 0];
      y2 = world[w + 5 | 0];
      tmpx = (x1 - cx1 * 2 + cx2) * 0.1875;
      tmpy = (y1 - cy1 * 2 + cy2) * 0.1875;
      dddfx = ((cx1 - cx2) * 3 - x1 + x2) * 0.09375;
      dddfy = ((cy1 - cy2) * 3 - y1 + y2) * 0.09375;
      ddfx = tmpx * 2 + dddfx;
      ddfy = tmpy * 2 + dddfy;
      dfx = (cx1 - x1) * 0.75 + tmpx + dddfx * 0.16666667;
      dfy = (cy1 - y1) * 0.75 + tmpy + dddfy * 0.16666667;
      var tmp = pathLength_0;
      // Inline function 'kotlin.math.sqrt' call
      var x = dfx * dfx + dfy * dfy;
      pathLength_0 = tmp + Math.sqrt(x);
      dfx = dfx + ddfx;
      dfy = dfy + ddfy;
      ddfx = ddfx + dddfx;
      ddfy = ddfy + dddfy;
      var tmp_0 = pathLength_0;
      // Inline function 'kotlin.math.sqrt' call
      var x_0 = dfx * dfx + dfy * dfy;
      pathLength_0 = tmp_0 + Math.sqrt(x_0);
      dfx = dfx + ddfx;
      dfy = dfy + ddfy;
      var tmp_1 = pathLength_0;
      // Inline function 'kotlin.math.sqrt' call
      var x_1 = dfx * dfx + dfy * dfy;
      pathLength_0 = tmp_1 + Math.sqrt(x_1);
      dfx = dfx + (ddfx + dddfx);
      dfy = dfy + (ddfy + dddfy);
      var tmp_2 = pathLength_0;
      // Inline function 'kotlin.math.sqrt' call
      var x_2 = dfx * dfx + dfy * dfy;
      pathLength_0 = tmp_2 + Math.sqrt(x_2);
      curves[i_1] = pathLength_0;
      x1 = x2;
      y1 = y2;
      i_1 = i_1 + 1 | 0;
      w = w + 6 | 0;
    }
    if (percentPosition)
      position = position * pathLength_0;
    else
      position = position * (pathLength_0 / path.wpm()[curveCount - 1 | 0]);
    if (percentSpacing) {
      var inductionVariable_0 = 1;
      if (inductionVariable_0 < spacesCount)
        do {
          var i_2 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          spaces[i_2] = spaces[i_2] * pathLength_0;
        }
         while (inductionVariable_0 < spacesCount);
    }
    var segments = this.epg_1;
    var curveLength = 0.0;
    var i_3 = 0;
    var o_0 = 0;
    var curve_0 = 0;
    var segment = 0;
    $l$loop_3: while (i_3 < spacesCount) {
      var space_0 = spaces[i_3];
      position = position + space_0;
      var p_0 = position;
      if (closed) {
        p_0 = p_0 % pathLength_0;
        if (p_0 < 0)
          p_0 = p_0 + pathLength_0;
        curve_0 = 0;
      } else if (p_0 < 0) {
        addBeforePosition(this, p_0, world, 0, out, o_0);
        i_3 = i_3 + 1 | 0;
        o_0 = o_0 + 3 | 0;
        continue $l$loop_3;
      } else if (p_0 > pathLength_0) {
        addAfterPosition(this, p_0 - pathLength_0, world, verticesLength - 4 | 0, out, o_0);
        continue $l$loop_3;
      }
      $l$loop_4: while (true) {
        var length_0 = curves[curve_0];
        if (p_0 > length_0) {
          curve_0 = curve_0 + 1 | 0;
          continue $l$loop_4;
        }
        if (curve_0 === 0)
          p_0 = p_0 / length_0;
        else {
          var prev_0 = curves[curve_0 - 1 | 0];
          p_0 = (p_0 - prev_0) / (length_0 - prev_0);
        }
        break $l$loop_4;
      }
      if (!(curve_0 === prevCurve)) {
        prevCurve = curve_0;
        var ii = imul(curve_0, 6);
        x1 = world[ii];
        y1 = world[ii + 1 | 0];
        cx1 = world[ii + 2 | 0];
        cy1 = world[ii + 3 | 0];
        cx2 = world[ii + 4 | 0];
        cy2 = world[ii + 5 | 0];
        x2 = world[ii + 6 | 0];
        y2 = world[ii + 7 | 0];
        tmpx = (x1 - cx1 * 2 + cx2) * 0.03;
        tmpy = (y1 - cy1 * 2 + cy2) * 0.03;
        dddfx = ((cx1 - cx2) * 3 - x1 + x2) * 0.006;
        dddfy = ((cy1 - cy2) * 3 - y1 + y2) * 0.006;
        ddfx = tmpx * 2 + dddfx;
        ddfy = tmpy * 2 + dddfy;
        dfx = (cx1 - x1) * 0.3 + tmpx + dddfx * 0.16666667;
        dfy = (cy1 - y1) * 0.3 + tmpy + dddfy * 0.16666667;
        // Inline function 'kotlin.math.sqrt' call
        var x_3 = dfx * dfx + dfy * dfy;
        curveLength = Math.sqrt(x_3);
        segments[0] = curveLength;
        ii = 1;
        while (ii < 8) {
          dfx = dfx + ddfx;
          dfy = dfy + ddfy;
          ddfx = ddfx + dddfx;
          ddfy = ddfy + dddfy;
          var tmp_3 = curveLength;
          // Inline function 'kotlin.math.sqrt' call
          var x_4 = dfx * dfx + dfy * dfy;
          curveLength = tmp_3 + Math.sqrt(x_4);
          segments[ii] = curveLength;
          ii = ii + 1 | 0;
        }
        dfx = dfx + ddfx;
        dfy = dfy + ddfy;
        var tmp_4 = curveLength;
        // Inline function 'kotlin.math.sqrt' call
        var x_5 = dfx * dfx + dfy * dfy;
        curveLength = tmp_4 + Math.sqrt(x_5);
        segments[8] = curveLength;
        dfx = dfx + (ddfx + dddfx);
        dfy = dfy + (ddfy + dddfy);
        var tmp_5 = curveLength;
        // Inline function 'kotlin.math.sqrt' call
        var x_6 = dfx * dfx + dfy * dfy;
        curveLength = tmp_5 + Math.sqrt(x_6);
        segments[9] = curveLength;
        segment = 0;
      }
      p_0 = p_0 * curveLength;
      $l$loop_5: while (true) {
        var length_1 = segments[segment];
        if (p_0 > length_1) {
          segment = segment + 1 | 0;
          continue $l$loop_5;
        }
        if (segment === 0)
          p_0 = p_0 / length_1;
        else {
          var prev_1 = segments[segment - 1 | 0];
          p_0 = segment + (p_0 - prev_1) / (length_1 - prev_1);
        }
        break $l$loop_5;
      }
      addCurvePosition(this, p_0 * 0.1, x1, y1, cx1, cy1, cx2, cy2, x2, y2, out, o_0, tangents || (i_3 > 0 && space_0 < Companion_instance_14.fpm_1));
      i_3 = i_3 + 1 | 0;
      o_0 = o_0 + 3 | 0;
    }
    return out;
  }
  toString() {
    return this.rpf_1.mpl_1;
  }
}
class Companion_14 {
  constructor() {
    Companion_instance_15 = this;
    this.ypm_1 = values_1();
  }
}
class Companion_15 {
  constructor() {
    Companion_instance_16 = this;
    this.zpm_1 = values_2();
  }
}
class Companion_16 {
  constructor() {
    Companion_instance_17 = this;
    this.apn_1 = values_3();
  }
}
class PositionMode extends Enum {}
class SpacingMode extends Enum {}
class RotateMode extends Enum {}
class PathConstraintData extends ConstraintData {
  constructor(name) {
    super(name);
    this.ipg_1 = FastArrayList.g14();
    this.npg_1 = 0;
    this.opg_1 = 0;
    this.ppg_1 = 0;
    this.qpg_1 = 0;
    this.rpg_1 = 0;
  }
  bpm() {
    var tmp = this.jpg_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('target');
    }
  }
  kpm() {
    var tmp = this.kpg_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('positionMode');
    }
  }
  ipm() {
    var tmp = this.lpg_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('spacingMode');
    }
  }
  jpm() {
    var tmp = this.mpg_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('rotateMode');
    }
  }
}
class Skeleton {
  lpn() {
    return this.updateCacheProp;
  }
  static mpn(data) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_Skeleton($this);
    $this.ep7_1 = data;
    $this.fp7_1 = FastArrayList.f14(data.zpj_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = data.zpj_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'com.esotericsoftware.spine.Skeleton.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var boneData = array[_unary__edvuaz];
      var bone;
      if (boneData.xpa_1 == null) {
        bone = Bone.bpl(boneData, $this, null);
      } else {
        var parent = $this.fp7_1.i1(boneData.xpa_1.vpa_1);
        bone = Bone.bpl(boneData, $this, parent);
        parent.xp9_1.f(bone);
      }
      $this.fp7_1.f(bone);
    }
    $this.gp7_1 = FastArrayList.f14(data.apk_1.j1());
    $this.hp7_1 = FastArrayList.f14(data.apk_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array_0 = data.apk_1.e14_1;
    var n_0 = 0;
    while (n_0 < array_0.length) {
      // Inline function 'com.esotericsoftware.spine.Skeleton.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz_0 = n_0;
      n_0 = _unary__edvuaz_0 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var slotData = array_0[_unary__edvuaz_0];
      var bone_0 = $this.fp7_1.i1(slotData.cpc_1.vpa_1);
      var slot = Slot.npn(slotData, bone_0);
      $this.gp7_1.f(slot);
      $this.hp7_1.f(slot);
    }
    $this.ip7_1 = FastArrayList.f14(data.fpk_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array_1 = data.fpk_1.e14_1;
    var n_1 = 0;
    while (n_1 < array_1.length) {
      // Inline function 'com.esotericsoftware.spine.Skeleton.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz_1 = n_1;
      n_1 = _unary__edvuaz_1 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var ikConstraintData = array_1[_unary__edvuaz_1];
      $this.ip7_1.f(IkConstraint.zpl(ikConstraintData, $this));
    }
    $this.jp7_1 = FastArrayList.f14(data.gpk_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array_2 = data.gpk_1.e14_1;
    var n_2 = 0;
    while (n_2 < array_2.length) {
      // Inline function 'com.esotericsoftware.spine.Skeleton.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz_2 = n_2;
      n_2 = _unary__edvuaz_2 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var transformConstraintData = array_2[_unary__edvuaz_2];
      $this.jp7_1.f(TransformConstraint.opn(transformConstraintData, $this));
    }
    $this.kp7_1 = FastArrayList.f14(data.hpk_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array_3 = data.hpk_1.e14_1;
    var n_3 = 0;
    while (n_3 < array_3.length) {
      // Inline function 'com.esotericsoftware.spine.Skeleton.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz_3 = n_3;
      n_3 = _unary__edvuaz_3 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var pathConstraintData = array_3[_unary__edvuaz_3];
      $this.kp7_1.f(PathConstraint.gpm(pathConstraintData, $this));
    }
    $this.np7_1 = _RGBAf___init__impl__idjpgn(1.0, 1.0, 1.0, 1.0);
    $this.ppn();
    return $this;
  }
  ppn() {
    var updateCache = this.updateCacheProp;
    updateCache.o3();
    this.lp7_1.o3();
    var boneCount = this.fp7_1.j1();
    var bones = this.fp7_1;
    var inductionVariable = 0;
    if (inductionVariable < boneCount)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var bone = bones.i1(i);
        bone.tpa_1 = bone.up9_1.hpb_1;
        bone.upa_1 = !bone.tpa_1;
      }
       while (inductionVariable < boneCount);
    if (!(this.mp7_1 == null)) {
      var skinBones = ensureNotNull(this.mp7_1).dpn_1;
      var i_0 = 0;
      var n = ensureNotNull(this.mp7_1).dpn_1.j1();
      while (i_0 < n) {
        var tmp = skinBones.i1(i_0);
        var bone_0 = bones.i1((tmp instanceof BoneData ? tmp : THROW_CCE()).vpa_1);
        do {
          ensureNotNull(bone_0).tpa_1 = false;
          bone_0.upa_1 = true;
          bone_0 = bone_0.wp9_1;
        }
         while (!(bone_0 == null));
        i_0 = i_0 + 1 | 0;
      }
    }
    var ikCount = this.ip7_1.j1();
    var transformCount = this.jp7_1.j1();
    var pathCount = this.kp7_1.j1();
    var ikConstraints = this.ip7_1;
    var transformConstraints = this.jp7_1;
    var pathConstraints = this.kp7_1;
    var constraintCount = (ikCount + transformCount | 0) + pathCount | 0;
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < constraintCount)
      outer: do {
        var i_1 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var inductionVariable_1 = 0;
        if (inductionVariable_1 < ikCount)
          do {
            var ii = inductionVariable_1;
            inductionVariable_1 = inductionVariable_1 + 1 | 0;
            var constraint = ikConstraints.i1(ii);
            if (constraint.rpd_1.npl_1 === i_1) {
              sortIkConstraint(this, constraint);
              continue outer;
            }
          }
           while (inductionVariable_1 < ikCount);
        var inductionVariable_2 = 0;
        if (inductionVariable_2 < transformCount)
          do {
            var ii_0 = inductionVariable_2;
            inductionVariable_2 = inductionVariable_2 + 1 | 0;
            var constraint_0 = transformConstraints.i1(ii_0);
            if (constraint_0.ope_1.npl_1 === i_1) {
              sortTransformConstraint(this, constraint_0);
              continue outer;
            }
          }
           while (inductionVariable_2 < transformCount);
        var inductionVariable_3 = 0;
        if (inductionVariable_3 < pathCount)
          do {
            var ii_1 = inductionVariable_3;
            inductionVariable_3 = inductionVariable_3 + 1 | 0;
            var constraint_1 = pathConstraints.i1(ii_1);
            if (constraint_1.rpf_1.npl_1 === i_1) {
              sortPathConstraint(this, constraint_1);
              continue outer;
            }
          }
           while (inductionVariable_3 < pathCount);
      }
       while (inductionVariable_0 < constraintCount);
    var inductionVariable_4 = 0;
    if (inductionVariable_4 < boneCount)
      do {
        var i_2 = inductionVariable_4;
        inductionVariable_4 = inductionVariable_4 + 1 | 0;
        sortBone(this, bones.i1(i_2));
      }
       while (inductionVariable_4 < boneCount);
  }
  qpn() {
    var updateCacheReset = this.lp7_1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i = 0;
    var n = updateCacheReset.j1();
    while (i < n) {
      var bone = updateCacheReset.i1(i);
      bone.fpa_1 = bone.yp9_1;
      bone.gpa_1 = bone.zp9_1;
      bone.hpa_1 = bone.apa_1;
      bone.ipa_1 = bone.bpa_1;
      bone.jpa_1 = bone.cpa_1;
      bone.kpa_1 = bone.dpa_1;
      bone.lpa_1 = bone.epa_1;
      bone.mpa_1 = true;
      i = i + 1 | 0;
    }
    var updateCache = this.updateCacheProp;
    var i_0 = 0;
    var n_0 = updateCache.j1();
    while (i_0 < n_0) {
      updateCache.i1(i_0).dpl();
      i_0 = i_0 + 1 | 0;
    }
  }
  apm(boneName) {
    var bones = this.fp7_1;
    var inductionVariable = 0;
    var last = bones.j1();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var bone = bones.i1(i);
        if (bone.up9_1.wpa_1 === boneName)
          return bone;
      }
       while (inductionVariable < last);
    return null;
  }
  hpm(slotName) {
    var slots = this.gp7_1;
    var i = 0;
    var n = slots.j1();
    while (i < n) {
      var slot = slots.i1(i);
      if (slot.up7_1.bpc_1 === slotName)
        return slot;
      i = i + 1 | 0;
    }
    return null;
  }
  tp7(slotIndex, attachmentName) {
    if (!(this.mp7_1 == null)) {
      var attachment = ensureNotNull(this.mp7_1).tp7(slotIndex, attachmentName);
      if (!(attachment == null))
        return attachment;
    }
    return !(this.ep7_1.gpn() == null) ? this.ep7_1.gpn().tp7(slotIndex, attachmentName) : null;
  }
  rpn(x, y) {
    this.rp7_1 = x;
    this.sp7_1 = y;
  }
  toString() {
    return !(this.ep7_1.ypj_1 == null) ? ensureNotNull(this.ep7_1.ypj_1) : anyToString(this);
  }
}
class Vertices {
  constructor() {
    this.zpo_1 = null;
    this.app_1 = null;
  }
}
class SkeletonInput {
  constructor(data) {
    this.bpo_1 = data;
    this.cpo_1 = 0;
    this.dpo_1 = charArray(32);
    this.epo_1 = null;
  }
  p1v() {
    if (this.cpo_1 >= this.bpo_1.length) {
      return -1;
    }
    var _unary__edvuaz = this.cpo_1;
    this.cpo_1 = _unary__edvuaz + 1 | 0;
    return this.bpo_1[_unary__edvuaz] & 255;
  }
  u6() {
  }
  wmr() {
    // Inline function 'kotlin.fromBits' call
    var bits = this.rmr();
    return floatFromBits(bits);
  }
  bpq() {
    return !(this.smr() === 0);
  }
  gpo(optimizePositive) {
    var b = this.p1v();
    var result = b & 127;
    if (!((b & 128) === 0)) {
      b = this.p1v();
      result = result | (b & 127) << 7;
      if (!((b & 128) === 0)) {
        b = this.p1v();
        result = result | (b & 127) << 14;
        if (!((b & 128) === 0)) {
          b = this.p1v();
          result = result | (b & 127) << 21;
          if (!((b & 128) === 0)) {
            b = this.p1v();
            result = result | (b & 127) << 28;
          }
        }
      }
    }
    return optimizePositive ? result : (result >>> 1 | 0) ^ (-(result & 1) | 0);
  }
  rmr() {
    var a = this.smr();
    var b = this.smr();
    var c = this.smr();
    var d = this.smr();
    return a << 24 | b << 16 | c << 8 | d;
  }
  smr() {
    var i = this.p1v();
    if (i === -1) {
      // Inline function 'kotlin.error' call
      throw IllegalStateException.m5(toString('EOF'));
    }
    return i;
  }
  y12() {
    return toByte(this.smr());
  }
  cmq() {
    var a = this.smr();
    var b = this.smr();
    return toShort(a << 8 | b);
  }
  fpo() {
    var index = this.gpo(true);
    return index === 0 ? null : ensureNotNull(this.epo_1).i1(index - 1 | 0);
  }
  dm0() {
    var byteCount = this.gpo(true);
    var tmp32_subject = byteCount;
    if (tmp32_subject === 0)
      return null;
    else if (tmp32_subject === 1)
      return '';
    byteCount = byteCount - 1 | 0;
    if (this.dpo_1.length < byteCount)
      this.dpo_1 = charArray(byteCount);
    var chars = this.dpo_1;
    var charCount = 0;
    var i = 0;
    while (i < byteCount) {
      var b = this.p1v();
      switch (b >> 4) {
        case -1:
          // Inline function 'kotlin.error' call

          throw IllegalStateException.m5(toString('EOF'));
        case 12:
        case 13:
          var _unary__edvuaz = charCount;
          charCount = _unary__edvuaz + 1 | 0;
          chars[_unary__edvuaz] = numberToChar((b & 31) << 6 | this.p1v() & 63);
          i = i + 2 | 0;
          break;
        case 14:
          var _unary__edvuaz_0 = charCount;
          charCount = _unary__edvuaz_0 + 1 | 0;
          chars[_unary__edvuaz_0] = numberToChar((b & 15) << 12 | (this.p1v() & 63) << 6 | this.p1v() & 63);
          i = i + 3 | 0;
          break;
        default:
          var _unary__edvuaz_1 = charCount;
          charCount = _unary__edvuaz_1 + 1 | 0;
          chars[_unary__edvuaz_1] = numberToChar(b);
          i = i + 1 | 0;
          break;
      }
    }
    return concatToString(chars, 0, charCount);
  }
}
class Companion_17 {
  constructor() {
    this.spq_1 = 0;
    this.tpq_1 = 1;
    this.upq_1 = 2;
    this.vpq_1 = 3;
    this.wpq_1 = 0;
    this.xpq_1 = 1;
    this.ypq_1 = 2;
    this.zpq_1 = 0;
    this.apr_1 = 1;
    this.bpr_1 = 2;
    this.cpr_1 = 0;
    this.dpr_1 = 1;
    this.epr_1 = 2;
  }
}
class SkeletonBinary {
  ypn(scale) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(scale === 0.0)) {
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.<set-scale>.<anonymous>' call
      var message = 'scale cannot be 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.tpn_1 = scale;
  }
  static xpn(atlas) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_SkeletonBinary($this);
    $this.spn_1 = new AtlasAttachmentLoader(atlas);
    return $this;
  }
  zpn(file, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readSkeletonData__wryeud(this, file, $completion), $completion);
  }
  apo(file, fileName) {
    var scale = this.tpn_1;
    var skeletonData = new SkeletonData();
    skeletonData.ypj_1 = get_baseNameWithoutExtension(_PathInfo___init__impl__vpw96a(fileName));
    var input = new SkeletonInput(file);
    try {
      skeletonData.npk_1 = input.dm0();
      // Inline function 'kotlin.text.isEmpty' call
      var this_0 = ensureNotNull(skeletonData.npk_1);
      if (charSequenceLength(this_0) === 0)
        skeletonData.npk_1 = null;
      skeletonData.mpk_1 = input.dm0();
      // Inline function 'kotlin.text.isEmpty' call
      var this_1 = ensureNotNull(skeletonData.mpk_1);
      if (charSequenceLength(this_1) === 0)
        skeletonData.mpk_1 = null;
      if ('3.8.75' === skeletonData.mpk_1)
        throw RuntimeException.db('Unsupported skeleton data, please export with a newer version of Spine.');
      skeletonData.ipk_1 = input.wmr();
      skeletonData.jpk_1 = input.wmr();
      skeletonData.kpk_1 = input.wmr();
      skeletonData.lpk_1 = input.wmr();
      var nonessential = input.bpq();
      if (nonessential) {
        skeletonData.opk_1 = input.wmr();
        skeletonData.ppk_1 = input.dm0();
        // Inline function 'kotlin.text.isEmpty' call
        var this_2 = ensureNotNull(skeletonData.ppk_1);
        if (charSequenceLength(this_2) === 0)
          skeletonData.ppk_1 = null;
        skeletonData.qpk_1 = input.dm0();
        // Inline function 'kotlin.text.isEmpty' call
        var this_3 = ensureNotNull(skeletonData.qpk_1);
        if (charSequenceLength(this_3) === 0)
          skeletonData.qpk_1 = null;
      }
      var n = 0;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp = input;
      // Inline function 'kotlin.also' call
      var this_4 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_4;
      tmp.epo_1 = FastArrayList.f14(this_4);
      var o = setSize(ensureNotNull(input.epo_1), n);
      var inductionVariable = 0;
      var last = n;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          setAndGrow(o, i, ensureNotNull(input.dm0()));
        }
         while (inductionVariable < last);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.also' call
      var this_5 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_5;
      var o_0 = setSize(skeletonData.zpj_1, this_5);
      var inductionVariable_0 = 0;
      var last_0 = n;
      if (inductionVariable_0 < last_0)
        do {
          var i_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var name = ensureNotNull(input.dm0());
          var parent = i_0 === 0 ? null : skeletonData.zpj_1.i1(input.gpo(true));
          var data = BoneData.lpl(i_0, name, parent);
          data.bpb_1 = input.wmr();
          data.zpa_1 = input.wmr() * scale;
          data.apb_1 = input.wmr() * scale;
          data.cpb_1 = input.wmr();
          data.dpb_1 = input.wmr();
          data.epb_1 = input.wmr();
          data.fpb_1 = input.wmr();
          data.ypa_1 = input.wmr() * scale;
          data.gpb_1 = Companion_getInstance_16().kpl_1[input.gpo(true)];
          data.hpb_1 = input.bpq();
          if (nonessential) {
            rgba8888ToColor(this, data.ipb_1, input.rmr());
          }
          setAndGrow(o_0, i_0, data);
        }
         while (inductionVariable_0 < last_0);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.also' call
      var this_6 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_6;
      var o_1 = setSize(skeletonData.apk_1, this_6);
      var inductionVariable_1 = 0;
      var last_1 = n;
      if (inductionVariable_1 < last_1)
        do {
          var i_1 = inductionVariable_1;
          inductionVariable_1 = inductionVariable_1 + 1 | 0;
          var slotName = ensureNotNull(input.dm0());
          var boneData = skeletonData.zpj_1.i1(input.gpo(true));
          var data_0 = new SlotData(i_1, slotName, boneData);
          rgba8888ToColor(this, data_0.dpc_1, input.rmr());
          var darkColor = input.rmr();
          if (!(darkColor === -1)) {
            // Inline function 'kotlin.also' call
            var this_7 = new RGBAf(_RGBAf___init__impl__idjpgn());
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
            data_0.epc_1 = this_7.b5k_1;
            var tmp$ret$9 = this_7.b5k_1;
            rgb888ToColor(this, tmp$ret$9, darkColor);
          }
          data_0.fpc_1 = input.fpo();
          data_0.gpc_1 = Companion_getInstance_15().apl_1[input.gpo(true)];
          setAndGrow(o_1, i_1, data_0);
        }
         while (inductionVariable_1 < last_1);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.also' call
      var this_8 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_8;
      var o_2 = setSize(skeletonData.fpk_1, this_8);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var i_2 = 0;
      var nn;
      while (i_2 < n) {
        var data_1 = new IkConstraintData(ensureNotNull(input.dm0()));
        data_1.npl_1 = input.gpo(true);
        data_1.opl_1 = input.bpq();
        // Inline function 'kotlin.also' call
        var this_9 = input.gpo(true);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>.<anonymous>' call
        nn = this_9;
        var bones = setSize(data_1.dpe_1, this_9);
        var inductionVariable_2 = 0;
        if (inductionVariable_2 < nn)
          do {
            var ii = inductionVariable_2;
            inductionVariable_2 = inductionVariable_2 + 1 | 0;
            setAndGrow(bones, ii, skeletonData.zpj_1.i1(input.gpo(true)));
          }
           while (inductionVariable_2 < nn);
        data_1.epe_1 = skeletonData.zpj_1.i1(input.gpo(true));
        data_1.jpe_1 = input.wmr();
        data_1.kpe_1 = input.wmr() * scale;
        data_1.fpe_1 = input.y12();
        data_1.gpe_1 = input.bpq();
        data_1.hpe_1 = input.bpq();
        data_1.ipe_1 = input.bpq();
        setAndGrow(o_2, i_2, data_1);
        i_2 = i_2 + 1 | 0;
      }
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.also' call
      var this_10 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_10;
      var o_3 = setSize(skeletonData.gpk_1, this_10);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var i_3 = 0;
      var nn_0;
      while (i_3 < n) {
        var data_2 = new TransformConstraintData(ensureNotNull(input.dm0()));
        data_2.npl_1 = input.gpo(true);
        data_2.opl_1 = input.bpq();
        // Inline function 'kotlin.also' call
        var this_11 = input.gpo(true);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>.<anonymous>' call
        nn_0 = this_11;
        var bones_0 = setSize(data_2.apf_1, this_11);
        var inductionVariable_3 = 0;
        if (inductionVariable_3 < nn_0)
          do {
            var ii_0 = inductionVariable_3;
            inductionVariable_3 = inductionVariable_3 + 1 | 0;
            setAndGrow(bones_0, ii_0, skeletonData.zpj_1.i1(input.gpo(true)));
          }
           while (inductionVariable_3 < nn_0);
        data_2.bpf_1 = skeletonData.zpj_1.i1(input.gpo(true));
        data_2.npf_1 = input.bpq();
        data_2.mpf_1 = input.bpq();
        data_2.gpf_1 = input.wmr();
        data_2.hpf_1 = input.wmr() * scale;
        data_2.ipf_1 = input.wmr() * scale;
        data_2.jpf_1 = input.wmr();
        data_2.kpf_1 = input.wmr();
        data_2.lpf_1 = input.wmr();
        data_2.cpf_1 = input.wmr();
        data_2.dpf_1 = input.wmr();
        data_2.epf_1 = input.wmr();
        data_2.fpf_1 = input.wmr();
        setAndGrow(o_3, i_3, data_2);
        i_3 = i_3 + 1 | 0;
      }
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.also' call
      var this_12 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_12;
      var o_4 = setSize(skeletonData.hpk_1, this_12);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var i_4 = 0;
      var nn_1;
      while (i_4 < n) {
        var data_3 = new PathConstraintData(ensureNotNull(input.dm0()));
        data_3.npl_1 = input.gpo(true);
        data_3.opl_1 = input.bpq();
        // Inline function 'kotlin.also' call
        var this_13 = input.gpo(true);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>.<anonymous>' call
        nn_1 = this_13;
        var bones_1 = setSize(data_3.ipg_1, this_13);
        var inductionVariable_4 = 0;
        if (inductionVariable_4 < nn_1)
          do {
            var ii_1 = inductionVariable_4;
            inductionVariable_4 = inductionVariable_4 + 1 | 0;
            setAndGrow(bones_1, ii_1, skeletonData.zpj_1.i1(input.gpo(true)));
          }
           while (inductionVariable_4 < nn_1);
        data_3.jpg_1 = skeletonData.apk_1.i1(input.gpo(true));
        data_3.kpg_1 = Companion_getInstance_19().ypm_1[input.gpo(true)];
        data_3.lpg_1 = Companion_getInstance_20().zpm_1[input.gpo(true)];
        data_3.mpg_1 = Companion_getInstance_21().apn_1[input.gpo(true)];
        data_3.npg_1 = input.wmr();
        data_3.opg_1 = input.wmr();
        if (data_3.kpm().equals(PositionMode_fixed_getInstance())) {
          data_3.opg_1 = data_3.opg_1 * scale;
        }
        data_3.ppg_1 = input.wmr();
        if (data_3.ipm().equals(SpacingMode_length_getInstance()) || data_3.ipm().equals(SpacingMode_fixed_getInstance())) {
          data_3.ppg_1 = data_3.ppg_1 * scale;
        }
        data_3.qpg_1 = input.wmr();
        data_3.rpg_1 = input.wmr();
        setAndGrow(o_4, i_4, data_3);
        i_4 = i_4 + 1 | 0;
      }
      var defaultSkin = readSkin(this, input, skeletonData, true, nonessential);
      if (!(defaultSkin == null)) {
        skeletonData.cpk_1 = defaultSkin;
        skeletonData.bpk_1.f(defaultSkin);
      }
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var i_5 = skeletonData.bpk_1.j1();
      // Inline function 'kotlin.also' call
      var this_14 = i_5 + input.gpo(true) | 0;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_14;
      var o_5 = setSize(skeletonData.bpk_1, this_14);
      while (i_5 < n) {
        setAndGrow(o_5, i_5, ensureNotNull(readSkin(this, input, skeletonData, false, nonessential)));
        i_5 = i_5 + 1 | 0;
      }
      n = _get_linkedMeshes__ybd6gn(this).j1();
      var inductionVariable_5 = 0;
      var last_2 = n;
      if (inductionVariable_5 < last_2)
        do {
          var i_6 = inductionVariable_5;
          inductionVariable_5 = inductionVariable_5 + 1 | 0;
          var linkedMesh = _get_linkedMeshes__ybd6gn(this).i1(i_6);
          var tmp0_elvis_lhs = linkedMesh.hpr_1 == null ? skeletonData.gpn() : skeletonData.lpr(linkedMesh.hpr_1);
          var tmp_0;
          if (tmp0_elvis_lhs == null) {
            var message = 'Skin not found: ' + ensureNotNull(linkedMesh.hpr_1);
            throw IllegalStateException.m5(toString(message));
          } else {
            tmp_0 = tmp0_elvis_lhs;
          }
          var skin = tmp_0;
          var tmp1_elvis_lhs = skin.tp7(linkedMesh.ipr_1, ensureNotNull(linkedMesh.jpr_1));
          var tmp_1;
          if (tmp1_elvis_lhs == null) {
            var message_0 = 'Parent mesh not found: ' + linkedMesh.jpr_1;
            throw IllegalStateException.m5(toString(message_0));
          } else {
            tmp_1 = tmp1_elvis_lhs;
          }
          var parent_0 = tmp_1;
          var tmp_2 = linkedMesh.gpr_1;
          var tmp_3;
          if (linkedMesh.kpr_1) {
            tmp_3 = parent_0 instanceof VertexAttachment ? parent_0 : THROW_CCE();
          } else {
            tmp_3 = linkedMesh.gpr_1;
          }
          tmp_2.ypc_1 = tmp_3;
          var tmp_4 = linkedMesh.gpr_1;
          tmp_4.mpr(parent_0 instanceof MeshAttachment ? parent_0 : THROW_CCE());
          linkedMesh.gpr_1.apq();
        }
         while (inductionVariable_5 < last_2);
      _get_linkedMeshes__ybd6gn(this).o3();
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.also' call
      var this_15 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_15;
      var o_6 = setSize(skeletonData.dpk_1, this_15);
      var inductionVariable_6 = 0;
      var last_3 = n;
      if (inductionVariable_6 < last_3)
        do {
          var i_7 = inductionVariable_6;
          inductionVariable_6 = inductionVariable_6 + 1 | 0;
          var data_4 = new EventData(ensureNotNull(input.fpo()));
          data_4.qpl_1 = input.gpo(false);
          data_4.rpl_1 = input.wmr();
          data_4.spl_1 = ensureNotNull(input.dm0());
          data_4.tpl_1 = input.dm0();
          if (!(data_4.tpl_1 == null)) {
            data_4.upl_1 = input.wmr();
            data_4.vpl_1 = input.wmr();
          }
          setAndGrow(o_6, i_7, data_4);
        }
         while (inductionVariable_6 < last_3);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.also' call
      var this_16 = input.gpo(true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.esotericsoftware.spine.SkeletonBinary.readSkeletonData.<anonymous>.<anonymous>' call
      n = this_16;
      var o_7 = setSize(skeletonData.epk_1, this_16);
      var inductionVariable_7 = 0;
      var last_4 = n;
      if (inductionVariable_7 < last_4)
        do {
          var i_8 = inductionVariable_7;
          inductionVariable_7 = inductionVariable_7 + 1 | 0;
          setAndGrow(o_7, i_8, readAnimation(this, input, input.dm0(), skeletonData));
        }
         while (inductionVariable_7 < last_4);
    } catch ($p) {
      if ($p instanceof Error) {
        var ex = $p;
        throw RuntimeException.ye('Error reading skeleton file.', ex);
      } else {
        throw $p;
      }
    }
    finally {
      try {
        input.u6();
      } catch ($p) {
        if ($p instanceof Error) {
          var ignored = $p;
        } else {
          throw $p;
        }
      }
    }
    return skeletonData;
  }
  fpr(timeline, frameIndex, cx1, cy1, cx2, cy2) {
    timeline.np9(frameIndex, cx1, cy1, cx2, cy2);
  }
}
class SkeletonData {
  constructor() {
    this.ypj_1 = null;
    this.zpj_1 = FastArrayList.g14();
    this.apk_1 = FastArrayList.g14();
    this.bpk_1 = FastArrayList.g14();
    this.dpk_1 = FastArrayList.g14();
    this.epk_1 = FastArrayList.g14();
    this.fpk_1 = FastArrayList.g14();
    this.gpk_1 = FastArrayList.g14();
    this.hpk_1 = FastArrayList.g14();
    this.ipk_1 = 0;
    this.jpk_1 = 0;
    this.kpk_1 = 0;
    this.lpk_1 = 0;
    this.mpk_1 = null;
    this.npk_1 = null;
    this.opk_1 = 30.0;
    this.ppk_1 = null;
    this.qpk_1 = null;
  }
  gpn() {
    var tmp = this.cpk_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('defaultSkin');
    }
  }
  lpr(skinName) {
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.bpk_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'com.esotericsoftware.spine.SkeletonData.findSkin.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var skin = array[_unary__edvuaz];
      if (skin.bpn_1 === skinName)
        return skin;
    }
    return null;
  }
  rpk(animationName) {
    var animations = this.epk_1;
    var i = 0;
    var n = animations.j1();
    while (i < n) {
      var animation = animations.i1(i);
      if (animation.ypg_1 === animationName)
        return animation;
      i = i + 1 | 0;
    }
    return null;
  }
  toString() {
    var tmp0_elvis_lhs = this.ypj_1;
    return tmp0_elvis_lhs == null ? anyToString(this) : tmp0_elvis_lhs;
  }
}
class LinkedMesh {
  constructor(mesh, skin, slotIndex, parent, inheritDeform) {
    this.gpr_1 = mesh;
    this.hpr_1 = skin;
    this.ipr_1 = slotIndex;
    this.jpr_1 = parent;
    this.kpr_1 = inheritDeform;
  }
}
class SkinEntry {
  w3() {
    var tmp = this.ipn_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('name');
    }
  }
  static npr() {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_Skin_SkinEntry($this);
    $this.opr(0, '');
    return $this;
  }
  static ppr(slotIndex, name, attachment) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_Skin_SkinEntry($this);
    $this.opr(slotIndex, name);
    $this.jpn_1 = attachment;
    return $this;
  }
  opr(slotIndex, name) {
    this.hpn_1 = slotIndex;
    this.ipn_1 = name;
    this.kpn_1 = getStringHashCode(name) + imul(slotIndex, 37) | 0;
  }
  hashCode() {
    return this.kpn_1;
  }
  equals(object) {
    if (object == null)
      return false;
    var other = (object == null ? true : object instanceof SkinEntry) ? object : THROW_CCE();
    if (!(this.hpn_1 === ensureNotNull(other).hpn_1))
      return false;
    return !(this.w3() === other.w3()) ? false : true;
  }
  toString() {
    return '' + this.hpn_1 + ':' + this.w3();
  }
}
class Skin {
  constructor(name) {
    this.bpn_1 = name;
    this.cpn_1 = LinkedHashMap.vc();
    this.dpn_1 = FastArrayList.g14();
    this.epn_1 = FastArrayList.g14();
    this.fpn_1 = SkinEntry.npr();
  }
  hpo(slotIndex, name, attachment) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(slotIndex >= 0)) {
      // Inline function 'com.esotericsoftware.spine.Skin.setAttachment.<anonymous>' call
      var message = 'slotIndex must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    var newEntry = SkinEntry.ppr(slotIndex, name, attachment);
    var oldEntry = this.cpn_1.r3(newEntry, newEntry);
    if (!(oldEntry == null)) {
      oldEntry.jpn_1 = attachment;
    }
  }
  tp7(slotIndex, name) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(slotIndex >= 0)) {
      // Inline function 'com.esotericsoftware.spine.Skin.getAttachment.<anonymous>' call
      var message = 'slotIndex must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.fpn_1.opr(slotIndex, name);
    var entry = this.cpn_1.k3(this.fpn_1);
    return entry == null ? null : entry.jpn_1;
  }
  toString() {
    return this.bpn_1;
  }
}
class Slot {
  qpr() {
    return this.vp7_1.vp9_1;
  }
  static npn(data, bone) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_Slot($this);
    $this.up7_1 = data;
    $this.vp7_1 = bone;
    var tmp = $this;
    var tmp_0;
    var tmp_1 = data.epc_1;
    if ((tmp_1 == null ? null : new RGBAf(tmp_1)) == null) {
      tmp_0 = null;
    } else {
      tmp_0 = _RGBAf___init__impl__idjpgn();
    }
    tmp.xp7_1 = tmp_0;
    $this.cpl();
    return $this;
  }
  cp8(attachment) {
    if (this.yp7_1 === attachment)
      return Unit_instance;
    this.yp7_1 = attachment;
    this.zp7_1 = this.vp7_1.vp9_1.op7_1;
    ensureNotNull(this.ap8_1).o3();
  }
  cpl() {
    RGBAf__setTo_impl_x4m1oi(this.wp7_1, this.up7_1.dpc_1);
    var tmp72_safe_receiver = this.xp7_1;
    var tmp = tmp72_safe_receiver;
    if ((tmp == null ? null : new RGBAf(tmp)) == null)
      null;
    else {
      var tmp_0 = this.up7_1.epc_1;
      RGBAf__setTo_impl_x4m1oi(tmp72_safe_receiver, ensureNotNull(tmp_0 == null ? null : new RGBAf(tmp_0)).b5k_1);
    }
    if (this.up7_1.fpc_1 == null) {
      this.cp8(null);
    } else {
      this.yp7_1 = null;
      this.cp8(this.vp7_1.vp9_1.tp7(this.up7_1.apc_1, ensureNotNull(this.up7_1.fpc_1)));
    }
  }
  toString() {
    return this.up7_1.bpc_1;
  }
}
class SlotData {
  constructor(index, name, boneData) {
    this.apc_1 = index;
    this.bpc_1 = name;
    this.cpc_1 = boneData;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.apc_1 >= 0)) {
      // Inline function 'com.esotericsoftware.spine.SlotData.<anonymous>' call
      var message = 'index must be >= 0.';
      throw IllegalArgumentException.l(toString(message));
    }
    this.dpc_1 = _RGBAf___init__impl__idjpgn(1.0, 1.0, 1.0, 1.0);
    this.epc_1 = null;
    this.fpc_1 = null;
  }
  rpr() {
    var tmp = this.gpc_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('blendMode');
    }
  }
  spr() {
    return this.rpr();
  }
  toString() {
    return this.bpc_1;
  }
}
class SpineRegion {
  constructor(entry) {
    this.tpr_1 = entry;
    this.upr_1 = this.tpr_1.p4l_1;
    this.vpr_1 = this.upr_1.f41_1;
    this.wpr_1 = this.upr_1.x41_1;
    this.xpr_1 = this.vpr_1;
    var tmp = this;
    // Inline function 'korlibs.math.geom.slice.Companion.ORIGINAL' call
    var tmp$ret$0 = Companion_getInstance_1().v40_1;
    tmp.ypr_1 = !(this.tpr_1.m4l_1.g4m_1 === tmp$ret$0);
    this.zpr_1 = this.wpr_1.g42_1;
    this.aps_1 = this.wpr_1.k42_1;
    this.bps_1 = this.ypr_1 ? this.upr_1.d42_1 : this.wpr_1.h42_1;
    this.cps_1 = this.ypr_1 ? this.upr_1.z41_1 : this.wpr_1.l42_1;
    var tmp_0 = this;
    var tmp73_safe_receiver = this.tpr_1.m4l_1.f4m_1;
    var tmp0_elvis_lhs = tmp73_safe_receiver == null ? null : tmp73_safe_receiver.z2t_1;
    tmp_0.dps_1 = tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs;
    var tmp_1 = this;
    var tmp74_safe_receiver = this.tpr_1.m4l_1.f4m_1;
    var tmp0_elvis_lhs_0 = tmp74_safe_receiver == null ? null : tmp74_safe_receiver.a2u_1;
    tmp_1.eps_1 = tmp0_elvis_lhs_0 == null ? 0 : tmp0_elvis_lhs_0;
    var tmp_2 = this;
    var tmp75_safe_receiver = this.tpr_1.m4l_1.f4m_1;
    var tmp0_elvis_lhs_1 = tmp75_safe_receiver == null ? null : tmp75_safe_receiver.b2u_1;
    tmp_2.fps_1 = tmp0_elvis_lhs_1 == null ? this.tpr_1.m4l_1.e4m_1.b2u_1 : tmp0_elvis_lhs_1;
    var tmp_3 = this;
    var tmp76_safe_receiver = this.tpr_1.m4l_1.f4m_1;
    var tmp0_elvis_lhs_2 = tmp76_safe_receiver == null ? null : tmp76_safe_receiver.c2u_1;
    tmp_3.gps_1 = tmp0_elvis_lhs_2 == null ? this.tpr_1.m4l_1.e4m_1.c2u_1 : tmp0_elvis_lhs_2;
    this.hps_1 = this.tpr_1.m4l_1.e4m_1.b2u_1;
    this.ips_1 = this.tpr_1.m4l_1.e4m_1.c2u_1;
    this.jps_1 = this.ypr_1 ? 90 : 0;
  }
}
class TransformConstraint {
  static opn(data, skeleton) {
    var $this = createThis(this);
    init_com_esotericsoftware_spine_TransformConstraint($this);
    $this.ope_1 = data;
    $this.rpe_1 = data.cpf_1;
    $this.spe_1 = data.dpf_1;
    $this.tpe_1 = data.epf_1;
    $this.upe_1 = data.fpf_1;
    $this.ppe_1 = FastArrayList.f14(data.apf_1.j1());
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = data.apf_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'com.esotericsoftware.spine.TransformConstraint.<init>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var boneData = array[_unary__edvuaz];
      $this.ppe_1.f(ensureNotNull(skeleton.apm(boneData.wpa_1)));
    }
    $this.qpe_1 = skeleton.apm(data.bpm().wpa_1);
    return $this;
  }
  dpl() {
    if (this.ope_1.npf_1) {
      if (this.ope_1.mpf_1) {
        applyRelativeLocal(this);
      } else {
        applyAbsoluteLocal(this);
      }
    } else {
      if (this.ope_1.mpf_1) {
        applyRelativeWorld(this);
      } else {
        applyAbsoluteWorld(this);
      }
    }
  }
  toString() {
    return this.ope_1.mpl_1;
  }
}
class TransformConstraintData extends ConstraintData {
  constructor(name) {
    super(name);
    this.apf_1 = FastArrayList.g14();
    this.cpf_1 = 0;
    this.dpf_1 = 0;
    this.epf_1 = 0;
    this.fpf_1 = 0;
    this.gpf_1 = 0;
    this.hpf_1 = 0;
    this.ipf_1 = 0;
    this.jpf_1 = 0;
    this.kpf_1 = 0;
    this.lpf_1 = 0;
    this.mpf_1 = false;
    this.npf_1 = false;
  }
  bpm() {
    var tmp = this.bpf_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('target');
    }
  }
}
class AttachmentLoader {}
function newRegionAttachment(skin, name, path) {
  return null;
}
function newMeshAttachment(skin, name, path) {
  return null;
}
function newBoundingBoxAttachment(skin, name) {
  return null;
}
function newClippingAttachment(skin, name) {
  return null;
}
function newPathAttachment(skin, name) {
  return null;
}
function newPointAttachment(skin, name) {
  return null;
}
class AtlasAttachmentLoader {
  constructor(atlas) {
    this.kps_1 = atlas;
    this.lps_1 = HashMap.c9();
  }
  jpo(skin, name, path) {
    var tmp0_elvis_lhs = findRegion(this, path);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw RuntimeException.db('Region not found in atlas: ' + path + ' (region attachment: ' + name + ')');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var region = tmp;
    var attachment = new RegionAttachment(name);
    attachment.mps(region);
    return attachment;
  }
  ipp(skin, name, path) {
    var tmp0_elvis_lhs = findRegion(this, path);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw RuntimeException.db('Region not found in atlas: ' + path + ' (mesh attachment: ' + name + ')');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var region = tmp;
    var attachment = new MeshAttachment(name);
    attachment.ppp_1 = region;
    return attachment;
  }
  ypo(skin, name) {
    return new BoundingBoxAttachment(name);
  }
  jpq(skin, name) {
    return new ClippingAttachment(name);
  }
  cpq(skin, name) {
    return new PathAttachment(name);
  }
  dpq(skin, name) {
    return new PointAttachment(name);
  }
}
class Attachment {
  constructor(name) {
    this.nps_1 = name;
  }
  toString() {
    return this.nps_1;
  }
}
class Companion_18 {
  constructor() {
    Companion_instance_19 = this;
    this.ipo_1 = values_4();
  }
}
class AttachmentType extends Enum {}
class VertexAttachment extends Attachment {
  constructor(name) {
    super(name);
    this.upc_1 = (nextID_0(Companion_instance_21) & 65535) << 11;
    this.vpc_1 = null;
    this.wpc_1 = null;
    this.xpc_1 = 0;
    this.ypc_1 = this;
  }
  xpm(slot, start, count, worldVertices, offset, stride) {
    var count_0 = count;
    count_0 = offset + imul(count_0 >> 1, stride) | 0;
    var skeleton = slot.qpr();
    var deformArray = ensureNotNull(slot.ap8_1);
    var vertices = this.wpc_1;
    var bones = this.vpc_1;
    if (bones == null) {
      if (deformArray.j1() > 0)
        vertices = deformArray.r30_1;
      var bone = slot.vp7_1;
      var x = bone.ppa_1;
      var y = bone.spa_1;
      var a = bone.npa_1;
      var b = bone.opa_1;
      var c = bone.qpa_1;
      var d = bone.rpa_1;
      var v = start;
      var w = offset;
      while (w < count_0) {
        var vx = ensureNotNull(vertices)[v];
        var vy = vertices[v + 1 | 0];
        worldVertices[w] = vx * a + vy * b + x;
        worldVertices[w + 1 | 0] = vx * c + vy * d + y;
        v = v + 2 | 0;
        w = w + stride | 0;
      }
      return Unit_instance;
    }
    var v_0 = 0;
    var skip = 0;
    var i = 0;
    while (i < start) {
      var n = bones[v_0];
      v_0 = v_0 + (n + 1 | 0) | 0;
      skip = skip + n | 0;
      i = i + 2 | 0;
    }
    var skeletonBones = skeleton.fp7_1;
    if (deformArray.j1() === 0) {
      var w_0 = offset;
      var b_0 = imul(skip, 3);
      while (w_0 < count_0) {
        var wx = 0.0;
        var wy = 0.0;
        var _unary__edvuaz = v_0;
        v_0 = _unary__edvuaz + 1 | 0;
        var n_0 = bones[_unary__edvuaz];
        n_0 = n_0 + v_0 | 0;
        while (v_0 < n_0) {
          var tmp = skeletonBones.i1(bones[v_0]);
          var bone_0 = tmp instanceof Bone ? tmp : THROW_CCE();
          var vx_0 = ensureNotNull(vertices)[b_0];
          var vy_0 = vertices[b_0 + 1 | 0];
          var weight = vertices[b_0 + 2 | 0];
          wx = wx + (vx_0 * bone_0.npa_1 + vy_0 * bone_0.opa_1 + bone_0.ppa_1) * weight;
          wy = wy + (vx_0 * bone_0.qpa_1 + vy_0 * bone_0.rpa_1 + bone_0.spa_1) * weight;
          v_0 = v_0 + 1 | 0;
          b_0 = b_0 + 3 | 0;
        }
        worldVertices[w_0] = wx;
        worldVertices[w_0 + 1 | 0] = wy;
        w_0 = w_0 + stride | 0;
      }
    } else {
      var deform = deformArray.r30_1;
      var w_1 = offset;
      var b_1 = imul(skip, 3);
      var f = skip << 1;
      while (w_1 < count_0) {
        var wx_0 = 0.0;
        var wy_0 = 0.0;
        var _unary__edvuaz_0 = v_0;
        v_0 = _unary__edvuaz_0 + 1 | 0;
        var n_1 = bones[_unary__edvuaz_0];
        n_1 = n_1 + v_0 | 0;
        while (v_0 < n_1) {
          var tmp_0 = skeletonBones.i1(bones[v_0]);
          var bone_1 = tmp_0 instanceof Bone ? tmp_0 : THROW_CCE();
          var vx_1 = ensureNotNull(vertices)[b_1] + deform[f];
          var vy_1 = vertices[b_1 + 1 | 0] + deform[f + 1 | 0];
          var weight_0 = vertices[b_1 + 2 | 0];
          wx_0 = wx_0 + (vx_1 * bone_1.npa_1 + vy_1 * bone_1.opa_1 + bone_1.ppa_1) * weight_0;
          wy_0 = wy_0 + (vx_1 * bone_1.qpa_1 + vy_1 * bone_1.rpa_1 + bone_1.spa_1) * weight_0;
          v_0 = v_0 + 1 | 0;
          b_1 = b_1 + 3 | 0;
          f = f + 2 | 0;
        }
        worldVertices[w_1] = wx_0;
        worldVertices[w_1 + 1 | 0] = wy_0;
        w_1 = w_1 + stride | 0;
      }
    }
  }
}
class BoundingBoxAttachment extends VertexAttachment {
  constructor(name) {
    super(name);
    this.hpp_1 = _RGBAf___init__impl__idjpgn(0.38, 0.94, 0.0, 1.0);
  }
}
class ClippingAttachment extends VertexAttachment {
  constructor(name) {
    super(name);
    this.rpq_1 = _RGBAf___init__impl__idjpgn(0.2275, 0.2275, 0.8078, 1.0);
  }
  ops() {
    var tmp = this.qpq_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('endSlot');
    }
  }
}
class MeshAttachment extends VertexAttachment {
  constructor(name) {
    super(name);
    this.ppp_1 = null;
    this.qpp_1 = null;
    this.spp_1 = null;
    this.upp_1 = _RGBAf___init__impl__idjpgn(1.0, 1.0, 1.0, 1.0);
    this.vpp_1 = 0;
    this.wpp_1 = null;
    this.xpp_1 = null;
    this.ypp_1 = 0;
    this.zpp_1 = 0;
  }
  pps() {
    var tmp = this.rpp_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('regionUVs');
    }
  }
  qps() {
    var tmp = this.tpp_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('triangles');
    }
  }
  mpr(parentMesh) {
    this.wpp_1 = parentMesh;
    if (!(parentMesh == null)) {
      this.vpc_1 = parentMesh.vpc_1;
      this.wpc_1 = parentMesh.wpc_1;
      this.rpp_1 = parentMesh.pps();
      this.tpp_1 = parentMesh.qps();
      this.vpp_1 = parentMesh.vpp_1;
      this.xpc_1 = parentMesh.xpc_1;
      this.xpp_1 = parentMesh.xpp_1;
      this.ypp_1 = parentMesh.ypp_1;
      this.zpp_1 = parentMesh.zpp_1;
    }
  }
  apq() {
    var regionUVs = this.pps();
    if (this.spp_1 == null || !(ensureNotNull(this.spp_1).length === ensureNotNull(regionUVs).length))
      this.spp_1 = new Float32Array(ensureNotNull(regionUVs).length);
    var uvs = this.spp_1;
    var n = ensureNotNull(uvs).length;
    var u;
    var v;
    var width;
    var height;
    var tmp = this.ppp_1;
    if (tmp instanceof SpineRegion) {
      u = ensureNotNull(this.ppp_1).zpr_1;
      v = ensureNotNull(this.ppp_1).bps_1;
      var tmp_0 = this.ppp_1;
      var region = (tmp_0 == null ? true : tmp_0 instanceof SpineRegion) ? tmp_0 : THROW_CCE();
      var textureWidth = ensureNotNull(region).xpr_1.j2y();
      var textureHeight = region.xpr_1.k2y();
      switch (region.jps_1) {
        case 90:
          u = u - (region.gps_1 - region.eps_1 - region.hps_1) / textureWidth;
          v = v - (region.fps_1 - region.dps_1 - region.ips_1) / textureHeight;
          width = region.gps_1 / textureWidth;
          height = region.fps_1 / textureHeight;
          // Inline function 'kotlin.run' call

          // Inline function 'kotlin.contracts.contract' call

          var i = 0;
          while (i < n) {
            uvs[i] = u + regionUVs[i + 1 | 0] * width;
            uvs[i + 1 | 0] = v + (1 - regionUVs[i]) * height;
            i = i + 2 | 0;
          }

          return Unit_instance;
        case 180:
          u = u - (region.fps_1 - region.dps_1 - region.hps_1) / textureWidth;
          v = v - region.eps_1 / textureHeight;
          width = region.fps_1 / textureWidth;
          height = region.gps_1 / textureHeight;
          // Inline function 'kotlin.run' call

          // Inline function 'kotlin.contracts.contract' call

          var i_0 = 0;
          while (i_0 < n) {
            uvs[i_0] = u + (1 - regionUVs[i_0]) * width;
            uvs[i_0 + 1 | 0] = v + (1 - regionUVs[i_0 + 1 | 0]) * height;
            i_0 = i_0 + 2 | 0;
          }

          return Unit_instance;
        case 270:
          u = u - region.eps_1 / textureWidth;
          v = v - region.dps_1 / textureHeight;
          width = region.gps_1 / textureWidth;
          height = region.fps_1 / textureHeight;
          var i_1 = 0;
          while (i_1 < n) {
            uvs[i_1] = u + (1 - regionUVs[i_1 + 1 | 0]) * width;
            uvs[i_1 + 1 | 0] = v + regionUVs[i_1] * height;
            i_1 = i_1 + 2 | 0;
          }

          return Unit_instance;
      }
      u = u - region.dps_1 / textureWidth;
      v = v - (region.gps_1 - region.eps_1 - region.ips_1) / textureHeight;
      width = region.fps_1 / textureWidth;
      height = region.gps_1 / textureHeight;
    } else {
      if (this.ppp_1 == null) {
        v = 0.0;
        u = v;
        height = 1.0;
        width = height;
      } else {
        u = ensureNotNull(this.ppp_1).zpr_1;
        v = ensureNotNull(this.ppp_1).bps_1;
        width = ensureNotNull(this.ppp_1).aps_1 - u;
        height = ensureNotNull(this.ppp_1).cps_1 - v;
      }
    }
    var i_2 = 0;
    while (i_2 < n) {
      uvs[i_2] = u + regionUVs[i_2] * width;
      uvs[i_2 + 1 | 0] = v + regionUVs[i_2 + 1 | 0] * height;
      i_2 = i_2 + 2 | 0;
    }
  }
}
class PathAttachment extends VertexAttachment {
  constructor(name) {
    super(name);
    this.tpm_1 = false;
    this.upm_1 = false;
    this.vpm_1 = _RGBAf___init__impl__idjpgn(1.0, 0.5, 0.0, 1.0);
  }
  wpm() {
    var tmp = this.spm_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('lengths');
    }
  }
}
class PointAttachment extends Attachment {
  constructor(name) {
    super(name);
    this.fpq_1 = 0;
    this.gpq_1 = 0;
    this.hpq_1 = 0;
    this.ipq_1 = _RGBAf___init__impl__idjpgn(0.9451, 0.9451, 0.0, 1.0);
  }
}
class Companion_19 {
  constructor() {
    this.rps_1 = 0;
    this.sps_1 = 1;
    this.tps_1 = 2;
    this.ups_1 = 3;
    this.vps_1 = 4;
    this.wps_1 = 5;
    this.xps_1 = 6;
    this.yps_1 = 7;
  }
}
class RegionAttachment extends Attachment {
  constructor(name) {
    super(name);
    this.lpo_1 = null;
    this.mpo_1 = null;
    this.npo_1 = 0;
    this.opo_1 = 0;
    this.ppo_1 = 1.0;
    this.qpo_1 = 1.0;
    this.rpo_1 = 0;
    this.spo_1 = 0;
    this.tpo_1 = 0;
    this.upo_1 = new Float32Array(8);
    this.vpo_1 = new Float32Array(8);
    this.wpo_1 = _RGBAf___init__impl__idjpgn(1.0, 1.0, 1.0, 1.0);
  }
  mps(region) {
    this.lpo_1 = region;
    var uvs = this.upo_1;
    if (region.ypr_1) {
      uvs[Companion_instance_20.vps_1] = region.zpr_1;
      uvs[Companion_instance_20.wps_1] = region.cps_1;
      uvs[Companion_instance_20.xps_1] = region.zpr_1;
      uvs[Companion_instance_20.yps_1] = region.bps_1;
      uvs[Companion_instance_20.rps_1] = region.aps_1;
      uvs[Companion_instance_20.sps_1] = region.bps_1;
      uvs[Companion_instance_20.tps_1] = region.aps_1;
      uvs[Companion_instance_20.ups_1] = region.cps_1;
    } else {
      uvs[Companion_instance_20.tps_1] = region.zpr_1;
      uvs[Companion_instance_20.ups_1] = region.cps_1;
      uvs[Companion_instance_20.vps_1] = region.zpr_1;
      uvs[Companion_instance_20.wps_1] = region.bps_1;
      uvs[Companion_instance_20.xps_1] = region.aps_1;
      uvs[Companion_instance_20.yps_1] = region.bps_1;
      uvs[Companion_instance_20.rps_1] = region.aps_1;
      uvs[Companion_instance_20.sps_1] = region.cps_1;
    }
  }
  zps() {
    var tmp0_elvis_lhs = this.lpo_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var message = 'Region was not set before';
      throw IllegalStateException.m5(toString(message));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  xpo() {
    var width = this.spo_1;
    var height = this.tpo_1;
    var localX2 = width / 2;
    var localY2 = height / 2;
    var localX = -localX2;
    var localY = -localY2;
    var tmp = this.zps();
    if (tmp instanceof SpineRegion) {
      var tmp_0 = this.zps();
      var region = tmp_0 instanceof SpineRegion ? tmp_0 : THROW_CCE();
      localX = localX + ensureNotNull(region).dps_1 / region.fps_1 * width;
      localY = localY + region.eps_1 / region.gps_1 * height;
      if (region.ypr_1) {
        localX2 = localX2 - (region.fps_1 - region.dps_1 - region.ips_1) / region.fps_1 * width;
        localY2 = localY2 - (region.gps_1 - region.eps_1 - region.hps_1) / region.gps_1 * height;
      } else {
        localX2 = localX2 - (region.fps_1 - region.dps_1 - region.hps_1) / region.fps_1 * width;
        localY2 = localY2 - (region.gps_1 - region.eps_1 - region.ips_1) / region.gps_1 * height;
      }
    }
    var scaleX = this.ppo_1;
    var scaleY = this.qpo_1;
    localX = localX * scaleX;
    localY = localY * scaleY;
    localX2 = localX2 * scaleX;
    localY2 = localY2 * scaleY;
    var rotation = this.rpo_1;
    // Inline function 'kotlin.math.cos' call
    var x = 0.017453292 * rotation;
    var cos = Math.cos(x);
    // Inline function 'kotlin.math.sin' call
    var x_0 = 0.017453292 * rotation;
    var sin = Math.sin(x_0);
    var x_1 = this.npo_1;
    var y = this.opo_1;
    var localXCos = localX * cos + x_1;
    var localXSin = localX * sin;
    var localYCos = localY * cos + y;
    var localYSin = localY * sin;
    var localX2Cos = localX2 * cos + x_1;
    var localX2Sin = localX2 * sin;
    var localY2Cos = localY2 * cos + y;
    var localY2Sin = localY2 * sin;
    var offset = this.vpo_1;
    offset[Companion_instance_20.rps_1] = localXCos - localYSin;
    offset[Companion_instance_20.sps_1] = localYCos + localXSin;
    offset[Companion_instance_20.tps_1] = localXCos - localY2Sin;
    offset[Companion_instance_20.ups_1] = localY2Cos + localXSin;
    offset[Companion_instance_20.vps_1] = localX2Cos - localY2Sin;
    offset[Companion_instance_20.wps_1] = localY2Cos + localX2Sin;
    offset[Companion_instance_20.xps_1] = localX2Cos - localYSin;
    offset[Companion_instance_20.yps_1] = localYCos + localX2Sin;
  }
  apt(bone, worldVertices, offset, stride) {
    var offset_0 = offset;
    var vertexOffset = this.vpo_1;
    var x = bone.ppa_1;
    var y = bone.spa_1;
    var a = bone.npa_1;
    var b = bone.opa_1;
    var c = bone.qpa_1;
    var d = bone.rpa_1;
    var offsetX;
    var offsetY;
    offsetX = vertexOffset[Companion_instance_20.xps_1];
    offsetY = vertexOffset[Companion_instance_20.yps_1];
    worldVertices[offset_0] = offsetX * a + offsetY * b + x;
    worldVertices[offset_0 + 1 | 0] = offsetX * c + offsetY * d + y;
    offset_0 = offset_0 + stride | 0;
    offsetX = vertexOffset[Companion_instance_20.rps_1];
    offsetY = vertexOffset[Companion_instance_20.sps_1];
    worldVertices[offset_0] = offsetX * a + offsetY * b + x;
    worldVertices[offset_0 + 1 | 0] = offsetX * c + offsetY * d + y;
    offset_0 = offset_0 + stride | 0;
    offsetX = vertexOffset[Companion_instance_20.tps_1];
    offsetY = vertexOffset[Companion_instance_20.ups_1];
    worldVertices[offset_0] = offsetX * a + offsetY * b + x;
    worldVertices[offset_0 + 1 | 0] = offsetX * c + offsetY * d + y;
    offset_0 = offset_0 + stride | 0;
    offsetX = vertexOffset[Companion_instance_20.vps_1];
    offsetY = vertexOffset[Companion_instance_20.wps_1];
    worldVertices[offset_0] = offsetX * a + offsetY * b + x;
    worldVertices[offset_0 + 1 | 0] = offsetX * c + offsetY * d + y;
  }
}
class SkeletonAttachment extends Attachment {}
class Companion_20 {}
class Companion_21 {
  constructor() {
    Companion_instance_22 = this;
    var tmp = this;
    // Inline function 'kotlin.shortArrayOf' call
    tmp.fpw_1 = new Int16Array([0, 1, 2, 2, 3, 0]);
  }
}
class SkeletonView extends View {
  static jpw(skeleton, animationState) {
    Companion_getInstance_26();
    var $this = this.hgn();
    $this.bpv_1 = skeleton;
    $this.cpv_1 = animationState;
    if (!($this.cpv_1 == null)) {
      addUpdater($this, SkeletonView$lambda);
    }
    $this.dpv_1 = Companion_getInstance_3().f10_1;
    $this.epv_1 = true;
    $this.fpv_1 = false;
    $this.gpv_1 = FloatArrayList.t30(32);
    $this.hpv_1 = new SkeletonClipping();
    $this.ipv_1 = null;
    $this.jpv_1 = new SpineVector2();
    $this.kpv_1 = new SpineVector2();
    $this.lpv_1 = _RGBAf___init__impl__idjpgn();
    $this.mpv_1 = _RGBAf___init__impl__idjpgn();
    $this.npv_1 = _RGBAf___init__impl__idjpgn();
    $this.opv_1 = _RGBAf___init__impl__idjpgn();
    $this.ppv_1 = null;
    $this.qpv_1 = new MBoundsBuilder();
    return $this;
  }
  lh9(delta) {
    if (this.epv_1) {
      var tmp81_safe_receiver = this.cpv_1;
      if (tmp81_safe_receiver == null)
        null;
      else {
        tmp81_safe_receiver.upj(get_seconds(delta));
      }
      // Inline function 'korlibs.time.milliseconds' call
      // Inline function 'kotlin.time.Companion.milliseconds' call
      Companion_getInstance();
      var tmp$ret$1 = toDuration(0, DurationUnit_MILLISECONDS_getInstance());
      if (!equals(delta, tmp$ret$1)) {
        this.cca();
      }
    }
    var tmp82_safe_receiver = this.cpv_1;
    if (tmp82_safe_receiver == null)
      null;
    else
      tmp82_safe_receiver.vpj(this.bpv_1);
  }
  kpw() {
    this.bpv_1.rpn(0.0, 0.0);
    this.bpv_1.qpn();
  }
  jc7(ctx) {
    this.kpw();
    renderSkeleton(this, ctx, this.bpv_1, null);
  }
  gc7() {
    this.qpv_1.z33();
    this.kpw();
    renderSkeleton(this, null, this.bpv_1, this.qpv_1);
    return this.qpv_1.o4i().j2t();
  }
}
class Companion_22 {
  lpw(polygon) {
    var vertices = polygon.r30_1;
    var verticeslength = polygon.j1();
    var area = vertices[verticeslength - 2 | 0] * vertices[1] - vertices[0] * vertices[verticeslength - 1 | 0];
    var p1x;
    var p1y;
    var p2x;
    var p2y;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i = 0;
    var n = verticeslength - 3 | 0;
    while (i < n) {
      p1x = vertices[i];
      p1y = vertices[i + 1 | 0];
      p2x = vertices[i + 2 | 0];
      p2y = vertices[i + 3 | 0];
      area = area + (p1x * p2y - p2x * p1y);
      i = i + 2 | 0;
    }
    if (area < 0)
      return Unit_instance;
    var i_0 = 0;
    var lastX = verticeslength - 2 | 0;
    var n_0 = verticeslength >> 1;
    while (i_0 < n_0) {
      var x = vertices[i_0];
      var y = vertices[i_0 + 1 | 0];
      var other = lastX - i_0 | 0;
      vertices[i_0] = vertices[other];
      vertices[i_0 + 1 | 0] = vertices[other + 1 | 0];
      vertices[other] = x;
      vertices[other + 1 | 0] = y;
      i_0 = i_0 + 2 | 0;
    }
  }
}
class SkeletonClipping {
  constructor() {
    this.spv_1 = new SpineTriangulator();
    this.tpv_1 = FloatArrayList.t30();
    this.upv_1 = FloatArrayList.t30(128);
    this.vpv_1 = FloatArrayList.t30(128);
    this.wpv_1 = new ShortArrayList(128);
    this.xpv_1 = FloatArrayList.t30();
    this.ypv_1 = null;
    this.zpv_1 = null;
  }
  bpw() {
    return !(this.ypv_1 == null);
  }
  epw(slot, clip) {
    if (!(this.ypv_1 == null))
      return 0;
    var n = clip.xpc_1;
    if (n < 6)
      return 0;
    this.ypv_1 = clip;
    var vertices = setSize_0(this.tpv_1, n);
    clip.xpm(slot, 0, n, vertices, 0, 2);
    Companion_instance_23.lpw(this.tpv_1);
    var triangles = this.spv_1.tpw(this.tpv_1);
    this.zpv_1 = this.spv_1.upw(this.tpv_1, triangles);
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = ensureNotNull(this.zpv_1).e14_1;
    var n_0 = 0;
    while (n_0 < array.length) {
      // Inline function 'com.esotericsoftware.spine.utils.SkeletonClipping.clipStart.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n_0;
      n_0 = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var polygon = array[_unary__edvuaz];
      Companion_instance_23.lpw(polygon);
      polygon.w30(polygon.r30_1[0]);
      polygon.w30(polygon.r30_1[1]);
    }
    return ensureNotNull(this.zpv_1).j1();
  }
  apw(slot) {
    if (!(this.ypv_1 == null) && ensureNotNull(this.ypv_1).ops() === slot.up7_1) {
      this.ipw();
    }
  }
  ipw() {
    if (this.ypv_1 == null)
      return Unit_instance;
    this.ypv_1 = null;
    this.zpv_1 = null;
    this.vpv_1.o3();
    this.wpv_1.o3();
    this.tpv_1.o3();
  }
  hpw(vertices, verticesLength, triangles, trianglesLength, uvs, light, dark, twoColor) {
    // Inline function 'kotlin.fromBits' call
    var bits = _RGBA___get_value__impl__hhco8v(light);
    var light_0 = floatFromBits(bits);
    // Inline function 'kotlin.fromBits' call
    var bits_0 = _RGBA___get_value__impl__hhco8v(dark);
    var dark_0 = floatFromBits(bits_0);
    var clipOutput = this.upv_1;
    var clippedVertices = this.vpv_1;
    var clippedTriangles = this.wpv_1;
    var polygons = ensureNotNull(this.zpv_1);
    var polygonsCount = ensureNotNull(this.zpv_1).j1();
    var vertexSize = twoColor ? 6 : 5;
    var index = 0;
    clippedVertices.o3();
    clippedTriangles.o3();
    var i = 0;
    outer: while (i < trianglesLength) {
      var vertexOffset = triangles[i] << 1;
      var x1 = vertices[vertexOffset];
      var y1 = vertices[vertexOffset + 1 | 0];
      var u1 = uvs[vertexOffset];
      var v1 = uvs[vertexOffset + 1 | 0];
      vertexOffset = triangles[i + 1 | 0] << 1;
      var x2 = vertices[vertexOffset];
      var y2 = vertices[vertexOffset + 1 | 0];
      var u2 = uvs[vertexOffset];
      var v2 = uvs[vertexOffset + 1 | 0];
      vertexOffset = triangles[i + 2 | 0] << 1;
      var x3 = vertices[vertexOffset];
      var y3 = vertices[vertexOffset + 1 | 0];
      var u3 = uvs[vertexOffset];
      var v3 = uvs[vertexOffset + 1 | 0];
      var inductionVariable = 0;
      if (inductionVariable < polygonsCount)
        $l$loop: do {
          var p = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var s = clippedVertices.j1();
          if (this.vpw(x1, y1, x2, y2, x3, y3, polygons.i1(p), clipOutput)) {
            var clipOutputLength = clipOutput.j1();
            if (clipOutputLength === 0)
              continue $l$loop;
            var d0 = y2 - y3;
            var d1 = x3 - x2;
            var d2 = x1 - x3;
            var d4 = y3 - y1;
            var d = 1 / (d0 * d2 + d1 * (y1 - y3));
            var clipOutputCount = clipOutputLength >> 1;
            var clipOutputItems = clipOutput.r30_1;
            var clippedVerticesItems = setSize_0(clippedVertices, s + imul(clipOutputCount, vertexSize) | 0);
            // Inline function 'kotlin.run' call
            // Inline function 'kotlin.contracts.contract' call
            var ii = 0;
            while (ii < clipOutputLength) {
              var x = clipOutputItems[ii];
              var y = clipOutputItems[ii + 1 | 0];
              clippedVerticesItems[s] = x;
              clippedVerticesItems[s + 1 | 0] = y;
              clippedVerticesItems[s + 2 | 0] = light_0;
              if (twoColor) {
                clippedVerticesItems[s + 3 | 0] = dark_0;
                s = s + 4 | 0;
              } else
                s = s + 3 | 0;
              var c0 = x - x3;
              var c1 = y - y3;
              var a = (d0 * c0 + d1 * c1) * d;
              var b = (d4 * c0 + d2 * c1) * d;
              var c = 1.0 - a - b;
              clippedVerticesItems[s] = u1 * a + u2 * b + u3 * c;
              clippedVerticesItems[s + 1 | 0] = v1 * a + v2 * b + v3 * c;
              s = s + 2 | 0;
              ii = ii + 2 | 0;
            }
            s = clippedTriangles.j1();
            setSize_2(clippedTriangles, s + imul(3, clipOutputCount - 2 | 0) | 0);
            var clippedTrianglesItems = clippedTriangles;
            clipOutputCount = clipOutputCount - 1 | 0;
            var inductionVariable_0 = 1;
            var last = clipOutputCount;
            if (inductionVariable_0 < last)
              do {
                var ii_0 = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                clippedTrianglesItems.r32(s, index);
                clippedTrianglesItems.r32(s + 1 | 0, toShort(index + ii_0 | 0));
                clippedTrianglesItems.r32(s + 2 | 0, toShort((index + ii_0 | 0) + 1 | 0));
                s = s + 3 | 0;
              }
               while (inductionVariable_0 < last);
            index = toShort(index + toShort(clipOutputCount + 1 | 0));
          } else {
            var clippedVerticesItems_0 = setSize_0(clippedVertices, s + imul(3, vertexSize) | 0);
            clippedVerticesItems_0[s] = x1;
            clippedVerticesItems_0[s + 1 | 0] = y1;
            clippedVerticesItems_0[s + 2 | 0] = light_0;
            if (!twoColor) {
              clippedVerticesItems_0[s + 3 | 0] = u1;
              clippedVerticesItems_0[s + 4 | 0] = v1;
              clippedVerticesItems_0[s + 5 | 0] = x2;
              clippedVerticesItems_0[s + 6 | 0] = y2;
              clippedVerticesItems_0[s + 7 | 0] = light_0;
              clippedVerticesItems_0[s + 8 | 0] = u2;
              clippedVerticesItems_0[s + 9 | 0] = v2;
              clippedVerticesItems_0[s + 10 | 0] = x3;
              clippedVerticesItems_0[s + 11 | 0] = y3;
              clippedVerticesItems_0[s + 12 | 0] = light_0;
              clippedVerticesItems_0[s + 13 | 0] = u3;
              clippedVerticesItems_0[s + 14 | 0] = v3;
            } else {
              clippedVerticesItems_0[s + 3 | 0] = dark_0;
              clippedVerticesItems_0[s + 4 | 0] = u1;
              clippedVerticesItems_0[s + 5 | 0] = v1;
              clippedVerticesItems_0[s + 6 | 0] = x2;
              clippedVerticesItems_0[s + 7 | 0] = y2;
              clippedVerticesItems_0[s + 8 | 0] = light_0;
              clippedVerticesItems_0[s + 9 | 0] = dark_0;
              clippedVerticesItems_0[s + 10 | 0] = u2;
              clippedVerticesItems_0[s + 11 | 0] = v2;
              clippedVerticesItems_0[s + 12 | 0] = x3;
              clippedVerticesItems_0[s + 13 | 0] = y3;
              clippedVerticesItems_0[s + 14 | 0] = light_0;
              clippedVerticesItems_0[s + 15 | 0] = dark_0;
              clippedVerticesItems_0[s + 16 | 0] = u3;
              clippedVerticesItems_0[s + 17 | 0] = v3;
            }
            s = clippedTriangles.j1();
            var clippedTrianglesItems_0 = setSize_2(clippedTriangles, s + 3 | 0);
            clippedTrianglesItems_0.r32(s, index);
            clippedTrianglesItems_0.r32(s + 1 | 0, toShort(index + 1 | 0));
            clippedTrianglesItems_0.r32(s + 2 | 0, toShort(index + 2 | 0));
            index = toShort(index + 3 | 0);
            i = i + 3 | 0;
            continue outer;
          }
        }
         while (inductionVariable < polygonsCount);
      i = i + 3 | 0;
    }
  }
  vpw(x1, y1, x2, y2, x3, y3, clippingArea, output) {
    var output_0 = output;
    var originalOutput = output_0;
    var clipped = false;
    var input = null;
    if ((clippingArea.j1() % 4 | 0) >= 2) {
      input = output_0;
      output_0 = this.xpv_1;
    } else
      input = this.xpv_1;
    input.o3();
    input.w30(x1);
    input.w30(y1);
    input.w30(x2);
    input.w30(y2);
    input.w30(x3);
    input.w30(y3);
    input.w30(x1);
    input.w30(y1);
    output_0.o3();
    var clippingVertices = clippingArea.r30_1;
    var clippingVerticesLast = clippingArea.j1() - 4 | 0;
    var i = 0;
    $l$loop_0: while (true) {
      var edgeX = clippingVertices[i];
      var edgeY = clippingVertices[i + 1 | 0];
      var edgeX2 = clippingVertices[i + 2 | 0];
      var edgeY2 = clippingVertices[i + 3 | 0];
      var deltaX = edgeX - edgeX2;
      var deltaY = edgeY - edgeY2;
      var inputVertices = ensureNotNull(input).r30_1;
      var inputVerticesLength = input.j1() - 2 | 0;
      var outputStart = output_0.j1();
      var ii = 0;
      $l$loop: while (ii < inputVerticesLength) {
        var inputX = inputVertices[ii];
        var inputY = inputVertices[ii + 1 | 0];
        var inputX2 = inputVertices[ii + 2 | 0];
        var inputY2 = inputVertices[ii + 3 | 0];
        var side2 = deltaX * (inputY2 - edgeY2) - deltaY * (inputX2 - edgeX2) > 0;
        if (deltaX * (inputY - edgeY2) - deltaY * (inputX - edgeX2) > 0) {
          if (side2) {
            output_0.w30(inputX2);
            output_0.w30(inputY2);
            ii = ii + 2 | 0;
            continue $l$loop;
          }
          var c0 = inputY2 - inputY;
          var c2 = inputX2 - inputX;
          var s = c0 * (edgeX2 - edgeX) - c2 * (edgeY2 - edgeY);
          // Inline function 'kotlin.math.abs' call
          if (Math.abs(s) > 1.0E-6) {
            var ua = (c2 * (edgeY - inputY) - c0 * (edgeX - inputX)) / s;
            output_0.w30(edgeX + (edgeX2 - edgeX) * ua);
            output_0.w30(edgeY + (edgeY2 - edgeY) * ua);
          } else {
            output_0.w30(edgeX);
            output_0.w30(edgeY);
          }
        } else if (side2) {
          var c0_0 = inputY2 - inputY;
          var c2_0 = inputX2 - inputX;
          var s_0 = c0_0 * (edgeX2 - edgeX) - c2_0 * (edgeY2 - edgeY);
          // Inline function 'kotlin.math.abs' call
          if (Math.abs(s_0) > 1.0E-6) {
            var ua_0 = (c2_0 * (edgeY - inputY) - c0_0 * (edgeX - inputX)) / s_0;
            output_0.w30(edgeX + (edgeX2 - edgeX) * ua_0);
            output_0.w30(edgeY + (edgeY2 - edgeY) * ua_0);
          } else {
            output_0.w30(edgeX);
            output_0.w30(edgeY);
          }
          output_0.w30(inputX2);
          output_0.w30(inputY2);
        }
        clipped = true;
        ii = ii + 2 | 0;
      }
      if (outputStart === output_0.j1()) {
        originalOutput.o3();
        return true;
      }
      output_0.w30(output_0.r30_1[0]);
      output_0.w30(output_0.r30_1[1]);
      if (i === clippingVerticesLast)
        break $l$loop_0;
      var temp = output_0;
      output_0 = input;
      output_0.o3();
      input = temp;
      i = i + 2 | 0;
    }
    if (!(originalOutput === output_0)) {
      originalOutput.o3();
      originalOutput.y30(output_0.r30_1, 0, output_0.j1() - 2 | 0);
    } else
      setSize_0(originalOutput, originalOutput.j1() - 2 | 0);
    return clipped;
  }
}
class SpineTriangulator {
  constructor() {
    this.mpw_1 = FastArrayList.g14();
    this.npw_1 = FastArrayList.g14();
    this.opw_1 = new ShortArrayList();
    this.ppw_1 = new BooleanArrayList();
    this.qpw_1 = new ShortArrayList();
    var tmp = this;
    tmp.rpw_1 = Pool.e39(VOID, SpineTriangulator$polygonPool$lambda);
    var tmp_0 = this;
    tmp_0.spw_1 = Pool.e39(VOID, SpineTriangulator$polygonIndicesPool$lambda);
  }
  tpw(verticesArray) {
    var vertices = verticesArray.r30_1;
    var vertexCount = verticesArray.j1() >> 1;
    var indicesArray = this.opw_1;
    indicesArray.o3();
    setSize_2(indicesArray, vertexCount);
    var indices = indicesArray;
    var inductionVariable = 0;
    var last = vertexCount;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        indices.r32(i, toShort(i));
      }
       while (inductionVariable < last);
    var isConcaveArray = this.ppw_1;
    setSize_3(isConcaveArray, vertexCount);
    var isConcave_0 = isConcaveArray;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_0 = 0;
    var n = vertexCount;
    while (i_0 < n) {
      isConcave_0.x32(i_0, isConcave(this, i_0, vertexCount, vertices, indices));
      i_0 = i_0 + 1 | 0;
    }
    var triangles = this.qpw_1;
    triangles.o3();
    // Inline function 'kotlin.math.max' call
    var b = vertexCount - 2 | 0;
    var tmp$ret$1 = Math.max(0, b);
    triangles.p8(tmp$ret$1 << 2);
    while (vertexCount > 3) {
      var previous = vertexCount - 1 | 0;
      var i_1 = 0;
      var next = 1;
      outer2: while (true) {
        outer: while (true) {
          if (!isConcave_0.i1(i_1)) {
            var p1 = indices.i1(previous) << 1;
            var p2 = indices.i1(i_1) << 1;
            var p3 = indices.i1(next) << 1;
            var p1x = vertices[p1];
            var p1y = vertices[p1 + 1 | 0];
            var p2x = vertices[p2];
            var p2y = vertices[p2 + 1 | 0];
            var p3x = vertices[p3];
            var p3y = vertices[p3 + 1 | 0];
            var ii = (next + 1 | 0) % vertexCount | 0;
            while (!(ii === previous)) {
              if (isConcave_0.i1(ii)) {
                var v = indices.i1(ii) << 1;
                var vx = vertices[v];
                var vy = vertices[v + 1 | 0];
                if (positiveArea(this, p3x, p3y, p1x, p1y, vx, vy)) {
                  if (positiveArea(this, p1x, p1y, p2x, p2y, vx, vy)) {
                    if (positiveArea(this, p2x, p2y, p3x, p3y, vx, vy))
                      break outer;
                  }
                }
              }
              ii = (ii + 1 | 0) % vertexCount | 0;
            }
            break outer2;
          }
          break outer;
        }
        if (next === 0) {
          $l$loop: do {
            i_1 = i_1 - 1 | 0;
          }
           while (i_1 > 0 && isConcave_0.i1(i_1));
          break outer2;
        }
        previous = i_1;
        i_1 = next;
        next = (next + 1 | 0) % vertexCount | 0;
      }
      triangles.u32(indices.i1(((vertexCount + i_1 | 0) - 1 | 0) % vertexCount | 0));
      triangles.u32(indices.i1(i_1));
      triangles.u32(indices.i1((i_1 + 1 | 0) % vertexCount | 0));
      indicesArray.t32(i_1);
      isConcaveArray.t32(i_1);
      vertexCount = vertexCount - 1 | 0;
      var previousIndex = ((vertexCount + i_1 | 0) - 1 | 0) % vertexCount | 0;
      var nextIndex = i_1 === vertexCount ? 0 : i_1;
      isConcave_0.x32(previousIndex, isConcave(this, previousIndex, vertexCount, vertices, indices));
      isConcave_0.x32(nextIndex, isConcave(this, nextIndex, vertexCount, vertices, indices));
    }
    if (vertexCount === 3) {
      triangles.u32(indices.i1(2));
      triangles.u32(indices.i1(0));
      triangles.u32(indices.i1(1));
    }
    return triangles;
  }
  upw(verticesArray, triangles) {
    var vertices = verticesArray.r30_1;
    var convexPolygons = this.mpw_1;
    this.rpw_1.k39(convexPolygons);
    convexPolygons.o3();
    var convexPolygonsIndices = this.npw_1;
    this.spw_1.k39(convexPolygonsIndices);
    convexPolygonsIndices.o3();
    var polygonIndices = this.spw_1.g39();
    polygonIndices.o3();
    var polygon = this.rpw_1.g39();
    polygon.o3();
    var fanBaseIndex = -1;
    var lastWinding = 0;
    var trianglesItems = triangles;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i = 0;
    var n = triangles.j1();
    while (i < n) {
      var t1 = trianglesItems.i1(i) << 1;
      var t2 = trianglesItems.i1(i + 1 | 0) << 1;
      var t3 = trianglesItems.i1(i + 2 | 0) << 1;
      var x1 = vertices[t1];
      var y1 = vertices[t1 + 1 | 0];
      var x2 = vertices[t2];
      var y2 = vertices[t2 + 1 | 0];
      var x3 = vertices[t3];
      var y3 = vertices[t3 + 1 | 0];
      var merged = false;
      if (fanBaseIndex === t1) {
        var o = polygon.j1() - 4 | 0;
        var p = polygon.r30_1;
        var winding1 = winding(this, p[o], p[o + 1 | 0], p[o + 2 | 0], p[o + 3 | 0], x3, y3);
        var winding2 = winding(this, x3, y3, p[0], p[1], p[2], p[3]);
        if (winding1 === lastWinding && winding2 === lastWinding) {
          polygon.w30(x3);
          polygon.w30(y3);
          polygonIndices.u32(toShort(t3));
          merged = true;
        }
      }
      if (!merged) {
        if (polygon.j1() > 0) {
          convexPolygons.f(polygon);
          convexPolygonsIndices.f(polygonIndices);
        } else {
          this.rpw_1.h39(polygon);
          this.spw_1.h39(polygonIndices);
        }
        polygon = this.rpw_1.g39();
        polygon.o3();
        polygon.w30(x1);
        polygon.w30(y1);
        polygon.w30(x2);
        polygon.w30(y2);
        polygon.w30(x3);
        polygon.w30(y3);
        polygonIndices = this.spw_1.g39();
        polygonIndices.o3();
        polygonIndices.u32(toShort(t1));
        polygonIndices.u32(toShort(t2));
        polygonIndices.u32(toShort(t3));
        lastWinding = winding(this, x1, y1, x2, y2, x3, y3);
        fanBaseIndex = t1;
      }
      i = i + 3 | 0;
    }
    if (polygon.j1() > 0) {
      convexPolygons.f(polygon);
      convexPolygonsIndices.f(polygonIndices);
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_0 = 0;
    var n_0 = convexPolygons.j1();
    $l$loop: while (i_0 < n_0) {
      polygonIndices = convexPolygonsIndices.i1(i_0);
      if (polygonIndices.j1() === 0) {
        i_0 = i_0 + 1 | 0;
        continue $l$loop;
      }
      var firstIndex = polygonIndices.i1(0);
      var lastIndex = polygonIndices.i1(polygonIndices.j1() - 1 | 0);
      polygon = convexPolygons.i1(i_0);
      var o_0 = polygon.j1() - 4 | 0;
      var p_0 = polygon.r30_1;
      var prevPrevX = p_0[o_0];
      var prevPrevY = p_0[o_0 + 1 | 0];
      var prevX = p_0[o_0 + 2 | 0];
      var prevY = p_0[o_0 + 3 | 0];
      var firstX = p_0[0];
      var firstY = p_0[1];
      var secondX = p_0[2];
      var secondY = p_0[3];
      var winding_0 = winding(this, prevPrevX, prevPrevY, prevX, prevY, firstX, firstY);
      var ii = 0;
      $l$loop_2: while (ii < n_0) {
        if (ii === i_0) {
          ii = ii + 1 | 0;
          continue $l$loop_2;
        }
        var otherIndices = convexPolygonsIndices.i1(ii);
        if (!(otherIndices.j1() === 3)) {
          ii = ii + 1 | 0;
          continue $l$loop_2;
        }
        var otherFirstIndex = otherIndices.i1(0);
        var otherSecondIndex = otherIndices.i1(1);
        var otherLastIndex = otherIndices.i1(2);
        var otherPoly = convexPolygons.i1(ii);
        var x3_0 = otherPoly.i1(otherPoly.j1() - 2 | 0);
        var y3_0 = otherPoly.i1(otherPoly.j1() - 1 | 0);
        if (!(otherFirstIndex === firstIndex) || !(otherSecondIndex === lastIndex)) {
          ii = ii + 1 | 0;
          continue $l$loop_2;
        }
        var winding1_0 = winding(this, prevPrevX, prevPrevY, prevX, prevY, x3_0, y3_0);
        var winding2_0 = winding(this, x3_0, y3_0, firstX, firstY, secondX, secondY);
        if (winding1_0 === winding_0 && winding2_0 === winding_0) {
          otherPoly.o3();
          otherIndices.o3();
          polygon.w30(x3_0);
          polygon.w30(y3_0);
          polygonIndices.u32(toShort(otherLastIndex));
          prevPrevX = prevX;
          prevPrevY = prevY;
          prevX = x3_0;
          prevY = y3_0;
          ii = 0;
        }
        ii = ii + 1 | 0;
      }
      i_0 = i_0 + 1 | 0;
    }
    var inductionVariable = convexPolygons.j1() - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var i_1 = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        polygon = convexPolygons.i1(i_1);
        if (polygon.j1() === 0) {
          convexPolygons.q3(i_1);
          this.rpw_1.h39(polygon);
          polygonIndices = convexPolygonsIndices.q3(i_1);
          this.spw_1.h39(polygonIndices);
        }
      }
       while (0 <= inductionVariable);
    return convexPolygons;
  }
}
class SpineUtils {
  constructor() {
    this.wpw_1 = 3.1415927;
    this.xpw_1 = 6.2831855;
    this.ypw_1 = 57.295776;
    this.zpw_1 = 57.295776;
    this.apx_1 = 0.017453292;
    this.bpx_1 = 0.017453292;
  }
}
class SpineVector2 {
  constructor(x, y) {
    x = x === VOID ? 0.0 : x;
    y = y === VOID ? 0.0 : y;
    this.ipl_1 = x;
    this.jpl_1 = y;
  }
  ci4(x, y) {
    this.ipl_1 = x;
    this.jpl_1 = y;
    return this;
  }
  toString() {
    return '(' + this.ipl_1 + ',' + this.jpl_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.ipl_1);
    result = imul(result, 31) + getNumberHashCode(this.jpl_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof SpineVector2))
      return false;
    var tmp0_other_with_cast = other instanceof SpineVector2 ? other : THROW_CCE();
    if (!equals(this.ipl_1, tmp0_other_with_cast.ipl_1))
      return false;
    if (!equals(this.jpl_1, tmp0_other_with_cast.jpl_1))
      return false;
    return true;
  }
}
//endregion
var MixBlend_setup_instance;
var MixBlend_first_instance;
var MixBlend_replace_instance;
var MixBlend_add_instance;
var MixBlend_entriesInitialized;
function MixBlend_initEntries() {
  if (MixBlend_entriesInitialized)
    return Unit_instance;
  MixBlend_entriesInitialized = true;
  MixBlend_setup_instance = new MixBlend('setup', 0);
  MixBlend_first_instance = new MixBlend('first', 1);
  MixBlend_replace_instance = new MixBlend('replace', 2);
  MixBlend_add_instance = new MixBlend('add', 3);
}
var MixDirection_in_instance;
var MixDirection_out_instance;
var MixDirection_entriesInitialized;
function MixDirection_initEntries() {
  if (MixDirection_entriesInitialized)
    return Unit_instance;
  MixDirection_entriesInitialized = true;
  MixDirection_in_instance = new MixDirection('in', 0);
  MixDirection_out_instance = new MixDirection('out', 1);
}
var TimelineType_rotate_instance;
var TimelineType_translate_instance;
var TimelineType_scale_instance;
var TimelineType_shear_instance;
var TimelineType_attachment_instance;
var TimelineType_color_instance;
var TimelineType_deform_instance;
var TimelineType_event_instance;
var TimelineType_drawOrder_instance;
var TimelineType_ikConstraint_instance;
var TimelineType_transformConstraint_instance;
var TimelineType_pathConstraintPosition_instance;
var TimelineType_pathConstraintSpacing_instance;
var TimelineType_pathConstraintMix_instance;
var TimelineType_twoColor_instance;
var TimelineType_entriesInitialized;
function TimelineType_initEntries() {
  if (TimelineType_entriesInitialized)
    return Unit_instance;
  TimelineType_entriesInitialized = true;
  TimelineType_rotate_instance = new TimelineType('rotate', 0);
  TimelineType_translate_instance = new TimelineType('translate', 1);
  TimelineType_scale_instance = new TimelineType('scale', 2);
  TimelineType_shear_instance = new TimelineType('shear', 3);
  TimelineType_attachment_instance = new TimelineType('attachment', 4);
  TimelineType_color_instance = new TimelineType('color', 5);
  TimelineType_deform_instance = new TimelineType('deform', 6);
  TimelineType_event_instance = new TimelineType('event', 7);
  TimelineType_drawOrder_instance = new TimelineType('drawOrder', 8);
  TimelineType_ikConstraint_instance = new TimelineType('ikConstraint', 9);
  TimelineType_transformConstraint_instance = new TimelineType('transformConstraint', 10);
  TimelineType_pathConstraintPosition_instance = new TimelineType('pathConstraintPosition', 11);
  TimelineType_pathConstraintSpacing_instance = new TimelineType('pathConstraintSpacing', 12);
  TimelineType_pathConstraintMix_instance = new TimelineType('pathConstraintMix', 13);
  TimelineType_twoColor_instance = new TimelineType('twoColor', 14);
}
var Companion_instance_0;
function Companion_getInstance_4() {
  return Companion_instance_0;
}
var Companion_instance_1;
function Companion_getInstance_5() {
  return Companion_instance_1;
}
var Companion_instance_2;
function Companion_getInstance_6() {
  return Companion_instance_2;
}
var Companion_instance_3;
function Companion_getInstance_7() {
  return Companion_instance_3;
}
var Companion_instance_4;
function Companion_getInstance_8() {
  return Companion_instance_4;
}
function setAttachment($this, skeleton, slot, attachmentName) {
  slot.cp8(attachmentName == null ? null : skeleton.tp7($this.bp7_1, attachmentName));
}
var Companion_instance_5;
function Companion_getInstance_9() {
  return Companion_instance_5;
}
var Companion_instance_6;
function Companion_getInstance_10() {
  return Companion_instance_6;
}
var Companion_instance_7;
function Companion_getInstance_11() {
  return Companion_instance_7;
}
var Companion_instance_8;
function Companion_getInstance_12() {
  return Companion_instance_8;
}
var Companion_instance_9;
function Companion_getInstance_13() {
  return Companion_instance_9;
}
function MixBlend_setup_getInstance() {
  MixBlend_initEntries();
  return MixBlend_setup_instance;
}
function MixBlend_first_getInstance() {
  MixBlend_initEntries();
  return MixBlend_first_instance;
}
function MixBlend_replace_getInstance() {
  MixBlend_initEntries();
  return MixBlend_replace_instance;
}
function MixBlend_add_getInstance() {
  MixBlend_initEntries();
  return MixBlend_add_instance;
}
function MixDirection_in_getInstance() {
  MixDirection_initEntries();
  return MixDirection_in_instance;
}
function MixDirection_out_getInstance() {
  MixDirection_initEntries();
  return MixDirection_out_instance;
}
function TimelineType_rotate_getInstance() {
  TimelineType_initEntries();
  return TimelineType_rotate_instance;
}
function TimelineType_translate_getInstance() {
  TimelineType_initEntries();
  return TimelineType_translate_instance;
}
function TimelineType_scale_getInstance() {
  TimelineType_initEntries();
  return TimelineType_scale_instance;
}
function TimelineType_shear_getInstance() {
  TimelineType_initEntries();
  return TimelineType_shear_instance;
}
function TimelineType_attachment_getInstance() {
  TimelineType_initEntries();
  return TimelineType_attachment_instance;
}
function TimelineType_color_getInstance() {
  TimelineType_initEntries();
  return TimelineType_color_instance;
}
function TimelineType_deform_getInstance() {
  TimelineType_initEntries();
  return TimelineType_deform_instance;
}
function TimelineType_event_getInstance() {
  TimelineType_initEntries();
  return TimelineType_event_instance;
}
function TimelineType_drawOrder_getInstance() {
  TimelineType_initEntries();
  return TimelineType_drawOrder_instance;
}
function TimelineType_ikConstraint_getInstance() {
  TimelineType_initEntries();
  return TimelineType_ikConstraint_instance;
}
function TimelineType_transformConstraint_getInstance() {
  TimelineType_initEntries();
  return TimelineType_transformConstraint_instance;
}
function TimelineType_pathConstraintPosition_getInstance() {
  TimelineType_initEntries();
  return TimelineType_pathConstraintPosition_instance;
}
function TimelineType_pathConstraintSpacing_getInstance() {
  TimelineType_initEntries();
  return TimelineType_pathConstraintSpacing_instance;
}
function TimelineType_pathConstraintMix_getInstance() {
  TimelineType_initEntries();
  return TimelineType_pathConstraintMix_instance;
}
function TimelineType_twoColor_getInstance() {
  TimelineType_initEntries();
  return TimelineType_twoColor_instance;
}
var EventType_start_instance;
var EventType_interrupt_instance;
var EventType_end_instance;
var EventType_dispose_instance;
var EventType_complete_instance;
var EventType_event_instance;
var EventType_entriesInitialized;
function EventType_initEntries() {
  if (EventType_entriesInitialized)
    return Unit_instance;
  EventType_entriesInitialized = true;
  EventType_start_instance = new EventType('start', 0);
  EventType_interrupt_instance = new EventType('interrupt', 1);
  EventType_end_instance = new EventType('end', 2);
  EventType_dispose_instance = new EventType('dispose', 3);
  EventType_complete_instance = new EventType('complete', 4);
  EventType_event_instance = new EventType('event', 5);
}
function updateMixingFrom($this, to, delta) {
  var tmp0_elvis_lhs = to.hph_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return true;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var from = tmp;
  var finished = updateMixingFrom($this, from, delta);
  from.sph_1 = from.tph_1;
  from.wph_1 = from.xph_1;
  if (to.bpi_1 > 0 && to.bpi_1 >= to.cpi_1) {
    if (from.epi_1 === 0.0 || to.cpi_1 === 0.0) {
      to.hph_1 = from.hph_1;
      if (!(from.hph_1 == null))
        ensureNotNull(from.hph_1).iph_1 = to;
      to.dpi_1 = from.dpi_1;
      $this.npi_1.wpi(from);
    }
    return finished;
  }
  from.vph_1 = from.vph_1 + delta * from.zph_1;
  to.bpi_1 = to.bpi_1 + delta;
  return false;
}
function applyMixingFrom($this, to, skeleton, blend) {
  var blend_0 = blend;
  var from = to.hph_1;
  if (!(ensureNotNull(from).hph_1 == null)) {
    applyMixingFrom($this, from, skeleton, blend_0);
  }
  var mix;
  if (to.cpi_1 === 0.0) {
    mix = 1.0;
    if (blend_0.equals(MixBlend_first_getInstance()))
      blend_0 = MixBlend_setup_getInstance();
  } else {
    mix = to.bpi_1 / to.cpi_1;
    if (mix > 1)
      mix = 1.0;
    if (!blend_0.equals(MixBlend_first_getInstance()))
      blend_0 = from.fpi_1;
  }
  var events = mix < from.nph_1 ? $this.lpi_1 : null;
  var attachments = mix < from.oph_1;
  var drawOrder = mix < from.pph_1;
  var animationLast = from.sph_1;
  var animationTime = from.xpi();
  var timelineCount = ensureNotNull(from.fph_1).dph().j1();
  var timelines = ensureNotNull(from.fph_1).dph();
  var alphaHold = from.api_1 * to.dpi_1;
  var alphaMix = alphaHold * (1 - mix);
  if (blend_0.equals(MixBlend_add_getInstance())) {
    var inductionVariable = 0;
    if (inductionVariable < timelineCount)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        timelines.i1(i).kp9(skeleton, animationLast, animationTime, events, alphaMix, blend_0, MixDirection_out_getInstance());
      }
       while (inductionVariable < timelineCount);
  } else {
    var timelineMode = from.gpi_1;
    var timelineHoldMix = from.hpi_1;
    var firstFrame = !(from.ipi_1.j1() === timelineCount << 1);
    if (firstFrame) {
      setSize_0(from.ipi_1, timelineCount << 1);
    }
    var timelinesRotation = from.ipi_1.r30_1;
    from.epi_1 = 0.0;
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < timelineCount)
      $l$loop: do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var tmp = timelines.i1(i_0);
        var timeline = isInterface(tmp, Timeline) ? tmp : THROW_CCE();
        var direction = MixDirection_out_getInstance();
        var timelineBlend;
        var alpha;
        var tmp20_subject = timelineMode.i1(i_0);
        if (tmp20_subject === Companion_getInstance_14().zpi_1) {
          var tmp_0;
          if (!drawOrder) {
            tmp_0 = timeline instanceof DrawOrderTimeline;
          } else {
            tmp_0 = false;
          }
          if (tmp_0)
            continue $l$loop;
          timelineBlend = blend_0;
          alpha = alphaMix;
        } else if (tmp20_subject === Companion_getInstance_14().apj_1) {
          timelineBlend = MixBlend_setup_getInstance();
          alpha = alphaMix;
        } else if (tmp20_subject === Companion_getInstance_14().bpj_1) {
          timelineBlend = MixBlend_setup_getInstance();
          alpha = alphaHold;
        } else {
          timelineBlend = MixBlend_setup_getInstance();
          var holdMix = timelineHoldMix.i1(i_0);
          // Inline function 'kotlin.math.max' call
          var b = 1 - holdMix.bpi_1 / holdMix.cpi_1;
          alpha = alphaHold * Math.max(0.0, b);
        }
        from.epi_1 = from.epi_1 + alpha;
        if (timeline instanceof RotateTimeline) {
          applyRotateTimeline($this, timeline, skeleton, animationTime, alpha, timelineBlend, timelinesRotation, i_0 << 1, firstFrame);
        } else {
          if (timeline instanceof AttachmentTimeline) {
            applyAttachmentTimeline($this, timeline, skeleton, animationTime, timelineBlend, attachments);
          } else {
            var tmp_1;
            var tmp_2;
            if (drawOrder) {
              tmp_2 = timeline instanceof DrawOrderTimeline;
            } else {
              tmp_2 = false;
            }
            if (tmp_2) {
              tmp_1 = timelineBlend.equals(MixBlend_setup_getInstance());
            } else {
              tmp_1 = false;
            }
            if (tmp_1)
              direction = MixDirection_in_getInstance();
            timeline.kp9(skeleton, animationLast, animationTime, events, alpha, timelineBlend, direction);
          }
        }
      }
       while (inductionVariable_0 < timelineCount);
  }
  if (to.cpi_1 > 0) {
    queueEvents($this, from, animationTime);
  }
  $this.lpi_1.o3();
  from.tph_1 = animationTime;
  from.xph_1 = from.vph_1;
  return mix;
}
function applyAttachmentTimeline($this, timeline, skeleton, time, blend, attachments) {
  var slot = skeleton.gp7_1.i1(timeline.bp7_1);
  if (!slot.vp7_1.upa_1)
    return Unit_instance;
  var frames = timeline.cp7_1;
  if (time < frames[0]) {
    if (blend.equals(MixBlend_setup_getInstance()) || blend.equals(MixBlend_first_getInstance())) {
      setAttachment_0($this, skeleton, slot, slot.up7_1.fpc_1, attachments);
    }
  } else {
    var frameIndex;
    if (time >= frames[frames.length - 1 | 0])
      frameIndex = frames.length - 1 | 0;
    else
      frameIndex = Companion_instance_9.mpc(frames, time) - 1 | 0;
    setAttachment_0($this, skeleton, slot, timeline.dp7_1[frameIndex], attachments);
  }
  if (slot.bp8_1 <= $this.rpi_1)
    slot.bp8_1 = $this.rpi_1 + Companion_getInstance_14().dpj_1 | 0;
}
function setAttachment_0($this, skeleton, slot, attachmentName, attachments) {
  slot.cp8(attachmentName == null ? null : skeleton.tp7(slot.up7_1.apc_1, attachmentName));
  if (attachments)
    slot.bp8_1 = $this.rpi_1 + Companion_getInstance_14().epj_1 | 0;
}
function applyRotateTimeline($this, timeline, skeleton, time, alpha, blend, timelinesRotation, i, firstFrame) {
  if (firstFrame) {
    timelinesRotation[i] = 0.0;
  }
  if (alpha === 1.0) {
    timeline.kp9(skeleton, 0.0, time, null, 1.0, blend, MixDirection_in_getInstance());
    return Unit_instance;
  }
  var bone = skeleton.fp7_1.i1(timeline.qp9_1);
  if (!bone.upa_1)
    return Unit_instance;
  var frames = timeline.rp9_1;
  var r1;
  var r2;
  if (time < frames[0]) {
    switch (blend.v3_1) {
      case 0:
        bone.apa_1 = bone.up9_1.bpb_1;
        return Unit_instance;
      case 1:
        r1 = bone.apa_1;
        r2 = bone.up9_1.bpb_1;
        break;
      default:
        return Unit_instance;
    }
  } else {
    r1 = blend.equals(MixBlend_setup_getInstance()) ? bone.up9_1.bpb_1 : bone.apa_1;
    if (time >= frames[frames.length - Companion_instance_1.rp5_1 | 0])
      r2 = bone.up9_1.bpb_1 + frames[frames.length + Companion_instance_1.tp5_1 | 0];
    else {
      var frame = Companion_instance_9.jpb(frames, time, Companion_instance_1.rp5_1);
      var prevRotation = frames[frame + Companion_instance_1.tp5_1 | 0];
      var frameTime = frames[frame];
      var percent = timeline.op9((frame >> 1) - 1 | 0, 1 - (time - frameTime) / (frames[frame + Companion_instance_1.sp5_1 | 0] - frameTime));
      r2 = frames[frame + Companion_instance_1.up5_1 | 0] - prevRotation;
      r2 = r2 - imul(16384 - numberToInt(16384.499999999996 - r2 / 360) | 0, 360);
      r2 = prevRotation + r2 * percent + bone.up9_1.bpb_1;
      r2 = r2 - imul(16384 - numberToInt(16384.499999999996 - r2 / 360) | 0, 360);
    }
  }
  var total;
  var diff = r2 - r1;
  diff = diff - imul(16384 - numberToInt(16384.499999999996 - diff / 360) | 0, 360);
  if (diff === 0.0)
    total = timelinesRotation[i];
  else {
    var lastTotal;
    var lastDiff;
    if (firstFrame) {
      lastTotal = 0.0;
      lastDiff = diff;
    } else {
      lastTotal = timelinesRotation[i];
      lastDiff = timelinesRotation[i + 1 | 0];
    }
    var current = diff > 0;
    var dir = lastTotal >= 0;
    var tmp;
    // Inline function 'kotlin.math.sign' call
    var this_0 = lastDiff;
    var tmp_0 = sign(this_0);
    // Inline function 'kotlin.math.sign' call
    var this_1 = diff;
    if (!(tmp_0 === sign(this_1))) {
      // Inline function 'kotlin.math.abs' call
      var x = lastDiff;
      tmp = Math.abs(x) <= 90;
    } else {
      tmp = false;
    }
    if (tmp) {
      // Inline function 'kotlin.math.abs' call
      var x_0 = lastTotal;
      if (Math.abs(x_0) > 180) {
        var tmp_1 = lastTotal;
        // Inline function 'kotlin.math.sign' call
        var this_2 = lastTotal;
        lastTotal = tmp_1 + 360 * sign(this_2);
      }
      dir = current;
    }
    total = diff + lastTotal - lastTotal % 360;
    if (!(dir === current)) {
      var tmp_2 = total;
      // Inline function 'kotlin.math.sign' call
      var this_3 = lastTotal;
      total = tmp_2 + 360 * sign(this_3);
    }
    timelinesRotation[i] = total;
  }
  timelinesRotation[i + 1 | 0] = diff;
  r1 = r1 + total * alpha;
  bone.apa_1 = r1 - imul(16384 - numberToInt(16384.499999999996 - r1 / 360) | 0, 360);
}
function queueEvents($this, entry, animationTime) {
  var animationStart = entry.qph_1;
  var animationEnd = entry.rph_1;
  var duration = animationEnd - animationStart;
  var trackLastWrapped = entry.wph_1 % duration;
  var events = $this.lpi_1;
  var i = 0;
  var n = events.j1();
  $l$loop_0: while (i < n) {
    var event = events.i1(i);
    if (event.dpd_1 < trackLastWrapped)
      break $l$loop_0;
    if (event.dpd_1 > animationEnd) {
      i = i + 1 | 0;
      continue $l$loop_0;
    }
    $this.npi_1.fpj(entry, event);
    i = i + 1 | 0;
  }
  var complete;
  if (entry.lph_1)
    complete = duration === 0.0 || trackLastWrapped > entry.vph_1 % duration;
  else
    complete = (animationTime >= animationEnd && entry.sph_1 < animationEnd);
  if (complete) {
    $this.npi_1.gpj(entry);
  }
  $l$loop_1: while (i < n) {
    var event_0 = events.i1(i);
    if (event_0.dpd_1 < animationStart) {
      i = i + 1 | 0;
      continue $l$loop_1;
    }
    $this.npi_1.fpj(entry, events.i1(i));
    i = i + 1 | 0;
  }
}
function setCurrent($this, index, current, interrupt) {
  var from = expandToIndex($this, index);
  setAndGrow($this.kpi_1, index, current);
  if (!(from == null)) {
    if (interrupt) {
      $this.npi_1.hpj(from);
    }
    current.hph_1 = from;
    from.iph_1 = current;
    current.bpi_1 = 0.0;
    if (!(from.hph_1 == null) && from.cpi_1 > 0) {
      var tmp = current;
      var tmp_0 = current.dpi_1;
      // Inline function 'kotlin.math.min' call
      var b = from.bpi_1 / from.cpi_1;
      tmp.dpi_1 = tmp_0 * Math.min(1.0, b);
    }
    from.ipi_1.o3();
  }
  $this.npi_1.ipj(current);
}
function expandToIndex($this, index) {
  if (index < $this.kpi_1.j1())
    return $this.kpi_1.i1(index);
  $this.kpi_1.p8((index - $this.kpi_1.j1() | 0) + 1 | 0);
  setSize($this.kpi_1, index + 1 | 0);
  return null;
}
function trackEntry($this, trackIndex, animation, loop, last) {
  var entry = $this.spi_1.g39();
  entry.kph_1 = trackIndex;
  entry.fph_1 = animation;
  entry.lph_1 = loop;
  entry.mph_1 = false;
  entry.nph_1 = 0.0;
  entry.oph_1 = 0.0;
  entry.pph_1 = 0.0;
  entry.qph_1 = 0.0;
  entry.rph_1 = animation.zpg_1;
  entry.sph_1 = -1.0;
  entry.tph_1 = -1.0;
  entry.uph_1 = 0.0;
  entry.vph_1 = 0.0;
  entry.wph_1 = -1.0;
  entry.xph_1 = -1.0;
  entry.yph_1 = 3.4028235E38;
  entry.zph_1 = 1.0;
  entry.api_1 = 1.0;
  entry.dpi_1 = 1.0;
  entry.bpi_1 = 0.0;
  entry.cpi_1 = last == null ? 0.0 : ensureNotNull($this.jpi_1).npj(last.fph_1, animation);
  return entry;
}
function disposeNext($this, entry) {
  var next = entry.gph_1;
  while (!(next == null)) {
    $this.npi_1.opj(next);
    next = next.gph_1;
  }
  entry.gph_1 = null;
}
function computeHold($this, entry) {
  var to = entry.iph_1;
  var timelines = ensureNotNull(entry.fph_1).dph();
  var timelinesCount = ensureNotNull(entry.fph_1).dph().j1();
  var timelineMode = setSize_1(entry.gpi_1, timelinesCount);
  entry.hpi_1.o3();
  var timelineHoldMix = setSize(entry.hpi_1, timelinesCount);
  var propertyIDs = $this.opi_1;
  if (!(to == null) && to.mph_1) {
    var inductionVariable = 0;
    if (inductionVariable < timelinesCount)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = timelines.i1(i);
        propertyIDs.q35((isInterface(tmp, Timeline) ? tmp : THROW_CCE()).jp9());
        timelineMode[i] = Companion_getInstance_14().bpj_1;
      }
       while (inductionVariable < timelinesCount);
    return Unit_instance;
  }
  var inductionVariable_0 = 0;
  if (inductionVariable_0 < timelinesCount)
    outer: do {
      var i_0 = inductionVariable_0;
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      var tmp_0 = timelines.i1(i_0);
      var timeline = isInterface(tmp_0, Timeline) ? tmp_0 : THROW_CCE();
      var id = timeline.jp9();
      if (!propertyIDs.q35(id)) {
        timelineMode[i_0] = Companion_getInstance_14().zpi_1;
      } else {
        var tmp_1;
        var tmp_2;
        var tmp_3;
        var tmp_4;
        if (to == null) {
          tmp_4 = true;
        } else {
          tmp_4 = timeline instanceof AttachmentTimeline;
        }
        if (tmp_4) {
          tmp_3 = true;
        } else {
          tmp_3 = timeline instanceof DrawOrderTimeline;
        }
        if (tmp_3) {
          tmp_2 = true;
        } else {
          tmp_2 = timeline instanceof EventTimeline;
        }
        if (tmp_2) {
          tmp_1 = true;
        } else {
          tmp_1 = !ensureNotNull(to.fph_1).eph(id);
        }
        if (tmp_1) {
          timelineMode[i_0] = Companion_getInstance_14().apj_1;
        } else {
          var next = to.iph_1;
          $l$loop: while (!(next == null)) {
            if (ensureNotNull(next.fph_1).eph(id)) {
              next = next.iph_1;
              continue $l$loop;
            }
            if (next.cpi_1 > 0) {
              timelineMode[i_0] = Companion_getInstance_14().cpj_1;
              setAndGrow(timelineHoldMix, i_0, next);
              continue outer;
            }
            break $l$loop;
          }
          timelineMode[i_0] = Companion_getInstance_14().bpj_1;
        }
      }
    }
     while (inductionVariable_0 < timelinesCount);
}
var Companion_instance_10;
function Companion_getInstance_14() {
  if (Companion_instance_10 === VOID)
    new Companion_9();
  return Companion_instance_10;
}
function AnimationState$trackEntryPool$lambda(it) {
  return new TrackEntry();
}
function init_com_esotericsoftware_spine_AnimationState(_this__u8e3s4) {
  Companion_getInstance_14();
  _this__u8e3s4.jpi_1 = null;
  _this__u8e3s4.kpi_1 = FastArrayList.g14();
  _this__u8e3s4.lpi_1 = FastArrayList.g14();
  _this__u8e3s4.mpi_1 = FastArrayList.g14();
  _this__u8e3s4.npi_1 = new EventQueue(_this__u8e3s4);
  _this__u8e3s4.opi_1 = new IntSet();
  _this__u8e3s4.ppi_1 = false;
  _this__u8e3s4.qpi_1 = 1.0;
  _this__u8e3s4.rpi_1 = 0;
  var tmp = _this__u8e3s4;
  var tmp_0 = Companion_instance;
  tmp.spi_1 = tmp_0.d39(VOID, AnimationState$trackEntryPool$lambda);
}
function EventType_start_getInstance() {
  EventType_initEntries();
  return EventType_start_instance;
}
function EventType_interrupt_getInstance() {
  EventType_initEntries();
  return EventType_interrupt_instance;
}
function EventType_end_getInstance() {
  EventType_initEntries();
  return EventType_end_instance;
}
function EventType_dispose_getInstance() {
  EventType_initEntries();
  return EventType_dispose_instance;
}
function EventType_complete_getInstance() {
  EventType_initEntries();
  return EventType_complete_instance;
}
function EventType_event_getInstance() {
  EventType_initEntries();
  return EventType_event_instance;
}
var BlendMode_normal_instance;
var BlendMode_additive_instance;
var BlendMode_multiply_instance;
var BlendMode_screen_instance;
var Companion_instance_11;
function Companion_getInstance_15() {
  BlendMode_initEntries();
  if (Companion_instance_11 === VOID)
    new Companion_10();
  return Companion_instance_11;
}
function values() {
  return [BlendMode_normal_getInstance(), BlendMode_additive_getInstance(), BlendMode_multiply_getInstance(), BlendMode_screen_getInstance()];
}
var BlendMode_entriesInitialized;
function BlendMode_initEntries() {
  if (BlendMode_entriesInitialized)
    return Unit_instance;
  BlendMode_entriesInitialized = true;
  BlendMode_normal_instance = new BlendMode('normal', 0);
  BlendMode_additive_instance = new BlendMode('additive', 1);
  BlendMode_multiply_instance = new BlendMode('multiply', 2);
  BlendMode_screen_instance = new BlendMode('screen', 3);
  Companion_getInstance_15();
}
function BlendMode_normal_getInstance() {
  BlendMode_initEntries();
  return BlendMode_normal_instance;
}
function BlendMode_additive_getInstance() {
  BlendMode_initEntries();
  return BlendMode_additive_instance;
}
function BlendMode_multiply_getInstance() {
  BlendMode_initEntries();
  return BlendMode_multiply_instance;
}
function BlendMode_screen_getInstance() {
  BlendMode_initEntries();
  return BlendMode_screen_instance;
}
function init_com_esotericsoftware_spine_Bone(_this__u8e3s4) {
  _this__u8e3s4.xp9_1 = FastArrayList.g14();
  _this__u8e3s4.yp9_1 = 0;
  _this__u8e3s4.zp9_1 = 0;
  _this__u8e3s4.apa_1 = 0;
  _this__u8e3s4.bpa_1 = 0;
  _this__u8e3s4.cpa_1 = 0;
  _this__u8e3s4.dpa_1 = 0;
  _this__u8e3s4.epa_1 = 0;
  _this__u8e3s4.fpa_1 = 0;
  _this__u8e3s4.gpa_1 = 0;
  _this__u8e3s4.hpa_1 = 0;
  _this__u8e3s4.ipa_1 = 0;
  _this__u8e3s4.jpa_1 = 0;
  _this__u8e3s4.kpa_1 = 0;
  _this__u8e3s4.lpa_1 = 0;
  _this__u8e3s4.mpa_1 = false;
  _this__u8e3s4.npa_1 = 0;
  _this__u8e3s4.opa_1 = 0;
  _this__u8e3s4.ppa_1 = 0;
  _this__u8e3s4.qpa_1 = 0;
  _this__u8e3s4.rpa_1 = 0;
  _this__u8e3s4.spa_1 = 0;
  _this__u8e3s4.tpa_1 = false;
  _this__u8e3s4.upa_1 = false;
}
var TransformMode_normal_instance;
var TransformMode_onlyTranslation_instance;
var TransformMode_noRotationOrReflection_instance;
var TransformMode_noScale_instance;
var TransformMode_noScaleOrReflection_instance;
var Companion_instance_12;
function Companion_getInstance_16() {
  TransformMode_initEntries();
  if (Companion_instance_12 === VOID)
    new Companion_11();
  return Companion_instance_12;
}
function values_0() {
  return [TransformMode_normal_getInstance(), TransformMode_onlyTranslation_getInstance(), TransformMode_noRotationOrReflection_getInstance(), TransformMode_noScale_getInstance(), TransformMode_noScaleOrReflection_getInstance()];
}
var TransformMode_entriesInitialized;
function TransformMode_initEntries() {
  if (TransformMode_entriesInitialized)
    return Unit_instance;
  TransformMode_entriesInitialized = true;
  TransformMode_normal_instance = new TransformMode('normal', 0);
  TransformMode_onlyTranslation_instance = new TransformMode('onlyTranslation', 1);
  TransformMode_noRotationOrReflection_instance = new TransformMode('noRotationOrReflection', 2);
  TransformMode_noScale_instance = new TransformMode('noScale', 3);
  TransformMode_noScaleOrReflection_instance = new TransformMode('noScaleOrReflection', 4);
  Companion_getInstance_16();
}
function init_com_esotericsoftware_spine_BoneData(_this__u8e3s4) {
  _this__u8e3s4.ypa_1 = 0;
  _this__u8e3s4.zpa_1 = 0;
  _this__u8e3s4.apb_1 = 0;
  _this__u8e3s4.bpb_1 = 0;
  _this__u8e3s4.cpb_1 = 1.0;
  _this__u8e3s4.dpb_1 = 1.0;
  _this__u8e3s4.epb_1 = 0;
  _this__u8e3s4.fpb_1 = 0;
  _this__u8e3s4.gpb_1 = TransformMode_normal_getInstance();
  _this__u8e3s4.hpb_1 = false;
  _this__u8e3s4.ipb_1 = _RGBAf___init__impl__idjpgn(0.61, 0.61, 0.61, 1.0);
}
function TransformMode_normal_getInstance() {
  TransformMode_initEntries();
  return TransformMode_normal_instance;
}
function TransformMode_onlyTranslation_getInstance() {
  TransformMode_initEntries();
  return TransformMode_onlyTranslation_instance;
}
function TransformMode_noRotationOrReflection_getInstance() {
  TransformMode_initEntries();
  return TransformMode_noRotationOrReflection_instance;
}
function TransformMode_noScale_getInstance() {
  TransformMode_initEntries();
  return TransformMode_noScale_instance;
}
function TransformMode_noScaleOrReflection_getInstance() {
  TransformMode_initEntries();
  return TransformMode_noScaleOrReflection_instance;
}
var Companion_instance_13;
function Companion_getInstance_17() {
  return Companion_instance_13;
}
function init_com_esotericsoftware_spine_IkConstraint(_this__u8e3s4) {
  _this__u8e3s4.tpd_1 = null;
  _this__u8e3s4.upd_1 = 0;
  _this__u8e3s4.vpd_1 = false;
  _this__u8e3s4.wpd_1 = false;
  _this__u8e3s4.xpd_1 = 1.0;
  _this__u8e3s4.ypd_1 = 0;
  _this__u8e3s4.zpd_1 = false;
}
function addBeforePosition($this, p, temp, i, out, o) {
  var x1 = temp[i];
  var y1 = temp[i + 1 | 0];
  var dx = temp[i + 2 | 0] - x1;
  var dy = temp[i + 3 | 0] - y1;
  // Inline function 'kotlin.math.atan2' call
  var r = Math.atan2(dy, dx);
  // Inline function 'kotlin.math.cos' call
  out[o] = x1 + p * Math.cos(r);
  var tmp = o + 1 | 0;
  // Inline function 'kotlin.math.sin' call
  out[tmp] = y1 + p * Math.sin(r);
  out[o + 2 | 0] = r;
}
function addAfterPosition($this, p, temp, i, out, o) {
  var x1 = temp[i + 2 | 0];
  var y1 = temp[i + 3 | 0];
  var dx = x1 - temp[i];
  var dy = y1 - temp[i + 1 | 0];
  // Inline function 'kotlin.math.atan2' call
  var r = Math.atan2(dy, dx);
  // Inline function 'kotlin.math.cos' call
  out[o] = x1 + p * Math.cos(r);
  var tmp = o + 1 | 0;
  // Inline function 'kotlin.math.sin' call
  out[tmp] = y1 + p * Math.sin(r);
  out[o + 2 | 0] = r;
}
function addCurvePosition($this, p, x1, y1, cx1, cy1, cx2, cy2, x2, y2, out, o, tangents) {
  if (p < Companion_instance_14.fpm_1 || isNaN_0(p)) {
    out[o] = x1;
    out[o + 1 | 0] = y1;
    var tmp = o + 2 | 0;
    // Inline function 'kotlin.math.atan2' call
    var y = cy1 - y1;
    var x = cx1 - x1;
    out[tmp] = Math.atan2(y, x);
    return Unit_instance;
  }
  var tt = p * p;
  var ttt = tt * p;
  var u = 1 - p;
  var uu = u * u;
  var uuu = uu * u;
  var ut = u * p;
  var ut3 = ut * 3;
  var uut3 = u * ut3;
  var utt3 = ut3 * p;
  var x_0 = x1 * uuu + cx1 * uut3 + cx2 * utt3 + x2 * ttt;
  var y_0 = y1 * uuu + cy1 * uut3 + cy2 * utt3 + y2 * ttt;
  out[o] = x_0;
  out[o + 1 | 0] = y_0;
  if (tangents) {
    if (p < 0.001) {
      var tmp_0 = o + 2 | 0;
      // Inline function 'kotlin.math.atan2' call
      var y_1 = cy1 - y1;
      var x_1 = cx1 - x1;
      out[tmp_0] = Math.atan2(y_1, x_1);
    } else {
      var tmp_1 = o + 2 | 0;
      // Inline function 'kotlin.math.atan2' call
      var y_2 = y_0 - (y1 * uu + cy1 * ut * 2.0 + cy2 * tt);
      var x_2 = x_0 - (x1 * uu + cx1 * ut * 2.0 + cx2 * tt);
      out[tmp_1] = Math.atan2(y_2, x_2);
    }
  }
}
var Companion_instance_14;
function Companion_getInstance_18() {
  return Companion_instance_14;
}
function init_com_esotericsoftware_spine_PathConstraint(_this__u8e3s4) {
  _this__u8e3s4.upf_1 = 0;
  _this__u8e3s4.vpf_1 = 0;
  _this__u8e3s4.wpf_1 = 0;
  _this__u8e3s4.xpf_1 = 0;
  _this__u8e3s4.ypf_1 = false;
  _this__u8e3s4.zpf_1 = FloatArrayList.t30();
  _this__u8e3s4.apg_1 = FloatArrayList.t30();
  _this__u8e3s4.bpg_1 = FloatArrayList.t30();
  _this__u8e3s4.cpg_1 = FloatArrayList.t30();
  _this__u8e3s4.dpg_1 = FloatArrayList.t30();
  _this__u8e3s4.epg_1 = new Float32Array(10);
}
var PositionMode_fixed_instance;
var PositionMode_percent_instance;
var Companion_instance_15;
function Companion_getInstance_19() {
  PositionMode_initEntries();
  if (Companion_instance_15 === VOID)
    new Companion_14();
  return Companion_instance_15;
}
function values_1() {
  return [PositionMode_fixed_getInstance(), PositionMode_percent_getInstance()];
}
var PositionMode_entriesInitialized;
function PositionMode_initEntries() {
  if (PositionMode_entriesInitialized)
    return Unit_instance;
  PositionMode_entriesInitialized = true;
  PositionMode_fixed_instance = new PositionMode('fixed', 0);
  PositionMode_percent_instance = new PositionMode('percent', 1);
  Companion_getInstance_19();
}
var SpacingMode_length_instance;
var SpacingMode_fixed_instance;
var SpacingMode_percent_instance;
var Companion_instance_16;
function Companion_getInstance_20() {
  SpacingMode_initEntries();
  if (Companion_instance_16 === VOID)
    new Companion_15();
  return Companion_instance_16;
}
function values_2() {
  return [SpacingMode_length_getInstance(), SpacingMode_fixed_getInstance(), SpacingMode_percent_getInstance()];
}
var SpacingMode_entriesInitialized;
function SpacingMode_initEntries() {
  if (SpacingMode_entriesInitialized)
    return Unit_instance;
  SpacingMode_entriesInitialized = true;
  SpacingMode_length_instance = new SpacingMode('length', 0);
  SpacingMode_fixed_instance = new SpacingMode('fixed', 1);
  SpacingMode_percent_instance = new SpacingMode('percent', 2);
  Companion_getInstance_20();
}
var RotateMode_tangent_instance;
var RotateMode_chain_instance;
var RotateMode_chainScale_instance;
var Companion_instance_17;
function Companion_getInstance_21() {
  RotateMode_initEntries();
  if (Companion_instance_17 === VOID)
    new Companion_16();
  return Companion_instance_17;
}
function values_3() {
  return [RotateMode_tangent_getInstance(), RotateMode_chain_getInstance(), RotateMode_chainScale_getInstance()];
}
var RotateMode_entriesInitialized;
function RotateMode_initEntries() {
  if (RotateMode_entriesInitialized)
    return Unit_instance;
  RotateMode_entriesInitialized = true;
  RotateMode_tangent_instance = new RotateMode('tangent', 0);
  RotateMode_chain_instance = new RotateMode('chain', 1);
  RotateMode_chainScale_instance = new RotateMode('chainScale', 2);
  Companion_getInstance_21();
}
function PositionMode_fixed_getInstance() {
  PositionMode_initEntries();
  return PositionMode_fixed_instance;
}
function PositionMode_percent_getInstance() {
  PositionMode_initEntries();
  return PositionMode_percent_instance;
}
function SpacingMode_length_getInstance() {
  SpacingMode_initEntries();
  return SpacingMode_length_instance;
}
function SpacingMode_fixed_getInstance() {
  SpacingMode_initEntries();
  return SpacingMode_fixed_instance;
}
function SpacingMode_percent_getInstance() {
  SpacingMode_initEntries();
  return SpacingMode_percent_instance;
}
function RotateMode_tangent_getInstance() {
  RotateMode_initEntries();
  return RotateMode_tangent_instance;
}
function RotateMode_chain_getInstance() {
  RotateMode_initEntries();
  return RotateMode_chain_instance;
}
function RotateMode_chainScale_getInstance() {
  RotateMode_initEntries();
  return RotateMode_chainScale_instance;
}
function sortIkConstraint($this, constraint) {
  constraint.zpd_1 = (ensureNotNull(constraint.tpd_1).upa_1 && (!constraint.rpd_1.opl_1 || (!($this.mp7_1 == null) && containsIdentity(ensureNotNull($this.mp7_1).epn_1, constraint.rpd_1))));
  if (!constraint.zpd_1)
    return Unit_instance;
  var target = constraint.tpd_1;
  sortBone($this, ensureNotNull(target));
  var constrained = constraint.spd_1;
  var parent = first(constrained);
  sortBone($this, parent);
  if (constrained.j1() > 1) {
    var child = last(constrained);
    if (!containsIdentity($this.updateCacheProp, child)) {
      $this.lp7_1.f(child);
    }
  }
  $this.updateCacheProp.f(constraint);
  sortReset($this, parent.xp9_1);
  last(constrained).tpa_1 = true;
}
function sortPathConstraint($this, constraint) {
  constraint.ypf_1 = (ensureNotNull(constraint.bpm()).vp7_1.upa_1 && (!constraint.rpf_1.opl_1 || (!($this.mp7_1 == null) && containsIdentity(ensureNotNull($this.mp7_1).epn_1, constraint.rpf_1))));
  if (!constraint.ypf_1)
    return Unit_instance;
  var slot = constraint.bpm();
  var slotIndex = ensureNotNull(slot).up7_1.apc_1;
  var slotBone = slot.vp7_1;
  if (!($this.mp7_1 == null)) {
    sortPathConstraintAttachment($this, ensureNotNull($this.mp7_1), slotIndex, slotBone);
  }
  if (!($this.ep7_1.gpn() == null) && !($this.ep7_1.gpn() === $this.mp7_1)) {
    sortPathConstraintAttachment($this, $this.ep7_1.gpn(), slotIndex, slotBone);
  }
  var attachment = slot.yp7_1;
  if (attachment instanceof PathAttachment) {
    sortPathConstraintAttachment_0($this, attachment, slotBone);
  }
  var constrained = constraint.spf_1;
  var boneCount = constrained.j1();
  var inductionVariable = 0;
  if (inductionVariable < boneCount)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      sortBone($this, constrained.i1(i));
    }
     while (inductionVariable < boneCount);
  $this.updateCacheProp.f(constraint);
  var inductionVariable_0 = 0;
  if (inductionVariable_0 < boneCount)
    do {
      var i_0 = inductionVariable_0;
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      sortReset($this, constrained.i1(i_0).xp9_1);
    }
     while (inductionVariable_0 < boneCount);
  var inductionVariable_1 = 0;
  if (inductionVariable_1 < boneCount)
    do {
      var i_1 = inductionVariable_1;
      inductionVariable_1 = inductionVariable_1 + 1 | 0;
      constrained.i1(i_1).tpa_1 = true;
    }
     while (inductionVariable_1 < boneCount);
}
function sortTransformConstraint($this, constraint) {
  constraint.vpe_1 = (ensureNotNull(constraint.qpe_1).upa_1 && (!constraint.ope_1.opl_1 || (!($this.mp7_1 == null) && containsIdentity(ensureNotNull($this.mp7_1).epn_1, constraint.ope_1))));
  if (!constraint.vpe_1)
    return Unit_instance;
  sortBone($this, ensureNotNull(constraint.qpe_1));
  var constrained = constraint.ppe_1;
  var boneCount = constrained.j1();
  if (constraint.ope_1.npf_1) {
    var inductionVariable = 0;
    if (inductionVariable < boneCount)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var child = constrained.i1(i);
        sortBone($this, ensureNotNull(child.wp9_1));
        if (!containsIdentity($this.updateCacheProp, child)) {
          $this.lp7_1.f(child);
        }
      }
       while (inductionVariable < boneCount);
  } else {
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < boneCount)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        sortBone($this, constrained.i1(i_0));
      }
       while (inductionVariable_0 < boneCount);
  }
  $this.updateCacheProp.f(constraint);
  var inductionVariable_1 = 0;
  if (inductionVariable_1 < boneCount)
    do {
      var i_1 = inductionVariable_1;
      inductionVariable_1 = inductionVariable_1 + 1 | 0;
      sortReset($this, constrained.i1(i_1).xp9_1);
    }
     while (inductionVariable_1 < boneCount);
  var inductionVariable_2 = 0;
  if (inductionVariable_2 < boneCount)
    do {
      var i_2 = inductionVariable_2;
      inductionVariable_2 = inductionVariable_2 + 1 | 0;
      constrained.i1(i_2).tpa_1 = true;
    }
     while (inductionVariable_2 < boneCount);
}
function sortPathConstraintAttachment($this, skin, slotIndex, slotBone) {
  var _iterator__ex2g4s = skin.cpn_1.l3().f1();
  while (_iterator__ex2g4s.g1()) {
    var entry = _iterator__ex2g4s.h1();
    if (entry.hpn_1 === slotIndex) {
      sortPathConstraintAttachment_0($this, entry.jpn_1, slotBone);
    }
  }
}
function sortPathConstraintAttachment_0($this, attachment, slotBone) {
  if (!(attachment instanceof PathAttachment))
    return Unit_instance;
  var pathBones = attachment.vpc_1;
  if (pathBones == null) {
    sortBone($this, slotBone);
  } else {
    var bones = $this.fp7_1;
    var i = 0;
    var n = pathBones.length;
    while (i < n) {
      var _unary__edvuaz = i;
      i = _unary__edvuaz + 1 | 0;
      var nn = pathBones[_unary__edvuaz];
      nn = nn + i | 0;
      while (i < nn) {
        var _unary__edvuaz_0 = i;
        i = _unary__edvuaz_0 + 1 | 0;
        sortBone($this, bones.i1(pathBones[_unary__edvuaz_0]));
      }
    }
  }
}
function sortBone($this, bone) {
  if (bone.tpa_1)
    return Unit_instance;
  var parent = bone.wp9_1;
  if (!(parent == null)) {
    sortBone($this, parent);
  }
  bone.tpa_1 = true;
  $this.updateCacheProp.f(bone);
}
function sortReset($this, bones) {
  var i = 0;
  var n = bones.j1();
  $l$loop: while (i < n) {
    var bone = bones.i1(i);
    if (!bone.upa_1) {
      i = i + 1 | 0;
      continue $l$loop;
    }
    if (bone.tpa_1) {
      sortReset($this, bone.xp9_1);
    }
    bone.tpa_1 = false;
    i = i + 1 | 0;
  }
}
function init_com_esotericsoftware_spine_Skeleton(_this__u8e3s4) {
  _this__u8e3s4.updateCacheProp = FastArrayList.g14();
  _this__u8e3s4.lp7_1 = FastArrayList.g14();
  _this__u8e3s4.mp7_1 = null;
  _this__u8e3s4.op7_1 = 0;
  _this__u8e3s4.pp7_1 = 1.0;
  _this__u8e3s4.qp7_1 = 1.0;
  _this__u8e3s4.rp7_1 = 0;
  _this__u8e3s4.sp7_1 = 0;
}
function readSkeletonBinary(_this__u8e3s4, atlas, scale, $completion) {
  scale = scale === VOID ? 1.0 : scale;
  // Inline function 'kotlin.also' call
  var this_0 = SkeletonBinary.xpn(atlas);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'com.esotericsoftware.spine.readSkeletonBinary.<anonymous>' call
  this_0.ypn(scale);
  return this_0.zpn(_this__u8e3s4, $completion);
}
function _get_linkedMeshes__ybd6gn($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.upn_1;
  linkedMeshes$factory();
  return this_0.b2();
}
function *_generator_readSkeletonData__wryeud($this, file, $completion) {
  var tmp = file.v22($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return $this.apo(tmp, get_fullName(file));
}
function rgb888ToColor($this, color, value) {
  _RGBAf___set_r__impl__pz5j5e(color, ((value & 16711680) >>> 16 | 0) / 255.0);
  _RGBAf___set_g__impl__c4jj21(color, ((value & 65280) >>> 8 | 0) / 255.0);
  _RGBAf___set_b__impl__a2n9pa(color, (value & 255) / 255.0);
}
function rgba8888ToColor($this, color, value) {
  _RGBAf___set_r__impl__pz5j5e(color, ((value & -16777216) >>> 24 | 0) / 255.0);
  _RGBAf___set_g__impl__c4jj21(color, ((value & 16711680) >>> 16 | 0) / 255.0);
  _RGBAf___set_b__impl__a2n9pa(color, ((value & 65280) >>> 8 | 0) / 255.0);
  _RGBAf___set_a__impl__iqz7cx(color, (value & 255) / 255.0);
}
function readSkin($this, input, skeletonData, defaultSkin, nonessential) {
  var skin;
  var slotCount;
  if (defaultSkin) {
    slotCount = input.gpo(true);
    if (slotCount === 0)
      return null;
    skin = new Skin('default');
  } else {
    skin = new Skin(ensureNotNull(input.fpo()));
    var bones = setSize(skin.dpn_1, input.gpo(true));
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i = 0;
    var n = skin.dpn_1.j1();
    while (i < n) {
      setAndGrow(bones, i, skeletonData.zpj_1.i1(input.gpo(true)));
      i = i + 1 | 0;
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_0 = 0;
    var n_0 = input.gpo(true);
    while (i_0 < n_0) {
      skin.epn_1.f(skeletonData.fpk_1.i1(input.gpo(true)));
      i_0 = i_0 + 1 | 0;
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_1 = 0;
    var n_1 = input.gpo(true);
    while (i_1 < n_1) {
      skin.epn_1.f(skeletonData.gpk_1.i1(input.gpo(true)));
      i_1 = i_1 + 1 | 0;
    }
    var i_2 = 0;
    var n_2 = input.gpo(true);
    while (i_2 < n_2) {
      skin.epn_1.f(skeletonData.hpk_1.i1(input.gpo(true)));
      i_2 = i_2 + 1 | 0;
    }
    shrink(skin.epn_1);
    slotCount = input.gpo(true);
  }
  var inductionVariable = 0;
  if (inductionVariable < slotCount)
    do {
      var i_3 = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var slotIndex = input.gpo(true);
      var ii = 0;
      var nn = input.gpo(true);
      while (ii < nn) {
        var name = ensureNotNull(input.fpo());
        var attachment = readAttachment($this, input, skeletonData, skin, slotIndex, name, nonessential);
        if (!(attachment == null)) {
          skin.hpo(slotIndex, name, attachment);
        }
        ii = ii + 1 | 0;
      }
    }
     while (inductionVariable < slotCount);
  return skin;
}
function readAttachment($this, input, skeletonData, skin, slotIndex, attachmentName, nonessential) {
  var scale = $this.tpn_1;
  var name = input.fpo();
  if (name == null)
    name = attachmentName;
  var type = Companion_getInstance_23().ipo_1[input.y12()];
  switch (type.v3_1) {
    case 0:
      var path = input.fpo();
      var rotation = input.wmr();
      var x = input.wmr();
      var y = input.wmr();
      var scaleX = input.wmr();
      var scaleY = input.wmr();
      var width = input.wmr();
      var height = input.wmr();
      var color = input.rmr();
      if (path == null)
        path = name;
      var tmp0_elvis_lhs = $this.spn_1.jpo(skin, ensureNotNull(name), ensureNotNull(path));
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return null;
      } else {
        tmp = tmp0_elvis_lhs;
      }

      var region = tmp;
      region.mpo_1 = path;
      region.npo_1 = x * scale;
      region.opo_1 = y * scale;
      region.ppo_1 = scaleX;
      region.qpo_1 = scaleY;
      region.rpo_1 = rotation;
      region.spo_1 = width * scale;
      region.tpo_1 = height * scale;
      rgba8888ToColor($this, region.wpo_1, color);
      region.xpo();
      return region;
    case 1:
      var vertexCount = input.gpo(true);
      var vertices = readVertices($this, input, vertexCount);
      var color_0 = nonessential ? input.rmr() : 0;
      var tmp1_elvis_lhs = $this.spn_1.ypo(skin, ensureNotNull(name));
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        return null;
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }

      var box = tmp_0;
      box.xpc_1 = vertexCount << 1;
      box.wpc_1 = vertices.app_1;
      box.vpc_1 = vertices.zpo_1;
      if (nonessential) {
        rgba8888ToColor($this, box.hpp_1, color_0);
      }

      return box;
    case 2:
      var path_0 = input.fpo();
      var color_1 = input.rmr();
      var vertexCount_0 = input.gpo(true);
      var uvs = readFloatArray($this, input, vertexCount_0 << 1, 1.0);
      var triangles = readShortArray($this, input);
      var vertices_0 = readVertices($this, input, vertexCount_0);
      var hullLength = input.gpo(true);
      var edges = null;
      var width_0 = 0.0;
      var height_0 = 0.0;
      if (nonessential) {
        edges = readShortArray($this, input);
        width_0 = input.wmr();
        height_0 = input.wmr();
      }

      if (path_0 == null)
        path_0 = name;
      var tmp2_elvis_lhs = $this.spn_1.ipp(skin, ensureNotNull(name), ensureNotNull(path_0));
      var tmp_1;
      if (tmp2_elvis_lhs == null) {
        return null;
      } else {
        tmp_1 = tmp2_elvis_lhs;
      }

      var mesh = tmp_1;
      mesh.qpp_1 = path_0;
      rgba8888ToColor($this, mesh.upp_1, color_1);
      mesh.vpc_1 = vertices_0.zpo_1;
      mesh.wpc_1 = vertices_0.app_1;
      mesh.xpc_1 = vertexCount_0 << 1;
      mesh.tpp_1 = triangles;
      mesh.rpp_1 = uvs;
      mesh.apq();
      mesh.vpp_1 = hullLength << 1;
      if (nonessential) {
        mesh.xpp_1 = edges;
        mesh.ypp_1 = width_0 * scale;
        mesh.zpp_1 = height_0 * scale;
      }

      return mesh;
    case 3:
      var path_1 = input.fpo();
      var color_2 = input.rmr();
      var skinName = input.fpo();
      var parent = input.fpo();
      var inheritDeform = input.bpq();
      var width_1 = 0.0;
      var height_1 = 0.0;
      if (nonessential) {
        width_1 = input.wmr();
        height_1 = input.wmr();
      }

      if (path_1 == null)
        path_1 = name;
      var tmp3_elvis_lhs = $this.spn_1.ipp(skin, ensureNotNull(name), ensureNotNull(path_1));
      var tmp_2;
      if (tmp3_elvis_lhs == null) {
        return null;
      } else {
        tmp_2 = tmp3_elvis_lhs;
      }

      var mesh_0 = tmp_2;
      mesh_0.qpp_1 = path_1;
      rgba8888ToColor($this, mesh_0.upp_1, color_2);
      if (nonessential) {
        mesh_0.ypp_1 = width_1 * scale;
        mesh_0.zpp_1 = height_1 * scale;
      }

      _get_linkedMeshes__ybd6gn($this).f(new LinkedMesh(mesh_0, skinName, slotIndex, parent, inheritDeform));
      return mesh_0;
    case 4:
      var closed = input.bpq();
      var constantSpeed = input.bpq();
      var vertexCount_1 = input.gpo(true);
      var vertices_1 = readVertices($this, input, vertexCount_1);
      var lengths = new Float32Array(vertexCount_1 / 3 | 0);
      var i = 0;
      var n = lengths.length;
      while (i < n) {
        lengths[i] = input.wmr() * scale;
        i = i + 1 | 0;
      }

      var color_3 = nonessential ? input.rmr() : 0;
      var tmp4_elvis_lhs = $this.spn_1.cpq(skin, ensureNotNull(name));
      var tmp_3;
      if (tmp4_elvis_lhs == null) {
        return null;
      } else {
        tmp_3 = tmp4_elvis_lhs;
      }

      var path_2 = tmp_3;
      path_2.tpm_1 = closed;
      path_2.upm_1 = constantSpeed;
      path_2.xpc_1 = vertexCount_1 << 1;
      path_2.wpc_1 = vertices_1.app_1;
      path_2.vpc_1 = vertices_1.zpo_1;
      path_2.spm_1 = lengths;
      if (nonessential) {
        rgba8888ToColor($this, path_2.vpm_1, color_3);
      }

      return path_2;
    case 5:
      var rotation_0 = input.wmr();
      var x_0 = input.wmr();
      var y_0 = input.wmr();
      var color_4 = nonessential ? input.rmr() : 0;
      var tmp5_elvis_lhs = $this.spn_1.dpq(skin, ensureNotNull(name));
      var tmp_4;
      if (tmp5_elvis_lhs == null) {
        return null;
      } else {
        tmp_4 = tmp5_elvis_lhs;
      }

      var point = tmp_4;
      point.fpq_1 = x_0 * scale;
      point.gpq_1 = y_0 * scale;
      point.hpq_1 = rotation_0;
      if (nonessential) {
        rgba8888ToColor($this, point.ipq_1, color_4);
      }

      return point;
    case 6:
      var endSlotIndex = input.gpo(true);
      var vertexCount_2 = input.gpo(true);
      var vertices_2 = readVertices($this, input, vertexCount_2);
      var color_5 = nonessential ? input.rmr() : 0;
      var tmp6_elvis_lhs = $this.spn_1.jpq(skin, ensureNotNull(name));
      var tmp_5;
      if (tmp6_elvis_lhs == null) {
        return null;
      } else {
        tmp_5 = tmp6_elvis_lhs;
      }

      var clip = tmp_5;
      clip.qpq_1 = skeletonData.apk_1.i1(endSlotIndex);
      clip.xpc_1 = vertexCount_2 << 1;
      clip.wpc_1 = vertices_2.app_1;
      clip.vpc_1 = vertices_2.zpo_1;
      if (nonessential) {
        rgba8888ToColor($this, clip.rpq_1, color_5);
      }

      return clip;
    default:
      noWhenBranchMatchedException();
      break;
  }
}
function readVertices($this, input, vertexCount) {
  var verticesLength = vertexCount << 1;
  var vertices = new Vertices();
  if (!input.bpq()) {
    vertices.app_1 = readFloatArray($this, input, verticesLength, $this.tpn_1);
    return vertices;
  }
  var weights = FloatArrayList.t30(imul(imul(verticesLength, 3), 3));
  var bonesArray = IntArrayList.f31(imul(verticesLength, 3));
  var inductionVariable = 0;
  if (inductionVariable < vertexCount)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var boneCount = input.gpo(true);
      bonesArray.l31(boneCount);
      var inductionVariable_0 = 0;
      if (inductionVariable_0 < boneCount)
        do {
          var ii = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          bonesArray.l31(input.gpo(true));
          weights.w30(input.wmr() * $this.tpn_1);
          weights.w30(input.wmr() * $this.tpn_1);
          weights.w30(input.wmr());
        }
         while (inductionVariable_0 < boneCount);
    }
     while (inductionVariable < vertexCount);
  vertices.app_1 = toArray(weights);
  vertices.zpo_1 = toArray_0(bonesArray);
  return vertices;
}
function readFloatArray($this, input, n, scale) {
  var array = new Float32Array(n);
  if (scale === 1.0) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        array[i] = input.wmr();
      }
       while (inductionVariable < n);
  } else {
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < n)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        array[i_0] = input.wmr() * scale;
      }
       while (inductionVariable_0 < n);
  }
  return array;
}
function readShortArray($this, input) {
  var n = input.gpo(true);
  var array = new Int16Array(n);
  var inductionVariable = 0;
  if (inductionVariable < n)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      array[i] = input.cmq();
    }
     while (inductionVariable < n);
  return array;
}
function readAnimation($this, input, name, skeletonData) {
  var timelines = FastArrayList.f14(32);
  var scale = $this.tpn_1;
  var duration = 0.0;
  try {
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i = 0;
    var n = input.gpo(true);
    while (i < n) {
      var slotIndex = input.gpo(true);
      var ii = 0;
      var nn = input.gpo(true);
      while (ii < nn) {
        var timelineType = input.y12();
        var frameCount = input.gpo(true);
        if (timelineType === Companion_instance_18.wpq_1) {
          var timeline = new AttachmentTimeline(frameCount);
          timeline.ypb(slotIndex);
          var inductionVariable = 0;
          if (inductionVariable < frameCount)
            do {
              var frameIndex = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              timeline.lpc(frameIndex, input.wmr(), input.fpo());
            }
             while (inductionVariable < frameCount);
          timelines.f(timeline);
          // Inline function 'kotlin.math.max' call
          var a = duration;
          var b = timeline.cp7_1[frameCount - 1 | 0];
          duration = Math.max(a, b);
        } else if (timelineType === Companion_instance_18.xpq_1) {
          var timeline_0 = new ColorTimeline(frameCount);
          timeline_0.ypb(slotIndex);
          var inductionVariable_0 = 0;
          if (inductionVariable_0 < frameCount)
            do {
              var frameIndex_0 = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              var time = input.wmr();
              rgba8888ToColor($this, $this.vpn_1, input.rmr());
              timeline_0.zpb(frameIndex_0, time, _RGBAf___get_r__impl__jfq8gq($this.vpn_1), _RGBAf___get_g__impl__dhoraz($this.vpn_1), _RGBAf___get_b__impl__fjl0nq($this.vpn_1), _RGBAf___get_a__impl__qnwk97($this.vpn_1));
              if (frameIndex_0 < (frameCount - 1 | 0)) {
                readCurve($this, input, frameIndex_0, timeline_0);
              }
            }
             while (inductionVariable_0 < frameCount);
          timelines.f(timeline_0);
          // Inline function 'kotlin.math.max' call
          var a_0 = duration;
          var b_0 = timeline_0.xpb_1[imul(frameCount - 1 | 0, Companion_instance_3.bp6_1)];
          duration = Math.max(a_0, b_0);
        } else if (timelineType === Companion_instance_18.ypq_1) {
          var timeline_1 = new TwoColorTimeline(frameCount);
          timeline_1.ypb(slotIndex);
          var inductionVariable_1 = 0;
          if (inductionVariable_1 < frameCount)
            do {
              var frameIndex_1 = inductionVariable_1;
              inductionVariable_1 = inductionVariable_1 + 1 | 0;
              var time_0 = input.wmr();
              rgba8888ToColor($this, $this.vpn_1, input.rmr());
              rgb888ToColor($this, $this.wpn_1, input.rmr());
              timeline_1.kpc(frameIndex_1, time_0, _RGBAf___get_r__impl__jfq8gq($this.vpn_1), _RGBAf___get_g__impl__dhoraz($this.vpn_1), _RGBAf___get_b__impl__fjl0nq($this.vpn_1), _RGBAf___get_a__impl__qnwk97($this.vpn_1), _RGBAf___get_r__impl__jfq8gq($this.wpn_1), _RGBAf___get_g__impl__dhoraz($this.wpn_1), _RGBAf___get_b__impl__fjl0nq($this.wpn_1));
              if (frameIndex_1 < (frameCount - 1 | 0)) {
                readCurve($this, input, frameIndex_1, timeline_1);
              }
            }
             while (inductionVariable_1 < frameCount);
          timelines.f(timeline_1);
          // Inline function 'kotlin.math.max' call
          var a_1 = duration;
          var b_1 = timeline_1.jpc_1[imul(frameCount - 1 | 0, Companion_instance_4.lp6_1)];
          duration = Math.max(a_1, b_1);
        }
        ii = ii + 1 | 0;
      }
      i = i + 1 | 0;
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_0 = 0;
    var n_0 = input.gpo(true);
    while (i_0 < n_0) {
      var boneIndex = input.gpo(true);
      var ii_0 = 0;
      var nn_0 = input.gpo(true);
      while (ii_0 < nn_0) {
        var timelineType_0 = input.y12();
        var frameCount_0 = input.gpo(true);
        if (timelineType_0 === Companion_instance_18.spq_1) {
          var timeline_2 = new RotateTimeline(frameCount_0);
          timeline_2.sp9(boneIndex);
          var inductionVariable_2 = 0;
          if (inductionVariable_2 < frameCount_0)
            do {
              var frameIndex_2 = inductionVariable_2;
              inductionVariable_2 = inductionVariable_2 + 1 | 0;
              timeline_2.tp9(frameIndex_2, input.wmr(), input.wmr());
              if (frameIndex_2 < (frameCount_0 - 1 | 0)) {
                readCurve($this, input, frameIndex_2, timeline_2);
              }
            }
             while (inductionVariable_2 < frameCount_0);
          timelines.f(timeline_2);
          // Inline function 'kotlin.math.max' call
          var a_2 = duration;
          var b_2 = timeline_2.rp9_1[imul(frameCount_0 - 1 | 0, Companion_instance_1.rp5_1)];
          duration = Math.max(a_2, b_2);
        } else if (timelineType_0 === Companion_instance_18.tpq_1 || (timelineType_0 === Companion_instance_18.upq_1 || timelineType_0 === Companion_instance_18.vpq_1)) {
          var timeline_3;
          var timelineScale = 1.0;
          if (timelineType_0 === Companion_instance_18.upq_1)
            timeline_3 = new ScaleTimeline(frameCount_0);
          else if (timelineType_0 === Companion_instance_18.vpq_1)
            timeline_3 = new ShearTimeline(frameCount_0);
          else {
            timeline_3 = new TranslateTimeline(frameCount_0);
            timelineScale = scale;
          }
          timeline_3.sp9(boneIndex);
          var inductionVariable_3 = 0;
          if (inductionVariable_3 < frameCount_0)
            do {
              var frameIndex_3 = inductionVariable_3;
              inductionVariable_3 = inductionVariable_3 + 1 | 0;
              timeline_3.opb(frameIndex_3, input.wmr(), input.wmr() * timelineScale, input.wmr() * timelineScale);
              if (frameIndex_3 < (frameCount_0 - 1 | 0)) {
                readCurve($this, input, frameIndex_3, timeline_3);
              }
            }
             while (inductionVariable_3 < frameCount_0);
          timelines.f(timeline_3);
          // Inline function 'kotlin.math.max' call
          var a_3 = duration;
          var b_3 = timeline_3.mpb_1[imul(frameCount_0 - 1 | 0, Companion_instance_2.vp5_1)];
          duration = Math.max(a_3, b_3);
        }
        ii_0 = ii_0 + 1 | 0;
      }
      i_0 = i_0 + 1 | 0;
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_1 = 0;
    var n_1 = input.gpo(true);
    while (i_1 < n_1) {
      var index = input.gpo(true);
      var frameCount_1 = input.gpo(true);
      var timeline_4 = new IkConstraintTimeline(frameCount_1);
      timeline_4.opd_1 = index;
      var inductionVariable_4 = 0;
      if (inductionVariable_4 < frameCount_1)
        do {
          var frameIndex_4 = inductionVariable_4;
          inductionVariable_4 = inductionVariable_4 + 1 | 0;
          timeline_4.qpd(frameIndex_4, input.wmr(), input.wmr(), input.wmr() * scale, input.y12(), input.bpq(), input.bpq());
          if (frameIndex_4 < (frameCount_1 - 1 | 0)) {
            readCurve($this, input, frameIndex_4, timeline_4);
          }
        }
         while (inductionVariable_4 < frameCount_1);
      timelines.f(timeline_4);
      // Inline function 'kotlin.math.max' call
      var a_4 = duration;
      var b_4 = timeline_4.ppd_1[imul(frameCount_1 - 1 | 0, Companion_instance_5.dp8_1)];
      duration = Math.max(a_4, b_4);
      i_1 = i_1 + 1 | 0;
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_2 = 0;
    var n_2 = input.gpo(true);
    while (i_2 < n_2) {
      var index_0 = input.gpo(true);
      var frameCount_2 = input.gpo(true);
      var timeline_5 = new TransformConstraintTimeline(frameCount_2);
      timeline_5.mpe_1 = index_0;
      var inductionVariable_5 = 0;
      if (inductionVariable_5 < frameCount_2)
        do {
          var frameIndex_5 = inductionVariable_5;
          inductionVariable_5 = inductionVariable_5 + 1 | 0;
          timeline_5.zpb(frameIndex_5, input.wmr(), input.wmr(), input.wmr(), input.wmr(), input.wmr());
          if (frameIndex_5 < (frameCount_2 - 1 | 0)) {
            readCurve($this, input, frameIndex_5, timeline_5);
          }
        }
         while (inductionVariable_5 < frameCount_2);
      timelines.f(timeline_5);
      // Inline function 'kotlin.math.max' call
      var a_5 = duration;
      var b_5 = timeline_5.npe_1[imul(frameCount_2 - 1 | 0, Companion_instance_6.pp8_1)];
      duration = Math.max(a_5, b_5);
      i_2 = i_2 + 1 | 0;
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_3 = 0;
    var n_3 = input.gpo(true);
    while (i_3 < n_3) {
      var index_1 = input.gpo(true);
      var data = skeletonData.hpk_1.i1(index_1);
      var ii_1 = 0;
      var nn_1 = input.gpo(true);
      while (ii_1 < nn_1) {
        var timelineType_1 = input.y12();
        var frameCount_3 = input.gpo(true);
        if (timelineType_1 === Companion_instance_18.zpq_1 || timelineType_1 === Companion_instance_18.apr_1) {
          var timeline_6;
          var timelineScale_0 = 1.0;
          if (timelineType_1 === Companion_instance_18.apr_1) {
            timeline_6 = new PathConstraintSpacingTimeline(frameCount_3);
            if (data.ipm().equals(SpacingMode_length_getInstance()) || data.ipm().equals(SpacingMode_fixed_getInstance()))
              timelineScale_0 = scale;
          } else {
            timeline_6 = new PathConstraintPositionTimeline(frameCount_3);
            if (data.kpm().equals(PositionMode_fixed_getInstance()))
              timelineScale_0 = scale;
          }
          timeline_6.ppf_1 = index_1;
          var inductionVariable_6 = 0;
          if (inductionVariable_6 < frameCount_3)
            do {
              var frameIndex_6 = inductionVariable_6;
              inductionVariable_6 = inductionVariable_6 + 1 | 0;
              timeline_6.tp9(frameIndex_6, input.wmr(), input.wmr() * timelineScale_0);
              if (frameIndex_6 < (frameCount_3 - 1 | 0)) {
                readCurve($this, input, frameIndex_6, timeline_6);
              }
            }
             while (inductionVariable_6 < frameCount_3);
          timelines.f(timeline_6);
          // Inline function 'kotlin.math.max' call
          var a_6 = duration;
          var b_6 = timeline_6.qpf_1[imul(frameCount_3 - 1 | 0, Companion_instance_7.zp8_1)];
          duration = Math.max(a_6, b_6);
        } else if (timelineType_1 === Companion_instance_18.bpr_1) {
          var timeline_7 = new PathConstraintMixTimeline(frameCount_3);
          timeline_7.wpg_1 = index_1;
          var inductionVariable_7 = 0;
          if (inductionVariable_7 < frameCount_3)
            do {
              var frameIndex_7 = inductionVariable_7;
              inductionVariable_7 = inductionVariable_7 + 1 | 0;
              timeline_7.opb(frameIndex_7, input.wmr(), input.wmr(), input.wmr());
              if (frameIndex_7 < (frameCount_3 - 1 | 0)) {
                readCurve($this, input, frameIndex_7, timeline_7);
              }
            }
             while (inductionVariable_7 < frameCount_3);
          timelines.f(timeline_7);
          // Inline function 'kotlin.math.max' call
          var a_7 = duration;
          var b_7 = timeline_7.xpg_1[imul(frameCount_3 - 1 | 0, Companion_instance_8.dp9_1)];
          duration = Math.max(a_7, b_7);
        }
        ii_1 = ii_1 + 1 | 0;
      }
      i_3 = i_3 + 1 | 0;
    }
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_4 = 0;
    var n_4 = input.gpo(true);
    while (i_4 < n_4) {
      var skin = skeletonData.bpk_1.i1(input.gpo(true));
      var ii_2 = 0;
      var nn_2 = input.gpo(true);
      while (ii_2 < nn_2) {
        var slotIndex_0 = input.gpo(true);
        var iii = 0;
        var nnn = input.gpo(true);
        while (iii < nnn) {
          var tmp = skin.tp7(slotIndex_0, ensureNotNull(input.fpo()));
          var attachment = tmp instanceof VertexAttachment ? tmp : THROW_CCE();
          var weighted = !(attachment.vpc_1 == null);
          var vertices = attachment.wpc_1;
          var deformLength = weighted ? imul(ensureNotNull(vertices).length / 3 | 0, 2) : ensureNotNull(vertices).length;
          var frameCount_4 = input.gpo(true);
          var timeline_8 = new DeformTimeline(frameCount_4);
          timeline_8.ypb(slotIndex_0);
          timeline_8.ppc_1 = attachment;
          var inductionVariable_8 = 0;
          if (inductionVariable_8 < frameCount_4)
            do {
              var frameIndex_8 = inductionVariable_8;
              inductionVariable_8 = inductionVariable_8 + 1 | 0;
              var time_1 = input.wmr();
              var deform;
              var end = input.gpo(true);
              if (end === 0)
                deform = weighted ? new Float32Array(deformLength) : vertices;
              else {
                deform = new Float32Array(deformLength);
                var start = input.gpo(true);
                end = end + start | 0;
                if (scale === 1.0) {
                  var inductionVariable_9 = start;
                  var last = end;
                  if (inductionVariable_9 < last)
                    do {
                      var v = inductionVariable_9;
                      inductionVariable_9 = inductionVariable_9 + 1 | 0;
                      deform[v] = input.wmr();
                    }
                     while (inductionVariable_9 < last);
                } else {
                  var inductionVariable_10 = start;
                  var last_0 = end;
                  if (inductionVariable_10 < last_0)
                    do {
                      var v_0 = inductionVariable_10;
                      inductionVariable_10 = inductionVariable_10 + 1 | 0;
                      deform[v_0] = input.wmr() * scale;
                    }
                     while (inductionVariable_10 < last_0);
                }
                if (!weighted) {
                  var v_1 = 0;
                  var vn = deform.length;
                  while (v_1 < vn) {
                    var _array__4zh2yp = deform;
                    var _index_0__fvwizt = v_1;
                    _array__4zh2yp[_index_0__fvwizt] = _array__4zh2yp[_index_0__fvwizt] + vertices[v_1];
                    v_1 = v_1 + 1 | 0;
                  }
                }
              }
              timeline_8.zpc(frameIndex_8, time_1, deform);
              if (frameIndex_8 < (frameCount_4 - 1 | 0)) {
                readCurve($this, input, frameIndex_8, timeline_8);
              }
            }
             while (inductionVariable_8 < frameCount_4);
          timelines.f(timeline_8);
          // Inline function 'kotlin.math.max' call
          var a_8 = duration;
          var b_8 = timeline_8.qpc_1[frameCount_4 - 1 | 0];
          duration = Math.max(a_8, b_8);
          iii = iii + 1 | 0;
        }
        ii_2 = ii_2 + 1 | 0;
      }
      i_4 = i_4 + 1 | 0;
    }
    var drawOrderCount = input.gpo(true);
    if (drawOrderCount > 0) {
      var timeline_9 = new DrawOrderTimeline(drawOrderCount);
      var slotCount = skeletonData.apk_1.j1();
      var inductionVariable_11 = 0;
      if (inductionVariable_11 < drawOrderCount)
        do {
          var i_5 = inductionVariable_11;
          inductionVariable_11 = inductionVariable_11 + 1 | 0;
          var time_2 = input.wmr();
          var offsetCount = input.gpo(true);
          var drawOrder = new Int32Array(slotCount);
          var inductionVariable_12 = slotCount - 1 | 0;
          if (0 <= inductionVariable_12)
            do {
              var ii_3 = inductionVariable_12;
              inductionVariable_12 = inductionVariable_12 + -1 | 0;
              drawOrder[ii_3] = -1;
            }
             while (0 <= inductionVariable_12);
          var unchanged = new Int32Array(slotCount - offsetCount | 0);
          var originalIndex = 0;
          var unchangedIndex = 0;
          var inductionVariable_13 = 0;
          if (inductionVariable_13 < offsetCount)
            do {
              var ii_4 = inductionVariable_13;
              inductionVariable_13 = inductionVariable_13 + 1 | 0;
              var slotIndex_1 = input.gpo(true);
              while (!(originalIndex === slotIndex_1)) {
                var _unary__edvuaz = unchangedIndex;
                unchangedIndex = _unary__edvuaz + 1 | 0;
                var _unary__edvuaz_0 = originalIndex;
                originalIndex = _unary__edvuaz_0 + 1 | 0;
                unchanged[_unary__edvuaz] = _unary__edvuaz_0;
              }
              var tmp_0 = originalIndex + input.gpo(true) | 0;
              var _unary__edvuaz_1 = originalIndex;
              originalIndex = _unary__edvuaz_1 + 1 | 0;
              drawOrder[tmp_0] = _unary__edvuaz_1;
            }
             while (inductionVariable_13 < offsetCount);
          while (originalIndex < slotCount) {
            var _unary__edvuaz_2 = unchangedIndex;
            unchangedIndex = _unary__edvuaz_2 + 1 | 0;
            var _unary__edvuaz_3 = originalIndex;
            originalIndex = _unary__edvuaz_3 + 1 | 0;
            unchanged[_unary__edvuaz_2] = _unary__edvuaz_3;
          }
          var inductionVariable_14 = slotCount - 1 | 0;
          if (0 <= inductionVariable_14)
            do {
              var ii_5 = inductionVariable_14;
              inductionVariable_14 = inductionVariable_14 + -1 | 0;
              if (drawOrder[ii_5] === -1) {
                unchangedIndex = unchangedIndex - 1 | 0;
                drawOrder[ii_5] = unchanged[unchangedIndex];
              }
            }
             while (0 <= inductionVariable_14);
          timeline_9.mpd(i_5, time_2, drawOrder);
        }
         while (inductionVariable_11 < drawOrderCount);
      timelines.f(timeline_9);
      // Inline function 'kotlin.math.max' call
      var a_9 = duration;
      var b_9 = timeline_9.kpd_1[drawOrderCount - 1 | 0];
      duration = Math.max(a_9, b_9);
    }
    var eventCount = input.gpo(true);
    if (eventCount > 0) {
      var timeline_10 = new EventTimeline(eventCount);
      var inductionVariable_15 = 0;
      if (inductionVariable_15 < eventCount)
        do {
          var i_6 = inductionVariable_15;
          inductionVariable_15 = inductionVariable_15 + 1 | 0;
          var time_3 = input.wmr();
          var eventData = skeletonData.dpk_1.i1(input.gpo(true));
          var event = new Event(time_3, eventData);
          event.fpd_1 = input.gpo(false);
          event.gpd_1 = input.wmr();
          event.hpd_1 = input.bpq() ? ensureNotNull(input.dm0()) : eventData.wpl();
          if (!(event.epd_1.tpl_1 == null)) {
            event.ipd_1 = input.wmr();
            event.jpd_1 = input.wmr();
          }
          timeline_10.cpd(i_6, event);
        }
         while (inductionVariable_15 < eventCount);
      timelines.f(timeline_10);
      // Inline function 'kotlin.math.max' call
      var a_10 = duration;
      var b_10 = timeline_10.apd_1[eventCount - 1 | 0];
      duration = Math.max(a_10, b_10);
    }
  } catch ($p) {
    if ($p instanceof Error) {
      var ex = $p;
      throw RuntimeException.ye('Error reading skeleton file.', ex);
    } else {
      throw $p;
    }
  }
  shrink(timelines);
  return new Animation(ensureNotNull(name), timelines, duration);
}
function readCurve($this, input, frameIndex, timeline) {
  var tmp31_subject = input.y12();
  if (tmp31_subject === Companion_instance_18.dpr_1) {
    timeline.mp9(frameIndex);
  } else if (tmp31_subject === Companion_instance_18.epr_1) {
    $this.fpr(timeline, frameIndex, input.wmr(), input.wmr(), input.wmr(), input.wmr());
  }
}
var Companion_instance_18;
function Companion_getInstance_22() {
  return Companion_instance_18;
}
function SkeletonBinary$linkedMeshes$delegate$lambda() {
  return FastArrayList.g14();
}
function init_com_esotericsoftware_spine_SkeletonBinary(_this__u8e3s4) {
  _this__u8e3s4.tpn_1 = 1.0;
  var tmp = _this__u8e3s4;
  tmp.upn_1 = lazy(SkeletonBinary$linkedMeshes$delegate$lambda);
  _this__u8e3s4.vpn_1 = _RGBAf___init__impl__idjpgn();
  _this__u8e3s4.wpn_1 = _RGBAf___init__impl__idjpgn();
}
function linkedMeshes$factory() {
  return getPropertyCallableRef('linkedMeshes', 1, KProperty1, function (receiver) {
    return _get_linkedMeshes__ybd6gn(receiver);
  }, null);
}
function init_com_esotericsoftware_spine_Skin_SkinEntry(_this__u8e3s4) {
  _this__u8e3s4.hpn_1 = 0;
  _this__u8e3s4.jpn_1 = null;
  _this__u8e3s4.kpn_1 = 0;
}
function init_com_esotericsoftware_spine_Slot(_this__u8e3s4) {
  _this__u8e3s4.wp7_1 = _RGBAf___init__impl__idjpgn();
  _this__u8e3s4.yp7_1 = null;
  _this__u8e3s4.zp7_1 = 0;
  _this__u8e3s4.ap8_1 = FloatArrayList.t30();
  _this__u8e3s4.bp8_1 = 0;
}
function applyAbsoluteWorld($this) {
  var rotateMix = $this.rpe_1;
  var translateMix = $this.spe_1;
  var scaleMix = $this.tpe_1;
  var shearMix = $this.upe_1;
  var target = $this.qpe_1;
  var ta = ensureNotNull(target).npa_1;
  var tb = target.opa_1;
  var tc = target.qpa_1;
  var td = target.rpa_1;
  var degRadReflect = ta * td - tb * tc > 0 ? 0.017453292 : -0.017453292;
  var offsetRotation = $this.ope_1.gpf_1 * degRadReflect;
  var offsetShearY = $this.ope_1.lpf_1 * degRadReflect;
  var bones = $this.ppe_1;
  var i = 0;
  var n = bones.j1();
  while (i < n) {
    var bone = bones.i1(i);
    var modified = false;
    if (!(rotateMix === 0.0)) {
      var a = bone.npa_1;
      var b = bone.opa_1;
      var c = bone.qpa_1;
      var d = bone.rpa_1;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var tmp = Math.atan2(tc, ta);
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var r = tmp - Math.atan2(c, a) + offsetRotation;
      if (r > 3.1415927)
        r = r - 6.2831855;
      else if (r < -3.1415927)
        r = r + 6.2831855;
      r = r * rotateMix;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cos' call
      // Inline function 'kotlin.math.cos' call
      var x = r;
      var cos = Math.cos(x);
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sin' call
      // Inline function 'kotlin.math.sin' call
      var x_0 = r;
      var sin = Math.sin(x_0);
      bone.npa_1 = cos * a - sin * c;
      bone.opa_1 = cos * b - sin * d;
      bone.qpa_1 = sin * a + cos * c;
      bone.rpa_1 = sin * b + cos * d;
      modified = true;
    }
    if (!(translateMix === 0.0)) {
      var temp = $this.wpe_1;
      target.hpl(temp.ci4($this.ope_1.hpf_1, $this.ope_1.ipf_1));
      bone.ppa_1 = bone.ppa_1 + (temp.ipl_1 - bone.ppa_1) * translateMix;
      bone.spa_1 = bone.spa_1 + (temp.jpl_1 - bone.spa_1) * translateMix;
      modified = true;
    }
    if (scaleMix > 0) {
      // Inline function 'kotlin.math.sqrt' call
      var x_1 = bone.npa_1 * bone.npa_1 + bone.qpa_1 * bone.qpa_1;
      var s = Math.sqrt(x_1);
      if (!(s === 0.0)) {
        var tmp_0 = s;
        // Inline function 'kotlin.math.sqrt' call
        var x_2 = ta * ta + tc * tc;
        s = (tmp_0 + (Math.sqrt(x_2) - s + $this.ope_1.jpf_1) * scaleMix) / s;
      }
      bone.npa_1 = bone.npa_1 * s;
      bone.qpa_1 = bone.qpa_1 * s;
      // Inline function 'kotlin.math.sqrt' call
      var x_3 = bone.opa_1 * bone.opa_1 + bone.rpa_1 * bone.rpa_1;
      s = Math.sqrt(x_3);
      if (!(s === 0.0)) {
        var tmp_1 = s;
        // Inline function 'kotlin.math.sqrt' call
        var x_4 = tb * tb + td * td;
        s = (tmp_1 + (Math.sqrt(x_4) - s + $this.ope_1.kpf_1) * scaleMix) / s;
      }
      bone.opa_1 = bone.opa_1 * s;
      bone.rpa_1 = bone.rpa_1 * s;
      modified = true;
    }
    if (shearMix > 0) {
      var b_0 = bone.opa_1;
      var d_0 = bone.rpa_1;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var by = Math.atan2(d_0, b_0);
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var tmp_2 = Math.atan2(td, tb);
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var tmp_3 = tmp_2 - Math.atan2(tc, ta);
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      var y = bone.qpa_1;
      // Inline function 'kotlin.math.atan2' call
      var x_5 = bone.npa_1;
      var r_0 = tmp_3 - (by - Math.atan2(y, x_5));
      if (r_0 > 3.1415927)
        r_0 = r_0 - 6.2831855;
      else if (r_0 < -3.1415927)
        r_0 = r_0 + 6.2831855;
      r_0 = by + (r_0 + offsetShearY) * shearMix;
      // Inline function 'kotlin.math.sqrt' call
      var x_6 = b_0 * b_0 + d_0 * d_0;
      var s_0 = Math.sqrt(x_6);
      var tmp_4 = bone;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cos' call
      // Inline function 'kotlin.math.cos' call
      var x_7 = r_0;
      tmp_4.opa_1 = Math.cos(x_7) * s_0;
      var tmp_5 = bone;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sin' call
      // Inline function 'kotlin.math.sin' call
      var x_8 = r_0;
      tmp_5.rpa_1 = Math.sin(x_8) * s_0;
      modified = true;
    }
    if (modified)
      bone.mpa_1 = false;
    i = i + 1 | 0;
  }
}
function applyRelativeWorld($this) {
  var rotateMix = $this.rpe_1;
  var translateMix = $this.spe_1;
  var scaleMix = $this.tpe_1;
  var shearMix = $this.upe_1;
  var target = $this.qpe_1;
  var ta = ensureNotNull(target).npa_1;
  var tb = target.opa_1;
  var tc = target.qpa_1;
  var td = target.rpa_1;
  var degRadReflect = ta * td - tb * tc > 0 ? 0.017453292 : -0.017453292;
  var offsetRotation = $this.ope_1.gpf_1 * degRadReflect;
  var offsetShearY = $this.ope_1.lpf_1 * degRadReflect;
  var bones = $this.ppe_1;
  var i = 0;
  var n = bones.j1();
  while (i < n) {
    var bone = bones.i1(i);
    var modified = false;
    if (!(rotateMix === 0.0)) {
      var a = bone.npa_1;
      var b = bone.opa_1;
      var c = bone.qpa_1;
      var d = bone.rpa_1;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var r = Math.atan2(tc, ta) + offsetRotation;
      if (r > 3.1415927)
        r = r - 6.2831855;
      else if (r < -3.1415927)
        r = r + 6.2831855;
      r = r * rotateMix;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cos' call
      // Inline function 'kotlin.math.cos' call
      var x = r;
      var cos = Math.cos(x);
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sin' call
      // Inline function 'kotlin.math.sin' call
      var x_0 = r;
      var sin = Math.sin(x_0);
      bone.npa_1 = cos * a - sin * c;
      bone.opa_1 = cos * b - sin * d;
      bone.qpa_1 = sin * a + cos * c;
      bone.rpa_1 = sin * b + cos * d;
      modified = true;
    }
    if (!(translateMix === 0.0)) {
      var temp = $this.wpe_1;
      target.hpl(temp.ci4($this.ope_1.hpf_1, $this.ope_1.ipf_1));
      bone.ppa_1 = bone.ppa_1 + temp.ipl_1 * translateMix;
      bone.spa_1 = bone.spa_1 + temp.jpl_1 * translateMix;
      modified = true;
    }
    if (scaleMix > 0) {
      // Inline function 'kotlin.math.sqrt' call
      var x_1 = ta * ta + tc * tc;
      var s = (Math.sqrt(x_1) - 1 + $this.ope_1.jpf_1) * scaleMix + 1;
      bone.npa_1 = bone.npa_1 * s;
      bone.qpa_1 = bone.qpa_1 * s;
      // Inline function 'kotlin.math.sqrt' call
      var x_2 = tb * tb + td * td;
      s = (Math.sqrt(x_2) - 1 + $this.ope_1.kpf_1) * scaleMix + 1;
      bone.opa_1 = bone.opa_1 * s;
      bone.rpa_1 = bone.rpa_1 * s;
      modified = true;
    }
    if (shearMix > 0) {
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var tmp = Math.atan2(td, tb);
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      var r_0 = tmp - Math.atan2(tc, ta);
      if (r_0 > 3.1415927)
        r_0 = r_0 - 6.2831855;
      else if (r_0 < -3.1415927)
        r_0 = r_0 + 6.2831855;
      var b_0 = bone.opa_1;
      var d_0 = bone.rpa_1;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.atan2' call
      // Inline function 'kotlin.math.atan2' call
      r_0 = Math.atan2(d_0, b_0) + (r_0 - 3.1415927 / 2 + offsetShearY) * shearMix;
      // Inline function 'kotlin.math.sqrt' call
      var x_3 = b_0 * b_0 + d_0 * d_0;
      var s_0 = Math.sqrt(x_3);
      var tmp_0 = bone;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.cos' call
      // Inline function 'kotlin.math.cos' call
      var x_4 = r_0;
      tmp_0.opa_1 = Math.cos(x_4) * s_0;
      var tmp_1 = bone;
      // Inline function 'com.esotericsoftware.spine.utils.SpineUtils.sin' call
      // Inline function 'kotlin.math.sin' call
      var x_5 = r_0;
      tmp_1.rpa_1 = Math.sin(x_5) * s_0;
      modified = true;
    }
    if (modified)
      bone.mpa_1 = false;
    i = i + 1 | 0;
  }
}
function applyAbsoluteLocal($this) {
  var rotateMix = $this.rpe_1;
  var translateMix = $this.spe_1;
  var scaleMix = $this.tpe_1;
  var shearMix = $this.upe_1;
  var target = $this.qpe_1;
  if (!ensureNotNull(target).mpa_1) {
    target.gpl();
  }
  var bones = $this.ppe_1;
  var i = 0;
  var n = bones.j1();
  while (i < n) {
    var bone = bones.i1(i);
    if (!bone.mpa_1) {
      bone.gpl();
    }
    var rotation = bone.hpa_1;
    if (!(rotateMix === 0.0)) {
      var r = target.hpa_1 - rotation + $this.ope_1.gpf_1;
      r = r - imul(16384 - numberToInt(16384.499999999996 - r / 360) | 0, 360);
      rotation = rotation + r * rotateMix;
    }
    var x = bone.fpa_1;
    var y = bone.gpa_1;
    if (!(translateMix === 0.0)) {
      x = x + (target.fpa_1 - x + $this.ope_1.hpf_1) * translateMix;
      y = y + (target.gpa_1 - y + $this.ope_1.ipf_1) * translateMix;
    }
    var scaleX = bone.ipa_1;
    var scaleY = bone.jpa_1;
    if (!(scaleMix === 0.0)) {
      if (!(scaleX === 0.0))
        scaleX = (scaleX + (target.ipa_1 - scaleX + $this.ope_1.jpf_1) * scaleMix) / scaleX;
      if (!(scaleY === 0.0))
        scaleY = (scaleY + (target.jpa_1 - scaleY + $this.ope_1.kpf_1) * scaleMix) / scaleY;
    }
    var shearY = bone.lpa_1;
    if (!(shearMix === 0.0)) {
      var r_0 = target.lpa_1 - shearY + $this.ope_1.lpf_1;
      r_0 = r_0 - imul(16384 - numberToInt(16384.499999999996 - r_0 / 360) | 0, 360);
      shearY = shearY + r_0 * shearMix;
    }
    bone.epl(x, y, rotation, scaleX, scaleY, bone.kpa_1, shearY);
    i = i + 1 | 0;
  }
}
function applyRelativeLocal($this) {
  var rotateMix = $this.rpe_1;
  var translateMix = $this.spe_1;
  var scaleMix = $this.tpe_1;
  var shearMix = $this.upe_1;
  var target = $this.qpe_1;
  if (!ensureNotNull(target).mpa_1) {
    target.gpl();
  }
  var bones = $this.ppe_1;
  var i = 0;
  var n = bones.j1();
  while (i < n) {
    var bone = bones.i1(i);
    if (!bone.mpa_1) {
      bone.gpl();
    }
    var rotation = bone.hpa_1;
    if (!(rotateMix === 0.0))
      rotation = rotation + (target.hpa_1 + $this.ope_1.gpf_1) * rotateMix;
    var x = bone.fpa_1;
    var y = bone.gpa_1;
    if (!(translateMix === 0.0)) {
      x = x + (target.fpa_1 + $this.ope_1.hpf_1) * translateMix;
      y = y + (target.gpa_1 + $this.ope_1.ipf_1) * translateMix;
    }
    var scaleX = bone.ipa_1;
    var scaleY = bone.jpa_1;
    if (!(scaleMix === 0.0)) {
      scaleX = scaleX * ((target.ipa_1 - 1 + $this.ope_1.jpf_1) * scaleMix + 1);
      scaleY = scaleY * ((target.jpa_1 - 1 + $this.ope_1.kpf_1) * scaleMix + 1);
    }
    var shearY = bone.lpa_1;
    if (!(shearMix === 0.0))
      shearY = shearY + (target.lpa_1 + $this.ope_1.lpf_1) * shearMix;
    bone.epl(x, y, rotation, scaleX, scaleY, bone.kpa_1, shearY);
    i = i + 1 | 0;
  }
}
function init_com_esotericsoftware_spine_TransformConstraint(_this__u8e3s4) {
  _this__u8e3s4.qpe_1 = null;
  _this__u8e3s4.rpe_1 = 0;
  _this__u8e3s4.spe_1 = 0;
  _this__u8e3s4.tpe_1 = 0;
  _this__u8e3s4.upe_1 = 0;
  _this__u8e3s4.vpe_1 = false;
  _this__u8e3s4.wpe_1 = new SpineVector2();
}
function findRegion($this, path) {
  // Inline function 'kotlin.collections.getOrPut' call
  var this_0 = $this.lps_1;
  var value = this_0.k3(path);
  var tmp;
  if (value == null) {
    // Inline function 'com.esotericsoftware.spine.attachments.AtlasAttachmentLoader.findRegion.<anonymous>' call
    var tmp0_elvis_lhs = $this.kps_1.r4m(path);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var message = "Can't find '" + path + "' in atlas";
      throw IllegalStateException.m5(toString(message));
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var entry = tmp_0;
    var answer = new SpineRegion(entry);
    this_0.r3(path, answer);
    tmp = answer;
  } else {
    tmp = value;
  }
  return tmp;
}
var AttachmentType_region_instance;
var AttachmentType_boundingbox_instance;
var AttachmentType_mesh_instance;
var AttachmentType_linkedmesh_instance;
var AttachmentType_path_instance;
var AttachmentType_point_instance;
var AttachmentType_clipping_instance;
var Companion_instance_19;
function Companion_getInstance_23() {
  AttachmentType_initEntries();
  if (Companion_instance_19 === VOID)
    new Companion_18();
  return Companion_instance_19;
}
function values_4() {
  return [AttachmentType_region_getInstance(), AttachmentType_boundingbox_getInstance(), AttachmentType_mesh_getInstance(), AttachmentType_linkedmesh_getInstance(), AttachmentType_path_getInstance(), AttachmentType_point_getInstance(), AttachmentType_clipping_getInstance()];
}
var AttachmentType_entriesInitialized;
function AttachmentType_initEntries() {
  if (AttachmentType_entriesInitialized)
    return Unit_instance;
  AttachmentType_entriesInitialized = true;
  AttachmentType_region_instance = new AttachmentType('region', 0);
  AttachmentType_boundingbox_instance = new AttachmentType('boundingbox', 1);
  AttachmentType_mesh_instance = new AttachmentType('mesh', 2);
  AttachmentType_linkedmesh_instance = new AttachmentType('linkedmesh', 3);
  AttachmentType_path_instance = new AttachmentType('path', 4);
  AttachmentType_point_instance = new AttachmentType('point', 5);
  AttachmentType_clipping_instance = new AttachmentType('clipping', 6);
  Companion_getInstance_23();
}
function AttachmentType_region_getInstance() {
  AttachmentType_initEntries();
  return AttachmentType_region_instance;
}
function AttachmentType_boundingbox_getInstance() {
  AttachmentType_initEntries();
  return AttachmentType_boundingbox_instance;
}
function AttachmentType_mesh_getInstance() {
  AttachmentType_initEntries();
  return AttachmentType_mesh_instance;
}
function AttachmentType_linkedmesh_getInstance() {
  AttachmentType_initEntries();
  return AttachmentType_linkedmesh_instance;
}
function AttachmentType_path_getInstance() {
  AttachmentType_initEntries();
  return AttachmentType_path_instance;
}
function AttachmentType_point_getInstance() {
  AttachmentType_initEntries();
  return AttachmentType_point_instance;
}
function AttachmentType_clipping_getInstance() {
  AttachmentType_initEntries();
  return AttachmentType_clipping_instance;
}
var Companion_instance_20;
function Companion_getInstance_24() {
  return Companion_instance_20;
}
var nextID;
function nextID_0($this) {
  var _unary__edvuaz = nextID;
  nextID = _unary__edvuaz + 1 | 0;
  return _unary__edvuaz;
}
var Companion_instance_21;
function Companion_getInstance_25() {
  return Companion_instance_21;
}
function renderSkeleton($this, ctx, skeleton, bb) {
  var tempPosition = $this.jpv_1;
  var tempUV = $this.kpv_1;
  var tempLight1 = $this.lpv_1;
  var tempDark1 = $this.mpv_1;
  var tempLight2 = $this.npv_1;
  var tempDark2 = $this.opv_1;
  var vertexEffect = $this.ipv_1;
  if (vertexEffect == null)
    null;
  else {
    vertexEffect.rpv(skeleton);
  }
  var premultipliedAlpha = $this.fpv_1;
  var blendMode = null;
  var verticesLength = 0;
  var vertices = null;
  var uvs = null;
  var triangles = null;
  var color = null;
  var skeletonColor = skeleton.np7_1;
  var r = _RGBAf___get_r__impl__jfq8gq(skeletonColor);
  var g = _RGBAf___get_g__impl__dhoraz(skeletonColor);
  var b = _RGBAf___get_b__impl__fjl0nq(skeletonColor);
  var a = _RGBAf___get_a__impl__qnwk97(skeletonColor);
  var drawOrder = skeleton.hp7_1;
  var i = 0;
  var n = drawOrder.j1();
  $l$loop_0: while (i < n) {
    var slot = drawOrder.i1(i);
    if (!slot.vp7_1.upa_1) {
      $this.hpv_1.apw(slot);
      i = i + 1 | 0;
      continue $l$loop_0;
    }
    var texture = null;
    var vertexSize = $this.hpv_1.bpw() ? 2 : 5;
    var attachment = slot.yp7_1;
    if (attachment instanceof RegionAttachment) {
      verticesLength = vertexSize << 2;
      vertices = $this.gpv_1.r30_1;
      var tmp;
      if (vertices == null) {
        throwUninitializedPropertyAccessException('vertices');
      } else {
        tmp = vertices;
      }
      attachment.apt(slot.vp7_1, tmp, 0, vertexSize);
      triangles = Companion_getInstance_26().fpw_1;
      texture = attachment.zps().xpr_1;
      uvs = attachment.upo_1;
      color = attachment.wpo_1;
    } else {
      if (attachment instanceof MeshAttachment) {
        var count = attachment.xpc_1;
        verticesLength = imul(count >> 1, vertexSize);
        vertices = setSize_0($this.gpv_1, verticesLength);
        var tmp_0;
        if (vertices == null) {
          throwUninitializedPropertyAccessException('vertices');
        } else {
          tmp_0 = vertices;
        }
        attachment.xpm(slot, 0, count, tmp_0, 0, vertexSize);
        triangles = attachment.qps();
        texture = ensureNotNull(attachment.ppp_1).xpr_1;
        uvs = attachment.spp_1;
        color = attachment.upp_1;
      } else {
        if (attachment instanceof ClippingAttachment) {
          $this.hpv_1.epw(slot, attachment);
          i = i + 1 | 0;
          continue $l$loop_0;
        } else {
          if (attachment instanceof SkeletonAttachment) {
            var attachmentSkeleton = attachment.dpw_1;
            if (!(attachmentSkeleton == null)) {
              renderSkeleton($this, ctx, attachmentSkeleton, bb);
            }
          }
        }
      }
    }
    if (!(texture == null)) {
      var slotColor = slot.wp7_1;
      var tmp_1 = a * _RGBAf___get_a__impl__qnwk97(slotColor);
      var tmp_2 = color;
      var alpha = tmp_1 * _RGBAf___get_a__impl__qnwk97(ensureNotNull(tmp_2 == null ? null : new RGBAf(tmp_2)).b5k_1) * 255.0;
      var multiplier = premultipliedAlpha ? alpha : 255.0;
      var slotBlendMode = slot.up7_1.spr();
      if (!slotBlendMode.equals(blendMode)) {
        if (slotBlendMode.equals(BlendMode_additive_getInstance()) && premultipliedAlpha) {
          slotBlendMode = BlendMode_normal_getInstance();
          alpha = 0.0;
        }
        blendMode = slotBlendMode;
        $this.ppv_1 = blendMode;
      }
      var c = Companion_getInstance_0().a57(numberToInt(r * _RGBAf___get_r__impl__jfq8gq(slotColor) * _RGBAf___get_r__impl__jfq8gq(color) * multiplier), numberToInt(g * _RGBAf___get_g__impl__dhoraz(slotColor) * _RGBAf___get_g__impl__dhoraz(color) * multiplier), numberToInt(b * _RGBAf___get_b__impl__fjl0nq(slotColor) * _RGBAf___get_b__impl__fjl0nq(color) * multiplier), numberToInt(alpha));
      if ($this.hpv_1.bpw()) {
        var tmp_3;
        if (vertices == null) {
          throwUninitializedPropertyAccessException('vertices');
        } else {
          tmp_3 = vertices;
        }
        var tmp_4 = tmp_3;
        var tmp_5 = verticesLength;
        var tmp_6;
        if (triangles == null) {
          throwUninitializedPropertyAccessException('triangles');
        } else {
          tmp_6 = triangles;
        }
        var tmp_7 = tmp_6;
        var tmp_8;
        if (triangles == null) {
          throwUninitializedPropertyAccessException('triangles');
        } else {
          tmp_8 = triangles;
        }
        $this.hpv_1.hpw(tmp_4, tmp_5, tmp_7, tmp_8.length, ensureNotNull(uvs), c, Colors_getInstance().h4r_1, false);
        var clippedVertices = $this.hpv_1.vpv_1;
        var clippedTriangles = $this.hpv_1.wpv_1;
        if (!(vertexEffect == null)) {
          applyVertexEffect($this, clippedVertices.r30_1, clippedVertices.j1(), 5, c, Colors_getInstance().h4r_1);
        }
        draw($this, bb, ctx, texture, clippedVertices.r30_1, 0, clippedVertices.j1(), toArray_1(clippedTriangles), 0, clippedTriangles.j1(), $this.ppv_1);
      } else {
        if (!(vertexEffect == null)) {
          RGBAf__setTo_impl_x4m1oi_1(tempLight1, c);
          RGBAf__setTo_impl_x4m1oi_1(tempDark1, Colors_getInstance().h4r_1);
          var v = 0;
          var u = 0;
          while (v < verticesLength) {
            var tmp_9 = tempPosition;
            var tmp_10;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_10 = vertices;
            }
            tmp_9.ipl_1 = ensureNotNull(tmp_10)[v];
            var tmp_11 = tempPosition;
            var tmp_12;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_12 = vertices;
            }
            tmp_11.jpl_1 = tmp_12[v + 1 | 0];
            RGBAf__setTo_impl_x4m1oi(tempLight2, tempLight1);
            RGBAf__setTo_impl_x4m1oi(tempDark2, tempDark1);
            tempUV.ipl_1 = ensureNotNull(uvs)[u];
            tempUV.jpl_1 = uvs[u + 1 | 0];
            vertexEffect.gpw(tempPosition, tempUV, tempLight2, tempDark2);
            var tmp_13;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_13 = vertices;
            }
            tmp_13[v + 0 | 0] = tempPosition.ipl_1;
            var tmp_14;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_14 = vertices;
            }
            tmp_14[v + 1 | 0] = tempPosition.jpl_1;
            var tmp_15;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_15 = vertices;
            }
            tmp_15[v + 2 | 0] = toFloatBits(tempLight2, $this);
            var tmp_16;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_16 = vertices;
            }
            tmp_16[v + 3 | 0] = tempUV.ipl_1;
            var tmp_17;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_17 = vertices;
            }
            tmp_17[v + 4 | 0] = tempUV.jpl_1;
            v = v + 5 | 0;
            u = u + 2 | 0;
          }
        } else {
          var v_0 = 0;
          var u_0 = 0;
          while (v_0 < verticesLength) {
            var tmp_18;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_18 = vertices;
            }
            var tmp_19 = tmp_18;
            var tmp_20 = v_0 + 2 | 0;
            // Inline function 'kotlin.fromBits' call
            var bits = _RGBA___get_value__impl__hhco8v(c);
            tmp_19[tmp_20] = floatFromBits(bits);
            var tmp_21;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_21 = vertices;
            }
            tmp_21[v_0 + 3 | 0] = ensureNotNull(uvs)[u_0];
            var tmp_22;
            if (vertices == null) {
              throwUninitializedPropertyAccessException('vertices');
            } else {
              tmp_22 = vertices;
            }
            tmp_22[v_0 + 4 | 0] = uvs[u_0 + 1 | 0];
            v_0 = v_0 + 5 | 0;
            u_0 = u_0 + 2 | 0;
          }
        }
        var tmp_23 = texture;
        var tmp_24;
        if (vertices == null) {
          throwUninitializedPropertyAccessException('vertices');
        } else {
          tmp_24 = vertices;
        }
        var tmp_25 = tmp_24;
        var tmp_26 = verticesLength;
        var tmp_27;
        if (triangles == null) {
          throwUninitializedPropertyAccessException('triangles');
        } else {
          tmp_27 = triangles;
        }
        var tmp_28 = tmp_27;
        var tmp_29;
        if (triangles == null) {
          throwUninitializedPropertyAccessException('triangles');
        } else {
          tmp_29 = triangles;
        }
        draw($this, bb, ctx, tmp_23, tmp_25, 0, tmp_26, tmp_28, 0, tmp_29.length, $this.ppv_1);
      }
    }
    $this.hpv_1.apw(slot);
    i = i + 1 | 0;
  }
  $this.hpv_1.ipw();
  if (vertexEffect == null)
    null;
  else {
    vertexEffect.v6y();
  }
}
function applyVertexEffect($this, vertices, verticesLength, stride, light, dark) {
  var tempPosition = $this.jpv_1;
  var tempUV = $this.kpv_1;
  var tempLight1 = $this.lpv_1;
  var tempDark1 = $this.mpv_1;
  var tempLight2 = $this.npv_1;
  var tempDark2 = $this.opv_1;
  var vertexEffect = $this.ipv_1;
  RGBAf__setTo_impl_x4m1oi_1(tempLight1, light);
  RGBAf__setTo_impl_x4m1oi_1(tempDark1, dark);
  if (stride === 5) {
    var v = 0;
    while (v < verticesLength) {
      tempPosition.ipl_1 = vertices[v];
      tempPosition.jpl_1 = vertices[v + 1 | 0];
      tempUV.ipl_1 = vertices[v + 3 | 0];
      tempUV.jpl_1 = vertices[v + 4 | 0];
      RGBAf__setTo_impl_x4m1oi(tempLight2, tempLight1);
      RGBAf__setTo_impl_x4m1oi(tempDark2, tempDark1);
      ensureNotNull(vertexEffect).gpw(tempPosition, tempUV, tempLight2, tempDark2);
      vertices[v] = tempPosition.ipl_1;
      vertices[v + 1 | 0] = tempPosition.jpl_1;
      vertices[v + 2 | 0] = toFloatBits(tempLight2, $this);
      vertices[v + 3 | 0] = tempUV.ipl_1;
      vertices[v + 4 | 0] = tempUV.jpl_1;
      v = v + stride | 0;
    }
  } else {
    var v_0 = 0;
    while (v_0 < verticesLength) {
      tempPosition.ipl_1 = vertices[v_0];
      tempPosition.jpl_1 = vertices[v_0 + 1 | 0];
      tempUV.ipl_1 = vertices[v_0 + 4 | 0];
      tempUV.jpl_1 = vertices[v_0 + 5 | 0];
      RGBAf__setTo_impl_x4m1oi(tempLight2, tempLight1);
      RGBAf__setTo_impl_x4m1oi(tempDark2, tempDark1);
      ensureNotNull(vertexEffect).gpw(tempPosition, tempUV, tempLight2, tempDark2);
      vertices[v_0] = tempPosition.ipl_1;
      vertices[v_0 + 1 | 0] = tempPosition.jpl_1;
      vertices[v_0 + 2 | 0] = toFloatBits(tempLight2, $this);
      vertices[v_0 + 3 | 0] = toFloatBits(tempDark2, $this);
      vertices[v_0 + 4 | 0] = tempUV.ipl_1;
      vertices[v_0 + 5 | 0] = tempUV.jpl_1;
      v_0 = v_0 + stride | 0;
    }
  }
}
function draw($this, bb, ctx, texture, verticesData, verticesOffset, verticesCount, triangle, trianglesOffset, trianglesCount, blendMode) {
  var vertexSize = 5;
  var vertexCount = verticesCount / vertexSize | 0;
  if (!(bb == null)) {
    var inductionVariable = 0;
    if (inductionVariable < vertexCount)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var index = imul(n, vertexSize);
        var x = verticesData[index + 0 | 0];
        var y = verticesData[index + 1 | 0];
        bb.j4i(x, -y);
      }
       while (inductionVariable < vertexCount);
  }
  if (ctx == null)
    null;
  else {
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    var tmp;
    switch (blendMode == null ? -1 : blendMode.v3_1) {
      case 0:
        tmp = Companion_getInstance_2().ra5_1;
        break;
      case 1:
        tmp = Companion_getInstance_2().ua5_1;
        break;
      case 2:
        tmp = Companion_getInstance_2().va5_1;
        break;
      case 3:
        tmp = Companion_getInstance_2().wa5_1;
        break;
      case -1:
        tmp = $this.bba_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    var viewBlendMode = tmp;
    this_0.iaf(texture, true, viewBlendMode, null, trianglesCount, vertexCount);
    var transform = $this.ybp();
    var premultiplied = texture.v4q();
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < trianglesCount)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        // Inline function 'korlibs.korge.render.BatchBuilder2D.addIndexRelative' call
        var idx = triangle[trianglesOffset + n_0 | 0];
        var _unary__edvuaz = this_0.ha8_1;
        this_0.ha8_1 = _unary__edvuaz + 1 | 0;
        Uint16Buffer__set_impl_g0kliu(this_0.ea8_1, _unary__edvuaz, this_0.kae() + idx | 0);
      }
       while (inductionVariable_0 < trianglesCount);
    var colorMul = $this.wcc();
    var inductionVariable_1 = 0;
    var tmp_0;
    if (inductionVariable_1 < vertexCount) {
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var x_0 = verticesData[(verticesOffset + imul(n_1, vertexSize) | 0) + 0 | 0];
        var y_0 = -verticesData[(verticesOffset + imul(n_1, vertexSize) | 0) + 1 | 0];
        var u = verticesData[(verticesOffset + imul(n_1, vertexSize) | 0) + 3 | 0];
        var v = verticesData[(verticesOffset + imul(n_1, vertexSize) | 0) + 4 | 0];
        var realX = transform.e2n(x_0, y_0);
        var realY = transform.f2n(x_0, y_0);
        this_0.pae(realX, realY, u, v, colorMul);
      }
       while (inductionVariable_1 < vertexCount);
      tmp_0 = Unit_instance;
    }
  }
}
function toFloatBits(_this__u8e3s4, $this) {
  // Inline function 'kotlin.fromBits' call
  var bits = _RGBA___get_value__impl__hhco8v(_RGBAf___get_rgba__impl__ui2t32(_this__u8e3s4));
  return floatFromBits(bits);
}
var Companion_instance_22;
function Companion_getInstance_26() {
  if (Companion_instance_22 === VOID)
    new Companion_21();
  return Companion_instance_22;
}
function SkeletonView$lambda($this$addUpdater, delta) {
  $this$addUpdater.lh9(delta.hk_1);
  return Unit_instance;
}
var Companion_instance_23;
function Companion_getInstance_27() {
  return Companion_instance_23;
}
function setSize(_this__u8e3s4, newSize) {
  // Inline function 'kotlin.math.max' call
  var tmp$ret$0 = Math.max(8, newSize);
  truncate(_this__u8e3s4, tmp$ret$0);
  return _this__u8e3s4;
}
function setAndGrow(_this__u8e3s4, index, value) {
  if (index >= _this__u8e3s4.j1()) {
    var items = isInterface(_this__u8e3s4, KtMutableList) ? _this__u8e3s4 : THROW_CCE();
    while (items.j1() <= index) {
      items.f(null);
    }
  }
  _this__u8e3s4.p3(index, value);
}
function shrink(_this__u8e3s4) {
  if (!(_this__u8e3s4.j1() === _this__u8e3s4.j1())) {
    resize(_this__u8e3s4, _this__u8e3s4.j1());
  }
}
function toArray(_this__u8e3s4) {
  return _this__u8e3s4.c31();
}
function toArray_0(_this__u8e3s4) {
  return _this__u8e3s4.z31();
}
function containsIdentity(_this__u8e3s4, value) {
  return indexOfIdentity(_this__u8e3s4, value) >= 0;
}
function setSize_0(_this__u8e3s4, size) {
  _this__u8e3s4.nv(size);
  return _this__u8e3s4.r30_1;
}
function setSize_1(_this__u8e3s4, size) {
  _this__u8e3s4.nv(size);
  return _this__u8e3s4.d31_1;
}
function toArray_1(_this__u8e3s4) {
  var tmp = 0;
  var tmp_0 = _this__u8e3s4.j1();
  var tmp_1 = new Int16Array(tmp_0);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = _this__u8e3s4.i1(tmp_2);
    tmp = tmp + 1 | 0;
  }
  return tmp_1;
}
function indexOfIdentity(_this__u8e3s4, value) {
  // Inline function 'korlibs.datastructure.FastArrayList.fastForEachWithIndex' call
  // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var array = _this__u8e3s4.e14_1;
  var n = 0;
  while (n < array.length) {
    // Inline function 'com.esotericsoftware.spine.utils.indexOfIdentity.<anonymous>' call
    var index = n;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'korlibs.datastructure.get' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    if (array[n] === value)
      return index;
    n = n + 1 | 0;
  }
  return -1;
}
function truncate(_this__u8e3s4, newSize) {
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(newSize >= 0)) {
    // Inline function 'com.esotericsoftware.spine.utils.truncate.<anonymous>' call
    var message = 'newSize must be >= 0: ' + newSize;
    throw IllegalArgumentException.l(toString(message));
  }
  while (_this__u8e3s4.j1() > newSize) {
    _this__u8e3s4.q3(_this__u8e3s4.j1() - 1 | 0);
  }
}
function resize(_this__u8e3s4, newSize) {
  truncate(_this__u8e3s4, newSize);
}
function setSize_2(_this__u8e3s4, size) {
  _this__u8e3s4.nv(size);
  return _this__u8e3s4;
}
function setSize_3(_this__u8e3s4, size) {
  _this__u8e3s4.nv(size);
}
function isConcave($this, index, vertexCount, vertices, indices) {
  var previous = indices.i1(((vertexCount + index | 0) - 1 | 0) % vertexCount | 0) << 1;
  var current = indices.i1(index) << 1;
  var next = indices.i1((index + 1 | 0) % vertexCount | 0) << 1;
  return !positiveArea($this, vertices[previous], vertices[previous + 1 | 0], vertices[current], vertices[current + 1 | 0], vertices[next], vertices[next + 1 | 0]);
}
function positiveArea($this, p1x, p1y, p2x, p2y, p3x, p3y) {
  return p1x * (p3y - p2y) + p2x * (p1y - p3y) + p3x * (p2y - p1y) >= 0;
}
function winding($this, p1x, p1y, p2x, p2y, p3x, p3y) {
  var px = p2x - p1x;
  var py = p2y - p1y;
  return p3x * py - p3y * px + px * p1y - p1x * py >= 0 ? 1 : -1;
}
function SpineTriangulator$polygonPool$lambda(it) {
  return FloatArrayList.t30(16);
}
function SpineTriangulator$polygonIndicesPool$lambda(it) {
  return new ShortArrayList(16);
}
var SpineUtils_instance;
function SpineUtils_getInstance() {
  return SpineUtils_instance;
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForCompanion(Companion_0);
initMetadataForCompanion(Companion_1);
initMetadataForCompanion(Companion_2);
initMetadataForCompanion(Companion_3);
initMetadataForCompanion(Companion_4);
initMetadataForCompanion(Companion_5);
initMetadataForCompanion(Companion_6);
initMetadataForCompanion(Companion_7);
initMetadataForInterface(Timeline, 'Timeline');
initMetadataForClass(MixBlend, 'MixBlend');
initMetadataForClass(MixDirection, 'MixDirection');
initMetadataForClass(TimelineType, 'TimelineType');
initMetadataForClass(CurveTimeline, 'CurveTimeline', VOID, VOID, [Timeline]);
initMetadataForClass(RotateTimeline, 'RotateTimeline', VOID, VOID, [CurveTimeline, Timeline]);
initMetadataForClass(TranslateTimeline, 'TranslateTimeline', VOID, VOID, [CurveTimeline, Timeline]);
initMetadataForClass(ScaleTimeline, 'ScaleTimeline');
initMetadataForClass(ShearTimeline, 'ShearTimeline');
initMetadataForClass(ColorTimeline, 'ColorTimeline', VOID, VOID, [CurveTimeline, Timeline]);
initMetadataForClass(TwoColorTimeline, 'TwoColorTimeline', VOID, VOID, [CurveTimeline, Timeline]);
initMetadataForClass(AttachmentTimeline, 'AttachmentTimeline', VOID, VOID, [Timeline]);
initMetadataForClass(DeformTimeline, 'DeformTimeline', VOID, VOID, [CurveTimeline, Timeline]);
initMetadataForClass(EventTimeline, 'EventTimeline', VOID, VOID, [Timeline]);
initMetadataForClass(DrawOrderTimeline, 'DrawOrderTimeline', VOID, VOID, [Timeline]);
initMetadataForClass(IkConstraintTimeline, 'IkConstraintTimeline');
initMetadataForClass(TransformConstraintTimeline, 'TransformConstraintTimeline');
initMetadataForClass(PathConstraintPositionTimeline, 'PathConstraintPositionTimeline');
initMetadataForClass(PathConstraintSpacingTimeline, 'PathConstraintSpacingTimeline');
initMetadataForClass(PathConstraintMixTimeline, 'PathConstraintMixTimeline');
initMetadataForCompanion(Companion_8);
initMetadataForClass(Animation, 'Animation');
initMetadataForClass(TrackEntry, 'TrackEntry', TrackEntry);
initMetadataForClass(EventQueue, 'EventQueue');
initMetadataForClass(EventType, 'EventType');
initMetadataForCompanion(Companion_9);
initMetadataForClass(AnimationState, 'AnimationState', AnimationState.spj);
initMetadataForClass(FloatHolder, 'FloatHolder');
initMetadataForClass(Key, 'Key', Key);
initMetadataForClass(AnimationStateData, 'AnimationStateData');
initMetadataForCompanion(Companion_10);
initMetadataForClass(BlendMode, 'BlendMode');
initMetadataForClass(Bone, 'Bone');
initMetadataForCompanion(Companion_11);
initMetadataForClass(TransformMode, 'TransformMode');
initMetadataForClass(BoneData, 'BoneData');
initMetadataForClass(ConstraintData, 'ConstraintData');
initMetadataForClass(Event, 'Event');
initMetadataForClass(EventData, 'EventData');
initMetadataForCompanion(Companion_12);
initMetadataForClass(IkConstraint, 'IkConstraint');
initMetadataForClass(IkConstraintData, 'IkConstraintData');
initMetadataForCompanion(Companion_13);
initMetadataForClass(PathConstraint, 'PathConstraint');
initMetadataForCompanion(Companion_14);
initMetadataForCompanion(Companion_15);
initMetadataForCompanion(Companion_16);
initMetadataForClass(PositionMode, 'PositionMode');
initMetadataForClass(SpacingMode, 'SpacingMode');
initMetadataForClass(RotateMode, 'RotateMode');
initMetadataForClass(PathConstraintData, 'PathConstraintData');
initMetadataForClass(Skeleton, 'Skeleton');
initMetadataForClass(Vertices, 'Vertices', Vertices);
initMetadataForClass(SkeletonInput, 'SkeletonInput');
initMetadataForCompanion(Companion_17);
initMetadataForClass(SkeletonBinary, 'SkeletonBinary', VOID, VOID, VOID, [1]);
initMetadataForClass(SkeletonData, 'SkeletonData', SkeletonData);
initMetadataForClass(LinkedMesh, 'LinkedMesh');
initMetadataForClass(SkinEntry, 'SkinEntry');
initMetadataForClass(Skin, 'Skin');
initMetadataForClass(Slot, 'Slot');
initMetadataForClass(SlotData, 'SlotData');
initMetadataForClass(SpineRegion, 'SpineRegion');
initMetadataForClass(TransformConstraint, 'TransformConstraint');
initMetadataForClass(TransformConstraintData, 'TransformConstraintData');
initMetadataForInterface(AttachmentLoader, 'AttachmentLoader');
initMetadataForClass(AtlasAttachmentLoader, 'AtlasAttachmentLoader', VOID, VOID, [AttachmentLoader]);
initMetadataForClass(Attachment, 'Attachment');
initMetadataForCompanion(Companion_18);
initMetadataForClass(AttachmentType, 'AttachmentType');
initMetadataForClass(VertexAttachment, 'VertexAttachment');
initMetadataForClass(BoundingBoxAttachment, 'BoundingBoxAttachment');
initMetadataForClass(ClippingAttachment, 'ClippingAttachment');
initMetadataForClass(MeshAttachment, 'MeshAttachment');
initMetadataForClass(PathAttachment, 'PathAttachment');
initMetadataForClass(PointAttachment, 'PointAttachment');
initMetadataForCompanion(Companion_19);
initMetadataForClass(RegionAttachment, 'RegionAttachment');
initMetadataForClass(SkeletonAttachment, 'SkeletonAttachment');
initMetadataForCompanion(Companion_20);
initMetadataForCompanion(Companion_21);
initMetadataForClass(SkeletonView, 'SkeletonView');
initMetadataForCompanion(Companion_22);
initMetadataForClass(SkeletonClipping, 'SkeletonClipping', SkeletonClipping);
initMetadataForClass(SpineTriangulator, 'SpineTriangulator', SpineTriangulator);
initMetadataForObject(SpineUtils, 'SpineUtils');
initMetadataForClass(SpineVector2, 'SpineVector2', SpineVector2);
//endregion
//region block: init
Companion_instance_0 = new Companion();
Companion_instance_1 = new Companion_0();
Companion_instance_2 = new Companion_1();
Companion_instance_3 = new Companion_2();
Companion_instance_4 = new Companion_3();
Companion_instance_5 = new Companion_4();
Companion_instance_6 = new Companion_5();
Companion_instance_7 = new Companion_6();
Companion_instance_8 = new Companion_7();
Companion_instance_9 = new Companion_8();
Companion_instance_13 = new Companion_12();
Companion_instance_14 = new Companion_13();
Companion_instance_18 = new Companion_17();
Companion_instance_20 = new Companion_19();
nextID = 0;
Companion_instance_21 = new Companion_20();
Companion_instance_23 = new Companion_22();
SpineUtils_instance = new SpineUtils();
//endregion
//region block: exports
export {
  SkeletonView as SkeletonView14qrut8pgmv4h,
  AnimationStateData as AnimationStateDatar5ny3zfqlh85,
  AnimationState as AnimationState2vlachi2cbl4b,
  Skeleton as Skeleton1jajq7hvqgg3p,
  readSkeletonBinary as readSkeletonBinary3bx8lidxm67wk,
};
//endregion
