import {
  VOID7hggqo3abtya as VOID,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  indexOf1xbs558u7wr52 as indexOf,
  toInt2q8uldh7sc951 as toInt,
  contains3ue2qo8xhmpf1 as contains,
  abs1kdzbjes1idip as abs,
  repeat2w4c6j8zoq09o as repeat,
  indexOfwa4w6635jewi as indexOf_0,
  replace3le3ie7l9k8aq as replace,
  StringBuildermazzzhj6kkai as StringBuilder,
  until1jbpn0z3f8lbg as until,
  Collection1k04j3hzsbod0 as Collection,
  isInterface3d6p8outrmvmk as isInterface,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  Unit_instance104q5opgivhr8 as Unit_instance,
  charArrayOf27f4r3dozbrk1 as charArrayOf,
  trimEndvvzjdhan75g as trimEnd,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  isNaNymqb93xtq8w8 as isNaN_0,
  isInfinite12nl8hpz1hbp2 as isInfinite,
  round2mrvepag8eey0 as round,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  Char__minus_impl_a2frrhp8twy13wqz9c as Char__minus_impl_a2frrh,
  Char__compareTo_impl_ypi4mb25zzl63tc6wpg as Char__compareTo_impl_ypi4mb,
  toLongw1zpgk99d84b as toLong,
  Long2qws0ah9gnpki as Long,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  toShort36kaw0zjdq3ex as toShort,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  toString1h6jjoch8cjt8 as toString,
  numberToLong1a4cndvg6c52s as numberToLong,
  protoOf180f3jzyo7rfj as protoOf,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  compareTo3ankvs086tmwq as compareTo,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  equals2au1ep9vhcato as equals,
  Comparable198qfk8pnblz0 as Comparable,
  countTrailingZeroBitszhs0313cn11e as countTrailingZeroBits,
  coerceInlzh524ulyz3c as coerceIn,
  toByte4i43936u611k as toByte,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
var imul = Math.imul;
var clz32 = Math.clz32;
var log2 = Math.log2;
//endregion
//region block: pre-declaration
class NumberParser {
  constructor() {
    NumberParser_instance = this;
    this.tz_1 = _Char___init__impl__6a9atx(0);
  }
}
class IsAlmostEquals {}
function isAlmostEquals$default(other, epsilon, $super) {
  epsilon = epsilon === VOID ? 1.0E-6 : epsilon;
  return $super === VOID ? this.uz(other, epsilon) : $super.uz.call(this, other, epsilon);
}
class Easing {}
function invoke(it) {
  return this.xz(it);
}
function invoke_0(it) {
  return _Ratio___init__impl__9mwvn2(this.xz(Ratio__toFloat_impl_1ftup5(it)));
}
class sam$korlibs_math_interpolation_Easing$0 {
  constructor(function_0) {
    this.wz_1 = function_0;
  }
  xz(it) {
    return this.wz_1(it);
  }
}
class sam$korlibs_math_interpolation_Easing$0_0 {
  constructor(function_0) {
    this.a10_1 = function_0;
  }
  xz(it) {
    return this.a10_1(it);
  }
}
class Companion {
  constructor() {
    Companion_instance = this;
    var tmp = this;
    var tmp_0 = Easing$Companion$LINEAR$lambda;
    tmp.b10_1 = new sam$korlibs_math_interpolation_Easing$0(tmp_0);
    var tmp_1 = this;
    var tmp_2 = Easing$Companion$SMOOTH$lambda;
    tmp_1.c10_1 = new sam$korlibs_math_interpolation_Easing$0_0(tmp_2);
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    this.f10_1 = _Ratio___init__impl__9mwvn2(0.0);
    this.g10_1 = _Ratio___init__impl__9mwvn2(0.25);
    this.h10_1 = _Ratio___init__impl__9mwvn2(0.5);
    this.i10_1 = _Ratio___init__impl__9mwvn2(1.0);
    this.j10_1 = _Ratio___init__impl__9mwvn2_0(NaN);
  }
}
class Ratio {
  constructor(value) {
    Companion_getInstance_0();
    this.e10_1 = value;
  }
  k10(other) {
    return Ratio__compareTo_impl_z2ienc(this.e10_1, other);
  }
  d(other) {
    return Ratio__compareTo_impl_z2ienc_0(this, other);
  }
  toString() {
    return Ratio__toString_impl_mfgt3e(this.e10_1);
  }
  hashCode() {
    return Ratio__hashCode_impl_quhc2v(this.e10_1);
  }
  equals(other) {
    return Ratio__equals_impl_mfc2gt(this.e10_1, other);
  }
}
class Companion_1 {
  l10(offset, size) {
    return _IntMaskRange___init__impl__8vv60x(insert8(insert8(0, offset, 0), size, 8));
  }
  m10(mask) {
    if (mask === 0)
      return _IntMaskRange___init__impl__8vv60x(0);
    var offset = countTrailingZeroBits(mask);
    // Inline function 'kotlin.countLeadingZeroBits' call
    var size = (32 - clz32(mask) | 0) - offset | 0;
    return this.l10(offset, size);
  }
}
//endregion
function get_MINUS_ZERO_D() {
  _init_properties_NumberExt_kt__gxb31i();
  return MINUS_ZERO_D;
}
var MINUS_ZERO_D;
function toStringDecimal(_this__u8e3s4, decimalPlaces, skipTrailingZeros) {
  skipTrailingZeros = skipTrailingZeros === VOID ? false : skipTrailingZeros;
  _init_properties_NumberExt_kt__gxb31i();
  if (isNanOrInfinite(_this__u8e3s4))
    return _this__u8e3s4.toString();
  var res = normalizeZero(roundDecimalPlaces(_this__u8e3s4, decimalPlaces)).toString();
  var eup = indexOf(res, _Char___init__impl__6a9atx(69));
  var elo = indexOf(res, _Char___init__impl__6a9atx(101));
  var eIndex = eup >= 0 ? eup : elo;
  var tmp;
  if (eIndex >= 0) {
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    var base = res.substring(0, eIndex);
    // Inline function 'kotlin.text.substring' call
    var startIndex = eIndex + 1 | 0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$3 = res.substring(startIndex);
    var exp = toInt(tmp$ret$3);
    var rbase = contains(base, '.') ? base : base + '.0';
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp$ret$4 = abs(exp);
    var zeros = repeat('0', tmp$ret$4 + 2 | 0);
    var part = exp > 0 ? rbase + zeros : zeros + rbase;
    var pointIndex2 = indexOf_0(part, '.');
    var pointIndex = pointIndex2 < 0 ? part.length : pointIndex2;
    var outIndex = pointIndex + exp | 0;
    var part2 = replace(part, '.', '');
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>' call
    var tmp$ret$5;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_1 = until(0, outIndex);
      var tmp_0;
      if (isInterface(this_1, Collection)) {
        tmp_0 = this_1.a1();
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp$ret$5 = true;
        break $l$block_0;
      }
      var tmp0_iterator = this_1.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>.<anonymous>' call
        if (!(charSequenceGet(part2, element) === _Char___init__impl__6a9atx(48))) {
          tmp$ret$5 = false;
          break $l$block_0;
        }
      }
      tmp$ret$5 = true;
    }
    if (tmp$ret$5) {
      this_0.jc(_Char___init__impl__6a9atx(48));
    } else {
      this_0.lh(part2, 0, outIndex);
    }
    this_0.jc(_Char___init__impl__6a9atx(46));
    this_0.lh(part2, outIndex, part2.length);
    tmp = this_0.toString();
  } else {
    tmp = res;
  }
  var rez = tmp;
  var pointIndex_0 = indexOf(rez, _Char___init__impl__6a9atx(46));
  var tmp_1;
  if (pointIndex_0 >= 0) {
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp_1 = rez.substring(0, pointIndex_0);
  } else {
    tmp_1 = rez;
  }
  var integral = tmp_1;
  if (decimalPlaces === 0)
    return integral;
  var tmp_2;
  if (pointIndex_0 >= 0) {
    // Inline function 'kotlin.text.substring' call
    var startIndex_0 = pointIndex_0 + 1 | 0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$12 = rez.substring(startIndex_0);
    tmp_2 = trimEnd(tmp$ret$12, charArrayOf([_Char___init__impl__6a9atx(48)]));
  } else {
    tmp_2 = '';
  }
  var decimal = tmp_2;
  // Inline function 'kotlin.text.buildString' call
  var capacity = (2 + integral.length | 0) + decimalPlaces | 0;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_2 = StringBuilder.nc(capacity);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>' call
  this_2.ic(integral);
  var tmp_3;
  // Inline function 'kotlin.text.isNotEmpty' call
  if (charSequenceLength(decimal) > 0) {
    tmp_3 = true;
  } else {
    tmp_3 = !skipTrailingZeros;
  }
  if (tmp_3) {
    // Inline function 'kotlin.math.min' call
    var a = decimal.length;
    var decimalCount = Math.min(a, decimalPlaces);
    var tmp$ret$15;
    $l$block_2: {
      // Inline function 'kotlin.collections.all' call
      var this_3 = until(0, decimalCount);
      var tmp_4;
      if (isInterface(this_3, Collection)) {
        tmp_4 = this_3.a1();
      } else {
        tmp_4 = false;
      }
      if (tmp_4) {
        tmp$ret$15 = true;
        break $l$block_2;
      }
      var tmp0_iterator_0 = this_3.f1();
      while (tmp0_iterator_0.g1()) {
        var element_0 = tmp0_iterator_0.h1();
        // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>.<anonymous>' call
        if (!(charSequenceGet(decimal, element_0) === _Char___init__impl__6a9atx(48))) {
          tmp$ret$15 = false;
          break $l$block_2;
        }
      }
      tmp$ret$15 = true;
    }
    var allZeros = tmp$ret$15;
    if (!skipTrailingZeros || !allZeros) {
      this_2.jc(_Char___init__impl__6a9atx(46));
      this_2.lh(decimal, 0, decimalCount);
      if (!skipTrailingZeros) {
        // Inline function 'kotlin.repeat' call
        var times = decimalPlaces - decimalCount | 0;
        // Inline function 'kotlin.contracts.contract' call
        var inductionVariable = 0;
        if (inductionVariable < times)
          do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>.<anonymous>' call
            this_2.jc(_Char___init__impl__6a9atx(48));
          }
           while (inductionVariable < times);
      }
    }
  }
  return this_2.toString();
}
function isNanOrInfinite(_this__u8e3s4) {
  _init_properties_NumberExt_kt__gxb31i();
  return isNaN_0(_this__u8e3s4) || isInfinite(_this__u8e3s4);
}
function normalizeZero(_this__u8e3s4) {
  _init_properties_NumberExt_kt__gxb31i();
  return _this__u8e3s4 === get_MINUS_ZERO_D() ? 0.0 : _this__u8e3s4;
}
function roundDecimalPlaces(_this__u8e3s4, places) {
  _init_properties_NumberExt_kt__gxb31i();
  if (places < 0)
    return _this__u8e3s4;
  // Inline function 'kotlin.math.pow' call
  var placesFactor = Math.pow(10.0, places);
  return round(_this__u8e3s4 * placesFactor) / placesFactor;
}
var properties_initialized_NumberExt_kt_qjkgco;
function _init_properties_NumberExt_kt__gxb31i() {
  if (!properties_initialized_NumberExt_kt_qjkgco) {
    properties_initialized_NumberExt_kt_qjkgco = true;
    MINUS_ZERO_D = -0.0;
  }
}
var NumberParser_instance;
function NumberParser_getInstance() {
  if (NumberParser_instance === VOID)
    new NumberParser();
  return NumberParser_instance;
}
function ctypeAsInt(_this__u8e3s4) {
  return Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(48)) >= 0 && Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(57)) <= 0 ? Char__minus_impl_a2frrh(_this__u8e3s4, _Char___init__impl__6a9atx(48)) : Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(97)) >= 0 && Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(122)) <= 0 ? Char__minus_impl_a2frrh(_this__u8e3s4, _Char___init__impl__6a9atx(97)) + 10 | 0 : Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(65)) >= 0 && Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(90)) <= 0 ? Char__minus_impl_a2frrh(_this__u8e3s4, _Char___init__impl__6a9atx(65)) + 10 | 0 : -1;
}
function nextAlignedTo(_this__u8e3s4, align) {
  return isAlignedTo(_this__u8e3s4, align) ? _this__u8e3s4 : imul((_this__u8e3s4 / align | 0) + 1 | 0, align);
}
function isAlignedTo(_this__u8e3s4, alignment) {
  return alignment === 0 || (_this__u8e3s4 % alignment | 0) === 0;
}
function nextAlignedTo_0(_this__u8e3s4, align) {
  var tmp;
  if (isAlignedTo_0(_this__u8e3s4, align)) {
    tmp = _this__u8e3s4;
  } else {
    // Inline function 'kotlin.Long.plus' call
    tmp = _this__u8e3s4.e4(align).c4(toLong(1)).d4(align);
  }
  return tmp;
}
function nearestAlignedTo(_this__u8e3s4, align) {
  var prev = prevAlignedTo(_this__u8e3s4, align);
  var next = nextAlignedTo_1(_this__u8e3s4, align);
  var tmp;
  // Inline function 'kotlin.math.absoluteValue' call
  var this_0 = _this__u8e3s4 - prev;
  var tmp_0 = Math.abs(this_0);
  // Inline function 'kotlin.math.absoluteValue' call
  var this_1 = _this__u8e3s4 - next;
  if (tmp_0 < Math.abs(this_1)) {
    tmp = prev;
  } else {
    tmp = next;
  }
  return tmp;
}
function isAlignedTo_0(_this__u8e3s4, alignment) {
  return alignment.equals(new Long(0, 0)) || _this__u8e3s4.f4(alignment).equals(new Long(0, 0));
}
function prevAlignedTo(_this__u8e3s4, align) {
  return isAlignedTo_1(_this__u8e3s4, align) ? _this__u8e3s4 : nextAlignedTo_1(_this__u8e3s4, align) - align;
}
function nextAlignedTo_1(_this__u8e3s4, align) {
  return isAlignedTo_1(_this__u8e3s4, align) ? _this__u8e3s4 : (numberToInt(_this__u8e3s4 / align) + 1 | 0) * align;
}
function isAlignedTo_1(_this__u8e3s4, alignment) {
  return alignment === 0.0 || _this__u8e3s4 % alignment === 0.0;
}
function clamp(_this__u8e3s4, min, max) {
  return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
}
function clamp_0(_this__u8e3s4, min, max) {
  return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
}
function clamp_1(_this__u8e3s4, min, max) {
  return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
}
function clampUByte(_this__u8e3s4) {
  var n = _this__u8e3s4 & (-(_this__u8e3s4 >= 0 ? 1 : 0) | 0);
  return (n | (255 - n | 0) >> 31) & 255;
}
function clamp01(_this__u8e3s4) {
  return clamp(_this__u8e3s4, 0.0, 1.0);
}
function clamp01_0(_this__u8e3s4) {
  return clamp_0(_this__u8e3s4, 0.0, 1.0);
}
function toShortClamped(_this__u8e3s4) {
  return toShort(clamp_1(_this__u8e3s4, -32768, 32767));
}
function toIntClamp(_this__u8e3s4, min, max) {
  min = min === VOID ? -2147483648 : min;
  max = max === VOID ? 2147483647 : max;
  if (_this__u8e3s4.g2(toLong(min)) < 0)
    return min;
  if (_this__u8e3s4.g2(toLong(max)) > 0)
    return max;
  return _this__u8e3s4.q2();
}
function convertRange(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
  return dstMin + (dstMax - dstMin) * ((_this__u8e3s4 - srcMin) / (srcMax - srcMin));
}
function convertRangeClamped(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
  return clamp(convertRange(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax), dstMin, dstMax);
}
function convertRange_0(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
  return dstMin + (dstMax - dstMin) * ((_this__u8e3s4 - srcMin) / (srcMax - srcMin));
}
function convertRange_1(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
  return numberToInt(dstMin + (dstMax - dstMin | 0) * ((_this__u8e3s4 - srcMin | 0) / (srcMax - srcMin | 0)));
}
function divCeil(_this__u8e3s4, that) {
  return !((_this__u8e3s4 % that | 0) === 0) ? (_this__u8e3s4 / that | 0) + 1 | 0 : _this__u8e3s4 / that | 0;
}
function ilog2(v) {
  var tmp;
  if (v === 0) {
    tmp = -1;
  } else {
    // Inline function 'kotlin.countLeadingZeroBits' call
    tmp = 31 - clz32(v) | 0;
  }
  return tmp;
}
function ilog2Ceil(v) {
  // Inline function 'kotlin.math.ceil' call
  // Inline function 'kotlin.math.log2' call
  var x = log2(v);
  var tmp$ret$1 = Math.ceil(x);
  return numberToInt(tmp$ret$1);
}
function isAlmostEquals(_this__u8e3s4, other, epsilon) {
  epsilon = epsilon === VOID ? 1.0E-6 : epsilon;
  // Inline function 'kotlin.math.absoluteValue' call
  var this_0 = _this__u8e3s4 - other;
  return Math.abs(this_0) < epsilon;
}
function isAlmostEquals_0(_this__u8e3s4, other, epsilon) {
  epsilon = epsilon === VOID ? 1.0E-6 : epsilon;
  // Inline function 'kotlin.math.absoluteValue' call
  var this_0 = _this__u8e3s4 - other;
  return Math.abs(this_0) < epsilon;
}
function isAlmostZero(_this__u8e3s4) {
  // Inline function 'kotlin.math.abs' call
  return Math.abs(_this__u8e3s4) <= 1.0E-19;
}
function isAlmostZero_0(_this__u8e3s4) {
  // Inline function 'kotlin.math.abs' call
  return Math.abs(_this__u8e3s4) <= 1.0E-6;
}
function min(a, b, c, d) {
  // Inline function 'kotlin.math.min' call
  // Inline function 'kotlin.math.min' call
  // Inline function 'kotlin.math.min' call
  var a_0 = Math.min(a, b);
  var a_1 = Math.min(a_0, c);
  return Math.min(a_1, d);
}
function max(a, b, c, d) {
  // Inline function 'kotlin.math.max' call
  // Inline function 'kotlin.math.max' call
  // Inline function 'kotlin.math.max' call
  var a_0 = Math.max(a, b);
  var a_1 = Math.max(a_0, c);
  return Math.max(a_1, d);
}
function min_0(a, b, c, d, e) {
  // Inline function 'kotlin.math.min' call
  // Inline function 'kotlin.math.min' call
  // Inline function 'kotlin.math.min' call
  // Inline function 'kotlin.math.min' call
  var a_0 = Math.min(a, b);
  var a_1 = Math.min(a_0, c);
  var a_2 = Math.min(a_1, d);
  return Math.min(a_2, e);
}
function max_0(a, b, c, d, e) {
  // Inline function 'kotlin.math.max' call
  // Inline function 'kotlin.math.max' call
  // Inline function 'kotlin.math.max' call
  // Inline function 'kotlin.math.max' call
  var a_0 = Math.max(a, b);
  var a_1 = Math.max(a_0, c);
  var a_2 = Math.max(a_1, d);
  return Math.max(a_2, e);
}
function nextMultipleOf(_this__u8e3s4, multiple) {
  return isMultipleOf(_this__u8e3s4, multiple) ? _this__u8e3s4 : imul((_this__u8e3s4 / multiple | 0) + 1 | 0, multiple);
}
function normalizeAlmostZero(_this__u8e3s4) {
  return isAlmostZero_0(_this__u8e3s4) ? 0.0 : _this__u8e3s4;
}
function isMultipleOf(_this__u8e3s4, multiple) {
  return multiple === 0 || (_this__u8e3s4 % multiple | 0) === 0;
}
function almostEquals(a, b) {
  return almostZero(a - b);
}
function min_1(a, b, c, d) {
  // Inline function 'kotlin.math.min' call
  // Inline function 'kotlin.math.min' call
  // Inline function 'kotlin.math.min' call
  var a_0 = Math.min(a, b);
  var a_1 = Math.min(a_0, c);
  return Math.min(a_1, d);
}
function max_1(a, b, c, d) {
  // Inline function 'kotlin.math.max' call
  // Inline function 'kotlin.math.max' call
  // Inline function 'kotlin.math.max' call
  var a_0 = Math.max(a, b);
  var a_1 = Math.max(a_0, c);
  return Math.max(a_1, d);
}
function numberOfDigits(_this__u8e3s4, radix) {
  radix = radix === VOID ? 10 : radix;
  return toString(radix, radix).length;
}
function almostZero(a) {
  // Inline function 'kotlin.math.abs' call
  return Math.abs(a) <= 1.0E-7;
}
function get_MINUS_ZERO_D_0() {
  _init_properties_NormalizeZero_kt__l55nqb();
  return MINUS_ZERO_D_0;
}
var MINUS_ZERO_D_0;
var MINUS_ZERO_F;
function normalizeZero_0(_this__u8e3s4) {
  _init_properties_NormalizeZero_kt__l55nqb();
  return _this__u8e3s4 === get_MINUS_ZERO_D_0() ? 0.0 : _this__u8e3s4;
}
var properties_initialized_NormalizeZero_kt_tccwx1;
function _init_properties_NormalizeZero_kt__l55nqb() {
  if (!properties_initialized_NormalizeZero_kt_tccwx1) {
    properties_initialized_NormalizeZero_kt_tccwx1 = true;
    MINUS_ZERO_D_0 = -0.0;
    MINUS_ZERO_F = -0.0;
  }
}
function get_nextPowerOfTwo(_this__u8e3s4) {
  var v = _this__u8e3s4;
  v = v - 1 | 0;
  v = v | v >> 1;
  v = v | v >> 2;
  v = v | v >> 4;
  v = v | v >> 8;
  v = v | v >> 16;
  v = v + 1 | 0;
  return v;
}
function get_isPowerOfTwo(_this__u8e3s4) {
  return get_nextPowerOfTwo(_this__u8e3s4) === _this__u8e3s4;
}
function roundDecimalPlaces_0(_this__u8e3s4, places) {
  if (places < 0)
    return _this__u8e3s4;
  // Inline function 'kotlin.math.pow' call
  var placesFactor = Math.pow(10.0, places);
  return round(_this__u8e3s4 * placesFactor) / placesFactor;
}
function roundDecimalPlaces_1(_this__u8e3s4, places) {
  if (places < 0)
    return _this__u8e3s4;
  // Inline function 'kotlin.math.pow' call
  var placesFactor = Math.pow(10.0, places);
  // Inline function 'kotlin.math.round' call
  var x = _this__u8e3s4 * placesFactor;
  return round(x) / placesFactor;
}
function toIntRound(_this__u8e3s4) {
  return numberToInt(round(_this__u8e3s4));
}
function toIntFloor(_this__u8e3s4) {
  // Inline function 'kotlin.math.floor' call
  var tmp$ret$0 = Math.floor(_this__u8e3s4);
  return numberToInt(tmp$ret$0);
}
function toIntCeil(_this__u8e3s4) {
  // Inline function 'kotlin.math.ceil' call
  var tmp$ret$0 = Math.ceil(_this__u8e3s4);
  return numberToInt(tmp$ret$0);
}
function toIntCeil_0(_this__u8e3s4) {
  // Inline function 'kotlin.math.ceil' call
  var tmp$ret$0 = Math.ceil(_this__u8e3s4);
  return numberToInt(tmp$ret$0);
}
function toIntRound_0(_this__u8e3s4) {
  // Inline function 'kotlin.math.round' call
  var tmp$ret$0 = round(_this__u8e3s4);
  return numberToInt(tmp$ret$0);
}
function toIntFloor_0(_this__u8e3s4) {
  // Inline function 'kotlin.math.floor' call
  var tmp$ret$0 = Math.floor(_this__u8e3s4);
  return numberToInt(tmp$ret$0);
}
function toLongRound(_this__u8e3s4) {
  return numberToLong(round(_this__u8e3s4));
}
function get_MINUS_ZERO_F() {
  _init_properties_Umod_kt__kqwjv1();
  return MINUS_ZERO_F_0;
}
var MINUS_ZERO_F_0;
function umod(_this__u8e3s4, other) {
  _init_properties_Umod_kt__kqwjv1();
  var rm = _this__u8e3s4 % other | 0;
  var remainder = rm === 0 ? 0 : rm;
  return remainder < 0 ? remainder + other | 0 : remainder;
}
function umod_0(_this__u8e3s4, other) {
  _init_properties_Umod_kt__kqwjv1();
  var rm = _this__u8e3s4 % other;
  var remainder = rm === get_MINUS_ZERO_F() ? 0.0 : rm;
  return remainder < 0.0 ? remainder + other : remainder;
}
function umod_1(_this__u8e3s4, other) {
  _init_properties_Umod_kt__kqwjv1();
  var rm = _this__u8e3s4 % other;
  var remainder = rm === -0.0 ? 0.0 : rm;
  return remainder < 0.0 ? remainder + other : remainder;
}
var properties_initialized_Umod_kt_24uvkf;
function _init_properties_Umod_kt__kqwjv1() {
  if (!properties_initialized_Umod_kt_24uvkf) {
    properties_initialized_Umod_kt_24uvkf = true;
    MINUS_ZERO_F_0 = -0.0;
  }
}
function interpolate(_this__u8e3s4, l, r) {
  return Ratio__plus_impl_pm43pk(l, Ratio__times_impl_ucdh7y(Ratio__minus_impl_s1uwyg(r, l), _this__u8e3s4));
}
function interpolate_0(_this__u8e3s4, l, r) {
  return l + (r - l) * Ratio__toDouble_impl_o7epze(_this__u8e3s4);
}
function interpolate_1(_this__u8e3s4, l, r) {
  return numberToInt(l + (r - l | 0) * Ratio__toDouble_impl_o7epze(_this__u8e3s4));
}
function Easing$Companion$LINEAR$lambda(it) {
  return it;
}
function Easing$Companion$SMOOTH$lambda(it) {
  return it * it * (3 - 2 * it);
}
var Companion_instance;
function Companion_getInstance() {
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
function interpolate_2(_this__u8e3s4, l, r) {
  return l + (r - l) * Ratio__toFloat_impl_1ftup5(_this__u8e3s4);
}
function interpolate_3(_this__u8e3s4, l, r) {
  return l.d10(_this__u8e3s4, r);
}
function _Ratio___init__impl__9mwvn2(value) {
  return value;
}
function _Ratio___get_value__impl__57cli6($this) {
  return $this;
}
function _Ratio___init__impl__9mwvn2_0(ratio) {
  return _Ratio___init__impl__9mwvn2(ratio);
}
function Ratio__toFloat_impl_1ftup5($this) {
  return _Ratio___get_value__impl__57cli6($this);
}
function Ratio__toDouble_impl_o7epze($this) {
  return _Ratio___get_value__impl__57cli6($this);
}
function _Ratio___init__impl__9mwvn2_1(value, maximum) {
  return _Ratio___init__impl__9mwvn2_0(value / maximum);
}
function _Ratio___init__impl__9mwvn2_2(value, maximum) {
  return _Ratio___init__impl__9mwvn2(value / maximum);
}
function Ratio__plus_impl_pm43pk($this, that) {
  return _Ratio___init__impl__9mwvn2(_Ratio___get_value__impl__57cli6($this) + _Ratio___get_value__impl__57cli6(that));
}
function Ratio__minus_impl_s1uwyg($this, that) {
  return _Ratio___init__impl__9mwvn2(_Ratio___get_value__impl__57cli6($this) - _Ratio___get_value__impl__57cli6(that));
}
function Ratio__times_impl_ucdh7y($this, that) {
  return _Ratio___init__impl__9mwvn2(_Ratio___get_value__impl__57cli6($this) * _Ratio___get_value__impl__57cli6(that));
}
function Ratio__times_impl_ucdh7y_0($this, that) {
  return _Ratio___get_value__impl__57cli6($this) * that;
}
function _Ratio___get_absoluteValue__impl__92ozif($this) {
  // Inline function 'kotlin.math.absoluteValue' call
  var this_0 = _Ratio___get_value__impl__57cli6($this);
  var tmp$ret$0 = Math.abs(this_0);
  return _Ratio___init__impl__9mwvn2(tmp$ret$0);
}
function _Ratio___get_clamped__impl__cvqpjt($this) {
  return _Ratio___init__impl__9mwvn2(clamp01(_Ratio___get_value__impl__57cli6($this)));
}
function Ratio__convertToRange_impl_8a60wd($this, min, max) {
  return convertRange(Ratio__toDouble_impl_o7epze($this), 0.0, 1.0, min, max);
}
function Ratio__compareTo_impl_z2ienc($this, other) {
  return compareTo(_Ratio___get_value__impl__57cli6($this), _Ratio___get_value__impl__57cli6(other));
}
function Ratio__compareTo_impl_z2ienc_0($this, other) {
  return Ratio__compareTo_impl_z2ienc($this.e10_1, other instanceof Ratio ? other.e10_1 : THROW_CCE());
}
function Ratio__toString_impl_mfgt3e($this) {
  return '' + _Ratio___get_value__impl__57cli6($this);
}
var Companion_instance_0;
function Companion_getInstance_0() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function Ratio__hashCode_impl_quhc2v($this) {
  return getNumberHashCode($this);
}
function Ratio__equals_impl_mfc2gt($this, other) {
  if (!(other instanceof Ratio))
    return false;
  var tmp0_other_with_cast = other instanceof Ratio ? other.e10_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function toRatio(_this__u8e3s4) {
  return _this__u8e3s4;
}
function convertRange_2(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
  return _Ratio___init__impl__9mwvn2(convertRange(Ratio__toDouble_impl_o7epze(_this__u8e3s4), Ratio__toDouble_impl_o7epze(srcMin), Ratio__toDouble_impl_o7epze(srcMax), Ratio__toDouble_impl_o7epze(dstMin), Ratio__toDouble_impl_o7epze(dstMax)));
}
function toRatio_0(_this__u8e3s4) {
  return _Ratio___init__impl__9mwvn2(_this__u8e3s4);
}
function isAlmostEquals_1(_this__u8e3s4, that, epsilon) {
  epsilon = epsilon === VOID ? _Ratio___init__impl__9mwvn2(1.0E-6) : epsilon;
  return isAlmostEquals(Ratio__toDouble_impl_o7epze(_this__u8e3s4), Ratio__toDouble_impl_o7epze(that), Ratio__toDouble_impl_o7epze(epsilon));
}
function toRatio_1(_this__u8e3s4) {
  return _Ratio___init__impl__9mwvn2_0(_this__u8e3s4);
}
function toRatioClamped(_this__u8e3s4) {
  return _Ratio___init__impl__9mwvn2(clamp01(_this__u8e3s4));
}
function extract(_this__u8e3s4, offset, count) {
  return (_this__u8e3s4 >>> offset | 0) & mask_0(count);
}
function insert(_this__u8e3s4, value, offset, count) {
  var mask = mask_0(count) << offset;
  var ovalue = value << offset & mask;
  return _this__u8e3s4 & ~mask | ovalue;
}
function extractScaledFF(_this__u8e3s4, offset, count) {
  return extractScaled(_this__u8e3s4, offset, count, 255);
}
function extractScaledFFDefault(_this__u8e3s4, offset, count, default_0) {
  return count === 0 ? default_0 : extractScaled(_this__u8e3s4, offset, count, 255);
}
function extractSigned(_this__u8e3s4, offset, count) {
  return signExtend((_this__u8e3s4 >>> offset | 0) & mask_0(count), count);
}
function extract16Signed(_this__u8e3s4, offset) {
  return toShort(_this__u8e3s4 >>> offset | 0);
}
function insert16(_this__u8e3s4, value, offset) {
  // Inline function 'korlibs.memory.insertMask' call
  return _this__u8e3s4 & ~(65535 << offset) | (value & 65535) << offset;
}
function mask(_this__u8e3s4, offset) {
  return mask_0(_this__u8e3s4) << offset;
}
function _IntMaskRange___init__impl__8vv60x(raw) {
  return raw;
}
function _IntMaskRange___get_raw__impl__o13tc4($this) {
  return $this;
}
function _IntMaskRange___get_offset__impl__5q65d3($this) {
  // Inline function 'korlibs.memory.extract8' call
  return (_IntMaskRange___get_raw__impl__o13tc4($this) >>> 0 | 0) & 255;
}
function _IntMaskRange___get_size__impl__slzprt($this) {
  // Inline function 'korlibs.memory.extract8' call
  return (_IntMaskRange___get_raw__impl__o13tc4($this) >>> 8 | 0) & 255;
}
function IntMaskRange__extractSigned_impl_s8qphq($this, value, signed) {
  return extractSigned_0(value, _IntMaskRange___get_offset__impl__5q65d3($this), _IntMaskRange___get_size__impl__slzprt($this), signed);
}
var Companion_instance_1;
function Companion_getInstance_1() {
  return Companion_instance_1;
}
function insert8(_this__u8e3s4, value, offset) {
  // Inline function 'korlibs.memory.insertMask' call
  return _this__u8e3s4 & ~(255 << offset) | (value & 255) << offset;
}
function get_low(_this__u8e3s4) {
  return _this__u8e3s4.q2();
}
function get_high(_this__u8e3s4) {
  return _this__u8e3s4.m4(32).q2();
}
function fromLowHigh(_this__u8e3s4, low, high) {
  return toLong(low).n4(new Long(-1, 0)).o4(toLong(high).k4(32));
}
function insert_0(_this__u8e3s4, value, offset) {
  var bits = 1 << offset;
  return value ? _this__u8e3s4 | bits : _this__u8e3s4 & ~bits;
}
function hasFlags(_this__u8e3s4, bits) {
  return (_this__u8e3s4 & bits) === bits;
}
function setBits(_this__u8e3s4, bits, set) {
  return set ? setBits_0(_this__u8e3s4, bits) : unsetBits(_this__u8e3s4, bits);
}
function insert5(_this__u8e3s4, value, offset) {
  // Inline function 'korlibs.memory.insertMask' call
  return _this__u8e3s4 & ~(31 << offset) | (value & 31) << offset;
}
function insert2(_this__u8e3s4, value, offset) {
  // Inline function 'korlibs.memory.insertMask' call
  return _this__u8e3s4 & ~(3 << offset) | (value & 3) << offset;
}
function insert4(_this__u8e3s4, value, offset) {
  // Inline function 'korlibs.memory.insertMask' call
  return _this__u8e3s4 & ~(15 << offset) | (value & 15) << offset;
}
function insert14(_this__u8e3s4, value, offset) {
  // Inline function 'korlibs.memory.insertMask' call
  return _this__u8e3s4 & ~(16383 << offset) | (value & 16383) << offset;
}
function insert3(_this__u8e3s4, value, offset) {
  // Inline function 'korlibs.memory.insertMask' call
  return _this__u8e3s4 & ~(7 << offset) | (value & 7) << offset;
}
function extractScaledf01(_this__u8e3s4, offset, count) {
  return extract(_this__u8e3s4, offset, count) / mask_0(count);
}
function insertScaledf01(_this__u8e3s4, value, offset, count) {
  return insert(_this__u8e3s4, numberToInt(coerceIn(value, 0.0, 1.0) * mask_0(offset)), offset, count);
}
function mask_0(_this__u8e3s4) {
  return (1 << _this__u8e3s4) - 1 | 0;
}
function extractScaled(_this__u8e3s4, offset, count, scale) {
  return imul(extract(_this__u8e3s4, offset, count), scale) / mask_0(count) | 0;
}
function signExtend(_this__u8e3s4, bits) {
  return _this__u8e3s4 << (32 - bits | 0) >> (32 - bits | 0);
}
function extractSigned_0(_this__u8e3s4, offset, count, signed) {
  return signed ? extractSigned(_this__u8e3s4, offset, count) : extract(_this__u8e3s4, offset, count);
}
function setBits_0(_this__u8e3s4, bits) {
  return _this__u8e3s4 | bits;
}
function unsetBits(_this__u8e3s4, bits) {
  return _this__u8e3s4 & ~bits;
}
function extractByte(_this__u8e3s4, offset) {
  return toByte(_this__u8e3s4 >>> offset | 0);
}
function hasBitSet(_this__u8e3s4, index) {
  return !(((_this__u8e3s4 >>> index | 0) & 1) === 0);
}
function get_niceStr(_this__u8e3s4) {
  return niceStr(_this__u8e3s4, -1, false);
}
function niceStr(_this__u8e3s4, decimalPlaces, zeroSuffix) {
  zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
  // Inline function 'kotlin.text.buildString' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.h();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.number.niceStr.<anonymous>' call
  appendNice(this_0, roundDecimalPlaces_0(_this__u8e3s4, decimalPlaces), zeroSuffix && decimalPlaces > 0);
  return this_0.toString();
}
function get_niceStr_0(_this__u8e3s4) {
  return niceStr_0(_this__u8e3s4, -1, false);
}
function niceStr_0(_this__u8e3s4, decimalPlaces, zeroSuffix) {
  zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
  // Inline function 'kotlin.text.buildString' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.h();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.number.niceStr.<anonymous>' call
  appendNice_0(this_0, roundDecimalPlaces_1(_this__u8e3s4, decimalPlaces), zeroSuffix && decimalPlaces > 0);
  return this_0.toString();
}
function appendNice(_this__u8e3s4, value, zeroSuffix) {
  zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
  if (isAlmostEquals(round(value), value))
    if (value >= -2147483648 && value <= 2147483647)
      _this__u8e3s4.oh(numberToInt(round(value)));
    else
      _this__u8e3s4.ph(numberToLong(round(value)));
  else {
    _this__u8e3s4.rh(value);
    return Unit_instance;
  }
  if (zeroSuffix) {
    _this__u8e3s4.ic('.0');
  }
}
function appendNice_0(_this__u8e3s4, value, zeroSuffix) {
  zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
  // Inline function 'kotlin.math.round' call
  var tmp$ret$0 = round(value);
  if (isAlmostEquals_0(tmp$ret$0, value))
    if (value >= -2147483648 && value <= 2147483647)
      _this__u8e3s4.oh(numberToInt(value));
    else
      _this__u8e3s4.ph(numberToLong(value));
  else {
    _this__u8e3s4.qh(value);
    return Unit_instance;
  }
  if (zeroSuffix) {
    _this__u8e3s4.ic('.0');
  }
}
function appendGenericArray(_this__u8e3s4, size, appendElement) {
  _this__u8e3s4.ic('[');
  var inductionVariable = 0;
  if (inductionVariable < size)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      if (!(n === 0)) {
        _this__u8e3s4.ic(', ');
      }
      appendElement(_this__u8e3s4, n);
    }
     while (inductionVariable < size);
  _this__u8e3s4.ic(']');
}
//region block: post-declaration
initMetadataForObject(NumberParser, 'NumberParser');
initMetadataForInterface(IsAlmostEquals, 'IsAlmostEquals');
initMetadataForInterface(Easing, 'Easing');
protoOf(sam$korlibs_math_interpolation_Easing$0).yz = invoke;
protoOf(sam$korlibs_math_interpolation_Easing$0).zz = invoke_0;
initMetadataForClass(sam$korlibs_math_interpolation_Easing$0, 'sam$korlibs_math_interpolation_Easing$0', VOID, VOID, [Easing]);
protoOf(sam$korlibs_math_interpolation_Easing$0_0).yz = invoke;
protoOf(sam$korlibs_math_interpolation_Easing$0_0).zz = invoke_0;
initMetadataForClass(sam$korlibs_math_interpolation_Easing$0_0, 'sam$korlibs_math_interpolation_Easing$0', VOID, VOID, [Easing]);
initMetadataForCompanion(Companion);
initMetadataForCompanion(Companion_0);
initMetadataForClass(Ratio, 'Ratio', VOID, VOID, [Comparable]);
initMetadataForCompanion(Companion_1);
//endregion
//region block: init
Companion_instance_1 = new Companion_1();
//endregion
//region block: exports
export {
  isAlmostEquals$default as isAlmostEquals$default1ua43koqhm6mp,
  _Ratio___init__impl__9mwvn2_2 as _Ratio___init__impl__9mwvn2cg32u2k0cnlg,
  _Ratio___init__impl__9mwvn2_1 as _Ratio___init__impl__9mwvn2tqmswwh98av3,
  _Ratio___init__impl__9mwvn2_0 as _Ratio___init__impl__9mwvn21qo8ztog8o9ep,
  _Ratio___init__impl__9mwvn2 as _Ratio___init__impl__9mwvn21rjrz0s2y877i,
  _Ratio___get_absoluteValue__impl__92ozif as _Ratio___get_absoluteValue__impl__92ozif22un4a8w9a8ev,
  _Ratio___get_clamped__impl__cvqpjt as _Ratio___get_clamped__impl__cvqpjtmyj4kf6qyj5u,
  Ratio__compareTo_impl_z2ienc as Ratio__compareTo_impl_z2ienc1iwi3w3dhe1b7,
  Ratio__convertToRange_impl_8a60wd as Ratio__convertToRange_impl_8a60wd2mvk8r985t1wp,
  Ratio__hashCode_impl_quhc2v as Ratio__hashCode_impl_quhc2v7lufr2urxbhm,
  Ratio__minus_impl_s1uwyg as Ratio__minus_impl_s1uwyg3oqkno1go43tn,
  Ratio__times_impl_ucdh7y as Ratio__times_impl_ucdh7y151ap1tls937r,
  Ratio__times_impl_ucdh7y_0 as Ratio__times_impl_ucdh7y1eb1bj32n3p46,
  Ratio__toDouble_impl_o7epze as Ratio__toDouble_impl_o7epze3e3zsci3ipwe7,
  Ratio__toFloat_impl_1ftup5 as Ratio__toFloat_impl_1ftup5294anfb3s41c1,
  Ratio__toString_impl_mfgt3e as Ratio__toString_impl_mfgt3e2hpoo0nu4cwhr,
  _Ratio___get_value__impl__57cli6 as _Ratio___get_value__impl__57cli6w7w64hz8t0rv,
  IntMaskRange__extractSigned_impl_s8qphq as IntMaskRange__extractSigned_impl_s8qphq2famz7h8sv4u4,
  NumberParser_getInstance as NumberParser_getInstance1wuuyirzmqen5,
  Companion_getInstance as Companion_getInstancech3bi94cc2ym,
  Companion_getInstance_0 as Companion_getInstance1kb6pmvl5oz92,
  Companion_instance_1 as Companion_instance1hng7e117o7dr,
  ctypeAsInt as ctypeAsInt3q5txwwtaj9c8,
  toStringDecimal as toStringDecimalw23mr08es1e2,
  invoke as invoke3idfsdzzpzmqq,
  invoke_0 as invoke1y68e7g1lbvl5,
  Easing as Easing3266vfl8a4o75,
  Ratio as Ratio136tf4z2t0p2d,
  convertRange_2 as convertRange139vj18oahy2c,
  interpolate_3 as interpolate27vw5eh8zjnyi,
  interpolate as interpolate2g7wzskc23f1q,
  interpolate_1 as interpolatexm8yaf0iv9q9,
  interpolate_0 as interpolate35pbs3ac913y4,
  interpolate_2 as interpolate1wglaj6k11c6v,
  isAlmostEquals_1 as isAlmostEquals18m8qk24p7w0q,
  toRatioClamped as toRatioClampedeq87m2alw3kr,
  toRatio_1 as toRatiolga1a9coidva,
  toRatio_0 as toRatio1hooqit5gulhr,
  toRatio as toRatioa3v13oich5q,
  IsAlmostEquals as IsAlmostEquals2vs54x7i426j2,
  almostEquals as almostEquals13hvojogln91b,
  clamp01 as clamp011ml7wx1d2b7sp,
  clamp01_0 as clamp012a0sd8pj80ati,
  clampUByte as clampUByte16elok6pzd6c5,
  clamp_0 as clampdicp8njiht45,
  clamp_1 as clamp31lhm66c28ic8,
  clamp as clamp1qclpmpfxm7xb,
  convertRangeClamped as convertRangeClamped3njqr0qabe371,
  convertRange as convertRange1a6ancvyn151r,
  convertRange_0 as convertRange378kqy75r4gq5,
  convertRange_1 as convertRange24n2xnngpze54,
  divCeil as divCeil2r4o5lbvnv4lh,
  ilog2Ceil as ilog2Ceil34a3ub1n8578t,
  ilog2 as ilog23dbvpk38jf7rc,
  isAlmostEquals as isAlmostEquals2e7wogp48cvmj,
  isAlmostZero_0 as isAlmostZero1ou51u04focf8,
  isAlmostZero as isAlmostZero7baqs51et9hm,
  get_isPowerOfTwo as get_isPowerOfTwoeb2yxu9ww0f7,
  max_0 as maxfuu7ju0dv4mx,
  max_1 as max329dnql9l08bp,
  max as max1izmzj76cv5b4,
  min as min1z074tc6jeruz,
  min_1 as minhi4sj3tfj9ww,
  min_0 as min2wcdwdef93gdl,
  nearestAlignedTo as nearestAlignedTo29ierorbs9y6l,
  nextAlignedTo_0 as nextAlignedTo3ni8b2boh3zjg,
  nextAlignedTo as nextAlignedTo2788hp7lcoqp,
  nextMultipleOf as nextMultipleOf14oge7vxqp9sy,
  get_nextPowerOfTwo as get_nextPowerOfTwoselx3fye7kjp,
  normalizeAlmostZero as normalizeAlmostZeronwhxiyvg9dek,
  normalizeZero_0 as normalizeZero2ksfd6bzt4qwz,
  numberOfDigits as numberOfDigits2ov4bzopld8er,
  roundDecimalPlaces_0 as roundDecimalPlaces1x32iuyd1l8m5,
  roundDecimalPlaces_1 as roundDecimalPlacespck2j5mpzhqn,
  toIntCeil_0 as toIntCeil3h286vm49zgwd,
  toIntCeil as toIntCeiltm5lvaa6k4k9,
  toIntClamp as toIntClampad53d0g5q5oi,
  toIntFloor_0 as toIntFloor2i5mxseifeud5,
  toIntFloor as toIntFloor3t307m2tavlm8,
  toIntRound as toIntRoundjnzmk86x1qp5,
  toIntRound_0 as toIntRound1mv0y8x9hh1p4,
  toLongRound as toLongRound7crzegvo2qbo,
  toShortClamped as toShortClampedqjuvod9w2qhj,
  umod as umod164hvijxhfi8p,
  umod_0 as umodgae5ifx362au,
  umod_1 as umod1d8wj61c81r0q,
  extract16Signed as extract16Signed1oywtbmsx4vcm,
  extractByte as extractBytedbxg7rz8vq1s,
  extractScaledFFDefault as extractScaledFFDefault1jt5eyc4afdti,
  extractScaledFF as extractScaledFF37en8uu0vajkj,
  extractScaledf01 as extractScaledf013fj6eqsu4szwo,
  extractSigned as extractSignedx6cuhlj55jv4,
  extract as extract2vhru1ihy4ogl,
  fromLowHigh as fromLowHigh12fm8h1tmjclj,
  hasBitSet as hasBitSet141smdymg86x0,
  hasFlags as hasFlagsibfubl68mj8u,
  get_high as get_high3vl4k4kkorqlu,
  insert14 as insert141hi307uxchfzk,
  insert16 as insert163hf9bvod0wg0m,
  insert2 as insert22yqrzxcpmo55e,
  insert3 as insert32d422lnha9g5r,
  insert4 as insert42sy0xc4ax89g9,
  insert5 as insert52qor1kqho1lpj,
  insert8 as insert8f3zk23r8x9da,
  insertScaledf01 as insertScaledf01oj6k04gavkch,
  insert as insertm9bp9ll4cml3,
  insert_0 as insert2fz5125p2hdyd,
  get_low as get_low2kdkn3173rx8r,
  mask as maskkmj9g5rvi2pr,
  setBits as setBitsqsmqq5kc486j,
  signExtend as signExtend10m51xy6jou1t,
  appendGenericArray as appendGenericArray5krccch8fjd0,
  appendNice as appendNice3hizhj9kone09,
  get_niceStr as get_niceStr3crhxydmlnaoj,
  get_niceStr_0 as get_niceStr1tlwtz2l2j4my,
  niceStr as niceStr1mw27wr6fq0ek,
  niceStr_0 as niceStr1ftz2qvn4gdyq,
};
//endregion
