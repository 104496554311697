import {
  EmptySerializersModule991ju6pz9b79 as EmptySerializersModule,
  Decoder23nde051s631g as Decoder,
  CompositeDecoder2tzm7wpwkr0og as CompositeDecoder,
  SerializerFactory1qv9hivitncuv as SerializerFactory,
  serializer1x79l67jvwntn as serializer,
  InlinePrimitiveDescriptor3i6ccn1a4fw94 as InlinePrimitiveDescriptor,
  SEALED_getInstance15u0wl5f5h4qk as SEALED_getInstance,
  buildSerialDescriptor2873qmkp8r2ib as buildSerialDescriptor,
  KSerializerzf77vz1967fq as KSerializer,
  ListSerializer1hxuk9dx5n9du as ListSerializer,
  SerialDescriptor2pelqekb5ic3a as SerialDescriptor,
  MapSerializer11kmegt3g5c1g as MapSerializer,
  STRING_getInstance2gbamclnmtzqa as STRING_getInstance,
  ENUM_getInstance3doea03ve3rgg as ENUM_getInstance,
  PrimitiveSerialDescriptor3egfp53lutxj2 as PrimitiveSerialDescriptor,
  get_isNullable36pbikm8xb7bz as get_isNullable,
  get_isInline5x26qrhi9qs6 as get_isInline,
  get_annotationshjxdbdcl8kmv as get_annotations,
  ElementMarker33ojvsajwmzts as ElementMarker,
  SerializationExceptioneqrdve3ts2n9 as SerializationException,
  CLASS_getInstance1ss90e870vddp as CLASS_getInstance,
  LIST_getInstance3iji3zetpdcxm as LIST_getInstance,
  CONTEXTUAL_getInstance40twmib99zqv as CONTEXTUAL_getInstance,
  PolymorphicKindla9gurooefwb as PolymorphicKind,
  PrimitiveKindndgbuh6is7ze as PrimitiveKind,
  MAP_getInstance2l4ulrz8ljw5i as MAP_getInstance,
  ENUMlmq49cvwy4ow as ENUM,
  contextual3hpp1gupsu4al as contextual,
  SerializersModuleCollector3dddz14wd7brg as SerializersModuleCollector,
  AbstractDecoder35guh02ubh2hm as AbstractDecoder,
  AbstractPolymorphicSerializer1ccxwp48nfy58 as AbstractPolymorphicSerializer,
  DeserializationStrategy1z3z5pj9f7zc8 as DeserializationStrategy,
  findPolymorphicSerializer1nm87hvemahcj as findPolymorphicSerializer,
  MissingFieldException24tqif29emcmi as MissingFieldException,
  serializer3ikrxnm8b29d6 as serializer_0,
  serializer2lw83vwvpnyms as serializer_1,
  serializer36584sjyg5661 as serializer_2,
  serializer1q7c5q67ysppr as serializer_3,
  NamedValueDecoderzk26ztf92xbq as NamedValueDecoder,
  jsonCachedSerialNameslxufy2gu43jt as jsonCachedSerialNames,
  getContextualDescriptor2n1gf3b895yb8 as getContextualDescriptor,
} from './kotlinx-serialization-kotlinx-serialization-core.mjs';
import {
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  VOID7hggqo3abtya as VOID,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  toString1pkumu07cwy4m as toString,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  Unit_instance104q5opgivhr8 as Unit_instance,
  equals2au1ep9vhcato as equals,
  toString30pk9tzaqopn as toString_0,
  Enum3alwj03lh1n41 as Enum,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  hashCodeq5arwsb9dgti as hashCode,
  joinToString1cxrrlmo0chqs as joinToString,
  KtList3hktaavzmj137 as KtList,
  StringBuildermazzzhj6kkai as StringBuilder,
  KtMap140uvy3s5zad8 as KtMap,
  LazyThreadSafetyMode_PUBLICATION_getInstance3hlj875zwihx0 as LazyThreadSafetyMode_PUBLICATION_getInstance,
  lazy1261dae0bgscp as lazy,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  NumberFormatException3bgsm2s9o4t55 as NumberFormatException,
  numberRangeToNumber25vse2rgp6rs8 as numberRangeToNumber,
  ClosedRangehokgr73im9z3 as ClosedRange,
  isInterface3d6p8outrmvmk as isInterface,
  contains2c50nlxg7en7o as contains,
  toDouble1kn912gjoizjp as toDouble,
  StringCompanionObject_instance2odz3s3zbrixa as StringCompanionObject_instance,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  lazy2hsh8ze7j6ikd as lazy_0,
  protoOf180f3jzyo7rfj as protoOf,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  captureStack1fzi4aczwc4hg as captureStack,
  charSequenceSubSequence1iwpdba8s3jc7 as charSequenceSubSequence,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  coerceAtMost322komnqp70ag as coerceAtMost,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  ArrayList3it5z8td81qkl as ArrayList,
  singleOrNullrknfaxokm1sl as singleOrNull,
  emptyMapr06gerzljqtm as emptyMap,
  getValue48kllevslyh6 as getValue,
  copyOf2ng0t8oizk6it as copyOf,
  copyOf3rutauicler23 as copyOf_0,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  DeepRecursiveFunction3r49v8igsve1g as DeepRecursiveFunction,
  invoke246lvi6tzooz1 as invoke,
  DeepRecursiveScope1pqaydvh4vdcu as DeepRecursiveScope,
  Unitkvevlwgzwiuc as Unit,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  getKClass1s3j9wy1cofik as getKClass,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  substringBefore3n7kj60w69hju as substringBefore,
  removeSuffix3d61x5lsuvuho as removeSuffix,
  substringAfter1hku067gwr5ve as substringAfter,
  contains3ue2qo8xhmpf1 as contains_0,
  plus17rl43at52ays as plus,
  toLongw1zpgk99d84b as toLong,
  isFinite1tx0gn65nl9tj as isFinite,
  isFinite2t9l5a275mxm6 as isFinite_0,
  toUInt21lx0mz8wkp7c as toUInt,
  _UInt___get_data__impl__f0vqqw1vaw6fgw4kwl0 as _UInt___get_data__impl__f0vqqw,
  toULong266mnyksbttkw as toULong,
  _ULong___get_data__impl__fggpzb1fgc8uwlfcfgw as _ULong___get_data__impl__fggpzb,
  toUByteh6p4wmqswkrs as toUByte,
  _UByte___get_data__impl__jof9qr9d98aajdmpjx as _UByte___get_data__impl__jof9qr,
  toUShort7yqspfnhrot4 as toUShort,
  _UShort___get_data__impl__g02453juyrdkc7n6xd as _UShort___get_data__impl__g0245,
  Companion_getInstance1z323tr26bmxd as Companion_getInstance,
  Companion_getInstance1poxudqc1ru3p as Companion_getInstance_0,
  Companion_getInstance374brtr06v94p as Companion_getInstance_1,
  Companion_getInstanceojp2cj59jqir as Companion_getInstance_2,
  setOf45ia9pnfhe90 as setOf,
  Char__toInt_impl_vasixd1g1ubcha2b2yr as Char__toInt_impl_vasixd,
  numberToChar93r9buh19yek as numberToChar,
  equals2v6cggk171b6e as equals_0,
  toString26mv3gzfo7fn as toString_1,
  startsWith26w8qjqapeeq6 as startsWith,
  toByte4i43936u611k as toByte,
  toShort36kaw0zjdq3ex as toShort,
  single29ec4rh52687r as single,
  Char19o2r8palgjof as Char,
  emptySetcxexqki71qfa as emptySet,
  plus1ogy4liedzq5j as plus_0,
  toInt2q8uldh7sc951 as toInt,
  toList3jhuyej2anx2q as toList,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  last1vo29oleiqj36 as last,
  removeLast3759euu1xvfa3 as removeLast,
  lastIndexOf2d52xhix5ymjr as lastIndexOf,
  Long2qws0ah9gnpki as Long,
  Char__minus_impl_a2frrhp8twy13wqz9c as Char__minus_impl_a2frrh,
  numberToLong1a4cndvg6c52s as numberToLong,
  charArray2ujmm1qusno00 as charArray,
  indexOfwa4w6635jewi as indexOf,
  indexOf1xbs558u7wr52 as indexOf_0,
  HashMap1a0ld5kgwhmhv as HashMap,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Json {
  constructor(configuration, serializersModule) {
    Default_getInstance();
    this.wjw_1 = configuration;
    this.xjw_1 = serializersModule;
    this.yjw_1 = new DescriptorSchemaCache();
  }
  ejk() {
    return this.xjw_1;
  }
  zjw(deserializer, string) {
    var lexer = StringJsonLexer_0(this, string);
    var input = new StreamingJsonDecoder(this, WriteMode_OBJ_getInstance(), lexer, deserializer.ujf(), null);
    var result = input.ojj(deserializer);
    lexer.mjx();
    return result;
  }
}
class Default extends Json {
  constructor() {
    Default_instance = null;
    super(new JsonConfiguration(), EmptySerializersModule());
    Default_instance = this;
  }
}
class JsonBuilder {
  constructor(json) {
    this.njx_1 = json.wjw_1.gjy_1;
    this.ojx_1 = json.wjw_1.ljy_1;
    this.pjx_1 = json.wjw_1.hjy_1;
    this.qjx_1 = json.wjw_1.ijy_1;
    this.rjx_1 = json.wjw_1.kjy_1;
    this.sjx_1 = json.wjw_1.mjy_1;
    this.tjx_1 = json.wjw_1.njy_1;
    this.ujx_1 = json.wjw_1.pjy_1;
    this.vjx_1 = json.wjw_1.wjy_1;
    this.wjx_1 = json.wjw_1.rjy_1;
    this.xjx_1 = json.wjw_1.sjy_1;
    this.yjx_1 = json.wjw_1.tjy_1;
    this.zjx_1 = json.wjw_1.ujy_1;
    this.ajy_1 = json.wjw_1.vjy_1;
    this.bjy_1 = json.wjw_1.qjy_1;
    this.cjy_1 = json.wjw_1.jjy_1;
    this.djy_1 = json.wjw_1.ojy_1;
    this.ejy_1 = json.ejk();
  }
  fjy() {
    if (this.djy_1) {
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(this.ujx_1 === 'type')) {
        // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
        var message = 'Class discriminator should not be specified when array polymorphism is specified';
        throw IllegalArgumentException.l(toString(message));
      }
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!this.vjx_1.equals(ClassDiscriminatorMode_POLYMORPHIC_getInstance())) {
        // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
        var message_0 = 'useArrayPolymorphism option can only be used if classDiscriminatorMode in a default POLYMORPHIC state.';
        throw IllegalArgumentException.l(toString(message_0));
      }
    }
    if (!this.rjx_1) {
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(this.sjx_1 === '    ')) {
        // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
        var message_1 = 'Indent should not be specified when default printing mode is used';
        throw IllegalArgumentException.l(toString(message_1));
      }
    } else if (!(this.sjx_1 === '    ')) {
      var tmp$ret$4;
      $l$block: {
        // Inline function 'kotlin.text.all' call
        var indexedObject = this.sjx_1;
        var inductionVariable = 0;
        while (inductionVariable < charSequenceLength(indexedObject)) {
          var element = charSequenceGet(indexedObject, inductionVariable);
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
          if (!(element === _Char___init__impl__6a9atx(32) || element === _Char___init__impl__6a9atx(9) || element === _Char___init__impl__6a9atx(13) || element === _Char___init__impl__6a9atx(10))) {
            tmp$ret$4 = false;
            break $l$block;
          }
        }
        tmp$ret$4 = true;
      }
      var allWhitespaces = tmp$ret$4;
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!allWhitespaces) {
        // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
        var message_2 = 'Only whitespace, tab, newline and carriage return are allowed as pretty print symbols. Had ' + this.sjx_1;
        throw IllegalArgumentException.l(toString(message_2));
      }
    }
    return new JsonConfiguration(this.njx_1, this.pjx_1, this.qjx_1, this.cjy_1, this.rjx_1, this.ojx_1, this.sjx_1, this.tjx_1, this.djy_1, this.ujx_1, this.bjy_1, this.wjx_1, this.xjx_1, this.yjx_1, this.zjx_1, this.ajy_1, this.vjx_1);
  }
}
class JsonImpl extends Json {
  constructor(configuration, module_0) {
    super(configuration, module_0);
    validateConfiguration(this);
  }
}
class JsonClassDiscriminator {}
class JsonNames {}
class JsonConfiguration {
  constructor(encodeDefaults, ignoreUnknownKeys, isLenient, allowStructuredMapKeys, prettyPrint, explicitNulls, prettyPrintIndent, coerceInputValues, useArrayPolymorphism, classDiscriminator, allowSpecialFloatingPointValues, useAlternativeNames, namingStrategy, decodeEnumsCaseInsensitive, allowTrailingComma, allowComments, classDiscriminatorMode) {
    encodeDefaults = encodeDefaults === VOID ? false : encodeDefaults;
    ignoreUnknownKeys = ignoreUnknownKeys === VOID ? false : ignoreUnknownKeys;
    isLenient = isLenient === VOID ? false : isLenient;
    allowStructuredMapKeys = allowStructuredMapKeys === VOID ? false : allowStructuredMapKeys;
    prettyPrint = prettyPrint === VOID ? false : prettyPrint;
    explicitNulls = explicitNulls === VOID ? true : explicitNulls;
    prettyPrintIndent = prettyPrintIndent === VOID ? '    ' : prettyPrintIndent;
    coerceInputValues = coerceInputValues === VOID ? false : coerceInputValues;
    useArrayPolymorphism = useArrayPolymorphism === VOID ? false : useArrayPolymorphism;
    classDiscriminator = classDiscriminator === VOID ? 'type' : classDiscriminator;
    allowSpecialFloatingPointValues = allowSpecialFloatingPointValues === VOID ? false : allowSpecialFloatingPointValues;
    useAlternativeNames = useAlternativeNames === VOID ? true : useAlternativeNames;
    namingStrategy = namingStrategy === VOID ? null : namingStrategy;
    decodeEnumsCaseInsensitive = decodeEnumsCaseInsensitive === VOID ? false : decodeEnumsCaseInsensitive;
    allowTrailingComma = allowTrailingComma === VOID ? false : allowTrailingComma;
    allowComments = allowComments === VOID ? false : allowComments;
    classDiscriminatorMode = classDiscriminatorMode === VOID ? ClassDiscriminatorMode_POLYMORPHIC_getInstance() : classDiscriminatorMode;
    this.gjy_1 = encodeDefaults;
    this.hjy_1 = ignoreUnknownKeys;
    this.ijy_1 = isLenient;
    this.jjy_1 = allowStructuredMapKeys;
    this.kjy_1 = prettyPrint;
    this.ljy_1 = explicitNulls;
    this.mjy_1 = prettyPrintIndent;
    this.njy_1 = coerceInputValues;
    this.ojy_1 = useArrayPolymorphism;
    this.pjy_1 = classDiscriminator;
    this.qjy_1 = allowSpecialFloatingPointValues;
    this.rjy_1 = useAlternativeNames;
    this.sjy_1 = namingStrategy;
    this.tjy_1 = decodeEnumsCaseInsensitive;
    this.ujy_1 = allowTrailingComma;
    this.vjy_1 = allowComments;
    this.wjy_1 = classDiscriminatorMode;
  }
  toString() {
    return 'JsonConfiguration(encodeDefaults=' + this.gjy_1 + ', ignoreUnknownKeys=' + this.hjy_1 + ', isLenient=' + this.ijy_1 + ', ' + ('allowStructuredMapKeys=' + this.jjy_1 + ', prettyPrint=' + this.kjy_1 + ', explicitNulls=' + this.ljy_1 + ', ') + ("prettyPrintIndent='" + this.mjy_1 + "', coerceInputValues=" + this.njy_1 + ', useArrayPolymorphism=' + this.ojy_1 + ', ') + ("classDiscriminator='" + this.pjy_1 + "', allowSpecialFloatingPointValues=" + this.qjy_1 + ', ') + ('useAlternativeNames=' + this.rjy_1 + ', namingStrategy=' + toString_0(this.sjy_1) + ', decodeEnumsCaseInsensitive=' + this.tjy_1 + ', ') + ('allowTrailingComma=' + this.ujy_1 + ', allowComments=' + this.vjy_1 + ', classDiscriminatorMode=' + this.wjy_1.toString() + ')');
  }
}
class ClassDiscriminatorMode extends Enum {}
class JsonDecoder {}
class Companion {}
class JsonElement {}
class Companion_0 {}
class JsonArray extends JsonElement {
  constructor(content) {
    super();
    this.zjy_1 = content;
  }
  ajz(element) {
    return this.zjy_1.f3(element);
  }
  f3(element) {
    if (!(element instanceof JsonElement))
      return false;
    return this.ajz(element instanceof JsonElement ? element : THROW_CCE());
  }
  bjz(elements) {
    return this.zjy_1.g3(elements);
  }
  g3(elements) {
    return this.bjz(elements);
  }
  i1(index) {
    return this.zjy_1.i1(index);
  }
  cjz(element) {
    return this.zjy_1.q1(element);
  }
  q1(element) {
    if (!(element instanceof JsonElement))
      return -1;
    return this.cjz(element instanceof JsonElement ? element : THROW_CCE());
  }
  a1() {
    return this.zjy_1.a1();
  }
  f1() {
    return this.zjy_1.f1();
  }
  h3() {
    return this.zjy_1.h3();
  }
  p1(index) {
    return this.zjy_1.p1(index);
  }
  n1(fromIndex, toIndex) {
    return this.zjy_1.n1(fromIndex, toIndex);
  }
  j1() {
    return this.zjy_1.j1();
  }
  equals(other) {
    return equals(this.zjy_1, other);
  }
  hashCode() {
    return hashCode(this.zjy_1);
  }
  toString() {
    return joinToString(this.zjy_1, ',', '[', ']');
  }
}
class Companion_1 {}
class JsonObject extends JsonElement {
  constructor(content) {
    super();
    this.djz_1 = content;
  }
  c14(key) {
    return this.djz_1.i3(key);
  }
  i3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    return this.c14((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
  bj(key) {
    return this.djz_1.k3(key);
  }
  k3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.bj((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
  a1() {
    return this.djz_1.a1();
  }
  z1() {
    return this.djz_1.z1();
  }
  l3() {
    return this.djz_1.l3();
  }
  j1() {
    return this.djz_1.j1();
  }
  m3() {
    return this.djz_1.m3();
  }
  equals(other) {
    return equals(this.djz_1, other);
  }
  hashCode() {
    return hashCode(this.djz_1);
  }
  toString() {
    var tmp = this.djz_1.z1();
    return joinToString(tmp, ',', '{', '}', VOID, VOID, JsonObject$toString$lambda);
  }
}
class Companion_2 {}
class JsonPrimitive extends JsonElement {
  toString() {
    return this.fjz();
  }
}
class JsonNull extends JsonPrimitive {
  constructor() {
    JsonNull_instance = null;
    super();
    JsonNull_instance = this;
    this.gjz_1 = 'null';
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.hjz_1 = lazy(tmp_0, JsonNull$_anonymous__enib48);
  }
  ejz() {
    return false;
  }
  fjz() {
    return this.gjz_1;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class JsonLiteral extends JsonPrimitive {
  constructor(body, isString, coerceToInlineType) {
    coerceToInlineType = coerceToInlineType === VOID ? null : coerceToInlineType;
    super();
    this.jjz_1 = isString;
    this.kjz_1 = coerceToInlineType;
    this.ljz_1 = toString(body);
    if (!(this.kjz_1 == null)) {
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!this.kjz_1.zjh()) {
        // Inline function 'kotlin.require.<anonymous>' call
        var message = 'Failed requirement.';
        throw IllegalArgumentException.l(toString(message));
      }
    }
  }
  ejz() {
    return this.jjz_1;
  }
  fjz() {
    return this.ljz_1;
  }
  toString() {
    var tmp;
    if (this.jjz_1) {
      // Inline function 'kotlin.text.buildString' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.apply' call
      var this_0 = StringBuilder.h();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.serialization.json.JsonLiteral.toString.<anonymous>' call
      printQuoted(this_0, this.ljz_1);
      tmp = this_0.toString();
    } else {
      tmp = this.ljz_1;
    }
    return tmp;
  }
  equals(other) {
    if (this === other)
      return true;
    if (other == null || !getKClassFromExpression(this).equals(getKClassFromExpression(other)))
      return false;
    if (!(other instanceof JsonLiteral))
      THROW_CCE();
    if (!(this.jjz_1 === other.jjz_1))
      return false;
    if (!(this.ljz_1 === other.ljz_1))
      return false;
    return true;
  }
  hashCode() {
    var result = getBooleanHashCode(this.jjz_1);
    result = imul(31, result) + getStringHashCode(this.ljz_1) | 0;
    return result;
  }
}
class JsonElementSerializer {
  constructor() {
    JsonElementSerializer_instance = this;
    var tmp = this;
    var tmp_0 = SEALED_getInstance();
    tmp.sjz_1 = buildSerialDescriptor('kotlinx.serialization.json.JsonElement', tmp_0, [], JsonElementSerializer$descriptor$lambda);
  }
  ujf() {
    return this.sjz_1;
  }
  vjf(decoder) {
    var input = asJsonDecoder(decoder);
    return input.yjy();
  }
}
class JsonArrayDescriptor {
  constructor() {
    JsonArrayDescriptor_instance = this;
    this.tjz_1 = ListSerializer(JsonElementSerializer_getInstance()).ujf();
    this.ujz_1 = 'kotlinx.serialization.json.JsonArray';
  }
  tjh(index) {
    return this.tjz_1.tjh(index);
  }
  ujh(name) {
    return this.tjz_1.ujh(name);
  }
  vjh(index) {
    return this.tjz_1.vjh(index);
  }
  wjh(index) {
    return this.tjz_1.wjh(index);
  }
  xjh(index) {
    return this.tjz_1.xjh(index);
  }
  yjh() {
    return this.tjz_1.yjh();
  }
  ojh() {
    return this.tjz_1.ojh();
  }
  zjh() {
    return this.tjz_1.zjh();
  }
  aji() {
    return this.tjz_1.aji();
  }
  bji() {
    return this.tjz_1.bji();
  }
  sjh() {
    return this.ujz_1;
  }
}
class JsonArraySerializer {
  constructor() {
    JsonArraySerializer_instance = this;
    this.rjz_1 = JsonArrayDescriptor_getInstance();
  }
  ujf() {
    return this.rjz_1;
  }
  vjf(decoder) {
    verify(decoder);
    return new JsonArray(ListSerializer(JsonElementSerializer_getInstance()).vjf(decoder));
  }
}
class JsonObjectDescriptor {
  constructor() {
    JsonObjectDescriptor_instance = this;
    this.vjz_1 = MapSerializer(serializer(StringCompanionObject_instance), JsonElementSerializer_getInstance()).ujf();
    this.wjz_1 = 'kotlinx.serialization.json.JsonObject';
  }
  tjh(index) {
    return this.vjz_1.tjh(index);
  }
  ujh(name) {
    return this.vjz_1.ujh(name);
  }
  vjh(index) {
    return this.vjz_1.vjh(index);
  }
  wjh(index) {
    return this.vjz_1.wjh(index);
  }
  xjh(index) {
    return this.vjz_1.xjh(index);
  }
  yjh() {
    return this.vjz_1.yjh();
  }
  ojh() {
    return this.vjz_1.ojh();
  }
  zjh() {
    return this.vjz_1.zjh();
  }
  aji() {
    return this.vjz_1.aji();
  }
  bji() {
    return this.vjz_1.bji();
  }
  sjh() {
    return this.wjz_1;
  }
}
class JsonObjectSerializer {
  constructor() {
    JsonObjectSerializer_instance = this;
    this.qjz_1 = JsonObjectDescriptor_getInstance();
  }
  ujf() {
    return this.qjz_1;
  }
  vjf(decoder) {
    verify(decoder);
    return new JsonObject(MapSerializer(serializer(StringCompanionObject_instance), JsonElementSerializer_getInstance()).vjf(decoder));
  }
}
class JsonPrimitiveSerializer {
  constructor() {
    JsonPrimitiveSerializer_instance = this;
    this.njz_1 = buildSerialDescriptor('kotlinx.serialization.json.JsonPrimitive', STRING_getInstance(), []);
  }
  ujf() {
    return this.njz_1;
  }
  vjf(decoder) {
    var result = asJsonDecoder(decoder).yjy();
    if (!(result instanceof JsonPrimitive))
      throw JsonDecodingException_0(-1, 'Unexpected JSON element, expected JsonPrimitive, had ' + toString(getKClassFromExpression(result)), toString(result));
    return result;
  }
}
class JsonNullSerializer {
  constructor() {
    JsonNullSerializer_instance = this;
    this.ojz_1 = buildSerialDescriptor('kotlinx.serialization.json.JsonNull', ENUM_getInstance(), []);
  }
  ujf() {
    return this.ojz_1;
  }
  vjf(decoder) {
    verify(decoder);
    if (decoder.ajj()) {
      throw JsonDecodingException.dk0("Expected 'null' literal");
    }
    decoder.bjj();
    return JsonNull_getInstance();
  }
}
class JsonLiteralSerializer {
  constructor() {
    JsonLiteralSerializer_instance = this;
    this.pjz_1 = PrimitiveSerialDescriptor('kotlinx.serialization.json.JsonLiteral', STRING_getInstance());
  }
  ujf() {
    return this.pjz_1;
  }
  vjf(decoder) {
    var result = asJsonDecoder(decoder).yjy();
    if (!(result instanceof JsonLiteral))
      throw JsonDecodingException_0(-1, 'Unexpected JSON element, expected JsonLiteral, had ' + toString(getKClassFromExpression(result)), toString(result));
    return result;
  }
}
class defer$1 {
  constructor($deferred) {
    this.ek0_1 = lazy_0($deferred);
  }
  sjh() {
    return _get_original__l7ku1m(this).sjh();
  }
  yjh() {
    return _get_original__l7ku1m(this).yjh();
  }
  aji() {
    return _get_original__l7ku1m(this).aji();
  }
  tjh(index) {
    return _get_original__l7ku1m(this).tjh(index);
  }
  ujh(name) {
    return _get_original__l7ku1m(this).ujh(name);
  }
  vjh(index) {
    return _get_original__l7ku1m(this).vjh(index);
  }
  wjh(index) {
    return _get_original__l7ku1m(this).wjh(index);
  }
  xjh(index) {
    return _get_original__l7ku1m(this).xjh(index);
  }
}
class JsonElementMarker {
  constructor(descriptor) {
    var tmp = this;
    tmp.fk0_1 = new ElementMarker(descriptor, JsonElementMarker$readIfAbsent$ref(this));
    this.gk0_1 = false;
  }
  hk0(index) {
    this.fk0_1.yjn(index);
  }
  ik0() {
    return this.fk0_1.zjn();
  }
}
class JsonException extends SerializationException {
  static ok0(message) {
    var $this = this.sjg(message);
    captureStack($this, $this.nk0_1);
    return $this;
  }
}
class JsonDecodingException extends JsonException {
  static dk0(message) {
    var $this = this.ok0(message);
    captureStack($this, $this.ck0_1);
    return $this;
  }
}
class JsonEncodingException extends JsonException {
  static xk0(message) {
    var $this = this.ok0(message);
    captureStack($this, $this.wk0_1);
    return $this;
  }
}
class Tombstone {}
class JsonPath {
  constructor() {
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.ck1_1 = fillArrayVal(Array(8), null);
    var tmp_0 = this;
    var tmp_1 = 0;
    var tmp_2 = new Int32Array(8);
    while (tmp_1 < 8) {
      tmp_2[tmp_1] = -1;
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp_0.dk1_1 = tmp_2;
    this.ek1_1 = -1;
  }
  fk1(sd) {
    this.ek1_1 = this.ek1_1 + 1 | 0;
    var depth = this.ek1_1;
    if (depth === this.ck1_1.length) {
      resize(this);
    }
    this.ck1_1[depth] = sd;
  }
  gk1(index) {
    this.dk1_1[this.ek1_1] = index;
  }
  hk1(key) {
    var tmp;
    if (!(this.dk1_1[this.ek1_1] === -2)) {
      this.ek1_1 = this.ek1_1 + 1 | 0;
      tmp = this.ek1_1 === this.ck1_1.length;
    } else {
      tmp = false;
    }
    if (tmp) {
      resize(this);
    }
    this.ck1_1[this.ek1_1] = key;
    this.dk1_1[this.ek1_1] = -2;
  }
  ik1() {
    if (this.dk1_1[this.ek1_1] === -2) {
      this.ck1_1[this.ek1_1] = Tombstone_instance;
    }
  }
  jk1() {
    var depth = this.ek1_1;
    if (this.dk1_1[depth] === -2) {
      this.dk1_1[depth] = -1;
      this.ek1_1 = this.ek1_1 - 1 | 0;
    }
    if (!(this.ek1_1 === -1)) {
      this.ek1_1 = this.ek1_1 - 1 | 0;
    }
  }
  kk1() {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.serialization.json.internal.JsonPath.getPath.<anonymous>' call
    this_0.ic('$');
    // Inline function 'kotlin.repeat' call
    var times = this.ek1_1 + 1 | 0;
    // Inline function 'kotlin.contracts.contract' call
    var inductionVariable = 0;
    if (inductionVariable < times)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.serialization.json.internal.JsonPath.getPath.<anonymous>.<anonymous>' call
        var element = this.ck1_1[index];
        if (!(element == null) ? isInterface(element, SerialDescriptor) : false) {
          if (equals(element.yjh(), LIST_getInstance())) {
            if (!(this.dk1_1[index] === -1)) {
              this_0.ic('[');
              this_0.oh(this.dk1_1[index]);
              this_0.ic(']');
            }
          } else {
            var idx = this.dk1_1[index];
            if (idx >= 0) {
              this_0.ic('.');
              this_0.ic(element.tjh(idx));
            }
          }
        } else {
          if (!(element === Tombstone_instance)) {
            this_0.ic('[');
            this_0.ic("'");
            this_0.hc(element);
            this_0.ic("'");
            this_0.ic(']');
          }
        }
      }
       while (inductionVariable < times);
    return this_0.toString();
  }
  toString() {
    return this.kk1();
  }
}
class JsonTreeReader$readDeepRecursive$slambda {
  constructor(this$0) {
    this.pk1_1 = this$0;
  }
  wk1($this$DeepRecursiveFunction, it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$DeepRecursiveFunction, it, $completion), $completion);
  }
  re(p1, p2, $completion) {
    var tmp = p1 instanceof DeepRecursiveScope ? p1 : THROW_CCE();
    return this.wk1(tmp, p2 instanceof Unit ? p2 : THROW_CCE(), $completion);
  }
}
class JsonTreeReader {
  constructor(configuration, lexer) {
    this.lk1_1 = lexer;
    this.mk1_1 = configuration.ijy_1;
    this.nk1_1 = configuration.ujy_1;
    this.ok1_1 = 0;
  }
  p1v() {
    var token = this.lk1_1.qk1();
    var tmp;
    if (token === 1) {
      tmp = readValue(this, true);
    } else if (token === 0) {
      tmp = readValue(this, false);
    } else if (token === 6) {
      var tmp_0;
      this.ok1_1 = this.ok1_1 + 1 | 0;
      if (this.ok1_1 === 200) {
        tmp_0 = readDeepRecursive(this);
      } else {
        tmp_0 = readObject(this);
      }
      var result = tmp_0;
      this.ok1_1 = this.ok1_1 - 1 | 0;
      tmp = result;
    } else if (token === 8) {
      tmp = readArray(this);
    } else {
      this.lk1_1.qk0('Cannot read Json element because of unexpected ' + tokenDescription(token));
    }
    return tmp;
  }
}
class PolymorphismValidator {
  constructor(useArrayPolymorphism, discriminator) {
    this.yk1_1 = useArrayPolymorphism;
    this.zk1_1 = discriminator;
  }
  njw(kClass, provider) {
  }
  qjw(baseClass, actualClass, actualSerializer) {
    var descriptor = actualSerializer.ujf();
    checkKind(this, descriptor, actualClass);
    if (!this.yk1_1) {
      checkDiscriminatorCollisions(this, descriptor, actualClass);
    }
  }
  rjw(baseClass, defaultSerializerProvider) {
  }
  sjw(baseClass, defaultDeserializerProvider) {
  }
}
class Key {}
class DescriptorSchemaCache {
  constructor() {
    this.yk0_1 = createMapForCache(16);
  }
  ak2(descriptor, key, value) {
    // Inline function 'kotlin.collections.set' call
    // Inline function 'kotlin.collections.getOrPut' call
    var this_0 = this.yk0_1;
    var value_0 = this_0.k3(descriptor);
    var tmp;
    if (value_0 == null) {
      // Inline function 'kotlinx.serialization.json.internal.DescriptorSchemaCache.set.<anonymous>' call
      var answer = createMapForCache(2);
      this_0.r3(descriptor, answer);
      tmp = answer;
    } else {
      tmp = value_0;
    }
    var this_1 = tmp;
    var key_0 = key instanceof Key ? key : THROW_CCE();
    var value_1 = !(value == null) ? value : THROW_CCE();
    this_1.r3(key_0, value_1);
  }
  zk0(descriptor, key, defaultValue) {
    var tmp18_safe_receiver = this.bk2(descriptor, key);
    if (tmp18_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      return tmp18_safe_receiver;
    }
    var value = defaultValue();
    this.ak2(descriptor, key, value);
    return value;
  }
  bk2(descriptor, key) {
    var tmp19_safe_receiver = this.yk0_1.k3(descriptor);
    var tmp;
    if (tmp19_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = tmp19_safe_receiver.k3(key instanceof Key ? key : THROW_CCE());
    }
    var tmp_0 = tmp;
    return !(tmp_0 == null) ? tmp_0 : null;
  }
}
class DiscriminatorHolder {
  constructor(discriminatorToSkip) {
    this.ck2_1 = discriminatorToSkip;
  }
}
class StreamingJsonDecoder extends AbstractDecoder {
  constructor(json, mode, lexer, descriptor, discriminatorHolder) {
    super();
    this.ajx_1 = json;
    this.bjx_1 = mode;
    this.cjx_1 = lexer;
    this.djx_1 = this.ajx_1.ejk();
    this.ejx_1 = -1;
    this.fjx_1 = discriminatorHolder;
    this.gjx_1 = this.ajx_1.wjw_1;
    this.hjx_1 = this.gjx_1.ljy_1 ? null : new JsonElementMarker(descriptor);
  }
  xjy() {
    return this.ajx_1;
  }
  ejk() {
    return this.djx_1;
  }
  yjy() {
    return (new JsonTreeReader(this.ajx_1.wjw_1, this.cjx_1)).p1v();
  }
  ojj(deserializer) {
    try {
      var tmp;
      if (!(deserializer instanceof AbstractPolymorphicSerializer)) {
        tmp = true;
      } else {
        tmp = this.ajx_1.wjw_1.ojy_1;
      }
      if (tmp) {
        return deserializer.vjf(this);
      }
      var discriminator = classDiscriminator(deserializer.ujf(), this.ajx_1);
      var tmp0_elvis_lhs = this.cjx_1.lk2(discriminator, this.gjx_1.ijy_1);
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        var tmp$ret$0;
        $l$block: {
          // Inline function 'kotlinx.serialization.json.internal.decodeSerializableValuePolymorphic' call
          var deserializer_0 = isInterface(deserializer, DeserializationStrategy) ? deserializer : THROW_CCE();
          var tmp_1;
          if (!(deserializer_0 instanceof AbstractPolymorphicSerializer)) {
            tmp_1 = true;
          } else {
            tmp_1 = this.xjy().wjw_1.ojy_1;
          }
          if (tmp_1) {
            tmp$ret$0 = deserializer_0.vjf(this);
            break $l$block;
          }
          var discriminator_0 = classDiscriminator(deserializer_0.ujf(), this.xjy());
          // Inline function 'kotlinx.serialization.json.internal.cast' call
          var value = this.yjy();
          var serialName = deserializer_0.ujf().sjh();
          if (!(value instanceof JsonObject)) {
            var tmp_2 = getKClass(JsonObject).xf();
            var tmp_3 = getKClassFromExpression(value).xf();
            // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeSerializableValue.<anonymous>' call
            var tmp$ret$1 = this.cjx_1.jjx_1.kk1();
            throw JsonDecodingException_0(-1, 'Expected ' + tmp_2 + ', but had ' + tmp_3 + ' as the serialized body of ' + serialName + ' at element: ' + tmp$ret$1, toString(value));
          }
          var jsonTree = value;
          var tmp16_safe_receiver = jsonTree.bj(discriminator_0);
          var tmp17_safe_receiver = tmp16_safe_receiver == null ? null : get_jsonPrimitive(tmp16_safe_receiver);
          var type = tmp17_safe_receiver == null ? null : get_contentOrNull(tmp17_safe_receiver);
          var tmp_4;
          try {
            tmp_4 = findPolymorphicSerializer(deserializer_0, this, type);
          } catch ($p) {
            var tmp_5;
            if ($p instanceof SerializationException) {
              var it = $p;
              throw JsonDecodingException_0(-1, ensureNotNull(it.message), jsonTree.toString());
            } else {
              throw $p;
            }
          }
          var tmp_6 = tmp_4;
          var actualSerializer = isInterface(tmp_6, DeserializationStrategy) ? tmp_6 : THROW_CCE();
          tmp$ret$0 = readPolymorphicJson(this.xjy(), discriminator_0, jsonTree, actualSerializer);
        }
        return tmp$ret$0;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var type_0 = tmp_0;
      var tmp_7;
      try {
        tmp_7 = findPolymorphicSerializer(deserializer, this, type_0);
      } catch ($p) {
        var tmp_8;
        if ($p instanceof SerializationException) {
          var it_0 = $p;
          var message = removeSuffix(substringBefore(ensureNotNull(it_0.message), _Char___init__impl__6a9atx(10)), '.');
          var hint = substringAfter(ensureNotNull(it_0.message), _Char___init__impl__6a9atx(10), '');
          this.cjx_1.qk0(message, VOID, hint);
        } else {
          throw $p;
        }
        tmp_7 = tmp_8;
      }
      var tmp_9 = tmp_7;
      var actualSerializer_0 = isInterface(tmp_9, DeserializationStrategy) ? tmp_9 : THROW_CCE();
      this.fjx_1 = new DiscriminatorHolder(discriminator);
      return actualSerializer_0.vjf(this);
    } catch ($p) {
      if ($p instanceof MissingFieldException) {
        var e = $p;
        if (contains_0(ensureNotNull(e.message), 'at path'))
          throw e;
        throw MissingFieldException.hjh(e.fjh_1, plus(e.message, ' at path: ') + this.cjx_1.jjx_1.kk1(), e);
      } else {
        throw $p;
      }
    }
  }
  pjj(descriptor) {
    var newMode = switchMode(this.ajx_1, descriptor);
    this.cjx_1.jjx_1.fk1(descriptor);
    this.cjx_1.dk2(newMode.ok2_1);
    checkLeadingComma(this);
    var tmp;
    switch (newMode.v3_1) {
      case 1:
      case 2:
      case 3:
        tmp = new StreamingJsonDecoder(this.ajx_1, newMode, this.cjx_1, descriptor, this.fjx_1);
        break;
      default:
        var tmp_0;
        if (this.bjx_1.equals(newMode) && this.ajx_1.wjw_1.ljy_1) {
          tmp_0 = this;
        } else {
          tmp_0 = new StreamingJsonDecoder(this.ajx_1, newMode, this.cjx_1, descriptor, this.fjx_1);
        }

        tmp = tmp_0;
        break;
    }
    return tmp;
  }
  qjj(descriptor) {
    if (this.ajx_1.wjw_1.hjy_1 && descriptor.aji() === 0) {
      skipLeftoverElements(this, descriptor);
    }
    if (this.cjx_1.ek2() && !this.ajx_1.wjw_1.ujy_1) {
      invalidTrailingComma(this.cjx_1, '');
    }
    this.cjx_1.dk2(this.bjx_1.pk2_1);
    this.cjx_1.jjx_1.jk1();
  }
  ajj() {
    var tmp;
    var tmp21_safe_receiver = this.hjx_1;
    var tmp0_elvis_lhs = tmp21_safe_receiver == null ? null : tmp21_safe_receiver.gk0_1;
    if (!(tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs)) {
      tmp = !this.cjx_1.qk2();
    } else {
      tmp = false;
    }
    return tmp;
  }
  bjj() {
    return null;
  }
  bjk(descriptor, index, deserializer, previousValue) {
    var isMapKey = this.bjx_1.equals(WriteMode_MAP_getInstance()) && (index & 1) === 0;
    if (isMapKey) {
      this.cjx_1.jjx_1.ik1();
    }
    var value = super.bjk(descriptor, index, deserializer, previousValue);
    if (isMapKey) {
      this.cjx_1.jjx_1.hk1(value);
    }
    return value;
  }
  gjk(descriptor) {
    var index;
    switch (this.bjx_1.v3_1) {
      case 0:
        index = decodeObjectIndex(this, descriptor);
        break;
      case 2:
        index = decodeMapIndex(this);
        break;
      default:
        index = decodeListIndex(this);
        break;
    }
    if (!this.bjx_1.equals(WriteMode_MAP_getInstance())) {
      this.cjx_1.jjx_1.gk1(index);
    }
    return index;
  }
  cjj() {
    return this.cjx_1.rk2();
  }
  djj() {
    var value = this.cjx_1.mjz();
    if (!value.equals(toLong(value.q4()))) {
      this.cjx_1.qk0("Failed to parse byte for input '" + value.toString() + "'");
    }
    return value.q4();
  }
  ejj() {
    var value = this.cjx_1.mjz();
    if (!value.equals(toLong(value.r4()))) {
      this.cjx_1.qk0("Failed to parse short for input '" + value.toString() + "'");
    }
    return value.r4();
  }
  fjj() {
    var value = this.cjx_1.mjz();
    if (!value.equals(toLong(value.q2()))) {
      this.cjx_1.qk0("Failed to parse int for input '" + value.toString() + "'");
    }
    return value.q2();
  }
  gjj() {
    return this.cjx_1.mjz();
  }
  hjj() {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var this_0 = this.cjx_1;
      var input = this_0.uk1();
      try {
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeFloat.<anonymous>' call
        // Inline function 'kotlin.text.toFloat' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$4 = toDouble(input);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          this_0.qk0("Failed to parse type '" + 'float' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$4;
    var specialFp = this.ajx_1.wjw_1.qjy_1;
    if (specialFp || isFinite(result))
      return result;
    throwInvalidFloatingPointDecoded(this.cjx_1, result);
  }
  ijj() {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var this_0 = this.cjx_1;
      var input = this_0.uk1();
      try {
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeDouble.<anonymous>' call
        tmp$ret$1 = toDouble(input);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          this_0.qk0("Failed to parse type '" + 'double' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$1;
    var specialFp = this.ajx_1.wjw_1.qjy_1;
    if (specialFp || isFinite_0(result))
      return result;
    throwInvalidFloatingPointDecoded(this.cjx_1, result);
  }
  jjj() {
    var string = this.cjx_1.uk1();
    if (!(string.length === 1)) {
      this.cjx_1.qk0("Expected single char, but got '" + string + "'");
    }
    return charSequenceGet(string, 0);
  }
  kjj() {
    var tmp;
    if (this.gjx_1.ijy_1) {
      tmp = this.cjx_1.kk2();
    } else {
      tmp = this.cjx_1.tk1();
    }
    return tmp;
  }
  mjj(descriptor) {
    return get_isUnsignedNumber(descriptor) ? new JsonDecoderForUnsignedTypes(this.cjx_1, this.ajx_1) : super.mjj(descriptor);
  }
  ljj(enumDescriptor) {
    return getJsonNameIndexOrThrow(enumDescriptor, this.ajx_1, this.kjj(), ' at path ' + this.cjx_1.jjx_1.kk1());
  }
}
class JsonDecoderForUnsignedTypes extends AbstractDecoder {
  constructor(lexer, json) {
    super();
    this.sk2_1 = lexer;
    this.tk2_1 = json.ejk();
  }
  ejk() {
    return this.tk2_1;
  }
  gjk(descriptor) {
    var message = 'unsupported';
    throw IllegalStateException.m5(toString(message));
  }
  fjj() {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var this_0 = this.sk2_1;
      var input = this_0.uk1();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeInt.<anonymous>' call
        // Inline function 'kotlin.UInt.toInt' call
        var this_1 = toUInt(input);
        tmp$ret$2 = _UInt___get_data__impl__f0vqqw(this_1);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          this_0.qk0("Failed to parse type '" + 'UInt' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  }
  gjj() {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var this_0 = this.sk2_1;
      var input = this_0.uk1();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeLong.<anonymous>' call
        // Inline function 'kotlin.ULong.toLong' call
        var this_1 = toULong(input);
        tmp$ret$2 = _ULong___get_data__impl__fggpzb(this_1);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          this_0.qk0("Failed to parse type '" + 'ULong' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  }
  djj() {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var this_0 = this.sk2_1;
      var input = this_0.uk1();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeByte.<anonymous>' call
        // Inline function 'kotlin.UByte.toByte' call
        var this_1 = toUByte(input);
        tmp$ret$2 = _UByte___get_data__impl__jof9qr(this_1);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          this_0.qk0("Failed to parse type '" + 'UByte' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  }
  ejj() {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var this_0 = this.sk2_1;
      var input = this_0.uk1();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeShort.<anonymous>' call
        // Inline function 'kotlin.UShort.toShort' call
        var this_1 = toUShort(input);
        tmp$ret$2 = _UShort___get_data__impl__g0245(this_1);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          this_0.qk0("Failed to parse type '" + 'UShort' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  }
}
class AbstractJsonTreeDecoder extends NamedValueDecoder {
  constructor(json, value, polymorphicDiscriminator) {
    polymorphicDiscriminator = polymorphicDiscriminator === VOID ? null : polymorphicDiscriminator;
    super();
    this.wk2_1 = json;
    this.xk2_1 = value;
    this.yk2_1 = polymorphicDiscriminator;
    this.zk2_1 = this.xjy().wjw_1;
  }
  xjy() {
    return this.wk2_1;
  }
  b2() {
    return this.xk2_1;
  }
  ejk() {
    return this.xjy().ejk();
  }
  bk3() {
    var tmp28_safe_receiver = this.pju();
    var tmp;
    if (tmp28_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.currentObject.<anonymous>' call
      tmp = this.ck3(tmp28_safe_receiver);
    }
    var tmp0_elvis_lhs = tmp;
    return tmp0_elvis_lhs == null ? this.b2() : tmp0_elvis_lhs;
  }
  ak3(currentTag) {
    return this.rju() + ('.' + currentTag);
  }
  yjy() {
    return this.bk3();
  }
  ojj(deserializer) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.decodeSerializableValuePolymorphic' call
      var tmp;
      if (!(deserializer instanceof AbstractPolymorphicSerializer)) {
        tmp = true;
      } else {
        tmp = this.xjy().wjw_1.ojy_1;
      }
      if (tmp) {
        tmp$ret$0 = deserializer.vjf(this);
        break $l$block;
      }
      var discriminator = classDiscriminator(deserializer.ujf(), this.xjy());
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.yjy();
      var serialName = deserializer.ujf().sjh();
      if (!(value instanceof JsonObject)) {
        var tmp_0 = getKClass(JsonObject).xf();
        var tmp_1 = getKClassFromExpression(value).xf();
        var tmp$ret$1 = this.rju();
        throw JsonDecodingException_0(-1, 'Expected ' + tmp_0 + ', but had ' + tmp_1 + ' as the serialized body of ' + serialName + ' at element: ' + tmp$ret$1, toString(value));
      }
      var jsonTree = value;
      var tmp16_safe_receiver = jsonTree.bj(discriminator);
      var tmp17_safe_receiver = tmp16_safe_receiver == null ? null : get_jsonPrimitive(tmp16_safe_receiver);
      var type = tmp17_safe_receiver == null ? null : get_contentOrNull(tmp17_safe_receiver);
      var tmp_2;
      try {
        tmp_2 = findPolymorphicSerializer(deserializer, this, type);
      } catch ($p) {
        var tmp_3;
        if ($p instanceof SerializationException) {
          var it = $p;
          throw JsonDecodingException_0(-1, ensureNotNull(it.message), jsonTree.toString());
        } else {
          throw $p;
        }
      }
      var tmp_4 = tmp_2;
      var actualSerializer = isInterface(tmp_4, DeserializationStrategy) ? tmp_4 : THROW_CCE();
      tmp$ret$0 = readPolymorphicJson(this.xjy(), discriminator, jsonTree, actualSerializer);
    }
    return tmp$ret$0;
  }
  qju(parentName, childName) {
    return childName;
  }
  pjj(descriptor) {
    var currentObject = this.bk3();
    var tmp29_subject = descriptor.yjh();
    var tmp;
    var tmp_0;
    if (equals(tmp29_subject, LIST_getInstance())) {
      tmp_0 = true;
    } else {
      tmp_0 = tmp29_subject instanceof PolymorphicKind;
    }
    if (tmp_0) {
      var tmp_1 = this.xjy();
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var serialName = descriptor.sjh();
      if (!(currentObject instanceof JsonArray)) {
        var tmp_2 = getKClass(JsonArray).xf();
        var tmp_3 = getKClassFromExpression(currentObject).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.rju();
        throw JsonDecodingException_0(-1, 'Expected ' + tmp_2 + ', but had ' + tmp_3 + ' as the serialized body of ' + serialName + ' at element: ' + tmp$ret$0, toString(currentObject));
      }
      tmp = new JsonTreeListDecoder(tmp_1, currentObject);
    } else {
      if (equals(tmp29_subject, MAP_getInstance())) {
        // Inline function 'kotlinx.serialization.json.internal.selectMapMode' call
        var this_0 = this.xjy();
        var keyDescriptor = carrierDescriptor(descriptor.wjh(0), this_0.ejk());
        var keyKind = keyDescriptor.yjh();
        var tmp_4;
        var tmp_5;
        if (keyKind instanceof PrimitiveKind) {
          tmp_5 = true;
        } else {
          tmp_5 = equals(keyKind, ENUM_getInstance());
        }
        if (tmp_5) {
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.beginStructure.<anonymous>' call
          var tmp_6 = this.xjy();
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
          // Inline function 'kotlinx.serialization.json.internal.cast' call
          var serialName_0 = descriptor.sjh();
          if (!(currentObject instanceof JsonObject)) {
            var tmp_7 = getKClass(JsonObject).xf();
            var tmp_8 = getKClassFromExpression(currentObject).xf();
            // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
            var tmp$ret$3 = this.rju();
            throw JsonDecodingException_0(-1, 'Expected ' + tmp_7 + ', but had ' + tmp_8 + ' as the serialized body of ' + serialName_0 + ' at element: ' + tmp$ret$3, toString(currentObject));
          }
          tmp_4 = new JsonTreeMapDecoder(tmp_6, currentObject);
        } else {
          if (this_0.wjw_1.jjy_1) {
            // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.beginStructure.<anonymous>' call
            var tmp_9 = this.xjy();
            // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
            // Inline function 'kotlinx.serialization.json.internal.cast' call
            var serialName_1 = descriptor.sjh();
            if (!(currentObject instanceof JsonArray)) {
              var tmp_10 = getKClass(JsonArray).xf();
              var tmp_11 = getKClassFromExpression(currentObject).xf();
              // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
              var tmp$ret$7 = this.rju();
              throw JsonDecodingException_0(-1, 'Expected ' + tmp_10 + ', but had ' + tmp_11 + ' as the serialized body of ' + serialName_1 + ' at element: ' + tmp$ret$7, toString(currentObject));
            }
            tmp_4 = new JsonTreeListDecoder(tmp_9, currentObject);
          } else {
            throw InvalidKeyKindException(keyDescriptor);
          }
        }
        tmp = tmp_4;
      } else {
        var tmp_12 = this.xjy();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
        // Inline function 'kotlinx.serialization.json.internal.cast' call
        var serialName_2 = descriptor.sjh();
        if (!(currentObject instanceof JsonObject)) {
          var tmp_13 = getKClass(JsonObject).xf();
          var tmp_14 = getKClassFromExpression(currentObject).xf();
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
          var tmp$ret$12 = this.rju();
          throw JsonDecodingException_0(-1, 'Expected ' + tmp_13 + ', but had ' + tmp_14 + ' as the serialized body of ' + serialName_2 + ' at element: ' + tmp$ret$12, toString(currentObject));
        }
        tmp = new JsonTreeDecoder(tmp_12, currentObject, this.yk2_1);
      }
    }
    return tmp;
  }
  qjj(descriptor) {
  }
  ajj() {
    var tmp = this.bk3();
    return !(tmp instanceof JsonNull);
  }
  dk3(tag, enumDescriptor) {
    var tmp = this.xjy();
    // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
    // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
    var value = this.ck3(tag);
    // Inline function 'kotlinx.serialization.json.internal.cast' call
    var serialName = enumDescriptor.sjh();
    if (!(value instanceof JsonPrimitive)) {
      var tmp_0 = getKClass(JsonPrimitive).xf();
      var tmp_1 = getKClassFromExpression(value).xf();
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
      var tmp$ret$0 = this.ak3(tag);
      throw JsonDecodingException_0(-1, 'Expected ' + tmp_0 + ', but had ' + tmp_1 + ' as the serialized body of ' + serialName + ' at element: ' + tmp$ret$0, toString(value));
    }
    return getJsonNameIndexOrThrow(enumDescriptor, tmp, value.fjz());
  }
  djv(tag, enumDescriptor) {
    return this.dk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE(), enumDescriptor);
  }
  ek3(tag) {
    return !(this.ck3(tag) === JsonNull_getInstance());
  }
  tju(tag) {
    return this.ek3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  fk3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'boolean' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        var tmp0_elvis_lhs = get_booleanOrNull(literal);
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'boolean', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'boolean', tag);
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$4;
  }
  uju(tag) {
    return this.fk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  gk3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'byte' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedByte.<anonymous>' call
        var result = get_int(literal);
        var tmp0_elvis_lhs = (-128 <= result ? result <= 127 : false) ? toByte(result) : null;
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'byte', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'byte', tag);
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$4;
  }
  vju(tag) {
    return this.gk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  hk3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'short' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedShort.<anonymous>' call
        var result = get_int(literal);
        var tmp0_elvis_lhs = (-32768 <= result ? result <= 32767 : false) ? toShort(result) : null;
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'short', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'short', tag);
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$4;
  }
  wju(tag) {
    return this.hk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  ik3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'int' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedInt.<anonymous>' call
        var tmp0_elvis_lhs = get_int(literal);
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'int', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'int', tag);
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$4;
  }
  xju(tag) {
    return this.ik3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  jk3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'long' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedLong.<anonymous>' call
        var tmp0_elvis_lhs = get_long(literal);
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'long', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'long', tag);
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$4;
  }
  yju(tag) {
    return this.jk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  kk3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'float' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedFloat.<anonymous>' call
        var tmp0_elvis_lhs = get_float(literal);
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'float', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'float', tag);
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$4;
    var specialFp = this.xjy().wjw_1.qjy_1;
    if (specialFp || isFinite(result))
      return result;
    throw InvalidFloatingPointDecoded(result, tag, toString(this.bk3()));
  }
  zju(tag) {
    return this.kk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  lk3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'double' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedDouble.<anonymous>' call
        var tmp0_elvis_lhs = get_double(literal);
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'double', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'double', tag);
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$4;
    var specialFp = this.xjy().wjw_1.qjy_1;
    if (specialFp || isFinite_0(result))
      return result;
    throw InvalidFloatingPointDecoded(result, tag, toString(this.bk3()));
  }
  ajv(tag) {
    return this.lk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  mk3(tag) {
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var value = this.ck3(tag);
      if (!(value instanceof JsonPrimitive)) {
        var tmp = getKClass(JsonPrimitive).xf();
        var tmp_0 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'char' + ' at element: ' + tmp$ret$0, toString(value));
      }
      var literal = value;
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedChar.<anonymous>' call
        var tmp0_elvis_lhs = new Char(single(literal.fjz()));
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, literal, 'char', tag);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$4 = tmp_1.t2_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, literal, 'char', tag);
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$4;
  }
  bjv(tag) {
    return this.mk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  nk3(tag) {
    // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
    // Inline function 'kotlinx.serialization.json.internal.cast' call
    var value = this.ck3(tag);
    if (!(value instanceof JsonPrimitive)) {
      var tmp = getKClass(JsonPrimitive).xf();
      var tmp_0 = getKClassFromExpression(value).xf();
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
      var tmp$ret$0 = this.ak3(tag);
      throw JsonDecodingException_0(-1, 'Expected ' + tmp + ', but had ' + tmp_0 + ' as the serialized body of ' + 'string' + ' at element: ' + tmp$ret$0, toString(value));
    }
    var value_0 = value;
    if (!(value_0 instanceof JsonLiteral))
      throw JsonDecodingException_0(-1, "Expected string value for a non-null key '" + tag + "', got null literal instead at element: " + this.ak3(tag), toString(this.bk3()));
    if (!value_0.jjz_1 && !this.xjy().wjw_1.ijy_1) {
      throw JsonDecodingException_0(-1, "String literal for key '" + tag + "' should be quoted at element: " + this.ak3(tag) + ".\nUse 'isLenient = true' in 'Json {}' builder to accept non-compliant JSON.", toString(this.bk3()));
    }
    return value_0.ljz_1;
  }
  cjv(tag) {
    return this.nk3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  }
  ok3(tag, inlineDescriptor) {
    var tmp;
    if (get_isUnsignedNumber(inlineDescriptor)) {
      var tmp_0 = this.xjy();
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.getPrimitiveValue' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      var value = this.ck3(tag);
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var serialName = inlineDescriptor.sjh();
      if (!(value instanceof JsonPrimitive)) {
        var tmp_1 = getKClass(JsonPrimitive).xf();
        var tmp_2 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.ak3(tag);
        throw JsonDecodingException_0(-1, 'Expected ' + tmp_1 + ', but had ' + tmp_2 + ' as the serialized body of ' + serialName + ' at element: ' + tmp$ret$0, toString(value));
      }
      var lexer = StringJsonLexer_0(tmp_0, value.fjz());
      tmp = new JsonDecoderForUnsignedTypes(lexer, this.xjy());
    } else {
      tmp = super.ejv(tag, inlineDescriptor);
    }
    return tmp;
  }
  ejv(tag, inlineDescriptor) {
    return this.ok3((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE(), inlineDescriptor);
  }
  mjj(descriptor) {
    return !(this.pju() == null) ? super.mjj(descriptor) : (new JsonPrimitiveDecoder(this.xjy(), this.b2(), this.yk2_1)).mjj(descriptor);
  }
}
class JsonTreeDecoder extends AbstractJsonTreeDecoder {
  constructor(json, value, polymorphicDiscriminator, polyDescriptor) {
    polymorphicDiscriminator = polymorphicDiscriminator === VOID ? null : polymorphicDiscriminator;
    polyDescriptor = polyDescriptor === VOID ? null : polyDescriptor;
    super(json, value, polymorphicDiscriminator);
    this.vk3_1 = value;
    this.wk3_1 = polyDescriptor;
    this.xk3_1 = 0;
    this.yk3_1 = false;
  }
  b2() {
    return this.vk3_1;
  }
  gjk(descriptor) {
    while (this.xk3_1 < descriptor.aji()) {
      var _unary__edvuaz = this.xk3_1;
      this.xk3_1 = _unary__edvuaz + 1 | 0;
      var name = this.kju(descriptor, _unary__edvuaz);
      var index = this.xk3_1 - 1 | 0;
      this.yk3_1 = false;
      var tmp;
      var tmp_0;
      // Inline function 'kotlin.collections.contains' call
      // Inline function 'kotlin.collections.containsKey' call
      var this_0 = this.b2();
      if ((isInterface(this_0, KtMap) ? this_0 : THROW_CCE()).i3(name)) {
        tmp_0 = true;
      } else {
        tmp_0 = absenceIsNull(this, descriptor, index);
      }
      if (tmp_0) {
        tmp = !this.zk2_1.njy_1 || !coerceInputValue_0(this, descriptor, index, name);
      } else {
        tmp = false;
      }
      if (tmp) {
        return index;
      }
    }
    return -1;
  }
  ajj() {
    return !this.yk3_1 && super.ajj();
  }
  lju(descriptor, index) {
    var strategy = namingStrategy(descriptor, this.xjy());
    var baseName = descriptor.tjh(index);
    if (strategy == null) {
      if (!this.zk2_1.rjy_1)
        return baseName;
      if (this.b2().l3().f3(baseName))
        return baseName;
    }
    var deserializationNamesMap_0 = deserializationNamesMap(this.xjy(), descriptor);
    // Inline function 'kotlin.collections.find' call
    var this_0 = this.b2().l3();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.elementName.<anonymous>' call
        if (deserializationNamesMap_0.k3(element) === index) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    var tmp31_safe_receiver = tmp$ret$1;
    if (tmp31_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      return tmp31_safe_receiver;
    }
    var fallbackName = strategy == null ? null : strategy.bk1(descriptor, index, baseName);
    return fallbackName == null ? baseName : fallbackName;
  }
  ck3(tag) {
    return getValue(this.b2(), tag);
  }
  pjj(descriptor) {
    if (descriptor === this.wk3_1) {
      var tmp = this.xjy();
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast' call
      var value = this.bk3();
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var serialName = this.wk3_1.sjh();
      if (!(value instanceof JsonObject)) {
        var tmp_0 = getKClass(JsonObject).xf();
        var tmp_1 = getKClassFromExpression(value).xf();
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.cast.<anonymous>' call
        var tmp$ret$0 = this.rju();
        throw JsonDecodingException_0(-1, 'Expected ' + tmp_0 + ', but had ' + tmp_1 + ' as the serialized body of ' + serialName + ' at element: ' + tmp$ret$0, toString(value));
      }
      return new JsonTreeDecoder(tmp, value, this.yk2_1, this.wk3_1);
    }
    return super.pjj(descriptor);
  }
  qjj(descriptor) {
    var tmp;
    if (this.zk2_1.hjy_1) {
      tmp = true;
    } else {
      var tmp_0 = descriptor.yjh();
      tmp = tmp_0 instanceof PolymorphicKind;
    }
    if (tmp)
      return Unit_instance;
    var strategy = namingStrategy(descriptor, this.xjy());
    var tmp_1;
    if (strategy == null && !this.zk2_1.rjy_1) {
      tmp_1 = jsonCachedSerialNames(descriptor);
    } else if (!(strategy == null)) {
      tmp_1 = deserializationNamesMap(this.xjy(), descriptor).l3();
    } else {
      var tmp_2 = jsonCachedSerialNames(descriptor);
      // Inline function 'kotlin.collections.orEmpty' call
      var tmp33_safe_receiver = get_schemaCache(this.xjy()).bk2(descriptor, get_JsonDeserializationNamesKey());
      var tmp0_elvis_lhs = tmp33_safe_receiver == null ? null : tmp33_safe_receiver.l3();
      var tmp$ret$0 = tmp0_elvis_lhs == null ? emptySet() : tmp0_elvis_lhs;
      tmp_1 = plus_0(tmp_2, tmp$ret$0);
    }
    var names = tmp_1;
    var _iterator__ex2g4s = this.b2().l3().f1();
    while (_iterator__ex2g4s.g1()) {
      var key = _iterator__ex2g4s.h1();
      if (!names.f3(key) && !(key === this.yk2_1)) {
        throw UnknownKeyException(key, this.b2().toString());
      }
    }
  }
}
class JsonTreeListDecoder extends AbstractJsonTreeDecoder {
  constructor(json, value) {
    super(json, value);
    this.fk4_1 = value;
    this.gk4_1 = this.fk4_1.j1();
    this.hk4_1 = -1;
  }
  b2() {
    return this.fk4_1;
  }
  lju(descriptor, index) {
    return index.toString();
  }
  ck3(tag) {
    return this.fk4_1.i1(toInt(tag));
  }
  gjk(descriptor) {
    while (this.hk4_1 < (this.gk4_1 - 1 | 0)) {
      this.hk4_1 = this.hk4_1 + 1 | 0;
      return this.hk4_1;
    }
    return -1;
  }
}
class JsonPrimitiveDecoder extends AbstractJsonTreeDecoder {
  constructor(json, value, polymorphicDiscriminator) {
    polymorphicDiscriminator = polymorphicDiscriminator === VOID ? null : polymorphicDiscriminator;
    super(json, value, polymorphicDiscriminator);
    this.ok4_1 = value;
    this.fjv('primitive');
  }
  b2() {
    return this.ok4_1;
  }
  gjk(descriptor) {
    return 0;
  }
  ck3(tag) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(tag === 'primitive')) {
      // Inline function 'kotlinx.serialization.json.internal.JsonPrimitiveDecoder.currentElement.<anonymous>' call
      var message = "This input can only handle primitives with 'primitive' tag";
      throw IllegalArgumentException.l(toString(message));
    }
    return this.ok4_1;
  }
}
class JsonTreeMapDecoder extends JsonTreeDecoder {
  constructor(json, value) {
    super(json, value);
    this.zk4_1 = value;
    this.ak5_1 = toList(this.zk4_1.l3());
    this.bk5_1 = imul(this.ak5_1.j1(), 2);
    this.ck5_1 = -1;
  }
  b2() {
    return this.zk4_1;
  }
  lju(descriptor, index) {
    var i = index / 2 | 0;
    return this.ak5_1.i1(i);
  }
  gjk(descriptor) {
    while (this.ck5_1 < (this.bk5_1 - 1 | 0)) {
      this.ck5_1 = this.ck5_1 + 1 | 0;
      return this.ck5_1;
    }
    return -1;
  }
  ck3(tag) {
    return (this.ck5_1 % 2 | 0) === 0 ? JsonPrimitive_0(tag) : getValue(this.zk4_1, tag);
  }
  qjj(descriptor) {
  }
}
class WriteMode extends Enum {
  constructor(name, ordinal, begin, end) {
    super(name, ordinal);
    this.ok2_1 = begin;
    this.pk2_1 = end;
  }
}
class AbstractJsonLexer {
  constructor() {
    this.ijx_1 = 0;
    this.jjx_1 = new JsonPath();
    this.kjx_1 = null;
    this.ljx_1 = StringBuilder.h();
  }
  gk5() {
  }
  ek2() {
    var current = this.hk5();
    var source = this.ek5();
    if (current >= charSequenceLength(source) || current === -1)
      return false;
    if (charSequenceGet(source, current) === _Char___init__impl__6a9atx(44)) {
      this.ijx_1 = this.ijx_1 + 1 | 0;
      return true;
    }
    return false;
  }
  ik5(c) {
    return c === _Char___init__impl__6a9atx(125) || c === _Char___init__impl__6a9atx(93) || (c === _Char___init__impl__6a9atx(58) || c === _Char___init__impl__6a9atx(44)) ? false : true;
  }
  mjx() {
    var nextToken = this.vk1();
    if (!(nextToken === 10)) {
      this.qk0('Expected EOF after parsing, but had ' + toString_1(charSequenceGet(this.ek5(), this.ijx_1 - 1 | 0)) + ' instead');
    }
  }
  rk1(expected) {
    var token = this.vk1();
    if (!(token === expected)) {
      this.jk5(expected);
    }
    return token;
  }
  kk5(expected) {
    if (this.ijx_1 > 0 && expected === _Char___init__impl__6a9atx(34)) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.withPositionRollback' call
        var snapshot = this.ijx_1;
        try {
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.unexpectedToken.<anonymous>' call
          this.ijx_1 = this.ijx_1 - 1 | 0;
          tmp$ret$1 = this.uk1();
          break $l$block;
        }finally {
          this.ijx_1 = snapshot;
        }
      }
      var inputLiteral = tmp$ret$1;
      if (inputLiteral === 'null') {
        this.pk0("Expected string literal but 'null' literal was found", this.ijx_1 - 1 | 0, "Use 'coerceInputValues = true' in 'Json {}' builder to coerce nulls if property has a default value.");
      }
    }
    this.jk5(charToTokenClass(expected));
  }
  lk5(expectedToken, wasConsumed) {
    var expected = tokenDescription(expectedToken);
    var position = wasConsumed ? this.ijx_1 - 1 | 0 : this.ijx_1;
    var s = this.ijx_1 === charSequenceLength(this.ek5()) || position < 0 ? 'EOF' : toString_1(charSequenceGet(this.ek5(), position));
    this.qk0('Expected ' + expected + ", but had '" + s + "' instead", position);
  }
  jk5(expectedToken, wasConsumed, $super) {
    wasConsumed = wasConsumed === VOID ? true : wasConsumed;
    return $super === VOID ? this.lk5(expectedToken, wasConsumed) : $super.lk5.call(this, expectedToken, wasConsumed);
  }
  qk1() {
    var source = this.ek5();
    var cpos = this.ijx_1;
    $l$loop_0: while (true) {
      cpos = this.fk5(cpos);
      if (cpos === -1)
        break $l$loop_0;
      var ch = charSequenceGet(source, cpos);
      if (ch === _Char___init__impl__6a9atx(32) || ch === _Char___init__impl__6a9atx(10) || ch === _Char___init__impl__6a9atx(13) || ch === _Char___init__impl__6a9atx(9)) {
        cpos = cpos + 1 | 0;
        continue $l$loop_0;
      }
      this.ijx_1 = cpos;
      return charToTokenClass(ch);
    }
    this.ijx_1 = cpos;
    return 10;
  }
  fk2(doConsume) {
    var current = this.hk5();
    current = this.fk5(current);
    var len = charSequenceLength(this.ek5()) - current | 0;
    if (len < 4 || current === -1)
      return false;
    var inductionVariable = 0;
    if (inductionVariable <= 3)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(charSequenceGet('null', i) === charSequenceGet(this.ek5(), current + i | 0)))
          return false;
      }
       while (inductionVariable <= 3);
    if (len > 4 && charToTokenClass(charSequenceGet(this.ek5(), current + 4 | 0)) === 0)
      return false;
    if (doConsume) {
      this.ijx_1 = current + 4 | 0;
    }
    return true;
  }
  qk2(doConsume, $super) {
    doConsume = doConsume === VOID ? true : doConsume;
    return $super === VOID ? this.fk2(doConsume) : $super.fk2.call(this, doConsume);
  }
  gk2(isLenient) {
    var token = this.qk1();
    var tmp;
    if (isLenient) {
      if (!(token === 1) && !(token === 0))
        return null;
      tmp = this.uk1();
    } else {
      if (!(token === 1))
        return null;
      tmp = this.tk1();
    }
    var string = tmp;
    this.kjx_1 = string;
    return string;
  }
  mk5() {
    this.kjx_1 = null;
  }
  uh(startPos, endPos) {
    // Inline function 'kotlin.text.substring' call
    var this_0 = this.ek5();
    return toString(charSequenceSubSequence(this_0, startPos, endPos));
  }
  tk1() {
    if (!(this.kjx_1 == null)) {
      return takePeeked(this);
    }
    return this.jk2();
  }
  consumeString2(source, startPosition, current) {
    var currentPosition = current;
    var lastPosition = startPosition;
    var char = charSequenceGet(source, currentPosition);
    var usedAppend = false;
    while (!(char === _Char___init__impl__6a9atx(34))) {
      if (char === _Char___init__impl__6a9atx(92)) {
        usedAppend = true;
        currentPosition = this.fk5(appendEscape(this, lastPosition, currentPosition));
        if (currentPosition === -1) {
          this.qk0('Unexpected EOF', currentPosition);
        }
        lastPosition = currentPosition;
      } else {
        currentPosition = currentPosition + 1 | 0;
        if (currentPosition >= charSequenceLength(source)) {
          usedAppend = true;
          this.dk5(lastPosition, currentPosition);
          currentPosition = this.fk5(currentPosition);
          if (currentPosition === -1) {
            this.qk0('Unexpected EOF', currentPosition);
          }
          lastPosition = currentPosition;
        }
      }
      char = charSequenceGet(source, currentPosition);
    }
    var tmp;
    if (!usedAppend) {
      tmp = this.uh(lastPosition, currentPosition);
    } else {
      tmp = decodedString(this, lastPosition, currentPosition);
    }
    var string = tmp;
    this.ijx_1 = currentPosition + 1 | 0;
    return string;
  }
  kk2() {
    var result = this.uk1();
    if (result === 'null' && wasUnquotedString(this)) {
      this.qk0("Unexpected 'null' value instead of string literal");
    }
    return result;
  }
  uk1() {
    if (!(this.kjx_1 == null)) {
      return takePeeked(this);
    }
    var current = this.hk5();
    if (current >= charSequenceLength(this.ek5()) || current === -1) {
      this.qk0('EOF', current);
    }
    var token = charToTokenClass(charSequenceGet(this.ek5(), current));
    if (token === 1) {
      return this.tk1();
    }
    if (!(token === 0)) {
      this.qk0('Expected beginning of the string, but got ' + toString_1(charSequenceGet(this.ek5(), current)));
    }
    var usedAppend = false;
    while (charToTokenClass(charSequenceGet(this.ek5(), current)) === 0) {
      current = current + 1 | 0;
      if (current >= charSequenceLength(this.ek5())) {
        usedAppend = true;
        this.dk5(this.ijx_1, current);
        var eof = this.fk5(current);
        if (eof === -1) {
          this.ijx_1 = current;
          return decodedString(this, 0, 0);
        } else {
          current = eof;
        }
      }
    }
    var tmp;
    if (!usedAppend) {
      tmp = this.uh(this.ijx_1, current);
    } else {
      tmp = decodedString(this, this.ijx_1, current);
    }
    var result = tmp;
    this.ijx_1 = current;
    return result;
  }
  dk5(fromIndex, toIndex) {
    this.ljx_1.lh(this.ek5(), fromIndex, toIndex);
  }
  ik2(allowLenientStrings) {
    // Inline function 'kotlin.collections.mutableListOf' call
    var tokenStack = ArrayList.m1();
    var lastToken = this.qk1();
    if (!(lastToken === 8) && !(lastToken === 6)) {
      this.uk1();
      return Unit_instance;
    }
    $l$loop: while (true) {
      lastToken = this.qk1();
      if (lastToken === 1) {
        if (allowLenientStrings)
          this.uk1();
        else
          this.jk2();
        continue $l$loop;
      }
      var tmp40_subject = lastToken;
      if (tmp40_subject === 8 || tmp40_subject === 6) {
        tokenStack.f(lastToken);
      } else if (tmp40_subject === 9) {
        if (!(last(tokenStack) === 8))
          throw JsonDecodingException_0(this.ijx_1, 'found ] instead of } at path: ' + this.jjx_1.toString(), this.ek5());
        removeLast(tokenStack);
      } else if (tmp40_subject === 7) {
        if (!(last(tokenStack) === 6))
          throw JsonDecodingException_0(this.ijx_1, 'found } instead of ] at path: ' + this.jjx_1.toString(), this.ek5());
        removeLast(tokenStack);
      } else if (tmp40_subject === 10) {
        this.qk0('Unexpected end of input due to malformed JSON during ignoring unknown keys');
      }
      this.vk1();
      if (tokenStack.j1() === 0)
        return Unit_instance;
    }
  }
  toString() {
    return "JsonReader(source='" + toString(this.ek5()) + "', currentPosition=" + this.ijx_1 + ')';
  }
  hk2(key) {
    var processed = this.uh(0, this.ijx_1);
    var lastIndexOf_0 = lastIndexOf(processed, key);
    this.pk0("Encountered an unknown key '" + key + "'", lastIndexOf_0, "Use 'ignoreUnknownKeys = true' in 'Json {}' builder to ignore unknown keys.");
  }
  pk0(message, position, hint) {
    var tmp;
    // Inline function 'kotlin.text.isEmpty' call
    if (charSequenceLength(hint) === 0) {
      tmp = '';
    } else {
      tmp = '\n' + hint;
    }
    var hintMessage = tmp;
    throw JsonDecodingException_0(position, message + ' at path: ' + this.jjx_1.kk1() + hintMessage, this.ek5());
  }
  qk0(message, position, hint, $super) {
    position = position === VOID ? this.ijx_1 : position;
    hint = hint === VOID ? '' : hint;
    return $super === VOID ? this.pk0(message, position, hint) : $super.pk0.call(this, message, position, hint);
  }
  mjz() {
    var current = this.hk5();
    current = this.fk5(current);
    if (current >= charSequenceLength(this.ek5()) || current === -1) {
      this.qk0('EOF');
    }
    var tmp;
    if (charSequenceGet(this.ek5(), current) === _Char___init__impl__6a9atx(34)) {
      current = current + 1 | 0;
      if (current === charSequenceLength(this.ek5())) {
        this.qk0('EOF');
      }
      tmp = true;
    } else {
      tmp = false;
    }
    var hasQuotation = tmp;
    var accumulator = new Long(0, 0);
    var exponentAccumulator = new Long(0, 0);
    var isNegative = false;
    var isExponentPositive = false;
    var hasExponent = false;
    var start = current;
    $l$loop_4: while (!(current === charSequenceLength(this.ek5()))) {
      var ch = charSequenceGet(this.ek5(), current);
      if ((ch === _Char___init__impl__6a9atx(101) || ch === _Char___init__impl__6a9atx(69)) && !hasExponent) {
        if (current === start) {
          this.qk0('Unexpected symbol ' + toString_1(ch) + ' in numeric literal');
        }
        isExponentPositive = true;
        hasExponent = true;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      if (ch === _Char___init__impl__6a9atx(45) && hasExponent) {
        if (current === start) {
          this.qk0("Unexpected symbol '-' in numeric literal");
        }
        isExponentPositive = false;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      if (ch === _Char___init__impl__6a9atx(43) && hasExponent) {
        if (current === start) {
          this.qk0("Unexpected symbol '+' in numeric literal");
        }
        isExponentPositive = true;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      if (ch === _Char___init__impl__6a9atx(45)) {
        if (!(current === start)) {
          this.qk0("Unexpected symbol '-' in numeric literal");
        }
        isNegative = true;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      var token = charToTokenClass(ch);
      if (!(token === 0))
        break $l$loop_4;
      current = current + 1 | 0;
      var digit = Char__minus_impl_a2frrh(ch, _Char___init__impl__6a9atx(48));
      if (!(0 <= digit ? digit <= 9 : false)) {
        this.qk0("Unexpected symbol '" + toString_1(ch) + "' in numeric literal");
      }
      if (hasExponent) {
        // Inline function 'kotlin.Long.plus' call
        // Inline function 'kotlin.Long.times' call
        exponentAccumulator = exponentAccumulator.d4(toLong(10)).c4(toLong(digit));
        continue $l$loop_4;
      }
      // Inline function 'kotlin.Long.minus' call
      // Inline function 'kotlin.Long.times' call
      accumulator = accumulator.d4(toLong(10)).h2(toLong(digit));
      if (accumulator.g2(new Long(0, 0)) > 0) {
        this.qk0('Numeric value overflow');
      }
    }
    var hasChars = !(current === start);
    if (start === current || (isNegative && start === (current - 1 | 0))) {
      this.qk0('Expected numeric literal');
    }
    if (hasQuotation) {
      if (!hasChars) {
        this.qk0('EOF');
      }
      if (!(charSequenceGet(this.ek5(), current) === _Char___init__impl__6a9atx(34))) {
        this.qk0('Expected closing quotation mark');
      }
      current = current + 1 | 0;
    }
    this.ijx_1 = current;
    if (hasExponent) {
      var doubleAccumulator = accumulator.t4() * consumeNumericLiteral$calculateExponent(exponentAccumulator, isExponentPositive);
      if (doubleAccumulator > (new Long(-1, 2147483647)).t4() || doubleAccumulator < (new Long(0, -2147483648)).t4()) {
        this.qk0('Numeric value overflow');
      }
      // Inline function 'kotlin.math.floor' call
      if (!(Math.floor(doubleAccumulator) === doubleAccumulator)) {
        this.qk0("Can't convert " + doubleAccumulator + ' to Long');
      }
      accumulator = numberToLong(doubleAccumulator);
    }
    var tmp_0;
    if (isNegative) {
      tmp_0 = accumulator;
    } else if (!accumulator.equals(new Long(0, -2147483648))) {
      tmp_0 = accumulator.i4();
    } else {
      this.qk0('Numeric value overflow');
    }
    return tmp_0;
  }
  rk2() {
    var current = this.hk5();
    if (current === charSequenceLength(this.ek5())) {
      this.qk0('EOF');
    }
    var tmp;
    if (charSequenceGet(this.ek5(), current) === _Char___init__impl__6a9atx(34)) {
      current = current + 1 | 0;
      tmp = true;
    } else {
      tmp = false;
    }
    var hasQuotation = tmp;
    var result = consumeBoolean2(this, current);
    if (hasQuotation) {
      if (this.ijx_1 === charSequenceLength(this.ek5())) {
        this.qk0('EOF');
      }
      if (!(charSequenceGet(this.ek5(), this.ijx_1) === _Char___init__impl__6a9atx(34))) {
        this.qk0('Expected closing quotation mark');
      }
      this.ijx_1 = this.ijx_1 + 1 | 0;
    }
    return result;
  }
}
class CharMappings {
  constructor() {
    CharMappings_instance = this;
    this.nk5_1 = charArray(117);
    this.ok5_1 = new Int8Array(126);
    initEscape(this);
    initCharToToken(this);
  }
}
class StringJsonLexer extends AbstractJsonLexer {
  constructor(source) {
    super();
    this.yk5_1 = source;
  }
  ek5() {
    return this.yk5_1;
  }
  fk5(position) {
    return position < this.ek5().length ? position : -1;
  }
  vk1() {
    var source = this.ek5();
    var cpos = this.ijx_1;
    $l$loop: while (!(cpos === -1) && cpos < source.length) {
      var _unary__edvuaz = cpos;
      cpos = _unary__edvuaz + 1 | 0;
      var c = charSequenceGet(source, _unary__edvuaz);
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.isWs' call
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9))
        continue $l$loop;
      this.ijx_1 = cpos;
      return charToTokenClass(c);
    }
    this.ijx_1 = source.length;
    return 10;
  }
  sk1() {
    var current = this.ijx_1;
    if (current === -1)
      return false;
    var source = this.ek5();
    $l$loop: while (current < source.length) {
      var c = charSequenceGet(source, current);
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.isWs' call
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9)) {
        current = current + 1 | 0;
        continue $l$loop;
      }
      this.ijx_1 = current;
      return this.ik5(c);
    }
    this.ijx_1 = current;
    return false;
  }
  hk5() {
    var current = this.ijx_1;
    if (current === -1)
      return current;
    var source = this.ek5();
    $l$loop: while (current < source.length) {
      var c = charSequenceGet(source, current);
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.isWs' call
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9)) {
        current = current + 1 | 0;
      } else {
        break $l$loop;
      }
    }
    this.ijx_1 = current;
    return current;
  }
  dk2(expected) {
    if (this.ijx_1 === -1) {
      this.kk5(expected);
    }
    var source = this.ek5();
    var cpos = this.ijx_1;
    $l$loop: while (cpos < source.length) {
      var _unary__edvuaz = cpos;
      cpos = _unary__edvuaz + 1 | 0;
      var c = charSequenceGet(source, _unary__edvuaz);
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.isWs' call
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9))
        continue $l$loop;
      this.ijx_1 = cpos;
      if (c === expected)
        return Unit_instance;
      this.kk5(expected);
    }
    this.ijx_1 = -1;
    this.kk5(expected);
  }
  jk2() {
    this.dk2(_Char___init__impl__6a9atx(34));
    var current = this.ijx_1;
    var closingQuote = indexOf_0(this.ek5(), _Char___init__impl__6a9atx(34), current);
    if (closingQuote === -1) {
      this.uk1();
      this.lk5(1, false);
    }
    var inductionVariable = current;
    if (inductionVariable < closingQuote)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (charSequenceGet(this.ek5(), i) === _Char___init__impl__6a9atx(92)) {
          return this.consumeString2(this.ek5(), this.ijx_1, i);
        }
      }
       while (inductionVariable < closingQuote);
    this.ijx_1 = closingQuote + 1 | 0;
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.ek5().substring(current, closingQuote);
  }
  lk2(keyToMatch, isLenient) {
    var positionSnapshot = this.ijx_1;
    try {
      if (!(this.vk1() === 6))
        return null;
      var firstKey = this.gk2(isLenient);
      if (!(firstKey === keyToMatch))
        return null;
      this.mk5();
      if (!(this.vk1() === 5))
        return null;
      return this.gk2(isLenient);
    }finally {
      this.ijx_1 = positionSnapshot;
      this.mk5();
    }
  }
}
class StringJsonLexerWithComments extends StringJsonLexer {
  vk1() {
    var source = this.ek5();
    var cpos = this.hk5();
    if (cpos >= source.length || cpos === -1)
      return 10;
    this.ijx_1 = cpos + 1 | 0;
    return charToTokenClass(charSequenceGet(source, cpos));
  }
  sk1() {
    var current = this.hk5();
    if (current >= this.ek5().length || current === -1)
      return false;
    return this.ik5(charSequenceGet(this.ek5(), current));
  }
  dk2(expected) {
    var source = this.ek5();
    var current = this.hk5();
    if (current >= source.length || current === -1) {
      this.ijx_1 = -1;
      this.kk5(expected);
    }
    var c = charSequenceGet(source, current);
    this.ijx_1 = current + 1 | 0;
    if (c === expected)
      return Unit_instance;
    else {
      this.kk5(expected);
    }
  }
  qk1() {
    var source = this.ek5();
    var cpos = this.hk5();
    if (cpos >= source.length || cpos === -1)
      return 10;
    this.ijx_1 = cpos;
    return charToTokenClass(charSequenceGet(source, cpos));
  }
  hk5() {
    var current = this.ijx_1;
    if (current === -1)
      return current;
    var source = this.ek5();
    $l$loop_1: while (current < source.length) {
      var c = charSequenceGet(source, current);
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.isWs' call
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9)) {
        current = current + 1 | 0;
        continue $l$loop_1;
      }
      if (c === _Char___init__impl__6a9atx(47) && (current + 1 | 0) < source.length) {
        var tmp43_subject = charSequenceGet(source, current + 1 | 0);
        if (tmp43_subject === _Char___init__impl__6a9atx(47)) {
          current = indexOf_0(source, _Char___init__impl__6a9atx(10), current + 2 | 0);
          if (current === -1) {
            current = source.length;
          } else {
            current = current + 1 | 0;
          }
          continue $l$loop_1;
        } else if (tmp43_subject === _Char___init__impl__6a9atx(42)) {
          current = indexOf(source, '*/', current + 2 | 0);
          if (current === -1) {
            this.ijx_1 = source.length;
            this.qk0('Expected end of the block comment: "*/", but had EOF instead');
          } else {
            current = current + 2 | 0;
          }
          continue $l$loop_1;
        }
      }
      break $l$loop_1;
    }
    this.ijx_1 = current;
    return current;
  }
}
//endregion
var Default_instance;
function Default_getInstance() {
  if (Default_instance === VOID)
    new Default();
  return Default_instance;
}
function Json_0(from, builderAction) {
  from = from === VOID ? Default_getInstance() : from;
  var builder = new JsonBuilder(from);
  builderAction(builder);
  var conf = builder.fjy();
  return new JsonImpl(conf, builder.ejy_1);
}
function validateConfiguration($this) {
  if (equals($this.ejk(), EmptySerializersModule()))
    return Unit_instance;
  var collector = new PolymorphismValidator($this.wjw_1.ojy_1, $this.wjw_1.pjy_1);
  $this.ejk().ejw(collector);
}
var ClassDiscriminatorMode_NONE_instance;
var ClassDiscriminatorMode_ALL_JSON_OBJECTS_instance;
var ClassDiscriminatorMode_POLYMORPHIC_instance;
var ClassDiscriminatorMode_entriesInitialized;
function ClassDiscriminatorMode_initEntries() {
  if (ClassDiscriminatorMode_entriesInitialized)
    return Unit_instance;
  ClassDiscriminatorMode_entriesInitialized = true;
  ClassDiscriminatorMode_NONE_instance = new ClassDiscriminatorMode('NONE', 0);
  ClassDiscriminatorMode_ALL_JSON_OBJECTS_instance = new ClassDiscriminatorMode('ALL_JSON_OBJECTS', 1);
  ClassDiscriminatorMode_POLYMORPHIC_instance = new ClassDiscriminatorMode('POLYMORPHIC', 2);
}
function ClassDiscriminatorMode_POLYMORPHIC_getInstance() {
  ClassDiscriminatorMode_initEntries();
  return ClassDiscriminatorMode_POLYMORPHIC_instance;
}
var jsonUnquotedLiteralDescriptor;
var Companion_instance;
function Companion_getInstance_3() {
  return Companion_instance;
}
var Companion_instance_0;
function Companion_getInstance_4() {
  return Companion_instance_0;
}
var Companion_instance_1;
function Companion_getInstance_5() {
  return Companion_instance_1;
}
function JsonObject$toString$lambda(_destruct__k2r9zo) {
  // Inline function 'kotlin.collections.component1' call
  var k = _destruct__k2r9zo.a2();
  // Inline function 'kotlin.collections.component2' call
  var v = _destruct__k2r9zo.b2();
  // Inline function 'kotlin.text.buildString' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.h();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.serialization.json.JsonObject.toString.<anonymous>.<anonymous>' call
  printQuoted(this_0, k);
  this_0.jc(_Char___init__impl__6a9atx(58));
  this_0.hc(v);
  return this_0.toString();
}
var Companion_instance_2;
function Companion_getInstance_6() {
  return Companion_instance_2;
}
function _get_$cachedSerializer__te6jhj($this) {
  return $this.hjz_1.b2();
}
function JsonNull$_anonymous__enib48() {
  return JsonNullSerializer_getInstance();
}
var JsonNull_instance;
function JsonNull_getInstance() {
  if (JsonNull_instance === VOID)
    new JsonNull();
  return JsonNull_instance;
}
function JsonPrimitive_0(value) {
  _init_properties_JsonElement_kt__7cbdc2();
  if (value == null)
    return JsonNull_getInstance();
  return new JsonLiteral(value, true);
}
function get_booleanOrNull(_this__u8e3s4) {
  _init_properties_JsonElement_kt__7cbdc2();
  return toBooleanStrictOrNull(_this__u8e3s4.fjz());
}
function get_int(_this__u8e3s4) {
  _init_properties_JsonElement_kt__7cbdc2();
  // Inline function 'kotlinx.serialization.json.mapExceptions' call
  var tmp;
  try {
    // Inline function 'kotlinx.serialization.json.<get-int>.<anonymous>' call
    tmp = (new StringJsonLexer(_this__u8e3s4.fjz())).mjz();
  } catch ($p) {
    var tmp_0;
    if ($p instanceof JsonDecodingException) {
      var e = $p;
      throw NumberFormatException.sf(e.message);
    } else {
      throw $p;
    }
  }
  var result = tmp;
  // Inline function 'kotlin.ranges.contains' call
  var this_0 = numberRangeToNumber(-2147483648, 2147483647);
  if (!contains(isInterface(this_0, ClosedRange) ? this_0 : THROW_CCE(), result))
    throw NumberFormatException.sf(_this__u8e3s4.fjz() + ' is not an Int');
  return result.q2();
}
function get_long(_this__u8e3s4) {
  _init_properties_JsonElement_kt__7cbdc2();
  // Inline function 'kotlinx.serialization.json.mapExceptions' call
  var tmp;
  try {
    // Inline function 'kotlinx.serialization.json.<get-long>.<anonymous>' call
    tmp = (new StringJsonLexer(_this__u8e3s4.fjz())).mjz();
  } catch ($p) {
    var tmp_0;
    if ($p instanceof JsonDecodingException) {
      var e = $p;
      throw NumberFormatException.sf(e.message);
    } else {
      throw $p;
    }
  }
  return tmp;
}
function get_float(_this__u8e3s4) {
  _init_properties_JsonElement_kt__7cbdc2();
  // Inline function 'kotlin.text.toFloat' call
  var this_0 = _this__u8e3s4.fjz();
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  return toDouble(this_0);
}
function get_double(_this__u8e3s4) {
  _init_properties_JsonElement_kt__7cbdc2();
  return toDouble(_this__u8e3s4.fjz());
}
function get_contentOrNull(_this__u8e3s4) {
  _init_properties_JsonElement_kt__7cbdc2();
  var tmp;
  if (_this__u8e3s4 instanceof JsonNull) {
    tmp = null;
  } else {
    tmp = _this__u8e3s4.fjz();
  }
  return tmp;
}
function get_jsonPrimitive(_this__u8e3s4) {
  _init_properties_JsonElement_kt__7cbdc2();
  var tmp0_elvis_lhs = _this__u8e3s4 instanceof JsonPrimitive ? _this__u8e3s4 : null;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    error(_this__u8e3s4, 'JsonPrimitive');
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function error(_this__u8e3s4, element) {
  _init_properties_JsonElement_kt__7cbdc2();
  throw IllegalArgumentException.l('Element ' + toString(getKClassFromExpression(_this__u8e3s4)) + ' is not a ' + element);
}
var properties_initialized_JsonElement_kt_abxy8s;
function _init_properties_JsonElement_kt__7cbdc2() {
  if (!properties_initialized_JsonElement_kt_abxy8s) {
    properties_initialized_JsonElement_kt_abxy8s = true;
    jsonUnquotedLiteralDescriptor = InlinePrimitiveDescriptor('kotlinx.serialization.json.JsonUnquotedLiteral', serializer(StringCompanionObject_instance));
  }
}
function JsonElementSerializer$descriptor$lambda($this$buildSerialDescriptor) {
  $this$buildSerialDescriptor.ejg('JsonPrimitive', defer(JsonElementSerializer$descriptor$lambda$lambda));
  $this$buildSerialDescriptor.ejg('JsonNull', defer(JsonElementSerializer$descriptor$lambda$lambda_0));
  $this$buildSerialDescriptor.ejg('JsonLiteral', defer(JsonElementSerializer$descriptor$lambda$lambda_1));
  $this$buildSerialDescriptor.ejg('JsonObject', defer(JsonElementSerializer$descriptor$lambda$lambda_2));
  $this$buildSerialDescriptor.ejg('JsonArray', defer(JsonElementSerializer$descriptor$lambda$lambda_3));
  return Unit_instance;
}
function JsonElementSerializer$descriptor$lambda$lambda() {
  return JsonPrimitiveSerializer_getInstance().njz_1;
}
function JsonElementSerializer$descriptor$lambda$lambda_0() {
  return JsonNullSerializer_getInstance().ojz_1;
}
function JsonElementSerializer$descriptor$lambda$lambda_1() {
  return JsonLiteralSerializer_getInstance().pjz_1;
}
function JsonElementSerializer$descriptor$lambda$lambda_2() {
  return JsonObjectSerializer_getInstance().qjz_1;
}
function JsonElementSerializer$descriptor$lambda$lambda_3() {
  return JsonArraySerializer_getInstance().rjz_1;
}
var JsonElementSerializer_instance;
function JsonElementSerializer_getInstance() {
  if (JsonElementSerializer_instance === VOID)
    new JsonElementSerializer();
  return JsonElementSerializer_instance;
}
var JsonArrayDescriptor_instance;
function JsonArrayDescriptor_getInstance() {
  if (JsonArrayDescriptor_instance === VOID)
    new JsonArrayDescriptor();
  return JsonArrayDescriptor_instance;
}
var JsonArraySerializer_instance;
function JsonArraySerializer_getInstance() {
  if (JsonArraySerializer_instance === VOID)
    new JsonArraySerializer();
  return JsonArraySerializer_instance;
}
var JsonObjectDescriptor_instance;
function JsonObjectDescriptor_getInstance() {
  if (JsonObjectDescriptor_instance === VOID)
    new JsonObjectDescriptor();
  return JsonObjectDescriptor_instance;
}
var JsonObjectSerializer_instance;
function JsonObjectSerializer_getInstance() {
  if (JsonObjectSerializer_instance === VOID)
    new JsonObjectSerializer();
  return JsonObjectSerializer_instance;
}
function defer(deferred) {
  return new defer$1(deferred);
}
var JsonPrimitiveSerializer_instance;
function JsonPrimitiveSerializer_getInstance() {
  if (JsonPrimitiveSerializer_instance === VOID)
    new JsonPrimitiveSerializer();
  return JsonPrimitiveSerializer_instance;
}
var JsonNullSerializer_instance;
function JsonNullSerializer_getInstance() {
  if (JsonNullSerializer_instance === VOID)
    new JsonNullSerializer();
  return JsonNullSerializer_instance;
}
var JsonLiteralSerializer_instance;
function JsonLiteralSerializer_getInstance() {
  if (JsonLiteralSerializer_instance === VOID)
    new JsonLiteralSerializer();
  return JsonLiteralSerializer_instance;
}
function asJsonDecoder(_this__u8e3s4) {
  var tmp0_elvis_lhs = isInterface(_this__u8e3s4, JsonDecoder) ? _this__u8e3s4 : null;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    throw IllegalStateException.m5('This serializer can be used only with Json format.' + ('Expected Decoder to be JsonDecoder, got ' + toString(getKClassFromExpression(_this__u8e3s4))));
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function verify(decoder) {
  asJsonDecoder(decoder);
}
function _get_original__l7ku1m($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.ek0_1;
  original$factory();
  return this_0.b2();
}
function original$factory() {
  return getPropertyCallableRef('original', 1, KProperty1, function (receiver) {
    return _get_original__l7ku1m(receiver);
  }, null);
}
function readIfAbsent($this, descriptor, index) {
  $this.gk0_1 = (!descriptor.xjh(index) && descriptor.wjh(index).ojh());
  return $this.gk0_1;
}
function JsonElementMarker$readIfAbsent$ref($boundThis) {
  var l = function (p0, p1) {
    return readIfAbsent($boundThis, p0, p1);
  };
  l.callableName = 'readIfAbsent';
  return l;
}
function JsonDecodingException_0(offset, message, input) {
  return JsonDecodingException_1(offset, message + '\nJSON input: ' + toString(minify(input, offset)));
}
function JsonDecodingException_1(offset, message) {
  return JsonDecodingException.dk0(offset >= 0 ? 'Unexpected JSON token at offset ' + offset + ': ' + message : message);
}
function minify(_this__u8e3s4, offset) {
  offset = offset === VOID ? -1 : offset;
  if (charSequenceLength(_this__u8e3s4) < 200)
    return _this__u8e3s4;
  if (offset === -1) {
    var start = charSequenceLength(_this__u8e3s4) - 60 | 0;
    if (start <= 0)
      return _this__u8e3s4;
    // Inline function 'kotlin.text.substring' call
    var endIndex = charSequenceLength(_this__u8e3s4);
    return '.....' + toString(charSequenceSubSequence(_this__u8e3s4, start, endIndex));
  }
  var start_0 = offset - 30 | 0;
  var end = offset + 30 | 0;
  var prefix = start_0 <= 0 ? '' : '.....';
  var suffix = end >= charSequenceLength(_this__u8e3s4) ? '' : '.....';
  // Inline function 'kotlin.text.substring' call
  var startIndex = coerceAtLeast(start_0, 0);
  var endIndex_0 = coerceAtMost(end, charSequenceLength(_this__u8e3s4));
  return prefix + toString(charSequenceSubSequence(_this__u8e3s4, startIndex, endIndex_0)) + suffix;
}
function invalidTrailingComma(_this__u8e3s4, entity) {
  entity = entity === VOID ? 'object' : entity;
  _this__u8e3s4.pk0('Trailing comma before the end of JSON ' + entity, _this__u8e3s4.ijx_1 - 1 | 0, "Trailing commas are non-complaint JSON and not allowed by default. Use 'allowTrailingCommas = true' in 'Json {}' builder to support them.");
}
function throwInvalidFloatingPointDecoded(_this__u8e3s4, result) {
  _this__u8e3s4.qk0('Unexpected special floating-point value ' + toString(result) + '. By default, ' + 'non-finite floating point values are prohibited because they do not conform JSON specification', VOID, "It is possible to deserialize them using 'JsonBuilder.allowSpecialFloatingPointValues = true'");
}
function InvalidKeyKindException(keyDescriptor) {
  return JsonEncodingException.xk0("Value of type '" + keyDescriptor.sjh() + "' can't be used in JSON as a key in the map. " + ("It should have either primitive or enum kind, but its kind is '" + keyDescriptor.yjh().toString() + "'.\n") + "Use 'allowStructuredMapKeys = true' in 'Json {}' builder to convert such maps to [key1, value1, key2, value2,...] arrays.");
}
function InvalidFloatingPointDecoded(value, key, output) {
  return JsonDecodingException_1(-1, unexpectedFpErrorMessage(value, key, output));
}
function UnknownKeyException(key, input) {
  return JsonDecodingException_1(-1, "Encountered an unknown key '" + key + "'.\n" + "Use 'ignoreUnknownKeys = true' in 'Json {}' builder to ignore unknown keys.\n" + ('Current input: ' + toString(minify(input))));
}
function unexpectedFpErrorMessage(value, key, output) {
  return 'Unexpected special floating-point value ' + toString(value) + ' with key ' + key + '. By default, ' + 'non-finite floating point values are prohibited because they do not conform JSON specification. ' + "It is possible to deserialize them using 'JsonBuilder.allowSpecialFloatingPointValues = true'\n" + ('Current output: ' + toString(minify(output)));
}
function get_JsonDeserializationNamesKey() {
  _init_properties_JsonNamesMap_kt__cbbp0k();
  return JsonDeserializationNamesKey;
}
var JsonDeserializationNamesKey;
var JsonSerializationNamesKey;
function getJsonNameIndex(_this__u8e3s4, json, name) {
  _init_properties_JsonNamesMap_kt__cbbp0k();
  if (decodeCaseInsensitive(json, _this__u8e3s4)) {
    // Inline function 'kotlin.text.lowercase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = name.toLowerCase();
    return getJsonNameIndexSlowPath(_this__u8e3s4, json, tmp$ret$1);
  }
  var strategy = namingStrategy(_this__u8e3s4, json);
  if (!(strategy == null))
    return getJsonNameIndexSlowPath(_this__u8e3s4, json, name);
  var index = _this__u8e3s4.ujh(name);
  if (!(index === -3))
    return index;
  if (!json.wjw_1.rjy_1)
    return index;
  return getJsonNameIndexSlowPath(_this__u8e3s4, json, name);
}
function getJsonNameIndexOrThrow(_this__u8e3s4, json, name, suffix) {
  suffix = suffix === VOID ? '' : suffix;
  _init_properties_JsonNamesMap_kt__cbbp0k();
  var index = getJsonNameIndex(_this__u8e3s4, json, name);
  if (index === -3)
    throw SerializationException.sjg(_this__u8e3s4.sjh() + " does not contain element with name '" + name + "'" + suffix);
  return index;
}
function namingStrategy(_this__u8e3s4, json) {
  _init_properties_JsonNamesMap_kt__cbbp0k();
  return equals(_this__u8e3s4.yjh(), CLASS_getInstance()) ? json.wjw_1.sjy_1 : null;
}
function deserializationNamesMap(_this__u8e3s4, descriptor) {
  _init_properties_JsonNamesMap_kt__cbbp0k();
  var tmp = get_schemaCache(_this__u8e3s4);
  var tmp_0 = get_JsonDeserializationNamesKey();
  return tmp.zk0(descriptor, tmp_0, deserializationNamesMap$lambda(descriptor, _this__u8e3s4));
}
function decodeCaseInsensitive(_this__u8e3s4, descriptor) {
  _init_properties_JsonNamesMap_kt__cbbp0k();
  return _this__u8e3s4.wjw_1.tjy_1 && equals(descriptor.yjh(), ENUM_getInstance());
}
function getJsonNameIndexSlowPath(_this__u8e3s4, json, name) {
  _init_properties_JsonNamesMap_kt__cbbp0k();
  var tmp0_elvis_lhs = deserializationNamesMap(json, _this__u8e3s4).k3(name);
  return tmp0_elvis_lhs == null ? -3 : tmp0_elvis_lhs;
}
function buildDeserializationNamesMap(_this__u8e3s4, json) {
  _init_properties_JsonNamesMap_kt__cbbp0k();
  // Inline function 'kotlin.collections.mutableMapOf' call
  var builder = LinkedHashMap.vc();
  var useLowercaseEnums = decodeCaseInsensitive(json, _this__u8e3s4);
  var strategyForClasses = namingStrategy(_this__u8e3s4, json);
  var inductionVariable = 0;
  var last = _this__u8e3s4.aji();
  if (inductionVariable < last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.collections.filterIsInstance' call
      // Inline function 'kotlin.collections.filterIsInstanceTo' call
      var this_0 = _this__u8e3s4.vjh(i);
      var destination = ArrayList.m1();
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        if (element instanceof JsonNames) {
          destination.f(element);
        }
      }
      var tmp9_safe_receiver = singleOrNull(destination);
      var tmp10_safe_receiver = tmp9_safe_receiver == null ? null : tmp9_safe_receiver.ak1_1;
      if (tmp10_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.collections.forEach' call
        var inductionVariable_0 = 0;
        var last_0 = tmp10_safe_receiver.length;
        while (inductionVariable_0 < last_0) {
          var element_0 = tmp10_safe_receiver[inductionVariable_0];
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          // Inline function 'kotlinx.serialization.json.internal.buildDeserializationNamesMap.<anonymous>' call
          var tmp;
          if (useLowercaseEnums) {
            // Inline function 'kotlin.text.lowercase' call
            // Inline function 'kotlin.js.asDynamic' call
            tmp = element_0.toLowerCase();
          } else {
            tmp = element_0;
          }
          buildDeserializationNamesMap$putOrThrow(builder, _this__u8e3s4, tmp, i);
        }
      }
      var tmp_0;
      if (useLowercaseEnums) {
        // Inline function 'kotlin.text.lowercase' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp_0 = _this__u8e3s4.tjh(i).toLowerCase();
      } else if (!(strategyForClasses == null)) {
        tmp_0 = strategyForClasses.bk1(_this__u8e3s4, i, _this__u8e3s4.tjh(i));
      } else {
        tmp_0 = null;
      }
      var nameToPut = tmp_0;
      if (nameToPut == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        buildDeserializationNamesMap$putOrThrow(builder, _this__u8e3s4, nameToPut, i);
      }
    }
     while (inductionVariable < last);
  // Inline function 'kotlin.collections.ifEmpty' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp_1;
  if (builder.a1()) {
    // Inline function 'kotlinx.serialization.json.internal.buildDeserializationNamesMap.<anonymous>' call
    tmp_1 = emptyMap();
  } else {
    tmp_1 = builder;
  }
  return tmp_1;
}
function buildDeserializationNamesMap$putOrThrow(_this__u8e3s4, $this_buildDeserializationNamesMap, name, index) {
  var entity = equals($this_buildDeserializationNamesMap.yjh(), ENUM_getInstance()) ? 'enum value' : 'property';
  // Inline function 'kotlin.collections.contains' call
  // Inline function 'kotlin.collections.containsKey' call
  if ((isInterface(_this__u8e3s4, KtMap) ? _this__u8e3s4 : THROW_CCE()).i3(name)) {
    throw JsonException.ok0("The suggested name '" + name + "' for " + entity + ' ' + $this_buildDeserializationNamesMap.tjh(index) + ' is already one of the names for ' + entity + ' ' + ($this_buildDeserializationNamesMap.tjh(getValue(_this__u8e3s4, name)) + ' in ' + toString($this_buildDeserializationNamesMap)));
  }
  // Inline function 'kotlin.collections.set' call
  _this__u8e3s4.r3(name, index);
}
function deserializationNamesMap$lambda($descriptor, $this_deserializationNamesMap) {
  return function () {
    return buildDeserializationNamesMap($descriptor, $this_deserializationNamesMap);
  };
}
var properties_initialized_JsonNamesMap_kt_ljpf42;
function _init_properties_JsonNamesMap_kt__cbbp0k() {
  if (!properties_initialized_JsonNamesMap_kt_ljpf42) {
    properties_initialized_JsonNamesMap_kt_ljpf42 = true;
    JsonDeserializationNamesKey = new Key();
    JsonSerializationNamesKey = new Key();
  }
}
var Tombstone_instance;
function Tombstone_getInstance() {
  return Tombstone_instance;
}
function resize($this) {
  var newSize = imul($this.ek1_1, 2);
  $this.ck1_1 = copyOf($this.ck1_1, newSize);
  $this.dk1_1 = copyOf_0($this.dk1_1, newSize);
}
function *_generator_invoke__zhh2q8($this, $this$DeepRecursiveFunction, it, $completion) {
  var tmp14_subject = $this.pk1_1.lk1_1.qk1();
  var tmp;
  if (tmp14_subject === 1) {
    tmp = readValue($this.pk1_1, true);
  } else if (tmp14_subject === 0) {
    tmp = readValue($this.pk1_1, false);
  } else if (tmp14_subject === 6) {
    var tmp_0 = readObject_0($this$DeepRecursiveFunction, $this.pk1_1, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else if (tmp14_subject === 8) {
    tmp = readArray($this.pk1_1);
  } else {
    $this.pk1_1.lk1_1.qk0("Can't begin reading element, unexpected token");
  }
  return tmp;
}
function readObject($this) {
  // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readObjectImpl' call
  var lastToken = $this.lk1_1.rk1(6);
  if ($this.lk1_1.qk1() === 4) {
    $this.lk1_1.qk0('Unexpected leading comma');
  }
  // Inline function 'kotlin.collections.linkedMapOf' call
  var result = LinkedHashMap.vc();
  $l$loop: while ($this.lk1_1.sk1()) {
    var key = $this.mk1_1 ? $this.lk1_1.uk1() : $this.lk1_1.tk1();
    $this.lk1_1.rk1(5);
    // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readObject.<anonymous>' call
    var element = $this.p1v();
    // Inline function 'kotlin.collections.set' call
    result.r3(key, element);
    lastToken = $this.lk1_1.vk1();
    var tmp13_subject = lastToken;
    if (tmp13_subject !== 4)
      if (tmp13_subject === 7)
        break $l$loop;
      else {
        $this.lk1_1.qk0('Expected end of the object or comma');
      }
  }
  if (lastToken === 6) {
    $this.lk1_1.rk1(7);
  } else if (lastToken === 4) {
    if (!$this.nk1_1) {
      invalidTrailingComma($this.lk1_1);
    }
    $this.lk1_1.rk1(7);
  }
  return new JsonObject(result);
}
function *_generator_readObject__361wel(_this__u8e3s4, $this, $completion) {
  // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readObjectImpl' call
  var lastToken = $this.lk1_1.rk1(6);
  if ($this.lk1_1.qk1() === 4) {
    $this.lk1_1.qk0('Unexpected leading comma');
  }
  // Inline function 'kotlin.collections.linkedMapOf' call
  var result = LinkedHashMap.vc();
  $l$loop: while ($this.lk1_1.sk1()) {
    var key = $this.mk1_1 ? $this.lk1_1.uk1() : $this.lk1_1.tk1();
    $this.lk1_1.rk1(5);
    // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readObject.<anonymous>' call
    var tmp = _this__u8e3s4.vr(Unit_instance, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var element = tmp;
    // Inline function 'kotlin.collections.set' call
    result.r3(key, element);
    lastToken = $this.lk1_1.vk1();
    var tmp13_subject = lastToken;
    if (tmp13_subject !== 4)
      if (tmp13_subject === 7)
        break $l$loop;
      else {
        $this.lk1_1.qk0('Expected end of the object or comma');
      }
  }
  if (lastToken === 6) {
    $this.lk1_1.rk1(7);
  } else if (lastToken === 4) {
    if (!$this.nk1_1) {
      invalidTrailingComma($this.lk1_1);
    }
    $this.lk1_1.rk1(7);
  }
  return new JsonObject(result);
}
function readObject_0(_this__u8e3s4, $this, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readObject__361wel(_this__u8e3s4, $this, $completion), $completion);
}
function readArray($this) {
  var lastToken = $this.lk1_1.vk1();
  if ($this.lk1_1.qk1() === 4) {
    $this.lk1_1.qk0('Unexpected leading comma');
  }
  // Inline function 'kotlin.collections.arrayListOf' call
  var result = ArrayList.m1();
  while ($this.lk1_1.sk1()) {
    var element = $this.p1v();
    result.f(element);
    lastToken = $this.lk1_1.vk1();
    if (!(lastToken === 4)) {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.require' call
      var this_0 = $this.lk1_1;
      var condition = lastToken === 9;
      var position = this_0.ijx_1;
      if (!condition) {
        // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readArray.<anonymous>' call
        var tmp$ret$1 = 'Expected end of the array or comma';
        this_0.qk0(tmp$ret$1, position);
      }
    }
  }
  if (lastToken === 8) {
    $this.lk1_1.rk1(9);
  } else if (lastToken === 4) {
    if (!$this.nk1_1) {
      invalidTrailingComma($this.lk1_1, 'array');
    }
    $this.lk1_1.rk1(9);
  }
  return new JsonArray(result);
}
function readValue($this, isString) {
  var tmp;
  if ($this.mk1_1 || !isString) {
    tmp = $this.lk1_1.uk1();
  } else {
    tmp = $this.lk1_1.tk1();
  }
  var string = tmp;
  if (!isString && string === 'null')
    return JsonNull_getInstance();
  return new JsonLiteral(string, isString);
}
function readDeepRecursive($this) {
  return invoke(new DeepRecursiveFunction(JsonTreeReader$readDeepRecursive$slambda_0($this)), Unit_instance);
}
function JsonTreeReader$readDeepRecursive$slambda_0(this$0) {
  var i = new JsonTreeReader$readDeepRecursive$slambda(this$0);
  var l = function ($this$DeepRecursiveFunction, it, $completion) {
    return i.wk1($this$DeepRecursiveFunction, it, $completion);
  };
  l.$arity = 2;
  return l;
}
function classDiscriminator(_this__u8e3s4, json) {
  var _iterator__ex2g4s = _this__u8e3s4.bji().f1();
  while (_iterator__ex2g4s.g1()) {
    var annotation = _iterator__ex2g4s.h1();
    if (annotation instanceof JsonClassDiscriminator)
      return annotation.xk1_1;
  }
  return json.wjw_1.pjy_1;
}
function checkKind($this, descriptor, actualClass) {
  var kind = descriptor.yjh();
  var tmp;
  if (kind instanceof PolymorphicKind) {
    tmp = true;
  } else {
    tmp = equals(kind, CONTEXTUAL_getInstance());
  }
  if (tmp) {
    throw IllegalArgumentException.l('Serializer for ' + actualClass.xf() + " can't be registered as a subclass for polymorphic serialization " + ('because its kind ' + kind.toString() + ' is not concrete. To work with multiple hierarchies, register it as a base class.'));
  }
  if ($this.yk1_1)
    return Unit_instance;
  var tmp_0;
  var tmp_1;
  if (equals(kind, LIST_getInstance()) || equals(kind, MAP_getInstance())) {
    tmp_1 = true;
  } else {
    tmp_1 = kind instanceof PrimitiveKind;
  }
  if (tmp_1) {
    tmp_0 = true;
  } else {
    tmp_0 = kind instanceof ENUM;
  }
  if (tmp_0) {
    throw IllegalArgumentException.l('Serializer for ' + actualClass.xf() + ' of kind ' + kind.toString() + ' cannot be serialized polymorphically with class discriminator.');
  }
}
function checkDiscriminatorCollisions($this, descriptor, actualClass) {
  var inductionVariable = 0;
  var last = descriptor.aji();
  if (inductionVariable < last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var name = descriptor.tjh(i);
      if (name === $this.zk1_1) {
        throw IllegalArgumentException.l('Polymorphic serializer for ' + toString(actualClass) + " has property '" + name + "' that conflicts " + 'with JSON class discriminator. You can either change class discriminator in JsonConfiguration, ' + 'rename property with @SerialName annotation ' + 'or fall back to array polymorphism');
      }
    }
     while (inductionVariable < last);
}
function trySkip(_this__u8e3s4, $this, unknownKey) {
  if (_this__u8e3s4 == null)
    return false;
  if (_this__u8e3s4.ck2_1 === unknownKey) {
    _this__u8e3s4.ck2_1 = null;
    return true;
  }
  return false;
}
function skipLeftoverElements($this, descriptor) {
  while (!($this.gjk(descriptor) === -1)) {
  }
}
function checkLeadingComma($this) {
  if ($this.cjx_1.qk1() === 4) {
    $this.cjx_1.qk0('Unexpected leading comma');
  }
}
function decodeMapIndex($this) {
  var hasComma = false;
  var decodingKey = !(($this.ejx_1 % 2 | 0) === 0);
  if (decodingKey) {
    if (!($this.ejx_1 === -1)) {
      hasComma = $this.cjx_1.ek2();
    }
  } else {
    $this.cjx_1.dk2(_Char___init__impl__6a9atx(58));
  }
  var tmp;
  if ($this.cjx_1.sk1()) {
    if (decodingKey) {
      if ($this.ejx_1 === -1) {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.require' call
        var this_0 = $this.cjx_1;
        var condition = !hasComma;
        var position = this_0.ijx_1;
        if (!condition) {
          // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeMapIndex.<anonymous>' call
          var tmp$ret$0 = 'Unexpected leading comma';
          this_0.qk0(tmp$ret$0, position);
        }
      } else {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.require' call
        var this_1 = $this.cjx_1;
        var condition_0 = hasComma;
        var position_0 = this_1.ijx_1;
        if (!condition_0) {
          // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeMapIndex.<anonymous>' call
          var tmp$ret$1 = 'Expected comma after the key-value pair';
          this_1.qk0(tmp$ret$1, position_0);
        }
      }
    }
    $this.ejx_1 = $this.ejx_1 + 1 | 0;
    tmp = $this.ejx_1;
  } else {
    if (hasComma && !$this.ajx_1.wjw_1.ujy_1) {
      invalidTrailingComma($this.cjx_1);
    }
    tmp = -1;
  }
  return tmp;
}
function coerceInputValue($this, descriptor, index) {
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue' call
    var this_0 = $this.ajx_1;
    var isOptional = descriptor.xjh(index);
    var elementDescriptor = descriptor.wjh(index);
    var tmp;
    if (isOptional && !elementDescriptor.ojh()) {
      // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
      tmp = $this.cjx_1.fk2(true);
    } else {
      tmp = false;
    }
    if (tmp) {
      tmp$ret$1 = true;
      break $l$block_2;
    }
    if (equals(elementDescriptor.yjh(), ENUM_getInstance())) {
      var tmp_0;
      if (elementDescriptor.ojh()) {
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
        tmp_0 = $this.cjx_1.fk2(false);
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp$ret$1 = false;
        break $l$block_2;
      }
      // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
      var tmp0_elvis_lhs = $this.cjx_1.gk2($this.gjx_1.ijy_1);
      var tmp_1;
      if (tmp0_elvis_lhs == null) {
        tmp$ret$1 = false;
        break $l$block_2;
      } else {
        tmp_1 = tmp0_elvis_lhs;
      }
      var enumValue = tmp_1;
      var enumIndex = getJsonNameIndex(elementDescriptor, this_0, enumValue);
      var coerceToNull = !this_0.wjw_1.ljy_1 && elementDescriptor.ojh();
      if (enumIndex === -3 && (isOptional || coerceToNull)) {
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
        $this.cjx_1.tk1();
        tmp$ret$1 = true;
        break $l$block_2;
      }
    }
    tmp$ret$1 = false;
  }
  return tmp$ret$1;
}
function decodeObjectIndex($this, descriptor) {
  var hasComma = $this.cjx_1.ek2();
  while ($this.cjx_1.sk1()) {
    hasComma = false;
    var key = decodeStringKey($this);
    $this.cjx_1.dk2(_Char___init__impl__6a9atx(58));
    var index = getJsonNameIndex(descriptor, $this.ajx_1, key);
    var tmp;
    if (!(index === -3)) {
      var tmp_0;
      if ($this.gjx_1.njy_1 && coerceInputValue($this, descriptor, index)) {
        hasComma = $this.cjx_1.ek2();
        tmp_0 = false;
      } else {
        var tmp23_safe_receiver = $this.hjx_1;
        if (tmp23_safe_receiver == null)
          null;
        else {
          tmp23_safe_receiver.hk0(index);
        }
        return index;
      }
      tmp = tmp_0;
    } else {
      tmp = true;
    }
    var isUnknown = tmp;
    if (isUnknown) {
      hasComma = handleUnknown($this, key);
    }
  }
  if (hasComma && !$this.ajx_1.wjw_1.ujy_1) {
    invalidTrailingComma($this.cjx_1);
  }
  var tmp24_safe_receiver = $this.hjx_1;
  var tmp0_elvis_lhs = tmp24_safe_receiver == null ? null : tmp24_safe_receiver.ik0();
  return tmp0_elvis_lhs == null ? -1 : tmp0_elvis_lhs;
}
function handleUnknown($this, key) {
  if ($this.gjx_1.hjy_1 || trySkip($this.fjx_1, $this, key)) {
    $this.cjx_1.ik2($this.gjx_1.ijy_1);
  } else {
    $this.cjx_1.hk2(key);
  }
  return $this.cjx_1.ek2();
}
function decodeListIndex($this) {
  var hasComma = $this.cjx_1.ek2();
  var tmp;
  if ($this.cjx_1.sk1()) {
    if (!($this.ejx_1 === -1) && !hasComma) {
      $this.cjx_1.qk0('Expected end of the array or comma');
    }
    $this.ejx_1 = $this.ejx_1 + 1 | 0;
    tmp = $this.ejx_1;
  } else {
    if (hasComma && !$this.ajx_1.wjw_1.ujy_1) {
      invalidTrailingComma($this.cjx_1, 'array');
    }
    tmp = -1;
  }
  return tmp;
}
function decodeStringKey($this) {
  var tmp;
  if ($this.gjx_1.ijy_1) {
    tmp = $this.cjx_1.kk2();
  } else {
    tmp = $this.cjx_1.jk2();
  }
  return tmp;
}
function get_unsignedNumberDescriptors() {
  _init_properties_StreamingJsonEncoder_kt__pn1bsi();
  return unsignedNumberDescriptors;
}
var unsignedNumberDescriptors;
function get_isUnsignedNumber(_this__u8e3s4) {
  _init_properties_StreamingJsonEncoder_kt__pn1bsi();
  return _this__u8e3s4.zjh() && get_unsignedNumberDescriptors().f3(_this__u8e3s4);
}
var properties_initialized_StreamingJsonEncoder_kt_6ifwwk;
function _init_properties_StreamingJsonEncoder_kt__pn1bsi() {
  if (!properties_initialized_StreamingJsonEncoder_kt_6ifwwk) {
    properties_initialized_StreamingJsonEncoder_kt_6ifwwk = true;
    unsignedNumberDescriptors = setOf([serializer_0(Companion_getInstance()).ujf(), serializer_1(Companion_getInstance_0()).ujf(), serializer_2(Companion_getInstance_1()).ujf(), serializer_3(Companion_getInstance_2()).ujf()]);
  }
}
function get_ESCAPE_STRINGS() {
  _init_properties_StringOps_kt__fcy1db();
  return ESCAPE_STRINGS;
}
var ESCAPE_STRINGS;
var ESCAPE_MARKERS;
function toHexChar(i) {
  _init_properties_StringOps_kt__fcy1db();
  var d = i & 15;
  var tmp;
  if (d < 10) {
    // Inline function 'kotlin.code' call
    var this_0 = _Char___init__impl__6a9atx(48);
    var tmp$ret$0 = Char__toInt_impl_vasixd(this_0);
    tmp = numberToChar(d + tmp$ret$0 | 0);
  } else {
    var tmp_0 = d - 10 | 0;
    // Inline function 'kotlin.code' call
    var this_1 = _Char___init__impl__6a9atx(97);
    var tmp$ret$1 = Char__toInt_impl_vasixd(this_1);
    tmp = numberToChar(tmp_0 + tmp$ret$1 | 0);
  }
  return tmp;
}
function printQuoted(_this__u8e3s4, value) {
  _init_properties_StringOps_kt__fcy1db();
  _this__u8e3s4.jc(_Char___init__impl__6a9atx(34));
  var lastPos = 0;
  var inductionVariable = 0;
  var last = charSequenceLength(value) - 1 | 0;
  if (inductionVariable <= last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.code' call
      var this_0 = charSequenceGet(value, i);
      var c = Char__toInt_impl_vasixd(this_0);
      if (c < get_ESCAPE_STRINGS().length && !(get_ESCAPE_STRINGS()[c] == null)) {
        _this__u8e3s4.lh(value, lastPos, i);
        _this__u8e3s4.ic(get_ESCAPE_STRINGS()[c]);
        lastPos = i + 1 | 0;
      }
    }
     while (inductionVariable <= last);
  if (!(lastPos === 0))
    _this__u8e3s4.lh(value, lastPos, value.length);
  else
    _this__u8e3s4.ic(value);
  _this__u8e3s4.jc(_Char___init__impl__6a9atx(34));
}
function toBooleanStrictOrNull(_this__u8e3s4) {
  _init_properties_StringOps_kt__fcy1db();
  return equals_0(_this__u8e3s4, 'true', true) ? true : equals_0(_this__u8e3s4, 'false', true) ? false : null;
}
var properties_initialized_StringOps_kt_wzaea7;
function _init_properties_StringOps_kt__fcy1db() {
  if (!properties_initialized_StringOps_kt_wzaea7) {
    properties_initialized_StringOps_kt_wzaea7 = true;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.arrayOfNulls' call
    var this_0 = fillArrayVal(Array(93), null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.serialization.json.internal.ESCAPE_STRINGS.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable <= 31)
      do {
        var c = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var c1 = toHexChar(c >> 12);
        var c2 = toHexChar(c >> 8);
        var c3 = toHexChar(c >> 4);
        var c4 = toHexChar(c);
        this_0[c] = '\\u' + toString_1(c1) + toString_1(c2) + toString_1(c3) + toString_1(c4);
      }
       while (inductionVariable <= 31);
    // Inline function 'kotlin.code' call
    var this_1 = _Char___init__impl__6a9atx(34);
    this_0[Char__toInt_impl_vasixd(this_1)] = '\\"';
    // Inline function 'kotlin.code' call
    var this_2 = _Char___init__impl__6a9atx(92);
    this_0[Char__toInt_impl_vasixd(this_2)] = '\\\\';
    // Inline function 'kotlin.code' call
    var this_3 = _Char___init__impl__6a9atx(9);
    this_0[Char__toInt_impl_vasixd(this_3)] = '\\t';
    // Inline function 'kotlin.code' call
    var this_4 = _Char___init__impl__6a9atx(8);
    this_0[Char__toInt_impl_vasixd(this_4)] = '\\b';
    // Inline function 'kotlin.code' call
    var this_5 = _Char___init__impl__6a9atx(10);
    this_0[Char__toInt_impl_vasixd(this_5)] = '\\n';
    // Inline function 'kotlin.code' call
    var this_6 = _Char___init__impl__6a9atx(13);
    this_0[Char__toInt_impl_vasixd(this_6)] = '\\r';
    this_0[12] = '\\f';
    ESCAPE_STRINGS = this_0;
    // Inline function 'kotlin.apply' call
    var this_7 = new Int8Array(93);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.serialization.json.internal.ESCAPE_MARKERS.<anonymous>' call
    var inductionVariable_0 = 0;
    if (inductionVariable_0 <= 31)
      do {
        var c_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        this_7[c_0] = 1;
      }
       while (inductionVariable_0 <= 31);
    // Inline function 'kotlin.code' call
    var this_8 = _Char___init__impl__6a9atx(34);
    this_7[Char__toInt_impl_vasixd(this_8)] = 34;
    // Inline function 'kotlin.code' call
    var this_9 = _Char___init__impl__6a9atx(92);
    this_7[Char__toInt_impl_vasixd(this_9)] = 92;
    // Inline function 'kotlin.code' call
    var this_10 = _Char___init__impl__6a9atx(9);
    this_7[Char__toInt_impl_vasixd(this_10)] = 116;
    // Inline function 'kotlin.code' call
    var this_11 = _Char___init__impl__6a9atx(8);
    this_7[Char__toInt_impl_vasixd(this_11)] = 98;
    // Inline function 'kotlin.code' call
    var this_12 = _Char___init__impl__6a9atx(10);
    this_7[Char__toInt_impl_vasixd(this_12)] = 110;
    // Inline function 'kotlin.code' call
    var this_13 = _Char___init__impl__6a9atx(13);
    this_7[Char__toInt_impl_vasixd(this_13)] = 114;
    this_7[12] = 102;
    ESCAPE_MARKERS = this_7;
  }
}
function unparsedPrimitive($this, literal, primitive, tag) {
  var type = startsWith(primitive, 'i') ? 'an ' + primitive : 'a ' + primitive;
  throw JsonDecodingException_0(-1, "Failed to parse literal '" + literal.toString() + "' as " + type + ' value at element: ' + $this.ak3(tag), toString($this.bk3()));
}
function coerceInputValue_0($this, descriptor, index, tag) {
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue' call
    var this_0 = $this.xjy();
    var isOptional = descriptor.xjh(index);
    var elementDescriptor = descriptor.wjh(index);
    var tmp;
    if (isOptional && !elementDescriptor.ojh()) {
      // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.coerceInputValue.<anonymous>' call
      var tmp_0 = $this.ck3(tag);
      tmp = tmp_0 instanceof JsonNull;
    } else {
      tmp = false;
    }
    if (tmp) {
      tmp$ret$1 = true;
      break $l$block_2;
    }
    if (equals(elementDescriptor.yjh(), ENUM_getInstance())) {
      var tmp_1;
      if (elementDescriptor.ojh()) {
        // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.coerceInputValue.<anonymous>' call
        var tmp_2 = $this.ck3(tag);
        tmp_1 = tmp_2 instanceof JsonNull;
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp$ret$1 = false;
        break $l$block_2;
      }
      // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.coerceInputValue.<anonymous>' call
      var tmp_3 = $this.ck3(tag);
      var tmp30_safe_receiver = tmp_3 instanceof JsonPrimitive ? tmp_3 : null;
      var tmp0_elvis_lhs = tmp30_safe_receiver == null ? null : get_contentOrNull(tmp30_safe_receiver);
      var tmp_4;
      if (tmp0_elvis_lhs == null) {
        tmp$ret$1 = false;
        break $l$block_2;
      } else {
        tmp_4 = tmp0_elvis_lhs;
      }
      var enumValue = tmp_4;
      var enumIndex = getJsonNameIndex(elementDescriptor, this_0, enumValue);
      var coerceToNull = !this_0.wjw_1.ljy_1 && elementDescriptor.ojh();
      if (enumIndex === -3 && (isOptional || coerceToNull)) {
        // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue.<anonymous>' call
        tmp$ret$1 = true;
        break $l$block_2;
      }
    }
    tmp$ret$1 = false;
  }
  return tmp$ret$1;
}
function absenceIsNull($this, descriptor, index) {
  $this.yk3_1 = (!$this.xjy().wjw_1.ljy_1 && !descriptor.xjh(index) && descriptor.wjh(index).ojh());
  return $this.yk3_1;
}
function readPolymorphicJson(_this__u8e3s4, discriminator, element, deserializer) {
  return (new JsonTreeDecoder(_this__u8e3s4, element, discriminator, deserializer.ujf())).ojj(deserializer);
}
var WriteMode_OBJ_instance;
var WriteMode_LIST_instance;
var WriteMode_MAP_instance;
var WriteMode_POLY_OBJ_instance;
var WriteMode_entriesInitialized;
function WriteMode_initEntries() {
  if (WriteMode_entriesInitialized)
    return Unit_instance;
  WriteMode_entriesInitialized = true;
  WriteMode_OBJ_instance = new WriteMode('OBJ', 0, _Char___init__impl__6a9atx(123), _Char___init__impl__6a9atx(125));
  WriteMode_LIST_instance = new WriteMode('LIST', 1, _Char___init__impl__6a9atx(91), _Char___init__impl__6a9atx(93));
  WriteMode_MAP_instance = new WriteMode('MAP', 2, _Char___init__impl__6a9atx(123), _Char___init__impl__6a9atx(125));
  WriteMode_POLY_OBJ_instance = new WriteMode('POLY_OBJ', 3, _Char___init__impl__6a9atx(91), _Char___init__impl__6a9atx(93));
}
function switchMode(_this__u8e3s4, desc) {
  var tmp36_subject = desc.yjh();
  var tmp;
  if (tmp36_subject instanceof PolymorphicKind) {
    tmp = WriteMode_POLY_OBJ_getInstance();
  } else {
    if (equals(tmp36_subject, LIST_getInstance())) {
      tmp = WriteMode_LIST_getInstance();
    } else {
      if (equals(tmp36_subject, MAP_getInstance())) {
        // Inline function 'kotlinx.serialization.json.internal.selectMapMode' call
        var keyDescriptor = carrierDescriptor(desc.wjh(0), _this__u8e3s4.ejk());
        var keyKind = keyDescriptor.yjh();
        var tmp_0;
        var tmp_1;
        if (keyKind instanceof PrimitiveKind) {
          tmp_1 = true;
        } else {
          tmp_1 = equals(keyKind, ENUM_getInstance());
        }
        if (tmp_1) {
          // Inline function 'kotlinx.serialization.json.internal.switchMode.<anonymous>' call
          tmp_0 = WriteMode_MAP_getInstance();
        } else {
          if (_this__u8e3s4.wjw_1.jjy_1) {
            // Inline function 'kotlinx.serialization.json.internal.switchMode.<anonymous>' call
            tmp_0 = WriteMode_LIST_getInstance();
          } else {
            throw InvalidKeyKindException(keyDescriptor);
          }
        }
        tmp = tmp_0;
      } else {
        tmp = WriteMode_OBJ_getInstance();
      }
    }
  }
  return tmp;
}
function carrierDescriptor(_this__u8e3s4, module_0) {
  var tmp;
  if (equals(_this__u8e3s4.yjh(), CONTEXTUAL_getInstance())) {
    var tmp37_safe_receiver = getContextualDescriptor(module_0, _this__u8e3s4);
    var tmp0_elvis_lhs = tmp37_safe_receiver == null ? null : carrierDescriptor(tmp37_safe_receiver, module_0);
    tmp = tmp0_elvis_lhs == null ? _this__u8e3s4 : tmp0_elvis_lhs;
  } else if (_this__u8e3s4.zjh()) {
    tmp = carrierDescriptor(_this__u8e3s4.wjh(0), module_0);
  } else {
    tmp = _this__u8e3s4;
  }
  return tmp;
}
function WriteMode_OBJ_getInstance() {
  WriteMode_initEntries();
  return WriteMode_OBJ_instance;
}
function WriteMode_LIST_getInstance() {
  WriteMode_initEntries();
  return WriteMode_LIST_instance;
}
function WriteMode_MAP_getInstance() {
  WriteMode_initEntries();
  return WriteMode_MAP_instance;
}
function WriteMode_POLY_OBJ_getInstance() {
  WriteMode_initEntries();
  return WriteMode_POLY_OBJ_instance;
}
function appendEscape($this, lastPosition, current) {
  $this.dk5(lastPosition, current);
  return appendEsc($this, current + 1 | 0);
}
function decodedString($this, lastPosition, currentPosition) {
  $this.dk5(lastPosition, currentPosition);
  var result = $this.ljx_1.toString();
  $this.ljx_1.th(0);
  return result;
}
function takePeeked($this) {
  // Inline function 'kotlin.also' call
  var this_0 = ensureNotNull($this.kjx_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.takePeeked.<anonymous>' call
  $this.kjx_1 = null;
  return this_0;
}
function wasUnquotedString($this) {
  return !(charSequenceGet($this.ek5(), $this.ijx_1 - 1 | 0) === _Char___init__impl__6a9atx(34));
}
function appendEsc($this, startPosition) {
  var currentPosition = startPosition;
  currentPosition = $this.fk5(currentPosition);
  if (currentPosition === -1) {
    $this.qk0('Expected escape sequence to continue, got EOF');
  }
  var tmp = $this.ek5();
  var _unary__edvuaz = currentPosition;
  currentPosition = _unary__edvuaz + 1 | 0;
  var currentChar = charSequenceGet(tmp, _unary__edvuaz);
  if (currentChar === _Char___init__impl__6a9atx(117)) {
    return appendHex($this, $this.ek5(), currentPosition);
  }
  // Inline function 'kotlin.code' call
  var tmp$ret$0 = Char__toInt_impl_vasixd(currentChar);
  var c = escapeToChar(tmp$ret$0);
  if (c === _Char___init__impl__6a9atx(0)) {
    $this.qk0("Invalid escaped char '" + toString_1(currentChar) + "'");
  }
  $this.ljx_1.jc(c);
  return currentPosition;
}
function appendHex($this, source, startPos) {
  if ((startPos + 4 | 0) >= charSequenceLength(source)) {
    $this.ijx_1 = startPos;
    $this.gk5();
    if (($this.ijx_1 + 4 | 0) >= charSequenceLength(source)) {
      $this.qk0('Unexpected EOF during unicode escape');
    }
    return appendHex($this, source, $this.ijx_1);
  }
  $this.ljx_1.jc(numberToChar((((fromHexChar($this, source, startPos) << 12) + (fromHexChar($this, source, startPos + 1 | 0) << 8) | 0) + (fromHexChar($this, source, startPos + 2 | 0) << 4) | 0) + fromHexChar($this, source, startPos + 3 | 0) | 0));
  return startPos + 4 | 0;
}
function fromHexChar($this, source, currentPosition) {
  var character = charSequenceGet(source, currentPosition);
  var tmp;
  if (_Char___init__impl__6a9atx(48) <= character ? character <= _Char___init__impl__6a9atx(57) : false) {
    // Inline function 'kotlin.code' call
    var tmp_0 = Char__toInt_impl_vasixd(character);
    // Inline function 'kotlin.code' call
    var this_0 = _Char___init__impl__6a9atx(48);
    tmp = tmp_0 - Char__toInt_impl_vasixd(this_0) | 0;
  } else if (_Char___init__impl__6a9atx(97) <= character ? character <= _Char___init__impl__6a9atx(102) : false) {
    // Inline function 'kotlin.code' call
    var tmp_1 = Char__toInt_impl_vasixd(character);
    // Inline function 'kotlin.code' call
    var this_1 = _Char___init__impl__6a9atx(97);
    tmp = (tmp_1 - Char__toInt_impl_vasixd(this_1) | 0) + 10 | 0;
  } else if (_Char___init__impl__6a9atx(65) <= character ? character <= _Char___init__impl__6a9atx(70) : false) {
    // Inline function 'kotlin.code' call
    var tmp_2 = Char__toInt_impl_vasixd(character);
    // Inline function 'kotlin.code' call
    var this_2 = _Char___init__impl__6a9atx(65);
    tmp = (tmp_2 - Char__toInt_impl_vasixd(this_2) | 0) + 10 | 0;
  } else {
    $this.qk0("Invalid toHexChar char '" + toString_1(character) + "' in unicode escape");
  }
  return tmp;
}
function consumeBoolean2($this, start) {
  var current = $this.fk5(start);
  if (current >= charSequenceLength($this.ek5()) || current === -1) {
    $this.qk0('EOF');
  }
  // Inline function 'kotlin.code' call
  var tmp = $this.ek5();
  var _unary__edvuaz = current;
  current = _unary__edvuaz + 1 | 0;
  var this_0 = charSequenceGet(tmp, _unary__edvuaz);
  var tmp42_subject = Char__toInt_impl_vasixd(this_0) | 32;
  var tmp_0;
  // Inline function 'kotlin.code' call
  var this_1 = _Char___init__impl__6a9atx(116);
  if (tmp42_subject === Char__toInt_impl_vasixd(this_1)) {
    consumeBooleanLiteral($this, 'rue', current);
    tmp_0 = true;
  } else {
    // Inline function 'kotlin.code' call
    var this_2 = _Char___init__impl__6a9atx(102);
    if (tmp42_subject === Char__toInt_impl_vasixd(this_2)) {
      consumeBooleanLiteral($this, 'alse', current);
      tmp_0 = false;
    } else {
      $this.qk0("Expected valid boolean literal prefix, but had '" + $this.uk1() + "'");
    }
  }
  return tmp_0;
}
function consumeBooleanLiteral($this, literalSuffix, current) {
  if ((charSequenceLength($this.ek5()) - current | 0) < literalSuffix.length) {
    $this.qk0('Unexpected end of boolean literal');
  }
  var inductionVariable = 0;
  var last = charSequenceLength(literalSuffix) - 1 | 0;
  if (inductionVariable <= last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var expected = charSequenceGet(literalSuffix, i);
      var actual = charSequenceGet($this.ek5(), current + i | 0);
      // Inline function 'kotlin.code' call
      var tmp = Char__toInt_impl_vasixd(expected);
      // Inline function 'kotlin.code' call
      if (!(tmp === (Char__toInt_impl_vasixd(actual) | 32))) {
        $this.qk0("Expected valid boolean literal prefix, but had '" + $this.uk1() + "'");
      }
    }
     while (inductionVariable <= last);
  $this.ijx_1 = current + literalSuffix.length | 0;
}
function consumeNumericLiteral$calculateExponent(exponentAccumulator, isExponentPositive) {
  var tmp;
  switch (isExponentPositive) {
    case false:
      // Inline function 'kotlin.math.pow' call

      var x = -exponentAccumulator.t4();
      tmp = Math.pow(10.0, x);
      break;
    case true:
      // Inline function 'kotlin.math.pow' call

      var x_0 = exponentAccumulator.t4();
      tmp = Math.pow(10.0, x_0);
      break;
    default:
      noWhenBranchMatchedException();
      break;
  }
  return tmp;
}
function charToTokenClass(c) {
  var tmp;
  // Inline function 'kotlin.code' call
  if (Char__toInt_impl_vasixd(c) < 126) {
    var tmp_0 = CharMappings_getInstance().ok5_1;
    // Inline function 'kotlin.code' call
    tmp = tmp_0[Char__toInt_impl_vasixd(c)];
  } else {
    tmp = 0;
  }
  return tmp;
}
function tokenDescription(token) {
  return token === 1 ? "quotation mark '\"'" : token === 2 ? "string escape sequence '\\'" : token === 4 ? "comma ','" : token === 5 ? "colon ':'" : token === 6 ? "start of the object '{'" : token === 7 ? "end of the object '}'" : token === 8 ? "start of the array '['" : token === 9 ? "end of the array ']'" : token === 10 ? 'end of the input' : token === 127 ? 'invalid token' : 'valid token';
}
function escapeToChar(c) {
  return c < 117 ? CharMappings_getInstance().nk5_1[c] : _Char___init__impl__6a9atx(0);
}
function initEscape($this) {
  var inductionVariable = 0;
  if (inductionVariable <= 31)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      initC2ESC($this, i, _Char___init__impl__6a9atx(117));
    }
     while (inductionVariable <= 31);
  initC2ESC($this, 8, _Char___init__impl__6a9atx(98));
  initC2ESC($this, 9, _Char___init__impl__6a9atx(116));
  initC2ESC($this, 10, _Char___init__impl__6a9atx(110));
  initC2ESC($this, 12, _Char___init__impl__6a9atx(102));
  initC2ESC($this, 13, _Char___init__impl__6a9atx(114));
  initC2ESC_0($this, _Char___init__impl__6a9atx(47), _Char___init__impl__6a9atx(47));
  initC2ESC_0($this, _Char___init__impl__6a9atx(34), _Char___init__impl__6a9atx(34));
  initC2ESC_0($this, _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(92));
}
function initCharToToken($this) {
  var inductionVariable = 0;
  if (inductionVariable <= 32)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      initC2TC($this, i, 127);
    }
     while (inductionVariable <= 32);
  initC2TC($this, 9, 3);
  initC2TC($this, 10, 3);
  initC2TC($this, 13, 3);
  initC2TC($this, 32, 3);
  initC2TC_0($this, _Char___init__impl__6a9atx(44), 4);
  initC2TC_0($this, _Char___init__impl__6a9atx(58), 5);
  initC2TC_0($this, _Char___init__impl__6a9atx(123), 6);
  initC2TC_0($this, _Char___init__impl__6a9atx(125), 7);
  initC2TC_0($this, _Char___init__impl__6a9atx(91), 8);
  initC2TC_0($this, _Char___init__impl__6a9atx(93), 9);
  initC2TC_0($this, _Char___init__impl__6a9atx(34), 1);
  initC2TC_0($this, _Char___init__impl__6a9atx(92), 2);
}
function initC2ESC($this, c, esc) {
  if (!(esc === _Char___init__impl__6a9atx(117))) {
    // Inline function 'kotlin.code' call
    var tmp$ret$0 = Char__toInt_impl_vasixd(esc);
    $this.nk5_1[tmp$ret$0] = numberToChar(c);
  }
}
function initC2ESC_0($this, c, esc) {
  // Inline function 'kotlin.code' call
  var tmp$ret$0 = Char__toInt_impl_vasixd(c);
  return initC2ESC($this, tmp$ret$0, esc);
}
function initC2TC($this, c, cl) {
  $this.ok5_1[c] = cl;
}
function initC2TC_0($this, c, cl) {
  // Inline function 'kotlin.code' call
  var tmp$ret$0 = Char__toInt_impl_vasixd(c);
  return initC2TC($this, tmp$ret$0, cl);
}
var CharMappings_instance;
function CharMappings_getInstance() {
  if (CharMappings_instance === VOID)
    new CharMappings();
  return CharMappings_instance;
}
function StringJsonLexer_0(json, source) {
  return !json.wjw_1.vjy_1 ? new StringJsonLexer(source) : new StringJsonLexerWithComments(source);
}
function get_schemaCache(_this__u8e3s4) {
  return _this__u8e3s4.yjw_1;
}
function createMapForCache(initialCapacity) {
  return HashMap.q9(initialCapacity);
}
//region block: post-declaration
initMetadataForClass(Json, 'Json');
initMetadataForObject(Default, 'Default');
initMetadataForClass(JsonBuilder, 'JsonBuilder');
initMetadataForClass(JsonImpl, 'JsonImpl');
initMetadataForClass(JsonClassDiscriminator, 'JsonClassDiscriminator');
initMetadataForClass(JsonNames, 'JsonNames');
initMetadataForClass(JsonConfiguration, 'JsonConfiguration');
initMetadataForClass(ClassDiscriminatorMode, 'ClassDiscriminatorMode');
initMetadataForInterface(JsonDecoder, 'JsonDecoder', VOID, VOID, [Decoder, CompositeDecoder]);
initMetadataForCompanion(Companion);
initMetadataForClass(JsonElement, 'JsonElement', VOID, VOID, VOID, VOID, VOID, {0: JsonElementSerializer_getInstance});
initMetadataForCompanion(Companion_0);
initMetadataForClass(JsonArray, 'JsonArray', VOID, VOID, [JsonElement, KtList], VOID, VOID, {0: JsonArraySerializer_getInstance});
initMetadataForCompanion(Companion_1);
initMetadataForClass(JsonObject, 'JsonObject', VOID, VOID, [JsonElement, KtMap], VOID, VOID, {0: JsonObjectSerializer_getInstance});
initMetadataForCompanion(Companion_2);
initMetadataForClass(JsonPrimitive, 'JsonPrimitive', VOID, VOID, VOID, VOID, VOID, {0: JsonPrimitiveSerializer_getInstance});
initMetadataForObject(JsonNull, 'JsonNull', VOID, VOID, [JsonPrimitive, SerializerFactory], VOID, VOID, {0: JsonNull_getInstance});
initMetadataForClass(JsonLiteral, 'JsonLiteral');
initMetadataForObject(JsonElementSerializer, 'JsonElementSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(JsonArrayDescriptor, 'JsonArrayDescriptor', VOID, VOID, [SerialDescriptor]);
initMetadataForObject(JsonArraySerializer, 'JsonArraySerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(JsonObjectDescriptor, 'JsonObjectDescriptor', VOID, VOID, [SerialDescriptor]);
initMetadataForObject(JsonObjectSerializer, 'JsonObjectSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(JsonPrimitiveSerializer, 'JsonPrimitiveSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(JsonNullSerializer, 'JsonNullSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(JsonLiteralSerializer, 'JsonLiteralSerializer', VOID, VOID, [KSerializer]);
protoOf(defer$1).ojh = get_isNullable;
protoOf(defer$1).zjh = get_isInline;
protoOf(defer$1).bji = get_annotations;
initMetadataForClass(defer$1, VOID, VOID, VOID, [SerialDescriptor]);
initMetadataForClass(JsonElementMarker, 'JsonElementMarker');
initMetadataForClass(JsonException, 'JsonException');
initMetadataForClass(JsonDecodingException, 'JsonDecodingException');
initMetadataForClass(JsonEncodingException, 'JsonEncodingException');
initMetadataForObject(Tombstone, 'Tombstone');
initMetadataForClass(JsonPath, 'JsonPath', JsonPath);
initMetadataForLambda(JsonTreeReader$readDeepRecursive$slambda, VOID, VOID, [2]);
initMetadataForClass(JsonTreeReader, 'JsonTreeReader', VOID, VOID, VOID, [0]);
protoOf(PolymorphismValidator).pjw = contextual;
initMetadataForClass(PolymorphismValidator, 'PolymorphismValidator', VOID, VOID, [SerializersModuleCollector]);
initMetadataForClass(Key, 'Key', Key);
initMetadataForClass(DescriptorSchemaCache, 'DescriptorSchemaCache', DescriptorSchemaCache);
initMetadataForClass(DiscriminatorHolder, 'DiscriminatorHolder');
initMetadataForClass(StreamingJsonDecoder, 'StreamingJsonDecoder', VOID, VOID, [JsonDecoder, AbstractDecoder]);
initMetadataForClass(JsonDecoderForUnsignedTypes, 'JsonDecoderForUnsignedTypes');
initMetadataForClass(AbstractJsonTreeDecoder, 'AbstractJsonTreeDecoder', VOID, VOID, [NamedValueDecoder, JsonDecoder]);
initMetadataForClass(JsonTreeDecoder, 'JsonTreeDecoder');
initMetadataForClass(JsonTreeListDecoder, 'JsonTreeListDecoder');
initMetadataForClass(JsonPrimitiveDecoder, 'JsonPrimitiveDecoder');
initMetadataForClass(JsonTreeMapDecoder, 'JsonTreeMapDecoder');
initMetadataForClass(WriteMode, 'WriteMode');
initMetadataForClass(AbstractJsonLexer, 'AbstractJsonLexer');
initMetadataForObject(CharMappings, 'CharMappings');
initMetadataForClass(StringJsonLexer, 'StringJsonLexer');
initMetadataForClass(StringJsonLexerWithComments, 'StringJsonLexerWithComments');
//endregion
//region block: init
Companion_instance = new Companion();
Companion_instance_0 = new Companion_0();
Companion_instance_1 = new Companion_1();
Companion_instance_2 = new Companion_2();
Tombstone_instance = new Tombstone();
//endregion
//region block: exports
export {
  JsonArraySerializer_getInstance as JsonArraySerializer_getInstance29kwliignms5j,
  JsonElementSerializer_getInstance as JsonElementSerializer_getInstance1rgo1giz8g15y,
  JsonObjectSerializer_getInstance as JsonObjectSerializer_getInstance197qdg6ernwdp,
  JsonArray as JsonArray2urf8ey7u44sd,
  JsonNull as JsonNull2liwjj96vm0w2,
  JsonObject as JsonObjectee06ihoeeiqj,
  JsonPrimitive as JsonPrimitive3ttzjh2ft5dnx,
  Json_0 as Jsonsmkyu9xjl7fv,
  get_jsonPrimitive as get_jsonPrimitivez17tyd5rw1ql,
};
//endregion
