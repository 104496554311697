import {
  DurationUnit_SECONDS_getInstance3jias9ne5z4er as DurationUnit_SECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  VOID7hggqo3abtya as VOID,
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  equals2au1ep9vhcato as equals,
  Duration__compareTo_impl_pchp0f1z7ae3ty3s6c1 as Duration__compareTo_impl_pchp0f,
} from './kotlin-kotlin-stdlib.mjs';
import {
  get_fastSeconds3ld6xqdi008l3 as get_fastSeconds,
  _FastDuration___get_seconds__impl__4kvvav2y79dbfyhbo29 as _FastDuration___get_seconds__impl__4kvvav,
  get_CoreTime23yr4od90u6o9 as get_CoreTime,
  get_fastMillisecondsfxaeyfzoedsj as get_fastMilliseconds,
  _FastDuration___get_nanoseconds__impl__pxizcb3qjtomx85900t as _FastDuration___get_nanoseconds__impl__pxizcb,
  _FastDuration___get_microseconds__impl__o4j93h1to7wq49algof as _FastDuration___get_microseconds__impl__o4j93h,
  get_slowaqovrrnrezzv as get_slow,
  get_fastNanosecondsibv7lnrlu50o as get_fastNanoseconds,
  Companion_instance2bvf2vakw640b as Companion_instance,
  Companion_getInstance3moaeo8p2fvjj as Companion_getInstance,
  DateTime__minus_impl_10njw81nqbgaa48pgq4 as DateTime__minus_impl_10njw8,
} from './korlibs-korlibs-time-core.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class PerformanceCounter {
  constructor() {
    PerformanceCounter_instance = this;
    this.g88_1 = nowMillis(this);
  }
  h88() {
    return _FastDuration___get_nanoseconds__impl__pxizcb(ellapsed(this));
  }
  i88() {
    return _FastDuration___get_microseconds__impl__o4j93h(ellapsed(this));
  }
  j88() {
    return get_slow(ellapsed(this));
  }
  k88() {
    return ellapsed(this);
  }
}
class Stopwatch {
  constructor(nanosecondProvider) {
    var tmp;
    if (nanosecondProvider === VOID) {
      tmp = Stopwatch$_init_$lambda_lg3mf2;
    } else {
      tmp = nanosecondProvider;
    }
    nanosecondProvider = tmp;
    this.l88_1 = nanosecondProvider;
    this.m88_1 = false;
    this.n88_1 = 0.0;
    setStart(this);
  }
  o88() {
    return this.m88_1 ? _get_currentNano__jndyu4(this) - this.n88_1 : this.n88_1;
  }
  p88() {
    return get_fastNanoseconds(this.o88());
  }
}
class IntTimedCache {
  constructor(ttl, timeProvider, gen) {
    timeProvider = timeProvider === VOID ? Companion_instance : timeProvider;
    this.q88_1 = ttl;
    this.r88_1 = timeProvider;
    this.s88_1 = gen;
    this.t88_1 = Companion_getInstance().c1w_1;
    this.u88_1 = 0;
  }
  ko(obj, property) {
    // Inline function 'korlibs.time.IntTimedCache.get' call
    var now = this.r88_1.h1w();
    if (equals(this.t88_1, Companion_getInstance().c1w_1) || Duration__compareTo_impl_pchp0f(DateTime__minus_impl_10njw8(now, this.t88_1), this.q88_1) >= 0) {
      this.t88_1 = now;
      this.u88_1 = this.s88_1();
    }
    return this.u88_1;
  }
}
//endregion
function _Frequency___init__impl__upasnx(hertz) {
  return hertz;
}
function _Frequency___get_hertz__impl__9m7k6x($this) {
  return $this;
}
function _Frequency___get_timeSpan__impl__f9mrmt($this) {
  return _Frequency___get_duration__impl__om2ay0($this);
}
function _Frequency___get_duration__impl__om2ay0($this) {
  // Inline function 'korlibs.time.seconds' call
  var this_0 = 1.0 / _Frequency___get_hertz__impl__9m7k6x($this);
  return toDuration(this_0, DurationUnit_SECONDS_getInstance());
}
function _Frequency___get_fastDuration__impl__87web0($this) {
  return get_fastSeconds(1.0 / _Frequency___get_hertz__impl__9m7k6x($this));
}
function get_hz(_this__u8e3s4) {
  return get_timesPerSecond(_this__u8e3s4);
}
function toFrequency(_this__u8e3s4) {
  return get_timesPerSecond_0(_this__u8e3s4);
}
function get_timesPerSecond(_this__u8e3s4) {
  return _Frequency___init__impl__upasnx(_this__u8e3s4);
}
function get_timesPerSecond_0(_this__u8e3s4) {
  return _Frequency___init__impl__upasnx(1.0 / _FastDuration___get_seconds__impl__4kvvav(_this__u8e3s4));
}
var KlockLocale_default;
function nowMillis($this) {
  return get_CoreTime().r1w();
}
function ellapsed($this) {
  return get_fastMilliseconds(nowMillis($this) - $this.g88_1);
}
var PerformanceCounter_instance;
function PerformanceCounter_getInstance() {
  if (PerformanceCounter_instance === VOID)
    new PerformanceCounter();
  return PerformanceCounter_instance;
}
function _get_currentNano__jndyu4($this) {
  return $this.l88_1();
}
function setStart($this) {
  $this.n88_1 = _get_currentNano__jndyu4($this);
}
function Stopwatch$_init_$lambda_lg3mf2() {
  return PerformanceCounter_getInstance().h88();
}
var ExtendedTimezoneNamesOrNull;
//region block: post-declaration
initMetadataForObject(PerformanceCounter, 'PerformanceCounter');
initMetadataForClass(Stopwatch, 'Stopwatch', Stopwatch);
initMetadataForClass(IntTimedCache, 'IntTimedCache');
//endregion
//region block: init
KlockLocale_default = null;
ExtendedTimezoneNamesOrNull = null;
//endregion
//region block: exports
export {
  _Frequency___get_fastDuration__impl__87web0 as _Frequency___get_fastDuration__impl__87web02ham1qlkmkeau,
  _Frequency___get_hertz__impl__9m7k6x as _Frequency___get_hertz__impl__9m7k6x37zmlsrxcrsca,
  _Frequency___get_timeSpan__impl__f9mrmt as _Frequency___get_timeSpan__impl__f9mrmt72kalre6wihh,
  PerformanceCounter_getInstance as PerformanceCounter_getInstance23wo5gavc7ilf,
  IntTimedCache as IntTimedCache39ymx9abjz88x,
  Stopwatch as Stopwatch1guqi4xl0rpfb,
  get_hz as get_hz3a5o5age7b808,
  get_timesPerSecond as get_timesPerSecond1juuclsmjle05,
  toFrequency as toFrequency3flbtmp5xney7,
};
//endregion
