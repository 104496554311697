import {
  VOID7hggqo3abtya as VOID,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  isInterface3d6p8outrmvmk as isInterface,
  toString1pkumu07cwy4m as toString,
  toString30pk9tzaqopn as toString_0,
  hashCodeq5arwsb9dgti as hashCode,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  equals2au1ep9vhcato as equals,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  println2shhhgwwt4c61 as println,
  DurationUnit_MICROSECONDS_getInstance2dy62s6vvg5md as DurationUnit_MICROSECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  printStackTrace18lnx7a39cni as printStackTrace,
  getKClass1s3j9wy1cofik as getKClass,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  first58ocm7j58k3q as first,
  CoroutineContext3n9ge8bnnq7z2 as CoroutineContext,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  ArrayList3it5z8td81qkl as ArrayList,
  toList383f556t1dixk as toList,
  toMutableList20rdgwi7d3cwi as toMutableList,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  DurationUnit_MILLISECONDS_getInstance15owevua4zjxe as DurationUnit_MILLISECONDS_getInstance,
  CancellationException3b36o9qz53rgr as CancellationException,
  checkIndexOverflow3frtmheghr0th as checkIndexOverflow,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  lazy2hsh8ze7j6ikd as lazy,
  toList3jhuyej2anx2q as toList_0,
  retainAll1hufpctl19y6u as retainAll,
  toSet2orjxp16sotqu as toSet,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  KMutableProperty025txtn5b59pq1 as KMutableProperty0,
  KtMutableMap1kqeifoi36kpz as KtMutableMap,
  LinkedHashSet2tkztfx86kyx2 as LinkedHashSet,
  addAll1k27qatfgp3k5 as addAll,
  KMutableProperty11e8g1gb0ecb9j as KMutableProperty1,
  Enum3alwj03lh1n41 as Enum,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  Duration5ynfiptaqcrg as Duration,
  listOfvhqybd2zx248 as listOf,
  toSet1glep2u1u9tcb as toSet_0,
  AutoCloseable1l5p57f9lp7kv as AutoCloseable,
  isNaNymqb93xtq8w8 as isNaN_0,
  to2cs3ny02qtbcb as to,
  Duration__minus_impl_q5cfm7ntz6q4kzlr5l as Duration__minus_impl_q5cfm7,
  Duration__compareTo_impl_pchp0f1z7ae3ty3s6c1 as Duration__compareTo_impl_pchp0f,
  DurationUnit_SECONDS_getInstance3jias9ne5z4er as DurationUnit_SECONDS_getInstance,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  numberToDouble210hrknaofnhf as numberToDouble,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  KClass1cc9rfeybg8hs as KClass,
  emptyList1g2z5xcrvp2zy as emptyList,
  arrayListOf1fz8nib0ncbow as arrayListOf,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  protoOf180f3jzyo7rfj as protoOf,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  KProperty02ce7r476m8633 as KProperty0,
  THROW_ISE25y4rao9gcv5s as THROW_ISE,
  getLocalDelegateReferencenlta2y7wt3po as getLocalDelegateReference,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  numberRangeToNumber25vse2rgp6rs8 as numberRangeToNumber,
  Char__toInt_impl_vasixd1g1ubcha2b2yr as Char__toInt_impl_vasixd,
  abs1kdzbjes1idip as abs,
  until1jbpn0z3f8lbg as until,
  get_sign1dnxi33u9vk0c as get_sign,
  isLetterOrDigit2kuxd9e198haf as isLetterOrDigit,
  Companion_instance3fplhgf4ipvld as Companion_instance,
  _Result___init__impl__xyqfz831xktsyjq1qrq as _Result___init__impl__xyqfz8,
  intercepted2ogpsikxxj4u0 as intercepted,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  maxOrNull5i0eu9xqz7pb as maxOrNull,
  returnIfSuspended1qp7gd6asibpv as returnIfSuspended,
  Regexxgw0gjiagf4z as Regex,
  Companion_getInstance3kme2txpd8mob as Companion_getInstance_0,
  RegexOption_IGNORE_CASE_getInstance1e6t23ud6ux7i as RegexOption_IGNORE_CASE_getInstance,
  firstOrNulltrxqttxfxqju as firstOrNull,
  Char19o2r8palgjof as Char,
  dropLastlqc2oyv04br0 as dropLast,
  indexOfa2zokh3tu4p6 as indexOf,
  getOrNull1go7ef9ldk0df as getOrNull,
  contains3ue2qo8xhmpf1 as contains,
  toDoubleOrNullkxwozihadygj as toDoubleOrNull,
  Comparator2b3maoeh98xtg as Comparator,
  compareValues1n2ayl87ihzfk as compareValues,
  sortedWith2csnbbb21k0lg as sortedWith,
  contains1tccixv8iwdcq as contains_0,
  copyToArray2j022khrow2yi as copyToArray,
  repeat2w4c6j8zoq09o as repeat,
  last1vo29oleiqj36 as last,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  listOf1jh22dvmctj1r as listOf_0,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  compareTo3ankvs086tmwq as compareTo,
  sortWith4fnm6b3vw03s as sortWith,
  Collection1k04j3hzsbod0 as Collection,
  MutableIterablez3x4ksk1fmrm as MutableIterable,
  KtList3hktaavzmj137 as KtList,
  firstOrNull1982767dljvdy as firstOrNull_0,
  coerceAtMost322komnqp70ag as coerceAtMost,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  Duration__times_impl_sfuzvped9o6pl6oatt as Duration__times_impl_sfuzvp,
  Duration__div_impl_dknbf438qfgvtpimpa9 as Duration__div_impl_dknbf4,
  NotImplementedErrorfzlkpv14xxr8 as NotImplementedError,
  HashMap1a0ld5kgwhmhv as HashMap,
  replace3le3ie7l9k8aq as replace,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  arrayCopytctsywo3h7gj as arrayCopy,
  KProperty22bogvl36563zx as KProperty2,
  plus310ted5e4i90h as plus,
  createThis2j2avj17cvnv2 as createThis,
  mapOf1xd03cq9cnmy8 as mapOf,
  isFinite1tx0gn65nl9tj as isFinite,
  get_lastIndex327lnwnipm748 as get_lastIndex,
  countTrailingZeroBitszhs0313cn11e as countTrailingZeroBits,
  removeAll3o43e67jmwdpc as removeAll,
  filterNotNull3qfgcwmxhwfxe as filterNotNull,
  getOrNull1d60i0672n7ns as getOrNull_0,
  Monotonic_instance19wu6xfdyzm85 as Monotonic_instance,
  ValueTimeMark__elapsedNow_impl_eonqvs2ntz2k8jdtq63 as ValueTimeMark__elapsedNow_impl_eonqvs,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Companion_getInstance1lqql7kjbk545 as Companion_getInstance_1,
  Level_ERROR_getInstance3sgixyayx9gy9 as Level_ERROR_getInstance,
  Level_TRACE_getInstance3v3hjwgnnbwb6 as Level_TRACE_getInstance,
  Level_INFO_getInstance2ziz2o3m3loni as Level_INFO_getInstance,
  Level_DEBUG_getInstance2cjwrm9110zsz as Level_DEBUG_getInstance,
} from './korlibs-korlibs-logger.mjs';
import {
  Companion_getInstance15h5aaybcsdep as Companion_getInstance_2,
  waitOne1uk08owur305i as waitOne,
  invoke2sf88d1xeuyqh as invoke,
  Signal3nnez3346azzf as Signal,
  AsyncSignal3p186s2w7f15b as AsyncSignal,
  Signal22rzcw7xf4vkv7 as Signal2,
  Lock5n7hh3dgcidl as Lock,
} from './korlibs-korlibs-concurrent.mjs';
import {
  withContext2i47m7ae4v1sd as withContext,
  CoroutineScopefcb5f5dwqcas as CoroutineScope,
  coroutineScopesisx8kbb9f8x as coroutineScope,
  SupervisorJobythnfxkr3jxc as SupervisorJob,
  CompletableDeferred2lnqvsbvx74d3 as CompletableDeferred,
  cancel2en0dn4yvpufo as cancel,
  Key_instance3o7pj7ik1b183 as Key_instance,
  Job13y4jkazwjho0 as Job,
  CancellableContinuationImpl1cx201opicavg as CancellableContinuationImpl,
  TimeoutCancellationException198b5zwr3v3uw as TimeoutCancellationException,
  withTimeout3hvfy7xgqgnvm as withTimeout,
  CoroutineScopelux7s7zphw7e as CoroutineScope_0,
} from './kotlinx-coroutines-core.mjs';
import {
  get_RegisteredImageFormats243xf60qkmjxj as get_RegisteredImageFormats,
  Colors_getInstance1qvds1isfbag2 as Colors_getInstance,
  RGBA34obx2i62wcl3 as RGBA,
  RGBA__toString_impl_4lw6dp2cnrrhh9rakoc as RGBA__toString_impl_4lw6dp,
  RGBA__hashCode_impl_h59qf63jsbsnnrny7w3 as RGBA__hashCode_impl_h59qf6,
  readBitmap1dn2o52mvbpv4 as readBitmap,
  Bitmaps_instancebct336c8d0m7 as Bitmaps_instance,
  get_Bitmaps_white37u58wbn8ngk2 as get_Bitmaps_white,
  RGBA__withAd_impl_cralaopec5nyxs57ng as RGBA__withAd_impl_cralao,
  Companion_getInstanceocd6ti8e4l21 as Companion_getInstance_3,
  _RGBA___get_premultipliedFast__impl__qiau4g1xqusknfne7tm as _RGBA___get_premultipliedFast__impl__qiau4g,
  get_lazyBitmap3pyj425xl7cp4 as get_lazyBitmap,
  RGBA__times_impl_wehs9nfzkdmuyd5ryl as RGBA__times_impl_wehs9n,
  get_DefaultTtfFontAsBitmap1hzwi8e4truhb as get_DefaultTtfFontAsBitmap,
  Companion_getInstance2fl1toxz5lf8a as Companion_getInstance_4,
  Font2bg7nte760csr as Font,
  get_Bitmaps_transparent3nm2857y3u3hn as get_Bitmaps_transparent,
  MaterialColors_getInstance1lpfveu52xgfj as MaterialColors_getInstance,
  RichTextData1m4oei1jdcuz5 as RichTextData,
  Style3c9y3it7ye9du as Style,
  _RGBA___get_hexString__impl__kocngmzp2nzhttcejz as _RGBA___get_hexString__impl__kocngm,
  Companion_getInstanceqt8o2xg52sv0 as Companion_getInstance_5,
  fromHTMLu0erjeijja8h as fromHTML,
  MutableAtlas41tbjanvwcb0 as MutableAtlas,
  Bitmap321klycakvwgb5w as Bitmap32,
  add2qjqug2ygwo4y as add,
  get_bounds3v18gk1bgnv5r as get_bounds,
  _RGBA___get_a__impl__6msvu72gkofe1pz7ypd as _RGBA___get_a__impl__6msvu7,
  RGBA__withAf_impl_dpdu321jt40ermi0we8 as RGBA__withAf_impl_dpdu32,
  _RGBA___get_ad__impl__pt5rdj3f7zi8gf9z65b as _RGBA___get_ad__impl__pt5rdj,
  get_DefaultStringTextRendererdyzggul2pyry as get_DefaultStringTextRenderer,
  bounds39r89eou7x4l9 as bounds,
  interpolate2qv1iflz655kr as interpolate,
  get_bmpevghw2zw2fa7 as get_bmp,
  NativeImageOrBitmap3211fg7set8uiki as NativeImageOrBitmap32,
  slice2pfxfvku0ybgy as slice,
  get_isLow1k7uocm2ojjr8 as get_isLow,
  ShapeBuilderk05da5wk16e9 as ShapeBuilder,
  EmptyShape_instance7pcf1jiur1zr as EmptyShape_instance,
  readBitmapSlice1mxfjm5uys58e as readBitmapSlice,
  readFont45tmpsdawgck as readFont,
  HorizontalAlign__getOffsetX_impl_urzzxfwwtut2j2hfgv as HorizontalAlign__getOffsetX_impl_urzzxf,
  VerticalAlign__getOffsetY_impl_iso0y61hxbkot1sg4z0 as VerticalAlign__getOffsetY_impl_iso0y6,
  Text2TextRendererActions1307nbc4pqqhs as Text2TextRendererActions,
  Entry3jfmfe0iy8gwc as Entry,
  FontMetrics1b1o1zbrk6cew as FontMetrics,
  TextMetrics1vfyzci74zdhy as TextMetrics,
  BitmapFont35unsvaxq1brd as BitmapFont,
  getTextBoundsWithGlyphsa2ggkffutq4d as getTextBoundsWithGlyphs,
  getTextBoundsio1pfpt2q8ze as getTextBounds,
  TextMetricsResult1qr6b0fc6i56z as TextMetricsResult,
  invoke2ot88cm6qcfmr as invoke_0,
  LineInfo27uw564y6w2d2 as LineInfo,
  _VerticalAlign___get_ratioFake__impl__1h2fwv3b9t7y5yljykc as _VerticalAlign___get_ratioFake__impl__1h2fwv,
  Companion_getInstance1rfeljt05xntw as Companion_getInstance_6,
  NativeImage33w1i7ec8zfym as NativeImage,
  drawRichTextyqpa5ren8u0j as drawRichText,
  placem8wpiy4fm4tw as place,
  readVectorImage3lc7lmj3f80g8 as readVectorImage,
  ColorTransformMul2rawiv5hyxh9p as ColorTransformMul,
  _RGBA___get_rgb__impl__tijaqt1opkd4mw5vm2z as _RGBA___get_rgb__impl__tijaqt,
  _RGBA___get_value__impl__hhco8v1fb2bqjo5n70o as _RGBA___get_value__impl__hhco8v,
  Bitmap32Context2d1xx7pfswf9phl as Bitmap32Context2d,
  CycleMethod_NO_CYCLE_getInstancebe2q8ehvdorv as CycleMethod_NO_CYCLE_getInstance,
  GradientKind_LINEAR_getInstance2913i2r9znmz6 as GradientKind_LINEAR_getInstance,
  GradientPaint1uc4xuo0s2w8w as GradientPaint,
  GradientKind_RADIAL_getInstance1ck78a4imhrkm as GradientKind_RADIAL_getInstance,
  GradientKind_SWEEP_getInstance2nsgf0mxeho86 as GradientKind_SWEEP_getInstance,
  TileMapRepeat_NONE_getInstance1rwcpdxdrcp50 as TileMapRepeat_NONE_getInstance,
  _Tile___get_isInvalid__impl__yfg9ed173l8j2a3h38e as _Tile___get_isInvalid__impl__yfg9ed,
  _Tile___get_tile__impl__nfq1aw343zt1qckjepn as _Tile___get_tile__impl__nfq1aw,
  _Tile___get_flipX__impl__jobqpfe9cgmzzfemed as _Tile___get_flipX__impl__jobqpf,
  _Tile___get_flipY__impl__95aqcseyyd2ly5g92f as _Tile___get_flipY__impl__95aqcs,
  _Tile___get_rotate__impl__5kjdv91g1hqxlteoxnf as _Tile___get_rotate__impl__5kjdv9,
  _Tile___get_offsetX__impl__qwhdsd2u0qshojurkrv as _Tile___get_offsetX__impl__qwhdsd,
  _Tile___get_offsetY__impl__1x539um3hkx58tbqvt as _Tile___get_offsetY__impl__1x539u,
  TileMapData285v3okd3krdh as TileMapData,
  PolylineShape2w5p5crr94b97 as PolylineShape,
  FillShape13c5zk3dmjgcf as FillShape,
  TextShape3u9vrw15swk1x as TextShape,
  CompoundShape2jsp34o6wp7wp as CompoundShape,
  _RGBA___get_premultiplied__impl__3bulqcbb8bnh8j0y24 as _RGBA___get_premultiplied__impl__3bulqc,
  _RgbaPremultipliedArray___init__impl__lx83hyhrkrjwkiqj0l as _RgbaPremultipliedArray___init__impl__lx83hy,
  BitmapPaintgyia0tdij0bl as BitmapPaint,
  NonePaint2h93brzruznft as NonePaint,
} from './korlibs-korlibs-image.mjs';
import {
  Companion_instance2bvf2vakw640b as Companion_instance_0,
  Companion_instance2mxqoacslm62w as Companion_instance_1,
  Companion_getInstance3moaeo8p2fvjj as Companion_getInstance_7,
  DateTime2gld4ho36azq9 as DateTime,
  TimeProvider2j6wl2le6n0p3 as TimeProvider,
  FastDuration3d3pupg2e5ora as FastDuration,
  FastDuration__times_impl_h6amcp25vm4d6obfh9l as FastDuration__times_impl_h6amcp,
  get_fastSecondsxsf72e0ltu4j as get_fastSeconds,
  FastDuration__compareTo_impl_4fhnj71uadlvoozsr50 as FastDuration__compareTo_impl_4fhnj7,
  _FastDuration___get_fastMilliseconds__impl__pe68va279q35r54j5bc as _FastDuration___get_fastMilliseconds__impl__pe68va,
  get_fastMillisecondsfxaeyfzoedsj as get_fastMilliseconds,
  FastDuration__compareTo_impl_4fhnj71anf17lkhs1by as FastDuration__compareTo_impl_4fhnj7_0,
  get_fastMilliseconds2pmoifl74b2oz as get_fastMilliseconds_0,
  FastDuration__toString_impl_c9xohb3cvph1y7s95v4 as FastDuration__toString_impl_c9xohb,
  FastDuration__plus_impl_q1enab3lvjsvnxno7fk as FastDuration__plus_impl_q1enab,
  _FastDuration___get_fastSeconds__impl__amo4s3p9zx52w3o39j as _FastDuration___get_fastSeconds__impl__amo4s3,
  FastDuration__minus_impl_evs2371fjveki3j5gyc as FastDuration__minus_impl_evs237,
  get_slowaqovrrnrezzv as get_slow,
  get_fast37cu8s8qwnuyi as get_fast,
  DateTime__minus_impl_10njw81nqbgaa48pgq4 as DateTime__minus_impl_10njw8,
  DateTime__hashCode_impl_yf0bc92p1oa6fp4l38p as DateTime__hashCode_impl_yf0bc9,
  get_DURATION_NIL2lkiawowwp1us as get_DURATION_NIL,
  FastDuration__div_impl_rlslck1plvf7n5rb7aa as FastDuration__div_impl_rlslck,
  _FastDuration___get_nanoseconds__impl__pxizcb3qjtomx85900t as _FastDuration___get_nanoseconds__impl__pxizcb,
  get_fastNanosecondsibv7lnrlu50o as get_fastNanoseconds,
  get_secondsjblpw5qd4dzj as get_seconds,
  _FastDuration___get_milliseconds__impl__ffr17uhz706a3hbxx1 as _FastDuration___get_milliseconds__impl__ffr17u,
  _FastDuration___get_seconds__impl__4kvvav2y79dbfyhbo29 as _FastDuration___get_seconds__impl__4kvvav,
  get_fastMicroseconds3xewe49ldi5s as get_fastMicroseconds,
  div1s6t4x2ts64sh as div,
  get_fastMicroseconds16wth98xujxdo as get_fastMicroseconds_0,
  _FastDuration___get_fastMicroseconds__impl__e641g13hz45luccu2o2 as _FastDuration___get_fastMicroseconds__impl__e641g1,
  FastDuration__div_impl_rlslck15f8mt5g6dyds as FastDuration__div_impl_rlslck_0,
  toDuration15w5sljvx2ut2 as toDuration_0,
  get_milliseconds20vru1dkca6bq as get_milliseconds,
} from './korlibs-korlibs-time-core.mjs';
import {
  Injectorxjyp19tp5mfu as Injector,
  InjectorContextps0ngmjb9onz as InjectorContext,
  InjectorDependency2f8dsqe1kn3nn as InjectorDependency,
  injector13ltfovoybm52 as injector,
} from './korlibs-korlibs-inject.mjs';
import {
  Companion_getInstance12gtxroo40wno as Companion_getInstance_8,
  DefaultViewport_getInstance3j3s2jy6zu9ca as DefaultViewport_getInstance,
  Quality_PERFORMANCE_getInstance2im0li1v0jac6 as Quality_PERFORMANCE_getInstance,
  GameWindowCreationConfigbzmga8g39et1 as GameWindowCreationConfig,
  configure1a7py4k2lj4vi as configure,
  Input3ljaq07eyi73v as Input,
  Statslnkcsgem8jjl as Stats,
  AGPrint2do8iynklt71b as AGPrint,
  GameWindow35197f3hcbh1b as GameWindow,
  KorgeReload_getInstance1i7uio6gustu7 as KorgeReload_getInstance,
  configureLoggerFromProperties1t3glidkjwd17 as configureLoggerFromProperties,
  CoroutineKey_getInstance3nlh6pc2noqhi as CoroutineKey_getInstance,
  CreateDefaultGameWindow1l94icsbq4y8h as CreateDefaultGameWindow,
  Kind_MOUSE_getInstance1x6f2nb0du729 as Kind_MOUSE_getInstance,
  ResourcesRoot36ooexy6oq69y as ResourcesRoot,
  Type_START_getInstancegn6n8lkvuoxm as Type_START_getInstance,
  Status_ADD_getInstance2ikhpw2btzfzo as Status_ADD_getInstance,
  Type_END_getInstance331lr99r7xh7i as Type_END_getInstance,
  Status_REMOVE_getInstance21rl9vf9qjrb0 as Status_REMOVE_getInstance,
  Type_MOVE_getInstance2g4jkr289s1a8 as Type_MOVE_getInstance,
  Status_KEEP_getInstance3p1s7ste35m5y as Status_KEEP_getInstance,
  MouseButton_LEFT_getInstance2dunhocwmk9pi as MouseButton_LEFT_getInstance,
  Type_DRAG_getInstance3nknmoqnyfvel as Type_DRAG_getInstance,
  Type_UP_getInstance3cbsyzwl1abw4 as Type_UP_getInstance,
  Type_DOWN_getInstance20slx18g3n9iw as Type_DOWN_getInstance,
  AG35f0xk1ko87rh as AG,
  TouchEvent37l81isz00qgj as TouchEvent,
  Companion_getInstance2sn5bsju340al as Companion_getInstance_9,
  Companion_getInstancezx9mtebwpbyq as Companion_getInstance_10,
  Companion_getInstancex1pk9m73g9c6 as Companion_getInstance_11,
  Companion_getInstance35vn8xpzue606 as Companion_getInstance_12,
  Companion_instance2kf6v2is0lhhr as Companion_instance_2,
  Companion_instancelgkpg07onuw2 as Companion_instance_3,
  Companion_instance2ffiktwt5nfeb as Companion_instance_4,
  Companion_instance3nf2h14fw03zr as Companion_instance_5,
  MouseEventmt8xebywpdp9 as MouseEvent,
  Companion_getInstance18fciagsd02o3 as Companion_getInstance_13,
  Companion_getInstancenz8ykkb6phk0 as Companion_getInstance_14,
  Companion_instanceozmuwjcmdf52 as Companion_instance_6,
  Companion_instancez9q7819xwnc1 as Companion_instance_7,
  ReshapeEvent3137ki3m95agm as ReshapeEvent,
  Companion_instancekrhj1pqvxv6j as Companion_instance_8,
  incrvqd3aj0o2965 as incr,
  V22su20mpwty0v0 as V2,
  _interpolateFloat22vwsp58cwzfw as _interpolateFloat,
  Companion_getInstance31f0y2ylke4bl as Companion_getInstance_15,
  Companion_getInstance1yaadt0u5el0f as Companion_getInstance_16,
  GamePadUpdateEvent1d3tqgcuuj4dw as GamePadUpdateEvent,
  TypedEvent2sht3ekuftqke as TypedEvent,
  GameStick_LEFT_getInstanceqs0nc2u1h6p8 as GameStick_LEFT_getInstance,
  Eventnral6s6c3h27 as Event,
  GameButton_BUTTON_SOUTH_getInstancefuuqbnftxu5s as GameButton_BUTTON_SOUTH_getInstance,
  GestureEvent15rebw4wmjtpj as GestureEvent,
  KeyEvent3kdv2rq8w0dew as KeyEvent,
  Type_TYPE_getInstanceae1nnzr8xwbc as Type_TYPE_getInstance,
  Type_DOWN_getInstance313b56cnb7a70 as Type_DOWN_getInstance_0,
  Type_UP_getInstance14prrhxnriw3z as Type_UP_getInstance_0,
  Cursor_DEFAULT_getInstance11huz1cp1hftw as Cursor_DEFAULT_getInstance,
  Companion_getInstance3jgth1v8sehkm as Companion_getInstance_17,
  drawText2ld6eqw3mc9xd as drawText,
  ScrollDeltaMode_PIXEL_getInstance1t158ggrr658n as ScrollDeltaMode_PIXEL_getInstance,
  UniformBlock2qoqszhm3r9i2 as UniformBlock,
  ProgramBuilderDefault3573mhybddt0b as ProgramBuilderDefault,
  SDFShaders_getInstance27u34u1y8yo31 as SDFShaders_getInstance,
  If1av5krmfyt44s as If,
  FragmentShader2u3ycaqbgbmdi as FragmentShader,
  _interpolateRatio2cj6ldbl0g6ae as _interpolateRatio,
  get_BB_MAX_TEXTURES19x2awozztfc7 as get_BB_MAX_TEXTURES,
  TextClipboardDatafimoftc14pmm as TextClipboardData,
  Key_X_getInstancecx3jjvu3v5gk as Key_X_getInstance,
  Key_BACKSPACE_getInstance3rjprs9bquj2s as Key_BACKSPACE_getInstance,
  SoftKeyboardConfig3ue4h4egpkqhq as SoftKeyboardConfig,
  Companion_getInstance1r9b3td34f16o as Companion_getInstance_18,
  Cursor_TEXT_getInstance38yy9u9rxp5sl as Cursor_TEXT_getInstance,
  ISoftKeyboardConfige7b4czi6ukd7 as ISoftKeyboardConfig,
  Companion_instance18k7zco3357fp as Companion_instance_9,
  _interpolateColor3ps0kri0tazz4 as _interpolateColor,
  Cursor_HAND_getInstance1a8elkut4sr6s as Cursor_HAND_getInstance,
  Key_SPACE_getInstance13zcep8fbcon1 as Key_SPACE_getInstance,
  Companion_getInstance77k1lbkmp5wu as Companion_getInstance_19,
  _interpolated0idy8l9id9j as _interpolate,
  Key_UP_getInstanceb8rctup40hnz as Key_UP_getInstance,
  Key_DOWN_getInstance3uv13du3770bq as Key_DOWN_getInstance,
  Key_ESCAPE_getInstance3cajn3snqk2cl as Key_ESCAPE_getInstance,
  Cursor_RESIZE_EAST_getInstance2w703jzx4ubcd as Cursor_RESIZE_EAST_getInstance,
  Key_TAB_getInstance1i9er9c2pz5on as Key_TAB_getInstance,
  Companion_getInstance29r688krv3c4g as Companion_getInstance_20,
  get_TEXTURED_ARRAY_QUAD_INDICES20zmm5sqavviz as get_TEXTURED_ARRAY_QUAD_INDICES,
  TexturedVertexArrayt1cf0uhgxqi1 as TexturedVertexArray,
  V2Callback2d6dtckx13wqz as V2Callback,
  Companion_getInstance37zfsbm2flr4o as Companion_getInstance_21,
  BaseEventListener6clplq3qhf9k as BaseEventListener,
  Companion_getInstance2hvv939x49crx as Companion_getInstance_22,
  TextureBase31xvoisoo1pp7 as TextureBase,
  Texture39uvj3jju6ujd as Texture,
  AGScissor1lpa42gfyr1p2 as AGScissor,
  ProjViewUB_getInstance24kbqbtea649t as ProjViewUB_getInstance,
  get_debugLineRenderContext3s3h9yq8a0rtj as get_debugLineRenderContext,
  BoundsProvideri69utw6h2duh as BoundsProvider,
  debugBmpFont119k9qsj5fsyz as debugBmpFont,
  Basef4lqzcfsxjhi as Base,
  UpdateEvent2wwv3f0im2cby as UpdateEvent,
  RenderContext3l27as458i66g as RenderContext,
  EventResult1qajuo9ssrvv5 as EventResult,
  Key_F12_getInstance3iwen1o4s4mlo as Key_F12_getInstance,
  Key_F7_getInstance1rgyn6mno38yl as Key_F7_getInstance,
  StopPropagatingException3fryyzw9pc422 as StopPropagatingException,
  DeviceDimensionsProvider22igvmdnpe2w1 as DeviceDimensionsProvider,
  DefaultShaders_getInstance11xvl6rr8iso as DefaultShaders_getInstance,
  VertexShader26kcg39ndf3s1 as VertexShader,
  AGVertexArrayObjectp6l6cor3jhq8 as AGVertexArrayObject,
  AGVertexDatawn78bdwnwjab as AGVertexData,
  drawfyi5eaogony1 as draw,
  Attribute3obvnbxr5w4xd as Attribute,
  VarType_Float2_getInstance26tgtz9tkdb0r as VarType_Float2_getInstance,
  VarType_Float1_getInstance2yyqoe6yly9a9 as VarType_Float1_getInstance,
  VarType_UShort2_getInstance1kb0okwtn0sad as VarType_UShort2_getInstance,
  VarType_Byte4_getInstance141vunatqfafm as VarType_Byte4_getInstance,
  Precision_LOW_getInstance3sce4muk7g3c6 as Precision_LOW_getInstance,
  VarType_UByte1_getInstanceedmnuc4ekhqj as VarType_UByte1_getInstance,
  Varying105nnu9x9j75f as Varying,
  Companion_getInstance21hbm5vhzozgd as Companion_getInstance_23,
  Program1hnygp9hph4ry as Program,
  AGTextureUnits3k80ikbk5qbv9 as AGTextureUnits,
  VarType_Int1_getInstance1li648f16m3co as VarType_Int1_getInstance,
  ForSimple22pqvfecoo85d as ForSimple,
  Texture2obedkmljq67n as Texture_0,
  Shader39o79ur9unxav as Shader,
  AGTextureUnitInfoArray__get_impl_xhvpef3h2w6c3rmad6f as AGTextureUnitInfoArray__get_impl_xhvpef,
  TEXTURED_ARRAY_quadIndices1vrv67jygypre as TEXTURED_ARRAY_quadIndices,
  ShrinkableTexturedVertexArray2dp7296cmttgp as ShrinkableTexturedVertexArray,
  Companion_getInstancer6z1nlxalqx7 as Companion_getInstance_24,
  Companion_getInstancey5u5n7g62xvk as Companion_getInstance_25,
  toAGScissor50oxbqy8vpfx as toAGScissor,
  plus3tmekjwnqrsa6 as plus_0,
  _AGColorMask___init__impl__j38ai83dsldtujda2h7 as _AGColorMask___init__impl__j38ai8,
  Companion_getInstance2v5l7b83xykmk as Companion_getInstance_26,
  AGStencilOpFunc__withEnabled_impl_mpcwmrtmo0h8rbfy7w as AGStencilOpFunc__withEnabled_impl_mpcwmr,
  AGStencilOpFunc__withCompareMode$default_impl_rzbauv5p5tszphd23r as AGStencilOpFunc__withCompareMode$default_impl_rzbauv,
  Companion_getInstance19lgm19ipf6do as Companion_getInstance_27,
  AGStencilOpFunc__withActionOnBothPass$default_impl_69nsgo33l2pvqtfq9yj as AGStencilOpFunc__withActionOnBothPass$default_impl_69nsgo,
  AGStencilReference__withWriteMask$default_impl_b7j7b43mm4w8krkt3ep as AGStencilReference__withWriteMask$default_impl_b7j7b4,
  Companion_getInstance2sk7vs29gnik3 as Companion_getInstance_28,
  AGStencilOpFunc__withActionOnBothPass_impl_qbn6at30n6rjhwnoxep as AGStencilOpFunc__withActionOnBothPass_impl_qbn6at,
  AGStencilReference__withReadMask$default_impl_7mdffx1g2sfp701cvyr as AGStencilReference__withReadMask$default_impl_7mdffx,
  AGCompareMode__inverted_impl_7xxk8m26xkfw1943nj2 as AGCompareMode__inverted_impl_7xxk8m,
  AGStencilReference__withReferenceValue$default_impl_2trj0v232x7ttx8ekye as AGStencilReference__withReferenceValue$default_impl_2trj0v,
  _AGColorMask___init__impl__j38ai814mo4mz42teo7 as _AGColorMask___init__impl__j38ai8_0,
  Companion_getInstance18pjxyoy9tzln as Companion_getInstance_29,
  AGDrawType__toString_impl_eonaeb25w5br3wcp5j8 as AGDrawType__toString_impl_eonaeb,
  AGColorMask__toString_impl_5i5oqg2gcs1m2a75488 as AGColorMask__toString_impl_5i5oqg,
  AGStencilOpFunc__toString_impl_pl8quy5ckch3y49o93 as AGStencilOpFunc__toString_impl_pl8quy,
  AGStencilReference__toString_impl_qu5r8sbb7dfey0kemg as AGStencilReference__toString_impl_qu5r8s,
  AGCullFace__toString_impl_7d891wpots1bgmosc2 as AGCullFace__toString_impl_7d891w,
  AGDrawType__hashCode_impl_ylauryzr6i7tjdryw8 as AGDrawType__hashCode_impl_ylaury,
  AGColorMask__hashCode_impl_g9082fi7509ibyna3k as AGColorMask__hashCode_impl_g9082f,
  AGStencilOpFunc__hashCode_impl_nopebb7w0dexbvx0dj as AGStencilOpFunc__hashCode_impl_nopebb,
  AGStencilReference__hashCode_impl_52zufx2wwwwyq5gfu31 as AGStencilReference__hashCode_impl_52zufx,
  AGCullFace__hashCode_impl_t4e5ur1en9y9rx0mxgq as AGCullFace__hashCode_impl_t4e5ur,
  AGDrawType11vxw4gf0yugk as AGDrawType,
  AGColorMaskwqd86t1dhpnk as AGColorMask,
  AGStencilOpFunc1juigl5mvjr07 as AGStencilOpFunc,
  AGStencilReference8at184sv64zi as AGStencilReference,
  AGCullFaceff7di2v9k2ne as AGCullFace,
  AgCachedBuffer8k1i505isl6b as AgCachedBuffer,
  applyMatrixBounds3j13fpymjibm6 as applyMatrixBounds,
  AGBatch2bse24318tbhc as AGBatch,
  draw3n7i8s75fp58g as draw_0,
  UniformBlockBuffer2p2oao2ic417b as UniformBlockBuffer,
  Precision_MEDIUM_getInstance45g8detuxjnt as Precision_MEDIUM_getInstance,
  ProgramLayout373hh4xkth3qt as ProgramLayout,
  Companion_instance3jw50k8jil3sx as Companion_instance_10,
  Companion_getInstance2milgz47dwnlh as Companion_getInstance_30,
} from './korge-root-korge-core.mjs';
import {
  Companion_getInstance19z3k1g06rka6 as Companion_getInstance_31,
  Companion_getInstance21plr7f541fx9 as Companion_getInstance_32,
  Companion_getInstancew949xxob44bw as Companion_getInstance_33,
  Size2Dum5ayuyhhau7 as Size2D,
  Vector2D378fe5rov8fgw as Vector2D,
  Companion_getInstanceuv1p1c5gwy11 as Companion_getInstance_34,
  get_degrees3czcd4ojw4i as get_degrees,
  Angle__minus_impl_xeau7k30vsnq3biqxde as Angle__minus_impl_xeau7k,
  Angle__toString_impl_786qky2c0lsq1q2n03p as Angle__toString_impl_786qky,
  Angle__hashCode_impl_szcndt1v9it5377zvl as Angle__hashCode_impl_szcndt,
  Angle2itorpob18n08 as Angle,
  Companion_getInstance1ygiwm9bgjia3 as Companion_getInstance_35,
  Angle_between72970e1vlen9 as Angle_between,
  Companion_getInstance1a1gzjuenalp2 as Companion_getInstance_36,
  Vector2F2yb91r49vy4m7 as Vector2F,
  timesatqrmkwmiu2z as times,
  Marginzmgz0t8x8vn as Margin,
  Rectangle3q03woyn9k8x1 as Rectangle,
  place35kwuvwdaw5nl as place_0,
  Companion_getInstancery5tnhoxuqu as Companion_getInstance_37,
  RectCorners2yrtl8r42p5bs as RectCorners,
  Companion_getInstancewvzae8n0fprj as Companion_getInstance_38,
  Companion_getInstance2ngycsg9kr2pn as Companion_getInstance_39,
  Companion_getInstance3om7tc9mg7edv as Companion_getInstance_40,
  BoundsBuilder__plus_impl_cz7f7x3pz0lxdivao0o as BoundsBuilder__plus_impl_cz7f7x,
  _BoundsBuilder___get_xmax__impl__gsz0km3izaxmrwq1yh1 as _BoundsBuilder___get_xmax__impl__gsz0km,
  _BoundsBuilder___get_ymax__impl__oia4uz2y6v142m2imsu as _BoundsBuilder___get_ymax__impl__oia4uz,
  Companion_getInstance3jdtpl42k8kus as Companion_getInstance_41,
  toFloat3b315y36g90cd as toFloat,
  applyScaleMode1nqdhbqs15126 as applyScaleMode,
  Anchor2D3ucppq7m9n3z8 as Anchor2D,
  MatrixTransform1wlsrhxt7473v as MatrixTransform,
  Scale2cm697xlulcp5 as Scale,
  Circle1sk1qi5ph1m23 as Circle,
  _BoundsBuilder___get_bounds__impl__s1l1gx3cti8ahdxq1b5 as _BoundsBuilder___get_bounds__impl__s1l1gx,
  interpolatewhd2sgxffql5 as interpolate_0,
  _Angle___get_degrees__impl__qg56vw1zqnfrqkp8did as _Angle___get_degrees__impl__qg56vw,
  _Angle___get_absoluteValue__impl__4qoakv3gpdj6wpeuir3 as _Angle___get_absoluteValue__impl__4qoakv,
  SizeInte6w85oa6mz6v as SizeInt,
  toIntp2twe4onfbic as toInt,
  BoundsBuilder__plus_impl_cz7f7x1gtmpkit3llyq as BoundsBuilder__plus_impl_cz7f7x_0,
  MarginInt3jiec8xsxkfd as MarginInt,
  Companion_getInstance2npyq9n96lvll as Companion_getInstance_42,
  Companion_getInstance9hqru60m2833 as Companion_getInstance_43,
  _Angle___get_sine__impl__15nmyi1kdagydgbnjhl as _Angle___get_sine__impl__15nmyi,
  _Angle___get_cosine__impl__vprvmu3fuyxpbgo71fk as _Angle___get_cosine__impl__vprvmu,
  BoundsBuilder__plus_impl_cz7f7x11d6y0i85df0y as BoundsBuilder__plus_impl_cz7f7x_1,
  _BoundsBuilder___get_xmin__impl__o2sib014moqt3x1cfln as _BoundsBuilder___get_xmin__impl__o2sib0,
  _BoundsBuilder___get_ymin__impl__5n2e8jlouwj806h8ky as _BoundsBuilder___get_ymin__impl__5n2e8j,
  Vector4F1clr35ctch8js as Vector4F,
} from './korlibs-korlibs-math-vector.mjs';
import {
  Companion_getInstancech3bi94cc2ym as Companion_getInstance_44,
  convertRange1a6ancvyn151r as convertRange,
  clamp011ml7wx1d2b7sp as clamp01,
  toRatio1hooqit5gulhr as toRatio,
  Companion_getInstance1kb6pmvl5oz92 as Companion_getInstance_45,
  Ratio136tf4z2t0p2d as Ratio,
  Ratio__toString_impl_mfgt3e2hpoo0nu4cwhr as Ratio__toString_impl_mfgt3e,
  Ratio__compareTo_impl_z2ienc1iwi3w3dhe1b7 as Ratio__compareTo_impl_z2ienc,
  Ratio__toFloat_impl_1ftup5294anfb3s41c1 as Ratio__toFloat_impl_1ftup5,
  clamp31lhm66c28ic8 as clamp,
  clampdicp8njiht45 as clamp_0,
  clamp1qclpmpfxm7xb as clamp_1,
  toLongRound7crzegvo2qbo as toLongRound,
  _Ratio___get_value__impl__57cli6w7w64hz8t0rv as _Ratio___get_value__impl__57cli6,
  umod164hvijxhfi8p as umod,
  toStringDecimalw23mr08es1e2 as toStringDecimal,
  convertRange378kqy75r4gq5 as convertRange_0,
  interpolate35pbs3ac913y4 as interpolate_1,
  _Ratio___init__impl__9mwvn2cg32u2k0cnlg as _Ratio___init__impl__9mwvn2,
  _Ratio___get_clamped__impl__cvqpjtmyj4kf6qyj5u as _Ratio___get_clamped__impl__cvqpjt,
  toIntCeil3h286vm49zgwd as toIntCeil,
  Ratio__convertToRange_impl_8a60wd2mvk8r985t1wp as Ratio__convertToRange_impl_8a60wd,
  convertRangeClamped3njqr0qabe371 as convertRangeClamped,
  numberOfDigits2ov4bzopld8er as numberOfDigits,
  niceStr1mw27wr6fq0ek as niceStr,
  _Ratio___init__impl__9mwvn2tqmswwh98av3 as _Ratio___init__impl__9mwvn2_0,
  nearestAlignedTo29ierorbs9y6l as nearestAlignedTo,
  get_niceStr3crhxydmlnaoj as get_niceStr,
  roundDecimalPlacespck2j5mpzhqn as roundDecimalPlaces,
  convertRange24n2xnngpze54 as convertRange_1,
  niceStr1ftz2qvn4gdyq as niceStr_0,
  toIntRoundjnzmk86x1qp5 as toIntRound,
  clamp012a0sd8pj80ati as clamp01_0,
  toIntCeiltm5lvaa6k4k9 as toIntCeil_0,
  insert2fz5125p2hdyd as insert,
  minhi4sj3tfj9ww as min,
  max329dnql9l08bp as max,
  get_nextPowerOfTwoselx3fye7kjp as get_nextPowerOfTwo,
} from './korlibs-korlibs-math-core.mjs';
import {
  get_EASE_IN_OUT_QUAD21w3u6e1aoexb as get_EASE_IN_OUT_QUAD,
  get_EASElq2v0gsk9fz7 as get_EASE,
  get_immutablepxpdoj6e54ll as get_immutable,
  Bezier1nrnsph9esuxa as Bezier,
  VectorPath1chx1oqdw6sb9 as VectorPath,
  get_EASE_IN3dgilxucimnao as get_EASE_IN,
  toMatrix42ci5k1es0x1v1 as toMatrix4,
  toShape2D2vgft60vvvqgq as toShape2D,
  MMatrix1y71lv7vv6y8t as MMatrix,
  StrokeInfo1cunqczl43ffk as StrokeInfo,
  interpolate2729pk90l3f88 as interpolate_2,
  HitTestDirection_ANY_getInstance2tae82ty68yvy as HitTestDirection_ANY_getInstance,
  toCurvesList2ibv2opnrpohn as toCurvesList,
  StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_getInstance8e3pzrc8s6ce as StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_getInstance,
  toStrokePointsList2dojuc3rfktd4 as toStrokePointsList,
  get_cachedPoints16ehjcv6uuio1 as get_cachedPoints,
  getPoints2List1zux0vi6zk0wt as getPoints2List,
  Winding_NON_ZERO_getInstance2zsru2lm79i6x as Winding_NON_ZERO_getInstance,
  LineScaleMode_NONE_getInstance2502q1le8nox0 as LineScaleMode_NONE_getInstance,
  LineCap_BUTT_getInstanceb57ygh36gdst as LineCap_BUTT_getInstance,
  LineJoin_MITER_getInstance3doesgezxmixn as LineJoin_MITER_getInstance,
} from './korlibs-korlibs-math.mjs';
import {
  Companion_getInstance4j5fpzk5rb8p as Companion_getInstance_46,
  cancel1n0qjc93h2v6w as cancel_0,
  CloseableCancellable3mxnqaxyfppmn as CloseableCancellable,
  CancellableGroup3e8z70ga3djg as CancellableGroup,
  Closeable2m9yv0b0gma2l as Closeable,
  invalidOp1stebxh2m3n07 as invalidOp,
  get_portableSimpleName6o7s8nc63i85 as get_portableSimpleName,
  Companion_instance21jklwk36y3dn as Companion_instance_11,
} from './korlibs-korlibs-platform.mjs';
import {
  PerformanceCounter_getInstance23wo5gavc7ilf as PerformanceCounter_getInstance,
  toFrequency3flbtmp5xney7 as toFrequency,
  _Frequency___get_hertz__impl__9m7k6x37zmlsrxcrsca as _Frequency___get_hertz__impl__9m7k6x,
  _Frequency___get_fastDuration__impl__87web02ham1qlkmkeau as _Frequency___get_fastDuration__impl__87web0,
  _Frequency___get_timeSpan__impl__f9mrmt72kalre6wihh as _Frequency___get_timeSpan__impl__f9mrmt,
} from './korlibs-korlibs-time.mjs';
import {
  get_resourcesVfs3qrvup7jpy9uc as get_resourcesVfs,
  launchImmediately11za8mkid9pxu as launchImmediately,
  Companion_instance1fqld9q4wfq20 as Companion_instance_12,
  get_workerImpl14jb6v5ie8q11 as get_workerImpl,
  get_DEBUG_WORKER2r7zp31jch3ip as get_DEBUG_WORKER,
  get_localCurrentDirVfs2lka1gsv26an5 as get_localCurrentDirVfs,
  Resources1ysf2j4wd5yk7 as Resources,
  launchImmediatelylnv8lnistpdy as launchImmediately_0,
  Once1q3lxlgflq3fq as Once,
  launchUnscopedAndWait6v8yfjb8olf5 as launchUnscopedAndWait,
  launchUnscopedpyoa01nqmf1z as launchUnscoped,
  launchUnscoped2lqe6wwpvfo83 as launchUnscoped_0,
  get_length100spxmnpj7nb as get_length,
  asyncImmediatelyxg0gfwuwfdn8 as asyncImmediately,
  StandardPaths_instance3ckwvxznkwud8 as StandardPaths_instance,
} from './korlibs-korlibs-io.mjs';
import {
  Companion_instance3c5rz779n238s as Companion_instance_13,
  Dyn__set_impl_2ozvx82r2n5zj0vrm3z as Dyn__set_impl_2ozvx8,
} from './korlibs-korlibs-dyn.mjs';
import { get_nativeSoundProvider1b6rsunmfcp5k as get_nativeSoundProvider } from './korlibs-korlibs-audio.mjs';
import {
  getExtraTyped8zexqxz5xxli as getExtraTyped,
  setExtra314k0z6q35eku as setExtra,
  fastArrayListOf1rdodhrpn0q8y as fastArrayListOf,
  PropertyThis2r0hbk1cs09wj as PropertyThis,
  hasExtra1969mplo13qgb as hasExtra,
  FastArrayListw5fr1wlp4slq as FastArrayList,
  Property1m5vulkepgwzd as Property,
  Mixin3lmkm5hum2vdj as Mixin,
  getExtra307y0o9pd6mp3 as getExtra,
  toFastList1btvvxvv57pw as toFastList,
  Resourceable38whwgli3ts8g as Resourceable,
} from './korlibs-korlibs-datastructure-core.mjs';
import {
  TGenDeque2zx9g0ftfbw19 as TGenDeque,
  Pool1zbsw0zhvh8ca as Pool,
  FastIntMapl0pzyavg6uw5 as FastIntMap,
  linkedHashMapOf2w2uhgrq4khrk as linkedHashMapOf,
  HistoryStack2mrikkhc0etuj as HistoryStack,
  PointArrayList281mrsgyr6ua1 as PointArrayList,
  IntArrayList3liuagbdnwmna as IntArrayList,
  getCyclicOrNull12fem85bj0m7u as getCyclicOrNull,
  Ref2y4j4snnxo6gs as Ref,
  IntDeque2ebok66x2sofm as IntDeque,
  toRef3av6zm8yavumw as toRef,
  Ref11k43t82thcp3 as Ref_0,
  get2pw1zlgv5zypd as get,
  set2vuztm9k7ubgo as set,
  FastIdentityCacheMap3fegd59qa1ixp as FastIdentityCacheMap,
  asLong2pdvo9u0nmcck as asLong,
  toStacked2m99u9tgdzvj0 as toStacked,
  floatArrayListOfa8snvg2p0fhc as floatArrayListOf,
} from './korlibs-korlibs-datastructure.mjs';
import {
  arrayfill2iqmyqqqfwuaf as arrayfill,
  arraycopy3uxsqjlixmnz3 as arraycopy,
  arrayequal25g4om83vd8w1 as arrayequal,
  Buffer25z94vsj0kb1s as Buffer,
  get_i323p4hlz6j3xys8 as get_i32,
  get_f3218rglfhpgbhzp as get_f32,
  Float32Buffer__set_impl_34sznevuviq8wx4pjc as Float32Buffer__set_impl_34szne,
  Float32Buffer__get_impl_659lhu25y6ts3w0gs8j as Float32Buffer__get_impl_659lhu,
  Int32Buffer__set_impl_dj1e3r3rgggtatuzfx6 as Int32Buffer__set_impl_dj1e3r,
  _Float32Buffer___init__impl__403k2m37epj48967hb0 as _Float32Buffer___init__impl__403k2m,
  _Float32Buffer___get_buffer__impl__x5nu9x3g4pb23dnjw1t as _Float32Buffer___get_buffer__impl__x5nu9x,
} from './korlibs-korlibs-memory.mjs';
import { atomic$int$11d5swdyn6j0pu as atomic$int$1 } from './kotlinx-atomicfu.mjs';
import {
  withoutRangetvtevgxw4fe7 as withoutRange,
  withoutIndex1982826lx7knb as withoutIndex,
  withInsertion32qy0wb7w2f46 as withInsertion,
} from './korlibs-korlibs-string.mjs';
import { htmlspecialcharsooxhmw9ymkma as htmlspecialchars } from './korlibs-korlibs-serialization.mjs';
//region block: imports
var imul = Math.imul;
var log2 = Math.log2;
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    Companion_instance_14 = this;
    this.rb3_1 = Companion_getInstance_1().ez('Korge');
    this.sb3_1 = 'korlibs.korge.unknown';
  }
}
class Korge$_init_$slambda_ys8r4w {
  tb3(_this__u8e3s4, $completion) {
    return Unit_instance;
  }
  qe(p1, $completion) {
    return this.tb3(p1 instanceof Stage ? p1 : THROW_CCE(), $completion);
  }
}
class Korge$start$slambda {
  constructor(this$0, $entry) {
    this.gb2_1 = this$0;
    this.hb2_1 = $entry;
  }
  o1s($this$withContext, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$withContext, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class Korge {
  constructor(args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowSize, virtualSize, displayMode, title, backgroundColor, quality, icon, multithreaded, forceRenderEveryFrame, windowCreationConfig, main, debugAg, debugFontExtraScale, debugFontColor, stageBuilder, targetFps, preferSyncIo, unit) {
    Companion_getInstance_47();
    var tmp;
    if (args === VOID) {
      // Inline function 'kotlin.arrayOf' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = [];
    } else {
      tmp = args;
    }
    args = tmp;
    imageFormats = imageFormats === VOID ? get_RegisteredImageFormats() : imageFormats;
    gameWindow = gameWindow === VOID ? null : gameWindow;
    mainSceneClass = mainSceneClass === VOID ? null : mainSceneClass;
    timeProvider = timeProvider === VOID ? Companion_instance_0 : timeProvider;
    injector = injector === VOID ? new Injector() : injector;
    var tmp_0;
    if (configInjector === VOID) {
      tmp_0 = Korge$_init_$lambda_95dffl;
    } else {
      tmp_0 = configInjector;
    }
    configInjector = tmp_0;
    debug = debug === VOID ? false : debug;
    trace = trace === VOID ? false : trace;
    context = context === VOID ? null : context;
    fullscreen = fullscreen === VOID ? null : fullscreen;
    blocking = blocking === VOID ? true : blocking;
    gameId = gameId === VOID ? Companion_getInstance_47().sb3_1 : gameId;
    settingsFolder = settingsFolder === VOID ? null : settingsFolder;
    batchMaxQuads = batchMaxQuads === VOID ? Companion_getInstance_8().rad_1 : batchMaxQuads;
    windowSize = windowSize === VOID ? DefaultViewport_getInstance().cab_1 : windowSize;
    virtualSize = virtualSize === VOID ? windowSize : virtualSize;
    displayMode = displayMode === VOID ? Companion_getInstance_48().i4b() : displayMode;
    title = title === VOID ? 'Game' : title;
    backgroundColor = backgroundColor === VOID ? Colors_getInstance().h4r_1 : backgroundColor;
    quality = quality === VOID ? Quality_PERFORMANCE_getInstance() : quality;
    icon = icon === VOID ? null : icon;
    multithreaded = multithreaded === VOID ? null : multithreaded;
    forceRenderEveryFrame = forceRenderEveryFrame === VOID ? true : forceRenderEveryFrame;
    windowCreationConfig = windowCreationConfig === VOID ? new GameWindowCreationConfig(multithreaded, VOID, VOID, VOID, VOID, VOID, fullscreen) : windowCreationConfig;
    var tmp_1;
    if (main === VOID) {
      tmp_1 = Korge$_init_$slambda_ys8r4w_0();
    } else {
      tmp_1 = main;
    }
    main = tmp_1;
    debugAg = debugAg === VOID ? false : debugAg;
    debugFontExtraScale = debugFontExtraScale === VOID ? 1.0 : debugFontExtraScale;
    debugFontColor = debugFontColor === VOID ? Colors_getInstance().g4r_1 : debugFontColor;
    var tmp_2;
    if (stageBuilder === VOID) {
      tmp_2 = Korge$_init_$lambda_95dffl_0;
    } else {
      tmp_2 = stageBuilder;
    }
    stageBuilder = tmp_2;
    targetFps = targetFps === VOID ? 0.0 : targetFps;
    preferSyncIo = preferSyncIo === VOID ? null : preferSyncIo;
    unit = unit === VOID ? Unit_instance : unit;
    this.ib2_1 = args;
    this.jb2_1 = imageFormats;
    this.kb2_1 = gameWindow;
    this.lb2_1 = mainSceneClass;
    this.mb2_1 = timeProvider;
    this.nb2_1 = injector;
    this.ob2_1 = configInjector;
    this.pb2_1 = debug;
    this.qb2_1 = trace;
    this.rb2_1 = context;
    this.sb2_1 = fullscreen;
    this.tb2_1 = blocking;
    this.ub2_1 = gameId;
    this.vb2_1 = settingsFolder;
    this.wb2_1 = batchMaxQuads;
    this.xb2_1 = windowSize;
    this.yb2_1 = virtualSize;
    this.zb2_1 = displayMode;
    this.ab3_1 = title;
    this.bb3_1 = backgroundColor;
    this.cb3_1 = quality;
    this.db3_1 = icon;
    this.eb3_1 = multithreaded;
    this.fb3_1 = forceRenderEveryFrame;
    this.gb3_1 = windowCreationConfig;
    this.hb3_1 = main;
    this.ib3_1 = debugAg;
    this.jb3_1 = debugFontExtraScale;
    this.kb3_1 = debugFontColor;
    this.lb3_1 = stageBuilder;
    this.mb3_1 = targetFps;
    this.nb3_1 = preferSyncIo;
    this.ob3_1 = unit;
  }
  ob6(entry, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_start__v5y7pu(this, entry, $completion), $completion);
  }
  pb6(args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowSize, virtualSize, displayMode, title, backgroundColor, quality, icon, multithreaded, forceRenderEveryFrame, windowCreationConfig, main, debugAg, debugFontExtraScale, debugFontColor, stageBuilder, targetFps, preferSyncIo, unit) {
    return new Korge(args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowSize, virtualSize, displayMode, title, backgroundColor, quality, icon, multithreaded, forceRenderEveryFrame, windowCreationConfig, main, debugAg, debugFontExtraScale, debugFontColor, stageBuilder, targetFps, preferSyncIo, unit);
  }
  pb3(args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowSize, virtualSize, displayMode, title, backgroundColor, quality, icon, multithreaded, forceRenderEveryFrame, windowCreationConfig, main, debugAg, debugFontExtraScale, debugFontColor, stageBuilder, targetFps, preferSyncIo, unit, $super) {
    args = args === VOID ? this.ib2_1 : args;
    imageFormats = imageFormats === VOID ? this.jb2_1 : imageFormats;
    gameWindow = gameWindow === VOID ? this.kb2_1 : gameWindow;
    mainSceneClass = mainSceneClass === VOID ? this.lb2_1 : mainSceneClass;
    timeProvider = timeProvider === VOID ? this.mb2_1 : timeProvider;
    injector = injector === VOID ? this.nb2_1 : injector;
    configInjector = configInjector === VOID ? this.ob2_1 : configInjector;
    debug = debug === VOID ? this.pb2_1 : debug;
    trace = trace === VOID ? this.qb2_1 : trace;
    context = context === VOID ? this.rb2_1 : context;
    fullscreen = fullscreen === VOID ? this.sb2_1 : fullscreen;
    blocking = blocking === VOID ? this.tb2_1 : blocking;
    gameId = gameId === VOID ? this.ub2_1 : gameId;
    settingsFolder = settingsFolder === VOID ? this.vb2_1 : settingsFolder;
    batchMaxQuads = batchMaxQuads === VOID ? this.wb2_1 : batchMaxQuads;
    windowSize = windowSize === VOID ? this.xb2_1 : windowSize;
    virtualSize = virtualSize === VOID ? this.yb2_1 : virtualSize;
    displayMode = displayMode === VOID ? this.zb2_1 : displayMode;
    title = title === VOID ? this.ab3_1 : title;
    backgroundColor = backgroundColor === VOID ? this.bb3_1 : backgroundColor;
    quality = quality === VOID ? this.cb3_1 : quality;
    icon = icon === VOID ? this.db3_1 : icon;
    multithreaded = multithreaded === VOID ? this.eb3_1 : multithreaded;
    forceRenderEveryFrame = forceRenderEveryFrame === VOID ? this.fb3_1 : forceRenderEveryFrame;
    windowCreationConfig = windowCreationConfig === VOID ? this.gb3_1 : windowCreationConfig;
    main = main === VOID ? this.hb3_1 : main;
    debugAg = debugAg === VOID ? this.ib3_1 : debugAg;
    debugFontExtraScale = debugFontExtraScale === VOID ? this.jb3_1 : debugFontExtraScale;
    debugFontColor = debugFontColor === VOID ? this.kb3_1 : debugFontColor;
    stageBuilder = stageBuilder === VOID ? this.lb3_1 : stageBuilder;
    targetFps = targetFps === VOID ? this.mb3_1 : targetFps;
    preferSyncIo = preferSyncIo === VOID ? this.nb3_1 : preferSyncIo;
    unit = unit === VOID ? this.ob3_1 : unit;
    var tmp;
    if ($super === VOID) {
      tmp = this.pb6(args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowSize, virtualSize, displayMode, title, backgroundColor, quality, icon, multithreaded, forceRenderEveryFrame, windowCreationConfig, main, debugAg, debugFontExtraScale, debugFontColor, stageBuilder, targetFps, preferSyncIo, unit);
    } else {
      var tmp_0 = $super.pb6;
      var tmp_1 = backgroundColor;
      var tmp_2 = tmp_1 == null ? null : new RGBA(tmp_1);
      tmp = tmp_0.call(this, args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowSize, virtualSize, displayMode, title, tmp_2, quality, icon, multithreaded, forceRenderEveryFrame, windowCreationConfig, main, debugAg, debugFontExtraScale, new RGBA(debugFontColor), stageBuilder, targetFps, preferSyncIo, Unit_instance);
    }
    return tmp;
  }
  toString() {
    var tmp = toString(this.ib2_1);
    var tmp_0 = this.jb2_1.toString();
    var tmp_1 = toString_0(this.kb2_1);
    var tmp_2 = toString_0(this.lb2_1);
    var tmp_3 = toString(this.mb2_1);
    var tmp_4 = this.nb2_1.toString();
    var tmp_5 = toString(this.ob2_1);
    var tmp_6 = toString_0(this.rb2_1);
    var tmp_7 = this.xb2_1.toString();
    var tmp_8 = this.yb2_1.toString();
    var tmp_9 = this.zb2_1.toString();
    var tmp_10 = this.bb3_1;
    return 'Korge(args=' + tmp + ', imageFormats=' + tmp_0 + ', gameWindow=' + tmp_1 + ', mainSceneClass=' + tmp_2 + ', timeProvider=' + tmp_3 + ', injector=' + tmp_4 + ', configInjector=' + tmp_5 + ', debug=' + this.pb2_1 + ', trace=' + this.qb2_1 + ', context=' + tmp_6 + ', fullscreen=' + this.sb2_1 + ', blocking=' + this.tb2_1 + ', gameId=' + this.ub2_1 + ', settingsFolder=' + this.vb2_1 + ', batchMaxQuads=' + this.wb2_1 + ', windowSize=' + tmp_7 + ', virtualSize=' + tmp_8 + ', displayMode=' + tmp_9 + ', title=' + this.ab3_1 + ', backgroundColor=' + toString_0(tmp_10 == null ? null : new RGBA(tmp_10)) + ', quality=' + toString(this.cb3_1) + ', icon=' + this.db3_1 + ', multithreaded=' + this.eb3_1 + ', forceRenderEveryFrame=' + this.fb3_1 + ', windowCreationConfig=' + this.gb3_1.toString() + ', main=' + toString(this.hb3_1) + ', debugAg=' + this.ib3_1 + ', debugFontExtraScale=' + this.jb3_1 + ', debugFontColor=' + RGBA__toString_impl_4lw6dp(this.kb3_1) + ', stageBuilder=' + toString(this.lb3_1) + ', targetFps=' + this.mb3_1 + ', preferSyncIo=' + this.nb3_1 + ', unit=' + this.ob3_1.toString() + ')';
  }
  hashCode() {
    var result = hashCode(this.ib2_1);
    result = imul(result, 31) + hashCode(this.jb2_1) | 0;
    result = imul(result, 31) + (this.kb2_1 == null ? 0 : hashCode(this.kb2_1)) | 0;
    result = imul(result, 31) + (this.lb2_1 == null ? 0 : this.lb2_1.hashCode()) | 0;
    result = imul(result, 31) + hashCode(this.mb2_1) | 0;
    result = imul(result, 31) + hashCode(this.nb2_1) | 0;
    result = imul(result, 31) + hashCode(this.ob2_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.pb2_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.qb2_1) | 0;
    result = imul(result, 31) + (this.rb2_1 == null ? 0 : hashCode(this.rb2_1)) | 0;
    result = imul(result, 31) + (this.sb2_1 == null ? 0 : getBooleanHashCode(this.sb2_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.tb2_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.ub2_1) | 0;
    result = imul(result, 31) + (this.vb2_1 == null ? 0 : getStringHashCode(this.vb2_1)) | 0;
    result = imul(result, 31) + this.wb2_1 | 0;
    result = imul(result, 31) + this.xb2_1.hashCode() | 0;
    result = imul(result, 31) + this.yb2_1.hashCode() | 0;
    result = imul(result, 31) + this.zb2_1.hashCode() | 0;
    result = imul(result, 31) + getStringHashCode(this.ab3_1) | 0;
    var tmp = imul(result, 31);
    var tmp_0;
    var tmp_1 = this.bb3_1;
    if ((tmp_1 == null ? null : new RGBA(tmp_1)) == null) {
      tmp_0 = 0;
    } else {
      tmp_0 = RGBA__hashCode_impl_h59qf6(this.bb3_1);
    }
    result = tmp + tmp_0 | 0;
    result = imul(result, 31) + this.cb3_1.hashCode() | 0;
    result = imul(result, 31) + (this.db3_1 == null ? 0 : getStringHashCode(this.db3_1)) | 0;
    result = imul(result, 31) + (this.eb3_1 == null ? 0 : getBooleanHashCode(this.eb3_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.fb3_1) | 0;
    result = imul(result, 31) + this.gb3_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.hb3_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.ib3_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.jb3_1) | 0;
    result = imul(result, 31) + RGBA__hashCode_impl_h59qf6(this.kb3_1) | 0;
    result = imul(result, 31) + hashCode(this.lb3_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.mb3_1) | 0;
    result = imul(result, 31) + (this.nb3_1 == null ? 0 : getBooleanHashCode(this.nb3_1)) | 0;
    var tmp_2 = imul(result, 31);
    result = tmp_2 + hashCode(Unit_instance) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Korge))
      return false;
    var tmp0_other_with_cast = other instanceof Korge ? other : THROW_CCE();
    if (!equals(this.ib2_1, tmp0_other_with_cast.ib2_1))
      return false;
    if (!equals(this.jb2_1, tmp0_other_with_cast.jb2_1))
      return false;
    if (!equals(this.kb2_1, tmp0_other_with_cast.kb2_1))
      return false;
    if (!equals(this.lb2_1, tmp0_other_with_cast.lb2_1))
      return false;
    if (!equals(this.mb2_1, tmp0_other_with_cast.mb2_1))
      return false;
    if (!equals(this.nb2_1, tmp0_other_with_cast.nb2_1))
      return false;
    if (!equals(this.ob2_1, tmp0_other_with_cast.ob2_1))
      return false;
    if (!(this.pb2_1 === tmp0_other_with_cast.pb2_1))
      return false;
    if (!(this.qb2_1 === tmp0_other_with_cast.qb2_1))
      return false;
    if (!equals(this.rb2_1, tmp0_other_with_cast.rb2_1))
      return false;
    if (!(this.sb2_1 == tmp0_other_with_cast.sb2_1))
      return false;
    if (!(this.tb2_1 === tmp0_other_with_cast.tb2_1))
      return false;
    if (!(this.ub2_1 === tmp0_other_with_cast.ub2_1))
      return false;
    if (!(this.vb2_1 == tmp0_other_with_cast.vb2_1))
      return false;
    if (!(this.wb2_1 === tmp0_other_with_cast.wb2_1))
      return false;
    if (!this.xb2_1.equals(tmp0_other_with_cast.xb2_1))
      return false;
    if (!this.yb2_1.equals(tmp0_other_with_cast.yb2_1))
      return false;
    if (!this.zb2_1.equals(tmp0_other_with_cast.zb2_1))
      return false;
    if (!(this.ab3_1 === tmp0_other_with_cast.ab3_1))
      return false;
    var tmp = this.bb3_1;
    var tmp_0 = tmp == null ? null : new RGBA(tmp);
    var tmp_1 = tmp0_other_with_cast.bb3_1;
    if (!equals(tmp_0, tmp_1 == null ? null : new RGBA(tmp_1)))
      return false;
    if (!this.cb3_1.equals(tmp0_other_with_cast.cb3_1))
      return false;
    if (!(this.db3_1 == tmp0_other_with_cast.db3_1))
      return false;
    if (!(this.eb3_1 == tmp0_other_with_cast.eb3_1))
      return false;
    if (!(this.fb3_1 === tmp0_other_with_cast.fb3_1))
      return false;
    if (!this.gb3_1.equals(tmp0_other_with_cast.gb3_1))
      return false;
    if (!equals(this.hb3_1, tmp0_other_with_cast.hb3_1))
      return false;
    if (!(this.ib3_1 === tmp0_other_with_cast.ib3_1))
      return false;
    if (!equals(this.jb3_1, tmp0_other_with_cast.jb3_1))
      return false;
    if (!(this.kb3_1 === tmp0_other_with_cast.kb3_1))
      return false;
    if (!equals(this.lb3_1, tmp0_other_with_cast.lb3_1))
      return false;
    if (!equals(this.mb3_1, tmp0_other_with_cast.mb3_1))
      return false;
    if (!(this.nb3_1 == tmp0_other_with_cast.nb3_1))
      return false;
    if (!equals(Unit_instance, Unit_instance))
      return false;
    return true;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_15 = this;
    this.kb6_1 = new KorgeDisplayMode(Companion_getInstance_31().n2v_1, Companion_getInstance_32().f2h(), true);
    this.lb6_1 = new KorgeDisplayMode(Companion_getInstance_31().n2v_1, Companion_getInstance_32().f2h(), false);
    this.mb6_1 = new KorgeDisplayMode(Companion_getInstance_31().n2v_1, Companion_getInstance_32().p2g_1, false);
    this.nb6_1 = new KorgeDisplayMode(Companion_getInstance_31().p2v_1, Companion_getInstance_32().p2g_1, false);
  }
  i4b() {
    return this.kb6_1;
  }
}
class KorgeDisplayMode {
  constructor(scaleMode, scaleAnchor, clipBorders) {
    Companion_getInstance_48();
    this.qb6_1 = scaleMode;
    this.rb6_1 = scaleAnchor;
    this.sb6_1 = clipBorders;
  }
  toString() {
    return 'KorgeDisplayMode(scaleMode=' + this.qb6_1.toString() + ', scaleAnchor=' + this.rb6_1.toString() + ', clipBorders=' + this.sb6_1 + ')';
  }
  hashCode() {
    var result = hashCode(this.qb6_1);
    result = imul(result, 31) + this.rb6_1.hashCode() | 0;
    result = imul(result, 31) + getBooleanHashCode(this.sb6_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof KorgeDisplayMode))
      return false;
    var tmp0_other_with_cast = other instanceof KorgeDisplayMode ? other : THROW_CCE();
    if (!equals(this.qb6_1, tmp0_other_with_cast.qb6_1))
      return false;
    if (!this.rb6_1.equals(tmp0_other_with_cast.rb6_1))
      return false;
    if (!(this.sb6_1 === tmp0_other_with_cast.sb6_1))
      return false;
    return true;
  }
}
class KorgeRunner$invoke$slambda$slambda$slambda {
  constructor($config, $views, $entry, $gameWindow) {
    this.hb9_1 = $config;
    this.ib9_1 = $views;
    this.jb9_1 = $entry;
    this.kb9_1 = $gameWindow;
  }
  o1s($this$coroutineScope, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_0(this, $this$coroutineScope, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class KorgeRunner$invoke$slambda$slambda {
  constructor($config, $views, $entry, $gameWindow) {
    this.nbe_1 = $config;
    this.obe_1 = $views;
    this.pbe_1 = $entry;
    this.qbe_1 = $gameWindow;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_1(this, $completion), $completion);
  }
}
class KorgeRunner$invoke$slambda {
  constructor($realGameWindow, $windowSize, $config, $iconPath, $imageFormats, $entry) {
    this.rbe_1 = $realGameWindow;
    this.sbe_1 = $windowSize;
    this.tbe_1 = $config;
    this.ube_1 = $iconPath;
    this.vbe_1 = $imageFormats;
    this.wbe_1 = $entry;
  }
  ebf($this$loop, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_2(this, $this$loop, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.ebf(p1 instanceof GameWindow ? p1 : THROW_CCE(), $completion);
  }
}
class KorgeRunner$prepareViewsBase$lambda$slambda {
  constructor($views) {
    this.fbf_1 = $views;
  }
  pe($completion) {
    this.fbf_1.u6();
  }
}
class KorgeRunner {
  qb3(config, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_3(this, config, $completion), $completion);
  }
  abf(views, eventDispatcher, clearEachFrame, bgcolor, fixedSizeStep, forceRenderEveryFrame, configInjector) {
    var injector = views.ab7_1;
    // Inline function 'korlibs.inject.Injector.mapInstance' call
    injector.o87(getKClass(Views), views);
    // Inline function 'korlibs.inject.Injector.mapInstance' call
    var instance = views.zb6_1;
    injector.o87(getKClass(AG), instance);
    injector.o87(getKClass(Resources), views.hbf());
    var tmp = getKClass(ResourcesRoot);
    injector.r87(tmp, KorgeRunner$prepareViewsBase$lambda);
    // Inline function 'korlibs.inject.Injector.mapInstance' call
    var instance_0 = views.bb7_1;
    injector.o87(getKClass(Input), instance_0);
    // Inline function 'korlibs.inject.Injector.mapInstance' call
    var instance_1 = views.db7_1;
    injector.o87(getKClass(Stats), instance_1);
    injector.o87(getKClass(CoroutineContext), views.yb6_1);
    var tmp_0 = getKClass(EmptyScene);
    injector.s87(tmp_0, KorgeRunner$prepareViewsBase$lambda_0);
    injector.o87(getKClass(TimeProvider), views.cb7_1);
    configInjector(injector);
    var input = views.bb7_1;
    var ag = views.zb6_1;
    var downPos = {_v: Companion_getInstance_34().a2i_1};
    var upPos = {_v: Companion_getInstance_34().a2i_1};
    var downTime = {_v: new DateTime(Companion_getInstance_7().c1w_1)};
    var moveTime = {_v: new DateTime(Companion_getInstance_7().c1w_1)};
    var upTime = Companion_getInstance_7().c1w_1;
    var moveMouseOutsideInNextFrame = {_v: false};
    var mouseTouchId = -1;
    views.ibf(forceRenderEveryFrame);
    var mouseTouchEvent = new TouchEvent();
    var tmp_1 = Companion_getInstance_9().q8c_1.slice();
    eventDispatcher.w8a(tmp_1, KorgeRunner$prepareViewsBase$lambda_1(views, input, downPos, downTime, mouseTouchEvent, upPos, moveTime, moveMouseOutsideInNextFrame));
    var tmp_2 = Companion_getInstance_10().a8c_1.slice();
    eventDispatcher.w8a(tmp_2, KorgeRunner$prepareViewsBase$lambda_2(views));
    var tmp_3 = Companion_getInstance_11().t8d_1.slice();
    eventDispatcher.w8a(tmp_3, KorgeRunner$prepareViewsBase$lambda_3(views));
    var tmp_4 = Companion_getInstance_12().c8e_1.slice();
    eventDispatcher.w8a(tmp_4, KorgeRunner$prepareViewsBase$lambda_4(views));
    var tmp_5 = Companion_instance_2;
    eventDispatcher.k8a(tmp_5, KorgeRunner$prepareViewsBase$lambda_5(views));
    var tmp_6 = Companion_instance_3;
    eventDispatcher.k8a(tmp_6, KorgeRunner$prepareViewsBase$lambda_6(views));
    var tmp_7 = Companion_instance_4;
    eventDispatcher.k8a(tmp_7, KorgeRunner$prepareViewsBase$lambda_7(views));
    var tmp_8 = Companion_instance_5;
    eventDispatcher.k8a(tmp_8, KorgeRunner$prepareViewsBase$lambda_8(views));
    var touchMouseEvent = new MouseEvent();
    var tmp_9 = Companion_getInstance_13().v8e_1.slice();
    eventDispatcher.w8a(tmp_9, KorgeRunner$prepareViewsBase$lambda_9(input, views, touchMouseEvent, moveMouseOutsideInNextFrame, downPos, downTime, upPos, moveTime));
    var tmp_10 = Companion_getInstance_14().b8h_1.slice();
    eventDispatcher.w8a(tmp_10, KorgeRunner$prepareViewsBase$lambda_10(views));
    var tmp_11 = Companion_instance_6;
    eventDispatcher.k8a(tmp_11, KorgeRunner$prepareViewsBase$lambda_11(views, input));
    var tmp_12 = Companion_instance_7;
    eventDispatcher.k8a(tmp_12, KorgeRunner$prepareViewsBase$lambda_12(views, ag));
    eventDispatcher.v8a(new ReshapeEvent(0, 0, views.jbf(), views.kbf()));
    var tmp_13 = Companion_instance_8;
    eventDispatcher.k8a(tmp_13, KorgeRunner$prepareViewsBase$lambda_13(views));
    var renderShown = {_v: false};
    views.yb7_1 = clearEachFrame;
    views.zb7_1 = bgcolor;
    var firstRenderDeferred = CompletableDeferred();
    views.eb7_1.vaq(KorgeRunner$prepareViewsBase$lambda_14(views, renderShown, firstRenderDeferred, fixedSizeStep, moveMouseOutsideInNextFrame));
    return firstRenderDeferred;
  }
}
class Korge$slambda {
  tb3(_this__u8e3s4, $completion) {
    return Unit_instance;
  }
  qe(p1, $completion) {
    return this.tb3(p1 instanceof Stage ? p1 : THROW_CCE(), $completion);
  }
}
class Korge$slambda_0 {
  tb3(_this__u8e3s4, $completion) {
    return Unit_instance;
  }
  qe(p1, $completion) {
    return this.tb3(p1 instanceof Stage ? p1 : THROW_CCE(), $completion);
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_16 = this;
    var tmp = this;
    // Inline function 'korlibs.time.milliseconds' call
    // Inline function 'kotlin.time.Companion.milliseconds' call
    Companion_getInstance();
    tmp.ubf_1 = toDuration(500, DurationUnit_MILLISECONDS_getInstance());
    this.vbf_1 = 128.0;
    this.wbf_1 = get_EASE(Companion_getInstance_44());
    this.xbf_1 = true;
  }
}
class RootAnimationNode {
  constructor($outer) {
    this.sbg_1 = $outer;
    this.qbg_1 = null;
    this.rbg_1 = fastArrayListOf([]);
  }
  z33() {
    this.qbg_1 = null;
  }
  bbh(dt) {
    var dt_0 = !(this.sbg_1.kbg_1 === 1.0) ? FastDuration__times_impl_h6amcp(dt, this.sbg_1.kbg_1) : dt;
    ensureInit(this.sbg_1);
    if (this.sbg_1.cbg_1) {
      var completedTime = get_fastSeconds(0);
      this.rbg_1.o3();
      // Inline function 'kotlin.collections.forEachIndexed' call
      var index = 0;
      var tmp0_iterator = this.sbg_1.jbg_1.f1();
      while (tmp0_iterator.g1()) {
        var item = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.animate.RootAnimationNode.update.<anonymous>' call
        var tmp1 = index;
        index = tmp1 + 1 | 0;
        var index_0 = checkIndexOverflow(tmp1);
        var result = item.bbh(dt_0);
        if (FastDuration__compareTo_impl_4fhnj7(result, get_fastSeconds(0)) >= 0) {
          this.rbg_1.f(item);
        }
        var tmp;
        if (index_0 === 0) {
          tmp = result;
        } else {
          // Inline function 'kotlin.math.min' call
          var a = _FastDuration___get_fastMilliseconds__impl__pe68va(completedTime);
          var b = _FastDuration___get_fastMilliseconds__impl__pe68va(result);
          var tmp$ret$0 = Math.min(a, b);
          tmp = get_fastMilliseconds(tmp$ret$0);
        }
        completedTime = tmp;
      }
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!this.rbg_1.a1()) {
        this.sbg_1.jbg_1.b35(this.rbg_1);
      }
      this.rbg_1.o3();
      this.sbg_1.nbg_1 = true;
      return completedTime;
    }
    $l$loop: while (true) {
      if (this.qbg_1 == null) {
        var tmp_0 = this;
        var tmp_1;
        // Inline function 'kotlin.collections.isNotEmpty' call
        if (!this.sbg_1.jbg_1.a1()) {
          tmp_1 = this.sbg_1.jbg_1.nl();
        } else {
          tmp_1 = null;
        }
        tmp_0.qbg_1 = tmp_1;
        var tmp19_safe_receiver = this.qbg_1;
        if (tmp19_safe_receiver == null)
          null;
        else {
          tmp19_safe_receiver.z33();
        }
        if (this.sbg_1.dbg_1) {
          var tmp20_safe_receiver = this.qbg_1;
          if (tmp20_safe_receiver == null)
            null;
          else {
            // Inline function 'kotlin.let' call
            // Inline function 'kotlin.contracts.contract' call
            this.sbg_1.jbg_1.q34(tmp20_safe_receiver);
          }
        }
      }
      if (!(this.qbg_1 == null)) {
        var node = ensureNotNull(this.qbg_1);
        var extraTime = node.bbh(dt_0);
        if (FastDuration__compareTo_impl_4fhnj7_0(extraTime, Companion_getInstance().ik_1) >= 0) {
          this.qbg_1 = null;
          dt_0 = extraTime;
        }
        if (FastDuration__compareTo_impl_4fhnj7_0(extraTime, Companion_getInstance().ik_1) > 0) {
          continue $l$loop;
        }
      }
      var tmp_2;
      var tmp_3;
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!this.sbg_1.jbg_1.a1()) {
        tmp_3 = true;
      } else {
        tmp_3 = !(this.qbg_1 == null);
      }
      if (tmp_3) {
        tmp_2 = get_fastSeconds(-1);
      } else {
        tmp_2 = Companion_instance_1.m1w();
      }
      return tmp_2;
    }
  }
  cbh() {
    ensureInit(this.sbg_1);
    var tmp21_safe_receiver = this.qbg_1;
    if (tmp21_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.animate.RootAnimationNode.complete.<anonymous>' call
      this.qbg_1 = null;
      tmp21_safe_receiver.cbh();
    }
    $l$loop: while (true) {
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!!this.sbg_1.jbg_1.a1()) {
        break $l$loop;
      }
      this.sbg_1.jbg_1.nl().cbh();
    }
    this.sbg_1.ybg();
  }
  toString() {
    return 'RootAnimationNode(' + toString(this.sbg_1) + ')';
  }
  a1() {
    return this.qbg_1 == null && this.sbg_1.jbg_1.a1();
  }
}
class TweenNode {
  constructor(vs, lazyVs, time, lazyTime, easing, name) {
    lazyVs = lazyVs === VOID ? null : lazyVs;
    time = time === VOID ? get_fastMilliseconds_0(1000) : time;
    lazyTime = lazyTime === VOID ? null : lazyTime;
    name = name === VOID ? null : name;
    this.lbf_1 = vs;
    this.mbf_1 = lazyVs;
    this.nbf_1 = time;
    this.obf_1 = lazyTime;
    this.pbf_1 = easing;
    this.qbf_1 = name;
    var tmp = this;
    tmp.rbf_1 = lazy(Animator$TweenNode$computedVs$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.sbf_1 = lazy(Animator$TweenNode$totalTime$delegate$lambda(this));
    this.tbf_1 = Companion_instance_1.m1w();
  }
  dbh() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.rbf_1;
    computedVs$factory();
    return this_0.b2();
  }
  toString() {
    return 'TweenNode(totalTime=' + FastDuration__toString_impl_c9xohb(_get_totalTime__xgts5c(this)) + ', name=' + this.qbf_1 + ', ' + toString(toList_0(this.dbh())) + ')';
  }
  z33() {
    this.tbf_1 = Companion_instance_1.m1w();
  }
  bbh(dt) {
    if (equals(this.tbf_1, Companion_instance_1.m1w())) {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var this_0 = this.dbh();
      var n = 0;
      while (n < this_0.j1()) {
        // Inline function 'korlibs.korge.animate.TweenNode.update.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        this_0.i1(_unary__edvuaz).e28();
      }
    }
    this.tbf_1 = FastDuration__plus_impl_q1enab(this.tbf_1, dt);
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_1 = this.dbh();
    var n_0 = 0;
    while (n_0 < this_1.j1()) {
      // Inline function 'korlibs.korge.animate.TweenNode.update.<anonymous>' call
      var _unary__edvuaz_0 = n_0;
      n_0 = _unary__edvuaz_0 + 1 | 0;
      var it = this_1.i1(_unary__edvuaz_0);
      var ratio = equals(_get_totalTime__xgts5c(this), Companion_instance_1.m1w()) ? 1.0 : convertRange(_FastDuration___get_fastSeconds__impl__amo4s3(this.tbf_1), _FastDuration___get_fastSeconds__impl__amo4s3(it.wal_1), _FastDuration___get_fastSeconds__impl__amo4s3(it.bam(_get_totalTime__xgts5c(this))), 0.0, 1.0);
      if (ratio >= 0.0) {
        it.cam(toRatio(this.pbf_1.yz(clamp01(ratio))));
      }
    }
    return FastDuration__minus_impl_evs237(this.tbf_1, _get_totalTime__xgts5c(this));
  }
  cbh() {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.dbh();
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.animate.TweenNode.complete.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      this_0.i1(_unary__edvuaz).cam(Companion_getInstance_45().i10_1);
    }
  }
}
class BlockNode {
  constructor(name, callback) {
    name = name === VOID ? null : name;
    this.ebh_1 = name;
    this.fbh_1 = callback;
    this.gbh_1 = false;
  }
  toString() {
    return 'BlockNode(name=' + this.ebh_1 + ')';
  }
  z33() {
    this.gbh_1 = false;
  }
  bbh(dt) {
    this.cbh();
    return dt;
  }
  cbh() {
    if (this.gbh_1)
      return Unit_instance;
    this.gbh_1 = true;
    this.fbh_1();
  }
}
class Animator {
  constructor(root, fastDefaultTime, defaultSpeed, defaultEasing, parallel, looped, parent, lazyInit, level, startImmediately) {
    Companion_getInstance_49();
    parallel = parallel === VOID ? false : parallel;
    looped = looped === VOID ? false : looped;
    lazyInit = lazyInit === VOID ? null : lazyInit;
    this.ybf_1 = root;
    this.zbf_1 = fastDefaultTime;
    this.abg_1 = defaultSpeed;
    this.bbg_1 = defaultEasing;
    this.cbg_1 = parallel;
    this.dbg_1 = looped;
    this.ebg_1 = parent;
    this.fbg_1 = lazyInit;
    this.gbg_1 = level;
    this.hbg_1 = startImmediately;
    this.ibg_1 = new Signal();
    this.jbg_1 = TGenDeque.p34();
    this.kbg_1 = 1.0;
    this.lbg_1 = null;
    this.mbg_1 = false;
    this.nbg_1 = false;
    this.obg_1 = new RootAnimationNode(this);
  }
  ibh() {
    return get_slow(this.zbf_1);
  }
  pbg() {
    var tmp4_safe_receiver = this.ebg_1;
    var tmp0_elvis_lhs = tmp4_safe_receiver == null ? null : tmp4_safe_receiver.pbg();
    return tmp0_elvis_lhs == null ? this : tmp0_elvis_lhs;
  }
  jbh(props) {
    var _iterator__ex2g4s = this.jbg_1.f1();
    while (_iterator__ex2g4s.g1()) {
      var node = _iterator__ex2g4s.h1();
      if (node instanceof TweenNode) {
        var tmp = node.dbh();
        retainAll(tmp, Animator$removeProps$lambda(props));
      }
    }
  }
  kbh(node) {
    this.jbg_1.y34(node);
    ensure(this);
  }
  abh(completeOnCancel, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_awaitComplete__3zrgmd(this, completeOnCancel, $completion), $completion);
  }
  lbh(completeOnCancel, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_await__mos7q6(this, completeOnCancel, $completion), $completion);
  }
  u6() {
    this.ybg();
  }
  iu(e) {
    this.ybg();
  }
  ybg() {
    this.obg_1.z33();
    this.jbg_1.o3();
    var tmp6_safe_receiver = this.pbg().lbg_1;
    if (tmp6_safe_receiver == null)
      null;
    else {
      cancel_0(tmp6_safe_receiver);
    }
    this.pbg().lbg_1 = null;
    this.nbg_1 = false;
    invoke(this.ibg_1);
    return this;
  }
  zbg() {
    this.obg_1.cbh();
    return this;
  }
  mbh(vs, lazyVs, time, lazyTime, easing, name, replace) {
    if (replace && this.cbg_1) {
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList.w(vs.length);
      var inductionVariable = 0;
      var last = vs.length;
      while (inductionVariable < last) {
        var item = vs[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.korge.animate.Animator.__tween.<anonymous>' call
        var tmp$ret$0 = item.ral_1;
        destination.f(tmp$ret$0);
      }
      this.jbh(toSet(destination));
    }
    this.kbh(new TweenNode(vs.slice(), lazyVs, time, lazyTime, easing, name));
  }
  nbh(vs, lazyVs, time, lazyTime, easing, name, replace, $super) {
    lazyVs = lazyVs === VOID ? null : lazyVs;
    time = time === VOID ? this.zbf_1 : time;
    lazyTime = lazyTime === VOID ? null : lazyTime;
    easing = easing === VOID ? this.bbg_1 : easing;
    replace = replace === VOID ? true : replace;
    var tmp;
    if ($super === VOID) {
      this.mbh(vs, lazyVs, time, lazyTime, easing, name, replace);
      tmp = Unit_instance;
    } else {
      tmp = $super.mbh.call(this, vs, lazyVs, new FastDuration(time), lazyTime, easing, name, replace);
    }
    return tmp;
  }
  obh(vs, time, lazyTime, easing, name) {
    this.kbh(new TweenNode([], vs, time, lazyTime, easing, name));
  }
  pbh(vs, time, lazyTime, easing, name, $super) {
    time = time === VOID ? this.zbf_1 : time;
    lazyTime = lazyTime === VOID ? null : lazyTime;
    easing = easing === VOID ? this.bbg_1 : easing;
    var tmp;
    if ($super === VOID) {
      this.obh(vs, time, lazyTime, easing, name);
      tmp = Unit_instance;
    } else {
      tmp = $super.obh.call(this, vs, new FastDuration(time), lazyTime, easing, name);
    }
    return tmp;
  }
}
class AnimateCancellationException extends CancellationException {}
class ViewStageComponent {
  constructor(view) {
    this.mbj_1 = view;
    this.nbj_1 = new Signal();
    this.obj_1 = new Signal();
  }
}
class GamePadEvents {
  constructor(view) {
    this.qbj_1 = view;
    this.sbj_1 = new GamePadUpdateEvent();
    this.tbj_1 = new Signal();
    this.ubj_1 = new Signal();
    this.vbj_1 = new Signal();
    this.wbj_1 = new Signal();
    this.xbj_1 = new Signal();
    this.ybj_1 = new GamePadUpdateEvent();
    this.zbj_1 = new GamePadStickEvent();
    this.abk_1 = new GamePadButtonEvent();
    var tmp = Companion_instance_6;
    this.qbj_1.k8a(tmp, GamePadEvents$lambda(this));
    var tmp_0 = Companion_getInstance_14().b8h_1.slice();
    this.qbj_1.w8a(tmp_0, GamePadEvents$lambda_0(this));
  }
  nbk(callback) {
    this.ubj_1.v1x(callback);
  }
}
class Companion_2 {}
class GamePadStickEvent extends TypedEvent {
  constructor(gamepad, stick, pos) {
    gamepad = gamepad === VOID ? 0 : gamepad;
    stick = stick === VOID ? GameStick_LEFT_getInstance() : stick;
    pos = pos === VOID ? Companion_getInstance_34().a2i_1 : pos;
    super(Companion_instance_17);
    this.kbk_1 = gamepad;
    this.lbk_1 = stick;
    this.mbk_1 = pos;
  }
  toString() {
    return 'GamePadStickEvent(gamepad=' + this.kbk_1 + ', stick=' + this.lbk_1.toString() + ', pos=' + this.mbk_1.toString() + ')';
  }
  hashCode() {
    var result = this.kbk_1;
    result = imul(result, 31) + this.lbk_1.hashCode() | 0;
    result = imul(result, 31) + this.mbk_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof GamePadStickEvent))
      return false;
    var tmp0_other_with_cast = other instanceof GamePadStickEvent ? other : THROW_CCE();
    if (!(this.kbk_1 === tmp0_other_with_cast.kbk_1))
      return false;
    if (!this.lbk_1.equals(tmp0_other_with_cast.lbk_1))
      return false;
    if (!this.mbk_1.equals(tmp0_other_with_cast.mbk_1))
      return false;
    return true;
  }
}
class Type extends Enum {}
class GamePadButtonEvent extends Event {
  constructor(type, gamepad, button, value) {
    type = type === VOID ? Type_DOWN_getInstance_1() : type;
    gamepad = gamepad === VOID ? 0 : gamepad;
    button = button === VOID ? GameButton_BUTTON_SOUTH_getInstance() : button;
    value = value === VOID ? 0.0 : value;
    super();
    this.dbk_1 = type;
    this.ebk_1 = gamepad;
    this.fbk_1 = button;
    this.gbk_1 = value;
  }
  b2n() {
    return this.dbk_1;
  }
  toString() {
    return 'GamePadButtonEvent(type=' + toString(this.dbk_1) + ', gamepad=' + this.ebk_1 + ', button=' + this.fbk_1.toString() + ', value=' + this.gbk_1 + ')';
  }
  hashCode() {
    var result = this.dbk_1.hashCode();
    result = imul(result, 31) + this.ebk_1 | 0;
    result = imul(result, 31) + this.fbk_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.gbk_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof GamePadButtonEvent))
      return false;
    var tmp0_other_with_cast = other instanceof GamePadButtonEvent ? other : THROW_CCE();
    if (!this.dbk_1.equals(tmp0_other_with_cast.dbk_1))
      return false;
    if (!(this.ebk_1 === tmp0_other_with_cast.ebk_1))
      return false;
    if (!this.fbk_1.equals(tmp0_other_with_cast.fbk_1))
      return false;
    if (!equals(this.gbk_1, tmp0_other_with_cast.gbk_1))
      return false;
    return true;
  }
}
class GestureEvents {
  constructor(view) {
    this.obk_1 = view;
    this.pbk_1 = new GestureEvent();
    this.qbk_1 = new Signal();
    this.rbk_1 = new Signal();
    this.sbk_1 = new Signal();
    this.tbk_1 = new Signal();
    var tmp = Companion_getInstance_11().t8d_1.slice();
    this.obk_1.w8a(tmp, GestureEvents$lambda(this));
  }
  a8e() {
    return this.pbk_1.a8e();
  }
  vbk() {
    var tmp = this.ubk_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('views');
    }
  }
}
class KeysEvents$down$slambda {
  constructor($keys, $callback) {
    this.wbk_1 = $keys;
    this.xbk_1 = $callback;
  }
  sbl(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_4(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class KeysEvents$down$slambda_0 {
  constructor($callback) {
    this.ybk_1 = $callback;
  }
  sbl(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_5(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class KeysEvents$down$slambda_1 {
  constructor($key, $callback) {
    this.zbk_1 = $key;
    this.abl_1 = $callback;
  }
  sbl(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_6(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class KeysEvents$up$slambda {
  constructor($keys, $callback) {
    this.bbl_1 = $keys;
    this.cbl_1 = $callback;
  }
  sbl(e, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_7(this, e, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class KeysEvents$typed$slambda {
  constructor($callback) {
    this.dbl_1 = $callback;
  }
  sbl(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_8(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class KeysEvents$lambda$slambda {
  constructor(this$0, $event) {
    this.kbl_1 = this$0;
    this.lbl_1 = $event;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_9(this, $completion), $completion);
  }
}
class KeysEvents$lambda$slambda_0 {
  constructor(this$0, $event) {
    this.mbl_1 = this$0;
    this.nbl_1 = $event;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_10(this, $completion), $completion);
  }
}
class KeysEvents$lambda$slambda_1 {
  constructor(this$0, $event) {
    this.obl_1 = this$0;
    this.pbl_1 = $event;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_11(this, $completion), $completion);
  }
}
class KeysEvents {
  constructor(view) {
    this.ebl_1 = view;
    this.gbl_1 = new AsyncSignal();
    this.hbl_1 = new AsyncSignal();
    this.ibl_1 = new AsyncSignal();
    this.jbl_1 = new CancellableGroup();
    var tmp = Type_TYPE_getInstance();
    this.ebl_1.k8a(tmp, KeysEvents$lambda(this));
    var tmp_0 = Type_DOWN_getInstance_0();
    this.ebl_1.k8a(tmp_0, KeysEvents$lambda_0(this));
    var tmp_1 = Type_UP_getInstance_0();
    this.ebl_1.k8a(tmp_1, KeysEvents$lambda_1(this));
  }
  qbl() {
    var tmp = this.fbl_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('views');
    }
  }
  tbl(_this__u8e3s4, key, keys, dt, type) {
    _this__u8e3s4.d8c_1 = type;
    _this__u8e3s4.f8c_1 = key;
    _this__u8e3s4.g8c_1 = key.v3_1;
    _this__u8e3s4.i8c_1 = keys.ia9_1;
    _this__u8e3s4.j8c_1 = keys.ja9_1;
    _this__u8e3s4.k8c_1 = keys.ka9_1;
    _this__u8e3s4.l8c_1 = keys.la9_1;
    _this__u8e3s4.n8c_1 = dt;
    return _this__u8e3s4;
  }
  rbl(_this__u8e3s4, key, keys, dt, type, $super) {
    type = type === VOID ? Type_DOWN_getInstance_0() : type;
    return $super === VOID ? this.tbl(_this__u8e3s4, key, keys, dt, type) : $super.tbl.call(this, _this__u8e3s4, key, keys, new Duration(dt), type);
  }
  ubl(keys, dt, callback) {
    var ke = new KeyEvent();
    return addOptFixedUpdater(this.ebl_1, dt, KeysEvents$downFrame$lambda(this, keys, callback, ke));
  }
  vbl(key, dt, callback) {
    return this.ubl(listOf(key), dt, callback);
  }
  wbl(keys, callback) {
    return this.gbl_1.j1y(KeysEvents$down$slambda_2(keys, callback));
  }
  xbl(callback) {
    return this.gbl_1.j1y(KeysEvents$down$slambda_3(callback));
  }
  ybl(key, callback) {
    return this.gbl_1.j1y(KeysEvents$down$slambda_4(key, callback));
  }
  zbl(keys, callback) {
    return this.wbl(toSet_0(keys), callback);
  }
  abm(keys, callback) {
    return this.hbl_1.j1y(KeysEvents$up$slambda_0(keys, callback));
  }
  bbm(keys, callback) {
    return this.abm(toSet_0(keys), callback);
  }
  cbm(callback) {
    return this.ibl_1.j1y(KeysEvents$typed$slambda_0(callback));
  }
  u6() {
    this.jbl_1.u6();
  }
}
class MouseDragInfo {
  constructor(view, dx, dy, start, end, startTime, time, sx, sy, cx, cy) {
    dx = dx === VOID ? 0.0 : dx;
    dy = dy === VOID ? 0.0 : dy;
    start = start === VOID ? false : start;
    end = end === VOID ? false : end;
    startTime = startTime === VOID ? Companion_getInstance_7().c1w_1 : startTime;
    time = time === VOID ? Companion_getInstance_7().c1w_1 : time;
    sx = sx === VOID ? 0.0 : sx;
    sy = sy === VOID ? 0.0 : sy;
    cx = cx === VOID ? 0.0 : cx;
    cy = cy === VOID ? 0.0 : cy;
    this.dbm_1 = view;
    this.ebm_1 = dx;
    this.fbm_1 = dy;
    this.gbm_1 = start;
    this.hbm_1 = end;
    this.ibm_1 = startTime;
    this.jbm_1 = time;
    this.kbm_1 = sx;
    this.lbm_1 = sy;
    this.mbm_1 = cx;
    this.nbm_1 = cy;
    this.pbm_1 = NaN;
    this.qbm_1 = NaN;
    this.rbm_1 = 0.0;
    this.sbm_1 = 0.0;
  }
  toString() {
    return 'MouseDragInfo(start=' + this.gbm_1 + ', end=' + this.hbm_1 + ', sx=' + this.kbm_1 + ', sy=' + this.lbm_1 + ', cx=' + this.mbm_1 + ', cy=' + this.nbm_1 + ')';
  }
  tbm() {
    var tmp = this.obm_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('mouseEvents');
    }
  }
  ubm() {
    return DateTime__minus_impl_10njw8(this.jbm_1, this.ibm_1);
  }
  vbm() {
    return this.wbm(this.dbm_1);
  }
  xbm() {
    return this.ybm(this.dbm_1);
  }
  zbm(view) {
    var tmp36_safe_receiver = view.w15();
    var tmp0_elvis_lhs = tmp36_safe_receiver == null ? null : tmp36_safe_receiver.abn(Vector2D.k2h(0.0, 0.0), Vector2D.k2h(this.ebm_1, this.fbm_1));
    return tmp0_elvis_lhs == null ? Vector2D.k2h(this.ebm_1, this.fbm_1) : tmp0_elvis_lhs;
  }
  wbm(view) {
    return this.zbm(view).i2h_1;
  }
  ybm(view) {
    return this.zbm(view).j2h_1;
  }
  z33() {
    this.pbm_1 = NaN;
    this.qbm_1 = NaN;
    this.rbm_1 = 0.0;
    this.sbm_1 = 0.0;
    this.ebm_1 = 0.0;
    this.fbm_1 = 0.0;
    this.kbm_1 = 0.0;
    this.lbm_1 = 0.0;
    this.mbm_1 = 0.0;
    this.nbm_1 = 0.0;
  }
  bbn(dx, dy, start, end, time, sx, sy, cx, cy) {
    this.ebm_1 = dx;
    this.fbm_1 = dy;
    this.kbm_1 = sx;
    this.lbm_1 = sy;
    this.mbm_1 = cx;
    this.nbm_1 = cy;
    if (!isNaN_0(this.pbm_1) && !isNaN_0(this.qbm_1)) {
      this.rbm_1 = this.pbm_1 - dx;
      this.sbm_1 = this.qbm_1 - dy;
    }
    this.pbm_1 = dx;
    this.qbm_1 = dy;
    this.gbm_1 = start;
    this.hbm_1 = end;
    if (start)
      this.ibm_1 = time;
    this.jbm_1 = time;
    return this;
  }
}
class OnMouseDragCloseable {
  constructor(onDownCloseable, onUpAnywhereCloseable, onMoveAnywhereCloseable) {
    this.cbn_1 = onDownCloseable;
    this.dbn_1 = onUpAnywhereCloseable;
    this.ebn_1 = onMoveAnywhereCloseable;
  }
  u6() {
    this.cbn_1.u6();
    this.dbn_1.u6();
    this.ebn_1.u6();
  }
  toString() {
    return 'OnMouseDragCloseable(onDownCloseable=' + toString(this.cbn_1) + ', onUpAnywhereCloseable=' + toString(this.dbn_1) + ', onMoveAnywhereCloseable=' + toString(this.ebn_1) + ')';
  }
  hashCode() {
    var result = hashCode(this.cbn_1);
    result = imul(result, 31) + hashCode(this.dbn_1) | 0;
    result = imul(result, 31) + hashCode(this.ebn_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof OnMouseDragCloseable))
      return false;
    var tmp0_other_with_cast = other instanceof OnMouseDragCloseable ? other : THROW_CCE();
    if (!equals(this.cbn_1, tmp0_other_with_cast.cbn_1))
      return false;
    if (!equals(this.dbn_1, tmp0_other_with_cast.dbn_1))
      return false;
    if (!equals(this.ebn_1, tmp0_other_with_cast.ebn_1))
      return false;
    return true;
  }
}
class MouseDragState extends Enum {
  u8f() {
    return this.equals(MouseDragState_START_getInstance());
  }
  v8f() {
    return this.equals(MouseDragState_END_getInstance());
  }
}
class DraggableInfo extends MouseDragInfo {
  constructor(view) {
    super(view);
    this.xbn_1 = Vector2D.i2l();
    this.ybn_1 = Vector2D.i2l();
    this.zbn_1 = Vector2D.i2l();
    this.abo_1 = Companion_getInstance_34().a2i_1;
  }
  bbo() {
    return this.xbn_1.i2h_1;
  }
  cbo() {
    return this.xbn_1.j2h_1;
  }
  dbo() {
    return this.ybn_1.i2h_1;
  }
  ebo() {
    return this.ybn_1.j2h_1;
  }
  fbo() {
    return this.zbn_1.i2h_1;
  }
  gbo() {
    return this.zbn_1.j2h_1;
  }
}
class DraggableCloseable {
  constructor(onMouseDragCloseable) {
    this.hbo_1 = onMouseDragCloseable;
  }
  u6() {
    this.hbo_1.u6();
  }
  toString() {
    return 'DraggableCloseable(onMouseDragCloseable=' + toString(this.hbo_1) + ')';
  }
  hashCode() {
    return hashCode(this.hbo_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof DraggableCloseable))
      return false;
    var tmp0_other_with_cast = other instanceof DraggableCloseable ? other : THROW_CCE();
    if (!equals(this.hbo_1, tmp0_other_with_cast.hbo_1))
      return false;
    return true;
  }
}
class onMouseDragInternal$slambda {
  constructor($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view) {
    this.jbo_1 = $dragging;
    this.kbo_1 = $info;
    this.lbo_1 = $mousePos;
    this.mbo_1 = $sx;
    this.nbo_1 = $sy;
    this.obo_1 = $cx;
    this.pbo_1 = $cy;
    this.qbo_1 = $callback;
    this.rbo_1 = $timeProvider;
    this.sbo_1 = $view;
  }
  tbo(it, $completion) {
    onMouseDragInternal$handle(this.jbo_1, this.kbo_1, this.lbo_1, this.mbo_1, this.nbo_1, this.obo_1, this.pbo_1, this.qbo_1, this.rbo_1, this.sbo_1, it, MouseDragState_START_getInstance());
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class onMouseDragInternal$lambda$slambda {
  constructor($handler, $it) {
    this.ubo_1 = $handler;
    this.vbo_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_12(this, $completion), $completion);
  }
}
class onMouseDragInternal$slambda_0 {
  constructor($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view) {
    this.xbo_1 = $dragging;
    this.ybo_1 = $info;
    this.zbo_1 = $mousePos;
    this.abp_1 = $sx;
    this.bbp_1 = $sy;
    this.cbp_1 = $cx;
    this.dbp_1 = $cy;
    this.ebp_1 = $callback;
    this.fbp_1 = $timeProvider;
    this.gbp_1 = $view;
  }
  tbo(it, $completion) {
    onMouseDragInternal$handle(this.xbo_1, this.ybo_1, this.zbo_1, this.abp_1, this.bbp_1, this.cbp_1, this.dbp_1, this.ebp_1, this.fbp_1, this.gbp_1, it, MouseDragState_END_getInstance());
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class onMouseDragInternal$lambda$slambda_0 {
  constructor($handler, $it) {
    this.hbp_1 = $handler;
    this.ibp_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_13(this, $completion), $completion);
  }
}
class onMouseDragInternal$slambda_1 {
  constructor($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view) {
    this.jbp_1 = $dragging;
    this.kbp_1 = $info;
    this.lbp_1 = $mousePos;
    this.mbp_1 = $sx;
    this.nbp_1 = $sy;
    this.obp_1 = $cx;
    this.pbp_1 = $cy;
    this.qbp_1 = $callback;
    this.rbp_1 = $timeProvider;
    this.sbp_1 = $view;
  }
  tbo(it, $completion) {
    onMouseDragInternal$handle(this.jbp_1, this.kbp_1, this.lbp_1, this.mbp_1, this.nbp_1, this.obp_1, this.pbp_1, this.qbp_1, this.rbp_1, this.sbp_1, it, MouseDragState_DRAG_getInstance());
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class onMouseDragInternal$lambda$slambda_1 {
  constructor($handler, $it) {
    this.tbp_1 = $handler;
    this.ubp_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_14(this, $completion), $completion);
  }
}
class Companion_3 {
  bbq(views) {
    // Inline function 'korlibs.io.util.Once.invoke' call
    var this_0 = get_mouseDebugHandlerOnce(views);
    if (!this_0.g3p_1) {
      this_0.g3p_1 = true;
      // Inline function 'korlibs.korge.input.Companion.installDebugExtensionOnce.<anonymous>' call
      // Inline function 'kotlin.collections.plusAssign' call
      var this_1 = views.qb8_1;
      var element = MouseEvents$Companion$installDebugExtensionOnce$lambda(views);
      this_1.f(element);
    }
  }
}
class MouseEventsUpdate {
  constructor($outer, view) {
    this.ebq_1 = $outer;
    this.cbq_1 = new Mixin();
    this.dbq_1 = view;
    addUpdaterWithViews(this.dbq_1, MouseEvents$MouseEventsUpdate$lambda(this.ebq_1));
  }
  n13(_set____db54di) {
    this.cbq_1.n13(_set____db54di);
  }
  o13() {
    return this.cbq_1.o13();
  }
}
class MouseEvents {
  constructor(view) {
    this.vbh_1 = new Mixin();
    this.wbh_1 = view;
    this.wbh_1.hbq(true);
    this.ybh_1 = new Signal();
    this.zbh_1 = new Signal();
    this.abi_1 = new Signal();
    this.bbi_1 = new Signal();
    this.cbi_1 = new Signal();
    this.dbi_1 = new Signal();
    this.ebi_1 = new Signal();
    this.fbi_1 = new Signal();
    this.gbi_1 = new Signal();
    this.hbi_1 = new Signal();
    this.ibi_1 = new Signal();
    this.jbi_1 = new Signal();
    this.kbi_1 = new Signal();
    this.lbi_1 = new Signal();
    this.mbi_1 = new Signal();
    this.nbi_1 = new Signal();
    this.obi_1 = new Signal();
    this.pbi_1 = new Signal();
    this.qbi_1 = new Signal();
    this.rbi_1 = null;
    this.sbi_1 = false;
    this.tbi_1 = false;
    this.ubi_1 = false;
    this.vbi_1 = PerformanceCounter_getInstance().j88();
    this.wbi_1 = PerformanceCounter_getInstance().j88();
    this.xbi_1 = Vector2D.i2l();
    this.ybi_1 = Vector2D.i2l();
    this.zbi_1 = Vector2D.i2l();
    this.abj_1 = Vector2D.i2l();
    this.bbj_1 = Vector2D.i2l();
    this.cbj_1 = 0;
    this.dbj_1 = false;
    this.ebj_1 = null;
    this.fbj_1 = new MouseEvent();
    this.gbj_1 = false;
    this.hbj_1 = new MouseEvent();
    var tmp = this;
    var tmp_0 = Companion_getInstance_9().q8c_1.slice();
    tmp.ibj_1 = this.wbh_1.w8a(tmp_0, MouseEvents$closeable$lambda(this));
    this.jbj_1 = new MouseEventsUpdate(this, this.wbh_1);
  }
  n13(_set____db54di) {
    this.vbh_1.n13(_set____db54di);
  }
  o13() {
    return this.vbh_1.o13();
  }
  vbk() {
    var tmp = this.xbh_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('views');
    }
  }
  wbo() {
    return this.vbk().yb6_1;
  }
  lbj() {
    return this.vbk().bb7_1;
  }
  ibq() {
    return this.wbh_1.jbq(this.bbj_1);
  }
  kbq() {
    var tmp68_safe_receiver = this.ebj_1;
    if (tmp68_safe_receiver == null)
      null;
    else {
      tmp68_safe_receiver.g8b();
    }
  }
  lbq() {
    var tmp69_safe_receiver = this.ebj_1;
    if (tmp69_safe_receiver == null)
      null;
    else {
      tmp69_safe_receiver.j8b();
    }
  }
  gbq() {
    var tmp70_safe_receiver = this.rbi_1;
    var tmp0_elvis_lhs = tmp70_safe_receiver == null ? null : hasAncestor(tmp70_safe_receiver, this.wbh_1);
    return tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs;
  }
  mbq() {
    // Inline function 'korlibs.event.MouseEvent.scrollDeltaXPixels' call
    return this.fbj_1.q8d(ScrollDeltaMode_PIXEL_getInstance());
  }
  nbq() {
    // Inline function 'korlibs.event.MouseEvent.scrollDeltaYPixels' call
    return this.fbj_1.r8d(ScrollDeltaMode_PIXEL_getInstance());
  }
  obq() {
    return this.fbj_1.i8d_1;
  }
  pbq() {
    return !(this.vbk().bb7_1.baa_1 === 0);
  }
  toString() {
    return this.fbj_1.toString();
  }
  abq(views, dt) {
    if (!this.wbh_1.fbq())
      return Unit_instance;
    this.xbh_1 = views;
    Companion_instance_18.bbq(views);
    this.rbi_1 = mouseHitTest(Companion_instance_18, views);
    var over = this.gbq();
    var inside = views.bb7_1.daa_1;
    if (over) {
      set_mouseHitResultUsed(views.bb7_1, this.wbh_1);
    }
    var overChanged = !(this.sbi_1 === over);
    var insideChanged = !(this.tbi_1 === inside);
    var pressingChanged = !(this.pbq() === this.ubi_1);
    this.bbj_1 = views.bb7_1.saa();
    if (!overChanged && over && !this.bbj_1.equals(this.abj_1)) {
      this.kbi_1.x1x(this);
    }
    if (!overChanged && !over && !this.bbj_1.equals(this.abj_1)) {
      this.mbi_1.x1x(this);
    }
    if (!this.bbj_1.equals(this.abj_1)) {
      this.lbi_1.x1x(this);
    }
    if (overChanged && over) {
      this.abi_1.x1x(this);
    }
    if (overChanged && !over) {
      this.bbi_1.x1x(this);
    }
    if (pressingChanged && this.pbq()) {
      this.zbi_1 = this.bbj_1;
      if (over) {
        this.cbi_1.x1x(this);
      }
      if (!over) {
        this.dbi_1.x1x(this);
      }
    }
    if (overChanged && this.pbq()) {
      this.ebi_1.x1x(this);
    }
    if (pressingChanged && !this.pbq()) {
      var tmp = this.hbj_1;
      temporalLastEvent(this, tmp, MouseEvents$update$lambda(over, this));
    }
    over && this.cbj_1 > 0;
    if (insideChanged && !inside) {
      this.mbi_1.x1x(this);
      this.bbi_1.x1x(this);
      this.hbi_1.x1x(this);
      this.ibi_1.x1x(this);
      this.nbi_1.x1x(this);
    }
    this.sbi_1 = over;
    this.tbi_1 = inside;
    this.ubi_1 = this.pbq();
    this.abj_1 = this.bbj_1;
    this.cbj_1 = 0;
  }
  u6() {
    this.ibj_1.u6();
  }
}
class Info {
  constructor(index, id, local, startLocal, startTime, global, startGlobal, time) {
    index = index === VOID ? -1 : index;
    id = id === VOID ? 0 : id;
    local = local === VOID ? Vector2D.i2l() : local;
    startLocal = startLocal === VOID ? Vector2D.i2l() : startLocal;
    startTime = startTime === VOID ? Companion_getInstance_7().c1w_1 : startTime;
    global = global === VOID ? Vector2D.i2l() : global;
    startGlobal = startGlobal === VOID ? Vector2D.i2l() : startGlobal;
    time = time === VOID ? Companion_getInstance_7().c1w_1 : time;
    this.qbq_1 = new Mixin();
    this.rbq_1 = index;
    this.sbq_1 = id;
    this.tbq_1 = local;
    this.ubq_1 = startLocal;
    this.vbq_1 = startTime;
    this.wbq_1 = global;
    this.xbq_1 = startGlobal;
    this.ybq_1 = time;
  }
  n13(_set____db54di) {
    this.qbq_1.n13(_set____db54di);
  }
  o13() {
    return this.qbq_1.o13();
  }
  abr() {
    return DateTime__minus_impl_10njw8(this.ybq_1, this.vbq_1);
  }
  vbk() {
    var tmp = this.zbq_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('views');
    }
  }
  bbr() {
    return this.tbq_1.i2h_1;
  }
  cbr() {
    return this.tbq_1.j2h_1;
  }
  toString() {
    return 'Touch[' + this.sbq_1 + '](' + numberToInt(this.bbr()) + ', ' + numberToInt(this.cbr()) + ')';
  }
  hashCode() {
    var result = this.rbq_1;
    result = imul(result, 31) + this.sbq_1 | 0;
    result = imul(result, 31) + this.tbq_1.hashCode() | 0;
    result = imul(result, 31) + this.ubq_1.hashCode() | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.vbq_1) | 0;
    result = imul(result, 31) + this.wbq_1.hashCode() | 0;
    result = imul(result, 31) + this.xbq_1.hashCode() | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.ybq_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Info))
      return false;
    var tmp0_other_with_cast = other instanceof Info ? other : THROW_CCE();
    if (!(this.rbq_1 === tmp0_other_with_cast.rbq_1))
      return false;
    if (!(this.sbq_1 === tmp0_other_with_cast.sbq_1))
      return false;
    if (!this.tbq_1.equals(tmp0_other_with_cast.tbq_1))
      return false;
    if (!this.ubq_1.equals(tmp0_other_with_cast.ubq_1))
      return false;
    if (!equals(this.vbq_1, tmp0_other_with_cast.vbq_1))
      return false;
    if (!this.wbq_1.equals(tmp0_other_with_cast.wbq_1))
      return false;
    if (!this.xbq_1.equals(tmp0_other_with_cast.xbq_1))
      return false;
    if (!equals(this.ybq_1, tmp0_other_with_cast.ybq_1))
      return false;
    return true;
  }
}
class TouchEvents {
  constructor(view) {
    this.dbr_1 = view;
    this.ebr_1 = new Signal();
    this.fbr_1 = new Signal();
    this.gbr_1 = new Signal();
    this.hbr_1 = new Signal();
    this.ibr_1 = new Signal();
    this.jbr_1 = new Signal();
    this.kbr_1 = new Signal();
    var tmp = this;
    tmp.lbr_1 = Pool.e39(VOID, TouchEvents$infoPool$lambda);
    this.mbr_1 = FastIntMap();
    this.nbr_1 = FastArrayList.g14();
    var tmp_0 = Companion_getInstance_13().v8e_1.slice();
    this.dbr_1.w8a(tmp_0, TouchEvents$lambda(this));
  }
  obr(_this__u8e3s4, touch) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.TouchEvents.copyFrom.<anonymous>' call
    _this__u8e3s4.sbq_1 = touch.o8e_1;
    _this__u8e3s4.wbq_1 = touch.p8e_1;
    _this__u8e3s4.tbq_1 = this.dbr_1.jbq(touch.p8e_1);
    return _this__u8e3s4;
  }
  pbr(_this__u8e3s4) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.TouchEvents.start.<anonymous>' call
    _this__u8e3s4.ubq_1 = _this__u8e3s4.tbq_1;
    _this__u8e3s4.xbq_1 = _this__u8e3s4.wbq_1;
    return _this__u8e3s4;
  }
  qbr(views, globalXY) {
    var ev = new TouchEvent(Type_START_getInstance());
    ev.m8f(Type_START_getInstance());
    ev.r8f(0, globalXY, Status_ADD_getInstance());
    ev.n8f();
    onTouchEvent(this, views, ev);
    ev.m8f(Type_END_getInstance());
    ev.r8f(0, globalXY, Status_REMOVE_getInstance());
    ev.n8f();
    onTouchEvent(this, views, ev);
    get_mouse(this.dbr_1).zbh_1.x1x(get_mouse(this.dbr_1));
  }
}
class SingleTouchHandler {
  constructor() {
    this.rbr_1 = new Signal();
    this.sbr_1 = new Signal();
    this.tbr_1 = new Signal();
    this.ubr_1 = new Signal();
    this.vbr_1 = new Signal();
    this.wbr_1 = new Signal();
    this.xbr_1 = new Signal();
    this.ybr_1 = new Signal();
  }
}
class singleTouch$SingleTouchInfo {
  constructor(handler, startedInside) {
    handler = handler === VOID ? new SingleTouchHandler() : handler;
    startedInside = startedInside === VOID ? false : startedInside;
    this.zbr_1 = handler;
    this.abs_1 = startedInside;
  }
  toString() {
    return 'SingleTouchInfo(handler=' + toString(this.zbr_1) + ', startedInside=' + this.abs_1 + ')';
  }
  hashCode() {
    var result = hashCode(this.zbr_1);
    result = imul(result, 31) + getBooleanHashCode(this.abs_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof singleTouch$SingleTouchInfo))
      return false;
    var tmp0_other_with_cast = other instanceof singleTouch$SingleTouchInfo ? other : THROW_CCE();
    if (!equals(this.zbr_1, tmp0_other_with_cast.zbr_1))
      return false;
    if (!(this.abs_1 === tmp0_other_with_cast.abs_1))
      return false;
    return true;
  }
}
class ScaleRecognizerInfo {
  constructor(started, completed, start, current) {
    started = started === VOID ? false : started;
    completed = completed === VOID ? true : completed;
    start = start === VOID ? 0.0 : start;
    current = current === VOID ? 0.0 : current;
    this.cbs_1 = started;
    this.dbs_1 = completed;
    this.ebs_1 = start;
    this.fbs_1 = current;
  }
  gbs() {
    return this.fbs_1 / this.ebs_1;
  }
  toString() {
    return 'ScaleRecognizerInfo(started=' + this.cbs_1 + ', completed=' + this.dbs_1 + ', start=' + this.ebs_1 + ', current=' + this.fbs_1 + ')';
  }
  hashCode() {
    var result = getBooleanHashCode(this.cbs_1);
    result = imul(result, 31) + getBooleanHashCode(this.dbs_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ebs_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.fbs_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ScaleRecognizerInfo))
      return false;
    var tmp0_other_with_cast = other instanceof ScaleRecognizerInfo ? other : THROW_CCE();
    if (!(this.cbs_1 === tmp0_other_with_cast.cbs_1))
      return false;
    if (!(this.dbs_1 === tmp0_other_with_cast.dbs_1))
      return false;
    if (!equals(this.ebs_1, tmp0_other_with_cast.ebs_1))
      return false;
    if (!equals(this.fbs_1, tmp0_other_with_cast.fbs_1))
      return false;
    return true;
  }
}
class RotationRecognizerInfo {
  constructor(started, completed, start, current) {
    started = started === VOID ? false : started;
    completed = completed === VOID ? false : completed;
    start = start === VOID ? get_degrees(0) : start;
    current = current === VOID ? get_degrees(0) : current;
    this.hbs_1 = started;
    this.ibs_1 = completed;
    this.jbs_1 = start;
    this.kbs_1 = current;
  }
  lbs() {
    return Angle__minus_impl_xeau7k(this.kbs_1, this.jbs_1);
  }
  toString() {
    return 'RotationRecognizerInfo(started=' + this.hbs_1 + ', completed=' + this.ibs_1 + ', start=' + Angle__toString_impl_786qky(this.jbs_1) + ', current=' + Angle__toString_impl_786qky(this.kbs_1) + ')';
  }
  hashCode() {
    var result = getBooleanHashCode(this.hbs_1);
    result = imul(result, 31) + getBooleanHashCode(this.ibs_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.jbs_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.kbs_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof RotationRecognizerInfo))
      return false;
    var tmp0_other_with_cast = other instanceof RotationRecognizerInfo ? other : THROW_CCE();
    if (!(this.hbs_1 === tmp0_other_with_cast.hbs_1))
      return false;
    if (!(this.ibs_1 === tmp0_other_with_cast.ibs_1))
      return false;
    if (!equals(this.jbs_1, tmp0_other_with_cast.jbs_1))
      return false;
    if (!equals(this.kbs_1, tmp0_other_with_cast.kbs_1))
      return false;
    return true;
  }
}
class MaterialBlockUB extends UniformBlock {
  constructor() {
    MaterialBlockUB_instance = null;
    super(3);
    MaterialBlockUB_instance = this;
    this.qbs_1 = this.b90().p8z(this, u_ShadowColor$factory());
    this.rbs_1 = this.x8z().p8z(this, u_ShadowRadius$factory());
    this.sbs_1 = this.z8z().p8z(this, u_ShadowOffset$factory());
    this.tbs_1 = this.z8z().p8z(this, u_HighlightPos$factory());
    this.ubs_1 = this.x8z().p8z(this, u_HighlightRadius$factory());
    this.vbs_1 = this.b90().p8z(this, u_HighlightColor$factory());
    this.wbs_1 = this.z8z().p8z(this, u_Size$factory());
    this.xbs_1 = this.b90().p8z(this, u_Radius$factory());
    this.ybs_1 = this.x8z().p8z(this, u_BorderSizeHalf$factory());
    this.zbs_1 = this.b90().p8z(this, u_BorderColor$factory());
    this.abt_1 = this.b90().p8z(this, u_BackgroundColor$factory());
  }
  jbt() {
    return this.qbs_1.ko(this, u_ShadowColor$factory_0());
  }
  lbt() {
    return this.rbs_1.ko(this, u_ShadowRadius$factory_0());
  }
  kbt() {
    return this.sbs_1.ko(this, u_ShadowOffset$factory_0());
  }
  ebt() {
    return this.tbs_1.ko(this, u_HighlightPos$factory_0());
  }
  fbt() {
    return this.ubs_1.ko(this, u_HighlightRadius$factory_0());
  }
  gbt() {
    return this.vbs_1.ko(this, u_HighlightColor$factory_0());
  }
  cbt() {
    return this.wbs_1.ko(this, u_Size$factory_0());
  }
  bbt() {
    return this.xbs_1.ko(this, u_Radius$factory_0());
  }
  hbt() {
    return this.ybs_1.ko(this, u_BorderSizeHalf$factory_0());
  }
  ibt() {
    return this.zbs_1.ko(this, u_BorderColor$factory_0());
  }
  dbt() {
    return this.abt_1.ko(this, u_BackgroundColor$factory_0());
  }
}
class MaterialRender {
  constructor() {
    MaterialRender_instance = this;
    this.mbt_1 = MaterialBlockUB_getInstance().jbt();
    this.nbt_1 = MaterialBlockUB_getInstance().lbt();
    this.obt_1 = MaterialBlockUB_getInstance().kbt();
    this.pbt_1 = MaterialBlockUB_getInstance().ebt();
    this.qbt_1 = MaterialBlockUB_getInstance().fbt();
    this.rbt_1 = MaterialBlockUB_getInstance().gbt();
    this.sbt_1 = MaterialBlockUB_getInstance().cbt();
    this.tbt_1 = MaterialBlockUB_getInstance().bbt();
    this.ubt_1 = MaterialBlockUB_getInstance().hbt();
    this.vbt_1 = MaterialBlockUB_getInstance().ibt();
    this.wbt_1 = MaterialBlockUB_getInstance().dbt();
    var tmp = this;
    // Inline function 'korlibs.korge.view.Companion.buildShader' call
    var tmp292_$this = Companion_getInstance_8().yad_1;
    var tmp293_name = null == null ? Companion_getInstance_8().yad_1.k8s_1 : null;
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Companion.buildShader.<anonymous>' call
    // Inline function 'korlibs.korge.render.MaterialRender.PROGRAM.<anonymous>' call
    var roundedDist = this_0.h93(this_0.w93());
    var borderDist = this_0.h93(this_0.w93());
    var highlightDist = this_0.h93(this_0.w93());
    var borderAlpha = this_0.h93(this_0.w93());
    var highlightAlpha = this_0.h93(this_0.w93());
    var size = MaterialRender_getInstance().sbt_1;
    this_0.f92(roundedDist, this_0.k93(SDFShaders_getInstance().kak(), this_0.g95(this_0.b92(), this_0.k95(size, 2.0)), this_0.k95(size, 2.0), MaterialRender_getInstance().tbt_1));
    this_0.f92(this_0.i92(), this_0.j92(MaterialRender_getInstance().wbt_1, this_0.i93(SDFShaders_getInstance().eak(), roundedDist)));
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = this_0.r95(MaterialRender_getInstance().qbt_1, 0.0);
    var body = this_0.w92();
    // Inline function 'korlibs.korge.render.MaterialRender.PROGRAM.<anonymous>.<anonymous>' call
    body.f92(highlightDist, body.j93(SDFShaders_getInstance().zaj(), roundedDist, body.j93(SDFShaders_getInstance().jak(), body.g95(this_0.b92(), MaterialRender_getInstance().pbt_1), MaterialRender_getInstance().qbt_1)));
    body.f92(highlightAlpha, body.i93(SDFShaders_getInstance().eak(), highlightDist));
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond_0 = body.r95(highlightAlpha, 0.0);
    var body_0 = body.w92();
    // Inline function 'korlibs.korge.render.MaterialRender.PROGRAM.<anonymous>.<anonymous>.<anonymous>' call
    body_0.f92(body_0.i92(), body_0.j93(SDFShaders_getInstance().mak(), body_0.i92(), body_0.j92(MaterialRender_getInstance().rbt_1, highlightAlpha)));
    var stmIf = new If(cond_0, body_0.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    body.e92_1.f(stmIf);
    var stmIf_0 = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_0.e92_1.f(stmIf_0);
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond_1 = this_0.r95(MaterialRender_getInstance().ubt_1, 0.0);
    var body_1 = this_0.w92();
    // Inline function 'korlibs.korge.render.MaterialRender.PROGRAM.<anonymous>.<anonymous>' call
    body_1.f92(borderDist, body_1.j93(SDFShaders_getInstance().dak(), roundedDist, body_1.j95(MaterialRender_getInstance().ubt_1, 2.0)));
    body_1.f92(borderAlpha, body_1.i93(SDFShaders_getInstance().eak(), borderDist));
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond_2 = body_1.r95(borderAlpha, 0.0);
    var body_2 = body_1.w92();
    // Inline function 'korlibs.korge.render.MaterialRender.PROGRAM.<anonymous>.<anonymous>.<anonymous>' call
    body_2.f92(body_2.i92(), body_2.j93(SDFShaders_getInstance().mak(), body_2.i92(), body_2.j92(MaterialRender_getInstance().vbt_1, borderAlpha)));
    var stmIf_1 = new If(cond_2, body_2.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    body_1.e92_1.f(stmIf_1);
    var stmIf_2 = new If(cond_1, body_1.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_0.e92_1.f(stmIf_2);
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond_3 = this_0.t95(this_0.o95(this_0.s92(this_0.i92()), 1.0), this_0.r95(MaterialRender_getInstance().nbt_1, 0.0));
    var body_3 = this_0.w92();
    // Inline function 'korlibs.korge.render.MaterialRender.PROGRAM.<anonymous>.<anonymous>' call
    var shadowSoftness = MaterialRender_getInstance().nbt_1;
    var shadowOffset = MaterialRender_getInstance().obt_1;
    var shadowDistance = body_3.k93(SDFShaders_getInstance().kak(), body_3.g95(body_3.i95(body_3.o92(this_0.b92(), 'xy'), shadowOffset), body_3.k95(size, 2.0)), body_3.k95(size, 2.0), MaterialRender_getInstance().tbt_1);
    var shadowAlpha = body_3.h95(1.0, body_3.o94(body_3.f95(shadowSoftness), shadowSoftness, shadowDistance));
    body_3.f92(body_3.i92(), body_3.j93(SDFShaders_getInstance().mak(), body_3.j92(MaterialRender_getInstance().mbt_1, shadowAlpha), body_3.i92()));
    var stmIf_3 = new If(cond_3, body_3.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_0.e92_1.f(stmIf_3);
    this_0.f92(this_0.i92(), this_0.j92(this_0.i92(), this_0.g92()));
    var tmp294_fragment = FragmentShader(this_0.n92());
    tmp.xbt_1 = tmp292_$this.c9r(VOID, tmp294_fragment, tmp293_name);
  }
}
class Scene {
  constructor() {
    this.ybt_1 = new Mixin();
    this.dbu_1 = Container.vbw();
    this.ebu_1 = new CancellableGroup();
    var tmp = this;
    tmp.fbu_1 = lazy(Scene$coroutineContext$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.gbu_1 = lazy(Scene$sceneView$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.hbu_1 = lazy(Scene$resources$delegate$lambda(this));
  }
  n13(_set____db54di) {
    this.ybt_1.n13(_set____db54di);
  }
  o13() {
    return this.ybt_1.o13();
  }
  jbu() {
    var tmp = this.zbt_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('injector');
    }
  }
  wbw(_set____db54di) {
    this.abu_1 = _set____db54di;
  }
  vbk() {
    var tmp = this.abu_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('views');
    }
  }
  kbu() {
    var tmp = this.bbu_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('sceneContainer');
    }
  }
  saq() {
    return this.vbk().zb6_1;
  }
  xbw() {
    return this.vbk().eb7_1;
  }
  c15() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.fbu_1;
    coroutineContext$factory();
    return this_0.b2();
  }
  ybw() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.gbu_1;
    sceneView$factory();
    return this_0.b2();
  }
  lbu(size) {
    return SContainer.kbz(size);
  }
  z87(injector) {
    this.zbt_1 = injector;
    // Inline function 'korlibs.inject.Injector.get' call
    var tmp$ret$0 = injector.x87(getKClass(Views));
    this.wbw(tmp$ret$0);
    var tmp = this;
    // Inline function 'korlibs.inject.Injector.get' call
    tmp.bbu_1 = injector.x87(getKClass(SceneContainer));
    var tmp_0 = this;
    // Inline function 'korlibs.inject.Injector.get' call
    tmp_0.cbu_1 = injector.x87(getKClass(ResourcesRoot));
  }
  lbz(_this__u8e3s4, $completion) {
  }
  mbz(_this__u8e3s4, $completion) {
  }
  nbz($completion) {
  }
  obz($completion) {
  }
  pbz($completion) {
  }
  qbz($completion) {
    cancel_0(this.ebu_1);
    this.jbu().a88();
  }
  ibu($completion) {
  }
  rbz($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneAfterDestroyInternal__rup219(this, $completion), $completion);
  }
  sbz(size) {
    size_0(this.ybw(), size);
  }
}
class EmptyScene extends Scene {
  mbz(_this__u8e3s4, $completion) {
  }
}
class ScaledScene extends Scene {
  constructor(sceneWidth, sceneHeight, sceneScaleMode, sceneAnchor, sceneSmoothing) {
    sceneScaleMode = sceneScaleMode === VOID ? Companion_getInstance_31().n2v_1 : sceneScaleMode;
    sceneAnchor = sceneAnchor === VOID ? Companion_getInstance_32().f2h() : sceneAnchor;
    sceneSmoothing = sceneSmoothing === VOID ? true : sceneSmoothing;
    super();
    this.nc0_1 = sceneWidth;
    this.oc0_1 = sceneHeight;
    this.pc0_1 = sceneScaleMode;
    this.qc0_1 = sceneAnchor;
    this.rc0_1 = sceneSmoothing;
  }
  sc0() {
    return this.nc0_1;
  }
  tc0() {
    return this.oc0_1;
  }
  sbz(size) {
    onSizeChanged(this, this.ybw(), size);
  }
  lbu(size) {
    // Inline function 'kotlin.also' call
    var this_0 = SContainer.kbz(size);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.scene.ScaledScene.createSceneView.<anonymous>' call
    onSizeChanged(this, this_0, size);
    return this_0;
  }
}
class PixelatedScene extends ScaledScene {
  constructor(sceneWidth, sceneHeight, sceneScaleMode, sceneAnchor, sceneSmoothing) {
    sceneScaleMode = sceneScaleMode === VOID ? Companion_getInstance_31().n2v_1 : sceneScaleMode;
    sceneAnchor = sceneAnchor === VOID ? Companion_getInstance_32().f2h() : sceneAnchor;
    sceneSmoothing = sceneSmoothing === VOID ? false : sceneSmoothing;
    super(sceneWidth, sceneHeight, sceneScaleMode, sceneAnchor, sceneSmoothing);
  }
}
class VisitEntry {
  constructor(clazz, injects) {
    this.ac4_1 = clazz;
    this.bc4_1 = injects;
  }
  toString() {
    return 'VisitEntry(clazz=' + toString(this.ac4_1) + ', injects=' + toString(this.bc4_1) + ')';
  }
  hashCode() {
    var result = this.ac4_1.hashCode();
    result = imul(result, 31) + hashCode(this.bc4_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof VisitEntry))
      return false;
    var tmp0_other_with_cast = other instanceof VisitEntry ? other : THROW_CCE();
    if (!this.ac4_1.equals(tmp0_other_with_cast.ac4_1))
      return false;
    if (!equals(this.bc4_1, tmp0_other_with_cast.bc4_1))
      return false;
    return true;
  }
}
class Companion_4 {
  constructor() {
    Companion_instance_19 = this;
    this.ec1_1 = Companion_getInstance_1().ez('SceneContainer');
    var tmp = this;
    var tmp_0 = getKClass(EmptyScene);
    // Inline function 'kotlin.collections.listOf' call
    var tmp$ret$0 = emptyList();
    tmp.fc1_1 = new VisitEntry(tmp_0, tmp$ret$0);
  }
}
class SceneContainer$lambda$slambda {
  constructor(this$0, $event) {
    this.uc0_1 = this$0;
    this.vc0_1 = $event;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_15(this, $completion), $completion);
  }
}
class SceneContainer$_changeTo$slambda {
  constructor($newScene) {
    this.hc1_1 = $newScene;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_16(this, $completion), $completion);
  }
}
class SceneContainer$_changeTo$slambda_0 {
  constructor($newScene) {
    this.ic1_1 = $newScene;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_17(this, $completion), $completion);
  }
}
class SceneContainer$_changeTo$slambda_1 {
  constructor($oldScene) {
    this.jc1_1 = $oldScene;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_18(this, $completion), $completion);
  }
}
class SceneContainer$_changeTo$slambda_2 {
  constructor($oldScene) {
    this.kc1_1 = $oldScene;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_19(this, $completion), $completion);
  }
}
class SceneContainer$_changeTo$slambda_3 {
  constructor($oldScene) {
    this.lc1_1 = $oldScene;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_20(this, $completion), $completion);
  }
}
class SceneContainer$_changeTo$slambda_4 {
  constructor($newScene) {
    this.mc1_1 = $newScene;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_21(this, $completion), $completion);
  }
}
class BaseView extends BaseEventListener {
  static pgb() {
    return this.i8a();
  }
  hbh() {
  }
}
class View extends BaseView {
  static jgh(isContainer) {
    var $this = this.pgb();
    $this.pb9_1 = isContainer;
    var tmp = $this;
    tmp.qb9_1 = lazy(View$bviewAll$delegate$lambda($this));
    $this.rb9_1 = true;
    $this.sb9_1 = null;
    $this.tb9_1 = null;
    $this.ub9_1 = null;
    $this.vb9_1 = 0;
    $this.wb9_1 = null;
    $this.xb9_1 = 1.0;
    $this.yb9_1 = null;
    $this.zb9_1 = null;
    $this.aba_1 = null;
    $this.bba_1 = Companion_getInstance_17().sa5_1;
    $this.cba_1 = 0.0;
    $this.dba_1 = 0.0;
    $this.eba_1 = 1.0;
    $this.fba_1 = 1.0;
    $this.gba_1 = Companion_getInstance_35().r2h_1;
    $this.hba_1 = Companion_getInstance_35().r2h_1;
    $this.iba_1 = Companion_getInstance_35().r2h_1;
    $this.jba_1 = 0.0;
    $this.kba_1 = scale$factory($this);
    $this.lba_1 = false;
    $this.mba_1 = true;
    $this.nba_1 = true;
    $this.oba_1 = true;
    $this.pba_1 = true;
    $this.qba_1 = Companion_getInstance_41().i2m_1;
    $this.rba_1 = Companion_getInstance_41().i2m_1;
    $this.sba_1 = -1;
    $this.tba_1 = Companion_getInstance_41().i2m_1;
    $this.uba_1 = -1;
    $this.vba_1 = new ColorTransformMul();
    $this.wba_1 = new ColorTransformMul();
    $this.xba_1 = -1;
    $this.yba_1 = Companion_getInstance_17().sa5_1;
    $this.zba_1 = -1;
    $this.abb_1 = true;
    $this.bbb_1 = 0;
    $this.cbb_1 = 0;
    $this.dbb_1 = false;
    $this.ebb_1 = false;
    $this.fbb_1 = null;
    $this.gbb_1 = false;
    $this.hbb_1 = null;
    $this.ibb_1 = 0;
    $this.jbb_1 = true;
    $this.kbb_1 = null;
    return $this;
  }
  static hgn() {
    return this.jgh(false);
  }
  zca() {
    return 0.0;
  }
  acb() {
    return 0.0;
  }
  bca() {
    return null;
  }
  ogb(_set____db54di) {
    this.tb9_1 = _set____db54di;
  }
  bcb() {
    return this.tb9_1;
  }
  ccb() {
    return this.ub9_1;
  }
  fgh(value) {
    this.sb9_1 = value;
  }
  dcb(value) {
    this.vb9_1 = value;
  }
  m8h() {
    return this.vb9_1;
  }
  ecb(value) {
    if (this.yb9_1 === value)
      return Unit_instance;
    this.yb9_1 = value;
    // Inline function 'korlibs.korge.view.View.forEachChild' call
    var tmp337_safe_receiver = this.bca();
    var tmp;
    if (tmp337_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var n = 0;
      while (n < tmp337_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.view.View.<set-_stage>.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        tmp337_safe_receiver.i1(_unary__edvuaz).ecb(value);
      }
      tmp = Unit_instance;
    }
    this.fcb();
  }
  fcb() {
  }
  gcb(value) {
    if (this.zb9_1 === value)
      return Unit_instance;
    this.zb9_1 = value;
    var parent = this.zc9();
    // Inline function 'korlibs.korge.view.View.forEachChild' call
    var tmp337_safe_receiver = this.bca();
    var tmp;
    if (tmp337_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var n = 0;
      while (n < tmp337_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.view.View.<set-_invalidateNotifier>.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        tmp337_safe_receiver.i1(_unary__edvuaz).gcb(parent);
      }
      tmp = Unit_instance;
    }
  }
  zc9() {
    return this.zb9_1;
  }
  hcb() {
    var tmp345_safe_receiver = this.wb9_1;
    this.gcb(tmp345_safe_receiver == null ? null : tmp345_safe_receiver.zc9());
  }
  icb(value) {
    if (this.wb9_1 === value)
      return Unit_instance;
    this.wb9_1 = value;
    var tmp346_safe_receiver = this.wb9_1;
    this.ecb(tmp346_safe_receiver == null ? null : tmp346_safe_receiver.yb9_1);
    this.hcb();
    this.zcc();
    this.j8a(value);
  }
  w15() {
    return this.wb9_1;
  }
  jcb(value) {
    if (!this.bba_1.equals(value)) {
      this.bba_1 = value;
      this.cca();
    }
  }
  kcb() {
    return !(this.w15() == null) ? ensureNotNull(this.w15()).kcb() * this.xb9_1 : this.xb9_1;
  }
  lcb(x, y) {
    this.occ();
    if (!(this.cba_1 === x) || !(this.dba_1 === y)) {
      this.cba_1 = x;
      this.dba_1 = y;
      this.xcc();
    }
  }
  s5w(value) {
    return this.lcb(value.i2h_1, value.j2h_1);
  }
  lz() {
    return Vector2D.k2h(this.n4f(), this.p4f());
  }
  rbh(v) {
    this.lcb(v, this.p4f());
  }
  n4f() {
    this.occ();
    return this.cba_1;
  }
  sbh(v) {
    this.lcb(this.n4f(), v);
  }
  p4f() {
    this.occ();
    return this.dba_1;
  }
  mcb(v) {
    var tmp347_safe_receiver = this.w15();
    if (tmp347_safe_receiver == null)
      null;
    else {
      tmp347_safe_receiver.tca(this, this.jba_1, v);
    }
    this.jba_1 = v;
  }
  ncb() {
    return this.jba_1;
  }
  ocb(v) {
    this.ucb(Scale.s2r(v, v));
  }
  pcb() {
    return this.vcb().x2o();
  }
  qcb(v) {
    this.occ();
    if (!(this.eba_1 === v)) {
      this.eba_1 = v;
      this.xcc();
    }
  }
  rcb() {
    this.occ();
    return this.eba_1;
  }
  scb(v) {
    this.occ();
    if (!(this.fba_1 === v)) {
      this.fba_1 = v;
      this.xcc();
    }
  }
  tcb() {
    this.occ();
    return this.fba_1;
  }
  ucb(value) {
    this.qcb(value.q2r_1);
    this.scb(value.r2r_1);
  }
  vcb() {
    return Scale.s2r(this.rcb(), this.tcb());
  }
  wcb(v) {
    this.occ();
    if (!equals(this.gba_1, v)) {
      this.gba_1 = v;
      this.xcc();
    }
  }
  xcb() {
    this.occ();
    return this.gba_1;
  }
  ycb(v) {
    this.occ();
    if (!equals(this.hba_1, v)) {
      this.hba_1 = v;
      this.xcc();
    }
  }
  zcb() {
    this.occ();
    return this.hba_1;
  }
  acc(v) {
    this.occ();
    if (!equals(this.iba_1, v)) {
      this.iba_1 = v;
      this.xcc();
    }
  }
  bcc() {
    this.occ();
    return this.iba_1;
  }
  ccc(value) {
    var tmp349_safe_receiver = this.w15();
    var tmp0_elvis_lhs = tmp349_safe_receiver == null ? null : tmp349_safe_receiver.jbq(value);
    this.s5w(tmp0_elvis_lhs == null ? value : tmp0_elvis_lhs);
  }
  dcc() {
    var tmp348_safe_receiver = this.w15();
    var tmp0_elvis_lhs = tmp348_safe_receiver == null ? null : tmp348_safe_receiver.gcd(Vector2D.k2h(this.n4f(), this.p4f()));
    return tmp0_elvis_lhs == null ? Vector2D.k2h(this.n4f(), this.p4f()) : tmp0_elvis_lhs;
  }
  ecc(value) {
    this.bc7(value);
  }
  j1() {
    return this.cc7();
  }
  bc7(value) {
    var size = this.j1();
    this.ucb(Scale.s2r((this.rcb() === 0.0 ? 1.0 : this.rcb()) * (value.d2j_1 / size.d2j_1), (this.tcb() === 0.0 ? 1.0 : this.tcb()) * (value.e2j_1 / size.e2j_1)));
  }
  cc7() {
    return this.wbp().j1();
  }
  fcc(value) {
    this.bc7(this.cc7().b2w(value));
  }
  gcc() {
    return this.cc7().d2j_1;
  }
  hcc(value) {
    this.bc7(this.cc7().b2w(VOID, value));
  }
  icc() {
    return this.cc7().e2j_1;
  }
  jcc(value) {
    this.fcc(this.rcb() === 0.0 ? value : value / this.rcb());
  }
  kcc() {
    return this.gcc() * this.rcb();
  }
  lcc(value) {
    this.hcc(this.tcb() === 0.0 ? value : value / this.tcb());
  }
  mcc() {
    return this.icc() * this.tcb();
  }
  fek(value) {
    this.fcc(value);
  }
  j2y() {
    return this.gcc();
  }
  gek(value) {
    this.hcc(value);
  }
  k2y() {
    return this.icc();
  }
  m59(v) {
    if (!(v === this.vba_1.x58())) {
      this.vba_1.m59(v);
      this.dca();
    }
  }
  x58() {
    return this.vba_1.x58();
  }
  kbj(v) {
    this.ubh(v);
  }
  ncc() {
    return this.tbh();
  }
  ubh(v) {
    if (!(this.vba_1.l59() === v)) {
      this.vba_1.k59(v);
      this.dca();
    }
  }
  tbh() {
    return this.vba_1.l59();
  }
  occ() {
    if (this.oba_1)
      return Unit_instance;
    this.oba_1 = true;
    var t = this.qba_1.z2n();
    this.cba_1 = t.l2o_1;
    this.dba_1 = t.m2o_1;
    this.eba_1 = t.n2o_1;
    this.fba_1 = t.o2o_1;
    this.gba_1 = t.p2o_1;
    this.hba_1 = t.q2o_1;
    this.iba_1 = t.r2o_1;
  }
  f22() {
    var tmp350_safe_receiver = this.w15();
    var tmp0_elvis_lhs = tmp350_safe_receiver == null ? null : tmp350_safe_receiver.f22();
    return tmp0_elvis_lhs == null ? this : tmp0_elvis_lhs;
  }
  pbj() {
    return this.yb9_1;
  }
  hbq(_set____db54di) {
    this.lba_1 = _set____db54di;
  }
  fbq() {
    return this.lba_1;
  }
  pcc() {
    return this.mba_1;
  }
  car(value) {
    if (!(this.nba_1 === value)) {
      this.nba_1 = value;
      this.cca();
    }
  }
  qcc() {
    return this.nba_1;
  }
  yai(matrix) {
    this.qba_1 = matrix;
    this.oba_1 = false;
    this.cca();
    this.ycc();
  }
  rcc(ratio, l, r) {
    this.qba_1 = interpolate_2(toRatio(ratio), l, r);
    this.oba_1 = false;
    this.cca();
    this.ycc();
  }
  ige(value) {
    this.yai(value);
    this.cca();
    this.ycc();
  }
  scc() {
    if (!this.pba_1) {
      this.pba_1 = true;
      this.dbb_1 = true;
      this.qba_1 = Companion_getInstance_41().w2m(this.n4f(), this.p4f(), this.bcc(), this.rcb(), this.tcb(), this.xcb(), this.zcb(), 0.0, 0.0);
    }
    return this.qba_1;
  }
  ybp() {
    if (!(this.sba_1 === this.bbb_1)) {
      this.sba_1 = this.bbb_1;
      this.dbb_1 = true;
      this.rba_1 = !(this.w15() == null) ? this.scc().z2m(ensureNotNull(this.w15()).ybp()) : this.scc();
    }
    return this.rba_1;
  }
  tcc() {
    if (!(this.uba_1 === this.bbb_1)) {
      this.uba_1 = this.bbb_1;
      this.dbb_1 = true;
      this.tba_1 = this.ybp().y2n();
    }
    return this.tba_1;
  }
  ucc() {
    updateRenderColorTransformIfRequired(this);
    return this.wba_1;
  }
  vcc() {
    updateRenderBlendModeIfRequired(this);
    return this.yba_1;
  }
  wcc() {
    updateRenderColorTransformIfRequired(this);
    return this.wba_1.x58();
  }
  mgh(views) {
    return this.jbq(views.bb7_1.saa());
  }
  xcc() {
    this.pba_1 = false;
    this.cca();
  }
  cca() {
    this.bbb_1 = this.bbb_1 + 1 | 0;
    this.dbb_1 = false;
    this.abb_1 = true;
    this.hbh();
  }
  ycc() {
    if (!(this.fbb_1 == null)) {
      this.fbb_1 = null;
      var tmp354_safe_receiver = this.w15();
      if (tmp354_safe_receiver == null)
        null;
      else {
        tmp354_safe_receiver.ycc();
      }
    }
  }
  zcc() {
    this.hc7();
  }
  acd() {
    this.hc7();
  }
  hc7() {
  }
  hbh() {
    var tmp355_safe_receiver = this.zb9_1;
    if (tmp355_safe_receiver == null)
      null;
    else {
      tmp355_safe_receiver.aca(this);
    }
    this.ycc();
  }
  dca() {
    this.cbb_1 = this.cbb_1 + 1 | 0;
    this.ebb_1 = false;
    this.abb_1 = true;
    this.hbh();
  }
  bcd(phase) {
    if (this.hbb_1 == null)
      this.hbb_1 = fastArrayListOf([]);
    var tmp358_safe_receiver = this.hbb_1;
    if (tmp358_safe_receiver == null)
      null;
    else
      tmp358_safe_receiver.f(phase);
    var tmp359_safe_receiver = this.hbb_1;
    if (tmp359_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.collections.sortBy' call
      if (tmp359_safe_receiver.j1() > 1) {
        // Inline function 'kotlin.comparisons.compareBy' call
        var tmp = View$addRenderPhase$lambda;
        var tmp$ret$0 = new sam$kotlin_Comparator$0_0(tmp);
        sortWith(tmp359_safe_receiver, tmp$ret$0);
      }
    }
  }
  fc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    var tmp361_safe_receiver = this.hbb_1;
    if (tmp361_safe_receiver == null)
      null;
    else {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = tmp361_safe_receiver.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.korge.view.View.render.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        array[_unary__edvuaz].jh8(this, ctx);
      }
    }
    try {
      this.ccd(ctx);
    }finally {
      var tmp362_safe_receiver = this.hbb_1;
      if (tmp362_safe_receiver == null)
        null;
      else {
        // Inline function 'korlibs.datastructure.FastArrayList.fastForEachReverse' call
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        var array_0 = tmp362_safe_receiver.e14_1;
        var n_0 = 0;
        while (n_0 < array_0.length) {
          // Inline function 'korlibs.korge.view.View.render.<anonymous>' call
          // Inline function 'kotlin.js.unsafeCast' call
          // Inline function 'korlibs.datastructure.get' call
          // Inline function 'kotlin.js.asDynamic' call
          // Inline function 'kotlin.js.asDynamic' call
          array_0[(tmp362_safe_receiver.j1() - n_0 | 0) - 1 | 0].kh8(this, ctx);
          n_0 = n_0 + 1 | 0;
        }
      }
    }
  }
  ccd(ctx) {
    var oldPhase = this.ibb_1;
    try {
      this.ibb_1 = 0;
      this.dcd(ctx);
    }finally {
      this.ibb_1 = oldPhase;
    }
  }
  dcd(ctx) {
    var stages = this.hbb_1;
    if (!(stages == null) && this.ibb_1 < stages.j1()) {
      var _unary__edvuaz = this.ibb_1;
      this.ibb_1 = _unary__edvuaz + 1 | 0;
      stages.i1(_unary__edvuaz).lh8(this, ctx);
    } else {
      var tmp = this.ibb_1;
      var tmp0_elvis_lhs = stages == null ? null : stages.j1();
      if (equals(tmp, tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs)) {
        this.jc7(ctx);
        this.ibb_1 = this.ibb_1 + 1 | 0;
      }
    }
  }
  qca(ctx) {
    if (this.gbb_1 || this === get_debugAnnotateView(ctx)) {
      this.ecd(ctx);
    }
  }
  ecd(ctx) {
    var local = this.wbp();
    // Inline function 'korlibs.korge.render.useLineBatcher' call
    // Inline function 'korlibs.korge.render.LineRenderBatcher.use' call
    var this_0 = get_debugLineRenderContext(ctx);
    var this_1 = this_0.xag_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    // Inline function 'korlibs.korge.render.LineRenderBatcher.drawWithGlobalMatrix' call
    var this_2 = get_debugLineRenderContext(ctx);
    $l$block_0: {
      // Inline function 'korlibs.datastructure.keep' call
      var mut = currentMatrix$factory_0(this_2);
      var temp = mut.get();
      try {
        // Inline function 'korlibs.korge.render.LineRenderBatcher.drawWithGlobalMatrix.<anonymous>' call
        if (!(null == null))
          this_2.jah_1 = null;
        // Inline function 'korlibs.korge.view.View.renderDebugAnnotationsInternal.<anonymous>' call
        $l$block: {
          // Inline function 'korlibs.korge.render.LineRenderBatcher.blending' call
          var blending = Companion_getInstance_17().ba6_1;
          var doUpdate = !(this_0.iah_1 === blending);
          var old = this_0.iah_1;
          try {
            if (doUpdate) {
              this_0.xag_1.ya8();
              this_0.iah_1 = blending;
            }
            // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
            var color = Colors_getInstance().i4r_1;
            var m = this_0.jah_1;
            // Inline function 'korlibs.korge.render.LineRenderBatcher.color' call
            var oldColor = this_0.aah_1;
            this_0.aah_1 = color;
            try {
              // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector.<anonymous>' call
              // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
              // Inline function 'kotlin.apply' call
              var this_3 = new VectorPath();
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'korlibs.korge.view.View.renderDebugAnnotationsInternal.<anonymous>.<anonymous>.<anonymous>' call
              this_3.n4a(this.rcd());
              this_0.pah(this_3, m);
            }finally {
              this_0.aah_1 = oldColor;
            }
            break $l$block;
          }finally {
            if (doUpdate) {
              this_0.xag_1.ya8();
            }
            this_0.iah_1 = old;
          }
        }
        // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
        var color_0 = Colors_getInstance().i4r_1;
        var m_0 = this_0.jah_1;
        // Inline function 'korlibs.korge.render.LineRenderBatcher.color' call
        var oldColor_0 = this_0.aah_1;
        this_0.aah_1 = color_0;
        try {
          // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector.<anonymous>' call
          // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
          // Inline function 'kotlin.apply' call
          var this_4 = new VectorPath();
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.korge.view.View.renderDebugAnnotationsInternal.<anonymous>.<anonymous>' call
          this_4.e3y(this.gcd(Vector2D.k2h(local.p2i(), local.r2i())));
          this_4.g3y(this.gcd(Vector2D.k2h(local.q2i(), local.r2i())));
          this_4.g3y(this.gcd(Vector2D.k2h(local.q2i(), local.s2i())));
          this_4.g3y(this.gcd(Vector2D.k2h(local.p2i(), local.s2i())));
          this_4.u6();
          this_0.pah(this_4, m_0);
        }finally {
          this_0.aah_1 = oldColor_0;
        }
        // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
        var color_1 = Colors_getInstance().w4w_1;
        var m_1 = this_0.jah_1;
        // Inline function 'korlibs.korge.render.LineRenderBatcher.color' call
        var oldColor_1 = this_0.aah_1;
        this_0.aah_1 = color_1;
        try {
          // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector.<anonymous>' call
          // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
          // Inline function 'kotlin.apply' call
          var this_5 = new VectorPath();
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.korge.view.View.renderDebugAnnotationsInternal.<anonymous>.<anonymous>' call
          var anchorSize = 6.0 * ensureNotNull(get_views(ctx)).qag();
          this_5.m3s(this.gcd(local.h2j()), anchorSize);
          this_5.m3s(this.gcd(local.p2t()), anchorSize);
          this_5.m3s(this.gcd(local.j2j()), anchorSize);
          this_5.m3s(this.gcd(local.q2t()), anchorSize);
          this_5.m3s(this.gcd(interpolate_0(Companion_getInstance_45().h10_1, local.h2j(), local.p2t())), anchorSize);
          this_5.m3s(this.gcd(interpolate_0(Companion_getInstance_45().h10_1, local.p2t(), local.j2j())), anchorSize);
          this_5.m3s(this.gcd(interpolate_0(Companion_getInstance_45().h10_1, local.j2j(), local.q2t())), anchorSize);
          this_5.m3s(this.gcd(interpolate_0(Companion_getInstance_45().h10_1, local.q2t(), local.h2j())), anchorSize);
          this_0.pah(this_5, m_1);
        }finally {
          this_0.aah_1 = oldColor_1;
        }
        // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
        var color_2 = Colors_getInstance().k4r_1;
        var m_2 = this_0.jah_1;
        // Inline function 'korlibs.korge.render.LineRenderBatcher.color' call
        var oldColor_2 = this_0.aah_1;
        this_0.aah_1 = color_2;
        try {
          // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector.<anonymous>' call
          // Inline function 'korlibs.korge.render.LineRenderBatcher.drawVector' call
          // Inline function 'kotlin.apply' call
          var this_6 = new VectorPath();
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.korge.view.View.renderDebugAnnotationsInternal.<anonymous>.<anonymous>' call
          var center = this.dcc();
          this_6.l4a(Vector2D.k2h(center.i2h_1, center.j2h_1 - 5), Vector2D.k2h(center.i2h_1, center.j2h_1 + 5));
          this_6.l4a(Vector2D.k2h(center.i2h_1 - 5, center.j2h_1), Vector2D.k2h(center.i2h_1 + 5, center.j2h_1));
          this_0.pah(this_6, m_2);
        }finally {
          this_0.aah_1 = oldColor_2;
        }
        break $l$block_0;
      }finally {
        mut.set(temp);
      }
    }
  }
  toString() {
    var out = get_portableSimpleName(getKClassFromExpression(this));
    if (!(this.n4f() === 0.0) || !(this.p4f() === 0.0))
      out = out + (':pos=(' + this.fcd(this.n4f()) + ',' + this.fcd(this.p4f()) + ')');
    if (!(this.rcb() === 1.0) || !(this.tcb() === 1.0))
      out = out + (':scale=(' + this.fcd(this.rcb()) + ',' + this.fcd(this.tcb()) + ')');
    if (!equals(this.xcb(), Companion_getInstance_35().r2h_1) || !equals(this.zcb(), Companion_getInstance_35().r2h_1))
      out = out + (':skew=(' + this.fcd(_Angle___get_degrees__impl__qg56vw(this.xcb())) + ',' + this.fcd(_Angle___get_degrees__impl__qg56vw(this.zcb())) + ')');
    if (!equals(_Angle___get_absoluteValue__impl__4qoakv(this.bcc()), Companion_getInstance_35().r2h_1))
      out = out + (':rotation=(' + this.fcd(_Angle___get_degrees__impl__qg56vw(this.bcc())) + '\xBA)');
    if (!(this.aba_1 == null))
      out = out + (':name=(' + this.aba_1 + ')');
    if (!this.bba_1.equals(Companion_getInstance_17().sa5_1))
      out = out + (':blendMode=(' + this.bba_1.toString() + ')');
    if (!this.qcc())
      out = out + (':visible=' + this.qcc());
    if (!(this.tbh() === 1.0))
      out = out + (':alpha=' + niceStr_0(this.tbh(), 2));
    if (!(_RGBA___get_rgb__impl__tijaqt(this.x58()) === _RGBA___get_rgb__impl__tijaqt(Colors_getInstance().g4r_1)))
      out = out + (':colorMul=' + _RGBA___get_hexString__impl__kocngm(this.x58()));
    return out;
  }
  fcd(_this__u8e3s4) {
    return toStringDecimal(_this__u8e3s4, 2, true);
  }
  jbq(p) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var this_0 = this.tcc();
      if (this_0.n2i()) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = Vector2D.k2h(this_0.d2n(p.i2h_1, p.j2h_1), this_0.g2n(p.i2h_1, p.j2h_1));
    }
    return tmp$ret$0;
  }
  abn(p0, p1) {
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var this_0 = this.jbq(p1);
    var that = this.jbq(p0);
    return Vector2D.k2h(this_0.i2h_1 - that.i2h_1, this_0.j2h_1 - that.j2h_1);
  }
  gcd(p) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var this_0 = this.ybp();
      if (this_0.n2i()) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = Vector2D.k2h(this_0.d2n(p.i2h_1, p.j2h_1), this_0.g2n(p.i2h_1, p.j2h_1));
    }
    return tmp$ret$0;
  }
  hcd(globalPos, direction) {
    if (!this.jbb_1)
      return null;
    if (!this.qcc())
      return null;
    var tmp364_safe_receiver = this.bca();
    if (tmp364_safe_receiver == null)
      null;
    else {
      // Inline function 'korlibs.datastructure.iterators.fastForEachReverse' call
      var n = 0;
      while (n < tmp364_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.view.View.hitTest.<anonymous>' call
        var tmp365_safe_receiver = tmp364_safe_receiver.i1((tmp364_safe_receiver.j1() - n | 0) - 1 | 0).hcd(globalPos, direction);
        if (tmp365_safe_receiver == null)
          null;
        else {
          // Inline function 'kotlin.let' call
          // Inline function 'kotlin.contracts.contract' call
          return tmp365_safe_receiver;
        }
        n = n + 1 | 0;
      }
    }
    var res = this.kcd(globalPos);
    if (!(res == null))
      return res;
    var tmp;
    if (this instanceof Stage) {
      tmp = this;
    } else {
      tmp = null;
    }
    return tmp;
  }
  bbs(globalPos, direction, $super) {
    direction = direction === VOID ? HitTestDirection_ANY_getInstance() : direction;
    return $super === VOID ? this.hcd(globalPos, direction) : $super.hcd.call(this, globalPos, direction);
  }
  vbp(p) {
    if (!this.jbb_1)
      return null;
    if (!this.qcc())
      return null;
    if (this.pcc()) {
      var tmp371_safe_receiver = this.bca();
      if (tmp371_safe_receiver == null)
        null;
      else {
        // Inline function 'korlibs.datastructure.iterators.fastForEachReverse' call
        var n = 0;
        while (n < tmp371_safe_receiver.j1()) {
          // Inline function 'korlibs.korge.view.View.mouseHitTest.<anonymous>' call
          var tmp372_safe_receiver = tmp371_safe_receiver.i1((tmp371_safe_receiver.j1() - n | 0) - 1 | 0).vbp(p);
          if (tmp372_safe_receiver == null)
            null;
          else {
            // Inline function 'kotlin.let' call
            // Inline function 'kotlin.contracts.contract' call
            return tmp372_safe_receiver;
          }
          n = n + 1 | 0;
        }
      }
    }
    if (!this.fbq())
      return null;
    var tmp373_safe_receiver = this.kcd(p);
    if (tmp373_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var area = this.icd();
      if (!area.n2i() && !area.u2k(p))
        return null;
      return tmp373_safe_receiver;
    }
    var tmp;
    if (this instanceof Stage) {
      tmp = this;
    } else {
      tmp = null;
    }
    return tmp;
  }
  icd() {
    var out = Companion_getInstance_38().v2i_1;
    var count = 0;
    // Inline function 'korlibs.korge.view.forEachAscendant' call
    var view = this;
    if (!true) {
      var tmp390_safe_receiver = view;
      view = tmp390_safe_receiver == null ? null : tmp390_safe_receiver.w15();
    }
    while (!(view == null)) {
      // Inline function 'korlibs.korge.view.View.getClippingAreaInternal.<anonymous>' call
      var it = view;
      var tmp;
      var tmp_0;
      if (!(it instanceof Stage)) {
        tmp_0 = it instanceof FixedSizeContainer;
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp = it.t78();
      } else {
        tmp = false;
      }
      if (tmp) {
        var _localBounds = it.tcd();
        if (count === 0) {
          out = _localBounds;
        } else {
          out = out.y2t(_localBounds);
        }
        count = count + 1 | 0;
      }
      view = view.w15();
    }
    return count === 0 ? Companion_getInstance_38().x2i() : out;
  }
  jcd(p, direction) {
    if (!this.jbb_1)
      return null;
    var ll = this.jbq(p);
    var bounds = this.wbp();
    if (!bounds.u2k(ll)) {
      return null;
    }
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    var that = Vector2D.u2n(this.zca(), this.acb());
    var l = Vector2D.k2h(ll.i2h_1 + that.i2h_1, ll.j2h_1 + that.j2h_1);
    if (this.kbb_1 === false)
      return this;
    var hitShape = this.bcb();
    var hitShapes = this.ccb();
    if (this.kbb_1 == null && (!(hitShape == null) || !(hitShapes == null))) {
      if (hitShapes == null)
        null;
      else {
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var n = 0;
        while (n < hitShapes.j1()) {
          // Inline function 'korlibs.korge.view.View.hitTestInternal.<anonymous>' call
          var _unary__edvuaz = n;
          n = _unary__edvuaz + 1 | 0;
          if (hitShapes.i1(_unary__edvuaz).u4b(l))
            return this;
        }
      }
      if (!(hitShape == null) && hitShape.u4b(l))
        return this;
      return null;
    } else {
      return this;
    }
  }
  kcd(p, direction, $super) {
    direction = direction === VOID ? HitTestDirection_ANY_getInstance() : direction;
    return $super === VOID ? this.jcd(p, direction) : $super.jcd.call(this, p, direction);
  }
  qbh() {
    var tmp375_safe_receiver = this.w15();
    if (tmp375_safe_receiver == null)
      null;
    else
      tmp375_safe_receiver.xca(this);
  }
  lcd(target, inclusive) {
    var tmp;
    if (target === this.w15()) {
      tmp = this.scc();
    } else if (target === this) {
      tmp = Companion_getInstance_41().i2m_1;
    } else {
      var commonAncestor = Companion_instance_36.gh8(this, target);
      var tmp_0;
      if (!(commonAncestor === null)) {
        if (target.w15() == null && inclusive) {
          return this.ybp();
        }
        tmp_0 = this.ybp().z2m(target.tcc());
      } else {
        tmp_0 = Companion_getInstance_41().i2m_1;
      }
      tmp = tmp_0;
    }
    var out = tmp;
    if (inclusive) {
      out = out.z2m(target.scc());
    }
    return out;
  }
  mcd() {
    var tmp0_elvis_lhs = this.ncd();
    return tmp0_elvis_lhs == null ? this.tcd() : tmp0_elvis_lhs;
  }
  ncd() {
    var stage = this.f22();
    if (!(stage instanceof Stage))
      return null;
    return this.ocd(stage);
  }
  ocd(bp) {
    return this.tcd().j2u(bp.fag());
  }
  pcd(ctx) {
    return ctx.aai() ? this.ocd(ctx) : this.tcd();
  }
  qcd(ctx) {
    return this.pcd(ctx);
  }
  rcd() {
    return this.tcd();
  }
  scd(includeFilters) {
    return this.ycd(this.f22(), VOID, true, includeFilters);
  }
  tcd(includeFilters, $super) {
    includeFilters = includeFilters === VOID ? false : includeFilters;
    return $super === VOID ? this.scd(includeFilters) : $super.scd.call(this, includeFilters);
  }
  hek(bounds) {
    var transform = ensureNotNull(this.w15()).ybp().z2n();
    this.ccc(bounds.h2j());
    sizeScaled(this, Size2D.k2t(bounds.k2i_1 * transform.n2o_1, bounds.l2i_1 * transform.o2o_1));
  }
  ucd(target, inclusive, includeFilters) {
    return this.xcd(target, false, inclusive, includeFilters);
  }
  vcd(target, inclusive, includeFilters, $super) {
    target = target === VOID ? this : target;
    inclusive = inclusive === VOID ? false : inclusive;
    includeFilters = includeFilters === VOID ? false : includeFilters;
    return $super === VOID ? this.ucd(target, inclusive, includeFilters) : $super.ucd.call(this, target, inclusive, includeFilters);
  }
  wcd(concat, doAnchoring, includeFilters) {
    var out = this.zcd(doAnchoring, includeFilters);
    if (concat.o2i() && !concat.a2n()) {
      var tmp = Companion_getInstance_40();
      var tmp$ret$0;
      $l$block: {
        // Inline function 'korlibs.math.geom.Matrix.transform' call
        var p = out.h2j();
        if (concat.n2i()) {
          tmp$ret$0 = p;
          break $l$block;
        }
        tmp$ret$0 = Vector2D.k2h(concat.d2n(p.i2h_1, p.j2h_1), concat.g2n(p.i2h_1, p.j2h_1));
      }
      var tmp_0 = tmp$ret$0;
      var tmp$ret$1;
      $l$block_0: {
        // Inline function 'korlibs.math.geom.Matrix.transform' call
        var p_0 = out.p2t();
        if (concat.n2i()) {
          tmp$ret$1 = p_0;
          break $l$block_0;
        }
        tmp$ret$1 = Vector2D.k2h(concat.d2n(p_0.i2h_1, p_0.j2h_1), concat.g2n(p_0.i2h_1, p_0.j2h_1));
      }
      var tmp_1 = tmp$ret$1;
      var tmp$ret$2;
      $l$block_1: {
        // Inline function 'korlibs.math.geom.Matrix.transform' call
        var p_1 = out.j2j();
        if (concat.n2i()) {
          tmp$ret$2 = p_1;
          break $l$block_1;
        }
        tmp$ret$2 = Vector2D.k2h(concat.d2n(p_1.i2h_1, p_1.j2h_1), concat.g2n(p_1.i2h_1, p_1.j2h_1));
      }
      var tmp_2 = tmp$ret$2;
      var tmp$ret$3;
      $l$block_2: {
        // Inline function 'korlibs.math.geom.Matrix.transform' call
        var p_2 = out.q2t();
        if (concat.n2i()) {
          tmp$ret$3 = p_2;
          break $l$block_2;
        }
        tmp$ret$3 = Vector2D.k2h(concat.d2n(p_2.i2h_1, p_2.j2h_1), concat.g2n(p_2.i2h_1, p_2.j2h_1));
      }
      out = _BoundsBuilder___get_bounds__impl__s1l1gx(tmp.z2i(tmp_0, tmp_1, tmp_2, tmp$ret$3));
    }
    return out;
  }
  xcd(target, doAnchoring, inclusive, includeFilters) {
    return this.wcd(this.lcd(target == null ? this : target, inclusive), doAnchoring, includeFilters);
  }
  ycd(target, doAnchoring, inclusive, includeFilters, $super) {
    target = target === VOID ? this : target;
    doAnchoring = doAnchoring === VOID ? true : doAnchoring;
    inclusive = inclusive === VOID ? false : inclusive;
    includeFilters = includeFilters === VOID ? false : includeFilters;
    return $super === VOID ? this.xcd(target, doAnchoring, inclusive, includeFilters) : $super.xcd.call(this, target, doAnchoring, inclusive, includeFilters);
  }
  zcd(doAnchoring, includeFilters) {
    var tmp0_elvis_lhs = this.fbb_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.also' call
      var this_0 = this.gc7();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.View.getLocalBounds.<anonymous>' call
      this.fbb_1 = this_0;
      tmp = this_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var out = tmp;
    if (!doAnchoring) {
      out = out.o2n(Vector2D.u2n(this.zca(), this.acb()));
    }
    if (includeFilters) {
      var tmp376_safe_receiver = get_filter(this);
      if (tmp376_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        out = expandedBorderRectangle(tmp376_safe_receiver, out);
      }
    }
    return out;
  }
  wbp(doAnchoring, includeFilters, $super) {
    doAnchoring = doAnchoring === VOID ? true : doAnchoring;
    includeFilters = includeFilters === VOID ? false : includeFilters;
    return $super === VOID ? this.zcd(doAnchoring, includeFilters) : $super.zcd.call(this, doAnchoring, includeFilters);
  }
  gc7() {
    return Companion_getInstance_38().x2i();
  }
  r8a(type, event, result) {
    var tmp379_safe_receiver = this.w15();
    if (tmp379_safe_receiver == null)
      null;
    else
      tmp379_safe_receiver.y8a(type, event, result);
  }
}
class Container extends View {
  static vbw(cull) {
    cull = cull === VOID ? false : cull;
    var $this = this.jgh(true);
    $this.mbw_1 = cull;
    $this.nbw_1 = FastArrayList.g14();
    $this.obw_1 = new ContainerCollection($this, $this.nbw_1);
    $this.pbw_1 = null;
    $this.qbw_1 = new MMatrix();
    $this.rbw_1 = false;
    $this.sbw_1 = false;
    $this.tbw_1 = FastArrayList.g14();
    return $this;
  }
  bca() {
    return this.nbw_1;
  }
  cca() {
    super.cca();
    // Inline function 'korlibs.korge.view.Container.fastForEachChild' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.obw_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.view.Container.fastForEachChild.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'korlibs.korge.view.Container.invalidate.<anonymous>' call
      var child = this_0.i1(_unary__edvuaz);
      if (child.dbb_1) {
        child.cca();
      }
    }
  }
  dca() {
    super.dca();
    // Inline function 'korlibs.korge.view.Container.fastForEachChild' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.obw_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.view.Container.fastForEachChild.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'korlibs.korge.view.Container.invalidateColorTransform.<anonymous>' call
      var child = this_0.i1(_unary__edvuaz);
      if (child.ebb_1) {
        child.dca();
      }
    }
  }
  eca() {
    return this.nbw_1.j1();
  }
  fca() {
    var tmp = this.w15();
    if (!(tmp == null) ? isInterface(tmp, Reference) : false)
      return this.w15();
    var tmp225_safe_receiver = this.w15();
    return tmp225_safe_receiver == null ? null : tmp225_safe_receiver.fca();
  }
  gca(view1, view2) {
    if (!equals(view1.w15(), view2.w15()) || !equals(view1.w15(), this))
      return Unit_instance;
    this.sca();
    var index1 = view1.m8h();
    var index2 = view2.m8h();
    this.nbw_1.p3(index1, view2);
    view2.dcb(index1);
    this.nbw_1.p3(index2, view1);
    view1.dcb(index2);
  }
  hca(view, index) {
    if (!equals(view.w15(), this))
      return Unit_instance;
    var targetIndex = clamp(index, 0, this.eca() - 1 | 0);
    while (view.m8h() < targetIndex)
      this.gca(view, this.nbw_1.i1(view.m8h() + 1 | 0));
    while (view.m8h() > targetIndex)
      this.gca(view, this.nbw_1.i1(view.m8h() - 1 | 0));
  }
  ica(view) {
  }
  jca(view) {
  }
  hc7() {
    super.hc7();
    // Inline function 'korlibs.korge.view.View.forEachChild' call
    var tmp337_safe_receiver = this.bca();
    var tmp;
    if (tmp337_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var n = 0;
      while (n < tmp337_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.view.Container.onAncestorChanged.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        tmp337_safe_receiver.i1(_unary__edvuaz).acd();
      }
      tmp = Unit_instance;
    }
  }
  kca(index) {
    return this.nbw_1.i1(index);
  }
  lca(index) {
    return getOrNull(this.nbw_1, index);
  }
  mca(index) {
    return this.xca(this.lca(index));
  }
  nca() {
    // Inline function 'korlibs.korge.view.Container.fastForEachChild' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.obw_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.view.Container.fastForEachChild.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'korlibs.korge.view.Container.removeChildren.<anonymous>' call
      var child = this_0.i1(_unary__edvuaz);
      child.icb(null);
      child.dcb(-1);
    }
    this.nbw_1.o3();
    this.sca();
  }
  oca(view) {
    return this.wca(view, this.eca());
  }
  ubw(view) {
    this.wca(view, this.eca());
  }
  i1(index) {
    return this.kca(index);
  }
  jc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    this.pca(ctx);
  }
  pca(ctx) {
    if (!this.mbw_1) {
      // Inline function 'korlibs.korge.view.Container.fastForEachChildRender' call
      if (!this.rbw_1) {
        this.rbw_1 = true;
        this.tbw_1.o3();
        this.tbw_1.k1(this.obw_1);
        if (this.rca())
          this.sbw_1 = false;
      }
      if (!this.sbw_1) {
        this.sbw_1 = true;
        sortWith(this.tbw_1, ZIndexComparator_instance);
      }
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = this.tbw_1.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.korge.view.Container.fastForEachChildRender.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'korlibs.korge.view.Container.renderChildrenInternal.<anonymous>' call
        array[_unary__edvuaz].fc7(ctx);
      }
      return Unit_instance;
    }
    var tmp = findFirstAscendant(this, Container$renderChildrenInternal$lambda);
    var clippingContainer = (tmp == null ? true : tmp instanceof FixedSizeContainer) ? tmp : null;
    var renderedCount = 0;
    var culledCount = 0;
    var tmp0_elvis_lhs = clippingContainer == null ? null : clippingContainer.tcd();
    var bounds = tmp0_elvis_lhs == null ? Companion_getInstance_38().x2i() : tmp0_elvis_lhs;
    // Inline function 'korlibs.korge.view.Container.fastForEachChildRender' call
    if (!this.rbw_1) {
      this.rbw_1 = true;
      this.tbw_1.o3();
      this.tbw_1.k1(this.obw_1);
      if (this.rca())
        this.sbw_1 = false;
    }
    if (!this.sbw_1) {
      this.sbw_1 = true;
      sortWith(this.tbw_1, ZIndexComparator_instance);
    }
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array_0 = this.tbw_1.e14_1;
    var n_0 = 0;
    while (n_0 < array_0.length) {
      // Inline function 'korlibs.korge.view.Container.fastForEachChildRender.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz_0 = n_0;
      n_0 = _unary__edvuaz_0 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'korlibs.korge.view.Container.renderChildrenInternal.<anonymous>' call
      var child = array_0[_unary__edvuaz_0];
      if (bounds.n2i() || bounds.v2t(child.scd(true))) {
        child.fc7(ctx);
        renderedCount = renderedCount + 1 | 0;
      } else {
        culledCount = culledCount + 1 | 0;
      }
    }
  }
  qca(ctx) {
    // Inline function 'korlibs.korge.view.Container.fastForEachChildRender' call
    if (!this.rbw_1) {
      this.rbw_1 = true;
      this.tbw_1.o3();
      this.tbw_1.k1(this.obw_1);
      if (this.rca())
        this.sbw_1 = false;
    }
    if (!this.sbw_1) {
      this.sbw_1 = true;
      sortWith(this.tbw_1, ZIndexComparator_instance);
    }
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.tbw_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.korge.view.Container.fastForEachChildRender.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'korlibs.korge.view.Container.renderDebug.<anonymous>' call
      array[_unary__edvuaz].qca(ctx);
    }
    super.qca(ctx);
  }
  gc7() {
    var bb = Companion_getInstance_40().y2i();
    // Inline function 'korlibs.korge.view.Container.fastForEachChild' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.obw_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.view.Container.fastForEachChild.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'korlibs.korge.view.Container.getLocalBoundsInternal.<anonymous>' call
      var child = this_0.i1(_unary__edvuaz);
      bb = BoundsBuilder__plus_impl_cz7f7x(bb, child.ycd(this));
    }
    return _BoundsBuilder___get_bounds__impl__s1l1gx(bb);
  }
  rca() {
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.nbw_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.korge.view.Container.shouldSortChildren.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      if (!(array[_unary__edvuaz].ncb() === 0.0)) {
        return true;
      }
    }
    return false;
  }
  sca() {
    this.rbw_1 = false;
    this.uca();
  }
  tca(child, oldZIndex, newZIndex) {
    if (!equals(child.w15(), this))
      return Unit_instance;
    this.sbw_1 = false;
    this.uca();
  }
  uca() {
    var tmp227_safe_receiver = this.pbj();
    var tmp228_safe_receiver = tmp227_safe_receiver == null ? null : tmp227_safe_receiver.vbk();
    if (tmp228_safe_receiver == null)
      null;
    else {
      tmp228_safe_receiver.aca(this);
    }
  }
  vca(child) {
    var parent = this;
    if (parent === child) {
      invalidOp("Can't addChild to itself");
    }
    if (hasAncestor(parent, child)) {
      invalidOp("Can't addChild to an ancestor");
    }
  }
  wca(view, index) {
    this.vca(view);
    var tmp229_safe_receiver = view.w15();
    if (tmp229_safe_receiver == null)
      null;
    else {
      tmp229_safe_receiver.sca();
    }
    view.qbh();
    var aindex = clamp(index, 0, this.eca());
    view.dcb(aindex);
    var children = this.nbw_1;
    children.r7(aindex, view);
    var inductionVariable = aindex + 1 | 0;
    var last = children.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        children.i1(n).dcb(n);
      }
       while (inductionVariable < last);
    view.icb(this);
    view.cca();
    this.ica(view);
    this.sca();
    this.uca();
    this.ycc();
  }
  xca(view) {
    if (!((view == null ? null : view.w15()) === this))
      return false;
    var inductionVariable = view.m8h() + 1 | 0;
    var last = this.eca();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var _receiver__tnumb7 = this.nbw_1.i1(i);
        var _unary__edvuaz = _receiver__tnumb7.m8h();
        _receiver__tnumb7.dcb(_unary__edvuaz - 1 | 0);
      }
       while (inductionVariable < last);
    this.nbw_1.q3(view.m8h());
    view.icb(null);
    view.dcb(-1);
    this.sca();
    this.uca();
    this.ycc();
    return true;
  }
  yca(old, new_0) {
    if (old === new_0)
      return false;
    if (!equals(old.w15(), this))
      return false;
    this.uca();
    this.sca();
    var tmp231_safe_receiver = new_0.w15();
    var tmp232_safe_receiver = tmp231_safe_receiver == null ? null : tmp231_safe_receiver.nbw_1;
    if (tmp232_safe_receiver == null)
      null;
    else
      tmp232_safe_receiver.n3(new_0);
    ensureNotNull(old.w15()).nbw_1.p3(old.m8h(), new_0);
    new_0.dcb(old.m8h());
    new_0.icb(old.w15());
    old.icb(null);
    new_0.cca();
    old.dcb(-1);
    this.ycc();
    return true;
  }
  o8a(type, event, result) {
    if (this.pbw_1 == null)
      this.pbw_1 = FastArrayList.f14(this.obw_1.j1());
    // Inline function 'korlibs.datastructure.iterators.fastForEachWithTemp' call
    var this_0 = this.nbw_1;
    var temp = ensureNotNull(this.pbw_1);
    toFastList(this_0, temp);
    try {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = temp.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.korge.view.Container.dispatchChildren.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        array[_unary__edvuaz].x8a(type, event, result);
      }
    }finally {
      temp.o3();
    }
  }
}
class CachedContainer extends Container {
  static qgb(cache, renderQuality) {
    cache = cache === VOID ? true : cache;
    renderQuality = renderQuality === VOID ? null : renderQuality;
    var $this = this.vbw();
    $this.sc9_1 = cache;
    $this.tc9_1 = renderQuality;
    $this.uc9_1 = null;
    $this.vc9_1 = true;
    $this.wc9_1 = -1.0;
    $this.xc9_1 = Companion_getInstance_38().l2m();
    $this.yc9_1 = Scale.h2v(1);
    return $this;
  }
  hbh() {
    super.hbh();
    this.vc9_1 = true;
  }
  jc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    if (!this.sc9_1) {
      return super.jc7(ctx);
    }
    if (this.uc9_1 == null) {
      this.uc9_1 = new CacheTexture(this, ctx);
      this.vc9_1 = true;
    }
    var cache = ensureNotNull(this.uc9_1);
    ctx.hai(cache);
    var renderScale = ctx.vah().v3_1 === 0 ? 1.0 : ctx.rah();
    var tmp0_elvis_lhs = this.tc9_1;
    var doExpensiveScaling = !get_isLow(tmp0_elvis_lhs == null ? ctx.vah() : tmp0_elvis_lhs);
    if (this.vc9_1 || !(this.wc9_1 === renderScale)) {
      this.wc9_1 = renderScale;
      this.xc9_1 = this.wbp(VOID, false);
      this.yc9_1 = doExpensiveScaling ? this.mcd().j1().j2v(this.xc9_1.j1()) : Scale.h2v(1);
      this.vc9_1 = false;
      var texWidth = coerceAtLeast(numberToInt(this.xc9_1.k2i_1 * renderScale * this.yc9_1.q2r_1), 1);
      var texHeight = coerceAtLeast(numberToInt(this.xc9_1.l2i_1 * renderScale * this.yc9_1.r2r_1), 1);
      cache.wgb(texWidth, texHeight);
      ctx.ya8();
      // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer' call
      var frameBuffer = cache.sgb_1;
      // Inline function 'korlibs.korge.render.RenderContext.setFrameBufferTemporally' call
      ctx.mai(frameBuffer);
      try {
        // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer.<anonymous>' call
        // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
        // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
        var this_0 = ctx.ka7_1;
        var this_1 = this_0.ta7_1;
        if (!(this_1.oa7_1 === this_0)) {
          this_1.oa7_1 = this_0;
          this_1.ya8();
        }
        $l$block_0: {
          // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
          var scissor = AGScissor.p8v(0, 0, frameBuffer.a8k_1, frameBuffer.b8k_1);
          var temp = this_0.ra8_1;
          this_0.eaf();
          this_0.ra8_1 = scissor;
          try {
            if (true) {
              ctx.eai(Colors_getInstance().l4r_1);
            }
            // Inline function 'korlibs.korge.view.CachedContainer.renderInternal.<anonymous>' call
            // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp' call
            var matrix = this.tcc().q2n(-this.xc9_1.i2i_1, -this.xc9_1.j2i_1).k2n(renderScale * this.yc9_1.q2r_1, renderScale * this.yc9_1.r2r_1);
            ctx.ya8();
            var temp4 = ctx.za6_1;
            var temp2d = ctx.aa7_1;
            ctx.aa7_1 = matrix;
            ctx.za6_1 = toMatrix4(matrix);
            $l$block: {
              // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
              var this_2 = ctx.y8r(ProjViewUB_getInstance());
              var _unary__edvuaz = this_2.n8t();
              this_2.v9h(_unary__edvuaz + 1 | 0);
              this_2.w9h(this_2.n8t() + 1 | 0);
              var blockSize = this_2.r8r_1;
              var index0 = imul(this_2.n8t() - 1 | 0, blockSize);
              var index1 = imul(this_2.n8t(), blockSize);
              var texBlockSize = this_2.o8r_1.s8z().j1();
              var texIndex0 = imul(this_2.n8t() - 1 | 0, texBlockSize);
              var texIndex1 = imul(this_2.n8t(), texBlockSize);
              if (this_2.n8t() > 0) {
                arraycopy(this_2.t8r_1, index0, this_2.t8r_1, index1, blockSize);
              } else {
                arrayfill(this_2.t8r_1, 0, 0, blockSize);
              }
              // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp.<anonymous>' call
              var $this$push = this_2.o8r_1;
              this_2.u8r_1.m9i($this$push.r8z(), ctx.za6_1);
              if (true && this_2.n8t() >= 1) {
                var equals = arrayequal(this_2.t8r_1, index0, this_2.t8r_1, index1, blockSize);
                if (equals) {
                  var _unary__edvuaz_0 = this_2.n8t();
                  this_2.v9h(_unary__edvuaz_0 - 1 | 0);
                  break $l$block;
                }
              }
            }
            try {
              // Inline function 'korlibs.korge.view.CachedContainer.renderInternal.<anonymous>.<anonymous>' call
              super.jc7(ctx);
            }finally {
              ctx.ya8();
              ctx.za6_1 = temp4;
              ctx.aa7_1 = temp2d;
              ctx.y8r(ProjViewUB_getInstance()).x9h();
            }
            break $l$block_0;
          }finally {
            this_0.eaf();
            this_0.ra8_1 = temp;
          }
        }
      }finally {
        ctx.nai();
      }
    }
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_3 = ctx.ka7_1;
    var this_4 = this_3.ta7_1;
    if (!(this_4.oa7_1 === this_3)) {
      this_4.oa7_1 = this_3;
      this_4.ya8();
    }
    this_3.aa9(cache.ugb_1, VOID, VOID, VOID, VOID, this.ybp().v2n(this.xc9_1.i2i_1, this.xc9_1.j2i_1).m2n(1.0 / (renderScale * this.yc9_1.q2r_1), 1.0 / (renderScale * this.yc9_1.r2r_1)), VOID, this.wcc(), this.bba_1);
  }
  zc9() {
    return this;
  }
  aca(view) {
    this.vc9_1 = true;
    var tmp218_safe_receiver = this.w15();
    var tmp219_safe_receiver = tmp218_safe_receiver == null ? null : tmp218_safe_receiver.zb9_1;
    if (tmp219_safe_receiver == null)
      null;
    else {
      tmp219_safe_receiver.aca(view);
    }
  }
}
class Reference {}
class FixedSizeCachedContainer extends CachedContainer {
  static eg1(size, cache, clip) {
    size = size === VOID ? Size2D.f2j(100, 100) : size;
    cache = cache === VOID ? true : cache;
    clip = clip === VOID ? true : clip;
    var $this = this.qgb(cache);
    $this.bg1_1 = clip;
    $this.cg1_1 = size;
    $this.dg1_1 = Ref(false);
    return $this;
  }
  bc7(_set____db54di) {
    this.cg1_1 = _set____db54di;
  }
  cc7() {
    return this.cg1_1;
  }
  gc7() {
    return new Rectangle(0.0, 0.0, this.j2y(), this.k2y());
  }
  jc7(ctx) {
    $l$block_0: {
      // Inline function 'korlibs.korge.view.Companion.renderClipped' call
      var clip = this.bg1_1;
      var renderingInternalRef = this.dg1_1;
      if (renderingInternalRef.b2()) {
        super.jc7(ctx);
        break $l$block_0;
      }
      if (clip) {
        var m = this.ybp();
        var hasRotation = !(m.o2m_1 === 0.0) || !(m.p2m_1 === 0.0);
        if (hasRotation) {
          var old = renderingInternalRef.b2();
          try {
            renderingInternalRef.n3a(true);
            renderFiltered(this, ctx, Companion_getInstance_80());
          }finally {
            renderingInternalRef.n3a(old);
          }
          break $l$block_0;
        }
        // Inline function 'korlibs.korge.render.RenderContext.useCtx2d' call
        // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
        var batcher = ctx.ka7_1;
        if (!(ctx.oa7_1 === batcher)) {
          ctx.oa7_1 = batcher;
          ctx.ya8();
        }
        // Inline function 'korlibs.korge.render.RenderContext.useCtx2d.<anonymous>' call
        // Inline function 'korlibs.korge.view.Companion.renderClipped.<anonymous>' call
        var c2d = ctx.ma7_1;
        var bounds = this.qcd(ctx).j2u(ctx.aa7_1).e2s();
        var rect = c2d.qai_1.mae().x8v();
        var intersects = true;
        if (!(rect == null)) {
          intersects = bounds.v2t(rect);
        }
        if (intersects) {
          // Inline function 'korlibs.korge.render.RenderContext2D.scissor' call
          var scissor = Companion_getInstance_22().s8v(bounds);
          $l$block_1: {
            // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
            var this_0 = c2d.qai_1;
            var scissor_0 = c2d.daj(scissor);
            var temp = this_0.ra8_1;
            this_0.eaf();
            this_0.ra8_1 = scissor_0;
            try {
              super.jc7(ctx);
              break $l$block_1;
            }finally {
              this_0.eaf();
              this_0.ra8_1 = temp;
            }
          }
        } else {
          // Inline function 'korlibs.korge.view.FixedSizeCachedContainer.renderInternal.<anonymous>' call
          super.jc7(ctx);
        }
      } else {
        // Inline function 'korlibs.korge.view.FixedSizeCachedContainer.renderInternal.<anonymous>' call
        super.jc7(ctx);
      }
    }
  }
}
class UIView extends FixedSizeCachedContainer {
  static wc6(size, cache) {
    Companion_getInstance_61();
    size = size === VOID ? Companion_getInstance_61().hcz_1 : size;
    cache = cache === VOID ? false : cache;
    var $this = this.eg1(size, cache, false);
    $this.uc6_1 = size;
    $this.vc6_1 = false;
    return $this;
  }
  bc7(value) {
    if (!this.uc6_1.equals(value)) {
      this.uc6_1 = value;
      this.yc6();
    }
  }
  cc7() {
    return this.uc6_1;
  }
  dc7(_this__u8e3s4, ctx, view) {
    // Inline function 'korlibs.korge.render.renderCtx2d' call
    // Inline function 'korlibs.korge.render.RenderContext.useCtx2d' call
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    var batcher = ctx.ka7_1;
    if (!(ctx.oa7_1 === batcher)) {
      ctx.oa7_1 = batcher;
      ctx.ya8();
    }
    // Inline function 'korlibs.korge.render.RenderContext.useCtx2d.<anonymous>' call
    // Inline function 'korlibs.korge.render.renderCtx2d.<anonymous>' call
    var context = ctx.ma7_1;
    // Inline function 'korlibs.korge.render.RenderContext2D.keep' call
    $l$block_3: {
      // Inline function 'korlibs.korge.render.RenderContext2D.keepMatrix' call
      var old = context.uai_1;
      try {
        // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>' call
        $l$block_2: {
          // Inline function 'korlibs.korge.render.RenderContext2D.keepBlendMode' call
          var oldBlendFactors = context.vai_1;
          try {
            // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>' call
            $l$block_1: {
              // Inline function 'korlibs.korge.render.RenderContext2D.keepColor' call
              var multiplyColor = context.wai_1;
              try {
                // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>' call
                $l$block_0: {
                  // Inline function 'korlibs.korge.render.RenderContext2D.keepFiltering' call
                  var filtering = context.xai_1;
                  try {
                    // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                    $l$block: {
                      // Inline function 'korlibs.korge.render.RenderContext2D.keepSize' call
                      var size = context.sai_1;
                      try {
                        // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                        context.sai_1 = Size2D.k2t(this.j2y(), this.k2y());
                        context.vai_1 = this.vcc();
                        context.wai_1 = this.wcc();
                        context.yai(this.ybp());
                        // Inline function 'korlibs.korge.ui.UIView.render.<anonymous>' call
                        _this__u8e3s4(context, view);
                        break $l$block;
                      }finally {
                        context.sai_1 = size;
                      }
                    }
                    break $l$block_0;
                  }finally {
                    context.xai_1 = filtering;
                  }
                }
                break $l$block_1;
              }finally {
                context.wai_1 = multiplyColor;
              }
            }
            break $l$block_2;
          }finally {
            context.vai_1 = oldBlendFactors;
          }
        }
        break $l$block_3;
      }finally {
        context.uai_1 = old;
      }
    }
  }
  ec7(_this__u8e3s4, ctx, view, $super) {
    var tmp;
    if (view === VOID) {
      tmp = this instanceof View ? this : THROW_CCE();
    } else {
      tmp = view;
    }
    view = tmp;
    var tmp_0;
    if ($super === VOID) {
      this.dc7(_this__u8e3s4, ctx, view);
      tmp_0 = Unit_instance;
    } else {
      tmp_0 = $super.dc7.call(this, _this__u8e3s4, ctx, view);
    }
    return tmp_0;
  }
  gc7() {
    return new Rectangle(0.0, 0.0, this.j2y(), this.k2y());
  }
  jcz() {
    return this.fbq();
  }
  yc6() {
    var tmp160_safe_receiver = this.w15();
    if (tmp160_safe_receiver == null)
      null;
    else {
      tmp160_safe_receiver.jca(this);
    }
  }
  hc7() {
    super.hc7();
    this.ic7();
  }
  ic7() {
    this.cca();
  }
  jc7(ctx) {
    registerUISupportOnce(this);
    super.jc7(ctx);
  }
}
class SceneContainer extends UIView {
  static lbe(views, defaultTransition, name, size) {
    Companion_getInstance_52();
    defaultTransition = defaultTransition === VOID ? withEasing(get_AlphaTransition(), get_EASE_IN_OUT_QUAD(Companion_getInstance_44())) : defaultTransition;
    name = name === VOID ? 'sceneContainer' : name;
    size = size === VOID ? views.kb8_1.j1() : size;
    var $this = this.wc6(size);
    $this.fbe_1 = views;
    $this.gbe_1 = defaultTransition;
    $this.aba_1 = name;
    var tmp = $this;
    // Inline function 'kotlin.also' call
    var this_0 = TransitionView.xc6();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.scene.SceneContainer.transitionView.<anonymous>' call
    $this.ubw(this_0);
    tmp.hbe_1 = this_0;
    $this.ibe_1 = null;
    var tmp_0 = Companion_instance_8;
    $this.k8a(tmp_0, SceneContainer$lambda($this));
    $this.jbe_1 = arrayListOf([Companion_getInstance_52().fc1_1]);
    $this.kbe_1 = 0;
    return $this;
  }
  c15() {
    return this.fbe_1.yb6_1;
  }
  yc6() {
    var tmp79_safe_receiver = this.ibe_1;
    if (tmp79_safe_receiver == null)
      null;
    else {
      tmp79_safe_receiver.sbz(Size2D.k2t(this.j2y(), this.k2y()));
    }
  }
  zc6(clazz, injects, time, transition, $completion) {
    this.ac7(new VisitEntry(clazz, toList(injects)));
    return _changeTo(this, clazz, injects.slice(), time, transition, $completion);
  }
  mbe(clazz, injects, time, transition, $completion, $super) {
    var tmp;
    if (time === VOID) {
      // Inline function 'korlibs.time.seconds' call
      // Inline function 'korlibs.time.seconds' call
      tmp = toDuration(0, DurationUnit_SECONDS_getInstance());
    } else {
      tmp = time;
    }
    time = tmp;
    transition = transition === VOID ? this.gbe_1 : transition;
    return $super === VOID ? this.zc6(clazz, injects, time, transition, $completion) : $super.zc6.call(this, clazz, injects, new Duration(time), transition, $completion);
  }
  gc1(newScene, time, transition, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator__changeTo__ueovgq_0(this, newScene, time, transition, $completion), $completion);
  }
  ac7(entry) {
    while (this.jbe_1.j1() <= this.kbe_1) {
      this.jbe_1.f(Companion_getInstance_52().fc1_1);
    }
    this.jbe_1.p3(this.kbe_1, entry);
  }
}
class TransitionProcess {}
function start(prev, next) {
  return Unit_instance;
}
function end(prev, next) {
  return Unit_instance;
}
function render(ctx, prev, next, ratio) {
  return Unit_instance;
}
class TransitionView extends Container {
  static xc6() {
    var $this = this.vbw();
    dummyView($this);
    dummyView($this);
    $this.vc3_1 = Companion_getInstance_45().f10_1;
    $this.wc3_1 = get_AlphaTransition();
    return $this;
  }
  l1i() {
    return this.i1(0);
  }
  i1i() {
    return this.i1(1);
  }
  yc3(next, transition) {
    this.wc3_1 = transition;
    this.vc3_1 = Companion_getInstance_45().f10_1;
    this.xc3_1 = transition.dce();
    this.ece(this.i1i(), next);
    _get_transitionProcess__5hlux5(this).ace(this.l1i(), this.i1i());
  }
  toString() {
    return super.toString() + (':ratio=' + Ratio__toString_impl_mfgt3e(this.vc3_1) + ':transition=' + toString(this.wc3_1));
  }
  zc3() {
    this.vc3_1 = Companion_getInstance_45().i10_1;
    _get_transitionProcess__5hlux5(this).bce(this.l1i(), this.i1i());
    this.ece(dummyView(this), this.i1i());
  }
  ece(prev, next) {
    this.nca();
    this.oca(prev);
    this.oca(next);
  }
  jc7(ctx) {
    if (Ratio__compareTo_impl_z2ienc(this.vc3_1, Companion_getInstance_45().f10_1) <= 0) {
      this.l1i().fc7(ctx);
    } else if (Ratio__compareTo_impl_z2ienc(this.vc3_1, Companion_getInstance_45().i10_1) >= 0) {
      this.i1i().fc7(ctx);
    } else {
      _get_transitionProcess__5hlux5(this).cce(ctx, this.l1i(), this.i1i(), this.vc3_1);
    }
  }
}
class Transition$1 {
  constructor($render, $name) {
    this.fce_1 = $render;
    this.gce_1 = $name;
  }
  dce() {
    return this;
  }
  cce(ctx, prev, next, ratio) {
    return this.fce_1(ctx, prev, next, new Ratio(ratio));
  }
  toString() {
    return this.gce_1;
  }
}
class withEasing$1$create$1 {
  constructor($process, $easing) {
    this.hce_1 = $process;
    this.ice_1 = $easing;
  }
  ace(prev, next) {
    return this.hce_1.ace(prev, next);
  }
  bce(prev, next) {
    return this.hce_1.bce(prev, next);
  }
  cce(ctx, prev, next, ratio) {
    return this.hce_1.cce(ctx, prev, next, this.ice_1.zz(ratio));
  }
}
class withEasing$1 {
  constructor($this_withEasing, $easing) {
    this.jce_1 = $this_withEasing;
    this.kce_1 = $easing;
  }
  toString() {
    return toString(this.jce_1) + '.withEasing';
  }
  dce() {
    var process = this.jce_1.dce();
    return new withEasing$1$create$1(process, this.kce_1);
  }
}
class ViewStyles {
  constructor(view) {
    this.lce_1 = view;
    this.mce_1 = null;
    this.nce_1 = atomic$int$1(0);
  }
  qce(prop, default_0) {
    var tmp81_safe_receiver = this.mce_1;
    var tmp = tmp81_safe_receiver == null ? null : tmp81_safe_receiver.k3(prop.callableName);
    var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var tmp82_safe_receiver = this.lce_1.w15();
      var tmp83_safe_receiver = tmp82_safe_receiver == null ? null : get_styles(tmp82_safe_receiver);
      tmp_0 = tmp83_safe_receiver == null ? null : tmp83_safe_receiver.qce(prop, default_0);
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var tmp1_elvis_lhs = tmp_0;
    return tmp1_elvis_lhs == null ? default_0 : tmp1_elvis_lhs;
  }
  rce(updating) {
    if (updating === 0) {
      var tmp = this.lce_1;
      var tmp84_safe_receiver = isInterface(tmp, StyleableView) ? tmp : null;
      if (tmp84_safe_receiver == null)
        null;
      else {
        tmp84_safe_receiver.sce();
      }
    }
  }
  oce(updating, $super) {
    updating = updating === VOID ? this.nce_1.kotlinx$atomicfu$value : updating;
    var tmp;
    if ($super === VOID) {
      this.rce(updating);
      tmp = Unit_instance;
    } else {
      tmp = $super.rce.call(this, updating);
    }
    return tmp;
  }
}
class ViewStyle {
  constructor(default_0) {
    this.pce_1 = default_0;
  }
}
class StyleableView {}
function updatedStyles() {
}
class MsdfRender {
  constructor() {
    MsdfRender_instance = this;
    this.tce_1 = this.xce(true, VOID, false);
    this.uce_1 = this.xce(true, VOID, true);
    this.vce_1 = this.yce(false, 'r', false);
    this.wce_1 = this.yce(false, 'a', false);
  }
  yce(msdf, sdf, inverted) {
    // Inline function 'korlibs.korge.view.Companion.buildShader' call
    var name = 'PROGRAM_msdf=' + msdf + ',sdf=' + sdf + ',inverted=' + inverted;
    var tmp292_$this = Companion_getInstance_8().yad_1;
    var tmp293_name = name == null ? Companion_getInstance_8().yad_1.k8s_1 : name;
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Companion.buildShader.<anonymous>' call
    // Inline function 'korlibs.korge.text.MsdfRender.createProgram.<anonymous>' call
    var tmp = this_0.w93();
    var tmp_0 = this_0.w93();
    var tmp_1 = this_0.w93();
    var tmp_2 = this_0.w93();
    var median$delegate = this_0.g93(tmp, tmp_0, tmp_1, VOID, tmp_2, MsdfRender$createProgram$lambda).i90(null, getLocalDelegateReference('median', KProperty0, false, function () {
      return THROW_ISE();
    }));
    var tmp_3 = Companion_getInstance_8().vad_1;
    var tmp_4 = get_BB_MAX_TEXTURES() - 1 | 0;
    this_0.m93(tmp_3, 0, tmp_4, MsdfRender$createProgram$lambda_0(this_0));
    var d = this_0.a95(this_0.t92());
    var alpha = this_0.b95(this_0.t92());
    this_0.f92(this_0.i92(), this_0.g95(this_0.i92(), this_0.m92([this_0.l92(0.5)])));
    if (msdf) {
      this_0.f92(d, this_0.k93(createProgram$_anonymous_$lambda$0_hhid5m(median$delegate), this_0.o92(this_0.i92(), 'r'), this_0.o92(this_0.i92(), 'g'), this_0.o92(this_0.i92(), 'b')));
    } else {
      this_0.f92(d, this_0.o92(this_0.i92(), sdf));
    }
    this_0.f92(alpha, this_0.i93(SDFShaders_getInstance().fak(), d));
    if (inverted) {
      this_0.f92(alpha, this_0.g95(this_0.l92(1.0), alpha));
    }
    this_0.f92(this_0.i92(), this_0.j92(this_0.g92(), alpha));
    var tmp294_fragment = FragmentShader(this_0.n92());
    return tmp292_$this.c9r(VOID, tmp294_fragment, tmp293_name);
  }
  xce(msdf, sdf, inverted, $super) {
    sdf = sdf === VOID ? 'a' : sdf;
    inverted = inverted === VOID ? false : inverted;
    return $super === VOID ? this.yce(msdf, sdf, inverted) : $super.yce.call(this, msdf, sdf, inverted);
  }
}
class TextSnapshot {
  constructor(text, selectionRange) {
    this.ncg_1 = text;
    this.ocg_1 = selectionRange;
  }
  pcg(out) {
    setTextNoSnapshot$default(out, this.ncg_1);
    out.qcg(this.ocg_1);
  }
  toString() {
    return 'TextSnapshot(text=' + this.ncg_1 + ', selectionRange=' + this.ocg_1.toString() + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.ncg_1);
    result = imul(result, 31) + this.ocg_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof TextSnapshot))
      return false;
    var tmp0_other_with_cast = other instanceof TextSnapshot ? other : THROW_CCE();
    if (!(this.ncg_1 === tmp0_other_with_cast.ncg_1))
      return false;
    if (!this.ocg_1.equals(tmp0_other_with_cast.ocg_1))
      return false;
    return true;
  }
}
class TextEditController$slambda {
  constructor(this$0) {
    this.qck_1 = this$0;
  }
  sbl(it, $completion) {
    if (!get_focused(this.qck_1))
      return Unit_instance;
    if (it.l8c_1)
      return Unit_instance;
    // Inline function 'kotlin.code' call
    var this_0 = it.h8c_1;
    var code = Char__toInt_impl_vasixd(this_0);
    switch (code) {
      case 8:
      case 127:
        break;
      case 9:
      case 10:
      case 13:
        if (code === 10 || code === 13) {
          this.qck_1.icf_1.x1x(this.qck_1);
        }

        break;
      case 27:
        this.qck_1.hcf_1.x1x(this.qck_1);
        break;
      default:
        this.qck_1.dcg(it.o8c());
        break;
    }
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class TextEditController$slambda_0 {
  constructor(this$0) {
    this.zce_1 = this$0;
  }
  sbl(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_22(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIFocusable {}
class TextEditController {
  constructor(textView, caretContainer, eventHandler, bg) {
    caretContainer = caretContainer === VOID ? textView : caretContainer;
    eventHandler = eventHandler === VOID ? textView : eventHandler;
    bg = bg === VOID ? null : bg;
    this.acf_1 = new SoftKeyboardConfig();
    this.bcf_1 = textView;
    this.ccf_1 = caretContainer;
    this.dcf_1 = eventHandler;
    this.ecf_1 = bg;
    set_focusable(this.bcf_1, this);
    this.fcf_1 = this.bcf_1.o2f();
    this.gcf_1 = new CancellableGroup();
    this.hcf_1 = new Signal();
    this.icf_1 = new Signal();
    this.jcf_1 = new Signal();
    this.kcf_1 = new Signal();
    this.lcf_1 = new Signal();
    this.mcf_1 = new Signal();
    this.ncf_1 = new Signal();
    this.ocf_1 = new Signal();
    var tmp = this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.debug.debugVertexView' call
    var this_0 = this.ccf_1;
    // Inline function 'kotlin.collections.listOf' call
    var pointsList = emptyList();
    var color = Colors_getInstance().g4r_1;
    var type = Companion_getInstance_18().m8u_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = DebugVertexView.mcp(pointsList, color, type);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.debug.debugVertexView.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.text.TextEditController.caret.<anonymous>' call
    this_2.jcb(Companion_getInstance_17().ba6_1);
    this_2.car(false);
    tmp.pcf_1 = this_2;
    var tmp_0 = this;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_37();
    tmp_0.qcf_1 = Margin.u2l(numberToDouble(3.0), numberToDouble(2.0), numberToDouble(2.0), numberToDouble(2.0));
    this.gcf_1.ku(onNewAttachDetach(this.bcf_1, TextEditController$lambda));
    this.ocf_1.x1x(this);
    this.rcf_1 = new HistoryStack();
    this.scf_1 = color$factory(this.bcf_1);
    this.tcf_1 = this.fcf_1.length;
    this.ucf_1 = this.tcf_1;
    this.vcf_1 = 0;
    var tmp_1 = this;
    tmp_1.wcf_1 = TextEditController$acceptTextChange$lambda;
    set_cursor(this.dcf_1, Cursor_TEXT_getInstance());
    var tmp_2 = get_timers(this.dcf_1);
    // Inline function 'korlibs.time.seconds' call
    var tmp_3 = toDuration(0.5, DurationUnit_SECONDS_getInstance());
    this.gcf_1.ku(tmp_2.scp(tmp_3, TextEditController$lambda_0(this)));
    // Inline function 'korlibs.korge.input.newKeys' call
    var this_3 = this.dcf_1;
    // Inline function 'kotlin.also' call
    var this_4 = new KeysEvents(this_3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.newKeys.<anonymous>' call
    // Inline function 'korlibs.korge.text.TextEditController.<anonymous>' call
    this_4.cbm(TextEditController$slambda_1(this));
    this_4.xbl(TextEditController$slambda_2(this));
    this.gcf_1.ku(this_4);
    // Inline function 'korlibs.korge.input.newMouse' call
    var this_5 = this.dcf_1;
    // Inline function 'kotlin.also' call
    var this_6 = new MouseEvents(this_5);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.newMouse.<anonymous>' call
    // Inline function 'korlibs.korge.text.TextEditController.<anonymous>' call
    var dragging = {_v: false};
    var tmp105_safe_receiver = this.ecf_1;
    if (tmp105_safe_receiver == null)
      null;
    else {
      tmp105_safe_receiver.ccn_1 = false;
    }
    this.ncf_1.x1x(this);
    this_6.abi_1.w1x(TextEditController$lambda_1(this));
    this_6.bbi_1.w1x(TextEditController$lambda_2(this));
    this_6.ybh_1.w1x(TextEditController$lambda_3(this, dragging));
    this_6.cbi_1.w1x(TextEditController$lambda_4(this, dragging));
    this_6.dbi_1.w1x(TextEditController$lambda_5(dragging, this));
    this_6.lbi_1.w1x(TextEditController$lambda_6(this, dragging));
    this_6.gbi_1.w1x(TextEditController$lambda_7(dragging, this));
    doubleClick(this_6, TextEditController$lambda_8(this));
    this.gcf_1.ku(this_6);
    this.tcp();
  }
  pbj() {
    return this.bcf_1.pbj();
  }
  ucp(_this__u8e3s4) {
    return this.bcf_1;
  }
  bcg(value) {
    var snapshot = setTextNoSnapshot$default(this, value);
    if (!(snapshot == null)) {
      this.rcf_1.q36(snapshot);
    }
  }
  o2f() {
    return this.bcf_1.o2f();
  }
  xcf() {
    var tmp93_safe_receiver = this.rcf_1.r36();
    if (tmp93_safe_receiver == null)
      null;
    else {
      tmp93_safe_receiver.pcg(this);
    }
  }
  ycf() {
    var tmp94_safe_receiver = this.rcf_1.s36();
    if (tmp94_safe_receiver == null)
      null;
    else {
      tmp94_safe_receiver.pcg(this);
    }
  }
  dcg(substr) {
    var tmp = withoutRange(this.o2f(), this.acg());
    // Inline function 'kotlin.math.min' call
    var a = this.icg();
    var b = this.mck();
    var tmp$ret$0 = Math.min(a, b);
    this.bcg(withInsertion(tmp, tmp$ret$0, substr));
    this.hcg(this.gcg() + substr.length | 0);
  }
  vcp(value) {
    this.bcf_1.wcp(value);
    this.tcp();
  }
  p5w() {
    var tmp = this.bcf_1.hcj_1;
    return isInterface(tmp, Font) ? tmp : THROW_CCE();
  }
  xcp(value) {
    this.bcf_1.xcp(value);
    this.tcp();
  }
  ycp() {
    return this.bcf_1.icj_1;
  }
  ock(value) {
    this.tcf_1 = clampIndex(this, value);
    this.tcp();
  }
  icg() {
    return this.tcf_1;
  }
  fcn(value) {
    this.ucf_1 = clampIndex(this, value);
    this.tcp();
  }
  mck() {
    return this.ucf_1;
  }
  hcg(value) {
    var value_0 = clampIndex(this, value);
    this.tcf_1 = value_0;
    this.ucf_1 = value_0;
    this.tcp();
  }
  gcg() {
    return this.icg();
  }
  nck(start, end) {
    this.tcf_1 = clampIndex(this, start);
    this.ucf_1 = clampIndex(this, end);
    this.tcp();
  }
  qcg(range) {
    this.nck(range.k2_1, range.l2_1 + 1 | 0);
  }
  ecg() {
    this.nck(0, this.o2f().length);
  }
  pck() {
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = this.mck() - this.icg() | 0;
    return abs(this_0);
  }
  zcf() {
    // Inline function 'kotlin.text.substring' call
    var this_0 = this.o2f();
    // Inline function 'kotlin.math.min' call
    var a = this.icg();
    var b = this.mck();
    var startIndex = Math.min(a, b);
    // Inline function 'kotlin.math.max' call
    var a_0 = this.icg();
    var b_0 = this.mck();
    var endIndex = Math.max(a_0, b_0);
    // Inline function 'kotlin.js.asDynamic' call
    return this_0.substring(startIndex, endIndex);
  }
  zcp(value) {
    this.qcg(value);
  }
  acg() {
    // Inline function 'kotlin.math.min' call
    var a = this.icg();
    var b = this.mck();
    var tmp = Math.min(a, b);
    // Inline function 'kotlin.math.max' call
    var a_0 = this.icg();
    var b_0 = this.mck();
    var tmp$ret$1 = Math.max(a_0, b_0);
    return until(tmp, tmp$ret$1);
  }
  acq(index) {
    var glyphPositions = this.bcf_1.bcq().z5r_1;
    if (glyphPositions.a1())
      return Bezier.o3w(Vector2D.i2l(), Vector2D.i2l());
    // Inline function 'kotlin.math.min' call
    var b = glyphPositions.j1() - 1 | 0;
    var tmp$ret$0 = Math.min(index, b);
    var glyph = glyphPositions.i1(tmp$ret$0);
    return index < glyphPositions.j1() ? glyph.s5r() : glyph.t5r();
  }
  ecn(pos) {
    var glyphPositions = this.bcf_1.bcq().z5r_1;
    var index = 0;
    var minDist = Infinity;
    // Inline function 'kotlin.collections.isNotEmpty' call
    if (!glyphPositions.a1()) {
      var inductionVariable = 0;
      var last = glyphPositions.j1();
      if (inductionVariable <= last)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlin.math.min' call
          var a = glyphPositions.j1() - 1 | 0;
          var b = n;
          var tmp$ret$1 = Math.min(a, b);
          var glyph = glyphPositions.i1(tmp$ret$1);
          var dist = glyph.w5r(pos);
          if (minDist > dist) {
            minDist = dist;
            index = n >= (glyphPositions.j1() - 1 | 0) && !(dist === 0.0) && glyph.v5r(pos, false) < glyph.v5r(pos, true) ? n + 1 | 0 : n;
          }
        }
         while (!(n === last));
    }
    return index;
  }
  tcp() {
    var range = this.acg();
    var array = new PointArrayList(range.a1() ? 2 : imul(get_length(range) + 1 | 0, 2));
    if (range.a1()) {
      var last = range.k2_1 >= this.o2f().length;
      var caret = this.acq(range.k2_1);
      var sign = last ? -1.0 : +1.0;
      // Inline function 'korlibs.math.geom.Vector2D.times' call
      var this_0 = caret.n3t(Companion_getInstance_45().f10_1);
      var scale = 2.0 * sign;
      var normal = Vector2D.k2h(this_0.i2h_1 * scale, this_0.j2h_1 * scale);
      var p0 = caret.e3u_1.v34();
      var p1 = caret.e3u_1.w34();
      array.s3e(p0);
      array.s3e(p1);
      // Inline function 'korlibs.math.geom.Vector2D.plus' call
      var tmp$ret$1 = Vector2D.k2h(p0.i2h_1 + normal.i2h_1, p0.j2h_1 + normal.j2h_1);
      array.s3e(tmp$ret$1);
      // Inline function 'korlibs.math.geom.Vector2D.plus' call
      var tmp$ret$2 = Vector2D.k2h(p1.i2h_1 + normal.i2h_1, p1.j2h_1 + normal.j2h_1);
      array.s3e(tmp$ret$2);
    } else {
      var inductionVariable = range.k2_1;
      var last_0 = range.l2_1 + 1 | 0;
      if (inductionVariable <= last_0)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var caret_0 = this.acq(n);
          array.s3e(caret_0.e3u_1.v34());
          array.s3e(caret_0.e3u_1.w34());
        }
         while (!(n === last_0));
    }
    this.pcf_1.m59(Colors_getInstance().g4r_1);
    this.pcf_1.ccq(listOf(array));
    this.pcf_1.car(get_focused(this));
    this.bcf_1.hbh();
  }
  ccg(selection, index) {
    if (selection) {
      this.ock(index);
    } else {
      this.hcg(index);
    }
  }
  dcq(index, direction, word) {
    var dir = get_sign(direction);
    if (word) {
      var sidx = index + dir | 0;
      var idx = sidx;
      while (true) {
        if (!(0 <= idx ? idx <= (charSequenceLength(this.o2f()) - 1 | 0) : false)) {
          return dir < 0 ? idx - dir | 0 : idx;
        }
        if (!isLetterOrDigit(charSequenceGet(this.o2f(), idx))) {
          return dir < 0 ? idx === sidx ? idx : idx - dir | 0 : idx;
        }
        idx = idx + dir | 0;
      }
    }
    return index + dir | 0;
  }
  kcg(index, word) {
    return this.dcq(index, -1, word);
  }
  mcg(index, word) {
    return this.dcq(index, 1, word);
  }
  ecq() {
    return this.vcf_1;
  }
  fcq() {
    return true;
  }
  gcq(value) {
    var tmp95_safe_receiver = this.ecf_1;
    if (tmp95_safe_receiver == null)
      null;
    else {
      tmp95_safe_receiver.bcn_1 = value;
    }
    if (value) {
      this.pcf_1.car(true);
      var tmp96_safe_receiver = this.pbj();
      var tmp97_safe_receiver = tmp96_safe_receiver == null ? null : get_uiFocusManager(tmp96_safe_receiver);
      if (tmp97_safe_receiver == null)
        null;
      else {
        tmp97_safe_receiver.kcq(true, this);
      }
    } else {
      this.pcf_1.car(false);
      var tmp100_safe_receiver = this.pbj();
      var tmp = tmp100_safe_receiver == null ? null : get_uiFocusedView(tmp100_safe_receiver);
      if (!(!(tmp == null) ? isInterface(tmp, ISoftKeyboardConfig) : false)) {
        var tmp98_safe_receiver = this.pbj();
        var tmp99_safe_receiver = tmp98_safe_receiver == null ? null : get_uiFocusManager(tmp98_safe_receiver);
        if (tmp99_safe_receiver == null)
          null;
        else {
          tmp99_safe_receiver.kcq(false, null);
        }
      }
    }
    if (value) {
      this.kcf_1.x1x(this);
    } else {
      this.lcf_1.x1x(this);
    }
  }
  jcg(_this__u8e3s4) {
    return Companion_getInstance_46().uw().rw() ? _this__u8e3s4.k8c_1 : _this__u8e3s4.j8c_1;
  }
  lcg(_this__u8e3s4) {
    return _this__u8e3s4.l8c_1 && Companion_getInstance_46().uw().rw();
  }
  fcg(_this__u8e3s4) {
    return _this__u8e3s4.p8c();
  }
  u6() {
    set_cursor(this.bcf_1, null);
    cancel_0(this.gcf_1);
    set_focusable(this.bcf_1, null);
  }
}
class TimerComponents {
  constructor(view) {
    this.ncp_1 = view;
    var tmp = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.ocp_1 = ArrayList.m1();
    this.pcp_1 = IntArrayList.f31();
    this.qcp_1 = IntArrayList.f31();
    this.rcp_1 = null;
  }
  ncq(time, $completion) {
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
    cancellable.u19();
    // Inline function 'korlibs.korge.time.TimerComponents.wait.<anonymous>' call
    this.ocq(time, TimerComponents$wait$lambda(cancellable));
    return cancellable.g17();
  }
  ocq(time, callback) {
    return this.pcq(get_fast(time), callback);
  }
  scp(time, callback) {
    return this.qcq(get_fast(time), callback);
  }
  pcq(time, callback) {
    return _interval(this, time, false, callback);
  }
  qcq(time, callback) {
    return _interval(this, time, true, callback);
  }
}
class TimerRef {
  constructor(index) {
    this.mcq_1 = index;
  }
  toString() {
    return TimerRef__toString_impl_8hh2j7(this.mcq_1);
  }
  hashCode() {
    return TimerRef__hashCode_impl_d9ou9o(this.mcq_1);
  }
  equals(other) {
    return TimerRef__equals_impl_2fvpr4(this.mcq_1, other);
  }
}
class TweenComponent {
  constructor(view, vs, fastTime, easing, callback, c, fastWaitTime, autoInvalidate) {
    fastTime = fastTime === VOID ? Companion_instance_1.n1w() : fastTime;
    easing = easing === VOID ? get_DEFAULT_EASING() : easing;
    fastWaitTime = fastWaitTime === VOID ? Companion_instance_1.n1w() : fastWaitTime;
    autoInvalidate = autoInvalidate === VOID ? true : autoInvalidate;
    this.rcq_1 = view;
    this.scq_1 = vs;
    this.tcq_1 = fastTime;
    this.ucq_1 = easing;
    this.vcq_1 = callback;
    this.wcq_1 = c;
    this.xcq_1 = fastWaitTime;
    this.ycq_1 = autoInvalidate;
    this.zcq_1 = Companion_instance_1.m1w();
    var tmp = this;
    var tmp_0;
    if (!equals(this.tcq_1, Companion_instance_1.n1w())) {
      tmp_0 = this.tcq_1;
    } else {
      // Inline function 'kotlin.collections.map' call
      var this_0 = this.scq_1;
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var item = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.tween.TweenComponent.hrtime.<anonymous>' call
        var tmp$ret$0 = _FastDuration___get_nanoseconds__impl__pxizcb(item.zal_1);
        destination.f(tmp$ret$0);
      }
      var tmp0_elvis_lhs = maxOrNull(destination);
      tmp_0 = get_fastNanoseconds(tmp0_elvis_lhs == null ? 0.0 : tmp0_elvis_lhs);
    }
    tmp.acr_1 = tmp_0;
    this.bcr_1 = false;
    this.ccr_1 = false;
    this.dcr_1 = false;
    var tmp_1 = this;
    var tmp_2 = Companion_instance_9;
    tmp_1.ecr_1 = this.rcq_1.k8a(tmp_2, TweenComponent$updater$lambda(this));
    var tmp110_safe_receiver = this.wcq_1;
    if (tmp110_safe_receiver == null)
      null;
    else {
      tmp110_safe_receiver.c18(TweenComponent$lambda(this));
    }
    _update(this, Companion_instance_1.m1w());
  }
  lcr() {
    return get_slow(this.xcq_1);
  }
  mcr() {
    if (this.dcr_1)
      return Unit_instance;
    this.dcr_1 = true;
    var tmp111_safe_receiver = this.wcq_1;
    if (tmp111_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.coroutines.resume' call
      // Inline function 'kotlin.Companion.success' call
      var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
      tmp111_safe_receiver.id(tmp$ret$0);
    }
  }
  kcr() {
    if (this.ccr_1)
      return Unit_instance;
    this.ccr_1 = true;
    var tmp112_safe_receiver = this.ecr_1;
    if (tmp112_safe_receiver == null)
      null;
    else {
      tmp112_safe_receiver.u6();
    }
    this.ecr_1 = null;
    this.mcr();
  }
  fcr(elapsed) {
    if (equals(elapsed, Companion_instance_1.m1w())) {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var this_0 = this.scq_1;
      var n = 0;
      while (n < this_0.j1()) {
        // Inline function 'korlibs.korge.tween.TweenComponent.setTo.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        this_0.i1(_unary__edvuaz).e28();
      }
    }
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_1 = this.scq_1;
    var n_0 = 0;
    while (n_0 < this_1.j1()) {
      // Inline function 'korlibs.korge.tween.TweenComponent.setTo.<anonymous>' call
      var _unary__edvuaz_0 = n_0;
      n_0 = _unary__edvuaz_0 + 1 | 0;
      var v = this_1.i1(_unary__edvuaz_0);
      var durationInTween = equals(v.xal_1, Companion_instance_1.n1w()) ? FastDuration__minus_impl_evs237(this.acr_1, v.wal_1) : v.xal_1;
      var elapsedInTween = get_fastMilliseconds(clamp_1(_FastDuration___get_fastMilliseconds__impl__pe68va(FastDuration__minus_impl_evs237(elapsed, v.wal_1)), 0.0, _FastDuration___get_fastMilliseconds__impl__pe68va(durationInTween)));
      var ratioInTween = FastDuration__compareTo_impl_4fhnj7_0(durationInTween, Companion_getInstance().ik_1) <= 0 || FastDuration__compareTo_impl_4fhnj7(elapsedInTween, durationInTween) >= 0 ? 1.0 : FastDuration__div_impl_rlslck(elapsedInTween, durationInTween);
      var easedRatioInTween = this.ucq_1.yz(ratioInTween);
      v.cam(toRatio(easedRatioInTween));
    }
  }
  toString() {
    return 'TweenComponent(' + toString(this.rcq_1) + ')';
  }
}
class tween$slambda {
  constructor($this_tween, $tc, $vs, $time, $easing, $callback, $waitTime, $autoInvalidate) {
    this.ncr_1 = $this_tween;
    this.ocr_1 = $tc;
    this.pcr_1 = $vs;
    this.qcr_1 = $time;
    this.rcr_1 = $easing;
    this.scr_1 = $callback;
    this.tcr_1 = $waitTime;
    this.ucr_1 = $autoInvalidate;
  }
  o1s($this$withTimeoutNullable, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_23(this, $this$withTimeoutNullable, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class tweenAsync$slambda {
  constructor($this_tweenAsync, $vs, $time, $easing, $waitTime, $callback) {
    this.vcr_1 = $this_tweenAsync;
    this.wcr_1 = $vs;
    this.xcr_1 = $time;
    this.ycr_1 = $easing;
    this.zcr_1 = $waitTime;
    this.acs_1 = $callback;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_24(this, $completion), $completion);
  }
}
class Companion_5 {
  constructor() {
    Companion_instance_20 = this;
    this.fcs_1 = get_UI_DEFAULT_SIZE();
  }
}
class UIButton$slambda {
  constructor($$this$mouse, this$0) {
    this.id2_1 = $$this$mouse;
    this.jd2_1 = this$0;
  }
  tbo(it, $completion) {
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    this.id2_1;
    this.jd2_1.kd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIButton$lambda$slambda {
  constructor($handler, $it) {
    this.bcs_1 = $handler;
    this.ccs_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_25(this, $completion), $completion);
  }
}
class UIButton$slambda_0 {
  constructor($$this$mouse, this$0) {
    this.ld2_1 = $$this$mouse;
    this.md2_1 = this$0;
  }
  tbo(it, $completion) {
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    this.ld2_1;
    this.md2_1.nd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIButton$lambda$slambda_0 {
  constructor($handler, $it) {
    this.dcs_1 = $handler;
    this.ecs_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_26(this, $completion), $completion);
  }
}
class UIButton$slambda_1 {
  constructor(this$0) {
    this.od2_1 = this$0;
  }
  sbl(it, $completion) {
    if (get_focused(this.od2_1)) {
      this.od2_1.kcz(0.5, 0.5);
    }
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIButton$slambda_2 {
  constructor(this$0, $$this$keys) {
    this.pd2_1 = this$0;
    this.qd2_1 = $$this$keys;
  }
  sbl(it, $completion) {
    if (this.pd2_1.pcv_1) {
      this.pd2_1.hd2();
      this.pd2_1.rd2(this.qd2_1.qbl());
    }
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIFocusableView extends UIView {
  static td2(size, cache) {
    size = size === VOID ? Size2D.f2j(90, 32) : size;
    cache = cache === VOID ? false : cache;
    var $this = this.wc6(size, cache);
    $this.fd2_1 = 0;
    $this.gd2_1 = true;
    return $this;
  }
  ucp(_this__u8e3s4) {
    return this;
  }
  ecq() {
    return this.fd2_1;
  }
  od9(_set____db54di) {
    this.gd2_1 = _set____db54di;
  }
  fcq() {
    return this.gd2_1;
  }
  gcq(value) {
  }
}
class UIButton extends UIFocusableView {
  static sd2(size, text, icon, richText) {
    Companion_getInstance_53();
    size = size === VOID ? Companion_getInstance_53().fcs_1 : size;
    text = text === VOID ? '' : text;
    icon = icon === VOID ? null : icon;
    richText = richText === VOID ? null : richText;
    var $this = this.td2(size);
    $this.ccv_1 = false;
    $this.dcv_1 = Companion_getInstance_45().j10_1;
    $this.ecv_1 = 6.0;
    $this.fcv_1 = MaterialColors_getInstance().h5c_1;
    $this.gcv_1 = MaterialColors_getInstance().i5c_1;
    $this.hcv_1 = MaterialColors_getInstance().j5c_1;
    $this.icv_1 = Colors_getInstance().r59('#777777ff');
    $this.jcv_1 = true;
    var tmp = $this;
    // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
    // Inline function 'kotlin.apply' call
    var this_0 = addTo(UIMaterialLayer.ud2(size), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIButton.background.<anonymous>' call
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp$ret$0 = new RectCorners(numberToDouble(5.0), numberToDouble(5.0), numberToDouble(5.0), numberToDouble(5.0));
    this_0.dcz(tmp$ret$0);
    tmp.kcv_1 = this_0;
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.view.textBlock' call
    var text_0 = richText == null ? RichTextData.o6x(text, get_DefaultTtfFontAsBitmap()) : richText;
    var align = Companion_getInstance_4().u5s_1;
    var size_0 = Size2D.f2j(100, 100);
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = TextBlock.gd6(text_0, align, size_0);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.textBlock.<anonymous>' call
    tmp_0.lcv_1 = addTo(this_1, $this);
    $this.mcv_1 = align$factory($this.lcv_1);
    var tmp_1 = $this;
    // Inline function 'korlibs.korge.view.image' call
    // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
    var texture = get_Bitmaps_transparent();
    var anchor = Companion_getInstance_32().p2g_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_2 = Image.rd8(texture, anchor);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.image.<anonymous>' call
    tmp_1.ncv_1 = addTo(this_2, $this);
    $this.ocv_1 = false;
    $this.pcv_1 = false;
    var tmp_2 = $this;
    var tmp114_defaultEasing = Companion_getInstance_44().b10_1;
    tmp_2.qcv_1 = animator($this, VOID, VOID, tmp114_defaultEasing, true);
    var tmp_3 = $this;
    var tmp115_defaultEasing = Companion_getInstance_44().b10_1;
    tmp_3.rcv_1 = animator($this, VOID, VOID, tmp115_defaultEasing, true);
    $this.scv_1 = text$factory($this.lcv_1);
    $this.tcv_1 = icon;
    $this.ucv_1 = new Signal();
    singleTouch($this, VOID, VOID, UIButton$lambda($this));
    // Inline function 'korlibs.korge.input.mouse' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var $this$mouse = get_mouse($this);
    // Inline function 'korlibs.korge.input.MouseEvents.onOver' call
    var handler = UIButton$slambda_3($this$mouse, $this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_4 = over$factory().get($this$mouse);
    tmp_4.v1x(UIButton$lambda_0($this$mouse, handler));
    // Inline function 'korlibs.korge.input.MouseEvents.onOut' call
    var handler_0 = UIButton$slambda_4($this$mouse, $this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_5 = out$factory().get($this$mouse);
    tmp_5.v1x(UIButton$lambda_1($this$mouse, handler_0));
    set_cursor($this, Cursor_HAND_getInstance());
    setInitialState($this);
    $this.vcv_1 = false;
    $this.sd8(false, false, VOID, VOID, true);
    $this.wcv_1 = 0.0;
    // Inline function 'korlibs.korge.input.keys' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIButton.<anonymous>' call
    var $this$keys = get_keys($this);
    var tmp_6 = [Key_SPACE_getInstance(), Companion_getInstance_19().a8i_1];
    $this$keys.zbl(tmp_6, UIButton$slambda_5($this));
    var tmp_7 = [Key_SPACE_getInstance(), Companion_getInstance_19().a8i_1];
    $this$keys.bbm(tmp_7, UIButton$slambda_6($this, $this$keys));
    return $this;
  }
  td8(value) {
    this.dcv_1 = value;
    this.ecv_1 = NaN;
    setInitialState(this);
  }
  ud8(value) {
    this.ecv_1 = value;
    this.dcv_1 = Companion_getInstance_45().j10_1;
    setInitialState(this);
  }
  xcv() {
    return !isNaN_0(this.ecv_1) ? this.ecv_1 : _Ratio___get_value__impl__57cli6(this.dcv_1) * _get_halfSide__37ya73(this);
  }
  vd8(value) {
    this.fcv_1 = value;
    this.kcv_1.wd8(value);
  }
  xd8(value) {
    this.gcv_1 = value;
    this.sd8(VOID, VOID, VOID, VOID, true);
  }
  yd8(value) {
    this.hcv_1 = value;
    this.sd8(VOID, VOID, VOID, VOID, true);
  }
  zd8(value) {
    this.icv_1 = value;
    this.sd8(VOID, VOID, VOID, VOID, true);
  }
  ad9(value) {
    this.jcv_1 = value;
    setInitialState(this);
  }
  jcz() {
    return super.jcz();
  }
  bd9(_set____db54di) {
    var this_0 = this.mcv_1;
    textAlignment$factory_1();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  cd9() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.mcv_1;
    textAlignment$factory_2();
    return this_0.get();
  }
  dd9(_set____db54di) {
    var this_0 = this.scv_1;
    richText$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  ed9() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.scv_1;
    richText$factory_0();
    return this_0.get();
  }
  bcg(value) {
    this.dd9(this.ed9().s6w(value));
  }
  fd9(value) {
    if (!(this.gd9() === value)) {
      this.dd9(this.ed9().r6w(this.ed9().v6w_1.c6x(VOID, VOID, VOID, VOID, VOID, value)));
    }
  }
  gd9() {
    var tmp0_elvis_lhs = this.ed9().v6w_1.h6w_1;
    var tmp;
    var tmp_0 = tmp0_elvis_lhs;
    if ((tmp_0 == null ? null : new RGBA(tmp_0)) == null) {
      tmp = Colors_getInstance().g4r_1;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  yc6() {
    setInitialState(this);
  }
  kd2() {
    if (this.ocv_1)
      return Unit_instance;
    this.ocv_1 = true;
    this.sd8(VOID, true);
  }
  nd2() {
    if (!this.ocv_1)
      return Unit_instance;
    this.ocv_1 = false;
    this.sd8(VOID, false);
  }
  hd9(value) {
    if (this.pcv_1 === value)
      return Unit_instance;
    this.pcv_1 = value;
  }
  kcz(x, y) {
    return this.id9(Vector2D.k2h(x, y));
  }
  jd9(x, y, $super) {
    x = x === VOID ? 0.5 : x;
    y = y === VOID ? 0.5 : y;
    var tmp;
    if ($super === VOID) {
      this.kcz(x, y);
      tmp = Unit_instance;
    } else {
      tmp = $super.kcz.call(this, x, y);
    }
    return tmp;
  }
  id9(p) {
    if (this.pcv_1)
      return Unit_instance;
    this.pcv_1 = true;
    this.sd8(true, VOID, VOID, p);
  }
  hd2() {
    if (!this.pcv_1)
      return Unit_instance;
    this.pcv_1 = false;
    this.sd8(false);
  }
  rd2(views) {
    get_touch(this).qbr(views, this.gcd(Vector2D.k2h(this.j2y() * 0.5, this.k2y() * 0.5)));
  }
  kd9(down, over, enable, pos, immediate) {
    if (this.jcz() && !(down == null)) {
      if (down) {
        this.kcv_1.md9(pos);
      } else {
        this.kcv_1.ld9();
      }
    }
    var bgColor = enable === false || !this.jcz() ? this.icv_1 : over === true ? this.gcv_1 : this.vcv_1 ? this.hcv_1 : this.fcv_1;
    if (immediate) {
      this.kcv_1.wd8(bgColor);
    } else {
      // Inline function 'korlibs.korge.tween.get' call
      var this_0 = bgColor$factory(this.kcv_1);
      var tmp = this_0.get();
      var tmp_0 = [new V2(this_0, tmp, new RGBA(bgColor), _interpolateColor$ref(), false)];
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$1 = toDuration(0.25, DurationUnit_SECONDS_getInstance());
      tween(this.qcv_1, tmp_0, tmp$ret$1);
    }
  }
  sd8(down, over, enable, pos, immediate, $super) {
    down = down === VOID ? null : down;
    over = over === VOID ? null : over;
    enable = enable === VOID ? null : enable;
    pos = pos === VOID ? Companion_getInstance_34().a2i_1 : pos;
    immediate = immediate === VOID ? false : immediate;
    var tmp;
    if ($super === VOID) {
      this.kd9(down, over, enable, pos, immediate);
      tmp = Unit_instance;
    } else {
      tmp = $super.kd9.call(this, down, over, enable, pos, immediate);
    }
    return tmp;
  }
  nd9(value) {
    this.vcv_1 = value;
    this.sd8(VOID, false, VOID, VOID, true);
  }
  ic7() {
    super.ic7();
    this.sd8(VOID, VOID, VOID, VOID, true);
  }
  gcq(value) {
    this.sd8(VOID, value);
  }
}
class clicked$slambda {
  constructor($block, $this_clicked) {
    this.pd9_1 = $block;
    this.qd9_1 = $this_clicked;
  }
  tbo(it, $completion) {
    this.pd9_1(this.qd9_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class clicked$lambda$slambda {
  constructor($handler, $it) {
    this.rd9_1 = $handler;
    this.sd9_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_27(this, $completion), $completion);
  }
}
class UIButtonToggleableGroup$add$slambda {
  constructor(this$0, $button) {
    this.xd9_1 = this$0;
    this.yd9_1 = $button;
  }
  tbo(it, $completion) {
    this.xd9_1.ada(this.yd9_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIButtonToggleableGroup$add$lambda$slambda {
  constructor($handler, $it) {
    this.vd9_1 = $handler;
    this.wd9_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_28(this, $completion), $completion);
  }
}
class UIButtonToggleableGroup {
  constructor() {
    this.zd9_1 = null;
  }
  ada(value) {
    if (!(this.zd9_1 == null)) {
      var tmp116_safe_receiver = this.zd9_1;
      if (tmp116_safe_receiver == null)
        null;
      else {
        tmp116_safe_receiver.ccv_1 = false;
      }
    }
    this.zd9_1 = value;
    var tmp117_safe_receiver = this.zd9_1;
    if (tmp117_safe_receiver == null)
      null;
    else {
      tmp117_safe_receiver.ccv_1 = true;
    }
  }
  bda(button, pressed) {
    if (pressed) {
      this.ada(button);
    }
    // Inline function 'korlibs.korge.input.onClick' call
    var handler = UIButtonToggleableGroup$add$slambda_0(this, button);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop = click$factory_0();
    var tmp73_safe_receiver = button == null ? null : get_mouse(button);
    if (tmp73_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp = prop.get(tmp73_safe_receiver);
      tmp.v1x(UIButtonToggleableGroup$add$lambda(tmp73_safe_receiver, handler));
    }
    return button;
  }
}
class Kind {}
class sam$korlibs_korge_view_ViewRenderer$0 {
  constructor(function_0) {
    this.zdd_1 = function_0;
  }
  ade(_this__u8e3s4) {
    return this.zdd_1(_this__u8e3s4);
  }
}
class UIBaseCheckBox$slambda {
  constructor(this$0) {
    this.kdg_1 = this$0;
  }
  tbo(it, $completion) {
    _set_over__9rx6v7(this.kdg_1, true);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIBaseCheckBox$lambda$slambda {
  constructor($handler, $it) {
    this.cda_1 = $handler;
    this.dda_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_29(this, $completion), $completion);
  }
}
class UIBaseCheckBox$slambda_0 {
  constructor(this$0) {
    this.ldg_1 = this$0;
  }
  tbo(it, $completion) {
    _set_over__9rx6v7(this.ldg_1, false);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIBaseCheckBox$lambda$slambda_0 {
  constructor($handler, $it) {
    this.eda_1 = $handler;
    this.fda_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_30(this, $completion), $completion);
  }
}
class UIBaseCheckBox$slambda_1 {
  constructor(this$0) {
    this.mdg_1 = this$0;
  }
  tbo(it, $completion) {
    _set_pressing__m9uj20(this.mdg_1, true);
    this.mdg_1.udd_1.qdg(Vector2D.k2h(0.5, 0.5));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIBaseCheckBox$lambda$slambda_1 {
  constructor($handler, $it) {
    this.gda_1 = $handler;
    this.hda_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_31(this, $completion), $completion);
  }
}
class UIBaseCheckBox$slambda_2 {
  constructor(this$0) {
    this.rdg_1 = this$0;
  }
  tbo(it, $completion) {
    _set_pressing__m9uj20(this.rdg_1, false);
    this.rdg_1.udd_1.ld9();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIBaseCheckBox$lambda$slambda_2 {
  constructor($handler, $it) {
    this.ida_1 = $handler;
    this.jda_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_32(this, $completion), $completion);
  }
}
class UIBaseCheckBox$slambda_3 {
  constructor(this$0) {
    this.sdg_1 = this$0;
  }
  tbo(it, $completion) {
    if (!it.vbk().db8_1) {
      this.sdg_1.tdg();
    }
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIBaseCheckBox$lambda$slambda_3 {
  constructor($handler, $it) {
    this.kda_1 = $handler;
    this.lda_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_33(this, $completion), $completion);
  }
}
class UIBaseCheckBox$slambda_4 {
  constructor(this$0) {
    this.udg_1 = this$0;
  }
  sbl(it, $completion) {
    if (get_focused(this.udg_1)) {
      this.udg_1.tdg();
    }
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIBaseCheckBox extends UIFocusableView {
  static vdg(size, checked, text, kind) {
    size = size === VOID ? get_UI_DEFAULT_SIZE() : size;
    checked = checked === VOID ? false : checked;
    text = text === VOID ? 'CheckBox' : text;
    var $this = this.td2(size);
    $this.idd_1 = text;
    $this.jdd_1 = kind;
    get_simpleAnimator($this).mbg_1 = true;
    $this.kdd_1 = new Signal();
    $this.ldd_1 = checked;
    var tmp = $this;
    // Inline function 'korlibs.korge.view.solidRect' call
    var color = Colors_getInstance().l4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = SolidRect.ddj(size, color);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp.mdd_1 = addTo(this_0, $this);
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.view.renderableView' call
    var viewRenderer = UIBaseCheckBox$canvas$lambda($this);
    var size_0 = Size2D.f2j(128, 24);
    tmp_0.ndd_1 = addTo(RenderableView.edj(size_0, new sam$korlibs_korge_view_ViewRenderer$0(viewRenderer)), $this);
    var tmp_1 = $this;
    // Inline function 'korlibs.korge.view.textBlock' call
    var text_0 = RichTextData.o6x($this.idd_1);
    var align = Companion_getInstance_4().h5s_1;
    var size_1 = Size2D.f2j(100, 100);
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = TextBlock.gd6(text_0, align, size_1);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.textBlock.<anonymous>' call
    tmp_1.odd_1 = addTo(this_1, $this);
    $this.pdd_1 = checked ? 1.0 : 0.0;
    $this.qdd_1 = 0.0;
    var tmp_2 = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe = UIBaseCheckBox$over$delegate$lambda($this);
    tmp_2.rdd_1 = new UIObservable(false, observe);
    var tmp_3 = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe_0 = UIBaseCheckBox$pressing$delegate$lambda($this);
    tmp_3.sdd_1 = new UIObservable(false, observe_0);
    $this.tdd_1 = Companion_getInstance_38().l2m();
    $this.udd_1 = new Highlights($this);
    // Inline function 'korlibs.korge.input.mouse' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var $this$mouse = get_mouse($this);
    // Inline function 'korlibs.korge.input.MouseEvents.onOver' call
    var handler = UIBaseCheckBox$slambda_5($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_4 = over$factory_0().get($this$mouse);
    tmp_4.v1x(UIBaseCheckBox$lambda($this$mouse, handler));
    // Inline function 'korlibs.korge.input.MouseEvents.onOut' call
    var handler_0 = UIBaseCheckBox$slambda_6($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_5 = out$factory_0().get($this$mouse);
    tmp_5.v1x(UIBaseCheckBox$lambda_0($this$mouse, handler_0));
    // Inline function 'korlibs.korge.input.MouseEvents.onDown' call
    var handler_1 = UIBaseCheckBox$slambda_7($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_6 = down$factory_0().get($this$mouse);
    tmp_6.v1x(UIBaseCheckBox$lambda_1($this$mouse, handler_1));
    // Inline function 'korlibs.korge.input.MouseEvents.onUpAnywhere' call
    var handler_2 = UIBaseCheckBox$slambda_8($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_7 = upAnywhere$factory_0().get($this$mouse);
    tmp_7.v1x(UIBaseCheckBox$lambda_2($this$mouse, handler_2));
    // Inline function 'korlibs.korge.input.MouseEvents.onClick' call
    var handler_3 = UIBaseCheckBox$slambda_9($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_8 = click$factory_1().get($this$mouse);
    tmp_8.v1x(UIBaseCheckBox$lambda_3($this$mouse, handler_3));
    $this.vdd_1 = 0.0;
    // Inline function 'korlibs.korge.input.keys' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIBaseCheckBox.<anonymous>' call
    var $this$keys = get_keys($this);
    var tmp_9 = [Key_SPACE_getInstance(), Companion_getInstance_19().a8i_1];
    $this$keys.zbl(tmp_9, UIBaseCheckBox$slambda_10($this));
    return $this;
  }
  fdj() {
    // Inline function 'korlibs.datastructure.fastCastTo' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return this;
  }
  gdj(value) {
    this.ldd_1 = value;
    this.kdd_1.x1x(this.fdj());
    this.cca();
    var tmp = get_simpleAnimator(this);
    // Inline function 'korlibs.korge.tween.get' call
    var this_0 = checkedRatio$factory(this);
    var end = value ? 1.0 : 0.0;
    var tmp_0 = this_0.get();
    var tmp_1 = [new V2(this_0, tmp_0, end, _interpolate$ref(), false)];
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$1 = toDuration(0.1, DurationUnit_SECONDS_getInstance());
    tween(tmp, tmp_1, tmp$ret$1);
  }
  hdj() {
    return this.ldd_1;
  }
  yc6() {
    super.yc6();
    this.ic7();
  }
  ic7() {
    super.ic7();
    var width = this.j2y();
    var height = this.k2y();
    this.odd_1.ud9(RichTextData.a6x(this.odd_1.qd5_1.o2f(), new Style(get_textFont(get_styles(this)), get_textSize(get_styles(this)), VOID, VOID, VOID, get_textColor(get_styles(this)))));
    this.odd_1.td9(Companion_getInstance_4().t5s_1);
    position(this.odd_1, height + 4.0, 0.0);
    size(this.odd_1, width - height - 8.0, height);
    size(this.mdd_1, width, height);
  }
  tdg() {
    this.gdj(!this.hdj());
    set_focused(this, true);
  }
  gcq(value) {
    var tmp = get_simpleAnimator(this);
    // Inline function 'korlibs.korge.tween.get' call
    var this_0 = focusRatio$factory(this);
    // Inline function 'korlibs.math.toInt' call
    var end = value ? 1 : 0;
    var tmp_0 = this_0.get();
    var tmp_1 = [new V2(this_0, tmp_0, end, _interpolate$ref_1(), false)];
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$2 = toDuration(0.2, DurationUnit_SECONDS_getInstance());
    tween(tmp, tmp_1, tmp$ret$2);
  }
}
class Companion_6 extends Kind {
  constructor() {
    Companion_instance_21 = null;
    super();
    Companion_instance_21 = this;
  }
}
class UICheckBox extends UIBaseCheckBox {
  static sdm(size, checked, text) {
    Companion_getInstance_54();
    size = size === VOID ? get_UI_DEFAULT_SIZE() : size;
    checked = checked === VOID ? false : checked;
    text = text === VOID ? 'CheckBox' : text;
    return this.vdg(size, checked, text, Companion_getInstance_54());
  }
}
class UIComboBox$verticalList$o$getItemView$slambda {
  constructor(this$0, $index) {
    this.vdm_1 = this$0;
    this.wdm_1 = $index;
  }
  tbo(it, $completion) {
    this.vdm_1.hdq(this.wdm_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$verticalList$o$getItemView$lambda$slambda {
  constructor($handler, $it) {
    this.tdm_1 = $handler;
    this.udm_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_34(this, $completion), $completion);
  }
}
class Provider {}
function getItemY(index) {
  var tmp0_elvis_lhs = this.qdx();
  return (tmp0_elvis_lhs == null ? 20.0 : tmp0_elvis_lhs) * index;
}
class UIComboBox$verticalList$1 {
  constructor($items, this$0) {
    this.mdx_1 = $items;
    this.ndx_1 = this$0;
    this.kdx_1 = $items.j1();
    this.ldx_1 = this$0.odx();
  }
  pdx() {
    return this.kdx_1;
  }
  qdx() {
    return this.ldx_1;
  }
  tdt(index) {
    return this.ldx_1;
  }
  rdx(index, vlist) {
    var itemText = toString_0(this.mdx_1.i1(index));
    var tmp;
    // Inline function 'kotlin.text.isEmpty' call
    var this_0 = this.ndx_1.ydp_1;
    if (charSequenceLength(this_0) === 0) {
      var tmp118_color = Colors_getInstance().h4r_1;
      var tmp119_font = get_DefaultTtfFontAsBitmap();
      tmp = RichTextData.o6x(itemText, tmp119_font, VOID, VOID, VOID, VOID, tmp118_color);
    } else {
      if (this.ndx_1.sdx(index)) {
        var highlightColor = MaterialColors_getInstance().i5c_1;
        var tmp_0 = Companion_getInstance_5();
        // Inline function 'kotlin.text.replace' call
        var this_1 = htmlspecialchars(itemText);
        var regex = Regex.mi(Companion_getInstance_0().di(this.ndx_1.ydp_1), RegexOption_IGNORE_CASE_getInstance());
        var transform = UIComboBox$verticalList$o$getItemView$lambda(highlightColor);
        var tmp_1 = regex.ti(this_1, transform);
        var tmp120_color = Colors_getInstance().h4r_1;
        var tmp121_font = get_DefaultTtfFontAsBitmap();
        tmp = fromHTML(tmp_0, tmp_1, new Style(tmp121_font, VOID, VOID, VOID, VOID, tmp120_color));
      } else {
        var tmp122_color = Colors_getInstance().l4s_1;
        var tmp123_font = get_DefaultTtfFontAsBitmap();
        tmp = RichTextData.o6x(itemText, tmp123_font, VOID, VOID, VOID, VOID, tmp122_color);
      }
    }
    var richText = tmp;
    // Inline function 'kotlin.apply' call
    var tmp124_size = Size2D.k2t(this.ndx_1.j2y(), this.ndx_1.odx());
    var this_2 = UIButton.sd2(tmp124_size, VOID, VOID, richText);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.<no name provided>.getItemView.<anonymous>' call
    this_2.bd9(Companion_getInstance_4().t5s_1);
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_37();
    var tmp$ret$2 = Margin.u2l(numberToDouble(0.0), numberToDouble(8.0), numberToDouble(0.0), numberToDouble(8.0));
    this_2.lcv_1.tdx(tmp$ret$2);
    this_2.ud8(0.0);
    this_2.vd8(MaterialColors_getInstance().i5i_1);
    this_2.xd8(MaterialColors_getInstance().m5i_1);
    this_2.yd8(MaterialColors_getInstance().y5c_1);
    this_2.nd9(this.ndx_1.udx() === index);
    this_2.ad9(false);
    this_2.od9(false);
    var it = this_2;
    // Inline function 'korlibs.korge.input.onClick' call
    var handler = UIComboBox$verticalList$o$getItemView$slambda_0(this.ndx_1, index);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop = click$factory_3();
    var tmp73_safe_receiver = it == null ? null : get_mouse(it);
    if (tmp73_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_2 = prop.get(tmp73_safe_receiver);
      tmp_2.v1x(UIComboBox$verticalList$o$getItemView$lambda_0(tmp73_safe_receiver, handler));
    }
    return it;
  }
}
class UIComboBox$slambda {
  constructor(this$0) {
    this.vdx_1 = this$0;
  }
  tbo(it, $completion) {
    this.vdx_1.zdp_1.kd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$lambda$slambda {
  constructor($handler, $it) {
    this.idq_1 = $handler;
    this.jdq_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_35(this, $completion), $completion);
  }
}
class UIComboBox$slambda_0 {
  constructor(this$0) {
    this.wdx_1 = this$0;
  }
  tbo(it, $completion) {
    this.wdx_1.zdp_1.nd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$lambda$slambda_0 {
  constructor($handler, $it) {
    this.kdq_1 = $handler;
    this.ldq_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_36(this, $completion), $completion);
  }
}
class UIComboBox$slambda_1 {
  constructor(this$0) {
    this.xdx_1 = this$0;
  }
  tbo(it, $completion) {
    this.xdx_1.zdp_1.jd9();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$lambda$slambda_1 {
  constructor($handler, $it) {
    this.mdq_1 = $handler;
    this.ndq_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_37(this, $completion), $completion);
  }
}
class UIComboBox$slambda_2 {
  constructor(this$0) {
    this.ydx_1 = this$0;
  }
  tbo(it, $completion) {
    this.ydx_1.zdp_1.hd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$lambda$slambda_2 {
  constructor($handler, $it) {
    this.odq_1 = $handler;
    this.pdq_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_38(this, $completion), $completion);
  }
}
class UIComboBox$slambda_3 {
  constructor(this$0) {
    this.zdx_1 = this$0;
  }
  tbo(it, $completion) {
    this.zdx_1.fdq_1 = !this.zdx_1.fdq_1;
    this.zdx_1.ady(false);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$lambda$slambda_3 {
  constructor($handler, $it) {
    this.qdq_1 = $handler;
    this.rdq_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_39(this, $completion), $completion);
  }
}
class UIComboBox$slambda_4 {
  constructor(this$0) {
    this.cdy_1 = this$0;
  }
  sbl(it, $completion) {
    if (!get_focused(this.cdy_1))
      return Unit_instance;
    if (it.f8c_1.equals(Key_BACKSPACE_getInstance()))
      return Unit_instance;
    var tmp130_safe_receiver = firstOrNull(it.o8c());
    var tmp;
    var tmp_0 = tmp130_safe_receiver;
    if ((tmp_0 == null ? null : new Char(tmp_0)) == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.code' call
      tmp = Char__toInt_impl_vasixd(tmp130_safe_receiver);
    }
    var tmp0_elvis_lhs = tmp;
    if ((tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs) < 32)
      return Unit_instance;
    if (!this.cdy_1.gdy()) {
      this.cdy_1.fdy();
    } else {
      this.cdy_1.ddy(this.cdy_1.ydp_1 + it.o8c());
      this.cdy_1.edy(0);
    }
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$slambda_5 {
  constructor(this$0) {
    this.hdy_1 = this$0;
  }
  sbl(it, $completion) {
    if (!get_focused(this.hdy_1))
      return Unit_instance;
    this.hdy_1.ddy(dropLast(this.hdy_1.ydp_1, 1));
    this.hdy_1.edy(0);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$slambda_6 {
  constructor(this$0) {
    this.idy_1 = this$0;
  }
  sbl(it, $completion) {
    if (!get_focused(this.idy_1))
      return Unit_instance;
    if (!this.idy_1.gdy()) {
      this.idy_1.fdy();
    }
    this.idy_1.edy(it.f8c_1.equals(Key_UP_getInstance()) ? -1 : 1);
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$slambda_7 {
  constructor(this$0) {
    this.jdy_1 = this$0;
  }
  sbl(it, $completion) {
    if (!get_focused(this.jdy_1))
      return Unit_instance;
    if (!this.jdy_1.gdy()) {
      this.jdy_1.fdy();
    } else {
      this.jdy_1.hdq(this.jdy_1.udx());
    }
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox$slambda_8 {
  constructor(this$0) {
    this.kdy_1 = this$0;
  }
  sbl(it, $completion) {
    if (get_focused(this.kdy_1)) {
      if (this.kdy_1.gdy()) {
        this.kdy_1.ldy();
      } else {
        blur(this.kdy_1);
      }
    }
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class UIComboBox extends UIFocusableView {
  static mdy(size, selectedIndex, items) {
    size = size === VOID ? get_UI_DEFAULT_SIZE() : size;
    selectedIndex = selectedIndex === VOID ? 0 : selectedIndex;
    var tmp;
    if (items === VOID) {
      // Inline function 'kotlin.collections.listOf' call
      tmp = emptyList();
    } else {
      tmp = items;
    }
    items = tmp;
    var $this = this.td2(size);
    $this.tdp_1 = new Signal();
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe = UIComboBox$selectedIndex$delegate$lambda($this);
    tmp_0.udp_1 = new UIObservable(selectedIndex, observe);
    var tmp_1 = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe_0 = UIComboBox$focusedIndex$delegate$lambda($this);
    tmp_1.vdp_1 = new UIObservable(selectedIndex, observe_0);
    var tmp_2 = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe_1 = UIComboBox$items$delegate$lambda($this);
    tmp_2.wdp_1 = new UIObservable(items, observe_1);
    var tmp_3 = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe_2 = UIComboBox$viewportHeight$delegate$lambda($this);
    tmp_3.xdp_1 = new UIObservable(196, observe_2);
    $this.ydp_1 = '';
    var tmp_4 = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiButton' call
    // Inline function 'kotlin.apply' call
    var this_0 = addTo(UIButton.sd2(size, '', null), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIComboBox.selectedButton.<anonymous>' call
    this_0.bd9(Companion_getInstance_4().t5s_1);
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_37();
    var tmp$ret$8 = Margin.u2l(numberToDouble(0.0), numberToDouble(8.0), numberToDouble(0.0), numberToDouble(8.0));
    this_0.lcv_1.tdx(tmp$ret$8);
    this_0.vd8(Colors_getInstance().g4r_1);
    this_0.xd8(MaterialColors_getInstance().j5i_1);
    this_0.zd8(MaterialColors_getInstance().j5i_1);
    this_0.fd9(MaterialColors_getInstance().q5i_1);
    this_0.kcv_1.ndy(MaterialColors_getInstance().m5i_1);
    this_0.kcv_1.ody(1.0);
    this_0.od9(false);
    tmp_4.zdp_1 = this_0;
    var tmp_5 = $this;
    // Inline function 'korlibs.korge.view.shapeView' call
    // Inline function 'korlibs.math.geom.shape.buildVectorPath' call
    // Inline function 'kotlin.apply' call
    var this_1 = new VectorPath();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIComboBox.expandButtonIcon.<anonymous>' call
    this_1.e3y(Vector2D.k2l(0, 0));
    this_1.g3y(Vector2D.k2l(20, 0));
    this_1.g3y(Vector2D.k2l(10, 10));
    this_1.u6();
    var fill = new RGBA(MaterialColors_getInstance().p5i_1);
    var renderer = GraphicsRenderer_SYSTEM_getInstance();
    var stroke = new RGBA(Colors_getInstance().g4r_1);
    // Inline function 'korlibs.korge.view.addTo' call
    var this_2 = ShapeView.je1(this_1, fill, stroke, 1.0, true, renderer);
    // Inline function 'kotlin.apply' call
    var this_3 = addTo(this_2, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.shapeView.<anonymous>' call
    tmp_5.adq_1 = scale(position(get_centered(this_3), $this.j2y() - 16.0, $this.k2y() * 0.5), 1.0, +1.0);
    var tmp_6 = $this;
    // Inline function 'korlibs.korge.view.solidRect' call
    var color = Colors_getInstance().l4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_4 = SolidRect.ddj(size, color);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp_6.bdq_1 = addTo(this_4, $this);
    var tmp_7 = $this;
    // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
    var size_0 = Size2D.k2t(size.d2j_1, 128.0);
    // Inline function 'kotlin.apply' call
    var this_5 = addTo(UIMaterialLayer.ud2(size_0), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIComboBox.itemsViewBackground.<anonymous>' call
    this_5.dcz(new RectCorners(0.0, 0.0, 9.0, 9.0));
    this_5.mcb(-1000.0);
    tmp_7.cdq_1 = this_5;
    var tmp_8 = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiScrollable' call
    var size_1 = Size2D.k2t(size.d2j_1, 128.0);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.apply' call
    var this_6 = addTo(UIScrollable.ke1(size_1, true), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    this_6.qdw_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIComboBox.itemsView.<anonymous>' call
    this_6.fdx_1 = Colors_getInstance().l4r_1;
    tmp_8.ddq_1 = this_6;
    var tmp_9 = $this;
    // Inline function 'korlibs.korge.ui.uiVerticalList' call
    var this_7 = $this.ddq_1.qdw_1;
    var provider = new UIComboBox$verticalList$1(items, $this);
    var width = $this.j2y();
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiVerticalList.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiVerticalList.<anonymous>' call
    tmp_9.edq_1 = addTo(UIVerticalList.le1(provider, numberToDouble(width)), this_7);
    $this.fdq_1 = false;
    updateItems($this);
    // Inline function 'korlibs.korge.input.onOver' call
    var this_8 = $this.bdq_1;
    var handler = UIComboBox$slambda_9($this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop = over$factory_3();
    var tmp73_safe_receiver = this_8 == null ? null : get_mouse(this_8);
    if (tmp73_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_10 = prop.get(tmp73_safe_receiver);
      tmp_10.v1x(UIComboBox$lambda(tmp73_safe_receiver, handler));
    }
    // Inline function 'korlibs.korge.input.onOut' call
    var this_9 = $this.bdq_1;
    var handler_0 = UIComboBox$slambda_10($this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop_0 = out$factory_1();
    var tmp73_safe_receiver_0 = this_9 == null ? null : get_mouse(this_9);
    if (tmp73_safe_receiver_0 == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_11 = prop_0.get(tmp73_safe_receiver_0);
      tmp_11.v1x(UIComboBox$lambda_0(tmp73_safe_receiver_0, handler_0));
    }
    // Inline function 'korlibs.korge.input.onDown' call
    var this_10 = $this.bdq_1;
    var handler_1 = UIComboBox$slambda_11($this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop_1 = down$factory_1();
    var tmp73_safe_receiver_1 = this_10 == null ? null : get_mouse(this_10);
    if (tmp73_safe_receiver_1 == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_12 = prop_1.get(tmp73_safe_receiver_1);
      tmp_12.v1x(UIComboBox$lambda_1(tmp73_safe_receiver_1, handler_1));
    }
    // Inline function 'korlibs.korge.input.onUp' call
    var this_11 = $this.bdq_1;
    var handler_2 = UIComboBox$slambda_12($this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop_2 = up$factory();
    var tmp73_safe_receiver_2 = this_11 == null ? null : get_mouse(this_11);
    if (tmp73_safe_receiver_2 == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_13 = prop_2.get(tmp73_safe_receiver_2);
      tmp_13.v1x(UIComboBox$lambda_2(tmp73_safe_receiver_2, handler_2));
    }
    // Inline function 'korlibs.korge.input.onClick' call
    var this_12 = $this.bdq_1;
    var handler_3 = UIComboBox$slambda_13($this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop_3 = click$factory_2();
    var tmp73_safe_receiver_3 = this_12 == null ? null : get_mouse(this_12);
    if (tmp73_safe_receiver_3 == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_14 = prop_3.get(tmp73_safe_receiver_3);
      tmp_14.v1x(UIComboBox$lambda_3(tmp73_safe_receiver_3, handler_3));
    }
    $this.yc6();
    $this.gdq_1 = false;
    var comboBox = $this;
    // Inline function 'korlibs.korge.input.keys' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIComboBox.<anonymous>' call
    var $this$keys = get_keys($this);
    $this$keys.cbm(UIComboBox$slambda_14($this));
    var tmp_15 = Key_BACKSPACE_getInstance();
    $this$keys.ybl(tmp_15, UIComboBox$slambda_15($this));
    var tmp_16 = [Key_UP_getInstance(), Key_DOWN_getInstance()];
    $this$keys.zbl(tmp_16, UIComboBox$slambda_16($this));
    var tmp_17 = [Companion_getInstance_19().a8i_1, Key_SPACE_getInstance()];
    $this$keys.zbl(tmp_17, UIComboBox$slambda_17($this));
    var tmp_18 = Key_ESCAPE_getInstance();
    $this$keys.ybl(tmp_18, UIComboBox$slambda_18($this));
    return $this;
  }
  me1(_set____db54di) {
    return this.udp_1.i3e(this, selectedIndex$factory(), _set____db54di);
  }
  ne1() {
    return this.udp_1.h3e(this, selectedIndex$factory_0());
  }
  jdx(_set____db54di) {
    return this.vdp_1.i3e(this, focusedIndex$factory(), _set____db54di);
  }
  udx() {
    return this.vdp_1.h3e(this, focusedIndex$factory_0());
  }
  edy(sdir) {
    var dir = sdir === 0 ? 1 : sdir;
    var inductionVariable = sdir === 0 ? 0 : 1;
    var last = this.oe1().j1();
    if (inductionVariable < last)
      $l$loop: do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var proposedIndex = umod(this.udx() + imul(dir, n) | 0, this.oe1().j1());
        if (this.sdx(proposedIndex)) {
          this.jdx(proposedIndex);
          break $l$loop;
        }
      }
       while (inductionVariable < last);
  }
  pe1(value) {
    this.me1(indexOf(this.oe1(), value));
  }
  idx() {
    return getOrNull(this.oe1(), this.ne1());
  }
  qe1(_set____db54di) {
    return this.wdp_1.i3e(this, items$factory(), _set____db54di);
  }
  oe1() {
    return this.wdp_1.h3e(this, items$factory_0());
  }
  odx() {
    return this.k2y();
  }
  re1(_set____db54di) {
    return this.xdp_1.i3e(this, viewportHeight$factory(), _set____db54di);
  }
  se1() {
    return this.xdp_1.h3e(this, viewportHeight$factory_0());
  }
  ddy(value) {
    this.ydp_1 = value;
    this.edq_1.te1();
  }
  sdx(index) {
    return contains(toString_0(this.oe1().i1(index)), this.ydp_1, true);
  }
  hdq(index) {
    this.me1(index);
    this.ddy('');
    this.ldy();
  }
  gdy() {
    return this.ddq_1.qcc();
  }
  bdy(set) {
    var parent = set ? this.pbj() : null;
    if (!(parent == null)) {
      parent.oca(this.cdq_1);
      parent.oca(this.ddq_1);
    } else {
      this.cdq_1.qbh();
      this.ddq_1.qbh();
    }
    this.ddq_1.mcb(+100001.0);
    this.cdq_1.mcb(+100000.0);
  }
  ue1(immediate) {
    this.edq_1.te1();
    set_focused(this, true);
    var tmp125_safe_receiver = this.pbj();
    var views = tmp125_safe_receiver == null ? null : tmp125_safe_receiver.vbk();
    if (!(views == null)) {
      if (!equals(get_openedComboBox(views), this)) {
        var tmp126_safe_receiver = get_openedComboBox(views);
        if (tmp126_safe_receiver == null)
          null;
        else {
          tmp126_safe_receiver.ldy();
        }
      }
      if (views == null)
        null;
      else {
        set_openedComboBox(views, this);
      }
    }
    if (!this.gdy()) {
      this.bdy(true);
      this.ddq_1.car(true);
      this.cdq_1.car(true);
      if (immediate) {
        this.ddq_1.ubh(1.0);
        this.ddq_1.scb(1.0);
        this.cdq_1.ubh(1.0);
        this.cdq_1.scb(1.0);
        this.adq_1.scb(-1.0);
        this.zdp_1.kcv_1.ndy(MaterialColors_getInstance().d5c_1);
        this.zdp_1.kcv_1.ody(2.0);
      } else {
        this.ddq_1.ubh(0.0);
        this.ddq_1.scb(0.0);
        this.cdq_1.ubh(0.0);
        this.cdq_1.scb(0.0);
        // Inline function 'korlibs.korge.animate.Animator.sequence' call
        var this_0 = get_simpleAnimator(this).ybg();
        var defaultTime = this_0.ibh();
        var defaultSpeed = this_0.abg_1;
        var easing = this_0.bbg_1;
        var startImmediately = this_0.hbg_1;
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.also' call
        var tmp10_root = this_0.ybf_1;
        var tmp11_fastDefaultTime = get_fast(defaultTime);
        var tmp12_level = this_0.gbg_1 + 1 | 0;
        var this_1 = new Animator(tmp10_root, tmp11_fastDefaultTime, defaultSpeed, easing, false, false, this_0, VOID, tmp12_level, startImmediately);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
        // Inline function 'korlibs.korge.ui.UIComboBox.open.<anonymous>' call
        // Inline function 'korlibs.korge.tween.get' call
        // Inline function 'korlibs.korge.tween.get' call
        var this_2 = alpha$factory(this.ddq_1);
        var tmp = new V2(this_2, 0.0, 1.0, _interpolate$ref_2(), true);
        // Inline function 'korlibs.korge.tween.get' call
        var this_3 = scaleY$factory(this.ddq_1);
        var tmp_0 = new V2(this_3, 0.0, 1.0, _interpolate$ref_3(), true);
        // Inline function 'korlibs.korge.tween.get' call
        // Inline function 'korlibs.korge.tween.get' call
        var this_4 = alpha$factory_0(this.cdq_1);
        var tmp_1 = new V2(this_4, 0.0, 1.0, _interpolate$ref_4(), true);
        // Inline function 'korlibs.korge.tween.get' call
        var this_5 = scaleY$factory_0(this.cdq_1);
        var tmp_2 = new V2(this_5, 0.0, 1.0, _interpolate$ref_5(), true);
        // Inline function 'korlibs.korge.tween.get' call
        var this_6 = scaleY$factory_1(this.adq_1);
        var end = -1.0;
        var tmp_3 = this_6.get();
        var tmp_4 = new V2(this_6, tmp_3, end, _interpolate$ref_6(), false);
        // Inline function 'korlibs.korge.tween.get' call
        var this_7 = borderColor$factory(this.zdp_1.kcv_1);
        var end_0 = MaterialColors_getInstance().d5c_1;
        var tmp_5 = this_7.get();
        var tmp_6 = new V2(this_7, tmp_5, new RGBA(end_0), _interpolateColor$ref_0(), false);
        // Inline function 'korlibs.korge.tween.get' call
        var this_8 = borderSize$factory(this.zdp_1.kcv_1);
        var tmp_7 = this_8.get();
        var tmp_8 = [tmp, tmp_0, tmp_1, tmp_2, tmp_4, tmp_6, new V2(this_8, tmp_7, 2.0, _interpolate$ref_7(), false)];
        // Inline function 'korlibs.time.seconds' call
        var tmp$ret$9 = toDuration(0.25, DurationUnit_SECONDS_getInstance());
        tween(this_1, tmp_8, tmp$ret$9, get_EASE(Companion_getInstance_44()));
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
        this_0.kbh(this_1.obg_1);
      }
    }
    globalPos(size(this.ddq_1, this.j2y(), this.se1()), this.gcd(Vector2D.k2h(0.0, this.k2y() + 8.0)));
    globalPos(size(this.cdq_1, this.j2y(), this.ddq_1.k2y() + 16), this.gcd(Vector2D.k2h(0.0, this.k2y())));
    size(this.edq_1, this.j2y(), this.edq_1.k2y());
    this.edq_1.te1();
    this.fdq_1 = true;
    updateProps(this);
  }
  fdy(immediate, $super) {
    immediate = immediate === VOID ? false : immediate;
    var tmp;
    if ($super === VOID) {
      this.ue1(immediate);
      tmp = Unit_instance;
    } else {
      tmp = $super.ue1.call(this, immediate);
    }
    return tmp;
  }
  ve1(immediate) {
    var tmp128_safe_receiver = this.pbj();
    var views = tmp128_safe_receiver == null ? null : tmp128_safe_receiver.vbk();
    if (!(views == null)) {
      if (equals(get_openedComboBox(views), this)) {
        set_openedComboBox(views, null);
      }
    }
    if (this.gdy()) {
      if (immediate) {
        this.ddq_1.ubh(0.0);
        this.ddq_1.scb(0.0);
        this.cdq_1.ubh(0.0);
        this.cdq_1.scb(0.0);
        this.adq_1.scb(+1.0);
        this.zdp_1.kcv_1.ndy(MaterialColors_getInstance().m5i_1);
        this.zdp_1.kcv_1.ody(1.0);
        this.ddq_1.car(false);
        this.bdy(false);
      } else {
        // Inline function 'korlibs.korge.animate.Animator.sequence' call
        var this_0 = get_simpleAnimator(this).ybg();
        var defaultTime = this_0.ibh();
        var defaultSpeed = this_0.abg_1;
        var easing = this_0.bbg_1;
        var startImmediately = this_0.hbg_1;
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.also' call
        var tmp10_root = this_0.ybf_1;
        var tmp11_fastDefaultTime = get_fast(defaultTime);
        var tmp12_level = this_0.gbg_1 + 1 | 0;
        var this_1 = new Animator(tmp10_root, tmp11_fastDefaultTime, defaultSpeed, easing, false, false, this_0, VOID, tmp12_level, startImmediately);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
        // Inline function 'korlibs.korge.ui.UIComboBox.close.<anonymous>' call
        // Inline function 'korlibs.korge.tween.get' call
        // Inline function 'korlibs.korge.tween.get' call
        var this_2 = alpha$factory_1(this.ddq_1);
        var tmp = this_2.get();
        var tmp_0 = new V2(this_2, tmp, 0.0, _interpolate$ref_8(), false);
        // Inline function 'korlibs.korge.tween.get' call
        // Inline function 'korlibs.korge.tween.get' call
        var this_3 = scaleY$factory_2(this.ddq_1);
        var tmp_1 = this_3.get();
        var tmp_2 = new V2(this_3, tmp_1, 0.0, _interpolate$ref_9(), false);
        // Inline function 'korlibs.korge.tween.get' call
        // Inline function 'korlibs.korge.tween.get' call
        var this_4 = alpha$factory_2(this.cdq_1);
        var tmp_3 = this_4.get();
        var tmp_4 = new V2(this_4, tmp_3, 0.0, _interpolate$ref_10(), false);
        // Inline function 'korlibs.korge.tween.get' call
        // Inline function 'korlibs.korge.tween.get' call
        var this_5 = scaleY$factory_3(this.cdq_1);
        var tmp_5 = this_5.get();
        var tmp_6 = new V2(this_5, tmp_5, 0.0, _interpolate$ref_11(), false);
        // Inline function 'korlibs.korge.tween.get' call
        var this_6 = scaleY$factory_4(this.adq_1);
        // Inline function 'korlibs.korge.tween.get' call
        var end = +1.0;
        var tmp_7 = this_6.get();
        var tmp_8 = new V2(this_6, tmp_7, end, _interpolate$ref_12(), false);
        // Inline function 'korlibs.korge.tween.get' call
        var this_7 = borderColor$factory_0(this.zdp_1.kcv_1);
        var end_0 = MaterialColors_getInstance().m5i_1;
        var tmp_9 = this_7.get();
        var tmp_10 = new V2(this_7, tmp_9, new RGBA(end_0), _interpolateColor$ref_1(), false);
        // Inline function 'korlibs.korge.tween.get' call
        var this_8 = borderSize$factory_0(this.zdp_1.kcv_1);
        var tmp_11 = this_8.get();
        var tmp_12 = [tmp_0, tmp_2, tmp_4, tmp_6, tmp_8, tmp_10, new V2(this_8, tmp_11, 1.0, _interpolate$ref_13(), false)];
        // Inline function 'korlibs.time.seconds' call
        var tmp$ret$12 = toDuration(0.25, DurationUnit_SECONDS_getInstance());
        tween(this_1, tmp_12, tmp$ret$12, get_EASE(Companion_getInstance_44()));
        block(this_1, VOID, UIComboBox$close$lambda(this));
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
        this_0.kbh(this_1.obg_1);
      }
    }
    this.fdq_1 = false;
    updateProps(this);
  }
  ldy(immediate, $super) {
    immediate = immediate === VOID ? false : immediate;
    var tmp;
    if ($super === VOID) {
      this.ve1(immediate);
      tmp = Unit_instance;
    } else {
      tmp = $super.ve1.call(this, immediate);
    }
    return tmp;
  }
  jc7(ctx) {
    if (this.gdq_1 && this.fdq_1) {
      this.gdq_1 = false;
      this.edq_1.rdt();
      this.ic7();
    }
    super.jc7(ctx);
  }
  ic7() {
    super.ic7();
    this.yc6();
    var inductionVariable = 0;
    var last = this.oe1().j1() - 1 | 0;
    if (inductionVariable <= last)
      $l$loop: do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.ddq_1.qdw_1.lca(i);
        var tmp0_elvis_lhs = tmp instanceof UIButton ? tmp : null;
        var tmp_0;
        if (tmp0_elvis_lhs == null) {
          continue $l$loop;
        } else {
          tmp_0 = tmp0_elvis_lhs;
        }
        var button = tmp_0;
        button.ccv_1 = this.ne1() === i;
      }
       while (inductionVariable <= last);
  }
  yc6() {
    super.yc6();
    this.ady(true);
  }
  ady(immediate) {
    if (this.fdq_1) {
      this.ue1(immediate);
    } else {
      this.ve1(immediate);
    }
  }
  gcq(value) {
    if (value) {
      this.fdy();
    } else {
      this.ldy();
    }
  }
}
class UIBaseContainer extends UIView {
  static eek(size) {
    var $this = this.wc6(size);
    $this.qe4_1 = false;
    $this.re4_1 = true;
    return $this;
  }
  ica(view) {
    super.ica(view);
    this.se4();
  }
  jca(view) {
    super.jca(view);
    this.se4();
  }
  yc6() {
    super.yc6();
    this.se4();
  }
  se4() {
    if (this.qe4_1)
      return Unit_instance;
    this.qe4_1 = true;
    try {
      this.zea();
    }finally {
      this.qe4_1 = false;
    }
  }
}
class UIContainer extends UIBaseContainer {
  static qea(size) {
    return this.eek(size);
  }
  zea() {
  }
}
class UIGridFill extends UIContainer {
  static te7(size, cols, rows, spacing, direction) {
    size = size === VOID ? Size2D.f2j(128, 128) : size;
    cols = cols === VOID ? 3 : cols;
    rows = rows === VOID ? 3 : rows;
    spacing = spacing === VOID ? Companion_getInstance_39().d2w_1 : spacing;
    direction = direction === VOID ? UIDirection_ROW_getInstance() : direction;
    var $this = this.qea(size);
    var tmp = $this;
    tmp.pe7_1 = new UIObservable(cols, UIGridFill$cols$delegate$lambda($this));
    var tmp_0 = $this;
    tmp_0.qe7_1 = new UIObservable(rows, UIGridFill$rows$delegate$lambda($this));
    var tmp_1 = $this;
    tmp_1.re7_1 = new UIObservable(spacing, UIGridFill$spacing$delegate$lambda($this));
    var tmp_2 = $this;
    tmp_2.se7_1 = new UIObservable(direction, UIGridFill$direction$delegate$lambda($this));
    return $this;
  }
  rea(_set____db54di) {
    return this.pe7_1.i3e(this, cols$factory(), _set____db54di);
  }
  sea() {
    return this.pe7_1.h3e(this, cols$factory_0());
  }
  tea(_set____db54di) {
    return this.qe7_1.i3e(this, rows$factory(), _set____db54di);
  }
  uea() {
    return this.qe7_1.h3e(this, rows$factory_0());
  }
  vea(_set____db54di) {
    return this.re7_1.i3e(this, spacing$factory(), _set____db54di);
  }
  wea() {
    return this.re7_1.h3e(this, spacing$factory_0());
  }
  xea(_set____db54di) {
    return this.se7_1.i3e(this, direction$factory(), _set____db54di);
  }
  yea() {
    return this.se7_1.h3e(this, direction$factory_0());
  }
  zea() {
    var width = this.j2y();
    var height = this.k2y();
    var paddingH = this.wea().f2w_1;
    var paddingV = this.wea().e2w_1;
    var elementHeight = (height - paddingV * (this.uea() - 1 | 0)) / this.uea();
    var elementWidth = (width - paddingH * (this.sea() - 1 | 0)) / this.sea();
    var elementHeightP = elementHeight + paddingV;
    var elementWidthP = elementWidth + paddingH;
    // Inline function 'korlibs.korge.view.View.forEachChildWithIndex' call
    var tmp339_safe_receiver = this.bca();
    var tmp;
    if (tmp339_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'korlibs.datastructure.iterators.fastForEachWithIndex' call
      var n = 0;
      while (n < tmp339_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.ui.UIGridFill.relayoutInternal.<anonymous>' call
        var index = n;
        var view = tmp339_safe_receiver.i1(n);
        var ex = this.yea().ceb_1 ? index % this.sea() | 0 : index / this.uea() | 0;
        var ey = this.yea().ceb_1 ? index / this.sea() | 0 : index % this.uea() | 0;
        var px = ex * elementWidthP;
        var py = ey * elementHeightP;
        var rpx = this.yea().ceb_1 && this.yea().eeb_1 ? width - px - elementWidth : px;
        var rpy = this.yea().deb_1 && this.yea().eeb_1 ? height - py - elementHeight : py;
        xy_0(view, rpx, rpy);
        size(view, elementWidth, elementHeight);
        n = n + 1 | 0;
      }
      tmp = Unit_instance;
    }
  }
}
class UIVerticalHorizontalStack extends UIContainer {
  static deh(size, padding, adjustSize) {
    size = size === VOID ? get_UI_DEFAULT_SIZE() : size;
    padding = padding === VOID ? 0.0 : padding;
    var $this = this.qea(size);
    $this.beh_1 = adjustSize;
    $this.ceh_1 = numberToDouble(padding);
    return $this;
  }
}
class UIVerticalStack extends UIVerticalHorizontalStack {
  static eee(forcedWidth, padding, adjustSize) {
    forcedWidth = forcedWidth === VOID ? null : forcedWidth;
    padding = padding === VOID ? 0.0 : padding;
    adjustSize = adjustSize === VOID ? true : adjustSize;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_33();
    var width = forcedWidth == null ? 100.0 : forcedWidth;
    var tmp$ret$0 = Size2D.k2t(numberToDouble(width), numberToDouble(0.0));
    var $this = this.deh(tmp$ret$0, padding, adjustSize);
    var tmp = $this;
    tmp.dee_1 = forcedWidth == null ? null : numberToDouble(forcedWidth);
    return $this;
  }
  zea() {
    var y = 0.0;
    var bb = Companion_getInstance_40().y2i();
    // Inline function 'korlibs.korge.view.View.forEachChild' call
    var tmp337_safe_receiver = this.bca();
    var tmp;
    if (tmp337_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var n = 0;
      while (n < tmp337_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.ui.UIVerticalStack.relayoutInternal.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        var it = tmp337_safe_receiver.i1(_unary__edvuaz);
        it.sbh(y);
        if (this.beh_1 && !(this.dee_1 == null)) {
          it.jcc(this.j2y());
        }
        var bounds = it.wbp();
        y = y + (bounds.s2i() + this.ceh_1);
        bb = BoundsBuilder__plus_impl_cz7f7x(bb, it.ycd(this));
      }
      tmp = Unit_instance;
    }
    this.bc7(Size2D.k2t(this.dee_1 == null ? _BoundsBuilder___get_xmax__impl__gsz0km(bb) : ensureNotNull(this.dee_1), y));
  }
}
class UIHorizontalStack extends UIVerticalHorizontalStack {
  static dek(forcedHeight, padding, adjustHeight) {
    forcedHeight = forcedHeight === VOID ? null : forcedHeight;
    padding = padding === VOID ? 0.0 : padding;
    adjustHeight = adjustHeight === VOID ? true : adjustHeight;
    var $this = this.deh(Size2D.k2t(0.0, forcedHeight == null ? 32.0 : forcedHeight), padding, adjustHeight);
    $this.cek_1 = forcedHeight;
    return $this;
  }
  zea() {
    var x = 0.0;
    var bb = Companion_getInstance_40().y2i();
    // Inline function 'korlibs.korge.view.View.forEachChild' call
    var tmp337_safe_receiver = this.bca();
    var tmp;
    if (tmp337_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var n = 0;
      while (n < tmp337_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.ui.UIHorizontalStack.relayoutInternal.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        var it = tmp337_safe_receiver.i1(_unary__edvuaz);
        it.rbh(x);
        if (this.beh_1 && !(this.cek_1 == null)) {
          it.lcc(this.k2y());
        }
        var bounds = it.wbp();
        x = x + (bounds.q2i() + this.ceh_1);
        bb = BoundsBuilder__plus_impl_cz7f7x(bb, it.ycd(this));
      }
      tmp = Unit_instance;
    }
    this.bc7(Size2D.k2t(x, this.cek_1 == null ? _BoundsBuilder___get_ymax__impl__oia4uz(bb) : ensureNotNull(this.cek_1)));
  }
}
class UIHorizontalVerticalFill extends UIContainer {
  static deq(size, padding) {
    size = size === VOID ? Size2D.f2j(128, 128) : size;
    padding = padding === VOID ? 0.0 : padding;
    var $this = this.qea(size);
    $this.ceq_1 = padding;
    return $this;
  }
}
class UIHorizontalFill extends UIHorizontalVerticalFill {
  static fen(size, padding) {
    size = size === VOID ? Size2D.f2j(128, 20) : size;
    padding = padding === VOID ? 0.0 : padding;
    return this.deq(size, padding);
  }
  zea() {
    // Inline function 'korlibs.korge.ui.UIHorizontalVerticalFill._relayoutInternal' call
    var dimension = this.j2y();
    var pos = 0.0;
    var padding = this.ceq_1;
    // Inline function 'kotlin.comparisons.maxOf' call
    var b = this.eca() - 1 | 0;
    var numSpaces = Math.max(0, b);
    var elementSize = (dimension - numSpaces * padding) / this.eca();
    // Inline function 'korlibs.korge.view.View.forEachChild' call
    var tmp337_safe_receiver = this.bca();
    var tmp;
    if (tmp337_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var n = 0;
      while (n < tmp337_safe_receiver.j1()) {
        // Inline function 'korlibs.korge.ui.UIHorizontalVerticalFill._relayoutInternal.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'korlibs.korge.ui.UIHorizontalFill.relayoutInternal.<anonymous>' call
        var it = tmp337_safe_receiver.i1(_unary__edvuaz);
        var pos_0 = pos;
        it.rbh(pos_0);
        it.lcc(this.k2y());
        it.fcc(elementSize);
        pos = pos + (elementSize + padding);
      }
      tmp = Unit_instance;
    }
  }
}
class Companion_7 {
  eeq() {
    return UIDirection_ROW_getInstance();
  }
  feq() {
    return UIDirection_COLUMN_getInstance();
  }
}
class UIDirection extends Enum {
  constructor(name, ordinal, isHorizontal, isVertical, isReverse) {
    isHorizontal = isHorizontal === VOID ? false : isHorizontal;
    isVertical = isVertical === VOID ? false : isVertical;
    isReverse = isReverse === VOID ? false : isReverse;
    super(name, ordinal);
    this.ceb_1 = isHorizontal;
    this.deb_1 = isVertical;
    this.eeb_1 = isReverse;
  }
}
class UIObservableProperty {
  constructor(prop) {
    this.oeq_1 = prop;
    this.peq_1 = new Signal2();
    this.qeq_1 = this.oeq_1.v1c();
    var tmp = this;
    tmp.req_1 = UIObservableProperty$getDisplayValue$lambda(this);
  }
  seq(value, notify) {
    if (!equals(value, this.oeq_1.v1c())) {
      this.oeq_1.w1c(value);
      if (notify) {
        this.peq_1.v1y(this, value);
      }
    }
  }
  b2() {
    return this.oeq_1.v1c();
  }
}
class UIEditableNumberProps extends UIObservableProperty {
  constructor(prop, min, max, decimals, clamped) {
    min = min === VOID ? 0.0 : min;
    max = max === VOID ? 1.0 : max;
    decimals = decimals === VOID ? 2 : decimals;
    clamped = clamped === VOID ? true : clamped;
    super(prop);
    this.keq_1 = min;
    this.leq_1 = max;
    this.meq_1 = decimals;
    this.neq_1 = clamped;
  }
}
class UIEditableNumber extends UIView {
  static kf0(value, min, max, decimals, clamped, size) {
    value = value === VOID ? 0.0 : value;
    min = min === VOID ? 0.0 : min;
    max = max === VOID ? 1.0 : max;
    decimals = decimals === VOID ? 2 : decimals;
    clamped = clamped === VOID ? true : clamped;
    size = size === VOID ? Size2D.f2j(64, 18) : size;
    var $this = this.wc6(size);
    $this.net_1 = decimals;
    $this.oet_1 = clamped;
    var tmp = $this;
    // Inline function 'korlibs.korge.ui.uiText' call
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiText.<anonymous>' call
    tmp.pet_1 = addTo(UIText.lf0('', size), $this);
    var tmp_0 = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiTextInput' call
    // Inline function 'kotlin.also' call
    var this_0 = addTo(UITextInput.mf0('', size), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiTextInput.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiTextInput.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIEditableNumber.textInputView.<anonymous>' call
    this_0.car(false);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIEditableNumber.textInputView.<anonymous>' call
    this_0.tdx(Companion_getInstance_37().p2l_1);
    tmp_0.qet_1 = this_0;
    $this.ret_1 = min;
    $this.set_1 = max;
    $this.tet_1 = new Signal();
    $this.uet_1 = NaN;
    $this.vet_1 = value;
    $this.hex(value);
    set_cursor($this, Cursor_RESIZE_EAST_getInstance());
    var start = {_v: 0.0};
    var tmp_1 = $this.qet_1.nf0();
    tmp_1.w1x(UIEditableNumber$lambda($this));
    var tmp_2 = $this.qet_1.of0();
    tmp_2.w1x(UIEditableNumber$lambda_0($this));
    var tmp_3 = $this.qet_1.pf0();
    tmp_3.w1x(UIEditableNumber$lambda_1($this));
    // Inline function 'korlibs.korge.input.mouse' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var $this$mouse = get_mouse($this);
    $this$mouse.cbi_1.w1x(UIEditableNumber$lambda_2);
    $this$mouse.zbh_1.w1x(UIEditableNumber$lambda_3($this));
    onMouseDrag($this, VOID, VOID, UIEditableNumber$lambda_4($this, start, max, min));
    return $this;
  }
  yc6() {
    super.yc6();
    size(this.pet_1, this.j2y(), this.k2y());
    size(this.qet_1, this.j2y(), this.k2y());
  }
  hex(value) {
    var clampedValue = this.oet_1 ? clamp_1(value, this.ret_1, this.set_1) : value;
    var tmp;
    if (!(this.uet_1 === clampedValue)) {
      tmp = true;
    } else {
      // Inline function 'kotlin.text.isEmpty' call
      var this_0 = this.pet_1.o2f();
      tmp = charSequenceLength(this_0) === 0;
    }
    if (tmp) {
      this.uet_1 = clampedValue;
      this.pet_1.bcg(getValueText$default(this));
      this.tet_1.x1x(this);
    }
  }
  qf0() {
    return this.qet_1.qcc();
  }
}
class Scope {}
class sam$kotlin_Comparator$0 {
  constructor(function_0) {
    this.sf0_1 = function_0;
  }
  rj(a, b) {
    return this.sf0_1(a, b);
  }
  compare(a, b) {
    return this.rj(a, b);
  }
}
class UIFocusManager {
  constructor(view) {
    this.hcq_1 = view;
    this.icq_1 = this.hcq_1;
    this.jcq_1 = null;
    var tmp = Type_DOWN_getInstance_0();
    this.hcq_1.k8a(tmp, UIFocusManager$lambda(this));
  }
  vbk() {
    return this.icq_1.vbk();
  }
  xbw() {
    return this.hcq_1.xbw();
  }
  rf0(value) {
    if (equals(this.jcq_1, value))
      return Unit_instance;
    var tmp136_safe_receiver = this.jcq_1;
    if (tmp136_safe_receiver == null)
      null;
    else {
      tmp136_safe_receiver.gcq(false);
    }
    this.jcq_1 = value;
    var tmp137_safe_receiver = this.jcq_1;
    if (tmp137_safe_receiver == null)
      null;
    else {
      tmp137_safe_receiver.gcq(true);
    }
    if (!(value == null)) {
      this.vbk().uf0(_get_rfocusView__qys03k(value, this), true);
    }
  }
  kcq(show, view) {
    if (show) {
      if (view == null)
        null;
      else {
        // Inline function 'kotlin.apply' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.ui.UIFocusManager.requestToggleSoftKeyboard.<anonymous>' call
        this.xbw().maq(view.ucp(Scope_instance).ocd(this.icq_1).j2t());
      }
      var tmp = this.xbw();
      tmp.oaq(VOID, (view == null ? true : isInterface(view, ISoftKeyboardConfig)) ? view : null);
    } else {
      this.xbw().paq();
    }
  }
  tf0(dir) {
    // Inline function 'kotlin.collections.mapNotNull' call
    // Inline function 'kotlin.collections.mapNotNullTo' call
    var this_0 = descendantsWith(this.icq_1, VOID, UIFocusManager$changeFocusIndex$lambda);
    var destination = ArrayList.m1();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'kotlin.collections.mapNotNullTo.<anonymous>' call
      // Inline function 'korlibs.korge.ui.UIFocusManager.changeFocusIndex.<anonymous>' call
      var tmp0_safe_receiver = get_focusable(element);
      if (tmp0_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        destination.f(tmp0_safe_receiver);
      }
    }
    var focusables = destination;
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.collections.sortedBy' call
    // Inline function 'kotlin.comparisons.compareBy' call
    var tmp = UIFocusManager$changeFocusIndex$lambda_0;
    var tmp$ret$4 = new sam$kotlin_Comparator$0(tmp);
    // Inline function 'kotlin.collections.filterTo' call
    var this_1 = sortedWith(focusables, tmp$ret$4);
    var destination_0 = ArrayList.m1();
    var tmp0_iterator_0 = this_1.f1();
    while (tmp0_iterator_0.g1()) {
      var element_0 = tmp0_iterator_0.h1();
      // Inline function 'korlibs.korge.ui.UIFocusManager.changeFocusIndex.<anonymous>' call
      if (element_0.fcq()) {
        destination_0.f(element_0);
      }
    }
    var sortedFocusables = destination_0;
    // Inline function 'kotlin.takeIf' call
    var this_2 = indexOf(sortedFocusables, this.jcq_1);
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    // Inline function 'korlibs.korge.ui.UIFocusManager.changeFocusIndex.<anonymous>' call
    if (this_2 >= 0) {
      tmp_0 = this_2;
    } else {
      tmp_0 = null;
    }
    var index = tmp_0;
    var tmp139_safe_receiver = getCyclicOrNull(sortedFocusables, !(index == null) ? index + dir | 0 : dir < 0 ? -1 : 0);
    if (tmp139_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ui.UIFocusManager.changeFocusIndex.<anonymous>' call
      focus(tmp139_safe_receiver);
      scrollParentsToMakeVisible(_get_rfocusView__qys03k(tmp139_safe_receiver, this));
    }
  }
}
class makeFocusable$2 {
  constructor($tabIndex, $this_makeFocusable, $onChanged) {
    this.wf0_1 = $this_makeFocusable;
    this.xf0_1 = $onChanged;
    this.vf0_1 = $tabIndex;
  }
  ucp(_this__u8e3s4) {
    return this.wf0_1;
  }
  ecq() {
    return this.vf0_1;
  }
  fcq() {
    return true;
  }
  gcq(value) {
    this.xf0_1(value);
  }
}
class UIIcons {
  constructor() {
    UIIcons_instance = this;
    this.yf0_1 = MutableAtlas.l4q();
    var tmp = this;
    tmp.zf0_1 = this.af1(VOID, UIIcons$CROSS$lambda);
  }
  bf1(size, block) {
    // Inline function 'korlibs.image.bitmap.context2d' call
    var this_0 = Bitmap32.c4p(numberToInt(size.d2j_1), numberToInt(size.e2j_1));
    // Inline function 'korlibs.image.bitmap.Bitmap.lock' call
    var rect = this_0.i4p_1;
    if (true) {
      this_0.w1w();
    }
    var result;
    try {
      // Inline function 'korlibs.image.bitmap.context2d.<anonymous>' call
      var ctx = this_0.y4x(true);
      try {
        // Inline function 'korlibs.korge.ui.UIIcons.createIcon.<anonymous>' call
        block(ctx);
      }finally {
        ctx.o18();
      }
    }finally {
      result = true ? this_0.q4q(rect) : 0;
    }
    return add(this.yf0_1, this_0).w4p_1;
  }
  af1(size, block, $super) {
    size = size === VOID ? Size2D.f2j(32, 32) : size;
    return $super === VOID ? this.bf1(size, block) : $super.bf1.call(this, size, block);
  }
}
class UIImage extends UIView {
  static ef4(size, bitmap, scaleMode, contentAnchor) {
    scaleMode = scaleMode === VOID ? Companion_getInstance_31().p2v_1 : scaleMode;
    contentAnchor = contentAnchor === VOID ? Companion_getInstance_32().p2g_1 : contentAnchor;
    var $this = this.wc6(size);
    $this.wf3_1 = Companion_getInstance_41().i2m_1;
    $this.xf3_1 = false;
    $this.yf3_1 = Colors_getInstance().l4r_1;
    $this.zf3_1 = true;
    $this.af4_1 = bitmap;
    $this.bf4_1 = scaleMode;
    $this.cf4_1 = contentAnchor;
    var tmp = $this;
    // Inline function 'korlibs.korge.render.Companion.QUAD_INDICES' call
    Companion_getInstance_20();
    var tmp$ret$0 = get_TEXTURED_ARRAY_QUAD_INDICES();
    tmp.df4_1 = new TexturedVertexArray(4, tmp$ret$0);
    return $this;
  }
  ff4(value) {
    if (!(this.af4_1 === value)) {
      this.af4_1 = value;
      this.xf3_1 = false;
    }
  }
  gf4(value) {
    if (!(this.bf4_1 === value)) {
      this.bf4_1 = value;
      this.xf3_1 = false;
    }
  }
  hf4(value) {
    if (!this.cf4_1.equals(value)) {
      this.cf4_1 = value;
      this.xf3_1 = false;
    }
  }
  yc6() {
    this.xf3_1 = false;
  }
  jc7(ctx) {
    if (!this.xf3_1 || !this.wf3_1.equals(this.ybp())) {
      this.xf3_1 = true;
      this.wf3_1 = this.ybp();
      var bitmapSize = toFloat(get_bounds(this.af4_1).j1());
      var width = this.j2y();
      var height = this.k2y();
      var finalRect = applyScaleMode(bitmapSize, new Rectangle(0.0, 0.0, width, height), this.bf4_1, this.cf4_1);
      var realL = clamp_1(finalRect.p2i(), 0.0, width);
      var realT = clamp_1(finalRect.r2i(), 0.0, height);
      var realR = clamp_1(finalRect.q2i(), 0.0, width);
      var realB = clamp_1(finalRect.s2i(), 0.0, height);
      var ratioL = convertRange_0(realL, finalRect.p2i(), finalRect.q2i(), 0.0, 1.0);
      var ratioR = convertRange_0(realR, finalRect.p2i(), finalRect.q2i(), 0.0, 1.0);
      var ratioT = convertRange_0(realT, finalRect.r2i(), finalRect.s2i(), 0.0, 1.0);
      var ratioB = convertRange_0(realB, finalRect.r2i(), finalRect.s2i(), 0.0, 1.0);
      this.df4_1.caj(0, realL, realT, realR - realL, realB - realT, this.ybp(), convertRange_0(ratioL, 0.0, 1.0, this.af4_1.y41_1, this.af4_1.a42_1), convertRange_0(ratioT, 0.0, 1.0, this.af4_1.z41_1, this.af4_1.f42_1), convertRange_0(ratioR, 0.0, 1.0, this.af4_1.y41_1, this.af4_1.a42_1), convertRange_0(ratioT, 0.0, 1.0, this.af4_1.b42_1, this.af4_1.d42_1), convertRange_0(ratioL, 0.0, 1.0, this.af4_1.e42_1, this.af4_1.c42_1), convertRange_0(ratioB, 0.0, 1.0, this.af4_1.z41_1, this.af4_1.f42_1), convertRange_0(ratioR, 0.0, 1.0, this.af4_1.e42_1, this.af4_1.c42_1), convertRange_0(ratioB, 0.0, 1.0, this.af4_1.b42_1, this.af4_1.d42_1), this.wcc());
    }
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    if (!(_RGBA___get_a__impl__6msvu7(this.yf3_1) === 0)) {
      // Inline function 'korlibs.image.bitmap.Bitmaps.white' call
      var tmp$ret$0 = get_Bitmaps_white();
      this_0.aa9(ctx.za8(tmp$ret$0), 0.0, 0.0, this.j2y(), this.k2y(), this.ybp(), VOID, this.yf3_1);
    }
    var array = this.df4_1;
    var tex = ctx.za8(this.af4_1).f41_1;
    var smoothing = this.zf3_1;
    var blendMode = this.vcc();
    var vcount = array.yae_1;
    var icount = array.zae_1;
    var matrix = Companion_getInstance_41().j2m_1;
    $l$block: {
      // Inline function 'korlibs.korge.render.BatchBuilder2D.setStateFast' call
      var tex_0 = tex.wab_1;
      this_0.rae(icount, vcount);
      var pprogram = null == null ? Companion_getInstance_8().yad_1 : null;
      if (this_0.gaf(tex_0, smoothing, blendMode, pprogram)) {
        break $l$block;
      }
      this_0.gae();
      if (!contains_0(this_0.ja8_1, tex_0)) {
        this_0.ia8_1 = 0;
        this_0.ja8_1[0] = tex_0;
      }
      this_0.ka8_1 = smoothing;
      this_0.la8_1 = blendMode;
      this_0.ma8_1 = pprogram;
    }
    this_0.daf(array, matrix, vcount, icount);
  }
}
class Highlight {
  constructor(pos, radiusRatio, alpha, below, scale) {
    below = below === VOID ? false : below;
    scale = scale === VOID ? 1.0 : scale;
    this.if4_1 = pos;
    this.jf4_1 = radiusRatio;
    this.kf4_1 = alpha;
    this.lf4_1 = below;
    this.mf4_1 = scale;
  }
}
class Highlights {
  constructor(view) {
    this.ndg_1 = view;
    this.odg_1 = fastArrayListOf([]);
    this.pdg_1 = fastArrayListOf([]);
  }
  nf4(pos, below, scale, startRadius) {
    this.ld9();
    var highlight = new Highlight(pos, startRadius, 1.0, below, scale);
    // Inline function 'kotlin.collections.plusAssign' call
    this.odg_1.f(highlight);
    // Inline function 'kotlin.collections.plusAssign' call
    this.pdg_1.f(highlight);
    var tmp = get_simpleAnimator(this.ndg_1);
    // Inline function 'korlibs.korge.tween.get' call
    var this_0 = radiusRatio$factory(highlight);
    var tmp_0 = this_0.get();
    var tmp_1 = new V2(this_0, tmp_0, 1.0, _interpolate$ref_14(), false);
    var tmp_2 = [tmp_1, V2Callback(VOID, UIMaterialLayer$Highlights$addHighlight$lambda(this))];
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$1 = toDuration(0.5, DurationUnit_SECONDS_getInstance());
    tween(tmp, tmp_2, tmp$ret$1, get_EASE_IN(Companion_getInstance_44()));
    return highlight;
  }
  qdg(pos, below, scale, startRadius, $super) {
    below = below === VOID ? false : below;
    scale = scale === VOID ? 1.0 : scale;
    startRadius = startRadius === VOID ? 0.0 : startRadius;
    return $super === VOID ? this.nf4(pos, below, scale, startRadius) : $super.nf4.call(this, pos, below, scale, startRadius);
  }
  of4(highlight) {
    // Inline function 'korlibs.korge.animate.Animator.sequence' call
    var this_0 = get_simpleAnimator(this.ndg_1);
    var defaultTime = this_0.ibh();
    var defaultSpeed = this_0.abg_1;
    var easing = this_0.bbg_1;
    var startImmediately = this_0.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp10_root = this_0.ybf_1;
    var tmp11_fastDefaultTime = get_fast(defaultTime);
    var tmp12_level = this_0.gbg_1 + 1 | 0;
    var this_1 = new Animator(tmp10_root, tmp11_fastDefaultTime, defaultSpeed, easing, false, false, this_0, VOID, tmp12_level, startImmediately);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    // Inline function 'korlibs.korge.ui.Highlights.removeHighlight.<anonymous>' call
    var tmp;
    if (highlight.lf4_1) {
      // Inline function 'korlibs.korge.tween.get' call
      var this_2 = radiusRatio$factory_0(highlight);
      var tmp_0 = this_2.get();
      tmp = new V2(this_2, tmp_0, 0.0, _interpolate$ref_15(), false);
    } else {
      // Inline function 'korlibs.korge.tween.get' call
      var this_3 = alpha$factory_3(highlight);
      var tmp_1 = this_3.get();
      tmp = new V2(this_3, tmp_1, 0.0, _interpolate$ref_16(), false);
    }
    var tmp_2 = tmp;
    var tmp_3 = [tmp_2, V2Callback(VOID, UIMaterialLayer$Highlights$removeHighlight$lambda(this))];
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$2 = toDuration(0.3, DurationUnit_SECONDS_getInstance());
    tween(this_1, tmp_3, tmp$ret$2, get_EASE_IN(Companion_getInstance_44()));
    block(this_1, VOID, UIMaterialLayer$Highlights$removeHighlight$lambda_0(this, highlight));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    this_0.kbh(this_1.obg_1);
    this.pdg_1.n3(highlight);
  }
  pf4(highlights) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = toList_0(highlights);
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.ui.Highlights.removeHighlights.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var it = this_0.i1(_unary__edvuaz);
      this.of4(it);
    }
  }
  ld9() {
    this.pf4(this.pdg_1);
  }
}
class Anchorable {}
class UIMaterialLayer extends UIView {
  static ud2(size) {
    size = size === VOID ? Size2D.f2j(100, 100) : size;
    var $this = this.wc6(size);
    $this.scy_1 = Colors_getInstance().g4r_1;
    $this.tcy_1 = null;
    $this.ucy_1 = null;
    $this.vcy_1 = Colors_getInstance().h4r_1;
    $this.wcy_1 = 0.0;
    $this.xcy_1 = RGBA__withAf_impl_dpdu32(Colors_getInstance().h4r_1, 0.3);
    $this.ycy_1 = 10.0;
    $this.zcy_1 = Companion_getInstance_34().a2i_1;
    $this.acz_1 = RGBA__withAd_impl_cralao(Colors_getInstance().g4r_1, 0.4);
    $this.bcz_1 = new Highlights($this);
    $this.ccz_1 = Companion_getInstance_32().p2g_1;
    return $this;
  }
  wd8(value) {
    this.scy_1 = value;
    this.hbh();
  }
  dcz(value) {
    this.tcy_1 = value;
    this.hbh();
  }
  qf4(value) {
    this.ucy_1 = value;
    this.hbh();
  }
  ndy(value) {
    this.vcy_1 = value;
    this.hbh();
  }
  ody(value) {
    this.wcy_1 = value;
    this.hbh();
  }
  rf4(value) {
    this.xcy_1 = value;
    this.hbh();
  }
  ecz(value) {
    this.ycy_1 = value;
    this.hbh();
  }
  sf4(value) {
    this.zcy_1 = value;
    this.hbh();
  }
  tf4(value) {
    if (!this.ccz_1.equals(value)) {
      this.ccz_1 = value;
      this.ycc();
    }
  }
  uf4() {
    return this.ccz_1;
  }
  zca() {
    return this.j2y() * this.ccz_1.y2g_1;
  }
  acb() {
    return this.k2y() * this.ccz_1.z2g_1;
  }
  gc7() {
    return new Rectangle(-this.zca(), -this.acb(), this.j2y(), this.k2y());
  }
  jc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    // Inline function 'korlibs.korge.render.renderCtx2d' call
    // Inline function 'korlibs.korge.render.RenderContext.useCtx2d' call
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    var batcher = ctx.ka7_1;
    if (!(ctx.oa7_1 === batcher)) {
      ctx.oa7_1 = batcher;
      ctx.ya8();
    }
    // Inline function 'korlibs.korge.render.RenderContext.useCtx2d.<anonymous>' call
    // Inline function 'korlibs.korge.render.renderCtx2d.<anonymous>' call
    var context = ctx.ma7_1;
    // Inline function 'korlibs.korge.render.RenderContext2D.keep' call
    $l$block_3: {
      // Inline function 'korlibs.korge.render.RenderContext2D.keepMatrix' call
      var old = context.uai_1;
      try {
        // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>' call
        $l$block_2: {
          // Inline function 'korlibs.korge.render.RenderContext2D.keepBlendMode' call
          var oldBlendFactors = context.vai_1;
          try {
            // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>' call
            $l$block_1: {
              // Inline function 'korlibs.korge.render.RenderContext2D.keepColor' call
              var multiplyColor = context.wai_1;
              try {
                // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>' call
                $l$block_0: {
                  // Inline function 'korlibs.korge.render.RenderContext2D.keepFiltering' call
                  var filtering = context.xai_1;
                  try {
                    // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                    $l$block: {
                      // Inline function 'korlibs.korge.render.RenderContext2D.keepSize' call
                      var size = context.sai_1;
                      try {
                        // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                        context.sai_1 = Size2D.k2t(this.j2y(), this.k2y());
                        context.vai_1 = this.vcc();
                        context.wai_1 = this.wcc();
                        context.yai(this.ybp());
                        this.vf4(context, true);
                        materialRoundRect(context, -this.zca(), -this.acb(), this.j2y(), this.k2y(), this.scy_1, _get_computedRadius__cigsus(this), this.zcy_1, this.xcy_1, this.ycy_1, VOID, VOID, VOID, this.wcy_1, this.vcy_1);
                        this.vf4(context, false);
                        break $l$block;
                      }finally {
                        context.sai_1 = size;
                      }
                    }
                    break $l$block_0;
                  }finally {
                    context.xai_1 = filtering;
                  }
                }
                break $l$block_1;
              }finally {
                context.wai_1 = multiplyColor;
              }
            }
            break $l$block_2;
          }finally {
            context.vai_1 = oldBlendFactors;
          }
        }
        break $l$block_3;
      }finally {
        context.uai_1 = old;
      }
    }
  }
  vf4(ctx2d, below) {
    // Inline function 'korlibs.korge.ui.Highlights.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.bcz_1.odg_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.korge.ui.UIMaterialLayer.renderHighlights.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it = array[_unary__edvuaz];
      if (below === it.lf4_1) {
        materialRoundRect(ctx2d, -this.zca() * it.mf4_1, -this.acb() * it.mf4_1, this.j2y() * it.mf4_1, this.k2y() * it.mf4_1, Colors_getInstance().l4r_1, _get_computedRadius__cigsus(this).c2t(it.mf4_1), VOID, VOID, VOID, it.if4_1, it.jf4_1 * this.pcb(), RGBA__withAd_impl_cralao(this.acz_1, _RGBA___get_ad__impl__pt5rdj(this.acz_1) * it.kf4_1));
      }
    }
  }
  nf4(pos, below, scale, startRadius) {
    return this.bcz_1.nf4(pos, below, scale, startRadius);
  }
  md9(pos, below, scale, startRadius, $super) {
    below = below === VOID ? false : below;
    scale = scale === VOID ? 1.0 : scale;
    startRadius = startRadius === VOID ? 0.0 : startRadius;
    return $super === VOID ? this.nf4(pos, below, scale, startRadius) : $super.nf4.call(this, pos, below, scale, startRadius);
  }
  ld9() {
    this.bcz_1.ld9();
  }
}
class UIObservable {
  constructor(initial, observe) {
    this.wdd_1 = initial;
    this.xdd_1 = observe;
    this.ydd_1 = this.wdd_1;
  }
  h3e(obj, prop) {
    return this.ydd_1;
  }
  i3e(obj, prop, value) {
    if (!equals(this.ydd_1, value)) {
      this.ydd_1 = value;
      this.xdd_1(value);
    }
  }
}
class UIProperty$1 {
  constructor($set, $get) {
    this.wf7_1 = $set;
    this.xf7_1 = $get;
  }
  w1c(value) {
    return this.wf7_1(value);
  }
  v1c() {
    return this.xf7_1();
  }
}
class UIPropertyRow extends UIView {
  static tfd(title, size) {
    size = size === VOID ? Size2D.f2j(128, 20) : size;
    var $this = this.wc6(size);
    $this.qfd_1 = title;
    var tmp = $this;
    // Inline function 'korlibs.korge.view.append' call
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.append' call
    var view = UIHorizontalFill.fen(size);
    $this.oca(view);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIPropertyRow.horizontal.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiText' call
    var text = $this.qfd_1;
    var size_0 = Companion_getInstance_58().vfd_1;
    // Inline function 'kotlin.apply' call
    addTo(UIText.lf0(text, size_0), view);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiText.<anonymous>' call
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.view.append' call
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.append' call
    var view_0 = UIHorizontalFill.fen(size);
    view.oca(view_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIPropertyRow.horizontal.<anonymous>.<anonymous>' call
    tmp_0.rfd_1 = view_0;
    tmp.sfd_1 = view;
    return $this;
  }
  ufd() {
    var tmp = this.rfd_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('container');
    }
  }
  yc6() {
    size(this.sfd_1, this.j2y(), this.k2y());
  }
}
class UIPropertyNumberRow extends UIPropertyRow {
  static vfa(title, propsList, size) {
    size = size === VOID ? Size2D.f2j(128, 20) : size;
    var $this = this.tfd(title, size);
    // Inline function 'kotlin.apply' call
    var this_0 = $this.ufd();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIPropertyNumberRow.<anonymous>' call
    var inductionVariable = 0;
    var last = propsList.length;
    while (inductionVariable < last) {
      var props = propsList[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.korge.ui.uiEditableNumber' call
      var value = props.b2();
      var min = props.keq_1;
      var max = props.leq_1;
      var decimals = props.meq_1;
      var clamped = props.neq_1;
      var size_0 = Size2D.f2j(64, 18);
      // Inline function 'kotlin.apply' call
      // Inline function 'korlibs.korge.view.append' call
      var view = UIEditableNumber.kf0(value, min, max, decimals, clamped, size_0);
      this_0.oca(view);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ui.UIPropertyNumberRow.<anonymous>.<anonymous>' call
      var editableNumber = view;
      var tmp = props;
      tmp.req_1 = UIPropertyNumberRow$lambda(editableNumber);
      view.tet_1.w1x(UIPropertyNumberRow$lambda_0(props));
      props.peq_1.u1y(UIPropertyNumberRow$lambda_1(editableNumber));
    }
    return $this;
  }
}
class MyScrollbarInfo {
  constructor(scrollable, direction, view) {
    this.wfd_1 = scrollable;
    this.xfd_1 = direction;
    this.yfd_1 = view;
    this.zfd_1 = this.gfe() ? x$factory_1(this.yfd_1) : y$factory_1(this.yfd_1);
    this.afe_1 = this.gfe() ? x$factory_2(this.yfd_1) : y$factory_2(this.yfd_1);
    this.bfe_1 = this.gfe() ? scaledWidth$factory(this.yfd_1) : scaledHeight$factory(this.yfd_1);
    this.cfe_1 = this.gfe() ? x$factory_3(this.ufd()) : y$factory_3(this.ufd());
    this.dfe_1 = new Signal();
    this.efe_1 = 0.0;
    this.ffe_1 = 0.0;
  }
  gfe() {
    return this.xfd_1.ceb_1;
  }
  ufd() {
    return this.wfd_1.qdw_1;
  }
  hfe(_set____db54di) {
    var this_0 = this.zfd_1;
    scrollBarPos$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  ife() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.zfd_1;
    scrollBarPos$factory_0();
    return this_0.get();
  }
  jfe(_set____db54di) {
    var this_0 = this.afe_1;
    viewPos$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  kfe() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.afe_1;
    viewPos$factory_0();
    return this_0.get();
  }
  lfe(_set____db54di) {
    var this_0 = this.bfe_1;
    viewScaledSize$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  mfe() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.bfe_1;
    viewScaledSize$factory_0();
    return this_0.get();
  }
  nfe() {
    return this.j1() / this.u8z();
  }
  ofe() {
    return this.j1() * this.nfe();
  }
  pfe() {
    return this.gfe() ? this.yfd_1.kcc() : this.yfd_1.mcc();
  }
  qfe(_set____db54di) {
    var this_0 = this.cfe_1;
    containerPos$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  rfe() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.cfe_1;
    containerPos$factory_0();
    return this_0.get();
  }
  sfe() {
    return this.gfe() ? this.wfd_1.ufe() : this.wfd_1.tfe();
  }
  vfe() {
    return this.gfe() ? this.wfd_1.xfe() : this.wfd_1.wfe();
  }
  j1() {
    return this.gfe() ? this.wfd_1.j2y() : this.wfd_1.k2y();
  }
  yfe() {
    return this.j1() < this.u8z();
  }
  u8z() {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.MyScrollbarInfo.<get-totalSize>.<anonymous>' call
    var it = this.ufd().wbp();
    var tmp;
    if (this.gfe()) {
      // Inline function 'kotlin.math.max' call
      var a = this.wfd_1.j2y();
      var b = it.q2i();
      tmp = Math.max(a, b);
    } else {
      // Inline function 'kotlin.math.max' call
      var a_0 = this.wfd_1.k2y();
      var b_0 = it.s2i();
      tmp = Math.max(a_0, b_0);
    }
    return tmp;
  }
  zfe() {
    return this.u8z() - this.j1();
  }
  aff() {
    return this.q1q() + this.j1();
  }
  bff(value) {
    var oldValue = -this.rfe();
    var newValue = this.wfd_1.odw_1 ? -clamp_1(value, -this.sfe(), this.zfe() + this.vfe()) : -clamp_1(value, 0.0, this.zfe());
    if (!(newValue === oldValue)) {
      this.qfe(newValue);
      this.dfe_1.x1x(this.wfd_1);
    }
  }
  q1q() {
    return -this.rfe();
  }
  cff(pos) {
    var d = this.j1() - this.pfe();
    if (d === 0.0)
      return 0.0;
    return pos / d * this.zfe();
  }
  dff(pos) {
    var d = this.zfe();
    if (d === 0.0)
      return 0.0;
    return pos / d * (this.j1() - this.pfe());
  }
  eff(start, end, anchor) {
    var tmp;
    var containsLower = this.q1q();
    if (!(start <= this.aff() ? containsLower <= start : false)) {
      tmp = true;
    } else {
      var containsLower_0 = this.q1q();
      tmp = !(end <= this.aff() ? containsLower_0 <= end : false);
    }
    if (tmp) {
      this.bff(clamp_1(start - this.j1() * anchor, 0.0, this.zfe()));
    }
  }
  fff(value) {
    this.bff(this.zfe() * value);
  }
  gff() {
    return this.q1q() / this.zfe();
  }
}
class UIScrollable extends UIView {
  static ke1(size, cache) {
    cache = cache === VOID ? true : cache;
    var $this = this.wc6(size, cache);
    $this.odw_1 = true;
    var tmp = $this;
    // Inline function 'korlibs.korge.view.fixedSizeContainer' call
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = FixedSizeContainer.sfh(size, true);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fixedSizeContainer.<anonymous>' call
    tmp.pdw_1 = addTo(this_0, $this);
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.view.container' call
    var this_1 = $this.pdw_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_2 = Container.vbw(true);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp_0.qdw_1 = addTo(this_2, this_1);
    var tmp_1 = $this;
    var tmp_2 = Companion_instance_22.feq();
    // Inline function 'korlibs.korge.view.solidRect' call
    var size_0 = Size2D.k2t(10.0, size.e2j_1 / 2);
    var color = Colors_getInstance().r59('#57577a');
    // Inline function 'korlibs.korge.view.addTo' call
    var this_3 = SolidRect.ddj(size_0, color);
    // Inline function 'kotlin.apply' call
    var this_4 = addTo(this_3, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp_1.rdw_1 = new MyScrollbarInfo($this, tmp_2, this_4);
    var tmp_3 = $this;
    var tmp_4 = Companion_instance_22.eeq();
    // Inline function 'korlibs.korge.view.solidRect' call
    var size_1 = Size2D.k2t(size.d2j_1 / 2, 10.0);
    var color_0 = Colors_getInstance().r59('#57577a');
    // Inline function 'korlibs.korge.view.addTo' call
    var this_5 = SolidRect.ddj(size_1, color_0);
    // Inline function 'kotlin.apply' call
    var this_6 = addTo(this_5, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp_3.sdw_1 = new MyScrollbarInfo($this, tmp_4, this_6);
    var tmp_5 = $this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp_5.tdw_1 = [$this.sdw_1, $this.rdw_1];
    $this.udw_1 = position$factory($this.sdw_1);
    $this.vdw_1 = positionRatio$factory($this.sdw_1);
    $this.wdw_1 = position$factory_0($this.rdw_1);
    $this.xdw_1 = positionRatio$factory_0($this.rdw_1);
    $this.ydw_1 = 0.75;
    $this.zdw_1 = 0.1;
    $this.adx_1 = x$factory_0($this.qdw_1);
    $this.bdx_1 = y$factory_0($this.qdw_1);
    $this.cdx_1 = get_fastSeconds(0);
    $this.ddx_1 = false;
    $this.edx_1 = 0.75;
    $this.fdx_1 = Colors_getInstance().r59('#161a1d');
    $this.gdx_1 = true;
    $this.qdw_1.sbh(0.0);
    showScrollBar($this);
    $this.yc6();
    // Inline function 'korlibs.korge.input.mouse' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    get_mouse($this).obi_1.w1x(UIScrollable$lambda($this));
    var dragging = {_v: false};
    var indexedObject = $this.tdw_1;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var info = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      decorateOutOverAlpha(info.yfd_1, UIScrollable$lambda_0($this));
    }
    var indexedObject_0 = $this.tdw_1;
    var inductionVariable_0 = 0;
    var last_0 = indexedObject_0.length;
    while (inductionVariable_0 < last_0) {
      var info_0 = indexedObject_0[inductionVariable_0];
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      var startScrollBarPos = {_v: 0.0};
      onMouseDrag(info_0.yfd_1, VOID, VOID, UIScrollable$lambda_1(info_0, startScrollBarPos));
    }
    onMouseDrag($this.pdw_1, VOID, VOID, UIScrollable$lambda_2($this, dragging));
    addFastUpdater_0($this, UIScrollable$lambda_3(dragging));
    // Inline function 'korlibs.time.seconds' call
    var tmp_6 = toDuration(0.1, DurationUnit_SECONDS_getInstance());
    addFixedUpdater($this, tmp_6, VOID, VOID, UIScrollable$lambda_4);
    return $this;
  }
  tfh() {
    return this.k2y() * this.zdw_1;
  }
  ufh() {
    return this.j2y() * this.zdw_1;
  }
  tfe() {
    return this.tfh();
  }
  wfe() {
    return this.tfh();
  }
  ufe() {
    return this.ufh();
  }
  xfe() {
    return this.ufh();
  }
  jc7(ctx) {
    if (!(this.fdx_1 === Colors_getInstance().l4r_1)) {
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
      var this_0 = ctx.ka7_1;
      var this_1 = this_0.ta7_1;
      if (!(this_1.oa7_1 === this_0)) {
        this_1.oa7_1 = this_0;
        this_1.ya8();
      }
      // Inline function 'korlibs.image.bitmap.Bitmaps.white' call
      var tmp$ret$0 = get_Bitmaps_white();
      this_0.aa9(ctx.za8(tmp$ret$0), 0.0, 0.0, this.j2y(), this.k2y(), this.ybp(), VOID, RGBA__times_impl_wehs9n(this.fdx_1, this.wcc()));
    }
    super.jc7(ctx);
  }
  vfh(rect, anchor) {
    this.sdw_1.eff(rect.p2i(), rect.q2i(), anchor.y2g_1);
    this.rdw_1.eff(rect.r2i(), rect.s2i(), anchor.z2g_1);
  }
  hdx(rect, anchor, $super) {
    anchor = anchor === VOID ? Companion_getInstance_32().f2h() : anchor;
    var tmp;
    if ($super === VOID) {
      this.vfh(rect, anchor);
      tmp = Unit_instance;
    } else {
      tmp = $super.vfh.call(this, rect, anchor);
    }
    return tmp;
  }
  wfh(view, anchor) {
    this.vfh(view.ycd(this), anchor);
    scrollParentsToMakeVisible(this);
  }
  xfh(view, anchor, $super) {
    anchor = anchor === VOID ? Companion_getInstance_32().f2h() : anchor;
    var tmp;
    if ($super === VOID) {
      this.wfh(view, anchor);
      tmp = Unit_instance;
    } else {
      tmp = $super.wfh.call(this, view, anchor);
    }
    return tmp;
  }
  yc6() {
    super.yc6();
    size(this.pdw_1, this.j2y(), this.k2y());
    position(this.rdw_1.yfd_1, this.j2y() - 10.0, 0.0);
    position(this.sdw_1.yfd_1, 0.0, this.k2y() - 10.0);
    this.hbh();
  }
}
class Companion_8 {
  constructor() {
    Companion_instance_23 = this;
    this.cfi_1 = 0;
    this.dfi_1 = 0;
    this.efi_1 = 100;
    this.ffi_1 = 1.0;
    this.gfi_1 = 1;
    this.hfi_1 = Size2D.f2j(128, 16);
    this.ifi_1 = 0.0;
  }
  jfi(step) {
    return step >= 1.0 ? 0 : step > 0.01 ? 1 : 2;
  }
}
class UISlider$thumb$slambda {
  constructor($component, $out) {
    this.yfl_1 = $component;
    this.zfl_1 = $out;
  }
  tbo(events, $completion) {
    var tmp74_safe_receiver = this.yfl_1._v;
    if (tmp74_safe_receiver == null)
      null;
    else {
      tmp74_safe_receiver.u6();
    }
    this.yfl_1._v = null;
    this.zfl_1(events);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UISlider$thumb$lambda$slambda {
  constructor($handler, $it) {
    this.yfh_1 = $handler;
    this.zfh_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_40(this, $completion), $completion);
  }
}
class UISlider$thumb$slambda_0 {
  constructor($component, $this, $over) {
    this.afm_1 = $component;
    this.bfm_1 = $this;
    this.cfm_1 = $over;
  }
  tbo(events, $completion) {
    this.afm_1._v = onNextFrame(this.bfm_1, UISlider$thumb$slambda$lambda(this.cfm_1, events));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UISlider$thumb$lambda$slambda_0 {
  constructor($handler, $it) {
    this.afi_1 = $handler;
    this.bfi_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_41(this, $completion), $completion);
  }
}
class UISlider extends UIView {
  static dfm(value, min, max, step, decimalPlaces, size) {
    Companion_getInstance_56();
    value = value === VOID ? 0 : value;
    min = min === VOID ? 0 : min;
    max = max === VOID ? 100 : max;
    step = step === VOID ? 1.0 : step;
    decimalPlaces = decimalPlaces === VOID ? null : decimalPlaces;
    size = size === VOID ? Companion_getInstance_56().hfi_1 : size;
    var $this = this.wc6(size);
    var tmp = $this;
    // Inline function 'korlibs.korge.view.xy' call
    // Inline function 'korlibs.korge.view.container' call
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = Container.vbw(false);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    var y = size.e2j_1 / 2;
    tmp.efl_1 = xy_0(this_1, numberToDouble(0), numberToDouble(y));
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
    var this_2 = $this.efl_1;
    var size_0 = Size2D.f2j(100, 6);
    // Inline function 'kotlin.apply' call
    var this_3 = addTo(UIMaterialLayer.ud2(size_0), this_2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UISlider.background.<anonymous>' call
    anchor(this_3, Companion_getInstance_32().s2g_1);
    this_3.ecz(0.0);
    this_3.kbj(0.34);
    this_3.wd8(get_uiSelectedColor(get_styles(this_3)));
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp$ret$5 = new RectCorners(numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0));
    this_3.qf4(tmp$ret$5);
    tmp_0.ffl_1 = this_3;
    var tmp_1 = $this;
    // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
    var this_4 = $this.efl_1;
    var size_1 = Size2D.f2j(0, 6);
    // Inline function 'kotlin.apply' call
    var this_5 = addTo(UIMaterialLayer.ud2(size_1), this_4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UISlider.foreground.<anonymous>' call
    anchor(this_5, Companion_getInstance_32().s2g_1);
    this_5.ecz(0.0);
    this_5.wd8(get_uiSelectedColor(get_styles(this_5)));
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp$ret$8 = new RectCorners(numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0));
    this_5.qf4(tmp$ret$8);
    tmp_1.gfl_1 = this_5;
    var tmp_2 = $this;
    // Inline function 'korlibs.korge.view.container' call
    var this_6 = $this.efl_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_7 = Container.vbw(false);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp_2.hfl_1 = addTo(this_7, this_6);
    var tmp_3 = $this;
    // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
    var this_8 = $this.efl_1;
    var size_2 = Size2D.f2j(16, 16);
    // Inline function 'kotlin.apply' call
    var this_9 = addTo(UIMaterialLayer.ud2(size_2), this_8);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UISlider.thumb.<anonymous>' call
    anchor(this_9, Companion_getInstance_32().f2h());
    this_9.rf4(RGBA__withAd_impl_cralao(Colors_getInstance().h4r_1, 0.15));
    this_9.ecz(4.0);
    this_9.sf4(Vector2D.k2h(-1.0, -1.0));
    this_9.wd8(get_uiSelectedColor(get_styles(this_9)));
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp$ret$15 = new RectCorners(numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0));
    this_9.qf4(tmp$ret$15);
    this_9.acz_1 = RGBA__withAd_impl_cralao(get_uiSelectedColor(get_styles(this_9)), 0.4);
    // Inline function 'kotlin.collections.arrayListOf' call
    var hoverHighlights = ArrayList.m1();
    // Inline function 'korlibs.korge.input.onOutOnOver' call
    var out = UISlider$thumb$lambda(this_9);
    var over = UISlider$thumb$lambda_0(this_9);
    var component = {_v: null};
    // Inline function 'korlibs.korge.input.onOut' call
    var handler = UISlider$thumb$slambda_1(component, out);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop = out$factory_2();
    var tmp73_safe_receiver = this_9 == null ? null : get_mouse(this_9);
    if (tmp73_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_4 = prop.get(tmp73_safe_receiver);
      tmp_4.v1x(UISlider$thumb$lambda_1(tmp73_safe_receiver, handler));
    }
    // Inline function 'korlibs.korge.input.onOver' call
    var handler_0 = UISlider$thumb$slambda_2(component, this_9, over);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop_0 = over$factory_4();
    var tmp73_safe_receiver_0 = this_9 == null ? null : get_mouse(this_9);
    if (tmp73_safe_receiver_0 == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_5 = prop_0.get(tmp73_safe_receiver_0);
      tmp_5.v1x(UISlider$thumb$lambda_2(tmp73_safe_receiver_0, handler_0));
    }
    tmp_3.ifl_1 = this_9;
    draggable($this, VOID, false, UISlider$lambda($this));
    $this.jfl_1 = new Signal();
    $this.kfl_1 = numberToDouble(min);
    $this.lfl_1 = numberToDouble(max);
    $this.mfl_1 = numberToDouble(step);
    $this.nfl_1 = numberToDouble(value);
    $this.ofl_1 = false;
    $this.pfl_1 = null;
    $this.qfl_1 = decimalPlaces;
    var tmp_6 = $this;
    tmp_6.rfl_1 = UISlider$textTransformer$lambda;
    $this.sfl_1 = null;
    updatedMarks($this);
    readjust($this);
    $this.tfl_1 = null;
    return $this;
  }
  hex(value) {
    var rvalue = nearestAlignedTo(clamp_1(value, this.kfl_1, this.lfl_1), this.mfl_1);
    if (!(rvalue === this.nfl_1)) {
      this.nfl_1 = rvalue;
      readjust(this);
      this.jfl_1.x1x(rvalue);
      if (!(this.pfl_1 === false)) {
        showTooltip(this, true);
      }
    }
  }
  sce() {
    this.ffl_1.wd8(get_uiSelectedColor(get_styles(this)));
    this.gfl_1.wd8(get_uiSelectedColor(get_styles(this)));
    this.ifl_1.wd8(get_uiSelectedColor(get_styles(this)));
    this.ifl_1.acz_1 = RGBA__withAd_impl_cralao(get_uiSelectedColor(get_styles(this)), 0.4);
    updatedMarks(this);
  }
  yc6() {
    super.yc6();
    updatedMarks(this);
    this.sce();
    readjust(this);
  }
  zcc() {
    super.zcc();
    var tmp152_safe_receiver = this.tfl_1;
    if (tmp152_safe_receiver == null)
      null;
    else {
      tmp152_safe_receiver.u6();
    }
    var tmp = this;
    var tmp153_safe_receiver = this.w15();
    tmp.tfl_1 = tmp153_safe_receiver == null ? null : get_closestUITooltipContainerMediatorNew(tmp153_safe_receiver);
    this.sce();
  }
}
class Companion_9 extends Kind {
  constructor() {
    Companion_instance_24 = null;
    super();
    Companion_instance_24 = this;
  }
}
class Companion_10 {
  constructor() {
    Companion_instance_25 = this;
    this.vfd_1 = Size2D.f2j(128, 18);
  }
}
class UIText$slambda {
  constructor(this$0) {
    this.mfm_1 = this$0;
  }
  tbo(it, $completion) {
    this.mfm_1.kd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIText$lambda$slambda {
  constructor($handler, $it) {
    this.efm_1 = $handler;
    this.ffm_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_42(this, $completion), $completion);
  }
}
class UIText$slambda_0 {
  constructor(this$0) {
    this.nfm_1 = this$0;
  }
  tbo(it, $completion) {
    this.nfm_1.nd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIText$lambda$slambda_0 {
  constructor($handler, $it) {
    this.gfm_1 = $handler;
    this.hfm_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_43(this, $completion), $completion);
  }
}
class UIText$slambda_1 {
  constructor(this$0) {
    this.ofm_1 = this$0;
  }
  tbo(it, $completion) {
    this.ofm_1.pfm();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIText$lambda$slambda_1 {
  constructor($handler, $it) {
    this.ifm_1 = $handler;
    this.jfm_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_44(this, $completion), $completion);
  }
}
class UIText$slambda_2 {
  constructor(this$0) {
    this.qfm_1 = this$0;
  }
  tbo(it, $completion) {
    this.qfm_1.hd2();
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIText$lambda$slambda_2 {
  constructor($handler, $it) {
    this.kfm_1 = $handler;
    this.lfm_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_45(this, $completion), $completion);
  }
}
class UIText extends UIView {
  static lf0(text, size) {
    Companion_getInstance_58();
    size = size === VOID ? Companion_getInstance_58().vfd_1 : size;
    var $this = this.wc6(size);
    var tmp = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe = UIText$bover$delegate$lambda($this);
    tmp.cf0_1 = new UIObservable(false, observe);
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var observe_0 = UIText$bpressing$delegate$lambda($this);
    tmp_0.df0_1 = new UIObservable(false, observe_0);
    var tmp_1 = $this;
    // Inline function 'korlibs.korge.view.solidRect' call
    var color = Colors_getInstance().l4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = SolidRect.ddj(size, color);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp_1.ef0_1 = addTo(this_0, $this);
    var tmp_2 = $this;
    // Inline function 'korlibs.korge.view.text' call
    var font = get_DefaultTtfFontAsBitmap();
    var textSize = Companion_instance_33.rfm_1;
    var color_0 = Colors_getInstance().g4r_1;
    var alignment = Companion_getInstance_4().h5s_1;
    var renderer = get_DefaultStringTextRenderer();
    var autoScaling = Companion_instance_33.sfm_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = Text.tfm(text, numberToDouble(textSize), color_0, font, alignment, renderer, autoScaling, null, null);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp_2.ff0_1 = addTo(this_1, $this);
    $this.gf0_1 = Colors_getInstance().l4r_1;
    $this.hf0_1 = text$factory_0($this.ff0_1);
    // Inline function 'korlibs.korge.input.mouse' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var $this$mouse = get_mouse($this);
    // Inline function 'korlibs.korge.input.MouseEvents.onOver' call
    var handler = UIText$slambda_3($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_3 = over$factory_5().get($this$mouse);
    tmp_3.v1x(UIText$lambda($this$mouse, handler));
    // Inline function 'korlibs.korge.input.MouseEvents.onOut' call
    var handler_0 = UIText$slambda_4($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_4 = out$factory_3().get($this$mouse);
    tmp_4.v1x(UIText$lambda_0($this$mouse, handler_0));
    // Inline function 'korlibs.korge.input.MouseEvents.onDown' call
    var handler_1 = UIText$slambda_5($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_5 = down$factory_2().get($this$mouse);
    tmp_5.v1x(UIText$lambda_1($this$mouse, handler_1));
    // Inline function 'korlibs.korge.input.MouseEvents.onUpAnywhere' call
    var handler_2 = UIText$slambda_6($this);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp_6 = upAnywhere$factory_1().get($this$mouse);
    tmp_6.v1x(UIText$lambda_2($this$mouse, handler_2));
    $this.if0_1 = Companion_getInstance_38().l2m();
    return $this;
  }
  ufm(_set____db54di) {
    return this.cf0_1.i3e(this, bover$factory(), _set____db54di);
  }
  vfm() {
    return this.cf0_1.h3e(this, bover$factory_0());
  }
  wfm(_set____db54di) {
    return this.df0_1.i3e(this, bpressing$factory(), _set____db54di);
  }
  xfm() {
    return this.df0_1.h3e(this, bpressing$factory_0());
  }
  bcg(_set____db54di) {
    var this_0 = this.hf0_1;
    text$factory_1();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  o2f() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.hf0_1;
    text$factory_2();
    return this_0.get();
  }
  kd2() {
    this.ufm(true);
  }
  nd2() {
    this.ufm(false);
  }
  pfm() {
    this.wfm(true);
  }
  hd2() {
    this.wfm(false);
  }
  jc7(ctx) {
    this.ef0_1.car(!(_RGBA___get_a__impl__6msvu7(this.gf0_1) === 0));
    this.ef0_1.m59(this.gf0_1);
    this.if0_1 = Companion_getInstance_38().g2j(Companion_getInstance_34().a2i_1, this.cc7());
    this.ff0_1.yfm(get_textFont(get_styles(this)), numberToInt(get_textSize(get_styles(this))), get_textColor(get_styles(this)), get_textAlignment(get_styles(this)));
    this.ff0_1.zfm(this.if0_1.j2t());
    this.ff0_1.bcg(this.o2f());
    super.jc7(ctx);
  }
  ic7() {
    super.ic7();
  }
}
class sam$korlibs_korge_view_ViewRenderer$0_0 {
  constructor(function_0) {
    this.afn_1 = function_0;
  }
  ade(_this__u8e3s4) {
    return this.afn_1(_this__u8e3s4);
  }
}
class UITextInput extends UIView {
  static mf0(initialText, size) {
    initialText = initialText === VOID ? '' : initialText;
    size = size === VOID ? Size2D.f2j(128, 24) : size;
    var $this = this.wc6(size);
    $this.qew_1 = new SoftKeyboardConfig();
    var tmp = $this;
    // Inline function 'korlibs.korge.view.renderableView' call
    var viewRenderer = UITextInput$bg$lambda;
    tmp.rew_1 = addTo(RenderableView.edj(size, new sam$korlibs_korge_view_ViewRenderer$0_0(viewRenderer)), $this);
    $this.sew_1 = viewRenderer$factory($this.rew_1);
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.view.clipContainer' call
    var size_0 = Companion_getInstance_33().t2v_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = ClipContainer.mfp(size_0);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.clipContainer.<anonymous>' call
    tmp_0.tew_1 = addTo(this_0, $this);
    var tmp_1 = $this;
    // Inline function 'korlibs.korge.view.text' call
    var this_1 = $this.tew_1;
    var color = Colors_getInstance().h4r_1;
    var font = get_DefaultTtfFontAsBitmap();
    var alignment = Companion_getInstance_4().h5s_1;
    var renderer = get_DefaultStringTextRenderer();
    var autoScaling = Companion_instance_33.sfm_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_2 = Text.tfm(initialText, numberToDouble(16.0), color, font, alignment, renderer, autoScaling, null, null);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp_1.uew_1 = addTo(this_2, this_1);
    $this.vew_1 = new TextEditController($this.uew_1, $this.uew_1, $this, $this.rew_1);
    $this.wew_1 = text$factory_3($this.vew_1);
    $this.xew_1 = textSize$factory_1($this.vew_1);
    $this.yew_1 = font$factory($this.vew_1);
    $this.zew_1 = onReturnPressed$factory($this.vew_1);
    $this.aex_1 = onEscPressed$factory($this.vew_1);
    $this.bex_1 = onFocusLost$factory($this.vew_1);
    $this.cex_1 = selectionRange$factory($this.vew_1);
    $this.dex_1 = selectionStart$factory($this.vew_1);
    $this.eex_1 = selectionEnd$factory($this.vew_1);
    $this.fex_1 = selectionLength$factory($this.vew_1);
    var tmp_2 = $this;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_37();
    tmp_2.gex_1 = Margin.u2l(numberToDouble(3.0), numberToDouble(2.0), numberToDouble(2.0), numberToDouble(2.0));
    $this.yc6();
    return $this;
  }
  bcg(_set____db54di) {
    var this_0 = this.wew_1;
    text$factory_4();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  o2f() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.wew_1;
    text$factory_5();
    return this_0.get();
  }
  nf0() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.zew_1;
    onReturnPressed$factory_0();
    return this_0.get();
  }
  of0() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.aex_1;
    onEscPressed$factory_0();
    return this_0.get();
  }
  pf0() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.bex_1;
    onFocusLost$factory_0();
    return this_0.get();
  }
  jf0() {
    return focus(this.vew_1);
  }
  ecg() {
    return this.vew_1.ecg();
  }
  tdx(value) {
    this.gex_1 = value;
    this.yc6();
  }
  yc6() {
    size(this.rew_1, this.j2y(), this.k2y());
    bounds_0(this.tew_1, (new Rectangle(0.0, 0.0, this.j2y(), this.k2y())).u2t(this.gex_1));
  }
}
class sam$korlibs_korge_view_ViewRenderer$0_1 {
  constructor(function_0) {
    this.nfp_1 = function_0;
  }
  ade(_this__u8e3s4) {
    return this.nfp_1(_this__u8e3s4);
  }
}
class UITooltipContainer extends Container {
  static efs() {
    var $this = this.vbw();
    var tmp = $this;
    // Inline function 'korlibs.korge.view.container' call
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = Container.vbw(false);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp.xfr_1 = addTo(this_0, $this);
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.ui.uiText' call
    var size = Companion_getInstance_58().vfd_1;
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(UIText.lf0('tooltip', size), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UITooltipContainer.tooltip.<anonymous>' call
    this_1.gf0_1 = Colors_getInstance().r59('#303030');
    this_1.car(false);
    tmp_0.yfr_1 = this_1;
    var tmp_1 = $this;
    // Inline function 'korlibs.time.seconds' call
    tmp_1.zfr_1 = toDuration(0.4, DurationUnit_SECONDS_getInstance());
    var tmp_2 = $this;
    // Inline function 'korlibs.time.seconds' call
    tmp_2.afs_1 = toDuration(0.2, DurationUnit_SECONDS_getInstance());
    $this.bfs_1 = 0.0;
    $this.cfs_1 = 4.0;
    $this.dfs_1 = null;
    return $this;
  }
  ffs() {
    this.yfr_1.car(false);
    var tmp154_safe_receiver = this.dfs_1;
    if (tmp154_safe_receiver == null)
      null;
    else {
      tmp154_safe_receiver.u6();
    }
    this.dfs_1 = null;
  }
  gfs() {
    this.ffs();
    var tmp = this;
    var tmp_0 = get_timers(this);
    var tmp_1 = this.zfr_1;
    tmp.dfs_1 = tmp_0.ocq(tmp_1, UITooltipContainer$appear$lambda(this));
  }
  hfs(view) {
    var bounds = view.rcd();
    globalPos(this.yfr_1, Vector2D.k2h(bounds.p2i() + this.bfs_1, bounds.s2i() + this.cfs_1));
  }
  ifs(text) {
    this.yfr_1.bcg(text);
  }
  jfs(view, text) {
    this.gfs();
    this.hfs(view);
    this.ifs(text);
  }
}
class tooltip$slambda {
  constructor($component, $out) {
    this.kfs_1 = $component;
    this.lfs_1 = $out;
  }
  tbo(events, $completion) {
    var tmp74_safe_receiver = this.kfs_1._v;
    if (tmp74_safe_receiver == null)
      null;
    else {
      tmp74_safe_receiver.u6();
    }
    this.kfs_1._v = null;
    this.lfs_1(events);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class tooltip$lambda$slambda {
  constructor($handler, $it) {
    this.mfs_1 = $handler;
    this.nfs_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_46(this, $completion), $completion);
  }
}
class tooltip$slambda_0 {
  constructor($component, $this, $over) {
    this.ofs_1 = $component;
    this.pfs_1 = $this;
    this.qfs_1 = $over;
  }
  tbo(events, $completion) {
    this.ofs_1._v = onNextFrame(this.pfs_1, tooltip$slambda$lambda(this.qfs_1, events));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class tooltip$lambda$slambda_0 {
  constructor($handler, $it) {
    this.rfs_1 = $handler;
    this.sfs_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_47(this, $completion), $completion);
  }
}
class Companion_11 {
  tfs(textData) {
    return bounds(textData).j1().w2v(Size2D.f2j(8, 4));
  }
}
class Tooltip extends UIView {
  static ufv(mediator, track, textData) {
    var $this = this.wc6(Companion_instance_26.tfs(textData));
    $this.ofv_1 = mediator;
    $this.pfv_1 = track;
    var tmp = $this;
    // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
    var size = $this.j1();
    // Inline function 'kotlin.apply' call
    var this_0 = addTo(UIMaterialLayer.ud2(size), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.Tooltip.backgroundView.<anonymous>' call
    this_0.wd8(get_uiUnselectedColor(get_styles(this_0)));
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp$ret$0 = new RectCorners(numberToDouble(0.25), numberToDouble(0.25), numberToDouble(0.25), numberToDouble(0.25));
    this_0.qf4(tmp$ret$0);
    tmp.qfv_1 = this_0;
    var tmp_0 = $this;
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.korge.view.textBlock' call
    var align = Companion_getInstance_4().h5s_1;
    var size_0 = Size2D.f2j(100, 100);
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = TextBlock.gd6(textData, align, size_0);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.textBlock.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.Tooltip.textView.<anonymous>' call
    this_2.ecc($this.j1());
    this_2.td9(Companion_getInstance_4().u5s_1);
    tmp_0.rfv_1 = this_2;
    $this.sfv_1 = textData;
    $this.tfv_1 = textData;
    $this.mcb(100000.0);
    var tmp_1 = get_timers($this);
    // Inline function 'korlibs.time.seconds' call
    var tmp_2 = toDuration(0.5, DurationUnit_SECONDS_getInstance());
    tmp_1.scp(tmp_2, UITooltipContainerMediatorNew$Tooltip$lambda(track, $this));
    return $this;
  }
  vfv(value) {
    this.tfv_1 = value;
    this.rfv_1.ud9(value);
  }
  wfv(track) {
    this.pfv_1 = track;
    var bounds = track.tcd();
    var tooltipBounds = this.tcd();
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var this_0 = bounds.e2u(Companion_getInstance_32().q2g_1);
    var that = Vector2D.k2h(tooltipBounds.k2i_1 * 0.5, tooltipBounds.l2i_1 + 4.0);
    var tmp$ret$0 = Vector2D.k2h(this_0.i2h_1 - that.i2h_1, this_0.j2h_1 - that.j2h_1);
    this.ccc(tmp$ret$0);
  }
  xfv(track, $super) {
    track = track === VOID ? this.pfv_1 : track;
    var tmp;
    if ($super === VOID) {
      this.wfv(track);
      tmp = Unit_instance;
    } else {
      tmp = $super.wfv.call(this, track);
    }
    return tmp;
  }
  yfv(textData) {
    var size = Companion_instance_26.tfs(textData);
    this.ecc(size);
    this.rfv_1.ecc(size);
    this.qfv_1.ecc(size);
  }
  zcc() {
    this.xfv();
  }
}
class UITooltipContainerMediatorNew {
  constructor(container) {
    this.ufl_1 = container;
    var tmp = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.vfl_1 = ArrayList.m1();
  }
  zfv(track, text, maxTextSize, update, immediate) {
    var textStyle = new Style(get_DefaultTtfFontAsBitmap(), 12.0);
    var textData = textStyle.s6w(text);
    var tmp;
    if (update) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlin.collections.firstOrNull' call
        var tmp0_iterator = this.vfl_1.f1();
        while (tmp0_iterator.g1()) {
          var element = tmp0_iterator.h1();
          // Inline function 'korlibs.korge.ui.UITooltipContainerMediatorNew.show.<anonymous>' call
          if (equals(element.pfv_1, track)) {
            tmp$ret$1 = element;
            break $l$block;
          }
        }
        tmp$ret$1 = null;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = null;
    }
    var _tooltip = tmp;
    var tmp_0;
    if (_tooltip == null) {
      // Inline function 'kotlin.also' call
      var this_0 = Tooltip.ufv(this, track, textData);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ui.UITooltipContainerMediatorNew.show.<anonymous>' call
      // Inline function 'kotlin.collections.plusAssign' call
      var this_1 = this.vfl_1;
      var element_0 = addTo(this_0, this.ufl_1);
      this_1.f(element_0);
      tmp_0 = this_0;
    } else {
      tmp_0 = _tooltip;
    }
    var tooltip = tmp_0;
    if (!immediate && _tooltip == null) {
      tooltip.kbj(0.0);
      var tmp_1 = get_simpleAnimator(tooltip);
      // Inline function 'korlibs.korge.tween.get' call
      var this_2 = alpha$factory_4(tooltip);
      var tmp_2 = this_2.get();
      var tmp_3 = [new V2(this_2, tmp_2, 1.0, _interpolate$ref_17(), false)];
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$4 = toDuration(0.3, DurationUnit_SECONDS_getInstance());
      tween(tmp_1, tmp_3, tmp$ret$4);
    }
    tooltip.yfv(textStyle.s6w(maxTextSize));
    tooltip.vfv(textData);
    tooltip.xfv();
    return tooltip;
  }
  wfl(track, text, maxTextSize, update, immediate, $super) {
    maxTextSize = maxTextSize === VOID ? text : maxTextSize;
    update = update === VOID ? true : update;
    immediate = immediate === VOID ? false : immediate;
    return $super === VOID ? this.zfv(track, text, maxTextSize, update, immediate) : $super.zfv.call(this, track, text, maxTextSize, update, immediate);
  }
  afw(tooltip, immediate) {
    if (immediate) {
      tooltip.qbh();
    } else {
      // Inline function 'korlibs.korge.animate.Animator.sequence' call
      var this_0 = get_simpleAnimator(tooltip);
      var defaultTime = this_0.ibh();
      var defaultSpeed = this_0.abg_1;
      var easing = this_0.bbg_1;
      var startImmediately = this_0.hbg_1;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.also' call
      var tmp10_root = this_0.ybf_1;
      var tmp11_fastDefaultTime = get_fast(defaultTime);
      var tmp12_level = this_0.gbg_1 + 1 | 0;
      var this_1 = new Animator(tmp10_root, tmp11_fastDefaultTime, defaultSpeed, easing, false, false, this_0, VOID, tmp12_level, startImmediately);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
      // Inline function 'korlibs.korge.ui.UITooltipContainerMediatorNew.hide.<anonymous>' call
      // Inline function 'korlibs.korge.tween.get' call
      var this_2 = alpha$factory_5(tooltip);
      var tmp = this_2.get();
      var tmp_0 = [new V2(this_2, tmp, 0.0, _interpolate$ref_18(), false)];
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$1 = toDuration(0.3, DurationUnit_SECONDS_getInstance());
      tween(this_1, tmp_0, tmp$ret$1);
      removeFromParent(this_1, tooltip);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
      this_0.kbh(this_1.obg_1);
    }
    // Inline function 'kotlin.collections.minusAssign' call
    this.vfl_1.n3(tooltip);
  }
  xfl(tooltip, immediate, $super) {
    immediate = immediate === VOID ? false : immediate;
    var tmp;
    if ($super === VOID) {
      this.afw(tooltip, immediate);
      tmp = Unit_instance;
    } else {
      tmp = $super.afw.call(this, tooltip, immediate);
    }
    return tmp;
  }
  bfw(immediate) {
    $l$loop: while (true) {
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!!this.vfl_1.a1()) {
        break $l$loop;
      }
      this.afw(last(this.vfl_1), immediate);
    }
    return this;
  }
  cfw(immediate, $super) {
    immediate = immediate === VOID ? true : immediate;
    return $super === VOID ? this.bfw(immediate) : $super.bfw.call(this, immediate);
  }
  u6() {
    this.cfw();
  }
}
class Companion_12 {}
class UIVerticalList extends UIView {
  static le1(provider, width) {
    width = width === VOID ? 200.0 : width;
    var $this = this.wc6(Companion_getInstance_61().hcz_1.b2w(width));
    $this.mdt_1 = false;
    $this.ndt_1 = LinkedHashMap.vc();
    $this.odt_1 = Companion_getInstance_38().w2i_1;
    $this.pdt_1 = Companion_getInstance_34().b2i_1;
    $this.qdt_1 = provider;
    $this.rdt();
    addFastUpdater_0($this, UIVerticalList$lambda);
    return $this;
  }
  jc7(ctx) {
    this.rdt();
    super.jc7(ctx);
  }
  te1() {
    this.mdt_1 = true;
    this.nca();
    this.ndt_1.o3();
    this.rdt();
  }
  rdt() {
    if (this.w15() == null)
      return Unit_instance;
    var area = getVisibleGlobalArea(this);
    var point = this.dcc();
    var numItems = this.qdt_1.pdx();
    if (this.mdt_1 || !area.equals(this.odt_1) || !point.equals(this.pdt_1)) {
      this.mdt_1 = false;
      this.odt_1 = area;
      this.pdt_1 = point;
      var transform = ensureNotNull(this.w15()).ybp().z2n();
      var fromIndex = clamp(getIndexAtY(this, (-point.j2h_1 + 0) / transform.o2o_1), 0, numItems - 1 | 0);
      var toIndex = fromIndex;
      if (numItems > 0) {
        var inductionVariable = fromIndex;
        if (inductionVariable < numItems)
          $l$loop: do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'kotlin.collections.getOrPut' call
            var this_0 = this.ndt_1;
            var value = this_0.k3(index);
            var tmp;
            if (value == null) {
              // Inline function 'korlibs.korge.ui.UIVerticalList.updateList.<anonymous>' call
              var itemHeight = this.qdt_1.tdt(index);
              // Inline function 'kotlin.also' call
              var this_1 = this.qdt_1.rdx(index, this);
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'korlibs.korge.ui.UIVerticalList.updateList.<anonymous>.<anonymous>' call
              this.oca(this_1);
              var answer = size(position(this_1, 0.0, this.qdt_1.sdt(index)), this.j2y(), itemHeight);
              this_0.r3(index, answer);
              tmp = answer;
            } else {
              tmp = value;
            }
            var view = tmp;
            view.mcb(index);
            toIndex = index;
            if (view.gcd(Vector2D.k2h(0.0, view.k2y())).j2h_1 >= area.s2i()) {
              break $l$loop;
            }
          }
           while (inductionVariable < numItems);
      }
      // Inline function 'kotlin.collections.filter' call
      // Inline function 'kotlin.collections.filterTo' call
      var this_2 = this.ndt_1.l3();
      var destination = ArrayList.m1();
      var tmp0_iterator = this_2.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.ui.UIVerticalList.updateList.<anonymous>' call
        if (!(fromIndex <= element ? element <= toIndex : false)) {
          destination.f(element);
        }
      }
      var removeIndices = toSet(destination);
      // Inline function 'kotlin.collections.forEach' call
      // Inline function 'kotlin.collections.iterator' call
      var tmp0_iterator_0 = this.ndt_1.z1().f1();
      while (tmp0_iterator_0.g1()) {
        var element_0 = tmp0_iterator_0.h1();
        // Inline function 'korlibs.korge.ui.UIVerticalList.updateList.<anonymous>' call
        // Inline function 'kotlin.collections.component1' call
        var index_0 = element_0.a2();
        // Inline function 'kotlin.collections.component2' call
        var view_0 = element_0.b2();
        if (removeIndices.f3(index_0)) {
          view_0.qbh();
        }
      }
      var _iterator__ex2g4s = removeIndices.f1();
      while (_iterator__ex2g4s.g1()) {
        var index_1 = _iterator__ex2g4s.h1();
        this.ndt_1.s3(index_1);
      }
    }
    size(this, this.j2y(), this.qdt_1.sdt(numItems - 1 | 0) + this.qdt_1.tdt(numItems - 1 | 0));
  }
}
class Companion_13 {
  constructor() {
    Companion_instance_28 = this;
    this.fcz_1 = 90.0;
    this.gcz_1 = 32.0;
    this.hcz_1 = Size2D.f2t(90.0, 32.0);
  }
  icz(iconView, bmp, width, height, anchor_0) {
    var iconScaleX = width / bmp.o41_1;
    var iconScaleY = height / bmp.p41_1;
    // Inline function 'kotlin.math.min' call
    var iconScale = Math.min(iconScaleX, iconScaleY);
    iconView.lfy(bmp);
    anchor(iconView, anchor_0);
    position(iconView, width * anchor_0.y2g_1, height * anchor_0.z2g_1);
    scale(iconView, iconScale, iconScale);
  }
}
class ScaleHandler {
  constructor(window_0, anchor_0) {
    this.gg1_1 = window_0;
    this.hg1_1 = anchor_0;
    this.ig1_1 = this.hg1_1.y2g_1 === this.hg1_1.z2g_1;
    var tmp = this;
    // Inline function 'korlibs.korge.view.solidRect' call
    var this_0 = this.gg1_1;
    var size = Companion_getInstance_33().t2v_1;
    var color = Colors_getInstance().l4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = SolidRect.ddj(size, color);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.ScaleHandler.view.<anonymous>' call
    var sh = this_2;
    var anchor_1 = this.hg1_1;
    anchor(this_2, Companion_getInstance_32().f2h());
    set_cursor(this_2, Companion_getInstance_21().pam(anchor_1));
    draggable(sh, VOID, VOID, UIWindow$ScaleHandler$view$lambda(this, anchor_1));
    tmp.jg1_1 = this_2;
  }
  kg1(width, height) {
    size(position(this.jg1_1, getExpectedX(this), getExpectedY(this)), this.hg1_1.y2g_1 === 0.5 ? width : 10.0, this.hg1_1.z2g_1 === 0.5 ? height : 10.0);
  }
}
class sam$korlibs_korge_view_ViewRenderer$0_2 {
  constructor(function_0) {
    this.gg5_1 = function_0;
  }
  ade(_this__u8e3s4) {
    return this.gg5_1(_this__u8e3s4);
  }
}
class UIWindow$closeButton$slambda {
  constructor(this$0) {
    this.cg5_1 = this$0;
  }
  tbo(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_48(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class UIWindow$closeButton$lambda$slambda {
  constructor($handler, $it) {
    this.eg5_1 = $handler;
    this.fg5_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_49(this, $completion), $completion);
  }
}
class UIWindow extends UIContainer {
  static ig5(title, size_0) {
    size_0 = size_0 === VOID ? Size2D.f2j(256, 256) : size_0;
    var $this = this.qea(size_0);
    $this.hg4_1 = 32.0;
    $this.ig4_1 = 6.0;
    $this.jg4_1 = Colors_getInstance().r59('#6f6e85');
    $this.kg4_1 = Colors_getInstance().r59('#6f6e85');
    $this.lg4_1 = Colors_getInstance().r59('#471175');
    $this.mg4_1 = Colors_getInstance().h4r_1;
    $this.ng4_1 = 128.0;
    $this.og4_1 = 64.0;
    $this.pg4_1 = 4096.0;
    $this.qg4_1 = 4096.0;
    var tmp = $this;
    // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
    // Inline function 'kotlin.apply' call
    var this_0 = addTo(UIMaterialLayer.ud2(size_0), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIWindow.bgMaterial.<anonymous>' call
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp$ret$0 = new RectCorners(numberToDouble(12.0), numberToDouble(12.0), numberToDouble(12.0), numberToDouble(12.0));
    this_0.dcz(tmp$ret$0);
    this_0.m59($this.hg5() ? Colors_getInstance().r59('#394674') : Colors_getInstance().r59('#999'));
    this_0.rf4(RGBA__withAd_impl_cralao(Colors_getInstance().h4r_1, 0.9));
    this_0.ecz(20.0);
    tmp.rg4_1 = this_0;
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.view.renderableView' call
    var tmp_1 = UIWindow$bg$lambda($this);
    var viewRenderer = new sam$korlibs_korge_view_ViewRenderer$0_2(tmp_1);
    tmp_0.sg4_1 = addTo(RenderableView.edj(size_0, viewRenderer), $this);
    var tmp_2 = $this;
    // Inline function 'korlibs.korge.view.fixedSizeContainer' call
    var size_1 = Size2D.k2t($this.j2y(), $this.hg4_1);
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = FixedSizeContainer.sfh(size_1, false);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fixedSizeContainer.<anonymous>' call
    tmp_2.tg4_1 = addTo(this_1, $this);
    var tmp_3 = $this;
    // Inline function 'korlibs.korge.view.xy' call
    // Inline function 'korlibs.korge.view.textBlock' call
    var this_2 = $this.tg4_1;
    var text = RichTextData.o6x(title);
    var align = Companion_getInstance_4().t5s_1;
    var size_2 = Size2D.f2j(100, 100);
    // Inline function 'korlibs.korge.view.addTo' call
    var this_3 = TextBlock.gd6(text, align, size_2);
    // Inline function 'kotlin.apply' call
    var this_4 = addTo(this_3, this_2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.textBlock.<anonymous>' call
    var tmp$ret$12 = xy_0(this_4, numberToDouble(12), numberToDouble(0));
    tmp_3.ug4_1 = size(tmp$ret$12, $this.j2y(), $this.hg4_1);
    var tmp_4 = $this;
    var tmp209_$receiver = $this.tg4_1;
    var tmp210_icon = UIIcons_getInstance().zf0_1;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var size_3 = Size2D.k2t($this.hg4_1 - $this.ig4_1 * 2, $this.hg4_1 - $this.ig4_1 * 2);
    // Inline function 'kotlin.apply' call
    var this_5 = addTo(UIButton.sd2(size_3, '', tmp210_icon), tmp209_$receiver);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIWindow.closeButton.<anonymous>' call
    this_5.td8(Companion_getInstance_45().i10_1);
    this_5.ad9(false);
    this_5.vd8(MaterialColors_getInstance().c5a_1);
    this_5.xd8(MaterialColors_getInstance().e5a_1);
    // Inline function 'korlibs.korge.input.onClick' call
    var handler = UIWindow$closeButton$slambda_0($this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop = click$factory_4();
    var tmp73_safe_receiver = this_5 == null ? null : get_mouse(this_5);
    if (tmp73_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_5 = prop.get(tmp73_safe_receiver);
      tmp_5.v1x(UIWindow$closeButton$lambda(tmp73_safe_receiver, handler));
    }
    tmp_4.vg4_1 = this_5;
    $this.wg4_1 = plainText$factory($this.ug4_1);
    var tmp_6 = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiScrollable' call
    var size_4 = Size2D.k2t($this.j2y(), $this.k2y() - $this.hg4_1);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.apply' call
    var this_6 = addTo(UIScrollable.ke1(size_4, true), $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    this_6.qdw_1;
    var this_7 = position(this_6, 0.0, $this.hg4_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.UIWindow.container.<anonymous>' call
    this_7.fdx_1 = Colors_getInstance().r59('#161a1d');
    tmp_6.xg4_1 = this_7;
    $this.yg4_1 = true;
    $this.zg4_1 = listOf_0([Companion_getInstance_32().p2g_1, Companion_getInstance_32().b2h(), Companion_getInstance_32().r2g_1, Companion_getInstance_32().d2h(), Companion_getInstance_32().x2g_1, Companion_getInstance_32().e2h(), Companion_getInstance_32().v2g_1, Companion_getInstance_32().c2h()]);
    var tmp_7 = $this;
    // Inline function 'kotlin.collections.map' call
    var this_8 = $this.zg4_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_8, 10));
    var tmp0_iterator = this_8.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ui.UIWindow.scaleHandlers.<anonymous>' call
      var tmp$ret$25 = new ScaleHandler($this, item);
      destination.f(tmp$ret$25);
    }
    tmp_7.ag5_1 = destination;
    var tmp_8 = $this;
    tmp_8.bg5_1 = UIWindow$dragProcessor$lambda;
    var tmp_9 = get_mouse($this).cbi_1;
    tmp_9.w1x(UIWindow$lambda($this));
    draggable($this, $this.tg4_1, false, UIWindow$lambda_0($this));
    $this.yc6();
    return $this;
  }
  hg5() {
    var tmp = this.m8h();
    var tmp208_safe_receiver = this.w15();
    var tmp0_elvis_lhs = tmp208_safe_receiver == null ? null : tmp208_safe_receiver.eca();
    return tmp === ((tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs) - 1 | 0);
  }
  jc7(ctx) {
    ctx.ya8();
    super.jc7(ctx);
    ctx.ya8();
  }
  yc6() {
    size(this.rg4_1, this.j2y(), this.k2y());
    size(this.sg4_1, this.j2y(), this.k2y());
    size(this.tg4_1, this.j2y(), this.hg4_1);
    size(this.xg4_1, this.j2y(), this.k2y() - this.hg4_1);
    position(this.vg4_1, this.j2y() - this.hg4_1 - this.ig4_1, this.ig4_1);
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.ag5_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.ui.UIWindow.onSizeChanged.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      this_0.i1(_unary__edvuaz).kg1(this.j2y(), this.k2y());
    }
  }
  dg5($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_closeAnimated__5v26vt(this, $completion), $completion);
  }
}
class InternalViewAutoscaling {
  constructor() {
    this.kg8_1 = 1.0;
    this.lg8_1 = 1.0;
    this.mg8_1 = 1.0;
    this.ng8_1 = 1.0;
    this.og8_1 = 1.0;
    this.pg8_1 = MatrixTransform.v2o();
  }
  qg8(autoScaling, autoScalingPrecise, globalMatrix) {
    if (autoScaling) {
      this.pg8_1 = globalMatrix.z2n();
      // Inline function 'kotlin.math.abs' call
      var x = this.pg8_1.n2o_1;
      var sx = Math.abs(x);
      // Inline function 'kotlin.math.abs' call
      var x_0 = this.pg8_1.o2o_1;
      var sy = Math.abs(x_0);
      // Inline function 'kotlin.math.max' call
      var sxy = Math.max(sx, sy);
      // Inline function 'kotlin.math.abs' call
      var x_1 = sx / this.mg8_1 - 1.0;
      var diffX = Math.abs(x_1);
      // Inline function 'kotlin.math.abs' call
      var x_2 = sy / this.ng8_1 - 1.0;
      var diffY = Math.abs(x_2);
      var tmp;
      switch (autoScalingPrecise) {
        case true:
          tmp = diffX > 0.0 || diffY > 0.0;
          break;
        case false:
          tmp = diffX >= 0.1 || diffY >= 0.1;
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      var shouldUpdate = tmp;
      if (shouldUpdate) {
        this.mg8_1 = sx;
        this.ng8_1 = sy;
        this.og8_1 = sxy;
        this.kg8_1 = 1.0 / sx;
        this.lg8_1 = 1.0 / sy;
        return true;
      }
    } else {
      this.mg8_1 = 1.0;
      this.ng8_1 = 1.0;
      this.og8_1 = 1.0;
      this.kg8_1 = 1.0;
      this.lg8_1 = 1.0;
    }
    return false;
  }
}
class RectBase extends View {
  static egl(anchor, hitShape, smoothing) {
    anchor = anchor === VOID ? Companion_getInstance_32().p2g_1 : anchor;
    hitShape = hitShape === VOID ? null : hitShape;
    smoothing = smoothing === VOID ? true : smoothing;
    var $this = this.hgn();
    $this.zga_1 = smoothing;
    $this.ogb(hitShape);
    var tmp = $this;
    // Inline function 'korlibs.image.bitmap.Bitmaps.white' call
    tmp.agb_1 = get_Bitmaps_white();
    $this.bgb_1 = anchor;
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.render.Companion.QUAD_INDICES' call
    Companion_getInstance_20();
    var tmp$ret$1 = get_TEXTURED_ARRAY_QUAD_INDICES();
    tmp_0.cgb_1 = new TexturedVertexArray(4, tmp$ret$1);
    $this.dgb_1 = null;
    $this.egb_1 = null;
    return $this;
  }
  ch4(_set____db54di) {
    this.zga_1 = _set____db54di;
  }
  lgb(v) {
    if (!(this.agb_1 === v)) {
      this.agb_1 = v;
      this.abb_1 = true;
      this.hbh();
    }
  }
  tf4(v) {
    if (!this.bgb_1.equals(v)) {
      this.bgb_1 = v;
      this.abb_1 = true;
      this.hbh();
    }
  }
  uf4() {
    return this.bgb_1;
  }
  xg8() {
    return 0.0;
  }
  yg8() {
    return 0.0;
  }
  zca() {
    return this.uf4().y2g_1 * this.xg8();
  }
  acb() {
    return this.uf4().z2g_1 * this.yg8();
  }
  fgb() {
    return -this.zca();
  }
  ggb() {
    return -this.acb();
  }
  jc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    var tmp = this.agb_1;
    // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
    if (tmp === get_Bitmaps_transparent())
      return Unit_instance;
    if (this.abb_1) {
      this.abb_1 = false;
      this.ngb();
    }
    this.mgb(ctx);
  }
  mgb(ctx) {
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    var tmp283_safe_receiver = this.egb_1;
    if (tmp283_safe_receiver == null)
      null;
    else
      tmp283_safe_receiver(ctx);
    var array = this.cgb_1;
    var tex = ctx.fai(this.agb_1).o33();
    var smoothing = this.zga_1;
    var blendMode = this.vcc();
    var program = this.dgb_1;
    var vcount = array.yae_1;
    var icount = array.zae_1;
    var matrix = Companion_getInstance_41().j2m_1;
    $l$block: {
      // Inline function 'korlibs.korge.render.BatchBuilder2D.setStateFast' call
      var tex_0 = tex.wab_1;
      this_0.rae(icount, vcount);
      var pprogram = program == null ? Companion_getInstance_8().yad_1 : program;
      if (this_0.gaf(tex_0, smoothing, blendMode, pprogram)) {
        break $l$block;
      }
      this_0.gae();
      if (!contains_0(this_0.ja8_1, tex_0)) {
        this_0.ia8_1 = 0;
        this_0.ja8_1[0] = tex_0;
      }
      this_0.ka8_1 = smoothing;
      this_0.la8_1 = blendMode;
      this_0.ma8_1 = pprogram;
    }
    this_0.daf(array, matrix, vcount, icount);
  }
  ngb() {
    this.cgb_1.vak(0, this.fgb(), this.ggb(), this.xg8(), this.yg8(), this.ybp(), this.agb_1, this.wcc());
  }
  gc7() {
    return Companion_getInstance_38().e2t(this.fgb(), this.ggb(), this.xg8(), this.yg8());
  }
  toString() {
    var out = super.toString();
    if (!this.uf4().equals(Companion_getInstance_32().p2g_1))
      out = out + (':anchor=(' + this.fcd(this.uf4().y2g_1) + ', ' + this.fcd(this.uf4().z2g_1) + ')');
    return out;
  }
}
class BaseImage extends RectBase {
  static kh4(bitmap, anchor, hitShape, smoothing) {
    anchor = anchor === VOID ? Companion_getInstance_32().p2g_1 : anchor;
    hitShape = hitShape === VOID ? null : hitShape;
    smoothing = smoothing === VOID ? true : smoothing;
    var $this = this.egl(anchor, hitShape, smoothing);
    $this.jfy_1 = false;
    $this.kfy_1 = bitmap;
    $this.igb();
    return $this;
  }
  static sg8(bmpCoords, anchor, hitShape, smoothing) {
    anchor = anchor === VOID ? Companion_getInstance_32().p2g_1 : anchor;
    hitShape = hitShape === VOID ? null : hitShape;
    smoothing = smoothing === VOID ? true : smoothing;
    return this.kh4(Resourceable(bmpCoords), anchor, hitShape, smoothing);
  }
  lfy(value) {
    if (equals(this.agb_1, value))
      return Unit_instance;
    this.jfy_1 = true;
    this.lgb(value);
  }
  j6l() {
    return this.agb_1;
  }
  igb() {
    if (this.jfy_1)
      return Unit_instance;
    trySetSourceActually(this);
  }
  jc7(ctx) {
    this.igb();
    super.jc7(ctx);
  }
  xg8() {
    return this.agb_1.j2y();
  }
  yg8() {
    return this.agb_1.k2y();
  }
  k43() {
    return this.agb_1.k43();
  }
  l43() {
    return this.agb_1.l43();
  }
  q42() {
    return this.agb_1.q42();
  }
  r42() {
    return this.agb_1.r42();
  }
  jgb() {
    return this.uf4().y2g_1 * this.q42();
  }
  kgb() {
    return this.uf4().z2g_1 * this.r42();
  }
  zca() {
    return this.jgb() - this.k43();
  }
  acb() {
    return this.kgb() - this.l43();
  }
  gc7() {
    return Companion_getInstance_38().e2t(-this.jgb(), -this.kgb(), this.q42(), this.r42());
  }
  toString() {
    return super.toString() + (':bitmap=' + toString(this.j6l()));
  }
}
class BaseGraphics extends BaseImage {
  static rg8(autoScaling) {
    autoScaling = autoScaling === VOID ? false : autoScaling;
    // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
    var tmp$ret$0 = get_Bitmaps_transparent();
    var $this = this.sg8(tmp$ret$0);
    $this.tg7_1 = autoScaling;
    $this.ug7_1 = true;
    $this.vg7_1 = false;
    $this.wg7_1 = false;
    var tmp = $this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.xg7_1 = ArrayList.m1();
    $this.yg7_1 = new InternalViewAutoscaling();
    $this.zg7_1 = 1;
    $this.ag8_1 = true;
    $this.bg8_1 = true;
    $this.cg8_1 = null;
    $this.dg8_1 = 1.0;
    $this.eg8_1 = 1.0;
    $this.fg8_1 = true;
    $this.gg8_1 = true;
    $this.hg8_1 = Companion_getInstance_38().l2m();
    $this.ig8_1 = Companion_getInstance_38().l2m();
    return $this;
  }
  tg8(value) {
    this.ug7_1 = value;
    this.ug8();
  }
  ug8() {
    this.ag8_1 = true;
    this.bg8_1 = true;
    this.ycc();
  }
  hc7() {
    super.hc7();
    if (this.w15() == null || this.pbj() == null) {
      var tmp = this.j6l().o33();
      // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
      var tmp$ret$0 = get_Bitmaps_transparent();
      if (!equals(tmp, get_bmp(tmp$ret$0))) {
        this.xg7_1.f(this.j6l().o33());
      }
      clearBitmaps(this, true);
    }
  }
  jc7(ctx) {
    this.cg8_1 = ctx;
    clearBitmaps(this, false);
    this.vg8();
    super.jc7(ctx);
  }
  vg8() {
    if (this.yg7_1.qg8(this.tg7_1, this.vg7_1, this.ybp())) {
      this.ag8_1 = true;
    }
    if (!this.ag8_1)
      return false;
    this.ag8_1 = false;
    var boundsWithShapes = boundsUnsafe(this, true);
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.BaseGraphics.redrawIfRequired.<anonymous>' call
    this.xg7_1.f(this.j6l().o33());
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var imageWidth = coerceAtLeast(toIntCeil(boundsWithShapes.k2i_1 * this.yg7_1.mg8_1), 1);
    var imageHeight = coerceAtLeast(toIntCeil(boundsWithShapes.l2i_1 * this.yg7_1.ng8_1), 1);
    var image = NativeImageOrBitmap32(imageWidth + this.zg7_1 | 0, imageHeight + this.zg7_1 | 0, this.ug7_1, true);
    this.dg8_1 = this.yg7_1.mg8_1;
    this.eg8_1 = this.yg7_1.ng8_1;
    // Inline function 'korlibs.image.bitmap.context2d' call
    // Inline function 'korlibs.image.bitmap.Bitmap.lock' call
    var rect = image.i4p_1;
    if (true) {
      image.w1w();
    }
    var result;
    try {
      // Inline function 'korlibs.image.bitmap.context2d.<anonymous>' call
      var ctx = image.y4x(true);
      try {
        // Inline function 'korlibs.korge.view.BaseGraphics.redrawIfRequired.<anonymous>.<anonymous>' call
        ctx.o6e(this.dg8_1, this.eg8_1);
        ctx.m5w(-boundsWithShapes.i2i_1, -boundsWithShapes.j2i_1);
        this.wg8(ctx);
      }finally {
        ctx.o18();
      }
    }finally {
      result = true ? image.q4q(rect) : 0;
    }
    this.lfy(slice(image));
    return true;
  }
  xg8() {
    return _get_bitmapWidth__ndy7bu(this) / this.dg8_1;
  }
  yg8() {
    return _get_bitmapHeight__5t1vx1(this) / this.eg8_1;
  }
  q42() {
    return this.xg8();
  }
  r42() {
    return this.yg8();
  }
  zca() {
    return this.uf4().y2g_1 * this.xg8();
  }
  acb() {
    return this.uf4().z2g_1 * this.yg8();
  }
  fgb() {
    return _getLocalBoundsInternal(this, this.fg8_1).i2i_1;
  }
  ggb() {
    return _getLocalBoundsInternal(this, this.fg8_1).j2i_1;
  }
  gc7() {
    return _getLocalBoundsInternal$default(this);
  }
  hgb(value) {
    this.gg8_1 = value;
    this.ycc();
  }
}
class CacheTexture {
  constructor($outer, ctx) {
    this.vgb_1 = $outer;
    this.rgb_1 = ctx;
    this.sgb_1 = this.rgb_1.kai(16, 16, VOID, VOID, VOID, false);
    this.tgb_1 = TextureBase.zab(this.sgb_1.n8p(), 16, 16);
    this.ugb_1 = Texture(this.tgb_1);
  }
  wgb(width, height) {
    this.sgb_1.p8p(0, 0, width, height);
    this.tgb_1.aac(width);
    this.tgb_1.bac(height);
    this.ugb_1 = Texture(this.tgb_1);
  }
  u6() {
    this.vgb_1.uc9_1 = null;
    this.rgb_1.lai(this.sgb_1);
  }
}
class Camera extends Container {
  static fge() {
    return this.vbw();
  }
  bc7(_) {
    return Unit_instance;
  }
  cc7() {
    var tmp220_safe_receiver = this.fca();
    var tmp0_elvis_lhs = tmp220_safe_receiver == null ? null : tmp220_safe_receiver.j2y();
    var tmp = tmp0_elvis_lhs == null ? 100.0 : tmp0_elvis_lhs;
    var tmp221_safe_receiver = this.fca();
    var tmp1_elvis_lhs = tmp221_safe_receiver == null ? null : tmp221_safe_receiver.k2y();
    return Size2D.k2t(tmp, tmp1_elvis_lhs == null ? 100.0 : tmp1_elvis_lhs);
  }
  gc7() {
    return new Rectangle(0.0, 0.0, this.j2y(), this.k2y());
  }
  gge(rect) {
    var destinationBounds = rect;
    var tmp222_safe_receiver = this.w15();
    var tmp0_elvis_lhs = tmp222_safe_receiver == null ? null : tmp222_safe_receiver.ybp();
    return (tmp0_elvis_lhs == null ? Companion_getInstance_41().l2m() : tmp0_elvis_lhs).q2n(-destinationBounds.i2i_1, -destinationBounds.j2i_1).k2n(this.j2y() / destinationBounds.k2i_1, this.k2y() / destinationBounds.l2i_1);
  }
  hge(rect) {
    this.ige(this.gge(rect));
  }
}
class ShapeView extends Container {
  static je1(shape, fill, stroke, strokeThickness, autoScaling, renderer) {
    shape = shape === VOID ? null : shape;
    fill = fill === VOID ? new RGBA(Colors_getInstance().g4r_1) : fill;
    stroke = stroke === VOID ? new RGBA(Colors_getInstance().g4r_1) : stroke;
    strokeThickness = strokeThickness === VOID ? 1.0 : strokeThickness;
    autoScaling = autoScaling === VOID ? true : autoScaling;
    renderer = renderer === VOID ? GraphicsRenderer_GPU_getInstance() : renderer;
    var $this = this.vbw();
    $this.xe0_1 = addTo(Graphics.zh3(VOID, renderer), $this);
    $this.xe0_1.dh4(autoScaling);
    $this.ye0_1 = anchor$factory($this.xe0_1);
    $this.ze0_1 = antialiased$factory($this.xe0_1);
    $this.ae1_1 = autoScaling$factory($this.xe0_1);
    $this.be1_1 = renderer$factory($this.xe0_1);
    $this.ce1_1 = smoothing$factory($this.xe0_1);
    $this.de1_1 = boundsIncludeStrokes$factory($this.xe0_1);
    $this.ee1_1 = shape;
    $this.fe1_1 = fill;
    $this.ge1_1 = stroke;
    $this.he1_1 = numberToDouble(strokeThickness);
    $this.ggh();
    $this.ie1_1 = new VectorPath();
    return $this;
  }
  tf4(_set____db54di) {
    var this_0 = this.ye0_1;
    anchor$factory_0();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  uf4() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ye0_1;
    anchor$factory_1();
    return this_0.get();
  }
  zca() {
    return this.xe0_1.zca();
  }
  acb() {
    return this.xe0_1.acb();
  }
  v1z() {
    return this.ee1_1;
  }
  ggh() {
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var this_0 = this.xe0_1;
    var result;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.apply' call
    var this_1 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var shapeView = this;
    var shape = shapeView.v1z();
    if (!(shape == null) && shape.y2k()) {
      if (!(shapeView.he1_1 === 0.0)) {
        // Inline function 'korlibs.image.vector.Context2d.fillStroke' call
        var fill = shapeView.fe1_1;
        var stroke = shapeView.ge1_1;
        var strokeInfo = new StrokeInfo(shapeView.he1_1);
        // Inline function 'korlibs.korge.view.ShapeView._updateShapeGraphics.<anonymous>.<anonymous>' call
        this_1.n6e(shape);
        if (!(fill == null)) {
          this_1.n5u(fill);
        }
        if (!(stroke == null)) {
          if (!(strokeInfo == null)) {
            $l$block: {
              // Inline function 'korlibs.image.vector.Context2d.stroke' call
              if (stroke == null || strokeInfo == null) {
                break $l$block;
              }
              // Inline function 'korlibs.image.vector.Context2d.stroke' call
              var lineWidth = strokeInfo.n3s_1;
              var lineCap = strokeInfo.q3s_1;
              var lineJoin = strokeInfo.s3s_1;
              var miterLimit = strokeInfo.t3s_1;
              var lineDash = strokeInfo.u3s_1;
              var lineDashOffset = strokeInfo.v3s_1;
              if (false) {
                this_1.b5t();
              }
              // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
              // Inline function 'korlibs.image.vector.Context2d.keep' call
              this_1.u5u();
              try {
                // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
                this_1.v5u(numberToDouble(lineWidth));
                this_1.w5u(lineCap);
                this_1.x5u(lineJoin);
                this_1.y5u(numberToDouble(miterLimit));
                this_1.z5u(lineDash);
                this_1.a5v(numberToDouble(lineDashOffset));
                this_1.b5v(stroke);
              }finally {
                this_1.c5v();
              }
            }
          } else {
            // Inline function 'korlibs.image.vector.Context2d.stroke' call
            var lineWidth_0 = this_1.o5u();
            var lineCap_0 = this_1.p5u();
            var lineJoin_0 = this_1.q5u();
            var miterLimit_0 = this_1.r5u();
            var lineDash_0 = this_1.s5u();
            var lineDashOffset_0 = this_1.t5u();
            if (false) {
              this_1.b5t();
            }
            // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
            // Inline function 'korlibs.image.vector.Context2d.keep' call
            this_1.u5u();
            try {
              // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
              this_1.v5u(numberToDouble(lineWidth_0));
              this_1.w5u(lineCap_0);
              this_1.x5u(lineJoin_0);
              this_1.y5u(numberToDouble(miterLimit_0));
              this_1.z5u(lineDash_0);
              this_1.a5v(numberToDouble(lineDashOffset_0));
              this_1.b5v(stroke);
            }finally {
              this_1.c5v();
            }
          }
        }
      } else {
        // Inline function 'korlibs.image.vector.Context2d.fill' call
        var paint = shapeView.fe1_1;
        if (true) {
          this_1.b5t();
        }
        // Inline function 'korlibs.korge.view.ShapeView._updateShapeGraphics.<anonymous>.<anonymous>' call
        this_1.n6e(shape);
        this_1.x76(paint, null);
      }
    }
    result = Unit_instance;
    var tmp$ret$4 = this_1.p5r();
    this_0.xh3(tmp$ret$4);
  }
}
class Circle_0 extends ShapeView {
  static hgh(radius, fill, stroke, strokeThickness, autoScaling, renderer) {
    radius = radius === VOID ? 16.0 : radius;
    fill = fill === VOID ? new RGBA(Colors_getInstance().g4r_1) : fill;
    stroke = stroke === VOID ? new RGBA(Colors_getInstance().g4r_1) : stroke;
    strokeThickness = strokeThickness === VOID ? 0.0 : strokeThickness;
    autoScaling = autoScaling === VOID ? true : autoScaling;
    renderer = renderer === VOID ? GraphicsRenderer_GPU_getInstance() : renderer;
    var $this = this.je1(new VectorPath(), fill, stroke, strokeThickness, autoScaling, renderer);
    var tmp = $this;
    // Inline function 'korlibs.korge.ui.uiObservable' call
    var value = numberToDouble(radius);
    var observe = Circle$radius$delegate$lambda($this);
    tmp.dgh_1 = new UIObservable(value, observe);
    $this.egh_1 = colorMul$factory($this);
    updateGraphics($this);
    return $this;
  }
  ud8(_set____db54di) {
    return this.dgh_1.i3e(this, radius$factory(), _set____db54di);
  }
  xcv() {
    return this.dgh_1.h3e(this, radius$factory_0());
  }
}
class FixedSizeContainer extends Container {
  static sfh(size, clip) {
    size = size === VOID ? Size2D.f2j(100, 100) : size;
    clip = clip === VOID ? false : clip;
    var $this = this.vbw();
    $this.pfh_1 = clip;
    $this.qfh_1 = size;
    $this.rfh_1 = Ref(false);
    return $this;
  }
  t78() {
    return this.pfh_1;
  }
  bc7(_set____db54di) {
    this.qfh_1 = _set____db54di;
  }
  cc7() {
    return this.qfh_1;
  }
  gc7() {
    return new Rectangle(0.0, 0.0, this.j2y(), this.k2y());
  }
  toString() {
    var out = super.toString();
    out = out + (':size=(' + get_niceStr(this.j2y()) + 'x' + get_niceStr(this.k2y()) + ')');
    return out;
  }
  jc7(ctx) {
    $l$block_0: {
      // Inline function 'korlibs.korge.view.Companion.renderClipped' call
      var clip = this.t78();
      var renderingInternalRef = this.rfh_1;
      if (renderingInternalRef.b2()) {
        super.jc7(ctx);
        break $l$block_0;
      }
      if (clip) {
        var m = this.ybp();
        var hasRotation = !(m.o2m_1 === 0.0) || !(m.p2m_1 === 0.0);
        if (hasRotation) {
          var old = renderingInternalRef.b2();
          try {
            renderingInternalRef.n3a(true);
            renderFiltered(this, ctx, Companion_getInstance_80());
          }finally {
            renderingInternalRef.n3a(old);
          }
          break $l$block_0;
        }
        // Inline function 'korlibs.korge.render.RenderContext.useCtx2d' call
        // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
        var batcher = ctx.ka7_1;
        if (!(ctx.oa7_1 === batcher)) {
          ctx.oa7_1 = batcher;
          ctx.ya8();
        }
        // Inline function 'korlibs.korge.render.RenderContext.useCtx2d.<anonymous>' call
        // Inline function 'korlibs.korge.view.Companion.renderClipped.<anonymous>' call
        var c2d = ctx.ma7_1;
        var bounds = this.qcd(ctx).j2u(ctx.aa7_1).e2s();
        var rect = c2d.qai_1.mae().x8v();
        var intersects = true;
        if (!(rect == null)) {
          intersects = bounds.v2t(rect);
        }
        if (intersects) {
          // Inline function 'korlibs.korge.render.RenderContext2D.scissor' call
          var scissor = Companion_getInstance_22().s8v(bounds);
          $l$block_1: {
            // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
            var this_0 = c2d.qai_1;
            var scissor_0 = c2d.daj(scissor);
            var temp = this_0.ra8_1;
            this_0.eaf();
            this_0.ra8_1 = scissor_0;
            try {
              super.jc7(ctx);
              break $l$block_1;
            }finally {
              this_0.eaf();
              this_0.ra8_1 = temp;
            }
          }
        } else {
          // Inline function 'korlibs.korge.view.FixedSizeContainer.renderInternal.<anonymous>' call
          super.jc7(ctx);
        }
      } else {
        // Inline function 'korlibs.korge.view.FixedSizeContainer.renderInternal.<anonymous>' call
        super.jc7(ctx);
      }
    }
  }
}
class ClipContainer extends FixedSizeContainer {
  static mfp(size) {
    size = size === VOID ? Size2D.f2j(100, 100) : size;
    return this.sfh(size, true);
  }
}
class ZIndexComparator {
  igh(a, b) {
    return compareTo(a.ncb(), b.ncb());
  }
  compare(a, b) {
    var tmp = a instanceof View ? a : THROW_CCE();
    return this.igh(tmp, b instanceof View ? b : THROW_CCE());
  }
}
class ContainerCollection$iterator$1 {
  constructor(this$0) {
    this.ogh_1 = this$0;
    this.ngh_1 = 0;
  }
  g1() {
    return this.ngh_1 < this.ogh_1.lgh_1.eca();
  }
  h1() {
    var _unary__edvuaz = this.ngh_1;
    this.ngh_1 = _unary__edvuaz + 1 | 0;
    return this.ogh_1.lgh_1.kca(_unary__edvuaz);
  }
  y6() {
    this.ngh_1 = this.ngh_1 - 1 | 0;
    this.ogh_1.lgh_1.mca(this.ngh_1);
  }
}
class ContainerCollection {
  constructor(container, children) {
    this.kgh_1 = children;
    this.lgh_1 = container;
  }
  i1(index) {
    return this.kgh_1.i1(index);
  }
  pgh(element) {
    return this.kgh_1.q1(element);
  }
  q1(element) {
    if (!(element instanceof View))
      return -1;
    return this.pgh(element instanceof View ? element : THROW_CCE());
  }
  h3() {
    return this.kgh_1.h3();
  }
  p1(index) {
    return this.kgh_1.p1(index);
  }
  n1(fromIndex, toIndex) {
    return this.kgh_1.n1(fromIndex, toIndex);
  }
  j1() {
    return this.lgh_1.eca();
  }
  qgh(element) {
    return element.w15() === this.lgh_1;
  }
  f3(element) {
    if (!(element instanceof View))
      return false;
    return this.qgh(element instanceof View ? element : THROW_CCE());
  }
  rgh(elements) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = elements.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.view.ContainerCollection.containsAll.<anonymous>' call
        if (!(element.w15() === this.lgh_1)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
  g3(elements) {
    return this.rgh(elements);
  }
  a1() {
    return this.lgh_1.eca() === 0;
  }
  sgh(element) {
    this.lgh_1.oca(element);
    return true;
  }
  f(element) {
    return this.sgh(element instanceof View ? element : THROW_CCE());
  }
  tgh(elements) {
    if (elements.a1())
      return false;
    var _iterator__ex2g4s = elements.f1();
    while (_iterator__ex2g4s.g1()) {
      var element = _iterator__ex2g4s.h1();
      this.lgh_1.oca(element);
    }
    return true;
  }
  k1(elements) {
    return this.tgh(elements);
  }
  f1() {
    return new ContainerCollection$iterator$1(this);
  }
  ugh(element) {
    return this.lgh_1.xca(element);
  }
  n3(element) {
    if (!(element instanceof View))
      return false;
    return this.ugh(element instanceof View ? element : THROW_CCE());
  }
}
class CpuGraphics extends BaseGraphics {
  static bgl(autoScaling) {
    autoScaling = autoScaling === VOID ? false : autoScaling;
    var $this = this.rg8(autoScaling);
    $this.tgk_1 = 0;
    $this.ugk_1 = null;
    $this.vgk_1 = true;
    $this.wgk_1 = -1;
    $this.xgk_1 = -1;
    var tmp = $this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.ygk_1 = ArrayList.m1();
    $this.zgk_1 = null;
    $this.agl_1 = null;
    $this.kbb_1 = true;
    return $this;
  }
  cgl(value) {
    this.ugk_1 = value;
    this.tgk_1 = this.tgk_1 + 1 | 0;
    this.ug8();
  }
  ogb(value) {
    var tmp = this;
    var tmp_0;
    if (value == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.CpuGraphics.<set-hitShape>.<anonymous>' call
      tmp_0 = listOf(value);
    }
    tmp.agl_1 = tmp_0;
  }
  bcb() {
    var tmp234_safe_receiver = this.ccb();
    return tmp234_safe_receiver == null ? null : firstOrNull_0(tmp234_safe_receiver);
  }
  ccb() {
    if (!(this.agl_1 == null))
      return this.agl_1;
    if (!(this.wgk_1 === this.tgk_1)) {
      this.wgk_1 = this.tgk_1;
      this.ygk_1.o3();
      var tmp = this.ygk_1;
      var tmp236_safe_receiver = this.ugk_1;
      var tmp0_elvis_lhs = tmp236_safe_receiver == null ? null : tmp236_safe_receiver.y5x();
      tmp.f(tmp0_elvis_lhs == null ? new VectorPath() : tmp0_elvis_lhs);
    }
    return this.ygk_1;
  }
  wg8(ctx) {
    var tmp237_safe_receiver = this.ugk_1;
    if (tmp237_safe_receiver == null)
      null;
    else {
      tmp237_safe_receiver.f5x(ctx);
    }
  }
  jg8(includeStrokes) {
    var tmp238_safe_receiver = this.ugk_1;
    var tmp0_elvis_lhs = tmp238_safe_receiver == null ? null : tmp238_safe_receiver.l78(includeStrokes);
    return tmp0_elvis_lhs == null ? Companion_getInstance_38().u2i_1 : tmp0_elvis_lhs;
  }
}
class CustomContextRenderizableView extends RectBase {
  static dgl(size) {
    var $this = this.egl();
    $this.hdg_1 = size;
    return $this;
  }
  bc7(value) {
    if (!this.hdg_1.equals(value)) {
      this.hdg_1 = value;
      this.abb_1 = true;
    }
  }
  cc7() {
    return this.hdg_1;
  }
  d7i() {
    var tmp = this.idg_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('ctx');
    }
  }
  ofp() {
    var tmp = this.jdg_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('ctx2d');
    }
  }
  jc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    this.idg_1 = ctx;
    // Inline function 'korlibs.korge.render.renderCtx2d' call
    // Inline function 'korlibs.korge.render.RenderContext.useCtx2d' call
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    var batcher = ctx.ka7_1;
    if (!(ctx.oa7_1 === batcher)) {
      ctx.oa7_1 = batcher;
      ctx.ya8();
    }
    // Inline function 'korlibs.korge.render.RenderContext.useCtx2d.<anonymous>' call
    // Inline function 'korlibs.korge.render.renderCtx2d.<anonymous>' call
    var context = ctx.ma7_1;
    // Inline function 'korlibs.korge.render.RenderContext2D.keep' call
    $l$block_3: {
      // Inline function 'korlibs.korge.render.RenderContext2D.keepMatrix' call
      var old = context.uai_1;
      try {
        // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>' call
        $l$block_2: {
          // Inline function 'korlibs.korge.render.RenderContext2D.keepBlendMode' call
          var oldBlendFactors = context.vai_1;
          try {
            // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>' call
            $l$block_1: {
              // Inline function 'korlibs.korge.render.RenderContext2D.keepColor' call
              var multiplyColor = context.wai_1;
              try {
                // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>' call
                $l$block_0: {
                  // Inline function 'korlibs.korge.render.RenderContext2D.keepFiltering' call
                  var filtering = context.xai_1;
                  try {
                    // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                    $l$block: {
                      // Inline function 'korlibs.korge.render.RenderContext2D.keepSize' call
                      var size = context.sai_1;
                      try {
                        // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                        context.sai_1 = Size2D.k2t(this.j2y(), this.k2y());
                        context.vai_1 = this.vcc();
                        context.wai_1 = this.wcc();
                        context.yai(this.ybp());
                        this.jdg_1 = context;
                        this.fgl(context, Size2D.k2t(this.j2y(), this.k2y()));
                        break $l$block;
                      }finally {
                        context.sai_1 = size;
                      }
                    }
                    break $l$block_0;
                  }finally {
                    context.xai_1 = filtering;
                  }
                }
                break $l$block_1;
              }finally {
                context.wai_1 = multiplyColor;
              }
            }
            break $l$block_2;
          }finally {
            context.vai_1 = oldBlendFactors;
          }
        }
        break $l$block_3;
      }finally {
        context.uai_1 = old;
      }
    }
  }
}
class DummyView extends View {
  static ggn() {
    return this.hgn();
  }
  jc7(ctx) {
    return Unit_instance;
  }
}
class Ellipse extends ShapeView {
  dgq(_set____db54di) {
    return this.cgq_1.i3e(this, radius$factory_1(), _set____db54di);
  }
  xcv() {
    return this.cgq_1.h3e(this, radius$factory_2());
  }
  egq() {
    return this.xcv().d2j_1 === this.xcv().e2j_1;
  }
}
class ShadedView extends RectBase {
  static ygx(program, size, coordsType) {
    size = size === VOID ? Size2D.f2t(100.0, 100.0) : size;
    coordsType = coordsType === VOID ? CoordsType_D_0_1_getInstance() : coordsType;
    var $this = this.egl(Companion_getInstance_32().p2g_1);
    $this.lgx_1 = size;
    $this.dgb_1 = program;
    $this.mgx_1 = Companion_getInstance_37().p2l_1;
    $this.ngx_1 = coordsType;
    return $this;
  }
  bc7(value) {
    if (this.lgx_1.equals(value))
      return Unit_instance;
    this.lgx_1 = value;
    this.abb_1 = true;
  }
  cc7() {
    return this.lgx_1;
  }
  xg8() {
    return this.j2y();
  }
  yg8() {
    return this.k2y();
  }
  ngb() {
    var L = this.fgb() - this.mgx_1.t2l_1;
    var T = this.ggb() - this.mgx_1.q2l_1;
    var R = this.xg8() + this.mgx_1.x2l();
    var B = this.yg8() + this.mgx_1.y2l();
    var l = -this.mgx_1.t2l_1;
    var t = -this.mgx_1.q2l_1;
    var r = this.xg8() + this.mgx_1.r2l_1;
    var b = this.yg8() + this.mgx_1.s2l_1;
    var tmp;
    switch (this.ngx_1.v3_1) {
      case 0:
        tmp = 0.0;
        break;
      case 1:
        tmp = -1.0;
        break;
      case 2:
        tmp = 0.0;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    var lmin = tmp;
    var lmax = 1.0;
    switch (this.ngx_1.v3_1) {
      case 0:
      case 1:
        l = convertRange_0(l, 0.0, this.xg8(), lmin, lmax);
        r = convertRange_0(r, 0.0, this.xg8(), lmin, lmax);
        t = convertRange_0(t, 0.0, this.yg8(), lmin, lmax);
        b = convertRange_0(b, 0.0, this.yg8(), lmin, lmax);
        break;
      default:
        break;
    }
    this.cgb_1.caj(0, L, T, R, B, this.ybp(), l, t, r, t, l, b, r, b, this.wcc());
  }
  ogx(ctx) {
  }
  jc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    this.ogx(ctx);
    super.jc7(ctx);
  }
}
class FastRoundRectBase extends ShadedView {
  static cgv(size, cornersRatio, doScale) {
    Companion_getInstance_62();
    size = size === VOID ? Size2D.f2t(100.0, 100.0) : size;
    var tmp;
    if (cornersRatio === VOID) {
      // Inline function 'korlibs.math.geom.Companion.invoke' call
      Companion_getInstance_36();
      tmp = new RectCorners(numberToDouble(0.0), numberToDouble(0.0), numberToDouble(0.0), numberToDouble(0.0));
    } else {
      tmp = cornersRatio;
    }
    cornersRatio = tmp;
    doScale = doScale === VOID ? true : doScale;
    var $this = this.ygx(Companion_getInstance_62().xgx_1, size);
    $this.agv_1 = cornersRatio;
    $this.bgv_1 = doScale;
    return $this;
  }
  jc7(ctx) {
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.y8r(SDFUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.FastRoundRectBase.renderInternal.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.l9i($this$push.vgx(), this.agv_1);
      it.g9i($this$push.wgx(), !this.bgv_1 || this.j2y() === this.k2y() ? Vector2D.k2h(1.0, 1.0) : this.j2y() > this.k2y() ? Vector2D.k2h(this.j2y() / this.k2y(), 1.0) : Vector2D.k2h(1.0, this.k2y() / this.j2y()));
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    super.jc7(ctx);
  }
}
class FastEllipse extends FastRoundRectBase {
  static qgs(size) {
    size = size === VOID ? Size2D.f2t(100.0, 100.0) : size;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp$ret$0 = new RectCorners(numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0));
    return this.cgv(size, tmp$ret$0, false);
  }
  dgq(value) {
    size_0(this, value.e2r(2.0));
  }
  xcv() {
    return Size2D.k2t(this.j2y(), this.k2y()).z2v(2.0);
  }
  dgv(value) {
    this.dgq(Size2D.k2t(value, value));
  }
  egv() {
    return this.xcv().u2v();
  }
}
class SDFUB extends UniformBlock {
  constructor() {
    SDFUB_instance = null;
    super(6);
    SDFUB_instance = this;
    this.tgx_1 = this.b90().p8z(this, u_Corners$factory());
    this.ugx_1 = this.z8z().p8z(this, u_Scale$factory());
  }
  vgx() {
    return this.tgx_1.ko(this, u_Corners$factory_0());
  }
  wgx() {
    return this.ugx_1.ko(this, u_Scale$factory_0());
  }
}
class Companion_14 {
  constructor() {
    Companion_instance_29 = this;
    var tmp = this;
    // Inline function 'korlibs.korge.view.Companion.buildShader' call
    var tmp292_$this = Companion_getInstance_8().yad_1;
    var tmp293_name = null == null ? Companion_getInstance_8().yad_1.k8s_1 : null;
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Companion.buildShader.<anonymous>' call
    // Inline function 'korlibs.korge.view.Companion.PROGRAM.<anonymous>' call
    var SDF = SDFShaders_getInstance();
    this_0.f92(this_0.i92(), this_0.j92(this_0.g92(), this_0.i93(SDF.eak(), this_0.k93(SDF.kak(), this_0.j92(this_0.g95(this_0.b92(), this_0.v94(new Float32Array([0.5, 0.5]))), SDFUB_getInstance().wgx()), this_0.j92(this_0.v94(new Float32Array([0.5, 0.5])), SDFUB_getInstance().wgx()), this_0.j92(SDFUB_getInstance().vgx(), this_0.l92(0.5))))));
    var tmp294_fragment = FragmentShader(this_0.n92());
    tmp.xgx_1 = tmp292_$this.c9r(VOID, tmp294_fragment, tmp293_name);
  }
}
class Companion_15 {}
class SContainer extends FixedSizeContainer {
  static kbz(size, clip) {
    size = size === VOID ? Size2D.f2j(100, 100) : size;
    clip = clip === VOID ? false : clip;
    return this.sfh(size, clip);
  }
}
class TimeSlidingWindow {
  constructor(capacity) {
    this.agy_1 = capacity;
    this.bgy_1 = IntDeque.l35(this.agy_1);
    this.cgy_1 = 0;
  }
  j1() {
    return this.bgy_1.j1();
  }
  dgy() {
    return this.bgy_1.j1() === 0 ? get_fastSeconds(0) : get_fastMicroseconds(this.cgy_1 / this.bgy_1.j1());
  }
  egy() {
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$1 = toDuration(1, DurationUnit_SECONDS_getInstance());
    return div(tmp$ret$1, this.dgy());
  }
  fgy(index) {
    return get_fastMicroseconds_0(this.bgy_1.i1(index));
  }
  ggy(value) {
    var intValue = numberToInt(_FastDuration___get_fastMicroseconds__impl__e641g1(value));
    this.bgy_1.q35(intValue);
    this.cgy_1 = this.cgy_1 + intValue | 0;
    if (this.bgy_1.j1() > this.agy_1) {
      this.cgy_1 = this.cgy_1 - this.bgy_1.nl() | 0;
    }
  }
}
class GraphicsRenderer extends Enum {}
class Graphics extends Container {
  static zh3(shape, renderer) {
    shape = shape === VOID ? EmptyShape_instance : shape;
    renderer = renderer === VOID ? GraphicsRenderer_GPU_getInstance() : renderer;
    var $this = this.vbw();
    $this.ph0_1 = null;
    $this.qh0_1 = null;
    $this.rh0_1 = true;
    $this.sh0_1 = false;
    $this.th0_1 = true;
    $this.uh0_1 = true;
    $this.vh0_1 = EmptyShape_instance;
    $this.wh0_1 = renderer;
    $this.xh3(shape);
    return $this;
  }
  ah4() {
    var tmp0_elvis_lhs = this.ph0_1;
    return ensureNotNull(tmp0_elvis_lhs == null ? this.qh0_1 : tmp0_elvis_lhs);
  }
  hgb(value) {
    var tmp246_safe_receiver = this.ph0_1;
    if (tmp246_safe_receiver == null)
      null;
    else {
      tmp246_safe_receiver.hgb(value);
    }
    var tmp247_safe_receiver = this.qh0_1;
    if (tmp247_safe_receiver == null)
      null;
    else {
      tmp247_safe_receiver.hgb(value);
    }
    this.hbh();
    this.ycc();
  }
  bh4() {
    var tmp244_safe_receiver = this.ph0_1;
    var tmp0_elvis_lhs = tmp244_safe_receiver == null ? null : tmp244_safe_receiver.gg8_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp245_safe_receiver = this.qh0_1;
      tmp = tmp245_safe_receiver == null ? null : tmp245_safe_receiver.ah3_1;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var tmp1_elvis_lhs = tmp;
    return tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
  }
  tf4(value) {
    _get_anchorable__ebfoik(this).tf4(value);
    this.hbh();
  }
  uf4() {
    return _get_anchorable__ebfoik(this).uf4();
  }
  zca() {
    return this.ah4().zca();
  }
  acb() {
    return this.ah4().acb();
  }
  yh3(value) {
    if (this.rh0_1 === value)
      return Unit_instance;
    this.rh0_1 = value;
    var tmp248_safe_receiver = this.ph0_1;
    if (tmp248_safe_receiver == null)
      null;
    else {
      tmp248_safe_receiver.vgk_1 = value;
    }
    var tmp249_safe_receiver = this.qh0_1;
    if (tmp249_safe_receiver == null)
      null;
    else {
      tmp249_safe_receiver.yh3(value);
    }
    this.hbh();
  }
  ch4(value) {
    if (this.th0_1 === value)
      return Unit_instance;
    this.th0_1 = value;
    var tmp251_safe_receiver = this.ph0_1;
    if (tmp251_safe_receiver == null)
      null;
    else {
      tmp251_safe_receiver.zga_1 = value;
    }
    var tmp252_safe_receiver = this.qh0_1;
    if (tmp252_safe_receiver == null)
      null;
    else {
      tmp252_safe_receiver.zh2_1 = value;
    }
    this.hbh();
  }
  dh4(value) {
    if (this.uh0_1 === value)
      return Unit_instance;
    this.uh0_1 = value;
    var tmp253_safe_receiver = this.ph0_1;
    if (tmp253_safe_receiver == null)
      null;
    else {
      tmp253_safe_receiver.tg7_1 = value;
    }
    var tmp254_safe_receiver = this.qh0_1;
    if (tmp254_safe_receiver == null)
      null;
    else {
      tmp254_safe_receiver.xh2_1 = value;
    }
    this.hbh();
  }
  xh3(value) {
    if (this.vh0_1 === value)
      return Unit_instance;
    this.vh0_1 = value;
    ensure_0(this);
    var tmp255_safe_receiver = this.ph0_1;
    if (tmp255_safe_receiver == null)
      null;
    else {
      tmp255_safe_receiver.cgl(value);
    }
    var tmp256_safe_receiver = this.qh0_1;
    if (tmp256_safe_receiver == null)
      null;
    else {
      tmp256_safe_receiver.xh3(value);
    }
    this.hbh();
  }
  eh4(value) {
    if (this.wh0_1 === value)
      return Unit_instance;
    this.wh0_1 = value;
    this.fh4();
  }
  fh4() {
    ensure_0(this);
    var tmp257_safe_receiver = this.ph0_1;
    if (tmp257_safe_receiver == null)
      null;
    else {
      tmp257_safe_receiver.ug8();
    }
    var tmp258_safe_receiver = this.ph0_1;
    if (tmp258_safe_receiver == null)
      null;
    else
      tmp258_safe_receiver.vg8();
  }
}
class ViewFileRef {}
function forceLoadSourceFile$default(views, currentVfs, sourceFile, $completion, $super) {
  currentVfs = currentVfs === VOID ? views.vb7_1 : currentVfs;
  sourceFile = sourceFile === VOID ? null : sourceFile;
  return $super === VOID ? this.qh4(views, currentVfs, sourceFile, $completion) : $super.qh4.call(this, views, currentVfs, sourceFile, $completion);
}
class SmoothedBmpSlice {}
class Image extends BaseImage {
  static jh4(bitmap, anchor, hitShape, smoothing) {
    anchor = anchor === VOID ? Companion_getInstance_32().p2g_1 : anchor;
    hitShape = hitShape === VOID ? null : hitShape;
    smoothing = smoothing === VOID ? true : smoothing;
    var $this = this.kh4(bitmap, anchor, hitShape, smoothing);
    $this.pd8_1 = new Mixin_0();
    $this.qd8_1 = sourceFile$factory($this);
    return $this;
  }
  ih4(_this__u8e3s4, views, currentVfs, sourceFile, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_baseForceLoadSourceFile__natc6b(_this__u8e3s4, this, views, currentVfs, sourceFile, $completion), $completion);
  }
  lh4(ctx, view) {
    this.pd8_1.lh4(ctx, view);
  }
  mh4(_set____db54di) {
    this.pd8_1.gh4_1 = _set____db54di;
  }
  nh4(_set____db54di) {
    this.pd8_1.nh4(_set____db54di);
  }
  oh4() {
    return this.pd8_1.hh4_1;
  }
  static rd8(bitmap, anchor, hitShape, smoothing) {
    anchor = anchor === VOID ? Companion_getInstance_32().p2g_1 : anchor;
    hitShape = hitShape === VOID ? null : hitShape;
    smoothing = smoothing === VOID ? true : smoothing;
    return this.jh4(Resourceable(bitmap), anchor, hitShape, smoothing);
  }
  static ph4(bitmap, anchor, hitShape, smoothing) {
    anchor = anchor === VOID ? Companion_getInstance_32().p2g_1 : anchor;
    hitShape = hitShape === VOID ? null : hitShape;
    smoothing = smoothing === VOID ? true : smoothing;
    return this.rd8(slice(bitmap), anchor, hitShape, smoothing);
  }
  jc7(ctx) {
    this.lh4(ctx, this);
    super.jc7(ctx);
  }
  qh4(views, currentVfs, sourceFile, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_forceLoadSourceFile__yy5vos(this, views, currentVfs, sourceFile, $completion), $completion);
  }
}
class RenderableView extends CustomContextRenderizableView {
  static edj(size, viewRenderer) {
    var $this = this.dgl(size);
    $this.acn_1 = viewRenderer;
    $this.bcn_1 = false;
    $this.ccn_1 = false;
    $this.dcn_1 = false;
    return $this;
  }
  fgl(context, size) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.RenderableView.renderer.<anonymous>' call
    this.acn_1.ade(this);
  }
}
class CoordsType extends Enum {}
class Companion_16 {}
class Companion_17 {}
class SolidRect extends RectBase {
  static ddj(size, color) {
    color = color === VOID ? Colors_getInstance().g4r_1 : color;
    var $this = this.egl();
    $this.cdj_1 = size;
    $this.m59(color);
    return $this;
  }
  bc7(value) {
    if (this.cdj_1.equals(value))
      return Unit_instance;
    this.cdj_1 = value;
    this.abb_1 = true;
    this.hbh();
  }
  cc7() {
    return this.cdj_1;
  }
  xg8() {
    return this.j2y();
  }
  yg8() {
    return this.k2y();
  }
  lcq(value) {
    this.m59(value);
  }
}
class Stage extends FixedSizeContainer {
  static jb6(views) {
    var $this = this.sfh();
    $this.fb6_1 = views.ib7_1;
    $this.gb6_1 = views;
    $this.hb6_1 = clipBorders$factory($this.vbk());
    $this.ib6_1 = virtualSizeDouble$factory($this.vbk());
    $this.ecb($this);
    $this.gcb($this);
    return $this;
  }
  sag(pos) {
    return this.fb6_1.sag(pos);
  }
  aag(_set____db54di) {
    this.fb6_1.aag(_set____db54di);
  }
  bag() {
    return this.fb6_1.bag();
  }
  cag(_set____db54di) {
    this.fb6_1.cag(_set____db54di);
  }
  dag() {
    return this.fb6_1.dag();
  }
  eag(_set____db54di) {
    this.fb6_1.eag(_set____db54di);
  }
  fag() {
    return this.fb6_1.fag();
  }
  gag(_set____db54di) {
    this.fb6_1.gag(_set____db54di);
  }
  hag() {
    return this.fb6_1.hag();
  }
  iag(_set____db54di) {
    this.fb6_1.iag(_set____db54di);
  }
  jag() {
    return this.fb6_1.jag();
  }
  kag() {
    return this.fb6_1.kag();
  }
  lag() {
    return this.fb6_1.lag();
  }
  mag() {
    return this.fb6_1.mag();
  }
  nag() {
    return this.fb6_1.nag();
  }
  oag() {
    return this.fb6_1.oag();
  }
  pag() {
    return this.fb6_1.pag();
  }
  qag() {
    return this.fb6_1.qag();
  }
  rag() {
    return this.fb6_1.rag();
  }
  vbk() {
    return this.gb6_1;
  }
  c15() {
    return this.gb6_1.yb6_1;
  }
  sh4(_set____db54di) {
    var this_0 = this.hb6_1;
    clip$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  t78() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.hb6_1;
    clip$factory_0();
    return this_0.get();
  }
  bc7(_set____db54di) {
    var this_0 = this.ib6_1;
    unscaledSize$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  cc7() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ib6_1;
    unscaledSize$factory_0();
    return this_0.get();
  }
  lbj() {
    return this.vbk().bb7_1;
  }
  jbu() {
    return this.vbk().ab7_1;
  }
  xbw() {
    return this.vbk().eb7_1;
  }
  pbj() {
    return this;
  }
  zc9() {
    return this;
  }
  saa() {
    return this.mgh(this.vbk());
  }
  aca(view) {
    this.vbk().aca(view);
  }
  toString() {
    return 'Stage';
  }
}
class Companion_18 {
  constructor() {
    this.rfm_1 = 16.0;
    this.sfm_1 = true;
  }
}
class Text$_renderInternal$slambda {
  constructor(this$0, $ctx, $fontSource) {
    this.vh4_1 = this$0;
    this.wh4_1 = $ctx;
    this.xh4_1 = $fontSource;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_50(this, $completion), $completion);
  }
}
class Text extends Container {
  static tfm(text, textSize, color, font, alignment, renderer, autoScaling, fill, stroke) {
    textSize = textSize === VOID ? Companion_instance_33.rfm_1 : textSize;
    color = color === VOID ? Colors_getInstance().g4r_1 : color;
    font = font === VOID ? get_DefaultTtfFontAsBitmap() : font;
    alignment = alignment === VOID ? Companion_getInstance_4().h5s_1 : alignment;
    renderer = renderer === VOID ? get_DefaultStringTextRenderer() : renderer;
    autoScaling = autoScaling === VOID ? Companion_instance_33.sfm_1 : autoScaling;
    fill = fill === VOID ? null : fill;
    stroke = stroke === VOID ? null : stroke;
    var $this = this.vbw();
    $this.zci_1 = true;
    $this.acj_1 = -1;
    $this.bcj_1 = -1;
    $this.ccj_1 = 0;
    $this.dcj_1 = 0;
    $this.ecj_1 = text;
    updateLineCount($this);
    var tmp = $this;
    tmp.fcj_1 = fill == null ? new RGBA(color) : fill;
    $this.gcj_1 = stroke;
    $this.hcj_1 = font;
    $this.icj_1 = numberToDouble(textSize);
    $this.jcj_1 = textSize$factory_2($this);
    $this.kcj_1 = 0;
    $this.lcj_1 = 2147483647;
    $this.mcj_1 = renderer;
    $this.ncj_1 = alignment;
    var tmp_0 = $this;
    // Inline function 'korlibs.korge.view.container' call
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = Container.vbw(false);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp_0.ocj_1 = addTo(this_0, $this);
    $this.pcj_1 = new Text2TextRendererActions();
    $this.qcj_1 = false;
    $this.rcj_1 = autoScaling;
    $this.scj_1 = false;
    $this.tcj_1 = null;
    $this.ucj_1 = Companion_getInstance_38().d2t(0, 0, 2048, 2048);
    $this.vcj_1 = true;
    $this.wcj_1 = -1;
    $this.xcj_1 = new MMatrix();
    $this.ycj_1 = -1;
    $this.zcj_1 = null;
    $this.ack_1 = new Entry();
    $this.bck_1 = new FontMetrics();
    $this.cck_1 = new TextMetrics();
    $this.dck_1 = null;
    $this.eck_1 = null;
    $this.fck_1 = null;
    $this.gck_1 = null;
    $this.hck_1 = new MMatrix();
    $this.ick_1 = GraphicsRenderer_SYSTEM_getInstance();
    $this.jck_1 = true;
    $this.kck_1 = null;
    $this.lck_1 = fontSource$factory($this);
    return $this;
  }
  bcg(value) {
    if (!(this.ecj_1 === value)) {
      this.ecj_1 = value;
      updateLineCount(this);
      this.cca();
      this.hbh();
    }
  }
  o2f() {
    return this.ecj_1;
  }
  ah5(value) {
    if (!equals(this.fcj_1, value)) {
      this.fcj_1 = value;
      this.cca();
    }
  }
  lcq(value) {
    this.ah5(new RGBA(value));
  }
  j6b() {
    var tmp = this.fcj_1;
    var tmp_0;
    if (tmp == null ? true : tmp instanceof RGBA) {
      var tmp_1 = tmp;
      tmp_0 = tmp_1 == null ? null : tmp_1.v59_1;
    } else {
      tmp_0 = null;
    }
    var tmp0_elvis_lhs = tmp_0;
    var tmp_2;
    var tmp_3 = tmp0_elvis_lhs;
    if ((tmp_3 == null ? null : new RGBA(tmp_3)) == null) {
      tmp_2 = Colors_getInstance().g4r_1;
    } else {
      tmp_2 = tmp0_elvis_lhs;
    }
    return tmp_2;
  }
  wcp(value) {
    if (!equals(this.hcj_1, value)) {
      this.hcj_1 = value;
      this.cca();
    }
  }
  xcp(value) {
    if (!(this.icj_1 === value)) {
      this.icj_1 = value;
      this.cca();
    }
  }
  l76(_set____db54di) {
    var this_0 = this.jcj_1;
    fontSize$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  b5m() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.jcj_1;
    fontSize$factory_0();
    return this_0.get();
  }
  cca() {
    this.ccj_1 = this.ccj_1 + 1 | 0;
    super.cca();
  }
  p76(value) {
    if (!this.ncj_1.equals(value)) {
      this.ncj_1 = value;
      this.cca();
    }
  }
  z6z() {
    return this.ncj_1.a5u_1;
  }
  zh4(value) {
    this.tcj_1 = value;
    this.qcj_1 = false;
    this.cca();
  }
  yh4(currentVfs, sourceFile, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_forceLoadFontSource__wlrz09(this, currentVfs, sourceFile, $completion), $completion);
  }
  yfm(face, size, color, align) {
    this.wcp(face == null ? get_DefaultTtfFontAsBitmap() : face);
    this.xcp(size);
    this.lcq(color);
    this.p76(align);
  }
  zfm(rect) {
    if (this.ucj_1.equals(rect) && !this.vcj_1)
      return Unit_instance;
    this.ucj_1 = rect;
    this.vcj_1 = false;
    this.wcj_1 = this.wcj_1 + 1 | 0;
    this.cca();
  }
  gc7() {
    this.bh5(null);
    if (!(get_filter(this) == null) || !(get_backdropFilter(this) == null)) {
      return super.gc7();
    } else {
      return this.ucj_1;
    }
  }
  jc7(ctx) {
    this.bh5(ctx);
    var tva = this.gck_1;
    if (!(tva == null)) {
      this.xcj_1.q4d(this.ybp());
      this.xcj_1.t4d(this.ocj_1.n4f(), this.ocj_1.p4f());
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
      var this_0 = ctx.ka7_1;
      var this_1 = this_0.ta7_1;
      if (!(this_1.oa7_1 === this_0)) {
        this_1.oa7_1 = this_0;
        this_1.ya8();
      }
      var tmp = this.hcj_1;
      var bmpfont = isInterface(tmp, BitmapFont) ? tmp : THROW_CCE();
      var tex = bmpfont.f5m();
      this_0.iaf(tex, this.zci_1, this.vcc(), get_agProgram(bmpfont), tva.zae_1, tva.yae_1);
      this_0.daf(tva, this.xcj_1.j2t());
    } else {
      super.jc7(ctx);
    }
  }
  bcq() {
    this.bh5(null);
    if (!(this.ycj_1 === this.ccj_1)) {
      this.ycj_1 = this.ccj_1;
      var tmp = this;
      var tmp303_safe_receiver = this.hcj_1.s14();
      tmp.zcj_1 = tmp303_safe_receiver == null ? null : getTextBoundsWithGlyphs(tmp303_safe_receiver, this.b5m(), this.o2f(), this.mcj_1, this.ncj_1);
    }
    var tmp0_elvis_lhs = this.zcj_1;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var message = 'Must ensure font is resolved before calling getGlyphMetrics';
      throw IllegalStateException.m5(toString(message));
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    return tmp_0;
  }
  bh5(ctx) {
    if (!(ctx == null)) {
      var fontSource = this.tcj_1;
      if (!this.qcj_1 && !(fontSource == null)) {
        this.qcj_1 = true;
        launchImmediately_0(ctx.qa6_1, Text$_renderInternal$slambda_0(this, ctx, fontSource));
      }
    }
    var font = this.hcj_1.s14();
    var tmp;
    var tmp_0;
    if (this.vcj_1) {
      tmp_0 = !(font == null) ? isInterface(font, Font) : false;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = !(this.wcj_1 === this.ccj_1);
    } else {
      tmp = false;
    }
    if (tmp) {
      this.wcj_1 = this.ccj_1;
      var metrics = getTextBounds(font, this.icj_1, this.o2f(), this.cck_1, this.mcj_1, this.ncj_1);
      this.ucj_1 = new Rectangle(metrics.p2i(), VerticalAlign__getOffsetY_impl_iso0y6(this.ncj_1.a5u_1, metrics.k2y(), -metrics.h5u()), metrics.j2y(), font.j5m(this.icj_1, this.bck_1).d5l() * this.dcj_1);
    }
    if (font != null) {
      if (!(font == null) ? isInterface(font, BitmapFont) : false) {
        var rversion = this.mcj_1.s6y();
        if (!(this.eck_1 === this.zci_1) || !(this.acj_1 === this.ccj_1) || !(this.bcj_1 === rversion)) {
          this.eck_1 = this.zci_1;
          this.bcj_1 = rversion;
          this.acj_1 = this.ccj_1;
          if (!(this.kck_1 == null)) {
            this.kck_1 = null;
            this.ocj_1.nca();
          }
          this.pcj_1.d70();
          this.pcj_1.q6z_1 = Companion_getInstance_4().l5s_1;
          invoke_0(this.mcj_1, this.pcj_1, this.o2f(), this.icj_1, font);
          while (this.ocj_1.eca() < this.pcj_1.j1()) {
            // Inline function 'korlibs.korge.view.image' call
            var this_0 = this.ocj_1;
            // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
            var texture = get_Bitmaps_transparent();
            var anchor_0 = Companion_getInstance_32().p2g_1;
            // Inline function 'korlibs.korge.view.addTo' call
            var this_1 = Image.rd8(texture, anchor_0);
            // Inline function 'kotlin.apply' call
            addTo(this_1, this_0);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge.view.image.<anonymous>' call
          }
          while (this.ocj_1.eca() > this.pcj_1.j1()) {
            this.ocj_1.i1(this.ocj_1.eca() - 1 | 0).qbh();
          }
          var bounds = this.pcj_1.v3u();
          var firstBounds = this.pcj_1.b70(0);
          var lineInfos = this.pcj_1.a70();
          var tmp0_elvis_lhs = firstOrNull_0(lineInfos);
          var firstLineInfos = tmp0_elvis_lhs == null ? new LineInfo() : tmp0_elvis_lhs;
          // Inline function 'kotlin.collections.sumOf' call
          var sum = 0;
          var tmp0_iterator = lineInfos.f1();
          while (tmp0_iterator.g1()) {
            var element = tmp0_iterator.h1();
            var tmp_1 = sum;
            // Inline function 'korlibs.korge.view.Text._renderInternal.<anonymous>' call
            sum = tmp_1 + element.z6y_1;
          }
          var totalHeight = sum;
          var textWidth = bounds.k2i_1;
          var textHeight = bounds.l2i_1;
          var dx = this.ucj_1.i2i_1;
          var tmp306_subject = this.z6z();
          var dy = equals(tmp306_subject, Companion_getInstance_6().m6y_1) ? 0.0 : equals(tmp306_subject, Companion_getInstance_6().j6y_1) ? firstLineInfos.x6y_1 : firstLineInfos.x6y_1 - totalHeight * _VerticalAlign___get_ratioFake__impl__1h2fwv(this.z6z());
          var program = get_agProgram(font);
          var inductionVariable = 0;
          var last = this.pcj_1.j1();
          if (inductionVariable < last)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              var entry = this.pcj_1.c70(n, this.ack_1);
              var tmp_2 = this.ocj_1.i1(n);
              var it = tmp_2 instanceof Image ? tmp_2 : THROW_CCE();
              it.dgb_1 = program;
              it.m59(this.j6b());
              // Inline function 'korlibs.korge.view.anchor' call
              anchor(it, Anchor2D.g2h(numberToDouble(0), numberToDouble(0)));
              it.zga_1 = this.zci_1;
              it.lfy(entry.a6z_1);
              it.s5w(Vector2D.k2h(entry.b6z_1 + dx, entry.c6z_1 + dy));
              it.ucb(Scale.s2r(entry.d6z_1, entry.e6z_1));
              it.acc(entry.f6z_1);
              var containsLower = this.kcj_1;
              it.car(n < this.lcj_1 ? containsLower <= n : false);
            }
             while (inductionVariable < last);
        }
      } else {
        if (!(this.eck_1 === this.zci_1) || !(this.fck_1 === this.jck_1)) {
          this.ccj_1 = this.ccj_1 + 1 | 0;
          this.fck_1 = this.jck_1;
          this.dck_1 = this.rcj_1;
          this.eck_1 = this.zci_1;
        }
        if (!(this.acj_1 === this.ccj_1)) {
          this.acj_1 = this.ccj_1;
          var realTextSize = this.icj_1;
          if (this.kck_1 == null) {
            this.ocj_1.nca();
            var tmp_3 = this;
            // Inline function 'korlibs.korge.view.graphics' call
            var this_2 = this.ocj_1;
            var renderer = this.ick_1;
            // Inline function 'kotlin.also' call
            var this_3 = addTo(Graphics.zh3(EmptyShape_instance, renderer), this_2);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
            // Inline function 'korlibs.korge.view.Graphics.updateShape' call
            var result;
            // Inline function 'korlibs.image.vector.buildShape' call
            // Inline function 'kotlin.apply' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
            // Inline function 'korlibs.korge.view.Text._renderInternal.<anonymous>' call
            result = Unit_instance;
            var tmp$ret$10 = (new ShapeBuilder(null, null)).p5r();
            this_3.xh3(tmp$ret$10);
            this_3.fh4();
            tmp_3.kck_1 = this_3;
            var tmp307_safe_receiver = this.kck_1;
            if (tmp307_safe_receiver == null)
              null;
            else {
              tmp307_safe_receiver.dh4(true);
            }
          }
          // Inline function 'korlibs.korge.view.Graphics.updateShape' call
          var this_4 = ensureNotNull(this.kck_1);
          var result_0;
          // Inline function 'korlibs.image.vector.buildShape' call
          // Inline function 'kotlin.apply' call
          var this_5 = new ShapeBuilder(null, null);
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
          // Inline function 'korlibs.korge.view.Text._renderInternal.<anonymous>' call
          var tmp308_text = this.o2f();
          var tmp309_pos = Companion_getInstance_34().a2i_1;
          var tmp310_paint = this.j6b();
          var tmp311_renderer = this.mcj_1;
          var tmp312_align = this.ncj_1;
          var tmp313_outMetrics = new TextMetricsResult();
          var tmp314_fillStyle = this.fcj_1;
          var tmp315_stroke = this.gcj_1;
          var tmp316_textRangeStart = this.kcj_1;
          var tmp317_textRangeEnd = this.lcj_1;
          result_0 = this_5.z6x(tmp308_text, tmp309_pos, VOID, new RGBA(tmp310_paint), font, realTextSize, tmp311_renderer, tmp312_align, tmp313_outMetrics, tmp314_fillStyle, tmp315_stroke, tmp316_textRangeStart, tmp317_textRangeEnd);
          var tmp$ret$16 = this_5.p5r();
          this_4.xh3(tmp$ret$16);
          var metrics_0 = result_0;
          this.ycj_1 = this.ccj_1;
          this.zcj_1 = metrics_0;
          setContainerPosition(this, Companion_getInstance_34().a2i_1, font.j5m(this.b5m(), this.bck_1).x5k_1);
        }
        var tmp318_safe_receiver = this.kck_1;
        if (tmp318_safe_receiver == null)
          null;
        else {
          tmp318_safe_receiver.ch4(this.zci_1);
        }
      }
    }
  }
}
class Companion_19 {
  constructor() {
    Companion_instance_34 = this;
    this.ch5_1 = until(0, 2147483647);
  }
}
class TextBlock extends UIView {
  static gd6(text, align, size) {
    Companion_getInstance_67();
    var tmp;
    if (text === VOID) {
      var tmp321_font = get_DefaultTtfFontAsBitmap();
      tmp = RichTextData.o6x('', tmp321_font, 16.0);
    } else {
      tmp = text;
    }
    text = tmp;
    align = align === VOID ? Companion_getInstance_4().h5s_1 : align;
    size = size === VOID ? Size2D.f2j(100, 100) : size;
    var $this = this.wc6(size);
    $this.pd5_1 = true;
    $this.qd5_1 = text;
    $this.rd5_1 = align;
    $this.sd5_1 = false;
    $this.td5_1 = true;
    $this.ud5_1 = new RGBA($this.x58());
    $this.vd5_1 = null;
    $this.wd5_1 = true;
    $this.xd5_1 = '...';
    $this.yd5_1 = Companion_getInstance_37().p2l_1;
    $this.zd5_1 = false;
    $this.ad6_1 = 0;
    $this.bd6_1 = 2147483647;
    $this.cd6_1 = true;
    $this.dd6_1 = null;
    $this.ed6_1 = null;
    invalidateText($this);
    $this.fd6_1 = null;
    return $this;
  }
  ud9(value) {
    if (!this.qd5_1.equals(value)) {
      this.qd5_1 = value;
      invalidateText(this);
    }
  }
  td9(value) {
    if (!this.rd5_1.equals(value)) {
      this.rd5_1 = value;
      invalidProps(this);
    }
  }
  tdx(value) {
    if (!this.yd5_1.equals(value)) {
      this.yd5_1 = value;
      invalidProps(this);
    }
  }
  kg5(value) {
    if (!(this.jg5() === value)) {
      this.ud9(RichTextData.a6x(value, this.qd5_1.v6w_1));
    }
  }
  jg5() {
    return this.qd5_1.o2f();
  }
  yc6() {
    invalidProps(this);
  }
  jc7(ctx) {
    if (_get_allBitmap__bdn8f(this) === true) {
      if (this.pd5_1 || this.fd6_1 == null) {
        this.pd5_1 = false;
        this.fd6_1 = place(this.qd5_1, new Rectangle(this.yd5_1.t2l_1, this.yd5_1.q2l_1, this.j2y() - this.yd5_1.r2l_1, this.k2y() - this.yd5_1.s2l_1), this.wd5_1, this.sd5_1, this.xd5_1, this.ud5_1, this.vd5_1, this.rd5_1, this.td5_1);
      }
      var tmp336_safe_receiver = this.dd6_1;
      if (tmp336_safe_receiver == null)
        null;
      else {
        tmp336_safe_receiver.qbh();
      }
      this.dd6_1 = null;
      // Inline function 'korlibs.korge.render.renderCtx2d' call
      // Inline function 'korlibs.korge.render.RenderContext.useCtx2d' call
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      var batcher = ctx.ka7_1;
      if (!(ctx.oa7_1 === batcher)) {
        ctx.oa7_1 = batcher;
        ctx.ya8();
      }
      // Inline function 'korlibs.korge.render.RenderContext.useCtx2d.<anonymous>' call
      // Inline function 'korlibs.korge.render.renderCtx2d.<anonymous>' call
      var context = ctx.ma7_1;
      // Inline function 'korlibs.korge.render.RenderContext2D.keep' call
      $l$block_3: {
        // Inline function 'korlibs.korge.render.RenderContext2D.keepMatrix' call
        var old = context.uai_1;
        try {
          // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>' call
          $l$block_2: {
            // Inline function 'korlibs.korge.render.RenderContext2D.keepBlendMode' call
            var oldBlendFactors = context.vai_1;
            try {
              // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>' call
              $l$block_1: {
                // Inline function 'korlibs.korge.render.RenderContext2D.keepColor' call
                var multiplyColor = context.wai_1;
                try {
                  // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>' call
                  $l$block_0: {
                    // Inline function 'korlibs.korge.render.RenderContext2D.keepFiltering' call
                    var filtering = context.xai_1;
                    try {
                      // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                      $l$block: {
                        // Inline function 'korlibs.korge.render.RenderContext2D.keepSize' call
                        var size = context.sai_1;
                        try {
                          // Inline function 'korlibs.korge.render.RenderContext2D.keep.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                          context.sai_1 = Size2D.k2t(this.j2y(), this.k2y());
                          context.vai_1 = this.vcc();
                          context.wai_1 = this.wcc();
                          context.yai(this.ybp());
                          drawText_0(context, ensureNotNull(this.fd6_1), this.ad6_1, this.bd6_1, this.cd6_1);
                          break $l$block;
                        }finally {
                          context.sai_1 = size;
                        }
                      }
                      break $l$block_0;
                    }finally {
                      context.xai_1 = filtering;
                    }
                  }
                  break $l$block_1;
                }finally {
                  context.wai_1 = multiplyColor;
                }
              }
              break $l$block_2;
            }finally {
              context.vai_1 = oldBlendFactors;
            }
          }
          break $l$block_3;
        }finally {
          context.uai_1 = old;
        }
      }
    } else {
      ensureTexture(this);
    }
    super.jc7(ctx);
  }
}
class Companion_20 {}
class VectorImage extends BaseGraphics {
  static fh8(shape, autoScaling) {
    autoScaling = autoScaling === VOID ? true : autoScaling;
    var $this = this.rg8(autoScaling);
    $this.bh8_1 = new Mixin_0();
    $this.ch8_1 = shape;
    $this.dh8_1 = sourceFile$factory_0($this);
    return $this;
  }
  ih4(_this__u8e3s4, views, currentVfs, sourceFile, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_baseForceLoadSourceFile__natc6b_0(_this__u8e3s4, this, views, currentVfs, sourceFile, $completion), $completion);
  }
  lh4(ctx, view) {
    this.bh8_1.lh4(ctx, view);
  }
  mh4(_set____db54di) {
    this.bh8_1.gh4_1 = _set____db54di;
  }
  nh4(_set____db54di) {
    this.bh8_1.nh4(_set____db54di);
  }
  oh4() {
    return this.bh8_1.hh4_1;
  }
  eh8(value) {
    if (!(this.ch8_1 === value)) {
      this.ch8_1 = value;
      this.ug8();
      this.vg8();
      this.ocb(1.0);
    }
  }
  wg8(ctx) {
    ctx.g5x(this.ch8_1);
  }
  jg8(includeStrokes) {
    return Companion_getInstance_38().d2t(0, 0, this.ch8_1.j2y(), this.ch8_1.k2y());
  }
  jc7(ctx) {
    this.lh4(ctx, this);
    super.jc7(ctx);
  }
  qh4(views, currentVfs, sourceFile, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_forceLoadSourceFile__yy5vos_0(this, views, currentVfs, sourceFile, $completion), $completion);
  }
}
class ColorReference {}
class Companion_21 {
  gh8(left, right) {
    var l = left;
    var r = right;
    var lCount = get_ancestorCount(l);
    var rCount = get_ancestorCount(r);
    while (lCount >= 0 || rCount >= 0) {
      if (equals(l, r))
        return l;
      var ldec = lCount >= rCount;
      var rdec = rCount >= lCount;
      if (ldec) {
        lCount = lCount - 1 | 0;
        var tmp343_safe_receiver = l;
        l = tmp343_safe_receiver == null ? null : tmp343_safe_receiver.w15();
      }
      if (rdec) {
        rCount = rCount - 1 | 0;
        var tmp344_safe_receiver = r;
        r = tmp344_safe_receiver == null ? null : tmp344_safe_receiver.w15();
      }
    }
    return null;
  }
}
class sam$kotlin_Comparator$0_0 {
  constructor(function_0) {
    this.hh8_1 = function_0;
  }
  rj(a, b) {
    return this.hh8_1(a, b);
  }
  compare(a, b) {
    return this.rj(a, b);
  }
}
class ViewRenderPhase {}
function get_priority() {
  return 0;
}
function render_0(view, ctx) {
  return view.dcd(ctx);
}
function beforeRender(view, ctx) {
  return Unit_instance;
}
function afterRender(view, ctx) {
  return Unit_instance;
}
class ViewFileRef$Mixin$lazyLoadRenderInternalActually$slambda {
  constructor($view, $ctx, this$0) {
    this.vh8_1 = $view;
    this.wh8_1 = $ctx;
    this.xh8_1 = this$0;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_51(this, $completion), $completion);
  }
}
class Mixin_0 {
  constructor() {
    this.gh4_1 = true;
    this.hh4_1 = null;
  }
  mh4(_set____db54di) {
    this.gh4_1 = _set____db54di;
  }
  nh4(value) {
    this.gh4_1 = false;
    this.hh4_1 = value;
  }
  ih4(_this__u8e3s4, views, currentVfs, sourceFile, $completion) {
    _this__u8e3s4.nh4(sourceFile);
    _this__u8e3s4.mh4(true);
  }
  qh4(views, currentVfs, sourceFile, $completion) {
    // Inline function 'kotlin.TODO' call
    var reason = 'Must override this';
    throw NotImplementedError.oe('An operation is not implemented: ' + reason);
  }
  lh4(ctx, view) {
    if (this.gh4_1)
      return Unit_instance;
    this.yh8(ctx, view);
  }
  yh8(ctx, view) {
    if (!this.gh4_1 && !(this.hh4_1 == null)) {
      this.gh4_1 = true;
      launchImmediately_0(ctx.qa6_1, ViewFileRef$Mixin$lazyLoadRenderInternalActually$slambda_0(view, ctx, this));
    }
  }
}
class Views$close$slambda {
  constructor(this$0) {
    this.zh8_1 = this$0;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_52(this, $completion), $completion);
  }
}
class Views extends BaseEventListener {
  static xbe(coroutineContext, ag, injector, input, timeProvider, stats, gameWindow, gameId, settingsFolder, batchMaxQuads, bp, stageBuilder) {
    injector = injector === VOID ? new Injector() : injector;
    input = input === VOID ? new Input() : input;
    timeProvider = timeProvider === VOID ? Companion_instance_0 : timeProvider;
    stats = stats === VOID ? new Stats() : stats;
    gameId = gameId === VOID ? 'korgegame' : gameId;
    settingsFolder = settingsFolder === VOID ? null : settingsFolder;
    batchMaxQuads = batchMaxQuads === VOID ? Companion_getInstance_8().rad_1 : batchMaxQuads;
    bp = bp === VOID ? new Base() : bp;
    var tmp;
    if (stageBuilder === VOID) {
      tmp = Views$_init_$lambda_jivasj;
    } else {
      tmp = stageBuilder;
    }
    stageBuilder = tmp;
    var $this = this.i8a();
    $this.xb6_1 = new Mixin();
    $this.yb6_1 = coroutineContext;
    $this.zb6_1 = ag;
    $this.ab7_1 = injector;
    $this.bb7_1 = input;
    $this.cb7_1 = timeProvider;
    $this.db7_1 = stats;
    $this.eb7_1 = gameWindow;
    $this.fb7_1 = gameId;
    $this.gb7_1 = settingsFolder;
    $this.hb7_1 = batchMaxQuads;
    $this.ib7_1 = bp;
    $this.jb7_1 = stageBuilder;
    $this.kb7_1 = quality$factory($this.eb7_1);
    $this.lb7_1 = $this;
    $this.mb7_1 = false;
    $this.nb7_1 = continuousRenderMode$factory($this.eb7_1);
    $this.ob7_1 = new ReshapeEvent(0, 0);
    $this.pb7_1 = new UpdateEvent();
    $this.qb7_1 = new ViewsUpdateEvent($this);
    $this.rb7_1 = new ViewsResizedEvent($this);
    var tmp_0 = $this;
    tmp_0.sb7_1 = lazy(Views$realSettingsFolder$delegate$lambda($this));
    $this.ub7_1 = null;
    $this.vb7_1 = get_resourcesVfs();
    $this.wb7_1 = get_RegisteredImageFormats();
    $this.xb7_1 = new RenderContext($this.zb6_1, $this, $this.eb7_1, $this.db7_1, $this.yb6_1, $this.hb7_1, $this.eb7_1);
    $this.yb7_1 = true;
    $this.zb7_1 = Colors_getInstance().h4r_1;
    var tmp_1 = $this;
    // Inline function 'kotlin.collections.hashMapOf' call
    tmp_1.ab8_1 = HashMap.c9();
    var tmp_2 = $this;
    // Inline function 'korlibs.time.milliseconds' call
    tmp_2.bb8_1 = toDuration(100.0, DurationUnit_MILLISECONDS_getInstance());
    $this.cb8_1 = new Resources($this.yb6_1, $this.vb7_1);
    $this.db8_1 = false;
    $this.eb8_1 = 1280;
    $this.fb8_1 = 720;
    var tmp_3 = $this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp_3.gb8_1 = ArrayList.m1();
    $this.hb8_1 = Companion_getInstance_31().n2v_1;
    $this.ib8_1 = Companion_getInstance_32().t2g_1;
    $this.jb8_1 = true;
    $this.kb8_1 = $this.jb7_1($this);
    $this.lb8_1 = $this.kb8_1;
    $this.mb8_1 = true;
    $this.nb8_1 = false;
    $this.ob8_1 = debugExtraFontScale$factory($this.xb7_1);
    $this.pb8_1 = debugExtraFontColor$factory($this.xb7_1);
    $this.qb8_1 = FastArrayList.g14();
    $this.rb8_1 = $this.cb7_1.h1w();
    var tmp_4 = $this;
    tmp_4.sb8_1 = Pool.e39(VOID, Views$tempViewsPool$lambda);
    $this.tb8_1 = SizeInt.c2w();
    $this.ub8_1 = SizeInt.c2w();
    $this.vb8_1 = SizeInt.c2w();
    $this.wb8_1 = new Signal();
    $this.xb8_1 = new Signal();
    $this.yb8_1 = -1.0;
    $this.ab7_1.o87(getKClass(CoroutineContext), $this.yb6_1);
    $this.ab7_1.o87(getKClass(AG), $this.zb6_1);
    $this.ab7_1.o87(getKClass(Views), $this);
    $this.xb8_1.w1x(Views$lambda($this));
    $this.wb8_1.w1x(Views$lambda_0($this));
    installFpsDebugOverlay($this);
    $this.zb8_1 = Companion_instance_11.bj('SHOW_FRAME_UPDATE_AND_RENDER') === 'true';
    $this.ab9_1 = new EventResult();
    $this.bb9_1 = new Signal();
    $this.cb9_1 = Companion_getInstance_1().ez('debugHightlightView');
    $this.db9_1 = new Signal();
    $this.eb9_1 = new Signal();
    $this.fb9_1 = ArrayList.m1();
    $this.gb9_1 = updatedSinceFrame$factory($this.eb7_1);
    return $this;
  }
  n13(_set____db54di) {
    this.xb6_1.n13(_set____db54di);
  }
  o13() {
    return this.xb6_1.o13();
  }
  c15() {
    return this.yb6_1;
  }
  gam() {
    return this.eb7_1.gam();
  }
  rah() {
    return this.eb7_1.rah();
  }
  sah() {
    return this.eb7_1.sah();
  }
  tah() {
    return this.eb7_1.tah();
  }
  uah() {
    return this.eb7_1.uah();
  }
  sag(pos) {
    return this.ib7_1.sag(pos);
  }
  aag(_set____db54di) {
    this.ib7_1.aag(_set____db54di);
  }
  bag() {
    return this.ib7_1.bag();
  }
  cag(_set____db54di) {
    this.ib7_1.cag(_set____db54di);
  }
  dag() {
    return this.ib7_1.dag();
  }
  eag(_set____db54di) {
    this.ib7_1.eag(_set____db54di);
  }
  fag() {
    return this.ib7_1.fag();
  }
  gag(_set____db54di) {
    this.ib7_1.gag(_set____db54di);
  }
  hag() {
    return this.ib7_1.hag();
  }
  iag(_set____db54di) {
    this.ib7_1.iag(_set____db54di);
  }
  jag() {
    return this.ib7_1.jag();
  }
  kag() {
    return this.ib7_1.kag();
  }
  lag() {
    return this.ib7_1.lag();
  }
  mag() {
    return this.ib7_1.mag();
  }
  nag() {
    return this.ib7_1.nag();
  }
  oag() {
    return this.ib7_1.oag();
  }
  pag() {
    return this.ib7_1.pag();
  }
  qag() {
    return this.ib7_1.qag();
  }
  rag() {
    return this.ib7_1.rag();
  }
  vbk() {
    return this.lb7_1;
  }
  ibf(_set____db54di) {
    var this_0 = this.nb7_1;
    forceRenderEveryFrame$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  cbf() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.nb7_1;
    forceRenderEveryFrame$factory_0();
    return this_0.get();
  }
  l3() {
    return this.bb7_1.faa_1;
  }
  bh9() {
    return replace(replace(replace(this.fb7_1, '\\', ''), '/', ''), '..', '');
  }
  zbp() {
    var tmp = this.tb7_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('debugBmpFont');
    }
  }
  i21($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_init__qn9atk(this, $completion), $completion);
  }
  hbf() {
    return this.cb8_1;
  }
  jbf() {
    return this.zb6_1.h8k().a8k_1;
  }
  kbf() {
    return this.zb6_1.h8k().b8k_1;
  }
  ch9(value) {
    this.eb8_1 = numberToInt(value);
  }
  dh9() {
    return this.eb8_1;
  }
  eh9(value) {
    this.fb8_1 = numberToInt(value);
  }
  fh9() {
    return this.fb8_1;
  }
  uh4(value) {
    this.ch9(value.d2j_1);
    this.eh9(value.e2j_1);
  }
  th4() {
    return Size2D.k2t(this.dh9(), this.fh9());
  }
  u6() {
    launchImmediately(this, Views$close$slambda_0(this));
  }
  ah9($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_closeSuspend__agjafw(this, $completion), $completion);
  }
  ibo() {
    return this.kb8_1.saa();
  }
  ybe(_set____db54di) {
    var this_0 = this.ob8_1;
    debugFontExtraScale$factory();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  gh9() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ob8_1;
    debugFontExtraScale$factory_0();
    return this_0.get();
  }
  zbe(_set____db54di) {
    var this_0 = this.pb8_1;
    debugFontColor$factory();
    var value = new RGBA(_set____db54di);
    this_0.set(value);
    return Unit_instance;
  }
  hh9() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.pb8_1;
    debugFontColor$factory_0();
    return this_0.get().v59_1;
  }
  zgx(block) {
    this.qb8_1.f(block);
  }
  m8a(type, event, result, up, down) {
    event.h8b(false);
    var result_0 = super.m8a(type, event, result, up, down);
    var e = event;
    e.e8b(this.lb7_1);
    if (e instanceof KeyEvent) {
      this.bb7_1.zaa(e);
      this.bb7_1.faa_1.pa9(e);
      if (e.d8c_1.equals(Type_UP_getInstance_0()) && this.mb8_1 && (e.f8c_1.equals(Key_F12_getInstance()) || e.f8c_1.equals(Key_F7_getInstance()))) {
        this.nb8_1 = !this.nb8_1;
        this.eb7_1.gar(this.nb8_1);
        this.aca(this.kb8_1);
      }
    }
    try {
      return this.kb8_1.v8a(e);
    } catch ($p) {
      if ($p instanceof StopPropagatingException) {
        var e_0 = $p;
      } else {
        throw $p;
      }
    }
    return result_0;
  }
  ih9(frameBuffer) {
    this.xb7_1.pa7_1 = frameBuffer;
    if (this.yb7_1) {
      var tmp404_$this = this.xb7_1;
      var tmp405_color = this.zb7_1;
      tmp404_$this.eai(tmp405_color, 1.0, 0, true, true, true);
    }
    this.wb8_1.x1x(this.xb7_1);
    this.xb7_1.ya8();
    this.kb8_1.fc7(this.xb7_1);
    this.xb7_1.ya8();
    this.kb8_1.qca(this.xb7_1);
    if (this.nb8_1) {
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = this.qb8_1.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.korge.view.Views.renderNew.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        array[_unary__edvuaz](this, this.xb7_1);
      }
    }
    this.xb8_1.x1x(this.xb7_1);
    this.xb7_1.ya8();
  }
  jh9(frameBuffer, $super) {
    frameBuffer = frameBuffer === VOID ? this.zb6_1.h8k() : frameBuffer;
    var tmp;
    if ($super === VOID) {
      this.ih9(frameBuffer);
      tmp = Unit_instance;
    } else {
      tmp = $super.ih9.call(this, frameBuffer);
    }
    return tmp;
  }
  kh9() {
    this.zb6_1.l8k();
    this.jh9();
  }
  dbf(fixedSizeStep, forceRender, doUpdate, doRender) {
    var currentTime = this.cb7_1.h1w();
    this.lb7_1.db7_1.l8k();
    // Inline function 'korlibs.logger.Logger.trace' call
    var this_0 = Companion_getInstance_47().rb3_1;
    var level = Level_TRACE_getInstance();
    var tmp;
    if (this_0.u3g(level)) {
      // Inline function 'korlibs.korge.view.Views.frameUpdateAndRender.<anonymous>' call
      var tmp$ret$0 = 'ag.onRender';
      this_0.v3g(level, tmp$ret$0);
      tmp = Unit_instance;
    }
    var delta = DateTime__minus_impl_10njw8(currentTime, this.rb8_1);
    var adelta = Duration__compareTo_impl_pchp0f(delta, this.lb7_1.bb8_1) > 0 ? this.lb7_1.bb8_1 : delta;
    this.rb8_1 = currentTime;
    if (doUpdate) {
      if (!equals(fixedSizeStep, Companion_instance_1.n1w())) {
        this.mh9(fixedSizeStep);
      } else {
        this.lh9(adelta);
      }
    }
    var doRender2 = doRender && (forceRender || this.nh9() > 0);
    if (doRender2) {
      if (this.zb8_1) {
        println('Views.frameUpdateAndRender[' + Companion_getInstance_7().j1w().toString() + ']: doRender=' + doRender2 + ' -> [forceRender=' + forceRender + ', updatedSinceFrame=' + this.nh9() + ']');
      }
      this.kh9();
      this.l8k();
    }
  }
  lh9(elapsed) {
    return this.mh9(get_fast(elapsed));
  }
  mh9(elapsed) {
    this.bb7_1.xaa(elapsed);
    this.ab9_1.z33();
    updateSingleViewWithViewsAll(this.kb8_1, this, elapsed);
    this.bb7_1.yaa(elapsed);
  }
  bbf() {
  }
  gbf(width, height) {
    var actualWidth = width;
    var actualHeight = height;
    this.ub8_1 = SizeInt.c2v(actualWidth, actualHeight);
    this.oh9();
  }
  oh9() {
    setBoundsInfo(this.ib7_1, Size2D.f2j(this.eb8_1, this.fb8_1), toFloat(this.ub8_1), this.hb8_1, this.ib8_1, toRef(virtualSize$factory(this)), toRef(targetSize$factory(this)));
    this.xb7_1.wa6_1 = this.ib7_1.fag();
    this.xb7_1.xa6_1 = this.ib7_1.bag();
    // Inline function 'kotlin.also' call
    var this_0 = this.ob7_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Views.resized.<anonymous>' call
    this_0.y8b_1 = this.ub8_1.a2v_1;
    this_0.z8b_1 = this.ub8_1.b2v_1;
    this.v8a(this_0);
    // Inline function 'kotlin.also' call
    var this_1 = this.rb7_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Views.resized.<anonymous>' call
    this_1.uh8_1 = this.tb8_1;
    this.v8a(this_1);
    this.kb8_1.cca();
  }
  uf0(viewToHightlight, onlyIfDebuggerOpened) {
    if (onlyIfDebuggerOpened && !this.eb7_1.har())
      return Unit_instance;
    // Inline function 'korlibs.logger.Logger.debug' call
    var this_0 = this.cb9_1;
    var level = Level_DEBUG_getInstance();
    var tmp;
    if (this_0.u3g(level)) {
      // Inline function 'korlibs.korge.view.Views.debugHightlightView.<anonymous>' call
      var tmp$ret$0 = 'debugHightlightView: ' + toString_0(viewToHightlight);
      this_0.v3g(level, tmp$ret$0);
      tmp = Unit_instance;
    }
    this.bb9_1.x1x(viewToHightlight);
  }
  xbp(viewToHightlight, onlyIfDebuggerOpened, $super) {
    onlyIfDebuggerOpened = onlyIfDebuggerOpened === VOID ? false : onlyIfDebuggerOpened;
    var tmp;
    if ($super === VOID) {
      this.uf0(viewToHightlight, onlyIfDebuggerOpened);
      tmp = Unit_instance;
    } else {
      tmp = $super.uf0.call(this, viewToHightlight, onlyIfDebuggerOpened);
    }
    return tmp;
  }
  ph9(_set____db54di) {
    var this_0 = this.gb9_1;
    updatedSinceFrame$factory_0();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  nh9() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.gb9_1;
    updatedSinceFrame$factory_1();
    return this_0.get();
  }
  l8k() {
    this.eb7_1.l8k();
  }
  aca(view) {
    this.eb7_1.var();
  }
}
class Companion_22 {}
class ViewsUpdateEvent extends Event {
  constructor(views, fastDelta) {
    fastDelta = fastDelta === VOID ? Companion_instance_1.m1w() : fastDelta;
    super();
    this.oh8_1 = views;
    this.ph8_1 = fastDelta;
  }
  qh8() {
    return toDuration_0(this.ph8_1);
  }
  b2n() {
    return Companion_instance_37;
  }
  toString() {
    return 'ViewsUpdateEvent(time=' + FastDuration__toString_impl_c9xohb(this.ph8_1) + ')';
  }
}
class Companion_23 {}
class ViewsResizedEvent extends Event {
  constructor(views, size) {
    size = size === VOID ? SizeInt.c2v(0, 0) : size;
    super();
    this.th8_1 = views;
    this.uh8_1 = size;
  }
  b2n() {
    return Companion_instance_38;
  }
  toString() {
    return 'ViewsResizedEvent(size=' + this.uh8_1.toString() + ')';
  }
}
class UB extends UniformBlock {
  constructor() {
    UB_instance = null;
    super(6);
    UB_instance = this;
    this.uh9_1 = this.b90().p8z(this, u_Col$factory());
    this.vh9_1 = this.f8z().p8z(this, u_Matrix$factory());
  }
  wh9() {
    return this.uh9_1.ko(this, u_Col$factory_0());
  }
  xh9() {
    return this.vh9_1.ko(this, u_Matrix$factory_0());
  }
}
class Companion_24 {
  constructor() {
    Companion_instance_39 = this;
    var tmp = this;
    var tmp_0 = DefaultShaders_getInstance().i8y_1;
    // Inline function 'korlibs.graphics.VertexShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.debug.Companion.PROGRAM.<anonymous>' call
    this_0.f92(this_0.i92(), this_0.j92(this_0.j92(this_0.j92(this_0.q8z(), this_0.r8z()), UB_getInstance().xh9()), this_0.m92([this_0.k92(), this_0.l92(0.0), this_0.l92(1.0)])));
    var tmp_1 = VertexShader(this_0.n92());
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder = ProgramBuilderDefault.a92();
    // Inline function 'korlibs.korge.view.debug.Companion.PROGRAM.<anonymous>' call
    builder.f92(builder.i92(), UB_getInstance().wh9());
    var tmp$ret$2 = FragmentShader(builder.n92());
    tmp.yh9_1 = tmp_0.c9r(tmp_1, tmp$ret$2);
  }
}
class Batch {
  constructor(offset, count) {
    this.zh9_1 = offset;
    this.aha_1 = count;
  }
}
class DebugVertexView extends View {
  static mcp(pointsList, color, type) {
    Companion_getInstance_72();
    color = color === VOID ? Colors_getInstance().g4r_1 : color;
    type = type === VOID ? Companion_getInstance_18().m8u_1 : type;
    var $this = this.hgn();
    $this.m59(color);
    $this.gcp_1 = colorMul$factory_0($this);
    $this.hcp_1 = pointsList;
    $this.icp_1 = type;
    var tmp = $this;
    // Inline function 'kotlin.floatArrayOf' call
    tmp.jcp_1 = new Float32Array([0.0, 0.0, 100.0, 0.0, 0.0, 100.0, 100.0, 100.0]);
    var tmp_0 = $this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp_0.kcp_1 = ArrayList.m1();
    $this.lcp_1 = Companion_getInstance_40().y2i();
    updatedPoints($this);
    return $this;
  }
  ccq(value) {
    if (!(this.hcp_1 === value)) {
      this.hcp_1 = value;
      updatedPoints(this);
    }
  }
  gc7() {
    return _BoundsBuilder___get_bounds__impl__s1l1gx(this.lcp_1);
  }
  jc7(ctx) {
    ctx.ya8();
    ctx.oaf();
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.y8r(UB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.debug.DebugVertexView.renderInternal.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.h9i($this$push.wh9(), this.wcc());
      it.m9i($this$push.xh9(), toMatrix4(this.ybp()));
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    $l$block_0: {
      // Inline function 'korlibs.datastructure.Pool.alloc' call
      var this_1 = ctx.la7_1;
      var temp = this_1.g39();
      try {
        temp.u8n(this.jcp_1);
        var vData = AGVertexArrayObject.s8t([AGVertexData.w8t(DefaultShaders_getInstance().g8y_1, temp)], true);
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var this_2 = this.kcp_1;
        var n = 0;
        while (n < this_2.j1()) {
          // Inline function 'korlibs.korge.view.debug.DebugVertexView.renderInternal.<anonymous>.<anonymous>' call
          var _unary__edvuaz_1 = n;
          n = _unary__edvuaz_1 + 1 | 0;
          var batch = this_2.i1(_unary__edvuaz_1);
          var tmp424_$receiver = ctx.ma6_1;
          var tmp425_frameBuffer = ctx.pa7_1;
          var tmp426_drawType = this.icp_1;
          var tmp427_program = Companion_getInstance_72().yh9_1;
          var tmp428_uniformBlocks = ctx.qah(Companion_getInstance_72().yh9_1);
          var tmp429_textureUnits = ctx.va6_1.j13();
          var tmp430_vertexCount = batch.aha_1;
          var tmp431_drawOffset = batch.zh9_1;
          var tmp432_blending = this.vcc().ba9_1;
          draw(tmp424_$receiver, tmp425_frameBuffer, vData, tmp427_program, tmp426_drawType, tmp430_vertexCount, VOID, VOID, tmp431_drawOffset, tmp432_blending, tmp428_uniformBlocks, tmp429_textureUnits);
        }
        break $l$block_0;
      }finally {
        this_1.h39(temp);
      }
    }
  }
}
class FViewInfo {
  constructor(nTexs) {
    this.dhb_1 = nTexs;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = this.dhb_1;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      // Inline function 'korlibs.image.bitmap.Bitmaps.white' call
      var tmp$ret$1 = get_Bitmaps_white();
      tmp_2[tmp_3] = get_bmp(tmp$ret$1);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.ehb_1 = tmp_2;
    var tmp_4 = this;
    var tmp_5 = 0;
    var tmp_6 = this.ehb_1.length;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_7 = fillArrayVal(Array(tmp_6), null);
    while (tmp_5 < tmp_6) {
      tmp_7[tmp_5] = Vector2F.c2y();
      tmp_5 = tmp_5 + 1 | 0;
    }
    tmp_4.fhb_1 = tmp_7;
    var tmp_8 = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var size = this.ehb_1.length;
    tmp_8.ghb_1 = fillArrayVal(Array(size), null);
    var tmp_9 = this;
    // Inline function 'kotlin.collections.getOrElse' call
    var this_0 = Companion_getInstance_73().tha_1;
    var index = this.ehb_1.length;
    // Inline function 'kotlin.contracts.contract' call
    var tmp_10;
    if (0 <= index ? index <= (this_0.length - 1 | 0) : false) {
      tmp_10 = this_0[index];
    } else {
      var message = 'Only supported up to 4 textures';
      throw IllegalStateException.m5(toString(message));
    }
    tmp_9.hhb_1 = tmp_10;
  }
}
class FView extends View {
  static mhd(sprites, texs) {
    var $this = this.hgn();
    $this.ihd_1 = sprites;
    $this.jhd_1 = texs;
    $this.khd_1 = true;
    $this.lhd_1 = new FViewInfo($this.jhd_1.length);
    return $this;
  }
  static nhd(sprites, tex) {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$2 = [tex];
    return this.mhd(sprites, tmp$ret$2);
  }
  jc7(ctx) {
    // Inline function 'kotlin.collections.copyInto' call
    var this_0 = this.jhd_1;
    var destination = this.lhd_1.ehb_1;
    var endIndex = this_0.length;
    arrayCopy(this_0, destination, 0, 0, endIndex);
    Companion_getInstance_73().ohd(ctx, this.ihd_1, this.lhd_1, this.khd_1, this.ybp(), this.vcc());
  }
}
class FspritesUB extends UniformBlock {
  constructor() {
    FspritesUB_instance = null;
    super(6);
    FspritesUB_instance = this;
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(5), null);
    while (tmp_0 < 5) {
      var tmp_2 = tmp_0;
      tmp_1[tmp_2] = FspritesUB_getInstance().y8z('u_texSize' + tmp_2).l9g();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.chb_1 = tmp_1;
  }
}
class Companion_25 {
  constructor() {
    Companion_instance_40 = this;
    this.bha_1 = 4;
    var tmp = this;
    // Inline function 'kotlin.floatArrayOf' call
    tmp.cha_1 = new Float32Array([0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0]);
    var tmp_0 = this;
    var tmp_1 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(5), null);
    while (tmp_1 < 5) {
      var tmp_3 = tmp_1;
      tmp_2[tmp_3] = FspritesUB_getInstance().chb_1[tmp_3].h8t_1;
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp_0.dha_1 = tmp_2;
    this.eha_1 = Attribute.z90('a_xy', VarType_Float2_getInstance(), false, VOID, 0);
    this.fha_1 = Attribute.z90('a_rxy', VarType_Float2_getInstance(), false, VOID, 1).v9o(1);
    this.gha_1 = Attribute.z90('a_scale', VarType_Float2_getInstance(), true, VOID, 2).v9o(1);
    this.hha_1 = Attribute.z90('a_rangle', VarType_Float1_getInstance(), false, VOID, 3).v9o(1);
    this.iha_1 = Attribute.z90('a_axy', VarType_UShort2_getInstance(), true, VOID, 4).v9o(1);
    this.jha_1 = Attribute.z90('a_uv0', VarType_UShort2_getInstance(), false, VOID, 5).v9o(1);
    this.kha_1 = Attribute.z90('a_uv1', VarType_UShort2_getInstance(), false, VOID, 6).v9o(1);
    this.lha_1 = Attribute.z90('a_colMul', VarType_Byte4_getInstance(), true, Precision_LOW_getInstance(), 7).v9o(1);
    this.mha_1 = Attribute.z90('a_texId', VarType_UByte1_getInstance(), false, Precision_LOW_getInstance(), 8).v9o(1);
    this.nha_1 = Varying.l91('v_TexId', VarType_Float1_getInstance(), Precision_LOW_getInstance());
    var tmp_4 = this;
    tmp_4.oha_1 = new PropertyThis(VOID, FSprites$Companion$xyBuffer$delegate$lambda);
    var tmp_5 = this;
    tmp_5.pha_1 = new PropertyThis(VOID, FSprites$Companion$fastSpriteBuffer$delegate$lambda);
    var tmp_6 = this;
    tmp_6.qha_1 = new PropertyThis(VOID, FSprites$Companion$fastSpriteBufferMul$delegate$lambda);
    var tmp_7 = this;
    tmp_7.rha_1 = new PropertyThis(VOID, FSprites$Companion$fastSpriteBufferTexId$delegate$lambda);
    var tmp_8 = this;
    tmp_8.sha_1 = new PropertyThis(VOID, FSprites$Companion$buffers$delegate$lambda);
    var tmp_9 = this;
    var tmp_10 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_11 = fillArrayVal(Array(5), null);
    while (tmp_10 < 5) {
      var tmp_12 = tmp_10;
      tmp_11[tmp_12] = Companion_getInstance_73().phd(tmp_12);
      tmp_10 = tmp_10 + 1 | 0;
    }
    tmp_9.tha_1 = tmp_11;
  }
  ohd(ctx, sprites, info, smoothing, globalMatrix, blending) {
    if (!ctx.q8k()) {
      println('WARNING: FSprites without instanced rendering support not implemented yet.');
      println('         Please, if you are reading this message, let us know');
      return Unit_instance;
    }
    var textureUnits = sprites.zhd_1;
    var texs = info.ehb_1;
    var u_i_texSizeDataN = info.fhb_1;
    var program = info.hhb_1;
    ctx.ya8();
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    this_0.oaf();
    var inductionVariable = 0;
    var last = texs.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tex = texs[n];
        var ttex = ctx.fa7_1.zac(tex);
        u_i_texSizeDataN[n] = Vector2F.a2y(1.0 / ttex.j2y(), 1.0 / ttex.k2y());
        textureUnits.z8o(Companion_getInstance_8().pad_1[n], ttex.wab_1, Companion_getInstance_23().l8r(VOID, smoothing));
      }
       while (inductionVariable < last);
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_2 = ctx.y8r(FspritesUB_getInstance());
      var _unary__edvuaz = this_2.n8t();
      this_2.v9h(_unary__edvuaz + 1 | 0);
      this_2.w9h(this_2.n8t() + 1 | 0);
      var blockSize = this_2.r8r_1;
      var index0 = imul(this_2.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_2.n8t(), blockSize);
      var texBlockSize = this_2.o8r_1.s8z().j1();
      var texIndex0 = imul(this_2.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_2.n8t(), texBlockSize);
      if (this_2.n8t() > 0) {
        arraycopy(this_2.t8r_1, index0, this_2.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_2.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.fast.Companion.render.<anonymous>.<anonymous>' call
      var $this$push = this_2.o8r_1;
      var it = this_2.u8r_1;
      var inductionVariable_0 = 0;
      var last_0 = texs.length;
      if (inductionVariable_0 < last_0)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          it.e9i($this$push.chb_1[n_0], u_i_texSizeDataN[n_0]);
        }
         while (inductionVariable_0 < last_0);
      if (true && this_2.n8t() >= 1) {
        var equals = arrayequal(this_2.t8r_1, index0, this_2.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_2.n8t();
          this_2.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    // Inline function 'korlibs.korge.render.BatchBuilder2D.setViewMatrixTemp' call
    var this_3 = this_0.ta7_1;
    this_3.ya8();
    var temp4 = this_3.za6_1;
    var temp2d = this_3.aa7_1;
    this_3.aa7_1 = globalMatrix;
    this_3.za6_1 = toMatrix4(globalMatrix);
    $l$block_0: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_4 = this_3.y8r(ProjViewUB_getInstance());
      var _unary__edvuaz_1 = this_4.n8t();
      this_4.v9h(_unary__edvuaz_1 + 1 | 0);
      this_4.w9h(this_4.n8t() + 1 | 0);
      var blockSize_0 = this_4.r8r_1;
      var index0_0 = imul(this_4.n8t() - 1 | 0, blockSize_0);
      var index1_0 = imul(this_4.n8t(), blockSize_0);
      var texBlockSize_0 = this_4.o8r_1.s8z().j1();
      var texIndex0_0 = imul(this_4.n8t() - 1 | 0, texBlockSize_0);
      var texIndex1_0 = imul(this_4.n8t(), texBlockSize_0);
      if (this_4.n8t() > 0) {
        arraycopy(this_4.t8r_1, index0_0, this_4.t8r_1, index1_0, blockSize_0);
      } else {
        arrayfill(this_4.t8r_1, 0, 0, blockSize_0);
      }
      // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp.<anonymous>' call
      var $this$push_0 = this_4.o8r_1;
      this_4.u8r_1.m9i($this$push_0.r8z(), this_3.za6_1);
      if (true && this_4.n8t() >= 1) {
        var equals_0 = arrayequal(this_4.t8r_1, index0_0, this_4.t8r_1, index1_0, blockSize_0);
        if (equals_0) {
          var _unary__edvuaz_2 = this_4.n8t();
          this_4.v9h(_unary__edvuaz_2 - 1 | 0);
          break $l$block_0;
        }
      }
    }
    var tmp;
    try {
      sprites.ahe(ctx);
      Companion_getInstance_73().uha(ctx).u8t_1.u8n(Companion_getInstance_73().cha_1);
      var tmp433_$receiver = ctx.ma6_1;
      var tmp434_frameBuffer = ctx.pa7_1;
      var tmp435_vertexData = Companion_getInstance_73().bhe(ctx);
      var tmp436_drawType = Companion_getInstance_18().n8u_1;
      var tmp437_instances = sprites.rhd_1;
      var tmp438_uniformBlocks = ctx.qah(program);
      var tmp439_textureUnits = textureUnits.j13();
      var tmp440_blending = blending.ba9_1;
      draw(tmp433_$receiver, tmp434_frameBuffer, tmp435_vertexData, program, tmp436_drawType, 4, VOID, VOID, VOID, tmp440_blending, tmp438_uniformBlocks, tmp439_textureUnits, VOID, VOID, VOID, VOID, VOID, VOID, tmp437_instances);
      sprites.che(ctx);
      tmp = Unit_instance;
    }finally {
      this_3.ya8();
      this_3.za6_1 = temp4;
      this_3.aa7_1 = temp2d;
      this_3.y8r(ProjViewUB_getInstance()).x9h();
    }
    this_0.ta8_1.x1x(sprites.rhd_1);
  }
  uha(_this__u8e3s4) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var this_0 = this.oha_1;
      var property = xyBuffer$factory();
      var tmp0_elvis_lhs = this_0.r13_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = this_0.s13_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = this_0.r13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  vha(_this__u8e3s4) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var this_0 = this.pha_1;
      var property = fastSpriteBuffer$factory();
      var tmp0_elvis_lhs = this_0.r13_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = this_0.s13_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = this_0.r13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  wha(_this__u8e3s4) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var this_0 = this.qha_1;
      var property = fastSpriteBufferMul$factory();
      var tmp0_elvis_lhs = this_0.r13_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = this_0.s13_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = this_0.r13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  xha(_this__u8e3s4) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var this_0 = this.rha_1;
      var property = fastSpriteBufferTexId$factory();
      var tmp0_elvis_lhs = this_0.r13_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = this_0.s13_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = this_0.r13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  bhe(_this__u8e3s4) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var this_0 = this.sha_1;
      var property = buffers$factory();
      var tmp0_elvis_lhs = this_0.r13_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = this_0.s13_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = this_0.r13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  phd(maxTexs) {
    // Inline function 'korlibs.graphics.VertexShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fast.Companion.createProgram.<anonymous>' call
    var baseSize = this_0.h93(VarType_Float2_getInstance());
    var texSize = this_0.h93(VarType_Float2_getInstance());
    this_0.f92(baseSize, this_0.g95(Companion_getInstance_73().kha_1, Companion_getInstance_73().jha_1));
    this_0.f92(this_0.g92(), this_0.m92([this_0.j92(this_0.o92(Companion_getInstance_73().lha_1, 'rgb'), this_0.o92(Companion_getInstance_73().lha_1, 'a')), this_0.o92(Companion_getInstance_73().lha_1, 'a')]));
    this_0.f92(Companion_getInstance_73().nha_1, Companion_getInstance_73().mha_1);
    var tmp = Companion_getInstance_73().mha_1;
    createProgram$blockN(this_0, maxTexs, tmp, FSprites$Companion$createProgram$lambda(texSize));
    this_0.f92(this_0.b92(), this_0.j92(this_0.u94([this_0.n94(this_0.a95(Companion_getInstance_73().jha_1), this_0.a95(Companion_getInstance_73().kha_1), this_0.a95(Companion_getInstance_73().eha_1)), this_0.n94(this_0.b95(Companion_getInstance_73().jha_1), this_0.b95(Companion_getInstance_73().kha_1), this_0.b95(Companion_getInstance_73().eha_1))]), texSize));
    var cos = this_0.h93(VarType_Float1_getInstance());
    var sin = this_0.h93(VarType_Float1_getInstance());
    this_0.f92(cos, this_0.z93(Companion_getInstance_73().hha_1));
    this_0.f92(sin, this_0.y93(Companion_getInstance_73().hha_1));
    this_0.f92(this_0.w95(), this_0.y94([cos, this_0.f95(sin), sin, cos]));
    var size = this_0.o92(this_0.t92(), 'zw');
    var localPos = this_0.o92(this_0.t92(), 'xy');
    this_0.f92(size, this_0.j92(baseSize, Companion_getInstance_73().gha_1));
    this_0.f92(localPos, this_0.j92(this_0.w95(), this_0.j92(this_0.g95(Companion_getInstance_73().eha_1, Companion_getInstance_73().iha_1), size)));
    this_0.f92(this_0.i92(), this_0.j92(this_0.j92(this_0.q8z(), this_0.r8z()), this_0.m92([this_0.i95(localPos, this_0.u94([this_0.a95(Companion_getInstance_73().fha_1), this_0.b95(Companion_getInstance_73().fha_1)])), this_0.l92(0.0), this_0.l92(1.0)])));
    var tmp_0 = VertexShader(this_0.n92());
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_1 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fast.Companion.createProgram.<anonymous>' call
    var tmp_1 = Companion_getInstance_73().nha_1;
    createProgram$blockN(this_1, maxTexs, tmp_1, FSprites$Companion$createProgram$lambda_0(this_1));
    this_1.f92(this_1.i92(), this_1.j92(this_1.i92(), this_1.g92()));
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = this_1.p95(this_1.o92(this_1.i92(), 'a'), this_1.l92(0.0));
    var body = this_1.w92();
    // Inline function 'korlibs.korge.view.fast.Companion.createProgram.<anonymous>.<anonymous>' call
    body.q93();
    var stmIf = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_1.e92_1.f(stmIf);
    var tmp$ret$4 = FragmentShader(this_1.n92());
    return new Program(tmp_0, tmp$ret$4, 'FSprites' + maxTexs);
  }
  dhe(x, y) {
    return packAnchorComponent(this, x) & 65535 | packAnchorComponent(this, y) << 16;
  }
}
class FSprites {
  constructor(maxSize) {
    Companion_getInstance_73();
    this.qhd_1 = maxSize;
    this.rhd_1 = 0;
    this.shd_1 = Buffer.t10(imul(imul(this.qhd_1, 8), 4));
    this.thd_1 = Buffer.t10(imul(this.qhd_1, 4));
    this.uhd_1 = IntArrayList.f31();
    this.vhd_1 = get_i32(this.shd_1);
    this.whd_1 = get_f32(this.shd_1);
    this.xhd_1 = get_i32(this.thd_1);
    this.yhd_1 = new Int8Array(this.qhd_1);
    this.zhd_1 = AGTextureUnits.e8o();
  }
  oy() {
    return this.qhd_1 - this.rhd_1 | 0;
  }
  ahe(ctx) {
    Companion_getInstance_73().vha(ctx).u8t_1.v8n(this.shd_1, 0, imul(imul(this.rhd_1, 8), 4));
    Companion_getInstance_73().wha(ctx).u8t_1.v8n(this.thd_1, 0, imul(this.rhd_1, 4));
    Companion_getInstance_73().xha(ctx).u8t_1.r8n(this.yhd_1, 0, this.rhd_1);
  }
  che(ctx) {
    Companion_getInstance_73().vha(ctx).u8t_1.v8n(this.shd_1, 0, 0);
  }
  ehe(_this__u8e3s4) {
    this.fhe(_this__u8e3s4, 0.0);
    this.ghe(_this__u8e3s4, 0.0);
    this.hhe(_this__u8e3s4, 1.0);
    this.ihe(_this__u8e3s4, 1.0);
    this.jhe(_this__u8e3s4, 0.0);
    this.khe(_this__u8e3s4, Colors_getInstance().g4r_1);
  }
  lhe() {
    // Inline function 'kotlin.also' call
    var tmp;
    if (this.uhd_1.y2k()) {
      tmp = this.uhd_1.q3(this.uhd_1.j1() - 1 | 0);
    } else {
      var _unary__edvuaz = this.rhd_1;
      this.rhd_1 = _unary__edvuaz + 1 | 0;
      tmp = imul(_unary__edvuaz, 8);
    }
    var this_0 = new FSprite(_FSprite___init__impl__y4bm26(tmp));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fast.FSprites.alloc.<anonymous>' call
    var it = this_0.mhe_1;
    this.ehe(it);
    return this_0.mhe_1;
  }
  fhe(_this__u8e3s4, value) {
    Float32Buffer__set_impl_34szne(this.whd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 0 | 0, value);
  }
  nhe(_this__u8e3s4) {
    return Float32Buffer__get_impl_659lhu(this.whd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 0 | 0);
  }
  ghe(_this__u8e3s4, value) {
    Float32Buffer__set_impl_34szne(this.whd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 1 | 0, value);
  }
  ohe(_this__u8e3s4) {
    return Float32Buffer__get_impl_659lhu(this.whd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 1 | 0);
  }
  hhe(_this__u8e3s4, value) {
    Float32Buffer__set_impl_34szne(this.whd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 2 | 0, value);
  }
  ihe(_this__u8e3s4, value) {
    Float32Buffer__set_impl_34szne(this.whd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 3 | 0, value);
  }
  jhe(_this__u8e3s4, value) {
    Float32Buffer__set_impl_34szne(this.whd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 4 | 0, value);
  }
  phe(_this__u8e3s4, value) {
    Int32Buffer__set_impl_dj1e3r(this.vhd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 5 | 0, value);
  }
  qhe(_this__u8e3s4, value) {
    Int32Buffer__set_impl_dj1e3r(this.vhd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 6 | 0, value);
  }
  rhe(_this__u8e3s4, value) {
    Int32Buffer__set_impl_dj1e3r(this.vhd_1, _FSprite___get_offset__impl__t72eo8(_this__u8e3s4) + 7 | 0, value);
  }
  khe(_this__u8e3s4, value) {
    Int32Buffer__set_impl_dj1e3r(this.xhd_1, _FSprite___get_index__impl__7khb8f(_this__u8e3s4), _RGBA___get_value__impl__hhco8v(value));
  }
  she(_this__u8e3s4, x, y) {
    this.phe(_this__u8e3s4, Companion_getInstance_73().dhe(x, y));
  }
  the(_this__u8e3s4, sx, sy) {
    this.hhe(_this__u8e3s4, sx);
    this.ihe(_this__u8e3s4, sy);
  }
  uhe(_this__u8e3s4, sx, sy, $super) {
    sy = sy === VOID ? sx : sy;
    var tmp;
    if ($super === VOID) {
      this.the(_this__u8e3s4, sx, sy);
      tmp = Unit_instance;
    } else {
      tmp = $super.the.call(this, new FSprite(_this__u8e3s4), sx, sy);
    }
    return tmp;
  }
  vhe(_this__u8e3s4, tex) {
    this.qhe(_this__u8e3s4, tex.p2i() | tex.r2i() << 16);
    this.rhe(_this__u8e3s4, tex.q2i() | tex.s2i() << 16);
  }
  whe(tex) {
    return FView.nhd(this, tex);
  }
}
class FSprite {
  constructor(id) {
    this.mhe_1 = id;
  }
  toString() {
    return FSprite__toString_impl_v3ejgq(this.mhe_1);
  }
  hashCode() {
    return FSprite__hashCode_impl_i6jlpj(this.mhe_1);
  }
  equals(other) {
    return FSprite__equals_impl_v8570j(this.mhe_1, other);
  }
}
class Companion_26 {}
class Filter {}
function get_recommendedFilterScale() {
  return 1.0;
}
function computeBorder(texWidth, texHeight) {
  return Companion_getInstance_43().z2l_1;
}
class ComposedFilter {
  static fhg(filters, unit) {
    unit = unit === VOID ? Unit_instance : unit;
    var $this = createThis(this);
    $this.dhf_1 = filters;
    return $this;
  }
  static ehf() {
    // Inline function 'kotlin.collections.mutableListOf' call
    var tmp$ret$0 = ArrayList.m1();
    return this.ehg(tmp$ret$0);
  }
  static ehg(filters) {
    var tmp;
    if (filters instanceof FastArrayList) {
      tmp = filters;
    } else {
      tmp = FastArrayList.u14(filters);
    }
    return this.fhg(tmp);
  }
  static ghg(filters) {
    return this.ehg(toList(filters));
  }
  zhf() {
    var out = 1.0;
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.dhf_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.korge.view.filter.ComposedFilter.<get-recommendedFilterScale>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      out = out * array[_unary__edvuaz].zhf();
    }
    return out;
  }
  ahg(texWidth, texHeight) {
    var sumLeft = 0;
    var sumTop = 0;
    var sumRight = 0;
    var sumBottom = 0;
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.dhf_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.korge.view.filter.ComposedFilter.computeBorder.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var out = array[_unary__edvuaz].ahg(texWidth, texHeight);
      sumLeft = sumLeft + out.d2m_1 | 0;
      sumRight = sumRight + out.b2m_1 | 0;
      sumTop = sumTop + out.a2m_1 | 0;
      sumBottom = sumBottom + out.c2m_1 | 0;
    }
    return MarginInt.e2m(sumTop, sumRight, sumBottom, sumLeft);
  }
  a2n() {
    return false;
  }
  bhg(ctx, matrix, texture, texWidth, texHeight, renderColorMul, blendMode, filterScale) {
    var mat = matrix;
    var tex = texture;
    var last = null;
    var texWidth_0 = texWidth;
    var texHeight_0 = texHeight;
    var filterScale_0 = filterScale;
    var resultPool = get_renderToTextureResultPool(ctx);
    if (!this.a2n()) {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = this.dhf_1.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.korge.view.filter.ComposedFilter.render.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        var filter = array[_unary__edvuaz];
        var result = resultPool.g39();
        this.chg();
        renderToTextureWithBorderUnsafe(filter, ctx, mat, tex, texWidth_0, texHeight_0, filterScale_0, result);
        result.kh9();
        ctx.ya8();
        resultPool.i39(last);
        last = result;
        mat = result.nhg_1;
        tex = ensureNotNull(result.qhg_1);
        texWidth_0 = result.ihg_1;
        texHeight_0 = result.jhg_1;
      }
    }
    Companion_getInstance_80().bhg(ctx, mat, tex, texWidth_0, texHeight_0, renderColorMul, blendMode, filterScale_0);
    resultPool.i39(last);
  }
  chg() {
  }
}
class BlurFilter extends ComposedFilter {
  static chf(radius, expandBorder, optimize) {
    radius = radius === VOID ? 4.0 : radius;
    expandBorder = expandBorder === VOID ? true : expandBorder;
    optimize = optimize === VOID ? true : optimize;
    var $this = this.ehf();
    $this.yhe_1 = optimize;
    var tmp = $this;
    // Inline function 'kotlin.also' call
    var this_0 = new DirectionalBlurFilter(get_degrees(0), radius, expandBorder);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.filter.BlurFilter.horizontal.<anonymous>' call
    $this.dhf_1.f(this_0);
    tmp.zhe_1 = this_0;
    var tmp_0 = $this;
    // Inline function 'kotlin.also' call
    var this_1 = new DirectionalBlurFilter(get_degrees(90), radius, expandBorder);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.filter.BlurFilter.vertical.<anonymous>' call
    $this.dhf_1.f(this_1);
    tmp_0.ahf_1 = this_1;
    $this.bhf_1 = radius;
    return $this;
  }
  fhf(value) {
    this.zhe_1.fhf(value);
    this.ahf_1.fhf(value);
  }
  nhf() {
    return this.zhe_1.nhf();
  }
  ud8(value) {
    this.bhf_1 = value;
    this.zhe_1.whf_1 = this.bhf_1;
    this.ahf_1.whf_1 = this.bhf_1;
  }
  zhf() {
    var tmp;
    if (!this.yhe_1 || this.bhf_1 <= 2.0) {
      tmp = 1.0;
    } else {
      // Inline function 'kotlin.math.log2' call
      var x = this.bhf_1 * 0.5;
      tmp = 1.0 / log2(x);
    }
    return tmp;
  }
  a2n() {
    return this.bhf_1 === 0.0;
  }
}
class Companion_27 {
  dhg(left, right) {
    var tmp;
    if (left == null) {
      tmp = right.a1() ? null : right.j1() === 1 ? first(right) : ComposedFilter.ehg(right);
    } else {
      if (left instanceof ComposedFilter) {
        tmp = ComposedFilter.ehg(plus(left.dhf_1, right));
      } else {
        tmp = ComposedFilter.ehg(plus(listOf(left), right));
      }
    }
    return tmp;
  }
}
class ConvoluteUB extends UniformBlock {
  constructor() {
    ConvoluteUB_instance = null;
    super(5);
    ConvoluteUB_instance = this;
    this.xhg_1 = this.x8z().p8z(this, u_ApplyAlpha$factory());
    this.yhg_1 = this.x8z().p8z(this, u_Dist$factory());
    this.zhg_1 = this.d90().p8z(this, u_Weights$factory());
  }
  ahh() {
    return this.xhg_1.ko(this, u_ApplyAlpha$factory_0());
  }
  bhh() {
    return this.yhg_1.ko(this, u_Dist$factory_0());
  }
  chh() {
    return this.zhg_1.ko(this, u_Weights$factory_0());
  }
}
class BaseProgramProvider {
  constructor() {
    Companion_getInstance_82();
    this.rhh_1 = Companion_getInstance_8().yad_1.i8s_1;
    this.shh_1 = Companion_getInstance_83().nhh_1;
    var tmp = this;
    tmp.thh_1 = lazy(ShaderFilter$BaseProgramProvider$_program$delegate$lambda(this));
  }
  uhh(vertex, fragment) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.graphics.shader.appending' call
      if (fragment.d9r()) {
        tmp$ret$0 = fragment;
        break $l$block;
      }
      // Inline function 'kotlin.also' call
      var this_0 = ProgramBuilderDefault.a92().o93(fragment);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.filter.BaseProgramProvider.createProgram.<anonymous>' call
      // Inline function 'korlibs.graphics.shader.Builder.IF' call
      var cond = this_0.p95(this_0.o92(this_0.i92(), 'a'), this_0.l92(0.0));
      var body = this_0.w92();
      // Inline function 'korlibs.korge.view.filter.BaseProgramProvider.createProgram.<anonymous>.<anonymous>' call
      body.q93();
      var stmIf = new If(cond, body.y92());
      // Inline function 'kotlin.collections.plusAssign' call
      this_0.e92_1.f(stmIf);
      var pair = this_0.n92();
      tmp$ret$0 = new Shader(fragment.e9j_1, pair.im_1, pair.jm_1);
    }
    return new Program(vertex, tmp$ret$0);
  }
  vhh() {
    return this.rhh_1;
  }
  qhh() {
    return this.shh_1;
  }
  whh() {
    return _get__program__9g4ap2(this);
  }
}
class Companion_28 extends BaseProgramProvider {
  constructor() {
    Companion_instance_43 = null;
    super();
    Companion_instance_43 = this;
    this.ghh_1 = Companion_getInstance_42().f2q(1.0, 2.0, 1.0, 2.0, 4.0, 2.0, 1.0, 2.0, 1.0).e2r(1.0 / 16.0);
    this.hhh_1 = Companion_getInstance_42().f2q(1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0).e2r(1.0 / 9.0);
    this.ihh_1 = Companion_getInstance_42().f2q(0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0);
    this.jhh_1 = Companion_getInstance_42().f2q(-1.0, -1.0, -1.0, -1.0, +8.0, -1.0, -1.0, -1.0, -1.0);
    this.khh_1 = Companion_getInstance_42().f2q(-1.0, -1.0, -1.0, -1.0, +9.0, -1.0, -1.0, -1.0, -1.0);
    this.lhh_1 = mapOf([to('IDENTITY', this.ihh_1), to('GAUSSIAN_BLUR', this.ghh_1), to('BOX_BLUR', this.hhh_1), to('EDGE_DETECTION', this.jhh_1), to('SHARPEN', this.khh_1)]);
    var tmp = this;
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>' call
    this_0.f92(this_0.i92(), this_0.m92([this_0.l92(0.0), this_0.l92(0.0), this_0.l92(0.0), this_0.l92(0.0)]));
    var inductionVariable = 0;
    if (inductionVariable < 3)
      do {
        var y = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < 3)
          do {
            var x = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var color = Companion_getInstance_83().phh(this_0, this_0.i95(Companion_getInstance_83().ohh(this_0), this_0.u94([this_0.j92(this_0.l92(x - 1 | 0), ConvoluteUB_getInstance().bhh()), this_0.j92(this_0.l92(y - 1 | 0), ConvoluteUB_getInstance().bhh())])));
            this_0.f92(this_0.i92(), this_0.i95(this_0.i92(), this_0.j92(color, this_0.z94(this_0.z94(ConvoluteUB_getInstance().chh(), x), y))));
          }
           while (inductionVariable_0 < 3);
      }
       while (inductionVariable < 3);
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = this_0.m95(ConvoluteUB_getInstance().ahh(), this_0.l92(1.0));
    var body = this_0.w92();
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>.<anonymous>' call
    body.f92(body.o92(body.i92(), 'a'), body.o92(Companion_getInstance_83().phh(body, Companion_getInstance_83().ohh(body)), 'a'));
    var stmIf = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_0.e92_1.f(stmIf);
    tmp.mhh_1 = FragmentShader(this_0.n92());
  }
  qhh() {
    return this.mhh_1;
  }
}
class ShaderFilter {
  constructor() {
    Companion_getInstance_83();
    this.ghf_1 = true;
    this.hhf_1 = Vector2D.i2l();
    this.ihf_1 = Vector2D.i2l();
    this.jhf_1 = Vector2D.i2l();
    this.khf_1 = Companion_getInstance_82();
    this.lhf_1 = AGTextureUnits.e8o();
    this.mhf_1 = 0;
  }
  fhf(_set____db54di) {
    this.ghf_1 = _set____db54di;
  }
  nhf() {
    return this.ghf_1;
  }
  ahg(texWidth, texHeight) {
    return Companion_getInstance_43().z2l_1;
  }
  ihi() {
    return this.khf_1;
  }
  chl(ctx, sampler, texture, info) {
    this.lhf_1.g8o(ctx.va6_1, sampler);
    ctx.va6_1.z8o(sampler, texture, info);
    this.mhf_1 = this.mhf_1 | 1 << sampler.s8o_1;
  }
  jhi(ctx, filterScale) {
  }
  a2n() {
    return false;
  }
  bhg(ctx, matrix, texture, texWidth, texHeight, renderColorMul, blendMode, filterScale) {
    if (this.a2n())
      return Companion_getInstance_80().bhg(ctx, matrix, texture, texWidth, texHeight, renderColorMul, blendMode, filterScale);
    var _margin = getBorder(this, texWidth, texHeight);
    var marginLeft = toIntCeil(_margin.d2m_1 * filterScale);
    var marginRight = toIntCeil(_margin.b2m_1 * filterScale);
    var marginTop = toIntCeil(_margin.a2m_1 * filterScale);
    var marginBottom = toIntCeil(_margin.c2m_1 * filterScale);
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    var slice = texture.t42(-marginLeft | 0, -marginTop | 0, texture.o41_1 + marginRight | 0, texture.p41_1 + marginBottom | 0, VOID, false);
    $l$block: {
      // Inline function 'korlibs.korge.render.BatchBuilder2D.temporalTextureUnit' call
      var sampler = DefaultShaders_getInstance().p92();
      var tex = slice.f41_1.wab_1;
      var info = Companion_getInstance_23().y8o_1;
      var oldTex = this_0.ta7_1.va6_1.c8o_1[sampler.s8o_1];
      var oldInfo = AGTextureUnitInfoArray__get_impl_xhvpef(this_0.ta7_1.va6_1.d8o_1, sampler.s8o_1);
      this_0.ta7_1.va6_1.z8o(sampler, tex, info);
      try {
        _updateUniforms(this, ctx, filterScale, texture, texWidth, texHeight);
        this_0.aa9(slice, -marginLeft, -marginTop, VOID, VOID, matrix, this.nhf(), renderColorMul, blendMode, this.ihi().whh());
        break $l$block;
      }finally {
        this_0.gae();
        this_0.ta7_1.va6_1.z8o(sampler, oldTex, oldInfo);
      }
    }
    _restoreUniforms(this, ctx, filterScale);
  }
}
class Convolute3Filter extends ShaderFilter {
  constructor(kernel, dist, applyAlpha) {
    Companion_getInstance_76();
    dist = dist === VOID ? 1.0 : dist;
    applyAlpha = applyAlpha === VOID ? false : applyAlpha;
    super();
    this.ehi_1 = kernel;
    this.fhi_1 = this.ehi_1;
    this.ghi_1 = dist;
    this.hhi_1 = applyAlpha;
  }
  ihi() {
    return Companion_getInstance_76();
  }
  ahg(texWidth, texHeight) {
    return MarginInt.g2m(toIntCeil(this.ghi_1));
  }
  jhi(ctx, filterScale) {
    super.jhi(ctx, filterScale);
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.y8r(ConvoluteUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.filter.Convolute3Filter.updateUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.a9i($this$push.ahh(), this.hhi_1);
      it.c9i($this$push.bhh(), this.ghi_1);
      it.m9i($this$push.chh(), this.fhi_1);
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
  }
}
class BlurUB extends UniformBlock {
  constructor() {
    BlurUB_instance = null;
    super(5);
    BlurUB_instance = this;
    this.ohi_1 = this.x8z().p8z(this, u_radius$factory());
    this.phi_1 = this.x8z().p8z(this, u_constant1$factory());
    this.qhi_1 = this.x8z().p8z(this, u_constant2$factory());
    this.rhi_1 = this.z8z().p8z(this, u_direction$factory());
  }
  shi() {
    return this.ohi_1.ko(this, u_radius$factory_0());
  }
  thi() {
    return this.phi_1.ko(this, u_constant1$factory_0());
  }
  uhi() {
    return this.qhi_1.ko(this, u_constant2$factory_0());
  }
  vhi() {
    return this.rhi_1.ko(this, u_direction$factory_0());
  }
}
class Companion_29 extends BaseProgramProvider {
  constructor() {
    Companion_instance_44 = null;
    super();
    Companion_instance_44 = this;
    var tmp = this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>' call
    var loopLen = this_0.u93(this_0.v93());
    var gaussianResult = this_0.u93(this_0.w93());
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = this_0.n95(BlurUB_getInstance().shi(), this_0.l92(1.0));
    var body = this_0.w92();
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>.<anonymous>' call
    body.f92(body.i92(), body.q92(this_0.p92(), Companion_getInstance_83().fhj(body)));
    var stmIf = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_0.e92_1.f(stmIf);
    var tmp_0 = stmIf;
    this_0.l93(tmp_0, DirectionalBlurFilter$Companion$fragment$lambda(loopLen, gaussianResult, this_0));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>' call
    tmp.khj_1 = FragmentShader(this_0.n92());
  }
  qhh() {
    return this.khj_1;
  }
}
class DirectionalBlurFilter extends ShaderFilter {
  constructor(angle, radius, expandBorder) {
    Companion_getInstance_77();
    angle = angle === VOID ? get_degrees(0) : angle;
    radius = radius === VOID ? 4.0 : radius;
    expandBorder = expandBorder === VOID ? true : expandBorder;
    super();
    this.vhf_1 = angle;
    this.whf_1 = radius;
    this.xhf_1 = expandBorder;
    var tmp = this;
    // Inline function 'kotlin.math.sqrt' call
    // Inline function 'kotlin.math.ln' call
    var x = 2 * Math.log(255.0);
    tmp.yhf_1 = Math.sqrt(x);
  }
  ahg(texWidth, texHeight) {
    if (!this.xhf_1)
      return Companion_getInstance_43().z2l_1;
    var radius = _get_rradius__1gjegj(this);
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = _Angle___get_sine__impl__15nmyi(this.vhf_1);
    var tmp$ret$0 = Math.abs(this_0);
    var tmp = toIntCeil(tmp$ret$0 * radius);
    // Inline function 'kotlin.math.absoluteValue' call
    var this_1 = _Angle___get_cosine__impl__vprvmu(this.vhf_1);
    var tmp$ret$1 = Math.abs(this_1);
    return MarginInt.f2m(tmp, toIntCeil(tmp$ret$1 * radius));
  }
  jhi(ctx, filterScale) {
    var radius = _get_rradius__1gjegj(this) * filterScale;
    var sigma = (radius + 1) / this.yhf_1;
    // Inline function 'kotlin.math.sqrt' call
    var x = 2.0 * 3.141592653589793;
    var constant1 = 1.0 / (sigma * Math.sqrt(x));
    var constant2 = 1.0 / (2.0 * sigma * sigma);
    var scaleSum = 0.0;
    if (isFinite(radius)) {
      var inductionVariable = 0;
      var last = toIntCeil_0(radius);
      if (inductionVariable < last)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var gauss = gaussian(this, n, constant1, constant2);
          scaleSum = scaleSum + (!(n === 0) ? gauss * 2 : gauss);
        }
         while (inductionVariable < last);
    }
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.y8r(BlurUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.filter.DirectionalBlurFilter.updateUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.b9i($this$push.shi(), radius);
      it.b9i($this$push.thi(), constant1 * (1.0 / scaleSum));
      it.b9i($this$push.uhi(), constant2);
      it.g9i($this$push.vhi(), Vector2D.k2h(_Angle___get_cosine__impl__vprvmu(this.vhf_1), _Angle___get_sine__impl__15nmyi(this.vhf_1)));
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
  }
  ihi() {
    return Companion_getInstance_77();
  }
  a2n() {
    return this.whf_1 === 0.0;
  }
}
class Companion_30 {
  constructor() {
    Companion_instance_45 = this;
    var tmp = this;
    // Inline function 'korlibs.graphics.shader.replacingFragment' call
    var this_0 = Companion_getInstance_8().yad_1;
    var extraName = 'nontransparentiswhite';
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder = ProgramBuilderDefault.a92();
    // Inline function 'korlibs.korge.view.filter.Companion.NON_TRANSPARENT_IS_WHITE.<anonymous>' call
    Companion_getInstance_8().fae(builder);
    builder.f92(builder.i92(), builder.i95(builder.i92(), builder.x94(new Float32Array([1.0, 1.0, 1.0, 0.0]))));
    builder.f92(builder.i92(), builder.j92(builder.i92(), Companion_getInstance_8().eae()));
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = builder.p95(builder.o92(builder.i92(), 'a'), builder.l92(0.0));
    var body = builder.w92();
    // Inline function 'korlibs.korge.view.filter.Companion.NON_TRANSPARENT_IS_WHITE.<anonymous>.<anonymous>' call
    body.q93();
    var stmIf = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    builder.e92_1.f(stmIf);
    var tmp$ret$1 = FragmentShader(builder.n92());
    tmp.lhj_1 = this_0.c9r(VOID, tmp$ret$1, this_0.k8s_1 + '-' + extraName);
  }
}
class DropshadowFilter {
  constructor(dropX, dropY, shadowColor, blurRadius, smoothing) {
    Companion_getInstance_78();
    dropX = dropX === VOID ? 10.0 : dropX;
    dropY = dropY === VOID ? 10.0 : dropY;
    shadowColor = shadowColor === VOID ? RGBA__withAd_impl_cralao(Colors_getInstance().h4r_1, 0.75) : shadowColor;
    blurRadius = blurRadius === VOID ? 4.0 : blurRadius;
    smoothing = smoothing === VOID ? true : smoothing;
    this.mhj_1 = dropX;
    this.nhj_1 = dropY;
    this.ohj_1 = shadowColor;
    this.phj_1 = blurRadius;
    this.qhj_1 = smoothing;
    this.rhj_1 = BlurFilter.chf(16.0);
    this.shj_1 = filtering$factory(this.rhj_1);
  }
  ahg(texWidth, texHeight) {
    var out = this.rhj_1.ahg(texWidth, texHeight);
    var top = out.a2m_1;
    var right = out.b2m_1;
    var bottom = out.c2m_1;
    var left = out.d2m_1;
    if (this.mhj_1 >= 0.0)
      right = right + toIntCeil(this.mhj_1) | 0;
    else
      left = left - toIntCeil(this.mhj_1) | 0;
    if (this.nhj_1 >= 0.0)
      bottom = bottom + toIntCeil(this.nhj_1) | 0;
    else
      top = top - toIntCeil(this.nhj_1) | 0;
    return MarginInt.e2m(top, right, bottom, left);
  }
  bhg(ctx, matrix, texture, texWidth, texHeight, renderColorMul, blendMode, filterScale) {
    this.rhj_1.ud8(this.phj_1);
    renderToTextureWithBorder(this.rhj_1, ctx, matrix, texture, texWidth, texHeight, filterScale, DropshadowFilter$render$lambda(ctx, this, filterScale, blendMode));
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    this_0.aa9(texture, VOID, VOID, VOID, VOID, matrix, this.qhj_1, renderColorMul, blendMode, Companion_getInstance_8().yad_1);
  }
}
class Companion_31 {
  constructor() {
    Companion_instance_46 = this;
    var tmp = this;
    // Inline function 'kotlin.doubleArrayOf' call
    tmp.thj_1 = new Float64Array([0.03125, 0.0625, 0.125, 0.25, 0.5, 0.75, 1.0]);
  }
  uhj(scale) {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlin.collections.minByOrNull' call
      var this_0 = this.thj_1;
      // Inline function 'kotlin.collections.isEmpty' call
      if (this_0.length === 0) {
        tmp$ret$1 = null;
        break $l$block_0;
      }
      var minElem = this_0[0];
      var lastIndex = get_lastIndex(this_0);
      if (lastIndex === 0) {
        tmp$ret$1 = minElem;
        break $l$block_0;
      }
      // Inline function 'korlibs.korge.view.filter.Companion.discretizeFilterScale.<anonymous>' call
      // Inline function 'kotlin.math.absoluteValue' call
      var this_1 = scale - minElem;
      var minValue = Math.abs(this_1);
      var inductionVariable = 1;
      if (inductionVariable <= lastIndex)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var e = this_0[i];
          // Inline function 'korlibs.korge.view.filter.Companion.discretizeFilterScale.<anonymous>' call
          // Inline function 'kotlin.math.absoluteValue' call
          var this_2 = scale - e;
          var v = Math.abs(this_2);
          if (compareTo(minValue, v) > 0) {
            minElem = e;
            minValue = v;
          }
        }
         while (!(i === lastIndex));
      tmp$ret$1 = minElem;
    }
    return ensureNotNull(tmp$ret$1);
  }
}
class RenderToTextureResult {
  constructor() {
    this.hhg_1 = null;
    this.ihg_1 = 0;
    this.jhg_1 = 0;
    this.khg_1 = 0;
    this.lhg_1 = 0;
    this.mhg_1 = 1.0;
    this.nhg_1 = Companion_getInstance_41().i2m_1;
    this.ohg_1 = null;
    this.phg_1 = null;
    this.qhg_1 = null;
    this.rhg_1 = null;
  }
  kh9() {
    var tmp0_elvis_lhs = this.phg_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var fb = tmp;
    var tmp1_elvis_lhs = this.rhg_1;
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var ctx = tmp_0;
    // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer' call
    // Inline function 'korlibs.korge.render.RenderContext.setFrameBufferTemporally' call
    ctx.mai(fb);
    try {
      // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer.<anonymous>' call
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
      var this_0 = ctx.ka7_1;
      var this_1 = this_0.ta7_1;
      if (!(this_1.oa7_1 === this_0)) {
        this_1.oa7_1 = this_0;
        this_1.ya8();
      }
      $l$block_0: {
        // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
        var scissor = AGScissor.p8v(0, 0, fb.a8k_1, fb.b8k_1);
        var temp = this_0.ra8_1;
        this_0.eaf();
        this_0.ra8_1 = scissor;
        try {
          if (true) {
            ctx.eai(Colors_getInstance().l4r_1);
          }
          // Inline function 'korlibs.korge.view.filter.RenderToTextureResult.render.<anonymous>' call
          // Inline function 'korlibs.korge.render.BatchBuilder2D.setViewMatrixTemp' call
          var this_2 = ctx.ka7_1;
          var matrix = Companion_getInstance_41().i2m_1;
          var this_3 = this_2.ta7_1;
          this_3.ya8();
          var temp4 = this_3.za6_1;
          var temp2d = this_3.aa7_1;
          this_3.aa7_1 = matrix;
          this_3.za6_1 = toMatrix4(matrix);
          $l$block: {
            // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
            var this_4 = this_3.y8r(ProjViewUB_getInstance());
            var _unary__edvuaz = this_4.n8t();
            this_4.v9h(_unary__edvuaz + 1 | 0);
            this_4.w9h(this_4.n8t() + 1 | 0);
            var blockSize = this_4.r8r_1;
            var index0 = imul(this_4.n8t() - 1 | 0, blockSize);
            var index1 = imul(this_4.n8t(), blockSize);
            var texBlockSize = this_4.o8r_1.s8z().j1();
            var texIndex0 = imul(this_4.n8t() - 1 | 0, texBlockSize);
            var texIndex1 = imul(this_4.n8t(), texBlockSize);
            if (this_4.n8t() > 0) {
              arraycopy(this_4.t8r_1, index0, this_4.t8r_1, index1, blockSize);
            } else {
              arrayfill(this_4.t8r_1, 0, 0, blockSize);
            }
            // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp.<anonymous>' call
            var $this$push = this_4.o8r_1;
            this_4.u8r_1.m9i($this$push.r8z(), this_3.za6_1);
            if (true && this_4.n8t() >= 1) {
              var equals = arrayequal(this_4.t8r_1, index0, this_4.t8r_1, index1, blockSize);
              if (equals) {
                var _unary__edvuaz_0 = this_4.n8t();
                this_4.v9h(_unary__edvuaz_0 - 1 | 0);
                break $l$block;
              }
            }
          }
          var tmp_1;
          try {
            var tmp448_safe_receiver = this.ohg_1;
            if (tmp448_safe_receiver == null)
              null;
            else {
              // Inline function 'kotlin.let' call
              // Inline function 'kotlin.contracts.contract' call
              var tmp449_safe_receiver = this.hhg_1;
              if (tmp449_safe_receiver == null)
                null;
              else {
                tmp449_safe_receiver.bhg(ctx, Companion_getInstance_41().i2m_1.p2n(this.khg_1, this.lhg_1), tmp448_safe_receiver, this.ihg_1, this.jhg_1, Colors_getInstance().g4r_1, Companion_getInstance_17().ra5_1, this.mhg_1);
              }
            }
            tmp_1 = Unit_instance;
          }finally {
            this_3.ya8();
            this_3.za6_1 = temp4;
            this_3.aa7_1 = temp2d;
            this_3.y8r(ProjViewUB_getInstance()).x9h();
          }
          break $l$block_0;
        }finally {
          this_0.eaf();
          this_0.ra8_1 = temp;
        }
      }
    }finally {
      ctx.nai();
    }
    this.qhg_1 = Texture_0(fb).v42(0, 0, this.ihg_1, this.jhg_1);
  }
  o18() {
    if (this.phg_1 == null || this.rhg_1 == null)
      return Unit_instance;
    var tmp450_safe_receiver = this.phg_1;
    if (tmp450_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.filter.RenderToTextureResult.dispose.<anonymous>' call
      var tmp451_safe_receiver = this.rhg_1;
      var tmp;
      if (tmp451_safe_receiver == null) {
        tmp = null;
      } else {
        tmp451_safe_receiver.lai(tmp450_safe_receiver);
        tmp = Unit_instance;
      }
    }
    this.hhg_1 = null;
    this.ohg_1 = null;
    this.phg_1 = null;
    this.rhg_1 = null;
    this.qhg_1 = null;
  }
}
class IdentityFilter {
  constructor(smoothing) {
    Companion_getInstance_80();
    this.shg_1 = smoothing;
  }
  bhg(ctx, matrix, texture, texWidth, texHeight, renderColorMul, blendMode, filterScale) {
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    this_0.aa9(texture, VOID, VOID, VOID, VOID, matrix, this.shg_1, renderColorMul, blendMode, Companion_getInstance_8().yad_1);
  }
}
class Companion_32 extends IdentityFilter {
  constructor() {
    Companion_instance_47 = null;
    super(true);
    Companion_instance_47 = this;
  }
}
class Linear extends IdentityFilter {
  constructor() {
    Linear_instance = null;
    super(true);
    Linear_instance = this;
  }
}
class Nearest extends IdentityFilter {
  constructor() {
    Nearest_instance = null;
    super(false);
    Nearest_instance = this;
  }
}
class PageUB extends UniformBlock {
  constructor() {
    PageUB_instance = null;
    super(5);
    PageUB_instance = this;
    this.zhj_1 = this.z8z().p8z(this, u_Offset$factory());
    this.ahk_1 = this.b90().p8z(this, u_HAmplitude$factory());
    this.bhk_1 = this.b90().p8z(this, u_VAmplitude$factory());
  }
  chk() {
    return this.zhj_1.ko(this, u_Offset$factory_0());
  }
  dhk() {
    return this.ahk_1.ko(this, u_HAmplitude$factory_0());
  }
  ehk() {
    return this.bhk_1.ko(this, u_VAmplitude$factory_0());
  }
}
class Companion_33 extends BaseProgramProvider {
  constructor() {
    Companion_instance_48 = null;
    super();
    Companion_instance_48 = this;
    var tmp = this;
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>' call
    var x01 = this_0.o92(DefaultShaders_getInstance().v8x_1, 'zw');
    this_0.f92(x01, Companion_getInstance_83().fhk(this_0));
    var inductionVariable = 0;
    if (inductionVariable <= 1)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var vr = this_0.z94(x01, n);
        var offset = this_0.z94(PageUB_getInstance().chk(), n);
        var amplitudes = n === 0 ? this_0.o92(PageUB_getInstance().dhk(), 'xyz') : this_0.o92(PageUB_getInstance().ehk(), 'xyz');
        var tmp_0 = this_0.z94(DefaultShaders_getInstance().v8x_1, n);
        // Inline function 'korlibs.graphics.shader.Builder.IF' call
        var cond = this_0.n95(vr, offset);
        var body = this_0.w92();
        // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>.<anonymous>' call
        var ratio = body.u92(body.g95(vr, body.l92(0.0)), offset);
        body.f92(tmp_0, body.n94(body.z94(amplitudes, 0), body.z94(amplitudes, 1), sin01(body, Companion_getInstance_81(), ratio)));
        var stmIf = new If(cond, body.y92());
        // Inline function 'kotlin.collections.plusAssign' call
        this_0.e92_1.f(stmIf);
        var tmp_1 = stmIf;
        this_0.l93(tmp_1, PageFilter$Companion$fragment$lambda(vr, offset, tmp_0, amplitudes));
      }
       while (inductionVariable <= 1);
    this_0.f92(this_0.i92(), Companion_getInstance_83().phh(this_0, this_0.i95(Companion_getInstance_83().ohh(this_0), this_0.o92(DefaultShaders_getInstance().v8x_1, 'yx'))));
    tmp.jhk_1 = FragmentShader(this_0.n92());
  }
  qhh() {
    return this.jhk_1;
  }
}
class PageFilter extends ShaderFilter {
  constructor(hratio, hamplitude0, hamplitude1, hamplitude2, vratio, vamplitude0, vamplitude1, vamplitude2) {
    Companion_getInstance_81();
    hratio = hratio === VOID ? Companion_getInstance_45().f10_1 : hratio;
    hamplitude0 = hamplitude0 === VOID ? 0.0 : hamplitude0;
    hamplitude1 = hamplitude1 === VOID ? 10.0 : hamplitude1;
    hamplitude2 = hamplitude2 === VOID ? 0.0 : hamplitude2;
    vratio = vratio === VOID ? Companion_getInstance_45().h10_1 : vratio;
    vamplitude0 = vamplitude0 === VOID ? 0.0 : vamplitude0;
    vamplitude1 = vamplitude1 === VOID ? 0.0 : vamplitude1;
    vamplitude2 = vamplitude2 === VOID ? 0.0 : vamplitude2;
    super();
    this.rhk_1 = hratio;
    this.shk_1 = hamplitude0;
    this.thk_1 = hamplitude1;
    this.uhk_1 = hamplitude2;
    this.vhk_1 = vratio;
    this.whk_1 = vamplitude0;
    this.xhk_1 = vamplitude1;
    this.yhk_1 = vamplitude2;
  }
  ihi() {
    return Companion_getInstance_81();
  }
  ahg(texWidth, texHeight) {
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.abs' call
    var x = this.shk_1;
    var a = Math.abs(x);
    // Inline function 'kotlin.math.abs' call
    var x_0 = this.thk_1;
    var b = Math.abs(x_0);
    var a_0 = Math.max(a, b);
    // Inline function 'kotlin.math.abs' call
    var x_1 = this.uhk_1;
    var b_0 = Math.abs(x_1);
    var tmp$ret$4 = Math.max(a_0, b_0);
    return MarginInt.g2m(toIntCeil(tmp$ret$4));
  }
  jhi(ctx, filterScale) {
    super.jhi(ctx, filterScale);
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.y8r(PageUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.filter.PageFilter.updateUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.f9i($this$push.chk(), Ratio__toFloat_impl_1ftup5(this.rhk_1), Ratio__toFloat_impl_1ftup5(this.vhk_1));
      it.i9i($this$push.dhk(), this.shk_1, this.thk_1, this.uhk_1, 0.0);
      it.i9i($this$push.ehk(), this.whk_1, this.xhk_1, this.yhk_1, 0.0);
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
  }
}
class Companion_34 extends BaseProgramProvider {
  constructor() {
    Companion_instance_49 = null;
    super();
    Companion_instance_49 = this;
  }
}
class TexInfoUB extends UniformBlock {
  constructor() {
    TexInfoUB_instance = null;
    super(4);
    TexInfoUB_instance = this;
    this.ahj_1 = this.z8z().p8z(this, u_TextureSize$factory());
    this.bhj_1 = this.z8z().p8z(this, u_MaxTexCoords$factory());
    this.chj_1 = this.z8z().p8z(this, u_StdTexDerivates$factory());
    this.dhj_1 = this.x8z().p8z(this, u_filterScale$factory());
  }
  zhk() {
    return this.ahj_1.ko(this, u_TextureSize$factory_0());
  }
  ahl() {
    return this.bhj_1.ko(this, u_MaxTexCoords$factory_0());
  }
  ehj() {
    return this.chj_1.ko(this, u_StdTexDerivates$factory_0());
  }
  bhl() {
    return this.dhj_1.ko(this, u_filterScale$factory_0());
  }
}
class Companion_35 {
  constructor() {
    Companion_instance_50 = this;
    this.nhh_1 = Companion_getInstance_8().yad_1.j8s_1;
  }
  fhj(_this__u8e3s4) {
    return _this__u8e3s4.o92(DefaultShaders_getInstance().t8x_1, 'xy');
  }
  ohh(_this__u8e3s4) {
    return _this__u8e3s4.j92(this.fhj(_this__u8e3s4), TexInfoUB_getInstance().zhk());
  }
  phh(_this__u8e3s4, coords) {
    return _this__u8e3s4.q92(DefaultShaders_getInstance().p92(), _this__u8e3s4.u92(coords, TexInfoUB_getInstance().zhk()));
  }
  fhk(_this__u8e3s4) {
    return _this__u8e3s4.u92(_this__u8e3s4.o92(DefaultShaders_getInstance().t8x_1, 'xy'), TexInfoUB_getInstance().ahl());
  }
  ghj(_this__u8e3s4, sampler, coords, check) {
    var tmp;
    if (check) {
      tmp = _this__u8e3s4.b94(_this__u8e3s4.t95(_this__u8e3s4.u95(_this__u8e3s4.a95(coords), _this__u8e3s4.l92(0.0), _this__u8e3s4.l92(1.0)), _this__u8e3s4.u95(_this__u8e3s4.b95(coords), _this__u8e3s4.l92(0.0), _this__u8e3s4.l92(1.0))), _this__u8e3s4.q92(sampler, coords), _this__u8e3s4.m92([_this__u8e3s4.l92(0.0)]));
    } else {
      tmp = _this__u8e3s4.q92(sampler, coords);
    }
    return tmp;
  }
}
class SwizzleProgram extends BaseProgramProvider {
  constructor(swizzle) {
    super();
    this.ghl_1 = swizzle;
    var tmp = this;
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.graphics.shader.appending' call
      var this_0 = Companion_getInstance_83().nhh_1;
      if (this_0.d9r()) {
        tmp$ret$0 = this_0;
        break $l$block;
      }
      // Inline function 'kotlin.also' call
      var this_1 = ProgramBuilderDefault.a92().o93(this_0);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.filter.SwizzleProgram.fragment.<anonymous>' call
      this_1.f92(this_1.i92(), this_1.o92(this_1.i92(), this.ghl_1));
      var pair = this_1.n92();
      tmp$ret$0 = new Shader(this_0.e9j_1, pair.im_1, pair.jm_1);
    }
    tmp.hhl_1 = tmp$ret$0;
  }
  qhh() {
    return this.hhl_1;
  }
}
class Companion_36 {
  constructor() {
    Companion_instance_51 = this;
    this.ihl_1 = new Lock();
    this.jhl_1 = LinkedHashMap.vc();
  }
}
class SwizzleColorsFilter extends ShaderFilter {
  constructor(initialSwizzle) {
    Companion_getInstance_84();
    initialSwizzle = initialSwizzle === VOID ? 'rgba' : initialSwizzle;
    super();
    this.rhl_1 = null;
    this.shl_1 = initialSwizzle;
  }
  thl(value) {
    this.shl_1 = value;
    this.rhl_1 = null;
  }
  ihi() {
    if (this.rhl_1 == null) {
      var tmp = this;
      var tmp$ret$3;
      $l$block: {
        // Inline function 'korlibs.concurrent.lock.BaseLock.invoke' call
        var this_0 = Companion_getInstance_84().ihl_1;
        this_0.w1w();
        try {
          // Inline function 'korlibs.korge.view.filter.SwizzleColorsFilter.<get-programProvider>.<anonymous>' call
          // Inline function 'kotlin.collections.getOrPut' call
          var this_1 = Companion_getInstance_84().jhl_1;
          var key = this.shl_1;
          var value = this_1.k3(key);
          var tmp_0;
          if (value == null) {
            // Inline function 'korlibs.korge.view.filter.SwizzleColorsFilter.<get-programProvider>.<anonymous>.<anonymous>' call
            var answer = new SwizzleProgram(this.shl_1);
            this_1.r3(key, answer);
            tmp_0 = answer;
          } else {
            tmp_0 = value;
          }
          tmp$ret$3 = tmp_0;
          break $l$block;
        }finally {
          this_0.z1w();
        }
      }
      tmp.rhl_1 = tmp$ret$3;
    }
    return ensureNotNull(this.rhl_1);
  }
}
class Companion_37 {
  constructor() {
    Companion_instance_52 = this;
    this.uhl_1 = 64;
    var tmp = this;
    tmp.vhl_1 = lazy(TransitionFilter$Transition$Companion$VERTICAL$delegate$lambda);
    var tmp_0 = this;
    tmp_0.whl_1 = lazy(TransitionFilter$Transition$Companion$HORIZONTAL$delegate$lambda);
    var tmp_1 = this;
    tmp_1.xhl_1 = lazy(TransitionFilter$Transition$Companion$DIAGONAL1$delegate$lambda);
    var tmp_2 = this;
    tmp_2.yhl_1 = lazy(TransitionFilter$Transition$Companion$DIAGONAL2$delegate$lambda);
    var tmp_3 = this;
    tmp_3.zhl_1 = lazy(TransitionFilter$Transition$Companion$CIRCULAR$delegate$lambda);
    var tmp_4 = this;
    tmp_4.ahm_1 = lazy(TransitionFilter$Transition$Companion$SWEEP$delegate$lambda);
  }
  bhm() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.zhl_1;
    CIRCULAR$factory();
    return this_0.b2();
  }
  chm() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ahm_1;
    SWEEP$factory();
    return this_0.b2();
  }
}
class Transition {
  constructor(bmp) {
    Companion_getInstance_85();
    this.dhm_1 = bmp;
  }
}
class TransitionUB extends UniformBlock {
  constructor() {
    TransitionUB_instance = null;
    super(5);
    TransitionUB_instance = this;
    this.ihm_1 = this.x8z().p8z(this, u_Reversed$factory());
    this.jhm_1 = this.x8z().p8z(this, u_Spread$factory());
    this.khm_1 = this.x8z().p8z(this, u_Ratio$factory());
  }
  lhm() {
    return this.ihm_1.ko(this, u_Reversed$factory_0());
  }
  mhm() {
    return this.jhm_1.ko(this, u_Spread$factory_0());
  }
  nhm() {
    return this.khm_1.ko(this, u_Ratio$factory_0());
  }
}
class Companion_38 extends BaseProgramProvider {
  constructor() {
    Companion_instance_53 = null;
    super();
    Companion_instance_53 = this;
    this.rhm_1 = DefaultShaders_getInstance().r92();
    var tmp = this;
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.graphics.shader.appending' call
      var this_0 = Companion_getInstance_83().nhh_1;
      if (this_0.d9r()) {
        tmp$ret$0 = this_0;
        break $l$block;
      }
      // Inline function 'kotlin.also' call
      var this_1 = ProgramBuilderDefault.a92().o93(this_0);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>' call
      var alpha = this_1.a95(DefaultShaders_getInstance().w8x_1);
      var spread = this_1.b95(DefaultShaders_getInstance().w8x_1);
      this_1.f92(alpha, this_1.e95(this_1.q92(Companion_getInstance_86().rhm_1, Companion_getInstance_83().fhk(this_1))));
      // Inline function 'korlibs.graphics.shader.Builder.IF' call
      var cond = this_1.l95(TransitionUB_getInstance().lhm(), this_1.l92(1.0));
      var body = this_1.w92();
      // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>.<anonymous>' call
      body.f92(alpha, body.g95(body.l92(1.0), alpha));
      var stmIf = new If(cond, body.y92());
      // Inline function 'kotlin.collections.plusAssign' call
      this_1.e92_1.f(stmIf);
      this_1.f92(alpha, this_1.l94(this_1.i95(alpha, this_1.g95(this_1.j92(TransitionUB_getInstance().nhm(), this_1.l92(2.0)), this_1.l92(1.0))), this_1.l92(0.0), this_1.l92(1.0)));
      this_1.f92(spread, this_1.j92(this_1.l94(TransitionUB_getInstance().mhm(), this_1.l92(0.01), this_1.l92(1.0)), this_1.l92(0.5)));
      this_1.f92(alpha, this_1.o94(this_1.k94(this_1.g95(TransitionUB_getInstance().nhm(), spread)), this_1.k94(this_1.i95(TransitionUB_getInstance().nhm(), spread)), alpha));
      this_1.f92(this_1.i92(), this_1.j92(this_1.i92(), alpha));
      var pair = this_1.n92();
      tmp$ret$0 = new Shader(this_0.e9j_1, pair.im_1, pair.jm_1);
    }
    tmp.shm_1 = tmp$ret$0;
  }
  qhh() {
    return this.shm_1;
  }
}
class TransitionFilter extends ShaderFilter {
  constructor(transition, reversed, spread, ratio, filtering) {
    Companion_getInstance_86();
    transition = transition === VOID ? Companion_getInstance_85().bhm() : transition;
    reversed = reversed === VOID ? false : reversed;
    spread = spread === VOID ? 1.0 : spread;
    ratio = ratio === VOID ? Companion_getInstance_45().i10_1 : ratio;
    filtering = filtering === VOID ? false : filtering;
    super();
    this.ahn_1 = transition;
    this.fhf(filtering);
    this.bhn_1 = reversed;
    this.chn_1 = spread;
    this.dhn_1 = ratio;
  }
  ihi() {
    return Companion_getInstance_86();
  }
  jhi(ctx, filterScale) {
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.y8r(TransitionUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.filter.TransitionFilter.updateUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.a9i($this$push.lhm(), this.bhn_1);
      it.c9i($this$push.mhm(), this.chn_1);
      it.d9i($this$push.nhm(), this.dhn_1);
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    this.chl(ctx, Companion_getInstance_86().rhm_1, ctx.gai(this.ahn_1.dhm_1).wab_1, Companion_getInstance_23().y8o_1);
  }
}
class ViewRenderPhaseBackdropFilter {}
class Companion_39 {
  constructor() {
    this.ghn_1 = -200;
  }
}
class ViewRenderPhaseFilter {
  constructor(filter) {
    filter = filter === VOID ? null : filter;
    this.fhn_1 = filter;
  }
  ih8() {
    return -200;
  }
  lh8(view, ctx) {
    if (!(this.fhn_1 == null)) {
      renderFiltered(view, ctx, ensureNotNull(this.fhn_1), false);
    } else {
      render_0.call(this, view, ctx);
    }
  }
}
class WaveUB extends UniformBlock {
  constructor() {
    WaveUB_instance = null;
    super(5);
    WaveUB_instance = this;
    this.lhn_1 = this.x8z().p8z(this, u_Time$factory());
    this.mhn_1 = this.z8z().p8z(this, u_Amplitude$factory());
    this.nhn_1 = this.z8z().p8z(this, u_crestDistance$factory());
    this.ohn_1 = this.z8z().p8z(this, u_cyclesPerSecond$factory());
  }
  phn() {
    return this.lhn_1.ko(this, u_Time$factory_0());
  }
  qhn() {
    return this.mhn_1.ko(this, u_Amplitude$factory_0());
  }
  rhn() {
    return this.nhn_1.ko(this, u_crestDistance$factory_0());
  }
  shn() {
    return this.ohn_1.ko(this, u_cyclesPerSecond$factory_0());
  }
}
class Companion_40 extends BaseProgramProvider {
  constructor() {
    Companion_instance_55 = null;
    super();
    Companion_instance_55 = this;
    var tmp = this;
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>' call
    this_0.f92(this_0.o92(this_0.t92(), 'xy'), this_0.y93(this_0.j92(this_0.l92(3.141592653589793 * 2.0), this_0.i95(this_0.u92(Companion_getInstance_83().ohh(this_0), WaveUB_getInstance().rhn()), this_0.j92(WaveUB_getInstance().phn(), WaveUB_getInstance().shn())))));
    this_0.f92(this_0.i92(), Companion_getInstance_83().phh(this_0, this_0.g95(Companion_getInstance_83().ohh(this_0), this_0.j92(this_0.o92(this_0.t92(), 'yx'), WaveUB_getInstance().qhn()))));
    tmp.whn_1 = FragmentShader(this_0.n92());
  }
  qhh() {
    return this.whn_1;
  }
}
class WaveFilter extends ShaderFilter {
  constructor(amplitude, crestDistance, cyclesPerSecond, fastTime) {
    Companion_getInstance_88();
    amplitude = amplitude === VOID ? Vector2D.k2l(10, 10) : amplitude;
    crestDistance = crestDistance === VOID ? Vector2D.k2l(16, 16) : crestDistance;
    cyclesPerSecond = cyclesPerSecond === VOID ? Vector2D.k2l(1, 1) : cyclesPerSecond;
    fastTime = fastTime === VOID ? get_fastSeconds(0) : fastTime;
    super();
    this.eho_1 = amplitude;
    this.fho_1 = crestDistance;
    this.gho_1 = cyclesPerSecond;
    this.hho_1 = fastTime;
  }
  iho(value) {
    this.hho_1 = get_fast(value);
  }
  a6n() {
    return toDuration_0(this.hho_1);
  }
  ihi() {
    return Companion_getInstance_88();
  }
  jhi(ctx, filterScale) {
    super.jhi(ctx, filterScale);
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.y8r(WaveUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.view.filter.WaveFilter.updateUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.b9i($this$push.phn(), get_seconds(this.a6n()));
      it.g9i($this$push.qhn(), this.eho_1);
      it.g9i($this$push.rhn(), this.fho_1);
      it.g9i($this$push.shn(), this.gho_1);
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
  }
  ahg(texWidth, texHeight) {
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = this.eho_1.j2h_1;
    var tmp$ret$0 = Math.abs(this_0);
    var tmp = toIntCeil(tmp$ret$0);
    // Inline function 'kotlin.math.absoluteValue' call
    var this_1 = this.eho_1.i2h_1;
    var tmp$ret$1 = Math.abs(this_1);
    return MarginInt.f2m(tmp, toIntCeil(tmp$ret$1));
  }
}
class Info_0 {
  constructor(tex, vertices) {
    this.jho_1 = tex;
    this.kho_1 = vertices;
    var tmp = this;
    // Inline function 'kotlin.also' call
    var this_0 = FastArrayList.g14();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.tiles.Info.verticesList.<anonymous>' call
    this_0.f(this.kho_1);
    tmp.lho_1 = this_0;
  }
  mho(vertices) {
    vertices.z33();
    this.kho_1 = vertices;
    this.lho_1.f(vertices);
  }
  z33() {
    this.kho_1.yak_1 = 0;
    this.kho_1.xak_1 = 0;
  }
}
class Companion_41 {
  constructor() {
    Companion_instance_56 = this;
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(8), null);
    while (tmp_0 < 8) {
      var tmp_2 = tmp_0;
      var tmp_3 = Companion_getInstance_89();
      // Inline function 'korlibs.memory.extract' call
      // Inline function 'korlibs.memory.extract1' call
      var tmp_4 = !(((tmp_2 >>> 0 | 0) & 1) === 0);
      // Inline function 'korlibs.memory.extract' call
      // Inline function 'korlibs.memory.extract1' call
      var tmp_5 = !(((tmp_2 >>> 1 | 0) & 1) === 0);
      // Inline function 'korlibs.memory.extract' call
      // Inline function 'korlibs.memory.extract1' call
      var tmp$ret$6 = !(((tmp_2 >>> 2 | 0) & 1) === 0);
      tmp_1[tmp_2] = computeIndices$default(tmp_3, tmp_4, tmp_5, tmp$ret$6);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.nho_1 = tmp_1;
  }
}
class TileMap extends View {
  static nhr(map, tileset, smoothing, tileSize) {
    Companion_getInstance_89();
    map = map === VOID ? TileMapData.z70(1, 1) : map;
    tileset = tileset === VOID ? map.v70_1 : tileset;
    smoothing = smoothing === VOID ? true : smoothing;
    tileSize = tileSize === VOID ? tileset.c72() : tileSize;
    var $this = this.hgn();
    $this.ohq_1 = map;
    $this.phq_1 = smoothing;
    $this.qhq_1 = tileSize;
    $this.rhq_1 = 0.0;
    $this.shq_1 = 0.0;
    $this.thq_1 = $this.ohq_1.w70_1;
    $this.uhq_1 = $this.ohq_1.x70_1;
    $this.vhq_1 = 0;
    $this.whq_1 = -1;
    $this.xhq_1 = -1;
    $this.yhq_1 = new FastIdentityCacheMap();
    var tmp = $this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.zhq_1 = ArrayList.m1();
    var tmp_0 = $this;
    var tmp_1 = TileMap$infosPool$lambda;
    tmp_0.ahr_1 = Pool.c39(tmp_1, VOID, TileMap$infosPool$lambda_0);
    $this.bhr_1 = Companion_getInstance_38().d2t(-1, -1, -1, -1);
    $this.chr_1 = Companion_getInstance_38().d2t(-1, -1, -1, -1);
    $this.dhr_1 = new Float32Array(4);
    $this.ehr_1 = new Float32Array(4);
    $this.fhr_1 = 0;
    $this.ghr_1 = 0;
    var tmp_2 = $this;
    // Inline function 'kotlin.emptyArray' call
    tmp_2.hhr_1 = [];
    $this.ihr_1 = new Int32Array(0);
    $this.jhr_1 = new Float64Array(0);
    $this.khr_1 = tileset;
    updatedTileSet($this);
    addFastUpdater_0($this, TileMap$lambda(tileset));
    return $this;
  }
  ohr(value) {
    this.ohq_1 = TileMapData.b71(asLong(value));
  }
  w1w() {
  }
  z1w() {
  }
  jc7(ctx) {
    if (!this.qcc())
      return Unit_instance;
    computeVertexIfRequired(this, ctx);
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    var this_2 = this.zhq_1;
    var n = 0;
    while (n < this_2.j1()) {
      // Inline function 'korlibs.korge.view.tiles.TileMap.renderInternal.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var info = this_2.i1(_unary__edvuaz);
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = info.lho_1.e14_1;
      var n_0 = 0;
      while (n_0 < array.length) {
        // Inline function 'korlibs.korge.view.tiles.TileMap.renderInternal.<anonymous>.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz_0 = n_0;
        n_0 = _unary__edvuaz_0 + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        var vertices = array[_unary__edvuaz_0];
        // Inline function 'korlibs.korge.render.BatchBuilder2D.drawVertices' call
        var array_0 = vertices.wak_1;
        var tex = ctx.gai(info.jho_1);
        var smoothing = this.phq_1;
        var blendMode = this.vcc();
        var vcount = vertices.xak_1;
        var icount = vertices.yak_1;
        var matrix = Companion_getInstance_41().j2m_1;
        $l$block: {
          // Inline function 'korlibs.korge.render.BatchBuilder2D.setStateFast' call
          var tex_0 = tex.wab_1;
          this_0.rae(icount, vcount);
          var pprogram = null == null ? Companion_getInstance_8().yad_1 : null;
          if (this_0.gaf(tex_0, smoothing, blendMode, pprogram)) {
            break $l$block;
          }
          this_0.gae();
          if (!contains_0(this_0.ja8_1, tex_0)) {
            this_0.ia8_1 = 0;
            this_0.ja8_1[0] = tex_0;
          }
          this_0.ka8_1 = smoothing;
          this_0.la8_1 = blendMode;
          this_0.ma8_1 = pprogram;
        }
        this_0.daf(array_0, matrix, vcount, icount);
      }
    }
  }
  phr(value) {
    if (this.khr_1 === value)
      return Unit_instance;
    $l$block: {
      // Inline function 'korlibs.korge.view.tiles.TileMap.lock' call
      this.w1w();
      try {
        this.khr_1 = value;
        updatedTileSet(this);
        break $l$block;
      }finally {
        this.z1w();
      }
    }
  }
  static qhr(map, tileset, smoothing, tileSize) {
    Companion_getInstance_89();
    smoothing = smoothing === VOID ? true : smoothing;
    tileSize = tileSize === VOID ? tileset.c72() : tileSize;
    return this.nhr(TileMapData.b71(asLong(map)), tileset, smoothing, tileSize);
  }
  static rhr(map, tileset, smoothing, tileSize) {
    Companion_getInstance_89();
    smoothing = smoothing === VOID ? true : smoothing;
    tileSize = tileSize === VOID ? tileset.c72() : tileSize;
    return this.qhr(toStacked(map), tileset, smoothing, tileSize);
  }
  gc7() {
    return Companion_getInstance_38().e2t(0.0, 0.0, this.rhq_1 * this.ohq_1.j2y(), this.shq_1 * this.ohq_1.k2y());
  }
  shr(repeatX, repeatY) {
    this.thq_1 = repeatX;
    this.uhq_1 = repeatY;
    return this;
  }
}
class PointsResult {
  constructor(bounds, vertexCount, vertexStart, vertexEnd) {
    this.dht_1 = bounds;
    this.eht_1 = vertexCount;
    this.fht_1 = vertexStart;
    this.ght_1 = vertexEnd;
  }
}
class GpuShapeView extends View {
  static wh3(shape, antialiased, autoScaling) {
    shape = shape === VOID ? EmptyShape_instance : shape;
    antialiased = antialiased === VOID ? true : antialiased;
    autoScaling = autoScaling === VOID ? true : autoScaling;
    var $this = this.hgn();
    $this.xh2_1 = autoScaling;
    $this.yh2_1 = true;
    $this.zh2_1 = true;
    $this.ah3_1 = true;
    $this.bh3_1 = new GpuShapeViewCommands();
    $this.ch3_1 = 1000;
    $this.dh3_1 = 1000;
    $this.eh3_1 = Companion_getInstance_32().p2g_1;
    $this.fh3_1 = true;
    $this.gh3_1 = false;
    $this.hh3_1 = antialiased;
    $this.ih3_1 = shape;
    $this.jh3_1 = false;
    $this.kh3_1 = false;
    $this.lh3_1 = false;
    $this.mh3_1 = 0;
    $this.nh3_1 = Companion_getInstance_38().l2m();
    $this.oh3_1 = Companion_getInstance_38().l2m();
    $this.ph3_1 = false;
    $this.qh3_1 = 1.0;
    $this.rh3_1 = NaN;
    $this.sh3_1 = Companion_getInstance_41().l2m();
    $this.th3_1 = 100000;
    $this.uh3_1 = false;
    $this.vh3_1 = 10000.0;
    invalidateShape($this);
    return $this;
  }
  hgb(value) {
    this.ah3_1 = value;
    this.ycc();
  }
  tf4(value) {
    this.eh3_1 = value;
    this.cca();
  }
  uf4() {
    return this.eh3_1;
  }
  yh3(value) {
    this.hh3_1 = value;
    invalidateShape(this);
  }
  xh3(value) {
    this.ih3_1 = value;
    invalidateShape(this);
  }
  zca() {
    return _get_shapeBounds__actvvf(this).k2i_1 * this.uf4().y2g_1;
  }
  acb() {
    return _get_shapeBounds__actvvf(this).l2i_1 * this.uf4().z2g_1;
  }
  cca() {
    super.cca();
    invalidateShape(this);
  }
  gc7() {
    return new Rectangle(_get_shapeBounds__actvvf(this).i2i_1 - this.zca(), _get_shapeBounds__actvvf(this).j2i_1 - this.acb(), _get_shapeBounds__actvvf(this).k2i_1, _get_shapeBounds__actvvf(this).l2i_1);
  }
  iht(_this__u8e3s4) {
    var tmp;
    if (equals(_this__u8e3s4, EmptyShape_instance)) {
      tmp = false;
    } else {
      if (_this__u8e3s4 instanceof CompoundShape) {
        var tmp$ret$0;
        $l$block_0: {
          // Inline function 'kotlin.collections.any' call
          var this_0 = _this__u8e3s4.y78_1;
          var tmp_0;
          if (isInterface(this_0, Collection)) {
            tmp_0 = this_0.a1();
          } else {
            tmp_0 = false;
          }
          if (tmp_0) {
            tmp$ret$0 = false;
            break $l$block_0;
          }
          var tmp0_iterator = this_0.f1();
          while (tmp0_iterator.g1()) {
            var element = tmp0_iterator.h1();
            // Inline function 'korlibs.korge.view.vector.GpuShapeView.<get-requireStencil>.<anonymous>' call
            if (this.iht(element)) {
              tmp$ret$0 = true;
              break $l$block_0;
            }
          }
          tmp$ret$0 = false;
        }
        tmp = tmp$ret$0;
      } else {
        if (_this__u8e3s4 instanceof TextShape) {
          tmp = this.iht(_this__u8e3s4.b7a());
        } else {
          if (_this__u8e3s4 instanceof FillShape) {
            tmp = !_this__u8e3s4.w78();
          } else {
            if (_this__u8e3s4 instanceof PolylineShape) {
              tmp = false;
            } else {
              tmp = true;
            }
          }
        }
      }
    }
    return tmp;
  }
  jc7(ctx) {
    this.qh3_1 = this.ybp().z2n().x2o() * ctx.na6_1.rag();
    if (!(this.rh3_1 === this.qh3_1)) {
      invalidateShape(this);
    }
    ctx.ya8();
    var doRequireTexture = this.iht(this.ih3_1);
    // Inline function 'korlibs.time.measureTime' call
    // Inline function 'kotlin.time.measureTime' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.time.measureTime' call
    // Inline function 'kotlin.contracts.contract' call
    var mark = Monotonic_instance.bk();
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderInternal.<anonymous>' call
    if (doRequireTexture) {
      var currentFrameBuffer = ctx.ma6_1.h8k();
      this.ch3_1 = currentFrameBuffer.a8k_1;
      this.dh3_1 = currentFrameBuffer.b8k_1;
      // Inline function 'korlibs.korge.render.RenderContext.renderToTexture' call
      var width = this.ch3_1;
      var height = this.dh3_1;
      ctx.ya8();
      // Inline function 'korlibs.korge.render.RenderContext.tempAllocateFrameBuffer' call
      var rb = ctx.kai(width, height, false, true, 1);
      try {
        // Inline function 'korlibs.korge.render.RenderContext.renderToTexture.<anonymous>' call
        // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer' call
        // Inline function 'korlibs.korge.render.RenderContext.setFrameBufferTemporally' call
        ctx.mai(rb);
        try {
          // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer.<anonymous>' call
          // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
          // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
          var this_0 = ctx.ka7_1;
          var this_1 = this_0.ta7_1;
          if (!(this_1.oa7_1 === this_0)) {
            this_1.oa7_1 = this_0;
            this_1.ya8();
          }
          $l$block: {
            // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
            var scissor = AGScissor.p8v(0, 0, rb.a8k_1, rb.b8k_1);
            var temp = this_0.ra8_1;
            this_0.eaf();
            this_0.ra8_1 = scissor;
            try {
              if (true) {
                ctx.eai(Colors_getInstance().l4r_1);
              }
              renderCommands(this, ctx, doRequireTexture);
              break $l$block;
            }finally {
              this_0.eaf();
              this_0.ra8_1 = temp;
            }
          }
        }finally {
          ctx.nai();
        }
        // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderInternal.<anonymous>.<anonymous>' call
        var texture = Texture_0(rb).v42(0, 0, width, height);
        // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
        // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
        var this_2 = ctx.ka7_1;
        var this_3 = this_2.ta7_1;
        if (!(this_3.oa7_1 === this_2)) {
          this_3.oa7_1 = this_2;
          this_3.ya8();
        }
        this_2.aa9(texture, VOID, VOID, VOID, VOID, get_immutable(ctx.na6_1.bag()));
        ctx.ya8();
      }finally {
        ctx.lai(rb);
      }
    } else {
      renderCommands(this, ctx, doRequireTexture);
    }
    var time = ValueTimeMark__elapsedNow_impl_eonqvs(mark);
  }
}
class ScissorCommand {
  constructor(scissor) {
    this.jht_1 = scissor;
  }
  toString() {
    return 'ScissorCommand(scissor=' + this.jht_1.toString() + ')';
  }
  hashCode() {
    return this.jht_1.hashCode();
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ScissorCommand))
      return false;
    var tmp0_other_with_cast = other instanceof ScissorCommand ? other : THROW_CCE();
    if (!this.jht_1.equals(tmp0_other_with_cast.jht_1))
      return false;
    return true;
  }
}
class ClearCommand {
  constructor(i) {
    this.kht_1 = i;
  }
  toString() {
    return 'ClearCommand(i=' + this.kht_1 + ')';
  }
  hashCode() {
    return this.kht_1;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ClearCommand))
      return false;
    var tmp0_other_with_cast = other instanceof ClearCommand ? other : THROW_CCE();
    if (!(this.kht_1 === tmp0_other_with_cast.kht_1))
      return false;
    return true;
  }
}
class ShapeCommand {
  constructor(drawType, vertexIndex, vertexEnd, paintShader, program, colorMask, stencilOpFunc, stencilRef, blendMode, cullFace) {
    drawType = drawType === VOID ? Companion_getInstance_18().i8u_1 : drawType;
    vertexIndex = vertexIndex === VOID ? 0 : vertexIndex;
    vertexEnd = vertexEnd === VOID ? 0 : vertexEnd;
    program = program === VOID ? null : program;
    colorMask = colorMask === VOID ? Companion_getInstance_29().n8l_1 : colorMask;
    stencilOpFunc = stencilOpFunc === VOID ? Companion_getInstance_24().k8l_1 : stencilOpFunc;
    stencilRef = stencilRef === VOID ? Companion_getInstance_25().i8l_1 : stencilRef;
    blendMode = blendMode === VOID ? null : blendMode;
    cullFace = cullFace === VOID ? Companion_getInstance_28().y8l_1 : cullFace;
    this.lht_1 = drawType;
    this.mht_1 = vertexIndex;
    this.nht_1 = vertexEnd;
    this.oht_1 = paintShader;
    this.pht_1 = program;
    this.qht_1 = colorMask;
    this.rht_1 = stencilOpFunc;
    this.sht_1 = stencilRef;
    this.tht_1 = blendMode;
    this.uht_1 = cullFace;
  }
  kae() {
    return this.nht_1 - this.mht_1 | 0;
  }
  toString() {
    return 'ShapeCommand(drawType=' + AGDrawType__toString_impl_eonaeb(this.lht_1) + ', vertexIndex=' + this.mht_1 + ', vertexEnd=' + this.nht_1 + ', paintShader=' + toString_0(this.oht_1) + ', program=' + toString_0(this.pht_1) + ', colorMask=' + AGColorMask__toString_impl_5i5oqg(this.qht_1) + ', stencilOpFunc=' + AGStencilOpFunc__toString_impl_pl8quy(this.rht_1) + ', stencilRef=' + AGStencilReference__toString_impl_qu5r8s(this.sht_1) + ', blendMode=' + toString_0(this.tht_1) + ', cullFace=' + AGCullFace__toString_impl_7d891w(this.uht_1) + ')';
  }
  hashCode() {
    var result = AGDrawType__hashCode_impl_ylaury(this.lht_1);
    result = imul(result, 31) + this.mht_1 | 0;
    result = imul(result, 31) + this.nht_1 | 0;
    result = imul(result, 31) + (this.oht_1 == null ? 0 : this.oht_1.hashCode()) | 0;
    result = imul(result, 31) + (this.pht_1 == null ? 0 : this.pht_1.hashCode()) | 0;
    result = imul(result, 31) + AGColorMask__hashCode_impl_g9082f(this.qht_1) | 0;
    result = imul(result, 31) + AGStencilOpFunc__hashCode_impl_nopebb(this.rht_1) | 0;
    result = imul(result, 31) + AGStencilReference__hashCode_impl_52zufx(this.sht_1) | 0;
    result = imul(result, 31) + (this.tht_1 == null ? 0 : this.tht_1.hashCode()) | 0;
    result = imul(result, 31) + AGCullFace__hashCode_impl_t4e5ur(this.uht_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ShapeCommand))
      return false;
    var tmp0_other_with_cast = other instanceof ShapeCommand ? other : THROW_CCE();
    if (!(this.lht_1 === tmp0_other_with_cast.lht_1))
      return false;
    if (!(this.mht_1 === tmp0_other_with_cast.mht_1))
      return false;
    if (!(this.nht_1 === tmp0_other_with_cast.nht_1))
      return false;
    if (!equals(this.oht_1, tmp0_other_with_cast.oht_1))
      return false;
    if (!equals(this.pht_1, tmp0_other_with_cast.pht_1))
      return false;
    if (!(this.qht_1 === tmp0_other_with_cast.qht_1))
      return false;
    if (!(this.rht_1 === tmp0_other_with_cast.rht_1))
      return false;
    if (!equals(this.sht_1, tmp0_other_with_cast.sht_1))
      return false;
    if (!equals(this.tht_1, tmp0_other_with_cast.tht_1))
      return false;
    if (!(this.uht_1 === tmp0_other_with_cast.uht_1))
      return false;
    return true;
  }
}
class GpuShapeViewCommands {
  constructor() {
    this.thr_1 = 0;
    this.uhr_1 = floatArrayListOf(new Float32Array([]));
    var tmp = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.vhr_1 = ArrayList.m1();
    this.whr_1 = null;
    this.xhr_1 = FastArrayList.g14();
    this.yhr_1 = 0;
    this.zhr_1 = 0;
    this.ahs_1 = MatrixTransform.v2o();
    this.bhs_1 = FastArrayList.g14();
    this.chs_1 = AGTextureUnits.e8o();
  }
  o3() {
    this.thr_1 = 0;
    this.uhr_1.o3();
    this.vhr_1.o3();
  }
  hhs(x, y, len, maxLen) {
    var tmp;
    if (maxLen <= 0.0) {
      var _unary__edvuaz = this.yhr_1;
      this.yhr_1 = _unary__edvuaz + 1 | 0;
      tmp = _unary__edvuaz <= 0;
    } else {
      tmp = false;
    }
    if (tmp) {
      println('Invalid maxLen=' + maxLen);
    }
    this.uhr_1.x30(x, y, len, maxLen);
    var _unary__edvuaz_0 = this.thr_1;
    this.thr_1 = _unary__edvuaz_0 + 1 | 0;
    return _unary__edvuaz_0;
  }
  ghs() {
    this.zhr_1 = this.thr_1;
    return this.zhr_1;
  }
  ihs() {
    return this.thr_1;
  }
  hht(drawType, paintShader, colorMask, stencilOpFunc, stencilRef, blendMode, cullFace, startIndex, endIndex) {
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.vhr_1;
    var element = new ShapeCommand(drawType, startIndex, endIndex, paintShader, VOID, colorMask, stencilOpFunc, stencilRef, blendMode, cullFace);
    this_0.f(element);
  }
  cht(drawType, paintShader, colorMask, stencilOpFunc, stencilRef, blendMode, cullFace, startIndex, endIndex, $super) {
    colorMask = colorMask === VOID ? Companion_getInstance_29().n8l_1 : colorMask;
    stencilOpFunc = stencilOpFunc === VOID ? Companion_getInstance_24().k8l_1 : stencilOpFunc;
    stencilRef = stencilRef === VOID ? Companion_getInstance_25().i8l_1 : stencilRef;
    blendMode = blendMode === VOID ? Companion_getInstance_17().ra5_1 : blendMode;
    cullFace = cullFace === VOID ? Companion_getInstance_28().y8l_1 : cullFace;
    startIndex = startIndex === VOID ? this.zhr_1 : startIndex;
    endIndex = endIndex === VOID ? this.thr_1 : endIndex;
    var tmp;
    if ($super === VOID) {
      this.hht(drawType, paintShader, colorMask, stencilOpFunc, stencilRef, blendMode, cullFace, startIndex, endIndex);
      tmp = Unit_instance;
    } else {
      tmp = $super.hht.call(this, new AGDrawType(drawType), paintShader, new AGColorMask(colorMask), new AGStencilOpFunc(stencilOpFunc), new AGStencilReference(stencilRef), blendMode, new AGCullFace(cullFace), startIndex, endIndex);
    }
    return tmp;
  }
  h9c(i) {
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.vhr_1;
    var element = new ClearCommand(i);
    this_0.f(element);
  }
  dhs(i, $super) {
    i = i === VOID ? 0 : i;
    var tmp;
    if ($super === VOID) {
      this.h9c(i);
      tmp = Unit_instance;
    } else {
      tmp = $super.h9c.call(this, i);
    }
    return tmp;
  }
  ehs(scissor) {
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.vhr_1;
    var element = new ScissorCommand(scissor);
    this_0.f(element);
  }
  j8k() {
    var tmp488_safe_receiver = this.whr_1;
    if (tmp488_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      this.xhr_1.f(tmp488_safe_receiver);
    }
    this.whr_1 = new AgCachedBuffer(_Float32Buffer___get_buffer__impl__x5nu9x(_Float32Buffer___init__impl__403k2m(this.uhr_1.c31())));
  }
  fhs(ctx, globalMatrix, localMatrix, applyScissor, colorMul, doRequireTexture) {
    var tmp0_elvis_lhs = this.whr_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var vertices = tmp;
    ctx.ga7_1.oad(this.xhr_1);
    this.xhr_1.o3();
    ctx.ya8();
    var ag = ctx.ma6_1;
    var vao = AGVertexArrayObject.r8t(fastArrayListOf([AGVertexData.w8t(GpuShapeViewPrograms_getInstance().shs_1, ctx.lad(vertices))]), true);
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    this_0.oaf();
    var tempMat = (doRequireTexture ? Companion_getInstance_41().i2m_1 : this_0.iae()).x2n(globalMatrix);
    // Inline function 'korlibs.korge.render.BatchBuilder2D.setViewMatrixTemp' call
    var this_2 = this_0.ta7_1;
    this_2.ya8();
    var temp4 = this_2.za6_1;
    var temp2d = this_2.aa7_1;
    this_2.aa7_1 = tempMat;
    this_2.za6_1 = toMatrix4(tempMat);
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_3 = this_2.y8r(ProjViewUB_getInstance());
      var _unary__edvuaz = this_3.n8t();
      this_3.v9h(_unary__edvuaz + 1 | 0);
      this_3.w9h(this_3.n8t() + 1 | 0);
      var blockSize = this_3.r8r_1;
      var index0 = imul(this_3.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_3.n8t(), blockSize);
      var texBlockSize = this_3.o8r_1.s8z().j1();
      var texIndex0 = imul(this_3.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_3.n8t(), texBlockSize);
      if (this_3.n8t() > 0) {
        arraycopy(this_3.t8r_1, index0, this_3.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_3.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp.<anonymous>' call
      var $this$push = this_3.o8r_1;
      this_3.u8r_1.m9i($this$push.r8z(), this_2.za6_1);
      if (true && this_3.n8t() >= 1) {
        var equals = arrayequal(this_3.t8r_1, index0, this_3.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_3.n8t();
          this_3.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    var tmp_0;
    try {
      this.ahs_1 = globalMatrix.z2n();
      var program = GpuShapeViewPrograms_getInstance().zhs_1;
      var scissor = Companion_getInstance_22().w8l_1;
      var this_4 = this.vhr_1;
      var n = 0;
      while (n < this_4.j1()) {
        // Inline function 'korlibs.korge.view.vector.GpuShapeViewCommands.render.<anonymous>.<anonymous>.<anonymous>' call
        var _unary__edvuaz_1 = n;
        n = _unary__edvuaz_1 + 1 | 0;
        var cmd = this_4.i1(_unary__edvuaz_1);
        if (cmd instanceof ScissorCommand) {
          scissor = cmd.jht_1;
        } else {
          if (cmd instanceof ClearCommand) {
            var tmp490_stencil = cmd.kht_1;
            ctx.eai(VOID, VOID, tmp490_stencil, false, false, true);
          } else {
            if (cmd instanceof ShapeCommand) {
              var paintShader = cmd.oht_1;
              this_0.uaf(cmd.kae());
              var pixelScale = this.ahs_1.x2o() / ctx.na6_1.rag();
              if (!(paintShader == null)) {
                $l$block_0: {
                  // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
                  var this_5 = this_0.ta7_1.y8r(ShapeViewUB_getInstance());
                  var _unary__edvuaz_2 = this_5.n8t();
                  this_5.v9h(_unary__edvuaz_2 + 1 | 0);
                  this_5.w9h(this_5.n8t() + 1 | 0);
                  var blockSize_0 = this_5.r8r_1;
                  var index0_0 = imul(this_5.n8t() - 1 | 0, blockSize_0);
                  var index1_0 = imul(this_5.n8t(), blockSize_0);
                  var texBlockSize_0 = this_5.o8r_1.s8z().j1();
                  var texIndex0_0 = imul(this_5.n8t() - 1 | 0, texBlockSize_0);
                  var texIndex1_0 = imul(this_5.n8t(), texBlockSize_0);
                  if (this_5.n8t() > 0) {
                    arraycopy(this_5.t8r_1, index0_0, this_5.t8r_1, index1_0, blockSize_0);
                  } else {
                    arrayfill(this_5.t8r_1, 0, 0, blockSize_0);
                  }
                  // Inline function 'korlibs.korge.view.vector.GpuShapeViewCommands.render.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                  var $this$push_0 = this_5.o8r_1;
                  var it = this_5.u8r_1;
                  it.z9h(paintShader.vht_1);
                  it.c9i($this$push_0.khu(), pixelScale);
                  it.j9i($this$push_0.lhu(), _RGBA___get_premultiplied__impl__3bulqc(colorMul));
                  if (true && this_5.n8t() >= 1) {
                    var equals_0 = arrayequal(this_5.t8r_1, index0_0, this_5.t8r_1, index1_0, blockSize_0);
                    if (equals_0) {
                      var _unary__edvuaz_3 = this_5.n8t();
                      this_5.v9h(_unary__edvuaz_3 - 1 | 0);
                      break $l$block_0;
                    }
                  }
                }
                if (!(paintShader.wht_1 == null)) {
                  var tex = ctx.ca7_1.g39();
                  tex.f8q(paintShader.wht_1);
                  this.chs_1.a8p(DefaultShaders_getInstance().p92(), tex);
                  this.bhs_1.f(tex);
                }
              }
              var tmp0_elvis_lhs_0 = cmd.pht_1;
              var _program = tmp0_elvis_lhs_0 == null ? program : tmp0_elvis_lhs_0;
              var tmp491_frameBuffer = ctx.pa7_1.t8j_1;
              var tmp492_frameBufferInfo = ctx.pa7_1.c8m();
              var tmp493_scissor = applyMatrixBounds(scissor, tempMat);
              var tmp494_uniformBlocks = ctx.qah(_program);
              var tmp495_textureUnits = this.chs_1.j13();
              var tmp496_stencilOpFunc = cmd.rht_1;
              var tmp497_stencilRef = cmd.sht_1;
              var tmp498_colorMask = cmd.qht_1;
              var tmp1_elvis_lhs = cmd.tht_1;
              var tmp499_blending = (tmp1_elvis_lhs == null ? Companion_getInstance_17().ra5_1 : tmp1_elvis_lhs).ba9_1;
              var tmp500_cullFace = cmd.uht_1;
              var tmp501_drawType = cmd.lht_1;
              var tmp502_drawOffset = cmd.mht_1;
              var tmp503_vertexCount = cmd.kae();
              draw_0(ag, new AGBatch(tmp491_frameBuffer, tmp492_frameBufferInfo, vao, VOID, VOID, _program, tmp494_uniformBlocks, tmp495_textureUnits, tmp499_blending, tmp496_stencilOpFunc, tmp497_stencilRef, tmp498_colorMask, VOID, tmp493_scissor, tmp500_cullFace, tmp501_drawType, tmp502_drawOffset, tmp503_vertexCount));
            } else {
              noWhenBranchMatchedException();
            }
          }
        }
      }
      tmp_0 = Unit_instance;
    }finally {
      this_2.ya8();
      this_2.za6_1 = temp4;
      this_2.aa7_1 = temp2d;
      this_2.y8r(ProjViewUB_getInstance()).x9h();
    }
    var _iterator__ex2g4s = this.bhs_1.f1();
    while (_iterator__ex2g4s.g1()) {
      var tex_0 = _iterator__ex2g4s.h1();
      ctx.ca7_1.h39(tex_0);
    }
    this.bhs_1.o3();
  }
}
class ShapeViewUB extends UniformBlock {
  constructor() {
    ShapeViewUB_instance = null;
    super(5);
    ShapeViewUB_instance = this;
    this.chu_1 = this.x8z().p8z(this, u_ProgramType$factory());
    this.dhu_1 = this.b90().p8z(this, u_Color$factory());
    this.ehu_1 = this.b90().p8z(this, u_ColorMul$factory());
    this.fhu_1 = this.x8z().p8z(this, u_GlobalAlpha$factory());
    this.ghu_1 = this.x8z().p8z(this, u_GlobalPixelScale$factory());
    this.hhu_1 = this.f8z().p8z(this, u_Transform$factory());
    this.ihu_1 = this.b90().p8z(this, u_Gradientp0$factory());
    this.jhu_1 = this.b90().p8z(this, u_Gradientp1$factory());
  }
  mhu() {
    return this.chu_1.ko(this, u_ProgramType$factory_0());
  }
  nhu() {
    return this.dhu_1.ko(this, u_Color$factory_0());
  }
  lhu() {
    return this.ehu_1.ko(this, u_ColorMul$factory_0());
  }
  ohu() {
    return this.fhu_1.ko(this, u_GlobalAlpha$factory_0());
  }
  khu() {
    return this.ghu_1.ko(this, u_GlobalPixelScale$factory_0());
  }
  phu() {
    return this.hhu_1.ko(this, u_Transform$factory_0());
  }
  qhu() {
    return this.ihu_1.ko(this, u_Gradientp0$factory_0());
  }
  rhu() {
    return this.jhu_1.ko(this, u_Gradientp1$factory_0());
  }
}
class PaintShader {
  constructor(uniforms, texture, program) {
    uniforms = uniforms === VOID ? new UniformBlockBuffer(ShapeViewUB_getInstance()) : uniforms;
    program = program === VOID ? GpuShapeViewPrograms_getInstance().zhs_1 : program;
    this.vht_1 = uniforms;
    this.wht_1 = texture;
    this.xht_1 = program;
  }
  toString() {
    return 'PaintShader(uniforms=' + this.vht_1.toString() + ', texture=' + toString_0(this.wht_1) + ', program=' + this.xht_1.toString() + ')';
  }
  hashCode() {
    var result = hashCode(this.vht_1);
    result = imul(result, 31) + (this.wht_1 == null ? 0 : hashCode(this.wht_1)) | 0;
    result = imul(result, 31) + this.xht_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof PaintShader))
      return false;
    var tmp0_other_with_cast = other instanceof PaintShader ? other : THROW_CCE();
    if (!equals(this.vht_1, tmp0_other_with_cast.vht_1))
      return false;
    if (!equals(this.wht_1, tmp0_other_with_cast.wht_1))
      return false;
    if (!this.xht_1.equals(tmp0_other_with_cast.xht_1))
      return false;
    return true;
  }
}
class GpuShapeViewPrograms {
  constructor() {
    GpuShapeViewPrograms_instance = this;
    this.jhs_1 = Attribute.z90('a_MaxDist', VarType_Float1_getInstance(), false, Precision_MEDIUM_getInstance(), 4);
    this.khs_1 = Attribute.z90('a_Dist', VarType_Float1_getInstance(), false, Precision_MEDIUM_getInstance(), 5);
    this.lhs_1 = Varying.l91('v_MaxDist', VarType_Float1_getInstance(), Precision_MEDIUM_getInstance());
    this.mhs_1 = Varying.l91('v_Dist', VarType_Float1_getInstance(), Precision_MEDIUM_getInstance());
    this.nhs_1 = ProgramLayout.f8u([DefaultShaders_getInstance().q8x_1]);
    this.ohs_1 = ProgramLayout.f8u([DefaultShaders_getInstance().r8x_1]);
    this.phs_1 = ProgramLayout.f8u([this.khs_1]);
    this.qhs_1 = ProgramLayout.f8u([DefaultShaders_getInstance().q8x_1, DefaultShaders_getInstance().r8x_1]);
    this.rhs_1 = ProgramLayout.f8u([DefaultShaders_getInstance().q8x_1, this.khs_1]);
    this.shs_1 = ProgramLayout.f8u([DefaultShaders_getInstance().q8x_1, this.khs_1, this.jhs_1]);
    this.ths_1 = 0;
    this.uhs_1 = 1;
    this.vhs_1 = 2;
    this.whs_1 = 3;
    this.xhs_1 = 4;
    this.yhs_1 = 5;
    var tmp = this;
    // Inline function 'korlibs.graphics.VertexShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_0 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.PROGRAM_COMBINED.<anonymous>' call
    this_0.f92(this_0.i92(), this_0.j92(this_0.j92(this_0.q8z(), this_0.r8z()), this_0.m92([this_0.k92(), this_0.l92(0.0), this_0.l92(1.0)])));
    this_0.f92(this_0.b92(), DefaultShaders_getInstance().q8x_1);
    this_0.f92(GpuShapeViewPrograms_getInstance().mhs_1, GpuShapeViewPrograms_getInstance().khs_1);
    this_0.f92(GpuShapeViewPrograms_getInstance().lhs_1, GpuShapeViewPrograms_getInstance().jhs_1);
    var tmp505_vertex = VertexShader(this_0.n92());
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    // Inline function 'kotlin.also' call
    var this_1 = ProgramBuilderDefault.a92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.PROGRAM_COMBINED.<anonymous>' call
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = this_1.l95(GpuShapeViewPrograms_getInstance().mhu(), this_1.l92(5));
    var body = this_1.w92();
    // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.PROGRAM_COMBINED.<anonymous>.<anonymous>' call
    body.f92(body.i92(), body.m92([body.l92(1.0), body.l92(0.0), body.l92(0.0), body.l92(1.0)]));
    body.t93();
    var stmIf = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_1.e92_1.f(stmIf);
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond_0 = this_1.q95(this_1.h94(GpuShapeViewPrograms_getInstance().mhs_1), GpuShapeViewPrograms_getInstance().lhs_1);
    var body_0 = this_1.w92();
    // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.PROGRAM_COMBINED.<anonymous>.<anonymous>' call
    body_0.q93();
    var stmIf_0 = new If(cond_0, body_0.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_1.e92_1.f(stmIf_0);
    var tmp_0 = GpuShapeViewPrograms_getInstance().mhu();
    this_1.n93(tmp_0, 0, 4, GpuShapeViewPrograms$PROGRAM_COMBINED$lambda(this_1));
    var aaAlpha = this_1.g95(this_1.l92(1.0), this_1.o94(this_1.g95(this_1.j92(GpuShapeViewPrograms_getInstance().lhs_1, GpuShapeViewPrograms_getInstance().khu()), this_1.l92(1.5)), this_1.j92(GpuShapeViewPrograms_getInstance().lhs_1, GpuShapeViewPrograms_getInstance().khu()), this_1.h94(this_1.j92(GpuShapeViewPrograms_getInstance().mhs_1, GpuShapeViewPrograms_getInstance().khu()))));
    this_1.f92(this_1.i92(), this_1.j92(this_1.j92(this_1.j92(this_1.i92(), GpuShapeViewPrograms_getInstance().lhu()), GpuShapeViewPrograms_getInstance().ohu()), aaAlpha));
    var tmp506_fragment = FragmentShader(this_1.n92());
    tmp.zhs_1 = new Program(tmp505_vertex, tmp506_fragment, 'GpuShapeViewPrograms.Combined');
    var tmp_1 = this;
    // Inline function 'korlibs.graphics.shader.Companion.single' call
    var block = ShapeViewUB_getInstance();
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var this_2 = new UniformBlockBuffer(block);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
    this_2.z33();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var _unary__edvuaz = this_2.n8t();
      this_2.v9h(_unary__edvuaz + 1 | 0);
      this_2.w9h(this_2.n8t() + 1 | 0);
      var blockSize = this_2.r8r_1;
      var index0 = imul(this_2.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_2.n8t(), blockSize);
      var texBlockSize = this_2.o8r_1.s8z().j1();
      var texIndex0 = imul(this_2.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_2.n8t(), texBlockSize);
      if (this_2.n8t() > 0) {
        arraycopy(this_2.t8r_1, index0, this_2.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_2.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>.<anonymous>' call
      this_2.o8r_1;
      // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.stencilPaintShader.<anonymous>' call
      this_2.u8r_1.b9i(ShapeViewUB_getInstance().mhu(), 5);
      if (true && this_2.n8t() >= 1) {
        var equals = arrayequal(this_2.t8r_1, index0, this_2.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_2.n8t();
          this_2.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    tmp_1.aht_1 = new PaintShader(this_2, null);
  }
  mhu() {
    return ShapeViewUB_getInstance().mhu().h8t_1;
  }
  nhu() {
    return ShapeViewUB_getInstance().nhu().h8t_1;
  }
  lhu() {
    return ShapeViewUB_getInstance().lhu().h8t_1;
  }
  ohu() {
    return ShapeViewUB_getInstance().ohu().h8t_1;
  }
  khu() {
    return ShapeViewUB_getInstance().khu().h8t_1;
  }
  phu() {
    return ShapeViewUB_getInstance().phu().h8t_1;
  }
  qhu() {
    return ShapeViewUB_getInstance().qhu().h8t_1;
  }
  rhu() {
    return ShapeViewUB_getInstance().rhu().h8t_1;
  }
  shu(_this__u8e3s4) {
    // Inline function 'korlibs.graphics.shader.Companion.invoke' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.<get-pow2>.<anonymous>' call
    var $this$ExpressionBuilder = Companion_getInstance_30().y9o_1;
    return $this$ExpressionBuilder.e94(_this__u8e3s4, $this$ExpressionBuilder.l92(2.0));
  }
  bht(stateTransform, paint, globalAlpha, lineWidth) {
    var tmp;
    if (paint instanceof NonePaint) {
      tmp = null;
    } else {
      if (paint instanceof RGBA) {
        // Inline function 'korlibs.graphics.shader.Companion.single' call
        var block = ShapeViewUB_getInstance();
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.also' call
        var this_0 = new UniformBlockBuffer(block);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
        this_0.z33();
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
        $l$block: {
          // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
          var _unary__edvuaz = this_0.n8t();
          this_0.v9h(_unary__edvuaz + 1 | 0);
          this_0.w9h(this_0.n8t() + 1 | 0);
          var blockSize = this_0.r8r_1;
          var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
          var index1 = imul(this_0.n8t(), blockSize);
          var texBlockSize = this_0.o8r_1.s8z().j1();
          var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
          var texIndex1 = imul(this_0.n8t(), texBlockSize);
          if (this_0.n8t() > 0) {
            arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
          } else {
            arrayfill(this_0.t8r_1, 0, 0, blockSize);
          }
          // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>.<anonymous>' call
          var $this$push = this_0.o8r_1;
          // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.paintToShaderInfo.<anonymous>' call
          var it = this_0.u8r_1;
          it.b9i($this$push.mhu(), 0);
          it.j9i($this$push.nhu(), _RGBA___get_premultiplied__impl__3bulqc(paint.v59_1));
          it.b9i($this$push.ohu(), globalAlpha);
          if (true && this_0.n8t() >= 1) {
            var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
            if (equals) {
              var _unary__edvuaz_0 = this_0.n8t();
              this_0.v9h(_unary__edvuaz_0 - 1 | 0);
              break $l$block;
            }
          }
        }
        tmp = new PaintShader(this_0, null);
      } else {
        if (paint instanceof BitmapPaint) {
          var mat = Companion_getInstance_41().i2m_1.e2o(paint.r6q_1).e2o(stateTransform).y2n().k2n(1.0 / paint.q6q_1.j2y(), 1.0 / paint.q6q_1.k2y());
          // Inline function 'korlibs.graphics.shader.Companion.single' call
          var block_0 = ShapeViewUB_getInstance();
          // Inline function 'kotlin.also' call
          // Inline function 'kotlin.also' call
          var this_1 = new UniformBlockBuffer(block_0);
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
          this_1.z33();
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
          $l$block_0: {
            // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
            var _unary__edvuaz_1 = this_1.n8t();
            this_1.v9h(_unary__edvuaz_1 + 1 | 0);
            this_1.w9h(this_1.n8t() + 1 | 0);
            var blockSize_0 = this_1.r8r_1;
            var index0_0 = imul(this_1.n8t() - 1 | 0, blockSize_0);
            var index1_0 = imul(this_1.n8t(), blockSize_0);
            var texBlockSize_0 = this_1.o8r_1.s8z().j1();
            var texIndex0_0 = imul(this_1.n8t() - 1 | 0, texBlockSize_0);
            var texIndex1_0 = imul(this_1.n8t(), texBlockSize_0);
            if (this_1.n8t() > 0) {
              arraycopy(this_1.t8r_1, index0_0, this_1.t8r_1, index1_0, blockSize_0);
            } else {
              arrayfill(this_1.t8r_1, 0, 0, blockSize_0);
            }
            // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>.<anonymous>' call
            var $this$push_0 = this_1.o8r_1;
            // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.paintToShaderInfo.<anonymous>' call
            var it_0 = this_1.u8r_1;
            it_0.b9i($this$push_0.mhu(), 1);
            it_0.m9i($this$push_0.phu(), toMatrix4(mat));
            it_0.b9i($this$push_0.ohu(), globalAlpha);
            if (true && this_1.n8t() >= 1) {
              var equals_0 = arrayequal(this_1.t8r_1, index0_0, this_1.t8r_1, index1_0, blockSize_0);
              if (equals_0) {
                var _unary__edvuaz_2 = this_1.n8t();
                this_1.v9h(_unary__edvuaz_2 - 1 | 0);
                break $l$block_0;
              }
            }
          }
          tmp = new PaintShader(this_1, paint.q6q_1);
        } else {
          if (paint instanceof GradientPaint) {
            var gradientBitmap = Bitmap32.c4p(256, 1, VOID, true);
            // Inline function 'korlibs.image.bitmap.Bitmap.lock' call
            var rect = gradientBitmap.i4p_1;
            if (true) {
              gradientBitmap.w1w();
            }
            var result;
            try {
              // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.paintToShaderInfo.<anonymous>' call
              paint.c6q(_RgbaPremultipliedArray___init__impl__lx83hy(gradientBitmap.z4o_1));
            }finally {
              result = true ? gradientBitmap.q4q(rect) : 0;
            }
            var npaint = paint.d6q(VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, Companion_getInstance_41().i2m_1.e2o(paint.x69_1).e2o(stateTransform));
            var mat_0 = paint.n69_1.v3_1 === 0 ? npaint.d6a_1 : npaint.x69_1.y2n();
            // Inline function 'korlibs.graphics.shader.Companion.single' call
            var block_1 = ShapeViewUB_getInstance();
            // Inline function 'kotlin.also' call
            // Inline function 'kotlin.also' call
            var this_2 = new UniformBlockBuffer(block_1);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
            this_2.z33();
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>' call
            $l$block_1: {
              // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
              var _unary__edvuaz_3 = this_2.n8t();
              this_2.v9h(_unary__edvuaz_3 + 1 | 0);
              this_2.w9h(this_2.n8t() + 1 | 0);
              var blockSize_1 = this_2.r8r_1;
              var index0_1 = imul(this_2.n8t() - 1 | 0, blockSize_1);
              var index1_1 = imul(this_2.n8t(), blockSize_1);
              var texBlockSize_1 = this_2.o8r_1.s8z().j1();
              var texIndex0_1 = imul(this_2.n8t() - 1 | 0, texBlockSize_1);
              var texIndex1_1 = imul(this_2.n8t(), texBlockSize_1);
              if (this_2.n8t() > 0) {
                arraycopy(this_2.t8r_1, index0_1, this_2.t8r_1, index1_1, blockSize_1);
              } else {
                arrayfill(this_2.t8r_1, 0, 0, blockSize_1);
              }
              // Inline function 'korlibs.graphics.shader.Companion.single.<anonymous>.<anonymous>' call
              var $this$push_1 = this_2.o8r_1;
              // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.paintToShaderInfo.<anonymous>' call
              var it_1 = this_2.u8r_1;
              var tmp_0 = $this$push_1.mhu();
              var tmp0 = paint.n69_1.v3_1;
              it_1.b9i(tmp_0, tmp0 === 1 ? 3 : tmp0 === 2 ? 4 : 2);
              it_1.m9i($this$push_1.phu(), toMatrix4(mat_0));
              it_1.k9i($this$push_1.qhu(), new Vector4F(paint.o69_1, paint.p69_1, paint.q69_1, 1.0));
              it_1.k9i($this$push_1.rhu(), new Vector4F(paint.r69_1, paint.s69_1, paint.t69_1, 1.0));
              it_1.b9i($this$push_1.ohu(), globalAlpha);
              if (true && this_2.n8t() >= 1) {
                var equals_1 = arrayequal(this_2.t8r_1, index0_1, this_2.t8r_1, index1_1, blockSize_1);
                if (equals_1) {
                  var _unary__edvuaz_4 = this_2.n8t();
                  this_2.v9h(_unary__edvuaz_4 - 1 | 0);
                  break $l$block_1;
                }
              }
            }
            tmp = new PaintShader(this_2, gradientBitmap);
          } else {
            // Inline function 'kotlin.TODO' call
            var reason = 'paint=' + toString(paint);
            throw NotImplementedError.oe('An operation is not implemented: ' + reason);
          }
        }
      }
    }
    return tmp;
  }
}
//endregion
function *_generator_invoke__zhh2q8($this, $this$withContext, $completion) {
  var tmp = KorgeRunner_instance.qb3($this.gb2_1.pb3(VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, $this.hb2_1), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
var Companion_instance_14;
function Companion_getInstance_47() {
  if (Companion_instance_14 === VOID)
    new Companion();
  return Companion_instance_14;
}
function *_generator_start__v5y7pu($this, entry, $completion) {
  var tmp = Companion_getInstance_2().j1x($this.nb3_1);
  var tmp_0 = withContext(tmp, Korge$start$slambda_0($this, entry), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function Korge$_init_$lambda_95dffl(_this__u8e3s4) {
  return Unit_instance;
}
function Korge$_init_$slambda_ys8r4w_0() {
  var i = new Korge$_init_$slambda_ys8r4w();
  var l = function (_this__u8e3s4, $completion) {
    return i.tb3(_this__u8e3s4, $completion);
  };
  l.$arity = 1;
  return l;
}
function Korge$_init_$lambda_95dffl_0(it) {
  return Stage.jb6(it);
}
function Korge$start$slambda_0(this$0, $entry) {
  var i = new Korge$start$slambda(this$0, $entry);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
var Companion_instance_15;
function Companion_getInstance_48() {
  if (Companion_instance_15 === VOID)
    new Companion_0();
  return Companion_instance_15;
}
function *_generator_invoke__zhh2q8_0($this, $this$coroutineScope, $completion) {
  if (!($this.hb9_1.lb2_1 == null)) {
    // Inline function 'korlibs.korge.scene.sceneContainer' call
    var this_0 = $this.ib9_1.kb8_1;
    var defaultTransition = withEasing(get_AlphaTransition(), get_EASE_IN_OUT_QUAD(Companion_getInstance_44()));
    var name = 'sceneContainer';
    var size = Companion_getInstance_33().t2v_1;
    // Inline function 'korlibs.korge.scene.sceneContainer' call
    var tmp = views($completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var views_0 = tmp;
    var width = size.km();
    var height = size.lm();
    var rwidth = width;
    var rheight = height;
    if (width === 0.0 && height === 0.0) {
      var tmp_0 = findFirstAscendant(this_0, KorgeRunner$invoke$slambda$slambda$slambda$lambda);
      var base = (tmp_0 == null ? true : tmp_0 instanceof FixedSizeContainer) ? tmp_0 : null;
      var tmp0_elvis_lhs = base == null ? null : base.j2y();
      rwidth = tmp0_elvis_lhs == null ? views_0.kb8_1.j2y() : tmp0_elvis_lhs;
      var tmp1_elvis_lhs = base == null ? null : base.k2y();
      rheight = tmp1_elvis_lhs == null ? views_0.kb8_1.k2y() : tmp1_elvis_lhs;
    }
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = SceneContainer.lbe(views_0, defaultTransition, name, Size2D.k2t(rwidth, rheight));
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.scene.sceneContainer.<anonymous>' call
    var tmp_1 = addTo(this_1, this_0).mbe($this.hb9_1.lb2_1, [], VOID, VOID, $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
  }
  var tmp_2 = $this.jb9_1($this.ib9_1.kb8_1, $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  if ($this.hb9_1.tb2_1) {
    var tmp_3 = $this.kb9_1.mar($completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
  }
  return Unit_instance;
}
function KorgeRunner$invoke$slambda$slambda$slambda$lambda(it) {
  return it instanceof FixedSizeContainer;
}
function *_generator_invoke__zhh2q8_1($this, $completion) {
  var tmp = coroutineScope(KorgeRunner$invoke$slambda$slambda$slambda_0($this.nbe_1, $this.obe_1, $this.pbe_1, $this.qbe_1), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function KorgeRunner$invoke$slambda$slambda$slambda_0($config, $views, $entry, $gameWindow) {
  var i = new KorgeRunner$invoke$slambda$slambda$slambda($config, $views, $entry, $gameWindow);
  var l = function ($this$coroutineScope, $completion) {
    return i.o1s($this$coroutineScope, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_2($this, $this$loop, $completion) {
  var gameWindow = $this$loop;
  if (Companion_getInstance_46().yw()) {
    println('Korui[0]');
  }
  $l$block: {
    // Inline function 'korlibs.render.GameWindow.registerTime' call
    var name = 'configureGameWindow';
    var start = PerformanceCounter_getInstance().i88();
    try {
      var tmp0_elvis_lhs = $this.tbe_1.bb3_1;
      var tmp;
      var tmp_0 = tmp0_elvis_lhs;
      if ((tmp_0 == null ? null : new RGBA(tmp_0)) == null) {
        tmp = Colors_getInstance().h4r_1;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      configure($this.rbe_1, $this.sbe_1, $this.tbe_1.ab3_1, null, $this.tbe_1.sb2_1, tmp);
      break $l$block;
    }finally {
      var end = PerformanceCounter_getInstance().i88();
      // Inline function 'korlibs.time.microseconds' call
      var this_0 = end - start;
      var tmp$ret$1 = toDuration(this_0, DurationUnit_MICROSECONDS_getInstance());
      gameWindow.xaq(name, tmp$ret$1);
    }
  }
  $l$block_0: {
    // Inline function 'korlibs.render.GameWindow.registerTime' call
    var start_0 = PerformanceCounter_getInstance().i88();
    try {
      try {
        if (!($this.ube_1 == null)) {
          var tmp_1 = readBitmap(get_resourcesVfs().bj($this.ube_1), $this.vbe_1, $completion);
          if (tmp_1 === get_COROUTINE_SUSPENDED())
            tmp_1 = yield tmp_1;
          $this$loop.zaq(tmp_1);
        }
      } catch ($p) {
        if ($p instanceof Error) {
          var e = $p;
          // Inline function 'korlibs.logger.Logger.error' call
          var this_1 = Companion_getInstance_47().rb3_1;
          var level = Level_ERROR_getInstance();
          var tmp_2;
          if (this_1.u3g(level)) {
            // Inline function 'korlibs.korge.KorgeRunner.invoke.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
            var tmp$ret$2 = "Couldn't get the application icon";
            this_1.v3g(level, tmp$ret$2);
            tmp_2 = Unit_instance;
          }
          printStackTrace(e);
        } else {
          throw $p;
        }
      }
      break $l$block_0;
    }finally {
      var end_0 = PerformanceCounter_getInstance().i88();
      // Inline function 'korlibs.time.microseconds' call
      var this_2 = end_0 - start_0;
      var tmp$ret$5 = toDuration(this_2, DurationUnit_MICROSECONDS_getInstance());
      gameWindow.xaq('setIcon', tmp$ret$5);
    }
  }
  $this$loop.far($this$loop.vah());
  if (Companion_getInstance_46().yw()) {
    println('CanvasApplicationEx.IN[0]');
  }
  var input = new Input();
  var stats = new Stats();
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.js.getCoroutineContext' call
  var tmp$ret$6 = $completion.gd();
  var this_3 = Views.xbe(tmp$ret$6.xn(gameWindow.taq()).xn(new InjectorContext($this.tbe_1.nb2_1)).xn(SupervisorJob()), $this.tbe_1.ib3_1 ? new AGPrint() : $this$loop.saq(), $this.tbe_1.nb2_1, input, $this.tbe_1.mb2_1, stats, gameWindow, $this.tbe_1.ub2_1, $this.tbe_1.vb2_1, $this.tbe_1.wb2_1, VOID, $this.tbe_1.lb3_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.KorgeRunner.invoke.<anonymous>.<anonymous>.<anonymous>' call
  var tmp_3 = this_3.i21($completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  if (Companion_getInstance_46().ax()) {
    Dyn__set_impl_2ozvx8(Companion_instance_13.f3h(), 'views', this_3);
  }
  var views = this_3;
  $this.tbe_1.nb2_1.o87(getKClass(GameWindow), gameWindow);
  $this.tbe_1.nb2_1.o87(getKClass(Korge), $this.tbe_1);
  views.nb8_1 = $this$loop.har();
  views.ybe($this.tbe_1.jb3_1);
  views.zbe($this.tbe_1.kb3_1);
  views.eb8_1 = numberToInt($this.tbe_1.yb2_1.d2j_1);
  views.fb8_1 = numberToInt($this.tbe_1.yb2_1.e2j_1);
  views.ib8_1 = $this.tbe_1.zb2_1.rb6_1;
  views.hb8_1 = $this.tbe_1.zb2_1.qb6_1;
  views.jb8_1 = $this.tbe_1.zb2_1.sb6_1;
  views.yb8_1 = $this.tbe_1.mb3_1;
  $l$block_1: {
    // Inline function 'korlibs.render.GameWindow.registerTime' call
    var name_0 = 'prepareViews';
    var start_1 = PerformanceCounter_getInstance().i88();
    try {
      KorgeReload_getInstance().pa5(gameWindow);
      var tmp_4 = KorgeRunner_instance.abf(views, gameWindow, true, $this$loop.ear(), Companion_instance_1.n1w(), $this.tbe_1.fb3_1, $this.tbe_1.ob2_1).v17($completion);
      if (tmp_4 === get_COROUTINE_SUSPENDED())
        tmp_4 = yield tmp_4;
      break $l$block_1;
    }finally {
      var end_1 = PerformanceCounter_getInstance().i88();
      // Inline function 'korlibs.time.microseconds' call
      var this_4 = end_1 - start_1;
      var tmp$ret$9 = toDuration(this_4, DurationUnit_MICROSECONDS_getInstance());
      gameWindow.xaq(name_0, tmp$ret$9);
    }
  }
  $l$block_2: {
    // Inline function 'korlibs.render.GameWindow.registerTime' call
    var name_1 = 'completeViews';
    var start_2 = PerformanceCounter_getInstance().i88();
    try {
      completeViews(views);
      break $l$block_2;
    }finally {
      var end_2 = PerformanceCounter_getInstance().i88();
      // Inline function 'korlibs.time.microseconds' call
      var this_5 = end_2 - start_2;
      var tmp$ret$11 = toDuration(this_5, DurationUnit_MICROSECONDS_getInstance());
      gameWindow.xaq(name_1, tmp$ret$11);
    }
  }
  launchImmediately(views, KorgeRunner$invoke$slambda$slambda_0($this.tbe_1, views, $this.wbe_1, gameWindow));
  if ($this.tbe_1.tb2_1) {
    var tmp_5 = gameWindow.mar($completion);
    if (tmp_5 === get_COROUTINE_SUSPENDED())
      tmp_5 = yield tmp_5;
    gameWindow.kar();
  }
  return Unit_instance;
}
function KorgeRunner$invoke$slambda$slambda_0($config, $views, $entry, $gameWindow) {
  var i = new KorgeRunner$invoke$slambda$slambda($config, $views, $entry, $gameWindow);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function *_generator_invoke__zhh2q8_3($this, config, $completion) {
  $l$block: {
    // Inline function 'korlibs.io.worker.Companion.init' call
    get_workerImpl().e28();
    if (get_workerImpl().i3q()) {
      if (get_DEBUG_WORKER()) {
        println('INSIDE WORKER');
      }
      break $l$block;
    } else {
      if (get_DEBUG_WORKER()) {
        println('**NOT** INSIDE WORKER');
      }
    }
    // Inline function 'korlibs.korge.KorgeRunner.invoke.<anonymous>' call
    get_nativeSoundProvider();
    get_RegisteredImageFormats().y6m([config.jb2_1]);
    var iconPath = config.db3_1;
    var imageFormats = config.jb2_1;
    var entry = config.hb3_1;
    var windowSize = config.xb2_1;
    if (!Companion_getInstance_46().ax()) {
      var tmp = configureLoggerFromProperties(get_localCurrentDirVfs().bj('klogger.properties'), $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
    }
    var tmp0_elvis_lhs = config.kb2_1;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.js.getCoroutineContext' call
      tmp_0 = $completion.gd().td(CoroutineKey_getInstance());
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var tmp1_elvis_lhs = tmp_0;
    var realGameWindow = tmp1_elvis_lhs == null ? CreateDefaultGameWindow(config.gb3_1) : tmp1_elvis_lhs;
    var tmp2_elvis_lhs = config.bb3_1;
    var tmp_1;
    var tmp_2 = tmp2_elvis_lhs;
    if ((tmp_2 == null ? null : new RGBA(tmp_2)) == null) {
      tmp_1 = Colors_getInstance().h4r_1;
    } else {
      tmp_1 = tmp2_elvis_lhs;
    }
    realGameWindow.dar(tmp_1);
    var tmp_3 = realGameWindow.nar(KorgeRunner$invoke$slambda_0(realGameWindow, windowSize, config, iconPath, imageFormats, entry), $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
  }
  return Unit_instance;
}
function prepareViewsBase$getRealXY($views, x, y, scaleCoords) {
  return $views.sag(Vector2D.k2h(x, y));
}
function prepareViewsBase$mouseDown(input, $views, downPos, downTime, type, p, button) {
  input.uaa(button, true);
  input.taa(p, false);
  input.taa(p, true);
  $views.bbf();
  downPos._v = input.saa();
  downTime._v = new DateTime(Companion_getInstance_7().h1w());
  input.daa_1 = true;
}
function prepareViewsBase$mouseUp(input, $views, upPos, type, p, button) {
  input.uaa(button, false);
  input.taa(p, false);
  $views.bbf();
  upPos._v = $views.bb7_1.saa();
}
function prepareViewsBase$mouseMove($views, moveMouseOutsideInNextFrame, moveTime, type, p, inside) {
  $views.bb7_1.taa(p, false);
  $views.bb7_1.daa_1 = inside;
  if (!inside) {
    moveMouseOutsideInNextFrame._v = true;
  }
  $views.bbf();
  moveTime._v = new DateTime(Companion_getInstance_7().h1w());
}
function prepareViewsBase$mouseDrag($views, moveTime, type, p) {
  $views.bb7_1.taa(p, false);
  $views.bbf();
  moveTime._v = new DateTime(Companion_getInstance_7().h1w());
}
function prepareViewsBase$dispatchSimulatedTouchEvent(mouseTouchEvent, $views, p, button, type, status) {
  mouseTouchEvent.a8f_1 = 0;
  mouseTouchEvent.d8f_1 = true;
  mouseTouchEvent.b8f_1 = Companion_getInstance_7().h1w();
  mouseTouchEvent.c8f_1 = false;
  mouseTouchEvent.m8f(type);
  mouseTouchEvent.r8f(button.a8j_1, p, status, VOID, Kind_MOUSE_getInstance(), button);
  mouseTouchEvent.n8f();
  $views.v8a(mouseTouchEvent);
}
function prepareViewsBase$gamepadUpdated(input, e) {
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var this_0 = e.m8g_1;
  var n = 0;
  while (n < this_0.length) {
    // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.gamepadUpdated.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var gamepad = this_0[_unary__edvuaz];
    input.naa_1[gamepad.n8g_1].v8g(gamepad);
  }
  input.vaa();
}
function prepareViewsBase$renderBlock($views, $fixedSizeStep, moveMouseOutsideInNextFrame, event) {
  try {
    $views.dbf($fixedSizeStep, $views.cbf(), event.n8b_1, event.o8b_1);
    $views.bb7_1.caa_1 = false;
    if (moveMouseOutsideInNextFrame._v) {
      moveMouseOutsideInNextFrame._v = false;
      $views.bb7_1.caa_1 = true;
      $views.bb7_1.daa_1 = false;
      $views.bbf();
    }
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      // Inline function 'korlibs.logger.Logger.error' call
      var this_0 = Companion_getInstance_47().rb3_1;
      var level = Level_ERROR_getInstance();
      var tmp;
      if (this_0.u3g(level)) {
        // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.renderBlock.<anonymous>' call
        var tmp$ret$0 = 'views.gameWindow.onRenderEvent:';
        this_0.v3g(level, tmp$ret$0);
        tmp = Unit_instance;
      }
      printStackTrace(e);
      if ($views.mb7_1)
        throw e;
    } else {
      throw $p;
    }
  }
}
function KorgeRunner$invoke$slambda_0($realGameWindow, $windowSize, $config, $iconPath, $imageFormats, $entry) {
  var i = new KorgeRunner$invoke$slambda($realGameWindow, $windowSize, $config, $iconPath, $imageFormats, $entry);
  var l = function ($this$loop, $completion) {
    return i.ebf($this$loop, $completion);
  };
  l.$arity = 1;
  return l;
}
function KorgeRunner$prepareViewsBase$lambda($this$mapSingleton) {
  return new ResourcesRoot();
}
function KorgeRunner$prepareViewsBase$lambda_0($this$mapPrototype) {
  return new EmptyScene();
}
function KorgeRunner$prepareViewsBase$lambda_1($views, $input, $downPos, $downTime, $mouseTouchEvent, $upPos, $moveTime, $moveMouseOutsideInNextFrame) {
  return function (e) {
    // Inline function 'korlibs.logger.Logger.trace' call
    var this_0 = Companion_getInstance_47().rb3_1;
    var level = Level_TRACE_getInstance();
    var tmp;
    if (this_0.u3g(level)) {
      // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.<anonymous>.<anonymous>' call
      var tmp$ret$0 = 'eventDispatcher.addEventListener<MouseEvent>:' + e.toString();
      this_0.v3g(level, tmp$ret$0);
      tmp = Unit_instance;
    }
    var p = prepareViewsBase$getRealXY($views, e.z8c_1, e.a8d_1, e.k8d_1);
    var tmp_0;
    switch (e.x8c_1.v3_1) {
      case 3:
        prepareViewsBase$mouseDown($input, $views, $downPos, $downTime, 'mouseDown', p, e.b8d_1);
        prepareViewsBase$dispatchSimulatedTouchEvent($mouseTouchEvent, $views, p, e.b8d_1, Type_START_getInstance(), Status_ADD_getInstance());
        tmp_0 = Unit_instance;
        break;
      case 2:
        prepareViewsBase$mouseUp($input, $views, $upPos, 'mouseUp', p, e.b8d_1);
        prepareViewsBase$dispatchSimulatedTouchEvent($mouseTouchEvent, $views, p, e.b8d_1, Type_END_getInstance(), Status_REMOVE_getInstance());
        tmp_0 = Unit_instance;
        break;
      case 1:
        prepareViewsBase$mouseDrag($views, $moveTime, 'onMouseDrag', p);
        prepareViewsBase$dispatchSimulatedTouchEvent($mouseTouchEvent, $views, p, e.b8d_1, Type_MOVE_getInstance(), Status_KEEP_getInstance());
        tmp_0 = Unit_instance;
        break;
      case 0:
        prepareViewsBase$mouseMove($views, $moveMouseOutsideInNextFrame, $moveTime, 'mouseMove', p, true);
        tmp_0 = Unit_instance;
        break;
      case 4:
        tmp_0 = Unit_instance;
        break;
      case 5:
        prepareViewsBase$mouseMove($views, $moveMouseOutsideInNextFrame, $moveTime, 'mouseEnter', p, true);
        tmp_0 = Unit_instance;
        break;
      case 6:
        prepareViewsBase$mouseMove($views, $moveMouseOutsideInNextFrame, $moveTime, 'mouseExit', p, false);
        tmp_0 = Unit_instance;
        break;
      case 7:
        tmp_0 = Unit_instance;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    $views.v8a(e);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_2($views) {
  return function (e) {
    // Inline function 'korlibs.logger.Logger.trace' call
    var this_0 = Companion_getInstance_47().rb3_1;
    var level = Level_TRACE_getInstance();
    var tmp;
    if (this_0.u3g(level)) {
      // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.<anonymous>.<anonymous>' call
      var tmp$ret$0 = 'eventDispatcher.addEventListener<KeyEvent>:' + e.toString();
      this_0.v3g(level, tmp$ret$0);
      tmp = Unit_instance;
    }
    $views.v8a(e);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_3($views) {
  return function (e) {
    // Inline function 'korlibs.logger.Logger.trace' call
    var this_0 = Companion_getInstance_47().rb3_1;
    var level = Level_TRACE_getInstance();
    var tmp;
    if (this_0.u3g(level)) {
      // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.<anonymous>.<anonymous>' call
      var tmp$ret$0 = 'eventDispatcher.addEventListener<GestureEvent>:' + e.toString();
      this_0.v3g(level, tmp$ret$0);
      tmp = Unit_instance;
    }
    $views.v8a(e);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_4($views) {
  return function (e) {
    $views.v8a(e);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_5($views) {
  return function (e) {
    $views.v8a(e);
    get_nativeSoundProvider().z3d(false);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_6($views) {
  return function (e) {
    $views.v8a(e);
    get_nativeSoundProvider().z3d(true);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_7($views) {
  return function (e) {
    $views.v8a(e);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda$slambda_0($views) {
  var i = new KorgeRunner$prepareViewsBase$lambda$slambda($views);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function KorgeRunner$prepareViewsBase$lambda_8($views) {
  return function (e) {
    try {
      $views.v8a(e);
    }finally {
      launchImmediately($views, KorgeRunner$prepareViewsBase$lambda$slambda_0($views));
    }
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_9($input, $views, $touchMouseEvent, $moveMouseOutsideInNextFrame, $downPos, $downTime, $upPos, $moveTime) {
  return function (e) {
    // Inline function 'korlibs.logger.Logger.trace' call
    var this_0 = Companion_getInstance_47().rb3_1;
    var level = Level_TRACE_getInstance();
    var tmp;
    if (this_0.u3g(level)) {
      // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.<anonymous>.<anonymous>' call
      var tmp$ret$0 = 'eventDispatcher.addEventListener<TouchEvent>:' + e.toString();
      this_0.v3g(level, tmp$ret$0);
      tmp = Unit_instance;
    }
    $input.raa(e);
    var ee = $input.va9_1;
    var _iterator__ex2g4s = ee.i8f().f1();
    while (_iterator__ex2g4s.g1()) {
      var t = _iterator__ex2g4s.h1();
      t.p8e_1 = prepareViewsBase$getRealXY($views, t.n4f(), t.p4f(), e.c8f_1);
    }
    $views.v8a(ee);
    var tmp_0;
    if (ee.k8f() === 1) {
      var start = ee.u8f();
      var end = ee.v8f();
      var t_0 = first(ee.i8f());
      var p = t_0.p8e_1;
      var x = t_0.n4f();
      var y = t_0.p4f();
      var button = MouseButton_LEFT_getInstance();
      if (start) {
        prepareViewsBase$mouseDown($input, $views, $downPos, $downTime, 'onTouchStart', p, button);
      } else if (end) {
        prepareViewsBase$mouseUp($input, $views, $upPos, 'onTouchEnd', p, button);
      } else {
        prepareViewsBase$mouseMove($views, $moveMouseOutsideInNextFrame, $moveTime, 'onTouchMove', p, true);
      }
      // Inline function 'kotlin.also' call
      var this_1 = $touchMouseEvent;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.<anonymous>.<anonymous>' call
      this_1.y8c_1 = 0;
      this_1.b8d_1 = button;
      this_1.c8d_1 = end ? 0 : 1 << button.a8j_1;
      this_1.z8c_1 = numberToInt(x);
      this_1.a8d_1 = numberToInt(y);
      this_1.k8d_1 = false;
      this_1.l8d_1 = true;
      this_1.x8c_1 = start ? Type_DOWN_getInstance() : end ? Type_UP_getInstance() : Type_DRAG_getInstance();
      $views.v8a(this_1);
      var tmp_1;
      if (end) {
        $moveMouseOutsideInNextFrame._v = true;
        tmp_1 = Unit_instance;
      }
      tmp_0 = tmp_1;
    }
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_10($views) {
  return function (e) {
    // Inline function 'korlibs.logger.Logger.trace' call
    var this_0 = Companion_getInstance_47().rb3_1;
    var level = Level_TRACE_getInstance();
    var tmp;
    if (this_0.u3g(level)) {
      // Inline function 'korlibs.korge.KorgeRunner.prepareViewsBase.<anonymous>.<anonymous>' call
      var tmp$ret$0 = 'eventDispatcher.addEventListener<GamePadConnectionEvent>:' + e.toString();
      this_0.v3g(level, tmp$ret$0);
      tmp = Unit_instance;
    }
    $views.v8a(e);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_11($views, $input) {
  return function (e) {
    prepareViewsBase$gamepadUpdated($input, e);
    $views.v8a(e);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_12($views, $ag) {
  return function (e) {
    $views.gbf($ag.h8k().a8k_1, $ag.h8k().b8k_1);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_13($views) {
  return function (it) {
    $views.v8a(it);
    return Unit_instance;
  };
}
function KorgeRunner$prepareViewsBase$lambda_14($views, $renderShown, $firstRenderDeferred, $fixedSizeStep, $moveMouseOutsideInNextFrame) {
  return function (event) {
    var tmp;
    if (!event.o8b_1) {
      prepareViewsBase$renderBlock($views, $fixedSizeStep, $moveMouseOutsideInNextFrame, event);
      tmp = Unit_instance;
    } else {
      var this_0 = $views.xb7_1;
      var frameBuffer = this_0.h8k();
      this_0.ta6_1.z33();
      this_0.ma6_1.l8k();
      var tmp_0;
      try {
        this_0.mai(frameBuffer);
        var tmp_1;
        try {
          if (!$renderShown._v) {
            $renderShown._v = true;
            $firstRenderDeferred.m1b(Unit_instance);
          }
          prepareViewsBase$renderBlock($views, $fixedSizeStep, $moveMouseOutsideInNextFrame, event);
          tmp_1 = Unit_instance;
        }finally {
          this_0.nai();
        }
        tmp_0 = tmp_1;
      }finally {
        this_0.ma6_1.n8f();
      }
      tmp = tmp_0;
    }
    return Unit_instance;
  };
}
var KorgeRunner_instance;
function KorgeRunner_getInstance() {
  return KorgeRunner_instance;
}
function Korge_0(args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowWidth, windowHeight, windowSize, virtualWidth, virtualHeight, virtualSize, scaleMode, scaleAnchor, clipBorders, displayMode, title, bgcolor, backgroundColor, quality, icon, multithreaded, forceRenderEveryFrame, main, debugAg, debugFontExtraScale, debugFontColor, stageBuilder, targetFps, preferSyncIo, entry, $completion) {
  var tmp;
  if (args === VOID) {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp = [];
  } else {
    tmp = args;
  }
  args = tmp;
  imageFormats = imageFormats === VOID ? get_RegisteredImageFormats() : imageFormats;
  gameWindow = gameWindow === VOID ? null : gameWindow;
  mainSceneClass = mainSceneClass === VOID ? null : mainSceneClass;
  timeProvider = timeProvider === VOID ? Companion_instance_0 : timeProvider;
  injector = injector === VOID ? new Injector() : injector;
  var tmp_0;
  if (configInjector === VOID) {
    tmp_0 = Korge$lambda;
  } else {
    tmp_0 = configInjector;
  }
  configInjector = tmp_0;
  debug = debug === VOID ? false : debug;
  trace = trace === VOID ? false : trace;
  context = context === VOID ? null : context;
  fullscreen = fullscreen === VOID ? null : fullscreen;
  blocking = blocking === VOID ? true : blocking;
  gameId = gameId === VOID ? Companion_getInstance_47().sb3_1 : gameId;
  settingsFolder = settingsFolder === VOID ? null : settingsFolder;
  batchMaxQuads = batchMaxQuads === VOID ? Companion_getInstance_8().rad_1 : batchMaxQuads;
  windowWidth = windowWidth === VOID ? numberToInt(DefaultViewport_getInstance().cab_1.d2j_1) : windowWidth;
  windowHeight = windowHeight === VOID ? numberToInt(DefaultViewport_getInstance().cab_1.e2j_1) : windowHeight;
  windowSize = windowSize === VOID ? Size2D.f2j(windowWidth, windowHeight) : windowSize;
  virtualWidth = virtualWidth === VOID ? numberToInt(windowSize.d2j_1) : virtualWidth;
  virtualHeight = virtualHeight === VOID ? numberToInt(windowSize.e2j_1) : virtualHeight;
  virtualSize = virtualSize === VOID ? Size2D.f2j(virtualWidth, virtualHeight) : virtualSize;
  scaleMode = scaleMode === VOID ? Companion_getInstance_31().n2v_1 : scaleMode;
  scaleAnchor = scaleAnchor === VOID ? Companion_getInstance_32().f2h() : scaleAnchor;
  clipBorders = clipBorders === VOID ? true : clipBorders;
  displayMode = displayMode === VOID ? new KorgeDisplayMode(scaleMode, scaleAnchor, clipBorders) : displayMode;
  title = title === VOID ? 'Game' : title;
  bgcolor = bgcolor === VOID ? Colors_getInstance().h4r_1 : bgcolor;
  backgroundColor = backgroundColor === VOID ? bgcolor : backgroundColor;
  quality = quality === VOID ? Quality_PERFORMANCE_getInstance() : quality;
  icon = icon === VOID ? null : icon;
  multithreaded = multithreaded === VOID ? null : multithreaded;
  forceRenderEveryFrame = forceRenderEveryFrame === VOID ? true : forceRenderEveryFrame;
  var tmp_1;
  if (main === VOID) {
    tmp_1 = Korge$slambda_1();
  } else {
    tmp_1 = main;
  }
  main = tmp_1;
  debugAg = debugAg === VOID ? false : debugAg;
  debugFontExtraScale = debugFontExtraScale === VOID ? 1.0 : debugFontExtraScale;
  debugFontColor = debugFontColor === VOID ? Colors_getInstance().g4r_1 : debugFontColor;
  var tmp_2;
  if (stageBuilder === VOID) {
    tmp_2 = Korge$lambda_0;
  } else {
    tmp_2 = stageBuilder;
  }
  stageBuilder = tmp_2;
  targetFps = targetFps === VOID ? 0.0 : targetFps;
  preferSyncIo = preferSyncIo === VOID ? null : preferSyncIo;
  var tmp_3;
  if (entry === VOID) {
    tmp_3 = Korge$slambda_2();
  } else {
    tmp_3 = entry;
  }
  entry = tmp_3;
  return (new Korge(args, imageFormats, gameWindow, mainSceneClass, timeProvider, injector, configInjector, debug, trace, context, fullscreen, blocking, gameId, settingsFolder, batchMaxQuads, windowSize, virtualSize, displayMode, title, backgroundColor, quality, icon, multithreaded, forceRenderEveryFrame, VOID, main, debugAg, debugFontExtraScale, debugFontColor, stageBuilder, targetFps, preferSyncIo, Unit_instance)).ob6(entry, $completion);
}
function Korge$lambda(_this__u8e3s4) {
  return Unit_instance;
}
function Korge$slambda_1() {
  var i = new Korge$slambda();
  var l = function (_this__u8e3s4, $completion) {
    return i.tb3(_this__u8e3s4, $completion);
  };
  l.$arity = 1;
  return l;
}
function Korge$lambda_0(it) {
  return Stage.jb6(it);
}
function Korge$slambda_2() {
  var i = new Korge$slambda_0();
  var l = function (_this__u8e3s4, $completion) {
    return i.tb3(_this__u8e3s4, $completion);
  };
  l.$arity = 1;
  return l;
}
function get_simpleAnimator(_this__u8e3s4) {
  _init_properties_Animator_kt__gicf47();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = simpleAnimator$delegate;
    var property = simpleAnimator$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var simpleAnimator$delegate;
function _get_totalTime__xgts5c($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.sbf_1;
  totalTime$factory();
  return this_0.b2().p1w_1;
}
function Animator$TweenNode$computedVs$delegate$lambda(this$0) {
  return function () {
    var tmp22_safe_receiver = this$0.mbf_1;
    var tmp;
    if (tmp22_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList.w(tmp22_safe_receiver.length);
      var inductionVariable = 0;
      var last = tmp22_safe_receiver.length;
      while (inductionVariable < last) {
        var item = tmp22_safe_receiver[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.korge.animate.TweenNode.computedVs$delegate.<anonymous>.<anonymous>' call
        var tmp$ret$0 = item();
        destination.f(tmp$ret$0);
      }
      tmp = destination;
    }
    var tmp0_elvis_lhs = tmp;
    return toMutableList(tmp0_elvis_lhs == null ? toList(this$0.lbf_1) : tmp0_elvis_lhs);
  };
}
function Animator$TweenNode$totalTime$delegate$lambda(this$0) {
  return function () {
    var tmp23_safe_receiver = this$0.obf_1;
    var tmp;
    if (tmp23_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp_0 = tmp23_safe_receiver();
      tmp = tmp_0 == null ? null : tmp_0.p1w_1;
    }
    var tmp0_elvis_lhs = tmp;
    var tmp_1;
    var tmp_2 = tmp0_elvis_lhs;
    if ((tmp_2 == null ? null : new FastDuration(tmp_2)) == null) {
      tmp_1 = this$0.nbf_1;
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    return new FastDuration(tmp_1);
  };
}
var Companion_instance_16;
function Companion_getInstance_49() {
  if (Companion_instance_16 === VOID)
    new Companion_1();
  return Companion_instance_16;
}
function ensure($this) {
  if (!($this.ebg_1 == null))
    return ensure($this.ebg_1);
  if (!($this.pbg().lbg_1 == null))
    return Unit_instance;
  var tmp = $this.pbg();
  var tmp_0 = $this.pbg().hbg_1;
  tmp.lbg_1 = addFastUpdater($this.ybf_1, tmp_0, VOID, Animator$ensure$lambda($this));
}
function ensureInit($this) {
  var tmp0_elvis_lhs = $this.fbg_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var linit = tmp;
  $this.fbg_1 = null;
  linit($this);
}
function *_generator_awaitComplete__3zrgmd($this, completeOnCancel, $completion) {
  try {
    if ($this.lbg_1 == null)
      return Unit_instance;
    if ($this.obg_1.a1())
      return Unit_instance;
    var tmp = waitOne($this.ibg_1, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  } catch ($p) {
    if ($p instanceof CancellationException) {
      var e = $p;
      var tmp5_safe_receiver = e instanceof AnimateCancellationException ? e : null;
      var tmp0_elvis_lhs = tmp5_safe_receiver == null ? null : tmp5_safe_receiver.xbg_1;
      if (tmp0_elvis_lhs == null ? completeOnCancel : tmp0_elvis_lhs)
        $this.zbg();
      else {
        $this.ybg();
      }
    } else {
      throw $p;
    }
  }
  return Unit_instance;
}
function *_generator_await__mos7q6($this, completeOnCancel, $completion) {
  var tmp = $this.abh(completeOnCancel, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function Animator$removeProps$lambda($props) {
  return function (it) {
    return !$props.f3(it.ral_1);
  };
}
function Animator$ensure$lambda(this$0) {
  return function ($this$addFastUpdater, dt) {
    var tmp;
    if (this$0.mbg_1) {
      this$0.ybf_1.hbh();
      tmp = Unit_instance;
    }
    var tmp_0;
    if (FastDuration__compareTo_impl_4fhnj7(this$0.obg_1.bbh(dt.p1w_1), Companion_instance_1.m1w()) >= 0) {
      if (this$0.dbg_1) {
        invoke(this$0.ibg_1);
      } else {
        this$0.ybg();
      }
      tmp_0 = Unit_instance;
    }
    return Unit_instance;
  };
}
function animator(_this__u8e3s4, defaultTime, defaultSpeed, defaultEasing, parallel, looped, startImmediately, block) {
  defaultTime = defaultTime === VOID ? Companion_getInstance_49().ubf_1 : defaultTime;
  defaultSpeed = defaultSpeed === VOID ? Companion_getInstance_49().vbf_1 : defaultSpeed;
  defaultEasing = defaultEasing === VOID ? Companion_getInstance_49().wbf_1 : defaultEasing;
  parallel = parallel === VOID ? false : parallel;
  looped = looped === VOID ? false : looped;
  startImmediately = startImmediately === VOID ? Companion_getInstance_49().xbf_1 : startImmediately;
  var tmp;
  if (block === VOID) {
    tmp = animator$lambda;
  } else {
    tmp = block;
  }
  block = tmp;
  _init_properties_Animator_kt__gicf47();
  // Inline function 'kotlin.apply' call
  var tmp2_fastDefaultTime = get_fast(defaultTime);
  var this_0 = new Animator(_this__u8e3s4, tmp2_fastDefaultTime, defaultSpeed, defaultEasing, parallel, looped, null, VOID, 0, startImmediately);
  // Inline function 'kotlin.contracts.contract' call
  block(this_0);
  return this_0;
}
function tween(_this__u8e3s4, vs, time, easing, name, replace) {
  time = time === VOID ? _this__u8e3s4.ibh() : time;
  easing = easing === VOID ? _this__u8e3s4.bbg_1 : easing;
  name = name === VOID ? null : name;
  replace = replace === VOID ? true : replace;
  _init_properties_Animator_kt__gicf47();
  return _this__u8e3s4.nbh(vs.slice(), VOID, get_fast(time), VOID, easing, name, replace);
}
function block(_this__u8e3s4, name, callback) {
  name = name === VOID ? null : name;
  _init_properties_Animator_kt__gicf47();
  _this__u8e3s4.kbh(new BlockNode(name, callback));
}
function removeFromParent(_this__u8e3s4, view) {
  _init_properties_Animator_kt__gicf47();
  block(_this__u8e3s4, VOID, removeFromParent$lambda(view));
}
function *_generator_animate__awi6b7(_this__u8e3s4, defaultTime, defaultSpeed, defaultEasing, parallel, looped, completeOnCancel, startImmediately, block, $completion) {
  defaultTime = defaultTime === VOID ? Companion_getInstance_49().ubf_1 : defaultTime;
  defaultSpeed = defaultSpeed === VOID ? Companion_getInstance_49().vbf_1 : defaultSpeed;
  defaultEasing = defaultEasing === VOID ? Companion_getInstance_49().wbf_1 : defaultEasing;
  parallel = parallel === VOID ? false : parallel;
  looped = looped === VOID ? false : looped;
  completeOnCancel = completeOnCancel === VOID ? false : completeOnCancel;
  startImmediately = startImmediately === VOID ? Companion_getInstance_49().xbf_1 : startImmediately;
  var tmp;
  if (block === VOID) {
    tmp = animate$lambda;
  } else {
    tmp = block;
  }
  block = tmp;
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.apply' call
  var tmp3_fastDefaultTime = get_fast(defaultTime);
  var this_0 = new Animator(_this__u8e3s4, tmp3_fastDefaultTime, defaultSpeed, defaultEasing, parallel, looped, null, VOID, 0, startImmediately);
  // Inline function 'kotlin.contracts.contract' call
  block(this_0);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.animate.animate.<anonymous>' call
  var tmp_0 = this_0.lbh(completeOnCancel, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return this_0;
}
function animate(_this__u8e3s4, defaultTime, defaultSpeed, defaultEasing, parallel, looped, completeOnCancel, startImmediately, block, $completion) {
  defaultTime = defaultTime === VOID ? Companion_getInstance_49().ubf_1 : defaultTime;
  defaultSpeed = defaultSpeed === VOID ? Companion_getInstance_49().vbf_1 : defaultSpeed;
  defaultEasing = defaultEasing === VOID ? Companion_getInstance_49().wbf_1 : defaultEasing;
  parallel = parallel === VOID ? false : parallel;
  looped = looped === VOID ? false : looped;
  completeOnCancel = completeOnCancel === VOID ? false : completeOnCancel;
  startImmediately = startImmediately === VOID ? Companion_getInstance_49().xbf_1 : startImmediately;
  var tmp;
  if (block === VOID) {
    tmp = animate$lambda;
  } else {
    tmp = block;
  }
  block = tmp;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_animate__awi6b7(_this__u8e3s4, defaultTime, defaultSpeed, defaultEasing, parallel, looped, completeOnCancel, startImmediately, block, $completion), $completion);
}
function wait(_this__u8e3s4, time) {
  time = time === VOID ? _this__u8e3s4.ibh() : time;
  _init_properties_Animator_kt__gicf47();
  return _this__u8e3s4.pbh([], get_fast(time), VOID, VOID, 'wait');
}
function show(_this__u8e3s4, view, time, easing) {
  time = time === VOID ? _this__u8e3s4.ibh() : time;
  easing = easing === VOID ? _this__u8e3s4.bbg_1 : easing;
  _init_properties_Animator_kt__gicf47();
  return alpha(_this__u8e3s4, view, 1.0, time, easing);
}
function moveBy(_this__u8e3s4, view, x, y, time, easing) {
  x = x === VOID ? 0.0 : x;
  y = y === VOID ? 0.0 : y;
  time = time === VOID ? _this__u8e3s4.ibh() : time;
  easing = easing === VOID ? _this__u8e3s4.bbg_1 : easing;
  _init_properties_Animator_kt__gicf47();
  return _this__u8e3s4.nbh([incr(x$factory(view), x), incr(y$factory(view), y)], VOID, get_fast(time), VOID, easing, 'moveBy');
}
function hide(_this__u8e3s4, view, time, easing) {
  time = time === VOID ? _this__u8e3s4.ibh() : time;
  easing = easing === VOID ? _this__u8e3s4.bbg_1 : easing;
  _init_properties_Animator_kt__gicf47();
  return alpha(_this__u8e3s4, view, 0.0, time, easing);
}
function alpha(_this__u8e3s4, view, alpha, time, easing) {
  time = time === VOID ? _this__u8e3s4.ibh() : time;
  easing = easing === VOID ? _this__u8e3s4.bbg_1 : easing;
  _init_properties_Animator_kt__gicf47();
  return alpha_0(_this__u8e3s4, view, alpha, time, easing);
}
function alpha_0(_this__u8e3s4, view, alpha, time, easing) {
  time = time === VOID ? _this__u8e3s4.ibh() : time;
  easing = easing === VOID ? _this__u8e3s4.bbg_1 : easing;
  _init_properties_Animator_kt__gicf47();
  // Inline function 'korlibs.korge.tween.get' call
  var this_0 = alphaF$factory(view);
  var tmp = this_0.get();
  var tmp$ret$0 = new V2(this_0, tmp, alpha, _interpolateFloat$ref(), false);
  return _this__u8e3s4.nbh([tmp$ret$0], VOID, get_fast(time), VOID, easing, 'alpha');
}
function simpleAnimator$delegate$lambda($this$PropertyThis) {
  _init_properties_Animator_kt__gicf47();
  // Inline function 'kotlin.also' call
  var this_0 = animator($this$PropertyThis, VOID, VOID, VOID, true);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.animate.simpleAnimator$delegate.<anonymous>.<anonymous>' call
  this_0.mbg_1 = true;
  return this_0;
}
function animator$lambda(_this__u8e3s4) {
  _init_properties_Animator_kt__gicf47();
  return Unit_instance;
}
function removeFromParent$lambda($view) {
  return function () {
    $view.qbh();
    return Unit_instance;
  };
}
function animate$lambda(_this__u8e3s4) {
  _init_properties_Animator_kt__gicf47();
  return Unit_instance;
}
function _interpolateFloat$ref() {
  var l = function (p0, p1, p2) {
    return _interpolateFloat(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolateFloat';
  return l;
}
function simpleAnimator$factory() {
  return getPropertyCallableRef('simpleAnimator', 1, KProperty1, function (receiver) {
    return get_simpleAnimator(receiver);
  }, null);
}
function computedVs$factory() {
  return getPropertyCallableRef('computedVs', 1, KProperty1, function (receiver) {
    return receiver.dbh();
  }, null);
}
function totalTime$factory() {
  return getPropertyCallableRef('totalTime', 1, KProperty1, function (receiver) {
    return new FastDuration(_get_totalTime__xgts5c(receiver));
  }, null);
}
function x$factory($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function y$factory($b0) {
  return getPropertyCallableRef('y', 0, KMutableProperty0, function () {
    return $b0.p4f();
  }, function (value) {
    return $b0.sbh(value);
  });
}
function alphaF$factory($b0) {
  return getPropertyCallableRef('alphaF', 0, KMutableProperty0, function () {
    return $b0.tbh();
  }, function (value) {
    return $b0.ubh(value);
  });
}
var properties_initialized_Animator_kt_opr5o7;
function _init_properties_Animator_kt__gicf47() {
  if (!properties_initialized_Animator_kt_opr5o7) {
    properties_initialized_Animator_kt_opr5o7 = true;
    simpleAnimator$delegate = new PropertyThis(VOID, simpleAnimator$delegate$lambda);
  }
}
function decorateOutOverAlpha(_this__u8e3s4, alpha) {
  var tmp;
  if (alpha === VOID) {
    tmp = decorateOutOverAlpha$lambda;
  } else {
    tmp = alpha;
  }
  alpha = tmp;
  return decorateOutOver(_this__u8e3s4, decorateOutOverAlpha$lambda_0(alpha));
}
function decorateOutOver(_this__u8e3s4, onEvent) {
  var tmp;
  if (onEvent === VOID) {
    tmp = decorateOutOver$lambda;
  } else {
    tmp = onEvent;
  }
  onEvent = tmp;
  var view = _this__u8e3s4;
  onEvent(view, false);
  // Inline function 'korlibs.korge.input.mouse' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var $this$mouse = get_mouse(_this__u8e3s4);
  $this$mouse.abi_1.w1x(decorateOutOver$lambda_0(onEvent, view));
  $this$mouse.bbi_1.w1x(decorateOutOver$lambda_1(onEvent, view));
  $this$mouse.gbi_1.w1x(decorateOutOver$lambda_2(onEvent, view));
  return _this__u8e3s4;
}
function decorateOutOverAlpha$lambda(it) {
  return it ? 1.0 : 0.75;
}
function decorateOutOverAlpha$lambda_0($alpha) {
  return function (view, over) {
    view.kbj($alpha(over));
    return Unit_instance;
  };
}
function decorateOutOver$lambda(view, over) {
  return Unit_instance;
}
function decorateOutOver$lambda_0($onEvent, $view) {
  return function (it) {
    $onEvent($view, true);
    return Unit_instance;
  };
}
function decorateOutOver$lambda_1($onEvent, $view) {
  return function (it) {
    if (it.lbj().qaa() === 0)
      $onEvent($view, false);
    return Unit_instance;
  };
}
function decorateOutOver$lambda_2($onEvent, $view) {
  return function (it) {
    $onEvent($view, false);
    return Unit_instance;
  };
}
function get_viewStageComponent(_this__u8e3s4) {
  _init_properties_StageComponent_kt__9w4k8v();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = viewStageComponent$delegate;
    var property = viewStageComponent$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var viewStageComponent$delegate;
function set_viewsToTrack(_this__u8e3s4, _set____db54di) {
  _init_properties_StageComponent_kt__9w4k8v();
  var this_0 = viewsToTrack$delegate;
  var property = viewsToTrack$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_viewsToTrack(_this__u8e3s4) {
  _init_properties_StageComponent_kt__9w4k8v();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = viewsToTrack$delegate;
    var property = viewsToTrack$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var viewsToTrack$delegate;
function onNewAttachDetach(_this__u8e3s4, onAttach, onDetach) {
  var tmp;
  if (onAttach === VOID) {
    tmp = onNewAttachDetach$lambda;
  } else {
    tmp = onAttach;
  }
  onAttach = tmp;
  var tmp_0;
  if (onDetach === VOID) {
    tmp_0 = onNewAttachDetach$lambda_0;
  } else {
    tmp_0 = onDetach;
  }
  onDetach = tmp_0;
  _init_properties_StageComponent_kt__9w4k8v();
  var view = _this__u8e3s4;
  var closeable = new CancellableGroup();
  var viewStageComponent = get_viewStageComponent(_this__u8e3s4);
  deferWithViews(view, VOID, VOID, onNewAttachDetach$lambda_1(view));
  closeable.ku(viewStageComponent.nbj_1.v1x(onNewAttachDetach$lambda_2(onAttach, view)));
  closeable.ku(viewStageComponent.obj_1.v1x(onNewAttachDetach$lambda_3(onDetach, view)));
  return closeable;
}
function registerStageComponent(_this__u8e3s4, view) {
  _init_properties_StageComponent_kt__9w4k8v();
  var firstRun = get_viewsToTrack(_this__u8e3s4) == null;
  if (firstRun) {
    // Inline function 'kotlin.collections.linkedSetOf' call
    var tmp$ret$0 = LinkedHashSet.l1();
    set_viewsToTrack(_this__u8e3s4, tmp$ret$0);
  }
  // Inline function 'kotlin.collections.plusAssign' call
  ensureNotNull(get_viewsToTrack(_this__u8e3s4)).f(view);
  if (!firstRun)
    return Unit_instance;
  var componentsInStagePrev = FastArrayList.g14();
  // Inline function 'kotlin.collections.linkedSetOf' call
  var componentsInStageCur = LinkedHashSet.l1();
  // Inline function 'kotlin.collections.linkedSetOf' call
  var componentsInStage = LinkedHashSet.l1();
  _this__u8e3s4.wb8_1.w1x(registerStageComponent$lambda(componentsInStagePrev, componentsInStageCur, _this__u8e3s4, componentsInStage));
}
function viewStageComponent$delegate$lambda($this$PropertyThis) {
  _init_properties_StageComponent_kt__9w4k8v();
  return new ViewStageComponent($this$PropertyThis);
}
function viewsToTrack$delegate$lambda() {
  _init_properties_StageComponent_kt__9w4k8v();
  return null;
}
function onNewAttachDetach$lambda(_this__u8e3s4, it) {
  _init_properties_StageComponent_kt__9w4k8v();
  return Unit_instance;
}
function onNewAttachDetach$lambda_0(_this__u8e3s4, it) {
  _init_properties_StageComponent_kt__9w4k8v();
  return Unit_instance;
}
function onNewAttachDetach$lambda_1($view) {
  return function (it) {
    registerStageComponent(it, $view);
    return Unit_instance;
  };
}
function onNewAttachDetach$lambda_2($onAttach, $view) {
  return function (it) {
    $onAttach(it, $view);
    return Unit_instance;
  };
}
function onNewAttachDetach$lambda_3($onDetach, $view) {
  return function (it) {
    $onDetach(it, $view);
    return Unit_instance;
  };
}
function registerStageComponent$lambda($componentsInStagePrev, $componentsInStageCur, $this_registerStageComponent, $componentsInStage) {
  return function (it) {
    $componentsInStagePrev.o3();
    var this_0 = $componentsInStagePrev;
    var elements = $componentsInStageCur;
    addAll(this_0, elements);
    $componentsInStageCur.o3();
    var tmp0_iterator = ensureNotNull(get_viewsToTrack($this_registerStageComponent)).f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.component.registerStageComponent.<anonymous>.<anonymous>' call
      if (hasExtra(element, '__viewStageComponent') && !(element.pbj() == null)) {
        var it_0 = get_viewStageComponent(element);
        // Inline function 'kotlin.collections.plusAssign' call
        $componentsInStageCur.f(it_0);
        if (!$componentsInStage.f3(it_0)) {
          // Inline function 'kotlin.collections.plusAssign' call
          $componentsInStage.f(it_0);
          it_0.nbj_1.x1x($this_registerStageComponent.lb7_1);
        }
      }
    }
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = $componentsInStagePrev.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.korge.component.registerStageComponent.<anonymous>.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it_1 = array[_unary__edvuaz];
      if (!$componentsInStageCur.f3(it_1)) {
        it_1.obj_1.x1x($this_registerStageComponent.lb7_1);
        ensureNotNull(get_viewsToTrack($this_registerStageComponent)).n3(it_1.mbj_1);
      }
    }
    return Unit_instance;
  };
}
function viewStageComponent$factory() {
  return getPropertyCallableRef('viewStageComponent', 1, KProperty1, function (receiver) {
    return get_viewStageComponent(receiver);
  }, null);
}
function viewsToTrack$factory() {
  return getPropertyCallableRef('viewsToTrack', 1, KMutableProperty1, function (receiver) {
    return get_viewsToTrack(receiver);
  }, function (receiver, value) {
    return set_viewsToTrack(receiver, value);
  });
}
function viewsToTrack$factory_0() {
  return getPropertyCallableRef('viewsToTrack', 1, KMutableProperty1, function (receiver) {
    return get_viewsToTrack(receiver);
  }, function (receiver, value) {
    return set_viewsToTrack(receiver, value);
  });
}
var properties_initialized_StageComponent_kt_b9koe7;
function _init_properties_StageComponent_kt__9w4k8v() {
  if (!properties_initialized_StageComponent_kt_b9koe7) {
    properties_initialized_StageComponent_kt_b9koe7 = true;
    viewStageComponent$delegate = new PropertyThis('__viewStageComponent', viewStageComponent$delegate$lambda);
    viewsToTrack$delegate = new Property(VOID, viewsToTrack$delegate$lambda);
  }
}
function onDropFile(_this__u8e3s4, handler) {
  var tmp = Companion_getInstance_12().c8e_1.slice();
  return _this__u8e3s4.w8a(tmp, onDropFile$lambda(handler));
}
function onDropFile$lambda($handler) {
  return function (it) {
    $handler(it);
    return Unit_instance;
  };
}
function get_gamepad(_this__u8e3s4) {
  _init_properties_GamepadEvents_kt__kfzyw8();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = gamepad$delegate;
    var property = gamepad$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var gamepad$delegate;
function GamePadEvents$lambda(this$0) {
  return function (event) {
    var tmp = this$0;
    var tmp_0 = event.c8b_1;
    tmp.rbj_1 = tmp_0 instanceof Views ? tmp_0 : THROW_CCE();
    this$0.sbj_1.s8h(event);
    var gamepadsUpdated = false;
    var inductionVariable = 0;
    var last = event.l8g_1;
    var tmp_1;
    if (inductionVariable < last) {
      do {
        var gamepadIndex = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var gamepad = event.m8g_1[gamepadIndex];
        var oldGamepad = this$0.ybj_1.m8g_1[gamepadIndex];
        var updateCount = 0;
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var this_0 = Companion_getInstance_15().t8h_1;
        var n = 0;
        while (n < this_0.length) {
          // Inline function 'korlibs.korge.input.GamePadEvents.<anonymous>.<anonymous>' call
          var _unary__edvuaz = n;
          n = _unary__edvuaz + 1 | 0;
          var button = this_0[_unary__edvuaz];
          if (!(gamepad.j8h(button) === oldGamepad.j8h(button))) {
            updateCount = updateCount + 1 | 0;
            // Inline function 'kotlin.apply' call
            var this_1 = this$0.abk_1;
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge.input.GamePadEvents.<anonymous>.<anonymous>.<anonymous>' call
            this_1.ebk_1 = gamepad.n8g_1;
            this_1.dbk_1 = !(gamepad.j8h(button) === 0.0) ? Type_DOWN_getInstance_1() : Type_UP_getInstance_1();
            this_1.fbk_1 = button;
            this_1.gbk_1 = gamepad.j8h(button);
            this$0.wbj_1.x1x(this_1);
          }
        }
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var this_2 = Companion_getInstance_16().v8h_1;
        var n_0 = 0;
        while (n_0 < this_2.length) {
          // Inline function 'korlibs.korge.input.GamePadEvents.<anonymous>.<anonymous>' call
          var _unary__edvuaz_0 = n_0;
          n_0 = _unary__edvuaz_0 + 1 | 0;
          var stick = this_2[_unary__edvuaz_0];
          var vector = gamepad.n8h(stick);
          if (!vector.equals(oldGamepad.n8h(stick))) {
            updateCount = updateCount + 1 | 0;
            // Inline function 'kotlin.apply' call
            var this_3 = this$0.zbj_1;
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge.input.GamePadEvents.<anonymous>.<anonymous>.<anonymous>' call
            this_3.kbk_1 = gamepad.n8g_1;
            this_3.lbk_1 = stick;
            this_3.mbk_1 = vector;
            this$0.vbj_1.x1x(this_3);
          }
        }
        if (updateCount > 0) {
          this$0.ubj_1.x1x(gamepad);
          gamepadsUpdated = true;
        }
      }
       while (inductionVariable < last);
      tmp_1 = Unit_instance;
    }
    this$0.ybj_1.s8h(event);
    var tmp_2;
    if (gamepadsUpdated) {
      this$0.tbj_1.x1x(event);
      tmp_2 = Unit_instance;
    }
    return Unit_instance;
  };
}
function GamePadEvents$lambda_0(this$0) {
  return function (event) {
    var tmp = this$0;
    var tmp_0 = event.c8b_1;
    tmp.rbj_1 = tmp_0 instanceof Views ? tmp_0 : THROW_CCE();
    this$0.xbj_1.x1x(event);
    return Unit_instance;
  };
}
var Companion_instance_17;
function Companion_getInstance_50() {
  return Companion_instance_17;
}
var Type_UP_instance;
var Type_DOWN_instance;
var Type_entriesInitialized;
function Type_initEntries() {
  if (Type_entriesInitialized)
    return Unit_instance;
  Type_entriesInitialized = true;
  Type_UP_instance = new Type('UP', 0);
  Type_DOWN_instance = new Type('DOWN', 1);
}
function Type_UP_getInstance_1() {
  Type_initEntries();
  return Type_UP_instance;
}
function Type_DOWN_getInstance_1() {
  Type_initEntries();
  return Type_DOWN_instance;
}
function gamepad$delegate$lambda($this$PropertyThis) {
  _init_properties_GamepadEvents_kt__kfzyw8();
  return new GamePadEvents($this$PropertyThis);
}
function gamepad$factory() {
  return getPropertyCallableRef('gamepad', 1, KProperty1, function (receiver) {
    return get_gamepad(receiver);
  }, null);
}
var properties_initialized_GamepadEvents_kt_whzq2e;
function _init_properties_GamepadEvents_kt__kfzyw8() {
  if (!properties_initialized_GamepadEvents_kt_whzq2e) {
    properties_initialized_GamepadEvents_kt_whzq2e = true;
    gamepad$delegate = new PropertyThis(VOID, gamepad$delegate$lambda);
  }
}
function get_gestures(_this__u8e3s4) {
  _init_properties_GestureEvents_kt__6u888g();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = gestures$delegate;
    var property = gestures$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var gestures$delegate;
function GestureEvents$lambda(this$0) {
  return function (event) {
    var tmp = this$0;
    var tmp_0 = event.c8b_1;
    tmp.ubk_1 = tmp_0 instanceof Views ? tmp_0 : THROW_CCE();
    this$0.pbk_1.b8e(event);
    var tmp_1;
    switch (event.w8d_1.v3_1) {
      case 0:
        this$0.qbk_1.x1x(this$0);
        tmp_1 = Unit_instance;
        break;
      case 1:
        this$0.qbk_1.x1x(this$0);
        tmp_1 = Unit_instance;
        break;
      case 2:
        this$0.sbk_1.x1x(this$0);
        tmp_1 = Unit_instance;
        break;
      case 3:
        this$0.tbk_1.x1x(this$0);
        tmp_1 = Unit_instance;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return Unit_instance;
  };
}
function gestures$delegate$lambda($this$PropertyThis) {
  _init_properties_GestureEvents_kt__6u888g();
  return new GestureEvents($this$PropertyThis);
}
function gestures$factory() {
  return getPropertyCallableRef('gestures', 1, KProperty1, function (receiver) {
    return get_gestures(receiver);
  }, null);
}
var properties_initialized_GestureEvents_kt_8pfkjy;
function _init_properties_GestureEvents_kt__6u888g() {
  if (!properties_initialized_GestureEvents_kt_8pfkjy) {
    properties_initialized_GestureEvents_kt_8pfkjy = true;
    gestures$delegate = new PropertyThis(VOID, gestures$delegate$lambda);
  }
}
function get_keys(_this__u8e3s4) {
  _init_properties_KeysEvents_kt__ekyd0z();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = keys$delegate;
    var property = keys$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var keys$delegate;
function *_generator_invoke__zhh2q8_4($this, it, $completion) {
  if ($this.wbk_1.f3(it.f8c_1)) {
    var tmp = $this.xbk_1(it, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_5($this, it, $completion) {
  var tmp = $this.ybk_1(it, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_6($this, it, $completion) {
  if (it.f8c_1.equals($this.zbk_1)) {
    var tmp = $this.abl_1(it, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_7($this, e, $completion) {
  if ($this.bbl_1.f3(e.f8c_1)) {
    var tmp = $this.cbl_1(e, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_8($this, it, $completion) {
  var tmp = $this.dbl_1(it, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_9($this, $completion) {
  var tmp = $this.kbl_1.ibl_1.o1l($this.lbl_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_10($this, $completion) {
  var tmp = $this.mbl_1.gbl_1.o1l($this.nbl_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_11($this, $completion) {
  var tmp = $this.obl_1.hbl_1.o1l($this.pbl_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function KeysEvents$downFrame$lambda(this$0, $keys, $callback, $ke) {
  return function ($this$addOptFixedUpdater, dt) {
    var tmp;
    if (!(this$0.fbl_1 == null)) {
      var vkeys = this$0.qbl().l3();
      var this_0 = $keys;
      var n = 0;
      while (n < this_0.j1()) {
        // Inline function 'korlibs.korge.input.KeysEvents.downFrame.<anonymous>.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        var key = this_0.i1(_unary__edvuaz);
        if (vkeys.ma9(key)) {
          $callback(this$0.rbl($ke, key, vkeys, dt.hk_1));
        }
      }
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function KeysEvents$down$slambda_2($keys, $callback) {
  var i = new KeysEvents$down$slambda($keys, $callback);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function KeysEvents$down$slambda_3($callback) {
  var i = new KeysEvents$down$slambda_0($callback);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function KeysEvents$down$slambda_4($key, $callback) {
  var i = new KeysEvents$down$slambda_1($key, $callback);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function KeysEvents$up$slambda_0($keys, $callback) {
  var i = new KeysEvents$up$slambda($keys, $callback);
  var l = function (e, $completion) {
    return i.sbl(e, $completion);
  };
  l.$arity = 1;
  return l;
}
function KeysEvents$typed$slambda_0($callback) {
  var i = new KeysEvents$typed$slambda($callback);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function KeysEvents$lambda$slambda_2(this$0, $event) {
  var i = new KeysEvents$lambda$slambda(this$0, $event);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function KeysEvents$lambda(this$0) {
  return function (event) {
    var tmp = this$0;
    var tmp_0 = event.c8b_1;
    tmp.fbl_1 = tmp_0 instanceof Views ? tmp_0 : THROW_CCE();
    var tmp_1 = this$0.qbl().yb6_1;
    launchImmediately_0(tmp_1, KeysEvents$lambda$slambda_2(this$0, event));
    return Unit_instance;
  };
}
function KeysEvents$lambda$slambda_3(this$0, $event) {
  var i = new KeysEvents$lambda$slambda_0(this$0, $event);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function KeysEvents$lambda_0(this$0) {
  return function (event) {
    var tmp = this$0;
    var tmp_0 = event.c8b_1;
    tmp.fbl_1 = tmp_0 instanceof Views ? tmp_0 : THROW_CCE();
    var tmp_1 = this$0.qbl().yb6_1;
    launchImmediately_0(tmp_1, KeysEvents$lambda$slambda_3(this$0, event));
    return Unit_instance;
  };
}
function KeysEvents$lambda$slambda_4(this$0, $event) {
  var i = new KeysEvents$lambda$slambda_1(this$0, $event);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function KeysEvents$lambda_1(this$0) {
  return function (event) {
    var tmp = this$0;
    var tmp_0 = event.c8b_1;
    tmp.fbl_1 = tmp_0 instanceof Views ? tmp_0 : THROW_CCE();
    var tmp_1 = this$0.qbl().yb6_1;
    launchImmediately_0(tmp_1, KeysEvents$lambda$slambda_4(this$0, event));
    return Unit_instance;
  };
}
function keys$delegate$lambda($this$PropertyThis) {
  _init_properties_KeysEvents_kt__ekyd0z();
  return new KeysEvents($this$PropertyThis);
}
function keys$factory() {
  return getPropertyCallableRef('keys', 1, KProperty1, function (receiver) {
    return get_keys(receiver);
  }, null);
}
var properties_initialized_KeysEvents_kt_1mffin;
function _init_properties_KeysEvents_kt__ekyd0z() {
  if (!properties_initialized_KeysEvents_kt_1mffin) {
    properties_initialized_KeysEvents_kt_1mffin = true;
    keys$delegate = new PropertyThis(VOID, keys$delegate$lambda);
  }
}
function onMouseDrag(_this__u8e3s4, timeProvider, info, callback) {
  timeProvider = timeProvider === VOID ? Companion_instance_0 : timeProvider;
  info = info === VOID ? new MouseDragInfo(_this__u8e3s4) : info;
  return onMouseDragInternal(_this__u8e3s4, timeProvider, info, callback).im_1;
}
function onMouseDragInternal(_this__u8e3s4, timeProvider, info, callback) {
  timeProvider = timeProvider === VOID ? Companion_instance_0 : timeProvider;
  info = info === VOID ? new MouseDragInfo(_this__u8e3s4) : info;
  var dragging = {_v: false};
  var sx = {_v: 0.0};
  var sy = {_v: 0.0};
  var cx = {_v: 0.0};
  var cy = {_v: 0.0};
  var view = _this__u8e3s4;
  var mousePos = {_v: Vector2D.i2l()};
  var onDownCloseable = null;
  var onUpAnywhereCloseable = null;
  var onMoveAnywhereCloseable = null;
  // Inline function 'korlibs.korge.input.mouse' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var $this$mouse = get_mouse(_this__u8e3s4);
  // Inline function 'korlibs.korge.input.MouseEvents.onDownCloseable' call
  var handler = onMouseDragInternal$slambda_2(dragging, info, mousePos, sx, sy, cx, cy, callback, timeProvider, view);
  // Inline function 'korlibs.korge.input.MouseEvents._mouseEventCloseable' call
  var tmp = down$factory().get($this$mouse);
  onDownCloseable = tmp.v1x(onMouseDragInternal$lambda($this$mouse, handler));
  // Inline function 'korlibs.korge.input.MouseEvents.onUpAnywhereCloseable' call
  var handler_0 = onMouseDragInternal$slambda_3(dragging, info, mousePos, sx, sy, cx, cy, callback, timeProvider, view);
  // Inline function 'korlibs.korge.input.MouseEvents._mouseEventCloseable' call
  var tmp_0 = upAnywhere$factory().get($this$mouse);
  onUpAnywhereCloseable = tmp_0.v1x(onMouseDragInternal$lambda_0($this$mouse, handler_0));
  // Inline function 'korlibs.korge.input.MouseEvents.onMoveAnywhereCloseable' call
  var handler_1 = onMouseDragInternal$slambda_4(dragging, info, mousePos, sx, sy, cx, cy, callback, timeProvider, view);
  // Inline function 'korlibs.korge.input.MouseEvents._mouseEventCloseable' call
  var tmp_1 = moveAnywhere$factory().get($this$mouse);
  onMoveAnywhereCloseable = tmp_1.v1x(onMouseDragInternal$lambda_1($this$mouse, handler_1));
  var tmp_2;
  if (onDownCloseable == null) {
    throwUninitializedPropertyAccessException('onDownCloseable');
  } else {
    tmp_2 = onDownCloseable;
  }
  var tmp_3 = tmp_2;
  var tmp_4;
  if (onUpAnywhereCloseable == null) {
    throwUninitializedPropertyAccessException('onUpAnywhereCloseable');
  } else {
    tmp_4 = onUpAnywhereCloseable;
  }
  var tmp_5 = tmp_4;
  var tmp_6;
  if (onMoveAnywhereCloseable == null) {
    throwUninitializedPropertyAccessException('onMoveAnywhereCloseable');
  } else {
    tmp_6 = onMoveAnywhereCloseable;
  }
  return to(_this__u8e3s4, new OnMouseDragCloseable(tmp_3, tmp_5, tmp_6));
}
var MouseDragState_START_instance;
var MouseDragState_DRAG_instance;
var MouseDragState_END_instance;
var MouseDragState_entriesInitialized;
function MouseDragState_initEntries() {
  if (MouseDragState_entriesInitialized)
    return Unit_instance;
  MouseDragState_entriesInitialized = true;
  MouseDragState_START_instance = new MouseDragState('START', 0);
  MouseDragState_DRAG_instance = new MouseDragState('DRAG', 1);
  MouseDragState_END_instance = new MouseDragState('END', 2);
}
function draggable(_this__u8e3s4, selector, autoMove, onDrag) {
  selector = selector === VOID ? _this__u8e3s4 : selector;
  autoMove = autoMove === VOID ? true : autoMove;
  onDrag = onDrag === VOID ? null : onDrag;
  return draggableInternal(_this__u8e3s4, selector, autoMove, onDrag).im_1;
}
function draggableInternal(_this__u8e3s4, selector, autoMove, onDrag) {
  selector = selector === VOID ? _this__u8e3s4 : selector;
  autoMove = autoMove === VOID ? true : autoMove;
  onDrag = onDrag === VOID ? null : onDrag;
  var view = _this__u8e3s4;
  var info = new DraggableInfo(view);
  var onMouseDragCloseable_0 = onMouseDragCloseable(selector, VOID, info, draggableInternal$lambda(info, view, autoMove, onDrag));
  return to(_this__u8e3s4, new DraggableCloseable(onMouseDragCloseable_0));
}
function onMouseDragCloseable(_this__u8e3s4, timeProvider, info, callback) {
  timeProvider = timeProvider === VOID ? Companion_instance_0 : timeProvider;
  info = info === VOID ? new MouseDragInfo(_this__u8e3s4) : info;
  return onMouseDragInternal(_this__u8e3s4, timeProvider, info, callback).jm_1;
}
function onMouseDragInternal$views(view) {
  return ensureNotNull(view.pbj()).vbk();
}
function onMouseDragInternal$updateMouse(mousePos, view) {
  var views = onMouseDragInternal$views(view);
  mousePos._v = views.ibo();
}
function onMouseDragInternal$handle(dragging, $info, mousePos, sx, sy, cx, cy, $callback, $timeProvider, view, it, state) {
  if (!state.equals(MouseDragState_START_getInstance()) && !dragging._v)
    return Unit_instance;
  onMouseDragInternal$updateMouse(mousePos, view);
  $info.obm_1 = it;
  var px = mousePos._v.i2h_1;
  var py = mousePos._v.j2h_1;
  switch (state.v3_1) {
    case 0:
      dragging._v = true;
      sx._v = px;
      sy._v = py;
      $info.z33();
      break;
    case 2:
      dragging._v = false;
      break;
    default:
      break;
  }
  cx._v = mousePos._v.i2h_1;
  cy._v = mousePos._v.j2h_1;
  var dx = cx._v - sx._v;
  var dy = cy._v - sy._v;
  $callback(onMouseDragInternal$views(view), $info.bbn(dx, dy, state.u8f(), state.v8f(), $timeProvider.h1w(), sx._v, sy._v, cx._v, cy._v));
}
function onMouseDragInternal$slambda_2($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view) {
  var i = new onMouseDragInternal$slambda($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_12($this, $completion) {
  var tmp = $this.ubo_1($this.vbo_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function onMouseDragInternal$lambda$slambda_2($handler, $it) {
  var i = new onMouseDragInternal$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function onMouseDragInternal$lambda($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, onMouseDragInternal$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function onMouseDragInternal$slambda_3($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view) {
  var i = new onMouseDragInternal$slambda_0($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_13($this, $completion) {
  var tmp = $this.hbp_1($this.ibp_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function onMouseDragInternal$lambda$slambda_3($handler, $it) {
  var i = new onMouseDragInternal$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function onMouseDragInternal$lambda_0($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, onMouseDragInternal$lambda$slambda_3($handler, it));
    return Unit_instance;
  };
}
function onMouseDragInternal$slambda_4($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view) {
  var i = new onMouseDragInternal$slambda_1($dragging, $info, $mousePos, $sx, $sy, $cx, $cy, $callback, $timeProvider, $view);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_14($this, $completion) {
  var tmp = $this.tbp_1($this.ubp_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function onMouseDragInternal$lambda$slambda_4($handler, $it) {
  var i = new onMouseDragInternal$lambda$slambda_1($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function onMouseDragInternal$lambda_1($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, onMouseDragInternal$lambda$slambda_4($handler, it));
    return Unit_instance;
  };
}
function draggableInternal$lambda($info, $view, $autoMove, $onDrag) {
  return function ($this$onMouseDragCloseable, it) {
    var tmp;
    if ($info.gbm_1) {
      $info.xbn_1 = $view.lz();
      tmp = Unit_instance;
    }
    $info.ybn_1 = $view.lz();
    $info.zbn_1 = Vector2D.k2h($info.bbo() + $info.wbm($view), $info.cbo() + $info.ybm($view));
    $info.abo_1 = Vector2D.k2h($info.fbo() - $info.dbo(), $info.gbo() - $info.ebo());
    var tmp_0;
    if ($autoMove) {
      xy($view, $info.zbn_1);
      tmp_0 = Unit_instance;
    }
    var tmp38_safe_receiver = $onDrag;
    if (tmp38_safe_receiver == null)
      null;
    else
      tmp38_safe_receiver($info);
    return Unit_instance;
  };
}
function MouseDragState_START_getInstance() {
  MouseDragState_initEntries();
  return MouseDragState_START_instance;
}
function MouseDragState_DRAG_getInstance() {
  MouseDragState_initEntries();
  return MouseDragState_DRAG_instance;
}
function MouseDragState_END_getInstance() {
  MouseDragState_initEntries();
  return MouseDragState_END_instance;
}
function down$factory() {
  return getPropertyCallableRef('down', 1, KProperty1, function (receiver) {
    return receiver.cbi_1;
  }, null);
}
function upAnywhere$factory() {
  return getPropertyCallableRef('upAnywhere', 1, KProperty1, function (receiver) {
    return receiver.jbi_1;
  }, null);
}
function moveAnywhere$factory() {
  return getPropertyCallableRef('moveAnywhere', 1, KProperty1, function (receiver) {
    return receiver.lbi_1;
  }, null);
}
function set_mouseHitSearch(_this__u8e3s4, _set____db54di) {
  _init_properties_MouseEvents_kt__io5soc();
  var this_0 = mouseHitSearch$delegate;
  var property = mouseHitSearch$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_mouseHitSearch(_this__u8e3s4) {
  _init_properties_MouseEvents_kt__io5soc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = mouseHitSearch$delegate;
    var property = mouseHitSearch$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var mouseHitSearch$delegate;
function set_mouseHitResult(_this__u8e3s4, _set____db54di) {
  _init_properties_MouseEvents_kt__io5soc();
  var this_0 = mouseHitResult$delegate;
  var property = mouseHitResult$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_mouseHitResult(_this__u8e3s4) {
  _init_properties_MouseEvents_kt__io5soc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = mouseHitResult$delegate;
    var property = mouseHitResult$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var mouseHitResult$delegate;
function set_mouseHitResultUsed(_this__u8e3s4, _set____db54di) {
  _init_properties_MouseEvents_kt__io5soc();
  var this_0 = mouseHitResultUsed$delegate;
  var property = mouseHitResultUsed$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_mouseHitResultUsed(_this__u8e3s4) {
  _init_properties_MouseEvents_kt__io5soc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = mouseHitResultUsed$delegate;
    var property = mouseHitResultUsed$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var mouseHitResultUsed$delegate;
function set_mouseDebugHandlerOnce(_this__u8e3s4, _set____db54di) {
  _init_properties_MouseEvents_kt__io5soc();
  var this_0 = mouseDebugHandlerOnce$delegate;
  var property = mouseDebugHandlerOnce$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_mouseDebugHandlerOnce(_this__u8e3s4) {
  _init_properties_MouseEvents_kt__io5soc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = mouseDebugHandlerOnce$delegate;
    var property = mouseDebugHandlerOnce$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var mouseDebugHandlerOnce$delegate;
function set_mouseDebugLastFrameClicked(_this__u8e3s4, _set____db54di) {
  _init_properties_MouseEvents_kt__io5soc();
  var this_0 = mouseDebugLastFrameClicked$delegate;
  var property = mouseDebugLastFrameClicked$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_mouseDebugLastFrameClicked(_this__u8e3s4) {
  _init_properties_MouseEvents_kt__io5soc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = mouseDebugLastFrameClicked$delegate;
    var property = mouseDebugLastFrameClicked$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var mouseDebugLastFrameClicked$delegate;
function get_mouse(_this__u8e3s4) {
  _init_properties_MouseEvents_kt__io5soc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = mouse$delegate;
    var property = mouse$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var mouse$delegate;
function set_cursor(_this__u8e3s4, _set____db54di) {
  _init_properties_MouseEvents_kt__io5soc();
  var this_0 = cursor$delegate;
  var property = cursor$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_cursor(_this__u8e3s4) {
  _init_properties_MouseEvents_kt__io5soc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = cursor$delegate;
    var property = cursor$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var cursor$delegate;
function mouseHitTest($this, views) {
  if (!get_mouseHitSearch(views.bb7_1)) {
    set_mouseHitSearch(views.bb7_1, true);
    set_mouseHitResult(views.bb7_1, views.kb8_1.vbp(views.ibo()));
    var view = get_mouseHitResult(views.bb7_1);
    $l$loop: while (!(view == null) && get_cursor(view) == null) {
      view = view.w15();
    }
    var tmp39_safe_receiver = view;
    var tmp0_elvis_lhs = tmp39_safe_receiver == null ? null : get_cursor(tmp39_safe_receiver);
    var newCursor = tmp0_elvis_lhs == null ? Cursor_DEFAULT_getInstance() : tmp0_elvis_lhs;
    if (!equals(views.eb7_1.raq(), newCursor)) {
      views.eb7_1.qaq(newCursor);
    }
  }
  return get_mouseHitResult(views.bb7_1);
}
function MouseEvents$Companion$installDebugExtensionOnce$lambda($views) {
  return function (_this__u8e3s4, ctx) {
    var scale = ctx.xah();
    // Inline function 'kotlin.math.max' call
    var a = 1 * scale;
    var space = Math.max(a, 2.0);
    var matrix = $views.bag();
    var yy = 60 * scale;
    var lineHeight = 8 * scale;
    var mouseHit = mouseHitTest(Companion_instance_18, $views);
    var tmp;
    if (!(mouseHit == null)) {
      var bounds = mouseHit.wbp();
      if (get_mouseDebugLastFrameClicked(_this__u8e3s4)) {
        $views.xbp(mouseHit);
      }
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
      var this_0 = _this__u8e3s4.xb7_1.ka7_1;
      var this_1 = this_0.ta7_1;
      if (!(this_1.oa7_1 === this_0)) {
        this_1.oa7_1 = this_0;
        this_1.ya8();
      }
      // Inline function 'korlibs.image.bitmap.Bitmaps.white' call
      var tmp$ret$1 = get_Bitmaps_white();
      var tmp40_tex = ctx.za8(tmp$ret$1);
      var tmp41_x = bounds.i2i_1;
      var tmp42_y = bounds.j2i_1;
      var tmp43_width = bounds.k2i_1;
      var tmp44_height = bounds.l2i_1;
      var tmp45_colorMul = RGBA__withAd_impl_cralao(Colors_getInstance().i4r_1, 0.3);
      var tmp46_m = mouseHit.ybp();
      this_0.aa9(tmp40_tex, tmp41_x, tmp42_y, tmp43_width, tmp44_height, tmp46_m, VOID, tmp45_colorMul);
      var tmp47_$receiver = _this__u8e3s4.xb7_1;
      var tmp48_font = _this__u8e3s4.zbp();
      var tmp49_str = toString_0(mouseHit) + ' : ' + $views.ibo().i2h_1 + ',' + $views.ibo().j2h_1;
      var tmp50_y = numberToInt(yy);
      var tmp51_colMul = ctx.ja7_1;
      var tmp52_blendMode = Companion_getInstance_17().ba6_1;
      var tmp53_m = get_immutable(matrix);
      drawText(tmp47_$receiver, tmp48_font, lineHeight, tmp49_str, 0, tmp50_y, tmp53_m, tmp51_colMul, tmp52_blendMode, false);
      yy = yy + lineHeight;
      yy = yy + space;
      tmp = Unit_instance;
    }
    var mouseHitResultUsed = get_mouseHitResultUsed(_this__u8e3s4.bb7_1);
    var tmp_0;
    if (!(mouseHitResultUsed == null)) {
      var bounds_0 = mouseHitResultUsed.wbp();
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
      var this_2 = _this__u8e3s4.xb7_1.ka7_1;
      var this_3 = this_2.ta7_1;
      if (!(this_3.oa7_1 === this_2)) {
        this_3.oa7_1 = this_2;
        this_3.ya8();
      }
      // Inline function 'korlibs.image.bitmap.Bitmaps.white' call
      var tmp$ret$4 = get_Bitmaps_white();
      var tmp54_tex = ctx.za8(tmp$ret$4);
      var tmp55_x = bounds_0.i2i_1;
      var tmp56_y = bounds_0.j2i_1;
      var tmp57_width = bounds_0.k2i_1;
      var tmp58_height = bounds_0.l2i_1;
      var tmp59_colorMul = Companion_getInstance_3().a57(0, 0, 255, 63);
      var tmp60_m = mouseHitResultUsed.ybp();
      this_2.aa9(tmp54_tex, tmp55_x, tmp56_y, tmp57_width, tmp58_height, tmp60_m, VOID, tmp59_colorMul);
      var vview = mouseHitResultUsed;
      while (!(vview == null)) {
        var tmp61_$receiver = _this__u8e3s4.xb7_1;
        var tmp62_font = _this__u8e3s4.zbp();
        var tmp63_str = vview.toString();
        var tmp64_y = numberToInt(yy);
        var tmp65_colMul = ctx.ja7_1;
        var tmp66_blendMode = Companion_getInstance_17().ba6_1;
        var tmp67_m = get_immutable(matrix);
        drawText(tmp61_$receiver, tmp62_font, lineHeight, tmp63_str, 0, tmp64_y, tmp67_m, tmp65_colMul, tmp66_blendMode, false);
        vview = vview.w15();
        yy = yy + lineHeight;
        yy = yy + space;
      }
      tmp_0 = Unit_instance;
    }
    set_mouseDebugLastFrameClicked(_this__u8e3s4, false);
    return Unit_instance;
  };
}
function MouseEvents$MouseEventsUpdate$lambda(this$0) {
  return function ($this$addUpdaterWithViews, views, dt) {
    this$0.abq(views, dt.hk_1);
    return Unit_instance;
  };
}
var Companion_instance_18;
function Companion_getInstance_51() {
  return Companion_instance_18;
}
function temporalLastEvent($this, lastEventNew, block) {
  var old = $this.fbj_1;
  var tmp = $this;
  tmp.fbj_1 = lastEventNew == null ? $this.fbj_1 : lastEventNew;
  try {
    return block();
  }finally {
    $this.fbj_1 = old;
  }
}
function MouseEvents$closeable$lambda(this$0) {
  return function (event) {
    var tmp;
    if (!this$0.wbh_1.fbq()) {
      return Unit_instance;
    }
    var tmp_0 = this$0;
    var tmp_1 = event.c8b_1;
    tmp_0.xbh_1 = tmp_1 instanceof Views ? tmp_1 : THROW_CCE();
    this$0.ebj_1 = event;
    this$0.fbj_1.p8d(event);
    this$0.gbj_1 = event.l8d_1;
    this$0.dbj_1 = true;
    var tmp_2;
    switch (event.x8c_1.v3_1) {
      case 2:
        this$0.hbj_1.p8d(event);
        this$0.ybi_1 = this$0.vbk().bb7_1.saa();
        this$0.vbi_1 = PerformanceCounter_getInstance().j88();
        var elapsedTime = Duration__minus_impl_q5cfm7(this$0.vbi_1, this$0.wbi_1);
        var tmp_3;
        if (this$0.ybi_1.s2w(this$0.xbi_1) < this$0.vbk().bb7_1.ya9_1 && Duration__compareTo_impl_pchp0f(elapsedTime, this$0.vbk().bb7_1.xa9_1) < 0) {
          var _unary__edvuaz = this$0.cbj_1;
          this$0.cbj_1 = _unary__edvuaz + 1 | 0;
          var tmp_4;
          if (this$0.gbq()) {
            this$0.zbh_1.x1x(this$0);
            var tmp_5;
            if (this$0.zbh_1.c1y() > 0) {
              event.f8b(this$0.wbh_1);
              tmp_5 = Unit_instance;
            }
            tmp_4 = tmp_5;
          }
          tmp_3 = tmp_4;
        }

        tmp_2 = tmp_3;
        break;
      case 3:
        if (!event.i8d_1) {
          set_mouseDebugLastFrameClicked(this$0.vbk(), true);
        }

        this$0.wbi_1 = PerformanceCounter_getInstance().j88();
        this$0.xbi_1 = this$0.vbk().bb7_1.saa();
        var tmp_6;
        if (this$0.ybh_1.d1y()) {
          var tmp_7;
          if (this$0.gbq()) {
            this$0.ybh_1.x1x(this$0);
            tmp_7 = Unit_instance;
          }
          tmp_6 = tmp_7;
        }

        tmp_2 = tmp_6;
        break;
      case 7:
        if (this$0.gbq()) {
          this$0.obi_1.x1x(this$0);
        } else {
          this$0.qbi_1.x1x(this$0);
        }

        this$0.pbi_1.x1x(this$0);
        tmp_2 = Unit_instance;
        break;
      default:
        tmp_2 = Unit_instance;
        break;
    }
    return Unit_instance;
  };
}
function MouseEvents$update$lambda($over, this$0) {
  return function () {
    var tmp;
    if ($over) {
      this$0.fbi_1.x1x(this$0);
      tmp = Unit_instance;
    } else {
      this$0.gbi_1.x1x(this$0);
      this$0.ibi_1.x1x(this$0);
      tmp = Unit_instance;
    }
    this$0.jbi_1.x1x(this$0);
    return Unit_instance;
  };
}
function doubleClick(_this__u8e3s4, callback) {
  _init_properties_MouseEvents_kt__io5soc();
  return multiClick(_this__u8e3s4, 2, callback);
}
function multiClick(_this__u8e3s4, count, callback) {
  _init_properties_MouseEvents_kt__io5soc();
  var clickCount = {_v: 0};
  var lastClickTime = {_v: new DateTime(Companion_getInstance_7().c1w_1)};
  return _this__u8e3s4.zbh_1.w1x(multiClick$lambda(lastClickTime, clickCount, count, callback));
}
function mouseHitSearch$delegate$lambda() {
  _init_properties_MouseEvents_kt__io5soc();
  return false;
}
function mouseHitResult$delegate$lambda() {
  _init_properties_MouseEvents_kt__io5soc();
  return null;
}
function mouseHitResultUsed$delegate$lambda() {
  _init_properties_MouseEvents_kt__io5soc();
  return null;
}
function mouseDebugHandlerOnce$delegate$lambda() {
  _init_properties_MouseEvents_kt__io5soc();
  return new Once();
}
function mouseDebugLastFrameClicked$delegate$lambda() {
  _init_properties_MouseEvents_kt__io5soc();
  return false;
}
function mouse$delegate$lambda($this$PropertyThis) {
  _init_properties_MouseEvents_kt__io5soc();
  return new MouseEvents($this$PropertyThis);
}
function cursor$delegate$lambda() {
  _init_properties_MouseEvents_kt__io5soc();
  return null;
}
function multiClick$lambda($lastClickTime, $clickCount, $count, $callback) {
  return function (it) {
    var currentClickTime = Companion_getInstance_7().h1w();
    var tmp;
    var tmp_0 = DateTime__minus_impl_10njw8(currentClickTime, $lastClickTime._v.k1w_1);
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$0 = toDuration(0.3, DurationUnit_SECONDS_getInstance());
    if (Duration__compareTo_impl_pchp0f(tmp_0, tmp$ret$0) > 0) {
      $clickCount._v = 0;
      tmp = Unit_instance;
    }
    $lastClickTime._v = new DateTime(currentClickTime);
    var _unary__edvuaz = $clickCount._v;
    $clickCount._v = _unary__edvuaz + 1 | 0;
    it.cbj_1 = $clickCount._v;
    var tmp_1;
    if ($clickCount._v === $count) {
      tmp_1 = $callback(it);
    }
    return Unit_instance;
  };
}
function mouseHitSearch$factory() {
  return getPropertyCallableRef('mouseHitSearch', 1, KMutableProperty1, function (receiver) {
    return get_mouseHitSearch(receiver);
  }, function (receiver, value) {
    return set_mouseHitSearch(receiver, value);
  });
}
function mouseHitSearch$factory_0() {
  return getPropertyCallableRef('mouseHitSearch', 1, KMutableProperty1, function (receiver) {
    return get_mouseHitSearch(receiver);
  }, function (receiver, value) {
    return set_mouseHitSearch(receiver, value);
  });
}
function mouseHitResult$factory() {
  return getPropertyCallableRef('mouseHitResult', 1, KMutableProperty1, function (receiver) {
    return get_mouseHitResult(receiver);
  }, function (receiver, value) {
    return set_mouseHitResult(receiver, value);
  });
}
function mouseHitResult$factory_0() {
  return getPropertyCallableRef('mouseHitResult', 1, KMutableProperty1, function (receiver) {
    return get_mouseHitResult(receiver);
  }, function (receiver, value) {
    return set_mouseHitResult(receiver, value);
  });
}
function mouseHitResultUsed$factory() {
  return getPropertyCallableRef('mouseHitResultUsed', 1, KMutableProperty1, function (receiver) {
    return get_mouseHitResultUsed(receiver);
  }, function (receiver, value) {
    return set_mouseHitResultUsed(receiver, value);
  });
}
function mouseHitResultUsed$factory_0() {
  return getPropertyCallableRef('mouseHitResultUsed', 1, KMutableProperty1, function (receiver) {
    return get_mouseHitResultUsed(receiver);
  }, function (receiver, value) {
    return set_mouseHitResultUsed(receiver, value);
  });
}
function mouseDebugHandlerOnce$factory() {
  return getPropertyCallableRef('mouseDebugHandlerOnce', 1, KMutableProperty1, function (receiver) {
    return get_mouseDebugHandlerOnce(receiver);
  }, function (receiver, value) {
    return set_mouseDebugHandlerOnce(receiver, value);
  });
}
function mouseDebugHandlerOnce$factory_0() {
  return getPropertyCallableRef('mouseDebugHandlerOnce', 1, KMutableProperty1, function (receiver) {
    return get_mouseDebugHandlerOnce(receiver);
  }, function (receiver, value) {
    return set_mouseDebugHandlerOnce(receiver, value);
  });
}
function mouseDebugLastFrameClicked$factory() {
  return getPropertyCallableRef('mouseDebugLastFrameClicked', 1, KMutableProperty1, function (receiver) {
    return get_mouseDebugLastFrameClicked(receiver);
  }, function (receiver, value) {
    return set_mouseDebugLastFrameClicked(receiver, value);
  });
}
function mouseDebugLastFrameClicked$factory_0() {
  return getPropertyCallableRef('mouseDebugLastFrameClicked', 1, KMutableProperty1, function (receiver) {
    return get_mouseDebugLastFrameClicked(receiver);
  }, function (receiver, value) {
    return set_mouseDebugLastFrameClicked(receiver, value);
  });
}
function mouse$factory() {
  return getPropertyCallableRef('mouse', 1, KProperty1, function (receiver) {
    return get_mouse(receiver);
  }, null);
}
function cursor$factory() {
  return getPropertyCallableRef('cursor', 1, KMutableProperty1, function (receiver) {
    return get_cursor(receiver);
  }, function (receiver, value) {
    return set_cursor(receiver, value);
  });
}
function cursor$factory_0() {
  return getPropertyCallableRef('cursor', 1, KMutableProperty1, function (receiver) {
    return get_cursor(receiver);
  }, function (receiver, value) {
    return set_cursor(receiver, value);
  });
}
var properties_initialized_MouseEvents_kt_t0zz8y;
function _init_properties_MouseEvents_kt__io5soc() {
  if (!properties_initialized_MouseEvents_kt_t0zz8y) {
    properties_initialized_MouseEvents_kt_t0zz8y = true;
    mouseHitSearch$delegate = new Property(VOID, mouseHitSearch$delegate$lambda);
    mouseHitResult$delegate = new Property(VOID, mouseHitResult$delegate$lambda);
    mouseHitResultUsed$delegate = new Property(VOID, mouseHitResultUsed$delegate$lambda);
    mouseDebugHandlerOnce$delegate = new Property(VOID, mouseDebugHandlerOnce$delegate$lambda);
    mouseDebugLastFrameClicked$delegate = new Property(VOID, mouseDebugLastFrameClicked$delegate$lambda);
    mouse$delegate = new PropertyThis(VOID, mouse$delegate$lambda);
    // Inline function 'korlibs.datastructure.extraProperty' call
    var default_0 = cursor$delegate$lambda;
    cursor$delegate = new Property(null, default_0);
  }
}
function get_touch(_this__u8e3s4) {
  _init_properties_TouchEvents_kt__159jsq();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = touch$delegate;
    var property = touch$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var touch$delegate;
function onTouchEvent($this, views, e) {
  $this.nbr_1.o3();
  var now = views.cb7_1.h1w();
  if (e.z8e_1.equals(Type_START_getInstance())) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = e.i8f();
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.input.TouchEvents.onTouchEvent.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var it = this_0.i1(_unary__edvuaz);
      if (!it.r8e_1.equals(Status_KEEP_getInstance())) {
        var info = $this.pbr($this.obr($this.lbr_1.g39(), it));
        info.vbq_1 = now;
        info.zbq_1 = views;
        // Inline function 'korlibs.datastructure.set' call
        var this_1 = $this.mbr_1;
        var key = info.sbq_1;
        // Inline function 'kotlin.js.asDynamic' call
        this_1.set(key, info);
        $this.ebr_1.x1x(info);
      }
    }
  }
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var this_2 = e.i8f();
  var n_0 = 0;
  while (n_0 < this_2.j1()) {
    // Inline function 'korlibs.korge.input.TouchEvents.onTouchEvent.<anonymous>' call
    var _unary__edvuaz_0 = n_0;
    n_0 = _unary__edvuaz_0 + 1 | 0;
    var it_0 = this_2.i1(_unary__edvuaz_0);
    // Inline function 'korlibs.datastructure.get' call
    var this_3 = $this.mbr_1;
    var key_0 = it_0.o8e_1;
    // Inline function 'kotlin.js.asDynamic' call
    var info_0 = this_3.get(key_0);
    if (!(info_0 == null)) {
      info_0.ybq_1 = now;
      $this.nbr_1.f($this.obr(info_0, it_0));
    }
  }
  if (e.z8e_1.equals(Type_START_getInstance())) {
    $this.gbr_1.x1x($this);
  }
  if (e.z8e_1.equals(Type_MOVE_getInstance())) {
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = $this.nbr_1.e14_1;
    var n_1 = 0;
    while (n_1 < array.length) {
      // Inline function 'korlibs.korge.input.TouchEvents.onTouchEvent.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz_1 = n_1;
      n_1 = _unary__edvuaz_1 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it_1 = array[_unary__edvuaz_1];
      $this.fbr_1.x1x(it_1);
    }
    $this.hbr_1.x1x($this);
  }
  if (e.z8e_1.equals(Type_END_getInstance())) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_4 = e.i8f();
    var n_2 = 0;
    while (n_2 < this_4.j1()) {
      // Inline function 'korlibs.korge.input.TouchEvents.onTouchEvent.<anonymous>' call
      var _unary__edvuaz_2 = n_2;
      n_2 = _unary__edvuaz_2 + 1 | 0;
      var it_2 = this_4.i1(_unary__edvuaz_2);
      if (!it_2.r8e_1.equals(Status_KEEP_getInstance())) {
        // Inline function 'korlibs.datastructure.get' call
        var this_5 = $this.mbr_1;
        var key_1 = it_2.o8e_1;
        // Inline function 'kotlin.js.asDynamic' call
        var info_1 = this_5.get(key_1);
        if (!(info_1 == null)) {
          $this.kbr_1.x1x($this.obr(info_1, it_2));
          $this.ibr_1.x1x($this);
          // Inline function 'korlibs.datastructure.remove' call
          var this_6 = $this.mbr_1;
          var key_2 = info_1.sbq_1;
          // Inline function 'kotlin.js.asDynamic' call
          this_6.delete(key_2);
          $this.lbr_1.h39(info_1);
        }
      }
    }
  }
  $this.jbr_1.x1x($this);
}
function TouchEvents$infoPool$lambda(it) {
  return new Info(it);
}
function TouchEvents$lambda(this$0) {
  return function (e) {
    var tmp = e.c8b_1;
    onTouchEvent(this$0, tmp instanceof Views ? tmp : THROW_CCE(), e);
    return Unit_instance;
  };
}
function singleTouch(_this__u8e3s4, removeTouch, supportStartAnywhere, block) {
  removeTouch = removeTouch === VOID ? false : removeTouch;
  supportStartAnywhere = supportStartAnywhere === VOID ? false : supportStartAnywhere;
  _init_properties_TouchEvents_kt__159jsq();
  var ids = FastIntMap();
  touch(_this__u8e3s4, singleTouch$lambda(_this__u8e3s4, supportStartAnywhere, ids, block, removeTouch));
}
function touch(_this__u8e3s4, block) {
  _init_properties_TouchEvents_kt__159jsq();
  return block(get_touch(_this__u8e3s4));
}
function singleTouch$getById(ids, $block, id) {
  var tmp$ret$2;
  $l$block: {
    // Inline function 'korlibs.datastructure.getOrPut' call
    // Inline function 'korlibs.datastructure.get' call
    // Inline function 'kotlin.js.asDynamic' call
    var res = ids.get(id);
    if (!(res == null)) {
      tmp$ret$2 = res;
      break $l$block;
    }
    // Inline function 'korlibs.korge.input.singleTouch.getById.<anonymous>' call
    // Inline function 'kotlin.also' call
    var this_0 = new singleTouch$SingleTouchInfo();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.singleTouch.getById.<anonymous>.<anonymous>' call
    $block(this_0.zbr_1, id);
    var out = this_0;
    // Inline function 'korlibs.datastructure.set' call
    // Inline function 'kotlin.js.asDynamic' call
    ids.set(id, out);
    tmp$ret$2 = out;
  }
  return tmp$ret$2;
}
function touch$delegate$lambda($this$PropertyThis) {
  _init_properties_TouchEvents_kt__159jsq();
  return new TouchEvents($this$PropertyThis);
}
function singleTouch$lambda$lambda($this_singleTouch, $supportStartAnywhere, $ids, $block) {
  return function (it) {
    var info = singleTouch$getById($ids, $block, it.sbq_1);
    var handler = info.zbr_1;
    info.abs_1 = !($this_singleTouch.bbs(it.wbq_1) == null);
    var tmp;
    if (handler.rbr_1.d1y() && info.abs_1) {
      handler.rbr_1.x1x(it);
      tmp = Unit_instance;
    }
    var tmp_0;
    if ($supportStartAnywhere) {
      handler.sbr_1.x1x(it);
      tmp_0 = Unit_instance;
    }
    return Unit_instance;
  };
}
function singleTouch$lambda$lambda_0($supportStartAnywhere, $this_singleTouch, $ids, $block) {
  return function (it) {
    var info = singleTouch$getById($ids, $block, it.sbq_1);
    var tmp;
    if (!$supportStartAnywhere && !info.abs_1) {
      return Unit_instance;
    }
    var handler = info.zbr_1;
    var tmp_0;
    if (handler.tbr_1.d1y() && !($this_singleTouch.bbs(it.wbq_1) == null)) {
      handler.tbr_1.x1x(it);
      tmp_0 = Unit_instance;
    }
    handler.ubr_1.x1x(it);
    return Unit_instance;
  };
}
function singleTouch$lambda$lambda_1($supportStartAnywhere, $this_singleTouch, $removeTouch, $ids, $block) {
  return function (it) {
    var info = singleTouch$getById($ids, $block, it.sbq_1);
    var tmp;
    if (!$supportStartAnywhere && !info.abs_1) {
      return Unit_instance;
    }
    var handler = info.zbr_1;
    var hitTest = handler.vbr_1.d1y() || handler.xbr_1.d1y() ? !($this_singleTouch.bbs(it.wbq_1) == null) : false;
    var tmp_0;
    if (hitTest) {
      handler.vbr_1.x1x(it);
      tmp_0 = Unit_instance;
    }
    handler.wbr_1.x1x(it);
    var tmp_1;
    if (Companion_getInstance_34().n2w(it.xbq_1, it.wbq_1) <= it.vbk().bb7_1.ya9_1 && Duration__compareTo_impl_pchp0f(it.abr(), it.vbk().bb7_1.xa9_1) <= 0) {
      if (info.abs_1 && hitTest) {
        handler.xbr_1.x1x(it);
      }
      handler.ybr_1.x1x(it);
      tmp_1 = Unit_instance;
    }
    var tmp_2;
    if ($removeTouch) {
      var this_0 = $ids;
      var key = it.sbq_1;
      // Inline function 'kotlin.js.asDynamic' call
      this_0.delete(key);
      tmp_2 = Unit_instance;
    }
    return Unit_instance;
  };
}
function singleTouch$lambda($this_singleTouch, $supportStartAnywhere, $ids, $block, $removeTouch) {
  return function ($this$touch) {
    $this$touch.ebr_1.w1x(singleTouch$lambda$lambda($this_singleTouch, $supportStartAnywhere, $ids, $block));
    $this$touch.fbr_1.w1x(singleTouch$lambda$lambda_0($supportStartAnywhere, $this_singleTouch, $ids, $block));
    $this$touch.kbr_1.w1x(singleTouch$lambda$lambda_1($supportStartAnywhere, $this_singleTouch, $removeTouch, $ids, $block));
    return Unit_instance;
  };
}
function touch$factory() {
  return getPropertyCallableRef('touch', 1, KProperty1, function (receiver) {
    return get_touch(receiver);
  }, null);
}
var properties_initialized_TouchEvents_kt_a232zc;
function _init_properties_TouchEvents_kt__159jsq() {
  if (!properties_initialized_TouchEvents_kt_a232zc) {
    properties_initialized_TouchEvents_kt_a232zc = true;
    touch$delegate = new PropertyThis(VOID, touch$delegate$lambda);
  }
}
function scaleRecognizer(_this__u8e3s4, start, end, block) {
  var tmp;
  if (start === VOID) {
    tmp = scaleRecognizer$lambda;
  } else {
    tmp = start;
  }
  start = tmp;
  var tmp_0;
  if (end === VOID) {
    tmp_0 = scaleRecognizer$lambda_0;
  } else {
    tmp_0 = end;
  }
  end = tmp_0;
  var info = new ScaleRecognizerInfo();
  _this__u8e3s4.jbr_1.w1x(scaleRecognizer$lambda_1(info, start, block, end));
}
function rotationRecognizer(_this__u8e3s4, start, end, block) {
  var tmp;
  if (start === VOID) {
    tmp = rotationRecognizer$lambda;
  } else {
    tmp = start;
  }
  start = tmp;
  var tmp_0;
  if (end === VOID) {
    tmp_0 = rotationRecognizer$lambda_0;
  } else {
    tmp_0 = end;
  }
  end = tmp_0;
  var info = new RotationRecognizerInfo();
  _this__u8e3s4.jbr_1.w1x(rotationRecognizer$lambda_1(info, start, block, end));
}
function scaleRecognizer$lambda(_this__u8e3s4) {
  return Unit_instance;
}
function scaleRecognizer$lambda_0(_this__u8e3s4, it) {
  return Unit_instance;
}
function scaleRecognizer$lambda_1($info, $start, $block, $end) {
  return function (it) {
    var tmp;
    if (it.nbr_1.j1() >= 2) {
      var i0 = it.nbr_1.i1(0);
      var i1 = it.nbr_1.i1(1);
      $info.cbs_1 = $info.dbs_1;
      $info.dbs_1 = false;
      $info.ebs_1 = Companion_getInstance_34().n2w(i0.xbq_1, i1.xbq_1);
      $info.fbs_1 = Companion_getInstance_34().n2w(i0.wbq_1, i1.wbq_1);
      if ($info.cbs_1) {
        $start($info);
      }
      tmp = $block($info, $info.gbs());
    } else {
      var tmp_0;
      if (!$info.dbs_1) {
        $info.dbs_1 = true;
        $info.cbs_1 = false;
        $block($info, $info.gbs());
        tmp_0 = $end($info, $info.gbs());
      }
      tmp = tmp_0;
    }
    return Unit_instance;
  };
}
function rotationRecognizer$lambda(_this__u8e3s4) {
  return Unit_instance;
}
function rotationRecognizer$lambda_0(_this__u8e3s4, it) {
  return Unit_instance;
}
function rotationRecognizer$lambda_1($info, $start, $block, $end) {
  return function (it) {
    var tmp;
    if (it.nbr_1.j1() >= 2) {
      var i0 = it.nbr_1.i1(0);
      var i1 = it.nbr_1.i1(1);
      $info.hbs_1 = $info.ibs_1;
      $info.ibs_1 = false;
      var tmp_0 = $info;
      // Inline function 'korlibs.math.geom.Companion.between' call
      Companion_getInstance_35();
      var p0 = i0.xbq_1;
      var p1 = i1.xbq_1;
      var up = Companion_getInstance_34().e2i_1;
      // Inline function 'korlibs.math.geom.Companion.between' call
      var x0 = p0.i2h_1;
      var y0 = p0.j2h_1;
      var x1 = p1.i2h_1;
      var y1 = p1.j2h_1;
      tmp_0.jbs_1 = Angle_between(x0, y0, x1, y1, up);
      var tmp_1 = $info;
      // Inline function 'korlibs.math.geom.Companion.between' call
      Companion_getInstance_35();
      var p0_0 = i0.wbq_1;
      var p1_0 = i1.wbq_1;
      var up_0 = Companion_getInstance_34().e2i_1;
      // Inline function 'korlibs.math.geom.Companion.between' call
      var x0_0 = p0_0.i2h_1;
      var y0_0 = p0_0.j2h_1;
      var x1_0 = p1_0.i2h_1;
      var y1_0 = p1_0.j2h_1;
      tmp_1.kbs_1 = Angle_between(x0_0, y0_0, x1_0, y1_0, up_0);
      if ($info.hbs_1) {
        $start($info);
      }
      tmp = $block($info, new Angle($info.lbs()));
    } else {
      var tmp_2;
      if (!$info.ibs_1) {
        $info.ibs_1 = true;
        $block($info, new Angle($info.lbs()));
        tmp_2 = $end($info, new Angle($info.lbs()));
      }
      tmp = tmp_2;
    }
    return Unit_instance;
  };
}
function materialRoundRect(_this__u8e3s4, x, y, width, height, color, radius, shadowOffset, shadowColor, shadowRadius, highlightPos, highlightRadius, highlightColor, borderSize, borderColor) {
  color = color === VOID ? Colors_getInstance().i4r_1 : color;
  radius = radius === VOID ? Companion_getInstance_36().t2s_1 : radius;
  shadowOffset = shadowOffset === VOID ? Companion_getInstance_34().a2i_1 : shadowOffset;
  shadowColor = shadowColor === VOID ? Colors_getInstance().h4r_1 : shadowColor;
  shadowRadius = shadowRadius === VOID ? 0.0 : shadowRadius;
  highlightPos = highlightPos === VOID ? Companion_getInstance_34().a2i_1 : highlightPos;
  highlightRadius = highlightRadius === VOID ? 0.0 : highlightRadius;
  highlightColor = highlightColor === VOID ? Colors_getInstance().g4r_1 : highlightColor;
  borderSize = borderSize === VOID ? 0.0 : borderSize;
  borderColor = borderColor === VOID ? Colors_getInstance().g4r_1 : borderColor;
  $l$block: {
    // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
    var this_0 = _this__u8e3s4.d7i().y8r(MaterialBlockUB_getInstance());
    var _unary__edvuaz = this_0.n8t();
    this_0.v9h(_unary__edvuaz + 1 | 0);
    this_0.w9h(this_0.n8t() + 1 | 0);
    var blockSize = this_0.r8r_1;
    var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
    var index1 = imul(this_0.n8t(), blockSize);
    var texBlockSize = this_0.o8r_1.s8z().j1();
    var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
    var texIndex1 = imul(this_0.n8t(), texBlockSize);
    if (this_0.n8t() > 0) {
      arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
    } else {
      arrayfill(this_0.t8r_1, 0, 0, blockSize);
    }
    // Inline function 'korlibs.korge.render.materialRoundRect.<anonymous>' call
    var $this$push = this_0.o8r_1;
    var it = this_0.u8r_1;
    it.l9i($this$push.bbt(), radius);
    it.e9i($this$push.cbt(), Vector2F.b2y(width, height));
    it.j9i($this$push.dbt(), _RGBA___get_premultipliedFast__impl__qiau4g(color));
    it.g9i($this$push.ebt(), times(highlightPos, Size2D.k2t(width, height)));
    var tmp = $this$push.fbt();
    // Inline function 'kotlin.math.max' call
    var tmp$ret$0 = Math.max(width, height);
    it.b9i(tmp, highlightRadius * tmp$ret$0 * 1.25);
    it.j9i($this$push.gbt(), _RGBA___get_premultipliedFast__impl__qiau4g(highlightColor));
    it.c9i($this$push.hbt(), borderSize * 0.5);
    it.j9i($this$push.ibt(), _RGBA___get_premultipliedFast__impl__qiau4g(borderColor));
    it.j9i($this$push.jbt(), _RGBA___get_premultipliedFast__impl__qiau4g(shadowColor));
    it.g9i($this$push.kbt(), shadowOffset);
    it.b9i($this$push.lbt(), shadowRadius);
    if (true && this_0.n8t() >= 1) {
      var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      if (equals) {
        var _unary__edvuaz_0 = this_0.n8t();
        this_0.v9h(_unary__edvuaz_0 - 1 | 0);
        break $l$block;
      }
    }
  }
  _this__u8e3s4.baj(x, y, width, height, MaterialRender_getInstance().xbt_1, Margin.v2l(shadowRadius + shadowOffset.a()));
}
function drawText_0(_this__u8e3s4, placements, textRangeStart, textRangeEnd, filtering) {
  textRangeStart = textRangeStart === VOID ? 0 : textRangeStart;
  textRangeEnd = textRangeEnd === VOID ? 2147483647 : textRangeEnd;
  filtering = filtering === VOID ? _this__u8e3s4.xai_1 : filtering;
  var n = 0;
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n_0 = 0;
  while (n_0 < placements.j1()) {
    // Inline function 'korlibs.korge.render.drawText.<anonymous>' call
    var _unary__edvuaz = n_0;
    n_0 = _unary__edvuaz + 1 | 0;
    var it = placements.i1(_unary__edvuaz);
    var tmp = get_lazyBitmap(it.w6x_1);
    var tmp_0 = it.x6x_1;
    var tmp_1;
    if (tmp_0 == null ? true : tmp_0 instanceof RGBA) {
      var tmp_2 = tmp_0;
      tmp_1 = tmp_2 == null ? null : tmp_2.v59_1;
    } else {
      tmp_1 = null;
    }
    var tmp0_elvis_lhs = tmp_1;
    var tmp_3;
    var tmp_4 = tmp0_elvis_lhs;
    if ((tmp_4 == null ? null : new RGBA(tmp_4)) == null) {
      tmp_3 = Colors_getInstance().g4r_1;
    } else {
      tmp_3 = tmp0_elvis_lhs;
    }
    drawText_1(_this__u8e3s4, it.t6x_1, tmp, it.v6x_1, it.u6x_1, tmp_3, true, textRangeStart - n | 0, textRangeEnd - n | 0, filtering);
    n = n + it.t6x_1.length | 0;
  }
}
var MaterialBlockUB_instance;
function MaterialBlockUB_getInstance() {
  if (MaterialBlockUB_instance === VOID)
    new MaterialBlockUB();
  return MaterialBlockUB_instance;
}
var MaterialRender_instance;
function MaterialRender_getInstance() {
  if (MaterialRender_instance === VOID)
    new MaterialRender();
  return MaterialRender_instance;
}
function drawText_1(_this__u8e3s4, text, font, textSize, pos, color, baseline, textRangeStart, textRangeEnd, filtering) {
  textSize = textSize === VOID ? 16.0 : textSize;
  pos = pos === VOID ? Companion_getInstance_34().a2i_1 : pos;
  color = color === VOID ? Colors_getInstance().g4r_1 : color;
  baseline = baseline === VOID ? false : baseline;
  textRangeStart = textRangeStart === VOID ? 0 : textRangeStart;
  textRangeEnd = textRangeEnd === VOID ? 2147483647 : textRangeEnd;
  filtering = filtering === VOID ? _this__u8e3s4.xai_1 : filtering;
  var scale = font.o5m(textSize);
  var sx = pos.i2h_1;
  var sy = pos.j2h_1 + (baseline ? -font.o33() * scale : 0.0);
  var n = 0;
  var inductionVariable = 0;
  var last = text.length;
  while (inductionVariable < last) {
    var char = charSequenceGet(text, inductionVariable);
    inductionVariable = inductionVariable + 1 | 0;
    var glyph = font.q5m(char);
    if (textRangeStart <= n ? n < textRangeEnd : false) {
      _this__u8e3s4.zai(new Rectangle(sx + glyph.g5k_1 * scale, sy + glyph.h5k_1 * scale, glyph.y5l() * scale, glyph.z5l() * scale), RGBA__times_impl_wehs9n(color, _this__u8e3s4.wai_1), filtering, glyph.f5k_1, get_agProgram(font));
    }
    sx = sx + glyph.i5k_1 * scale;
    n = n + 1 | 0;
  }
}
function u_ShadowColor$factory() {
  return getPropertyCallableRef('u_ShadowColor', 1, KProperty1, function (receiver) {
    return receiver.jbt();
  }, null);
}
function u_ShadowRadius$factory() {
  return getPropertyCallableRef('u_ShadowRadius', 1, KProperty1, function (receiver) {
    return receiver.lbt();
  }, null);
}
function u_ShadowOffset$factory() {
  return getPropertyCallableRef('u_ShadowOffset', 1, KProperty1, function (receiver) {
    return receiver.kbt();
  }, null);
}
function u_HighlightPos$factory() {
  return getPropertyCallableRef('u_HighlightPos', 1, KProperty1, function (receiver) {
    return receiver.ebt();
  }, null);
}
function u_HighlightRadius$factory() {
  return getPropertyCallableRef('u_HighlightRadius', 1, KProperty1, function (receiver) {
    return receiver.fbt();
  }, null);
}
function u_HighlightColor$factory() {
  return getPropertyCallableRef('u_HighlightColor', 1, KProperty1, function (receiver) {
    return receiver.gbt();
  }, null);
}
function u_Size$factory() {
  return getPropertyCallableRef('u_Size', 1, KProperty1, function (receiver) {
    return receiver.cbt();
  }, null);
}
function u_Radius$factory() {
  return getPropertyCallableRef('u_Radius', 1, KProperty1, function (receiver) {
    return receiver.bbt();
  }, null);
}
function u_BorderSizeHalf$factory() {
  return getPropertyCallableRef('u_BorderSizeHalf', 1, KProperty1, function (receiver) {
    return receiver.hbt();
  }, null);
}
function u_BorderColor$factory() {
  return getPropertyCallableRef('u_BorderColor', 1, KProperty1, function (receiver) {
    return receiver.ibt();
  }, null);
}
function u_BackgroundColor$factory() {
  return getPropertyCallableRef('u_BackgroundColor', 1, KProperty1, function (receiver) {
    return receiver.dbt();
  }, null);
}
function u_ShadowColor$factory_0() {
  return getPropertyCallableRef('u_ShadowColor', 1, KProperty1, function (receiver) {
    return receiver.jbt();
  }, null);
}
function u_ShadowRadius$factory_0() {
  return getPropertyCallableRef('u_ShadowRadius', 1, KProperty1, function (receiver) {
    return receiver.lbt();
  }, null);
}
function u_ShadowOffset$factory_0() {
  return getPropertyCallableRef('u_ShadowOffset', 1, KProperty1, function (receiver) {
    return receiver.kbt();
  }, null);
}
function u_HighlightPos$factory_0() {
  return getPropertyCallableRef('u_HighlightPos', 1, KProperty1, function (receiver) {
    return receiver.ebt();
  }, null);
}
function u_HighlightRadius$factory_0() {
  return getPropertyCallableRef('u_HighlightRadius', 1, KProperty1, function (receiver) {
    return receiver.fbt();
  }, null);
}
function u_HighlightColor$factory_0() {
  return getPropertyCallableRef('u_HighlightColor', 1, KProperty1, function (receiver) {
    return receiver.gbt();
  }, null);
}
function u_Size$factory_0() {
  return getPropertyCallableRef('u_Size', 1, KProperty1, function (receiver) {
    return receiver.cbt();
  }, null);
}
function u_Radius$factory_0() {
  return getPropertyCallableRef('u_Radius', 1, KProperty1, function (receiver) {
    return receiver.bbt();
  }, null);
}
function u_BorderSizeHalf$factory_0() {
  return getPropertyCallableRef('u_BorderSizeHalf', 1, KProperty1, function (receiver) {
    return receiver.hbt();
  }, null);
}
function u_BorderColor$factory_0() {
  return getPropertyCallableRef('u_BorderColor', 1, KProperty1, function (receiver) {
    return receiver.ibt();
  }, null);
}
function u_BackgroundColor$factory_0() {
  return getPropertyCallableRef('u_BackgroundColor', 1, KProperty1, function (receiver) {
    return receiver.dbt();
  }, null);
}
function set__debugAnnotateView(_this__u8e3s4, _set____db54di) {
  _init_properties_RenderContextExt_kt__49d41k();
  var this_0 = _debugAnnotateView$delegate;
  var property = _debugAnnotateView$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get__debugAnnotateView(_this__u8e3s4) {
  _init_properties_RenderContextExt_kt__49d41k();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = _debugAnnotateView$delegate;
    var property = _debugAnnotateView$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var _debugAnnotateView$delegate;
function get_debugAnnotateView(_this__u8e3s4) {
  _init_properties_RenderContextExt_kt__49d41k();
  return get__debugAnnotateView(_this__u8e3s4);
}
function get_views(_this__u8e3s4) {
  _init_properties_RenderContextExt_kt__49d41k();
  var tmp = _this__u8e3s4.na6_1;
  return tmp instanceof Views ? tmp : null;
}
function _debugAnnotateView$delegate$lambda() {
  _init_properties_RenderContextExt_kt__49d41k();
  return null;
}
function _debugAnnotateView$factory() {
  return getPropertyCallableRef('_debugAnnotateView', 1, KMutableProperty1, function (receiver) {
    return get__debugAnnotateView(receiver);
  }, function (receiver, value) {
    return set__debugAnnotateView(receiver, value);
  });
}
function _debugAnnotateView$factory_0() {
  return getPropertyCallableRef('_debugAnnotateView', 1, KMutableProperty1, function (receiver) {
    return get__debugAnnotateView(receiver);
  }, function (receiver, value) {
    return set__debugAnnotateView(receiver, value);
  });
}
var properties_initialized_RenderContextExt_kt_9hx4ly;
function _init_properties_RenderContextExt_kt__49d41k() {
  if (!properties_initialized_RenderContextExt_kt_9hx4ly) {
    properties_initialized_RenderContextExt_kt_9hx4ly = true;
    // Inline function 'korlibs.datastructure.extraProperty' call
    var default_0 = _debugAnnotateView$delegate$lambda;
    _debugAnnotateView$delegate = new Property(null, default_0);
  }
}
function *_generator_sceneAfterDestroyInternal__rup219($this, $completion) {
  var tmp = $this.ibu($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  try {
    cancel($this.c15());
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      if (e instanceof CancellationException)
        throw e;
      printStackTrace(e);
    } else {
      throw $p;
    }
  }
  return Unit_instance;
}
function Scene$coroutineContext$delegate$lambda(this$0) {
  return function () {
    return this$0.vbk().yb6_1.xn(new InjectorContext(this$0.jbu())).xn(Job(this$0.vbk().yb6_1.td(Key_instance)));
  };
}
function Scene$sceneView$delegate$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.apply' call
    var this_0 = this$0.lbu(this$0.kbu().j1());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.scene.Scene.sceneView$delegate.<anonymous>.<anonymous>' call
    this$0.dbu_1.ubw(this_0);
    return this_0;
  };
}
function Scene$resources$delegate$lambda(this$0) {
  return function () {
    // Inline function 'korlibs.inject.Injector.getSync' call
    return this$0.jbu().x87(getKClass(Resources));
  };
}
function onSizeChanged($this, sceneView, size_0) {
  var width = size_0.km();
  var height = size_0.lm();
  var out = place_0(new Rectangle(0.0, 0.0, width, height), Size2D.f2j($this.sc0(), $this.tc0()), $this.qc0_1, $this.pc0_1);
  // Inline function 'kotlin.also' call
  // Inline function 'korlibs.korge.view.size' call
  var width_0 = $this.sc0();
  var height_0 = $this.tc0();
  var tmp$ret$0 = size(sceneView, numberToDouble(width_0), numberToDouble(height_0));
  var this_0 = scale(xy_0(tmp$ret$0, out.i2i_1, out.j2i_1), out.k2i_1 / $this.sc0(), out.l2i_1 / $this.tc0());
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.ScaledScene.onSizeChanged.<anonymous>' call
  set_filter(this_0, $this.rc0_1 ? Linear_getInstance() : Nearest_getInstance());
}
function coroutineContext$factory() {
  return getPropertyCallableRef('coroutineContext', 1, KProperty1, function (receiver) {
    return receiver.c15();
  }, null);
}
function sceneView$factory() {
  return getPropertyCallableRef('sceneView', 1, KProperty1, function (receiver) {
    return receiver.ybw();
  }, null);
}
function *_generator_invoke__zhh2q8_15($this, $completion) {
  var scene = $this.uc0_1.ibe_1;
  if (!(scene == null)) {
    println('[ReloadEvent] Reloading ' + toString_0($this.uc0_1.ibe_1) + ' . doFullReload=' + $this.vc0_1.cc1());
    var tmp;
    if ($this.vc0_1.cc1()) {
      tmp = $this.vc0_1.dc1(getKClassFromExpression(scene), scene.jbu());
    } else {
      tmp = getKClassFromExpression(scene);
    }
    var tmp_0 = tmp;
    var sceneClass = isInterface(tmp_0, KClass) ? tmp_0 : THROW_CCE();
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
    var this_0 = $this.uc0_1;
    var injects = [];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var time = toDuration(0, DurationUnit_SECONDS_getInstance());
    var transition = this_0.gbe_1;
    var sceneInjector = this_0.fbe_1.ab7_1.q87().o87(getKClass(SceneContainer), this_0).o87(getKClass(Resources), new Resources(this_0.c15(), this_0.fbe_1.hbf().d3o_1, this_0.fbe_1.hbf()));
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < injects.length) {
      // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var inject = injects[_unary__edvuaz];
      var tmp_1 = getKClassFromExpression(inject);
      sceneInjector.o87(isInterface(tmp_1, KClass) ? tmp_1 : THROW_CCE(), inject);
    }
    // Inline function 'korlibs.korge.scene.SceneContainer.<anonymous>.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.also' call
    var this_1 = sceneInjector.x87(sceneClass);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.scene.SceneContainer.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    try {
      $this.vc0_1.ja5(scene, this_1);
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        printStackTrace(e);
      } else {
        throw $p;
      }
    }
    var newScene = this_1;
    // Inline function 'korlibs.logger.Logger.info' call
    var this_2 = Companion_getInstance_52().ec1_1;
    var level = Level_INFO_getInstance();
    var tmp_2;
    if (this_2.u3g(level)) {
      // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
      var tmp$ret$4 = 'Changing scene to... ' + toString(sceneClass) + ' ... ' + toString(newScene);
      this_2.v3g(level, tmp$ret$4);
      tmp_2 = Unit_instance;
    }
    if (false) {
      newScene.z87(sceneInjector);
      var tmp_3 = getKClassFromExpression(newScene);
      var tmp_4 = isInterface(tmp_3, KClass) ? tmp_3 : THROW_CCE();
      this_0.fbe_1.ab7_1.s87(tmp_4, SceneContainer$lambda$slambda$lambda(sceneInjector, sceneClass, $this.vc0_1, scene));
    }
    var tmp_5 = this_0.gc1(newScene, time, transition, $completion);
    if (tmp_5 === get_COROUTINE_SUSPENDED())
      tmp_5 = yield tmp_5;
    var scene_0 = tmp_5;
    scene_0.vbk().xbp(scene_0.ybw());
  }
  return Unit_instance;
}
function SceneContainer$lambda$slambda$lambda($sceneInjector, $sceneClass, $event, $scene) {
  return function ($this$mapPrototype) {
    // Inline function 'korlibs.korge.scene.SceneContainer.<anonymous>.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.also' call
    var this_0 = $sceneInjector.x87($sceneClass);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.scene.SceneContainer.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    try {
      $event.ja5($scene, this_0);
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        printStackTrace(e);
      } else {
        throw $p;
      }
    }
    return this_0;
  };
}
function *_generator_invoke__zhh2q8_16($this, $completion) {
  // Inline function 'kotlin.apply' call
  var this_0 = $this.hc1_1.ybw();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.SceneContainer._changeTo.<anonymous>.<anonymous>' call
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.SceneContainer._changeTo.<anonymous>.<anonymous>.<anonymous>' call
  var tmp = $this.hc1_1.lbz(this_0, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return this_0;
}
function *_generator_invoke__zhh2q8_17($this, $completion) {
  // Inline function 'kotlin.apply' call
  var this_0 = $this.ic1_1.ybw();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.SceneContainer._changeTo.<anonymous>.<anonymous>' call
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.SceneContainer._changeTo.<anonymous>.<anonymous>.<anonymous>' call
  var tmp = $this.ic1_1.mbz(this_0, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_18($this, $completion) {
  var tmp = $this.jc1_1.obz($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_19($this, $completion) {
  var tmp = $this.kc1_1.pbz($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = $this.kc1_1.qbz($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_20($this, $completion) {
  var tmp = $this.lc1_1.rbz($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_21($this, $completion) {
  var tmp = $this.mc1_1.nbz($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator__changeTo__ueovgq($this, clazz, injects, time, transition, $completion) {
  // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
  var injects_0 = injects.slice();
  var sceneInjector = $this.fbe_1.ab7_1.q87().o87(getKClass(SceneContainer), $this).o87(getKClass(Resources), new Resources($this.c15(), $this.fbe_1.hbf().d3o_1, $this.fbe_1.hbf()));
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n = 0;
  while (n < injects_0.length) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var inject = injects_0[_unary__edvuaz];
    var tmp = getKClassFromExpression(inject);
    sceneInjector.o87(isInterface(tmp, KClass) ? tmp : THROW_CCE(), inject);
  }
  // Inline function 'korlibs.korge.scene.SceneContainer._changeTo.<anonymous>' call
  var newScene = sceneInjector.x87(clazz);
  // Inline function 'korlibs.logger.Logger.info' call
  var this_0 = Companion_getInstance_52().ec1_1;
  var level = Level_INFO_getInstance();
  var tmp_0;
  if (this_0.u3g(level)) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var tmp$ret$3 = 'Changing scene to... ' + toString(clazz) + ' ... ' + toString(newScene);
    this_0.v3g(level, tmp$ret$3);
    tmp_0 = Unit_instance;
  }
  if (false) {
    newScene.z87(sceneInjector);
    var tmp_1 = getKClassFromExpression(newScene);
    var tmp_2 = isInterface(tmp_1, KClass) ? tmp_1 : THROW_CCE();
    $this.fbe_1.ab7_1.s87(tmp_2, SceneContainer$_changeTo$lambda(sceneInjector, clazz));
  }
  var tmp_3 = $this.gc1(newScene, time, transition, $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  return tmp_3;
}
function _changeTo($this, clazz, injects, time, transition, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator__changeTo__ueovgq($this, clazz, injects, time, transition, $completion), $completion);
}
function *_generator__changeTo__ueovgq_0($this, newScene, time, transition, $completion) {
  var oldScene = $this.ibe_1;
  $this.ibe_1 = newScene;
  $this.hbe_1.yc3(newScene.dbu_1, transition);
  try {
    var tmp = newScene.c15();
    var tmp_0 = launchUnscopedAndWait(tmp, SceneContainer$_changeTo$slambda_5(newScene), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      if (e instanceof CancellationException)
        throw e;
      printStackTrace(e);
    } else {
      throw $p;
    }
  }
  launchUnscoped(newScene, SceneContainer$_changeTo$slambda_6(newScene));
  if (!(oldScene == null)) {
    var tmp_1 = oldScene.c15();
    var tmp_2 = launchUnscopedAndWait(tmp_1, SceneContainer$_changeTo$slambda_7(oldScene), $completion);
    if (tmp_2 === get_COROUTINE_SUSPENDED())
      tmp_2 = yield tmp_2;
  }
  // Inline function 'korlibs.time.seconds' call
  // Inline function 'korlibs.time.seconds' call
  var tmp$ret$3 = toDuration(0, DurationUnit_SECONDS_getInstance());
  if (Duration__compareTo_impl_pchp0f(time, tmp$ret$3) > 0) {
    // Inline function 'korlibs.korge.tween.get' call
    var this_0 = ratio$factory($this.hbe_1);
    var initial = Companion_getInstance_45().f10_1;
    var end = Companion_getInstance_45().i10_1;
    var tmp$ret$4 = new V2(this_0, new Ratio(initial), new Ratio(end), _interpolateRatio$ref(), true);
    var tmp_3 = tween_0($this.hbe_1, [tmp$ret$4], time, VOID, VOID, VOID, VOID, VOID, $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
  } else {
    $this.hbe_1.vc3_1 = Companion_getInstance_45().i10_1;
  }
  $this.hbe_1.zc3();
  if (!(oldScene == null)) {
    var tmp_4 = oldScene.c15();
    var tmp_5 = launchUnscopedAndWait(tmp_4, SceneContainer$_changeTo$slambda_8(oldScene), $completion);
    if (tmp_5 === get_COROUTINE_SUSPENDED())
      tmp_5 = yield tmp_5;
    launchUnscoped(oldScene, SceneContainer$_changeTo$slambda_9(oldScene));
  }
  var tmp_6 = newScene.c15();
  launchUnscoped_0(tmp_6, SceneContainer$_changeTo$slambda_10(newScene));
  return newScene;
}
var Companion_instance_19;
function Companion_getInstance_52() {
  if (Companion_instance_19 === VOID)
    new Companion_4();
  return Companion_instance_19;
}
function SceneContainer$lambda$lambda(this$0) {
  return function (it) {
    var tmp;
    if (it instanceof SceneContainer) {
      tmp = !equals(it, this$0);
    } else {
      tmp = false;
    }
    return tmp;
  };
}
function SceneContainer$lambda$slambda_0(this$0, $event) {
  var i = new SceneContainer$lambda$slambda(this$0, $event);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SceneContainer$lambda(this$0) {
  return function (event) {
    // Inline function 'kotlin.collections.isNotEmpty' call
    var hasChildScenes = !descendantsWith(this$0, VOID, SceneContainer$lambda$lambda(this$0)).a1();
    var tmp;
    if (hasChildScenes) {
      println('[ReloadEvent] Scene ' + toString_0(this$0.ibe_1) + ' not reloaded because has child scenes...');
      return Unit_instance;
    }
    launchImmediately(this$0, SceneContainer$lambda$slambda_0(this$0, event));
    return Unit_instance;
  };
}
function SceneContainer$_changeTo$lambda($sceneInjector, $clazz) {
  return function ($this$mapPrototype) {
    // Inline function 'korlibs.korge.scene.SceneContainer._changeTo.<anonymous>' call
    return $sceneInjector.x87($clazz);
  };
}
function SceneContainer$_changeTo$slambda_5($newScene) {
  var i = new SceneContainer$_changeTo$slambda($newScene);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SceneContainer$_changeTo$slambda_6($newScene) {
  var i = new SceneContainer$_changeTo$slambda_0($newScene);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SceneContainer$_changeTo$slambda_7($oldScene) {
  var i = new SceneContainer$_changeTo$slambda_1($oldScene);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function _interpolateRatio$ref() {
  var l = function (p0, p1, p2) {
    return new Ratio(_interpolateRatio(p0.e10_1, p1.e10_1, p2.e10_1));
  };
  l.callableName = '_interpolateRatio';
  return l;
}
function SceneContainer$_changeTo$slambda_8($oldScene) {
  var i = new SceneContainer$_changeTo$slambda_2($oldScene);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SceneContainer$_changeTo$slambda_9($oldScene) {
  var i = new SceneContainer$_changeTo$slambda_3($oldScene);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SceneContainer$_changeTo$slambda_10($newScene) {
  var i = new SceneContainer$_changeTo$slambda_4($newScene);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function ratio$factory($b0) {
  return getPropertyCallableRef('ratio', 0, KMutableProperty0, function () {
    return new Ratio($b0.vc3_1);
  }, function (value) {
    $b0.vc3_1 = value.e10_1;
    return Unit_instance;
  });
}
function get_AlphaTransition() {
  _init_properties_Transition_kt__s6zoln();
  return AlphaTransition;
}
var AlphaTransition;
function Transition_0(name, render) {
  name = name === VOID ? 'Transition' : name;
  _init_properties_Transition_kt__s6zoln();
  return new Transition$1(render, name);
}
function withEasing(_this__u8e3s4, easing) {
  _init_properties_Transition_kt__s6zoln();
  return new withEasing$1(_this__u8e3s4, easing);
}
function _get_transitionProcess__5hlux5($this) {
  var tmp = $this.xc3_1;
  if (!(tmp == null))
    return tmp;
  else {
    throwUninitializedPropertyAccessException('transitionProcess');
  }
}
function AlphaTransition$lambda(ctx, prev, next, ratio) {
  _init_properties_Transition_kt__s6zoln();
  var prevAlpha = prev.tbh();
  var nextAlpha = next.tbh();
  try {
    prev.ubh(1.0 - Ratio__toFloat_impl_1ftup5(ratio.e10_1));
    next.ubh(Ratio__toFloat_impl_1ftup5(ratio.e10_1));
    prev.fc7(ctx);
    next.fc7(ctx);
  }finally {
    prev.ubh(prevAlpha);
    next.ubh(nextAlpha);
  }
  return Unit_instance;
}
var properties_initialized_Transition_kt_wb2qvd;
function _init_properties_Transition_kt__s6zoln() {
  if (!properties_initialized_Transition_kt_wb2qvd) {
    properties_initialized_Transition_kt_wb2qvd = true;
    AlphaTransition = Transition_0('AlphaTransition', AlphaTransition$lambda);
  }
}
function get_styles(_this__u8e3s4) {
  _init_properties_ViewStyles_kt__hdc4op();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = styles$delegate;
    var property = styles$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var styles$delegate;
function set_textFont(_this__u8e3s4, _set____db54di) {
  _init_properties_ViewStyles_kt__hdc4op();
  textFont$delegate;
  var property = textFont$factory();
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, _set____db54di);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_textFont(_this__u8e3s4) {
  _init_properties_ViewStyles_kt__hdc4op();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = textFont$delegate;
  var property = textFont$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0;
}
var textFont$delegate;
function set_textSize(_this__u8e3s4, _set____db54di) {
  _init_properties_ViewStyles_kt__hdc4op();
  textSize$delegate;
  var property = textSize$factory();
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, _set____db54di);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_textSize(_this__u8e3s4) {
  _init_properties_ViewStyles_kt__hdc4op();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = textSize$delegate;
  var property = textSize$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0;
}
var textSize$delegate;
function set_textColor(_this__u8e3s4, _set____db54di) {
  _init_properties_ViewStyles_kt__hdc4op();
  textColor$delegate;
  var property = textColor$factory();
  var value = new RGBA(_set____db54di);
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, value);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_textColor(_this__u8e3s4) {
  _init_properties_ViewStyles_kt__hdc4op();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = textColor$delegate;
  var property = textColor$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0.v59_1;
}
var textColor$delegate;
var buttonBackColor$delegate;
function set_textAlignment(_this__u8e3s4, _set____db54di) {
  _init_properties_ViewStyles_kt__hdc4op();
  textAlignment$delegate;
  var property = textAlignment$factory();
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, _set____db54di);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_textAlignment(_this__u8e3s4) {
  _init_properties_ViewStyles_kt__hdc4op();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = textAlignment$delegate;
  var property = textAlignment$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0;
}
var textAlignment$delegate;
function styles$delegate$lambda($this$PropertyThis) {
  _init_properties_ViewStyles_kt__hdc4op();
  return new ViewStyles($this$PropertyThis);
}
function styles$factory() {
  return getPropertyCallableRef('styles', 1, KProperty1, function (receiver) {
    return get_styles(receiver);
  }, null);
}
function textFont$factory() {
  return getPropertyCallableRef('textFont', 1, KMutableProperty1, function (receiver) {
    return get_textFont(receiver);
  }, function (receiver, value) {
    return set_textFont(receiver, value);
  });
}
function textFont$factory_0() {
  return getPropertyCallableRef('textFont', 1, KMutableProperty1, function (receiver) {
    return get_textFont(receiver);
  }, function (receiver, value) {
    return set_textFont(receiver, value);
  });
}
function textSize$factory() {
  return getPropertyCallableRef('textSize', 1, KMutableProperty1, function (receiver) {
    return get_textSize(receiver);
  }, function (receiver, value) {
    return set_textSize(receiver, value);
  });
}
function textSize$factory_0() {
  return getPropertyCallableRef('textSize', 1, KMutableProperty1, function (receiver) {
    return get_textSize(receiver);
  }, function (receiver, value) {
    return set_textSize(receiver, value);
  });
}
function textColor$factory() {
  return getPropertyCallableRef('textColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_textColor(receiver));
  }, function (receiver, value) {
    return set_textColor(receiver, value.v59_1);
  });
}
function textColor$factory_0() {
  return getPropertyCallableRef('textColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_textColor(receiver));
  }, function (receiver, value) {
    return set_textColor(receiver, value.v59_1);
  });
}
function textAlignment$factory() {
  return getPropertyCallableRef('textAlignment', 1, KMutableProperty1, function (receiver) {
    return get_textAlignment(receiver);
  }, function (receiver, value) {
    return set_textAlignment(receiver, value);
  });
}
function textAlignment$factory_0() {
  return getPropertyCallableRef('textAlignment', 1, KMutableProperty1, function (receiver) {
    return get_textAlignment(receiver);
  }, function (receiver, value) {
    return set_textAlignment(receiver, value);
  });
}
var properties_initialized_ViewStyles_kt_405jyh;
function _init_properties_ViewStyles_kt__hdc4op() {
  if (!properties_initialized_ViewStyles_kt_405jyh) {
    properties_initialized_ViewStyles_kt_405jyh = true;
    styles$delegate = new PropertyThis(VOID, styles$delegate$lambda);
    textFont$delegate = new ViewStyle(get_DefaultTtfFontAsBitmap());
    textSize$delegate = new ViewStyle(16.0);
    textColor$delegate = new ViewStyle(new RGBA(Colors_getInstance().g4r_1));
    buttonBackColor$delegate = new ViewStyle(new RGBA(Colors_getInstance().j4s_1));
    textAlignment$delegate = new ViewStyle(Companion_getInstance_4().h5s_1);
  }
}
function createProgram$_anonymous_$lambda$0_hhid5m($median$delegate) {
  return $median$delegate.ko(null, getLocalDelegateReference('median', KProperty0, false, function () {
    return THROW_ISE();
  }));
}
function MsdfRender$createProgram$lambda($this$FUNC, a, b, c) {
  $this$FUNC.s93($this$FUNC.m94($this$FUNC.v9p(a, b), $this$FUNC.v9p($this$FUNC.m94(a, b), c)));
  return Unit_instance;
}
function MsdfRender$createProgram$lambda_0($$this$buildShader) {
  return function ($this$IF_ELSE_BINARY_LOOKUP, n) {
    $this$IF_ELSE_BINARY_LOOKUP.f92($this$IF_ELSE_BINARY_LOOKUP.i92(), $this$IF_ELSE_BINARY_LOOKUP.q92(Companion_getInstance_8().pad_1[n], $this$IF_ELSE_BINARY_LOOKUP.o92($$this$buildShader.b92(), 'xy')));
    return Unit_instance;
  };
}
var MsdfRender_instance;
function MsdfRender_getInstance() {
  if (MsdfRender_instance === VOID)
    new MsdfRender();
  return MsdfRender_instance;
}
function *_generator_invoke__zhh2q8_22($this, it, $completion) {
  if (!get_focused($this.zce_1))
    return Unit_instance;
  switch (it.f8c_1.v3_1) {
    case 25:
    case 44:
    case 48:
    case 23:
    case 46:
      if ($this.zce_1.fcg(it)) {
        switch (it.f8c_1.v3_1) {
          case 48:
            if (it.i8c_1) {
              $this.zce_1.ycf();
            } else {
              $this.zce_1.xcf();
            }

            break;
          case 25:
          case 46:
            // Inline function 'kotlin.text.isNotEmpty' call

            var this_0 = $this.zce_1.zcf();
            if (charSequenceLength(this_0) > 0) {
              var tmp = _get_gameWindow__b33wdz($this.zce_1).bas(new TextClipboardData($this.zce_1.zcf()), $completion);
              if (tmp === get_COROUTINE_SUSPENDED())
                tmp = yield tmp;
            }

            if (it.f8c_1.equals(Key_X_getInstance())) {
              var selection = $this.zce_1.acg();
              $this.zce_1.bcg(withoutRange($this.zce_1.o2f(), $this.zce_1.acg()));
              $this.zce_1.ccg(false, selection.k2_1);
            }

            break;
          case 44:
            var tmp_0 = _get_gameWindow__b33wdz($this.zce_1).cas($completion);
            if (tmp_0 === get_COROUTINE_SUSPENDED())
              tmp_0 = yield tmp_0;
            var tmp_1 = tmp_0;
            var tmp104_safe_receiver = (tmp_1 == null ? true : tmp_1 instanceof TextClipboardData) ? tmp_1 : null;
            var rtext = tmp104_safe_receiver == null ? null : tmp104_safe_receiver.oas_1;
            if (!(rtext == null)) {
              $this.zce_1.dcg(rtext);
            }

            break;
          case 23:
            $this.zce_1.ecg();
            break;
          default:
            break;
        }
      }

      break;
    case 59:
    case 61:
      var range = $this.zce_1.acg();
      if (get_length(range) > 0) {
        $this.zce_1.bcg(withoutRange($this.zce_1.o2f(), range));
        $this.zce_1.hcg(range.k2_1);
      } else {
        if (it.f8c_1.equals(Key_BACKSPACE_getInstance())) {
          if ($this.zce_1.gcg() > 0) {
            var oldCursorIndex = $this.zce_1.gcg();
            $this.zce_1.bcg(withoutIndex($this.zce_1.o2f(), $this.zce_1.gcg() - 1 | 0));
            $this.zce_1.hcg(oldCursorIndex - 1 | 0);
          }
        } else {
          if ($this.zce_1.gcg() < $this.zce_1.o2f().length) {
            $this.zce_1.bcg(withoutIndex($this.zce_1.o2f(), $this.zce_1.gcg()));
          }
        }
      }

      break;
    case 63:
      if ($this.zce_1.lcg(it)) {
        $this.zce_1.ccg(it.i8c_1, 0);
      } else {
        $this.zce_1.ccg(it.i8c_1, $this.zce_1.kcg($this.zce_1.icg(), $this.zce_1.jcg(it)));
      }

      break;
    case 62:
      if ($this.zce_1.lcg(it)) {
        $this.zce_1.ccg(it.i8c_1, $this.zce_1.o2f().length);
      } else {
        $this.zce_1.ccg(it.i8c_1, $this.zce_1.mcg($this.zce_1.icg(), $this.zce_1.jcg(it)));
      }

      break;
    case 74:
      $this.zce_1.ccg(it.i8c_1, 0);
      break;
    case 75:
      $this.zce_1.ccg(it.i8c_1, $this.zce_1.o2f().length);
      break;
    default:
      break;
  }
  return Unit_instance;
}
function setTextNoSnapshot($this, text, out) {
  if (!$this.wcf_1($this.bcf_1.o2f(), text))
    return null;
  out.ncg_1 = $this.bcf_1.o2f();
  out.ocg_1 = $this.acg();
  $this.bcf_1.bcg(text);
  reclampSelection($this);
  $this.jcf_1.x1x($this);
  return out;
}
function setTextNoSnapshot$default($this, text, out, $super) {
  out = out === VOID ? new TextSnapshot('', numberRangeToNumber(0, 0)) : out;
  return setTextNoSnapshot($this, text, out);
}
function clampIndex($this, index) {
  return clamp(index, 0, $this.o2f().length);
}
function reclampSelection($this) {
  $this.nck($this.icg(), $this.mck());
  $this.ock($this.icg());
}
function _get_gameWindow__b33wdz($this) {
  return ensureNotNull($this.bcf_1.pbj()).vbk().eb7_1;
}
function TextEditController$lambda($this$onNewAttachDetach, it) {
  get_uiFocusManager($this$onNewAttachDetach.kb8_1);
  return Unit_instance;
}
function TextEditController$acceptTextChange$lambda(old, new_0) {
  return true;
}
function TextEditController$lambda_0(this$0) {
  return function () {
    var tmp;
    if (!get_focused(this$0)) {
      this$0.pcf_1.car(false);
      tmp = Unit_instance;
    } else {
      var tmp_0;
      if (this$0.pck() === 0) {
        this$0.pcf_1.car(!this$0.pcf_1.qcc());
        tmp_0 = Unit_instance;
      } else {
        this$0.pcf_1.car(true);
        tmp_0 = Unit_instance;
      }
      tmp = tmp_0;
    }
    return Unit_instance;
  };
}
function TextEditController$slambda_1(this$0) {
  var i = new TextEditController$slambda(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function TextEditController$slambda_2(this$0) {
  var i = new TextEditController$slambda_0(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function TextEditController$lambda_1(this$0) {
  return function (it) {
    this$0.mcf_1.x1x(this$0);
    var tmp106_safe_receiver = this$0.ecf_1;
    if (tmp106_safe_receiver == null)
      null;
    else {
      tmp106_safe_receiver.ccn_1 = true;
    }
    return Unit_instance;
  };
}
function TextEditController$lambda_2(this$0) {
  return function (it) {
    this$0.ncf_1.x1x(this$0);
    var tmp107_safe_receiver = this$0.ecf_1;
    if (tmp107_safe_receiver == null)
      null;
    else {
      tmp107_safe_receiver.ccn_1 = false;
    }
    return Unit_instance;
  };
}
function TextEditController$lambda_3(this$0, $dragging) {
  return function (it) {
    this$0.hcg(this$0.ecn(it.ibq()));
    $dragging._v = false;
    set_focused(this$0, true);
    return Unit_instance;
  };
}
function TextEditController$lambda_4(this$0, $dragging) {
  return function (it) {
    this$0.hcg(this$0.ecn(it.ibq()));
    $dragging._v = false;
    return Unit_instance;
  };
}
function TextEditController$lambda_5($dragging, this$0) {
  return function (it) {
    $dragging._v = false;
    var tmp;
    if (get_focused(this$0)) {
      set_focused(this$0, false);
      blur(this$0);
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function TextEditController$lambda_6(this$0, $dragging) {
  return function (it) {
    var tmp;
    if (get_focused(this$0) && it.pbq()) {
      $dragging._v = true;
      this$0.fcn(this$0.ecn(it.ibq()));
      it.lbq();
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function TextEditController$lambda_7($dragging, this$0) {
  return function (it) {
    var tmp;
    if (!$dragging._v && get_focused(this$0)) {
      blur(this$0);
      tmp = Unit_instance;
    }
    $dragging._v = false;
    return Unit_instance;
  };
}
function TextEditController$lambda_8(this$0) {
  return function (it) {
    var index = this$0.ecn(it.ibq());
    this$0.nck(this$0.kcg(index, true), this$0.mcg(index, true));
    return Unit_instance;
  };
}
function color$factory($b0) {
  return getPropertyCallableRef('color', 0, KMutableProperty0, function () {
    return new RGBA($b0.j6b());
  }, function (value) {
    return $b0.lcq(value.v59_1);
  });
}
function get_timers(_this__u8e3s4) {
  _init_properties_TimerComponents_kt__j84oeh();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = timers$delegate;
    var property = timers$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var timers$delegate;
function ensureUpdater($this) {
  if (!($this.rcp_1 == null))
    return Unit_instance;
  var tmp = $this;
  tmp.rcp_1 = addFastUpdater_0($this.ncp_1, TimerComponents$ensureUpdater$lambda($this));
}
function addTimer($this, autoRemove, callback) {
  if ($this.qcp_1.y2k()) {
    var index = $this.qcp_1.q3($this.qcp_1.j1() - 1 | 0);
    $this.ocp_1.p3(index, callback);
    // Inline function 'korlibs.math.toInt' call
    var tmp$ret$0 = autoRemove ? 1 : 0;
    $this.pcp_1.t31(index, tmp$ret$0);
    ensureUpdater($this);
    return _TimerRef___init__impl__cysrar(index);
  }
  // Inline function 'kotlin.collections.plusAssign' call
  $this.ocp_1.f(callback);
  // Inline function 'korlibs.math.toInt' call
  var tmp$ret$1 = autoRemove ? 1 : 0;
  $this.pcp_1.l31(tmp$ret$1);
  ensureUpdater($this);
  return _TimerRef___init__impl__cysrar($this.ocp_1.j1() - 1 | 0);
}
function removeTimer($this, ref) {
  $this.ocp_1.p3(_TimerRef___get_index__impl__pnrx4q(ref), null);
  $this.pcp_1.t31(_TimerRef___get_index__impl__pnrx4q(ref), 0);
  $this.qcp_1.l31(_TimerRef___get_index__impl__pnrx4q(ref));
}
function _interval($this, time, repeat, callback) {
  var elapsed = {_v: new FastDuration(get_fastMilliseconds_0(0))};
  var ref = {_v: new TimerRef(_TimerRef___init__impl__cysrar(-1))};
  ref._v = new TimerRef(addTimer($this, false, TimerComponents$_interval$lambda(elapsed, time, repeat, $this, ref, callback)));
  return Closeable(TimerComponents$_interval$lambda_0($this, ref));
}
function TimerComponents$ensureUpdater$lambda(this$0) {
  return function ($this$addFastUpdater, dt) {
    var this_0 = this$0.ocp_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.time.TimerComponents.ensureUpdater.<anonymous>.<anonymous>' call
      var index = n;
      var tmp108_safe_receiver = this_0.i1(n);
      if (tmp108_safe_receiver == null)
        null;
      else
        tmp108_safe_receiver(dt);
      if (!(this$0.pcp_1.i1(index) === 0)) {
        removeTimer(this$0, _TimerRef___init__impl__cysrar(index));
      }
      n = n + 1 | 0;
    }
    var tmp;
    if (this$0.ocp_1.a1()) {
      var tmp109_safe_receiver = this$0.rcp_1;
      if (tmp109_safe_receiver == null)
        null;
      else {
        tmp109_safe_receiver.u6();
      }
      this$0.rcp_1 = null;
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function TimerComponents$wait$lambda($c) {
  return function () {
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = $c;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    this_0.id(tmp$ret$0);
    return Unit_instance;
  };
}
function TimerComponents$_interval$lambda($elapsed, $time, $repeat, this$0, $ref, $callback) {
  return function (dt) {
    $elapsed._v = new FastDuration(FastDuration__plus_impl_q1enab($elapsed._v.p1w_1, dt.p1w_1));
    $l$loop: while (FastDuration__compareTo_impl_4fhnj7($elapsed._v.p1w_1, $time) >= 0) {
      if (!$repeat) {
        removeTimer(this$0, $ref._v.mcq_1);
      }
      $elapsed._v = new FastDuration(FastDuration__minus_impl_evs237($elapsed._v.p1w_1, $time));
      $callback();
      if (!$repeat)
        break $l$loop;
    }
    return Unit_instance;
  };
}
function TimerComponents$_interval$lambda_0(this$0, $ref) {
  return function () {
    removeTimer(this$0, $ref._v.mcq_1);
    return Unit_instance;
  };
}
function _TimerRef___init__impl__cysrar(index) {
  return index;
}
function _TimerRef___get_index__impl__pnrx4q($this) {
  return $this;
}
function TimerRef__toString_impl_8hh2j7($this) {
  return 'TimerRef(index=' + $this + ')';
}
function TimerRef__hashCode_impl_d9ou9o($this) {
  return $this;
}
function TimerRef__equals_impl_2fvpr4($this, other) {
  if (!(other instanceof TimerRef))
    return false;
  if (!($this === (other instanceof TimerRef ? other.mcq_1 : THROW_CCE())))
    return false;
  return true;
}
function delay(_this__u8e3s4, time, $completion) {
  return get_timers(_this__u8e3s4).ncq(time, $completion);
}
function interval(_this__u8e3s4, time, callback) {
  _init_properties_TimerComponents_kt__j84oeh();
  return get_timers(_this__u8e3s4).scp(time, callback);
}
function timers$delegate$lambda($this$PropertyThis) {
  _init_properties_TimerComponents_kt__j84oeh();
  return new TimerComponents($this$PropertyThis);
}
function timers$factory() {
  return getPropertyCallableRef('timers', 1, KProperty1, function (receiver) {
    return get_timers(receiver);
  }, null);
}
var properties_initialized_TimerComponents_kt_v7ywzv;
function _init_properties_TimerComponents_kt__j84oeh() {
  if (!properties_initialized_TimerComponents_kt_v7ywzv) {
    properties_initialized_TimerComponents_kt_v7ywzv = true;
    timers$delegate = new PropertyThis('__ViewTimerComponents', timers$delegate$lambda);
  }
}
function get_DEFAULT_EASING() {
  _init_properties_tween_kt__1g7sex();
  return DEFAULT_EASING;
}
var DEFAULT_EASING;
function get_DEFAULT_TIME() {
  _init_properties_tween_kt__1g7sex();
  return DEFAULT_TIME;
}
var DEFAULT_TIME;
function get_DEFAULT_FTIME() {
  _init_properties_tween_kt__1g7sex();
  return DEFAULT_FTIME;
}
var DEFAULT_FTIME;
function tween_0(_this__u8e3s4, vs, time, easing, waitTime, timeout, autoInvalidate, callback, $completion) {
  time = time === VOID ? get_DEFAULT_TIME() : time;
  easing = easing === VOID ? get_DEFAULT_EASING() : easing;
  var tmp;
  if (waitTime === VOID) {
    // Inline function 'korlibs.time.NIL' call
    Companion_getInstance();
    tmp = get_DURATION_NIL();
  } else {
    tmp = waitTime;
  }
  waitTime = tmp;
  timeout = timeout === VOID ? false : timeout;
  autoInvalidate = autoInvalidate === VOID ? true : autoInvalidate;
  var tmp_0;
  if (callback === VOID) {
    tmp_0 = tween$lambda;
  } else {
    tmp_0 = callback;
  }
  callback = tmp_0;
  return tween_1(_this__u8e3s4, vs.slice(), get_fast(time), easing, get_fast(waitTime), timeout, autoInvalidate, callback, $completion);
}
function *_generator_tween__vopi21(_this__u8e3s4, vs, time, easing, waitTime, timeout, autoInvalidate, callback, $completion) {
  time = time === VOID ? get_DEFAULT_FTIME() : time;
  easing = easing === VOID ? get_DEFAULT_EASING() : easing;
  waitTime = waitTime === VOID ? Companion_instance_1.n1w() : waitTime;
  timeout = timeout === VOID ? false : timeout;
  autoInvalidate = autoInvalidate === VOID ? true : autoInvalidate;
  var tmp;
  if (callback === VOID) {
    tmp = tween$lambda_0;
  } else {
    tmp = callback;
  }
  callback = tmp;
  if (!(_this__u8e3s4 == null)) {
    var tc = {_v: null};
    try {
      var tmp_0;
      if (timeout) {
        // Inline function 'korlibs.time.FastDuration.times' call
        var tmp$ret$0 = FastDuration__times_impl_h6amcp(time, numberToDouble(2));
        tmp_0 = FastDuration__plus_impl_q1enab(tmp$ret$0, get_fastMilliseconds_0(300));
      } else {
        tmp_0 = Companion_instance_1.n1w();
      }
      var tmp_1 = tmp_0;
      var tmp_2 = withTimeoutNullable(tmp_1, tween$slambda_0(_this__u8e3s4, tc, vs, time, easing, callback, waitTime, autoInvalidate), $completion);
      if (tmp_2 === get_COROUTINE_SUSPENDED())
        tmp_2 = yield tmp_2;
    } catch ($p) {
      if ($p instanceof TimeoutCancellationException) {
        var e = $p;
        var tmp113_safe_receiver = tc._v;
        if (tmp113_safe_receiver == null)
          null;
        else {
          tmp113_safe_receiver.fcr(time);
        }
      } else {
        throw $p;
      }
    }
  }
  return Unit_instance;
}
function tween_1(_this__u8e3s4, vs, time, easing, waitTime, timeout, autoInvalidate, callback, $completion) {
  time = time === VOID ? get_DEFAULT_FTIME() : time;
  easing = easing === VOID ? get_DEFAULT_EASING() : easing;
  waitTime = waitTime === VOID ? Companion_instance_1.n1w() : waitTime;
  timeout = timeout === VOID ? false : timeout;
  autoInvalidate = autoInvalidate === VOID ? true : autoInvalidate;
  var tmp;
  if (callback === VOID) {
    tmp = tween$lambda_0;
  } else {
    tmp = callback;
  }
  callback = tmp;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_tween__vopi21(_this__u8e3s4, vs, time, easing, waitTime, timeout, autoInvalidate, callback, $completion), $completion);
}
function _update($this, dt) {
  if ($this.ycq_1) {
    $this.rcq_1.hbh();
  }
  if ($this.bcr_1) {
    return $this.kcr();
  }
  $this.zcq_1 = FastDuration__plus_impl_q1enab($this.zcq_1, dt);
  var ratio = clamp_0(FastDuration__div_impl_rlslck($this.zcq_1, $this.acr_1), 0.0, 1.0);
  $this.fcr($this.zcq_1);
  $this.vcq_1($this.ucq_1.xz(ratio));
  var tmp;
  var tmp_0 = $this.lcr();
  // Inline function 'korlibs.time.NIL' call
  Companion_getInstance();
  var tmp$ret$0 = get_DURATION_NIL();
  if (!equals(tmp_0, tmp$ret$0)) {
    tmp = FastDuration__compareTo_impl_4fhnj7_0($this.zcq_1, $this.lcr()) >= 0;
  } else {
    tmp = false;
  }
  if (tmp) {
    $this.mcr();
  }
  if (ratio >= 1.0) {
    return $this.kcr();
  }
}
function TweenComponent$updater$lambda(this$0) {
  return function (it) {
    _update(this$0, it.eat_1);
    return Unit_instance;
  };
}
function TweenComponent$lambda(this$0) {
  return function (it) {
    this$0.bcr_1 = true;
    return Unit_instance;
  };
}
function *_generator_withTimeoutNullable__i31xu0(time, block, $completion) {
  var tmp;
  var tmp_0;
  var tmp_1 = time;
  if ((tmp_1 == null ? null : new FastDuration(tmp_1)) == null) {
    tmp_0 = true;
  } else {
    var tmp_2 = time;
    tmp_0 = equals(tmp_2 == null ? null : new FastDuration(tmp_2), new FastDuration(Companion_instance_1.n1w()));
  }
  if (tmp_0) {
    // Inline function 'kotlin.js.getCoroutineContext' call
    var tmp$ret$0 = $completion.gd();
    var tmp_3 = block(CoroutineScope_0(tmp$ret$0), $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    tmp = tmp_3;
  } else {
    var tmp_4 = withTimeout(toLongRound(_FastDuration___get_fastMilliseconds__impl__pe68va(time)), block, $completion);
    if (tmp_4 === get_COROUTINE_SUSPENDED())
      tmp_4 = yield tmp_4;
    tmp = tmp_4;
  }
  return tmp;
}
function withTimeoutNullable(time, block, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_withTimeoutNullable__i31xu0(time, block, $completion), $completion);
}
function tweenAsync(_this__u8e3s4, vs, time, easing, waitTime, callback, $completion) {
  time = time === VOID ? get_DEFAULT_FTIME() : time;
  easing = easing === VOID ? get_DEFAULT_EASING() : easing;
  waitTime = waitTime === VOID ? Companion_instance_1.n1w() : waitTime;
  var tmp;
  if (callback === VOID) {
    tmp = tweenAsync$lambda;
  } else {
    tmp = callback;
  }
  callback = tmp;
  // Inline function 'kotlin.js.getCoroutineContext' call
  var tmp_0 = $completion.gd();
  return asyncImmediately(tmp_0, tweenAsync$slambda_0(_this__u8e3s4, vs, time, easing, waitTime, callback));
}
function tweenNoWait(_this__u8e3s4, vs, time, easing, waitTime, callback) {
  time = time === VOID ? get_DEFAULT_TIME() : time;
  easing = easing === VOID ? get_DEFAULT_EASING() : easing;
  var tmp;
  if (waitTime === VOID) {
    // Inline function 'korlibs.time.NIL' call
    Companion_getInstance();
    tmp = get_DURATION_NIL();
  } else {
    tmp = waitTime;
  }
  waitTime = tmp;
  var tmp_0;
  if (callback === VOID) {
    tmp_0 = tweenNoWait$lambda;
  } else {
    tmp_0 = callback;
  }
  callback = tmp_0;
  _init_properties_tween_kt__1g7sex();
  if (_this__u8e3s4 == null)
    return null;
  return new TweenComponent(_this__u8e3s4, toList(vs), get_fast(time), easing, callback, null, get_fast(waitTime));
}
function tween$lambda(it) {
  _init_properties_tween_kt__1g7sex();
  return Unit_instance;
}
function tween$lambda_0(it) {
  _init_properties_tween_kt__1g7sex();
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_23($this, $this$withTimeoutNullable, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine' call
  // Inline function 'kotlin.js.suspendCoroutineUninterceptedOrReturnJS' call
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
  cancellable.u19();
  // Inline function 'korlibs.korge.tween.tween.<anonymous>.<anonymous>' call
  var view = $this.ncr_1;
  $this.ocr_1._v = new TweenComponent(view, toList($this.pcr_1), $this.qcr_1, $this.rcr_1, $this.scr_1, cancellable, $this.tcr_1, $this.ucr_1);
  var tmp$ret$0 = cancellable.g17();
  var tmp = returnIfSuspended(tmp$ret$0, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function tween$slambda_0($this_tween, $tc, $vs, $time, $easing, $callback, $waitTime, $autoInvalidate) {
  var i = new tween$slambda($this_tween, $tc, $vs, $time, $easing, $callback, $waitTime, $autoInvalidate);
  var l = function ($this$withTimeoutNullable, $completion) {
    return i.o1s($this$withTimeoutNullable, $completion);
  };
  l.$arity = 1;
  return l;
}
function tweenAsync$lambda(it) {
  _init_properties_tween_kt__1g7sex();
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_24($this, $completion) {
  var tmp = tween_1($this.vcr_1, $this.wcr_1.slice(), $this.xcr_1, $this.ycr_1, $this.zcr_1, VOID, VOID, $this.acs_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function tweenAsync$slambda_0($this_tweenAsync, $vs, $time, $easing, $waitTime, $callback) {
  var i = new tweenAsync$slambda($this_tweenAsync, $vs, $time, $easing, $waitTime, $callback);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function tweenNoWait$lambda(it) {
  _init_properties_tween_kt__1g7sex();
  return Unit_instance;
}
var properties_initialized_tween_kt_p0n8nv;
function _init_properties_tween_kt__1g7sex() {
  if (!properties_initialized_tween_kt_p0n8nv) {
    properties_initialized_tween_kt_p0n8nv = true;
    DEFAULT_EASING = get_EASE_IN_OUT_QUAD(Companion_getInstance_44());
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    DEFAULT_TIME = toDuration(1, DurationUnit_SECONDS_getInstance());
    DEFAULT_FTIME = get_fastSeconds(1);
  }
}
function *_generator_invoke__zhh2q8_25($this, $completion) {
  var tmp = $this.bcs_1($this.ccs_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_26($this, $completion) {
  var tmp = $this.dcs_1($this.ecs_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
var Companion_instance_20;
function Companion_getInstance_53() {
  if (Companion_instance_20 === VOID)
    new Companion_5();
  return Companion_instance_20;
}
function _get_halfSide__37ya73($this) {
  // Inline function 'kotlin.math.min' call
  var a = $this.j2y();
  var b = $this.k2y();
  var tmp$ret$0 = Math.min(a, b);
  return numberToInt(tmp$ret$0) / 2 | 0;
}
function setInitialState($this) {
  var width = $this.j2y();
  var height = $this.k2y();
  size($this.kcv_1, width, height);
  // Inline function 'korlibs.math.geom.Companion.invoke' call
  Companion_getInstance_36();
  var corner = $this.xcv();
  var tmp$ret$0 = new RectCorners(numberToDouble(corner), numberToDouble(corner), numberToDouble(corner), numberToDouble(corner));
  $this.kcv_1.dcz(tmp$ret$0);
  $this.kcv_1.ecz($this.jcv_1 ? 10.0 : 0.0);
  size($this.lcv_1, width, height);
  var tmp = Companion_getInstance_61();
  var tmp0_elvis_lhs = $this.tcv_1;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
    tmp_0 = get_Bitmaps_transparent();
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  tmp.icz($this.ncv_1, tmp_0, width, height, Companion_getInstance_32().t2g_1);
  $this.ncv_1.ubh(!$this.jcz() ? 0.5 : $this.ocv_1 ? 1.0 : 1.0);
  $this.hbh();
}
function UIButton$lambda$lambda(this$0) {
  return function (it) {
    this$0.kcz(it.bbr() / this$0.j2y(), it.cbr() / this$0.k2y());
    var tmp;
    if (this$0.fcq()) {
      set_focused(this$0, true);
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function UIButton$lambda$lambda_0(this$0) {
  return function (it) {
    this$0.hd2();
    return Unit_instance;
  };
}
function UIButton$lambda$lambda_1(this$0) {
  return function (it) {
    this$0.ucv_1.x1x(it);
    return Unit_instance;
  };
}
function UIButton$lambda(this$0) {
  return function ($this$singleTouch, it) {
    $this$singleTouch.rbr_1.w1x(UIButton$lambda$lambda(this$0));
    $this$singleTouch.wbr_1.w1x(UIButton$lambda$lambda_0(this$0));
    $this$singleTouch.xbr_1.w1x(UIButton$lambda$lambda_1(this$0));
    return Unit_instance;
  };
}
function UIButton$slambda_3($$this$mouse, this$0) {
  var i = new UIButton$slambda($$this$mouse, this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIButton$lambda$slambda_1($handler, $it) {
  var i = new UIButton$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIButton$lambda_0($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIButton$lambda$slambda_1($handler, it));
    return Unit_instance;
  };
}
function UIButton$slambda_4($$this$mouse, this$0) {
  var i = new UIButton$slambda_0($$this$mouse, this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIButton$lambda$slambda_2($handler, $it) {
  var i = new UIButton$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIButton$lambda_1($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIButton$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function _interpolateColor$ref() {
  var l = function (p0, p1, p2) {
    return new RGBA(_interpolateColor(p0.e10_1, p1.v59_1, p2.v59_1));
  };
  l.callableName = '_interpolateColor';
  return l;
}
function UIButton$slambda_5(this$0) {
  var i = new UIButton$slambda_1(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIButton$slambda_6(this$0, $$this$keys) {
  var i = new UIButton$slambda_2(this$0, $$this$keys);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function clicked(_this__u8e3s4, block) {
  // Inline function 'korlibs.korge.input.onClick' call
  var handler = clicked$slambda_0(block, _this__u8e3s4);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop = click$factory();
  var tmp73_safe_receiver = _this__u8e3s4 == null ? null : get_mouse(_this__u8e3s4);
  if (tmp73_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp = prop.get(tmp73_safe_receiver);
    tmp.v1x(clicked$lambda(tmp73_safe_receiver, handler));
  }
  return _this__u8e3s4;
}
function clicked$slambda_0($block, $this_clicked) {
  var i = new clicked$slambda($block, $this_clicked);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_27($this, $completion) {
  var tmp = $this.rd9_1($this.sd9_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function clicked$lambda$slambda_0($handler, $it) {
  var i = new clicked$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function clicked$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, clicked$lambda$slambda_0($handler, it));
    return Unit_instance;
  };
}
function align$factory($b0) {
  return getPropertyCallableRef('align', 0, KMutableProperty0, function () {
    return $b0.rd5_1;
  }, function (value) {
    return $b0.td9(value);
  });
}
function text$factory($b0) {
  return getPropertyCallableRef('text', 0, KMutableProperty0, function () {
    return $b0.qd5_1;
  }, function (value) {
    return $b0.ud9(value);
  });
}
function over$factory() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
function out$factory() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
function textAlignment$factory_1() {
  return getPropertyCallableRef('textAlignment', 1, KMutableProperty1, function (receiver) {
    return receiver.cd9();
  }, function (receiver, value) {
    return receiver.bd9(value);
  });
}
function textAlignment$factory_2() {
  return getPropertyCallableRef('textAlignment', 1, KMutableProperty1, function (receiver) {
    return receiver.cd9();
  }, function (receiver, value) {
    return receiver.bd9(value);
  });
}
function richText$factory() {
  return getPropertyCallableRef('richText', 1, KMutableProperty1, function (receiver) {
    return receiver.ed9();
  }, function (receiver, value) {
    return receiver.dd9(value);
  });
}
function richText$factory_0() {
  return getPropertyCallableRef('richText', 1, KMutableProperty1, function (receiver) {
    return receiver.ed9();
  }, function (receiver, value) {
    return receiver.dd9(value);
  });
}
function bgColor$factory($b0) {
  return getPropertyCallableRef('bgColor', 0, KMutableProperty0, function () {
    return new RGBA($b0.scy_1);
  }, function (value) {
    return $b0.wd8(value.v59_1);
  });
}
function click$factory() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function *_generator_invoke__zhh2q8_28($this, $completion) {
  var tmp = $this.vd9_1($this.wd9_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function UIButtonToggleableGroup$add$slambda_0(this$0, $button) {
  var i = new UIButtonToggleableGroup$add$slambda(this$0, $button);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIButtonToggleableGroup$add$lambda$slambda_0($handler, $it) {
  var i = new UIButtonToggleableGroup$add$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIButtonToggleableGroup$add$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIButtonToggleableGroup$add$lambda$slambda_0($handler, it));
    return Unit_instance;
  };
}
function click$factory_0() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function *_generator_invoke__zhh2q8_29($this, $completion) {
  var tmp = $this.cda_1($this.dda_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_30($this, $completion) {
  var tmp = $this.eda_1($this.fda_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_31($this, $completion) {
  var tmp = $this.gda_1($this.hda_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_32($this, $completion) {
  var tmp = $this.ida_1($this.jda_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_33($this, $completion) {
  var tmp = $this.kda_1($this.lda_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function _set_over__9rx6v7($this, _set____db54di) {
  return $this.rdd_1.i3e($this, over$factory_1(), _set____db54di);
}
function _get_over__dbp6gn($this) {
  return $this.rdd_1.h3e($this, over$factory_2());
}
function _set_pressing__m9uj20($this, _set____db54di) {
  return $this.sdd_1.i3e($this, pressing$factory(), _set____db54di);
}
function _get_pressing__9gy7l8($this) {
  return $this.sdd_1.h3e($this, pressing$factory_0());
}
function _interpolate$ref() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function UIBaseCheckBox$canvas$lambda(this$0) {
  return function ($this$renderableView) {
    this$0.ec7(get_uiCheckboxButtonRenderer(get_styles($this$renderableView)), $this$renderableView.d7i());
    return Unit_instance;
  };
}
function _interpolate$ref_0() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function UIBaseCheckBox$over$delegate$lambda(this$0) {
  return function (it) {
    this$0.ic7();
    var tmp = get_simpleAnimator(this$0);
    // Inline function 'korlibs.korge.tween.get' call
    var this_0 = overRatio$factory(this$0);
    var end = it ? 1.0 : 0.0;
    var tmp_0 = this_0.get();
    var tmp_1 = [new V2(this_0, tmp_0, end, _interpolate$ref_0(), false)];
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$1 = toDuration(0.1, DurationUnit_SECONDS_getInstance());
    tween(tmp, tmp_1, tmp$ret$1);
    return Unit_instance;
  };
}
function UIBaseCheckBox$pressing$delegate$lambda(this$0) {
  return function (it) {
    this$0.ic7();
    return Unit_instance;
  };
}
function UIBaseCheckBox$slambda_5(this$0) {
  var i = new UIBaseCheckBox$slambda(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIBaseCheckBox$lambda$slambda_4($handler, $it) {
  var i = new UIBaseCheckBox$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIBaseCheckBox$lambda($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIBaseCheckBox$lambda$slambda_4($handler, it));
    return Unit_instance;
  };
}
function UIBaseCheckBox$slambda_6(this$0) {
  var i = new UIBaseCheckBox$slambda_0(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIBaseCheckBox$lambda$slambda_5($handler, $it) {
  var i = new UIBaseCheckBox$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIBaseCheckBox$lambda_0($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIBaseCheckBox$lambda$slambda_5($handler, it));
    return Unit_instance;
  };
}
function UIBaseCheckBox$slambda_7(this$0) {
  var i = new UIBaseCheckBox$slambda_1(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIBaseCheckBox$lambda$slambda_6($handler, $it) {
  var i = new UIBaseCheckBox$lambda$slambda_1($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIBaseCheckBox$lambda_1($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIBaseCheckBox$lambda$slambda_6($handler, it));
    return Unit_instance;
  };
}
function UIBaseCheckBox$slambda_8(this$0) {
  var i = new UIBaseCheckBox$slambda_2(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIBaseCheckBox$lambda$slambda_7($handler, $it) {
  var i = new UIBaseCheckBox$lambda$slambda_2($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIBaseCheckBox$lambda_2($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIBaseCheckBox$lambda$slambda_7($handler, it));
    return Unit_instance;
  };
}
function UIBaseCheckBox$slambda_9(this$0) {
  var i = new UIBaseCheckBox$slambda_3(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIBaseCheckBox$lambda$slambda_8($handler, $it) {
  var i = new UIBaseCheckBox$lambda$slambda_3($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIBaseCheckBox$lambda_3($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIBaseCheckBox$lambda$slambda_8($handler, it));
    return Unit_instance;
  };
}
function _interpolate$ref_1() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function UIBaseCheckBox$slambda_10(this$0) {
  var i = new UIBaseCheckBox$slambda_4(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
var Companion_instance_21;
function Companion_getInstance_54() {
  if (Companion_instance_21 === VOID)
    new Companion_6();
  return Companion_instance_21;
}
function over$factory_0() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
function out$factory_0() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
function down$factory_0() {
  return getPropertyCallableRef('down', 1, KProperty1, function (receiver) {
    return receiver.cbi_1;
  }, null);
}
function upAnywhere$factory_0() {
  return getPropertyCallableRef('upAnywhere', 1, KProperty1, function (receiver) {
    return receiver.jbi_1;
  }, null);
}
function click$factory_1() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function checkedRatio$factory($b0) {
  return getPropertyCallableRef('checkedRatio', 0, KMutableProperty0, function () {
    return $b0.pdd_1;
  }, function (value) {
    $b0.pdd_1 = value;
    return Unit_instance;
  });
}
function over$factory_1() {
  return getPropertyCallableRef('over', 1, KMutableProperty1, function (receiver) {
    return _get_over__dbp6gn(receiver);
  }, function (receiver, value) {
    return _set_over__9rx6v7(receiver, value);
  });
}
function over$factory_2() {
  return getPropertyCallableRef('over', 1, KMutableProperty1, function (receiver) {
    return _get_over__dbp6gn(receiver);
  }, function (receiver, value) {
    return _set_over__9rx6v7(receiver, value);
  });
}
function pressing$factory() {
  return getPropertyCallableRef('pressing', 1, KMutableProperty1, function (receiver) {
    return _get_pressing__9gy7l8(receiver);
  }, function (receiver, value) {
    return _set_pressing__m9uj20(receiver, value);
  });
}
function pressing$factory_0() {
  return getPropertyCallableRef('pressing', 1, KMutableProperty1, function (receiver) {
    return _get_pressing__9gy7l8(receiver);
  }, function (receiver, value) {
    return _set_pressing__m9uj20(receiver, value);
  });
}
function focusRatio$factory($b0) {
  return getPropertyCallableRef('focusRatio', 0, KMutableProperty0, function () {
    return $b0.vdd_1;
  }, function (value) {
    $b0.vdd_1 = value;
    return Unit_instance;
  });
}
function overRatio$factory($b0) {
  return getPropertyCallableRef('overRatio', 0, KMutableProperty0, function () {
    return $b0.qdd_1;
  }, function (value) {
    $b0.qdd_1 = value;
    return Unit_instance;
  });
}
function set_openedComboBox(_this__u8e3s4, _set____db54di) {
  _init_properties_UIComboBox_kt__76011t();
  var this_0 = openedComboBox$delegate;
  var property = openedComboBox$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_openedComboBox(_this__u8e3s4) {
  _init_properties_UIComboBox_kt__76011t();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = openedComboBox$delegate;
    var property = openedComboBox$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var openedComboBox$delegate;
function *_generator_invoke__zhh2q8_34($this, $completion) {
  var tmp = $this.tdm_1($this.udm_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function UIComboBox$verticalList$o$getItemView$lambda($highlightColor) {
  return function (it) {
    return "<span color='" + _RGBA___get_hexString__impl__kocngm($highlightColor) + "'>" + it.b2() + '<\/span>';
  };
}
function UIComboBox$verticalList$o$getItemView$slambda_0(this$0, $index) {
  var i = new UIComboBox$verticalList$o$getItemView$slambda(this$0, $index);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$verticalList$o$getItemView$lambda$slambda_0($handler, $it) {
  var i = new UIComboBox$verticalList$o$getItemView$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIComboBox$verticalList$o$getItemView$lambda_0($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIComboBox$verticalList$o$getItemView$lambda$slambda_0($handler, it));
    return Unit_instance;
  };
}
function *_generator_invoke__zhh2q8_35($this, $completion) {
  var tmp = $this.idq_1($this.jdq_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_36($this, $completion) {
  var tmp = $this.kdq_1($this.ldq_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_37($this, $completion) {
  var tmp = $this.mdq_1($this.ndq_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_38($this, $completion) {
  var tmp = $this.odq_1($this.pdq_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_39($this, $completion) {
  var tmp = $this.qdq_1($this.rdq_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function ensureSelectedIsInVisibleArea($this, index) {
  $this.edq_1.rdt();
  $this.ddq_1.hdx(new Rectangle(0.0, $this.edq_1.qdt_1.sdt(index), $this.j2y(), $this.edq_1.qdt_1.tdt(index)));
}
function updateItems($this) {
  $this.gdq_1 = true;
}
function updateProps($this) {
  $this.zdp_1.hd9($this.fdq_1);
  size($this.bdq_1, $this.j2y(), $this.k2y());
  size($this.zdp_1, $this.j2y(), $this.k2y());
  var tmp129_safe_receiver = $this.idx();
  var tmp0_elvis_lhs = tmp129_safe_receiver == null ? null : toString(tmp129_safe_receiver);
  $this.zdp_1.bcg(tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs);
  position($this.adq_1, $this.j2y() - 16.0, $this.k2y() * 0.5);
}
function UIComboBox$selectedIndex$delegate$lambda(this$0) {
  return function (it) {
    this$0.jdx(it);
    this$0.tdp_1.x1x(this$0);
    return Unit_instance;
  };
}
function UIComboBox$focusedIndex$delegate$lambda(this$0) {
  return function (it) {
    ensureSelectedIsInVisibleArea(this$0, it);
    this$0.ic7();
    return Unit_instance;
  };
}
function UIComboBox$items$delegate$lambda(this$0) {
  return function (it) {
    updateItems(this$0);
    return Unit_instance;
  };
}
function UIComboBox$viewportHeight$delegate$lambda(this$0) {
  return function (it) {
    this$0.yc6();
    return Unit_instance;
  };
}
function UIComboBox$slambda_9(this$0) {
  var i = new UIComboBox$slambda(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$lambda$slambda_4($handler, $it) {
  var i = new UIComboBox$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIComboBox$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIComboBox$lambda$slambda_4($handler, it));
    return Unit_instance;
  };
}
function UIComboBox$slambda_10(this$0) {
  var i = new UIComboBox$slambda_0(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$lambda$slambda_5($handler, $it) {
  var i = new UIComboBox$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIComboBox$lambda_0($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIComboBox$lambda$slambda_5($handler, it));
    return Unit_instance;
  };
}
function UIComboBox$slambda_11(this$0) {
  var i = new UIComboBox$slambda_1(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$lambda$slambda_6($handler, $it) {
  var i = new UIComboBox$lambda$slambda_1($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIComboBox$lambda_1($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIComboBox$lambda$slambda_6($handler, it));
    return Unit_instance;
  };
}
function UIComboBox$slambda_12(this$0) {
  var i = new UIComboBox$slambda_2(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$lambda$slambda_7($handler, $it) {
  var i = new UIComboBox$lambda$slambda_2($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIComboBox$lambda_2($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIComboBox$lambda$slambda_7($handler, it));
    return Unit_instance;
  };
}
function UIComboBox$slambda_13(this$0) {
  var i = new UIComboBox$slambda_3(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$lambda$slambda_8($handler, $it) {
  var i = new UIComboBox$lambda$slambda_3($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIComboBox$lambda_3($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIComboBox$lambda$slambda_8($handler, it));
    return Unit_instance;
  };
}
function _interpolate$ref_2() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_3() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_4() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_5() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_6() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolateColor$ref_0() {
  var l = function (p0, p1, p2) {
    return new RGBA(_interpolateColor(p0.e10_1, p1.v59_1, p2.v59_1));
  };
  l.callableName = '_interpolateColor';
  return l;
}
function _interpolate$ref_7() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_8() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_9() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_10() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_11() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_12() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolateColor$ref_1() {
  var l = function (p0, p1, p2) {
    return new RGBA(_interpolateColor(p0.e10_1, p1.v59_1, p2.v59_1));
  };
  l.callableName = '_interpolateColor';
  return l;
}
function _interpolate$ref_13() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function UIComboBox$close$lambda(this$0) {
  return function () {
    this$0.ddq_1.car(false);
    this$0.bdy(false);
    return Unit_instance;
  };
}
function UIComboBox$slambda_14(this$0) {
  var i = new UIComboBox$slambda_4(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$slambda_15(this$0) {
  var i = new UIComboBox$slambda_5(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$slambda_16(this$0) {
  var i = new UIComboBox$slambda_6(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$slambda_17(this$0) {
  var i = new UIComboBox$slambda_7(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIComboBox$slambda_18(this$0) {
  var i = new UIComboBox$slambda_8(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function openedComboBox$delegate$lambda() {
  _init_properties_UIComboBox_kt__76011t();
  return null;
}
function openedComboBox$factory() {
  return getPropertyCallableRef('openedComboBox', 1, KMutableProperty1, function (receiver) {
    return get_openedComboBox(receiver);
  }, function (receiver, value) {
    return set_openedComboBox(receiver, value);
  });
}
function openedComboBox$factory_0() {
  return getPropertyCallableRef('openedComboBox', 1, KMutableProperty1, function (receiver) {
    return get_openedComboBox(receiver);
  }, function (receiver, value) {
    return set_openedComboBox(receiver, value);
  });
}
function over$factory_3() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
function out$factory_1() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
function down$factory_1() {
  return getPropertyCallableRef('down', 1, KProperty1, function (receiver) {
    return receiver.cbi_1;
  }, null);
}
function up$factory() {
  return getPropertyCallableRef('up', 1, KProperty1, function (receiver) {
    return receiver.fbi_1;
  }, null);
}
function click$factory_2() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function selectedIndex$factory() {
  return getPropertyCallableRef('selectedIndex', 1, KMutableProperty1, function (receiver) {
    return receiver.ne1();
  }, function (receiver, value) {
    return receiver.me1(value);
  });
}
function selectedIndex$factory_0() {
  return getPropertyCallableRef('selectedIndex', 1, KMutableProperty1, function (receiver) {
    return receiver.ne1();
  }, function (receiver, value) {
    return receiver.me1(value);
  });
}
function focusedIndex$factory() {
  return getPropertyCallableRef('focusedIndex', 1, KMutableProperty1, function (receiver) {
    return receiver.udx();
  }, function (receiver, value) {
    return receiver.jdx(value);
  });
}
function focusedIndex$factory_0() {
  return getPropertyCallableRef('focusedIndex', 1, KMutableProperty1, function (receiver) {
    return receiver.udx();
  }, function (receiver, value) {
    return receiver.jdx(value);
  });
}
function items$factory() {
  return getPropertyCallableRef('items', 1, KMutableProperty1, function (receiver) {
    return receiver.oe1();
  }, function (receiver, value) {
    return receiver.qe1(value);
  });
}
function items$factory_0() {
  return getPropertyCallableRef('items', 1, KMutableProperty1, function (receiver) {
    return receiver.oe1();
  }, function (receiver, value) {
    return receiver.qe1(value);
  });
}
function viewportHeight$factory() {
  return getPropertyCallableRef('viewportHeight', 1, KMutableProperty1, function (receiver) {
    return receiver.se1();
  }, function (receiver, value) {
    return receiver.re1(value);
  });
}
function viewportHeight$factory_0() {
  return getPropertyCallableRef('viewportHeight', 1, KMutableProperty1, function (receiver) {
    return receiver.se1();
  }, function (receiver, value) {
    return receiver.re1(value);
  });
}
function alpha$factory($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function scaleY$factory($b0) {
  return getPropertyCallableRef('scaleY', 0, KMutableProperty0, function () {
    return $b0.tcb();
  }, function (value) {
    return $b0.scb(value);
  });
}
function alpha$factory_0($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function scaleY$factory_0($b0) {
  return getPropertyCallableRef('scaleY', 0, KMutableProperty0, function () {
    return $b0.tcb();
  }, function (value) {
    return $b0.scb(value);
  });
}
function scaleY$factory_1($b0) {
  return getPropertyCallableRef('scaleY', 0, KMutableProperty0, function () {
    return $b0.tcb();
  }, function (value) {
    return $b0.scb(value);
  });
}
function borderColor$factory($b0) {
  return getPropertyCallableRef('borderColor', 0, KMutableProperty0, function () {
    return new RGBA($b0.vcy_1);
  }, function (value) {
    return $b0.ndy(value.v59_1);
  });
}
function borderSize$factory($b0) {
  return getPropertyCallableRef('borderSize', 0, KMutableProperty0, function () {
    return $b0.wcy_1;
  }, function (value) {
    return $b0.ody(value);
  });
}
function alpha$factory_1($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function scaleY$factory_2($b0) {
  return getPropertyCallableRef('scaleY', 0, KMutableProperty0, function () {
    return $b0.tcb();
  }, function (value) {
    return $b0.scb(value);
  });
}
function alpha$factory_2($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function scaleY$factory_3($b0) {
  return getPropertyCallableRef('scaleY', 0, KMutableProperty0, function () {
    return $b0.tcb();
  }, function (value) {
    return $b0.scb(value);
  });
}
function scaleY$factory_4($b0) {
  return getPropertyCallableRef('scaleY', 0, KMutableProperty0, function () {
    return $b0.tcb();
  }, function (value) {
    return $b0.scb(value);
  });
}
function borderColor$factory_0($b0) {
  return getPropertyCallableRef('borderColor', 0, KMutableProperty0, function () {
    return new RGBA($b0.vcy_1);
  }, function (value) {
    return $b0.ndy(value.v59_1);
  });
}
function borderSize$factory_0($b0) {
  return getPropertyCallableRef('borderSize', 0, KMutableProperty0, function () {
    return $b0.wcy_1;
  }, function (value) {
    return $b0.ody(value);
  });
}
function click$factory_3() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
var properties_initialized_UIComboBox_kt_3nswod;
function _init_properties_UIComboBox_kt__76011t() {
  if (!properties_initialized_UIComboBox_kt_3nswod) {
    properties_initialized_UIComboBox_kt_3nswod = true;
    openedComboBox$delegate = new Property(VOID, openedComboBox$delegate$lambda);
  }
}
function UIGridFill$cols$delegate$lambda(this$0) {
  return function (it) {
    this$0.se4();
    return Unit_instance;
  };
}
function UIGridFill$rows$delegate$lambda(this$0) {
  return function (it) {
    this$0.se4();
    return Unit_instance;
  };
}
function UIGridFill$spacing$delegate$lambda(this$0) {
  return function (it) {
    this$0.se4();
    return Unit_instance;
  };
}
function UIGridFill$direction$delegate$lambda(this$0) {
  return function (it) {
    this$0.se4();
    return Unit_instance;
  };
}
function cols$factory() {
  return getPropertyCallableRef('cols', 1, KMutableProperty1, function (receiver) {
    return receiver.sea();
  }, function (receiver, value) {
    return receiver.rea(value);
  });
}
function cols$factory_0() {
  return getPropertyCallableRef('cols', 1, KMutableProperty1, function (receiver) {
    return receiver.sea();
  }, function (receiver, value) {
    return receiver.rea(value);
  });
}
function rows$factory() {
  return getPropertyCallableRef('rows', 1, KMutableProperty1, function (receiver) {
    return receiver.uea();
  }, function (receiver, value) {
    return receiver.tea(value);
  });
}
function rows$factory_0() {
  return getPropertyCallableRef('rows', 1, KMutableProperty1, function (receiver) {
    return receiver.uea();
  }, function (receiver, value) {
    return receiver.tea(value);
  });
}
function spacing$factory() {
  return getPropertyCallableRef('spacing', 1, KMutableProperty1, function (receiver) {
    return receiver.wea();
  }, function (receiver, value) {
    return receiver.vea(value);
  });
}
function spacing$factory_0() {
  return getPropertyCallableRef('spacing', 1, KMutableProperty1, function (receiver) {
    return receiver.wea();
  }, function (receiver, value) {
    return receiver.vea(value);
  });
}
function direction$factory() {
  return getPropertyCallableRef('direction', 1, KMutableProperty1, function (receiver) {
    return receiver.yea();
  }, function (receiver, value) {
    return receiver.xea(value);
  });
}
function direction$factory_0() {
  return getPropertyCallableRef('direction', 1, KMutableProperty1, function (receiver) {
    return receiver.yea();
  }, function (receiver, value) {
    return receiver.xea(value);
  });
}
function get_UI_DEFAULT_SIZE() {
  _init_properties_UIDefaults_kt__ebmksm();
  return UI_DEFAULT_SIZE;
}
var UI_DEFAULT_SIZE;
var properties_initialized_UIDefaults_kt_arkqjc;
function _init_properties_UIDefaults_kt__ebmksm() {
  if (!properties_initialized_UIDefaults_kt_arkqjc) {
    properties_initialized_UIDefaults_kt_arkqjc = true;
    UI_DEFAULT_SIZE = Size2D.f2j(100, 32);
  }
}
var UIDirection_ROW_instance;
var UIDirection_COLUMN_instance;
var UIDirection_ROW_REVERSE_instance;
var UIDirection_COLUMN_REVERSE_instance;
var Companion_instance_22;
function Companion_getInstance_55() {
  return Companion_instance_22;
}
var UIDirection_entriesInitialized;
function UIDirection_initEntries() {
  if (UIDirection_entriesInitialized)
    return Unit_instance;
  UIDirection_entriesInitialized = true;
  UIDirection_ROW_instance = new UIDirection('ROW', 0, true);
  UIDirection_COLUMN_instance = new UIDirection('COLUMN', 1, VOID, true);
  UIDirection_ROW_REVERSE_instance = new UIDirection('ROW_REVERSE', 2, true, VOID, true);
  UIDirection_COLUMN_REVERSE_instance = new UIDirection('COLUMN_REVERSE', 3, VOID, true, true);
}
function UIDirection_ROW_getInstance() {
  UIDirection_initEntries();
  return UIDirection_ROW_instance;
}
function UIDirection_COLUMN_getInstance() {
  UIDirection_initEntries();
  return UIDirection_COLUMN_instance;
}
function getValueText($this, value) {
  return toStringDecimal(value, $this.net_1);
}
function getValueText$default($this, value, $super) {
  value = value === VOID ? $this.uet_1 : value;
  return getValueText($this, value);
}
function setTextInputVisible($this, visible, useValue) {
  $this.pet_1.car(!visible);
  $this.qet_1.car(visible);
  if ($this.qet_1.qcc()) {
    $this.vet_1 = $this.uet_1;
    $this.pet_1.bcg(getValueText$default($this));
    $this.qet_1.bcg(getValueText$default($this));
    $this.qet_1.jf0();
    $this.qet_1.ecg();
  } else {
    var tmp;
    if (useValue) {
      var tmp0_elvis_lhs = toDoubleOrNull($this.qet_1.o2f());
      tmp = tmp0_elvis_lhs == null ? $this.vet_1 : tmp0_elvis_lhs;
    } else {
      tmp = $this.vet_1;
    }
    $this.hex(tmp);
  }
}
function setTextInputVisible$default($this, visible, useValue, $super) {
  useValue = useValue === VOID ? true : useValue;
  return setTextInputVisible($this, visible, useValue);
}
function UIEditableNumber$lambda(this$0) {
  return function (it) {
    setTextInputVisible(this$0, false, true);
    return Unit_instance;
  };
}
function UIEditableNumber$lambda_0(this$0) {
  return function (it) {
    setTextInputVisible(this$0, false, false);
    return Unit_instance;
  };
}
function UIEditableNumber$lambda_1(this$0) {
  return function (it) {
    setTextInputVisible(this$0, false, true);
    return Unit_instance;
  };
}
function UIEditableNumber$lambda_2(it) {
  return Unit_instance;
}
function UIEditableNumber$lambda_3(this$0) {
  return function (it) {
    setTextInputVisible$default(this$0, !this$0.qet_1.qcc());
    return Unit_instance;
  };
}
function UIEditableNumber$lambda_4(this$0, $start, $max, $min) {
  return function ($this$onMouseDrag, it) {
    var tmp;
    if (this$0.qet_1.qcc()) {
      return Unit_instance;
    }
    var tmp_0;
    if (it.gbm_1) {
      $start._v = this$0.uet_1;
      tmp_0 = Unit_instance;
    }
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = $max - $min;
    var dist = Math.abs(this_0);
    this$0.hex($start._v + dist * (it.ebm_1 / (this$0.j2y() * 2)));
    it.tbm().lbq();
    return Unit_instance;
  };
}
function set__focusable(_this__u8e3s4, _set____db54di) {
  _init_properties_UIFocusManager_kt__odyms7();
  var this_0 = _focusable$delegate;
  var property = _focusable$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get__focusable(_this__u8e3s4) {
  _init_properties_UIFocusManager_kt__odyms7();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = _focusable$delegate;
    var property = _focusable$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var _focusable$delegate;
function get_uiFocusManager(_this__u8e3s4) {
  _init_properties_UIFocusManager_kt__odyms7();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = uiFocusManager$delegate;
    var property = uiFocusManager$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var uiFocusManager$delegate;
function set_focused(_this__u8e3s4, value) {
  _init_properties_UIFocusManager_kt__odyms7();
  var tmp134_safe_receiver = _this__u8e3s4.ucp(Scope_instance).pbj();
  var tmp135_safe_receiver = tmp134_safe_receiver == null ? null : get_uiFocusManager(tmp134_safe_receiver);
  if (tmp135_safe_receiver == null)
    null;
  else {
    tmp135_safe_receiver.rf0(value ? _this__u8e3s4 : null);
  }
}
function get_focused(_this__u8e3s4) {
  _init_properties_UIFocusManager_kt__odyms7();
  var tmp133_safe_receiver = _this__u8e3s4.ucp(Scope_instance).pbj();
  return equals(tmp133_safe_receiver == null ? null : get_uiFocusedView(tmp133_safe_receiver), _this__u8e3s4);
}
function focus(_this__u8e3s4) {
  _init_properties_UIFocusManager_kt__odyms7();
  set_focused(_this__u8e3s4, true);
}
function blur(_this__u8e3s4) {
  _init_properties_UIFocusManager_kt__odyms7();
  set_focused(_this__u8e3s4, false);
}
var Scope_instance;
function Scope_getInstance() {
  return Scope_instance;
}
function _get_rfocusView__qys03k(_this__u8e3s4, $this) {
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.UIFocusManager.<get-rfocusView>.<anonymous>' call
  return _this__u8e3s4.ucp(Scope_instance);
}
function UIFocusManager$changeFocusIndex$lambda(it) {
  return !(get_focusable(it) == null);
}
function UIFocusManager$changeFocusIndex$lambda_0(a, b) {
  // Inline function 'kotlin.comparisons.compareValuesBy' call
  // Inline function 'korlibs.korge.ui.UIFocusManager.changeFocusIndex.<anonymous>' call
  var tmp = a.ecq();
  // Inline function 'korlibs.korge.ui.UIFocusManager.changeFocusIndex.<anonymous>' call
  var tmp$ret$1 = b.ecq();
  return compareValues(tmp, tmp$ret$1);
}
function UIFocusManager$lambda(this$0) {
  return function (it) {
    var tmp;
    if (it.f8c_1.equals(Key_TAB_getInstance())) {
      this$0.tf0(it.i8c_1 ? -1 : 1);
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function get_uiFocusedView(_this__u8e3s4) {
  _init_properties_UIFocusManager_kt__odyms7();
  return get_uiFocusManager(_this__u8e3s4).jcq_1;
}
function set_focusable(_this__u8e3s4, value) {
  _init_properties_UIFocusManager_kt__odyms7();
  set__focusable(_this__u8e3s4, value);
}
function get_focusable(_this__u8e3s4) {
  _init_properties_UIFocusManager_kt__odyms7();
  var tmp0_elvis_lhs = isInterface(_this__u8e3s4, UIFocusable) ? _this__u8e3s4 : null;
  return tmp0_elvis_lhs == null ? get__focusable(_this__u8e3s4) : tmp0_elvis_lhs;
}
function makeFocusable(_this__u8e3s4, tabIndex, onChanged) {
  tabIndex = tabIndex === VOID ? 0 : tabIndex;
  var tmp;
  if (onChanged === VOID) {
    tmp = makeFocusable$lambda;
  } else {
    tmp = onChanged;
  }
  onChanged = tmp;
  _init_properties_UIFocusManager_kt__odyms7();
  set_focusable(_this__u8e3s4, new makeFocusable$2(tabIndex, _this__u8e3s4, onChanged));
  return ensureNotNull(get_focusable(_this__u8e3s4));
}
function _focusable$delegate$lambda() {
  _init_properties_UIFocusManager_kt__odyms7();
  return null;
}
function uiFocusManager$delegate$lambda($this$PropertyThis) {
  _init_properties_UIFocusManager_kt__odyms7();
  return new UIFocusManager($this$PropertyThis);
}
function makeFocusable$lambda(it) {
  _init_properties_UIFocusManager_kt__odyms7();
  return Unit_instance;
}
function _focusable$factory() {
  return getPropertyCallableRef('_focusable', 1, KMutableProperty1, function (receiver) {
    return get__focusable(receiver);
  }, function (receiver, value) {
    return set__focusable(receiver, value);
  });
}
function _focusable$factory_0() {
  return getPropertyCallableRef('_focusable', 1, KMutableProperty1, function (receiver) {
    return get__focusable(receiver);
  }, function (receiver, value) {
    return set__focusable(receiver, value);
  });
}
function uiFocusManager$factory() {
  return getPropertyCallableRef('uiFocusManager', 1, KProperty1, function (receiver) {
    return get_uiFocusManager(receiver);
  }, null);
}
var properties_initialized_UIFocusManager_kt_a5rjit;
function _init_properties_UIFocusManager_kt__odyms7() {
  if (!properties_initialized_UIFocusManager_kt_a5rjit) {
    properties_initialized_UIFocusManager_kt_a5rjit = true;
    // Inline function 'korlibs.datastructure.extraProperty' call
    var default_0 = _focusable$delegate$lambda;
    _focusable$delegate = new Property(null, default_0);
    uiFocusManager$delegate = new PropertyThis(VOID, uiFocusManager$delegate$lambda);
  }
}
function UIIcons$CROSS$lambda($this$createIcon) {
  var padding = 8;
  // Inline function 'korlibs.image.vector.Context2d.stroke' call
  var paint = new RGBA(Colors_getInstance().g4r_1);
  var lineCap = $this$createIcon.p5u();
  var lineJoin = $this$createIcon.q5u();
  var miterLimit = $this$createIcon.r5u();
  var lineDash = $this$createIcon.s5u();
  var lineDashOffset = $this$createIcon.t5u();
  if (true) {
    $this$createIcon.b5t();
  }
  // Inline function 'korlibs.korge.ui.UIIcons.CROSS.<anonymous>.<anonymous>' call
  $this$createIcon.l4a(Vector2D.k2l(padding, padding), Vector2D.k2l(32 - padding | 0, 32 - padding | 0));
  $this$createIcon.l4a(Vector2D.k2l(32 - padding | 0, padding), Vector2D.k2l(padding, 32 - padding | 0));
  // Inline function 'korlibs.image.vector.Context2d.keep' call
  $this$createIcon.u5u();
  try {
    // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
    $this$createIcon.v5u(numberToDouble(4.0));
    $this$createIcon.w5u(lineCap);
    $this$createIcon.x5u(lineJoin);
    $this$createIcon.y5u(numberToDouble(miterLimit));
    $this$createIcon.z5u(lineDash);
    $this$createIcon.a5v(numberToDouble(lineDashOffset));
    $this$createIcon.b5v(paint);
  }finally {
    $this$createIcon.c5v();
  }
  return Unit_instance;
}
var UIIcons_instance;
function UIIcons_getInstance() {
  if (UIIcons_instance === VOID)
    new UIIcons();
  return UIIcons_instance;
}
function _interpolate$ref_14() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function UIMaterialLayer$Highlights$addHighlight$lambda(this$0) {
  return function (it) {
    this$0.ndg_1.hbh();
    return Unit_instance;
  };
}
function _interpolate$ref_15() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_16() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function UIMaterialLayer$Highlights$removeHighlight$lambda(this$0) {
  return function (it) {
    this$0.ndg_1.hbh();
    return Unit_instance;
  };
}
function UIMaterialLayer$Highlights$removeHighlight$lambda_0(this$0, $highlight) {
  return function () {
    this$0.odg_1.n3($highlight);
    return Unit_instance;
  };
}
function _get_computedRadius__cigsus($this) {
  var tmp140_safe_receiver = $this.ucy_1;
  var tmp;
  if (tmp140_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.comparisons.minOf' call
    var a = $this.j2y();
    var b = $this.k2y();
    var tmp$ret$0 = Math.min(a, b);
    tmp = tmp140_safe_receiver.c2t(tmp$ret$0 / 2.0);
  }
  var tmp0_elvis_lhs = tmp;
  var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? $this.tcy_1 : tmp0_elvis_lhs;
  return tmp1_elvis_lhs == null ? Companion_getInstance_36().t2s_1 : tmp1_elvis_lhs;
}
function radiusRatio$factory($b0) {
  return getPropertyCallableRef('radiusRatio', 0, KMutableProperty0, function () {
    return $b0.jf4_1;
  }, function (value) {
    $b0.jf4_1 = value;
    return Unit_instance;
  });
}
function radiusRatio$factory_0($b0) {
  return getPropertyCallableRef('radiusRatio', 0, KMutableProperty0, function () {
    return $b0.jf4_1;
  }, function (value) {
    $b0.jf4_1 = value;
    return Unit_instance;
  });
}
function alpha$factory_3($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.kf4_1;
  }, function (value) {
    $b0.kf4_1 = value;
    return Unit_instance;
  });
}
function current$factory() {
  return getPropertyCallableRef('current', 1, KMutableProperty1, function (receiver) {
    return receiver.sf7();
  }, function (receiver, value) {
    return receiver.tf7(value);
  });
}
function current$factory_0() {
  return getPropertyCallableRef('current', 1, KMutableProperty1, function (receiver) {
    return receiver.sf7();
  }, function (receiver, value) {
    return receiver.tf7(value);
  });
}
function maximum$factory() {
  return getPropertyCallableRef('maximum', 1, KMutableProperty1, function (receiver) {
    return receiver.uf7();
  }, function (receiver, value) {
    return receiver.vf7(value);
  });
}
function maximum$factory_0() {
  return getPropertyCallableRef('maximum', 1, KMutableProperty1, function (receiver) {
    return receiver.uf7();
  }, function (receiver, value) {
    return receiver.vf7(value);
  });
}
function UIObservableProperty$getDisplayValue$lambda(this$0) {
  return function () {
    return this$0.b2();
  };
}
function toUI(_this__u8e3s4) {
  var tmp = toUI$lambda(_this__u8e3s4);
  return UIProperty(tmp, toUI$lambda_0(_this__u8e3s4));
}
function UIProperty(set, get) {
  return new UIProperty$1(set, get);
}
function toUI$lambda($this_toUI) {
  return function (it) {
    $this_toUI.set(it);
    return Unit_instance;
  };
}
function toUI$lambda_0($this_toUI) {
  return function () {
    return $this_toUI.get();
  };
}
function UIPropertyNumberRow$lambda($editableNumber) {
  return function () {
    return $editableNumber.uet_1;
  };
}
function UIPropertyNumberRow$lambda_0($props) {
  return function (it) {
    $props.seq(it.uet_1, false);
    return Unit_instance;
  };
}
function UIPropertyNumberRow$lambda_1($editableNumber) {
  return function (prop, value) {
    var tmp;
    if (!$editableNumber.qf0()) {
      $editableNumber.hex(value);
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function UIEditableNumberPropsList(mut, min, max, decimals, clamped) {
  min = min === VOID ? 0.0 : min;
  max = max === VOID ? 1.0 : max;
  decimals = decimals === VOID ? 2 : decimals;
  clamped = clamped === VOID ? true : clamped;
  // Inline function 'kotlin.collections.toTypedArray' call
  // Inline function 'kotlin.collections.map' call
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(mut.length);
  var inductionVariable = 0;
  var last = mut.length;
  while (inductionVariable < last) {
    var item = mut[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'korlibs.korge.ui.UIEditableNumberPropsList.<anonymous>' call
    var tmp$ret$0 = new UIEditableNumberProps(toUI(item), min, max, decimals, clamped);
    destination.f(tmp$ret$0);
  }
  return copyToArray(destination);
}
function showScrollBar($this) {
  $this.sdw_1.yfd_1.kbj($this.edx_1);
  $this.rdw_1.yfd_1.kbj($this.edx_1);
  $this.cdx_1 = get_fastSeconds(0);
}
function UIScrollable$lambda(this$0) {
  return function (it) {
    this$0.odw_1 = false;
    showScrollBar(this$0);
    var axisY = !this$0.sdw_1.yfe() ? this$0.rdw_1 : !this$0.rdw_1.yfe() ? this$0.sdw_1 : it.obq() ? this$0.sdw_1 : this$0.rdw_1;
    var axisX = it.obq() ? this$0.rdw_1 : this$0.sdw_1;
    var tmp;
    if (axisX.yfe()) {
      axisX.bff(axisX.q1q() + it.mbq() * (axisY.j1() / 16.0));
      var tmp_0;
      if (!(it.mbq() === 0.0)) {
        axisX.efe_1 = 0.0;
        tmp_0 = Unit_instance;
      }
      tmp = tmp_0;
    }
    var tmp_1;
    if (axisY.yfe()) {
      axisY.bff(axisY.q1q() + it.nbq() * (axisY.j1() / 16.0));
      var tmp_2;
      if (!(it.nbq() === 0.0)) {
        axisY.efe_1 = 0.0;
        tmp_2 = Unit_instance;
      }
      tmp_1 = tmp_2;
    }
    it.kbq();
    this$0.hbh();
    return Unit_instance;
  };
}
function UIScrollable$lambda_0(this$0) {
  return function (it) {
    return it ? 1.0 : this$0.edx_1;
  };
}
function UIScrollable$lambda_1($info, $startScrollBarPos) {
  return function ($this$onMouseDrag, it) {
    var tmp;
    if (!$info.yfe()) {
      return Unit_instance;
    }
    var dxy = $info.gfe() ? it.vbm() : it.xbm();
    var tmp_0;
    if (it.gbm_1) {
      $startScrollBarPos._v = $info.ife();
      tmp_0 = Unit_instance;
    }
    $info.bff(clamp_1($info.cff($startScrollBarPos._v + dxy), 0.0, $info.zfe()));
    return Unit_instance;
  };
}
function UIScrollable$lambda_2(this$0, $dragging) {
  return function ($this$onMouseDrag, it) {
    this$0.odw_1 = true;
    var tmp;
    if (it.gbm_1) {
      showScrollBar(this$0);
      $dragging._v = true;
      var indexedObject = this$0.tdw_1;
      var inductionVariable = 0;
      var last = indexedObject.length;
      $l$loop: while (inductionVariable < last) {
        var info = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (!info.yfe() || !this$0.gdx_1)
          continue $l$loop;
        info.ffe_1 = info.q1q();
        info.efe_1 = 0.0;
      }
      tmp = Unit_instance;
    }
    var indexedObject_0 = this$0.tdw_1;
    var inductionVariable_0 = 0;
    var last_0 = indexedObject_0.length;
    $l$loop_0: while (inductionVariable_0 < last_0) {
      var info_0 = indexedObject_0[inductionVariable_0];
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      if (!info_0.yfe() || !this$0.gdx_1)
        continue $l$loop_0;
      // Inline function 'kotlin.math.absoluteValue' call
      var this_0 = info_0.efe_1;
      if (Math.abs(this_0) < 1.0E-4) {
        info_0.efe_1 = 0.0;
      }
    }
    var indexedObject_1 = this$0.tdw_1;
    var inductionVariable_1 = 0;
    var last_1 = indexedObject_1.length;
    $l$loop_1: while (inductionVariable_1 < last_1) {
      var info_1 = indexedObject_1[inductionVariable_1];
      inductionVariable_1 = inductionVariable_1 + 1 | 0;
      if (!info_1.yfe() || !this$0.gdx_1)
        continue $l$loop_1;
      var localDXY = info_1.gfe() ? it.vbm() : it.xbm();
      info_1.bff(info_1.ffe_1 - localDXY);
      if (it.hbm_1) {
        $dragging._v = false;
        info_1.efe_1 = 300.0;
        var elapsedTime = it.ubm();
        info_1.efe_1 = -(localDXY * 1.1) / get_seconds(elapsedTime);
      }
    }
    return Unit_instance;
  };
}
function UIScrollable$lambda_3($dragging) {
  return function ($this$addFastUpdater, it) {
    var tmp;
    if (_FastDuration___get_milliseconds__impl__ffr17u(it.p1w_1) === 0.0) {
      return Unit_instance;
    }
    var this_0 = $this$addFastUpdater.tdw_1;
    var n = 0;
    while (n < this_0.length) {
      // Inline function 'korlibs.korge.ui.UIScrollable.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var info = this_0[_unary__edvuaz];
      info.yfd_1.car(info.yfe());
      // Inline function 'kotlin.math.max' call
      var a = info.ofe();
      var tmp$ret$0 = Math.max(a, 10.0);
      info.lfe(tmp$ret$0);
      info.jfe(info.dff(info.q1q()));
      // Inline function 'kotlin.math.absoluteValue' call
      var this_1 = info.efe_1;
      if (Math.abs(this_1) <= 1.0) {
        info.efe_1 = 0.0;
      }
      if (!(info.efe_1 === 0.0)) {
        var oldScrollPos = info.q1q();
        info.bff(info.q1q() + info.efe_1 * _FastDuration___get_seconds__impl__4kvvav(it.p1w_1));
        if (oldScrollPos === info.q1q()) {
          info.efe_1 = 0.0;
        }
      } else {
        if (!$dragging._v && (info.q1q() < 0.0 || info.q1q() > info.zfe())) {
          var destScrollPos = info.q1q() < 0.0 ? 0.0 : info.zfe();
          // Inline function 'kotlin.math.absoluteValue' call
          var this_2 = destScrollPos - info.q1q();
          if (Math.abs(this_2) < 0.1) {
            info.bff(destScrollPos);
          } else {
            info.bff(interpolate_1(toRatio(0.5 * (_FastDuration___get_seconds__impl__4kvvav(it.p1w_1) * 10.0)), info.q1q(), destScrollPos));
          }
        }
        if (!$dragging._v && $this$addFastUpdater.ddx_1) {
          if (FastDuration__compareTo_impl_4fhnj7($this$addFastUpdater.cdx_1, get_fastSeconds(1)) >= 0) {
            var _receiver__tnumb7 = info.yfd_1;
            _receiver__tnumb7.ubh(_receiver__tnumb7.tbh() * 0.9);
          } else {
            $this$addFastUpdater.cdx_1 = FastDuration__plus_impl_q1enab($this$addFastUpdater.cdx_1, it.p1w_1);
          }
        }
      }
    }
    return Unit_instance;
  };
}
function UIScrollable$lambda_4($this$addFixedUpdater) {
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var this_0 = $this$addFixedUpdater.tdw_1;
  var n = 0;
  while (n < this_0.length) {
    // Inline function 'korlibs.korge.ui.UIScrollable.<anonymous>.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var _receiver__tnumb7 = this_0[_unary__edvuaz];
    _receiver__tnumb7.efe_1 = _receiver__tnumb7.efe_1 * $this$addFixedUpdater.ydw_1;
  }
  return Unit_instance;
}
function position$factory($b0) {
  return getPropertyCallableRef('position', 0, KMutableProperty0, function () {
    return $b0.q1q();
  }, function (value) {
    return $b0.bff(value);
  });
}
function positionRatio$factory($b0) {
  return getPropertyCallableRef('positionRatio', 0, KMutableProperty0, function () {
    return $b0.gff();
  }, function (value) {
    return $b0.fff(value);
  });
}
function position$factory_0($b0) {
  return getPropertyCallableRef('position', 0, KMutableProperty0, function () {
    return $b0.q1q();
  }, function (value) {
    return $b0.bff(value);
  });
}
function positionRatio$factory_0($b0) {
  return getPropertyCallableRef('positionRatio', 0, KMutableProperty0, function () {
    return $b0.gff();
  }, function (value) {
    return $b0.fff(value);
  });
}
function x$factory_0($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function y$factory_0($b0) {
  return getPropertyCallableRef('y', 0, KMutableProperty0, function () {
    return $b0.p4f();
  }, function (value) {
    return $b0.sbh(value);
  });
}
function x$factory_1($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function y$factory_1($b0) {
  return getPropertyCallableRef('y', 0, KMutableProperty0, function () {
    return $b0.p4f();
  }, function (value) {
    return $b0.sbh(value);
  });
}
function x$factory_2($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function y$factory_2($b0) {
  return getPropertyCallableRef('y', 0, KMutableProperty0, function () {
    return $b0.p4f();
  }, function (value) {
    return $b0.sbh(value);
  });
}
function scaledWidth$factory($b0) {
  return getPropertyCallableRef('scaledWidth', 0, KMutableProperty0, function () {
    return $b0.kcc();
  }, function (value) {
    return $b0.jcc(value);
  });
}
function scaledHeight$factory($b0) {
  return getPropertyCallableRef('scaledHeight', 0, KMutableProperty0, function () {
    return $b0.mcc();
  }, function (value) {
    return $b0.lcc(value);
  });
}
function x$factory_3($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function y$factory_3($b0) {
  return getPropertyCallableRef('y', 0, KMutableProperty0, function () {
    return $b0.p4f();
  }, function (value) {
    return $b0.sbh(value);
  });
}
function scrollBarPos$factory() {
  return getPropertyCallableRef('scrollBarPos', 1, KMutableProperty1, function (receiver) {
    return receiver.ife();
  }, function (receiver, value) {
    return receiver.hfe(value);
  });
}
function scrollBarPos$factory_0() {
  return getPropertyCallableRef('scrollBarPos', 1, KMutableProperty1, function (receiver) {
    return receiver.ife();
  }, function (receiver, value) {
    return receiver.hfe(value);
  });
}
function viewPos$factory() {
  return getPropertyCallableRef('viewPos', 1, KMutableProperty1, function (receiver) {
    return receiver.kfe();
  }, function (receiver, value) {
    return receiver.jfe(value);
  });
}
function viewPos$factory_0() {
  return getPropertyCallableRef('viewPos', 1, KMutableProperty1, function (receiver) {
    return receiver.kfe();
  }, function (receiver, value) {
    return receiver.jfe(value);
  });
}
function viewScaledSize$factory() {
  return getPropertyCallableRef('viewScaledSize', 1, KMutableProperty1, function (receiver) {
    return receiver.mfe();
  }, function (receiver, value) {
    return receiver.lfe(value);
  });
}
function viewScaledSize$factory_0() {
  return getPropertyCallableRef('viewScaledSize', 1, KMutableProperty1, function (receiver) {
    return receiver.mfe();
  }, function (receiver, value) {
    return receiver.lfe(value);
  });
}
function containerPos$factory() {
  return getPropertyCallableRef('containerPos', 1, KMutableProperty1, function (receiver) {
    return receiver.rfe();
  }, function (receiver, value) {
    return receiver.qfe(value);
  });
}
function containerPos$factory_0() {
  return getPropertyCallableRef('containerPos', 1, KMutableProperty1, function (receiver) {
    return receiver.rfe();
  }, function (receiver, value) {
    return receiver.qfe(value);
  });
}
function *_generator_invoke__zhh2q8_40($this, $completion) {
  var tmp = $this.yfh_1($this.zfh_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function UISlider$thumb$slambda$lambda($over, $events) {
  return function ($this$onNextFrame, it) {
    $over($events);
    return Unit_instance;
  };
}
function *_generator_invoke__zhh2q8_41($this, $completion) {
  var tmp = $this.afi_1($this.bfi_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
var Companion_instance_23;
function Companion_getInstance_56() {
  if (Companion_instance_23 === VOID)
    new Companion_8();
  return Companion_instance_23;
}
function updateThumbFromMouse($this, it) {
  var cp = $this.jbq(it.bbj_1);
  var ratio = _Ratio___get_clamped__impl__cvqpjt(_Ratio___init__impl__9mwvn2(cp.i2h_1, $this.j2y()));
  _set_ratio__k0zb16($this, ratio);
  readjust($this);
}
function _get_realDecimalPlaces__gxvxzi($this) {
  var tmp0_elvis_lhs = $this.qfl_1;
  return tmp0_elvis_lhs == null ? Companion_getInstance_56().jfi($this.mfl_1) : tmp0_elvis_lhs;
}
function _get_nmarks1__n61rbs($this) {
  // Inline function 'kotlin.comparisons.minOf' call
  var a = toIntCeil($this.j2y() / 4) + 1 | 0;
  var b = toIntCeil(($this.lfl_1 - $this.kfl_1) / $this.mfl_1);
  return Math.min(a, b);
}
function _set_ratio__k0zb16($this, value) {
  $this.hex(Ratio__convertToRange_impl_8a60wd(value, $this.kfl_1, $this.lfl_1));
}
function _get_ratio__c055ga($this) {
  return toRatio(convertRangeClamped($this.nfl_1, $this.kfl_1, $this.lfl_1, 0.0, 1.0));
}
function showTooltip($this, show) {
  if (show) {
    updateTooltip$default($this);
  } else {
    updateTooltip($this, null);
  }
}
function updateTooltip($this, value) {
  if (value == null) {
    var tmp148_safe_receiver = $this.sfl_1;
    if (tmp148_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ui.UISlider.updateTooltip.<anonymous>' call
      var tmp149_safe_receiver = $this.tfl_1;
      var tmp;
      if (tmp149_safe_receiver == null) {
        tmp = null;
      } else {
        tmp149_safe_receiver.xfl(tmp148_safe_receiver);
        tmp = Unit_instance;
      }
    }
  } else {
    var maxNumber = ($this.kfl_1 < 0.0 ? '-' : '') + repeat('9', numberOfDigits(toIntCeil($this.lfl_1), 10)) + '.' + repeat('9', _get_realDecimalPlaces__gxvxzi($this));
    var tmp_0 = $this;
    var tmp150_safe_receiver = $this.tfl_1;
    tmp_0.sfl_1 = tmp150_safe_receiver == null ? null : tmp150_safe_receiver.wfl($this.ifl_1, $this.rfl_1(value), $this.rfl_1(maxNumber));
  }
}
function updateTooltip$default($this, value, $super) {
  value = value === VOID ? niceStr($this.nfl_1, _get_realDecimalPlaces__gxvxzi($this), true) : value;
  return updateTooltip($this, value);
}
function readjust($this) {
  var ratio = _get_ratio__c055ga($this);
  $this.ffl_1.fek($this.j2y());
  // Inline function 'korlibs.math.interpolation.times' call
  var tmp$ret$0 = $this.j2y() * _Ratio___get_value__impl__57cli6(ratio);
  $this.gfl_1.fek(tmp$ret$0);
  // Inline function 'korlibs.math.interpolation.times' call
  var tmp$ret$1 = $this.j2y() * _Ratio___get_value__impl__57cli6(ratio);
  $this.ifl_1.rbh(tmp$ret$1);
  if ($this.pfl_1 === true) {
    showTooltip($this, true);
  }
  updatedMarks($this);
}
function updatedMarks($this) {
  if (!$this.ofl_1) {
    $this.hfl_1.nca();
    return Unit_instance;
  }
  var nmarks1 = _get_nmarks1__n61rbs($this);
  var nmarks = nmarks1 + 1 | 0;
  if (!($this.hfl_1.eca() === nmarks)) {
    $this.hfl_1.nca();
    var viewWidth = $this.j2y();
    var inductionVariable = 0;
    if (inductionVariable <= nmarks1)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio = _Ratio___init__impl__9mwvn2_0(n, nmarks1);
        // Inline function 'korlibs.korge.ui.uiMaterialLayer' call
        var this_0 = $this.hfl_1;
        var size_0 = get_UI_DEFAULT_SIZE();
        // Inline function 'kotlin.apply' call
        var this_1 = addTo(UIMaterialLayer.ud2(size_0), this_0);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.ui.UISlider.updatedMarks.<anonymous>' call
        anchor(this_1, Companion_getInstance_32().f2h());
        // Inline function 'korlibs.korge.view.size' call
        size(this_1, numberToDouble(2), numberToDouble(2));
        this_1.ecz(0.0);
        this_1.wd8(get_uiSelectedColor(get_styles($this)));
        // Inline function 'korlibs.math.geom.Companion.invoke' call
        Companion_getInstance_36();
        var tmp$ret$1 = new RectCorners(numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0));
        this_1.qf4(tmp$ret$1);
        // Inline function 'korlibs.korge.view.xy' call
        var x = Ratio__convertToRange_impl_8a60wd(ratio, 4.0, viewWidth - 4.0);
        xy_0(this_1, numberToDouble(x), numberToDouble(0));
      }
       while (!(n === nmarks1));
  }
  var inductionVariable_0 = 0;
  if (inductionVariable_0 <= nmarks1)
    do {
      var n_0 = inductionVariable_0;
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      var markRatio = _Ratio___init__impl__9mwvn2_0(n_0, nmarks1);
      var include = Ratio__compareTo_impl_z2ienc(markRatio, _get_ratio__c055ga($this)) >= 0;
      var child = $this.hfl_1.lca(n_0);
      var tmp151_safe_receiver = child instanceof UIMaterialLayer ? child : null;
      if (tmp151_safe_receiver == null)
        null;
      else {
        tmp151_safe_receiver.wd8(include ? get_uiSelectedColor(get_styles($this)) : get_uiBackgroundColor(get_styles($this)));
      }
    }
     while (!(n_0 === nmarks1));
}
function UISlider$thumb$lambda($$this$uiMaterialLayer) {
  return function (it) {
    $$this$uiMaterialLayer.ld9();
    return Unit_instance;
  };
}
function UISlider$thumb$lambda_0($$this$uiMaterialLayer) {
  return function (it) {
    $$this$uiMaterialLayer.nf4(Vector2D.k2h(0.5, 0.5), true, 1.5, 0.25);
    return Unit_instance;
  };
}
function UISlider$thumb$slambda_1($component, $out) {
  var i = new UISlider$thumb$slambda($component, $out);
  var l = function (events, $completion) {
    return i.tbo(events, $completion);
  };
  l.$arity = 1;
  return l;
}
function UISlider$thumb$lambda$slambda_1($handler, $it) {
  var i = new UISlider$thumb$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UISlider$thumb$lambda_1($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UISlider$thumb$lambda$slambda_1($handler, it));
    return Unit_instance;
  };
}
function UISlider$thumb$slambda_2($component, $this, $over) {
  var i = new UISlider$thumb$slambda_0($component, $this, $over);
  var l = function (events, $completion) {
    return i.tbo(events, $completion);
  };
  l.$arity = 1;
  return l;
}
function UISlider$thumb$lambda$slambda_2($handler, $it) {
  var i = new UISlider$thumb$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UISlider$thumb$lambda_2($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UISlider$thumb$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function UISlider$lambda(this$0) {
  return function (it) {
    var tmp;
    if (it.gbm_1) {
      this$0.ifl_1.nf4(Vector2D.k2h(0.5, 0.5), true, 1.5, 0.25);
      tmp = Unit_instance;
    }
    updateThumbFromMouse(this$0, it.tbm());
    var tmp_0;
    if (it.hbm_1) {
      var tmp_1;
      if (!(this$0.pfl_1 === true)) {
        showTooltip(this$0, false);
        tmp_1 = Unit_instance;
      }
      tmp_0 = tmp_1;
    }
    return Unit_instance;
  };
}
function UISlider$textTransformer$lambda(it) {
  return it;
}
function out$factory_2() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
function over$factory_4() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
var Companion_instance_24;
function Companion_getInstance_57() {
  if (Companion_instance_24 === VOID)
    new Companion_9();
  return Companion_instance_24;
}
function *_generator_invoke__zhh2q8_42($this, $completion) {
  var tmp = $this.efm_1($this.ffm_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_43($this, $completion) {
  var tmp = $this.gfm_1($this.hfm_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_44($this, $completion) {
  var tmp = $this.ifm_1($this.jfm_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_45($this, $completion) {
  var tmp = $this.kfm_1($this.lfm_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
var Companion_instance_25;
function Companion_getInstance_58() {
  if (Companion_instance_25 === VOID)
    new Companion_10();
  return Companion_instance_25;
}
function UIText$bover$delegate$lambda(this$0) {
  return function (it) {
    this$0.ic7();
    return Unit_instance;
  };
}
function UIText$bpressing$delegate$lambda(this$0) {
  return function (it) {
    this$0.ic7();
    return Unit_instance;
  };
}
function UIText$slambda_3(this$0) {
  var i = new UIText$slambda(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIText$lambda$slambda_3($handler, $it) {
  var i = new UIText$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIText$lambda($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIText$lambda$slambda_3($handler, it));
    return Unit_instance;
  };
}
function UIText$slambda_4(this$0) {
  var i = new UIText$slambda_0(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIText$lambda$slambda_4($handler, $it) {
  var i = new UIText$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIText$lambda_0($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIText$lambda$slambda_4($handler, it));
    return Unit_instance;
  };
}
function UIText$slambda_5(this$0) {
  var i = new UIText$slambda_1(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIText$lambda$slambda_5($handler, $it) {
  var i = new UIText$lambda$slambda_1($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIText$lambda_1($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIText$lambda$slambda_5($handler, it));
    return Unit_instance;
  };
}
function UIText$slambda_6(this$0) {
  var i = new UIText$slambda_2(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIText$lambda$slambda_6($handler, $it) {
  var i = new UIText$lambda$slambda_2($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIText$lambda_2($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately_0(tmp, UIText$lambda$slambda_6($handler, it));
    return Unit_instance;
  };
}
function text$factory_0($b0) {
  return getPropertyCallableRef('text', 0, KMutableProperty0, function () {
    return $b0.o2f();
  }, function (value) {
    return $b0.bcg(value);
  });
}
function over$factory_5() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
function out$factory_3() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
function down$factory_2() {
  return getPropertyCallableRef('down', 1, KProperty1, function (receiver) {
    return receiver.cbi_1;
  }, null);
}
function upAnywhere$factory_1() {
  return getPropertyCallableRef('upAnywhere', 1, KProperty1, function (receiver) {
    return receiver.jbi_1;
  }, null);
}
function bover$factory() {
  return getPropertyCallableRef('bover', 1, KMutableProperty1, function (receiver) {
    return receiver.vfm();
  }, function (receiver, value) {
    return receiver.ufm(value);
  });
}
function bover$factory_0() {
  return getPropertyCallableRef('bover', 1, KMutableProperty1, function (receiver) {
    return receiver.vfm();
  }, function (receiver, value) {
    return receiver.ufm(value);
  });
}
function bpressing$factory() {
  return getPropertyCallableRef('bpressing', 1, KMutableProperty1, function (receiver) {
    return receiver.xfm();
  }, function (receiver, value) {
    return receiver.wfm(value);
  });
}
function bpressing$factory_0() {
  return getPropertyCallableRef('bpressing', 1, KMutableProperty1, function (receiver) {
    return receiver.xfm();
  }, function (receiver, value) {
    return receiver.wfm(value);
  });
}
function text$factory_1() {
  return getPropertyCallableRef('text', 1, KMutableProperty1, function (receiver) {
    return receiver.o2f();
  }, function (receiver, value) {
    return receiver.bcg(value);
  });
}
function text$factory_2() {
  return getPropertyCallableRef('text', 1, KMutableProperty1, function (receiver) {
    return receiver.o2f();
  }, function (receiver, value) {
    return receiver.bcg(value);
  });
}
function set_uiTextInputBackgroundRender(_this__u8e3s4, _set____db54di) {
  _init_properties_UITextInput_kt__kefnmf();
  uiTextInputBackgroundRender$delegate;
  var property = uiTextInputBackgroundRender$factory();
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, _set____db54di);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_uiTextInputBackgroundRender(_this__u8e3s4) {
  _init_properties_UITextInput_kt__kefnmf();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = uiTextInputBackgroundRender$delegate;
  var property = uiTextInputBackgroundRender$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0;
}
var uiTextInputBackgroundRender$delegate;
function UITextInput$bg$lambda($this$renderableView) {
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.UITextInput.bg.<anonymous>.<anonymous>' call
  get_uiTextInputBackgroundRender(get_styles($this$renderableView)).ade($this$renderableView);
  return Unit_instance;
}
function uiTextInputBackgroundRender$delegate$lambda($this$ViewRenderer) {
  _init_properties_UITextInput_kt__kefnmf();
  $this$ViewRenderer.ofp().aaj(new Rectangle(0.0, 0.0, $this$ViewRenderer.j2y(), $this$ViewRenderer.k2y()), Colors_getInstance().g4r_1);
  return Unit_instance;
}
function uiTextInputBackgroundRender$factory() {
  return getPropertyCallableRef('uiTextInputBackgroundRender', 1, KMutableProperty1, function (receiver) {
    return get_uiTextInputBackgroundRender(receiver);
  }, function (receiver, value) {
    return set_uiTextInputBackgroundRender(receiver, value);
  });
}
function uiTextInputBackgroundRender$factory_0() {
  return getPropertyCallableRef('uiTextInputBackgroundRender', 1, KMutableProperty1, function (receiver) {
    return get_uiTextInputBackgroundRender(receiver);
  }, function (receiver, value) {
    return set_uiTextInputBackgroundRender(receiver, value);
  });
}
function viewRenderer$factory($b0) {
  return getPropertyCallableRef('viewRenderer', 0, KMutableProperty0, function () {
    return $b0.acn_1;
  }, function (value) {
    $b0.acn_1 = value;
    return Unit_instance;
  });
}
function text$factory_3($b0) {
  return getPropertyCallableRef('text', 0, KMutableProperty0, function () {
    return $b0.o2f();
  }, function (value) {
    return $b0.bcg(value);
  });
}
function textSize$factory_1($b0) {
  return getPropertyCallableRef('textSize', 0, KMutableProperty0, function () {
    return $b0.ycp();
  }, function (value) {
    return $b0.xcp(value);
  });
}
function font$factory($b0) {
  return getPropertyCallableRef('font', 0, KMutableProperty0, function () {
    return $b0.p5w();
  }, function (value) {
    return $b0.vcp(value);
  });
}
function onReturnPressed$factory($b0) {
  return getPropertyCallableRef('onReturnPressed', 0, KProperty0, function () {
    return $b0.icf_1;
  }, null);
}
function onEscPressed$factory($b0) {
  return getPropertyCallableRef('onEscPressed', 0, KProperty0, function () {
    return $b0.hcf_1;
  }, null);
}
function onFocusLost$factory($b0) {
  return getPropertyCallableRef('onFocusLost', 0, KProperty0, function () {
    return $b0.lcf_1;
  }, null);
}
function selectionRange$factory($b0) {
  return getPropertyCallableRef('selectionRange', 0, KMutableProperty0, function () {
    return $b0.acg();
  }, function (value) {
    return $b0.zcp(value);
  });
}
function selectionStart$factory($b0) {
  return getPropertyCallableRef('selectionStart', 0, KMutableProperty0, function () {
    return $b0.icg();
  }, function (value) {
    return $b0.ock(value);
  });
}
function selectionEnd$factory($b0) {
  return getPropertyCallableRef('selectionEnd', 0, KMutableProperty0, function () {
    return $b0.mck();
  }, function (value) {
    return $b0.fcn(value);
  });
}
function selectionLength$factory($b0) {
  return getPropertyCallableRef('selectionLength', 0, KProperty0, function () {
    return $b0.pck();
  }, null);
}
function text$factory_4() {
  return getPropertyCallableRef('text', 1, KMutableProperty1, function (receiver) {
    return receiver.o2f();
  }, function (receiver, value) {
    return receiver.bcg(value);
  });
}
function text$factory_5() {
  return getPropertyCallableRef('text', 1, KMutableProperty1, function (receiver) {
    return receiver.o2f();
  }, function (receiver, value) {
    return receiver.bcg(value);
  });
}
function onReturnPressed$factory_0() {
  return getPropertyCallableRef('onReturnPressed', 1, KProperty1, function (receiver) {
    return receiver.nf0();
  }, null);
}
function onEscPressed$factory_0() {
  return getPropertyCallableRef('onEscPressed', 1, KProperty1, function (receiver) {
    return receiver.of0();
  }, null);
}
function onFocusLost$factory_0() {
  return getPropertyCallableRef('onFocusLost', 1, KProperty1, function (receiver) {
    return receiver.pf0();
  }, null);
}
var properties_initialized_UITextInput_kt_7ksi6x;
function _init_properties_UITextInput_kt__kefnmf() {
  if (!properties_initialized_UITextInput_kt_7ksi6x) {
    properties_initialized_UITextInput_kt_7ksi6x = true;
    var tmp = uiTextInputBackgroundRender$delegate$lambda;
    uiTextInputBackgroundRender$delegate = new ViewStyle(new sam$korlibs_korge_view_ViewRenderer$0_1(tmp));
  }
}
function _interpolateFloat$ref_0() {
  var l = function (p0, p1, p2) {
    return _interpolateFloat(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolateFloat';
  return l;
}
function UITooltipContainer$appear$lambda(this$0) {
  return function () {
    this$0.yfr_1.ubh(0.0);
    this$0.yfr_1.car(true);
    this$0.dfs_1 = null;
    var tmp = get_simpleAnimator(this$0.yfr_1);
    // Inline function 'korlibs.korge.tween.get' call
    var this_0 = alphaF$factory_0(this$0.yfr_1);
    var tmp$ret$0 = new V2(this_0, 0.0, 1.0, _interpolateFloat$ref_0(), true);
    tween(tmp, [tmp$ret$0], this$0.afs_1);
    return Unit_instance;
  };
}
function tooltip(_this__u8e3s4, tooltips, text) {
  // Inline function 'korlibs.korge.input.onOutOnOver' call
  var out = tooltip$lambda(tooltips);
  var over = tooltip$lambda_0(tooltips, _this__u8e3s4, text);
  var component = {_v: null};
  // Inline function 'korlibs.korge.input.onOut' call
  var handler = tooltip$slambda_1(component, out);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop = out$factory_4();
  var tmp73_safe_receiver = _this__u8e3s4 == null ? null : get_mouse(_this__u8e3s4);
  if (tmp73_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp = prop.get(tmp73_safe_receiver);
    tmp.v1x(tooltip$lambda_1(tmp73_safe_receiver, handler));
  }
  // Inline function 'korlibs.korge.input.onOver' call
  var handler_0 = tooltip$slambda_2(component, _this__u8e3s4, over);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_0 = over$factory_6();
  var tmp73_safe_receiver_0 = _this__u8e3s4 == null ? null : get_mouse(_this__u8e3s4);
  if (tmp73_safe_receiver_0 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_0 = prop_0.get(tmp73_safe_receiver_0);
    tmp_0.v1x(tooltip$lambda_2(tmp73_safe_receiver_0, handler_0));
  }
  return _this__u8e3s4;
}
function tooltip$lambda($tooltips) {
  return function (it) {
    $tooltips.ffs();
    return Unit_instance;
  };
}
function tooltip$lambda_0($tooltips, $this_tooltip, $text) {
  return function (it) {
    $tooltips.jfs($this_tooltip, $text);
    return Unit_instance;
  };
}
function tooltip$slambda_1($component, $out) {
  var i = new tooltip$slambda($component, $out);
  var l = function (events, $completion) {
    return i.tbo(events, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_46($this, $completion) {
  var tmp = $this.mfs_1($this.nfs_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function tooltip$lambda$slambda_1($handler, $it) {
  var i = new tooltip$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function tooltip$lambda_1($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, tooltip$lambda$slambda_1($handler, it));
    return Unit_instance;
  };
}
function tooltip$slambda$lambda($over, $events) {
  return function ($this$onNextFrame, it) {
    $over($events);
    return Unit_instance;
  };
}
function tooltip$slambda_2($component, $this, $over) {
  var i = new tooltip$slambda_0($component, $this, $over);
  var l = function (events, $completion) {
    return i.tbo(events, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_47($this, $completion) {
  var tmp = $this.rfs_1($this.sfs_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function tooltip$lambda$slambda_2($handler, $it) {
  var i = new tooltip$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function tooltip$lambda_2($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, tooltip$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function alphaF$factory_0($b0) {
  return getPropertyCallableRef('alphaF', 0, KMutableProperty0, function () {
    return $b0.tbh();
  }, function (value) {
    return $b0.ubh(value);
  });
}
function out$factory_4() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
function over$factory_6() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
function get_uiTooltipContainerMediatorNewKey() {
  _init_properties_UITooltipContainerMediatorNew_kt__3gm4fr();
  return uiTooltipContainerMediatorNewKey;
}
var uiTooltipContainerMediatorNewKey;
function get_uiTooltipContainerMediatorNew(_this__u8e3s4) {
  _init_properties_UITooltipContainerMediatorNew_kt__3gm4fr();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = uiTooltipContainerMediatorNew$delegate;
    var property = uiTooltipContainerMediatorNew$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var uiTooltipContainerMediatorNew$delegate;
var Companion_instance_26;
function Companion_getInstance_59() {
  return Companion_instance_26;
}
function UITooltipContainerMediatorNew$Tooltip$lambda($track, this$0) {
  return function () {
    var tmp;
    if ($track.pbj() == null) {
      this$0.ofv_1.xfl(this$0);
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function _interpolate$ref_17() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_18() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function get_closestUITooltipContainerMediatorNew(_this__u8e3s4) {
  _init_properties_UITooltipContainerMediatorNew_kt__3gm4fr();
  var tmp155_safe_receiver = _this__u8e3s4.o13();
  if ((tmp155_safe_receiver == null ? null : tmp155_safe_receiver.y13(get_uiTooltipContainerMediatorNewKey())) === true) {
    return get_uiTooltipContainerMediatorNew(_this__u8e3s4);
  } else {
    var tmp156_safe_receiver = _this__u8e3s4.w15();
    var tmp0_elvis_lhs = tmp156_safe_receiver == null ? null : get_closestUITooltipContainerMediatorNew(tmp156_safe_receiver);
    return tmp0_elvis_lhs == null ? get_uiTooltipContainerMediatorNew(_this__u8e3s4) : tmp0_elvis_lhs;
  }
}
function uiTooltipContainerMediatorNew$delegate$lambda($this$PropertyThis) {
  _init_properties_UITooltipContainerMediatorNew_kt__3gm4fr();
  return new UITooltipContainerMediatorNew($this$PropertyThis);
}
function uiTooltipContainerMediatorNew$factory() {
  return getPropertyCallableRef('uiTooltipContainerMediatorNew', 1, KProperty1, function (receiver) {
    return get_uiTooltipContainerMediatorNew(receiver);
  }, null);
}
function alpha$factory_4($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function alpha$factory_5($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
var properties_initialized_UITooltipContainerMediatorNew_kt_85kbud;
function _init_properties_UITooltipContainerMediatorNew_kt__3gm4fr() {
  if (!properties_initialized_UITooltipContainerMediatorNew_kt_85kbud) {
    properties_initialized_UITooltipContainerMediatorNew_kt_85kbud = true;
    uiTooltipContainerMediatorNewKey = 'uiTooltipContainerMediatorNew';
    var tmp = get_uiTooltipContainerMediatorNewKey();
    uiTooltipContainerMediatorNew$delegate = new PropertyThis(tmp, uiTooltipContainerMediatorNew$delegate$lambda);
  }
}
var Companion_instance_27;
function Companion_getInstance_60() {
  return Companion_instance_27;
}
function getIndexAtY($this, y) {
  var tmp159_safe_receiver = $this.qdt_1.qdx();
  var tmp0_elvis_lhs = tmp159_safe_receiver == null ? null : tmp159_safe_receiver;
  var index = y / (tmp0_elvis_lhs == null ? 20.0 : tmp0_elvis_lhs);
  return numberToInt(index);
}
function UIVerticalList$lambda($this$addFastUpdater, it) {
  $this$addFastUpdater.rdt();
  return Unit_instance;
}
function registerUISupportOnce($this) {
  if ($this.vc6_1)
    return Unit_instance;
  var tmp0_elvis_lhs = $this.pbj();
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var stage = tmp;
  $this.vc6_1 = true;
  if (equals(getExtra(stage, 'uiSupport'), true))
    return Unit_instance;
  setExtra(stage, 'uiSupport', true);
  // Inline function 'korlibs.korge.input.keys' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.UIView.registerUISupportOnce.<anonymous>' call
  get_keys(stage);
}
var Companion_instance_28;
function Companion_getInstance_61() {
  if (Companion_instance_28 === VOID)
    new Companion_13();
  return Companion_instance_28;
}
function set_uiBackgroundColor(_this__u8e3s4, _set____db54di) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  uiBackgroundColor$delegate;
  var property = uiBackgroundColor$factory();
  var value = new RGBA(_set____db54di);
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, value);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_uiBackgroundColor(_this__u8e3s4) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = uiBackgroundColor$delegate;
  var property = uiBackgroundColor$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0.v59_1;
}
var uiBackgroundColor$delegate;
function set_uiSelectedColor(_this__u8e3s4, _set____db54di) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  uiSelectedColor$delegate;
  var property = uiSelectedColor$factory();
  var value = new RGBA(_set____db54di);
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, value);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_uiSelectedColor(_this__u8e3s4) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = uiSelectedColor$delegate;
  var property = uiSelectedColor$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0.v59_1;
}
var uiSelectedColor$delegate;
function set_uiUnselectedColor(_this__u8e3s4, _set____db54di) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  uiUnselectedColor$delegate;
  var property = uiUnselectedColor$factory();
  var value = new RGBA(_set____db54di);
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, value);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_uiUnselectedColor(_this__u8e3s4) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = uiUnselectedColor$delegate;
  var property = uiUnselectedColor$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0.v59_1;
}
var uiUnselectedColor$delegate;
var uiProgressBarRenderer$delegate;
function set_uiCheckboxButtonRenderer(_this__u8e3s4, _set____db54di) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  uiCheckboxButtonRenderer$delegate;
  var property = uiCheckboxButtonRenderer$factory();
  if (_this__u8e3s4.mce_1 == null)
    _this__u8e3s4.mce_1 = linkedHashMapOf([]);
  // Inline function 'kotlin.collections.set' call
  var this_0 = ensureNotNull(_this__u8e3s4.mce_1);
  var key = property.callableName;
  this_0.r3(key, _set____db54di);
  _this__u8e3s4.oce();
  return Unit_instance;
}
function get_uiCheckboxButtonRenderer(_this__u8e3s4) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  // Inline function 'korlibs.korge.style.ViewStyle.getValue' call
  var this_0 = uiCheckboxButtonRenderer$delegate;
  var property = uiCheckboxButtonRenderer$factory_0();
  var tmp = _this__u8e3s4.qce(property, this_0.pce_1);
  var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : null;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = "Can't cast " + toString(property) + ' to T';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  return tmp_0;
}
var uiCheckboxButtonRenderer$delegate;
function uiSelectedColor(_this__u8e3s4, selected) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  return selected ? get_uiSelectedColor(_this__u8e3s4) : get_uiUnselectedColor(_this__u8e3s4);
}
function uiProgressBarRenderer$delegate$lambda($this$ViewStyle, it) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  var tmp161_width = $this$ViewStyle.j2y();
  var tmp162_height = $this$ViewStyle.k2y();
  // Inline function 'korlibs.math.geom.Companion.invoke' call
  Companion_getInstance_36();
  var tmp163_radius = new RectCorners(numberToDouble(3.0), numberToDouble(3.0), numberToDouble(3.0), numberToDouble(3.0));
  var tmp164_color = get_uiBackgroundColor(get_styles(it));
  materialRoundRect($this$ViewStyle, 0.0, 0.0, tmp161_width, tmp162_height, tmp164_color, tmp163_radius);
  // Inline function 'korlibs.math.interpolation.times' call
  var this_0 = $this$ViewStyle.j2y();
  var ratio = it.fg1();
  var tmp165_width = this_0 * _Ratio___get_value__impl__57cli6(ratio);
  var tmp166_height = $this$ViewStyle.k2y();
  // Inline function 'korlibs.math.geom.Companion.invoke' call
  Companion_getInstance_36();
  var tmp167_radius = new RectCorners(numberToDouble(3.0), numberToDouble(3.0), numberToDouble(3.0), numberToDouble(3.0));
  var tmp168_color = get_uiSelectedColor(get_styles(it));
  materialRoundRect($this$ViewStyle, 0.0, 0.0, tmp165_width, tmp166_height, tmp168_color, tmp167_radius);
  return Unit_instance;
}
function uiCheckboxButtonRenderer$delegate$lambda($this$ViewStyle, it) {
  _init_properties_UIViewStyles_kt__ksz6vv();
  var extraPad = -0.0;
  var extraPad2 = extraPad * 2;
  var styles = get_styles(it);
  var tmp169_x = 0.0 + extraPad;
  var tmp170_y = 0.0 + extraPad;
  var tmp171_width = $this$ViewStyle.k2y() - extraPad2;
  var tmp172_height = $this$ViewStyle.k2y() - extraPad2;
  // Inline function 'korlibs.math.geom.Companion.invoke' call
  Companion_getInstance_36();
  var corner = ($this$ViewStyle.k2y() - extraPad2) * 0.5;
  var tmp173_radius = new RectCorners(numberToDouble(corner), numberToDouble(corner), numberToDouble(corner), numberToDouble(corner));
  // Inline function 'kotlin.math.max' call
  var a = it.qdd_1;
  var b = it.vdd_1;
  var tmp$ret$1 = Math.max(a, b);
  var tmp174_color = interpolate(tmp$ret$1, Colors_getInstance().l4r_1, RGBA__withAd_impl_cralao(uiSelectedColor(styles, it.pdd_1 > 0.5), 0.3));
  materialRoundRect($this$ViewStyle, tmp169_x, tmp170_y, tmp171_width, tmp172_height, tmp174_color, tmp173_radius);
  // Inline function 'korlibs.korge.ui.Highlights.fastForEach' call
  // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
  // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var array = it.udd_1.odg_1.e14_1;
  var n = 0;
  while (n < array.length) {
    // Inline function 'korlibs.korge.ui.uiCheckboxButtonRenderer$delegate.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'korlibs.datastructure.get' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var it_0 = array[_unary__edvuaz];
    var tmp175_x = 0.0 + extraPad;
    var tmp176_y = 0.0 + extraPad;
    var tmp177_width = $this$ViewStyle.k2y() - extraPad2;
    var tmp178_height = $this$ViewStyle.k2y() - extraPad2;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var corner_0 = $this$ViewStyle.k2y() * 0.5;
    var tmp179_radius = new RectCorners(numberToDouble(corner_0), numberToDouble(corner_0), numberToDouble(corner_0), numberToDouble(corner_0));
    var tmp180_color = Colors_getInstance().l4r_1;
    var tmp181_highlightPos = it_0.if4_1;
    var tmp182_highlightRadius = it_0.jf4_1;
    var tmp183_highlightColor = RGBA__withAd_impl_cralao(get_uiSelectedColor(styles), it_0.kf4_1 * 0.7);
    materialRoundRect($this$ViewStyle, tmp175_x, tmp176_y, tmp177_width, tmp178_height, tmp180_color, tmp179_radius, VOID, VOID, VOID, tmp181_highlightPos, tmp182_highlightRadius, tmp183_highlightColor);
  }
  var tmp184_subject = it.jdd_1;
  if (equals(tmp184_subject, Companion_getInstance_57()) || equals(tmp184_subject, Companion_getInstance_54())) {
    var padding = 6.0;
    var padding2 = padding * 2;
    var tmp185_x = 0.0 + padding;
    var tmp186_y = 0.0 + padding;
    var tmp187_width = $this$ViewStyle.k2y() - padding2;
    var tmp188_height = $this$ViewStyle.k2y() - padding2;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp189_radius = new RectCorners(numberToDouble(4.0), numberToDouble(4.0), numberToDouble(4.0), numberToDouble(4.0));
    var tmp190_color = Colors_getInstance().l4r_1;
    var tmp191_borderColor = interpolate(it.pdd_1, get_uiUnselectedColor(styles), get_uiSelectedColor(styles));
    materialRoundRect($this$ViewStyle, tmp185_x, tmp186_y, tmp187_width, tmp188_height, tmp190_color, tmp189_radius, VOID, VOID, VOID, VOID, VOID, VOID, 2.0, tmp191_borderColor);
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var padding_0 = interpolate_1(toRatio(it.pdd_1), $this$ViewStyle.k2y(), 10.0);
    var padding2_0 = padding_0 * 2;
    var tmp192_x = 0.0 + padding_0;
    var tmp193_y = 0.0 + padding_0;
    var tmp194_width = $this$ViewStyle.k2y() - padding2_0;
    var tmp195_height = $this$ViewStyle.k2y() - padding2_0;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var tmp196_radius = new RectCorners(numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0));
    var tmp197_color = interpolate(it.pdd_1, Colors_getInstance().l4r_1, get_uiSelectedColor(styles));
    materialRoundRect($this$ViewStyle, tmp192_x, tmp193_y, tmp194_width, tmp195_height, tmp197_color, tmp196_radius);
  } else {
    var padding_1 = 6.0;
    var padding2_1 = padding_1 * 2;
    var tmp198_x = 0.0 + padding_1;
    var tmp199_y = 0.0 + padding_1;
    var tmp200_width = $this$ViewStyle.k2y() - padding2_1;
    var tmp201_height = $this$ViewStyle.k2y() - padding2_1;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_36();
    var corner_1 = ($this$ViewStyle.k2y() - padding2_1) * 0.5;
    var tmp202_radius = new RectCorners(numberToDouble(corner_1), numberToDouble(corner_1), numberToDouble(corner_1), numberToDouble(corner_1));
    var tmp203_borderColor = interpolate(it.pdd_1, get_uiUnselectedColor(styles), get_uiSelectedColor(styles));
    var tmp204_color = Colors_getInstance().l4r_1;
    var tmp205_highlightRadius = interpolate_1(toRatio(it.pdd_1), 0.0, 0.2);
    var tmp206_highlightPos = Vector2D.k2h(0.5, 0.5);
    var tmp207_highlightColor = get_uiSelectedColor(styles);
    materialRoundRect($this$ViewStyle, tmp198_x, tmp199_y, tmp200_width, tmp201_height, tmp204_color, tmp202_radius, VOID, VOID, VOID, tmp206_highlightPos, tmp205_highlightRadius, tmp207_highlightColor, 2.0, tmp203_borderColor);
  }
  return Unit_instance;
}
function uiBackgroundColor$factory() {
  return getPropertyCallableRef('uiBackgroundColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_uiBackgroundColor(receiver));
  }, function (receiver, value) {
    return set_uiBackgroundColor(receiver, value.v59_1);
  });
}
function uiBackgroundColor$factory_0() {
  return getPropertyCallableRef('uiBackgroundColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_uiBackgroundColor(receiver));
  }, function (receiver, value) {
    return set_uiBackgroundColor(receiver, value.v59_1);
  });
}
function uiSelectedColor$factory() {
  return getPropertyCallableRef('uiSelectedColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_uiSelectedColor(receiver));
  }, function (receiver, value) {
    return set_uiSelectedColor(receiver, value.v59_1);
  });
}
function uiSelectedColor$factory_0() {
  return getPropertyCallableRef('uiSelectedColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_uiSelectedColor(receiver));
  }, function (receiver, value) {
    return set_uiSelectedColor(receiver, value.v59_1);
  });
}
function uiUnselectedColor$factory() {
  return getPropertyCallableRef('uiUnselectedColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_uiUnselectedColor(receiver));
  }, function (receiver, value) {
    return set_uiUnselectedColor(receiver, value.v59_1);
  });
}
function uiUnselectedColor$factory_0() {
  return getPropertyCallableRef('uiUnselectedColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(get_uiUnselectedColor(receiver));
  }, function (receiver, value) {
    return set_uiUnselectedColor(receiver, value.v59_1);
  });
}
function uiCheckboxButtonRenderer$factory() {
  return getPropertyCallableRef('uiCheckboxButtonRenderer', 1, KMutableProperty1, function (receiver) {
    return get_uiCheckboxButtonRenderer(receiver);
  }, function (receiver, value) {
    return set_uiCheckboxButtonRenderer(receiver, value);
  });
}
function uiCheckboxButtonRenderer$factory_0() {
  return getPropertyCallableRef('uiCheckboxButtonRenderer', 1, KMutableProperty1, function (receiver) {
    return get_uiCheckboxButtonRenderer(receiver);
  }, function (receiver, value) {
    return set_uiCheckboxButtonRenderer(receiver, value);
  });
}
var properties_initialized_UIViewStyles_kt_yo0q3h;
function _init_properties_UIViewStyles_kt__ksz6vv() {
  if (!properties_initialized_UIViewStyles_kt_yo0q3h) {
    properties_initialized_UIViewStyles_kt_yo0q3h = true;
    uiBackgroundColor$delegate = new ViewStyle(new RGBA(MaterialColors_getInstance().a5c_1));
    uiSelectedColor$delegate = new ViewStyle(new RGBA(MaterialColors_getInstance().g5c_1));
    uiUnselectedColor$delegate = new ViewStyle(new RGBA(MaterialColors_getInstance().p5i_1));
    uiProgressBarRenderer$delegate = new ViewStyle(uiProgressBarRenderer$delegate$lambda);
    uiCheckboxButtonRenderer$delegate = new ViewStyle(uiCheckboxButtonRenderer$delegate$lambda);
  }
}
function getExpectedX($this) {
  var tmp = $this.gg1_1.j2y() * $this.hg1_1.y2g_1;
  var tmp212_subject = $this.hg1_1.y2g_1;
  return tmp + (tmp212_subject === 0.0 ? -2.0 : tmp212_subject === 1.0 ? +2.0 : 0.0);
}
function getExpectedY($this) {
  var tmp = $this.gg1_1.k2y() * $this.hg1_1.z2g_1;
  var tmp213_subject = $this.hg1_1.z2g_1;
  return tmp + (tmp213_subject === 0.0 ? -2.0 : tmp213_subject === 1.0 ? +2.0 : 0.0);
}
function resized($this) {
  $this.kg1($this.gg1_1.j2y(), $this.gg1_1.k2y());
}
function UIWindow$ScaleHandler$view$lambda(this$0, $anchor) {
  return function (it) {
    var obounds = this$0.gg1_1.tcd();
    var bounds = obounds;
    var tmp;
    if ($anchor.y2g_1 < 0.5) {
      bounds = bounds.h2u(it.mbm_1);
      var tmp_0;
      var containsLower = this$0.gg1_1.ng4_1;
      var containsUpper = this$0.gg1_1.pg4_1;
      var containsArg = bounds.k2i_1;
      if (!(containsLower <= containsArg ? containsArg <= containsUpper : false)) {
        bounds = bounds.h2u(obounds.p2i());
        tmp_0 = Unit_instance;
      }
      tmp = tmp_0;
    } else if ($anchor.y2g_1 > 0.5) {
      bounds = bounds.h2u(VOID, VOID, it.mbm_1);
      bounds = bounds.i2u(VOID, VOID, clamp_1(bounds.k2i_1, this$0.gg1_1.ng4_1, this$0.gg1_1.pg4_1));
      tmp = Unit_instance;
    } else {
      tmp = Unit_instance;
    }
    var tmp_1;
    if ($anchor.z2g_1 < 0.5) {
      bounds = bounds.h2u(VOID, it.nbm_1);
      var tmp_2;
      var containsLower_0 = this$0.gg1_1.og4_1;
      var containsUpper_0 = this$0.gg1_1.qg4_1;
      var containsArg_0 = bounds.l2i_1;
      if (!(containsLower_0 <= containsArg_0 ? containsArg_0 <= containsUpper_0 : false)) {
        bounds = bounds.h2u(VOID, obounds.r2i());
        tmp_2 = Unit_instance;
      }
      tmp_1 = tmp_2;
    } else if ($anchor.z2g_1 > 0.5) {
      bounds = bounds.h2u(VOID, VOID, VOID, it.nbm_1);
      bounds = bounds.i2u(VOID, VOID, VOID, clamp_1(bounds.l2i_1, this$0.gg1_1.og4_1, this$0.gg1_1.qg4_1));
      tmp_1 = Unit_instance;
    } else {
      tmp_1 = Unit_instance;
    }
    this$0.gg1_1.hek(bounds);
    var tmp_3;
    if (it.hbm_1) {
      resized(this$0);
      tmp_3 = Unit_instance;
    }
    return Unit_instance;
  };
}
function *_generator_invoke__zhh2q8_48($this, it, $completion) {
  var tmp = $this.cg5_1.dg5($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_49($this, $completion) {
  var tmp = $this.eg5_1($this.fg5_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_closeAnimated__5v26vt($this, $completion) {
  // Inline function 'korlibs.korge.tween.get' call
  var this_0 = height$factory($this);
  var tmp = this_0.get();
  var tmp_0 = new V2(this_0, tmp, 0.0, _interpolate$ref_19(), false);
  // Inline function 'korlibs.korge.tween.get' call
  var this_1 = alphaF$factory_1($this);
  var tmp_1 = this_1.get();
  var tmp_2 = [tmp_0, new V2(this_1, tmp_1, 0.0, _interpolateFloat$ref_1(), false)];
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp$ret$3 = toDuration(300, DurationUnit_MILLISECONDS_getInstance());
  var tmp_3 = tween_0($this, tmp_2, tmp$ret$3, VOID, VOID, VOID, VOID, VOID, $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  $this.qbh();
  return Unit_instance;
}
function UIWindow$bg$lambda(this$0) {
  return function ($this$ViewRenderer) {
    var isFocused = this$0.hg5();
    var borderSize = isFocused ? 2.0 : 1.0;
    var borderColor = isFocused ? this$0.lg4_1 : this$0.mg4_1;
    return Unit_instance;
  };
}
function UIWindow$closeButton$slambda_0(this$0) {
  var i = new UIWindow$closeButton$slambda(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function UIWindow$closeButton$lambda$slambda_0($handler, $it) {
  var i = new UIWindow$closeButton$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function UIWindow$closeButton$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately_0(tmp, UIWindow$closeButton$lambda$slambda_0($handler, it));
    return Unit_instance;
  };
}
function UIWindow$dragProcessor$lambda(it) {
  xy(it.dbm_1, it.zbn_1);
  return Unit_instance;
}
function UIWindow$lambda(this$0) {
  return function (it) {
    bringToTop(this$0);
    return Unit_instance;
  };
}
function UIWindow$lambda_0(this$0) {
  return function (it) {
    this$0.bg5_1(it);
    return Unit_instance;
  };
}
function _interpolate$ref_19() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolateFloat$ref_1() {
  var l = function (p0, p1, p2) {
    return _interpolateFloat(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolateFloat';
  return l;
}
function click$factory_4() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function plainText$factory($b0) {
  return getPropertyCallableRef('plainText', 0, KMutableProperty0, function () {
    return $b0.jg5();
  }, function (value) {
    return $b0.kg5(value);
  });
}
function height$factory($b0) {
  return getPropertyCallableRef('height', 0, KMutableProperty0, function () {
    return $b0.k2y();
  }, function (value) {
    return $b0.gek(value);
  });
}
function alphaF$factory_1($b0) {
  return getPropertyCallableRef('alphaF', 0, KMutableProperty0, function () {
    return $b0.tbh();
  }, function (value) {
    return $b0.ubh(value);
  });
}
function get_centered(_this__u8e3s4) {
  return center(_this__u8e3s4);
}
function anchor(_this__u8e3s4, anchor) {
  _this__u8e3s4.tf4(anchor);
  return _this__u8e3s4;
}
function center(_this__u8e3s4) {
  // Inline function 'korlibs.korge.view.anchor' call
  return anchor(_this__u8e3s4, Anchor2D.g2h(numberToDouble(0.5), numberToDouble(0.5)));
}
function clearBitmaps($this, onParentChanged) {
  if ($this.xg7_1.a1())
    return Unit_instance;
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var this_0 = $this.xg7_1;
  var n = 0;
  while (n < this_0.j1()) {
    // Inline function 'korlibs.korge.view.BaseGraphics.clearBitmaps.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var it = this_0.i1(_unary__edvuaz);
    // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
    var tmp$ret$0 = get_Bitmaps_transparent();
    if (!equals(it, get_bmp(tmp$ret$0))) {
      var tmp214_safe_receiver = $this.cg8_1;
      var tmp215_safe_receiver = tmp214_safe_receiver == null ? null : tmp214_safe_receiver.fa7_1;
      if (tmp215_safe_receiver == null)
        null;
      else {
        tmp215_safe_receiver.dad(it, 'BaseGraphics');
      }
    }
  }
  $this.xg7_1.o3();
}
function _get_bitmapWidth__ndy7bu($this) {
  return $this.j6l().j2y();
}
function _get_bitmapHeight__5t1vx1($this) {
  return $this.j6l().k2y();
}
function _getLocalBoundsInternal($this, strokes) {
  var bounds = boundsUnsafe($this, strokes);
  return new Rectangle(bounds.i2i_1 - $this.zca(), bounds.j2i_1 - $this.acb(), bounds.k2i_1, bounds.l2i_1);
}
function _getLocalBoundsInternal$default($this, strokes, $super) {
  strokes = strokes === VOID ? $this.gg8_1 : strokes;
  return _getLocalBoundsInternal($this, strokes);
}
function boundsUnsafe($this, strokes) {
  if ($this.bg8_1) {
    $this.bg8_1 = false;
    $this.ig8_1 = $this.jg8(false);
    $this.hg8_1 = $this.jg8(true);
  }
  return strokes ? $this.hg8_1 : $this.ig8_1;
}
function updateGraphics($this) {
  var halfStroke = $this.he1_1 / 2;
  var radius = $this.xcv();
  $this.fgh(toShape2D(new Circle(Vector2D.k2h(radius, radius), radius)));
  // Inline function 'korlibs.korge.view.ShapeView.updatePath' call
  // Inline function 'korlibs.korge.view.Circle.updateGraphics.<anonymous>' call
  var $this$updatePath = $this.ie1_1;
  $this$updatePath.o3();
  $this$updatePath.m3s(Vector2D.k2h(radius, radius), radius);
  $this$updatePath.q3y_1 = true;
  $this.ee1_1 = $this.ie1_1;
  $this.ggh();
}
function Circle$radius$delegate$lambda(this$0) {
  return function (it) {
    updateGraphics(this$0);
    return Unit_instance;
  };
}
function colorMul$factory($b0) {
  return getPropertyCallableRef('colorMul', 0, KMutableProperty0, function () {
    return new RGBA($b0.x58());
  }, function (value) {
    return $b0.m59(value.v59_1);
  });
}
function radius$factory() {
  return getPropertyCallableRef('radius', 1, KMutableProperty1, function (receiver) {
    return receiver.xcv();
  }, function (receiver, value) {
    return receiver.ud8(value);
  });
}
function radius$factory_0() {
  return getPropertyCallableRef('radius', 1, KMutableProperty1, function (receiver) {
    return receiver.xcv();
  }, function (receiver, value) {
    return receiver.ud8(value);
  });
}
var ZIndexComparator_instance;
function ZIndexComparator_getInstance() {
  return ZIndexComparator_instance;
}
function Container$renderChildrenInternal$lambda(it) {
  var tmp;
  if (it instanceof FixedSizeContainer) {
    tmp = it.t78();
  } else {
    tmp = false;
  }
  return tmp;
}
function addTo(_this__u8e3s4, parent) {
  parent.ubw(_this__u8e3s4);
  return _this__u8e3s4;
}
function bringToTop(_this__u8e3s4) {
  var tmp0_elvis_lhs = _this__u8e3s4.w15();
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var parent = tmp;
  parent.hca(_this__u8e3s4, parent.eca() - 1 | 0);
}
function dummyView(_this__u8e3s4) {
  // Inline function 'kotlin.also' call
  var this_0 = DummyView.ggn();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.dummyView.<anonymous>' call
  _this__u8e3s4.oca(this_0);
  return this_0;
}
function radius$factory_1() {
  return getPropertyCallableRef('radius', 1, KMutableProperty1, function (receiver) {
    return receiver.xcv();
  }, function (receiver, value) {
    return receiver.dgq(value);
  });
}
function radius$factory_2() {
  return getPropertyCallableRef('radius', 1, KMutableProperty1, function (receiver) {
    return receiver.xcv();
  }, function (receiver, value) {
    return receiver.dgq(value);
  });
}
var SDFUB_instance;
function SDFUB_getInstance() {
  if (SDFUB_instance === VOID)
    new SDFUB();
  return SDFUB_instance;
}
var Companion_instance_29;
function Companion_getInstance_62() {
  if (Companion_instance_29 === VOID)
    new Companion_14();
  return Companion_instance_29;
}
function u_Corners$factory() {
  return getPropertyCallableRef('u_Corners', 1, KProperty1, function (receiver) {
    return receiver.vgx();
  }, null);
}
function u_Scale$factory() {
  return getPropertyCallableRef('u_Scale', 1, KProperty1, function (receiver) {
    return receiver.wgx();
  }, null);
}
function u_Corners$factory_0() {
  return getPropertyCallableRef('u_Corners', 1, KProperty1, function (receiver) {
    return receiver.vgx();
  }, null);
}
function u_Scale$factory_0() {
  return getPropertyCallableRef('u_Scale', 1, KProperty1, function (receiver) {
    return receiver.wgx();
  }, null);
}
var Companion_instance_30;
function Companion_getInstance_63() {
  return Companion_instance_30;
}
function getVisibleGlobalArea(_this__u8e3s4) {
  // Inline function 'korlibs.korge.view.forEachAscendant' call
  var view = _this__u8e3s4;
  if (!true) {
    var tmp390_safe_receiver = view;
    view = tmp390_safe_receiver == null ? null : tmp390_safe_receiver.w15();
  }
  while (!(view == null)) {
    // Inline function 'korlibs.korge.view.getVisibleGlobalArea.<anonymous>' call
    var it = view;
    var tmp;
    var tmp_0;
    if (it instanceof FixedSizeContainer) {
      tmp_0 = it.t78();
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = true;
    } else {
      tmp = it instanceof Stage;
    }
    if (tmp)
      return it.rcd();
    view = view.w15();
  }
  return new Rectangle(0.0, 0.0, 4096.0, 4096.0);
}
function installFpsDebugOverlay(_this__u8e3s4) {
  var longWindow = new TimeSlidingWindow(240);
  var shortWindow = new TimeSlidingWindow(10);
  var previousTime = {_v: new FastDuration(PerformanceCounter_getInstance().k88())};
  var frames = {_v: 0};
  var batchCount = {_v: 0};
  var vertexCount = {_v: 0};
  var instanceCount = {_v: 0};
  var tmp = _this__u8e3s4.vbk().wb8_1;
  tmp.w1x(installFpsDebugOverlay$lambda(batchCount, vertexCount, instanceCount));
  var tmp_0 = get_debugLineRenderContext(_this__u8e3s4.vbk().xb7_1).yag_1;
  tmp_0.w1x(installFpsDebugOverlay$lambda_0(batchCount, vertexCount));
  var tmp_1 = _this__u8e3s4.vbk().xb7_1.ka7_1.sa8_1;
  tmp_1.w1x(installFpsDebugOverlay$lambda_1(batchCount, vertexCount));
  var tmp_2 = _this__u8e3s4.vbk().xb7_1.ka7_1.ta8_1;
  tmp_2.w1x(installFpsDebugOverlay$lambda_2(instanceCount));
  var tmp_3 = _this__u8e3s4.vbk();
  tmp_3.zgx(installFpsDebugOverlay$lambda_3(previousTime, frames, longWindow, shortWindow, batchCount, vertexCount, instanceCount));
}
function installFpsDebugOverlay$lambda($batchCount, $vertexCount, $instanceCount) {
  return function (it) {
    $batchCount._v = 0;
    $vertexCount._v = 0;
    $instanceCount._v = 0;
    return Unit_instance;
  };
}
function installFpsDebugOverlay$lambda_0($batchCount, $vertexCount) {
  return function (it) {
    var _unary__edvuaz = $batchCount._v;
    $batchCount._v = _unary__edvuaz + 1 | 0;
    $vertexCount._v = $vertexCount._v + it.gah_1 | 0;
    return Unit_instance;
  };
}
function installFpsDebugOverlay$lambda_1($batchCount, $vertexCount) {
  return function (it) {
    var _unary__edvuaz = $batchCount._v;
    $batchCount._v = _unary__edvuaz + 1 | 0;
    $vertexCount._v = $vertexCount._v + it.kae() | 0;
    return Unit_instance;
  };
}
function installFpsDebugOverlay$lambda_2($instanceCount) {
  return function (it) {
    $instanceCount._v = $instanceCount._v + it | 0;
    return Unit_instance;
  };
}
function invoke$drawTextWithShadow($this_addDebugRenderer, $ctx, fontSize, matrix, text, x, y) {
  var tmp239_font = $this_addDebugRenderer.zbp();
  var tmp240_colMul = $ctx.ja7_1;
  var tmp241_blendMode = Companion_getInstance_17().ba6_1;
  drawText($ctx, tmp239_font, fontSize, text, x, y, matrix, tmp240_colMul, tmp241_blendMode, false);
}
function installFpsDebugOverlay$lambda_3($previousTime, $frames, $longWindow, $shortWindow, $batchCount, $vertexCount, $instanceCount) {
  return function ($this$addDebugRenderer, ctx) {
    var scale = ctx.xah();
    var fontSize = 8.0 * scale;
    var currentTime = PerformanceCounter_getInstance().k88();
    var elapsedTime = FastDuration__minus_impl_evs237(currentTime, $previousTime._v.p1w_1);
    var tmp;
    if ($frames._v > 3) {
      var tmp_0;
      if (FastDuration__compareTo_impl_4fhnj7(elapsedTime, get_fastMilliseconds_0(4)) > 0) {
        $longWindow.ggy(elapsedTime);
        $shortWindow.ggy(elapsedTime);
        tmp_0 = Unit_instance;
      }
      tmp = tmp_0;
    }
    var _unary__edvuaz = $frames._v;
    $frames._v = _unary__edvuaz + 1 | 0;
    $previousTime._v = new FastDuration(currentTime);
    var matrix = $this$addDebugRenderer.lb7_1.bag();
    invoke$drawTextWithShadow($this$addDebugRenderer, ctx, fontSize, matrix, 'FPS: ' + ('' + roundDecimalPlaces($shortWindow.egy(), 1)) + (', batchCount=' + $batchCount._v + ', vertexCount=' + $vertexCount._v + ', instanceCount=' + $instanceCount._v) + (', (' + Companion_getInstance_46().ww() + ')'), 0, 0);
    var graphLeft = 1.0;
    var graphTop = fontSize * 2;
    var overlayLines = 60;
    var overlayWidth = 120.0 * scale;
    var overlayHeight = 30 * scale;
    var overlayHeightGap = 5.0;
    // Inline function 'korlibs.korge.render.useLineBatcher' call
    var this_0 = $this$addDebugRenderer.xb7_1;
    // Inline function 'korlibs.korge.render.LineRenderBatcher.use' call
    var this_1 = get_debugLineRenderContext(this_0);
    var this_2 = this_1.xag_1;
    if (!(this_2.oa7_1 === this_1)) {
      this_2.oa7_1 = this_1;
      this_2.ya8();
    }
    // Inline function 'korlibs.korge.render.LineRenderBatcher.drawWithGlobalMatrix' call
    var this_3 = get_debugLineRenderContext(this_0);
    $l$block_0: {
      // Inline function 'korlibs.datastructure.keep' call
      var mut = currentMatrix$factory(this_3);
      var temp = mut.get();
      try {
        // Inline function 'korlibs.korge.render.LineRenderBatcher.drawWithGlobalMatrix.<anonymous>' call
        if (!(matrix == null))
          this_3.jah_1 = matrix;
        // Inline function 'korlibs.korge.view.installFpsDebugOverlay.<anonymous>.<anonymous>' call
        $l$block: {
          // Inline function 'korlibs.korge.render.LineRenderBatcher.blending' call
          var blending = Companion_getInstance_17().ba6_1;
          var doUpdate = !(this_1.iah_1 === blending);
          var old = this_1.iah_1;
          try {
            if (doUpdate) {
              this_1.xag_1.ya8();
              this_1.iah_1 = blending;
            }
            // Inline function 'korlibs.korge.render.LineRenderBatcher.color' call
            var color = Colors_getInstance().w4w_1;
            var oldColor = this_1.aah_1;
            this_1.aah_1 = color;
            try {
              // Inline function 'korlibs.korge.view.installFpsDebugOverlay.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
              this_1.oah(graphLeft, graphTop, graphLeft, graphTop + overlayHeight);
              this_1.oah(graphLeft, graphTop + overlayHeight, graphLeft + overlayWidth, graphTop + overlayHeight);
            }finally {
              this_1.aah_1 = oldColor;
            }
            var inductionVariable = 0;
            if (inductionVariable <= 0)
              do {
                var index = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var color_0 = index === 0 ? Colors_getInstance().g4r_1 : Colors_getInstance().h4r_1;
                // Inline function 'korlibs.korge.render.LineRenderBatcher.color' call
                var oldColor_0 = this_1.aah_1;
                this_1.aah_1 = color_0;
                try {
                  // Inline function 'korlibs.korge.view.installFpsDebugOverlay.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                  var ratio = $longWindow.j1() / $longWindow.agy_1;
                  var totalOverlayLines = coerceAtLeast(numberToInt(overlayLines * ratio), 1);
                  if ($longWindow.j1() > 0) {
                    var previousX = 0.0;
                    var previousY = 0.0;
                    var minFps = 0.0;
                    var maxFps = 150.0;
                    var inductionVariable_0 = 0;
                    if (inductionVariable_0 < totalOverlayLines)
                      do {
                        var n = inductionVariable_0;
                        inductionVariable_0 = inductionVariable_0 + 1 | 0;
                        // Inline function 'kotlin.run' call
                        // Inline function 'kotlin.contracts.contract' call
                        // Inline function 'korlibs.korge.view.installFpsDebugOverlay.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                        var p0 = convertRange_1(n, 0, totalOverlayLines, 0, coerceAtLeast($longWindow.j1(), 1));
                        var p1 = convertRange_1(n + 1 | 0, 0, totalOverlayLines, 0, coerceAtLeast($longWindow.j1(), 1));
                        var plen = 0;
                        var timeSum = get_fastMilliseconds_0(0);
                        var inductionVariable_1 = p0;
                        var last = coerceAtMost(p1, $longWindow.j1());
                        if (inductionVariable_1 < last)
                          do {
                            var m = inductionVariable_1;
                            inductionVariable_1 = inductionVariable_1 + 1 | 0;
                            timeSum = FastDuration__plus_impl_q1enab(timeSum, $longWindow.fgy(m));
                            plen = plen + 1 | 0;
                          }
                           while (inductionVariable_1 < last);
                        if (plen === 0) {
                          timeSum = FastDuration__plus_impl_q1enab(timeSum, $longWindow.fgy(p0));
                          plen = plen + 1 | 0;
                        }
                        var time = FastDuration__div_impl_rlslck_0(timeSum, plen);
                        var fps = _Frequency___get_hertz__impl__9m7k6x(toFrequency(time));
                        var fps_0 = fps;
                        var scaledFreq = convertRange(fps_0, minFps, maxFps, overlayHeightGap, overlayHeight);
                        var y = graphTop + overlayHeight - scaledFreq;
                        var x = graphLeft + n * overlayWidth / overlayLines;
                        if (n > 0) {
                          this_1.oah(previousX, previousY + index, x, y + index);
                        }
                        previousY = y;
                        previousX = x;
                      }
                       while (inductionVariable_0 < totalOverlayLines);
                  }
                }finally {
                  this_1.aah_1 = oldColor_0;
                }
              }
               while (inductionVariable <= 0);
            break $l$block;
          }finally {
            if (doUpdate) {
              this_1.xag_1.ya8();
            }
            this_1.iah_1 = old;
          }
        }
        break $l$block_0;
      }finally {
        mut.set(temp);
      }
    }
    return Unit_instance;
  };
}
function currentMatrix$factory($b0) {
  return getPropertyCallableRef('currentMatrix', 0, KMutableProperty0, function () {
    return $b0.jah_1;
  }, function (value) {
    $b0.jah_1 = value;
    return Unit_instance;
  });
}
var GraphicsRenderer_SYSTEM_instance;
var GraphicsRenderer_CPU_instance;
var GraphicsRenderer_GPU_instance;
function values() {
  return [GraphicsRenderer_SYSTEM_getInstance(), GraphicsRenderer_CPU_getInstance(), GraphicsRenderer_GPU_getInstance()];
}
var GraphicsRenderer_entriesInitialized;
function GraphicsRenderer_initEntries() {
  if (GraphicsRenderer_entriesInitialized)
    return Unit_instance;
  GraphicsRenderer_entriesInitialized = true;
  GraphicsRenderer_SYSTEM_instance = new GraphicsRenderer('SYSTEM', 0);
  GraphicsRenderer_CPU_instance = new GraphicsRenderer('CPU', 1);
  GraphicsRenderer_GPU_instance = new GraphicsRenderer('GPU', 2);
}
function _get_anchorable__ebfoik($this) {
  var tmp0_elvis_lhs = $this.ph0_1;
  return ensureNotNull(tmp0_elvis_lhs == null ? $this.qh0_1 : tmp0_elvis_lhs);
}
function ensure_0($this) {
  if ($this.wh0_1.v3_1 === 2) {
    if (!($this.ph0_1 == null)) {
      var tmp260_safe_receiver = $this.ph0_1;
      if (tmp260_safe_receiver == null)
        null;
      else {
        tmp260_safe_receiver.qbh();
      }
      $this.ph0_1 = null;
    }
    if ($this.qh0_1 == null) {
      var tmp = $this;
      // Inline function 'kotlin.also' call
      // Inline function 'korlibs.korge.view.vector.gpuGraphics' call
      // Inline function 'korlibs.korge.view.vector.gpuShapeView' call
      // Inline function 'kotlin.also' call
      var this_0 = GpuShapeView.wh3(EmptyShape_instance, true);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>' call
      // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape' call
      // Inline function 'korlibs.image.vector.buildShape' call
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape.<anonymous>' call
      // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>.<anonymous>' call
      // Inline function 'korlibs.korge.view.vector.gpuGraphics.<anonymous>' call
      var tmp$ret$2 = (new ShapeBuilder(null, null)).p5r();
      this_0.xh3(tmp$ret$2);
      var this_1 = addTo(this_0, $this);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.Graphics.ensure.<anonymous>' call
      this_1.yh3($this.rh0_1);
      this_1.xh2_1 = $this.uh0_1;
      this_1.zh2_1 = $this.th0_1;
      this_1.xh3($this.vh0_1);
      tmp.qh0_1 = this_1;
    }
  } else {
    if (!($this.qh0_1 == null)) {
      var tmp261_safe_receiver = $this.qh0_1;
      if (tmp261_safe_receiver == null)
        null;
      else {
        tmp261_safe_receiver.qbh();
      }
      $this.qh0_1 = null;
    }
    if ($this.ph0_1 == null) {
      var tmp_0 = $this;
      // Inline function 'kotlin.also' call
      // Inline function 'korlibs.korge.view.cpuGraphics' call
      // Inline function 'kotlin.also' call
      var this_2 = addTo(CpuGraphics.bgl(false), $this);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.cpuGraphics.<anonymous>' call
      // Inline function 'korlibs.korge.view.CpuGraphics.updateShape' call
      // Inline function 'korlibs.image.vector.buildShape' call
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.CpuGraphics.updateShape.<anonymous>' call
      // Inline function 'korlibs.korge.view.cpuGraphics.<anonymous>.<anonymous>' call
      // Inline function 'korlibs.korge.view.cpuGraphics.<anonymous>' call
      var tmp$ret$9 = (new ShapeBuilder(null, null)).p5r();
      this_2.cgl(tmp$ret$9);
      if (false) {
        this_2.vg8();
      }
      this_2.bg8_1 = true;
      this_2.ycc();
      this_2.vg8();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.Graphics.ensure.<anonymous>' call
      this_2.vgk_1 = $this.rh0_1;
      this_2.tg7_1 = $this.uh0_1;
      this_2.zga_1 = $this.th0_1;
      this_2.cgl($this.vh0_1);
      tmp_0.ph0_1 = this_2;
    }
    var tmp262_safe_receiver = $this.ph0_1;
    if (tmp262_safe_receiver == null)
      null;
    else {
      tmp262_safe_receiver.tg8($this.wh0_1.equals(GraphicsRenderer_SYSTEM_getInstance()));
    }
  }
}
function GraphicsRenderer_SYSTEM_getInstance() {
  GraphicsRenderer_initEntries();
  return GraphicsRenderer_SYSTEM_instance;
}
function GraphicsRenderer_CPU_getInstance() {
  GraphicsRenderer_initEntries();
  return GraphicsRenderer_CPU_instance;
}
function GraphicsRenderer_GPU_getInstance() {
  GraphicsRenderer_initEntries();
  return GraphicsRenderer_GPU_instance;
}
function *_generator_baseForceLoadSourceFile__natc6b(_this__u8e3s4, $this, views, currentVfs, sourceFile, $completion) {
  var tmp = $this.pd8_1.ih4(_this__u8e3s4, views, currentVfs, sourceFile, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_forceLoadSourceFile__yy5vos($this, views, currentVfs, sourceFile, $completion) {
  var tmp = $this.ih4($this, views, currentVfs, sourceFile, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  try {
    var tmp_0 = readBitmapSlice(currentVfs.bj('' + sourceFile), VOID, VOID, VOID, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    $this.lfy(tmp_0);
    $this.ocb(1.0);
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      // Inline function 'korlibs.image.bitmap.Bitmaps.white' call
      var tmp$ret$0 = get_Bitmaps_white();
      $this.lfy(tmp$ret$0);
      $this.ocb(100.0);
    } else {
      throw $p;
    }
  }
  return Unit_instance;
}
function trySetSourceActually($this) {
  var source = $this.kfy_1.s14();
  if (!(source == null)) {
    $this.jfy_1 = true;
    $this.lgb(source);
  }
}
function sourceFile$factory($b0) {
  return getPropertyCallableRef('sourceFile', 0, KMutableProperty0, function () {
    return $b0.oh4();
  }, function (value) {
    return $b0.nh4(value);
  });
}
var CoordsType_D_0_1_instance;
var CoordsType_D_M1_1_instance;
var CoordsType_D_W_H_instance;
var CoordsType_entriesInitialized;
function CoordsType_initEntries() {
  if (CoordsType_entriesInitialized)
    return Unit_instance;
  CoordsType_entriesInitialized = true;
  CoordsType_D_0_1_instance = new CoordsType('D_0_1', 0);
  CoordsType_D_M1_1_instance = new CoordsType('D_M1_1', 1);
  CoordsType_D_W_H_instance = new CoordsType('D_W_H', 2);
}
var Companion_instance_31;
function Companion_getInstance_64() {
  return Companion_instance_31;
}
function CoordsType_D_0_1_getInstance() {
  CoordsType_initEntries();
  return CoordsType_D_0_1_instance;
}
function anchor$factory($b0) {
  return getPropertyCallableRef('anchor', 0, KMutableProperty0, function () {
    return $b0.uf4();
  }, function (value) {
    return $b0.tf4(value);
  });
}
function antialiased$factory($b0) {
  return getPropertyCallableRef('antialiased', 0, KMutableProperty0, function () {
    return $b0.rh0_1;
  }, function (value) {
    return $b0.yh3(value);
  });
}
function autoScaling$factory($b0) {
  return getPropertyCallableRef('autoScaling', 0, KMutableProperty0, function () {
    return $b0.uh0_1;
  }, function (value) {
    return $b0.dh4(value);
  });
}
function renderer$factory($b0) {
  return getPropertyCallableRef('renderer', 0, KMutableProperty0, function () {
    return $b0.wh0_1;
  }, function (value) {
    return $b0.eh4(value);
  });
}
function smoothing$factory($b0) {
  return getPropertyCallableRef('smoothing', 0, KMutableProperty0, function () {
    return $b0.th0_1;
  }, function (value) {
    return $b0.ch4(value);
  });
}
function boundsIncludeStrokes$factory($b0) {
  return getPropertyCallableRef('boundsIncludeStrokes', 0, KMutableProperty0, function () {
    return $b0.bh4();
  }, function (value) {
    return $b0.hgb(value);
  });
}
function anchor$factory_0() {
  return getPropertyCallableRef('anchor', 1, KMutableProperty1, function (receiver) {
    return receiver.uf4();
  }, function (receiver, value) {
    return receiver.tf4(value);
  });
}
function anchor$factory_1() {
  return getPropertyCallableRef('anchor', 1, KMutableProperty1, function (receiver) {
    return receiver.uf4();
  }, function (receiver, value) {
    return receiver.tf4(value);
  });
}
var Companion_instance_32;
function Companion_getInstance_65() {
  return Companion_instance_32;
}
function clipBorders$factory($b0) {
  return getPropertyCallableRef('clipBorders', 0, KMutableProperty0, function () {
    return $b0.jb8_1;
  }, function (value) {
    $b0.jb8_1 = value;
    return Unit_instance;
  });
}
function virtualSizeDouble$factory($b0) {
  return getPropertyCallableRef('virtualSizeDouble', 0, KMutableProperty0, function () {
    return $b0.th4();
  }, function (value) {
    return $b0.uh4(value);
  });
}
function clip$factory() {
  return getPropertyCallableRef('clip', 1, KMutableProperty1, function (receiver) {
    return receiver.t78();
  }, function (receiver, value) {
    return receiver.sh4(value);
  });
}
function clip$factory_0() {
  return getPropertyCallableRef('clip', 1, KMutableProperty1, function (receiver) {
    return receiver.t78();
  }, function (receiver, value) {
    return receiver.sh4(value);
  });
}
function unscaledSize$factory() {
  return getPropertyCallableRef('unscaledSize', 1, KMutableProperty1, function (receiver) {
    return receiver.cc7();
  }, function (receiver, value) {
    return receiver.bc7(value);
  });
}
function unscaledSize$factory_0() {
  return getPropertyCallableRef('unscaledSize', 1, KMutableProperty1, function (receiver) {
    return receiver.cc7();
  }, function (receiver, value) {
    return receiver.bc7(value);
  });
}
function get_agProgram(_this__u8e3s4) {
  switch (_this__u8e3s4.c5m()) {
    case 'msdf':
      return MsdfRender_getInstance().tce_1;
    case 'psdf':
      return MsdfRender_getInstance().wce_1;
    case 'sdf':
      return MsdfRender_getInstance().wce_1;
    default:
      return null;
  }
}
function *_generator_invoke__zhh2q8_50($this, $completion) {
  var tmp = $this.vh4_1.yh4(ensureNotNull(get_views($this.wh4_1)).vb7_1, $this.xh4_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
var Companion_instance_33;
function Companion_getInstance_66() {
  return Companion_instance_33;
}
function updateLineCount($this) {
  var tmp = $this;
  // Inline function 'kotlin.text.count' call
  var count = 0;
  var indexedObject = $this.o2f();
  var inductionVariable = 0;
  while (inductionVariable < charSequenceLength(indexedObject)) {
    var element = charSequenceGet(indexedObject, inductionVariable);
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'korlibs.korge.view.Text.updateLineCount.<anonymous>' call
    if (element === _Char___init__impl__6a9atx(10)) {
      count = count + 1 | 0;
    }
  }
  tmp.dcj_1 = count + 1 | 0;
}
function *_generator_forceLoadFontSource__wlrz09($this, currentVfs, sourceFile, $completion) {
  $this.zh4(sourceFile);
  $this.qcj_1 = true;
  if (!(sourceFile == null)) {
    var tmp = readFont(currentVfs.bj('' + sourceFile), VOID, VOID, VOID, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    $this.wcp(tmp);
  }
  return Unit_instance;
}
function setContainerPosition($this, pos, baseline) {
  var x = pos.km();
  var y = pos.lm();
  if ($this.vcj_1) {
    setRealContainerPosition($this, x, y);
  } else {
    setRealContainerPosition($this, x + HorizontalAlign__getOffsetX_impl_urzzxf($this.ncj_1.z5t_1, $this.ucj_1.k2i_1), y - VerticalAlign__getOffsetY_impl_iso0y6($this.ncj_1.a5u_1, $this.ucj_1.l2i_1, baseline));
  }
}
function setRealContainerPosition($this, x, y) {
  position($this.ocj_1, x, y);
}
function Text$_renderInternal$slambda_0(this$0, $ctx, $fontSource) {
  var i = new Text$_renderInternal$slambda(this$0, $ctx, $fontSource);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function textSize$factory_2($b0) {
  return getPropertyCallableRef('textSize', 0, KMutableProperty0, function () {
    return $b0.icj_1;
  }, function (value) {
    return $b0.xcp(value);
  });
}
function fontSource$factory($b0) {
  return getPropertyCallableRef('fontSource', 0, KMutableProperty0, function () {
    return $b0.tcj_1;
  }, function (value) {
    return $b0.zh4(value);
  });
}
function fontSize$factory() {
  return getPropertyCallableRef('fontSize', 1, KMutableProperty1, function (receiver) {
    return receiver.b5m();
  }, function (receiver, value) {
    return receiver.l76(value);
  });
}
function fontSize$factory_0() {
  return getPropertyCallableRef('fontSize', 1, KMutableProperty1, function (receiver) {
    return receiver.b5m();
  }, function (receiver, value) {
    return receiver.l76(value);
  });
}
function _get_allBitmap__bdn8f($this) {
  if ($this.ed6_1 == null) {
    var tmp = $this;
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_0 = $this.qd5_1.o6w();
      var tmp_0;
      if (isInterface(this_0, Collection)) {
        tmp_0 = this_0.a1();
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.view.TextBlock.<get-allBitmap>.<anonymous>' call
        if (!isInterface(element, BitmapFont)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    tmp.ed6_1 = tmp$ret$0;
  }
  return ensureNotNull($this.ed6_1);
}
function invalidateText($this) {
  invalidProps($this);
  if ($this.zd5_1) {
    // Inline function 'korlibs.korge.view.size' call
    var width = $this.qd5_1.j2y();
    var height = $this.qd5_1.k2y();
    size($this, numberToDouble(width), numberToDouble(height));
  }
  $this.ed6_1 = null;
}
function invalidProps($this) {
  $this.pd5_1 = true;
  $this.hbh();
}
function ensureTexture($this) {
  if (!$this.pd5_1)
    return Unit_instance;
  $this.pd5_1 = false;
  var bmp = NativeImage(toIntCeil($this.j2y()), toIntCeil($this.k2y()));
  if ($this.dd6_1 == null) {
    var tmp = $this;
    // Inline function 'korlibs.korge.view.image' call
    // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
    var texture = get_Bitmaps_transparent();
    var anchor = Companion_getInstance_32().p2g_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = Image.rd8(texture, anchor);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.image.<anonymous>' call
    tmp.dd6_1 = addTo(this_0, $this);
  }
  var tmp322_safe_receiver = $this.dd6_1;
  if (tmp322_safe_receiver == null)
    null;
  else {
    tmp322_safe_receiver.lfy(slice(bmp));
  }
  var tmp323_safe_receiver = $this.dd6_1;
  if (tmp323_safe_receiver == null)
    null;
  else {
    var tmp_0 = tmp323_safe_receiver;
    var tmp_1 = $this.qd5_1.v6w_1.c6w_1;
    var tmp324_safe_receiver = isInterface(tmp_1, BitmapFont) ? tmp_1 : null;
    tmp_0.dgb_1 = tmp324_safe_receiver == null ? null : get_agProgram(tmp324_safe_receiver);
  }
  var tmp325_safe_receiver = $this.dd6_1;
  if (tmp325_safe_receiver == null)
    null;
  else {
    tmp325_safe_receiver.zga_1 = $this.cd6_1;
  }
  // Inline function 'korlibs.image.bitmap.context2d' call
  // Inline function 'korlibs.image.bitmap.Bitmap.lock' call
  var rect = bmp.i4p_1;
  if (true) {
    bmp.w1w();
  }
  var result;
  try {
    // Inline function 'korlibs.image.bitmap.context2d.<anonymous>' call
    var ctx = bmp.y4x(true);
    try {
      // Inline function 'korlibs.korge.view.TextBlock.ensureTexture.<anonymous>' call
      var tmp326_text = $this.qd5_1;
      var tmp327_bounds = Companion_getInstance_38().g2t($this.yd5_1.t2l_1, $this.yd5_1.q2l_1, ctx.j2y() - $this.yd5_1.r2l_1, ctx.k2y() - $this.yd5_1.s2l_1);
      var tmp328_includePartialLines = $this.sd5_1;
      var tmp329_wordWrap = $this.wd5_1;
      var tmp330_ellipsis = $this.xd5_1;
      var tmp331_align = $this.rd5_1;
      var tmp332_fill = $this.ud5_1;
      var tmp333_stroke = $this.vd5_1;
      var tmp334_textRangeStart = $this.ad6_1;
      var tmp335_textRangeEnd = $this.bd6_1;
      drawRichText(ctx, tmp326_text, tmp327_bounds, tmp329_wordWrap, tmp328_includePartialLines, tmp330_ellipsis, tmp332_fill, tmp333_stroke, tmp331_align, true, tmp334_textRangeStart, tmp335_textRangeEnd);
    }finally {
      ctx.o18();
    }
  }finally {
    result = true ? bmp.q4q(rect) : 0;
  }
}
var Companion_instance_34;
function Companion_getInstance_67() {
  if (Companion_instance_34 === VOID)
    new Companion_19();
  return Companion_instance_34;
}
function *_generator_baseForceLoadSourceFile__natc6b_0(_this__u8e3s4, $this, views, currentVfs, sourceFile, $completion) {
  var tmp = $this.bh8_1.ih4(_this__u8e3s4, views, currentVfs, sourceFile, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
var Companion_instance_35;
function Companion_getInstance_68() {
  return Companion_instance_35;
}
function *_generator_forceLoadSourceFile__yy5vos_0($this, views, currentVfs, sourceFile, $completion) {
  var tmp = $this.ih4($this, views, currentVfs, sourceFile, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = readVectorImage(currentVfs.bj('' + sourceFile), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var vector = tmp_0;
  println('VECTOR: ' + toString(vector));
  $this.eh8(vector);
  return Unit_instance;
}
function sourceFile$factory_0($b0) {
  return getPropertyCallableRef('sourceFile', 0, KMutableProperty0, function () {
    return $b0.oh4();
  }, function (value) {
    return $b0.nh4(value);
  });
}
var Companion_instance_36;
function Companion_getInstance_69() {
  return Companion_instance_36;
}
function updateRenderColorTransform($this) {
  $this.xba_1 = $this.cbb_1;
  $this.ebb_1 = true;
  var parent = $this.w15();
  if (!((parent == null ? null : get_filter(parent)) == null)) {
    $this.wba_1.o59($this.vba_1);
  } else {
    var tmp;
    if (!(parent == null)) {
      tmp = !isInterface($this, ColorReference);
    } else {
      tmp = false;
    }
    if (tmp) {
      $this.wba_1.p59($this.vba_1, parent.ucc());
    } else {
      $this.wba_1.o59($this.vba_1);
    }
  }
}
function updateRenderColorTransformIfRequired($this) {
  if ($this.xba_1 === $this.cbb_1)
    return Unit_instance;
  updateRenderColorTransform($this);
}
function updateRenderBlendMode($this) {
  $this.zba_1 = $this.bbb_1;
  $this.dbb_1 = true;
  var tmp = $this;
  var tmp_0;
  if ($this.bba_1.equals(Companion_getInstance_17().sa5_1)) {
    var tmp353_safe_receiver = $this.w15();
    var tmp0_elvis_lhs = tmp353_safe_receiver == null ? null : tmp353_safe_receiver.vcc();
    tmp_0 = tmp0_elvis_lhs == null ? Companion_getInstance_17().ra5_1 : tmp0_elvis_lhs;
  } else {
    tmp_0 = $this.bba_1;
  }
  tmp.yba_1 = tmp_0;
}
function updateRenderBlendModeIfRequired($this) {
  if ($this.zba_1 === $this.bbb_1)
    return Unit_instance;
  updateRenderBlendMode($this);
}
function View$bviewAll$delegate$lambda(this$0) {
  return function () {
    return listOf(this$0);
  };
}
function View$addRenderPhase$lambda(a, b) {
  // Inline function 'kotlin.comparisons.compareValuesBy' call
  // Inline function 'korlibs.korge.view.View.addRenderPhase.<anonymous>' call
  var tmp = a.ih8();
  // Inline function 'korlibs.korge.view.View.addRenderPhase.<anonymous>' call
  var tmp$ret$1 = b.ih8();
  return compareValues(tmp, tmp$ret$1);
}
function addFastUpdater(_this__u8e3s4, first, firstTime, updatable) {
  first = first === VOID ? true : first;
  firstTime = firstTime === VOID ? Companion_instance_1.m1w() : firstTime;
  if (first)
    updatable(_this__u8e3s4, new FastDuration(firstTime));
  var tmp = Companion_instance_9;
  return _this__u8e3s4.k8a(tmp, addFastUpdater$lambda(updatable, _this__u8e3s4));
}
function addFastUpdater_0(_this__u8e3s4, updatable) {
  return addFastUpdater(_this__u8e3s4, true, VOID, updatable);
}
function addOptFixedUpdater(_this__u8e3s4, time, updatable) {
  var tmp;
  if (time === VOID) {
    // Inline function 'korlibs.time.NIL' call
    Companion_getInstance();
    tmp = get_DURATION_NIL();
  } else {
    tmp = time;
  }
  time = tmp;
  var tmp_0;
  // Inline function 'korlibs.time.NIL' call
  Companion_getInstance();
  var tmp$ret$1 = get_DURATION_NIL();
  if (equals(time, tmp$ret$1)) {
    tmp_0 = addUpdater(_this__u8e3s4, updatable);
  } else {
    tmp_0 = addFixedUpdater(_this__u8e3s4, time, VOID, VOID, addOptFixedUpdater$lambda(updatable, time));
  }
  return tmp_0;
}
function addUpdaterWithViews(_this__u8e3s4, updatable) {
  var tmp = Companion_instance_37;
  return _this__u8e3s4.k8a(tmp, addUpdaterWithViews$lambda(updatable, _this__u8e3s4));
}
function hasAncestor(_this__u8e3s4, ancestor) {
  var tmp;
  if (equals(_this__u8e3s4, ancestor)) {
    tmp = true;
  } else {
    var tmp380_safe_receiver = _this__u8e3s4.w15();
    var tmp0_elvis_lhs = tmp380_safe_receiver == null ? null : hasAncestor(tmp380_safe_receiver, ancestor);
    tmp = tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs;
  }
  return tmp;
}
function scale(_this__u8e3s4, sx, sy) {
  sy = sy === VOID ? sx : sy;
  _this__u8e3s4.ucb(Scale.s2r(sx, sy));
  return _this__u8e3s4;
}
function position(_this__u8e3s4, x, y) {
  return xy(_this__u8e3s4, Vector2D.k2h(x, y));
}
function globalPos(_this__u8e3s4, p) {
  _this__u8e3s4.ccc(p);
  return _this__u8e3s4;
}
function size(_this__u8e3s4, width, height) {
  return size_0(_this__u8e3s4, Size2D.k2t(width, height));
}
function descendantsWith(_this__u8e3s4, out, check) {
  var tmp;
  if (out === VOID) {
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp = ArrayList.m1();
  } else {
    tmp = out;
  }
  out = tmp;
  if (!(_this__u8e3s4 == null)) {
    if (check(_this__u8e3s4)) {
      // Inline function 'kotlin.collections.plusAssign' call
      out.f(_this__u8e3s4);
    }
    if (_this__u8e3s4.pb9_1) {
      // Inline function 'korlibs.korge.view.View.forEachChild' call
      var tmp337_safe_receiver = _this__u8e3s4.bca();
      var tmp_0;
      if (tmp337_safe_receiver == null) {
        tmp_0 = null;
      } else {
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var n = 0;
        while (n < tmp337_safe_receiver.j1()) {
          // Inline function 'korlibs.korge.view.descendantsWith.<anonymous>' call
          var _unary__edvuaz = n;
          n = _unary__edvuaz + 1 | 0;
          var child = tmp337_safe_receiver.i1(_unary__edvuaz);
          descendantsWith(child, out, check);
        }
        tmp_0 = Unit_instance;
      }
    }
  }
  return out;
}
function scrollParentsToMakeVisible(_this__u8e3s4) {
  // Inline function 'korlibs.korge.view.firstAncestorOfType' call
  var tmp = firstAncestor(_this__u8e3s4, false, scrollParentsToMakeVisible$lambda);
  var tmp389_safe_receiver = (tmp == null ? true : tmp instanceof UIScrollable) ? tmp : THROW_CCE();
  if (tmp389_safe_receiver == null)
    null;
  else {
    tmp389_safe_receiver.xfh(_this__u8e3s4);
  }
}
function addFixedUpdater(_this__u8e3s4, time, first, limitCallsPerFrame, updatable) {
  first = first === VOID ? true : first;
  limitCallsPerFrame = limitCallsPerFrame === VOID ? 16 : limitCallsPerFrame;
  return addFixedUpdater_0(_this__u8e3s4, get_fast(time), first, limitCallsPerFrame, updatable);
}
function findFirstAscendant(_this__u8e3s4, cond) {
  var current = _this__u8e3s4;
  while (!(current == null)) {
    if (cond(current))
      return current;
    current = current.w15();
  }
  return null;
}
function get_ancestorCount(_this__u8e3s4) {
  var count = 0;
  var parent = _this__u8e3s4 == null ? null : _this__u8e3s4.w15();
  while (!(parent == null)) {
    count = count + 1 | 0;
    parent = parent.w15();
  }
  return count;
}
function sizeScaled(_this__u8e3s4, size) {
  _this__u8e3s4.ecc(Size2D.k2t(_this__u8e3s4.rcb() === 0.0 ? size.d2j_1 : size.d2j_1 / _this__u8e3s4.rcb(), _this__u8e3s4.tcb() === 0.0 ? size.e2j_1 : size.e2j_1 / _this__u8e3s4.tcb()));
  return _this__u8e3s4;
}
function addUpdater(_this__u8e3s4, updatable) {
  return addUpdater_0(_this__u8e3s4, true, VOID, updatable);
}
function xy(_this__u8e3s4, p) {
  _this__u8e3s4.s5w(p);
  return _this__u8e3s4;
}
function size_0(_this__u8e3s4, size) {
  _this__u8e3s4.ecc(size);
  return _this__u8e3s4;
}
function addFixedUpdater_0(_this__u8e3s4, time, first, limitCallsPerFrame, updatable) {
  first = first === VOID ? true : first;
  limitCallsPerFrame = limitCallsPerFrame === VOID ? 16 : limitCallsPerFrame;
  var accum = {_v: new FastDuration(Companion_instance_1.m1w())};
  return addFastUpdater(_this__u8e3s4, first, time, addFixedUpdater$lambda(accum, time, updatable, _this__u8e3s4, limitCallsPerFrame));
}
function addUpdater_0(_this__u8e3s4, first, firstTime, updatable) {
  first = first === VOID ? true : first;
  firstTime = firstTime === VOID ? Companion_getInstance().ik_1 : firstTime;
  if (first)
    updatable(_this__u8e3s4, new Duration(firstTime));
  var tmp = Companion_instance_9;
  return _this__u8e3s4.k8a(tmp, addUpdater$lambda(updatable, _this__u8e3s4));
}
function firstAncestor(_this__u8e3s4, includeThis, condition) {
  includeThis = includeThis === VOID ? true : includeThis;
  if (_this__u8e3s4 == null)
    return null;
  if (includeThis && condition(_this__u8e3s4))
    return _this__u8e3s4;
  var tmp388_safe_receiver = _this__u8e3s4.w15();
  return tmp388_safe_receiver == null ? null : firstAncestor(tmp388_safe_receiver, true, condition);
}
function replaceWith(_this__u8e3s4, view) {
  var tmp381_safe_receiver = _this__u8e3s4.w15();
  var tmp0_elvis_lhs = tmp381_safe_receiver == null ? null : tmp381_safe_receiver.yca(_this__u8e3s4, view);
  return tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs;
}
function xy_0(_this__u8e3s4, x, y) {
  _this__u8e3s4.lcb(x, y);
  return _this__u8e3s4;
}
function rotation(_this__u8e3s4, rot) {
  _this__u8e3s4.acc(rot);
  return _this__u8e3s4;
}
function addFixedUpdater_1(_this__u8e3s4, timesPerSecond, initial, limitCallsPerFrame, updatable) {
  initial = initial === VOID ? true : initial;
  limitCallsPerFrame = limitCallsPerFrame === VOID ? 16 : limitCallsPerFrame;
  return addFixedUpdater_0(_this__u8e3s4, _Frequency___get_fastDuration__impl__87web0(timesPerSecond), initial, limitCallsPerFrame, updatable);
}
function onStageResized(_this__u8e3s4, firstTrigger, block) {
  firstTrigger = firstTrigger === VOID ? true : firstTrigger;
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.onStageResized.<anonymous>' call
  if (firstTrigger) {
    deferWithViews(_this__u8e3s4, VOID, VOID, onStageResized$lambda(block));
  }
  var tmp = Companion_instance_38;
  _this__u8e3s4.k8a(tmp, onStageResized$lambda_0(block));
  return _this__u8e3s4;
}
function alpha_1(_this__u8e3s4, alpha) {
  _this__u8e3s4.ubh(alpha);
  return _this__u8e3s4;
}
function zIndex(_this__u8e3s4, index) {
  _this__u8e3s4.mcb(index);
  return _this__u8e3s4;
}
function deferWithViews(_this__u8e3s4, views, tryImmediate, block) {
  views = views === VOID ? null : views;
  tryImmediate = tryImmediate === VOID ? true : tryImmediate;
  if (tryImmediate) {
    var tmp;
    if (views == null) {
      var tmp382_safe_receiver = _this__u8e3s4.pbj();
      tmp = tmp382_safe_receiver == null ? null : tmp382_safe_receiver.vbk();
    } else {
      tmp = views;
    }
    var tmp383_safe_receiver = tmp;
    if (tmp383_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      block(tmp383_safe_receiver);
      return _this__u8e3s4;
    }
  }
  onNextFrame(_this__u8e3s4, deferWithViews$lambda(block));
  return _this__u8e3s4;
}
function onNextFrame(_this__u8e3s4, block) {
  var closeable = {_v: null};
  closeable._v = addUpdaterWithViews(_this__u8e3s4, onNextFrame$lambda(block, closeable));
  return closeable._v;
}
function bounds_0(_this__u8e3s4, rect) {
  return bounds_1(_this__u8e3s4, rect.p2i(), rect.r2i(), rect.q2i(), rect.s2i());
}
function bounds_1(_this__u8e3s4, left, top, right, bottom) {
  return size(xy_0(_this__u8e3s4, left, top), right - left, bottom - top);
}
function colorMul(_this__u8e3s4, color) {
  _this__u8e3s4.m59(color);
  return _this__u8e3s4;
}
function addFastUpdater$lambda($updatable, $this_addFastUpdater) {
  return function (it) {
    $updatable($this_addFastUpdater, new FastDuration(FastDuration__times_impl_h6amcp(it.eat_1, $this_addFastUpdater.kcb())));
    return Unit_instance;
  };
}
function addOptFixedUpdater$lambda($updatable, $time) {
  return function ($this$addFixedUpdater) {
    $updatable($this$addFixedUpdater, new Duration($time));
    return Unit_instance;
  };
}
function addUpdaterWithViews$lambda($updatable, $this_addUpdaterWithViews) {
  return function (it) {
    $updatable($this_addUpdaterWithViews, it.oh8_1, new Duration(Duration__times_impl_sfuzvp(it.qh8(), $this_addUpdaterWithViews.kcb())));
    return Unit_instance;
  };
}
function scrollParentsToMakeVisible$lambda(it) {
  return it instanceof UIScrollable;
}
function addFixedUpdater$lambda($accum, $time, $updatable, $this_addFixedUpdater, $limitCallsPerFrame) {
  return function ($this$addFastUpdater, dt) {
    $accum._v = new FastDuration(FastDuration__plus_impl_q1enab($accum._v.p1w_1, dt.p1w_1));
    var calls = 0;
    $l$loop: while (FastDuration__compareTo_impl_4fhnj7($accum._v.p1w_1, FastDuration__times_impl_h6amcp($time, 0.75)) >= 0) {
      $accum._v = new FastDuration(FastDuration__minus_impl_evs237($accum._v.p1w_1, $time));
      $updatable($this_addFixedUpdater);
      calls = calls + 1 | 0;
      if (calls >= $limitCallsPerFrame) {
        $accum._v = new FastDuration(Companion_instance_1.m1w());
        break $l$loop;
      }
    }
    var tmp;
    if (calls > 0) {
      var tmp_0;
      if (FastDuration__compareTo_impl_4fhnj7($accum._v.p1w_1, FastDuration__times_impl_h6amcp($time, 0.25)) < 0) {
        $accum._v = new FastDuration(Companion_instance_1.m1w());
        tmp_0 = Unit_instance;
      }
      tmp = tmp_0;
    }
    return Unit_instance;
  };
}
function addUpdater$lambda($updatable, $this_addUpdater) {
  return function (it) {
    $updatable($this_addUpdater, new Duration(Duration__times_impl_sfuzvp(it.fat(), $this_addUpdater.kcb())));
    return Unit_instance;
  };
}
function onStageResized$lambda($block) {
  return function (views) {
    $block(views, views.kag(), views.lag());
    return Unit_instance;
  };
}
function onStageResized$lambda_0($block) {
  return function (it) {
    $block(it.th8_1, it.th8_1.kag(), it.th8_1.lag());
    return Unit_instance;
  };
}
function deferWithViews$lambda($block) {
  return function ($this$onNextFrame, it) {
    $block(it);
    return Unit_instance;
  };
}
function onNextFrame$lambda($block, $closeable) {
  return function ($this$addUpdaterWithViews, views, _unused_var__etf5q3) {
    $block($this$addUpdaterWithViews, views);
    var tmp386_safe_receiver = $closeable._v;
    if (tmp386_safe_receiver == null)
      null;
    else {
      tmp386_safe_receiver.u6();
    }
    return Unit_instance;
  };
}
function scale$factory($b0) {
  return getPropertyCallableRef('scale', 0, KMutableProperty0, function () {
    return $b0.pcb();
  }, function (value) {
    return $b0.ocb(value);
  });
}
function currentMatrix$factory_0($b0) {
  return getPropertyCallableRef('currentMatrix', 0, KMutableProperty0, function () {
    return $b0.jah_1;
  }, function (value) {
    $b0.jah_1 = value;
    return Unit_instance;
  });
}
function addUpdater_1(_this__u8e3s4, referenceFps, first, updatable) {
  first = first === VOID ? true : first;
  var time = _Frequency___get_timeSpan__impl__f9mrmt(referenceFps);
  return addUpdater_0(_this__u8e3s4, first, VOID, addUpdater$lambda_0(updatable, time));
}
function addUpdater$lambda_0($updatable, $time) {
  return function ($this$addUpdater, it) {
    $updatable($this$addUpdater, Duration__div_impl_dknbf4(it.hk_1, $time));
    return Unit_instance;
  };
}
function *_generator_invoke__zhh2q8_51($this, $completion) {
  var tmp = $this.vh8_1.rh4(ensureNotNull(get_views($this.wh8_1)), VOID, $this.xh8_1.hh4_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function ViewFileRef$Mixin$lazyLoadRenderInternalActually$slambda_0($view, $ctx, this$0) {
  var i = new ViewFileRef$Mixin$lazyLoadRenderInternalActually$slambda($view, $ctx, this$0);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function *_generator_invoke__zhh2q8_52($this, $completion) {
  var tmp = $this.zh8_1.ah9($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_init__qn9atk($this, $completion) {
  var tmp = $this;
  var tmp_0 = debugBmpFont($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  tmp.tb7_1 = tmp_0;
  return Unit_instance;
}
function *_generator_closeSuspend__agjafw($this, $completion) {
  KorgeReload_getInstance().qa5();
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var this_0 = $this.gb8_1;
  var n = 0;
  while (n < this_0.j1()) {
    // Inline function 'korlibs.korge.view.Views.closeSuspend.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var tmp = this_0.i1(_unary__edvuaz).f1q($completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  $this.gb8_1.o3();
  cancel($this.yb6_1);
  $this.eb7_1.lar();
  return Unit_instance;
}
function Views$_init_$lambda_jivasj(it) {
  return Stage.jb6(it);
}
function Views$realSettingsFolder$delegate$lambda(this$0) {
  return function () {
    return !(this$0.gb7_1 == null) ? ensureNotNull(this$0.gb7_1) : StandardPaths_instance.p3i(this$0.bh9());
  };
}
function Views$close$slambda_0(this$0) {
  var i = new Views$close$slambda(this$0);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function Views$tempViewsPool$lambda(it) {
  return FastArrayList.g14();
}
function Views$lambda(this$0) {
  return function (it) {
    this$0.xb7_1.cad();
    return Unit_instance;
  };
}
function Views$lambda_0(this$0) {
  return function (it) {
    this$0.xb7_1.iai();
    return Unit_instance;
  };
}
var Companion_instance_37;
function Companion_getInstance_70() {
  return Companion_instance_37;
}
var Companion_instance_38;
function Companion_getInstance_71() {
  return Companion_instance_38;
}
function updateSingleViewWithViewsAll(_this__u8e3s4, views, delta) {
  // Inline function 'kotlin.also' call
  var this_0 = views.pb7_1;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.updateSingleViewWithViewsAll.<anonymous>' call
  this_0.eat_1 = delta;
  _this__u8e3s4.v8a(this_0);
  // Inline function 'kotlin.also' call
  var this_1 = views.qb7_1;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.updateSingleViewWithViewsAll.<anonymous>' call
  this_1.ph8_1 = delta;
  _this__u8e3s4.v8a(this_1);
}
function setBoundsInfo(_this__u8e3s4, reqVirtualSize, actualSize, scaleMode, anchor, virtualSize, targetSize) {
  scaleMode = scaleMode === VOID ? Companion_getInstance_31().r2v() : scaleMode;
  anchor = anchor === VOID ? Companion_getInstance_32().f2h() : anchor;
  virtualSize = virtualSize === VOID ? Ref_0() : virtualSize;
  targetSize = targetSize === VOID ? Ref_0() : targetSize;
  var reqVirtualSize_0 = toInt(reqVirtualSize);
  var actualSize_0 = toInt(actualSize);
  virtualSize.n3a(reqVirtualSize_0);
  targetSize.n3a(scaleMode.s2v(virtualSize.b2(), actualSize_0));
  var ratioX = targetSize.b2().a2v_1 / reqVirtualSize_0.a2v_1;
  var ratioY = targetSize.b2().b2v_1 / reqVirtualSize_0.b2v_1;
  var actualVirtualWidth = toIntRound(actualSize_0.a2v_1 / ratioX);
  var actualVirtualHeight = toIntRound(actualSize_0.b2v_1 / ratioY);
  _this__u8e3s4.eag(Companion_getInstance_41().i2m_1.m2n(ratioX, ratioY).v2n(toIntRound((actualVirtualWidth - reqVirtualSize_0.a2v_1 | 0) * anchor.y2g_1), toIntRound((actualVirtualHeight - reqVirtualSize_0.b2v_1 | 0) * anchor.z2g_1)));
  _this__u8e3s4.aag(_this__u8e3s4.fag().y2n());
  _this__u8e3s4.gag(_this__u8e3s4.fag().z2n());
  _this__u8e3s4.cag(_this__u8e3s4.bag().z2n());
  var tl = _this__u8e3s4.sag(Vector2D.k2l(0, 0));
  var br = _this__u8e3s4.sag(Vector2D.k2h(actualSize_0.a2v_1, actualSize_0.b2v_1));
  _this__u8e3s4.iag(Companion_getInstance_38().g2t(tl.i2h_1, tl.j2h_1, br.i2h_1, br.j2h_1));
}
function *_generator_views__wf4006($completion) {
  // Inline function 'korlibs.inject.Injector.get' call
  var tmp = injector($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return tmp.x87(getKClass(Views));
}
function views($completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_views__wf4006($completion), $completion);
}
function quality$factory($b0) {
  return getPropertyCallableRef('quality', 0, KMutableProperty0, function () {
    return $b0.vah();
  }, function (value) {
    return $b0.far(value);
  });
}
function continuousRenderMode$factory($b0) {
  return getPropertyCallableRef('continuousRenderMode', 0, KMutableProperty0, function () {
    return $b0.par();
  }, function (value) {
    return $b0.oar(value);
  });
}
function debugExtraFontScale$factory($b0) {
  return getPropertyCallableRef('debugExtraFontScale', 0, KMutableProperty0, function () {
    return $b0.ia7_1;
  }, function (value) {
    $b0.ia7_1 = value;
    return Unit_instance;
  });
}
function debugExtraFontColor$factory($b0) {
  return getPropertyCallableRef('debugExtraFontColor', 0, KMutableProperty0, function () {
    return new RGBA($b0.ja7_1);
  }, function (value) {
    $b0.ja7_1 = value.v59_1;
    return Unit_instance;
  });
}
function updatedSinceFrame$factory($b0) {
  return getPropertyCallableRef('updatedSinceFrame', 0, KMutableProperty0, function () {
    return $b0.aap_1;
  }, function (value) {
    $b0.aap_1 = value;
    return Unit_instance;
  });
}
function forceRenderEveryFrame$factory() {
  return getPropertyCallableRef('forceRenderEveryFrame', 1, KMutableProperty1, function (receiver) {
    return receiver.cbf();
  }, function (receiver, value) {
    return receiver.ibf(value);
  });
}
function forceRenderEveryFrame$factory_0() {
  return getPropertyCallableRef('forceRenderEveryFrame', 1, KMutableProperty1, function (receiver) {
    return receiver.cbf();
  }, function (receiver, value) {
    return receiver.ibf(value);
  });
}
function debugFontExtraScale$factory() {
  return getPropertyCallableRef('debugFontExtraScale', 1, KMutableProperty1, function (receiver) {
    return receiver.gh9();
  }, function (receiver, value) {
    return receiver.ybe(value);
  });
}
function debugFontExtraScale$factory_0() {
  return getPropertyCallableRef('debugFontExtraScale', 1, KMutableProperty1, function (receiver) {
    return receiver.gh9();
  }, function (receiver, value) {
    return receiver.ybe(value);
  });
}
function debugFontColor$factory() {
  return getPropertyCallableRef('debugFontColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(receiver.hh9());
  }, function (receiver, value) {
    return receiver.zbe(value.v59_1);
  });
}
function debugFontColor$factory_0() {
  return getPropertyCallableRef('debugFontColor', 1, KMutableProperty1, function (receiver) {
    return new RGBA(receiver.hh9());
  }, function (receiver, value) {
    return receiver.zbe(value.v59_1);
  });
}
function virtualSize$factory($b0) {
  return getPropertyCallableRef('virtualSize', 0, KMutableProperty0, function () {
    return $b0.tb8_1;
  }, function (value) {
    $b0.tb8_1 = value;
    return Unit_instance;
  });
}
function targetSize$factory($b0) {
  return getPropertyCallableRef('targetSize', 0, KMutableProperty0, function () {
    return $b0.vb8_1;
  }, function (value) {
    $b0.vb8_1 = value;
    return Unit_instance;
  });
}
function updatedSinceFrame$factory_0() {
  return getPropertyCallableRef('updatedSinceFrame', 1, KMutableProperty1, function (receiver) {
    return receiver.nh9();
  }, function (receiver, value) {
    return receiver.ph9(value);
  });
}
function updatedSinceFrame$factory_1() {
  return getPropertyCallableRef('updatedSinceFrame', 1, KMutableProperty1, function (receiver) {
    return receiver.nh9();
  }, function (receiver, value) {
    return receiver.ph9(value);
  });
}
function centerOnStage(_this__u8e3s4) {
  return centerYOnStage(centerXOnStage(_this__u8e3s4));
}
function centerYOnStage(_this__u8e3s4) {
  return centerYOn(_this__u8e3s4, _this__u8e3s4.f22());
}
function centerXOnStage(_this__u8e3s4) {
  return centerXOn(_this__u8e3s4, _this__u8e3s4.f22());
}
function centerYOn(_this__u8e3s4, other) {
  return alignY(_this__u8e3s4, other, 0.5, true);
}
function centerXOn(_this__u8e3s4, other) {
  return alignX(_this__u8e3s4, other, 0.5, true);
}
function alignY(_this__u8e3s4, other, ratio, inside, padding) {
  padding = padding === VOID ? 0.0 : padding;
  return alignXY(_this__u8e3s4, other, ratio, inside, false, padding);
}
function alignX(_this__u8e3s4, other, ratio, inside, padding) {
  padding = padding === VOID ? 0.0 : padding;
  return alignXY(_this__u8e3s4, other, ratio, inside, true, padding);
}
function alignXY(_this__u8e3s4, other, ratio, inside, doX, padding) {
  padding = padding === VOID ? 0.0 : padding;
  var bounds = other.vcd(_this__u8e3s4.w15());
  var localBounds = _this__u8e3s4.wbp();
  var ratioM1_1 = ratio * 2 - 1;
  var rratioM1_1 = inside ? ratioM1_1 : -ratioM1_1;
  var iratio = inside ? ratio : 1.0 - ratio;
  if (doX) {
    _this__u8e3s4.rbh(bounds.i2i_1 + bounds.k2i_1 * ratio - localBounds.p2i() - _this__u8e3s4.kcc() * iratio - padding * rratioM1_1);
  } else {
    _this__u8e3s4.sbh(bounds.j2i_1 + bounds.l2i_1 * ratio - localBounds.r2i() - _this__u8e3s4.mcc() * iratio - padding * rratioM1_1);
  }
  return _this__u8e3s4;
}
var UB_instance;
function UB_getInstance() {
  if (UB_instance === VOID)
    new UB();
  return UB_instance;
}
var Companion_instance_39;
function Companion_getInstance_72() {
  if (Companion_instance_39 === VOID)
    new Companion_24();
  return Companion_instance_39;
}
function updatedPoints($this) {
  var tmp = $this;
  // Inline function 'kotlin.collections.sumOf' call
  var sum = 0;
  var tmp0_iterator = $this.hcp_1.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    var tmp_0 = sum;
    // Inline function 'korlibs.korge.view.debug.DebugVertexView.updatedPoints.<anonymous>' call
    sum = tmp_0 + element.j1() | 0;
  }
  var tmp$ret$1 = sum;
  tmp.jcp_1 = new Float32Array(imul(tmp$ret$1, 2));
  var buffer = $this.jcp_1;
  var n = 0;
  $this.kcp_1.o3();
  $this.lcp_1 = Companion_getInstance_40().y2i();
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var this_0 = $this.hcp_1;
  var n_0 = 0;
  while (n_0 < this_0.j1()) {
    // Inline function 'korlibs.korge.view.debug.DebugVertexView.updatedPoints.<anonymous>' call
    var _unary__edvuaz = n_0;
    n_0 = _unary__edvuaz + 1 | 0;
    var points = this_0.i1(_unary__edvuaz);
    $this.kcp_1.f(new Batch(n / 2 | 0, points.j1()));
    if (points.b2l() >= 5) {
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = points.j1();
      if (inductionVariable < last)
        do {
          var n_1 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge.view.debug.DebugVertexView.updatedPoints.<anonymous>.<anonymous>' call
          var x = points.z2k(n_1, 0);
          var y = points.z2k(n_1, 1);
          var dx = points.z2k(n_1, 2);
          var dy = points.z2k(n_1, 3);
          var scale = points.z2k(n_1, 4);
          var px = x + dx * scale;
          var py = y + dy * scale;
          var _unary__edvuaz_0 = n;
          n = _unary__edvuaz_0 + 1 | 0;
          buffer[_unary__edvuaz_0] = px;
          var _unary__edvuaz_1 = n;
          n = _unary__edvuaz_1 + 1 | 0;
          buffer[_unary__edvuaz_1] = py;
          $this.lcp_1 = BoundsBuilder__plus_impl_cz7f7x_0($this.lcp_1, Vector2D.k2h(px, py));
        }
         while (inductionVariable < last);
    } else {
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable_0 = 0;
      var last_0 = points.j1();
      if (inductionVariable_0 < last_0)
        do {
          var n_2 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          // Inline function 'korlibs.korge.view.debug.DebugVertexView.updatedPoints.<anonymous>.<anonymous>' call
          var x_0 = points.z2k(n_2, 0);
          var y_0 = points.z2k(n_2, 1);
          var _unary__edvuaz_2 = n;
          n = _unary__edvuaz_2 + 1 | 0;
          buffer[_unary__edvuaz_2] = x_0;
          var _unary__edvuaz_3 = n;
          n = _unary__edvuaz_3 + 1 | 0;
          buffer[_unary__edvuaz_3] = y_0;
          $this.lcp_1 = BoundsBuilder__plus_impl_cz7f7x_0($this.lcp_1, Vector2D.k2h(x_0, y_0));
        }
         while (inductionVariable_0 < last_0);
    }
  }
}
function colorMul$factory_0($b0) {
  return getPropertyCallableRef('colorMul', 0, KMutableProperty0, function () {
    return new RGBA($b0.x58());
  }, function (value) {
    return $b0.m59(value.v59_1);
  });
}
function u_Col$factory() {
  return getPropertyCallableRef('u_Col', 1, KProperty1, function (receiver) {
    return receiver.wh9();
  }, null);
}
function u_Matrix$factory() {
  return getPropertyCallableRef('u_Matrix', 1, KProperty1, function (receiver) {
    return receiver.xh9();
  }, null);
}
function u_Col$factory_0() {
  return getPropertyCallableRef('u_Col', 1, KProperty1, function (receiver) {
    return receiver.wh9();
  }, null);
}
function u_Matrix$factory_0() {
  return getPropertyCallableRef('u_Matrix', 1, KProperty1, function (receiver) {
    return receiver.xh9();
  }, null);
}
function packAnchorComponent($this, v) {
  return numberToInt(clamp01_0(v) * 65535) & 65535;
}
function createProgram$blockN(_this__u8e3s4, $maxTexs, ref, block) {
  _this__u8e3s4.m93(ref, 0, $maxTexs - 1 | 0, block);
}
function FSprites$Companion$xyBuffer$delegate$lambda($this$PropertyThis) {
  return AGVertexData.g8u([Companion_getInstance_73().eha_1]);
}
function FSprites$Companion$fastSpriteBuffer$delegate$lambda($this$PropertyThis) {
  return AGVertexData.g8u([Companion_getInstance_73().fha_1, Companion_getInstance_73().gha_1, Companion_getInstance_73().hha_1, Companion_getInstance_73().iha_1, Companion_getInstance_73().jha_1, Companion_getInstance_73().kha_1]);
}
function FSprites$Companion$fastSpriteBufferMul$delegate$lambda($this$PropertyThis) {
  return AGVertexData.g8u([Companion_getInstance_73().lha_1]);
}
function FSprites$Companion$fastSpriteBufferTexId$delegate$lambda($this$PropertyThis) {
  return AGVertexData.g8u([Companion_getInstance_73().mha_1]);
}
function FSprites$Companion$buffers$delegate$lambda($this$PropertyThis) {
  return AGVertexArrayObject.s8t([Companion_getInstance_73().uha($this$PropertyThis), Companion_getInstance_73().vha($this$PropertyThis), Companion_getInstance_73().wha($this$PropertyThis), Companion_getInstance_73().xha($this$PropertyThis)], false);
}
function FSprites$Companion$createProgram$lambda($texSize) {
  return function ($this$blockN, it) {
    $this$blockN.f92($texSize, FspritesUB_getInstance().chb_1[it]);
    return Unit_instance;
  };
}
function FSprites$Companion$createProgram$lambda_0($$this$FragmentShaderDefault) {
  return function ($this$blockN, it) {
    $this$blockN.f92($this$blockN.i92(), $this$blockN.q92(Companion_getInstance_8().pad_1[it], $this$blockN.o92($$this$FragmentShaderDefault.b92(), 'xy')));
    return Unit_instance;
  };
}
var FspritesUB_instance;
function FspritesUB_getInstance() {
  if (FspritesUB_instance === VOID)
    new FspritesUB();
  return FspritesUB_instance;
}
var Companion_instance_40;
function Companion_getInstance_73() {
  if (Companion_instance_40 === VOID)
    new Companion_25();
  return Companion_instance_40;
}
function _FSprite___init__impl__y4bm26(id) {
  return id;
}
function _FSprite___get_id__impl__e8b6fk($this) {
  return $this;
}
function _FSprite___get_offset__impl__t72eo8($this) {
  return _FSprite___get_id__impl__e8b6fk($this);
}
function _FSprite___get_index__impl__7khb8f($this) {
  return _FSprite___get_offset__impl__t72eo8($this) / 8 | 0;
}
function FSprite__toString_impl_v3ejgq($this) {
  return 'FSprite(id=' + $this + ')';
}
function FSprite__hashCode_impl_i6jlpj($this) {
  return $this;
}
function FSprite__equals_impl_v8570j($this, other) {
  if (!(other instanceof FSprite))
    return false;
  if (!($this === (other instanceof FSprite ? other.mhe_1 : THROW_CCE())))
    return false;
  return true;
}
function xyBuffer$factory() {
  return getPropertyCallableRef('xyBuffer', 2, KProperty2, function (receiver1, receiver2) {
    return receiver1.uha(receiver2);
  }, null);
}
function fastSpriteBuffer$factory() {
  return getPropertyCallableRef('fastSpriteBuffer', 2, KProperty2, function (receiver1, receiver2) {
    return receiver1.vha(receiver2);
  }, null);
}
function fastSpriteBufferMul$factory() {
  return getPropertyCallableRef('fastSpriteBufferMul', 2, KProperty2, function (receiver1, receiver2) {
    return receiver1.wha(receiver2);
  }, null);
}
function fastSpriteBufferTexId$factory() {
  return getPropertyCallableRef('fastSpriteBufferTexId', 2, KProperty2, function (receiver1, receiver2) {
    return receiver1.xha(receiver2);
  }, null);
}
function buffers$factory() {
  return getPropertyCallableRef('buffers', 2, KProperty2, function (receiver1, receiver2) {
    return receiver1.bhe(receiver2);
  }, null);
}
var Companion_instance_41;
function Companion_getInstance_74() {
  return Companion_instance_41;
}
var Companion_instance_42;
function Companion_getInstance_75() {
  return Companion_instance_42;
}
var ConvoluteUB_instance;
function ConvoluteUB_getInstance() {
  if (ConvoluteUB_instance === VOID)
    new ConvoluteUB();
  return ConvoluteUB_instance;
}
var Companion_instance_43;
function Companion_getInstance_76() {
  if (Companion_instance_43 === VOID)
    new Companion_28();
  return Companion_instance_43;
}
function u_ApplyAlpha$factory() {
  return getPropertyCallableRef('u_ApplyAlpha', 1, KProperty1, function (receiver) {
    return receiver.ahh();
  }, null);
}
function u_Dist$factory() {
  return getPropertyCallableRef('u_Dist', 1, KProperty1, function (receiver) {
    return receiver.bhh();
  }, null);
}
function u_Weights$factory() {
  return getPropertyCallableRef('u_Weights', 1, KProperty1, function (receiver) {
    return receiver.chh();
  }, null);
}
function u_ApplyAlpha$factory_0() {
  return getPropertyCallableRef('u_ApplyAlpha', 1, KProperty1, function (receiver) {
    return receiver.ahh();
  }, null);
}
function u_Dist$factory_0() {
  return getPropertyCallableRef('u_Dist', 1, KProperty1, function (receiver) {
    return receiver.bhh();
  }, null);
}
function u_Weights$factory_0() {
  return getPropertyCallableRef('u_Weights', 1, KProperty1, function (receiver) {
    return receiver.chh();
  }, null);
}
function DirectionalBlurFilter$Companion$fragment$lambda($loopLen, $gaussianResult, $$this$FragmentShaderDefault) {
  return function ($this$ELSE) {
    $this$ELSE.f92($this$ELSE.i92(), $this$ELSE.m92([$this$ELSE.l92(0.0), $this$ELSE.l92(0.0), $this$ELSE.l92(0.0), $this$ELSE.l92(0.0)]));
    $this$ELSE.f92($loopLen, $this$ELSE.c94($this$ELSE.i94(BlurUB_getInstance().shi())));
    // Inline function 'korlibs.graphics.shader.Builder.FOR_0_UNTIL_FIXED_BREAK' call
    var len = $loopLen;
    // Inline function 'korlibs.graphics.shader.Builder.FOR_0_UNTIL_FIXED' call
    // Inline function 'korlibs.graphics.shader.Builder.FOR_0_UNTIL' call
    var len_0 = $this$ELSE.t94(256);
    var temp = $this$ELSE.u93(VarType_Int1_getInstance());
    // Inline function 'kotlin.apply' call
    var this_0 = $this$ELSE.w92();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.Builder.FOR_0_UNTIL.<anonymous>' call
    // Inline function 'korlibs.graphics.shader.Builder.FOR_0_UNTIL_FIXED_BREAK.<anonymous>' call
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = this_0.s95(temp, len);
    var body = this_0.w92();
    // Inline function 'korlibs.graphics.shader.Builder.FOR_0_UNTIL_FIXED_BREAK.<anonymous>.<anonymous>' call
    body.r93();
    var stmIf = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_0.e92_1.f(stmIf);
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>.<anonymous>.<anonymous>' call
    var xfloat = this_0.u93(this_0.w93());
    this_0.f92(xfloat, this_0.d94(temp));
    this_0.f92($gaussianResult, this_0.j92(BlurUB_getInstance().thi(), this_0.f94(this_0.j92(this_0.j92(this_0.f95(xfloat), xfloat), BlurUB_getInstance().uhi()))));
    var addTemp = this_0.u93(this_0.x93());
    this_0.f92(addTemp, this_0.j92(this_0.j92(BlurUB_getInstance().vhi(), xfloat), TexInfoUB_getInstance().ehj()));
    this_0.f92(this_0.i92(), this_0.i95(this_0.i92(), this_0.j92(Companion_getInstance_83().ghj(this_0, $$this$FragmentShaderDefault.p92(), this_0.i95(Companion_getInstance_83().fhj(this_0), addTemp), true), $gaussianResult)));
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond_0 = this_0.m95(temp, this_0.t94(0));
    var body_0 = this_0.w92();
    // Inline function 'korlibs.korge.view.filter.Companion.fragment.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    body_0.f92(body_0.i92(), body_0.i95(body_0.i92(), body_0.j92(Companion_getInstance_83().ghj(body_0, $$this$FragmentShaderDefault.p92(), body_0.g95(Companion_getInstance_83().fhj(body_0), addTemp), true), $gaussianResult)));
    var stmIf_0 = new If(cond_0, body_0.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    this_0.e92_1.f(stmIf_0);
    var body_1 = this_0;
    var stmFor = new ForSimple(temp, $this$ELSE.t94(0), len_0, body_1.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    $this$ELSE.e92_1.f(stmFor);
    return Unit_instance;
  };
}
var BlurUB_instance;
function BlurUB_getInstance() {
  if (BlurUB_instance === VOID)
    new BlurUB();
  return BlurUB_instance;
}
var Companion_instance_44;
function Companion_getInstance_77() {
  if (Companion_instance_44 === VOID)
    new Companion_29();
  return Companion_instance_44;
}
function _get_rradius__1gjegj($this) {
  return $this.whf_1 * $this.yhf_1;
}
function gaussian($this, x, constant1, constant2) {
  // Inline function 'kotlin.math.exp' call
  var x_0 = -x * x * constant2;
  return constant1 * Math.exp(x_0);
}
function u_radius$factory() {
  return getPropertyCallableRef('u_radius', 1, KProperty1, function (receiver) {
    return receiver.shi();
  }, null);
}
function u_constant1$factory() {
  return getPropertyCallableRef('u_constant1', 1, KProperty1, function (receiver) {
    return receiver.thi();
  }, null);
}
function u_constant2$factory() {
  return getPropertyCallableRef('u_constant2', 1, KProperty1, function (receiver) {
    return receiver.uhi();
  }, null);
}
function u_direction$factory() {
  return getPropertyCallableRef('u_direction', 1, KProperty1, function (receiver) {
    return receiver.vhi();
  }, null);
}
function u_radius$factory_0() {
  return getPropertyCallableRef('u_radius', 1, KProperty1, function (receiver) {
    return receiver.shi();
  }, null);
}
function u_constant1$factory_0() {
  return getPropertyCallableRef('u_constant1', 1, KProperty1, function (receiver) {
    return receiver.thi();
  }, null);
}
function u_constant2$factory_0() {
  return getPropertyCallableRef('u_constant2', 1, KProperty1, function (receiver) {
    return receiver.uhi();
  }, null);
}
function u_direction$factory_0() {
  return getPropertyCallableRef('u_direction', 1, KProperty1, function (receiver) {
    return receiver.vhi();
  }, null);
}
var Companion_instance_45;
function Companion_getInstance_78() {
  if (Companion_instance_45 === VOID)
    new Companion_30();
  return Companion_instance_45;
}
function DropshadowFilter$render$lambda($ctx, this$0, $filterScale, $blendMode) {
  return function (newtex, matrix) {
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = $ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    var tmp443_x = this$0.mhj_1 * $filterScale;
    var tmp444_y = this$0.nhj_1 * $filterScale;
    var tmp445_filtering = this$0.qhj_1;
    var tmp446_colorMul = this$0.ohj_1;
    var tmp447_program = Companion_getInstance_78().lhj_1;
    this_0.aa9(newtex, tmp443_x, tmp444_y, VOID, VOID, matrix, tmp445_filtering, tmp446_colorMul, $blendMode, tmp447_program);
    return Unit_instance;
  };
}
function filtering$factory($b0) {
  return getPropertyCallableRef('filtering', 0, KMutableProperty0, function () {
    return $b0.nhf();
  }, function (value) {
    return $b0.fhf(value);
  });
}
function get_renderToTextureResultPool(_this__u8e3s4) {
  _init_properties_Filter_kt__xu8pbc();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = renderToTextureResultPool$delegate;
    var property = renderToTextureResultPool$factory();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var renderToTextureResultPool$delegate;
var Companion_instance_46;
function Companion_getInstance_79() {
  if (Companion_instance_46 === VOID)
    new Companion_31();
  return Companion_instance_46;
}
function expandedBorderRectangle(_this__u8e3s4, inp) {
  _init_properties_Filter_kt__xu8pbc();
  return inp.f2u(getBorder(_this__u8e3s4, toIntCeil(inp.k2i_1), toIntCeil(inp.l2i_1)));
}
function getBorder(_this__u8e3s4, texWidth, texHeight) {
  _init_properties_Filter_kt__xu8pbc();
  return _this__u8e3s4.ahg(texWidth, texHeight);
}
function renderToTextureWithBorder(_this__u8e3s4, ctx, matrix, texture, texWidth, texHeight, filterScale, block) {
  _init_properties_Filter_kt__xu8pbc();
  var filter = _this__u8e3s4;
  var margin = getBorder(filter, texWidth, texHeight);
  var borderLeft = toIntCeil(margin.d2m_1 * filterScale);
  var borderTop = toIntCeil(margin.a2m_1 * filterScale);
  var newTexWidth = texWidth + toIntCeil(margin.x2l() * filterScale) | 0;
  var newTexHeight = texHeight + toIntCeil(margin.y2l() * filterScale) | 0;
  // Inline function 'korlibs.korge.render.RenderContext.renderToTexture' call
  ctx.ya8();
  // Inline function 'korlibs.korge.render.RenderContext.tempAllocateFrameBuffer' call
  var rb = ctx.kai(newTexWidth, newTexHeight, false, true, 1);
  try {
    // Inline function 'korlibs.korge.render.RenderContext.renderToTexture.<anonymous>' call
    // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer' call
    // Inline function 'korlibs.korge.render.RenderContext.setFrameBufferTemporally' call
    ctx.mai(rb);
    try {
      // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer.<anonymous>' call
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
      var this_0 = ctx.ka7_1;
      var this_1 = this_0.ta7_1;
      if (!(this_1.oa7_1 === this_0)) {
        this_1.oa7_1 = this_0;
        this_1.ya8();
      }
      $l$block_0: {
        // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
        var scissor = AGScissor.p8v(0, 0, rb.a8k_1, rb.b8k_1);
        var temp = this_0.ra8_1;
        this_0.eaf();
        this_0.ra8_1 = scissor;
        try {
          if (true) {
            ctx.eai(Colors_getInstance().l4r_1);
          }
          // Inline function 'korlibs.korge.render.RenderContext.renderToTexture.<anonymous>.<anonymous>' call
          // Inline function 'korlibs.korge.view.filter.renderToTextureWithBorder.<anonymous>' call
          // Inline function 'korlibs.korge.render.BatchBuilder2D.setViewMatrixTemp' call
          var this_2 = ctx.ka7_1;
          var matrix_0 = Companion_getInstance_41().i2m_1;
          var this_3 = this_2.ta7_1;
          this_3.ya8();
          var temp4 = this_3.za6_1;
          var temp2d = this_3.aa7_1;
          this_3.aa7_1 = matrix_0;
          this_3.za6_1 = toMatrix4(matrix_0);
          $l$block: {
            // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
            var this_4 = this_3.y8r(ProjViewUB_getInstance());
            var _unary__edvuaz = this_4.n8t();
            this_4.v9h(_unary__edvuaz + 1 | 0);
            this_4.w9h(this_4.n8t() + 1 | 0);
            var blockSize = this_4.r8r_1;
            var index0 = imul(this_4.n8t() - 1 | 0, blockSize);
            var index1 = imul(this_4.n8t(), blockSize);
            var texBlockSize = this_4.o8r_1.s8z().j1();
            var texIndex0 = imul(this_4.n8t() - 1 | 0, texBlockSize);
            var texIndex1 = imul(this_4.n8t(), texBlockSize);
            if (this_4.n8t() > 0) {
              arraycopy(this_4.t8r_1, index0, this_4.t8r_1, index1, blockSize);
            } else {
              arrayfill(this_4.t8r_1, 0, 0, blockSize);
            }
            // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp.<anonymous>' call
            var $this$push = this_4.o8r_1;
            this_4.u8r_1.m9i($this$push.r8z(), this_3.za6_1);
            if (true && this_4.n8t() >= 1) {
              var equals = arrayequal(this_4.t8r_1, index0, this_4.t8r_1, index1, blockSize);
              if (equals) {
                var _unary__edvuaz_0 = this_4.n8t();
                this_4.v9h(_unary__edvuaz_0 - 1 | 0);
                break $l$block;
              }
            }
          }
          var tmp;
          try {
            filter.bhg(ctx, Companion_getInstance_41().i2m_1.p2n(borderLeft, borderTop), texture, newTexWidth, newTexHeight, Colors_getInstance().g4r_1, Companion_getInstance_17().ra5_1, filterScale);
            tmp = Unit_instance;
          }finally {
            this_3.ya8();
            this_3.za6_1 = temp4;
            this_3.aa7_1 = temp2d;
            this_3.y8r(ProjViewUB_getInstance()).x9h();
          }
          break $l$block_0;
        }finally {
          this_0.eaf();
          this_0.ra8_1 = temp;
        }
      }
    }finally {
      ctx.nai();
    }
    // Inline function 'korlibs.korge.view.filter.renderToTextureWithBorder.<anonymous>' call
    block(Texture_0(rb).v42(0, 0, newTexWidth, newTexHeight), matrix.s2n(-borderLeft | 0, -borderTop | 0));
    ctx.ya8();
  }finally {
    ctx.lai(rb);
  }
}
function renderToTextureWithBorderUnsafe(_this__u8e3s4, ctx, matrix, texture, texWidth, texHeight, filterScale, result) {
  result = result === VOID ? new RenderToTextureResult() : result;
  _init_properties_Filter_kt__xu8pbc();
  var filter = _this__u8e3s4;
  var margin = getBorder(filter, texWidth, texHeight);
  var borderLeft = toIntCeil(margin.d2m_1 * filterScale);
  var borderTop = toIntCeil(margin.a2m_1 * filterScale);
  var newTexWidth = texWidth + toIntCeil(margin.x2l() * filterScale) | 0;
  var newTexHeight = texHeight + toIntCeil(margin.y2l() * filterScale) | 0;
  ctx.ya8();
  var fb = ctx.kai(newTexWidth, newTexHeight);
  result.khg_1 = borderLeft;
  result.lhg_1 = borderTop;
  result.ihg_1 = newTexWidth;
  result.jhg_1 = newTexHeight;
  result.ohg_1 = texture;
  result.hhg_1 = filter;
  result.mhg_1 = filterScale;
  result.nhg_1 = matrix.s2n(-borderLeft | 0, -borderTop | 0);
  result.rhg_1 = ctx;
  result.phg_1 = fb;
  return result;
}
function renderToTextureResultPool$delegate$lambda() {
  _init_properties_Filter_kt__xu8pbc();
  var tmp = renderToTextureResultPool$delegate$lambda$lambda;
  return Pool.c39(tmp, VOID, renderToTextureResultPool$delegate$lambda$lambda_0);
}
function renderToTextureResultPool$delegate$lambda$lambda(it) {
  _init_properties_Filter_kt__xu8pbc();
  it.o18();
  return Unit_instance;
}
function renderToTextureResultPool$delegate$lambda$lambda_0(it) {
  _init_properties_Filter_kt__xu8pbc();
  return new RenderToTextureResult();
}
function renderToTextureResultPool$factory() {
  return getPropertyCallableRef('renderToTextureResultPool', 1, KProperty1, function (receiver) {
    return get_renderToTextureResultPool(receiver);
  }, null);
}
var properties_initialized_Filter_kt_lkm0w6;
function _init_properties_Filter_kt__xu8pbc() {
  if (!properties_initialized_Filter_kt_lkm0w6) {
    properties_initialized_Filter_kt_lkm0w6 = true;
    renderToTextureResultPool$delegate = new Property(VOID, renderToTextureResultPool$delegate$lambda);
  }
}
var Companion_instance_47;
function Companion_getInstance_80() {
  if (Companion_instance_47 === VOID)
    new Companion_32();
  return Companion_instance_47;
}
var Linear_instance;
function Linear_getInstance() {
  if (Linear_instance === VOID)
    new Linear();
  return Linear_instance;
}
var Nearest_instance;
function Nearest_getInstance() {
  if (Nearest_instance === VOID)
    new Nearest();
  return Nearest_instance;
}
function sin01(_this__u8e3s4, $this, arg) {
  return _this__u8e3s4.y93(_this__u8e3s4.j92(arg, _this__u8e3s4.j92(_this__u8e3s4.l92(3.141592653589793), _this__u8e3s4.l92(0.5))));
}
function PageFilter$Companion$fragment$lambda($vr, $offset, $tmp, $amplitudes) {
  return function ($this$ELSE) {
    var ratio = $this$ELSE.i95($this$ELSE.l92(1.0), $this$ELSE.u92($this$ELSE.g95($vr, $offset), $this$ELSE.g95($this$ELSE.l92(1.0), $offset)));
    $this$ELSE.f92($tmp, $this$ELSE.n94($this$ELSE.z94($amplitudes, 2), $this$ELSE.z94($amplitudes, 1), sin01($this$ELSE, Companion_getInstance_81(), ratio)));
    return Unit_instance;
  };
}
var PageUB_instance;
function PageUB_getInstance() {
  if (PageUB_instance === VOID)
    new PageUB();
  return PageUB_instance;
}
var Companion_instance_48;
function Companion_getInstance_81() {
  if (Companion_instance_48 === VOID)
    new Companion_33();
  return Companion_instance_48;
}
function u_Offset$factory() {
  return getPropertyCallableRef('u_Offset', 1, KProperty1, function (receiver) {
    return receiver.chk();
  }, null);
}
function u_HAmplitude$factory() {
  return getPropertyCallableRef('u_HAmplitude', 1, KProperty1, function (receiver) {
    return receiver.dhk();
  }, null);
}
function u_VAmplitude$factory() {
  return getPropertyCallableRef('u_VAmplitude', 1, KProperty1, function (receiver) {
    return receiver.ehk();
  }, null);
}
function u_Offset$factory_0() {
  return getPropertyCallableRef('u_Offset', 1, KProperty1, function (receiver) {
    return receiver.chk();
  }, null);
}
function u_HAmplitude$factory_0() {
  return getPropertyCallableRef('u_HAmplitude', 1, KProperty1, function (receiver) {
    return receiver.dhk();
  }, null);
}
function u_VAmplitude$factory_0() {
  return getPropertyCallableRef('u_VAmplitude', 1, KProperty1, function (receiver) {
    return receiver.ehk();
  }, null);
}
var Companion_instance_49;
function Companion_getInstance_82() {
  if (Companion_instance_49 === VOID)
    new Companion_34();
  return Companion_instance_49;
}
function _get__program__9g4ap2($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.thh_1;
  _program$factory();
  return this_0.b2();
}
function ShaderFilter$BaseProgramProvider$_program$delegate$lambda(this$0) {
  return function () {
    return this$0.uhh(this$0.vhh(), this$0.qhh());
  };
}
var TexInfoUB_instance;
function TexInfoUB_getInstance() {
  if (TexInfoUB_instance === VOID)
    new TexInfoUB();
  return TexInfoUB_instance;
}
var Companion_instance_50;
function Companion_getInstance_83() {
  if (Companion_instance_50 === VOID)
    new Companion_35();
  return Companion_instance_50;
}
function _restoreUniforms($this, ctx, filterScale) {
  // Inline function 'korlibs.memory.fastForEachOneBits' call
  var value = $this.mhf_1;
  var index = 0;
  while (!(value === 0)) {
    var shift = countTrailingZeroBits(value);
    index = index + shift | 0;
    if (index < 32) {
      // Inline function 'korlibs.korge.view.filter.ShaderFilter._restoreUniforms.<anonymous>' call
      var it = index;
      ctx.va6_1.u8o($this.lhf_1, it);
      $this.lhf_1.w8o(it, null);
    }
    value = value >>> (shift + 1 | 0) | 0;
    index = index + 1 | 0;
  }
  $this.mhf_1 = 0;
}
function _updateUniforms($this, ctx, filterScale, texture, texWidth, texHeight) {
  $this.hhf_1 = Vector2D.u2n(texture.f41_1.j2y(), texture.f41_1.k2y());
  $this.jhf_1 = Vector2D.u2n(1.0 / texture.f41_1.j2y(), 1.0 / texture.f41_1.k2y());
  $this.ihf_1 = Vector2D.u2n(texWidth / texture.f41_1.j2y(), texHeight / texture.f41_1.k2y());
  $l$block: {
    // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
    var this_0 = ctx.y8r(TexInfoUB_getInstance());
    var _unary__edvuaz = this_0.n8t();
    this_0.v9h(_unary__edvuaz + 1 | 0);
    this_0.w9h(this_0.n8t() + 1 | 0);
    var blockSize = this_0.r8r_1;
    var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
    var index1 = imul(this_0.n8t(), blockSize);
    var texBlockSize = this_0.o8r_1.s8z().j1();
    var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
    var texIndex1 = imul(this_0.n8t(), texBlockSize);
    if (this_0.n8t() > 0) {
      arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
    } else {
      arrayfill(this_0.t8r_1, 0, 0, blockSize);
    }
    // Inline function 'korlibs.korge.view.filter.ShaderFilter._updateUniforms.<anonymous>' call
    var $this$push = this_0.o8r_1;
    var it = this_0.u8r_1;
    it.c9i($this$push.bhl(), filterScale);
    it.g9i($this$push.zhk(), $this.hhf_1);
    it.g9i($this$push.ahl(), $this.ihf_1);
    it.g9i($this$push.ehj(), $this.jhf_1);
    if (true && this_0.n8t() >= 1) {
      var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      if (equals) {
        var _unary__edvuaz_0 = this_0.n8t();
        this_0.v9h(_unary__edvuaz_0 - 1 | 0);
        break $l$block;
      }
    }
  }
  $this.jhi(ctx, filterScale);
}
function u_TextureSize$factory() {
  return getPropertyCallableRef('u_TextureSize', 1, KProperty1, function (receiver) {
    return receiver.zhk();
  }, null);
}
function u_MaxTexCoords$factory() {
  return getPropertyCallableRef('u_MaxTexCoords', 1, KProperty1, function (receiver) {
    return receiver.ahl();
  }, null);
}
function u_StdTexDerivates$factory() {
  return getPropertyCallableRef('u_StdTexDerivates', 1, KProperty1, function (receiver) {
    return receiver.ehj();
  }, null);
}
function u_filterScale$factory() {
  return getPropertyCallableRef('u_filterScale', 1, KProperty1, function (receiver) {
    return receiver.bhl();
  }, null);
}
function u_TextureSize$factory_0() {
  return getPropertyCallableRef('u_TextureSize', 1, KProperty1, function (receiver) {
    return receiver.zhk();
  }, null);
}
function u_MaxTexCoords$factory_0() {
  return getPropertyCallableRef('u_MaxTexCoords', 1, KProperty1, function (receiver) {
    return receiver.ahl();
  }, null);
}
function u_StdTexDerivates$factory_0() {
  return getPropertyCallableRef('u_StdTexDerivates', 1, KProperty1, function (receiver) {
    return receiver.ehj();
  }, null);
}
function u_filterScale$factory_0() {
  return getPropertyCallableRef('u_filterScale', 1, KProperty1, function (receiver) {
    return receiver.bhl();
  }, null);
}
function _program$factory() {
  return getPropertyCallableRef('_program', 1, KProperty1, function (receiver) {
    return _get__program__9g4ap2(receiver);
  }, null);
}
var Companion_instance_51;
function Companion_getInstance_84() {
  if (Companion_instance_51 === VOID)
    new Companion_36();
  return Companion_instance_51;
}
function createTransitionBox($this, paint) {
  return new Transition(Bitmap32Context2d($this.uhl_1, $this.uhl_1, VOID, TransitionFilter$Transition$Companion$createTransitionBox$lambda(paint)));
}
function createLinearTransitionBox($this, x0, y0, x1, y1) {
  // Inline function 'korlibs.image.paint.LinearGradientPaint' call
  var cycle = CycleMethod_NO_CYCLE_getInstance();
  var transform = Companion_getInstance_41().i2m_1;
  // Inline function 'kotlin.also' call
  var this_0 = new GradientPaint(GradientKind_LINEAR_getInstance(), numberToDouble(x0), numberToDouble(y0), 0.0, numberToDouble(x1), numberToDouble(y1), 0.0, VOID, VOID, cycle, transform);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.image.paint.LinearGradientPaint.<anonymous>' call
  return createTransitionBox($this, this_0);
}
function TransitionFilter$Transition$Companion$createTransitionBox$lambda($paint) {
  return function ($this$Bitmap32Context2d) {
    var paint = $paint.c6r(0.0, Colors_getInstance().g4r_1).c6r(1.0, Colors_getInstance().h4r_1);
    if (true) {
      $this$Bitmap32Context2d.b5t();
    }
    // Inline function 'korlibs.korge.view.filter.Companion.createTransitionBox.<anonymous>.<anonymous>' call
    $this$Bitmap32Context2d.a4b(0, 0, Companion_getInstance_85().uhl_1, Companion_getInstance_85().uhl_1);
    $this$Bitmap32Context2d.x76(paint, null);
    return Unit_instance;
  };
}
function TransitionFilter$Transition$Companion$VERTICAL$delegate$lambda() {
  return createLinearTransitionBox(Companion_getInstance_85(), 0, 0, 0, Companion_getInstance_85().uhl_1);
}
function TransitionFilter$Transition$Companion$HORIZONTAL$delegate$lambda() {
  return createLinearTransitionBox(Companion_getInstance_85(), 0, 0, Companion_getInstance_85().uhl_1, 0);
}
function TransitionFilter$Transition$Companion$DIAGONAL1$delegate$lambda() {
  return createLinearTransitionBox(Companion_getInstance_85(), 0, 0, Companion_getInstance_85().uhl_1, Companion_getInstance_85().uhl_1);
}
function TransitionFilter$Transition$Companion$DIAGONAL2$delegate$lambda() {
  return createLinearTransitionBox(Companion_getInstance_85(), Companion_getInstance_85().uhl_1, 0, 0, Companion_getInstance_85().uhl_1);
}
function TransitionFilter$Transition$Companion$CIRCULAR$delegate$lambda() {
  var tmp = Companion_getInstance_85();
  // Inline function 'korlibs.image.paint.RadialGradientPaint' call
  var x0 = Companion_getInstance_85().uhl_1 / 2 | 0;
  var y0 = Companion_getInstance_85().uhl_1 / 2 | 0;
  var x1 = Companion_getInstance_85().uhl_1 / 2 | 0;
  var y1 = Companion_getInstance_85().uhl_1 / 2 | 0;
  var r1 = Companion_getInstance_85().uhl_1 / 2 | 0;
  var cycle = CycleMethod_NO_CYCLE_getInstance();
  var transform = Companion_getInstance_41().i2m_1;
  // Inline function 'kotlin.also' call
  var this_0 = new GradientPaint(GradientKind_RADIAL_getInstance(), numberToDouble(x0), numberToDouble(y0), numberToDouble(0), numberToDouble(x1), numberToDouble(y1), numberToDouble(r1), VOID, VOID, cycle, transform);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.image.paint.RadialGradientPaint.<anonymous>' call
  return createTransitionBox(tmp, this_0);
}
function TransitionFilter$Transition$Companion$SWEEP$delegate$lambda() {
  var tmp = Companion_getInstance_85();
  // Inline function 'korlibs.image.paint.SweepGradientPaint' call
  var x0 = Companion_getInstance_85().uhl_1 / 2 | 0;
  var y0 = Companion_getInstance_85().uhl_1 / 2 | 0;
  var startAngle = Companion_getInstance_35().r2h_1;
  var transform = Companion_getInstance_41().i2m_1;
  // Inline function 'kotlin.also' call
  var tmp223_kind = GradientKind_SWEEP_getInstance();
  var tmp224_x0 = numberToDouble(x0);
  var tmp225_y0 = numberToDouble(y0);
  var this_0 = new GradientPaint(tmp223_kind, tmp224_x0, tmp225_y0, 0.0, 0.0, 0.0, 0.0, VOID, VOID, VOID, transform, VOID, VOID, startAngle);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.image.paint.SweepGradientPaint.<anonymous>' call
  return createTransitionBox(tmp, this_0);
}
var Companion_instance_52;
function Companion_getInstance_85() {
  if (Companion_instance_52 === VOID)
    new Companion_37();
  return Companion_instance_52;
}
var TransitionUB_instance;
function TransitionUB_getInstance() {
  if (TransitionUB_instance === VOID)
    new TransitionUB();
  return TransitionUB_instance;
}
var Companion_instance_53;
function Companion_getInstance_86() {
  if (Companion_instance_53 === VOID)
    new Companion_38();
  return Companion_instance_53;
}
function CIRCULAR$factory() {
  return getPropertyCallableRef('CIRCULAR', 1, KProperty1, function (receiver) {
    return receiver.bhm();
  }, null);
}
function SWEEP$factory() {
  return getPropertyCallableRef('SWEEP', 1, KProperty1, function (receiver) {
    return receiver.chm();
  }, null);
}
function u_Reversed$factory() {
  return getPropertyCallableRef('u_Reversed', 1, KProperty1, function (receiver) {
    return receiver.lhm();
  }, null);
}
function u_Spread$factory() {
  return getPropertyCallableRef('u_Spread', 1, KProperty1, function (receiver) {
    return receiver.mhm();
  }, null);
}
function u_Ratio$factory() {
  return getPropertyCallableRef('u_Ratio', 1, KProperty1, function (receiver) {
    return receiver.nhm();
  }, null);
}
function u_Reversed$factory_0() {
  return getPropertyCallableRef('u_Reversed', 1, KProperty1, function (receiver) {
    return receiver.lhm();
  }, null);
}
function u_Spread$factory_0() {
  return getPropertyCallableRef('u_Spread', 1, KProperty1, function (receiver) {
    return receiver.mhm();
  }, null);
}
function u_Ratio$factory_0() {
  return getPropertyCallableRef('u_Ratio', 1, KProperty1, function (receiver) {
    return receiver.nhm();
  }, null);
}
function get_backdropFilter(_this__u8e3s4) {
  // Inline function 'korlibs.korge.view.View.getRenderPhaseOfTypeOrNull' call
  var tmp357_safe_receiver = _this__u8e3s4.hbb_1;
  var tmp;
  if (tmp357_safe_receiver == null) {
    tmp = null;
  } else {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator = tmp357_safe_receiver.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.view.View.getRenderPhaseOfTypeOrNull.<anonymous>' call
        if (element instanceof ViewRenderPhaseBackdropFilter) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    tmp = tmp$ret$1;
  }
  var tmp_0 = tmp;
  var tmp452_safe_receiver = (tmp_0 == null ? true : tmp_0 instanceof ViewRenderPhaseBackdropFilter) ? tmp_0 : null;
  return tmp452_safe_receiver == null ? null : tmp452_safe_receiver.ehn_1;
}
function set_filterScale(_this__u8e3s4, _set____db54di) {
  _init_properties_ViewFilter_kt__pd9xp9();
  var this_0 = filterScale$delegate;
  var property = filterScale$factory();
  var value = this_0.t13_1(_this__u8e3s4, _set____db54di);
  var tmp0_elvis_lhs = this_0.r13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, value);
  return Unit_instance;
}
function get_filterScale(_this__u8e3s4) {
  _init_properties_ViewFilter_kt__pd9xp9();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = filterScale$delegate;
    var property = filterScale$factory_0();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var filterScale$delegate;
function renderFiltered(_this__u8e3s4, ctx, filter, first) {
  first = first === VOID ? true : first;
  _init_properties_ViewFilter_kt__pd9xp9();
  var bounds = _this__u8e3s4.wbp(VOID, false);
  if (bounds.k2i_1 <= 0.0 || bounds.l2i_1 <= 0.0)
    return Unit_instance;
  var tmp = Companion_getInstance_79();
  // Inline function 'kotlin.math.min' call
  var a = get_filterScale(_this__u8e3s4);
  var b = filter.zhf();
  var tmp$ret$0 = Math.min(a, b);
  var tryFilterScale = tmp.uhj(tmp$ret$0);
  var texWidthNoBorder = coerceAtLeast(numberToInt(bounds.k2i_1 * tryFilterScale), 1);
  var texHeightNoBorder = coerceAtLeast(numberToInt(bounds.l2i_1 * tryFilterScale), 1);
  var realFilterScale = clamp_1(texWidthNoBorder / bounds.k2i_1, 0.03125, 1.0);
  var tmp_0;
  switch (false) {
    case true:
      tmp_0 = clamp(toIntCeil(1.0 / get_filterScale(_this__u8e3s4)), 1, 8);
      break;
    case false:
      tmp_0 = 0;
      break;
    default:
      noWhenBranchMatchedException();
      break;
  }
  var edgeSize = tmp_0;
  var texWidth = texWidthNoBorder + imul(edgeSize, 2) | 0;
  var texHeight = texHeightNoBorder + imul(edgeSize, 2) | 0;
  var addx = -bounds.i2i_1 + edgeSize;
  var addy = -bounds.j2i_1 + edgeSize;
  // Inline function 'korlibs.korge.render.RenderContext.renderToTexture' call
  ctx.ya8();
  // Inline function 'korlibs.korge.render.RenderContext.tempAllocateFrameBuffer' call
  var rb = ctx.kai(texWidth, texHeight, false, true, 1);
  try {
    // Inline function 'korlibs.korge.render.RenderContext.renderToTexture.<anonymous>' call
    // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer' call
    // Inline function 'korlibs.korge.render.RenderContext.setFrameBufferTemporally' call
    ctx.mai(rb);
    try {
      // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer.<anonymous>' call
      // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
      // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
      var this_0 = ctx.ka7_1;
      var this_1 = this_0.ta7_1;
      if (!(this_1.oa7_1 === this_0)) {
        this_1.oa7_1 = this_0;
        this_1.ya8();
      }
      $l$block_0: {
        // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
        var scissor = AGScissor.p8v(0, 0, rb.a8k_1, rb.b8k_1);
        var temp = this_0.ra8_1;
        this_0.eaf();
        this_0.ra8_1 = scissor;
        try {
          if (true) {
            ctx.eai(Colors_getInstance().l4r_1);
          }
          // Inline function 'korlibs.korge.render.RenderContext.renderToTexture.<anonymous>.<anonymous>' call
          // Inline function 'korlibs.korge.view.filter.renderFiltered.<anonymous>' call
          var mat = _this__u8e3s4.tcc().q2n(addx, addy).l2n(realFilterScale);
          // Inline function 'korlibs.korge.render.BatchBuilder2D.setViewMatrixTemp' call
          var this_2 = ctx.ka7_1.ta7_1;
          this_2.ya8();
          var temp4 = this_2.za6_1;
          var temp2d = this_2.aa7_1;
          this_2.aa7_1 = mat;
          this_2.za6_1 = toMatrix4(mat);
          $l$block: {
            // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
            var this_3 = this_2.y8r(ProjViewUB_getInstance());
            var _unary__edvuaz = this_3.n8t();
            this_3.v9h(_unary__edvuaz + 1 | 0);
            this_3.w9h(this_3.n8t() + 1 | 0);
            var blockSize = this_3.r8r_1;
            var index0 = imul(this_3.n8t() - 1 | 0, blockSize);
            var index1 = imul(this_3.n8t(), blockSize);
            var texBlockSize = this_3.o8r_1.s8z().j1();
            var texIndex0 = imul(this_3.n8t() - 1 | 0, texBlockSize);
            var texIndex1 = imul(this_3.n8t(), texBlockSize);
            if (this_3.n8t() > 0) {
              arraycopy(this_3.t8r_1, index0, this_3.t8r_1, index1, blockSize);
            } else {
              arrayfill(this_3.t8r_1, 0, 0, blockSize);
            }
            // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp.<anonymous>' call
            var $this$push = this_3.o8r_1;
            this_3.u8r_1.m9i($this$push.r8z(), this_2.za6_1);
            if (true && this_3.n8t() >= 1) {
              var equals = arrayequal(this_3.t8r_1, index0, this_3.t8r_1, index1, blockSize);
              if (equals) {
                var _unary__edvuaz_0 = this_3.n8t();
                this_3.v9h(_unary__edvuaz_0 - 1 | 0);
                break $l$block;
              }
            }
          }
          var tmp_1;
          try {
            var tmp_2;
            if (first) {
              _this__u8e3s4.ccd(ctx);
              tmp_2 = Unit_instance;
            } else {
              _this__u8e3s4.dcd(ctx);
              tmp_2 = Unit_instance;
            }
            tmp_1 = tmp_2;
          }finally {
            this_2.ya8();
            this_2.za6_1 = temp4;
            this_2.aa7_1 = temp2d;
            this_2.y8r(ProjViewUB_getInstance()).x9h();
          }
          break $l$block_0;
        }finally {
          this_0.eaf();
          this_0.ra8_1 = temp;
        }
      }
    }finally {
      ctx.nai();
    }
    // Inline function 'korlibs.korge.view.filter.renderFiltered.<anonymous>' call
    var texture = Texture_0(rb).v42(0, 0, texWidth, texHeight);
    var mat_0 = _this__u8e3s4.ybp().v2n(-addx, -addy).n2n(1.0 / realFilterScale);
    filter.bhg(ctx, mat_0, texture, texWidth, texHeight, _this__u8e3s4.wcc(), _this__u8e3s4.bba_1, realFilterScale);
    ctx.ya8();
  }finally {
    ctx.lai(rb);
  }
}
function set_filter(_this__u8e3s4, value) {
  _init_properties_ViewFilter_kt__pd9xp9();
  var enabled = !(value == null);
  if (enabled) {
    var tmp$ret$3;
    $l$block_0: {
      // Inline function 'korlibs.korge.view.View.getOrCreateAndAddRenderPhase' call
      // Inline function 'korlibs.korge.view.View.getRenderPhaseOfTypeOrNull' call
      var tmp357_safe_receiver = _this__u8e3s4.hbb_1;
      var tmp;
      if (tmp357_safe_receiver == null) {
        tmp = null;
      } else {
        var tmp$ret$1;
        $l$block: {
          // Inline function 'kotlin.collections.firstOrNull' call
          var tmp0_iterator = tmp357_safe_receiver.f1();
          while (tmp0_iterator.g1()) {
            var element = tmp0_iterator.h1();
            // Inline function 'korlibs.korge.view.View.getRenderPhaseOfTypeOrNull.<anonymous>' call
            if (element instanceof ViewRenderPhaseFilter) {
              tmp$ret$1 = element;
              break $l$block;
            }
          }
          tmp$ret$1 = null;
        }
        tmp = tmp$ret$1;
      }
      var tmp_0 = tmp;
      var tmp360_safe_receiver = (tmp_0 == null ? true : tmp_0 instanceof ViewRenderPhaseFilter) ? tmp_0 : null;
      if (tmp360_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        tmp$ret$3 = tmp360_safe_receiver;
        break $l$block_0;
      }
      // Inline function 'kotlin.also' call
      // Inline function 'korlibs.korge.view.filter.<set-filter>.<anonymous>' call
      var this_0 = new ViewRenderPhaseFilter(value);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.View.getOrCreateAndAddRenderPhase.<anonymous>' call
      _this__u8e3s4.bcd(this_0);
      tmp$ret$3 = this_0;
    }
    tmp$ret$3.fhn_1 = value;
  } else {
    // Inline function 'korlibs.korge.view.View.removeRenderPhaseOfType' call
    var tmp356_safe_receiver = _this__u8e3s4.hbb_1;
    if (tmp356_safe_receiver == null)
      null;
    else {
      removeAll(tmp356_safe_receiver, _set_filter_$lambda_rgmh04);
    }
  }
  _this__u8e3s4.cca();
}
function get_filter(_this__u8e3s4) {
  _init_properties_ViewFilter_kt__pd9xp9();
  // Inline function 'korlibs.korge.view.View.getRenderPhaseOfTypeOrNull' call
  var tmp357_safe_receiver = _this__u8e3s4.hbb_1;
  var tmp;
  if (tmp357_safe_receiver == null) {
    tmp = null;
  } else {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator = tmp357_safe_receiver.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.view.View.getRenderPhaseOfTypeOrNull.<anonymous>' call
        if (element instanceof ViewRenderPhaseFilter) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    tmp = tmp$ret$1;
  }
  var tmp_0 = tmp;
  var tmp456_safe_receiver = (tmp_0 == null ? true : tmp_0 instanceof ViewRenderPhaseFilter) ? tmp_0 : null;
  return tmp456_safe_receiver == null ? null : tmp456_safe_receiver.fhn_1;
}
var Companion_instance_54;
function Companion_getInstance_87() {
  return Companion_instance_54;
}
function composedOrNull(_this__u8e3s4) {
  _init_properties_ViewFilter_kt__pd9xp9();
  var items = filterNotNull(_this__u8e3s4);
  return items.a1() ? null : ComposedFilter.ehg(items);
}
function filterScale$delegate$lambda($this$extraPropertyThis, it) {
  _init_properties_ViewFilter_kt__pd9xp9();
  return Companion_getInstance_79().uhj(it);
}
function filterScale$delegate$lambda_0($this$extraPropertyThis) {
  _init_properties_ViewFilter_kt__pd9xp9();
  return 1.0;
}
function _set_filter_$lambda_rgmh04(it) {
  _init_properties_ViewFilter_kt__pd9xp9();
  return it instanceof ViewRenderPhaseFilter;
}
function filterScale$factory() {
  return getPropertyCallableRef('filterScale', 1, KMutableProperty1, function (receiver) {
    return get_filterScale(receiver);
  }, function (receiver, value) {
    return set_filterScale(receiver, value);
  });
}
function filterScale$factory_0() {
  return getPropertyCallableRef('filterScale', 1, KMutableProperty1, function (receiver) {
    return get_filterScale(receiver);
  }, function (receiver, value) {
    return set_filterScale(receiver, value);
  });
}
var properties_initialized_ViewFilter_kt_mku9up;
function _init_properties_ViewFilter_kt__pd9xp9() {
  if (!properties_initialized_ViewFilter_kt_mku9up) {
    properties_initialized_ViewFilter_kt_mku9up = true;
    // Inline function 'korlibs.datastructure.extraPropertyThis' call
    var transform = filterScale$delegate$lambda;
    var default_0 = filterScale$delegate$lambda_0;
    // Inline function 'korlibs.datastructure.PropertyThis.withTransform' call
    var this_0 = new PropertyThis(null, default_0);
    this_0.t13_1 = transform;
    filterScale$delegate = this_0;
  }
}
var WaveUB_instance;
function WaveUB_getInstance() {
  if (WaveUB_instance === VOID)
    new WaveUB();
  return WaveUB_instance;
}
var Companion_instance_55;
function Companion_getInstance_88() {
  if (Companion_instance_55 === VOID)
    new Companion_40();
  return Companion_instance_55;
}
function u_Time$factory() {
  return getPropertyCallableRef('u_Time', 1, KProperty1, function (receiver) {
    return receiver.phn();
  }, null);
}
function u_Amplitude$factory() {
  return getPropertyCallableRef('u_Amplitude', 1, KProperty1, function (receiver) {
    return receiver.qhn();
  }, null);
}
function u_crestDistance$factory() {
  return getPropertyCallableRef('u_crestDistance', 1, KProperty1, function (receiver) {
    return receiver.rhn();
  }, null);
}
function u_cyclesPerSecond$factory() {
  return getPropertyCallableRef('u_cyclesPerSecond', 1, KProperty1, function (receiver) {
    return receiver.shn();
  }, null);
}
function u_Time$factory_0() {
  return getPropertyCallableRef('u_Time', 1, KProperty1, function (receiver) {
    return receiver.phn();
  }, null);
}
function u_Amplitude$factory_0() {
  return getPropertyCallableRef('u_Amplitude', 1, KProperty1, function (receiver) {
    return receiver.qhn();
  }, null);
}
function u_crestDistance$factory_0() {
  return getPropertyCallableRef('u_crestDistance', 1, KProperty1, function (receiver) {
    return receiver.rhn();
  }, null);
}
function u_cyclesPerSecond$factory_0() {
  return getPropertyCallableRef('u_cyclesPerSecond', 1, KProperty1, function (receiver) {
    return receiver.shn();
  }, null);
}
function transformIndex($this, flipX, flipY, rotate) {
  return insert(insert(insert(0, flipX, 0), flipY, 1), rotate, 2);
}
function computeIndices($this, flipX, flipY, rotate, indices) {
  indices[0] = 0;
  indices[1] = 1;
  indices[2] = 2;
  indices[3] = 3;
  if (rotate) {
    swap(indices, $this, 1, 3);
  }
  if (flipY) {
    swap(indices, $this, 0, 3);
    swap(indices, $this, 1, 2);
  }
  if (flipX) {
    swap(indices, $this, 0, 1);
    swap(indices, $this, 3, 2);
  }
  return indices;
}
function computeIndices$default($this, flipX, flipY, rotate, indices, $super) {
  indices = indices === VOID ? new Int32Array(4) : indices;
  return computeIndices($this, flipX, flipY, rotate, indices);
}
function swap(_this__u8e3s4, $this, a, b) {
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.tiles.Companion.swap.<anonymous>' call
  var t = _this__u8e3s4[a];
  _this__u8e3s4[a] = _this__u8e3s4[b];
  _this__u8e3s4[b] = t;
  return _this__u8e3s4;
}
var Companion_instance_56;
function Companion_getInstance_89() {
  if (Companion_instance_56 === VOID)
    new Companion_41();
  return Companion_instance_56;
}
function computeVertexIfRequired($this, ctx) {
  $this.chr_1 = new Rectangle(ctx.mag(), ctx.nag(), ctx.oag(), ctx.pag());
  if (!$this.chr_1.equals($this.bhr_1)) {
    $this.abb_1 = true;
    $this.bhr_1 = $this.chr_1;
  }
  if (!$this.abb_1 && $this.whq_1 === $this.ohq_1.s3a() && $this.xhq_1 === $this.vhq_1)
    return Unit_instance;
  $this.whq_1 = $this.ohq_1.s3a();
  $this.xhq_1 = $this.vhq_1;
  $this.abb_1 = false;
  var m = $this.ybp();
  var renderTilesCounter = ctx.pa6_1.qal('renderedTiles');
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.math.geom.Matrix.transform' call
    var p = Companion_getInstance_34().a2i_1;
    if (m.n2i()) {
      tmp$ret$0 = p;
      break $l$block;
    }
    tmp$ret$0 = Vector2D.k2h(m.d2n(p.i2h_1, p.j2h_1), m.g2n(p.i2h_1, p.j2h_1));
  }
  var _destruct__k2r9zo = tmp$ret$0;
  var posX = _destruct__k2r9zo.km();
  var posY = _destruct__k2r9zo.lm();
  var dUX = m.e2n($this.rhq_1, 0.0) - posX;
  var dUY = m.f2n($this.rhq_1, 0.0) - posY;
  var dVX = m.e2n(0.0, $this.shq_1) - posX;
  var dVY = m.f2n(0.0, $this.shq_1) - posY;
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.tiles.TileMap.computeVertexIfRequired.<anonymous>' call
  var width = $this.qhq_1.a2v_1;
  // Inline function 'kotlin.math.min' call
  var a = m.d2n(width, 0.0) - posX;
  var b = m.g2n(0.0, width) - posY;
  var nextTileX = Math.min(a, b);
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.tiles.TileMap.computeVertexIfRequired.<anonymous>' call
  var height = $this.qhq_1.b2v_1;
  // Inline function 'kotlin.math.min' call
  var a_0 = m.d2n(height, 0.0) - posX;
  var b_0 = m.g2n(0.0, height) - posY;
  var nextTileY = Math.min(a_0, b_0);
  var colMul = $this.wcc();
  var pp0 = $this.jbq(Vector2D.k2h($this.chr_1.p2i(), $this.chr_1.r2i()));
  var pp1 = $this.jbq(Vector2D.k2h($this.chr_1.q2i(), $this.chr_1.s2i()));
  var pp2 = $this.jbq(Vector2D.k2h($this.chr_1.q2i(), $this.chr_1.r2i()));
  var pp3 = $this.jbq(Vector2D.k2h($this.chr_1.p2i(), $this.chr_1.s2i()));
  var mapTileWidth = $this.qhq_1.a2v_1;
  var mapTileHeight = $this.qhq_1.b2v_1;
  var mx0 = toIntCeil(pp0.i2h_1 / mapTileWidth);
  var mx1 = toIntCeil(pp1.i2h_1 / mapTileWidth);
  var mx2 = toIntCeil(pp2.i2h_1 / mapTileWidth);
  var mx3 = toIntCeil(pp3.i2h_1 / mapTileWidth);
  var my0 = toIntCeil(pp0.j2h_1 / mapTileHeight);
  var my1 = toIntCeil(pp1.j2h_1 / mapTileHeight);
  var my2 = toIntCeil(pp2.j2h_1 / mapTileHeight);
  var my3 = toIntCeil(pp3.j2h_1 / mapTileHeight);
  var ymin = min(my0, my1, my2, my3);
  var ymax = max(my0, my1, my2, my3);
  var xmin = min(mx0, mx1, mx2, mx3);
  var xmax = max(mx0, mx1, mx2, mx3);
  var doRepeatX = !$this.thq_1.equals(TileMapRepeat_NONE_getInstance());
  var doRepeatY = !$this.uhq_1.equals(TileMapRepeat_NONE_getInstance());
  var doRepeatAny = doRepeatX || doRepeatY;
  var ymin2 = ((doRepeatAny ? ymin : clamp(ymin, $this.ohq_1.u3a(), $this.ohq_1.x3a())) - 1 | 0) - $this.fhr_1 | 0;
  var ymax2 = ((doRepeatAny ? ymax : clamp(ymax, $this.ohq_1.u3a(), $this.ohq_1.x3a())) + 1 | 0) + $this.fhr_1 | 0;
  var xmin2 = ((doRepeatAny ? xmin : clamp(xmin, $this.ohq_1.t3a(), $this.ohq_1.w3a())) - 1 | 0) - $this.fhr_1 | 0;
  var xmax2 = ((doRepeatAny ? xmax : clamp(xmax, $this.ohq_1.t3a(), $this.ohq_1.w3a())) + 1 | 0) + $this.fhr_1 | 0;
  var yheight = ymax2 - ymin2 | 0;
  var xwidth = xmax2 - xmin2 | 0;
  var ntiles = imul(imul(xwidth, yheight), $this.ohq_1.v3a());
  var allocTiles = get_nextPowerOfTwo(ntiles);
  var MAX_TILES = 16368;
  // Inline function 'kotlin.math.min' call
  var allocTilesClamped = Math.min(allocTiles, MAX_TILES);
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var this_0 = $this.zhq_1;
  var n = 0;
  while (n < this_0.j1()) {
    // Inline function 'korlibs.korge.view.tiles.TileMap.computeVertexIfRequired.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var it = this_0.i1(_unary__edvuaz);
    $this.ahr_1.h39(it);
  }
  $this.yhq_1.o3();
  $this.zhq_1.o3();
  var iterationCount = 0;
  var count = 0;
  var nblocks = 0;
  // Inline function 'korlibs.korge.render.Companion.quadIndices' call
  Companion_getInstance_20();
  var quadIndexData = TEXTURED_ARRAY_quadIndices(allocTilesClamped);
  var scaleOffsetX = 1.0 / $this.rhq_1 * $this.ohq_1.y70_1;
  var scaleOffsetY = 1.0 / $this.shq_1 * $this.ohq_1.y70_1;
  var inductionVariable = ymin2;
  if (inductionVariable < ymax2)
    do {
      var y = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var inductionVariable_0 = xmin2;
      if (inductionVariable_0 < xmax2)
        $l$loop_0: do {
          var x = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          iterationCount = iterationCount + 1 | 0;
          var rx = $this.thq_1.i71_1(x, $this.ohq_1.j2y());
          var ry = $this.uhq_1.i71_1(y, $this.ohq_1.k2y());
          if (rx < $this.ohq_1.t3a() || rx >= $this.ohq_1.w3a())
            continue $l$loop_0;
          if (ry < $this.ohq_1.u3a() || ry >= $this.ohq_1.x3a())
            continue $l$loop_0;
          var inductionVariable_1 = 0;
          var last = $this.ohq_1.y3a(rx, ry);
          if (inductionVariable_1 < last)
            $l$loop_2: do {
              var level = inductionVariable_1;
              inductionVariable_1 = inductionVariable_1 + 1 | 0;
              var cell = $this.ohq_1.e71(rx, ry, level);
              if (_Tile___get_isInvalid__impl__yfg9ed(cell))
                continue $l$loop_2;
              var cellData = _Tile___get_tile__impl__nfq1aw(cell);
              var flipX = _Tile___get_flipX__impl__jobqpf(cell);
              var flipY = _Tile___get_flipY__impl__95aqcs(cell);
              var rotate = _Tile___get_rotate__impl__5kjdv9(cell);
              var offsetX = _Tile___get_offsetX__impl__qwhdsd(cell);
              var offsetY = _Tile___get_offsetY__impl__1x539u(cell);
              var rationalOffsetX = offsetX * scaleOffsetX;
              var rationalOffsetY = offsetY * scaleOffsetY;
              var tmp0_elvis_lhs = getOrNull_0($this.hhr_1, cellData);
              var tmp;
              if (tmp0_elvis_lhs == null) {
                continue $l$loop_2;
              } else {
                tmp = tmp0_elvis_lhs;
              }
              var tex = tmp;
              count = count + 1 | 0;
              var tmp$ret$9;
              $l$block_1: {
                // Inline function 'korlibs.datastructure.FastIdentityCacheMap.getOrPut' call
                var this_1 = $this.yhq_1;
                var key = tex.o33();
                if (key === this_1.t35_1) {
                  tmp$ret$9 = ensureNotNull(this_1.u35_1);
                  break $l$block_1;
                }
                if (key === this_1.v35_1) {
                  tmp$ret$9 = ensureNotNull(this_1.w35_1);
                  break $l$block_1;
                }
                var tmp$ret$10;
                $l$block_2: {
                  // Inline function 'korlibs.datastructure.getOrPut' call
                  var this_2 = this_1.x35_1;
                  var res = get(this_2, key);
                  if (!(res == null)) {
                    tmp$ret$10 = res;
                    break $l$block_2;
                  }
                  // Inline function 'korlibs.datastructure.FastIdentityCacheMap.getOrPut.<anonymous>' call
                  // Inline function 'korlibs.korge.view.tiles.TileMap.computeVertexIfRequired.<anonymous>' call
                  // Inline function 'kotlin.also' call
                  var this_3 = $this.ahr_1.g39();
                  // Inline function 'kotlin.contracts.contract' call
                  // Inline function 'korlibs.korge.view.tiles.TileMap.computeVertexIfRequired.<anonymous>.<anonymous>' call
                  this_3.jho_1 = tex.o33();
                  this_3.lho_1.o3();
                  this_3.mho(new ShrinkableTexturedVertexArray(new TexturedVertexArray(imul(allocTilesClamped, 4), quadIndexData)));
                  // Inline function 'kotlin.collections.plusAssign' call
                  $this.zhq_1.f(this_3);
                  nblocks = nblocks + 1 | 0;
                  var out = this_3;
                  set(this_2, key, out);
                  tmp$ret$10 = out;
                }
                var value = tmp$ret$10;
                this_1.v35_1 = this_1.t35_1;
                this_1.w35_1 = this_1.u35_1;
                this_1.t35_1 = key;
                this_1.u35_1 = value;
                tmp$ret$9 = value;
              }
              var info = tmp$ret$9;
              // Inline function 'kotlin.run' call
              // Inline function 'kotlin.contracts.contract' call
              var px = x + rationalOffsetX;
              var py = y + rationalOffsetY;
              var p0X = posX + nextTileX * px + dVX * py;
              var p0Y = posY + dUY * px + nextTileY * py;
              var p1X = p0X + dUX;
              var p1Y = p0Y + dUY;
              var p2X = p0X + dUX + dVX;
              var p2Y = p0Y + dUY + dVY;
              var p3X = p0X + dVX;
              var p3Y = p0Y + dVY;
              $this.dhr_1[0] = tex.b43();
              $this.dhr_1[1] = tex.d43();
              $this.dhr_1[2] = tex.f43();
              $this.dhr_1[3] = tex.h43();
              $this.ehr_1[0] = tex.c43();
              $this.ehr_1[1] = tex.e43();
              $this.ehr_1[2] = tex.g43();
              $this.ehr_1[3] = tex.i43();
              var indices = Companion_getInstance_89().nho_1[transformIndex(Companion_getInstance_89(), flipX, flipY, rotate)];
              info.kho_1.zak(p0X, p0Y, $this.dhr_1[indices[0]], $this.ehr_1[indices[0]], colMul);
              info.kho_1.zak(p1X, p1Y, $this.dhr_1[indices[1]], $this.ehr_1[indices[1]], colMul);
              info.kho_1.zak(p2X, p2Y, $this.dhr_1[indices[2]], $this.ehr_1[indices[2]], colMul);
              info.kho_1.zak(p3X, p3Y, $this.dhr_1[indices[3]], $this.ehr_1[indices[3]], colMul);
              var _receiver__tnumb7 = info.kho_1;
              _receiver__tnumb7.yak_1 = _receiver__tnumb7.yak_1 + 6 | 0;
              if (info.kho_1.yak_1 >= (MAX_TILES - 1 | 0)) {
                info.mho(new ShrinkableTexturedVertexArray(new TexturedVertexArray(imul(allocTilesClamped, 4), quadIndexData)));
                nblocks = nblocks + 1 | 0;
              }
            }
             while (inductionVariable_1 < last);
        }
         while (inductionVariable_0 < xmax2);
    }
     while (inductionVariable < ymax2);
  renderTilesCounter.ial(count);
  $this.ghr_1 = iterationCount;
}
function updatedTileSet($this) {
  var tmp = $this;
  var tmp_0 = 0;
  var tmp_1 = $this.khr_1.d72().length;
  // Inline function 'kotlin.arrayOfNulls' call
  var tmp_2 = fillArrayVal(Array(tmp_1), null);
  while (tmp_0 < tmp_1) {
    var tmp_3 = tmp_0;
    tmp_2[tmp_3] = $this.khr_1.d72()[tmp_3];
    tmp_0 = tmp_0 + 1 | 0;
  }
  tmp.hhr_1 = tmp_2;
  var tmp_4 = $this;
  var tmp_5 = 0;
  var tmp_6 = $this.khr_1.d72().length;
  var tmp_7 = new Int32Array(tmp_6);
  while (tmp_5 < tmp_6) {
    tmp_7[tmp_5] = 0;
    tmp_5 = tmp_5 + 1 | 0;
  }
  tmp_4.ihr_1 = tmp_7;
  var tmp_8 = $this;
  var tmp_9 = 0;
  var tmp_10 = $this.khr_1.d72().length;
  var tmp_11 = new Float64Array(tmp_10);
  while (tmp_9 < tmp_10) {
    tmp_11[tmp_9] = 0.0;
    tmp_9 = tmp_9 + 1 | 0;
  }
  tmp_8.jhr_1 = tmp_11;
  $this.qhq_1 = $this.khr_1.c72();
  $this.rhq_1 = $this.khr_1.w71_1;
  $this.shq_1 = $this.khr_1.x71_1;
}
function TileMap$infosPool$lambda(it) {
  it.z33();
  return Unit_instance;
}
function TileMap$infosPool$lambda_0(it) {
  // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
  var tmp$ret$0 = get_Bitmaps_transparent();
  return new Info_0(get_bmp(tmp$ret$0), new ShrinkableTexturedVertexArray(Companion_getInstance_20().sak_1));
}
function TileMap$lambda($tileset) {
  return function ($this$addFastUpdater, dt) {
    var this_0 = $tileset.w3u();
    var n = 0;
    while (n < this_0.length) {
      // Inline function 'korlibs.korge.view.tiles.TileMap.<anonymous>.<anonymous>' call
      var tileIndex = n;
      var info = this_0[n];
      var tmp;
      if (!(info == null)) {
        // Inline function 'kotlin.collections.isNotEmpty' call
        tmp = !info.o71_1.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        var aindex = $this$addFastUpdater.ihr_1[tileIndex];
        var currentFrame = info.o71_1.i1(aindex);
        var _array__4zh2yp = $this$addFastUpdater.jhr_1;
        _array__4zh2yp[tileIndex] = _array__4zh2yp[tileIndex] + _FastDuration___get_milliseconds__impl__ffr17u(dt.p1w_1);
        // Inline function 'korlibs.time.milliseconds' call
        var this_1 = $this$addFastUpdater.jhr_1[tileIndex];
        var tmp$ret$1 = toDuration(this_1, DurationUnit_MILLISECONDS_getInstance());
        if (Duration__compareTo_impl_pchp0f(tmp$ret$1, currentFrame.mhr_1) >= 0) {
          var nextIndex = (aindex + 1 | 0) % info.o71_1.j1() | 0;
          var _array__4zh2yp_0 = $this$addFastUpdater.jhr_1;
          _array__4zh2yp_0[tileIndex] = _array__4zh2yp_0[tileIndex] - get_milliseconds(currentFrame.mhr_1);
          $this$addFastUpdater.ihr_1[tileIndex] = nextIndex;
          $this$addFastUpdater.hhr_1[tileIndex] = $tileset.d72()[info.o71_1.i1(nextIndex).lhr_1];
          $this$addFastUpdater.vhq_1 = $this$addFastUpdater.vhq_1 + 1 | 0;
        }
      }
      n = n + 1 | 0;
    }
    return Unit_instance;
  };
}
function _get_shapeBounds__actvvf($this) {
  var valid = $this.ah3_1 ? $this.lh3_1 : $this.kh3_1;
  if (!valid) {
    if ($this.ah3_1)
      $this.lh3_1 = true;
    else
      $this.kh3_1 = true;
    var result = $this.ih3_1.l78($this.ah3_1);
    if ($this.ah3_1)
      $this.oh3_1 = result;
    else
      $this.nh3_1 = result;
    return result;
  }
  return $this.ah3_1 ? $this.oh3_1 : $this.nh3_1;
}
function invalidateShape($this) {
  $this.mh3_1 = 0;
  $this.jh3_1 = false;
  $this.kh3_1 = false;
  $this.lh3_1 = false;
  $this.hbh();
}
function requireShape($this) {
  if ($this.jh3_1)
    return Unit_instance;
  $this.jh3_1 = true;
  $this.bh3_1.o3();
  $this.bh3_1.dhs();
  $this.bh3_1.ehs(Companion_getInstance_22().w8l_1);
  $this.ph3_1 = true;
  renderShape($this, $this.ih3_1);
  $this.bh3_1.j8k();
  $this.rh3_1 = $this.qh3_1;
}
function renderCommands($this, ctx, doRequireTexture) {
  var mat = doRequireTexture ? $this.ybp().z2m(get_immutable(ctx.na6_1.fag())) : $this.ybp();
  $this.sh3_1 = mat.t2n(-$this.zca(), -$this.acb());
  requireShape($this);
  $this.bh3_1.fhs(ctx, $this.sh3_1, $this.scc(), $this.fh3_1, $this.wcc(), doRequireTexture);
}
function renderShape($this, shape) {
  if (!equals(shape, EmptyShape_instance)) {
    if (shape instanceof CompoundShape) {
      var _iterator__ex2g4s = shape.y78_1.f1();
      while (_iterator__ex2g4s.g1()) {
        var v = _iterator__ex2g4s.h1();
        renderShape($this, v);
      }
    } else {
      if (shape instanceof TextShape) {
        renderShape($this, shape.b7a());
      } else {
        if (shape instanceof FillShape) {
          $this.mh3_1 = $this.mh3_1 + 1 | 0;
          if ($this.mh3_1 > $this.th3_1)
            return Unit_instance;
          renderFill($this, shape);
        } else {
          if (shape instanceof PolylineShape) {
            $this.mh3_1 = $this.mh3_1 + 1 | 0;
            if ($this.mh3_1 > $this.th3_1)
              return Unit_instance;
            renderStroke$default($this, shape.d79_1, shape.a79_1, shape.c79_1, shape.f79_1, shape.e79_1);
          } else {
            var reason = 'shape=' + toString(shape);
            throw NotImplementedError.oe('An operation is not implemented: ' + reason);
          }
        }
      }
    }
  }
}
function renderStroke($this, stateTransform, strokePath, paint, globalAlpha, strokeInfo, forceClosed, stencilOpFunc, stencilRef) {
  var gpuShapeViewCommands = $this.bh3_1;
  var pointsList = toStrokePointsList(toCurvesList(strokePath), strokeInfo, StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_getInstance(), VOID, forceClosed);
  gpuShapeViewCommands.ehs(Companion_getInstance_22().w8l_1);
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n = 0;
  while (n < pointsList.j1()) {
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderStroke.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var points = pointsList.i1(_unary__edvuaz);
    var startIndex = gpuShapeViewCommands.ghs();
    var vector = points.e3t();
    // Inline function 'korlibs.math.geom.fastForEachGeneric' call
    var inductionVariable = 0;
    var last = vector.j1();
    if (inductionVariable < last)
      do {
        var n_0 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderStroke.<anonymous>.<anonymous>' call
        var x = vector.z2k(n_0, 0);
        var y = vector.z2k(n_0, 1);
        var dx = vector.z2k(n_0, 2);
        var dy = vector.z2k(n_0, 3);
        var len = vector.z2k(n_0, 4);
        // Inline function 'kotlin.math.absoluteValue' call
        var this_0 = vector.z2k(n_0, 5);
        var maxLen = Math.abs(this_0);
        var px = x + dx * len;
        var py = y + dy * len;
        gpuShapeViewCommands.hhs(px, py, $this.hh3_1 ? len : 0.0, $this.hh3_1 ? maxLen : $this.vh3_1);
      }
       while (inductionVariable < last);
    var endIndex = gpuShapeViewCommands.ihs();
    var info = GpuShapeViewPrograms_getInstance().bht(stateTransform, paint, globalAlpha, strokeInfo.n3s_1);
    var tmp466_drawType = Companion_getInstance_18().m8u_1;
    var tmp467_blendMode = Companion_getInstance_17().ra5_1;
    gpuShapeViewCommands.cht(tmp466_drawType, info, VOID, stencilOpFunc, stencilRef, tmp467_blendMode, VOID, startIndex, endIndex);
  }
}
function renderStroke$default($this, stateTransform, strokePath, paint, globalAlpha, strokeInfo, forceClosed, stencilOpFunc, stencilRef, $super) {
  forceClosed = forceClosed === VOID ? null : forceClosed;
  stencilOpFunc = stencilOpFunc === VOID ? Companion_getInstance_24().k8l_1 : stencilOpFunc;
  stencilRef = stencilRef === VOID ? Companion_getInstance_25().i8l_1 : stencilRef;
  return renderStroke($this, stateTransform, strokePath, paint, globalAlpha, strokeInfo, forceClosed, stencilOpFunc, stencilRef);
}
function getPointsForPath($this, points, type) {
  if (points.j1() < 3)
    return null;
  var vertexStart = $this.bh3_1.ghs();
  var bb = BoundsBuilder__plus_impl_cz7f7x_1(Companion_getInstance_40().y2i(), points);
  var xMid = (_BoundsBuilder___get_xmax__impl__gsz0km(bb) + _BoundsBuilder___get_xmin__impl__o2sib0(bb)) / 2;
  var yMid = (_BoundsBuilder___get_ymax__impl__oia4uz(bb) + _BoundsBuilder___get_ymin__impl__5n2e8j(bb)) / 2;
  var pMid = Vector2D.k2h(xMid, yMid);
  var isStrip = type === Companion_getInstance_18().m8u_1;
  var antialiased = $this.hh3_1;
  var isStripAndAntialiased = antialiased && isStrip;
  if (!isStrip) {
    $this.bh3_1.hhs(xMid, yMid, 0.0, $this.vh3_1);
  }
  var inductionVariable = 0;
  var last = points.j1() + 1 | 0;
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var p = points.i1(n % points.j1() | 0);
      var x = p.km();
      var y = p.lm();
      var len = isStripAndAntialiased ? Companion_getInstance_34().n2w(p, pMid) : 0.0;
      var maxLen = isStripAndAntialiased ? len : $this.vh3_1;
      if (isStrip) {
        $this.bh3_1.hhs(xMid, yMid, 0.0, maxLen);
      }
      $this.bh3_1.hhs(x, y, len, maxLen);
    }
     while (inductionVariable < last);
  var vertexEnd = $this.bh3_1.ihs();
  return new PointsResult(toAGScissor(_BoundsBuilder___get_bounds__impl__s1l1gx(bb)), points.j1() + 2 | 0, vertexStart, vertexEnd);
}
function getPointsForPath_0($this, path, type) {
  return getPointsForPath($this, get_cachedPoints(path), type);
}
function getPointsForPathList($this, path, type) {
  // Inline function 'kotlin.collections.mapNotNull' call
  // Inline function 'kotlin.collections.mapNotNullTo' call
  var this_0 = getPoints2List(path);
  var destination = ArrayList.m1();
  // Inline function 'kotlin.collections.forEach' call
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'kotlin.collections.mapNotNullTo.<anonymous>' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.getPointsForPathList.<anonymous>' call
    var tmp0_safe_receiver = getPointsForPath($this, element, type);
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      destination.f(tmp0_safe_receiver);
    }
  }
  return destination;
}
function renderFill($this, shape) {
  var tmp0_elvis_lhs = GpuShapeViewPrograms_getInstance().bht(shape.p78_1, shape.o78_1, shape.q78_1, 1.0E7);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var paintShader = tmp;
  var drawFill = !$this.uh3_1;
  var drawAntialiasingBorder = $this.uh3_1 ? true : $this.hh3_1;
  var shapeIsConvex = shape.w78();
  var isSimpleDraw = shapeIsConvex && shape.n78_1 == null && !$this.uh3_1;
  var pathDataList = getPointsForPathList($this, shape.m78_1, isSimpleDraw ? Companion_getInstance_18().m8u_1 : Companion_getInstance_18().n8u_1);
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderFill.<anonymous>' call
  var bb = Companion_getInstance_40().y2i();
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n = 0;
  while (n < pathDataList.j1()) {
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderFill.<anonymous>.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var it = pathDataList.i1(_unary__edvuaz);
    bb = plus_0(bb, it.dht_1);
  }
  var pathBoundsNoExpanded = _BoundsBuilder___get_bounds__impl__s1l1gx(bb);
  var pathBounds = toAGScissor(pathBoundsNoExpanded.f2u(MarginInt.g2m(2)));
  var clipDataStart = $this.bh3_1.ghs();
  var tmp468_safe_receiver = shape.n78_1;
  var tmp_0;
  if (tmp468_safe_receiver == null) {
    tmp_0 = null;
  } else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderFill.<anonymous>' call
    tmp_0 = getPointsForPath_0($this, tmp468_safe_receiver, Companion_getInstance_18().n8u_1);
  }
  var clipData = tmp_0;
  var clipDataEnd = $this.bh3_1.ihs();
  var tmp1_elvis_lhs = clipData == null ? null : clipData.dht_1;
  var clipBounds = tmp1_elvis_lhs == null ? Companion_getInstance_22().w8l_1 : tmp1_elvis_lhs;
  if (!isSimpleDraw || $this.ph3_1) {
    $this.ph3_1 = true;
    $this.bh3_1.ehs(isSimpleDraw ? Companion_getInstance_22().w8l_1 : !clipBounds.equals(Companion_getInstance_22().w8l_1) ? Companion_getInstance_22().u8v(pathBounds, clipBounds) : pathBounds);
  }
  if (isSimpleDraw) {
    $this.ph3_1 = false;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n_0 = 0;
    while (n_0 < pathDataList.j1()) {
      // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderFill.<anonymous>' call
      var _unary__edvuaz_0 = n_0;
      n_0 = _unary__edvuaz_0 + 1 | 0;
      var pathData = pathDataList.i1(_unary__edvuaz_0);
      var tmp470_$this = $this.bh3_1;
      var tmp471_drawType = Companion_getInstance_18().m8u_1;
      var tmp472_startIndex = pathData.fht_1;
      var tmp473_endIndex = pathData.ght_1;
      var tmp474_colorMask = _AGColorMask___init__impl__j38ai8(true);
      var tmp475_blendMode = $this.vcc();
      tmp470_$this.cht(tmp471_drawType, paintShader, tmp474_colorMask, VOID, VOID, tmp475_blendMode, VOID, tmp472_startIndex, tmp473_endIndex);
    }
    return Unit_instance;
  }
  var winding = shape.m78_1.o3y_1;
  var stencilReferenceValue = 1;
  var stencilCompare = Companion_getInstance_26().o8w_1;
  if (drawFill) {
    switch (winding.v3_1) {
      case 0:
        stencilReferenceValue = 1;
        stencilCompare = Companion_getInstance_26().o8w_1;
        var stencilOpFunc = AGStencilOpFunc__withActionOnBothPass$default_impl_69nsgo(AGStencilOpFunc__withCompareMode$default_impl_rzbauv(AGStencilOpFunc__withEnabled_impl_mpcwmr(Companion_getInstance_24().k8l_1, true), Companion_getInstance_26().n8w_1), Companion_getInstance_27().z8w_1);
        var stencilRef = AGStencilReference__withWriteMask$default_impl_b7j7b4(Companion_getInstance_25().i8l_1, 1);
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call

        var n_1 = 0;
        while (n_1 < pathDataList.j1()) {
          // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderFill.<anonymous>' call
          var _unary__edvuaz_1 = n_1;
          n_1 = _unary__edvuaz_1 + 1 | 0;
          var pathData_0 = pathDataList.i1(_unary__edvuaz_1);
          writeStencil($this, pathData_0.fht_1, pathData_0.ght_1, stencilOpFunc, stencilRef, Companion_getInstance_28().y8l_1);
        }

        break;
      case 1:
        stencilReferenceValue = 0;
        stencilCompare = Companion_getInstance_26().u8w_1;
        var stencilOpFunc_0 = AGStencilOpFunc__withCompareMode$default_impl_rzbauv(AGStencilOpFunc__withEnabled_impl_mpcwmr(Companion_getInstance_24().k8l_1, true), Companion_getInstance_26().n8w_1);
        var stencilRef_0 = AGStencilReference__withWriteMask$default_impl_b7j7b4(Companion_getInstance_25().i8l_1, 255);
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call

        var n_2 = 0;
        while (n_2 < pathDataList.j1()) {
          // Inline function 'korlibs.korge.view.vector.GpuShapeView.renderFill.<anonymous>' call
          var _unary__edvuaz_2 = n_2;
          n_2 = _unary__edvuaz_2 + 1 | 0;
          var pathData_1 = pathDataList.i1(_unary__edvuaz_2);
          writeStencil($this, pathData_1.fht_1, pathData_1.ght_1, AGStencilOpFunc__withActionOnBothPass_impl_qbn6at(stencilOpFunc_0, Companion_getInstance_27().y8w_1, Companion_getInstance_27().w8w_1), stencilRef_0, Companion_getInstance_28().y8l_1);
        }

        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    if (!(clipData == null)) {
      if (winding.equals(Winding_NON_ZERO_getInstance())) {
        writeStencil($this, clipDataStart, clipDataEnd, AGStencilOpFunc__withActionOnBothPass$default_impl_69nsgo(AGStencilOpFunc__withCompareMode$default_impl_rzbauv(AGStencilOpFunc__withEnabled_impl_mpcwmr(Companion_getInstance_24().k8l_1, true), Companion_getInstance_26().u8w_1), Companion_getInstance_27().z8w_1), AGStencilReference__withWriteMask$default_impl_b7j7b4(AGStencilReference__withReadMask$default_impl_7mdffx(Companion_getInstance_25().i8l_1, 0), 255), Companion_getInstance_28().z8l_1);
      }
      writeStencil($this, clipDataStart, clipDataEnd, AGStencilOpFunc__withActionOnBothPass$default_impl_69nsgo(AGStencilOpFunc__withCompareMode$default_impl_rzbauv(AGStencilOpFunc__withEnabled_impl_mpcwmr(Companion_getInstance_24().k8l_1, true), Companion_getInstance_26().n8w_1), Companion_getInstance_27().z8w_1), AGStencilReference__withWriteMask$default_impl_b7j7b4(Companion_getInstance_25().i8l_1, 2), Companion_getInstance_28().b8m_1);
      stencilReferenceValue = 3;
    }
  }
  if (drawAntialiasingBorder && shape.n78_1 == null) {
    renderStroke($this, shape.p78_1, shape.m78_1, shape.o78_1, shape.q78_1, new StrokeInfo(1.6 / $this.qh3_1, VOID, LineScaleMode_NONE_getInstance(), LineCap_BUTT_getInstance(), LineCap_BUTT_getInstance(), LineJoin_MITER_getInstance(), 5.0), true, !drawFill ? Companion_getInstance_24().k8l_1 : AGStencilOpFunc__withCompareMode$default_impl_rzbauv(AGStencilOpFunc__withEnabled_impl_mpcwmr(Companion_getInstance_24().k8l_1, true), AGCompareMode__inverted_impl_7xxk8m(stencilCompare)), AGStencilReference__withWriteMask$default_impl_b7j7b4(AGStencilReference__withReferenceValue$default_impl_2trj0v(Companion_getInstance_25().i8l_1, stencilReferenceValue), 0));
  }
  if (drawFill) {
    writeFill($this, paintShader, stencilReferenceValue, pathBounds, pathDataList, stencilCompare);
    $this.bh3_1.h9c(0);
  }
}
function writeStencil($this, pathDataStart, pathDataEnd, stencilOpFunc, stencilRef, cullFace) {
  var tmp477_$this = $this.bh3_1;
  var tmp478_drawType = Companion_getInstance_18().n8u_1;
  var tmp479_paintShader = GpuShapeViewPrograms_getInstance().aht_1;
  var tmp480_colorMask = _AGColorMask___init__impl__j38ai8_0(false, false, false, false);
  var tmp481_blendMode = Companion_getInstance_17().ta5_1;
  tmp477_$this.hht(tmp478_drawType, tmp479_paintShader, tmp480_colorMask, stencilOpFunc, stencilRef, tmp481_blendMode, cullFace, pathDataStart, pathDataEnd);
}
function writeFill($this, paintShader, stencilReferenceValue, pathBounds, pathDataList, stencilCompare) {
  var vstart = $this.bh3_1.ghs();
  var x0 = pathBounds.p2i();
  var y0 = pathBounds.r2i();
  var x1 = pathBounds.q2i();
  var y1 = pathBounds.s2i();
  $this.bh3_1.hhs(x0, y0, 0.0, $this.vh3_1);
  $this.bh3_1.hhs(x1, y0, 0.0, $this.vh3_1);
  $this.bh3_1.hhs(x1, y1, 0.0, $this.vh3_1);
  $this.bh3_1.hhs(x0, y1, 0.0, $this.vh3_1);
  var vend = $this.bh3_1.ihs();
  var tmp482_$this = $this.bh3_1;
  var tmp483_drawType = Companion_getInstance_18().n8u_1;
  var tmp484_colorMask = _AGColorMask___init__impl__j38ai8(true);
  var tmp485_stencilOpFunc = AGStencilOpFunc__withCompareMode$default_impl_rzbauv(AGStencilOpFunc__withEnabled_impl_mpcwmr(Companion_getInstance_24().k8l_1, true), stencilCompare);
  var tmp486_stencilRef = AGStencilReference__withWriteMask$default_impl_b7j7b4(AGStencilReference__withReferenceValue$default_impl_2trj0v(Companion_getInstance_25().i8l_1, stencilReferenceValue), 0);
  var tmp487_blendMode = $this.vcc();
  tmp482_$this.cht(tmp483_drawType, paintShader, tmp484_colorMask, tmp485_stencilOpFunc, tmp486_stencilRef, tmp487_blendMode, VOID, vstart, vend);
}
var ShapeViewUB_instance;
function ShapeViewUB_getInstance() {
  if (ShapeViewUB_instance === VOID)
    new ShapeViewUB();
  return ShapeViewUB_instance;
}
function GpuShapeViewPrograms$PROGRAM_COMBINED$lambda($$this$FragmentShaderDefault) {
  return function ($this$IF_ELSE_LIST, it) {
    var tmp;
    switch (it) {
      case 0:
        $this$IF_ELSE_LIST.f92($this$IF_ELSE_LIST.i92(), GpuShapeViewPrograms_getInstance().nhu());
        tmp = Unit_instance;
        break;
      case 1:
        $this$IF_ELSE_LIST.f92($this$IF_ELSE_LIST.i92(), $this$IF_ELSE_LIST.q92($$this$FragmentShaderDefault.p92(), $this$IF_ELSE_LIST.j94($this$IF_ELSE_LIST.u94([$this$IF_ELSE_LIST.o92($this$IF_ELSE_LIST.j92(GpuShapeViewPrograms_getInstance().phu(), $this$IF_ELSE_LIST.m92([$$this$FragmentShaderDefault.b92(), $this$IF_ELSE_LIST.l92(0.0), $this$IF_ELSE_LIST.l92(1.0)])), 'xy')]))));
        tmp = Unit_instance;
        break;
      case 2:
        $this$IF_ELSE_LIST.f92($this$IF_ELSE_LIST.i92(), $this$IF_ELSE_LIST.q92($$this$FragmentShaderDefault.p92(), $this$IF_ELSE_LIST.o92($this$IF_ELSE_LIST.j92(GpuShapeViewPrograms_getInstance().phu(), $this$IF_ELSE_LIST.m92([$this$IF_ELSE_LIST.a95($$this$FragmentShaderDefault.b92()), $this$IF_ELSE_LIST.b95($$this$FragmentShaderDefault.b92()), $this$IF_ELSE_LIST.l92(0.0), $this$IF_ELSE_LIST.l92(1.0)])), 'xy')));
        tmp = Unit_instance;
        break;
      case 3:
        var rpoint = $this$IF_ELSE_LIST.u93(VarType_Float2_getInstance());
        $this$IF_ELSE_LIST.f92($this$IF_ELSE_LIST.o92(rpoint, 'xy'), $this$IF_ELSE_LIST.o92($this$IF_ELSE_LIST.j92(GpuShapeViewPrograms_getInstance().phu(), $this$IF_ELSE_LIST.m92([$this$IF_ELSE_LIST.a95($$this$FragmentShaderDefault.b92()), $this$IF_ELSE_LIST.b95($$this$FragmentShaderDefault.b92()), $this$IF_ELSE_LIST.l92(0.0), $this$IF_ELSE_LIST.l92(1.0)])), 'xy'));
        var x = $this$IF_ELSE_LIST.a95(rpoint);
        var y = $this$IF_ELSE_LIST.b95(rpoint);
        var x0 = $this$IF_ELSE_LIST.a95(GpuShapeViewPrograms_getInstance().qhu());
        var y0 = $this$IF_ELSE_LIST.b95(GpuShapeViewPrograms_getInstance().qhu());
        var r0 = $this$IF_ELSE_LIST.c95(GpuShapeViewPrograms_getInstance().qhu());
        var x1 = $this$IF_ELSE_LIST.a95(GpuShapeViewPrograms_getInstance().rhu());
        var y1 = $this$IF_ELSE_LIST.b95(GpuShapeViewPrograms_getInstance().rhu());
        var r1 = $this$IF_ELSE_LIST.c95(GpuShapeViewPrograms_getInstance().rhu());
        var ratio = $this$IF_ELSE_LIST.a95($$this$FragmentShaderDefault.t92());
        var r0r1_2 = $this$IF_ELSE_LIST.b95($$this$FragmentShaderDefault.t92());
        var r0pow2 = $this$IF_ELSE_LIST.c95($$this$FragmentShaderDefault.t92());
        var r1pow2 = $this$IF_ELSE_LIST.d95($$this$FragmentShaderDefault.t92());
        var y0_y1 = $this$IF_ELSE_LIST.a95($$this$FragmentShaderDefault.v95());
        var x0_x1 = $this$IF_ELSE_LIST.b95($$this$FragmentShaderDefault.v95());
        var r0_r1 = $this$IF_ELSE_LIST.c95($$this$FragmentShaderDefault.v95());
        var radial_scale = $this$IF_ELSE_LIST.d95($$this$FragmentShaderDefault.v95());
        $this$IF_ELSE_LIST.f92(r0r1_2, $this$IF_ELSE_LIST.j92($this$IF_ELSE_LIST.j92($this$IF_ELSE_LIST.l92(2.0), r0), r1));
        $this$IF_ELSE_LIST.f92(r0pow2, GpuShapeViewPrograms_getInstance().shu(r0));
        $this$IF_ELSE_LIST.f92(r1pow2, GpuShapeViewPrograms_getInstance().shu(r1));
        $this$IF_ELSE_LIST.f92(x0_x1, $this$IF_ELSE_LIST.g95(x0, x1));
        $this$IF_ELSE_LIST.f92(y0_y1, $this$IF_ELSE_LIST.g95(y0, y1));
        $this$IF_ELSE_LIST.f92(r0_r1, $this$IF_ELSE_LIST.g95(r0, r1));
        $this$IF_ELSE_LIST.f92(radial_scale, $this$IF_ELSE_LIST.u92($this$IF_ELSE_LIST.l92(1.0), $this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.g95(GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95(r0, r1)), GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95(x0, x1))), GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95(y0, y1)))));
        $this$IF_ELSE_LIST.f92(ratio, $this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.l92(1.0), $this$IF_ELSE_LIST.j92($this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.i95($this$IF_ELSE_LIST.i95($this$IF_ELSE_LIST.j92($this$IF_ELSE_LIST.f95(r1), r0_r1), $this$IF_ELSE_LIST.j92(x0_x1, $this$IF_ELSE_LIST.g95(x1, x))), $this$IF_ELSE_LIST.j92(y0_y1, $this$IF_ELSE_LIST.g95(y1, y))), $this$IF_ELSE_LIST.g94($this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.i95($this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.j92(r1pow2, $this$IF_ELSE_LIST.i95(GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95(x0, x)), GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95(y0, y)))), $this$IF_ELSE_LIST.j92(r0r1_2, $this$IF_ELSE_LIST.i95($this$IF_ELSE_LIST.j92($this$IF_ELSE_LIST.g95(x0, x), $this$IF_ELSE_LIST.g95(x1, x)), $this$IF_ELSE_LIST.j92($this$IF_ELSE_LIST.g95(y0, y), $this$IF_ELSE_LIST.g95(y1, y))))), $this$IF_ELSE_LIST.j92(r0pow2, $this$IF_ELSE_LIST.i95(GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95(x1, x)), GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95(y1, y))))), GpuShapeViewPrograms_getInstance().shu($this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.i95($this$IF_ELSE_LIST.i95($this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.g95($this$IF_ELSE_LIST.j92(x1, y0), $this$IF_ELSE_LIST.j92(x, y0)), $this$IF_ELSE_LIST.j92(x0, y1)), $this$IF_ELSE_LIST.j92(x, y1)), $this$IF_ELSE_LIST.j92(x0, y)), $this$IF_ELSE_LIST.j92(x1, y)))))), radial_scale)));
        $this$IF_ELSE_LIST.f92($this$IF_ELSE_LIST.i92(), $this$IF_ELSE_LIST.q92($$this$FragmentShaderDefault.p92(), $this$IF_ELSE_LIST.u94([ratio, $this$IF_ELSE_LIST.l92(0.0)])));
        tmp = Unit_instance;
        break;
      case 4:
        var rpoint_0 = $this$IF_ELSE_LIST.u93(VarType_Float2_getInstance());
        $this$IF_ELSE_LIST.f92($this$IF_ELSE_LIST.o92(rpoint_0, 'xy'), $this$IF_ELSE_LIST.o92($this$IF_ELSE_LIST.j92(GpuShapeViewPrograms_getInstance().phu(), $this$IF_ELSE_LIST.m92([$this$IF_ELSE_LIST.a95($$this$FragmentShaderDefault.b92()), $this$IF_ELSE_LIST.b95($$this$FragmentShaderDefault.b92()), $this$IF_ELSE_LIST.l92(0.0), $this$IF_ELSE_LIST.l92(1.0)])), 'xy'));
        var x_0 = $this$IF_ELSE_LIST.a95(rpoint_0);
        var y_0 = $this$IF_ELSE_LIST.b95(rpoint_0);
        var ratio_0 = $this$IF_ELSE_LIST.a95($$this$FragmentShaderDefault.t92());
        var angle = $this$IF_ELSE_LIST.b95($$this$FragmentShaderDefault.t92());
        var x0_0 = $this$IF_ELSE_LIST.a95(GpuShapeViewPrograms_getInstance().qhu());
        var y0_0 = $this$IF_ELSE_LIST.b95(GpuShapeViewPrograms_getInstance().qhu());
        var PI2 = $this$IF_ELSE_LIST.l92(3.141592653589793 * 2);
        $this$IF_ELSE_LIST.f92(angle, $this$IF_ELSE_LIST.a94($this$IF_ELSE_LIST.g95(y_0, y0_0), $this$IF_ELSE_LIST.g95(x_0, x0_0)));
        // Inline function 'korlibs.graphics.shader.Builder.IF' call

        var cond = $this$IF_ELSE_LIST.n95(angle, $this$IF_ELSE_LIST.l92(0.0));
        var body = $this$IF_ELSE_LIST.w92();
        // Inline function 'korlibs.korge.view.vector.GpuShapeViewPrograms.PROGRAM_COMBINED.<anonymous>.<anonymous>.<anonymous>' call

        body.f92(angle, body.i95(angle, PI2));
        var stmIf = new If(cond, body.y92());
        // Inline function 'kotlin.collections.plusAssign' call

        $this$IF_ELSE_LIST.e92_1.f(stmIf);
        $this$IF_ELSE_LIST.f92(ratio_0, $this$IF_ELSE_LIST.u92(angle, PI2));
        $this$IF_ELSE_LIST.f92($this$IF_ELSE_LIST.i92(), $this$IF_ELSE_LIST.q92($$this$FragmentShaderDefault.p92(), $this$IF_ELSE_LIST.j94($this$IF_ELSE_LIST.u94([ratio_0, $this$IF_ELSE_LIST.l92(0.0)]))));
        tmp = Unit_instance;
        break;
    }
    return Unit_instance;
  };
}
var GpuShapeViewPrograms_instance;
function GpuShapeViewPrograms_getInstance() {
  if (GpuShapeViewPrograms_instance === VOID)
    new GpuShapeViewPrograms();
  return GpuShapeViewPrograms_instance;
}
function u_ProgramType$factory() {
  return getPropertyCallableRef('u_ProgramType', 1, KProperty1, function (receiver) {
    return receiver.mhu();
  }, null);
}
function u_Color$factory() {
  return getPropertyCallableRef('u_Color', 1, KProperty1, function (receiver) {
    return receiver.nhu();
  }, null);
}
function u_ColorMul$factory() {
  return getPropertyCallableRef('u_ColorMul', 1, KProperty1, function (receiver) {
    return receiver.lhu();
  }, null);
}
function u_GlobalAlpha$factory() {
  return getPropertyCallableRef('u_GlobalAlpha', 1, KProperty1, function (receiver) {
    return receiver.ohu();
  }, null);
}
function u_GlobalPixelScale$factory() {
  return getPropertyCallableRef('u_GlobalPixelScale', 1, KProperty1, function (receiver) {
    return receiver.khu();
  }, null);
}
function u_Transform$factory() {
  return getPropertyCallableRef('u_Transform', 1, KProperty1, function (receiver) {
    return receiver.phu();
  }, null);
}
function u_Gradientp0$factory() {
  return getPropertyCallableRef('u_Gradientp0', 1, KProperty1, function (receiver) {
    return receiver.qhu();
  }, null);
}
function u_Gradientp1$factory() {
  return getPropertyCallableRef('u_Gradientp1', 1, KProperty1, function (receiver) {
    return receiver.rhu();
  }, null);
}
function u_ProgramType$factory_0() {
  return getPropertyCallableRef('u_ProgramType', 1, KProperty1, function (receiver) {
    return receiver.mhu();
  }, null);
}
function u_Color$factory_0() {
  return getPropertyCallableRef('u_Color', 1, KProperty1, function (receiver) {
    return receiver.nhu();
  }, null);
}
function u_ColorMul$factory_0() {
  return getPropertyCallableRef('u_ColorMul', 1, KProperty1, function (receiver) {
    return receiver.lhu();
  }, null);
}
function u_GlobalAlpha$factory_0() {
  return getPropertyCallableRef('u_GlobalAlpha', 1, KProperty1, function (receiver) {
    return receiver.ohu();
  }, null);
}
function u_GlobalPixelScale$factory_0() {
  return getPropertyCallableRef('u_GlobalPixelScale', 1, KProperty1, function (receiver) {
    return receiver.khu();
  }, null);
}
function u_Transform$factory_0() {
  return getPropertyCallableRef('u_Transform', 1, KProperty1, function (receiver) {
    return receiver.phu();
  }, null);
}
function u_Gradientp0$factory_0() {
  return getPropertyCallableRef('u_Gradientp0', 1, KProperty1, function (receiver) {
    return receiver.qhu();
  }, null);
}
function u_Gradientp1$factory_0() {
  return getPropertyCallableRef('u_Gradientp1', 1, KProperty1, function (receiver) {
    return receiver.rhu();
  }, null);
}
function completeViews(views) {
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForLambda(Korge$_init_$slambda_ys8r4w, VOID, VOID, [1]);
initMetadataForLambda(Korge$start$slambda, VOID, VOID, [1]);
initMetadataForClass(Korge, 'Korge', Korge, VOID, VOID, [1]);
initMetadataForCompanion(Companion_0);
initMetadataForClass(KorgeDisplayMode, 'KorgeDisplayMode');
initMetadataForLambda(KorgeRunner$invoke$slambda$slambda$slambda, VOID, VOID, [1]);
initMetadataForLambda(KorgeRunner$invoke$slambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(KorgeRunner$invoke$slambda, VOID, VOID, [1]);
initMetadataForLambda(KorgeRunner$prepareViewsBase$lambda$slambda, VOID, VOID, [0]);
initMetadataForObject(KorgeRunner, 'KorgeRunner', VOID, VOID, VOID, [1]);
initMetadataForLambda(Korge$slambda, VOID, VOID, [1]);
initMetadataForLambda(Korge$slambda_0, VOID, VOID, [1]);
initMetadataForCompanion(Companion_1);
initMetadataForClass(RootAnimationNode, 'RootAnimationNode');
initMetadataForClass(TweenNode, 'TweenNode');
initMetadataForClass(BlockNode, 'BlockNode');
initMetadataForClass(Animator, 'Animator', VOID, VOID, [CloseableCancellable], [1]);
initMetadataForClass(AnimateCancellationException, 'AnimateCancellationException');
initMetadataForClass(ViewStageComponent, 'ViewStageComponent');
initMetadataForClass(GamePadEvents, 'GamePadEvents');
initMetadataForCompanion(Companion_2);
initMetadataForClass(GamePadStickEvent, 'GamePadStickEvent', GamePadStickEvent);
initMetadataForClass(Type, 'Type');
initMetadataForClass(GamePadButtonEvent, 'GamePadButtonEvent', GamePadButtonEvent);
initMetadataForClass(GestureEvents, 'GestureEvents');
initMetadataForLambda(KeysEvents$down$slambda, VOID, VOID, [1]);
initMetadataForLambda(KeysEvents$down$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(KeysEvents$down$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(KeysEvents$up$slambda, VOID, VOID, [1]);
initMetadataForLambda(KeysEvents$typed$slambda, VOID, VOID, [1]);
initMetadataForLambda(KeysEvents$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(KeysEvents$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(KeysEvents$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForClass(KeysEvents, 'KeysEvents', VOID, VOID, [AutoCloseable]);
initMetadataForClass(MouseDragInfo, 'MouseDragInfo');
initMetadataForClass(OnMouseDragCloseable, 'OnMouseDragCloseable', VOID, VOID, [AutoCloseable]);
initMetadataForClass(MouseDragState, 'MouseDragState');
initMetadataForClass(DraggableInfo, 'DraggableInfo');
initMetadataForClass(DraggableCloseable, 'DraggableCloseable', VOID, VOID, [AutoCloseable]);
initMetadataForLambda(onMouseDragInternal$slambda, VOID, VOID, [1]);
initMetadataForLambda(onMouseDragInternal$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(onMouseDragInternal$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(onMouseDragInternal$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(onMouseDragInternal$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(onMouseDragInternal$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForCompanion(Companion_3);
initMetadataForClass(MouseEventsUpdate, 'MouseEventsUpdate');
initMetadataForClass(MouseEvents, 'MouseEvents', VOID, VOID, [AutoCloseable]);
initMetadataForClass(Info, 'Info', Info);
initMetadataForClass(TouchEvents, 'TouchEvents');
initMetadataForClass(SingleTouchHandler, 'SingleTouchHandler', SingleTouchHandler);
initMetadataForClass(singleTouch$SingleTouchInfo, 'SingleTouchInfo');
initMetadataForClass(ScaleRecognizerInfo, 'ScaleRecognizerInfo', ScaleRecognizerInfo);
initMetadataForClass(RotationRecognizerInfo, 'RotationRecognizerInfo', RotationRecognizerInfo);
initMetadataForObject(MaterialBlockUB, 'MaterialBlockUB');
initMetadataForObject(MaterialRender, 'MaterialRender');
initMetadataForClass(Scene, 'Scene', VOID, VOID, [InjectorDependency, CoroutineScope], [0]);
initMetadataForClass(EmptyScene, 'EmptyScene', EmptyScene, VOID, VOID, [0]);
initMetadataForClass(ScaledScene, 'ScaledScene', VOID, VOID, VOID, [0]);
initMetadataForClass(PixelatedScene, 'PixelatedScene', VOID, VOID, VOID, [0]);
initMetadataForClass(VisitEntry, 'VisitEntry');
initMetadataForCompanion(Companion_4);
initMetadataForLambda(SceneContainer$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(SceneContainer$_changeTo$slambda, VOID, VOID, [0]);
initMetadataForLambda(SceneContainer$_changeTo$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(SceneContainer$_changeTo$slambda_1, VOID, VOID, [0]);
initMetadataForLambda(SceneContainer$_changeTo$slambda_2, VOID, VOID, [0]);
initMetadataForLambda(SceneContainer$_changeTo$slambda_3, VOID, VOID, [0]);
initMetadataForLambda(SceneContainer$_changeTo$slambda_4, VOID, VOID, [0]);
initMetadataForClass(BaseView, 'BaseView', BaseView.pgb);
initMetadataForClass(View, 'View');
initMetadataForClass(Container, 'Container', Container.vbw);
initMetadataForClass(CachedContainer, 'CachedContainer', CachedContainer.qgb);
initMetadataForInterface(Reference, 'Reference');
initMetadataForClass(FixedSizeCachedContainer, 'FixedSizeCachedContainer', FixedSizeCachedContainer.eg1, VOID, [CachedContainer, Reference]);
initMetadataForClass(UIView, 'UIView', UIView.wc6);
initMetadataForClass(SceneContainer, 'SceneContainer', VOID, VOID, [UIView, CoroutineScope], [2, 4, 6, 3]);
initMetadataForInterface(TransitionProcess, 'TransitionProcess');
initMetadataForClass(TransitionView, 'TransitionView', TransitionView.xc6);
protoOf(Transition$1).ace = start;
protoOf(Transition$1).bce = end;
initMetadataForClass(Transition$1, VOID, VOID, VOID, [TransitionProcess]);
initMetadataForClass(withEasing$1$create$1, VOID, VOID, VOID, [TransitionProcess]);
initMetadataForClass(withEasing$1);
initMetadataForClass(ViewStyles, 'ViewStyles');
initMetadataForClass(ViewStyle, 'ViewStyle');
initMetadataForInterface(StyleableView, 'StyleableView');
initMetadataForObject(MsdfRender, 'MsdfRender');
initMetadataForClass(TextSnapshot, 'TextSnapshot');
initMetadataForLambda(TextEditController$slambda, VOID, VOID, [1]);
initMetadataForLambda(TextEditController$slambda_0, VOID, VOID, [1]);
initMetadataForInterface(UIFocusable, 'UIFocusable');
initMetadataForClass(TextEditController, 'TextEditController', VOID, VOID, [AutoCloseable, UIFocusable, ISoftKeyboardConfig]);
initMetadataForClass(TimerComponents, 'TimerComponents', VOID, VOID, VOID, [1, 0]);
initMetadataForClass(TimerRef, 'TimerRef');
initMetadataForClass(TweenComponent, 'TweenComponent');
initMetadataForLambda(tween$slambda, VOID, VOID, [1]);
initMetadataForLambda(tweenAsync$slambda, VOID, VOID, [0]);
initMetadataForCompanion(Companion_5);
initMetadataForLambda(UIButton$slambda, VOID, VOID, [1]);
initMetadataForLambda(UIButton$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(UIButton$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(UIButton$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(UIButton$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(UIButton$slambda_2, VOID, VOID, [1]);
initMetadataForClass(UIFocusableView, 'UIFocusableView', UIFocusableView.td2, VOID, [UIView, UIFocusable]);
initMetadataForClass(UIButton, 'UIButton', UIButton.sd2);
initMetadataForLambda(clicked$slambda, VOID, VOID, [1]);
initMetadataForLambda(clicked$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(UIButtonToggleableGroup$add$slambda, VOID, VOID, [1]);
initMetadataForLambda(UIButtonToggleableGroup$add$lambda$slambda, VOID, VOID, [0]);
initMetadataForClass(UIButtonToggleableGroup, 'UIButtonToggleableGroup', UIButtonToggleableGroup);
initMetadataForClass(Kind, 'Kind', Kind);
initMetadataForClass(sam$korlibs_korge_view_ViewRenderer$0, 'sam$korlibs_korge_view_ViewRenderer$0');
initMetadataForLambda(UIBaseCheckBox$slambda, VOID, VOID, [1]);
initMetadataForLambda(UIBaseCheckBox$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(UIBaseCheckBox$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(UIBaseCheckBox$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(UIBaseCheckBox$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(UIBaseCheckBox$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForLambda(UIBaseCheckBox$slambda_2, VOID, VOID, [1]);
initMetadataForLambda(UIBaseCheckBox$lambda$slambda_2, VOID, VOID, [0]);
initMetadataForLambda(UIBaseCheckBox$slambda_3, VOID, VOID, [1]);
initMetadataForLambda(UIBaseCheckBox$lambda$slambda_3, VOID, VOID, [0]);
initMetadataForLambda(UIBaseCheckBox$slambda_4, VOID, VOID, [1]);
initMetadataForClass(UIBaseCheckBox, 'UIBaseCheckBox');
initMetadataForCompanion(Companion_6);
initMetadataForClass(UICheckBox, 'UICheckBox', UICheckBox.sdm);
initMetadataForLambda(UIComboBox$verticalList$o$getItemView$slambda, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$verticalList$o$getItemView$lambda$slambda, VOID, VOID, [0]);
initMetadataForInterface(Provider, 'Provider');
protoOf(UIComboBox$verticalList$1).sdt = getItemY;
initMetadataForClass(UIComboBox$verticalList$1, VOID, VOID, VOID, [Provider]);
initMetadataForLambda(UIComboBox$slambda, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(UIComboBox$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(UIComboBox$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForLambda(UIComboBox$slambda_2, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$lambda$slambda_2, VOID, VOID, [0]);
initMetadataForLambda(UIComboBox$slambda_3, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$lambda$slambda_3, VOID, VOID, [0]);
initMetadataForLambda(UIComboBox$slambda_4, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$slambda_5, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$slambda_6, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$slambda_7, VOID, VOID, [1]);
initMetadataForLambda(UIComboBox$slambda_8, VOID, VOID, [1]);
initMetadataForClass(UIComboBox, 'UIComboBox', UIComboBox.mdy);
initMetadataForClass(UIBaseContainer, 'UIBaseContainer');
initMetadataForClass(UIContainer, 'UIContainer');
initMetadataForClass(UIGridFill, 'UIGridFill', UIGridFill.te7);
initMetadataForClass(UIVerticalHorizontalStack, 'UIVerticalHorizontalStack');
initMetadataForClass(UIVerticalStack, 'UIVerticalStack', UIVerticalStack.eee);
initMetadataForClass(UIHorizontalStack, 'UIHorizontalStack', UIHorizontalStack.dek);
initMetadataForClass(UIHorizontalVerticalFill, 'UIHorizontalVerticalFill');
initMetadataForClass(UIHorizontalFill, 'UIHorizontalFill', UIHorizontalFill.fen);
initMetadataForCompanion(Companion_7);
initMetadataForClass(UIDirection, 'UIDirection');
initMetadataForClass(UIObservableProperty, 'UIObservableProperty');
initMetadataForClass(UIEditableNumberProps, 'UIEditableNumberProps');
initMetadataForClass(UIEditableNumber, 'UIEditableNumber', UIEditableNumber.kf0);
initMetadataForObject(Scope, 'Scope');
initMetadataForClass(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', VOID, VOID, [Comparator]);
initMetadataForClass(UIFocusManager, 'UIFocusManager');
initMetadataForClass(makeFocusable$2, VOID, VOID, VOID, [UIFocusable]);
initMetadataForObject(UIIcons, 'UIIcons');
initMetadataForClass(UIImage, 'UIImage');
initMetadataForClass(Highlight, 'Highlight');
initMetadataForClass(Highlights, 'Highlights');
initMetadataForInterface(Anchorable, 'Anchorable');
initMetadataForClass(UIMaterialLayer, 'UIMaterialLayer', UIMaterialLayer.ud2, VOID, [UIView, Anchorable]);
initMetadataForClass(UIObservable, 'UIObservable');
initMetadataForClass(UIProperty$1);
initMetadataForClass(UIPropertyRow, 'UIPropertyRow');
initMetadataForClass(UIPropertyNumberRow, 'UIPropertyNumberRow');
initMetadataForClass(MyScrollbarInfo, 'MyScrollbarInfo');
initMetadataForClass(UIScrollable, 'UIScrollable');
initMetadataForCompanion(Companion_8);
initMetadataForLambda(UISlider$thumb$slambda, VOID, VOID, [1]);
initMetadataForLambda(UISlider$thumb$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(UISlider$thumb$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(UISlider$thumb$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForClass(UISlider, 'UISlider', UISlider.dfm, VOID, [UIView, StyleableView]);
initMetadataForCompanion(Companion_9);
initMetadataForCompanion(Companion_10);
initMetadataForLambda(UIText$slambda, VOID, VOID, [1]);
initMetadataForLambda(UIText$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(UIText$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(UIText$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(UIText$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(UIText$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForLambda(UIText$slambda_2, VOID, VOID, [1]);
initMetadataForLambda(UIText$lambda$slambda_2, VOID, VOID, [0]);
initMetadataForClass(UIText, 'UIText');
initMetadataForClass(sam$korlibs_korge_view_ViewRenderer$0_0, 'sam$korlibs_korge_view_ViewRenderer$0');
initMetadataForClass(UITextInput, 'UITextInput', UITextInput.mf0, VOID, [UIView, ISoftKeyboardConfig]);
initMetadataForClass(sam$korlibs_korge_view_ViewRenderer$0_1, 'sam$korlibs_korge_view_ViewRenderer$0');
initMetadataForClass(UITooltipContainer, 'UITooltipContainer', UITooltipContainer.efs);
initMetadataForLambda(tooltip$slambda, VOID, VOID, [1]);
initMetadataForLambda(tooltip$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(tooltip$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(tooltip$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForCompanion(Companion_11);
initMetadataForClass(Tooltip, 'Tooltip');
initMetadataForClass(UITooltipContainerMediatorNew, 'UITooltipContainerMediatorNew', VOID, VOID, [AutoCloseable]);
initMetadataForCompanion(Companion_12);
initMetadataForClass(UIVerticalList, 'UIVerticalList');
initMetadataForCompanion(Companion_13);
initMetadataForClass(ScaleHandler, 'ScaleHandler');
initMetadataForClass(sam$korlibs_korge_view_ViewRenderer$0_2, 'sam$korlibs_korge_view_ViewRenderer$0');
initMetadataForLambda(UIWindow$closeButton$slambda, VOID, VOID, [1]);
initMetadataForLambda(UIWindow$closeButton$lambda$slambda, VOID, VOID, [0]);
initMetadataForClass(UIWindow, 'UIWindow', VOID, VOID, VOID, [0]);
initMetadataForClass(InternalViewAutoscaling, 'InternalViewAutoscaling', InternalViewAutoscaling);
initMetadataForClass(RectBase, 'RectBase', RectBase.egl, VOID, [View, Anchorable]);
initMetadataForClass(BaseImage, 'BaseImage');
initMetadataForClass(BaseGraphics, 'BaseGraphics');
initMetadataForClass(CacheTexture, 'CacheTexture', VOID, VOID, [AutoCloseable]);
initMetadataForClass(Camera, 'Camera', Camera.fge, VOID, [Container, Reference], [4]);
initMetadataForClass(ShapeView, 'ShapeView', ShapeView.je1, VOID, [Container, Anchorable]);
initMetadataForClass(Circle_0, 'Circle', Circle_0.hgh);
initMetadataForClass(FixedSizeContainer, 'FixedSizeContainer', FixedSizeContainer.sfh, VOID, [Container, Reference]);
initMetadataForClass(ClipContainer, 'ClipContainer', ClipContainer.mfp);
initMetadataForObject(ZIndexComparator, 'ZIndexComparator', VOID, VOID, [Comparator]);
initMetadataForClass(ContainerCollection$iterator$1);
initMetadataForClass(ContainerCollection, 'ContainerCollection', VOID, VOID, [Collection, MutableIterable, KtList]);
initMetadataForClass(CpuGraphics, 'CpuGraphics', CpuGraphics.bgl);
initMetadataForClass(CustomContextRenderizableView, 'CustomContextRenderizableView');
initMetadataForClass(DummyView, 'DummyView', DummyView.ggn);
initMetadataForClass(Ellipse, 'Ellipse');
initMetadataForClass(ShadedView, 'ShadedView');
initMetadataForClass(FastRoundRectBase, 'FastRoundRectBase');
initMetadataForClass(FastEllipse, 'FastEllipse', FastEllipse.qgs);
initMetadataForObject(SDFUB, 'SDFUB');
initMetadataForCompanion(Companion_14);
initMetadataForCompanion(Companion_15);
initMetadataForClass(SContainer, 'SContainer', SContainer.kbz);
initMetadataForClass(TimeSlidingWindow, 'TimeSlidingWindow');
initMetadataForClass(GraphicsRenderer, 'GraphicsRenderer');
initMetadataForClass(Graphics, 'Graphics', Graphics.zh3, VOID, [Container, Anchorable]);
initMetadataForInterface(ViewFileRef, 'ViewFileRef', VOID, VOID, VOID, [3]);
initMetadataForInterface(SmoothedBmpSlice, 'SmoothedBmpSlice');
protoOf(Image).rh4 = forceLoadSourceFile$default;
initMetadataForClass(Image, 'Image', VOID, VOID, [BaseImage, ViewFileRef, SmoothedBmpSlice], [3]);
initMetadataForClass(RenderableView, 'RenderableView');
initMetadataForClass(CoordsType, 'CoordsType');
initMetadataForCompanion(Companion_16);
initMetadataForCompanion(Companion_17);
initMetadataForClass(SolidRect, 'SolidRect');
initMetadataForClass(Stage, 'Stage', VOID, VOID, [FixedSizeContainer, Reference, CoroutineScope, BoundsProvider]);
initMetadataForCompanion(Companion_18);
initMetadataForLambda(Text$_renderInternal$slambda, VOID, VOID, [0]);
initMetadataForClass(Text, 'Text', VOID, VOID, VOID, [2]);
initMetadataForCompanion(Companion_19);
initMetadataForClass(TextBlock, 'TextBlock', TextBlock.gd6);
initMetadataForCompanion(Companion_20);
protoOf(VectorImage).rh4 = forceLoadSourceFile$default;
initMetadataForClass(VectorImage, 'VectorImage', VOID, VOID, [BaseGraphics, ViewFileRef], [3]);
initMetadataForInterface(ColorReference, 'ColorReference');
initMetadataForCompanion(Companion_21);
initMetadataForClass(sam$kotlin_Comparator$0_0, 'sam$kotlin_Comparator$0', VOID, VOID, [Comparator]);
initMetadataForInterface(ViewRenderPhase, 'ViewRenderPhase');
initMetadataForLambda(ViewFileRef$Mixin$lazyLoadRenderInternalActually$slambda, VOID, VOID, [0]);
protoOf(Mixin_0).rh4 = forceLoadSourceFile$default;
initMetadataForClass(Mixin_0, 'Mixin', Mixin_0, VOID, [ViewFileRef], [3]);
initMetadataForLambda(Views$close$slambda, VOID, VOID, [0]);
initMetadataForClass(Views, 'Views', VOID, VOID, [BaseEventListener, CoroutineScope, BoundsProvider, AutoCloseable, DeviceDimensionsProvider], [0]);
initMetadataForCompanion(Companion_22);
initMetadataForClass(ViewsUpdateEvent, 'ViewsUpdateEvent');
initMetadataForCompanion(Companion_23);
initMetadataForClass(ViewsResizedEvent, 'ViewsResizedEvent');
initMetadataForObject(UB, 'UB');
initMetadataForCompanion(Companion_24);
initMetadataForClass(Batch, 'Batch');
initMetadataForClass(DebugVertexView, 'DebugVertexView');
initMetadataForClass(FViewInfo, 'FViewInfo');
initMetadataForClass(FView, 'FView');
initMetadataForObject(FspritesUB, 'FspritesUB');
initMetadataForCompanion(Companion_25);
initMetadataForClass(FSprites, 'FSprites');
initMetadataForClass(FSprite, 'FSprite');
initMetadataForCompanion(Companion_26);
initMetadataForInterface(Filter, 'Filter');
initMetadataForClass(ComposedFilter, 'ComposedFilter', ComposedFilter.ehf, VOID, [Filter]);
initMetadataForClass(BlurFilter, 'BlurFilter', BlurFilter.chf, VOID, [ComposedFilter, Filter]);
initMetadataForCompanion(Companion_27);
initMetadataForObject(ConvoluteUB, 'ConvoluteUB');
initMetadataForClass(BaseProgramProvider, 'BaseProgramProvider', BaseProgramProvider);
initMetadataForCompanion(Companion_28);
protoOf(ShaderFilter).zhf = get_recommendedFilterScale;
initMetadataForClass(ShaderFilter, 'ShaderFilter', VOID, VOID, [Filter]);
initMetadataForClass(Convolute3Filter, 'Convolute3Filter');
initMetadataForObject(BlurUB, 'BlurUB');
initMetadataForCompanion(Companion_29);
initMetadataForClass(DirectionalBlurFilter, 'DirectionalBlurFilter', DirectionalBlurFilter);
initMetadataForCompanion(Companion_30);
protoOf(DropshadowFilter).zhf = get_recommendedFilterScale;
initMetadataForClass(DropshadowFilter, 'DropshadowFilter', DropshadowFilter, VOID, [Filter]);
initMetadataForCompanion(Companion_31);
initMetadataForClass(RenderToTextureResult, 'RenderToTextureResult', RenderToTextureResult);
protoOf(IdentityFilter).zhf = get_recommendedFilterScale;
protoOf(IdentityFilter).ahg = computeBorder;
initMetadataForClass(IdentityFilter, 'IdentityFilter', VOID, VOID, [Filter]);
initMetadataForCompanion(Companion_32);
initMetadataForObject(Linear, 'Linear');
initMetadataForObject(Nearest, 'Nearest');
initMetadataForObject(PageUB, 'PageUB');
initMetadataForCompanion(Companion_33);
initMetadataForClass(PageFilter, 'PageFilter', PageFilter);
initMetadataForCompanion(Companion_34);
initMetadataForObject(TexInfoUB, 'TexInfoUB');
initMetadataForCompanion(Companion_35);
initMetadataForClass(SwizzleProgram, 'SwizzleProgram');
initMetadataForCompanion(Companion_36);
initMetadataForClass(SwizzleColorsFilter, 'SwizzleColorsFilter', SwizzleColorsFilter);
initMetadataForCompanion(Companion_37);
initMetadataForClass(Transition, 'Transition');
initMetadataForObject(TransitionUB, 'TransitionUB');
initMetadataForCompanion(Companion_38);
initMetadataForClass(TransitionFilter, 'TransitionFilter', TransitionFilter);
initMetadataForClass(ViewRenderPhaseBackdropFilter, 'ViewRenderPhaseBackdropFilter', VOID, VOID, [ViewRenderPhase]);
initMetadataForCompanion(Companion_39);
protoOf(ViewRenderPhaseFilter).jh8 = beforeRender;
protoOf(ViewRenderPhaseFilter).kh8 = afterRender;
initMetadataForClass(ViewRenderPhaseFilter, 'ViewRenderPhaseFilter', ViewRenderPhaseFilter, VOID, [ViewRenderPhase]);
initMetadataForObject(WaveUB, 'WaveUB');
initMetadataForCompanion(Companion_40);
initMetadataForClass(WaveFilter, 'WaveFilter', WaveFilter);
initMetadataForClass(Info_0, 'Info');
initMetadataForCompanion(Companion_41);
initMetadataForClass(TileMap, 'TileMap', TileMap.nhr);
initMetadataForClass(PointsResult, 'PointsResult');
initMetadataForClass(GpuShapeView, 'GpuShapeView', GpuShapeView.wh3, VOID, [View, Anchorable]);
initMetadataForClass(ScissorCommand, 'ScissorCommand');
initMetadataForClass(ClearCommand, 'ClearCommand');
initMetadataForClass(ShapeCommand, 'ShapeCommand');
initMetadataForClass(GpuShapeViewCommands, 'GpuShapeViewCommands', GpuShapeViewCommands);
initMetadataForObject(ShapeViewUB, 'ShapeViewUB');
initMetadataForClass(PaintShader, 'PaintShader');
initMetadataForObject(GpuShapeViewPrograms, 'GpuShapeViewPrograms');
//endregion
//region block: init
KorgeRunner_instance = new KorgeRunner();
Companion_instance_17 = new Companion_2();
Companion_instance_18 = new Companion_3();
Companion_instance_22 = new Companion_7();
Scope_instance = new Scope();
Companion_instance_26 = new Companion_11();
Companion_instance_27 = new Companion_12();
ZIndexComparator_instance = new ZIndexComparator();
Companion_instance_30 = new Companion_15();
Companion_instance_31 = new Companion_16();
Companion_instance_32 = new Companion_17();
Companion_instance_33 = new Companion_18();
Companion_instance_35 = new Companion_20();
Companion_instance_36 = new Companion_21();
Companion_instance_37 = new Companion_22();
Companion_instance_38 = new Companion_23();
Companion_instance_41 = new Companion_26();
Companion_instance_42 = new Companion_27();
Companion_instance_54 = new Companion_39();
//endregion
//region block: exports
export {
  UIDirection_ROW_getInstance as UIDirection_ROW_getInstancemp081o8fljhz,
  GraphicsRenderer_CPU_getInstance as GraphicsRenderer_CPU_getInstance1ncsup8rv6gza,
  GraphicsRenderer_GPU_getInstance as GraphicsRenderer_GPU_getInstance2y97iviksol8a,
  GraphicsRenderer_SYSTEM_getInstance as GraphicsRenderer_SYSTEM_getInstance1vcvjxz5tx3ul,
  animate as animatexev04pctltcp,
  delay as delay1gbwd28dgkhwf,
  tweenAsync as tweenAsync2qo1zo5xt537f,
  tween_0 as tween3jbh5j0ixzsj7,
  views as views17oaxjs1xed1j,
  Korge_0 as Korge11eut3mr7473l,
  _FSprite___init__impl__y4bm26 as _FSprite___init__impl__y4bm26o3ksm0zh85u1,
  _FSprite___get_index__impl__7khb8f as _FSprite___get_index__impl__7khb8f3srrtt23r5dj1,
  Companion_getInstance_52 as Companion_getInstancezug9z9lglek,
  Companion_getInstance_53 as Companion_getInstance31zn3b6amcjag,
  Companion_getInstance_56 as Companion_getInstance19ucmifvc1zkc,
  Companion_getInstance_58 as Companion_getInstance1rvgf02fkp37z,
  Companion_instance_41 as Companion_instance3d7ruxdq0a083,
  Companion_instance_42 as Companion_instance3f2pfd31j919l,
  Companion_getInstance_76 as Companion_getInstancebcwpyc7v6z0k,
  Companion_getInstance_78 as Companion_getInstance2ae29zia1q3bi,
  Nearest_getInstance as Nearest_getInstance2ablj0bsr7f3q,
  Companion_getInstance_85 as Companion_getInstance3v280k3k1v32j,
  Companion_instance_33 as Companion_instancecqwbvq0sb4tp,
  Animator as Animator3ve4r56t61w8x,
  animator as animator38vbqz0qhvd6m,
  block as block1zs9asix98nq4,
  hide as hide1jf8h1e875esy,
  moveBy as moveBy2jlfhnymnp65t,
  show as show2aeumyubbk4hr,
  get_simpleAnimator as get_simpleAnimator3dm3towjhldr6,
  tween as tween3cqjzsn3zwtgf,
  wait as wait7ky6hfhpq4xi,
  GestureEvents as GestureEvents2xgmdbiz01aec,
  MouseEvents as MouseEvents19mapgq8ez76g,
  set_cursor as set_cursor2bma4bfi3ugur,
  get_gamepad as get_gamepad1qh8ubuabluby,
  get_gestures as get_gestures13dhx4eoxy4m7,
  get_keys as get_keysmipauarvwdlb,
  get_mouse as get_mouse1jstwhfaumelj,
  onDropFile as onDropFile1vcbx8hsm6l1h,
  onMouseDrag as onMouseDrag20om3ch85u2pf,
  rotationRecognizer as rotationRecognizer1cyl287fv0vf7,
  scaleRecognizer as scaleRecognizerbwriock1g9at,
  singleTouch as singleTouchbklee9j9g3fy,
  touch as touchiy6gmy0gyfbe,
  get_AlphaTransition as get_AlphaTransition23l7sd982osvm,
  PixelatedScene as PixelatedSceneoudxoze5iszc,
  SceneContainer as SceneContainer99ijjqni12hp,
  Scene as Scenezu810y12qpqp,
  withEasing as withEasingtnol1eyyo9sf,
  get_styles as get_styles1wpy8tgbxrx7h,
  set_textColor as set_textColor1p99dtdbjl3ci,
  set_textFont as set_textFontzxrbrksrw2rf,
  interval as intervalfu9yhuucbcwn,
  tweenNoWait as tweenNoWaitv4vl4ybj7v8x,
  UIButtonToggleableGroup as UIButtonToggleableGroup1ec0hg22gg0yq,
  UIButton as UIButton259u54yn72ttw,
  UICheckBox as UICheckBox1yp8vj5k61hy,
  UIComboBox as UIComboBoxr3ju7l8oh2k6,
  UIEditableNumberPropsList as UIEditableNumberPropsList9iwm3l1q1tjs,
  UIGridFill as UIGridFill2fuun2uv06ma4,
  UIHorizontalStack as UIHorizontalStack2hv4gvky7dghi,
  UIImage as UIImage3935n4wnarhtx,
  UIPropertyNumberRow as UIPropertyNumberRowlpofk3dkngtp,
  UIPropertyRow as UIPropertyRow4cwplox1mm4b,
  UISlider as UISlider5m4dwgr7ecf8,
  UIText as UIText20upwp95popg2,
  UITooltipContainer as UITooltipContainer1enlpvyskzqjr,
  getItemY as getItemY38ekgpm3oa8ep,
  Provider as Provider12q7o5zi8ker6,
  UIVerticalList as UIVerticalList8l3cvb5wtmnl,
  UIVerticalStack as UIVerticalStack2gichhwk4pg3x,
  UIWindow as UIWindowfz5bs45h8v81,
  get_UI_DEFAULT_SIZE as get_UI_DEFAULT_SIZE1tys63379ijr3,
  clicked as clicked3gw2g8098k3q5,
  makeFocusable as makeFocusablebj3kuvwsemya,
  tooltip as tooltip2lix13cilv7ve,
  centerOnStage as centerOnStage1tf2oda60yrak,
  FSprites as FSprites2ca4s38v9nhd8,
  BlurFilter as BlurFilter1o7ok9bw0jdji,
  ComposedFilter as ComposedFilter2v12lgfcjpd5f,
  Convolute3Filter as Convolute3Filter5g83e8d3dtvp,
  DropshadowFilter as DropshadowFilter3mmze965vo4ad,
  PageFilter as PageFilter2zor0zqtd57qt,
  SwizzleColorsFilter as SwizzleColorsFilter1v3oimf3q32jo,
  TransitionFilter as TransitionFilter3bnqs9bq9zqka,
  WaveFilter as WaveFilter3180y9l8huonp,
  composedOrNull as composedOrNull2kh1bl8tczqtf,
  set_filter as set_filter36wgvpm5cukpc,
  get_filterScale as get_filterScaleuvlwz3cded4n,
  set_filterScale as set_filterScale3iptc9lhu4vp1,
  TileMap as TileMapunot5gnjvpqy,
  Anchorable as Anchorable2p2wd2c4mob8i,
  BaseView as BaseView2hxxa0hhvhngi,
  Camera as Camera3eju605c3layp,
  Circle_0 as Circlepba9jsmewo4k,
  Container as Container7486fjmip554,
  DummyView as DummyView1mkji6wt5kruk,
  Ellipse as Ellipse3mlmmuhtwwzwf,
  FastEllipse as FastEllipse3srb40xmwqack,
  FixedSizeContainer as FixedSizeContainersre0rworj71w,
  values as values367gftm1eq5yt,
  Graphics as Graphics2q0lx93a2j4b2,
  Image as Image1b2d7ghyim7p5,
  SmoothedBmpSlice as SmoothedBmpSlice3gb2g5l2kdzel,
  SolidRect as SolidRect131p7hyzlx7j8,
  Stage as Stage1ggtrzujnnkl0,
  Text as Text3kimhvgfxflj3,
  VectorImage as VectorImage34eszej2kvy5t,
  Reference as Reference3ldn5efkcy9oq,
  afterRender as afterRenderjbmwx3695z3h,
  beforeRender as beforeRenderiy81t6fb11po,
  ViewRenderPhase as ViewRenderPhase1ybhofrqiytg2,
  View as View11ybmmoof9vue,
  addFixedUpdater_1 as addFixedUpdaterkdt88bj87xku,
  addFixedUpdater as addFixedUpdater1i013etalwvod,
  addTo as addTo25e29vq105prw,
  addUpdater_1 as addUpdater2sjoslzga432q,
  addUpdater as addUpdater1la23qj9g2d42,
  alpha_1 as alphaher384qrj9q4,
  anchor as anchor3c5itxst996yy,
  get_centered as get_centeredilxldnbf7ma6,
  colorMul as colorMulerj70rh5yczt,
  findFirstAscendant as findFirstAscendante8nli1jx4t64,
  onStageResized as onStageResized1gaool2kxfglm,
  position as positionu6441b7hkb4l,
  replaceWith as replaceWith20ov7o46kyfqm,
  rotation as rotation29g98bv3f78wn,
  scale as scalea9fc0mbrr7ub,
  sizeScaled as sizeScaled36d00h3dw190k,
  size as size1ac0fubkxl72w,
  size_0 as size3ul8wgwwt88hf,
  xy as xyssniz4rympby,
  xy_0 as xy1gb51vhy014mx,
  zIndex as zIndex304xgfufia3na,
};
//endregion
