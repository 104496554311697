import {
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  Unit_instance104q5opgivhr8 as Unit_instance,
  contentEquals2jbsaphs7gxql as contentEquals,
  contentHashCode33l4yznfe2pz3 as contentHashCode,
  intArrayIterator15bt84fheeonh as intArrayIterator,
  protoOf180f3jzyo7rfj as protoOf,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  VOID7hggqo3abtya as VOID,
  contentEquals379bkuxwxaiqz as contentEquals_0,
  contentHashCode2dpr73siofp13 as contentHashCode_0,
  floatArrayIteratortsocm2kbztk as floatArrayIterator,
  coerceIn10f36k81le1mm as coerceIn,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  contentEqualsc4e23leqcdd7 as contentEquals_1,
  Long2qws0ah9gnpki as Long,
  contentHashCode3g64c8pna955v as contentHashCode_1,
  longArrayIterator1n9ha3b3kmtst as longArrayIterator,
  println2shhhgwwt4c61 as println,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  toLongw1zpgk99d84b as toLong,
  reverse2o3etdk6ug8y7 as reverse,
  copyOfq9pcgcgbldck as copyOf,
  isFloatArrayjjscnqphw92j as isFloatArray,
  createThis2j2avj17cvnv2 as createThis,
  takeg3iw3d2yd8b4 as take,
  equals2au1ep9vhcato as equals,
  KtList3hktaavzmj137 as KtList,
  isInterface3d6p8outrmvmk as isInterface,
  StringBuildermazzzhj6kkai as StringBuilder,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  Collection1k04j3hzsbod0 as Collection,
  isIntArrayeijsubfngq38 as isIntArray,
  copyOf3rutauicler23 as copyOf_0,
  IndexOutOfBoundsException1qfr429iumro0 as IndexOutOfBoundsException,
  take8rd02inxauy8 as take_0,
  copyOfgossjg6lh6js as copyOf_1,
  isDoubleArray1wyh4nyf7pjxn as isDoubleArray,
  takei63g8tyo7wy9 as take_1,
  asList2ho2pewtsfvv as asList,
  toShort36kaw0zjdq3ex as toShort,
  compareTo3ankvs086tmwq as compareTo,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  until1jbpn0z3f8lbg as until,
  ArrayList3it5z8td81qkl as ArrayList,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  isArray1hxjqtqy632bc as isArray,
  toSet2orjxp16sotqu as toSet,
  toList3jhuyej2anx2q as toList,
  to2cs3ny02qtbcb as to,
  KtMap140uvy3s5zad8 as KtMap,
  hashCodeq5arwsb9dgti as hashCode,
  MutableIterablez3x4ksk1fmrm as MutableIterable,
  AbstractMutableSetthfi6jds1k2h as AbstractMutableSet,
  LinkedHashSet2tkztfx86kyx2 as LinkedHashSet,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  toMutableSetjdpdbr9jsqq8 as toMutableSet,
  NoSuchElementException679xzhnp5bpj as NoSuchElementException,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  coerceAtMost322komnqp70ag as coerceAtMost,
  joinToString1cxrrlmo0chqs as joinToString,
  Iterable1y3qizuf89iou as Iterable,
  copyOf2ng0t8oizk6it as copyOf_2,
  toString30pk9tzaqopn as toString_0,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  KtMutableSetwuwn7k5m570a as KtMutableSet,
  Comparator2b3maoeh98xtg as Comparator,
  reversed3cb5fwpceefdb as reversed,
  NotImplementedErrorfzlkpv14xxr8 as NotImplementedError,
  contentEqualsaf55p28mnw74 as contentEquals_2,
  contentHashCode2i020q5tbeh2s as contentHashCode_2,
  _UInt___init__impl__l7qpdl2soskspn8hzmt as _UInt___init__impl__l7qpdl,
  _UInt___get_data__impl__f0vqqw1vaw6fgw4kwl0 as _UInt___get_data__impl__f0vqqw,
  addAll21mdhg523wnoa as addAll,
  first1vi3grnpj1175 as first,
  longArray288a0fctlmjmj as longArray,
  isLongArray2fdt3z7yu3ef as isLongArray,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  lazy2hsh8ze7j6ikd as lazy,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  removeLast3759euu1xvfa3 as removeLast,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  SequenceScope1coiso86pqzq2 as SequenceScope,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  HashMap1a0ld5kgwhmhv as HashMap,
  iterator3f5i676wpaa3g as iterator,
  KtMutableMap1kqeifoi36kpz as KtMutableMap,
  contentHashCodem24x0wy7gjt7 as contentHashCode_3,
  contentEquals5p44wfjhu6ta as contentEquals_3,
  arrayCopytctsywo3h7gj as arrayCopy,
  Char__plus_impl_qi7pgjue8oeapgr66d as Char__plus_impl_qi7pgj,
  joinToString39rl9p9h59k3o as joinToString_0,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  toRawBits2035dtuolth0v as toRawBits,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  getOrNull1go7ef9ldk0df as getOrNull,
  step18s9qzr5xwxat as step,
  round2mrvepag8eey0 as round,
  toList383f556t1dixk as toList_0,
} from './kotlin-kotlin-stdlib.mjs';
import {
  ilog23dbvpk38jf7rc as ilog2,
  umod164hvijxhfi8p as umod,
  divCeil2r4o5lbvnv4lh as divCeil,
  ilog2Ceil34a3ub1n8578t as ilog2Ceil,
  isAlmostEquals2e7wogp48cvmj as isAlmostEquals,
  get_niceStr3crhxydmlnaoj as get_niceStr,
  isAlmostEquals$default1ua43koqhm6mp as isAlmostEquals$default,
  roundDecimalPlaces1x32iuyd1l8m5 as roundDecimalPlaces,
  appendNice3hizhj9kone09 as appendNice,
  appendGenericArray5krccch8fjd0 as appendGenericArray,
} from './korlibs-korlibs-math-core.mjs';
import {
  listIterator247sl90ooi4qv as listIterator,
  DoubleList2honu8rif2roh as DoubleList,
  fastArrayListOf1rdodhrpn0q8y as fastArrayListOf,
  FastArrayListw5fr1wlp4slq as FastArrayList,
  Mixin3lmkm5hum2vdj as Mixin,
} from './korlibs-korlibs-datastructure-core.mjs';
import {
  Companion_instance3cau272s9vxbi as Companion_instance,
  Vector2D378fe5rov8fgw as Vector2D,
  IDoubleVectorListoa05cse3he2p as IDoubleVectorList,
  IPointListz7raskb2mrxa as IPointList,
  isNotEmptyrwyeygz7izwg as isNotEmpty,
  isAlmostEquals2qd88bwpjj4z5 as isAlmostEquals_0,
  containsAll194lepx84fvib as containsAll,
  indexOf3tp8bzbv46hci as indexOf,
  iterator1sm8hwbj0nmg8 as iterator_0,
  listIteratorrk3szyua4uzm as listIterator_0,
  listIterator3ooh2yjav3kr9 as listIterator_1,
  subList2funs4qwcrhhq as subList,
} from './korlibs-korlibs-math-vector.mjs';
import { atomic$int$11d5swdyn6j0pu as atomic$int$1 } from './kotlinx-atomicfu.mjs';
import {
  NonRecursiveLock2vf7fkhp857q as NonRecursiveLock,
  Lock5n7hh3dgcidl as Lock,
} from './korlibs-korlibs-concurrent.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Companion {}
class IArray2 {}
function inside(x, y) {
  return x >= 0 && y >= 0 && x < this.j2y() && y < this.k2y();
}
function getAt(x, y) {
  return this.v2y(x, y) ? this.h13(indexOr(this, x, y)) : this.h13(0);
}
function iterator_1() {
  // Inline function 'kotlin.collections.map' call
  var this_0 = until(0, imul(this.j2y(), this.k2y()));
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'korlibs.datastructure.IArray2.iterator.<anonymous>' call
    var tmp$ret$0 = this.h13(item);
    destination.f(tmp$ret$0);
  }
  return destination.h3();
}
function asString() {
  var tmp = until(0, this.k2y());
  return joinToString(tmp, '\n', VOID, VOID, VOID, VOID, IArray2$asString$lambda(this));
}
class IntIArray2 {}
function get(x, y) {
  return this.m2y(indexOr(this, x, y));
}
function set(x, y, value) {
  return this.n2y(indexOr(this, x, y), value);
}
function setAt(idx, value) {
  return this.n2y(idx, value);
}
function getAt_0(idx) {
  return this.m2y(idx);
}
function get_0(p) {
  return this.z2k(p.s2u_1, p.t2u_1);
}
function set_0(p, value) {
  return this.p2y(p.s2u_1, p.t2u_1, value);
}
function set_1(rect, value) {
  var l = coerceIn(rect.p2i(), 0, this.j2y());
  var r = coerceIn(rect.q2i(), 0, this.j2y());
  var u = coerceIn(rect.r2i(), 0, this.k2y());
  var d = coerceIn(rect.s2i(), 0, this.k2y());
  var inductionVariable = l;
  var tmp;
  if (inductionVariable < r) {
    do {
      var x = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var inductionVariable_0 = u;
      if (inductionVariable_0 < d)
        do {
          var y = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          // Inline function 'korlibs.datastructure.IntIArray2.set.<anonymous>' call
          this.p2y(x, y, value);
        }
         while (inductionVariable_0 < d);
    }
     while (inductionVariable < r);
    tmp = Unit_instance;
  }
  return tmp;
}
class IntArray2 {
  constructor(width, height, data) {
    this.g2y_1 = width;
    this.h2y_1 = height;
    this.i2y_1 = data;
    Companion_instance_6.l2y(this.j2y(), this.k2y(), this.i2y_1.length);
  }
  j2y() {
    return this.g2y_1;
  }
  k2y() {
    return this.h2y_1;
  }
  equals(other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof IntArray2) {
      tmp_1 = this.j2y() === other.j2y();
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.k2y() === other.k2y();
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals(this.i2y_1, other.i2y_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  m2y(idx) {
    // Inline function 'kotlin.collections.getOrElse' call
    var this_0 = this.i2y_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (0 <= idx ? idx <= (this_0.length - 1 | 0) : false) {
      tmp = this_0[idx];
    } else {
      // Inline function 'korlibs.datastructure.IntArray2.getFast.<anonymous>' call
      tmp = this.i2y_1[0];
    }
    return tmp;
  }
  n2y(idx, value) {
    if (0 <= idx ? idx <= (this.i2y_1.length - 1 | 0) : false) {
      this.i2y_1[idx] = value;
    }
  }
  hashCode() {
    return (this.j2y() + this.k2y() | 0) + contentHashCode(this.i2y_1) | 0;
  }
  f1() {
    return intArrayIterator(this.i2y_1);
  }
  toString() {
    return this.o2y();
  }
  t2y(idx, value) {
    return this.s2y(idx, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  }
}
class Companion_0 {}
class FloatIArray2 {}
function get_1(x, y) {
  return this.m2y(indexOr(this, x, y));
}
function set_2(x, y, value) {
  return this.z2y(indexOr(this, x, y), value);
}
function setAt_0(idx, value) {
  return this.z2y(idx, value);
}
function getAt_1(idx) {
  return this.m2y(idx);
}
class FloatArray2 {
  constructor(width, height, data) {
    this.w2y_1 = width;
    this.x2y_1 = height;
    this.y2y_1 = data;
    Companion_instance_6.l2y(this.j2y(), this.k2y(), this.y2y_1.length);
  }
  j2y() {
    return this.w2y_1;
  }
  k2y() {
    return this.x2y_1;
  }
  equals(other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof FloatArray2) {
      tmp_1 = this.j2y() === other.j2y();
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.k2y() === other.k2y();
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals_0(this.y2y_1, other.y2y_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  m2y(idx) {
    // Inline function 'kotlin.collections.getOrElse' call
    var this_0 = this.y2y_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (0 <= idx ? idx <= (this_0.length - 1 | 0) : false) {
      tmp = this_0[idx];
    } else {
      // Inline function 'korlibs.datastructure.FloatArray2.getFast.<anonymous>' call
      tmp = 0.0;
    }
    return tmp;
  }
  z2y(idx, value) {
    if (0 <= idx ? idx <= (this.y2y_1.length - 1 | 0) : false) {
      this.y2y_1[idx] = value;
    }
  }
  hashCode() {
    return (this.j2y() + this.k2y() | 0) + contentHashCode_0(this.y2y_1) | 0;
  }
  f1() {
    return floatArrayIterator(this.y2y_1);
  }
  toString() {
    return this.o2y();
  }
  t2y(idx, value) {
    return this.b2z(idx, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  }
}
class Companion_1 {}
class LongIArray2 {}
function get_2(x, y) {
  return this.m2y(indexOr(this, x, y));
}
function set_3(x, y, value) {
  return this.g2z(indexOr(this, x, y), value);
}
function setAt_1(idx, value) {
  return this.g2z(idx, value);
}
function getAt_2(idx) {
  return this.m2y(idx);
}
class LongArray2 {
  constructor(width, height, data) {
    this.d2z_1 = width;
    this.e2z_1 = height;
    this.f2z_1 = data;
    Companion_instance_6.l2y(this.j2y(), this.k2y(), this.f2z_1.length);
  }
  j2y() {
    return this.d2z_1;
  }
  k2y() {
    return this.e2z_1;
  }
  equals(other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof LongArray2) {
      tmp_1 = this.j2y() === other.j2y();
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.k2y() === other.k2y();
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals_1(this.f2z_1, other.f2z_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  m2y(idx) {
    // Inline function 'kotlin.collections.getOrElse' call
    var this_0 = this.f2z_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (0 <= idx ? idx <= (this_0.length - 1 | 0) : false) {
      tmp = this_0[idx];
    } else {
      // Inline function 'korlibs.datastructure.LongArray2.getFast.<anonymous>' call
      tmp = new Long(0, 0);
    }
    return tmp;
  }
  g2z(idx, value) {
    if (0 <= idx ? idx <= (this.f2z_1.length - 1 | 0) : false) {
      this.f2z_1[idx] = value;
    }
  }
  hashCode() {
    return (this.j2y() + this.k2y() | 0) + contentHashCode_1(this.f2z_1) | 0;
  }
  f1() {
    return longArrayIterator(this.f2z_1);
  }
  toString() {
    return this.o2y();
  }
  t2y(idx, value) {
    return this.i2z(idx, value instanceof Long ? value : THROW_CCE());
  }
}
class ByteArrayDeque {
  constructor(initialBits, allowGrow) {
    initialBits = initialBits === VOID ? 10 : initialBits;
    allowGrow = allowGrow === VOID ? true : allowGrow;
    this.r2z_1 = initialBits;
    this.s2z_1 = allowGrow;
    this.t2z_1 = new RingBuffer(this.r2z_1);
    this.u2z_1 = new Int8Array(1024);
    this.v2z_1 = new Long(0, 0);
    this.w2z_1 = new Long(0, 0);
  }
  i12() {
    return this.t2z_1.q2z_1;
  }
  j12(buffer, offset, size) {
    ensureWrite(this, size);
    var out = this.t2z_1.j12(buffer, offset, size);
    if (out > 0) {
      var tmp = this;
      // Inline function 'kotlin.Long.plus' call
      tmp.v2z_1 = this.v2z_1.c4(toLong(out));
    }
    return out;
  }
  y2z(buffer, offset, size, $super) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? buffer.length - offset | 0 : size;
    return $super === VOID ? this.j12(buffer, offset, size) : $super.j12.call(this, buffer, offset, size);
  }
  oz(count) {
    return this.t2z_1.oz(count);
  }
  x12(buffer, offset, size) {
    return this.t2z_1.x12(buffer, offset, size);
  }
  o3() {
    this.t2z_1.o3();
  }
  hashCode() {
    return this.t2z_1.l12();
  }
  equals(other) {
    var tmp;
    if (other instanceof ByteArrayDeque) {
      tmp = this.t2z_1.equals(other.t2z_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
}
class ShortArrayDeque {
  constructor(initialBits) {
    initialBits = initialBits === VOID ? 10 : initialBits;
    this.i30_1 = initialBits;
    this.j30_1 = new ShortRingBuffer(this.i30_1);
    this.k30_1 = new Int16Array(1024);
    this.l30_1 = new Long(0, 0);
    this.m30_1 = new Long(0, 0);
  }
  i12() {
    return this.j30_1.g30_1;
  }
  o30(buffer, offset, size) {
    var out = ensureWrite_0(this, size).j30_1.o30(buffer, offset, size);
    if (out > 0) {
      var tmp = this;
      // Inline function 'kotlin.Long.plus' call
      tmp.l30_1 = this.l30_1.c4(toLong(out));
    }
    return out;
  }
  n30(buffer, offset, size) {
    var out = this.j30_1.n30(buffer, offset, size);
    if (out > 0) {
      var tmp = this;
      // Inline function 'kotlin.Long.plus' call
      tmp.m30_1 = this.m30_1.c4(toLong(out));
    }
    return out;
  }
  o3() {
    this.j30_1.o3();
  }
  p30() {
    this.n30(this.k30_1, 0, 1);
    return this.k30_1[0];
  }
  q30(value) {
    this.k30_1[0] = value;
    this.o30(this.k30_1, 0, 1);
  }
  hashCode() {
    return this.j30_1.l12();
  }
  equals(other) {
    var tmp;
    if (other instanceof ShortArrayDeque) {
      tmp = this.j30_1.equals(other.j30_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
}
class Companion_2 {}
class FloatArrayList {
  static t30(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    var $this = createThis(this);
    var tmp = $this;
    var tmp_0 = new Float32Array(capacity);
    tmp.r30_1 = isFloatArray(tmp_0) ? tmp_0 : THROW_CCE();
    $this.s30_1 = 0;
    return $this;
  }
  nv(value) {
    ensure(this, value);
    this.s30_1 = value;
  }
  j1() {
    return this.s30_1;
  }
  static u30(other) {
    var $this = this.t30();
    $this.v30(other);
    return $this;
  }
  o3() {
    this.s30_1 = 0;
  }
  w30(value) {
    ensure(this, 1);
    var tmp = this.r30_1;
    var _unary__edvuaz = this.s30_1;
    this.s30_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = value;
  }
  x30(v0, v1, v2, v3) {
    ensure(this, 4);
    var tmp = this.r30_1;
    var _unary__edvuaz = this.s30_1;
    this.s30_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
    var tmp_0 = this.r30_1;
    var _unary__edvuaz_0 = this.s30_1;
    this.s30_1 = _unary__edvuaz_0 + 1 | 0;
    tmp_0[_unary__edvuaz_0] = v1;
    var tmp_1 = this.r30_1;
    var _unary__edvuaz_1 = this.s30_1;
    this.s30_1 = _unary__edvuaz_1 + 1 | 0;
    tmp_1[_unary__edvuaz_1] = v2;
    var tmp_2 = this.r30_1;
    var _unary__edvuaz_2 = this.s30_1;
    this.s30_1 = _unary__edvuaz_2 + 1 | 0;
    tmp_2[_unary__edvuaz_2] = v3;
  }
  y30(values, offset, length) {
    ensure(this, length);
    var tmp = Memory_instance;
    tmp.z30(isFloatArray(values) ? values : THROW_CCE(), offset, this.r30_1, this.j1(), length);
    this.nv(this.j1() + length | 0);
  }
  v30(values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.y30(values, offset, length);
      tmp = Unit_instance;
    } else {
      tmp = $super.y30.call(this, values, offset, length);
    }
    return tmp;
  }
  i1(index) {
    return this.r30_1[index];
  }
  h13(index) {
    return this.r30_1[index];
  }
  f1() {
    return this.p1(0);
  }
  a31(element) {
    var inductionVariable = 0;
    var last = this.s30_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.r30_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  }
  f3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.a31((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  b31(elements) {
    var _iterator__ex2g4s = elements.f1();
    while (_iterator__ex2g4s.g1()) {
      var e = _iterator__ex2g4s.h1();
      if (!this.a31(e))
        return false;
    }
    return true;
  }
  g3(elements) {
    return this.b31(elements);
  }
  a1() {
    return this.j1() === 0;
  }
  c31() {
    return copyOf(this.r30_1, this.s30_1);
  }
  p1(index) {
    return take(this.r30_1, this.s30_1).p1(index);
  }
  hashCode() {
    return contentHashCode_4(this.r30_1, 0, this.j1());
  }
  equals(other) {
    if (other instanceof FloatArrayList)
      return this.j1() === other.j1() && contentEquals_4(this.r30_1, other.r30_1, 0, this.j1());
    if (!(other == null) ? isInterface(other, KtList) : false)
      return equals(other, this);
    return false;
  }
  toString() {
    // Inline function 'kotlin.also' call
    var this_0 = StringBuilder.nc(2 + imul(5, this.j1()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.FloatArrayList.toString.<anonymous>' call
    this_0.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.ic(', ');
        }
        this_0.qh(this.h13(n));
      }
       while (inductionVariable < last);
    this_0.jc(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  }
}
class Companion_3 {}
class IntArrayList {
  static f31(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    var $this = createThis(this);
    var tmp = $this;
    var tmp_0 = new Int32Array(capacity);
    tmp.d31_1 = isIntArray(tmp_0) ? tmp_0 : THROW_CCE();
    $this.e31_1 = 0;
    return $this;
  }
  nv(value) {
    this.g31(value);
    this.e31_1 = value;
  }
  j1() {
    return this.e31_1;
  }
  static h31(other) {
    var $this = this.f31();
    $this.i31(other);
    return $this;
  }
  static j31(other) {
    var $this = this.f31();
    $this.k31(other);
    return $this;
  }
  g31(count) {
    if ((this.e31_1 + count | 0) > this.d31_1.length) {
      var tmp = this;
      var tmp_0 = this.d31_1;
      // Inline function 'kotlin.math.max' call
      var a = this.e31_1 + count | 0;
      var b = imul(this.d31_1.length, 3);
      var tmp$ret$0 = Math.max(a, b);
      var tmp_1 = copyOf_0(tmp_0, tmp$ret$0);
      tmp.d31_1 = isIntArray(tmp_1) ? tmp_1 : THROW_CCE();
    }
  }
  o3() {
    this.e31_1 = 0;
  }
  l31(v0) {
    this.g31(1);
    var tmp = this.d31_1;
    var _unary__edvuaz = this.e31_1;
    this.e31_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
  }
  m31(v0, v1, v2, v3) {
    this.g31(4);
    var tmp = this.d31_1;
    var _unary__edvuaz = this.e31_1;
    this.e31_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
    var tmp_0 = this.d31_1;
    var _unary__edvuaz_0 = this.e31_1;
    this.e31_1 = _unary__edvuaz_0 + 1 | 0;
    tmp_0[_unary__edvuaz_0] = v1;
    var tmp_1 = this.d31_1;
    var _unary__edvuaz_1 = this.e31_1;
    this.e31_1 = _unary__edvuaz_1 + 1 | 0;
    tmp_1[_unary__edvuaz_1] = v2;
    var tmp_2 = this.d31_1;
    var _unary__edvuaz_2 = this.e31_1;
    this.e31_1 = _unary__edvuaz_2 + 1 | 0;
    tmp_2[_unary__edvuaz_2] = v3;
  }
  n31(v0, v1, v2, v3, v4, v5) {
    this.g31(6);
    var tmp = this.d31_1;
    var _unary__edvuaz = this.e31_1;
    this.e31_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
    var tmp_0 = this.d31_1;
    var _unary__edvuaz_0 = this.e31_1;
    this.e31_1 = _unary__edvuaz_0 + 1 | 0;
    tmp_0[_unary__edvuaz_0] = v1;
    var tmp_1 = this.d31_1;
    var _unary__edvuaz_1 = this.e31_1;
    this.e31_1 = _unary__edvuaz_1 + 1 | 0;
    tmp_1[_unary__edvuaz_1] = v2;
    var tmp_2 = this.d31_1;
    var _unary__edvuaz_2 = this.e31_1;
    this.e31_1 = _unary__edvuaz_2 + 1 | 0;
    tmp_2[_unary__edvuaz_2] = v3;
    var tmp_3 = this.d31_1;
    var _unary__edvuaz_3 = this.e31_1;
    this.e31_1 = _unary__edvuaz_3 + 1 | 0;
    tmp_3[_unary__edvuaz_3] = v4;
    var tmp_4 = this.d31_1;
    var _unary__edvuaz_4 = this.e31_1;
    this.e31_1 = _unary__edvuaz_4 + 1 | 0;
    tmp_4[_unary__edvuaz_4] = v5;
  }
  o31(value) {
    return this.l31(value);
  }
  p31(value) {
    return this.i31(value);
  }
  q31(values, offset, length) {
    this.g31(length);
    var tmp = Memory_instance;
    tmp.r31(isIntArray(values) ? values : THROW_CCE(), offset, this.d31_1, this.j1(), length);
    this.nv(this.j1() + length | 0);
  }
  k31(values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.q31(values, offset, length);
      tmp = Unit_instance;
    } else {
      tmp = $super.q31.call(this, values, offset, length);
    }
    return tmp;
  }
  i31(values) {
    return this.q31(values.d31_1, 0, values.j1());
  }
  i1(index) {
    return this.d31_1[index];
  }
  h13(index) {
    return this.d31_1[index];
  }
  s31(index, value) {
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntArrayList.setAt.<anonymous>' call
    this.t31(index, value);
    return value;
  }
  t31(index, value) {
    if (index >= this.e31_1) {
      this.g31(index + 1 | 0);
      this.e31_1 = index + 1 | 0;
    }
    this.d31_1[index] = value;
  }
  f1() {
    return this.p1(0);
  }
  dp(element) {
    var inductionVariable = 0;
    var last = this.e31_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.d31_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  }
  f3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.dp((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  u31(elements) {
    var _iterator__ex2g4s = elements.f1();
    while (_iterator__ex2g4s.g1()) {
      var e = _iterator__ex2g4s.h1();
      if (!this.dp(e))
        return false;
    }
    return true;
  }
  g3(elements) {
    return this.u31(elements);
  }
  a1() {
    return this.j1() === 0;
  }
  y2k() {
    return !(this.j1() === 0);
  }
  kl() {
    var tmp;
    if (this.a1()) {
      throw IndexOutOfBoundsException.ze();
    } else {
      tmp = this.d31_1[0];
    }
    return tmp;
  }
  v31() {
    var tmp;
    if (this.a1()) {
      throw IndexOutOfBoundsException.ze();
    } else {
      tmp = this.d31_1[this.e31_1 - 1 | 0];
    }
    return tmp;
  }
  w31(index, value) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntArrayList.insertAt.<anonymous>' call
    this.g31(1);
    if (this.y2k()) {
      Memory_instance.r31(this.d31_1, index, this.d31_1, index + 1 | 0, this.e31_1 - index | 0);
    }
    this.d31_1[index] = value;
    this.e31_1 = this.e31_1 + 1 | 0;
    return this;
  }
  x31(indexA, indexB) {
    var l = this.h13(indexA);
    var r = this.h13(indexB);
    this.t31(indexA, r);
    this.t31(indexB, l);
  }
  q3(index) {
    return this.y31(index, 1);
  }
  y31(index, count) {
    if (index < 0 || index >= this.e31_1 || (index + count | 0) > this.e31_1)
      throw IndexOutOfBoundsException.ze();
    var out = this.d31_1[index];
    if (count > 0) {
      if (index < (this.e31_1 - count | 0)) {
        Memory_instance.r31(this.d31_1, index + count | 0, this.d31_1, index, (this.e31_1 - index | 0) - count | 0);
      }
      this.e31_1 = this.e31_1 - count | 0;
    }
    return out;
  }
  z31() {
    return copyOf_0(this.d31_1, this.e31_1);
  }
  p1(index) {
    return take_0(this.d31_1, this.e31_1).p1(index);
  }
  hashCode() {
    return contentHashCode_5(this.d31_1, 0, this.j1());
  }
  equals(other) {
    if (other instanceof IntArrayList)
      return this.j1() === other.j1() && contentEquals_5(this.d31_1, other.d31_1, 0, this.j1());
    if (!(other == null) ? isInterface(other, KtList) : false)
      return equals(other, this);
    return false;
  }
  toString() {
    // Inline function 'kotlin.also' call
    var this_0 = StringBuilder.nc(2 + imul(5, this.j1()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntArrayList.toString.<anonymous>' call
    this_0.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.ic(', ');
        }
        this_0.oh(this.h13(n));
      }
       while (inductionVariable < last);
    this_0.jc(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  }
}
class Companion_4 {}
class DoubleArrayList {
  static c32(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    var $this = createThis(this);
    var tmp = $this;
    var tmp_0 = new Float64Array(capacity);
    tmp.a32_1 = isDoubleArray(tmp_0) ? tmp_0 : THROW_CCE();
    $this.b32_1 = 0;
    return $this;
  }
  nv(value) {
    ensure_0(this, value);
    this.b32_1 = value;
  }
  j1() {
    return this.b32_1;
  }
  static d32(other) {
    var $this = this.c32();
    $this.e32(other);
    return $this;
  }
  static f32(other) {
    var $this = this.c32();
    $this.g32(other);
    return $this;
  }
  o3() {
    this.b32_1 = 0;
  }
  t2d(v0) {
    ensure_0(this, 1);
    var tmp = this.a32_1;
    var _unary__edvuaz = this.b32_1;
    this.b32_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
  }
  h32(v0, v1) {
    ensure_0(this, 2);
    var tmp = this.a32_1;
    var _unary__edvuaz = this.b32_1;
    this.b32_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
    var tmp_0 = this.a32_1;
    var _unary__edvuaz_0 = this.b32_1;
    this.b32_1 = _unary__edvuaz_0 + 1 | 0;
    tmp_0[_unary__edvuaz_0] = v1;
  }
  i32(v0, v1, v2) {
    ensure_0(this, 3);
    var tmp = this.a32_1;
    var _unary__edvuaz = this.b32_1;
    this.b32_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
    var tmp_0 = this.a32_1;
    var _unary__edvuaz_0 = this.b32_1;
    this.b32_1 = _unary__edvuaz_0 + 1 | 0;
    tmp_0[_unary__edvuaz_0] = v1;
    var tmp_1 = this.a32_1;
    var _unary__edvuaz_1 = this.b32_1;
    this.b32_1 = _unary__edvuaz_1 + 1 | 0;
    tmp_1[_unary__edvuaz_1] = v2;
  }
  j32(v0, v1, v2, v3) {
    ensure_0(this, 4);
    var tmp = this.a32_1;
    var _unary__edvuaz = this.b32_1;
    this.b32_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
    var tmp_0 = this.a32_1;
    var _unary__edvuaz_0 = this.b32_1;
    this.b32_1 = _unary__edvuaz_0 + 1 | 0;
    tmp_0[_unary__edvuaz_0] = v1;
    var tmp_1 = this.a32_1;
    var _unary__edvuaz_1 = this.b32_1;
    this.b32_1 = _unary__edvuaz_1 + 1 | 0;
    tmp_1[_unary__edvuaz_1] = v2;
    var tmp_2 = this.a32_1;
    var _unary__edvuaz_2 = this.b32_1;
    this.b32_1 = _unary__edvuaz_2 + 1 | 0;
    tmp_2[_unary__edvuaz_2] = v3;
  }
  k32(v0, v1, v2, v3, v4, v5) {
    ensure_0(this, 6);
    var tmp = this.a32_1;
    var _unary__edvuaz = this.b32_1;
    this.b32_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v0;
    var tmp_0 = this.a32_1;
    var _unary__edvuaz_0 = this.b32_1;
    this.b32_1 = _unary__edvuaz_0 + 1 | 0;
    tmp_0[_unary__edvuaz_0] = v1;
    var tmp_1 = this.a32_1;
    var _unary__edvuaz_1 = this.b32_1;
    this.b32_1 = _unary__edvuaz_1 + 1 | 0;
    tmp_1[_unary__edvuaz_1] = v2;
    var tmp_2 = this.a32_1;
    var _unary__edvuaz_2 = this.b32_1;
    this.b32_1 = _unary__edvuaz_2 + 1 | 0;
    tmp_2[_unary__edvuaz_2] = v3;
    var tmp_3 = this.a32_1;
    var _unary__edvuaz_3 = this.b32_1;
    this.b32_1 = _unary__edvuaz_3 + 1 | 0;
    tmp_3[_unary__edvuaz_3] = v4;
    var tmp_4 = this.a32_1;
    var _unary__edvuaz_4 = this.b32_1;
    this.b32_1 = _unary__edvuaz_4 + 1 | 0;
    tmp_4[_unary__edvuaz_4] = v5;
  }
  l32(value) {
    return this.t2d(value);
  }
  m32(value) {
    return this.e32(value);
  }
  n32(values, offset, length) {
    ensure_0(this, length);
    var tmp = Memory_instance;
    tmp.o32(isDoubleArray(values) ? values : THROW_CCE(), offset, this.a32_1, this.j1(), length);
    this.nv(this.j1() + length | 0);
  }
  g32(values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.n32(values, offset, length);
      tmp = Unit_instance;
    } else {
      tmp = $super.n32.call(this, values, offset, length);
    }
    return tmp;
  }
  e32(values) {
    return this.n32(values.a32_1, 0, values.j1());
  }
  i1(index) {
    return this.a32_1[index];
  }
  h13(index) {
    return this.a32_1[index];
  }
  p32(index, value) {
    if (index >= this.b32_1) {
      ensure_0(this, index + 1 | 0);
      this.b32_1 = index + 1 | 0;
    }
    this.a32_1[index] = value;
  }
  f1() {
    return this.p1(0);
  }
  k13(element) {
    var inductionVariable = 0;
    var last = this.b32_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.a32_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  }
  f3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.k13((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  l13(elements) {
    var _iterator__ex2g4s = elements.f1();
    while (_iterator__ex2g4s.g1()) {
      var e = _iterator__ex2g4s.h1();
      if (!this.k13(e))
        return false;
    }
    return true;
  }
  g3(elements) {
    return this.l13(elements);
  }
  a1() {
    return this.j1() === 0;
  }
  x31(indexA, indexB) {
    var l = this.h13(indexA);
    var r = this.h13(indexB);
    this.p32(indexA, r);
    this.p32(indexB, l);
  }
  q3(index) {
    return this.y31(index, 1);
  }
  y31(index, count) {
    if (index < 0 || index >= this.b32_1 || (index + count | 0) > this.b32_1)
      throw IndexOutOfBoundsException.ze();
    var out = this.a32_1[index];
    if (count > 0) {
      if (index < (this.b32_1 - count | 0)) {
        Memory_instance.o32(this.a32_1, index + count | 0, this.a32_1, index, (this.b32_1 - index | 0) - count | 0);
      }
      this.b32_1 = this.b32_1 - count | 0;
    }
    return out;
  }
  i13() {
    return copyOf_1(this.a32_1, this.b32_1);
  }
  p1(index) {
    return take_1(this.a32_1, this.b32_1).p1(index);
  }
  n1(fromIndex, toIndex) {
    // Inline function 'kotlin.collections.asList' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = this.a32_1;
    return asList(tmp$ret$1).n1(fromIndex, toIndex);
  }
  j13() {
    return DoubleArrayList.d32(this);
  }
  hashCode() {
    return contentHashCode_6(this.a32_1, 0, this.j1());
  }
  equals(other) {
    if (other instanceof DoubleArrayList)
      return this.j1() === other.j1() && contentEquals_6(this.a32_1, other.a32_1, 0, this.j1());
    if (!(other == null) ? isInterface(other, KtList) : false)
      return equals(other, this);
    return false;
  }
  toString() {
    // Inline function 'kotlin.also' call
    var this_0 = StringBuilder.nc(2 + imul(5, this.j1()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.DoubleArrayList.toString.<anonymous>' call
    this_0.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.ic(', ');
        }
        this_0.rh(this.h13(n));
      }
       while (inductionVariable < last);
    this_0.jc(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  }
}
class ShortArrayList {
  constructor(initialCapacity) {
    initialCapacity = initialCapacity === VOID ? 7 : initialCapacity;
    this.q32_1 = IntArrayList.f31(initialCapacity);
  }
  nv(value) {
    this.q32_1.nv(value);
  }
  j1() {
    return this.q32_1.j1();
  }
  i1(index) {
    return this.h13(index);
  }
  r32(index, value) {
    return this.s32(index, value);
  }
  h13(index) {
    return toShort(this.q32_1.h13(index));
  }
  s32(index, value) {
    return this.q32_1.s31(index, value);
  }
  t32(index) {
    return toShort(this.q32_1.q3(index));
  }
  o3() {
    return this.q32_1.o3();
  }
  u32(value) {
    return this.q32_1.l31(value);
  }
  p8(capacity) {
    return this.q32_1.g31(capacity);
  }
  v32() {
    var out = new Int16Array(this.j1());
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out[n] = toShort(this.q32_1.i1(n));
      }
       while (inductionVariable < last);
    return out;
  }
}
class BooleanArrayList {
  constructor(initialCapacity) {
    initialCapacity = initialCapacity === VOID ? 7 : initialCapacity;
    this.w32_1 = IntArrayList.f31(initialCapacity);
  }
  nv(value) {
    this.w32_1.nv(value);
  }
  i1(index) {
    return this.h13(index);
  }
  x32(index, value) {
    return this.y32(index, value);
  }
  h13(index) {
    return !(this.w32_1.h13(index) === 0);
  }
  y32(index, value) {
    return this.w32_1.s31(index, toInt(value));
  }
  t32(index) {
    return !(this.w32_1.q3(index) === 0);
  }
}
class SortOps {
  e33(subject, indexL, indexR) {
    var inductionVariable = indexR;
    var last = indexL + 1 | 0;
    if (last <= inductionVariable)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        this.d33(subject, n - 1 | 0, n);
      }
       while (!(n === last));
  }
  z32(subject, indexL, indexR) {
    var count = (indexR - indexL | 0) + 1 | 0;
    var inductionVariable = 0;
    var last = count / 2 | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.d33(subject, indexL + n | 0, indexR - n | 0);
      }
       while (inductionVariable < last);
  }
}
class DoubleArrayListSortOps extends SortOps {
  constructor() {
    DoubleArrayListSortOps_instance = null;
    super();
    DoubleArrayListSortOps_instance = this;
  }
  a33(subject, l, r) {
    return compareTo(subject.h13(l), subject.h13(r));
  }
  b33(subject, l, r) {
    return this.a33(subject instanceof DoubleArrayList ? subject : THROW_CCE(), l, r);
  }
  c33(subject, indexL, indexR) {
    var l = subject.h13(indexL);
    var r = subject.h13(indexR);
    subject.p32(indexR, l);
    subject.p32(indexL, r);
  }
  d33(subject, indexL, indexR) {
    return this.c33(subject instanceof DoubleArrayList ? subject : THROW_CCE(), indexL, indexR);
  }
}
class BaseObservableArray {
  constructor() {
    this.a34_1 = atomic$int$1(0);
    this.b34_1 = atomic$int$1(0);
  }
}
class BaseObservableArray2 extends BaseObservableArray {
  constructor(base, updated) {
    super();
    this.r33_1 = base;
    this.s33_1 = updated;
    this.t33_1 = 0;
    this.u33_1 = 0;
    this.v33_1 = 0;
    this.w33_1 = 0;
    this.z33();
  }
  o33() {
    return this.r33_1;
  }
  z33() {
    this.t33_1 = 2147483647;
    this.u33_1 = -2147483648;
    this.v33_1 = 2147483647;
    this.w33_1 = -2147483648;
  }
  fd() {
    if (this.b34_1.kotlinx$atomicfu$value === 0 && !_get_isEmpty__oti0q6(this)) {
      this.a34_1.atomicfu$incrementAndGet();
      this.s33_1(!(this == null) ? this : THROW_CCE(), Companion_instance.h2t(this.t33_1, this.v33_1, this.u33_1 + 1 | 0, this.w33_1 + 1 | 0));
      this.z33();
    }
  }
  x33(x, y) {
    _mark(this, x, y);
    this.fd();
  }
  y33(rect) {
    _mark(this, rect.p2i(), rect.r2i());
    _mark(this, rect.q2i() - 1 | 0, rect.s2i() - 1 | 0);
    this.fd();
  }
  j2y() {
    return this.o33().j2y();
  }
  k2y() {
    return this.o33().k2y();
  }
  f1() {
    return this.o33().f1();
  }
}
class ObservableIntArray2 extends BaseObservableArray2 {
  constructor(base, updated) {
    super(base, updated);
    this.n33_1 = base;
  }
  o33() {
    return this.n33_1;
  }
  m2y(idx) {
    return this.o33().m2y(idx);
  }
  n2y(idx, value) {
    if (this.m2y(idx) === value)
      return Unit_instance;
    this.o33().n2y(idx, value);
    this.x33(revIndexX(this, idx), revIndexY(this, idx));
  }
  r2y(rect, value) {
    this.o33().r2y(rect, value);
    this.y33(rect);
  }
  t2y(idx, value) {
    return this.s2y(idx, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  }
}
class BitSet {
  constructor(size) {
    this.c34_1 = size;
    this.d34_1 = new Int32Array(divCeil(this.c34_1, 32));
  }
  j1() {
    return this.c34_1;
  }
  i1(index) {
    return !(((this.d34_1[part(this, index)] >>> bit(this, index) | 0) & 1) === 0);
  }
  e34(index, value) {
    var i = part(this, index);
    var b = bit(this, index);
    if (value) {
      this.d34_1[i] = this.d34_1[i] | 1 << b;
    } else {
      this.d34_1[i] = this.d34_1[i] & ~(1 << b);
    }
  }
  o3() {
    return fill(this.d34_1, 0);
  }
  f34(element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.c34_1);
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element_0 = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.BitSet.contains.<anonymous>' call
        if (this.i1(element_0) === element) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  f3(element) {
    if (!(!(element == null) ? typeof element === 'boolean' : false))
      return false;
    return this.f34((!(element == null) ? typeof element === 'boolean' : false) ? element : THROW_CCE());
  }
  g34(elements) {
    return elements.f3(true) && !this.f34(true) ? false : elements.f3(false) && !this.f34(false) ? false : true;
  }
  g3(elements) {
    return this.g34(elements);
  }
  a1() {
    return this.c34_1 === 0;
  }
  f1() {
    // Inline function 'kotlin.collections.map' call
    var this_0 = until(0, this.c34_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.datastructure.BitSet.iterator.<anonymous>' call
      var tmp$ret$0 = this.i1(item);
      destination.f(tmp$ret$0);
    }
    return destination.f1();
  }
  hashCode() {
    return contentHashCode(this.d34_1) + this.c34_1 | 0;
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof BitSet) {
      tmp_0 = this.c34_1 === other.c34_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals(this.d34_1, other.d34_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
}
class TGenDeque$iterator$1 {
  constructor($that, this$0) {
    this.m34_1 = $that;
    this.n34_1 = this$0;
    this.l34_1 = 0;
  }
  h1() {
    var _unary__edvuaz = this.l34_1;
    this.l34_1 = _unary__edvuaz + 1 | 0;
    return this.m34_1.i1(_unary__edvuaz);
  }
  g1() {
    return this.l34_1 < this.n34_1.j1();
  }
  y6() {
    this.l34_1 = this.l34_1 - 1 | 0;
    this.n34_1.q3(this.l34_1);
  }
}
class TGenDeque {
  static o34(initialCapacity) {
    var $this = createThis(this);
    $this.h34_1 = 0;
    $this.i34_1 = 0;
    var tmp = $this;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_0 = fillArrayVal(Array(initialCapacity), null);
    tmp.j34_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
    return $this;
  }
  static p34() {
    return this.o34(16);
  }
  j1() {
    return this.i34_1;
  }
  a1() {
    return this.j1() === 0;
  }
  q34(item) {
    resizeIfRequiredFor(this, 1);
    _addLast(this, item);
  }
  r34(item) {
    resizeIfRequiredFor(this, 1);
    this.h34_1 = umod(this.h34_1 - 1 | 0, _get_capacity__a9k9f3(this));
    this.i34_1 = this.i34_1 + 1 | 0;
    this.j34_1[this.h34_1] = item;
  }
  s34(list) {
    // Inline function 'korlibs.datastructure.TGenDeque._addAll' call
    var count = list.j1();
    resizeIfRequiredFor(this, count);
    var base = this.h34_1 + this.i34_1 | 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.j34_1;
        var tmp_0 = (base + n | 0) % _get_capacity__a9k9f3(this) | 0;
        // Inline function 'korlibs.datastructure.TGenDeque.addAll.<anonymous>' call
        tmp[tmp_0] = list.i1(n);
      }
       while (inductionVariable < count);
    this.i34_1 = this.i34_1 + count | 0;
    return true;
  }
  t34(elements) {
    return this.s34(toList(elements));
  }
  k1(elements) {
    return this.t34(elements);
  }
  u34(items) {
    // Inline function 'korlibs.datastructure.TGenDeque._addAllFirst' call
    var count = items.j1();
    resizeIfRequiredFor(this, count);
    this.h34_1 = umod(this.h34_1 - count | 0, _get_capacity__a9k9f3(this));
    this.i34_1 = this.i34_1 + count | 0;
    var pos = this.h34_1;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.j34_1;
        var _unary__edvuaz = pos;
        pos = _unary__edvuaz + 1 | 0;
        var tmp_0 = umod(_unary__edvuaz, _get_capacity__a9k9f3(this));
        // Inline function 'korlibs.datastructure.TGenDeque.addAllFirst.<anonymous>' call
        tmp[tmp_0] = items.i1(n);
      }
       while (inductionVariable < count);
    return true;
  }
  nl() {
    if (this.i34_1 <= 0)
      throw IndexOutOfBoundsException.ze();
    var out = this.v34();
    nullify(this, this.h34_1);
    this.h34_1 = (this.h34_1 + 1 | 0) % _get_capacity__a9k9f3(this) | 0;
    this.i34_1 = this.i34_1 - 1 | 0;
    return out;
  }
  pl() {
    if (this.i34_1 <= 0)
      throw IndexOutOfBoundsException.ze();
    var out = this.w34();
    nullify(this, internalIndex(this, this.j1() - 1 | 0));
    this.i34_1 = this.i34_1 - 1 | 0;
    return out;
  }
  q3(index) {
    if (index < 0 || index >= this.j1())
      throw IndexOutOfBoundsException.ze();
    if (index === 0)
      return this.nl();
    if (index === (this.j1() - 1 | 0))
      return this.pl();
    var old = this.i1(index);
    if (index < (this.j1() / 2 | 0)) {
      var inductionVariable = index;
      if (1 <= inductionVariable)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          this.x34(n, this.i1(n - 1 | 0));
        }
         while (1 <= inductionVariable);
      this.h34_1 = umod(this.h34_1 + 1 | 0, _get_capacity__a9k9f3(this));
    } else {
      var inductionVariable_0 = index;
      var last = this.j1() - 1 | 0;
      if (inductionVariable_0 < last)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this.x34(n_0, this.i1(n_0 + 1 | 0));
        }
         while (inductionVariable_0 < last);
    }
    this.i34_1 = this.i34_1 - 1 | 0;
    return old;
  }
  y34(element) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.TGenDeque.add.<anonymous>' call
    this.q34(element);
    return true;
  }
  f(element) {
    return this.y34((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  o3() {
    this.i34_1 = 0;
  }
  z34(element) {
    var index = this.a35(element);
    if (index >= 0) {
      this.q3(index);
    }
    return index >= 0;
  }
  n3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.z34((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  b35(elements) {
    return _removeRetainAll(this, elements, false);
  }
  v34() {
    return this.j34_1[this.h34_1];
  }
  w34() {
    return this.j34_1[internalIndex(this, this.j1() - 1 | 0)];
  }
  x34(index, value) {
    this.j34_1[internalIndex(this, index)] = value;
  }
  i1(index) {
    return this.j34_1[internalIndex(this, index)];
  }
  c35(index) {
    return (0 <= index ? index <= (this.j1() - 1 | 0) : false) ? this.i1(index) : null;
  }
  d35(element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.j1());
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element_0 = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.TGenDeque.contains.<anonymous>' call
        if (equals(this.i1(element_0), element)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  f3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.d35((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  a35(element) {
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.i1(n), element))
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  e35(elements) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(elements, 10));
    var tmp0_iterator = elements.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.datastructure.TGenDeque.containsAll.<anonymous>' call
      var tmp$ret$0 = to(item, 0);
      destination.f(tmp$ret$0);
    }
    var emap = toLinkedMap_0(destination);
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var it = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var e = this.i1(it);
        // Inline function 'kotlin.collections.contains' call
        // Inline function 'kotlin.collections.containsKey' call
        if ((isInterface(emap, KtMap) ? emap : THROW_CCE()).i3(e)) {
          // Inline function 'kotlin.collections.set' call
          emap.r3(e, 1);
        }
      }
       while (inductionVariable < last);
    var tmp$ret$5;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_0 = emap.m3();
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$5 = true;
        break $l$block_0;
      }
      var tmp0_iterator_0 = this_0.f1();
      while (tmp0_iterator_0.g1()) {
        var element = tmp0_iterator_0.h1();
        // Inline function 'korlibs.datastructure.TGenDeque.containsAll.<anonymous>' call
        if (!(element === 1)) {
          tmp$ret$5 = false;
          break $l$block_0;
        }
      }
      tmp$ret$5 = true;
    }
    return tmp$ret$5;
  }
  g3(elements) {
    return this.e35(elements);
  }
  f1() {
    var that = this;
    return new TGenDeque$iterator$1(that, this);
  }
  hashCode() {
    // Inline function 'korlibs.datastructure.internal.contentHashCode' call
    var size = this.j1();
    var result = 1;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = imul(31, result);
        // Inline function 'kotlin.hashCode' call
        // Inline function 'korlibs.datastructure.TGenDeque.hashCode.<anonymous>' call
        var tmp0_safe_receiver = this.i1(n);
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        result = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
      }
       while (inductionVariable < size);
    return result;
  }
  equals(other) {
    if (!(other instanceof TGenDeque))
      return false;
    if (!(other.j1() === this.j1()))
      return false;
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(this.i1(n), other.i1(n)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  }
  toString() {
    var sb = StringBuilder.h();
    sb.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.hc(this.i1(n));
        if (!(n === (this.j1() - 1 | 0))) {
          sb.ic(', ');
        }
      }
       while (inductionVariable < last);
    sb.jc(_Char___init__impl__6a9atx(93));
    return sb.toString();
  }
}
class IntDeque$iterator$1 {
  constructor($that, this$0) {
    this.j35_1 = $that;
    this.k35_1 = this$0;
    this.i35_1 = 0;
  }
  h1() {
    var _unary__edvuaz = this.i35_1;
    this.i35_1 = _unary__edvuaz + 1 | 0;
    return this.j35_1.i1(_unary__edvuaz);
  }
  g1() {
    return this.i35_1 < this.k35_1.j1();
  }
  y6() {
    this.i35_1 = this.i35_1 - 1 | 0;
    this.k35_1.q3(this.i35_1);
  }
}
class IntDeque {
  static l35(initialCapacity) {
    var $this = createThis(this);
    $this.f35_1 = 0;
    $this.g35_1 = 0;
    var tmp = $this;
    var tmp_0 = new Int32Array(initialCapacity);
    tmp.h35_1 = isIntArray(tmp_0) ? tmp_0 : THROW_CCE();
    return $this;
  }
  static m35() {
    return this.l35(16);
  }
  j1() {
    return this.g35_1;
  }
  a1() {
    return this.j1() === 0;
  }
  n35(item) {
    resizeIfRequiredFor_0(this, 1);
    _addLast_0(this, item);
  }
  o35(list) {
    // Inline function 'korlibs.datastructure.IntDeque._addAll' call
    var count = list.j1();
    resizeIfRequiredFor_0(this, count);
    var base = this.f35_1 + this.g35_1 | 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.h35_1;
        var tmp_0 = (base + n | 0) % _get_capacity__a9k9f3_0(this) | 0;
        // Inline function 'korlibs.datastructure.IntDeque.addAll.<anonymous>' call
        tmp[tmp_0] = list.i1(n);
      }
       while (inductionVariable < count);
    this.g35_1 = this.g35_1 + count | 0;
    return true;
  }
  p35(elements) {
    return this.o35(toList(elements));
  }
  k1(elements) {
    return this.p35(elements);
  }
  nl() {
    if (this.g35_1 <= 0)
      throw IndexOutOfBoundsException.ze();
    var out = this.v34();
    nullify_0(this, this.f35_1);
    this.f35_1 = (this.f35_1 + 1 | 0) % _get_capacity__a9k9f3_0(this) | 0;
    this.g35_1 = this.g35_1 - 1 | 0;
    return out;
  }
  pl() {
    if (this.g35_1 <= 0)
      throw IndexOutOfBoundsException.ze();
    var out = this.w34();
    nullify_0(this, internalIndex_0(this, this.j1() - 1 | 0));
    this.g35_1 = this.g35_1 - 1 | 0;
    return out;
  }
  q3(index) {
    if (index < 0 || index >= this.j1())
      throw IndexOutOfBoundsException.ze();
    if (index === 0)
      return this.nl();
    if (index === (this.j1() - 1 | 0))
      return this.pl();
    var old = this.i1(index);
    if (index < (this.j1() / 2 | 0)) {
      var inductionVariable = index;
      if (1 <= inductionVariable)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          this.t31(n, this.i1(n - 1 | 0));
        }
         while (1 <= inductionVariable);
      this.f35_1 = umod(this.f35_1 + 1 | 0, _get_capacity__a9k9f3_0(this));
    } else {
      var inductionVariable_0 = index;
      var last = this.j1() - 1 | 0;
      if (inductionVariable_0 < last)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this.t31(n_0, this.i1(n_0 + 1 | 0));
        }
         while (inductionVariable_0 < last);
    }
    this.g35_1 = this.g35_1 - 1 | 0;
    return old;
  }
  q35(element) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntDeque.add.<anonymous>' call
    this.n35(element);
    return true;
  }
  f(element) {
    return this.q35((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  r35(element) {
    var index = this.s35(element);
    if (index >= 0) {
      this.q3(index);
    }
    return index >= 0;
  }
  n3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.r35((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  v34() {
    return this.h35_1[this.f35_1];
  }
  w34() {
    return this.h35_1[internalIndex_0(this, this.j1() - 1 | 0)];
  }
  t31(index, value) {
    this.h35_1[internalIndex_0(this, index)] = value;
  }
  i1(index) {
    return this.h35_1[internalIndex_0(this, index)];
  }
  dp(element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.j1());
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element_0 = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.IntDeque.contains.<anonymous>' call
        if (this.i1(element_0) === element) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  f3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.dp((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  s35(element) {
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.i1(n) === element)
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  u31(elements) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(elements, 10));
    var tmp0_iterator = elements.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.datastructure.IntDeque.containsAll.<anonymous>' call
      var tmp$ret$0 = to(item, 0);
      destination.f(tmp$ret$0);
    }
    var emap = toLinkedMap_0(destination);
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var it = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var e = this.i1(it);
        // Inline function 'kotlin.collections.contains' call
        // Inline function 'kotlin.collections.containsKey' call
        if ((isInterface(emap, KtMap) ? emap : THROW_CCE()).i3(e)) {
          // Inline function 'kotlin.collections.set' call
          emap.r3(e, 1);
        }
      }
       while (inductionVariable < last);
    var tmp$ret$5;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_0 = emap.m3();
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$5 = true;
        break $l$block_0;
      }
      var tmp0_iterator_0 = this_0.f1();
      while (tmp0_iterator_0.g1()) {
        var element = tmp0_iterator_0.h1();
        // Inline function 'korlibs.datastructure.IntDeque.containsAll.<anonymous>' call
        if (!(element === 1)) {
          tmp$ret$5 = false;
          break $l$block_0;
        }
      }
      tmp$ret$5 = true;
    }
    return tmp$ret$5;
  }
  g3(elements) {
    return this.u31(elements);
  }
  f1() {
    var that = this;
    return new IntDeque$iterator$1(that, this);
  }
  hashCode() {
    // Inline function 'korlibs.datastructure.internal.contentHashCode' call
    var size = this.j1();
    var result = 1;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = imul(31, result);
        // Inline function 'kotlin.hashCode' call
        // Inline function 'korlibs.datastructure.IntDeque.hashCode.<anonymous>' call
        var tmp0_safe_receiver = this.i1(n);
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        result = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
      }
       while (inductionVariable < size);
    return result;
  }
  equals(other) {
    if (!(other instanceof IntDeque))
      return false;
    if (!(other.j1() === this.j1()))
      return false;
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(this.i1(n) === other.i1(n)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  }
  toString() {
    var sb = StringBuilder.h();
    sb.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.oh(this.i1(n));
        if (!(n === (this.j1() - 1 | 0))) {
          sb.ic(', ');
        }
      }
       while (inductionVariable < last);
    sb.jc(_Char___init__impl__6a9atx(93));
    return sb.toString();
  }
}
class FastIdentityCacheMap {
  constructor() {
    this.t35_1 = null;
    this.u35_1 = null;
    this.v35_1 = null;
    this.w35_1 = null;
    this.x35_1 = FastIdentityMap();
  }
  o3() {
    this.y35();
    clear(this.x35_1);
  }
  y35() {
    this.t35_1 = null;
    this.u35_1 = null;
    this.v35_1 = null;
    this.w35_1 = null;
  }
}
class FastSmallSet extends AbstractMutableSet {
  static d36() {
    var $this = this.k8();
    $this.z35_1 = LinkedHashSet.l1();
    $this.a36_1 = null;
    $this.b36_1 = null;
    $this.c36_1 = null;
    return $this;
  }
  j1() {
    return this.z35_1.j1();
  }
  f1() {
    return this.z35_1.f1();
  }
  e36(element) {
    if (this.xl(element))
      return false;
    this.z35_1.f(element);
    return true;
  }
  f(element) {
    return this.e36((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  f36(element) {
    this.a36_1 = null;
    this.b36_1 = null;
    this.c36_1 = null;
    this.z35_1.n3(element);
    return true;
  }
  n3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.f36((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  xl(element) {
    if (element === this.a36_1 || element === this.b36_1 || element === this.a36_1)
      return true;
    var result = this.z35_1.f3(element);
    if (result) {
      this.b36_1 = this.a36_1;
      this.c36_1 = this.b36_1;
      this.a36_1 = element;
    }
    return result;
  }
  f3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.xl((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  o3() {
    this.z35_1.o3();
    this.a36_1 = null;
    this.b36_1 = null;
    this.c36_1 = null;
  }
}
class GenericSubList {
  constructor(base, start, end) {
    this.g36_1 = base;
    this.h36_1 = start;
    this.i36_1 = end;
    var containsUpper = this.g36_1.j1();
    var containsArg = this.h36_1;
    if (!(0 <= containsArg ? containsArg <= containsUpper : false))
      throw IndexOutOfBoundsException.x1('' + this.h36_1);
    var containsUpper_0 = this.g36_1.j1();
    var containsArg_0 = this.i36_1;
    if (!(0 <= containsArg_0 ? containsArg_0 <= containsUpper_0 : false))
      throw IndexOutOfBoundsException.x1('' + this.i36_1);
  }
  j1() {
    return this.i36_1 - this.h36_1 | 0;
  }
  xl(element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.j1());
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element_0 = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.GenericSubList.contains.<anonymous>' call
        if (equals(this.i1(element_0), element)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  f3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.xl((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  yl(elements) {
    var elementsSet = toMutableSet(elements);
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.collections.minusAssign' call
        var element = this.i1(n);
        elementsSet.n3(element);
      }
       while (inductionVariable < last);
    return elementsSet.a1();
  }
  g3(elements) {
    return this.yl(elements);
  }
  i1(index) {
    return this.g36_1.i1(translateIndex(index, this));
  }
  j36(element) {
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.i1(n), element))
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  q1(element) {
    if (!(element == null ? true : !(element == null)))
      return -1;
    return this.j36((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  a1() {
    return this.j1() === 0;
  }
  f1() {
    return new GenericListIterator(this);
  }
  h3() {
    return new GenericListIterator(this);
  }
  p1(index) {
    return new GenericListIterator(this, index);
  }
  n1(fromIndex, toIndex) {
    return new GenericSubList(this, fromIndex, toIndex);
  }
  toString() {
    // Inline function 'kotlin.collections.map' call
    var this_0 = until(0, this.j1());
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.datastructure.GenericSubList.toString.<anonymous>' call
      var tmp$ret$0 = this.i1(item);
      destination.f(tmp$ret$0);
    }
    return toString(destination);
  }
  equals(other) {
    var tmp;
    if (other instanceof GenericSubList) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.datastructure.internal.equaler' call
        var count = this.j1();
        var inductionVariable = 0;
        if (inductionVariable < count)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.datastructure.GenericSubList.equals.<anonymous>' call
            if (!equals(this.i1(n), other.i1(n))) {
              tmp$ret$1 = false;
              break $l$block;
            }
          }
           while (inductionVariable < count);
        tmp$ret$1 = true;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var count = this.j1();
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.datastructure.GenericSubList.hashCode.<anonymous>' call
        // Inline function 'kotlin.hashCode' call
        var tmp0_safe_receiver = this.i1(n);
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        out = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
      }
       while (inductionVariable < count);
    return out;
  }
}
class GenericListIterator {
  constructor(list, iindex) {
    iindex = iindex === VOID ? 0 : iindex;
    this.k36_1 = list;
    this.l36_1 = iindex;
    var containsUpper = this.k36_1.j1();
    var containsArg = this.l36_1;
    if (!(0 <= containsArg ? containsArg < containsUpper : false))
      throw IndexOutOfBoundsException.x1('' + this.l36_1);
    this.m36_1 = this.l36_1;
  }
  g1() {
    return this.m36_1 < this.k36_1.j1();
  }
  h1() {
    if (!this.g1())
      throw NoSuchElementException.c7();
    var _unary__edvuaz = this.m36_1;
    this.m36_1 = _unary__edvuaz + 1 | 0;
    return this.k36_1.i1(_unary__edvuaz);
  }
  j7() {
    return this.m36_1 > 0;
  }
  k7() {
    if (!this.j7())
      throw NoSuchElementException.c7();
    this.m36_1 = this.m36_1 - 1 | 0;
    return this.k36_1.i1(this.m36_1);
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof GenericListIterator) {
      tmp_0 = equals(this.k36_1, other.k36_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.m36_1 === other.m36_1;
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return hashCode(this.k36_1) + this.m36_1 | 0;
  }
}
class HistoryStack {
  constructor(maxLength, initialCapacity) {
    maxLength = maxLength === VOID ? 2147483637 : maxLength;
    initialCapacity = initialCapacity === VOID ? 7 : initialCapacity;
    this.n36_1 = maxLength;
    this.o36_1 = TGenDeque.o34(initialCapacity);
    this.p36_1 = 0;
  }
  q36(value) {
    $l$loop: while (true) {
      var tmp;
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!this.o36_1.a1()) {
        tmp = this.o36_1.j1() > this.p36_1;
      } else {
        tmp = false;
      }
      if (!tmp) {
        break $l$loop;
      }
      this.o36_1.pl();
    }
    this.o36_1.y34(value);
    this.p36_1 = this.o36_1.j1();
    $l$loop_0: while (true) {
      var tmp_0;
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!this.o36_1.a1()) {
        tmp_0 = this.o36_1.j1() > this.n36_1;
      } else {
        tmp_0 = false;
      }
      if (!tmp_0) {
        break $l$loop_0;
      }
      this.o36_1.nl();
      this.p36_1 = this.p36_1 - 1 | 0;
    }
  }
  r36() {
    this.p36_1 = coerceAtLeast(this.p36_1 - 1 | 0, 0);
    return this.o36_1.c35(this.p36_1 - 1 | 0);
  }
  s36() {
    // Inline function 'kotlin.also' call
    var this_0 = this.o36_1.c35(this.p36_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.HistoryStack.redo.<anonymous>' call
    this.p36_1 = coerceAtMost(this.p36_1 + 1 | 0, this.o36_1.j1() + 1 | 0);
    return this_0;
  }
}
class Companion_5 {
  l2y(width, height, arraySize) {
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(arraySize >= imul(width, height))) {
      // Inline function 'korlibs.datastructure.Companion.checkArraySize.<anonymous>' call
      var message = 'backing array of size=' + arraySize + ', has less elements than ' + width + ' * ' + height;
      throw IllegalStateException.m5(toString(message));
    }
  }
}
class Companion_6 {
  constructor() {
    this.j37_1 = 2147483646;
    this.k37_1 = 2147483647;
    this.l37_1 = 0;
  }
}
class Entry {
  constructor(key, value) {
    this.p37_1 = key;
    this.q37_1 = value;
  }
  toString() {
    return 'Entry(key=' + this.p37_1 + ', value=' + toString_0(this.q37_1) + ')';
  }
  hashCode() {
    var result = this.p37_1;
    result = imul(result, 31) + (this.q37_1 == null ? 0 : hashCode(this.q37_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Entry))
      return false;
    var tmp0_other_with_cast = other instanceof Entry ? other : THROW_CCE();
    if (!(this.p37_1 === tmp0_other_with_cast.p37_1))
      return false;
    if (!equals(this.q37_1, tmp0_other_with_cast.q37_1))
      return false;
    return true;
  }
}
class Iterator {
  constructor(map) {
    this.t36_1 = map;
    this.u36_1 = this.t36_1.z36_1 ? 2147483647 : nextNonEmptyIndex(this, this.t36_1.d37_1, 0);
    this.v36_1 = new Entry(0, null);
  }
  g1() {
    return !(this.u36_1 === 2147483646);
  }
  h37() {
    // Inline function 'kotlin.apply' call
    var this_0 = currentKey(this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Iterator.nextKey.<anonymous>' call
    next(this);
    return this_0;
  }
  i37() {
    // Inline function 'kotlin.apply' call
    var this_0 = currentValue(this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Iterator.nextValue.<anonymous>' call
    next(this);
    return this_0;
  }
}
class _no_name_provided__qut3iv {
  constructor(this$0) {
    this.r37_1 = this$0;
  }
  f1() {
    // Inline function 'korlibs.datastructure.IntMap.<get-keys>.<anonymous>' call
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntMap.<get-keys>.<anonymous>.<anonymous>' call
    var it = new Iterator(this.r37_1);
    var tmp = IntMap$_get_keys_$o$iterator$lambda_bn8atq(it);
    return Iterator_0(tmp, IntMap$_get_keys_$o$iterator$lambda_bn8atq_0(it));
  }
}
class _no_name_provided__qut3iv_0 {
  constructor(this$0) {
    this.s37_1 = this$0;
  }
  f1() {
    // Inline function 'korlibs.datastructure.IntMap.<get-values>.<anonymous>' call
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntMap.<get-values>.<anonymous>.<anonymous>' call
    var it = new Iterator(this.s37_1);
    var tmp = IntMap$_get_values_$o$iterator$lambda_ggo058(it);
    return Iterator_0(tmp, IntMap$_get_values_$o$iterator$lambda_ggo058_0(it));
  }
}
class IntMap {
  static m37(nbits, loadFactor, dummy) {
    dummy = dummy === VOID ? false : dummy;
    var $this = createThis(this);
    $this.w36_1 = nbits;
    $this.x36_1 = loadFactor;
    $this.y36_1 = 1 << $this.w36_1;
    $this.z36_1 = false;
    $this.a37_1 = null;
    $this.b37_1 = $this.y36_1 - 1 | 0;
    $this.c37_1 = 1 + imul($this.w36_1, $this.w36_1) | 0;
    $this.d37_1 = new Int32Array($this.o37());
    var tmp = $this;
    // Inline function 'kotlin.arrayOfNulls' call
    var size = $this.o37();
    var tmp_0 = fillArrayVal(Array(size), null);
    tmp.e37_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
    $this.f37_1 = numberToInt($this.y36_1 * $this.x36_1);
    $this.g37_1 = 0;
    return $this;
  }
  static t37(initialCapacity, loadFactor) {
    initialCapacity = initialCapacity === VOID ? 16 : initialCapacity;
    loadFactor = loadFactor === VOID ? 0.75 : loadFactor;
    // Inline function 'kotlin.math.max' call
    var b = ilog2Ceil(initialCapacity);
    var tmp$ret$0 = Math.max(4, b);
    return this.m37(tmp$ret$0, loadFactor, true);
  }
  o37() {
    return this.y36_1 + this.c37_1 | 0;
  }
  dp(key) {
    return _getKeyIndex(this, key) >= 0;
  }
  r35(key) {
    var index = _getKeyIndex(this, key);
    if (index < 0)
      return false;
    if (index === 2147483647) {
      this.z36_1 = false;
      this.a37_1 = null;
    } else {
      this.d37_1[index] = 0;
    }
    this.g37_1 = this.g37_1 - 1 | 0;
    return true;
  }
  o3() {
    this.z36_1 = false;
    this.a37_1 = null;
    fill(this.d37_1, 0);
    fill_0(this.e37_1, null);
    this.g37_1 = 0;
  }
  i1(key) {
    var index = _getKeyIndex(this, key);
    if (index < 0)
      return null;
    if (index === 2147483647)
      return this.a37_1;
    return this.e37_1[index];
  }
  n37(key, value) {
    retry: while (true) {
      var index = _getKeyIndex(this, key);
      if (index < 0) {
        if (key === 0) {
          this.z36_1 = true;
          this.a37_1 = value;
          this.g37_1 = this.g37_1 + 1 | 0;
          return null;
        }
        if (this.g37_1 >= this.f37_1) {
          grow(this);
        }
        var index1 = hash1(this, key);
        if (this.d37_1[index1] === 0)
          return setEmptySlot(this, index1, key, value);
        var index2 = hash2(this, key);
        if (this.d37_1[index2] === 0)
          return setEmptySlot(this, index2, key, value);
        var index3 = hash3(this, key);
        if (this.d37_1[index3] === 0)
          return setEmptySlot(this, index3, key, value);
        var inductionVariable = _get_stashStart__3psegu(this);
        var last = this.d37_1.length;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            if (this.d37_1[n] === 0)
              return setEmptySlot(this, n, key, value);
          }
           while (inductionVariable < last);
        if (this.c37_1 > 512) {
          grow(this);
        } else {
          growStash(this);
        }
        continue retry;
      } else if (index === 2147483647) {
        // Inline function 'kotlin.apply' call
        var this_0 = this.a37_1;
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntMap.set.<anonymous>' call
        this.a37_1 = value;
        return this_0;
      } else {
        // Inline function 'kotlin.apply' call
        var this_1 = this.e37_1[index];
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntMap.set.<anonymous>' call
        this.e37_1[index] = value;
        return this_1;
      }
    }
  }
  l3() {
    // Inline function 'kotlin.collections.Iterable' call
    return new _no_name_provided__qut3iv(this);
  }
  m3() {
    // Inline function 'kotlin.collections.Iterable' call
    return new _no_name_provided__qut3iv_0(this);
  }
  u37(keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(keys[n] === 0))
          return n;
      }
       while (inductionVariable < last);
    return 2147483646;
  }
  equals(other) {
    if (!(other instanceof IntMap))
      return false;
    // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var index = this.z36_1 ? 2147483647 : this.u37(this.d37_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.d37_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntMap.equals.<anonymous>' call
      var key = it;
      var value = this.i1(it);
      if (!equals(other.i1(key), value))
        return false;
      index = this.u37(this.d37_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return true;
  }
  hashCode() {
    var out = 0;
    // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var index = this.z36_1 ? 2147483647 : this.u37(this.d37_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.d37_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntMap.hashCode.<anonymous>' call
      var tmp = out;
      var tmp_0 = it;
      // Inline function 'kotlin.hashCode' call
      var tmp0_safe_receiver = this.i1(it);
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
      out = tmp + (tmp_0 + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0) | 0;
      index = this.u37(this.d37_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return out;
  }
  v37() {
    // Inline function 'korlibs.datastructure.IntMap.fastValueForEach' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var index = this.z36_1 ? 2147483647 : this.u37(this.d37_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntMap.fastValueForEach.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.d37_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntMap.firstValue.<anonymous>' call
      return ensureNotNull(this.i1(it));
    }
    // Inline function 'kotlin.error' call
    var message = 'firstValue on empty IntMap';
    throw IllegalStateException.m5(toString(message));
  }
}
class Companion_7 {
  constructor() {
    this.w37_1 = 2147483646;
    this.x37_1 = 2147483647;
    this.y37_1 = 0;
  }
}
class IntIntMap {
  static k38(nbits, loadFactor, dummy) {
    var $this = createThis(this);
    $this.z37_1 = nbits;
    $this.a38_1 = loadFactor;
    $this.b38_1 = 1 << $this.z37_1;
    $this.c38_1 = false;
    $this.d38_1 = 0;
    $this.e38_1 = $this.b38_1 - 1 | 0;
    $this.f38_1 = 1 + imul($this.z37_1, $this.z37_1) | 0;
    $this.g38_1 = new Int32Array($this.o37());
    $this.h38_1 = new Int32Array($this.o37());
    $this.i38_1 = numberToInt($this.b38_1 * $this.a38_1);
    $this.j38_1 = 0;
    return $this;
  }
  static m38(initialCapacity, loadFactor) {
    initialCapacity = initialCapacity === VOID ? 16 : initialCapacity;
    loadFactor = loadFactor === VOID ? 0.75 : loadFactor;
    // Inline function 'kotlin.math.max' call
    var b = ilog2Ceil(initialCapacity);
    var tmp$ret$0 = Math.max(4, b);
    return this.k38(tmp$ret$0, loadFactor, true);
  }
  toString() {
    return toString(this.n38());
  }
  o37() {
    return this.b38_1 + this.f38_1 | 0;
  }
  o38(out) {
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var index = this.c38_1 ? 2147483647 : this.u37(this.g38_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.g38_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.toMap.<anonymous>' call
      var key = it;
      // Inline function 'kotlin.collections.set' call
      var value = this.i1(it);
      out.r3(key, value);
      index = this.u37(this.g38_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return out;
  }
  n38(out, $super) {
    out = out === VOID ? linkedHashMapOf([]) : out;
    return $super === VOID ? this.o38(out) : $super.o38.call(this, out);
  }
  dp(key) {
    return _getKeyIndex_0(this, key) >= 0;
  }
  o3() {
    this.c38_1 = false;
    this.d38_1 = 0;
    fill(this.g38_1, 0);
    fill(this.h38_1, 0);
    this.j38_1 = 0;
  }
  i1(key) {
    var index = _getKeyIndex_0(this, key);
    if (index < 0)
      return 0;
    if (index === 2147483647)
      return this.d38_1;
    return this.h38_1[index];
  }
  l38(key, value) {
    retry: while (true) {
      var index = _getKeyIndex_0(this, key);
      if (index < 0) {
        if (key === 0) {
          this.c38_1 = true;
          this.d38_1 = value;
          this.j38_1 = this.j38_1 + 1 | 0;
          return 0;
        }
        if (this.j38_1 >= this.i38_1) {
          grow_0(this);
        }
        var index1 = hash1_0(this, key);
        if (this.g38_1[index1] === 0)
          return setEmptySlot_0(this, index1, key, value);
        var index2 = hash2_0(this, key);
        if (this.g38_1[index2] === 0)
          return setEmptySlot_0(this, index2, key, value);
        var index3 = hash3_0(this, key);
        if (this.g38_1[index3] === 0)
          return setEmptySlot_0(this, index3, key, value);
        var inductionVariable = _get_stashStart__3psegu_0(this);
        var last = this.g38_1.length;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            if (this.g38_1[n] === 0)
              return setEmptySlot_0(this, n, key, value);
          }
           while (inductionVariable < last);
        if (this.f38_1 > 512) {
          grow_0(this);
        } else {
          growStash_0(this);
        }
        continue retry;
      } else if (index === 2147483647) {
        // Inline function 'kotlin.apply' call
        var this_0 = this.d38_1;
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntIntMap.set.<anonymous>' call
        this.d38_1 = value;
        return this_0;
      } else {
        // Inline function 'kotlin.apply' call
        var this_1 = this.h38_1[index];
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntIntMap.set.<anonymous>' call
        this.h38_1[index] = value;
        return this_1;
      }
    }
  }
  u37(keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(keys[n] === 0))
          return n;
      }
       while (inductionVariable < last);
    return 2147483646;
  }
  equals(other) {
    if (!(other instanceof IntIntMap))
      return false;
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var index = this.c38_1 ? 2147483647 : this.u37(this.g38_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.g38_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.equals.<anonymous>' call
      var key = it;
      var value = this.i1(it);
      if (!(other.i1(key) === value))
        return false;
      index = this.u37(this.g38_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return true;
  }
  hashCode() {
    var out = 0;
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var index = this.c38_1 ? 2147483647 : this.u37(this.g38_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.g38_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.hashCode.<anonymous>' call
      out = out + (it + this.i1(it) | 0) | 0;
      index = this.u37(this.g38_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return out;
  }
}
class IntSet {
  constructor() {
    this.p38_1 = IntMap.t37();
  }
  j1() {
    return this.p38_1.g37_1;
  }
  u31(elements) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = elements.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.IntSet.containsAll.<anonymous>' call
        if (!this.p38_1.dp(element)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
  g3(elements) {
    return this.u31(elements);
  }
  a1() {
    return this.j1() === 0;
  }
  f1() {
    return asFakeMutable(this.p38_1.l3().f1());
  }
  q38(maxCapacity) {
    return this.o3();
  }
  o3() {
    this.p38_1.o3();
  }
  q35(element) {
    return this.p38_1.n37(element, Unit_instance) == null;
  }
  f(element) {
    return this.q35((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  p35(elements) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = elements.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.IntSet.addAll.<anonymous>' call
        if (this.q35(element)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  k1(elements) {
    return this.p35(elements);
  }
  dp(element) {
    return this.p38_1.dp(element);
  }
  f3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.dp((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  r35(element) {
    return this.p38_1.r35(element);
  }
  n3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.r35((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  toString() {
    return '[' + joinToString(this.p38_1.l3(), ', ') + ']';
  }
  equals(other) {
    var tmp;
    if (other instanceof IntSet) {
      tmp = this.p38_1.equals(other.p38_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return this.p38_1.hashCode();
  }
}
class Iterator$1 {
  constructor($hasNext, $next) {
    this.r38_1 = $hasNext;
    this.s38_1 = $next;
  }
  g1() {
    return this.r38_1();
  }
  h1() {
    return this.s38_1();
  }
}
class ListReader {
  constructor(list) {
    this.t38_1 = list;
    this.u38_1 = 0;
  }
  ty() {
    return this.u38_1 >= this.t38_1.j1();
  }
  uy() {
    return this.u38_1 < this.t38_1.j1();
  }
  v38() {
    return this.t38_1.i1(this.u38_1);
  }
  z12(offset) {
    return this.t38_1.i1(this.u38_1 + offset | 0);
  }
  oz(count) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.ListReader.skip.<anonymous>' call
    this.u38_1 = this.u38_1 + count | 0;
    return this;
  }
  w38(count, $super) {
    count = count === VOID ? 1 : count;
    return $super === VOID ? this.oz(count) : $super.oz.call(this, count);
  }
  p1v() {
    // Inline function 'kotlin.apply' call
    var this_0 = this.v38();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.ListReader.read.<anonymous>' call
    this.oz(1);
    return this_0;
  }
  toString() {
    return 'ListReader(' + toString(this.t38_1) + ')';
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof ListReader) {
      tmp_0 = equals(this.t38_1, other.t38_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.u38_1 === other.u38_1;
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return hashCode(this.t38_1);
  }
}
class Companion_8 {
  x38(preallocate, gen) {
    return Pool.c39(Pool$Companion$fromPoolable$lambda, preallocate, gen);
  }
  d39(preallocate, gen, $super) {
    preallocate = preallocate === VOID ? 0 : preallocate;
    return $super === VOID ? this.x38(preallocate, gen) : $super.x38.call(this, preallocate, gen);
  }
}
class Pool {
  static c39(reset, preallocate, gen) {
    var tmp;
    if (reset === VOID) {
      tmp = Pool$_init_$lambda_q3cbhr;
    } else {
      tmp = reset;
    }
    reset = tmp;
    preallocate = preallocate === VOID ? 0 : preallocate;
    var $this = createThis(this);
    $this.y38_1 = reset;
    $this.z38_1 = gen;
    $this.a39_1 = _TGenStack___init__impl__yynjgt();
    $this.b39_1 = 0;
    var inductionVariable = 0;
    if (inductionVariable < preallocate)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var _unary__edvuaz = $this.b39_1;
        $this.b39_1 = _unary__edvuaz + 1 | 0;
        TGenStack__push_impl_9yh33t($this.a39_1, $this.z38_1(_unary__edvuaz));
      }
       while (inductionVariable < preallocate);
    return $this;
  }
  static e39(preallocate, gen) {
    preallocate = preallocate === VOID ? 0 : preallocate;
    return this.c39(Pool$_init_$lambda_q3cbhr_0, preallocate, gen);
  }
  f39() {
    return _TGenStack___get_size__impl__cvg66t(this.a39_1);
  }
  g39() {
    var tmp;
    // Inline function 'kotlin.collections.isNotEmpty' call
    if (!(new TGenStack(this.a39_1)).a1()) {
      tmp = TGenStack__pop_impl_qisxa0(this.a39_1);
    } else {
      var _unary__edvuaz = this.b39_1;
      this.b39_1 = _unary__edvuaz + 1 | 0;
      tmp = this.z38_1(_unary__edvuaz);
    }
    return tmp;
  }
  o3() {
    TGenStack__clear_impl_pali4s(this.a39_1);
    this.b39_1 = 0;
  }
  h39(element) {
    this.y38_1(element);
    TGenStack__push_impl_9yh33t(this.a39_1, element);
  }
  i39(element) {
    if (element == null)
      return Unit_instance;
    this.h39(element);
  }
  j39(elements) {
    var _iterator__ex2g4s = elements.f1();
    while (_iterator__ex2g4s.g1()) {
      var element = _iterator__ex2g4s.h1();
      this.h39(element);
    }
  }
  k39(elements) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < elements.j1()) {
      // Inline function 'korlibs.datastructure.Pool.free.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var it = elements.i1(_unary__edvuaz);
      this.h39(it);
    }
  }
  hashCode() {
    return TGenStack__hashCode_impl_l04zom(this.a39_1);
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof Pool) {
      tmp_0 = equals(this.a39_1, other.a39_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.f39() === other.f39();
    } else {
      tmp = false;
    }
    return tmp;
  }
}
class ConcurrentPool extends Pool {
  static s39(reset, preallocate, gen) {
    var tmp;
    if (reset === VOID) {
      tmp = ConcurrentPool$_init_$lambda_gqhtk8;
    } else {
      tmp = reset;
    }
    reset = tmp;
    preallocate = preallocate === VOID ? 0 : preallocate;
    var $this = this.c39(reset, preallocate, gen);
    $this.p39_1 = reset;
    $this.q39_1 = gen;
    $this.r39_1 = new NonRecursiveLock();
    return $this;
  }
  g39() {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.BaseLock.invoke' call
      var this_0 = this.r39_1;
      this_0.w1w();
      try {
        // Inline function 'korlibs.datastructure.ConcurrentPool.alloc.<anonymous>' call
        tmp$ret$1 = super.g39();
        break $l$block;
      }finally {
        this_0.z1w();
      }
    }
    return tmp$ret$1;
  }
  o3() {
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.BaseLock.invoke' call
      var this_0 = this.r39_1;
      this_0.w1w();
      try {
        super.o3();
        break $l$block;
      }finally {
        this_0.z1w();
      }
    }
  }
  h39(element) {
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.BaseLock.invoke' call
      var this_0 = this.r39_1;
      this_0.w1w();
      try {
        super.h39(element);
        break $l$block;
      }finally {
        this_0.z1w();
      }
    }
  }
}
class ReturnablePool {
  constructor(_reset, gen) {
    var tmp;
    if (_reset === VOID) {
      tmp = ReturnablePool$_init_$lambda_rsm60r;
    } else {
      tmp = _reset;
    }
    _reset = tmp;
    this.t39_1 = _reset;
    this.u39_1 = gen;
    this.v39_1 = fastArrayListOf([]);
    var tmp_0 = this;
    var tmp_1 = ReturnablePool$list$lambda(this);
    tmp_0.w39_1 = Pool.c39(tmp_1, VOID, ReturnablePool$list$lambda_0(this));
    this.x39_1 = this.w39_1.g39();
  }
  h1() {
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.v39_1;
    var element = this.x39_1;
    this_0.f(element);
    this.x39_1 = this.w39_1.g39();
    return this.x39_1;
  }
  z33() {
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.v39_1.e14_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.datastructure.ReturnablePool.reset.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it = array[_unary__edvuaz];
      this.w39_1.h39(it);
    }
    this.v39_1.o3();
  }
}
class sam$kotlin_Comparator$0 {
  constructor(function_0) {
    this.y39_1 = function_0;
  }
  rj(a, b) {
    return this.y39_1(a, b);
  }
  compare(a, b) {
    return this.rj(a, b);
  }
}
class Companion_9 {
  z39(comparator, reversed_0) {
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp = fillArrayVal(Array(16), null);
    return new TGenPriorityQueue(isArray(tmp) ? tmp : THROW_CCE(), reversed_0 ? reversed(comparator) : comparator);
  }
  a3a(reversed, comparator) {
    return Companion_instance_10.z39(new sam$kotlin_Comparator$0(comparator), reversed);
  }
  b3a(reversed, comparator, $super) {
    reversed = reversed === VOID ? false : reversed;
    return $super === VOID ? this.a3a(reversed, comparator) : $super.a3a.call(this, reversed, comparator);
  }
}
class TGenPriorityQueue$iterator$1 {
  constructor($index, this$0) {
    this.f3a_1 = $index;
    this.g3a_1 = this$0;
  }
  g1() {
    return this.f3a_1._v < this.g3a_1.e3a_1;
  }
  h1() {
    var _unary__edvuaz = this.f3a_1._v;
    this.f3a_1._v = _unary__edvuaz + 1 | 0;
    return _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(_unary__edvuaz), this.g3a_1);
  }
  j1b() {
    throw NotImplementedError.oe();
  }
  y6() {
    return this.j1b();
  }
}
class TGenPriorityQueue {
  constructor(data, comparator) {
    this.c3a_1 = data;
    this.d3a_1 = comparator;
    this.e3a_1 = 0;
  }
  j1() {
    return this.e3a_1;
  }
  h3a() {
    if (this.e3a_1 <= 0)
      throw IndexOutOfBoundsException.ze();
    return this.c3a_1[0];
  }
  y34(element) {
    this.e3a_1 = this.e3a_1 + 1 | 0;
    ensure_1(this, this.e3a_1);
    var i = _PriorityQueueNode___init__impl__34dlfu(this.e3a_1 - 1 | 0);
    _set_value__lx0xdg(i, this, element);
    while (!_get_isRoot__1006zj(i, this) && gt(this, _get_value__a43j40(_get_parent__oo9xup(i, this), this), _get_value__a43j40(i, this))) {
      swap_0(this, i, _get_parent__oo9xup(i, this));
      i = _get_parent__oo9xup(i, this);
    }
    return true;
  }
  f(element) {
    return this.y34((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  i3a() {
    if (this.e3a_1 <= 0)
      throw IndexOutOfBoundsException.ze();
    if (this.e3a_1 === 1) {
      this.e3a_1 = this.e3a_1 - 1 | 0;
      return _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(0), this);
    }
    var root = _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(0), this);
    _set_value__lx0xdg(_PriorityQueueNode___init__impl__34dlfu(0), this, _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(this.e3a_1 - 1 | 0), this));
    this.e3a_1 = this.e3a_1 - 1 | 0;
    minHeapify(this, 0);
    return root;
  }
  a35(element) {
    var inductionVariable = 0;
    var last = this.e3a_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.c3a_1[n], element))
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  z34(element) {
    var index = this.a35(element);
    if (index >= 0) {
      this.j3a(index);
    }
    return index >= 0;
  }
  n3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.z34((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  j3a(index) {
    var i = _PriorityQueueNode___init__impl__34dlfu(index);
    while (!(_PriorityQueueNode___get_index__impl__b6r7i5(i) === 0)) {
      swap_0(this, i, _get_parent__oo9xup(i, this));
      i = _get_parent__oo9xup(i, this);
    }
    this.i3a();
  }
  d35(element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.e3a_1);
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element_0 = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.TGenPriorityQueue.contains.<anonymous>' call
        if (equals(_get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(element_0), this), element)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  f3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.d35((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  e35(elements) {
    var thisSet = toSet(this);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = elements.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.datastructure.TGenPriorityQueue.containsAll.<anonymous>' call
        if (!thisSet.f3(element)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
  g3(elements) {
    return this.e35(elements);
  }
  a1() {
    return this.e3a_1 === 0;
  }
  t34(elements) {
    var _iterator__ex2g4s = elements.f1();
    while (_iterator__ex2g4s.g1()) {
      var e = _iterator__ex2g4s.h1();
      this.y34(e);
    }
    // Inline function 'kotlin.collections.isNotEmpty' call
    return !elements.a1();
  }
  k1(elements) {
    return this.t34(elements);
  }
  f1() {
    var index = {_v: 0};
    return new TGenPriorityQueue$iterator$1(index, this);
  }
  toString() {
    return toString(toList(this));
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof TGenPriorityQueue) {
      tmp_0 = contentEquals_2(this.c3a_1, other.c3a_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = equals(this.d3a_1, other.d3a_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return contentHashCode_2(this.c3a_1);
  }
}
class TGenQueue {
  constructor() {
    this.k3a_1 = TGenDeque.p34();
  }
  j1() {
    return this.k3a_1.j1();
  }
  a1() {
    return this.j1() === 0;
  }
  l3a(v) {
    this.k3a_1.q34(v);
  }
  m3a() {
    return this.k3a_1.nl();
  }
  d35(element) {
    return this.k3a_1.d35(element);
  }
  f3(element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.d35((element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  e35(elements) {
    return this.k3a_1.e35(elements);
  }
  g3(elements) {
    return this.e35(elements);
  }
  f1() {
    return this.k3a_1.f1();
  }
  hashCode() {
    return this.k3a_1.hashCode();
  }
  equals(other) {
    var tmp;
    if (other instanceof TGenQueue) {
      tmp = this.k3a_1.equals(other.k3a_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
}
class Ref$2 {
  n3a(_set____db54di) {
    this.o3a_1 = _set____db54di;
  }
  b2() {
    var tmp = this.o3a_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('value');
    }
  }
}
class Ref$3 {
  constructor($prop) {
    this.p3a_1 = $prop;
  }
  n3a(value) {
    this.p3a_1.set(value);
  }
  b2() {
    return this.p3a_1.get();
  }
}
class ByteRingBuffer {
  constructor(bits) {
    this.j2z_1 = bits;
    this.k2z_1 = 1 << this.j2z_1;
    this.l2z_1 = this.k2z_1 - 1 | 0;
    this.m2z_1 = new Int8Array(this.k2z_1);
    this.n2z_1 = 0;
    this.o2z_1 = 0;
    this.p2z_1 = this.k2z_1;
    this.q2z_1 = 0;
  }
  q12() {
    return this.m2z_1;
  }
  r12() {
    return this.n2z_1 & this.l2z_1;
  }
  s12() {
    return this.o2z_1 & this.l2z_1;
  }
  t12(count) {
    if (count < 0 || count > this.p2z_1) {
      // Inline function 'kotlin.error' call
      var message = 'Try to write more than available';
      throw IllegalStateException.m5(toString(message));
    }
    this.o2z_1 = this.o2z_1 + count | 0;
    this.q2z_1 = this.q2z_1 + count | 0;
    this.p2z_1 = this.p2z_1 - count | 0;
  }
  u12(count) {
    if (count < 0 || count > this.q2z_1) {
      // Inline function 'kotlin.error' call
      var message = 'Try to write more than available';
      throw IllegalStateException.m5(toString(message));
    }
    this.n2z_1 = this.n2z_1 + count | 0;
    this.q2z_1 = this.q2z_1 - count | 0;
    this.p2z_1 = this.p2z_1 + count | 0;
  }
  v12() {
    // Inline function 'kotlin.math.min' call
    var a = this.q2z_1;
    var b = this.k2z_1 - (this.n2z_1 & this.l2z_1) | 0;
    return Math.min(a, b);
  }
  w12() {
    // Inline function 'kotlin.math.min' call
    var a = this.p2z_1;
    var b = this.k2z_1 - (this.o2z_1 & this.l2z_1) | 0;
    return Math.min(a, b);
  }
  x2z(consume) {
    while (consume.q2z_1 > 0) {
      // Inline function 'kotlin.math.min' call
      var a = consume.v12();
      var b = this.w12();
      var copySize = Math.min(a, b);
      Memory_instance.q3a(consume.q12(), consume.r12(), this.q12(), this.s12(), copySize);
      consume.u12(copySize);
      this.t12(copySize);
    }
  }
  j12(data, offset, size) {
    // Inline function 'kotlin.math.min' call
    var a = this.p2z_1;
    var remaining = Math.min(a, size);
    var coffset = offset;
    var totalWrite = 0;
    $l$loop: while (remaining > 0) {
      // Inline function 'kotlin.math.min' call
      var a_0 = remaining;
      var b = this.w12();
      var chunkSize = Math.min(a_0, b);
      if (chunkSize <= 0)
        break $l$loop;
      Memory_instance.q3a(data, coffset, this.m2z_1, this.s12(), chunkSize);
      this.t12(chunkSize);
      coffset = coffset + chunkSize | 0;
      remaining = remaining - chunkSize | 0;
      totalWrite = totalWrite + chunkSize | 0;
    }
    return totalWrite;
  }
  oz(size) {
    // Inline function 'kotlin.math.min' call
    var a = this.q2z_1;
    var toRead = Math.min(a, size);
    this.n2z_1 = (this.n2z_1 + toRead | 0) & this.l2z_1;
    this.p2z_1 = this.p2z_1 + toRead | 0;
    this.q2z_1 = this.q2z_1 - toRead | 0;
    return toRead;
  }
  x12(data, offset, size) {
    // Inline function 'kotlin.math.min' call
    var a = this.q2z_1;
    var toRead = Math.min(a, size);
    var readCount = 0;
    var buffer = this.m2z_1;
    var mask = this.l2z_1;
    var coffset = offset;
    var lReadPos = this.n2z_1;
    $l$loop: while (true) {
      // Inline function 'kotlin.math.min' call
      var a_0 = toRead;
      var b = this.v12();
      var toReadChunk = Math.min(a_0, b);
      if (toReadChunk <= 0)
        break $l$loop;
      Memory_instance.q3a(buffer, lReadPos & mask, data, coffset, toReadChunk);
      toRead = toRead - toReadChunk | 0;
      coffset = coffset + toReadChunk | 0;
      lReadPos = lReadPos + toReadChunk | 0;
      readCount = readCount + toReadChunk | 0;
    }
    return readCount;
  }
  o3() {
    this.n2z_1 = 0;
    this.o2z_1 = 0;
    this.q2z_1 = 0;
    this.p2z_1 = this.k2z_1;
  }
  z12(offset) {
    return this.m2z_1[(this.n2z_1 + offset | 0) & this.l2z_1];
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof ByteRingBuffer) {
      tmp_0 = this.q2z_1 === other.q2z_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.datastructure.internal.equaler' call
        var count = this.q2z_1;
        var inductionVariable = 0;
        if (inductionVariable < count)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.datastructure.ByteRingBuffer.equals.<anonymous>' call
            if (!(this.z12(n) === other.z12(n))) {
              tmp$ret$1 = false;
              break $l$block;
            }
          }
           while (inductionVariable < count);
        tmp$ret$1 = true;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return this.l12();
  }
  l12() {
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var count = this.q2z_1;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.datastructure.ByteRingBuffer.contentHashCode.<anonymous>' call
        out = tmp + this.z12(n) | 0;
      }
       while (inductionVariable < count);
    return out;
  }
}
class RingBuffer extends ByteRingBuffer {}
class ShortRingBuffer {
  constructor(bits) {
    this.z2z_1 = bits;
    this.a30_1 = 1 << this.z2z_1;
    this.b30_1 = this.a30_1 - 1 | 0;
    this.c30_1 = new Int16Array(this.a30_1);
    this.d30_1 = 0;
    this.e30_1 = 0;
    this.f30_1 = this.a30_1;
    this.g30_1 = 0;
    this.h30_1 = new Int16Array(1);
  }
  o30(data, offset, size) {
    // Inline function 'kotlin.math.min' call
    var a = this.f30_1;
    var toWrite = Math.min(a, size);
    var inductionVariable = 0;
    if (inductionVariable < toWrite)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.c30_1[this.e30_1] = data[offset + n | 0];
        this.e30_1 = (this.e30_1 + 1 | 0) & this.b30_1;
      }
       while (inductionVariable < toWrite);
    this.g30_1 = this.g30_1 + toWrite | 0;
    this.f30_1 = this.f30_1 - toWrite | 0;
    return toWrite;
  }
  n30(data, offset, size) {
    // Inline function 'kotlin.math.min' call
    var a = this.g30_1;
    var toRead = Math.min(a, size);
    var inductionVariable = 0;
    if (inductionVariable < toRead)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        data[offset + n | 0] = this.c30_1[this.d30_1];
        this.d30_1 = (this.d30_1 + 1 | 0) & this.b30_1;
      }
       while (inductionVariable < toRead);
    this.f30_1 = this.f30_1 + toRead | 0;
    this.g30_1 = this.g30_1 - toRead | 0;
    return toRead;
  }
  o3() {
    this.d30_1 = 0;
    this.e30_1 = 0;
    this.g30_1 = 0;
    this.f30_1 = this.a30_1;
  }
  z12(offset) {
    return this.c30_1[(this.d30_1 + offset | 0) & this.b30_1];
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof ShortRingBuffer) {
      tmp_0 = this.g30_1 === other.g30_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.datastructure.internal.equaler' call
        var count = this.g30_1;
        var inductionVariable = 0;
        if (inductionVariable < count)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.datastructure.ShortRingBuffer.equals.<anonymous>' call
            if (!(this.z12(n) === other.z12(n))) {
              tmp$ret$1 = false;
              break $l$block;
            }
          }
           while (inductionVariable < count);
        tmp$ret$1 = true;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return this.l12();
  }
  l12() {
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var count = this.g30_1;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.datastructure.ShortRingBuffer.contentHashCode.<anonymous>' call
        out = tmp + this.z12(n) | 0;
      }
       while (inductionVariable < count);
    return out;
  }
}
class Companion_10 {}
class TGenStack {
  constructor(items) {
    this.r3a_1 = items;
  }
  j1() {
    return _TGenStack___get_size__impl__cvg66t(this.r3a_1);
  }
  a1() {
    return TGenStack__isEmpty_impl_bsbpyi(this.r3a_1);
  }
  d35(element) {
    return TGenStack__contains_impl_l8kli6(this.r3a_1, element);
  }
  f3(element) {
    return TGenStack__contains_impl_l8kli6_0(this, element);
  }
  e35(elements) {
    return TGenStack__containsAll_impl_ic9i7r(this.r3a_1, elements);
  }
  g3(elements) {
    return TGenStack__containsAll_impl_ic9i7r_0(this, elements);
  }
  f1() {
    return TGenStack__iterator_impl_2pdia5(this.r3a_1);
  }
  toString() {
    return TGenStack__toString_impl_s9t5hn(this.r3a_1);
  }
  hashCode() {
    return TGenStack__hashCode_impl_l04zom(this.r3a_1);
  }
  equals(other) {
    return TGenStack__equals_impl_w9i35u(this.r3a_1, other);
  }
}
class IStackedArray2Base {}
function get_endX() {
  return this.t3a() + this.j2y() | 0;
}
function get_endY() {
  return this.u3a() + this.k2y() | 0;
}
function inside_0(x, y) {
  return x >= 0 && y >= 0 && x < this.j2y() && y < this.k2y();
}
class BaseDelegatedStackedArray2 {
  static b3b(other) {
    var $this = createThis(this);
    $this.a3b_1 = other;
    return $this;
  }
  y3a(x, y) {
    return this.a3b_1.y3a(x, y);
  }
  z3a(x, y) {
    this.a3b_1.z3a(x, y);
  }
  v2y(x, y) {
    return this.a3b_1.v2y(x, y);
  }
  s3a() {
    return this.a3b_1.s3a();
  }
  t3a() {
    return this.a3b_1.t3a();
  }
  u3a() {
    return this.a3b_1.u3a();
  }
  j2y() {
    return this.a3b_1.j2y();
  }
  k2y() {
    return this.a3b_1.k2y();
  }
  v3a() {
    return this.a3b_1.v3a();
  }
  w3a() {
    return this.a3b_1.w3a();
  }
  x3a() {
    return this.a3b_1.x3a();
  }
}
class IStackedLongArray2 {}
function push(x, y, value) {
  this.f3b(x, y, this.y3a(x, y), value);
}
class StackedLongArray2FromIStackedIntArray2 extends BaseDelegatedStackedArray2 {
  static e3b(data) {
    var $this = this.b3b(data);
    $this.d3b_1 = data;
    return $this;
  }
  f3b(x, y, level, value) {
    this.d3b_1.g3b(x, y, level, value.q2());
  }
  h3b(x, y, level) {
    // Inline function 'kotlin.UInt.toLong' call
    // Inline function 'kotlin.toUInt' call
    var this_0 = this.d3b_1.h3b(x, y, level);
    var this_1 = _UInt___init__impl__l7qpdl(this_0);
    // Inline function 'kotlin.uintToLong' call
    var value = _UInt___get_data__impl__f0vqqw(this_1);
    return toLong(value).n4(new Long(-1, 0));
  }
}
class Companion_11 {
  constructor() {
    this.j3b_1 = -1;
  }
  k3b(layers, width, height, empty, startX, startY) {
    var stacked = new StackedIntArray2(width, height, empty, startX, startY);
    // Inline function 'korlibs.datastructure.fill' call
    var this_0 = stacked.r3b_1;
    var n = 0;
    var inductionVariable = 0;
    var last = this_0.k2y();
    if (inductionVariable < last)
      do {
        var y = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        var last_0 = this_0.j2y();
        if (inductionVariable_0 < last_0)
          do {
            var x = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var tmp = n;
            // Inline function 'korlibs.datastructure.Companion.invoke.<anonymous>' call
            this_0.h13(n);
            var tmp$ret$0 = layers.length;
            this_0.t2y(tmp, tmp$ret$0);
            n = n + 1 | 0;
          }
           while (inductionVariable_0 < last_0);
      }
       while (inductionVariable < last);
    addAll(stacked.s3b_1, layers);
    return stacked;
  }
  t3b(layers, width, height, empty, startX, startY, $super) {
    width = width === VOID ? first(layers).j2y() : width;
    height = height === VOID ? first(layers).k2y() : height;
    empty = empty === VOID ? -1 : empty;
    startX = startX === VOID ? 0 : startX;
    startY = startY === VOID ? 0 : startY;
    return $super === VOID ? this.k3b(layers, width, height, empty, startX, startY) : $super.k3b.call(this, layers, width, height, empty, startX, startY);
  }
}
class IStackedIntArray2 {}
function push_0(x, y, value) {
  this.g3b(x, y, this.y3a(x, y), value);
}
class StackedIntArray2 {
  constructor(width, height, empty, startX, startY) {
    empty = empty === VOID ? -1 : empty;
    startX = startX === VOID ? 0 : startX;
    startY = startY === VOID ? 0 : startY;
    this.l3b_1 = width;
    this.m3b_1 = height;
    this.n3b_1 = empty;
    this.o3b_1 = startX;
    this.p3b_1 = startY;
    this.q3b_1 = 0;
    var tmp = this;
    // Inline function 'korlibs.datastructure.Companion.invoke' call
    var width_0 = this.l3b_1;
    var height_0 = this.m3b_1;
    var tmp_0 = 0;
    var tmp_1 = imul(width_0, height_0);
    var tmp_2 = new Int32Array(tmp_1);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = 0;
      tmp_0 = tmp_0 + 1 | 0;
    }
    var tmp_3 = tmp_2;
    tmp.r3b_1 = new IntArray2(width_0, height_0, isIntArray(tmp_3) ? tmp_3 : THROW_CCE());
    this.s3b_1 = fastArrayListOf([]);
  }
  j2y() {
    return this.l3b_1;
  }
  k2y() {
    return this.m3b_1;
  }
  t3a() {
    return this.o3b_1;
  }
  u3a() {
    return this.p3b_1;
  }
  s3a() {
    return this.q3b_1;
  }
  v3a() {
    return this.s3b_1.j1();
  }
  u3b(level) {
    while (level >= this.s3b_1.j1()) {
      // Inline function 'korlibs.datastructure.Companion.invoke' call
      var width = this.l3b_1;
      var height = this.m3b_1;
      var fill = this.n3b_1;
      var tmp = 0;
      var tmp_0 = imul(width, height);
      var tmp_1 = new Int32Array(tmp_0);
      while (tmp < tmp_0) {
        tmp_1[tmp] = fill;
        tmp = tmp + 1 | 0;
      }
      var tmp_2 = tmp_1;
      var tmp$ret$1 = new IntArray2(width, height, isIntArray(tmp_2) ? tmp_2 : THROW_CCE());
      this.s3b_1.f(tmp$ret$1);
    }
  }
  g3b(x, y, level, value) {
    this.u3b(level);
    this.s3b_1.i1(level).p2y(x, y, value);
    // Inline function 'kotlin.comparisons.maxOf' call
    var a = this.r3b_1.z2k(x, y);
    var b = level + 1 | 0;
    var tmp$ret$0 = Math.max(a, b);
    this.r3b_1.p2y(x, y, tmp$ret$0);
    this.q3b_1 = this.q3b_1 + 1 | 0;
  }
  h3b(x, y, level) {
    if (level > this.r3b_1.z2k(x, y))
      return this.n3b_1;
    return this.s3b_1.i1(level).z2k(x, y);
  }
  y3a(x, y) {
    return this.r3b_1.z2k(x, y);
  }
  z3a(x, y) {
    this.r3b_1.p2y(x, y, coerceAtLeast(this.r3b_1.z2k(x, y) - 1 | 0, 0));
    this.q3b_1 = this.q3b_1 + 1 | 0;
  }
}
class Companion_12 {
  constructor() {
    Companion_instance_13 = this;
    this.w3b_1 = new Long(-1, -1);
  }
}
class StackedLongArray2 {
  constructor(width, height, empty, startX, startY) {
    Companion_getInstance_12();
    empty = empty === VOID ? new Long(-1, -1) : empty;
    startX = startX === VOID ? 0 : startX;
    startY = startY === VOID ? 0 : startY;
    this.x3b_1 = width;
    this.y3b_1 = height;
    this.z3b_1 = empty;
    this.a3c_1 = startX;
    this.b3c_1 = startY;
    this.c3c_1 = 0;
    var tmp = this;
    // Inline function 'korlibs.datastructure.Companion.invoke' call
    var width_0 = this.x3b_1;
    var height_0 = this.y3b_1;
    var tmp_0 = 0;
    var tmp_1 = imul(width_0, height_0);
    var tmp_2 = new Int32Array(tmp_1);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = 0;
      tmp_0 = tmp_0 + 1 | 0;
    }
    var tmp_3 = tmp_2;
    tmp.d3c_1 = new IntArray2(width_0, height_0, isIntArray(tmp_3) ? tmp_3 : THROW_CCE());
    this.e3c_1 = fastArrayListOf([]);
  }
  j2y() {
    return this.x3b_1;
  }
  k2y() {
    return this.y3b_1;
  }
  t3a() {
    return this.a3c_1;
  }
  u3a() {
    return this.b3c_1;
  }
  s3a() {
    return this.c3c_1;
  }
  v3a() {
    return this.e3c_1.j1();
  }
  u3b(level) {
    while (level >= this.e3c_1.j1()) {
      // Inline function 'korlibs.datastructure.Companion.invoke' call
      var width = this.x3b_1;
      var height = this.y3b_1;
      var fill = this.z3b_1;
      var tmp = 0;
      var tmp_0 = imul(width, height);
      var tmp_1 = longArray(tmp_0);
      while (tmp < tmp_0) {
        tmp_1[tmp] = fill;
        tmp = tmp + 1 | 0;
      }
      var tmp_2 = tmp_1;
      var tmp$ret$1 = new LongArray2(width, height, isLongArray(tmp_2) ? tmp_2 : THROW_CCE());
      this.e3c_1.f(tmp$ret$1);
    }
  }
  f3b(x, y, level, value) {
    this.u3b(level);
    this.e3c_1.i1(level).h2z(x, y, value);
    // Inline function 'kotlin.comparisons.maxOf' call
    var a = this.d3c_1.z2k(x, y);
    var b = level + 1 | 0;
    var tmp$ret$0 = Math.max(a, b);
    this.d3c_1.p2y(x, y, tmp$ret$0);
    this.c3c_1 = this.c3c_1 + 1 | 0;
  }
  h3b(x, y, level) {
    if (level > this.d3c_1.z2k(x, y))
      return this.z3b_1;
    return this.e3c_1.i1(level).z2k(x, y);
  }
  y3a(x, y) {
    return this.d3c_1.z2k(x, y);
  }
  z3a(x, y) {
    this.d3c_1.p2y(x, y, coerceAtLeast(this.d3c_1.z2k(x, y) - 1 | 0, 0));
    this.c3c_1 = this.c3c_1 + 1 | 0;
  }
}
class Comparators {
  constructor() {
    Companion_getInstance_13();
  }
  q3c(a, b) {
    if (!(_BVHRect___get_length__impl__sgc79u(a) === _BVHRect___get_length__impl__sgc79u(b))) {
      // Inline function 'kotlin.error' call
      var message = 'Not matching dimensions';
      throw IllegalStateException.m5(toString(message));
    }
    var inductionVariable = 0;
    var last = _BVHRect___get_length__impl__sgc79u(a);
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(BVHRect__min_impl_5ss93n(a, i) < BVHRect__max_impl_z6ab7l(b, i) && BVHRect__max_impl_z6ab7l(a, i) > BVHRect__min_impl_5ss93n(b, i)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  }
  r3c(a, b) {
    if (!(_BVHRect___get_length__impl__sgc79u(a) === _BVHRect___get_length__impl__sgc79u(b))) {
      // Inline function 'kotlin.error' call
      var message = 'Not matching dimensions';
      throw IllegalStateException.m5(toString(message));
    }
    var inductionVariable = 0;
    var last = _BVHRect___get_length__impl__sgc79u(a);
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(BVHRect__max_impl_z6ab7l(a, i) <= BVHRect__max_impl_z6ab7l(b, i) && BVHRect__min_impl_5ss93n(a, i) >= BVHRect__min_impl_5ss93n(b, i)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  }
}
class Companion_13 extends Comparators {
  constructor() {
    Companion_instance_14 = null;
    super();
    Companion_instance_14 = this;
  }
}
class Node {
  constructor(d, id, nodes, value) {
    id = id === VOID ? null : id;
    nodes = nodes === VOID ? null : nodes;
    value = value === VOID ? null : value;
    this.k3c_1 = d;
    this.l3c_1 = id;
    this.m3c_1 = nodes;
    this.n3c_1 = value;
  }
  toString() {
    return 'Node(d=' + BVHRect__toString_impl_z8yavd(this.k3c_1) + ', id=' + this.l3c_1 + ', nodes=' + toString_0(this.m3c_1) + ', value=' + toString_0(this.n3c_1) + ')';
  }
  hashCode() {
    var result = BVHRect__hashCode_impl_dhse2i(this.k3c_1);
    result = imul(result, 31) + (this.l3c_1 == null ? 0 : getStringHashCode(this.l3c_1)) | 0;
    result = imul(result, 31) + (this.m3c_1 == null ? 0 : this.m3c_1.hashCode()) | 0;
    result = imul(result, 31) + (this.n3c_1 == null ? 0 : hashCode(this.n3c_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Node))
      return false;
    var tmp0_other_with_cast = other instanceof Node ? other : THROW_CCE();
    if (!equals(this.k3c_1, tmp0_other_with_cast.k3c_1))
      return false;
    if (!(this.l3c_1 == tmp0_other_with_cast.l3c_1))
      return false;
    if (!equals(this.m3c_1, tmp0_other_with_cast.m3c_1))
      return false;
    if (!equals(this.n3c_1, tmp0_other_with_cast.n3c_1))
      return false;
    return true;
  }
}
class RemoveSubtreeRetObject {
  constructor(d, target, nodes) {
    target = target === VOID ? null : target;
    nodes = nodes === VOID ? null : nodes;
    this.z3c_1 = d;
    this.a3d_1 = target;
    this.b3d_1 = nodes;
  }
}
class IntersectResult {
  constructor(ray, intersect, obj) {
    this.f3c_1 = ray;
    this.g3c_1 = intersect;
    this.h3c_1 = obj;
    var tmp = this;
    tmp.i3c_1 = lazy(BVH$IntersectResult$point$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.j3c_1 = lazy(BVH$IntersectResult$normal$delegate$lambda(this));
  }
  o3c() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.i3c_1;
    point$factory();
    return this_0.b2();
  }
  toString() {
    return 'IntersectResult(ray=' + BVHRay__toString_impl_otz8id(this.f3c_1) + ', intersect=' + this.g3c_1 + ', obj=' + this.h3c_1.toString() + ')';
  }
  hashCode() {
    var result = BVHRay__hashCode_impl_ofywnw(this.f3c_1);
    result = imul(result, 31) + getNumberHashCode(this.g3c_1) | 0;
    result = imul(result, 31) + this.h3c_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof IntersectResult))
      return false;
    var tmp0_other_with_cast = other instanceof IntersectResult ? other : THROW_CCE();
    if (!equals(this.f3c_1, tmp0_other_with_cast.f3c_1))
      return false;
    if (!equals(this.g3c_1, tmp0_other_with_cast.g3c_1))
      return false;
    if (!this.h3c_1.equals(tmp0_other_with_cast.h3c_1))
      return false;
    return true;
  }
}
class BVH$iterator$slambda {
  constructor(this$0) {
    this.y3c_1 = this$0;
  }
  h3d($this$iterator, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$iterator, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.h3d(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  }
}
class BVH {
  constructor(dimensions, width, allowUpdateObjects) {
    width = width === VOID ? imul(dimensions, 3) : width;
    allowUpdateObjects = allowUpdateObjects === VOID ? true : allowUpdateObjects;
    this.s3c_1 = dimensions;
    this.t3c_1 = allowUpdateObjects;
    this.u3c_1 = width;
    var tmp = this;
    // Inline function 'kotlin.math.floor' call
    var x = this.u3c_1 / this.s3c_1;
    var tmp$ret$0 = Math.floor(x);
    tmp.v3c_1 = numberToInt(tmp$ret$0);
    this.w3c_1 = new Node(_make_Empty(this), 'root', fastArrayListOf([]));
    this.x3c_1 = HashMap.c9();
  }
  i3d() {
    return _make_Intervals$default(this, this.w3c_1.k3c_1);
  }
  j3d(ray, return_array) {
    return _intersect_subtree(this, ray, return_array, this.w3c_1);
  }
  k3d(intervals, return_array, comparators) {
    var tmp48_root = this.w3c_1;
    return _search_subtree(this, intervals, comparators, return_array, tmp48_root);
  }
  l3d(intervals, return_array, comparators, $super) {
    return_array = return_array === VOID ? fastArrayListOf([]) : return_array;
    comparators = comparators === VOID ? Companion_getInstance_13() : comparators;
    return $super === VOID ? this.k3d(intervals, return_array, comparators) : $super.k3d.call(this, new BVHRect(intervals), return_array, comparators);
  }
  f1() {
    return iterator(BVH$iterator$slambda_0(this));
  }
  m3d(rect, obj) {
    checkDimensions(rect, this);
    if (this.t3c_1) {
      var tmp = this.x3c_1.k3(obj);
      var oldIntervals = tmp == null ? null : tmp.n3d_1;
      var tmp_0 = oldIntervals;
      if (!((tmp_0 == null ? null : new BVHRect(tmp_0)) == null)) {
        var tmp_1 = oldIntervals;
        if (equals(new BVHRect(rect), tmp_1 == null ? null : new BVHRect(tmp_1))) {
          return Unit_instance;
        }
        // Inline function 'kotlin.collections.contains' call
        // Inline function 'kotlin.collections.containsKey' call
        var this_0 = this.x3c_1;
        if ((isInterface(this_0, KtMap) ? this_0 : THROW_CCE()).i3(obj)) {
          this.o3d(obj);
        }
      }
    }
    _insert_subtree(this, this.w3c_1, new Node(rect, VOID, VOID, obj));
    if (this.t3c_1) {
      // Inline function 'kotlin.collections.set' call
      var this_1 = this.x3c_1;
      var value = new BVHRect(rect);
      this_1.r3(obj, value);
    }
  }
  o3d(obj) {
    if (!this.t3c_1) {
      // Inline function 'kotlin.error' call
      var message = 'allowUpdateObjects not enabled';
      throw IllegalStateException.m5(toString(message));
    }
    var tmp = this.x3c_1.k3(obj);
    var intervals = tmp == null ? null : tmp.n3d_1;
    var tmp_0 = intervals;
    if (!((tmp_0 == null ? null : new BVHRect(tmp_0)) == null)) {
      this.p3d(intervals, obj);
    }
  }
  q3d(rect, obj, comparators) {
    checkDimensions(rect, this);
    // Inline function 'kotlin.also' call
    var tmp;
    if (obj == null) {
      var ret_array = fastArrayListOf([]);
      do {
        var numberdeleted = ret_array.j1();
        var tmp52_root = this.w3c_1;
        ret_array.v14(_remove_subtree(this, rect, null, tmp52_root, comparators));
      }
       while (!(numberdeleted === ret_array.j1()));
      tmp = ret_array;
    } else {
      var tmp53_root = this.w3c_1;
      tmp = _remove_subtree(this, rect, obj, tmp53_root, comparators);
    }
    var this_0 = tmp;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.ds.BVH.remove.<anonymous>' call
    if (this.t3c_1) {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = this_0.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.datastructure.ds.BVH.remove.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        var node = array[_unary__edvuaz];
        // Inline function 'kotlin.collections.remove' call
        var this_1 = this.x3c_1;
        var key = node.n3c_1;
        var tmp_0 = (isInterface(this_1, KtMutableMap) ? this_1 : THROW_CCE()).s3(key);
        tmp_0 == null || tmp_0.n3d_1;
      }
    }
    return this_0;
  }
  p3d(rect, obj, comparators, $super) {
    obj = obj === VOID ? null : obj;
    comparators = comparators === VOID ? Companion_getInstance_13() : comparators;
    return $super === VOID ? this.q3d(rect, obj, comparators) : $super.q3d.call(this, new BVHRect(rect), obj, comparators);
  }
}
class BVHRect {
  constructor(intervals) {
    this.n3d_1 = intervals;
  }
  toString() {
    return BVHRect__toString_impl_z8yavd(this.n3d_1);
  }
  hashCode() {
    return BVHRect__hashCode_impl_dhse2i(this.n3d_1);
  }
  equals(other) {
    return BVHRect__equals_impl_cirifq(this.n3d_1, other);
  }
}
class Companion_14 {}
class BVHIntervals {
  static s3d(data) {
    var $this = createThis(this);
    $this.c3d_1 = data;
    $this.d3d_1 = contentHashCode_3($this.c3d_1);
    return $this;
  }
  static e3d(dimensions) {
    return this.s3d(new Float64Array(imul(dimensions, 2)));
  }
  hashCode() {
    return this.d3d_1;
  }
  equals(other) {
    var tmp;
    if (other instanceof BVHIntervals) {
      tmp = contentEquals_3(this.c3d_1, other.c3d_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  t3d(a0, b0, a1, b1) {
    this.c3d_1[0] = a0;
    this.c3d_1[1] = b0;
    this.c3d_1[2] = a1;
    this.c3d_1[3] = b1;
  }
  a() {
    return this.c3d_1.length / 2 | 0;
  }
  u3d(dim) {
    return this.c3d_1[imul(dim, 2) + 0 | 0];
  }
  v3d(dim) {
    return this.c3d_1[imul(dim, 2) + 1 | 0];
  }
  f3d() {
    var result = 0.0;
    var data = this.c3d_1;
    var inductionVariable = 0;
    var last = this.a();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        result = result + data[imul(n, 2) + 1 | 0];
      }
       while (inductionVariable < last);
    return result;
  }
  g3d() {
    var result = 1.0;
    var data = this.c3d_1;
    var inductionVariable = 0;
    var last = this.a();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        result = result * data[imul(n, 2) + 1 | 0];
      }
       while (inductionVariable < last);
    return result;
  }
  r3d(other) {
    // Inline function 'kotlin.collections.copyInto' call
    var this_0 = other.c3d_1;
    var destination = this.c3d_1;
    var endIndex = this_0.length;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = this_0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, destination, 0, 0, endIndex);
    return this;
  }
  toString() {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.ds.BVHIntervals.toString.<anonymous>' call
    this_0.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.a();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.ic(', ');
        }
        this_0.jc(Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(120), n));
        this_0.jc(_Char___init__impl__6a9atx(61));
        this_0.jc(_Char___init__impl__6a9atx(40));
        this_0.rh(this.u3d(n));
        this_0.jc(_Char___init__impl__6a9atx(44));
        this_0.rh(this.v3d(n));
        this_0.jc(_Char___init__impl__6a9atx(41));
      }
       while (inductionVariable < last);
    this_0.jc(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  }
}
class Companion_15 {}
class BVHVector {
  constructor(data) {
    this.p3c_1 = data;
  }
  i1(dim) {
    return this.p3c_1[dim];
  }
  equals(other) {
    var tmp;
    if (other instanceof BVHVector) {
      tmp = contentEquals_3(this.p3c_1, other.p3c_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  toString() {
    return 'BVHVector(' + joinToString_0(this.p3c_1, ', ', VOID, VOID, VOID, VOID, BVHVector$toString$lambda) + ')';
  }
  hashCode() {
    return hashCode(this.p3c_1);
  }
}
class FakeMutableIterator {
  constructor(iterator) {
    this.w3d_1 = iterator;
  }
  g1() {
    return this.w3d_1.g1();
  }
  h1() {
    return this.w3d_1.h1();
  }
  j1b() {
    throw NotImplementedError.oe();
  }
  y6() {
    return this.j1b();
  }
}
class SyncPauseable {
  constructor() {
    this.x3d_1 = new Lock();
    this.y3d_1 = false;
  }
  z3d(value) {
    if (!(this.y3d_1 === value)) {
      this.y3d_1 = value;
      $l$block: {
        // Inline function 'korlibs.concurrent.lock.BaseLock.invoke' call
        var this_0 = this.x3d_1;
        this_0.w1w();
        try {
          this.x3d_1.c1x();
          break $l$block;
        }finally {
          this_0.z1w();
        }
      }
    }
  }
}
class Computed {
  constructor(prop, default_0) {
    this.a3e_1 = prop;
    this.b3e_1 = default_0;
  }
  c3e(thisRef, p) {
    var current = thisRef;
    while (!(current == null)) {
      var result = this.a3e_1.get(current);
      if (!(result == null))
        return result;
      current = current.w15();
    }
    return this.b3e_1();
  }
}
class Observable {
  constructor(initial, before, after) {
    var tmp;
    if (before === VOID) {
      tmp = Observable$_init_$lambda_i0szc;
    } else {
      tmp = before;
    }
    before = tmp;
    var tmp_0;
    if (after === VOID) {
      tmp_0 = Observable$_init_$lambda_i0szc_0;
    } else {
      tmp_0 = after;
    }
    after = tmp_0;
    this.d3e_1 = initial;
    this.e3e_1 = before;
    this.f3e_1 = after;
    this.g3e_1 = this.d3e_1;
  }
  h3e(obj, prop) {
    return this.g3e_1;
  }
  i3e(obj, prop, value) {
    this.e3e_1(value);
    this.g3e_1 = value;
    this.f3e_1(value);
  }
}
class Memory {
  k34(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var destination = isArray(dst) ? dst : THROW_CCE();
    var endIndex = srcPos + size | 0;
    arrayCopy(src, destination, dstPos, srcPos, endIndex);
  }
  q3a(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
  r31(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
  z30(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
  o32(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
}
class PointList {}
function get_dimensions() {
  return 2;
}
function isEmpty() {
  return this.j1() === 0;
}
function getX(index) {
  return this.z2k(index, 0);
}
function getY(index) {
  return this.z2k(index, 1);
}
function get_3(index) {
  return Vector2D.k2h(this.z2k(index, 0), this.z2k(index, 1));
}
function getComponentList(component, out) {
  var inductionVariable = 0;
  var last = this.j1();
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      out[n] = this.z2k(n, component);
    }
     while (inductionVariable < last);
  return out;
}
function getComponentList$default(component, out, $super) {
  out = out === VOID ? new Float64Array(this.j1()) : out;
  return $super === VOID ? this.j3e(component, out) : getComponentList(component, out);
}
function get_first() {
  return this.i1(0);
}
function get_last() {
  return this.i1(this.j1() - 1 | 0);
}
function contains(p) {
  return this.o2t(p.i2h_1, p.j2h_1);
}
function contains_0(x, y) {
  var inductionVariable = 0;
  var last = this.j1();
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      if (this.v2k(n) === x && this.w2k(n) === y)
        return true;
    }
     while (inductionVariable < last);
  return false;
}
function clone(out) {
  out.p3e(this);
  return out;
}
function clone$default(out, $super) {
  out = out === VOID ? new PointArrayList(this.j1()) : out;
  return $super === VOID ? this.l3e(out) : clone(out);
}
class Companion_16 {}
class PointArrayList {
  constructor(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    this.m3e_1 = new Mixin();
    this.n3e_1 = false;
    this.o3e_1 = DoubleArrayList.c32(imul(capacity, 2));
  }
  n13(_set____db54di) {
    this.m3e_1.n13(_set____db54di);
  }
  o13() {
    return this.m3e_1.o13();
  }
  j1() {
    return this.o3e_1.j1() / 2 | 0;
  }
  z2k(index_0, dim) {
    return this.o3e_1.h13(index(this, index_0, dim));
  }
  v2k(index_0) {
    return this.o3e_1.h13(index(this, index_0, 0));
  }
  w2k(index_0) {
    return this.o3e_1.h13(index(this, index_0, 1));
  }
  i1(index_0) {
    var i = index(this, index_0, 0);
    return Vector2D.k2h(this.o3e_1.h13(i), this.o3e_1.h13(i + 1 | 0));
  }
  wh() {
    this.o3e_1.o3();
    return this;
  }
  r3e(x, y) {
    this.o3e_1.h32(x, y);
    return this;
  }
  s3e(p) {
    return this.r3e(p.i2h_1, p.j2h_1);
  }
  t3e(p) {
    return this.u3e(p);
  }
  u3e(p) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.PointArrayList.addAll.<anonymous>' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < p.j1()) {
      // Inline function 'korlibs.math.geom.PointArrayList.addAll.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var destruct = p.i1(_unary__edvuaz);
      var x = destruct.km();
      var y = destruct.lm();
      this.r3e(x, y);
    }
    return this;
  }
  v3e(p, index) {
    this.r3e(p.v2k(index), p.w2k(index));
  }
  p3e(other) {
    this.wh();
    this.t3e(other);
    return this;
  }
  l3e(out) {
    return out.wh().t3e(this);
  }
  y31(index_0, count) {
    this.o3e_1.y31(index(this, index_0, 0), imul(count, 2));
    return this;
  }
  w3e(index, count, $super) {
    count = count === VOID ? 1 : count;
    return $super === VOID ? this.y31(index, count) : $super.y31.call(this, index, count);
  }
  equals(other) {
    var tmp;
    if (other instanceof PointArrayList) {
      tmp = this.o3e_1.equals(other.o3e_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return this.o3e_1.hashCode();
  }
  toString() {
    var sb = StringBuilder.h();
    sb.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var _destruct__k2r9zo = this.i1(n);
        var x = _destruct__k2r9zo.km();
        var y = _destruct__k2r9zo.lm();
        if (!(n === 0)) {
          sb.ic(', ');
        }
        sb.jc(_Char___init__impl__6a9atx(40));
        if (x === round(x))
          sb.oh(numberToInt(x));
        else
          sb.rh(x);
        sb.ic(', ');
        if (y === round(y))
          sb.oh(numberToInt(y));
        else
          sb.rh(y);
        sb.jc(_Char___init__impl__6a9atx(41));
      }
       while (inductionVariable < last);
    sb.jc(_Char___init__impl__6a9atx(93));
    return sb.toString();
  }
  f3(element) {
    if (!(element instanceof Vector2D))
      return false;
    return this.u2k(element instanceof Vector2D ? element : THROW_CCE());
  }
  uz(other, epsilon) {
    return this.a2l((!(other == null) ? isInterface(other, IDoubleVectorList) : false) ? other : THROW_CCE(), epsilon);
  }
  g3(elements) {
    return this.s2k(elements);
  }
  q1(element) {
    if (!(element instanceof Vector2D))
      return -1;
    return this.q2k(element instanceof Vector2D ? element : THROW_CCE());
  }
}
class DoubleVectorArrayList {
  constructor(dimensions, capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    this.x3e_1 = new Mixin();
    this.y3e_1 = dimensions;
    this.z3e_1 = DoubleArrayList.c32(imul(capacity, this.y3e_1));
    this.a3f_1 = false;
  }
  n13(_set____db54di) {
    this.x3e_1.n13(_set____db54di);
  }
  o13() {
    return this.x3e_1.o13();
  }
  b2l() {
    return this.y3e_1;
  }
  j1() {
    return this.z3e_1.j1() / this.y3e_1 | 0;
  }
  z2k(index, dim) {
    return this.z3e_1.i1(imul(index, this.y3e_1) + dim | 0);
  }
  h32(v0, v1) {
    // Inline function 'kotlin.also' call
    checkDimensions_1(this, 2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.add.<anonymous>' call
    this.z3e_1.h32(v0, v1);
    return Unit_instance;
  }
  i32(v0, v1, v2) {
    // Inline function 'kotlin.also' call
    checkDimensions_1(this, 3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.add.<anonymous>' call
    this.z3e_1.i32(v0, v1, v2);
    return Unit_instance;
  }
  j32(v0, v1, v2, v3) {
    // Inline function 'kotlin.also' call
    checkDimensions_1(this, 4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.add.<anonymous>' call
    this.z3e_1.j32(v0, v1, v2, v3);
    return Unit_instance;
  }
  k32(v0, v1, v2, v3, v4, v5) {
    // Inline function 'kotlin.also' call
    checkDimensions_1(this, 6);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.add.<anonymous>' call
    this.z3e_1.k32(v0, v1, v2, v3, v4, v5);
    return Unit_instance;
  }
  b3f(v0, v1, v2) {
    return this.i32(v0, v1, v2);
  }
  c3f(index, out, roundDecimalPlaces) {
    appendGenericArray(out, this.y3e_1, DoubleVectorArrayList$vectorToStringBuilder$lambda(this, index, roundDecimalPlaces));
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof DoubleVectorArrayList) {
      tmp_0 = this.y3e_1 === other.y3e_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.z3e_1.equals(other.z3e_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return this.z3e_1.hashCode();
  }
  toString() {
    return this.d3f(null);
  }
  d3f(roundDecimalPlaces) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.toString.<anonymous>' call
    this_0.ic('VectorArrayList[' + this.j1() + '](\n');
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.ic(', \n');
        }
        this_0.ic('   ');
        this.c3f(n, this_0, roundDecimalPlaces);
      }
       while (inductionVariable < last);
    this_0.ic('\n)');
    return this_0.toString();
  }
  o3() {
    this.z3e_1.o3();
  }
  uz(other, epsilon) {
    return this.a2l((!(other == null) ? isInterface(other, IDoubleVectorList) : false) ? other : THROW_CCE(), epsilon);
  }
}
class Companion_17 {}
class PointIntArrayList {
  constructor(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    this.e3f_1 = new Mixin();
    this.f3f_1 = false;
    this.g3f_1 = IntArrayList.f31(capacity);
    this.h3f_1 = IntArrayList.f31(capacity);
  }
  n13(_set____db54di) {
    this.e3f_1.n13(_set____db54di);
  }
  o13() {
    return this.e3f_1.o13();
  }
  j1() {
    return this.g3f_1.j1();
  }
  a1() {
    return this.j1() === 0;
  }
  o3() {
    this.g3f_1.o3();
    this.h3f_1.o3();
  }
  i3f(x, y) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.PointIntArrayList.add.<anonymous>' call
    this.g3f_1.o31(x);
    this.h3f_1.o31(y);
    return this;
  }
  j3f(p) {
    return this.i3f(p.s2u_1, p.t2u_1);
  }
  v2k(index) {
    return this.g3f_1.h13(index);
  }
  w2k(index) {
    return this.h3f_1.h13(index);
  }
  toString() {
    var sb = StringBuilder.h();
    sb.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var x = this.v2k(n);
        var y = this.w2k(n);
        if (!(n === 0)) {
          sb.ic(', ');
        }
        sb.jc(_Char___init__impl__6a9atx(40));
        sb.oh(x);
        sb.ic(', ');
        sb.oh(y);
        sb.jc(_Char___init__impl__6a9atx(41));
      }
       while (inductionVariable < last);
    sb.jc(_Char___init__impl__6a9atx(93));
    return sb.toString();
  }
}
//endregion
var Companion_instance_0;
function Companion_getInstance() {
  return Companion_instance_0;
}
var Companion_instance_1;
function Companion_getInstance_0() {
  return Companion_instance_1;
}
var Companion_instance_2;
function Companion_getInstance_1() {
  return Companion_instance_2;
}
function ensureWrite($this, count) {
  if (count <= $this.t2z_1.p2z_1)
    return Unit_instance;
  if (!$this.s2z_1) {
    var message = "Can't grow ByteArrayDeque. Need to write " + count + ', but only ' + $this.t2z_1.p2z_1 + ' is available';
    println('ERROR: ' + message);
    // Inline function 'kotlin.error' call
    throw IllegalStateException.m5(toString(message));
  }
  var minNewSize = $this.t2z_1.q2z_1 + count | 0;
  var tmp = $this;
  // Inline function 'kotlin.also' call
  var this_0 = new RingBuffer(ilog2(minNewSize) + 2 | 0);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.ByteArrayDeque.ensureWrite.<anonymous>' call
  this_0.x2z($this.t2z_1);
  tmp.t2z_1 = this_0;
}
function ensureWrite_0($this, count) {
  if (count > $this.j30_1.f30_1) {
    var minNewSize = $this.j30_1.g30_1 + count | 0;
    var newBits = ilog2(minNewSize) + 2 | 0;
    var newRing = new ShortRingBuffer(newBits);
    while ($this.j30_1.g30_1 > 0) {
      var read = $this.j30_1.n30($this.k30_1, 0, $this.k30_1.length);
      newRing.o30($this.k30_1, 0, read);
    }
    $this.j30_1 = newRing;
  }
  return $this;
}
function swap(_this__u8e3s4, lIndex, rIndex) {
  var temp = _this__u8e3s4[lIndex];
  _this__u8e3s4[lIndex] = _this__u8e3s4[rIndex];
  _this__u8e3s4[rIndex] = temp;
}
function rotateRight(_this__u8e3s4, offset) {
  offset = offset === VOID ? 1 : offset;
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure._rotateRight' call
    var size = _this__u8e3s4.length;
    var offset_0 = umod(offset, size);
    if (offset_0 === 0) {
      tmp$ret$0 = Unit_instance;
      break $l$block;
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(1 <= offset_0 ? offset_0 < size : false)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
    reverse(_this__u8e3s4, 0, size);
    // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
    reverse(_this__u8e3s4, 0, offset_0);
    // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
    reverse(_this__u8e3s4, offset_0, size);
  }
  return tmp$ret$0;
}
var Companion_instance_3;
function Companion_getInstance_2() {
  return Companion_instance_3;
}
function ensure($this, count) {
  if (($this.s30_1 + count | 0) > $this.r30_1.length) {
    var tmp = $this;
    var tmp_0 = $this.r30_1;
    // Inline function 'kotlin.math.max' call
    var a = $this.s30_1 + count | 0;
    var b = imul($this.r30_1.length, 3);
    var tmp$ret$0 = Math.max(a, b);
    var tmp_1 = copyOf(tmp_0, tmp$ret$0);
    tmp.r30_1 = isFloatArray(tmp_1) ? tmp_1 : THROW_CCE();
  }
}
var Companion_instance_4;
function Companion_getInstance_3() {
  return Companion_instance_4;
}
var Companion_instance_5;
function Companion_getInstance_4() {
  return Companion_instance_5;
}
function ensure_0($this, count) {
  if (($this.b32_1 + count | 0) > $this.a32_1.length) {
    var tmp = $this;
    var tmp_0 = $this.a32_1;
    // Inline function 'kotlin.math.max' call
    var a = $this.b32_1 + count | 0;
    var b = imul($this.a32_1.length, 3);
    var tmp$ret$0 = Math.max(a, b);
    var tmp_1 = copyOf_1(tmp_0, tmp$ret$0);
    tmp.a32_1 = isDoubleArray(tmp_1) ? tmp_1 : THROW_CCE();
  }
}
function intArrayListOf(values) {
  return IntArrayList.j31(values.slice());
}
function toIntArrayList(_this__u8e3s4) {
  return IntArrayList.j31(_this__u8e3s4.slice());
}
function floatArrayListOf(values) {
  return FloatArrayList.u30(values.slice());
}
function doubleArrayListOf(values) {
  return DoubleArrayList.f32(values.slice());
}
function toInt(_this__u8e3s4) {
  return _this__u8e3s4 ? 1 : 0;
}
function sort(_this__u8e3s4, start, end, reversed) {
  start = start === VOID ? 0 : start;
  end = end === VOID ? _this__u8e3s4.j1() : end;
  reversed = reversed === VOID ? false : reversed;
  return genericSort_0(_this__u8e3s4, start, end - 1 | 0, DoubleArrayListSortOps_getInstance(), reversed);
}
function reverse_0(_this__u8e3s4, start, end) {
  start = start === VOID ? 0 : start;
  end = end === VOID ? _this__u8e3s4.j1() : end;
  return DoubleArrayListSortOps_getInstance().z32(_this__u8e3s4, start, end - 1 | 0);
}
var DoubleArrayListSortOps_instance;
function DoubleArrayListSortOps_getInstance() {
  if (DoubleArrayListSortOps_instance === VOID)
    new DoubleArrayListSortOps();
  return DoubleArrayListSortOps_instance;
}
function observe(_this__u8e3s4, updated) {
  return new ObservableIntArray2(_this__u8e3s4, updated);
}
function _get_isEmpty__oti0q6($this) {
  return $this.t33_1 > $this.u33_1;
}
function _mark($this, x, y) {
  var tmp = $this;
  // Inline function 'kotlin.comparisons.minOf' call
  var a = $this.t33_1;
  var tmp$ret$0 = Math.min(a, x);
  tmp.t33_1 = coerceIn(tmp$ret$0, 0, $this.j2y() - 1 | 0);
  var tmp_0 = $this;
  // Inline function 'kotlin.comparisons.minOf' call
  var a_0 = $this.v33_1;
  var tmp$ret$1 = Math.min(a_0, y);
  tmp_0.v33_1 = coerceIn(tmp$ret$1, 0, $this.k2y() - 1 | 0);
  var tmp_1 = $this;
  // Inline function 'kotlin.comparisons.maxOf' call
  var a_1 = $this.u33_1;
  var tmp$ret$2 = Math.max(a_1, x);
  tmp_1.u33_1 = coerceIn(tmp$ret$2, 0, $this.j2y() - 1 | 0);
  var tmp_2 = $this;
  // Inline function 'kotlin.comparisons.maxOf' call
  var a_2 = $this.w33_1;
  var tmp$ret$3 = Math.max(a_2, y);
  tmp_2.w33_1 = coerceIn(tmp$ret$3, 0, $this.k2y() - 1 | 0);
}
function part($this, index) {
  return index >>> 5 | 0;
}
function bit($this, index) {
  return index & 31;
}
function _get__data__kyoi3c($this) {
  // Inline function 'korlibs.datastructure.fastCastTo' call
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  return $this.j34_1;
}
function _get_capacity__a9k9f3($this) {
  return $this.j34_1.length;
}
function resizeIfRequiredFor($this, count) {
  if (($this.j1() + count | 0) > _get_capacity__a9k9f3($this)) {
    var i = $this.j34_1;
    var istart = $this.h34_1;
    // Inline function 'kotlin.arrayOfNulls' call
    // Inline function 'kotlin.comparisons.maxOf' call
    var a = $this.j34_1.length + 7 | 0;
    // Inline function 'kotlin.comparisons.maxOf' call
    var a_0 = $this.j1() + count | 0;
    var b = imul($this.j34_1.length, 2);
    var b_0 = Math.max(a_0, b);
    var size = Math.max(a, b_0);
    var _o = fillArrayVal(Array(size), null);
    var o = isArray(_o) ? _o : THROW_CCE();
    copyCyclic($this, i, istart, o, $this.i34_1);
    $this.j34_1 = o;
    $this.h34_1 = 0;
  }
}
function copyCyclic($this, i, istart, o, count) {
  // Inline function 'kotlin.math.min' call
  var a = i.length - istart | 0;
  var size1 = Math.min(a, count);
  var size2 = count - size1 | 0;
  Memory_instance.k34(i, istart, o, 0, size1);
  if (size2 > 0) {
    Memory_instance.k34(i, 0, o, size1, size2);
  }
}
function _addLast($this, item) {
  $this.j34_1[($this.h34_1 + $this.i34_1 | 0) % _get_capacity__a9k9f3($this) | 0] = item;
  $this.i34_1 = $this.i34_1 + 1 | 0;
}
function nullify($this, index) {
  _get__data__kyoi3c($this)[index] = null;
}
function _removeRetainAll($this, elements, retain) {
  var eset = toSet(elements);
  // Inline function 'kotlin.collections.copyOf' call
  // Inline function 'kotlin.js.asDynamic' call
  var _temp = $this.j34_1.slice();
  var tsize = 0;
  var osize = $this.j1();
  var inductionVariable = 0;
  var last = $this.j1();
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var c = $this.i1(n);
      if (eset.f3(c) === retain) {
        var _unary__edvuaz = tsize;
        tsize = _unary__edvuaz + 1 | 0;
        _temp[_unary__edvuaz] = c;
      }
    }
     while (inductionVariable < last);
  $this.j34_1 = _temp;
  $this.h34_1 = 0;
  $this.i34_1 = tsize;
  return !(tsize === osize);
}
function internalIndex($this, index) {
  return umod($this.h34_1 + index | 0, _get_capacity__a9k9f3($this));
}
function _get__data__kyoi3c_0($this) {
  // Inline function 'korlibs.datastructure.fastCastTo' call
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  return $this.h35_1;
}
function _get_capacity__a9k9f3_0($this) {
  return $this.h35_1.length;
}
function resizeIfRequiredFor_0($this, count) {
  if (($this.j1() + count | 0) > _get_capacity__a9k9f3_0($this)) {
    var i = $this.h35_1;
    var istart = $this.f35_1;
    // Inline function 'kotlin.comparisons.maxOf' call
    var a = $this.h35_1.length + 7 | 0;
    // Inline function 'kotlin.comparisons.maxOf' call
    var a_0 = $this.j1() + count | 0;
    var b = imul($this.h35_1.length, 2);
    var b_0 = Math.max(a_0, b);
    var tmp$ret$1 = Math.max(a, b_0);
    var _o = new Int32Array(tmp$ret$1);
    var o = isIntArray(_o) ? _o : THROW_CCE();
    copyCyclic_0($this, i, istart, o, $this.g35_1);
    $this.h35_1 = o;
    $this.f35_1 = 0;
  }
}
function copyCyclic_0($this, i, istart, o, count) {
  // Inline function 'kotlin.math.min' call
  var a = i.length - istart | 0;
  var size1 = Math.min(a, count);
  var size2 = count - size1 | 0;
  Memory_instance.r31(i, istart, o, 0, size1);
  if (size2 > 0) {
    Memory_instance.r31(i, 0, o, size1, size2);
  }
}
function _addLast_0($this, item) {
  $this.h35_1[($this.f35_1 + $this.g35_1 | 0) % _get_capacity__a9k9f3_0($this) | 0] = item;
  $this.g35_1 = $this.g35_1 + 1 | 0;
}
function nullify_0($this, index) {
  _get__data__kyoi3c_0($this)[index] = 0;
}
function internalIndex_0($this, index) {
  return umod($this.f35_1 + index | 0, _get_capacity__a9k9f3_0($this));
}
function Deque(other) {
  // Inline function 'kotlin.also' call
  var this_0 = TGenDeque.o34(other.j1());
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.Deque.<anonymous>' call
  this_0.t34(other);
  return this_0;
}
function toDeque(_this__u8e3s4) {
  return Deque(_this__u8e3s4);
}
function getAndRemove(_this__u8e3s4, key) {
  // Inline function 'kotlin.also' call
  var this_0 = get_4(_this__u8e3s4, key);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.getAndRemove.<anonymous>' call
  remove(_this__u8e3s4, key);
  return this_0;
}
function get_keys(_this__u8e3s4) {
  return keys(_this__u8e3s4);
}
function toLinkedMap(_this__u8e3s4) {
  var out = linkedHashMapOf([]);
  // Inline function 'korlibs.datastructure.fastForEachNullable' call
  // Inline function 'korlibs.datastructure.fastKeyForEach' call
  // Inline function 'kotlin.js.asDynamic' call
  var mapIterator = _this__u8e3s4.keys();
  $l$loop: while (true) {
    var v = mapIterator.next();
    if (v.done)
      break $l$loop;
    // Inline function 'korlibs.datastructure.fastForEachNullable.<anonymous>' call
    var it = v.value;
    // Inline function 'korlibs.datastructure.toLinkedMap.<anonymous>' call
    // Inline function 'korlibs.datastructure.get' call
    // Inline function 'kotlin.js.asDynamic' call
    var value = _this__u8e3s4.get(it);
    if (!(value == null)) {
      // Inline function 'kotlin.collections.set' call
      out.r3(it, value);
    }
  }
  return out;
}
function memoize(_this__u8e3s4) {
  var func = _this__u8e3s4;
  var set = {_v: false};
  var cached = {_v: null};
  return memoize$lambda(set, cached, func);
}
function memoize$lambda($set, $cached, $func) {
  return function () {
    var tmp;
    if (!$set._v) {
      $cached._v = $func();
      $set._v = true;
      tmp = Unit_instance;
    }
    var tmp_0;
    if ($cached._v == null) {
      throwUninitializedPropertyAccessException('cached');
    } else {
      tmp_0 = $cached._v;
    }
    return tmp_0;
  };
}
function translateIndex(_this__u8e3s4, $this) {
  if (!(0 <= _this__u8e3s4 ? _this__u8e3s4 < $this.j1() : false))
    throw IndexOutOfBoundsException.x1('' + _this__u8e3s4);
  return $this.h36_1 + _this__u8e3s4 | 0;
}
var Companion_instance_6;
function Companion_getInstance_5() {
  return Companion_instance_6;
}
function IArray2$asString$lambda(this$0) {
  return function (y) {
    // Inline function 'kotlin.collections.map' call
    var this_0 = until(0, this$0.j2y());
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.datastructure.IArray2.asString.<anonymous>.<anonymous>' call
      var tmp$ret$0 = this$0.u2y(item, y);
      destination.f(tmp$ret$0);
    }
    return joinToString(destination, ', ');
  };
}
function indexOr(_this__u8e3s4, x, y, invalid) {
  invalid = invalid === VOID ? -1 : invalid;
  if (!(0 <= x ? x < _this__u8e3s4.j2y() : false) || !(0 <= y ? y < _this__u8e3s4.k2y() : false))
    return invalid;
  return imul(y, _this__u8e3s4.j2y()) + x | 0;
}
function revIndexX(_this__u8e3s4, index) {
  return index % _this__u8e3s4.j2y() | 0;
}
function revIndexY(_this__u8e3s4, index) {
  return index / _this__u8e3s4.j2y() | 0;
}
function currentKey($this) {
  switch ($this.u36_1) {
    case 2147483647:
    case 2147483646:
      return 0;
    default:
      return $this.t36_1.d37_1[$this.u36_1];
  }
}
function currentValue($this) {
  switch ($this.u36_1) {
    case 2147483647:
      return $this.t36_1.a37_1;
    case 2147483646:
      return null;
    default:
      return $this.t36_1.e37_1[$this.u36_1];
  }
}
function nextNonEmptyIndex($this, keys, offset) {
  var inductionVariable = offset;
  var last = keys.length;
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      if (!(keys[n] === 0))
        return n;
    }
     while (inductionVariable < last);
  return 2147483646;
}
function next($this) {
  if (!($this.u36_1 === 2147483646))
    $this.u36_1 = nextNonEmptyIndex($this, $this.t36_1.d37_1, $this.u36_1 === 2147483647 ? 0 : $this.u36_1 + 1 | 0);
}
function IntMap$_get_keys_$o$iterator$lambda_bn8atq($it) {
  return function () {
    return $it.g1();
  };
}
function IntMap$_get_keys_$o$iterator$lambda_bn8atq_0($it) {
  return function () {
    return $it.h37();
  };
}
function IntMap$_get_values_$o$iterator$lambda_ggo058($it) {
  return function () {
    return $it.g1();
  };
}
function IntMap$_get_values_$o$iterator$lambda_ggo058_0($it) {
  return function () {
    return $it.i37();
  };
}
var Companion_instance_7;
function Companion_getInstance_6() {
  return Companion_instance_7;
}
function _get_stashStart__3psegu($this) {
  return $this.d37_1.length - $this.c37_1 | 0;
}
function grow($this) {
  var inc = $this.w36_1 < 20 ? 3 : 1;
  var newnbits = $this.w36_1 + inc | 0;
  var new_0 = IntMap.m37(newnbits, $this.x36_1, true);
  var inductionVariable = 0;
  var last = $this.d37_1.length - 1 | 0;
  if (inductionVariable <= last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var k = $this.d37_1[n];
      if (!(k === 0)) {
        new_0.n37(k, $this.e37_1[n]);
      }
    }
     while (inductionVariable <= last);
  $this.w36_1 = new_0.w36_1;
  $this.y36_1 = new_0.y36_1;
  $this.b37_1 = new_0.b37_1;
  $this.c37_1 = new_0.c37_1;
  $this.d37_1 = new_0.d37_1;
  $this.e37_1 = new_0.e37_1;
  $this.f37_1 = new_0.f37_1;
}
function growStash($this) {
  $this.c37_1 = imul($this.c37_1, 2);
  $this.d37_1 = copyOf_0($this.d37_1, $this.o37());
  $this.e37_1 = copyOf_2($this.e37_1, $this.o37());
}
function _getKeyIndex($this, key) {
  if (key === 0)
    return $this.z36_1 ? 2147483647 : -1;
  var index1 = hash1($this, key);
  if ($this.d37_1[index1] === key)
    return index1;
  var index2 = hash2($this, key);
  if ($this.d37_1[index2] === key)
    return index2;
  var index3 = hash3($this, key);
  if ($this.d37_1[index3] === key)
    return index3;
  var inductionVariable = _get_stashStart__3psegu($this);
  var last = $this.d37_1.length;
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      if ($this.d37_1[n] === key)
        return n;
    }
     while (inductionVariable < last);
  return -1;
}
function setEmptySlot($this, index, key, value) {
  if (!($this.d37_1[index] === 0))
    throw IllegalStateException.d6();
  $this.d37_1[index] = key;
  $this.e37_1[index] = value;
  $this.g37_1 = $this.g37_1 + 1 | 0;
  return null;
}
function hash1($this, key) {
  return _hash1(key, $this.b37_1);
}
function hash2($this, key) {
  return _hash2(key, $this.b37_1);
}
function hash3($this, key) {
  return _hash3(key, $this.b37_1);
}
var Companion_instance_8;
function Companion_getInstance_7() {
  return Companion_instance_8;
}
function _get_stashStart__3psegu_0($this) {
  return $this.g38_1.length - $this.f38_1 | 0;
}
function grow_0($this) {
  var inc = $this.z37_1 < 20 ? 3 : 1;
  var newnbits = $this.z37_1 + inc | 0;
  var new_0 = IntIntMap.k38(newnbits, $this.a38_1, true);
  var inductionVariable = 0;
  var last = $this.g38_1.length - 1 | 0;
  if (inductionVariable <= last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var k = $this.g38_1[n];
      if (!(k === 0)) {
        new_0.l38(k, $this.h38_1[n]);
      }
    }
     while (inductionVariable <= last);
  $this.z37_1 = new_0.z37_1;
  $this.b38_1 = new_0.b38_1;
  $this.e38_1 = new_0.e38_1;
  $this.f38_1 = new_0.f38_1;
  $this.g38_1 = new_0.g38_1;
  $this.h38_1 = new_0.h38_1;
  $this.i38_1 = new_0.i38_1;
}
function growStash_0($this) {
  $this.f38_1 = imul($this.f38_1, 2);
  $this.g38_1 = copyOf_0($this.g38_1, $this.o37());
  $this.h38_1 = copyOf_0($this.h38_1, $this.o37());
}
function _getKeyIndex_0($this, key) {
  if (key === 0)
    return $this.c38_1 ? 2147483647 : -1;
  var index1 = hash1_0($this, key);
  if ($this.g38_1[index1] === key)
    return index1;
  var index2 = hash2_0($this, key);
  if ($this.g38_1[index2] === key)
    return index2;
  var index3 = hash3_0($this, key);
  if ($this.g38_1[index3] === key)
    return index3;
  var inductionVariable = _get_stashStart__3psegu_0($this);
  var last = $this.g38_1.length;
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      if ($this.g38_1[n] === key)
        return n;
    }
     while (inductionVariable < last);
  return -1;
}
function setEmptySlot_0($this, index, key, value) {
  if (!($this.g38_1[index] === 0))
    throw IllegalStateException.d6();
  $this.g38_1[index] = key;
  $this.h38_1[index] = value;
  $this.j38_1 = $this.j38_1 + 1 | 0;
  return 0;
}
function hash1_0($this, key) {
  return _hash1(key, $this.e38_1);
}
function hash2_0($this, key) {
  return _hash2(key, $this.e38_1);
}
function hash3_0($this, key) {
  return _hash3(key, $this.e38_1);
}
function toIntMap(_this__u8e3s4) {
  var out = IntMap.t37(numberToInt(_this__u8e3s4.j1() * 1.25));
  // Inline function 'kotlin.collections.iterator' call
  var _iterator__ex2g4s = _this__u8e3s4.z1().f1();
  while (_iterator__ex2g4s.g1()) {
    var _destruct__k2r9zo = _iterator__ex2g4s.h1();
    // Inline function 'kotlin.collections.component1' call
    var k = _destruct__k2r9zo.a2();
    // Inline function 'kotlin.collections.component2' call
    var v = _destruct__k2r9zo.b2();
    out.n37(k, v);
  }
  return out;
}
function _hash1(key, mask) {
  return _mask(key, mask);
}
function _hash2(key, mask) {
  return _mask(imul(key, 1294968109), mask);
}
function _hash3(key, mask) {
  return _mask(imul(key, 294969449), mask);
}
function _mask(value, mask) {
  return (((value + ((value >>> 8 | 0) & 255) | 0) + ((value >>> 16 | 0) & 255) | 0) + (value >> 24 & 255) | 0) & mask;
}
function associateByInt(_this__u8e3s4, block) {
  var n = 0;
  var out = IntMap.t37();
  var _iterator__ex2g4s = _this__u8e3s4.f1();
  while (_iterator__ex2g4s.g1()) {
    var it = _iterator__ex2g4s.h1();
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    out.n37(block(_unary__edvuaz, it), it);
  }
  return out;
}
function Iterator_0(hasNext, next) {
  return new Iterator$1(hasNext, next);
}
function reader(_this__u8e3s4) {
  return new ListReader(_this__u8e3s4);
}
function expect(_this__u8e3s4, value) {
  var v = _this__u8e3s4.p1v();
  if (!equals(v, value)) {
    // Inline function 'kotlin.error' call
    var message = "Expecting '" + toString_0(value) + "' but found '" + toString_0(v) + "'";
    throw IllegalStateException.m5(toString(message));
  }
  return v;
}
function Pool$Companion$fromPoolable$lambda(it) {
  it.z33();
  return Unit_instance;
}
var Companion_instance_9;
function Companion_getInstance_8() {
  return Companion_instance_9;
}
function Pool$_init_$lambda_q3cbhr(it) {
  return Unit_instance;
}
function Pool$_init_$lambda_q3cbhr_0(it) {
  return Unit_instance;
}
function ConcurrentPool$_init_$lambda_gqhtk8(it) {
  return Unit_instance;
}
function ReturnablePool$_init_$lambda_rsm60r(it) {
  return Unit_instance;
}
function ReturnablePool$list$lambda(this$0) {
  return function (it) {
    this$0.t39_1(it);
    return Unit_instance;
  };
}
function ReturnablePool$list$lambda_0(this$0) {
  return function (it) {
    return this$0.u39_1(it);
  };
}
var Companion_instance_10;
function Companion_getInstance_9() {
  return Companion_instance_10;
}
function _set_value__lx0xdg(_this__u8e3s4, $this, value) {
  $this.c3a_1[_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)] = value;
}
function _get_value__a43j40(_this__u8e3s4, $this) {
  return $this.c3a_1[_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)];
}
function _get_isRoot__1006zj(_this__u8e3s4, $this) {
  return _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4) === 0;
}
function _get_parent__oo9xup(_this__u8e3s4, $this) {
  return _PriorityQueueNode___init__impl__34dlfu((_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4) - 1 | 0) / 2 | 0);
}
function _get_left__d9qyp0(_this__u8e3s4, $this) {
  return _PriorityQueueNode___init__impl__34dlfu(imul(2, _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)) + 1 | 0);
}
function _get_right__bvz45n(_this__u8e3s4, $this) {
  return _PriorityQueueNode___init__impl__34dlfu(imul(2, _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)) + 2 | 0);
}
function gt($this, a, b) {
  return $this.d3a_1.compare(a, b) > 0;
}
function lt($this, a, b) {
  return $this.d3a_1.compare(a, b) < 0;
}
function _get_capacity__a9k9f3_1($this) {
  return $this.c3a_1.length;
}
function ensure_1($this, index) {
  if (index >= _get_capacity__a9k9f3_1($this)) {
    var tmp = $this;
    var tmp_0 = copyOf_2($this.c3a_1, 2 + imul(_get_capacity__a9k9f3_1($this), 2) | 0);
    tmp.c3a_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
  }
}
function minHeapify($this, index) {
  var i = _PriorityQueueNode___init__impl__34dlfu(index);
  $l$loop: while (true) {
    var left = _get_left__d9qyp0(i, $this);
    var right = _get_right__bvz45n(i, $this);
    var smallest = i;
    if (_PriorityQueueNode___get_index__impl__b6r7i5(left) < $this.e3a_1 && lt($this, _get_value__a43j40(left, $this), _get_value__a43j40(i, $this)))
      smallest = left;
    if (_PriorityQueueNode___get_index__impl__b6r7i5(right) < $this.e3a_1 && lt($this, _get_value__a43j40(right, $this), _get_value__a43j40(smallest, $this)))
      smallest = right;
    if (!(smallest === i)) {
      swap_0($this, i, smallest);
      i = smallest;
    } else {
      break $l$loop;
    }
  }
}
function swap_0($this, l, r) {
  var temp = _get_value__a43j40(r, $this);
  _set_value__lx0xdg(r, $this, _get_value__a43j40(l, $this));
  _set_value__lx0xdg(l, $this, temp);
}
function _PriorityQueueNode___init__impl__34dlfu(index) {
  return index;
}
function _PriorityQueueNode___get_index__impl__b6r7i5($this) {
  return $this;
}
function Ref(value) {
  // Inline function 'kotlin.also' call
  var this_0 = Ref_0();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.Ref.<anonymous>' call
  this_0.n3a(value);
  return this_0;
}
function toRef(_this__u8e3s4) {
  return Ref_1(_this__u8e3s4);
}
function Ref_0() {
  return new Ref$2();
}
function Ref_1(prop) {
  return new Ref$3(prop);
}
function _TGenStack___init__impl__yynjgt(items) {
  items = items === VOID ? FastArrayList.g14() : items;
  return items;
}
function _get_items__fzd5gv($this) {
  return $this;
}
function _TGenStack___get_size__impl__cvg66t($this) {
  return _get_items__fzd5gv($this).j1();
}
function TGenStack__isEmpty_impl_bsbpyi($this) {
  return _TGenStack___get_size__impl__cvg66t($this) === 0;
}
var Companion_instance_11;
function Companion_getInstance_10() {
  return Companion_instance_11;
}
function TGenStack__push_impl_9yh33t($this, v) {
  _get_items__fzd5gv($this).f(v);
}
function TGenStack__pop_impl_qisxa0($this) {
  return _get_items__fzd5gv($this).q3(_get_items__fzd5gv($this).j1() - 1 | 0);
}
function TGenStack__clear_impl_pali4s($this) {
  _get_items__fzd5gv($this).o3();
}
function TGenStack__contains_impl_l8kli6($this, element) {
  return _get_items__fzd5gv($this).f3(element);
}
function TGenStack__contains_impl_l8kli6_0($this, element) {
  if (!(element == null ? true : !(element == null)))
    return false;
  return TGenStack__contains_impl_l8kli6($this.r3a_1, (element == null ? true : !(element == null)) ? element : THROW_CCE());
}
function TGenStack__containsAll_impl_ic9i7r($this, elements) {
  return _get_items__fzd5gv($this).g3(elements);
}
function TGenStack__containsAll_impl_ic9i7r_0($this, elements) {
  return TGenStack__containsAll_impl_ic9i7r($this.r3a_1, elements);
}
function TGenStack__iterator_impl_2pdia5($this) {
  return _get_items__fzd5gv($this).f1();
}
function TGenStack__toString_impl_s9t5hn($this) {
  return 'TGenStack(items=' + $this.toString() + ')';
}
function TGenStack__hashCode_impl_l04zom($this) {
  return $this.hashCode();
}
function TGenStack__equals_impl_w9i35u($this, other) {
  if (!(other instanceof TGenStack))
    return false;
  var tmp0_other_with_cast = other instanceof TGenStack ? other.r3a_1 : THROW_CCE();
  if (!$this.equals(tmp0_other_with_cast))
    return false;
  return true;
}
function asLong(_this__u8e3s4) {
  return StackedLongArray2FromIStackedIntArray2.e3b(_this__u8e3s4);
}
var Companion_instance_12;
function Companion_getInstance_11() {
  return Companion_instance_12;
}
function toStacked(_this__u8e3s4) {
  return Companion_instance_12.t3b([_this__u8e3s4]);
}
var Companion_instance_13;
function Companion_getInstance_12() {
  if (Companion_instance_13 === VOID)
    new Companion_12();
  return Companion_instance_13;
}
function BVH$IntersectResult$point$delegate$lambda(this$0) {
  return function () {
    var tmp = 0;
    var tmp_0 = _BVHRay___get_dimensions__impl__ba0c7z(this$0.f3c_1);
    var tmp_1 = new Float64Array(tmp_0);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      tmp_1[tmp_2] = BVHRay__pos_impl_rqkdin(this$0.f3c_1, tmp_2) + BVHRay__dir_impl_jibowm(this$0.f3c_1, tmp_2) * this$0.g3c_1;
      tmp = tmp + 1 | 0;
    }
    return new BVHVector(tmp_1);
  };
}
function BVH$IntersectResult$normal$delegate$lambda(this$0) {
  return function () {
    var bounds = this$0.h3c_1.k3c_1;
    var tmp = 0;
    var tmp_0 = _BVHRay___get_dimensions__impl__ba0c7z(this$0.f3c_1);
    var tmp_1 = new Float64Array(tmp_0);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var bmin = BVHRect__min_impl_5ss93n(bounds, tmp_2);
      var bmax = BVHRect__max_impl_z6ab7l(bounds, tmp_2);
      var p = this$0.o3c().i1(tmp_2);
      tmp_1[tmp_2] = isAlmostEquals(bmin, p) || p < bmin ? -1.0 : isAlmostEquals(bmax, p) || p > bmax ? +1.0 : 0.0;
      tmp = tmp + 1 | 0;
    }
    return new BVHVector(tmp_1);
  };
}
var Companion_instance_14;
function Companion_getInstance_13() {
  if (Companion_instance_14 === VOID)
    new Companion_13();
  return Companion_instance_14;
}
function *_generator_invoke__zhh2q8($this, $this$iterator, $completion) {
  var deque = TGenDeque.p34();
  deque.q34($this.y3c_1.w3c_1);
  $l$loop: while (true) {
    // Inline function 'kotlin.collections.isNotEmpty' call
    if (!!deque.a1()) {
      break $l$loop;
    }
    var node = deque.nl();
    var tmp = $this$iterator.sm(node, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var tmp49_safe_receiver = node.m3c_1;
    if (tmp49_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.datastructure.ds.BVH.iterator.<anonymous>.<anonymous>' call
      deque.s34(tmp49_safe_receiver);
    }
  }
  return Unit_instance;
}
function _make_Empty($this) {
  return _BVHRect___init__impl__pawpsv(BVHIntervals.e3d($this.s3c_1));
}
function _make_Intervals($this, other, out) {
  return BVHRect__copyFrom_impl_kjg73e(out, other);
}
function _make_Intervals$default($this, other, out, $super) {
  out = out === VOID ? _BVHRect___init__impl__pawpsv_0($this.s3c_1) : out;
  return _make_Intervals($this, other, out);
}
function _expand_intervals($this, a, b) {
  var inductionVariable = 0;
  var last = $this.s3c_1;
  if (inductionVariable < last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var a_a = BVHRect__min_impl_5ss93n(a, i);
      var b_a = BVHRect__min_impl_5ss93n(b, i);
      var a_b = BVHRect__size_impl_axghd8(a, i);
      var b_b = BVHRect__size_impl_axghd8(b, i);
      // Inline function 'kotlin.math.min' call
      var n = Math.min(a_a, b_a);
      // Inline function 'kotlin.math.max' call
      var a_0 = a_a + a_b;
      var b_0 = b_a + b_b;
      var tmp$ret$1 = Math.max(a_0, b_0);
      BVHRect__size_impl_axghd8_0(a, i, tmp$ret$1 - n);
      BVHRect__min_impl_5ss93n_0(a, i, n);
    }
     while (inductionVariable < last);
  return a;
}
function _make_MBV($this, nodes, intervals) {
  if (nodes.a1())
    return _make_Empty($this);
  var tmp;
  var tmp_0 = intervals;
  if ((tmp_0 == null ? null : new BVHRect(tmp_0)) == null) {
    tmp = null;
  } else {
    tmp = BVHRect__copyFrom_impl_kjg73e(intervals, nodes.i1(0).k3c_1);
  }
  var tmp0_elvis_lhs = tmp;
  var tmp_1;
  var tmp_2 = tmp0_elvis_lhs;
  if ((tmp_2 == null ? null : new BVHRect(tmp_2)) == null) {
    tmp_1 = BVHRect__clone_impl_weisiw(nodes.i1(0).k3c_1);
  } else {
    tmp_1 = tmp0_elvis_lhs;
  }
  var ints = tmp_1;
  var inductionVariable = nodes.j1() - 1 | 0;
  if (1 <= inductionVariable)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + -1 | 0;
      _expand_intervals($this, ints, nodes.i1(i).k3c_1);
    }
     while (1 <= inductionVariable);
  return ints;
}
function _jons_ratio($this, intervals, count) {
  var dims = _BVHRect___get_length__impl__sgc79u(intervals);
  var sum = _BVHRect___get_intervals__impl__r54djc(intervals).f3d();
  var mul = _BVHRect___get_intervals__impl__r54djc(intervals).g3d();
  sum = sum / dims;
  // Inline function 'kotlin.math.pow' call
  var this_0 = sum;
  var lgeo = mul / Math.pow(this_0, dims);
  return mul * count / lgeo;
}
function checkDimensions(_this__u8e3s4, $this) {
  return BVHRect__checkDimensions_impl_tlq5ao(_this__u8e3s4, $this.s3c_1);
}
function _remove_subtree($this, intervals, obj, root, comparators) {
  var hit_stack = fastArrayListOf([]);
  var count_stack = fastArrayListOf([]);
  var ret_array = fastArrayListOf([]);
  var current_depth = 1;
  var tmp;
  var tmp_0 = intervals;
  if ((tmp_0 == null ? null : new BVHRect(tmp_0)) == null) {
    tmp = true;
  } else {
    tmp = !comparators.q3c(intervals, root.k3c_1);
  }
  if (tmp)
    return ret_array;
  var ret_obj = new RemoveSubtreeRetObject(BVHRect__clone_impl_weisiw(intervals), obj);
  count_stack.f(ensureNotNull(root.m3c_1).j1());
  hit_stack.f(root);
  $l$1: do {
    $l$0: do {
      var tree = removeLast(hit_stack);
      var i = removeLast(count_stack) - 1 | 0;
      if (!(ret_obj.a3d_1 == null)) {
        $l$loop: while (i >= 0) {
          var ltree = ensureNotNull(tree.m3c_1).i1(i);
          if (comparators.q3c(ret_obj.z3c_1, ltree.k3c_1)) {
            if (!(ret_obj.a3d_1 == null) && !(ltree.n3c_1 == null) && ltree.n3c_1 === ret_obj.a3d_1 || (ret_obj.a3d_1 == null && (!(ltree.n3c_1 == null) || comparators.r3c(ltree.k3c_1, ret_obj.z3c_1)))) {
              if (!(ltree.m3c_1 == null)) {
                var tmp37_intervals = ltree.k3c_1;
                ret_array = _search_subtree$default($this, tmp37_intervals, comparators, VOID, ltree);
                var tmp38_safe_receiver = tree.m3c_1;
                if (tmp38_safe_receiver == null)
                  null;
                else
                  tmp38_safe_receiver.q3(i);
              } else {
                ret_array = fastArrayListOf([ensureNotNull(tree.m3c_1).q3(i)]);
              }
              _make_MBV($this, ensureNotNull(tree.m3c_1), tree.k3c_1);
              ret_obj.a3d_1 = null;
              if (ensureNotNull(tree.m3c_1).j1() < $this.v3c_1) {
                var tmp_1 = ret_obj;
                var tmp39_intervals = tree.k3c_1;
                var tmp40_root = tree;
                tmp_1.b3d_1 = _search_subtree$default($this, tmp39_intervals, comparators, VOID, tmp40_root);
              }
              break $l$loop;
            } else if (!(ltree.m3c_1 == null)) {
              current_depth = current_depth + 1 | 0;
              count_stack.f(i);
              hit_stack.f(tree);
              tree = ltree;
              i = ensureNotNull(ltree.m3c_1).j1();
            }
          }
          i = i - 1 | 0;
        }
      } else if (!(ret_obj.b3d_1 == null)) {
        if (ensureNotNull(tree.m3c_1).j1() >= (i + 1 | 0)) {
          ensureNotNull(tree.m3c_1).q3(i + 1 | 0);
        }
        // Inline function 'kotlin.collections.isNotEmpty' call
        if (!ensureNotNull(tree.m3c_1).a1()) {
          _make_MBV($this, ensureNotNull(tree.m3c_1), tree.k3c_1);
        }
        var inductionVariable = 0;
        var last = ensureNotNull(ret_obj.b3d_1).j1();
        if (inductionVariable < last)
          do {
            var t = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            _insert_subtree($this, tree, ensureNotNull(ret_obj.b3d_1).i1(t));
          }
           while (inductionVariable < last);
        ensureNotNull(ret_obj.b3d_1).o3();
        if (hit_stack.a1() && ensureNotNull(tree.m3c_1).j1() <= 1) {
          var tmp_2 = ret_obj;
          var tmp41_intervals = tree.k3c_1;
          var tmp42_return_array = ensureNotNull(ret_obj.b3d_1);
          var tmp43_root = tree;
          tmp_2.b3d_1 = _search_subtree($this, tmp41_intervals, comparators, tmp42_return_array, tmp43_root);
          ensureNotNull(tree.m3c_1).o3();
          hit_stack.f(tree);
          count_stack.f(1);
        } else {
          var tmp_3;
          // Inline function 'kotlin.collections.isNotEmpty' call
          if (!hit_stack.a1()) {
            tmp_3 = ensureNotNull(tree.m3c_1).j1() < $this.v3c_1;
          } else {
            tmp_3 = false;
          }
          if (tmp_3) {
            var tmp_4 = ret_obj;
            var tmp44_intervals = tree.k3c_1;
            var tmp45_return_array = ensureNotNull(ret_obj.b3d_1);
            var tmp46_root = tree;
            tmp_4.b3d_1 = _search_subtree($this, tmp44_intervals, comparators, tmp45_return_array, tmp46_root);
            ensureNotNull(tree.m3c_1).o3();
          } else {
            ret_obj.b3d_1 = null;
          }
        }
      } else {
        new BVHRect(_make_MBV($this, ensureNotNull(tree.m3c_1), tree.k3c_1));
      }
      current_depth = current_depth - 1 | 0;
    }
     while (false);
    // Inline function 'kotlin.collections.isNotEmpty' call
    var tmp$ret$2 = !hit_stack.a1();
  }
   while (tmp$ret$2);
  return ret_array;
}
function _choose_leaf_subtree($this, intervals, root) {
  var best_choice_index = -1;
  var best_choice_stack = fastArrayListOf([]);
  var best_choice_area = 0.0;
  best_choice_stack.f(root);
  var nodes = ensureNotNull(root.m3c_1);
  do {
    if (!(best_choice_index === -1)) {
      best_choice_stack.f(nodes.i1(best_choice_index));
      nodes = ensureNotNull(nodes.i1(best_choice_index).m3c_1);
      best_choice_index = -1;
    }
    var inductionVariable = nodes.j1() - 1 | 0;
    if (0 <= inductionVariable)
      $l$loop: do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var ltree = nodes.i1(i);
        if (!(ltree.n3c_1 == null)) {
          best_choice_index = -1;
          break $l$loop;
        }
        var old_lratio = _jons_ratio($this, ltree.k3c_1, ensureNotNull(ltree.m3c_1).j1() + 1 | 0);
        var copy_of_intervals = BVHRect__clone_impl_weisiw(ltree.k3c_1);
        _expand_intervals($this, copy_of_intervals, intervals);
        var lratio = _jons_ratio($this, copy_of_intervals, ensureNotNull(ltree.m3c_1).j1() + 2 | 0);
        var tmp;
        if (best_choice_index < 0) {
          tmp = true;
        } else {
          // Inline function 'kotlin.math.abs' call
          var x = lratio - old_lratio;
          tmp = Math.abs(x) < best_choice_area;
        }
        if (tmp) {
          // Inline function 'kotlin.math.abs' call
          var x_0 = lratio - old_lratio;
          best_choice_area = Math.abs(x_0);
          best_choice_index = i;
        }
      }
       while (0 <= inductionVariable);
  }
   while (!(best_choice_index === -1));
  return best_choice_stack;
}
function _linear_split($this, nodes) {
  var n = _pick_linear($this, nodes);
  $l$loop: while (true) {
    // Inline function 'kotlin.collections.isNotEmpty' call
    if (!!nodes.a1()) {
      break $l$loop;
    }
    _pick_next($this, nodes, n.i1(0), n.i1(1));
  }
  return n;
}
function _pick_next($this, nodes, a, b) {
  var area_a = _jons_ratio($this, a.k3c_1, ensureNotNull(a.m3c_1).j1() + 1 | 0);
  var area_b = _jons_ratio($this, b.k3c_1, ensureNotNull(b.m3c_1).j1() + 1 | 0);
  var high_area_delta = null;
  var high_area_node = null;
  var lowest_growth_group = null;
  var inductionVariable = nodes.j1() - 1 | 0;
  if (0 <= inductionVariable)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + -1 | 0;
      var l = nodes.i1(i);
      var copy_of_intervals = _make_Intervals$default($this, a.k3c_1);
      _expand_intervals($this, copy_of_intervals, l.k3c_1);
      // Inline function 'kotlin.math.abs' call
      var x = _jons_ratio($this, copy_of_intervals, ensureNotNull(a.m3c_1).j1() + 2 | 0) - area_a;
      var change_new_area_a = Math.abs(x);
      copy_of_intervals = _make_Intervals$default($this, b.k3c_1);
      _expand_intervals($this, copy_of_intervals, l.k3c_1);
      // Inline function 'kotlin.math.abs' call
      var x_0 = _jons_ratio($this, copy_of_intervals, ensureNotNull(b.m3c_1).j1() + 2 | 0) - area_b;
      var change_new_area_b = Math.abs(x_0);
      var tmp;
      if (high_area_node == null || high_area_delta == null) {
        tmp = true;
      } else {
        // Inline function 'kotlin.math.abs' call
        var x_1 = change_new_area_b - change_new_area_a;
        tmp = Math.abs(x_1) < high_area_delta;
      }
      if (tmp) {
        high_area_node = i;
        // Inline function 'kotlin.math.abs' call
        var x_2 = change_new_area_b - change_new_area_a;
        high_area_delta = Math.abs(x_2);
        lowest_growth_group = change_new_area_b < change_new_area_a ? b : a;
      }
    }
     while (0 <= inductionVariable);
  var temp_node = nodes.q3(ensureNotNull(high_area_node));
  if (((ensureNotNull(a.m3c_1).j1() + nodes.j1() | 0) + 1 | 0) <= $this.v3c_1) {
    ensureNotNull(a.m3c_1).f(temp_node);
    _expand_intervals($this, a.k3c_1, temp_node.k3c_1);
  } else if (((ensureNotNull(b.m3c_1).j1() + nodes.j1() | 0) + 1 | 0) <= $this.v3c_1) {
    ensureNotNull(b.m3c_1).f(temp_node);
    _expand_intervals($this, b.k3c_1, temp_node.k3c_1);
  } else {
    var tmp_0;
    if (lowest_growth_group == null) {
      throwUninitializedPropertyAccessException('lowest_growth_group');
    } else {
      tmp_0 = lowest_growth_group;
    }
    ensureNotNull(tmp_0.m3c_1).f(temp_node);
    var tmp_1;
    if (lowest_growth_group == null) {
      throwUninitializedPropertyAccessException('lowest_growth_group');
    } else {
      tmp_1 = lowest_growth_group;
    }
    _expand_intervals($this, tmp_1.k3c_1, temp_node.k3c_1);
  }
}
function _pick_linear($this, nodes) {
  var tmp = 0;
  var tmp_0 = $this.s3c_1;
  // Inline function 'kotlin.arrayOfNulls' call
  var tmp_1 = fillArrayVal(Array(tmp_0), null);
  while (tmp < tmp_0) {
    tmp_1[tmp] = nodes.j1() - 1 | 0;
    tmp = tmp + 1 | 0;
  }
  var lowest_high = tmp_1;
  var tmp_2 = 0;
  var tmp_3 = $this.s3c_1;
  // Inline function 'kotlin.arrayOfNulls' call
  var tmp_4 = fillArrayVal(Array(tmp_3), null);
  while (tmp_2 < tmp_3) {
    tmp_4[tmp_2] = 0;
    tmp_2 = tmp_2 + 1 | 0;
  }
  var highest_low = tmp_4;
  var inductionVariable = nodes.j1() - 2 | 0;
  if (0 <= inductionVariable)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + -1 | 0;
      var l = nodes.i1(i);
      var inductionVariable_0 = 0;
      var last = $this.s3c_1;
      if (inductionVariable_0 < last)
        do {
          var d = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (BVHRect__min_impl_5ss93n(l.k3c_1, d) > BVHRect__min_impl_5ss93n(nodes.i1(highest_low[d]).k3c_1, d)) {
            highest_low[d] = i;
          } else if (BVHRect__min_impl_5ss93n(l.k3c_1, d) + BVHRect__size_impl_axghd8(l.k3c_1, d) < BVHRect__min_impl_5ss93n(nodes.i1(lowest_high[d]).k3c_1, d) + BVHRect__size_impl_axghd8(nodes.i1(lowest_high[d]).k3c_1, d)) {
            lowest_high[d] = i;
          }
        }
         while (inductionVariable_0 < last);
    }
     while (0 <= inductionVariable);
  var d_0 = 0;
  var last_difference = 0.0;
  var inductionVariable_1 = 0;
  var last_0 = $this.s3c_1;
  if (inductionVariable_1 < last_0)
    do {
      var i_0 = inductionVariable_1;
      inductionVariable_1 = inductionVariable_1 + 1 | 0;
      // Inline function 'kotlin.math.abs' call
      var x = BVHRect__min_impl_5ss93n(nodes.i1(lowest_high[i_0]).k3c_1, i_0) + BVHRect__size_impl_axghd8(nodes.i1(lowest_high[i_0]).k3c_1, i_0) - BVHRect__min_impl_5ss93n(nodes.i1(highest_low[i_0]).k3c_1, i_0);
      var difference = Math.abs(x);
      if (difference > last_difference) {
        d_0 = i_0;
        last_difference = difference;
      }
    }
     while (inductionVariable_1 < last_0);
  var t1;
  var t2;
  if (lowest_high[d_0] > highest_low[d_0]) {
    t1 = nodes.q3(lowest_high[d_0]);
    t2 = nodes.q3(highest_low[d_0]);
  } else {
    t2 = nodes.q3(highest_low[d_0]);
    t1 = nodes.q3(lowest_high[d_0]);
  }
  return fastArrayListOf([new Node(_make_Intervals$default($this, t1.k3c_1), VOID, fastArrayListOf([t1])), new Node(_make_Intervals$default($this, t2.k3c_1), VOID, fastArrayListOf([t2]))]);
}
function _insert_subtree($this, root, node) {
  var bc = null;
  if (ensureNotNull(root.m3c_1).a1()) {
    _make_Intervals($this, node.k3c_1, root.k3c_1);
    ensureNotNull(root.m3c_1).f(node);
    return Unit_instance;
  }
  var tree_stack = _choose_leaf_subtree($this, node.k3c_1, root);
  var ret_obj_array = null;
  var ret_obj = node;
  $l$1: do {
    $l$0: do {
      var tmp;
      var tmp47_safe_receiver = bc;
      if (!((tmp47_safe_receiver == null ? null : tmp47_safe_receiver.m3c_1) == null)) {
        tmp = ensureNotNull(bc.m3c_1).a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        var pbc = bc;
        bc = removeLast(tree_stack);
        var inductionVariable = 0;
        var last = ensureNotNull(bc.m3c_1).j1();
        if (inductionVariable < last)
          $l$loop: do {
            var t = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            if (ensureNotNull(bc.m3c_1).i1(t) === pbc || ensureNotNull(ensureNotNull(bc.m3c_1).i1(t).m3c_1).a1()) {
              ensureNotNull(bc.m3c_1).q3(t);
              break $l$loop;
            }
          }
           while (inductionVariable < last);
      } else {
        bc = removeLast(tree_stack);
      }
      if (!(ret_obj_array == null) || !(ensureNotNull(ret_obj).n3c_1 == null) || !(ret_obj.m3c_1 == null)) {
        if (!(ret_obj_array == null)) {
          var inductionVariable_0 = 0;
          var last_0 = ret_obj_array.j1();
          if (inductionVariable_0 < last_0)
            do {
              var ai = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              _expand_intervals($this, bc.k3c_1, ret_obj_array.i1(ai).k3c_1);
            }
             while (inductionVariable_0 < last_0);
          ensureNotNull(bc.m3c_1).v14(ret_obj_array);
        } else {
          _expand_intervals($this, bc.k3c_1, ensureNotNull(ret_obj).k3c_1);
          ensureNotNull(bc.m3c_1).f(ret_obj);
        }
        if (ensureNotNull(bc.m3c_1).j1() <= $this.u3c_1) {
          ret_obj_array = null;
          ret_obj = new Node(_make_Intervals$default($this, bc.k3c_1));
        } else {
          var a = _linear_split($this, ensureNotNull(bc.m3c_1));
          ret_obj_array = a;
          ret_obj = null;
          if (tree_stack.a1()) {
            ensureNotNull(bc.m3c_1).f(a.i1(0));
            tree_stack.f(bc);
            ret_obj_array = null;
            ret_obj = a.i1(1);
          }
        }
      } else {
        _expand_intervals($this, bc.k3c_1, ret_obj.k3c_1);
        ret_obj_array = null;
        ret_obj = new Node(_make_Intervals$default($this, bc.k3c_1));
      }
    }
     while (false);
    // Inline function 'kotlin.collections.isNotEmpty' call
    var tmp$ret$0 = !tree_stack.a1();
  }
   while (tmp$ret$0);
}
function _intersect_Intervals($this, ray, intervals) {
  var ints = intervals;
  var tmp = ints;
  if ((tmp == null ? null : new BVHRect(tmp)) == null) {
    ints = $this.w3c_1.k3c_1;
  }
  var tmp_0 = 0;
  // Inline function 'kotlin.arrayOfNulls' call
  var tmp_1 = fillArrayVal(Array(2), null);
  while (tmp_0 < 2) {
    tmp_1[tmp_0] = new Float64Array($this.s3c_1);
    tmp_0 = tmp_0 + 1 | 0;
  }
  var parameters = tmp_1;
  var inv_direction = new Float64Array($this.s3c_1);
  var sign = new Int32Array($this.s3c_1);
  var inductionVariable = 0;
  var last = $this.s3c_1;
  if (inductionVariable < last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      parameters[0][i] = BVHRect__min_impl_5ss93n(ints, i);
      parameters[1][i] = BVHRect__min_impl_5ss93n(ints, i) + BVHRect__size_impl_axghd8(ints, i);
      var j = 1.0 / BVHRay__dir_impl_jibowm(ray, i);
      inv_direction[i] = j;
      sign[i] = j <= 0 ? 1 : 0;
    }
     while (inductionVariable < last);
  var omin = (parameters[sign[0]][0] - BVHRay__pos_impl_rqkdin(ray, 0)) * inv_direction[0];
  var omax = (parameters[1 - sign[0] | 0][0] - BVHRay__pos_impl_rqkdin(ray, 0)) * inv_direction[0];
  var inductionVariable_0 = 1;
  var last_0 = $this.s3c_1;
  if (inductionVariable_0 < last_0)
    do {
      var i_0 = inductionVariable_0;
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      var tmin = (parameters[sign[i_0]][i_0] - BVHRay__pos_impl_rqkdin(ray, i_0)) * inv_direction[i_0];
      var tmax = (parameters[1 - sign[i_0] | 0][i_0] - BVHRay__pos_impl_rqkdin(ray, i_0)) * inv_direction[i_0];
      if (omin > tmax || tmin > omax) {
        return null;
      }
      if (tmin > omin) {
        omin = tmin;
      }
      if (tmax < omax) {
        omax = tmax;
      }
    }
     while (inductionVariable_0 < last_0);
  if (omin >= Infinity || omax <= -Infinity) {
    return null;
  }
  if (omin < 0 && omax < 0)
    return null;
  if (omin < 0)
    omin = 0.0;
  var rs = _make_Empty($this);
  var inductionVariable_1 = 0;
  var last_1 = $this.s3c_1;
  if (inductionVariable_1 < last_1)
    do {
      var i_1 = inductionVariable_1;
      inductionVariable_1 = inductionVariable_1 + 1 | 0;
      BVHRect__min_impl_5ss93n_0(rs, i_1, BVHRay__pos_impl_rqkdin(ray, i_1) + BVHRay__dir_impl_jibowm(ray, i_1) * omin);
      BVHRect__size_impl_axghd8_0(rs, i_1, BVHRay__pos_impl_rqkdin(ray, i_1) + BVHRay__dir_impl_jibowm(ray, i_1) * omax);
    }
     while (inductionVariable_1 < last_1);
  return rs;
}
function _intersect_subtree($this, ray, return_array, root) {
  var hit_stack = fastArrayListOf([]);
  var tmp = _intersect_Intervals($this, ray, root.k3c_1);
  if ((tmp == null ? null : new BVHRect(tmp)) == null)
    return return_array;
  hit_stack.f(ensureNotNull(root.m3c_1));
  $l$1: do {
    $l$0: do {
      var nodes = removeLast(hit_stack);
      var inductionVariable = nodes.j1() - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var ltree = nodes.i1(i);
          var intersect_points = _intersect_Intervals($this, ray, ltree.k3c_1);
          var tmp_0 = intersect_points;
          if (!((tmp_0 == null ? null : new BVHRect(tmp_0)) == null)) {
            if (!(ltree.m3c_1 == null)) {
              hit_stack.f(ensureNotNull(ltree.m3c_1));
            } else if (!(ltree.n3c_1 == null)) {
              var tmp$ret$0;
              $l$block: {
                // Inline function 'kotlin.collections.maxBy' call
                var iterator = until(0, _BVHRay___get_dimensions__impl__ba0c7z(ray)).f1();
                if (!iterator.g1())
                  throw NoSuchElementException.c7();
                var maxElem = iterator.h1();
                if (!iterator.g1()) {
                  tmp$ret$0 = maxElem;
                  break $l$block;
                }
                // Inline function 'korlibs.datastructure.ds.BVH._intersect_subtree.<anonymous>' call
                var it = maxElem;
                // Inline function 'kotlin.math.absoluteValue' call
                var this_0 = BVHRay__dir_impl_jibowm(ray, it);
                var maxValue = Math.abs(this_0);
                do {
                  var e = iterator.h1();
                  // Inline function 'korlibs.datastructure.ds.BVH._intersect_subtree.<anonymous>' call
                  // Inline function 'kotlin.math.absoluteValue' call
                  var this_1 = BVHRay__dir_impl_jibowm(ray, e);
                  var v = Math.abs(this_1);
                  if (compareTo(maxValue, v) < 0) {
                    maxElem = e;
                    maxValue = v;
                  }
                }
                 while (iterator.g1());
                tmp$ret$0 = maxElem;
              }
              var dim = tmp$ret$0;
              var raySize = BVHRay__dir_impl_jibowm(ray, dim);
              var imin = BVHRect__min_impl_5ss93n(intersect_points, dim);
              var rmin = BVHRay__pos_impl_rqkdin(ray, dim);
              var tminNum = imin - rmin;
              var tmin = tminNum / raySize;
              return_array.f(new IntersectResult(ray, tmin, ltree));
            }
          }
        }
         while (0 <= inductionVariable);
    }
     while (false);
    // Inline function 'kotlin.collections.isNotEmpty' call
    var tmp$ret$5 = !hit_stack.a1();
  }
   while (tmp$ret$5);
  return return_array;
}
function _search_subtree($this, intervals, comparators, return_array, root) {
  checkDimensions(intervals, $this);
  var hit_stack = fastArrayListOf([]);
  if (!comparators.q3c(intervals, root.k3c_1))
    return return_array;
  hit_stack.f(ensureNotNull(root.m3c_1));
  $l$1: do {
    $l$0: do {
      var nodes = removeLast(hit_stack);
      var inductionVariable = nodes.j1() - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var ltree = nodes.i1(i);
          if (comparators.q3c(intervals, ltree.k3c_1)) {
            if (!(ltree.m3c_1 == null)) {
              hit_stack.f(ensureNotNull(ltree.m3c_1));
            } else if (!(ltree.n3c_1 == null)) {
              return_array.f(ltree);
            }
          }
        }
         while (0 <= inductionVariable);
    }
     while (false);
    // Inline function 'kotlin.collections.isNotEmpty' call
    var tmp$ret$0 = !hit_stack.a1();
  }
   while (tmp$ret$0);
  return return_array;
}
function _search_subtree$default($this, intervals, comparators, return_array, root, $super) {
  return_array = return_array === VOID ? fastArrayListOf([]) : return_array;
  root = root === VOID ? $this.w3c_1 : root;
  return _search_subtree($this, intervals, comparators, return_array, root);
}
function BVH$iterator$slambda_0(this$0) {
  var i = new BVH$iterator$slambda(this$0);
  var l = function ($this$iterator, $completion) {
    return i.h3d($this$iterator, $completion);
  };
  l.$arity = 1;
  return l;
}
function _BVHRect___init__impl__pawpsv(intervals) {
  return intervals;
}
function _BVHRect___get_intervals__impl__r54djc($this) {
  return $this;
}
function _BVHRect___init__impl__pawpsv_0(dimensions) {
  return _BVHRect___init__impl__pawpsv(BVHIntervals.e3d(dimensions));
}
function BVHRect__checkDimensions_impl_tlq5ao($this, dimensions) {
  checkDimensions_0(_BVHRect___get_dimensions__impl__9rcjgr($this), dimensions);
}
function _BVHRect___get_data__impl__kn7sj6($this) {
  return _BVHRect___get_intervals__impl__r54djc($this).c3d_1;
}
function BVHRect__copyFrom_impl_kjg73e($this, other) {
  _BVHRect___get_intervals__impl__r54djc($this).r3d(_BVHRect___get_intervals__impl__r54djc(other));
  return $this;
}
function BVHRect__clone_impl_weisiw($this) {
  // Inline function 'kotlin.collections.copyOf' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$1 = _BVHRect___get_data__impl__kn7sj6($this).slice();
  return _BVHRect___init__impl__pawpsv(BVHIntervals.s3d(tmp$ret$1));
}
function _BVHRect___get_length__impl__sgc79u($this) {
  return _BVHRect___get_dimensions__impl__9rcjgr($this);
}
function _BVHRect___get_dimensions__impl__9rcjgr($this) {
  return _BVHRect___get_data__impl__kn7sj6($this).length / 2 | 0;
}
function BVHRect__min_impl_5ss93n($this, dim) {
  return _BVHRect___get_data__impl__kn7sj6($this)[imul(dim, 2) + 0 | 0];
}
function BVHRect__size_impl_axghd8($this, dim) {
  return _BVHRect___get_data__impl__kn7sj6($this)[imul(dim, 2) + 1 | 0];
}
function BVHRect__max_impl_z6ab7l($this, dim) {
  return BVHRect__min_impl_5ss93n($this, dim) + BVHRect__size_impl_axghd8($this, dim);
}
function BVHRect__min_impl_5ss93n_0($this, dim, value) {
  _BVHRect___get_data__impl__kn7sj6($this)[imul(dim, 2) + 0 | 0] = value;
}
function BVHRect__size_impl_axghd8_0($this, dim, value) {
  _BVHRect___get_data__impl__kn7sj6($this)[imul(dim, 2) + 1 | 0] = value;
}
function _BVHRect___get_min__impl__nebhl8($this) {
  var tmp = 0;
  var tmp_0 = _BVHRect___get_dimensions__impl__9rcjgr($this);
  var tmp_1 = new Float64Array(tmp_0);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = BVHRect__min_impl_5ss93n($this, tmp_2);
    tmp = tmp + 1 | 0;
  }
  return new BVHVector(tmp_1);
}
function _BVHRect___get_max__impl__6r11ia($this) {
  var tmp = 0;
  var tmp_0 = _BVHRect___get_dimensions__impl__9rcjgr($this);
  var tmp_1 = new Float64Array(tmp_0);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = BVHRect__max_impl_z6ab7l($this, tmp_2);
    tmp = tmp + 1 | 0;
  }
  return new BVHVector(tmp_1);
}
function BVHRect__toString_impl_z8yavd($this) {
  return 'BVHRect(min=' + _BVHRect___get_min__impl__nebhl8($this).toString() + ', max=' + _BVHRect___get_max__impl__6r11ia($this).toString() + ')';
}
function BVHRect__hashCode_impl_dhse2i($this) {
  return $this.hashCode();
}
function BVHRect__equals_impl_cirifq($this, other) {
  if (!(other instanceof BVHRect))
    return false;
  var tmp0_other_with_cast = other instanceof BVHRect ? other.n3d_1 : THROW_CCE();
  if (!$this.equals(tmp0_other_with_cast))
    return false;
  return true;
}
function _BVHRay___init__impl__rrqut(intervals) {
  return intervals;
}
function _BVHRay___get_intervals__impl__b4qgpu($this) {
  return $this;
}
function _BVHRay___get_data__impl__uwp1cs($this) {
  return _BVHRay___get_intervals__impl__b4qgpu($this).c3d_1;
}
function _BVHRay___get_dimensions__impl__ba0c7z($this) {
  return _BVHRay___get_data__impl__uwp1cs($this).length / 2 | 0;
}
function BVHRay__pos_impl_rqkdin($this, dim) {
  return _BVHRay___get_data__impl__uwp1cs($this)[imul(dim, 2) + 0 | 0];
}
function BVHRay__dir_impl_jibowm($this, dim) {
  return _BVHRay___get_data__impl__uwp1cs($this)[imul(dim, 2) + 1 | 0];
}
function _BVHRay___get_pos__impl__icqom4($this) {
  var tmp = 0;
  var tmp_0 = _BVHRay___get_dimensions__impl__ba0c7z($this);
  var tmp_1 = new Float64Array(tmp_0);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = BVHRay__pos_impl_rqkdin($this, tmp_2);
    tmp = tmp + 1 | 0;
  }
  return new BVHVector(tmp_1);
}
function _BVHRay___get_dir__impl__57k859($this) {
  var tmp = 0;
  var tmp_0 = _BVHRay___get_dimensions__impl__ba0c7z($this);
  var tmp_1 = new Float64Array(tmp_0);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = BVHRay__dir_impl_jibowm($this, tmp_2);
    tmp = tmp + 1 | 0;
  }
  return new BVHVector(tmp_1);
}
function BVHRay__toString_impl_otz8id($this) {
  return 'BVHRay(pos=' + _BVHRay___get_pos__impl__icqom4($this).toString() + ', dir=' + _BVHRay___get_dir__impl__57k859($this).toString() + ')';
}
function BVHRay__hashCode_impl_ofywnw($this) {
  return $this.hashCode();
}
var Companion_instance_15;
function Companion_getInstance_14() {
  return Companion_instance_15;
}
var Companion_instance_16;
function Companion_getInstance_15() {
  return Companion_instance_16;
}
function BVHVector$toString$lambda(it) {
  return get_niceStr(it);
}
function checkDimensions_0(actual, expected) {
  if (!(actual === expected)) {
    // Inline function 'kotlin.error' call
    var message = 'element ' + actual + " doesn't match dimensions " + expected;
    throw IllegalStateException.m5(toString(message));
  }
}
function point$factory() {
  return getPropertyCallableRef('point', 1, KProperty1, function (receiver) {
    return receiver.o3c();
  }, null);
}
function asFakeMutable(_this__u8e3s4) {
  return new FakeMutableIterator(_this__u8e3s4);
}
function contentHashCode_4(_this__u8e3s4, src, dst) {
  // Inline function 'korlibs.datastructure.internal.hashCoder' call
  var count = dst - src | 0;
  var out = 0;
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      out = imul(out, 7);
      var tmp = out;
      // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
      out = tmp + toRawBits(_this__u8e3s4[src + n | 0]) | 0;
    }
     while (inductionVariable < count);
  return out;
}
function contentEquals_4(_this__u8e3s4, that, src, dst) {
  var tmp$ret$1;
  $l$block: {
    // Inline function 'korlibs.datastructure.internal.equaler' call
    var count = dst - src | 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
        if (!(_this__u8e3s4[src + n | 0] === that[src + n | 0])) {
          tmp$ret$1 = false;
          break $l$block;
        }
      }
       while (inductionVariable < count);
    tmp$ret$1 = true;
  }
  return tmp$ret$1;
}
function contentHashCode_5(_this__u8e3s4, src, dst) {
  // Inline function 'korlibs.datastructure.internal.hashCoder' call
  var count = dst - src | 0;
  var out = 0;
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      out = imul(out, 7);
      var tmp = out;
      // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
      out = tmp + _this__u8e3s4[src + n | 0] | 0;
    }
     while (inductionVariable < count);
  return out;
}
function contentEquals_5(_this__u8e3s4, that, src, dst) {
  var tmp$ret$1;
  $l$block: {
    // Inline function 'korlibs.datastructure.internal.equaler' call
    var count = dst - src | 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
        if (!(_this__u8e3s4[src + n | 0] === that[src + n | 0])) {
          tmp$ret$1 = false;
          break $l$block;
        }
      }
       while (inductionVariable < count);
    tmp$ret$1 = true;
  }
  return tmp$ret$1;
}
function contentHashCode_6(_this__u8e3s4, src, dst) {
  // Inline function 'korlibs.datastructure.internal.hashCoder' call
  var count = dst - src | 0;
  var out = 0;
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      out = imul(out, 7);
      var tmp = out;
      // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
      out = tmp + numberToInt(_this__u8e3s4[src + n | 0]) | 0;
    }
     while (inductionVariable < count);
  return out;
}
function contentEquals_6(_this__u8e3s4, that, src, dst) {
  var tmp$ret$1;
  $l$block: {
    // Inline function 'korlibs.datastructure.internal.equaler' call
    var count = dst - src | 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
        if (!(_this__u8e3s4[src + n | 0] === that[src + n | 0])) {
          tmp$ret$1 = false;
          break $l$block;
        }
      }
       while (inductionVariable < count);
    tmp$ret$1 = true;
  }
  return tmp$ret$1;
}
function fill(_this__u8e3s4, value) {
  var inductionVariable = 0;
  var last = _this__u8e3s4.length - 1 | 0;
  if (inductionVariable <= last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      _this__u8e3s4[n] = value;
    }
     while (inductionVariable <= last);
}
function fill_0(_this__u8e3s4, value) {
  var inductionVariable = 0;
  var last = _this__u8e3s4.length - 1 | 0;
  if (inductionVariable <= last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      _this__u8e3s4[n] = value;
    }
     while (inductionVariable <= last);
}
function Observable$_init_$lambda_i0szc(it) {
  return Unit_instance;
}
function Observable$_init_$lambda_i0szc_0(it) {
  return Unit_instance;
}
function linkedHashMapOf(pairs) {
  // Inline function 'kotlin.also' call
  var this_0 = LinkedHashMap.vc();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.linkedHashMapOf.<anonymous>' call
  var inductionVariable = 0;
  var last = pairs.length;
  while (inductionVariable < last) {
    var _destruct__k2r9zo = pairs[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    var key = _destruct__k2r9zo.km();
    var value = _destruct__k2r9zo.lm();
    // Inline function 'kotlin.collections.set' call
    this_0.r3(key, value);
  }
  return this_0;
}
function _BSearchResult___init__impl__em6zam(raw) {
  return raw;
}
function _BSearchResult___get_raw__impl__uyuhf3($this) {
  return $this;
}
function _BSearchResult___get_found__impl__om04iz($this) {
  return _BSearchResult___get_raw__impl__uyuhf3($this) >= 0;
}
function _BSearchResult___get_index__impl__b5kraz($this) {
  return _BSearchResult___get_found__impl__om04iz($this) ? _BSearchResult___get_raw__impl__uyuhf3($this) : -1;
}
function _BSearchResult___get_nearIndex__impl__s8nq43($this) {
  return _BSearchResult___get_found__impl__om04iz($this) ? _BSearchResult___get_raw__impl__uyuhf3($this) : (-_BSearchResult___get_raw__impl__uyuhf3($this) | 0) - 1 | 0;
}
function binarySearch(_this__u8e3s4, v, fromIndex, toIndex) {
  fromIndex = fromIndex === VOID ? 0 : fromIndex;
  toIndex = toIndex === VOID ? _this__u8e3s4.j1() : toIndex;
  // Inline function 'korlibs.datastructure.genericBinarySearchResult' call
  var tmp$ret$1;
  $l$block: {
    // Inline function 'korlibs.datastructure.genericBinarySearch' call
    var low = fromIndex;
    var high = toIndex - 1 | 0;
    while (low <= high) {
      var mid = (low + high | 0) / 2 | 0;
      // Inline function 'korlibs.datastructure.binarySearch.<anonymous>' call
      var mval = compareTo(_this__u8e3s4.h13(mid), v);
      if (mval < 0)
        low = mid + 1 | 0;
      else if (mval > 0)
        high = mid - 1 | 0;
      else {
        tmp$ret$1 = mid;
        break $l$block;
      }
    }
    // Inline function 'korlibs.datastructure.genericBinarySearchResult.<anonymous>' call
    tmp$ret$1 = (-low | 0) - 1 | 0;
  }
  return _BSearchResult___init__impl__em6zam(tmp$ret$1);
}
function getCyclic(_this__u8e3s4, index) {
  return _this__u8e3s4.i1(umod(index, _this__u8e3s4.j1()));
}
function getCyclicOrNull(_this__u8e3s4, index) {
  return _this__u8e3s4.a1() ? null : getOrNull(_this__u8e3s4, umod(index, _this__u8e3s4.j1()));
}
function binarySearch_0(_this__u8e3s4, v, fromIndex, toIndex) {
  fromIndex = fromIndex === VOID ? 0 : fromIndex;
  toIndex = toIndex === VOID ? _this__u8e3s4.j1() : toIndex;
  // Inline function 'korlibs.datastructure.genericBinarySearchResult' call
  var tmp$ret$1;
  $l$block: {
    // Inline function 'korlibs.datastructure.genericBinarySearch' call
    var low = fromIndex;
    var high = toIndex - 1 | 0;
    while (low <= high) {
      var mid = (low + high | 0) / 2 | 0;
      // Inline function 'korlibs.datastructure.binarySearch.<anonymous>' call
      var mval = compareTo(_this__u8e3s4.h13(mid), v);
      if (mval < 0)
        low = mid + 1 | 0;
      else if (mval > 0)
        high = mid - 1 | 0;
      else {
        tmp$ret$1 = mid;
        break $l$block;
      }
    }
    // Inline function 'korlibs.datastructure.genericBinarySearchResult.<anonymous>' call
    tmp$ret$1 = (-low | 0) - 1 | 0;
  }
  return _BSearchResult___init__impl__em6zam(tmp$ret$1);
}
function toLinkedMap_0(_this__u8e3s4) {
  // Inline function 'kotlin.also' call
  var this_0 = LinkedHashMap.vc();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.toLinkedMap.<anonymous>' call
  var _iterator__ex2g4s = _this__u8e3s4.f1();
  while (_iterator__ex2g4s.g1()) {
    var _destruct__k2r9zo = _iterator__ex2g4s.h1();
    var key = _destruct__k2r9zo.km();
    var value = _destruct__k2r9zo.lm();
    // Inline function 'kotlin.collections.set' call
    this_0.r3(key, value);
  }
  return this_0;
}
function getCyclic_0(_this__u8e3s4, index) {
  return _this__u8e3s4[umod(index, _this__u8e3s4.length)];
}
function genericSort(subject, left, right, ops) {
  return genericSort_0(subject, left, right, ops, false);
}
function genericSort_0(subject, left, right, ops, reversed) {
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.datastructure.genericSort.<anonymous>' call
  timSort(subject, left, right, ops, reversed);
  return subject;
}
function timSort(arr, l, r, ops, reversed, RUN) {
  RUN = RUN === VOID ? 32 : RUN;
  var n = (r - l | 0) + 1 | 0;
  var progression = step(until(0, n), RUN);
  var inductionVariable = progression.k2_1;
  var last = progression.l2_1;
  var step_0 = progression.m2_1;
  if (step_0 > 0 && inductionVariable <= last || (step_0 < 0 && last <= inductionVariable))
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + step_0 | 0;
      var tmp = l + i | 0;
      // Inline function 'kotlin.math.min' call
      var a = (i + RUN | 0) - 1 | 0;
      var b = n - 1 | 0;
      var tmp$ret$0 = Math.min(a, b);
      insertionSort(arr, tmp, l + tmp$ret$0 | 0, ops, reversed);
    }
     while (!(i === last));
  var size = RUN;
  while (size < n) {
    var progression_0 = step(until(0, n), imul(2, size));
    var inductionVariable_0 = progression_0.k2_1;
    var last_0 = progression_0.l2_1;
    var step_1 = progression_0.m2_1;
    if (step_1 > 0 && inductionVariable_0 <= last_0 || (step_1 < 0 && last_0 <= inductionVariable_0))
      do {
        var left = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + step_1 | 0;
        // Inline function 'kotlin.math.min' call
        var a_0 = size;
        var b_0 = (n - left | 0) - 1 | 0;
        var rize = Math.min(a_0, b_0);
        var mid = (left + rize | 0) - 1 | 0;
        // Inline function 'kotlin.math.min' call
        var a_1 = (left + imul(2, rize) | 0) - 1 | 0;
        var b_1 = n - 1 | 0;
        var right = Math.min(a_1, b_1);
        merge(arr, l + left | 0, l + mid | 0, l + right | 0, ops, reversed);
      }
       while (!(left === last_0));
    size = imul(size, 2);
  }
}
function insertionSort(arr, left, right, ops, reversed) {
  var inductionVariable = left + 1 | 0;
  if (inductionVariable <= right)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var m = n - 1 | 0;
      $l$loop: while (m >= left && negateIf(ops.b33(arr, m, n), reversed) > 0) {
        m = m - 1 | 0;
      }
      m = m + 1 | 0;
      if (!(m === n)) {
        ops.e33(arr, m, n);
      }
    }
     while (!(n === right));
}
function merge(arr, start, mid, end, ops, reversed) {
  var s = start;
  var m = mid;
  var s2 = m + 1 | 0;
  if (negateIf(ops.b33(arr, m, s2), reversed) <= 0)
    return Unit_instance;
  while (s <= m && s2 <= end) {
    if (negateIf(ops.b33(arr, s, s2), reversed) <= 0) {
      s = s + 1 | 0;
    } else {
      ops.e33(arr, s, s2);
      s = s + 1 | 0;
      m = m + 1 | 0;
      s2 = s2 + 1 | 0;
    }
  }
}
function negateIf(_this__u8e3s4, doNegate) {
  return doNegate ? -_this__u8e3s4 | 0 : _this__u8e3s4;
}
var Memory_instance;
function Memory_getInstance() {
  return Memory_instance;
}
var Companion_instance_17;
function Companion_getInstance_16() {
  return Companion_instance_17;
}
function index($this, index, offset) {
  return imul(index, 2) + offset | 0;
}
function pointArrayListOf(p0, p1) {
  return (new PointArrayList(2)).s3e(p0).s3e(p1);
}
function pointArrayListOf_0(p0, p1, p2) {
  return (new PointArrayList(3)).s3e(p0).s3e(p1).s3e(p2);
}
function pointArrayListOf_1(p0, p1, p2, p3) {
  return (new PointArrayList(4)).s3e(p0).s3e(p1).s3e(p2).s3e(p3);
}
function checkDimensions_1($this, dim) {
  if (!(dim === $this.y3e_1)) {
    // Inline function 'kotlin.error' call
    var message = 'Invalid dimensions ' + dim + ' != ' + $this.y3e_1;
    throw IllegalStateException.m5(toString(message));
  }
}
function DoubleVectorArrayList$vectorToStringBuilder$lambda(this$0, $index, $roundDecimalPlaces) {
  return function ($this$appendGenericArray, it) {
    var v = this$0.z2k($index, it);
    appendNice($this$appendGenericArray, !($roundDecimalPlaces == null) ? roundDecimalPlaces(v, $roundDecimalPlaces) : v);
    return Unit_instance;
  };
}
var Companion_instance_18;
function Companion_getInstance_17() {
  return Companion_instance_18;
}
function FastStringMap() {
  // Inline function 'kotlin.js.asDynamic' call
  return new Map();
}
function FastIntMap() {
  // Inline function 'kotlin.js.asDynamic' call
  return new Map();
}
function get_size(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  return _this__u8e3s4.size;
}
function FastIdentityMap() {
  // Inline function 'kotlin.js.asDynamic' call
  return new Map();
}
function contains_1(_this__u8e3s4, key) {
  // Inline function 'kotlin.js.asDynamic' call
  return _this__u8e3s4.has(key);
}
function clear(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  _this__u8e3s4.clear();
}
function get_size_0(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  return _this__u8e3s4.size;
}
function get_4(_this__u8e3s4, key) {
  // Inline function 'kotlin.js.asDynamic' call
  return _this__u8e3s4.get(key);
}
function set_4(_this__u8e3s4, key, value) {
  // Inline function 'kotlin.js.asDynamic' call
  _this__u8e3s4.set(key, value);
}
function remove(_this__u8e3s4, key) {
  // Inline function 'kotlin.js.asDynamic' call
  _this__u8e3s4.delete(key);
}
function keys(_this__u8e3s4) {
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$2 = Array_from(_this__u8e3s4.keys());
  return toList_0(tmp$ret$2);
}
function Array_from(value) {
  return Array.from(value);
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForInterface(IArray2, 'IArray2', VOID, VOID, [Iterable]);
initMetadataForInterface(IntIArray2, 'IntIArray2', VOID, VOID, [IArray2]);
protoOf(IntArray2).z2k = get;
protoOf(IntArray2).p2y = set;
protoOf(IntArray2).q2y = set_0;
protoOf(IntArray2).r2y = set_1;
protoOf(IntArray2).s2y = setAt;
protoOf(IntArray2).h13 = getAt_0;
protoOf(IntArray2).u2y = getAt;
protoOf(IntArray2).v2y = inside;
protoOf(IntArray2).o2y = asString;
initMetadataForClass(IntArray2, 'IntArray2', VOID, VOID, [IntIArray2]);
initMetadataForCompanion(Companion_0);
initMetadataForInterface(FloatIArray2, 'FloatIArray2', VOID, VOID, [IArray2]);
protoOf(FloatArray2).z2k = get_1;
protoOf(FloatArray2).a2z = set_2;
protoOf(FloatArray2).b2z = setAt_0;
protoOf(FloatArray2).h13 = getAt_1;
protoOf(FloatArray2).u2y = getAt;
protoOf(FloatArray2).v2y = inside;
protoOf(FloatArray2).o2y = asString;
initMetadataForClass(FloatArray2, 'FloatArray2', VOID, VOID, [FloatIArray2]);
initMetadataForCompanion(Companion_1);
initMetadataForInterface(LongIArray2, 'LongIArray2', VOID, VOID, [IArray2]);
protoOf(LongArray2).z2k = get_2;
protoOf(LongArray2).h2z = set_3;
protoOf(LongArray2).i2z = setAt_1;
protoOf(LongArray2).h13 = getAt_2;
protoOf(LongArray2).u2y = getAt;
protoOf(LongArray2).v2y = inside;
protoOf(LongArray2).o2y = asString;
initMetadataForClass(LongArray2, 'LongArray2', VOID, VOID, [LongIArray2]);
initMetadataForClass(ByteArrayDeque, 'ByteArrayDeque', ByteArrayDeque);
initMetadataForClass(ShortArrayDeque, 'ShortArrayDeque', ShortArrayDeque);
initMetadataForCompanion(Companion_2);
initMetadataForClass(FloatArrayList, 'FloatArrayList', FloatArrayList.t30, VOID, [Collection]);
initMetadataForCompanion(Companion_3);
initMetadataForClass(IntArrayList, 'IntArrayList', IntArrayList.f31, VOID, [Collection]);
initMetadataForCompanion(Companion_4);
protoOf(DoubleArrayList).h3 = listIterator;
initMetadataForClass(DoubleArrayList, 'DoubleArrayList', DoubleArrayList.c32, VOID, [DoubleList]);
initMetadataForClass(ShortArrayList, 'ShortArrayList', ShortArrayList);
initMetadataForClass(BooleanArrayList, 'BooleanArrayList', BooleanArrayList);
initMetadataForClass(SortOps, 'SortOps');
initMetadataForObject(DoubleArrayListSortOps, 'DoubleArrayListSortOps');
initMetadataForClass(BaseObservableArray, 'BaseObservableArray');
protoOf(BaseObservableArray2).v2y = inside;
protoOf(BaseObservableArray2).u2y = getAt;
initMetadataForClass(BaseObservableArray2, 'BaseObservableArray2', VOID, VOID, [BaseObservableArray, IArray2]);
protoOf(ObservableIntArray2).s2y = setAt;
protoOf(ObservableIntArray2).h13 = getAt_0;
protoOf(ObservableIntArray2).p2y = set;
protoOf(ObservableIntArray2).q2y = set_0;
protoOf(ObservableIntArray2).z2k = get;
protoOf(ObservableIntArray2).c2z = get_0;
initMetadataForClass(ObservableIntArray2, 'ObservableIntArray2', VOID, VOID, [BaseObservableArray2, IntIArray2]);
initMetadataForClass(BitSet, 'BitSet', VOID, VOID, [Collection]);
initMetadataForClass(TGenDeque$iterator$1);
initMetadataForClass(TGenDeque, 'TGenDeque', TGenDeque.p34, VOID, [Collection, MutableIterable]);
initMetadataForClass(IntDeque$iterator$1);
initMetadataForClass(IntDeque, 'IntDeque', IntDeque.m35, VOID, [Collection, MutableIterable]);
initMetadataForClass(FastIdentityCacheMap, 'FastIdentityCacheMap', FastIdentityCacheMap);
initMetadataForClass(FastSmallSet, 'FastSmallSet', FastSmallSet.d36);
initMetadataForClass(GenericSubList, 'GenericSubList', VOID, VOID, [KtList]);
initMetadataForClass(GenericListIterator, 'GenericListIterator');
initMetadataForClass(HistoryStack, 'HistoryStack', HistoryStack);
initMetadataForCompanion(Companion_5);
initMetadataForCompanion(Companion_6);
initMetadataForClass(Entry, 'Entry');
initMetadataForClass(Iterator, 'Iterator');
initMetadataForClass(_no_name_provided__qut3iv, VOID, VOID, VOID, [Iterable]);
initMetadataForClass(_no_name_provided__qut3iv_0, VOID, VOID, VOID, [Iterable]);
initMetadataForClass(IntMap, 'IntMap', IntMap.t37);
initMetadataForCompanion(Companion_7);
initMetadataForClass(IntIntMap, 'IntIntMap', IntIntMap.m38);
initMetadataForClass(IntSet, 'IntSet', IntSet, VOID, [KtMutableSet]);
initMetadataForClass(Iterator$1);
initMetadataForClass(ListReader, 'ListReader');
initMetadataForCompanion(Companion_8);
initMetadataForClass(Pool, 'Pool');
initMetadataForClass(ConcurrentPool, 'ConcurrentPool');
initMetadataForClass(ReturnablePool, 'ReturnablePool');
initMetadataForClass(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', VOID, VOID, [Comparator]);
initMetadataForCompanion(Companion_9);
initMetadataForClass(TGenPriorityQueue$iterator$1);
initMetadataForClass(TGenPriorityQueue, 'TGenPriorityQueue', VOID, VOID, [Collection, MutableIterable]);
initMetadataForClass(TGenQueue, 'TGenQueue', TGenQueue, VOID, [Collection]);
initMetadataForClass(Ref$2);
initMetadataForClass(Ref$3);
initMetadataForClass(ByteRingBuffer, 'ByteRingBuffer');
initMetadataForClass(RingBuffer, 'RingBuffer');
initMetadataForClass(ShortRingBuffer, 'ShortRingBuffer');
initMetadataForCompanion(Companion_10);
initMetadataForClass(TGenStack, 'TGenStack', VOID, VOID, [Collection]);
initMetadataForInterface(IStackedArray2Base, 'IStackedArray2Base');
initMetadataForClass(BaseDelegatedStackedArray2, 'BaseDelegatedStackedArray2', VOID, VOID, [IStackedArray2Base]);
initMetadataForInterface(IStackedLongArray2, 'IStackedLongArray2', VOID, VOID, [IStackedArray2Base]);
protoOf(StackedLongArray2FromIStackedIntArray2).i3b = push;
initMetadataForClass(StackedLongArray2FromIStackedIntArray2, 'StackedLongArray2FromIStackedIntArray2', VOID, VOID, [BaseDelegatedStackedArray2, IStackedLongArray2]);
initMetadataForCompanion(Companion_11);
initMetadataForInterface(IStackedIntArray2, 'IStackedIntArray2', VOID, VOID, [IStackedArray2Base]);
protoOf(StackedIntArray2).v3b = push_0;
protoOf(StackedIntArray2).w3a = get_endX;
protoOf(StackedIntArray2).x3a = get_endY;
protoOf(StackedIntArray2).v2y = inside_0;
initMetadataForClass(StackedIntArray2, 'StackedIntArray2', VOID, VOID, [IStackedIntArray2]);
initMetadataForCompanion(Companion_12);
protoOf(StackedLongArray2).i3b = push;
protoOf(StackedLongArray2).w3a = get_endX;
protoOf(StackedLongArray2).x3a = get_endY;
protoOf(StackedLongArray2).v2y = inside_0;
initMetadataForClass(StackedLongArray2, 'StackedLongArray2', VOID, VOID, [IStackedLongArray2]);
initMetadataForClass(Comparators, 'Comparators', Comparators);
initMetadataForCompanion(Companion_13);
initMetadataForClass(Node, 'Node');
initMetadataForClass(RemoveSubtreeRetObject, 'RemoveSubtreeRetObject');
initMetadataForClass(IntersectResult, 'IntersectResult');
initMetadataForLambda(BVH$iterator$slambda, VOID, VOID, [1]);
initMetadataForClass(BVH, 'BVH', VOID, VOID, [Iterable]);
initMetadataForClass(BVHRect, 'BVHRect');
initMetadataForCompanion(Companion_14);
initMetadataForClass(BVHIntervals, 'BVHIntervals');
initMetadataForCompanion(Companion_15);
initMetadataForClass(BVHVector, 'BVHVector');
initMetadataForClass(FakeMutableIterator, 'FakeMutableIterator');
initMetadataForClass(SyncPauseable, 'SyncPauseable', SyncPauseable);
initMetadataForClass(Computed, 'Computed');
initMetadataForClass(Observable, 'Observable');
initMetadataForObject(Memory, 'Memory');
initMetadataForInterface(PointList, 'PointList', VOID, VOID, [IDoubleVectorList, IPointList]);
initMetadataForCompanion(Companion_16);
protoOf(PointArrayList).q3e = clone$default;
protoOf(PointArrayList).b2l = get_dimensions;
protoOf(PointArrayList).a1 = isEmpty;
protoOf(PointArrayList).j3e = getComponentList;
protoOf(PointArrayList).k3e = getComponentList$default;
protoOf(PointArrayList).v34 = get_first;
protoOf(PointArrayList).w34 = get_last;
protoOf(PointArrayList).u2k = contains;
protoOf(PointArrayList).o2t = contains_0;
protoOf(PointArrayList).y2k = isNotEmpty;
protoOf(PointArrayList).a2l = isAlmostEquals_0;
protoOf(PointArrayList).vz = isAlmostEquals$default;
protoOf(PointArrayList).s2k = containsAll;
protoOf(PointArrayList).q2k = indexOf;
protoOf(PointArrayList).f1 = iterator_0;
protoOf(PointArrayList).h3 = listIterator_0;
protoOf(PointArrayList).p1 = listIterator_1;
protoOf(PointArrayList).n1 = subList;
initMetadataForClass(PointArrayList, 'PointArrayList', PointArrayList, VOID, [PointList]);
protoOf(DoubleVectorArrayList).a2l = isAlmostEquals_0;
protoOf(DoubleVectorArrayList).vz = isAlmostEquals$default;
initMetadataForClass(DoubleVectorArrayList, 'DoubleVectorArrayList', VOID, VOID, [IDoubleVectorList]);
initMetadataForCompanion(Companion_17);
initMetadataForClass(PointIntArrayList, 'PointIntArrayList', PointIntArrayList);
//endregion
//region block: init
Companion_instance_0 = new Companion();
Companion_instance_1 = new Companion_0();
Companion_instance_2 = new Companion_1();
Companion_instance_3 = new Companion_2();
Companion_instance_4 = new Companion_3();
Companion_instance_5 = new Companion_4();
Companion_instance_6 = new Companion_5();
Companion_instance_7 = new Companion_6();
Companion_instance_8 = new Companion_7();
Companion_instance_9 = new Companion_8();
Companion_instance_10 = new Companion_9();
Companion_instance_11 = new Companion_10();
Companion_instance_12 = new Companion_11();
Companion_instance_15 = new Companion_14();
Companion_instance_16 = new Companion_15();
Memory_instance = new Memory();
Companion_instance_17 = new Companion_16();
Companion_instance_18 = new Companion_17();
//endregion
//region block: exports
export {
  _BVHRay___init__impl__rrqut as _BVHRay___init__impl__rrqut1wksan13jo12b,
  _BVHRect___init__impl__pawpsv as _BVHRect___init__impl__pawpsv13onfwumlb9kw,
  BVHRect__min_impl_5ss93n as BVHRect__min_impl_5ss93n2jfqrhkmyl6xm,
  BVHRect__size_impl_axghd8 as BVHRect__size_impl_axghd82vtcbc1dq7n7n,
  _BSearchResult___init__impl__em6zam as _BSearchResult___init__impl__em6zamdlvo8rkghxe3,
  _BSearchResult___get_found__impl__om04iz as _BSearchResult___get_found__impl__om04iz3gtiempqu7d21,
  _BSearchResult___get_index__impl__b5kraz as _BSearchResult___get_index__impl__b5krazm2irv549pugj,
  _BSearchResult___get_nearIndex__impl__s8nq43 as _BSearchResult___get_nearIndex__impl__s8nq4312kfva7q1s86i,
  _BSearchResult___get_raw__impl__uyuhf3 as _BSearchResult___get_raw__impl__uyuhf33e2ricm0z1amr,
  _TGenStack___init__impl__yynjgt as _TGenStack___init__impl__yynjgtbdasgrb15naj,
  TGenStack__pop_impl_qisxa0 as TGenStack__pop_impl_qisxa03row6p4nvthsp,
  TGenStack__push_impl_9yh33t as TGenStack__push_impl_9yh33t3meiyro65i033,
  Companion_instance_1 as Companion_instance1pvn6c7thxh4,
  Companion_instance_0 as Companion_instance1nrsidgsshcc7,
  Companion_instance_9 as Companion_instanceyizv3jv25o3v,
  Companion_instance_12 as Companion_instance102wguxec40nc,
  Companion_instance_10 as Companion_instance3aqdk7kknb8d1,
  BVHIntervals as BVHIntervals11ladchlo7ig0,
  BVH as BVHdgoku170r1rz,
  SyncPauseable as SyncPauseable20rt0nt3umu2s,
  BaseDelegatedStackedArray2 as BaseDelegatedStackedArray2i6xcy52y4r2o,
  BitSet as BitSetdjtyjyvg0eod,
  BooleanArrayList as BooleanArrayListzyiutig3135i,
  ByteArrayDeque as ByteArrayDeque2dezgzzyyl6zm,
  Computed as Computed3myh0u4qvtp8f,
  ConcurrentPool as ConcurrentPool3ky1e4r5za9ab,
  Deque as Deque18dk36ds2yxk9,
  DoubleArrayList as DoubleArrayList3by0we6lhx26x,
  FastIdentityCacheMap as FastIdentityCacheMap3fegd59qa1ixp,
  FastIdentityMap as FastIdentityMap17d14y8i3m5g7,
  FastIntMap as FastIntMapl0pzyavg6uw5,
  FastSmallSet as FastSmallSet2og2j8v2pz4c7,
  FastStringMap as FastStringMap1l1uzdhsfbatm,
  FloatArray2 as FloatArray2beehiaucxmzd,
  FloatArrayList as FloatArrayListavy8wwlieh26,
  GenericListIterator as GenericListIterator397gjg9p2xwl5,
  GenericSubList as GenericSubList2rec7qqyf06nw,
  HistoryStack as HistoryStack2mrikkhc0etuj,
  IStackedArray2Base as IStackedArray2Baseutd8qm80cz0q,
  IntArray2 as IntArray23680o40e0fgt8,
  IntArrayList as IntArrayList3liuagbdnwmna,
  IntDeque as IntDeque2ebok66x2sofm,
  IntIntMap as IntIntMap3mvg5iqgd5t3z,
  IntMap as IntMap2d9g6f5pyaoc2,
  IntSet as IntSet3s3k6p4gna72o,
  ListReader as ListReader37jxep6q6wbzq,
  Observable as Observable19v74jqcsmm4f,
  Pool as Pool1zbsw0zhvh8ca,
  Ref_0 as Ref11k43t82thcp3,
  Ref as Ref2y4j4snnxo6gs,
  ReturnablePool as ReturnablePool3rkgvxsckx2u2,
  ShortArrayDeque as ShortArrayDeque13dn2tr6asm80,
  ShortArrayList as ShortArrayList24204ipipacat,
  SortOps as SortOps3rp25tm4jbjru,
  StackedIntArray2 as StackedIntArray21gpxcd3ph9cq2,
  StackedLongArray2 as StackedLongArray2rhxzonenq3dr,
  TGenDeque as TGenDeque2zx9g0ftfbw19,
  TGenQueue as TGenQueue2v9j3ju90xgpv,
  asLong as asLong2pdvo9u0nmcck,
  associateByInt as associateByInt3vmv30txx5avl,
  binarySearch_0 as binarySearch2l7jc6a3nco69,
  binarySearch as binarySearch1lxbagta5e4q4,
  clear as clear3idbm40uzyume,
  contains_1 as containsrsn76oa0puwr,
  doubleArrayListOf as doubleArrayListOfkj3z9zq4pobs,
  expect as expect28ynvao96agxo,
  floatArrayListOf as floatArrayListOfa8snvg2p0fhc,
  genericSort as genericSort3vhmyxbppxo3h,
  getAndRemove as getAndRemove1ksxnnren48mb,
  getCyclicOrNull as getCyclicOrNull12fem85bj0m7u,
  getCyclic_0 as getCyclic18d9063nmuul,
  getCyclic as getCyclic28wvgqye98jqx,
  get_4 as get2pw1zlgv5zypd,
  intArrayListOf as intArrayListOf18h0hna7wb0zr,
  get_keys as get_keyswqddktl7cnu2,
  linkedHashMapOf as linkedHashMapOf2w2uhgrq4khrk,
  memoize as memoize7smufkpxqzwy,
  observe as observe9z1mw8x3w951,
  reader as reader2h6ma9ht1vbo2,
  reverse_0 as reverse22fe0u1pwl2c6,
  rotateRight as rotateRight96vk8nw2c6nx,
  set_4 as set2vuztm9k7ubgo,
  get_size_0 as get_size2tttv7g2u748n,
  get_size as get_size3oaoh8cpamcqc,
  sort as sort23bpsawysis6n,
  swap as swap3upf401qn4fc8,
  toDeque as toDeque2gx2crptp8sro,
  toIntArrayList as toIntArrayList3jlt4o6tyleu,
  toIntMap as toIntMapp1jij98esrcm,
  toLinkedMap as toLinkedMap37vdtde5j2z8d,
  toRef as toRef3av6zm8yavumw,
  toStacked as toStacked2m99u9tgdzvj0,
  DoubleVectorArrayList as DoubleVectorArrayListq54dfthnqi2t,
  PointArrayList as PointArrayList281mrsgyr6ua1,
  PointIntArrayList as PointIntArrayList1s7gare9hr1oh,
  pointArrayListOf_1 as pointArrayListOf36sb9r6prgwk4,
  pointArrayListOf_0 as pointArrayListOf2s9je3njyg83l,
  pointArrayListOf as pointArrayListOf7rbgdbqf3x4b,
};
//endregion
