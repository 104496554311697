import {
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  Unit_instance104q5opgivhr8 as Unit_instance,
  fill2542d4m9l93pn as fill,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  toByte4i43936u611k as toByte,
  VOID7hggqo3abtya as VOID,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  toShort36kaw0zjdq3ex as toShort,
  until1jbpn0z3f8lbg as until,
  step18s9qzr5xwxat as step,
  createThis2j2avj17cvnv2 as createThis,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  arrayCopytctsywo3h7gj as arrayCopy,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  Long2qws0ah9gnpki as Long,
  toLongw1zpgk99d84b as toLong,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isInterface3d6p8outrmvmk as isInterface,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  KProperty02ce7r476m8633 as KProperty0,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  lazy2hsh8ze7j6ikd as lazy,
  protoOf180f3jzyo7rfj as protoOf,
  NotImplementedErrorfzlkpv14xxr8 as NotImplementedError,
  Exceptiondt2hlxn7j7vw as Exception,
  numberToLong1a4cndvg6c52s as numberToLong,
} from './kotlin-kotlin-stdlib.mjs';
import {
  unsupported198cz39rr1ztw as unsupported,
  ByteArrayBuilderyrflhqjz64ju as ByteArrayBuilder,
  invalidOp1stebxh2m3n07 as invalidOp,
} from './korlibs-korlibs-platform.mjs';
import {
  asyncStreamWriter29xletsdir5u as asyncStreamWriter,
  MemorySyncStream1uv927me2xl9s as MemorySyncStream,
  openSync2m3qn99fqytu0 as openSync,
  AsyncOutputStreamkoco1dsjo5gm as AsyncOutputStream,
  toAsync26jtc2k5wi4gn as toAsync,
  toAsync2pa2d6thtq8t4 as toAsync_0,
  write$default79xrgs2e032z as write$default,
  readAllc0x1zv8qqb58 as readAll,
  readBytesExact2w7t0p09xkbt6 as readBytesExact,
  readS64LE1ebpo9xwj4186 as readS64LE,
  writeBytes1cdj8d25o06wj as writeBytes,
  AsyncGetLengthStream219k26r6v6js2 as AsyncGetLengthStream,
  write81fg3kayjaelgo as write8,
  AsyncGetPositionStream2f8n5516y4rp7 as AsyncGetPositionStream,
} from './korlibs-korlibs-io-stream.mjs';
import { runBlockingNoSuspensions25hsuevf2mle as runBlockingNoSuspensions } from './korlibs-korlibs-concurrent.mjs';
import {
  CRC32_getInstance3miqiylzjuhxd as CRC32_getInstance,
  Adler32_instance34zzfqagdwr1t as Adler32_instance,
} from './korlibs-korlibs-checksum.mjs';
import {
  get_hex2zltmntpniwff as get_hex,
  get_hex32p4ssq0y1xxx as get_hex_0,
} from './korlibs-korlibs-encoding.mjs';
import { extract2vhru1ihy4ogl as extract } from './korlibs-korlibs-math-core.mjs';
import {
  ASCII_getInstance1x38t72vrtpyt as ASCII_getInstance,
  toString2x583ovdqfjci as toString_0,
} from './korlibs-korlibs-string.mjs';
import {
  SimpleRingBuffer1a4k3g2va6i8y as SimpleRingBuffer,
  getU16LE2cizldw8qp53x as getU16LE,
  getS32LE1pxm9wohin3g8 as getS32LE,
  getS32BE5aphh7sm8f2q as getS32BE,
} from './korlibs-korlibs-memory.mjs';
//region block: imports
var imul = Math.imul;
var clz32 = Math.clz32;
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    this.c25_1 = 8388608;
    this.d25_1 = 131072;
  }
}
class Companion_0 {
  constructor() {
    this.e25_1 = -1;
    this.f25_1 = -2;
    this.g25_1 = 1023;
    this.h25_1 = 16;
    this.i25_1 = 288;
    this.j25_1 = true;
    this.k25_1 = true;
    this.l25_1 = 10;
  }
}
class SlidingWindowWithOutput {
  constructor(sliding, out, flushSize, extraSize) {
    flushSize = flushSize === VOID ? 8388608 : flushSize;
    extraSize = extraSize === VOID ? 131072 : extraSize;
    this.b26_1 = sliding;
    this.c26_1 = out;
    this.d26_1 = flushSize;
    this.e26_1 = extraSize;
    this.f26_1 = FixedSizeByteArrayBuilder.c27(this.d26_1 + this.e26_1 | 0);
  }
  i26() {
    return this.f26_1.h26_1 >= this.d26_1;
  }
  x26(distance, length) {
    var inductionVariable = 0;
    if (inductionVariable < length)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = this.b26_1.h27(distance);
        this.f26_1.qv(toByte(v));
      }
       while (inductionVariable < length);
  }
  b27(bytes, offset, len) {
    this.f26_1.ov(bytes, offset, len);
    this.b26_1.i27(bytes, offset, len);
  }
  y26(byte) {
    this.f26_1.qv(byte);
    this.b26_1.j27(get_unsigned(byte));
  }
}
class DeflaterPortable {
  constructor(windowBits) {
    Companion_getInstance_1();
    this.y25_1 = windowBits;
  }
  l27(reader, out, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_uncompress__ej2bxp(this, reader, out, $completion), $completion);
  }
}
class Companion_1 extends DeflaterPortable {
  constructor() {
    Companion_instance_1 = null;
    super(15);
    Companion_instance_1 = this;
    var tmp = this;
    var tmp_0 = new HuffmanTree();
    // Inline function 'kotlin.apply' call
    var this_0 = new Int32Array(288);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.compression.deflate.Companion.FIXED_TREE.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable <= 143)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this_0[n] = 8;
      }
       while (inductionVariable <= 143);
    var inductionVariable_0 = 144;
    if (inductionVariable_0 <= 255)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        this_0[n_0] = 9;
      }
       while (inductionVariable_0 <= 255);
    var inductionVariable_1 = 256;
    if (inductionVariable_1 <= 279)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        this_0[n_1] = 7;
      }
       while (inductionVariable_1 <= 279);
    var inductionVariable_2 = 280;
    if (inductionVariable_2 <= 287)
      do {
        var n_2 = inductionVariable_2;
        inductionVariable_2 = inductionVariable_2 + 1 | 0;
        this_0[n_2] = 8;
      }
       while (inductionVariable_2 <= 287);
    tmp.n26_1 = tmp_0.k27(this_0);
    var tmp_1 = this;
    var tmp_2 = new HuffmanTree();
    var tmp_3 = 0;
    var tmp_4 = new Int32Array(32);
    while (tmp_3 < 32) {
      tmp_4[tmp_3] = 5;
      tmp_3 = tmp_3 + 1 | 0;
    }
    tmp_1.o26_1 = tmp_2.k27(tmp_4);
    var tmp_5 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_5.p26_1 = new Int32Array([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0]);
    var tmp_6 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_6.q26_1 = new Int32Array([3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19, 23, 27, 31, 35, 43, 51, 59, 67, 83, 99, 115, 131, 163, 195, 227, 258, 0, 0]);
    var tmp_7 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_7.r26_1 = new Int32Array([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13]);
    var tmp_8 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_8.s26_1 = new Int32Array([1, 2, 3, 4, 5, 7, 9, 13, 17, 25, 33, 49, 65, 97, 129, 193, 257, 385, 513, 769, 1025, 1537, 2049, 3073, 4097, 6145, 8193, 12289, 16385, 24577, 0, 0]);
    var tmp_9 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_9.t26_1 = new Int32Array([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]);
  }
}
class HuffmanTree {
  constructor() {
    this.m25_1 = new Int32Array(1024);
    this.n25_1 = new Int32Array(1024);
    this.o25_1 = new Int32Array(1024);
    this.p25_1 = 0;
    this.q25_1 = 1023;
    this.r25_1 = 0;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = new Int32Array(1024);
    while (tmp_0 < 1024) {
      tmp_1[tmp_0] = -1;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.s25_1 = tmp_1;
    var tmp_2 = this;
    var tmp_3 = 0;
    var tmp_4 = new Int32Array(1024);
    while (tmp_3 < 1024) {
      tmp_4[tmp_3] = 0;
      tmp_3 = tmp_3 + 1 | 0;
    }
    tmp_2.t25_1 = tmp_4;
    this.u25_1 = new Int32Array(17);
    this.v25_1 = new Int32Array(17);
    this.w25_1 = new Int32Array(17);
    this.x25_1 = new Int32Array(288);
  }
  v26(reader) {
    reader.m27(10);
    var node = this.q25_1;
    if (true && reader.p27() >= 10) {
      var bits = reader.n27(10);
      var raw = this.s25_1[bits];
      var value = toShort(raw);
      var len = raw >> 16;
      if (len > 0) {
        reader.o27(len);
        if (value === -2) {
          node = this.t25_1[bits];
        } else {
          return value;
        }
      }
    }
    $l$1: do {
      $l$0: do {
        var tmp;
        if (reader.k26()) {
          // Inline function 'korlibs.compression.deflate.HuffmanTree.right' call
          var this_0 = node;
          tmp = this.o25_1[this_0];
        } else {
          // Inline function 'korlibs.compression.deflate.HuffmanTree.left' call
          var this_1 = node;
          tmp = this.n25_1[this_1];
        }
        node = tmp;
      }
       while (false);
      var tmp_0;
      if (!(node === 1023)) {
        // Inline function 'korlibs.compression.deflate.HuffmanTree.value' call
        var this_2 = node;
        tmp_0 = this.m25_1[this_2] === -1;
      } else {
        tmp_0 = false;
      }
    }
     while (tmp_0);
    // Inline function 'korlibs.compression.deflate.HuffmanTree.value' call
    var this_3 = node;
    return this.m25_1[this_3];
  }
  q27(codeLengths, start, end) {
    this.w26(codeLengths, start, end);
    return this;
  }
  k27(codeLengths, start, end, $super) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? codeLengths.length : end;
    return $super === VOID ? this.q27(codeLengths, start, end) : $super.q27.call(this, codeLengths, start, end);
  }
  w26(codeLengths, start, end) {
    var oldOffset = 0;
    var oldCount = 0;
    var ncodes = end - start | 0;
    resetAlloc(this);
    fill(this.u25_1, 0);
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var codeLen = codeLengths[n];
        if (!(0 <= codeLen ? codeLen <= 16 : false)) {
          // Inline function 'kotlin.error' call
          var message = 'Invalid HuffmanTree.codeLengths ' + codeLen;
          throw IllegalStateException.m5(toString(message));
        }
        var _array__4zh2yp = this.u25_1;
        _array__4zh2yp[codeLen] = _array__4zh2yp[codeLen] + 1 | 0;
      }
       while (inductionVariable < end);
    var currentOffset = 0;
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < 16)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var count = this.u25_1[n_0];
        this.v25_1[n_0] = currentOffset;
        this.w25_1[n_0] = currentOffset;
        currentOffset = currentOffset + count | 0;
      }
       while (inductionVariable_0 < 16);
    var inductionVariable_1 = start;
    if (inductionVariable_1 < end)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var codeLen_0 = codeLengths[n_1];
        var _array__4zh2yp_0 = this.w25_1;
        var _unary__edvuaz = _array__4zh2yp_0[codeLen_0];
        _array__4zh2yp_0[codeLen_0] = _unary__edvuaz + 1 | 0;
        this.x25_1[_unary__edvuaz] = n_1 - start | 0;
      }
       while (inductionVariable_1 < end);
    var inductionVariable_2 = 16;
    if (1 <= inductionVariable_2)
      do {
        var i = inductionVariable_2;
        inductionVariable_2 = inductionVariable_2 + -1 | 0;
        var newOffset = this.p25_1;
        var OFFSET = this.v25_1[i];
        var SIZE = this.u25_1[i];
        var inductionVariable_3 = 0;
        if (inductionVariable_3 < SIZE)
          do {
            var j = inductionVariable_3;
            inductionVariable_3 = inductionVariable_3 + 1 | 0;
            allocLeaf(this, this.x25_1[OFFSET + j | 0]);
          }
           while (inductionVariable_3 < SIZE);
        var progression = step(until(0, oldCount), 2);
        var inductionVariable_4 = progression.k2_1;
        var last = progression.l2_1;
        var step_0 = progression.m2_1;
        if (step_0 > 0 && inductionVariable_4 <= last || (step_0 < 0 && last <= inductionVariable_4))
          do {
            var j_0 = inductionVariable_4;
            inductionVariable_4 = inductionVariable_4 + step_0 | 0;
            allocNode(this, oldOffset + j_0 | 0, (oldOffset + j_0 | 0) + 1 | 0);
          }
           while (!(j_0 === last));
        oldOffset = newOffset;
        oldCount = SIZE + (oldCount / 2 | 0) | 0;
        if (oldCount >= 2 && !((oldCount % 2 | 0) === 0)) {
          // Inline function 'kotlin.error' call
          var message_0 = 'This canonical code does not represent a Huffman code tree: ' + oldCount;
          throw IllegalStateException.m5(toString(message_0));
        }
      }
       while (1 <= inductionVariable_2);
    if (!(oldCount === 2)) {
      // Inline function 'kotlin.error' call
      var message_1 = 'This canonical code does not represent a Huffman code tree';
      throw IllegalStateException.m5(toString(message_1));
    }
    this.q25_1 = allocNode(this, this.p25_1 - 2 | 0, this.p25_1 - 1 | 0);
    this.r25_1 = ncodes;
    computeFastLookup(this);
  }
  u26(codeLengths, start, end, $super) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? codeLengths.length : end;
    var tmp;
    if ($super === VOID) {
      this.w26(codeLengths, start, end);
      tmp = Unit_instance;
    } else {
      tmp = $super.w26.call(this, codeLengths, start, end);
    }
    return tmp;
  }
}
class SlidingWindow {
  constructor(nbits) {
    this.d27_1 = nbits;
    this.e27_1 = new Int8Array(1 << this.d27_1);
    this.f27_1 = this.e27_1.length - 1 | 0;
    this.g27_1 = 0;
  }
  i1(offset) {
    return this.e27_1[(this.g27_1 - offset | 0) & this.f27_1] & 255;
  }
  h27(offset) {
    return this.j27(this.i1(offset));
  }
  j27(value) {
    this.e27_1[this.g27_1] = toByte(value);
    this.g27_1 = (this.g27_1 + 1 | 0) & this.f27_1;
    return value;
  }
  i27(bytes, offset, len) {
    var inductionVariable = 0;
    if (inductionVariable < len)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.j27(get_unsigned(bytes[offset + n | 0]));
      }
       while (inductionVariable < len);
  }
}
class FixedSizeByteArrayBuilder {
  static r27(data) {
    var $this = createThis(this);
    $this.g26_1 = data;
    $this.h26_1 = 0;
    return $this;
  }
  static c27(size) {
    return this.r27(new Int8Array(size));
  }
  o3() {
    this.h26_1 = 0;
  }
  ov(array, offset, len) {
    arraycopy(array, offset, this.g26_1, this.h26_1, len);
    this.h26_1 = this.h26_1 + len | 0;
  }
  qv(v) {
    var _unary__edvuaz = this.h26_1;
    this.h26_1 = _unary__edvuaz + 1 | 0;
    this.g26_1[_unary__edvuaz] = v;
  }
}
class Companion_2 {
  s27(Models, startIndex, rangeDecoder, NumBitLevels) {
    var m = 1;
    var symbol = 0;
    var inductionVariable = 0;
    if (inductionVariable < NumBitLevels)
      do {
        var bitIndex = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var bit = rangeDecoder.w27(Models, startIndex + m | 0);
        m = m << 1;
        m = m + bit | 0;
        symbol = symbol | bit << bitIndex;
      }
       while (inductionVariable < NumBitLevels);
    return symbol;
  }
}
class Companion_3 {
  constructor() {
    this.x27_1 = -16777216;
    this.y27_1 = 11;
    this.z27_1 = 2048;
    this.a28_1 = 5;
  }
  b28(probs) {
    var inductionVariable = 0;
    var last = probs.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        probs[i] = 1024;
      }
       while (inductionVariable <= last);
  }
}
class Decoder2 {
  constructor($outer) {
    this.d28_1 = $outer;
    this.c28_1 = new Int16Array(768);
  }
  e28() {
    Companion_instance_3.b28(this.c28_1);
  }
  f28(rangeDecoder) {
    var symbol = 1;
    do {
      symbol = symbol << 1 | rangeDecoder.w27(this.c28_1, symbol);
    }
     while (symbol < 256);
    return toByte(symbol);
  }
  g28(rangeDecoder, matchByte) {
    var matchByte_0 = matchByte;
    var symbol = 1;
    $l$loop: do {
      var matchBit = shr(matchByte_0, 7) & 1;
      matchByte_0 = toByte(shl(matchByte_0, 1));
      var bit = rangeDecoder.w27(this.c28_1, ((1 + matchBit | 0) << 8) + symbol | 0);
      symbol = symbol << 1 | bit;
      if (!(matchBit === bit)) {
        while (symbol < 256)
          symbol = symbol << 1 | rangeDecoder.w27(this.c28_1, symbol);
        break $l$loop;
      }
    }
     while (symbol < 256);
    return toByte(symbol);
  }
}
class LenDecoder {
  constructor($outer) {
    this.m28_1 = $outer;
    this.h28_1 = new Int16Array(2);
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.i28_1 = fillArrayVal(Array(16), null);
    var tmp_0 = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp_0.j28_1 = fillArrayVal(Array(16), null);
    this.k28_1 = new BitTreeDecoder(8);
    this.l28_1 = 0;
  }
  n28(numPosStates) {
    while (this.l28_1 < numPosStates) {
      this.i28_1[this.l28_1] = new BitTreeDecoder(3);
      this.j28_1[this.l28_1] = new BitTreeDecoder(3);
      this.l28_1 = this.l28_1 + 1 | 0;
    }
  }
  e28() {
    Companion_instance_3.b28(this.h28_1);
    var inductionVariable = 0;
    var last = this.l28_1;
    if (inductionVariable < last)
      do {
        var posState = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        ensureNotNull(this.i28_1[posState]).e28();
        ensureNotNull(this.j28_1[posState]).e28();
      }
       while (inductionVariable < last);
    this.k28_1.e28();
  }
  q28(rangeDecoder, posState) {
    if (rangeDecoder.w27(this.h28_1, 0) === 0)
      return ensureNotNull(this.i28_1[posState]).r28(rangeDecoder);
    var symbol = 8;
    symbol = symbol + (rangeDecoder.w27(this.h28_1, 1) === 0 ? ensureNotNull(this.j28_1[posState]).r28(rangeDecoder) : 8 + this.k28_1.r28(rangeDecoder) | 0) | 0;
    return symbol;
  }
}
class LiteralDecoder {
  constructor($outer) {
    this.w28_1 = $outer;
    this.s28_1 = null;
    this.t28_1 = 0;
    this.u28_1 = 0;
    this.v28_1 = 0;
  }
  x28(numPosBits, numPrevBits) {
    if (!(this.s28_1 == null) && this.t28_1 === numPrevBits && this.u28_1 === numPosBits)
      return Unit_instance;
    this.u28_1 = numPosBits;
    this.v28_1 = (1 << numPosBits) - 1 | 0;
    this.t28_1 = numPrevBits;
    var numStates = 1 << (this.t28_1 + this.u28_1 | 0);
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(numStates), null);
    while (tmp_0 < numStates) {
      tmp_1[tmp_0] = new Decoder2(this);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.s28_1 = tmp_1;
  }
  e28() {
    var numStates = 1 << (this.t28_1 + this.u28_1 | 0);
    var inductionVariable = 0;
    if (inductionVariable < numStates)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        ensureNotNull(this.s28_1)[i].e28();
      }
       while (inductionVariable < numStates);
  }
  y28(pos, prevByte) {
    return ensureNotNull(this.s28_1)[((pos & this.v28_1) << this.t28_1) + (and(prevByte, 255) >>> (8 - this.t28_1 | 0) | 0) | 0];
  }
}
class BitTreeDecoder {
  constructor(numBitLevels) {
    this.o28_1 = numBitLevels;
    this.p28_1 = new Int16Array(1 << this.o28_1);
  }
  e28() {
    Companion_instance_3.b28(this.p28_1);
  }
  r28(rangeDecoder) {
    var m = 1;
    var inductionVariable = this.o28_1;
    if (1 <= inductionVariable)
      do {
        var bitIndex = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        m = (m << 1) + rangeDecoder.w27(this.p28_1, m) | 0;
      }
       while (1 <= inductionVariable);
    return m - (1 << this.o28_1) | 0;
  }
  v29(rangeDecoder) {
    var m = 1;
    var symbol = 0;
    var inductionVariable = 0;
    var last = this.o28_1;
    if (inductionVariable < last)
      do {
        var bitIndex = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var bit = rangeDecoder.w27(this.p28_1, m);
        m = m << 1;
        m = m + bit | 0;
        symbol = symbol | bit << bitIndex;
      }
       while (inductionVariable < last);
    return symbol;
  }
}
class RangeDecoder {
  constructor() {
    this.t27_1 = 0;
    this.u27_1 = 0;
    this.v27_1 = null;
  }
  w29(stream) {
    this.v27_1 = stream;
  }
  x29() {
    this.v27_1 = null;
  }
  e28() {
    this.u27_1 = 0;
    this.t27_1 = -1;
    var inductionVariable = 0;
    if (inductionVariable <= 4)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.u27_1 = this.u27_1 << 8 | ensureNotNull(this.v27_1).p1v();
      }
       while (inductionVariable <= 4);
  }
  y29(numTotalBits) {
    var result = 0;
    var inductionVariable = numTotalBits;
    if (1 <= inductionVariable)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        this.t27_1 = this.t27_1 >>> 1 | 0;
        var t = (this.u27_1 - this.t27_1 | 0) >>> 31 | 0;
        this.u27_1 = this.u27_1 - (this.t27_1 & (t - 1 | 0)) | 0;
        result = result << 1 | (1 - t | 0);
        if ((this.t27_1 & -16777216) === 0) {
          this.u27_1 = this.u27_1 << 8 | ensureNotNull(this.v27_1).p1v();
          this.t27_1 = this.t27_1 << 8;
        }
      }
       while (1 <= inductionVariable);
    return result;
  }
  w27(probs, index) {
    var prob = probs[index];
    var newBound = imul(this.t27_1 >>> 11 | 0, prob);
    if ((this.u27_1 ^ -2147483648) < (newBound ^ -2147483648)) {
      this.t27_1 = newBound;
      probs[index] = toShort(prob + ((2048 - prob | 0) >>> 5 | 0) | 0);
      if ((this.t27_1 & -16777216) === 0) {
        this.u27_1 = this.u27_1 << 8 | ensureNotNull(this.v27_1).p1v();
        this.t27_1 = this.t27_1 << 8;
      }
      return 0;
    } else {
      this.t27_1 = this.t27_1 - newBound | 0;
      this.u27_1 = this.u27_1 - newBound | 0;
      probs[index] = toShort(prob - (prob >>> 5 | 0) | 0);
      if ((this.t27_1 & -16777216) === 0) {
        this.u27_1 = this.u27_1 << 8 | ensureNotNull(this.v27_1).p1v();
        this.t27_1 = this.t27_1 << 8;
      }
      return 1;
    }
  }
}
class LzmaBase {
  constructor() {
    this.z29_1 = 4;
    this.a2a_1 = 12;
    this.b2a_1 = 6;
    this.c2a_1 = 0;
    this.d2a_1 = 2;
    this.e2a_1 = 4;
    this.f2a_1 = 2;
    this.g2a_1 = 4;
    this.h2a_1 = 16;
    this.i2a_1 = 15;
    this.j2a_1 = 4;
    this.k2a_1 = 14;
    this.l2a_1 = 128;
    this.m2a_1 = 4;
    this.n2a_1 = 8;
    this.o2a_1 = 4;
    this.p2a_1 = 16;
    this.q2a_1 = 4;
    this.r2a_1 = 16;
    this.s2a_1 = 3;
    this.t2a_1 = 3;
    this.u2a_1 = 8;
    this.v2a_1 = 8;
    this.w2a_1 = 8;
    this.x2a_1 = 272;
    this.y2a_1 = 273;
  }
  z2a() {
    return 0;
  }
  a2b(index) {
    return index < 4 ? 0 : index < 10 ? index - 3 | 0 : index - 6 | 0;
  }
  b2b(index) {
    return index < 7 ? 7 : 10;
  }
  c2b(index) {
    return index < 7 ? 8 : 11;
  }
  d2b(index) {
    return index < 7 ? 9 : 11;
  }
  e2b(index) {
    return index < 7;
  }
  f2b(len) {
    var len_0 = len;
    len_0 = len_0 - 2 | 0;
    return len_0 < 4 ? len_0 : 3;
  }
}
class LzmaDecoder {
  constructor() {
    this.z28_1 = new LzOutWindow();
    this.a29_1 = new RangeDecoder();
    this.b29_1 = new Int16Array(192);
    this.c29_1 = new Int16Array(12);
    this.d29_1 = new Int16Array(12);
    this.e29_1 = new Int16Array(12);
    this.f29_1 = new Int16Array(12);
    this.g29_1 = new Int16Array(192);
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.h29_1 = fillArrayVal(Array(4), null);
    this.i29_1 = new Int16Array(114);
    this.j29_1 = new BitTreeDecoder(4);
    this.k29_1 = new LenDecoder(this);
    this.l29_1 = new LenDecoder(this);
    this.m29_1 = new LiteralDecoder(this);
    this.n29_1 = -1;
    this.o29_1 = -1;
    this.p29_1 = 0;
    var inductionVariable = 0;
    if (inductionVariable < 4)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.h29_1[i] = new BitTreeDecoder(6);
      }
       while (inductionVariable < 4);
  }
  g2b() {
    this.z28_1.h2b(false);
    Companion_instance_3.b28(this.b29_1);
    Companion_instance_3.b28(this.g29_1);
    Companion_instance_3.b28(this.c29_1);
    Companion_instance_3.b28(this.d29_1);
    Companion_instance_3.b28(this.e29_1);
    Companion_instance_3.b28(this.f29_1);
    Companion_instance_3.b28(this.i29_1);
    this.m29_1.e28();
    var inductionVariable = 0;
    if (inductionVariable < 4)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        ensureNotNull(this.h29_1[i]).e28();
      }
       while (inductionVariable < 4);
    this.k29_1.e28();
    this.l29_1.e28();
    this.j29_1.e28();
    this.a29_1.e28();
  }
  i2b(inStream, outStream, outSize) {
    this.a29_1.w29(inStream);
    this.z28_1.j2b(outStream);
    this.g2b();
    var state = LzmaBase_instance.z2a();
    var rep0 = 0;
    var rep1 = 0;
    var rep2 = 0;
    var rep3 = 0;
    var nowPos64 = new Long(0, 0);
    var prevByte = 0;
    $l$loop: while (outSize.g2(new Long(0, 0)) < 0 || nowPos64.g2(outSize) < 0) {
      var posState = nowPos64.q2() & this.p29_1;
      if (this.a29_1.w27(this.b29_1, (state << 4) + posState | 0) === 0) {
        var decoder2 = this.m29_1.y28(nowPos64.q2(), prevByte);
        prevByte = !LzmaBase_instance.e2b(state) ? decoder2.g28(this.a29_1, this.z28_1.l2b(rep0)) : decoder2.f28(this.a29_1);
        this.z28_1.m2b(prevByte);
        state = LzmaBase_instance.a2b(state);
        nowPos64 = nowPos64.g4();
      } else {
        var len;
        if (this.a29_1.w27(this.c29_1, state) === 1) {
          len = 0;
          if (this.a29_1.w27(this.d29_1, state) === 0) {
            if (this.a29_1.w27(this.g29_1, (state << 4) + posState | 0) === 0) {
              state = LzmaBase_instance.d2b(state);
              len = 1;
            }
          } else {
            var distance;
            if (this.a29_1.w27(this.e29_1, state) === 0)
              distance = rep1;
            else {
              if (this.a29_1.w27(this.f29_1, state) === 0)
                distance = rep2;
              else {
                distance = rep3;
                rep3 = rep2;
              }
              rep2 = rep1;
            }
            rep1 = rep0;
            rep0 = distance;
          }
          if (len === 0) {
            len = this.l29_1.q28(this.a29_1, posState) + 2 | 0;
            state = LzmaBase_instance.c2b(state);
          }
        } else {
          rep3 = rep2;
          rep2 = rep1;
          rep1 = rep0;
          len = 2 + this.k29_1.q28(this.a29_1, posState) | 0;
          state = LzmaBase_instance.b2b(state);
          var posSlot = ensureNotNull(this.h29_1[LzmaBase_instance.f2b(len)]).r28(this.a29_1);
          if (posSlot >= 4) {
            var numDirectBits = (posSlot >> 1) - 1 | 0;
            rep0 = (2 | posSlot & 1) << numDirectBits;
            if (posSlot < 14)
              rep0 = rep0 + Companion_instance_2.s27(this.i29_1, (rep0 - posSlot | 0) - 1 | 0, this.a29_1, numDirectBits) | 0;
            else {
              rep0 = rep0 + (this.a29_1.y29(numDirectBits - 4 | 0) << 4) | 0;
              rep0 = rep0 + this.j29_1.v29(this.a29_1) | 0;
              if (rep0 < 0) {
                if (rep0 === -1)
                  break $l$loop;
                return false;
              }
            }
          } else
            rep0 = posSlot;
        }
        if (toLong(rep0).g2(nowPos64) >= 0 || rep0 >= this.o29_1) {
          return false;
        }
        this.z28_1.k2b(rep0, len);
        nowPos64 = nowPos64.c4(toLong(len));
        prevByte = this.z28_1.l2b(0);
      }
    }
    this.z28_1.fd();
    this.z28_1.x29();
    this.a29_1.x29();
    return true;
  }
  n2b(properties) {
    if (properties.length < 5)
      return false;
    var val = and(properties[0], 255);
    var lc = val % 9 | 0;
    var remainder = val / 9 | 0;
    var lp = remainder % 5 | 0;
    var pb = remainder / 5 | 0;
    var dictionarySize = 0;
    var inductionVariable = 0;
    if (inductionVariable <= 3)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        dictionarySize = dictionarySize + ((properties[1 + i | 0] & 255) << imul(i, 8)) | 0;
      }
       while (inductionVariable <= 3);
    return !setLcLpPb(this, lc, lp, pb) ? false : setDictionarySize(this, dictionarySize);
  }
}
class LzOutWindow {
  constructor() {
    this.q29_1 = null;
    this.r29_1 = 0;
    this.s29_1 = 0;
    this.t29_1 = 0;
    this.u29_1 = null;
  }
  n28(windowSize) {
    if (this.q29_1 == null || !(this.s29_1 === windowSize))
      this.q29_1 = new Int8Array(windowSize);
    this.s29_1 = windowSize;
    this.r29_1 = 0;
    this.t29_1 = 0;
  }
  j2b(stream) {
    this.x29();
    this.u29_1 = stream;
  }
  x29() {
    this.fd();
    this.u29_1 = null;
  }
  h2b(solid) {
    if (!solid) {
      this.t29_1 = 0;
      this.r29_1 = 0;
    }
  }
  fd() {
    var size = this.r29_1 - this.t29_1 | 0;
    if (size === 0)
      return Unit_instance;
    ensureNotNull(this.u29_1).p12(ensureNotNull(this.q29_1), this.t29_1, size);
    if (this.r29_1 >= this.s29_1)
      this.r29_1 = 0;
    this.t29_1 = this.r29_1;
  }
  k2b(distance, len) {
    var llen = len;
    var pos = (this.r29_1 - distance | 0) - 1 | 0;
    if (pos < 0)
      pos = pos + this.s29_1 | 0;
    while (!(llen === 0)) {
      if (pos >= this.s29_1)
        pos = 0;
      var tmp = ensureNotNull(this.q29_1);
      var _unary__edvuaz = this.r29_1;
      this.r29_1 = _unary__edvuaz + 1 | 0;
      var tmp_0 = ensureNotNull(this.q29_1);
      var _unary__edvuaz_0 = pos;
      pos = _unary__edvuaz_0 + 1 | 0;
      tmp[_unary__edvuaz] = tmp_0[_unary__edvuaz_0];
      if (this.r29_1 >= this.s29_1) {
        this.fd();
      }
      llen = llen - 1 | 0;
    }
  }
  m2b(b) {
    var tmp = ensureNotNull(this.q29_1);
    var _unary__edvuaz = this.r29_1;
    this.r29_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = b;
    if (this.r29_1 >= this.s29_1) {
      this.fd();
    }
  }
  l2b(distance) {
    var pos = (this.r29_1 - distance | 0) - 1 | 0;
    if (pos < 0)
      pos = pos + this.s29_1 | 0;
    return ensureNotNull(this.q29_1)[pos];
  }
}
class CompressionMethod {}
function uncompress(reader, out, $completion) {
  unsupported();
}
class uncompressStream$slambda {
  constructor($this_uncompressStream, $input) {
    this.s2b_1 = $this_uncompressStream;
    this.t2b_1 = $input;
  }
  u2b(output, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, output, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.u2b((!(p1 == null) ? isInterface(p1, AsyncOutputStream) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class uncompress$slambda {
  constructor($this_uncompress, $i, $o) {
    this.v2b_1 = $this_uncompress;
    this.w2b_1 = $i;
    this.x2b_1 = $o;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_0(this, $completion), $completion);
  }
}
class Deflate$4 {
  constructor($windowBits) {
    this.y2b_1 = DeflaterNative($windowBits);
  }
  o2b(reader, out, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_uncompress__ej2bxp_1(this, reader, out, $completion), $completion);
  }
}
class toDeflater$1 {
  constructor($this_toDeflater) {
    this.z2b_1 = $this_toDeflater;
  }
  z25() {
    return this.z2b_1.b2c_1;
  }
  a26() {
    return this.z2b_1.c2c_1;
  }
  p27() {
    return this.z2b_1.e2c_1;
  }
  m27(bits) {
    return this.z2b_1.m27(bits);
  }
  a27(count, $completion) {
    return this.z2b_1.l2c(count, VOID, $completion);
  }
  z26() {
    return this.z2b_1.z26();
  }
  k26() {
    return this.z2b_1.k26();
  }
  o27(bits) {
    return this.z2b_1.o27(bits);
  }
  n27(count) {
    return this.z2b_1.n27(count);
  }
  l26(count) {
    return this.z2b_1.l26(count);
  }
  j26($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_prepareBigChunkIfRequired__yhuuys(this, $completion), $completion);
  }
}
class toDeflater$2 {
  constructor($this_toDeflater) {
    this.m2c_1 = $this_toDeflater;
  }
  c1q(bytes, offset, size, $completion) {
    return this.m2c_1.c1q(bytes, offset, size, $completion);
  }
}
class GZIPBase {
  constructor(checkCrc, deflater) {
    this.n2c_1 = checkCrc;
    this.o2c_1 = deflater;
  }
  toString() {
    return 'GZIPBase(' + this.n2c_1 + ', ' + toString(this.o2c_1) + ')';
  }
  o2b(reader, out, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_uncompress__ej2bxp_2(this, reader, out, $completion), $completion);
  }
}
class GZIP extends GZIPBase {
  constructor(deflater) {
    Companion_getInstance_4();
    super(true, deflater);
  }
}
class Companion_4 extends GZIP {
  constructor() {
    Companion_instance_4 = null;
    super(GZIP$Companion$_init_$lambda_4zsoc7);
    Companion_instance_4 = this;
  }
  toString() {
    return 'GZIP';
  }
}
class GZIPBase$uncompress$2 {
  constructor($out, $ccrc32, $csize) {
    this.q2c_1 = $out;
    this.r2c_1 = $ccrc32;
    this.s2c_1 = $csize;
    this.p2c_1 = $out;
  }
  f1q($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_close__nh2uv0(this, $completion), $completion);
  }
  c1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l(this, buffer, offset, len, $completion), $completion);
  }
}
class ZLib {
  constructor(deflater) {
    Companion_getInstance_5();
    this.y2c_1 = deflater;
  }
  o2b(reader, out, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_uncompress__ej2bxp_3(this, reader, out, $completion), $completion);
  }
}
class Companion_5 extends ZLib {
  constructor() {
    Companion_instance_5 = null;
    super(ZLib$Companion$_init_$lambda_nrgg4o);
    Companion_instance_5 = this;
  }
}
class ZLib$uncompress$2 {
  constructor($o, $chash) {
    this.w2c_1 = $o;
    this.x2c_1 = $chash;
  }
  f1q($completion) {
    return this.w2c_1.f1q($completion);
  }
  c1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l_0(this, buffer, offset, len, $completion), $completion);
  }
}
class Lzma$toLzmaInput$1 {
  constructor($this_toLzmaInput) {
    this.c2d_1 = $this_toLzmaInput;
  }
  p1v() {
    return this.c2d_1.p1v();
  }
}
class Lzma$toLzmaOutput$1 {
  constructor($this_toLzmaOutput) {
    this.d2d_1 = $this_toLzmaOutput;
  }
  p12(bytes, offset, size) {
    return this.d2d_1.p12(bytes, offset, size);
  }
}
class Lzma {
  o2b(reader, out, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_uncompress__ej2bxp_4(this, reader, out, $completion), $completion);
  }
  a2d(_this__u8e3s4) {
    return new Lzma$toLzmaInput$1(_this__u8e3s4);
  }
  b2d(_this__u8e3s4) {
    return new Lzma$toLzmaOutput$1(_this__u8e3s4);
  }
}
class Companion_6 {
  constructor() {
    this.p2b_1 = 8388608;
    this.q2b_1 = 32768;
  }
  r2b(s, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_forInput__mjt6c9(this, s, $completion), $completion);
  }
}
class BitReader {
  constructor(s, bigChunkSize, readWithSize) {
    bigChunkSize = bigChunkSize === VOID ? 8388608 : bigChunkSize;
    readWithSize = readWithSize === VOID ? 32768 : readWithSize;
    this.a2c_1 = s;
    this.b2c_1 = bigChunkSize;
    this.c2c_1 = readWithSize;
    this.d2c_1 = 0;
    this.e2c_1 = 0;
    this.f2c_1 = new SimpleRingBuffer(ilog2(get_nextPowerOfTwo(this.b2c_1)));
    this.g2c_1 = 0.0;
    this.h2c_1 = 0.0;
    this.i2c_1 = new Int8Array(4);
  }
  toString() {
    return 'BitReader(' + toString(this.a2c_1) + ', bigChunkSize=' + this.b2c_1 + ', readWithSize=' + this.c2c_1 + ')';
  }
  k2c() {
    return this.f2c_1.a12_1 < this.c2c_1;
  }
  j2c(expectedBytes, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_prepareBytesUpTo__5zpo26(this, expectedBytes, $completion), $completion);
  }
  m27(bitcount) {
    while (this.e2c_1 < bitcount) {
      var tmp = this;
      var tmp_0 = this.d2c_1;
      // Inline function 'korlibs.io.compression.util.BitReader._su8' call
      this.g2c_1 = this.g2c_1 + 1;
      tmp.d2c_1 = tmp_0 | this.f2c_1.y12() << this.e2c_1;
      this.e2c_1 = this.e2c_1 + 8 | 0;
    }
  }
  n27(bitcount) {
    return this.d2c_1 & ((1 << bitcount) - 1 | 0);
  }
  o27(bitcount) {
    this.d2c_1 = this.d2c_1 >>> bitcount | 0;
    this.e2c_1 = this.e2c_1 - bitcount | 0;
  }
  l26(bitcount) {
    this.m27(bitcount);
    var readed = this.n27(bitcount);
    this.o27(bitcount);
    return readed;
  }
  k26() {
    return !(this.l26(1) === 0);
  }
  f2d(count, out) {
    var offset = 0;
    var count_0 = count;
    if (this.e2c_1 >= 8) {
      if (!((this.e2c_1 % 8 | 0) === 0)) {
        var bits = this.e2c_1 % 8 | 0;
        this.o27(bits);
      }
      while (this.e2c_1 >= 8) {
        var byte = toByte(this.l26(8));
        var _unary__edvuaz = offset;
        offset = _unary__edvuaz + 1 | 0;
        out[_unary__edvuaz] = byte;
        count_0 = count_0 - 1 | 0;
      }
    }
    // Inline function 'korlibs.io.compression.util.BitReader.discardBits' call
    this.d2c_1 = 0;
    this.e2c_1 = 0;
    var readCount = this.f2c_1.k12(out, offset, count_0);
    if (readCount > 0)
      this.g2c_1 = this.g2c_1 + readCount;
  }
  e2d(count, out) {
    this.f2d(count, out);
    return out;
  }
  t2c() {
    // Inline function 'korlibs.io.compression.util.BitReader._su8' call
    // Inline function 'korlibs.io.compression.util.BitReader.discardBits' call
    this.d2c_1 = 0;
    this.e2c_1 = 0;
    this.g2c_1 = this.g2c_1 + 1;
    return this.f2c_1.y12();
  }
  z26() {
    this.f2d(2, this.i2c_1);
    return getU16LE(this.i2c_1, 0);
  }
  u2c() {
    this.f2d(4, this.i2c_1);
    return getS32LE(this.i2c_1, 0);
  }
  z2c() {
    this.f2d(4, this.i2c_1);
    return getS32BE(this.i2c_1, 0);
  }
  g2d(count, out, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_abytes__gje57m(this, count, out, $completion), $completion);
  }
  l2c(count, out, $completion, $super) {
    out = out === VOID ? new Int8Array(count) : out;
    return $super === VOID ? this.g2d(count, out, $completion) : $super.g2d.call(this, count, out, $completion);
  }
  e1q(buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe(this, buffer, offset, len, $completion), $completion);
  }
  f1q($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_close__nh2uv0_0(this, $completion), $completion);
  }
  v2c($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_strz__qhnc5d(this, $completion), $completion);
  }
  q1t($completion) {
    return numberToLong(this.g2c_1);
  }
  z1s($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_hasLength__5f5ox8(this, $completion), $completion);
  }
  y1s($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_getLength__lvva94(this, $completion), $completion);
  }
}
//endregion
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
var Companion_instance_0;
function Companion_getInstance_0() {
  return Companion_instance_0;
}
function resetAlloc($this) {
  $this.p25_1 = 0;
}
function alloc($this, value, left, right) {
  // Inline function 'kotlin.apply' call
  var _unary__edvuaz = $this.p25_1;
  $this.p25_1 = _unary__edvuaz + 1 | 0;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.compression.deflate.HuffmanTree.alloc.<anonymous>' call
  $this.m25_1[_unary__edvuaz] = value;
  $this.n25_1[_unary__edvuaz] = left;
  $this.o25_1[_unary__edvuaz] = right;
  return _unary__edvuaz;
}
function allocLeaf($this, value) {
  return alloc($this, value, 1023, 1023);
}
function allocNode($this, left, right) {
  return alloc($this, -1, left, right);
}
function computeFastLookup($this) {
  fill($this.s25_1, -1);
  computeEncodedValues($this, $this.q25_1, 0, 0);
}
function computeEncodedValues($this, node, encoded, encodedBits) {
  // Inline function 'korlibs.compression.deflate.HuffmanTree.value' call
  if ($this.m25_1[node] === -1) {
    if (encodedBits < 10) {
      // Inline function 'korlibs.compression.deflate.HuffmanTree.left' call
      var tmp$ret$1 = $this.n25_1[node];
      computeEncodedValues($this, tmp$ret$1, encoded, encodedBits + 1 | 0);
      // Inline function 'korlibs.compression.deflate.HuffmanTree.right' call
      var tmp$ret$2 = $this.o25_1[node];
      computeEncodedValues($this, tmp$ret$2, encoded | 1 << encodedBits, encodedBits + 1 | 0);
    } else {
      writeVariants($this, encoded, encodedBits, node, -2);
    }
  } else {
    // Inline function 'korlibs.compression.deflate.HuffmanTree.value' call
    var tmp$ret$3 = $this.m25_1[node];
    writeVariants($this, encoded, encodedBits, node, tmp$ret$3);
  }
}
function writeVariants($this, encoded, encodedBits, node, nvalue) {
  var encodedInfo = nvalue & 65535 | encodedBits << 16;
  var rangeCount = 1 << (10 - encodedBits | 0);
  var inductionVariable = 0;
  if (inductionVariable < rangeCount)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var i = encoded | n << encodedBits;
      $this.s25_1[i] = encodedInfo;
      $this.t25_1[i] = node;
    }
     while (inductionVariable < rangeCount);
}
function *_generator_uncompress__ej2bxp($this, reader, out, $completion) {
  var sout = new SlidingWindowWithOutput(new SlidingWindow($this.y25_1), out, reader.z25(), reader.a26());
  var lastBlock = false;
  var tempTree = new HuffmanTree();
  var tempDist = new HuffmanTree();
  var codeLenCodeLen = new Int32Array(32);
  var lengths = new Int32Array(512);
  while (!lastBlock) {
    // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flushIfRequired' call
    if (false || sout.i26()) {
      // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flush' call
      var tmp = sout.c26_1.c1q(sout.f26_1.g26_1, 0, sout.f26_1.h26_1, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
      sout.f26_1.o3();
    }
    var tmp_0 = reader.j26($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    lastBlock = reader.k26();
    var blockType = reader.l26(2);
    if (!(0 <= blockType ? blockType <= 2 : false)) {
      // Inline function 'kotlin.error' call
      var message = 'invalid bit';
      throw IllegalStateException.m5(toString(message));
    }
    if (blockType === 0) {
      var len = reader.z26();
      var nlen = reader.z26();
      var nnlen = ~nlen & 65535;
      if (!(len === nnlen)) {
        // Inline function 'kotlin.error' call
        var message_0 = 'Invalid deflate stream: len(' + len + ') != ~nlen(' + nnlen + ') :: nlen=' + nlen;
        throw IllegalStateException.m5(toString(message_0));
      }
      var tmp_1 = reader.a27(len, $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      var bytes = tmp_1;
      sout.b27(bytes, 0, len);
      // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flushIfRequired' call
      if (false || sout.i26()) {
        // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flush' call
        var tmp_2 = sout.c26_1.c1q(sout.f26_1.g26_1, 0, sout.f26_1.h26_1, $completion);
        if (tmp_2 === get_COROUTINE_SUSPENDED())
          tmp_2 = yield tmp_2;
        sout.f26_1.o3();
      }
    } else {
      var tree;
      var dist;
      if (blockType === 1) {
        tree = Companion_getInstance_1().n26_1;
        dist = Companion_getInstance_1().o26_1;
      } else {
        var hlit = reader.l26(5) + 257 | 0;
        var hdist = reader.l26(5) + 1 | 0;
        var hclen = reader.l26(4) + 4 | 0;
        fill(codeLenCodeLen, 0);
        var inductionVariable = 0;
        if (inductionVariable < hclen)
          do {
            var i = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            codeLenCodeLen[Companion_getInstance_1().t26_1[i]] = reader.l26(3);
          }
           while (inductionVariable < hclen);
        tempTree.u26(codeLenCodeLen);
        var codeLen = tempTree;
        var hlithdist = hlit + hdist | 0;
        var n = 0;
        fill(lengths, 0);
        while (n < hlithdist) {
          // Inline function 'korlibs.compression.deflate.DeflaterPortable.read' call
          var value = codeLen.v26(reader);
          if (!(0 <= value ? value <= 18 : false)) {
            // Inline function 'kotlin.error' call
            throw IllegalStateException.m5(toString('Invalid'));
          }
          var len_0;
          switch (value) {
            case 16:
              len_0 = reader.l26(2) + 3 | 0;
              break;
            case 17:
              len_0 = reader.l26(3) + 3 | 0;
              break;
            case 18:
              len_0 = reader.l26(7) + 11 | 0;
              break;
            default:
              len_0 = 1;
              break;
          }
          var vv;
          switch (value) {
            case 16:
              vv = lengths[n - 1 | 0];
              break;
            case 17:
              vv = 0;
              break;
            case 18:
              vv = 0;
              break;
            default:
              vv = value;
              break;
          }
          fill(lengths, vv, n, n + len_0 | 0);
          n = n + len_0 | 0;
        }
        tempTree.w26(lengths, 0, hlit);
        tempDist.w26(lengths, hlit, hlithdist);
        tree = tempTree;
        dist = tempDist;
      }
      $l$loop: while (true) {
        var tmp_3 = reader.j26($completion);
        if (tmp_3 === get_COROUTINE_SUSPENDED())
          tmp_3 = yield tmp_3;
        var value_0 = tree.v26(reader);
        if (value_0 === 256)
          break $l$loop;
        if (value_0 < 256) {
          sout.y26(toByte(value_0));
        } else {
          var zlenof = value_0 - 257 | 0;
          var lengthExtra = reader.l26(Companion_getInstance_1().p26_1[zlenof]);
          // Inline function 'korlibs.compression.deflate.DeflaterPortable.read' call
          var distanceData = dist.v26(reader);
          var distanceExtra = reader.l26(Companion_getInstance_1().r26_1[distanceData]);
          var distance = Companion_getInstance_1().s26_1[distanceData] + distanceExtra | 0;
          var length = Companion_getInstance_1().q26_1[zlenof] + lengthExtra | 0;
          sout.x26(distance, length);
        }
        // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flushIfRequired' call
        if (false || sout.i26()) {
          // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flush' call
          var tmp_4 = sout.c26_1.c1q(sout.f26_1.g26_1, 0, sout.f26_1.h26_1, $completion);
          if (tmp_4 === get_COROUTINE_SUSPENDED())
            tmp_4 = yield tmp_4;
          sout.f26_1.o3();
        }
      }
    }
  }
  // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flushIfRequired' call
  if (true || sout.i26()) {
    // Inline function 'korlibs.compression.deflate.SlidingWindowWithOutput.flush' call
    var tmp_5 = sout.c26_1.c1q(sout.f26_1.g26_1, 0, sout.f26_1.h26_1, $completion);
    if (tmp_5 === get_COROUTINE_SUSPENDED())
      tmp_5 = yield tmp_5;
    sout.f26_1.o3();
  }
  return Unit_instance;
}
var Companion_instance_1;
function Companion_getInstance_1() {
  if (Companion_instance_1 === VOID)
    new Companion_1();
  return Companion_instance_1;
}
function get_unsigned(_this__u8e3s4) {
  return _this__u8e3s4 & 255;
}
function arraycopy(src, srcPos, dst, dstPos, size) {
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = srcPos + size | 0;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = src;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
}
var Companion_instance_2;
function Companion_getInstance_2() {
  return Companion_instance_2;
}
var Companion_instance_3;
function Companion_getInstance_3() {
  return Companion_instance_3;
}
function setDictionarySize($this, dictionarySize) {
  if (dictionarySize < 0)
    return false;
  if (!($this.n29_1 === dictionarySize)) {
    $this.n29_1 = dictionarySize;
    var tmp = $this;
    // Inline function 'kotlin.math.max' call
    var a = $this.n29_1;
    tmp.o29_1 = Math.max(a, 1);
    var tmp_0 = $this.z28_1;
    // Inline function 'kotlin.math.max' call
    var a_0 = $this.o29_1;
    var tmp$ret$1 = Math.max(a_0, 4096);
    tmp_0.n28(tmp$ret$1);
  }
  return true;
}
function setLcLpPb($this, lc, lp, pb) {
  if (lc > 8 || lp > 4 || pb > 4)
    return false;
  $this.m29_1.x28(lp, lc);
  var numPosStates = 1 << pb;
  $this.k29_1.n28(numPosStates);
  $this.l29_1.n28(numPosStates);
  $this.p29_1 = numPosStates - 1 | 0;
  return true;
}
var LzmaBase_instance;
function LzmaBase_getInstance() {
  return LzmaBase_instance;
}
function shr(_this__u8e3s4, that) {
  return _this__u8e3s4 >> that;
}
function shl(_this__u8e3s4, that) {
  return _this__u8e3s4 << that;
}
function and(_this__u8e3s4, mask) {
  return _this__u8e3s4 & mask;
}
function uncompressed(_this__u8e3s4, method, bufferSize, $completion) {
  bufferSize = bufferSize === VOID ? 8388608 : bufferSize;
  return uncompressStream(method, _this__u8e3s4, bufferSize, $completion);
}
function uncompress_0(_this__u8e3s4, method, outputSizeHint) {
  outputSizeHint = outputSizeHint === VOID ? imul(_this__u8e3s4.length, 2) : outputSizeHint;
  return uncompress_1(method, _this__u8e3s4, outputSizeHint);
}
function uncompressStream(_this__u8e3s4, input, bufferSize, $completion) {
  bufferSize = bufferSize === VOID ? 8388608 : bufferSize;
  var tmp = 'uncompress:' + toString(_this__u8e3s4);
  return asyncStreamWriter(bufferSize, tmp, false, uncompressStream$slambda_0(_this__u8e3s4, input), $completion);
}
function uncompress_1(_this__u8e3s4, bytes, outputSizeHint) {
  outputSizeHint = outputSizeHint === VOID ? imul(bytes.length, 2) : outputSizeHint;
  // Inline function 'korlibs.io.stream.MemorySyncStreamToByteArray' call
  var buffer = ByteArrayBuilder.mv(outputSizeHint);
  var s = MemorySyncStream(buffer);
  // Inline function 'korlibs.io.compression.uncompress.<anonymous>' call
  uncompress_3(_this__u8e3s4, openSync(bytes), s);
  return buffer.rv();
}
function *_generator_uncompress__ej2bxp_0(_this__u8e3s4, i, o, $completion) {
  var tmp = Companion_instance_6.r2b(i, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = _this__u8e3s4.o2b(tmp, o, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function uncompress_2(_this__u8e3s4, i, o, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_uncompress__ej2bxp_0(_this__u8e3s4, i, o, $completion), $completion);
}
function uncompress_3(_this__u8e3s4, i, o) {
  return runBlockingNoSuspensions(uncompress$slambda_0(_this__u8e3s4, i, o));
}
function *_generator_invoke__zhh2q8($this, output, $completion) {
  var tmp = uncompress_2($this.s2b_1, $this.t2b_1, output, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function uncompressStream$slambda_0($this_uncompressStream, $input) {
  var i = new uncompressStream$slambda($this_uncompressStream, $input);
  var l = function (output, $completion) {
    return i.u2b(output, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_0($this, $completion) {
  var tmp = uncompress_2($this.v2b_1, toAsync($this.w2b_1), toAsync_0($this.x2b_1), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function uncompress$slambda_0($this_uncompress, $i, $o) {
  var i = new uncompress$slambda($this_uncompress, $i, $o);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function get_Deflate() {
  _init_properties_Deflate_kt__jbr9mv();
  // Inline function 'kotlin.getValue' call
  var this_0 = Deflate$delegate;
  Deflate$factory();
  return this_0.b2();
}
var Deflate$delegate;
function Deflate(windowBits) {
  _init_properties_Deflate_kt__jbr9mv();
  return new Deflate$4(windowBits);
}
function toDeflater(_this__u8e3s4) {
  _init_properties_Deflate_kt__jbr9mv();
  return new toDeflater$1(_this__u8e3s4);
}
function toDeflater_0(_this__u8e3s4) {
  _init_properties_Deflate_kt__jbr9mv();
  return new toDeflater$2(_this__u8e3s4);
}
function Deflate$delegate$lambda() {
  _init_properties_Deflate_kt__jbr9mv();
  return Deflate(15);
}
function *_generator_uncompress__ej2bxp_1($this, reader, out, $completion) {
  var tmp = $this.y2b_1.l27(toDeflater(reader), toDeflater_0(out), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_prepareBigChunkIfRequired__yhuuys($this, $completion) {
  // Inline function 'korlibs.io.compression.util.BitReader.prepareBigChunkIfRequired' call
  var this_0 = $this.z2b_1;
  if (this_0.k2c()) {
    var tmp = this_0.j2c(this_0.b2c_1, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function Deflate$factory() {
  return getPropertyCallableRef('Deflate', 0, KProperty0, function () {
    return get_Deflate();
  }, null);
}
var properties_initialized_Deflate_kt_5ek8zp;
function _init_properties_Deflate_kt__jbr9mv() {
  if (!properties_initialized_Deflate_kt_5ek8zp) {
    properties_initialized_Deflate_kt_5ek8zp = true;
    Deflate$delegate = lazy(Deflate$delegate$lambda);
  }
}
function GZIP$Companion$_init_$lambda_4zsoc7() {
  return get_Deflate();
}
var Companion_instance_4;
function Companion_getInstance_4() {
  if (Companion_instance_4 === VOID)
    new Companion_4();
  return Companion_instance_4;
}
function *_generator_close__nh2uv0($this, $completion) {
  var tmp = $this.p2c_1.f1q($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_write__x1694l($this, buffer, offset, len, $completion) {
  if (len > 0) {
    $this.r2c_1._v = CRC32_getInstance().fu($this.r2c_1._v, buffer, offset, len);
    $this.s2c_1._v = $this.s2c_1._v + len | 0;
    var tmp = $this.q2c_1.c1q(buffer, offset, len, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function *_generator_uncompress__ej2bxp_2($this, reader, out, $completion) {
  var r = reader;
  // Inline function 'korlibs.io.compression.util.BitReader.prepareBigChunkIfRequired' call
  if (r.k2c()) {
    var tmp = r.j2c(r.b2c_1, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  var h0 = r.t2c();
  var h1 = r.t2c();
  if (!(h0 === 31) || !(h1 === 139)) {
    // Inline function 'kotlin.error' call
    var message = 'Not a GZIP file (h0=' + get_hex(toByte(h0)) + ', h1=' + get_hex(toByte(h1)) + ')';
    throw IllegalStateException.m5(toString(message));
  }
  var method = r.t2c();
  if (!(method === 8)) {
    // Inline function 'kotlin.error' call
    var message_0 = 'Just supported deflate in GZIP (method=' + method + ')';
    throw IllegalStateException.m5(toString(message_0));
  }
  var ftext = r.k26();
  var fhcrc = r.k26();
  var fextra = r.k26();
  var fname = r.k26();
  var fcomment = r.k26();
  var reserved = r.l26(3);
  var mtime = r.u2c();
  var xfl = r.t2c();
  var os = r.t2c();
  var tmp_0;
  if (fextra) {
    var tmp_1 = r.l2c(r.z26(), VOID, $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp_0 = tmp_1;
  } else {
    // Inline function 'kotlin.byteArrayOf' call
    tmp_0 = new Int8Array([]);
  }
  var extra = tmp_0;
  var tmp_2;
  if (fname) {
    var tmp_3 = r.v2c($completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    tmp_2 = tmp_3;
  } else {
    tmp_2 = null;
  }
  var name = tmp_2;
  var tmp_4;
  if (fcomment) {
    var tmp_5 = r.v2c($completion);
    if (tmp_5 === get_COROUTINE_SUSPENDED())
      tmp_5 = yield tmp_5;
    tmp_4 = tmp_5;
  } else {
    tmp_4 = null;
  }
  var comment = tmp_4;
  var crc16 = fhcrc ? r.z26() : 0;
  var ccrc32 = {_v: CRC32_getInstance().gu_1};
  var csize = {_v: 0};
  var tmp_6 = $this.o2c_1();
  var tmp_7 = tmp_6.o2b(r, new GZIPBase$uncompress$2(out, ccrc32, csize), $completion);
  if (tmp_7 === get_COROUTINE_SUSPENDED())
    tmp_7 = yield tmp_7;
  // Inline function 'korlibs.io.compression.util.BitReader.prepareBigChunkIfRequired' call
  if (r.k2c()) {
    var tmp_8 = r.j2c(r.b2c_1, $completion);
    if (tmp_8 === get_COROUTINE_SUSPENDED())
      tmp_8 = yield tmp_8;
  }
  var crc32 = r.u2c();
  var size = r.u2c();
  if ($this.n2c_1 && (!(csize._v === size) || !(ccrc32._v === crc32))) {
    invalidOp("Size doesn't match SIZE(" + get_hex_0(csize._v) + ' != ' + get_hex_0(size) + ') || CRC32(' + get_hex_0(ccrc32._v) + ' != ' + get_hex_0(crc32) + ')');
  }
  return Unit_instance;
}
function ZLib$Companion$_init_$lambda_nrgg4o(it) {
  return Deflate(it);
}
function *_generator_write__x1694l_0($this, buffer, offset, len, $completion) {
  var tmp = $this.w2c_1.c1q(buffer, offset, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  $this.x2c_1._v = Adler32_instance.fu($this.x2c_1._v, buffer, offset, len);
  return Unit_instance;
}
var Companion_instance_5;
function Companion_getInstance_5() {
  if (Companion_instance_5 === VOID)
    new Companion_5();
  return Companion_instance_5;
}
function *_generator_uncompress__ej2bxp_3($this, reader, out, $completion) {
  var r = reader;
  var o = out;
  // Inline function 'korlibs.io.compression.util.BitReader.prepareBigChunkIfRequired' call
  if (r.k2c()) {
    var tmp = r.j2c(r.b2c_1, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  var cmf = r.t2c();
  var flg = r.t2c();
  if (!(((imul(cmf, 256) + flg | 0) % 31 | 0) === 0)) {
    // Inline function 'kotlin.error' call
    var message = 'bad zlib header';
    throw IllegalStateException.m5(toString(message));
  }
  var compressionMethod = extract(cmf, 0, 4);
  if (!(compressionMethod === 8)) {
    // Inline function 'kotlin.error' call
    var message_0 = 'Invalid zlib stream compressionMethod=' + compressionMethod;
    throw IllegalStateException.m5(toString(message_0));
  }
  var windowBits = extract(cmf, 4, 4) + 8 | 0;
  var fcheck = extract(flg, 0, 5);
  // Inline function 'korlibs.memory.extract' call
  // Inline function 'korlibs.memory.extract1' call
  var hasDict = !(((flg >>> 5 | 0) & 1) === 0);
  var flevel = extract(flg, 6, 2);
  var dictid = 0;
  if (hasDict) {
    dictid = r.u2c();
    // Inline function 'kotlin.TODO' call
    var reason = 'Unsupported custom dictionaries (Provided DICTID=' + dictid + ')';
    throw NotImplementedError.oe('An operation is not implemented: ' + reason);
  }
  var chash = {_v: Adler32_instance.eu_1};
  var tmp_0 = $this.y2c_1(windowBits);
  var tmp_1 = tmp_0.o2b(r, new ZLib$uncompress$2(o, chash), $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  // Inline function 'korlibs.io.compression.util.BitReader.prepareBigChunkIfRequired' call
  if (r.k2c()) {
    var tmp_2 = r.j2c(r.b2c_1, $completion);
    if (tmp_2 === get_COROUTINE_SUSPENDED())
      tmp_2 = yield tmp_2;
  }
  var adler32 = r.z2c();
  if (!(chash._v === adler32)) {
    invalidOp("Adler32 doesn't match " + get_hex_0(chash._v) + ' != ' + get_hex_0(adler32));
  }
  return Unit_instance;
}
function *_generator_uncompress__ej2bxp_4($this, reader, out, $completion) {
  var tmp = readAll(reader, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var input = openSync(tmp);
  var properties = readBytesExact(input, 5);
  var decoder = new LzmaDecoder();
  if (!decoder.n2b(properties))
    throw Exception.f6('Incorrect stream properties');
  var outSize = readS64LE(input);
  // Inline function 'korlibs.io.stream.MemorySyncStreamToByteArray' call
  var buffer = ByteArrayBuilder.mv(4096);
  var s = MemorySyncStream(buffer);
  // Inline function 'korlibs.io.compression.lzma.Lzma.uncompress.<anonymous>' call
  if (!decoder.i2b(Lzma_instance.a2d(input), Lzma_instance.b2d(s), outSize))
    throw Exception.f6('Error in data stream');
  var tmp$ret$0 = buffer.rv();
  var tmp_0 = writeBytes(out, tmp$ret$0, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
var Lzma_instance;
function Lzma_getInstance() {
  return Lzma_instance;
}
function *_generator_forInput__mjt6c9($this, s, $completion) {
  var tmp;
  if (isInterface(s, AsyncGetLengthStream)) {
    var tmp_0 = s.z1s($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    tmp = false;
  }
  if (tmp) {
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.min' call
    var tmp_1 = s.y1s($completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    var a = tmp_1;
    var b = new Long(8388608, 0);
    var b_0 = (a.g2(b) <= 0 ? a : b).q2();
    var bigChunkSize = Math.max(32768, b_0);
    // Inline function 'kotlin.math.max' call
    var a_0 = bigChunkSize / 2 | 0;
    var readWithSize = Math.max(a_0, 32768);
    return new BitReader(s, bigChunkSize, readWithSize);
  }
  return new BitReader(s);
}
var Companion_instance_6;
function Companion_getInstance_6() {
  return Companion_instance_6;
}
function *_generator_prepareBytesUpTo__5zpo26($this, expectedBytes, $completion) {
  $l$loop_0: while ($this.f2c_1.a12_1 < expectedBytes) {
    // Inline function 'kotlin.math.min' call
    var b = $this.f2c_1.w12();
    var readCount = Math.min(expectedBytes, b);
    if (readCount <= 0)
      break $l$loop_0;
    var tmp = $this.a2c_1.e1q($this.f2c_1.q12(), $this.f2c_1.s12(), readCount, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var transferred = tmp;
    if (transferred <= 0)
      break $l$loop_0;
    $this.f2c_1.t12(transferred);
    $this.h2c_1 = $this.h2c_1 + transferred;
  }
  return Unit_instance;
}
function *_generator_abytes__gje57m($this, count, out, $completion) {
  var tmp = $this.j2c(count, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return $this.e2d(count, out);
}
function *_generator_read__qih2oe($this, buffer, offset, len, $completion) {
  var tmp = $this.j2c(len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var out = $this.f2c_1.k12(buffer, offset, len);
  $this.g2c_1 = $this.g2c_1 + out;
  return out;
}
function *_generator_close__nh2uv0_0($this, $completion) {
  var tmp = $this.a2c_1.f1q($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_strz__qhnc5d($this, $completion) {
  // Inline function 'korlibs.io.stream.MemorySyncStreamToByteArray' call
  var buffer = ByteArrayBuilder.mv(4096);
  var s = MemorySyncStream(buffer);
  // Inline function 'korlibs.io.compression.util.BitReader.strz.<anonymous>' call
  // Inline function 'korlibs.io.compression.util.BitReader.discardBits' call
  $this.d2c_1 = 0;
  $this.e2c_1 = 0;
  $l$loop: while (true) {
    // Inline function 'korlibs.io.compression.util.BitReader.prepareBigChunkIfRequired' call
    if ($this.k2c()) {
      var tmp = $this.j2c($this.b2c_1, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
    }
    // Inline function 'korlibs.io.compression.util.BitReader._su8' call
    $this.g2c_1 = $this.g2c_1 + 1;
    var c = $this.f2c_1.y12();
    if (c === 0)
      break $l$loop;
    write8(s, c);
  }
  var tmp$ret$2 = buffer.rv();
  return toString_0(tmp$ret$2, ASCII_getInstance());
}
function *_generator_hasLength__5f5ox8($this, $completion) {
  var tmp = $this.a2c_1;
  var tmp7_safe_receiver = isInterface(tmp, AsyncGetLengthStream) ? tmp : null;
  var tmp_0;
  if (tmp7_safe_receiver == null) {
    tmp_0 = null;
  } else {
    var tmp_1 = tmp7_safe_receiver.z1s($completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp_0 = tmp_1;
  }
  var tmp0_elvis_lhs = tmp_0;
  return tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs;
}
function *_generator_getLength__lvva94($this, $completion) {
  var tmp = $this.a2c_1;
  var tmp8_safe_receiver = isInterface(tmp, AsyncGetLengthStream) ? tmp : null;
  var tmp_0;
  if (tmp8_safe_receiver == null) {
    tmp_0 = null;
  } else {
    var tmp_1 = tmp8_safe_receiver.y1s($completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp_0 = tmp_1;
  }
  var tmp0_elvis_lhs = tmp_0;
  var tmp_2;
  if (tmp0_elvis_lhs == null) {
    var message = 'Length not available on Stream';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_2 = tmp0_elvis_lhs;
  }
  return tmp_2;
}
function ilog2(v) {
  var tmp;
  if (v === 0) {
    tmp = -1;
  } else {
    // Inline function 'kotlin.countLeadingZeroBits' call
    tmp = 31 - clz32(v) | 0;
  }
  return tmp;
}
function get_nextPowerOfTwo(_this__u8e3s4) {
  var v = _this__u8e3s4;
  v = v - 1 | 0;
  v = v | v >> 1;
  v = v | v >> 2;
  v = v | v >> 4;
  v = v | v >> 8;
  v = v | v >> 16;
  v = v + 1 | 0;
  return v;
}
function DeflaterNative(windowBits) {
  return new DeflaterPortable(windowBits);
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForCompanion(Companion_0);
initMetadataForClass(SlidingWindowWithOutput, 'SlidingWindowWithOutput', VOID, VOID, VOID, [0, 1]);
initMetadataForClass(DeflaterPortable, 'DeflaterPortable', VOID, VOID, VOID, [3, 2]);
initMetadataForCompanion(Companion_1, VOID, VOID, [3, 2]);
initMetadataForClass(HuffmanTree, 'HuffmanTree', HuffmanTree);
initMetadataForClass(SlidingWindow, 'SlidingWindow');
initMetadataForClass(FixedSizeByteArrayBuilder, 'FixedSizeByteArrayBuilder');
initMetadataForCompanion(Companion_2);
initMetadataForCompanion(Companion_3);
initMetadataForClass(Decoder2, 'Decoder2');
initMetadataForClass(LenDecoder, 'LenDecoder');
initMetadataForClass(LiteralDecoder, 'LiteralDecoder');
initMetadataForClass(BitTreeDecoder, 'BitTreeDecoder');
initMetadataForClass(RangeDecoder, 'RangeDecoder', RangeDecoder);
initMetadataForObject(LzmaBase, 'LzmaBase');
initMetadataForClass(LzmaDecoder, 'LzmaDecoder', LzmaDecoder);
initMetadataForClass(LzOutWindow, 'LzOutWindow', LzOutWindow);
initMetadataForInterface(CompressionMethod, 'CompressionMethod', VOID, VOID, VOID, [2, 3]);
initMetadataForLambda(uncompressStream$slambda, VOID, VOID, [1]);
initMetadataForLambda(uncompress$slambda, VOID, VOID, [0]);
initMetadataForClass(Deflate$4, VOID, VOID, VOID, [CompressionMethod], [2, 3]);
initMetadataForClass(toDeflater$1, VOID, VOID, VOID, VOID, [0, 1, 3]);
initMetadataForClass(toDeflater$2, VOID, VOID, VOID, VOID, [3, 1]);
initMetadataForClass(GZIPBase, 'GZIPBase', VOID, VOID, [CompressionMethod], [2, 3]);
initMetadataForClass(GZIP, 'GZIP', VOID, VOID, VOID, [2, 3]);
initMetadataForCompanion(Companion_4, VOID, VOID, [2, 3]);
protoOf(GZIPBase$uncompress$2).d1q = write$default;
initMetadataForClass(GZIPBase$uncompress$2, VOID, VOID, VOID, [AsyncOutputStream], [1, 0, 3]);
initMetadataForClass(ZLib, 'ZLib', VOID, VOID, [CompressionMethod], [2, 3]);
initMetadataForCompanion(Companion_5, VOID, VOID, [2, 3]);
protoOf(ZLib$uncompress$2).d1q = write$default;
initMetadataForClass(ZLib$uncompress$2, VOID, VOID, VOID, [AsyncOutputStream], [0, 3, 1]);
initMetadataForClass(Lzma$toLzmaInput$1);
initMetadataForClass(Lzma$toLzmaOutput$1);
initMetadataForObject(Lzma, 'Lzma', VOID, VOID, [CompressionMethod], [2, 3]);
initMetadataForCompanion(Companion_6, VOID, VOID, [1]);
initMetadataForClass(BitReader, 'BitReader', VOID, VOID, [AsyncGetPositionStream, AsyncGetLengthStream], [0, 1, 2, 3]);
//endregion
//region block: init
Companion_instance = new Companion();
Companion_instance_0 = new Companion_0();
Companion_instance_2 = new Companion_2();
Companion_instance_3 = new Companion_3();
LzmaBase_instance = new LzmaBase();
Lzma_instance = new Lzma();
Companion_instance_6 = new Companion_6();
//endregion
//region block: exports
export {
  uncompressed as uncompressed2homtbws7svq9,
  Companion_getInstance_4 as Companion_getInstance1ybjf5xfi0it8,
  Companion_getInstance_5 as Companion_getInstance27y7ak0p8xjnv,
  Lzma_instance as Lzma_instance2qi6ni3wowut9,
  get_Deflate as get_Deflate2a0lyute7oxjd,
  uncompress_0 as uncompress3ofkd276wd0zn,
};
//endregion
