import {
  Long2qws0ah9gnpki as Long,
  numberToLong1a4cndvg6c52s as numberToLong,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  VOID7hggqo3abtya as VOID,
  DurationUnit_MILLISECONDS_getInstance15owevua4zjxe as DurationUnit_MILLISECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  compareTo3ankvs086tmwq as compareTo,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  equals2au1ep9vhcato as equals,
  Comparable198qfk8pnblz0 as Comparable,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  round2mrvepag8eey0 as round,
  DurationUnit_SECONDS_getInstance3jias9ne5z4er as DurationUnit_SECONDS_getInstance,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  _Duration___get_inWholeNanoseconds__impl__r5x4mr3idov4hy0gkwd as _Duration___get_inWholeNanoseconds__impl__r5x4mr,
  DurationUnit_NANOSECONDS_getInstancexzp0zqz7eqak as DurationUnit_NANOSECONDS_getInstance,
  toDurationba1nlt78o6vu as toDuration_0,
  protoOf180f3jzyo7rfj as protoOf,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    Companion_instance = this;
    this.b1w_1 = new Long(1, 0);
    this.c1w_1 = _DateTime___init__impl__ifgty2(0.0);
    this.d1w_1 = 6.21355968E13;
  }
  e1w(unix) {
    return this.f1w(unix);
  }
  g1w(unix) {
    return _DateTime___init__impl__ifgty2(unix);
  }
  f1w(unix) {
    return this.g1w(unix.t4());
  }
  h1w() {
    return _DateTime___init__impl__ifgty2(get_CoreTime().i1w());
  }
  j1w() {
    return numberToLong(get_CoreTime().i1w());
  }
}
class DateTime {
  constructor(unixMillis) {
    Companion_getInstance_0();
    this.k1w_1 = unixMillis;
  }
  l1w(other) {
    return DateTime__compareTo_impl_geo3bs(this.k1w_1, other);
  }
  d(other) {
    return DateTime__compareTo_impl_geo3bs_0(this, other);
  }
  toString() {
    return DateTime__toString_impl_cnueje(this.k1w_1);
  }
  hashCode() {
    return DateTime__hashCode_impl_yf0bc9(this.k1w_1);
  }
  equals(other) {
    return DateTime__equals_impl_qd7s8j(this.k1w_1, other);
  }
}
class Companion_0 {
  m1w() {
    return _FastDuration___init__impl__cfl6bz(0.0);
  }
  n1w() {
    return _FastDuration___init__impl__cfl6bz(NaN);
  }
  o1w() {
    return this.n1w();
  }
}
class FastDuration {
  constructor(ms) {
    this.p1w_1 = ms;
  }
  q1w(other) {
    return FastDuration__compareTo_impl_4fhnj7(this.p1w_1, other);
  }
  d(other) {
    return FastDuration__compareTo_impl_4fhnj7_0(this, other);
  }
  toString() {
    return FastDuration__toString_impl_c9xohb(this.p1w_1);
  }
  hashCode() {
    return FastDuration__hashCode_impl_y13la6(this.p1w_1);
  }
  equals(other) {
    return FastDuration__equals_impl_unk8ke(this.p1w_1, other);
  }
}
class TimeProvider {}
class Companion_1 {
  h1w() {
    return Companion_getInstance_0().h1w();
  }
}
class ICoreTime {}
function performanceMillis() {
  return this.i1w();
}
class CoreTime$1 {
  i1w() {
    return Date.now();
  }
}
//endregion
function _DateTime___init__impl__ifgty2(unixMillis) {
  return unixMillis;
}
function _DateTime___get_unixMillis__impl__ofxkzp($this) {
  return $this;
}
var Companion_instance;
function Companion_getInstance_0() {
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
function _DateTime___get_unixMillisDouble__impl__3dzlsc($this) {
  return _DateTime___get_unixMillis__impl__ofxkzp($this);
}
function _DateTime___get_unixMillisLong__impl__sdx9bd($this) {
  return numberToLong(_DateTime___get_unixMillisDouble__impl__3dzlsc($this));
}
function DateTime__minus_impl_10njw8($this, other) {
  // Inline function 'korlibs.time.milliseconds' call
  var this_0 = _DateTime___get_unixMillisDouble__impl__3dzlsc($this) - _DateTime___get_unixMillisDouble__impl__3dzlsc(other);
  return toDuration(this_0, DurationUnit_MILLISECONDS_getInstance());
}
function DateTime__compareTo_impl_geo3bs($this, other) {
  return compareTo(_DateTime___get_unixMillis__impl__ofxkzp($this), _DateTime___get_unixMillis__impl__ofxkzp(other));
}
function DateTime__compareTo_impl_geo3bs_0($this, other) {
  return DateTime__compareTo_impl_geo3bs($this.k1w_1, other instanceof DateTime ? other.k1w_1 : THROW_CCE());
}
function DateTime__toString_impl_cnueje($this) {
  return 'DateTime(' + _DateTime___get_unixMillisLong__impl__sdx9bd($this).toString() + ')';
}
function DateTime__hashCode_impl_yf0bc9($this) {
  return getNumberHashCode($this);
}
function DateTime__equals_impl_qd7s8j($this, other) {
  if (!(other instanceof DateTime))
    return false;
  var tmp0_other_with_cast = other instanceof DateTime ? other.k1w_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function _FastDuration___init__impl__cfl6bz(ms) {
  return ms;
}
function _get_ms__ndc6g5($this) {
  return $this;
}
var Companion_instance_0;
function Companion_getInstance_1() {
  return Companion_instance_0;
}
function _FastDuration___get_isNil__impl__n4fw9d($this) {
  return equals($this, Companion_instance_0.o1w());
}
function _FastDuration___get_seconds__impl__4kvvav($this) {
  return _get_ms__ndc6g5($this) / 1000;
}
function _FastDuration___get_milliseconds__impl__ffr17u($this) {
  return _get_ms__ndc6g5($this);
}
function _FastDuration___get_microseconds__impl__o4j93h($this) {
  return _get_ms__ndc6g5($this) * 1000;
}
function _FastDuration___get_nanoseconds__impl__pxizcb($this) {
  return _get_ms__ndc6g5($this) * 1000000;
}
function _FastDuration___get_fastSeconds__impl__amo4s3($this) {
  return _FastDuration___get_seconds__impl__4kvvav($this);
}
function _FastDuration___get_fastMilliseconds__impl__pe68va($this) {
  return _FastDuration___get_milliseconds__impl__ffr17u($this);
}
function _FastDuration___get_fastMicroseconds__impl__e641g1($this) {
  return _FastDuration___get_microseconds__impl__o4j93h($this);
}
function _FastDuration___get_fastNanoseconds__impl__9kjwbr($this) {
  return _FastDuration___get_nanoseconds__impl__pxizcb($this);
}
function FastDuration__plus_impl_q1enab($this, that) {
  return _FastDuration___init__impl__cfl6bz(_get_ms__ndc6g5($this) + _get_ms__ndc6g5(that));
}
function FastDuration__minus_impl_evs237($this, that) {
  return _FastDuration___init__impl__cfl6bz(_get_ms__ndc6g5($this) - _get_ms__ndc6g5(that));
}
function FastDuration__div_impl_rlslck($this, other) {
  return _get_ms__ndc6g5($this) / _get_ms__ndc6g5(other);
}
function FastDuration__times_impl_h6amcp($this, scale) {
  return _FastDuration___init__impl__cfl6bz(_get_ms__ndc6g5($this) * scale);
}
function FastDuration__div_impl_rlslck_0($this, scale) {
  return _FastDuration___init__impl__cfl6bz(_get_ms__ndc6g5($this) / scale);
}
function FastDuration__plus_impl_q1enab_0($this, other) {
  return _FastDuration___init__impl__cfl6bz(_FastDuration___get_milliseconds__impl__ffr17u($this) + get_milliseconds(other));
}
function FastDuration__minus_impl_evs237_0($this, other) {
  return _FastDuration___init__impl__cfl6bz(_FastDuration___get_milliseconds__impl__ffr17u($this) - get_milliseconds(other));
}
function FastDuration__compareTo_impl_4fhnj7($this, other) {
  return compareTo(_get_ms__ndc6g5($this), _get_ms__ndc6g5(other));
}
function FastDuration__compareTo_impl_4fhnj7_0($this, other) {
  return FastDuration__compareTo_impl_4fhnj7($this.p1w_1, other instanceof FastDuration ? other.p1w_1 : THROW_CCE());
}
function FastDuration__compareTo_impl_4fhnj7_1($this, other) {
  return compareTo(_get_ms__ndc6g5($this), get_milliseconds(other));
}
function FastDuration__toString_impl_c9xohb($this) {
  return 'FastDuration(ms=' + $this + ')';
}
function FastDuration__hashCode_impl_y13la6($this) {
  return getNumberHashCode($this);
}
function FastDuration__equals_impl_unk8ke($this, other) {
  if (!(other instanceof FastDuration))
    return false;
  var tmp0_other_with_cast = other instanceof FastDuration ? other.p1w_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function get_fastSeconds(_this__u8e3s4) {
  return get_fastSeconds_0(_this__u8e3s4);
}
function toDuration_1(_this__u8e3s4) {
  return get_slow(_this__u8e3s4);
}
function get_fast(_this__u8e3s4) {
  var tmp;
  // Inline function 'korlibs.time.isNil' call
  if (equals(_this__u8e3s4, get_DURATION_NIL())) {
    tmp = Companion_instance_0.o1w();
  } else {
    tmp = get_fastMilliseconds_0(get_milliseconds(_this__u8e3s4));
  }
  return tmp;
}
function minus(_this__u8e3s4, other) {
  return _FastDuration___init__impl__cfl6bz(get_milliseconds(_this__u8e3s4) - _FastDuration___get_milliseconds__impl__ffr17u(other));
}
function get_fastMilliseconds(_this__u8e3s4) {
  return get_fastMilliseconds_0(_this__u8e3s4);
}
function roundMilliseconds(_this__u8e3s4) {
  return get_fastMilliseconds_0(round(_FastDuration___get_milliseconds__impl__ffr17u(_this__u8e3s4)));
}
function get_slow(_this__u8e3s4) {
  var tmp;
  if (_FastDuration___get_isNil__impl__n4fw9d(_this__u8e3s4)) {
    // Inline function 'korlibs.time.NIL' call
    Companion_getInstance();
    tmp = get_DURATION_NIL();
  } else {
    // Inline function 'korlibs.time.seconds' call
    var this_0 = _FastDuration___get_seconds__impl__4kvvav(_this__u8e3s4);
    tmp = toDuration(this_0, DurationUnit_SECONDS_getInstance());
  }
  return tmp;
}
function get_fastMilliseconds_0(_this__u8e3s4) {
  return _FastDuration___init__impl__cfl6bz(_this__u8e3s4);
}
function get_fastSeconds_0(_this__u8e3s4) {
  return _FastDuration___init__impl__cfl6bz(_this__u8e3s4 * 1000);
}
function get_fastMicroseconds(_this__u8e3s4) {
  return _FastDuration___init__impl__cfl6bz(_this__u8e3s4 / 1000);
}
function get_fastMicroseconds_0(_this__u8e3s4) {
  return get_fastMilliseconds_0(_this__u8e3s4);
}
function div(_this__u8e3s4, other) {
  return get_milliseconds(_this__u8e3s4) / _FastDuration___get_milliseconds__impl__ffr17u(other);
}
function get_fastNanoseconds(_this__u8e3s4) {
  return _FastDuration___init__impl__cfl6bz(_this__u8e3s4 / 1000000);
}
function get_fastNanoseconds_0(_this__u8e3s4) {
  return get_fastMilliseconds_0(_this__u8e3s4);
}
var Companion_instance_1;
function Companion_getInstance_2() {
  return Companion_instance_1;
}
function get_DURATION_NIL() {
  _init_properties_TimeSpan_kt__xworpz();
  return DURATION_NIL;
}
var DURATION_NIL;
function get_milliseconds(_this__u8e3s4) {
  _init_properties_TimeSpan_kt__xworpz();
  return _Duration___get_inWholeNanoseconds__impl__r5x4mr(_this__u8e3s4).t4() / 1000000.0;
}
function get_seconds(_this__u8e3s4) {
  _init_properties_TimeSpan_kt__xworpz();
  return get_milliseconds(_this__u8e3s4) / 1000;
}
function max(a, b) {
  _init_properties_TimeSpan_kt__xworpz();
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  // Inline function 'kotlin.math.max' call
  var a_0 = get_milliseconds(a);
  var b_0 = get_milliseconds(b);
  var this_0 = Math.max(a_0, b_0);
  return toDuration(this_0, DurationUnit_MILLISECONDS_getInstance());
}
function min(a, b) {
  _init_properties_TimeSpan_kt__xworpz();
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  // Inline function 'kotlin.math.min' call
  var a_0 = get_milliseconds(a);
  var b_0 = get_milliseconds(b);
  var this_0 = Math.min(a_0, b_0);
  return toDuration(this_0, DurationUnit_MILLISECONDS_getInstance());
}
var properties_initialized_TimeSpan_kt_fytdnd;
function _init_properties_TimeSpan_kt__xworpz() {
  if (!properties_initialized_TimeSpan_kt_fytdnd) {
    properties_initialized_TimeSpan_kt_fytdnd = true;
    DURATION_NIL = toDuration_0(new Long(13, -2097152), DurationUnit_NANOSECONDS_getInstance());
  }
}
function get_CoreTime() {
  _init_properties_CoreTime_js_kt__zee7g9();
  return CoreTime;
}
var CoreTime;
var properties_initialized_CoreTime_js_kt_73dge3;
function _init_properties_CoreTime_js_kt__zee7g9() {
  if (!properties_initialized_CoreTime_js_kt_73dge3) {
    properties_initialized_CoreTime_js_kt_73dge3 = true;
    CoreTime = new CoreTime$1();
  }
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForClass(DateTime, 'DateTime', VOID, VOID, [Comparable]);
initMetadataForCompanion(Companion_0);
initMetadataForClass(FastDuration, 'FastDuration', VOID, VOID, [Comparable]);
initMetadataForInterface(TimeProvider, 'TimeProvider');
initMetadataForCompanion(Companion_1, VOID, [TimeProvider]);
initMetadataForInterface(ICoreTime, 'ICoreTime');
protoOf(CoreTime$1).r1w = performanceMillis;
initMetadataForClass(CoreTime$1, VOID, VOID, VOID, [ICoreTime]);
//endregion
//region block: init
Companion_instance_0 = new Companion_0();
Companion_instance_1 = new Companion_1();
//endregion
//region block: exports
export {
  DateTime__hashCode_impl_yf0bc9 as DateTime__hashCode_impl_yf0bc92p1oa6fp4l38p,
  DateTime__minus_impl_10njw8 as DateTime__minus_impl_10njw81nqbgaa48pgq4,
  DateTime__toString_impl_cnueje as DateTime__toString_impl_cnuejeub59104j2jjn,
  FastDuration__compareTo_impl_4fhnj7 as FastDuration__compareTo_impl_4fhnj71uadlvoozsr50,
  FastDuration__compareTo_impl_4fhnj7_1 as FastDuration__compareTo_impl_4fhnj71anf17lkhs1by,
  FastDuration__div_impl_rlslck as FastDuration__div_impl_rlslck1plvf7n5rb7aa,
  FastDuration__div_impl_rlslck_0 as FastDuration__div_impl_rlslck15f8mt5g6dyds,
  _FastDuration___get_fastMicroseconds__impl__e641g1 as _FastDuration___get_fastMicroseconds__impl__e641g13hz45luccu2o2,
  _FastDuration___get_fastMilliseconds__impl__pe68va as _FastDuration___get_fastMilliseconds__impl__pe68va279q35r54j5bc,
  _FastDuration___get_fastNanoseconds__impl__9kjwbr as _FastDuration___get_fastNanoseconds__impl__9kjwbr1r08ed2cb76hy,
  _FastDuration___get_fastSeconds__impl__amo4s3 as _FastDuration___get_fastSeconds__impl__amo4s3p9zx52w3o39j,
  FastDuration__hashCode_impl_y13la6 as FastDuration__hashCode_impl_y13la61yn3ntf9nmd1o,
  _FastDuration___get_microseconds__impl__o4j93h as _FastDuration___get_microseconds__impl__o4j93h1to7wq49algof,
  _FastDuration___get_milliseconds__impl__ffr17u as _FastDuration___get_milliseconds__impl__ffr17uhz706a3hbxx1,
  FastDuration__minus_impl_evs237_0 as FastDuration__minus_impl_evs2371ieewiyvo7cp9,
  FastDuration__minus_impl_evs237 as FastDuration__minus_impl_evs2371fjveki3j5gyc,
  _FastDuration___get_nanoseconds__impl__pxizcb as _FastDuration___get_nanoseconds__impl__pxizcb3qjtomx85900t,
  FastDuration__plus_impl_q1enab as FastDuration__plus_impl_q1enab3lvjsvnxno7fk,
  FastDuration__plus_impl_q1enab_0 as FastDuration__plus_impl_q1enab14z0pv6jz22jd,
  _FastDuration___get_seconds__impl__4kvvav as _FastDuration___get_seconds__impl__4kvvav2y79dbfyhbo29,
  FastDuration__times_impl_h6amcp as FastDuration__times_impl_h6amcp25vm4d6obfh9l,
  FastDuration__toString_impl_c9xohb as FastDuration__toString_impl_c9xohb3cvph1y7s95v4,
  Companion_getInstance_0 as Companion_getInstance3moaeo8p2fvjj,
  Companion_instance_0 as Companion_instance2mxqoacslm62w,
  Companion_instance_1 as Companion_instance2bvf2vakw640b,
  get_CoreTime as get_CoreTime23yr4od90u6o9,
  get_DURATION_NIL as get_DURATION_NIL2lkiawowwp1us,
  DateTime as DateTime2gld4ho36azq9,
  FastDuration as FastDuration3d3pupg2e5ora,
  TimeProvider as TimeProvider2j6wl2le6n0p3,
  div as div1s6t4x2ts64sh,
  get_fast as get_fast37cu8s8qwnuyi,
  get_fastMicroseconds_0 as get_fastMicroseconds16wth98xujxdo,
  get_fastMicroseconds as get_fastMicroseconds3xewe49ldi5s,
  get_fastMilliseconds as get_fastMilliseconds2pmoifl74b2oz,
  get_fastMilliseconds_0 as get_fastMillisecondsfxaeyfzoedsj,
  get_fastNanoseconds_0 as get_fastNanoseconds1laeiegsk8vcx,
  get_fastNanoseconds as get_fastNanosecondsibv7lnrlu50o,
  get_fastSeconds as get_fastSecondsxsf72e0ltu4j,
  get_fastSeconds_0 as get_fastSeconds3ld6xqdi008l3,
  max as max1q2oylvn6uire,
  get_milliseconds as get_milliseconds20vru1dkca6bq,
  minus as minus2npwq73u0d9yx,
  min as min27i0e7o9bo03e,
  roundMilliseconds as roundMilliseconds3bd2pntxev66o,
  get_seconds as get_secondsjblpw5qd4dzj,
  get_slow as get_slowaqovrrnrezzv,
  toDuration_1 as toDuration15w5sljvx2ut2,
};
//endregion
