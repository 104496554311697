import {
  VOID7hggqo3abtya as VOID,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  Char__toInt_impl_vasixd1g1ubcha2b2yr as Char__toInt_impl_vasixd,
  toByte4i43936u611k as toByte,
  copyOfwy6h3t5vzqpl as copyOf,
  replace3le3ie7l9k8aq as replace,
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  Char__plus_impl_qi7pgjue8oeapgr66d as Char__plus_impl_qi7pgj,
  StringBuildermazzzhj6kkai as StringBuilder,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Base64 {
  constructor() {
    Base64_instance = this;
    this.o24_1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    this.p24_1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=';
    this.q24_1 = toDecodeArray(this.o24_1);
    this.r24_1 = toDecodeArray(this.p24_1);
  }
  t24(str, url) {
    var tmp = 0;
    var tmp_0 = str.length;
    var tmp_1 = new Int8Array(tmp_0);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      // Inline function 'kotlin.code' call
      var this_0 = charSequenceGet(str, tmp_2);
      var tmp$ret$0 = Char__toInt_impl_vasixd(this_0);
      tmp_1[tmp_2] = toByte(tmp$ret$0);
      tmp = tmp + 1 | 0;
    }
    var src = tmp_1;
    var dst = new Int8Array(src.length);
    return copyOf(dst, this.u24(src, dst, url));
  }
  s24(ignoreSpaces, str, url) {
    return ignoreSpaces ? Base64_getInstance().v24(str, url) : Base64_getInstance().t24(str, url);
  }
  v24(str, url) {
    return this.t24(replace(replace(replace(replace(str, ' ', ''), '\n', ''), '\r', ''), '\t', ''), url);
  }
  u24(src, dst, url) {
    var tmp;
    if (url) {
      tmp = this.r24_1;
    } else {
      tmp = this.q24_1;
    }
    var decodeArray = tmp;
    var m = 0;
    var n = 0;
    $l$loop: while (n < src.length) {
      var d = decodeArray[readU8(src, this, n)];
      if (d < 0) {
        n = n + 1 | 0;
        continue $l$loop;
      }
      var tmp_0;
      if (n < src.length) {
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        tmp_0 = decodeArray[readU8(src, this, _unary__edvuaz)];
      } else {
        tmp_0 = 64;
      }
      var b0 = tmp_0;
      var tmp_1;
      if (n < src.length) {
        var _unary__edvuaz_0 = n;
        n = _unary__edvuaz_0 + 1 | 0;
        tmp_1 = decodeArray[readU8(src, this, _unary__edvuaz_0)];
      } else {
        tmp_1 = 64;
      }
      var b1 = tmp_1;
      var tmp_2;
      if (n < src.length) {
        var _unary__edvuaz_1 = n;
        n = _unary__edvuaz_1 + 1 | 0;
        tmp_2 = decodeArray[readU8(src, this, _unary__edvuaz_1)];
      } else {
        tmp_2 = 64;
      }
      var b2 = tmp_2;
      var tmp_3;
      if (n < src.length) {
        var _unary__edvuaz_2 = n;
        n = _unary__edvuaz_2 + 1 | 0;
        tmp_3 = decodeArray[readU8(src, this, _unary__edvuaz_2)];
      } else {
        tmp_3 = 64;
      }
      var b3 = tmp_3;
      var _unary__edvuaz_3 = m;
      m = _unary__edvuaz_3 + 1 | 0;
      dst[_unary__edvuaz_3] = toByte(b0 << 2 | b1 >> 4);
      if (b2 < 64) {
        var _unary__edvuaz_4 = m;
        m = _unary__edvuaz_4 + 1 | 0;
        dst[_unary__edvuaz_4] = toByte(b1 << 4 | b2 >> 2);
        if (b3 < 64) {
          var _unary__edvuaz_5 = m;
          m = _unary__edvuaz_5 + 1 | 0;
          dst[_unary__edvuaz_5] = toByte(b2 << 6 | b3);
        }
      }
    }
    return m;
  }
}
class Hex {
  w24(v) {
    return v >= 10 ? Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(97), v - 10 | 0) : Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(48), v);
  }
  x24(v) {
    return v >= 10 ? Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(65), v - 10 | 0) : Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(48), v);
  }
  y24(appender, value) {
    appender.jc(this.w24((value >>> 4 | 0) & 15));
    appender.jc(this.w24((value >>> 0 | 0) & 15));
  }
  z24(src) {
    // Inline function 'korlibs.encoding.Hex.encodeBase' call
    // Inline function 'kotlin.text.buildString' call
    var capacity = imul(src.length, 2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.nc(capacity);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.encoding.Hex.encodeBase.<anonymous>' call
    // Inline function 'korlibs.encoding.Hex.encode' call
    var inductionVariable = 0;
    var last = src.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = src[n] & 255;
        // Inline function 'korlibs.encoding.Hex.encodeLower.<anonymous>' call
        var it = (v >>> 4 | 0) & 15;
        var tmp$ret$0 = Hex_instance.w24(it);
        this_0.jc(tmp$ret$0);
        // Inline function 'korlibs.encoding.Hex.encodeLower.<anonymous>' call
        var it_0 = (v >>> 0 | 0) & 15;
        var tmp$ret$1 = Hex_instance.w24(it_0);
        this_0.jc(tmp$ret$1);
      }
       while (inductionVariable <= last);
    return this_0.toString();
  }
  a25(v) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.nc(2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.encoding.Hex.shex.<anonymous>' call
    this_0.jc(Hex_instance.x24((v >>> 4 | 0) & 15));
    this_0.jc(Hex_instance.x24((v >>> 0 | 0) & 15));
    return this_0.toString();
  }
  b25(v) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.nc(8);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.encoding.Hex.shex.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < 8)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v_0 = (v >>> imul(7 - n | 0, 4) | 0) & 15;
        this_0.jc(Hex_instance.x24(v_0));
      }
       while (inductionVariable < 8);
    return this_0.toString();
  }
}
//endregion
function fromBase64(_this__u8e3s4, ignoreSpaces, url) {
  ignoreSpaces = ignoreSpaces === VOID ? false : ignoreSpaces;
  url = url === VOID ? false : url;
  return Base64_getInstance().s24(ignoreSpaces, _this__u8e3s4, url);
}
function readU8(_this__u8e3s4, $this, index) {
  return _this__u8e3s4[index] & 255;
}
var Base64_instance;
function Base64_getInstance() {
  if (Base64_instance === VOID)
    new Base64();
  return Base64_instance;
}
function toDecodeArray(_this__u8e3s4) {
  // Inline function 'kotlin.also' call
  var this_0 = new Int32Array(256);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.encoding.toDecodeArray.<anonymous>' call
  var inductionVariable = 0;
  if (inductionVariable <= 255)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      this_0[n] = -1;
    }
     while (inductionVariable <= 255);
  var inductionVariable_0 = 0;
  var last = charSequenceLength(_this__u8e3s4) - 1 | 0;
  if (inductionVariable_0 <= last)
    do {
      var n_0 = inductionVariable_0;
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      // Inline function 'kotlin.code' call
      var this_1 = charSequenceGet(_this__u8e3s4, n_0);
      this_0[Char__toInt_impl_vasixd(this_1)] = n_0;
    }
     while (inductionVariable_0 <= last);
  return this_0;
}
var Hex_instance;
function Hex_getInstance() {
  return Hex_instance;
}
function get_hex(_this__u8e3s4) {
  return Hex_instance.z24(_this__u8e3s4);
}
function get_shex(_this__u8e3s4) {
  return Hex_instance.b25(_this__u8e3s4);
}
function appendHexByte(_this__u8e3s4, value) {
  return Hex_instance.y24(_this__u8e3s4, value);
}
function get_hex_0(_this__u8e3s4) {
  return '0x' + get_shex(_this__u8e3s4);
}
function get_hex_1(_this__u8e3s4) {
  return '0x' + get_shex_0(_this__u8e3s4);
}
function get_shex_0(_this__u8e3s4) {
  return Hex_instance.a25(_this__u8e3s4);
}
function get_hexLower(_this__u8e3s4) {
  return Hex_instance.z24(_this__u8e3s4);
}
//region block: post-declaration
initMetadataForObject(Base64, 'Base64');
initMetadataForObject(Hex, 'Hex');
//endregion
//region block: init
Hex_instance = new Hex();
//endregion
//region block: exports
export {
  appendHexByte as appendHexByte1xtb46j7ij290,
  fromBase64 as fromBase6434uveajlhn2ar,
  get_hex as get_hex3lw8e6gsph9rg,
  get_hex_1 as get_hex2zltmntpniwff,
  get_hex_0 as get_hex32p4ssq0y1xxx,
  get_hexLower as get_hexLower32nr02qmg5e1t,
};
//endregion
