import {
  VOID7hggqo3abtya as VOID,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  Duration5ynfiptaqcrg as Duration,
  Duration__times_impl_sfuzvped9o6pl6oatt as Duration__times_impl_sfuzvp,
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  toString30pk9tzaqopn as toString,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  equals2au1ep9vhcato as equals,
  hashCodeq5arwsb9dgti as hashCode,
  toShort36kaw0zjdq3ex as toShort,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  ArrayList3it5z8td81qkl as ArrayList,
  lazy2hsh8ze7j6ikd as lazy,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  sequence2vgswtrxvqoa7 as sequence,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  SequenceScope1coiso86pqzq2 as SequenceScope,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  removeLast3759euu1xvfa3 as removeLast,
  protoOf180f3jzyo7rfj as protoOf,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  last2n4gf5az1lkn4 as last,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  Char__minus_impl_a2frrhp8twy13wqz9c as Char__minus_impl_a2frrh,
  startsWith26w8qjqapeeq6 as startsWith,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  LazyThreadSafetyMode_PUBLICATION_getInstance3hlj875zwihx0 as LazyThreadSafetyMode_PUBLICATION_getInstance,
  lazy1261dae0bgscp as lazy_0,
  Enum3alwj03lh1n41 as Enum,
  NotImplementedErrorfzlkpv14xxr8 as NotImplementedError,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString_0,
  compareTo3ankvs086tmwq as compareTo,
  coerceIn10f36k81le1mm as coerceIn,
  getKClass1s3j9wy1cofik as getKClass,
  arrayOf1akklvh2at202 as arrayOf,
  createKType1lgox3mzhchp5 as createKType,
  isInterface3d6p8outrmvmk as isInterface,
  println2shhhgwwt4c61 as println,
  Monotonic_instance19wu6xfdyzm85 as Monotonic_instance,
  ValueTimeMark__elapsedNow_impl_eonqvs2ntz2k8jdtq63 as ValueTimeMark__elapsedNow_impl_eonqvs,
  TimedValuew9j01dao9jci as TimedValue,
  Duration__toString_impl_8d916b1f2vqqjkgwgjr as Duration__toString_impl_8d916b,
  emptyList1g2z5xcrvp2zy as emptyList,
  emptyMapr06gerzljqtm as emptyMap,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  toLongw1zpgk99d84b as toLong,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  substringBefore3n7kj60w69hju as substringBefore,
  substringAfter1hku067gwr5ve as substringAfter,
  createThis2j2avj17cvnv2 as createThis,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  IndexOutOfBoundsException1qfr429iumro0 as IndexOutOfBoundsException,
  round2mrvepag8eey0 as round,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  toByte4i43936u611k as toByte,
  StringBuildermazzzhj6kkai as StringBuilder,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  until1jbpn0z3f8lbg as until,
  copyToArray2j022khrow2yi as copyToArray,
  firstOrNull175qkyx53x0vd as firstOrNull,
  DurationUnit_SECONDS_getInstance3jias9ne5z4er as DurationUnit_SECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  Duration__plus_impl_yu9v8f1x7qwk17prwx4 as Duration__plus_impl_yu9v8f,
  checkIndexOverflow3frtmheghr0th as checkIndexOverflow,
  addAll1k27qatfgp3k5 as addAll,
  arrayConcat3qsij7vh68m69 as arrayConcat,
  KtMap140uvy3s5zad8 as KtMap,
  arrayListOf1fz8nib0ncbow as arrayListOf,
  listOf1jh22dvmctj1r as listOf,
  last1vo29oleiqj36 as last_0,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Companion_instance18k7zco3357fp as Companion_instance,
  AGIndexType__toString_impl_hjqwm32mdzx8y07ki6u as AGIndexType__toString_impl_hjqwm3,
  AGDrawType__toString_impl_eonaeb25w5br3wcp5j8 as AGDrawType__toString_impl_eonaeb,
  AGIndexType__hashCode_impl_47f06sm5x1b5uy8g0k as AGIndexType__hashCode_impl_47f06s,
  AGDrawType__hashCode_impl_ylauryzr6i7tjdryw8 as AGDrawType__hashCode_impl_ylaury,
  Companion_getInstance1r9b3td34f16o as Companion_getInstance_0,
  AGDrawType11vxw4gf0yugk as AGDrawType,
  ProgramLayout373hh4xkth3qt as ProgramLayout,
  Companion_getInstance2nqpuzpsa8xr1 as Companion_getInstance_1,
  AGBuffer24eh7xseqt7zt as AGBuffer,
  AGVertexDatawn78bdwnwjab as AGVertexData,
  AgBitmapTextureManager2xbtbx0jd1or0 as AgBitmapTextureManager,
  AgBufferManager1sha16u5by4lx as AgBufferManager,
  Companion_getInstance1c5y8vpd826by as Companion_getInstance_2,
  Companion_getInstance2v5l7b83xykmk as Companion_getInstance_3,
  AGDepthAndFrontFace__withDepthFunc_impl_7t7yrshaluzbo1kv57 as AGDepthAndFrontFace__withDepthFunc_impl_7t7yrs,
  AGDepthAndFrontFace__withDepth_impl_wsx82k3od8qukq65bpe as AGDepthAndFrontFace__withDepth_impl_wsx82k,
  Companion_getInstance3jgth1v8sehkm as Companion_getInstance_4,
  ProjViewUB_getInstance24kbqbtea649t as ProjViewUB_getInstance,
  AGTextureUnits3k80ikbk5qbv9 as AGTextureUnits,
  Companion_getInstance21hbm5vhzozgd as Companion_getInstance_5,
  AGTextureUnitInfo__withLinear_impl_jl8yudmrq2e4l66lkn as AGTextureUnitInfo__withLinear_impl_jl8yud,
  Companion_getInstance2gaa7ku0bx2qt as Companion_getInstance_6,
  AGTextureUnitInfo__withWrap_impl_akwb6y3ls6427f6ddao as AGTextureUnitInfo__withWrap_impl_akwb6y,
  AGVertexArrayObjectp6l6cor3jhq8 as AGVertexArrayObject,
  drawfyi5eaogony1 as draw,
  values27fkm2c0pan63 as values,
  Companion_instance3fmek7gj0zzwa as Companion_instance_0,
  VarKind_TBYTE_getInstance2f4bqopvsa557 as VarKind_TBYTE_getInstance,
  VarKind_TUNSIGNED_BYTE_getInstancelmv3e9ctu0su as VarKind_TUNSIGNED_BYTE_getInstance,
  VarKind_TSHORT_getInstance3bz2uawb9nt6z as VarKind_TSHORT_getInstance,
  VarKind_TUNSIGNED_SHORT_getInstance1vvmqqcsbjp9 as VarKind_TUNSIGNED_SHORT_getInstance,
  VarKind_TINT_getInstance3fbaa1lxq42j as VarKind_TINT_getInstance,
  VarKind_TFLOAT_getInstance3qr8a0l2vrn4p as VarKind_TFLOAT_getInstance,
  AGIndexTypenyfo4cxtrg69 as AGIndexType,
  _AGIndexType___get_bytesSize__impl__lsf1842zwb6tiav2tom as _AGIndexType___get_bytesSize__impl__lsf184,
  Companion_getInstance2sk7vs29gnik3 as Companion_getInstance_7,
  AGBatch2bse24318tbhc as AGBatch,
  draw3n7i8s75fp58g as draw_0,
  UniformBlock2qoqszhm3r9i2 as UniformBlock,
  DefaultShaders_getInstance11xvl6rr8iso as DefaultShaders_getInstance,
  Attribute3obvnbxr5w4xd as Attribute,
  VarType_Float3_getInstance2ijj9ybtzxz8w as VarType_Float3_getInstance,
  Precision_HIGH_getInstance6iuog1cw3jr0 as Precision_HIGH_getInstance,
  Precision_LOW_getInstance3sce4muk7g3c6 as Precision_LOW_getInstance,
  VarType_Float4_getInstanceomsfyancnlr as VarType_Float4_getInstance,
  VarType_Float2_getInstance26tgtz9tkdb0r as VarType_Float2_getInstance,
  Precision_MEDIUM_getInstance45g8detuxjnt as Precision_MEDIUM_getInstance,
  Varying105nnu9x9j75f as Varying,
  Temp2o9ep2ww9mag1 as Temp,
  VarType_Mat4_getInstance2srh53egil2ho as VarType_Mat4_getInstance,
  Sampler1pcjbdzdxbif3 as Sampler,
  SamplerVarType_Sampler2D_getInstancepf6ffmb89evn as SamplerVarType_Sampler2D_getInstance,
  ShaderType_VERTEX_getInstance1cr8wwngbi4ix as ShaderType_VERTEX_getInstance,
  Companion_instance21gpry3t4pmsj as Companion_instance_1,
  Mutable1g6izonky2e38 as Mutable,
  GlslConfig298xixs7efncl as GlslConfig,
  GlslGenerator2uoj59ir73hv0 as GlslGenerator,
  ShaderType_FRAGMENT_getInstance2q2lcwtkv0h13 as ShaderType_FRAGMENT_getInstance,
  VarType_Float1_getInstance2yyqoe6yly9a9 as VarType_Float1_getInstance,
  If1av5krmfyt44s as If,
  ProgramBuilderDefault3573mhybddt0b as ProgramBuilderDefault,
  VertexShader26kcg39ndf3s1 as VertexShader,
  FragmentShader2u3ycaqbgbmdi as FragmentShader,
  Program1hnygp9hph4ry as Program,
  ArrayAccessethjey8s09dj as ArrayAccess,
} from './korge-root-korge-core.mjs';
import {
  get_degrees3czcd4ojw4i as get_degrees,
  _Angle___get_cosine__impl__vprvmu3fuyxpbgo71fk as _Angle___get_cosine__impl__vprvmu,
  _Angle___get_sine__impl__15nmyi1kdagydgbnjhl as _Angle___get_sine__impl__15nmyi,
  Vector4F1clr35ctch8js as Vector4F,
  get_degrees1wvulgxbo1fy6 as get_degrees_0,
  Companion_getInstance2175iaprojxbf as Companion_getInstance_8,
  Vector2F2yb91r49vy4m7 as Vector2F,
  Quaternion3no7g5rnd4z1g as Quaternion,
  _EulerRotation___init__impl__bezlqm15akoevhh9e0u as _EulerRotation___init__impl__bezlqm,
  Companion_getInstance2npyq9n96lvll as Companion_getInstance_9,
  Companion_getInstance145etojj24o8w as Companion_getInstance_10,
  get_radians3l1td1j6jwrs as get_radians,
  Companion_getInstanceujowwr66h2x9 as Companion_getInstance_11,
  Vector3F3dkkvai0hm6xy as Vector3F,
} from './korlibs-korlibs-math-vector.mjs';
import {
  MMatrix4250i8g1w6yxgo as MMatrix4,
  Companion_instance1oet36wi14ql2 as Companion_instance_2,
  MVector42dr6dus5luca5 as MVector4,
  get_immutable3bx5i4my5ro9n as get_immutable,
  get_mutable3t5h9feuvhrnv as get_mutable,
} from './korlibs-korlibs-math.mjs';
import {
  FastArrayListw5fr1wlp4slq as FastArrayList,
  toFastList1btvvxvv57pw as toFastList,
  fastArrayListOf1rdodhrpn0q8y as fastArrayListOf,
  Mixin3lmkm5hum2vdj as Mixin,
} from './korlibs-korlibs-datastructure-core.mjs';
import {
  floatArrayListOfa8snvg2p0fhc as floatArrayListOf,
  ShortArrayList24204ipipacat as ShortArrayList,
  Pool1zbsw0zhvh8ca as Pool,
  Ref2y4j4snnxo6gs as Ref,
  DoubleVectorArrayListq54dfthnqi2t as DoubleVectorArrayList,
} from './korlibs-korlibs-datastructure.mjs';
import {
  View11ybmmoof9vue as View,
  BaseView2hxxa0hhvhngi as BaseView,
} from './korge-root-korge.mjs';
import {
  setToColorPremultiplied262aculpj6azp as setToColorPremultiplied,
  Colors_getInstance1qvds1isfbag2 as Colors_getInstance,
  _RGBA___get_premultiplied__impl__3bulqcbb8bnh8j0y24 as _RGBA___get_premultiplied__impl__3bulqc,
  get_nativeImageFormatProvidery5ujkkhajtmi as get_nativeImageFormatProvider,
  Bitmaps_instancebct336c8d0m7 as Bitmaps_instance,
  get_Bitmaps_transparent3nm2857y3u3hn as get_Bitmaps_transparent,
  get_bmpevghw2zw2fa7 as get_bmp,
  Companion_getInstanceocd6ti8e4l21 as Companion_getInstance_12,
  RGBA__toString_impl_4lw6dp2cnrrhh9rakoc as RGBA__toString_impl_4lw6dp,
  RGBA__hashCode_impl_h59qf63jsbsnnrny7w3 as RGBA__hashCode_impl_h59qf6,
} from './korlibs-korlibs-image.mjs';
import {
  arrayfill2iqmyqqqfwuaf as arrayfill,
  arraycopy3uxsqjlixmnz3 as arraycopy,
  arrayequal25g4om83vd8w1 as arrayequal,
  Buffer25z94vsj0kb1s as Buffer,
  get_i83qhdj74rhdyeg as get_i8,
  Int8Buffer__getArray$default_impl_hlbc10e8bl8n5jkqf0 as Int8Buffer__getArray$default_impl_hlbc10,
  sliceWithSize2i7i6olf26byj as sliceWithSize,
  sliceBufferpiate0wictoq as sliceBuffer,
  Int8Buffer__get_impl_amhyyo5htdu7x32gnh as Int8Buffer__get_impl_amhyyo,
  get_i1618yeitcooc2c6 as get_i16,
  Int16Buffer__get_impl_nt0z853qm50aljuefot as Int16Buffer__get_impl_nt0z85,
  get_u163bxpdbbave1v5 as get_u16,
  Uint16Buffer__get_impl_6qi0dm2c05p5vb6bqo5 as Uint16Buffer__get_impl_6qi0dm,
  get_i323p4hlz6j3xys8 as get_i32,
  Int32Buffer__get_impl_48ysyj21kw6kt95s8lz as Int32Buffer__get_impl_48ysyj,
  get_f3218rglfhpgbhzp as get_f32,
  Float32Buffer__get_impl_659lhu25y6ts3w0gs8j as Float32Buffer__get_impl_659lhu,
  Int8Buffer__set_impl_jwkk3w1mx9n6g4w36y3 as Int8Buffer__set_impl_jwkk3w,
  Int16Buffer__set_impl_x33kdd2g8e6x8n4uak0 as Int16Buffer__set_impl_x33kdd,
  Int32Buffer__set_impl_dj1e3r3rgggtatuzfx6 as Int32Buffer__set_impl_dj1e3r,
  Float32Buffer__set_impl_34sznevuviq8wx4pjc as Float32Buffer__set_impl_34szne,
  Companion_instanceimfvy8b5dxd9 as Companion_instance_3,
  allocDirecttfrgytwudkpf as allocDirect,
  setArrayFloat322635lx2p290a1 as setArrayFloat32,
  setArrayInt163i1kdo3ludp9x as setArrayInt16,
} from './korlibs-korlibs-memory.mjs';
import {
  clamp31lhm66c28ic8 as clamp,
  convertRange378kqy75r4gq5 as convertRange,
  clamp012a0sd8pj80ati as clamp01,
  toRatiolga1a9coidva as toRatio,
  interpolate1wglaj6k11c6v as interpolate,
} from './korlibs-korlibs-math-core.mjs';
import {
  PluginGeneratedSerialDescriptorqdzeg5asqhfg as PluginGeneratedSerialDescriptor,
  StringSerializer_getInstance1k1oz5j50sfik as StringSerializer_getInstance,
  UnknownFieldExceptiona60e3a6v1xqo as UnknownFieldException,
  get_nullable197rfua9r7fsz as get_nullable,
  typeParametersSerializers2likxjr48tr7y as typeParametersSerializers,
  GeneratedSerializer1f7t7hssdd2ws as GeneratedSerializer,
  IntArraySerializer_getInstance23lwf0o9n75js as IntArraySerializer_getInstance,
  FloatArraySerializer_getInstance6swinqh9bas6 as FloatArraySerializer_getInstance,
  IntSerializer_getInstance9scrtcljaiv6 as IntSerializer_getInstance,
  ArrayListSerializer7k5wnrulb3y6 as ArrayListSerializer,
  InlineClassDescriptor2odlvyasjrmr0 as InlineClassDescriptor,
  LinkedHashMapSerializermaoj2nyji7op as LinkedHashMapSerializer,
  SerializerFactory1qv9hivitncuv as SerializerFactory,
  throwMissingFieldException2cmke0v3ynf14 as throwMissingFieldException,
  STRING_getInstance2gbamclnmtzqa as STRING_getInstance,
  PrimitiveSerialDescriptor3egfp53lutxj2 as PrimitiveSerialDescriptor,
  KSerializerzf77vz1967fq as KSerializer,
  createSimpleEnumSerializer2guioz11kk1m0 as createSimpleEnumSerializer,
  BooleanSerializer_getInstancet3wtk7fl7i4f as BooleanSerializer_getInstance,
  FloatSerializer_getInstance1vxxfqtcay8g1 as FloatSerializer_getInstance,
  PolymorphicSerializer3p3fzpdobi8hh as PolymorphicSerializer,
  serializer1i4e9ym37oxmo as serializer,
} from './kotlinx-serialization-kotlinx-serialization-core.mjs';
import {
  JsonElementSerializer_getInstance1rgo1giz8g15y as JsonElementSerializer_getInstance,
  Jsonsmkyu9xjl7fv as Json,
} from './kotlinx-serialization-kotlinx-serialization-json.mjs';
import {
  asMemoryVfsFile18fsaquwjql18 as asMemoryVfsFile,
  get_extensionLC3gx4qkgbywkav as get_extensionLC,
} from './korlibs-korlibs-io-vfs.mjs';
import {
  substr1q77u5lo4evr6 as substr,
  Charsets_instancenv17fp98ne59 as Charsets_instance,
  toString2x583ovdqfjci as toString_1,
} from './korlibs-korlibs-string.mjs';
import {
  Companion_getInstance1lqql7kjbk545 as Companion_getInstance_13,
  Level_TRACE_getInstance3v3hjwgnnbwb6 as Level_TRACE_getInstance,
} from './korlibs-korlibs-logger.mjs';
import {
  openSync2m3qn99fqytu0 as openSync,
  readString20u8kjncp0xlx as readString,
  readS32LE3aqh95gxznf4n as readS32LE,
  readStringz3jxaogr49n5ys as readStringz,
  readStreamp6bgvjd275ek as readStream,
  toByteArray1sz37t46781i3 as toByteArray,
} from './korlibs-korlibs-io-stream.mjs';
import { fromBase6434uveajlhn2ar as fromBase64 } from './korlibs-korlibs-encoding.mjs';
import { Companion_getInstance2o41ajwjrmgx as Companion_getInstance_14 } from './korlibs-korlibs-io.mjs';
import { get_secondsjblpw5qd4dzj as get_seconds } from './korlibs-korlibs-time-core.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class View3D extends BaseView {
  static kkz() {
    var $this = this.pgb();
    $this.wkv_1 = true;
    $this.xkv_1 = null;
    $this.ykv_1 = null;
    var tmp = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.View3D.transform.<anonymous>' call
    tmp.zkv_1 = new Transform3D();
    $this.akw_1 = 1.0;
    $this.bkw_1 = Companion_getInstance_4().ta5_1;
    $this.ckw_1 = null;
    $this.dkw_1 = new MMatrix4();
    return $this;
  }
  dl0() {
    var tmp;
    if (this instanceof Stage3D) {
      tmp = this;
    } else {
      var tmp30_safe_receiver = this.w15();
      tmp = tmp30_safe_receiver == null ? null : tmp30_safe_receiver.dl0();
    }
    return tmp;
  }
  kcb() {
    var tmp31_safe_receiver = this.w15();
    var tmp32_safe_receiver = tmp31_safe_receiver == null ? null : tmp31_safe_receiver.kcb();
    var tmp0_elvis_lhs = tmp32_safe_receiver == null ? null : tmp32_safe_receiver * this.akw_1;
    return tmp0_elvis_lhs == null ? this.akw_1 : tmp0_elvis_lhs;
  }
  hbh() {
    var tmp = this.f22();
    var stage3D = tmp instanceof Stage3D ? tmp : null;
    var tmp34_safe_receiver = stage3D == null ? null : stage3D.zl5_1;
    var tmp35_safe_receiver = tmp34_safe_receiver == null ? null : tmp34_safe_receiver.hl6_1;
    if (tmp35_safe_receiver == null)
      null;
    else {
      tmp35_safe_receiver.aca(this);
    }
  }
  pky(value) {
    if (this.ckw_1 === value)
      return Unit_instance;
    this.ckw_1 = value;
    this.zcc();
    this.hbh();
    this.j8a(value);
  }
  w15() {
    return this.ckw_1;
  }
  zcc() {
  }
  qky() {
    return Companion_getInstance_9().s2p_1;
  }
  rky(ctx) {
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.fl3().y8r(ProjViewUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge3d.View3D.putUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      it.m9i($this$push.q8z(), get_immutable(ctx.tl2_1));
      it.m9i($this$push.r8z(), get_immutable(this.zkv_1.ybp()));
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    $l$block_0: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_1 = ctx.fl3().y8r(K3DPropsUB_getInstance());
      var _unary__edvuaz_1 = this_1.n8t();
      this_1.v9h(_unary__edvuaz_1 + 1 | 0);
      this_1.w9h(this_1.n8t() + 1 | 0);
      var blockSize_0 = this_1.r8r_1;
      var index0_0 = imul(this_1.n8t() - 1 | 0, blockSize_0);
      var index1_0 = imul(this_1.n8t(), blockSize_0);
      var texBlockSize_0 = this_1.o8r_1.s8z().j1();
      var texIndex0_0 = imul(this_1.n8t() - 1 | 0, texBlockSize_0);
      var texIndex1_0 = imul(this_1.n8t(), texBlockSize_0);
      if (this_1.n8t() > 0) {
        arraycopy(this_1.t8r_1, index0_0, this_1.t8r_1, index1_0, blockSize_0);
      } else {
        arrayfill(this_1.t8r_1, 0, 0, blockSize_0);
      }
      // Inline function 'korlibs.korge3d.View3D.putUniforms.<anonymous>' call
      var $this$push_0 = this_1.o8r_1;
      var it_0 = this_1.u8r_1;
      it_0.m9i($this$push_0.kl7(), Companion_getInstance_9().s2p_1);
      it_0.m9i($this$push_0.ll7(), this.qky().j2r(get_immutable(this.dkw_1)));
      it_0.b9i($this$push_0.ml7(), ctx.al3_1);
      if (true && this_1.n8t() >= 1) {
        var equals_0 = arrayequal(this_1.t8r_1, index0_0, this_1.t8r_1, index1_0, blockSize_0);
        if (equals_0) {
          var _unary__edvuaz_2 = this_1.n8t();
          this_1.v9h(_unary__edvuaz_2 - 1 | 0);
          break $l$block_0;
        }
      }
    }
    // Inline function 'korlibs.datastructure.iterators.fastForEachWithIndex' call
    var this_2 = ctx.sl2_1;
    var n = 0;
    while (n < this_2.j1()) {
      // Inline function 'korlibs.korge3d.View3D.putUniforms.<anonymous>' call
      var index = n;
      var light = this_2.i1(n);
      $l$block_1: {
        // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
        var this_3 = ctx.fl3().y8r(Companion_getInstance_51().zl1_1.i1(index));
        var _unary__edvuaz_3 = this_3.n8t();
        this_3.v9h(_unary__edvuaz_3 + 1 | 0);
        this_3.w9h(this_3.n8t() + 1 | 0);
        var blockSize_1 = this_3.r8r_1;
        var index0_1 = imul(this_3.n8t() - 1 | 0, blockSize_1);
        var index1_1 = imul(this_3.n8t(), blockSize_1);
        var texBlockSize_1 = this_3.o8r_1.s8z().j1();
        var texIndex0_1 = imul(this_3.n8t() - 1 | 0, texBlockSize_1);
        var texIndex1_1 = imul(this_3.n8t(), texBlockSize_1);
        if (this_3.n8t() > 0) {
          arraycopy(this_3.t8r_1, index0_1, this_3.t8r_1, index1_1, blockSize_1);
        } else {
          arrayfill(this_3.t8r_1, 0, 0, blockSize_1);
        }
        // Inline function 'korlibs.korge3d.View3D.putUniforms.<anonymous>.<anonymous>' call
        var $this$push_1 = this_3.o8r_1;
        var it_1 = this_3.u8r_1;
        it_1.k9i($this$push_1.wl7(), get_immutable_0(light.zkv_1.ol6()));
        it_1.h9i($this$push_1.nhu(), light.jl8_1);
        it_1.k9i($this$push_1.ol8(), get_immutable_0(light.nl8_1.w4f(light.kl8_1, light.ll8_1, light.ml8_1)));
        if (true && this_3.n8t() >= 1) {
          var equals_1 = arrayequal(this_3.t8r_1, index0_1, this_3.t8r_1, index1_1, blockSize_1);
          if (equals_1) {
            var _unary__edvuaz_4 = this_3.n8t();
            this_3.v9h(_unary__edvuaz_4 - 1 | 0);
            break $l$block_1;
          }
        }
      }
      n = n + 1 | 0;
    }
  }
  f22() {
    var tmp36_safe_receiver = this.w15();
    var tmp0_elvis_lhs = tmp36_safe_receiver == null ? null : tmp36_safe_receiver.f22();
    return tmp0_elvis_lhs == null ? this : tmp0_elvis_lhs;
  }
}
class Camera3D extends View3D {
  static kky() {
    var $this = this.kkz();
    $this.vkx_1 = new MMatrix4();
    $this.wkx_1 = 0.0;
    $this.xkx_1 = 0.0;
    $this.ykx_1 = true;
    $this.zkx_1 = $this;
    $this.aky_1 = new Vector4F(0.0, 1.0, 10.0, 1.0);
    $this.bky_1 = get_degrees(-90);
    $this.cky_1 = get_degrees_0(0.0);
    $this.dky_1 = get_degrees_0(0.0);
    $this.eky_1 = get_degrees(45);
    $this.fky_1 = Companion_instance_2.h4f(0.0, 0.0, -1.0);
    $this.gky_1 = Companion_instance_2.h4f(0.0, 1.0, 0.0);
    $this.hky_1 = Companion_instance_2.h4f(0.0, 1.0, 0.0);
    $this.iky_1 = new MVector4();
    $this.jky_1 = (new MVector4()).d4g($this.fky_1, $this.hky_1).b4g();
    update($this);
    return $this;
  }
  mky(_set____db54di) {
    this.zkx_1 = _set____db54di;
  }
  f22() {
    return this.zkx_1;
  }
  nky(width, height) {
    if (!(this.wkx_1 === width) || !(this.xkx_1 === height)) {
      this.ykx_1 = true;
      this.wkx_1 = width;
      this.xkx_1 = height;
    }
    if (this.ykx_1) {
      this.ykx_1 = false;
      this.lky(this.vkx_1, this.wkx_1, this.xkx_1);
    }
    return this.vkx_1;
  }
  oky(ctx) {
  }
}
class Perspective extends Camera3D {
  static ikx(fov, near, far) {
    fov = fov === VOID ? get_degrees(60) : fov;
    near = near === VOID ? 0.5 : near;
    far = far === VOID ? 100.0 : far;
    var $this = this.kky();
    $this.fkx_1 = fov;
    $this.gkx_1 = near;
    $this.hkx_1 = far;
    return $this;
  }
  lky(mat, width, height) {
    mat.v4h(this.fkx_1, !(height === 0.0) ? width / height : 1.0, this.gkx_1, this.hkx_1);
  }
}
class Container3D extends View3D {
  static zkz() {
    var $this = this.kkz();
    $this.xkz_1 = FastArrayList.g14();
    $this.ykz_1 = null;
    return $this;
  }
  s16() {
    return this.xkz_1;
  }
  al0(child) {
    if (!this.xkz_1.n3(child))
      return Unit_instance;
    child.pky(null);
  }
  bl0(child) {
    removeFromParent(child);
    // Inline function 'kotlin.collections.plusAssign' call
    this.xkz_1.f(child);
    child.pky(this);
    child.zkv_1.cl0(this.zkv_1);
    this.hbh();
  }
  oky(ctx) {
    // Inline function 'korlibs.korge3d.Container3D.fastForEachChild' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.s16();
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.Container3D.render.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      this_0.i1(_unary__edvuaz).oky(ctx);
    }
  }
  o8a(type, event, result) {
    if (this.ykz_1 == null)
      this.ykz_1 = FastArrayList.f14(this.s16().j1());
    // Inline function 'korlibs.datastructure.iterators.fastForEachWithTemp' call
    var this_0 = this.xkz_1;
    var temp = ensureNotNull(this.ykz_1);
    toFastList(this_0, temp);
    try {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = temp.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.korge3d.Container3D.dispatchChildren.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        array[_unary__edvuaz].t8a(type, event, result);
      }
    }finally {
      temp.o3();
    }
  }
}
class Light3D extends View3D {}
class Companion {
  constructor() {
    Companion_instance_4 = this;
    var tmp = this;
    var tmp_0 = Companion_instance_5;
    tmp.el0_1 = tmp_0.fl0(VOID, Mesh3D$Companion$EMPTY$lambda);
  }
}
class Mesh3D {
  constructor(vertexBuffers, indexBuffer, indexType, vertexCount, program, drawType, hasTexture, maxWeights, skin, material) {
    Companion_getInstance_15();
    hasTexture = hasTexture === VOID ? false : hasTexture;
    maxWeights = maxWeights === VOID ? 0 : maxWeights;
    skin = skin === VOID ? null : skin;
    material = material === VOID ? null : material;
    this.gl0_1 = vertexBuffers;
    this.hl0_1 = indexBuffer;
    this.il0_1 = indexType;
    this.jl0_1 = vertexCount;
    this.kl0_1 = program;
    this.ll0_1 = drawType;
    this.ml0_1 = hasTexture;
    this.nl0_1 = maxWeights;
    this.ol0_1 = skin;
    this.pl0_1 = material;
  }
  toString() {
    return 'Mesh3D(vertexBuffers=' + this.gl0_1.toString() + ', indexBuffer=' + this.hl0_1.toString() + ', indexType=' + AGIndexType__toString_impl_hjqwm3(this.il0_1) + ', vertexCount=' + this.jl0_1 + ', program=' + toString(this.kl0_1) + ', drawType=' + AGDrawType__toString_impl_eonaeb(this.ll0_1) + ', hasTexture=' + this.ml0_1 + ', maxWeights=' + this.nl0_1 + ', skin=' + toString(this.ol0_1) + ', material=' + toString(this.pl0_1) + ')';
  }
  hashCode() {
    var result = this.gl0_1.hashCode();
    result = imul(result, 31) + this.hl0_1.hashCode() | 0;
    result = imul(result, 31) + AGIndexType__hashCode_impl_47f06s(this.il0_1) | 0;
    result = imul(result, 31) + this.jl0_1 | 0;
    result = imul(result, 31) + (this.kl0_1 == null ? 0 : this.kl0_1.hashCode()) | 0;
    result = imul(result, 31) + AGDrawType__hashCode_impl_ylaury(this.ll0_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.ml0_1) | 0;
    result = imul(result, 31) + this.nl0_1 | 0;
    result = imul(result, 31) + (this.ol0_1 == null ? 0 : this.ol0_1.hashCode()) | 0;
    result = imul(result, 31) + (this.pl0_1 == null ? 0 : this.pl0_1.hashCode()) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Mesh3D))
      return false;
    var tmp0_other_with_cast = other instanceof Mesh3D ? other : THROW_CCE();
    if (!this.gl0_1.equals(tmp0_other_with_cast.gl0_1))
      return false;
    if (!this.hl0_1.equals(tmp0_other_with_cast.hl0_1))
      return false;
    if (!(this.il0_1 === tmp0_other_with_cast.il0_1))
      return false;
    if (!(this.jl0_1 === tmp0_other_with_cast.jl0_1))
      return false;
    if (!equals(this.kl0_1, tmp0_other_with_cast.kl0_1))
      return false;
    if (!(this.ll0_1 === tmp0_other_with_cast.ll0_1))
      return false;
    if (!(this.ml0_1 === tmp0_other_with_cast.ml0_1))
      return false;
    if (!(this.nl0_1 === tmp0_other_with_cast.nl0_1))
      return false;
    if (!equals(this.ol0_1, tmp0_other_with_cast.ol0_1))
      return false;
    if (!equals(this.pl0_1, tmp0_other_with_cast.pl0_1))
      return false;
    return true;
  }
}
class BufferWithVertexLayout {
  constructor(buffer, layout) {
    this.ql0_1 = buffer;
    this.rl0_1 = layout;
    this.sl0_1 = this.rl0_1.e8u_1;
    this.tl0_1 = this.sl0_1 / 4 | 0;
  }
  toString() {
    return 'BufferWithVertexLayout(buffer=' + this.ql0_1.toString() + ', layout=' + this.rl0_1.toString() + ')';
  }
  hashCode() {
    var result = this.ql0_1.hashCode();
    result = imul(result, 31) + hashCode(this.rl0_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof BufferWithVertexLayout))
      return false;
    var tmp0_other_with_cast = other instanceof BufferWithVertexLayout ? other : THROW_CCE();
    if (!this.ql0_1.equals(tmp0_other_with_cast.ql0_1))
      return false;
    if (!equals(this.rl0_1, tmp0_other_with_cast.rl0_1))
      return false;
    return true;
  }
}
class Companion_0 {
  ul0(drawType, callback) {
    // Inline function 'kotlin.apply' call
    var this_0 = new MeshBuilder3D(drawType);
    // Inline function 'kotlin.contracts.contract' call
    callback(this_0);
    return this_0.bl1();
  }
  fl0(drawType, callback, $super) {
    drawType = drawType === VOID ? Companion_getInstance_0().l8u_1 : drawType;
    return $super === VOID ? this.ul0(drawType, callback) : $super.ul0.call(this, new AGDrawType(drawType), callback);
  }
}
class MeshBuilder3D {
  constructor(drawType) {
    drawType = drawType === VOID ? Companion_getInstance_0().l8u_1 : drawType;
    this.vl0_1 = drawType;
    this.wl0_1 = ProgramLayout.f8u([Companion_getInstance_51().el1_1, Companion_getInstance_51().fl1_1, Companion_getInstance_51().il1_1]);
    this.xl0_1 = floatArrayListOf(new Float32Array([]));
    this.yl0_1 = 0;
    this.zl0_1 = new ShortArrayList();
    this.al1_1 = null;
  }
  hl2(pos, normal, texcoords) {
    return this.il2(pos.k2x_1, pos.l2x_1, pos.m2x_1, normal.k2x_1, normal.l2x_1, normal.m2x_1, texcoords.y2x_1, texcoords.z2x_1);
  }
  jl2(pos, normal, texcoords, $super) {
    normal = normal === VOID ? Companion_getInstance_8().c2x_1 : normal;
    texcoords = texcoords === VOID ? Vector2F.a2y(pos.k2x_1, pos.l2x_1) : texcoords;
    return $super === VOID ? this.hl2(pos, normal, texcoords) : $super.hl2.call(this, pos, normal, texcoords);
  }
  il2(px, py, pz, nx, ny, nz, u, v) {
    this.xl0_1.w30(px);
    this.xl0_1.w30(py);
    this.xl0_1.w30(pz);
    this.xl0_1.w30(nx);
    this.xl0_1.w30(ny);
    this.xl0_1.w30(nz);
    this.xl0_1.w30(u);
    this.xl0_1.w30(v);
    var _unary__edvuaz = this.yl0_1;
    this.yl0_1 = _unary__edvuaz + 1 | 0;
    return _unary__edvuaz;
  }
  kl2(index) {
    this.zl0_1.u32(toShort(index));
  }
  bl1() {
    return new Mesh3D(fastArrayListOf([new BufferWithVertexLayout(toNBuffer(this.xl0_1), this.wl0_1)]), toNBuffer_0(this.zl0_1), Companion_getInstance_1().w8k_1, this.zl0_1.j1(), null, this.vl0_1, true, 0, null, this.al1_1);
  }
}
class RenderContext3D {
  constructor() {
    this.nl2_1 = new Shaders3D();
    this.ol2_1 = new MMatrix4();
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(128), null);
    while (tmp_0 < 128) {
      tmp_1[tmp_0] = new MMatrix4();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.pl2_1 = tmp_1;
    this.ql2_1 = new MMatrix4();
    this.rl2_1 = new MMatrix4();
    var tmp_2 = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp_2.sl2_1 = ArrayList.m1();
    this.tl2_1 = new MMatrix4();
    this.ul2_1 = new MMatrix4();
    this.vl2_1 = new MMatrix4();
    var tmp_3 = this;
    tmp_3.wl2_1 = Pool.e39(VOID, RenderContext3D$dynamicVertexBufferPool$lambda);
    var tmp_4 = this;
    tmp_4.xl2_1 = Pool.e39(VOID, RenderContext3D$dynamicVertexDataPool$lambda);
    var tmp_5 = this;
    tmp_5.yl2_1 = Pool.e39(VOID, RenderContext3D$dynamicIndexBufferPool$lambda);
    this.zl2_1 = new MVector4();
    this.al3_1 = 1.0;
    var tmp_6 = this;
    tmp_6.bl3_1 = lazy(RenderContext3D$textureManager$delegate$lambda(this));
    var tmp_7 = this;
    tmp_7.cl3_1 = lazy(RenderContext3D$bufferManager$delegate$lambda(this));
    this.dl3_1 = AGDepthAndFrontFace__withDepth_impl_wsx82k(AGDepthAndFrontFace__withDepthFunc_impl_7t7yrs(Companion_getInstance_2().s8l_1, Companion_getInstance_3().r8w_1), 0.0, 1.0);
    this.el3_1 = FastArrayList.g14();
  }
  saq() {
    var tmp = this.ll2_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('ag');
    }
  }
  fl3() {
    var tmp = this.ml2_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('rctx');
    }
  }
}
class Stage3DView extends View {
  static kl5(views, container) {
    var $this = this.hgn();
    $this.gl5_1 = views;
    $this.hl5_1 = container;
    $this.il5_1 = Stage3D.fl6($this.gl5_1, $this);
    $this.jl5_1 = new RenderContext3D();
    return $this;
  }
  jc7(ctx) {
    ctx.ya8();
    ctx.eai(VOID, 1.0, VOID, false);
    this.jl5_1.ll2_1 = ctx.ma6_1;
    this.jl5_1.ml2_1 = ctx;
    this.jl5_1.rl2_1.t4h(this.il5_1.el6_1.nky(ctx.bai(), ctx.cai()));
    this.jl5_1.ul2_1.t4h(this.il5_1.el6_1.zkv_1.gl6());
    setToColorPremultiplied(this.jl5_1.zl2_1, this.il5_1.cl6_1).z4f(this.il5_1.dl6_1);
    this.jl5_1.al3_1 = this.il5_1.bl6_1;
    this.jl5_1.vl2_1.z4h(this.il5_1.el6_1.zkv_1.gl6());
    this.jl5_1.tl2_1.s4h(this.jl5_1.rl2_1, this.jl5_1.vl2_1);
    this.jl5_1.sl2_1.o3();
    foreachDescendant(this.il5_1, Stage3DView$renderInternal$lambda(this));
    this.il5_1.oky(this.jl5_1);
  }
  o8a(type, event, result) {
    this.il5_1.t8a(type, event, result);
  }
}
class Stage3D extends Container3D {
  static fl6(views, viewParent) {
    var $this = this.zkz();
    $this.zl5_1 = views;
    $this.al6_1 = viewParent;
    $this.j8a($this.al6_1);
    $this.bl6_1 = 1.0;
    $this.cl6_1 = Colors_getInstance().h4r_1;
    $this.dl6_1 = 0.3;
    var tmp = $this;
    // Inline function 'kotlin.apply' call
    var this_0 = Perspective.ikx();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.Stage3D.camera.<anonymous>' call
    this_0.mky($this);
    tmp.el6_1 = this_0;
    return $this;
  }
  kcb() {
    return this.zl5_1.il6_1.kcb();
  }
}
class Views3D {
  constructor(views, container) {
    this.hl6_1 = views;
    this.il6_1 = container;
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_6 = this;
    this.jl6_1 = new MMatrix4();
  }
}
class Transform3D {
  constructor() {
    Companion_getInstance_17();
    this.sky_1 = false;
    this.tky_1 = false;
    this.uky_1 = new MMatrix4();
    this.vky_1 = new MMatrix4();
    this.wky_1 = new MMatrix4();
    var tmp = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.xky_1 = ArrayList.m1();
    this.yky_1 = null;
    this.zky_1 = Companion_instance_2.j4f(0, 0, 0);
    this.akz_1 = Quaternion.b2s();
    this.bkz_1 = Companion_instance_2.j4f(1, 1, 1);
    this.ckz_1 = true;
    this.dkz_1 = Companion_instance_2.h4f(0.0, 1.0, 0.0);
    this.ekz_1 = new MMatrix4();
    this.fkz_1 = new MMatrix4();
    this.gkz_1 = new MVector4();
    this.hkz_1 = new MVector4();
    this.ikz_1 = _EulerRotation___init__impl__bezlqm();
  }
  ll6(root, including) {
    if (equals(this, root) || this.yky_1 == null) {
      return including ? get_immutable(this.gl6()) : Companion_getInstance_9().s2p_1;
    }
    return ensureNotNull(this.yky_1).ll6(root, including).j2r(get_immutable(this.gl6()));
  }
  ml6(root, including) {
    return this.ll6(root == null ? null : root.zkv_1, including);
  }
  nl6() {
    var tmp25_safe_receiver = this.yky_1;
    var tmp0_elvis_lhs = tmp25_safe_receiver == null ? null : tmp25_safe_receiver.nl6();
    var parent = tmp0_elvis_lhs == null ? Companion_getInstance_17().jl6_1 : tmp0_elvis_lhs;
    this.vky_1.s4h(parent, this.gl6());
    return this.vky_1;
  }
  ybp() {
    return this.nl6();
  }
  gl6() {
    if (this.sky_1) {
      this.sky_1 = false;
      this.wky_1.x4h(this.ol6(), this.kl6(), this.pl6());
    }
    return this.wky_1;
  }
  cl0(value) {
    var tmp26_safe_receiver = this.yky_1;
    var tmp27_safe_receiver = tmp26_safe_receiver == null ? null : tmp26_safe_receiver.xky_1;
    if (tmp27_safe_receiver == null)
      null;
    else
      tmp27_safe_receiver.n3(this);
    this.yky_1 = value;
    var tmp28_safe_receiver = this.yky_1;
    var tmp29_safe_receiver = tmp28_safe_receiver == null ? null : tmp28_safe_receiver.xky_1;
    if (tmp29_safe_receiver == null)
      null;
    else
      tmp29_safe_receiver.f(this);
  }
  ql6() {
    if (this.tky_1) {
      updateTRS(this);
    }
    return this;
  }
  ol6() {
    return this.ql6().zky_1;
  }
  rl6(value) {
    this.ql6().akz_1 = value;
  }
  kl6() {
    return this.ql6().akz_1;
  }
  pl6() {
    return this.ql6().bkz_1;
  }
  sl6(mat) {
    this.tky_1 = true;
    this.gl6().t4h(mat);
    return this;
  }
  tl6(x, y, z, w) {
    // Inline function 'korlibs.korge3d.Transform3D.updatingTRS' call
    this.ql6();
    this.sky_1 = true;
    // Inline function 'korlibs.korge3d.Transform3D.setTranslation.<anonymous>' call
    this.ql6();
    this.sky_1 = true;
    this.ol6().g4f(x, y, z, w);
    return this;
  }
  ul6(quat) {
    // Inline function 'korlibs.korge3d.Transform3D.updatingTRS' call
    this.ql6();
    this.sky_1 = true;
    // Inline function 'korlibs.korge3d.Transform3D.setRotation.<anonymous>' call
    this.ql6();
    this.sky_1 = true;
    this.ckz_1 = true;
    this.rl6(quat);
    return this;
  }
  vl6(x, y, z, w) {
    // Inline function 'korlibs.korge3d.Transform3D.updatingTRS' call
    this.ql6();
    this.sky_1 = true;
    // Inline function 'korlibs.korge3d.Transform3D.setScale.<anonymous>' call
    this.pl6().g4f(x, y, z, w);
    return this;
  }
  wl6(px, py, pz, tx, ty, tz, up) {
    return this.sl6(this.gl6().s4h(this.ekz_1.r4h(px, py, pz), this.fkz_1.w4h(this.gkz_1.w4f(px, py, pz), this.hkz_1.w4f(tx, ty, tz), up)));
  }
  jkz(px, py, pz, tx, ty, tz, up, $super) {
    up = up === VOID ? this.dkz_1 : up;
    return $super === VOID ? this.wl6(px, py, pz, tx, ty, tz, up) : $super.wl6.call(this, px, py, pz, tx, ty, tz, up);
  }
  xl6(a, b, t, doTranslation, doRotation, doScale) {
    if (doTranslation) {
      this.zky_1.y4f(a.ol6(), b.ol6(), t);
    }
    if (doRotation)
      this.akz_1 = Companion_getInstance_10().g2s(a.kl6(), b.kl6(), t);
    if (doScale) {
      this.bkz_1.y4f(a.pl6(), b.pl6(), t);
    }
    this.sky_1 = true;
    return this;
  }
  toString() {
    return 'Transform3D(translation=' + this.ol6().toString() + ',rotation=' + this.kl6().toString() + ',scale=' + this.pl6().toString() + ')';
  }
}
class descendants$slambda {
  constructor($this_descendants) {
    this.pl8_1 = $this_descendants;
  }
  ql8($this$sequence, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$sequence, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.ql8(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  }
}
class ViewWithMaterial3D extends View3D {
  static el9() {
    var $this = this.kkz();
    $this.dl9_1 = AGTextureUnits.e8o();
    return $this;
  }
  gl9(ctx, uniform, actual) {
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.fl3().y8r(uniform);
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge3d.ViewWithMaterial3D.setMaterialLight.<anonymous>' call
      var $this$push = this_0.o8r_1;
      var it = this_0.u8r_1;
      if (actual instanceof LightColor) {
        it.j9i($this$push.ul9(), _RGBA___get_premultiplied__impl__3bulqc(actual.xl9_1));
      } else {
        if (actual instanceof LightTexture) {
          var tmp = $this$push.ql9().s8o_1;
          var tmp40_safe_receiver = actual.tl9_1;
          var tmp_0;
          if (tmp40_safe_receiver == null) {
            tmp_0 = null;
          } else {
            // Inline function 'kotlin.let' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge3d.ViewWithMaterial3D.setMaterialLight.<anonymous>.<anonymous>' call
            tmp_0 = ctx.fl3().fa7_1.zac(tmp40_safe_receiver).wab_1;
          }
          this.dl9_1.v8o(tmp, tmp_0, AGTextureUnitInfo__withWrap_impl_akwb6y(AGTextureUnitInfo__withLinear_impl_jl8yud(Companion_getInstance_5().y8o_1, true), Companion_getInstance_6().i8r_1));
        }
      }
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
  }
  rky(ctx) {
    this.dl9_1.o3();
    super.rky(ctx);
    var meshMaterial = this.fl9();
    if (!(meshMaterial == null)) {
      this.gl9(ctx, Companion_getInstance_51().bl2_1, meshMaterial.zl9_1);
      this.gl9(ctx, Companion_getInstance_51().cl2_1, meshMaterial.ala_1);
      this.gl9(ctx, Companion_getInstance_51().al2_1, meshMaterial.yl9_1);
      this.gl9(ctx, Companion_getInstance_51().dl2_1, meshMaterial.bla_1);
      if (!(meshMaterial.ela_1 == null)) {
        this.dl9_1.v8o(Companion_getInstance_51().ila().s8o_1, ctx.fl3().fa7_1.zac(meshMaterial.ela_1).wab_1, AGTextureUnitInfo__withWrap_impl_akwb6y(AGTextureUnitInfo__withLinear_impl_jl8yud(Companion_getInstance_5().y8o_1, true), Companion_getInstance_6().i8r_1));
      }
    }
  }
}
class ViewWithMesh3D extends ViewWithMaterial3D {
  static yla(mesh, skeleton) {
    skeleton = skeleton === VOID ? null : skeleton;
    var $this = this.el9();
    $this.wla_1 = mesh;
    $this.xla_1 = skeleton;
    return $this;
  }
  fl9() {
    return this.wla_1.pl0_1;
  }
  oky(ctx) {
    var ag = ctx.saq();
    $l$block_0: {
      // Inline function 'korlibs.datastructure.Pool.alloc' call
      var this_0 = ctx.yl2_1;
      var temp = this_0.g39();
      try {
        // Inline function 'korlibs.korge3d.RenderContext3D.useDynamicVertexData' call
        var vertexBuffers = this.wla_1.gl0_1;
        $l$block: {
          // Inline function 'korlibs.datastructure.Pool.allocMultiple' call
          var this_1 = ctx.xl2_1;
          var count = vertexBuffers.j1();
          var temp_0 = ctx.el3_1;
          temp_0.o3();
          var inductionVariable = 0;
          if (inductionVariable < count)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              temp_0.f(this_1.g39());
            }
             while (inductionVariable < count);
          try {
            var inductionVariable_0 = 0;
            var last = vertexBuffers.j1();
            if (inductionVariable_0 < last)
              do {
                var n_0 = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                var bufferWithVertexLayout = vertexBuffers.i1(n_0);
                temp_0.i1(n_0).u8t_1.s8n(bufferWithVertexLayout.ql0_1);
                temp_0.i1(n_0).t8t_1 = bufferWithVertexLayout.rl0_1;
              }
               while (inductionVariable_0 < last);
            var vertexData = AGVertexArrayObject.r8t(temp_0);
            temp.s8n(this.wla_1.hl0_1);
            var meshMaterial = this.wla_1.pl0_1;
            var tmp0_elvis_lhs = this.wla_1.kl0_1;
            var program = tmp0_elvis_lhs == null ? ctx.nl2_1.blb(clamp(ctx.sl2_1.j1(), 0, 4), this.wla_1.nl0_1, meshMaterial, this.wla_1.ml0_1, 0, false) : tmp0_elvis_lhs;
            this.rky(ctx);
            // Inline function 'kotlin.apply' call
            Companion_getInstance_51();
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge3d.ViewWithMesh3D.render.<anonymous>.<anonymous>.<anonymous>' call
            var tmp41_frameBuffer = ctx.fl3().pa7_1;
            var tmp42_indexType = this.wla_1.il0_1;
            var tmp43_drawType = this.wla_1.ll0_1;
            var tmp44_vertexCount = this.wla_1.jl0_1;
            var tmp45_blending = this.bkw_1.ba9_1;
            var tmp46_uniformBlocks = ctx.fl3().qah(program);
            var tmp47_textureUnits = this.dl9_1;
            var tmp48_depthAndFrontFace = ctx.dl3_1;
            draw(ag, tmp41_frameBuffer, vertexData, program, tmp43_drawType, tmp44_vertexCount, temp, tmp42_indexType, VOID, tmp45_blending, tmp46_uniformBlocks, tmp47_textureUnits, VOID, VOID, VOID, tmp48_depthAndFrontFace);
            break $l$block;
          }finally {
            $l$loop: while (true) {
              // Inline function 'kotlin.collections.isNotEmpty' call
              if (!!temp_0.a1()) {
                break $l$loop;
              }
              this_1.h39(removeLast(temp_0));
            }
          }
        }
        break $l$block_0;
      }finally {
        this_0.h39(temp);
      }
    }
  }
}
class Companion_2 {
  constructor() {
    Companion_instance_7 = this;
    this.clb_1 = new ReadOptions();
  }
}
class Companion_3 {}
class $serializer {
  constructor() {
    $serializer_instance = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Asset', this, 7);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('version', true);
    tmp0_serialDesc.fjp('generator', true);
    tmp0_serialDesc.fjp('copyright', true);
    tmp0_serialDesc.fjp('minVersion', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.dlb_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.dlb_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_input = decoder.pjj(tmp0_desc);
    if (tmp11_input.fjk()) {
      tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.zjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.djk(tmp0_desc, 2, StringSerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.djk(tmp0_desc, 3, StringSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.djk(tmp0_desc, 4, StringSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.zjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.djk(tmp0_desc, 2, StringSerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.djk(tmp0_desc, 3, StringSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.djk(tmp0_desc, 4, StringSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp11_input.qjj(tmp0_desc);
    return Asset.mlb(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, null);
  }
  ujf() {
    return this.dlb_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), StringSerializer_getInstance(), get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_4 {}
class $serializer_0 {
  constructor() {
    $serializer_instance_0 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Scene', this, 4);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('nodes', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.nlb_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.nlb_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_input = decoder.pjj(tmp0_desc);
    if (tmp8_input.fjk()) {
      tmp4_local0 = tmp8_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp8_input.bjk(tmp0_desc, 1, IntArraySerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp8_input.djk(tmp0_desc, 2, JsonElementSerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp8_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp8_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp8_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp8_input.bjk(tmp0_desc, 1, IntArraySerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp8_input.djk(tmp0_desc, 2, JsonElementSerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp8_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp8_input.qjj(tmp0_desc);
    return Scene.tlb(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, null);
  }
  ujf() {
    return this.nlb_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntArraySerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_5 {}
class $serializer_1 {
  constructor() {
    $serializer_instance_1 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Node', this, 12);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('camera', true);
    tmp0_serialDesc.fjp('skin', true);
    tmp0_serialDesc.fjp('mesh', true);
    tmp0_serialDesc.fjp('children', true);
    tmp0_serialDesc.fjp('scale', true);
    tmp0_serialDesc.fjp('translation', true);
    tmp0_serialDesc.fjp('rotation', true);
    tmp0_serialDesc.fjp('matrix', true);
    tmp0_serialDesc.fjp('weights', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.ulb_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ulb_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp17_input = decoder.pjj(tmp0_desc);
    if (tmp17_input.fjk()) {
      tmp4_local0 = tmp17_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp17_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp17_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp17_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp17_input.djk(tmp0_desc, 4, IntArraySerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp17_input.djk(tmp0_desc, 5, FloatArraySerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp17_input.djk(tmp0_desc, 6, FloatArraySerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp17_input.djk(tmp0_desc, 7, FloatArraySerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp17_input.djk(tmp0_desc, 8, FloatArraySerializer_getInstance(), tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp17_input.djk(tmp0_desc, 9, IntArraySerializer_getInstance(), tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp17_input.djk(tmp0_desc, 10, JsonElementSerializer_getInstance(), tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp17_input.djk(tmp0_desc, 11, JsonElementSerializer_getInstance(), tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp17_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp17_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp17_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp17_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp17_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp17_input.djk(tmp0_desc, 4, IntArraySerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp17_input.djk(tmp0_desc, 5, FloatArraySerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp17_input.djk(tmp0_desc, 6, FloatArraySerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp17_input.djk(tmp0_desc, 7, FloatArraySerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp17_input.djk(tmp0_desc, 8, FloatArraySerializer_getInstance(), tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp17_input.djk(tmp0_desc, 9, IntArraySerializer_getInstance(), tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp17_input.djk(tmp0_desc, 10, JsonElementSerializer_getInstance(), tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp17_input.djk(tmp0_desc, 11, JsonElementSerializer_getInstance(), tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp17_input.qjj(tmp0_desc);
    return Node.jlc(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, null);
  }
  ujf() {
    return this.ulb_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(IntArraySerializer_getInstance()), get_nullable(FloatArraySerializer_getInstance()), get_nullable(FloatArraySerializer_getInstance()), get_nullable(FloatArraySerializer_getInstance()), get_nullable(FloatArraySerializer_getInstance()), get_nullable(IntArraySerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_6 {
  constructor() {
    Companion_instance_11 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.klc_1 = [null, new ArrayListSerializer($serializer_getInstance_4()), null, null, null];
  }
}
class $serializer_2 {
  constructor() {
    $serializer_instance_2 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Mesh', this, 5);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('primitives', true);
    tmp0_serialDesc.fjp('weights', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.llc_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.llc_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    var tmp11_cached = Companion_getInstance_22().klc_1;
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.bjk(tmp0_desc, 1, tmp11_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.djk(tmp0_desc, 2, FloatArraySerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.bjk(tmp0_desc, 1, tmp11_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.djk(tmp0_desc, 2, FloatArraySerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return Mesh.tlc(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.llc_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_22().klc_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), tmp0_cached[1], get_nullable(FloatArraySerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_7 {
  constructor() {
    Companion_instance_12 = this;
    this.ulc_1 = _PrimitiveAttribute___init__impl__43i1o4('POSITION');
    this.vlc_1 = _PrimitiveAttribute___init__impl__43i1o4('NORMAL');
    this.wlc_1 = _PrimitiveAttribute___init__impl__43i1o4('TANGENT');
    this.xlc_1 = _PrimitiveAttribute___init__impl__43i1o4('TEXCOORD_0');
    this.ylc_1 = _PrimitiveAttribute___init__impl__43i1o4('TEXCOORD_1');
    this.zlc_1 = _PrimitiveAttribute___init__impl__43i1o4('JOINTS_0');
    this.ald_1 = _PrimitiveAttribute___init__impl__43i1o4('WEIGHTS_0');
  }
  bld(n) {
    return _PrimitiveAttribute___init__impl__43i1o4('COLOR_' + n);
  }
}
class $serializer_3 {
  constructor() {
    $serializer_instance_3 = this;
    var tmp0_serialDesc = new InlineClassDescriptor('korlibs.korge3d.format.gltf2.GLTF2.PrimitiveAttribute', this);
    tmp0_serialDesc.fjp('str', false);
    this.cld_1 = tmp0_serialDesc;
  }
  dld(decoder) {
    return _PrimitiveAttribute___init__impl__43i1o4(decoder.mjj(this.cld_1).kjj());
  }
  vjf(decoder) {
    return new PrimitiveAttribute(this.dld(decoder));
  }
  ujf() {
    return this.cld_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance()];
  }
}
class Companion_8 {
  constructor() {
    Companion_instance_13 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.fld_1 = [null, new LinkedHashMapSerializer($serializer_getInstance_3(), IntSerializer_getInstance()), null, null, null, new ArrayListSerializer(new LinkedHashMapSerializer($serializer_getInstance_3(), IntSerializer_getInstance())), null, null];
  }
}
class $serializer_4 {
  constructor() {
    $serializer_instance_4 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Primitive', this, 8);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('attributes', true);
    tmp0_serialDesc.fjp('indices', true);
    tmp0_serialDesc.fjp('material', true);
    tmp0_serialDesc.fjp('mode', true);
    tmp0_serialDesc.fjp('targets', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.gld_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.gld_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0;
    var tmp8_local4 = 0;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_input = decoder.pjj(tmp0_desc);
    var tmp13_cached = Companion_getInstance_24().fld_1;
    if (tmp12_input.fjk()) {
      tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp12_input.bjk(tmp0_desc, 1, tmp13_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp12_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp12_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp12_input.ujj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp12_input.bjk(tmp0_desc, 5, tmp13_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp12_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, JsonElementSerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp12_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp12_input.bjk(tmp0_desc, 1, tmp13_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp12_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp12_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp12_input.ujj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp12_input.bjk(tmp0_desc, 5, tmp13_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp12_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, JsonElementSerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp12_input.qjj(tmp0_desc);
    return Primitive.qld(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, null);
  }
  ujf() {
    return this.gld_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_24().fld_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), tmp0_cached[1], IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), tmp0_cached[5], get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_9 {}
class $serializer_5 {
  constructor() {
    $serializer_instance_5 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Skin', this, 6);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('inverseBindMatrices', true);
    tmp0_serialDesc.fjp('joints', true);
    tmp0_serialDesc.fjp('skeleton', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.rld_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.rld_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = 0;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.bjk(tmp0_desc, 2, IntArraySerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.bjk(tmp0_desc, 2, IntArraySerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return Skin.zld(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.rld_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntArraySerializer_getInstance(), IntSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_10 {}
class $serializer_6 {
  constructor() {
    $serializer_instance_6 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Animation.Channel.Target', this, 5);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('node', true);
    tmp0_serialDesc.fjp('path', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.ale_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ale_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_input = decoder.pjj(tmp0_desc);
    if (tmp9_input.fjk()) {
      tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp9_input.djk(tmp0_desc, 2, TargetPathSerializer_instance, tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp9_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp9_input.djk(tmp0_desc, 2, TargetPathSerializer_instance, tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp9_input.qjj(tmp0_desc);
    return Target.hle(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.ale_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), get_nullable(TargetPathSerializer_instance), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_11 {
  constructor() {
    Companion_instance_16 = this;
    var tmp = this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = values_0();
    var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.wc(capacity);
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.korge3d.format.gltf2.Companion.BY_KEY.<anonymous>' call
      var tmp$ret$0 = element.mle_1;
      destination.r3(tmp$ret$0, element);
    }
    tmp.ile_1 = destination;
    var tmp_0 = this;
    var tmp_1 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_0.jle_1 = lazy_0(tmp_1, GLTF2$Animation$Channel$TargetPath$Companion$_anonymous__6vqjkf);
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class GLTFProperty {
  constructor() {
    Companion_getInstance_48();
    this.nle_1 = new Mixin();
  }
  n13(_set____db54di) {
    this.nle_1.n13(_set____db54di);
  }
  o13() {
    return this.nle_1.o13();
  }
  static ole(seen0, serializationConstructorMarker) {
    Companion_getInstance_48();
    var $this = createThis(this);
    $this.nle_1 = new Mixin();
    return $this;
  }
}
class Target extends GLTFProperty {
  constructor(name, node, path, extensions, extras) {
    name = name === VOID ? null : name;
    node = node === VOID ? -1 : node;
    path = path === VOID ? null : path;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.cle_1 = name;
    this.dle_1 = node;
    this.ele_1 = path;
    this.fle_1 = extensions;
    this.gle_1 = extras;
  }
  toString() {
    return 'Target(name=' + this.cle_1 + ', node=' + this.dle_1 + ', path=' + toString(this.ele_1) + ', extensions=' + toString(this.fle_1) + ', extras=' + toString(this.gle_1) + ')';
  }
  hashCode() {
    var result = this.cle_1 == null ? 0 : getStringHashCode(this.cle_1);
    result = imul(result, 31) + this.dle_1 | 0;
    result = imul(result, 31) + (this.ele_1 == null ? 0 : this.ele_1.hashCode()) | 0;
    result = imul(result, 31) + (this.fle_1 == null ? 0 : hashCode(this.fle_1)) | 0;
    result = imul(result, 31) + (this.gle_1 == null ? 0 : hashCode(this.gle_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Target))
      return false;
    var tmp0_other_with_cast = other instanceof Target ? other : THROW_CCE();
    if (!(this.cle_1 == tmp0_other_with_cast.cle_1))
      return false;
    if (!(this.dle_1 === tmp0_other_with_cast.dle_1))
      return false;
    if (!equals(this.ele_1, tmp0_other_with_cast.ele_1))
      return false;
    if (!equals(this.fle_1, tmp0_other_with_cast.fle_1))
      return false;
    if (!equals(this.gle_1, tmp0_other_with_cast.gle_1))
      return false;
    return true;
  }
  static hle(seen0, name, node, path, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_6().ale_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.cle_1 = null;
    else
      $this.cle_1 = name;
    if (0 === (seen0 & 2))
      $this.dle_1 = -1;
    else
      $this.dle_1 = node;
    if (0 === (seen0 & 4))
      $this.ele_1 = null;
    else
      $this.ele_1 = path;
    if (0 === (seen0 & 8))
      $this.fle_1 = null;
    else
      $this.fle_1 = extensions;
    if (0 === (seen0 & 16))
      $this.gle_1 = null;
    else
      $this.gle_1 = extras;
    return $this;
  }
}
class TargetPath extends Enum {
  constructor(name, ordinal, key) {
    super(name, ordinal);
    this.mle_1 = key;
  }
}
class TargetPathSerializer {
  ujf() {
    return PrimitiveSerialDescriptor('TargetPath', STRING_getInstance());
  }
  vjf(decoder) {
    var tmp0_elvis_lhs = Companion_getInstance_27().ile_1.k3(decoder.kjj());
    return tmp0_elvis_lhs == null ? TargetPath_UNKNOWN_getInstance() : tmp0_elvis_lhs;
  }
}
class Companion_12 {}
class $serializer_7 {
  constructor() {
    $serializer_instance_7 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Animation.Channel', this, 5);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('sampler', true);
    tmp0_serialDesc.fjp('target', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.ple_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ple_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_input = decoder.pjj(tmp0_desc);
    if (tmp9_input.fjk()) {
      tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp9_input.djk(tmp0_desc, 2, $serializer_getInstance_6(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp9_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp9_input.djk(tmp0_desc, 2, $serializer_getInstance_6(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp9_input.qjj(tmp0_desc);
    return Channel.wle(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.ple_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), get_nullable($serializer_getInstance_6()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Lookup {
  constructor(requestedTime, lowIndex, highIndex, lowTime, highTime, interpolation) {
    requestedTime = requestedTime === VOID ? 0.0 : requestedTime;
    lowIndex = lowIndex === VOID ? 0 : lowIndex;
    highIndex = highIndex === VOID ? 0 : highIndex;
    lowTime = lowTime === VOID ? 0.0 : lowTime;
    highTime = highTime === VOID ? 0.0 : highTime;
    interpolation = interpolation === VOID ? 'LINEAR' : interpolation;
    this.xle_1 = requestedTime;
    this.yle_1 = lowIndex;
    this.zle_1 = highIndex;
    this.alf_1 = lowTime;
    this.blf_1 = highTime;
    this.clf_1 = interpolation;
  }
  gbs() {
    var tmp;
    switch (this.clf_1) {
      case 'LINEAR':
        tmp = convertRange(this.xle_1, this.alf_1, this.blf_1, 0.0, 1.0);
        break;
      case 'STEP':
        tmp = 0.0;
        break;
      default:
        var reason = 'Unimplemented interpolation ' + this.clf_1;
        throw NotImplementedError.oe('An operation is not implemented: ' + reason);
    }
    return tmp;
  }
  dlf() {
    return clamp01(this.gbs());
  }
  toString() {
    return 'Lookup(requestedTime=' + this.xle_1 + ', lowIndex=' + this.yle_1 + ', highIndex=' + this.zle_1 + ', lowTime=' + this.alf_1 + ', highTime=' + this.blf_1 + ', interpolation=' + this.clf_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.xle_1);
    result = imul(result, 31) + this.yle_1 | 0;
    result = imul(result, 31) + this.zle_1 | 0;
    result = imul(result, 31) + getNumberHashCode(this.alf_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.blf_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.clf_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Lookup))
      return false;
    var tmp0_other_with_cast = other instanceof Lookup ? other : THROW_CCE();
    if (!equals(this.xle_1, tmp0_other_with_cast.xle_1))
      return false;
    if (!(this.yle_1 === tmp0_other_with_cast.yle_1))
      return false;
    if (!(this.zle_1 === tmp0_other_with_cast.zle_1))
      return false;
    if (!equals(this.alf_1, tmp0_other_with_cast.alf_1))
      return false;
    if (!equals(this.blf_1, tmp0_other_with_cast.blf_1))
      return false;
    if (!(this.clf_1 === tmp0_other_with_cast.clf_1))
      return false;
    return true;
  }
}
class LookupKind extends Enum {}
class Companion_13 {}
class $serializer_8 {
  constructor() {
    $serializer_instance_8 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Animation.Sampler', this, 6);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('input', true);
    tmp0_serialDesc.fjp('interpolation', true);
    tmp0_serialDesc.fjp('output', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.elf_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.elf_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = 0;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp12_input = decoder.pjj(tmp0_desc);
    if (tmp12_input.fjk()) {
      tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp12_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp12_input.zjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp12_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp12_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp12_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp12_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp12_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp12_input.zjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp12_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp12_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp12_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp12_input.qjj(tmp0_desc);
    return Sampler_0.olf(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.elf_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), StringSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Channel extends GLTFProperty {
  constructor(name, sampler, target, extensions, extras) {
    name = name === VOID ? null : name;
    sampler = sampler === VOID ? -1 : sampler;
    target = target === VOID ? null : target;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.rle_1 = name;
    this.sle_1 = sampler;
    this.tle_1 = target;
    this.ule_1 = extensions;
    this.vle_1 = extras;
  }
  toString() {
    return 'Channel(name=' + this.rle_1 + ', sampler=' + this.sle_1 + ', target=' + toString(this.tle_1) + ', extensions=' + toString(this.ule_1) + ', extras=' + toString(this.vle_1) + ')';
  }
  hashCode() {
    var result = this.rle_1 == null ? 0 : getStringHashCode(this.rle_1);
    result = imul(result, 31) + this.sle_1 | 0;
    result = imul(result, 31) + (this.tle_1 == null ? 0 : this.tle_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ule_1 == null ? 0 : hashCode(this.ule_1)) | 0;
    result = imul(result, 31) + (this.vle_1 == null ? 0 : hashCode(this.vle_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Channel))
      return false;
    var tmp0_other_with_cast = other instanceof Channel ? other : THROW_CCE();
    if (!(this.rle_1 == tmp0_other_with_cast.rle_1))
      return false;
    if (!(this.sle_1 === tmp0_other_with_cast.sle_1))
      return false;
    if (!equals(this.tle_1, tmp0_other_with_cast.tle_1))
      return false;
    if (!equals(this.ule_1, tmp0_other_with_cast.ule_1))
      return false;
    if (!equals(this.vle_1, tmp0_other_with_cast.vle_1))
      return false;
    return true;
  }
  static wle(seen0, name, sampler, target, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_7().ple_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.rle_1 = null;
    else
      $this.rle_1 = name;
    if (0 === (seen0 & 2))
      $this.sle_1 = -1;
    else
      $this.sle_1 = sampler;
    if (0 === (seen0 & 4))
      $this.tle_1 = null;
    else
      $this.tle_1 = target;
    if (0 === (seen0 & 8))
      $this.ule_1 = null;
    else
      $this.ule_1 = extensions;
    if (0 === (seen0 & 16))
      $this.vle_1 = null;
    else
      $this.vle_1 = extras;
    return $this;
  }
}
class Sampler_0 extends GLTFProperty {
  constructor(name, input, interpolation, output, extensions, extras) {
    name = name === VOID ? null : name;
    input = input === VOID ? -1 : input;
    interpolation = interpolation === VOID ? 'LINEAR' : interpolation;
    output = output === VOID ? -1 : output;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.glf_1 = name;
    this.hlf_1 = input;
    this.ilf_1 = interpolation;
    this.jlf_1 = output;
    this.klf_1 = extensions;
    this.llf_1 = extras;
    if (!(this.ilf_1 === 'LINEAR') && !(this.ilf_1 === 'STEP')) {
      // Inline function 'kotlin.error' call
      var message = "Only implemented LINEAR & STEP interpolations for now, but requested '" + this.ilf_1 + "'";
      throw IllegalStateException.m5(toString_0(message));
    }
    this.mlf_1 = null;
    this.nlf_1 = null;
  }
  plf(gltf) {
    var times = this.qlf(gltf);
    return times.j1() > 0 ? times.z2k(times.j1() - 1 | 0, 0) : 0.0;
  }
  ulf(gltf) {
    return gltf.klg_1.i1(this.hlf_1);
  }
  rlg(gltf) {
    return gltf.klg_1.i1(this.jlf_1);
  }
  qlf(gltf) {
    if (this.mlf_1 == null) {
      var accessor = this.ulf(gltf);
      this.mlf_1 = GLTF2AccessorVector.ilh(accessor, accessor.hlh(gltf));
    }
    return ensureNotNull(this.mlf_1);
  }
  jlh(gltf) {
    if (this.nlf_1 == null) {
      var accessor = this.rlg(gltf);
      this.nlf_1 = GLTF2AccessorVector.ilh(accessor, accessor.hlh(gltf));
    }
    return ensureNotNull(this.nlf_1);
  }
  klh(gltf, time, kind, out) {
    var times = this.qlf(gltf);
    // Inline function 'korlibs.datastructure.genericBinarySearchLeft' call
    var toIndex = times.j1();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = 0;
      var high = toIndex - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        // Inline function 'korlibs.korge3d.format.gltf2.Sampler.lookup.<anonymous>' call
        var mval = compareTo(times.z2k(mid, 0), time);
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      // Inline function 'korlibs.datastructure.genericBinarySearchLeft.<anonymous>' call
      var low_0 = low;
      // Inline function 'kotlin.math.min' call
      var b = high;
      var tmp$ret$2 = Math.min(low_0, b);
      tmp$ret$1 = coerceIn(tmp$ret$2, 0, toIndex - 1 | 0);
    }
    var lowIndex = tmp$ret$1;
    var highIndex = lowIndex >= (times.j1() - 1 | 0) ? lowIndex : lowIndex + 1 | 0;
    out.xle_1 = time;
    out.yle_1 = lowIndex;
    out.zle_1 = highIndex;
    out.alf_1 = times.z2k(lowIndex, 0);
    out.blf_1 = times.z2k(highIndex, 0);
    out.clf_1 = this.ilf_1;
    return out;
  }
  llh(gltf, time, kind, out, $super) {
    out = out === VOID ? new Lookup() : out;
    return $super === VOID ? this.klh(gltf, time, kind, out) : $super.klh.call(this, gltf, time, kind, out);
  }
  mlh(gltf, time, kind, count) {
    var vec = GLTF2AccessorVector.nlh(this.rlg(gltf), count);
    this.olh(gltf, time, kind, vec, count);
    return vec;
  }
  plh(gltf, time, kind, count, $super) {
    count = count === VOID ? 1 : count;
    return $super === VOID ? this.mlh(gltf, time, kind, count) : $super.mlh.call(this, gltf, time, kind, count);
  }
  qlh(gltf, time, kind, out, count, outIndex) {
    var lookup = this.llh(gltf, time, kind);
    var output = this.jlh(gltf);
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out.rlh(outIndex + n | 0, output, imul(lookup.yle_1, count) + n | 0, output, imul(lookup.zle_1, count) + n | 0, lookup.dlf(), kind);
      }
       while (inductionVariable < count);
  }
  olh(gltf, time, kind, out, count, outIndex, $super) {
    count = count === VOID ? 1 : count;
    outIndex = outIndex === VOID ? 0 : outIndex;
    var tmp;
    if ($super === VOID) {
      this.qlh(gltf, time, kind, out, count, outIndex);
      tmp = Unit_instance;
    } else {
      tmp = $super.qlh.call(this, gltf, time, kind, out, count, outIndex);
    }
    return tmp;
  }
  toString() {
    return 'Sampler(name=' + this.glf_1 + ', input=' + this.hlf_1 + ', interpolation=' + this.ilf_1 + ', output=' + this.jlf_1 + ', extensions=' + toString(this.klf_1) + ', extras=' + toString(this.llf_1) + ')';
  }
  hashCode() {
    var result = this.glf_1 == null ? 0 : getStringHashCode(this.glf_1);
    result = imul(result, 31) + this.hlf_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.ilf_1) | 0;
    result = imul(result, 31) + this.jlf_1 | 0;
    result = imul(result, 31) + (this.klf_1 == null ? 0 : hashCode(this.klf_1)) | 0;
    result = imul(result, 31) + (this.llf_1 == null ? 0 : hashCode(this.llf_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Sampler_0))
      return false;
    var tmp0_other_with_cast = other instanceof Sampler_0 ? other : THROW_CCE();
    if (!(this.glf_1 == tmp0_other_with_cast.glf_1))
      return false;
    if (!(this.hlf_1 === tmp0_other_with_cast.hlf_1))
      return false;
    if (!(this.ilf_1 === tmp0_other_with_cast.ilf_1))
      return false;
    if (!(this.jlf_1 === tmp0_other_with_cast.jlf_1))
      return false;
    if (!equals(this.klf_1, tmp0_other_with_cast.klf_1))
      return false;
    if (!equals(this.llf_1, tmp0_other_with_cast.llf_1))
      return false;
    return true;
  }
  static olf(seen0, name, input, interpolation, output, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_8().elf_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.glf_1 = null;
    else
      $this.glf_1 = name;
    if (0 === (seen0 & 2))
      $this.hlf_1 = -1;
    else
      $this.hlf_1 = input;
    if (0 === (seen0 & 4))
      $this.ilf_1 = 'LINEAR';
    else
      $this.ilf_1 = interpolation;
    if (0 === (seen0 & 8))
      $this.jlf_1 = -1;
    else
      $this.jlf_1 = output;
    if (0 === (seen0 & 16))
      $this.klf_1 = null;
    else
      $this.klf_1 = extensions;
    if (0 === (seen0 & 32))
      $this.llf_1 = null;
    else
      $this.llf_1 = extras;
    if (!($this.ilf_1 === 'LINEAR') && !($this.ilf_1 === 'STEP')) {
      // Inline function 'kotlin.error' call
      var message = "Only implemented LINEAR & STEP interpolations for now, but requested '" + $this.ilf_1 + "'";
      throw IllegalStateException.m5(toString_0(message));
    }
    $this.mlf_1 = null;
    $this.nlf_1 = null;
    return $this;
  }
}
class Companion_14 {
  constructor() {
    Companion_instance_19 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.slh_1 = [null, new ArrayListSerializer($serializer_getInstance_7()), new ArrayListSerializer($serializer_getInstance_8()), null, null];
  }
}
class $serializer_9 {
  constructor() {
    $serializer_instance_9 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Animation', this, 5);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('channels', true);
    tmp0_serialDesc.fjp('samplers', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.tlh_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.tlh_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_input = decoder.pjj(tmp0_desc);
    var tmp10_cached = Companion_getInstance_30().slh_1;
    if (tmp9_input.fjk()) {
      tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp9_input.bjk(tmp0_desc, 1, tmp10_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp9_input.bjk(tmp0_desc, 2, tmp10_cached[2], tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp9_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp9_input.bjk(tmp0_desc, 1, tmp10_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp9_input.bjk(tmp0_desc, 2, tmp10_cached[2], tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp9_input.qjj(tmp0_desc);
    return Animation.ali(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.tlh_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_30().slh_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), tmp0_cached[1], tmp0_cached[2], get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_15 {}
class $serializer_10 {
  constructor() {
    $serializer_instance_10 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Buffer', this, 5);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('uri', true);
    tmp0_serialDesc.fjp('byteLength', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.bli_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.bli_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = 0;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return Buffer_0.jli(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.bli_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_16 {}
class $serializer_11 {
  constructor() {
    $serializer_instance_11 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.BufferView', this, 8);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('buffer', true);
    tmp0_serialDesc.fjp('byteOffset', true);
    tmp0_serialDesc.fjp('byteLength', true);
    tmp0_serialDesc.fjp('byteStride', true);
    tmp0_serialDesc.fjp('target', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.kli_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.kli_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0;
    var tmp8_local4 = 0;
    var tmp9_local5 = 0;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_input = decoder.pjj(tmp0_desc);
    if (tmp12_input.fjk()) {
      tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp12_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp12_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp12_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp12_input.ujj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp12_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp12_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, JsonElementSerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp12_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp12_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp12_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp12_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp12_input.ujj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp12_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp12_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, JsonElementSerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp12_input.qjj(tmp0_desc);
    return BufferView.uli(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, null);
  }
  ujf() {
    return this.kli_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_17 {
  constructor() {
    Companion_instance_22 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.vli_1 = lazy_0(tmp_0, GLTF2$AccessorType$Companion$_anonymous__ofqqqt);
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_0(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class Companion_18 {
  constructor() {
    Companion_instance_23 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.wli_1 = [null, null, null, null, null, null, null, null, Companion_getInstance_33().ijz(), null, null, null, null, createSimpleEnumSerializer('korlibs.graphics.shader.VarType', values())];
  }
}
class $serializer_12 {
  constructor() {
    $serializer_instance_12 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Accessor', this, 14);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('bufferView', true);
    tmp0_serialDesc.fjp('byteOffset', true);
    tmp0_serialDesc.fjp('componentType', true);
    tmp0_serialDesc.fjp('normalized', true);
    tmp0_serialDesc.fjp('count', true);
    tmp0_serialDesc.fjp('min', true);
    tmp0_serialDesc.fjp('max', true);
    tmp0_serialDesc.fjp('type', true);
    tmp0_serialDesc.fjp('sparse', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    tmp0_serialDesc.fjp('attachDebugName', true);
    tmp0_serialDesc.fjp('varType', true);
    this.xli_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.xli_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0;
    var tmp8_local4 = false;
    var tmp9_local5 = 0;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp16_local12 = null;
    var tmp17_local13 = null;
    var tmp18_input = decoder.pjj(tmp0_desc);
    var tmp19_cached = Companion_getInstance_34().wli_1;
    if (tmp18_input.fjk()) {
      tmp4_local0 = tmp18_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp18_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp18_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp18_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp18_input.rjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp18_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp18_input.bjk(tmp0_desc, 6, FloatArraySerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp18_input.bjk(tmp0_desc, 7, FloatArraySerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp18_input.bjk(tmp0_desc, 8, tmp19_cached[8], tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp18_input.djk(tmp0_desc, 9, JsonElementSerializer_getInstance(), tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp18_input.djk(tmp0_desc, 10, JsonElementSerializer_getInstance(), tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp18_input.djk(tmp0_desc, 11, JsonElementSerializer_getInstance(), tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp18_input.djk(tmp0_desc, 12, StringSerializer_getInstance(), tmp16_local12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp18_input.bjk(tmp0_desc, 13, tmp19_cached[13], tmp17_local13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp18_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp18_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp18_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp18_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp18_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp18_input.rjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp18_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp18_input.bjk(tmp0_desc, 6, FloatArraySerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp18_input.bjk(tmp0_desc, 7, FloatArraySerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp18_input.bjk(tmp0_desc, 8, tmp19_cached[8], tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp18_input.djk(tmp0_desc, 9, JsonElementSerializer_getInstance(), tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp18_input.djk(tmp0_desc, 10, JsonElementSerializer_getInstance(), tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp18_input.djk(tmp0_desc, 11, JsonElementSerializer_getInstance(), tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp18_input.djk(tmp0_desc, 12, StringSerializer_getInstance(), tmp16_local12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp18_input.bjk(tmp0_desc, 13, tmp19_cached[13], tmp17_local13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp18_input.qjj(tmp0_desc);
    return Accessor.yli(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, null);
  }
  ujf() {
    return this.xli_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_34().wli_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), BooleanSerializer_getInstance(), IntSerializer_getInstance(), FloatArraySerializer_getInstance(), FloatArraySerializer_getInstance(), tmp0_cached[8], get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), tmp0_cached[13]];
  }
}
class Companion_19 {}
class $serializer_13 {
  constructor() {
    $serializer_instance_13 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Material.PBRMetallicRoughness', this, 8);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('baseColorFactor', true);
    tmp0_serialDesc.fjp('baseColorTexture', true);
    tmp0_serialDesc.fjp('metallicFactor', true);
    tmp0_serialDesc.fjp('roughnessFactor', true);
    tmp0_serialDesc.fjp('metallicRoughnessTexture', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.zli_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.zli_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = 0.0;
    var tmp8_local4 = 0.0;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_input = decoder.pjj(tmp0_desc);
    if (tmp12_input.fjk()) {
      tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp12_input.djk(tmp0_desc, 1, FloatArraySerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp12_input.djk(tmp0_desc, 2, $serializer_getInstance_14(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp12_input.wjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp12_input.wjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp12_input.djk(tmp0_desc, 5, $serializer_getInstance_14(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp12_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, JsonElementSerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp12_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp12_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp12_input.djk(tmp0_desc, 1, FloatArraySerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp12_input.djk(tmp0_desc, 2, $serializer_getInstance_14(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp12_input.wjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp12_input.wjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp12_input.djk(tmp0_desc, 5, $serializer_getInstance_14(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp12_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, JsonElementSerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp12_input.qjj(tmp0_desc);
    return PBRMetallicRoughness.jlj(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, null);
  }
  ujf() {
    return this.zli_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), get_nullable(FloatArraySerializer_getInstance()), get_nullable($serializer_getInstance_14()), FloatSerializer_getInstance(), FloatSerializer_getInstance(), get_nullable($serializer_getInstance_14()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_20 {
  constructor() {
    Companion_instance_25 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.klj_1 = lazy_0(tmp_0, GLTF2$Material$BaseTextureInfo$Companion$_anonymous__rh9kxh);
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_1(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class Companion_21 {}
class $serializer_14 {
  constructor() {
    $serializer_instance_14 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Material.TextureInfo', this, 5);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('index', true);
    tmp0_serialDesc.fjp('texCoord', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.llj_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.llj_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_input = decoder.pjj(tmp0_desc);
    if (tmp9_input.fjk()) {
      tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp9_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp9_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp9_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp9_input.qjj(tmp0_desc);
    return TextureInfo.slj(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.llj_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_22 {}
class $serializer_15 {
  constructor() {
    $serializer_instance_15 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Material.OcclusionTextureInfo', this, 6);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('index', true);
    tmp0_serialDesc.fjp('texCoord', true);
    tmp0_serialDesc.fjp('strength', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.tlj_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.tlj_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0.0;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.wjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.wjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return OcclusionTextureInfo.blk(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.tlj_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), FloatSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_23 {}
class $serializer_16 {
  constructor() {
    $serializer_instance_16 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Material.NormalTextureInfo', this, 6);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('index', true);
    tmp0_serialDesc.fjp('texCoord', true);
    tmp0_serialDesc.fjp('scale', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.clk_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.clk_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0.0;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.wjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.wjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return NormalTextureInfo.klk(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.clk_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), FloatSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class PBRMetallicRoughness extends GLTFProperty {
  constructor(name, baseColorFactor, baseColorTexture, metallicFactor, roughnessFactor, metallicRoughnessTexture, extensions, extras) {
    name = name === VOID ? null : name;
    baseColorFactor = baseColorFactor === VOID ? null : baseColorFactor;
    baseColorTexture = baseColorTexture === VOID ? null : baseColorTexture;
    metallicFactor = metallicFactor === VOID ? 0.0 : metallicFactor;
    roughnessFactor = roughnessFactor === VOID ? 0.0 : roughnessFactor;
    metallicRoughnessTexture = metallicRoughnessTexture === VOID ? null : metallicRoughnessTexture;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.blj_1 = name;
    this.clj_1 = baseColorFactor;
    this.dlj_1 = baseColorTexture;
    this.elj_1 = metallicFactor;
    this.flj_1 = roughnessFactor;
    this.glj_1 = metallicRoughnessTexture;
    this.hlj_1 = extensions;
    this.ilj_1 = extras;
  }
  toString() {
    return 'PBRMetallicRoughness(name=' + this.blj_1 + ', baseColorFactor=' + toString_0(this.clj_1) + ', baseColorTexture=' + toString(this.dlj_1) + ', metallicFactor=' + this.elj_1 + ', roughnessFactor=' + this.flj_1 + ', metallicRoughnessTexture=' + toString(this.glj_1) + ', extensions=' + toString(this.hlj_1) + ', extras=' + toString(this.ilj_1) + ')';
  }
  hashCode() {
    var result = this.blj_1 == null ? 0 : getStringHashCode(this.blj_1);
    result = imul(result, 31) + (this.clj_1 == null ? 0 : hashCode(this.clj_1)) | 0;
    result = imul(result, 31) + (this.dlj_1 == null ? 0 : this.dlj_1.hashCode()) | 0;
    result = imul(result, 31) + getNumberHashCode(this.elj_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.flj_1) | 0;
    result = imul(result, 31) + (this.glj_1 == null ? 0 : this.glj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.hlj_1 == null ? 0 : hashCode(this.hlj_1)) | 0;
    result = imul(result, 31) + (this.ilj_1 == null ? 0 : hashCode(this.ilj_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof PBRMetallicRoughness))
      return false;
    var tmp0_other_with_cast = other instanceof PBRMetallicRoughness ? other : THROW_CCE();
    if (!(this.blj_1 == tmp0_other_with_cast.blj_1))
      return false;
    if (!equals(this.clj_1, tmp0_other_with_cast.clj_1))
      return false;
    if (!equals(this.dlj_1, tmp0_other_with_cast.dlj_1))
      return false;
    if (!equals(this.elj_1, tmp0_other_with_cast.elj_1))
      return false;
    if (!equals(this.flj_1, tmp0_other_with_cast.flj_1))
      return false;
    if (!equals(this.glj_1, tmp0_other_with_cast.glj_1))
      return false;
    if (!equals(this.hlj_1, tmp0_other_with_cast.hlj_1))
      return false;
    if (!equals(this.ilj_1, tmp0_other_with_cast.ilj_1))
      return false;
    return true;
  }
  static jlj(seen0, name, baseColorFactor, baseColorTexture, metallicFactor, roughnessFactor, metallicRoughnessTexture, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_13().zli_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.blj_1 = null;
    else
      $this.blj_1 = name;
    if (0 === (seen0 & 2))
      $this.clj_1 = null;
    else
      $this.clj_1 = baseColorFactor;
    if (0 === (seen0 & 4))
      $this.dlj_1 = null;
    else
      $this.dlj_1 = baseColorTexture;
    if (0 === (seen0 & 8))
      $this.elj_1 = 0.0;
    else
      $this.elj_1 = metallicFactor;
    if (0 === (seen0 & 16))
      $this.flj_1 = 0.0;
    else
      $this.flj_1 = roughnessFactor;
    if (0 === (seen0 & 32))
      $this.glj_1 = null;
    else
      $this.glj_1 = metallicRoughnessTexture;
    if (0 === (seen0 & 64))
      $this.hlj_1 = null;
    else
      $this.hlj_1 = extensions;
    if (0 === (seen0 & 128))
      $this.ilj_1 = null;
    else
      $this.ilj_1 = extras;
    return $this;
  }
}
class BaseTextureInfo extends GLTFProperty {
  constructor() {
    Companion_getInstance_36();
    super();
  }
  mlk(gltf) {
    return gltf.clg_1.i1(this.m8h()).bll(gltf).ulk_1;
  }
  static cll(seen0, serializationConstructorMarker) {
    Companion_getInstance_36();
    return this.ole(seen0, serializationConstructorMarker);
  }
}
class TextureInfo extends BaseTextureInfo {
  constructor(name, index, texCoord, extensions, extras) {
    name = name === VOID ? null : name;
    index = index === VOID ? -1 : index;
    texCoord = texCoord === VOID ? -1 : texCoord;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.nlj_1 = name;
    this.olj_1 = index;
    this.plj_1 = texCoord;
    this.qlj_1 = extensions;
    this.rlj_1 = extras;
  }
  m8h() {
    return this.olj_1;
  }
  toString() {
    return 'TextureInfo(name=' + this.nlj_1 + ', index=' + this.olj_1 + ', texCoord=' + this.plj_1 + ', extensions=' + toString(this.qlj_1) + ', extras=' + toString(this.rlj_1) + ')';
  }
  hashCode() {
    var result = this.nlj_1 == null ? 0 : getStringHashCode(this.nlj_1);
    result = imul(result, 31) + this.olj_1 | 0;
    result = imul(result, 31) + this.plj_1 | 0;
    result = imul(result, 31) + (this.qlj_1 == null ? 0 : hashCode(this.qlj_1)) | 0;
    result = imul(result, 31) + (this.rlj_1 == null ? 0 : hashCode(this.rlj_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof TextureInfo))
      return false;
    var tmp0_other_with_cast = other instanceof TextureInfo ? other : THROW_CCE();
    if (!(this.nlj_1 == tmp0_other_with_cast.nlj_1))
      return false;
    if (!(this.olj_1 === tmp0_other_with_cast.olj_1))
      return false;
    if (!(this.plj_1 === tmp0_other_with_cast.plj_1))
      return false;
    if (!equals(this.qlj_1, tmp0_other_with_cast.qlj_1))
      return false;
    if (!equals(this.rlj_1, tmp0_other_with_cast.rlj_1))
      return false;
    return true;
  }
  static slj(seen0, name, index, texCoord, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_14().llj_1);
    }
    var $this = this.cll(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.nlj_1 = null;
    else
      $this.nlj_1 = name;
    if (0 === (seen0 & 2))
      $this.olj_1 = -1;
    else
      $this.olj_1 = index;
    if (0 === (seen0 & 4))
      $this.plj_1 = -1;
    else
      $this.plj_1 = texCoord;
    if (0 === (seen0 & 8))
      $this.qlj_1 = null;
    else
      $this.qlj_1 = extensions;
    if (0 === (seen0 & 16))
      $this.rlj_1 = null;
    else
      $this.rlj_1 = extras;
    return $this;
  }
}
class OcclusionTextureInfo extends BaseTextureInfo {
  constructor(name, index, texCoord, strength, extensions, extras) {
    name = name === VOID ? null : name;
    index = index === VOID ? -1 : index;
    texCoord = texCoord === VOID ? -1 : texCoord;
    strength = strength === VOID ? 1.0 : strength;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.vlj_1 = name;
    this.wlj_1 = index;
    this.xlj_1 = texCoord;
    this.ylj_1 = strength;
    this.zlj_1 = extensions;
    this.alk_1 = extras;
  }
  m8h() {
    return this.wlj_1;
  }
  toString() {
    return 'OcclusionTextureInfo(name=' + this.vlj_1 + ', index=' + this.wlj_1 + ', texCoord=' + this.xlj_1 + ', strength=' + this.ylj_1 + ', extensions=' + toString(this.zlj_1) + ', extras=' + toString(this.alk_1) + ')';
  }
  hashCode() {
    var result = this.vlj_1 == null ? 0 : getStringHashCode(this.vlj_1);
    result = imul(result, 31) + this.wlj_1 | 0;
    result = imul(result, 31) + this.xlj_1 | 0;
    result = imul(result, 31) + getNumberHashCode(this.ylj_1) | 0;
    result = imul(result, 31) + (this.zlj_1 == null ? 0 : hashCode(this.zlj_1)) | 0;
    result = imul(result, 31) + (this.alk_1 == null ? 0 : hashCode(this.alk_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof OcclusionTextureInfo))
      return false;
    var tmp0_other_with_cast = other instanceof OcclusionTextureInfo ? other : THROW_CCE();
    if (!(this.vlj_1 == tmp0_other_with_cast.vlj_1))
      return false;
    if (!(this.wlj_1 === tmp0_other_with_cast.wlj_1))
      return false;
    if (!(this.xlj_1 === tmp0_other_with_cast.xlj_1))
      return false;
    if (!equals(this.ylj_1, tmp0_other_with_cast.ylj_1))
      return false;
    if (!equals(this.zlj_1, tmp0_other_with_cast.zlj_1))
      return false;
    if (!equals(this.alk_1, tmp0_other_with_cast.alk_1))
      return false;
    return true;
  }
  static blk(seen0, name, index, texCoord, strength, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_15().tlj_1);
    }
    var $this = this.cll(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.vlj_1 = null;
    else
      $this.vlj_1 = name;
    if (0 === (seen0 & 2))
      $this.wlj_1 = -1;
    else
      $this.wlj_1 = index;
    if (0 === (seen0 & 4))
      $this.xlj_1 = -1;
    else
      $this.xlj_1 = texCoord;
    if (0 === (seen0 & 8))
      $this.ylj_1 = 1.0;
    else
      $this.ylj_1 = strength;
    if (0 === (seen0 & 16))
      $this.zlj_1 = null;
    else
      $this.zlj_1 = extensions;
    if (0 === (seen0 & 32))
      $this.alk_1 = null;
    else
      $this.alk_1 = extras;
    return $this;
  }
}
class NormalTextureInfo extends BaseTextureInfo {
  constructor(name, index, texCoord, scale, extensions, extras) {
    name = name === VOID ? null : name;
    index = index === VOID ? -1 : index;
    texCoord = texCoord === VOID ? -1 : texCoord;
    scale = scale === VOID ? 1.0 : scale;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.elk_1 = name;
    this.flk_1 = index;
    this.glk_1 = texCoord;
    this.hlk_1 = scale;
    this.ilk_1 = extensions;
    this.jlk_1 = extras;
  }
  m8h() {
    return this.flk_1;
  }
  toString() {
    return 'NormalTextureInfo(name=' + this.elk_1 + ', index=' + this.flk_1 + ', texCoord=' + this.glk_1 + ', scale=' + this.hlk_1 + ', extensions=' + toString(this.ilk_1) + ', extras=' + toString(this.jlk_1) + ')';
  }
  hashCode() {
    var result = this.elk_1 == null ? 0 : getStringHashCode(this.elk_1);
    result = imul(result, 31) + this.flk_1 | 0;
    result = imul(result, 31) + this.glk_1 | 0;
    result = imul(result, 31) + getNumberHashCode(this.hlk_1) | 0;
    result = imul(result, 31) + (this.ilk_1 == null ? 0 : hashCode(this.ilk_1)) | 0;
    result = imul(result, 31) + (this.jlk_1 == null ? 0 : hashCode(this.jlk_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof NormalTextureInfo))
      return false;
    var tmp0_other_with_cast = other instanceof NormalTextureInfo ? other : THROW_CCE();
    if (!(this.elk_1 == tmp0_other_with_cast.elk_1))
      return false;
    if (!(this.flk_1 === tmp0_other_with_cast.flk_1))
      return false;
    if (!(this.glk_1 === tmp0_other_with_cast.glk_1))
      return false;
    if (!equals(this.hlk_1, tmp0_other_with_cast.hlk_1))
      return false;
    if (!equals(this.ilk_1, tmp0_other_with_cast.ilk_1))
      return false;
    if (!equals(this.jlk_1, tmp0_other_with_cast.jlk_1))
      return false;
    return true;
  }
  static klk(seen0, name, index, texCoord, scale, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_16().clk_1);
    }
    var $this = this.cll(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.elk_1 = null;
    else
      $this.elk_1 = name;
    if (0 === (seen0 & 2))
      $this.flk_1 = -1;
    else
      $this.flk_1 = index;
    if (0 === (seen0 & 4))
      $this.glk_1 = -1;
    else
      $this.glk_1 = texCoord;
    if (0 === (seen0 & 8))
      $this.hlk_1 = 1.0;
    else
      $this.hlk_1 = scale;
    if (0 === (seen0 & 16))
      $this.ilk_1 = null;
    else
      $this.ilk_1 = extensions;
    if (0 === (seen0 & 32))
      $this.jlk_1 = null;
    else
      $this.jlk_1 = extras;
    return $this;
  }
}
class Companion_24 {}
class $serializer_17 {
  constructor() {
    $serializer_instance_17 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Material', this, 11);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('alphaMode', true);
    tmp0_serialDesc.fjp('alphaCutoff', true);
    tmp0_serialDesc.fjp('doubleSided', true);
    tmp0_serialDesc.fjp('emissiveFactor', true);
    tmp0_serialDesc.fjp('emissiveTexture', true);
    tmp0_serialDesc.fjp('pbrMetallicRoughness', true);
    tmp0_serialDesc.fjp('normalTexture', true);
    tmp0_serialDesc.fjp('occlusionTexture', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.dll_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.dll_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = 0.0;
    var tmp7_local3 = false;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_input = decoder.pjj(tmp0_desc);
    if (tmp15_input.fjk()) {
      tmp4_local0 = tmp15_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp15_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp15_input.wjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp15_input.rjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp15_input.djk(tmp0_desc, 4, FloatArraySerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp15_input.djk(tmp0_desc, 5, $serializer_getInstance_14(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp15_input.bjk(tmp0_desc, 6, $serializer_getInstance_13(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp15_input.djk(tmp0_desc, 7, $serializer_getInstance_16(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp15_input.djk(tmp0_desc, 8, $serializer_getInstance_15(), tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp15_input.djk(tmp0_desc, 9, JsonElementSerializer_getInstance(), tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp15_input.djk(tmp0_desc, 10, JsonElementSerializer_getInstance(), tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp15_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp15_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp15_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp15_input.wjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp15_input.rjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp15_input.djk(tmp0_desc, 4, FloatArraySerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp15_input.djk(tmp0_desc, 5, $serializer_getInstance_14(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp15_input.bjk(tmp0_desc, 6, $serializer_getInstance_13(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp15_input.djk(tmp0_desc, 7, $serializer_getInstance_16(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp15_input.djk(tmp0_desc, 8, $serializer_getInstance_15(), tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp15_input.djk(tmp0_desc, 9, JsonElementSerializer_getInstance(), tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp15_input.djk(tmp0_desc, 10, JsonElementSerializer_getInstance(), tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp15_input.qjj(tmp0_desc);
    return Material.qll(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, null);
  }
  ujf() {
    return this.dll_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), FloatSerializer_getInstance(), BooleanSerializer_getInstance(), get_nullable(FloatArraySerializer_getInstance()), get_nullable($serializer_getInstance_14()), $serializer_getInstance_13(), get_nullable($serializer_getInstance_16()), get_nullable($serializer_getInstance_15()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_25 {}
class $serializer_18 {
  constructor() {
    $serializer_instance_18 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Texture', this, 5);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('sampler', true);
    tmp0_serialDesc.fjp('source', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.rll_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.rll_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_input = decoder.pjj(tmp0_desc);
    if (tmp9_input.fjk()) {
      tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp9_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp9_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp9_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp9_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp9_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp9_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp9_input.qjj(tmp0_desc);
    return Texture.sll(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.rll_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_26 {}
class $serializer_19 {
  constructor() {
    $serializer_instance_19 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Image', this, 6);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('uri', true);
    tmp0_serialDesc.fjp('bufferView', true);
    tmp0_serialDesc.fjp('mimeType', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.tll_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.tll_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = 0;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp11_input = decoder.pjj(tmp0_desc);
    if (tmp11_input.fjk()) {
      tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.djk(tmp0_desc, 3, StringSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.djk(tmp0_desc, 3, StringSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp11_input.qjj(tmp0_desc);
    return Image.ull(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.tll_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), get_nullable(StringSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_27 {}
class $serializer_20 {
  constructor() {
    $serializer_instance_20 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Sampler', this, 7);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('magFilter', true);
    tmp0_serialDesc.fjp('minFilter', true);
    tmp0_serialDesc.fjp('wrapS', true);
    tmp0_serialDesc.fjp('wrapT', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.vll_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.vll_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0;
    var tmp8_local4 = 0;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_input = decoder.pjj(tmp0_desc);
    if (tmp11_input.fjk()) {
      tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.ujj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.ujj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp11_input.qjj(tmp0_desc);
    return Sampler_1.elm(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, null);
  }
  ujf() {
    return this.vll_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_28 {}
class $serializer_21 {
  constructor() {
    $serializer_instance_21 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Camera.Orthographic', this, 7);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('xmag', false);
    tmp0_serialDesc.fjp('ymag', false);
    tmp0_serialDesc.fjp('zfar', false);
    tmp0_serialDesc.fjp('znear', false);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.flm_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.flm_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0.0;
    var tmp6_local2 = 0.0;
    var tmp7_local3 = 0.0;
    var tmp8_local4 = 0.0;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_input = decoder.pjj(tmp0_desc);
    if (tmp11_input.fjk()) {
      tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.wjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.wjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.wjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.wjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.wjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.wjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.wjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.wjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp11_input.qjj(tmp0_desc);
    return Orthographic.olm(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, null);
  }
  ujf() {
    return this.flm_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), FloatSerializer_getInstance(), FloatSerializer_getInstance(), FloatSerializer_getInstance(), FloatSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Companion_29 {}
class $serializer_22 {
  constructor() {
    $serializer_instance_22 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Camera.Perspective', this, 7);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('aspectRatio', true);
    tmp0_serialDesc.fjp('yfov', true);
    tmp0_serialDesc.fjp('zfar', true);
    tmp0_serialDesc.fjp('znear', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.plm_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.plm_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0.0;
    var tmp6_local2 = 0.0;
    var tmp7_local3 = 0.0;
    var tmp8_local4 = 0.0;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_input = decoder.pjj(tmp0_desc);
    if (tmp11_input.fjk()) {
      tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.wjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.wjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.wjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.wjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.wjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.wjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.wjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.wjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.djk(tmp0_desc, 6, JsonElementSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp11_input.qjj(tmp0_desc);
    return Perspective_0.ylm(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, null);
  }
  ujf() {
    return this.plm_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), FloatSerializer_getInstance(), FloatSerializer_getInstance(), FloatSerializer_getInstance(), FloatSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class Orthographic extends GLTFProperty {
  toString() {
    return 'Orthographic(name=' + this.hlm_1 + ', xmag=' + this.ilm_1 + ', ymag=' + this.jlm_1 + ', zfar=' + this.klm_1 + ', znear=' + this.llm_1 + ', extensions=' + toString(this.mlm_1) + ', extras=' + toString(this.nlm_1) + ')';
  }
  hashCode() {
    var result = this.hlm_1 == null ? 0 : getStringHashCode(this.hlm_1);
    result = imul(result, 31) + getNumberHashCode(this.ilm_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.jlm_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.klm_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.llm_1) | 0;
    result = imul(result, 31) + (this.mlm_1 == null ? 0 : hashCode(this.mlm_1)) | 0;
    result = imul(result, 31) + (this.nlm_1 == null ? 0 : hashCode(this.nlm_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Orthographic))
      return false;
    var tmp0_other_with_cast = other instanceof Orthographic ? other : THROW_CCE();
    if (!(this.hlm_1 == tmp0_other_with_cast.hlm_1))
      return false;
    if (!equals(this.ilm_1, tmp0_other_with_cast.ilm_1))
      return false;
    if (!equals(this.jlm_1, tmp0_other_with_cast.jlm_1))
      return false;
    if (!equals(this.klm_1, tmp0_other_with_cast.klm_1))
      return false;
    if (!equals(this.llm_1, tmp0_other_with_cast.llm_1))
      return false;
    if (!equals(this.mlm_1, tmp0_other_with_cast.mlm_1))
      return false;
    if (!equals(this.nlm_1, tmp0_other_with_cast.nlm_1))
      return false;
    return true;
  }
  static olm(seen0, name, xmag, ymag, zfar, znear, extensions, extras, serializationConstructorMarker) {
    if (!(30 === (30 & seen0))) {
      throwMissingFieldException(seen0, 30, $serializer_getInstance_21().flm_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.hlm_1 = null;
    else
      $this.hlm_1 = name;
    $this.ilm_1 = xmag;
    $this.jlm_1 = ymag;
    $this.klm_1 = zfar;
    $this.llm_1 = znear;
    if (0 === (seen0 & 32))
      $this.mlm_1 = null;
    else
      $this.mlm_1 = extensions;
    if (0 === (seen0 & 64))
      $this.nlm_1 = null;
    else
      $this.nlm_1 = extras;
    return $this;
  }
}
class Perspective_0 extends GLTFProperty {
  constructor(name, aspectRatio, yfov, zfar, znear, extensions, extras) {
    name = name === VOID ? null : name;
    aspectRatio = aspectRatio === VOID ? 1.5 : aspectRatio;
    yfov = yfov === VOID ? 0.660593 : yfov;
    zfar = zfar === VOID ? 100.0 : zfar;
    znear = znear === VOID ? 0.01 : znear;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.rlm_1 = name;
    this.slm_1 = aspectRatio;
    this.tlm_1 = yfov;
    this.ulm_1 = zfar;
    this.vlm_1 = znear;
    this.wlm_1 = extensions;
    this.xlm_1 = extras;
  }
  zlm() {
    return Perspective.ikx(get_radians(this.tlm_1), this.vlm_1, this.ulm_1);
  }
  toString() {
    return 'Perspective(name=' + this.rlm_1 + ', aspectRatio=' + this.slm_1 + ', yfov=' + this.tlm_1 + ', zfar=' + this.ulm_1 + ', znear=' + this.vlm_1 + ', extensions=' + toString(this.wlm_1) + ', extras=' + toString(this.xlm_1) + ')';
  }
  hashCode() {
    var result = this.rlm_1 == null ? 0 : getStringHashCode(this.rlm_1);
    result = imul(result, 31) + getNumberHashCode(this.slm_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.tlm_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ulm_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.vlm_1) | 0;
    result = imul(result, 31) + (this.wlm_1 == null ? 0 : hashCode(this.wlm_1)) | 0;
    result = imul(result, 31) + (this.xlm_1 == null ? 0 : hashCode(this.xlm_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Perspective_0))
      return false;
    var tmp0_other_with_cast = other instanceof Perspective_0 ? other : THROW_CCE();
    if (!(this.rlm_1 == tmp0_other_with_cast.rlm_1))
      return false;
    if (!equals(this.slm_1, tmp0_other_with_cast.slm_1))
      return false;
    if (!equals(this.tlm_1, tmp0_other_with_cast.tlm_1))
      return false;
    if (!equals(this.ulm_1, tmp0_other_with_cast.ulm_1))
      return false;
    if (!equals(this.vlm_1, tmp0_other_with_cast.vlm_1))
      return false;
    if (!equals(this.wlm_1, tmp0_other_with_cast.wlm_1))
      return false;
    if (!equals(this.xlm_1, tmp0_other_with_cast.xlm_1))
      return false;
    return true;
  }
  static ylm(seen0, name, aspectRatio, yfov, zfar, znear, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_22().plm_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.rlm_1 = null;
    else
      $this.rlm_1 = name;
    if (0 === (seen0 & 2))
      $this.slm_1 = 1.5;
    else
      $this.slm_1 = aspectRatio;
    if (0 === (seen0 & 4))
      $this.tlm_1 = 0.660593;
    else
      $this.tlm_1 = yfov;
    if (0 === (seen0 & 8))
      $this.ulm_1 = 100.0;
    else
      $this.ulm_1 = zfar;
    if (0 === (seen0 & 16))
      $this.vlm_1 = 0.01;
    else
      $this.vlm_1 = znear;
    if (0 === (seen0 & 32))
      $this.wlm_1 = null;
    else
      $this.wlm_1 = extensions;
    if (0 === (seen0 & 64))
      $this.xlm_1 = null;
    else
      $this.xlm_1 = extras;
    return $this;
  }
}
class Companion_30 {}
class $serializer_23 {
  constructor() {
    $serializer_instance_23 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2.Camera', this, 6);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('type', false);
    tmp0_serialDesc.fjp('perspective', true);
    tmp0_serialDesc.fjp('orthographic', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.aln_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.aln_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.zjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.djk(tmp0_desc, 2, $serializer_getInstance_22(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, $serializer_getInstance_21(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.zjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.djk(tmp0_desc, 2, $serializer_getInstance_22(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, $serializer_getInstance_21(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.djk(tmp0_desc, 4, JsonElementSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp10_input.djk(tmp0_desc, 5, JsonElementSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return Camera.iln(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.aln_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), StringSerializer_getInstance(), get_nullable($serializer_getInstance_22()), get_nullable($serializer_getInstance_21()), get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class ReadOptions {
  constructor(ignoreUnknownKeys) {
    Companion_getInstance_18();
    ignoreUnknownKeys = ignoreUnknownKeys === VOID ? false : ignoreUnknownKeys;
    this.nln_1 = ignoreUnknownKeys;
  }
  toString() {
    return 'ReadOptions(ignoreUnknownKeys=' + this.nln_1 + ')';
  }
  hashCode() {
    return getBooleanHashCode(this.nln_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ReadOptions))
      return false;
    var tmp0_other_with_cast = other instanceof ReadOptions ? other : THROW_CCE();
    if (!(this.nln_1 === tmp0_other_with_cast.nln_1))
      return false;
    return true;
  }
}
class Asset extends GLTFProperty {
  constructor(name, version, generator, copyright, minVersion, extensions, extras) {
    name = name === VOID ? null : name;
    version = version === VOID ? '2.0' : version;
    generator = generator === VOID ? null : generator;
    copyright = copyright === VOID ? null : copyright;
    minVersion = minVersion === VOID ? null : minVersion;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.flb_1 = name;
    this.glb_1 = version;
    this.hlb_1 = generator;
    this.ilb_1 = copyright;
    this.jlb_1 = minVersion;
    this.klb_1 = extensions;
    this.llb_1 = extras;
  }
  toString() {
    return 'Asset(name=' + this.flb_1 + ', version=' + this.glb_1 + ', generator=' + this.hlb_1 + ', copyright=' + this.ilb_1 + ', minVersion=' + this.jlb_1 + ', extensions=' + toString(this.klb_1) + ', extras=' + toString(this.llb_1) + ')';
  }
  hashCode() {
    var result = this.flb_1 == null ? 0 : getStringHashCode(this.flb_1);
    result = imul(result, 31) + getStringHashCode(this.glb_1) | 0;
    result = imul(result, 31) + (this.hlb_1 == null ? 0 : getStringHashCode(this.hlb_1)) | 0;
    result = imul(result, 31) + (this.ilb_1 == null ? 0 : getStringHashCode(this.ilb_1)) | 0;
    result = imul(result, 31) + (this.jlb_1 == null ? 0 : getStringHashCode(this.jlb_1)) | 0;
    result = imul(result, 31) + (this.klb_1 == null ? 0 : hashCode(this.klb_1)) | 0;
    result = imul(result, 31) + (this.llb_1 == null ? 0 : hashCode(this.llb_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Asset))
      return false;
    var tmp0_other_with_cast = other instanceof Asset ? other : THROW_CCE();
    if (!(this.flb_1 == tmp0_other_with_cast.flb_1))
      return false;
    if (!(this.glb_1 === tmp0_other_with_cast.glb_1))
      return false;
    if (!(this.hlb_1 == tmp0_other_with_cast.hlb_1))
      return false;
    if (!(this.ilb_1 == tmp0_other_with_cast.ilb_1))
      return false;
    if (!(this.jlb_1 == tmp0_other_with_cast.jlb_1))
      return false;
    if (!equals(this.klb_1, tmp0_other_with_cast.klb_1))
      return false;
    if (!equals(this.llb_1, tmp0_other_with_cast.llb_1))
      return false;
    return true;
  }
  static mlb(seen0, name, version, generator, copyright, minVersion, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance().dlb_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.flb_1 = null;
    else
      $this.flb_1 = name;
    if (0 === (seen0 & 2))
      $this.glb_1 = '2.0';
    else
      $this.glb_1 = version;
    if (0 === (seen0 & 4))
      $this.hlb_1 = null;
    else
      $this.hlb_1 = generator;
    if (0 === (seen0 & 8))
      $this.ilb_1 = null;
    else
      $this.ilb_1 = copyright;
    if (0 === (seen0 & 16))
      $this.jlb_1 = null;
    else
      $this.jlb_1 = minVersion;
    if (0 === (seen0 & 32))
      $this.klb_1 = null;
    else
      $this.klb_1 = extensions;
    if (0 === (seen0 & 64))
      $this.llb_1 = null;
    else
      $this.llb_1 = extras;
    return $this;
  }
}
class Scene extends GLTFProperty {
  constructor(name, nodes, extensions, extras) {
    name = name === VOID ? null : name;
    var tmp;
    if (nodes === VOID) {
      // Inline function 'kotlin.intArrayOf' call
      tmp = new Int32Array([]);
    } else {
      tmp = nodes;
    }
    nodes = tmp;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.plb_1 = name;
    this.qlb_1 = nodes;
    this.rlb_1 = extensions;
    this.slb_1 = extras;
  }
  toString() {
    return 'Scene(name=' + this.plb_1 + ', nodes=' + toString_0(this.qlb_1) + ', extensions=' + toString(this.rlb_1) + ', extras=' + toString(this.slb_1) + ')';
  }
  hashCode() {
    var result = this.plb_1 == null ? 0 : getStringHashCode(this.plb_1);
    result = imul(result, 31) + hashCode(this.qlb_1) | 0;
    result = imul(result, 31) + (this.rlb_1 == null ? 0 : hashCode(this.rlb_1)) | 0;
    result = imul(result, 31) + (this.slb_1 == null ? 0 : hashCode(this.slb_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Scene))
      return false;
    var tmp0_other_with_cast = other instanceof Scene ? other : THROW_CCE();
    if (!(this.plb_1 == tmp0_other_with_cast.plb_1))
      return false;
    if (!equals(this.qlb_1, tmp0_other_with_cast.qlb_1))
      return false;
    if (!equals(this.rlb_1, tmp0_other_with_cast.rlb_1))
      return false;
    if (!equals(this.slb_1, tmp0_other_with_cast.slb_1))
      return false;
    return true;
  }
  static tlb(seen0, name, nodes, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_0().nlb_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.plb_1 = null;
    else
      $this.plb_1 = name;
    if (0 === (seen0 & 2)) {
      var tmp = $this;
      // Inline function 'kotlin.intArrayOf' call
      tmp.qlb_1 = new Int32Array([]);
    } else
      $this.qlb_1 = nodes;
    if (0 === (seen0 & 4))
      $this.rlb_1 = null;
    else
      $this.rlb_1 = extensions;
    if (0 === (seen0 & 8))
      $this.slb_1 = null;
    else
      $this.slb_1 = extras;
    return $this;
  }
}
class Node extends GLTFProperty {
  constructor(name, camera, skin, mesh, children, scale, translation, rotation, matrix, weights, extensions, extras) {
    name = name === VOID ? null : name;
    camera = camera === VOID ? -1 : camera;
    skin = skin === VOID ? -1 : skin;
    mesh = mesh === VOID ? -1 : mesh;
    children = children === VOID ? null : children;
    scale = scale === VOID ? null : scale;
    translation = translation === VOID ? null : translation;
    rotation = rotation === VOID ? null : rotation;
    matrix = matrix === VOID ? null : matrix;
    weights = weights === VOID ? null : weights;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.wlb_1 = name;
    this.xlb_1 = camera;
    this.ylb_1 = skin;
    this.zlb_1 = mesh;
    this.alc_1 = children;
    this.blc_1 = scale;
    this.clc_1 = translation;
    this.dlc_1 = rotation;
    this.elc_1 = matrix;
    this.flc_1 = weights;
    this.glc_1 = extensions;
    this.hlc_1 = extras;
    var tmp = this;
    tmp.ilc_1 = lazy(GLTF2$Node$mmatrix$delegate$lambda(this));
  }
  qln() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ilc_1;
    mmatrix$factory();
    return this_0.b2();
  }
  toString() {
    return 'Node(name=' + this.wlb_1 + ', camera=' + this.xlb_1 + ', skin=' + this.ylb_1 + ', mesh=' + this.zlb_1 + ', children=' + toString_0(this.alc_1) + ', scale=' + toString_0(this.blc_1) + ', translation=' + toString_0(this.clc_1) + ', rotation=' + toString_0(this.dlc_1) + ', matrix=' + toString_0(this.elc_1) + ', weights=' + toString_0(this.flc_1) + ', extensions=' + toString(this.glc_1) + ', extras=' + toString(this.hlc_1) + ')';
  }
  hashCode() {
    var result = this.wlb_1 == null ? 0 : getStringHashCode(this.wlb_1);
    result = imul(result, 31) + this.xlb_1 | 0;
    result = imul(result, 31) + this.ylb_1 | 0;
    result = imul(result, 31) + this.zlb_1 | 0;
    result = imul(result, 31) + (this.alc_1 == null ? 0 : hashCode(this.alc_1)) | 0;
    result = imul(result, 31) + (this.blc_1 == null ? 0 : hashCode(this.blc_1)) | 0;
    result = imul(result, 31) + (this.clc_1 == null ? 0 : hashCode(this.clc_1)) | 0;
    result = imul(result, 31) + (this.dlc_1 == null ? 0 : hashCode(this.dlc_1)) | 0;
    result = imul(result, 31) + (this.elc_1 == null ? 0 : hashCode(this.elc_1)) | 0;
    result = imul(result, 31) + (this.flc_1 == null ? 0 : hashCode(this.flc_1)) | 0;
    result = imul(result, 31) + (this.glc_1 == null ? 0 : hashCode(this.glc_1)) | 0;
    result = imul(result, 31) + (this.hlc_1 == null ? 0 : hashCode(this.hlc_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Node))
      return false;
    var tmp0_other_with_cast = other instanceof Node ? other : THROW_CCE();
    if (!(this.wlb_1 == tmp0_other_with_cast.wlb_1))
      return false;
    if (!(this.xlb_1 === tmp0_other_with_cast.xlb_1))
      return false;
    if (!(this.ylb_1 === tmp0_other_with_cast.ylb_1))
      return false;
    if (!(this.zlb_1 === tmp0_other_with_cast.zlb_1))
      return false;
    if (!equals(this.alc_1, tmp0_other_with_cast.alc_1))
      return false;
    if (!equals(this.blc_1, tmp0_other_with_cast.blc_1))
      return false;
    if (!equals(this.clc_1, tmp0_other_with_cast.clc_1))
      return false;
    if (!equals(this.dlc_1, tmp0_other_with_cast.dlc_1))
      return false;
    if (!equals(this.elc_1, tmp0_other_with_cast.elc_1))
      return false;
    if (!equals(this.flc_1, tmp0_other_with_cast.flc_1))
      return false;
    if (!equals(this.glc_1, tmp0_other_with_cast.glc_1))
      return false;
    if (!equals(this.hlc_1, tmp0_other_with_cast.hlc_1))
      return false;
    return true;
  }
  static jlc(seen0, name, camera, skin, mesh, children, scale, translation, rotation, matrix, weights, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_1().ulb_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.wlb_1 = null;
    else
      $this.wlb_1 = name;
    if (0 === (seen0 & 2))
      $this.xlb_1 = -1;
    else
      $this.xlb_1 = camera;
    if (0 === (seen0 & 4))
      $this.ylb_1 = -1;
    else
      $this.ylb_1 = skin;
    if (0 === (seen0 & 8))
      $this.zlb_1 = -1;
    else
      $this.zlb_1 = mesh;
    if (0 === (seen0 & 16))
      $this.alc_1 = null;
    else
      $this.alc_1 = children;
    if (0 === (seen0 & 32))
      $this.blc_1 = null;
    else
      $this.blc_1 = scale;
    if (0 === (seen0 & 64))
      $this.clc_1 = null;
    else
      $this.clc_1 = translation;
    if (0 === (seen0 & 128))
      $this.dlc_1 = null;
    else
      $this.dlc_1 = rotation;
    if (0 === (seen0 & 256))
      $this.elc_1 = null;
    else
      $this.elc_1 = matrix;
    if (0 === (seen0 & 512))
      $this.flc_1 = null;
    else
      $this.flc_1 = weights;
    if (0 === (seen0 & 1024))
      $this.glc_1 = null;
    else
      $this.glc_1 = extensions;
    if (0 === (seen0 & 2048))
      $this.hlc_1 = null;
    else
      $this.hlc_1 = extras;
    var tmp = $this;
    tmp.ilc_1 = lazy(GLTF2$Node$_init_$lambda_rh2074($this));
    return $this;
  }
}
class Mesh extends GLTFProperty {
  constructor(name, primitives, weights, extensions, extras) {
    Companion_getInstance_22();
    name = name === VOID ? null : name;
    primitives = primitives === VOID ? emptyList() : primitives;
    weights = weights === VOID ? null : weights;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.nlc_1 = name;
    this.olc_1 = primitives;
    this.plc_1 = weights;
    this.qlc_1 = extensions;
    this.rlc_1 = extras;
    var tmp = this;
    var tmp_0;
    if (!(this.plc_1 == null)) {
      // Inline function 'korlibs.math.geom.Companion.func' call
      Companion_getInstance_11();
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_0 = this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_1;
      if (0 <= 0 ? 0 <= (this_0.length - 1 | 0) : false) {
        tmp_1 = this_0[0];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>.<anonymous>' call
        tmp_1 = 0.0;
      }
      var tmp_2 = tmp_1;
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_1 = this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_3;
      if (0 <= 1 ? 1 <= (this_1.length - 1 | 0) : false) {
        tmp_3 = this_1[1];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>.<anonymous>' call
        tmp_3 = 0.0;
      }
      var tmp_4 = tmp_3;
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_2 = this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_5;
      if (0 <= 2 ? 2 <= (this_2.length - 1 | 0) : false) {
        tmp_5 = this_2[2];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>.<anonymous>' call
        tmp_5 = 0.0;
      }
      var tmp_6 = tmp_5;
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_3 = this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_7;
      if (0 <= 3 ? 3 <= (this_3.length - 1 | 0) : false) {
        tmp_7 = this_3[3];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.weightsVector.<anonymous>.<anonymous>' call
        tmp_7 = 0.0;
      }
      var tmp$ret$11 = tmp_7;
      tmp_0 = new Vector4F(tmp_2, tmp_4, tmp_6, tmp$ret$11);
    } else {
      tmp_0 = Companion_getInstance_11().m2r_1;
    }
    tmp.slc_1 = tmp_0;
  }
  toString() {
    return 'Mesh(name=' + this.nlc_1 + ', primitives=' + toString_0(this.olc_1) + ', weights=' + toString_0(this.plc_1) + ', extensions=' + toString(this.qlc_1) + ', extras=' + toString(this.rlc_1) + ')';
  }
  hashCode() {
    var result = this.nlc_1 == null ? 0 : getStringHashCode(this.nlc_1);
    result = imul(result, 31) + hashCode(this.olc_1) | 0;
    result = imul(result, 31) + (this.plc_1 == null ? 0 : hashCode(this.plc_1)) | 0;
    result = imul(result, 31) + (this.qlc_1 == null ? 0 : hashCode(this.qlc_1)) | 0;
    result = imul(result, 31) + (this.rlc_1 == null ? 0 : hashCode(this.rlc_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Mesh))
      return false;
    var tmp0_other_with_cast = other instanceof Mesh ? other : THROW_CCE();
    if (!(this.nlc_1 == tmp0_other_with_cast.nlc_1))
      return false;
    if (!equals(this.olc_1, tmp0_other_with_cast.olc_1))
      return false;
    if (!equals(this.plc_1, tmp0_other_with_cast.plc_1))
      return false;
    if (!equals(this.qlc_1, tmp0_other_with_cast.qlc_1))
      return false;
    if (!equals(this.rlc_1, tmp0_other_with_cast.rlc_1))
      return false;
    return true;
  }
  static tlc(seen0, name, primitives, weights, extensions, extras, serializationConstructorMarker) {
    Companion_getInstance_22();
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_2().llc_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.nlc_1 = null;
    else
      $this.nlc_1 = name;
    if (0 === (seen0 & 2))
      $this.olc_1 = emptyList();
    else
      $this.olc_1 = primitives;
    if (0 === (seen0 & 4))
      $this.plc_1 = null;
    else
      $this.plc_1 = weights;
    if (0 === (seen0 & 8))
      $this.qlc_1 = null;
    else
      $this.qlc_1 = extensions;
    if (0 === (seen0 & 16))
      $this.rlc_1 = null;
    else
      $this.rlc_1 = extras;
    var tmp = $this;
    var tmp_0;
    if (!($this.plc_1 == null)) {
      // Inline function 'korlibs.math.geom.Companion.func' call
      Companion_getInstance_11();
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_0 = $this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_1;
      if (0 <= 0 ? 0 <= (this_0.length - 1 | 0) : false) {
        tmp_1 = this_0[0];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>.<anonymous>' call
        tmp_1 = 0.0;
      }
      var tmp_2 = tmp_1;
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_1 = $this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_3;
      if (0 <= 1 ? 1 <= (this_1.length - 1 | 0) : false) {
        tmp_3 = this_1[1];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>.<anonymous>' call
        tmp_3 = 0.0;
      }
      var tmp_4 = tmp_3;
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_2 = $this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_5;
      if (0 <= 2 ? 2 <= (this_2.length - 1 | 0) : false) {
        tmp_5 = this_2[2];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>.<anonymous>' call
        tmp_5 = 0.0;
      }
      var tmp_6 = tmp_5;
      // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>' call
      // Inline function 'kotlin.collections.getOrElse' call
      var this_3 = $this.plc_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_7;
      if (0 <= 3 ? 3 <= (this_3.length - 1 | 0) : false) {
        tmp_7 = this_3[3];
      } else {
        // Inline function 'korlibs.korge3d.format.gltf2.Mesh.<init>.<anonymous>.<anonymous>' call
        tmp_7 = 0.0;
      }
      var tmp$ret$11 = tmp_7;
      tmp_0 = new Vector4F(tmp_2, tmp_4, tmp_6, tmp$ret$11);
    } else {
      tmp_0 = Companion_getInstance_11().m2r_1;
    }
    tmp.slc_1 = tmp_0;
    return $this;
  }
}
class PrimitiveAttribute {
  constructor(str) {
    Companion_getInstance_23();
    this.eld_1 = str;
  }
  toString() {
    return PrimitiveAttribute__toString_impl_lmy2fg(this.eld_1);
  }
  hashCode() {
    return PrimitiveAttribute__hashCode_impl_47udf(this.eld_1);
  }
  equals(other) {
    return PrimitiveAttribute__equals_impl_l2x0rd(this.eld_1, other);
  }
}
class Primitive extends GLTFProperty {
  constructor(name, attributes, indices, material, mode, targets, extensions, extras) {
    Companion_getInstance_24();
    name = name === VOID ? null : name;
    attributes = attributes === VOID ? emptyMap() : attributes;
    indices = indices === VOID ? -1 : indices;
    material = material === VOID ? -1 : material;
    mode = mode === VOID ? 4 : mode;
    targets = targets === VOID ? emptyList() : targets;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.ild_1 = name;
    this.jld_1 = attributes;
    this.kld_1 = indices;
    this.lld_1 = material;
    this.mld_1 = mode;
    this.nld_1 = targets;
    this.old_1 = extensions;
    this.pld_1 = extras;
  }
  rln() {
    var tmp;
    switch (this.mld_1) {
      case 0:
        tmp = Companion_getInstance_0().h8u_1;
        break;
      case 1:
        tmp = Companion_getInstance_0().k8u_1;
        break;
      case 2:
        tmp = Companion_getInstance_0().j8u_1;
        break;
      case 3:
        tmp = Companion_getInstance_0().i8u_1;
        break;
      case 4:
        tmp = Companion_getInstance_0().l8u_1;
        break;
      case 5:
        tmp = Companion_getInstance_0().m8u_1;
        break;
      case 6:
        tmp = Companion_getInstance_0().n8u_1;
        break;
      default:
        var reason = 'Unsupported draw mode=' + this.mld_1;
        throw NotImplementedError.oe('An operation is not implemented: ' + reason);
    }
    return tmp;
  }
  toString() {
    return 'Primitive(name=' + this.ild_1 + ', attributes=' + toString_0(this.jld_1) + ', indices=' + this.kld_1 + ', material=' + this.lld_1 + ', mode=' + this.mld_1 + ', targets=' + toString_0(this.nld_1) + ', extensions=' + toString(this.old_1) + ', extras=' + toString(this.pld_1) + ')';
  }
  hashCode() {
    var result = this.ild_1 == null ? 0 : getStringHashCode(this.ild_1);
    result = imul(result, 31) + hashCode(this.jld_1) | 0;
    result = imul(result, 31) + this.kld_1 | 0;
    result = imul(result, 31) + this.lld_1 | 0;
    result = imul(result, 31) + this.mld_1 | 0;
    result = imul(result, 31) + hashCode(this.nld_1) | 0;
    result = imul(result, 31) + (this.old_1 == null ? 0 : hashCode(this.old_1)) | 0;
    result = imul(result, 31) + (this.pld_1 == null ? 0 : hashCode(this.pld_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Primitive))
      return false;
    var tmp0_other_with_cast = other instanceof Primitive ? other : THROW_CCE();
    if (!(this.ild_1 == tmp0_other_with_cast.ild_1))
      return false;
    if (!equals(this.jld_1, tmp0_other_with_cast.jld_1))
      return false;
    if (!(this.kld_1 === tmp0_other_with_cast.kld_1))
      return false;
    if (!(this.lld_1 === tmp0_other_with_cast.lld_1))
      return false;
    if (!(this.mld_1 === tmp0_other_with_cast.mld_1))
      return false;
    if (!equals(this.nld_1, tmp0_other_with_cast.nld_1))
      return false;
    if (!equals(this.old_1, tmp0_other_with_cast.old_1))
      return false;
    if (!equals(this.pld_1, tmp0_other_with_cast.pld_1))
      return false;
    return true;
  }
  static qld(seen0, name, attributes, indices, material, mode, targets, extensions, extras, serializationConstructorMarker) {
    Companion_getInstance_24();
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_4().gld_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.ild_1 = null;
    else
      $this.ild_1 = name;
    if (0 === (seen0 & 2))
      $this.jld_1 = emptyMap();
    else
      $this.jld_1 = attributes;
    if (0 === (seen0 & 4))
      $this.kld_1 = -1;
    else
      $this.kld_1 = indices;
    if (0 === (seen0 & 8))
      $this.lld_1 = -1;
    else
      $this.lld_1 = material;
    if (0 === (seen0 & 16))
      $this.mld_1 = 4;
    else
      $this.mld_1 = mode;
    if (0 === (seen0 & 32))
      $this.nld_1 = emptyList();
    else
      $this.nld_1 = targets;
    if (0 === (seen0 & 64))
      $this.old_1 = null;
    else
      $this.old_1 = extensions;
    if (0 === (seen0 & 128))
      $this.pld_1 = null;
    else
      $this.pld_1 = extras;
    return $this;
  }
}
class Skin extends GLTFProperty {
  constructor(name, inverseBindMatrices, joints, skeleton, extensions, extras) {
    name = name === VOID ? null : name;
    inverseBindMatrices = inverseBindMatrices === VOID ? -1 : inverseBindMatrices;
    joints = joints === VOID ? new Int32Array(0) : joints;
    skeleton = skeleton === VOID ? -1 : skeleton;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.tld_1 = name;
    this.uld_1 = inverseBindMatrices;
    this.vld_1 = joints;
    this.wld_1 = skeleton;
    this.xld_1 = extensions;
    this.yld_1 = extras;
  }
  toString() {
    return 'Skin(name=' + this.tld_1 + ', inverseBindMatrices=' + this.uld_1 + ', joints=' + toString_0(this.vld_1) + ', skeleton=' + this.wld_1 + ', extensions=' + toString(this.xld_1) + ', extras=' + toString(this.yld_1) + ')';
  }
  hashCode() {
    var result = this.tld_1 == null ? 0 : getStringHashCode(this.tld_1);
    result = imul(result, 31) + this.uld_1 | 0;
    result = imul(result, 31) + hashCode(this.vld_1) | 0;
    result = imul(result, 31) + this.wld_1 | 0;
    result = imul(result, 31) + (this.xld_1 == null ? 0 : hashCode(this.xld_1)) | 0;
    result = imul(result, 31) + (this.yld_1 == null ? 0 : hashCode(this.yld_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Skin))
      return false;
    var tmp0_other_with_cast = other instanceof Skin ? other : THROW_CCE();
    if (!(this.tld_1 == tmp0_other_with_cast.tld_1))
      return false;
    if (!(this.uld_1 === tmp0_other_with_cast.uld_1))
      return false;
    if (!equals(this.vld_1, tmp0_other_with_cast.vld_1))
      return false;
    if (!(this.wld_1 === tmp0_other_with_cast.wld_1))
      return false;
    if (!equals(this.xld_1, tmp0_other_with_cast.xld_1))
      return false;
    if (!equals(this.yld_1, tmp0_other_with_cast.yld_1))
      return false;
    return true;
  }
  static zld(seen0, name, inverseBindMatrices, joints, skeleton, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_5().rld_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.tld_1 = null;
    else
      $this.tld_1 = name;
    if (0 === (seen0 & 2))
      $this.uld_1 = -1;
    else
      $this.uld_1 = inverseBindMatrices;
    if (0 === (seen0 & 4))
      $this.vld_1 = new Int32Array(0);
    else
      $this.vld_1 = joints;
    if (0 === (seen0 & 8))
      $this.wld_1 = -1;
    else
      $this.wld_1 = skeleton;
    if (0 === (seen0 & 16))
      $this.xld_1 = null;
    else
      $this.xld_1 = extensions;
    if (0 === (seen0 & 32))
      $this.yld_1 = null;
    else
      $this.yld_1 = extras;
    return $this;
  }
}
class Animation extends GLTFProperty {
  constructor(name, channels, samplers, extensions, extras) {
    Companion_getInstance_30();
    name = name === VOID ? null : name;
    channels = channels === VOID ? emptyList() : channels;
    samplers = samplers === VOID ? emptyList() : samplers;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.vlh_1 = name;
    this.wlh_1 = channels;
    this.xlh_1 = samplers;
    this.ylh_1 = extensions;
    this.zlh_1 = extras;
  }
  toString() {
    return 'Animation(name=' + this.vlh_1 + ', channels=' + toString_0(this.wlh_1) + ', samplers=' + toString_0(this.xlh_1) + ', extensions=' + toString(this.ylh_1) + ', extras=' + toString(this.zlh_1) + ')';
  }
  hashCode() {
    var result = this.vlh_1 == null ? 0 : getStringHashCode(this.vlh_1);
    result = imul(result, 31) + hashCode(this.wlh_1) | 0;
    result = imul(result, 31) + hashCode(this.xlh_1) | 0;
    result = imul(result, 31) + (this.ylh_1 == null ? 0 : hashCode(this.ylh_1)) | 0;
    result = imul(result, 31) + (this.zlh_1 == null ? 0 : hashCode(this.zlh_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Animation))
      return false;
    var tmp0_other_with_cast = other instanceof Animation ? other : THROW_CCE();
    if (!(this.vlh_1 == tmp0_other_with_cast.vlh_1))
      return false;
    if (!equals(this.wlh_1, tmp0_other_with_cast.wlh_1))
      return false;
    if (!equals(this.xlh_1, tmp0_other_with_cast.xlh_1))
      return false;
    if (!equals(this.ylh_1, tmp0_other_with_cast.ylh_1))
      return false;
    if (!equals(this.zlh_1, tmp0_other_with_cast.zlh_1))
      return false;
    return true;
  }
  static ali(seen0, name, channels, samplers, extensions, extras, serializationConstructorMarker) {
    Companion_getInstance_30();
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_9().tlh_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.vlh_1 = null;
    else
      $this.vlh_1 = name;
    if (0 === (seen0 & 2))
      $this.wlh_1 = emptyList();
    else
      $this.wlh_1 = channels;
    if (0 === (seen0 & 4))
      $this.xlh_1 = emptyList();
    else
      $this.xlh_1 = samplers;
    if (0 === (seen0 & 8))
      $this.ylh_1 = null;
    else
      $this.ylh_1 = extensions;
    if (0 === (seen0 & 16))
      $this.zlh_1 = null;
    else
      $this.zlh_1 = extras;
    return $this;
  }
}
class Buffer_0 extends GLTFProperty {
  constructor(name, uri, byteLength, extensions, extras) {
    name = name === VOID ? null : name;
    uri = uri === VOID ? null : uri;
    byteLength = byteLength === VOID ? 0 : byteLength;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.dli_1 = name;
    this.eli_1 = uri;
    this.fli_1 = byteLength;
    this.gli_1 = extensions;
    this.hli_1 = extras;
    this.ili_1 = null;
  }
  j11() {
    var tmp0_elvis_lhs = this.ili_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var message = 'Buffer not loaded!';
      throw IllegalStateException.m5(toString_0(message));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  toString() {
    var tmp = this.dli_1;
    var tmp136_safe_receiver = this.eli_1;
    return 'Buffer(' + tmp + ', uri=' + (tmp136_safe_receiver == null ? null : substr(tmp136_safe_receiver, 0, 100)) + ', byteLength=' + this.fli_1 + ')';
  }
  hashCode() {
    var result = this.dli_1 == null ? 0 : getStringHashCode(this.dli_1);
    result = imul(result, 31) + (this.eli_1 == null ? 0 : getStringHashCode(this.eli_1)) | 0;
    result = imul(result, 31) + this.fli_1 | 0;
    result = imul(result, 31) + (this.gli_1 == null ? 0 : hashCode(this.gli_1)) | 0;
    result = imul(result, 31) + (this.hli_1 == null ? 0 : hashCode(this.hli_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Buffer_0))
      return false;
    var tmp0_other_with_cast = other instanceof Buffer_0 ? other : THROW_CCE();
    if (!(this.dli_1 == tmp0_other_with_cast.dli_1))
      return false;
    if (!(this.eli_1 == tmp0_other_with_cast.eli_1))
      return false;
    if (!(this.fli_1 === tmp0_other_with_cast.fli_1))
      return false;
    if (!equals(this.gli_1, tmp0_other_with_cast.gli_1))
      return false;
    if (!equals(this.hli_1, tmp0_other_with_cast.hli_1))
      return false;
    return true;
  }
  static jli(seen0, name, uri, byteLength, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_10().bli_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.dli_1 = null;
    else
      $this.dli_1 = name;
    if (0 === (seen0 & 2))
      $this.eli_1 = null;
    else
      $this.eli_1 = uri;
    if (0 === (seen0 & 4))
      $this.fli_1 = 0;
    else
      $this.fli_1 = byteLength;
    if (0 === (seen0 & 8))
      $this.gli_1 = null;
    else
      $this.gli_1 = extensions;
    if (0 === (seen0 & 16))
      $this.hli_1 = null;
    else
      $this.hli_1 = extras;
    $this.ili_1 = null;
    return $this;
  }
}
class BufferView extends GLTFProperty {
  constructor(name, buffer, byteOffset, byteLength, byteStride, target, extensions, extras) {
    name = name === VOID ? null : name;
    buffer = buffer === VOID ? -1 : buffer;
    byteOffset = byteOffset === VOID ? 0 : byteOffset;
    byteLength = byteLength === VOID ? 0 : byteLength;
    byteStride = byteStride === VOID ? 0 : byteStride;
    target = target === VOID ? -1 : target;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.mli_1 = name;
    this.nli_1 = buffer;
    this.oli_1 = byteOffset;
    this.pli_1 = byteLength;
    this.qli_1 = byteStride;
    this.rli_1 = target;
    this.sli_1 = extensions;
    this.tli_1 = extras;
  }
  pln(gltf) {
    return sliceWithSize(gltf.ilg_1.i1(this.nli_1).j11(), this.oli_1, this.pli_1);
  }
  toString() {
    return 'BufferView(name=' + this.mli_1 + ', buffer=' + this.nli_1 + ', byteOffset=' + this.oli_1 + ', byteLength=' + this.pli_1 + ', byteStride=' + this.qli_1 + ', target=' + this.rli_1 + ', extensions=' + toString(this.sli_1) + ', extras=' + toString(this.tli_1) + ')';
  }
  hashCode() {
    var result = this.mli_1 == null ? 0 : getStringHashCode(this.mli_1);
    result = imul(result, 31) + this.nli_1 | 0;
    result = imul(result, 31) + this.oli_1 | 0;
    result = imul(result, 31) + this.pli_1 | 0;
    result = imul(result, 31) + this.qli_1 | 0;
    result = imul(result, 31) + this.rli_1 | 0;
    result = imul(result, 31) + (this.sli_1 == null ? 0 : hashCode(this.sli_1)) | 0;
    result = imul(result, 31) + (this.tli_1 == null ? 0 : hashCode(this.tli_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof BufferView))
      return false;
    var tmp0_other_with_cast = other instanceof BufferView ? other : THROW_CCE();
    if (!(this.mli_1 == tmp0_other_with_cast.mli_1))
      return false;
    if (!(this.nli_1 === tmp0_other_with_cast.nli_1))
      return false;
    if (!(this.oli_1 === tmp0_other_with_cast.oli_1))
      return false;
    if (!(this.pli_1 === tmp0_other_with_cast.pli_1))
      return false;
    if (!(this.qli_1 === tmp0_other_with_cast.qli_1))
      return false;
    if (!(this.rli_1 === tmp0_other_with_cast.rli_1))
      return false;
    if (!equals(this.sli_1, tmp0_other_with_cast.sli_1))
      return false;
    if (!equals(this.tli_1, tmp0_other_with_cast.tli_1))
      return false;
    return true;
  }
  static uli(seen0, name, buffer, byteOffset, byteLength, byteStride, target, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_11().kli_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.mli_1 = null;
    else
      $this.mli_1 = name;
    if (0 === (seen0 & 2))
      $this.nli_1 = -1;
    else
      $this.nli_1 = buffer;
    if (0 === (seen0 & 4))
      $this.oli_1 = 0;
    else
      $this.oli_1 = byteOffset;
    if (0 === (seen0 & 8))
      $this.pli_1 = 0;
    else
      $this.pli_1 = byteLength;
    if (0 === (seen0 & 16))
      $this.qli_1 = 0;
    else
      $this.qli_1 = byteStride;
    if (0 === (seen0 & 32))
      $this.rli_1 = -1;
    else
      $this.rli_1 = target;
    if (0 === (seen0 & 64))
      $this.sli_1 = null;
    else
      $this.sli_1 = extensions;
    if (0 === (seen0 & 128))
      $this.tli_1 = null;
    else
      $this.tli_1 = extras;
    return $this;
  }
}
class AccessorType extends Enum {
  constructor(name, ordinal, ncomponent) {
    super(name, ordinal);
    this.uln_1 = ncomponent;
  }
}
class Accessor extends GLTFProperty {
  constructor(name, bufferView, byteOffset, componentType, normalized, count, min, max, type, sparse, extensions, extras) {
    Companion_getInstance_34();
    name = name === VOID ? null : name;
    bufferView = bufferView === VOID ? 0 : bufferView;
    byteOffset = byteOffset === VOID ? 0 : byteOffset;
    componentType = componentType === VOID ? 0 : componentType;
    normalized = normalized === VOID ? false : normalized;
    count = count === VOID ? 1 : count;
    min = min === VOID ? new Float32Array(0) : min;
    max = max === VOID ? new Float32Array(0) : max;
    type = type === VOID ? AccessorType_SCALAR_getInstance() : type;
    sparse = sparse === VOID ? null : sparse;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.tlg_1 = name;
    this.ulg_1 = bufferView;
    this.vlg_1 = byteOffset;
    this.wlg_1 = componentType;
    this.xlg_1 = normalized;
    this.ylg_1 = count;
    this.zlg_1 = min;
    this.alh_1 = max;
    this.blh_1 = type;
    this.clh_1 = sparse;
    this.dlh_1 = extensions;
    this.elh_1 = extras;
    if (!(this.clh_1 == null)) {
      // Inline function 'kotlin.TODO' call
      var reason = 'Unimplemented SPARSE: ' + toString(this.clh_1);
      throw NotImplementedError.oe('An operation is not implemented: ' + reason);
    }
    this.flh_1 = null;
    this.glh_1 = this.xln(Companion_instance_0, this.vln(), this.wln(), this.blh_1);
  }
  vln() {
    var tmp;
    switch (this.wlg_1) {
      case 5120:
        tmp = VarKind_TBYTE_getInstance();
        break;
      case 5121:
        tmp = VarKind_TUNSIGNED_BYTE_getInstance();
        break;
      case 5122:
        tmp = VarKind_TSHORT_getInstance();
        break;
      case 5123:
        tmp = VarKind_TUNSIGNED_SHORT_getInstance();
        break;
      case 5124:
        tmp = VarKind_TINT_getInstance();
        break;
      case 5125:
        tmp = VarKind_TINT_getInstance();
        break;
      case 5126:
        tmp = VarKind_TFLOAT_getInstance();
        break;
      default:
        var reason = 'Unsupported componentType=' + this.wlg_1;
        throw NotImplementedError.oe('An operation is not implemented: ' + reason);
    }
    return tmp;
  }
  wln() {
    return this.blh_1.uln_1;
  }
  yln() {
    return imul(this.vln().q9o_1, this.wln());
  }
  xln(_this__u8e3s4, kind, ncomponent, type) {
    var tmp;
    switch (type.v3_1) {
      case 4:
        tmp = Companion_instance_0.f9s(2);
        break;
      case 5:
        tmp = Companion_instance_0.f9s(3);
        break;
      case 6:
        tmp = Companion_instance_0.f9s(4);
        break;
      default:
        var tmp_0;
        switch (kind.v3_1) {
          case 0:
            tmp_0 = Companion_instance_0.y9r(ncomponent);
            break;
          case 1:
            tmp_0 = Companion_instance_0.z9r(ncomponent);
            break;
          case 2:
            tmp_0 = Companion_instance_0.a9s(ncomponent);
            break;
          case 3:
            tmp_0 = Companion_instance_0.b9s(ncomponent);
            break;
          case 4:
            tmp_0 = Companion_instance_0.c9s(ncomponent);
            break;
          case 5:
            tmp_0 = Companion_instance_0.d9s(ncomponent);
            break;
          case 6:
            tmp_0 = Companion_instance_0.e9s(ncomponent);
            break;
          default:
            noWhenBranchMatchedException();
            break;
        }

        tmp = tmp_0;
        break;
    }
    return tmp;
  }
  zln() {
    var tmp;
    switch (this.vln().v3_1) {
      case 0:
      case 1:
      case 2:
        tmp = Companion_getInstance_1().v8k_1;
        break;
      case 3:
      case 4:
        tmp = Companion_getInstance_1().w8k_1;
        break;
      case 5:
      case 6:
        tmp = Companion_getInstance_1().x8k_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  alo(gltf) {
    return gltf.jlg_1.i1(this.ulg_1);
  }
  hlh(gltf) {
    var allBuffer = this.alo(gltf).pln(gltf);
    return this.ylg_1 < 0 ? sliceBuffer(allBuffer, this.vlg_1) : sliceWithSize(allBuffer, this.vlg_1, imul(this.ylg_1, this.yln()));
  }
  blo(gltf) {
    return GLTF2AccessorVector.ilh(this, this.hlh(gltf));
  }
  toString() {
    return 'Accessor(name=' + this.tlg_1 + ', bufferView=' + this.ulg_1 + ', byteOffset=' + this.vlg_1 + ', componentType=' + this.wlg_1 + ', normalized=' + this.xlg_1 + ', count=' + this.ylg_1 + ', min=' + toString_0(this.zlg_1) + ', max=' + toString_0(this.alh_1) + ', type=' + this.blh_1.toString() + ', sparse=' + toString(this.clh_1) + ', extensions=' + toString(this.dlh_1) + ', extras=' + toString(this.elh_1) + ')';
  }
  hashCode() {
    var result = this.tlg_1 == null ? 0 : getStringHashCode(this.tlg_1);
    result = imul(result, 31) + this.ulg_1 | 0;
    result = imul(result, 31) + this.vlg_1 | 0;
    result = imul(result, 31) + this.wlg_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.xlg_1) | 0;
    result = imul(result, 31) + this.ylg_1 | 0;
    result = imul(result, 31) + hashCode(this.zlg_1) | 0;
    result = imul(result, 31) + hashCode(this.alh_1) | 0;
    result = imul(result, 31) + this.blh_1.hashCode() | 0;
    result = imul(result, 31) + (this.clh_1 == null ? 0 : hashCode(this.clh_1)) | 0;
    result = imul(result, 31) + (this.dlh_1 == null ? 0 : hashCode(this.dlh_1)) | 0;
    result = imul(result, 31) + (this.elh_1 == null ? 0 : hashCode(this.elh_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Accessor))
      return false;
    var tmp0_other_with_cast = other instanceof Accessor ? other : THROW_CCE();
    if (!(this.tlg_1 == tmp0_other_with_cast.tlg_1))
      return false;
    if (!(this.ulg_1 === tmp0_other_with_cast.ulg_1))
      return false;
    if (!(this.vlg_1 === tmp0_other_with_cast.vlg_1))
      return false;
    if (!(this.wlg_1 === tmp0_other_with_cast.wlg_1))
      return false;
    if (!(this.xlg_1 === tmp0_other_with_cast.xlg_1))
      return false;
    if (!(this.ylg_1 === tmp0_other_with_cast.ylg_1))
      return false;
    if (!equals(this.zlg_1, tmp0_other_with_cast.zlg_1))
      return false;
    if (!equals(this.alh_1, tmp0_other_with_cast.alh_1))
      return false;
    if (!this.blh_1.equals(tmp0_other_with_cast.blh_1))
      return false;
    if (!equals(this.clh_1, tmp0_other_with_cast.clh_1))
      return false;
    if (!equals(this.dlh_1, tmp0_other_with_cast.dlh_1))
      return false;
    if (!equals(this.elh_1, tmp0_other_with_cast.elh_1))
      return false;
    return true;
  }
  static yli(seen0, name, bufferView, byteOffset, componentType, normalized, count, min, max, type, sparse, extensions, extras, attachDebugName, varType, serializationConstructorMarker) {
    Companion_getInstance_34();
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_12().xli_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.tlg_1 = null;
    else
      $this.tlg_1 = name;
    if (0 === (seen0 & 2))
      $this.ulg_1 = 0;
    else
      $this.ulg_1 = bufferView;
    if (0 === (seen0 & 4))
      $this.vlg_1 = 0;
    else
      $this.vlg_1 = byteOffset;
    if (0 === (seen0 & 8))
      $this.wlg_1 = 0;
    else
      $this.wlg_1 = componentType;
    if (0 === (seen0 & 16))
      $this.xlg_1 = false;
    else
      $this.xlg_1 = normalized;
    if (0 === (seen0 & 32))
      $this.ylg_1 = 1;
    else
      $this.ylg_1 = count;
    if (0 === (seen0 & 64))
      $this.zlg_1 = new Float32Array(0);
    else
      $this.zlg_1 = min;
    if (0 === (seen0 & 128))
      $this.alh_1 = new Float32Array(0);
    else
      $this.alh_1 = max;
    if (0 === (seen0 & 256))
      $this.blh_1 = AccessorType_SCALAR_getInstance();
    else
      $this.blh_1 = type;
    if (0 === (seen0 & 512))
      $this.clh_1 = null;
    else
      $this.clh_1 = sparse;
    if (0 === (seen0 & 1024))
      $this.dlh_1 = null;
    else
      $this.dlh_1 = extensions;
    if (0 === (seen0 & 2048))
      $this.elh_1 = null;
    else
      $this.elh_1 = extras;
    if (!($this.clh_1 == null)) {
      // Inline function 'kotlin.TODO' call
      var reason = 'Unimplemented SPARSE: ' + toString($this.clh_1);
      throw NotImplementedError.oe('An operation is not implemented: ' + reason);
    }
    if (0 === (seen0 & 4096))
      $this.flh_1 = null;
    else
      $this.flh_1 = attachDebugName;
    if (0 === (seen0 & 8192))
      $this.glh_1 = $this.xln(Companion_instance_0, $this.vln(), $this.wln(), $this.blh_1);
    else
      $this.glh_1 = varType;
    return $this;
  }
}
class Material extends GLTFProperty {
  constructor(name, alphaMode, alphaCutoff, doubleSided, emissiveFactor, emissiveTexture, pbrMetallicRoughness, normalTexture, occlusionTexture, extensions, extras) {
    name = name === VOID ? null : name;
    alphaMode = alphaMode === VOID ? null : alphaMode;
    alphaCutoff = alphaCutoff === VOID ? 0.5 : alphaCutoff;
    doubleSided = doubleSided === VOID ? true : doubleSided;
    emissiveFactor = emissiveFactor === VOID ? null : emissiveFactor;
    emissiveTexture = emissiveTexture === VOID ? null : emissiveTexture;
    pbrMetallicRoughness = pbrMetallicRoughness === VOID ? new PBRMetallicRoughness() : pbrMetallicRoughness;
    normalTexture = normalTexture === VOID ? null : normalTexture;
    occlusionTexture = occlusionTexture === VOID ? null : occlusionTexture;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.fll_1 = name;
    this.gll_1 = alphaMode;
    this.hll_1 = alphaCutoff;
    this.ill_1 = doubleSided;
    this.jll_1 = emissiveFactor;
    this.kll_1 = emissiveTexture;
    this.lll_1 = pbrMetallicRoughness;
    this.mll_1 = normalTexture;
    this.nll_1 = occlusionTexture;
    this.oll_1 = extensions;
    this.pll_1 = extras;
  }
  toString() {
    return 'Material(name=' + this.fll_1 + ', alphaMode=' + this.gll_1 + ', alphaCutoff=' + this.hll_1 + ', doubleSided=' + this.ill_1 + ', emissiveFactor=' + toString_0(this.jll_1) + ', emissiveTexture=' + toString(this.kll_1) + ', pbrMetallicRoughness=' + this.lll_1.toString() + ', normalTexture=' + toString(this.mll_1) + ', occlusionTexture=' + toString(this.nll_1) + ', extensions=' + toString(this.oll_1) + ', extras=' + toString(this.pll_1) + ')';
  }
  hashCode() {
    var result = this.fll_1 == null ? 0 : getStringHashCode(this.fll_1);
    result = imul(result, 31) + (this.gll_1 == null ? 0 : getStringHashCode(this.gll_1)) | 0;
    result = imul(result, 31) + getNumberHashCode(this.hll_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.ill_1) | 0;
    result = imul(result, 31) + (this.jll_1 == null ? 0 : hashCode(this.jll_1)) | 0;
    result = imul(result, 31) + (this.kll_1 == null ? 0 : this.kll_1.hashCode()) | 0;
    result = imul(result, 31) + this.lll_1.hashCode() | 0;
    result = imul(result, 31) + (this.mll_1 == null ? 0 : this.mll_1.hashCode()) | 0;
    result = imul(result, 31) + (this.nll_1 == null ? 0 : this.nll_1.hashCode()) | 0;
    result = imul(result, 31) + (this.oll_1 == null ? 0 : hashCode(this.oll_1)) | 0;
    result = imul(result, 31) + (this.pll_1 == null ? 0 : hashCode(this.pll_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Material))
      return false;
    var tmp0_other_with_cast = other instanceof Material ? other : THROW_CCE();
    if (!(this.fll_1 == tmp0_other_with_cast.fll_1))
      return false;
    if (!(this.gll_1 == tmp0_other_with_cast.gll_1))
      return false;
    if (!equals(this.hll_1, tmp0_other_with_cast.hll_1))
      return false;
    if (!(this.ill_1 === tmp0_other_with_cast.ill_1))
      return false;
    if (!equals(this.jll_1, tmp0_other_with_cast.jll_1))
      return false;
    if (!equals(this.kll_1, tmp0_other_with_cast.kll_1))
      return false;
    if (!this.lll_1.equals(tmp0_other_with_cast.lll_1))
      return false;
    if (!equals(this.mll_1, tmp0_other_with_cast.mll_1))
      return false;
    if (!equals(this.nll_1, tmp0_other_with_cast.nll_1))
      return false;
    if (!equals(this.oll_1, tmp0_other_with_cast.oll_1))
      return false;
    if (!equals(this.pll_1, tmp0_other_with_cast.pll_1))
      return false;
    return true;
  }
  static qll(seen0, name, alphaMode, alphaCutoff, doubleSided, emissiveFactor, emissiveTexture, pbrMetallicRoughness, normalTexture, occlusionTexture, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_17().dll_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.fll_1 = null;
    else
      $this.fll_1 = name;
    if (0 === (seen0 & 2))
      $this.gll_1 = null;
    else
      $this.gll_1 = alphaMode;
    if (0 === (seen0 & 4))
      $this.hll_1 = 0.5;
    else
      $this.hll_1 = alphaCutoff;
    if (0 === (seen0 & 8))
      $this.ill_1 = true;
    else
      $this.ill_1 = doubleSided;
    if (0 === (seen0 & 16))
      $this.jll_1 = null;
    else
      $this.jll_1 = emissiveFactor;
    if (0 === (seen0 & 32))
      $this.kll_1 = null;
    else
      $this.kll_1 = emissiveTexture;
    if (0 === (seen0 & 64))
      $this.lll_1 = new PBRMetallicRoughness();
    else
      $this.lll_1 = pbrMetallicRoughness;
    if (0 === (seen0 & 128))
      $this.mll_1 = null;
    else
      $this.mll_1 = normalTexture;
    if (0 === (seen0 & 256))
      $this.nll_1 = null;
    else
      $this.nll_1 = occlusionTexture;
    if (0 === (seen0 & 512))
      $this.oll_1 = null;
    else
      $this.oll_1 = extensions;
    if (0 === (seen0 & 1024))
      $this.pll_1 = null;
    else
      $this.pll_1 = extras;
    return $this;
  }
}
class Texture extends GLTFProperty {
  constructor(name, sampler, source, extensions, extras) {
    name = name === VOID ? null : name;
    sampler = sampler === VOID ? -1 : sampler;
    source = source === VOID ? -1 : source;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.wlk_1 = name;
    this.xlk_1 = sampler;
    this.ylk_1 = source;
    this.zlk_1 = extensions;
    this.all_1 = extras;
  }
  bll(gltf) {
    return gltf.blg_1.i1(this.ylk_1);
  }
  toString() {
    return 'Texture(name=' + this.wlk_1 + ', sampler=' + this.xlk_1 + ', source=' + this.ylk_1 + ', extensions=' + toString(this.zlk_1) + ', extras=' + toString(this.all_1) + ')';
  }
  hashCode() {
    var result = this.wlk_1 == null ? 0 : getStringHashCode(this.wlk_1);
    result = imul(result, 31) + this.xlk_1 | 0;
    result = imul(result, 31) + this.ylk_1 | 0;
    result = imul(result, 31) + (this.zlk_1 == null ? 0 : hashCode(this.zlk_1)) | 0;
    result = imul(result, 31) + (this.all_1 == null ? 0 : hashCode(this.all_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Texture))
      return false;
    var tmp0_other_with_cast = other instanceof Texture ? other : THROW_CCE();
    if (!(this.wlk_1 == tmp0_other_with_cast.wlk_1))
      return false;
    if (!(this.xlk_1 === tmp0_other_with_cast.xlk_1))
      return false;
    if (!(this.ylk_1 === tmp0_other_with_cast.ylk_1))
      return false;
    if (!equals(this.zlk_1, tmp0_other_with_cast.zlk_1))
      return false;
    if (!equals(this.all_1, tmp0_other_with_cast.all_1))
      return false;
    return true;
  }
  static sll(seen0, name, sampler, source, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_18().rll_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.wlk_1 = null;
    else
      $this.wlk_1 = name;
    if (0 === (seen0 & 2))
      $this.xlk_1 = -1;
    else
      $this.xlk_1 = sampler;
    if (0 === (seen0 & 4))
      $this.ylk_1 = -1;
    else
      $this.ylk_1 = source;
    if (0 === (seen0 & 8))
      $this.zlk_1 = null;
    else
      $this.zlk_1 = extensions;
    if (0 === (seen0 & 16))
      $this.all_1 = null;
    else
      $this.all_1 = extras;
    return $this;
  }
}
class Image extends GLTFProperty {
  constructor(name, uri, bufferView, mimeType, extensions, extras) {
    name = name === VOID ? null : name;
    uri = uri === VOID ? null : uri;
    bufferView = bufferView === VOID ? -1 : bufferView;
    mimeType = mimeType === VOID ? null : mimeType;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.olk_1 = name;
    this.plk_1 = uri;
    this.qlk_1 = bufferView;
    this.rlk_1 = mimeType;
    this.slk_1 = extensions;
    this.tlk_1 = extras;
    this.ulk_1 = null;
  }
  toString() {
    return 'Image(name=' + this.olk_1 + ', uri=' + this.plk_1 + ', bufferView=' + this.qlk_1 + ', mimeType=' + this.rlk_1 + ', extensions=' + toString(this.slk_1) + ', extras=' + toString(this.tlk_1) + ')';
  }
  hashCode() {
    var result = this.olk_1 == null ? 0 : getStringHashCode(this.olk_1);
    result = imul(result, 31) + (this.plk_1 == null ? 0 : getStringHashCode(this.plk_1)) | 0;
    result = imul(result, 31) + this.qlk_1 | 0;
    result = imul(result, 31) + (this.rlk_1 == null ? 0 : getStringHashCode(this.rlk_1)) | 0;
    result = imul(result, 31) + (this.slk_1 == null ? 0 : hashCode(this.slk_1)) | 0;
    result = imul(result, 31) + (this.tlk_1 == null ? 0 : hashCode(this.tlk_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Image))
      return false;
    var tmp0_other_with_cast = other instanceof Image ? other : THROW_CCE();
    if (!(this.olk_1 == tmp0_other_with_cast.olk_1))
      return false;
    if (!(this.plk_1 == tmp0_other_with_cast.plk_1))
      return false;
    if (!(this.qlk_1 === tmp0_other_with_cast.qlk_1))
      return false;
    if (!(this.rlk_1 == tmp0_other_with_cast.rlk_1))
      return false;
    if (!equals(this.slk_1, tmp0_other_with_cast.slk_1))
      return false;
    if (!equals(this.tlk_1, tmp0_other_with_cast.tlk_1))
      return false;
    return true;
  }
  static ull(seen0, name, uri, bufferView, mimeType, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_19().tll_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.olk_1 = null;
    else
      $this.olk_1 = name;
    if (0 === (seen0 & 2))
      $this.plk_1 = null;
    else
      $this.plk_1 = uri;
    if (0 === (seen0 & 4))
      $this.qlk_1 = -1;
    else
      $this.qlk_1 = bufferView;
    if (0 === (seen0 & 8))
      $this.rlk_1 = null;
    else
      $this.rlk_1 = mimeType;
    if (0 === (seen0 & 16))
      $this.slk_1 = null;
    else
      $this.slk_1 = extensions;
    if (0 === (seen0 & 32))
      $this.tlk_1 = null;
    else
      $this.tlk_1 = extras;
    $this.ulk_1 = null;
    return $this;
  }
}
class Sampler_1 extends GLTFProperty {
  constructor(name, magFilter, minFilter, wrapS, wrapT, extensions, extras) {
    name = name === VOID ? null : name;
    magFilter = magFilter === VOID ? -1 : magFilter;
    minFilter = minFilter === VOID ? -1 : minFilter;
    wrapS = wrapS === VOID ? -1 : wrapS;
    wrapT = wrapT === VOID ? -1 : wrapT;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.xll_1 = name;
    this.yll_1 = magFilter;
    this.zll_1 = minFilter;
    this.alm_1 = wrapS;
    this.blm_1 = wrapT;
    this.clm_1 = extensions;
    this.dlm_1 = extras;
  }
  toString() {
    return 'Sampler(name=' + this.xll_1 + ', magFilter=' + this.yll_1 + ', minFilter=' + this.zll_1 + ', wrapS=' + this.alm_1 + ', wrapT=' + this.blm_1 + ', extensions=' + toString(this.clm_1) + ', extras=' + toString(this.dlm_1) + ')';
  }
  hashCode() {
    var result = this.xll_1 == null ? 0 : getStringHashCode(this.xll_1);
    result = imul(result, 31) + this.yll_1 | 0;
    result = imul(result, 31) + this.zll_1 | 0;
    result = imul(result, 31) + this.alm_1 | 0;
    result = imul(result, 31) + this.blm_1 | 0;
    result = imul(result, 31) + (this.clm_1 == null ? 0 : hashCode(this.clm_1)) | 0;
    result = imul(result, 31) + (this.dlm_1 == null ? 0 : hashCode(this.dlm_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Sampler_1))
      return false;
    var tmp0_other_with_cast = other instanceof Sampler_1 ? other : THROW_CCE();
    if (!(this.xll_1 == tmp0_other_with_cast.xll_1))
      return false;
    if (!(this.yll_1 === tmp0_other_with_cast.yll_1))
      return false;
    if (!(this.zll_1 === tmp0_other_with_cast.zll_1))
      return false;
    if (!(this.alm_1 === tmp0_other_with_cast.alm_1))
      return false;
    if (!(this.blm_1 === tmp0_other_with_cast.blm_1))
      return false;
    if (!equals(this.clm_1, tmp0_other_with_cast.clm_1))
      return false;
    if (!equals(this.dlm_1, tmp0_other_with_cast.dlm_1))
      return false;
    return true;
  }
  static elm(seen0, name, magFilter, minFilter, wrapS, wrapT, extensions, extras, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_20().vll_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.xll_1 = null;
    else
      $this.xll_1 = name;
    if (0 === (seen0 & 2))
      $this.yll_1 = -1;
    else
      $this.yll_1 = magFilter;
    if (0 === (seen0 & 4))
      $this.zll_1 = -1;
    else
      $this.zll_1 = minFilter;
    if (0 === (seen0 & 8))
      $this.alm_1 = -1;
    else
      $this.alm_1 = wrapS;
    if (0 === (seen0 & 16))
      $this.blm_1 = -1;
    else
      $this.blm_1 = wrapT;
    if (0 === (seen0 & 32))
      $this.clm_1 = null;
    else
      $this.clm_1 = extensions;
    if (0 === (seen0 & 64))
      $this.dlm_1 = null;
    else
      $this.dlm_1 = extras;
    return $this;
  }
}
class Camera extends GLTFProperty {
  toString() {
    return 'Camera(name=' + this.cln_1 + ', type=' + this.dln_1 + ', perspective=' + toString(this.eln_1) + ', orthographic=' + toString(this.fln_1) + ', extensions=' + toString(this.gln_1) + ', extras=' + toString(this.hln_1) + ')';
  }
  hashCode() {
    var result = this.cln_1 == null ? 0 : getStringHashCode(this.cln_1);
    result = imul(result, 31) + getStringHashCode(this.dln_1) | 0;
    result = imul(result, 31) + (this.eln_1 == null ? 0 : this.eln_1.hashCode()) | 0;
    result = imul(result, 31) + (this.fln_1 == null ? 0 : this.fln_1.hashCode()) | 0;
    result = imul(result, 31) + (this.gln_1 == null ? 0 : hashCode(this.gln_1)) | 0;
    result = imul(result, 31) + (this.hln_1 == null ? 0 : hashCode(this.hln_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Camera))
      return false;
    var tmp0_other_with_cast = other instanceof Camera ? other : THROW_CCE();
    if (!(this.cln_1 == tmp0_other_with_cast.cln_1))
      return false;
    if (!(this.dln_1 === tmp0_other_with_cast.dln_1))
      return false;
    if (!equals(this.eln_1, tmp0_other_with_cast.eln_1))
      return false;
    if (!equals(this.fln_1, tmp0_other_with_cast.fln_1))
      return false;
    if (!equals(this.gln_1, tmp0_other_with_cast.gln_1))
      return false;
    if (!equals(this.hln_1, tmp0_other_with_cast.hln_1))
      return false;
    return true;
  }
  static iln(seen0, name, type, perspective, orthographic, extensions, extras, serializationConstructorMarker) {
    if (!(2 === (2 & seen0))) {
      throwMissingFieldException(seen0, 2, $serializer_getInstance_23().aln_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.cln_1 = null;
    else
      $this.cln_1 = name;
    $this.dln_1 = type;
    if (0 === (seen0 & 4))
      $this.eln_1 = null;
    else
      $this.eln_1 = perspective;
    if (0 === (seen0 & 8))
      $this.fln_1 = null;
    else
      $this.fln_1 = orthographic;
    if (0 === (seen0 & 16))
      $this.gln_1 = null;
    else
      $this.gln_1 = extensions;
    if (0 === (seen0 & 32))
      $this.hln_1 = null;
    else
      $this.hln_1 = extras;
    return $this;
  }
}
class Companion_31 {
  constructor() {
    Companion_instance_36 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.jln_1 = [null, null, new ArrayListSerializer(StringSerializer_getInstance()), new ArrayListSerializer(StringSerializer_getInstance()), null, new ArrayListSerializer($serializer_getInstance_19()), new ArrayListSerializer($serializer_getInstance_18()), new ArrayListSerializer($serializer_getInstance_0()), new ArrayListSerializer($serializer_getInstance_1()), new ArrayListSerializer($serializer_getInstance_2()), new ArrayListSerializer($serializer_getInstance_5()), new ArrayListSerializer($serializer_getInstance_9()), new ArrayListSerializer($serializer_getInstance_10()), new ArrayListSerializer($serializer_getInstance_11()), new ArrayListSerializer($serializer_getInstance_12()), new ArrayListSerializer($serializer_getInstance_17()), new ArrayListSerializer($serializer_getInstance_20()), new ArrayListSerializer($serializer_getInstance_23()), null, null];
    this.kln_1 = Buffer.t10(0);
    this.lln_1 = Companion_getInstance_13().ez('GLTF2');
  }
  clo(file, options, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readGLB__9p6tt9(this, file, options, $completion), $completion);
  }
  mln(data, file, options, $completion) {
    var s = openSync(data);
    if (!(readString(s, 4) === 'glTF')) {
      // Inline function 'kotlin.error' call
      var message = 'Not a glTF binary';
      throw IllegalStateException.m5(toString_0(message));
    }
    var version = readS32LE(s);
    if (!(version === 2)) {
      // Inline function 'kotlin.error' call
      var message_0 = 'Not a glTF version 2.0';
      throw IllegalStateException.m5(toString_0(message_0));
    }
    var fileSize = readS32LE(s);
    var json = '{}';
    var bin = null;
    while (s.q1q().g2(toLong(fileSize)) < 0) {
      var chunkSize = readS32LE(s);
      var chunkName = readStringz(s, 4);
      var chunkData = readStream(s, chunkSize);
      if (chunkName === 'JSON')
        json = toString_1(toByteArray(chunkData), Charsets_instance.ky());
      else if (chunkName === 'BIN')
        bin = toByteArray(chunkData);
      // Inline function 'korlibs.logger.Logger.trace' call
      var this_0 = this.lln_1;
      var level = Level_TRACE_getInstance();
      var tmp;
      if (this_0.u3g(level)) {
        // Inline function 'korlibs.korge3d.format.gltf2.Companion.readGLB.<anonymous>' call
        var tmp$ret$0 = 'CHUNK[' + chunkName + '] = ' + chunkSize;
        this_0.v3g(level, tmp$ret$0);
        tmp = Unit_instance;
      }
    }
    return this.dlo(json, bin, file, options, $completion);
  }
  dlo(jsonString, bin, file, options, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readGLTF__gkftvf(this, jsonString, bin, file, options, $completion), $completion);
  }
}
class $serializer_24 {
  constructor() {
    $serializer_instance_24 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge3d.format.gltf2.GLTF2', this, 20);
    tmp0_serialDesc.fjp('name', true);
    tmp0_serialDesc.fjp('asset', true);
    tmp0_serialDesc.fjp('extensionsUsed', true);
    tmp0_serialDesc.fjp('extensionsRequired', true);
    tmp0_serialDesc.fjp('scene', true);
    tmp0_serialDesc.fjp('images', true);
    tmp0_serialDesc.fjp('textures', true);
    tmp0_serialDesc.fjp('scenes', true);
    tmp0_serialDesc.fjp('nodes', true);
    tmp0_serialDesc.fjp('meshes', true);
    tmp0_serialDesc.fjp('skins', true);
    tmp0_serialDesc.fjp('animations', true);
    tmp0_serialDesc.fjp('buffers', true);
    tmp0_serialDesc.fjp('bufferViews', true);
    tmp0_serialDesc.fjp('accessors', true);
    tmp0_serialDesc.fjp('materials', true);
    tmp0_serialDesc.fjp('samplers', true);
    tmp0_serialDesc.fjp('cameras', true);
    tmp0_serialDesc.fjp('extensions', true);
    tmp0_serialDesc.fjp('extras', true);
    this.elo_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.elo_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = 0;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp16_local12 = null;
    var tmp17_local13 = null;
    var tmp18_local14 = null;
    var tmp19_local15 = null;
    var tmp20_local16 = null;
    var tmp21_local17 = null;
    var tmp22_local18 = null;
    var tmp23_local19 = null;
    var tmp25_input = decoder.pjj(tmp0_desc);
    var tmp26_cached = Companion_getInstance_47().jln_1;
    if (tmp25_input.fjk()) {
      tmp4_local0 = tmp25_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp25_input.bjk(tmp0_desc, 1, $serializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp25_input.bjk(tmp0_desc, 2, tmp26_cached[2], tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp25_input.bjk(tmp0_desc, 3, tmp26_cached[3], tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp25_input.ujj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp25_input.bjk(tmp0_desc, 5, tmp26_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp25_input.bjk(tmp0_desc, 6, tmp26_cached[6], tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp25_input.bjk(tmp0_desc, 7, tmp26_cached[7], tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp25_input.bjk(tmp0_desc, 8, tmp26_cached[8], tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp25_input.bjk(tmp0_desc, 9, tmp26_cached[9], tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp25_input.bjk(tmp0_desc, 10, tmp26_cached[10], tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp25_input.bjk(tmp0_desc, 11, tmp26_cached[11], tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp25_input.bjk(tmp0_desc, 12, tmp26_cached[12], tmp16_local12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp25_input.bjk(tmp0_desc, 13, tmp26_cached[13], tmp17_local13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp25_input.bjk(tmp0_desc, 14, tmp26_cached[14], tmp18_local14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp25_input.bjk(tmp0_desc, 15, tmp26_cached[15], tmp19_local15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp25_input.bjk(tmp0_desc, 16, tmp26_cached[16], tmp20_local16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp25_input.bjk(tmp0_desc, 17, tmp26_cached[17], tmp21_local17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp25_input.djk(tmp0_desc, 18, JsonElementSerializer_getInstance(), tmp22_local18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp25_input.djk(tmp0_desc, 19, JsonElementSerializer_getInstance(), tmp23_local19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp25_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp25_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp25_input.bjk(tmp0_desc, 1, $serializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp25_input.bjk(tmp0_desc, 2, tmp26_cached[2], tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp25_input.bjk(tmp0_desc, 3, tmp26_cached[3], tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp25_input.ujj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp25_input.bjk(tmp0_desc, 5, tmp26_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp25_input.bjk(tmp0_desc, 6, tmp26_cached[6], tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp25_input.bjk(tmp0_desc, 7, tmp26_cached[7], tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp25_input.bjk(tmp0_desc, 8, tmp26_cached[8], tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp25_input.bjk(tmp0_desc, 9, tmp26_cached[9], tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp25_input.bjk(tmp0_desc, 10, tmp26_cached[10], tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp25_input.bjk(tmp0_desc, 11, tmp26_cached[11], tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp25_input.bjk(tmp0_desc, 12, tmp26_cached[12], tmp16_local12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp25_input.bjk(tmp0_desc, 13, tmp26_cached[13], tmp17_local13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp25_input.bjk(tmp0_desc, 14, tmp26_cached[14], tmp18_local14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp25_input.bjk(tmp0_desc, 15, tmp26_cached[15], tmp19_local15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp25_input.bjk(tmp0_desc, 16, tmp26_cached[16], tmp20_local16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp25_input.bjk(tmp0_desc, 17, tmp26_cached[17], tmp21_local17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp25_input.djk(tmp0_desc, 18, JsonElementSerializer_getInstance(), tmp22_local18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp25_input.djk(tmp0_desc, 19, JsonElementSerializer_getInstance(), tmp23_local19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp25_input.qjj(tmp0_desc);
    return GLTF2.flo(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, null);
  }
  ujf() {
    return this.elo_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_47().jln_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), $serializer_getInstance(), tmp0_cached[2], tmp0_cached[3], IntSerializer_getInstance(), tmp0_cached[5], tmp0_cached[6], tmp0_cached[7], tmp0_cached[8], tmp0_cached[9], tmp0_cached[10], tmp0_cached[11], tmp0_cached[12], tmp0_cached[13], tmp0_cached[14], tmp0_cached[15], tmp0_cached[16], tmp0_cached[17], get_nullable(JsonElementSerializer_getInstance()), get_nullable(JsonElementSerializer_getInstance())];
  }
}
class GLTF2Holder {}
function get_childrenNodes(_this__u8e3s4) {
  var tmp0_elvis_lhs = childrenNodes(_this__u8e3s4, this.glo());
  return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
}
function get_childrenNodes_0(_this__u8e3s4) {
  var tmp0_elvis_lhs = childrenNodes_0(_this__u8e3s4, this.glo());
  return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
}
class GLTF2 extends GLTFProperty {
  constructor(name, asset, extensionsUsed, extensionsRequired, scene, images, textures, scenes, nodes, meshes, skins, animations, buffers, bufferViews, accessors, materials, samplers, cameras, extensions, extras) {
    Companion_getInstance_47();
    name = name === VOID ? null : name;
    asset = asset === VOID ? new Asset() : asset;
    extensionsUsed = extensionsUsed === VOID ? emptyList() : extensionsUsed;
    extensionsRequired = extensionsRequired === VOID ? emptyList() : extensionsRequired;
    scene = scene === VOID ? -1 : scene;
    images = images === VOID ? emptyList() : images;
    textures = textures === VOID ? emptyList() : textures;
    scenes = scenes === VOID ? emptyList() : scenes;
    nodes = nodes === VOID ? emptyList() : nodes;
    meshes = meshes === VOID ? emptyList() : meshes;
    skins = skins === VOID ? emptyList() : skins;
    animations = animations === VOID ? emptyList() : animations;
    buffers = buffers === VOID ? emptyList() : buffers;
    bufferViews = bufferViews === VOID ? emptyList() : bufferViews;
    accessors = accessors === VOID ? emptyList() : accessors;
    materials = materials === VOID ? emptyList() : materials;
    samplers = samplers === VOID ? emptyList() : samplers;
    cameras = cameras === VOID ? emptyList() : cameras;
    extensions = extensions === VOID ? null : extensions;
    extras = extras === VOID ? null : extras;
    super();
    this.wlf_1 = name;
    this.xlf_1 = asset;
    this.ylf_1 = extensionsUsed;
    this.zlf_1 = extensionsRequired;
    this.alg_1 = scene;
    this.blg_1 = images;
    this.clg_1 = textures;
    this.dlg_1 = scenes;
    this.elg_1 = nodes;
    this.flg_1 = meshes;
    this.glg_1 = skins;
    this.hlg_1 = animations;
    this.ilg_1 = buffers;
    this.jlg_1 = bufferViews;
    this.klg_1 = accessors;
    this.llg_1 = materials;
    this.mlg_1 = samplers;
    this.nlg_1 = cameras;
    this.olg_1 = extensions;
    this.plg_1 = extras;
    var tmp = this;
    tmp.qlg_1 = lazy(GLTF2$materials3D$delegate$lambda(this));
  }
  glo() {
    return this;
  }
  hlo() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.qlg_1;
    materials3D$factory();
    return this_0.b2();
  }
  oln(file, uri) {
    if (startsWith(uri, 'data:')) {
      var dataPart = substringBefore(uri, _Char___init__impl__6a9atx(44), '');
      var content = substringAfter(uri, _Char___init__impl__6a9atx(44));
      return asMemoryVfsFile(fromBase64(content), 'buffer.bin');
    }
    var tmp133_safe_receiver = file == null ? null : file.w15();
    return tmp133_safe_receiver == null ? null : tmp133_safe_receiver.bj(Companion_getInstance_14().v3l(uri));
  }
  toString() {
    return 'GLTF2(name=' + this.wlf_1 + ', asset=' + this.xlf_1.toString() + ', extensionsUsed=' + toString_0(this.ylf_1) + ', extensionsRequired=' + toString_0(this.zlf_1) + ', scene=' + this.alg_1 + ', images=' + toString_0(this.blg_1) + ', textures=' + toString_0(this.clg_1) + ', scenes=' + toString_0(this.dlg_1) + ', nodes=' + toString_0(this.elg_1) + ', meshes=' + toString_0(this.flg_1) + ', skins=' + toString_0(this.glg_1) + ', animations=' + toString_0(this.hlg_1) + ', buffers=' + toString_0(this.ilg_1) + ', bufferViews=' + toString_0(this.jlg_1) + ', accessors=' + toString_0(this.klg_1) + ', materials=' + toString_0(this.llg_1) + ', samplers=' + toString_0(this.mlg_1) + ', cameras=' + toString_0(this.nlg_1) + ', extensions=' + toString(this.olg_1) + ', extras=' + toString(this.plg_1) + ')';
  }
  hashCode() {
    var result = this.wlf_1 == null ? 0 : getStringHashCode(this.wlf_1);
    result = imul(result, 31) + this.xlf_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.ylf_1) | 0;
    result = imul(result, 31) + hashCode(this.zlf_1) | 0;
    result = imul(result, 31) + this.alg_1 | 0;
    result = imul(result, 31) + hashCode(this.blg_1) | 0;
    result = imul(result, 31) + hashCode(this.clg_1) | 0;
    result = imul(result, 31) + hashCode(this.dlg_1) | 0;
    result = imul(result, 31) + hashCode(this.elg_1) | 0;
    result = imul(result, 31) + hashCode(this.flg_1) | 0;
    result = imul(result, 31) + hashCode(this.glg_1) | 0;
    result = imul(result, 31) + hashCode(this.hlg_1) | 0;
    result = imul(result, 31) + hashCode(this.ilg_1) | 0;
    result = imul(result, 31) + hashCode(this.jlg_1) | 0;
    result = imul(result, 31) + hashCode(this.klg_1) | 0;
    result = imul(result, 31) + hashCode(this.llg_1) | 0;
    result = imul(result, 31) + hashCode(this.mlg_1) | 0;
    result = imul(result, 31) + hashCode(this.nlg_1) | 0;
    result = imul(result, 31) + (this.olg_1 == null ? 0 : hashCode(this.olg_1)) | 0;
    result = imul(result, 31) + (this.plg_1 == null ? 0 : hashCode(this.plg_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof GLTF2))
      return false;
    var tmp0_other_with_cast = other instanceof GLTF2 ? other : THROW_CCE();
    if (!(this.wlf_1 == tmp0_other_with_cast.wlf_1))
      return false;
    if (!this.xlf_1.equals(tmp0_other_with_cast.xlf_1))
      return false;
    if (!equals(this.ylf_1, tmp0_other_with_cast.ylf_1))
      return false;
    if (!equals(this.zlf_1, tmp0_other_with_cast.zlf_1))
      return false;
    if (!(this.alg_1 === tmp0_other_with_cast.alg_1))
      return false;
    if (!equals(this.blg_1, tmp0_other_with_cast.blg_1))
      return false;
    if (!equals(this.clg_1, tmp0_other_with_cast.clg_1))
      return false;
    if (!equals(this.dlg_1, tmp0_other_with_cast.dlg_1))
      return false;
    if (!equals(this.elg_1, tmp0_other_with_cast.elg_1))
      return false;
    if (!equals(this.flg_1, tmp0_other_with_cast.flg_1))
      return false;
    if (!equals(this.glg_1, tmp0_other_with_cast.glg_1))
      return false;
    if (!equals(this.hlg_1, tmp0_other_with_cast.hlg_1))
      return false;
    if (!equals(this.ilg_1, tmp0_other_with_cast.ilg_1))
      return false;
    if (!equals(this.jlg_1, tmp0_other_with_cast.jlg_1))
      return false;
    if (!equals(this.klg_1, tmp0_other_with_cast.klg_1))
      return false;
    if (!equals(this.llg_1, tmp0_other_with_cast.llg_1))
      return false;
    if (!equals(this.mlg_1, tmp0_other_with_cast.mlg_1))
      return false;
    if (!equals(this.nlg_1, tmp0_other_with_cast.nlg_1))
      return false;
    if (!equals(this.olg_1, tmp0_other_with_cast.olg_1))
      return false;
    if (!equals(this.plg_1, tmp0_other_with_cast.plg_1))
      return false;
    return true;
  }
  static flo(seen0, name, asset, extensionsUsed, extensionsRequired, scene, images, textures, scenes, nodes, meshes, skins, animations, buffers, bufferViews, accessors, materials, samplers, cameras, extensions, extras, serializationConstructorMarker) {
    Companion_getInstance_47();
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_24().elo_1);
    }
    var $this = this.ole(seen0, serializationConstructorMarker);
    if (0 === (seen0 & 1))
      $this.wlf_1 = null;
    else
      $this.wlf_1 = name;
    if (0 === (seen0 & 2))
      $this.xlf_1 = new Asset();
    else
      $this.xlf_1 = asset;
    if (0 === (seen0 & 4))
      $this.ylf_1 = emptyList();
    else
      $this.ylf_1 = extensionsUsed;
    if (0 === (seen0 & 8))
      $this.zlf_1 = emptyList();
    else
      $this.zlf_1 = extensionsRequired;
    if (0 === (seen0 & 16))
      $this.alg_1 = -1;
    else
      $this.alg_1 = scene;
    if (0 === (seen0 & 32))
      $this.blg_1 = emptyList();
    else
      $this.blg_1 = images;
    if (0 === (seen0 & 64))
      $this.clg_1 = emptyList();
    else
      $this.clg_1 = textures;
    if (0 === (seen0 & 128))
      $this.dlg_1 = emptyList();
    else
      $this.dlg_1 = scenes;
    if (0 === (seen0 & 256))
      $this.elg_1 = emptyList();
    else
      $this.elg_1 = nodes;
    if (0 === (seen0 & 512))
      $this.flg_1 = emptyList();
    else
      $this.flg_1 = meshes;
    if (0 === (seen0 & 1024))
      $this.glg_1 = emptyList();
    else
      $this.glg_1 = skins;
    if (0 === (seen0 & 2048))
      $this.hlg_1 = emptyList();
    else
      $this.hlg_1 = animations;
    if (0 === (seen0 & 4096))
      $this.ilg_1 = emptyList();
    else
      $this.ilg_1 = buffers;
    if (0 === (seen0 & 8192))
      $this.jlg_1 = emptyList();
    else
      $this.jlg_1 = bufferViews;
    if (0 === (seen0 & 16384))
      $this.klg_1 = emptyList();
    else
      $this.klg_1 = accessors;
    if (0 === (seen0 & 32768))
      $this.llg_1 = emptyList();
    else
      $this.llg_1 = materials;
    if (0 === (seen0 & 65536))
      $this.mlg_1 = emptyList();
    else
      $this.mlg_1 = samplers;
    if (0 === (seen0 & 131072))
      $this.nlg_1 = emptyList();
    else
      $this.nlg_1 = cameras;
    if (0 === (seen0 & 262144))
      $this.olg_1 = null;
    else
      $this.olg_1 = extensions;
    if (0 === (seen0 & 524288))
      $this.plg_1 = null;
    else
      $this.plg_1 = extras;
    var tmp = $this;
    tmp.qlg_1 = lazy(GLTF2$_init_$lambda_rrepww($this));
    return $this;
  }
}
class Companion_32 {
  constructor() {
    Companion_instance_37 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.ilo_1 = lazy_0(tmp_0, GLTFProperty$Companion$_anonymous__zfetnn);
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_2(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class GLTF2AccessorVector {
  static ilh(accessor, buffer) {
    var $this = createThis(this);
    $this.rlf_1 = accessor;
    $this.slf_1 = buffer;
    $this.tlf_1 = $this.rlf_1.yln();
    return $this;
  }
  static nlh(accessor, size) {
    size = size === VOID ? 1 : size;
    return this.ilh(accessor, Buffer.t10(imul(accessor.yln(), size)));
  }
  h3w() {
    return this.rlf_1.wln();
  }
  j1() {
    return this.slf_1.y10() / this.tlf_1 | 0;
  }
  llo() {
    return this.slf_1.y10() / this.rlf_1.vln().q9o_1 | 0;
  }
  mlo() {
    // Inline function 'korlibs.math.geom.Companion.func' call
    Companion_getInstance_8();
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toVector3.<anonymous>' call
    var tmp = 0 < this.llo() ? this.nlo(0) : 0.0;
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toVector3.<anonymous>' call
    var tmp_0 = 1 < this.llo() ? this.nlo(1) : 0.0;
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toVector3.<anonymous>' call
    var tmp$ret$2 = 2 < this.llo() ? this.nlo(2) : 0.0;
    return Vector3F.n2x(tmp, tmp_0, tmp$ret$2);
  }
  olo() {
    // Inline function 'korlibs.math.geom.Companion.func' call
    Companion_getInstance_11();
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toVector4.<anonymous>' call
    var tmp = 0 < this.llo() ? this.nlo(0) : 0.0;
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toVector4.<anonymous>' call
    var tmp_0 = 1 < this.llo() ? this.nlo(1) : 0.0;
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toVector4.<anonymous>' call
    var tmp_1 = 2 < this.llo() ? this.nlo(2) : 0.0;
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toVector4.<anonymous>' call
    var tmp$ret$3 = 3 < this.llo() ? this.nlo(3) : 0.0;
    return new Vector4F(tmp, tmp_0, tmp_1, tmp$ret$3);
  }
  nlo(index) {
    try {
      var tmp;
      switch (this.rlf_1.vln().v3_1) {
        case 1:
          tmp = Int8Buffer__get_impl_amhyyo(get_i8(this.slf_1), index);
          break;
        case 0:
        case 2:
          tmp = Int8Buffer__get_impl_amhyyo(get_i8(this.slf_1), index);
          break;
        case 3:
          tmp = Int16Buffer__get_impl_nt0z85(get_i16(this.slf_1), index);
          break;
        case 4:
          tmp = Uint16Buffer__get_impl_6qi0dm(get_u16(this.slf_1), index);
          break;
        case 5:
          tmp = Int32Buffer__get_impl_48ysyj(get_i32(this.slf_1), index);
          break;
        case 6:
          tmp = Float32Buffer__get_impl_659lhu(get_f32(this.slf_1), index);
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      var value = tmp;
      var tmp_0;
      if (this.rlf_1.xlg_1) {
        var tmp_1;
        switch (this.rlf_1.vln().v3_1) {
          case 1:
            // Inline function 'kotlin.math.max' call

            var a = value / 127.0;
            var b = -1.0;
            tmp_1 = Math.max(a, b);
            break;
          case 0:
          case 2:
            tmp_1 = value / 255.0;
            break;
          case 3:
            // Inline function 'kotlin.math.max' call

            var a_0 = value / 32767.0;
            var b_0 = -1.0;
            tmp_1 = Math.max(a_0, b_0);
            break;
          case 4:
            tmp_1 = value / 65535.0;
            break;
          case 5:
            tmp_1 = value;
            break;
          case 6:
            tmp_1 = value;
            break;
          default:
            noWhenBranchMatchedException();
            break;
        }
        tmp_0 = tmp_1;
      } else {
        tmp_0 = value;
      }
      return tmp_0;
    } catch ($p) {
      if ($p instanceof IndexOutOfBoundsException) {
        var e = $p;
        println('!! ERROR accessing ' + index + ' of buffer.sizeInBytes=' + this.slf_1.y10() + ', dims=' + this.h3w() + ', bytesPerEntry=' + this.tlf_1 + ', size=' + this.j1() + ', accessor=' + this.rlf_1.toString());
        throw e;
      } else {
        throw $p;
      }
    }
  }
  plo(index, value) {
    switch (this.rlf_1.vln().v3_1) {
      case 1:
        Int8Buffer__set_impl_jwkk3w(get_i8(this.slf_1), index, toByte(numberToInt(round(value * 127.0))));
        break;
      case 0:
      case 2:
        Int8Buffer__set_impl_jwkk3w(get_i8(this.slf_1), index, toByte(numberToInt(round(value * 255.0))));
        break;
      case 3:
        var tmp = get_i16(this.slf_1);
        // Inline function 'kotlin.math.round' call

        var x = value * 32767.0;
        var tmp$ret$0 = round(x);
        Int16Buffer__set_impl_x33kdd(tmp, index, toShort(numberToInt(tmp$ret$0)));
        break;
      case 4:
        var tmp_0 = get_i16(this.slf_1);
        // Inline function 'kotlin.math.round' call

        var x_0 = value * 65535.0;
        var tmp$ret$1 = round(x_0);
        Int16Buffer__set_impl_x33kdd(tmp_0, index, toShort(numberToInt(tmp$ret$1)));
        break;
      case 5:
        Int32Buffer__set_impl_dj1e3r(get_i32(this.slf_1), index, numberToInt(value));
        break;
      case 6:
        Float32Buffer__set_impl_34szne(get_f32(this.slf_1), index, value);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
  }
  qlo(index) {
    return new Vector4F(this.h3w() >= 1 ? this.z2k(index, 0) : 0.0, this.h3w() >= 2 ? this.z2k(index, 1) : 0.0, this.h3w() >= 3 ? this.z2k(index, 2) : 0.0, this.h3w() >= 4 ? this.z2k(index, 3) : 0.0);
  }
  z2k(index, dim) {
    return this.nlo(imul(index, this.h3w()) + dim | 0);
  }
  a2z(index, dim, value) {
    this.plo(imul(index, this.h3w()) + dim | 0, value);
  }
  rlh(index, a, aIndex, b, bIndex, ratio, kind) {
    switch (kind.v3_1) {
      case 0:
        var inductionVariable = 0;
        var last = this.h3w();
        if (inductionVariable < last)
          do {
            var dim = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            this.a2z(index, dim, interpolate(toRatio(ratio), a.z2k(aIndex, dim), b.z2k(bIndex, dim)));
          }
           while (inductionVariable < last);
        break;
      case 1:
        if (!(this.h3w() === 4)) {
          // Inline function 'kotlin.error' call
          var message = 'Invalid dimensions ' + this.h3w() + ' for quaternion';
          throw IllegalStateException.m5(toString_0(message));
        }

        var q1 = Quaternion.j2s(a.qlo(aIndex));
        var q2 = Quaternion.j2s(b.qlo(bIndex));
        var qr = Companion_getInstance_10().g2s(q1, q2, ratio);
        this.a2z(index, 0, qr.w2r_1);
        this.a2z(index, 1, qr.x2r_1);
        this.a2z(index, 2, qr.y2r_1);
        this.a2z(index, 3, qr.z2r_1);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
  }
  toString() {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2AccessorVector.toString.<anonymous>' call
    this_0.ic('[');
    var inductionVariable = 0;
    var last = this.j1();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.ic(', ');
        }
        this_0.ic('[');
        var inductionVariable_0 = 0;
        var last_0 = this.h3w();
        if (inductionVariable_0 < last_0)
          do {
            var dim = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            if (!(dim === 0)) {
              this_0.ic(', ');
            }
            this_0.qh(this.z2k(n, dim));
          }
           while (inductionVariable_0 < last_0);
        this_0.ic(']');
      }
       while (inductionVariable < last);
    this_0.ic(']');
    return this_0.toString();
  }
  hashCode() {
    var result = this.rlf_1.hashCode();
    result = imul(result, 31) + this.slf_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof GLTF2AccessorVector))
      return false;
    var tmp0_other_with_cast = other instanceof GLTF2AccessorVector ? other : THROW_CCE();
    if (!this.rlf_1.equals(tmp0_other_with_cast.rlf_1))
      return false;
    if (!this.slf_1.equals(tmp0_other_with_cast.slf_1))
      return false;
    return true;
  }
}
class GLTF2ViewSkin$writeFrom$slambda {
  constructor($this, $name) {
    this.rlo_1 = $this;
    this.slo_1 = $name;
  }
  ql8($this$sequence, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_0(this, $this$sequence, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.ql8(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  }
}
class GLTF2ViewSkin$writeFrom$slambda_0 {
  constructor($this, $name) {
    this.tlo_1 = $this;
    this.ulo_1 = $name;
  }
  ql8($this$sequence, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_1(this, $this$sequence, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.ql8(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  }
}
class GLTF2ViewSkin {
  constructor(gltf, skin, view) {
    this.vlo_1 = gltf;
    this.wlo_1 = skin;
    this.xlo_1 = view;
    this.ylo_1 = _GLTF2AccessorVectorMAT4___init__impl__q6vlls(this.vlo_1.klg_1.i1(this.wlo_1.uld_1).blo(this.vlo_1));
  }
  zlo() {
    var rootJoint = ensureNotNull(this.xlo_1.plp_1.k3(this.vlo_1.elg_1.i1(this.wlo_1.vld_1[0])));
    // Inline function 'kotlin.collections.toTypedArray' call
    // Inline function 'kotlin.collections.map' call
    var this_0 = until(0, this.wlo_1.vld_1.length);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewSkin.getJoints.<anonymous>' call
      var jointId = this.wlo_1.vld_1[item];
      var viewNode = ensureNotNull(this.xlo_1.plp_1.k3(this.vlo_1.elg_1.i1(jointId)));
      // Inline function 'kotlin.also' call
      var this_1 = viewNode.zkv_1.ml6(rootJoint, true).j2r(GLTF2AccessorVectorMAT4__get_impl_c4fgc0(this.ylo_1, item));
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewSkin.getJoints.<anonymous>.<anonymous>' call
      this_1.equals(Companion_getInstance_9().s2p_1);
      destination.f(this_1);
    }
    return copyToArray(destination);
  }
  rky(ctx) {
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.fl3().y8r(Bones4UB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewSkin.putUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      this_0.u8r_1.p9i($this$push.alq(), this.zlo());
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
  }
  blq(skin0, skin1, ratio) {
    var targetSkin = this;
    var ratio_0 = clamp01(ratio);
    var indexedObject = targetSkin.wlo_1.vld_1;
    var inductionVariable = 0;
    var last = indexedObject.length;
    $l$loop: while (inductionVariable < last) {
      var jointId = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var targetNode = ensureNotNull(targetSkin.xlo_1.plp_1.k3(targetSkin.vlo_1.elg_1.i1(jointId)));
      var tmp0_elvis_lhs = targetNode.ykv_1;
      var tmp;
      if (tmp0_elvis_lhs == null) {
        continue $l$loop;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var targetNodeName = tmp;
      // Inline function 'korlibs.korge3d.findByName' call
      var this_0 = skin0.xlo_1;
      var tmp$ret$0 = sequence(GLTF2ViewSkin$writeFrom$slambda_1(this_0, targetNodeName));
      var skin0Node = firstOrNull(tmp$ret$0);
      var tmp151_safe_receiver = skin1 == null ? null : skin1.xlo_1;
      var tmp_0;
      if (tmp151_safe_receiver == null) {
        tmp_0 = null;
      } else {
        // Inline function 'korlibs.korge3d.findByName' call
        tmp_0 = sequence(GLTF2ViewSkin$writeFrom$slambda_2(tmp151_safe_receiver, targetNodeName));
      }
      var tmp152_safe_receiver = tmp_0;
      var skin1Node = tmp152_safe_receiver == null ? null : firstOrNull(tmp152_safe_receiver);
      if (!(skin0Node == null) || !(skin1Node == null)) {
        if (!(skin0Node == null) && !(skin1Node == null)) {
          targetNode.zkv_1.xl6(skin0Node.zkv_1, skin1Node.zkv_1, ratio_0, false, true, false);
        }
      }
    }
  }
}
class GLTF2View extends Container3D {
  static elq(gltf, autoAnimate) {
    autoAnimate = autoAnimate === VOID ? true : autoAnimate;
    var $this = this.zkz();
    $this.olp_1 = gltf;
    $this.plp_1 = LinkedHashMap.vc();
    $this.qlp_1 = LinkedHashMap.vc();
    var tmp = $this;
    tmp.rlp_1 = lazy(GLTF2View$viewSkins$delegate$lambda($this));
    var _iterator__ex2g4s = $this.olp_1.glg_1.f1();
    while (_iterator__ex2g4s.g1()) {
      var skin = _iterator__ex2g4s.h1();
      $this.clq(skin);
    }
    var tmp_0 = $this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = $this.olp_1.dlg_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2View.sceneViews.<anonymous>' call
      var tmp$ret$0 = addTo(GLTF2SceneView.xlq($this.olp_1, item, $this, autoAnimate), $this);
      destination.f(tmp$ret$0);
    }
    tmp_0.slp_1 = destination;
    if (autoAnimate) {
      addUpdater_0($this, false, VOID, GLTF2View$lambda);
    }
    var tmp_1 = $this;
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    tmp_1.tlp_1 = toDuration(0, DurationUnit_SECONDS_getInstance());
    return $this;
  }
  glo() {
    return this.olp_1;
  }
  ylq() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.rlp_1;
    viewSkins$factory();
    return this_0.b2();
  }
  clq(skin) {
    // Inline function 'kotlin.collections.getOrPut' call
    var this_0 = this.qlp_1;
    var value = this_0.k3(skin);
    var tmp;
    if (value == null) {
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2View.getViewSkin.<anonymous>' call
      var answer = new GLTF2ViewSkin(this.olp_1, skin, this);
      this_0.r3(skin, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    return tmp;
  }
  dlq(dt) {
    this.tlp_1 = Duration__plus_impl_yu9v8f(this.tlp_1, dt);
    this.zlq(this.tlp_1);
  }
  zlq(time) {
    var _iterator__ex2g4s = this.olp_1.hlg_1.f1();
    while (_iterator__ex2g4s.g1()) {
      var animation = _iterator__ex2g4s.h1();
      var _iterator__ex2g4s_0 = animation.wlh_1.f1();
      $l$loop_0: while (_iterator__ex2g4s_0.g1()) {
        var channel = _iterator__ex2g4s_0.h1();
        var tmp0_elvis_lhs = channel.tle_1;
        var tmp;
        if (tmp0_elvis_lhs == null) {
          continue $l$loop_0;
        } else {
          tmp = tmp0_elvis_lhs;
        }
        var target = tmp;
        var node = this.olp_1.elg_1.i1(target.dle_1);
        var tmp1_elvis_lhs = this.plp_1.k3(node);
        var tmp_0;
        if (tmp1_elvis_lhs == null) {
          continue $l$loop_0;
        } else {
          tmp_0 = tmp1_elvis_lhs;
        }
        var view = tmp_0;
        var sampler = animation.xlh_1.i1(channel.sle_1);
        var maxTime = sampler.plf(this.olp_1);
        var currentTime = get_seconds(time);
        var rtime = currentTime % maxTime;
        var tmp153_subject = target.ele_1;
        switch (tmp153_subject == null ? -1 : tmp153_subject.v3_1) {
          case 3:
            var tmp154_safe_receiver = view.slr_1;
            var tmp155_safe_receiver = tmp154_safe_receiver == null ? null : tmp154_safe_receiver.lls_1;
            if (tmp155_safe_receiver == null)
              null;
            else {
              // Inline function 'korlibs.datastructure.iterators.fastForEach' call
              var n = 0;
              while (n < tmp155_safe_receiver.j1()) {
                // Inline function 'korlibs.korge3d.format.gltf2.GLTF2View.updateAnimation.<anonymous>' call
                var _unary__edvuaz = n;
                n = _unary__edvuaz + 1 | 0;
                var it = tmp155_safe_receiver.i1(_unary__edvuaz);
                it.dlt_1 = sampler.mlh(this.olp_1, rtime, LookupKind_NORMAL_getInstance(), it.olt()).olo();
              }
            }

            break;
          case 1:
            var vec4 = sampler.plh(this.olp_1, rtime, LookupKind_QUATERNION_getInstance()).olo();
            var quat = Quaternion.j2s(vec4);
            rotation(view, quat.e2s());
            break;
          case 0:
            var pos = sampler.plh(this.olp_1, rtime, LookupKind_NORMAL_getInstance()).mlo();
            position_0(view, pos);
            break;
          case 2:
            var scale_0 = sampler.plh(this.olp_1, rtime, LookupKind_NORMAL_getInstance()).mlo();
            // Inline function 'korlibs.korge3d.scale' call

            scale(view, scale_0.k2x_1, scale_0.l2x_1, scale_0.m2x_1);
            break;
          default:
            println('Unsupported animation target.path=' + toString(target.ele_1));
            break;
        }
      }
    }
  }
  plt(node) {
    return node.ylb_1 >= 0 ? this.qlp_1.k3(this.olp_1.glg_1.i1(node.ylb_1)) : null;
  }
}
class GLTF2ViewNode extends Container3D {
  static qlt(gltf, node, view) {
    var $this = this.zkz();
    $this.olr_1 = gltf;
    $this.plr_1 = node;
    $this.qlr_1 = view;
    $this.ykv_1 = $this.plr_1.wlb_1;
    $this.rlr_1 = $this.qlr_1.plt($this.plr_1);
    $this.slr_1 = $this.plr_1.zlb_1 >= 0 ? addTo(GLTF2ViewMesh.rlt($this.olr_1, $this.olr_1.flg_1.i1($this.plr_1.zlb_1), $this), $this) : null;
    var tmp = $this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = $this.jlo($this.plr_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewNode.childrenNodes.<anonymous>' call
      var tmp$ret$0 = addTo(GLTF2ViewNode.qlt($this.olr_1, item, $this.qlr_1), $this);
      destination.f(tmp$ret$0);
    }
    tmp.tlr_1 = destination;
    $this.zkv_1.sl6(get_mutable($this.plr_1.qln()));
    // Inline function 'kotlin.collections.set' call
    var this_1 = $this.qlr_1.plp_1;
    var key = $this.plr_1;
    this_1.r3(key, $this);
    return $this;
  }
  glo() {
    return this.olr_1;
  }
}
class GLTF2SceneView extends Container3D {
  static xlq(gltf, scene, rootView, autoAnimate) {
    autoAnimate = autoAnimate === VOID ? true : autoAnimate;
    var $this = this.zkz();
    $this.tlq_1 = gltf;
    $this.ulq_1 = scene;
    $this.vlq_1 = rootView;
    var tmp = $this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = $this.klo($this.ulq_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2SceneView.rootNodes.<anonymous>' call
      var tmp$ret$0 = $this.slt(item);
      destination.f(tmp$ret$0);
    }
    tmp.wlq_1 = destination;
    return $this;
  }
  glo() {
    return this.tlq_1;
  }
  slt(node) {
    var view = GLTF2ViewNode.qlt(this.tlq_1, node, this.vlq_1);
    this.bl0(view);
    return view;
  }
}
class Companion_33 {}
class GLTF2ViewPrimitive extends ViewWithMaterial3D {
  static tlt(gltf, primitive, mesh, viewMesh) {
    var $this = this.el9();
    $this.zls_1 = gltf;
    $this.alt_1 = primitive;
    $this.blt_1 = mesh;
    $this.clt_1 = viewMesh;
    $this.dlt_1 = $this.blt_1.slc_1;
    var tmp = $this;
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlin.collections.count' call
      var this_0 = $this.alt_1.jld_1;
      if (this_0.a1()) {
        tmp$ret$0 = 0;
        break $l$block;
      }
      var count = 0;
      // Inline function 'kotlin.collections.iterator' call
      var tmp0_iterator = this_0.z1().f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.njoins.<anonymous>' call
        if (PrimitiveAttribute__isJoints_impl_lsaysl(element.a2().eld_1, 0)) {
          count = count + 1 | 0;
        }
      }
      tmp$ret$0 = count;
    }
    tmp.elt_1 = imul(tmp$ret$0, 4);
    var tmp_0 = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.collections.toTypedArray' call
    // Inline function 'kotlin.collections.map' call
    var this_1 = $this.alt_1.jld_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(this_1.j1());
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator_0 = this_1.z1().f1();
    while (tmp0_iterator_0.g1()) {
      var item = tmp0_iterator_0.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.vertexData.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var prim = item.a2().eld_1;
      // Inline function 'kotlin.collections.component2' call
      var index = item.b2();
      var tmp$ret$6 = $this.ult(prim, index, -1);
      destination.f(tmp$ret$6);
    }
    var tmp_1 = copyToArray(destination);
    // Inline function 'kotlin.collections.toTypedArray' call
    // Inline function 'kotlin.collections.flatMapIndexed' call
    // Inline function 'kotlin.collections.flatMapIndexedTo' call
    var this_2 = $this.alt_1.nld_1;
    var destination_0 = ArrayList.m1();
    var index_0 = 0;
    var tmp0_iterator_1 = this_2.f1();
    while (tmp0_iterator_1.g1()) {
      var element_0 = tmp0_iterator_1.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.vertexData.<anonymous>' call
      var tmp1 = index_0;
      index_0 = tmp1 + 1 | 0;
      var targetIndex = checkIndexOverflow(tmp1);
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.mapTo' call
      var destination_1 = ArrayList.w(element_0.j1());
      // Inline function 'kotlin.collections.iterator' call
      var tmp0_iterator_2 = element_0.z1().f1();
      while (tmp0_iterator_2.g1()) {
        var item_0 = tmp0_iterator_2.h1();
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.vertexData.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.collections.component1' call
        var prim_0 = item_0.a2().eld_1;
        // Inline function 'kotlin.collections.component2' call
        var index_1 = item_0.b2();
        var tmp$ret$13 = $this.ult(prim_0, index_1, targetIndex);
        destination_1.f(tmp$ret$13);
      }
      var list = destination_1;
      addAll(destination_0, list);
    }
    var tmp$ret$19 = copyToArray(destination_0);
    var this_3 = AGVertexArrayObject.s8t(arrayConcat([tmp_1, tmp$ret$19]));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.vertexData.<anonymous>' call
    var iterator = $this.zls_1.klg_1.f1();
    var index_2 = 0;
    while (iterator.g1()) {
      var index_3 = index_2;
      index_2 = index_2 + 1 | 0;
      var accessor = iterator.h1();
    }
    tmp_0.flt_1 = this_3;
    var tmp_2 = $this;
    // Inline function 'kotlin.collections.contains' call
    var this_4 = $this.alt_1.jld_1;
    // Inline function 'kotlin.collections.containsKey' call
    var key = new PrimitiveAttribute(Companion_getInstance_23().bld(0));
    tmp_2.glt_1 = (isInterface(this_4, KtMap) ? this_4 : THROW_CCE()).i3(key);
    var tmp_3 = $this;
    // Inline function 'kotlin.takeIf' call
    var this_5 = $this.alt_1.kld_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp_4;
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.indexAccessor.<anonymous>' call
    if (this_5 >= 0) {
      tmp_4 = this_5;
    } else {
      tmp_4 = null;
    }
    var tmp158_safe_receiver = tmp_4;
    var tmp_5;
    if (tmp158_safe_receiver == null) {
      tmp_5 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.indexAccessor.<anonymous>' call
      tmp_5 = $this.zls_1.klg_1.i1(tmp158_safe_receiver);
    }
    tmp_3.hlt_1 = tmp_5;
    var tmp_6 = $this;
    var tmp159_safe_receiver = $this.hlt_1;
    tmp_6.ilt_1 = tmp159_safe_receiver == null ? null : tmp159_safe_receiver.zln();
    $this.jlt_1 = 0;
    var tmp_7 = $this;
    var tmp160_safe_receiver = $this.hlt_1;
    tmp_7.klt_1 = tmp160_safe_receiver == null ? null : tmp160_safe_receiver.hlh($this.zls_1);
    var tmp_8 = $this;
    var tmp161_safe_receiver = $this.klt_1;
    var tmp_9;
    if (tmp161_safe_receiver == null) {
      tmp_9 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.indexData.<anonymous>' call
      // Inline function 'kotlin.also' call
      var this_6 = new AGBuffer();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.indexData.<anonymous>.<anonymous>' call
      this_6.s8n(tmp161_safe_receiver);
      tmp_9 = this_6;
    }
    tmp_8.llt_1 = tmp_9;
    var tmp_10 = $this;
    var tmp_11;
    if (!($this.klt_1 == null)) {
      var tmp_12 = $this.klt_1.y10();
      var tmp_13 = $this.ilt_1;
      tmp_11 = tmp_12 / _AGIndexType___get_bytesSize__impl__lsf184(ensureNotNull(tmp_13 == null ? null : new AGIndexType(tmp_13)).p8u_1) | 0;
    } else {
      tmp_11 = $this.zls_1.klg_1.i1(ensureNotNull($this.alt_1.jld_1.k3(new PrimitiveAttribute(Companion_getInstance_23().ulc_1)))).ylg_1;
    }
    tmp_10.mlt_1 = tmp_11;
    var tmp_14 = $this;
    // Inline function 'kotlin.collections.getOrElse' call
    var this_7 = $this.zls_1.hlo();
    var index_4 = $this.alt_1.lld_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp_15;
    if (0 <= index_4 ? index_4 < this_7.j1() : false) {
      tmp_15 = this_7.i1(index_4);
    } else {
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.material.<anonymous>' call
      tmp_15 = Companion_getInstance_50().vlt_1;
    }
    tmp_14.nlt_1 = tmp_15;
    return $this;
  }
  glo() {
    return this.zls_1;
  }
  olt() {
    return this.alt_1.nld_1.j1();
  }
  rln() {
    return this.alt_1.rln();
  }
  ult(prim, index, targetIndex) {
    var accessor = this.zls_1.klg_1.i1(index);
    var bufferView = this.zls_1.jlg_1.i1(accessor.ulg_1);
    var buffer = sliceBuffer(bufferView.pln(this.zls_1), accessor.vlg_1);
    var tmp;
    if (_PrimitiveAttribute___get_isPosition__impl__o3zd2(prim)) {
      tmp = Companion_getInstance_51().el1_1;
    } else if (_PrimitiveAttribute___get_isNormal__impl__6yj138(prim)) {
      tmp = Companion_getInstance_51().fl1_1;
    } else if (_PrimitiveAttribute___get_isTangent__impl__s8gm6i(prim)) {
      tmp = accessor.wln() === 3 ? Companion_getInstance_51().hl1_1 : Companion_getInstance_51().gl1_1;
    } else if (_PrimitiveAttribute___get_isColor0__impl__ug1pvu(prim)) {
      tmp = Companion_getInstance_51().ml1_1;
    } else if (PrimitiveAttribute__isTexcoord_impl_m3qlxc(prim, 0)) {
      tmp = Companion_getInstance_51().il1_1;
    } else if (PrimitiveAttribute__isTexcoord_impl_m3qlxc(prim, 1)) {
      tmp = Companion_getInstance_51().jl1_1;
    } else if (PrimitiveAttribute__isTexcoord_impl_m3qlxc(prim, 2)) {
      tmp = Companion_getInstance_51().kl1_1;
    } else if (PrimitiveAttribute__isTexcoord_impl_m3qlxc(prim, 3)) {
      tmp = Companion_getInstance_51().ll1_1;
    } else if (PrimitiveAttribute__isJoints_impl_lsaysl(prim, 0)) {
      tmp = Companion_getInstance_51().ql1_1[0];
    } else if (PrimitiveAttribute__isWeights_impl_ctkpe7(prim, 0)) {
      tmp = Companion_getInstance_51().rl1_1[0];
    } else {
      var reason = PrimitiveAttribute__toString_impl_lmy2fg(prim);
      throw NotImplementedError.oe('An operation is not implemented: ' + reason);
    }
    var att = tmp;
    var tmp_0;
    if (att.equals(Companion_getInstance_51().el1_1) || att.equals(Companion_getInstance_51().fl1_1)) {
      tmp_0 = 3;
    } else if (att.equals(Companion_getInstance_51().hl1_1)) {
      tmp_0 = 3;
    } else if (att.equals(Companion_getInstance_51().gl1_1)) {
      tmp_0 = 4;
    } else if (att.equals(Companion_getInstance_51().ml1_1)) {
      tmp_0 = 4;
    } else if (att.equals(Companion_getInstance_51().il1_1)) {
      tmp_0 = 2;
    } else if (att.equals(Companion_getInstance_51().jl1_1)) {
      tmp_0 = 2;
    } else if (att.equals(Companion_getInstance_51().kl1_1)) {
      tmp_0 = 2;
    } else if (att.equals(Companion_getInstance_51().ll1_1)) {
      tmp_0 = 2;
    } else if (att.equals(Companion_getInstance_51().ql1_1[0])) {
      tmp_0 = 4;
    } else if (att.equals(Companion_getInstance_51().rl1_1[0])) {
      tmp_0 = 4;
    } else {
      var reason_0 = 'Unsupported ' + att.toString();
      throw NotImplementedError.oe('An operation is not implemented: ' + reason_0);
    }
    var expectedComponents = tmp_0;
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(accessor.wln() === expectedComponents)) {
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.genAGVertexData.<anonymous>' call
      var message = PrimitiveAttribute__toString_impl_lmy2fg(prim) + ' in ' + accessor.toString() + ' expected to have ' + expectedComponents + ' components but had ' + accessor.wln();
      throw IllegalStateException.m5(toString_0(message));
    }
    var ratt = att.equals(Companion_getInstance_51().el1_1) ? targetIndex < 0 ? Companion_getInstance_51().el1_1 : Companion_getInstance_51().nl1_1[targetIndex] : att.equals(Companion_getInstance_51().gl1_1) ? targetIndex < 0 ? Companion_getInstance_51().gl1_1 : Companion_getInstance_51().pl1_1[targetIndex] : att.equals(Companion_getInstance_51().fl1_1) ? targetIndex < 0 ? Companion_getInstance_51().fl1_1 : Companion_getInstance_51().ol1_1[targetIndex] : att;
    var normalized = accessor.xlg_1;
    var stride = accessor.alo(this.zls_1).qli_1;
    accessor.flh_1 = _PrimitiveAttribute___get_str__impl__zd1b7k(prim);
    var tmp_1 = ProgramLayout.f8u([ratt.u9o(VOID, accessor.glh_1, normalized)], stride > 0 ? stride : null);
    // Inline function 'kotlin.also' call
    var this_0 = new AGBuffer();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.genAGVertexData.<anonymous>' call
    this_0.s8n(buffer);
    return AGVertexData.w8t(tmp_1, this_0);
  }
  fl9() {
    return this.nlt_1;
  }
  rky(ctx) {
    super.rky(ctx);
    $l$block: {
      // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
      var this_0 = ctx.fl3().y8r(WeightsUB_getInstance());
      var _unary__edvuaz = this_0.n8t();
      this_0.v9h(_unary__edvuaz + 1 | 0);
      this_0.w9h(this_0.n8t() + 1 | 0);
      var blockSize = this_0.r8r_1;
      var index0 = imul(this_0.n8t() - 1 | 0, blockSize);
      var index1 = imul(this_0.n8t(), blockSize);
      var texBlockSize = this_0.o8r_1.s8z().j1();
      var texIndex0 = imul(this_0.n8t() - 1 | 0, texBlockSize);
      var texIndex1 = imul(this_0.n8t(), texBlockSize);
      if (this_0.n8t() > 0) {
        arraycopy(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
      } else {
        arrayfill(this_0.t8r_1, 0, 0, blockSize);
      }
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewPrimitive.putUniforms.<anonymous>' call
      var $this$push = this_0.o8r_1;
      this_0.u8r_1.k9i($this$push.chh(), this.dlt_1);
      if (true && this_0.n8t() >= 1) {
        var equals = arrayequal(this_0.t8r_1, index0, this_0.t8r_1, index1, blockSize);
        if (equals) {
          var _unary__edvuaz_0 = this_0.n8t();
          this_0.v9h(_unary__edvuaz_0 - 1 | 0);
          break $l$block;
        }
      }
    }
    var tmp162_safe_receiver = this.clt_1.kls_1.rlr_1;
    if (tmp162_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      tmp162_safe_receiver.rky(ctx);
    }
  }
  oky(ctx) {
    var program = ctx.nl2_1.blb(clamp(ctx.sl2_1.j1(), 0, 4), this.olt(), this.nlt_1, this.nlt_1.gla_1, this.elt_1, this.glt_1);
    this.rky(ctx);
    var tmp = ctx.saq();
    var tmp163_frameBuffer = ctx.fl3().pa7_1.t8j_1;
    var tmp164_frameBufferInfo = ctx.fl3().pa7_1.c8m();
    var tmp165_drawType = this.rln();
    var tmp0_elvis_lhs = this.ilt_1;
    var tmp_0;
    var tmp_1 = tmp0_elvis_lhs;
    if ((tmp_1 == null ? null : new AGIndexType(tmp_1)) == null) {
      tmp_0 = Companion_getInstance_1().u8k_1;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var tmp166_indexType = tmp_0;
    var tmp167_indices = this.llt_1;
    var tmp168_vertexData = this.flt_1;
    var tmp169_cullFace = this.nlt_1.fla_1 ? Companion_getInstance_7().y8l_1 : Companion_getInstance_7().a8m_1;
    var tmp170_uniformBlocks = ctx.fl3().qah(program);
    var tmp171_textureUnits = this.dl9_1;
    var tmp172_vertexCount = this.mlt_1;
    var tmp173_depthAndFrontFace = ctx.dl3_1;
    draw_0(tmp, new AGBatch(tmp163_frameBuffer, tmp164_frameBufferInfo, tmp168_vertexData, tmp167_indices, tmp166_indexType, program, tmp170_uniformBlocks, tmp171_textureUnits, VOID, VOID, VOID, VOID, tmp173_depthAndFrontFace, VOID, tmp169_cullFace, tmp165_drawType, 0, tmp172_vertexCount));
  }
}
class GLTF2ViewMesh extends Container3D {
  static rlt(gltf, mesh, viewNode) {
    var $this = this.zkz();
    $this.ils_1 = gltf;
    $this.jls_1 = mesh;
    $this.kls_1 = viewNode;
    var tmp = $this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = $this.jls_1.olc_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2ViewMesh.primitiveViews.<anonymous>' call
      var tmp$ret$0 = addTo(GLTF2ViewPrimitive.tlt($this.ils_1, item, $this.jls_1, $this), $this);
      destination.f(tmp$ret$0);
    }
    tmp.lls_1 = destination;
    return $this;
  }
}
class Vector3DTemps {
  constructor() {
    this.blu_1 = 0;
    this.clu_1 = arrayListOf([new MVector4(), new MVector4(), new MVector4()]);
  }
}
class Companion_34 {
  constructor() {
    Companion_instance_39 = this;
    this.vlt_1 = new PBRMaterial3D(VOID, VOID, new LightColor(Colors_getInstance().g4r_1));
  }
}
class Light {
  constructor(kind, hasTexture) {
    this.dlu_1 = kind;
    this.elu_1 = hasTexture;
  }
}
class LightColor extends Light {
  constructor(color) {
    super('color', false);
    this.xl9_1 = color;
  }
  toString() {
    return 'LightColor(color=' + RGBA__toString_impl_4lw6dp(this.xl9_1) + ')';
  }
  hashCode() {
    return RGBA__hashCode_impl_h59qf6(this.xl9_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof LightColor))
      return false;
    var tmp0_other_with_cast = other instanceof LightColor ? other : THROW_CCE();
    if (!(this.xl9_1 === tmp0_other_with_cast.xl9_1))
      return false;
    return true;
  }
}
class LightTexture extends Light {
  constructor(bitmap) {
    super('texture', true);
    this.tl9_1 = bitmap;
  }
  toString() {
    return 'LightTexture(bitmap=' + toString(this.tl9_1) + ')';
  }
  hashCode() {
    return this.tl9_1 == null ? 0 : hashCode(this.tl9_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof LightTexture))
      return false;
    var tmp0_other_with_cast = other instanceof LightTexture ? other : THROW_CCE();
    if (!equals(this.tl9_1, tmp0_other_with_cast.tl9_1))
      return false;
    return true;
  }
}
class PBRMaterial3D {
  constructor(emission, ambient, diffuse, specular, shininess, indexOfRefraction, occlusionTexture, doubleSided) {
    Companion_getInstance_50();
    emission = emission === VOID ? new LightColor(Colors_getInstance().h4r_1) : emission;
    ambient = ambient === VOID ? new LightColor(Colors_getInstance().h4r_1) : ambient;
    diffuse = diffuse === VOID ? new LightColor(Colors_getInstance().h4r_1) : diffuse;
    specular = specular === VOID ? new LightColor(Colors_getInstance().h4r_1) : specular;
    shininess = shininess === VOID ? 0.5 : shininess;
    indexOfRefraction = indexOfRefraction === VOID ? 1.0 : indexOfRefraction;
    occlusionTexture = occlusionTexture === VOID ? null : occlusionTexture;
    doubleSided = doubleSided === VOID ? false : doubleSided;
    this.yl9_1 = emission;
    this.zl9_1 = ambient;
    this.ala_1 = diffuse;
    this.bla_1 = specular;
    this.cla_1 = shininess;
    this.dla_1 = indexOfRefraction;
    this.ela_1 = occlusionTexture;
    this.fla_1 = doubleSided;
    this.gla_1 = this.yl9_1.elu_1 || this.zl9_1.elu_1 || this.ala_1.elu_1 || this.bla_1.elu_1;
    this.hla_1 = this.yl9_1.dlu_1 + '_' + this.zl9_1.dlu_1 + '_' + this.ala_1.dlu_1 + '_' + this.bla_1.dlu_1;
  }
  toString() {
    return 'PBRMaterial3D(emission=' + toString_0(this.yl9_1) + ', ambient=' + toString_0(this.zl9_1) + ', diffuse=' + toString_0(this.ala_1) + ', specular=' + toString_0(this.bla_1) + ', shininess=' + this.cla_1 + ', indexOfRefraction=' + this.dla_1 + ', occlusionTexture=' + toString(this.ela_1) + ', doubleSided=' + this.fla_1 + ')';
  }
  hashCode() {
    var result = hashCode(this.yl9_1);
    result = imul(result, 31) + hashCode(this.zl9_1) | 0;
    result = imul(result, 31) + hashCode(this.ala_1) | 0;
    result = imul(result, 31) + hashCode(this.bla_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.cla_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.dla_1) | 0;
    result = imul(result, 31) + (this.ela_1 == null ? 0 : hashCode(this.ela_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.fla_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof PBRMaterial3D))
      return false;
    var tmp0_other_with_cast = other instanceof PBRMaterial3D ? other : THROW_CCE();
    if (!equals(this.yl9_1, tmp0_other_with_cast.yl9_1))
      return false;
    if (!equals(this.zl9_1, tmp0_other_with_cast.zl9_1))
      return false;
    if (!equals(this.ala_1, tmp0_other_with_cast.ala_1))
      return false;
    if (!equals(this.bla_1, tmp0_other_with_cast.bla_1))
      return false;
    if (!equals(this.cla_1, tmp0_other_with_cast.cla_1))
      return false;
    if (!equals(this.dla_1, tmp0_other_with_cast.dla_1))
      return false;
    if (!equals(this.ela_1, tmp0_other_with_cast.ela_1))
      return false;
    if (!(this.fla_1 === tmp0_other_with_cast.fla_1))
      return false;
    return true;
  }
}
class K3DPropsUB extends UniformBlock {
  constructor() {
    K3DPropsUB_instance = null;
    super(1);
    K3DPropsUB_instance = this;
    this.cl7_1 = this.x8z().p8z(this, u_Shininess$factory());
    this.dl7_1 = this.x8z().p8z(this, u_IndexOfRefraction$factory());
    this.el7_1 = this.b90().p8z(this, u_AmbientColor$factory());
    this.fl7_1 = this.x8z().p8z(this, u_OcclusionStrength$factory());
    this.gl7_1 = this.f8z().p8z(this, u_BindShapeMatrix$factory());
    this.hl7_1 = this.f8z().p8z(this, u_BindShapeMatrixInv$factory());
    this.il7_1 = this.f8z().p8z(this, u_ModMat$factory());
    this.jl7_1 = this.f8z().p8z(this, u_NormMat$factory());
  }
  flu() {
    return this.cl7_1.ko(this, u_Shininess$factory_0());
  }
  glu() {
    return this.dl7_1.ko(this, u_IndexOfRefraction$factory_0());
  }
  hlu() {
    return this.el7_1.ko(this, u_AmbientColor$factory_0());
  }
  ml7() {
    return this.fl7_1.ko(this, u_OcclusionStrength$factory_0());
  }
  ilu() {
    return this.gl7_1.ko(this, u_BindShapeMatrix$factory_0());
  }
  jlu() {
    return this.hl7_1.ko(this, u_BindShapeMatrixInv$factory_0());
  }
  ll7() {
    return this.il7_1.ko(this, u_ModMat$factory_0());
  }
  kl7() {
    return this.jl7_1.ko(this, u_NormMat$factory_0());
  }
}
class Bones4UB extends UniformBlock {
  constructor() {
    Bones4UB_instance = null;
    super(2);
    Bones4UB_instance = this;
    this.ylp_1 = 64;
    var tmp = this;
    tmp.zlp_1 = this.r9g(this.ylp_1, Shaders3D$Bones4UB$u_BoneMats$delegate$lambda).p8z(this, u_BoneMats$factory());
  }
  alq() {
    return this.zlp_1.ko(this, u_BoneMats$factory_0());
  }
}
class Companion_35 {
  constructor() {
    Companion_instance_40 = this;
    this.cl1_1 = DefaultShaders_getInstance().q8z();
    this.dl1_1 = DefaultShaders_getInstance().r8z();
    this.el1_1 = Attribute.z90('a_Pos', VarType_Float3_getInstance(), false, Precision_HIGH_getInstance(), 0);
    this.fl1_1 = Attribute.z90('a_Nor', VarType_Float3_getInstance(), false, Precision_LOW_getInstance(), 1);
    this.gl1_1 = Attribute.z90('a_Tan', VarType_Float4_getInstance(), false, Precision_LOW_getInstance(), 2);
    this.hl1_1 = Attribute.z90('a_Tan', VarType_Float3_getInstance(), false, Precision_LOW_getInstance(), 2);
    this.il1_1 = Attribute.z90('a_TexCoords', VarType_Float2_getInstance(), false, Precision_MEDIUM_getInstance(), 3);
    this.jl1_1 = Attribute.z90('a_TexCoords1', VarType_Float2_getInstance(), false, Precision_MEDIUM_getInstance(), 3);
    this.kl1_1 = Attribute.z90('a_TexCoords2', VarType_Float2_getInstance(), false, Precision_MEDIUM_getInstance(), 3);
    this.ll1_1 = Attribute.z90('a_TexCoords3', VarType_Float2_getInstance(), false, Precision_MEDIUM_getInstance(), 3);
    this.ml1_1 = Attribute.z90('a_Col', VarType_Float4_getInstance(), true, Precision_LOW_getInstance(), 4);
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(4), null);
    while (tmp_0 < 4) {
      var tmp_2 = tmp_0;
      tmp_1[tmp_2] = Attribute.z90('a_Pos' + tmp_2, VarType_Float3_getInstance(), false, VOID, (5 + imul(tmp_2, 3) | 0) + 0 | 0);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.nl1_1 = tmp_1;
    var tmp_3 = this;
    var tmp_4 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_5 = fillArrayVal(Array(4), null);
    while (tmp_4 < 4) {
      var tmp_6 = tmp_4;
      tmp_5[tmp_6] = Attribute.z90('a_Nor' + tmp_6, VarType_Float3_getInstance(), false, VOID, (5 + imul(tmp_6, 3) | 0) + 1 | 0);
      tmp_4 = tmp_4 + 1 | 0;
    }
    tmp_3.ol1_1 = tmp_5;
    var tmp_7 = this;
    var tmp_8 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_9 = fillArrayVal(Array(4), null);
    while (tmp_8 < 4) {
      var tmp_10 = tmp_8;
      tmp_9[tmp_10] = Attribute.z90('a_Tan' + tmp_10, VarType_Float3_getInstance(), false, VOID, (5 + imul(tmp_10, 3) | 0) + 2 | 0);
      tmp_8 = tmp_8 + 1 | 0;
    }
    tmp_7.pl1_1 = tmp_9;
    var tmp_11 = this;
    var tmp_12 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_13 = fillArrayVal(Array(4), null);
    while (tmp_12 < 4) {
      var tmp_14 = tmp_12;
      tmp_13[tmp_14] = Attribute.z90('a_Joint' + tmp_14, VarType_Float4_getInstance(), false, VOID, 4 + tmp_14 | 0);
      tmp_12 = tmp_12 + 1 | 0;
    }
    tmp_11.ql1_1 = tmp_13;
    var tmp_15 = this;
    var tmp_16 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_17 = fillArrayVal(Array(4), null);
    while (tmp_16 < 4) {
      var tmp_18 = tmp_16;
      tmp_17[tmp_18] = Attribute.z90('a_Weight' + tmp_18, VarType_Float4_getInstance(), false, VOID, 8 + tmp_18 | 0);
      tmp_16 = tmp_16 + 1 | 0;
    }
    tmp_15.rl1_1 = tmp_17;
    this.sl1_1 = Varying.l91('v_Col', VarType_Float4_getInstance(), Precision_LOW_getInstance());
    this.tl1_1 = Varying.l91('v_Pos', VarType_Float3_getInstance(), Precision_HIGH_getInstance());
    this.ul1_1 = Varying.l91('v_Norm', VarType_Float3_getInstance(), Precision_HIGH_getInstance());
    this.vl1_1 = Varying.l91('v_TexCoords', VarType_Float2_getInstance(), Precision_MEDIUM_getInstance());
    this.wl1_1 = Temp.x91(0, VarType_Float4_getInstance());
    this.xl1_1 = Temp.x91(1, VarType_Float4_getInstance());
    this.yl1_1 = Temp.x91(2, VarType_Mat4_getInstance());
    var tmp_19 = this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = until(0, 4);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.material.Companion.lights.<anonymous>' call
      var tmp$ret$10 = new LightUB(item, 6 + item | 0);
      destination.f(tmp$ret$10);
    }
    tmp_19.zl1_1 = destination;
    this.al2_1 = new MaterialUB('emission', 2, 1);
    this.bl2_1 = new MaterialUB('ambient', 3, 2);
    this.cl2_1 = new MaterialUB('diffuse', 4, 3);
    this.dl2_1 = new MaterialUB('specular', 5, 4);
    this.el2_1 = Sampler.s9r('u_texUnit_occlusion', 5, SamplerVarType_Sampler2D_getInstance());
    this.fl2_1 = ProgramLayout.f8u([this.el1_1, this.ml1_1]);
    this.gl2_1 = this.fl2_1.e8u_1 / 4 | 0;
  }
  ila() {
    return this.el2_1.ko(this, u_OcclussionTexUnit$factory());
  }
}
class LightUB extends UniformBlock {
  constructor(index, fixLocation) {
    super(fixLocation);
    this.rl7_1 = index;
    this.sl7_1 = fixLocation;
    this.tl7_1 = this.a90('u_lightPos' + this.rl7_1).p8z(this, u_SourcePos$factory());
    this.ul7_1 = this.a90('u_lightColor' + this.rl7_1).p8z(this, u_Color$factory());
    this.vl7_1 = this.a90('u_lightAttenuation' + this.rl7_1).p8z(this, u_Attenuation$factory());
  }
  wl7() {
    return this.tl7_1.ko(this, u_SourcePos$factory_0());
  }
  nhu() {
    return this.ul7_1.ko(this, u_Color$factory_0());
  }
  ol8() {
    return this.vl7_1.ko(this, u_Attenuation$factory_0());
  }
  toString() {
    return 'LightUB(index=' + this.rl7_1 + ', fixLocation=' + this.sl7_1 + ')';
  }
  hashCode() {
    var result = this.rl7_1;
    result = imul(result, 31) + this.sl7_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof LightUB))
      return false;
    var tmp0_other_with_cast = other instanceof LightUB ? other : THROW_CCE();
    if (!(this.rl7_1 === tmp0_other_with_cast.rl7_1))
      return false;
    if (!(this.sl7_1 === tmp0_other_with_cast.sl7_1))
      return false;
    return true;
  }
}
class MaterialUB extends UniformBlock {
  constructor(kind, fixLocation, textureIndex) {
    super(fixLocation);
    this.ll9_1 = kind;
    this.ml9_1 = fixLocation;
    this.nl9_1 = textureIndex;
    this.ol9_1 = this.a90('u_' + this.ll9_1 + '_color').p8z(this, u_color$factory());
    this.pl9_1 = Sampler.s9r('u_' + this.ll9_1 + '_texUnit', this.nl9_1, SamplerVarType_Sampler2D_getInstance());
  }
  ul9() {
    return this.ol9_1.ko(this, u_color$factory_0());
  }
  ql9() {
    return this.pl9_1.ko(this, u_texUnit$factory());
  }
  toString() {
    return 'MaterialUB(kind=' + this.ll9_1 + ', fixLocation=' + this.ml9_1 + ', textureIndex=' + this.nl9_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.ll9_1);
    result = imul(result, 31) + this.ml9_1 | 0;
    result = imul(result, 31) + this.nl9_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof MaterialUB))
      return false;
    var tmp0_other_with_cast = other instanceof MaterialUB ? other : THROW_CCE();
    if (!(this.ll9_1 === tmp0_other_with_cast.ll9_1))
      return false;
    if (!(this.ml9_1 === tmp0_other_with_cast.ml9_1))
      return false;
    if (!(this.nl9_1 === tmp0_other_with_cast.nl9_1))
      return false;
    return true;
  }
}
class WeightsUB extends UniformBlock {
  constructor() {
    WeightsUB_instance = null;
    super(32);
    WeightsUB_instance = this;
    this.alu_1 = this.b90().p8z(this, u_Weights$factory());
  }
  chh() {
    return this.alu_1.ko(this, u_Weights$factory_0());
  }
}
class Shaders3D {
  constructor() {
    Companion_getInstance_51();
    this.zla_1 = LinkedHashMap.vc();
    this.alb_1 = false;
  }
  blb(nlights, nmorphWeights, meshMaterial, hasTexture, njoints, hasVertexColor) {
    // Inline function 'kotlin.collections.getOrPut' call
    var this_0 = this.zla_1;
    var key = 'program_L' + nlights + '_W' + nmorphWeights + '_J' + njoints + '_M' + (meshMaterial == null ? null : meshMaterial.hla_1) + '_T' + hasTexture + '_VC_' + hasVertexColor;
    var value = this_0.k3(key);
    var tmp;
    if (value == null) {
      // Inline function 'korlibs.korge3d.material.Shaders3D.getProgram3D.<anonymous>' call
      // Inline function 'kotlin.apply' call
      var this_1 = (new StandardShader3D(nlights, nmorphWeights, meshMaterial, hasTexture, njoints, hasVertexColor)).llu();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge3d.material.Shaders3D.getProgram3D.<anonymous>.<anonymous>' call
      if (this.alb_1) {
        println((new GlslGenerator(ShaderType_VERTEX_getInstance(), new GlslConfig(Companion_instance_1.x9i(), new Mutable(VOID, VOID, VOID, true)))).x9m(this_1.i8s_1));
        println((new GlslGenerator(ShaderType_FRAGMENT_getInstance(), new GlslConfig(Companion_instance_1.x9i(), new Mutable(VOID, VOID, VOID, true)))).x9m(this_1.j8s_1));
      }
      var answer = this_1;
      this_0.r3(key, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    return tmp;
  }
}
class BaseShader3D {
  constructor() {
    var tmp = this;
    tmp.klu_1 = lazy(BaseShader3D$program$delegate$lambda(this));
  }
  llu() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.klu_1;
    program$factory();
    return this_0.b2();
  }
}
class StandardShader3D extends BaseShader3D {
  constructor(nlights, nweights, meshMaterial, hasTexture, njoints, hasVertexColor) {
    super();
    this.nlu_1 = nlights;
    this.olu_1 = nweights;
    this.plu_1 = meshMaterial;
    this.qlu_1 = hasTexture;
    this.rlu_1 = njoints;
    this.slu_1 = hasVertexColor;
  }
  tlu(_this__u8e3s4, attr, targets, nweights) {
    var out = attr;
    var inductionVariable = 0;
    if (inductionVariable < nweights)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = _this__u8e3s4.i95(out, _this__u8e3s4.j92(targets[n], _this__u8e3s4.z94(WeightsUB_getInstance().chh(), n)));
      }
       while (inductionVariable < nweights);
    return out;
  }
  ulu(_this__u8e3s4, njoints) {
    var out = null;
    var swizzles = listOf(['x', 'y', 'z', 'w']);
    var inductionVariable = 0;
    if (inductionVariable < njoints)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var weightIndex = n / 4 | 0;
        var weightComponent = n % 4 | 0;
        var component = swizzles.i1(weightComponent);
        var weight = _this__u8e3s4.o92(Companion_getInstance_51().rl1_1[weightIndex], component);
        var joint = _this__u8e3s4.o92(Companion_getInstance_51().ql1_1[weightIndex], component);
        var boneMatrix = get(Bones4UB_getInstance().alq(), _this__u8e3s4.c94(joint));
        var chunk = _this__u8e3s4.j92(weight, boneMatrix);
        out = out == null ? chunk : _this__u8e3s4.i95(out, chunk);
      }
       while (inductionVariable < njoints);
    return ensureNotNull(out);
  }
  vlu(_this__u8e3s4) {
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var $this$run = Companion_getInstance_51();
    var modelViewMat = _this__u8e3s4.u93(VarType_Mat4_getInstance());
    var normalMat = _this__u8e3s4.u93(VarType_Mat4_getInstance());
    var boneMatrix = _this__u8e3s4.u93(VarType_Mat4_getInstance());
    var localPos = _this__u8e3s4.u93(VarType_Float4_getInstance());
    var localNorm = _this__u8e3s4.u93(VarType_Float4_getInstance());
    var skinPos = _this__u8e3s4.u93(VarType_Float4_getInstance());
    _this__u8e3s4.f92(localPos, _this__u8e3s4.m92([this.tlu(_this__u8e3s4, $this$run.el1_1, $this$run.nl1_1, this.olu_1), _this__u8e3s4.l92(1.0)]));
    _this__u8e3s4.f92(localNorm, _this__u8e3s4.m92([this.tlu(_this__u8e3s4, $this$run.fl1_1, $this$run.ol1_1, this.olu_1), _this__u8e3s4.l92(0.0)]));
    _this__u8e3s4.f92(modelViewMat, _this__u8e3s4.j92($this$run.dl1_1, K3DPropsUB_getInstance().ll7()));
    _this__u8e3s4.f92(normalMat, K3DPropsUB_getInstance().kl7());
    _this__u8e3s4.f92($this$run.wl1_1, _this__u8e3s4.m92([_this__u8e3s4.o92(localPos, 'xyz'), _this__u8e3s4.l92(1.0)]));
    _this__u8e3s4.f92($this$run.xl1_1, _this__u8e3s4.r94(_this__u8e3s4.m92([_this__u8e3s4.o92(localNorm, 'xyz'), _this__u8e3s4.l92(0.0)])));
    if (this.rlu_1 > 0) {
      _this__u8e3s4.f92($this$run.yl1_1, this.ulu(_this__u8e3s4, this.rlu_1));
      _this__u8e3s4.f92($this$run.wl1_1, _this__u8e3s4.j92($this$run.yl1_1, $this$run.wl1_1));
      _this__u8e3s4.f92($this$run.xl1_1, _this__u8e3s4.j92($this$run.yl1_1, $this$run.xl1_1));
    }
    _this__u8e3s4.f92($this$run.tl1_1, _this__u8e3s4.w94([_this__u8e3s4.j92(modelViewMat, $this$run.wl1_1)]));
    _this__u8e3s4.f92($this$run.ul1_1, _this__u8e3s4.w94([_this__u8e3s4.j92(normalMat, $this$run.xl1_1)]));
    if (this.slu_1) {
      _this__u8e3s4.f92($this$run.sl1_1, $this$run.ml1_1);
    }
    if (this.qlu_1) {
      _this__u8e3s4.f92($this$run.vl1_1, _this__u8e3s4.u94([_this__u8e3s4.o92($this$run.il1_1, 'x'), _this__u8e3s4.o92($this$run.il1_1, 'y')]));
    }
    _this__u8e3s4.f92(_this__u8e3s4.i92(), _this__u8e3s4.j92($this$run.cl1_1, _this__u8e3s4.m92([$this$run.tl1_1, _this__u8e3s4.l92(1.0)])));
    return Unit_instance;
  }
  wlu(_this__u8e3s4) {
    var meshMaterial = this.plu_1;
    if (!(meshMaterial == null)) {
      this.xlu(_this__u8e3s4, _this__u8e3s4.i92(), Companion_getInstance_51().cl2_1, meshMaterial.ala_1);
    } else {
      _this__u8e3s4.f92(_this__u8e3s4.i92(), _this__u8e3s4.m92([_this__u8e3s4.l92(0.5), _this__u8e3s4.l92(0.5), _this__u8e3s4.l92(0.5), _this__u8e3s4.l92(1.0)]));
    }
    var inductionVariable = 0;
    var last = this.nlu_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.ylu(_this__u8e3s4, Companion_getInstance_51().zl1_1.i1(n), _this__u8e3s4.i92());
      }
       while (inductionVariable < last);
    if (!(meshMaterial == null)) {
      if (!(meshMaterial.ela_1 == null)) {
        _this__u8e3s4.f92(_this__u8e3s4.o92(_this__u8e3s4.i92(), 'rgb'), _this__u8e3s4.j92(_this__u8e3s4.o92(_this__u8e3s4.i92(), 'rgb'), _this__u8e3s4.i95(_this__u8e3s4.l92(1.0), _this__u8e3s4.j92(K3DPropsUB_getInstance().ml7(), _this__u8e3s4.g95(_this__u8e3s4.e95(_this__u8e3s4.q92(Companion_getInstance_51().ila(), _this__u8e3s4.o92(Companion_getInstance_51().vl1_1, 'xy'))), _this__u8e3s4.l92(1.0))))));
      }
    }
    if (this.slu_1) {
      _this__u8e3s4.f92(_this__u8e3s4.o92(_this__u8e3s4.i92(), 'rgb'), _this__u8e3s4.j92(_this__u8e3s4.o92(_this__u8e3s4.i92(), 'rgb'), _this__u8e3s4.o92(Companion_getInstance_51().sl1_1, 'rgb')));
    }
  }
  xlu(_this__u8e3s4, out, uniform, light) {
    if (light instanceof LightColor) {
      _this__u8e3s4.f92(out, uniform.ul9());
    } else {
      if (light instanceof LightTexture) {
        _this__u8e3s4.f92(out, _this__u8e3s4.m92([_this__u8e3s4.o92(_this__u8e3s4.q92(uniform.ql9(), _this__u8e3s4.o92(Companion_getInstance_51().vl1_1, 'xy')), 'rgb'), _this__u8e3s4.l92(1.0)]));
      } else {
        var message = 'Unsupported MateriaList: ' + toString_0(light);
        throw IllegalStateException.m5(toString_0(message));
      }
    }
  }
  ylu(_this__u8e3s4, light, out) {
    var v = Companion_getInstance_51().tl1_1;
    var N = Companion_getInstance_51().ul1_1;
    var L = _this__u8e3s4.u93(VarType_Float3_getInstance());
    var E = _this__u8e3s4.u93(VarType_Float3_getInstance());
    var R = _this__u8e3s4.u93(VarType_Float3_getInstance());
    var attenuation = _this__u8e3s4.u93(VarType_Float1_getInstance());
    var dist = _this__u8e3s4.u93(VarType_Float1_getInstance());
    var NdotL = _this__u8e3s4.u93(VarType_Float1_getInstance());
    var lightDir = _this__u8e3s4.u93(VarType_Float3_getInstance());
    _this__u8e3s4.f92(L, _this__u8e3s4.r94(_this__u8e3s4.g95(_this__u8e3s4.o92(light.wl7(), 'xyz'), v)));
    _this__u8e3s4.f92(E, _this__u8e3s4.r94(_this__u8e3s4.f95(v)));
    _this__u8e3s4.f92(R, _this__u8e3s4.r94(_this__u8e3s4.f95(_this__u8e3s4.s94(L, N))));
    var constantAttenuation = _this__u8e3s4.a95(light.ol8());
    var linearAttenuation = _this__u8e3s4.b95(light.ol8());
    var quadraticAttenuation = _this__u8e3s4.c95(light.ol8());
    _this__u8e3s4.f92(lightDir, _this__u8e3s4.g95(_this__u8e3s4.o92(light.wl7(), 'xyz'), Companion_getInstance_51().tl1_1));
    _this__u8e3s4.f92(dist, _this__u8e3s4.p94(lightDir));
    _this__u8e3s4.f92(attenuation, _this__u8e3s4.u92(_this__u8e3s4.l92(1.0), _this__u8e3s4.i95(_this__u8e3s4.i95(constantAttenuation, _this__u8e3s4.j92(linearAttenuation, dist)), _this__u8e3s4.j92(_this__u8e3s4.j92(quadraticAttenuation, dist), dist))));
    _this__u8e3s4.f92(NdotL, _this__u8e3s4.m94(_this__u8e3s4.q94(_this__u8e3s4.r94(N), _this__u8e3s4.r94(lightDir)), _this__u8e3s4.l92(0.0)));
    // Inline function 'korlibs.graphics.shader.Builder.IF' call
    var cond = _this__u8e3s4.s95(NdotL, _this__u8e3s4.l92(0.0));
    var body = _this__u8e3s4.w92();
    // Inline function 'korlibs.korge3d.material.StandardShader3D.addLight.<anonymous>' call
    body.f92(body.o92(out, 'rgb'), body.i95(body.o92(out, 'rgb'), body.j92(body.j92(body.i95(body.j92(body.o92(light.nhu(), 'rgb'), NdotL), body.o92(K3DPropsUB_getInstance().hlu(), 'rgb')), attenuation), K3DPropsUB_getInstance().flu())));
    var stmIf = new If(cond, body.y92());
    // Inline function 'kotlin.collections.plusAssign' call
    _this__u8e3s4.e92_1.f(stmIf);
  }
  toString() {
    return 'StandardShader3D(nlights=' + this.nlu_1 + ', nweights=' + this.olu_1 + ', meshMaterial=' + toString(this.plu_1) + ', hasTexture=' + this.qlu_1 + ', njoints=' + this.rlu_1 + ', hasVertexColor=' + this.slu_1 + ')';
  }
  hashCode() {
    var result = this.nlu_1;
    result = imul(result, 31) + this.olu_1 | 0;
    result = imul(result, 31) + (this.plu_1 == null ? 0 : this.plu_1.hashCode()) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.qlu_1) | 0;
    result = imul(result, 31) + this.rlu_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.slu_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof StandardShader3D))
      return false;
    var tmp0_other_with_cast = other instanceof StandardShader3D ? other : THROW_CCE();
    if (!(this.nlu_1 === tmp0_other_with_cast.nlu_1))
      return false;
    if (!(this.olu_1 === tmp0_other_with_cast.olu_1))
      return false;
    if (!equals(this.plu_1, tmp0_other_with_cast.plu_1))
      return false;
    if (!(this.qlu_1 === tmp0_other_with_cast.qlu_1))
      return false;
    if (!(this.rlu_1 === tmp0_other_with_cast.rlu_1))
      return false;
    if (!(this.slu_1 === tmp0_other_with_cast.slu_1))
      return false;
    return true;
  }
}
class RenderContext3D_0 {
  constructor() {
    var tmp = this;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.collections.arrayListOf' call
    var this_0 = ArrayList.m1();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.RenderContext3D.polylines.<anonymous>' call
    this_0.f(new DoubleVectorArrayList(3));
    tmp.zlu_1 = this_0;
    this.alv_1 = Companion_getInstance_8().c2x_1;
  }
  slv(p) {
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.zlu_1;
    // Inline function 'kotlin.also' call
    var this_1 = new DoubleVectorArrayList(3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.RenderContext3D.moveTo.<anonymous>' call
    this_1.b3f(p.k2x_1, p.l2x_1, p.m2x_1);
    this_0.f(this_1);
    this.alv_1 = p;
  }
  tlv(p) {
    last_0(this.zlu_1).b3f(p.k2x_1, p.l2x_1, p.m2x_1);
    this.alv_1 = p;
  }
}
class Polyline3D extends ViewWithMesh3D {
  static rlv(color) {
    color = color === VOID ? Colors_getInstance().g4r_1 : color;
    var $this = this.yla(Companion_getInstance_15().el0_1);
    $this.qlv_1 = color;
    return $this;
  }
}
//endregion
function addUpdater(_this__u8e3s4, updatable) {
  return addUpdater_0(_this__u8e3s4, true, VOID, updatable);
}
function addUpdater_0(_this__u8e3s4, first, firstTime, updatable) {
  first = first === VOID ? true : first;
  firstTime = firstTime === VOID ? Companion_getInstance().ik_1 : firstTime;
  if (first)
    updatable(_this__u8e3s4, new Duration(firstTime));
  var tmp = Companion_instance;
  return _this__u8e3s4.k8a(tmp, addUpdater$lambda(updatable, _this__u8e3s4));
}
function addUpdater$lambda($updatable, $this_addUpdater) {
  return function (it) {
    // Inline function 'korlibs.time.times' call
    var this_0 = it.fat();
    var scale = $this_addUpdater.kcb();
    var tmp$ret$0 = Duration__times_impl_sfuzvp(this_0, scale);
    $updatable($this_addUpdater, new Duration(tmp$ret$0));
    return Unit_instance;
  };
}
function update($this) {
  var fx = _Angle___get_cosine__impl__vprvmu($this.bky_1) * _Angle___get_cosine__impl__vprvmu($this.cky_1);
  var fy = _Angle___get_sine__impl__15nmyi($this.cky_1);
  var fz = _Angle___get_sine__impl__15nmyi($this.bky_1) * _Angle___get_cosine__impl__vprvmu($this.cky_1);
  $this.fky_1.x4f(fx, fy, fz).b4g();
  $this.jky_1.d4g($this.fky_1, $this.gky_1).b4g();
  $this.hky_1.d4g($this.jky_1, $this.fky_1).b4g();
  var tx = $this.aky_1.w2p_1 + $this.fky_1.n4f();
  var ty = $this.aky_1.x2p_1 + $this.fky_1.p4f();
  var tz = $this.aky_1.y2p_1 + $this.fky_1.r4f();
  $this.zkv_1.jkz($this.aky_1.w2p_1, $this.aky_1.x2p_1, $this.aky_1.y2p_1, tx, ty, tz);
  $this.hbh();
}
function removeFromParent(_this__u8e3s4) {
  var tmp1_safe_receiver = _this__u8e3s4.w15();
  if (tmp1_safe_receiver == null)
    null;
  else {
    tmp1_safe_receiver.al0(_this__u8e3s4);
  }
}
function container3D(_this__u8e3s4, block) {
  // Inline function 'korlibs.korge3d.addTo' call
  var this_0 = Container3D.zkz();
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  block(this_1);
  return this_1;
}
function Mesh3D$Companion$EMPTY$lambda($this$MeshBuilder3D) {
  return Unit_instance;
}
var Companion_instance_4;
function Companion_getInstance_15() {
  if (Companion_instance_4 === VOID)
    new Companion();
  return Companion_instance_4;
}
var Companion_instance_5;
function Companion_getInstance_16() {
  return Companion_instance_5;
}
function RenderContext3D$dynamicVertexBufferPool$lambda(it) {
  return new AGBuffer();
}
function RenderContext3D$dynamicVertexDataPool$lambda(it) {
  return AGVertexData.w8t();
}
function RenderContext3D$dynamicIndexBufferPool$lambda(it) {
  return new AGBuffer();
}
function RenderContext3D$textureManager$delegate$lambda(this$0) {
  return function () {
    return new AgBitmapTextureManager(this$0.saq());
  };
}
function RenderContext3D$bufferManager$delegate$lambda(this$0) {
  return function () {
    return new AgBufferManager(this$0.saq());
  };
}
function Stage3DView$renderInternal$lambda(this$0) {
  return function (it) {
    var tmp;
    if (it instanceof Light3D) {
      var tmp_0;
      if (it.wkv_1) {
        this$0.jl5_1.sl2_1.f(it);
        tmp_0 = Unit_instance;
      }
      tmp = tmp_0;
    }
    return Unit_instance;
  };
}
var Companion_instance_6;
function Companion_getInstance_17() {
  if (Companion_instance_6 === VOID)
    new Companion_1();
  return Companion_instance_6;
}
function updateTRS($this) {
  $this.tky_1 = false;
  $this.gl6().y4h($this.zky_1, Ref($this.kl6()), $this.bkz_1);
  $this.ckz_1 = true;
  $this.tky_1 = false;
}
function position(_this__u8e3s4, x, y, z, w) {
  w = w === VOID ? 1 : w;
  return position_1(_this__u8e3s4, x, y, z, w);
}
function addTo(_this__u8e3s4, container) {
  container.bl0(_this__u8e3s4);
  return _this__u8e3s4;
}
function set_position(_this__u8e3s4, value) {
  position_0(_this__u8e3s4, value);
}
function position_0(_this__u8e3s4, pos) {
  return position_1(_this__u8e3s4, pos.k2x_1, pos.l2x_1, pos.m2x_1);
}
function foreachDescendant(_this__u8e3s4, handler) {
  if (!(_this__u8e3s4 == null)) {
    handler(_this__u8e3s4);
    if (_this__u8e3s4 instanceof Container3D) {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var this_0 = _this__u8e3s4.s16();
      var n = 0;
      while (n < this_0.j1()) {
        // Inline function 'korlibs.korge3d.foreachDescendant.<anonymous>' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        var child = this_0.i1(_unary__edvuaz);
        foreachDescendant(child, handler);
      }
    }
  }
}
function rotation(_this__u8e3s4, quat) {
  _this__u8e3s4.zkv_1.ul6(quat);
  _this__u8e3s4.hbh();
  return _this__u8e3s4;
}
function position_1(_this__u8e3s4, x, y, z, w) {
  w = w === VOID ? 1.0 : w;
  _this__u8e3s4.zkv_1.tl6(x, y, z, w);
  _this__u8e3s4.hbh();
  return _this__u8e3s4;
}
function descendants(_this__u8e3s4) {
  return sequence(descendants$slambda_0(_this__u8e3s4));
}
function scale(_this__u8e3s4, x, y, z, w) {
  x = x === VOID ? 1.0 : x;
  y = y === VOID ? x : y;
  z = z === VOID ? x : z;
  w = w === VOID ? 1.0 : w;
  _this__u8e3s4.zkv_1.vl6(x, y, z, w);
  return _this__u8e3s4;
}
function *_generator_invoke__zhh2q8($this, $this$sequence, $completion) {
  var tmp0_elvis_lhs = $this.pl8_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var view = tmp;
  var tmp_0 = $this$sequence.sm(view, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  if (view instanceof Container3D) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = view.s16();
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.descendants.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var it = this_0.i1(_unary__edvuaz);
      var tmp_1 = $this$sequence.um(descendants(it), $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
    }
  }
  return Unit_instance;
}
function descendants$slambda_0($this_descendants) {
  var i = new descendants$slambda($this_descendants);
  var l = function ($this$sequence, $completion) {
    return i.ql8($this$sequence, $completion);
  };
  l.$arity = 1;
  return l;
}
var Companion_instance_7;
function Companion_getInstance_18() {
  if (Companion_instance_7 === VOID)
    new Companion_2();
  return Companion_instance_7;
}
var Companion_instance_8;
function Companion_getInstance_19() {
  return Companion_instance_8;
}
var $serializer_instance;
function $serializer_getInstance() {
  if ($serializer_instance === VOID)
    new $serializer();
  return $serializer_instance;
}
var Companion_instance_9;
function Companion_getInstance_20() {
  return Companion_instance_9;
}
var $serializer_instance_0;
function $serializer_getInstance_0() {
  if ($serializer_instance_0 === VOID)
    new $serializer_0();
  return $serializer_instance_0;
}
var Companion_instance_10;
function Companion_getInstance_21() {
  return Companion_instance_10;
}
var $serializer_instance_1;
function $serializer_getInstance_1() {
  if ($serializer_instance_1 === VOID)
    new $serializer_1();
  return $serializer_instance_1;
}
function GLTF2$Node$mmatrix$delegate$lambda(this$0) {
  return function () {
    var out = Companion_getInstance_9().s2p_1;
    var tmp;
    if (!(this$0.elc_1 == null)) {
      out = Companion_getInstance_9().d2q(this$0.elc_1);
      tmp = Unit_instance;
    }
    var tmp_0;
    if (!(this$0.clc_1 == null)) {
      out = out.j2r(Companion_getInstance_9().h2q(this$0.clc_1[0], this$0.clc_1[1], this$0.clc_1[2]));
      tmp_0 = Unit_instance;
    }
    var tmp_1;
    if (!(this$0.dlc_1 == null)) {
      out = out.j2r(Quaternion.f2s(this$0.dlc_1[0], this$0.dlc_1[1], this$0.dlc_1[2], this$0.dlc_1[3]).k2s());
      tmp_1 = Unit_instance;
    }
    var tmp_2;
    if (!(this$0.blc_1 == null)) {
      out = out.j2r(Companion_getInstance_9().j2q(this$0.blc_1[0], this$0.blc_1[1], this$0.blc_1[2]));
      tmp_2 = Unit_instance;
    }
    return out;
  };
}
function GLTF2$Node$_init_$lambda_rh2074(this$0) {
  return function () {
    var out = Companion_getInstance_9().s2p_1;
    var tmp;
    if (!(this$0.elc_1 == null)) {
      out = Companion_getInstance_9().d2q(this$0.elc_1);
      tmp = Unit_instance;
    }
    var tmp_0;
    if (!(this$0.clc_1 == null)) {
      out = out.j2r(Companion_getInstance_9().h2q(this$0.clc_1[0], this$0.clc_1[1], this$0.clc_1[2]));
      tmp_0 = Unit_instance;
    }
    var tmp_1;
    if (!(this$0.dlc_1 == null)) {
      out = out.j2r(Quaternion.f2s(this$0.dlc_1[0], this$0.dlc_1[1], this$0.dlc_1[2], this$0.dlc_1[3]).k2s());
      tmp_1 = Unit_instance;
    }
    var tmp_2;
    if (!(this$0.blc_1 == null)) {
      out = out.j2r(Companion_getInstance_9().j2q(this$0.blc_1[0], this$0.blc_1[1], this$0.blc_1[2]));
      tmp_2 = Unit_instance;
    }
    return out;
  };
}
var Companion_instance_11;
function Companion_getInstance_22() {
  if (Companion_instance_11 === VOID)
    new Companion_6();
  return Companion_instance_11;
}
var $serializer_instance_2;
function $serializer_getInstance_2() {
  if ($serializer_instance_2 === VOID)
    new $serializer_2();
  return $serializer_instance_2;
}
function _PrimitiveAttribute___init__impl__43i1o4(str) {
  return str;
}
function _PrimitiveAttribute___get_str__impl__zd1b7k($this) {
  return $this;
}
var Companion_instance_12;
function Companion_getInstance_23() {
  if (Companion_instance_12 === VOID)
    new Companion_7();
  return Companion_instance_12;
}
function _PrimitiveAttribute___get_index__impl__9edhvj($this) {
  var lc = last(_PrimitiveAttribute___get_str__impl__zd1b7k($this));
  return (_Char___init__impl__6a9atx(48) <= lc ? lc <= _Char___init__impl__6a9atx(57) : false) ? Char__minus_impl_a2frrh(lc, _Char___init__impl__6a9atx(48)) : 0;
}
function _PrimitiveAttribute___get_isPosition__impl__o3zd2($this) {
  return _PrimitiveAttribute___get_str__impl__zd1b7k($this) === 'POSITION';
}
function _PrimitiveAttribute___get_isNormal__impl__6yj138($this) {
  return _PrimitiveAttribute___get_str__impl__zd1b7k($this) === 'NORMAL';
}
function _PrimitiveAttribute___get_isTangent__impl__s8gm6i($this) {
  return _PrimitiveAttribute___get_str__impl__zd1b7k($this) === 'TANGENT';
}
function _PrimitiveAttribute___get_isColor0__impl__ug1pvu($this) {
  return _PrimitiveAttribute___get_str__impl__zd1b7k($this) === 'COLOR_0';
}
function _PrimitiveAttribute___get_isTexcoord__impl__w6mcmj($this) {
  return startsWith(_PrimitiveAttribute___get_str__impl__zd1b7k($this), 'TEXCOORD', true);
}
function PrimitiveAttribute__isTexcoord_impl_m3qlxc($this, n) {
  return _PrimitiveAttribute___get_isTexcoord__impl__w6mcmj($this) && _PrimitiveAttribute___get_index__impl__9edhvj($this) === n;
}
function _PrimitiveAttribute___get_isJoints__impl__ihpbra($this) {
  return startsWith(_PrimitiveAttribute___get_str__impl__zd1b7k($this), 'JOINTS', true);
}
function PrimitiveAttribute__isJoints_impl_lsaysl($this, n) {
  return _PrimitiveAttribute___get_isJoints__impl__ihpbra($this) && _PrimitiveAttribute___get_index__impl__9edhvj($this) === n;
}
function _PrimitiveAttribute___get_isWeights__impl__h7ak74($this) {
  return startsWith(_PrimitiveAttribute___get_str__impl__zd1b7k($this), 'WEIGHTS', true);
}
function PrimitiveAttribute__isWeights_impl_ctkpe7($this, n) {
  return _PrimitiveAttribute___get_isWeights__impl__h7ak74($this) && _PrimitiveAttribute___get_index__impl__9edhvj($this) === n;
}
var $serializer_instance_3;
function $serializer_getInstance_3() {
  if ($serializer_instance_3 === VOID)
    new $serializer_3();
  return $serializer_instance_3;
}
function PrimitiveAttribute__toString_impl_lmy2fg($this) {
  return 'PrimitiveAttribute(str=' + $this + ')';
}
function PrimitiveAttribute__hashCode_impl_47udf($this) {
  return getStringHashCode($this);
}
function PrimitiveAttribute__equals_impl_l2x0rd($this, other) {
  if (!(other instanceof PrimitiveAttribute))
    return false;
  if (!($this === (other instanceof PrimitiveAttribute ? other.eld_1 : THROW_CCE())))
    return false;
  return true;
}
var Companion_instance_13;
function Companion_getInstance_24() {
  if (Companion_instance_13 === VOID)
    new Companion_8();
  return Companion_instance_13;
}
var $serializer_instance_4;
function $serializer_getInstance_4() {
  if ($serializer_instance_4 === VOID)
    new $serializer_4();
  return $serializer_instance_4;
}
var Companion_instance_14;
function Companion_getInstance_25() {
  return Companion_instance_14;
}
var $serializer_instance_5;
function $serializer_getInstance_5() {
  if ($serializer_instance_5 === VOID)
    new $serializer_5();
  return $serializer_instance_5;
}
var Companion_instance_15;
function Companion_getInstance_26() {
  return Companion_instance_15;
}
var $serializer_instance_6;
function $serializer_getInstance_6() {
  if ($serializer_instance_6 === VOID)
    new $serializer_6();
  return $serializer_instance_6;
}
function _get_$cachedSerializer__te6jhj($this) {
  return $this.jle_1.b2();
}
function GLTF2$Animation$Channel$TargetPath$Companion$_anonymous__6vqjkf() {
  return TargetPathSerializer_instance;
}
var TargetPath_TRANSLATION_instance;
var TargetPath_ROTATION_instance;
var TargetPath_SCALE_instance;
var TargetPath_WEIGHTS_instance;
var TargetPath_UNKNOWN_instance;
var Companion_instance_16;
function Companion_getInstance_27() {
  TargetPath_initEntries();
  if (Companion_instance_16 === VOID)
    new Companion_11();
  return Companion_instance_16;
}
function values_0() {
  return [TargetPath_TRANSLATION_getInstance(), TargetPath_ROTATION_getInstance(), TargetPath_SCALE_getInstance(), TargetPath_WEIGHTS_getInstance(), TargetPath_UNKNOWN_getInstance()];
}
var TargetPath_entriesInitialized;
function TargetPath_initEntries() {
  if (TargetPath_entriesInitialized)
    return Unit_instance;
  TargetPath_entriesInitialized = true;
  TargetPath_TRANSLATION_instance = new TargetPath('TRANSLATION', 0, 'translation');
  TargetPath_ROTATION_instance = new TargetPath('ROTATION', 1, 'rotation');
  TargetPath_SCALE_instance = new TargetPath('SCALE', 2, 'scale');
  TargetPath_WEIGHTS_instance = new TargetPath('WEIGHTS', 3, 'weights');
  TargetPath_UNKNOWN_instance = new TargetPath('UNKNOWN', 4, 'unknown');
  Companion_getInstance_27();
}
var TargetPathSerializer_instance;
function TargetPathSerializer_getInstance() {
  return TargetPathSerializer_instance;
}
var Companion_instance_17;
function Companion_getInstance_28() {
  return Companion_instance_17;
}
var $serializer_instance_7;
function $serializer_getInstance_7() {
  if ($serializer_instance_7 === VOID)
    new $serializer_7();
  return $serializer_instance_7;
}
function TargetPath_TRANSLATION_getInstance() {
  TargetPath_initEntries();
  return TargetPath_TRANSLATION_instance;
}
function TargetPath_ROTATION_getInstance() {
  TargetPath_initEntries();
  return TargetPath_ROTATION_instance;
}
function TargetPath_SCALE_getInstance() {
  TargetPath_initEntries();
  return TargetPath_SCALE_instance;
}
function TargetPath_WEIGHTS_getInstance() {
  TargetPath_initEntries();
  return TargetPath_WEIGHTS_instance;
}
function TargetPath_UNKNOWN_getInstance() {
  TargetPath_initEntries();
  return TargetPath_UNKNOWN_instance;
}
var LookupKind_NORMAL_instance;
var LookupKind_QUATERNION_instance;
var LookupKind_entriesInitialized;
function LookupKind_initEntries() {
  if (LookupKind_entriesInitialized)
    return Unit_instance;
  LookupKind_entriesInitialized = true;
  LookupKind_NORMAL_instance = new LookupKind('NORMAL', 0);
  LookupKind_QUATERNION_instance = new LookupKind('QUATERNION', 1);
}
var Companion_instance_18;
function Companion_getInstance_29() {
  return Companion_instance_18;
}
var $serializer_instance_8;
function $serializer_getInstance_8() {
  if ($serializer_instance_8 === VOID)
    new $serializer_8();
  return $serializer_instance_8;
}
function LookupKind_NORMAL_getInstance() {
  LookupKind_initEntries();
  return LookupKind_NORMAL_instance;
}
function LookupKind_QUATERNION_getInstance() {
  LookupKind_initEntries();
  return LookupKind_QUATERNION_instance;
}
var Companion_instance_19;
function Companion_getInstance_30() {
  if (Companion_instance_19 === VOID)
    new Companion_14();
  return Companion_instance_19;
}
var $serializer_instance_9;
function $serializer_getInstance_9() {
  if ($serializer_instance_9 === VOID)
    new $serializer_9();
  return $serializer_instance_9;
}
var Companion_instance_20;
function Companion_getInstance_31() {
  return Companion_instance_20;
}
var $serializer_instance_10;
function $serializer_getInstance_10() {
  if ($serializer_instance_10 === VOID)
    new $serializer_10();
  return $serializer_instance_10;
}
var Companion_instance_21;
function Companion_getInstance_32() {
  return Companion_instance_21;
}
var $serializer_instance_11;
function $serializer_getInstance_11() {
  if ($serializer_instance_11 === VOID)
    new $serializer_11();
  return $serializer_instance_11;
}
function _get_$cachedSerializer__te6jhj_0($this) {
  return $this.vli_1.b2();
}
function GLTF2$AccessorType$Companion$_anonymous__ofqqqt() {
  return createSimpleEnumSerializer('korlibs.korge3d.format.gltf2.GLTF2.AccessorType', values_1());
}
var AccessorType_SCALAR_instance;
var AccessorType_VEC2_instance;
var AccessorType_VEC3_instance;
var AccessorType_VEC4_instance;
var AccessorType_MAT2_instance;
var AccessorType_MAT3_instance;
var AccessorType_MAT4_instance;
function values_1() {
  return [AccessorType_SCALAR_getInstance(), AccessorType_VEC2_getInstance(), AccessorType_VEC3_getInstance(), AccessorType_VEC4_getInstance(), AccessorType_MAT2_getInstance(), AccessorType_MAT3_getInstance(), AccessorType_MAT4_getInstance()];
}
var Companion_instance_22;
function Companion_getInstance_33() {
  AccessorType_initEntries();
  if (Companion_instance_22 === VOID)
    new Companion_17();
  return Companion_instance_22;
}
var AccessorType_entriesInitialized;
function AccessorType_initEntries() {
  if (AccessorType_entriesInitialized)
    return Unit_instance;
  AccessorType_entriesInitialized = true;
  AccessorType_SCALAR_instance = new AccessorType('SCALAR', 0, 1);
  AccessorType_VEC2_instance = new AccessorType('VEC2', 1, 2);
  AccessorType_VEC3_instance = new AccessorType('VEC3', 2, 3);
  AccessorType_VEC4_instance = new AccessorType('VEC4', 3, 4);
  AccessorType_MAT2_instance = new AccessorType('MAT2', 4, 4);
  AccessorType_MAT3_instance = new AccessorType('MAT3', 5, 9);
  AccessorType_MAT4_instance = new AccessorType('MAT4', 6, 16);
  Companion_getInstance_33();
}
var Companion_instance_23;
function Companion_getInstance_34() {
  if (Companion_instance_23 === VOID)
    new Companion_18();
  return Companion_instance_23;
}
var $serializer_instance_12;
function $serializer_getInstance_12() {
  if ($serializer_instance_12 === VOID)
    new $serializer_12();
  return $serializer_instance_12;
}
var Companion_instance_24;
function Companion_getInstance_35() {
  return Companion_instance_24;
}
var $serializer_instance_13;
function $serializer_getInstance_13() {
  if ($serializer_instance_13 === VOID)
    new $serializer_13();
  return $serializer_instance_13;
}
function _get_$cachedSerializer__te6jhj_1($this) {
  return $this.klj_1.b2();
}
function GLTF2$Material$BaseTextureInfo$Companion$_anonymous__rh9kxh() {
  var tmp = getKClass(BaseTextureInfo);
  // Inline function 'kotlin.arrayOf' call
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$2 = [];
  return PolymorphicSerializer.ljg(tmp, tmp$ret$2);
}
var Companion_instance_25;
function Companion_getInstance_36() {
  if (Companion_instance_25 === VOID)
    new Companion_20();
  return Companion_instance_25;
}
var Companion_instance_26;
function Companion_getInstance_37() {
  return Companion_instance_26;
}
var $serializer_instance_14;
function $serializer_getInstance_14() {
  if ($serializer_instance_14 === VOID)
    new $serializer_14();
  return $serializer_instance_14;
}
var Companion_instance_27;
function Companion_getInstance_38() {
  return Companion_instance_27;
}
var $serializer_instance_15;
function $serializer_getInstance_15() {
  if ($serializer_instance_15 === VOID)
    new $serializer_15();
  return $serializer_instance_15;
}
var Companion_instance_28;
function Companion_getInstance_39() {
  return Companion_instance_28;
}
var $serializer_instance_16;
function $serializer_getInstance_16() {
  if ($serializer_instance_16 === VOID)
    new $serializer_16();
  return $serializer_instance_16;
}
var Companion_instance_29;
function Companion_getInstance_40() {
  return Companion_instance_29;
}
var $serializer_instance_17;
function $serializer_getInstance_17() {
  if ($serializer_instance_17 === VOID)
    new $serializer_17();
  return $serializer_instance_17;
}
var Companion_instance_30;
function Companion_getInstance_41() {
  return Companion_instance_30;
}
var $serializer_instance_18;
function $serializer_getInstance_18() {
  if ($serializer_instance_18 === VOID)
    new $serializer_18();
  return $serializer_instance_18;
}
var Companion_instance_31;
function Companion_getInstance_42() {
  return Companion_instance_31;
}
var $serializer_instance_19;
function $serializer_getInstance_19() {
  if ($serializer_instance_19 === VOID)
    new $serializer_19();
  return $serializer_instance_19;
}
var Companion_instance_32;
function Companion_getInstance_43() {
  return Companion_instance_32;
}
var $serializer_instance_20;
function $serializer_getInstance_20() {
  if ($serializer_instance_20 === VOID)
    new $serializer_20();
  return $serializer_instance_20;
}
var Companion_instance_33;
function Companion_getInstance_44() {
  return Companion_instance_33;
}
var $serializer_instance_21;
function $serializer_getInstance_21() {
  if ($serializer_instance_21 === VOID)
    new $serializer_21();
  return $serializer_instance_21;
}
var Companion_instance_34;
function Companion_getInstance_45() {
  return Companion_instance_34;
}
var $serializer_instance_22;
function $serializer_getInstance_22() {
  if ($serializer_instance_22 === VOID)
    new $serializer_22();
  return $serializer_instance_22;
}
var Companion_instance_35;
function Companion_getInstance_46() {
  return Companion_instance_35;
}
var $serializer_instance_23;
function $serializer_getInstance_23() {
  if ($serializer_instance_23 === VOID)
    new $serializer_23();
  return $serializer_instance_23;
}
function *_generator_readGLB__9p6tt9($this, file, options, $completion) {
  var tmp = file.w22($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = $this.mln(tmp, file, options, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return tmp_0;
}
function *_generator_readGLTF__gkftvf($this, jsonString, bin, file, options, $completion) {
  var tmp;
  try {
    // Inline function 'kotlin.also' call
    // Inline function 'kotlinx.serialization.json.Json.decodeFromString' call
    var this_0 = Json(VOID, GLTF2$Companion$readGLTF$lambda(options));
    // Inline function 'kotlinx.serialization.serializer' call
    var this_1 = this_0.ejk();
    // Inline function 'kotlinx.serialization.internal.cast' call
    var this_2 = serializer(this_1, createKType(getKClass(GLTF2), arrayOf([]), false));
    var tmp$ret$1 = isInterface(this_2, KSerializer) ? this_2 : THROW_CCE();
    var this_3 = this_0.zjw(tmp$ret$1, jsonString);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.format.gltf2.Companion.readGLTF.<anonymous>' call
    var tmp_0 = ensureLoad(this_3, file, bin, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = this_3;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      println('options=' + options.toString());
      println('ERROR parsing: ' + jsonString);
      throw e;
    } else {
      throw $p;
    }
  }
  return tmp;
}
function GLTF2$Companion$readGLTF$lambda($options) {
  return function ($this$Json) {
    $this$Json.pjx_1 = $options.nln_1;
    return Unit_instance;
  };
}
function *_generator_ensureLoad__vtxj5o($this, file, bin, $completion) {
  var tmp = ensureLoadBuffers($this, file, bin, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = ensureLoadImages($this, file, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function ensureLoad($this, file, bin, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_ensureLoad__vtxj5o($this, file, bin, $completion), $completion);
}
function *_generator_ensureLoadBuffers__wre539($this, file, bin, $completion) {
  var _iterator__ex2g4s = $this.ilg_1.f1();
  while (_iterator__ex2g4s.g1()) {
    var buffer = _iterator__ex2g4s.h1();
    if (buffer.ili_1 == null) {
      var tmp128_safe_receiver = buffer.eli_1;
      var tmp;
      if (tmp128_safe_receiver == null) {
        tmp = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.ensureLoadBuffers.<anonymous>' call
        tmp = $this.oln(file, tmp128_safe_receiver);
      }
      var tmp129_safe_receiver = tmp;
      var tmp_0;
      if (tmp129_safe_receiver == null) {
        tmp_0 = null;
      } else {
        var tmp_1 = tmp129_safe_receiver.w22($completion);
        if (tmp_1 === get_COROUTINE_SUSPENDED())
          tmp_1 = yield tmp_1;
        tmp_0 = tmp_1;
      }
      var vfile = tmp_0;
      // Inline function 'korlibs.time.measureTimeWithResult' call
      // Inline function 'kotlin.time.measureTimedValue' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.time.measureTimedValue' call
      // Inline function 'kotlin.contracts.contract' call
      var mark = Monotonic_instance.bk();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.ensureLoadBuffers.<anonymous>' call
      var tmp1_elvis_lhs = vfile == null ? bin : vfile;
      var tmp_2;
      if (tmp1_elvis_lhs == null) {
        var message = "Couldn't load buffer : " + buffer.toString();
        throw IllegalStateException.m5(toString_0(message));
      } else {
        tmp_2 = tmp1_elvis_lhs;
      }
      var result = tmp_2;
      var _destruct__k2r9zo = new TimedValue(result, ValueTimeMark__elapsedNow_impl_eonqvs(mark));
      var bytes = _destruct__k2r9zo.km();
      var time = _destruct__k2r9zo.ur();
      println('Loaded ' + toString(vfile) + ' in ... ' + Duration__toString_impl_8d916b(time));
      buffer.ili_1 = Buffer.f13(bytes);
    }
  }
  return Unit_instance;
}
function ensureLoadBuffers($this, file, bin, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_ensureLoadBuffers__wre539($this, file, bin, $completion), $completion);
}
function *_generator_ensureLoadImages__3p96ss($this, file, $completion) {
  var _iterator__ex2g4s = $this.blg_1.f1();
  while (_iterator__ex2g4s.g1()) {
    var image = _iterator__ex2g4s.h1();
    if (image.ulk_1 == null) {
      var tmp130_safe_receiver = image.plk_1;
      var tmp;
      if (tmp130_safe_receiver == null) {
        tmp = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.ensureLoadImages.<anonymous>' call
        tmp = $this.oln(file, tmp130_safe_receiver);
      }
      var vfile = tmp;
      // Inline function 'korlibs.time.measureTimeWithResult' call
      // Inline function 'kotlin.time.measureTimedValue' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.time.measureTimedValue' call
      // Inline function 'kotlin.contracts.contract' call
      var mark = Monotonic_instance.bk();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.ensureLoadImages.<anonymous>' call
      var result = vfile == null ? image.qlk_1 >= 0 ? asMemoryVfsFile(Int8Buffer__getArray$default_impl_hlbc10(get_i8($this.jlg_1.i1(image.qlk_1).pln($this)))) : null : vfile;
      var _destruct__k2r9zo = new TimedValue(result, ValueTimeMark__elapsedNow_impl_eonqvs(mark));
      var buffer = _destruct__k2r9zo.km();
      var time = _destruct__k2r9zo.ur();
      // Inline function 'korlibs.time.measureTimeWithResult' call
      // Inline function 'kotlin.time.measureTimedValue' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.time.measureTimedValue' call
      // Inline function 'kotlin.contracts.contract' call
      var mark_0 = Monotonic_instance.bk();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.ensureLoadImages.<anonymous>' call
      var tmp_0;
      if (buffer == null) {
        tmp_0 = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.ensureLoadImages.<anonymous>.<anonymous>' call
        var tmp_1 = get_nativeImageFormatProvider().q6k(buffer, VOID, $completion);
        if (tmp_1 === get_COROUTINE_SUSPENDED())
          tmp_1 = yield tmp_1;
        tmp_0 = tmp_1;
      }
      var tmp0_elvis_lhs = tmp_0;
      var tmp_2;
      if (tmp0_elvis_lhs == null) {
        // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
        var tmp$ret$8 = get_Bitmaps_transparent();
        tmp_2 = get_bmp(tmp$ret$8);
      } else {
        tmp_2 = tmp0_elvis_lhs;
      }
      var result_0 = tmp_2;
      var _destruct__k2r9zo_0 = new TimedValue(result_0, ValueTimeMark__elapsedNow_impl_eonqvs(mark_0));
      var bitmap = _destruct__k2r9zo_0.km();
      var timeBitmap = _destruct__k2r9zo_0.ur();
      println(toString(vfile) + ' read in ' + Duration__toString_impl_8d916b(time) + ', decoded in ' + Duration__toString_impl_8d916b(timeBitmap) + '...');
      image.ulk_1 = bitmap;
    }
  }
  return Unit_instance;
}
function ensureLoadImages($this, file, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_ensureLoadImages__3p96ss($this, file, $completion), $completion);
}
var Companion_instance_36;
function Companion_getInstance_47() {
  if (Companion_instance_36 === VOID)
    new Companion_31();
  return Companion_instance_36;
}
var $serializer_instance_24;
function $serializer_getInstance_24() {
  if ($serializer_instance_24 === VOID)
    new $serializer_24();
  return $serializer_instance_24;
}
function GLTF2$materials3D$delegate$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.collections.map' call
    var this_0 = this$0.llg_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.materials3D$delegate.<anonymous>.<anonymous>' call
      var gMetallicRoughness = item.lll_1;
      var tmp0_elvis_lhs = gMetallicRoughness.clj_1;
      var baseColorFactor = tmp0_elvis_lhs == null ? new Float32Array(0) : tmp0_elvis_lhs;
      var tmp126_safe_receiver = gMetallicRoughness.dlj_1;
      var tmp;
      if (tmp126_safe_receiver == null) {
        tmp = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.materials3D$delegate.<anonymous>.<anonymous>.<anonymous>' call
        tmp = new LightTexture(tmp126_safe_receiver.mlk(this$0));
      }
      var tmp1_elvis_lhs = tmp;
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        var tmp_1 = Companion_getInstance_12();
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_2;
        if (0 <= 0 ? 0 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_2 = baseColorFactor[0];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.materials3D$delegate.<anonymous>.<anonymous>.<anonymous>' call
          tmp_2 = 1.0;
        }
        var tmp_3 = tmp_2;
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_4;
        if (0 <= 1 ? 1 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_4 = baseColorFactor[1];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.materials3D$delegate.<anonymous>.<anonymous>.<anonymous>' call
          tmp_4 = 1.0;
        }
        var tmp_5 = tmp_4;
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_6;
        if (0 <= 2 ? 2 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_6 = baseColorFactor[2];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.materials3D$delegate.<anonymous>.<anonymous>.<anonymous>' call
          tmp_6 = 1.0;
        }
        var tmp_7 = tmp_6;
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_8;
        if (0 <= 3 ? 3 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_8 = baseColorFactor[3];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.materials3D$delegate.<anonymous>.<anonymous>.<anonymous>' call
          tmp_8 = 1.0;
        }
        var tmp$ret$9 = tmp_8;
        tmp_0 = new LightColor(tmp_1.e53(tmp_3, tmp_5, tmp_7, tmp$ret$9));
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      var tmp_9 = tmp_0;
      var tmp127_safe_receiver = item.nll_1;
      var tmp_10;
      if (tmp127_safe_receiver == null) {
        tmp_10 = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.materials3D$delegate.<anonymous>.<anonymous>.<anonymous>' call
        tmp_10 = tmp127_safe_receiver.mlk(this$0.glo());
      }
      var tmp$ret$12 = new PBRMaterial3D(VOID, VOID, tmp_9, VOID, VOID, VOID, tmp_10, item.ill_1);
      destination.f(tmp$ret$12);
    }
    return destination;
  };
}
function GLTF2$_init_$lambda_rrepww(this$0) {
  return function () {
    // Inline function 'kotlin.collections.map' call
    var this_0 = this$0.llg_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.<init>.<anonymous>.<anonymous>' call
      var gMetallicRoughness = item.lll_1;
      var tmp0_elvis_lhs = gMetallicRoughness.clj_1;
      var baseColorFactor = tmp0_elvis_lhs == null ? new Float32Array(0) : tmp0_elvis_lhs;
      var tmp126_safe_receiver = gMetallicRoughness.dlj_1;
      var tmp;
      if (tmp126_safe_receiver == null) {
        tmp = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.<init>.<anonymous>.<anonymous>.<anonymous>' call
        tmp = new LightTexture(tmp126_safe_receiver.mlk(this$0));
      }
      var tmp1_elvis_lhs = tmp;
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        var tmp_1 = Companion_getInstance_12();
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_2;
        if (0 <= 0 ? 0 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_2 = baseColorFactor[0];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.<init>.<anonymous>.<anonymous>.<anonymous>' call
          tmp_2 = 1.0;
        }
        var tmp_3 = tmp_2;
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_4;
        if (0 <= 1 ? 1 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_4 = baseColorFactor[1];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.<init>.<anonymous>.<anonymous>.<anonymous>' call
          tmp_4 = 1.0;
        }
        var tmp_5 = tmp_4;
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_6;
        if (0 <= 2 ? 2 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_6 = baseColorFactor[2];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.<init>.<anonymous>.<anonymous>.<anonymous>' call
          tmp_6 = 1.0;
        }
        var tmp_7 = tmp_6;
        // Inline function 'kotlin.collections.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_8;
        if (0 <= 3 ? 3 <= (baseColorFactor.length - 1 | 0) : false) {
          tmp_8 = baseColorFactor[3];
        } else {
          // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.<init>.<anonymous>.<anonymous>.<anonymous>' call
          tmp_8 = 1.0;
        }
        var tmp$ret$9 = tmp_8;
        tmp_0 = new LightColor(tmp_1.e53(tmp_3, tmp_5, tmp_7, tmp$ret$9));
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      var tmp_9 = tmp_0;
      var tmp127_safe_receiver = item.nll_1;
      var tmp_10;
      if (tmp127_safe_receiver == null) {
        tmp_10 = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge3d.format.gltf2.GLTF2.<init>.<anonymous>.<anonymous>.<anonymous>' call
        tmp_10 = tmp127_safe_receiver.mlk(this$0.glo());
      }
      var tmp$ret$12 = new PBRMaterial3D(VOID, VOID, tmp_9, VOID, VOID, VOID, tmp_10, item.ill_1);
      destination.f(tmp$ret$12);
    }
    return destination;
  };
}
function AccessorType_SCALAR_getInstance() {
  AccessorType_initEntries();
  return AccessorType_SCALAR_instance;
}
function AccessorType_VEC2_getInstance() {
  AccessorType_initEntries();
  return AccessorType_VEC2_instance;
}
function AccessorType_VEC3_getInstance() {
  AccessorType_initEntries();
  return AccessorType_VEC3_instance;
}
function AccessorType_VEC4_getInstance() {
  AccessorType_initEntries();
  return AccessorType_VEC4_instance;
}
function AccessorType_MAT2_getInstance() {
  AccessorType_initEntries();
  return AccessorType_MAT2_instance;
}
function AccessorType_MAT3_getInstance() {
  AccessorType_initEntries();
  return AccessorType_MAT3_instance;
}
function AccessorType_MAT4_getInstance() {
  AccessorType_initEntries();
  return AccessorType_MAT4_instance;
}
function *_generator_readGLTF2__gdubzj(_this__u8e3s4, options, $completion) {
  options = options === VOID ? Companion_getInstance_18().clb_1 : options;
  if (get_extensionLC(_this__u8e3s4) === 'glb') {
    var tmp = readGLB(_this__u8e3s4, options, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    return tmp;
  }
  var tmp_0 = Companion_getInstance_47();
  var tmp_1 = _this__u8e3s4.y22(VOID, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var tmp_2 = tmp_0.dlo(tmp_1, null, _this__u8e3s4, options, $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  return tmp_2;
}
function readGLTF2(_this__u8e3s4, options, $completion) {
  options = options === VOID ? Companion_getInstance_18().clb_1 : options;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readGLTF2__gdubzj(_this__u8e3s4, options, $completion), $completion);
}
function _get_$cachedSerializer__te6jhj_2($this) {
  return $this.ilo_1.b2();
}
function GLTFProperty$Companion$_anonymous__zfetnn() {
  var tmp = getKClass(GLTFProperty);
  // Inline function 'kotlin.arrayOf' call
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$2 = [];
  return PolymorphicSerializer.ljg(tmp, tmp$ret$2);
}
var Companion_instance_37;
function Companion_getInstance_48() {
  if (Companion_instance_37 === VOID)
    new Companion_32();
  return Companion_instance_37;
}
function readGLB(_this__u8e3s4, options, $completion) {
  options = options === VOID ? Companion_getInstance_18().clb_1 : options;
  return Companion_getInstance_47().clo(_this__u8e3s4, options, $completion);
}
function childrenNodes(_this__u8e3s4, gltf) {
  var tmp148_safe_receiver = _this__u8e3s4.alc_1;
  var tmp;
  if (tmp148_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(tmp148_safe_receiver.length);
    var inductionVariable = 0;
    var last = tmp148_safe_receiver.length;
    while (inductionVariable < last) {
      var item = tmp148_safe_receiver[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.korge3d.format.gltf2.childrenNodes.<anonymous>' call
      var tmp$ret$0 = gltf.elg_1.i1(item);
      destination.f(tmp$ret$0);
    }
    tmp = destination;
  }
  return tmp;
}
function childrenNodes_0(_this__u8e3s4, gltf) {
  var tmp149_safe_receiver = _this__u8e3s4.qlb_1;
  var tmp;
  if (tmp149_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(tmp149_safe_receiver.length);
    var inductionVariable = 0;
    var last = tmp149_safe_receiver.length;
    while (inductionVariable < last) {
      var item = tmp149_safe_receiver[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.korge3d.format.gltf2.childrenNodes.<anonymous>' call
      var tmp$ret$0 = gltf.elg_1.i1(item);
      destination.f(tmp$ret$0);
    }
    tmp = destination;
  }
  return tmp;
}
function _GLTF2AccessorVectorMAT4___init__impl__q6vlls(vec) {
  return vec;
}
function _GLTF2AccessorVectorMAT4___get_vec__impl__5bahrj($this) {
  return $this;
}
function GLTF2AccessorVectorMAT4__get_impl_c4fgc0($this, index) {
  var tmp = Companion_getInstance_9();
  var tmp_0 = 0;
  var tmp_1 = new Float32Array(16);
  while (tmp_0 < 16) {
    var tmp_2 = tmp_0;
    tmp_1[tmp_2] = _GLTF2AccessorVectorMAT4___get_vec__impl__5bahrj($this).z2k(index, tmp_2);
    tmp_0 = tmp_0 + 1 | 0;
  }
  return tmp.d2q(tmp_1);
}
function materials3D$factory() {
  return getPropertyCallableRef('materials3D', 1, KProperty1, function (receiver) {
    return receiver.hlo();
  }, null);
}
function mmatrix$factory() {
  return getPropertyCallableRef('mmatrix', 1, KProperty1, function (receiver) {
    return receiver.qln();
  }, null);
}
function *_generator_invoke__zhh2q8_0($this, $this$sequence, $completion) {
  var _iterator__ex2g4s = descendants($this.rlo_1).f1();
  while (_iterator__ex2g4s.g1()) {
    var it = _iterator__ex2g4s.h1();
    if (it.ykv_1 === $this.slo_1) {
      var tmp = $this$sequence.sm(it, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
    }
  }
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_1($this, $this$sequence, $completion) {
  var _iterator__ex2g4s = descendants($this.tlo_1).f1();
  while (_iterator__ex2g4s.g1()) {
    var it = _iterator__ex2g4s.h1();
    if (it.ykv_1 === $this.ulo_1) {
      var tmp = $this$sequence.sm(it, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
    }
  }
  return Unit_instance;
}
function GLTF2ViewSkin$writeFrom$slambda_1($this, $name) {
  var i = new GLTF2ViewSkin$writeFrom$slambda($this, $name);
  var l = function ($this$sequence, $completion) {
    return i.ql8($this$sequence, $completion);
  };
  l.$arity = 1;
  return l;
}
function GLTF2ViewSkin$writeFrom$slambda_2($this, $name) {
  var i = new GLTF2ViewSkin$writeFrom$slambda_0($this, $name);
  var l = function ($this$sequence, $completion) {
    return i.ql8($this$sequence, $completion);
  };
  l.$arity = 1;
  return l;
}
function GLTF2View$viewSkins$delegate$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.collections.map' call
    var this_0 = this$0.olp_1.glg_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge3d.format.gltf2.GLTF2View.viewSkins$delegate.<anonymous>.<anonymous>' call
      var tmp$ret$0 = this$0.clq(item);
      destination.f(tmp$ret$0);
    }
    return destination;
  };
}
function GLTF2View$lambda($this$addUpdater, it) {
  $this$addUpdater.dlq(it.hk_1);
  return Unit_instance;
}
function gltf2View(_this__u8e3s4, gltf, autoAnimate) {
  autoAnimate = autoAnimate === VOID ? true : autoAnimate;
  return addTo(GLTF2View.elq(gltf, autoAnimate), _this__u8e3s4);
}
var Companion_instance_38;
function Companion_getInstance_49() {
  return Companion_instance_38;
}
function viewSkins$factory() {
  return getPropertyCallableRef('viewSkins', 1, KProperty1, function (receiver) {
    return receiver.ylq();
  }, null);
}
var vector3DTemps;
function toNBuffer(_this__u8e3s4) {
  _init_properties_InternalExt_kt__wjpaxa();
  return toNBuffer_1(_this__u8e3s4.c31());
}
function toNBuffer_0(_this__u8e3s4) {
  _init_properties_InternalExt_kt__wjpaxa();
  return toNBuffer_2(_this__u8e3s4.v32());
}
function toNBuffer_1(_this__u8e3s4) {
  _init_properties_InternalExt_kt__wjpaxa();
  // Inline function 'kotlin.also' call
  var this_0 = allocDirect(Companion_instance_3, imul(_this__u8e3s4.length, 4));
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge3d.internal.toNBuffer.<anonymous>' call
  setArrayFloat32(this_0, 0, _this__u8e3s4, 0, _this__u8e3s4.length);
  return this_0;
}
function toNBuffer_2(_this__u8e3s4) {
  _init_properties_InternalExt_kt__wjpaxa();
  // Inline function 'kotlin.also' call
  var this_0 = allocDirect(Companion_instance_3, imul(_this__u8e3s4.length, 2));
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge3d.internal.toNBuffer.<anonymous>' call
  setArrayInt16(this_0, 0, _this__u8e3s4, 0, _this__u8e3s4.length);
  return this_0;
}
var properties_initialized_InternalExt_kt_two1as;
function _init_properties_InternalExt_kt__wjpaxa() {
  if (!properties_initialized_InternalExt_kt_two1as) {
    properties_initialized_InternalExt_kt_two1as = true;
    vector3DTemps = new Vector3DTemps();
  }
}
var Companion_instance_39;
function Companion_getInstance_50() {
  if (Companion_instance_39 === VOID)
    new Companion_34();
  return Companion_instance_39;
}
function Shaders3D$Bones4UB$u_BoneMats$delegate$lambda() {
  return Bones4UB_getInstance().f8z();
}
var K3DPropsUB_instance;
function K3DPropsUB_getInstance() {
  if (K3DPropsUB_instance === VOID)
    new K3DPropsUB();
  return K3DPropsUB_instance;
}
var Bones4UB_instance;
function Bones4UB_getInstance() {
  if (Bones4UB_instance === VOID)
    new Bones4UB();
  return Bones4UB_instance;
}
var Companion_instance_40;
function Companion_getInstance_51() {
  if (Companion_instance_40 === VOID)
    new Companion_35();
  return Companion_instance_40;
}
var WeightsUB_instance;
function WeightsUB_getInstance() {
  if (WeightsUB_instance === VOID)
    new WeightsUB();
  return WeightsUB_instance;
}
function BaseShader3D$program$delegate$lambda(this$0) {
  return function () {
    // Inline function 'korlibs.graphics.shader.VertexShader' call
    var builder = ProgramBuilderDefault.a92();
    // Inline function 'korlibs.korge3d.material.BaseShader3D.program$delegate.<anonymous>.<anonymous>' call
    this$0.vlu(builder);
    var tmp = VertexShader(builder.n92());
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_0 = ProgramBuilderDefault.a92();
    // Inline function 'korlibs.korge3d.material.BaseShader3D.program$delegate.<anonymous>.<anonymous>' call
    this$0.wlu(builder_0);
    var tmp$ret$1 = FragmentShader(builder_0.n92());
    return new Program(tmp, tmp$ret$1, toString_0(this$0));
  };
}
function get(_this__u8e3s4, index) {
  return ArrayAccess.n9q(_this__u8e3s4, index);
}
function u_Shininess$factory() {
  return getPropertyCallableRef('u_Shininess', 1, KProperty1, function (receiver) {
    return receiver.flu();
  }, null);
}
function u_IndexOfRefraction$factory() {
  return getPropertyCallableRef('u_IndexOfRefraction', 1, KProperty1, function (receiver) {
    return receiver.glu();
  }, null);
}
function u_AmbientColor$factory() {
  return getPropertyCallableRef('u_AmbientColor', 1, KProperty1, function (receiver) {
    return receiver.hlu();
  }, null);
}
function u_OcclusionStrength$factory() {
  return getPropertyCallableRef('u_OcclusionStrength', 1, KProperty1, function (receiver) {
    return receiver.ml7();
  }, null);
}
function u_BindShapeMatrix$factory() {
  return getPropertyCallableRef('u_BindShapeMatrix', 1, KProperty1, function (receiver) {
    return receiver.ilu();
  }, null);
}
function u_BindShapeMatrixInv$factory() {
  return getPropertyCallableRef('u_BindShapeMatrixInv', 1, KProperty1, function (receiver) {
    return receiver.jlu();
  }, null);
}
function u_ModMat$factory() {
  return getPropertyCallableRef('u_ModMat', 1, KProperty1, function (receiver) {
    return receiver.ll7();
  }, null);
}
function u_NormMat$factory() {
  return getPropertyCallableRef('u_NormMat', 1, KProperty1, function (receiver) {
    return receiver.kl7();
  }, null);
}
function u_Shininess$factory_0() {
  return getPropertyCallableRef('u_Shininess', 1, KProperty1, function (receiver) {
    return receiver.flu();
  }, null);
}
function u_IndexOfRefraction$factory_0() {
  return getPropertyCallableRef('u_IndexOfRefraction', 1, KProperty1, function (receiver) {
    return receiver.glu();
  }, null);
}
function u_AmbientColor$factory_0() {
  return getPropertyCallableRef('u_AmbientColor', 1, KProperty1, function (receiver) {
    return receiver.hlu();
  }, null);
}
function u_OcclusionStrength$factory_0() {
  return getPropertyCallableRef('u_OcclusionStrength', 1, KProperty1, function (receiver) {
    return receiver.ml7();
  }, null);
}
function u_BindShapeMatrix$factory_0() {
  return getPropertyCallableRef('u_BindShapeMatrix', 1, KProperty1, function (receiver) {
    return receiver.ilu();
  }, null);
}
function u_BindShapeMatrixInv$factory_0() {
  return getPropertyCallableRef('u_BindShapeMatrixInv', 1, KProperty1, function (receiver) {
    return receiver.jlu();
  }, null);
}
function u_ModMat$factory_0() {
  return getPropertyCallableRef('u_ModMat', 1, KProperty1, function (receiver) {
    return receiver.ll7();
  }, null);
}
function u_NormMat$factory_0() {
  return getPropertyCallableRef('u_NormMat', 1, KProperty1, function (receiver) {
    return receiver.kl7();
  }, null);
}
function u_BoneMats$factory() {
  return getPropertyCallableRef('u_BoneMats', 1, KProperty1, function (receiver) {
    return receiver.alq();
  }, null);
}
function u_BoneMats$factory_0() {
  return getPropertyCallableRef('u_BoneMats', 1, KProperty1, function (receiver) {
    return receiver.alq();
  }, null);
}
function u_OcclussionTexUnit$factory() {
  return getPropertyCallableRef('u_OcclussionTexUnit', 1, KProperty1, function (receiver) {
    return receiver.ila();
  }, null);
}
function u_SourcePos$factory() {
  return getPropertyCallableRef('u_SourcePos', 1, KProperty1, function (receiver) {
    return receiver.wl7();
  }, null);
}
function u_Color$factory() {
  return getPropertyCallableRef('u_Color', 1, KProperty1, function (receiver) {
    return receiver.nhu();
  }, null);
}
function u_Attenuation$factory() {
  return getPropertyCallableRef('u_Attenuation', 1, KProperty1, function (receiver) {
    return receiver.ol8();
  }, null);
}
function u_SourcePos$factory_0() {
  return getPropertyCallableRef('u_SourcePos', 1, KProperty1, function (receiver) {
    return receiver.wl7();
  }, null);
}
function u_Color$factory_0() {
  return getPropertyCallableRef('u_Color', 1, KProperty1, function (receiver) {
    return receiver.nhu();
  }, null);
}
function u_Attenuation$factory_0() {
  return getPropertyCallableRef('u_Attenuation', 1, KProperty1, function (receiver) {
    return receiver.ol8();
  }, null);
}
function u_color$factory() {
  return getPropertyCallableRef('u_color', 1, KProperty1, function (receiver) {
    return receiver.ul9();
  }, null);
}
function u_color$factory_0() {
  return getPropertyCallableRef('u_color', 1, KProperty1, function (receiver) {
    return receiver.ul9();
  }, null);
}
function u_texUnit$factory() {
  return getPropertyCallableRef('u_texUnit', 1, KProperty1, function (receiver) {
    return receiver.ql9();
  }, null);
}
function u_Weights$factory() {
  return getPropertyCallableRef('u_Weights', 1, KProperty1, function (receiver) {
    return receiver.chh();
  }, null);
}
function u_Weights$factory_0() {
  return getPropertyCallableRef('u_Weights', 1, KProperty1, function (receiver) {
    return receiver.chh();
  }, null);
}
function program$factory() {
  return getPropertyCallableRef('program', 1, KProperty1, function (receiver) {
    return receiver.llu();
  }, null);
}
function axisLines(_this__u8e3s4, basePosition, length, lengthWhiteScale) {
  basePosition = basePosition === VOID ? Companion_getInstance_8().c2x_1 : basePosition;
  length = length === VOID ? 10.0 : length;
  lengthWhiteScale = lengthWhiteScale === VOID ? 0.25 : lengthWhiteScale;
  var ll = length;
  var l2 = length * lengthWhiteScale;
  return container3D(_this__u8e3s4, axisLines$lambda(basePosition, ll, l2));
}
function axisLines$lambda$lambda($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines$lambda$lambda_0($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines$lambda$lambda_1($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines$lambda$lambda_2($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines$lambda($basePosition, $ll, $l2) {
  return function ($this$container3D) {
    position_0($this$container3D, $basePosition);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color = Colors_getInstance().r59('#e20050');
    // Inline function 'kotlin.also' call
    var this_0 = Polyline3D.rlv(color);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_1 = new RenderContext3D_0();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.axisLines.<anonymous>.<anonymous>' call
    this_1.slv(Vector3F.n2x(-$ll, 0.0, 0.0));
    this_1.tlv(Vector3F.n2x($ll, 0.0, 0.0));
    var ctx = this_1;
    var tmp = this_0;
    // Inline function 'kotlin.also' call
    var tmp_0 = Companion_instance_5;
    var tmp_1 = Companion_getInstance_0().k8u_1;
    var this_2 = tmp_0.ul0(tmp_1, axisLines$lambda$lambda(ctx));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_2.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_0.qlv_1));
    tmp.wla_1 = this_2;
    addTo(this_0, $this$container3D);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color_0 = Colors_getInstance().z4u_1;
    // Inline function 'kotlin.also' call
    var this_3 = Polyline3D.rlv(color_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_4 = new RenderContext3D_0();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.axisLines.<anonymous>.<anonymous>' call
    this_4.slv(Companion_getInstance_8().j2x_1.e2r($ll));
    this_4.tlv(Companion_getInstance_8().i2x_1.e2r($ll));
    var ctx_0 = this_4;
    var tmp_2 = this_3;
    // Inline function 'kotlin.also' call
    var tmp_3 = Companion_instance_5;
    var tmp_4 = Companion_getInstance_0().k8u_1;
    var this_5 = tmp_3.ul0(tmp_4, axisLines$lambda$lambda_0(ctx_0));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_5.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_3.qlv_1));
    tmp_2.wla_1 = this_5;
    addTo(this_3, $this$container3D);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color_1 = Colors_getInstance().r59('#8cb04d');
    // Inline function 'kotlin.also' call
    var this_6 = Polyline3D.rlv(color_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_7 = new RenderContext3D_0();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.axisLines.<anonymous>.<anonymous>' call
    this_7.slv(Vector3F.n2x(0.0, 0.0, -$ll));
    this_7.tlv(Vector3F.n2x(0.0, 0.0, $ll));
    var ctx_1 = this_7;
    var tmp_5 = this_6;
    // Inline function 'kotlin.also' call
    var tmp_6 = Companion_instance_5;
    var tmp_7 = Companion_getInstance_0().k8u_1;
    var this_8 = tmp_6.ul0(tmp_7, axisLines$lambda$lambda_1(ctx_1));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_8.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_6.qlv_1));
    tmp_5.wla_1 = this_8;
    addTo(this_6, $this$container3D);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color_2 = Colors_getInstance().g4r_1;
    // Inline function 'kotlin.also' call
    var this_9 = Polyline3D.rlv(color_2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_10 = new RenderContext3D_0();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.axisLines.<anonymous>.<anonymous>' call
    this_10.slv(Vector3F.n2x(0.0, 0.0, 0.0));
    this_10.tlv(Vector3F.n2x($l2, 0.0, 0.0));
    this_10.slv(Vector3F.n2x(0.0, 0.0, 0.0));
    this_10.tlv(Vector3F.n2x(0.0, $l2, 0.0));
    this_10.slv(Vector3F.n2x(0.0, 0.0, 0.0));
    this_10.tlv(Vector3F.n2x(0.0, 0.0, $l2));
    var ctx_2 = this_10;
    var tmp_8 = this_9;
    // Inline function 'kotlin.also' call
    var tmp_9 = Companion_instance_5;
    var tmp_10 = Companion_getInstance_0().k8u_1;
    var this_11 = tmp_9.ul0(tmp_10, axisLines$lambda$lambda_2(ctx_2));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_11.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_9.qlv_1));
    tmp_8.wla_1 = this_11;
    addTo(this_9, $this$container3D);
    return Unit_instance;
  };
}
function get_immutable_0(_this__u8e3s4) {
  return new Vector4F(_this__u8e3s4.n4f(), _this__u8e3s4.p4f(), _this__u8e3s4.r4f(), _this__u8e3s4.t4f());
}
//region block: post-declaration
initMetadataForClass(View3D, 'View3D');
initMetadataForClass(Camera3D, 'Camera3D');
initMetadataForClass(Perspective, 'Perspective', Perspective.ikx);
initMetadataForClass(Container3D, 'Container3D', Container3D.zkz);
initMetadataForClass(Light3D, 'Light3D');
initMetadataForCompanion(Companion);
initMetadataForClass(Mesh3D, 'Mesh3D');
initMetadataForClass(BufferWithVertexLayout, 'BufferWithVertexLayout');
initMetadataForCompanion(Companion_0);
initMetadataForClass(MeshBuilder3D, 'MeshBuilder3D', MeshBuilder3D);
initMetadataForClass(RenderContext3D, 'RenderContext3D', RenderContext3D);
initMetadataForClass(Stage3DView, 'Stage3DView');
initMetadataForClass(Stage3D, 'Stage3D');
initMetadataForClass(Views3D, 'Views3D');
initMetadataForCompanion(Companion_1);
initMetadataForClass(Transform3D, 'Transform3D', Transform3D);
initMetadataForLambda(descendants$slambda, VOID, VOID, [1]);
initMetadataForClass(ViewWithMaterial3D, 'ViewWithMaterial3D');
initMetadataForClass(ViewWithMesh3D, 'ViewWithMesh3D');
initMetadataForCompanion(Companion_2);
initMetadataForCompanion(Companion_3);
protoOf($serializer).vjp = typeParametersSerializers;
initMetadataForObject($serializer, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_4);
protoOf($serializer_0).vjp = typeParametersSerializers;
initMetadataForObject($serializer_0, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_5);
protoOf($serializer_1).vjp = typeParametersSerializers;
initMetadataForObject($serializer_1, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_6);
protoOf($serializer_2).vjp = typeParametersSerializers;
initMetadataForObject($serializer_2, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_7);
protoOf($serializer_3).vjp = typeParametersSerializers;
initMetadataForObject($serializer_3, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_8);
protoOf($serializer_4).vjp = typeParametersSerializers;
initMetadataForObject($serializer_4, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_9);
protoOf($serializer_5).vjp = typeParametersSerializers;
initMetadataForObject($serializer_5, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_10);
protoOf($serializer_6).vjp = typeParametersSerializers;
initMetadataForObject($serializer_6, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_11, VOID, [SerializerFactory]);
initMetadataForClass(GLTFProperty, 'GLTFProperty', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_48});
initMetadataForClass(Target, 'Target', Target, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_6});
initMetadataForClass(TargetPath, 'TargetPath', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_27});
initMetadataForObject(TargetPathSerializer, 'TargetPathSerializer', VOID, VOID, [KSerializer]);
initMetadataForCompanion(Companion_12);
protoOf($serializer_7).vjp = typeParametersSerializers;
initMetadataForObject($serializer_7, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(Lookup, 'Lookup', Lookup);
initMetadataForClass(LookupKind, 'LookupKind');
initMetadataForCompanion(Companion_13);
protoOf($serializer_8).vjp = typeParametersSerializers;
initMetadataForObject($serializer_8, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(Channel, 'Channel', Channel, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_7});
initMetadataForClass(Sampler_0, 'Sampler', Sampler_0, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_8});
initMetadataForCompanion(Companion_14);
protoOf($serializer_9).vjp = typeParametersSerializers;
initMetadataForObject($serializer_9, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_15);
protoOf($serializer_10).vjp = typeParametersSerializers;
initMetadataForObject($serializer_10, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_16);
protoOf($serializer_11).vjp = typeParametersSerializers;
initMetadataForObject($serializer_11, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_17, VOID, [SerializerFactory]);
initMetadataForCompanion(Companion_18);
protoOf($serializer_12).vjp = typeParametersSerializers;
initMetadataForObject($serializer_12, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_19);
protoOf($serializer_13).vjp = typeParametersSerializers;
initMetadataForObject($serializer_13, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_20, VOID, [SerializerFactory]);
initMetadataForCompanion(Companion_21);
protoOf($serializer_14).vjp = typeParametersSerializers;
initMetadataForObject($serializer_14, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_22);
protoOf($serializer_15).vjp = typeParametersSerializers;
initMetadataForObject($serializer_15, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_23);
protoOf($serializer_16).vjp = typeParametersSerializers;
initMetadataForObject($serializer_16, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(PBRMetallicRoughness, 'PBRMetallicRoughness', PBRMetallicRoughness, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_13});
initMetadataForClass(BaseTextureInfo, 'BaseTextureInfo', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_36});
initMetadataForClass(TextureInfo, 'TextureInfo', TextureInfo, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_14});
initMetadataForClass(OcclusionTextureInfo, 'OcclusionTextureInfo', OcclusionTextureInfo, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_15});
initMetadataForClass(NormalTextureInfo, 'NormalTextureInfo', NormalTextureInfo, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_16});
initMetadataForCompanion(Companion_24);
protoOf($serializer_17).vjp = typeParametersSerializers;
initMetadataForObject($serializer_17, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_25);
protoOf($serializer_18).vjp = typeParametersSerializers;
initMetadataForObject($serializer_18, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_26);
protoOf($serializer_19).vjp = typeParametersSerializers;
initMetadataForObject($serializer_19, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_27);
protoOf($serializer_20).vjp = typeParametersSerializers;
initMetadataForObject($serializer_20, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_28);
protoOf($serializer_21).vjp = typeParametersSerializers;
initMetadataForObject($serializer_21, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForCompanion(Companion_29);
protoOf($serializer_22).vjp = typeParametersSerializers;
initMetadataForObject($serializer_22, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(Orthographic, 'Orthographic', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_21});
initMetadataForClass(Perspective_0, 'Perspective', Perspective_0, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_22});
initMetadataForCompanion(Companion_30);
protoOf($serializer_23).vjp = typeParametersSerializers;
initMetadataForObject($serializer_23, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(ReadOptions, 'ReadOptions', ReadOptions);
initMetadataForClass(Asset, 'Asset', Asset, VOID, VOID, VOID, VOID, {0: $serializer_getInstance});
initMetadataForClass(Scene, 'Scene', Scene, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_0});
initMetadataForClass(Node, 'Node', Node, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_1});
initMetadataForClass(Mesh, 'Mesh', Mesh, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_2});
initMetadataForClass(PrimitiveAttribute, 'PrimitiveAttribute', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_3});
initMetadataForClass(Primitive, 'Primitive', Primitive, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_4});
initMetadataForClass(Skin, 'Skin', Skin, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_5});
initMetadataForClass(Animation, 'Animation', Animation, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_9});
initMetadataForClass(Buffer_0, 'Buffer', Buffer_0, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_10});
initMetadataForClass(BufferView, 'BufferView', BufferView, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_11});
initMetadataForClass(AccessorType, 'AccessorType', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_33});
initMetadataForClass(Accessor, 'Accessor', Accessor, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_12});
initMetadataForClass(Material, 'Material', Material, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_17});
initMetadataForClass(Texture, 'Texture', Texture, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_18});
initMetadataForClass(Image, 'Image', Image, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_19});
initMetadataForClass(Sampler_1, 'Sampler', Sampler_1, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_20});
initMetadataForClass(Camera, 'Camera', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_23});
initMetadataForCompanion(Companion_31, VOID, VOID, [2, 3, 4]);
protoOf($serializer_24).vjp = typeParametersSerializers;
initMetadataForObject($serializer_24, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForInterface(GLTF2Holder, 'GLTF2Holder');
initMetadataForClass(GLTF2, 'GLTF2', GLTF2, VOID, [GLTFProperty, GLTF2Holder], [2, 1], VOID, {0: $serializer_getInstance_24});
initMetadataForCompanion(Companion_32, VOID, [SerializerFactory]);
initMetadataForClass(GLTF2AccessorVector, 'GLTF2AccessorVector');
initMetadataForLambda(GLTF2ViewSkin$writeFrom$slambda, VOID, VOID, [1]);
initMetadataForLambda(GLTF2ViewSkin$writeFrom$slambda_0, VOID, VOID, [1]);
initMetadataForClass(GLTF2ViewSkin, 'GLTF2ViewSkin');
initMetadataForClass(GLTF2View, 'GLTF2View', VOID, VOID, [Container3D, GLTF2Holder]);
protoOf(GLTF2ViewNode).jlo = get_childrenNodes;
initMetadataForClass(GLTF2ViewNode, 'GLTF2ViewNode', VOID, VOID, [Container3D, GLTF2Holder]);
protoOf(GLTF2SceneView).klo = get_childrenNodes_0;
initMetadataForClass(GLTF2SceneView, 'GLTF2SceneView', VOID, VOID, [Container3D, GLTF2Holder]);
initMetadataForCompanion(Companion_33);
initMetadataForClass(GLTF2ViewPrimitive, 'GLTF2ViewPrimitive', VOID, VOID, [ViewWithMaterial3D, GLTF2Holder]);
initMetadataForClass(GLTF2ViewMesh, 'GLTF2ViewMesh');
initMetadataForClass(Vector3DTemps, 'Vector3DTemps', Vector3DTemps);
initMetadataForCompanion(Companion_34);
initMetadataForClass(Light, 'Light');
initMetadataForClass(LightColor, 'LightColor');
initMetadataForClass(LightTexture, 'LightTexture');
initMetadataForClass(PBRMaterial3D, 'PBRMaterial3D', PBRMaterial3D);
initMetadataForObject(K3DPropsUB, 'K3DPropsUB');
initMetadataForObject(Bones4UB, 'Bones4UB');
initMetadataForCompanion(Companion_35);
initMetadataForClass(LightUB, 'LightUB');
initMetadataForClass(MaterialUB, 'MaterialUB');
initMetadataForObject(WeightsUB, 'WeightsUB');
initMetadataForClass(Shaders3D, 'Shaders3D', Shaders3D);
initMetadataForClass(BaseShader3D, 'BaseShader3D');
initMetadataForClass(StandardShader3D, 'StandardShader3D');
initMetadataForClass(RenderContext3D_0, 'RenderContext3D', RenderContext3D_0);
initMetadataForClass(Polyline3D, 'Polyline3D', Polyline3D.rlv);
//endregion
//region block: init
Companion_instance_5 = new Companion_0();
Companion_instance_8 = new Companion_3();
Companion_instance_9 = new Companion_4();
Companion_instance_10 = new Companion_5();
Companion_instance_14 = new Companion_9();
Companion_instance_15 = new Companion_10();
TargetPathSerializer_instance = new TargetPathSerializer();
Companion_instance_17 = new Companion_12();
Companion_instance_18 = new Companion_13();
Companion_instance_20 = new Companion_15();
Companion_instance_21 = new Companion_16();
Companion_instance_24 = new Companion_19();
Companion_instance_26 = new Companion_21();
Companion_instance_27 = new Companion_22();
Companion_instance_28 = new Companion_23();
Companion_instance_29 = new Companion_24();
Companion_instance_30 = new Companion_25();
Companion_instance_31 = new Companion_26();
Companion_instance_32 = new Companion_27();
Companion_instance_33 = new Companion_28();
Companion_instance_34 = new Companion_29();
Companion_instance_35 = new Companion_30();
Companion_instance_38 = new Companion_33();
//endregion
//region block: exports
export {
  readGLTF2 as readGLTF23pahjsw3lsrt0,
  Companion_instance_5 as Companion_instance2neo4ifwb1va8,
  GLTF2View as GLTF2View27rsli4nemyyr,
  gltf2View as gltf2View2k8otfc3r0nfv,
  LightColor as LightColor39trwa64rf4qa,
  PBRMaterial3D as PBRMaterial3D2rz1eq17882bs,
  Polyline3D as Polyline3D641dlria96z3,
  RenderContext3D_0 as RenderContext3D3g9j9sw87j4iw,
  axisLines as axisLiness355wubr1txv,
  Perspective as Perspective2erinj849np0h,
  Stage3DView as Stage3DView1a3043g6rm78u,
  Views3D as Views3D1w8x3h72cqm4w,
  addTo as addTo1qty7sfviegib,
  addUpdater as addUpdater6igk22c2qvsi,
  container3D as container3D1e7tsx3qrm1iz,
  set_position as set_position24hx6qehuduzm,
  position as position3v5ajcdva76eg,
  position_0 as position11uq2wot88tb2,
  removeFromParent as removeFromParent134qrksv252z5,
};
//endregion
