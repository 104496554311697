import {
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  toString1pkumu07cwy4m as toString,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  RuntimeException1r3t0zl97011n as RuntimeException,
  VOID7hggqo3abtya as VOID,
  captureStack1fzi4aczwc4hg as captureStack,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  getKClass1s3j9wy1cofik as getKClass,
  Unit_instance104q5opgivhr8 as Unit_instance,
  ArrayList3it5z8td81qkl as ArrayList,
  isInterface3d6p8outrmvmk as isInterface,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  toString30pk9tzaqopn as toString_0,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  get6d5x931vk0s as get,
  protoOf180f3jzyo7rfj as protoOf,
  fold36i9psb7d5v48 as fold,
  minusKeyyqanvso9aovh as minusKey,
  plusolev77jfy5r9 as plus,
  Element2gr7ezmxqaln7 as Element,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class Companion {}
class RequestContext {
  constructor(initialClazz) {
    this.z86_1 = initialClazz;
  }
  toString() {
    return 'RequestContext(initialClazz=' + toString(this.z86_1) + ')';
  }
  hashCode() {
    return this.z86_1.hashCode();
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof RequestContext))
      return false;
    var tmp0_other_with_cast = other instanceof RequestContext ? other : THROW_CCE();
    if (!this.z86_1.equals(tmp0_other_with_cast.z86_1))
      return false;
    return true;
  }
}
class NotMappedException extends RuntimeException {
  static h87(clazz, requestedByClass, ctx, msg) {
    msg = msg === VOID ? 'Not mapped ' + toString(clazz) + ' requested by ' + toString(requestedByClass) + ' in ' + ctx.toString() : msg;
    var $this = this.db(msg);
    captureStack($this, $this.g87_1);
    $this.c87_1 = clazz;
    $this.d87_1 = requestedByClass;
    $this.e87_1 = ctx;
    $this.f87_1 = msg;
    return $this;
  }
}
class Injector {
  constructor(parent, level) {
    parent = parent === VOID ? null : parent;
    level = level === VOID ? 0 : level;
    this.i87_1 = parent;
    this.j87_1 = level;
    this.k87_1 = null;
    this.l87_1 = LinkedHashMap.vc();
    var tmp = this;
    var tmp3_safe_receiver = this.i87_1;
    var tmp0_elvis_lhs = tmp3_safe_receiver == null ? null : tmp3_safe_receiver.m87_1;
    tmp.m87_1 = tmp0_elvis_lhs == null ? this : tmp0_elvis_lhs;
    this.o87(getKClass(Injector), this);
    var tmp_0 = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp_0.n87_1 = ArrayList.m1();
  }
  p87() {
    var tmp0_elvis_lhs = this.k87_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp4_safe_receiver = this.i87_1;
      tmp = tmp4_safe_receiver == null ? null : tmp4_safe_receiver.k87_1;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  q87() {
    return new Injector(this, this.j87_1 + 1 | 0);
  }
  o87(clazz, instance) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.Injector.mapInstance.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.l87_1;
    var value = new InstanceObjectProvider(instance);
    this_0.r3(clazz, value);
    return this;
  }
  r87(clazz, gen) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.Injector.mapSingleton.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.l87_1;
    var value = new SingletonObjectProvider(gen);
    this_0.r3(clazz, value);
    return this;
  }
  s87(clazz, gen) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.Injector.mapPrototype.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.l87_1;
    var value = new PrototypeObjectProvider(gen);
    this_0.r3(clazz, value);
    return this;
  }
  t87(clazz, ctx) {
    var tmp0_elvis_lhs = this.l87_1.k3(clazz);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp7_safe_receiver = this.i87_1;
      tmp = tmp7_safe_receiver == null ? null : tmp7_safe_receiver.t87(clazz, ctx);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var tmp1_elvis_lhs = tmp;
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      var tmp8_safe_receiver = this.p87();
      var tmp9_safe_receiver = tmp8_safe_receiver == null ? null : tmp8_safe_receiver(clazz, ctx);
      var tmp_1;
      if (tmp9_safe_receiver == null) {
        tmp_1 = null;
      } else {
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.inject.Injector.getProviderOrNull.<anonymous>' call
        // Inline function 'kotlin.collections.set' call
        this.l87_1.r3(clazz, tmp9_safe_receiver);
        tmp_1 = tmp9_safe_receiver;
      }
      tmp_0 = tmp_1;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var tmp_2 = tmp_0;
    return (tmp_2 == null ? true : isInterface(tmp_2, ObjectProvider)) ? tmp_2 : null;
  }
  u87(clazz, ctx) {
    var tmp0_elvis_lhs = this.t87(clazz, ctx);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw NotMappedException.h87(clazz, ctx.z86_1, ctx, "Class '" + toString(clazz) + "' doesn't have constructors " + ctx.toString());
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  v87(clazz, ctx) {
    return this.u87(clazz, ctx).w87(this);
  }
  x87(clazz, ctx, $super) {
    ctx = ctx === VOID ? new RequestContext(clazz) : ctx;
    return $super === VOID ? this.v87(clazz, ctx) : $super.v87.call(this, clazz, ctx);
  }
  toString() {
    return 'Injector(level=' + this.j87_1 + ')';
  }
  y87(instance) {
    if (!(instance == null) ? isInterface(instance, InjectorDependency) : false) {
      instance.z87(this);
    }
    if (!(instance == null) ? isInterface(instance, InjectorDestructor) : false) {
      this.n87_1.f(instance);
    }
    return instance;
  }
  a88() {
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s = this.l87_1.z1().f1();
    while (_iterator__ex2g4s.g1()) {
      var pair = _iterator__ex2g4s.h1();
      pair.b2().a88();
    }
    var _iterator__ex2g4s_0 = this.n87_1.f1();
    while (_iterator__ex2g4s_0.g1()) {
      var deinit = _iterator__ex2g4s_0.h1();
      deinit.a88();
    }
    this.n87_1.o3();
  }
}
class InjectorDependency {}
class ObjectProvider {}
class InstanceObjectProvider {
  constructor(instance) {
    this.b88_1 = instance;
  }
  w87(injector) {
    return this.b88_1;
  }
  a88() {
    var tmp = this.b88_1;
    var tmp1_safe_receiver = (tmp == null ? true : isInterface(tmp, InjectorDestructor)) ? tmp : null;
    if (tmp1_safe_receiver == null)
      null;
    else {
      tmp1_safe_receiver.a88();
    }
  }
  toString() {
    return 'InstanceObjectProvider(' + toString_0(this.b88_1) + ')';
  }
}
class SingletonObjectProvider {
  constructor(generator) {
    this.c88_1 = generator;
    this.d88_1 = null;
  }
  w87(injector) {
    if (this.d88_1 == null)
      this.d88_1 = injector.y87(this.c88_1(injector));
    return ensureNotNull(this.d88_1);
  }
  a88() {
    var tmp = this.d88_1;
    var tmp0_safe_receiver = (tmp == null ? true : isInterface(tmp, InjectorDestructor)) ? tmp : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.a88();
    }
  }
  toString() {
    return 'SingletonObjectProvider(' + toString_0(this.d88_1) + ')';
  }
}
class PrototypeObjectProvider {
  constructor(generator) {
    this.e88_1 = generator;
  }
  w87(injector) {
    return injector.y87(this.e88_1(injector));
  }
  a88() {
    return Unit_instance;
  }
  toString() {
    return 'PrototypeObjectProvider()';
  }
}
class InjectorDestructor {}
class Companion_0 {}
class InjectorContext {
  constructor(injector) {
    this.f88_1 = injector;
  }
  a2() {
    return Companion_instance_0;
  }
}
//endregion
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
var Companion_instance_0;
function Companion_getInstance_0() {
  return Companion_instance_0;
}
function injector($completion) {
  // Inline function 'kotlin.js.getCoroutineContext' call
  var tmp11_safe_receiver = $completion.gd().td(Companion_instance_0);
  var tmp0_elvis_lhs = tmp11_safe_receiver == null ? null : tmp11_safe_receiver.f88_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var message = 'Injector not in the context, please call withInjector function';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForClass(RequestContext, 'RequestContext');
initMetadataForClass(NotMappedException, 'NotMappedException');
initMetadataForClass(Injector, 'Injector', Injector);
initMetadataForInterface(InjectorDependency, 'InjectorDependency');
initMetadataForInterface(ObjectProvider, 'ObjectProvider');
initMetadataForClass(InstanceObjectProvider, 'InstanceObjectProvider', VOID, VOID, [ObjectProvider]);
initMetadataForClass(SingletonObjectProvider, 'SingletonObjectProvider', VOID, VOID, [ObjectProvider]);
initMetadataForClass(PrototypeObjectProvider, 'PrototypeObjectProvider', VOID, VOID, [ObjectProvider]);
initMetadataForInterface(InjectorDestructor, 'InjectorDestructor');
initMetadataForCompanion(Companion_0);
protoOf(InjectorContext).td = get;
protoOf(InjectorContext).wn = fold;
protoOf(InjectorContext).vn = minusKey;
protoOf(InjectorContext).xn = plus;
initMetadataForClass(InjectorContext, 'InjectorContext', VOID, VOID, [Element]);
//endregion
//region block: init
Companion_instance = new Companion();
Companion_instance_0 = new Companion_0();
//endregion
//region block: exports
export {
  injector as injector13ltfovoybm52,
  InjectorContext as InjectorContextps0ngmjb9onz,
  InjectorDependency as InjectorDependency2f8dsqe1kn3nn,
  Injector as Injectorxjyp19tp5mfu,
};
//endregion
