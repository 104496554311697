import {
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  VOID7hggqo3abtya as VOID,
  createThis2j2avj17cvnv2 as createThis,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  equals2au1ep9vhcato as equals,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  protoOf180f3jzyo7rfj as protoOf,
  Comparable198qfk8pnblz0 as Comparable,
  Unit_instance104q5opgivhr8 as Unit_instance,
  Enum3alwj03lh1n41 as Enum,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  KtList3hktaavzmj137 as KtList,
  toSet2orjxp16sotqu as toSet,
  Collection1k04j3hzsbod0 as Collection,
  isInterface3d6p8outrmvmk as isInterface,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  isNaNymqb93xtq8w8 as isNaN_0,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  contentEquals379bkuxwxaiqz as contentEquals,
  contentHashCode2dpr73siofp13 as contentHashCode,
  arrayCopytctsywo3h7gj as arrayCopy,
  StringBuildermazzzhj6kkai as StringBuilder,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  numberToDouble210hrknaofnhf as numberToDouble,
  coerceIn10f36k81le1mm as coerceIn,
  minOfmf6w75l7u1a7 as minOf,
  maxOf1m19db8qhkqy5 as maxOf,
} from './kotlin-kotlin-stdlib.mjs';
import {
  toRatio1hooqit5gulhr as toRatio,
  interpolate35pbs3ac913y4 as interpolate,
  _Ratio___init__impl__9mwvn21rjrz0s2y877i as _Ratio___init__impl__9mwvn2,
  umod1d8wj61c81r0q as umod,
  isAlmostEquals2e7wogp48cvmj as isAlmostEquals,
  Ratio__toDouble_impl_o7epze3e3zsci3ipwe7 as Ratio__toDouble_impl_o7epze,
  Ratio__times_impl_ucdh7y1eb1bj32n3p46 as Ratio__times_impl_ucdh7y,
  Ratio__compareTo_impl_z2ienc1iwi3w3dhe1b7 as Ratio__compareTo_impl_z2ienc,
  roundDecimalPlaces1x32iuyd1l8m5 as roundDecimalPlaces,
  get_niceStr3crhxydmlnaoj as get_niceStr,
  isAlmostEquals$default1ua43koqhm6mp as isAlmostEquals$default,
  IsAlmostEquals2vs54x7i426j2 as IsAlmostEquals,
  interpolate2g7wzskc23f1q as interpolate_0,
  Ratio__toFloat_impl_1ftup5294anfb3s41c1 as Ratio__toFloat_impl_1ftup5,
  get_niceStr1tlwtz2l2j4my as get_niceStr_0,
} from './korlibs-korlibs-math-core.mjs';
//region block: imports
var imul = Math.imul;
var hypot = Math.hypot;
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    Companion_instance = this;
    this.p2g_1 = Anchor2D.a2h(0.0, 0.0);
    this.q2g_1 = Anchor2D.a2h(0.5, 0.0);
    this.r2g_1 = Anchor2D.a2h(1.0, 0.0);
    this.s2g_1 = Anchor2D.a2h(0.0, 0.5);
    this.t2g_1 = Anchor2D.a2h(0.5, 0.5);
    this.u2g_1 = Anchor2D.a2h(1.0, 0.5);
    this.v2g_1 = Anchor2D.a2h(0.0, 1.0);
    this.w2g_1 = Anchor2D.a2h(0.5, 1.0);
    this.x2g_1 = Anchor2D.a2h(1.0, 1.0);
  }
  b2h() {
    return this.q2g_1;
  }
  c2h() {
    return this.s2g_1;
  }
  d2h() {
    return this.u2g_1;
  }
  e2h() {
    return this.w2g_1;
  }
  f2h() {
    return this.t2g_1;
  }
}
class Anchor2D {
  static g2h(sx, sy) {
    Companion_getInstance();
    var $this = createThis(this);
    $this.y2g_1 = sx;
    $this.z2g_1 = sy;
    return $this;
  }
  h2h() {
    return Vector2D.k2h(this.y2g_1, this.z2g_1);
  }
  l2h() {
    return toRatio(this.y2g_1);
  }
  m2h() {
    return toRatio(this.z2g_1);
  }
  static a2h(sx, sy) {
    Companion_getInstance();
    return this.g2h(sx, sy);
  }
  n2h(ratio, other) {
    return Anchor2D.g2h(interpolate(ratio, this.y2g_1, other.y2g_1), interpolate(ratio, this.z2g_1, other.z2g_1));
  }
  d10(ratio, other) {
    return this.n2h(ratio, other instanceof Anchor2D ? other : THROW_CCE());
  }
  o2h() {
    return this.equals(Companion_getInstance().p2g_1) ? 'Anchor.TOP_LEFT' : this.equals(Companion_getInstance().b2h()) ? 'Anchor.TOP' : this.equals(Companion_getInstance().r2g_1) ? 'Anchor.TOP_RIGHT' : this.equals(Companion_getInstance().c2h()) ? 'Anchor.LEFT' : this.equals(Companion_getInstance().f2h()) ? 'Anchor.MIDDLE_CENTER' : this.equals(Companion_getInstance().d2h()) ? 'Anchor.RIGHT' : this.equals(Companion_getInstance().v2g_1) ? 'Anchor.BOTTOM_LEFT' : this.equals(Companion_getInstance().w2g_1) ? 'Anchor.BOTTOM_CENTER' : this.equals(Companion_getInstance().x2g_1) ? 'Anchor.BOTTOM_RIGHT' : this.toString();
  }
  toString() {
    return 'Anchor2D(sx=' + this.y2g_1 + ', sy=' + this.z2g_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.y2g_1);
    result = imul(result, 31) + getNumberHashCode(this.z2g_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Anchor2D))
      return false;
    var tmp0_other_with_cast = other instanceof Anchor2D ? other : THROW_CCE();
    if (!equals(this.y2g_1, tmp0_other_with_cast.y2g_1))
      return false;
    if (!equals(this.z2g_1, tmp0_other_with_cast.z2g_1))
      return false;
    return true;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    var tmp = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_0();
    var ratio = toRatio(1.0E-5);
    var tmp$ret$0 = Ratio__times_impl_ucdh7y(ratio, 6.283185307179586);
    tmp.q2h_1 = _Angle___init__impl__g23q1m(tmp$ret$0);
    var tmp_0 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_0();
    var ratio_0 = toRatio(0.0);
    var tmp$ret$2 = Ratio__times_impl_ucdh7y(ratio_0, 6.283185307179586);
    tmp_0.r2h_1 = _Angle___init__impl__g23q1m(tmp$ret$2);
    var tmp_1 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_0();
    var ratio_1 = toRatio(0.25);
    var tmp$ret$4 = Ratio__times_impl_ucdh7y(ratio_1, 6.283185307179586);
    tmp_1.s2h_1 = _Angle___init__impl__g23q1m(tmp$ret$4);
    var tmp_2 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_0();
    var ratio_2 = toRatio(0.5);
    var tmp$ret$6 = Ratio__times_impl_ucdh7y(ratio_2, 6.283185307179586);
    tmp_2.t2h_1 = _Angle___init__impl__g23q1m(tmp$ret$6);
    var tmp_3 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_0();
    var ratio_3 = toRatio(0.75);
    var tmp$ret$8 = Ratio__times_impl_ucdh7y(ratio_3, 6.283185307179586);
    tmp_3.u2h_1 = _Angle___init__impl__g23q1m(tmp$ret$8);
    var tmp_4 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_0();
    var ratio_4 = toRatio(1.0);
    var tmp$ret$10 = Ratio__times_impl_ucdh7y(ratio_4, 6.283185307179586);
    tmp_4.v2h_1 = _Angle___init__impl__g23q1m(tmp$ret$10);
  }
}
class Angle {
  constructor(radians) {
    Companion_getInstance_0();
    this.p2h_1 = radians;
  }
  w2h(other, epsilon) {
    return Angle__isAlmostEquals_impl_201sgt(this.p2h_1, other, epsilon);
  }
  uz(other, epsilon) {
    return Angle__isAlmostEquals_impl_201sgt_0(this, other, epsilon);
  }
  x2h(other) {
    return Angle__compareTo_impl_a0hfds(this.p2h_1, other);
  }
  d(other) {
    return Angle__compareTo_impl_a0hfds_0(this, other);
  }
  toString() {
    return Angle__toString_impl_786qky(this.p2h_1);
  }
  hashCode() {
    return Angle__hashCode_impl_szcndt(this.p2h_1);
  }
  equals(other) {
    return Angle__equals_impl_zcf5mt(this.p2h_1, other);
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_1 = this;
    this.t2i_1 = _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_16().x2i());
  }
  y2i() {
    return this.t2i_1;
  }
  z2i(p1, p2, p3, p4) {
    return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_16().c2j(Companion_getInstance_22().a2j(p1, p2, p3, p4), Companion_getInstance_22().b2j(p1, p2, p3, p4)));
  }
}
class BoundsBuilder {
  constructor(bounds) {
    Companion_getInstance_1();
    this.l2j_1 = bounds;
  }
  toString() {
    return BoundsBuilder__toString_impl_fmvxh7(this.l2j_1);
  }
  hashCode() {
    return BoundsBuilder__hashCode_impl_649zbo(this.l2j_1);
  }
  equals(other) {
    return BoundsBuilder__equals_impl_jngfjs(this.l2j_1, other);
  }
}
class Companion_2 {}
class Circle {
  constructor(center, radius) {
    this.m2j_1 = center;
    this.n2j_1 = radius;
  }
  o2j() {
    return this.n2j_1 * this.n2j_1;
  }
  p2j(p) {
    return Companion_getInstance_22().q2j(p, this.m2j_1);
  }
  r2j(p) {
    return this.p2j(p) - this.o2j();
  }
  s2j(p) {
    return this.p2j(p) + this.o2j();
  }
  toString() {
    return 'Circle(center=' + this.m2j_1.toString() + ', radius=' + this.n2j_1 + ')';
  }
  hashCode() {
    var result = this.m2j_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.n2j_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Circle))
      return false;
    var tmp0_other_with_cast = other instanceof Circle ? other : THROW_CCE();
    if (!this.m2j_1.equals(tmp0_other_with_cast.m2j_1))
      return false;
    if (!equals(this.n2j_1, tmp0_other_with_cast.n2j_1))
      return false;
    return true;
  }
}
class Companion_3 {
  constructor() {
    Companion_instance_3 = this;
    this.t2j_1 = values();
    this.u2j_1 = Order_XYZ_getInstance();
  }
}
class Companion_4 {
  d2k() {
    return _Config___init__impl__f0goox_0(Order_XYZ_getInstance(), CoordinateSystem_RIGHT_HANDED_getInstance());
  }
}
class Order extends Enum {
  constructor(name, ordinal, x, y, z, w, str) {
    super(name, ordinal);
    this.x2j_1 = x;
    this.y2j_1 = y;
    this.z2j_1 = z;
    this.a2k_1 = w;
    this.b2k_1 = str;
  }
  c2k(coordinateSystem) {
    return coordinateSystem.h2k_1 < 0 ? this.e2k() : this;
  }
  e2k() {
    var tmp;
    switch (this.v3_1) {
      case 0:
        tmp = Order_INVALID_getInstance();
        break;
      case 1:
        tmp = Order_ZYX_getInstance();
        break;
      case 2:
        tmp = Order_YZX_getInstance();
        break;
      case 3:
        tmp = Order_ZXY_getInstance();
        break;
      case 4:
        tmp = Order_XZY_getInstance();
        break;
      case 5:
        tmp = Order_YXZ_getInstance();
        break;
      case 6:
        tmp = Order_XYZ_getInstance();
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  toString() {
    return this.u3_1 + ' [' + this.x2j_1 + ', ' + this.y2j_1 + ', ' + this.z2j_1 + ', ' + this.a2k_1 + ']';
  }
}
class CoordinateSystem extends Enum {
  constructor(name, ordinal, sign) {
    super(name, ordinal);
    this.h2k_1 = sign;
    this.i2k_1 = -this.h2k_1 | 0;
  }
}
class IPointList$Sublist$listIterator$1 {
  constructor($index, this$0) {
    this.k2k_1 = $index;
    this.l2k_1 = this$0;
    this.j2k_1 = $index;
  }
  g1() {
    return this.j2k_1 >= this.l2k_1.p2k_1;
  }
  j7() {
    return this.j2k_1 > this.k2k_1;
  }
  h1() {
    var _unary__edvuaz = this.j2k_1;
    this.j2k_1 = _unary__edvuaz + 1 | 0;
    return this.l2k_1.i1(_unary__edvuaz);
  }
  k7() {
    this.j2k_1 = this.j2k_1 - 1 | 0;
    return this.l2k_1.i1(this.j2k_1);
  }
}
class Sublist {
  constructor(list, fromIndex, toIndex) {
    this.m2k_1 = list;
    this.n2k_1 = fromIndex;
    this.o2k_1 = toIndex;
    this.p2k_1 = this.o2k_1 - this.n2k_1 | 0;
  }
  j1() {
    return this.p2k_1;
  }
  i1(index) {
    return this.m2k_1.i1(index + this.n2k_1 | 0);
  }
  a1() {
    return this.p2k_1 === 0;
  }
  f1() {
    return this.h3();
  }
  h3() {
    return this.p1(0);
  }
  p1(index) {
    return new IPointList$Sublist$listIterator$1(index, this);
  }
  n1(fromIndex, toIndex) {
    return new Sublist(this.m2k_1, this.n2k_1 + fromIndex | 0, this.n2k_1 + toIndex | 0);
  }
  q2k(element) {
    return Companion_instance_5.r2k(this.m2k_1, element, this.n2k_1, this.o2k_1, -this.n2k_1 | 0);
  }
  q1(element) {
    if (!(element instanceof Vector2D))
      return -1;
    return this.q2k(element instanceof Vector2D ? element : THROW_CCE());
  }
  s2k(elements) {
    return Companion_instance_5.t2k(this, elements);
  }
  g3(elements) {
    return this.s2k(elements);
  }
  u2k(element) {
    return this.q2k(element) >= 0;
  }
  f3(element) {
    if (!(element instanceof Vector2D))
      return false;
    return this.u2k(element instanceof Vector2D ? element : THROW_CCE());
  }
}
class Companion_5 {
  t2k(_this__u8e3s4, elements) {
    var s = toSet(elements);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(_this__u8e3s4, Collection)) {
        tmp = _this__u8e3s4.a1();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = _this__u8e3s4.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.math.geom.Companion.containsAllSet.<anonymous>' call
        if (!s.f3(element)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
  r2k(list, element, fromIndex, toIndex, offset) {
    var inductionVariable = fromIndex;
    if (inductionVariable < toIndex)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (list.v2k(n) === element.i2h_1 && list.w2k(n) === element.j2h_1)
          return n + offset | 0;
      }
       while (inductionVariable < toIndex);
    return -1;
  }
  x2k(list, element, fromIndex, toIndex, offset, $super) {
    fromIndex = fromIndex === VOID ? 0 : fromIndex;
    toIndex = toIndex === VOID ? list.j1() : toIndex;
    offset = offset === VOID ? 0 : offset;
    return $super === VOID ? this.r2k(list, element, fromIndex, toIndex, offset) : $super.r2k.call(this, list, element, fromIndex, toIndex, offset);
  }
}
class IDoubleVectorList {}
function isNotEmpty() {
  return !(this.j1() === 0);
}
function isAlmostEquals_0(other, epsilon) {
  if (!(this.j1() === other.j1()))
    return false;
  if (!(this.b2l() === other.b2l()))
    return false;
  var inductionVariable = 0;
  var last = this.b2l();
  if (inductionVariable < last)
    do {
      var dim = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var inductionVariable_0 = 0;
      var last_0 = this.j1();
      if (inductionVariable_0 < last_0)
        do {
          var n = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (!isAlmostEquals(this.z2k(n, dim), other.z2k(n, dim), epsilon))
            return false;
        }
         while (inductionVariable_0 < last_0);
    }
     while (inductionVariable < last);
  return true;
}
class IPointList {}
function get(index) {
  return Vector2D.k2h(this.v2k(index), this.w2k(index));
}
function containsAll(elements) {
  return Companion_instance_5.t2k(this, elements);
}
function indexOf(element) {
  return Companion_instance_5.x2k(this, element);
}
function iterator() {
  return this.h3();
}
function listIterator() {
  return this.p1(0);
}
function listIterator_0(index) {
  return (new Sublist(this, 0, this.j1())).p1(index);
}
function subList(fromIndex, toIndex) {
  return new Sublist(this, fromIndex, toIndex);
}
class Companion_6 {
  constructor() {
    Companion_instance_6 = this;
    this.c2l_1 = Line2D.g2l(Companion_getInstance_22().a2i_1, Companion_getInstance_22().a2i_1);
    this.d2l_1 = Line2D.g2l(Companion_getInstance_22().b2i_1, Companion_getInstance_22().b2i_1);
  }
}
class Line2D {
  static g2l(a, b) {
    Companion_getInstance_6();
    var $this = createThis(this);
    $this.e2l_1 = a;
    $this.f2l_1 = b;
    return $this;
  }
  static h2l() {
    Companion_getInstance_6();
    return this.g2l(Vector2D.i2l(), Vector2D.i2l());
  }
  static j2l(x0, y0, x1, y1) {
    Companion_getInstance_6();
    return this.g2l(Vector2D.k2l(x0, y0), Vector2D.k2l(x1, y1));
  }
  l2l() {
    return this.e2l_1.i2h_1;
  }
  m2l() {
    return this.e2l_1.j2h_1;
  }
  n2l() {
    return this.f2l_1.i2h_1;
  }
  o2l() {
    return this.f2l_1.j2h_1;
  }
  toString() {
    return 'Line(' + this.e2l_1.toString() + ', ' + this.f2l_1.toString() + ')';
  }
  hashCode() {
    var result = this.e2l_1.hashCode();
    result = imul(result, 31) + this.f2l_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Line2D))
      return false;
    var tmp0_other_with_cast = other instanceof Line2D ? other : THROW_CCE();
    if (!this.e2l_1.equals(tmp0_other_with_cast.e2l_1))
      return false;
    if (!this.f2l_1.equals(tmp0_other_with_cast.f2l_1))
      return false;
    return true;
  }
}
class Companion_7 {
  constructor() {
    Companion_instance_7 = this;
    this.p2l_1 = Margin.u2l(0.0, 0.0, 0.0, 0.0);
  }
}
class Margin {
  static u2l(top, right, bottom, left) {
    Companion_getInstance_7();
    var $this = createThis(this);
    $this.q2l_1 = top;
    $this.r2l_1 = right;
    $this.s2l_1 = bottom;
    $this.t2l_1 = left;
    return $this;
  }
  static v2l(margin) {
    Companion_getInstance_7();
    return this.u2l(margin, margin, margin, margin);
  }
  w2l(other, epsilon) {
    return isAlmostEquals(this.t2l_1, other.t2l_1, epsilon) && isAlmostEquals(this.r2l_1, other.r2l_1, epsilon) && isAlmostEquals(this.q2l_1, other.q2l_1, epsilon) && isAlmostEquals(this.s2l_1, other.s2l_1, epsilon);
  }
  uz(other, epsilon) {
    return this.w2l(other instanceof Margin ? other : THROW_CCE(), epsilon);
  }
  x2l() {
    return this.t2l_1 + this.r2l_1;
  }
  y2l() {
    return this.q2l_1 + this.s2l_1;
  }
  toString() {
    return 'Margin(top=' + get_niceStr(this.q2l_1) + ', right=' + get_niceStr(this.r2l_1) + ', bottom=' + get_niceStr(this.s2l_1) + ', left=' + get_niceStr(this.t2l_1) + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.q2l_1);
    result = imul(result, 31) + getNumberHashCode(this.r2l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.s2l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.t2l_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Margin))
      return false;
    var tmp0_other_with_cast = other instanceof Margin ? other : THROW_CCE();
    if (!equals(this.q2l_1, tmp0_other_with_cast.q2l_1))
      return false;
    if (!equals(this.r2l_1, tmp0_other_with_cast.r2l_1))
      return false;
    if (!equals(this.s2l_1, tmp0_other_with_cast.s2l_1))
      return false;
    if (!equals(this.t2l_1, tmp0_other_with_cast.t2l_1))
      return false;
    return true;
  }
}
class Companion_8 {
  constructor() {
    Companion_instance_8 = this;
    this.z2l_1 = MarginInt.e2m(0, 0, 0, 0);
  }
}
class MarginInt {
  static e2m(top, right, bottom, left) {
    Companion_getInstance_8();
    var $this = createThis(this);
    $this.a2m_1 = top;
    $this.b2m_1 = right;
    $this.c2m_1 = bottom;
    $this.d2m_1 = left;
    return $this;
  }
  static f2m(vertical, horizontal) {
    Companion_getInstance_8();
    return this.e2m(vertical, horizontal, vertical, horizontal);
  }
  static g2m(margin) {
    Companion_getInstance_8();
    return this.e2m(margin, margin, margin, margin);
  }
  h2m() {
    return !(this.a2m_1 === 0) || !(this.d2m_1 === 0) || !(this.b2m_1 === 0) || !(this.c2m_1 === 0);
  }
  x2l() {
    return this.d2m_1 + this.b2m_1 | 0;
  }
  y2l() {
    return this.a2m_1 + this.c2m_1 | 0;
  }
  toString() {
    return 'MarginInt(top=' + this.a2m_1 + ', right=' + this.b2m_1 + ', bottom=' + this.c2m_1 + ', left=' + this.d2m_1 + ')';
  }
  hashCode() {
    var result = this.a2m_1;
    result = imul(result, 31) + this.b2m_1 | 0;
    result = imul(result, 31) + this.c2m_1 | 0;
    result = imul(result, 31) + this.d2m_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof MarginInt))
      return false;
    var tmp0_other_with_cast = other instanceof MarginInt ? other : THROW_CCE();
    if (!(this.a2m_1 === tmp0_other_with_cast.a2m_1))
      return false;
    if (!(this.b2m_1 === tmp0_other_with_cast.b2m_1))
      return false;
    if (!(this.c2m_1 === tmp0_other_with_cast.c2m_1))
      return false;
    if (!(this.d2m_1 === tmp0_other_with_cast.d2m_1))
      return false;
    return true;
  }
}
class Companion_9 {
  constructor() {
    Companion_instance_9 = this;
    this.i2m_1 = new Matrix(1.0, 0.0, 0.0, 1.0, 0.0, 0.0);
    this.j2m_1 = new Matrix(NaN, NaN, NaN, NaN, NaN, NaN);
    this.k2m_1 = this.j2m_1;
  }
  l2m() {
    return this.i2m_1;
  }
  m2m(a, b, epsilon) {
    return isAlmostEquals(a.r2m_1, b.r2m_1, epsilon) && isAlmostEquals(a.s2m_1, b.s2m_1, epsilon) && isAlmostEquals(a.n2m_1, b.n2m_1, epsilon) && isAlmostEquals(a.o2m_1, b.o2m_1, epsilon) && isAlmostEquals(a.p2m_1, b.p2m_1, epsilon) && isAlmostEquals(a.q2m_1, b.q2m_1, epsilon);
  }
  t2m(l, r) {
    if (l.n2i())
      return r;
    if (r.n2i())
      return l;
    return new Matrix(l.n2m_1 * r.n2m_1 + l.o2m_1 * r.p2m_1, l.n2m_1 * r.o2m_1 + l.o2m_1 * r.q2m_1, l.p2m_1 * r.n2m_1 + l.q2m_1 * r.p2m_1, l.p2m_1 * r.o2m_1 + l.q2m_1 * r.q2m_1, l.r2m_1 * r.n2m_1 + l.s2m_1 * r.p2m_1 + r.r2m_1, l.r2m_1 * r.o2m_1 + l.s2m_1 * r.q2m_1 + r.s2m_1);
  }
  u2m(angle) {
    return Companion_getInstance_9().i2m_1.v2m(angle);
  }
  w2m(x, y, rotation, scaleX, scaleY, skewX, skewY, pivotX, pivotY) {
    // Inline function 'korlibs.math.geom.cos' call
    var angle = Angle__plus_impl_26v37k(rotation, skewY);
    var up = Companion_getInstance_22().e2i_1;
    var a = Angle__cosine_impl_bgvzun(angle, up) * scaleX + 0.0;
    // Inline function 'korlibs.math.geom.sin' call
    var angle_0 = Angle__plus_impl_26v37k(rotation, skewY);
    var up_0 = Companion_getInstance_22().e2i_1;
    var b = Angle__sine_impl_3l11xv(angle_0, up_0) * scaleX + 0.0;
    // Inline function 'korlibs.math.geom.sin' call
    var angle_1 = Angle__minus_impl_xeau7k(rotation, skewX);
    var up_1 = Companion_getInstance_22().e2i_1;
    var c = -Angle__sine_impl_3l11xv(angle_1, up_1) * scaleY + 0.0;
    // Inline function 'korlibs.math.geom.cos' call
    var angle_2 = Angle__minus_impl_xeau7k(rotation, skewX);
    var up_2 = Companion_getInstance_22().e2i_1;
    var d = Angle__cosine_impl_bgvzun(angle_2, up_2) * scaleY + 0.0;
    var tx;
    var ty;
    if (pivotX === 0.0 && pivotY === 0.0) {
      tx = x;
      ty = y;
    } else {
      tx = x - (pivotX * a + pivotY * c);
      ty = y - (pivotX * b + pivotY * d);
    }
    return new Matrix(a, b, c, d, tx, ty);
  }
  x2m(a, b, c, d, tx, ty, p) {
    return Vector2D.k2h(a * p.i2h_1 + c * p.j2h_1 + tx, d * p.j2h_1 + b * p.i2h_1 + ty);
  }
  y2m(l, r, ratio) {
    return new Matrix(interpolate(ratio, l.n2m_1, r.n2m_1), interpolate(ratio, l.o2m_1, r.o2m_1), interpolate(ratio, l.p2m_1, r.p2m_1), interpolate(ratio, l.q2m_1, r.q2m_1), interpolate(ratio, l.r2m_1, r.r2m_1), interpolate(ratio, l.s2m_1, r.s2m_1));
  }
}
class Matrix {
  constructor(a, b, c, d, tx, ty) {
    Companion_getInstance_9();
    tx = tx === VOID ? 0.0 : tx;
    ty = ty === VOID ? 0.0 : ty;
    this.n2m_1 = a;
    this.o2m_1 = b;
    this.p2m_1 = c;
    this.q2m_1 = d;
    this.r2m_1 = tx;
    this.s2m_1 = ty;
  }
  z2m(other) {
    return Companion_getInstance_9().t2m(this, other);
  }
  n2i() {
    return isNaN_0(this.n2m_1);
  }
  o2i() {
    return !this.n2i();
  }
  a2n() {
    return this.n2m_1 === 1.0 && this.o2m_1 === 0.0 && this.p2m_1 === 0.0 && this.q2m_1 === 1.0 && this.r2m_1 === 0.0 && this.s2m_1 === 0.0;
  }
  b2n() {
    var hasRotation = !(this.o2m_1 === 0.0) || !(this.p2m_1 === 0.0);
    var hasScale = !(this.n2m_1 === 1.0) || !(this.q2m_1 === 1.0);
    var hasTranslation = !(this.r2m_1 === 0.0) || !(this.s2m_1 === 0.0);
    return hasRotation ? MatrixType_COMPLEX_getInstance() : hasScale && hasTranslation ? MatrixType_SCALE_TRANSLATE_getInstance() : hasScale ? MatrixType_SCALE_getInstance() : hasTranslation ? MatrixType_TRANSLATE_getInstance() : MatrixType_IDENTITY_getInstance();
  }
  c2n(p) {
    return this.d2n(p.i2h_1, p.j2h_1);
  }
  e2n(x, y) {
    return this.d2n(x, y);
  }
  f2n(x, y) {
    return this.g2n(x, y);
  }
  d2n(x, y) {
    return this.n2m_1 * x + this.p2m_1 * y + this.r2m_1;
  }
  g2n(x, y) {
    return this.q2m_1 * y + this.o2m_1 * x + this.s2m_1;
  }
  h2n(x, y) {
    return this.d2n(x, y);
  }
  i2n(x, y) {
    return this.g2n(x, y);
  }
  v2m(angle) {
    // Inline function 'korlibs.math.geom.cos' call
    var up = Companion_getInstance_22().e2i_1;
    var cos = Angle__cosine_impl_bgvzun(angle, up);
    // Inline function 'korlibs.math.geom.sin' call
    var up_0 = Companion_getInstance_22().e2i_1;
    var sin = Angle__sine_impl_3l11xv(angle, up_0);
    var a1 = this.n2m_1 * cos - this.o2m_1 * sin;
    var b = this.n2m_1 * sin + this.o2m_1 * cos;
    var a = a1;
    var c1 = this.p2m_1 * cos - this.q2m_1 * sin;
    var d = this.p2m_1 * sin + this.q2m_1 * cos;
    var c = c1;
    var tx1 = this.r2m_1 * cos - this.s2m_1 * sin;
    var ty = this.r2m_1 * sin + this.s2m_1 * cos;
    var tx = tx1;
    return new Matrix(a, b, c, d, tx, ty);
  }
  j2n(scaleX, scaleY) {
    return this.k2n(scaleX, scaleY);
  }
  k2n(scaleX, scaleY) {
    return new Matrix(this.n2m_1 * scaleX, this.o2m_1 * scaleX, this.p2m_1 * scaleY, this.q2m_1 * scaleY, this.r2m_1 * scaleX, this.s2m_1 * scaleY);
  }
  l2n(scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    return $super === VOID ? this.k2n(scaleX, scaleY) : $super.k2n.call(this, scaleX, scaleY);
  }
  m2n(scaleX, scaleY) {
    return new Matrix(this.n2m_1 * scaleX, this.o2m_1 * scaleX, this.p2m_1 * scaleY, this.q2m_1 * scaleY, this.r2m_1, this.s2m_1);
  }
  n2n(scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    return $super === VOID ? this.m2n(scaleX, scaleY) : $super.m2n.call(this, scaleX, scaleY);
  }
  o2n(delta) {
    return new Matrix(this.n2m_1, this.o2m_1, this.p2m_1, this.q2m_1, this.r2m_1 + delta.i2h_1, this.s2m_1 + delta.j2h_1);
  }
  p2n(x, y) {
    return this.o2n(Vector2D.k2l(x, y));
  }
  q2n(x, y) {
    return this.o2n(Vector2D.k2h(x, y));
  }
  r2n(delta) {
    return new Matrix(this.n2m_1, this.o2m_1, this.p2m_1, this.q2m_1, this.r2m_1 + (this.n2m_1 * delta.i2h_1 + this.p2m_1 * delta.j2h_1), this.s2m_1 + (this.o2m_1 * delta.i2h_1 + this.q2m_1 * delta.j2h_1));
  }
  s2n(deltaX, deltaY) {
    return this.r2n(Vector2D.k2l(deltaX, deltaY));
  }
  t2n(deltaX, deltaY) {
    return this.r2n(Vector2D.u2n(deltaX, deltaY));
  }
  v2n(deltaX, deltaY) {
    return this.r2n(Vector2D.k2h(deltaX, deltaY));
  }
  w2n(angle) {
    return Companion_getInstance_9().u2m(angle).z2m(this);
  }
  x2n(m) {
    return m.z2m(this);
  }
  y2n() {
    if (this.n2i())
      return Companion_getInstance_9().i2m_1;
    var m = this;
    var norm = m.n2m_1 * m.q2m_1 - m.o2m_1 * m.p2m_1;
    var tmp;
    if (norm === 0.0) {
      tmp = new Matrix(0.0, 0.0, 0.0, 0.0, -m.r2m_1, -m.s2m_1);
    } else {
      var inorm = 1.0 / norm;
      var d = m.n2m_1 * inorm;
      var a = m.q2m_1 * inorm;
      var b = m.o2m_1 * -inorm;
      var c = m.p2m_1 * -inorm;
      tmp = new Matrix(a, b, c, d, -a * m.r2m_1 - c * m.s2m_1, -b * m.r2m_1 - d * m.s2m_1);
    }
    return tmp;
  }
  z2n() {
    return this.a2o();
  }
  a2o() {
    return Companion_getInstance_10().c2o(this);
  }
  toString() {
    return 'Matrix(' + get_niceStr(this.n2m_1) + ', ' + get_niceStr(this.o2m_1) + ', ' + get_niceStr(this.p2m_1) + ', ' + get_niceStr(this.q2m_1) + ', ' + get_niceStr(this.r2m_1) + ', ' + get_niceStr(this.s2m_1) + ')';
  }
  d2o(other, epsilon) {
    return Companion_getInstance_9().m2m(this, other, epsilon);
  }
  uz(other, epsilon) {
    return this.d2o(other instanceof Matrix ? other : THROW_CCE(), epsilon);
  }
  e2o(other) {
    return this.z2m(other);
  }
  hashCode() {
    var result = getNumberHashCode(this.n2m_1);
    result = imul(result, 31) + getNumberHashCode(this.o2m_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.p2m_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.q2m_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.r2m_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.s2m_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Matrix))
      return false;
    var tmp0_other_with_cast = other instanceof Matrix ? other : THROW_CCE();
    if (!equals(this.n2m_1, tmp0_other_with_cast.n2m_1))
      return false;
    if (!equals(this.o2m_1, tmp0_other_with_cast.o2m_1))
      return false;
    if (!equals(this.p2m_1, tmp0_other_with_cast.p2m_1))
      return false;
    if (!equals(this.q2m_1, tmp0_other_with_cast.q2m_1))
      return false;
    if (!equals(this.r2m_1, tmp0_other_with_cast.r2m_1))
      return false;
    if (!equals(this.s2m_1, tmp0_other_with_cast.s2m_1))
      return false;
    return true;
  }
}
class MatrixType extends Enum {
  constructor(name, ordinal, id, hasRotation, hasScale, hasTranslation) {
    super(name, ordinal);
    this.h2o_1 = id;
    this.i2o_1 = hasRotation;
    this.j2o_1 = hasScale;
    this.k2o_1 = hasTranslation;
  }
}
class Companion_10 {
  constructor() {
    Companion_instance_10 = this;
    this.b2o_1 = MatrixTransform.s2o(0.0, 0.0, 1.0, 1.0, Companion_getInstance_0().r2h_1, Companion_getInstance_0().r2h_1, Companion_getInstance_0().r2h_1);
  }
  t2o(matrix, pivotX, pivotY) {
    var a = matrix.n2m_1;
    var b = matrix.o2m_1;
    var c = matrix.p2m_1;
    var d = matrix.q2m_1;
    // Inline function 'kotlin.math.atan2' call
    var y = -c;
    var skewX = -Math.atan2(y, d);
    // Inline function 'kotlin.math.atan2' call
    var skewY = Math.atan2(b, a);
    // Inline function 'kotlin.math.abs' call
    var x = skewX + skewY;
    var delta = Math.abs(x);
    var trotation;
    var tskewX;
    var tskewY;
    var tx;
    var ty;
    var tmp;
    if (delta < 0.001) {
      tmp = true;
    } else {
      // Inline function 'kotlin.math.abs' call
      var x_0 = 3.141592653589793 * 2 - delta;
      tmp = Math.abs(x_0) < 0.001;
    }
    if (tmp) {
      trotation = get_radians_0(skewY);
      tskewX = get_radians_0(0.0);
      tskewY = get_radians_0(0.0);
    } else {
      trotation = get_radians(0);
      tskewX = get_radians_0(skewX);
      tskewY = get_radians_0(skewY);
    }
    // Inline function 'kotlin.math.hypot' call
    var tscaleX = hypot(a, b);
    // Inline function 'kotlin.math.hypot' call
    var tscaleY = hypot(c, d);
    if (pivotX === 0.0 && pivotY === 0.0) {
      tx = matrix.r2m_1;
      ty = matrix.s2m_1;
    } else {
      tx = matrix.r2m_1 + (pivotX * a + pivotY * c);
      ty = matrix.s2m_1 + (pivotX * b + pivotY * d);
    }
    return MatrixTransform.s2o(tx, ty, tscaleX, tscaleY, tskewX, tskewY, trotation);
  }
  c2o(matrix, pivotX, pivotY, $super) {
    pivotX = pivotX === VOID ? 0.0 : pivotX;
    pivotY = pivotY === VOID ? 0.0 : pivotY;
    return $super === VOID ? this.t2o(matrix, pivotX, pivotY) : $super.t2o.call(this, matrix, pivotX, pivotY);
  }
  u2o(a, b, epsilon) {
    return isAlmostEquals(a.l2o_1, b.l2o_1, epsilon) && isAlmostEquals(a.m2o_1, b.m2o_1, epsilon) && isAlmostEquals(a.n2o_1, b.n2o_1, epsilon) && isAlmostEquals(a.o2o_1, b.o2o_1, epsilon) && Angle__isAlmostEquals_impl_201sgt(a.p2o_1, b.p2o_1, epsilon) && Angle__isAlmostEquals_impl_201sgt(a.q2o_1, b.q2o_1, epsilon) && Angle__isAlmostEquals_impl_201sgt(a.r2o_1, b.r2o_1, epsilon);
  }
}
class MatrixTransform {
  static s2o(x, y, scaleX, scaleY, skewX, skewY, rotation) {
    Companion_getInstance_10();
    x = x === VOID ? 0.0 : x;
    y = y === VOID ? 0.0 : y;
    scaleX = scaleX === VOID ? 1.0 : scaleX;
    scaleY = scaleY === VOID ? 1.0 : scaleY;
    skewX = skewX === VOID ? Companion_getInstance_0().r2h_1 : skewX;
    skewY = skewY === VOID ? Companion_getInstance_0().r2h_1 : skewY;
    rotation = rotation === VOID ? Companion_getInstance_0().r2h_1 : rotation;
    var $this = createThis(this);
    $this.l2o_1 = x;
    $this.m2o_1 = y;
    $this.n2o_1 = scaleX;
    $this.o2o_1 = scaleY;
    $this.p2o_1 = skewX;
    $this.q2o_1 = skewY;
    $this.r2o_1 = rotation;
    return $this;
  }
  toString() {
    return 'MatrixTransform(x=' + get_niceStr(this.l2o_1) + ', y=' + get_niceStr(this.m2o_1) + ', scaleX=' + this.n2o_1 + ', scaleY=' + this.o2o_1 + ', skewX=' + Angle__toString_impl_786qky(this.p2o_1) + ', skewY=' + Angle__toString_impl_786qky(this.q2o_1) + ', rotation=' + Angle__toString_impl_786qky(this.r2o_1) + ')';
  }
  static v2o() {
    Companion_getInstance_10();
    return this.s2o(0.0, 0.0, 1.0, 1.0, Companion_getInstance_0().r2h_1, Companion_getInstance_0().r2h_1, Companion_getInstance_0().r2h_1);
  }
  w2o(other, epsilon) {
    return Companion_getInstance_10().u2o(this, other, epsilon);
  }
  uz(other, epsilon) {
    return this.w2o(other instanceof MatrixTransform ? other : THROW_CCE(), epsilon);
  }
  x2o() {
    return (this.n2o_1 + this.o2o_1) * 0.5;
  }
  hashCode() {
    var result = getNumberHashCode(this.l2o_1);
    result = imul(result, 31) + getNumberHashCode(this.m2o_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.n2o_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.o2o_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.p2o_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.q2o_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.r2o_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof MatrixTransform))
      return false;
    var tmp0_other_with_cast = other instanceof MatrixTransform ? other : THROW_CCE();
    if (!equals(this.l2o_1, tmp0_other_with_cast.l2o_1))
      return false;
    if (!equals(this.m2o_1, tmp0_other_with_cast.m2o_1))
      return false;
    if (!equals(this.n2o_1, tmp0_other_with_cast.n2o_1))
      return false;
    if (!equals(this.o2o_1, tmp0_other_with_cast.o2o_1))
      return false;
    if (!equals(this.p2o_1, tmp0_other_with_cast.p2o_1))
      return false;
    if (!equals(this.q2o_1, tmp0_other_with_cast.q2o_1))
      return false;
    if (!equals(this.r2o_1, tmp0_other_with_cast.r2o_1))
      return false;
    return true;
  }
}
class Companion_11 {
  constructor() {
    Companion_instance_11 = this;
    this.y2o_1 = 0;
    this.z2o_1 = 1;
    this.a2p_1 = 2;
    this.b2p_1 = 3;
    this.c2p_1 = 4;
    this.d2p_1 = 5;
    this.e2p_1 = 6;
    this.f2p_1 = 7;
    this.g2p_1 = 8;
    this.h2p_1 = 9;
    this.i2p_1 = 10;
    this.j2p_1 = 11;
    this.k2p_1 = 12;
    this.l2p_1 = 13;
    this.m2p_1 = 14;
    this.n2p_1 = 15;
    var tmp = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp.o2p_1 = new Int32Array([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
    var tmp_0 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_0.p2p_1 = new Int32Array([0, 4, 8, 12, 1, 5, 9, 13, 2, 6, 10, 14, 3, 7, 11, 15]);
    var tmp_1 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_1.q2p_1 = new Int32Array([0, 1, 2, 4, 5, 6, 8, 9, 10]);
    var tmp_2 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_2.r2p_1 = new Int32Array([0, 4, 8, 1, 5, 9, 2, 6, 10]);
    this.s2p_1 = Matrix4.u2p();
  }
  v2p(c0, c1, c2, c3) {
    return Matrix4.a2q(c0.w2p_1, c0.x2p_1, c0.y2p_1, c0.z2p_1, c1.w2p_1, c1.x2p_1, c1.y2p_1, c1.z2p_1, c2.w2p_1, c2.x2p_1, c2.y2p_1, c2.z2p_1, c3.w2p_1, c3.x2p_1, c3.y2p_1, c3.z2p_1);
  }
  b2q(v, offset) {
    return Companion_getInstance_11().c2q(v[offset + 0 | 0], v[offset + 1 | 0], v[offset + 2 | 0], v[offset + 3 | 0], v[offset + 4 | 0], v[offset + 5 | 0], v[offset + 6 | 0], v[offset + 7 | 0], v[offset + 8 | 0], v[offset + 9 | 0], v[offset + 10 | 0], v[offset + 11 | 0], v[offset + 12 | 0], v[offset + 13 | 0], v[offset + 14 | 0], v[offset + 15 | 0]);
  }
  d2q(v, offset, $super) {
    offset = offset === VOID ? 0 : offset;
    return $super === VOID ? this.b2q(v, offset) : $super.b2q.call(this, v, offset);
  }
  c2q(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33) {
    return Matrix4.a2q(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33);
  }
  e2q(v00, v01, v02, v03, v10, v11, v12, v13, v20, v21, v22, v23, v30, v31, v32, v33) {
    return Matrix4.a2q(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33);
  }
  f2q(a00, a01, a02, a10, a11, a12, a20, a21, a22) {
    return Companion_getInstance_11().e2q(a00, a01, a02, 0.0, a10, a11, a12, 0.0, a20, a21, a22, 0.0, 0.0, 0.0, 0.0, 1.0);
  }
  g2q(x, y, z, w) {
    return Companion_getInstance_11().e2q(1.0, 0.0, 0.0, x, 0.0, 1.0, 0.0, y, 0.0, 0.0, 1.0, z, 0.0, 0.0, 0.0, w);
  }
  h2q(x, y, z, w, $super) {
    w = w === VOID ? 1.0 : w;
    return $super === VOID ? this.g2q(x, y, z, w) : $super.g2q.call(this, x, y, z, w);
  }
  i2q(x, y, z, w) {
    return Companion_getInstance_11().e2q(x, 0.0, 0.0, 0.0, 0.0, y, 0.0, 0.0, 0.0, 0.0, z, 0.0, 0.0, 0.0, 0.0, w);
  }
  j2q(x, y, z, w, $super) {
    w = w === VOID ? 1.0 : w;
    return $super === VOID ? this.i2q(x, y, z, w) : $super.i2q.call(this, x, y, z, w);
  }
  k2q(l, r) {
    return Companion_getInstance_11().e2q(l.l2q() * r.l2q() + l.m2q() * r.n2q() + l.o2q() * r.p2q() + l.q2q() * r.r2q(), l.l2q() * r.m2q() + l.m2q() * r.s2q() + l.o2q() * r.t2q() + l.q2q() * r.u2q(), l.l2q() * r.o2q() + l.m2q() * r.v2q() + l.o2q() * r.w2q() + l.q2q() * r.x2q(), l.l2q() * r.q2q() + l.m2q() * r.y2q() + l.o2q() * r.z2q() + l.q2q() * r.a2r(), l.n2q() * r.l2q() + l.s2q() * r.n2q() + l.v2q() * r.p2q() + l.y2q() * r.r2q(), l.n2q() * r.m2q() + l.s2q() * r.s2q() + l.v2q() * r.t2q() + l.y2q() * r.u2q(), l.n2q() * r.o2q() + l.s2q() * r.v2q() + l.v2q() * r.w2q() + l.y2q() * r.x2q(), l.n2q() * r.q2q() + l.s2q() * r.y2q() + l.v2q() * r.z2q() + l.y2q() * r.a2r(), l.p2q() * r.l2q() + l.t2q() * r.n2q() + l.w2q() * r.p2q() + l.z2q() * r.r2q(), l.p2q() * r.m2q() + l.t2q() * r.s2q() + l.w2q() * r.t2q() + l.z2q() * r.u2q(), l.p2q() * r.o2q() + l.t2q() * r.v2q() + l.w2q() * r.w2q() + l.z2q() * r.x2q(), l.p2q() * r.q2q() + l.t2q() * r.y2q() + l.w2q() * r.z2q() + l.z2q() * r.a2r(), l.r2q() * r.l2q() + l.u2q() * r.n2q() + l.x2q() * r.p2q() + l.a2r() * r.r2q(), l.r2q() * r.m2q() + l.u2q() * r.s2q() + l.x2q() * r.t2q() + l.a2r() * r.u2q(), l.r2q() * r.o2q() + l.u2q() * r.v2q() + l.x2q() * r.w2q() + l.a2r() * r.x2q(), l.r2q() * r.q2q() + l.u2q() * r.y2q() + l.x2q() * r.z2q() + l.a2r() * r.a2r());
  }
  b2r(left, right, bottom, top, near, far) {
    var sx = 2.0 / (right - left);
    var sy = 2.0 / (top - bottom);
    var sz = -2.0 / (far - near);
    var tx = -(right + left) / (right - left);
    var ty = -(top + bottom) / (top - bottom);
    var tz = -(far + near) / (far - near);
    return Companion_getInstance_11().e2q(sx, 0.0, 0.0, tx, 0.0, sy, 0.0, ty, 0.0, 0.0, sz, tz, 0.0, 0.0, 0.0, 1.0);
  }
  c2r(left, right, bottom, top, near, far) {
    return this.b2r(left, right, bottom, top, near, far);
  }
}
class Matrix4 {
  static d2r(data) {
    Companion_getInstance_11();
    var $this = createThis(this);
    $this.t2p_1 = data;
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!($this.t2p_1.length === 16)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    return $this;
  }
  l2q() {
    return this.t2p_1[0];
  }
  n2q() {
    return this.t2p_1[1];
  }
  p2q() {
    return this.t2p_1[2];
  }
  r2q() {
    return this.t2p_1[3];
  }
  m2q() {
    return this.t2p_1[4];
  }
  s2q() {
    return this.t2p_1[5];
  }
  t2q() {
    return this.t2p_1[6];
  }
  u2q() {
    return this.t2p_1[7];
  }
  o2q() {
    return this.t2p_1[8];
  }
  v2q() {
    return this.t2p_1[9];
  }
  w2q() {
    return this.t2p_1[10];
  }
  x2q() {
    return this.t2p_1[11];
  }
  q2q() {
    return this.t2p_1[12];
  }
  y2q() {
    return this.t2p_1[13];
  }
  z2q() {
    return this.t2p_1[14];
  }
  a2r() {
    return this.t2p_1[15];
  }
  equals(other) {
    var tmp;
    if (other instanceof Matrix4) {
      tmp = contentEquals(this.t2p_1, other.t2p_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return contentHashCode(this.t2p_1);
  }
  e2r(scale) {
    return Companion_getInstance_11().v2p(this.f2r().e2r(scale), this.g2r().e2r(scale), this.h2r().e2r(scale), this.i2r().e2r(scale));
  }
  j2r(that) {
    return Companion_getInstance_11().k2q(this, that);
  }
  k2r(out, offset) {
    // Inline function 'kotlin.collections.copyInto' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = this.t2p_1;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, out, offset, 0, 16);
    return out;
  }
  l2r(out, offset, $super) {
    out = out === VOID ? new Float32Array(16) : out;
    offset = offset === VOID ? 0 : offset;
    return $super === VOID ? this.k2r(out, offset) : $super.k2r.call(this, out, offset);
  }
  static a2q(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33) {
    Companion_getInstance_11();
    // Inline function 'kotlin.floatArrayOf' call
    var tmp$ret$0 = new Float32Array([v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33]);
    return this.d2r(tmp$ret$0);
  }
  static u2p() {
    Companion_getInstance_11();
    return this.a2q(1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0);
  }
  f2r() {
    return Companion_getInstance_23().o2r(this.t2p_1, 0);
  }
  g2r() {
    return Companion_getInstance_23().o2r(this.t2p_1, 4);
  }
  h2r() {
    return Companion_getInstance_23().o2r(this.t2p_1, 8);
  }
  i2r() {
    return Companion_getInstance_23().o2r(this.t2p_1, 12);
  }
  z2k(row, column) {
    if (!(0 <= column ? column <= 3 : false) || !(0 <= row ? row <= 3 : false)) {
      // Inline function 'kotlin.error' call
      var message = 'Invalid index ' + row + ',' + column;
      throw IllegalStateException.m5(toString(message));
    }
    return this.t2p_1[imul(row, 4) + column | 0];
  }
  p2r(index) {
    if (!(0 <= index ? index <= (this.t2p_1.length - 1 | 0) : false)) {
      // Inline function 'kotlin.error' call
      var message = 'Invalid index ' + index;
      throw IllegalStateException.m5(toString(message));
    }
    return this.t2p_1[index];
  }
  toString() {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.Matrix4.toString.<anonymous>' call
    this_0.ic('Matrix4(\n');
    var inductionVariable = 0;
    if (inductionVariable < 4)
      do {
        var row = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this_0.ic('  [ ');
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < 4)
          do {
            var col = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            if (!(col === 0)) {
              this_0.ic(', ');
            }
            var v = this.z2k(row, col);
            // Inline function 'kotlin.math.floor' call
            if (Math.floor(v) === v)
              this_0.oh(numberToInt(v));
            else {
              this_0.qh(v);
            }
          }
           while (inductionVariable_0 < 4);
        this_0.ic(' ],\n');
      }
       while (inductionVariable < 4);
    this_0.ic(')');
    return this_0.toString();
  }
}
class Companion_12 {
  constructor() {
    this.y2h_1 = 1.0E-7;
  }
  z2h(up) {
    // Inline function 'kotlin.check' call
    var tmp;
    if (up.i2h_1 === 0.0) {
      // Inline function 'kotlin.math.absoluteValue' call
      var this_0 = up.j2h_1;
      tmp = Math.abs(this_0) === 1.0;
    } else {
      tmp = false;
    }
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp) {
      // Inline function 'korlibs.math.geom.Companion.checkValidUpVector.<anonymous>' call
      var message = 'up vector only supports (0, -1) and (0, +1) for now';
      throw IllegalStateException.m5(toString(message));
    }
  }
}
class Orientation extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.v2r_1 = value;
  }
}
class Companion_13 {
  constructor() {
    Companion_instance_13 = this;
    this.a2s_1 = Quaternion.b2s();
  }
  c2s(l, r) {
    return l.w2r_1 * r.w2r_1 + l.x2r_1 * r.x2r_1 + l.y2r_1 * r.y2r_1 + l.z2r_1 * r.z2r_1;
  }
  d2s(left, right, t) {
    var tleft = left.e2s();
    var tright = right.e2s();
    var dot = Companion_getInstance_13().c2s(tleft, right);
    if (dot < 0.0) {
      tright = tright.i4();
      dot = -dot;
    }
    if (dot > 0.99995) {
      // Inline function 'korlibs.math.geom.Companion.func' call
      var r = tright;
      // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
      var l = tleft.w2r_1;
      var tmp = l + t * (r.w2r_1 - l);
      // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
      var l_0 = tleft.x2r_1;
      var tmp_0 = l_0 + t * (r.x2r_1 - l_0);
      // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
      var l_1 = tleft.y2r_1;
      var tmp_1 = l_1 + t * (r.y2r_1 - l_1);
      // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
      var l_2 = tleft.z2r_1;
      var tmp$ret$3 = l_2 + t * (r.z2r_1 - l_2);
      return Quaternion.f2s(tmp, tmp_0, tmp_1, tmp$ret$3);
    }
    // Inline function 'kotlin.math.acos' call
    var x = dot;
    var angle0 = Math.acos(x);
    var angle1 = angle0 * t;
    // Inline function 'kotlin.math.sin' call
    var tmp_2 = Math.sin(angle1);
    // Inline function 'kotlin.math.sin' call
    var s1 = tmp_2 / Math.sin(angle0);
    // Inline function 'kotlin.math.cos' call
    var s0 = Math.cos(angle1) - dot * s1;
    // Inline function 'korlibs.math.geom.Companion.func' call
    var r_0 = tright;
    // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
    var tmp_3 = s0 * tleft.w2r_1 + s1 * r_0.w2r_1;
    // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
    var tmp_4 = s0 * tleft.x2r_1 + s1 * r_0.x2r_1;
    // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
    var tmp_5 = s0 * tleft.y2r_1 + s1 * r_0.y2r_1;
    // Inline function 'korlibs.math.geom.Companion.slerp.<anonymous>' call
    var tmp$ret$12 = s0 * tleft.z2r_1 + s1 * r_0.z2r_1;
    return Quaternion.f2s(tmp_3, tmp_4, tmp_5, tmp$ret$12);
  }
  g2s(left, right, t) {
    return this.d2s(left, right, t);
  }
  h2s(m) {
    return this.i2s(m.l2q(), m.n2q(), m.p2q(), m.m2q(), m.s2q(), m.t2q(), m.o2q(), m.v2q(), m.w2q());
  }
  i2s(v00, v10, v20, v01, v11, v21, v02, v12, v22) {
    var t = v00 + v11 + v22;
    var tmp;
    if (t >= 0) {
      // Inline function 'kotlin.math.sqrt' call
      var x = t + 1.0;
      var s = 0.5 / Math.sqrt(x);
      tmp = Quaternion.f2s((v21 - v12) * s, (v02 - v20) * s, (v10 - v01) * s, 0.25 / s);
    } else if (v00 > v11 && v00 > v22) {
      // Inline function 'kotlin.math.sqrt' call
      var x_0 = 1.0 + v00 - v11 - v22;
      var s_0 = 2.0 * Math.sqrt(x_0);
      tmp = Quaternion.f2s(0.25 * s_0, (v01 + v10) / s_0, (v02 + v20) / s_0, (v21 - v12) / s_0);
    } else if (v11 > v22) {
      // Inline function 'kotlin.math.sqrt' call
      var x_1 = 1.0 + v11 - v00 - v22;
      var s_1 = 2.0 * Math.sqrt(x_1);
      tmp = Quaternion.f2s((v01 + v10) / s_1, 0.25 * s_1, (v12 + v21) / s_1, (v02 - v20) / s_1);
    } else {
      // Inline function 'kotlin.math.sqrt' call
      var x_2 = 1.0 + v22 - v00 - v11;
      var s_2 = 2.0 * Math.sqrt(x_2);
      tmp = Quaternion.f2s((v02 + v20) / s_2, (v12 + v21) / s_2, 0.25 * s_2, (v10 - v01) / s_2);
    }
    return tmp;
  }
}
class Quaternion {
  static f2s(x, y, z, w) {
    Companion_getInstance_13();
    var $this = createThis(this);
    $this.w2r_1 = x;
    $this.x2r_1 = y;
    $this.y2r_1 = z;
    $this.z2r_1 = w;
    return $this;
  }
  static j2s(vector, unit) {
    Companion_getInstance_13();
    unit = unit === VOID ? Unit_instance : unit;
    return this.f2s(vector.w2p_1, vector.x2p_1, vector.y2p_1, vector.z2p_1);
  }
  static b2s() {
    Companion_getInstance_13();
    return this.f2s(0.0, 0.0, 0.0, 1.0);
  }
  k2s() {
    var v = _toMatrix(this);
    return Companion_getInstance_11().e2q(v[0], v[1], v[2], 0.0, v[3], v[4], v[5], 0.0, v[6], v[7], v[8], 0.0, 0.0, 0.0, 0.0, 1.0);
  }
  i4() {
    return Quaternion.f2s(-this.w2r_1, -this.x2r_1, -this.y2r_1, -this.z2r_1);
  }
  e2s() {
    var length = 1.0 / (new Vector4F(this.w2r_1, this.x2r_1, this.y2r_1, this.z2r_1)).a();
    return Quaternion.f2s(this.w2r_1 / length, this.x2r_1 / length, this.y2r_1 / length, this.z2r_1 / length);
  }
  toString() {
    return 'Quaternion(x=' + this.w2r_1 + ', y=' + this.x2r_1 + ', z=' + this.y2r_1 + ', w=' + this.z2r_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.w2r_1);
    result = imul(result, 31) + getNumberHashCode(this.x2r_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.y2r_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.z2r_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Quaternion))
      return false;
    var tmp0_other_with_cast = other instanceof Quaternion ? other : THROW_CCE();
    if (!equals(this.w2r_1, tmp0_other_with_cast.w2r_1))
      return false;
    if (!equals(this.x2r_1, tmp0_other_with_cast.x2r_1))
      return false;
    if (!equals(this.y2r_1, tmp0_other_with_cast.y2r_1))
      return false;
    if (!equals(this.z2r_1, tmp0_other_with_cast.z2r_1))
      return false;
    return true;
  }
}
class Companion_14 {}
class Ray2D {
  static n2s(point, direction) {
    var $this = createThis(this);
    $this.l2s_1 = point;
    $this.m2s_1 = direction;
    return $this;
  }
  o2s() {
    return this.m2s_1.o2s();
  }
  static p2s(point, direction, unit) {
    unit = unit === VOID ? Unit_instance : unit;
    return this.n2s(point, direction.q2s());
  }
  r2s(other, epsilon) {
    return this.l2s_1.s2s(other.l2s_1, epsilon) && this.m2s_1.s2s(other.m2s_1, epsilon);
  }
  uz(other, epsilon) {
    return this.r2s(other instanceof Ray2D ? other : THROW_CCE(), epsilon);
  }
  toString() {
    return 'Ray(' + this.l2s_1.toString() + ', ' + Angle__toString_impl_786qky(this.o2s()) + ')';
  }
  hashCode() {
    var result = this.l2s_1.hashCode();
    result = imul(result, 31) + this.m2s_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Ray2D))
      return false;
    var tmp0_other_with_cast = other instanceof Ray2D ? other : THROW_CCE();
    if (!this.l2s_1.equals(tmp0_other_with_cast.l2s_1))
      return false;
    if (!this.m2s_1.equals(tmp0_other_with_cast.m2s_1))
      return false;
    return true;
  }
}
class Companion_15 {
  constructor() {
    Companion_instance_15 = this;
    var tmp = this;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_15();
    tmp.t2s_1 = new RectCorners(numberToDouble(0), numberToDouble(0), numberToDouble(0), numberToDouble(0));
    var tmp_0 = this;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_15();
    tmp_0.u2s_1 = new RectCorners(numberToDouble(0), numberToDouble(0), numberToDouble(0), numberToDouble(0));
    var tmp_1 = this;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_15();
    tmp_1.v2s_1 = new RectCorners(numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0), numberToDouble(1.0));
    var tmp_2 = this;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_15();
    var corner = -1.0;
    tmp_2.w2s_1 = new RectCorners(numberToDouble(corner), numberToDouble(corner), numberToDouble(corner), numberToDouble(corner));
    var tmp_3 = this;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    Companion_getInstance_15();
    tmp_3.x2s_1 = new RectCorners(numberToDouble(NaN), numberToDouble(NaN), numberToDouble(NaN), numberToDouble(NaN));
  }
}
class RectCorners {
  constructor(topLeft, topRight, bottomRight, bottomLeft) {
    Companion_getInstance_15();
    this.y2s_1 = topLeft;
    this.z2s_1 = topRight;
    this.a2t_1 = bottomRight;
    this.b2t_1 = bottomLeft;
  }
  c2t(scale) {
    return new RectCorners(this.y2s_1 * scale, this.z2s_1 * scale, this.a2t_1 * scale, this.b2t_1 * scale);
  }
  toString() {
    return 'RectCorners(topLeft=' + this.y2s_1 + ', topRight=' + this.z2s_1 + ', bottomRight=' + this.a2t_1 + ', bottomLeft=' + this.b2t_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.y2s_1);
    result = imul(result, 31) + getNumberHashCode(this.z2s_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.a2t_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.b2t_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof RectCorners))
      return false;
    var tmp0_other_with_cast = other instanceof RectCorners ? other : THROW_CCE();
    if (!equals(this.y2s_1, tmp0_other_with_cast.y2s_1))
      return false;
    if (!equals(this.z2s_1, tmp0_other_with_cast.z2s_1))
      return false;
    if (!equals(this.a2t_1, tmp0_other_with_cast.a2t_1))
      return false;
    if (!equals(this.b2t_1, tmp0_other_with_cast.b2t_1))
      return false;
    return true;
  }
}
class Companion_16 {
  constructor() {
    Companion_instance_16 = this;
    this.u2i_1 = Companion_getInstance_16().d2t(0, 0, 0, 0);
    this.v2i_1 = Companion_getInstance_16().e2t(-Infinity, -Infinity, Infinity, Infinity);
    this.w2i_1 = Companion_getInstance_16().e2t(NaN, NaN, 0.0, 0.0);
  }
  x2i() {
    return this.w2i_1;
  }
  l2m() {
    return this.u2i_1;
  }
  g2j(p, s) {
    return new Rectangle(p.i2h_1, p.j2h_1, s.d2j_1, s.e2j_1);
  }
  d2t(x, y, width, height) {
    return Companion_getInstance_16().g2j(Vector2D.k2l(x, y), Size2D.f2j(width, height));
  }
  e2t(x, y, width, height) {
    return Companion_getInstance_16().g2j(Vector2D.u2n(x, y), Size2D.f2t(width, height));
  }
  g2t(left, top, right, bottom) {
    return new Rectangle(left, top, right - left, bottom - top);
  }
  h2t(left, top, right, bottom) {
    return this.g2t(left, top, right, bottom);
  }
  c2j(point1, point2) {
    var tmp = Companion_getInstance_16();
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var tmp$ret$0 = Vector2D.k2h(point2.i2h_1 - point1.i2h_1, point2.j2h_1 - point1.j2h_1);
    return tmp.g2j(point1, toSize(tmp$ret$0));
  }
  i2t(a, b) {
    return a.i2i_1 >= b.i2i_1 && a.j2i_1 >= b.j2i_1 && a.i2i_1 + a.k2i_1 <= b.i2i_1 + b.k2i_1 && a.j2i_1 + a.l2i_1 <= b.j2i_1 + b.l2i_1;
  }
}
class Rectangle {
  constructor(x, y, width, height) {
    Companion_getInstance_16();
    this.i2i_1 = x;
    this.j2i_1 = y;
    this.k2i_1 = width;
    this.l2i_1 = height;
    this.m2i_1 = true;
  }
  j13() {
    return this;
  }
  j2t() {
    return this;
  }
  j1() {
    return Size2D.k2t(this.k2i_1, this.l2i_1);
  }
  l2t() {
    return isNaN_0(this.i2i_1);
  }
  n2i() {
    return this.l2t();
  }
  o2i() {
    return !this.n2i();
  }
  m2t(other, epsilon) {
    return isAlmostEquals(this.i2i_1, other.i2i_1, epsilon) && isAlmostEquals(this.j2i_1, other.j2i_1, epsilon) && isAlmostEquals(this.k2i_1, other.k2i_1, epsilon) && isAlmostEquals(this.l2i_1, other.l2i_1, epsilon);
  }
  uz(other, epsilon) {
    return this.m2t(other instanceof Rectangle ? other : THROW_CCE(), epsilon);
  }
  toString() {
    return this.n2i() ? 'null' : 'Rectangle(x=' + get_niceStr(this.i2i_1) + ', y=' + get_niceStr(this.j2i_1) + ', width=' + get_niceStr(this.k2i_1) + ', height=' + get_niceStr(this.l2i_1) + ')';
  }
  c2t(scale) {
    return new Rectangle(this.i2i_1 * scale, this.j2i_1 * scale, this.k2i_1 * scale, this.l2i_1 * scale);
  }
  e2r(scale) {
    return this.c2t(scale);
  }
  n2t(scale) {
    return new Rectangle(this.i2i_1 / scale, this.j2i_1 / scale, this.k2i_1 / scale, this.l2i_1 / scale);
  }
  u2k(that) {
    return this.o2t(that.i2h_1, that.j2h_1);
  }
  o2t(x, y) {
    return x >= this.p2i() && x < this.q2i() && (y >= this.r2i() && y < this.s2i());
  }
  p2i() {
    return this.i2i_1;
  }
  r2i() {
    return this.j2i_1;
  }
  q2i() {
    return this.i2i_1 + this.k2i_1;
  }
  s2i() {
    return this.j2i_1 + this.l2i_1;
  }
  h2j() {
    return Vector2D.k2h(this.p2i(), this.r2i());
  }
  p2t() {
    return Vector2D.k2h(this.q2i(), this.r2i());
  }
  q2t() {
    return Vector2D.k2h(this.p2i(), this.s2i());
  }
  j2j() {
    return Vector2D.k2h(this.q2i(), this.s2i());
  }
  r2t() {
    return (this.q2i() + this.p2i()) * 0.5;
  }
  s2t() {
    return (this.s2i() + this.r2i()) * 0.5;
  }
  t2t() {
    return Vector2D.k2h(this.r2t(), this.s2t());
  }
  u2t(padding) {
    return Companion_getInstance_16().g2t(this.p2i() + padding.t2l_1, this.r2i() + padding.q2l_1, this.q2i() - padding.r2l_1, this.s2i() - padding.s2l_1);
  }
  v2t(that) {
    return this.w2t(that) && this.x2t(that);
  }
  w2t(that) {
    return that.p2i() <= this.q2i() && that.q2i() >= this.p2i();
  }
  x2t(that) {
    return that.r2i() <= this.s2i() && that.s2i() >= this.r2i();
  }
  y2t(that) {
    var tmp;
    if (this.v2t(that)) {
      // Inline function 'kotlin.math.max' call
      var a = this.p2i();
      var b = that.p2i();
      var tmp_0 = Math.max(a, b);
      // Inline function 'kotlin.math.max' call
      var a_0 = this.r2i();
      var b_0 = that.r2i();
      var tmp_1 = Math.max(a_0, b_0);
      // Inline function 'kotlin.math.min' call
      var a_1 = this.q2i();
      var b_1 = that.q2i();
      var tmp_2 = Math.min(a_1, b_1);
      // Inline function 'kotlin.math.min' call
      var a_2 = this.s2i();
      var b_2 = that.s2i();
      var tmp$ret$3 = Math.min(a_2, b_2);
      tmp = new Rectangle(tmp_0, tmp_1, tmp_2, tmp$ret$3);
    } else {
      tmp = Companion_getInstance_16().x2i();
    }
    return tmp;
  }
  q2() {
    return RectangleInt.d2u(numberToInt(this.i2i_1), numberToInt(this.j2i_1), numberToInt(this.k2i_1), numberToInt(this.l2i_1));
  }
  e2u(anchor) {
    return Vector2D.k2h(this.p2i() + this.k2i_1 * anchor.y2g_1, this.r2i() + this.l2i_1 * anchor.z2g_1);
  }
  f2u(border) {
    return Companion_getInstance_16().g2t(this.p2i() - border.d2m_1, this.r2i() - border.a2m_1, this.q2i() + border.b2m_1, this.s2i() + border.c2m_1);
  }
  g2u(left, top, right, bottom) {
    return Companion_getInstance_16().g2t(left, top, right, bottom);
  }
  h2u(left, top, right, bottom, $super) {
    left = left === VOID ? this.p2i() : left;
    top = top === VOID ? this.r2i() : top;
    right = right === VOID ? this.q2i() : right;
    bottom = bottom === VOID ? this.s2i() : bottom;
    return $super === VOID ? this.g2u(left, top, right, bottom) : $super.g2u.call(this, left, top, right, bottom);
  }
  o2n(delta) {
    return this.i2u(this.i2i_1 + delta.i2h_1, this.j2i_1 + delta.j2h_1);
  }
  j2u(m) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p = this.h2j();
      if (m.n2i()) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = Vector2D.k2h(m.d2n(p.i2h_1, p.j2h_1), m.g2n(p.i2h_1, p.j2h_1));
    }
    var tl = tmp$ret$0;
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p_0 = this.p2t();
      if (m.n2i()) {
        tmp$ret$1 = p_0;
        break $l$block_0;
      }
      tmp$ret$1 = Vector2D.k2h(m.d2n(p_0.i2h_1, p_0.j2h_1), m.g2n(p_0.i2h_1, p_0.j2h_1));
    }
    var tr = tmp$ret$1;
    var tmp$ret$2;
    $l$block_1: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p_1 = this.q2t();
      if (m.n2i()) {
        tmp$ret$2 = p_1;
        break $l$block_1;
      }
      tmp$ret$2 = Vector2D.k2h(m.d2n(p_1.i2h_1, p_1.j2h_1), m.g2n(p_1.i2h_1, p_1.j2h_1));
    }
    var bl = tmp$ret$2;
    var tmp$ret$3;
    $l$block_2: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p_2 = this.j2j();
      if (m.n2i()) {
        tmp$ret$3 = p_2;
        break $l$block_2;
      }
      tmp$ret$3 = Vector2D.k2h(m.d2n(p_2.i2h_1, p_2.j2h_1), m.g2n(p_2.i2h_1, p_2.j2h_1));
    }
    var br = tmp$ret$3;
    var min = Companion_getInstance_22().a2j(tl, tr, bl, br);
    var max = Companion_getInstance_22().b2j(tl, tr, bl, br);
    return Companion_getInstance_16().c2j(min, max);
  }
  e2s() {
    return Companion_getInstance_16().c2j(Companion_getInstance_22().i2j(this.h2j(), this.j2j()), Companion_getInstance_22().k2j(this.h2j(), this.j2j()));
  }
  k2u(x, y, width, height) {
    return new Rectangle(x, y, width, height);
  }
  i2u(x, y, width, height, $super) {
    x = x === VOID ? this.i2i_1 : x;
    y = y === VOID ? this.j2i_1 : y;
    width = width === VOID ? this.k2i_1 : width;
    height = height === VOID ? this.l2i_1 : height;
    return $super === VOID ? this.k2u(x, y, width, height) : $super.k2u.call(this, x, y, width, height);
  }
  hashCode() {
    var result = getNumberHashCode(this.i2i_1);
    result = imul(result, 31) + getNumberHashCode(this.j2i_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.k2i_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.l2i_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Rectangle))
      return false;
    var tmp0_other_with_cast = other instanceof Rectangle ? other : THROW_CCE();
    if (!equals(this.i2i_1, tmp0_other_with_cast.i2i_1))
      return false;
    if (!equals(this.j2i_1, tmp0_other_with_cast.j2i_1))
      return false;
    if (!equals(this.k2i_1, tmp0_other_with_cast.k2i_1))
      return false;
    if (!equals(this.l2i_1, tmp0_other_with_cast.l2i_1))
      return false;
    return true;
  }
}
class Companion_17 {
  q2u(a, b) {
    // Inline function 'kotlin.math.min' call
    var a_0 = a.p2i();
    var b_0 = b.p2i();
    var tmp = Math.min(a_0, b_0);
    // Inline function 'kotlin.math.min' call
    var a_1 = a.r2i();
    var b_1 = b.r2i();
    var tmp_0 = Math.min(a_1, b_1);
    // Inline function 'kotlin.math.max' call
    var a_2 = a.q2i();
    var b_2 = b.q2i();
    var tmp_1 = Math.max(a_2, b_2);
    // Inline function 'kotlin.math.max' call
    var a_3 = a.s2i();
    var b_3 = b.s2i();
    var tmp$ret$3 = Math.max(a_3, b_3);
    return this.h2t(tmp, tmp_0, tmp_1, tmp$ret$3);
  }
  r2u(topLeft, bottomRight) {
    var size = bottomRight.u2u(topLeft);
    return RectangleInt.d2u(topLeft.s2u_1, topLeft.t2u_1, size.s2u_1, size.t2u_1);
  }
  h2t(left, top, right, bottom) {
    return this.r2u(Vector2I.v2u(left, top), Vector2I.v2u(right, bottom));
  }
}
class RectangleInt {
  static d2u(x, y, width, height) {
    var $this = createThis(this);
    $this.z2t_1 = x;
    $this.a2u_1 = y;
    $this.b2u_1 = width;
    $this.c2u_1 = height;
    return $this;
  }
  static w2u() {
    return this.d2u(0, 0, 0, 0);
  }
  x2u() {
    return imul(this.b2u_1, this.c2u_1);
  }
  p2i() {
    return this.z2t_1;
  }
  r2i() {
    return this.a2u_1;
  }
  q2i() {
    return this.z2t_1 + this.b2u_1 | 0;
  }
  s2i() {
    return this.a2u_1 + this.c2u_1 | 0;
  }
  y2u(left, top, right, bottom, clamped) {
    var left_0 = !clamped ? left : coerceIn(left, 0, this.b2u_1);
    var right_0 = !clamped ? right : coerceIn(right, 0, this.b2u_1);
    var top_0 = !clamped ? top : coerceIn(top, 0, this.c2u_1);
    var bottom_0 = !clamped ? bottom : coerceIn(bottom, 0, this.c2u_1);
    return Companion_instance_17.h2t(this.z2t_1 + left_0 | 0, this.a2u_1 + top_0 | 0, this.z2t_1 + right_0 | 0, this.a2u_1 + bottom_0 | 0);
  }
  toString() {
    return 'Rectangle(x=' + this.z2t_1 + ', y=' + this.a2u_1 + ', width=' + this.b2u_1 + ', height=' + this.c2u_1 + ')';
  }
  z2u() {
    return Companion_getInstance_16().d2t(this.z2t_1, this.a2u_1, this.b2u_1, this.c2u_1);
  }
  j1() {
    return SizeInt.c2v(this.b2u_1, this.c2u_1);
  }
  f2u(border) {
    return Companion_instance_17.h2t(this.p2i() - border.d2m_1 | 0, this.r2i() - border.a2m_1 | 0, this.q2i() + border.b2m_1 | 0, this.s2i() + border.c2m_1 | 0);
  }
  hashCode() {
    var result = this.z2t_1;
    result = imul(result, 31) + this.a2u_1 | 0;
    result = imul(result, 31) + this.b2u_1 | 0;
    result = imul(result, 31) + this.c2u_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof RectangleInt))
      return false;
    var tmp0_other_with_cast = other instanceof RectangleInt ? other : THROW_CCE();
    if (!(this.z2t_1 === tmp0_other_with_cast.z2t_1))
      return false;
    if (!(this.a2u_1 === tmp0_other_with_cast.a2u_1))
      return false;
    if (!(this.b2u_1 === tmp0_other_with_cast.b2u_1))
      return false;
    if (!(this.c2u_1 === tmp0_other_with_cast.c2u_1))
      return false;
    return true;
  }
}
class Companion_18 {
  constructor() {
    Companion_instance_18 = this;
    this.d2v_1 = Scale.e2v(1.0, 1.0);
  }
}
class Scale {
  static s2r(scaleX, scaleY) {
    Companion_getInstance_18();
    var $this = createThis(this);
    $this.q2r_1 = scaleX;
    $this.r2r_1 = scaleY;
    return $this;
  }
  x2o() {
    return this.q2r_1 * 0.5 + this.r2r_1 * 0.5;
  }
  static f2v() {
    Companion_getInstance_18();
    return this.e2v(1.0, 1.0);
  }
  static g2v(scale) {
    Companion_getInstance_18();
    return this.s2r(scale, scale);
  }
  static h2v(scale) {
    Companion_getInstance_18();
    return this.g2v(scale);
  }
  static e2v(scaleX, scaleY) {
    Companion_getInstance_18();
    return this.s2r(scaleX, scaleY);
  }
  static i2v(scaleX, scaleY) {
    Companion_getInstance_18();
    return this.s2r(scaleX, scaleY);
  }
  toString() {
    return 'Scale(scaleX=' + this.q2r_1 + ', scaleY=' + this.r2r_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.q2r_1);
    result = imul(result, 31) + getNumberHashCode(this.r2r_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Scale))
      return false;
    var tmp0_other_with_cast = other instanceof Scale ? other : THROW_CCE();
    if (!equals(this.q2r_1, tmp0_other_with_cast.q2r_1))
      return false;
    if (!equals(this.r2r_1, tmp0_other_with_cast.r2r_1))
      return false;
    return true;
  }
}
class Companion_19 {
  constructor() {
    Companion_instance_19 = this;
    var tmp = this;
    tmp.m2v_1 = new ScaleMode('COVER', ScaleMode$Companion$COVER$lambda);
    var tmp_0 = this;
    tmp_0.n2v_1 = new ScaleMode('SHOW_ALL', ScaleMode$Companion$SHOW_ALL$lambda);
    var tmp_1 = this;
    tmp_1.o2v_1 = new ScaleMode('EXACT', ScaleMode$Companion$EXACT$lambda);
    var tmp_2 = this;
    tmp_2.p2v_1 = new ScaleMode('NO_SCALE', ScaleMode$Companion$NO_SCALE$lambda);
  }
  q2v() {
    return this.n2v_1;
  }
  r2v() {
    return this.o2v_1;
  }
}
class ScaleMode {
  constructor(name, transform) {
    Companion_getInstance_19();
    name = name === VOID ? null : name;
    this.m2u_1 = name;
    this.n2u_1 = transform;
  }
  toString() {
    return 'ScaleMode(' + this.m2u_1 + ')';
  }
  o2u(item, container) {
    return this.n2u_1(item, container);
  }
  s2v(item, container) {
    return toInt(this.n2u_1(toFloat(item), toFloat(container)));
  }
}
class Companion_20 {
  constructor() {
    Companion_instance_20 = this;
    this.t2v_1 = Size2D.k2t(0.0, 0.0);
  }
}
class Size2D {
  static k2t(width, height) {
    Companion_getInstance_20();
    var $this = createThis(this);
    $this.d2j_1 = width;
    $this.e2j_1 = height;
    return $this;
  }
  u2v() {
    return this.d2j_1 * 0.5 + this.e2j_1 * 0.5;
  }
  x2u() {
    return this.d2j_1 * this.e2j_1;
  }
  static v2v() {
    Companion_getInstance_20();
    return this.k2t(0.0, 0.0);
  }
  static f2t(width, height) {
    Companion_getInstance_20();
    return this.k2t(width, height);
  }
  static f2j(width, height) {
    Companion_getInstance_20();
    return this.k2t(width, height);
  }
  p2u(other) {
    return Size2D.k2t(this.d2j_1 - other.d2j_1, this.e2j_1 - other.e2j_1);
  }
  w2v(other) {
    return Size2D.k2t(this.d2j_1 + other.d2j_1, this.e2j_1 + other.e2j_1);
  }
  x2v(scale) {
    return Size2D.k2t(this.d2j_1 * scale.q2r_1, this.e2j_1 * scale.r2r_1);
  }
  e2r(s) {
    return Size2D.k2t(this.d2j_1 * s, this.e2j_1 * s);
  }
  c2t(s) {
    return this.e2r(s);
  }
  y2v(s) {
    return this.e2r(s);
  }
  j2v(other) {
    return Scale.s2r(this.d2j_1 / other.d2j_1, this.e2j_1 / other.e2j_1);
  }
  z2v(s) {
    return Size2D.k2t(this.d2j_1 / s, this.e2j_1 / s);
  }
  toString() {
    return 'Size(width=' + get_niceStr(this.d2j_1) + ', height=' + get_niceStr(this.e2j_1) + ')';
  }
  km() {
    return this.d2j_1;
  }
  lm() {
    return this.e2j_1;
  }
  a2w(width, height) {
    return Size2D.k2t(width, height);
  }
  b2w(width, height, $super) {
    width = width === VOID ? this.d2j_1 : width;
    height = height === VOID ? this.e2j_1 : height;
    return $super === VOID ? this.a2w(width, height) : $super.a2w.call(this, width, height);
  }
  hashCode() {
    var result = getNumberHashCode(this.d2j_1);
    result = imul(result, 31) + getNumberHashCode(this.e2j_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Size2D))
      return false;
    var tmp0_other_with_cast = other instanceof Size2D ? other : THROW_CCE();
    if (!equals(this.d2j_1, tmp0_other_with_cast.d2j_1))
      return false;
    if (!equals(this.e2j_1, tmp0_other_with_cast.e2j_1))
      return false;
    return true;
  }
}
class SizeInt {
  static c2v(width, height) {
    var $this = createThis(this);
    $this.a2v_1 = width;
    $this.b2v_1 = height;
    return $this;
  }
  static c2w() {
    return this.c2v(0, 0);
  }
  toString() {
    return '' + this.a2v_1 + 'x' + this.b2v_1;
  }
  hashCode() {
    var result = this.a2v_1;
    result = imul(result, 31) + this.b2v_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof SizeInt))
      return false;
    var tmp0_other_with_cast = other instanceof SizeInt ? other : THROW_CCE();
    if (!(this.a2v_1 === tmp0_other_with_cast.a2v_1))
      return false;
    if (!(this.b2v_1 === tmp0_other_with_cast.b2v_1))
      return false;
    return true;
  }
}
class Companion_21 {
  constructor() {
    Companion_instance_21 = this;
    this.d2w_1 = new Spacing(0.0, 0.0);
  }
}
class Spacing {
  constructor(vertical, horizontal) {
    Companion_getInstance_21();
    this.e2w_1 = vertical;
    this.f2w_1 = horizontal;
  }
  toString() {
    return 'Spacing(vertical=' + get_niceStr(this.e2w_1) + ', horizontal=' + get_niceStr(this.f2w_1) + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.e2w_1);
    result = imul(result, 31) + getNumberHashCode(this.f2w_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Spacing))
      return false;
    var tmp0_other_with_cast = other instanceof Spacing ? other : THROW_CCE();
    if (!equals(this.e2w_1, tmp0_other_with_cast.e2w_1))
      return false;
    if (!equals(this.f2w_1, tmp0_other_with_cast.f2w_1))
      return false;
    return true;
  }
}
class Companion_22 {
  constructor() {
    Companion_instance_22 = this;
    this.a2i_1 = Vector2D.k2h(0.0, 0.0);
    this.b2i_1 = Vector2D.k2h(NaN, NaN);
    this.c2i_1 = Vector2D.k2h(-1.0, 0.0);
    this.d2i_1 = Vector2D.k2h(+1.0, 0.0);
    this.e2i_1 = Vector2D.k2h(0.0, +1.0);
    this.f2i_1 = Vector2D.k2h(0.0, -1.0);
    this.g2i_1 = Vector2D.k2h(0.0, -1.0);
    this.h2i_1 = Vector2D.k2h(0.0, +1.0);
  }
  g2w(a, b, up) {
    // Inline function 'korlibs.math.geom.Companion.between' call
    // Inline function 'korlibs.math.geom.Companion.between' call
    Companion_getInstance_0();
    var x0 = a.i2h_1;
    var y0 = a.j2h_1;
    var x1 = b.i2h_1;
    var y1 = b.j2h_1;
    return Angle_between(x0, y0, x1, y1, up);
  }
  h2w(a, b, up, $super) {
    up = up === VOID ? this.e2i_1 : up;
    return $super === VOID ? this.g2w(a, b, up) : $super.g2w.call(this, a, b, up).p2h_1;
  }
  i2w(a, b, up) {
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    Companion_getInstance_0();
    // Inline function 'kotlin.math.acos' call
    var x = a.j2w(b) / (a.a() * b.a());
    var radians = Math.acos(x);
    var tmp$ret$1 = _Angle___init__impl__g23q1m(radians);
    return adjustFromUp(tmp$ret$1, up);
  }
  k2w(a, b, up, $super) {
    up = up === VOID ? this.e2i_1 : up;
    return $super === VOID ? this.i2w(a, b, up) : $super.i2w.call(this, a, b, up).p2h_1;
  }
  l2u(x1, y1, x2, y2) {
    // Inline function 'kotlin.math.hypot' call
    var x = x1 - x2;
    var y = y1 - y2;
    return hypot(x, y);
  }
  l2w(x1, y1, x2, y2) {
    // Inline function 'kotlin.math.hypot' call
    var x = x1 - x2;
    var y = y1 - y2;
    return hypot(x, y);
  }
  m2w(x1, y1, x2, y2) {
    // Inline function 'kotlin.math.hypot' call
    var x = x1 - x2;
    var y = y1 - y2;
    return hypot(x, y);
  }
  n2w(a, b) {
    return this.l2u(a.i2h_1, a.j2h_1, b.i2h_1, b.j2h_1);
  }
  q2j(a, b) {
    return this.o2w(a.i2h_1, a.j2h_1, b.i2h_1, b.j2h_1);
  }
  o2w(x1, y1, x2, y2) {
    return square(this, x1 - x2) + square(this, y1 - y2);
  }
  p2w(ax, ay, bx, by) {
    return ax * by - bx * ay;
  }
  q2w(p1, p2) {
    return this.p2w(p1.i2h_1, p1.j2h_1, p2.i2h_1, p2.j2h_1);
  }
  i2j(p1, p2) {
    // Inline function 'kotlin.math.min' call
    var a = p1.i2h_1;
    var b = p2.i2h_1;
    var tmp = Math.min(a, b);
    // Inline function 'kotlin.math.min' call
    var a_0 = p1.j2h_1;
    var b_0 = p2.j2h_1;
    var tmp$ret$1 = Math.min(a_0, b_0);
    return Vector2D.k2h(tmp, tmp$ret$1);
  }
  a2j(p1, p2, p3, p4) {
    return Vector2D.k2h(minOf(p1.i2h_1, new Float64Array([p2.i2h_1, p3.i2h_1, p4.i2h_1])), minOf(p1.j2h_1, new Float64Array([p2.j2h_1, p3.j2h_1, p4.j2h_1])));
  }
  k2j(p1, p2) {
    // Inline function 'kotlin.math.max' call
    var a = p1.i2h_1;
    var b = p2.i2h_1;
    var tmp = Math.max(a, b);
    // Inline function 'kotlin.math.max' call
    var a_0 = p1.j2h_1;
    var b_0 = p2.j2h_1;
    var tmp$ret$1 = Math.max(a_0, b_0);
    return Vector2D.k2h(tmp, tmp$ret$1);
  }
  b2j(p1, p2, p3, p4) {
    return Vector2D.k2h(maxOf(p1.i2h_1, new Float64Array([p2.i2h_1, p3.i2h_1, p4.i2h_1])), maxOf(p1.j2h_1, new Float64Array([p2.j2h_1, p3.j2h_1, p4.j2h_1])));
  }
}
class Vector2D {
  static k2h(x, y) {
    Companion_getInstance_22();
    var $this = createThis(this);
    $this.i2h_1 = x;
    $this.j2h_1 = y;
    return $this;
  }
  static u2n(x, y) {
    Companion_getInstance_22();
    return this.k2h(x, y);
  }
  static k2l(x, y) {
    Companion_getInstance_22();
    return this.k2h(x, y);
  }
  static i2l() {
    Companion_getInstance_22();
    return this.k2h(0.0, 0.0);
  }
  l2v() {
    // Inline function 'kotlin.math.min' call
    var a = this.i2h_1;
    var b = this.j2h_1;
    return Math.min(a, b);
  }
  k2v() {
    // Inline function 'kotlin.math.max' call
    var a = this.i2h_1;
    var b = this.j2h_1;
    return Math.max(a, b);
  }
  r2w(x, y) {
    // Inline function 'kotlin.math.hypot' call
    var x_0 = x - this.i2h_1;
    var y_0 = y - this.j2h_1;
    return hypot(x_0, y_0);
  }
  s2w(that) {
    return this.r2w(that.i2h_1, that.j2h_1);
  }
  j2w(that) {
    return this.i2h_1 * that.i2h_1 + this.j2h_1 * that.j2h_1;
  }
  o2s() {
    return this.t2w();
  }
  u2w(up) {
    // Inline function 'korlibs.math.geom.Companion.between' call
    Companion_getInstance_0();
    var x1 = this.i2h_1;
    var y1 = this.j2h_1;
    return Angle_between(0.0, 0.0, x1, y1, up);
  }
  t2w(up, $super) {
    up = up === VOID ? Companion_getInstance_22().e2i_1 : up;
    return $super === VOID ? this.u2w(up) : $super.u2w.call(this, up).p2h_1;
  }
  a() {
    // Inline function 'kotlin.math.hypot' call
    var x = this.i2h_1;
    var y = this.j2h_1;
    return hypot(x, y);
  }
  v2w() {
    var x = this.i2h_1;
    var y = this.j2h_1;
    return x * x + y * y;
  }
  w2w() {
    // Inline function 'kotlin.math.hypot' call
    var x = this.i2h_1;
    var y = this.j2h_1;
    return hypot(x, y);
  }
  q2s() {
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale = 1.0 / this.w2w();
    return Vector2D.k2h(this.i2h_1 * scale, this.j2h_1 * scale);
  }
  x2w() {
    // Inline function 'korlibs.math.geom.Vector2D.div' call
    var scale = this.a();
    return Vector2D.k2h(this.i2h_1 / scale, this.j2h_1 / scale);
  }
  y2w() {
    return Vector2D.k2h(-this.j2h_1, this.i2h_1);
  }
  s2s(other, epsilon) {
    return isAlmostEquals(this.i2h_1, other.i2h_1, epsilon) && isAlmostEquals(this.j2h_1, other.j2h_1, epsilon);
  }
  uz(other, epsilon) {
    return this.s2s(other instanceof Vector2D ? other : THROW_CCE(), epsilon);
  }
  z2w() {
    return '(' + get_niceStr(this.i2h_1) + ', ' + get_niceStr(this.j2h_1) + ')';
  }
  toString() {
    return this.z2w();
  }
  a2x(normal) {
    var d = this;
    var n = normal;
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var that = times_1(2.0 * d.j2w(n), n);
    return Vector2D.k2h(d.i2h_1 - that.i2h_1, d.j2h_1 - that.j2h_1);
  }
  km() {
    return this.i2h_1;
  }
  lm() {
    return this.j2h_1;
  }
  hashCode() {
    var result = getNumberHashCode(this.i2h_1);
    result = imul(result, 31) + getNumberHashCode(this.j2h_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2D))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2D ? other : THROW_CCE();
    if (!equals(this.i2h_1, tmp0_other_with_cast.i2h_1))
      return false;
    if (!equals(this.j2h_1, tmp0_other_with_cast.j2h_1))
      return false;
    return true;
  }
}
class Companion_23 {
  constructor() {
    Companion_instance_23 = this;
    this.m2r_1 = new Vector4F(0.0, 0.0, 0.0, 0.0);
    this.n2r_1 = new Vector4F(1.0, 1.0, 1.0, 1.0);
  }
  o2r(array, offset) {
    return new Vector4F(array[offset + 0 | 0], array[offset + 1 | 0], array[offset + 2 | 0], array[offset + 3 | 0]);
  }
}
class Vector4F {
  constructor(x, y, z, w) {
    Companion_getInstance_23();
    this.w2p_1 = x;
    this.x2p_1 = y;
    this.y2p_1 = z;
    this.z2p_1 = w;
  }
  v2w() {
    return this.w2p_1 * this.w2p_1 + this.x2p_1 * this.x2p_1 + this.y2p_1 * this.y2p_1 + this.z2p_1 * this.z2p_1;
  }
  a() {
    // Inline function 'kotlin.math.sqrt' call
    var x = this.v2w();
    return Math.sqrt(x);
  }
  e2r(v) {
    return new Vector4F(this.w2p_1 * v, this.x2p_1 * v, this.y2p_1 * v, this.z2p_1 * v);
  }
  toString() {
    return 'Vector4(' + get_niceStr_0(this.w2p_1) + ', ' + get_niceStr_0(this.x2p_1) + ', ' + get_niceStr_0(this.y2p_1) + ', ' + get_niceStr_0(this.z2p_1) + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.w2p_1);
    result = imul(result, 31) + getNumberHashCode(this.x2p_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.y2p_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.z2p_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector4F))
      return false;
    var tmp0_other_with_cast = other instanceof Vector4F ? other : THROW_CCE();
    if (!equals(this.w2p_1, tmp0_other_with_cast.w2p_1))
      return false;
    if (!equals(this.x2p_1, tmp0_other_with_cast.x2p_1))
      return false;
    if (!equals(this.y2p_1, tmp0_other_with_cast.y2p_1))
      return false;
    if (!equals(this.z2p_1, tmp0_other_with_cast.z2p_1))
      return false;
    return true;
  }
}
class Companion_24 {
  constructor() {
    Companion_instance_24 = this;
    this.b2x_1 = Vector3F.n2x(NaN, NaN, NaN);
    this.c2x_1 = Vector3F.n2x(0.0, 0.0, 0.0);
    this.d2x_1 = Vector3F.n2x(1.0, 1.0, 1.0);
    this.e2x_1 = Vector3F.n2x(0.0, 0.0, 1.0);
    this.f2x_1 = Vector3F.n2x(0.0, 0.0, -1.0);
    this.g2x_1 = Vector3F.n2x(-1.0, 0.0, 0.0);
    this.h2x_1 = Vector3F.n2x(1.0, 0.0, 0.0);
    this.i2x_1 = Vector3F.n2x(0.0, 1.0, 0.0);
    this.j2x_1 = Vector3F.n2x(0.0, -1.0, 0.0);
  }
}
class Vector3F {
  static n2x(x, y, z) {
    Companion_getInstance_24();
    var $this = createThis(this);
    $this.k2x_1 = x;
    $this.l2x_1 = y;
    $this.m2x_1 = z;
    return $this;
  }
  static o2x(x, y, z) {
    Companion_getInstance_24();
    return this.n2x(x, y, z);
  }
  p2x(v) {
    return Vector3F.n2x(this.k2x_1 + v.k2x_1, this.l2x_1 + v.l2x_1, this.m2x_1 + v.m2x_1);
  }
  e2r(v) {
    return Vector3F.n2x(this.k2x_1 * v, this.l2x_1 * v, this.m2x_1 * v);
  }
  toString() {
    return 'Vector3(' + get_niceStr_0(this.k2x_1) + ', ' + get_niceStr_0(this.l2x_1) + ', ' + get_niceStr_0(this.m2x_1) + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.k2x_1);
    result = imul(result, 31) + getNumberHashCode(this.l2x_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.m2x_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector3F))
      return false;
    var tmp0_other_with_cast = other instanceof Vector3F ? other : THROW_CCE();
    if (!equals(this.k2x_1, tmp0_other_with_cast.k2x_1))
      return false;
    if (!equals(this.l2x_1, tmp0_other_with_cast.l2x_1))
      return false;
    if (!equals(this.m2x_1, tmp0_other_with_cast.m2x_1))
      return false;
    return true;
  }
}
class Companion_25 {
  constructor() {
    Companion_instance_25 = this;
    this.q2x_1 = Vector2F.a2y(0.0, 0.0);
    this.r2x_1 = Vector2F.a2y(NaN, NaN);
    this.s2x_1 = Vector2F.a2y(-1.0, 0.0);
    this.t2x_1 = Vector2F.a2y(+1.0, 0.0);
    this.u2x_1 = Vector2F.a2y(0.0, +1.0);
    this.v2x_1 = Vector2F.a2y(0.0, -1.0);
    this.w2x_1 = Vector2F.a2y(0.0, -1.0);
    this.x2x_1 = Vector2F.a2y(0.0, +1.0);
  }
}
class Vector2F {
  static a2y(x, y) {
    Companion_getInstance_25();
    var $this = createThis(this);
    $this.y2x_1 = x;
    $this.z2x_1 = y;
    return $this;
  }
  static b2y(x, y) {
    Companion_getInstance_25();
    return this.a2y(x, y);
  }
  static c2y() {
    Companion_getInstance_25();
    return this.a2y(0.0, 0.0);
  }
  z2w() {
    return '(' + get_niceStr_0(this.y2x_1) + ', ' + get_niceStr_0(this.z2x_1) + ')';
  }
  toString() {
    return this.z2w();
  }
  hashCode() {
    var result = getNumberHashCode(this.y2x_1);
    result = imul(result, 31) + getNumberHashCode(this.z2x_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2F))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2F ? other : THROW_CCE();
    if (!equals(this.y2x_1, tmp0_other_with_cast.y2x_1))
      return false;
    if (!equals(this.z2x_1, tmp0_other_with_cast.z2x_1))
      return false;
    return true;
  }
}
class Companion_26 {
  constructor() {
    Companion_instance_26 = this;
    this.d2y_1 = Vector2I.v2u(0, 0);
  }
}
class Vector2I {
  static v2u(x, y) {
    Companion_getInstance_26();
    var $this = createThis(this);
    $this.s2u_1 = x;
    $this.t2u_1 = y;
    return $this;
  }
  static e2y() {
    Companion_getInstance_26();
    return this.v2u(0, 0);
  }
  f2y(that) {
    return Vector2I.v2u(this.s2u_1 + that.s2u_1 | 0, this.t2u_1 + that.t2u_1 | 0);
  }
  u2u(that) {
    return Vector2I.v2u(this.s2u_1 - that.s2u_1 | 0, this.t2u_1 - that.t2u_1 | 0);
  }
  toString() {
    return '(' + this.s2u_1 + ', ' + this.t2u_1 + ')';
  }
  hashCode() {
    var result = this.s2u_1;
    result = imul(result, 31) + this.t2u_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2I))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2I ? other : THROW_CCE();
    if (!(this.s2u_1 === tmp0_other_with_cast.s2u_1))
      return false;
    if (!(this.t2u_1 === tmp0_other_with_cast.t2u_1))
      return false;
    return true;
  }
}
//endregion
var Companion_instance;
function Companion_getInstance() {
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
function times(_this__u8e3s4, anchor) {
  // Inline function 'korlibs.math.geom.Vector2D.times' call
  var this_0 = toVector(_this__u8e3s4);
  var that = anchor.h2h();
  return Vector2D.k2h(this_0.i2h_1 * that.i2h_1, this_0.j2h_1 * that.j2h_1);
}
function _Angle___init__impl__g23q1m(radians) {
  return radians;
}
function _Angle___get_radians__impl__n00yt5($this) {
  return $this;
}
function _Angle___get_ratio__impl__ap3on4($this) {
  // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
  Companion_getInstance_0();
  var radians = _Angle___get_radians__impl__n00yt5($this);
  return _Ratio___init__impl__9mwvn2(radians / 6.283185307179586);
}
function _Angle___get_degrees__impl__qg56vw($this) {
  // Inline function 'korlibs.math.geom.Companion.radiansToDegrees' call
  Companion_getInstance_0();
  return _Angle___get_radians__impl__n00yt5($this) * 57.29577951308232;
}
function _Angle___get_cosine__impl__vprvmu($this) {
  // Inline function 'kotlin.math.cos' call
  var x = _Angle___get_radians__impl__n00yt5($this);
  return Math.cos(x);
}
function _Angle___get_sine__impl__15nmyi($this) {
  // Inline function 'kotlin.math.sin' call
  var x = _Angle___get_radians__impl__n00yt5($this);
  return Math.sin(x);
}
function _Angle___get_tangent__impl__vushdy($this) {
  // Inline function 'kotlin.math.tan' call
  var x = _Angle___get_radians__impl__n00yt5($this);
  return Math.tan(x);
}
function Angle__cosine_impl_bgvzun($this, up) {
  return _Angle___get_cosine__impl__vprvmu(adjustFromUp($this, up));
}
function Angle__sine_impl_3l11xv($this, up) {
  return _Angle___get_sine__impl__15nmyi(adjustFromUp($this, up));
}
function _Angle___get_absoluteValue__impl__4qoakv($this) {
  // Inline function 'kotlin.math.absoluteValue' call
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var this_0 = _Angle___get_radians__impl__n00yt5($this);
  var tmp$ret$1 = Math.abs(this_0);
  return _Angle___init__impl__g23q1m(tmp$ret$1);
}
function Angle__times_impl_v3s9y2($this, scale) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
  return _Angle___init__impl__g23q1m(tmp$ret$0 * scale);
}
function Angle__div_impl_ut2btd($this, scale) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
  return _Angle___init__impl__g23q1m(tmp$ret$0 / scale);
}
function Angle__times_impl_v3s9y2_0($this, scale) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
  return _Angle___init__impl__g23q1m(tmp$ret$0 * scale);
}
function Angle__times_impl_v3s9y2_1($this, scale) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
  return _Angle___init__impl__g23q1m(tmp$ret$0 * scale);
}
function Angle__umod_impl_4wilf7($this, angle) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp = _Angle___get_radians__impl__n00yt5($this);
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$1 = _Angle___get_radians__impl__n00yt5(angle);
  return _Angle___init__impl__g23q1m(umod(tmp, tmp$ret$1));
}
function Angle__div_impl_ut2btd_0($this, other) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp = _Angle___get_radians__impl__n00yt5($this);
  // Inline function 'korlibs.math.geom.Angle.internal' call
  return tmp / _Angle___get_radians__impl__n00yt5(other);
}
function Angle__plus_impl_26v37k($this, other) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp = _Angle___get_radians__impl__n00yt5($this);
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$1 = _Angle___get_radians__impl__n00yt5(other);
  return _Angle___init__impl__g23q1m(tmp + tmp$ret$1);
}
function Angle__minus_impl_xeau7k($this, other) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp = _Angle___get_radians__impl__n00yt5($this);
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$1 = _Angle___get_radians__impl__n00yt5(other);
  return _Angle___init__impl__g23q1m(tmp - tmp$ret$1);
}
function Angle__unaryMinus_impl_7901fr($this) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
  return _Angle___init__impl__g23q1m(-tmp$ret$0);
}
function Angle__unaryPlus_impl_eyi5pl($this) {
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
  return _Angle___init__impl__g23q1m(+tmp$ret$0);
}
function Angle__isAlmostEquals_impl_201sgt($this, other, epsilon) {
  return isAlmostEquals(_Angle___get_radians__impl__n00yt5($this), _Angle___get_radians__impl__n00yt5(other), epsilon);
}
function Angle__isAlmostEquals_impl_201sgt_0($this, other, epsilon) {
  return Angle__isAlmostEquals_impl_201sgt($this.p2h_1, other instanceof Angle ? other.p2h_1 : THROW_CCE(), epsilon);
}
function _Angle___get_normalized__impl__9s30ys($this) {
  // Inline function 'korlibs.math.geom.Companion.fromRatio' call
  Companion_getInstance_0();
  var ratio = umod(Ratio__toDouble_impl_o7epze(_Angle___get_ratio__impl__ap3on4($this)), 1.0);
  // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
  var ratio_0 = toRatio(ratio);
  var tmp$ret$0 = Ratio__times_impl_ucdh7y(ratio_0, 6.283185307179586);
  return _Angle___init__impl__g23q1m(tmp$ret$0);
}
function Angle__compareTo_impl_a0hfds($this, other) {
  return Ratio__compareTo_impl_z2ienc(_Angle___get_ratio__impl__ap3on4($this), _Angle___get_ratio__impl__ap3on4(other));
}
function Angle__compareTo_impl_a0hfds_0($this, other) {
  return Angle__compareTo_impl_a0hfds($this.p2h_1, other instanceof Angle ? other.p2h_1 : THROW_CCE());
}
function Angle__toString_impl_786qky($this) {
  return get_niceStr(roundDecimalPlaces(_Angle___get_degrees__impl__qg56vw($this), 2)) + '.degrees';
}
var Companion_instance_0;
function Companion_getInstance_0() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function Angle__hashCode_impl_szcndt($this) {
  return getNumberHashCode($this);
}
function Angle__equals_impl_zcf5mt($this, other) {
  if (!(other instanceof Angle))
    return false;
  var tmp0_other_with_cast = other instanceof Angle ? other.p2h_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function get_degrees(_this__u8e3s4) {
  // Inline function 'korlibs.math.geom.Companion.fromDegrees' call
  // Inline function 'korlibs.math.geom.Companion.degreesToRadians' call
  Companion_getInstance_0();
  var tmp$ret$0 = _this__u8e3s4 * 0.017453292519943295;
  return _Angle___init__impl__g23q1m(tmp$ret$0);
}
function get_radians(_this__u8e3s4) {
  // Inline function 'korlibs.math.geom.Companion.fromRadians' call
  Companion_getInstance_0();
  return _Angle___init__impl__g23q1m(_this__u8e3s4);
}
function interpolateAngleDenormalized(_this__u8e3s4, l, r) {
  return interpolateAngle_0(_this__u8e3s4, l, r, false);
}
function get_radians_0(_this__u8e3s4) {
  // Inline function 'korlibs.math.geom.Companion.fromRadians' call
  Companion_getInstance_0();
  return _Angle___init__impl__g23q1m(_this__u8e3s4);
}
function get_degrees_0(_this__u8e3s4) {
  // Inline function 'korlibs.math.geom.Companion.fromDegrees' call
  // Inline function 'korlibs.math.geom.Companion.degreesToRadians' call
  Companion_getInstance_0();
  var tmp$ret$0 = _this__u8e3s4 * 0.017453292519943295;
  return _Angle___init__impl__g23q1m(tmp$ret$0);
}
function get_radians_1(_this__u8e3s4) {
  // Inline function 'korlibs.math.geom.Companion.fromRadians' call
  Companion_getInstance_0();
  return _Angle___init__impl__g23q1m(_this__u8e3s4);
}
function interpolateAngle(_this__u8e3s4, l, r) {
  return interpolateAngle_0(_this__u8e3s4, l, r, true);
}
function adjustFromUp(_this__u8e3s4, up) {
  Companion_instance_12.z2h(up);
  return up.j2h_1 > 0 ? _this__u8e3s4 : Angle__unaryMinus_impl_7901fr(_this__u8e3s4);
}
function Angle_between(x0, y0, x1, y1, up) {
  up = up === VOID ? Companion_getInstance_22().e2i_1 : up;
  // Inline function 'korlibs.math.geom.Companion.atan2' call
  Companion_getInstance_0();
  var x = y1 - y0;
  var y = x1 - x0;
  var up_0 = Companion_getInstance_22().e2i_1;
  // Inline function 'korlibs.math.geom.Companion.fromRadians' call
  // Inline function 'kotlin.math.atan2' call
  var radians = Math.atan2(x, y);
  var tmp$ret$1 = _Angle___init__impl__g23q1m(radians);
  var angle = adjustFromUp(tmp$ret$1, up_0);
  return adjustFromUp(Angle__compareTo_impl_a0hfds(angle, Companion_getInstance_0().r2h_1) < 0 ? Angle__plus_impl_26v37k(angle, Companion_getInstance_0().v2h_1) : angle, up);
}
function interpolateAngle_0(_this__u8e3s4, l, r, minimizeAngle) {
  return _interpolateAngleAny(_this__u8e3s4, l, r, minimizeAngle);
}
function _interpolateAngleAny(ratio, l, r, minimizeAngle) {
  minimizeAngle = minimizeAngle === VOID ? true : minimizeAngle;
  if (!minimizeAngle) {
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    Companion_getInstance_0();
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    var ratio_0 = interpolate_0(ratio, _Angle___get_ratio__impl__ap3on4(l), _Angle___get_ratio__impl__ap3on4(r));
    var tmp$ret$0 = Ratio__times_impl_ucdh7y(ratio_0, 6.283185307179586);
    return _Angle___init__impl__g23q1m(tmp$ret$0);
  }
  var ln = _Angle___get_normalized__impl__9s30ys(l);
  var rn = _Angle___get_normalized__impl__9s30ys(r);
  var tmp;
  if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(Angle__minus_impl_xeau7k(rn, ln)), Companion_getInstance_0().t2h_1) <= 0) {
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    Companion_getInstance_0();
    var radians = interpolate(ratio, _Angle___get_radians__impl__n00yt5(ln), _Angle___get_radians__impl__n00yt5(rn));
    tmp = _Angle___init__impl__g23q1m(radians);
  } else if (Angle__compareTo_impl_a0hfds(ln, rn) < 0) {
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    Companion_getInstance_0();
    var radians_0 = interpolate(ratio, _Angle___get_radians__impl__n00yt5(Angle__plus_impl_26v37k(ln, Companion_getInstance_0().v2h_1)), _Angle___get_radians__impl__n00yt5(rn));
    var tmp$ret$3 = _Angle___init__impl__g23q1m(radians_0);
    tmp = _Angle___get_normalized__impl__9s30ys(tmp$ret$3);
  } else {
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    Companion_getInstance_0();
    var radians_1 = interpolate(ratio, _Angle___get_radians__impl__n00yt5(ln), _Angle___get_radians__impl__n00yt5(Angle__plus_impl_26v37k(rn, Companion_getInstance_0().v2h_1)));
    var tmp$ret$4 = _Angle___init__impl__g23q1m(radians_1);
    tmp = _Angle___get_normalized__impl__9s30ys(tmp$ret$4);
  }
  return tmp;
}
function interpolateAngleNormalized(_this__u8e3s4, l, r) {
  return interpolateAngle_0(_this__u8e3s4, l, r, true);
}
function _BoundsBuilder___init__impl__g6dprh(bounds) {
  return bounds;
}
function _BoundsBuilder___get_bounds__impl__s1l1gx($this) {
  return $this;
}
function _BoundsBuilder___get_isEmpty__impl__e9t785($this) {
  return _BoundsBuilder___get_bounds__impl__s1l1gx($this).n2i();
}
function _BoundsBuilder___get_isNotEmpty__impl__w6rbsy($this) {
  return _BoundsBuilder___get_bounds__impl__s1l1gx($this).o2i();
}
function _BoundsBuilder___get_xmin__impl__o2sib0($this) {
  // Inline function 'kotlin.math.min' call
  var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).p2i();
  var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).q2i();
  return Math.min(a, b);
}
function _BoundsBuilder___get_xmax__impl__gsz0km($this) {
  // Inline function 'kotlin.math.max' call
  var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).p2i();
  var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).q2i();
  return Math.max(a, b);
}
function _BoundsBuilder___get_ymin__impl__5n2e8j($this) {
  // Inline function 'kotlin.math.min' call
  var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).r2i();
  var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).s2i();
  return Math.min(a, b);
}
function _BoundsBuilder___get_ymax__impl__oia4uz($this) {
  // Inline function 'kotlin.math.max' call
  var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).r2i();
  var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).s2i();
  return Math.max(a, b);
}
function BoundsBuilder__xminOr_impl_k3l1m2($this, default_0) {
  return _BoundsBuilder___get_hasPoints__impl__6mwi9x($this) ? _BoundsBuilder___get_xmin__impl__o2sib0($this) : default_0;
}
function _BoundsBuilder___get_hasPoints__impl__6mwi9x($this) {
  return _BoundsBuilder___get_isNotEmpty__impl__w6rbsy($this);
}
var Companion_instance_1;
function Companion_getInstance_1() {
  if (Companion_instance_1 === VOID)
    new Companion_1();
  return Companion_instance_1;
}
function BoundsBuilder__plus_impl_cz7f7x($this, p) {
  if (_BoundsBuilder___get_bounds__impl__s1l1gx($this).n2i())
    return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_16().g2j(p, Size2D.f2j(0, 0)));
  return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_16().c2j(Companion_getInstance_22().i2j(_BoundsBuilder___get_bounds__impl__s1l1gx($this).h2j(), p), Companion_getInstance_22().k2j(_BoundsBuilder___get_bounds__impl__s1l1gx($this).j2j(), p)));
}
function BoundsBuilder__plus_impl_cz7f7x_0($this, rect) {
  if (rect == null)
    return $this;
  if (rect.n2i())
    return $this;
  return BoundsBuilder__plus_impl_cz7f7x(BoundsBuilder__plus_impl_cz7f7x($this, rect.h2j()), rect.j2j());
}
function BoundsBuilder__plus_impl_cz7f7x_1($this, p) {
  var bb = $this;
  var inductionVariable = 0;
  var last = p.j1();
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      bb = BoundsBuilder__plus_impl_cz7f7x(bb, p.i1(n));
    }
     while (inductionVariable < last);
  return bb;
}
function BoundsBuilder__plus_impl_cz7f7x_2($this, rects) {
  var bb = $this;
  var _iterator__ex2g4s = rects.f1();
  while (_iterator__ex2g4s.g1()) {
    var it = _iterator__ex2g4s.h1();
    bb = BoundsBuilder__plus_impl_cz7f7x_0(bb, it);
  }
  return bb;
}
function BoundsBuilder__boundsOrNull_impl_se9lyg($this) {
  return _BoundsBuilder___get_isEmpty__impl__e9t785($this) ? null : _BoundsBuilder___get_bounds__impl__s1l1gx($this);
}
function BoundsBuilder__toString_impl_fmvxh7($this) {
  return 'BoundsBuilder(bounds=' + $this.toString() + ')';
}
function BoundsBuilder__hashCode_impl_649zbo($this) {
  return $this.hashCode();
}
function BoundsBuilder__equals_impl_jngfjs($this, other) {
  if (!(other instanceof BoundsBuilder))
    return false;
  var tmp0_other_with_cast = other instanceof BoundsBuilder ? other.l2j_1 : THROW_CCE();
  if (!$this.equals(tmp0_other_with_cast))
    return false;
  return true;
}
var Companion_instance_2;
function Companion_getInstance_2() {
  return Companion_instance_2;
}
var Order_INVALID_instance;
var Order_XYZ_instance;
var Order_XZY_instance;
var Order_YXZ_instance;
var Order_YZX_instance;
var Order_ZXY_instance;
var Order_ZYX_instance;
var Companion_instance_3;
function Companion_getInstance_3() {
  Order_initEntries();
  if (Companion_instance_3 === VOID)
    new Companion_3();
  return Companion_instance_3;
}
function values() {
  return [Order_INVALID_getInstance(), Order_XYZ_getInstance(), Order_XZY_getInstance(), Order_YXZ_getInstance(), Order_YZX_getInstance(), Order_ZXY_getInstance(), Order_ZYX_getInstance()];
}
var Order_entriesInitialized;
function Order_initEntries() {
  if (Order_entriesInitialized)
    return Unit_instance;
  Order_entriesInitialized = true;
  Order_INVALID_instance = new Order('INVALID', 0, 0, 0, 0, 0, 'XXX');
  Order_XYZ_instance = new Order('XYZ', 1, 1, -1, 1, -1, 'XYZ');
  Order_XZY_instance = new Order('XZY', 2, -1, -1, 1, 1, 'XZY');
  Order_YXZ_instance = new Order('YXZ', 3, 1, -1, -1, 1, 'YXZ');
  Order_YZX_instance = new Order('YZX', 4, 1, 1, -1, -1, 'YZX');
  Order_ZXY_instance = new Order('ZXY', 5, -1, 1, 1, -1, 'ZXY');
  Order_ZYX_instance = new Order('ZYX', 6, -1, 1, -1, 1, 'ZYX');
  Companion_getInstance_3();
}
function _Config___init__impl__f0goox(id) {
  return id;
}
function _Config___get_id__impl__x0sn1d($this) {
  return $this;
}
function _Config___init__impl__f0goox_0(order, coordinateSystem) {
  return _Config___init__impl__f0goox(order.c2k(coordinateSystem).v3_1);
}
var Companion_instance_4;
function Companion_getInstance_4() {
  return Companion_instance_4;
}
var CoordinateSystem_LEFT_HANDED_instance;
var CoordinateSystem_RIGHT_HANDED_instance;
var CoordinateSystem_entriesInitialized;
function CoordinateSystem_initEntries() {
  if (CoordinateSystem_entriesInitialized)
    return Unit_instance;
  CoordinateSystem_entriesInitialized = true;
  CoordinateSystem_LEFT_HANDED_instance = new CoordinateSystem('LEFT_HANDED', 0, -1);
  CoordinateSystem_RIGHT_HANDED_instance = new CoordinateSystem('RIGHT_HANDED', 1, 1);
}
function _EulerRotation___init__impl__bezlqm(data) {
  return data;
}
function _EulerRotation___init__impl__bezlqm_0() {
  return _EulerRotation___init__impl__bezlqm_1(Companion_getInstance_0().r2h_1, Companion_getInstance_0().r2h_1, Companion_getInstance_0().r2h_1);
}
function _EulerRotation___init__impl__bezlqm_1(roll, pitch, yaw, config) {
  config = config === VOID ? Companion_instance_4.d2k() : config;
  return _EulerRotation___init__impl__bezlqm(new Vector4F(Ratio__toFloat_impl_1ftup5(_Angle___get_ratio__impl__ap3on4(roll)), Ratio__toFloat_impl_1ftup5(_Angle___get_ratio__impl__ap3on4(pitch)), Ratio__toFloat_impl_1ftup5(_Angle___get_ratio__impl__ap3on4(yaw)), _Config___get_id__impl__x0sn1d(config)));
}
function Order_INVALID_getInstance() {
  Order_initEntries();
  return Order_INVALID_instance;
}
function Order_XYZ_getInstance() {
  Order_initEntries();
  return Order_XYZ_instance;
}
function Order_XZY_getInstance() {
  Order_initEntries();
  return Order_XZY_instance;
}
function Order_YXZ_getInstance() {
  Order_initEntries();
  return Order_YXZ_instance;
}
function Order_YZX_getInstance() {
  Order_initEntries();
  return Order_YZX_instance;
}
function Order_ZXY_getInstance() {
  Order_initEntries();
  return Order_ZXY_instance;
}
function Order_ZYX_getInstance() {
  Order_initEntries();
  return Order_ZYX_instance;
}
function CoordinateSystem_RIGHT_HANDED_getInstance() {
  CoordinateSystem_initEntries();
  return CoordinateSystem_RIGHT_HANDED_instance;
}
var Companion_instance_5;
function Companion_getInstance_5() {
  return Companion_instance_5;
}
var Companion_instance_6;
function Companion_getInstance_6() {
  if (Companion_instance_6 === VOID)
    new Companion_6();
  return Companion_instance_6;
}
var Companion_instance_7;
function Companion_getInstance_7() {
  if (Companion_instance_7 === VOID)
    new Companion_7();
  return Companion_instance_7;
}
var Companion_instance_8;
function Companion_getInstance_8() {
  if (Companion_instance_8 === VOID)
    new Companion_8();
  return Companion_instance_8;
}
var Companion_instance_9;
function Companion_getInstance_9() {
  if (Companion_instance_9 === VOID)
    new Companion_9();
  return Companion_instance_9;
}
var MatrixType_IDENTITY_instance;
var MatrixType_TRANSLATE_instance;
var MatrixType_SCALE_instance;
var MatrixType_SCALE_TRANSLATE_instance;
var MatrixType_COMPLEX_instance;
var MatrixType_entriesInitialized;
function MatrixType_initEntries() {
  if (MatrixType_entriesInitialized)
    return Unit_instance;
  MatrixType_entriesInitialized = true;
  MatrixType_IDENTITY_instance = new MatrixType('IDENTITY', 0, 1, false, false, false);
  MatrixType_TRANSLATE_instance = new MatrixType('TRANSLATE', 1, 2, false, false, true);
  MatrixType_SCALE_instance = new MatrixType('SCALE', 2, 3, false, true, false);
  MatrixType_SCALE_TRANSLATE_instance = new MatrixType('SCALE_TRANSLATE', 3, 4, false, true, true);
  MatrixType_COMPLEX_instance = new MatrixType('COMPLEX', 4, 5, true, true, true);
}
var Companion_instance_10;
function Companion_getInstance_10() {
  if (Companion_instance_10 === VOID)
    new Companion_10();
  return Companion_instance_10;
}
function MatrixType_IDENTITY_getInstance() {
  MatrixType_initEntries();
  return MatrixType_IDENTITY_instance;
}
function MatrixType_TRANSLATE_getInstance() {
  MatrixType_initEntries();
  return MatrixType_TRANSLATE_instance;
}
function MatrixType_SCALE_getInstance() {
  MatrixType_initEntries();
  return MatrixType_SCALE_instance;
}
function MatrixType_SCALE_TRANSLATE_getInstance() {
  MatrixType_initEntries();
  return MatrixType_SCALE_TRANSLATE_instance;
}
function MatrixType_COMPLEX_getInstance() {
  MatrixType_initEntries();
  return MatrixType_COMPLEX_instance;
}
var Companion_instance_11;
function Companion_getInstance_11() {
  if (Companion_instance_11 === VOID)
    new Companion_11();
  return Companion_instance_11;
}
function ortho(_this__u8e3s4, rect, near, far) {
  near = near === VOID ? 0.0 : near;
  far = far === VOID ? 1.0 : far;
  return Companion_getInstance_11().c2r(rect.p2i(), rect.q2i(), rect.s2i(), rect.r2i(), near, far);
}
function scaled(_this__u8e3s4, scale) {
  return _this__u8e3s4.k2n(scale.q2r_1, scale.r2r_1);
}
function get_scale(_this__u8e3s4) {
  return Scale.s2r(_this__u8e3s4.n2o_1, _this__u8e3s4.o2o_1);
}
var Orientation_CLOCK_WISE_instance;
var Orientation_COUNTER_CLOCK_WISE_instance;
var Orientation_COLLINEAR_instance;
var Companion_instance_12;
function Companion_getInstance_12() {
  return Companion_instance_12;
}
var Orientation_entriesInitialized;
function Orientation_initEntries() {
  if (Orientation_entriesInitialized)
    return Unit_instance;
  Orientation_entriesInitialized = true;
  Orientation_CLOCK_WISE_instance = new Orientation('CLOCK_WISE', 0, 1);
  Orientation_COUNTER_CLOCK_WISE_instance = new Orientation('COUNTER_CLOCK_WISE', 1, -1);
  Orientation_COLLINEAR_instance = new Orientation('COLLINEAR', 2, 0);
}
function _toMatrix($this) {
  var xx = $this.w2r_1 * $this.w2r_1;
  var xy = $this.w2r_1 * $this.x2r_1;
  var xz = $this.w2r_1 * $this.y2r_1;
  var xw = $this.w2r_1 * $this.z2r_1;
  var yy = $this.x2r_1 * $this.x2r_1;
  var yz = $this.x2r_1 * $this.y2r_1;
  var yw = $this.x2r_1 * $this.z2r_1;
  var zz = $this.y2r_1 * $this.y2r_1;
  var zw = $this.y2r_1 * $this.z2r_1;
  // Inline function 'kotlin.floatArrayOf' call
  return new Float32Array([1 - 2 * (yy + zz), 2 * (xy - zw), 2 * (xz + yw), 2 * (xy + zw), 1 - 2 * (xx + zz), 2 * (yz - xw), 2 * (xz - yw), 2 * (yz + xw), 1 - 2 * (xx + yy)]);
}
var Companion_instance_13;
function Companion_getInstance_13() {
  if (Companion_instance_13 === VOID)
    new Companion_13();
  return Companion_instance_13;
}
var Companion_instance_14;
function Companion_getInstance_14() {
  return Companion_instance_14;
}
var Companion_instance_15;
function Companion_getInstance_15() {
  if (Companion_instance_15 === VOID)
    new Companion_15();
  return Companion_instance_15;
}
var Companion_instance_16;
function Companion_getInstance_16() {
  if (Companion_instance_16 === VOID)
    new Companion_16();
  return Companion_instance_16;
}
function outerCircle(_this__u8e3s4) {
  var centerX = _this__u8e3s4.r2t();
  var centerY = _this__u8e3s4.s2t();
  return new Circle(_this__u8e3s4.t2t(), Companion_getInstance_22().l2u(centerX, centerY, _this__u8e3s4.q2i(), _this__u8e3s4.r2i()));
}
function place(_this__u8e3s4, item, anchor, scale) {
  var outSize = scale.o2u(item, _this__u8e3s4.j1());
  var p = times(_this__u8e3s4.j1().p2u(outSize), anchor);
  return Companion_getInstance_16().g2j(p, outSize);
}
var Companion_instance_17;
function Companion_getInstance_17() {
  return Companion_instance_17;
}
var Companion_instance_18;
function Companion_getInstance_18() {
  if (Companion_instance_18 === VOID)
    new Companion_18();
  return Companion_instance_18;
}
function toVector2(_this__u8e3s4) {
  return Vector2D.k2h(_this__u8e3s4.q2r_1, _this__u8e3s4.r2r_1);
}
function ScaleMode$Companion$COVER$lambda(i, c) {
  return i.c2t(toVector2(c.j2v(i)).k2v());
}
function ScaleMode$Companion$SHOW_ALL$lambda(i, c) {
  return i.c2t(toVector2(c.j2v(i)).l2v());
}
function ScaleMode$Companion$EXACT$lambda(i, c) {
  return c;
}
function ScaleMode$Companion$NO_SCALE$lambda(i, c) {
  return i;
}
var Companion_instance_19;
function Companion_getInstance_19() {
  if (Companion_instance_19 === VOID)
    new Companion_19();
  return Companion_instance_19;
}
function applyScaleMode(_this__u8e3s4, container, mode, anchor) {
  return applyScaleMode_0(_this__u8e3s4.j1(), container, mode, anchor);
}
function applyScaleMode_0(_this__u8e3s4, container, mode, anchor) {
  var outSize = applyScaleMode_1(_this__u8e3s4, container.j1(), mode);
  return new Rectangle(container.i2i_1 + anchor.y2g_1 * (container.k2i_1 - outSize.d2j_1), container.j2i_1 + anchor.z2g_1 * (container.l2i_1 - outSize.e2j_1), outSize.d2j_1, outSize.e2j_1);
}
function applyScaleMode_1(_this__u8e3s4, container, mode) {
  return mode.o2u(_this__u8e3s4, container);
}
var Companion_instance_20;
function Companion_getInstance_20() {
  if (Companion_instance_20 === VOID)
    new Companion_20();
  return Companion_instance_20;
}
function minus(_this__u8e3s4, other) {
  return Vector2D.k2h(_this__u8e3s4.i2h_1 - other.d2j_1, _this__u8e3s4.j2h_1 - other.e2j_1);
}
function toFloat(_this__u8e3s4) {
  return Size2D.f2t(_this__u8e3s4.a2v_1, _this__u8e3s4.b2v_1);
}
function toInt(_this__u8e3s4) {
  return SizeInt.c2v(numberToInt(_this__u8e3s4.d2j_1), numberToInt(_this__u8e3s4.e2j_1));
}
function times_0(_this__u8e3s4, other) {
  return Vector2D.k2h(_this__u8e3s4.i2h_1 * other.d2j_1, _this__u8e3s4.j2h_1 * other.e2j_1);
}
function div(_this__u8e3s4, other) {
  return Vector2D.k2h(_this__u8e3s4.i2h_1 / other.d2j_1, _this__u8e3s4.j2h_1 / other.e2j_1);
}
function toSize(_this__u8e3s4) {
  return Size2D.k2t(_this__u8e3s4.i2h_1, _this__u8e3s4.j2h_1);
}
function toVector(_this__u8e3s4) {
  return Vector2D.k2h(_this__u8e3s4.d2j_1, _this__u8e3s4.e2j_1);
}
var Companion_instance_21;
function Companion_getInstance_21() {
  if (Companion_instance_21 === VOID)
    new Companion_21();
  return Companion_instance_21;
}
function transformX(_this__u8e3s4, m) {
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.math.geom.Matrix.transform' call
    if (m.n2i()) {
      tmp$ret$0 = _this__u8e3s4;
      break $l$block;
    }
    tmp$ret$0 = Vector2D.k2h(m.d2n(_this__u8e3s4.i2h_1, _this__u8e3s4.j2h_1), m.g2n(_this__u8e3s4.i2h_1, _this__u8e3s4.j2h_1));
  }
  return tmp$ret$0.i2h_1;
}
function transformY(_this__u8e3s4, m) {
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.math.geom.Matrix.transform' call
    if (m.n2i()) {
      tmp$ret$0 = _this__u8e3s4;
      break $l$block;
    }
    tmp$ret$0 = Vector2D.k2h(m.d2n(_this__u8e3s4.i2h_1, _this__u8e3s4.j2h_1), m.g2n(_this__u8e3s4.i2h_1, _this__u8e3s4.j2h_1));
  }
  return tmp$ret$0.j2h_1;
}
function square($this, x) {
  return x * x;
}
var Companion_instance_22;
function Companion_getInstance_22() {
  if (Companion_instance_22 === VOID)
    new Companion_22();
  return Companion_instance_22;
}
function toInt_0(_this__u8e3s4) {
  return Vector2I.v2u(numberToInt(_this__u8e3s4.i2h_1), numberToInt(_this__u8e3s4.j2h_1));
}
function times_1(_this__u8e3s4, v) {
  // Inline function 'korlibs.math.geom.Vector2D.times' call
  return Vector2D.k2h(v.i2h_1 * _this__u8e3s4, v.j2h_1 * _this__u8e3s4);
}
var Companion_instance_23;
function Companion_getInstance_23() {
  if (Companion_instance_23 === VOID)
    new Companion_23();
  return Companion_instance_23;
}
var Companion_instance_24;
function Companion_getInstance_24() {
  if (Companion_instance_24 === VOID)
    new Companion_24();
  return Companion_instance_24;
}
var Companion_instance_25;
function Companion_getInstance_25() {
  if (Companion_instance_25 === VOID)
    new Companion_25();
  return Companion_instance_25;
}
var Companion_instance_26;
function Companion_getInstance_26() {
  if (Companion_instance_26 === VOID)
    new Companion_26();
  return Companion_instance_26;
}
function toDouble(_this__u8e3s4) {
  return Vector2D.k2l(_this__u8e3s4.s2u_1, _this__u8e3s4.t2u_1);
}
function interpolate_1(_this__u8e3s4, l, r) {
  return Vector2D.k2h(interpolate(_this__u8e3s4, l.i2h_1, r.i2h_1), interpolate(_this__u8e3s4, l.j2h_1, r.j2h_1));
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForClass(Anchor2D, 'Anchor2D');
initMetadataForCompanion(Companion_0);
protoOf(Angle).vz = isAlmostEquals$default;
initMetadataForClass(Angle, 'Angle', VOID, VOID, [Comparable, IsAlmostEquals]);
initMetadataForCompanion(Companion_1);
initMetadataForClass(BoundsBuilder, 'BoundsBuilder');
initMetadataForCompanion(Companion_2);
initMetadataForClass(Circle, 'Circle');
initMetadataForCompanion(Companion_3);
initMetadataForCompanion(Companion_4);
initMetadataForClass(Order, 'Order');
initMetadataForClass(CoordinateSystem, 'CoordinateSystem');
initMetadataForClass(IPointList$Sublist$listIterator$1);
initMetadataForClass(Sublist, 'Sublist', VOID, VOID, [KtList]);
initMetadataForCompanion(Companion_5);
initMetadataForInterface(IDoubleVectorList, 'IDoubleVectorList', VOID, VOID, [IsAlmostEquals]);
initMetadataForInterface(IPointList, 'IPointList', VOID, VOID, [IDoubleVectorList, KtList]);
initMetadataForCompanion(Companion_6);
initMetadataForClass(Line2D, 'Line2D', Line2D.h2l);
initMetadataForCompanion(Companion_7);
protoOf(Margin).vz = isAlmostEquals$default;
initMetadataForClass(Margin, 'Margin', VOID, VOID, [IsAlmostEquals]);
initMetadataForCompanion(Companion_8);
initMetadataForClass(MarginInt, 'MarginInt');
initMetadataForCompanion(Companion_9);
protoOf(Matrix).vz = isAlmostEquals$default;
initMetadataForClass(Matrix, 'Matrix', VOID, VOID, [IsAlmostEquals]);
initMetadataForClass(MatrixType, 'MatrixType');
initMetadataForCompanion(Companion_10);
protoOf(MatrixTransform).vz = isAlmostEquals$default;
initMetadataForClass(MatrixTransform, 'MatrixTransform', VOID, VOID, [IsAlmostEquals]);
initMetadataForCompanion(Companion_11);
initMetadataForClass(Matrix4, 'Matrix4', Matrix4.u2p);
initMetadataForCompanion(Companion_12);
initMetadataForClass(Orientation, 'Orientation');
initMetadataForCompanion(Companion_13);
initMetadataForClass(Quaternion, 'Quaternion', Quaternion.b2s);
initMetadataForCompanion(Companion_14);
protoOf(Ray2D).vz = isAlmostEquals$default;
initMetadataForClass(Ray2D, 'Ray2D', VOID, VOID, [IsAlmostEquals]);
initMetadataForCompanion(Companion_15);
initMetadataForClass(RectCorners, 'RectCorners');
initMetadataForCompanion(Companion_16);
protoOf(Rectangle).vz = isAlmostEquals$default;
initMetadataForClass(Rectangle, 'Rectangle', VOID, VOID, [IsAlmostEquals]);
initMetadataForCompanion(Companion_17);
initMetadataForClass(RectangleInt, 'RectangleInt', RectangleInt.w2u);
initMetadataForCompanion(Companion_18);
initMetadataForClass(Scale, 'Scale', Scale.f2v);
initMetadataForCompanion(Companion_19);
initMetadataForClass(ScaleMode, 'ScaleMode');
initMetadataForCompanion(Companion_20);
initMetadataForClass(Size2D, 'Size2D', Size2D.v2v);
initMetadataForClass(SizeInt, 'SizeInt', SizeInt.c2w);
initMetadataForCompanion(Companion_21);
initMetadataForClass(Spacing, 'Spacing');
initMetadataForCompanion(Companion_22);
protoOf(Vector2D).vz = isAlmostEquals$default;
initMetadataForClass(Vector2D, 'Vector2D', Vector2D.i2l, VOID, [IsAlmostEquals]);
initMetadataForCompanion(Companion_23);
initMetadataForClass(Vector4F, 'Vector4F');
initMetadataForCompanion(Companion_24);
initMetadataForClass(Vector3F, 'Vector3F');
initMetadataForCompanion(Companion_25);
initMetadataForClass(Vector2F, 'Vector2F', Vector2F.c2y);
initMetadataForCompanion(Companion_26);
initMetadataForClass(Vector2I, 'Vector2I', Vector2I.e2y);
//endregion
//region block: init
Companion_instance_2 = new Companion_2();
Companion_instance_4 = new Companion_4();
Companion_instance_5 = new Companion_5();
Companion_instance_12 = new Companion_12();
Companion_instance_14 = new Companion_14();
Companion_instance_17 = new Companion_17();
//endregion
//region block: exports
export {
  MatrixType_IDENTITY_getInstance as MatrixType_IDENTITY_getInstancevamvzsx28k56,
  _Angle___init__impl__g23q1m as _Angle___init__impl__g23q1m1vusxs8sthxgw,
  _Angle___get_absoluteValue__impl__4qoakv as _Angle___get_absoluteValue__impl__4qoakv3gpdj6wpeuir3,
  Angle__compareTo_impl_a0hfds as Angle__compareTo_impl_a0hfdsdv09zqotoc4l,
  _Angle___get_cosine__impl__vprvmu as _Angle___get_cosine__impl__vprvmu3fuyxpbgo71fk,
  Angle__cosine_impl_bgvzun as Angle__cosine_impl_bgvzunl3ekczcg3dy9,
  _Angle___get_degrees__impl__qg56vw as _Angle___get_degrees__impl__qg56vw1zqnfrqkp8did,
  Angle__div_impl_ut2btd as Angle__div_impl_ut2btdt21di2brbxq0,
  Angle__div_impl_ut2btd_0 as Angle__div_impl_ut2btd1uvzni4k9abn0,
  Angle__hashCode_impl_szcndt as Angle__hashCode_impl_szcndt1v9it5377zvl,
  Angle__minus_impl_xeau7k as Angle__minus_impl_xeau7k30vsnq3biqxde,
  _Angle___get_normalized__impl__9s30ys as _Angle___get_normalized__impl__9s30ys274hpyvbigjus,
  Angle__plus_impl_26v37k as Angle__plus_impl_26v37k2akkjkyjv8qeu,
  _Angle___get_radians__impl__n00yt5 as _Angle___get_radians__impl__n00yt523wqxa1jdhpa1,
  _Angle___get_ratio__impl__ap3on4 as _Angle___get_ratio__impl__ap3on42zzwqtrc65vpb,
  _Angle___get_sine__impl__15nmyi as _Angle___get_sine__impl__15nmyi1kdagydgbnjhl,
  Angle__sine_impl_3l11xv as Angle__sine_impl_3l11xvmzbai5p75zu2,
  _Angle___get_tangent__impl__vushdy as _Angle___get_tangent__impl__vushdye0u4tzlx2qii,
  Angle__times_impl_v3s9y2_1 as Angle__times_impl_v3s9y213uoamn5n3x7j,
  Angle__times_impl_v3s9y2_0 as Angle__times_impl_v3s9y22g57d1ddcd60g,
  Angle__times_impl_v3s9y2 as Angle__times_impl_v3s9y2z9k172i2sc9a,
  Angle__toString_impl_786qky as Angle__toString_impl_786qky2c0lsq1q2n03p,
  Angle__umod_impl_4wilf7 as Angle__umod_impl_4wilf73q48bzoy6t00h,
  Angle__unaryMinus_impl_7901fr as Angle__unaryMinus_impl_7901frdiuz4dirxfxh,
  Angle__unaryPlus_impl_eyi5pl as Angle__unaryPlus_impl_eyi5pl3cj7mhzkfb01q,
  _BoundsBuilder___get_bounds__impl__s1l1gx as _BoundsBuilder___get_bounds__impl__s1l1gx3cti8ahdxq1b5,
  BoundsBuilder__boundsOrNull_impl_se9lyg as BoundsBuilder__boundsOrNull_impl_se9lyg2y0izha8gzbzd,
  BoundsBuilder__hashCode_impl_649zbo as BoundsBuilder__hashCode_impl_649zbouv25pt0ki4b7,
  BoundsBuilder__plus_impl_cz7f7x as BoundsBuilder__plus_impl_cz7f7x1gtmpkit3llyq,
  BoundsBuilder__plus_impl_cz7f7x_2 as BoundsBuilder__plus_impl_cz7f7x3c46o898gf5al,
  BoundsBuilder__plus_impl_cz7f7x_1 as BoundsBuilder__plus_impl_cz7f7x11d6y0i85df0y,
  BoundsBuilder__plus_impl_cz7f7x_0 as BoundsBuilder__plus_impl_cz7f7x3pz0lxdivao0o,
  BoundsBuilder__toString_impl_fmvxh7 as BoundsBuilder__toString_impl_fmvxh71jc5bjlliwupe,
  _BoundsBuilder___get_xmax__impl__gsz0km as _BoundsBuilder___get_xmax__impl__gsz0km3izaxmrwq1yh1,
  _BoundsBuilder___get_xmin__impl__o2sib0 as _BoundsBuilder___get_xmin__impl__o2sib014moqt3x1cfln,
  BoundsBuilder__xminOr_impl_k3l1m2 as BoundsBuilder__xminOr_impl_k3l1m23vaitgwxe5y8m,
  _BoundsBuilder___get_ymax__impl__oia4uz as _BoundsBuilder___get_ymax__impl__oia4uz2y6v142m2imsu,
  _BoundsBuilder___get_ymin__impl__5n2e8j as _BoundsBuilder___get_ymin__impl__5n2e8jlouwj806h8ky,
  _EulerRotation___init__impl__bezlqm_0 as _EulerRotation___init__impl__bezlqm15akoevhh9e0u,
  Companion_getInstance as Companion_getInstance21plr7f541fx9,
  Companion_getInstance_0 as Companion_getInstance1ygiwm9bgjia3,
  Companion_getInstance_1 as Companion_getInstance3om7tc9mg7edv,
  Companion_getInstance_7 as Companion_getInstancery5tnhoxuqu,
  Companion_getInstance_8 as Companion_getInstance9hqru60m2833,
  Companion_getInstance_9 as Companion_getInstance3jdtpl42k8kus,
  Companion_getInstance_11 as Companion_getInstance2npyq9n96lvll,
  Companion_getInstance_13 as Companion_getInstance145etojj24o8w,
  Companion_getInstance_15 as Companion_getInstance1a1gzjuenalp2,
  Companion_getInstance_16 as Companion_getInstancewvzae8n0fprj,
  Companion_instance_17 as Companion_instance3cau272s9vxbi,
  Companion_getInstance_19 as Companion_getInstance19z3k1g06rka6,
  Companion_getInstance_20 as Companion_getInstancew949xxob44bw,
  Companion_getInstance_21 as Companion_getInstance2ngycsg9kr2pn,
  Companion_getInstance_22 as Companion_getInstanceuv1p1c5gwy11,
  Companion_getInstance_24 as Companion_getInstance2175iaprojxbf,
  Companion_getInstance_23 as Companion_getInstanceujowwr66h2x9,
  Anchor2D as Anchor2D3ucppq7m9n3z8,
  Angle_between as Angle_between72970e1vlen9,
  Angle as Angle2itorpob18n08,
  BoundsBuilder as BoundsBuilder162uwsje56mvu,
  Circle as Circle1sk1qi5ph1m23,
  isAlmostEquals_0 as isAlmostEquals2qd88bwpjj4z5,
  isNotEmpty as isNotEmptyrwyeygz7izwg,
  IDoubleVectorList as IDoubleVectorListoa05cse3he2p,
  containsAll as containsAll194lepx84fvib,
  indexOf as indexOf3tp8bzbv46hci,
  iterator as iterator1sm8hwbj0nmg8,
  listIterator as listIteratorrk3szyua4uzm,
  listIterator_0 as listIterator3ooh2yjav3kr9,
  subList as subList2funs4qwcrhhq,
  IPointList as IPointListz7raskb2mrxa,
  Line2D as Line2D2pf35pcx71ncp,
  MarginInt as MarginInt3jiec8xsxkfd,
  Margin as Marginzmgz0t8x8vn,
  Matrix4 as Matrix43neeu8v4zk4i7,
  MatrixTransform as MatrixTransform1wlsrhxt7473v,
  Matrix as Matrix1p940sdz227jj,
  Quaternion as Quaternion3no7g5rnd4z1g,
  Ray2D as Ray2D32ioa0bhx6kzt,
  RectCorners as RectCorners2yrtl8r42p5bs,
  RectangleInt as RectangleInt3lmu13ytuf5lk,
  Rectangle as Rectangle3q03woyn9k8x1,
  Scale as Scale2cm697xlulcp5,
  Size2D as Size2Dum5ayuyhhau7,
  SizeInt as SizeInte6w85oa6mz6v,
  Vector2D as Vector2D378fe5rov8fgw,
  Vector2F as Vector2F2yb91r49vy4m7,
  Vector2I as Vector2I2k5512l4eeahb,
  Vector3F as Vector3F3dkkvai0hm6xy,
  Vector4F as Vector4F1clr35ctch8js,
  adjustFromUp as adjustFromUp1ak85cceintt4,
  applyScaleMode_0 as applyScaleMode1nqdhbqs15126,
  applyScaleMode as applyScaleMode1soflu9k24j5z,
  get_degrees as get_degrees3czcd4ojw4i,
  get_degrees_0 as get_degrees1wvulgxbo1fy6,
  div as div919pico60rxu,
  interpolateAngleDenormalized as interpolateAngleDenormalized1hkwzk523mo1c,
  interpolateAngleNormalized as interpolateAngleNormalized3h8mzdshuvgea,
  interpolateAngle as interpolateAngle3ga2apfmi4ucr,
  minus as minus35u9jfp5de3x,
  ortho as orthow9c0o91bw54n,
  outerCircle as outerCircle30hn5vvbpz9gu,
  place as place35kwuvwdaw5nl,
  get_radians_1 as get_radians3l1td1j6jwrs,
  get_radians as get_radians391vbqqjsolq6,
  get_radians_0 as get_radians87y5j8tewvub,
  get_scale as get_scale17gmsnxnqj6j9,
  scaled as scaled2eehd42i2trq9,
  times_0 as timesatqrmkwmiu2z,
  toDouble as toDouble2d46ijanub8on,
  toFloat as toFloat3b315y36g90cd,
  toInt as toIntp2twe4onfbic,
  toInt_0 as toIntu0rc68akxu7a,
  transformX as transformX33nszy4vzqsfy,
  transformY as transformY3ktoxhxwkzwk1,
  interpolate_1 as interpolatewhd2sgxffql5,
};
//endregion
