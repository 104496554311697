import {
  Unit_instance104q5opgivhr8 as Unit_instance,
  Enum3alwj03lh1n41 as Enum,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  VOID7hggqo3abtya as VOID,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  println2shhhgwwt4c61 as println,
  StringBuildermazzzhj6kkai as StringBuilder,
  joinTo3533moo9lcgda as joinTo,
  protoOf180f3jzyo7rfj as protoOf,
  replaceqbix900hl8kl as replace,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  THROW_IAE23kobfj9wdoxr as THROW_IAE,
  HashMap1a0ld5kgwhmhv as HashMap,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  to2cs3ny02qtbcb as to,
  charArrayOf27f4r3dozbrk1 as charArrayOf,
  split3d3yeauc4rm2n as split,
  ArrayList3it5z8td81qkl as ArrayList,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isArray1hxjqtqy632bc as isArray,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  emptyMapr06gerzljqtm as emptyMap,
  trimStart1mkod6gyztuyy as trimStart,
  firstOrNull1982767dljvdy as firstOrNull,
  toMap1vec9topfei08 as toMap,
  KProperty02ce7r476m8633 as KProperty0,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  lazy2hsh8ze7j6ikd as lazy,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class Color extends Enum {
  constructor(name, ordinal, index) {
    super(name, ordinal);
    this.m3f_1 = index;
  }
}
class AnsiEscape {}
function appendAnsiScape(_this__u8e3s4, code, extra, char) {
  _this__u8e3s4.jc(_Char___init__impl__6a9atx(27));
  _this__u8e3s4.jc(_Char___init__impl__6a9atx(91));
  _this__u8e3s4.oh(code);
  if (!(extra == null)) {
    _this__u8e3s4.ic(extra);
  }
  _this__u8e3s4.jc(char);
  return _this__u8e3s4;
}
function appendAnsiScape$default(_this__u8e3s4, code, extra, char, $super) {
  extra = extra === VOID ? null : extra;
  char = char === VOID ? _Char___init__impl__6a9atx(109) : char;
  return $super === VOID ? this.n3f(_this__u8e3s4, code, extra, char) : appendAnsiScape(_this__u8e3s4, code, extra, char);
}
function appendReset(_this__u8e3s4) {
  return this.o3f(_this__u8e3s4, 0);
}
function appendFgColor(_this__u8e3s4, color, bright) {
  return this.o3f(_this__u8e3s4, 30 + color.m3f_1 | 0, bright ? ';1' : null);
}
function appendFgColor$default(_this__u8e3s4, color, bright, $super) {
  bright = bright === VOID ? false : bright;
  return $super === VOID ? this.q3f(_this__u8e3s4, color, bright) : appendFgColor(_this__u8e3s4, color, bright);
}
class Kind extends Enum {
  constructor(name, ordinal, level, color) {
    super(name, ordinal);
    this.u3f_1 = level;
    this.v3f_1 = color;
  }
}
class BaseConsole {
  x3f(kind, msg) {
    var hook = baseConsoleHook;
    if (!(hook == null)) {
      hook(kind, msg, BaseConsole$logInternal$ref(this));
    } else {
      this.w3f(kind, msg.slice());
    }
  }
  w3f(kind, msg) {
    println(this.y3f(kind, msg.slice()));
  }
  y3f(kind, msg) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.logger.BaseConsole.logToString.<anonymous>' call
    var color = kind.v3f_1;
    if (!(color == null)) {
      this.r3f(this_0, color);
    }
    joinTo(msg, this_0, ', ');
    if (!(color == null)) {
      this.p3f(this_0);
    }
    return this_0.toString();
  }
  z3f(msg) {
    return this.x3f(Kind_LOG_getInstance(), msg.slice());
  }
  a3g(msg) {
    return this.x3f(Kind_WARN_getInstance(), msg.slice());
  }
  b3g(msg) {
    return this.x3f(Kind_ERROR_getInstance(), msg.slice());
  }
}
class Companion {
  constructor() {
    Companion_instance = this;
    var tmp = this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = values();
    var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.wc(capacity);
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.logger.Companion.BY_NAME.<anonymous>' call
      var tmp$ret$0 = element.u3_1;
      destination.r3(tmp$ret$0, element);
    }
    tmp.c3g_1 = destination;
  }
  bj(name) {
    // Inline function 'kotlin.text.uppercase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = name.toUpperCase();
    var tmp0_elvis_lhs = this.c3g_1.k3(tmp$ret$1);
    return tmp0_elvis_lhs == null ? Level_NONE_getInstance() : tmp0_elvis_lhs;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    this.d3g_1 = new Object();
    this.e3g_1 = HashMap.c9();
    this.f3g_1 = null;
    this.g3g_1 = DefaultLogOutput_instance;
  }
  ez(name) {
    // Inline function 'kotlinx.atomicfu.locks.synchronized' call
    this.d3g_1;
    var normalizedName = normalizeName(Companion_getInstance_0(), name);
    if (Companion_getInstance_0().e3g_1.k3(normalizedName) == null) {
      var logger = new Logger(name, normalizedName, true);
      var tmp0_safe_receiver = get_miniEnvironmentVariablesUC().k3('LOG_' + normalizedName);
      if (tmp0_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.logger.Companion.invoke.<anonymous>.<anonymous>' call
        logger.n3g(Companion_getInstance().bj(tmp0_safe_receiver));
      }
      if (Companion_getInstance_0().e3g_1.a1()) {
        var tmp1_safe_receiver = get_miniEnvironmentVariablesUC().k3('LOG_LEVEL');
        if (tmp1_safe_receiver == null)
          null;
        else {
          // Inline function 'kotlin.also' call
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.logger.Companion.invoke.<anonymous>.<anonymous>' call
          Companion_getInstance_0().f3g_1 = Companion_getInstance().bj(tmp1_safe_receiver);
        }
      }
      // Inline function 'kotlin.collections.set' call
      Companion_getInstance_0().e3g_1.r3(normalizedName, logger);
    }
    return ensureNotNull(Companion_getInstance_0().e3g_1.k3(normalizedName));
  }
}
class Level extends Enum {
  constructor(name, ordinal, index) {
    super(name, ordinal);
    this.q3g_1 = index;
  }
}
class ConsoleLogOutput {
  r3g(logger, level, msg) {
    switch (level.v3_1) {
      case 2:
        Console_getInstance().b3g([logger.h3g_1, msg]);
        break;
      case 3:
        Console_getInstance().a3g([logger.h3g_1, msg]);
        break;
      default:
        Console_getInstance().z3f([logger.h3g_1, msg]);
        break;
    }
  }
}
class Logger {
  constructor(name, normalizedName, dummy) {
    Companion_getInstance_0();
    this.h3g_1 = name;
    this.i3g_1 = normalizedName;
    this.j3g_1 = dummy;
    this.k3g_1 = null;
    this.l3g_1 = null;
    this.m3g_1 = null;
  }
  n3g(value) {
    this.l3g_1 = value;
  }
  s3g() {
    var tmp0_elvis_lhs = this.l3g_1;
    var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? Companion_getInstance_0().f3g_1 : tmp0_elvis_lhs;
    return tmp1_elvis_lhs == null ? Level_WARN_getInstance() : tmp1_elvis_lhs;
  }
  t3g() {
    var tmp0_elvis_lhs = this.m3g_1;
    return tmp0_elvis_lhs == null ? Companion_getInstance_0().g3g_1 : tmp0_elvis_lhs;
  }
  u3g(level) {
    return level.q3g_1 <= this.s3g().q3g_1;
  }
  v3g(level, msg) {
    this.t3g().r3g(this, level, msg);
  }
}
class Console extends BaseConsole {
  constructor() {
    Console_instance = null;
    super();
    Console_instance = this;
  }
  w3f(kind, msg) {
    switch (kind.v3_1) {
      case 0:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.error.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 1:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.warn.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 2:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.info.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 3:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 4:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 5:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
  }
}
class DefaultLogOutput {
  r3g(logger, level, msg) {
    return ConsoleLogOutput_instance.r3g(logger, level, msg);
  }
}
//endregion
var Color_BLACK_instance;
var Color_RED_instance;
var Color_GREEN_instance;
var Color_YELLOW_instance;
var Color_BLUE_instance;
var Color_PURPLE_instance;
var Color_CYAN_instance;
var Color_WHITE_instance;
var Color_entriesInitialized;
function Color_initEntries() {
  if (Color_entriesInitialized)
    return Unit_instance;
  Color_entriesInitialized = true;
  Color_BLACK_instance = new Color('BLACK', 0, 0);
  Color_RED_instance = new Color('RED', 1, 1);
  Color_GREEN_instance = new Color('GREEN', 2, 2);
  Color_YELLOW_instance = new Color('YELLOW', 3, 3);
  Color_BLUE_instance = new Color('BLUE', 4, 4);
  Color_PURPLE_instance = new Color('PURPLE', 5, 5);
  Color_CYAN_instance = new Color('CYAN', 6, 6);
  Color_WHITE_instance = new Color('WHITE', 7, 7);
}
function Color_RED_getInstance() {
  Color_initEntries();
  return Color_RED_instance;
}
function Color_GREEN_getInstance() {
  Color_initEntries();
  return Color_GREEN_instance;
}
function Color_YELLOW_getInstance() {
  Color_initEntries();
  return Color_YELLOW_instance;
}
function Color_BLUE_getInstance() {
  Color_initEntries();
  return Color_BLUE_instance;
}
function Color_CYAN_getInstance() {
  Color_initEntries();
  return Color_CYAN_instance;
}
var baseConsoleHook;
var Kind_ERROR_instance;
var Kind_WARN_instance;
var Kind_INFO_instance;
var Kind_DEBUG_instance;
var Kind_TRACE_instance;
var Kind_LOG_instance;
var Kind_entriesInitialized;
function Kind_initEntries() {
  if (Kind_entriesInitialized)
    return Unit_instance;
  Kind_entriesInitialized = true;
  Kind_ERROR_instance = new Kind('ERROR', 0, 0, Color_RED_getInstance());
  Kind_WARN_instance = new Kind('WARN', 1, 1, Color_YELLOW_getInstance());
  Kind_INFO_instance = new Kind('INFO', 2, 2, Color_BLUE_getInstance());
  Kind_DEBUG_instance = new Kind('DEBUG', 3, 3, Color_CYAN_getInstance());
  Kind_TRACE_instance = new Kind('TRACE', 4, 4, Color_GREEN_getInstance());
  Kind_LOG_instance = new Kind('LOG', 5, 5, null);
}
function BaseConsole$logInternal$ref($boundThis) {
  var l = function (p0, p1) {
    $boundThis.w3f(p0, p1);
    return Unit_instance;
  };
  l.callableName = 'logInternal';
  return l;
}
function Kind_ERROR_getInstance() {
  Kind_initEntries();
  return Kind_ERROR_instance;
}
function Kind_WARN_getInstance() {
  Kind_initEntries();
  return Kind_WARN_instance;
}
function Kind_LOG_getInstance() {
  Kind_initEntries();
  return Kind_LOG_instance;
}
var _miniEnvironmentVariablesUC;
function normalizeName($this, name) {
  // Inline function 'kotlin.text.uppercase' call
  // Inline function 'kotlin.js.asDynamic' call
  return replace(replace(name, _Char___init__impl__6a9atx(46), _Char___init__impl__6a9atx(95)), _Char___init__impl__6a9atx(47), _Char___init__impl__6a9atx(95)).toUpperCase();
}
var Level_NONE_instance;
var Level_FATAL_instance;
var Level_ERROR_instance;
var Level_WARN_instance;
var Level_INFO_instance;
var Level_DEBUG_instance;
var Level_TRACE_instance;
var Companion_instance;
function Companion_getInstance() {
  Level_initEntries();
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
function values() {
  return [Level_NONE_getInstance(), Level_FATAL_getInstance(), Level_ERROR_getInstance(), Level_WARN_getInstance(), Level_INFO_getInstance(), Level_DEBUG_getInstance(), Level_TRACE_getInstance()];
}
function valueOf(value) {
  switch (value) {
    case 'NONE':
      return Level_NONE_getInstance();
    case 'FATAL':
      return Level_FATAL_getInstance();
    case 'ERROR':
      return Level_ERROR_getInstance();
    case 'WARN':
      return Level_WARN_getInstance();
    case 'INFO':
      return Level_INFO_getInstance();
    case 'DEBUG':
      return Level_DEBUG_getInstance();
    case 'TRACE':
      return Level_TRACE_getInstance();
    default:
      Level_initEntries();
      THROW_IAE('No enum constant value.');
      break;
  }
}
var Level_entriesInitialized;
function Level_initEntries() {
  if (Level_entriesInitialized)
    return Unit_instance;
  Level_entriesInitialized = true;
  Level_NONE_instance = new Level('NONE', 0, 0);
  Level_FATAL_instance = new Level('FATAL', 1, 1);
  Level_ERROR_instance = new Level('ERROR', 2, 2);
  Level_WARN_instance = new Level('WARN', 3, 3);
  Level_INFO_instance = new Level('INFO', 4, 4);
  Level_DEBUG_instance = new Level('DEBUG', 5, 5);
  Level_TRACE_instance = new Level('TRACE', 6, 6);
  Companion_getInstance();
}
var Companion_instance_0;
function Companion_getInstance_0() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
var ConsoleLogOutput_instance;
function ConsoleLogOutput_getInstance() {
  return ConsoleLogOutput_instance;
}
function Level_NONE_getInstance() {
  Level_initEntries();
  return Level_NONE_instance;
}
function Level_FATAL_getInstance() {
  Level_initEntries();
  return Level_FATAL_instance;
}
function Level_ERROR_getInstance() {
  Level_initEntries();
  return Level_ERROR_instance;
}
function Level_WARN_getInstance() {
  Level_initEntries();
  return Level_WARN_instance;
}
function Level_INFO_getInstance() {
  Level_initEntries();
  return Level_INFO_instance;
}
function Level_DEBUG_getInstance() {
  Level_initEntries();
  return Level_DEBUG_instance;
}
function Level_TRACE_getInstance() {
  Level_initEntries();
  return Level_TRACE_instance;
}
function get_miniEnvironmentVariablesUC() {
  if (_miniEnvironmentVariablesUC == null) {
    // Inline function 'kotlin.collections.mapKeys' call
    var this_0 = get_miniEnvironmentVariables();
    // Inline function 'kotlin.collections.mapKeysTo' call
    var destination = LinkedHashMap.wc(mapCapacity(this_0.j1()));
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp0_iterator = this_0.z1().f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.logger.<get-miniEnvironmentVariablesUC>.<anonymous>' call
      // Inline function 'kotlin.text.uppercase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp = element.a2().toUpperCase();
      // Inline function 'kotlin.collections.mapKeysTo.<anonymous>' call
      var tmp$ret$3 = element.b2();
      destination.r3(tmp, tmp$ret$3);
    }
    _miniEnvironmentVariablesUC = destination;
  }
  return ensureNotNull(_miniEnvironmentVariablesUC);
}
function get_isDenoJs() {
  _init_properties_Logger_js_kt__qad3pn();
  // Inline function 'kotlin.getValue' call
  var this_0 = isDenoJs$delegate;
  isDenoJs$factory();
  return this_0.b2();
}
var isDenoJs$delegate;
function get_isNodeJs() {
  _init_properties_Logger_js_kt__qad3pn();
  // Inline function 'kotlin.getValue' call
  var this_0 = isNodeJs$delegate;
  isNodeJs$factory();
  return this_0.b2();
}
var isNodeJs$delegate;
function get_miniEnvironmentVariables() {
  _init_properties_Logger_js_kt__qad3pn();
  // Inline function 'kotlin.getValue' call
  var this_0 = miniEnvironmentVariables$delegate;
  miniEnvironmentVariables$factory();
  return this_0.b2();
}
var miniEnvironmentVariables$delegate;
function jsObjectToMap(obj) {
  _init_properties_Logger_js_kt__qad3pn();
  // Inline function 'kotlin.collections.associate' call
  var this_0 = jsObjectKeysArray(obj);
  var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
  // Inline function 'kotlin.collections.associateTo' call
  var destination = LinkedHashMap.wc(capacity);
  var inductionVariable = 0;
  var last = this_0.length;
  while (inductionVariable < last) {
    var element = this_0[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'kotlin.collections.plusAssign' call
    // Inline function 'korlibs.logger.jsObjectToMap.<anonymous>' call
    var pair = to(element, obj[element]);
    destination.r3(pair.im_1, pair.jm_1);
  }
  return destination;
}
function QueryString_decode(str) {
  _init_properties_Logger_js_kt__qad3pn();
  // Inline function 'kotlin.collections.linkedMapOf' call
  var out = LinkedHashMap.vc();
  // Inline function 'kotlin.collections.forEach' call
  var tmp0_iterator = split(str, charArrayOf([_Char___init__impl__6a9atx(38)])).f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'korlibs.logger.QueryString_decode.<anonymous>' call
    var parts = split(element, charArrayOf([_Char___init__impl__6a9atx(61)]), VOID, 2);
    var key = parts.i1(0);
    // Inline function 'kotlin.collections.getOrElse' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (0 <= 1 ? 1 < parts.j1() : false) {
      tmp = parts.i1(1);
    } else {
      // Inline function 'korlibs.logger.QueryString_decode.<anonymous>.<anonymous>' call
      tmp = key;
    }
    var value = tmp;
    // Inline function 'kotlin.collections.getOrPut' call
    var value_0 = out.k3(key);
    var tmp_0;
    if (value_0 == null) {
      // Inline function 'korlibs.logger.QueryString_decode.<anonymous>.<anonymous>' call
      // Inline function 'kotlin.collections.arrayListOf' call
      var answer = ArrayList.m1();
      out.r3(key, answer);
      tmp_0 = answer;
    } else {
      tmp_0 = value_0;
    }
    var list = tmp_0;
    // Inline function 'kotlin.collections.plusAssign' call
    list.f(value);
  }
  return out;
}
function jsObjectKeysArray(obj) {
  _init_properties_Logger_js_kt__qad3pn();
  var tmp = jsToArray(jsObjectKeys(obj));
  return isArray(tmp) ? tmp : THROW_CCE();
}
function jsToArray(obj) {
  _init_properties_Logger_js_kt__qad3pn();
  var tmp = 0;
  var tmp_0 = obj.length;
  // Inline function 'kotlin.arrayOfNulls' call
  var tmp_1 = fillArrayVal(Array(tmp_0), null);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = obj[tmp_2];
    tmp = tmp + 1 | 0;
  }
  return tmp_1;
}
function jsObjectKeys(obj) {
  _init_properties_Logger_js_kt__qad3pn();
  return Object.keys(obj);
}
var Console_instance;
function Console_getInstance() {
  if (Console_instance === VOID)
    new Console();
  return Console_instance;
}
var DefaultLogOutput_instance;
function DefaultLogOutput_getInstance() {
  return DefaultLogOutput_instance;
}
function isDenoJs$delegate$lambda() {
  _init_properties_Logger_js_kt__qad3pn();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof Deno === 'object' && Deno.statSync;
}
function isNodeJs$delegate$lambda() {
  _init_properties_Logger_js_kt__qad3pn();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof process !== 'undefined' && process.release && process.release.name.search(/node|io.js/) !== -1;
}
function miniEnvironmentVariables$delegate$lambda() {
  _init_properties_Logger_js_kt__qad3pn();
  var tmp;
  if (get_isNodeJs()) {
    tmp = jsObjectToMap(process.env);
  } else if (get_isDenoJs()) {
    tmp = jsObjectToMap(Deno.env);
  } else if (typeof document !== 'undefined') {
    // Inline function 'kotlin.collections.map' call
    var tmp1_safe_receiver = document.location;
    var tmp0_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.search;
    var this_0 = QueryString_decode(trimStart(tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs, charArrayOf([_Char___init__impl__6a9atx(63)])));
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(this_0.j1());
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = this_0.z1().f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.logger.miniEnvironmentVariables$delegate.<anonymous>.<anonymous>' call
      var tmp_0 = item.a2();
      var tmp0_elvis_lhs_0 = firstOrNull(item.b2());
      var tmp$ret$1 = to(tmp_0, tmp0_elvis_lhs_0 == null ? item.a2() : tmp0_elvis_lhs_0);
      destination.f(tmp$ret$1);
    }
    tmp = toMap(destination);
  } else {
    // Inline function 'kotlin.collections.mapOf' call
    tmp = emptyMap();
  }
  return tmp;
}
function isDenoJs$factory() {
  return getPropertyCallableRef('isDenoJs', 0, KProperty0, function () {
    return get_isDenoJs();
  }, null);
}
function isNodeJs$factory() {
  return getPropertyCallableRef('isNodeJs', 0, KProperty0, function () {
    return get_isNodeJs();
  }, null);
}
function miniEnvironmentVariables$factory() {
  return getPropertyCallableRef('miniEnvironmentVariables', 0, KProperty0, function () {
    return get_miniEnvironmentVariables();
  }, null);
}
var properties_initialized_Logger_js_kt_6fk7m1;
function _init_properties_Logger_js_kt__qad3pn() {
  if (!properties_initialized_Logger_js_kt_6fk7m1) {
    properties_initialized_Logger_js_kt_6fk7m1 = true;
    isDenoJs$delegate = lazy(isDenoJs$delegate$lambda);
    isNodeJs$delegate = lazy(isNodeJs$delegate$lambda);
    miniEnvironmentVariables$delegate = lazy(miniEnvironmentVariables$delegate$lambda);
  }
}
//region block: post-declaration
initMetadataForClass(Color, 'Color');
initMetadataForInterface(AnsiEscape, 'AnsiEscape');
initMetadataForClass(Kind, 'Kind');
protoOf(BaseConsole).n3f = appendAnsiScape;
protoOf(BaseConsole).o3f = appendAnsiScape$default;
protoOf(BaseConsole).p3f = appendReset;
protoOf(BaseConsole).q3f = appendFgColor;
protoOf(BaseConsole).r3f = appendFgColor$default;
initMetadataForClass(BaseConsole, 'BaseConsole', BaseConsole, VOID, [AnsiEscape]);
initMetadataForCompanion(Companion);
initMetadataForCompanion(Companion_0);
initMetadataForClass(Level, 'Level');
initMetadataForObject(ConsoleLogOutput, 'ConsoleLogOutput');
initMetadataForClass(Logger, 'Logger');
initMetadataForObject(Console, 'Console');
initMetadataForObject(DefaultLogOutput, 'DefaultLogOutput');
//endregion
//region block: init
baseConsoleHook = null;
_miniEnvironmentVariablesUC = null;
ConsoleLogOutput_instance = new ConsoleLogOutput();
DefaultLogOutput_instance = new DefaultLogOutput();
//endregion
//region block: exports
export {
  Level_DEBUG_getInstance as Level_DEBUG_getInstance2cjwrm9110zsz,
  Level_ERROR_getInstance as Level_ERROR_getInstance3sgixyayx9gy9,
  Level_INFO_getInstance as Level_INFO_getInstance2ziz2o3m3loni,
  Level_TRACE_getInstance as Level_TRACE_getInstance3v3hjwgnnbwb6,
  Level_WARN_getInstance as Level_WARN_getInstancejrnwzyc079xb,
  Console_getInstance as Console_getInstance25u15nzhjnxu2,
  Companion_getInstance_0 as Companion_getInstance1lqql7kjbk545,
  valueOf as valueOf3aj0dpb8xpu5n,
};
//endregion
