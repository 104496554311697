import {
  numberToInt1ygmcfwhs2fkq as numberToInt,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  VOID7hggqo3abtya as VOID,
  copyOfgossjg6lh6js as copyOf,
  Collection1k04j3hzsbod0 as Collection,
  isInterface3d6p8outrmvmk as isInterface,
  equals2au1ep9vhcato as equals,
  KtList3hktaavzmj137 as KtList,
  StringBuildermazzzhj6kkai as StringBuilder,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  Unit_instance104q5opgivhr8 as Unit_instance,
  protoOf180f3jzyo7rfj as protoOf,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  Char__compareTo_impl_ypi4mb25zzl63tc6wpg as Char__compareTo_impl_ypi4mb,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString26mv3gzfo7fn as toString,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  ArrayList3it5z8td81qkl as ArrayList,
  toList2enan2cfix0ie as toList,
  toString1pkumu07cwy4m as toString_0,
  numberToChar93r9buh19yek as numberToChar,
  RuntimeException1r3t0zl97011n as RuntimeException,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  equals2v6cggk171b6e as equals_0,
  emptyList1g2z5xcrvp2zy as emptyList,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  checkIndexOverflow3frtmheghr0th as checkIndexOverflow,
  isCharSequence1ju9jr1w86plq as isCharSequence,
  trimEnd17pt8cbotbalj as trimEnd,
  trimStart5ewg8zf6cs5u as trimStart,
  trim11nh7r46at6sx as trim,
  toString30pk9tzaqopn as toString_1,
  hashCodeq5arwsb9dgti as hashCode,
  sorted354mfsiv4s7x5 as sorted,
  reversed22y3au42jl32b as reversed,
  distinct10qe1scfdvu5k as distinct,
  copyToArray2j022khrow2yi as copyToArray,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  endsWith1a79dp5rc3sfv as endsWith,
  startsWith38d3sbg25w0lx as startsWith,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  startsWith26w8qjqapeeq6 as startsWith_0,
  substringAfter1hku067gwr5ve as substringAfter,
  contains2el4s70rdq4ld as contains,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  SequenceScope1coiso86pqzq2 as SequenceScope,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  to2cs3ny02qtbcb as to,
  toMap1vec9topfei08 as toMap,
  firstOrNull1982767dljvdy as firstOrNull,
  println2shhhgwwt4c61 as println,
  NoSuchElementException679xzhnp5bpj as NoSuchElementException,
  Enum3alwj03lh1n41 as Enum,
  joinToStringxqcavsxcmh4q as joinToString,
  Char19o2r8palgjof as Char,
  linkedMapOf1is69t4zkcfr3 as linkedMapOf,
  KtMap140uvy3s5zad8 as KtMap,
  toIntOrNull1j8dcc6to13o4 as toIntOrNull,
  startsWith1bgirhbedtv2y as startsWith_1,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  Regexxgw0gjiagf4z as Regex,
  sequence2vgswtrxvqoa7 as sequence,
  joinToString1cxrrlmo0chqs as joinToString_0,
  toList2zksu85ukrmi as toList_0,
  toDoubleOrNullkxwozihadygj as toDoubleOrNull,
  toIntOrNull3w2d066r9pvwm as toIntOrNull_0,
  Paire9pteg33gng7 as Pair,
  createThis2j2avj17cvnv2 as createThis,
  KtMutableMap1kqeifoi36kpz as KtMutableMap,
  addAll1k27qatfgp3k5 as addAll,
} from './kotlin-kotlin-stdlib.mjs';
import {
  DoubleList2honu8rif2roh as DoubleList,
  getAt237u54ctbxwvd as getAt,
  listIterator247sl90ooi4qv as listIterator,
  listIterator1a2ckgeamf7jc as listIterator_0,
  subList28p829u9d5w23 as subList,
  containsteg8664aaglk as contains_0,
  containsAll1oa5i6t9xmbdv as containsAll,
  isEmptyrqh4vz39e0v6 as isEmpty,
  iterator300ls4j24zqzz as iterator,
} from './korlibs-korlibs-datastructure-core.mjs';
import {
  NumberParser_getInstance1wuuyirzmqen5 as NumberParser_getInstance,
  ctypeAsInt3q5txwwtaj9c8 as ctypeAsInt,
} from './korlibs-korlibs-math-core.mjs';
import {
  Companion_instance29c5vc3xudisn as Companion_instance,
  get_eofrk1bzx8ckgtd as get_eof,
  readUntilBuilderazvgts3bogti as readUntilBuilder,
  isWhitespaceFast2iszcfvpi3biz as isWhitespaceFast,
  skipExpect1tagjz9aj6y5q as skipExpect,
  skipSpaces25iogcdi8m3q2 as skipSpaces,
  tryExpect2a3h5rf36r8u1 as tryExpect,
  matchIdentifier3b7zgfy94bf8r as matchIdentifier,
  matchSingleOrDoubleQuoteString398sq4vhn9fz as matchSingleOrDoubleQuoteString,
} from './korlibs-korlibs-string.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Json {
  constructor() {
    Companion_getInstance();
    this.h2d_1 = false;
  }
  i2d(s) {
    return this.j2d(Companion_instance.sz(s));
  }
  k2d() {
    return this.h2d_1;
  }
  l2d(capacity) {
    return ArrayList.w(capacity);
  }
  m2d(capacity, $super) {
    capacity = capacity === VOID ? 16 : capacity;
    return $super === VOID ? this.l2d(capacity) : $super.l2d.call(this, capacity);
  }
  n2d(doubles) {
    return toList(doubles.i13());
  }
  j2d(s) {
    var ic = skipSpaces_0(s, this).jz();
    var tmp;
    if (ic === _Char___init__impl__6a9atx(123)) {
      tmp = parseObject(this, s);
    } else if (ic === _Char___init__impl__6a9atx(91)) {
      tmp = parseArray(this, s);
    } else if (ic === _Char___init__impl__6a9atx(45) || (ic === _Char___init__impl__6a9atx(43) || ic === _Char___init__impl__6a9atx(48)) || (ic === _Char___init__impl__6a9atx(49) || (ic === _Char___init__impl__6a9atx(50) || ic === _Char___init__impl__6a9atx(51))) || (ic === _Char___init__impl__6a9atx(52) || (ic === _Char___init__impl__6a9atx(53) || ic === _Char___init__impl__6a9atx(54)) || (ic === _Char___init__impl__6a9atx(55) || (ic === _Char___init__impl__6a9atx(56) || ic === _Char___init__impl__6a9atx(57))))) {
      var dres = parseNumber(this, s);
      tmp = numberToInt(dres) === dres ? numberToInt(dres) : dres;
    } else if (ic === _Char___init__impl__6a9atx(116)) {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.serialization.json.Json.parse.<anonymous>' call
      expect(s, this, 'true');
      tmp = true;
    } else if (ic === _Char___init__impl__6a9atx(102)) {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.serialization.json.Json.parse.<anonymous>' call
      expect(s, this, 'false');
      tmp = false;
    } else if (ic === _Char___init__impl__6a9atx(110)) {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.serialization.json.Json.parse.<anonymous>' call
      expect(s, this, 'null');
      tmp = null;
    } else if (ic === _Char___init__impl__6a9atx(117)) {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.serialization.json.Json.parse.<anonymous>' call
      expect(s, this, 'undefined');
      tmp = null;
    } else if (ic === _Char___init__impl__6a9atx(34)) {
      tmp = this.p2d(s).toString();
    } else {
      invalidJson(this, "Not expected '" + toString(ic) + "' in " + toString_0(s));
    }
    return tmp;
  }
  o2d(_this__u8e3s4, reportErrors, out) {
    var quotec = _this__u8e3s4.kz();
    if (quotec !== _Char___init__impl__6a9atx(34) && quotec !== _Char___init__impl__6a9atx(39))
      throw IllegalArgumentException.l('Invalid string literal');
    var closed = false;
    loop: while (_this__u8e3s4.uy()) {
      var c = _this__u8e3s4.kz();
      if (c === _Char___init__impl__6a9atx(92)) {
        var cc = _this__u8e3s4.kz();
        var tmp;
        if (cc === _Char___init__impl__6a9atx(92)) {
          tmp = _Char___init__impl__6a9atx(92);
        } else if (cc === _Char___init__impl__6a9atx(47)) {
          tmp = _Char___init__impl__6a9atx(47);
        } else if (cc === _Char___init__impl__6a9atx(39)) {
          tmp = _Char___init__impl__6a9atx(39);
        } else if (cc === _Char___init__impl__6a9atx(34)) {
          tmp = _Char___init__impl__6a9atx(34);
        } else if (cc === _Char___init__impl__6a9atx(98)) {
          tmp = _Char___init__impl__6a9atx(8);
        } else if (cc === _Char___init__impl__6a9atx(102)) {
          tmp = _Char___init__impl__6a9atx(12);
        } else if (cc === _Char___init__impl__6a9atx(110)) {
          tmp = _Char___init__impl__6a9atx(10);
        } else if (cc === _Char___init__impl__6a9atx(114)) {
          tmp = _Char___init__impl__6a9atx(13);
        } else if (cc === _Char___init__impl__6a9atx(116)) {
          tmp = _Char___init__impl__6a9atx(9);
        } else if (cc === _Char___init__impl__6a9atx(117)) {
          // Inline function 'korlibs.io.util.NumberParser.parseInt' call
          NumberParser_getInstance();
          var positive = true;
          var out_0 = 0;
          var n = 0;
          $l$loop_0: while (true) {
            // Inline function 'korlibs.io.serialization.json.Json.readStringLit.<anonymous>' call
            var _unary__edvuaz = n;
            n = _unary__edvuaz + 1 | 0;
            var c_0 = _unary__edvuaz >= 4 ? _Char___init__impl__6a9atx(0) : _this__u8e3s4.kz();
            if (c_0 === _Char___init__impl__6a9atx(0))
              break $l$loop_0;
            if (c_0 === _Char___init__impl__6a9atx(45) || c_0 === _Char___init__impl__6a9atx(43)) {
              positive = c_0 === _Char___init__impl__6a9atx(43);
            } else {
              var value = ctypeAsInt(c_0);
              if (value < 0)
                break $l$loop_0;
              out_0 = imul(out_0, 16);
              out_0 = out_0 + value | 0;
            }
          }
          var tmp$ret$1 = positive ? out_0 : -out_0 | 0;
          tmp = numberToChar(tmp$ret$1);
        } else {
          throw IllegalArgumentException.l("Invalid char '" + toString(cc) + "'");
        }
        var c_1 = tmp;
        out.jc(c_1);
      } else if (c === quotec) {
        closed = true;
        break loop;
      } else
        out.jc(c);
    }
    if (!closed && reportErrors) {
      throw RuntimeException.db("String literal not closed! '" + toString_0(_this__u8e3s4) + "'");
    }
    return out;
  }
  p2d(_this__u8e3s4, reportErrors, out, $super) {
    reportErrors = reportErrors === VOID ? true : reportErrors;
    out = out === VOID ? StringBuilder.h() : out;
    return $super === VOID ? this.o2d(_this__u8e3s4, reportErrors, out) : $super.o2d.call(this, _this__u8e3s4, reportErrors, out);
  }
}
class Companion extends Json {
  constructor() {
    Companion_instance_0 = null;
    super();
    Companion_instance_0 = this;
  }
}
class MiniNumberArrayList {
  constructor() {
    this.q2d_1 = 0;
    this.r2d_1 = new Float64Array(16);
  }
  j1() {
    return this.q2d_1;
  }
  s2d() {
    return this.r2d_1.length;
  }
  i1(index) {
    return this.r2d_1[index];
  }
  t2d(value) {
    if (this.q2d_1 >= this.s2d()) {
      this.r2d_1 = copyOf(this.r2d_1, imul(this.r2d_1.length, 3));
    }
    var tmp = this.r2d_1;
    var _unary__edvuaz = this.q2d_1;
    this.q2d_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = value;
  }
  i13() {
    return copyOf(this.r2d_1, this.q2d_1);
  }
  j13() {
    // Inline function 'kotlin.also' call
    var this_0 = new MiniNumberArrayList();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.serialization.json.MiniNumberArrayList.clone.<anonymous>' call
    this_0.q2d_1 = this.q2d_1;
    var tmp = this_0;
    // Inline function 'kotlin.collections.copyOf' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.r2d_1 = this.r2d_1.slice();
    return this_0;
  }
  equals(other) {
    if (!(!(other == null) ? isInterface(other, Collection) : false))
      return false;
    if (!(other.j1() === this.q2d_1))
      return false;
    if (isInterface(other, DoubleList)) {
      var inductionVariable = 0;
      var last = this.q2d_1;
      if (inductionVariable < last)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.i1(n) === other.i1(n)))
            return false;
        }
         while (inductionVariable < last);
      return true;
    }
    if (isInterface(other, KtList)) {
      var inductionVariable_0 = 0;
      var last_0 = this.q2d_1;
      if (inductionVariable_0 < last_0)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (!equals(this.i1(n_0), other.i1(n_0)))
            return false;
        }
         while (inductionVariable_0 < last_0);
      return true;
    }
    var n_1 = 0;
    var _iterator__ex2g4s = other.f1();
    while (_iterator__ex2g4s.g1()) {
      var v = _iterator__ex2g4s.h1();
      var _unary__edvuaz = n_1;
      n_1 = _unary__edvuaz + 1 | 0;
      if (!equals(this.i1(_unary__edvuaz), v))
        return false;
    }
    return true;
  }
  hashCode() {
    return contentHashCode(this.r2d_1, this, 0, this.q2d_1);
  }
  toString() {
    // Inline function 'kotlin.also' call
    var this_0 = StringBuilder.nc(2 + imul(5, this.q2d_1) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.serialization.json.MiniNumberArrayList.toString.<anonymous>' call
    this_0.jc(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.q2d_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.ic(', ');
        }
        var v = this.h13(n);
        if (numberToInt(v) === v)
          this_0.oh(numberToInt(v));
        else
          this_0.rh(v);
      }
       while (inductionVariable < last);
    this_0.jc(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  }
  f3(element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.k13((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  }
  g3(elements) {
    return this.l13(elements);
  }
}
class Xml$Companion$parse$Level {
  constructor(children, close) {
    this.u2d_1 = children;
    this.v2d_1 = close;
  }
  toString() {
    return 'Level(children=' + toString_0(this.u2d_1) + ', close=' + toString_1(this.v2d_1) + ')';
  }
  hashCode() {
    var result = hashCode(this.u2d_1);
    result = imul(result, 31) + (this.v2d_1 == null ? 0 : this.v2d_1.hashCode()) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Xml$Companion$parse$Level))
      return false;
    var tmp0_other_with_cast = other instanceof Xml$Companion$parse$Level ? other : THROW_CCE();
    if (!equals(this.u2d_1, tmp0_other_with_cast.u2d_1))
      return false;
    if (!equals(this.v2d_1, tmp0_other_with_cast.v2d_1))
      return false;
    return true;
  }
}
class Companion_0 {
  u2e(lits) {
    // Inline function 'kotlin.collections.toTypedArray' call
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(lits.length);
    var inductionVariable = 0;
    var last = lits.length;
    while (inductionVariable < last) {
      var item = lits[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.io.serialization.xml.Companion.fromList.<anonymous>' call
      var tmp$ret$0 = item.length;
      destination.f(tmp$ret$0);
    }
    var this_0 = distinct(reversed(sorted(destination)));
    var lengths = copyToArray(this_0);
    // Inline function 'kotlin.collections.linkedMapOf' call
    var map = LinkedHashMap.vc();
    // Inline function 'korlibs.io.serialization.xml.fastForEach' call
    var n = 0;
    while (n < lits.length) {
      // Inline function 'korlibs.io.serialization.xml.Companion.fromList.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      // Inline function 'kotlin.collections.set' call
      var key = lits[_unary__edvuaz];
      map.r3(key, true);
    }
    return new Literals(lits, map, lengths);
  }
}
class Element {}
class ProcessingInstructionTag extends Element {
  constructor(name, attributes) {
    super();
    this.v2e_1 = name;
    this.w2e_1 = attributes;
  }
  toString() {
    return 'ProcessingInstructionTag(name=' + this.v2e_1 + ', attributes=' + toString_0(this.w2e_1) + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.v2e_1);
    result = imul(result, 31) + hashCode(this.w2e_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ProcessingInstructionTag))
      return false;
    var tmp0_other_with_cast = other instanceof ProcessingInstructionTag ? other : THROW_CCE();
    if (!(this.v2e_1 === tmp0_other_with_cast.v2e_1))
      return false;
    if (!equals(this.w2e_1, tmp0_other_with_cast.w2e_1))
      return false;
    return true;
  }
}
class OpenCloseTag extends Element {
  constructor(name, attributes) {
    super();
    this.z2d_1 = name;
    this.a2e_1 = attributes;
  }
  toString() {
    return 'OpenCloseTag(name=' + this.z2d_1 + ', attributes=' + toString_0(this.a2e_1) + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.z2d_1);
    result = imul(result, 31) + hashCode(this.a2e_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof OpenCloseTag))
      return false;
    var tmp0_other_with_cast = other instanceof OpenCloseTag ? other : THROW_CCE();
    if (!(this.z2d_1 === tmp0_other_with_cast.z2d_1))
      return false;
    if (!equals(this.a2e_1, tmp0_other_with_cast.a2e_1))
      return false;
    return true;
  }
}
class OpenTag extends Element {
  constructor(name, attributes) {
    super();
    this.x2d_1 = name;
    this.y2d_1 = attributes;
  }
  toString() {
    return 'OpenTag(name=' + this.x2d_1 + ', attributes=' + toString_0(this.y2d_1) + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.x2d_1);
    result = imul(result, 31) + hashCode(this.y2d_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof OpenTag))
      return false;
    var tmp0_other_with_cast = other instanceof OpenTag ? other : THROW_CCE();
    if (!(this.x2d_1 === tmp0_other_with_cast.x2d_1))
      return false;
    if (!equals(this.y2d_1, tmp0_other_with_cast.y2d_1))
      return false;
    return true;
  }
}
class CommentTag extends Element {
  constructor(text) {
    super();
    this.k2e_1 = text;
  }
  toString() {
    return 'CommentTag(text=' + this.k2e_1 + ')';
  }
  hashCode() {
    return getStringHashCode(this.k2e_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CommentTag))
      return false;
    var tmp0_other_with_cast = other instanceof CommentTag ? other : THROW_CCE();
    if (!(this.k2e_1 === tmp0_other_with_cast.k2e_1))
      return false;
    return true;
  }
}
class CloseTag extends Element {
  constructor(name) {
    super();
    this.w2d_1 = name;
  }
  toString() {
    return 'CloseTag(name=' + this.w2d_1 + ')';
  }
  hashCode() {
    return getStringHashCode(this.w2d_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CloseTag))
      return false;
    var tmp0_other_with_cast = other instanceof CloseTag ? other : THROW_CCE();
    if (!(this.w2d_1 === tmp0_other_with_cast.w2d_1))
      return false;
    return true;
  }
}
class Text extends Element {
  constructor(text, cdata) {
    cdata = cdata === VOID ? false : cdata;
    super();
    this.g2e_1 = text;
    this.h2e_1 = cdata;
  }
  toString() {
    return 'Text(text=' + this.g2e_1 + ', cdata=' + this.h2e_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.g2e_1);
    result = imul(result, 31) + getBooleanHashCode(this.h2e_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Text))
      return false;
    var tmp0_other_with_cast = other instanceof Text ? other : THROW_CCE();
    if (!(this.g2e_1 === tmp0_other_with_cast.g2e_1))
      return false;
    if (!(this.h2e_1 === tmp0_other_with_cast.h2e_1))
      return false;
    return true;
  }
}
class Xml$Stream$xmlSequence$slambda {
  constructor($r, $collapseSpaces, $processNamespaces) {
    this.x2e_1 = $r;
    this.y2e_1 = $collapseSpaces;
    this.z2e_1 = $processNamespaces;
  }
  f2f($this$sequence, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$sequence, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.f2f(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  }
}
class Companion_1 {
  constructor() {
    this.b2e_1 = '_raw_';
    this.c2e_1 = '_text_';
    this.d2e_1 = '_cdata_';
    this.e2e_1 = '_comment_';
  }
  f2e(tagName, attributes, children) {
    var tmp = Type_NODE_getInstance();
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.collections.filterTo' call
    var destination = LinkedHashMap.vc();
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = attributes.z1().f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.io.serialization.xml.Companion.Tag.<anonymous>' call
      if (!(element.b2() == null)) {
        destination.r3(element.a2(), element.b2());
      }
    }
    // Inline function 'kotlin.collections.mapTo' call
    var destination_0 = ArrayList.w(destination.j1());
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator_0 = destination.z1().f1();
    while (tmp0_iterator_0.g1()) {
      var item = tmp0_iterator_0.h1();
      // Inline function 'korlibs.io.serialization.xml.Companion.Tag.<anonymous>' call
      var tmp$ret$5 = to(item.a2(), toString_1(item.b2()));
      destination_0.f(tmp$ret$5);
    }
    return new Xml(tmp, tagName, toMap(destination_0), children, '');
  }
  i2e(text) {
    var tmp = Type_TEXT_getInstance();
    var tmp_0 = LinkedHashMap.vc();
    // Inline function 'kotlin.collections.listOf' call
    var tmp$ret$0 = emptyList();
    return new Xml(tmp, '_text_', tmp_0, tmp$ret$0, text);
  }
  j2e(text) {
    var tmp = Type_TEXT_getInstance();
    var tmp_0 = LinkedHashMap.vc();
    // Inline function 'kotlin.collections.listOf' call
    var tmp$ret$0 = emptyList();
    return new Xml(tmp, '_cdata_', tmp_0, tmp$ret$0, text);
  }
  l2e(text) {
    var tmp = Type_COMMENT_getInstance();
    var tmp_0 = LinkedHashMap.vc();
    // Inline function 'kotlin.collections.listOf' call
    var tmp$ret$0 = emptyList();
    return new Xml(tmp, '_comment_', tmp_0, tmp$ret$0, text);
  }
  g2f(str, collapseSpaces, processNamespaces) {
    try {
      var stream = Stream_getInstance().h2f(str, collapseSpaces, processNamespaces).f1();
      var children = parse$level(stream).u2d_1;
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlin.collections.firstOrNull' call
        var tmp0_iterator = children.f1();
        while (tmp0_iterator.g1()) {
          var element = tmp0_iterator.h1();
          // Inline function 'korlibs.io.serialization.xml.Companion.parse.<anonymous>' call
          if (element.m2e_1.equals(Type_NODE_getInstance())) {
            tmp$ret$1 = element;
            break $l$block;
          }
        }
        tmp$ret$1 = null;
      }
      var tmp0_elvis_lhs = tmp$ret$1;
      var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? firstOrNull(children) : tmp0_elvis_lhs;
      return tmp1_elvis_lhs == null ? Companion_instance_2.i2e('') : tmp1_elvis_lhs;
    } catch ($p) {
      if ($p instanceof NoSuchElementException) {
        var t = $p;
        println('ERROR: XML: ' + str + ' thrown a NoSuchElementException');
        return Companion_instance_2.i2e('!!ERRORED!!');
      } else {
        throw $p;
      }
    }
  }
}
class Type extends Enum {}
class Literals {
  constructor(lits, map, lengths) {
    this.i2f_1 = lits;
    this.j2f_1 = map;
    this.k2f_1 = lengths;
  }
  toString() {
    return 'Literals(' + joinToString(this.i2f_1, ' ') + ')';
  }
}
class Entities {
  constructor() {
    Entities_instance = this;
    this.b2f_1 = linkedMapOf([to(new Char(_Char___init__impl__6a9atx(34)), '&quot;'), to(new Char(_Char___init__impl__6a9atx(39)), '&apos;'), to(new Char(_Char___init__impl__6a9atx(60)), '&lt;'), to(new Char(_Char___init__impl__6a9atx(62)), '&gt;'), to(new Char(_Char___init__impl__6a9atx(38)), '&amp;')]);
    var tmp = this;
    var tmp_0 = Companion_instance_1;
    // Inline function 'kotlin.collections.toTypedArray' call
    var this_0 = this.b2f_1.m3();
    var tmp$ret$0 = copyToArray(this_0);
    tmp.c2f_1 = tmp_0.u2e(tmp$ret$0);
    this.d2f_1 = flip(this.b2f_1);
  }
  l2f(str) {
    // Inline function 'korlibs.io.serialization.xml.eachBuilder' call
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.serialization.xml.eachBuilder.<anonymous>' call
    var inductionVariable = 0;
    var last = str.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.io.serialization.xml.Entities.encode.<anonymous>' call
        var it = charSequenceGet(str, n);
        var entry = Entities_getInstance().b2f_1.k3(new Char(it));
        if (!(entry == null))
          this_0.ic(entry);
        else
          this_0.jc(it);
      }
       while (inductionVariable < last);
    return this_0.toString();
  }
  e2f(str) {
    return this.m2f(Companion_instance.sz(str));
  }
  m2f(r) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.serialization.xml.Entities.decode.<anonymous>' call
    var sb = StringBuilder.h();
    $l$loop: while (!get_eof(r)) {
      this_0.e(readUntilBuilder(r, _Char___init__impl__6a9atx(38), sb.wh()));
      if (get_eof(r))
        break $l$loop;
      skipExpect(r, _Char___init__impl__6a9atx(38));
      var value = readUntilBuilder(r, _Char___init__impl__6a9atx(59), sb.wh(), true);
      var full = '&' + value.toString();
      if (startsWith_1(value, _Char___init__impl__6a9atx(35))) {
        var base = 10;
        var str = value.uh(1, value.a() - 1 | 0);
        if (startsWith_0(str, 'x')) {
          base = 16;
          // Inline function 'kotlin.text.substring' call
          // Inline function 'kotlin.js.asDynamic' call
          str = str.substring(1);
        }
        var tmp42_safe_receiver = toIntOrNull(str, base);
        var tmp = tmp42_safe_receiver == null ? null : numberToChar(tmp42_safe_receiver);
        this_0.hc(tmp == null ? null : new Char(tmp));
      } else {
        // Inline function 'kotlin.collections.contains' call
        // Inline function 'kotlin.collections.containsKey' call
        var this_1 = Entities_getInstance().d2f_1;
        if ((isInterface(this_1, KtMap) ? this_1 : THROW_CCE()).i3(full))
          this_0.hc(Entities_getInstance().d2f_1.k3(full));
        else {
          this_0.ic(full);
        }
      }
    }
    return this_0.toString();
  }
}
class Stream {
  constructor() {
    Stream_instance = this;
    this.a2f_1 = Regex.ni('\\s+');
  }
  h2f(str, collapseSpaces, processNamespaces) {
    return this.n2f(Companion_instance.sz(str), collapseSpaces, processNamespaces);
  }
  n2f(r, collapseSpaces, processNamespaces) {
    return sequence(Xml$Stream$xmlSequence$slambda_0(r, collapseSpaces, processNamespaces));
  }
}
class Xml {
  constructor(type, name, attributes, allChildren, content) {
    this.m2e_1 = type;
    this.n2e_1 = name;
    this.o2e_1 = attributes;
    this.p2e_1 = allChildren;
    this.q2e_1 = content;
    this.r2e_1 = toCaseInsensitiveMap(this.o2e_1);
    var tmp = this;
    // Inline function 'kotlin.text.trim' call
    // Inline function 'kotlin.text.lowercase' call
    // Inline function 'kotlin.js.asDynamic' call
    var this_0 = this.n2e_1.toLowerCase();
    tmp.s2e_1 = toString_0(trim(isCharSequence(this_0) ? this_0 : THROW_CCE()));
  }
  q2f() {
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.collections.filterTo' call
    var this_0 = this.p2e_1;
    var destination = ArrayList.m1();
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.io.serialization.xml.Xml.<get-allChildrenNoComments>.<anonymous>' call
      if (!get_isComment(element)) {
        destination.f(element);
      }
    }
    return destination;
  }
  r2f() {
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.collections.filterTo' call
    var this_0 = this.p2e_1;
    var destination = ArrayList.m1();
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.io.serialization.xml.Xml.<get-allNodeChildren>.<anonymous>' call
      if (get_isNode(element)) {
        destination.f(element);
      }
    }
    return destination;
  }
  o2f() {
    var tmp;
    switch (this.m2e_1.v3_1) {
      case 0:
        tmp = joinToString_0(this.p2e_1, '', VOID, VOID, VOID, VOID, Xml$_get_text_$lambda_nsh6o);
        break;
      case 1:
        tmp = this.q2e_1;
        break;
      case 2:
        tmp = '';
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  s2f() {
    // Inline function 'kotlin.collections.map' call
    var this_0 = toList_0(this.o2e_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.io.serialization.xml.Xml.<get-attributesStr>.<anonymous>' call
      var tmp$ret$0 = ' ' + item.im_1 + '="' + item.jm_1 + '"';
      destination.f(tmp$ret$0);
    }
    return joinToString_0(destination, '');
  }
  p2f() {
    var tmp;
    switch (this.m2e_1.v3_1) {
      case 0:
        var tmp_0;
        if (this.p2e_1.a1()) {
          tmp_0 = '<' + this.n2e_1 + this.s2f() + '/>';
        } else {
          var children = joinToString_0(this.p2e_1, '', VOID, VOID, VOID, VOID, Xml$_get_outerXml_$lambda_n5rc6n);
          tmp_0 = '<' + this.n2e_1 + this.s2f() + '>' + children + '<\/' + this.n2e_1 + '>';
        }

        tmp = tmp_0;
        break;
      case 1:
        switch (this.n2e_1) {
          case '_text_':
            tmp = Entities_getInstance().l2f(this.q2e_1);
            break;
          case '_cdata_':
            tmp = '<![CDATA[' + this.q2e_1 + ']]>';
            break;
          case '_raw_':
            tmp = this.q2e_1;
            break;
          default:
            tmp = this.q2e_1;
            break;
        }

        break;
      case 2:
        tmp = '<!--' + this.q2e_1 + '-->';
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  bj(name) {
    return this.t2f(name);
  }
  t2f(name) {
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.collections.filterTo' call
    var this_0 = this.p2e_1;
    var destination = ArrayList.m1();
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.io.serialization.xml.Xml.children.<anonymous>' call
      if (equals_0(element.n2e_1, name, true)) {
        destination.f(element);
      }
    }
    return destination;
  }
  j1d(name) {
    return this.r2e_1.k3(name);
  }
  u2f(name, defaultValue) {
    var tmp29_safe_receiver = this.r2e_1.k3(name);
    var tmp0_elvis_lhs = tmp29_safe_receiver == null ? null : toDoubleOrNull(tmp29_safe_receiver);
    return tmp0_elvis_lhs == null ? defaultValue : tmp0_elvis_lhs;
  }
  v2f(name, defaultValue, $super) {
    defaultValue = defaultValue === VOID ? 0.0 : defaultValue;
    return $super === VOID ? this.u2f(name, defaultValue) : $super.u2f.call(this, name, defaultValue);
  }
  w2f(name, defaultValue) {
    var tmp0_elvis_lhs = this.x2f(name);
    return tmp0_elvis_lhs == null ? defaultValue : tmp0_elvis_lhs;
  }
  x2f(name) {
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    switch (this.y2f(name).toLowerCase()) {
      case 'true':
      case '1':
        return true;
      case 'false':
      case '0':
        return false;
      default:
        return null;
    }
  }
  z2f(name, defaultValue) {
    var tmp31_safe_receiver = this.r2e_1.k3(name);
    var tmp;
    if (tmp31_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.text.toFloatOrNull' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = toDoubleOrNull(tmp31_safe_receiver);
    }
    var tmp0_elvis_lhs = tmp;
    return tmp0_elvis_lhs == null ? defaultValue : tmp0_elvis_lhs;
  }
  a2g(name, defaultValue, $super) {
    defaultValue = defaultValue === VOID ? 0.0 : defaultValue;
    return $super === VOID ? this.z2f(name, defaultValue) : $super.z2f.call(this, name, defaultValue);
  }
  b2g(name, defaultValue) {
    var tmp32_safe_receiver = this.r2e_1.k3(name);
    var tmp0_elvis_lhs = tmp32_safe_receiver == null ? null : toIntOrNull_0(tmp32_safe_receiver);
    return tmp0_elvis_lhs == null ? defaultValue : tmp0_elvis_lhs;
  }
  c2g(name, defaultValue, $super) {
    defaultValue = defaultValue === VOID ? 0 : defaultValue;
    return $super === VOID ? this.b2g(name, defaultValue) : $super.b2g.call(this, name, defaultValue);
  }
  d2g(name, defaultValue) {
    var tmp0_elvis_lhs = this.r2e_1.k3(name);
    return tmp0_elvis_lhs == null ? defaultValue : tmp0_elvis_lhs;
  }
  y2f(name, defaultValue, $super) {
    defaultValue = defaultValue === VOID ? '' : defaultValue;
    return $super === VOID ? this.d2g(name, defaultValue) : $super.d2g.call(this, name, defaultValue);
  }
  e2g(name) {
    var tmp35_safe_receiver = this.r2e_1.k3(name);
    return tmp35_safe_receiver == null ? null : toDoubleOrNull(tmp35_safe_receiver);
  }
  f2g(name) {
    var tmp37_safe_receiver = this.r2e_1.k3(name);
    return tmp37_safe_receiver == null ? null : toIntOrNull_0(tmp37_safe_receiver);
  }
  g2g(name) {
    return this.r2e_1.k3(name);
  }
  toString() {
    return this.p2f();
  }
  h2g(type, name, attributes, allChildren, content) {
    return new Xml(type, name, attributes, allChildren, content);
  }
  t2e(type, name, attributes, allChildren, content, $super) {
    type = type === VOID ? this.m2e_1 : type;
    name = name === VOID ? this.n2e_1 : name;
    attributes = attributes === VOID ? this.o2e_1 : attributes;
    allChildren = allChildren === VOID ? this.p2e_1 : allChildren;
    content = content === VOID ? this.q2e_1 : content;
    return $super === VOID ? this.h2g(type, name, attributes, allChildren, content) : $super.h2g.call(this, type, name, attributes, allChildren, content);
  }
  hashCode() {
    var result = this.m2e_1.hashCode();
    result = imul(result, 31) + getStringHashCode(this.n2e_1) | 0;
    result = imul(result, 31) + hashCode(this.o2e_1) | 0;
    result = imul(result, 31) + hashCode(this.p2e_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.q2e_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Xml))
      return false;
    var tmp0_other_with_cast = other instanceof Xml ? other : THROW_CCE();
    if (!this.m2e_1.equals(tmp0_other_with_cast.m2e_1))
      return false;
    if (!(this.n2e_1 === tmp0_other_with_cast.n2e_1))
      return false;
    if (!equals(this.o2e_1, tmp0_other_with_cast.o2e_1))
      return false;
    if (!equals(this.p2e_1, tmp0_other_with_cast.p2e_1))
      return false;
    if (!(this.q2e_1 === tmp0_other_with_cast.q2e_1))
      return false;
    return true;
  }
}
class CaseInsensitiveStringMap {
  static n2g(mapOrig, lcToOrig, mapLC) {
    var $this = createThis(this);
    $this.i2g_1 = mapOrig;
    $this.j2g_1 = lcToOrig;
    $this.k2g_1 = mapLC;
    return $this;
  }
  a1() {
    return this.i2g_1.a1();
  }
  z1() {
    return this.i2g_1.z1();
  }
  l3() {
    return this.i2g_1.l3();
  }
  m3() {
    return this.i2g_1.m3();
  }
  j1() {
    return this.i2g_1.j1();
  }
  static l2g() {
    return this.n2g(LinkedHashMap.vc(), LinkedHashMap.vc(), LinkedHashMap.vc());
  }
  c14(key) {
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = key.toLowerCase();
    return this.k2g_1.i3(tmp$ret$1);
  }
  i3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    return this.c14((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
  bj(key) {
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = key.toLowerCase();
    return this.k2g_1.k3(tmp$ret$1);
  }
  k3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.bj((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
  o2g(key, value) {
    this.z13(key);
    // Inline function 'kotlin.collections.set' call
    this.i2g_1.r3(key, value);
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.j2g_1;
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var key_0 = key.toLowerCase();
    this_0.r3(key_0, key);
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$3 = key.toLowerCase();
    return this.k2g_1.r3(tmp$ret$3, value);
  }
  r3(key, value) {
    var tmp = (!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE();
    return this.o2g(tmp, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  }
  m2g(from) {
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s = from.z1().f1();
    while (_iterator__ex2g4s.g1()) {
      var v = _iterator__ex2g4s.h1();
      this.o2g(v.a2(), v.b2());
    }
  }
  t3(from) {
    return this.m2g(from);
  }
  z13(key) {
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var lkey = key.toLowerCase();
    var okey = this.j2g_1.k3(lkey);
    // Inline function 'kotlin.collections.remove' call
    var this_0 = this.i2g_1;
    (isInterface(this_0, KtMutableMap) ? this_0 : THROW_CCE()).s3(okey);
    var res = this.k2g_1.s3(lkey);
    this.j2g_1.s3(lkey);
    return res;
  }
  s3(key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.z13((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  }
  equals(other) {
    var tmp;
    if (other instanceof CaseInsensitiveStringMap) {
      tmp = equals(this.k2g_1, other.k2g_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return hashCode(this.k2g_1);
  }
}
//endregion
function contentHashCode(_this__u8e3s4, $this, src, dst) {
  // Inline function 'korlibs.io.serialization.json.MiniNumberArrayList.hashCoder' call
  var count = dst - src | 0;
  var out = 0;
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      out = imul(out, 7);
      var tmp = out;
      // Inline function 'korlibs.io.serialization.json.MiniNumberArrayList.contentHashCode.<anonymous>' call
      out = tmp + numberToInt(_this__u8e3s4[src + n | 0]) | 0;
    }
     while (inductionVariable < count);
  return out;
}
var Companion_instance_0;
function Companion_getInstance() {
  if (Companion_instance_0 === VOID)
    new Companion();
  return Companion_instance_0;
}
function parseObject($this, s) {
  skipExpect_0(s, $this, _Char___init__impl__6a9atx(123));
  // Inline function 'kotlin.apply' call
  var this_0 = LinkedHashMap.vc();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.serialization.json.Json.parseObject.<anonymous>' call
  obj: while (true) {
    var tmp5_subject = skipSpaces_0(s, $this).jz();
    if (tmp5_subject === _Char___init__impl__6a9atx(125)) {
      s.kz();
      break obj;
    } else if (tmp5_subject === _Char___init__impl__6a9atx(44)) {
      s.kz();
      continue obj;
    }
    var tmp = $this.j2d(s);
    var key = (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : THROW_CCE();
    skipExpect_0(skipSpaces_0(s, $this), $this, _Char___init__impl__6a9atx(58));
    var value = $this.j2d(s);
    // Inline function 'kotlin.collections.set' call
    this_0.r3(key, value);
  }
  return this_0;
}
function parseArray($this, s) {
  var out = null;
  var outNumber = null;
  skipExpect_0(s, $this, _Char___init__impl__6a9atx(91));
  array: while (true) {
    var tmp6_subject = skipSpaces_0(s, $this).jz();
    if (tmp6_subject === _Char___init__impl__6a9atx(93)) {
      s.kz();
      break array;
    } else if (tmp6_subject === _Char___init__impl__6a9atx(44)) {
      s.kz();
      continue array;
    }
    var v = s.jz();
    if (out == null && $this.k2d() && ((_Char___init__impl__6a9atx(48) <= v ? v <= _Char___init__impl__6a9atx(57) : false) || v === _Char___init__impl__6a9atx(45))) {
      if (outNumber == null) {
        outNumber = new MiniNumberArrayList();
      }
      outNumber.t2d(parseNumber($this, s));
    } else {
      if (out == null) {
        var tmp7_safe_receiver = outNumber;
        var tmp0_elvis_lhs = tmp7_safe_receiver == null ? null : tmp7_safe_receiver.q2d_1;
        out = $this.l2d(tmp0_elvis_lhs == null ? 16 : tmp0_elvis_lhs);
      }
      if (!(outNumber == null)) {
        // Inline function 'korlibs.io.serialization.json.MiniNumberArrayList.fastForEach' call
        var this_0 = outNumber;
        var inductionVariable = 0;
        var last = this_0.q2d_1;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.io.serialization.json.Json.parseArray.<anonymous>' call
            var it = this_0.r2d_1[n];
            out.f(it);
          }
           while (inductionVariable < last);
        outNumber = null;
      }
      out.f($this.j2d(s));
    }
  }
  var tmp;
  if (outNumber == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.serialization.json.Json.parseArray.<anonymous>' call
    tmp = $this.n2d(outNumber);
  }
  var tmp1_elvis_lhs = tmp;
  var tmp2_elvis_lhs = tmp1_elvis_lhs == null ? out : tmp1_elvis_lhs;
  return tmp2_elvis_lhs == null ? $this.m2d() : tmp2_elvis_lhs;
}
function parseNumber($this, s) {
  // Inline function 'korlibs.io.util.NumberParser.parseDouble' call
  NumberParser_getInstance();
  var out = 0.0;
  var frac = 1.0;
  var pointSeen = false;
  var eSeen = false;
  var negate = false;
  var negateExponent = false;
  var exponent = 0;
  var n = 0;
  $l$loop: while (true) {
    // Inline function 'korlibs.io.serialization.json.Json.parseNumber.<anonymous>' call
    n = n + 1 | 0;
    var c = s.jz();
    var isC = Char__compareTo_impl_ypi4mb(c, _Char___init__impl__6a9atx(48)) >= 0 && Char__compareTo_impl_ypi4mb(c, _Char___init__impl__6a9atx(57)) <= 0 || c === _Char___init__impl__6a9atx(46) || c === _Char___init__impl__6a9atx(101) || c === _Char___init__impl__6a9atx(69) || c === _Char___init__impl__6a9atx(45) || c === _Char___init__impl__6a9atx(43);
    if (isC) {
      s.kz();
    }
    var c_0 = isC ? c : _Char___init__impl__6a9atx(0);
    if (c_0 === _Char___init__impl__6a9atx(0))
      break $l$loop;
    if (c_0 === _Char___init__impl__6a9atx(101) || c_0 === _Char___init__impl__6a9atx(69))
      eSeen = true;
    else if (c_0 === _Char___init__impl__6a9atx(45)) {
      if (eSeen)
        negateExponent = true;
      else
        negate = true;
    } else if (c_0 === _Char___init__impl__6a9atx(46))
      pointSeen = true;
    else {
      if (eSeen) {
        exponent = imul(exponent, 10);
        exponent = exponent + ctypeAsInt(c_0) | 0;
      } else {
        if (pointSeen)
          frac = frac / 10;
        out = out * 10;
        out = out + ctypeAsInt(c_0);
      }
    }
  }
  var tmp = out * frac;
  // Inline function 'kotlin.math.pow' call
  var n_0 = negateExponent ? -exponent | 0 : exponent;
  var res = tmp * Math.pow(10.0, n_0);
  return negate ? -res : res;
}
function invalidJson($this, msg) {
  throw IllegalArgumentException.l(msg);
}
function expect(_this__u8e3s4, $this, str) {
  var inductionVariable = 0;
  var last = charSequenceLength(str) - 1 | 0;
  if (inductionVariable <= last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var c = _this__u8e3s4.kz();
      if (!(c === charSequenceGet(str, n)))
        throw IllegalStateException.m5("Expected '" + str + "' but found '" + toString(c) + "' at " + n);
    }
     while (inductionVariable <= last);
}
function skipSpaces_0(_this__u8e3s4, $this) {
  // Inline function 'korlibs.io.serialization.json.Json.skipWhile' call
  $l$loop: while (true) {
    var tmp;
    if (_this__u8e3s4.uy()) {
      // Inline function 'korlibs.io.serialization.json.Json.skipSpaces.<anonymous>' call
      var it = _this__u8e3s4.jz();
      tmp = isWhitespaceFast_0(it, $this);
    } else {
      tmp = false;
    }
    if (!tmp) {
      break $l$loop;
    }
    _this__u8e3s4.kz();
  }
  return _this__u8e3s4;
}
function skipExpect_0(_this__u8e3s4, $this, expected) {
  var readed = _this__u8e3s4.kz();
  if (!(readed === expected))
    throw IllegalArgumentException.l("Expected '" + toString(expected) + "' but found '" + toString(readed) + "' at " + _this__u8e3s4.lz());
}
function isWhitespaceFast_0(_this__u8e3s4, $this) {
  return _this__u8e3s4 === _Char___init__impl__6a9atx(32) || _this__u8e3s4 === _Char___init__impl__6a9atx(9) || _this__u8e3s4 === _Char___init__impl__6a9atx(13) || _this__u8e3s4 === _Char___init__impl__6a9atx(10);
}
function parse$level(stream) {
  // Inline function 'kotlin.collections.arrayListOf' call
  var children = ArrayList.m1();
  var textNodes = 0;
  var endTag = null;
  loop: while (stream.g1()) {
    var tag = stream.h1();
    if (!(tag instanceof ProcessingInstructionTag)) {
      if (tag instanceof CommentTag)
        children.f(Companion_instance_2.l2e(tag.k2e_1));
      else {
        if (tag instanceof Text) {
          // Inline function 'kotlin.also' call
          children.f(tag.h2e_1 ? Companion_instance_2.j2e(tag.g2e_1) : Companion_instance_2.i2e(tag.g2e_1));
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.io.serialization.xml.Companion.parse.level.<anonymous>' call
          textNodes = textNodes + 1 | 0;
        } else {
          if (tag instanceof OpenCloseTag) {
            var tmp = Companion_instance_2;
            // Inline function 'kotlin.collections.listOf' call
            var tmp$ret$2 = emptyList();
            children.f(tmp.f2e(tag.z2d_1, tag.a2e_1, tmp$ret$2));
          } else {
            if (tag instanceof OpenTag) {
              var out = parse$level(stream);
              var tmp17_safe_receiver = out.v2d_1;
              var tmp18_safe_receiver = tmp17_safe_receiver == null ? null : tmp17_safe_receiver.w2d_1;
              if (!((tmp18_safe_receiver == null ? null : equals_0(tmp18_safe_receiver, tag.x2d_1, true)) === true)) {
                var tmp16_safe_receiver = out.v2d_1;
                throw IllegalArgumentException.l("Expected '" + tag.x2d_1 + "' but was " + (tmp16_safe_receiver == null ? null : tmp16_safe_receiver.w2d_1));
              }
              children.f(new Xml(Type_NODE_getInstance(), tag.x2d_1, tag.y2d_1, out.u2d_1, ''));
            } else {
              if (tag instanceof CloseTag) {
                endTag = tag;
                break loop;
              } else {
                noWhenBranchMatchedException();
              }
            }
          }
        }
      }
    }
  }
  // Inline function 'kotlin.collections.mapIndexed' call
  // Inline function 'kotlin.collections.mapIndexedTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(children, 10));
  var index = 0;
  var tmp0_iterator = children.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'korlibs.io.serialization.xml.Companion.parse.level.<anonymous>' call
    var tmp1 = index;
    index = tmp1 + 1 | 0;
    var i = checkIndexOverflow(tmp1);
    var tmp_0;
    if (item.m2e_1.equals(Type_TEXT_getInstance())) {
      var firstText = i === 0;
      var lastText = i === (children.j1() - 1 | 0);
      var tmp_1;
      if (firstText && lastText) {
        // Inline function 'kotlin.text.trim' call
        var this_0 = item.q2e_1;
        var tmp$ret$3 = toString_0(trim(isCharSequence(this_0) ? this_0 : THROW_CCE()));
        tmp_1 = item.t2e(VOID, VOID, VOID, VOID, tmp$ret$3);
      } else if (firstText) {
        // Inline function 'kotlin.text.trimStart' call
        var this_1 = item.q2e_1;
        var tmp$ret$4 = toString_0(trimStart(isCharSequence(this_1) ? this_1 : THROW_CCE()));
        tmp_1 = item.t2e(VOID, VOID, VOID, VOID, tmp$ret$4);
      } else if (lastText) {
        // Inline function 'kotlin.text.trimEnd' call
        var this_2 = item.q2e_1;
        var tmp$ret$5 = toString_0(trimEnd(isCharSequence(this_2) ? this_2 : THROW_CCE()));
        tmp_1 = item.t2e(VOID, VOID, VOID, VOID, tmp$ret$5);
      } else {
        tmp_1 = item;
      }
      tmp_0 = tmp_1;
    } else {
      tmp_0 = item;
    }
    var tmp$ret$6 = tmp_0;
    destination.f(tmp$ret$6);
  }
  return new Xml$Companion$parse$Level(destination, endTag);
}
var Type_NODE_instance;
var Type_TEXT_instance;
var Type_COMMENT_instance;
var Type_entriesInitialized;
function Type_initEntries() {
  if (Type_entriesInitialized)
    return Unit_instance;
  Type_entriesInitialized = true;
  Type_NODE_instance = new Type('NODE', 0);
  Type_TEXT_instance = new Type('TEXT', 1);
  Type_COMMENT_instance = new Type('COMMENT', 2);
}
var Companion_instance_1;
function Companion_getInstance_0() {
  return Companion_instance_1;
}
function *_generator_invoke__zhh2q8($this, $this$sequence, $completion) {
  var sb = StringBuilder.nc(128);
  loop: while (!get_eof($this.x2e_1)) {
    var str = readUntilBuilder($this.x2e_1, _Char___init__impl__6a9atx(60), sb.wh(), false);
    // Inline function 'kotlin.text.isNotEmpty' call
    if (charSequenceLength(str) > 0) {
      var text = str.toString();
      var tmp;
      if ($this.y2e_1) {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.text.replace' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.io.serialization.xml.Stream.xmlSequence.<anonymous>.<anonymous>' call
        var it = Stream_getInstance().a2f_1.si(text, ' ');
        var tmp_0;
        var tmp$ret$3;
        $l$block: {
          // Inline function 'kotlin.text.all' call
          var inductionVariable = 0;
          while (inductionVariable < charSequenceLength(it)) {
            var element = charSequenceGet(it, inductionVariable);
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.io.serialization.xml.Stream.xmlSequence.<anonymous>.<anonymous>.<anonymous>' call
            if (!isWhitespaceFast(element)) {
              tmp$ret$3 = false;
              break $l$block;
            }
          }
          tmp$ret$3 = true;
        }
        if (tmp$ret$3) {
          tmp_0 = '';
        } else {
          tmp_0 = it;
        }
        tmp = tmp_0;
      } else {
        tmp = text;
      }
      var textNs = tmp;
      // Inline function 'kotlin.text.isNotEmpty' call
      if (charSequenceLength(textNs) > 0) {
        var tmp_1 = $this$sequence.sm(new Text(Entities_getInstance().e2f(textNs)), $completion);
        if (tmp_1 === get_COROUTINE_SUSPENDED())
          tmp_1 = yield tmp_1;
      }
    }
    if (get_eof($this.x2e_1))
      break loop;
    skipExpect($this.x2e_1, _Char___init__impl__6a9atx(60));
    skipSpaces($this.x2e_1);
    sb.wh();
    var processingInstruction = tryExpect($this.x2e_1, _Char___init__impl__6a9atx(63));
    var processingEntityOrDocType = tryExpect($this.x2e_1, _Char___init__impl__6a9atx(33));
    if (processingEntityOrDocType) {
      sb.jc(_Char___init__impl__6a9atx(33));
      $l$loop: while (!get_eof($this.x2e_1)) {
        var c = $this.x2e_1.jz();
        if (c === _Char___init__impl__6a9atx(62) || isWhitespaceFast(c) || c === _Char___init__impl__6a9atx(47))
          break $l$loop;
        sb.jc($this.x2e_1.kz());
        if (startsWith(sb, '!--')) {
          sb.wh();
          $l$loop_0: while (!get_eof($this.x2e_1)) {
            sb.jc($this.x2e_1.kz());
            if (endsWith(sb, '-->')) {
              sb.yh(sb.a() - 3 | 0, sb.a());
              break $l$loop_0;
            }
          }
          var tmp_2 = $this$sequence.sm(new CommentTag(sb.toString()), $completion);
          if (tmp_2 === get_COROUTINE_SUSPENDED())
            tmp_2 = yield tmp_2;
          continue loop;
        }
        if (startsWith(sb, '![CDATA[')) {
          sb.wh();
          $l$loop_1: while (!get_eof($this.x2e_1)) {
            sb.jc($this.x2e_1.kz());
            if (endsWith(sb, ']]>')) {
              sb.yh(sb.a() - 3 | 0, sb.a());
              break $l$loop_1;
            }
          }
          // Inline function 'kotlin.also' call
          var this_0 = new Text(sb.toString());
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.io.serialization.xml.Stream.xmlSequence.<anonymous>.<anonymous>' call
          this_0.h2e_1 = true;
          var tmp_3 = $this$sequence.sm(this_0, $completion);
          if (tmp_3 === get_COROUTINE_SUSPENDED())
            tmp_3 = yield tmp_3;
          continue loop;
        }
      }
      sb.xh(0);
    }
    var close = tryExpect($this.x2e_1, _Char___init__impl__6a9atx(47)) || processingEntityOrDocType;
    skipSpaces($this.x2e_1);
    // Inline function 'kotlin.takeIf' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_4;
    // Inline function 'korlibs.io.serialization.xml.Stream.xmlSequence.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.text.isNotEmpty' call
    if (charSequenceLength(sb) > 0) {
      tmp_4 = sb;
    } else {
      tmp_4 = null;
    }
    var tmp43_safe_receiver = tmp_4;
    var tmp0_elvis_lhs = tmp43_safe_receiver == null ? null : tmp43_safe_receiver.toString();
    var tmp_5;
    if (tmp0_elvis_lhs == null) {
      var tmp44_safe_receiver = matchIdentifier($this.x2e_1, sb.wh());
      tmp_5 = tmp44_safe_receiver == null ? null : tmp44_safe_receiver.toString();
    } else {
      tmp_5 = tmp0_elvis_lhs;
    }
    var tmp1_elvis_lhs = tmp_5;
    var tmp_6;
    if (tmp1_elvis_lhs == null) {
      var message = "Couldn't match identifier after '<', offset=" + $this.x2e_1.lz() + ", near='" + $this.x2e_1.qz() + "'";
      throw IllegalStateException.m5(toString_0(message));
    } else {
      tmp_6 = tmp1_elvis_lhs;
    }
    var name = tmp_6;
    skipSpaces($this.x2e_1);
    // Inline function 'kotlin.collections.linkedMapOf' call
    var attributes = LinkedHashMap.vc();
    while (!($this.x2e_1.jz() === _Char___init__impl__6a9atx(63)) && !($this.x2e_1.jz() === _Char___init__impl__6a9atx(47)) && !($this.x2e_1.jz() === _Char___init__impl__6a9atx(62))) {
      var tmp45_safe_receiver = matchStringOrId($this.x2e_1, Stream_getInstance(), sb.wh());
      var tmp2_elvis_lhs = tmp45_safe_receiver == null ? null : tmp45_safe_receiver.toString();
      var tmp_7;
      if (tmp2_elvis_lhs == null) {
        throw IllegalArgumentException.l('Malformed document or unsupported xml construct around ~' + $this.x2e_1.qz() + "~ for name '" + name + "'");
      } else {
        tmp_7 = tmp2_elvis_lhs;
      }
      var key = tmp_7;
      skipSpaces($this.x2e_1);
      if (tryExpect($this.x2e_1, _Char___init__impl__6a9atx(61))) {
        skipSpaces($this.x2e_1);
        var tmp46_safe_receiver = matchStringOrId($this.x2e_1, Stream_getInstance(), sb.wh());
        var argsQuote = tmp46_safe_receiver == null ? null : tmp46_safe_receiver.toString();
        // Inline function 'kotlin.collections.set' call
        var tmp_8;
        if (!(argsQuote == null) && !(startsWith_0(argsQuote, "'") || startsWith_0(argsQuote, '"'))) {
          tmp_8 = argsQuote;
        } else if (!(argsQuote == null)) {
          var tmp_9 = Entities_getInstance();
          // Inline function 'kotlin.text.substring' call
          var endIndex = argsQuote.length - 1 | 0;
          // Inline function 'kotlin.js.asDynamic' call
          var tmp$ret$13 = argsQuote.substring(1, endIndex);
          tmp_8 = tmp_9.e2f(tmp$ret$13);
        } else {
          tmp_8 = Entities_getInstance().e2f(ensureNotNull(matchIdentifier($this.x2e_1, sb.wh())).toString());
        }
        var value = tmp_8;
        attributes.r3(key, value);
      } else {
        // Inline function 'kotlin.collections.set' call
        attributes.r3(key, key);
      }
      skipSpaces($this.x2e_1);
    }
    var openclose = tryExpect($this.x2e_1, _Char___init__impl__6a9atx(47));
    var processingInstructionEnd = tryExpect($this.x2e_1, _Char___init__impl__6a9atx(63));
    skipExpect($this.x2e_1, _Char___init__impl__6a9atx(62));
    var tmp_10;
    if (!$this.z2e_1 && contains(name, _Char___init__impl__6a9atx(58))) {
      tmp_10 = substringAfter(name, _Char___init__impl__6a9atx(58));
    } else {
      tmp_10 = name;
    }
    var elementName = tmp_10;
    var tmp_11 = $this$sequence.sm(processingInstruction || processingEntityOrDocType ? new ProcessingInstructionTag(elementName, attributes) : openclose ? new OpenCloseTag(elementName, attributes) : close ? new CloseTag(elementName) : new OpenTag(elementName, attributes), $completion);
    if (tmp_11 === get_COROUTINE_SUSPENDED())
      tmp_11 = yield tmp_11;
  }
  return Unit_instance;
}
function matchStringOrId(_this__u8e3s4, $this, out) {
  var tmp0_elvis_lhs = matchSingleOrDoubleQuoteString(_this__u8e3s4, out);
  return tmp0_elvis_lhs == null ? matchIdentifier(_this__u8e3s4, out) : tmp0_elvis_lhs;
}
function Xml$Stream$xmlSequence$slambda_0($r, $collapseSpaces, $processNamespaces) {
  var i = new Xml$Stream$xmlSequence$slambda($r, $collapseSpaces, $processNamespaces);
  var l = function ($this$sequence, $completion) {
    return i.f2f($this$sequence, $completion);
  };
  l.$arity = 1;
  return l;
}
var Companion_instance_2;
function Companion_getInstance_1() {
  return Companion_instance_2;
}
var Entities_instance;
function Entities_getInstance() {
  if (Entities_instance === VOID)
    new Entities();
  return Entities_instance;
}
var Stream_instance;
function Stream_getInstance() {
  if (Stream_instance === VOID)
    new Stream();
  return Stream_instance;
}
function Xml$_get_text_$lambda_nsh6o(it) {
  return it.o2f();
}
function Xml$_get_outerXml_$lambda_n5rc6n(it) {
  return it.p2f();
}
function Type_NODE_getInstance() {
  Type_initEntries();
  return Type_NODE_instance;
}
function Type_TEXT_getInstance() {
  Type_initEntries();
  return Type_TEXT_instance;
}
function Type_COMMENT_getInstance() {
  Type_initEntries();
  return Type_COMMENT_instance;
}
function Xml_0(str, collapseSpaces, processNamespaces) {
  collapseSpaces = collapseSpaces === VOID ? true : collapseSpaces;
  processNamespaces = processNamespaces === VOID ? false : processNamespaces;
  return Companion_instance_2.g2f(str, collapseSpaces, processNamespaces);
}
function get_isText(_this__u8e3s4) {
  return _this__u8e3s4.m2e_1.equals(Type_TEXT_getInstance());
}
function get_isComment(_this__u8e3s4) {
  return _this__u8e3s4.m2e_1.equals(Type_COMMENT_getInstance());
}
function get_isNode(_this__u8e3s4) {
  return _this__u8e3s4.m2e_1.equals(Type_NODE_getInstance());
}
function toCaseInsensitiveMap(_this__u8e3s4) {
  // Inline function 'kotlin.also' call
  var this_0 = CaseInsensitiveStringMap.l2g();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.serialization.xml.toCaseInsensitiveMap.<anonymous>' call
  this_0.m2g(_this__u8e3s4);
  return this_0;
}
function flip(_this__u8e3s4) {
  // Inline function 'kotlin.collections.map' call
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(_this__u8e3s4.j1());
  // Inline function 'kotlin.collections.iterator' call
  var tmp0_iterator = _this__u8e3s4.z1().f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'korlibs.io.serialization.xml.flip.<anonymous>' call
    var tmp$ret$1 = new Pair(item.b2(), item.a2());
    destination.f(tmp$ret$1);
  }
  return toMap(destination);
}
function get(_this__u8e3s4, name) {
  return children(_this__u8e3s4, name);
}
function children(_this__u8e3s4, name) {
  // Inline function 'kotlin.collections.flatMap' call
  // Inline function 'kotlin.collections.flatMapTo' call
  var destination = ArrayList.m1();
  var tmp0_iterator = _this__u8e3s4.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'korlibs.io.serialization.xml.children.<anonymous>' call
    var list = element.t2f(name);
    addAll(destination, list);
  }
  return destination;
}
function htmlspecialchars(_this__u8e3s4) {
  // Inline function 'kotlin.text.buildString' call
  var capacity = _this__u8e3s4.length + 16 | 0;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.nc(capacity);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.util.htmlspecialchars.<anonymous>' call
  var inductionVariable = 0;
  var last = _this__u8e3s4.length;
  while (inductionVariable < last) {
    var it = charSequenceGet(_this__u8e3s4, inductionVariable);
    inductionVariable = inductionVariable + 1 | 0;
    if (it === _Char___init__impl__6a9atx(34))
      this_0.ic('&quot;');
    else if (it === _Char___init__impl__6a9atx(39))
      this_0.ic('&apos;');
    else if (it === _Char___init__impl__6a9atx(60))
      this_0.ic('&lt;');
    else if (it === _Char___init__impl__6a9atx(62))
      this_0.ic('&gt;');
    else if (it === _Char___init__impl__6a9atx(38))
      this_0.ic('&amp;');
    else
      this_0.jc(it);
  }
  return this_0.toString();
}
//region block: post-declaration
initMetadataForClass(Json, 'Json', Json);
initMetadataForCompanion(Companion);
protoOf(MiniNumberArrayList).h13 = getAt;
protoOf(MiniNumberArrayList).h3 = listIterator;
protoOf(MiniNumberArrayList).p1 = listIterator_0;
protoOf(MiniNumberArrayList).n1 = subList;
protoOf(MiniNumberArrayList).k13 = contains_0;
protoOf(MiniNumberArrayList).l13 = containsAll;
protoOf(MiniNumberArrayList).a1 = isEmpty;
protoOf(MiniNumberArrayList).f1 = iterator;
initMetadataForClass(MiniNumberArrayList, 'MiniNumberArrayList', MiniNumberArrayList, VOID, [DoubleList]);
initMetadataForClass(Xml$Companion$parse$Level, 'Level');
initMetadataForCompanion(Companion_0);
initMetadataForClass(Element, 'Element');
initMetadataForClass(ProcessingInstructionTag, 'ProcessingInstructionTag');
initMetadataForClass(OpenCloseTag, 'OpenCloseTag');
initMetadataForClass(OpenTag, 'OpenTag');
initMetadataForClass(CommentTag, 'CommentTag');
initMetadataForClass(CloseTag, 'CloseTag');
initMetadataForClass(Text, 'Text');
initMetadataForLambda(Xml$Stream$xmlSequence$slambda, VOID, VOID, [1]);
initMetadataForCompanion(Companion_1);
initMetadataForClass(Type, 'Type');
initMetadataForClass(Literals, 'Literals');
initMetadataForObject(Entities, 'Entities');
initMetadataForObject(Stream, 'Stream');
initMetadataForClass(Xml, 'Xml');
initMetadataForClass(CaseInsensitiveStringMap, 'CaseInsensitiveStringMap', CaseInsensitiveStringMap.l2g, VOID, [KtMutableMap]);
//endregion
//region block: init
Companion_instance_1 = new Companion_0();
Companion_instance_2 = new Companion_1();
//endregion
//region block: exports
export {
  Companion_getInstance as Companion_getInstance1ijxhf2kvgag6,
  Xml_0 as Xmlepssdknidwxh,
  get as get12f8xnsftqcwn,
  get_isComment as get_isComment2ks3g310vik1w,
  get_isNode as get_isNode17sq12d5k4oq6,
  get_isText as get_isText26l7ptq9ktzap,
  htmlspecialchars as htmlspecialcharsooxhmw9ymkma,
};
//endregion
