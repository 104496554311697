import {
  createThis2j2avj17cvnv2 as createThis,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  replaceqbix900hl8kl as replace,
  VOID7hggqo3abtya as VOID,
  indexOfwa4w6635jewi as indexOf,
  ArrayList3it5z8td81qkl as ArrayList,
  split2bvyvnrlcifjv as split,
  Unit_instance104q5opgivhr8 as Unit_instance,
  joinToString1cxrrlmo0chqs as joinToString,
  startsWith26w8qjqapeeq6 as startsWith,
  substringAfterLast3r0t0my8cpqhk as substringAfterLast,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  charArrayOf27f4r3dozbrk1 as charArrayOf,
  trimEndvvzjdhan75g as trimEnd,
  trim6jpmwojjgb3l as trim,
  get_lastIndexld83bqhfgcdd as get_lastIndex,
  lastIndexOfpmd3ei5son2n as lastIndexOf,
  split3d3yeauc4rm2n as split_0,
  substringBefore3n7kj60w69hju as substringBefore,
  contains2el4s70rdq4ld as contains,
  substringBeforeLastqh7oeuvefdek as substringBeforeLast,
  Enum3alwj03lh1n41 as Enum,
  Long2qws0ah9gnpki as Long,
  toString30pk9tzaqopn as toString,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  hashCodeq5arwsb9dgti as hashCode,
  equals2au1ep9vhcato as equals,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  get6d5x931vk0s as get,
  protoOf180f3jzyo7rfj as protoOf,
  fold36i9psb7d5v48 as fold,
  minusKeyyqanvso9aovh as minusKey,
  plusolev77jfy5r9 as plus,
  Element2gr7ezmxqaln7 as Element,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  printStackTrace18lnx7a39cni as printStackTrace,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  copyToArray2j022khrow2yi as copyToArray,
  isInterface3d6p8outrmvmk as isInterface,
  toLongw1zpgk99d84b as toLong,
  UnsupportedOperationException2tkumpmhredt3 as UnsupportedOperationException,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  toString1pkumu07cwy4m as toString_0,
  boxApply1qmzdb3dh90hg as boxApply,
  arrayConcat3qsij7vh68m69 as arrayConcat,
  toList383f556t1dixk as toList,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  startsWith1bgirhbedtv2y as startsWith_0,
  Iterable1y3qizuf89iou as Iterable,
  to2cs3ny02qtbcb as to,
  mapOf2zpbbmyqk8xpf as mapOf,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  isByteArray4nnzfn1x4o3w as isByteArray,
  toString26mv3gzfo7fn as toString_1,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Companion_getInstance3moaeo8p2fvjj as Companion_getInstance,
  DateTime__toString_impl_cnuejeub59104j2jjn as DateTime__toString_impl_cnueje,
  DateTime2gld4ho36azq9 as DateTime,
  DateTime__hashCode_impl_yf0bc92p1oa6fp4l38p as DateTime__hashCode_impl_yf0bc9,
} from './korlibs-korlibs-time-core.mjs';
import {
  CoroutineScopelux7s7zphw7e as CoroutineScope,
  asyncz02dsa2nb2zt as async,
  FlowCollector26clgpmzihvke as FlowCollector,
  CoroutineScopefcb5f5dwqcas as CoroutineScope_0,
  toListgag8q7o845sq as toList_0,
  flow3tazazxj2t7g4 as flow,
} from './kotlinx-coroutines-core.mjs';
import {
  readBytesUpTo9uslmwqcrcrn as readBytesUpTo,
  copyTo28cahkq2g6hf3 as copyTo,
  openAsync37anb1e84urmo as openAsync,
  openSync2m3qn99fqytu0 as openSync,
  openAsync3viasxxrmd5gg as openAsync_0,
  toAsyncc87ncfb6nti7 as toAsync,
  SyncStreamce10l9wq5tt7 as SyncStream,
  toAsyncStream3ow20wz4trxzb as toAsyncStream,
  AsyncStreamBaseze8sfl15nwf0 as AsyncStreamBase,
  MemorySyncStreamw5bwb9yy0cam as MemorySyncStream,
} from './korlibs-korlibs-io-stream.mjs';
import {
  get_portableSimpleName6o7s8nc63i85 as get_portableSimpleName,
  FileNotFoundException2tz8ve019qipn as FileNotFoundException,
} from './korlibs-korlibs-platform.mjs';
import {
  toString2x583ovdqfjci as toString_2,
  toByteArraybbc3ng3wgt1d as toByteArray,
  get_UTF82geyfhi1l22xl as get_UTF8,
} from './korlibs-korlibs-string.mjs';
import { Mixin3lmkm5hum2vdj as Mixin } from './korlibs-korlibs-datastructure-core.mjs';
import { Signal3nnez3346azzf as Signal } from './korlibs-korlibs-concurrent.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class FinalVfsFile {
  static n1z(file) {
    var $this = createThis(this);
    $this.m1z_1 = file;
    return $this;
  }
  static o1z(vfs, path) {
    return this.n1z(vfs.bj(path));
  }
  p1z() {
    return this.m1z_1.s1z_1;
  }
  v1z() {
    return this.m1z_1.t1z_1;
  }
  toString() {
    return 'FinalVfsFile(file=' + this.m1z_1.toString() + ')';
  }
  hashCode() {
    return this.m1z_1.hashCode();
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FinalVfsFile))
      return false;
    var tmp0_other_with_cast = other instanceof FinalVfsFile ? other : THROW_CCE();
    if (!this.m1z_1.equals(tmp0_other_with_cast.m1z_1))
      return false;
    return true;
  }
}
class VfsNamed {
  constructor(pathInfo) {
    this.y1z_1 = pathInfo;
  }
  x1z() {
    return this.y1z_1;
  }
}
class VfsOpenMode extends Enum {
  constructor(name, ordinal, cmode, write, createIfNotExists, truncate, read, append, createNew) {
    createIfNotExists = createIfNotExists === VOID ? false : createIfNotExists;
    truncate = truncate === VOID ? false : truncate;
    read = read === VOID ? true : read;
    append = append === VOID ? false : append;
    createNew = createNew === VOID ? false : createNew;
    super(name, ordinal);
    this.b20_1 = cmode;
    this.c20_1 = write;
    this.d20_1 = createIfNotExists;
    this.e20_1 = truncate;
    this.f20_1 = read;
    this.g20_1 = append;
    this.h20_1 = createNew;
  }
}
class VfsStat {
  constructor(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id, exception) {
    device = device === VOID ? new Long(-1, -1) : device;
    inode = inode === VOID ? new Long(-1, -1) : inode;
    mode = mode === VOID ? 511 : mode;
    owner = owner === VOID ? 'nobody' : owner;
    group = group === VOID ? 'nobody' : group;
    createTime = createTime === VOID ? Companion_getInstance().c1w_1 : createTime;
    modifiedTime = modifiedTime === VOID ? createTime : modifiedTime;
    lastAccessTime = lastAccessTime === VOID ? modifiedTime : lastAccessTime;
    extraInfo = extraInfo === VOID ? null : extraInfo;
    kind = kind === VOID ? null : kind;
    id = id === VOID ? null : id;
    exception = exception === VOID ? null : exception;
    this.i20_1 = file;
    this.j20_1 = exists;
    this.k20_1 = isDirectory;
    this.l20_1 = size;
    this.m20_1 = device;
    this.n20_1 = inode;
    this.o20_1 = mode;
    this.p20_1 = owner;
    this.q20_1 = group;
    this.r20_1 = createTime;
    this.s20_1 = modifiedTime;
    this.t20_1 = lastAccessTime;
    this.u20_1 = extraInfo;
    this.v20_1 = kind;
    this.w20_1 = id;
    this.x20_1 = exception;
  }
  x1z() {
    return this.i20_1.x1z();
  }
  y20() {
    // Inline function 'kotlin.also' call
    var this_0 = this.i20_1.z20();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.VfsStat.<get-enrichedFile>.<anonymous>' call
    this_0.u1z_1 = this;
    return this_0;
  }
  a21(showFile) {
    // Inline function 'kotlin.also' call
    var this_0 = ArrayList.w(16);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.VfsStat.toString.<anonymous>' call
    if (showFile)
      this_0.f('file=' + this.i20_1.toString());
    else
      this_0.f('file=' + this.i20_1.b21());
    this_0.f('exists=' + this.j20_1);
    this_0.f('isDirectory=' + this.k20_1);
    this_0.f('size=' + this.l20_1.toString());
    this_0.f('device=' + this.m20_1.toString());
    this_0.f('inode=' + this.n20_1.toString());
    this_0.f('mode=' + this.o20_1);
    this_0.f('owner=' + this.p20_1);
    this_0.f('group=' + this.q20_1);
    this_0.f('createTime=' + DateTime__toString_impl_cnueje(this.r20_1));
    this_0.f('modifiedTime=' + DateTime__toString_impl_cnueje(this.s20_1));
    this_0.f('lastAccessTime=' + DateTime__toString_impl_cnueje(this.t20_1));
    this_0.f('extraInfo=' + toString(this.u20_1));
    if (!(this.v20_1 == null)) {
      this_0.f('kind=' + toString(this.v20_1));
    }
    this_0.f('id=' + this.w20_1);
    return 'VfsStat(' + joinToString(this_0, ', ') + ')';
  }
  toString() {
    return this.a21(true);
  }
  c21(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id, exception) {
    return new VfsStat(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id, exception);
  }
  d21(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id, exception, $super) {
    file = file === VOID ? this.i20_1 : file;
    exists = exists === VOID ? this.j20_1 : exists;
    isDirectory = isDirectory === VOID ? this.k20_1 : isDirectory;
    size = size === VOID ? this.l20_1 : size;
    device = device === VOID ? this.m20_1 : device;
    inode = inode === VOID ? this.n20_1 : inode;
    mode = mode === VOID ? this.o20_1 : mode;
    owner = owner === VOID ? this.p20_1 : owner;
    group = group === VOID ? this.q20_1 : group;
    createTime = createTime === VOID ? this.r20_1 : createTime;
    modifiedTime = modifiedTime === VOID ? this.s20_1 : modifiedTime;
    lastAccessTime = lastAccessTime === VOID ? this.t20_1 : lastAccessTime;
    extraInfo = extraInfo === VOID ? this.u20_1 : extraInfo;
    kind = kind === VOID ? this.v20_1 : kind;
    id = id === VOID ? this.w20_1 : id;
    exception = exception === VOID ? this.x20_1 : exception;
    return $super === VOID ? this.c21(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id, exception) : $super.c21.call(this, file, exists, isDirectory, size, device, inode, mode, owner, group, new DateTime(createTime), new DateTime(modifiedTime), new DateTime(lastAccessTime), extraInfo, kind, id, exception);
  }
  hashCode() {
    var result = this.i20_1.hashCode();
    result = imul(result, 31) + getBooleanHashCode(this.j20_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.k20_1) | 0;
    result = imul(result, 31) + this.l20_1.hashCode() | 0;
    result = imul(result, 31) + this.m20_1.hashCode() | 0;
    result = imul(result, 31) + this.n20_1.hashCode() | 0;
    result = imul(result, 31) + this.o20_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.p20_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.q20_1) | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.r20_1) | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.s20_1) | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.t20_1) | 0;
    result = imul(result, 31) + (this.u20_1 == null ? 0 : hashCode(this.u20_1)) | 0;
    result = imul(result, 31) + (this.v20_1 == null ? 0 : hashCode(this.v20_1)) | 0;
    result = imul(result, 31) + (this.w20_1 == null ? 0 : getStringHashCode(this.w20_1)) | 0;
    result = imul(result, 31) + (this.x20_1 == null ? 0 : hashCode(this.x20_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof VfsStat))
      return false;
    var tmp0_other_with_cast = other instanceof VfsStat ? other : THROW_CCE();
    if (!this.i20_1.equals(tmp0_other_with_cast.i20_1))
      return false;
    if (!(this.j20_1 === tmp0_other_with_cast.j20_1))
      return false;
    if (!(this.k20_1 === tmp0_other_with_cast.k20_1))
      return false;
    if (!this.l20_1.equals(tmp0_other_with_cast.l20_1))
      return false;
    if (!this.m20_1.equals(tmp0_other_with_cast.m20_1))
      return false;
    if (!this.n20_1.equals(tmp0_other_with_cast.n20_1))
      return false;
    if (!(this.o20_1 === tmp0_other_with_cast.o20_1))
      return false;
    if (!(this.p20_1 === tmp0_other_with_cast.p20_1))
      return false;
    if (!(this.q20_1 === tmp0_other_with_cast.q20_1))
      return false;
    if (!equals(this.r20_1, tmp0_other_with_cast.r20_1))
      return false;
    if (!equals(this.s20_1, tmp0_other_with_cast.s20_1))
      return false;
    if (!equals(this.t20_1, tmp0_other_with_cast.t20_1))
      return false;
    if (!equals(this.u20_1, tmp0_other_with_cast.u20_1))
      return false;
    if (!equals(this.v20_1, tmp0_other_with_cast.v20_1))
      return false;
    if (!(this.w20_1 == tmp0_other_with_cast.w20_1))
      return false;
    if (!equals(this.x20_1, tmp0_other_with_cast.x20_1))
      return false;
    return true;
  }
}
class Companion {}
class VfsCachedStatContext {
  constructor(stat) {
    this.e21_1 = stat;
  }
  a2() {
    return Companion_instance;
  }
}
class Companion_0 {
  f21() {
    return Standard_STRING_getInstance();
  }
}
class Standard extends Enum {}
class Vfs$Proxy$listFlow$slambda$slambda {
  constructor($this_flow, this$0) {
    this.j21_1 = $this_flow;
    this.k21_1 = this$0;
  }
  r21(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_0(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.r21(p1 instanceof VfsFile ? p1 : THROW_CCE(), $completion);
  }
}
class sam$kotlinx_coroutines_flow_FlowCollector$0 {
  constructor(function_0) {
    this.x21_1 = function_0;
  }
  g1l(value, $completion) {
    return this.x21_1(value, $completion);
  }
}
class Vfs$Proxy$initOnce$slambda {
  constructor(this$0) {
    this.g21_1 = this$0;
  }
  o1s($this$async, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$async, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class Vfs$Proxy$listFlow$slambda {
  constructor(this$0, $path) {
    this.m21_1 = this$0;
    this.n21_1 = $path;
  }
  y21($this$flow, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_1(this, $this$flow, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.y21((!(p1 == null) ? isInterface(p1, FlowCollector) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class Kind extends Enum {}
class Vfs {
  constructor($box) {
    boxApply(this, $box);
  }
  b21() {
    return '';
  }
  e22(path) {
    return _PathInfo___get_fullPath__impl__21fx6x(lightCombine(get_pathInfo(this.b21()), get_pathInfo(path)));
  }
  f22() {
    return new VfsFile(this, '');
  }
  bj(path) {
    return this.f22().bj(path);
  }
  w21(path) {
    return this.f22().bj(path);
  }
  f1q($completion) {
    return Unit_instance;
  }
  g22(path, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, id, cache) {
    // Inline function 'kotlin.also' call
    var this_0 = new VfsStat(this.w21(path), true, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, VOID, id);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.Vfs.createExistsStat.<anonymous>' call
    if (cache)
      this_0.i20_1.u1z_1 = this_0;
    return this_0;
  }
  h22(path, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, id, cache, $super) {
    device = device === VOID ? new Long(-1, -1) : device;
    inode = inode === VOID ? new Long(-1, -1) : inode;
    mode = mode === VOID ? 511 : mode;
    owner = owner === VOID ? 'nobody' : owner;
    group = group === VOID ? 'nobody' : group;
    createTime = createTime === VOID ? Companion_getInstance().c1w_1 : createTime;
    modifiedTime = modifiedTime === VOID ? Companion_getInstance().c1w_1 : modifiedTime;
    lastAccessTime = lastAccessTime === VOID ? modifiedTime : lastAccessTime;
    extraInfo = extraInfo === VOID ? null : extraInfo;
    id = id === VOID ? null : id;
    cache = cache === VOID ? false : cache;
    return $super === VOID ? this.g22(path, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, id, cache) : $super.g22.call(this, path, isDirectory, size, device, inode, mode, owner, group, new DateTime(createTime), new DateTime(modifiedTime), new DateTime(lastAccessTime), extraInfo, id, cache);
  }
  i22(path, extraInfo, cache, exception) {
    return new VfsStat(this.w21(path), false, false, new Long(0, 0), new Long(-1, -1), new Long(-1, -1), 511, 'nobody', 'nobody', Companion_getInstance().c1w_1, Companion_getInstance().c1w_1, Companion_getInstance().c1w_1, extraInfo, VOID, VOID, exception);
  }
  j22(path, extraInfo, cache, exception, $super) {
    extraInfo = extraInfo === VOID ? null : extraInfo;
    cache = cache === VOID ? false : cache;
    exception = exception === VOID ? null : exception;
    return $super === VOID ? this.i22(path, extraInfo, cache, exception) : $super.i22.call(this, path, extraInfo, cache, exception);
  }
  z21(path, mode, $completion) {
    unsupported(this);
  }
  b22(path, range, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readRange__lxd64t_0(this, path, range, $completion), $completion);
  }
  c22(path, content, attributes, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_put__cb93wb_0(this, path, content, attributes, $completion), $completion);
  }
  k22(path, content, attributes, $completion) {
    return this.c22(path, openAsync(content), attributes, $completion);
  }
  d22(path, $completion) {
    return this.j22(path);
  }
  l22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_listSimple__59lt3s(this, path, $completion), $completion);
  }
  a22(path, $completion) {
    unsupported(this);
  }
  w1z(path, $completion) {
    return FinalVfsFile.o1z(this, path);
  }
  toString() {
    return get_portableSimpleName(getKClassFromExpression(this));
  }
}
class Proxy extends Vfs {
  constructor() {
    super();
    this.h21_1 = null;
  }
  l21(_this__u8e3s4, $completion) {
    return this.w21(_this__u8e3s4.t1z_1);
  }
  w1z(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_getUnderlyingUnscapedFile__y1cdv2(this, path, $completion), $completion);
  }
  i21($completion) {
  }
  o21($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_initOnce__uuyf07(this, $completion), $completion);
  }
  z21(path, mode, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_open__qjxcea(this, path, mode, $completion), $completion);
  }
  b22(path, range, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readRange__lxd64t(this, path, range, $completion), $completion);
  }
  c22(path, content, attributes, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_put__cb93wb(this, path, content, attributes, $completion), $completion);
  }
  d22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_stat__qhnowc(this, path, $completion), $completion);
  }
  a22(path, $completion) {
    return flow(Vfs$Proxy$listFlow$slambda_0(this, path));
  }
}
class FileEvent {
  constructor(kind, file, other) {
    other = other === VOID ? null : other;
    this.m22_1 = kind;
    this.n22_1 = file;
    this.o22_1 = other;
  }
  toString() {
    return !(this.o22_1 == null) ? this.m22_1.toString() + '(' + this.n22_1.toString() + ', ' + toString(this.o22_1) + ')' : this.m22_1.toString() + '(' + this.n22_1.toString() + ')';
  }
  hashCode() {
    var result = this.m22_1.hashCode();
    result = imul(result, 31) + this.n22_1.hashCode() | 0;
    result = imul(result, 31) + (this.o22_1 == null ? 0 : this.o22_1.hashCode()) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FileEvent))
      return false;
    var tmp0_other_with_cast = other instanceof FileEvent ? other : THROW_CCE();
    if (!this.m22_1.equals(tmp0_other_with_cast.m22_1))
      return false;
    if (!this.n22_1.equals(tmp0_other_with_cast.n22_1))
      return false;
    if (!equals(this.o22_1, tmp0_other_with_cast.o22_1))
      return false;
    return true;
  }
}
class VfsFile extends VfsNamed {
  constructor(vfs, path) {
    super(get_pathInfo(path));
    this.r1z_1 = new Mixin();
    this.s1z_1 = vfs;
    this.t1z_1 = path;
    this.u1z_1 = null;
  }
  n13(_set____db54di) {
    this.r1z_1.n13(_set____db54di);
  }
  o13() {
    return this.r1z_1.o13();
  }
  w15() {
    return new VfsFile(this.s1z_1, get_folder_0(this));
  }
  f22() {
    return this.s1z_1.f22();
  }
  b21() {
    return this.s1z_1.e22(this.t1z_1);
  }
  bj(path) {
    return new VfsFile(this.s1z_1, _PathInfo___get_fullPath__impl__21fx6x(combine(get_pathInfo(this.t1z_1), get_pathInfo(path))));
  }
  u21(content, attributes, $completion) {
    return this.s1z_1.c22(this.t1z_1, content, toList(attributes), $completion);
  }
  q22(data, attributes, $completion) {
    return this.s1z_1.k22(this.t1z_1, data, toList(attributes), $completion);
  }
  s21(mode, $completion) {
    return this.s1z_1.z21(this.t1z_1, mode, $completion);
  }
  t22($completion) {
    return this.s21(VfsOpenMode_READ_getInstance(), $completion);
  }
  t21(range, $completion) {
    return this.s1z_1.b22(this.t1z_1, range, $completion);
  }
  u22(range, $completion) {
    return this.s1z_1.b22(this.t1z_1, toLongRange(range), $completion);
  }
  v22($completion) {
    return this.s1z_1.b22(this.t1z_1, get_LONG_ZERO_TO_MAX_RANGE(), $completion);
  }
  p22($completion) {
    return this.v22($completion);
  }
  w22($completion) {
    return this.v22($completion);
  }
  x22(charset, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readString__6uahun(this, charset, $completion), $completion);
  }
  y22(charset, $completion, $super) {
    charset = charset === VOID ? get_UTF8() : charset;
    return $super === VOID ? this.x22(charset, $completion) : $super.x22.call(this, charset, $completion);
  }
  z22(data, attributes, charset, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_writeString__8z2lpg(this, data, attributes, charset, $completion), $completion);
  }
  a23(data, attributes, charset, $completion, $super) {
    charset = charset === VOID ? get_UTF8() : charset;
    return $super === VOID ? this.z22(data, attributes, charset, $completion) : $super.z22.call(this, data, attributes, charset, $completion);
  }
  v21($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_stat__qhnowc_0(this, $completion), $completion);
  }
  r22($completion) {
    return this.s1z_1.l22(this.t1z_1, $completion);
  }
  q21($completion) {
    return this.s1z_1.a22(this.t1z_1, $completion);
  }
  s22(filter, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_listRecursiveSimple__p8dyqa(this, filter, $completion), $completion);
  }
  b23(filter, $completion, $super) {
    var tmp;
    if (filter === VOID) {
      tmp = VfsFile$listRecursiveSimple$lambda;
    } else {
      tmp = filter;
    }
    filter = tmp;
    return $super === VOID ? this.s22(filter, $completion) : $super.s22.call(this, filter, $completion);
  }
  c23() {
    return Companion_instance_1.d23(this);
  }
  toString() {
    return this.s1z_1.toString() + '[' + this.t1z_1 + ']';
  }
  e23(vfs, path) {
    return new VfsFile(vfs, path);
  }
  z20(vfs, path, $super) {
    vfs = vfs === VOID ? this.s1z_1 : vfs;
    path = path === VOID ? this.t1z_1 : path;
    return $super === VOID ? this.e23(vfs, path) : $super.e23.call(this, vfs, path);
  }
  hashCode() {
    var result = hashCode(this.s1z_1);
    result = imul(result, 31) + getStringHashCode(this.t1z_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof VfsFile))
      return false;
    var tmp0_other_with_cast = other instanceof VfsFile ? other : THROW_CCE();
    if (!equals(this.s1z_1, tmp0_other_with_cast.s1z_1))
      return false;
    if (!(this.t1z_1 === tmp0_other_with_cast.t1z_1))
      return false;
    return true;
  }
}
class Companion_1 {
  d23(jailRoot) {
    return (new JailVfs(jailRoot, Unit_instance)).f22();
  }
}
class JailVfs extends Proxy {
  constructor(jailRoot, dummy) {
    super();
    this.g23_1 = jailRoot;
    this.h23_1 = normalize(this.g23_1.x1z());
  }
  p21(path, $completion) {
    return this.g23_1.bj(trim(normalize(get_pathInfo(path)), charArrayOf([_Char___init__impl__6a9atx(47)])));
  }
  l21(_this__u8e3s4, $completion) {
    var outPath = normalize(get_pathInfo(_this__u8e3s4.t1z_1));
    if (!startsWith(outPath, this.h23_1))
      throw UnsupportedOperationException.ua('Jail not base root : ' + _this__u8e3s4.t1z_1 + ' | ' + this.h23_1);
    // Inline function 'kotlin.text.substring' call
    var startIndex = this.h23_1.length;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = outPath.substring(startIndex);
    return this.w21(tmp$ret$1);
  }
  b21() {
    return this.g23_1.b21();
  }
  toString() {
    return 'JailVfs(' + this.g23_1.toString() + ')';
  }
}
class LocalVfs extends Vfs {}
class Node {
  constructor($outer, name, isDirectory, parent) {
    isDirectory = isDirectory === VOID ? false : isDirectory;
    parent = parent === VOID ? null : parent;
    this.s23_1 = $outer;
    this.i23_1 = name;
    this.j23_1 = isDirectory;
    var tmp = this;
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.k23_1 = this.i23_1.toLowerCase();
    this.l23_1 = null;
    this.t23(parent);
    this.m23_1 = null;
    this.n23_1 = null;
    var tmp_0 = this;
    // Inline function 'kotlin.collections.linkedMapOf' call
    tmp_0.o23_1 = LinkedHashMap.vc();
    var tmp_1 = this;
    // Inline function 'kotlin.collections.linkedMapOf' call
    tmp_1.p23_1 = LinkedHashMap.vc();
    this.q23_1 = null;
    this.r23_1 = null;
  }
  f1() {
    return this.o23_1.m3().f1();
  }
  t23(value) {
    if (!(this.l23_1 == null)) {
      ensureNotNull(this.l23_1).o23_1.s3(this.i23_1);
      ensureNotNull(this.l23_1).p23_1.s3(this.k23_1);
    }
    this.l23_1 = value;
    var tmp7_safe_receiver = this.l23_1;
    var tmp8_safe_receiver = tmp7_safe_receiver == null ? null : tmp7_safe_receiver.o23_1;
    if (tmp8_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.collections.set' call
      var key = this.i23_1;
      tmp8_safe_receiver.r3(key, this);
    }
    var tmp9_safe_receiver = this.l23_1;
    var tmp10_safe_receiver = tmp9_safe_receiver == null ? null : tmp9_safe_receiver.p23_1;
    if (tmp10_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.collections.set' call
      var key_0 = this.k23_1;
      tmp10_safe_receiver.r3(key_0, this);
    }
  }
  f22() {
    var tmp11_safe_receiver = this.l23_1;
    var tmp0_elvis_lhs = tmp11_safe_receiver == null ? null : tmp11_safe_receiver.f22();
    return tmp0_elvis_lhs == null ? this : tmp0_elvis_lhs;
  }
  u23(name) {
    var tmp;
    switch (name) {
      case '':
      case '.':
        tmp = this;
        break;
      case '..':
        tmp = this.l23_1;
        break;
      default:
        var tmp_0;
        if (this.s23_1.v23_1) {
          tmp_0 = this.o23_1.k3(name);
        } else {
          // Inline function 'kotlin.text.lowercase' call
          // Inline function 'kotlin.js.asDynamic' call
          var tmp$ret$1 = name.toLowerCase();
          tmp_0 = this.p23_1.k3(tmp$ret$1);
        }

        tmp = tmp_0;
        break;
    }
    return tmp;
  }
  x23(name, isDirectory) {
    return new Node(this.s23_1, name, isDirectory, this);
  }
  bj(path) {
    return this.y23(path, false);
  }
  y23(path, createFolders) {
    var node = startsWith_0(path, _Char___init__impl__6a9atx(47)) ? this.f22() : this;
    // Inline function 'korlibs.io.file.std.fastForEach' call
    var this_0 = parts(get_pathInfo(path));
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.io.file.std.Node.access.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var part = this_0.i1(_unary__edvuaz);
      var child = node.u23(part);
      if (child == null && createFolders)
        child = node.x23(part, true);
      var tmp0_elvis_lhs = child;
      var tmp;
      if (tmp0_elvis_lhs == null) {
        throw FileNotFoundException.hx("Can't find '" + part + "' in " + path);
      } else {
        tmp = tmp0_elvis_lhs;
      }
      node = tmp;
    }
    return node;
  }
  z23(path, createFolders, $super) {
    createFolders = createFolders === VOID ? false : createFolders;
    return $super === VOID ? this.y23(path, createFolders) : $super.y23.call(this, path, createFolders);
  }
}
class MemoryNodeTree {
  constructor(caseSensitive) {
    caseSensitive = caseSensitive === VOID ? true : caseSensitive;
    this.v23_1 = caseSensitive;
    this.w23_1 = new Node(this, '', true);
  }
}
class NodeVfs$createStream$1 extends AsyncStreamBase {
  static j24($s, this$0, $vfsFile, $box) {
    if ($box === VOID)
      $box = {};
    $box.g24_1 = $s;
    $box.h24_1 = this$0;
    $box.i24_1 = $vfsFile;
    return this.v1s($box);
  }
  w1s(position, buffer, offset, len, $completion) {
    return this.g24_1.h1s(position, buffer, offset, len);
  }
  x1s(position, buffer, offset, len, $completion) {
    this.g24_1.i1s(position, buffer, offset, len);
    this.h24_1.b24_1.x1x(new FileEvent(Kind_MODIFIED_getInstance(), this.i24_1));
  }
  y1s($completion) {
    return this.g24_1.a();
  }
  f1q($completion) {
    this.g24_1.u6();
    return Unit_instance;
  }
}
class NodeVfs$listFlow$slambda {
  constructor(this$0, $path) {
    this.e24_1 = this$0;
    this.f24_1 = $path;
  }
  y21($this$flow, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_2(this, $this$flow, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.y21((!(p1 == null) ? isInterface(p1, FlowCollector) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class NodeVfs extends Vfs {
  constructor(caseSensitive) {
    caseSensitive = caseSensitive === VOID ? true : caseSensitive;
    super();
    this.a24_1 = caseSensitive;
    this.b24_1 = new Signal();
    this.c24_1 = new MemoryNodeTree(this.a24_1);
  }
  d24() {
    return this.c24_1.w23_1;
  }
  z21(path, mode, $completion) {
    var pathInfo = _PathInfo___init__impl__vpw96a(path);
    var folder = this.d24().z23(get_folder(pathInfo));
    var node = folder.u23(get_baseName(pathInfo));
    var vfsFile = this.bj(path);
    if (node == null && mode.d20_1) {
      node = folder.x23(get_baseName(pathInfo), false);
      node.q23_1 = createStream(this, MemorySyncStream().r1r_1, vfsFile);
    } else if (mode.e20_1) {
      var tmp21_safe_receiver = node;
      if (tmp21_safe_receiver == null)
        null;
      else {
        tmp21_safe_receiver.q23_1 = createStream(this, MemorySyncStream().r1r_1, vfsFile);
      }
    }
    var tmp22_safe_receiver = node;
    var tmp23_safe_receiver = tmp22_safe_receiver == null ? null : tmp22_safe_receiver.q23_1;
    var tmp0_elvis_lhs = tmp23_safe_receiver == null ? null : tmp23_safe_receiver.r1u();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw FileNotFoundException.hx(path);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  d22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_stat__qhnowc_1(this, path, $completion), $completion);
  }
  a22(path, $completion) {
    return flow(NodeVfs$listFlow$slambda_0(this, path));
  }
  toString() {
    return 'NodeVfs';
  }
}
class UrlVfs extends Vfs {
  constructor(url, dummy, failFromStatus) {
    failFromStatus = failFromStatus === VOID ? true : failFromStatus;
    super();
    this.k24_1 = url;
    this.l24_1 = failFromStatus;
    this.m24_1 = this.k24_1;
  }
  b21() {
    return this.m24_1;
  }
  n24(path) {
    var result = trim(this.k24_1, charArrayOf([_Char___init__impl__6a9atx(47)])) + toString_1(_Char___init__impl__6a9atx(47)) + trim(path, charArrayOf([_Char___init__impl__6a9atx(47)]));
    return result;
  }
}
//endregion
function getUnderlyingUnscapedFile(_this__u8e3s4, $completion) {
  return _this__u8e3s4.s1z_1.w1z(_this__u8e3s4.t1z_1, $completion);
}
function get_fullPathNormalized(_this__u8e3s4) {
  return replace(_PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4), _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(47));
}
function _PathInfo___init__impl__vpw96a(fullPath) {
  return fullPath;
}
function _PathInfo___get_fullPath__impl__21fx6x($this) {
  return $this;
}
function get_parent(_this__u8e3s4) {
  return _PathInfo___init__impl__vpw96a(get_folder(_this__u8e3s4));
}
function normalize(_this__u8e3s4, removeEndSlash) {
  removeEndSlash = removeEndSlash === VOID ? true : removeEndSlash;
  var path = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
  var schemeIndex = indexOf(path, ':');
  var tmp;
  if (schemeIndex >= 0) {
    var tmp_0;
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = path.substring(schemeIndex);
    if (startsWith(tmp$ret$1, '://')) {
      tmp_0 = 3;
    } else {
      tmp_0 = 1;
    }
    var take = tmp_0;
    // Inline function 'kotlin.text.substring' call
    var endIndex = schemeIndex + take | 0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp_1 = path.substring(0, endIndex);
    // Inline function 'kotlin.text.substring' call
    var startIndex = schemeIndex + take | 0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$5 = path.substring(startIndex);
    tmp = tmp_1 + normalize(get_pathInfo(tmp$ret$5), removeEndSlash);
  } else {
    var path2 = replace(path, _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(47));
    var out = ArrayList.m1();
    var path2PathLength;
    // Inline function 'korlibs.io.file.fastForEachWithIndex' call
    // Inline function 'kotlin.also' call
    var this_0 = split(path2, ['/']);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.normalize.<anonymous>' call
    path2PathLength = this_0.j1();
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.io.file.normalize.<anonymous>' call
      var index = n;
      var part = this_0.i1(n);
      switch (part) {
        case '':
          if (out.a1() || !removeEndSlash) {
            // Inline function 'kotlin.collections.plusAssign' call
            out.f('');
          }

          break;
        case '.':
          if (index === (path2PathLength - 1 | 0) && !removeEndSlash) {
            // Inline function 'kotlin.collections.plusAssign' call
            out.f('');
          }

          break;
        case '..':
          var tmp_2;
          // Inline function 'kotlin.collections.isNotEmpty' call

          if (!out.a1()) {
            tmp_2 = !(index === 1);
          } else {
            tmp_2 = false;
          }

          if (tmp_2) {
            out.q3(out.j1() - 1 | 0);
          }

          break;
        default:
          // Inline function 'kotlin.collections.plusAssign' call

          out.f(part);
          break;
      }
      n = n + 1 | 0;
    }
    tmp = joinToString(out, '/');
  }
  return tmp;
}
function get_pathInfo(_this__u8e3s4) {
  return _PathInfo___init__impl__vpw96a(_this__u8e3s4);
}
function get_baseName(_this__u8e3s4) {
  return substringAfterLast(get_fullPathNormalized(_this__u8e3s4), _Char___init__impl__6a9atx(47));
}
function get_baseName_0(_this__u8e3s4) {
  return get_baseName(_this__u8e3s4.x1z());
}
function get_folder(_this__u8e3s4) {
  // Inline function 'kotlin.text.substring' call
  var this_0 = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
  var tmp0_elvis_lhs = lastIndexOfOrNull(get_fullPathNormalized(_this__u8e3s4), _Char___init__impl__6a9atx(47));
  var endIndex = tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs;
  // Inline function 'kotlin.js.asDynamic' call
  return this_0.substring(0, endIndex);
}
function get_extensionLC(_this__u8e3s4) {
  // Inline function 'kotlin.text.lowercase' call
  // Inline function 'kotlin.js.asDynamic' call
  return get_extension(_this__u8e3s4).toLowerCase();
}
function get_extensionLC_0(_this__u8e3s4) {
  return get_extensionLC(_this__u8e3s4.x1z());
}
function get_folder_0(_this__u8e3s4) {
  return get_folder(_this__u8e3s4.x1z());
}
function combine(_this__u8e3s4, access) {
  var base = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
  var access_0 = _PathInfo___get_fullPath__impl__21fx6x(access);
  return get_pathInfo(isAbsolute(get_pathInfo(access_0)) ? normalize(get_pathInfo(access_0)) : normalize(get_pathInfo(base + '/' + access_0)));
}
function get_fullName(_this__u8e3s4) {
  return _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4.x1z());
}
function lightCombine(_this__u8e3s4, access) {
  var base = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
  var access_0 = _PathInfo___get_fullPath__impl__21fx6x(access);
  var tmp;
  // Inline function 'kotlin.text.isNotEmpty' call
  if (charSequenceLength(base) > 0) {
    tmp = trimEnd(base, charArrayOf([_Char___init__impl__6a9atx(47)])) + '/' + trim(access_0, charArrayOf([_Char___init__impl__6a9atx(47)]));
  } else {
    tmp = access_0;
  }
  var res = tmp;
  return get_pathInfo(res);
}
function lastIndexOfOrNull(_this__u8e3s4, char, startIndex) {
  startIndex = startIndex === VOID ? get_lastIndex(_this__u8e3s4) : startIndex;
  // Inline function 'kotlin.takeIf' call
  var this_0 = lastIndexOf(_this__u8e3s4, char, startIndex);
  // Inline function 'kotlin.contracts.contract' call
  var tmp;
  // Inline function 'korlibs.io.file.lastIndexOfOrNull.<anonymous>' call
  if (this_0 >= 0) {
    tmp = this_0;
  } else {
    tmp = null;
  }
  return tmp;
}
function get_extension(_this__u8e3s4) {
  return substringAfterLast(get_baseName(_this__u8e3s4), _Char___init__impl__6a9atx(46), '');
}
function parts(_this__u8e3s4) {
  return split_0(_PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4), charArrayOf([_Char___init__impl__6a9atx(47)]));
}
function isAbsolute(_this__u8e3s4) {
  var base = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
  // Inline function 'kotlin.text.isEmpty' call
  if (charSequenceLength(base) === 0)
    return false;
  var b = substringBefore(replace(base, _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(47)), _Char___init__impl__6a9atx(47));
  // Inline function 'kotlin.text.isEmpty' call
  if (charSequenceLength(b) === 0)
    return true;
  if (contains(b, _Char___init__impl__6a9atx(58)))
    return true;
  return false;
}
function get_baseNameWithoutExtension(_this__u8e3s4) {
  return substringBeforeLast(get_baseName(_this__u8e3s4), _Char___init__impl__6a9atx(46), get_baseName(_this__u8e3s4));
}
var VfsOpenMode_READ_instance;
var VfsOpenMode_WRITE_instance;
var VfsOpenMode_APPEND_instance;
var VfsOpenMode_CREATE_OR_TRUNCATE_instance;
var VfsOpenMode_CREATE_instance;
var VfsOpenMode_CREATE_NEW_instance;
var VfsOpenMode_entriesInitialized;
function VfsOpenMode_initEntries() {
  if (VfsOpenMode_entriesInitialized)
    return Unit_instance;
  VfsOpenMode_entriesInitialized = true;
  VfsOpenMode_READ_instance = new VfsOpenMode('READ', 0, 'rb', false);
  VfsOpenMode_WRITE_instance = new VfsOpenMode('WRITE', 1, 'r+b', true, true);
  VfsOpenMode_APPEND_instance = new VfsOpenMode('APPEND', 2, 'a+b', true, true, VOID, VOID, true);
  VfsOpenMode_CREATE_OR_TRUNCATE_instance = new VfsOpenMode('CREATE_OR_TRUNCATE', 3, 'w+b', true, true, true);
  VfsOpenMode_CREATE_instance = new VfsOpenMode('CREATE', 4, 'w+b', true, true);
  VfsOpenMode_CREATE_NEW_instance = new VfsOpenMode('CREATE_NEW', 5, 'w+b', true);
}
var Companion_instance;
function Companion_getInstance_0() {
  return Companion_instance;
}
var Standard_BINARY_instance;
var Standard_STRING_instance;
var Standard_LONG_instance;
var Standard_INT_instance;
var Standard_entriesInitialized;
function Standard_initEntries() {
  if (Standard_entriesInitialized)
    return Unit_instance;
  Standard_entriesInitialized = true;
  Standard_BINARY_instance = new Standard('BINARY', 0);
  Standard_STRING_instance = new Standard('STRING', 1);
  Standard_LONG_instance = new Standard('LONG', 2);
  Standard_INT_instance = new Standard('INT', 3);
}
var Companion_instance_0;
function Companion_getInstance_1() {
  return Companion_instance_0;
}
function Standard_STRING_getInstance() {
  Standard_initEntries();
  return Standard_STRING_instance;
}
function *_generator_invoke__zhh2q8($this, $this$async, $completion) {
  try {
    var tmp = $this.g21_1.i21($completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      printStackTrace(e);
    } else {
      throw $p;
    }
  }
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_0($this, it, $completion) {
  var tmp = $this.k21_1.l21(it, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = $this.j21_1.g1l(tmp, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_1($this, $this$flow, $completion) {
  var tmp = $this.m21_1.o21($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = $this.m21_1.p21($this.n21_1, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var tmp_1 = tmp_0.q21($completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var tmp_2 = Vfs$Proxy$listFlow$slambda$slambda_0($this$flow, $this.m21_1);
  var tmp_3 = tmp_1.e1l(new sam$kotlinx_coroutines_flow_FlowCollector$0(tmp_2), $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  return Unit_instance;
}
function Vfs$Proxy$listFlow$slambda$slambda_0($this_flow, this$0) {
  var i = new Vfs$Proxy$listFlow$slambda$slambda($this_flow, this$0);
  var l = function (it, $completion) {
    return i.r21(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_getUnderlyingUnscapedFile__y1cdv2($this, path, $completion) {
  var tmp = $this.o21($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = tmp.p21(path, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var tmp_1 = getUnderlyingUnscapedFile(tmp_0, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return tmp_1;
}
function *_generator_initOnce__uuyf07($this, $completion) {
  if ($this.h21_1 == null) {
    var tmp = $this;
    // Inline function 'kotlin.js.getCoroutineContext' call
    var tmp$ret$0 = $completion.gd();
    var tmp_0 = CoroutineScope(tmp$ret$0);
    tmp.h21_1 = async(tmp_0, VOID, VOID, Vfs$Proxy$initOnce$slambda_0($this));
  }
  var tmp_1 = ensureNotNull($this.h21_1).v17($completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return $this;
}
function *_generator_open__qjxcea($this, path, mode, $completion) {
  var tmp = $this.o21($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = tmp.p21(path, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var tmp_1 = tmp_0.s21(mode, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return tmp_1;
}
function *_generator_readRange__lxd64t($this, path, range, $completion) {
  var tmp = $this.o21($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = tmp.p21(path, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var tmp_1 = tmp_0.t21(range, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return tmp_1;
}
function *_generator_put__cb93wb($this, path, content, attributes, $completion) {
  var tmp = $this.o21($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = tmp.p21(path, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  // Inline function 'kotlin.collections.toTypedArray' call
  var tmp$ret$0 = copyToArray(attributes);
  var tmp_1 = tmp_0.u21(content, tmp$ret$0.slice(), $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return tmp_1;
}
function *_generator_stat__qhnowc($this, path, $completion) {
  var tmp = $this.o21($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = tmp.p21(path, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var tmp_1 = tmp_0.v21($completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  return tmp_1.d21($this.w21(path));
}
function Vfs$Proxy$initOnce$slambda_0(this$0) {
  var i = new Vfs$Proxy$initOnce$slambda(this$0);
  var l = function ($this$async, $completion) {
    return i.o1s($this$async, $completion);
  };
  l.$arity = 1;
  return l;
}
function Vfs$Proxy$listFlow$slambda_0(this$0, $path) {
  var i = new Vfs$Proxy$listFlow$slambda(this$0, $path);
  var l = function ($this$flow, $completion) {
    return i.y21($this$flow, $completion);
  };
  l.$arity = 1;
  return l;
}
var Kind_DELETED_instance;
var Kind_MODIFIED_instance;
var Kind_CREATED_instance;
var Kind_RENAMED_instance;
var Kind_entriesInitialized;
function Kind_initEntries() {
  if (Kind_entriesInitialized)
    return Unit_instance;
  Kind_entriesInitialized = true;
  Kind_DELETED_instance = new Kind('DELETED', 0);
  Kind_MODIFIED_instance = new Kind('MODIFIED', 1);
  Kind_CREATED_instance = new Kind('CREATED', 2);
  Kind_RENAMED_instance = new Kind('RENAMED', 3);
}
function Kind_MODIFIED_getInstance() {
  Kind_initEntries();
  return Kind_MODIFIED_instance;
}
function *_generator_readRange__lxd64t_0($this, path, range, $completion) {
  // Inline function 'korlibs.io.async.useIt' call
  var tmp = $this.z21(path, VfsOpenMode_READ_getInstance(), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  // Inline function 'korlibs.io.async.use' call
  var this_0 = tmp;
  var error = null;
  var tmp_0;
  try {
    // Inline function 'korlibs.io.file.Vfs.readRange.<anonymous>' call
    this_0.t1t_1 = range.b1();
    // Inline function 'kotlin.math.min' call
    // Inline function 'kotlin.Long.minus' call
    var a = (new Long(2147483647, 0)).h2(toLong(1));
    var b = range.c1().h2(range.b1());
    var tmp$ret$1 = a.g2(b) <= 0 ? a : b;
    var tmp_1 = readBytesUpTo(this_0, tmp$ret$1.q2() + 1 | 0, $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp_0 = tmp_1;
  } catch ($p) {
    var tmp_2;
    if ($p instanceof Error) {
      var e = $p;
      error = e;
      tmp_2 = null;
    } else {
      throw $p;
    }
    tmp_0 = tmp_2;
  }
  var result = tmp_0;
  if (this_0 == null)
    null;
  else {
    var tmp_3 = this_0.f1q($completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
  }
  if (!(error == null))
    throw error;
  return (result == null ? true : !(result == null)) ? result : THROW_CCE();
}
function *_generator_put__cb93wb_0($this, path, content, attributes, $completion) {
  // Inline function 'korlibs.io.async.useThis' call
  var tmp = $this.z21(path, VfsOpenMode_CREATE_OR_TRUNCATE_getInstance(), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  // Inline function 'korlibs.io.async.use' call
  var this_0 = tmp;
  var error = null;
  var tmp_0;
  try {
    // Inline function 'korlibs.io.file.Vfs.put.<anonymous>' call
    var tmp_1 = copyTo(content, this_0, VOID, $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp_0 = tmp_1;
  } catch ($p) {
    var tmp_2;
    if ($p instanceof Error) {
      var e = $p;
      error = e;
      tmp_2 = null;
    } else {
      throw $p;
    }
    tmp_0 = tmp_2;
  }
  var result = tmp_0;
  if (this_0 == null)
    null;
  else {
    var tmp_3 = this_0.f1q($completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
  }
  if (!(error == null))
    throw error;
  return (result == null ? true : !(result == null)) ? result : THROW_CCE();
}
function unsupported($this) {
  throw UnsupportedOperationException.ua('unsupported for ' + toString_0(getKClassFromExpression($this)) + ' : ' + $this.toString());
}
function *_generator_listSimple__59lt3s($this, path, $completion) {
  var tmp = $this.a22(path, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = toList_0(tmp, VOID, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return tmp_0;
}
function VfsOpenMode_READ_getInstance() {
  VfsOpenMode_initEntries();
  return VfsOpenMode_READ_instance;
}
function VfsOpenMode_CREATE_OR_TRUNCATE_getInstance() {
  VfsOpenMode_initEntries();
  return VfsOpenMode_CREATE_OR_TRUNCATE_instance;
}
function get_LONG_ZERO_TO_MAX_RANGE() {
  _init_properties_VfsFile_kt__9wj0sz();
  return LONG_ZERO_TO_MAX_RANGE;
}
var LONG_ZERO_TO_MAX_RANGE;
function *_generator_readString__6uahun($this, charset, $completion) {
  var tmp = $this.p22($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return toString_2(tmp, charset);
}
function *_generator_writeString__8z2lpg($this, data, attributes, charset, $completion) {
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp = $this.q22(toByteArray(data, charset), arrayConcat([attributes, [Companion_instance_0.f21()]]), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_stat__qhnowc_0($this, $completion) {
  var tmp0_elvis_lhs = $this.u1z_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var tmp_0 = $this.s1z_1.d22($this.t1z_1, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function *_generator_listRecursiveSimple__p8dyqa($this, filter, $completion) {
  // Inline function 'kotlin.apply' call
  var this_0 = ArrayList.m1();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.file.VfsFile.listRecursiveSimple.<anonymous>' call
  var tmp = $this.r22($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var _iterator__ex2g4s = tmp.f1();
  while (_iterator__ex2g4s.g1()) {
    var file = _iterator__ex2g4s.h1();
    if (filter(file)) {
      this_0.f(file);
      var tmp_0 = file.v21($completion);
      if (tmp_0 === get_COROUTINE_SUSPENDED())
        tmp_0 = yield tmp_0;
      var stat = tmp_0;
      if (stat.k20_1) {
        var tmp_1 = file.s22(filter, $completion);
        if (tmp_1 === get_COROUTINE_SUSPENDED())
          tmp_1 = yield tmp_1;
        this_0.k1(tmp_1);
      }
    }
  }
  return this_0;
}
function VfsFile$listRecursiveSimple$lambda(it) {
  return true;
}
function *_generator_readAsSyncStream__xvjwgr(_this__u8e3s4, $completion) {
  var tmp = _this__u8e3s4.p22($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return openSync(tmp);
}
function readAsSyncStream(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readAsSyncStream__xvjwgr(_this__u8e3s4, $completion), $completion);
}
function toLongRange(_this__u8e3s4) {
  _init_properties_VfsFile_kt__9wj0sz();
  return toLong(_this__u8e3s4.b1()).j2(toLong(_this__u8e3s4.c1()));
}
var properties_initialized_VfsFile_kt_lqx1ld;
function _init_properties_VfsFile_kt__9wj0sz() {
  if (!properties_initialized_VfsFile_kt_lqx1ld) {
    properties_initialized_VfsFile_kt_lqx1ld = true;
    LONG_ZERO_TO_MAX_RANGE = (new Long(0, 0)).j2(new Long(-1, 2147483647));
  }
}
var Companion_instance_1;
function Companion_getInstance_2() {
  return Companion_instance_1;
}
function MemoryVfs(items, caseSensitive) {
  items = items === VOID ? LinkedHashMap.vc() : items;
  caseSensitive = caseSensitive === VOID ? true : caseSensitive;
  var vfs = new NodeVfs(caseSensitive);
  // Inline function 'kotlin.collections.iterator' call
  var _iterator__ex2g4s = items.z1().f1();
  while (_iterator__ex2g4s.g1()) {
    var _destruct__k2r9zo = _iterator__ex2g4s.h1();
    // Inline function 'kotlin.collections.component1' call
    var path = _destruct__k2r9zo.a2();
    // Inline function 'kotlin.collections.component2' call
    var stream = _destruct__k2r9zo.b2();
    var info = _PathInfo___init__impl__vpw96a(path);
    var folderNode = vfs.d24().y23(get_folder(info), true);
    var fileNode = folderNode.x23(get_baseName(info), false);
    fileNode.q23_1 = stream;
  }
  return vfs.f22();
}
function VfsFileFromData(data, ext, basename) {
  ext = ext === VOID ? 'bin' : ext;
  basename = basename === VOID ? 'file' : basename;
  return SingleFileMemoryVfsWithName(data, basename + '.' + ext);
}
function SingleFileMemoryVfsWithName(data, name) {
  return MemoryVfsMix(mapOf(to(name, data))).bj(name);
}
function MemoryVfsMix(items, caseSensitive, charset) {
  items = items === VOID ? LinkedHashMap.vc() : items;
  caseSensitive = caseSensitive === VOID ? true : caseSensitive;
  charset = charset === VOID ? get_UTF8() : charset;
  // Inline function 'kotlin.collections.mapValues' call
  // Inline function 'kotlin.collections.mapValuesTo' call
  var destination = LinkedHashMap.wc(mapCapacity(items.j1()));
  // Inline function 'kotlin.collections.associateByTo' call
  var tmp0_iterator = items.z1().f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'kotlin.collections.mapValuesTo.<anonymous>' call
    var tmp = element.a2();
    // Inline function 'korlibs.io.file.std.MemoryVfsMix.<anonymous>' call
    // Inline function 'kotlin.collections.component2' call
    var v = element.b2();
    var tmp_0;
    if (v instanceof SyncStream) {
      tmp_0 = toAsync(v);
    } else {
      if (isByteArray(v)) {
        tmp_0 = openAsync(v);
      } else {
        if (typeof v === 'string') {
          tmp_0 = openAsync_0(v, charset);
        } else {
          tmp_0 = openAsync(toByteArray(toString_0(v), charset));
        }
      }
    }
    var tmp$ret$2 = tmp_0;
    destination.r3(tmp, tmp$ret$2);
  }
  return MemoryVfs(destination, caseSensitive);
}
function asMemoryVfsFile(_this__u8e3s4, name) {
  name = name === VOID ? 'temp.bin' : name;
  return MemoryVfs(mapOf(to(name, openAsync(_this__u8e3s4)))).bj(name);
}
function *_generator_invoke__zhh2q8_2($this, $this$flow, $completion) {
  var node = $this.e24_1.d24().bj($this.f24_1);
  // Inline function 'kotlin.collections.iterator' call
  var _iterator__ex2g4s = node.o23_1.z1().f1();
  while (_iterator__ex2g4s.g1()) {
    // Inline function 'kotlin.collections.component1' call
    var name = _iterator__ex2g4s.h1().a2();
    var tmp = $this$flow.g1l($this.e24_1.w21($this.f24_1 + '/' + name), $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function createStream($this, s, vfsFile) {
  return toAsyncStream(NodeVfs$createStream$1.j24(s, $this, vfsFile));
}
function *_generator_stat__qhnowc_1($this, path, $completion) {
  var tmp;
  try {
    var node = $this.d24().z23(path);
    var tmp24_safe_receiver = node.q23_1;
    var tmp_0;
    if (tmp24_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp_1 = tmp24_safe_receiver.y1s($completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      tmp_0 = tmp_1;
    }
    var tmp0_elvis_lhs = tmp_0;
    var length = tmp0_elvis_lhs == null ? new Long(0, 0) : tmp0_elvis_lhs;
    tmp = $this.h22(path, node.j23_1, length);
  } catch ($p) {
    var tmp_2;
    if ($p instanceof Error) {
      var e = $p;
      tmp_2 = $this.j22(path);
    } else {
      throw $p;
    }
    tmp = tmp_2;
  }
  return tmp;
}
function NodeVfs$listFlow$slambda_0(this$0, $path) {
  var i = new NodeVfs$listFlow$slambda(this$0, $path);
  var l = function ($this$flow, $completion) {
    return i.y21($this$flow, $completion);
  };
  l.$arity = 1;
  return l;
}
//region block: post-declaration
initMetadataForClass(FinalVfsFile, 'FinalVfsFile');
initMetadataForClass(VfsNamed, 'VfsNamed');
initMetadataForClass(VfsOpenMode, 'VfsOpenMode');
initMetadataForClass(VfsStat, 'VfsStat');
initMetadataForCompanion(Companion);
protoOf(VfsCachedStatContext).td = get;
protoOf(VfsCachedStatContext).wn = fold;
protoOf(VfsCachedStatContext).vn = minusKey;
protoOf(VfsCachedStatContext).xn = plus;
initMetadataForClass(VfsCachedStatContext, 'VfsCachedStatContext', VOID, VOID, [Element]);
initMetadataForCompanion(Companion_0);
initMetadataForClass(Standard, 'Standard');
initMetadataForLambda(Vfs$Proxy$listFlow$slambda$slambda, VOID, VOID, [1]);
initMetadataForClass(sam$kotlinx_coroutines_flow_FlowCollector$0, 'sam$kotlinx_coroutines_flow_FlowCollector$0', VOID, VOID, [FlowCollector], [1]);
initMetadataForLambda(Vfs$Proxy$initOnce$slambda, VOID, VOID, [1]);
initMetadataForLambda(Vfs$Proxy$listFlow$slambda, VOID, VOID, [1]);
initMetadataForClass(Kind, 'Kind');
initMetadataForClass(Vfs, 'Vfs', VOID, VOID, VOID, [1, 0, 2, 4, 3]);
initMetadataForClass(Proxy, 'Proxy', VOID, VOID, VOID, [1, 0, 4, 2, 3]);
initMetadataForClass(FileEvent, 'FileEvent');
initMetadataForClass(VfsFile, 'VfsFile', VOID, VOID, VOID, [0, 2, 3, 1, 4, 5]);
initMetadataForCompanion(Companion_1);
initMetadataForClass(JailVfs, 'JailVfs', VOID, VOID, VOID, [1, 0, 4, 2, 3]);
initMetadataForClass(LocalVfs, 'LocalVfs', VOID, VOID, VOID, [1, 4, 0, 2, 3]);
initMetadataForClass(Node, 'Node', VOID, VOID, [Iterable]);
initMetadataForClass(MemoryNodeTree, 'MemoryNodeTree', MemoryNodeTree);
initMetadataForClass(NodeVfs$createStream$1, VOID, VOID, VOID, VOID, [4, 1, 0]);
initMetadataForLambda(NodeVfs$listFlow$slambda, VOID, VOID, [1]);
initMetadataForClass(NodeVfs, 'NodeVfs', NodeVfs, VOID, VOID, [2, 1, 0, 4, 3]);
initMetadataForClass(UrlVfs, 'UrlVfs', VOID, VOID, VOID, [1, 0, 2, 4, 3]);
//endregion
//region block: init
Companion_instance = new Companion();
Companion_instance_0 = new Companion_0();
Companion_instance_1 = new Companion_1();
//endregion
//region block: exports
export {
  VfsOpenMode_READ_getInstance as VfsOpenMode_READ_getInstance21wyhaci8ji56,
  getUnderlyingUnscapedFile as getUnderlyingUnscapedFile1x96alth7xqs,
  readAsSyncStream as readAsSyncStream1fxi8ije69l8f,
  _PathInfo___init__impl__vpw96a as _PathInfo___init__impl__vpw96a3g5f537ih73ch,
  _PathInfo___get_fullPath__impl__21fx6x as _PathInfo___get_fullPath__impl__21fx6xt8vitrwzrja3,
  Companion_instance as Companion_instance18o5i5v0om74a,
  LocalVfs as LocalVfs18bzski76hhpp,
  MemoryVfs as MemoryVfs23lbcbyysxxy3,
  UrlVfs as UrlVfs292f1sja450ff,
  VfsFileFromData as VfsFileFromData3esxwyyzmvxag,
  asMemoryVfsFile as asMemoryVfsFile18fsaquwjql18,
  Proxy as Proxy2ri7nr2ijtx4g,
  VfsCachedStatContext as VfsCachedStatContext2gte5m09f8ox9,
  VfsFile as VfsFile5y6qnpu2qri3,
  Vfs as Vfs3mgmizq3oi7gy,
  get_baseName as get_baseName7iz3sifr1imo,
  get_baseName_0 as get_baseName2yf63efofm8te,
  get_baseNameWithoutExtension as get_baseNameWithoutExtension11tnoplwjp6a3,
  get_extensionLC_0 as get_extensionLC3gx4qkgbywkav,
  get_extensionLC as get_extensionLCr5nk7cwvpg7s,
  get_fullName as get_fullName2a2vm7nxze3wi,
  normalize as normalize34ppus0f5oo02,
  get_parent as get_parent1emraos91g459,
  get_pathInfo as get_pathInfo26tycr2r540oz,
};
//endregion
