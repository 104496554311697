import {
  Unit_instance104q5opgivhr8 as Unit_instance,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  Continuation1aa2oekvx7jm7 as Continuation,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  VOID7hggqo3abtya as VOID,
  EmptyCoroutineContext_getInstance2bxophqwsfm9n as EmptyCoroutineContext_getInstance,
  Key_instance2d3ch37kcwr5h as Key_instance,
  equals2au1ep9vhcato as equals,
  createCoroutineUninterceptedGeneratorVersion2gduom218i9ay as createCoroutineUninterceptedGeneratorVersion,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  intercepted2ogpsikxxj4u0 as intercepted,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  UnsupportedOperationException2tkumpmhredt3 as UnsupportedOperationException,
  isInterface3d6p8outrmvmk as isInterface,
  toString30pk9tzaqopn as toString_0,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  hashCodeq5arwsb9dgti as hashCode,
  Result__exceptionOrNull_impl_p6xea91phgcskd46fkx as Result__exceptionOrNull_impl_p6xea9,
  _Result___get_value__impl__bjfvqg1uwg4i093qaqh as _Result___get_value__impl__bjfvqg,
  CancellationException3b36o9qz53rgr as CancellationException,
  Companion_instance3fplhgf4ipvld as Companion_instance,
  _Result___init__impl__xyqfz831xktsyjq1qrq as _Result___init__impl__xyqfz8,
  createFailure8paxfkfa5dc7 as createFailure,
  AbstractCoroutineContextKey9xr9r6wlj5bm as AbstractCoroutineContextKey,
  AbstractCoroutineContextElement2rpehg0hv5szw as AbstractCoroutineContextElement,
  getxe4seun860fg as get,
  protoOf180f3jzyo7rfj as protoOf,
  minusKey2uxs00uz5ceqp as minusKey,
  ContinuationInterceptor2624y0vaqwxwf as ContinuationInterceptor,
  RuntimeException1r3t0zl97011n as RuntimeException,
  addSuppressedu5jwjfvsc039 as addSuppressed,
  Enum3alwj03lh1n41 as Enum,
  startCoroutine327fwvtqvedik as startCoroutine,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  Long2qws0ah9gnpki as Long,
  Duration__isPositive_impl_tvkkt23a6g2bafi065h as Duration__isPositive_impl_tvkkt2,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  DurationUnit_NANOSECONDS_getInstancexzp0zqz7eqak as DurationUnit_NANOSECONDS_getInstance,
  toDurationba1nlt78o6vu as toDuration,
  Duration__plus_impl_yu9v8f1x7qwk17prwx4 as Duration__plus_impl_yu9v8f,
  _Duration___get_inWholeMilliseconds__impl__msfiry1aosgeltb5g7r as _Duration___get_inWholeMilliseconds__impl__msfiry,
  ArrayDeque2dzc9uld4xi7n as ArrayDeque,
  captureStack1fzi4aczwc4hg as captureStack,
  Error3ofk6owajcepa as Error_0,
  Element2gr7ezmxqaln7 as Element,
  StringBuildermazzzhj6kkai as StringBuilder,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  ArrayList3it5z8td81qkl as ArrayList,
  SequenceScope1coiso86pqzq2 as SequenceScope,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  sequence2vgswtrxvqoa7 as sequence,
  plusolev77jfy5r9 as plus,
  get6d5x931vk0s as get_0,
  fold36i9psb7d5v48 as fold,
  minusKeyyqanvso9aovh as minusKey_0,
  anyToString3ho3k49fc56mj as anyToString,
  DurationUnit_MILLISECONDS_getInstance15owevua4zjxe as DurationUnit_MILLISECONDS_getInstance,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  toLongw1zpgk99d84b as toLong,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  listOf1jh22dvmctj1r as listOf,
  NoSuchElementException679xzhnp5bpj as NoSuchElementException,
  compareTo3ankvs086tmwq as compareTo,
  last2n4gf5az1lkn4 as last,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  getKClass1s3j9wy1cofik as getKClass,
  Exceptiondt2hlxn7j7vw as Exception,
  toLongOrNullutqivezb0wx1 as toLongOrNull,
  startCoroutineUninterceptedOrReturnGeneratorVersion1cv0wx9z0l0zn as startCoroutineUninterceptedOrReturnGeneratorVersion,
  plus20p0vtfmu0596 as plus_0,
  KtList3hktaavzmj137 as KtList,
  listOfvhqybd2zx248 as listOf_0,
  Unitkvevlwgzwiuc as Unit,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  removeFirstOrNull15yg2tczrh8a7 as removeFirstOrNull,
  KtMutableList1beimitadwkna as KtMutableList,
  coerceIn302bduskdb54x as coerceIn,
  boxApply1qmzdb3dh90hg as boxApply,
  AutoCloseable1l5p57f9lp7kv as AutoCloseable,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  HashSet2dzve9y63nf0v as HashSet,
  LinkedHashSet2tkztfx86kyx2 as LinkedHashSet,
} from './kotlin-kotlin-stdlib.mjs';
import {
  atomic$int$11d5swdyn6j0pu as atomic$int$1,
  atomic$ref$130aurmcwdfdf1 as atomic$ref$1,
  atomic$boolean$1iggki4z65a2h as atomic$boolean$1,
  atomicfu$AtomicRefArray$ofNulls2kz3j9ehigwa3 as atomicfu$AtomicRefArray$ofNulls,
  atomic$long$129k9zwo6n9ogd as atomic$long$1,
} from './kotlinx-atomicfu.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Job {}
function cancel$default(cause, $super) {
  cause = cause === VOID ? null : cause;
  var tmp;
  if ($super === VOID) {
    this.k16(cause);
    tmp = Unit_instance;
  } else {
    tmp = $super.k16.call(this, cause);
  }
  return tmp;
}
class ParentJob {}
class JobSupport {
  constructor(active) {
    this.w14_1 = atomic$ref$1(active ? get_EMPTY_ACTIVE() : get_EMPTY_NEW());
    this.x14_1 = atomic$ref$1(null);
  }
  a2() {
    return Key_instance_2;
  }
  u15(value) {
    this.x14_1.kotlinx$atomicfu$value = value;
  }
  v15() {
    return this.x14_1.kotlinx$atomicfu$value;
  }
  w15() {
    var tmp45_safe_receiver = this.v15();
    return tmp45_safe_receiver == null ? null : tmp45_safe_receiver.w15();
  }
  y14(parent) {
    // Inline function 'kotlinx.coroutines.assert' call
    if (parent == null) {
      this.u15(NonDisposableHandle_instance);
      return Unit_instance;
    }
    parent.z15();
    var handle = parent.t16(this);
    this.u15(handle);
    if (this.y15()) {
      handle.o18();
      this.u15(NonDisposableHandle_instance);
    }
  }
  x15() {
    return this.w14_1.kotlinx$atomicfu$value;
  }
  d15() {
    var state = this.x15();
    var tmp;
    if (!(state == null) ? isInterface(state, Incomplete) : false) {
      tmp = state.d15();
    } else {
      tmp = false;
    }
    return tmp;
  }
  y15() {
    var tmp = this.x15();
    return !(!(tmp == null) ? isInterface(tmp, Incomplete) : false);
  }
  z15() {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.start.<anonymous>' call
      var state = this.x15();
      var tmp52_subject = startInternal(this, state);
      if (tmp52_subject === 0)
        return false;
      else if (tmp52_subject === 1)
        return true;
    }
  }
  a16() {
  }
  b16() {
    var state = this.x15();
    var tmp;
    if (state instanceof Finishing) {
      var tmp54_safe_receiver = state.i1e();
      var tmp0_elvis_lhs = tmp54_safe_receiver == null ? null : this.c16(tmp54_safe_receiver, get_classSimpleName(this) + ' is cancelling');
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        var message = 'Job is still new or active: ' + this.toString();
        throw IllegalStateException.m5(toString(message));
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      tmp = tmp_0;
    } else {
      if (!(state == null) ? isInterface(state, Incomplete) : false) {
        var message_0 = 'Job is still new or active: ' + this.toString();
        throw IllegalStateException.m5(toString(message_0));
      } else {
        if (state instanceof CompletedExceptionally) {
          tmp = this.d16(state.i15_1);
        } else {
          tmp = JobCancellationException.d1e(get_classSimpleName(this) + ' has completed normally', null, this);
        }
      }
    }
    return tmp;
  }
  c16(_this__u8e3s4, message) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof CancellationException ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      tmp = JobCancellationException.d1e(message == null ? this.g15() : message, _this__u8e3s4, this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  d16(_this__u8e3s4, message, $super) {
    message = message === VOID ? null : message;
    return $super === VOID ? this.c16(_this__u8e3s4, message) : $super.c16.call(this, _this__u8e3s4, message);
  }
  e16(handler) {
    return this.g16(true, new InvokeOnCompletion(handler));
  }
  f16(onCancelling, invokeImmediately, handler) {
    var tmp;
    if (onCancelling) {
      tmp = new InvokeOnCancelling(handler);
    } else {
      tmp = new InvokeOnCompletion(handler);
    }
    return this.g16(invokeImmediately, tmp);
  }
  g16(invokeImmediately, node) {
    node.b1b_1 = this;
    var tmp$ret$0;
    $l$block_1: {
      // Inline function 'kotlinx.coroutines.JobSupport.tryPutNodeIntoList' call
      // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
      while (true) {
        // Inline function 'kotlinx.coroutines.JobSupport.tryPutNodeIntoList.<anonymous>' call
        var state = this.x15();
        if (state instanceof Empty) {
          if (state.f1d_1) {
            if (this.w14_1.atomicfu$compareAndSet(state, node)) {
              tmp$ret$0 = true;
              break $l$block_1;
            }
          } else {
            promoteEmptyToNodeList(this, state);
          }
        } else {
          if (!(state == null) ? isInterface(state, Incomplete) : false) {
            var list = state.e1b();
            if (list == null) {
              promoteSingleToNodeList(this, state instanceof JobNode ? state : THROW_CCE());
            } else {
              // Inline function 'kotlinx.coroutines.JobSupport.invokeOnCompletionInternal.<anonymous>' call
              var tmp;
              if (node.d1b()) {
                var tmp55_safe_receiver = state instanceof Finishing ? state : null;
                var rootCause = tmp55_safe_receiver == null ? null : tmp55_safe_receiver.i1e();
                var tmp_0;
                if (rootCause == null) {
                  tmp_0 = list.i1b(node, 5);
                } else {
                  if (invokeImmediately) {
                    node.n18(rootCause);
                  }
                  return NonDisposableHandle_instance;
                }
                tmp = tmp_0;
              } else {
                tmp = list.i1b(node, 1);
              }
              if (tmp) {
                tmp$ret$0 = true;
                break $l$block_1;
              }
            }
          } else {
            tmp$ret$0 = false;
            break $l$block_1;
          }
        }
      }
    }
    var added = tmp$ret$0;
    if (added)
      return node;
    else if (invokeImmediately) {
      var tmp_1 = this.x15();
      var tmp56_safe_receiver = tmp_1 instanceof CompletedExceptionally ? tmp_1 : null;
      node.n18(tmp56_safe_receiver == null ? null : tmp56_safe_receiver.i15_1);
    }
    return NonDisposableHandle_instance;
  }
  h16($completion) {
    if (!joinInternal(this)) {
      // Inline function 'kotlin.js.getCoroutineContext' call
      var tmp$ret$0 = $completion.gd();
      ensureActive(tmp$ret$0);
      return Unit_instance;
    }
    return joinSuspend(this, $completion);
  }
  i16(node) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.removeNode.<anonymous>' call
      var state = this.x15();
      if (state instanceof JobNode) {
        if (!(state === node))
          return Unit_instance;
        if (this.w14_1.atomicfu$compareAndSet(state, get_EMPTY_ACTIVE()))
          return Unit_instance;
      } else {
        if (!(state == null) ? isInterface(state, Incomplete) : false) {
          if (!(state.e1b() == null)) {
            node.j1b();
          }
          return Unit_instance;
        } else {
          return Unit_instance;
        }
      }
    }
  }
  j16() {
    return false;
  }
  k16(cause) {
    var tmp;
    if (cause == null) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      tmp = JobCancellationException.d1e(null == null ? this.g15() : null, null, this);
    } else {
      tmp = cause;
    }
    this.m16(tmp);
  }
  g15() {
    return 'Job was cancelled';
  }
  m16(cause) {
    this.q16(cause);
  }
  n16(parentJob) {
    this.q16(parentJob);
  }
  o16(cause) {
    if (cause instanceof CancellationException)
      return true;
    return this.q16(cause) && this.w16();
  }
  p16(cause) {
    return this.q16(cause);
  }
  q16(cause) {
    var finalState = get_COMPLETING_ALREADY();
    if (this.j16()) {
      finalState = cancelMakeCompleting(this, cause);
      if (finalState === get_COMPLETING_WAITING_CHILDREN())
        return true;
    }
    if (finalState === get_COMPLETING_ALREADY()) {
      finalState = makeCancelling(this, cause);
    }
    var tmp;
    if (finalState === get_COMPLETING_ALREADY()) {
      tmp = true;
    } else if (finalState === get_COMPLETING_WAITING_CHILDREN()) {
      tmp = true;
    } else if (finalState === get_TOO_LATE_TO_CANCEL()) {
      tmp = false;
    } else {
      this.n15(finalState);
      tmp = true;
    }
    return tmp;
  }
  r16() {
    var state = this.x15();
    var tmp;
    if (state instanceof Finishing) {
      tmp = state.i1e();
    } else {
      if (state instanceof CompletedExceptionally) {
        tmp = state.i15_1;
      } else {
        if (!(state == null) ? isInterface(state, Incomplete) : false) {
          var message = 'Cannot be cancelling child in this state: ' + toString(state);
          throw IllegalStateException.m5(toString(message));
        } else {
          tmp = null;
        }
      }
    }
    var rootCause = tmp;
    var tmp0_elvis_lhs = rootCause instanceof CancellationException ? rootCause : null;
    return tmp0_elvis_lhs == null ? JobCancellationException.d1e('Parent job is ' + stateString(this, state), rootCause, this) : tmp0_elvis_lhs;
  }
  p1b(proposedUpdate) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.makeCompleting.<anonymous>' call
        var state = this.x15();
        var finalState = tryMakeCompleting(this, state, proposedUpdate);
        if (finalState === get_COMPLETING_ALREADY())
          return false;
        else if (finalState === get_COMPLETING_WAITING_CHILDREN())
          return true;
        else if (finalState === get_COMPLETING_RETRY()) {
          break $l$block;
        } else {
          this.n15(finalState);
          return true;
        }
      }
    }
  }
  l15(proposedUpdate) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.makeCompletingOnce.<anonymous>' call
        var state = this.x15();
        var finalState = tryMakeCompleting(this, state, proposedUpdate);
        if (finalState === get_COMPLETING_ALREADY())
          throw IllegalStateException.je('Job ' + this.toString() + ' is already complete or completing, ' + ('but is being completed with ' + toString_0(proposedUpdate)), _get_exceptionOrNull__b3j7js(proposedUpdate, this));
        else if (finalState === get_COMPLETING_RETRY()) {
          break $l$block;
        } else
          return finalState;
      }
    }
  }
  s16() {
    return sequence(JobSupport$_get_children_$slambda_k839f8_0(this));
  }
  t16(child) {
    // Inline function 'kotlin.also' call
    var this_0 = new ChildHandleNode(child);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.JobSupport.attachChild.<anonymous>' call
    this_0.b1b_1 = this;
    var node = this_0;
    var tmp$ret$1;
    $l$block_1: {
      // Inline function 'kotlinx.coroutines.JobSupport.tryPutNodeIntoList' call
      // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
      while (true) {
        // Inline function 'kotlinx.coroutines.JobSupport.tryPutNodeIntoList.<anonymous>' call
        var state = this.x15();
        if (state instanceof Empty) {
          if (state.f1d_1) {
            if (this.w14_1.atomicfu$compareAndSet(state, node)) {
              tmp$ret$1 = true;
              break $l$block_1;
            }
          } else {
            promoteEmptyToNodeList(this, state);
          }
        } else {
          if (!(state == null) ? isInterface(state, Incomplete) : false) {
            var list = state.e1b();
            if (list == null) {
              promoteSingleToNodeList(this, state instanceof JobNode ? state : THROW_CCE());
            } else {
              // Inline function 'kotlinx.coroutines.JobSupport.attachChild.<anonymous>' call
              var addedBeforeCancellation = list.i1b(node, 7);
              var tmp;
              if (addedBeforeCancellation) {
                tmp = true;
              } else {
                var addedBeforeCompletion = list.i1b(node, 3);
                var latestState = this.x15();
                var tmp_0;
                if (latestState instanceof Finishing) {
                  tmp_0 = latestState.i1e();
                } else {
                  // Inline function 'kotlinx.coroutines.assert' call
                  var tmp69_safe_receiver = latestState instanceof CompletedExceptionally ? latestState : null;
                  tmp_0 = tmp69_safe_receiver == null ? null : tmp69_safe_receiver.i15_1;
                }
                var rootCause = tmp_0;
                node.n18(rootCause);
                var tmp_1;
                if (addedBeforeCompletion) {
                  // Inline function 'kotlinx.coroutines.assert' call
                  tmp_1 = true;
                } else {
                  return NonDisposableHandle_instance;
                }
                tmp = tmp_1;
              }
              if (tmp) {
                tmp$ret$1 = true;
                break $l$block_1;
              }
            }
          } else {
            tmp$ret$1 = false;
            break $l$block_1;
          }
        }
      }
    }
    var added = tmp$ret$1;
    if (added)
      return node;
    var tmp_2 = this.x15();
    var tmp70_safe_receiver = tmp_2 instanceof CompletedExceptionally ? tmp_2 : null;
    node.n18(tmp70_safe_receiver == null ? null : tmp70_safe_receiver.i15_1);
    return NonDisposableHandle_instance;
  }
  o15(exception) {
    throw exception;
  }
  u16(cause) {
  }
  v16() {
    return false;
  }
  w16() {
    return true;
  }
  x16(exception) {
    return false;
  }
  h15(state) {
  }
  n15(state) {
  }
  toString() {
    return this.y16() + '@' + get_hexAddress(this);
  }
  y16() {
    return this.p15() + '{' + stateString(this, this.x15()) + '}';
  }
  p15() {
    return get_classSimpleName(this);
  }
  z16($completion) {
    $l$loop: while (true) {
      var state = this.x15();
      if (!(!(state == null) ? isInterface(state, Incomplete) : false)) {
        if (state instanceof CompletedExceptionally) {
          // Inline function 'kotlinx.coroutines.internal.recoverAndThrow' call
          throw state.i15_1;
        }
        return unboxState(state);
      }
      if (startInternal(this, state) >= 0)
        break $l$loop;
    }
    return awaitSuspend(this, $completion);
  }
}
class CoroutineScope {}
class AbstractCoroutine extends JobSupport {
  constructor(parentContext, initParentJob, active) {
    super(active);
    if (initParentJob) {
      this.y14(parentContext.td(Key_instance_2));
    }
    this.b15_1 = parentContext.xn(this);
  }
  gd() {
    return this.b15_1;
  }
  c15() {
    return this.b15_1;
  }
  d15() {
    return super.d15();
  }
  e15(value) {
  }
  f15(cause, handled) {
  }
  g15() {
    return get_classSimpleName(this) + ' was cancelled';
  }
  h15(state) {
    if (state instanceof CompletedExceptionally) {
      this.f15(state.i15_1, state.k15());
    } else {
      this.e15((state == null ? true : !(state == null)) ? state : THROW_CCE());
    }
  }
  id(result) {
    var state = this.l15(toState_0(result));
    if (state === get_COMPLETING_WAITING_CHILDREN())
      return Unit_instance;
    this.m15(state);
  }
  m15(state) {
    return this.n15(state);
  }
  o15(exception) {
    handleCoroutineException(this.b15_1, exception);
  }
  p15() {
    var tmp0_elvis_lhs = get_coroutineName(this.b15_1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return super.p15();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var coroutineName = tmp;
    return '"' + coroutineName + '":' + super.p15();
  }
  q15(start, receiver, block) {
    start.t15(block, receiver, this);
  }
}
class StandaloneCoroutine extends AbstractCoroutine {
  constructor(parentContext, active) {
    super(parentContext, true, active);
  }
  x16(exception) {
    handleCoroutineException(this.b15_1, exception);
    return true;
  }
}
class LazyStandaloneCoroutine extends StandaloneCoroutine {
  constructor(parentContext, block) {
    super(parentContext, false);
    this.n17_1 = createCoroutineUninterceptedGeneratorVersion(block, this, this);
  }
  a16() {
    startCoroutineCancellable_0(this.n17_1, this);
  }
}
class ScopeCoroutine extends AbstractCoroutine {
  constructor(context, uCont) {
    super(context, true, true);
    this.r17_1 = uCont;
  }
  v16() {
    return true;
  }
  n15(state) {
    resumeCancellableWith(intercepted(this.r17_1), recoverResult(state, this.r17_1));
  }
  m15(state) {
    this.r17_1.id(recoverResult(state, this.r17_1));
  }
}
class DispatchedCoroutine extends ScopeCoroutine {
  constructor(context, uCont) {
    super(context, uCont);
    this.f17_1 = atomic$int$1(0);
  }
  n15(state) {
    this.m15(state);
  }
  m15(state) {
    if (tryResume(this))
      return Unit_instance;
    resumeCancellableWith(intercepted(this.r17_1), recoverResult(state, this.r17_1));
  }
  g17() {
    if (trySuspend(this))
      return get_COROUTINE_SUSPENDED();
    var state = unboxState(this.x15());
    if (state instanceof CompletedExceptionally)
      throw state.i15_1;
    return (state == null ? true : !(state == null)) ? state : THROW_CCE();
  }
}
class Deferred {}
class DeferredCoroutine extends AbstractCoroutine {
  constructor(parentContext, active) {
    super(parentContext, true, active);
  }
  v17($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_await__mos7q6(this, $completion), $completion);
  }
}
class LazyDeferredCoroutine extends DeferredCoroutine {
  constructor(parentContext, block) {
    super(parentContext, false);
    this.z17_1 = createCoroutineUninterceptedGeneratorVersion(block, this, this);
  }
  a16() {
    startCoroutineCancellable_0(this.z17_1, this);
  }
}
class CancellableContinuation {}
class NotCompleted {}
class CancelHandler {}
class DisposeOnCancel {
  constructor(handle) {
    this.m18_1 = handle;
  }
  n18(cause) {
    return this.m18_1.o18();
  }
  toString() {
    return 'DisposeOnCancel[' + toString(this.m18_1) + ']';
  }
}
class Runnable {}
class SchedulerTask {}
class DispatchedTask extends SchedulerTask {
  constructor(resumeMode) {
    super();
    this.y18_1 = resumeMode;
  }
  w19(takenState, cause) {
  }
  d1a(state) {
    return (state == null ? true : !(state == null)) ? state : THROW_CCE();
  }
  n1a(state) {
    var tmp151_safe_receiver = state instanceof CompletedExceptionally ? state : null;
    return tmp151_safe_receiver == null ? null : tmp151_safe_receiver.i15_1;
  }
  p1a() {
    // Inline function 'kotlinx.coroutines.assert' call
    var taskContext = get_taskContext(this);
    var fatalException = null;
    try {
      var tmp = this.t19();
      var delegate = tmp instanceof DispatchedContinuation ? tmp : THROW_CCE();
      var continuation = delegate.r18_1;
      // Inline function 'kotlinx.coroutines.withContinuationContext' call
      delegate.t18_1;
      var context = continuation.gd();
      var state = this.v19();
      var exception = this.n1a(state);
      var job = exception == null && get_isCancellableMode(this.y18_1) ? context.td(Key_instance_2) : null;
      if (!(job == null) && !job.d15()) {
        var cause = job.b16();
        this.w19(state, cause);
        // Inline function 'kotlinx.coroutines.resumeWithStackTrace' call
        // Inline function 'kotlin.Companion.failure' call
        var exception_0 = recoverStackTrace(cause, continuation);
        var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception_0));
        continuation.id(tmp$ret$0);
      } else {
        if (!(exception == null)) {
          // Inline function 'kotlin.coroutines.resumeWithException' call
          // Inline function 'kotlin.Companion.failure' call
          var tmp$ret$1 = _Result___init__impl__xyqfz8(createFailure(exception));
          continuation.id(tmp$ret$1);
        } else {
          // Inline function 'kotlin.coroutines.resume' call
          // Inline function 'kotlin.Companion.success' call
          var value = this.d1a(state);
          var tmp$ret$3 = _Result___init__impl__xyqfz8(value);
          continuation.id(tmp$ret$3);
        }
      }
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        fatalException = e;
      } else {
        throw $p;
      }
    }
    finally {
      // Inline function 'kotlin.runCatching' call
      var tmp_0;
      try {
        // Inline function 'kotlin.Companion.success' call
        // Inline function 'kotlinx.coroutines.afterTask' call
        tmp_0 = _Result___init__impl__xyqfz8(Unit_instance);
      } catch ($p) {
        var tmp_1;
        if ($p instanceof Error) {
          var e_0 = $p;
          // Inline function 'kotlin.Companion.failure' call
          tmp_1 = _Result___init__impl__xyqfz8(createFailure(e_0));
        } else {
          throw $p;
        }
        tmp_0 = tmp_1;
      }
      var result = tmp_0;
      this.q1a(fatalException, Result__exceptionOrNull_impl_p6xea9(result));
    }
  }
  q1a(exception, finallyException) {
    if (exception === null && finallyException === null)
      return Unit_instance;
    if (!(exception === null) && !(finallyException === null)) {
      addSuppressed(exception, finallyException);
    }
    var cause = exception == null ? finallyException : exception;
    var reason = CoroutinesInternalError.z1c('Fatal exception in coroutines machinery for ' + toString(this) + '. ' + "Please read KDoc to 'handleFatalException' method and report this incident to maintainers", ensureNotNull(cause));
    handleCoroutineException(this.t19().gd(), reason);
  }
}
class Waiter {}
class CancellableContinuationImpl extends DispatchedTask {
  constructor(delegate, resumeMode) {
    super(resumeMode);
    this.g18_1 = delegate;
    // Inline function 'kotlinx.coroutines.assert' call
    this.h18_1 = this.g18_1.gd();
    var tmp = this;
    // Inline function 'kotlinx.coroutines.decisionAndIndex' call
    var tmp$ret$0 = (0 << 29) + 536870911 | 0;
    tmp.i18_1 = atomic$int$1(tmp$ret$0);
    this.j18_1 = atomic$ref$1(Active_instance);
    this.k18_1 = atomic$ref$1(null);
  }
  t19() {
    return this.g18_1;
  }
  gd() {
    return this.h18_1;
  }
  x15() {
    return this.j18_1.kotlinx$atomicfu$value;
  }
  y15() {
    var tmp = this.x15();
    return !(!(tmp == null) ? isInterface(tmp, NotCompleted) : false);
  }
  u19() {
    var tmp0_elvis_lhs = installParentHandle(this);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var handle = tmp;
    if (this.y15()) {
      handle.o18();
      this.k18_1.kotlinx$atomicfu$value = NonDisposableHandle_instance;
    }
  }
  w18() {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    var state = this.j18_1.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.assert' call
    var tmp;
    if (state instanceof CompletedContinuation) {
      tmp = !(state.m19_1 == null);
    } else {
      tmp = false;
    }
    if (tmp) {
      this.s19();
      return false;
    }
    var tmp_0 = this.i18_1;
    // Inline function 'kotlinx.coroutines.decisionAndIndex' call
    tmp_0.kotlinx$atomicfu$value = (0 << 29) + 536870911 | 0;
    this.j18_1.kotlinx$atomicfu$value = Active_instance;
    return true;
  }
  v19() {
    return this.x15();
  }
  w19(takenState, cause) {
    var this_0 = this.j18_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancelCompletedResult.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (!(state == null) ? isInterface(state, NotCompleted) : false) {
        // Inline function 'kotlin.error' call
        var message = 'Not completed';
        throw IllegalStateException.m5(toString(message));
      } else {
        if (state instanceof CompletedExceptionally)
          return Unit_instance;
        else {
          if (state instanceof CompletedContinuation) {
            // Inline function 'kotlin.check' call
            // Inline function 'kotlin.contracts.contract' call
            if (!!state.p19()) {
              // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancelCompletedResult.<anonymous>.<anonymous>' call
              var message_0 = 'Must be called at most once';
              throw IllegalStateException.m5(toString(message_0));
            }
            var update = state.q19(VOID, VOID, VOID, VOID, cause);
            if (this.j18_1.atomicfu$compareAndSet(state, update)) {
              state.x19(this, cause);
              return Unit_instance;
            }
          } else {
            if (this.j18_1.atomicfu$compareAndSet(state, new CompletedContinuation(state, VOID, VOID, VOID, cause))) {
              return Unit_instance;
            }
          }
        }
      }
    }
    return Unit_instance;
  }
  y19(cause) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.j18_1;
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancel.<anonymous>' call
        var state = this_0.kotlinx$atomicfu$value;
        if (!(!(state == null) ? isInterface(state, NotCompleted) : false))
          return false;
        var tmp;
        if (isInterface(state, CancelHandler)) {
          tmp = true;
        } else {
          tmp = state instanceof Segment;
        }
        var update = new CancelledContinuation(this, cause, tmp);
        if (!this.j18_1.atomicfu$compareAndSet(state, update)) {
          break $l$block;
        }
        if (isInterface(state, CancelHandler)) {
          this.o19(state, cause);
        } else {
          if (state instanceof Segment) {
            callSegmentOnCancellation(this, state, cause);
          }
        }
        detachChildIfNonResuable(this);
        dispatchResume(this, this.y18_1);
        return true;
      }
    }
  }
  z19(cause) {
    if (cancelLater(this, cause))
      return Unit_instance;
    this.y19(cause);
    detachChildIfNonResuable(this);
  }
  o19(handler, cause) {
    var tmp;
    try {
      handler.n18(cause);
      tmp = Unit_instance;
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var ex = $p;
        handleCoroutineException(this.gd(), CompletionHandlerException.i19('Exception in invokeOnCancellation handler for ' + this.toString(), ex));
        tmp_0 = Unit_instance;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  a1a(onCancellation, cause, value) {
    try {
      onCancellation(cause, value, this.gd());
    } catch ($p) {
      if ($p instanceof Error) {
        var ex = $p;
        handleCoroutineException(this.gd(), CompletionHandlerException.i19('Exception in resume onCancellation handler for ' + this.toString(), ex));
      } else {
        throw $p;
      }
    }
  }
  b1a(parent) {
    return parent.b16();
  }
  g17() {
    var isReusable_0 = isReusable(this);
    if (trySuspend_0(this)) {
      if (_get_parentHandle__f8dcex(this) == null) {
        installParentHandle(this);
      }
      if (isReusable_0) {
        this.c1a();
      }
      return get_COROUTINE_SUSPENDED();
    }
    if (isReusable_0) {
      this.c1a();
    }
    var state = this.x15();
    if (state instanceof CompletedExceptionally)
      throw recoverStackTrace(state.i15_1, this);
    if (get_isCancellableMode(this.y18_1)) {
      var job = this.gd().td(Key_instance_2);
      if (!(job == null) && !job.d15()) {
        var cause = job.b16();
        this.w19(state, cause);
        throw recoverStackTrace(cause, this);
      }
    }
    return this.d1a(state);
  }
  c1a() {
    var tmp = this.g18_1;
    var tmp10_safe_receiver = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp0_elvis_lhs = tmp10_safe_receiver == null ? null : tmp10_safe_receiver.e1a(this);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var cancellationCause = tmp_0;
    this.s19();
    this.y19(cancellationCause);
  }
  id(result) {
    return this.f1a(toState(result, this), this.y18_1);
  }
  g1a(value, onCancellation) {
    var tmp = this.y18_1;
    var tmp_0;
    if (onCancellation == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.resume.<anonymous>' call
      tmp_0 = CancellableContinuationImpl$resume$lambda(onCancellation);
    }
    return this.h1a(value, tmp, tmp_0);
  }
  e18(value, onCancellation) {
    return this.h1a(value, this.y18_1, onCancellation);
  }
  i1a(segment, index) {
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var this_0 = this.i18_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellation.<anonymous>' call
        // Inline function 'kotlin.check' call
        // Inline function 'kotlinx.coroutines.index' call
        // Inline function 'kotlin.contracts.contract' call
        if (!((cur & 536870911) === 536870911)) {
          // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellation.<anonymous>.<anonymous>' call
          var message = 'invokeOnCancellation should be called at most once';
          throw IllegalStateException.m5(toString(message));
        }
        // Inline function 'kotlinx.coroutines.decisionAndIndex' call
        // Inline function 'kotlinx.coroutines.decision' call
        var upd = (cur >> 29 << 29) + index | 0;
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          break $l$block;
        }
      }
    }
    invokeOnCancellationImpl(this, segment);
  }
  c18(handler) {
    return invokeOnCancellation(this, new UserSupplied(handler));
  }
  l18(handler) {
    return invokeOnCancellationImpl(this, handler);
  }
  h1a(proposedUpdate, resumeMode, onCancellation) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.j18_1;
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.resumeImpl.<anonymous>' call
        var state = this_0.kotlinx$atomicfu$value;
        if (!(state == null) ? isInterface(state, NotCompleted) : false) {
          var update = resumedState(this, state, proposedUpdate, resumeMode, onCancellation, null);
          if (!this.j18_1.atomicfu$compareAndSet(state, update)) {
            break $l$block;
          }
          detachChildIfNonResuable(this);
          dispatchResume(this, resumeMode);
          return Unit_instance;
        } else {
          if (state instanceof CancelledContinuation) {
            if (state.m1a()) {
              if (onCancellation == null)
                null;
              else {
                // Inline function 'kotlin.let' call
                // Inline function 'kotlin.contracts.contract' call
                this.a1a(onCancellation, state.i15_1, proposedUpdate);
              }
              return Unit_instance;
            }
          }
        }
        alreadyResumedError(this, proposedUpdate);
      }
    }
  }
  f1a(proposedUpdate, resumeMode, onCancellation, $super) {
    onCancellation = onCancellation === VOID ? null : onCancellation;
    var tmp;
    if ($super === VOID) {
      this.h1a(proposedUpdate, resumeMode, onCancellation);
      tmp = Unit_instance;
    } else {
      tmp = $super.h1a.call(this, proposedUpdate, resumeMode, onCancellation);
    }
    return tmp;
  }
  s19() {
    var tmp0_elvis_lhs = _get_parentHandle__f8dcex(this);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var handle = tmp;
    handle.o18();
    this.k18_1.kotlinx$atomicfu$value = NonDisposableHandle_instance;
  }
  a18(value, idempotent, onCancellation) {
    return tryResumeImpl(this, value, idempotent, onCancellation);
  }
  b18(token) {
    // Inline function 'kotlinx.coroutines.assert' call
    dispatchResume(this, this.y18_1);
  }
  d18(_this__u8e3s4, value) {
    var tmp = this.g18_1;
    var dc = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp_0;
    if ((dc == null ? null : dc.q18_1) === _this__u8e3s4) {
      tmp_0 = 4;
    } else {
      tmp_0 = this.y18_1;
    }
    this.f1a(value, tmp_0);
  }
  d1a(state) {
    var tmp;
    if (state instanceof CompletedContinuation) {
      var tmp_0 = state.j19_1;
      tmp = (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
    } else {
      tmp = (state == null ? true : !(state == null)) ? state : THROW_CCE();
    }
    return tmp;
  }
  n1a(state) {
    var tmp20_safe_receiver = super.n1a(state);
    var tmp;
    if (tmp20_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.getExceptionalResult.<anonymous>' call
      tmp = recoverStackTrace(tmp20_safe_receiver, this.g18_1);
    }
    return tmp;
  }
  toString() {
    return this.o1a() + '(' + toDebugString(this.g18_1) + '){' + _get_stateDebugRepresentation__bf18u4(this) + '}@' + get_hexAddress(this);
  }
  o1a() {
    return 'CancellableContinuation';
  }
}
class UserSupplied {
  constructor(handler) {
    this.r1a_1 = handler;
  }
  n18(cause) {
    this.r1a_1(cause);
  }
  toString() {
    return 'CancelHandler.UserSupplied[' + get_classSimpleName(this.r1a_1) + '@' + get_hexAddress(this) + ']';
  }
}
class Active {
  toString() {
    return 'Active';
  }
}
class CompletedContinuation {
  constructor(result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    cancelHandler = cancelHandler === VOID ? null : cancelHandler;
    onCancellation = onCancellation === VOID ? null : onCancellation;
    idempotentResume = idempotentResume === VOID ? null : idempotentResume;
    cancelCause = cancelCause === VOID ? null : cancelCause;
    this.j19_1 = result;
    this.k19_1 = cancelHandler;
    this.l19_1 = onCancellation;
    this.m19_1 = idempotentResume;
    this.n19_1 = cancelCause;
  }
  p19() {
    return !(this.n19_1 == null);
  }
  x19(cont, cause) {
    var tmp21_safe_receiver = this.k19_1;
    if (tmp21_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      cont.o19(tmp21_safe_receiver, cause);
    }
    var tmp22_safe_receiver = this.l19_1;
    if (tmp22_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      cont.a1a(tmp22_safe_receiver, cause, this.j19_1);
    }
  }
  s1a(result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    return new CompletedContinuation(result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  }
  q19(result, cancelHandler, onCancellation, idempotentResume, cancelCause, $super) {
    result = result === VOID ? this.j19_1 : result;
    cancelHandler = cancelHandler === VOID ? this.k19_1 : cancelHandler;
    onCancellation = onCancellation === VOID ? this.l19_1 : onCancellation;
    idempotentResume = idempotentResume === VOID ? this.m19_1 : idempotentResume;
    cancelCause = cancelCause === VOID ? this.n19_1 : cancelCause;
    return $super === VOID ? this.s1a(result, cancelHandler, onCancellation, idempotentResume, cancelCause) : $super.s1a.call(this, result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  }
  toString() {
    return 'CompletedContinuation(result=' + toString_0(this.j19_1) + ', cancelHandler=' + toString_0(this.k19_1) + ', onCancellation=' + toString_0(this.l19_1) + ', idempotentResume=' + toString_0(this.m19_1) + ', cancelCause=' + toString_0(this.n19_1) + ')';
  }
  hashCode() {
    var result = this.j19_1 == null ? 0 : hashCode(this.j19_1);
    result = imul(result, 31) + (this.k19_1 == null ? 0 : hashCode(this.k19_1)) | 0;
    result = imul(result, 31) + (this.l19_1 == null ? 0 : hashCode(this.l19_1)) | 0;
    result = imul(result, 31) + (this.m19_1 == null ? 0 : hashCode(this.m19_1)) | 0;
    result = imul(result, 31) + (this.n19_1 == null ? 0 : hashCode(this.n19_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedContinuation))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedContinuation ? other : THROW_CCE();
    if (!equals(this.j19_1, tmp0_other_with_cast.j19_1))
      return false;
    if (!equals(this.k19_1, tmp0_other_with_cast.k19_1))
      return false;
    if (!equals(this.l19_1, tmp0_other_with_cast.l19_1))
      return false;
    if (!equals(this.m19_1, tmp0_other_with_cast.m19_1))
      return false;
    if (!equals(this.n19_1, tmp0_other_with_cast.n19_1))
      return false;
    return true;
  }
}
class LockFreeLinkedListNode {
  constructor() {
    this.f1b_1 = this;
    this.g1b_1 = this;
    this.h1b_1 = false;
  }
  i1b(node, permissionsBitmask) {
    var prev = this.g1b_1;
    var tmp;
    if (prev instanceof ListClosed) {
      tmp = ((prev.v1p_1 & permissionsBitmask) === 0 && prev.i1b(node, permissionsBitmask));
    } else {
      node.f1b_1 = this;
      node.g1b_1 = prev;
      prev.f1b_1 = node;
      this.g1b_1 = node;
      tmp = true;
    }
    return tmp;
  }
  k1d(forbiddenElementsBit) {
    this.i1b(new ListClosed(forbiddenElementsBit), forbiddenElementsBit);
  }
  j1b() {
    if (this.h1b_1)
      return false;
    var prev = this.g1b_1;
    var next = this.f1b_1;
    prev.f1b_1 = next;
    next.g1b_1 = prev;
    this.h1b_1 = true;
    return true;
  }
  k1b(node) {
    if (!(this.f1b_1 === this))
      return false;
    this.i1b(node, -2147483648);
    return true;
  }
}
class Incomplete {}
class JobNode extends LockFreeLinkedListNode {
  c1b() {
    var tmp = this.b1b_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('job');
    }
  }
  d15() {
    return true;
  }
  e1b() {
    return null;
  }
  o18() {
    return this.c1b().i16(this);
  }
  toString() {
    return get_classSimpleName(this) + '@' + get_hexAddress(this) + '[job@' + get_hexAddress(this.c1b()) + ']';
  }
}
class ChildContinuation extends JobNode {
  constructor(child) {
    super();
    this.x1a_1 = child;
  }
  n18(cause) {
    this.x1a_1.z19(this.x1a_1.b1a(this.c1b()));
  }
  d1b() {
    return true;
  }
}
class CompletableDeferredImpl extends JobSupport {
  constructor(parent) {
    super(true);
    this.y14(parent);
  }
  j16() {
    return true;
  }
  v17($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_await__mos7q6_0(this, $completion), $completion);
  }
  m1b(value) {
    return this.p1b(value);
  }
  l1b(exception) {
    return this.p1b(new CompletedExceptionally(exception));
  }
}
class CompletedExceptionally {
  constructor(cause, handled) {
    handled = handled === VOID ? false : handled;
    this.i15_1 = cause;
    this.j15_1 = atomic$boolean$1(handled);
  }
  k15() {
    return this.j15_1.kotlinx$atomicfu$value;
  }
  r19() {
    return this.j15_1.atomicfu$compareAndSet(false, true);
  }
  toString() {
    return get_classSimpleName(this) + '[' + this.i15_1.toString() + ']';
  }
}
class CancelledContinuation extends CompletedExceptionally {
  constructor(continuation, cause, handled) {
    super(cause == null ? CancellationException.he('Continuation ' + toString(continuation) + ' was cancelled normally') : cause, handled);
    this.l1a_1 = atomic$boolean$1(false);
  }
  m1a() {
    return this.l1a_1.atomicfu$compareAndSet(false, true);
  }
}
class CompletedWithCancellation {
  constructor(result, onCancellation) {
    this.q1b_1 = result;
    this.r1b_1 = onCancellation;
  }
  toString() {
    return 'CompletedWithCancellation(result=' + toString_0(this.q1b_1) + ', onCancellation=' + toString(this.r1b_1) + ')';
  }
  hashCode() {
    var result = this.q1b_1 == null ? 0 : hashCode(this.q1b_1);
    result = imul(result, 31) + hashCode(this.r1b_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedWithCancellation))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedWithCancellation ? other : THROW_CCE();
    if (!equals(this.q1b_1, tmp0_other_with_cast.q1b_1))
      return false;
    if (!equals(this.r1b_1, tmp0_other_with_cast.r1b_1))
      return false;
    return true;
  }
}
class Key extends AbstractCoroutineContextKey {
  constructor() {
    Key_instance_0 = null;
    var tmp = Key_instance;
    super(tmp, CoroutineDispatcher$Key$_init_$lambda_akl8b5);
    Key_instance_0 = this;
  }
}
class CoroutineDispatcher extends AbstractCoroutineContextElement {
  static t1b($box) {
    Key_getInstance();
    return this.co(Key_instance, $box);
  }
  u1b(context) {
    return true;
  }
  ud(continuation) {
    return new DispatchedContinuation(this, continuation);
  }
  vd(continuation) {
    var dispatched = continuation instanceof DispatchedContinuation ? continuation : THROW_CCE();
    dispatched.w1b();
  }
  toString() {
    return get_classSimpleName(this) + '@' + get_hexAddress(this);
  }
}
class Key_0 {}
class CoroutineStart extends Enum {
  t15(block, receiver, completion) {
    var tmp;
    switch (this.v3_1) {
      case 0:
        startCoroutineCancellable(block, receiver, completion);
        tmp = Unit_instance;
        break;
      case 2:
        startCoroutine(block, receiver, completion);
        tmp = Unit_instance;
        break;
      case 3:
        startCoroutineUndispatched(block, receiver, completion);
        tmp = Unit_instance;
        break;
      case 1:
        tmp = Unit_instance;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  a17() {
    return this === CoroutineStart_LAZY_getInstance();
  }
}
class Delay {}
function invokeOnTimeout(timeMillis, block, context) {
  return get_DefaultDelay().e1c(timeMillis, block, context);
}
class DelayWithTimeoutDiagnostics {}
class EventLoop extends CoroutineDispatcher {
  static k1c() {
    var $this = this.t1b();
    $this.h1c_1 = new Long(0, 0);
    $this.i1c_1 = false;
    $this.j1c_1 = null;
    return $this;
  }
  l1c() {
    var tmp0_elvis_lhs = this.j1c_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    var tmp1_elvis_lhs = queue.ol();
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return false;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var task = tmp_0;
    task.p1a();
    return true;
  }
  m1c(task) {
    var tmp0_elvis_lhs = this.j1c_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.also' call
      var this_0 = ArrayDeque.jl();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.EventLoop.dispatchUnconfined.<anonymous>' call
      this.j1c_1 = this_0;
      tmp = this_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    queue.ml(task);
  }
  n1c() {
    return this.h1c_1.g2(delta(this, true)) >= 0;
  }
  o1c() {
    var tmp27_safe_receiver = this.j1c_1;
    var tmp0_elvis_lhs = tmp27_safe_receiver == null ? null : tmp27_safe_receiver.a1();
    return tmp0_elvis_lhs == null ? true : tmp0_elvis_lhs;
  }
  p1c(unconfined) {
    this.h1c_1 = this.h1c_1.c4(delta(this, unconfined));
    if (!unconfined)
      this.i1c_1 = true;
  }
  q1c(unconfined) {
    this.h1c_1 = this.h1c_1.h2(delta(this, unconfined));
    if (this.h1c_1.g2(new Long(0, 0)) > 0)
      return Unit_instance;
    // Inline function 'kotlinx.coroutines.assert' call
    if (this.i1c_1) {
      this.r1c();
    }
  }
  r1c() {
  }
}
class ThreadLocalEventLoop {
  constructor() {
    ThreadLocalEventLoop_instance = this;
    this.s1c_1 = commonThreadLocal(new Symbol('ThreadLocalEventLoop'));
  }
  t1c() {
    var tmp0_elvis_lhs = this.s1c_1.v1c();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.also' call
      var this_0 = createEventLoop();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.ThreadLocalEventLoop.<get-eventLoop>.<anonymous>' call
      ThreadLocalEventLoop_getInstance().s1c_1.w1c(this_0);
      tmp = this_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
}
class CompletionHandlerException extends RuntimeException {
  static i19(message, cause) {
    var $this = this.ye(message, cause);
    captureStack($this, $this.h19_1);
    return $this;
  }
}
class CoroutinesInternalError extends Error_0 {
  static z1c(message, cause) {
    var $this = this.df(message, cause);
    captureStack($this, $this.y1c_1);
    return $this;
  }
}
class Key_1 {}
class NonDisposableHandle {
  w15() {
    return null;
  }
  o18() {
  }
  o16(cause) {
    return false;
  }
  toString() {
    return 'NonDisposableHandle';
  }
}
class DisposeOnCompletion extends JobNode {
  constructor(handle) {
    super();
    this.e1d_1 = handle;
  }
  n18(cause) {
    return this.e1d_1.o18();
  }
  d1b() {
    return false;
  }
}
class Empty {
  constructor(isActive) {
    this.f1d_1 = isActive;
  }
  d15() {
    return this.f1d_1;
  }
  e1b() {
    return null;
  }
  toString() {
    return 'Empty{' + (this.f1d_1 ? 'Active' : 'New') + '}';
  }
}
class LockFreeLinkedListHead extends LockFreeLinkedListNode {}
class NodeList extends LockFreeLinkedListHead {
  d15() {
    return true;
  }
  e1b() {
    return this;
  }
  j1d(state) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.NodeList.getString.<anonymous>' call
    this_0.ic('List{');
    this_0.ic(state);
    this_0.ic('}[');
    var first = true;
    // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListHead.forEach' call
    var cur = this.f1b_1;
    while (!equals(cur, this)) {
      // Inline function 'kotlinx.coroutines.NodeList.getString.<anonymous>.<anonymous>' call
      var node = cur;
      if (node instanceof JobNode) {
        if (first) {
          first = false;
        } else
          this_0.ic(', ');
        this_0.hc(node);
      }
      cur = cur.f1b_1;
    }
    this_0.ic(']');
    return this_0.toString();
  }
  toString() {
    return get_DEBUG() ? this.j1d('Active') : super.toString();
  }
}
class SynchronizedObject {}
class Finishing extends SynchronizedObject {
  constructor(list, isCompleting, rootCause) {
    super();
    this.l1d_1 = list;
    this.m1d_1 = atomic$boolean$1(isCompleting);
    this.n1d_1 = atomic$ref$1(rootCause);
    this.o1d_1 = atomic$ref$1(null);
  }
  e1b() {
    return this.l1d_1;
  }
  j1e(value) {
    this.m1d_1.kotlinx$atomicfu$value = value;
  }
  f1e() {
    return this.m1d_1.kotlinx$atomicfu$value;
  }
  k1e(value) {
    this.n1d_1.kotlinx$atomicfu$value = value;
  }
  i1e() {
    return this.n1d_1.kotlinx$atomicfu$value;
  }
  g1e() {
    return _get_exceptionsHolder__nhszp(this) === get_SEALED();
  }
  v1d() {
    return !(this.i1e() == null);
  }
  d15() {
    return this.i1e() == null;
  }
  w1d(proposedException) {
    var eh = _get_exceptionsHolder__nhszp(this);
    var tmp;
    if (eh == null) {
      tmp = allocateList(this);
    } else {
      if (eh instanceof Error) {
        // Inline function 'kotlin.also' call
        var this_0 = allocateList(this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.Finishing.sealLocked.<anonymous>' call
        this_0.f(eh);
        tmp = this_0;
      } else {
        if (eh instanceof ArrayList) {
          tmp = eh instanceof ArrayList ? eh : THROW_CCE();
        } else {
          var message = 'State is ' + toString_0(eh);
          throw IllegalStateException.m5(toString(message));
        }
      }
    }
    var list = tmp;
    var rootCause = this.i1e();
    if (rootCause == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      list.r7(0, rootCause);
    }
    if (!(proposedException == null) && !equals(proposedException, rootCause)) {
      list.f(proposedException);
    }
    _set_exceptionsHolder__tqm22h(this, get_SEALED());
    return list;
  }
  h1e(exception) {
    var rootCause = this.i1e();
    if (rootCause == null) {
      this.k1e(exception);
      return Unit_instance;
    }
    if (exception === rootCause)
      return Unit_instance;
    var eh = _get_exceptionsHolder__nhszp(this);
    if (eh == null) {
      _set_exceptionsHolder__tqm22h(this, exception);
    } else {
      if (eh instanceof Error) {
        if (exception === eh)
          return Unit_instance;
        // Inline function 'kotlin.apply' call
        var this_0 = allocateList(this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.Finishing.addExceptionLocked.<anonymous>' call
        this_0.f(eh);
        this_0.f(exception);
        _set_exceptionsHolder__tqm22h(this, this_0);
      } else {
        if (eh instanceof ArrayList) {
          (eh instanceof ArrayList ? eh : THROW_CCE()).f(exception);
        } else {
          var message = 'State is ' + toString_0(eh);
          throw IllegalStateException.m5(toString(message));
        }
      }
    }
  }
  toString() {
    return 'Finishing[cancelling=' + this.v1d() + ', completing=' + this.f1e() + ', rootCause=' + toString_0(this.i1e()) + ', exceptions=' + toString_0(_get_exceptionsHolder__nhszp(this)) + ', list=' + this.l1d_1.toString() + ']';
  }
}
class ChildCompletion extends JobNode {
  constructor(parent, state, child, proposedUpdate) {
    super();
    this.p1e_1 = parent;
    this.q1e_1 = state;
    this.r1e_1 = child;
    this.s1e_1 = proposedUpdate;
  }
  n18(cause) {
    continueCompleting(this.p1e_1, this.q1e_1, this.r1e_1, this.s1e_1);
  }
  d1b() {
    return false;
  }
}
class AwaitContinuation extends CancellableContinuationImpl {
  constructor(delegate, job) {
    super(delegate, 1);
    this.z1e_1 = job;
  }
  b1a(parent) {
    var state = this.z1e_1.x15();
    if (state instanceof Finishing) {
      var tmp73_safe_receiver = state.i1e();
      if (tmp73_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        return tmp73_safe_receiver;
      }
    }
    if (state instanceof CompletedExceptionally)
      return state.i15_1;
    return parent.b16();
  }
  o1a() {
    return 'AwaitContinuation';
  }
}
class JobSupport$_get_children_$slambda_k839f8 {
  constructor(this$0) {
    this.p1d_1 = this$0;
  }
  a1f($this$sequence, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$sequence, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.a1f(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  }
}
class InactiveNodeList {
  constructor(list) {
    this.e1e_1 = list;
  }
  e1b() {
    return this.e1e_1;
  }
  d15() {
    return false;
  }
  toString() {
    return get_DEBUG() ? this.e1e_1.j1d('New') : anyToString(this);
  }
}
class InvokeOnCompletion extends JobNode {
  constructor(handler) {
    super();
    this.f1f_1 = handler;
  }
  n18(cause) {
    return this.f1f_1(cause);
  }
  d1b() {
    return false;
  }
}
class InvokeOnCancelling extends JobNode {
  constructor(handler) {
    super();
    this.k1f_1 = handler;
    this.l1f_1 = atomic$boolean$1(false);
  }
  n18(cause) {
    if (this.l1f_1.atomicfu$compareAndSet(false, true))
      this.k1f_1(cause);
  }
  d1b() {
    return true;
  }
}
class ResumeOnCompletion extends JobNode {
  constructor(continuation) {
    super();
    this.q1f_1 = continuation;
  }
  n18(cause) {
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = this.q1f_1;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    this_0.id(tmp$ret$0);
    return Unit_instance;
  }
  d1b() {
    return false;
  }
}
class ChildHandleNode extends JobNode {
  constructor(childJob) {
    super();
    this.u1d_1 = childJob;
  }
  w15() {
    return this.c1b();
  }
  n18(cause) {
    return this.u1d_1.n16(this.c1b());
  }
  o16(cause) {
    return this.c1b().o16(cause);
  }
  d1b() {
    return true;
  }
}
class ResumeAwaitOnCompletion extends JobNode {
  constructor(continuation) {
    super();
    this.w1f_1 = continuation;
  }
  n18(cause) {
    var state = this.c1b().x15();
    // Inline function 'kotlinx.coroutines.assert' call
    if (state instanceof CompletedExceptionally) {
      // Inline function 'kotlin.coroutines.resumeWithException' call
      var this_0 = this.w1f_1;
      // Inline function 'kotlin.Companion.failure' call
      var exception = state.i15_1;
      var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception));
      this_0.id(tmp$ret$0);
    } else {
      // Inline function 'kotlin.coroutines.resume' call
      var this_1 = this.w1f_1;
      var tmp = unboxState(state);
      // Inline function 'kotlin.Companion.success' call
      var value = (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
      var tmp$ret$2 = _Result___init__impl__xyqfz8(value);
      this_1.id(tmp$ret$2);
    }
  }
  d1b() {
    return false;
  }
}
class IncompleteStateBox {
  constructor(state) {
    this.r1f_1 = state;
  }
}
class JobImpl extends JobSupport {
  constructor(parent) {
    super(true);
    this.y14(parent);
    this.z1f_1 = handlesExceptionF(this);
  }
  j16() {
    return true;
  }
  w16() {
    return this.z1f_1;
  }
}
class MainCoroutineDispatcher extends CoroutineDispatcher {
  static b1g() {
    return this.t1b();
  }
  toString() {
    var tmp0_elvis_lhs = this.d1g();
    return tmp0_elvis_lhs == null ? get_classSimpleName(this) + '@' + get_hexAddress(this) : tmp0_elvis_lhs;
  }
  d1g() {
    var main = Dispatchers_getInstance().c1c();
    if (this === main)
      return 'Dispatchers.Main';
    var tmp;
    try {
      tmp = main.c1g();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof UnsupportedOperationException) {
        var e = $p;
        tmp_0 = null;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    var immediate = tmp;
    if (this === immediate)
      return 'Dispatchers.Main.immediate';
    return null;
  }
}
class SupervisorJobImpl extends JobImpl {
  o16(cause) {
    return false;
  }
}
class TimeoutCancellationException extends CancellationException {
  static n1g(message, coroutine) {
    var $this = this.he(message);
    captureStack($this, $this.m1g_1);
    $this.l1g_1 = coroutine;
    return $this;
  }
  static o1g(message) {
    return this.n1g(message, null);
  }
}
class TimeoutCoroutine extends ScopeCoroutine {
  constructor(time, uCont) {
    super(uCont.gd(), uCont);
    this.t1g_1 = time;
  }
  p1a() {
    this.p16(TimeoutCancellationException_0(this.t1g_1, get_delay(this.b15_1), this));
  }
  p15() {
    return super.p15() + '(timeMillis=' + this.t1g_1.toString() + ')';
  }
}
class Unconfined extends CoroutineDispatcher {
  static v1g() {
    Unconfined_instance = null;
    var $this = this.t1b();
    Unconfined_instance = $this;
    return $this;
  }
  u1b(context) {
    return false;
  }
  v1b(context, block) {
    var yieldContext = context.td(Key_instance_3);
    if (!(yieldContext == null)) {
      yieldContext.x1g_1 = true;
      return Unit_instance;
    }
    throw UnsupportedOperationException.ua('Dispatchers.Unconfined.dispatch function can only be used by the yield function. If you wrap Unconfined dispatcher in your code, make sure you properly delegate isDispatchNeeded and dispatch calls.');
  }
  toString() {
    return 'Dispatchers.Unconfined';
  }
}
class Key_2 {}
class BufferOverflow extends Enum {}
class ConcurrentLinkedListNode {
  constructor(prev) {
    this.g1i_1 = atomic$ref$1(null);
    this.h1i_1 = atomic$ref$1(prev);
  }
  i1i() {
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
    var it = _get_nextOrClosed__w0gmuv(this);
    var tmp;
    if (it === get_CLOSED()) {
      // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.<get-next>.<anonymous>' call
      return null;
    } else {
      tmp = (it == null ? true : it instanceof ConcurrentLinkedListNode) ? it : THROW_CCE();
    }
    return tmp;
  }
  j1i(value) {
    return this.g1i_1.atomicfu$compareAndSet(null, value);
  }
  k1i() {
    return this.i1i() == null;
  }
  l1i() {
    return this.h1i_1.kotlinx$atomicfu$value;
  }
  m1i() {
    // Inline function 'kotlinx.atomicfu.AtomicRef.lazySet' call
    this.h1i_1.kotlinx$atomicfu$value = null;
  }
  n1i() {
    return this.g1i_1.atomicfu$compareAndSet(null, get_CLOSED());
  }
  y6() {
    // Inline function 'kotlinx.coroutines.assert' call
    if (this.k1i())
      return Unit_instance;
    $l$loop_0: while (true) {
      var prev = _get_aliveSegmentLeft__mr4ndu(this);
      var next = _get_aliveSegmentRight__7ulr0b(this);
      $l$block: {
        // Inline function 'kotlinx.atomicfu.update' call
        var this_0 = next.h1i_1;
        while (true) {
          var cur = this_0.kotlinx$atomicfu$value;
          // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.remove.<anonymous>' call
          var upd = cur === null ? null : prev;
          if (this_0.atomicfu$compareAndSet(cur, upd)) {
            break $l$block;
          }
        }
      }
      if (!(prev === null))
        prev.g1i_1.kotlinx$atomicfu$value = next;
      if (next.d1i() && !next.k1i())
        continue $l$loop_0;
      if (!(prev === null) && prev.d1i())
        continue $l$loop_0;
      return Unit_instance;
    }
  }
}
class Segment extends ConcurrentLinkedListNode {
  constructor(id, prev, pointers) {
    super(prev);
    this.c19_1 = id;
    this.d19_1 = atomic$int$1(pointers << 16);
  }
  d1i() {
    return this.d19_1.kotlinx$atomicfu$value === this.f1h() && !this.k1i();
  }
  e1i() {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.internal.addConditionally' call
      var this_0 = this.d19_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'kotlinx.coroutines.internal.Segment.tryIncPointers.<anonymous>' call
        if (!(!(cur === this.f1h()) || this.k1i())) {
          tmp$ret$1 = false;
          break $l$block_0;
        }
        if (this_0.atomicfu$compareAndSet(cur, cur + 65536 | 0)) {
          tmp$ret$1 = true;
          break $l$block_0;
        }
      }
    }
    return tmp$ret$1;
  }
  f1i() {
    return this.d19_1.atomicfu$addAndGet(-65536) === this.f1h() && !this.k1i();
  }
  c1i() {
    if (this.d19_1.atomicfu$incrementAndGet() === this.f1h()) {
      this.y6();
    }
  }
}
class ChannelSegment extends Segment {
  constructor(id, prev, channel, pointers) {
    super(id, prev, pointers);
    this.c1h_1 = channel;
    this.d1h_1 = atomicfu$AtomicRefArray$ofNulls(imul(get_SEGMENT_SIZE(), 2));
  }
  e1h() {
    return ensureNotNull(this.c1h_1);
  }
  f1h() {
    return get_SEGMENT_SIZE();
  }
  g1h(index, element) {
    setElementLazy(this, index, element);
  }
  h1h(index) {
    var tmp = this.d1h_1.atomicfu$get(imul(index, 2)).kotlinx$atomicfu$value;
    return (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
  }
  i1h(index) {
    // Inline function 'kotlin.also' call
    var this_0 = this.h1h(index);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.channels.ChannelSegment.retrieveElement.<anonymous>' call
    this.j1h(index);
    return this_0;
  }
  j1h(index) {
    setElementLazy(this, index, null);
  }
  k1h(index) {
    return this.d1h_1.atomicfu$get(imul(index, 2) + 1 | 0).kotlinx$atomicfu$value;
  }
  l1h(index, value) {
    this.d1h_1.atomicfu$get(imul(index, 2) + 1 | 0).kotlinx$atomicfu$value = value;
  }
  m1h(index, from, to) {
    return this.d1h_1.atomicfu$get(imul(index, 2) + 1 | 0).atomicfu$compareAndSet(from, to);
  }
  n1h(index, update) {
    return this.d1h_1.atomicfu$get(imul(index, 2) + 1 | 0).atomicfu$getAndSet(update);
  }
  e19(index, cause, context) {
    var isSender = index >= get_SEGMENT_SIZE();
    var index_0 = isSender ? index - get_SEGMENT_SIZE() | 0 : index;
    var element = this.h1h(index_0);
    $l$loop: while (true) {
      var cur = this.k1h(index_0);
      var tmp;
      if (!(cur == null) ? isInterface(cur, Waiter) : false) {
        tmp = true;
      } else {
        tmp = cur instanceof WaiterEB;
      }
      if (tmp) {
        var update = isSender ? get_INTERRUPTED_SEND() : get_INTERRUPTED_RCV();
        if (this.m1h(index_0, cur, update)) {
          this.j1h(index_0);
          this.a1i(index_0, !isSender);
          if (isSender) {
            var tmp109_safe_receiver = this.e1h().p1h_1;
            if (tmp109_safe_receiver == null)
              null;
            else {
              callUndeliveredElement(tmp109_safe_receiver, element, context);
            }
          }
          return Unit_instance;
        }
      } else {
        if (cur === get_INTERRUPTED_SEND() || cur === get_INTERRUPTED_RCV()) {
          this.j1h(index_0);
          if (isSender) {
            var tmp110_safe_receiver = this.e1h().p1h_1;
            if (tmp110_safe_receiver == null)
              null;
            else {
              callUndeliveredElement(tmp110_safe_receiver, element, context);
            }
          }
          return Unit_instance;
        } else {
          if (cur === get_RESUMING_BY_EB() || cur === get_RESUMING_BY_RCV())
            continue $l$loop;
          else {
            if (cur === get_DONE_RCV() || cur === get_BUFFERED())
              return Unit_instance;
            else {
              if (cur === get_CHANNEL_CLOSED())
                return Unit_instance;
              else {
                var message = 'unexpected state: ' + toString_0(cur);
                throw IllegalStateException.m5(toString(message));
              }
            }
          }
        }
      }
    }
  }
  a1i(index, receiver) {
    if (receiver) {
      var tmp = this.e1h();
      // Inline function 'kotlin.Long.plus' call
      // Inline function 'kotlin.Long.times' call
      var this_0 = this.c19_1;
      var other = get_SEGMENT_SIZE();
      var tmp$ret$1 = this_0.d4(toLong(other)).c4(toLong(index));
      tmp.b1i(tmp$ret$1);
    }
    this.c1i();
  }
}
class SendBroadcast {}
class BufferedChannelIterator {
  v1i(element) {
    var cont = ensureNotNull(this.t1i_1);
    this.t1i_1 = null;
    this.s1i_1 = element;
    var tmp98_safe_receiver = this.u1i_1.p1h_1;
    return tryResume0(cont, true, tmp98_safe_receiver == null ? null : bindCancellationFun(tmp98_safe_receiver, this.u1i_1, element));
  }
  i1j() {
    var cont = ensureNotNull(this.t1i_1);
    this.t1i_1 = null;
    this.s1i_1 = get_CHANNEL_CLOSED();
    var cause = this.u1i_1.j1j();
    if (cause == null) {
      // Inline function 'kotlin.coroutines.resume' call
      // Inline function 'kotlin.Companion.success' call
      var tmp$ret$0 = _Result___init__impl__xyqfz8(false);
      cont.id(tmp$ret$0);
    } else {
      // Inline function 'kotlin.coroutines.resumeWithException' call
      // Inline function 'kotlin.Companion.failure' call
      var exception = recoverStackTrace(cause, cont);
      var tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(exception));
      cont.id(tmp$ret$2);
    }
  }
}
class BufferedChannel {
  constructor(capacity, onUndeliveredElement) {
    onUndeliveredElement = onUndeliveredElement === VOID ? null : onUndeliveredElement;
    this.o1h_1 = capacity;
    this.p1h_1 = onUndeliveredElement;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.o1h_1 >= 0)) {
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.<anonymous>' call
      var message = 'Invalid channel capacity: ' + this.o1h_1 + ', should be >=0';
      throw IllegalArgumentException.l(toString(message));
    }
    this.q1h_1 = atomic$long$1(new Long(0, 0));
    this.r1h_1 = atomic$long$1(new Long(0, 0));
    this.s1h_1 = atomic$long$1(initialBufferEnd(this.o1h_1));
    this.t1h_1 = atomic$long$1(_get_bufferEndCounter__2d4hee(this));
    var firstSegment = new ChannelSegment(new Long(0, 0), null, this, 3);
    this.u1h_1 = atomic$ref$1(firstSegment);
    this.v1h_1 = atomic$ref$1(firstSegment);
    var tmp = this;
    var tmp_0;
    if (_get_isRendezvousOrUnlimited__3mdufi(this)) {
      var tmp_1 = get_NULL_SEGMENT();
      tmp_0 = tmp_1 instanceof ChannelSegment ? tmp_1 : THROW_CCE();
    } else {
      tmp_0 = firstSegment;
    }
    tmp.w1h_1 = atomic$ref$1(tmp_0);
    var tmp_2 = this;
    var tmp_3;
    if (this.p1h_1 == null) {
      tmp_3 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.onUndeliveredElementReceiveCancellationConstructor.<anonymous>' call
      tmp_3 = BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda(this);
    }
    tmp_2.x1h_1 = tmp_3;
    this.y1h_1 = atomic$ref$1(get_NO_CLOSE_CAUSE());
    this.z1h_1 = atomic$ref$1(null);
  }
  b1j() {
    // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
    return this.q1h_1.kotlinx$atomicfu$value.n4(new Long(-1, 268435455));
  }
  o1i() {
    return this.r1h_1.kotlinx$atomicfu$value;
  }
  w1j(element, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_send__qhx0g0(this, element, $completion), $completion);
  }
  x1j(element) {
    if (shouldSendSuspend0(this, this.q1h_1.kotlinx$atomicfu$value))
      return Companion_getInstance_0().y1j();
    var tmp$ret$4;
    $l$block_4: {
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl' call
      var waiter = get_INTERRUPTED_SEND();
      var segment = this.u1h_1.kotlinx$atomicfu$value;
      $l$loop_0: while (true) {
        var sendersAndCloseStatusCur = this.q1h_1.atomicfu$getAndIncrement$long();
        // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
        var s = sendersAndCloseStatusCur.n4(new Long(-1, 268435455));
        var closed = _get_isClosedForSend0__kxgf9m(sendersAndCloseStatusCur, this);
        // Inline function 'kotlin.Long.div' call
        var other = get_SEGMENT_SIZE();
        var id = s.e4(toLong(other));
        // Inline function 'kotlin.Long.rem' call
        var other_0 = get_SEGMENT_SIZE();
        var i = s.f4(toLong(other_0)).q2();
        if (!segment.c19_1.equals(id)) {
          var tmp0_elvis_lhs = findSegmentSend(this, id, segment);
          var tmp;
          if (tmp0_elvis_lhs == null) {
            var tmp_0;
            if (closed) {
              // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
              tmp$ret$4 = Companion_getInstance_0().s1j(this.p1i());
              break $l$block_4;
            } else {
              continue $l$loop_0;
            }
          } else {
            tmp = tmp0_elvis_lhs;
          }
          segment = tmp;
        }
        switch (updateCellSend(this, segment, i, element, s, waiter, closed)) {
          case 0:
            segment.m1i();
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call

            tmp$ret$4 = Companion_getInstance_0().x1i(Unit_instance);
            break $l$block_4;
          case 1:
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call

            tmp$ret$4 = Companion_getInstance_0().x1i(Unit_instance);
            break $l$block_4;
          case 2:
            if (closed) {
              segment.c1i();
              // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
              tmp$ret$4 = Companion_getInstance_0().s1j(this.p1i());
              break $l$block_4;
            }

            var tmp83_safe_receiver = (!(waiter == null) ? isInterface(waiter, Waiter) : false) ? waiter : null;
            if (tmp83_safe_receiver == null)
              null;
            else {
              prepareSenderForSuspension(tmp83_safe_receiver, this, segment, i);
            }

            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call

            segment.c1i();
            tmp$ret$4 = Companion_getInstance_0().y1j();
            break $l$block_4;
          case 4:
            if (s.g2(this.o1i()) < 0) {
              segment.m1i();
            }

            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call

            tmp$ret$4 = Companion_getInstance_0().s1j(this.p1i());
            break $l$block_4;
          case 5:
            segment.m1i();
            continue $l$loop_0;
          case 3:
            var message = 'unexpected';
            throw IllegalStateException.m5(toString(message));
        }
      }
    }
    return tmp$ret$4;
  }
  z1j(element) {
    var tmp$ret$3;
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl' call
    var waiter = get_BUFFERED();
    var segment = this.u1h_1.kotlinx$atomicfu$value;
    $l$loop_0: while (true) {
      var sendersAndCloseStatusCur = this.q1h_1.atomicfu$getAndIncrement$long();
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
      var s = sendersAndCloseStatusCur.n4(new Long(-1, 268435455));
      var closed = _get_isClosedForSend0__kxgf9m(sendersAndCloseStatusCur, this);
      // Inline function 'kotlin.Long.div' call
      var other = get_SEGMENT_SIZE();
      var id = s.e4(toLong(other));
      // Inline function 'kotlin.Long.rem' call
      var other_0 = get_SEGMENT_SIZE();
      var i = s.f4(toLong(other_0)).q2();
      if (!segment.c19_1.equals(id)) {
        var tmp0_elvis_lhs = findSegmentSend(this, id, segment);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          var tmp_0;
          if (closed) {
            return Companion_getInstance_0().s1j(this.p1i());
          } else {
            continue $l$loop_0;
          }
        } else {
          tmp = tmp0_elvis_lhs;
        }
        segment = tmp;
      }
      switch (updateCellSend(this, segment, i, element, s, waiter, closed)) {
        case 0:
          segment.m1i();
          return Companion_getInstance_0().x1i(Unit_instance);
        case 1:
          return Companion_getInstance_0().x1i(Unit_instance);
        case 2:
          if (closed) {
            segment.c1i();
            return Companion_getInstance_0().s1j(this.p1i());
          }

          var tmp83_safe_receiver = (!(waiter == null) ? isInterface(waiter, Waiter) : false) ? waiter : null;
          if (tmp83_safe_receiver == null)
            null;
          else {
            prepareSenderForSuspension(tmp83_safe_receiver, this, segment, i);
          }

          // Inline function 'kotlin.Long.plus' call

          // Inline function 'kotlin.Long.times' call

          var this_0 = segment.c19_1;
          var other_1 = get_SEGMENT_SIZE();
          var tmp$ret$5 = this_0.d4(toLong(other_1)).c4(toLong(i));
          this.q1j(tmp$ret$5);
          return Companion_getInstance_0().x1i(Unit_instance);
        case 4:
          if (s.g2(this.o1i()) < 0) {
            segment.m1i();
          }

          return Companion_getInstance_0().s1j(this.p1i());
        case 5:
          segment.m1i();
          continue $l$loop_0;
        case 3:
          var message = 'unexpected';
          throw IllegalStateException.m5(toString(message));
      }
    }
    return tmp$ret$3;
  }
  c1j() {
  }
  q1i() {
  }
  a1k($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_receive__aoggq9(this, $completion), $completion);
  }
  q1j(globalCellIndex) {
    // Inline function 'kotlinx.coroutines.assert' call
    var segment = this.v1h_1.kotlinx$atomicfu$value;
    $l$loop_0: while (true) {
      var r = this.r1h_1.kotlinx$atomicfu$value;
      // Inline function 'kotlin.math.max' call
      // Inline function 'kotlin.Long.plus' call
      var other = this.o1h_1;
      var a = r.c4(toLong(other));
      var b = _get_bufferEndCounter__2d4hee(this);
      var tmp$ret$1 = a.g2(b) >= 0 ? a : b;
      if (globalCellIndex.g2(tmp$ret$1) < 0)
        return Unit_instance;
      // Inline function 'kotlin.Long.plus' call
      var tmp$ret$2 = r.c4(toLong(1));
      if (!this.r1h_1.atomicfu$compareAndSet(r, tmp$ret$2))
        continue $l$loop_0;
      // Inline function 'kotlin.Long.div' call
      var other_0 = get_SEGMENT_SIZE();
      var id = r.e4(toLong(other_0));
      // Inline function 'kotlin.Long.rem' call
      var other_1 = get_SEGMENT_SIZE();
      var i = r.f4(toLong(other_1)).q2();
      if (!segment.c19_1.equals(id)) {
        var tmp0_elvis_lhs = findSegmentReceive(this, id, segment);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          continue $l$loop_0;
        } else {
          tmp = tmp0_elvis_lhs;
        }
        segment = tmp;
      }
      var updCellResult = updateCellReceive(this, segment, i, r, null);
      if (updCellResult === _get_FAILED_$accessor$yt74tm_h47uk8()) {
        if (r.g2(this.b1j()) < 0) {
          segment.m1i();
        }
      } else {
        segment.m1i();
        var tmp90_safe_receiver = this.p1h_1;
        var tmp_0;
        if (tmp90_safe_receiver == null) {
          tmp_0 = null;
        } else {
          tmp_0 = callUndeliveredElementCatchingException(tmp90_safe_receiver, (updCellResult == null ? true : !(updCellResult == null)) ? updCellResult : THROW_CCE());
        }
        var tmp91_safe_receiver = tmp_0;
        if (tmp91_safe_receiver == null)
          null;
        else {
          // Inline function 'kotlin.let' call
          // Inline function 'kotlin.contracts.contract' call
          throw tmp91_safe_receiver;
        }
      }
    }
  }
  b1i(globalIndex) {
    if (_get_isRendezvousOrUnlimited__3mdufi(this))
      return Unit_instance;
    while (_get_bufferEndCounter__2d4hee(this).g2(globalIndex) <= 0) {
    }
    // Inline function 'kotlin.repeat' call
    var times = get_EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS();
    // Inline function 'kotlin.contracts.contract' call
    var inductionVariable = 0;
    if (inductionVariable < times)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.waitExpandBufferCompletion.<anonymous>' call
        var b = _get_bufferEndCounter__2d4hee(this);
        // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
        var ebCompleted = this.t1h_1.kotlinx$atomicfu$value.n4(new Long(-1, 1073741823));
        if (b.equals(ebCompleted) && b.equals(_get_bufferEndCounter__2d4hee(this)))
          return Unit_instance;
      }
       while (inductionVariable < times);
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var this_0 = this.t1h_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.waitExpandBufferCompletion.<anonymous>' call
        // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
        var tmp$ret$1 = cur.n4(new Long(-1, 1073741823));
        var upd = constructEBCompletedAndPauseFlag(tmp$ret$1, true);
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          break $l$block;
        }
      }
    }
    while (true) {
      var b_0 = _get_bufferEndCounter__2d4hee(this);
      var ebCompletedAndBit = this.t1h_1.kotlinx$atomicfu$value;
      // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
      var ebCompleted_0 = ebCompletedAndBit.n4(new Long(-1, 1073741823));
      // Inline function 'kotlinx.coroutines.channels.ebPauseExpandBuffers' call
      var pauseExpandBuffers = !ebCompletedAndBit.n4(new Long(0, 1073741824)).equals(new Long(0, 0));
      if (b_0.equals(ebCompleted_0) && b_0.equals(_get_bufferEndCounter__2d4hee(this))) {
        $l$block_0: {
          // Inline function 'kotlinx.atomicfu.update' call
          var this_1 = this.t1h_1;
          while (true) {
            var cur_0 = this_1.kotlinx$atomicfu$value;
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.waitExpandBufferCompletion.<anonymous>' call
            // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
            var tmp$ret$6 = cur_0.n4(new Long(-1, 1073741823));
            var upd_0 = constructEBCompletedAndPauseFlag(tmp$ret$6, false);
            if (this_1.atomicfu$compareAndSet(cur_0, upd_0)) {
              break $l$block_0;
            }
          }
        }
        return Unit_instance;
      }
      if (!pauseExpandBuffers) {
        this.t1h_1.atomicfu$compareAndSet(ebCompletedAndBit, constructEBCompletedAndPauseFlag(ebCompleted_0, true));
      }
    }
  }
  j1j() {
    var tmp = this.y1h_1.kotlinx$atomicfu$value;
    return (tmp == null ? true : tmp instanceof Error) ? tmp : THROW_CCE();
  }
  p1i() {
    var tmp0_elvis_lhs = this.j1j();
    return tmp0_elvis_lhs == null ? ClosedSendChannelException.f1k('Channel was closed') : tmp0_elvis_lhs;
  }
  r1j() {
    return false;
  }
  p1j() {
    return _get_isClosedForSend0__kxgf9m(this.q1h_1.kotlinx$atomicfu$value, this);
  }
  a1j() {
    return _get_isClosedForReceive0__f7qknl(this.q1h_1.kotlinx$atomicfu$value, this);
  }
  t1j() {
    $l$loop: while (true) {
      var segment = this.v1h_1.kotlinx$atomicfu$value;
      var r = this.o1i();
      var s = this.b1j();
      if (s.g2(r) <= 0)
        return false;
      // Inline function 'kotlin.Long.div' call
      var other = get_SEGMENT_SIZE();
      var id = r.e4(toLong(other));
      if (!segment.c19_1.equals(id)) {
        var tmp0_elvis_lhs = findSegmentReceive(this, id, segment);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          var tmp_0;
          if (this.v1h_1.kotlinx$atomicfu$value.c19_1.g2(id) < 0) {
            return false;
          } else {
            continue $l$loop;
          }
        } else {
          tmp = tmp0_elvis_lhs;
        }
        segment = tmp;
      }
      segment.m1i();
      // Inline function 'kotlin.Long.rem' call
      var other_0 = get_SEGMENT_SIZE();
      var i = r.f4(toLong(other_0)).q2();
      if (isCellNonEmpty(this, segment, i, r))
        return true;
      // Inline function 'kotlin.Long.plus' call
      var tmp$ret$2 = r.c4(toLong(1));
      this.r1h_1.atomicfu$compareAndSet(r, tmp$ret$2);
    }
  }
  toString() {
    var sb = StringBuilder.h();
    // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
    var tmp103_subject = this.q1h_1.kotlinx$atomicfu$value.l4(60).q2();
    if (tmp103_subject === 2) {
      sb.ic('closed,');
    } else if (tmp103_subject === 3) {
      sb.ic('cancelled,');
    }
    sb.ic('capacity=' + this.o1h_1 + ',');
    sb.ic('data=[');
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlin.collections.minBy' call
      // Inline function 'kotlin.collections.filter' call
      // Inline function 'kotlin.collections.filterTo' call
      var this_0 = listOf([this.v1h_1.kotlinx$atomicfu$value, this.u1h_1.kotlinx$atomicfu$value, this.w1h_1.kotlinx$atomicfu$value]);
      var destination = ArrayList.m1();
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.toString.<anonymous>' call
        if (!(element === get_NULL_SEGMENT())) {
          destination.f(element);
        }
      }
      var iterator = destination.f1();
      if (!iterator.g1())
        throw NoSuchElementException.c7();
      var minElem = iterator.h1();
      if (!iterator.g1()) {
        tmp$ret$4 = minElem;
        break $l$block;
      }
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.toString.<anonymous>' call
      var minValue = minElem.c19_1;
      do {
        var e = iterator.h1();
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.toString.<anonymous>' call
        var v = e.c19_1;
        if (compareTo(minValue, v) > 0) {
          minElem = e;
          minValue = v;
        }
      }
       while (iterator.g1());
      tmp$ret$4 = minElem;
    }
    var firstSegment = tmp$ret$4;
    var r = this.o1i();
    var s = this.b1j();
    var segment = firstSegment;
    append_elements: while (true) {
      var inductionVariable = 0;
      var last_0 = get_SEGMENT_SIZE();
      if (inductionVariable < last_0)
        process_cell: do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlin.Long.plus' call
          // Inline function 'kotlin.Long.times' call
          var this_1 = segment.c19_1;
          var other = get_SEGMENT_SIZE();
          var globalCellIndex = this_1.d4(toLong(other)).c4(toLong(i));
          if (globalCellIndex.g2(s) >= 0 && globalCellIndex.g2(r) >= 0)
            break append_elements;
          var cellState = segment.k1h(i);
          var element_0 = segment.h1h(i);
          var tmp;
          if (!(cellState == null) ? isInterface(cellState, CancellableContinuation) : false) {
            tmp = globalCellIndex.g2(r) < 0 && globalCellIndex.g2(s) >= 0 ? 'receive' : globalCellIndex.g2(s) < 0 && globalCellIndex.g2(r) >= 0 ? 'send' : 'cont';
          } else {
            if (!(cellState == null) ? isInterface(cellState, SelectInstance) : false) {
              tmp = globalCellIndex.g2(r) < 0 && globalCellIndex.g2(s) >= 0 ? 'onReceive' : globalCellIndex.g2(s) < 0 && globalCellIndex.g2(r) >= 0 ? 'onSend' : 'select';
            } else {
              if (cellState instanceof ReceiveCatching) {
                tmp = 'receiveCatching';
              } else {
                if (cellState instanceof SendBroadcast) {
                  tmp = 'sendBroadcast';
                } else {
                  if (cellState instanceof WaiterEB) {
                    tmp = 'EB(' + cellState.toString() + ')';
                  } else {
                    if (equals(cellState, get_RESUMING_BY_RCV()) || equals(cellState, get_RESUMING_BY_EB())) {
                      tmp = 'resuming_sender';
                    } else {
                      if (cellState == null || (equals(cellState, get_IN_BUFFER()) || equals(cellState, get_DONE_RCV())) || (equals(cellState, get_POISONED()) || equals(cellState, get_INTERRUPTED_RCV()) || (equals(cellState, get_INTERRUPTED_SEND()) || equals(cellState, get_CHANNEL_CLOSED())))) {
                        continue process_cell;
                      } else {
                        tmp = toString(cellState);
                      }
                    }
                  }
                }
              }
            }
          }
          var cellStateString = tmp;
          if (!(element_0 == null)) {
            sb.ic('(' + cellStateString + ',' + toString_0(element_0) + '),');
          } else {
            sb.ic(cellStateString + ',');
          }
        }
         while (inductionVariable < last_0);
      var tmp0_elvis_lhs = segment.i1i();
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        break append_elements;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      segment = tmp_0;
    }
    if (last(sb) === _Char___init__impl__6a9atx(44)) {
      sb.xh(sb.a() - 1 | 0);
    }
    sb.ic(']');
    return sb.toString();
  }
}
class WaiterEB {
  constructor(waiter) {
    this.r1i_1 = waiter;
  }
  toString() {
    return 'WaiterEB(' + toString(this.r1i_1) + ')';
  }
}
class ReceiveCatching {}
class Factory {
  constructor() {
    Factory_instance = this;
    this.g1k_1 = 2147483647;
    this.h1k_1 = 0;
    this.i1k_1 = -1;
    this.j1k_1 = -2;
    this.k1k_1 = -3;
    this.l1k_1 = 'kotlinx.coroutines.channels.defaultBuffer';
    this.m1k_1 = systemProp('kotlinx.coroutines.channels.defaultBuffer', 64, 1, 2147483646);
  }
}
class Failed {
  toString() {
    return 'Failed';
  }
}
class Closed extends Failed {
  constructor(cause) {
    super();
    this.n1k_1 = cause;
  }
  equals(other) {
    var tmp;
    if (other instanceof Closed) {
      tmp = equals(this.n1k_1, other.n1k_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    // Inline function 'kotlin.hashCode' call
    var tmp0_safe_receiver = this.n1k_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  }
  toString() {
    return 'Closed(' + toString_0(this.n1k_1) + ')';
  }
}
class Companion {
  constructor() {
    Companion_instance_0 = this;
    this.w1i_1 = new Failed();
  }
  x1i(value) {
    return _ChannelResult___init__impl__siwsuf(value);
  }
  y1j() {
    return _ChannelResult___init__impl__siwsuf(this.w1i_1);
  }
  s1j(cause) {
    return _ChannelResult___init__impl__siwsuf(new Closed(cause));
  }
}
class ChannelResult {
  constructor(holder) {
    Companion_getInstance_0();
    this.v1j_1 = holder;
  }
  toString() {
    return ChannelResult__toString_impl_rrcqu7(this.v1j_1);
  }
  hashCode() {
    return ChannelResult__hashCode_impl_lilec2(this.v1j_1);
  }
  equals(other) {
    return ChannelResult__equals_impl_f471ri(this.v1j_1, other);
  }
}
class ClosedSendChannelException extends IllegalStateException {
  static f1k(message) {
    var $this = this.m5(message);
    captureStack($this, $this.e1k_1);
    return $this;
  }
}
class ClosedReceiveChannelException extends NoSuchElementException {
  static o1j(message) {
    var $this = this.p(message);
    captureStack($this, $this.n1j_1);
    return $this;
  }
}
class ConflatedBufferedChannel extends BufferedChannel {
  constructor(capacity, onBufferOverflow, onUndeliveredElement) {
    onUndeliveredElement = onUndeliveredElement === VOID ? null : onUndeliveredElement;
    super(capacity, onUndeliveredElement);
    this.a1l_1 = capacity;
    this.b1l_1 = onBufferOverflow;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(this.b1l_1 === BufferOverflow_SUSPEND_getInstance())) {
      // Inline function 'kotlinx.coroutines.channels.ConflatedBufferedChannel.<anonymous>' call
      var message = 'This implementation does not support suspension for senders, use ' + getKClass(BufferedChannel).xf() + ' instead';
      throw IllegalArgumentException.l(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.a1l_1 >= 1)) {
      // Inline function 'kotlinx.coroutines.channels.ConflatedBufferedChannel.<anonymous>' call
      var message_0 = 'Buffered channel capacity must be at least 1, but ' + this.a1l_1 + ' was specified';
      throw IllegalArgumentException.l(toString(message_0));
    }
  }
  r1j() {
    return this.b1l_1.equals(BufferOverflow_DROP_OLDEST_getInstance());
  }
  w1j(element, $completion) {
    // Inline function 'kotlinx.coroutines.channels.onClosed' call
    var this_0 = trySendImpl(this, element, true);
    var tmp = _ChannelResult___get_holder__impl__pm9gzw(this_0);
    if (tmp instanceof Closed) {
      // Inline function 'kotlinx.coroutines.channels.ConflatedBufferedChannel.send.<anonymous>' call
      ChannelResult__exceptionOrNull_impl_16ei30(this_0);
      var tmp116_safe_receiver = this.p1h_1;
      var tmp117_safe_receiver = tmp116_safe_receiver == null ? null : callUndeliveredElementCatchingException(tmp116_safe_receiver, element);
      if (tmp117_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        addSuppressed(tmp117_safe_receiver, this.p1i());
        throw tmp117_safe_receiver;
      }
      throw this.p1i();
    }
  }
  x1j(element) {
    return trySendImpl(this, element, false);
  }
}
class AbstractFlow {
  e1l(collector, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_collect__dlomyy_0(this, collector, $completion), $completion);
  }
}
class SafeFlow extends AbstractFlow {
  constructor(block) {
    super();
    this.c1l_1 = block;
  }
  d1l(collector, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_collectSafely__fg77v4(this, collector, $completion), $completion);
  }
}
class _no_name_provided__qut3iv {
  constructor($this_asFlow) {
    this.f1l_1 = $this_asFlow;
  }
  h1l(collector, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_collect__dlomyy(this, collector, $completion), $completion);
  }
  e1l(collector, $completion) {
    return this.h1l(collector, $completion);
  }
}
class FlowCollector {}
class sam$kotlinx_coroutines_flow_FlowCollector$0 {
  constructor(function_0) {
    this.m1l_1 = function_0;
  }
  g1l(value, $completion) {
    return this.m1l_1(value, $completion);
  }
}
class toCollection$slambda {
  constructor($destination) {
    this.n1l_1 = $destination;
  }
  o1l(value, $completion) {
    this.n1l_1.f(value);
  }
  qe(p1, $completion) {
    return this.o1l((p1 == null ? true : !(p1 == null)) ? p1 : THROW_CCE(), $completion);
  }
}
class SegmentOrClosed {
  constructor(value) {
    this.u1j_1 = value;
  }
  toString() {
    return SegmentOrClosed__toString_impl_pzb2an(this.u1j_1);
  }
  hashCode() {
    return SegmentOrClosed__hashCode_impl_4855hs(this.u1j_1);
  }
  equals(other) {
    return SegmentOrClosed__equals_impl_6erq1g(this.u1j_1, other);
  }
}
class ExceptionSuccessfullyProcessed extends Exception {}
class DispatchedContinuation extends DispatchedTask {
  constructor(dispatcher, continuation) {
    super(-1);
    this.q18_1 = dispatcher;
    this.r18_1 = continuation;
    this.s18_1 = get_UNDEFINED();
    this.t18_1 = threadContextElements(this.gd());
    this.u18_1 = atomic$ref$1(null);
  }
  gd() {
    return this.r18_1.gd();
  }
  x18() {
    return !(this.u18_1.kotlinx$atomicfu$value == null);
  }
  t1l() {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.u18_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.awaitReusability.<anonymous>' call
      if (!(this_0.kotlinx$atomicfu$value === get_REUSABLE_CLAIMED()))
        return Unit_instance;
    }
  }
  w1b() {
    this.t1l();
    var tmp148_safe_receiver = _get_reusableCancellableContinuation__9qex09(this);
    if (tmp148_safe_receiver == null)
      null;
    else {
      tmp148_safe_receiver.s19();
    }
  }
  v18() {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.u18_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.claimReusableCancellableContinuation.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (state === null) {
        this.u18_1.kotlinx$atomicfu$value = get_REUSABLE_CLAIMED();
        return null;
      } else {
        if (state instanceof CancellableContinuationImpl) {
          if (this.u18_1.atomicfu$compareAndSet(state, get_REUSABLE_CLAIMED())) {
            return state instanceof CancellableContinuationImpl ? state : THROW_CCE();
          }
        } else {
          if (state !== get_REUSABLE_CLAIMED()) {
            if (!(state instanceof Error)) {
              var message = 'Inconsistent state ' + toString_0(state);
              throw IllegalStateException.m5(toString(message));
            }
          }
        }
      }
    }
  }
  e1a(continuation) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.u18_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.tryReleaseClaimedContinuation.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (state === get_REUSABLE_CLAIMED()) {
        if (this.u18_1.atomicfu$compareAndSet(get_REUSABLE_CLAIMED(), continuation))
          return null;
      } else {
        if (state instanceof Error) {
          // Inline function 'kotlin.require' call
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'kotlin.require' call
          // Inline function 'kotlin.contracts.contract' call
          if (!this.u18_1.atomicfu$compareAndSet(state, null)) {
            // Inline function 'kotlin.require.<anonymous>' call
            var message = 'Failed requirement.';
            throw IllegalArgumentException.l(toString(message));
          }
          return state;
        } else {
          var message_0 = 'Inconsistent state ' + toString_0(state);
          throw IllegalStateException.m5(toString(message_0));
        }
      }
    }
  }
  z18(cause) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.u18_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.postponeCancellation.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (equals(state, get_REUSABLE_CLAIMED())) {
        if (this.u18_1.atomicfu$compareAndSet(get_REUSABLE_CLAIMED(), cause))
          return true;
      } else {
        if (state instanceof Error)
          return true;
        else {
          if (this.u18_1.atomicfu$compareAndSet(state, null))
            return false;
        }
      }
    }
  }
  v19() {
    var state = this.s18_1;
    // Inline function 'kotlinx.coroutines.assert' call
    this.s18_1 = get_UNDEFINED();
    return state;
  }
  t19() {
    return this;
  }
  id(result) {
    var context = this.r18_1.gd();
    var state = toState_0(result);
    if (this.q18_1.u1b(context)) {
      this.s18_1 = state;
      this.y18_1 = 0;
      this.q18_1.v1b(context, this);
    } else {
      $l$block: {
        // Inline function 'kotlinx.coroutines.internal.executeUnconfined' call
        // Inline function 'kotlinx.coroutines.assert' call
        var eventLoop = ThreadLocalEventLoop_getInstance().t1c();
        if (false && eventLoop.o1c()) {
          break $l$block;
        }
        var tmp;
        if (eventLoop.n1c()) {
          this.s18_1 = state;
          this.y18_1 = 0;
          eventLoop.m1c(this);
          tmp = true;
        } else {
          // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
          eventLoop.p1c(true);
          try {
            // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeWith.<anonymous>' call
            // Inline function 'kotlinx.coroutines.withCoroutineContext' call
            this.gd();
            this.t18_1;
            this.r18_1.id(result);
            $l$loop: while (eventLoop.l1c()) {
            }
          } catch ($p) {
            if ($p instanceof Error) {
              var e = $p;
              this.q1a(e, null);
            } else {
              throw $p;
            }
          }
          finally {
            eventLoop.q1c(true);
          }
          tmp = false;
        }
      }
    }
  }
  w19(takenState, cause) {
    if (takenState instanceof CompletedWithCancellation) {
      takenState.r1b_1(cause);
    }
  }
  toString() {
    return 'DispatchedContinuation[' + this.q18_1.toString() + ', ' + toDebugString(this.r18_1) + ']';
  }
}
class UndeliveredElementException extends RuntimeException {
  static x1l(message, cause) {
    var $this = this.ye(message, cause);
    captureStack($this, $this.w1l_1);
    return $this;
  }
}
class ContextScope {
  constructor(context) {
    this.y1l_1 = context;
  }
  c15() {
    return this.y1l_1;
  }
  toString() {
    return 'CoroutineScope(coroutineContext=' + toString(this.y1l_1) + ')';
  }
}
class Symbol {
  constructor(symbol) {
    this.z1l_1 = symbol;
  }
  toString() {
    return '<' + this.z1l_1 + '>';
  }
}
class SelectInstance {}
class ClauseData {
  e1m(select, internalResult) {
    var tmp160_safe_receiver = this.d1m_1;
    return tmp160_safe_receiver == null ? null : tmp160_safe_receiver(select, this.c1m_1, internalResult);
  }
}
class SelectImplementation {
  h1j(clauseObject, result) {
    return TrySelectDetailedResult_0(trySelectInternal(this, clauseObject, result));
  }
}
class TrySelectDetailedResult extends Enum {}
class CancellableContinuationWithOwner {
  constructor($outer, cont, owner) {
    this.q1m_1 = $outer;
    this.o1m_1 = cont;
    this.p1m_1 = owner;
  }
  b18(token) {
    this.o1m_1.b18(token);
  }
  c18(handler) {
    this.o1m_1.c18(handler);
  }
  c1n(_this__u8e3s4, value) {
    this.o1m_1.d18(_this__u8e3s4, Unit_instance);
  }
  d18(_this__u8e3s4, value) {
    return this.c1n(_this__u8e3s4, value instanceof Unit ? value : THROW_CCE());
  }
  zm(result) {
    this.o1m_1.id(result);
  }
  id(result) {
    return this.zm(result);
  }
  gd() {
    return this.o1m_1.gd();
  }
  i1a(segment, index) {
    this.o1m_1.i1a(segment, index);
  }
  d1n(value, idempotent, onCancellation) {
    // Inline function 'kotlinx.coroutines.assert' call
    var token = this.o1m_1.a18(value, idempotent, MutexImpl$CancellableContinuationWithOwner$tryResume$lambda(this.q1m_1, this));
    if (!(token == null)) {
      // Inline function 'kotlinx.coroutines.assert' call
      this.q1m_1.m1m_1.kotlinx$atomicfu$value = this.p1m_1;
    }
    return token;
  }
  a18(value, idempotent, onCancellation) {
    return this.d1n(value instanceof Unit ? value : THROW_CCE(), idempotent, onCancellation);
  }
  e1n(value, onCancellation) {
    // Inline function 'kotlinx.coroutines.assert' call
    this.q1m_1.m1m_1.kotlinx$atomicfu$value = this.p1m_1;
    this.o1m_1.g1a(Unit_instance, MutexImpl$CancellableContinuationWithOwner$resume$lambda(this.q1m_1, this));
  }
  e18(value, onCancellation) {
    return this.e1n(value instanceof Unit ? value : THROW_CCE(), onCancellation);
  }
}
class SemaphoreImpl {
  constructor(permits, acquiredPermits) {
    this.u1m_1 = permits;
    this.w1m_1 = atomic$long$1(new Long(0, 0));
    this.y1m_1 = atomic$long$1(new Long(0, 0));
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.u1m_1 > 0)) {
      // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.<anonymous>' call
      var message = 'Semaphore should have at least 1 permit, but had ' + this.u1m_1;
      throw IllegalArgumentException.l(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(0 <= acquiredPermits ? acquiredPermits <= this.u1m_1 : false)) {
      // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.<anonymous>' call
      var message_0 = 'The number of acquired permits should be in 0..' + this.u1m_1;
      throw IllegalArgumentException.l(toString(message_0));
    }
    var s = new SemaphoreSegment(new Long(0, 0), null, 2);
    this.v1m_1 = atomic$ref$1(s);
    this.x1m_1 = atomic$ref$1(s);
    this.z1m_1 = atomic$int$1(this.u1m_1 - acquiredPermits | 0);
    var tmp = this;
    tmp.a1n_1 = SemaphoreImpl$onCancellationRelease$lambda(this);
  }
  f1n() {
    // Inline function 'kotlin.math.max' call
    var a = this.z1m_1.kotlinx$atomicfu$value;
    return Math.max(a, 0);
  }
  b1n() {
    $l$loop: while (true) {
      var p = this.z1m_1.kotlinx$atomicfu$value;
      if (p > this.u1m_1) {
        coerceAvailablePermitsAtMaximum(this);
        continue $l$loop;
      }
      if (p <= 0)
        return false;
      if (this.z1m_1.atomicfu$compareAndSet(p, p - 1 | 0))
        return true;
    }
  }
  acquireCont(waiter) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.acquire' call
      while (true) {
        var p = decPermits(this);
        if (p > 0) {
          // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.acquire.<anonymous>' call
          waiter.e18(Unit_instance, this.a1n_1);
          tmp$ret$0 = Unit_instance;
          break $l$block_0;
        }
        // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.acquire.<anonymous>' call
        if (addAcquireToQueue(this, isInterface(waiter, Waiter) ? waiter : THROW_CCE())) {
          tmp$ret$0 = Unit_instance;
          break $l$block_0;
        }
      }
    }
    return tmp$ret$0;
  }
  h1n() {
    while (true) {
      var p = this.z1m_1.atomicfu$getAndIncrement();
      if (p >= this.u1m_1) {
        coerceAvailablePermitsAtMaximum(this);
        // Inline function 'kotlin.error' call
        var message = 'The number of released permits cannot be greater than ' + this.u1m_1;
        throw IllegalStateException.m5(toString(message));
      }
      if (p >= 0)
        return Unit_instance;
      if (tryResumeNextFromQueue(this))
        return Unit_instance;
    }
  }
}
class MutexImpl extends SemaphoreImpl {
  constructor(locked) {
    super(1, locked ? 1 : 0);
    this.m1m_1 = atomic$ref$1(locked ? null : get_NO_OWNER());
    var tmp = this;
    tmp.n1m_1 = MutexImpl$onSelectCancellationUnlockConstructor$lambda(this);
  }
  s1m() {
    return this.f1n() === 0;
  }
  g1n(owner, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_lock__qllepv(this, owner, $completion), $completion);
  }
  t1m(owner) {
    var tmp;
    switch (tryLockImpl(this, owner)) {
      case 0:
        tmp = true;
        break;
      case 1:
        tmp = false;
        break;
      case 2:
        var message = 'This mutex is already locked by the specified owner: ' + toString_0(owner);
        throw IllegalStateException.m5(toString(message));
      default:
        var message_0 = 'unexpected';
        throw IllegalStateException.m5(toString(message_0));
    }
    return tmp;
  }
  r1m(owner) {
    $l$loop_0: while (true) {
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!this.s1m()) {
        // Inline function 'kotlinx.coroutines.sync.MutexImpl.unlock.<anonymous>' call
        var message = 'This mutex is not locked';
        throw IllegalStateException.m5(toString(message));
      }
      var curOwner = this.m1m_1.kotlinx$atomicfu$value;
      if (curOwner === get_NO_OWNER())
        continue $l$loop_0;
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(curOwner === owner || owner == null)) {
        // Inline function 'kotlinx.coroutines.sync.MutexImpl.unlock.<anonymous>' call
        var message_0 = 'This mutex is locked by ' + toString_0(curOwner) + ', but ' + toString_0(owner) + ' is expected';
        throw IllegalStateException.m5(toString(message_0));
      }
      if (!this.m1m_1.atomicfu$compareAndSet(curOwner, get_NO_OWNER()))
        continue $l$loop_0;
      this.h1n();
      return Unit_instance;
    }
  }
  toString() {
    return 'Mutex@' + get_hexAddress(this) + '[isLocked=' + this.s1m() + ',owner=' + toString_0(this.m1m_1.kotlinx$atomicfu$value) + ']';
  }
}
class SemaphoreSegment extends Segment {
  constructor(id, prev, pointers) {
    super(id, prev, pointers);
    this.m1n_1 = atomicfu$AtomicRefArray$ofNulls(get_SEGMENT_SIZE_0());
  }
  f1h() {
    return get_SEGMENT_SIZE_0();
  }
  e19(index, cause, context) {
    // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.set' call
    var value = get_CANCELLED();
    this.m1n_1.atomicfu$get(index).kotlinx$atomicfu$value = value;
    this.c1i();
  }
  toString() {
    return 'SemaphoreSegment[id=' + this.c19_1.toString() + ', hashCode=' + hashCode(this) + ']';
  }
}
class SetTimeoutBasedDispatcher extends CoroutineDispatcher {
  static s1n() {
    var $this = this.t1b();
    $this.r1n_1 = new ScheduledMessageQueue($this);
    return $this;
  }
  v1b(context, block) {
    this.r1n_1.n1o(block);
  }
  e1c(timeMillis, block, context) {
    var handle = w3cSetTimeout(SetTimeoutBasedDispatcher$invokeOnTimeout$lambda(block), delayToInt(timeMillis));
    return new ClearTimeout(handle);
  }
  d1c(timeMillis, continuation) {
    var handle = w3cSetTimeout(SetTimeoutBasedDispatcher$scheduleResumeAfterDelay$lambda(continuation, this), delayToInt(timeMillis));
    invokeOnCancellation(continuation, new ClearTimeout(handle));
  }
}
class NodeDispatcher extends SetTimeoutBasedDispatcher {
  static p1n() {
    NodeDispatcher_instance = null;
    var $this = this.s1n();
    NodeDispatcher_instance = $this;
    return $this;
  }
  t1n() {
    process.nextTick(this.r1n_1.y1n_1);
  }
}
class MessageQueue {
  constructor() {
    this.z1n_1 = ArrayDeque.jl();
    this.a1o_1 = 16;
    this.b1o_1 = false;
  }
  g1o(element) {
    return this.z1n_1.f(element);
  }
  f(element) {
    return this.g1o((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  h1o(elements) {
    return this.z1n_1.k1(elements);
  }
  k1(elements) {
    return this.h1o(elements);
  }
  h3() {
    return this.z1n_1.h3();
  }
  p1(index) {
    return this.z1n_1.p1(index);
  }
  i1o(element) {
    return this.z1n_1.n3(element);
  }
  n3(element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return false;
    return this.i1o((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  q3(index) {
    return this.z1n_1.q3(index);
  }
  j1o(index, element) {
    return this.z1n_1.p3(index, element);
  }
  p3(index, element) {
    return this.j1o(index, (!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  n1(fromIndex, toIndex) {
    return this.z1n_1.n1(fromIndex, toIndex);
  }
  k1o(element) {
    return this.z1n_1.f3(element);
  }
  f3(element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return false;
    return this.k1o((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  l1o(elements) {
    return this.z1n_1.g3(elements);
  }
  g3(elements) {
    return this.l1o(elements);
  }
  i1(index) {
    return this.z1n_1.i1(index);
  }
  m1o(element) {
    return this.z1n_1.q1(element);
  }
  q1(element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return -1;
    return this.m1o((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  a1() {
    return this.z1n_1.a1();
  }
  f1() {
    return this.z1n_1.f1();
  }
  j1() {
    return this.z1n_1.gl_1;
  }
  n1o(element) {
    this.g1o(element);
    if (!this.b1o_1) {
      this.b1o_1 = true;
      this.d1o();
    }
  }
  c1o() {
    try {
      // Inline function 'kotlin.repeat' call
      var times = this.a1o_1;
      // Inline function 'kotlin.contracts.contract' call
      var inductionVariable = 0;
      if (inductionVariable < times)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlinx.coroutines.MessageQueue.process.<anonymous>' call
          var tmp0_elvis_lhs = removeFirstOrNull(this);
          var tmp;
          if (tmp0_elvis_lhs == null) {
            return Unit_instance;
          } else {
            tmp = tmp0_elvis_lhs;
          }
          var element = tmp;
          element.p1a();
        }
         while (inductionVariable < times);
    }finally {
      if (this.a1()) {
        this.b1o_1 = false;
      } else {
        this.e1o();
      }
    }
  }
}
class ScheduledMessageQueue extends MessageQueue {
  constructor(dispatcher) {
    super();
    this.x1n_1 = dispatcher;
    var tmp = this;
    tmp.y1n_1 = ScheduledMessageQueue$processQueue$lambda(this);
  }
  d1o() {
    this.x1n_1.t1n();
  }
  e1o() {
    setTimeout(this.y1n_1, 0);
  }
  f1o(timeout) {
    setTimeout(this.y1n_1, timeout);
  }
}
class WindowMessageQueue extends MessageQueue {
  constructor(window_0) {
    super();
    this.r1o_1 = window_0;
    this.s1o_1 = 'dispatchCoroutine';
    this.r1o_1.addEventListener('message', WindowMessageQueue$lambda(this), true);
  }
  d1o() {
    var tmp = Promise.resolve(Unit_instance);
    tmp.then(WindowMessageQueue$schedule$lambda(this));
  }
  e1o() {
    this.r1o_1.postMessage(this.s1o_1, '*');
  }
}
class UnconfinedEventLoop extends EventLoop {
  static b1p() {
    return this.k1c();
  }
  v1b(context, block) {
    unsupported();
  }
}
class SetTimeoutDispatcher extends SetTimeoutBasedDispatcher {
  static e1p() {
    SetTimeoutDispatcher_instance = null;
    var $this = this.s1n();
    SetTimeoutDispatcher_instance = $this;
    return $this;
  }
  t1n() {
    this.r1n_1.f1o(0);
  }
}
class ClearTimeout {
  constructor(handle, $box) {
    boxApply(this, $box);
    this.h1p_1 = handle;
  }
  o18() {
    w3cClearTimeout_0(this.h1p_1);
  }
  n18(cause) {
    this.o18();
  }
  toString() {
    return 'ClearTimeout[' + this.h1p_1 + ']';
  }
}
class WindowClearTimeout extends ClearTimeout {
  constructor($outer, handle, $box) {
    if ($box === VOID)
      $box = {};
    $box.g1p_1 = $outer;
    super(handle, $box);
  }
  o18() {
    w3cClearTimeout(this.g1p_1.u1o_1, this.h1p_1);
  }
}
class WindowDispatcher extends CoroutineDispatcher {
  static w1o(window_0) {
    var $this = this.t1b();
    $this.u1o_1 = window_0;
    $this.v1o_1 = new WindowMessageQueue($this.u1o_1);
    return $this;
  }
  v1b(context, block) {
    return this.v1o_1.n1o(block);
  }
  d1c(timeMillis, continuation) {
    var handle = w3cSetTimeout_0(this.u1o_1, WindowDispatcher$scheduleResumeAfterDelay$lambda(continuation, this), delayToInt(timeMillis));
    invokeOnCancellation(continuation, new WindowClearTimeout(this, handle));
  }
  e1c(timeMillis, block, context) {
    var handle = w3cSetTimeout_0(this.u1o_1, Runnable$run$ref(block), delayToInt(timeMillis));
    return new WindowClearTimeout(this, handle);
  }
}
class CloseableCoroutineDispatcher extends CoroutineDispatcher {}
class UndispatchedCoroutine extends ScopeCoroutine {
  m15(state) {
    return this.r17_1.id(recoverResult(state, this.r17_1));
  }
}
class Dispatchers {
  constructor() {
    Dispatchers_instance = this;
    this.y1b_1 = createDefaultDispatcher();
    this.z1b_1 = Unconfined_getInstance();
    this.a1c_1 = JsMainDispatcher.r1p(this.y1b_1, false);
    this.b1c_1 = null;
  }
  c1c() {
    var tmp0_elvis_lhs = this.b1c_1;
    return tmp0_elvis_lhs == null ? this.a1c_1 : tmp0_elvis_lhs;
  }
}
class JsMainDispatcher extends MainCoroutineDispatcher {
  static r1p(delegate, invokeImmediately) {
    var $this = this.b1g();
    $this.o1p_1 = delegate;
    $this.p1p_1 = invokeImmediately;
    $this.q1p_1 = $this.p1p_1 ? $this : JsMainDispatcher.r1p($this.o1p_1, true);
    return $this;
  }
  c1g() {
    return this.q1p_1;
  }
  u1b(context) {
    return !this.p1p_1;
  }
  v1b(context, block) {
    return this.o1p_1.v1b(context, block);
  }
  toString() {
    var tmp0_elvis_lhs = this.d1g();
    return tmp0_elvis_lhs == null ? this.o1p_1.toString() : tmp0_elvis_lhs;
  }
}
class JobCancellationException extends CancellationException {
  static d1e(message, cause, job) {
    var $this = this.ie(message, cause);
    captureStack($this, $this.c1e_1);
    $this.b1e_1 = job;
    return $this;
  }
  toString() {
    return super.toString() + '; job=' + toString(this.b1e_1);
  }
  equals(other) {
    var tmp;
    if (other === this) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      var tmp_2;
      if (other instanceof JobCancellationException) {
        tmp_2 = other.message == this.message;
      } else {
        tmp_2 = false;
      }
      if (tmp_2) {
        tmp_1 = equals(other.b1e_1, this.b1e_1);
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = equals(other.cause, this.cause);
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  hashCode() {
    var tmp = imul(imul(getStringHashCode(ensureNotNull(this.message)), 31) + hashCode(this.b1e_1) | 0, 31);
    var tmp0_safe_receiver = this.cause;
    var tmp0_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp + (tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs) | 0;
  }
}
class TaskContext {}
class SafeCollector {
  constructor(collector, collectContext) {
    this.i1l_1 = collector;
    this.j1l_1 = collectContext;
    var tmp = this;
    tmp.k1l_1 = this.j1l_1.wn(0, SafeCollector$collectContextSize$lambda);
    this.l1l_1 = null;
  }
  g1l(value, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_emit__qph46j(this, value, $completion), $completion);
  }
  rd() {
  }
}
class DiagnosticCoroutineContextException extends RuntimeException {
  static s1l(context) {
    var $this = this.db(toString(context));
    captureStack($this, $this.r1l_1);
    return $this;
  }
}
class ListClosed extends LockFreeLinkedListNode {
  constructor(forbiddenElementsBitmask) {
    super();
    this.v1p_1 = forbiddenElementsBitmask;
  }
}
class CommonThreadLocal {
  constructor() {
    this.u1c_1 = null;
  }
  v1c() {
    var tmp = this.u1c_1;
    return (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
  }
  w1c(value) {
    this.u1c_1 = value;
  }
}
//endregion
function launch(_this__u8e3s4, context, start, block) {
  context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
  start = start === VOID ? CoroutineStart_DEFAULT_getInstance() : start;
  var newContext = newCoroutineContext(_this__u8e3s4, context);
  var coroutine = start.a17() ? new LazyStandaloneCoroutine(newContext, block) : new StandaloneCoroutine(newContext, true);
  coroutine.q15(start, coroutine, block);
  return coroutine;
}
function withContext(context, block, $completion) {
  var tmp$ret$0;
  $l$block_0: {
    // Inline function 'kotlinx.coroutines.withContext.<anonymous>' call
    var oldContext = $completion.gd();
    var newContext = newCoroutineContext_0(oldContext, context);
    ensureActive(newContext);
    if (newContext === oldContext) {
      var coroutine = new ScopeCoroutine(newContext, $completion);
      tmp$ret$0 = startUndispatchedOrReturn(coroutine, coroutine, block);
      break $l$block_0;
    }
    if (equals(newContext.td(Key_instance), oldContext.td(Key_instance))) {
      var coroutine_0 = new UndispatchedCoroutine(newContext, $completion);
      // Inline function 'kotlinx.coroutines.withCoroutineContext' call
      coroutine_0.b15_1;
      tmp$ret$0 = startUndispatchedOrReturn(coroutine_0, coroutine_0, block);
      break $l$block_0;
    }
    var coroutine_1 = new DispatchedCoroutine(newContext, $completion);
    startCoroutineCancellable(block, coroutine_1, coroutine_1);
    tmp$ret$0 = coroutine_1.g17();
  }
  return tmp$ret$0;
}
function trySuspend($this) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.f17_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.DispatchedCoroutine.trySuspend.<anonymous>' call
    switch (this_0.kotlinx$atomicfu$value) {
      case 0:
        if ($this.f17_1.atomicfu$compareAndSet(0, 1))
          return true;
        break;
      case 2:
        return false;
      default:
        // Inline function 'kotlin.error' call

        var message = 'Already suspended';
        throw IllegalStateException.m5(toString(message));
    }
  }
}
function tryResume($this) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.f17_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.DispatchedCoroutine.tryResume.<anonymous>' call
    switch (this_0.kotlinx$atomicfu$value) {
      case 0:
        if ($this.f17_1.atomicfu$compareAndSet(0, 2))
          return true;
        break;
      case 1:
        return false;
      default:
        // Inline function 'kotlin.error' call

        var message = 'Already resumed';
        throw IllegalStateException.m5(toString(message));
    }
  }
}
function async(_this__u8e3s4, context, start, block) {
  context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
  start = start === VOID ? CoroutineStart_DEFAULT_getInstance() : start;
  var newContext = newCoroutineContext(_this__u8e3s4, context);
  var coroutine = start.a17() ? new LazyDeferredCoroutine(newContext, block) : new DeferredCoroutine(newContext, true);
  coroutine.q15(start, coroutine, block);
  return coroutine;
}
function *_generator_await__mos7q6($this, $completion) {
  var tmp = $this.z16($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = tmp;
  return (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
}
function disposeOnCancellation(_this__u8e3s4, handle) {
  return invokeOnCancellation(_this__u8e3s4, new DisposeOnCancel(handle));
}
function invokeOnCancellation(_this__u8e3s4, handler) {
  var tmp;
  if (_this__u8e3s4 instanceof CancellableContinuationImpl) {
    _this__u8e3s4.l18(handler);
    tmp = Unit_instance;
  } else {
    throw UnsupportedOperationException.ua('third-party implementation of CancellableContinuation is not supported');
  }
  return tmp;
}
function getOrCreateCancellableContinuation(delegate) {
  if (!(delegate instanceof DispatchedContinuation)) {
    return new CancellableContinuationImpl(delegate, 1);
  }
  var tmp4_safe_receiver = delegate.v18();
  var tmp;
  if (tmp4_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.takeIf' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    // Inline function 'kotlinx.coroutines.getOrCreateCancellableContinuation.<anonymous>' call
    if (tmp4_safe_receiver.w18()) {
      tmp_0 = tmp4_safe_receiver;
    } else {
      tmp_0 = null;
    }
    tmp = tmp_0;
  }
  var tmp0_elvis_lhs = tmp;
  var tmp_1;
  if (tmp0_elvis_lhs == null) {
    return new CancellableContinuationImpl(delegate, 2);
  } else {
    tmp_1 = tmp0_elvis_lhs;
  }
  return tmp_1;
}
function get_RESUME_TOKEN() {
  _init_properties_CancellableContinuationImpl_kt__6rrtdd();
  return RESUME_TOKEN;
}
var RESUME_TOKEN;
function _get_parentHandle__f8dcex($this) {
  return $this.k18_1.kotlinx$atomicfu$value;
}
function _get_stateDebugRepresentation__bf18u4($this) {
  var tmp5_subject = $this.x15();
  var tmp;
  if (!(tmp5_subject == null) ? isInterface(tmp5_subject, NotCompleted) : false) {
    tmp = 'Active';
  } else {
    if (tmp5_subject instanceof CancelledContinuation) {
      tmp = 'Cancelled';
    } else {
      tmp = 'Completed';
    }
  }
  return tmp;
}
function isReusable($this) {
  var tmp;
  if (get_isReusableMode($this.y18_1)) {
    var tmp_0 = $this.g18_1;
    tmp = (tmp_0 instanceof DispatchedContinuation ? tmp_0 : THROW_CCE()).x18();
  } else {
    tmp = false;
  }
  return tmp;
}
function cancelLater($this, cause) {
  if (!isReusable($this))
    return false;
  var tmp = $this.g18_1;
  var dispatched = tmp instanceof DispatchedContinuation ? tmp : THROW_CCE();
  return dispatched.z18(cause);
}
function callSegmentOnCancellation($this, segment, cause) {
  // Inline function 'kotlinx.coroutines.index' call
  var index = $this.i18_1.kotlinx$atomicfu$value & 536870911;
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  if (!!(index === 536870911)) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callSegmentOnCancellation.<anonymous>' call
    var message = 'The index for Segment.onCancellation(..) is broken';
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callCancelHandlerSafely' call
  try {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callSegmentOnCancellation.<anonymous>' call
    segment.e19(index, cause, $this.gd());
  } catch ($p) {
    if ($p instanceof Error) {
      var ex = $p;
      handleCoroutineException($this.gd(), CompletionHandlerException.i19('Exception in invokeOnCancellation handler for ' + $this.toString(), ex));
    } else {
      throw $p;
    }
  }
}
function trySuspend_0($this) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.i18_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.trySuspend.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.decision' call
    switch (cur >> 29) {
      case 0:
        // Inline function 'kotlinx.coroutines.decisionAndIndex' call

        // Inline function 'kotlinx.coroutines.index' call

        var tmp$ret$2 = (1 << 29) + (cur & 536870911) | 0;
        if ($this.i18_1.atomicfu$compareAndSet(cur, tmp$ret$2))
          return true;
        break;
      case 2:
        return false;
      default:
        // Inline function 'kotlin.error' call

        var message = 'Already suspended';
        throw IllegalStateException.m5(toString(message));
    }
  }
}
function tryResume_0($this) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.i18_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.tryResume.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.decision' call
    switch (cur >> 29) {
      case 0:
        // Inline function 'kotlinx.coroutines.decisionAndIndex' call

        // Inline function 'kotlinx.coroutines.index' call

        var tmp$ret$2 = (2 << 29) + (cur & 536870911) | 0;
        if ($this.i18_1.atomicfu$compareAndSet(cur, tmp$ret$2))
          return true;
        break;
      case 1:
        return false;
      default:
        // Inline function 'kotlin.error' call

        var message = 'Already resumed';
        throw IllegalStateException.m5(toString(message));
    }
  }
}
function installParentHandle($this) {
  var tmp0_elvis_lhs = $this.gd().td(Key_instance_2);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var parent = tmp;
  var handle = invokeOnCompletion(parent, VOID, new ChildContinuation($this));
  $this.k18_1.atomicfu$compareAndSet(null, handle);
  return handle;
}
function invokeOnCancellationImpl($this, handler) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.j18_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellationImpl.<anonymous>' call
    var state = this_0.kotlinx$atomicfu$value;
    if (state instanceof Active) {
      if ($this.j18_1.atomicfu$compareAndSet(state, handler))
        return Unit_instance;
    } else {
      var tmp;
      if (!(state == null) ? isInterface(state, CancelHandler) : false) {
        tmp = true;
      } else {
        tmp = state instanceof Segment;
      }
      if (tmp) {
        multipleHandlersError($this, handler, state);
      } else {
        if (state instanceof CompletedExceptionally) {
          if (!state.r19()) {
            multipleHandlersError($this, handler, state);
          }
          if (state instanceof CancelledContinuation) {
            var tmp13_safe_receiver = state instanceof CompletedExceptionally ? state : null;
            var cause = tmp13_safe_receiver == null ? null : tmp13_safe_receiver.i15_1;
            if (isInterface(handler, CancelHandler)) {
              $this.o19(handler, cause);
            } else {
              var segment = handler instanceof Segment ? handler : THROW_CCE();
              callSegmentOnCancellation($this, segment, cause);
            }
          }
          return Unit_instance;
        } else {
          if (state instanceof CompletedContinuation) {
            if (!(state.k19_1 == null)) {
              multipleHandlersError($this, handler, state);
            }
            if (handler instanceof Segment)
              return Unit_instance;
            if (!isInterface(handler, CancelHandler))
              THROW_CCE();
            if (state.p19()) {
              $this.o19(handler, state.n19_1);
              return Unit_instance;
            }
            var update = state.q19(VOID, handler);
            if ($this.j18_1.atomicfu$compareAndSet(state, update))
              return Unit_instance;
          } else {
            if (handler instanceof Segment)
              return Unit_instance;
            if (!isInterface(handler, CancelHandler))
              THROW_CCE();
            var update_0 = new CompletedContinuation(state, handler);
            if ($this.j18_1.atomicfu$compareAndSet(state, update_0))
              return Unit_instance;
          }
        }
      }
    }
  }
}
function multipleHandlersError($this, handler, state) {
  // Inline function 'kotlin.error' call
  var message = "It's prohibited to register multiple handlers, tried to register " + toString(handler) + ', already has ' + toString_0(state);
  throw IllegalStateException.m5(toString(message));
}
function dispatchResume($this, mode) {
  if (tryResume_0($this))
    return Unit_instance;
  dispatch($this, mode);
}
function resumedState($this, state, proposedUpdate, resumeMode, onCancellation, idempotent) {
  var tmp;
  if (proposedUpdate instanceof CompletedExceptionally) {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    tmp = proposedUpdate;
  } else {
    if (!get_isCancellableMode(resumeMode) && idempotent == null) {
      tmp = proposedUpdate;
    } else {
      var tmp_0;
      var tmp_1;
      if (!(onCancellation == null)) {
        tmp_1 = true;
      } else {
        tmp_1 = isInterface(state, CancelHandler);
      }
      if (tmp_1) {
        tmp_0 = true;
      } else {
        tmp_0 = !(idempotent == null);
      }
      if (tmp_0) {
        tmp = new CompletedContinuation(proposedUpdate, isInterface(state, CancelHandler) ? state : null, onCancellation, idempotent);
      } else {
        tmp = proposedUpdate;
      }
    }
  }
  return tmp;
}
function tryResumeImpl($this, proposedUpdate, idempotent, onCancellation) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.j18_1;
  while (true) {
    $l$block: {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.tryResumeImpl.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (!(state == null) ? isInterface(state, NotCompleted) : false) {
        var update = resumedState($this, state, proposedUpdate, $this.y18_1, onCancellation, idempotent);
        if (!$this.j18_1.atomicfu$compareAndSet(state, update)) {
          break $l$block;
        }
        detachChildIfNonResuable($this);
        return get_RESUME_TOKEN();
      } else {
        if (state instanceof CompletedContinuation) {
          var tmp;
          if (!(idempotent == null) && state.m19_1 === idempotent) {
            // Inline function 'kotlinx.coroutines.assert' call
            tmp = get_RESUME_TOKEN();
          } else {
            tmp = null;
          }
          return tmp;
        } else {
          return null;
        }
      }
    }
  }
}
function alreadyResumedError($this, proposedUpdate) {
  // Inline function 'kotlin.error' call
  var message = 'Already resumed, but proposed with update ' + toString_0(proposedUpdate);
  throw IllegalStateException.m5(toString(message));
}
function detachChildIfNonResuable($this) {
  if (!isReusable($this)) {
    $this.s19();
  }
}
function CancellableContinuationImpl$resume$lambda($onCancellation) {
  return function (cause, _unused_var__etf5q3, _unused_var__etf5q3_0) {
    $onCancellation(cause);
    return Unit_instance;
  };
}
var Active_instance;
function Active_getInstance() {
  return Active_instance;
}
var properties_initialized_CancellableContinuationImpl_kt_xtzb03;
function _init_properties_CancellableContinuationImpl_kt__6rrtdd() {
  if (!properties_initialized_CancellableContinuationImpl_kt_xtzb03) {
    properties_initialized_CancellableContinuationImpl_kt_xtzb03 = true;
    RESUME_TOKEN = new Symbol('RESUME_TOKEN');
  }
}
function CompletableDeferred(parent) {
  parent = parent === VOID ? null : parent;
  return new CompletableDeferredImpl(parent);
}
function completeWith(_this__u8e3s4, result) {
  // Inline function 'kotlin.fold' call
  // Inline function 'kotlin.contracts.contract' call
  var exception = Result__exceptionOrNull_impl_p6xea9(result);
  var tmp;
  if (exception == null) {
    // Inline function 'kotlinx.coroutines.completeWith.<anonymous>' call
    var tmp_0 = _Result___get_value__impl__bjfvqg(result);
    var it = (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
    tmp = _this__u8e3s4.m1b(it);
  } else {
    // Inline function 'kotlinx.coroutines.completeWith.<anonymous>' call
    tmp = _this__u8e3s4.l1b(exception);
  }
  return tmp;
}
function *_generator_await__mos7q6_0($this, $completion) {
  var tmp = $this.z16($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = tmp;
  return (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
}
function toState(_this__u8e3s4, caller) {
  // Inline function 'kotlin.fold' call
  // Inline function 'kotlin.contracts.contract' call
  var exception = Result__exceptionOrNull_impl_p6xea9(_this__u8e3s4);
  var tmp;
  if (exception == null) {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    var tmp_0 = _Result___get_value__impl__bjfvqg(_this__u8e3s4);
    tmp = (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
  } else {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    tmp = new CompletedExceptionally(recoverStackTrace(exception, caller));
  }
  return tmp;
}
function toState_0(_this__u8e3s4, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  // Inline function 'kotlin.fold' call
  // Inline function 'kotlin.contracts.contract' call
  var exception = Result__exceptionOrNull_impl_p6xea9(_this__u8e3s4);
  var tmp;
  if (exception == null) {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    var tmp_0 = _Result___get_value__impl__bjfvqg(_this__u8e3s4);
    var it = (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
    tmp = !(onCancellation == null) ? new CompletedWithCancellation(it, onCancellation) : it;
  } else {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    tmp = new CompletedExceptionally(exception);
  }
  return tmp;
}
function recoverResult(state, uCont) {
  var tmp;
  if (state instanceof CompletedExceptionally) {
    // Inline function 'kotlin.Companion.failure' call
    var exception = recoverStackTrace(state.i15_1, uCont);
    tmp = _Result___init__impl__xyqfz8(createFailure(exception));
  } else {
    // Inline function 'kotlin.Companion.success' call
    var value = (state == null ? true : !(state == null)) ? state : THROW_CCE();
    tmp = _Result___init__impl__xyqfz8(value);
  }
  return tmp;
}
function CoroutineDispatcher$Key$_init_$lambda_akl8b5(it) {
  return it instanceof CoroutineDispatcher ? it : null;
}
var Key_instance_0;
function Key_getInstance() {
  if (Key_instance_0 === VOID)
    new Key();
  return Key_instance_0;
}
function handleCoroutineException(context, exception) {
  try {
    var tmp23_safe_receiver = context.td(Key_instance_1);
    if (tmp23_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      tmp23_safe_receiver.x1b(context, exception);
      return Unit_instance;
    }
  } catch ($p) {
    if ($p instanceof Error) {
      var t = $p;
      handleUncaughtCoroutineException(context, handlerException(exception, t));
      return Unit_instance;
    } else {
      throw $p;
    }
  }
  handleUncaughtCoroutineException(context, exception);
}
var Key_instance_1;
function Key_getInstance_0() {
  return Key_instance_1;
}
function handlerException(originalException, thrownException) {
  if (originalException === thrownException)
    return originalException;
  // Inline function 'kotlin.apply' call
  var this_0 = RuntimeException.ye('Exception while trying to handle coroutine exception', thrownException);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.handlerException.<anonymous>' call
  addSuppressed(this_0, originalException);
  return this_0;
}
function CoroutineScope_0(context) {
  return new ContextScope(!(context.td(Key_instance_2) == null) ? context : context.xn(Job_0()));
}
function coroutineScope(block, $completion) {
  // Inline function 'kotlinx.coroutines.coroutineScope.<anonymous>' call
  var coroutine = new ScopeCoroutine($completion.gd(), $completion);
  return startUndispatchedOrReturn(coroutine, coroutine, block);
}
function MainScope() {
  return new ContextScope(SupervisorJob().xn(Dispatchers_getInstance().c1c()));
}
var CoroutineStart_DEFAULT_instance;
var CoroutineStart_LAZY_instance;
var CoroutineStart_ATOMIC_instance;
var CoroutineStart_UNDISPATCHED_instance;
var CoroutineStart_entriesInitialized;
function CoroutineStart_initEntries() {
  if (CoroutineStart_entriesInitialized)
    return Unit_instance;
  CoroutineStart_entriesInitialized = true;
  CoroutineStart_DEFAULT_instance = new CoroutineStart('DEFAULT', 0);
  CoroutineStart_LAZY_instance = new CoroutineStart('LAZY', 1);
  CoroutineStart_ATOMIC_instance = new CoroutineStart('ATOMIC', 2);
  CoroutineStart_UNDISPATCHED_instance = new CoroutineStart('UNDISPATCHED', 3);
}
function CoroutineStart_DEFAULT_getInstance() {
  CoroutineStart_initEntries();
  return CoroutineStart_DEFAULT_instance;
}
function CoroutineStart_LAZY_getInstance() {
  CoroutineStart_initEntries();
  return CoroutineStart_LAZY_instance;
}
function CoroutineStart_UNDISPATCHED_getInstance() {
  CoroutineStart_initEntries();
  return CoroutineStart_UNDISPATCHED_instance;
}
function get_delay(_this__u8e3s4) {
  var tmp = _this__u8e3s4.td(Key_instance);
  var tmp0_elvis_lhs = (!(tmp == null) ? isInterface(tmp, Delay) : false) ? tmp : null;
  return tmp0_elvis_lhs == null ? get_DefaultDelay() : tmp0_elvis_lhs;
}
function delay(duration, $completion) {
  return delay_0(toDelayMillis(duration), $completion);
}
function delay_0(timeMillis, $completion) {
  if (timeMillis.g2(new Long(0, 0)) <= 0)
    return Unit_instance;
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
  cancellable.u19();
  // Inline function 'kotlinx.coroutines.delay.<anonymous>' call
  if (timeMillis.g2(new Long(-1, 2147483647)) < 0) {
    get_delay(cancellable.gd()).d1c(timeMillis, cancellable);
  }
  return cancellable.g17();
}
function toDelayMillis(_this__u8e3s4) {
  var tmp;
  switch (Duration__isPositive_impl_tvkkt2(_this__u8e3s4)) {
    case true:
      // Inline function 'kotlin.time.Companion.nanoseconds' call

      Companion_getInstance();
      var this_0 = new Long(999999, 0);
      var tmp$ret$0 = toDuration(this_0, DurationUnit_NANOSECONDS_getInstance());
      tmp = _Duration___get_inWholeMilliseconds__impl__msfiry(Duration__plus_impl_yu9v8f(_this__u8e3s4, tmp$ret$0));
      break;
    case false:
      tmp = new Long(0, 0);
      break;
    default:
      noWhenBranchMatchedException();
      break;
  }
  return tmp;
}
function delta($this, unconfined) {
  return unconfined ? new Long(0, 1) : new Long(1, 0);
}
var ThreadLocalEventLoop_instance;
function ThreadLocalEventLoop_getInstance() {
  if (ThreadLocalEventLoop_instance === VOID)
    new ThreadLocalEventLoop();
  return ThreadLocalEventLoop_instance;
}
var Key_instance_2;
function Key_getInstance_1() {
  return Key_instance_2;
}
var NonDisposableHandle_instance;
function NonDisposableHandle_getInstance() {
  return NonDisposableHandle_instance;
}
function ensureActive(_this__u8e3s4) {
  var tmp41_safe_receiver = _this__u8e3s4.td(Key_instance_2);
  if (tmp41_safe_receiver == null)
    null;
  else {
    ensureActive_0(tmp41_safe_receiver);
  }
}
function invokeOnCompletion(_this__u8e3s4, invokeImmediately, handler) {
  invokeImmediately = invokeImmediately === VOID ? true : invokeImmediately;
  var tmp;
  if (_this__u8e3s4 instanceof JobSupport) {
    tmp = _this__u8e3s4.g16(invokeImmediately, handler);
  } else {
    var tmp_0 = handler.d1b();
    tmp = _this__u8e3s4.f16(tmp_0, invokeImmediately, JobNode$invoke$ref(handler));
  }
  return tmp;
}
function ensureActive_0(_this__u8e3s4) {
  if (!_this__u8e3s4.d15())
    throw _this__u8e3s4.b16();
}
function Job_0(parent) {
  parent = parent === VOID ? null : parent;
  return new JobImpl(parent);
}
function get_job(_this__u8e3s4) {
  var tmp0_elvis_lhs = _this__u8e3s4.td(Key_instance_2);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var message = "Current context doesn't contain Job in it: " + toString(_this__u8e3s4);
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function cancelChildren(_this__u8e3s4, cause) {
  cause = cause === VOID ? null : cause;
  var tmp42_safe_receiver = _this__u8e3s4.td(Key_instance_2);
  var tmp43_safe_receiver = tmp42_safe_receiver == null ? null : tmp42_safe_receiver.s16();
  if (tmp43_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_iterator = tmp43_safe_receiver.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'kotlinx.coroutines.cancelChildren.<anonymous>' call
      element.k16(cause);
    }
  }
}
function cancel(_this__u8e3s4, cause) {
  cause = cause === VOID ? null : cause;
  var tmp40_safe_receiver = _this__u8e3s4.td(Key_instance_2);
  if (tmp40_safe_receiver == null)
    null;
  else {
    tmp40_safe_receiver.k16(cause);
  }
}
function disposeOnCompletion(_this__u8e3s4, handle) {
  return invokeOnCompletion(_this__u8e3s4, VOID, new DisposeOnCompletion(handle));
}
function cancelAndJoin(_this__u8e3s4, $completion) {
  _this__u8e3s4.l16();
  return _this__u8e3s4.h16($completion);
}
function JobNode$invoke$ref($boundThis) {
  var l = function (p0) {
    $boundThis.n18(p0);
    return Unit_instance;
  };
  l.callableName = 'invoke';
  return l;
}
function get_COMPLETING_ALREADY() {
  _init_properties_JobSupport_kt__68f172();
  return COMPLETING_ALREADY;
}
var COMPLETING_ALREADY;
function get_COMPLETING_WAITING_CHILDREN() {
  _init_properties_JobSupport_kt__68f172();
  return COMPLETING_WAITING_CHILDREN;
}
var COMPLETING_WAITING_CHILDREN;
function get_COMPLETING_RETRY() {
  _init_properties_JobSupport_kt__68f172();
  return COMPLETING_RETRY;
}
var COMPLETING_RETRY;
function get_TOO_LATE_TO_CANCEL() {
  _init_properties_JobSupport_kt__68f172();
  return TOO_LATE_TO_CANCEL;
}
var TOO_LATE_TO_CANCEL;
function get_SEALED() {
  _init_properties_JobSupport_kt__68f172();
  return SEALED;
}
var SEALED;
function get_EMPTY_NEW() {
  _init_properties_JobSupport_kt__68f172();
  return EMPTY_NEW;
}
var EMPTY_NEW;
function get_EMPTY_ACTIVE() {
  _init_properties_JobSupport_kt__68f172();
  return EMPTY_ACTIVE;
}
var EMPTY_ACTIVE;
function _set_exceptionsHolder__tqm22h($this, value) {
  $this.o1d_1.kotlinx$atomicfu$value = value;
}
function _get_exceptionsHolder__nhszp($this) {
  return $this.o1d_1.kotlinx$atomicfu$value;
}
function allocateList($this) {
  return ArrayList.w(4);
}
function *_generator_invoke__zhh2q8($this, $this$sequence, $completion) {
  var state = $this.p1d_1.x15();
  if (state instanceof ChildHandleNode) {
    var tmp = $this$sequence.sm(state.u1d_1, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  } else {
    if (!(state == null) ? isInterface(state, Incomplete) : false) {
      var tmp68_safe_receiver = state.e1b();
      if (tmp68_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListHead.forEach' call
        var cur = tmp68_safe_receiver.f1b_1;
        while (!equals(cur, tmp68_safe_receiver)) {
          // Inline function 'kotlinx.coroutines.JobSupport.<get-children>.<anonymous>.<anonymous>.<anonymous>' call
          var it = cur;
          if (it instanceof ChildHandleNode) {
            var tmp_0 = $this$sequence.sm(it.u1d_1, $completion);
            if (tmp_0 === get_COROUTINE_SUSPENDED())
              tmp_0 = yield tmp_0;
          }
          cur = cur.f1b_1;
        }
      }
    }
  }
  return Unit_instance;
}
function finalizeFinishingState($this, state, proposedUpdate) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  var tmp46_safe_receiver = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
  var proposedException = tmp46_safe_receiver == null ? null : tmp46_safe_receiver.i15_1;
  var wasCancelling;
  // Inline function 'kotlinx.coroutines.internal.synchronized' call
  // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
  // Inline function 'kotlinx.coroutines.JobSupport.finalizeFinishingState.<anonymous>' call
  wasCancelling = state.v1d();
  var exceptions = state.w1d(proposedException);
  var finalCause = getFinalRootCause($this, state, exceptions);
  if (!(finalCause == null)) {
    addSuppressedExceptions($this, finalCause, exceptions);
  }
  var finalException = finalCause;
  var finalState = finalException == null ? proposedUpdate : finalException === proposedException ? proposedUpdate : new CompletedExceptionally(finalException);
  if (!(finalException == null)) {
    var handled = cancelParent($this, finalException) || $this.x16(finalException);
    if (handled) {
      (finalState instanceof CompletedExceptionally ? finalState : THROW_CCE()).r19();
    }
  }
  if (!wasCancelling) {
    $this.u16(finalException);
  }
  $this.h15(finalState);
  var casSuccess = $this.w14_1.atomicfu$compareAndSet(state, boxIncomplete(finalState));
  // Inline function 'kotlinx.coroutines.assert' call
  completeStateFinalization($this, state, finalState);
  return finalState;
}
function getFinalRootCause($this, state, exceptions) {
  if (exceptions.a1()) {
    if (state.v1d()) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      return JobCancellationException.d1e(null == null ? $this.g15() : null, null, $this);
    }
    return null;
  }
  var tmp$ret$2;
  $l$block: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var tmp0_iterator = exceptions.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'kotlinx.coroutines.JobSupport.getFinalRootCause.<anonymous>' call
      if (!(element instanceof CancellationException)) {
        tmp$ret$2 = element;
        break $l$block;
      }
    }
    tmp$ret$2 = null;
  }
  var firstNonCancellation = tmp$ret$2;
  if (!(firstNonCancellation == null))
    return firstNonCancellation;
  var first = exceptions.i1(0);
  if (first instanceof TimeoutCancellationException) {
    var tmp$ret$4;
    $l$block_0: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator_0 = exceptions.f1();
      while (tmp0_iterator_0.g1()) {
        var element_0 = tmp0_iterator_0.h1();
        // Inline function 'kotlinx.coroutines.JobSupport.getFinalRootCause.<anonymous>' call
        var tmp;
        if (!(element_0 === first)) {
          tmp = element_0 instanceof TimeoutCancellationException;
        } else {
          tmp = false;
        }
        if (tmp) {
          tmp$ret$4 = element_0;
          break $l$block_0;
        }
      }
      tmp$ret$4 = null;
    }
    var detailedTimeoutException = tmp$ret$4;
    if (!(detailedTimeoutException == null))
      return detailedTimeoutException;
  }
  return first;
}
function addSuppressedExceptions($this, rootCause, exceptions) {
  if (exceptions.j1() <= 1)
    return Unit_instance;
  var seenExceptions = identitySet(exceptions.j1());
  var unwrappedCause = unwrap(rootCause);
  var _iterator__ex2g4s = exceptions.f1();
  while (_iterator__ex2g4s.g1()) {
    var exception = _iterator__ex2g4s.h1();
    var unwrapped = unwrap(exception);
    var tmp;
    var tmp_0;
    if (!(unwrapped === rootCause) && !(unwrapped === unwrappedCause)) {
      tmp_0 = !(unwrapped instanceof CancellationException);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = seenExceptions.f(unwrapped);
    } else {
      tmp = false;
    }
    if (tmp) {
      addSuppressed(rootCause, unwrapped);
    }
  }
}
function tryFinalizeSimpleState($this, state, update) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  if (!$this.w14_1.atomicfu$compareAndSet(state, boxIncomplete(update)))
    return false;
  $this.u16(null);
  $this.h15(update);
  completeStateFinalization($this, state, update);
  return true;
}
function completeStateFinalization($this, state, update) {
  var tmp47_safe_receiver = $this.v15();
  if (tmp47_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    tmp47_safe_receiver.o18();
    $this.u15(NonDisposableHandle_instance);
  }
  var tmp48_safe_receiver = update instanceof CompletedExceptionally ? update : null;
  var cause = tmp48_safe_receiver == null ? null : tmp48_safe_receiver.i15_1;
  if (state instanceof JobNode) {
    try {
      state.n18(cause);
    } catch ($p) {
      if ($p instanceof Error) {
        var ex = $p;
        $this.o15(CompletionHandlerException.i19('Exception in completion handler ' + state.toString() + ' for ' + $this.toString(), ex));
      } else {
        throw $p;
      }
    }
  } else {
    var tmp49_safe_receiver = state.e1b();
    if (tmp49_safe_receiver == null)
      null;
    else {
      notifyCompletion(tmp49_safe_receiver, $this, cause);
    }
  }
}
function notifyCancelling($this, list, cause) {
  $this.u16(cause);
  list.k1d(4);
  // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers' call
  var exception = null;
  // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListHead.forEach' call
  var cur = list.f1b_1;
  while (!equals(cur, list)) {
    // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>' call
    var node = cur;
    var tmp;
    if (node instanceof JobNode) {
      // Inline function 'kotlinx.coroutines.JobSupport.notifyCancelling.<anonymous>' call
      tmp = node.d1b();
    } else {
      tmp = false;
    }
    if (tmp) {
      try {
        node.n18(cause);
      } catch ($p) {
        if ($p instanceof Error) {
          var ex = $p;
          var tmp50_safe_receiver = exception;
          var tmp_0;
          if (tmp50_safe_receiver == null) {
            tmp_0 = null;
          } else {
            // Inline function 'kotlin.apply' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>.<anonymous>' call
            addSuppressed(tmp50_safe_receiver, ex);
            tmp_0 = tmp50_safe_receiver;
          }
          if (tmp_0 == null) {
            // Inline function 'kotlin.run' call
            // Inline function 'kotlin.contracts.contract' call
            exception = CompletionHandlerException.i19('Exception in completion handler ' + node.toString() + ' for ' + $this.toString(), ex);
          }
        } else {
          throw $p;
        }
      }
    }
    cur = cur.f1b_1;
  }
  var tmp51_safe_receiver = exception;
  if (tmp51_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    $this.o15(tmp51_safe_receiver);
  }
  cancelParent($this, cause);
}
function cancelParent($this, cause) {
  if ($this.v16())
    return true;
  var isCancellation = cause instanceof CancellationException;
  var parent = $this.v15();
  if (parent === null || parent === NonDisposableHandle_instance) {
    return isCancellation;
  }
  return parent.o16(cause) || isCancellation;
}
function notifyCompletion(_this__u8e3s4, $this, cause) {
  _this__u8e3s4.k1d(1);
  // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers' call
  var exception = null;
  // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListHead.forEach' call
  var cur = _this__u8e3s4.f1b_1;
  while (!equals(cur, _this__u8e3s4)) {
    // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>' call
    var node = cur;
    var tmp;
    if (node instanceof JobNode) {
      // Inline function 'kotlinx.coroutines.JobSupport.notifyCompletion.<anonymous>' call
      tmp = true;
    } else {
      tmp = false;
    }
    if (tmp) {
      try {
        node.n18(cause);
      } catch ($p) {
        if ($p instanceof Error) {
          var ex = $p;
          var tmp50_safe_receiver = exception;
          var tmp_0;
          if (tmp50_safe_receiver == null) {
            tmp_0 = null;
          } else {
            // Inline function 'kotlin.apply' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>.<anonymous>' call
            addSuppressed(tmp50_safe_receiver, ex);
            tmp_0 = tmp50_safe_receiver;
          }
          if (tmp_0 == null) {
            // Inline function 'kotlin.run' call
            // Inline function 'kotlin.contracts.contract' call
            exception = CompletionHandlerException.i19('Exception in completion handler ' + node.toString() + ' for ' + $this.toString(), ex);
          }
        } else {
          throw $p;
        }
      }
    }
    cur = cur.f1b_1;
  }
  var tmp51_safe_receiver = exception;
  if (tmp51_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    $this.o15(tmp51_safe_receiver);
  }
}
function startInternal($this, state) {
  if (state instanceof Empty) {
    if (state.f1d_1)
      return 0;
    if (!$this.w14_1.atomicfu$compareAndSet(state, get_EMPTY_ACTIVE()))
      return -1;
    $this.a16();
    return 1;
  } else {
    if (state instanceof InactiveNodeList) {
      if (!$this.w14_1.atomicfu$compareAndSet(state, state.e1e_1))
        return -1;
      $this.a16();
      return 1;
    } else {
      return 0;
    }
  }
}
function promoteEmptyToNodeList($this, state) {
  var list = new NodeList();
  var update = state.f1d_1 ? list : new InactiveNodeList(list);
  $this.w14_1.atomicfu$compareAndSet(state, update);
}
function promoteSingleToNodeList($this, state) {
  state.k1b(new NodeList());
  // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListNode.nextNode' call
  var list = state.f1b_1;
  $this.w14_1.atomicfu$compareAndSet(state, list);
}
function joinInternal($this) {
  // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
  while (true) {
    // Inline function 'kotlinx.coroutines.JobSupport.joinInternal.<anonymous>' call
    var state = $this.x15();
    if (!(!(state == null) ? isInterface(state, Incomplete) : false))
      return false;
    if (startInternal($this, state) >= 0)
      return true;
  }
}
function joinSuspend($this, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
  cancellable.u19();
  // Inline function 'kotlinx.coroutines.JobSupport.joinSuspend.<anonymous>' call
  disposeOnCancellation(cancellable, invokeOnCompletion($this, VOID, new ResumeOnCompletion(cancellable)));
  return cancellable.g17();
}
function cancelMakeCompleting($this, cause) {
  // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
  while (true) {
    // Inline function 'kotlinx.coroutines.JobSupport.cancelMakeCompleting.<anonymous>' call
    var state = $this.x15();
    var tmp;
    if (!(!(state == null) ? isInterface(state, Incomplete) : false)) {
      tmp = true;
    } else {
      var tmp_0;
      if (state instanceof Finishing) {
        tmp_0 = state.f1e();
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    if (tmp) {
      return get_COMPLETING_ALREADY();
    }
    var proposedUpdate = new CompletedExceptionally(createCauseException($this, cause));
    var finalState = tryMakeCompleting($this, state, proposedUpdate);
    if (!(finalState === get_COMPLETING_RETRY()))
      return finalState;
  }
}
function createCauseException($this, cause) {
  var tmp;
  if (cause == null ? true : cause instanceof Error) {
    var tmp_0;
    if (cause == null) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      tmp_0 = JobCancellationException.d1e(null == null ? $this.g15() : null, null, $this);
    } else {
      tmp_0 = cause;
    }
    tmp = tmp_0;
  } else {
    tmp = ((!(cause == null) ? isInterface(cause, ParentJob) : false) ? cause : THROW_CCE()).r16();
  }
  return tmp;
}
function makeCancelling($this, cause) {
  var causeExceptionCache = null;
  // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
  while (true) {
    $l$block: {
      // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>' call
      var state = $this.x15();
      if (state instanceof Finishing) {
        // Inline function 'kotlinx.coroutines.internal.synchronized' call
        // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
        // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>' call
        if (state.g1e())
          return get_TOO_LATE_TO_CANCEL();
        var wasCancelling = state.v1d();
        if (!(cause == null) || !wasCancelling) {
          var tmp0_elvis_lhs = causeExceptionCache;
          var tmp;
          if (tmp0_elvis_lhs == null) {
            // Inline function 'kotlin.also' call
            var this_0 = createCauseException($this, cause);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>.<anonymous>' call
            causeExceptionCache = this_0;
            tmp = this_0;
          } else {
            tmp = tmp0_elvis_lhs;
          }
          var causeException = tmp;
          state.h1e(causeException);
        }
        // Inline function 'kotlin.takeIf' call
        var this_1 = state.i1e();
        // Inline function 'kotlin.contracts.contract' call
        var tmp_0;
        // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>.<anonymous>' call
        if (!wasCancelling) {
          tmp_0 = this_1;
        } else {
          tmp_0 = null;
        }
        var notifyRootCause = tmp_0;
        if (notifyRootCause == null)
          null;
        else {
          // Inline function 'kotlin.let' call
          // Inline function 'kotlin.contracts.contract' call
          notifyCancelling($this, state.l1d_1, notifyRootCause);
        }
        return get_COMPLETING_ALREADY();
      } else {
        if (!(state == null) ? isInterface(state, Incomplete) : false) {
          var tmp0_elvis_lhs_0 = causeExceptionCache;
          var tmp_1;
          if (tmp0_elvis_lhs_0 == null) {
            // Inline function 'kotlin.also' call
            var this_2 = createCauseException($this, cause);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>' call
            causeExceptionCache = this_2;
            tmp_1 = this_2;
          } else {
            tmp_1 = tmp0_elvis_lhs_0;
          }
          var causeException_0 = tmp_1;
          if (state.d15()) {
            if (tryMakeCancelling($this, state, causeException_0))
              return get_COMPLETING_ALREADY();
          } else {
            var finalState = tryMakeCompleting($this, state, new CompletedExceptionally(causeException_0));
            if (finalState === get_COMPLETING_ALREADY()) {
              // Inline function 'kotlin.error' call
              var message = 'Cannot happen in ' + toString(state);
              throw IllegalStateException.m5(toString(message));
            } else if (finalState === get_COMPLETING_RETRY()) {
              break $l$block;
            } else
              return finalState;
          }
        } else {
          return get_TOO_LATE_TO_CANCEL();
        }
      }
    }
  }
}
function getOrPromoteCancellingList($this, state) {
  var tmp0_elvis_lhs = state.e1b();
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var tmp_0;
    if (state instanceof Empty) {
      tmp_0 = new NodeList();
    } else {
      if (state instanceof JobNode) {
        promoteSingleToNodeList($this, state);
        tmp_0 = null;
      } else {
        var message = 'State should have list: ' + toString(state);
        throw IllegalStateException.m5(toString(message));
      }
    }
    tmp = tmp_0;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function tryMakeCancelling($this, state, rootCause) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return false;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var list = tmp;
  var cancelling = new Finishing(list, false, rootCause);
  if (!$this.w14_1.atomicfu$compareAndSet(state, cancelling))
    return false;
  notifyCancelling($this, list, rootCause);
  return true;
}
function tryMakeCompleting($this, state, proposedUpdate) {
  if (!(!(state == null) ? isInterface(state, Incomplete) : false))
    return get_COMPLETING_ALREADY();
  var tmp;
  var tmp_0;
  var tmp_1;
  if (state instanceof Empty) {
    tmp_1 = true;
  } else {
    tmp_1 = state instanceof JobNode;
  }
  if (tmp_1) {
    tmp_0 = !(state instanceof ChildHandleNode);
  } else {
    tmp_0 = false;
  }
  if (tmp_0) {
    tmp = !(proposedUpdate instanceof CompletedExceptionally);
  } else {
    tmp = false;
  }
  if (tmp) {
    if (tryFinalizeSimpleState($this, state, proposedUpdate)) {
      return proposedUpdate;
    }
    return get_COMPLETING_RETRY();
  }
  return tryMakeCompletingSlowPath($this, state, proposedUpdate);
}
function tryMakeCompletingSlowPath($this, state, proposedUpdate) {
  var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return get_COMPLETING_RETRY();
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var list = tmp;
  var tmp1_elvis_lhs = state instanceof Finishing ? state : null;
  var finishing = tmp1_elvis_lhs == null ? new Finishing(list, false, null) : tmp1_elvis_lhs;
  var notifyRootCause;
  // Inline function 'kotlinx.coroutines.internal.synchronized' call
  // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
  if (finishing.f1e())
    return get_COMPLETING_ALREADY();
  finishing.j1e(true);
  if (!(finishing === state)) {
    if (!$this.w14_1.atomicfu$compareAndSet(state, finishing))
      return get_COMPLETING_RETRY();
  }
  // Inline function 'kotlinx.coroutines.assert' call
  var wasCancelling = finishing.v1d();
  var tmp65_safe_receiver = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
  if (tmp65_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    finishing.h1e(tmp65_safe_receiver.i15_1);
  }
  // Inline function 'kotlin.takeIf' call
  var this_0 = finishing.i1e();
  // Inline function 'kotlin.contracts.contract' call
  var tmp_0;
  // Inline function 'kotlinx.coroutines.JobSupport.tryMakeCompletingSlowPath.<anonymous>.<anonymous>' call
  if (!wasCancelling) {
    tmp_0 = this_0;
  } else {
    tmp_0 = null;
  }
  notifyRootCause = tmp_0;
  if (notifyRootCause == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    notifyCancelling($this, list, notifyRootCause);
  }
  var child = nextChild(list, $this);
  if (!(child == null) && tryWaitForChild($this, finishing, child, proposedUpdate))
    return get_COMPLETING_WAITING_CHILDREN();
  list.k1d(2);
  var anotherChild = nextChild(list, $this);
  if (!(anotherChild == null) && tryWaitForChild($this, finishing, anotherChild, proposedUpdate))
    return get_COMPLETING_WAITING_CHILDREN();
  return finalizeFinishingState($this, finishing, proposedUpdate);
}
function _get_exceptionOrNull__b3j7js(_this__u8e3s4, $this) {
  var tmp67_safe_receiver = _this__u8e3s4 instanceof CompletedExceptionally ? _this__u8e3s4 : null;
  return tmp67_safe_receiver == null ? null : tmp67_safe_receiver.i15_1;
}
function tryWaitForChild($this, state, child, proposedUpdate) {
  var $this_0 = $this;
  var state_0 = state;
  var child_0 = child;
  var proposedUpdate_0 = proposedUpdate;
  $l$1: do {
    $l$0: do {
      var handle = invokeOnCompletion(child_0.u1d_1, false, new ChildCompletion($this_0, state_0, child_0, proposedUpdate_0));
      if (!(handle === NonDisposableHandle_instance))
        return true;
      var tmp0_elvis_lhs = nextChild(child_0, $this_0);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return false;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var nextChild_0 = tmp;
      $this_0 = $this_0;
      state_0 = state_0;
      child_0 = nextChild_0;
      proposedUpdate_0 = proposedUpdate_0;
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
function continueCompleting($this, state, lastChild, proposedUpdate) {
  // Inline function 'kotlinx.coroutines.assert' call
  var waitChild = nextChild(lastChild, $this);
  if (!(waitChild == null) && tryWaitForChild($this, state, waitChild, proposedUpdate))
    return Unit_instance;
  state.l1d_1.k1d(2);
  var waitChildAgain = nextChild(lastChild, $this);
  if (!(waitChildAgain == null) && tryWaitForChild($this, state, waitChildAgain, proposedUpdate)) {
    return Unit_instance;
  }
  var finalState = finalizeFinishingState($this, state, proposedUpdate);
  $this.n15(finalState);
}
function nextChild(_this__u8e3s4, $this) {
  var cur = _this__u8e3s4;
  $l$loop: while (true) {
    // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListNode.isRemoved' call
    if (!cur.h1b_1) {
      break $l$loop;
    }
    // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListNode.prevNode' call
    cur = cur.g1b_1;
  }
  $l$loop_0: while (true) {
    // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListNode.nextNode' call
    cur = cur.f1b_1;
    // Inline function 'kotlinx.coroutines.internal.LockFreeLinkedListNode.isRemoved' call
    if (cur.h1b_1)
      continue $l$loop_0;
    if (cur instanceof ChildHandleNode)
      return cur;
    if (cur instanceof NodeList)
      return null;
  }
}
function stateString($this, state) {
  var tmp;
  if (state instanceof Finishing) {
    tmp = state.v1d() ? 'Cancelling' : state.f1e() ? 'Completing' : 'Active';
  } else {
    if (!(state == null) ? isInterface(state, Incomplete) : false) {
      tmp = state.d15() ? 'Active' : 'New';
    } else {
      if (state instanceof CompletedExceptionally) {
        tmp = 'Cancelled';
      } else {
        tmp = 'Completed';
      }
    }
  }
  return tmp;
}
function awaitSuspend($this, $completion) {
  // Inline function 'kotlinx.coroutines.JobSupport.awaitSuspend.<anonymous>' call
  var cont = new AwaitContinuation(intercepted($completion), $this);
  cont.u19();
  disposeOnCancellation(cont, invokeOnCompletion($this, VOID, new ResumeAwaitOnCompletion(cont)));
  return cont.g17();
}
function JobSupport$_get_children_$slambda_k839f8_0(this$0) {
  var i = new JobSupport$_get_children_$slambda_k839f8(this$0);
  var l = function ($this$sequence, $completion) {
    return i.a1f($this$sequence, $completion);
  };
  l.$arity = 1;
  return l;
}
function boxIncomplete(_this__u8e3s4) {
  _init_properties_JobSupport_kt__68f172();
  var tmp;
  if (!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Incomplete) : false) {
    tmp = new IncompleteStateBox(_this__u8e3s4);
  } else {
    tmp = _this__u8e3s4;
  }
  return tmp;
}
function unboxState(_this__u8e3s4) {
  _init_properties_JobSupport_kt__68f172();
  var tmp74_safe_receiver = _this__u8e3s4 instanceof IncompleteStateBox ? _this__u8e3s4 : null;
  var tmp0_elvis_lhs = tmp74_safe_receiver == null ? null : tmp74_safe_receiver.r1f_1;
  return tmp0_elvis_lhs == null ? _this__u8e3s4 : tmp0_elvis_lhs;
}
function handlesExceptionF($this) {
  var tmp = $this.v15();
  var tmp75_safe_receiver = tmp instanceof ChildHandleNode ? tmp : null;
  var tmp0_elvis_lhs = tmp75_safe_receiver == null ? null : tmp75_safe_receiver.c1b();
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    return false;
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  var parentJob = tmp_0;
  while (true) {
    if (parentJob.w16())
      return true;
    var tmp_1 = parentJob.v15();
    var tmp76_safe_receiver = tmp_1 instanceof ChildHandleNode ? tmp_1 : null;
    var tmp1_elvis_lhs = tmp76_safe_receiver == null ? null : tmp76_safe_receiver.c1b();
    var tmp_2;
    if (tmp1_elvis_lhs == null) {
      return false;
    } else {
      tmp_2 = tmp1_elvis_lhs;
    }
    parentJob = tmp_2;
  }
}
var properties_initialized_JobSupport_kt_5iq8a4;
function _init_properties_JobSupport_kt__68f172() {
  if (!properties_initialized_JobSupport_kt_5iq8a4) {
    properties_initialized_JobSupport_kt_5iq8a4 = true;
    COMPLETING_ALREADY = new Symbol('COMPLETING_ALREADY');
    COMPLETING_WAITING_CHILDREN = new Symbol('COMPLETING_WAITING_CHILDREN');
    COMPLETING_RETRY = new Symbol('COMPLETING_RETRY');
    TOO_LATE_TO_CANCEL = new Symbol('TOO_LATE_TO_CANCEL');
    SEALED = new Symbol('SEALED');
    EMPTY_NEW = new Empty(false);
    EMPTY_ACTIVE = new Empty(true);
  }
}
function SupervisorJob(parent) {
  parent = parent === VOID ? null : parent;
  return new SupervisorJobImpl(parent);
}
function withTimeout(timeMillis, block, $completion) {
  if (timeMillis.g2(new Long(0, 0)) <= 0)
    throw TimeoutCancellationException.o1g('Timed out immediately');
  // Inline function 'kotlinx.coroutines.withTimeout.<anonymous>' call
  return setupTimeout(new TimeoutCoroutine(timeMillis, $completion), block);
}
function setupTimeout(coroutine, block) {
  var cont = coroutine.r17_1;
  var context = cont.gd();
  disposeOnCompletion(coroutine, get_delay(context).e1c(coroutine.t1g_1, coroutine, coroutine.b15_1));
  return startUndispatchedOrReturnIgnoreTimeout(coroutine, coroutine, block);
}
function TimeoutCancellationException_0(time, delay, coroutine) {
  var tmp77_safe_receiver = isInterface(delay, DelayWithTimeoutDiagnostics) ? delay : null;
  var tmp;
  if (tmp77_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.time.Companion.milliseconds' call
    Companion_getInstance();
    var tmp$ret$0 = toDuration(time, DurationUnit_MILLISECONDS_getInstance());
    tmp = tmp77_safe_receiver.f1c(tmp$ret$0);
  }
  var tmp0_elvis_lhs = tmp;
  var message = tmp0_elvis_lhs == null ? 'Timed out waiting for ' + time.toString() + ' ms' : tmp0_elvis_lhs;
  return TimeoutCancellationException.n1g(message, coroutine);
}
var Unconfined_instance;
function Unconfined_getInstance() {
  if (Unconfined_instance === VOID)
    Unconfined.v1g();
  return Unconfined_instance;
}
var Key_instance_3;
function Key_getInstance_2() {
  return Key_instance_3;
}
var BufferOverflow_SUSPEND_instance;
var BufferOverflow_DROP_OLDEST_instance;
var BufferOverflow_DROP_LATEST_instance;
var BufferOverflow_entriesInitialized;
function BufferOverflow_initEntries() {
  if (BufferOverflow_entriesInitialized)
    return Unit_instance;
  BufferOverflow_entriesInitialized = true;
  BufferOverflow_SUSPEND_instance = new BufferOverflow('SUSPEND', 0);
  BufferOverflow_DROP_OLDEST_instance = new BufferOverflow('DROP_OLDEST', 1);
  BufferOverflow_DROP_LATEST_instance = new BufferOverflow('DROP_LATEST', 2);
}
function BufferOverflow_SUSPEND_getInstance() {
  BufferOverflow_initEntries();
  return BufferOverflow_SUSPEND_instance;
}
function BufferOverflow_DROP_OLDEST_getInstance() {
  BufferOverflow_initEntries();
  return BufferOverflow_DROP_OLDEST_instance;
}
function BufferOverflow_DROP_LATEST_getInstance() {
  BufferOverflow_initEntries();
  return BufferOverflow_DROP_LATEST_instance;
}
function get_NULL_SEGMENT() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return NULL_SEGMENT;
}
var NULL_SEGMENT;
function get_SEGMENT_SIZE() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return SEGMENT_SIZE;
}
var SEGMENT_SIZE;
function get_EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS;
}
var EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS;
function get_BUFFERED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return BUFFERED;
}
var BUFFERED;
function get_IN_BUFFER() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return IN_BUFFER;
}
var IN_BUFFER;
function get_RESUMING_BY_RCV() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return RESUMING_BY_RCV;
}
var RESUMING_BY_RCV;
function get_RESUMING_BY_EB() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return RESUMING_BY_EB;
}
var RESUMING_BY_EB;
function get_POISONED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return POISONED;
}
var POISONED;
function get_DONE_RCV() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return DONE_RCV;
}
var DONE_RCV;
function get_INTERRUPTED_SEND() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return INTERRUPTED_SEND;
}
var INTERRUPTED_SEND;
function get_INTERRUPTED_RCV() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return INTERRUPTED_RCV;
}
var INTERRUPTED_RCV;
function get_CHANNEL_CLOSED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return CHANNEL_CLOSED;
}
var CHANNEL_CLOSED;
function get_SUSPEND() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return SUSPEND;
}
var SUSPEND;
function get_SUSPEND_NO_WAITER() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return SUSPEND_NO_WAITER;
}
var SUSPEND_NO_WAITER;
function get_FAILED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return FAILED;
}
var FAILED;
var NO_RECEIVE_RESULT;
var CLOSE_HANDLER_CLOSED;
var CLOSE_HANDLER_INVOKED;
function get_NO_CLOSE_CAUSE() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return NO_CLOSE_CAUSE;
}
var NO_CLOSE_CAUSE;
function setElementLazy($this, index, value) {
  // Inline function 'kotlinx.atomicfu.AtomicRef.lazySet' call
  $this.d1h_1.atomicfu$get(imul(index, 2)).kotlinx$atomicfu$value = value;
}
function _get_bufferEndCounter__2d4hee($this) {
  return $this.s1h_1.kotlinx$atomicfu$value;
}
function _get_isRendezvousOrUnlimited__3mdufi($this) {
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.<get-isRendezvousOrUnlimited>.<anonymous>' call
  var it = _get_bufferEndCounter__2d4hee($this);
  return it.equals(new Long(0, 0)) || it.equals(new Long(-1, 2147483647));
}
function *_generator_send__qhx0g0($this, element, $completion) {
  $l$block_5: {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl' call
    var segment = $this.u1h_1.kotlinx$atomicfu$value;
    $l$loop_0: while (true) {
      var sendersAndCloseStatusCur = $this.q1h_1.atomicfu$getAndIncrement$long();
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
      var s = sendersAndCloseStatusCur.n4(new Long(-1, 268435455));
      var closed = _get_isClosedForSend0__kxgf9m(sendersAndCloseStatusCur, $this);
      // Inline function 'kotlin.Long.div' call
      var other = get_SEGMENT_SIZE();
      var id = s.e4(toLong(other));
      // Inline function 'kotlin.Long.rem' call
      var other_0 = get_SEGMENT_SIZE();
      var i = s.f4(toLong(other_0)).q2();
      if (!segment.c19_1.equals(id)) {
        var tmp0_elvis_lhs = findSegmentSend($this, id, segment);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          var tmp_0;
          if (closed) {
            var tmp_1 = onClosedSend($this, element, $completion);
            if (tmp_1 === get_COROUTINE_SUSPENDED())
              tmp_1 = yield tmp_1;
            break $l$block_5;
          } else {
            continue $l$loop_0;
          }
        } else {
          tmp = tmp0_elvis_lhs;
        }
        segment = tmp;
      }
      switch (updateCellSend($this, segment, i, element, s, null, closed)) {
        case 0:
          segment.m1i();
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.send.<anonymous>' call

          break $l$block_5;
        case 1:
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.send.<anonymous>' call

          break $l$block_5;
        case 2:
          if (closed) {
            segment.c1i();
            var tmp_2 = onClosedSend($this, element, $completion);
            if (tmp_2 === get_COROUTINE_SUSPENDED())
              tmp_2 = yield tmp_2;
            break $l$block_5;
          }

          var tmp83_safe_receiver = (!(null == null) ? isInterface(null, Waiter) : false) ? null : null;
          if (tmp83_safe_receiver == null)
            null;
          else {
            prepareSenderForSuspension(tmp83_safe_receiver, $this, segment, i);
          }

          // Inline function 'kotlinx.coroutines.assert' call

          break $l$block_5;
        case 4:
          if (s.g2($this.o1i()) < 0) {
            segment.m1i();
          }

          var tmp_3 = onClosedSend($this, element, $completion);
          if (tmp_3 === get_COROUTINE_SUSPENDED())
            tmp_3 = yield tmp_3;
          break $l$block_5;
        case 5:
          segment.m1i();
          continue $l$loop_0;
        case 3:
          var segm = segment;
          var tmp_4 = sendOnNoWaiterSuspend($this, segm, i, element, s, $completion);
          if (tmp_4 === get_COROUTINE_SUSPENDED())
            tmp_4 = yield tmp_4;
          break $l$block_5;
      }
    }
  }
  return Unit_instance;
}
function onClosedSend($this, element, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
  cancellable.u19();
  $l$block: {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.onClosedSend.<anonymous>' call
    var tmp79_safe_receiver = $this.p1h_1;
    var tmp80_safe_receiver = tmp79_safe_receiver == null ? null : callUndeliveredElementCatchingException(tmp79_safe_receiver, element);
    if (tmp80_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      addSuppressed(tmp80_safe_receiver, $this.p1i());
      // Inline function 'kotlinx.coroutines.resumeWithStackTrace' call
      // Inline function 'kotlin.Companion.failure' call
      var exception = recoverStackTrace(tmp80_safe_receiver, cancellable);
      var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception));
      cancellable.id(tmp$ret$0);
      break $l$block;
    }
    // Inline function 'kotlinx.coroutines.resumeWithStackTrace' call
    var exception_0 = $this.p1i();
    // Inline function 'kotlin.Companion.failure' call
    var exception_1 = recoverStackTrace(exception_0, cancellable);
    var tmp$ret$3 = _Result___init__impl__xyqfz8(createFailure(exception_1));
    cancellable.id(tmp$ret$3);
  }
  return cancellable.g17();
}
function sendOnNoWaiterSuspend($this, segment, index, element, s, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutineReusable.<anonymous>' call
  var cancellable = getOrCreateCancellableContinuation(intercepted($completion));
  try {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendOnNoWaiterSuspend.<anonymous>' call
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImplOnNoWaiter' call
    switch (updateCellSend($this, segment, index, element, s, cancellable, false)) {
      case 0:
        segment.m1i();
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendOnNoWaiterSuspend.<anonymous>.<anonymous>' call

        // Inline function 'kotlin.coroutines.resume' call

        // Inline function 'kotlin.Companion.success' call

        var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
        cancellable.id(tmp$ret$0);
        break;
      case 1:
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendOnNoWaiterSuspend.<anonymous>.<anonymous>' call

        // Inline function 'kotlin.coroutines.resume' call

        // Inline function 'kotlin.Companion.success' call

        var tmp$ret$2 = _Result___init__impl__xyqfz8(Unit_instance);
        cancellable.id(tmp$ret$2);
        break;
      case 2:
        prepareSenderForSuspension(cancellable, $this, segment, index);
        break;
      case 4:
        if (s.g2($this.o1i()) < 0) {
          segment.m1i();
        }

        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendOnNoWaiterSuspend.<anonymous>.<anonymous>' call

        onClosedSendOnNoWaiterSuspend($this, element, cancellable);
        break;
      case 5:
        segment.m1i();
        $l$block_4: {
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl' call
          var segment_0 = $this.u1h_1.kotlinx$atomicfu$value;
          $l$loop_0: while (true) {
            var sendersAndCloseStatusCur = $this.q1h_1.atomicfu$getAndIncrement$long();
            // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
            var s_0 = sendersAndCloseStatusCur.n4(new Long(-1, 268435455));
            var closed = _get_isClosedForSend0__kxgf9m(sendersAndCloseStatusCur, $this);
            // Inline function 'kotlin.Long.div' call
            var other = get_SEGMENT_SIZE();
            var id = s_0.e4(toLong(other));
            // Inline function 'kotlin.Long.rem' call
            var other_0 = get_SEGMENT_SIZE();
            var i = s_0.f4(toLong(other_0)).q2();
            if (!segment_0.c19_1.equals(id)) {
              var tmp0_elvis_lhs = findSegmentSend($this, id, segment_0);
              var tmp;
              if (tmp0_elvis_lhs == null) {
                var tmp_0;
                if (closed) {
                  onClosedSendOnNoWaiterSuspend($this, element, cancellable);
                  break $l$block_4;
                } else {
                  continue $l$loop_0;
                }
              } else {
                tmp = tmp0_elvis_lhs;
              }
              segment_0 = tmp;
            }
            switch (updateCellSend($this, segment_0, i, element, s_0, cancellable, closed)) {
              case 0:
                segment_0.m1i();
                // Inline function 'kotlin.coroutines.resume' call

                // Inline function 'kotlin.Companion.success' call

                var tmp$ret$8 = _Result___init__impl__xyqfz8(Unit_instance);
                cancellable.id(tmp$ret$8);
                break $l$block_4;
              case 1:
                // Inline function 'kotlin.coroutines.resume' call

                // Inline function 'kotlin.Companion.success' call

                var tmp$ret$10 = _Result___init__impl__xyqfz8(Unit_instance);
                cancellable.id(tmp$ret$10);
                break $l$block_4;
              case 2:
                if (closed) {
                  segment_0.c1i();
                  onClosedSendOnNoWaiterSuspend($this, element, cancellable);
                  break $l$block_4;
                }

                var tmp83_safe_receiver = (!(cancellable == null) ? isInterface(cancellable, Waiter) : false) ? cancellable : null;
                if (tmp83_safe_receiver == null)
                  null;
                else {
                  prepareSenderForSuspension(tmp83_safe_receiver, $this, segment_0, i);
                }

                // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImplOnNoWaiter.<anonymous>' call

                break $l$block_4;
              case 4:
                if (s_0.g2($this.o1i()) < 0) {
                  segment_0.m1i();
                }

                onClosedSendOnNoWaiterSuspend($this, element, cancellable);
                break $l$block_4;
              case 5:
                segment_0.m1i();
                continue $l$loop_0;
              case 3:
                var message = 'unexpected';
                throw IllegalStateException.m5(toString(message));
            }
          }
        }

        break;
      default:
        // Inline function 'kotlin.error' call

        var message_0 = 'unexpected';
        throw IllegalStateException.m5(toString(message_0));
    }
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      cancellable.c1a();
      throw e;
    } else {
      throw $p;
    }
  }
  return cancellable.g17();
}
function prepareSenderForSuspension(_this__u8e3s4, $this, segment, index) {
  _this__u8e3s4.i1a(segment, index + get_SEGMENT_SIZE() | 0);
}
function onClosedSendOnNoWaiterSuspend($this, element, cont) {
  var tmp81_safe_receiver = $this.p1h_1;
  if (tmp81_safe_receiver == null)
    null;
  else {
    callUndeliveredElement(tmp81_safe_receiver, element, cont.gd());
  }
  // Inline function 'kotlin.coroutines.resumeWithException' call
  // Inline function 'kotlin.Companion.failure' call
  var exception = recoverStackTrace($this.p1i(), cont);
  var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception));
  cont.id(tmp$ret$0);
}
function updateCellSend($this, segment, index, element, s, waiter, closed) {
  segment.g1h(index, element);
  if (closed)
    return updateCellSendSlow($this, segment, index, element, s, waiter, closed);
  var state = segment.k1h(index);
  if (state === null) {
    if (bufferOrRendezvousSend($this, s)) {
      if (segment.m1h(index, null, get_BUFFERED())) {
        return 1;
      }
    } else {
      if (waiter == null) {
        return 3;
      } else {
        if (segment.m1h(index, null, waiter))
          return 2;
      }
    }
  } else {
    if (!(state == null) ? isInterface(state, Waiter) : false) {
      segment.j1h(index);
      var tmp;
      if (tryResumeReceiver(state, $this, element)) {
        segment.l1h(index, get_DONE_RCV());
        $this.q1i();
        tmp = 0;
      } else {
        if (!(segment.n1h(index, get_INTERRUPTED_RCV()) === get_INTERRUPTED_RCV())) {
          segment.a1i(index, true);
        }
        tmp = 5;
      }
      return tmp;
    }
  }
  return updateCellSendSlow($this, segment, index, element, s, waiter, closed);
}
function updateCellSendSlow($this, segment, index, element, s, waiter, closed) {
  while (true) {
    var state = segment.k1h(index);
    if (state === null) {
      if (bufferOrRendezvousSend($this, s) && !closed) {
        if (segment.m1h(index, null, get_BUFFERED())) {
          return 1;
        }
      } else {
        if (closed) {
          if (segment.m1h(index, null, get_INTERRUPTED_SEND())) {
            segment.a1i(index, false);
            return 4;
          }
        } else if (waiter == null)
          return 3;
        else if (segment.m1h(index, null, waiter))
          return 2;
      }
    } else if (state === get_IN_BUFFER()) {
      if (segment.m1h(index, state, get_BUFFERED())) {
        return 1;
      }
    } else if (state === get_INTERRUPTED_RCV()) {
      segment.j1h(index);
      return 5;
    } else if (state === get_POISONED()) {
      segment.j1h(index);
      return 5;
    } else if (state === get_CHANNEL_CLOSED()) {
      segment.j1h(index);
      completeCloseOrCancel($this);
      return 4;
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      segment.j1h(index);
      var tmp;
      if (state instanceof WaiterEB) {
        tmp = state.r1i_1;
      } else {
        tmp = state;
      }
      var receiver = tmp;
      var tmp_0;
      if (tryResumeReceiver(receiver, $this, element)) {
        segment.l1h(index, get_DONE_RCV());
        $this.q1i();
        tmp_0 = 0;
      } else {
        if (!(segment.n1h(index, get_INTERRUPTED_RCV()) === get_INTERRUPTED_RCV())) {
          segment.a1i(index, true);
        }
        tmp_0 = 5;
      }
      return tmp_0;
    }
  }
}
function shouldSendSuspend0($this, curSendersAndCloseStatus) {
  if (_get_isClosedForSend0__kxgf9m(curSendersAndCloseStatus, $this))
    return false;
  // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
  var tmp$ret$0 = curSendersAndCloseStatus.n4(new Long(-1, 268435455));
  return !bufferOrRendezvousSend($this, tmp$ret$0);
}
function bufferOrRendezvousSend($this, curSenders) {
  var tmp;
  if (curSenders.g2(_get_bufferEndCounter__2d4hee($this)) < 0) {
    tmp = true;
  } else {
    // Inline function 'kotlin.Long.plus' call
    var this_0 = $this.o1i();
    var other = $this.o1h_1;
    var tmp$ret$0 = this_0.c4(toLong(other));
    tmp = curSenders.g2(tmp$ret$0) < 0;
  }
  return tmp;
}
function tryResumeReceiver(_this__u8e3s4, $this, element) {
  var tmp;
  if (isInterface(_this__u8e3s4, SelectInstance)) {
    tmp = _this__u8e3s4.z1i($this, element);
  } else {
    if (_this__u8e3s4 instanceof ReceiveCatching) {
      if (!(_this__u8e3s4 instanceof ReceiveCatching))
        THROW_CCE();
      var tmp_0 = Companion_getInstance_0().x1i(element);
      var tmp86_safe_receiver = $this.p1h_1;
      tmp = tryResume0(_this__u8e3s4.y1i_1, new ChannelResult(tmp_0), tmp86_safe_receiver == null ? null : bindCancellationFunResult(tmp86_safe_receiver, $this));
    } else {
      if (_this__u8e3s4 instanceof BufferedChannelIterator) {
        if (!(_this__u8e3s4 instanceof BufferedChannelIterator))
          THROW_CCE();
        tmp = _this__u8e3s4.v1i(element);
      } else {
        if (isInterface(_this__u8e3s4, CancellableContinuation)) {
          if (!isInterface(_this__u8e3s4, CancellableContinuation))
            THROW_CCE();
          var tmp87_safe_receiver = $this.p1h_1;
          tmp = tryResume0(_this__u8e3s4, element, tmp87_safe_receiver == null ? null : bindCancellationFun_0(tmp87_safe_receiver, $this));
        } else {
          var message = 'Unexpected receiver type: ' + toString(_this__u8e3s4);
          throw IllegalStateException.m5(toString(message));
        }
      }
    }
  }
  return tmp;
}
function *_generator_receive__aoggq9($this, $completion) {
  var tmp$ret$0;
  $l$block: {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImpl' call
    var segment = $this.v1h_1.kotlinx$atomicfu$value;
    $l$loop_0: while (true) {
      if ($this.a1j()) {
        throw recoverStackTrace_0(_get_receiveException__foorc1($this));
      }
      var r = $this.r1h_1.atomicfu$getAndIncrement$long();
      // Inline function 'kotlin.Long.div' call
      var other = get_SEGMENT_SIZE();
      var id = r.e4(toLong(other));
      // Inline function 'kotlin.Long.rem' call
      var other_0 = get_SEGMENT_SIZE();
      var i = r.f4(toLong(other_0)).q2();
      if (!segment.c19_1.equals(id)) {
        var tmp0_elvis_lhs = findSegmentReceive($this, id, segment);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          continue $l$loop_0;
        } else {
          tmp = tmp0_elvis_lhs;
        }
        segment = tmp;
      }
      var updCellResult = updateCellReceive($this, segment, i, r, null);
      var tmp_0;
      if (updCellResult === _get_SUSPEND_$accessor$yt74tm_ccb8g1()) {
        var tmp92_safe_receiver = (!(null == null) ? isInterface(null, Waiter) : false) ? null : null;
        if (tmp92_safe_receiver == null)
          null;
        else {
          prepareReceiverForSuspension(tmp92_safe_receiver, $this, segment, i);
        }
        var message = 'unexpected';
        throw IllegalStateException.m5(toString(message));
      } else if (updCellResult === _get_FAILED_$accessor$yt74tm_h47uk8()) {
        if (r.g2($this.b1j()) < 0) {
          segment.m1i();
        }
        continue $l$loop_0;
      } else if (updCellResult === _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky()) {
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receive.<anonymous>' call
        var segm = segment;
        var tmp_1 = receiveOnNoWaiterSuspend($this, segm, i, r, $completion);
        if (tmp_1 === get_COROUTINE_SUSPENDED())
          tmp_1 = yield tmp_1;
        tmp_0 = tmp_1;
      } else {
        segment.m1i();
        return (updCellResult == null ? true : !(updCellResult == null)) ? updCellResult : THROW_CCE();
      }
      tmp$ret$0 = tmp_0;
      break $l$block;
    }
  }
  return tmp$ret$0;
}
function receiveOnNoWaiterSuspend($this, segment, index, r, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutineReusable.<anonymous>' call
  var cancellable = getOrCreateCancellableContinuation(intercepted($completion));
  try {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveOnNoWaiterSuspend.<anonymous>' call
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImplOnNoWaiter' call
    var updCellResult = updateCellReceive($this, segment, index, r, cancellable);
    if (updCellResult === _get_SUSPEND_$accessor$yt74tm_ccb8g1()) {
      prepareReceiverForSuspension(cancellable, $this, segment, index);
    } else if (updCellResult === _get_FAILED_$accessor$yt74tm_h47uk8()) {
      if (r.g2($this.b1j()) < 0) {
        segment.m1i();
      }
      $l$block_0: {
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImpl' call
        var segment_0 = $this.v1h_1.kotlinx$atomicfu$value;
        $l$loop_0: while (true) {
          if ($this.a1j()) {
            onClosedReceiveOnNoWaiterSuspend($this, cancellable);
            break $l$block_0;
          }
          var r_0 = $this.r1h_1.atomicfu$getAndIncrement$long();
          // Inline function 'kotlin.Long.div' call
          var other = get_SEGMENT_SIZE();
          var id = r_0.e4(toLong(other));
          // Inline function 'kotlin.Long.rem' call
          var other_0 = get_SEGMENT_SIZE();
          var i = r_0.f4(toLong(other_0)).q2();
          if (!segment_0.c19_1.equals(id)) {
            var tmp0_elvis_lhs = findSegmentReceive($this, id, segment_0);
            var tmp;
            if (tmp0_elvis_lhs == null) {
              continue $l$loop_0;
            } else {
              tmp = tmp0_elvis_lhs;
            }
            segment_0 = tmp;
          }
          var updCellResult_0 = updateCellReceive($this, segment_0, i, r_0, cancellable);
          var tmp_0;
          if (updCellResult_0 === _get_SUSPEND_$accessor$yt74tm_ccb8g1()) {
            var tmp92_safe_receiver = (!(cancellable == null) ? isInterface(cancellable, Waiter) : false) ? cancellable : null;
            if (tmp92_safe_receiver == null)
              null;
            else {
              prepareReceiverForSuspension(tmp92_safe_receiver, $this, segment_0, i);
            }
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImplOnNoWaiter.<anonymous>' call
            tmp_0 = Unit_instance;
          } else if (updCellResult_0 === _get_FAILED_$accessor$yt74tm_h47uk8()) {
            if (r_0.g2($this.b1j()) < 0) {
              segment_0.m1i();
            }
            continue $l$loop_0;
          } else if (updCellResult_0 === _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky()) {
            var message = 'unexpected';
            throw IllegalStateException.m5(toString(message));
          } else {
            segment_0.m1i();
            var element = (updCellResult_0 == null ? true : !(updCellResult_0 == null)) ? updCellResult_0 : THROW_CCE();
            var tmp88_safe_receiver = $this.p1h_1;
            var onCancellation = tmp88_safe_receiver == null ? null : bindCancellationFun_0(tmp88_safe_receiver, $this);
            cancellable.e18(element, onCancellation);
            tmp_0 = Unit_instance;
          }
          break $l$block_0;
        }
      }
    } else {
      segment.m1i();
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveOnNoWaiterSuspend.<anonymous>.<anonymous>' call
      var element_0 = (updCellResult == null ? true : !(updCellResult == null)) ? updCellResult : THROW_CCE();
      var tmp88_safe_receiver_0 = $this.p1h_1;
      var onCancellation_0 = tmp88_safe_receiver_0 == null ? null : bindCancellationFun_0(tmp88_safe_receiver_0, $this);
      cancellable.e18(element_0, onCancellation_0);
    }
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      cancellable.c1a();
      throw e;
    } else {
      throw $p;
    }
  }
  return cancellable.g17();
}
function prepareReceiverForSuspension(_this__u8e3s4, $this, segment, index) {
  $this.c1j();
  _this__u8e3s4.i1a(segment, index);
}
function onClosedReceiveOnNoWaiterSuspend($this, cont) {
  // Inline function 'kotlin.coroutines.resumeWithException' call
  // Inline function 'kotlin.Companion.failure' call
  var exception = _get_receiveException__foorc1($this);
  var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception));
  cont.id(tmp$ret$0);
}
function updateCellReceive($this, segment, index, r, waiter) {
  var state = segment.k1h(index);
  if (state === null) {
    // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
    var senders = $this.q1h_1.kotlinx$atomicfu$value.n4(new Long(-1, 268435455));
    if (r.g2(senders) >= 0) {
      if (waiter === null) {
        return _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky();
      }
      if (segment.m1h(index, state, waiter)) {
        expandBuffer($this);
        return _get_SUSPEND_$accessor$yt74tm_ccb8g1();
      }
    }
  } else if (state === get_BUFFERED())
    if (segment.m1h(index, state, get_DONE_RCV())) {
      expandBuffer($this);
      return segment.i1h(index);
    }
  return updateCellReceiveSlow($this, segment, index, r, waiter);
}
function updateCellReceiveSlow($this, segment, index, r, waiter) {
  $l$loop: while (true) {
    var state = segment.k1h(index);
    if (state === null || state === get_IN_BUFFER()) {
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
      var senders = $this.q1h_1.kotlinx$atomicfu$value.n4(new Long(-1, 268435455));
      if (r.g2(senders) < 0) {
        if (segment.m1h(index, state, get_POISONED())) {
          expandBuffer($this);
          return _get_FAILED_$accessor$yt74tm_h47uk8();
        }
      } else {
        if (waiter === null) {
          return _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky();
        }
        if (segment.m1h(index, state, waiter)) {
          expandBuffer($this);
          return _get_SUSPEND_$accessor$yt74tm_ccb8g1();
        }
      }
    } else if (state === get_BUFFERED()) {
      if (segment.m1h(index, state, get_DONE_RCV())) {
        expandBuffer($this);
        return segment.i1h(index);
      }
    } else if (state === get_INTERRUPTED_SEND())
      return _get_FAILED_$accessor$yt74tm_h47uk8();
    else if (state === get_POISONED())
      return _get_FAILED_$accessor$yt74tm_h47uk8();
    else if (state === get_CHANNEL_CLOSED()) {
      expandBuffer($this);
      return _get_FAILED_$accessor$yt74tm_h47uk8();
    } else if (state === get_RESUMING_BY_EB())
      continue $l$loop;
    else {
      if (segment.m1h(index, state, get_RESUMING_BY_RCV())) {
        var helpExpandBuffer = state instanceof WaiterEB;
        var tmp;
        if (state instanceof WaiterEB) {
          tmp = state.r1i_1;
        } else {
          tmp = state;
        }
        var sender = tmp;
        var tmp_0;
        if (tryResumeSender(sender, $this, segment, index)) {
          segment.l1h(index, get_DONE_RCV());
          expandBuffer($this);
          tmp_0 = segment.i1h(index);
        } else {
          segment.l1h(index, get_INTERRUPTED_SEND());
          segment.a1i(index, false);
          if (helpExpandBuffer) {
            expandBuffer($this);
          }
          tmp_0 = _get_FAILED_$accessor$yt74tm_h47uk8();
        }
        return tmp_0;
      }
    }
  }
}
function tryResumeSender(_this__u8e3s4, $this, segment, index) {
  var tmp;
  if (isInterface(_this__u8e3s4, CancellableContinuation)) {
    if (!isInterface(_this__u8e3s4, CancellableContinuation))
      THROW_CCE();
    tmp = tryResume0(_this__u8e3s4, Unit_instance);
  } else {
    if (isInterface(_this__u8e3s4, SelectInstance)) {
      if (!(_this__u8e3s4 instanceof SelectImplementation))
        THROW_CCE();
      var trySelectResult = _this__u8e3s4.h1j($this, Unit_instance);
      if (trySelectResult === TrySelectDetailedResult_REREGISTER_getInstance()) {
        segment.j1h(index);
      }
      tmp = trySelectResult === TrySelectDetailedResult_SUCCESSFUL_getInstance();
    } else {
      if (_this__u8e3s4 instanceof SendBroadcast) {
        tmp = tryResume0(_this__u8e3s4.d1j_1, true);
      } else {
        var message = 'Unexpected waiter: ' + toString(_this__u8e3s4);
        throw IllegalStateException.m5(toString(message));
      }
    }
  }
  return tmp;
}
function expandBuffer($this) {
  if (_get_isRendezvousOrUnlimited__3mdufi($this))
    return Unit_instance;
  var segment = $this.w1h_1.kotlinx$atomicfu$value;
  try_again: while (true) {
    var b = $this.s1h_1.atomicfu$getAndIncrement$long();
    // Inline function 'kotlin.Long.div' call
    var other = get_SEGMENT_SIZE();
    var id = b.e4(toLong(other));
    var s = $this.b1j();
    if (s.g2(b) <= 0) {
      if (segment.c19_1.g2(id) < 0 && !(segment.i1i() == null)) {
        moveSegmentBufferEndToSpecifiedOrLast($this, id, segment);
      }
      incCompletedExpandBufferAttempts$default($this);
      return Unit_instance;
    }
    if (!segment.c19_1.equals(id)) {
      var tmp0_elvis_lhs = findSegmentBufferEnd($this, id, segment, b);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        continue try_again;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      segment = tmp;
    }
    // Inline function 'kotlin.Long.rem' call
    var other_0 = get_SEGMENT_SIZE();
    var i = b.f4(toLong(other_0)).q2();
    if (updateCellExpandBuffer($this, segment, i, b)) {
      incCompletedExpandBufferAttempts$default($this);
      return Unit_instance;
    } else {
      incCompletedExpandBufferAttempts$default($this);
      continue try_again;
    }
  }
}
function updateCellExpandBuffer($this, segment, index, b) {
  var state = segment.k1h(index);
  if (!(state == null) ? isInterface(state, Waiter) : false) {
    if (b.g2($this.r1h_1.kotlinx$atomicfu$value) >= 0) {
      if (segment.m1h(index, state, get_RESUMING_BY_EB())) {
        var tmp;
        if (tryResumeSender(state, $this, segment, index)) {
          segment.l1h(index, get_BUFFERED());
          tmp = true;
        } else {
          segment.l1h(index, get_INTERRUPTED_SEND());
          segment.a1i(index, false);
          tmp = false;
        }
        return tmp;
      }
    }
  }
  return updateCellExpandBufferSlow($this, segment, index, b);
}
function updateCellExpandBufferSlow($this, segment, index, b) {
  $l$loop: while (true) {
    var state = segment.k1h(index);
    if (!(state == null) ? isInterface(state, Waiter) : false) {
      if (b.g2($this.r1h_1.kotlinx$atomicfu$value) < 0) {
        if (segment.m1h(index, state, new WaiterEB(state)))
          return true;
      } else {
        if (segment.m1h(index, state, get_RESUMING_BY_EB())) {
          var tmp;
          if (tryResumeSender(state, $this, segment, index)) {
            segment.l1h(index, get_BUFFERED());
            tmp = true;
          } else {
            segment.l1h(index, get_INTERRUPTED_SEND());
            segment.a1i(index, false);
            tmp = false;
          }
          return tmp;
        }
      }
    } else {
      if (state === get_INTERRUPTED_SEND())
        return false;
      else {
        if (state === null) {
          if (segment.m1h(index, state, get_IN_BUFFER()))
            return true;
        } else {
          if (state === get_BUFFERED())
            return true;
          else {
            if (state === get_POISONED() || state === get_DONE_RCV() || state === get_INTERRUPTED_RCV())
              return true;
            else {
              if (state === get_CHANNEL_CLOSED())
                return true;
              else {
                if (state === get_RESUMING_BY_RCV())
                  continue $l$loop;
                else {
                  var message = 'Unexpected cell state: ' + toString_0(state);
                  throw IllegalStateException.m5(toString(message));
                }
              }
            }
          }
        }
      }
    }
  }
}
function incCompletedExpandBufferAttempts($this, nAttempts) {
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.incCompletedExpandBufferAttempts.<anonymous>' call
  // Inline function 'kotlinx.coroutines.channels.ebPauseExpandBuffers' call
  if (!$this.t1h_1.atomicfu$addAndGet$long(nAttempts).n4(new Long(0, 1073741824)).equals(new Long(0, 0))) {
    $l$loop: while (true) {
      // Inline function 'kotlinx.coroutines.channels.ebPauseExpandBuffers' call
      if (!!$this.t1h_1.kotlinx$atomicfu$value.n4(new Long(0, 1073741824)).equals(new Long(0, 0))) {
        break $l$loop;
      }
    }
  }
}
function incCompletedExpandBufferAttempts$default($this, nAttempts, $super) {
  nAttempts = nAttempts === VOID ? new Long(1, 0) : nAttempts;
  return incCompletedExpandBufferAttempts($this, nAttempts);
}
function _get_receiveException__foorc1($this) {
  var tmp0_elvis_lhs = $this.j1j();
  return tmp0_elvis_lhs == null ? ClosedReceiveChannelException.o1j('Channel was closed') : tmp0_elvis_lhs;
}
function completeCloseOrCancel($this) {
  $this.p1j();
}
function completeClose($this, sendersCur) {
  var lastSegment = closeLinkedList($this);
  if ($this.r1j()) {
    var lastBufferedCellGlobalIndex = markAllEmptyCellsAsClosed($this, lastSegment);
    if (!lastBufferedCellGlobalIndex.equals(new Long(-1, -1))) {
      $this.q1j(lastBufferedCellGlobalIndex);
    }
  }
  cancelSuspendedReceiveRequests($this, lastSegment, sendersCur);
  return lastSegment;
}
function completeCancel($this, sendersCur) {
  var lastSegment = completeClose($this, sendersCur);
  removeUnprocessedElements($this, lastSegment);
}
function closeLinkedList($this) {
  var lastSegment = $this.w1h_1.kotlinx$atomicfu$value;
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  var it = $this.u1h_1.kotlinx$atomicfu$value;
  if (it.c19_1.g2(lastSegment.c19_1) > 0)
    lastSegment = it;
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  var it_0 = $this.v1h_1.kotlinx$atomicfu$value;
  if (it_0.c19_1.g2(lastSegment.c19_1) > 0)
    lastSegment = it_0;
  return close(lastSegment);
}
function markAllEmptyCellsAsClosed($this, lastSegment) {
  var segment = lastSegment;
  while (true) {
    var inductionVariable = get_SEGMENT_SIZE() - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        // Inline function 'kotlin.Long.plus' call
        // Inline function 'kotlin.Long.times' call
        var this_0 = segment.c19_1;
        var other = get_SEGMENT_SIZE();
        var globalIndex = this_0.d4(toLong(other)).c4(toLong(index));
        if (globalIndex.g2($this.o1i()) < 0)
          return new Long(-1, -1);
        cell_update: while (true) {
          var state = segment.k1h(index);
          if (state === null || state === get_IN_BUFFER()) {
            if (segment.m1h(index, state, get_CHANNEL_CLOSED())) {
              segment.c1i();
              break cell_update;
            }
          } else if (state === get_BUFFERED())
            return globalIndex;
          else
            break cell_update;
        }
      }
       while (0 <= inductionVariable);
    var tmp0_elvis_lhs = segment.l1i();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return new Long(-1, -1);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    segment = tmp;
  }
}
function removeUnprocessedElements($this, lastSegment) {
  var onUndeliveredElement = $this.p1h_1;
  var undeliveredElementException = null;
  var suspendedSenders = _InlineList___init__impl__z8n56();
  var segment = lastSegment;
  process_segments: while (true) {
    var inductionVariable = get_SEGMENT_SIZE() - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        // Inline function 'kotlin.Long.plus' call
        // Inline function 'kotlin.Long.times' call
        var this_0 = segment.c19_1;
        var other = get_SEGMENT_SIZE();
        var globalIndex = this_0.d4(toLong(other)).c4(toLong(index));
        update_cell: while (true) {
          var state = segment.k1h(index);
          if (state === get_DONE_RCV())
            break process_segments;
          else {
            if (state === get_BUFFERED()) {
              if (globalIndex.g2($this.o1i()) < 0)
                break process_segments;
              if (segment.m1h(index, state, get_CHANNEL_CLOSED())) {
                if (!(onUndeliveredElement == null)) {
                  var element = segment.h1h(index);
                  undeliveredElementException = callUndeliveredElementCatchingException(onUndeliveredElement, element, undeliveredElementException);
                }
                segment.j1h(index);
                segment.c1i();
                break update_cell;
              }
            } else {
              if (state === get_IN_BUFFER() || state === null) {
                if (segment.m1h(index, state, get_CHANNEL_CLOSED())) {
                  segment.c1i();
                  break update_cell;
                }
              } else {
                var tmp;
                if (!(state == null) ? isInterface(state, Waiter) : false) {
                  tmp = true;
                } else {
                  tmp = state instanceof WaiterEB;
                }
                if (tmp) {
                  if (globalIndex.g2($this.o1i()) < 0)
                    break process_segments;
                  var tmp_0;
                  if (state instanceof WaiterEB) {
                    tmp_0 = state.r1i_1;
                  } else {
                    tmp_0 = (!(state == null) ? isInterface(state, Waiter) : false) ? state : THROW_CCE();
                  }
                  var sender = tmp_0;
                  if (segment.m1h(index, state, get_CHANNEL_CLOSED())) {
                    if (!(onUndeliveredElement == null)) {
                      var element_0 = segment.h1h(index);
                      undeliveredElementException = callUndeliveredElementCatchingException(onUndeliveredElement, element_0, undeliveredElementException);
                    }
                    suspendedSenders = InlineList__plus_impl_nuetvo(suspendedSenders, sender);
                    segment.j1h(index);
                    segment.c1i();
                    break update_cell;
                  }
                } else {
                  if (state === get_RESUMING_BY_EB() || state === get_RESUMING_BY_RCV())
                    break process_segments;
                  else {
                    if (state === get_RESUMING_BY_EB())
                      continue update_cell;
                    else {
                      break update_cell;
                    }
                  }
                }
              }
            }
          }
        }
      }
       while (0 <= inductionVariable);
    var tmp0_elvis_lhs = segment.l1i();
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      break process_segments;
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    segment = tmp_1;
  }
  $l$block: {
    // Inline function 'kotlinx.coroutines.internal.InlineList.forEachReversed' call
    var this_1 = suspendedSenders;
    var tmp153_subject = _get_holder__f6h5pd(this_1);
    if (tmp153_subject == null) {
      break $l$block;
    } else {
      if (!(tmp153_subject instanceof ArrayList)) {
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.removeUnprocessedElements.<anonymous>' call
        var tmp_2 = _get_holder__f6h5pd(this_1);
        var it = (tmp_2 == null ? true : !(tmp_2 == null)) ? tmp_2 : THROW_CCE();
        resumeSenderOnCancelledChannel(it, $this);
      } else {
        var tmp_3 = _get_holder__f6h5pd(this_1);
        var list = tmp_3 instanceof ArrayList ? tmp_3 : THROW_CCE();
        var inductionVariable_0 = list.j1() - 1 | 0;
        if (0 <= inductionVariable_0)
          do {
            var i = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + -1 | 0;
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.removeUnprocessedElements.<anonymous>' call
            var it_0 = list.i1(i);
            resumeSenderOnCancelledChannel(it_0, $this);
          }
           while (0 <= inductionVariable_0);
      }
    }
  }
  var tmp100_safe_receiver = undeliveredElementException;
  if (tmp100_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    throw tmp100_safe_receiver;
  }
}
function cancelSuspendedReceiveRequests($this, lastSegment, sendersCounter) {
  var suspendedReceivers = _InlineList___init__impl__z8n56();
  var segment = lastSegment;
  process_segments: while (!(segment == null)) {
    var inductionVariable = get_SEGMENT_SIZE() - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        // Inline function 'kotlin.Long.plus' call
        // Inline function 'kotlin.Long.times' call
        var this_0 = segment.c19_1;
        var other = get_SEGMENT_SIZE();
        if (this_0.d4(toLong(other)).c4(toLong(index)).g2(sendersCounter) < 0)
          break process_segments;
        cell_update: while (true) {
          var state = segment.k1h(index);
          if (state === null || state === get_IN_BUFFER()) {
            if (segment.m1h(index, state, get_CHANNEL_CLOSED())) {
              segment.c1i();
              break cell_update;
            }
          } else {
            if (state instanceof WaiterEB) {
              if (segment.m1h(index, state, get_CHANNEL_CLOSED())) {
                suspendedReceivers = InlineList__plus_impl_nuetvo(suspendedReceivers, state.r1i_1);
                segment.a1i(index, true);
                break cell_update;
              }
            } else {
              if (!(state == null) ? isInterface(state, Waiter) : false) {
                if (segment.m1h(index, state, get_CHANNEL_CLOSED())) {
                  suspendedReceivers = InlineList__plus_impl_nuetvo(suspendedReceivers, state);
                  segment.a1i(index, true);
                  break cell_update;
                }
              } else {
                break cell_update;
              }
            }
          }
        }
      }
       while (0 <= inductionVariable);
    segment = segment.l1i();
  }
  $l$block: {
    // Inline function 'kotlinx.coroutines.internal.InlineList.forEachReversed' call
    var this_1 = suspendedReceivers;
    var tmp153_subject = _get_holder__f6h5pd(this_1);
    if (tmp153_subject == null) {
      break $l$block;
    } else {
      if (!(tmp153_subject instanceof ArrayList)) {
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.cancelSuspendedReceiveRequests.<anonymous>' call
        var tmp = _get_holder__f6h5pd(this_1);
        var it = (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
        resumeReceiverOnClosedChannel(it, $this);
      } else {
        var tmp_0 = _get_holder__f6h5pd(this_1);
        var list = tmp_0 instanceof ArrayList ? tmp_0 : THROW_CCE();
        var inductionVariable_0 = list.j1() - 1 | 0;
        if (0 <= inductionVariable_0)
          do {
            var i = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + -1 | 0;
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.cancelSuspendedReceiveRequests.<anonymous>' call
            var it_0 = list.i1(i);
            resumeReceiverOnClosedChannel(it_0, $this);
          }
           while (0 <= inductionVariable_0);
      }
    }
  }
}
function resumeReceiverOnClosedChannel(_this__u8e3s4, $this) {
  return resumeWaiterOnClosedChannel(_this__u8e3s4, $this, true);
}
function resumeSenderOnCancelledChannel(_this__u8e3s4, $this) {
  return resumeWaiterOnClosedChannel(_this__u8e3s4, $this, false);
}
function resumeWaiterOnClosedChannel(_this__u8e3s4, $this, receiver) {
  if (_this__u8e3s4 instanceof SendBroadcast) {
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = _this__u8e3s4.d1j_1;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(false);
    this_0.id(tmp$ret$0);
  } else {
    if (isInterface(_this__u8e3s4, CancellableContinuation)) {
      // Inline function 'kotlin.coroutines.resumeWithException' call
      // Inline function 'kotlin.Companion.failure' call
      var exception = receiver ? _get_receiveException__foorc1($this) : $this.p1i();
      var tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(exception));
      _this__u8e3s4.id(tmp$ret$2);
    } else {
      if (_this__u8e3s4 instanceof ReceiveCatching) {
        // Inline function 'kotlin.coroutines.resume' call
        var this_1 = _this__u8e3s4.y1i_1;
        // Inline function 'kotlin.Companion.success' call
        var value = new ChannelResult(Companion_getInstance_0().s1j($this.j1j()));
        var tmp$ret$4 = _Result___init__impl__xyqfz8(value);
        this_1.id(tmp$ret$4);
      } else {
        if (_this__u8e3s4 instanceof BufferedChannelIterator) {
          _this__u8e3s4.i1j();
        } else {
          if (isInterface(_this__u8e3s4, SelectInstance))
            _this__u8e3s4.z1i($this, get_CHANNEL_CLOSED());
          else {
            var message = 'Unexpected waiter: ' + toString(_this__u8e3s4);
            throw IllegalStateException.m5(toString(message));
          }
        }
      }
    }
  }
}
function _get_isClosedForSend0__kxgf9m(_this__u8e3s4, $this) {
  return isClosed($this, _this__u8e3s4, false);
}
function _get_isClosedForReceive0__f7qknl(_this__u8e3s4, $this) {
  return isClosed($this, _this__u8e3s4, true);
}
function isClosed($this, sendersAndCloseStatusCur, isClosedForReceive) {
  // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
  var tmp;
  switch (sendersAndCloseStatusCur.l4(60).q2()) {
    case 0:
      tmp = false;
      break;
    case 1:
      tmp = false;
      break;
    case 2:
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call

      var tmp$ret$1 = sendersAndCloseStatusCur.n4(new Long(-1, 268435455));
      completeClose($this, tmp$ret$1);
      tmp = isClosedForReceive ? !$this.t1j() : true;
      break;
    case 3:
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call

      var tmp$ret$2 = sendersAndCloseStatusCur.n4(new Long(-1, 268435455));
      completeCancel($this, tmp$ret$2);
      tmp = true;
      break;
    default:
      // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call

      var message = 'unexpected close status: ' + sendersAndCloseStatusCur.l4(60).q2();
      throw IllegalStateException.m5(toString(message));
  }
  return tmp;
}
function isCellNonEmpty($this, segment, index, globalIndex) {
  while (true) {
    var state = segment.k1h(index);
    if (state === null || state === get_IN_BUFFER()) {
      if (segment.m1h(index, state, get_POISONED())) {
        expandBuffer($this);
        return false;
      }
    } else if (state === get_BUFFERED())
      return true;
    else if (state === get_INTERRUPTED_SEND())
      return false;
    else if (state === get_CHANNEL_CLOSED())
      return false;
    else if (state === get_DONE_RCV())
      return false;
    else if (state === get_POISONED())
      return false;
    else if (state === get_RESUMING_BY_EB())
      return true;
    else if (state === get_RESUMING_BY_RCV())
      return false;
    else
      return globalIndex.equals($this.o1i());
  }
}
function findSegmentSend($this, id, startFrom) {
  // Inline function 'kotlin.let' call
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.u1h_1;
    var createNewSegment = createSegmentFunction();
    while (true) {
      var s = findSegmentInternal(startFrom, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$0;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.c19_1.g2(to.c19_1) >= 0) {
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (!to.e1i()) {
              tmp$ret$0 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.f1i()) {
                cur.y6();
              }
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (to.f1i()) {
              to.y6();
            }
          }
          tmp$ret$0 = Unit_instance;
        }
        tmp = tmp$ret$0;
      }
      if (tmp) {
        tmp$ret$1 = s;
        break $l$block_2;
      }
    }
  }
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.findSegmentSend.<anonymous>' call
  var it = tmp$ret$1;
  var tmp_0;
  if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(it)) {
    completeCloseOrCancel($this);
    // Inline function 'kotlin.Long.times' call
    var this_1 = startFrom.c19_1;
    var other = get_SEGMENT_SIZE();
    if (this_1.d4(toLong(other)).g2($this.o1i()) < 0) {
      startFrom.m1i();
    }
    tmp_0 = null;
  } else {
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(it);
    var tmp_1;
    if (segment.c19_1.g2(id) > 0) {
      // Inline function 'kotlin.Long.times' call
      var this_2 = segment.c19_1;
      var other_0 = get_SEGMENT_SIZE();
      var tmp$ret$3 = this_2.d4(toLong(other_0));
      updateSendersCounterIfLower($this, tmp$ret$3);
      // Inline function 'kotlin.Long.times' call
      var this_3 = segment.c19_1;
      var other_1 = get_SEGMENT_SIZE();
      if (this_3.d4(toLong(other_1)).g2($this.o1i()) < 0) {
        segment.m1i();
      }
      tmp_1 = null;
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      tmp_1 = segment;
    }
    tmp_0 = tmp_1;
  }
  return tmp_0;
}
function findSegmentReceive($this, id, startFrom) {
  // Inline function 'kotlin.let' call
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.v1h_1;
    var createNewSegment = createSegmentFunction();
    while (true) {
      var s = findSegmentInternal(startFrom, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$0;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.c19_1.g2(to.c19_1) >= 0) {
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (!to.e1i()) {
              tmp$ret$0 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.f1i()) {
                cur.y6();
              }
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (to.f1i()) {
              to.y6();
            }
          }
          tmp$ret$0 = Unit_instance;
        }
        tmp = tmp$ret$0;
      }
      if (tmp) {
        tmp$ret$1 = s;
        break $l$block_2;
      }
    }
  }
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.findSegmentReceive.<anonymous>' call
  var it = tmp$ret$1;
  var tmp_0;
  if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(it)) {
    completeCloseOrCancel($this);
    // Inline function 'kotlin.Long.times' call
    var this_1 = startFrom.c19_1;
    var other = get_SEGMENT_SIZE();
    if (this_1.d4(toLong(other)).g2($this.b1j()) < 0) {
      startFrom.m1i();
    }
    tmp_0 = null;
  } else {
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(it);
    var tmp_1;
    if (!_get_isRendezvousOrUnlimited__3mdufi($this)) {
      // Inline function 'kotlin.Long.div' call
      var this_2 = _get_bufferEndCounter__2d4hee($this);
      var other_0 = get_SEGMENT_SIZE();
      var tmp$ret$3 = this_2.e4(toLong(other_0));
      tmp_1 = id.g2(tmp$ret$3) <= 0;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      $l$block_5: {
        // Inline function 'kotlinx.coroutines.internal.moveForward' call
        var this_3 = $this.w1h_1;
        while (true) {
          // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
          var cur_0 = this_3.kotlinx$atomicfu$value;
          if (cur_0.c19_1.g2(segment.c19_1) >= 0) {
            break $l$block_5;
          }
          if (!segment.e1i()) {
            break $l$block_5;
          }
          if (this_3.atomicfu$compareAndSet(cur_0, segment)) {
            if (cur_0.f1i()) {
              cur_0.y6();
            }
            break $l$block_5;
          }
          if (segment.f1i()) {
            segment.y6();
          }
        }
      }
    }
    var tmp_2;
    if (segment.c19_1.g2(id) > 0) {
      // Inline function 'kotlin.Long.times' call
      var this_4 = segment.c19_1;
      var other_1 = get_SEGMENT_SIZE();
      var tmp$ret$5 = this_4.d4(toLong(other_1));
      updateReceiversCounterIfLower($this, tmp$ret$5);
      // Inline function 'kotlin.Long.times' call
      var this_5 = segment.c19_1;
      var other_2 = get_SEGMENT_SIZE();
      if (this_5.d4(toLong(other_2)).g2($this.b1j()) < 0) {
        segment.m1i();
      }
      tmp_2 = null;
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      tmp_2 = segment;
    }
    tmp_0 = tmp_2;
  }
  return tmp_0;
}
function findSegmentBufferEnd($this, id, startFrom, currentBufferEndCounter) {
  // Inline function 'kotlin.let' call
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.w1h_1;
    var createNewSegment = createSegmentFunction();
    while (true) {
      var s = findSegmentInternal(startFrom, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$0;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.c19_1.g2(to.c19_1) >= 0) {
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (!to.e1i()) {
              tmp$ret$0 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.f1i()) {
                cur.y6();
              }
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (to.f1i()) {
              to.y6();
            }
          }
          tmp$ret$0 = Unit_instance;
        }
        tmp = tmp$ret$0;
      }
      if (tmp) {
        tmp$ret$1 = s;
        break $l$block_2;
      }
    }
  }
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.findSegmentBufferEnd.<anonymous>' call
  var it = tmp$ret$1;
  var tmp_0;
  if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(it)) {
    completeCloseOrCancel($this);
    moveSegmentBufferEndToSpecifiedOrLast($this, id, startFrom);
    incCompletedExpandBufferAttempts$default($this);
    tmp_0 = null;
  } else {
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(it);
    var tmp_1;
    if (segment.c19_1.g2(id) > 0) {
      // Inline function 'kotlin.Long.plus' call
      var tmp_2 = currentBufferEndCounter.c4(toLong(1));
      // Inline function 'kotlin.Long.times' call
      var this_1 = segment.c19_1;
      var other = get_SEGMENT_SIZE();
      var tmp$ret$3 = this_1.d4(toLong(other));
      if ($this.s1h_1.atomicfu$compareAndSet(tmp_2, tmp$ret$3)) {
        // Inline function 'kotlin.Long.times' call
        var this_2 = segment.c19_1;
        var other_0 = get_SEGMENT_SIZE();
        var tmp$ret$4 = this_2.d4(toLong(other_0));
        incCompletedExpandBufferAttempts($this, tmp$ret$4.h2(currentBufferEndCounter));
      } else {
        incCompletedExpandBufferAttempts$default($this);
      }
      tmp_1 = null;
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      tmp_1 = segment;
    }
    tmp_0 = tmp_1;
  }
  return tmp_0;
}
function moveSegmentBufferEndToSpecifiedOrLast($this, id, startFrom) {
  var segment = startFrom;
  $l$loop: while (segment.c19_1.g2(id) < 0) {
    var tmp0_elvis_lhs = segment.i1i();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      break $l$loop;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    segment = tmp;
  }
  while (true) {
    $l$loop_0: while (segment.d1i()) {
      var tmp1_elvis_lhs = segment.i1i();
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        break $l$loop_0;
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      segment = tmp_0;
    }
    var tmp$ret$0;
    $l$block_1: {
      // Inline function 'kotlinx.coroutines.internal.moveForward' call
      var this_0 = $this.w1h_1;
      var to = segment;
      while (true) {
        // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
        var cur = this_0.kotlinx$atomicfu$value;
        if (cur.c19_1.g2(to.c19_1) >= 0) {
          tmp$ret$0 = true;
          break $l$block_1;
        }
        if (!to.e1i()) {
          tmp$ret$0 = false;
          break $l$block_1;
        }
        if (this_0.atomicfu$compareAndSet(cur, to)) {
          if (cur.f1i()) {
            cur.y6();
          }
          tmp$ret$0 = true;
          break $l$block_1;
        }
        if (to.f1i()) {
          to.y6();
        }
      }
      tmp$ret$0 = Unit_instance;
    }
    if (tmp$ret$0)
      return Unit_instance;
  }
}
function updateSendersCounterIfLower($this, value) {
  var this_0 = $this.q1h_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.updateSendersCounterIfLower.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
    var curCounter = cur.n4(new Long(-1, 268435455));
    if (curCounter.g2(value) >= 0)
      return Unit_instance;
    // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
    var tmp$ret$1 = cur.l4(60).q2();
    var update = constructSendersAndCloseStatus(curCounter, tmp$ret$1);
    if ($this.q1h_1.atomicfu$compareAndSet(cur, update))
      return Unit_instance;
  }
  return Unit_instance;
}
function updateReceiversCounterIfLower($this, value) {
  var this_0 = $this.r1h_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.updateReceiversCounterIfLower.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    if (cur.g2(value) >= 0)
      return Unit_instance;
    if ($this.r1h_1.atomicfu$compareAndSet(cur, value))
      return Unit_instance;
  }
  return Unit_instance;
}
function bindCancellationFunResult(_this__u8e3s4, $this) {
  return BufferedChannel$onCancellationChannelResultImplDoNotCall$ref($this);
}
function onCancellationChannelResultImplDoNotCall($this, cause, element, context) {
  callUndeliveredElement(ensureNotNull($this.p1h_1), ensureNotNull(ChannelResult__getOrNull_impl_f5e07h(element)), context);
}
function bindCancellationFun(_this__u8e3s4, $this, element) {
  return BufferedChannel$bindCancellationFun$lambda(_this__u8e3s4, element);
}
function bindCancellationFun_0(_this__u8e3s4, $this) {
  return BufferedChannel$onCancellationImplDoNotCall$ref($this);
}
function onCancellationImplDoNotCall($this, cause, element, context) {
  callUndeliveredElement(ensureNotNull($this.p1h_1), element, context);
}
function BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda$lambda($element, this$0, $select) {
  return function (_unused_var__etf5q3, _unused_var__etf5q3_0, _unused_var__etf5q3_1) {
    var tmp;
    if (!($element === get_CHANNEL_CLOSED())) {
      callUndeliveredElement(this$0.p1h_1, ($element == null ? true : !($element == null)) ? $element : THROW_CCE(), $select.gd());
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda(this$0) {
  return function (select, _unused_var__etf5q3, element) {
    return BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda$lambda(element, this$0, select);
  };
}
function BufferedChannel$onCancellationChannelResultImplDoNotCall$ref($boundThis) {
  var l = function (p0, p1, p2) {
    onCancellationChannelResultImplDoNotCall($boundThis, p0, p1.v1j_1, p2);
    return Unit_instance;
  };
  l.callableName = 'onCancellationChannelResultImplDoNotCall';
  return l;
}
function BufferedChannel$bindCancellationFun$lambda($this_bindCancellationFun, $element) {
  return function (_unused_var__etf5q3, _unused_var__etf5q3_0, context) {
    callUndeliveredElement($this_bindCancellationFun, $element, context);
    return Unit_instance;
  };
}
function BufferedChannel$onCancellationImplDoNotCall$ref($boundThis) {
  var l = function (p0, p1, p2) {
    onCancellationImplDoNotCall($boundThis, p0, p1, p2);
    return Unit_instance;
  };
  l.callableName = 'onCancellationImplDoNotCall';
  return l;
}
function initialBufferEnd(capacity) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  switch (capacity) {
    case 0:
      return new Long(0, 0);
    case 2147483647:
      return new Long(-1, 2147483647);
    default:
      return toLong(capacity);
  }
}
function tryResume0(_this__u8e3s4, value, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  _init_properties_BufferedChannel_kt__d6uc4y();
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.tryResume0.<anonymous>' call
  var token = _this__u8e3s4.a18(value, null, onCancellation);
  var tmp;
  if (!(token == null)) {
    _this__u8e3s4.b18(token);
    tmp = true;
  } else {
    tmp = false;
  }
  return tmp;
}
function constructEBCompletedAndPauseFlag(counter, pauseEB) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return (pauseEB ? new Long(0, 1073741824) : new Long(0, 0)).c4(counter);
}
function constructSendersAndCloseStatus(counter, closeStatus) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return toLong(closeStatus).k4(60).c4(counter);
}
function createSegmentFunction() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return createSegment$ref();
}
function createSegment(id, prev) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return new ChannelSegment(id, prev, prev.e1h(), 0);
}
function _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return get_SUSPEND_NO_WAITER();
}
function _get_SUSPEND_$accessor$yt74tm_ccb8g1() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return get_SUSPEND();
}
function _get_FAILED_$accessor$yt74tm_h47uk8() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return get_FAILED();
}
function createSegment$ref() {
  var l = function (p0, p1) {
    return createSegment(p0, p1);
  };
  l.callableName = 'createSegment';
  return l;
}
var properties_initialized_BufferedChannel_kt_58tjvw;
function _init_properties_BufferedChannel_kt__d6uc4y() {
  if (!properties_initialized_BufferedChannel_kt_58tjvw) {
    properties_initialized_BufferedChannel_kt_58tjvw = true;
    NULL_SEGMENT = new ChannelSegment(new Long(-1, -1), null, null, 0);
    SEGMENT_SIZE = systemProp('kotlinx.coroutines.bufferedChannel.segmentSize', 32);
    EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS = systemProp('kotlinx.coroutines.bufferedChannel.expandBufferCompletionWaitIterations', 10000);
    BUFFERED = new Symbol('BUFFERED');
    IN_BUFFER = new Symbol('SHOULD_BUFFER');
    RESUMING_BY_RCV = new Symbol('S_RESUMING_BY_RCV');
    RESUMING_BY_EB = new Symbol('RESUMING_BY_EB');
    POISONED = new Symbol('POISONED');
    DONE_RCV = new Symbol('DONE_RCV');
    INTERRUPTED_SEND = new Symbol('INTERRUPTED_SEND');
    INTERRUPTED_RCV = new Symbol('INTERRUPTED_RCV');
    CHANNEL_CLOSED = new Symbol('CHANNEL_CLOSED');
    SUSPEND = new Symbol('SUSPEND');
    SUSPEND_NO_WAITER = new Symbol('SUSPEND_NO_WAITER');
    FAILED = new Symbol('FAILED');
    NO_RECEIVE_RESULT = new Symbol('NO_RECEIVE_RESULT');
    CLOSE_HANDLER_CLOSED = new Symbol('CLOSE_HANDLER_CLOSED');
    CLOSE_HANDLER_INVOKED = new Symbol('CLOSE_HANDLER_INVOKED');
    NO_CLOSE_CAUSE = new Symbol('NO_CLOSE_CAUSE');
  }
}
var Factory_instance;
function Factory_getInstance() {
  if (Factory_instance === VOID)
    new Factory();
  return Factory_instance;
}
function _ChannelResult___init__impl__siwsuf(holder) {
  return holder;
}
function _ChannelResult___get_holder__impl__pm9gzw($this) {
  return $this;
}
function _ChannelResult___get_isSuccess__impl__odq1z9($this) {
  var tmp = _ChannelResult___get_holder__impl__pm9gzw($this);
  return !(tmp instanceof Failed);
}
function _ChannelResult___get_isClosed__impl__mg7kuu($this) {
  var tmp = _ChannelResult___get_holder__impl__pm9gzw($this);
  return tmp instanceof Closed;
}
function ChannelResult__getOrNull_impl_f5e07h($this) {
  var tmp;
  var tmp_0 = _ChannelResult___get_holder__impl__pm9gzw($this);
  if (!(tmp_0 instanceof Failed)) {
    var tmp_1 = _ChannelResult___get_holder__impl__pm9gzw($this);
    tmp = (tmp_1 == null ? true : !(tmp_1 == null)) ? tmp_1 : THROW_CCE();
  } else {
    tmp = null;
  }
  return tmp;
}
function ChannelResult__exceptionOrNull_impl_16ei30($this) {
  var tmp = _ChannelResult___get_holder__impl__pm9gzw($this);
  var tmp112_safe_receiver = tmp instanceof Closed ? tmp : null;
  return tmp112_safe_receiver == null ? null : tmp112_safe_receiver.n1k_1;
}
var Companion_instance_0;
function Companion_getInstance_0() {
  if (Companion_instance_0 === VOID)
    new Companion();
  return Companion_instance_0;
}
function ChannelResult__toString_impl_rrcqu7($this) {
  var tmp;
  if (_ChannelResult___get_holder__impl__pm9gzw($this) instanceof Closed) {
    tmp = _ChannelResult___get_holder__impl__pm9gzw($this).toString();
  } else {
    tmp = 'Value(' + toString_0(_ChannelResult___get_holder__impl__pm9gzw($this)) + ')';
  }
  return tmp;
}
function ChannelResult__hashCode_impl_lilec2($this) {
  return $this == null ? 0 : hashCode($this);
}
function ChannelResult__equals_impl_f471ri($this, other) {
  if (!(other instanceof ChannelResult))
    return false;
  var tmp0_other_with_cast = other instanceof ChannelResult ? other.v1j_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function Channel(capacity, onBufferOverflow, onUndeliveredElement) {
  capacity = capacity === VOID ? 0 : capacity;
  onBufferOverflow = onBufferOverflow === VOID ? BufferOverflow_SUSPEND_getInstance() : onBufferOverflow;
  onUndeliveredElement = onUndeliveredElement === VOID ? null : onUndeliveredElement;
  var tmp;
  switch (capacity) {
    case 0:
      tmp = onBufferOverflow.equals(BufferOverflow_SUSPEND_getInstance()) ? new BufferedChannel(0, onUndeliveredElement) : new ConflatedBufferedChannel(1, onBufferOverflow, onUndeliveredElement);
      break;
    case -1:
      // Inline function 'kotlin.require' call

      // Inline function 'kotlin.contracts.contract' call

      if (!onBufferOverflow.equals(BufferOverflow_SUSPEND_getInstance())) {
        // Inline function 'kotlinx.coroutines.channels.Channel.<anonymous>' call
        var message = 'CONFLATED capacity cannot be used with non-default onBufferOverflow';
        throw IllegalArgumentException.l(toString(message));
      }

      tmp = new ConflatedBufferedChannel(1, BufferOverflow_DROP_OLDEST_getInstance(), onUndeliveredElement);
      break;
    case 2147483647:
      tmp = new BufferedChannel(2147483647, onUndeliveredElement);
      break;
    case -2:
      tmp = onBufferOverflow.equals(BufferOverflow_SUSPEND_getInstance()) ? new BufferedChannel(Factory_getInstance().m1k_1, onUndeliveredElement) : new ConflatedBufferedChannel(1, onBufferOverflow, onUndeliveredElement);
      break;
    default:
      tmp = onBufferOverflow === BufferOverflow_SUSPEND_getInstance() ? new BufferedChannel(capacity, onUndeliveredElement) : new ConflatedBufferedChannel(capacity, onBufferOverflow, onUndeliveredElement);
      break;
  }
  return tmp;
}
function trySendImpl($this, element, isSendOp) {
  return $this.b1l_1 === BufferOverflow_DROP_LATEST_getInstance() ? trySendDropLatest($this, element, isSendOp) : $this.z1j(element);
}
function trySendDropLatest($this, element, isSendOp) {
  var result = protoOf(BufferedChannel).x1j.call($this, element);
  if (_ChannelResult___get_isSuccess__impl__odq1z9(result) || _ChannelResult___get_isClosed__impl__mg7kuu(result))
    return result;
  if (isSendOp) {
    var tmp118_safe_receiver = $this.p1h_1;
    var tmp119_safe_receiver = tmp118_safe_receiver == null ? null : callUndeliveredElementCatchingException(tmp118_safe_receiver, element);
    if (tmp119_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      throw tmp119_safe_receiver;
    }
  }
  return Companion_getInstance_0().x1i(Unit_instance);
}
function flow(block) {
  return new SafeFlow(block);
}
function asFlow(_this__u8e3s4) {
  // Inline function 'kotlinx.coroutines.flow.internal.unsafeFlow' call
  return new _no_name_provided__qut3iv(_this__u8e3s4);
}
function *_generator_collectSafely__fg77v4($this, collector, $completion) {
  var tmp = $this.c1l_1(collector, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_collect__dlomyy($this, collector, $completion) {
  // Inline function 'kotlinx.coroutines.flow.asFlow.<anonymous>' call
  // Inline function 'kotlin.collections.forEach' call
  var tmp0_iterator = $this.f1l_1.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'kotlinx.coroutines.flow.asFlow.<anonymous>.<anonymous>' call
    var tmp = collector.g1l(element, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function *_generator_collect__dlomyy_0($this, collector, $completion) {
  // Inline function 'kotlin.js.getCoroutineContext' call
  var tmp$ret$0 = $completion.gd();
  var safeCollector = new SafeCollector(collector, tmp$ret$0);
  try {
    var tmp = $this.d1l(safeCollector, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }finally {
    safeCollector.rd();
  }
  return Unit_instance;
}
function checkContext(_this__u8e3s4, currentContext) {
  var result = currentContext.wn(0, checkContext$lambda(_this__u8e3s4));
  if (!(result === _this__u8e3s4.k1l_1)) {
    // Inline function 'kotlin.error' call
    var message = 'Flow invariant is violated:\n' + ('\t\tFlow was collected in ' + toString(_this__u8e3s4.j1l_1) + ',\n') + ('\t\tbut emission happened in ' + toString(currentContext) + '.\n') + "\t\tPlease refer to 'flow' documentation or use 'flowOn' instead";
    throw IllegalStateException.m5(toString(message));
  }
}
function transitiveCoroutineParent(_this__u8e3s4, collectJob) {
  var $this = _this__u8e3s4;
  var collectJob_0 = collectJob;
  $l$1: do {
    $l$0: do {
      if ($this === null)
        return null;
      if ($this === collectJob_0)
        return $this;
      if (!($this instanceof ScopeCoroutine))
        return $this;
      $this = $this.w15();
      collectJob_0 = collectJob_0;
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
function checkContext$lambda($this_checkContext) {
  return function (count, element) {
    var key = element.a2();
    var collectElement = $this_checkContext.j1l_1.td(key);
    var tmp;
    if (!(key === Key_instance_2)) {
      return !(element === collectElement) ? -2147483648 : count + 1 | 0;
    }
    var collectJob = (collectElement == null ? true : isInterface(collectElement, Job)) ? collectElement : THROW_CCE();
    var emissionParentJob = transitiveCoroutineParent(isInterface(element, Job) ? element : THROW_CCE(), collectJob);
    var tmp_0;
    if (!(emissionParentJob === collectJob)) {
      var message = 'Flow invariant is violated:\n\t\tEmission from another coroutine is detected.\n' + ('\t\tChild of ' + toString_0(emissionParentJob) + ', expected child of ' + toString_0(collectJob) + '.\n') + '\t\tFlowCollector is not thread-safe and concurrent emissions are prohibited.\n' + "\t\tTo mitigate this restriction please use 'channelFlow' builder instead of 'flow'";
      throw IllegalStateException.m5(toString(message));
    }
    return collectJob == null ? count : count + 1 | 0;
  };
}
function toList(_this__u8e3s4, destination, $completion) {
  destination = destination === VOID ? ArrayList.m1() : destination;
  return toCollection(_this__u8e3s4, destination, $completion);
}
function *_generator_toCollection__bdt9kf(_this__u8e3s4, destination, $completion) {
  var tmp = toCollection$slambda_0(destination);
  var tmp_0 = _this__u8e3s4.e1l(new sam$kotlinx_coroutines_flow_FlowCollector$0(tmp), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return destination;
}
function toCollection(_this__u8e3s4, destination, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_toCollection__bdt9kf(_this__u8e3s4, destination, $completion), $completion);
}
function toCollection$slambda_0($destination) {
  var i = new toCollection$slambda($destination);
  var l = function (value, $completion) {
    return i.o1l(value, $completion);
  };
  l.$arity = 1;
  return l;
}
function get_CLOSED() {
  _init_properties_ConcurrentLinkedList_kt__5gcgzy();
  return CLOSED;
}
var CLOSED;
function close(_this__u8e3s4) {
  _init_properties_ConcurrentLinkedList_kt__5gcgzy();
  var cur = _this__u8e3s4;
  while (true) {
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
    var this_0 = cur;
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
    var it = _get_nextOrClosed__w0gmuv(this_0);
    var tmp;
    if (it === get_CLOSED()) {
      // Inline function 'kotlinx.coroutines.internal.close.<anonymous>' call
      return cur;
    } else {
      tmp = (it == null ? true : it instanceof ConcurrentLinkedListNode) ? it : THROW_CCE();
    }
    var next = tmp;
    if (next === null) {
      if (cur.n1i())
        return cur;
    } else {
      cur = next;
    }
  }
}
function _SegmentOrClosed___init__impl__jnexvb(value) {
  return value;
}
function _get_value__a43j40($this) {
  return $this;
}
function _SegmentOrClosed___get_isClosed__impl__qmxmlo($this) {
  return _get_value__a43j40($this) === get_CLOSED();
}
function _SegmentOrClosed___get_segment__impl__jvcr9l($this) {
  var tmp;
  if (_get_value__a43j40($this) === get_CLOSED()) {
    var message = 'Does not contain segment';
    throw IllegalStateException.m5(toString(message));
  } else {
    var tmp_0 = _get_value__a43j40($this);
    tmp = tmp_0 instanceof Segment ? tmp_0 : THROW_CCE();
  }
  return tmp;
}
function SegmentOrClosed__toString_impl_pzb2an($this) {
  return 'SegmentOrClosed(value=' + toString_0($this) + ')';
}
function SegmentOrClosed__hashCode_impl_4855hs($this) {
  return $this == null ? 0 : hashCode($this);
}
function SegmentOrClosed__equals_impl_6erq1g($this, other) {
  if (!(other instanceof SegmentOrClosed))
    return false;
  var tmp0_other_with_cast = other instanceof SegmentOrClosed ? other.u1j_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function _get_nextOrClosed__w0gmuv($this) {
  return $this.g1i_1.kotlinx$atomicfu$value;
}
function _get_aliveSegmentLeft__mr4ndu($this) {
  var cur = $this.l1i();
  while (!(cur === null) && cur.d1i())
    cur = cur.h1i_1.kotlinx$atomicfu$value;
  return cur;
}
function _get_aliveSegmentRight__7ulr0b($this) {
  // Inline function 'kotlinx.coroutines.assert' call
  var cur = ensureNotNull($this.i1i());
  while (cur.d1i()) {
    var tmp0_elvis_lhs = cur.i1i();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return cur;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    cur = tmp;
  }
  return cur;
}
function findSegmentInternal(_this__u8e3s4, id, createNewSegment) {
  _init_properties_ConcurrentLinkedList_kt__5gcgzy();
  var cur = _this__u8e3s4;
  $l$loop: while (cur.c19_1.g2(id) < 0 || cur.d1i()) {
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
    var this_0 = cur;
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
    var it = _get_nextOrClosed__w0gmuv(this_0);
    var tmp;
    if (it === get_CLOSED()) {
      // Inline function 'kotlinx.coroutines.internal.findSegmentInternal.<anonymous>' call
      return _SegmentOrClosed___init__impl__jnexvb(get_CLOSED());
    } else {
      tmp = (it == null ? true : it instanceof ConcurrentLinkedListNode) ? it : THROW_CCE();
    }
    var next = tmp;
    if (!(next == null)) {
      cur = next;
      continue $l$loop;
    }
    // Inline function 'kotlin.Long.plus' call
    var newTail = createNewSegment(cur.c19_1.c4(toLong(1)), cur);
    if (cur.j1i(newTail)) {
      if (cur.d1i()) {
        cur.y6();
      }
      cur = newTail;
    }
  }
  return _SegmentOrClosed___init__impl__jnexvb(cur);
}
var properties_initialized_ConcurrentLinkedList_kt_kwt434;
function _init_properties_ConcurrentLinkedList_kt__5gcgzy() {
  if (!properties_initialized_ConcurrentLinkedList_kt_kwt434) {
    properties_initialized_ConcurrentLinkedList_kt_kwt434 = true;
    CLOSED = new Symbol('CLOSED');
  }
}
function handleUncaughtCoroutineException(context, exception) {
  var _iterator__ex2g4s = get_platformExceptionHandlers().f1();
  while (_iterator__ex2g4s.g1()) {
    var handler = _iterator__ex2g4s.h1();
    try {
      handler.x1b(context, exception);
    } catch ($p) {
      if ($p instanceof ExceptionSuccessfullyProcessed) {
        var _unused_var__etf5q3 = $p;
        return Unit_instance;
      } else {
        if ($p instanceof Error) {
          var t = $p;
          propagateExceptionFinalResort(handlerException(exception, t));
        } else {
          throw $p;
        }
      }
    }
  }
  try {
    addSuppressed(exception, DiagnosticCoroutineContextException.s1l(context));
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
    } else {
      throw $p;
    }
  }
  propagateExceptionFinalResort(exception);
}
function get_UNDEFINED() {
  _init_properties_DispatchedContinuation_kt__tnmqc0();
  return UNDEFINED;
}
var UNDEFINED;
function get_REUSABLE_CLAIMED() {
  _init_properties_DispatchedContinuation_kt__tnmqc0();
  return REUSABLE_CLAIMED;
}
var REUSABLE_CLAIMED;
function _get_reusableCancellableContinuation__9qex09($this) {
  var tmp = $this.u18_1.kotlinx$atomicfu$value;
  return tmp instanceof CancellableContinuationImpl ? tmp : null;
}
function resumeCancellableWith(_this__u8e3s4, result, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  _init_properties_DispatchedContinuation_kt__tnmqc0();
  var tmp;
  if (_this__u8e3s4 instanceof DispatchedContinuation) {
    var state = toState_0(result, onCancellation);
    if (_this__u8e3s4.q18_1.u1b(_this__u8e3s4.gd())) {
      _this__u8e3s4.s18_1 = state;
      _this__u8e3s4.y18_1 = 1;
      _this__u8e3s4.q18_1.v1b(_this__u8e3s4.gd(), _this__u8e3s4);
    } else {
      $l$block: {
        // Inline function 'kotlinx.coroutines.internal.executeUnconfined' call
        // Inline function 'kotlinx.coroutines.assert' call
        var eventLoop = ThreadLocalEventLoop_getInstance().t1c();
        if (false && eventLoop.o1c()) {
          break $l$block;
        }
        var tmp_0;
        if (eventLoop.n1c()) {
          _this__u8e3s4.s18_1 = state;
          _this__u8e3s4.y18_1 = 1;
          eventLoop.m1c(_this__u8e3s4);
          tmp_0 = true;
        } else {
          // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
          eventLoop.p1c(true);
          try {
            // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeCancellableWith.<anonymous>' call
            var tmp$ret$3;
            $l$block_0: {
              // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeCancelled' call
              var job = _this__u8e3s4.gd().td(Key_instance_2);
              if (!(job == null) && !job.d15()) {
                var cause = job.b16();
                _this__u8e3s4.w19(state, cause);
                // Inline function 'kotlin.coroutines.resumeWithException' call
                // Inline function 'kotlin.Companion.failure' call
                var tmp$ret$1 = _Result___init__impl__xyqfz8(createFailure(cause));
                _this__u8e3s4.id(tmp$ret$1);
                tmp$ret$3 = true;
                break $l$block_0;
              }
              tmp$ret$3 = false;
            }
            if (!tmp$ret$3) {
              // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeUndispatchedWith' call
              // Inline function 'kotlinx.coroutines.withContinuationContext' call
              _this__u8e3s4.r18_1;
              _this__u8e3s4.t18_1;
              _this__u8e3s4.r18_1.id(result);
            }
            $l$loop: while (eventLoop.l1c()) {
            }
          } catch ($p) {
            if ($p instanceof Error) {
              var e = $p;
              _this__u8e3s4.q1a(e, null);
            } else {
              throw $p;
            }
          }
          finally {
            eventLoop.q1c(true);
          }
          tmp_0 = false;
        }
      }
    }
    tmp = Unit_instance;
  } else {
    _this__u8e3s4.id(result);
    tmp = Unit_instance;
  }
  return tmp;
}
var properties_initialized_DispatchedContinuation_kt_2siadq;
function _init_properties_DispatchedContinuation_kt__tnmqc0() {
  if (!properties_initialized_DispatchedContinuation_kt_2siadq) {
    properties_initialized_DispatchedContinuation_kt_2siadq = true;
    UNDEFINED = new Symbol('UNDEFINED');
    REUSABLE_CLAIMED = new Symbol('REUSABLE_CLAIMED');
  }
}
function get_isReusableMode(_this__u8e3s4) {
  return _this__u8e3s4 === 2;
}
function get_isCancellableMode(_this__u8e3s4) {
  return _this__u8e3s4 === 1 || _this__u8e3s4 === 2;
}
function dispatch(_this__u8e3s4, mode) {
  // Inline function 'kotlinx.coroutines.assert' call
  var delegate = _this__u8e3s4.t19();
  var undispatched = mode === 4;
  var tmp;
  var tmp_0;
  if (!undispatched) {
    tmp_0 = delegate instanceof DispatchedContinuation;
  } else {
    tmp_0 = false;
  }
  if (tmp_0) {
    tmp = get_isCancellableMode(mode) === get_isCancellableMode(_this__u8e3s4.y18_1);
  } else {
    tmp = false;
  }
  if (tmp) {
    var dispatcher = delegate.q18_1;
    var context = delegate.gd();
    if (dispatcher.u1b(context)) {
      dispatcher.v1b(context, _this__u8e3s4);
    } else {
      resumeUnconfined(_this__u8e3s4);
    }
  } else {
    resume(_this__u8e3s4, delegate, undispatched);
  }
}
function resumeUnconfined(_this__u8e3s4) {
  var eventLoop = ThreadLocalEventLoop_getInstance().t1c();
  if (eventLoop.n1c()) {
    eventLoop.m1c(_this__u8e3s4);
  } else {
    // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
    eventLoop.p1c(true);
    try {
      // Inline function 'kotlinx.coroutines.resumeUnconfined.<anonymous>' call
      resume(_this__u8e3s4, _this__u8e3s4.t19(), true);
      $l$loop: while (eventLoop.l1c()) {
      }
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        _this__u8e3s4.q1a(e, null);
      } else {
        throw $p;
      }
    }
    finally {
      eventLoop.q1c(true);
    }
  }
}
function resume(_this__u8e3s4, delegate, undispatched) {
  var state = _this__u8e3s4.v19();
  var exception = _this__u8e3s4.n1a(state);
  var tmp;
  if (!(exception == null)) {
    // Inline function 'kotlin.Companion.failure' call
    tmp = _Result___init__impl__xyqfz8(createFailure(exception));
  } else {
    // Inline function 'kotlin.Companion.success' call
    var value = _this__u8e3s4.d1a(state);
    tmp = _Result___init__impl__xyqfz8(value);
  }
  var result = tmp;
  if (undispatched) {
    // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeUndispatchedWith' call
    var this_0 = delegate instanceof DispatchedContinuation ? delegate : THROW_CCE();
    // Inline function 'kotlinx.coroutines.withContinuationContext' call
    this_0.r18_1;
    this_0.t18_1;
    this_0.r18_1.id(result);
  } else {
    delegate.id(result);
  }
}
function _InlineList___init__impl__z8n56(holder) {
  holder = holder === VOID ? null : holder;
  return holder;
}
function _get_holder__f6h5pd($this) {
  return $this;
}
function InlineList__plus_impl_nuetvo($this, element) {
  // Inline function 'kotlinx.coroutines.assert' call
  var tmp152_subject = _get_holder__f6h5pd($this);
  var tmp;
  if (tmp152_subject == null) {
    tmp = _InlineList___init__impl__z8n56(element);
  } else {
    if (tmp152_subject instanceof ArrayList) {
      var tmp_0 = _get_holder__f6h5pd($this);
      (tmp_0 instanceof ArrayList ? tmp_0 : THROW_CCE()).f(element);
      tmp = _InlineList___init__impl__z8n56(_get_holder__f6h5pd($this));
    } else {
      var list = ArrayList.w(4);
      var tmp_1 = _get_holder__f6h5pd($this);
      list.f((tmp_1 == null ? true : !(tmp_1 == null)) ? tmp_1 : THROW_CCE());
      list.f(element);
      tmp = _InlineList___init__impl__z8n56(list);
    }
  }
  return tmp;
}
function callUndeliveredElement(_this__u8e3s4, element, context) {
  var tmp155_safe_receiver = callUndeliveredElementCatchingException(_this__u8e3s4, element, null);
  if (tmp155_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    handleCoroutineException(context, tmp155_safe_receiver);
  }
}
function callUndeliveredElementCatchingException(_this__u8e3s4, element, undeliveredElementException) {
  undeliveredElementException = undeliveredElementException === VOID ? null : undeliveredElementException;
  try {
    _this__u8e3s4(element);
  } catch ($p) {
    if ($p instanceof Error) {
      var ex = $p;
      if (!(undeliveredElementException == null) && !(undeliveredElementException.cause === ex)) {
        addSuppressed(undeliveredElementException, ex);
      } else {
        return UndeliveredElementException.x1l('Exception in undelivered element handler for ' + toString_0(element), ex);
      }
    } else {
      throw $p;
    }
  }
  return undeliveredElementException;
}
function systemProp(propertyName, defaultValue, minValue, maxValue) {
  minValue = minValue === VOID ? 1 : minValue;
  maxValue = maxValue === VOID ? 2147483647 : maxValue;
  return systemProp_0(propertyName, toLong(defaultValue), toLong(minValue), toLong(maxValue)).q2();
}
function systemProp_0(propertyName, defaultValue, minValue, maxValue) {
  minValue = minValue === VOID ? new Long(1, 0) : minValue;
  maxValue = maxValue === VOID ? new Long(-1, 2147483647) : maxValue;
  var tmp0_elvis_lhs = systemProp_1(propertyName);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return defaultValue;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var value = tmp;
  var tmp1_elvis_lhs = toLongOrNull(value);
  var tmp_0;
  if (tmp1_elvis_lhs == null) {
    var message = "System property '" + propertyName + "' has unrecognized value '" + value + "'";
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp1_elvis_lhs;
  }
  var parsed = tmp_0;
  if (!(minValue.g2(parsed) <= 0 ? parsed.g2(maxValue) <= 0 : false)) {
    // Inline function 'kotlin.error' call
    var message_0 = "System property '" + propertyName + "' should be in range " + minValue.toString() + '..' + maxValue.toString() + ", but is '" + parsed.toString() + "'";
    throw IllegalStateException.m5(toString(message_0));
  }
  return parsed;
}
function startCoroutineCancellable(_this__u8e3s4, receiver, completion, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  var tmp;
  try {
    var tmp_0 = intercepted(createCoroutineUninterceptedGeneratorVersion(_this__u8e3s4, receiver, completion));
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    resumeCancellableWith(tmp_0, tmp$ret$0, onCancellation);
    tmp = Unit_instance;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      dispatcherFailure$accessor$glj1hg(completion, e);
      tmp_1 = Unit_instance;
    } else {
      throw $p;
    }
    tmp = tmp_1;
  }
  return tmp;
}
function startCoroutineCancellable_0(_this__u8e3s4, fatalCompletion) {
  var tmp;
  try {
    var tmp_0 = intercepted(_this__u8e3s4);
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    resumeCancellableWith(tmp_0, tmp$ret$0);
    tmp = Unit_instance;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      dispatcherFailure$accessor$glj1hg(fatalCompletion, e);
      tmp_1 = Unit_instance;
    } else {
      throw $p;
    }
    tmp = tmp_1;
  }
  return tmp;
}
function dispatcherFailure(completion, e) {
  // Inline function 'kotlin.Companion.failure' call
  var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(e));
  completion.id(tmp$ret$0);
  throw e;
}
function dispatcherFailure$accessor$glj1hg(completion, e) {
  return dispatcherFailure(completion, e);
}
function startUndispatchedOrReturn(_this__u8e3s4, receiver, block) {
  var tmp$ret$1;
  $l$block_0: {
    // Inline function 'kotlinx.coroutines.intrinsics.undispatchedResult' call
    var tmp;
    try {
      // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturn.<anonymous>' call
      tmp = startCoroutineUninterceptedOrReturnGeneratorVersion(block, receiver, _this__u8e3s4);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        tmp_0 = new CompletedExceptionally(e);
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    var result = tmp;
    if (result === get_COROUTINE_SUSPENDED()) {
      tmp$ret$1 = get_COROUTINE_SUSPENDED();
      break $l$block_0;
    }
    var state = _this__u8e3s4.l15(result);
    if (state === get_COMPLETING_WAITING_CHILDREN()) {
      tmp$ret$1 = get_COROUTINE_SUSPENDED();
      break $l$block_0;
    }
    var tmp_1;
    if (state instanceof CompletedExceptionally) {
      var tmp_2;
      // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturn.<anonymous>' call
      state.i15_1;
      if (true) {
        throw recoverStackTrace(state.i15_1, _this__u8e3s4.r17_1);
      } else {
        if (result instanceof CompletedExceptionally) {
          throw recoverStackTrace(result.i15_1, _this__u8e3s4.r17_1);
        } else {
          tmp_2 = result;
        }
      }
      tmp_1 = tmp_2;
    } else {
      tmp_1 = unboxState(state);
    }
    tmp$ret$1 = tmp_1;
  }
  return tmp$ret$1;
}
function startCoroutineUndispatched(_this__u8e3s4, receiver, completion) {
  // Inline function 'kotlinx.coroutines.internal.probeCoroutineCreated' call
  var actualCompletion = completion;
  var tmp;
  try {
    // Inline function 'kotlinx.coroutines.withCoroutineContext' call
    actualCompletion.gd();
    // Inline function 'kotlinx.coroutines.intrinsics.startCoroutineUndispatched.<anonymous>' call
    // Inline function 'kotlinx.coroutines.internal.probeCoroutineResumed' call
    tmp = startCoroutineUninterceptedOrReturnGeneratorVersion(_this__u8e3s4, receiver, actualCompletion);
  } catch ($p) {
    var tmp_0;
    if ($p instanceof Error) {
      var e = $p;
      // Inline function 'kotlin.coroutines.resumeWithException' call
      // Inline function 'kotlin.Companion.failure' call
      var tmp$ret$3 = _Result___init__impl__xyqfz8(createFailure(e));
      actualCompletion.id(tmp$ret$3);
      return Unit_instance;
    } else {
      throw $p;
    }
  }
  var value = tmp;
  if (!(value === get_COROUTINE_SUSPENDED())) {
    // Inline function 'kotlin.coroutines.resume' call
    // Inline function 'kotlin.Companion.success' call
    var value_0 = (value == null ? true : !(value == null)) ? value : THROW_CCE();
    var tmp$ret$5 = _Result___init__impl__xyqfz8(value_0);
    actualCompletion.id(tmp$ret$5);
  }
}
function startUndispatchedOrReturnIgnoreTimeout(_this__u8e3s4, receiver, block) {
  var tmp$ret$1;
  $l$block_0: {
    // Inline function 'kotlinx.coroutines.intrinsics.undispatchedResult' call
    var tmp;
    try {
      // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturnIgnoreTimeout.<anonymous>' call
      tmp = startCoroutineUninterceptedOrReturnGeneratorVersion(block, receiver, _this__u8e3s4);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        tmp_0 = new CompletedExceptionally(e);
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    var result = tmp;
    if (result === get_COROUTINE_SUSPENDED()) {
      tmp$ret$1 = get_COROUTINE_SUSPENDED();
      break $l$block_0;
    }
    var state = _this__u8e3s4.l15(result);
    if (state === get_COMPLETING_WAITING_CHILDREN()) {
      tmp$ret$1 = get_COROUTINE_SUSPENDED();
      break $l$block_0;
    }
    var tmp_1;
    if (state instanceof CompletedExceptionally) {
      var tmp_2;
      // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturnIgnoreTimeout.<anonymous>' call
      var e_0 = state.i15_1;
      var tmp_3;
      if (e_0 instanceof TimeoutCancellationException) {
        tmp_3 = e_0.l1g_1 === _this__u8e3s4;
      } else {
        tmp_3 = false;
      }
      if (!tmp_3) {
        throw recoverStackTrace(state.i15_1, _this__u8e3s4.r17_1);
      } else {
        if (result instanceof CompletedExceptionally) {
          throw recoverStackTrace(result.i15_1, _this__u8e3s4.r17_1);
        } else {
          tmp_2 = result;
        }
      }
      tmp_1 = tmp_2;
    } else {
      tmp_1 = unboxState(state);
    }
    tmp$ret$1 = tmp_1;
  }
  return tmp$ret$1;
}
var DUMMY_PROCESS_RESULT_FUNCTION;
function get_STATE_REG() {
  _init_properties_Select_kt__zhm2jg();
  return STATE_REG;
}
var STATE_REG;
function get_STATE_COMPLETED() {
  _init_properties_Select_kt__zhm2jg();
  return STATE_COMPLETED;
}
var STATE_COMPLETED;
function get_STATE_CANCELLED() {
  _init_properties_Select_kt__zhm2jg();
  return STATE_CANCELLED;
}
var STATE_CANCELLED;
function get_NO_RESULT() {
  _init_properties_Select_kt__zhm2jg();
  return NO_RESULT;
}
var NO_RESULT;
var PARAM_CLAUSE_0;
function trySelectInternal($this, clauseObject, internalResult) {
  $l$loop: while (true) {
    var curState = $this.e1j_1.kotlinx$atomicfu$value;
    if (isInterface(curState, CancellableContinuation)) {
      var tmp0_elvis_lhs = findClause($this, clauseObject);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        continue $l$loop;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var clause = tmp;
      var onCancellation = clause.e1m($this, internalResult);
      if ($this.e1j_1.atomicfu$compareAndSet(curState, clause)) {
        var cont = isInterface(curState, CancellableContinuation) ? curState : THROW_CCE();
        $this.g1j_1 = internalResult;
        if (tryResume_1(cont, onCancellation))
          return 0;
        $this.g1j_1 = get_NO_RESULT();
        return 2;
      }
    } else {
      var tmp_0;
      if (equals(curState, get_STATE_COMPLETED())) {
        tmp_0 = true;
      } else {
        tmp_0 = curState instanceof ClauseData;
      }
      if (tmp_0)
        return 3;
      else {
        if (equals(curState, get_STATE_CANCELLED()))
          return 2;
        else {
          if (equals(curState, get_STATE_REG())) {
            if ($this.e1j_1.atomicfu$compareAndSet(curState, listOf_0(clauseObject)))
              return 1;
          } else {
            if (isInterface(curState, KtList)) {
              if ($this.e1j_1.atomicfu$compareAndSet(curState, plus_0(curState, clauseObject)))
                return 1;
            } else {
              var message = 'Unexpected state: ' + toString(curState);
              throw IllegalStateException.m5(toString(message));
            }
          }
        }
      }
    }
  }
}
function findClause($this, clauseObject) {
  var tmp0_elvis_lhs = $this.f1j_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var clauses = tmp;
  // Inline function 'kotlin.collections.find' call
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var tmp0_iterator = clauses.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'kotlinx.coroutines.selects.SelectImplementation.findClause.<anonymous>' call
      if (element.b1m_1 === clauseObject) {
        tmp$ret$1 = element;
        break $l$block;
      }
    }
    tmp$ret$1 = null;
  }
  var tmp1_elvis_lhs = tmp$ret$1;
  var tmp_0;
  if (tmp1_elvis_lhs == null) {
    var message = 'Clause with object ' + toString(clauseObject) + ' is not found';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp1_elvis_lhs;
  }
  return tmp_0;
}
var TrySelectDetailedResult_SUCCESSFUL_instance;
var TrySelectDetailedResult_REREGISTER_instance;
var TrySelectDetailedResult_CANCELLED_instance;
var TrySelectDetailedResult_ALREADY_SELECTED_instance;
var TrySelectDetailedResult_entriesInitialized;
function TrySelectDetailedResult_initEntries() {
  if (TrySelectDetailedResult_entriesInitialized)
    return Unit_instance;
  TrySelectDetailedResult_entriesInitialized = true;
  TrySelectDetailedResult_SUCCESSFUL_instance = new TrySelectDetailedResult('SUCCESSFUL', 0);
  TrySelectDetailedResult_REREGISTER_instance = new TrySelectDetailedResult('REREGISTER', 1);
  TrySelectDetailedResult_CANCELLED_instance = new TrySelectDetailedResult('CANCELLED', 2);
  TrySelectDetailedResult_ALREADY_SELECTED_instance = new TrySelectDetailedResult('ALREADY_SELECTED', 3);
}
function TrySelectDetailedResult_0(trySelectInternalResult) {
  _init_properties_Select_kt__zhm2jg();
  var tmp;
  switch (trySelectInternalResult) {
    case 0:
      tmp = TrySelectDetailedResult_SUCCESSFUL_getInstance();
      break;
    case 1:
      tmp = TrySelectDetailedResult_REREGISTER_getInstance();
      break;
    case 2:
      tmp = TrySelectDetailedResult_CANCELLED_getInstance();
      break;
    case 3:
      tmp = TrySelectDetailedResult_ALREADY_SELECTED_getInstance();
      break;
    default:
      var message = 'Unexpected internal result: ' + trySelectInternalResult;
      throw IllegalStateException.m5(toString(message));
  }
  return tmp;
}
function tryResume_1(_this__u8e3s4, onCancellation) {
  _init_properties_Select_kt__zhm2jg();
  var tmp0_elvis_lhs = _this__u8e3s4.a18(Unit_instance, null, onCancellation);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return false;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var token = tmp;
  _this__u8e3s4.b18(token);
  return true;
}
function DUMMY_PROCESS_RESULT_FUNCTION$lambda(_unused_var__etf5q3, _unused_var__etf5q3_0, _unused_var__etf5q3_1) {
  _init_properties_Select_kt__zhm2jg();
  return null;
}
function TrySelectDetailedResult_SUCCESSFUL_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_SUCCESSFUL_instance;
}
function TrySelectDetailedResult_REREGISTER_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_REREGISTER_instance;
}
function TrySelectDetailedResult_CANCELLED_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_CANCELLED_instance;
}
function TrySelectDetailedResult_ALREADY_SELECTED_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_ALREADY_SELECTED_instance;
}
var properties_initialized_Select_kt_7rpl36;
function _init_properties_Select_kt__zhm2jg() {
  if (!properties_initialized_Select_kt_7rpl36) {
    properties_initialized_Select_kt_7rpl36 = true;
    DUMMY_PROCESS_RESULT_FUNCTION = DUMMY_PROCESS_RESULT_FUNCTION$lambda;
    STATE_REG = new Symbol('STATE_REG');
    STATE_COMPLETED = new Symbol('STATE_COMPLETED');
    STATE_CANCELLED = new Symbol('STATE_CANCELLED');
    NO_RESULT = new Symbol('NO_RESULT');
    PARAM_CLAUSE_0 = new Symbol('PARAM_CLAUSE_0');
  }
}
function get_NO_OWNER() {
  _init_properties_Mutex_kt__jod56b();
  return NO_OWNER;
}
var NO_OWNER;
var ON_LOCK_ALREADY_LOCKED_BY_OWNER;
function Mutex(locked) {
  locked = locked === VOID ? false : locked;
  _init_properties_Mutex_kt__jod56b();
  return new MutexImpl(locked);
}
function MutexImpl$CancellableContinuationWithOwner$tryResume$lambda(this$0, this$1) {
  return function (_unused_var__etf5q3, _unused_var__etf5q3_0, _unused_var__etf5q3_1) {
    this$0.m1m_1.kotlinx$atomicfu$value = this$1.p1m_1;
    this$0.r1m(this$1.p1m_1);
    return Unit_instance;
  };
}
function MutexImpl$CancellableContinuationWithOwner$resume$lambda(this$0, this$1) {
  return function (it) {
    this$0.r1m(this$1.p1m_1);
    return Unit_instance;
  };
}
function holdsLockImpl($this, owner) {
  $l$loop: while (true) {
    if (!$this.s1m())
      return 0;
    var curOwner = $this.m1m_1.kotlinx$atomicfu$value;
    if (curOwner === get_NO_OWNER())
      continue $l$loop;
    return curOwner === owner ? 1 : 2;
  }
}
function *_generator_lock__qllepv($this, owner, $completion) {
  if ($this.t1m(owner))
    return Unit_instance;
  var tmp = lockSuspend($this, owner, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function lockSuspend($this, owner, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutineReusable.<anonymous>' call
  var cancellable = getOrCreateCancellableContinuation(intercepted($completion));
  try {
    // Inline function 'kotlinx.coroutines.sync.MutexImpl.lockSuspend.<anonymous>' call
    var contWithOwner = new CancellableContinuationWithOwner($this, cancellable, owner);
    $this.acquireCont(contWithOwner);
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      cancellable.c1a();
      throw e;
    } else {
      throw $p;
    }
  }
  return cancellable.g17();
}
function tryLockImpl($this, owner) {
  $l$loop: while (true) {
    if ($this.b1n()) {
      // Inline function 'kotlinx.coroutines.assert' call
      $this.m1m_1.kotlinx$atomicfu$value = owner;
      return 0;
    } else {
      if (owner == null)
        return 1;
      switch (holdsLockImpl($this, owner)) {
        case 1:
          return 2;
        case 2:
          return 1;
        case 0:
          continue $l$loop;
      }
    }
  }
}
function MutexImpl$onSelectCancellationUnlockConstructor$lambda$lambda(this$0, $owner) {
  return function (_unused_var__etf5q3, _unused_var__etf5q3_0, _unused_var__etf5q3_1) {
    this$0.r1m($owner);
    return Unit_instance;
  };
}
function MutexImpl$onSelectCancellationUnlockConstructor$lambda(this$0) {
  return function (_unused_var__etf5q3, owner, _unused_var__etf5q3_0) {
    return MutexImpl$onSelectCancellationUnlockConstructor$lambda$lambda(this$0, owner);
  };
}
var properties_initialized_Mutex_kt_yv4p3j;
function _init_properties_Mutex_kt__jod56b() {
  if (!properties_initialized_Mutex_kt_yv4p3j) {
    properties_initialized_Mutex_kt_yv4p3j = true;
    NO_OWNER = new Symbol('NO_OWNER');
    ON_LOCK_ALREADY_LOCKED_BY_OWNER = new Symbol('ALREADY_LOCKED_BY_OWNER');
  }
}
function get_MAX_SPIN_CYCLES() {
  _init_properties_Semaphore_kt__t514r6();
  return MAX_SPIN_CYCLES;
}
var MAX_SPIN_CYCLES;
function get_PERMIT() {
  _init_properties_Semaphore_kt__t514r6();
  return PERMIT;
}
var PERMIT;
function get_TAKEN() {
  _init_properties_Semaphore_kt__t514r6();
  return TAKEN;
}
var TAKEN;
function get_BROKEN() {
  _init_properties_Semaphore_kt__t514r6();
  return BROKEN;
}
var BROKEN;
function get_CANCELLED() {
  _init_properties_Semaphore_kt__t514r6();
  return CANCELLED;
}
var CANCELLED;
function get_SEGMENT_SIZE_0() {
  _init_properties_Semaphore_kt__t514r6();
  return SEGMENT_SIZE_0;
}
var SEGMENT_SIZE_0;
function decPermits($this) {
  $l$loop: while (true) {
    var p = $this.z1m_1.atomicfu$getAndDecrement();
    if (p > $this.u1m_1)
      continue $l$loop;
    return p;
  }
}
function coerceAvailablePermitsAtMaximum($this) {
  $l$loop_0: while (true) {
    var cur = $this.z1m_1.kotlinx$atomicfu$value;
    if (cur <= $this.u1m_1)
      break $l$loop_0;
    if ($this.z1m_1.atomicfu$compareAndSet(cur, $this.u1m_1))
      break $l$loop_0;
  }
}
function addAcquireToQueue($this, waiter) {
  var curTail = $this.x1m_1.kotlinx$atomicfu$value;
  var enqIdx = $this.y1m_1.atomicfu$getAndIncrement$long();
  var createNewSegment = createSegment$ref_0();
  var tmp$ret$2;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.x1m_1;
    // Inline function 'kotlin.Long.div' call
    var other = get_SEGMENT_SIZE_0();
    var id = enqIdx.e4(toLong(other));
    while (true) {
      var s = findSegmentInternal(curTail, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$1;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.c19_1.g2(to.c19_1) >= 0) {
              tmp$ret$1 = true;
              break $l$block_1;
            }
            if (!to.e1i()) {
              tmp$ret$1 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.f1i()) {
                cur.y6();
              }
              tmp$ret$1 = true;
              break $l$block_1;
            }
            if (to.f1i()) {
              to.y6();
            }
          }
          tmp$ret$1 = Unit_instance;
        }
        tmp = tmp$ret$1;
      }
      if (tmp) {
        tmp$ret$2 = s;
        break $l$block_2;
      }
    }
  }
  var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(tmp$ret$2);
  // Inline function 'kotlin.Long.rem' call
  var other_0 = get_SEGMENT_SIZE_0();
  var i = enqIdx.f4(toLong(other_0)).q2();
  // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.cas' call
  if (segment.m1n_1.atomicfu$get(i).atomicfu$compareAndSet(null, waiter)) {
    waiter.i1a(segment, i);
    return true;
  }
  // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.cas' call
  var expected = get_PERMIT();
  var value = get_TAKEN();
  if (segment.m1n_1.atomicfu$get(i).atomicfu$compareAndSet(expected, value)) {
    if (isInterface(waiter, CancellableContinuation)) {
      if (!isInterface(waiter, CancellableContinuation))
        THROW_CCE();
      waiter.e18(Unit_instance, $this.a1n_1);
    } else {
      if (isInterface(waiter, SelectInstance)) {
        waiter.a1m(Unit_instance);
      } else {
        var message = 'unexpected: ' + toString(waiter);
        throw IllegalStateException.m5(toString(message));
      }
    }
    return true;
  }
  // Inline function 'kotlinx.coroutines.assert' call
  return false;
}
function tryResumeNextFromQueue($this) {
  var curHead = $this.v1m_1.kotlinx$atomicfu$value;
  var deqIdx = $this.w1m_1.atomicfu$getAndIncrement$long();
  // Inline function 'kotlin.Long.div' call
  var other = get_SEGMENT_SIZE_0();
  var id = deqIdx.e4(toLong(other));
  var createNewSegment = createSegment$ref_1();
  var tmp$ret$2;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.v1m_1;
    while (true) {
      var s = findSegmentInternal(curHead, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$1;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.c19_1.g2(to.c19_1) >= 0) {
              tmp$ret$1 = true;
              break $l$block_1;
            }
            if (!to.e1i()) {
              tmp$ret$1 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.f1i()) {
                cur.y6();
              }
              tmp$ret$1 = true;
              break $l$block_1;
            }
            if (to.f1i()) {
              to.y6();
            }
          }
          tmp$ret$1 = Unit_instance;
        }
        tmp = tmp$ret$1;
      }
      if (tmp) {
        tmp$ret$2 = s;
        break $l$block_2;
      }
    }
  }
  var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(tmp$ret$2);
  segment.m1i();
  if (segment.c19_1.g2(id) > 0)
    return false;
  // Inline function 'kotlin.Long.rem' call
  var other_0 = get_SEGMENT_SIZE_0();
  var i = deqIdx.f4(toLong(other_0)).q2();
  // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.getAndSet' call
  var value = get_PERMIT();
  var cellState = segment.m1n_1.atomicfu$get(i).atomicfu$getAndSet(value);
  if (cellState === null) {
    // Inline function 'kotlin.repeat' call
    var times = get_MAX_SPIN_CYCLES();
    // Inline function 'kotlin.contracts.contract' call
    var inductionVariable = 0;
    if (inductionVariable < times)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.tryResumeNextFromQueue.<anonymous>' call
        // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.get' call
        if (segment.m1n_1.atomicfu$get(i).kotlinx$atomicfu$value === get_TAKEN())
          return true;
      }
       while (inductionVariable < times);
    // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.cas' call
    var expected = get_PERMIT();
    var value_0 = get_BROKEN();
    return !segment.m1n_1.atomicfu$get(i).atomicfu$compareAndSet(expected, value_0);
  } else if (cellState === get_CANCELLED())
    return false;
  else
    return tryResumeAcquire(cellState, $this);
}
function tryResumeAcquire(_this__u8e3s4, $this) {
  var tmp;
  if (isInterface(_this__u8e3s4, CancellableContinuation)) {
    if (!isInterface(_this__u8e3s4, CancellableContinuation))
      THROW_CCE();
    var token = _this__u8e3s4.a18(Unit_instance, null, $this.a1n_1);
    var tmp_0;
    if (!(token == null)) {
      _this__u8e3s4.b18(token);
      tmp_0 = true;
    } else {
      tmp_0 = false;
    }
    tmp = tmp_0;
  } else {
    if (isInterface(_this__u8e3s4, SelectInstance)) {
      tmp = _this__u8e3s4.z1i($this, Unit_instance);
    } else {
      var message = 'unexpected: ' + toString(_this__u8e3s4);
      throw IllegalStateException.m5(toString(message));
    }
  }
  return tmp;
}
function SemaphoreImpl$onCancellationRelease$lambda(this$0) {
  return function (_unused_var__etf5q3, _unused_var__etf5q3_0, _unused_var__etf5q3_1) {
    this$0.h1n();
    return Unit_instance;
  };
}
function createSegment$ref_0() {
  var l = function (p0, p1) {
    return createSegment_0(p0, p1);
  };
  l.callableName = 'createSegment';
  return l;
}
function createSegment$ref_1() {
  var l = function (p0, p1) {
    return createSegment_0(p0, p1);
  };
  l.callableName = 'createSegment';
  return l;
}
function createSegment_0(id, prev) {
  _init_properties_Semaphore_kt__t514r6();
  return new SemaphoreSegment(id, prev, 0);
}
var properties_initialized_Semaphore_kt_uqcwok;
function _init_properties_Semaphore_kt__t514r6() {
  if (!properties_initialized_Semaphore_kt_uqcwok) {
    properties_initialized_Semaphore_kt_uqcwok = true;
    MAX_SPIN_CYCLES = systemProp('kotlinx.coroutines.semaphore.maxSpinCycles', 100);
    PERMIT = new Symbol('PERMIT');
    TAKEN = new Symbol('TAKEN');
    BROKEN = new Symbol('BROKEN');
    CANCELLED = new Symbol('CANCELLED');
    SEGMENT_SIZE_0 = systemProp('kotlinx.coroutines.semaphore.segmentSize', 16);
  }
}
function createDefaultDispatcher() {
  var tmp;
  if (isJsdom()) {
    tmp = NodeDispatcher_getInstance();
  } else {
    var tmp_0;
    var tmp_1;
    if (!(typeof window === 'undefined')) {
      // Inline function 'kotlin.js.asDynamic' call
      tmp_1 = window != null;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      // Inline function 'kotlin.js.asDynamic' call
      tmp_0 = !(typeof window.addEventListener === 'undefined');
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = asCoroutineDispatcher(window);
    } else {
      if (typeof process === 'undefined' || typeof process.nextTick === 'undefined') {
        tmp = SetTimeoutDispatcher_getInstance();
      } else {
        tmp = NodeDispatcher_getInstance();
      }
    }
  }
  return tmp;
}
function isJsdom() {
  return !(typeof navigator === 'undefined') && navigator != null && navigator.userAgent != null && !(typeof navigator.userAgent === 'undefined') && !(typeof navigator.userAgent.match === 'undefined') && navigator.userAgent.match('\\bjsdom\\b');
}
var counter;
function get_DEBUG() {
  return DEBUG;
}
var DEBUG;
function get_classSimpleName(_this__u8e3s4) {
  var tmp0_elvis_lhs = getKClassFromExpression(_this__u8e3s4).xf();
  return tmp0_elvis_lhs == null ? 'Unknown' : tmp0_elvis_lhs;
}
function get_hexAddress(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  var result = _this__u8e3s4.__debug_counter;
  if (!(typeof result === 'number')) {
    counter = counter + 1 | 0;
    result = counter;
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.__debug_counter = result;
  }
  return ((!(result == null) ? typeof result === 'number' : false) ? result : THROW_CCE()).toString();
}
var NodeDispatcher_instance;
function NodeDispatcher_getInstance() {
  if (NodeDispatcher_instance === VOID)
    NodeDispatcher.p1n();
  return NodeDispatcher_instance;
}
function ScheduledMessageQueue$processQueue$lambda(this$0) {
  return function () {
    this$0.c1o();
    return Unit_instance;
  };
}
function w3cSetTimeout(handler, timeout) {
  return setTimeout(handler, timeout);
}
function WindowMessageQueue$lambda(this$0) {
  return function (event) {
    var tmp;
    if (event.source == this$0.r1o_1 && event.data == this$0.s1o_1) {
      event.stopPropagation();
      this$0.c1o();
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function WindowMessageQueue$schedule$lambda(this$0) {
  return function (it) {
    this$0.c1o();
    return Unit_instance;
  };
}
function w3cSetTimeout_0(window_0, handler, timeout) {
  return setTimeout_0(window_0, handler, timeout);
}
function w3cClearTimeout(window_0, handle) {
  return window_0.clearTimeout(handle);
}
function w3cClearTimeout_0(handle) {
  return clearTimeout(handle);
}
function setTimeout_0(window_0, handler, timeout) {
  return window_0.setTimeout(handler, timeout);
}
function await_0(_this__u8e3s4, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
  cancellable.u19();
  // Inline function 'kotlinx.coroutines.await.<anonymous>' call
  var tmp = await$lambda(cancellable);
  _this__u8e3s4.then(tmp, await$lambda_0(cancellable));
  return cancellable.g17();
}
function await$lambda($cont) {
  return function (it) {
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = $cont;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(it);
    this_0.id(tmp$ret$0);
    return Unit_instance;
  };
}
function await$lambda_0($cont) {
  return function (it) {
    // Inline function 'kotlin.coroutines.resumeWithException' call
    var this_0 = $cont;
    // Inline function 'kotlin.Companion.failure' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(it));
    this_0.id(tmp$ret$0);
    return Unit_instance;
  };
}
function asCoroutineDispatcher(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  var tmp0_elvis_lhs = _this__u8e3s4.coroutineDispatcher;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    // Inline function 'kotlin.also' call
    var this_0 = WindowDispatcher.w1o(_this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.asCoroutineDispatcher.<anonymous>' call
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.coroutineDispatcher = this_0;
    tmp = this_0;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function propagateExceptionFinalResort(exception) {
  console.error(exception.toString());
}
function createEventLoop() {
  return UnconfinedEventLoop.b1p();
}
function unsupported() {
  throw UnsupportedOperationException.ua('runBlocking event loop is not supported');
}
var SetTimeoutDispatcher_instance;
function SetTimeoutDispatcher_getInstance() {
  if (SetTimeoutDispatcher_instance === VOID)
    SetTimeoutDispatcher.e1p();
  return SetTimeoutDispatcher_instance;
}
function SetTimeoutBasedDispatcher$invokeOnTimeout$lambda($block) {
  return function () {
    $block.p1a();
    return Unit_instance;
  };
}
function SetTimeoutBasedDispatcher$scheduleResumeAfterDelay$lambda($continuation, this$0) {
  return function () {
    // Inline function 'kotlin.with' call
    // Inline function 'kotlin.contracts.contract' call
    $continuation.d18(this$0, Unit_instance);
    return Unit_instance;
  };
}
function WindowDispatcher$scheduleResumeAfterDelay$lambda($continuation, this$0) {
  return function () {
    // Inline function 'kotlin.with' call
    // Inline function 'kotlin.contracts.contract' call
    $continuation.d18(this$0, Unit_instance);
    return Unit_instance;
  };
}
function Runnable$run$ref($boundThis) {
  var l = function () {
    $boundThis.p1a();
    return Unit_instance;
  };
  l.callableName = 'run';
  return l;
}
function delayToInt(timeMillis) {
  return coerceIn(timeMillis, new Long(0, 0), new Long(2147483647, 0)).q2();
}
function toDebugString(_this__u8e3s4) {
  return toString(_this__u8e3s4);
}
function get_DefaultDelay() {
  var tmp = Dispatchers_getInstance().y1b_1;
  return isInterface(tmp, Delay) ? tmp : THROW_CCE();
}
function newCoroutineContext(_this__u8e3s4, context) {
  var combined = _this__u8e3s4.c15().xn(context);
  return !(combined === Dispatchers_getInstance().y1b_1) && combined.td(Key_instance) == null ? combined.xn(Dispatchers_getInstance().y1b_1) : combined;
}
function newCoroutineContext_0(_this__u8e3s4, addedContext) {
  return _this__u8e3s4.xn(addedContext);
}
function get_coroutineName(_this__u8e3s4) {
  return null;
}
var Dispatchers_instance;
function Dispatchers_getInstance() {
  if (Dispatchers_instance === VOID)
    new Dispatchers();
  return Dispatchers_instance;
}
function get_taskContext(_this__u8e3s4) {
  return TaskContext_instance;
}
var TaskContext_instance;
function TaskContext_getInstance() {
  return TaskContext_instance;
}
function *_generator_emit__qph46j($this, value, $completion) {
  // Inline function 'kotlinx.coroutines.currentCoroutineContext' call
  // Inline function 'kotlin.js.getCoroutineContext' call
  var currentContext = $completion.gd();
  ensureActive(currentContext);
  if (!($this.l1l_1 === currentContext)) {
    checkContext($this, currentContext);
    $this.l1l_1 = currentContext;
  }
  var tmp = $this.i1l_1.g1l(value, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function SafeCollector$collectContextSize$lambda(count, _unused_var__etf5q3) {
  return count + 1 | 0;
}
function identitySet(expectedSize) {
  return HashSet.s1(expectedSize);
}
function get_platformExceptionHandlers_() {
  _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf();
  return platformExceptionHandlers_;
}
var platformExceptionHandlers_;
function get_platformExceptionHandlers() {
  _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf();
  return get_platformExceptionHandlers_();
}
var properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx;
function _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf() {
  if (!properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx) {
    properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx = true;
    // Inline function 'kotlin.collections.mutableSetOf' call
    platformExceptionHandlers_ = LinkedHashSet.l1();
  }
}
function unwrap(exception) {
  return exception;
}
function recoverStackTrace(exception, continuation) {
  return exception;
}
function recoverStackTrace_0(exception) {
  return exception;
}
function systemProp_1(propertyName) {
  return null;
}
function threadContextElements(context) {
  return 0;
}
function commonThreadLocal(name) {
  return new CommonThreadLocal();
}
//region block: post-declaration
initMetadataForInterface(Job, 'Job', VOID, VOID, [Element], [0]);
initMetadataForInterface(ParentJob, 'ParentJob', VOID, VOID, [Job], [0]);
protoOf(JobSupport).l16 = cancel$default;
protoOf(JobSupport).xn = plus;
protoOf(JobSupport).td = get_0;
protoOf(JobSupport).wn = fold;
protoOf(JobSupport).vn = minusKey_0;
initMetadataForClass(JobSupport, 'JobSupport', VOID, VOID, [Job, ParentJob], [0]);
initMetadataForInterface(CoroutineScope, 'CoroutineScope');
initMetadataForClass(AbstractCoroutine, 'AbstractCoroutine', VOID, VOID, [JobSupport, Job, Continuation, CoroutineScope], [0]);
initMetadataForClass(StandaloneCoroutine, 'StandaloneCoroutine', VOID, VOID, VOID, [0]);
initMetadataForClass(LazyStandaloneCoroutine, 'LazyStandaloneCoroutine', VOID, VOID, VOID, [0]);
initMetadataForClass(ScopeCoroutine, 'ScopeCoroutine', VOID, VOID, VOID, [0]);
initMetadataForClass(DispatchedCoroutine, 'DispatchedCoroutine', VOID, VOID, VOID, [0]);
initMetadataForInterface(Deferred, 'Deferred', VOID, VOID, [Job], [0]);
initMetadataForClass(DeferredCoroutine, 'DeferredCoroutine', VOID, VOID, [AbstractCoroutine, Deferred], [0]);
initMetadataForClass(LazyDeferredCoroutine, 'LazyDeferredCoroutine', VOID, VOID, VOID, [0]);
initMetadataForInterface(CancellableContinuation, 'CancellableContinuation', VOID, VOID, [Continuation]);
initMetadataForInterface(NotCompleted, 'NotCompleted');
initMetadataForInterface(CancelHandler, 'CancelHandler', VOID, VOID, [NotCompleted]);
initMetadataForClass(DisposeOnCancel, 'DisposeOnCancel', VOID, VOID, [CancelHandler]);
initMetadataForInterface(Runnable, 'Runnable');
initMetadataForClass(SchedulerTask, 'SchedulerTask', VOID, VOID, [Runnable]);
initMetadataForClass(DispatchedTask, 'DispatchedTask');
initMetadataForInterface(Waiter, 'Waiter');
initMetadataForClass(CancellableContinuationImpl, 'CancellableContinuationImpl', VOID, VOID, [DispatchedTask, CancellableContinuation, Waiter]);
initMetadataForClass(UserSupplied, 'UserSupplied', VOID, VOID, [CancelHandler]);
initMetadataForObject(Active, 'Active', VOID, VOID, [NotCompleted]);
initMetadataForClass(CompletedContinuation, 'CompletedContinuation');
initMetadataForClass(LockFreeLinkedListNode, 'LockFreeLinkedListNode', LockFreeLinkedListNode);
initMetadataForInterface(Incomplete, 'Incomplete');
initMetadataForClass(JobNode, 'JobNode', VOID, VOID, [LockFreeLinkedListNode, Incomplete]);
initMetadataForClass(ChildContinuation, 'ChildContinuation');
initMetadataForClass(CompletableDeferredImpl, 'CompletableDeferredImpl', VOID, VOID, [JobSupport, Deferred], [0]);
initMetadataForClass(CompletedExceptionally, 'CompletedExceptionally');
initMetadataForClass(CancelledContinuation, 'CancelledContinuation');
initMetadataForClass(CompletedWithCancellation, 'CompletedWithCancellation');
initMetadataForObject(Key, 'Key');
protoOf(CoroutineDispatcher).td = get;
protoOf(CoroutineDispatcher).vn = minusKey;
initMetadataForClass(CoroutineDispatcher, 'CoroutineDispatcher', VOID, VOID, [AbstractCoroutineContextElement, ContinuationInterceptor]);
initMetadataForObject(Key_0, 'Key');
initMetadataForClass(CoroutineStart, 'CoroutineStart');
initMetadataForInterface(Delay, 'Delay', VOID, VOID, VOID, [1]);
initMetadataForInterface(DelayWithTimeoutDiagnostics, 'DelayWithTimeoutDiagnostics', VOID, VOID, [Delay], [1]);
initMetadataForClass(EventLoop, 'EventLoop');
initMetadataForObject(ThreadLocalEventLoop, 'ThreadLocalEventLoop');
initMetadataForClass(CompletionHandlerException, 'CompletionHandlerException');
initMetadataForClass(CoroutinesInternalError, 'CoroutinesInternalError');
initMetadataForObject(Key_1, 'Key');
initMetadataForObject(NonDisposableHandle, 'NonDisposableHandle');
initMetadataForClass(DisposeOnCompletion, 'DisposeOnCompletion');
initMetadataForClass(Empty, 'Empty', VOID, VOID, [Incomplete]);
initMetadataForClass(LockFreeLinkedListHead, 'LockFreeLinkedListHead', LockFreeLinkedListHead);
initMetadataForClass(NodeList, 'NodeList', NodeList, VOID, [LockFreeLinkedListHead, Incomplete]);
initMetadataForClass(SynchronizedObject, 'SynchronizedObject', SynchronizedObject);
initMetadataForClass(Finishing, 'Finishing', VOID, VOID, [SynchronizedObject, Incomplete]);
initMetadataForClass(ChildCompletion, 'ChildCompletion');
initMetadataForClass(AwaitContinuation, 'AwaitContinuation');
initMetadataForLambda(JobSupport$_get_children_$slambda_k839f8, VOID, VOID, [1]);
initMetadataForClass(InactiveNodeList, 'InactiveNodeList', VOID, VOID, [Incomplete]);
initMetadataForClass(InvokeOnCompletion, 'InvokeOnCompletion');
initMetadataForClass(InvokeOnCancelling, 'InvokeOnCancelling');
initMetadataForClass(ResumeOnCompletion, 'ResumeOnCompletion');
initMetadataForClass(ChildHandleNode, 'ChildHandleNode');
initMetadataForClass(ResumeAwaitOnCompletion, 'ResumeAwaitOnCompletion');
initMetadataForClass(IncompleteStateBox, 'IncompleteStateBox');
initMetadataForClass(JobImpl, 'JobImpl', VOID, VOID, [JobSupport, Job], [0]);
initMetadataForClass(MainCoroutineDispatcher, 'MainCoroutineDispatcher');
initMetadataForClass(SupervisorJobImpl, 'SupervisorJobImpl', VOID, VOID, VOID, [0]);
initMetadataForClass(TimeoutCancellationException, 'TimeoutCancellationException');
initMetadataForClass(TimeoutCoroutine, 'TimeoutCoroutine', VOID, VOID, [ScopeCoroutine, Runnable], [0]);
initMetadataForObject(Unconfined, 'Unconfined');
initMetadataForObject(Key_2, 'Key');
initMetadataForClass(BufferOverflow, 'BufferOverflow');
initMetadataForClass(ConcurrentLinkedListNode, 'ConcurrentLinkedListNode');
initMetadataForClass(Segment, 'Segment', VOID, VOID, [ConcurrentLinkedListNode, NotCompleted]);
initMetadataForClass(ChannelSegment, 'ChannelSegment');
initMetadataForClass(SendBroadcast, 'SendBroadcast', VOID, VOID, [Waiter]);
initMetadataForClass(BufferedChannelIterator, 'BufferedChannelIterator', VOID, VOID, [Waiter], [0, 3]);
initMetadataForClass(BufferedChannel, 'BufferedChannel', VOID, VOID, VOID, [1, 4, 0, 3]);
initMetadataForClass(WaiterEB, 'WaiterEB');
initMetadataForClass(ReceiveCatching, 'ReceiveCatching', VOID, VOID, [Waiter]);
initMetadataForObject(Factory, 'Factory');
initMetadataForClass(Failed, 'Failed', Failed);
initMetadataForClass(Closed, 'Closed');
initMetadataForCompanion(Companion);
initMetadataForClass(ChannelResult, 'ChannelResult');
initMetadataForClass(ClosedSendChannelException, 'ClosedSendChannelException');
initMetadataForClass(ClosedReceiveChannelException, 'ClosedReceiveChannelException');
initMetadataForClass(ConflatedBufferedChannel, 'ConflatedBufferedChannel', VOID, VOID, VOID, [1, 0]);
initMetadataForClass(AbstractFlow, 'AbstractFlow', VOID, VOID, VOID, [1]);
initMetadataForClass(SafeFlow, 'SafeFlow', VOID, VOID, VOID, [1]);
initMetadataForClass(_no_name_provided__qut3iv, VOID, VOID, VOID, VOID, [1]);
initMetadataForInterface(FlowCollector, 'FlowCollector', VOID, VOID, VOID, [1]);
initMetadataForClass(sam$kotlinx_coroutines_flow_FlowCollector$0, 'sam$kotlinx_coroutines_flow_FlowCollector$0', VOID, VOID, [FlowCollector], [1]);
initMetadataForLambda(toCollection$slambda, VOID, VOID, [1]);
initMetadataForClass(SegmentOrClosed, 'SegmentOrClosed');
initMetadataForObject(ExceptionSuccessfullyProcessed, 'ExceptionSuccessfullyProcessed');
initMetadataForClass(DispatchedContinuation, 'DispatchedContinuation', VOID, VOID, [DispatchedTask, Continuation]);
initMetadataForClass(UndeliveredElementException, 'UndeliveredElementException');
initMetadataForClass(ContextScope, 'ContextScope', VOID, VOID, [CoroutineScope]);
initMetadataForClass(Symbol, 'Symbol');
initMetadataForInterface(SelectInstance, 'SelectInstance');
initMetadataForClass(ClauseData, 'ClauseData', VOID, VOID, VOID, [1]);
initMetadataForClass(SelectImplementation, 'SelectImplementation', VOID, VOID, [CancelHandler, Waiter, SelectInstance], [0, 2]);
initMetadataForClass(TrySelectDetailedResult, 'TrySelectDetailedResult');
initMetadataForClass(CancellableContinuationWithOwner, 'CancellableContinuationWithOwner', VOID, VOID, [CancellableContinuation, Waiter]);
initMetadataForClass(SemaphoreImpl, 'SemaphoreImpl', VOID, VOID, VOID, [0]);
initMetadataForClass(MutexImpl, 'MutexImpl', VOID, VOID, VOID, [1, 0]);
initMetadataForClass(SemaphoreSegment, 'SemaphoreSegment');
initMetadataForClass(SetTimeoutBasedDispatcher, 'SetTimeoutBasedDispatcher', VOID, VOID, [CoroutineDispatcher, Delay], [1]);
initMetadataForObject(NodeDispatcher, 'NodeDispatcher', VOID, VOID, VOID, [1]);
initMetadataForClass(MessageQueue, 'MessageQueue', VOID, VOID, [KtMutableList]);
initMetadataForClass(ScheduledMessageQueue, 'ScheduledMessageQueue');
initMetadataForClass(WindowMessageQueue, 'WindowMessageQueue');
initMetadataForClass(UnconfinedEventLoop, 'UnconfinedEventLoop', UnconfinedEventLoop.b1p);
initMetadataForObject(SetTimeoutDispatcher, 'SetTimeoutDispatcher', VOID, VOID, VOID, [1]);
initMetadataForClass(ClearTimeout, 'ClearTimeout', VOID, VOID, [CancelHandler]);
initMetadataForClass(WindowClearTimeout, 'WindowClearTimeout');
initMetadataForClass(WindowDispatcher, 'WindowDispatcher', VOID, VOID, [CoroutineDispatcher, Delay], [1]);
initMetadataForClass(CloseableCoroutineDispatcher, 'CloseableCoroutineDispatcher', VOID, VOID, [CoroutineDispatcher, AutoCloseable]);
initMetadataForClass(UndispatchedCoroutine, 'UndispatchedCoroutine', VOID, VOID, VOID, [0]);
initMetadataForObject(Dispatchers, 'Dispatchers');
initMetadataForClass(JsMainDispatcher, 'JsMainDispatcher');
initMetadataForClass(JobCancellationException, 'JobCancellationException');
initMetadataForObject(TaskContext, 'TaskContext');
initMetadataForClass(SafeCollector, 'SafeCollector', VOID, VOID, [FlowCollector], [1]);
initMetadataForClass(DiagnosticCoroutineContextException, 'DiagnosticCoroutineContextException');
initMetadataForClass(ListClosed, 'ListClosed');
initMetadataForClass(CommonThreadLocal, 'CommonThreadLocal', CommonThreadLocal);
//endregion
//region block: init
Active_instance = new Active();
Key_instance_1 = new Key_0();
Key_instance_2 = new Key_1();
NonDisposableHandle_instance = new NonDisposableHandle();
Key_instance_3 = new Key_2();
counter = 0;
DEBUG = false;
TaskContext_instance = new TaskContext();
//endregion
//region block: exports
export {
  CoroutineStart_UNDISPATCHED_getInstance as CoroutineStart_UNDISPATCHED_getInstance1s89xhsoy2cne,
  toList as toListgag8q7o845sq,
  await_0 as await2pwm8za8tntlx,
  cancelAndJoin as cancelAndJoin2e3e4cwh51my6,
  coroutineScope as coroutineScopesisx8kbb9f8x,
  delay_0 as delayop3h8lf643wg,
  delay as delay1a7xkalnslpld,
  withContext as withContext2i47m7ae4v1sd,
  withTimeout as withTimeout3hvfy7xgqgnvm,
  Dispatchers_getInstance as Dispatchers_getInstance1nk2l7rcqz5wi,
  Key_instance_2 as Key_instance3o7pj7ik1b183,
  Channel as Channel3r72atmcithql,
  FlowCollector as FlowCollector26clgpmzihvke,
  asFlow as asFlow3ngsnn5xpz8pw,
  flow as flow3tazazxj2t7g4,
  Mutex as Mutex16li1l0asjv17,
  CancellableContinuationImpl as CancellableContinuationImpl1cx201opicavg,
  CloseableCoroutineDispatcher as CloseableCoroutineDispatcher1gpuztfef1ucn,
  CompletableDeferred as CompletableDeferred2lnqvsbvx74d3,
  CoroutineDispatcher as CoroutineDispatcherizb7p9bijexj,
  CoroutineScope_0 as CoroutineScopelux7s7zphw7e,
  CoroutineScope as CoroutineScopefcb5f5dwqcas,
  Deferred as Deferred6wjx2us769ee,
  invokeOnTimeout as invokeOnTimeout3llts2igdsa63,
  Delay as Delay9umexudtwyie,
  Job_0 as Job13y4jkazwjho0,
  MainScope as MainScope1gi1r4abhrtmm,
  SupervisorJob as SupervisorJobythnfxkr3jxc,
  TimeoutCancellationException as TimeoutCancellationException198b5zwr3v3uw,
  async as asyncz02dsa2nb2zt,
  cancelChildren as cancelChildrennwartoz0yn6c,
  cancel as cancel2en0dn4yvpufo,
  completeWith as completeWith2hvdlbg1q1mlx,
  get_job as get_job2zvlvce9o9a29,
  launch as launch1c91vkjzdi9sd,
};
//endregion
