import {
  _UShort___get_data__impl__g02453juyrdkc7n6xd as _UShort___get_data__impl__g0245,
  FloatCompanionObject_instance29smzmmxq0czz as FloatCompanionObject_instance,
  floatFromBits1n9d03e2m5i5s as floatFromBits,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    this.c3h_1 = 15;
  }
  d3h(word) {
    // Inline function 'kotlin.UShort.toInt' call
    var word_0 = _UShort___get_data__impl__g0245(word) & 65535;
    var t2 = word_0 & 32768;
    var t1 = word_0 & 32767;
    var t3 = word_0 & 31744;
    if (t3 === 0)
      return t2 === 0 ? 0.0 : -0.0;
    if (t3 === 31744) {
      if (!((t1 & 1023) === 0))
        return NaN;
      return t2 === 0 ? Infinity : -Infinity;
    }
    t1 = t1 << 13;
    t1 = t1 + 939524096 | 0;
    t1 = t3 === 0 ? 0 : t1;
    t1 = t1 | t2 << 16;
    // Inline function 'korlibs.number.internal.reinterpretAsFloat' call
    // Inline function 'kotlin.fromBits' call
    var bits = t1;
    return floatFromBits(bits);
  }
}
//endregion
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
//region block: post-declaration
initMetadataForCompanion(Companion);
//endregion
//region block: init
Companion_instance = new Companion();
//endregion
//region block: exports
export {
  Companion_instance as Companion_instance3l8wldvwuxoxv,
};
//endregion
