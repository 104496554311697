import {
  setExtra314k0z6q35eku as setExtra,
  getExtraTyped8zexqxz5xxli as getExtraTyped,
  Property1m5vulkepgwzd as Property,
  PropertyThis2r0hbk1cs09wj as PropertyThis,
} from './korlibs-korlibs-datastructure-core.mjs';
import {
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  VOID7hggqo3abtya as VOID,
  numberToDouble210hrknaofnhf as numberToDouble,
  equals2au1ep9vhcato as equals,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  DurationUnit_MILLISECONDS_getInstance15owevua4zjxe as DurationUnit_MILLISECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  isInterface3d6p8outrmvmk as isInterface,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  KMutableProperty11e8g1gb0ecb9j as KMutableProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  createThis2j2avj17cvnv2 as createThis,
  Enum3alwj03lh1n41 as Enum,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  toByte4i43936u611k as toByte,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  Comparable198qfk8pnblz0 as Comparable,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  longArray288a0fctlmjmj as longArray,
  arrayCopytctsywo3h7gj as arrayCopy,
  Long2qws0ah9gnpki as Long,
  toLongw1zpgk99d84b as toLong,
  isArray1hxjqtqy632bc as isArray,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  round2mrvepag8eey0 as round,
  Monotonic_instance19wu6xfdyzm85 as Monotonic_instance,
  ValueTimeMark__elapsedNow_impl_eonqvs2ntz2k8jdtq63 as ValueTimeMark__elapsedNow_impl_eonqvs,
  isNaN3ixot6a1mjs5h as isNaN_0,
  isInfinite2z3fcu1dj15nr as isInfinite,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  isFinite1tx0gn65nl9tj as isFinite,
  toString30pk9tzaqopn as toString_0,
  hashCodeq5arwsb9dgti as hashCode,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  copyOfRangectu1wvlwieiw as copyOfRange,
  sortedArray3ci6175tfpe6q as sortedArray,
  copyOfRangebnui7uc1r2c1 as copyOfRange_0,
  sortedArray1s6qhg3jakjnx as sortedArray_0,
  numberToLong1a4cndvg6c52s as numberToLong,
  HashMap1a0ld5kgwhmhv as HashMap,
  lazy2hsh8ze7j6ikd as lazy,
  KProperty1ca4yb4wlo496 as KProperty1,
} from './kotlin-kotlin-stdlib.mjs';
import {
  get_secondsjblpw5qd4dzj as get_seconds,
  get_milliseconds20vru1dkca6bq as get_milliseconds,
} from './korlibs-korlibs-time-core.mjs';
import {
  positionu6441b7hkb4l as position,
  Stage1ggtrzujnnkl0 as Stage,
  addFixedUpdater1i013etalwvod as addFixedUpdater,
  Reference3ldn5efkcy9oq as Reference,
} from './korge-root-korge.mjs';
import { Companion_getInstance2iuza9dwssjbj as Companion_getInstance_0 } from './korlibs-korlibs-math.mjs';
import { get_invalidOp31x7cztl0dv9m as get_invalidOp } from './korlibs-korlibs-platform.mjs';
import {
  get_degrees3czcd4ojw4i as get_degrees,
  get_radians3l1td1j6jwrs as get_radians,
  _Angle___get_radians__impl__n00yt523wqxa1jdhpa1 as _Angle___get_radians__impl__n00yt5,
} from './korlibs-korlibs-math-vector.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Key {}
class Key_0 extends Key {
  constructor() {
    Key_instance = null;
    super();
    Key_instance = this;
  }
}
class Box2dWorldComponent {
  constructor(worldView, world, velocityIterations, positionIterations, autoDestroyBodies, step) {
    velocityIterations = velocityIterations === VOID ? 6 : velocityIterations;
    positionIterations = positionIterations === VOID ? 2 : positionIterations;
    autoDestroyBodies = autoDestroyBodies === VOID ? true : autoDestroyBodies;
    var tmp;
    if (step === VOID) {
      // Inline function 'korlibs.time.milliseconds' call
      // Inline function 'kotlin.time.Companion.milliseconds' call
      Companion_getInstance();
      tmp = toDuration(16, DurationUnit_MILLISECONDS_getInstance());
    } else {
      tmp = step;
    }
    step = tmp;
    this.li1_1 = worldView;
    this.mi1_1 = world;
    this.ni1_1 = velocityIterations;
    this.oi1_1 = positionIterations;
    this.pi1_1 = autoDestroyBodies;
    this.qi1_1 = step;
    var tmp_0 = this;
    tmp_0.ri1_1 = addFixedUpdater(this.li1_1, this.qi1_1, VOID, VOID, Box2dWorldComponent$updater$lambda(this));
    set_component(this.mi1_1, this);
    this.si1_1 = new Vec2();
    this.ti1_1 = Companion_getInstance_0().l2m();
  }
  zi3() {
    return this.mi1_1;
  }
}
class ContactFilter {
  mi6(fixtureA, fixtureB) {
    var filterA = fixtureA.ci7();
    var filterB = fixtureB.ci7();
    if (filterA.fi7_1 === filterB.fi7_1 && !(filterA.fi7_1 === 0)) {
      return filterA.fi7_1 > 0;
    }
    var collide = !((filterA.ei7_1 & filterB.di7_1) === 0) && !((filterA.di7_1 & filterB.ei7_1) === 0);
    return collide;
  }
}
class ContactImpulse {
  constructor() {
    this.gi7_1 = new Float32Array(Settings_getInstance().ui7());
    this.hi7_1 = new Float32Array(Settings_getInstance().ui7());
    this.ii7_1 = 0;
  }
}
class Companion {
  vi7(a, b) {
    if (b.wi7_1.ai4_1 - a.xi7_1.ai4_1 > 0.0 || b.wi7_1.bi4_1 - a.xi7_1.bi4_1 > 0.0) {
      return false;
    }
    return !(a.wi7_1.ai4_1 - b.xi7_1.ai4_1 > 0.0 || a.wi7_1.bi4_1 - b.xi7_1.bi4_1 > 0.0);
  }
}
class AABB {
  yi7() {
    var dx = this.xi7_1.ai4_1 - this.wi7_1.ai4_1;
    if (dx < 0.0) {
      return false;
    }
    var dy = this.xi7_1.bi4_1 - this.wi7_1.bi4_1;
    var tmp;
    if (dy < 0) {
      tmp = false;
    } else {
      tmp = (this.wi7_1.yi7() && this.xi7_1.yi7());
    }
    return tmp;
  }
  zi7() {
    return 2.0 * (this.xi7_1.ai4_1 - this.wi7_1.ai4_1 + this.xi7_1.bi4_1 - this.wi7_1.bi4_1);
  }
  static ai8() {
    var $this = createThis(this);
    init_org_jbox2d_collision_AABB($this);
    $this.wi7_1 = new Vec2();
    $this.xi7_1 = new Vec2();
    return $this;
  }
  bi8(aabb1, aab) {
    this.wi7_1.ai4_1 = aabb1.wi7_1.ai4_1 < aab.wi7_1.ai4_1 ? aabb1.wi7_1.ai4_1 : aab.wi7_1.ai4_1;
    this.wi7_1.bi4_1 = aabb1.wi7_1.bi4_1 < aab.wi7_1.bi4_1 ? aabb1.wi7_1.bi4_1 : aab.wi7_1.bi4_1;
    this.xi7_1.ai4_1 = aabb1.xi7_1.ai4_1 > aab.xi7_1.ai4_1 ? aabb1.xi7_1.ai4_1 : aab.xi7_1.ai4_1;
    this.xi7_1.bi4_1 = aabb1.xi7_1.bi4_1 > aab.xi7_1.bi4_1 ? aabb1.xi7_1.bi4_1 : aab.xi7_1.bi4_1;
  }
  toString() {
    var s = 'AABB[' + this.wi7_1.toString() + ' . ' + this.xi7_1.toString() + ']';
    return s;
  }
}
class Type extends Enum {}
class EdgeResults {
  constructor() {
    this.ci8_1 = 0;
    this.di8_1 = 0;
  }
}
class ClipVertex {
  constructor() {
    this.ei8_1 = new Vec2();
    this.fi8_1 = ContactID.ki8();
  }
  li8(cv) {
    var v1 = cv.ei8_1;
    this.ei8_1.ai4_1 = v1.ai4_1;
    this.ei8_1.bi4_1 = v1.bi4_1;
    var c = cv.fi8_1;
    this.fi8_1.gi8_1 = c.gi8_1;
    this.fi8_1.hi8_1 = c.hi8_1;
    this.fi8_1.ii8_1 = c.ii8_1;
    this.fi8_1.ji8_1 = c.ji8_1;
  }
}
class EPAxis {
  constructor() {
    this.mi8_1 = null;
    this.ni8_1 = 0;
    this.oi8_1 = 0;
  }
}
class TempPolygon {
  constructor() {
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().pi8();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = new Vec2();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.qi8_1 = tmp_2;
    var tmp_3 = this;
    var tmp_4 = 0;
    var tmp_5 = Settings_getInstance().pi8();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_6 = fillArrayVal(Array(tmp_5), null);
    while (tmp_4 < tmp_5) {
      tmp_6[tmp_4] = new Vec2();
      tmp_4 = tmp_4 + 1 | 0;
    }
    tmp_3.ri8_1 = tmp_6;
    this.si8_1 = 0;
  }
}
class ReferenceFace {
  constructor() {
    this.ti8_1 = 0;
    this.ui8_1 = 0;
    this.vi8_1 = new Vec2();
    this.wi8_1 = new Vec2();
    this.xi8_1 = new Vec2();
    this.yi8_1 = new Vec2();
    this.zi8_1 = 0;
    this.ai9_1 = new Vec2();
    this.bi9_1 = 0;
  }
}
class EPCollider {
  constructor() {
    this.ci9_1 = new TempPolygon();
    this.di9_1 = Transform.iia();
    this.ei9_1 = new Vec2();
    this.fi9_1 = new Vec2();
    this.gi9_1 = new Vec2();
    this.hi9_1 = new Vec2();
    this.ii9_1 = new Vec2();
    this.ji9_1 = new Vec2();
    this.ki9_1 = new Vec2();
    this.li9_1 = new Vec2();
    this.mi9_1 = new Vec2();
    this.ni9_1 = null;
    this.oi9_1 = null;
    this.pi9_1 = new Vec2();
    this.qi9_1 = new Vec2();
    this.ri9_1 = 0;
    this.si9_1 = false;
    this.ti9_1 = new Vec2();
    this.ui9_1 = new Vec2();
    this.vi9_1 = new Vec2();
    this.wi9_1 = new Vec2();
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(2), null);
    while (tmp_0 < 2) {
      tmp_1[tmp_0] = new ClipVertex();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.xi9_1 = tmp_1;
    var tmp_2 = this;
    var tmp_3 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_4 = fillArrayVal(Array(2), null);
    while (tmp_3 < 2) {
      tmp_4[tmp_3] = new ClipVertex();
      tmp_3 = tmp_3 + 1 | 0;
    }
    tmp_2.yi9_1 = tmp_4;
    var tmp_5 = this;
    var tmp_6 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_7 = fillArrayVal(Array(2), null);
    while (tmp_6 < 2) {
      tmp_7[tmp_6] = new ClipVertex();
      tmp_6 = tmp_6 + 1 | 0;
    }
    tmp_5.zi9_1 = tmp_7;
    this.aia_1 = new ReferenceFace();
    this.bia_1 = new EPAxis();
    this.cia_1 = new EPAxis();
    this.dia_1 = new Vec2();
    this.eia_1 = new Vec2();
    this.fia_1 = new Vec2();
  }
  jia(manifold, edgeA, xfA, polygonB, xfB) {
    Companion_instance_11.kia(xfA, xfB, this.di9_1, this.fia_1);
    Companion_instance_11.lia(this.di9_1, polygonB.ai6_1, this.ei9_1);
    this.fi9_1 = edgeA.qia_1;
    this.gi9_1 = edgeA.oia_1;
    this.hi9_1 = edgeA.pia_1;
    this.ii9_1 = edgeA.ria_1;
    var hasVertex0 = edgeA.sia_1;
    var hasVertex3 = edgeA.tia_1;
    this.ti9_1.via(this.hi9_1).wia(this.gi9_1);
    this.ti9_1.xia();
    this.ki9_1.ci4(this.ti9_1.bi4_1, -this.ti9_1.ai4_1);
    var offset1 = Companion_instance_12.yia(this.ki9_1, this.ui9_1.via(this.ei9_1).wia(this.gi9_1));
    var offset0 = 0.0;
    var offset2 = 0.0;
    var convex1 = false;
    var convex2 = false;
    if (hasVertex0) {
      this.vi9_1.via(this.gi9_1).wia(this.fi9_1);
      this.vi9_1.xia();
      this.ji9_1.ci4(this.vi9_1.bi4_1, -this.vi9_1.ai4_1);
      convex1 = Companion_instance_12.zia(this.vi9_1, this.ti9_1) >= 0.0;
      offset0 = Companion_instance_12.yia(this.ji9_1, this.ui9_1.via(this.ei9_1).wia(this.fi9_1));
    }
    if (hasVertex3) {
      this.wi9_1.via(this.ii9_1).wia(this.hi9_1);
      this.wi9_1.xia();
      this.li9_1.ci4(this.wi9_1.bi4_1, -this.wi9_1.ai4_1);
      convex2 = Companion_instance_12.zia(this.ti9_1, this.wi9_1) > 0.0;
      offset2 = Companion_instance_12.yia(this.li9_1, this.ui9_1.via(this.ei9_1).wia(this.hi9_1));
    }
    if (hasVertex0 && hasVertex3) {
      if (convex1 && convex2) {
        this.si9_1 = offset0 >= 0.0 || offset1 >= 0.0 || offset2 >= 0.0;
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ji9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ji9_1.bi4_1;
          this.qi9_1.ai4_1 = this.li9_1.ai4_1;
          this.qi9_1.bi4_1 = this.li9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ki9_1.bi4_1;
        }
      } else if (convex1) {
        this.si9_1 = offset0 >= 0.0 || (offset1 >= 0.0 && offset2 >= 0.0);
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ji9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ji9_1.bi4_1;
          this.qi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = this.ki9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.li9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.li9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ki9_1.bi4_1;
        }
      } else if (convex2) {
        this.si9_1 = offset2 >= 0.0 || (offset0 >= 0.0 && offset1 >= 0.0);
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = this.li9_1.ai4_1;
          this.qi9_1.bi4_1 = this.li9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ji9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ji9_1.bi4_1;
        }
      } else {
        this.si9_1 = (offset0 >= 0.0 && offset1 >= 0.0 && offset2 >= 0.0);
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = this.ki9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.li9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.li9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ji9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ji9_1.bi4_1;
        }
      }
    } else if (hasVertex0) {
      if (convex1) {
        this.si9_1 = offset0 >= 0.0 || offset1 >= 0.0;
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ji9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ji9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ki9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ki9_1.bi4_1;
        }
      } else {
        this.si9_1 = (offset0 >= 0.0 && offset1 >= 0.0);
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ki9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = -this.ji9_1.ai4_1;
          this.qi9_1.bi4_1 = -this.ji9_1.bi4_1;
        }
      }
    } else if (hasVertex3) {
      if (convex2) {
        this.si9_1 = offset1 >= 0.0 || offset2 >= 0.0;
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = this.li9_1.ai4_1;
          this.qi9_1.bi4_1 = this.li9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = this.ki9_1.bi4_1;
        }
      } else {
        this.si9_1 = (offset1 >= 0.0 && offset2 >= 0.0);
        if (this.si9_1) {
          this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.qi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = this.ki9_1.bi4_1;
        } else {
          this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
          this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
          this.pi9_1.ai4_1 = -this.li9_1.ai4_1;
          this.pi9_1.bi4_1 = -this.li9_1.bi4_1;
          this.qi9_1.ai4_1 = this.ki9_1.ai4_1;
          this.qi9_1.bi4_1 = this.ki9_1.bi4_1;
        }
      }
    } else {
      this.si9_1 = offset1 >= 0.0;
      if (this.si9_1) {
        this.mi9_1.ai4_1 = this.ki9_1.ai4_1;
        this.mi9_1.bi4_1 = this.ki9_1.bi4_1;
        this.pi9_1.ai4_1 = -this.ki9_1.ai4_1;
        this.pi9_1.bi4_1 = -this.ki9_1.bi4_1;
        this.qi9_1.ai4_1 = -this.ki9_1.ai4_1;
        this.qi9_1.bi4_1 = -this.ki9_1.bi4_1;
      } else {
        this.mi9_1.ai4_1 = -this.ki9_1.ai4_1;
        this.mi9_1.bi4_1 = -this.ki9_1.bi4_1;
        this.pi9_1.ai4_1 = this.ki9_1.ai4_1;
        this.pi9_1.bi4_1 = this.ki9_1.bi4_1;
        this.qi9_1.ai4_1 = this.ki9_1.ai4_1;
        this.qi9_1.bi4_1 = this.ki9_1.bi4_1;
      }
    }
    this.ci9_1.si8_1 = polygonB.di6_1;
    var inductionVariable = 0;
    var last = polygonB.di6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        Companion_instance_11.lia(this.di9_1, polygonB.bi6_1[i], this.ci9_1.qi8_1[i]);
        Companion_instance_10.aib(this.di9_1.hia_1, polygonB.ci6_1[i], this.ci9_1.ri8_1[i]);
      }
       while (inductionVariable < last);
    this.ri9_1 = 2.0 * Settings_getInstance().bib();
    manifold.gib_1 = 0;
    this.hib(this.bia_1);
    if (equals(this.bia_1.mi8_1, Type_UNKNOWN_getInstance())) {
      return Unit_instance;
    }
    if (this.bia_1.oi8_1 > this.ri9_1) {
      return Unit_instance;
    }
    this.iib(this.cia_1);
    if (!equals(this.cia_1.mi8_1, Type_UNKNOWN_getInstance()) && this.cia_1.oi8_1 > this.ri9_1) {
      return Unit_instance;
    }
    var k_relativeTol = 0.98;
    var k_absoluteTol = 0.001;
    var primaryAxis;
    if (equals(this.cia_1.mi8_1, Type_UNKNOWN_getInstance())) {
      primaryAxis = this.bia_1;
    } else if (this.cia_1.oi8_1 > k_relativeTol * this.bia_1.oi8_1 + k_absoluteTol) {
      primaryAxis = this.cia_1;
    } else {
      primaryAxis = this.bia_1;
    }
    var ie0 = this.xi9_1[0];
    var ie1 = this.xi9_1[1];
    if (equals(primaryAxis.mi8_1, Type_EDGE_A_getInstance())) {
      manifold.fib_1 = ManifoldType_FACE_A_getInstance();
      var bestIndex = 0;
      var bestValue = Companion_instance_12.yia(this.mi9_1, this.ci9_1.ri8_1[0]);
      var inductionVariable_0 = 1;
      var last_0 = this.ci9_1.si8_1;
      if (inductionVariable_0 < last_0)
        do {
          var i_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var value = Companion_instance_12.yia(this.mi9_1, this.ci9_1.ri8_1[i_0]);
          if (value < bestValue) {
            bestValue = value;
            bestIndex = i_0;
          }
        }
         while (inductionVariable_0 < last_0);
      var i1 = bestIndex;
      var i2 = (i1 + 1 | 0) < this.ci9_1.si8_1 ? i1 + 1 | 0 : 0;
      ie0.ei8_1.via(this.ci9_1.qi8_1[i1]);
      ie0.fi8_1.gi8_1 = 0;
      ie0.fi8_1.hi8_1 = toByte(i1);
      ie0.fi8_1.ii8_1 = toByte(Type_FACE_getInstance().v3_1);
      ie0.fi8_1.ji8_1 = toByte(Type_VERTEX_getInstance().v3_1);
      ie1.ei8_1.via(this.ci9_1.qi8_1[i2]);
      ie1.fi8_1.gi8_1 = 0;
      ie1.fi8_1.hi8_1 = toByte(i2);
      ie1.fi8_1.ii8_1 = toByte(Type_FACE_getInstance().v3_1);
      ie1.fi8_1.ji8_1 = toByte(Type_VERTEX_getInstance().v3_1);
      if (this.si9_1) {
        this.aia_1.ti8_1 = 0;
        this.aia_1.ui8_1 = 1;
        this.aia_1.vi8_1.via(this.gi9_1);
        this.aia_1.wi8_1.via(this.hi9_1);
        this.aia_1.xi8_1.via(this.ki9_1);
      } else {
        this.aia_1.ti8_1 = 1;
        this.aia_1.ui8_1 = 0;
        this.aia_1.vi8_1.via(this.hi9_1);
        this.aia_1.wi8_1.via(this.gi9_1);
        this.aia_1.xi8_1.via(this.ki9_1).jib();
      }
    } else {
      manifold.fib_1 = ManifoldType_FACE_B_getInstance();
      ie0.ei8_1.via(this.gi9_1);
      ie0.fi8_1.gi8_1 = 0;
      ie0.fi8_1.hi8_1 = toByte(primaryAxis.ni8_1);
      ie0.fi8_1.ii8_1 = toByte(Type_VERTEX_getInstance().v3_1);
      ie0.fi8_1.ji8_1 = toByte(Type_FACE_getInstance().v3_1);
      ie1.ei8_1.via(this.hi9_1);
      ie1.fi8_1.gi8_1 = 0;
      ie1.fi8_1.hi8_1 = toByte(primaryAxis.ni8_1);
      ie1.fi8_1.ii8_1 = toByte(Type_VERTEX_getInstance().v3_1);
      ie1.fi8_1.ji8_1 = toByte(Type_FACE_getInstance().v3_1);
      this.aia_1.ti8_1 = primaryAxis.ni8_1;
      this.aia_1.ui8_1 = (this.aia_1.ti8_1 + 1 | 0) < this.ci9_1.si8_1 ? this.aia_1.ti8_1 + 1 | 0 : 0;
      this.aia_1.vi8_1.via(this.ci9_1.qi8_1[this.aia_1.ti8_1]);
      this.aia_1.wi8_1.via(this.ci9_1.qi8_1[this.aia_1.ui8_1]);
      this.aia_1.xi8_1.via(this.ci9_1.ri8_1[this.aia_1.ti8_1]);
    }
    this.aia_1.yi8_1.ci4(this.aia_1.xi8_1.bi4_1, -this.aia_1.xi8_1.ai4_1);
    this.aia_1.ai9_1.via(this.aia_1.yi8_1).jib();
    this.aia_1.zi8_1 = Companion_instance_12.yia(this.aia_1.yi8_1, this.aia_1.vi8_1);
    this.aia_1.bi9_1 = Companion_instance_12.yia(this.aia_1.ai9_1, this.aia_1.wi8_1);
    var np;
    np = Companion_instance_0.lib(this.yi9_1, this.xi9_1, this.aia_1.yi8_1, this.aia_1.zi8_1, this.aia_1.ti8_1);
    if (np < Settings_getInstance().ui7()) {
      return Unit_instance;
    }
    np = Companion_instance_0.lib(this.zi9_1, this.yi9_1, this.aia_1.ai9_1, this.aia_1.bi9_1, this.aia_1.ui8_1);
    if (np < Settings_getInstance().ui7()) {
      return Unit_instance;
    }
    if (equals(primaryAxis.mi8_1, Type_EDGE_A_getInstance())) {
      manifold.dib_1.via(this.aia_1.xi8_1);
      manifold.eib_1.via(this.aia_1.vi8_1);
    } else {
      manifold.dib_1.via(polygonB.ci6_1[this.aia_1.ti8_1]);
      manifold.eib_1.via(polygonB.bi6_1[this.aia_1.ti8_1]);
    }
    var pointCount = 0;
    var inductionVariable_1 = 0;
    var last_1 = Settings_getInstance().ui7();
    if (inductionVariable_1 < last_1)
      do {
        var i_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var separation;
        separation = Companion_instance_12.yia(this.aia_1.xi8_1, this.ui9_1.via(this.zi9_1[i_1].ei8_1).wia(this.aia_1.vi8_1));
        if (separation <= this.ri9_1) {
          var cp = manifold.cib_1[pointCount];
          if (equals(primaryAxis.mi8_1, Type_EDGE_A_getInstance())) {
            Companion_instance_11.qib(this.di9_1, this.zi9_1[i_1].ei8_1, cp.mib_1);
            cp.pib_1.rib(this.zi9_1[i_1].fi8_1);
          } else {
            cp.mib_1.via(this.zi9_1[i_1].ei8_1);
            cp.pib_1.ii8_1 = this.zi9_1[i_1].fi8_1.ji8_1;
            cp.pib_1.ji8_1 = this.zi9_1[i_1].fi8_1.ii8_1;
            cp.pib_1.gi8_1 = this.zi9_1[i_1].fi8_1.hi8_1;
            cp.pib_1.hi8_1 = this.zi9_1[i_1].fi8_1.gi8_1;
          }
          pointCount = pointCount + 1 | 0;
        }
      }
       while (inductionVariable_1 < last_1);
    manifold.gib_1 = pointCount;
  }
  hib(axis) {
    axis.mi8_1 = Type_EDGE_A_getInstance();
    axis.ni8_1 = this.si9_1 ? 0 : 1;
    axis.oi8_1 = 3.4028235E38;
    var nx = this.mi9_1.ai4_1;
    var ny = this.mi9_1.bi4_1;
    var inductionVariable = 0;
    var last = this.ci9_1.si8_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = this.ci9_1.qi8_1[i];
        var tempx = v.ai4_1 - this.gi9_1.ai4_1;
        var tempy = v.bi4_1 - this.gi9_1.bi4_1;
        var s = nx * tempx + ny * tempy;
        if (s < axis.oi8_1) {
          axis.oi8_1 = s;
        }
      }
       while (inductionVariable < last);
  }
  iib(axis) {
    axis.mi8_1 = Type_UNKNOWN_getInstance();
    axis.ni8_1 = -1;
    axis.oi8_1 = -3.4028235E38;
    this.dia_1.ai4_1 = -this.mi9_1.bi4_1;
    this.dia_1.bi4_1 = this.mi9_1.ai4_1;
    var inductionVariable = 0;
    var last = this.ci9_1.si8_1;
    if (inductionVariable < last)
      $l$loop_0: do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var normalB = this.ci9_1.ri8_1[i];
        var vB = this.ci9_1.qi8_1[i];
        this.eia_1.ai4_1 = -normalB.ai4_1;
        this.eia_1.bi4_1 = -normalB.bi4_1;
        var tempx = vB.ai4_1 - this.gi9_1.ai4_1;
        var tempy = vB.bi4_1 - this.gi9_1.bi4_1;
        var s1 = this.eia_1.ai4_1 * tempx + this.eia_1.bi4_1 * tempy;
        tempx = vB.ai4_1 - this.hi9_1.ai4_1;
        tempy = vB.bi4_1 - this.hi9_1.bi4_1;
        var s2 = this.eia_1.ai4_1 * tempx + this.eia_1.bi4_1 * tempy;
        var s = Companion_getInstance_11().bic(s1, s2);
        if (s > this.ri9_1) {
          axis.mi8_1 = Type_EDGE_B_getInstance();
          axis.ni8_1 = i;
          axis.oi8_1 = s;
          return Unit_instance;
        }
        if (this.eia_1.ai4_1 * this.dia_1.ai4_1 + this.eia_1.bi4_1 * this.dia_1.bi4_1 >= 0.0) {
          if (Companion_instance_12.yia(this.ui9_1.via(this.eia_1).wia(this.qi9_1), this.mi9_1) < -Settings_getInstance().cic()) {
            continue $l$loop_0;
          }
        } else {
          if (Companion_instance_12.yia(this.ui9_1.via(this.eia_1).wia(this.pi9_1), this.mi9_1) < -Settings_getInstance().cic()) {
            continue $l$loop_0;
          }
        }
        if (s > axis.oi8_1) {
          axis.mi8_1 = Type_EDGE_B_getInstance();
          axis.ni8_1 = i;
          axis.oi8_1 = s;
        }
      }
       while (inductionVariable < last);
  }
}
class Companion_0 {
  constructor() {
    this.kib_1 = 2147483647;
  }
  lib(vOut, vIn, normal, offset, vertexIndexA) {
    var numOut = 0;
    var vIn0 = vIn[0];
    var vIn1 = vIn[1];
    var vIn0v = vIn0.ei8_1;
    var vIn1v = vIn1.ei8_1;
    var distance0 = Companion_instance_12.yia(normal, vIn0v) - offset;
    var distance1 = Companion_instance_12.yia(normal, vIn1v) - offset;
    if (distance0 <= 0.0) {
      var _unary__edvuaz = numOut;
      numOut = _unary__edvuaz + 1 | 0;
      vOut[_unary__edvuaz].li8(vIn0);
    }
    if (distance1 <= 0.0) {
      var _unary__edvuaz_0 = numOut;
      numOut = _unary__edvuaz_0 + 1 | 0;
      vOut[_unary__edvuaz_0].li8(vIn1);
    }
    if (distance0 * distance1 < 0.0) {
      var interp = distance0 / (distance0 - distance1);
      var vOutNO = vOut[numOut];
      vOutNO.ei8_1.ai4_1 = vIn0v.ai4_1 + interp * (vIn1v.ai4_1 - vIn0v.ai4_1);
      vOutNO.ei8_1.bi4_1 = vIn0v.bi4_1 + interp * (vIn1v.bi4_1 - vIn0v.bi4_1);
      vOutNO.fi8_1.gi8_1 = toByte(vertexIndexA);
      vOutNO.fi8_1.hi8_1 = vIn0.fi8_1.hi8_1;
      vOutNO.fi8_1.ii8_1 = toByte(Type_VERTEX_getInstance().v3_1);
      vOutNO.fi8_1.ji8_1 = toByte(Type_FACE_getInstance().v3_1);
      numOut = numOut + 1 | 0;
    }
    return numOut;
  }
}
class Collision {
  constructor(pool) {
    this.dic_1 = pool;
    this.eic_1 = new DistanceInput();
    this.fic_1 = new SimplexCache();
    this.gic_1 = new DistanceOutput();
    this.hic_1 = new Vec2();
    this.iic_1 = Transform.iia();
    this.jic_1 = new Vec2();
    this.kic_1 = new Vec2();
    this.lic_1 = new EdgeResults();
    this.mic_1 = new EdgeResults();
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(2), null);
    while (tmp_0 < 2) {
      tmp_1[tmp_0] = new ClipVertex();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.nic_1 = tmp_1;
    this.oic_1 = new Vec2();
    this.pic_1 = new Vec2();
    this.qic_1 = new Vec2();
    this.ric_1 = new Vec2();
    this.sic_1 = new Vec2();
    this.tic_1 = new Vec2();
    var tmp_2 = this;
    var tmp_3 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_4 = fillArrayVal(Array(2), null);
    while (tmp_3 < 2) {
      tmp_4[tmp_3] = new ClipVertex();
      tmp_3 = tmp_3 + 1 | 0;
    }
    tmp_2.uic_1 = tmp_4;
    var tmp_5 = this;
    var tmp_6 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_7 = fillArrayVal(Array(2), null);
    while (tmp_6 < 2) {
      tmp_7[tmp_6] = new ClipVertex();
      tmp_6 = tmp_6 + 1 | 0;
    }
    tmp_5.vic_1 = tmp_7;
    this.wic_1 = new Vec2();
    this.xic_1 = new Vec2();
    this.yic_1 = ContactID.ki8();
    this.zic_1 = new Vec2();
    this.aid_1 = new Vec2();
    this.bid_1 = new EPCollider();
    this.cid_1 = new Vec2();
    this.did_1 = new Vec2();
  }
  eid(shapeA, indexA, shapeB, indexB, xfA, xfB) {
    this.eic_1.fid_1.oid(shapeA, indexA);
    this.eic_1.gid_1.oid(shapeB, indexB);
    this.eic_1.hid_1.pid(xfA);
    this.eic_1.iid_1.pid(xfB);
    this.eic_1.jid_1 = true;
    this.fic_1.rid_1 = 0;
    this.dic_1.uid().die(this.gic_1, this.fic_1, this.eic_1);
    return this.gic_1.gie_1 < 10.0 * Settings_getInstance().ji7_1;
  }
  iie(manifold, circle1, xfA, circle2, xfB) {
    manifold.gib_1 = 0;
    var circle1p = circle1.lie_1;
    var circle2p = circle2.lie_1;
    var pAx = xfA.hia_1.nie_1 * circle1p.ai4_1 - xfA.hia_1.mie_1 * circle1p.bi4_1 + xfA.gia_1.ai4_1;
    var pAy = xfA.hia_1.mie_1 * circle1p.ai4_1 + xfA.hia_1.nie_1 * circle1p.bi4_1 + xfA.gia_1.bi4_1;
    var pBx = xfB.hia_1.nie_1 * circle2p.ai4_1 - xfB.hia_1.mie_1 * circle2p.bi4_1 + xfB.gia_1.ai4_1;
    var pBy = xfB.hia_1.mie_1 * circle2p.ai4_1 + xfB.hia_1.nie_1 * circle2p.bi4_1 + xfB.gia_1.bi4_1;
    var dx = pBx - pAx;
    var dy = pBy - pAy;
    var distSqr = dx * dx + dy * dy;
    var radius = circle1.pie_1 + circle2.pie_1;
    if (distSqr > radius * radius) {
      return Unit_instance;
    }
    manifold.fib_1 = ManifoldType_CIRCLES_getInstance();
    manifold.eib_1.via(circle1p);
    manifold.dib_1.ki6();
    manifold.gib_1 = 1;
    manifold.cib_1[0].mib_1.via(circle2p);
    manifold.cib_1[0].pib_1.qie();
  }
  rie(manifold, polygon, xfA, circle, xfB) {
    manifold.gib_1 = 0;
    var circlep = circle.lie_1;
    var xfBq = xfB.hia_1;
    var xfAq = xfA.hia_1;
    var cx = xfBq.nie_1 * circlep.ai4_1 - xfBq.mie_1 * circlep.bi4_1 + xfB.gia_1.ai4_1;
    var cy = xfBq.mie_1 * circlep.ai4_1 + xfBq.nie_1 * circlep.bi4_1 + xfB.gia_1.bi4_1;
    var px = cx - xfA.gia_1.ai4_1;
    var py = cy - xfA.gia_1.bi4_1;
    var cLocalx = xfAq.nie_1 * px + xfAq.mie_1 * py;
    var cLocaly = -xfAq.mie_1 * px + xfAq.nie_1 * py;
    var normalIndex = 0;
    var separation = -3.4028235E38;
    var radius = polygon.pie_1 + circle.pie_1;
    var vertexCount = polygon.di6_1;
    var s;
    var vertices = polygon.bi6_1;
    var normals = polygon.ci6_1;
    var inductionVariable = 0;
    if (inductionVariable < vertexCount)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var vertex = vertices[i];
        var tempx = cLocalx - vertex.ai4_1;
        var tempy = cLocaly - vertex.bi4_1;
        s = normals[i].ai4_1 * tempx + normals[i].bi4_1 * tempy;
        if (s > radius) {
          return Unit_instance;
        }
        if (s > separation) {
          separation = s;
          normalIndex = i;
        }
      }
       while (inductionVariable < vertexCount);
    var vertIndex1 = normalIndex;
    var vertIndex2 = (vertIndex1 + 1 | 0) < vertexCount ? vertIndex1 + 1 | 0 : 0;
    var v1 = vertices[vertIndex1];
    var v2 = vertices[vertIndex2];
    if (separation < Settings_getInstance().ji7_1) {
      manifold.gib_1 = 1;
      manifold.fib_1 = ManifoldType_FACE_A_getInstance();
      var normal = normals[normalIndex];
      manifold.dib_1.ai4_1 = normal.ai4_1;
      manifold.dib_1.bi4_1 = normal.bi4_1;
      manifold.eib_1.ai4_1 = (v1.ai4_1 + v2.ai4_1) * 0.5;
      manifold.eib_1.bi4_1 = (v1.bi4_1 + v2.bi4_1) * 0.5;
      var mpoint = manifold.cib_1[0];
      mpoint.mib_1.ai4_1 = circlep.ai4_1;
      mpoint.mib_1.bi4_1 = circlep.bi4_1;
      mpoint.pib_1.qie();
      return Unit_instance;
    }
    var tempX = cLocalx - v1.ai4_1;
    var tempY = cLocaly - v1.bi4_1;
    var temp2X = v2.ai4_1 - v1.ai4_1;
    var temp2Y = v2.bi4_1 - v1.bi4_1;
    var u1 = tempX * temp2X + tempY * temp2Y;
    var temp3X = cLocalx - v2.ai4_1;
    var temp3Y = cLocaly - v2.bi4_1;
    var temp4X = v1.ai4_1 - v2.ai4_1;
    var temp4Y = v1.bi4_1 - v2.bi4_1;
    var u2 = temp3X * temp4X + temp3Y * temp4Y;
    if (u1 <= 0.0) {
      var dx = cLocalx - v1.ai4_1;
      var dy = cLocaly - v1.bi4_1;
      if (dx * dx + dy * dy > radius * radius) {
        return Unit_instance;
      }
      manifold.gib_1 = 1;
      manifold.fib_1 = ManifoldType_FACE_A_getInstance();
      manifold.dib_1.ai4_1 = cLocalx - v1.ai4_1;
      manifold.dib_1.bi4_1 = cLocaly - v1.bi4_1;
      manifold.dib_1.xia();
      manifold.eib_1.via(v1);
      manifold.cib_1[0].mib_1.via(circlep);
      manifold.cib_1[0].pib_1.qie();
    } else if (u2 <= 0.0) {
      var dx_0 = cLocalx - v2.ai4_1;
      var dy_0 = cLocaly - v2.bi4_1;
      if (dx_0 * dx_0 + dy_0 * dy_0 > radius * radius) {
        return Unit_instance;
      }
      manifold.gib_1 = 1;
      manifold.fib_1 = ManifoldType_FACE_A_getInstance();
      manifold.dib_1.ai4_1 = cLocalx - v2.ai4_1;
      manifold.dib_1.bi4_1 = cLocaly - v2.bi4_1;
      manifold.dib_1.xia();
      manifold.eib_1.via(v2);
      manifold.cib_1[0].mib_1.via(circlep);
      manifold.cib_1[0].pib_1.qie();
    } else {
      var fcx = (v1.ai4_1 + v2.ai4_1) * 0.5;
      var fcy = (v1.bi4_1 + v2.bi4_1) * 0.5;
      var tx = cLocalx - fcx;
      var ty = cLocaly - fcy;
      var normal_0 = normals[vertIndex1];
      separation = tx * normal_0.ai4_1 + ty * normal_0.bi4_1;
      if (separation > radius) {
        return Unit_instance;
      }
      manifold.gib_1 = 1;
      manifold.fib_1 = ManifoldType_FACE_A_getInstance();
      manifold.dib_1.via(normals[vertIndex1]);
      manifold.eib_1.ai4_1 = fcx;
      manifold.eib_1.bi4_1 = fcy;
      manifold.cib_1[0].mib_1.via(circlep);
      manifold.cib_1[0].pib_1.qie();
    }
  }
  sie(results, poly1, xf1, poly2, xf2) {
    var count1 = poly1.di6_1;
    var count2 = poly2.di6_1;
    var n1s = poly1.ci6_1;
    var v1s = poly1.bi6_1;
    var v2s = poly2.bi6_1;
    Companion_instance_11.kia(xf2, xf1, this.iic_1, this.cid_1);
    var xfq = this.iic_1.hia_1;
    var bestIndex = 0;
    var maxSeparation = -3.4028235E38;
    var inductionVariable = 0;
    if (inductionVariable < count1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        Companion_instance_10.aib(xfq, n1s[i], this.jic_1);
        Companion_instance_11.lia(this.iic_1, v1s[i], this.kic_1);
        var si = 3.4028235E38;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < count2)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var v2sj = v2s[j];
            var sij = this.jic_1.ai4_1 * (v2sj.ai4_1 - this.kic_1.ai4_1) + this.jic_1.bi4_1 * (v2sj.bi4_1 - this.kic_1.bi4_1);
            if (sij < si) {
              si = sij;
            }
          }
           while (inductionVariable_0 < count2);
        if (si > maxSeparation) {
          maxSeparation = si;
          bestIndex = i;
        }
      }
       while (inductionVariable < count1);
    results.di8_1 = bestIndex;
    results.ci8_1 = maxSeparation;
  }
  tie(c, poly1, xf1, edge1, poly2, xf2) {
    var count1 = poly1.di6_1;
    var normals1 = poly1.ci6_1;
    var count2 = poly2.di6_1;
    var vertices2 = poly2.bi6_1;
    var normals2 = poly2.ci6_1;
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0 <= edge1 && edge1 < count1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var c0 = c[0];
    var c1 = c[1];
    var xf1q = xf1.hia_1;
    var xf2q = xf2.hia_1;
    var v = normals1[edge1];
    var tempx = xf1q.nie_1 * v.ai4_1 - xf1q.mie_1 * v.bi4_1;
    var tempy = xf1q.mie_1 * v.ai4_1 + xf1q.nie_1 * v.bi4_1;
    var normal1x = xf2q.nie_1 * tempx + xf2q.mie_1 * tempy;
    var normal1y = -xf2q.mie_1 * tempx + xf2q.nie_1 * tempy;
    var index = 0;
    var minDot = 3.4028235E38;
    var inductionVariable = 0;
    if (inductionVariable < count2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var b = normals2[i];
        var dot = normal1x * b.ai4_1 + normal1y * b.bi4_1;
        if (dot < minDot) {
          minDot = dot;
          index = i;
        }
      }
       while (inductionVariable < count2);
    var i1 = index;
    var i2 = (i1 + 1 | 0) < count2 ? i1 + 1 | 0 : 0;
    var v1 = vertices2[i1];
    var out = c0.ei8_1;
    out.ai4_1 = xf2q.nie_1 * v1.ai4_1 - xf2q.mie_1 * v1.bi4_1 + xf2.gia_1.ai4_1;
    out.bi4_1 = xf2q.mie_1 * v1.ai4_1 + xf2q.nie_1 * v1.bi4_1 + xf2.gia_1.bi4_1;
    c0.fi8_1.gi8_1 = toByte(edge1);
    c0.fi8_1.hi8_1 = toByte(i1);
    c0.fi8_1.ii8_1 = toByte(Type_FACE_getInstance().v3_1);
    c0.fi8_1.ji8_1 = toByte(Type_VERTEX_getInstance().v3_1);
    var v2 = vertices2[i2];
    var out1 = c1.ei8_1;
    out1.ai4_1 = xf2q.nie_1 * v2.ai4_1 - xf2q.mie_1 * v2.bi4_1 + xf2.gia_1.ai4_1;
    out1.bi4_1 = xf2q.mie_1 * v2.ai4_1 + xf2q.nie_1 * v2.bi4_1 + xf2.gia_1.bi4_1;
    c1.fi8_1.gi8_1 = toByte(edge1);
    c1.fi8_1.hi8_1 = toByte(i2);
    c1.fi8_1.ii8_1 = toByte(Type_FACE_getInstance().v3_1);
    c1.fi8_1.ji8_1 = toByte(Type_VERTEX_getInstance().v3_1);
  }
  uie(manifold, polyA, xfA, polyB, xfB) {
    manifold.gib_1 = 0;
    var totalRadius = polyA.pie_1 + polyB.pie_1;
    this.sie(this.lic_1, polyA, xfA, polyB, xfB);
    if (this.lic_1.ci8_1 > totalRadius) {
      return Unit_instance;
    }
    this.sie(this.mic_1, polyB, xfB, polyA, xfA);
    if (this.mic_1.ci8_1 > totalRadius) {
      return Unit_instance;
    }
    var poly1;
    var poly2;
    var xf1;
    var xf2;
    var edge1;
    var flip;
    var k_tol = 0.1 * Settings_getInstance().vie();
    if (this.mic_1.ci8_1 > this.lic_1.ci8_1 + k_tol) {
      poly1 = polyB;
      poly2 = polyA;
      xf1 = xfB;
      xf2 = xfA;
      edge1 = this.mic_1.di8_1;
      manifold.fib_1 = ManifoldType_FACE_B_getInstance();
      flip = true;
    } else {
      poly1 = polyA;
      poly2 = polyB;
      xf1 = xfA;
      xf2 = xfB;
      edge1 = this.lic_1.di8_1;
      manifold.fib_1 = ManifoldType_FACE_A_getInstance();
      flip = false;
    }
    var xf1q = xf1.hia_1;
    this.tie(this.nic_1, poly1, xf1, edge1, poly2, xf2);
    var count1 = poly1.di6_1;
    var vertices1 = poly1.bi6_1;
    var iv1 = edge1;
    var iv2 = (edge1 + 1 | 0) < count1 ? edge1 + 1 | 0 : 0;
    this.sic_1.via(vertices1[iv1]);
    this.tic_1.via(vertices1[iv2]);
    this.oic_1.ai4_1 = this.tic_1.ai4_1 - this.sic_1.ai4_1;
    this.oic_1.bi4_1 = this.tic_1.bi4_1 - this.sic_1.bi4_1;
    this.oic_1.xia();
    this.pic_1.ai4_1 = 1.0 * this.oic_1.bi4_1;
    this.pic_1.bi4_1 = -1.0 * this.oic_1.ai4_1;
    this.qic_1.ai4_1 = (this.sic_1.ai4_1 + this.tic_1.ai4_1) * 0.5;
    this.qic_1.bi4_1 = (this.sic_1.bi4_1 + this.tic_1.bi4_1) * 0.5;
    this.ric_1.ai4_1 = xf1q.nie_1 * this.oic_1.ai4_1 - xf1q.mie_1 * this.oic_1.bi4_1;
    this.ric_1.bi4_1 = xf1q.mie_1 * this.oic_1.ai4_1 + xf1q.nie_1 * this.oic_1.bi4_1;
    var normalx = 1.0 * this.ric_1.bi4_1;
    var normaly = -1.0 * this.ric_1.ai4_1;
    Companion_instance_11.wie(xf1, this.sic_1, this.sic_1);
    Companion_instance_11.wie(xf1, this.tic_1, this.tic_1);
    var frontOffset = normalx * this.sic_1.ai4_1 + normaly * this.sic_1.bi4_1;
    var sideOffset1 = -(this.ric_1.ai4_1 * this.sic_1.ai4_1 + this.ric_1.bi4_1 * this.sic_1.bi4_1) + totalRadius;
    var sideOffset2 = this.ric_1.ai4_1 * this.tic_1.ai4_1 + this.ric_1.bi4_1 * this.tic_1.bi4_1 + totalRadius;
    var np;
    this.ric_1.jib();
    np = Companion_instance_0.lib(this.uic_1, this.nic_1, this.ric_1, sideOffset1, iv1);
    this.ric_1.jib();
    if (np < 2) {
      return Unit_instance;
    }
    np = Companion_instance_0.lib(this.vic_1, this.uic_1, this.ric_1, sideOffset2, iv2);
    if (np < 2) {
      return Unit_instance;
    }
    manifold.dib_1.via(this.pic_1);
    manifold.eib_1.via(this.qic_1);
    var pointCount = 0;
    var inductionVariable = 0;
    var last = Settings_getInstance().ui7();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var separation = normalx * this.vic_1[i].ei8_1.ai4_1 + normaly * this.vic_1[i].ei8_1.bi4_1 - frontOffset;
        if (separation <= totalRadius) {
          var cp = manifold.cib_1[pointCount];
          var out = cp.mib_1;
          var px = this.vic_1[i].ei8_1.ai4_1 - xf2.gia_1.ai4_1;
          var py = this.vic_1[i].ei8_1.bi4_1 - xf2.gia_1.bi4_1;
          out.ai4_1 = xf2.hia_1.nie_1 * px + xf2.hia_1.mie_1 * py;
          out.bi4_1 = -xf2.hia_1.mie_1 * px + xf2.hia_1.nie_1 * py;
          cp.pib_1.rib(this.vic_1[i].fi8_1);
          if (flip) {
            cp.pib_1.xie();
          }
          pointCount = pointCount + 1 | 0;
        }
      }
       while (inductionVariable < last);
    manifold.gib_1 = pointCount;
  }
  yie(manifold, edgeA, xfA, circleB, xfB) {
    manifold.gib_1 = 0;
    Companion_instance_11.lia(xfB, circleB.lie_1, this.hic_1);
    Companion_instance_11.qib(xfA, this.hic_1, this.wic_1);
    var A = edgeA.oia_1;
    var B = edgeA.pia_1;
    this.xic_1.via(B).wia(A);
    var u = Companion_instance_12.yia(this.xic_1, this.hic_1.via(B).wia(this.wic_1));
    var v = Companion_instance_12.yia(this.xic_1, this.hic_1.via(this.wic_1).wia(A));
    var radius = edgeA.pie_1 + circleB.pie_1;
    this.yic_1.hi8_1 = 0;
    this.yic_1.ji8_1 = toByte(Type_VERTEX_getInstance().v3_1);
    if (v <= 0.0) {
      var P = A;
      this.did_1.via(this.wic_1).wia(P);
      var dd = Companion_instance_12.yia(this.did_1, this.did_1);
      if (dd > radius * radius) {
        return Unit_instance;
      }
      if (edgeA.sia_1) {
        var A1 = edgeA.qia_1;
        var B1 = A;
        this.zic_1.via(B1).wia(A1);
        var u1 = Companion_instance_12.yia(this.zic_1, this.hic_1.via(B1).wia(this.wic_1));
        if (u1 > 0.0) {
          return Unit_instance;
        }
      }
      this.yic_1.gi8_1 = 0;
      this.yic_1.ii8_1 = toByte(Type_VERTEX_getInstance().v3_1);
      manifold.gib_1 = 1;
      manifold.fib_1 = ManifoldType_CIRCLES_getInstance();
      manifold.dib_1.ki6();
      manifold.eib_1.via(P);
      manifold.cib_1[0].pib_1.rib(this.yic_1);
      manifold.cib_1[0].mib_1.via(circleB.lie_1);
      return Unit_instance;
    }
    if (u <= 0.0) {
      var P_0 = B;
      this.did_1.via(this.wic_1).wia(P_0);
      var dd_0 = Companion_instance_12.yia(this.did_1, this.did_1);
      if (dd_0 > radius * radius) {
        return Unit_instance;
      }
      if (edgeA.tia_1) {
        var B2 = edgeA.ria_1;
        var A2 = B;
        var e2 = this.zic_1;
        e2.via(B2).wia(A2);
        var v2 = Companion_instance_12.yia(e2, this.hic_1.via(this.wic_1).wia(A2));
        if (v2 > 0.0) {
          return Unit_instance;
        }
      }
      this.yic_1.gi8_1 = 1;
      this.yic_1.ii8_1 = toByte(Type_VERTEX_getInstance().v3_1);
      manifold.gib_1 = 1;
      manifold.fib_1 = ManifoldType_CIRCLES_getInstance();
      manifold.dib_1.ki6();
      manifold.eib_1.via(P_0);
      manifold.cib_1[0].pib_1.rib(this.yic_1);
      manifold.cib_1[0].mib_1.via(circleB.lie_1);
      return Unit_instance;
    }
    var den = Companion_instance_12.yia(this.xic_1, this.xic_1);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(den > 0.0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    this.aid_1.via(A).zie(u).aif(this.hic_1.via(B).zie(v));
    this.aid_1.zie(1.0 / den);
    this.did_1.via(this.wic_1).wia(this.aid_1);
    var dd_1 = Companion_instance_12.yia(this.did_1, this.did_1);
    if (dd_1 > radius * radius) {
      return Unit_instance;
    }
    this.jic_1.ai4_1 = -this.xic_1.bi4_1;
    this.jic_1.bi4_1 = this.xic_1.ai4_1;
    if (Companion_instance_12.yia(this.jic_1, this.hic_1.via(this.wic_1).wia(A)) < 0.0) {
      this.jic_1.ci4(-this.jic_1.ai4_1, -this.jic_1.bi4_1);
    }
    this.jic_1.xia();
    this.yic_1.gi8_1 = 0;
    this.yic_1.ii8_1 = toByte(Type_FACE_getInstance().v3_1);
    manifold.gib_1 = 1;
    manifold.fib_1 = ManifoldType_FACE_A_getInstance();
    manifold.dib_1.via(this.jic_1);
    manifold.eib_1.via(A);
    manifold.cib_1[0].pib_1.rib(this.yic_1);
    manifold.cib_1[0].mib_1.via(circleB.lie_1);
  }
  bif(manifold, edgeA, xfA, polygonB, xfB) {
    this.bid_1.jia(manifold, edgeA, xfA, polygonB, xfB);
  }
}
class Type_0 extends Enum {}
class ContactID {
  a2() {
    return this.gi8_1 << 24 | this.hi8_1 << 16 | this.ii8_1 << 8 | this.ji8_1;
  }
  cif(cid) {
    return this.a2() === cid.a2();
  }
  static ki8() {
    var $this = createThis(this);
    init_org_jbox2d_collision_ContactID($this);
    return $this;
  }
  rib(c) {
    this.gi8_1 = c.gi8_1;
    this.hi8_1 = c.hi8_1;
    this.ii8_1 = c.ii8_1;
    this.ji8_1 = c.ji8_1;
  }
  xie() {
    var tempA = this.gi8_1;
    this.gi8_1 = this.hi8_1;
    this.hi8_1 = tempA;
    tempA = this.ii8_1;
    this.ii8_1 = this.ji8_1;
    this.ji8_1 = tempA;
  }
  qie() {
    this.gi8_1 = 0;
    this.hi8_1 = 0;
    this.ii8_1 = 0;
    this.ji8_1 = 0;
  }
  dif(o) {
    return this.a2() - o.a2() | 0;
  }
  d(other) {
    return this.dif(other instanceof ContactID ? other : THROW_CCE());
  }
}
class SimplexVertex {
  constructor($outer) {
    this.kif_1 = $outer;
    this.eif_1 = new Vec2();
    this.fif_1 = new Vec2();
    this.gif_1 = new Vec2();
    this.hif_1 = 0;
    this.iif_1 = 0;
    this.jif_1 = 0;
  }
  lif(sv) {
    this.eif_1.via(sv.eif_1);
    this.fif_1.via(sv.fif_1);
    this.gif_1.via(sv.gif_1);
    this.hif_1 = sv.hif_1;
    this.iif_1 = sv.iif_1;
    this.jif_1 = sv.jif_1;
  }
}
class SimplexCache {
  constructor() {
    this.qid_1 = 0;
    this.rid_1 = 0;
    this.sid_1 = new Int32Array(3);
    this.tid_1 = new Int32Array(3);
    this.qid_1 = 0.0;
    this.rid_1 = 0;
    this.sid_1[0] = 2147483647;
    this.sid_1[1] = 2147483647;
    this.sid_1[2] = 2147483647;
    this.tid_1[0] = 2147483647;
    this.tid_1[1] = 2147483647;
    this.tid_1[2] = 2147483647;
  }
}
class Simplex {
  constructor($outer) {
    this.big_1 = $outer;
    this.mif_1 = new SimplexVertex(this.big_1);
    this.nif_1 = new SimplexVertex(this.big_1);
    this.oif_1 = new SimplexVertex(this.big_1);
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.pif_1 = [this.mif_1, this.nif_1, this.oif_1];
    this.qif_1 = 0;
    this.rif_1 = new Vec2();
    this.sif_1 = new Vec2();
    this.tif_1 = new Vec2();
    this.uif_1 = new Vec2();
    this.vif_1 = new Vec2();
    this.wif_1 = new Vec2();
    this.xif_1 = new Vec2();
    this.yif_1 = new Vec2();
    this.zif_1 = new Vec2();
    this.aig_1 = new Vec2();
  }
  cig() {
    switch (this.qif_1) {
      case 0:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }

        return 0.0;
      case 1:
        return 0.0;
      case 2:
        return Companion_getInstance_11().dig(this.mif_1.gif_1, this.nif_1.gif_1);
      case 3:
        this.uif_1.via(this.nif_1.gif_1).wia(this.mif_1.gif_1);
        this.vif_1.via(this.oif_1.gif_1).wia(this.mif_1.gif_1);
        return Companion_instance_12.zia(this.uif_1, this.vif_1);
      default:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp_0;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_0 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_0));
        }

        return 0.0;
    }
  }
  eig(cache, proxyA, transformA, proxyB, transformB) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(cache.rid_1 <= 3)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    this.qif_1 = cache.rid_1;
    var inductionVariable = 0;
    var last = this.qif_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = this.pif_1[i];
        v.iif_1 = cache.sid_1[i];
        v.jif_1 = cache.tid_1[i];
        var wALocal = proxyA.fig(v.iif_1);
        var wBLocal = proxyB.fig(v.jif_1);
        Companion_instance_11.lia(transformA, wALocal, v.eif_1);
        Companion_instance_11.lia(transformB, wBLocal, v.fif_1);
        v.gif_1.via(v.fif_1).wia(v.eif_1);
        v.hif_1 = 0.0;
      }
       while (inductionVariable < last);
    if (this.qif_1 > 1) {
      var metric1 = cache.qid_1;
      var metric2 = this.cig();
      if (metric2 < 0.5 * metric1 || 2.0 * metric1 < metric2 || metric2 < Settings_getInstance().ji7_1) {
        this.qif_1 = 0;
      }
    }
    if (this.qif_1 === 0) {
      var v_0 = this.pif_1[0];
      v_0.iif_1 = 0;
      v_0.jif_1 = 0;
      var wALocal_0 = proxyA.fig(0);
      var wBLocal_0 = proxyB.fig(0);
      Companion_instance_11.lia(transformA, wALocal_0, v_0.eif_1);
      Companion_instance_11.lia(transformB, wBLocal_0, v_0.fif_1);
      v_0.gif_1.via(v_0.fif_1).wia(v_0.eif_1);
      this.qif_1 = 1;
    }
  }
  gig(cache) {
    cache.qid_1 = this.cig();
    cache.rid_1 = this.qif_1;
    var inductionVariable = 0;
    var last = this.qif_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        cache.sid_1[i] = this.pif_1[i].iif_1;
        cache.tid_1[i] = this.pif_1[i].jif_1;
      }
       while (inductionVariable < last);
  }
  hig(out) {
    switch (this.qif_1) {
      case 1:
        out.via(this.mif_1.gif_1).jib();
        return Unit_instance;
      case 2:
        this.rif_1.via(this.nif_1.gif_1).wia(this.mif_1.gif_1);
        out.via(this.mif_1.gif_1).jib();
        var sgn = Companion_instance_12.zia(this.rif_1, out);
        if (sgn > 0.0) {
          Companion_instance_12.jig(1.0, this.rif_1, out);
          return Unit_instance;
        } else {
          Companion_instance_12.iig(this.rif_1, 1.0, out);
          return Unit_instance;
        }

      default:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }

        out.ki6();
        return Unit_instance;
    }
  }
  kig(out) {
    switch (this.qif_1) {
      case 0:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }

        out.ki6();
        return Unit_instance;
      case 1:
        out.via(this.mif_1.gif_1);
        return Unit_instance;
      case 2:
        this.tif_1.via(this.nif_1.gif_1).zie(this.nif_1.hif_1);
        this.sif_1.via(this.mif_1.gif_1).zie(this.mif_1.hif_1).aif(this.tif_1);
        out.via(this.sif_1);
        return Unit_instance;
      case 3:
        out.ki6();
        return Unit_instance;
      default:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp_0;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_0 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_0));
        }

        out.ki6();
        return Unit_instance;
    }
  }
  lig(pA, pB) {
    switch (this.qif_1) {
      case 0:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }

        break;
      case 1:
        pA.via(this.mif_1.eif_1);
        pB.via(this.mif_1.fif_1);
        break;
      case 2:
        this.sif_1.via(this.mif_1.eif_1).zie(this.mif_1.hif_1);
        pA.via(this.nif_1.eif_1).zie(this.nif_1.hif_1).aif(this.sif_1);
        this.sif_1.via(this.mif_1.fif_1).zie(this.mif_1.hif_1);
        pB.via(this.nif_1.fif_1).zie(this.nif_1.hif_1).aif(this.sif_1);
        break;
      case 3:
        pA.via(this.mif_1.eif_1).zie(this.mif_1.hif_1);
        this.uif_1.via(this.nif_1.eif_1).zie(this.nif_1.hif_1);
        this.vif_1.via(this.oif_1.eif_1).zie(this.oif_1.hif_1);
        pA.aif(this.uif_1).aif(this.vif_1);
        pB.via(pA);
        break;
      default:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp_0;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_0 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_0));
        }

        break;
    }
  }
  mig() {
    var w1 = this.mif_1.gif_1;
    var w2 = this.nif_1.gif_1;
    this.rif_1.via(w2).wia(w1);
    var d12_2 = -Companion_instance_12.yia(w1, this.rif_1);
    if (d12_2 <= 0.0) {
      this.mif_1.hif_1 = 1.0;
      this.qif_1 = 1;
      return Unit_instance;
    }
    var d12_1 = Companion_instance_12.yia(w2, this.rif_1);
    if (d12_1 <= 0.0) {
      this.nif_1.hif_1 = 1.0;
      this.qif_1 = 1;
      this.mif_1.lif(this.nif_1);
      return Unit_instance;
    }
    var inv_d12 = 1.0 / (d12_1 + d12_2);
    this.mif_1.hif_1 = d12_1 * inv_d12;
    this.nif_1.hif_1 = d12_2 * inv_d12;
    this.qif_1 = 2;
  }
  nig() {
    this.yif_1.via(this.mif_1.gif_1);
    this.zif_1.via(this.nif_1.gif_1);
    this.aig_1.via(this.oif_1.gif_1);
    this.rif_1.via(this.zif_1).wia(this.yif_1);
    var w1e12 = Companion_instance_12.yia(this.yif_1, this.rif_1);
    var w2e12 = Companion_instance_12.yia(this.zif_1, this.rif_1);
    var d12_1 = w2e12;
    var d12_2 = -w1e12;
    this.wif_1.via(this.aig_1).wia(this.yif_1);
    var w1e13 = Companion_instance_12.yia(this.yif_1, this.wif_1);
    var w3e13 = Companion_instance_12.yia(this.aig_1, this.wif_1);
    var d13_1 = w3e13;
    var d13_2 = -w1e13;
    this.xif_1.via(this.aig_1).wia(this.zif_1);
    var w2e23 = Companion_instance_12.yia(this.zif_1, this.xif_1);
    var w3e23 = Companion_instance_12.yia(this.aig_1, this.xif_1);
    var d23_1 = w3e23;
    var d23_2 = -w2e23;
    var n123 = Companion_instance_12.zia(this.rif_1, this.wif_1);
    var d123_1 = n123 * Companion_instance_12.zia(this.zif_1, this.aig_1);
    var d123_2 = n123 * Companion_instance_12.zia(this.aig_1, this.yif_1);
    var d123_3 = n123 * Companion_instance_12.zia(this.yif_1, this.zif_1);
    if (d12_2 <= 0.0 && d13_2 <= 0.0) {
      this.mif_1.hif_1 = 1.0;
      this.qif_1 = 1;
      return Unit_instance;
    }
    if (d12_1 > 0.0 && d12_2 > 0.0 && d123_3 <= 0.0) {
      var inv_d12 = 1.0 / (d12_1 + d12_2);
      this.mif_1.hif_1 = d12_1 * inv_d12;
      this.nif_1.hif_1 = d12_2 * inv_d12;
      this.qif_1 = 2;
      return Unit_instance;
    }
    if (d13_1 > 0.0 && d13_2 > 0.0 && d123_2 <= 0.0) {
      var inv_d13 = 1.0 / (d13_1 + d13_2);
      this.mif_1.hif_1 = d13_1 * inv_d13;
      this.oif_1.hif_1 = d13_2 * inv_d13;
      this.qif_1 = 2;
      this.nif_1.lif(this.oif_1);
      return Unit_instance;
    }
    if (d12_1 <= 0.0 && d23_2 <= 0.0) {
      this.nif_1.hif_1 = 1.0;
      this.qif_1 = 1;
      this.mif_1.lif(this.nif_1);
      return Unit_instance;
    }
    if (d13_1 <= 0.0 && d23_1 <= 0.0) {
      this.oif_1.hif_1 = 1.0;
      this.qif_1 = 1;
      this.mif_1.lif(this.oif_1);
      return Unit_instance;
    }
    if (d23_1 > 0.0 && d23_2 > 0.0 && d123_1 <= 0.0) {
      var inv_d23 = 1.0 / (d23_1 + d23_2);
      this.nif_1.hif_1 = d23_1 * inv_d23;
      this.oif_1.hif_1 = d23_2 * inv_d23;
      this.qif_1 = 2;
      this.mif_1.lif(this.oif_1);
      return Unit_instance;
    }
    var inv_d123 = 1.0 / (d123_1 + d123_2 + d123_3);
    this.mif_1.hif_1 = d123_1 * inv_d123;
    this.nif_1.hif_1 = d123_2 * inv_d123;
    this.oif_1.hif_1 = d123_3 * inv_d123;
    this.qif_1 = 3;
  }
}
class DistanceProxy {
  constructor() {
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().pi8();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = new Vec2();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.kid_1 = tmp_2;
    this.lid_1 = 0;
    this.mid_1 = 0.0;
    var tmp_3 = this;
    var tmp_4 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_5 = fillArrayVal(Array(2), null);
    while (tmp_4 < 2) {
      tmp_5[tmp_4] = new Vec2();
      tmp_4 = tmp_4 + 1 | 0;
    }
    tmp_3.nid_1 = tmp_5;
  }
  oid(shape, index) {
    switch (shape.oig().v3_1) {
      case 0:
        var circle = shape instanceof CircleShape ? shape : THROW_CCE();
        this.kid_1[0].via(circle.lie_1);
        this.lid_1 = 1;
        this.mid_1 = circle.pie_1;
        break;
      case 2:
        var poly = shape instanceof PolygonShape ? shape : THROW_CCE();
        this.lid_1 = poly.di6_1;
        this.mid_1 = poly.pie_1;
        var inductionVariable = 0;
        var last = this.lid_1;
        if (inductionVariable < last)
          do {
            var i = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            this.kid_1[i].via(poly.bi6_1[i]);
          }
           while (inductionVariable < last);
        break;
      case 3:
        var chain = shape instanceof ChainShape ? shape : THROW_CCE();
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp;
        if (!(0 <= index && index < chain.sig_1)) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }

        this.nid_1[0] = ensureNotNull(chain.rig_1)[index];
        if ((index + 1 | 0) < chain.sig_1) {
          this.nid_1[1] = ensureNotNull(chain.rig_1)[index + 1 | 0];
        } else {
          this.nid_1[1] = ensureNotNull(chain.rig_1)[0];
        }

        this.kid_1[0].via(this.nid_1[0]);
        this.kid_1[1].via(this.nid_1[1]);
        this.lid_1 = 2;
        this.mid_1 = chain.pie_1;
        break;
      case 1:
        var edge = shape instanceof EdgeShape ? shape : THROW_CCE();
        this.kid_1[0].via(edge.oia_1);
        this.kid_1[1].via(edge.pia_1);
        this.lid_1 = 2;
        this.mid_1 = edge.pie_1;
        break;
      default:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp_0;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_0 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_0));
        }

        break;
    }
  }
  xig(d) {
    var bestIndex = 0;
    var bestValue = Companion_instance_12.yia(this.kid_1[0], d);
    var inductionVariable = 1;
    var last = this.lid_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var value = Companion_instance_12.yia(this.kid_1[i], d);
        if (value > bestValue) {
          bestIndex = i;
          bestValue = value;
        }
      }
       while (inductionVariable < last);
    return bestIndex;
  }
  fig(index) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0 <= index && index < this.lid_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    return this.kid_1[index];
  }
}
class Companion_1 {
  constructor() {
    this.yig_1 = 20;
  }
}
class Stats {
  constructor() {
    this.zig_1 = 0;
    this.aih_1 = 0;
    this.bih_1 = 20;
  }
}
class Distance {
  constructor(stats) {
    stats = stats === VOID ? new Stats() : stats;
    this.vid_1 = stats;
    this.wid_1 = new Simplex(this);
    this.xid_1 = new Int32Array(3);
    this.yid_1 = new Int32Array(3);
    this.zid_1 = new Vec2();
    this.aie_1 = new Vec2();
    this.bie_1 = new Vec2();
    this.cie_1 = new Vec2();
  }
  die(output, cache, input) {
    var _receiver__tnumb7 = this.vid_1;
    _receiver__tnumb7.zig_1 = _receiver__tnumb7.zig_1 + 1 | 0;
    var proxyA = input.fid_1;
    var proxyB = input.gid_1;
    var transformA = input.hid_1;
    var transformB = input.iid_1;
    this.wid_1.eig(cache, proxyA, transformA, proxyB, transformB);
    var vertices = this.wid_1.pif_1;
    var saveCount = 0;
    this.wid_1.kig(this.zid_1);
    var distanceSqr1 = this.zid_1.cih();
    var distanceSqr2 = distanceSqr1;
    var iter = 0;
    $l$loop_2: while (iter < Companion_instance_1.yig_1) {
      saveCount = this.wid_1.qif_1;
      var inductionVariable = 0;
      var last = saveCount;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          this.xid_1[i] = vertices[i].iif_1;
          this.yid_1[i] = vertices[i].jif_1;
        }
         while (inductionVariable < last);
      switch (this.wid_1.qif_1) {
        case 1:
          break;
        case 2:
          this.wid_1.mig();
          break;
        case 3:
          this.wid_1.nig();
          break;
        default:
          // Inline function 'org.jbox2d.internal.assert' call

          // Inline function 'kotlin.contracts.contract' call

          // Inline function 'kotlin.contracts.contract' call

          var tmp;
          if (!false) {
            // Inline function 'kotlin.check.<anonymous>' call
            var message = 'Check failed.';
            throw IllegalStateException.m5(toString(message));
          }

          break;
      }
      if (this.wid_1.qif_1 === 3) {
        break $l$loop_2;
      }
      this.wid_1.kig(this.zid_1);
      distanceSqr2 = this.zid_1.cih();
      distanceSqr1 = distanceSqr2;
      this.wid_1.hig(this.aie_1);
      if (this.aie_1.cih() < Settings_getInstance().ji7_1 * Settings_getInstance().ji7_1) {
        break $l$loop_2;
      }
      var vertex = vertices[this.wid_1.qif_1];
      Companion_instance_10.dih(transformA.hia_1, this.aie_1.jib(), this.bie_1);
      vertex.iif_1 = proxyA.xig(this.bie_1);
      Companion_instance_11.lia(transformA, proxyA.fig(vertex.iif_1), vertex.eif_1);
      Companion_instance_10.dih(transformB.hia_1, this.aie_1.jib(), this.bie_1);
      vertex.jif_1 = proxyB.xig(this.bie_1);
      Companion_instance_11.lia(transformB, proxyB.fig(vertex.jif_1), vertex.fif_1);
      vertex.gif_1.via(vertex.fif_1).wia(vertex.eif_1);
      iter = iter + 1 | 0;
      var _receiver__tnumb7_0 = this.vid_1;
      _receiver__tnumb7_0.aih_1 = _receiver__tnumb7_0.aih_1 + 1 | 0;
      var duplicate = false;
      var inductionVariable_0 = 0;
      var last_0 = saveCount;
      if (inductionVariable_0 < last_0)
        $l$loop_1: do {
          var i_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (vertex.iif_1 === this.xid_1[i_0] && vertex.jif_1 === this.yid_1[i_0]) {
            duplicate = true;
            break $l$loop_1;
          }
        }
         while (inductionVariable_0 < last_0);
      if (duplicate) {
        break $l$loop_2;
      }
      var _receiver__tnumb7_1 = this.wid_1;
      _receiver__tnumb7_1.qif_1 = _receiver__tnumb7_1.qif_1 + 1 | 0;
    }
    this.vid_1.bih_1 = Companion_getInstance_11().eih(this.vid_1.bih_1, iter);
    this.wid_1.lig(output.eie_1, output.fie_1);
    output.gie_1 = Companion_getInstance_11().dig(output.eie_1, output.fie_1);
    output.hie_1 = iter;
    this.wid_1.gig(cache);
    if (input.jid_1) {
      var rA = proxyA.mid_1;
      var rB = proxyB.mid_1;
      if (output.gie_1 > rA + rB && output.gie_1 > Settings_getInstance().ji7_1) {
        output.gie_1 = output.gie_1 - (rA + rB);
        this.cie_1.via(output.fie_1).wia(output.eie_1);
        this.cie_1.xia();
        this.bie_1.via(this.cie_1).zie(rA);
        output.eie_1.aif(this.bie_1);
        this.bie_1.via(this.cie_1).zie(rB);
        output.fie_1.wia(this.bie_1);
      } else {
        output.eie_1.aif(output.fie_1).zie(0.5);
        output.fie_1.via(output.eie_1);
        output.gie_1 = 0.0;
      }
    }
  }
}
class DistanceInput {
  constructor() {
    this.fid_1 = new DistanceProxy();
    this.gid_1 = new DistanceProxy();
    this.hid_1 = Transform.iia();
    this.iid_1 = Transform.iia();
    this.jid_1 = false;
  }
}
class DistanceOutput {
  constructor() {
    this.eie_1 = new Vec2();
    this.fie_1 = new Vec2();
    this.gie_1 = 0;
    this.hie_1 = 0;
  }
}
class ManifoldType extends Enum {}
class Manifold {
  static fih() {
    var $this = createThis(this);
    init_org_jbox2d_collision_Manifold($this);
    var tmp = $this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().ui7();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = ManifoldPoint.gih();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.cib_1 = tmp_2;
    $this.dib_1 = new Vec2();
    $this.eib_1 = new Vec2();
    $this.gib_1 = 0;
    return $this;
  }
  hih(cp) {
    var inductionVariable = 0;
    var last = cp.gib_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.cib_1[i].iih(cp.cib_1[i]);
      }
       while (inductionVariable < last);
    this.fib_1 = cp.fib_1;
    this.dib_1.via(cp.dib_1);
    this.eib_1.via(cp.eib_1);
    this.gib_1 = cp.gib_1;
  }
}
class ManifoldPoint {
  static gih() {
    var $this = createThis(this);
    init_org_jbox2d_collision_ManifoldPoint($this);
    $this.mib_1 = new Vec2();
    $this.oib_1 = 0.0;
    $this.nib_1 = $this.oib_1;
    $this.pib_1 = ContactID.ki8();
    return $this;
  }
  iih(cp) {
    this.mib_1.via(cp.mib_1);
    this.nib_1 = cp.nib_1;
    this.oib_1 = cp.oib_1;
    this.pib_1.rib(cp.pib_1);
  }
}
class RayCastInput {
  constructor() {
    this.lih_1 = 0;
    this.jih_1 = new Vec2();
    this.kih_1 = new Vec2();
    this.lih_1 = 0.0;
  }
}
class RayCastOutput {
  constructor() {
    this.nih_1 = 0;
    this.mih_1 = new Vec2();
    this.nih_1 = 0.0;
  }
}
class TOIInput {
  constructor() {
    this.oih_1 = new DistanceProxy();
    this.pih_1 = new DistanceProxy();
    this.qih_1 = new Sweep();
    this.rih_1 = new Sweep();
    this.sih_1 = 0;
  }
}
class TOIOutputState extends Enum {}
class TOIOutput {
  constructor() {
    this.tih_1 = null;
    this.uih_1 = 0;
  }
}
class Companion_2 {
  constructor() {
    this.vih_1 = 20;
    this.wih_1 = 50;
  }
}
class Stats_0 {
  constructor() {
    this.xih_1 = 0;
    this.yih_1 = 0;
    this.zih_1 = 0;
    this.aii_1 = 0;
    this.bii_1 = 0;
  }
}
class TimeOfImpact {
  constructor(pool, stats) {
    stats = stats === VOID ? new Stats_0() : stats;
    this.cii_1 = pool;
    this.dii_1 = stats;
    this.eii_1 = new SimplexCache();
    this.fii_1 = new DistanceInput();
    this.gii_1 = Transform.iia();
    this.hii_1 = Transform.iia();
    this.iii_1 = new DistanceOutput();
    this.jii_1 = new SeparationFunction();
    this.kii_1 = new Int32Array(2);
    this.lii_1 = new Sweep();
    this.mii_1 = new Sweep();
  }
  nii(output, input) {
    var _receiver__tnumb7 = this.dii_1;
    _receiver__tnumb7.xih_1 = _receiver__tnumb7.xih_1 + 1 | 0;
    output.tih_1 = TOIOutputState_UNKNOWN_getInstance();
    output.uih_1 = input.sih_1;
    var proxyA = input.oih_1;
    var proxyB = input.pih_1;
    this.lii_1.uii(input.qih_1);
    this.mii_1.uii(input.rih_1);
    this.lii_1.vii();
    this.mii_1.vii();
    var tMax = input.sih_1;
    var totalRadius = proxyA.mid_1 + proxyB.mid_1;
    var target = Companion_getInstance_11().wii(Settings_getInstance().vie(), totalRadius - 3.0 * Settings_getInstance().vie());
    var tolerance = 0.25 * Settings_getInstance().vie();
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(target > tolerance)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var t1 = 0.0;
    var iter = 0;
    this.eii_1.rid_1 = 0;
    this.fii_1.fid_1 = input.oih_1;
    this.fii_1.gid_1 = input.pih_1;
    this.fii_1.jid_1 = false;
    $l$loop_9: while (true) {
      this.lii_1.xii(this.gii_1, t1);
      this.mii_1.xii(this.hii_1, t1);
      this.fii_1.hid_1 = this.gii_1;
      this.fii_1.iid_1 = this.hii_1;
      this.cii_1.uid().die(this.iii_1, this.eii_1, this.fii_1);
      if (this.iii_1.gie_1 <= 0.0) {
        output.tih_1 = TOIOutputState_OVERLAPPED_getInstance();
        output.uih_1 = 0.0;
        break $l$loop_9;
      }
      if (this.iii_1.gie_1 < target + tolerance) {
        output.tih_1 = TOIOutputState_TOUCHING_getInstance();
        output.uih_1 = t1;
        break $l$loop_9;
      }
      this.jii_1.tij(this.eii_1, proxyA, this.lii_1, proxyB, this.mii_1, t1);
      var done = false;
      var t2 = tMax;
      var pushBackIter = 0;
      $l$loop_7: while (true) {
        var s2 = this.jii_1.uij(this.kii_1, t2);
        if (s2 > target + tolerance) {
          output.tih_1 = TOIOutputState_SEPARATED_getInstance();
          output.uih_1 = tMax;
          done = true;
          break $l$loop_7;
        }
        if (s2 > target - tolerance) {
          t1 = t2;
          break $l$loop_7;
        }
        var s1 = this.jii_1.vij(this.kii_1[0], this.kii_1[1], t1);
        if (s1 < target - tolerance) {
          output.tih_1 = TOIOutputState_FAILED_getInstance();
          output.uih_1 = t1;
          done = true;
          break $l$loop_7;
        }
        if (s1 <= target + tolerance) {
          output.tih_1 = TOIOutputState_TOUCHING_getInstance();
          output.uih_1 = t1;
          done = true;
          break $l$loop_7;
        }
        var rootIterCount = 0;
        var a1 = t1;
        var a2 = t2;
        $l$loop_6: while (true) {
          var t;
          if ((rootIterCount & 1) === 1) {
            t = a1 + (target - s1) * (a2 - a1) / (s2 - s1);
          } else {
            t = 0.5 * (a1 + a2);
          }
          rootIterCount = rootIterCount + 1 | 0;
          var _receiver__tnumb7_0 = this.dii_1;
          _receiver__tnumb7_0.aii_1 = _receiver__tnumb7_0.aii_1 + 1 | 0;
          var s = this.jii_1.vij(this.kii_1[0], this.kii_1[1], t);
          if (Companion_getInstance_11().wij(s - target) < tolerance) {
            t2 = t;
            break $l$loop_6;
          }
          if (s > target) {
            a1 = t;
            s1 = s;
          } else {
            a2 = t;
            s2 = s;
          }
          if (rootIterCount === Companion_instance_2.wih_1) {
            break $l$loop_6;
          }
        }
        this.dii_1.bii_1 = Companion_getInstance_11().eih(this.dii_1.bii_1, rootIterCount);
        pushBackIter = pushBackIter + 1 | 0;
        if (pushBackIter === Settings_getInstance().pi8() || rootIterCount === Companion_instance_2.wih_1) {
          break $l$loop_7;
        }
      }
      iter = iter + 1 | 0;
      var _receiver__tnumb7_1 = this.dii_1;
      _receiver__tnumb7_1.yih_1 = _receiver__tnumb7_1.yih_1 + 1 | 0;
      if (done) {
        break $l$loop_9;
      }
      if (iter === Companion_instance_2.vih_1) {
        output.tih_1 = TOIOutputState_FAILED_getInstance();
        output.uih_1 = t1;
        break $l$loop_9;
      }
    }
    this.dii_1.zih_1 = Companion_getInstance_11().eih(this.dii_1.zih_1, iter);
  }
}
class SeparationFunction {
  constructor() {
    this.bij_1 = new Vec2();
    this.cij_1 = new Vec2();
    this.fij_1 = new Vec2();
    this.gij_1 = new Vec2();
    this.hij_1 = new Vec2();
    this.iij_1 = new Vec2();
    this.jij_1 = new Vec2();
    this.kij_1 = new Vec2();
    this.lij_1 = new Vec2();
    this.mij_1 = new Vec2();
    this.nij_1 = new Vec2();
    this.oij_1 = new Vec2();
    this.pij_1 = Transform.iia();
    this.qij_1 = Transform.iia();
    this.rij_1 = new Vec2();
    this.sij_1 = new Vec2();
  }
  xij() {
    var tmp = this.yii_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('m_proxyA');
    }
  }
  yij() {
    var tmp = this.zii_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('m_proxyB');
    }
  }
  zij() {
    var tmp = this.aij_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('m_type');
    }
  }
  aik() {
    var tmp = this.dij_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('m_sweepA');
    }
  }
  bik() {
    var tmp = this.eij_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('m_sweepB');
    }
  }
  tij(cache, proxyA, sweepA, proxyB, sweepB, t1) {
    this.yii_1 = proxyA;
    this.zii_1 = proxyB;
    var count = cache.rid_1;
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0 < count && count < 3)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    this.dij_1 = sweepA;
    this.eij_1 = sweepB;
    this.aik().xii(this.pij_1, t1);
    this.bik().xii(this.qij_1, t1);
    if (count === 1) {
      this.aij_1 = Type_POINTS_getInstance();
      this.fij_1.via(this.xij().fig(cache.sid_1[0]));
      this.gij_1.via(this.yij().fig(cache.tid_1[0]));
      Companion_instance_11.lia(this.pij_1, this.fij_1, this.hij_1);
      Companion_instance_11.lia(this.qij_1, this.gij_1, this.iij_1);
      this.cij_1.via(this.iij_1).wia(this.hij_1);
      var s = this.cij_1.xia();
      return s;
    } else if (cache.sid_1[0] === cache.sid_1[1]) {
      this.aij_1 = Type_FACE_B_getInstance();
      this.mij_1.via(this.yij().fig(cache.tid_1[0]));
      this.nij_1.via(this.yij().fig(cache.tid_1[1]));
      this.oij_1.via(this.nij_1).wia(this.mij_1);
      Companion_instance_12.iig(this.oij_1, 1.0, this.cij_1);
      this.cij_1.xia();
      Companion_instance_10.aib(this.qij_1.hia_1, this.cij_1, this.lij_1);
      this.bij_1.via(this.mij_1).aif(this.nij_1).zie(0.5);
      Companion_instance_11.lia(this.qij_1, this.bij_1, this.iij_1);
      this.fij_1.via(proxyA.fig(cache.sid_1[0]));
      Companion_instance_11.lia(this.pij_1, this.fij_1, this.hij_1);
      this.oij_1.via(this.hij_1).wia(this.iij_1);
      var s_0 = Companion_instance_12.yia(this.oij_1, this.lij_1);
      if (s_0 < 0.0) {
        this.cij_1.jib();
        s_0 = -s_0;
      }
      return s_0;
    } else {
      this.aij_1 = Type_FACE_A_getInstance();
      this.jij_1.via(this.xij().fig(cache.sid_1[0]));
      this.kij_1.via(this.xij().fig(cache.sid_1[1]));
      this.oij_1.via(this.kij_1).wia(this.jij_1);
      Companion_instance_12.iig(this.oij_1, 1.0, this.cij_1);
      this.cij_1.xia();
      Companion_instance_10.aib(this.pij_1.hia_1, this.cij_1, this.lij_1);
      this.bij_1.via(this.jij_1).aif(this.kij_1).zie(0.5);
      Companion_instance_11.lia(this.pij_1, this.bij_1, this.hij_1);
      this.gij_1.via(this.yij().fig(cache.tid_1[0]));
      Companion_instance_11.lia(this.qij_1, this.gij_1, this.iij_1);
      this.oij_1.via(this.iij_1).wia(this.hij_1);
      var s_1 = Companion_instance_12.yia(this.oij_1, this.lij_1);
      if (s_1 < 0.0) {
        this.cij_1.jib();
        s_1 = -s_1;
      }
      return s_1;
    }
  }
  uij(indexes, t) {
    this.aik().xii(this.pij_1, t);
    this.bik().xii(this.qij_1, t);
    switch (this.zij().v3_1) {
      case 0:
        Companion_instance_10.dih(this.pij_1.hia_1, this.cij_1, this.rij_1);
        Companion_instance_10.dih(this.qij_1.hia_1, this.cij_1.jib(), this.sij_1);
        this.cij_1.jib();
        indexes[0] = this.xij().xig(this.rij_1);
        indexes[1] = this.yij().xig(this.sij_1);
        this.fij_1.via(this.xij().fig(indexes[0]));
        this.gij_1.via(this.yij().fig(indexes[1]));
        Companion_instance_11.lia(this.pij_1, this.fij_1, this.hij_1);
        Companion_instance_11.lia(this.qij_1, this.gij_1, this.iij_1);
        var separation = Companion_instance_12.yia(this.iij_1.wia(this.hij_1), this.cij_1);
        return separation;
      case 1:
        Companion_instance_10.aib(this.pij_1.hia_1, this.cij_1, this.lij_1);
        Companion_instance_11.lia(this.pij_1, this.bij_1, this.hij_1);
        Companion_instance_10.dih(this.qij_1.hia_1, this.lij_1.jib(), this.sij_1);
        this.lij_1.jib();
        indexes[0] = -1;
        indexes[1] = this.yij().xig(this.sij_1);
        this.gij_1.via(this.yij().fig(indexes[1]));
        Companion_instance_11.lia(this.qij_1, this.gij_1, this.iij_1);
        var separation_0 = Companion_instance_12.yia(this.iij_1.wia(this.hij_1), this.lij_1);
        return separation_0;
      case 2:
        Companion_instance_10.aib(this.qij_1.hia_1, this.cij_1, this.lij_1);
        Companion_instance_11.lia(this.qij_1, this.bij_1, this.iij_1);
        Companion_instance_10.dih(this.pij_1.hia_1, this.lij_1.jib(), this.rij_1);
        this.lij_1.jib();
        indexes[1] = -1;
        indexes[0] = this.xij().xig(this.rij_1);
        this.fij_1.via(this.xij().fig(indexes[0]));
        Companion_instance_11.lia(this.pij_1, this.fij_1, this.hij_1);
        var separation_1 = Companion_instance_12.yia(this.hij_1.wia(this.iij_1), this.lij_1);
        return separation_1;
      default:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }

        indexes[0] = -1;
        indexes[1] = -1;
        return 0.0;
    }
  }
  vij(indexA, indexB, t) {
    this.aik().xii(this.pij_1, t);
    this.bik().xii(this.qij_1, t);
    switch (this.zij().v3_1) {
      case 0:
        this.fij_1.via(this.xij().fig(indexA));
        this.gij_1.via(this.yij().fig(indexB));
        Companion_instance_11.lia(this.pij_1, this.fij_1, this.hij_1);
        Companion_instance_11.lia(this.qij_1, this.gij_1, this.iij_1);
        var separation = Companion_instance_12.yia(this.iij_1.wia(this.hij_1), this.cij_1);
        return separation;
      case 1:
        Companion_instance_10.aib(this.pij_1.hia_1, this.cij_1, this.lij_1);
        Companion_instance_11.lia(this.pij_1, this.bij_1, this.hij_1);
        this.gij_1.via(this.yij().fig(indexB));
        Companion_instance_11.lia(this.qij_1, this.gij_1, this.iij_1);
        var separation_0 = Companion_instance_12.yia(this.iij_1.wia(this.hij_1), this.lij_1);
        return separation_0;
      case 2:
        Companion_instance_10.aib(this.qij_1.hia_1, this.cij_1, this.lij_1);
        Companion_instance_11.lia(this.qij_1, this.bij_1, this.iij_1);
        this.fij_1.via(this.xij().fig(indexA));
        Companion_instance_11.lia(this.pij_1, this.fij_1, this.hij_1);
        var separation_1 = Companion_instance_12.yia(this.hij_1.wia(this.iij_1), this.lij_1);
        return separation_1;
      default:
        // Inline function 'org.jbox2d.internal.assert' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'kotlin.contracts.contract' call

        var tmp;
        if (!false) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }

        return 0.0;
    }
  }
}
class Type_1 extends Enum {}
class WorldManifold {
  constructor() {
    this.cik_1 = new Vec2();
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().ui7();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = new Vec2();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.dik_1 = tmp_2;
    this.eik_1 = new Float32Array(Settings_getInstance().ui7());
    this.fik_1 = new Vec2();
    this.gik_1 = new Vec2();
  }
  hik(manifold, xfA, radiusA, xfB, radiusB) {
    if (manifold.gib_1 === 0) {
      return Unit_instance;
    }
    switch (manifold.fib_1.v3_1) {
      case 0:
        var pointA = this.fik_1;
        var pointB = this.gik_1;
        this.cik_1.ai4_1 = 1.0;
        this.cik_1.bi4_1 = 0.0;
        var v = manifold.eib_1;
        pointA.ai4_1 = xfA.hia_1.nie_1 * v.ai4_1 - xfA.hia_1.mie_1 * v.bi4_1 + xfA.gia_1.ai4_1;
        pointA.bi4_1 = xfA.hia_1.mie_1 * v.ai4_1 + xfA.hia_1.nie_1 * v.bi4_1 + xfA.gia_1.bi4_1;
        var mp0p = manifold.cib_1[0].mib_1;
        pointB.ai4_1 = xfB.hia_1.nie_1 * mp0p.ai4_1 - xfB.hia_1.mie_1 * mp0p.bi4_1 + xfB.gia_1.ai4_1;
        pointB.bi4_1 = xfB.hia_1.mie_1 * mp0p.ai4_1 + xfB.hia_1.nie_1 * mp0p.bi4_1 + xfB.gia_1.bi4_1;
        if (Companion_getInstance_11().iik(pointA, pointB) > Settings_getInstance().ji7_1 * Settings_getInstance().ji7_1) {
          this.cik_1.ai4_1 = pointB.ai4_1 - pointA.ai4_1;
          this.cik_1.bi4_1 = pointB.bi4_1 - pointA.bi4_1;
          this.cik_1.xia();
        }

        var cAx = this.cik_1.ai4_1 * radiusA + pointA.ai4_1;
        var cAy = this.cik_1.bi4_1 * radiusA + pointA.bi4_1;
        var cBx = -this.cik_1.ai4_1 * radiusB + pointB.ai4_1;
        var cBy = -this.cik_1.bi4_1 * radiusB + pointB.bi4_1;
        this.dik_1[0].ai4_1 = (cAx + cBx) * 0.5;
        this.dik_1[0].bi4_1 = (cAy + cBy) * 0.5;
        this.eik_1[0] = (cBx - cAx) * this.cik_1.ai4_1 + (cBy - cAy) * this.cik_1.bi4_1;
        break;
      case 1:
        var planePoint = this.fik_1;
        Companion_instance_10.aib(xfA.hia_1, manifold.dib_1, this.cik_1);
        Companion_instance_11.wie(xfA, manifold.eib_1, planePoint);
        var clipPoint = this.gik_1;
        var inductionVariable = 0;
        var last = manifold.gib_1;
        if (inductionVariable < last)
          do {
            var i = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            Companion_instance_11.wie(xfB, manifold.cib_1[i].mib_1, clipPoint);
            var scalar = radiusA - ((clipPoint.ai4_1 - planePoint.ai4_1) * this.cik_1.ai4_1 + (clipPoint.bi4_1 - planePoint.bi4_1) * this.cik_1.bi4_1);
            var cAx_0 = this.cik_1.ai4_1 * scalar + clipPoint.ai4_1;
            var cAy_0 = this.cik_1.bi4_1 * scalar + clipPoint.bi4_1;
            var cBx_0 = -this.cik_1.ai4_1 * radiusB + clipPoint.ai4_1;
            var cBy_0 = -this.cik_1.bi4_1 * radiusB + clipPoint.bi4_1;
            this.dik_1[i].ai4_1 = (cAx_0 + cBx_0) * 0.5;
            this.dik_1[i].bi4_1 = (cAy_0 + cBy_0) * 0.5;
            this.eik_1[i] = (cBx_0 - cAx_0) * this.cik_1.ai4_1 + (cBy_0 - cAy_0) * this.cik_1.bi4_1;
          }
           while (inductionVariable < last);
        break;
      case 2:
        var planePoint_0 = this.fik_1;
        Companion_instance_10.aib(xfB.hia_1, manifold.dib_1, this.cik_1);
        Companion_instance_11.wie(xfB, manifold.eib_1, planePoint_0);
        var clipPoint_0 = this.gik_1;
        var inductionVariable_0 = 0;
        var last_0 = manifold.gib_1;
        if (inductionVariable_0 < last_0)
          do {
            var i_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            Companion_instance_11.wie(xfA, manifold.cib_1[i_0].mib_1, clipPoint_0);
            var scalar_0 = radiusB - ((clipPoint_0.ai4_1 - planePoint_0.ai4_1) * this.cik_1.ai4_1 + (clipPoint_0.bi4_1 - planePoint_0.bi4_1) * this.cik_1.bi4_1);
            var cBx_1 = this.cik_1.ai4_1 * scalar_0 + clipPoint_0.ai4_1;
            var cBy_1 = this.cik_1.bi4_1 * scalar_0 + clipPoint_0.bi4_1;
            var cAx_1 = -this.cik_1.ai4_1 * radiusA + clipPoint_0.ai4_1;
            var cAy_1 = -this.cik_1.bi4_1 * radiusA + clipPoint_0.bi4_1;
            this.dik_1[i_0].ai4_1 = (cAx_1 + cBx_1) * 0.5;
            this.dik_1[i_0].bi4_1 = (cAy_1 + cBy_1) * 0.5;
            this.eik_1[i_0] = (cAx_1 - cBx_1) * this.cik_1.ai4_1 + (cAy_1 - cBy_1) * this.cik_1.bi4_1;
          }
           while (inductionVariable_0 < last_0);
        this.cik_1.ai4_1 = -this.cik_1.ai4_1;
        this.cik_1.bi4_1 = -this.cik_1.bi4_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
  }
}
class Companion_3 {
  constructor() {
    this.jik_1 = -1;
  }
}
class DefaultBroadPhaseBuffer {
  constructor(m_tree) {
    this.kik_1 = m_tree;
    this.lik_1 = 0;
    this.mik_1 = null;
    this.nik_1 = 0;
    this.oik_1 = 0;
    this.pik_1 = null;
    this.qik_1 = 0;
    this.rik_1 = 0;
    this.sik_1 = 0;
    this.lik_1 = 0;
    this.qik_1 = 16;
    this.rik_1 = 0;
    this.pik_1 = longArray(this.qik_1);
    this.nik_1 = 16;
    this.oik_1 = 0;
    this.mik_1 = new Int32Array(this.nik_1);
    this.sik_1 = Companion_instance_3.jik_1;
  }
  tik(aabb, userData) {
    var proxyId = this.kik_1.tik(aabb, userData);
    this.lik_1 = this.lik_1 + 1 | 0;
    this.uik(proxyId);
    return proxyId;
  }
  vik(proxyId) {
    this.wik(proxyId);
    this.lik_1 = this.lik_1 - 1 | 0;
    this.kik_1.vik(proxyId);
  }
  xik(proxyId, aabb, displacement) {
    var buffer = this.kik_1.yik(proxyId, aabb, displacement);
    if (buffer) {
      this.uik(proxyId);
    }
  }
  zik(proxyId) {
    return this.kik_1.zik(proxyId);
  }
  ail(proxyIdA, proxyIdB) {
    var a = this.kik_1.bil(proxyIdA);
    var b = this.kik_1.bil(proxyIdB);
    if (b.wi7_1.ai4_1 - a.xi7_1.ai4_1 > 0.0 || b.wi7_1.bi4_1 - a.xi7_1.bi4_1 > 0.0) {
      return false;
    }
    return !(a.wi7_1.ai4_1 - b.xi7_1.ai4_1 > 0.0 || a.wi7_1.bi4_1 - b.xi7_1.bi4_1 > 0.0);
  }
  cil(callback) {
    this.rik_1 = 0;
    var inductionVariable = 0;
    var last = this.oik_1;
    if (inductionVariable < last)
      $l$loop: do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.sik_1 = ensureNotNull(this.mik_1)[i];
        if (this.sik_1 === Companion_instance_3.jik_1) {
          continue $l$loop;
        }
        var fatAABB = this.kik_1.bil(this.sik_1);
        this.kik_1.dil(this, fatAABB);
      }
       while (inductionVariable < last);
    this.oik_1 = 0;
    Arrays_sort_0(ensureNotNull(this.pik_1), 0, this.rik_1);
    var i_0 = 0;
    while (i_0 < this.rik_1) {
      var primaryPair = ensureNotNull(this.pik_1)[i_0];
      var userDataA = this.kik_1.zik(primaryPair.l4(32).q2());
      var userDataB = this.kik_1.zik(primaryPair.q2());
      callback.eil(userDataA, userDataB);
      i_0 = i_0 + 1 | 0;
      $l$loop_0: while (i_0 < this.rik_1) {
        var pair = ensureNotNull(this.pik_1)[i_0];
        if (!pair.equals(primaryPair)) {
          break $l$loop_0;
        }
        i_0 = i_0 + 1 | 0;
      }
    }
  }
  dil(callback, aabb) {
    this.kik_1.dil(callback, aabb);
  }
  uik(proxyId) {
    if (this.oik_1 === this.nik_1) {
      var old = this.mik_1;
      this.nik_1 = imul(this.nik_1, 2);
      this.mik_1 = new Int32Array(this.nik_1);
      // Inline function 'org.jbox2d.internal.arraycopy' call
      var src = ensureNotNull(old);
      var dst = ensureNotNull(this.mik_1);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.copyInto' call
      var endIndex = 0 + old.length | 0;
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp = src;
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      arrayCopy(tmp, dst, 0, 0, endIndex);
    }
    ensureNotNull(this.mik_1)[this.oik_1] = proxyId;
    this.oik_1 = this.oik_1 + 1 | 0;
  }
  wik(proxyId) {
    var inductionVariable = 0;
    var last = this.oik_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (ensureNotNull(this.mik_1)[i] === proxyId) {
          ensureNotNull(this.mik_1)[i] = Companion_instance_3.jik_1;
        }
      }
       while (inductionVariable < last);
  }
  fil(proxyId) {
    if (proxyId === this.sik_1) {
      return true;
    }
    if (this.rik_1 === this.qik_1) {
      var oldBuffer = this.pik_1;
      this.qik_1 = imul(this.qik_1, 2);
      this.pik_1 = longArray(this.qik_1);
      // Inline function 'org.jbox2d.internal.arraycopy' call
      var src = ensureNotNull(oldBuffer);
      var dst = ensureNotNull(this.pik_1);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.copyInto' call
      var endIndex = 0 + oldBuffer.length | 0;
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp = src;
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      arrayCopy(tmp, dst, 0, 0, endIndex);
      var inductionVariable = oldBuffer.length;
      var last = this.qik_1;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          ensureNotNull(this.pik_1)[i] = new Long(0, 0);
        }
         while (inductionVariable < last);
    }
    if (proxyId < this.sik_1) {
      ensureNotNull(this.pik_1)[this.rik_1] = toLong(proxyId).k4(32).o4(toLong(this.sik_1));
    } else {
      ensureNotNull(this.pik_1)[this.rik_1] = toLong(this.sik_1).k4(32).o4(toLong(proxyId));
    }
    this.rik_1 = this.rik_1 + 1 | 0;
    return true;
  }
}
class Companion_4 {
  constructor() {
    this.cim_1 = 64;
    this.dim_1 = -1;
  }
}
class DynamicTree {
  constructor() {
    this.gil_1 = null;
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(16), null);
    while (tmp_0 < 16) {
      var tmp_2 = tmp_0;
      tmp_1[tmp_2] = new DynamicTreeNode(tmp_2);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.hil_1 = tmp_1;
    this.iil_1 = 0;
    this.jil_1 = 16;
    this.kil_1 = 0;
    var tmp_3 = this;
    var tmp_4 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_5 = fillArrayVal(Array(4), null);
    while (tmp_4 < 4) {
      tmp_5[tmp_4] = new Vec2();
      tmp_4 = tmp_4 + 1 | 0;
    }
    tmp_3.lil_1 = tmp_5;
    var tmp_6 = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp_6.mil_1 = fillArrayVal(Array(20), null);
    this.nil_1 = 0;
    this.oil_1 = new Vec2();
    this.pil_1 = AABB.ai8();
    this.qil_1 = new RayCastInput();
    this.ril_1 = AABB.ai8();
    this.sil_1 = Color3f.him();
    this.til_1 = new Vec2();
    var inductionVariable = this.jil_1 - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        ensureNotNull(this.hil_1)[i].yil_1 = i === (this.jil_1 - 1 | 0) ? null : ensureNotNull(this.hil_1)[i + 1 | 0];
        ensureNotNull(this.hil_1)[i].bim_1 = -1;
      }
       while (0 <= inductionVariable);
  }
  tik(aabb, userData) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!aabb.yi7()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var node = allocateNode(this);
    var proxyId = node.vil_1;
    var nodeAABB = node.wil_1;
    nodeAABB.wi7_1.ai4_1 = aabb.wi7_1.ai4_1 - Settings_getInstance().iim();
    nodeAABB.wi7_1.bi4_1 = aabb.wi7_1.bi4_1 - Settings_getInstance().iim();
    nodeAABB.xi7_1.ai4_1 = aabb.xi7_1.ai4_1 + Settings_getInstance().iim();
    nodeAABB.xi7_1.bi4_1 = aabb.xi7_1.bi4_1 + Settings_getInstance().iim();
    node.xil_1 = userData;
    insertLeaf(this, proxyId);
    return proxyId;
  }
  vik(proxyId) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0 <= proxyId && proxyId < this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var node = ensureNotNull(this.hil_1)[proxyId];
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(node.zil_1 == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    removeLeaf(this, node);
    freeNode(this, node);
  }
  yik(proxyId, aabb, displacement) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!aabb.yi7()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(0 <= proxyId && proxyId < this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    var node = ensureNotNull(this.hil_1)[proxyId];
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_1;
    if (!(node.zil_1 == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_1 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_1));
    }
    var nodeAABB = node.wil_1;
    if (nodeAABB.wi7_1.ai4_1 <= aabb.wi7_1.ai4_1 && nodeAABB.wi7_1.bi4_1 <= aabb.wi7_1.bi4_1 && aabb.xi7_1.ai4_1 <= nodeAABB.xi7_1.ai4_1 && aabb.xi7_1.bi4_1 <= nodeAABB.xi7_1.bi4_1) {
      return false;
    }
    removeLeaf(this, node);
    var lowerBound = nodeAABB.wi7_1;
    var upperBound = nodeAABB.xi7_1;
    lowerBound.ai4_1 = aabb.wi7_1.ai4_1 - Settings_getInstance().iim();
    lowerBound.bi4_1 = aabb.wi7_1.bi4_1 - Settings_getInstance().iim();
    upperBound.ai4_1 = aabb.xi7_1.ai4_1 + Settings_getInstance().iim();
    upperBound.bi4_1 = aabb.xi7_1.bi4_1 + Settings_getInstance().iim();
    var dx = displacement.ai4_1 * Settings_getInstance().jim();
    var dy = displacement.bi4_1 * Settings_getInstance().jim();
    if (dx < 0.0) {
      lowerBound.ai4_1 = lowerBound.ai4_1 + dx;
    } else {
      upperBound.ai4_1 = upperBound.ai4_1 + dx;
    }
    if (dy < 0.0) {
      lowerBound.bi4_1 = lowerBound.bi4_1 + dy;
    } else {
      upperBound.bi4_1 = upperBound.bi4_1 + dy;
    }
    insertLeaf(this, proxyId);
    return true;
  }
  zik(proxyId) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0 <= proxyId && proxyId < this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    return ensureNotNull(this.hil_1)[proxyId].xil_1;
  }
  bil(proxyId) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0 <= proxyId && proxyId < this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    return ensureNotNull(this.hil_1)[proxyId].wil_1;
  }
  dil(callback, aabb) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!aabb.yi7()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    this.nil_1 = 0;
    var tmp_0 = this.mil_1;
    var _unary__edvuaz = this.nil_1;
    this.nil_1 = _unary__edvuaz + 1 | 0;
    tmp_0[_unary__edvuaz] = this.gil_1;
    $l$loop: while (this.nil_1 > 0) {
      var tmp_1 = this.mil_1;
      this.nil_1 = this.nil_1 - 1 | 0;
      var tmp0_elvis_lhs = tmp_1[this.nil_1];
      var tmp_2;
      if (tmp0_elvis_lhs == null) {
        continue $l$loop;
      } else {
        tmp_2 = tmp0_elvis_lhs;
      }
      var node = tmp_2;
      if (Companion_instance.vi7(node.wil_1, aabb)) {
        if (node.zil_1 == null) {
          var proceed = callback.fil(node.vil_1);
          if (!proceed) {
            return Unit_instance;
          }
        } else {
          if (((this.mil_1.length - this.nil_1 | 0) - 2 | 0) <= 0) {
            // Inline function 'kotlin.arrayOfNulls' call
            var size = imul(this.mil_1.length, 2);
            var newBuffer = fillArrayVal(Array(size), null);
            // Inline function 'org.jbox2d.internal.arraycopy' call
            var src = this.mil_1;
            // Inline function 'kotlin.run' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlin.collections.copyInto' call
            var endIndex = 0 + this.mil_1.length | 0;
            arrayCopy(src, newBuffer, 0, 0, endIndex);
            this.mil_1 = newBuffer;
          }
          var tmp_3 = this.mil_1;
          var _unary__edvuaz_0 = this.nil_1;
          this.nil_1 = _unary__edvuaz_0 + 1 | 0;
          tmp_3[_unary__edvuaz_0] = node.zil_1;
          var tmp_4 = this.mil_1;
          var _unary__edvuaz_1 = this.nil_1;
          this.nil_1 = _unary__edvuaz_1 + 1 | 0;
          tmp_4[_unary__edvuaz_1] = node.aim_1;
        }
      }
    }
  }
}
class DynamicTreeNode {
  constructor(id) {
    this.uil_1 = new Mixin();
    this.vil_1 = id;
    this.wil_1 = AABB.ai8();
    this.xil_1 = Unit_instance;
    this.yil_1 = null;
    this.zil_1 = null;
    this.aim_1 = null;
    this.bim_1 = 0;
  }
}
class Shape {
  static mim(m_type) {
    var $this = createThis(this);
    $this.oie_1 = m_type;
    $this.pie_1 = 0.0;
    return $this;
  }
  oig() {
    return this.oie_1;
  }
}
class ChainShape extends Shape {
  kim(edge, index) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0 <= index && index < (this.sig_1 - 1 | 0))) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    edge.pie_1 = this.pie_1;
    var v0 = ensureNotNull(this.rig_1)[index + 0 | 0];
    var v1 = ensureNotNull(this.rig_1)[index + 1 | 0];
    edge.oia_1.ai4_1 = v0.ai4_1;
    edge.oia_1.bi4_1 = v0.bi4_1;
    edge.pia_1.ai4_1 = v1.ai4_1;
    edge.pia_1.bi4_1 = v1.bi4_1;
    if (index > 0) {
      var v = ensureNotNull(this.rig_1)[index - 1 | 0];
      edge.qia_1.ai4_1 = v.ai4_1;
      edge.qia_1.bi4_1 = v.bi4_1;
      edge.sia_1 = true;
    } else {
      edge.qia_1.ai4_1 = this.tig_1.ai4_1;
      edge.qia_1.bi4_1 = this.tig_1.bi4_1;
      edge.sia_1 = this.vig_1;
    }
    if (index < (this.sig_1 - 2 | 0)) {
      var v_0 = ensureNotNull(this.rig_1)[index + 2 | 0];
      edge.ria_1.ai4_1 = v_0.ai4_1;
      edge.ria_1.bi4_1 = v_0.bi4_1;
      edge.tia_1 = true;
    } else {
      edge.ria_1.ai4_1 = this.uig_1.ai4_1;
      edge.ria_1.bi4_1 = this.uig_1.bi4_1;
      edge.tia_1 = this.wig_1;
    }
  }
}
class CircleShape extends Shape {
  static lim() {
    var $this = this.mim(ShapeType_CIRCLE_getInstance());
    $this.lie_1 = new Vec2();
    return $this;
  }
  static nim(radius) {
    var $this = this.lim();
    $this.pie_1 = numberToDouble(radius);
    return $this;
  }
  j13() {
    var shape = CircleShape.lim();
    shape.lie_1.ai4_1 = this.lie_1.ai4_1;
    shape.lie_1.bi4_1 = this.lie_1.bi4_1;
    shape.pie_1 = this.pie_1;
    return shape;
  }
  oim() {
    return 1;
  }
  pim(xf, p, childIndex, normalOut) {
    var xfq = xf.hia_1;
    var centerx = xfq.nie_1 * this.lie_1.ai4_1 - xfq.mie_1 * this.lie_1.bi4_1 + xf.gia_1.ai4_1;
    var centery = xfq.mie_1 * this.lie_1.ai4_1 + xfq.nie_1 * this.lie_1.bi4_1 + xf.gia_1.bi4_1;
    var dx = p.ai4_1 - centerx;
    var dy = p.bi4_1 - centery;
    var d1 = Companion_getInstance_11().qim(dx * dx + dy * dy);
    normalOut.ai4_1 = dx * 1 / d1;
    normalOut.bi4_1 = dy * 1 / d1;
    return d1 - this.pie_1;
  }
  rim(output, input, transform, childIndex) {
    var inputp1 = input.jih_1;
    var inputp2 = input.kih_1;
    var tq = transform.hia_1;
    var tp = transform.gia_1;
    var positionx = tq.nie_1 * this.lie_1.ai4_1 - tq.mie_1 * this.lie_1.bi4_1 + tp.ai4_1;
    var positiony = tq.mie_1 * this.lie_1.ai4_1 + tq.nie_1 * this.lie_1.bi4_1 + tp.bi4_1;
    var sx = inputp1.ai4_1 - positionx;
    var sy = inputp1.bi4_1 - positiony;
    var b = sx * sx + sy * sy - this.pie_1 * this.pie_1;
    var rx = inputp2.ai4_1 - inputp1.ai4_1;
    var ry = inputp2.bi4_1 - inputp1.bi4_1;
    var c = sx * rx + sy * ry;
    var rr = rx * rx + ry * ry;
    var sigma = c * c - rr * b;
    if (sigma < 0.0 || rr < Settings_getInstance().ji7_1) {
      return false;
    }
    var a = -(c + Companion_getInstance_11().qim(sigma));
    if (0.0 <= a && a <= input.lih_1 * rr) {
      a = a / rr;
      output.nih_1 = a;
      output.mih_1.ai4_1 = rx * a + sx;
      output.mih_1.bi4_1 = ry * a + sy;
      output.mih_1.xia();
      return true;
    }
    return false;
  }
  sim(aabb, transform, childIndex) {
    var tq = transform.hia_1;
    var tp = transform.gia_1;
    var px = tq.nie_1 * this.lie_1.ai4_1 - tq.mie_1 * this.lie_1.bi4_1 + tp.ai4_1;
    var py = tq.mie_1 * this.lie_1.ai4_1 + tq.nie_1 * this.lie_1.bi4_1 + tp.bi4_1;
    aabb.wi7_1.ai4_1 = px - this.pie_1;
    aabb.wi7_1.bi4_1 = py - this.pie_1;
    aabb.xi7_1.ai4_1 = px + this.pie_1;
    aabb.xi7_1.bi4_1 = py + this.pie_1;
  }
  tim(massData, density) {
    massData.uim_1 = density * Settings_getInstance().ki7_1 * this.pie_1 * this.pie_1;
    massData.vim_1.ai4_1 = this.lie_1.ai4_1;
    massData.vim_1.bi4_1 = this.lie_1.bi4_1;
    massData.wim_1 = massData.uim_1 * (0.5 * this.pie_1 * this.pie_1 + (this.lie_1.ai4_1 * this.lie_1.ai4_1 + this.lie_1.bi4_1 * this.lie_1.bi4_1));
  }
}
class EdgeShape extends Shape {
  static xim() {
    var $this = this.mim(ShapeType_EDGE_getInstance());
    $this.oia_1 = new Vec2();
    $this.pia_1 = new Vec2();
    $this.qia_1 = new Vec2();
    $this.ria_1 = new Vec2();
    $this.sia_1 = false;
    $this.tia_1 = false;
    $this.uia_1 = new Vec2();
    $this.pie_1 = Settings_getInstance().bib();
    return $this;
  }
  oim() {
    return 1;
  }
  pim(xf, p, childIndex, normalOut) {
    var xfqc = xf.hia_1.nie_1;
    var xfqs = xf.hia_1.mie_1;
    var xfpx = xf.gia_1.ai4_1;
    var xfpy = xf.gia_1.bi4_1;
    var v1x = xfqc * this.oia_1.ai4_1 - xfqs * this.oia_1.bi4_1 + xfpx;
    var v1y = xfqs * this.oia_1.ai4_1 + xfqc * this.oia_1.bi4_1 + xfpy;
    var v2x = xfqc * this.pia_1.ai4_1 - xfqs * this.pia_1.bi4_1 + xfpx;
    var v2y = xfqs * this.pia_1.ai4_1 + xfqc * this.pia_1.bi4_1 + xfpy;
    var dx = p.ai4_1 - v1x;
    var dy = p.bi4_1 - v1y;
    var sx = v2x - v1x;
    var sy = v2y - v1y;
    var ds = dx * sx + dy * sy;
    if (ds > 0) {
      var s2 = sx * sx + sy * sy;
      if (ds > s2) {
        dx = p.ai4_1 - v2x;
        dy = p.bi4_1 - v2y;
      } else {
        dx = dx - ds / s2 * sx;
        dy = dy - ds / s2 * sy;
      }
    }
    var d1 = Companion_getInstance_11().qim(dx * dx + dy * dy);
    if (d1 > 0) {
      normalOut.ai4_1 = 1 / d1 * dx;
      normalOut.bi4_1 = 1 / d1 * dy;
    } else {
      normalOut.ai4_1 = 0.0;
      normalOut.bi4_1 = 0.0;
    }
    return d1;
  }
  rim(output, input, xf, childIndex) {
    var tempx;
    var tempy;
    var v1 = this.oia_1;
    var v2 = this.pia_1;
    var xfq = xf.hia_1;
    var xfp = xf.gia_1;
    tempx = input.jih_1.ai4_1 - xfp.ai4_1;
    tempy = input.jih_1.bi4_1 - xfp.bi4_1;
    var p1x = xfq.nie_1 * tempx + xfq.mie_1 * tempy;
    var p1y = -xfq.mie_1 * tempx + xfq.nie_1 * tempy;
    tempx = input.kih_1.ai4_1 - xfp.ai4_1;
    tempy = input.kih_1.bi4_1 - xfp.bi4_1;
    var p2x = xfq.nie_1 * tempx + xfq.mie_1 * tempy;
    var p2y = -xfq.mie_1 * tempx + xfq.nie_1 * tempy;
    var dx = p2x - p1x;
    var dy = p2y - p1y;
    this.uia_1.ai4_1 = v2.bi4_1 - v1.bi4_1;
    this.uia_1.bi4_1 = v1.ai4_1 - v2.ai4_1;
    this.uia_1.xia();
    var normalx = this.uia_1.ai4_1;
    var normaly = this.uia_1.bi4_1;
    tempx = v1.ai4_1 - p1x;
    tempy = v1.bi4_1 - p1y;
    var numerator = normalx * tempx + normaly * tempy;
    var denominator = normalx * dx + normaly * dy;
    if (denominator === 0.0) {
      return false;
    }
    var t = numerator / denominator;
    if (t < 0.0 || 1.0 < t) {
      return false;
    }
    var qx = p1x + t * dx;
    var qy = p1y + t * dy;
    var rx = v2.ai4_1 - v1.ai4_1;
    var ry = v2.bi4_1 - v1.bi4_1;
    var rr = rx * rx + ry * ry;
    if (rr === 0.0) {
      return false;
    }
    tempx = qx - v1.ai4_1;
    tempy = qy - v1.bi4_1;
    var s = (tempx * rx + tempy * ry) / rr;
    if (s < 0.0 || 1.0 < s) {
      return false;
    }
    output.nih_1 = t;
    if (numerator > 0.0) {
      output.mih_1.ai4_1 = -xfq.nie_1 * this.uia_1.ai4_1 + xfq.mie_1 * this.uia_1.bi4_1;
      output.mih_1.bi4_1 = -xfq.mie_1 * this.uia_1.ai4_1 - xfq.nie_1 * this.uia_1.bi4_1;
    } else {
      output.mih_1.ai4_1 = xfq.nie_1 * this.uia_1.ai4_1 - xfq.mie_1 * this.uia_1.bi4_1;
      output.mih_1.bi4_1 = xfq.mie_1 * this.uia_1.ai4_1 + xfq.nie_1 * this.uia_1.bi4_1;
    }
    return true;
  }
  sim(aabb, xf, childIndex) {
    var lowerBound = aabb.wi7_1;
    var upperBound = aabb.xi7_1;
    var xfq = xf.hia_1;
    var v1x = xfq.nie_1 * this.oia_1.ai4_1 - xfq.mie_1 * this.oia_1.bi4_1 + xf.gia_1.ai4_1;
    var v1y = xfq.mie_1 * this.oia_1.ai4_1 + xfq.nie_1 * this.oia_1.bi4_1 + xf.gia_1.bi4_1;
    var v2x = xfq.nie_1 * this.pia_1.ai4_1 - xfq.mie_1 * this.pia_1.bi4_1 + xf.gia_1.ai4_1;
    var v2y = xfq.mie_1 * this.pia_1.ai4_1 + xfq.nie_1 * this.pia_1.bi4_1 + xf.gia_1.bi4_1;
    lowerBound.ai4_1 = v1x < v2x ? v1x : v2x;
    lowerBound.bi4_1 = v1y < v2y ? v1y : v2y;
    upperBound.ai4_1 = v1x > v2x ? v1x : v2x;
    upperBound.bi4_1 = v1y > v2y ? v1y : v2y;
    lowerBound.ai4_1 = lowerBound.ai4_1 - this.pie_1;
    lowerBound.bi4_1 = lowerBound.bi4_1 - this.pie_1;
    upperBound.ai4_1 = upperBound.ai4_1 + this.pie_1;
    upperBound.bi4_1 = upperBound.bi4_1 + this.pie_1;
  }
  tim(massData, density) {
    massData.uim_1 = 0.0;
    massData.vim_1.via(this.oia_1).aif(this.pia_1).zie(0.5);
    massData.wim_1 = 0.0;
  }
  j13() {
    var edge = EdgeShape.xim();
    edge.pie_1 = this.pie_1;
    edge.sia_1 = this.sia_1;
    edge.tia_1 = this.tia_1;
    edge.qia_1.via(this.qia_1);
    edge.oia_1.via(this.oia_1);
    edge.pia_1.via(this.pia_1);
    edge.ria_1.via(this.ria_1);
    return edge;
  }
}
class MassData {
  static yim() {
    var $this = createThis(this);
    init_org_jbox2d_collision_shapes_MassData($this);
    $this.wim_1 = 0.0;
    $this.uim_1 = $this.wim_1;
    $this.vim_1 = new Vec2();
    return $this;
  }
}
class Companion_5 {
  constructor() {
    this.zim_1 = false;
  }
}
class PolygonShape extends Shape {
  static ji6() {
    var $this = this.mim(ShapeType_POLYGON_getInstance());
    $this.ai6_1 = new Vec2();
    var tmp = $this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().pi8();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = new Vec2();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.bi6_1 = tmp_2;
    var tmp_3 = $this;
    var tmp_4 = 0;
    var tmp_5 = Settings_getInstance().pi8();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_6 = fillArrayVal(Array(tmp_5), null);
    while (tmp_4 < tmp_5) {
      tmp_6[tmp_4] = new Vec2();
      tmp_4 = tmp_4 + 1 | 0;
    }
    tmp_3.ci6_1 = tmp_6;
    $this.di6_1 = 0;
    $this.ei6_1 = new Vec2();
    $this.fi6_1 = new Vec2();
    $this.gi6_1 = new Vec2();
    $this.hi6_1 = new Vec2();
    $this.ii6_1 = Transform.iia();
    $this.pie_1 = Settings_getInstance().bib();
    $this.ai6_1.ki6();
    return $this;
  }
  j13() {
    var shape = PolygonShape.ji6();
    shape.ai6_1.via(this.ai6_1);
    var inductionVariable = 0;
    var last = shape.ci6_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        shape.ci6_1[i].via(this.ci6_1[i]);
        shape.bi6_1[i].via(this.bi6_1[i]);
      }
       while (inductionVariable <= last);
    shape.pie_1 = this.pie_1;
    shape.di6_1 = this.di6_1;
    return shape;
  }
  oim() {
    return 1;
  }
  sim(aabb, xf, childIndex) {
    var lower = aabb.wi7_1;
    var upper = aabb.xi7_1;
    var v1 = this.bi6_1[0];
    var xfqc = xf.hia_1.nie_1;
    var xfqs = xf.hia_1.mie_1;
    var xfpx = xf.gia_1.ai4_1;
    var xfpy = xf.gia_1.bi4_1;
    lower.ai4_1 = xfqc * v1.ai4_1 - xfqs * v1.bi4_1 + xfpx;
    lower.bi4_1 = xfqs * v1.ai4_1 + xfqc * v1.bi4_1 + xfpy;
    upper.ai4_1 = lower.ai4_1;
    upper.bi4_1 = lower.bi4_1;
    var inductionVariable = 1;
    var last = this.di6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v2 = this.bi6_1[i];
        var vx = xfqc * v2.ai4_1 - xfqs * v2.bi4_1 + xfpx;
        var vy = xfqs * v2.ai4_1 + xfqc * v2.bi4_1 + xfpy;
        lower.ai4_1 = lower.ai4_1 < vx ? lower.ai4_1 : vx;
        lower.bi4_1 = lower.bi4_1 < vy ? lower.bi4_1 : vy;
        upper.ai4_1 = upper.ai4_1 > vx ? upper.ai4_1 : vx;
        upper.bi4_1 = upper.bi4_1 > vy ? upper.bi4_1 : vy;
      }
       while (inductionVariable < last);
    lower.ai4_1 = lower.ai4_1 - this.pie_1;
    lower.bi4_1 = lower.bi4_1 - this.pie_1;
    upper.ai4_1 = upper.ai4_1 + this.pie_1;
    upper.bi4_1 = upper.bi4_1 + this.pie_1;
  }
  pim(xf, p, childIndex, normalOut) {
    var xfqc = xf.hia_1.nie_1;
    var xfqs = xf.hia_1.mie_1;
    var tx = p.ai4_1 - xf.gia_1.ai4_1;
    var ty = p.bi4_1 - xf.gia_1.bi4_1;
    var pLocalx = xfqc * tx + xfqs * ty;
    var pLocaly = -xfqs * tx + xfqc * ty;
    var maxDistance = -3.4028235E38;
    var normalForMaxDistanceX = pLocalx;
    var normalForMaxDistanceY = pLocaly;
    var inductionVariable = 0;
    var last = this.di6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var vertex = this.bi6_1[i];
        var normal = this.ci6_1[i];
        tx = pLocalx - vertex.ai4_1;
        ty = pLocaly - vertex.bi4_1;
        var dot = normal.ai4_1 * tx + normal.bi4_1 * ty;
        if (dot > maxDistance) {
          maxDistance = dot;
          normalForMaxDistanceX = normal.ai4_1;
          normalForMaxDistanceY = normal.bi4_1;
        }
      }
       while (inductionVariable < last);
    var distance;
    if (maxDistance > 0) {
      var minDistanceX = normalForMaxDistanceX;
      var minDistanceY = normalForMaxDistanceY;
      var minDistance2 = maxDistance * maxDistance;
      var inductionVariable_0 = 0;
      var last_0 = this.di6_1;
      if (inductionVariable_0 < last_0)
        do {
          var i_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var vertex_0 = this.bi6_1[i_0];
          var distanceVecX = pLocalx - vertex_0.ai4_1;
          var distanceVecY = pLocaly - vertex_0.bi4_1;
          var distance2 = distanceVecX * distanceVecX + distanceVecY * distanceVecY;
          if (minDistance2 > distance2) {
            minDistanceX = distanceVecX;
            minDistanceY = distanceVecY;
            minDistance2 = distance2;
          }
        }
         while (inductionVariable_0 < last_0);
      distance = Companion_getInstance_11().qim(minDistance2);
      normalOut.ai4_1 = xfqc * minDistanceX - xfqs * minDistanceY;
      normalOut.bi4_1 = xfqs * minDistanceX + xfqc * minDistanceY;
      normalOut.xia();
    } else {
      distance = maxDistance;
      normalOut.ai4_1 = xfqc * normalForMaxDistanceX - xfqs * normalForMaxDistanceY;
      normalOut.bi4_1 = xfqs * normalForMaxDistanceX + xfqc * normalForMaxDistanceY;
    }
    return distance;
  }
  rim(output, input, xf, childIndex) {
    var xfqc = xf.hia_1.nie_1;
    var xfqs = xf.hia_1.mie_1;
    var xfp = xf.gia_1;
    var tempx;
    var tempy;
    tempx = input.jih_1.ai4_1 - xfp.ai4_1;
    tempy = input.jih_1.bi4_1 - xfp.bi4_1;
    var p1x = xfqc * tempx + xfqs * tempy;
    var p1y = -xfqs * tempx + xfqc * tempy;
    tempx = input.kih_1.ai4_1 - xfp.ai4_1;
    tempy = input.kih_1.bi4_1 - xfp.bi4_1;
    var p2x = xfqc * tempx + xfqs * tempy;
    var p2y = -xfqs * tempx + xfqc * tempy;
    var dx = p2x - p1x;
    var dy = p2y - p1y;
    var lower = 0.0;
    var upper = input.lih_1;
    var index = -1;
    var inductionVariable = 0;
    var last = this.di6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var normal = this.ci6_1[i];
        var vertex = this.bi6_1[i];
        var tempxn = vertex.ai4_1 - p1x;
        var tempyn = vertex.bi4_1 - p1y;
        var numerator = normal.ai4_1 * tempxn + normal.bi4_1 * tempyn;
        var denominator = normal.ai4_1 * dx + normal.bi4_1 * dy;
        if (denominator === 0.0) {
          if (numerator < 0.0) {
            return false;
          }
        } else {
          if (denominator < 0.0 && numerator < lower * denominator) {
            lower = numerator / denominator;
            index = i;
          } else if (denominator > 0.0 && numerator < upper * denominator) {
            upper = numerator / denominator;
          }
        }
        if (upper < lower) {
          return false;
        }
      }
       while (inductionVariable < last);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(0.0 <= lower && lower <= input.lih_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    if (index >= 0) {
      output.nih_1 = lower;
      var normal_0 = this.ci6_1[index];
      var out = output.mih_1;
      out.ai4_1 = xfqc * normal_0.ai4_1 - xfqs * normal_0.bi4_1;
      out.bi4_1 = xfqs * normal_0.ai4_1 + xfqc * normal_0.bi4_1;
      return true;
    }
    return false;
  }
  tim(massData, density) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.di6_1 >= 3)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var center = this.ei6_1;
    center.ki6();
    var area = 0.0;
    var I = 0.0;
    var s = this.fi6_1;
    s.ki6();
    var inductionVariable = 0;
    var last = this.di6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        s.aif(this.bi6_1[i]);
      }
       while (inductionVariable < last);
    s.zie(1.0 / this.di6_1);
    var k_inv3 = 1.0 / 3.0;
    var e1 = this.gi6_1;
    var e2 = this.hi6_1;
    var inductionVariable_0 = 0;
    var last_0 = this.di6_1;
    if (inductionVariable_0 < last_0)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        e1.via(this.bi6_1[i_0]).wia(s);
        e2.via(s).jib().aif((i_0 + 1 | 0) < this.di6_1 ? this.bi6_1[i_0 + 1 | 0] : this.bi6_1[0]);
        var D = Companion_instance_12.zia(e1, e2);
        var triangleArea = 0.5 * D;
        area = area + triangleArea;
        center.ai4_1 = center.ai4_1 + triangleArea * k_inv3 * (e1.ai4_1 + e2.ai4_1);
        center.bi4_1 = center.bi4_1 + triangleArea * k_inv3 * (e1.bi4_1 + e2.bi4_1);
        var ex1 = e1.ai4_1;
        var ey1 = e1.bi4_1;
        var ex2 = e2.ai4_1;
        var ey2 = e2.bi4_1;
        var intx2 = ex1 * ex1 + ex2 * ex1 + ex2 * ex2;
        var inty2 = ey1 * ey1 + ey2 * ey1 + ey2 * ey2;
        I = I + 0.25 * k_inv3 * D * (intx2 + inty2);
      }
       while (inductionVariable_0 < last_0);
    massData.uim_1 = density * area;
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(area > Settings_getInstance().ji7_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    center.zie(1.0 / area);
    massData.vim_1.via(center).aif(s);
    massData.wim_1 = I * density;
    massData.wim_1 = massData.wim_1 + massData.uim_1 * Companion_instance_12.yia(massData.vim_1, massData.vim_1);
  }
}
class ShapeType extends Enum {}
class BufferUtils {
  ain(klass, oldBuffer, oldCapacity, newCapacity) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(newCapacity > oldCapacity)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(newCapacity), null);
    while (tmp_0 < newCapacity) {
      var tmp_2 = tmp_0;
      tmp_1[tmp_2] = !(oldBuffer == null) && tmp_2 < oldCapacity ? oldBuffer[tmp_2] : klass();
      tmp_0 = tmp_0 + 1 | 0;
    }
    var tmp_3 = tmp_1;
    return isArray(tmp_3) ? tmp_3 : THROW_CCE();
  }
}
class Companion_6 {
  constructor() {
    Companion_instance_6 = this;
    this.bin_1 = Color3f.gin(1.0, 1.0, 1.0);
    this.cin_1 = Color3f.gin(0.0, 0.0, 0.0);
    this.din_1 = Color3f.gin(0.0, 0.0, 1.0);
    this.ein_1 = Color3f.gin(0.0, 1.0, 0.0);
    this.fin_1 = Color3f.gin(1.0, 0.0, 0.0);
  }
}
class Color3f {
  static him() {
    Companion_getInstance_8();
    var $this = createThis(this);
    init_org_jbox2d_common_Color3f($this);
    $this.gim_1 = 0.0;
    $this.fim_1 = $this.gim_1;
    $this.eim_1 = $this.fim_1;
    return $this;
  }
  static gin(r, g, b) {
    Companion_getInstance_8();
    var $this = createThis(this);
    init_org_jbox2d_common_Color3f($this);
    $this.eim_1 = r;
    $this.fim_1 = g;
    $this.gim_1 = b;
    return $this;
  }
}
class Companion_7 {}
class Mat22 {
  toString() {
    var s = '';
    s = s + ('[' + this.hin_1.ai4_1 + ',' + this.iin_1.ai4_1 + ']\n');
    s = s + ('[' + this.hin_1.bi4_1 + ',' + this.iin_1.bi4_1 + ']');
    return s;
  }
  static jin() {
    var $this = createThis(this);
    init_org_jbox2d_common_Mat22($this);
    $this.hin_1 = new Vec2();
    $this.iin_1 = new Vec2();
    return $this;
  }
  ki6() {
    this.hin_1.ai4_1 = 0.0;
    this.iin_1.ai4_1 = 0.0;
    this.hin_1.bi4_1 = 0.0;
    this.iin_1.bi4_1 = 0.0;
  }
  kin(out) {
    var a = this.hin_1.ai4_1;
    var b = this.iin_1.ai4_1;
    var c = this.hin_1.bi4_1;
    var d = this.iin_1.bi4_1;
    var det = a * d - b * c;
    det = 1.0 / det;
    out.hin_1.ai4_1 = det * d;
    out.iin_1.ai4_1 = -det * b;
    out.hin_1.bi4_1 = -det * c;
    out.iin_1.bi4_1 = det * a;
  }
  hashCode() {
    var prime = 31;
    var result = 1;
    var tmp = imul(prime, result);
    var tmp11_safe_receiver = this.hin_1;
    var tmp0_elvis_lhs = tmp11_safe_receiver == null ? null : tmp11_safe_receiver.hashCode();
    result = tmp + (tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs) | 0;
    var tmp_0 = imul(prime, result);
    var tmp12_safe_receiver = this.iin_1;
    var tmp1_elvis_lhs = tmp12_safe_receiver == null ? null : tmp12_safe_receiver.hashCode();
    result = tmp_0 + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
    return result;
  }
  equals(obj) {
    if (this === obj)
      return true;
    if (obj == null)
      return false;
    if (!getKClassFromExpression(this).equals(getKClassFromExpression(obj)))
      return false;
    var other = (obj == null ? true : obj instanceof Mat22) ? obj : THROW_CCE();
    if (this.hin_1 == null) {
      if (!((other == null ? null : other.hin_1) == null))
        return false;
    } else {
      if (!this.hin_1.equals(other == null ? null : other.hin_1))
        return false;
    }
    if (this.iin_1 == null) {
      if (!((other == null ? null : other.iin_1) == null))
        return false;
    } else {
      if (!this.iin_1.equals(other == null ? null : other.iin_1))
        return false;
    }
    return true;
  }
}
class Companion_8 {
  constructor() {
    Companion_instance_8 = this;
    this.lin_1 = Mat33.tin(Vec3.pin(1.0, 0.0, 0.0), Vec3.pin(0.0, 1.0, 0.0), Vec3.pin(0.0, 0.0, 1.0));
  }
}
class Mat33 {
  static uin() {
    Companion_getInstance_10();
    var $this = createThis(this);
    init_org_jbox2d_common_Mat33($this);
    $this.qin_1 = Vec3.pin();
    $this.rin_1 = Vec3.pin();
    $this.sin_1 = Vec3.pin();
    return $this;
  }
  static tin(argCol1, argCol2, argCol3) {
    Companion_getInstance_10();
    var $this = createThis(this);
    init_org_jbox2d_common_Mat33($this);
    $this.qin_1 = argCol1.j13();
    $this.rin_1 = argCol2.j13();
    $this.sin_1 = argCol3.j13();
    return $this;
  }
  hashCode() {
    var prime = 31;
    var result = 1;
    var tmp = imul(prime, result);
    var tmp17_safe_receiver = this.qin_1;
    var tmp0_elvis_lhs = tmp17_safe_receiver == null ? null : tmp17_safe_receiver.hashCode();
    result = tmp + (tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs) | 0;
    var tmp_0 = imul(prime, result);
    var tmp18_safe_receiver = this.rin_1;
    var tmp1_elvis_lhs = tmp18_safe_receiver == null ? null : tmp18_safe_receiver.hashCode();
    result = tmp_0 + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
    var tmp_1 = imul(prime, result);
    var tmp19_safe_receiver = this.sin_1;
    var tmp2_elvis_lhs = tmp19_safe_receiver == null ? null : tmp19_safe_receiver.hashCode();
    result = tmp_1 + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    return result;
  }
  equals(obj) {
    if (this === obj)
      return true;
    if (obj == null)
      return false;
    if (!getKClassFromExpression(this).equals(getKClassFromExpression(obj)))
      return false;
    var other = (obj == null ? true : obj instanceof Mat33) ? obj : THROW_CCE();
    if (this.qin_1 == null) {
      if (!((other == null ? null : other.qin_1) == null))
        return false;
    } else {
      if (!this.qin_1.equals(other == null ? null : other.qin_1))
        return false;
    }
    if (this.rin_1 == null) {
      if (!((other == null ? null : other.rin_1) == null))
        return false;
    } else {
      if (!this.rin_1.equals(other == null ? null : other.rin_1))
        return false;
    }
    if (this.sin_1 == null) {
      if (!((other == null ? null : other.sin_1) == null))
        return false;
    } else {
      if (!this.sin_1.equals(other == null ? null : other.sin_1))
        return false;
    }
    return true;
  }
}
class Companion_9 {
  constructor() {
    Companion_instance_9 = this;
    this.sib_1 = 3.141592653589793;
    this.tib_1 = 3.141592653589793 * 2;
    this.uib_1 = 1.0 / this.sib_1;
    this.vib_1 = this.sib_1 / 2;
    this.wib_1 = this.sib_1 / 4;
    this.xib_1 = this.tib_1 - this.vib_1;
    this.yib_1 = this.sib_1 / 180;
    this.zib_1 = 180 / this.sib_1;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().mi7_1;
    var tmp_2 = new Float32Array(tmp_1);
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      // Inline function 'kotlin.math.sin' call
      var x = tmp_3 * Settings_getInstance().li7_1;
      tmp_2[tmp_3] = Math.sin(x);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.aic_1 = tmp_2;
  }
  vin(x) {
    var tmp;
    if (Settings_getInstance().xin()) {
      tmp = this.win(x);
    } else {
      // Inline function 'kotlin.math.sin' call
      tmp = Math.sin(x);
    }
    return tmp;
  }
  win(x) {
    var x_0 = x;
    x_0 = x_0 % this.tib_1;
    if (x_0 < 0) {
      x_0 = x_0 + this.tib_1;
    }
    if (Settings_getInstance().zin()) {
      x_0 = x_0 / Settings_getInstance().li7_1;
      var index = numberToInt(x_0);
      if (!(index === 0)) {
        x_0 = x_0 % index;
      }
      var tmp;
      if (index === (Settings_getInstance().mi7_1 - 1 | 0)) {
        tmp = (1 - x_0) * this.aic_1[index] + x_0 * this.aic_1[0];
      } else {
        tmp = (1 - x_0) * this.aic_1[index] + x_0 * this.aic_1[index + 1 | 0];
      }
      return tmp;
    } else {
      return this.aic_1[Companion_getInstance_11().yin(x_0 / Settings_getInstance().li7_1) % Settings_getInstance().mi7_1 | 0];
    }
  }
  aio(x) {
    var tmp;
    if (Settings_getInstance().xin()) {
      tmp = this.win(this.vib_1 - x);
    } else {
      // Inline function 'kotlin.math.cos' call
      tmp = Math.cos(x);
    }
    return tmp;
  }
  wij(x) {
    var tmp;
    if (Settings_getInstance().bio()) {
      tmp = x > 0 ? x : -x;
    } else {
      // Inline function 'kotlin.math.abs' call
      tmp = Math.abs(x);
    }
    return tmp;
  }
  cio(x) {
    var tmp;
    if (Settings_getInstance().eio()) {
      tmp = this.dio(x);
    } else {
      // Inline function 'kotlin.math.floor' call
      var tmp$ret$0 = Math.floor(x);
      tmp = numberToInt(tmp$ret$0);
    }
    return tmp;
  }
  dio(x) {
    var y = numberToInt(x);
    return x < y ? y - 1 | 0 : y;
  }
  yin(x) {
    return Settings_getInstance().fio() ? this.cio(x + 0.5) : numberToInt(round(x));
  }
  wii(a, b) {
    return a > b ? a : b;
  }
  eih(a, b) {
    return a > b ? a : b;
  }
  bic(a, b) {
    return a < b ? a : b;
  }
  gio(a, b) {
    return a < b ? a : b;
  }
  hio(a, low, high) {
    return this.wii(low, this.bic(a, high));
  }
  qim(x) {
    // Inline function 'kotlin.math.sqrt' call
    return Math.sqrt(x);
  }
  iik(v1, v2) {
    var dx = v1.ai4_1 - v2.ai4_1;
    var dy = v1.bi4_1 - v2.bi4_1;
    return dx * dx + dy * dy;
  }
  dig(v1, v2) {
    return this.qim(this.iik(v1, v2));
  }
}
class Companion_10 {
  iio(q, r, out) {
    var tempc = q.nie_1 * r.nie_1 - q.mie_1 * r.mie_1;
    out.mie_1 = q.mie_1 * r.nie_1 + q.nie_1 * r.mie_1;
    out.nie_1 = tempc;
  }
  jio(q, r, out) {
    out.mie_1 = q.nie_1 * r.mie_1 - q.mie_1 * r.nie_1;
    out.nie_1 = q.nie_1 * r.nie_1 + q.mie_1 * r.mie_1;
  }
  kio(q, v, out) {
    var tempy = q.mie_1 * v.ai4_1 + q.nie_1 * v.bi4_1;
    out.ai4_1 = q.nie_1 * v.ai4_1 - q.mie_1 * v.bi4_1;
    out.bi4_1 = tempy;
  }
  aib(q, v, out) {
    out.ai4_1 = q.nie_1 * v.ai4_1 - q.mie_1 * v.bi4_1;
    out.bi4_1 = q.mie_1 * v.ai4_1 + q.nie_1 * v.bi4_1;
  }
  dih(q, v, out) {
    out.ai4_1 = q.nie_1 * v.ai4_1 + q.mie_1 * v.bi4_1;
    out.bi4_1 = -q.mie_1 * v.ai4_1 + q.nie_1 * v.bi4_1;
  }
}
class Rot {
  static lio() {
    var $this = createThis(this);
    init_org_jbox2d_common_Rot($this);
    $this.mio();
    return $this;
  }
  toString() {
    return 'Rot(s:' + this.mie_1 + ', c:' + this.nie_1 + ')';
  }
  nio(angleRadians) {
    this.mie_1 = Companion_getInstance_11().vin(angleRadians);
    this.nie_1 = Companion_getInstance_11().aio(angleRadians);
    return this;
  }
  oio(other) {
    this.mie_1 = other.mie_1;
    this.nie_1 = other.nie_1;
    return this;
  }
  mio() {
    this.mie_1 = 0.0;
    this.nie_1 = 1.0;
    return this;
  }
}
class Settings {
  constructor() {
    Settings_instance = this;
    this.ji7_1 = 1.1920929E-7;
    this.ki7_1 = 3.141592653589793;
    this.li7_1 = 1.1E-4;
    var tmp = this;
    // Inline function 'kotlin.math.ceil' call
    var x = 3.141592653589793 * 2 / this.li7_1;
    var tmp$ret$0 = Math.ceil(x);
    tmp.mi7_1 = numberToInt(tmp$ret$0);
    this.ni7_1 = -1;
    this.oi7_1 = 0.75;
    this.pi7_1 = 1.0;
    this.qi7_1 = 5.0;
    this.ri7_1 = 2;
    this.si7_1 = imul(this.ri7_1, this.ri7_1);
    this.ti7_1 = 256;
  }
  bio() {
    return get__FAST_ABS();
  }
  eio() {
    return get__FAST_FLOOR();
  }
  fio() {
    return get__FAST_ROUND();
  }
  pio() {
    return get__CONTACT_STACK_INIT_SIZE();
  }
  xin() {
    return get__SINCOS_LUT_ENABLED();
  }
  zin() {
    return get__SINCOS_LUT_LERP();
  }
  ui7() {
    return get__maxManifoldPoints();
  }
  pi8() {
    return get__maxPolygonVertices();
  }
  iim() {
    return get__aabbExtension();
  }
  jim() {
    return get__aabbMultiplier();
  }
  vie() {
    return get__linearSlop();
  }
  cic() {
    return get__angularSlop();
  }
  bib() {
    return get__polygonRadius();
  }
  qio() {
    return get__maxSubSteps();
  }
  rio() {
    return get__maxTOIContacts();
  }
  sio() {
    return get__velocityThreshold();
  }
  tio() {
    return get__maxLinearCorrection();
  }
  uio() {
    return get__maxTranslation();
  }
  vio() {
    return get__maxTranslationSquared();
  }
  wio() {
    return get__maxRotation();
  }
  xio() {
    return get__maxRotationSquared();
  }
  yio() {
    return get__baumgarte();
  }
  zio() {
    return get__toiBaugarte();
  }
  aip() {
    return get__timeToSleep();
  }
  bip() {
    return get__linearSleepTolerance();
  }
  cip() {
    return get__angularSleepTolerance();
  }
}
class Sweep {
  constructor() {
    this.oii_1 = new Vec2();
    this.pii_1 = new Vec2();
    this.qii_1 = new Vec2();
    this.rii_1 = 0;
    this.sii_1 = 0;
    this.tii_1 = 0;
  }
  toString() {
    var s = 'Sweep:\nlocalCenter: ' + this.oii_1.toString() + '\n';
    s = s + ('c0: ' + this.pii_1.toString() + ', c: ' + this.qii_1.toString() + '\n');
    s = s + ('a0: ' + this.rii_1 + ', a: ' + this.sii_1 + '\n');
    s = s + ('alpha0: ' + this.tii_1);
    return s;
  }
  vii() {
    var d = Companion_getInstance_11().tib_1 * Companion_getInstance_11().cio(this.rii_1 / Companion_getInstance_11().tib_1);
    this.rii_1 = this.rii_1 - d;
    this.sii_1 = this.sii_1 - d;
  }
  uii(other) {
    this.oii_1.via(other.oii_1);
    this.pii_1.via(other.pii_1);
    this.qii_1.via(other.qii_1);
    this.rii_1 = other.rii_1;
    this.sii_1 = other.sii_1;
    this.tii_1 = other.tii_1;
    return this;
  }
  xii(xf, beta) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(xf == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    xf.gia_1.ai4_1 = (1.0 - beta) * this.pii_1.ai4_1 + beta * this.qii_1.ai4_1;
    xf.gia_1.bi4_1 = (1.0 - beta) * this.pii_1.bi4_1 + beta * this.qii_1.bi4_1;
    var angle = (1.0 - beta) * this.rii_1 + beta * this.sii_1;
    xf.hia_1.nio(angle);
    var q = xf.hia_1;
    var _receiver__tnumb7 = xf.gia_1;
    _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - (q.nie_1 * this.oii_1.ai4_1 - q.mie_1 * this.oii_1.bi4_1);
    var _receiver__tnumb7_0 = xf.gia_1;
    _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - (q.mie_1 * this.oii_1.ai4_1 + q.nie_1 * this.oii_1.bi4_1);
  }
  dip(alpha) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.tii_1 < 1.0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var beta = (alpha - this.tii_1) / (1.0 - this.tii_1);
    var _receiver__tnumb7 = this.pii_1;
    _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 + beta * (this.qii_1.ai4_1 - this.pii_1.ai4_1);
    var _receiver__tnumb7_0 = this.pii_1;
    _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 + beta * (this.qii_1.bi4_1 - this.pii_1.bi4_1);
    this.rii_1 = this.rii_1 + beta * (this.sii_1 - this.rii_1);
    this.tii_1 = alpha;
  }
}
class Timer {
  constructor() {
    this.eip_1 = Monotonic_instance.bk();
    this.z33();
  }
  fip() {
    return get_milliseconds(ValueTimeMark__elapsedNow_impl_eonqvs(this.eip_1));
  }
  z33() {
    this.eip_1 = Monotonic_instance.bk();
  }
}
class Companion_11 {
  wie(T, v, out) {
    var tempy = T.hia_1.mie_1 * v.ai4_1 + T.hia_1.nie_1 * v.bi4_1 + T.gia_1.bi4_1;
    out.ai4_1 = T.hia_1.nie_1 * v.ai4_1 - T.hia_1.mie_1 * v.bi4_1 + T.gia_1.ai4_1;
    out.bi4_1 = tempy;
  }
  lia(T, v, out) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(v === out)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    out.ai4_1 = T.hia_1.nie_1 * v.ai4_1 - T.hia_1.mie_1 * v.bi4_1 + T.gia_1.ai4_1;
    out.bi4_1 = T.hia_1.mie_1 * v.ai4_1 + T.hia_1.nie_1 * v.bi4_1 + T.gia_1.bi4_1;
  }
  qib(T, v, out) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(v === out)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var px = v.ai4_1 - T.gia_1.ai4_1;
    var py = v.bi4_1 - T.gia_1.bi4_1;
    out.ai4_1 = T.hia_1.nie_1 * px + T.hia_1.mie_1 * py;
    out.bi4_1 = -T.hia_1.mie_1 * px + T.hia_1.nie_1 * py;
  }
  gip(A, B, out) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(out === A)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    Companion_instance_10.iio(A.hia_1, B.hia_1, out.hia_1);
    Companion_instance_10.kio(A.hia_1, B.gia_1, out.gia_1);
    out.gia_1.aif(A.gia_1);
  }
  kia(A, B, out, pool) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(out === A)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!!(out === B)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    Companion_instance_10.jio(A.hia_1, B.hia_1, out.hia_1);
    pool.via(B.gia_1).wia(A.gia_1);
    Companion_instance_10.dih(A.hia_1, pool, out.gia_1);
  }
}
class Transform {
  static iia() {
    var $this = createThis(this);
    init_org_jbox2d_common_Transform($this);
    $this.gia_1 = new Vec2();
    $this.hia_1 = Rot.lio();
    return $this;
  }
  pid(xf) {
    this.gia_1.via(xf.gia_1);
    this.hia_1.oio(xf.hia_1);
    return this;
  }
  toString() {
    var s = 'XForm:\n';
    s = s + ('Position: ' + this.gia_1.toString() + '\n');
    s = s + ('R: \n' + this.hia_1.toString() + '\n');
    return s;
  }
}
class Companion_12 {
  yia(a, b) {
    return a.ai4_1 * b.ai4_1 + a.bi4_1 * b.bi4_1;
  }
  zia(a, b) {
    return a.ai4_1 * b.bi4_1 - a.bi4_1 * b.ai4_1;
  }
  iig(a, s, out) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(out === a)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    out.ai4_1 = s * a.bi4_1;
    out.bi4_1 = -s * a.ai4_1;
  }
  hip(s, a) {
    return new Vec2(-s * a.bi4_1, s * a.ai4_1);
  }
  jig(s, a, out) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(out === a)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    out.ai4_1 = -s * a.bi4_1;
    out.bi4_1 = s * a.ai4_1;
  }
  iip(a, b, out) {
    out.ai4_1 = a.ai4_1 < b.ai4_1 ? a.ai4_1 : b.ai4_1;
    out.bi4_1 = a.bi4_1 < b.bi4_1 ? a.bi4_1 : b.bi4_1;
  }
  jip(a, b, out) {
    out.ai4_1 = a.ai4_1 > b.ai4_1 ? a.ai4_1 : b.ai4_1;
    out.bi4_1 = a.bi4_1 > b.bi4_1 ? a.bi4_1 : b.bi4_1;
  }
}
class Vec2 {
  constructor(x, y) {
    x = x === VOID ? 0.0 : x;
    y = y === VOID ? 0.0 : y;
    this.ai4_1 = x;
    this.bi4_1 = y;
  }
  yi7() {
    return !isNaN_0(this.ai4_1) && !isInfinite(this.ai4_1) && !isNaN_0(this.bi4_1) && !isInfinite(this.bi4_1);
  }
  ki6() {
    this.ai4_1 = 0.0;
    this.bi4_1 = 0.0;
  }
  ci4(x, y) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'org.jbox2d.common.Vec2.set.<anonymous>' call
    this.ai4_1 = x;
    this.bi4_1 = y;
    return this;
  }
  via(v) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'org.jbox2d.common.Vec2.set.<anonymous>' call
    this.ai4_1 = v.ai4_1;
    this.bi4_1 = v.bi4_1;
    return this;
  }
  kip(v) {
    return new Vec2(this.ai4_1 + v.ai4_1, this.bi4_1 + v.bi4_1);
  }
  jib() {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'org.jbox2d.common.Vec2.negateLocal.<anonymous>' call
    this.ai4_1 = -this.ai4_1;
    this.bi4_1 = -this.bi4_1;
    return this;
  }
  aif(v) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'org.jbox2d.common.Vec2.addLocal.<anonymous>' call
    this.ai4_1 = this.ai4_1 + v.ai4_1;
    this.bi4_1 = this.bi4_1 + v.bi4_1;
    return this;
  }
  wia(v) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'org.jbox2d.common.Vec2.subLocal.<anonymous>' call
    this.ai4_1 = this.ai4_1 - v.ai4_1;
    this.bi4_1 = this.bi4_1 - v.bi4_1;
    return this;
  }
  zie(a) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'org.jbox2d.common.Vec2.mulLocal.<anonymous>' call
    this.ai4_1 = this.ai4_1 * a;
    this.bi4_1 = this.bi4_1 * a;
    return this;
  }
  lip() {
    return Companion_getInstance_11().qim(this.ai4_1 * this.ai4_1 + this.bi4_1 * this.bi4_1);
  }
  cih() {
    return this.ai4_1 * this.ai4_1 + this.bi4_1 * this.bi4_1;
  }
  xia() {
    var length = this.lip();
    if (length < Settings_getInstance().ji7_1) {
      return 0.0;
    }
    var invLength = 1.0 / length;
    this.ai4_1 = this.ai4_1 * invLength;
    this.bi4_1 = this.bi4_1 * invLength;
    return length;
  }
  toString() {
    return '(' + this.ai4_1 + ',' + this.bi4_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.ai4_1);
    result = imul(result, 31) + getNumberHashCode(this.bi4_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Vec2))
      return false;
    var tmp0_other_with_cast = other instanceof Vec2 ? other : THROW_CCE();
    if (!equals(this.ai4_1, tmp0_other_with_cast.ai4_1))
      return false;
    if (!equals(this.bi4_1, tmp0_other_with_cast.bi4_1))
      return false;
    return true;
  }
}
class Companion_13 {}
class Vec3 {
  static pin(x, y, z) {
    x = x === VOID ? 0 : x;
    y = y === VOID ? 0 : y;
    z = z === VOID ? 0 : z;
    var $this = createThis(this);
    $this.min_1 = x;
    $this.nin_1 = y;
    $this.oin_1 = z;
    return $this;
  }
  static mip(copy) {
    return this.pin(copy.min_1, copy.nin_1, copy.oin_1);
  }
  j13() {
    return Vec3.mip(this);
  }
  toString() {
    return '(' + this.min_1 + ',' + this.nin_1 + ',' + this.oin_1 + ')';
  }
  hashCode() {
    var result = getNumberHashCode(this.min_1);
    result = imul(result, 31) + getNumberHashCode(this.nin_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.oin_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Vec3))
      return false;
    var tmp0_other_with_cast = other instanceof Vec3 ? other : THROW_CCE();
    if (!equals(this.min_1, tmp0_other_with_cast.min_1))
      return false;
    if (!equals(this.nin_1, tmp0_other_with_cast.nin_1))
      return false;
    if (!equals(this.oin_1, tmp0_other_with_cast.oin_1))
      return false;
    return true;
  }
}
class ViewInfo {
  constructor() {
    this.pi5_1 = null;
    this.qi5_1 = 0.0;
    this.ri5_1 = 0.0;
    this.si5_1 = get_degrees(0);
    this.ti5_1 = false;
    this.ui5_1 = true;
  }
}
class Companion_14 {
  constructor() {
    this.nip_1 = 1;
    this.oip_1 = 2;
    this.pip_1 = 4;
    this.qip_1 = 8;
    this.rip_1 = 16;
    this.sip_1 = 32;
    this.tip_1 = 64;
  }
}
class Body {
  constructor(bd, world) {
    this.di4_1 = new Mixin();
    this.ei4_1 = world;
    this.fi4_1 = bd;
    this.gi4_1 = true;
    this.hi4_1 = new ViewInfo();
    this.ji4_1 = 0;
    this.ki4_1 = 0;
    this.li4_1 = Transform.iia();
    this.mi4_1 = Transform.iia();
    this.ni4_1 = new Sweep();
    this.oi4_1 = new Vec2();
    this.pi4_1 = 0.0;
    this.qi4_1 = new Vec2();
    this.ri4_1 = 0.0;
    this.si4_1 = null;
    this.ti4_1 = null;
    this.ui4_1 = null;
    this.vi4_1 = 0;
    this.wi4_1 = null;
    this.xi4_1 = null;
    this.yi4_1 = 0;
    this.zi4_1 = 0;
    this.ai5_1 = 0;
    this.bi5_1 = 0;
    this.ci5_1 = 0;
    this.di5_1 = 0;
    this.ei5_1 = 0;
    this.fi5_1 = 0;
    this.gi5_1 = null;
    this.hi5_1 = new FixtureDef();
    this.ii5_1 = MassData.yim();
    this.ji5_1 = Transform.iia();
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!bd.xip_1.yi7()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!bd.zip_1.yi7()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_1;
    if (!(bd.iiq_1 >= 0.0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_1 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_1));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_2;
    if (!(bd.ciq_1 >= 0.0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_2 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_2));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_3;
    if (!(bd.biq_1 >= 0.0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_3 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_3));
    }
    this.ji4_1 = 0;
    if (bd.giq_1) {
      this.ji4_1 = this.ji4_1 | Companion_instance_14.qip_1;
    }
    if (bd.fiq_1) {
      this.ji4_1 = this.ji4_1 | Companion_instance_14.rip_1;
    }
    if (bd.diq_1) {
      this.ji4_1 = this.ji4_1 | Companion_instance_14.pip_1;
    }
    if (bd.eiq_1) {
      this.ji4_1 = this.ji4_1 | Companion_instance_14.oip_1;
    }
    if (bd.hiq_1) {
      this.ji4_1 = this.ji4_1 | Companion_instance_14.sip_1;
    }
    this.li4_1.gia_1.via(bd.xip_1);
    this.li4_1.hia_1.nio(bd.yip_1);
    this.ni4_1.oii_1.ki6();
    this.ni4_1.pii_1.via(this.li4_1.gia_1);
    this.ni4_1.qii_1.via(this.li4_1.gia_1);
    this.ni4_1.rii_1 = bd.yip_1;
    this.ni4_1.sii_1 = bd.yip_1;
    this.ni4_1.tii_1 = 0.0;
    this.wi4_1 = null;
    this.xi4_1 = null;
    this.si4_1 = null;
    this.ti4_1 = null;
    this.oi4_1.via(bd.zip_1);
    this.pi4_1 = bd.aiq_1;
    this.ci5_1 = bd.biq_1;
    this.di5_1 = bd.ciq_1;
    this.ei5_1 = bd.iiq_1;
    this.qi4_1.ki6();
    this.ri4_1 = 0.0;
    this.fi5_1 = 0.0;
    this.ii4_1 = bd.vip_1;
    if (this.ii4_1 === BodyType_DYNAMIC_getInstance()) {
      this.yi4_1 = 1.0;
      this.zi4_1 = 1.0;
    } else {
      this.yi4_1 = 0.0;
      this.zi4_1 = 0.0;
    }
    this.ai5_1 = 0.0;
    this.bi5_1 = 0.0;
    this.gi5_1 = bd.wip_1;
    this.ui4_1 = null;
    this.vi4_1 = 0;
  }
  xi5(key) {
    return this.di4_1.xi5(key);
  }
  wi5(key, value) {
    this.di4_1.wi5(key, value);
  }
  n5w() {
    return this.li4_1;
  }
  kiq() {
    return this.ti4_1;
  }
  liq() {
    return this.xi4_1;
  }
  miq() {
    return this.yi4_1;
  }
  q1q() {
    return this.li4_1.gia_1;
  }
  niq() {
    return this.ni4_1.sii_1;
  }
  o2s() {
    return get_radians(this.niq());
  }
  oiq() {
    return this.ni4_1.qii_1;
  }
  piq() {
    return this.ni4_1.oii_1;
  }
  li5(v) {
    if (this.ii4_1 === BodyType_STATIC_getInstance()) {
      return Unit_instance;
    }
    if (Companion_instance_12.yia(v, v) > 0.0) {
      this.oi5(true);
    }
    this.oi4_1.via(v);
  }
  mi5(w) {
    if (this.ii4_1 === BodyType_STATIC_getInstance()) {
      return Unit_instance;
    }
    if (w * w > 0.0) {
      this.oi5(true);
    }
    this.pi4_1 = w;
  }
  qiq() {
    return this.ai5_1 + this.yi4_1 * (this.ni4_1.oii_1.ai4_1 * this.ni4_1.oii_1.ai4_1 + this.ni4_1.oii_1.bi4_1 * this.ni4_1.oii_1.bi4_1);
  }
  b2n() {
    return this.ii4_1;
  }
  riq() {
    return (this.ji4_1 & Companion_instance_14.qip_1) === Companion_instance_14.qip_1;
  }
  oi5(flag) {
    if (flag) {
      if ((this.ji4_1 & Companion_instance_14.oip_1) === 0) {
        this.ji4_1 = this.ji4_1 | Companion_instance_14.oip_1;
        this.fi5_1 = 0.0;
      }
    } else {
      this.ji4_1 = this.ji4_1 & ~Companion_instance_14.oip_1;
      this.fi5_1 = 0.0;
      this.oi4_1.ki6();
      this.pi4_1 = 0.0;
      this.qi4_1.ki6();
      this.ri4_1 = 0.0;
    }
  }
  siq() {
    return (this.ji4_1 & Companion_instance_14.oip_1) === Companion_instance_14.oip_1;
  }
  ni5(flag) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!this.ei4_1.s1m()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    if (flag === this.d15()) {
      return Unit_instance;
    }
    if (flag) {
      this.ji4_1 = this.ji4_1 | Companion_instance_14.sip_1;
      var broadPhase = this.ei4_1.bi2_1.uiq_1;
      var f = this.ui4_1;
      while (!(f == null)) {
        f.fir(broadPhase, this.li4_1);
        f = f.pi6_1;
      }
    } else {
      this.ji4_1 = this.ji4_1 & ~Companion_instance_14.sip_1;
      var broadPhase_0 = this.ei4_1.bi2_1.uiq_1;
      var f_0 = this.ui4_1;
      while (!(f_0 == null)) {
        f_0.ziq(broadPhase_0);
        f_0 = f_0.pi6_1;
      }
      var ce = this.xi4_1;
      while (!(ce == null)) {
        var ce0 = ce;
        ce = ce.dir_1;
        this.ei4_1.bi2_1.eir(ensureNotNull(ce0.bir_1));
      }
      this.xi4_1 = null;
    }
  }
  d15() {
    return (this.ji4_1 & Companion_instance_14.sip_1) === Companion_instance_14.sip_1;
  }
  gir(def) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!this.ei4_1.s1m()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    if (this.ei4_1.s1m()) {
      return null;
    }
    var fixture = new Fixture();
    fixture.hir(this, def);
    if ((this.ji4_1 & Companion_instance_14.sip_1) === Companion_instance_14.sip_1) {
      var broadPhase = this.ei4_1.bi2_1.uiq_1;
      fixture.fir(broadPhase, this.li4_1);
    }
    fixture.pi6_1 = this.ui4_1;
    this.ui4_1 = fixture;
    this.vi4_1 = this.vi4_1 + 1 | 0;
    fixture.qi6_1 = this;
    if (fixture.oi6_1 > 0.0) {
      this.iir();
    }
    this.ei4_1.ai2_1 = this.ei4_1.ai2_1 | Companion_instance_17.lir_1;
    return fixture;
  }
  pir(position, angleRadians) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!position.yi7()) {
      // Inline function 'org.jbox2d.dynamics.Body.setTransformRadians.<anonymous>' call
      var message = 'Invalid position ' + position.toString();
      throw IllegalArgumentException.l(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!isFinite(angleRadians)) {
      // Inline function 'org.jbox2d.dynamics.Body.setTransformRadians.<anonymous>' call
      var message_0 = 'Invalid angleRadians ' + angleRadians;
      throw IllegalArgumentException.l(toString(message_0));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!this.ei4_1.s1m()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_1 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_1));
    }
    if (this.ei4_1.s1m()) {
      return Unit_instance;
    }
    this.li4_1.hia_1.nio(angleRadians);
    this.li4_1.gia_1.via(position);
    Companion_instance_11.lia(this.li4_1, this.ni4_1.oii_1, this.ni4_1.qii_1);
    this.ni4_1.sii_1 = angleRadians;
    this.ni4_1.pii_1.via(this.ni4_1.qii_1);
    this.ni4_1.rii_1 = this.ni4_1.sii_1;
    var broadPhase = this.ei4_1.bi2_1.uiq_1;
    var f = this.ui4_1;
    while (!(f == null)) {
      f.qir(broadPhase, this.li4_1, this.li4_1);
      f = f.pi6_1;
    }
  }
  ki5(position, angle) {
    return this.pir(position, _Angle___get_radians__impl__n00yt5(angle));
  }
  rir(impulse, point, wake) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!point.yi7()) {
      // Inline function 'org.jbox2d.dynamics.Body.applyLinearImpulse.<anonymous>' call
      var message = 'Invalid point ' + point.toString();
      throw IllegalArgumentException.l(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!impulse.yi7()) {
      // Inline function 'org.jbox2d.dynamics.Body.applyLinearImpulse.<anonymous>' call
      var message_0 = 'Invalid impulse ' + impulse.toString();
      throw IllegalArgumentException.l(toString(message_0));
    }
    if (!(this.ii4_1 === BodyType_DYNAMIC_getInstance())) {
      return Unit_instance;
    }
    if (!this.siq()) {
      if (wake) {
        this.oi5(true);
      } else {
        return Unit_instance;
      }
    }
    var _receiver__tnumb7 = this.oi4_1;
    _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 + impulse.ai4_1 * this.zi4_1;
    var _receiver__tnumb7_0 = this.oi4_1;
    _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 + impulse.bi4_1 * this.zi4_1;
    this.pi4_1 = this.pi4_1 + this.bi5_1 * ((point.ai4_1 - this.ni4_1.qii_1.ai4_1) * impulse.bi4_1 - (point.bi4_1 - this.ni4_1.qii_1.bi4_1) * impulse.ai4_1);
  }
  iir() {
    this.yi4_1 = 0.0;
    this.zi4_1 = 0.0;
    this.ai5_1 = 0.0;
    this.bi5_1 = 0.0;
    this.ni4_1.oii_1.ki6();
    if (this.ii4_1 === BodyType_STATIC_getInstance() || this.ii4_1 === BodyType_KINEMATIC_getInstance()) {
      this.ni4_1.pii_1.via(this.li4_1.gia_1);
      this.ni4_1.qii_1.via(this.li4_1.gia_1);
      this.ni4_1.rii_1 = this.ni4_1.sii_1;
      return Unit_instance;
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.ii4_1 === BodyType_DYNAMIC_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var localCenter = this.ei4_1.vi1_1.sir();
    localCenter.ki6();
    var temp = this.ei4_1.vi1_1.sir();
    var massData = this.ii5_1;
    var f = this.ui4_1;
    $l$loop: while (!(f == null)) {
      if (f.oi6_1 === 0.0) {
        f = f.pi6_1;
        continue $l$loop;
      }
      f.tir(massData);
      this.yi4_1 = this.yi4_1 + massData.uim_1;
      temp.via(massData.vim_1).zie(massData.uim_1);
      localCenter.aif(temp);
      this.ai5_1 = this.ai5_1 + massData.wim_1;
      f = f.pi6_1;
    }
    if (this.yi4_1 > 0.0) {
      this.zi4_1 = 1.0 / this.yi4_1;
      localCenter.zie(this.zi4_1);
    } else {
      this.yi4_1 = 1.0;
      this.zi4_1 = 1.0;
    }
    if (this.ai5_1 > 0.0 && (this.ji4_1 & Companion_instance_14.rip_1) === 0) {
      this.ai5_1 = this.ai5_1 - this.yi4_1 * Companion_instance_12.yia(localCenter, localCenter);
      // Inline function 'org.jbox2d.internal.assert' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp_0;
      if (!(this.ai5_1 > 0.0)) {
        // Inline function 'kotlin.check.<anonymous>' call
        var message_0 = 'Check failed.';
        throw IllegalStateException.m5(toString(message_0));
      }
      this.bi5_1 = 1.0 / this.ai5_1;
    } else {
      this.ai5_1 = 0.0;
      this.bi5_1 = 0.0;
    }
    var oldCenter = this.ei4_1.vi1_1.sir();
    oldCenter.via(this.ni4_1.qii_1);
    this.ni4_1.oii_1.via(localCenter);
    Companion_instance_11.lia(this.li4_1, this.ni4_1.oii_1, this.ni4_1.pii_1);
    this.ni4_1.qii_1.via(this.ni4_1.pii_1);
    temp.via(this.ni4_1.qii_1).wia(oldCenter);
    var temp2 = oldCenter;
    Companion_instance_12.jig(this.pi4_1, temp, temp2);
    this.oi4_1.aif(temp2);
    this.ei4_1.vi1_1.uir(3);
  }
  vir() {
    var xf1 = this.ji5_1;
    xf1.hia_1.mie_1 = Companion_getInstance_11().vin(this.ni4_1.rii_1);
    xf1.hia_1.nie_1 = Companion_getInstance_11().aio(this.ni4_1.rii_1);
    xf1.gia_1.ai4_1 = this.ni4_1.pii_1.ai4_1 - xf1.hia_1.nie_1 * this.ni4_1.oii_1.ai4_1 + xf1.hia_1.mie_1 * this.ni4_1.oii_1.bi4_1;
    xf1.gia_1.bi4_1 = this.ni4_1.pii_1.bi4_1 - xf1.hia_1.mie_1 * this.ni4_1.oii_1.ai4_1 - xf1.hia_1.nie_1 * this.ni4_1.oii_1.bi4_1;
    var f = this.ui4_1;
    while (!(f == null)) {
      f.qir(this.ei4_1.bi2_1.uiq_1, xf1, this.li4_1);
      f = f.pi6_1;
    }
  }
  wir() {
    this.li4_1.hia_1.mie_1 = Companion_getInstance_11().vin(this.ni4_1.sii_1);
    this.li4_1.hia_1.nie_1 = Companion_getInstance_11().aio(this.ni4_1.sii_1);
    var q = this.li4_1.hia_1;
    var v = this.ni4_1.oii_1;
    this.li4_1.gia_1.ai4_1 = this.ni4_1.qii_1.ai4_1 - q.nie_1 * v.ai4_1 + q.mie_1 * v.bi4_1;
    this.li4_1.gia_1.bi4_1 = this.ni4_1.qii_1.bi4_1 - q.mie_1 * v.ai4_1 - q.nie_1 * v.bi4_1;
  }
  xir(other) {
    if (!(this.ii4_1 === BodyType_DYNAMIC_getInstance()) && !(other.ii4_1 === BodyType_DYNAMIC_getInstance())) {
      return false;
    }
    var jn = this.wi4_1;
    while (!(jn == null)) {
      if (jn.yir_1 === other) {
        if (!ensureNotNull(jn.zir_1).ois()) {
          return false;
        }
      }
      jn = jn.bis_1;
    }
    return true;
  }
  dip(t) {
    this.ni4_1.dip(t);
    this.ni4_1.qii_1.via(this.ni4_1.pii_1);
    this.ni4_1.sii_1 = this.ni4_1.rii_1;
    this.li4_1.hia_1.nio(this.ni4_1.sii_1);
    Companion_instance_10.aib(this.li4_1.hia_1, this.ni4_1.oii_1, this.li4_1.gia_1);
    this.li4_1.gia_1.zie(-1.0).aif(this.ni4_1.qii_1);
  }
}
class BodyDef {
  constructor(type, userData, position, angleRadians, linearVelocity, angularVelocity, linearDamping, angularDamping, allowSleep, awake, fixedRotation, bullet, active, gravityScale) {
    type = type === VOID ? BodyType_STATIC_getInstance() : type;
    userData = userData === VOID ? null : userData;
    position = position === VOID ? new Vec2() : position;
    angleRadians = angleRadians === VOID ? 0.0 : angleRadians;
    linearVelocity = linearVelocity === VOID ? new Vec2() : linearVelocity;
    angularVelocity = angularVelocity === VOID ? 0.0 : angularVelocity;
    linearDamping = linearDamping === VOID ? 0.0 : linearDamping;
    angularDamping = angularDamping === VOID ? 0.0 : angularDamping;
    allowSleep = allowSleep === VOID ? true : allowSleep;
    awake = awake === VOID ? true : awake;
    fixedRotation = fixedRotation === VOID ? false : fixedRotation;
    bullet = bullet === VOID ? false : bullet;
    active = active === VOID ? true : active;
    gravityScale = gravityScale === VOID ? 1.0 : gravityScale;
    this.uip_1 = new Mixin();
    this.vip_1 = type;
    this.wip_1 = userData;
    this.xip_1 = position;
    this.yip_1 = angleRadians;
    this.zip_1 = linearVelocity;
    this.aiq_1 = angularVelocity;
    this.biq_1 = linearDamping;
    this.ciq_1 = angularDamping;
    this.diq_1 = allowSleep;
    this.eiq_1 = awake;
    this.fiq_1 = fixedRotation;
    this.giq_1 = bullet;
    this.hiq_1 = active;
    this.iiq_1 = gravityScale;
  }
  pis(value) {
    this.yip_1 = _Angle___get_radians__impl__n00yt5(value);
  }
  toString() {
    return 'BodyDef(type=' + this.vip_1.toString() + ', userData=' + toString_0(this.wip_1) + ', position=' + this.xip_1.toString() + ', angleRadians=' + this.yip_1 + ', linearVelocity=' + this.zip_1.toString() + ', angularVelocity=' + this.aiq_1 + ', linearDamping=' + this.biq_1 + ', angularDamping=' + this.ciq_1 + ', allowSleep=' + this.diq_1 + ', awake=' + this.eiq_1 + ', fixedRotation=' + this.fiq_1 + ', bullet=' + this.giq_1 + ', active=' + this.hiq_1 + ', gravityScale=' + this.iiq_1 + ')';
  }
  hashCode() {
    var result = this.vip_1.hashCode();
    result = imul(result, 31) + (this.wip_1 == null ? 0 : hashCode(this.wip_1)) | 0;
    result = imul(result, 31) + this.xip_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.yip_1) | 0;
    result = imul(result, 31) + this.zip_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.aiq_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.biq_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ciq_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.diq_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.eiq_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.fiq_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.giq_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.hiq_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.iiq_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof BodyDef))
      return false;
    var tmp0_other_with_cast = other instanceof BodyDef ? other : THROW_CCE();
    if (!this.vip_1.equals(tmp0_other_with_cast.vip_1))
      return false;
    if (!equals(this.wip_1, tmp0_other_with_cast.wip_1))
      return false;
    if (!this.xip_1.equals(tmp0_other_with_cast.xip_1))
      return false;
    if (!equals(this.yip_1, tmp0_other_with_cast.yip_1))
      return false;
    if (!this.zip_1.equals(tmp0_other_with_cast.zip_1))
      return false;
    if (!equals(this.aiq_1, tmp0_other_with_cast.aiq_1))
      return false;
    if (!equals(this.biq_1, tmp0_other_with_cast.biq_1))
      return false;
    if (!equals(this.ciq_1, tmp0_other_with_cast.ciq_1))
      return false;
    if (!(this.diq_1 === tmp0_other_with_cast.diq_1))
      return false;
    if (!(this.eiq_1 === tmp0_other_with_cast.eiq_1))
      return false;
    if (!(this.fiq_1 === tmp0_other_with_cast.fiq_1))
      return false;
    if (!(this.giq_1 === tmp0_other_with_cast.giq_1))
      return false;
    if (!(this.hiq_1 === tmp0_other_with_cast.hiq_1))
      return false;
    if (!equals(this.iiq_1, tmp0_other_with_cast.iiq_1))
      return false;
    return true;
  }
}
class Companion_15 {
  constructor() {
    Companion_instance_15 = this;
    var tmp = this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = values_0();
    var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.wc(capacity);
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'org.jbox2d.dynamics.Companion.BY_NAME.<anonymous>' call
      // Inline function 'kotlin.text.toUpperCase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$2 = element.u3_1.toUpperCase();
      destination.r3(tmp$ret$2, element);
    }
    tmp.qis_1 = destination;
  }
}
class BodyType extends Enum {}
class ContactManager {
  constructor(pool, m_broadPhase) {
    this.tiq_1 = pool;
    this.uiq_1 = m_broadPhase;
    this.viq_1 = null;
    this.wiq_1 = 0;
    this.xiq_1 = new ContactFilter();
    this.yiq_1 = null;
  }
  eil(proxyUserDataA, proxyUserDataB) {
    var proxyA = (proxyUserDataA == null ? true : proxyUserDataA instanceof FixtureProxy) ? proxyUserDataA : THROW_CCE();
    var proxyB = (proxyUserDataB == null ? true : proxyUserDataB instanceof FixtureProxy) ? proxyUserDataB : THROW_CCE();
    var fixtureA = ensureNotNull(proxyA).sis_1;
    var fixtureB = ensureNotNull(proxyB).sis_1;
    var indexA = proxyA.tis_1;
    var indexB = proxyB.tis_1;
    var bodyA = ensureNotNull(fixtureA).vis();
    var bodyB = ensureNotNull(fixtureB).vis();
    if (equals(bodyA, bodyB)) {
      return Unit_instance;
    }
    var edge = ensureNotNull(bodyB).liq();
    while (!(edge == null)) {
      if (equals(edge.air_1, bodyA)) {
        var fA = ensureNotNull(edge.bir_1).nit();
        var fB = ensureNotNull(edge.bir_1).oit();
        var iA = ensureNotNull(edge.bir_1).pit();
        var iB = ensureNotNull(edge.bir_1).qit();
        if (equals(fA, fixtureA) && iA === indexA && equals(fB, fixtureB) && iB === indexB) {
          return Unit_instance;
        }
        if (equals(fA, fixtureB) && iA === indexB && equals(fB, fixtureA) && iB === indexA) {
          return Unit_instance;
        }
      }
      edge = edge.dir_1;
    }
    if (!bodyB.xir(ensureNotNull(bodyA))) {
      return Unit_instance;
    }
    if (!(this.xiq_1 == null) && !ensureNotNull(this.xiq_1).mi6(fixtureA, fixtureB)) {
      return Unit_instance;
    }
    var tmp0_elvis_lhs = this.tiq_1.rit(fixtureA, indexA, fixtureB, indexB);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var c = tmp;
    fixtureA = c.nit();
    fixtureB = c.oit();
    indexA = c.pit();
    indexB = c.qit();
    bodyA = ensureNotNull(fixtureA).vis();
    bodyB = ensureNotNull(fixtureB).vis();
    c.yis_1 = null;
    c.zis_1 = this.viq_1;
    if (!(this.viq_1 == null)) {
      ensureNotNull(this.viq_1).yis_1 = c;
    }
    this.viq_1 = c;
    c.ait_1.bir_1 = c;
    c.ait_1.air_1 = bodyB;
    c.ait_1.cir_1 = null;
    c.ait_1.dir_1 = ensureNotNull(bodyA).xi4_1;
    if (!(bodyA.xi4_1 == null)) {
      ensureNotNull(bodyA.xi4_1).cir_1 = c.ait_1;
    }
    bodyA.xi4_1 = c.ait_1;
    c.bit_1.bir_1 = c;
    c.bit_1.air_1 = bodyA;
    c.bit_1.cir_1 = null;
    c.bit_1.dir_1 = ensureNotNull(bodyB).xi4_1;
    if (!(bodyB.xi4_1 == null)) {
      ensureNotNull(bodyB.xi4_1).cir_1 = c.bit_1;
    }
    bodyB.xi4_1 = c.bit_1;
    if (!fixtureA.sit() && !fixtureB.sit()) {
      bodyA.oi5(true);
      bodyB.oi5(true);
    }
    this.wiq_1 = this.wiq_1 + 1 | 0;
  }
  tit() {
    this.uiq_1.cil(this);
  }
  eir(c) {
    var fixtureA = c.nit();
    var fixtureB = c.oit();
    var bodyA = ensureNotNull(fixtureA).vis();
    var bodyB = ensureNotNull(fixtureB).vis();
    if (!(this.yiq_1 == null) && c.vit()) {
      ensureNotNull(this.yiq_1).uit(c);
    }
    if (!(c.yis_1 == null)) {
      ensureNotNull(c.yis_1).zis_1 = c.zis_1;
    }
    if (!(c.zis_1 == null)) {
      ensureNotNull(c.zis_1).yis_1 = c.yis_1;
    }
    if (c === this.viq_1) {
      this.viq_1 = c.zis_1;
    }
    if (!(c.ait_1.cir_1 == null)) {
      ensureNotNull(c.ait_1.cir_1).dir_1 = c.ait_1.dir_1;
    }
    if (!(c.ait_1.dir_1 == null)) {
      ensureNotNull(c.ait_1.dir_1).cir_1 = c.ait_1.cir_1;
    }
    if (equals(c.ait_1, ensureNotNull(bodyA).xi4_1)) {
      bodyA.xi4_1 = c.ait_1.dir_1;
    }
    if (!(c.bit_1.cir_1 == null)) {
      ensureNotNull(c.bit_1.cir_1).dir_1 = c.bit_1.dir_1;
    }
    if (!(c.bit_1.dir_1 == null)) {
      ensureNotNull(c.bit_1.dir_1).cir_1 = c.bit_1.cir_1;
    }
    if (equals(c.bit_1, ensureNotNull(bodyB).xi4_1)) {
      bodyB.xi4_1 = c.bit_1.dir_1;
    }
    this.tiq_1.wit(c);
    this.wiq_1 = this.wiq_1 - 1 | 0;
  }
  xit() {
    var c = this.viq_1;
    $l$loop_2: while (!(c == null)) {
      var fixtureA = c.nit();
      var fixtureB = c.oit();
      var indexA = c.pit();
      var indexB = c.qit();
      var bodyA = ensureNotNull(fixtureA).vis();
      var bodyB = ensureNotNull(fixtureB).vis();
      if ((c.xis_1 & Companion_instance_18.biu_1) === Companion_instance_18.biu_1) {
        if (!ensureNotNull(bodyB).xir(ensureNotNull(bodyA))) {
          var cNuke = c;
          c = cNuke.kiq();
          this.eir(cNuke);
          continue $l$loop_2;
        }
        if (!(this.xiq_1 == null) && !ensureNotNull(this.xiq_1).mi6(fixtureA, fixtureB)) {
          var cNuke_0 = c;
          c = cNuke_0.kiq();
          this.eir(cNuke_0);
          continue $l$loop_2;
        }
        c.xis_1 = c.xis_1 & ~Companion_instance_18.biu_1;
      }
      var activeA = ensureNotNull(bodyA).siq() && !(bodyA.ii4_1 === BodyType_STATIC_getInstance());
      var activeB = ensureNotNull(bodyB).siq() && !(bodyB.ii4_1 === BodyType_STATIC_getInstance());
      if (!activeA && !activeB) {
        c = c.kiq();
        continue $l$loop_2;
      }
      var proxyIdA = ensureNotNull(fixtureA.ui6_1)[indexA].uis_1;
      var proxyIdB = ensureNotNull(fixtureB.ui6_1)[indexB].uis_1;
      var overlap = this.uiq_1.ail(proxyIdA, proxyIdB);
      if (!overlap) {
        var cNuke_1 = c;
        c = cNuke_1.kiq();
        this.eir(cNuke_1);
        continue $l$loop_2;
      }
      c.eiu(this.yiq_1);
      c = c.kiq();
    }
  }
}
class Filter {
  constructor() {
    this.di7_1 = 1;
    this.ei7_1 = 65535;
    this.fi7_1 = 0;
  }
  fiu(argOther) {
    this.di7_1 = argOther.di7_1;
    this.ei7_1 = argOther.ei7_1;
    this.fi7_1 = argOther.fi7_1;
  }
}
class Fixture {
  constructor() {
    this.ni6_1 = new Mixin();
    this.oi6_1 = 0;
    this.pi6_1 = null;
    this.qi6_1 = null;
    this.ri6_1 = null;
    this.si6_1 = 0;
    this.ti6_1 = 0;
    this.ui6_1 = null;
    this.vi6_1 = 0;
    this.wi6_1 = new Filter();
    this.xi6_1 = false;
    this.yi6_1 = null;
    this.zi6_1 = AABB.ai8();
    this.ai7_1 = AABB.ai8();
    this.bi7_1 = new Vec2();
  }
  vis() {
    return this.qi6_1;
  }
  giu() {
    return this.ri6_1;
  }
  b2n() {
    return ensureNotNull(this.ri6_1).oig();
  }
  sit() {
    return this.xi6_1;
  }
  ci7() {
    return this.wi6_1;
  }
  hiu(output, input, childIndex) {
    return ensureNotNull(this.ri6_1).rim(output, input, ensureNotNull(this.qi6_1).li4_1, childIndex);
  }
  tir(massData) {
    ensureNotNull(this.ri6_1).tim(massData, this.oi6_1);
  }
  iiu(childIndex) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(childIndex >= 0 && childIndex < this.vi6_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    return ensureNotNull(this.ui6_1)[childIndex].ris_1;
  }
  jiu(p, childIndex, normalOut) {
    return ensureNotNull(this.ri6_1).pim(ensureNotNull(this.qi6_1).n5w(), p, childIndex, normalOut);
  }
  hir(body, def) {
    this.yi6_1 = def.miu_1;
    this.si6_1 = def.niu_1;
    this.ti6_1 = def.oiu_1;
    this.qi6_1 = body;
    this.pi6_1 = null;
    this.wi6_1.fiu(def.riu_1);
    this.xi6_1 = def.qiu_1;
    this.ri6_1 = ensureNotNull(def.liu_1).j13();
    var childCount = ensureNotNull(this.ri6_1).oim();
    if (this.ui6_1 == null) {
      var tmp = this;
      var tmp_0 = 0;
      // Inline function 'kotlin.arrayOfNulls' call
      var tmp_1 = fillArrayVal(Array(childCount), null);
      while (tmp_0 < childCount) {
        tmp_1[tmp_0] = new FixtureProxy();
        tmp_0 = tmp_0 + 1 | 0;
      }
      tmp.ui6_1 = tmp_1;
      var inductionVariable = 0;
      if (inductionVariable < childCount)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          ensureNotNull(this.ui6_1)[i].sis_1 = null;
          ensureNotNull(this.ui6_1)[i].uis_1 = Companion_instance_3.jik_1;
        }
         while (inductionVariable < childCount);
    }
    if (ensureNotNull(this.ui6_1).length < childCount) {
      var old = this.ui6_1;
      var newLen = Companion_getInstance_11().eih(imul(ensureNotNull(old).length, 2), childCount);
      var tmp_2 = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var tmp_3 = fillArrayVal(Array(newLen), null);
      tmp_2.ui6_1 = isArray(tmp_3) ? tmp_3 : THROW_CCE();
      // Inline function 'org.jbox2d.internal.arraycopy' call
      var dst = ensureNotNull(this.ui6_1);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.copyInto' call
      var endIndex = 0 + old.length | 0;
      arrayCopy(old, dst, 0, 0, endIndex);
      var inductionVariable_0 = 0;
      if (inductionVariable_0 < newLen)
        do {
          var i_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (i_0 >= old.length) {
            ensureNotNull(this.ui6_1)[i_0] = new FixtureProxy();
          }
          ensureNotNull(this.ui6_1)[i_0].sis_1 = null;
          ensureNotNull(this.ui6_1)[i_0].uis_1 = Companion_instance_3.jik_1;
        }
         while (inductionVariable_0 < newLen);
    }
    this.vi6_1 = 0;
    this.oi6_1 = def.piu_1;
  }
  siu() {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.vi6_1 === 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    this.ri6_1 = null;
    this.ui6_1 = null;
    this.pi6_1 = null;
  }
  fir(broadPhase, xf) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.vi6_1 === 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    this.vi6_1 = ensureNotNull(this.ri6_1).oim();
    var inductionVariable = 0;
    var last = this.vi6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var proxy = ensureNotNull(this.ui6_1)[i];
        ensureNotNull(this.ri6_1).sim(proxy.ris_1, xf, i);
        proxy.uis_1 = broadPhase.tik(proxy.ris_1, proxy);
        proxy.sis_1 = this;
        proxy.tis_1 = i;
      }
       while (inductionVariable < last);
  }
  ziq(broadPhase) {
    var inductionVariable = 0;
    var last = this.vi6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var proxy = ensureNotNull(this.ui6_1)[i];
        broadPhase.vik(proxy.uis_1);
        proxy.uis_1 = Companion_instance_3.jik_1;
      }
       while (inductionVariable < last);
    this.vi6_1 = 0;
  }
  qir(broadPhase, transform1, transform2) {
    if (this.vi6_1 === 0) {
      return Unit_instance;
    }
    var inductionVariable = 0;
    var last = this.vi6_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var proxy = ensureNotNull(this.ui6_1)[i];
        var aabb1 = this.zi6_1;
        var aab = this.ai7_1;
        ensureNotNull(this.ri6_1).sim(aabb1, transform1, proxy.tis_1);
        ensureNotNull(this.ri6_1).sim(aab, transform2, proxy.tis_1);
        proxy.ris_1.wi7_1.ai4_1 = aabb1.wi7_1.ai4_1 < aab.wi7_1.ai4_1 ? aabb1.wi7_1.ai4_1 : aab.wi7_1.ai4_1;
        proxy.ris_1.wi7_1.bi4_1 = aabb1.wi7_1.bi4_1 < aab.wi7_1.bi4_1 ? aabb1.wi7_1.bi4_1 : aab.wi7_1.bi4_1;
        proxy.ris_1.xi7_1.ai4_1 = aabb1.xi7_1.ai4_1 > aab.xi7_1.ai4_1 ? aabb1.xi7_1.ai4_1 : aab.xi7_1.ai4_1;
        proxy.ris_1.xi7_1.bi4_1 = aabb1.xi7_1.bi4_1 > aab.xi7_1.bi4_1 ? aabb1.xi7_1.bi4_1 : aab.xi7_1.bi4_1;
        this.bi7_1.ai4_1 = transform2.gia_1.ai4_1 - transform1.gia_1.ai4_1;
        this.bi7_1.bi4_1 = transform2.gia_1.bi4_1 - transform1.gia_1.bi4_1;
        broadPhase.xik(proxy.uis_1, proxy.ris_1, this.bi7_1);
      }
       while (inductionVariable < last);
  }
}
class FixtureDef {
  constructor(shape, userData, friction, restitution, density, isSensor, filter) {
    shape = shape === VOID ? null : shape;
    userData = userData === VOID ? null : userData;
    friction = friction === VOID ? 0.2 : friction;
    restitution = restitution === VOID ? 0.0 : restitution;
    density = density === VOID ? 0.0 : density;
    isSensor = isSensor === VOID ? false : isSensor;
    filter = filter === VOID ? new Filter() : filter;
    this.kiu_1 = new Mixin();
    this.liu_1 = shape;
    this.miu_1 = userData;
    this.niu_1 = friction;
    this.oiu_1 = restitution;
    this.piu_1 = density;
    this.qiu_1 = isSensor;
    this.riu_1 = filter;
  }
  toString() {
    return 'FixtureDef(shape=' + toString_0(this.liu_1) + ', userData=' + toString_0(this.miu_1) + ', friction=' + this.niu_1 + ', restitution=' + this.oiu_1 + ', density=' + this.piu_1 + ', isSensor=' + this.qiu_1 + ', filter=' + toString(this.riu_1) + ')';
  }
  hashCode() {
    var result = this.liu_1 == null ? 0 : hashCode(this.liu_1);
    result = imul(result, 31) + (this.miu_1 == null ? 0 : hashCode(this.miu_1)) | 0;
    result = imul(result, 31) + getNumberHashCode(this.niu_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.oiu_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.piu_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.qiu_1) | 0;
    result = imul(result, 31) + hashCode(this.riu_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FixtureDef))
      return false;
    var tmp0_other_with_cast = other instanceof FixtureDef ? other : THROW_CCE();
    if (!equals(this.liu_1, tmp0_other_with_cast.liu_1))
      return false;
    if (!equals(this.miu_1, tmp0_other_with_cast.miu_1))
      return false;
    if (!equals(this.niu_1, tmp0_other_with_cast.niu_1))
      return false;
    if (!equals(this.oiu_1, tmp0_other_with_cast.oiu_1))
      return false;
    if (!equals(this.piu_1, tmp0_other_with_cast.piu_1))
      return false;
    if (!(this.qiu_1 === tmp0_other_with_cast.qiu_1))
      return false;
    if (!equals(this.riu_1, tmp0_other_with_cast.riu_1))
      return false;
    return true;
  }
}
class FixtureProxy {
  constructor() {
    this.ris_1 = AABB.ai8();
    this.sis_1 = null;
    this.tis_1 = 0;
    this.uis_1 = 0;
  }
}
class Island {
  constructor() {
    this.tiu_1 = null;
    this.uiu_1 = null;
    this.viu_1 = null;
    this.wiu_1 = null;
    this.xiu_1 = null;
    this.yiu_1 = null;
    this.ziu_1 = 0;
    this.aiv_1 = 0;
    this.biv_1 = 0;
    this.civ_1 = 0;
    this.div_1 = 0;
    this.eiv_1 = 0;
    this.fiv_1 = new ContactSolver();
    this.giv_1 = new Timer();
    this.hiv_1 = new SolverData();
    this.iiv_1 = new ContactSolverDef();
    this.jiv_1 = new ContactSolver();
    this.kiv_1 = new ContactSolverDef();
    this.liv_1 = new ContactImpulse();
  }
  miv(bodyCapacity, contactCapacity, jointCapacity, listener) {
    this.civ_1 = bodyCapacity;
    this.div_1 = contactCapacity;
    this.eiv_1 = jointCapacity;
    this.ziu_1 = 0;
    this.biv_1 = 0;
    this.aiv_1 = 0;
    this.tiu_1 = listener;
    if (this.uiu_1 == null || this.civ_1 > ensureNotNull(this.uiu_1).length) {
      var tmp = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var size = this.civ_1;
      var tmp_0 = fillArrayVal(Array(size), null);
      tmp.uiu_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
    }
    if (this.wiu_1 == null || this.eiv_1 > ensureNotNull(this.wiu_1).length) {
      var tmp_1 = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var size_0 = this.eiv_1;
      var tmp_2 = fillArrayVal(Array(size_0), null);
      tmp_1.wiu_1 = isArray(tmp_2) ? tmp_2 : THROW_CCE();
    }
    if (this.viu_1 == null || this.div_1 > ensureNotNull(this.viu_1).length) {
      var tmp_3 = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var size_1 = this.div_1;
      var tmp_4 = fillArrayVal(Array(size_1), null);
      tmp_3.viu_1 = isArray(tmp_4) ? tmp_4 : THROW_CCE();
    }
    if (this.yiu_1 == null || this.civ_1 > ensureNotNull(this.yiu_1).length) {
      var tmp_5;
      if (this.yiu_1 == null) {
        // Inline function 'kotlin.emptyArray' call
        tmp_5 = [];
      } else {
        tmp_5 = this.yiu_1;
      }
      var old = tmp_5;
      var tmp_6 = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var size_2 = this.civ_1;
      var tmp_7 = fillArrayVal(Array(size_2), null);
      tmp_6.yiu_1 = isArray(tmp_7) ? tmp_7 : THROW_CCE();
      // Inline function 'org.jbox2d.internal.arraycopy' call
      var src = ensureNotNull(old);
      var dst = ensureNotNull(this.yiu_1);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.copyInto' call
      var endIndex = 0 + ensureNotNull(old).length | 0;
      arrayCopy(src, dst, 0, 0, endIndex);
      var inductionVariable = ensureNotNull(old).length;
      var last = ensureNotNull(this.yiu_1).length;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          ensureNotNull(this.yiu_1)[i] = new Velocity();
        }
         while (inductionVariable < last);
    }
    if (this.xiu_1 == null || this.civ_1 > ensureNotNull(this.xiu_1).length) {
      var tmp_8;
      if (this.xiu_1 == null) {
        // Inline function 'kotlin.emptyArray' call
        tmp_8 = [];
      } else {
        tmp_8 = this.xiu_1;
      }
      var old_0 = tmp_8;
      var tmp_9 = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var size_3 = this.civ_1;
      var tmp_10 = fillArrayVal(Array(size_3), null);
      tmp_9.xiu_1 = isArray(tmp_10) ? tmp_10 : THROW_CCE();
      // Inline function 'org.jbox2d.internal.arraycopy' call
      var src_0 = ensureNotNull(old_0);
      var dst_0 = ensureNotNull(this.xiu_1);
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.copyInto' call
      var endIndex_0 = 0 + ensureNotNull(old_0).length | 0;
      arrayCopy(src_0, dst_0, 0, 0, endIndex_0);
      var inductionVariable_0 = ensureNotNull(old_0).length;
      var last_0 = ensureNotNull(this.xiu_1).length;
      if (inductionVariable_0 < last_0)
        do {
          var i_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          ensureNotNull(this.xiu_1)[i_0] = new Position();
        }
         while (inductionVariable_0 < last_0);
    }
  }
  o3() {
    this.ziu_1 = 0;
    this.biv_1 = 0;
    this.aiv_1 = 0;
  }
  niv(profile, step, gravity, allowSleep) {
    var h = step.oiv_1;
    var inductionVariable = 0;
    var last = this.ziu_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var b = ensureNotNull(this.uiu_1)[i];
        var bm_sweep = b.ni4_1;
        var c = bm_sweep.qii_1;
        var a = bm_sweep.sii_1;
        var v = b.oi4_1;
        var w = b.pi4_1;
        bm_sweep.pii_1.via(bm_sweep.qii_1);
        bm_sweep.rii_1 = bm_sweep.sii_1;
        if (b.ii4_1 === BodyType_DYNAMIC_getInstance()) {
          v.ai4_1 = v.ai4_1 + h * (b.ei5_1 * gravity.ai4_1 + b.zi4_1 * b.qi4_1.ai4_1);
          v.bi4_1 = v.bi4_1 + h * (b.ei5_1 * gravity.bi4_1 + b.zi4_1 * b.qi4_1.bi4_1);
          w = w + h * b.bi5_1 * b.ri4_1;
          v.ai4_1 = v.ai4_1 * (1.0 / (1.0 + h * b.ci5_1));
          v.bi4_1 = v.bi4_1 * (1.0 / (1.0 + h * b.ci5_1));
          w = w * (1.0 / (1.0 + h * b.di5_1));
        }
        ensureNotNull(this.xiu_1)[i].uiv_1.ai4_1 = c.ai4_1;
        ensureNotNull(this.xiu_1)[i].uiv_1.bi4_1 = c.bi4_1;
        ensureNotNull(this.xiu_1)[i].viv_1 = a;
        ensureNotNull(this.yiu_1)[i].wiv_1.ai4_1 = v.ai4_1;
        ensureNotNull(this.yiu_1)[i].wiv_1.bi4_1 = v.bi4_1;
        ensureNotNull(this.yiu_1)[i].xiv_1 = w;
      }
       while (inductionVariable < last);
    this.giv_1.z33();
    this.hiv_1.yiv_1 = step;
    this.hiv_1.ziv_1 = this.xiu_1;
    this.hiv_1.aiw_1 = this.yiu_1;
    this.iiv_1.biw_1 = step;
    this.iiv_1.ciw_1 = this.viu_1;
    this.iiv_1.diw_1 = this.biv_1;
    this.iiv_1.eiw_1 = this.xiu_1;
    this.iiv_1.fiw_1 = this.yiu_1;
    this.fiv_1.viw(this.iiv_1);
    this.fiv_1.wiw();
    if (step.tiv_1) {
      this.fiv_1.xiw();
    }
    var inductionVariable_0 = 0;
    var last_0 = this.aiv_1;
    if (inductionVariable_0 < last_0)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        ensureNotNull(this.wiu_1)[i_0].yiw(this.hiv_1);
      }
       while (inductionVariable_0 < last_0);
    profile.eix_1.oix(this.giv_1.fip());
    this.giv_1.z33();
    var inductionVariable_1 = 0;
    var last_1 = step.riv_1;
    if (inductionVariable_1 < last_1)
      do {
        var i_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var inductionVariable_2 = 0;
        var last_2 = this.aiv_1;
        if (inductionVariable_2 < last_2)
          do {
            var j = inductionVariable_2;
            inductionVariable_2 = inductionVariable_2 + 1 | 0;
            ensureNotNull(this.wiu_1)[j].pix(this.hiv_1);
          }
           while (inductionVariable_2 < last_2);
        this.fiv_1.qix();
      }
       while (inductionVariable_1 < last_1);
    this.fiv_1.rix();
    profile.fix_1.oix(this.giv_1.fip());
    var inductionVariable_3 = 0;
    var last_3 = this.ziu_1;
    if (inductionVariable_3 < last_3)
      do {
        var i_2 = inductionVariable_3;
        inductionVariable_3 = inductionVariable_3 + 1 | 0;
        var c_0 = ensureNotNull(this.xiu_1)[i_2].uiv_1;
        var a_0 = ensureNotNull(this.xiu_1)[i_2].viv_1;
        var v_0 = ensureNotNull(this.yiu_1)[i_2].wiv_1;
        var w_0 = ensureNotNull(this.yiu_1)[i_2].xiv_1;
        var translationx = v_0.ai4_1 * h;
        var translationy = v_0.bi4_1 * h;
        if (translationx * translationx + translationy * translationy > Settings_getInstance().vio()) {
          var ratio = Settings_getInstance().uio() / Companion_getInstance_11().qim(translationx * translationx + translationy * translationy);
          v_0.ai4_1 = v_0.ai4_1 * ratio;
          v_0.bi4_1 = v_0.bi4_1 * ratio;
        }
        var rotation = h * w_0;
        if (rotation * rotation > Settings_getInstance().xio()) {
          var ratio_0 = Settings_getInstance().wio() / Companion_getInstance_11().wij(rotation);
          w_0 = w_0 * ratio_0;
        }
        c_0.ai4_1 = c_0.ai4_1 + h * v_0.ai4_1;
        c_0.bi4_1 = c_0.bi4_1 + h * v_0.bi4_1;
        a_0 = a_0 + h * w_0;
        ensureNotNull(this.xiu_1)[i_2].viv_1 = a_0;
        ensureNotNull(this.yiu_1)[i_2].xiv_1 = w_0;
      }
       while (inductionVariable_3 < last_3);
    this.giv_1.z33();
    var positionSolved = false;
    var inductionVariable_4 = 0;
    var last_4 = step.siv_1;
    if (inductionVariable_4 < last_4)
      $l$loop: do {
        var i_3 = inductionVariable_4;
        inductionVariable_4 = inductionVariable_4 + 1 | 0;
        var contactsOkay = this.fiv_1.six();
        var jointsOkay = true;
        var inductionVariable_5 = 0;
        var last_5 = this.aiv_1;
        if (inductionVariable_5 < last_5)
          do {
            var j_0 = inductionVariable_5;
            inductionVariable_5 = inductionVariable_5 + 1 | 0;
            var jointOkay = ensureNotNull(this.wiu_1)[j_0].tix(this.hiv_1);
            jointsOkay = (jointsOkay && jointOkay);
          }
           while (inductionVariable_5 < last_5);
        if (contactsOkay && jointsOkay) {
          positionSolved = true;
          break $l$loop;
        }
      }
       while (inductionVariable_4 < last_4);
    var inductionVariable_6 = 0;
    var last_6 = this.ziu_1;
    if (inductionVariable_6 < last_6)
      do {
        var i_4 = inductionVariable_6;
        inductionVariable_6 = inductionVariable_6 + 1 | 0;
        var body = ensureNotNull(this.uiu_1)[i_4];
        body.ni4_1.qii_1.ai4_1 = ensureNotNull(this.xiu_1)[i_4].uiv_1.ai4_1;
        body.ni4_1.qii_1.bi4_1 = ensureNotNull(this.xiu_1)[i_4].uiv_1.bi4_1;
        body.ni4_1.sii_1 = ensureNotNull(this.xiu_1)[i_4].viv_1;
        body.oi4_1.ai4_1 = ensureNotNull(this.yiu_1)[i_4].wiv_1.ai4_1;
        body.oi4_1.bi4_1 = ensureNotNull(this.yiu_1)[i_4].wiv_1.bi4_1;
        body.pi4_1 = ensureNotNull(this.yiu_1)[i_4].xiv_1;
        body.wir();
      }
       while (inductionVariable_6 < last_6);
    profile.gix_1.oix(this.giv_1.fip());
    this.uix(this.fiv_1.kiw_1);
    if (allowSleep) {
      var minSleepTime = 3.4028235E38;
      var linTolSqr = Settings_getInstance().bip() * Settings_getInstance().bip();
      var angTolSqr = Settings_getInstance().cip() * Settings_getInstance().cip();
      var inductionVariable_7 = 0;
      var last_7 = this.ziu_1;
      if (inductionVariable_7 < last_7)
        $l$loop_0: do {
          var i_5 = inductionVariable_7;
          inductionVariable_7 = inductionVariable_7 + 1 | 0;
          var b_0 = ensureNotNull(this.uiu_1)[i_5];
          if (b_0.b2n() === BodyType_STATIC_getInstance()) {
            continue $l$loop_0;
          }
          if ((b_0.ji4_1 & Companion_instance_14.pip_1) === 0 || b_0.pi4_1 * b_0.pi4_1 > angTolSqr || Companion_instance_12.yia(b_0.oi4_1, b_0.oi4_1) > linTolSqr) {
            b_0.fi5_1 = 0.0;
            minSleepTime = 0.0;
          } else {
            b_0.fi5_1 = b_0.fi5_1 + h;
            minSleepTime = Companion_getInstance_11().bic(minSleepTime, b_0.fi5_1);
          }
        }
         while (inductionVariable_7 < last_7);
      if (minSleepTime >= Settings_getInstance().aip() && positionSolved) {
        var inductionVariable_8 = 0;
        var last_8 = this.ziu_1;
        if (inductionVariable_8 < last_8)
          do {
            var i_6 = inductionVariable_8;
            inductionVariable_8 = inductionVariable_8 + 1 | 0;
            var b_1 = ensureNotNull(this.uiu_1)[i_6];
            b_1.oi5(false);
          }
           while (inductionVariable_8 < last_8);
      }
    }
  }
  vix(subStep, toiIndexA, toiIndexB) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(toiIndexA < this.ziu_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(toiIndexB < this.ziu_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    var inductionVariable = 0;
    var last = this.ziu_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        ensureNotNull(this.xiu_1)[i].uiv_1.ai4_1 = ensureNotNull(this.uiu_1)[i].ni4_1.qii_1.ai4_1;
        ensureNotNull(this.xiu_1)[i].uiv_1.bi4_1 = ensureNotNull(this.uiu_1)[i].ni4_1.qii_1.bi4_1;
        ensureNotNull(this.xiu_1)[i].viv_1 = ensureNotNull(this.uiu_1)[i].ni4_1.sii_1;
        ensureNotNull(this.yiu_1)[i].wiv_1.ai4_1 = ensureNotNull(this.uiu_1)[i].oi4_1.ai4_1;
        ensureNotNull(this.yiu_1)[i].wiv_1.bi4_1 = ensureNotNull(this.uiu_1)[i].oi4_1.bi4_1;
        ensureNotNull(this.yiu_1)[i].xiv_1 = ensureNotNull(this.uiu_1)[i].pi4_1;
      }
       while (inductionVariable < last);
    this.kiv_1.ciw_1 = this.viu_1;
    this.kiv_1.diw_1 = this.biv_1;
    this.kiv_1.biw_1 = subStep;
    this.kiv_1.eiw_1 = this.xiu_1;
    this.kiv_1.fiw_1 = this.yiu_1;
    this.jiv_1.viw(this.kiv_1);
    var inductionVariable_0 = 0;
    var last_0 = subStep.siv_1;
    if (inductionVariable_0 < last_0)
      $l$loop: do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var contactsOkay = this.jiv_1.wix(toiIndexA, toiIndexB);
        if (contactsOkay) {
          break $l$loop;
        }
      }
       while (inductionVariable_0 < last_0);
    ensureNotNull(this.uiu_1)[toiIndexA].ni4_1.pii_1.ai4_1 = ensureNotNull(this.xiu_1)[toiIndexA].uiv_1.ai4_1;
    ensureNotNull(this.uiu_1)[toiIndexA].ni4_1.pii_1.bi4_1 = ensureNotNull(this.xiu_1)[toiIndexA].uiv_1.bi4_1;
    ensureNotNull(this.uiu_1)[toiIndexA].ni4_1.rii_1 = ensureNotNull(this.xiu_1)[toiIndexA].viv_1;
    ensureNotNull(this.uiu_1)[toiIndexB].ni4_1.pii_1.via(ensureNotNull(this.xiu_1)[toiIndexB].uiv_1);
    ensureNotNull(this.uiu_1)[toiIndexB].ni4_1.rii_1 = ensureNotNull(this.xiu_1)[toiIndexB].viv_1;
    this.jiv_1.wiw();
    var inductionVariable_1 = 0;
    var last_1 = subStep.riv_1;
    if (inductionVariable_1 < last_1)
      do {
        var i_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        this.jiv_1.qix();
      }
       while (inductionVariable_1 < last_1);
    var h = subStep.oiv_1;
    var inductionVariable_2 = 0;
    var last_2 = this.ziu_1;
    if (inductionVariable_2 < last_2)
      do {
        var i_2 = inductionVariable_2;
        inductionVariable_2 = inductionVariable_2 + 1 | 0;
        var c = ensureNotNull(this.xiu_1)[i_2].uiv_1;
        var a = ensureNotNull(this.xiu_1)[i_2].viv_1;
        var v = ensureNotNull(this.yiu_1)[i_2].wiv_1;
        var w = ensureNotNull(this.yiu_1)[i_2].xiv_1;
        var translationx = v.ai4_1 * h;
        var translationy = v.bi4_1 * h;
        if (translationx * translationx + translationy * translationy > Settings_getInstance().vio()) {
          var ratio = Settings_getInstance().uio() / Companion_getInstance_11().qim(translationx * translationx + translationy * translationy);
          v.zie(ratio);
        }
        var rotation = h * w;
        if (rotation * rotation > Settings_getInstance().xio()) {
          var ratio_0 = Settings_getInstance().wio() / Companion_getInstance_11().wij(rotation);
          w = w * ratio_0;
        }
        c.ai4_1 = c.ai4_1 + v.ai4_1 * h;
        c.bi4_1 = c.bi4_1 + v.bi4_1 * h;
        a = a + h * w;
        ensureNotNull(this.xiu_1)[i_2].uiv_1.ai4_1 = c.ai4_1;
        ensureNotNull(this.xiu_1)[i_2].uiv_1.bi4_1 = c.bi4_1;
        ensureNotNull(this.xiu_1)[i_2].viv_1 = a;
        ensureNotNull(this.yiu_1)[i_2].wiv_1.ai4_1 = v.ai4_1;
        ensureNotNull(this.yiu_1)[i_2].wiv_1.bi4_1 = v.bi4_1;
        ensureNotNull(this.yiu_1)[i_2].xiv_1 = w;
        var body = ensureNotNull(this.uiu_1)[i_2];
        body.ni4_1.qii_1.ai4_1 = c.ai4_1;
        body.ni4_1.qii_1.bi4_1 = c.bi4_1;
        body.ni4_1.sii_1 = a;
        body.oi4_1.ai4_1 = v.ai4_1;
        body.oi4_1.bi4_1 = v.bi4_1;
        body.pi4_1 = w;
        body.wir();
      }
       while (inductionVariable_2 < last_2);
    this.uix(this.jiv_1.kiw_1);
  }
  xix(body) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.ziu_1 < this.civ_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    body.ki4_1 = this.ziu_1;
    ensureNotNull(this.uiu_1)[this.ziu_1] = body;
    this.ziu_1 = this.ziu_1 + 1 | 0;
  }
  yix(contact) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.biv_1 < this.div_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var tmp_0 = ensureNotNull(this.viu_1);
    var _unary__edvuaz = this.biv_1;
    this.biv_1 = _unary__edvuaz + 1 | 0;
    tmp_0[_unary__edvuaz] = contact;
  }
  zix(joint) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.aiv_1 < this.eiv_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var tmp_0 = ensureNotNull(this.wiu_1);
    var _unary__edvuaz = this.aiv_1;
    this.aiv_1 = _unary__edvuaz + 1 | 0;
    tmp_0[_unary__edvuaz] = joint;
  }
  uix(constraints) {
    if (this.tiu_1 == null) {
      return Unit_instance;
    }
    var inductionVariable = 0;
    var last = this.biv_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var c = ensureNotNull(this.viu_1)[i];
        var vc = constraints[i];
        this.liv_1.ii7_1 = vc.niy_1;
        var inductionVariable_0 = 0;
        var last_0 = vc.niy_1;
        if (inductionVariable_0 < last_0)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            this.liv_1.gi7_1[j] = vc.aiy_1[j].riy_1;
            this.liv_1.hi7_1[j] = vc.aiy_1[j].siy_1;
          }
           while (inductionVariable_0 < last_0);
        ensureNotNull(this.tiu_1).wiy(c, this.liv_1);
      }
       while (inductionVariable < last);
  }
}
class ProfileEntry {
  constructor() {
    this.jix_1 = 0;
    this.kix_1 = 0;
    this.lix_1 = 0;
    this.mix_1 = 0;
    this.nix_1 = 0;
    this.lix_1 = 3.4028235E38;
    this.mix_1 = -3.4028235E38;
  }
  xiy(value) {
    this.jix_1 = this.jix_1 * (1 - Companion_getInstance_18().ziy_1) + value * Companion_getInstance_18().ziy_1;
    this.kix_1 = this.kix_1 * (1 - Companion_getInstance_18().biz_1) + value * Companion_getInstance_18().biz_1;
    this.lix_1 = Companion_getInstance_11().bic(value, this.lix_1);
    this.mix_1 = Companion_getInstance_11().wii(value, this.mix_1);
  }
  ciz() {
    this.nix_1 = 0.0;
  }
  oix(value) {
    this.nix_1 = this.nix_1 + value;
  }
  diz() {
    this.xiy(this.nix_1);
  }
  toString() {
    return '' + this.kix_1 + ' (' + this.jix_1 + ') [' + this.lix_1 + ',' + this.mix_1 + ']';
  }
}
class Companion_16 {
  constructor() {
    Companion_instance_16 = this;
    this.yiy_1 = 20;
    this.ziy_1 = 1.0 / this.yiy_1;
    this.aiz_1 = 5;
    this.biz_1 = 1.0 / this.aiz_1;
  }
}
class Profile {
  constructor() {
    Companion_getInstance_18();
    this.ziw_1 = new ProfileEntry();
    this.aix_1 = new ProfileEntry();
    this.bix_1 = new ProfileEntry();
    this.cix_1 = new ProfileEntry();
    this.dix_1 = new ProfileEntry();
    this.eix_1 = new ProfileEntry();
    this.fix_1 = new ProfileEntry();
    this.gix_1 = new ProfileEntry();
    this.hix_1 = new ProfileEntry();
    this.iix_1 = new ProfileEntry();
  }
}
class SolverData {
  constructor() {
    this.yiv_1 = null;
    this.ziv_1 = null;
    this.aiw_1 = null;
  }
}
class TimeStep {
  constructor() {
    this.oiv_1 = 0;
    this.piv_1 = 0;
    this.qiv_1 = 0;
    this.riv_1 = 0;
    this.siv_1 = 0;
    this.tiv_1 = false;
  }
}
class Companion_17 {
  constructor() {
    this.jir_1 = 100;
    this.kir_1 = 10;
    this.lir_1 = 1;
    this.mir_1 = 2;
    this.nir_1 = 4;
    this.oir_1 = 1234598372;
  }
}
class World {
  static qiz(gravity, pool, broadPhase) {
    var $this = createThis(this);
    $this.ui1_1 = new Mixin();
    $this.vi1_1 = pool;
    $this.wi1_1 = 1.0;
    $this.xi1_1 = 0;
    $this.yi1_1 = 0;
    $this.zi1_1 = null;
    $this.ai2_1 = Companion_instance_17.nir_1;
    $this.bi2_1 = new ContactManager($this, broadPhase);
    $this.ci2_1 = null;
    $this.di2_1 = null;
    $this.ei2_1 = 0;
    $this.fi2_1 = 0;
    $this.gi2_1 = new Vec2();
    $this.hi2_1 = true;
    $this.ii2_1 = null;
    $this.ji2_1 = null;
    $this.ki2_1 = null;
    $this.li2_1 = 0.0;
    $this.mi2_1 = true;
    $this.ni2_1 = true;
    $this.oi2_1 = false;
    $this.pi2_1 = true;
    $this.qi2_1 = new Profile();
    $this.ri2_1 = new ParticleSystem($this);
    var tmp = $this;
    var tmp_0 = 0;
    var tmp_1 = values().length;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      // Inline function 'kotlin.arrayOfNulls' call
      var size = values().length;
      var tmp_4 = fillArrayVal(Array(size), null);
      tmp_2[tmp_3] = isArray(tmp_4) ? tmp_4 : THROW_CCE();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.si2_1 = tmp_2;
    $this.ti2_1 = new TimeStep();
    $this.ui2_1 = new Timer();
    $this.vi2_1 = new Timer();
    $this.wi2_1 = Color3f.him();
    $this.xi2_1 = Transform.iia();
    $this.yi2_1 = new Vec2();
    $this.zi2_1 = new Vec2();
    $this.ai3_1 = new Vec2ArrayPool();
    $this.bi3_1 = new WorldQueryWrapper();
    $this.ci3_1 = new WorldRayCastWrapper();
    $this.di3_1 = new RayCastInput();
    $this.ei3_1 = new Island();
    var tmp_5 = $this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp_5.fi3_1 = fillArrayVal(Array(10), null);
    $this.gi3_1 = new Timer();
    $this.hi3_1 = new Island();
    $this.ii3_1 = new TOIInput();
    $this.ji3_1 = new TOIOutput();
    $this.ki3_1 = new TimeStep();
    var tmp_6 = $this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp_6.li3_1 = fillArrayVal(Array(2), null);
    $this.mi3_1 = new Sweep();
    $this.ni3_1 = new Sweep();
    $this.oi3_1 = 0.12;
    $this.pi3_1 = -1.0;
    $this.qi3_1 = new Vec2();
    $this.ri3_1 = new Vec2();
    $this.si3_1 = Color3f.gin(0.4, 0.4, 1.0);
    $this.ti3_1 = new Vec2();
    $this.ui3_1 = new Vec2();
    $this.vi3_1 = new Vec2();
    $this.wi3_1 = new Vec2();
    $this.xi3_1 = new Vec2ArrayPool();
    $this.gi2_1.via(gravity);
    initializeRegisters($this);
    return $this;
  }
  wi5(key, value) {
    this.ui1_1.wi5(key, value);
  }
  zi3() {
    return this;
  }
  s1m() {
    return (this.ai2_1 & Companion_instance_17.mir_1) === Companion_instance_17.mir_1;
  }
  static li6(gravityX, gravityY, pool, strategy) {
    gravityX = gravityX === VOID ? 0.0 : gravityX;
    gravityY = gravityY === VOID ? 9.8 : gravityY;
    pool = pool === VOID ? new DefaultWorldPool(Companion_instance_17.jir_1, Companion_instance_17.kir_1) : pool;
    strategy = strategy === VOID ? new DynamicTree() : strategy;
    return this.qiz(new Vec2(numberToDouble(gravityX), numberToDouble(gravityY)), pool, new DefaultBroadPhaseBuffer(strategy));
  }
  rit(fixtureA, indexA, fixtureB, indexB) {
    var type1 = fixtureA.b2n();
    var type2 = fixtureB.b2n();
    var reg = this.si2_1[type1.v3_1][type2.v3_1];
    if (!(reg == null)) {
      if (reg.fiz_1) {
        var c = ensureNotNull(reg.eiz_1).riz();
        c.siz(fixtureA, indexA, fixtureB, indexB);
        return c;
      } else {
        var c_0 = ensureNotNull(reg.eiz_1).riz();
        c_0.siz(fixtureB, indexB, fixtureA, indexA);
        return c_0;
      }
    } else {
      return null;
    }
  }
  wit(contact) {
    var fixtureA = contact.nit();
    var fixtureB = contact.oit();
    if (contact.git_1.gib_1 > 0 && !ensureNotNull(fixtureA).sit() && !ensureNotNull(fixtureB).sit()) {
      ensureNotNull(fixtureA.vis()).oi5(true);
      ensureNotNull(fixtureB.vis()).oi5(true);
    }
    var type1 = ensureNotNull(fixtureA).b2n();
    var type2 = ensureNotNull(fixtureB).b2n();
    var creator = this.si2_1[type1.v3_1][type2.v3_1].eiz_1;
    ensureNotNull(creator).tiz(contact);
  }
  uiz(def) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!this.s1m()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    if (this.s1m()) {
      // Inline function 'kotlin.error' call
      var message_0 = 'World is locked';
      throw IllegalStateException.m5(toString(message_0));
    }
    var b = new Body(def, this);
    b.si4_1 = null;
    b.ti4_1 = this.ci2_1;
    if (!(this.ci2_1 == null)) {
      ensureNotNull(this.ci2_1).si4_1 = b;
    }
    this.ci2_1 = b;
    this.ei2_1 = this.ei2_1 + 1 | 0;
    return b;
  }
  vi5(body) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.ei2_1 > 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!!this.s1m()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    if (this.s1m()) {
      return Unit_instance;
    }
    var je = body.wi4_1;
    while (!(je == null)) {
      var je0 = je;
      je = je.bis_1;
      if (!(this.ii2_1 == null)) {
        ensureNotNull(this.ii2_1).viz(ensureNotNull(je0.zir_1));
      }
      this.wiz(je0.zir_1);
      body.wi4_1 = je;
    }
    body.wi4_1 = null;
    var ce = body.xi4_1;
    while (!(ce == null)) {
      var ce0 = ce;
      ce = ce.dir_1;
      this.bi2_1.eir(ensureNotNull(ce0.bir_1));
    }
    body.xi4_1 = null;
    var f = body.ui4_1;
    while (!(f == null)) {
      var f0 = f;
      f = f.pi6_1;
      if (!(this.ii2_1 == null)) {
        ensureNotNull(this.ii2_1).xiz(f0);
      }
      f0.ziq(this.bi2_1.uiq_1);
      f0.siu();
      body.ui4_1 = f;
      body.vi4_1 = body.vi4_1 - 1 | 0;
    }
    body.ui4_1 = null;
    body.vi4_1 = 0;
    if (!(body.si4_1 == null)) {
      ensureNotNull(body.si4_1).ti4_1 = body.ti4_1;
    }
    if (!(body.ti4_1 == null)) {
      ensureNotNull(body.ti4_1).si4_1 = body.si4_1;
    }
    if (equals(body, this.ci2_1)) {
      this.ci2_1 = body.ti4_1;
    }
    this.ei2_1 = this.ei2_1 - 1 | 0;
  }
  wiz(j) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!this.s1m()) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    if (this.s1m()) {
      return Unit_instance;
    }
    var collideConnected = ensureNotNull(j).ois();
    if (!(j.fis_1 == null)) {
      ensureNotNull(j.fis_1).gis_1 = j.gis_1;
    }
    if (!(j.gis_1 == null)) {
      ensureNotNull(j.gis_1).fis_1 = j.fis_1;
    }
    if (j === this.di2_1) {
      this.di2_1 = j.gis_1;
    }
    var bodyA = j.jis_1;
    var bodyB = j.kis_1;
    ensureNotNull(bodyA).oi5(true);
    ensureNotNull(bodyB).oi5(true);
    if (!(j.his_1.ais_1 == null)) {
      ensureNotNull(j.his_1.ais_1).bis_1 = j.his_1.bis_1;
    }
    if (!(j.his_1.bis_1 == null)) {
      ensureNotNull(j.his_1.bis_1).ais_1 = j.his_1.ais_1;
    }
    if (equals(j.his_1, bodyA.wi4_1)) {
      bodyA.wi4_1 = j.his_1.bis_1;
    }
    j.his_1.ais_1 = null;
    j.his_1.bis_1 = null;
    if (!(j.iis_1.ais_1 == null)) {
      ensureNotNull(j.iis_1.ais_1).bis_1 = j.iis_1.bis_1;
    }
    if (!(j.iis_1.bis_1 == null)) {
      ensureNotNull(j.iis_1.bis_1).ais_1 = j.iis_1.ais_1;
    }
    if (equals(j.iis_1, bodyB.wi4_1)) {
      bodyB.wi4_1 = j.iis_1.bis_1;
    }
    j.iis_1.ais_1 = null;
    j.iis_1.bis_1 = null;
    Companion_instance_20.yiz(j);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(this.fi2_1 > 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    this.fi2_1 = this.fi2_1 - 1 | 0;
    if (!collideConnected) {
      var edge = bodyB.liq();
      while (!(edge == null)) {
        if (equals(edge.air_1, bodyA)) {
          ensureNotNull(edge.bir_1).ziz();
        }
        edge = edge.dir_1;
      }
    }
  }
  yi3(dt, velocityIterations, positionIterations) {
    this.ui2_1.z33();
    this.vi2_1.z33();
    if ((this.ai2_1 & Companion_instance_17.lir_1) === Companion_instance_17.lir_1) {
      this.bi2_1.tit();
      this.ai2_1 = this.ai2_1 & ~Companion_instance_17.lir_1;
    }
    this.ai2_1 = this.ai2_1 | Companion_instance_17.mir_1;
    this.ti2_1.oiv_1 = dt;
    this.ti2_1.riv_1 = velocityIterations;
    this.ti2_1.siv_1 = positionIterations;
    if (dt > 0.0) {
      this.ti2_1.piv_1 = 1.0 / dt;
    } else {
      this.ti2_1.piv_1 = 0.0;
    }
    this.ti2_1.qiv_1 = this.li2_1 * dt;
    this.ti2_1.tiv_1 = this.mi2_1;
    this.qi2_1.aix_1.xiy(this.vi2_1.fip());
    this.vi2_1.z33();
    this.bi2_1.xit();
    this.qi2_1.bix_1.xiy(this.vi2_1.fip());
    if (this.pi2_1 && this.ti2_1.oiv_1 > 0.0) {
      this.vi2_1.z33();
      this.ri2_1.mj2(this.ti2_1);
      this.qi2_1.cix_1.xiy(this.vi2_1.fip());
      this.vi2_1.z33();
      solve(this, this.ti2_1);
      this.qi2_1.dix_1.xiy(this.vi2_1.fip());
    }
    if (this.ni2_1 && this.ti2_1.oiv_1 > 0.0) {
      this.vi2_1.z33();
      solveTOI(this, this.ti2_1);
      this.qi2_1.iix_1.xiy(this.vi2_1.fip());
    }
    if (this.ti2_1.oiv_1 > 0.0) {
      this.li2_1 = this.ti2_1.piv_1;
    }
    if ((this.ai2_1 & Companion_instance_17.nir_1) === Companion_instance_17.nir_1) {
      this.nj2();
    }
    this.ai2_1 = this.ai2_1 & ~Companion_instance_17.mir_1;
    this.qi2_1.ziw_1.xiy(this.ui2_1.fip());
  }
  nj2() {
    var body = this.ci2_1;
    while (!(body == null)) {
      body.qi4_1.ki6();
      body.ri4_1 = 0.0;
      body = body.kiq();
    }
  }
  oj2(callback, aabb) {
    this.bi3_1.pj2_1 = this.bi2_1.uiq_1;
    this.bi3_1.qj2_1 = callback;
    this.bi2_1.uiq_1.dil(this.bi3_1, aabb);
  }
}
class WorldQueryWrapper {
  constructor() {
    this.pj2_1 = null;
    this.qj2_1 = null;
  }
  fil(nodeId) {
    var tmp = ensureNotNull(this.pj2_1).zik(nodeId);
    var proxy = (tmp == null ? true : tmp instanceof FixtureProxy) ? tmp : THROW_CCE();
    return ensureNotNull(this.qj2_1).rj2(ensureNotNull(ensureNotNull(proxy).sis_1));
  }
}
class WorldRayCastWrapper {
  constructor() {
    this.sj2_1 = new RayCastOutput();
    this.tj2_1 = new Vec2();
    this.uj2_1 = new Vec2();
    this.vj2_1 = null;
    this.wj2_1 = null;
  }
}
class Contact {
  constructor(pool) {
    this.wis_1 = pool;
    this.xis_1 = 0;
    this.yis_1 = null;
    this.zis_1 = null;
    this.ait_1 = new ContactEdge();
    this.bit_1 = new ContactEdge();
    this.cit_1 = null;
    this.dit_1 = null;
    this.eit_1 = 0;
    this.fit_1 = 0;
    this.git_1 = Manifold.fih();
    this.hit_1 = 0;
    this.iit_1 = 0;
    this.jit_1 = 0;
    this.kit_1 = 0;
    this.lit_1 = 0;
    this.mit_1 = Manifold.fih();
  }
  kiq() {
    return this.zis_1;
  }
  nit() {
    return this.cit_1;
  }
  oit() {
    return this.dit_1;
  }
  pit() {
    return this.eit_1;
  }
  qit() {
    return this.fit_1;
  }
  rj3() {
    return this.git_1;
  }
  vit() {
    return (this.xis_1 & Companion_instance_18.zit_1) === Companion_instance_18.zit_1;
  }
  piz(flag) {
    var tmp;
    if (flag) {
      this.xis_1 = this.xis_1 | Companion_instance_18.aiu_1;
      tmp = Unit_instance;
    } else {
      this.xis_1 = this.xis_1 & ~Companion_instance_18.aiu_1;
      tmp = Unit_instance;
    }
    return tmp;
  }
  niz() {
    return (this.xis_1 & Companion_instance_18.aiu_1) === Companion_instance_18.aiu_1;
  }
  siz(fA, indexA, fB, indexB) {
    this.xis_1 = Companion_instance_18.aiu_1;
    this.cit_1 = fA;
    this.dit_1 = fB;
    this.eit_1 = indexA;
    this.fit_1 = indexB;
    this.git_1.gib_1 = 0;
    this.yis_1 = null;
    this.zis_1 = null;
    ensureNotNull(this.ait_1).bir_1 = null;
    ensureNotNull(this.ait_1).cir_1 = null;
    ensureNotNull(this.ait_1).dir_1 = null;
    ensureNotNull(this.ait_1).air_1 = null;
    ensureNotNull(this.bit_1).bir_1 = null;
    ensureNotNull(this.bit_1).cir_1 = null;
    ensureNotNull(this.bit_1).dir_1 = null;
    ensureNotNull(this.bit_1).air_1 = null;
    this.hit_1 = 0.0;
    this.jit_1 = Companion_instance_18.bj5(fA.si6_1, fB.si6_1);
    this.kit_1 = Companion_instance_18.cj5(fA.ti6_1, fB.ti6_1);
    this.lit_1 = 0.0;
  }
  ziz() {
    this.xis_1 = this.xis_1 | Companion_instance_18.biu_1;
  }
  eiu(listener) {
    this.mit_1.hih(this.git_1);
    this.xis_1 = this.xis_1 | Companion_instance_18.aiu_1;
    var touching = false;
    var wasTouching = (this.xis_1 & Companion_instance_18.zit_1) === Companion_instance_18.zit_1;
    var sensorA = ensureNotNull(this.cit_1).sit();
    var sensorB = ensureNotNull(this.dit_1).sit();
    var sensor = sensorA || sensorB;
    var bodyA = ensureNotNull(ensureNotNull(this.cit_1).qi6_1);
    var bodyB = ensureNotNull(ensureNotNull(this.dit_1).qi6_1);
    var xfA = bodyA.li4_1;
    var xfB = bodyB.li4_1;
    if (sensor) {
      var shapeA = ensureNotNull(ensureNotNull(this.cit_1).ri6_1);
      var shapeB = ensureNotNull(ensureNotNull(this.dit_1).ri6_1);
      touching = this.wis_1.qj3().eid(shapeA, this.eit_1, shapeB, this.fit_1, xfA, xfB);
      this.git_1.gib_1 = 0;
    } else {
      this.pj3(this.git_1, xfA, xfB);
      touching = this.git_1.gib_1 > 0;
      var inductionVariable = 0;
      var last = this.git_1.gib_1;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var mp2 = this.git_1.cib_1[i];
          mp2.nib_1 = 0.0;
          mp2.oib_1 = 0.0;
          var id2 = mp2.pib_1;
          var inductionVariable_0 = 0;
          var last_0 = this.mit_1.gib_1;
          if (inductionVariable_0 < last_0)
            $l$loop: do {
              var j = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              var mp1 = this.mit_1.cib_1[j];
              if (mp1.pib_1.cif(id2)) {
                mp2.nib_1 = mp1.nib_1;
                mp2.oib_1 = mp1.oib_1;
                break $l$loop;
              }
            }
             while (inductionVariable_0 < last_0);
        }
         while (inductionVariable < last);
      if (!(touching === wasTouching)) {
        ensureNotNull(bodyA).oi5(true);
        ensureNotNull(bodyB).oi5(true);
      }
    }
    if (touching) {
      this.xis_1 = this.xis_1 | Companion_instance_18.zit_1;
    } else {
      this.xis_1 = this.xis_1 & ~Companion_instance_18.zit_1;
    }
    if (listener == null) {
      return Unit_instance;
    }
    if (!wasTouching && touching) {
      listener.dj5(this);
    }
    if (wasTouching && !touching) {
      listener.uit(this);
    }
    if (!sensor && touching) {
      listener.ej5(this, this.mit_1);
    }
  }
}
class ChainAndCircleContact extends Contact {
  constructor(argPool) {
    super(argPool);
    this.oj3_1 = EdgeShape.xim();
  }
  siz(fA, indexA, fB, indexB) {
    super.siz(fA, indexA, fB, indexB);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(ensureNotNull(this.cit_1).b2n() === ShapeType_CHAIN_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(ensureNotNull(this.dit_1).b2n() === ShapeType_CIRCLE_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
  }
  pj3(manifold, xfA, xfB) {
    var tmp = ensureNotNull(this.cit_1).ri6_1;
    var chain = tmp instanceof ChainShape ? tmp : THROW_CCE();
    chain.kim(this.oj3_1, this.eit_1);
    var tmp_0 = this.wis_1.qj3();
    var tmp_1 = ensureNotNull(this.dit_1).ri6_1;
    tmp_0.yie(manifold, this.oj3_1, xfA, tmp_1 instanceof CircleShape ? tmp_1 : THROW_CCE(), xfB);
  }
}
class ChainAndPolygonContact extends Contact {
  constructor(argPool) {
    super(argPool);
    this.jj4_1 = EdgeShape.xim();
  }
  siz(fA, indexA, fB, indexB) {
    super.siz(fA, indexA, fB, indexB);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(ensureNotNull(this.cit_1).b2n() === ShapeType_CHAIN_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(ensureNotNull(this.dit_1).b2n() === ShapeType_POLYGON_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
  }
  pj3(manifold, xfA, xfB) {
    var tmp = ensureNotNull(this.cit_1).ri6_1;
    var chain = tmp instanceof ChainShape ? tmp : THROW_CCE();
    chain.kim(this.jj4_1, this.eit_1);
    var tmp_0 = this.wis_1.qj3();
    var tmp_1 = ensureNotNull(this.dit_1).ri6_1;
    tmp_0.bif(manifold, this.jj4_1, xfA, tmp_1 instanceof PolygonShape ? tmp_1 : THROW_CCE(), xfB);
  }
}
class CircleContact extends Contact {
  pj3(manifold, xfA, xfB) {
    var tmp = this.wis_1.qj3();
    var tmp_0 = ensureNotNull(this.cit_1).ri6_1;
    var tmp_1 = tmp_0 instanceof CircleShape ? tmp_0 : THROW_CCE();
    var tmp_2 = ensureNotNull(this.dit_1).ri6_1;
    tmp.iie(manifold, tmp_1, xfA, tmp_2 instanceof CircleShape ? tmp_2 : THROW_CCE(), xfB);
  }
}
class Companion_18 {
  constructor() {
    this.yit_1 = 1;
    this.zit_1 = 2;
    this.aiu_1 = 4;
    this.biu_1 = 8;
    this.ciu_1 = 16;
    this.diu_1 = 32;
  }
  bj5(friction1, friction2) {
    return Companion_getInstance_11().qim(friction1 * friction2);
  }
  cj5(restitution1, restitution2) {
    return restitution1 > restitution2 ? restitution1 : restitution2;
  }
}
class ContactEdge {
  constructor() {
    this.air_1 = null;
    this.bir_1 = null;
    this.cir_1 = null;
    this.dir_1 = null;
  }
}
class ContactPositionConstraint {
  constructor() {
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().ui7();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = new Vec2();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.fj5_1 = tmp_2;
    this.gj5_1 = new Vec2();
    this.hj5_1 = new Vec2();
    this.ij5_1 = 0;
    this.jj5_1 = 0;
    this.kj5_1 = 0;
    this.lj5_1 = 0;
    this.mj5_1 = new Vec2();
    this.nj5_1 = new Vec2();
    this.oj5_1 = 0;
    this.pj5_1 = 0;
    this.qj5_1 = null;
    this.rj5_1 = 0;
    this.sj5_1 = 0;
    this.tj5_1 = 0;
  }
}
class ContactRegister {
  constructor() {
    this.eiz_1 = null;
    this.fiz_1 = false;
  }
}
class ContactSolverDef {
  constructor() {
    this.biw_1 = null;
    this.ciw_1 = null;
    this.diw_1 = 0;
    this.eiw_1 = null;
    this.fiw_1 = null;
  }
}
class Companion_19 {
  constructor() {
    this.uj5_1 = false;
    this.vj5_1 = 0.001;
    this.wj5_1 = 256;
    this.xj5_1 = 100.0;
  }
}
class ContactSolver {
  constructor() {
    this.giw_1 = null;
    this.hiw_1 = null;
    this.iiw_1 = null;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Companion_instance_19.wj5_1;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = new ContactPositionConstraint();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.jiw_1 = tmp_2;
    var tmp_3 = this;
    var tmp_4 = 0;
    var tmp_5 = Companion_instance_19.wj5_1;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_6 = fillArrayVal(Array(tmp_5), null);
    while (tmp_4 < tmp_5) {
      tmp_6[tmp_4] = new ContactVelocityConstraint();
      tmp_4 = tmp_4 + 1 | 0;
    }
    tmp_3.kiw_1 = tmp_6;
    this.liw_1 = null;
    this.miw_1 = 0;
    this.niw_1 = Transform.iia();
    this.oiw_1 = Transform.iia();
    this.piw_1 = new WorldManifold();
    this.qiw_1 = new PositionSolverManifold();
    this.riw_1 = null;
    this.siw_1 = null;
    this.tiw_1 = 0;
    this.uiw_1 = 0;
  }
  viw(def) {
    this.giw_1 = def.biw_1;
    this.miw_1 = def.diw_1;
    if (this.jiw_1.length < this.miw_1) {
      var old = this.jiw_1;
      var tmp = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var size = Companion_getInstance_11().eih(imul(old.length, 2), this.miw_1);
      var tmp_0 = fillArrayVal(Array(size), null);
      tmp.jiw_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
      // Inline function 'org.jbox2d.internal.arraycopy' call
      var dst = this.jiw_1;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.copyInto' call
      var endIndex = 0 + old.length | 0;
      arrayCopy(old, dst, 0, 0, endIndex);
      var inductionVariable = old.length;
      var last = this.jiw_1.length;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          this.jiw_1[i] = new ContactPositionConstraint();
        }
         while (inductionVariable < last);
    }
    if (this.kiw_1.length < this.miw_1) {
      var old_0 = this.kiw_1;
      var tmp_1 = this;
      // Inline function 'kotlin.arrayOfNulls' call
      var size_0 = Companion_getInstance_11().eih(imul(old_0.length, 2), this.miw_1);
      var tmp_2 = fillArrayVal(Array(size_0), null);
      tmp_1.kiw_1 = isArray(tmp_2) ? tmp_2 : THROW_CCE();
      // Inline function 'org.jbox2d.internal.arraycopy' call
      var dst_0 = this.kiw_1;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.copyInto' call
      var endIndex_0 = 0 + old_0.length | 0;
      arrayCopy(old_0, dst_0, 0, 0, endIndex_0);
      var inductionVariable_0 = old_0.length;
      var last_0 = this.kiw_1.length;
      if (inductionVariable_0 < last_0)
        do {
          var i_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this.kiw_1[i_0] = new ContactVelocityConstraint();
        }
         while (inductionVariable_0 < last_0);
    }
    this.hiw_1 = def.eiw_1;
    this.iiw_1 = def.fiw_1;
    this.liw_1 = def.ciw_1;
    var inductionVariable_1 = 0;
    var last_1 = this.miw_1;
    if (inductionVariable_1 < last_1)
      do {
        var i_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var contact = ensureNotNull(this.liw_1)[i_1];
        var fixtureA = contact.cit_1;
        var fixtureB = contact.dit_1;
        var shapeA = ensureNotNull(fixtureA).giu();
        var shapeB = ensureNotNull(fixtureB).giu();
        var radiusA = ensureNotNull(shapeA).pie_1;
        var radiusB = ensureNotNull(shapeB).pie_1;
        var bodyA = fixtureA.vis();
        var bodyB = fixtureB.vis();
        var manifold = contact.rj3();
        var pointCount = manifold.gib_1;
        var vc = this.kiw_1[i_1];
        vc.kiy_1 = contact.jit_1;
        vc.liy_1 = contact.kit_1;
        vc.miy_1 = contact.lit_1;
        vc.eiy_1 = ensureNotNull(bodyA).ki4_1;
        vc.fiy_1 = ensureNotNull(bodyB).ki4_1;
        vc.giy_1 = bodyA.zi4_1;
        vc.hiy_1 = bodyB.zi4_1;
        vc.iiy_1 = bodyA.bi5_1;
        vc.jiy_1 = bodyB.bi5_1;
        vc.oiy_1 = i_1;
        vc.niy_1 = pointCount;
        vc.diy_1.ki6();
        vc.ciy_1.ki6();
        var pc = this.jiw_1[i_1];
        pc.ij5_1 = bodyA.ki4_1;
        pc.jj5_1 = bodyB.ki4_1;
        pc.kj5_1 = bodyA.zi4_1;
        pc.lj5_1 = bodyB.zi4_1;
        pc.mj5_1.via(bodyA.ni4_1.oii_1);
        pc.nj5_1.via(bodyB.ni4_1.oii_1);
        pc.oj5_1 = bodyA.bi5_1;
        pc.pj5_1 = bodyB.bi5_1;
        pc.gj5_1.via(manifold.dib_1);
        pc.hj5_1.via(manifold.eib_1);
        pc.tj5_1 = pointCount;
        pc.rj5_1 = radiusA;
        pc.sj5_1 = radiusB;
        pc.qj5_1 = manifold.fib_1;
        var inductionVariable_2 = 0;
        if (inductionVariable_2 < pointCount)
          do {
            var j = inductionVariable_2;
            inductionVariable_2 = inductionVariable_2 + 1 | 0;
            var cp = manifold.cib_1[j];
            var vcp = vc.aiy_1[j];
            if (ensureNotNull(this.giw_1).tiv_1) {
              vcp.riy_1 = ensureNotNull(this.giw_1).qiv_1 * cp.nib_1;
              vcp.siy_1 = ensureNotNull(this.giw_1).qiv_1 * cp.oib_1;
            } else {
              vcp.riy_1 = 0.0;
              vcp.siy_1 = 0.0;
            }
            vcp.piy_1.ki6();
            vcp.qiy_1.ki6();
            vcp.tiy_1 = 0.0;
            vcp.uiy_1 = 0.0;
            vcp.viy_1 = 0.0;
            pc.fj5_1[j].ai4_1 = cp.mib_1.ai4_1;
            pc.fj5_1[j].bi4_1 = cp.mib_1.bi4_1;
          }
           while (inductionVariable_2 < pointCount);
      }
       while (inductionVariable_1 < last_1);
  }
  xiw() {
    var inductionVariable = 0;
    var last = this.miw_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var vc = this.kiw_1[i];
        var indexA = vc.eiy_1;
        var indexB = vc.fiy_1;
        var mA = vc.giy_1;
        var iA = vc.iiy_1;
        var mB = vc.hiy_1;
        var iB = vc.jiy_1;
        var pointCount = vc.niy_1;
        var vA = ensureNotNull(this.iiw_1)[indexA].wiv_1;
        var wA = ensureNotNull(this.iiw_1)[indexA].xiv_1;
        var vB = ensureNotNull(this.iiw_1)[indexB].wiv_1;
        var wB = ensureNotNull(this.iiw_1)[indexB].xiv_1;
        var normal = vc.biy_1;
        var tangentx = 1.0 * normal.bi4_1;
        var tangenty = -1.0 * normal.ai4_1;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < pointCount)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var vcp = vc.aiy_1[j];
            var Px = tangentx * vcp.siy_1 + normal.ai4_1 * vcp.riy_1;
            var Py = tangenty * vcp.siy_1 + normal.bi4_1 * vcp.riy_1;
            wA = wA - iA * (vcp.piy_1.ai4_1 * Py - vcp.piy_1.bi4_1 * Px);
            vA.ai4_1 = vA.ai4_1 - Px * mA;
            vA.bi4_1 = vA.bi4_1 - Py * mA;
            wB = wB + iB * (vcp.qiy_1.ai4_1 * Py - vcp.qiy_1.bi4_1 * Px);
            vB.ai4_1 = vB.ai4_1 + Px * mB;
            vB.bi4_1 = vB.bi4_1 + Py * mB;
          }
           while (inductionVariable_0 < pointCount);
        ensureNotNull(this.iiw_1)[indexA].xiv_1 = wA;
        ensureNotNull(this.iiw_1)[indexB].xiv_1 = wB;
      }
       while (inductionVariable < last);
  }
  wiw() {
    var inductionVariable = 0;
    var last = this.miw_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var vc = this.kiw_1[i];
        var pc = this.jiw_1[i];
        var radiusA = pc.rj5_1;
        var radiusB = pc.sj5_1;
        var manifold = ensureNotNull(this.liw_1)[vc.oiy_1].rj3();
        var indexA = vc.eiy_1;
        var indexB = vc.fiy_1;
        var mA = vc.giy_1;
        var mB = vc.hiy_1;
        var iA = vc.iiy_1;
        var iB = vc.jiy_1;
        var localCenterA = pc.mj5_1;
        var localCenterB = pc.nj5_1;
        var cA = ensureNotNull(this.hiw_1)[indexA].uiv_1;
        var aA = ensureNotNull(this.hiw_1)[indexA].viv_1;
        var vA = ensureNotNull(this.iiw_1)[indexA].wiv_1;
        var wA = ensureNotNull(this.iiw_1)[indexA].xiv_1;
        var cB = ensureNotNull(this.hiw_1)[indexB].uiv_1;
        var aB = ensureNotNull(this.hiw_1)[indexB].viv_1;
        var vB = ensureNotNull(this.iiw_1)[indexB].wiv_1;
        var wB = ensureNotNull(this.iiw_1)[indexB].xiv_1;
        var xfAq = this.niw_1.hia_1;
        var xfBq = this.oiw_1.hia_1;
        xfAq.nio(aA);
        xfBq.nio(aB);
        this.niw_1.gia_1.ai4_1 = cA.ai4_1 - (xfAq.nie_1 * localCenterA.ai4_1 - xfAq.mie_1 * localCenterA.bi4_1);
        this.niw_1.gia_1.bi4_1 = cA.bi4_1 - (xfAq.mie_1 * localCenterA.ai4_1 + xfAq.nie_1 * localCenterA.bi4_1);
        this.oiw_1.gia_1.ai4_1 = cB.ai4_1 - (xfBq.nie_1 * localCenterB.ai4_1 - xfBq.mie_1 * localCenterB.bi4_1);
        this.oiw_1.gia_1.bi4_1 = cB.bi4_1 - (xfBq.mie_1 * localCenterB.ai4_1 + xfBq.nie_1 * localCenterB.bi4_1);
        this.piw_1.hik(manifold, this.niw_1, radiusA, this.oiw_1, radiusB);
        var vcnormal = vc.biy_1;
        vcnormal.ai4_1 = this.piw_1.cik_1.ai4_1;
        vcnormal.bi4_1 = this.piw_1.cik_1.bi4_1;
        var pointCount = vc.niy_1;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < pointCount)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var vcp = vc.aiy_1[j];
            var wmPj = this.piw_1.dik_1[j];
            var vcprA = vcp.piy_1;
            var vcprB = vcp.qiy_1;
            vcprA.ai4_1 = wmPj.ai4_1 - cA.ai4_1;
            vcprA.bi4_1 = wmPj.bi4_1 - cA.bi4_1;
            vcprB.ai4_1 = wmPj.ai4_1 - cB.ai4_1;
            vcprB.bi4_1 = wmPj.bi4_1 - cB.bi4_1;
            var rnA = vcprA.ai4_1 * vcnormal.bi4_1 - vcprA.bi4_1 * vcnormal.ai4_1;
            var rnB = vcprB.ai4_1 * vcnormal.bi4_1 - vcprB.bi4_1 * vcnormal.ai4_1;
            var kNormal = mA + mB + iA * rnA * rnA + iB * rnB * rnB;
            vcp.tiy_1 = kNormal > 0.0 ? 1.0 / kNormal : 0.0;
            var tangentx = 1.0 * vcnormal.bi4_1;
            var tangenty = -1.0 * vcnormal.ai4_1;
            var rtA = vcprA.ai4_1 * tangenty - vcprA.bi4_1 * tangentx;
            var rtB = vcprB.ai4_1 * tangenty - vcprB.bi4_1 * tangentx;
            var kTangent = mA + mB + iA * rtA * rtA + iB * rtB * rtB;
            vcp.uiy_1 = kTangent > 0.0 ? 1.0 / kTangent : 0.0;
            vcp.viy_1 = 0.0;
            var tempx = vB.ai4_1 + -wB * vcprB.bi4_1 - vA.ai4_1 - -wA * vcprA.bi4_1;
            var tempy = vB.bi4_1 + wB * vcprB.ai4_1 - vA.bi4_1 - wA * vcprA.ai4_1;
            var vRel = vcnormal.ai4_1 * tempx + vcnormal.bi4_1 * tempy;
            if (vRel < -Settings_getInstance().sio()) {
              vcp.viy_1 = -vc.liy_1 * vRel;
            }
          }
           while (inductionVariable_0 < pointCount);
        if (vc.niy_1 === 2) {
          var vcp1 = vc.aiy_1[0];
          var vcp2 = vc.aiy_1[1];
          var rn1A = vcp1.piy_1.ai4_1 * vcnormal.bi4_1 - vcp1.piy_1.bi4_1 * vcnormal.ai4_1;
          var rn1B = vcp1.qiy_1.ai4_1 * vcnormal.bi4_1 - vcp1.qiy_1.bi4_1 * vcnormal.ai4_1;
          var rn2A = vcp2.piy_1.ai4_1 * vcnormal.bi4_1 - vcp2.piy_1.bi4_1 * vcnormal.ai4_1;
          var rn2B = vcp2.qiy_1.ai4_1 * vcnormal.bi4_1 - vcp2.qiy_1.bi4_1 * vcnormal.ai4_1;
          var k11 = mA + mB + iA * rn1A * rn1A + iB * rn1B * rn1B;
          var k22 = mA + mB + iA * rn2A * rn2A + iB * rn2B * rn2B;
          var k12 = mA + mB + iA * rn1A * rn2A + iB * rn1B * rn2B;
          if (k11 * k11 < Companion_instance_19.xj5_1 * (k11 * k22 - k12 * k12)) {
            vc.diy_1.hin_1.ai4_1 = k11;
            vc.diy_1.hin_1.bi4_1 = k12;
            vc.diy_1.iin_1.ai4_1 = k12;
            vc.diy_1.iin_1.bi4_1 = k22;
            vc.diy_1.kin(vc.ciy_1);
          } else {
            vc.niy_1 = 1;
          }
        }
      }
       while (inductionVariable < last);
  }
  rix() {
    var inductionVariable = 0;
    var last = this.miw_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var vc = this.kiw_1[i];
        var manifold = ensureNotNull(this.liw_1)[vc.oiy_1].rj3();
        var inductionVariable_0 = 0;
        var last_0 = vc.niy_1;
        if (inductionVariable_0 < last_0)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            manifold.cib_1[j].nib_1 = vc.aiy_1[j].riy_1;
            manifold.cib_1[j].oib_1 = vc.aiy_1[j].siy_1;
          }
           while (inductionVariable_0 < last_0);
      }
       while (inductionVariable < last);
  }
  six() {
    var minSeparation = 0.0;
    var inductionVariable = 0;
    var last = this.miw_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var pc = this.jiw_1[i];
        var indexA = pc.ij5_1;
        var indexB = pc.jj5_1;
        var mA = pc.kj5_1;
        var iA = pc.oj5_1;
        var localCenterA = pc.mj5_1;
        var localCenterAx = localCenterA.ai4_1;
        var localCenterAy = localCenterA.bi4_1;
        var mB = pc.lj5_1;
        var iB = pc.pj5_1;
        var localCenterB = pc.nj5_1;
        var localCenterBx = localCenterB.ai4_1;
        var localCenterBy = localCenterB.bi4_1;
        var pointCount = pc.tj5_1;
        var cA = ensureNotNull(this.hiw_1)[indexA].uiv_1;
        var aA = ensureNotNull(this.hiw_1)[indexA].viv_1;
        var cB = ensureNotNull(this.hiw_1)[indexB].uiv_1;
        var aB = ensureNotNull(this.hiw_1)[indexB].viv_1;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < pointCount)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var xfAq = this.niw_1.hia_1;
            var xfBq = this.oiw_1.hia_1;
            xfAq.nio(aA);
            xfBq.nio(aB);
            this.niw_1.gia_1.ai4_1 = cA.ai4_1 - xfAq.nie_1 * localCenterAx + xfAq.mie_1 * localCenterAy;
            this.niw_1.gia_1.bi4_1 = cA.bi4_1 - xfAq.mie_1 * localCenterAx - xfAq.nie_1 * localCenterAy;
            this.oiw_1.gia_1.ai4_1 = cB.ai4_1 - xfBq.nie_1 * localCenterBx + xfBq.mie_1 * localCenterBy;
            this.oiw_1.gia_1.bi4_1 = cB.bi4_1 - xfBq.mie_1 * localCenterBx - xfBq.nie_1 * localCenterBy;
            var psm = this.qiw_1;
            psm.bj6(pc, this.niw_1, this.oiw_1, j);
            var normal = psm.yj5_1;
            var point = psm.zj5_1;
            var separation = psm.aj6_1;
            var rAx = point.ai4_1 - cA.ai4_1;
            var rAy = point.bi4_1 - cA.bi4_1;
            var rBx = point.ai4_1 - cB.ai4_1;
            var rBy = point.bi4_1 - cB.bi4_1;
            minSeparation = Companion_getInstance_11().bic(minSeparation, separation);
            var C = Companion_getInstance_11().hio(Settings_getInstance().yio() * (separation + Settings_getInstance().vie()), -Settings_getInstance().tio(), 0.0);
            var rnA = rAx * normal.bi4_1 - rAy * normal.ai4_1;
            var rnB = rBx * normal.bi4_1 - rBy * normal.ai4_1;
            var K = mA + mB + iA * rnA * rnA + iB * rnB * rnB;
            var impulse = K > 0.0 ? -C / K : 0.0;
            var Px = normal.ai4_1 * impulse;
            var Py = normal.bi4_1 * impulse;
            cA.ai4_1 = cA.ai4_1 - Px * mA;
            cA.bi4_1 = cA.bi4_1 - Py * mA;
            aA = aA - iA * (rAx * Py - rAy * Px);
            cB.ai4_1 = cB.ai4_1 + Px * mB;
            cB.bi4_1 = cB.bi4_1 + Py * mB;
            aB = aB + iB * (rBx * Py - rBy * Px);
          }
           while (inductionVariable_0 < pointCount);
        ensureNotNull(this.hiw_1)[indexA].viv_1 = aA;
        ensureNotNull(this.hiw_1)[indexB].viv_1 = aB;
      }
       while (inductionVariable < last);
    return minSeparation >= -3.0 * Settings_getInstance().vie();
  }
  wix(toiIndexA, toiIndexB) {
    var minSeparation = 0.0;
    var inductionVariable = 0;
    var last = this.miw_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var pc = this.jiw_1[i];
        var indexA = pc.ij5_1;
        var indexB = pc.jj5_1;
        var localCenterA = pc.mj5_1;
        var localCenterB = pc.nj5_1;
        var localCenterAx = localCenterA.ai4_1;
        var localCenterAy = localCenterA.bi4_1;
        var localCenterBx = localCenterB.ai4_1;
        var localCenterBy = localCenterB.bi4_1;
        var pointCount = pc.tj5_1;
        var mA = 0.0;
        var iA = 0.0;
        if (indexA === toiIndexA || indexA === toiIndexB) {
          mA = pc.kj5_1;
          iA = pc.oj5_1;
        }
        var mB = 0.0;
        var iB = 0.0;
        if (indexB === toiIndexA || indexB === toiIndexB) {
          mB = pc.lj5_1;
          iB = pc.pj5_1;
        }
        var cA = ensureNotNull(this.hiw_1)[indexA].uiv_1;
        var aA = ensureNotNull(this.hiw_1)[indexA].viv_1;
        var cB = ensureNotNull(this.hiw_1)[indexB].uiv_1;
        var aB = ensureNotNull(this.hiw_1)[indexB].viv_1;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < pointCount)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var xfAq = this.niw_1.hia_1;
            var xfBq = this.oiw_1.hia_1;
            xfAq.nio(aA);
            xfBq.nio(aB);
            this.niw_1.gia_1.ai4_1 = cA.ai4_1 - xfAq.nie_1 * localCenterAx + xfAq.mie_1 * localCenterAy;
            this.niw_1.gia_1.bi4_1 = cA.bi4_1 - xfAq.mie_1 * localCenterAx - xfAq.nie_1 * localCenterAy;
            this.oiw_1.gia_1.ai4_1 = cB.ai4_1 - xfBq.nie_1 * localCenterBx + xfBq.mie_1 * localCenterBy;
            this.oiw_1.gia_1.bi4_1 = cB.bi4_1 - xfBq.mie_1 * localCenterBx - xfBq.nie_1 * localCenterBy;
            var psm = this.qiw_1;
            psm.bj6(pc, this.niw_1, this.oiw_1, j);
            var normal = psm.yj5_1;
            var point = psm.zj5_1;
            var separation = psm.aj6_1;
            var rAx = point.ai4_1 - cA.ai4_1;
            var rAy = point.bi4_1 - cA.bi4_1;
            var rBx = point.ai4_1 - cB.ai4_1;
            var rBy = point.bi4_1 - cB.bi4_1;
            minSeparation = Companion_getInstance_11().bic(minSeparation, separation);
            var C = Companion_getInstance_11().hio(Settings_getInstance().zio() * (separation + Settings_getInstance().vie()), -Settings_getInstance().tio(), 0.0);
            var rnA = rAx * normal.bi4_1 - rAy * normal.ai4_1;
            var rnB = rBx * normal.bi4_1 - rBy * normal.ai4_1;
            var K = mA + mB + iA * rnA * rnA + iB * rnB * rnB;
            var impulse = K > 0.0 ? -C / K : 0.0;
            var Px = normal.ai4_1 * impulse;
            var Py = normal.bi4_1 * impulse;
            cA.ai4_1 = cA.ai4_1 - Px * mA;
            cA.bi4_1 = cA.bi4_1 - Py * mA;
            aA = aA - iA * (rAx * Py - rAy * Px);
            cB.ai4_1 = cB.ai4_1 + Px * mB;
            cB.bi4_1 = cB.bi4_1 + Py * mB;
            aB = aB + iB * (rBx * Py - rBy * Px);
          }
           while (inductionVariable_0 < pointCount);
        ensureNotNull(this.hiw_1)[indexA].viv_1 = aA;
        ensureNotNull(this.hiw_1)[indexB].viv_1 = aB;
      }
       while (inductionVariable < last);
    return minSeparation >= -1.5 * Settings_getInstance().vie();
  }
  qix() {
    var inductionVariable = 0;
    var last = this.miw_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var vc = this.kiw_1[i];
        var indexA = vc.eiy_1;
        var indexB = vc.fiy_1;
        var mA = vc.giy_1;
        var mB = vc.hiy_1;
        var iA = vc.iiy_1;
        var iB = vc.jiy_1;
        var pointCount = vc.niy_1;
        this.riw_1 = ensureNotNull(this.iiw_1)[indexA].wiv_1;
        this.tiw_1 = ensureNotNull(this.iiw_1)[indexA].xiv_1;
        this.siw_1 = ensureNotNull(this.iiw_1)[indexB].wiv_1;
        this.uiw_1 = ensureNotNull(this.iiw_1)[indexB].xiv_1;
        var normal = vc.biy_1;
        var normalx = normal.ai4_1;
        var normaly = normal.bi4_1;
        var tangentx = 1.0 * vc.biy_1.bi4_1;
        var tangenty = -1.0 * vc.biy_1.ai4_1;
        var friction = vc.kiy_1;
        solveVelocityConstraints0(this, vc, mA, mB, iA, iB, pointCount, tangentx, tangenty, friction);
        if (vc.niy_1 === 1) {
          solveVelocityConstraints1(this, vc.aiy_1[0], mA, mB, iA, iB, normalx, normaly);
        } else {
          solveVelocityConstraints2(this, vc, mA, mB, iA, iB, normal, normalx, normaly);
        }
        ensureNotNull(this.iiw_1)[indexA].xiv_1 = this.tiw_1;
        ensureNotNull(this.iiw_1)[indexB].xiv_1 = this.uiw_1;
      }
       while (inductionVariable < last);
  }
}
class VelocityConstraintPoint {
  constructor() {
    this.piy_1 = new Vec2();
    this.qiy_1 = new Vec2();
    this.riy_1 = 0;
    this.siy_1 = 0;
    this.tiy_1 = 0;
    this.uiy_1 = 0;
    this.viy_1 = 0;
  }
}
class ContactVelocityConstraint {
  constructor() {
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Settings_getInstance().ui7();
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = new VelocityConstraintPoint();
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.aiy_1 = tmp_2;
    this.biy_1 = new Vec2();
    this.ciy_1 = Mat22.jin();
    this.diy_1 = Mat22.jin();
    this.eiy_1 = 0;
    this.fiy_1 = 0;
    this.giy_1 = 0;
    this.hiy_1 = 0;
    this.iiy_1 = 0;
    this.jiy_1 = 0;
    this.kiy_1 = 0;
    this.liy_1 = 0;
    this.miy_1 = 0;
    this.niy_1 = 0;
    this.oiy_1 = 0;
  }
}
class EdgeAndCircleContact extends Contact {
  siz(fA, indexA, fB, indexB) {
    super.siz(fA, indexA, fB, indexB);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(ensureNotNull(this.cit_1).b2n() === ShapeType_EDGE_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(ensureNotNull(this.dit_1).b2n() === ShapeType_CIRCLE_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
  }
  pj3(manifold, xfA, xfB) {
    var tmp = this.wis_1.qj3();
    var tmp_0 = ensureNotNull(this.cit_1).ri6_1;
    var tmp_1 = tmp_0 instanceof EdgeShape ? tmp_0 : THROW_CCE();
    var tmp_2 = ensureNotNull(this.dit_1).ri6_1;
    tmp.yie(manifold, tmp_1, xfA, tmp_2 instanceof CircleShape ? tmp_2 : THROW_CCE(), xfB);
  }
}
class EdgeAndPolygonContact extends Contact {
  siz(fA, indexA, fB, indexB) {
    super.siz(fA, indexA, fB, indexB);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(ensureNotNull(this.cit_1).b2n() === ShapeType_EDGE_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!(ensureNotNull(this.dit_1).b2n() === ShapeType_POLYGON_getInstance())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
  }
  pj3(manifold, xfA, xfB) {
    var tmp = this.wis_1.qj3();
    var tmp_0 = ensureNotNull(this.cit_1).ri6_1;
    var tmp_1 = tmp_0 instanceof EdgeShape ? tmp_0 : THROW_CCE();
    var tmp_2 = ensureNotNull(this.dit_1).ri6_1;
    tmp.bif(manifold, tmp_1, xfA, tmp_2 instanceof PolygonShape ? tmp_2 : THROW_CCE(), xfB);
  }
}
class PolygonAndCircleContact extends Contact {
  pj3(manifold, xfA, xfB) {
    var tmp = this.wis_1.qj3();
    var tmp_0 = ensureNotNull(this.cit_1).ri6_1;
    var tmp_1 = tmp_0 instanceof PolygonShape ? tmp_0 : THROW_CCE();
    var tmp_2 = ensureNotNull(this.dit_1).ri6_1;
    tmp.rie(manifold, tmp_1, xfA, tmp_2 instanceof CircleShape ? tmp_2 : THROW_CCE(), xfB);
  }
}
class PolygonContact extends Contact {
  pj3(manifold, xfA, xfB) {
    var tmp = this.wis_1.qj3();
    var tmp_0 = ensureNotNull(this.cit_1).ri6_1;
    var tmp_1 = tmp_0 instanceof PolygonShape ? tmp_0 : THROW_CCE();
    var tmp_2 = ensureNotNull(this.dit_1).ri6_1;
    tmp.uie(manifold, tmp_1, xfA, tmp_2 instanceof PolygonShape ? tmp_2 : THROW_CCE(), xfB);
  }
}
class Position {
  constructor() {
    this.uiv_1 = new Vec2();
    this.viv_1 = 0;
  }
}
class PositionSolverManifold {
  constructor() {
    this.yj5_1 = new Vec2();
    this.zj5_1 = new Vec2();
    this.aj6_1 = 0;
  }
  bj6(pc, xfA, xfB, index) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(pc.tj5_1 > 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var xfAq = xfA.hia_1;
    var xfBq = xfB.hia_1;
    var pcLocalPointsI = pc.fj5_1[index];
    var tmp28_subject = pc.qj5_1;
    switch (tmp28_subject == null ? -1 : tmp28_subject.v3_1) {
      case 0:
        var plocalPoint = pc.hj5_1;
        var pLocalPoints0 = pc.fj5_1[0];
        var pointAx = xfAq.nie_1 * plocalPoint.ai4_1 - xfAq.mie_1 * plocalPoint.bi4_1 + xfA.gia_1.ai4_1;
        var pointAy = xfAq.mie_1 * plocalPoint.ai4_1 + xfAq.nie_1 * plocalPoint.bi4_1 + xfA.gia_1.bi4_1;
        var pointBx = xfBq.nie_1 * pLocalPoints0.ai4_1 - xfBq.mie_1 * pLocalPoints0.bi4_1 + xfB.gia_1.ai4_1;
        var pointBy = xfBq.mie_1 * pLocalPoints0.ai4_1 + xfBq.nie_1 * pLocalPoints0.bi4_1 + xfB.gia_1.bi4_1;
        this.yj5_1.ai4_1 = pointBx - pointAx;
        this.yj5_1.bi4_1 = pointBy - pointAy;
        this.yj5_1.xia();
        this.zj5_1.ai4_1 = (pointAx + pointBx) * 0.5;
        this.zj5_1.bi4_1 = (pointAy + pointBy) * 0.5;
        var tempx = pointBx - pointAx;
        var tempy = pointBy - pointAy;
        this.aj6_1 = tempx * this.yj5_1.ai4_1 + tempy * this.yj5_1.bi4_1 - pc.rj5_1 - pc.sj5_1;
        break;
      case 1:
        var pcLocalNormal = pc.gj5_1;
        var pcLocalPoint = pc.hj5_1;
        this.yj5_1.ai4_1 = xfAq.nie_1 * pcLocalNormal.ai4_1 - xfAq.mie_1 * pcLocalNormal.bi4_1;
        this.yj5_1.bi4_1 = xfAq.mie_1 * pcLocalNormal.ai4_1 + xfAq.nie_1 * pcLocalNormal.bi4_1;
        var planePointx = xfAq.nie_1 * pcLocalPoint.ai4_1 - xfAq.mie_1 * pcLocalPoint.bi4_1 + xfA.gia_1.ai4_1;
        var planePointy = xfAq.mie_1 * pcLocalPoint.ai4_1 + xfAq.nie_1 * pcLocalPoint.bi4_1 + xfA.gia_1.bi4_1;
        var clipPointx = xfBq.nie_1 * pcLocalPointsI.ai4_1 - xfBq.mie_1 * pcLocalPointsI.bi4_1 + xfB.gia_1.ai4_1;
        var clipPointy = xfBq.mie_1 * pcLocalPointsI.ai4_1 + xfBq.nie_1 * pcLocalPointsI.bi4_1 + xfB.gia_1.bi4_1;
        var tempx_0 = clipPointx - planePointx;
        var tempy_0 = clipPointy - planePointy;
        this.aj6_1 = tempx_0 * this.yj5_1.ai4_1 + tempy_0 * this.yj5_1.bi4_1 - pc.rj5_1 - pc.sj5_1;
        this.zj5_1.ai4_1 = clipPointx;
        this.zj5_1.bi4_1 = clipPointy;
        break;
      case 2:
        var pcLocalNormal_0 = pc.gj5_1;
        var pcLocalPoint_0 = pc.hj5_1;
        this.yj5_1.ai4_1 = xfBq.nie_1 * pcLocalNormal_0.ai4_1 - xfBq.mie_1 * pcLocalNormal_0.bi4_1;
        this.yj5_1.bi4_1 = xfBq.mie_1 * pcLocalNormal_0.ai4_1 + xfBq.nie_1 * pcLocalNormal_0.bi4_1;
        var planePointx_0 = xfBq.nie_1 * pcLocalPoint_0.ai4_1 - xfBq.mie_1 * pcLocalPoint_0.bi4_1 + xfB.gia_1.ai4_1;
        var planePointy_0 = xfBq.mie_1 * pcLocalPoint_0.ai4_1 + xfBq.nie_1 * pcLocalPoint_0.bi4_1 + xfB.gia_1.bi4_1;
        var clipPointx_0 = xfAq.nie_1 * pcLocalPointsI.ai4_1 - xfAq.mie_1 * pcLocalPointsI.bi4_1 + xfA.gia_1.ai4_1;
        var clipPointy_0 = xfAq.mie_1 * pcLocalPointsI.ai4_1 + xfAq.nie_1 * pcLocalPointsI.bi4_1 + xfA.gia_1.bi4_1;
        var tempx_1 = clipPointx_0 - planePointx_0;
        var tempy_1 = clipPointy_0 - planePointy_0;
        this.aj6_1 = tempx_1 * this.yj5_1.ai4_1 + tempy_1 * this.yj5_1.bi4_1 - pc.rj5_1 - pc.sj5_1;
        this.zj5_1.ai4_1 = clipPointx_0;
        this.zj5_1.bi4_1 = clipPointy_0;
        var _receiver__tnumb7 = this.yj5_1;
        _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 * -1.0;
        var _receiver__tnumb7_0 = this.yj5_1;
        _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 * -1.0;
        break;
      default:
        break;
    }
  }
}
class Velocity {
  constructor() {
    this.wiv_1 = new Vec2();
    this.xiv_1 = 0;
  }
}
class Companion_20 {
  yiz(joint) {
    joint.sj8();
  }
}
class ParticleBodyContact {
  constructor() {
    this.tj8_1 = 0;
    this.uj8_1 = null;
    this.vj8_1 = 0;
    this.wj8_1 = new Vec2();
    this.xj8_1 = 0;
  }
}
class ParticleColor {
  static cj9() {
    var $this = createThis(this);
    init_org_jbox2d_particle_ParticleColor($this);
    $this.yj8_1 = 127;
    $this.zj8_1 = 127;
    $this.aj9_1 = 127;
    $this.bj9_1 = 50;
    return $this;
  }
  dj9(color) {
    this.yj8_1 = color.yj8_1;
    this.zj8_1 = color.zj8_1;
    this.aj9_1 = color.aj9_1;
    this.bj9_1 = color.bj9_1;
  }
}
class ParticleContact {
  constructor() {
    this.ej9_1 = 0;
    this.fj9_1 = 0;
    this.gj9_1 = 0;
    this.hj9_1 = 0;
    this.ij9_1 = new Vec2();
  }
}
class ParticleDef {
  constructor() {
    this.jj9_1 = new Mixin();
    this.kj9_1 = 0;
    this.lj9_1 = new Vec2();
    this.mj9_1 = new Vec2();
    this.nj9_1 = null;
    this.oj9_1 = null;
  }
}
class ParticleGroupType {
  constructor() {
    this.pj9_1 = 1;
    this.qj9_1 = 2;
  }
}
class NewIndices {
  constructor() {
    this.tj9_1 = 0;
    this.uj9_1 = 0;
    this.vj9_1 = 0;
  }
}
class ParticleBuffer {
  constructor(dataClass) {
    this.wj9_1 = dataClass;
    this.xj9_1 = null;
    this.yj9_1 = 0;
  }
}
class ParticleBufferInt {
  constructor() {
    this.zj9_1 = null;
    this.aja_1 = 0;
  }
}
class CreateParticleGroupCallback {
  constructor() {
    this.bja_1 = null;
    this.cja_1 = null;
    this.dja_1 = 0;
  }
}
class DestroyParticlesInShapeCallback {
  constructor() {
    this.eja_1 = false;
    this.fja_1 = 0;
  }
}
class UpdateBodyContactsCallback {
  constructor() {
    this.gja_1 = null;
    this.hja_1 = new Vec2();
  }
  rj2(fixture) {
    if (fixture.sit()) {
      return true;
    }
    var shape = fixture.giu();
    var b = fixture.vis();
    var bp = ensureNotNull(b).oiq();
    var bm = b.miq();
    var bI = b.qiq() - bm * b.piq().cih();
    var invBm = bm > 0 ? 1.0 / bm : 0.0;
    var invBI = bI > 0 ? 1.0 / bI : 0.0;
    var childCount = ensureNotNull(shape).oim();
    var inductionVariable = 0;
    if (inductionVariable < childCount)
      do {
        var childIndex = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var aabb = fixture.iiu(childIndex);
        var aabblowerBoundx = aabb.wi7_1.ai4_1 - ensureNotNull(this.gja_1).hj0_1;
        var aabblowerBoundy = aabb.wi7_1.bi4_1 - ensureNotNull(this.gja_1).hj0_1;
        var aabbupperBoundx = aabb.xi7_1.ai4_1 + ensureNotNull(this.gja_1).hj0_1;
        var aabbupperBoundy = aabb.xi7_1.bi4_1 + ensureNotNull(this.gja_1).hj0_1;
        var firstProxy = lowerBound(Companion_getInstance_23(), ensureNotNull(this.gja_1).yj0_1, ensureNotNull(this.gja_1).wj0_1, Companion_getInstance_23().vja(ensureNotNull(this.gja_1).ij0_1 * aabblowerBoundx, ensureNotNull(this.gja_1).ij0_1 * aabblowerBoundy));
        var lastProxy = upperBound(Companion_getInstance_23(), ensureNotNull(this.gja_1).yj0_1, ensureNotNull(this.gja_1).wj0_1, Companion_getInstance_23().vja(ensureNotNull(this.gja_1).ij0_1 * aabbupperBoundx, ensureNotNull(this.gja_1).ij0_1 * aabbupperBoundy));
        var inductionVariable_0 = firstProxy;
        if (inductionVariable_0 < lastProxy)
          do {
            var proxy = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var a = ensureNotNull(this.gja_1).yj0_1[proxy].rj9_1;
            var ap = ensureNotNull(ensureNotNull(this.gja_1).oj0_1.xj9_1)[a];
            if (aabblowerBoundx <= ap.ai4_1 && ap.ai4_1 <= aabbupperBoundx && aabblowerBoundy <= ap.bi4_1 && ap.bi4_1 <= aabbupperBoundy) {
              var d;
              var n = this.hja_1;
              d = fixture.jiu(ap, childIndex, n);
              if (d < ensureNotNull(this.gja_1).hj0_1) {
                var invAm = !((ensureNotNull(ensureNotNull(this.gja_1).nj0_1.zj9_1)[a] & ParticleType_instance.zja_1) === 0) ? 0.0 : ensureNotNull(ensureNotNull(this.gja_1).wja());
                var rpx = ap.ai4_1 - bp.ai4_1;
                var rpy = ap.bi4_1 - bp.bi4_1;
                var rpn = rpx * n.bi4_1 - rpy * n.ai4_1;
                if (ensureNotNull(this.gja_1).cj1_1 >= ensureNotNull(this.gja_1).dj1_1) {
                  var oldCapacity = ensureNotNull(this.gja_1).dj1_1;
                  var newCapacity = !(ensureNotNull(this.gja_1).cj1_1 === 0) ? imul(2, ensureNotNull(this.gja_1).cj1_1) : Settings_getInstance().ti7_1;
                  var tmp = ensureNotNull(this.gja_1);
                  var tmp_0 = BufferUtils_instance;
                  tmp.ej1_1 = tmp_0.ain(ParticleSystem$UpdateBodyContactsCallback$reportFixture$lambda, ensureNotNull(this.gja_1).ej1_1, oldCapacity, newCapacity);
                  ensureNotNull(this.gja_1).dj1_1 = newCapacity;
                }
                var contact = ensureNotNull(this.gja_1).ej1_1[ensureNotNull(this.gja_1).cj1_1];
                contact.tj8_1 = a;
                contact.uj8_1 = b;
                contact.vj8_1 = 1 - d * ensureNotNull(this.gja_1).ij0_1;
                contact.wj8_1.ai4_1 = -n.ai4_1;
                contact.wj8_1.bi4_1 = -n.bi4_1;
                contact.xj8_1 = 1 / (invAm + invBm + invBI * rpn * rpn);
                var _receiver__tnumb7 = ensureNotNull(this.gja_1);
                _receiver__tnumb7.cj1_1 = _receiver__tnumb7.cj1_1 + 1 | 0;
              }
            }
          }
           while (inductionVariable_0 < lastProxy);
      }
       while (inductionVariable < childCount);
    return true;
  }
}
class SolveCollisionCallback {
  constructor() {
    this.hjb_1 = null;
    this.ijb_1 = null;
    this.jjb_1 = new RayCastInput();
    this.kjb_1 = new RayCastOutput();
    this.ljb_1 = new Vec2();
    this.mjb_1 = new Vec2();
  }
  rj2(fixture) {
    if (fixture.sit()) {
      return true;
    }
    var shape = fixture.giu();
    var body = fixture.vis();
    var childCount = ensureNotNull(shape).oim();
    var inductionVariable = 0;
    if (inductionVariable < childCount)
      do {
        var childIndex = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var aabb = fixture.iiu(childIndex);
        var aabblowerBoundx = aabb.wi7_1.ai4_1 - ensureNotNull(this.hjb_1).hj0_1;
        var aabblowerBoundy = aabb.wi7_1.bi4_1 - ensureNotNull(this.hjb_1).hj0_1;
        var aabbupperBoundx = aabb.xi7_1.ai4_1 + ensureNotNull(this.hjb_1).hj0_1;
        var aabbupperBoundy = aabb.xi7_1.bi4_1 + ensureNotNull(this.hjb_1).hj0_1;
        var firstProxy = lowerBound(Companion_getInstance_23(), ensureNotNull(this.hjb_1).yj0_1, ensureNotNull(this.hjb_1).wj0_1, Companion_getInstance_23().vja(ensureNotNull(this.hjb_1).ij0_1 * aabblowerBoundx, ensureNotNull(this.hjb_1).ij0_1 * aabblowerBoundy));
        var lastProxy = upperBound(Companion_getInstance_23(), ensureNotNull(this.hjb_1).yj0_1, ensureNotNull(this.hjb_1).wj0_1, Companion_getInstance_23().vja(ensureNotNull(this.hjb_1).ij0_1 * aabbupperBoundx, ensureNotNull(this.hjb_1).ij0_1 * aabbupperBoundy));
        var inductionVariable_0 = firstProxy;
        if (inductionVariable_0 < lastProxy)
          do {
            var proxy = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var a = ensureNotNull(this.hjb_1).yj0_1[proxy].rj9_1;
            var ap = ensureNotNull(ensureNotNull(this.hjb_1).oj0_1.xj9_1)[a];
            if (aabblowerBoundx <= ap.ai4_1 && ap.ai4_1 <= aabbupperBoundx && aabblowerBoundy <= ap.bi4_1 && ap.bi4_1 <= aabbupperBoundy) {
              var av = ensureNotNull(ensureNotNull(this.hjb_1).pj0_1.xj9_1)[a];
              var temp = this.ljb_1;
              Companion_instance_11.qib(ensureNotNull(body).mi4_1, ap, temp);
              Companion_instance_11.lia(body.li4_1, temp, this.jjb_1.jih_1);
              this.jjb_1.kih_1.ai4_1 = ap.ai4_1 + ensureNotNull(this.ijb_1).oiv_1 * av.ai4_1;
              this.jjb_1.kih_1.bi4_1 = ap.bi4_1 + ensureNotNull(this.ijb_1).oiv_1 * av.bi4_1;
              this.jjb_1.lih_1 = 1.0;
              if (fixture.hiu(this.kjb_1, this.jjb_1, childIndex)) {
                var p = this.ljb_1;
                p.ai4_1 = (1 - this.kjb_1.nih_1) * this.jjb_1.jih_1.ai4_1 + this.kjb_1.nih_1 * this.jjb_1.kih_1.ai4_1 + Settings_getInstance().vie() * this.kjb_1.mih_1.ai4_1;
                p.bi4_1 = (1 - this.kjb_1.nih_1) * this.jjb_1.jih_1.bi4_1 + this.kjb_1.nih_1 * this.jjb_1.kih_1.bi4_1 + Settings_getInstance().vie() * this.kjb_1.mih_1.bi4_1;
                var vx = ensureNotNull(this.ijb_1).piv_1 * (p.ai4_1 - ap.ai4_1);
                var vy = ensureNotNull(this.ijb_1).piv_1 * (p.bi4_1 - ap.bi4_1);
                av.ai4_1 = vx;
                av.bi4_1 = vy;
                var particleMass = ensureNotNull(this.hjb_1).njb();
                var ax = particleMass * (av.ai4_1 - vx);
                var ay = particleMass * (av.bi4_1 - vy);
                var b = this.kjb_1.mih_1;
                var fdn = ax * b.ai4_1 + ay * b.bi4_1;
                var f = this.mjb_1;
                f.ai4_1 = fdn * b.ai4_1;
                f.bi4_1 = fdn * b.bi4_1;
                body.rir(f, p, true);
              }
            }
          }
           while (inductionVariable_0 < lastProxy);
      }
       while (inductionVariable < childCount);
    return true;
  }
}
class Test {
  ojb(proxy) {
    return proxy.rj9_1 < 0;
  }
  pjb(contact) {
    return contact.ej9_1 < 0 || contact.fj9_1 < 0;
  }
  qjb(contact) {
    return contact.tj8_1 < 0;
  }
  rjb(pair) {
    return pair.sjb_1 < 0 || pair.tjb_1 < 0;
  }
  xjb(triad) {
    return triad.yjb_1 < 0 || triad.zjb_1 < 0 || triad.ajc_1 < 0;
  }
}
class Companion_21 {
  constructor() {
    Companion_instance_21 = this;
    this.ija_1 = ParticleType_instance.ajb_1;
    this.jja_1 = ParticleType_instance.bjb_1;
    this.kja_1 = ParticleType_instance.djb_1;
    this.lja_1 = 12;
    this.mja_1 = 12;
    this.nja_1 = 31;
    this.oja_1 = toLong(1 << (this.mja_1 - 1 | 0));
    this.pja_1 = this.nja_1 - this.mja_1 | 0;
    this.qja_1 = (this.nja_1 - this.mja_1 | 0) - this.lja_1 | 0;
    this.rja_1 = toLong(1 << this.qja_1);
    var tmp = this;
    // Inline function 'kotlin.Long.times' call
    var this_0 = this.rja_1;
    var other = 1 << (this.lja_1 - 1 | 0);
    tmp.sja_1 = this_0.d4(toLong(other));
    this.tja_1 = (1 << this.lja_1) - 1 | 0;
    this.uja_1 = (1 << this.mja_1) - 1 | 0;
  }
  vja(x, y) {
    var tmp = numberToLong(y + this.oja_1.s4()).k4(this.pja_1);
    // Inline function 'kotlin.Long.times' call
    var tmp$ret$0 = this.rja_1.s4() * x;
    return tmp.c4(numberToLong(tmp$ret$0).c4(this.sja_1));
  }
  kjc(tag, x, y) {
    return tag.c4(toLong(y << this.pja_1)).c4(toLong(x << this.qja_1));
  }
}
class ParticleSystem {
  constructor(m_world) {
    Companion_getInstance_23();
    this.aj0_1 = m_world;
    this.bj0_1 = 0;
    this.cj0_1 = 0;
    this.dj0_1 = 0;
    this.ej0_1 = 1.0;
    this.fj0_1 = 1.0;
    this.gj0_1 = 1.0;
    this.hj0_1 = 1.0;
    this.ij0_1 = 1.0;
    this.jj0_1 = 1.0;
    this.kj0_1 = 0;
    this.lj0_1 = 0;
    this.mj0_1 = 0;
    this.nj0_1 = new ParticleBufferInt();
    var tmp = this;
    tmp.oj0_1 = new ParticleBuffer(ParticleSystem$m_positionBuffer$lambda);
    var tmp_0 = this;
    tmp_0.pj0_1 = new ParticleBuffer(ParticleSystem$m_velocityBuffer$lambda);
    this.qj0_1 = new Float32Array(0);
    var tmp_1 = this;
    // Inline function 'kotlin.emptyArray' call
    tmp_1.rj0_1 = [];
    this.sj0_1 = null;
    var tmp_2 = this;
    tmp_2.tj0_1 = new ParticleBuffer(ParticleSystem$m_colorBuffer$lambda);
    var tmp_3 = this;
    // Inline function 'kotlin.emptyArray' call
    tmp_3.uj0_1 = [];
    var tmp_4 = this;
    tmp_4.vj0_1 = new ParticleBuffer(ParticleSystem$m_userDataBuffer$lambda);
    this.wj0_1 = 0;
    this.xj0_1 = 0;
    var tmp_5 = this;
    // Inline function 'kotlin.emptyArray' call
    tmp_5.yj0_1 = [];
    this.zj0_1 = 0;
    this.aj1_1 = 0;
    var tmp_6 = this;
    // Inline function 'kotlin.emptyArray' call
    tmp_6.bj1_1 = [];
    this.cj1_1 = 0;
    this.dj1_1 = 0;
    var tmp_7 = this;
    // Inline function 'kotlin.emptyArray' call
    tmp_7.ej1_1 = [];
    this.fj1_1 = 0;
    this.gj1_1 = 0;
    var tmp_8 = this;
    // Inline function 'kotlin.emptyArray' call
    tmp_8.hj1_1 = [];
    this.ij1_1 = 0;
    this.jj1_1 = 0;
    var tmp_9 = this;
    // Inline function 'kotlin.emptyArray' call
    tmp_9.kj1_1 = [];
    this.lj1_1 = 0;
    this.mj1_1 = null;
    this.nj1_1 = 0.05;
    this.oj1_1 = 1.0;
    this.pj1_1 = 0.25;
    this.qj1_1 = 0.25;
    this.rj1_1 = 0.25;
    this.sj1_1 = 0.1;
    this.tj1_1 = 0.2;
    this.uj1_1 = 0.5;
    this.vj1_1 = 0.5;
    this.wj1_1 = 0.5;
    this.xj1_1 = AABB.ai8();
    this.yj1_1 = new DestroyParticlesInShapeCallback();
    this.zj1_1 = AABB.ai8();
    this.aj2_1 = new Vec2();
    this.bj2_1 = Transform.iia();
    this.cj2_1 = Transform.iia();
    this.dj2_1 = new CreateParticleGroupCallback();
    this.ej2_1 = new ParticleDef();
    this.fj2_1 = new UpdateBodyContactsCallback();
    this.gj2_1 = new SolveCollisionCallback();
    this.hj2_1 = new Vec2();
    this.ij2_1 = Rot.lio();
    this.jj2_1 = Transform.iia();
    this.kj2_1 = Transform.iia();
    this.lj2_1 = new NewIndices();
  }
  ljc() {
    return Settings_getInstance().oi7_1 * this.hj0_1;
  }
  njb() {
    var stride = this.ljc();
    return this.ej0_1 * stride * stride;
  }
  wja() {
    return 1.777777 * this.fj0_1 * this.ij0_1 * this.ij0_1;
  }
  mjc(group) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.lj1_1 > 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!!(group == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    if (!(this.aj0_1.ji2_1 == null)) {
      ensureNotNull(this.aj0_1.ji2_1).njc(ensureNotNull(group));
    }
    var inductionVariable = ensureNotNull(group).pjc_1;
    var last = group.qjc_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        ensureNotNull(this.uj0_1)[i] = null;
      }
       while (inductionVariable < last);
    if (!(group.tjc_1 == null)) {
      ensureNotNull(group.tjc_1).ujc_1 = group.ujc_1;
    }
    if (!(group.ujc_1 == null)) {
      ensureNotNull(group.ujc_1).tjc_1 = group.tjc_1;
    }
    if (equals(group, this.mj1_1)) {
      this.mj1_1 = group.ujc_1;
    }
    this.lj1_1 = this.lj1_1 - 1 | 0;
  }
  gjd(a, b) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(a === b)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var pa = ensureNotNull(this.oj0_1.xj9_1)[a];
    var pb = ensureNotNull(this.oj0_1.xj9_1)[b];
    var dx = pb.ai4_1 - pa.ai4_1;
    var dy = pb.bi4_1 - pa.bi4_1;
    var d2 = dx * dx + dy * dy;
    if (d2 < this.jj0_1) {
      if (this.zj0_1 >= this.aj1_1) {
        var oldCapacity = this.aj1_1;
        var newCapacity = !(this.zj0_1 === 0) ? imul(2, this.zj0_1) : Settings_getInstance().ti7_1;
        var tmp_0 = this;
        var tmp_1 = BufferUtils_instance;
        tmp_0.bj1_1 = tmp_1.ain(ParticleSystem$addContact$lambda, this.bj1_1, oldCapacity, newCapacity);
        this.aj1_1 = newCapacity;
      }
      var invD = !(d2 === 0.0) ? Companion_getInstance_11().qim(1 / d2) : 3.4028235E38;
      var contact = this.bj1_1[this.zj0_1];
      contact.ej9_1 = a;
      contact.fj9_1 = b;
      contact.gj9_1 = ensureNotNull(this.nj0_1.zj9_1)[a] | ensureNotNull(this.nj0_1.zj9_1)[b];
      contact.hj9_1 = 1 - d2 * invD * this.ij0_1;
      contact.ij9_1.ai4_1 = invD * dx;
      contact.ij9_1.bi4_1 = invD * dy;
      this.zj0_1 = this.zj0_1 + 1 | 0;
    }
  }
  hjd(exceptZombie) {
    var inductionVariable = 0;
    var last = this.wj0_1;
    if (inductionVariable < last)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var proxy = this.yj0_1[p];
        var i = proxy.rj9_1;
        var pos = ensureNotNull(this.oj0_1.xj9_1)[i];
        proxy.sj9_1 = Companion_getInstance_23().vja(this.ij0_1 * pos.ai4_1, this.ij0_1 * pos.bi4_1);
      }
       while (inductionVariable < last);
    Arrays_sort(this.yj0_1, 0, this.wj0_1);
    this.zj0_1 = 0;
    var c_index = 0;
    var inductionVariable_0 = 0;
    var last_0 = this.wj0_1;
    if (inductionVariable_0 < last_0)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var a = this.yj0_1[i_0];
        var rightTag = Companion_getInstance_23().kjc(a.sj9_1, 1, 0);
        var inductionVariable_1 = i_0 + 1 | 0;
        var last_1 = this.wj0_1;
        if (inductionVariable_1 < last_1)
          $l$loop: do {
            var j = inductionVariable_1;
            inductionVariable_1 = inductionVariable_1 + 1 | 0;
            var b = this.yj0_1[j];
            if (rightTag.g2(b.sj9_1) < 0) {
              break $l$loop;
            }
            this.gjd(a.rj9_1, b.rj9_1);
          }
           while (inductionVariable_1 < last_1);
        var bottomLeftTag = Companion_getInstance_23().kjc(a.sj9_1, -1, 1);
        $l$loop_0: while (c_index < this.wj0_1) {
          var c = this.yj0_1[c_index];
          if (bottomLeftTag.g2(c.sj9_1) <= 0) {
            break $l$loop_0;
          }
          c_index = c_index + 1 | 0;
        }
        var bottomRightTag = Companion_getInstance_23().kjc(a.sj9_1, 1, 1);
        var inductionVariable_2 = c_index;
        var last_2 = this.wj0_1;
        if (inductionVariable_2 < last_2)
          $l$loop_1: do {
            var b_index = inductionVariable_2;
            inductionVariable_2 = inductionVariable_2 + 1 | 0;
            var b_0 = this.yj0_1[b_index];
            if (bottomRightTag.g2(b_0.sj9_1) < 0) {
              break $l$loop_1;
            }
            this.gjd(a.rj9_1, b_0.rj9_1);
          }
           while (inductionVariable_2 < last_2);
      }
       while (inductionVariable_0 < last_0);
    if (exceptZombie) {
      var j_0 = this.zj0_1;
      var i_1 = 0;
      while (i_1 < j_0) {
        if (!((this.bj1_1[i_1].gj9_1 & ParticleType_instance.yja_1) === 0)) {
          j_0 = j_0 - 1 | 0;
          var temp = this.bj1_1[j_0];
          this.bj1_1[j_0] = this.bj1_1[i_1];
          this.bj1_1[i_1] = temp;
          i_1 = i_1 - 1 | 0;
        }
        i_1 = i_1 + 1 | 0;
      }
      this.zj0_1 = j_0;
    }
  }
  ijd() {
    var aabb = this.xj1_1;
    aabb.wi7_1.ai4_1 = 3.4028235E38;
    aabb.wi7_1.bi4_1 = 3.4028235E38;
    aabb.xi7_1.ai4_1 = -3.4028235E38;
    aabb.xi7_1.bi4_1 = -3.4028235E38;
    var inductionVariable = 0;
    var last = this.kj0_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var p = ensureNotNull(this.oj0_1.xj9_1)[i];
        Companion_instance_12.iip(aabb.wi7_1, p, aabb.wi7_1);
        Companion_instance_12.jip(aabb.xi7_1, p, aabb.xi7_1);
      }
       while (inductionVariable < last);
    var _receiver__tnumb7 = aabb.wi7_1;
    _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - this.hj0_1;
    var _receiver__tnumb7_0 = aabb.wi7_1;
    _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - this.hj0_1;
    var _receiver__tnumb7_1 = aabb.xi7_1;
    _receiver__tnumb7_1.ai4_1 = _receiver__tnumb7_1.ai4_1 + this.hj0_1;
    var _receiver__tnumb7_2 = aabb.xi7_1;
    _receiver__tnumb7_2.bi4_1 = _receiver__tnumb7_2.bi4_1 + this.hj0_1;
    this.cj1_1 = 0;
    this.fj2_1.gja_1 = this;
    this.aj0_1.oj2(this.fj2_1, aabb);
  }
  jjd(step) {
    var aabb = this.xj1_1;
    var lowerBound = aabb.wi7_1;
    var upperBound = aabb.xi7_1;
    lowerBound.ai4_1 = 3.4028235E38;
    lowerBound.bi4_1 = 3.4028235E38;
    upperBound.ai4_1 = -3.4028235E38;
    upperBound.bi4_1 = -3.4028235E38;
    var inductionVariable = 0;
    var last = this.kj0_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = ensureNotNull(this.pj0_1.xj9_1)[i];
        var p1 = ensureNotNull(this.oj0_1.xj9_1)[i];
        var p1x = p1.ai4_1;
        var p1y = p1.bi4_1;
        var p2x = p1x + step.oiv_1 * v.ai4_1;
        var p2y = p1y + step.oiv_1 * v.bi4_1;
        var bx = p1x < p2x ? p1x : p2x;
        var by = p1y < p2y ? p1y : p2y;
        lowerBound.ai4_1 = lowerBound.ai4_1 < bx ? lowerBound.ai4_1 : bx;
        lowerBound.bi4_1 = lowerBound.bi4_1 < by ? lowerBound.bi4_1 : by;
        var b1x = p1x > p2x ? p1x : p2x;
        var b1y = p1y > p2y ? p1y : p2y;
        upperBound.ai4_1 = upperBound.ai4_1 > b1x ? upperBound.ai4_1 : b1x;
        upperBound.bi4_1 = upperBound.bi4_1 > b1y ? upperBound.bi4_1 : b1y;
      }
       while (inductionVariable < last);
    this.gj2_1.ijb_1 = step;
    this.gj2_1.hjb_1 = this;
    this.aj0_1.oj2(this.gj2_1, aabb);
  }
  mj2(step) {
    this.bj0_1 = this.bj0_1 + 1 | 0;
    if (this.kj0_1 === 0) {
      return Unit_instance;
    }
    this.cj0_1 = 0;
    var inductionVariable = 0;
    var last = this.kj0_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.cj0_1 = this.cj0_1 | ensureNotNull(this.nj0_1.zj9_1)[i];
      }
       while (inductionVariable < last);
    if (!((this.cj0_1 & ParticleType_instance.yja_1) === 0)) {
      this.kjd();
    }
    if (this.kj0_1 === 0) {
      return Unit_instance;
    }
    this.dj0_1 = 0;
    var group = this.mj1_1;
    while (!(group == null)) {
      this.dj0_1 = this.dj0_1 | group.rjc_1;
      group = group.kiq();
    }
    var gravityx = step.oiv_1 * this.gj0_1 * this.aj0_1.gi2_1.ai4_1;
    var gravityy = step.oiv_1 * this.gj0_1 * this.aj0_1.gi2_1.bi4_1;
    var criticalVelocytySquared = this.ljd(step);
    var inductionVariable_0 = 0;
    var last_0 = this.kj0_1;
    if (inductionVariable_0 < last_0)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var v = ensureNotNull(this.pj0_1.xj9_1)[i_0];
        v.ai4_1 = v.ai4_1 + gravityx;
        v.bi4_1 = v.bi4_1 + gravityy;
        var v2 = v.ai4_1 * v.ai4_1 + v.bi4_1 * v.bi4_1;
        if (v2 > criticalVelocytySquared) {
          var a = v2 === 0.0 ? 3.4028235E38 : Companion_getInstance_11().qim(criticalVelocytySquared / v2);
          v.ai4_1 = v.ai4_1 * a;
          v.bi4_1 = v.bi4_1 * a;
        }
      }
       while (inductionVariable_0 < last_0);
    this.jjd(step);
    if (!((this.dj0_1 & ParticleGroupType_instance.qj9_1) === 0)) {
      this.mjd(step);
    }
    if (!((this.cj0_1 & ParticleType_instance.zja_1) === 0)) {
      this.njd(step);
    }
    var inductionVariable_1 = 0;
    var last_1 = this.kj0_1;
    if (inductionVariable_1 < last_1)
      do {
        var i_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var pos = ensureNotNull(this.oj0_1.xj9_1)[i_1];
        var vel = ensureNotNull(this.pj0_1.xj9_1)[i_1];
        pos.ai4_1 = pos.ai4_1 + step.oiv_1 * vel.ai4_1;
        pos.bi4_1 = pos.bi4_1 + step.oiv_1 * vel.bi4_1;
      }
       while (inductionVariable_1 < last_1);
    this.ijd();
    this.hjd(false);
    if (!((this.cj0_1 & ParticleType_instance.cjb_1) === 0)) {
      this.ojd(step);
    }
    if (!((this.cj0_1 & ParticleType_instance.djb_1) === 0)) {
      this.pjd(step);
    }
    if (!((this.cj0_1 & ParticleType_instance.ejb_1) === 0)) {
      this.qjd(step);
    }
    if (!((this.cj0_1 & ParticleType_instance.bjb_1) === 0)) {
      this.rjd(step);
    }
    if (!((this.cj0_1 & ParticleType_instance.ajb_1) === 0)) {
      this.sjd(step);
    }
    if (!((this.dj0_1 & ParticleGroupType_instance.pj9_1) === 0)) {
      this.tjd(step);
    }
    if (!((this.cj0_1 & ParticleType_instance.fjb_1) === 0)) {
      this.ujd(step);
    }
    this.vjd(step);
    this.wjd(step);
  }
  vjd(step) {
    var inductionVariable = 0;
    var last = this.kj0_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.qj0_1[i] = 0.0;
      }
       while (inductionVariable < last);
    var inductionVariable_0 = 0;
    var last_0 = this.cj1_1;
    if (inductionVariable_0 < last_0)
      do {
        var k = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var contact = this.ej1_1[k];
        var a = contact.tj8_1;
        var w = contact.vj8_1;
        var _array__4zh2yp = this.qj0_1;
        _array__4zh2yp[a] = _array__4zh2yp[a] + w;
      }
       while (inductionVariable_0 < last_0);
    var inductionVariable_1 = 0;
    var last_1 = this.zj0_1;
    if (inductionVariable_1 < last_1)
      do {
        var k_0 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var contact_0 = this.bj1_1[k_0];
        var a_0 = contact_0.ej9_1;
        var b = contact_0.fj9_1;
        var w_0 = contact_0.hj9_1;
        var _array__4zh2yp_0 = this.qj0_1;
        _array__4zh2yp_0[a_0] = _array__4zh2yp_0[a_0] + w_0;
        var _array__4zh2yp_1 = this.qj0_1;
        _array__4zh2yp_1[b] = _array__4zh2yp_1[b] + w_0;
      }
       while (inductionVariable_1 < last_1);
    if (!((this.cj0_1 & Companion_getInstance_23().kja_1) === 0)) {
      var inductionVariable_2 = 0;
      var last_2 = this.kj0_1;
      if (inductionVariable_2 < last_2)
        do {
          var i_0 = inductionVariable_2;
          inductionVariable_2 = inductionVariable_2 + 1 | 0;
          if (!((ensureNotNull(this.nj0_1.zj9_1)[i_0] & Companion_getInstance_23().kja_1) === 0)) {
            this.qj0_1[i_0] = 0.0;
          }
        }
         while (inductionVariable_2 < last_2);
    }
    var pressurePerWeight = this.nj1_1 * this.xjd(step);
    var inductionVariable_3 = 0;
    var last_3 = this.kj0_1;
    if (inductionVariable_3 < last_3)
      do {
        var i_1 = inductionVariable_3;
        inductionVariable_3 = inductionVariable_3 + 1 | 0;
        var w_1 = ensureNotNull(this.qj0_1)[i_1];
        var h = pressurePerWeight * Companion_getInstance_11().wii(0.0, Companion_getInstance_11().bic(w_1, Settings_getInstance().qi7_1) - Settings_getInstance().pi7_1);
        this.qj0_1[i_1] = h;
      }
       while (inductionVariable_3 < last_3);
    var velocityPerPressure = step.oiv_1 / (this.ej0_1 * this.hj0_1);
    var inductionVariable_4 = 0;
    var last_4 = this.cj1_1;
    if (inductionVariable_4 < last_4)
      do {
        var k_1 = inductionVariable_4;
        inductionVariable_4 = inductionVariable_4 + 1 | 0;
        var contact_1 = this.ej1_1[k_1];
        var a_1 = contact_1.tj8_1;
        var b_0 = contact_1.uj8_1;
        var w_2 = contact_1.vj8_1;
        var m = contact_1.xj8_1;
        var n = contact_1.wj8_1;
        var p = ensureNotNull(this.oj0_1.xj9_1)[a_1];
        var h_0 = ensureNotNull(this.qj0_1)[a_1] + pressurePerWeight * w_2;
        var f = this.aj2_1;
        var coef = velocityPerPressure * w_2 * m * h_0;
        f.ai4_1 = coef * n.ai4_1;
        f.bi4_1 = coef * n.bi4_1;
        var velData = ensureNotNull(this.pj0_1.xj9_1)[a_1];
        var particleInvMass = this.wja();
        velData.ai4_1 = velData.ai4_1 - particleInvMass * f.ai4_1;
        velData.bi4_1 = velData.bi4_1 - particleInvMass * f.bi4_1;
        ensureNotNull(b_0).rir(f, p, true);
      }
       while (inductionVariable_4 < last_4);
    var inductionVariable_5 = 0;
    var last_5 = this.zj0_1;
    if (inductionVariable_5 < last_5)
      do {
        var k_2 = inductionVariable_5;
        inductionVariable_5 = inductionVariable_5 + 1 | 0;
        var contact_2 = this.bj1_1[k_2];
        var a_2 = contact_2.ej9_1;
        var b_1 = contact_2.fj9_1;
        var w_3 = contact_2.hj9_1;
        var n_0 = contact_2.ij9_1;
        var h_1 = ensureNotNull(this.qj0_1)[a_2] + ensureNotNull(this.qj0_1)[b_1];
        var fx = velocityPerPressure * w_3 * h_1 * n_0.ai4_1;
        var fy = velocityPerPressure * w_3 * h_1 * n_0.bi4_1;
        var velDataA = ensureNotNull(this.pj0_1.xj9_1)[a_2];
        var velDataB = ensureNotNull(this.pj0_1.xj9_1)[b_1];
        velDataA.ai4_1 = velDataA.ai4_1 - fx;
        velDataA.bi4_1 = velDataA.bi4_1 - fy;
        velDataB.ai4_1 = velDataB.ai4_1 + fx;
        velDataB.bi4_1 = velDataB.bi4_1 + fy;
      }
       while (inductionVariable_5 < last_5);
  }
  wjd(step) {
    var damping = this.oj1_1;
    var inductionVariable = 0;
    var last = this.cj1_1;
    if (inductionVariable < last)
      do {
        var k = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var contact = this.ej1_1[k];
        var a = contact.tj8_1;
        var b = contact.uj8_1;
        var w = contact.vj8_1;
        var m = contact.xj8_1;
        var n = contact.wj8_1;
        var p = ensureNotNull(this.oj0_1.xj9_1)[a];
        var tempX = p.ai4_1 - ensureNotNull(b).ni4_1.qii_1.ai4_1;
        var tempY = p.bi4_1 - ensureNotNull(b).ni4_1.qii_1.bi4_1;
        var velA = ensureNotNull(this.pj0_1.xj9_1)[a];
        var vx = -ensureNotNull(b).pi4_1 * tempY + ensureNotNull(b).oi4_1.ai4_1 - velA.ai4_1;
        var vy = ensureNotNull(b).pi4_1 * tempX + ensureNotNull(b).oi4_1.bi4_1 - velA.bi4_1;
        var vn = vx * n.ai4_1 + vy * n.bi4_1;
        if (vn < 0) {
          var f = this.aj2_1;
          f.ai4_1 = damping * w * m * vn * n.ai4_1;
          f.bi4_1 = damping * w * m * vn * n.bi4_1;
          var invMass = this.wja();
          velA.ai4_1 = velA.ai4_1 + invMass * f.ai4_1;
          velA.bi4_1 = velA.bi4_1 + invMass * f.bi4_1;
          f.ai4_1 = -f.ai4_1;
          f.bi4_1 = -f.bi4_1;
          ensureNotNull(b).rir(f, p, true);
        }
      }
       while (inductionVariable < last);
    var inductionVariable_0 = 0;
    var last_0 = this.zj0_1;
    if (inductionVariable_0 < last_0)
      do {
        var k_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var contact_0 = this.bj1_1[k_0];
        var a_0 = contact_0.ej9_1;
        var b_0 = contact_0.fj9_1;
        var w_0 = contact_0.hj9_1;
        var n_0 = contact_0.ij9_1;
        var velA_0 = ensureNotNull(this.pj0_1.xj9_1)[a_0];
        var velB = ensureNotNull(this.pj0_1.xj9_1)[b_0];
        var vx_0 = velB.ai4_1 - velA_0.ai4_1;
        var vy_0 = velB.bi4_1 - velA_0.bi4_1;
        var vn_0 = vx_0 * n_0.ai4_1 + vy_0 * n_0.bi4_1;
        if (vn_0 < 0) {
          var fx = damping * w_0 * vn_0 * n_0.ai4_1;
          var fy = damping * w_0 * vn_0 * n_0.bi4_1;
          velA_0.ai4_1 = velA_0.ai4_1 + fx;
          velA_0.bi4_1 = velA_0.bi4_1 + fy;
          velB.ai4_1 = velB.ai4_1 - fx;
          velB.bi4_1 = velB.bi4_1 - fy;
        }
      }
       while (inductionVariable_0 < last_0);
  }
  njd(step) {
    var inductionVariable = 0;
    var last = this.kj0_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!((ensureNotNull(this.nj0_1.zj9_1)[i] & ParticleType_instance.zja_1) === 0)) {
          var r = ensureNotNull(this.pj0_1.xj9_1)[i];
          r.ai4_1 = 0.0;
          r.bi4_1 = 0.0;
        }
      }
       while (inductionVariable < last);
  }
  mjd(step) {
    var group = this.mj1_1;
    while (!(group == null)) {
      if (!((group.rjc_1 & ParticleGroupType_instance.qj9_1) === 0)) {
        group.yjd();
        var temp = this.aj2_1;
        var cross = this.hj2_1;
        var rotation = this.ij2_1;
        rotation.nio(step.oiv_1 * group.ajd_1);
        Companion_instance_10.aib(rotation, group.yjc_1, cross);
        temp.via(group.zjc_1).zie(step.oiv_1).aif(group.yjc_1).wia(cross);
        this.jj2_1.gia_1.via(temp);
        this.jj2_1.hia_1.oio(rotation);
        Companion_instance_11.gip(this.jj2_1, group.bjd_1, group.bjd_1);
        var velocityTransform = this.kj2_1;
        velocityTransform.gia_1.ai4_1 = step.piv_1 * this.jj2_1.gia_1.ai4_1;
        velocityTransform.gia_1.bi4_1 = step.piv_1 * this.jj2_1.gia_1.bi4_1;
        velocityTransform.hia_1.mie_1 = step.piv_1 * this.jj2_1.hia_1.mie_1;
        velocityTransform.hia_1.nie_1 = step.piv_1 * (this.jj2_1.hia_1.nie_1 - 1);
        var inductionVariable = group.pjc_1;
        var last = group.qjc_1;
        if (inductionVariable < last)
          do {
            var i = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            Companion_instance_11.lia(velocityTransform, ensureNotNull(this.oj0_1.xj9_1)[i], ensureNotNull(this.pj0_1.xj9_1)[i]);
          }
           while (inductionVariable < last);
      }
      group = group.kiq();
    }
  }
  rjd(step) {
    var elasticStrength = step.piv_1 * this.pj1_1;
    var inductionVariable = 0;
    var last = this.ij1_1;
    if (inductionVariable < last)
      do {
        var k = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var triad = this.kj1_1[k];
        if (!((triad.bjc_1 & ParticleType_instance.bjb_1) === 0)) {
          var a = triad.yjb_1;
          var b = triad.zjb_1;
          var c = triad.ajc_1;
          var oa = triad.djc_1;
          var ob = triad.ejc_1;
          var oc = triad.fjc_1;
          var pa = ensureNotNull(this.oj0_1.xj9_1)[a];
          var pb = ensureNotNull(this.oj0_1.xj9_1)[b];
          var pc = ensureNotNull(this.oj0_1.xj9_1)[c];
          var px = 1.0 / 3 * (pa.ai4_1 + pb.ai4_1 + pc.ai4_1);
          var py = 1.0 / 3 * (pa.bi4_1 + pb.bi4_1 + pc.bi4_1);
          var rs = Companion_instance_12.zia(oa, pa) + Companion_instance_12.zia(ob, pb) + Companion_instance_12.zia(oc, pc);
          var rc = Companion_instance_12.yia(oa, pa) + Companion_instance_12.yia(ob, pb) + Companion_instance_12.yia(oc, pc);
          var r2 = rs * rs + rc * rc;
          var invR = r2 === 0.0 ? 3.4028235E38 : Companion_getInstance_11().qim(1.0 / r2);
          rs = rs * invR;
          rc = rc * invR;
          var strength = elasticStrength * triad.cjc_1;
          var roax = rc * oa.ai4_1 - rs * oa.bi4_1;
          var roay = rs * oa.ai4_1 + rc * oa.bi4_1;
          var robx = rc * ob.ai4_1 - rs * ob.bi4_1;
          var roby = rs * ob.ai4_1 + rc * ob.bi4_1;
          var rocx = rc * oc.ai4_1 - rs * oc.bi4_1;
          var rocy = rs * oc.ai4_1 + rc * oc.bi4_1;
          var va = ensureNotNull(this.pj0_1.xj9_1)[a];
          var vb = ensureNotNull(this.pj0_1.xj9_1)[b];
          var vc = ensureNotNull(this.pj0_1.xj9_1)[c];
          va.ai4_1 = va.ai4_1 + strength * (roax - (pa.ai4_1 - px));
          va.bi4_1 = va.bi4_1 + strength * (roay - (pa.bi4_1 - py));
          vb.ai4_1 = vb.ai4_1 + strength * (robx - (pb.ai4_1 - px));
          vb.bi4_1 = vb.bi4_1 + strength * (roby - (pb.bi4_1 - py));
          vc.ai4_1 = vc.ai4_1 + strength * (rocx - (pc.ai4_1 - px));
          vc.bi4_1 = vc.bi4_1 + strength * (rocy - (pc.bi4_1 - py));
        }
      }
       while (inductionVariable < last);
  }
  sjd(step) {
    var springStrength = step.piv_1 * this.qj1_1;
    var inductionVariable = 0;
    var last = this.fj1_1;
    if (inductionVariable < last)
      do {
        var k = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var pair = this.hj1_1[k];
        if (!((pair.ujb_1 & ParticleType_instance.ajb_1) === 0)) {
          var a = pair.sjb_1;
          var b = pair.tjb_1;
          var pa = ensureNotNull(this.oj0_1.xj9_1)[a];
          var pb = ensureNotNull(this.oj0_1.xj9_1)[b];
          var dx = pb.ai4_1 - pa.ai4_1;
          var dy = pb.bi4_1 - pa.bi4_1;
          var r0 = pair.wjb_1;
          var r1 = Companion_getInstance_11().qim(dx * dx + dy * dy);
          if (r1 === 0.0)
            r1 = 3.4028235E38;
          var strength = springStrength * pair.vjb_1;
          var fx = strength * (r0 - r1) / r1 * dx;
          var fy = strength * (r0 - r1) / r1 * dy;
          var va = ensureNotNull(this.pj0_1.xj9_1)[a];
          var vb = ensureNotNull(this.pj0_1.xj9_1)[b];
          va.ai4_1 = va.ai4_1 - fx;
          va.bi4_1 = va.bi4_1 - fy;
          vb.ai4_1 = vb.ai4_1 + fx;
          vb.bi4_1 = vb.bi4_1 + fy;
        }
      }
       while (inductionVariable < last);
  }
  qjd(step) {
    var tmp = this;
    tmp.rj0_1 = this.zjd(ParticleSystem$solveTensile$lambda, this.rj0_1);
    var inductionVariable = 0;
    var last = this.kj0_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.qj0_1[i] = 0.0;
        ensureNotNull(this.rj0_1)[i].ki6();
      }
       while (inductionVariable < last);
    var inductionVariable_0 = 0;
    var last_0 = this.zj0_1;
    if (inductionVariable_0 < last_0)
      do {
        var k = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var contact = this.bj1_1[k];
        if (!((contact.gj9_1 & ParticleType_instance.ejb_1) === 0)) {
          var a = contact.ej9_1;
          var b = contact.fj9_1;
          var w = contact.hj9_1;
          var n = contact.ij9_1;
          var _array__4zh2yp = this.qj0_1;
          _array__4zh2yp[a] = _array__4zh2yp[a] + w;
          var _array__4zh2yp_0 = this.qj0_1;
          _array__4zh2yp_0[b] = _array__4zh2yp_0[b] + w;
          var a2A = ensureNotNull(this.rj0_1)[a];
          var a2B = ensureNotNull(this.rj0_1)[b];
          var inter = (1 - w) * w;
          a2A.ai4_1 = a2A.ai4_1 - inter * n.ai4_1;
          a2A.bi4_1 = a2A.bi4_1 - inter * n.bi4_1;
          a2B.ai4_1 = a2B.ai4_1 + inter * n.ai4_1;
          a2B.bi4_1 = a2B.bi4_1 + inter * n.bi4_1;
        }
      }
       while (inductionVariable_0 < last_0);
    var strengthA = this.sj1_1 * this.aje(step);
    var strengthB = this.tj1_1 * this.aje(step);
    var inductionVariable_1 = 0;
    var last_1 = this.zj0_1;
    if (inductionVariable_1 < last_1)
      do {
        var k_0 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var contact_0 = this.bj1_1[k_0];
        if (!((contact_0.gj9_1 & ParticleType_instance.ejb_1) === 0)) {
          var a_0 = contact_0.ej9_1;
          var b_0 = contact_0.fj9_1;
          var w_0 = contact_0.hj9_1;
          var n_0 = contact_0.ij9_1;
          var a2A_0 = ensureNotNull(this.rj0_1)[a_0];
          var a2B_0 = ensureNotNull(this.rj0_1)[b_0];
          var h = ensureNotNull(this.qj0_1)[a_0] + ensureNotNull(this.qj0_1)[b_0];
          var sx = a2B_0.ai4_1 - a2A_0.ai4_1;
          var sy = a2B_0.bi4_1 - a2A_0.bi4_1;
          var fn = (strengthA * (h - 2) + strengthB * (sx * n_0.ai4_1 + sy * n_0.bi4_1)) * w_0;
          var fx = fn * n_0.ai4_1;
          var fy = fn * n_0.bi4_1;
          var va = ensureNotNull(this.pj0_1.xj9_1)[a_0];
          var vb = ensureNotNull(this.pj0_1.xj9_1)[b_0];
          va.ai4_1 = va.ai4_1 - fx;
          va.bi4_1 = va.bi4_1 - fy;
          vb.ai4_1 = vb.ai4_1 + fx;
          vb.bi4_1 = vb.bi4_1 + fy;
        }
      }
       while (inductionVariable_1 < last_1);
  }
  ojd(step) {
    var viscousStrength = this.rj1_1;
    var inductionVariable = 0;
    var last = this.cj1_1;
    if (inductionVariable < last)
      do {
        var k = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var contact = this.ej1_1[k];
        var a = contact.tj8_1;
        if (!((ensureNotNull(this.nj0_1.zj9_1)[a] & ParticleType_instance.cjb_1) === 0)) {
          var b = contact.uj8_1;
          var w = contact.vj8_1;
          var m = contact.xj8_1;
          var p = ensureNotNull(this.oj0_1.xj9_1)[a];
          var va = ensureNotNull(this.pj0_1.xj9_1)[a];
          var tempX = p.ai4_1 - ensureNotNull(b).ni4_1.qii_1.ai4_1;
          var tempY = p.bi4_1 - ensureNotNull(b).ni4_1.qii_1.bi4_1;
          var vx = -ensureNotNull(b).pi4_1 * tempY + ensureNotNull(b).oi4_1.ai4_1 - va.ai4_1;
          var vy = ensureNotNull(b).pi4_1 * tempX + ensureNotNull(b).oi4_1.bi4_1 - va.bi4_1;
          var f = this.aj2_1;
          var pInvMass = this.wja();
          f.ai4_1 = viscousStrength * m * w * vx;
          f.bi4_1 = viscousStrength * m * w * vy;
          va.ai4_1 = va.ai4_1 + pInvMass * f.ai4_1;
          va.bi4_1 = va.bi4_1 + pInvMass * f.bi4_1;
          f.ai4_1 = -f.ai4_1;
          f.bi4_1 = -f.bi4_1;
          ensureNotNull(b).rir(f, p, true);
        }
      }
       while (inductionVariable < last);
    var inductionVariable_0 = 0;
    var last_0 = this.zj0_1;
    if (inductionVariable_0 < last_0)
      do {
        var k_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var contact_0 = this.bj1_1[k_0];
        if (!((contact_0.gj9_1 & ParticleType_instance.cjb_1) === 0)) {
          var a_0 = contact_0.ej9_1;
          var b_0 = contact_0.fj9_1;
          var w_0 = contact_0.hj9_1;
          var va_0 = ensureNotNull(this.pj0_1.xj9_1)[a_0];
          var vb = ensureNotNull(this.pj0_1.xj9_1)[b_0];
          var vx_0 = vb.ai4_1 - va_0.ai4_1;
          var vy_0 = vb.bi4_1 - va_0.bi4_1;
          var fx = viscousStrength * w_0 * vx_0;
          var fy = viscousStrength * w_0 * vy_0;
          va_0.ai4_1 = va_0.ai4_1 + fx;
          va_0.bi4_1 = va_0.bi4_1 + fy;
          vb.ai4_1 = vb.ai4_1 - fx;
          vb.bi4_1 = vb.bi4_1 - fy;
        }
      }
       while (inductionVariable_0 < last_0);
  }
  pjd(step) {
    var powderStrength = this.uj1_1 * this.aje(step);
    var minWeight = 1.0 - Settings_getInstance().oi7_1;
    var inductionVariable = 0;
    var last = this.cj1_1;
    if (inductionVariable < last)
      do {
        var k = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var contact = this.ej1_1[k];
        var a = contact.tj8_1;
        if (!((ensureNotNull(this.nj0_1.zj9_1)[a] & ParticleType_instance.djb_1) === 0)) {
          var w = contact.vj8_1;
          if (w > minWeight) {
            var b = contact.uj8_1;
            var m = contact.xj8_1;
            var p = ensureNotNull(this.oj0_1.xj9_1)[a];
            var n = contact.wj8_1;
            var f = this.aj2_1;
            var va = ensureNotNull(this.pj0_1.xj9_1)[a];
            var inter = powderStrength * m * (w - minWeight);
            var pInvMass = this.wja();
            f.ai4_1 = inter * n.ai4_1;
            f.bi4_1 = inter * n.bi4_1;
            va.ai4_1 = va.ai4_1 - pInvMass * f.ai4_1;
            va.bi4_1 = va.bi4_1 - pInvMass * f.bi4_1;
            ensureNotNull(b).rir(f, p, true);
          }
        }
      }
       while (inductionVariable < last);
    var inductionVariable_0 = 0;
    var last_0 = this.zj0_1;
    if (inductionVariable_0 < last_0)
      do {
        var k_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var contact_0 = this.bj1_1[k_0];
        if (!((contact_0.gj9_1 & ParticleType_instance.djb_1) === 0)) {
          var w_0 = contact_0.hj9_1;
          if (w_0 > minWeight) {
            var a_0 = contact_0.ej9_1;
            var b_0 = contact_0.fj9_1;
            var n_0 = contact_0.ij9_1;
            var va_0 = ensureNotNull(this.pj0_1.xj9_1)[a_0];
            var vb = ensureNotNull(this.pj0_1.xj9_1)[b_0];
            var inter_0 = powderStrength * (w_0 - minWeight);
            var fx = inter_0 * n_0.ai4_1;
            var fy = inter_0 * n_0.bi4_1;
            va_0.ai4_1 = va_0.ai4_1 - fx;
            va_0.bi4_1 = va_0.bi4_1 - fy;
            vb.ai4_1 = vb.ai4_1 + fx;
            vb.bi4_1 = vb.bi4_1 + fy;
          }
        }
      }
       while (inductionVariable_0 < last_0);
  }
  tjd(step) {
    this.sj0_1 = this.bje(this.sj0_1);
    var ejectionStrength = step.piv_1 * this.vj1_1;
    var inductionVariable = 0;
    var last = this.zj0_1;
    if (inductionVariable < last)
      do {
        var k = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var contact = this.bj1_1[k];
        var a = contact.ej9_1;
        var b = contact.fj9_1;
        if (!equals(ensureNotNull(this.uj0_1)[a], ensureNotNull(this.uj0_1)[b])) {
          var w = contact.hj9_1;
          var n = contact.ij9_1;
          var h = ensureNotNull(this.sj0_1)[a] + ensureNotNull(this.sj0_1)[b];
          var va = ensureNotNull(this.pj0_1.xj9_1)[a];
          var vb = ensureNotNull(this.pj0_1.xj9_1)[b];
          var inter = ejectionStrength * h * w;
          var fx = inter * n.ai4_1;
          var fy = inter * n.bi4_1;
          va.ai4_1 = va.ai4_1 - fx;
          va.bi4_1 = va.bi4_1 - fy;
          vb.ai4_1 = vb.ai4_1 + fx;
          vb.bi4_1 = vb.bi4_1 + fy;
        }
      }
       while (inductionVariable < last);
  }
  ujd(step) {
    var tmp = this.tj0_1;
    tmp.xj9_1 = this.zjd(ParticleSystem$solveColorMixing$lambda, this.tj0_1.xj9_1);
    var colorMixing256 = numberToInt(256 * this.wj1_1);
    var inductionVariable = 0;
    var last = this.zj0_1;
    if (inductionVariable < last)
      do {
        var k = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var contact = this.bj1_1[k];
        var a = contact.ej9_1;
        var b = contact.fj9_1;
        if (!((ensureNotNull(this.nj0_1.zj9_1)[a] & ensureNotNull(this.nj0_1.zj9_1)[b] & ParticleType_instance.fjb_1) === 0)) {
          var colorA = ensureNotNull(ensureNotNull(this.tj0_1.xj9_1)[a]);
          var colorB = ensureNotNull(ensureNotNull(this.tj0_1.xj9_1)[b]);
          var dr = imul(colorMixing256, (colorB.yj8_1 & 255) - (colorA.yj8_1 & 255) | 0) >>> 8 | 0;
          var dg = imul(colorMixing256, (colorB.zj8_1 & 255) - (colorA.zj8_1 & 255) | 0) >>> 8 | 0;
          var db = imul(colorMixing256, (colorB.aj9_1 & 255) - (colorA.aj9_1 & 255) | 0) >>> 8 | 0;
          var da = imul(colorMixing256, (colorB.bj9_1 & 255) - (colorA.bj9_1 & 255) | 0) >>> 8 | 0;
          colorA.yj8_1 = toByte(colorA.yj8_1 + dr | 0);
          colorA.zj8_1 = toByte(colorA.zj8_1 + dg | 0);
          colorA.aj9_1 = toByte(colorA.aj9_1 + db | 0);
          colorA.bj9_1 = toByte(colorA.bj9_1 + da | 0);
          colorB.yj8_1 = toByte(colorB.yj8_1 - dr | 0);
          colorB.zj8_1 = toByte(colorB.zj8_1 - dg | 0);
          colorB.aj9_1 = toByte(colorB.aj9_1 - db | 0);
          colorB.bj9_1 = toByte(colorB.bj9_1 - da | 0);
        }
      }
       while (inductionVariable < last);
  }
  kjd() {
    var newCount = 0;
    var newIndices = new Int32Array(this.kj0_1);
    var inductionVariable = 0;
    var last = this.kj0_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var flags = ensureNotNull(this.nj0_1.zj9_1)[i];
        if (!((flags & ParticleType_instance.yja_1) === 0)) {
          var destructionListener = this.aj0_1.ji2_1;
          if (!((flags & ParticleType_instance.gjb_1) === 0) && !(destructionListener == null)) {
            destructionListener.cje(i);
          }
          newIndices[i] = Settings_getInstance().ni7_1;
        } else {
          newIndices[i] = newCount;
          if (!(i === newCount)) {
            ensureNotNull(ensureNotNull(this.nj0_1).zj9_1)[newCount] = ensureNotNull(this.nj0_1.zj9_1)[i];
            ensureNotNull(this.oj0_1.xj9_1)[newCount].via(ensureNotNull(this.oj0_1.xj9_1)[i]);
            ensureNotNull(this.pj0_1.xj9_1)[newCount].via(ensureNotNull(this.pj0_1.xj9_1)[i]);
            ensureNotNull(this.uj0_1)[newCount] = ensureNotNull(ensureNotNull(this.uj0_1)[i]);
            if (!(this.sj0_1 == null)) {
              ensureNotNull(this.sj0_1)[newCount] = ensureNotNull(this.sj0_1)[i];
            }
            if (!(this.tj0_1.xj9_1 == null)) {
              ensureNotNull(this.tj0_1.xj9_1)[newCount].dj9(ensureNotNull(this.tj0_1.xj9_1)[i]);
            }
            if (!(this.vj0_1.xj9_1 == null)) {
              ensureNotNull(ensureNotNull(this.vj0_1).xj9_1)[newCount] = ensureNotNull(this.vj0_1.xj9_1)[i];
            }
          }
          newCount = newCount + 1 | 0;
        }
      }
       while (inductionVariable < last);
    var inductionVariable_0 = 0;
    var last_0 = this.wj0_1;
    if (inductionVariable_0 < last_0)
      do {
        var k = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var proxy = this.yj0_1[k];
        proxy.rj9_1 = newIndices[proxy.rj9_1];
      }
       while (inductionVariable_0 < last_0);
    var j = this.wj0_1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_0 = 0;
    while (i_0 < j) {
      if (Test_instance.ojb(this.yj0_1[i_0])) {
        j = j - 1 | 0;
        var temp = this.yj0_1[j];
        this.yj0_1[j] = this.yj0_1[i_0];
        this.yj0_1[i_0] = temp;
        i_0 = i_0 - 1 | 0;
      }
      i_0 = i_0 + 1 | 0;
    }
    this.wj0_1 = j;
    var inductionVariable_1 = 0;
    var last_1 = this.zj0_1;
    if (inductionVariable_1 < last_1)
      do {
        var k_0 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var contact = this.bj1_1[k_0];
        contact.ej9_1 = newIndices[contact.ej9_1];
        contact.fj9_1 = newIndices[contact.fj9_1];
      }
       while (inductionVariable_1 < last_1);
    j = this.zj0_1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_1 = 0;
    while (i_1 < j) {
      if (Test_instance.pjb(this.bj1_1[i_1])) {
        j = j - 1 | 0;
        var temp_0 = this.bj1_1[j];
        this.bj1_1[j] = this.bj1_1[i_1];
        this.bj1_1[i_1] = temp_0;
        i_1 = i_1 - 1 | 0;
      }
      i_1 = i_1 + 1 | 0;
    }
    this.zj0_1 = j;
    var inductionVariable_2 = 0;
    var last_2 = this.cj1_1;
    if (inductionVariable_2 < last_2)
      do {
        var k_1 = inductionVariable_2;
        inductionVariable_2 = inductionVariable_2 + 1 | 0;
        var contact_0 = this.ej1_1[k_1];
        contact_0.tj8_1 = newIndices[contact_0.tj8_1];
      }
       while (inductionVariable_2 < last_2);
    j = this.cj1_1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_2 = 0;
    while (i_2 < j) {
      if (Test_instance.qjb(this.ej1_1[i_2])) {
        j = j - 1 | 0;
        var temp_1 = this.ej1_1[j];
        this.ej1_1[j] = this.ej1_1[i_2];
        this.ej1_1[i_2] = temp_1;
        i_2 = i_2 - 1 | 0;
      }
      i_2 = i_2 + 1 | 0;
    }
    this.cj1_1 = j;
    var inductionVariable_3 = 0;
    var last_3 = this.fj1_1;
    if (inductionVariable_3 < last_3)
      do {
        var k_2 = inductionVariable_3;
        inductionVariable_3 = inductionVariable_3 + 1 | 0;
        var pair = this.hj1_1[k_2];
        pair.sjb_1 = newIndices[pair.sjb_1];
        pair.tjb_1 = newIndices[pair.tjb_1];
      }
       while (inductionVariable_3 < last_3);
    j = this.fj1_1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_3 = 0;
    while (i_3 < j) {
      if (Test_instance.rjb(this.hj1_1[i_3])) {
        j = j - 1 | 0;
        var temp_2 = this.hj1_1[j];
        this.hj1_1[j] = this.hj1_1[i_3];
        this.hj1_1[i_3] = temp_2;
        i_3 = i_3 - 1 | 0;
      }
      i_3 = i_3 + 1 | 0;
    }
    this.fj1_1 = j;
    var inductionVariable_4 = 0;
    var last_4 = this.ij1_1;
    if (inductionVariable_4 < last_4)
      do {
        var k_3 = inductionVariable_4;
        inductionVariable_4 = inductionVariable_4 + 1 | 0;
        var triad = this.kj1_1[k_3];
        triad.yjb_1 = newIndices[triad.yjb_1];
        triad.zjb_1 = newIndices[triad.zjb_1];
        triad.ajc_1 = newIndices[triad.ajc_1];
      }
       while (inductionVariable_4 < last_4);
    j = this.ij1_1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var i_4 = 0;
    while (i_4 < j) {
      if (Test_instance.xjb(this.kj1_1[i_4])) {
        j = j - 1 | 0;
        var temp_3 = this.kj1_1[j];
        this.kj1_1[j] = this.kj1_1[i_4];
        this.kj1_1[i_4] = temp_3;
        i_4 = i_4 - 1 | 0;
      }
      i_4 = i_4 + 1 | 0;
    }
    this.ij1_1 = j;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var group = this.mj1_1;
    while (!(group == null)) {
      var firstIndex = newCount;
      var lastIndex = 0;
      var modified = false;
      var inductionVariable_5 = ensureNotNull(group).pjc_1;
      var last_5 = ensureNotNull(group).qjc_1;
      if (inductionVariable_5 < last_5)
        do {
          var i_5 = inductionVariable_5;
          inductionVariable_5 = inductionVariable_5 + 1 | 0;
          j = newIndices[i_5];
          if (j >= 0) {
            firstIndex = Companion_getInstance_11().gio(firstIndex, j);
            lastIndex = Companion_getInstance_11().eih(lastIndex, j + 1 | 0);
          } else {
            modified = true;
          }
        }
         while (inductionVariable_5 < last_5);
      if (firstIndex < lastIndex) {
        ensureNotNull(group).pjc_1 = firstIndex;
        ensureNotNull(group).qjc_1 = lastIndex;
        if (modified) {
          if (!((ensureNotNull(group).rjc_1 & ParticleGroupType_instance.qj9_1) === 0)) {
            ensureNotNull(group).ejd_1 = true;
          }
        }
      } else {
        ensureNotNull(group).pjc_1 = 0;
        ensureNotNull(group).qjc_1 = 0;
        if (ensureNotNull(group).cjd_1) {
          ensureNotNull(group).djd_1 = true;
        }
      }
      group = ensureNotNull(group).kiq();
    }
    this.kj0_1 = newCount;
    var group_0 = this.mj1_1;
    while (!(group_0 == null)) {
      var next = ensureNotNull(group_0).kiq();
      if (ensureNotNull(group_0).djd_1) {
        this.mjc(group_0);
      } else
        ensureNotNull(group_0).ejd_1;
      group_0 = next;
    }
  }
  aje(step) {
    return this.hj0_1 * step.piv_1;
  }
  ljd(step) {
    var velocity = this.aje(step);
    return velocity * velocity;
  }
  xjd(step) {
    return this.ej0_1 * this.ljd(step);
  }
  zjd(newInstance, buffer) {
    var buffer_0 = buffer;
    if (buffer_0 == null) {
      var tmp = 0;
      var tmp_0 = this.lj0_1;
      // Inline function 'kotlin.arrayOfNulls' call
      var tmp_1 = fillArrayVal(Array(tmp_0), null);
      while (tmp < tmp_0) {
        tmp_1[tmp] = newInstance();
        tmp = tmp + 1 | 0;
      }
      var tmp_2 = tmp_1;
      buffer_0 = isArray(tmp_2) ? tmp_2 : THROW_CCE();
    }
    return buffer_0;
  }
  bje(buffer) {
    var buffer_0 = buffer;
    if (buffer_0 == null) {
      buffer_0 = new Float32Array(this.lj0_1);
    }
    return buffer_0;
  }
}
class ParticleType {
  constructor() {
    this.xja_1 = 0;
    this.yja_1 = 2;
    this.zja_1 = 4;
    this.ajb_1 = 8;
    this.bjb_1 = 16;
    this.cjb_1 = 32;
    this.djb_1 = 64;
    this.ejb_1 = 128;
    this.fjb_1 = 256;
    this.gjb_1 = 512;
  }
}
class Vec2ArrayPool {
  constructor() {
    this.dje_1 = HashMap.c9();
  }
}
class DefaultWorldPool {
  constructor(argSize, argContainerSize) {
    this.eje_1 = this;
    var tmp = this;
    tmp.fje_1 = new LambdaOrderedStack(argSize, argContainerSize, DefaultWorldPool$vecs$lambda);
    var tmp_0 = this;
    tmp_0.gje_1 = new LambdaOrderedStack(argSize, argContainerSize, DefaultWorldPool$vec3s$lambda);
    var tmp_1 = this;
    tmp_1.hje_1 = new LambdaOrderedStack(argSize, argContainerSize, DefaultWorldPool$mats$lambda);
    var tmp_2 = this;
    tmp_2.ije_1 = new LambdaOrderedStack(argSize, argContainerSize, DefaultWorldPool$mat33s$lambda);
    var tmp_3 = this;
    tmp_3.jje_1 = new LambdaOrderedStack(argSize, argContainerSize, DefaultWorldPool$aabbs$lambda);
    var tmp_4 = this;
    tmp_4.kje_1 = new LambdaOrderedStack(argSize, argContainerSize, DefaultWorldPool$rots$lambda);
    this.lje_1 = HashMap.c9();
    this.mje_1 = HashMap.c9();
    this.nje_1 = HashMap.c9();
    var tmp_5 = this;
    var tmp_6 = DefaultWorldPool$pcstack$lambda(this);
    tmp_5.oje_1 = new ContactMutableStack(tmp_6, DefaultWorldPool$pcstack$lambda_0);
    var tmp_7 = this;
    var tmp_8 = DefaultWorldPool$ccstack$lambda(this);
    tmp_7.pje_1 = new ContactMutableStack(tmp_8, DefaultWorldPool$ccstack$lambda_0);
    var tmp_9 = this;
    var tmp_10 = DefaultWorldPool$cpstack$lambda(this);
    tmp_9.qje_1 = new ContactMutableStack(tmp_10, DefaultWorldPool$cpstack$lambda_0);
    var tmp_11 = this;
    var tmp_12 = DefaultWorldPool$ecstack$lambda(this);
    tmp_11.rje_1 = new ContactMutableStack(tmp_12, DefaultWorldPool$ecstack$lambda_0);
    var tmp_13 = this;
    var tmp_14 = DefaultWorldPool$epstack$lambda(this);
    tmp_13.sje_1 = new ContactMutableStack(tmp_14, DefaultWorldPool$epstack$lambda_0);
    var tmp_15 = this;
    var tmp_16 = DefaultWorldPool$chcstack$lambda(this);
    tmp_15.tje_1 = new ContactMutableStack(tmp_16, DefaultWorldPool$chcstack$lambda_0);
    var tmp_17 = this;
    var tmp_18 = DefaultWorldPool$chpstack$lambda(this);
    tmp_17.uje_1 = new ContactMutableStack(tmp_18, DefaultWorldPool$chpstack$lambda_0);
    this.vje_1 = new Collision(this);
    this.wje_1 = new TimeOfImpact(this);
    this.xje_1 = new Distance();
  }
  qj3() {
    return this.vje_1;
  }
  oiz() {
    return this.wje_1;
  }
  uid() {
    return this.xje_1;
  }
  iiz() {
    return this.oje_1;
  }
  giz() {
    return this.pje_1;
  }
  hiz() {
    return this.qje_1;
  }
  jiz() {
    return this.rje_1;
  }
  kiz() {
    return this.sje_1;
  }
  liz() {
    return this.tje_1;
  }
  miz() {
    return this.uje_1;
  }
  sir() {
    return this.fje_1.riz();
  }
  uir(argNum) {
    this.fje_1.cjf(argNum);
  }
}
class OrderedStack {
  constructor(size, argContainerSize) {
    this.yje_1 = size;
    var tmp = this;
    tmp.zje_1 = lazy(OrderedStack$pool$delegate$lambda(this));
    this.ajf_1 = 0;
    var tmp_0 = this;
    var tmp_1 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(argContainerSize), null);
    while (tmp_1 < argContainerSize) {
      tmp_2[tmp_1] = Unit_instance;
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp_0.bjf_1 = tmp_2;
  }
  riz() {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.ajf_1 < this.yje_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var tmp_0 = _get_pool__dc4pf3(this);
    var _unary__edvuaz = this.ajf_1;
    this.ajf_1 = _unary__edvuaz + 1 | 0;
    var tmp_1 = tmp_0[_unary__edvuaz];
    return !(tmp_1 == null) ? tmp_1 : THROW_CCE();
  }
  cjf(argNum) {
    this.ajf_1 = this.ajf_1 - argNum | 0;
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.ajf_1 >= 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
  }
}
class LambdaOrderedStack extends OrderedStack {
  constructor(argSize, argContainerSize, newInstanceLambda) {
    super(argSize, argContainerSize);
    this.hjf_1 = newInstanceLambda;
  }
  ijf() {
    return this.hjf_1();
  }
}
class MutableStack {
  constructor(argInitSize) {
    this.qjf_1 = argInitSize;
    this.rjf_1 = null;
    this.sjf_1 = 0;
    this.tjf_1 = 0;
  }
  riz() {
    ensureInit(this);
    if (this.sjf_1 >= this.tjf_1) {
      extendStack(this, imul(this.tjf_1, 2));
    }
    var tmp = ensureNotNull(this.rjf_1);
    var _unary__edvuaz = this.sjf_1;
    this.sjf_1 = _unary__edvuaz + 1 | 0;
    return tmp[_unary__edvuaz];
  }
  tiz(argObject) {
    ensureInit(this);
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!(this.sjf_1 > 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var tmp_0 = ensureNotNull(this.rjf_1);
    this.sjf_1 = this.sjf_1 - 1 | 0;
    tmp_0[this.sjf_1] = argObject;
  }
}
class ContactMutableStack extends MutableStack {
  constructor(newInstanceLambda, newArrayLambda) {
    super(Settings_getInstance().pio());
    this.njf_1 = newInstanceLambda;
    this.ojf_1 = newArrayLambda;
  }
  ijf() {
    return this.njf_1();
  }
  pjf(size) {
    var tmp = this.ojf_1(size);
    return isArray(tmp) ? tmp : THROW_CCE();
  }
}
class Mixin {
  constructor() {
    this.jiq_1 = null;
  }
  xi5(key) {
    var tmp31_safe_receiver = this.jiq_1;
    var tmp = tmp31_safe_receiver == null ? null : tmp31_safe_receiver.k3(key);
    return (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
  }
  wi5(key, value) {
    if (!(value == null)) {
      if (this.jiq_1 == null)
        this.jiq_1 = LinkedHashMap.vc();
      var tmp32_safe_receiver = this.jiq_1;
      if (tmp32_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.collections.set' call
        tmp32_safe_receiver.r3(key, value);
      }
    } else {
      var tmp33_safe_receiver = this.jiq_1;
      if (tmp33_safe_receiver == null)
        null;
      else
        tmp33_safe_receiver.s3(key);
    }
  }
}
//endregion
function get_DEFAULT_SCALE() {
  _init_properties_WorldView_kt__iv8vl1();
  return DEFAULT_SCALE;
}
var DEFAULT_SCALE;
function get_DEFAULT_GRAVITY_Y() {
  _init_properties_WorldView_kt__iv8vl1();
  return DEFAULT_GRAVITY_Y;
}
var DEFAULT_GRAVITY_Y;
var registeredBox2dSupport$delegate;
function set_box2dWorldComponent(_this__u8e3s4, _set____db54di) {
  _init_properties_WorldView_kt__iv8vl1();
  var this_0 = box2dWorldComponent$delegate;
  var property = box2dWorldComponent$factory();
  var value = this_0.t13_1(_this__u8e3s4, _set____db54di);
  var tmp0_elvis_lhs = this_0.r13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, value);
  return Unit_instance;
}
function get_box2dWorldComponent(_this__u8e3s4) {
  _init_properties_WorldView_kt__iv8vl1();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = box2dWorldComponent$delegate;
    var property = box2dWorldComponent$factory_0();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var box2dWorldComponent$delegate;
function set_body(_this__u8e3s4, _set____db54di) {
  _init_properties_WorldView_kt__iv8vl1();
  var this_0 = body$delegate;
  var property = body$factory();
  var value = this_0.t13_1(_this__u8e3s4, _set____db54di);
  var tmp0_elvis_lhs = this_0.r13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, value);
  return Unit_instance;
}
function get_body(_this__u8e3s4) {
  _init_properties_WorldView_kt__iv8vl1();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = body$delegate;
    var property = body$factory_0();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var body$delegate;
function get_ViewKey() {
  _init_properties_WorldView_kt__iv8vl1();
  return ViewKey;
}
var ViewKey;
var Key_instance;
function Key_getInstance() {
  if (Key_instance === VOID)
    new Key_0();
  return Key_instance;
}
function update($this, step) {
  $this.mi1_1.yi3(get_seconds(step), $this.ni1_1, $this.oi1_1);
  // Inline function 'org.jbox2d.dynamics.forEachBody' call
  var node = $this.mi1_1.zi3().ci2_1;
  while (!(node == null)) {
    // Inline function 'korlibs.korge.box2d.Box2dWorldComponent.update.<anonymous>' call
    var node_0 = node;
    var view = get_view(node_0);
    if (!(view == null)) {
      var worldScale = $this.mi1_1.wi1_1;
      var worldScaleInv = 1.0 / worldScale;
      var viewPos = $this.ti1_1.r4c(view.n4f(), view.p4f());
      if (!(viewPos.p4c_1 === node_0.hi4_1.qi5_1) || !(viewPos.q4c_1 === node_0.hi4_1.ri5_1) || !equals(view.bcc(), node_0.hi4_1.si5_1)) {
        // Inline function 'org.jbox2d.common.Vec2.set' call
        var this_0 = $this.si1_1;
        var x = viewPos.p4c_1 * worldScaleInv;
        var y = viewPos.q4c_1 * worldScaleInv;
        var tmp$ret$0 = this_0.ci4(numberToDouble(x), numberToDouble(y));
        node_0.ki5(tmp$ret$0, view.bcc());
        if (!node_0.hi4_1.ui5_1) {
          node_0.li5($this.si1_1.ci4(0.0, 0.0));
          node_0.mi5(0.0);
          node_0.ni5(true);
          node_0.oi5(true);
        }
      }
      var newX = node_0.q1q().ai4_1 * worldScale;
      var newY = node_0.q1q().bi4_1 * worldScale;
      position(view, newX, newY);
      view.acc(node_0.o2s());
      var viewRoot = view.f22();
      var viewRootStage = viewRoot instanceof Stage;
      node_0.hi4_1.qi5_1 = view.n4f();
      node_0.hi4_1.ri5_1 = view.p4f();
      node_0.hi4_1.si5_1 = view.bcc();
      node_0.hi4_1.ui5_1 = false;
      if ($this.pi1_1 && node_0.hi4_1.ti5_1 && !viewRootStage) {
        $this.mi1_1.vi5(node_0);
        var tmp0_safe_receiver = get_view(node_0);
        if (tmp0_safe_receiver == null)
          null;
        else {
          set_body(tmp0_safe_receiver, null);
        }
        set_view(node_0, null);
      }
      node_0.hi4_1.ti5_1 = viewRootStage;
    }
    node = node.ti4_1;
  }
}
function Box2dWorldComponent$updater$lambda(this$0) {
  return function ($this$addFixedUpdater) {
    update(this$0, this$0.qi1_1);
    return Unit_instance;
  };
}
function set_component(_this__u8e3s4, value) {
  _init_properties_WorldView_kt__iv8vl1();
  _this__u8e3s4.wi5(Key_getInstance(), value);
}
function set_view(_this__u8e3s4, value) {
  _init_properties_WorldView_kt__iv8vl1();
  _this__u8e3s4.wi5(get_ViewKey(), value);
}
function get_view(_this__u8e3s4) {
  _init_properties_WorldView_kt__iv8vl1();
  return _this__u8e3s4.xi5(get_ViewKey());
}
function BoxShape(rect) {
  _init_properties_WorldView_kt__iv8vl1();
  // Inline function 'kotlin.apply' call
  var this_0 = PolygonShape.ji6();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.box2d.BoxShape.<anonymous>' call
  this_0.di6_1 = 4;
  // Inline function 'org.jbox2d.common.Vec2.set' call
  var this_1 = this_0.bi6_1[0];
  var x = rect.p2i();
  var y = rect.r2i();
  this_1.ci4(numberToDouble(x), numberToDouble(y));
  // Inline function 'org.jbox2d.common.Vec2.set' call
  var this_2 = this_0.bi6_1[1];
  var x_0 = rect.q2i();
  var y_0 = rect.r2i();
  this_2.ci4(numberToDouble(x_0), numberToDouble(y_0));
  // Inline function 'org.jbox2d.common.Vec2.set' call
  var this_3 = this_0.bi6_1[2];
  var x_1 = rect.q2i();
  var y_1 = rect.s2i();
  this_3.ci4(numberToDouble(x_1), numberToDouble(y_1));
  // Inline function 'org.jbox2d.common.Vec2.set' call
  var this_4 = this_0.bi6_1[3];
  var x_2 = rect.p2i();
  var y_2 = rect.s2i();
  this_4.ci4(numberToDouble(x_2), numberToDouble(y_2));
  this_0.ci6_1[0].ci4(0.0, -1.0);
  this_0.ci6_1[1].ci4(1.0, 0.0);
  this_0.ci6_1[2].ci4(0.0, 1.0);
  this_0.ci6_1[3].ci4(-1.0, 0.0);
  this_0.ai6_1.ki6();
  return this_0;
}
function get_nearestBox2dWorld(_this__u8e3s4) {
  _init_properties_WorldView_kt__iv8vl1();
  return get_nearestBox2dWorldComponent(_this__u8e3s4).mi1_1;
}
function get_nearestBox2dWorldComponent(_this__u8e3s4) {
  _init_properties_WorldView_kt__iv8vl1();
  var nearestReference = null;
  var view = _this__u8e3s4;
  while (!(view == null)) {
    var component = get_box2dWorldComponent(view);
    if (!(component == null)) {
      return component;
    }
    if (!(view == null) ? isInterface(view, Reference) : false) {
      if (nearestReference == null) {
        nearestReference = view;
      }
    }
    if (view.w15() == null) {
      var tmp0_elvis_lhs = nearestReference;
      return getOrCreateBox2dWorld(tmp0_elvis_lhs == null ? view : tmp0_elvis_lhs);
    }
    view = view.w15();
  }
  get_invalidOp();
}
function getOrCreateBox2dWorld(_this__u8e3s4) {
  _init_properties_WorldView_kt__iv8vl1();
  if (get_box2dWorldComponent(_this__u8e3s4) == null) {
    // Inline function 'kotlin.also' call
    var this_0 = World.li6(0.0, get_DEFAULT_GRAVITY_Y());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.getOrCreateBox2dWorld.<anonymous>' call
    this_0.wi1_1 = get_DEFAULT_SCALE();
    set_box2dWorldComponent(_this__u8e3s4, new Box2dWorldComponent(_this__u8e3s4, this_0, 6, 2));
  }
  return ensureNotNull(get_box2dWorldComponent(_this__u8e3s4));
}
function registeredBox2dSupport$delegate$lambda() {
  _init_properties_WorldView_kt__iv8vl1();
  return false;
}
function box2dWorldComponent$delegate$lambda($this$PropertyThis) {
  _init_properties_WorldView_kt__iv8vl1();
  return null;
}
function body$delegate$lambda($this$PropertyThis) {
  _init_properties_WorldView_kt__iv8vl1();
  return null;
}
function box2dWorldComponent$factory() {
  return getPropertyCallableRef('box2dWorldComponent', 1, KMutableProperty1, function (receiver) {
    return get_box2dWorldComponent(receiver);
  }, function (receiver, value) {
    return set_box2dWorldComponent(receiver, value);
  });
}
function box2dWorldComponent$factory_0() {
  return getPropertyCallableRef('box2dWorldComponent', 1, KMutableProperty1, function (receiver) {
    return get_box2dWorldComponent(receiver);
  }, function (receiver, value) {
    return set_box2dWorldComponent(receiver, value);
  });
}
function body$factory() {
  return getPropertyCallableRef('body', 1, KMutableProperty1, function (receiver) {
    return get_body(receiver);
  }, function (receiver, value) {
    return set_body(receiver, value);
  });
}
function body$factory_0() {
  return getPropertyCallableRef('body', 1, KMutableProperty1, function (receiver) {
    return get_body(receiver);
  }, function (receiver, value) {
    return set_body(receiver, value);
  });
}
var properties_initialized_WorldView_kt_10jd7d;
function _init_properties_WorldView_kt__iv8vl1() {
  if (!properties_initialized_WorldView_kt_10jd7d) {
    properties_initialized_WorldView_kt_10jd7d = true;
    DEFAULT_SCALE = 20.0;
    DEFAULT_GRAVITY_Y = 9.8;
    registeredBox2dSupport$delegate = new Property(VOID, registeredBox2dSupport$delegate$lambda);
    box2dWorldComponent$delegate = new PropertyThis(VOID, box2dWorldComponent$delegate$lambda);
    body$delegate = new PropertyThis('box2dBody', body$delegate$lambda);
    ViewKey = new Key();
  }
}
var Companion_instance;
function Companion_getInstance_1() {
  return Companion_instance;
}
function init_org_jbox2d_collision_AABB(_this__u8e3s4) {
}
var Type_UNKNOWN_instance;
var Type_EDGE_A_instance;
var Type_EDGE_B_instance;
var Type_entriesInitialized;
function Type_initEntries() {
  if (Type_entriesInitialized)
    return Unit_instance;
  Type_entriesInitialized = true;
  Type_UNKNOWN_instance = new Type('UNKNOWN', 0);
  Type_EDGE_A_instance = new Type('EDGE_A', 1);
  Type_EDGE_B_instance = new Type('EDGE_B', 2);
}
function Type_UNKNOWN_getInstance() {
  Type_initEntries();
  return Type_UNKNOWN_instance;
}
function Type_EDGE_A_getInstance() {
  Type_initEntries();
  return Type_EDGE_A_instance;
}
function Type_EDGE_B_getInstance() {
  Type_initEntries();
  return Type_EDGE_B_instance;
}
var Companion_instance_0;
function Companion_getInstance_2() {
  return Companion_instance_0;
}
var Type_VERTEX_instance;
var Type_FACE_instance;
var Type_entriesInitialized_0;
function Type_initEntries_0() {
  if (Type_entriesInitialized_0)
    return Unit_instance;
  Type_entriesInitialized_0 = true;
  Type_VERTEX_instance = new Type_0('VERTEX', 0);
  Type_FACE_instance = new Type_0('FACE', 1);
}
function init_org_jbox2d_collision_ContactID(_this__u8e3s4) {
  _this__u8e3s4.gi8_1 = 0;
  _this__u8e3s4.hi8_1 = 0;
  _this__u8e3s4.ii8_1 = 0;
  _this__u8e3s4.ji8_1 = 0;
}
function Type_VERTEX_getInstance() {
  Type_initEntries_0();
  return Type_VERTEX_instance;
}
function Type_FACE_getInstance() {
  Type_initEntries_0();
  return Type_FACE_instance;
}
var Companion_instance_1;
function Companion_getInstance_3() {
  return Companion_instance_1;
}
var ManifoldType_CIRCLES_instance;
var ManifoldType_FACE_A_instance;
var ManifoldType_FACE_B_instance;
var ManifoldType_entriesInitialized;
function ManifoldType_initEntries() {
  if (ManifoldType_entriesInitialized)
    return Unit_instance;
  ManifoldType_entriesInitialized = true;
  ManifoldType_CIRCLES_instance = new ManifoldType('CIRCLES', 0);
  ManifoldType_FACE_A_instance = new ManifoldType('FACE_A', 1);
  ManifoldType_FACE_B_instance = new ManifoldType('FACE_B', 2);
}
function init_org_jbox2d_collision_Manifold(_this__u8e3s4) {
  _this__u8e3s4.fib_1 = ManifoldType_CIRCLES_getInstance();
  _this__u8e3s4.gib_1 = 0;
}
function ManifoldType_CIRCLES_getInstance() {
  ManifoldType_initEntries();
  return ManifoldType_CIRCLES_instance;
}
function ManifoldType_FACE_A_getInstance() {
  ManifoldType_initEntries();
  return ManifoldType_FACE_A_instance;
}
function ManifoldType_FACE_B_getInstance() {
  ManifoldType_initEntries();
  return ManifoldType_FACE_B_instance;
}
function init_org_jbox2d_collision_ManifoldPoint(_this__u8e3s4) {
  _this__u8e3s4.nib_1 = 0;
  _this__u8e3s4.oib_1 = 0;
}
var TOIOutputState_UNKNOWN_instance;
var TOIOutputState_FAILED_instance;
var TOIOutputState_OVERLAPPED_instance;
var TOIOutputState_TOUCHING_instance;
var TOIOutputState_SEPARATED_instance;
var TOIOutputState_entriesInitialized;
function TOIOutputState_initEntries() {
  if (TOIOutputState_entriesInitialized)
    return Unit_instance;
  TOIOutputState_entriesInitialized = true;
  TOIOutputState_UNKNOWN_instance = new TOIOutputState('UNKNOWN', 0);
  TOIOutputState_FAILED_instance = new TOIOutputState('FAILED', 1);
  TOIOutputState_OVERLAPPED_instance = new TOIOutputState('OVERLAPPED', 2);
  TOIOutputState_TOUCHING_instance = new TOIOutputState('TOUCHING', 3);
  TOIOutputState_SEPARATED_instance = new TOIOutputState('SEPARATED', 4);
}
var Companion_instance_2;
function Companion_getInstance_4() {
  return Companion_instance_2;
}
function TOIOutputState_UNKNOWN_getInstance() {
  TOIOutputState_initEntries();
  return TOIOutputState_UNKNOWN_instance;
}
function TOIOutputState_FAILED_getInstance() {
  TOIOutputState_initEntries();
  return TOIOutputState_FAILED_instance;
}
function TOIOutputState_OVERLAPPED_getInstance() {
  TOIOutputState_initEntries();
  return TOIOutputState_OVERLAPPED_instance;
}
function TOIOutputState_TOUCHING_getInstance() {
  TOIOutputState_initEntries();
  return TOIOutputState_TOUCHING_instance;
}
function TOIOutputState_SEPARATED_getInstance() {
  TOIOutputState_initEntries();
  return TOIOutputState_SEPARATED_instance;
}
var Type_POINTS_instance;
var Type_FACE_A_instance;
var Type_FACE_B_instance;
var Type_entriesInitialized_1;
function Type_initEntries_1() {
  if (Type_entriesInitialized_1)
    return Unit_instance;
  Type_entriesInitialized_1 = true;
  Type_POINTS_instance = new Type_1('POINTS', 0);
  Type_FACE_A_instance = new Type_1('FACE_A', 1);
  Type_FACE_B_instance = new Type_1('FACE_B', 2);
}
function Type_POINTS_getInstance() {
  Type_initEntries_1();
  return Type_POINTS_instance;
}
function Type_FACE_A_getInstance() {
  Type_initEntries_1();
  return Type_FACE_A_instance;
}
function Type_FACE_B_getInstance() {
  Type_initEntries_1();
  return Type_FACE_B_instance;
}
var Companion_instance_3;
function Companion_getInstance_5() {
  return Companion_instance_3;
}
function allocateNode($this) {
  if ($this.kil_1 === Companion_instance_4.dim_1) {
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!($this.iil_1 === $this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    var old = $this.hil_1;
    $this.jil_1 = imul($this.jil_1, 2);
    var tmp_0 = $this;
    // Inline function 'kotlin.arrayOfNulls' call
    var size = $this.jil_1;
    var tmp_1 = fillArrayVal(Array(size), null);
    tmp_0.hil_1 = isArray(tmp_1) ? tmp_1 : THROW_CCE();
    // Inline function 'org.jbox2d.internal.arraycopy' call
    var src = ensureNotNull(old);
    var dst = ensureNotNull($this.hil_1);
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = 0 + old.length | 0;
    arrayCopy(src, dst, 0, 0, endIndex);
    var inductionVariable = $this.jil_1 - 1 | 0;
    var last = $this.iil_1;
    if (last <= inductionVariable)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        ensureNotNull($this.hil_1)[i] = new DynamicTreeNode(i);
        ensureNotNull($this.hil_1)[i].yil_1 = i === ($this.jil_1 - 1 | 0) ? null : ensureNotNull($this.hil_1)[i + 1 | 0];
        ensureNotNull($this.hil_1)[i].bim_1 = -1;
      }
       while (!(i === last));
    $this.kil_1 = $this.iil_1;
  }
  var nodeId = $this.kil_1;
  var treeNode = ensureNotNull($this.hil_1)[nodeId];
  $this.kil_1 = !(treeNode.yil_1 == null) ? ensureNotNull(treeNode.yil_1).vil_1 : Companion_instance_4.dim_1;
  treeNode.yil_1 = null;
  treeNode.zil_1 = null;
  treeNode.aim_1 = null;
  treeNode.bim_1 = 0;
  treeNode.xil_1 = null;
  $this.iil_1 = $this.iil_1 + 1 | 0;
  return treeNode;
}
function freeNode($this, node) {
  // Inline function 'org.jbox2d.internal.assert' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp;
  if (!!(node == null)) {
    // Inline function 'kotlin.check.<anonymous>' call
    var message = 'Check failed.';
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'org.jbox2d.internal.assert' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp_0;
  if (!(0 < $this.iil_1)) {
    // Inline function 'kotlin.check.<anonymous>' call
    var message_0 = 'Check failed.';
    throw IllegalStateException.m5(toString(message_0));
  }
  node.yil_1 = !($this.kil_1 === Companion_instance_4.dim_1) ? ensureNotNull($this.hil_1)[$this.kil_1] : null;
  node.bim_1 = -1;
  $this.kil_1 = node.vil_1;
  $this.iil_1 = $this.iil_1 - 1 | 0;
}
function insertLeaf($this, leaf_index) {
  var leaf = ensureNotNull($this.hil_1)[leaf_index];
  if ($this.gil_1 == null) {
    $this.gil_1 = leaf;
    ensureNotNull($this.gil_1).yil_1 = null;
    return Unit_instance;
  }
  var leafAABB = leaf.wil_1;
  var index = $this.gil_1;
  $l$loop: while (!(ensureNotNull(index).zil_1 == null)) {
    var node = index;
    var child1 = node.zil_1;
    var child2 = node.aim_1;
    var area = node.wil_1.zi7();
    $this.ril_1.bi8(node.wil_1, leafAABB);
    var combinedArea = $this.ril_1.zi7();
    var cost = 2.0 * combinedArea;
    var inheritanceCost = 2.0 * (combinedArea - area);
    var cost1;
    if (ensureNotNull(child1).zil_1 == null) {
      $this.ril_1.bi8(leafAABB, child1.wil_1);
      cost1 = $this.ril_1.zi7() + inheritanceCost;
    } else {
      $this.ril_1.bi8(leafAABB, child1.wil_1);
      var oldArea = child1.wil_1.zi7();
      var newArea = $this.ril_1.zi7();
      cost1 = newArea - oldArea + inheritanceCost;
    }
    var cost2;
    if (ensureNotNull(child2).zil_1 == null) {
      $this.ril_1.bi8(leafAABB, child2.wil_1);
      cost2 = $this.ril_1.zi7() + inheritanceCost;
    } else {
      $this.ril_1.bi8(leafAABB, child2.wil_1);
      var oldArea_0 = child2.wil_1.zi7();
      var newArea_0 = $this.ril_1.zi7();
      cost2 = newArea_0 - oldArea_0 + inheritanceCost;
    }
    if (cost < cost1 && cost < cost2) {
      break $l$loop;
    }
    if (cost1 < cost2) {
      index = child1;
    } else {
      index = child2;
    }
  }
  var sibling = index;
  var oldParent = ensureNotNull($this.hil_1)[sibling.vil_1].yil_1;
  var newParent = allocateNode($this);
  newParent.yil_1 = oldParent;
  newParent.xil_1 = null;
  newParent.wil_1.bi8(leafAABB, sibling.wil_1);
  newParent.bim_1 = sibling.bim_1 + 1 | 0;
  if (!(oldParent == null)) {
    if (oldParent.zil_1 === sibling) {
      oldParent.zil_1 = newParent;
    } else {
      oldParent.aim_1 = newParent;
    }
    newParent.zil_1 = sibling;
    newParent.aim_1 = leaf;
    sibling.yil_1 = newParent;
    leaf.yil_1 = newParent;
  } else {
    newParent.zil_1 = sibling;
    newParent.aim_1 = leaf;
    sibling.yil_1 = newParent;
    leaf.yil_1 = newParent;
    $this.gil_1 = newParent;
  }
  index = leaf.yil_1;
  while (!(index == null)) {
    index = balance($this, index);
    var child1_0 = index.zil_1;
    var child2_0 = index.aim_1;
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!!(child1_0 == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    if (!!(child2_0 == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_0));
    }
    index.bim_1 = 1 + Companion_getInstance_11().eih(ensureNotNull(child1_0).bim_1, ensureNotNull(child2_0).bim_1) | 0;
    index.wil_1.bi8(child1_0.wil_1, child2_0.wil_1);
    index = index.yil_1;
  }
}
function removeLeaf($this, leaf) {
  if (leaf === $this.gil_1) {
    $this.gil_1 = null;
    return Unit_instance;
  }
  var parent = leaf.yil_1;
  var grandParent = parent == null ? null : parent.yil_1;
  var sibling;
  if (ensureNotNull(parent).zil_1 === leaf) {
    sibling = ensureNotNull(ensureNotNull(parent).aim_1);
  } else {
    sibling = ensureNotNull(ensureNotNull(parent).zil_1);
  }
  if (!(grandParent == null)) {
    if (grandParent.zil_1 === parent) {
      grandParent.zil_1 = sibling;
    } else {
      grandParent.aim_1 = sibling;
    }
    sibling.yil_1 = grandParent;
    freeNode($this, parent);
    var index = grandParent;
    while (!(index == null)) {
      index = balance($this, index);
      var child1 = index.zil_1;
      var child2 = index.aim_1;
      index.wil_1.bi8(ensureNotNull(child1).wil_1, ensureNotNull(child2).wil_1);
      index.bim_1 = 1 + Companion_getInstance_11().eih(child1.bim_1, child2.bim_1) | 0;
      index = index.yil_1;
    }
  } else {
    $this.gil_1 = sibling;
    sibling.yil_1 = null;
    freeNode($this, parent);
  }
}
function balance($this, iA) {
  // Inline function 'org.jbox2d.internal.assert' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp;
  if (!!(iA == null)) {
    // Inline function 'kotlin.check.<anonymous>' call
    var message = 'Check failed.';
    throw IllegalStateException.m5(toString(message));
  }
  var A = iA;
  if (ensureNotNull(A).zil_1 == null || A.bim_1 < 2) {
    return iA;
  }
  var iB = A.zil_1;
  var iC = A.aim_1;
  // Inline function 'org.jbox2d.internal.assert' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp_0;
  if (!(0 <= ensureNotNull(iB).vil_1 && iB.vil_1 < $this.jil_1)) {
    // Inline function 'kotlin.check.<anonymous>' call
    var message_0 = 'Check failed.';
    throw IllegalStateException.m5(toString(message_0));
  }
  // Inline function 'org.jbox2d.internal.assert' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp_1;
  if (!(0 <= ensureNotNull(iC).vil_1 && iC.vil_1 < $this.jil_1)) {
    // Inline function 'kotlin.check.<anonymous>' call
    var message_1 = 'Check failed.';
    throw IllegalStateException.m5(toString(message_1));
  }
  var B = iB;
  var C = iC;
  var balance = C.bim_1 - B.bim_1 | 0;
  if (balance > 1) {
    var iF = C.zil_1;
    var iG = C.aim_1;
    var F = iF;
    var G = iG;
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_2;
    if (!!(F == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_2 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_2));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_3;
    if (!!(G == null)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_3 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_3));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_4;
    if (!(0 <= ensureNotNull(iF).vil_1 && iF.vil_1 < $this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_4 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_4));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_5;
    if (!(0 <= ensureNotNull(iG).vil_1 && iG.vil_1 < $this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_5 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_5));
    }
    C.zil_1 = iA;
    C.yil_1 = A.yil_1;
    A.yil_1 = iC;
    if (!(C.yil_1 == null)) {
      if (ensureNotNull(C.yil_1).zil_1 === iA) {
        ensureNotNull(C.yil_1).zil_1 = iC;
      } else {
        // Inline function 'org.jbox2d.internal.assert' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_6;
        if (!(ensureNotNull(C.yil_1).aim_1 === iA)) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_6 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_6));
        }
        ensureNotNull(C.yil_1).aim_1 = iC;
      }
    } else {
      $this.gil_1 = iC;
    }
    if (ensureNotNull(F).bim_1 > ensureNotNull(G).bim_1) {
      C.aim_1 = iF;
      A.aim_1 = iG;
      G.yil_1 = iA;
      A.wil_1.bi8(B.wil_1, G.wil_1);
      C.wil_1.bi8(A.wil_1, F.wil_1);
      A.bim_1 = 1 + Companion_getInstance_11().eih(B.bim_1, G.bim_1) | 0;
      C.bim_1 = 1 + Companion_getInstance_11().eih(A.bim_1, F.bim_1) | 0;
    } else {
      C.aim_1 = iG;
      A.aim_1 = iF;
      F.yil_1 = iA;
      A.wil_1.bi8(B.wil_1, F.wil_1);
      C.wil_1.bi8(A.wil_1, G.wil_1);
      A.bim_1 = 1 + Companion_getInstance_11().eih(B.bim_1, F.bim_1) | 0;
      C.bim_1 = 1 + Companion_getInstance_11().eih(A.bim_1, G.bim_1) | 0;
    }
    return iC;
  }
  if (balance < -1) {
    var iD = B.zil_1;
    var iE = B.aim_1;
    var D = iD;
    var E = iE;
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_7;
    if (!(0 <= ensureNotNull(iD).vil_1 && iD.vil_1 < $this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_7 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_7));
    }
    // Inline function 'org.jbox2d.internal.assert' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_8;
    if (!(0 <= ensureNotNull(iE).vil_1 && iE.vil_1 < $this.jil_1)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_8 = 'Check failed.';
      throw IllegalStateException.m5(toString(message_8));
    }
    B.zil_1 = iA;
    B.yil_1 = A.yil_1;
    A.yil_1 = iB;
    if (!(B.yil_1 == null)) {
      if (ensureNotNull(B.yil_1).zil_1 === iA) {
        ensureNotNull(B.yil_1).zil_1 = iB;
      } else {
        // Inline function 'org.jbox2d.internal.assert' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_9;
        if (!(ensureNotNull(B.yil_1).aim_1 === iA)) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_9 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_9));
        }
        ensureNotNull(B.yil_1).aim_1 = iB;
      }
    } else {
      $this.gil_1 = iB;
    }
    if (ensureNotNull(D).bim_1 > ensureNotNull(E).bim_1) {
      B.aim_1 = iD;
      A.zil_1 = iE;
      E.yil_1 = iA;
      A.wil_1.bi8(C.wil_1, E.wil_1);
      B.wil_1.bi8(A.wil_1, D.wil_1);
      A.bim_1 = 1 + Companion_getInstance_11().eih(C.bim_1, E.bim_1) | 0;
      B.bim_1 = 1 + Companion_getInstance_11().eih(A.bim_1, D.bim_1) | 0;
    } else {
      B.aim_1 = iE;
      A.zil_1 = iD;
      D.yil_1 = iA;
      A.wil_1.bi8(C.wil_1, D.wil_1);
      B.wil_1.bi8(A.wil_1, E.wil_1);
      A.bim_1 = 1 + Companion_getInstance_11().eih(C.bim_1, D.bim_1) | 0;
      B.bim_1 = 1 + Companion_getInstance_11().eih(A.bim_1, E.bim_1) | 0;
    }
    return iB;
  }
  return iA;
}
var Companion_instance_4;
function Companion_getInstance_6() {
  return Companion_instance_4;
}
function init_org_jbox2d_collision_shapes_MassData(_this__u8e3s4) {
  _this__u8e3s4.uim_1 = 0;
  _this__u8e3s4.wim_1 = 0;
}
var Companion_instance_5;
function Companion_getInstance_7() {
  return Companion_instance_5;
}
var ShapeType_CIRCLE_instance;
var ShapeType_EDGE_instance;
var ShapeType_POLYGON_instance;
var ShapeType_CHAIN_instance;
function values() {
  return [ShapeType_CIRCLE_getInstance(), ShapeType_EDGE_getInstance(), ShapeType_POLYGON_getInstance(), ShapeType_CHAIN_getInstance()];
}
var ShapeType_entriesInitialized;
function ShapeType_initEntries() {
  if (ShapeType_entriesInitialized)
    return Unit_instance;
  ShapeType_entriesInitialized = true;
  ShapeType_CIRCLE_instance = new ShapeType('CIRCLE', 0);
  ShapeType_EDGE_instance = new ShapeType('EDGE', 1);
  ShapeType_POLYGON_instance = new ShapeType('POLYGON', 2);
  ShapeType_CHAIN_instance = new ShapeType('CHAIN', 3);
}
function ShapeType_CIRCLE_getInstance() {
  ShapeType_initEntries();
  return ShapeType_CIRCLE_instance;
}
function ShapeType_EDGE_getInstance() {
  ShapeType_initEntries();
  return ShapeType_EDGE_instance;
}
function ShapeType_POLYGON_getInstance() {
  ShapeType_initEntries();
  return ShapeType_POLYGON_instance;
}
function ShapeType_CHAIN_getInstance() {
  ShapeType_initEntries();
  return ShapeType_CHAIN_instance;
}
var BufferUtils_instance;
function BufferUtils_getInstance() {
  return BufferUtils_instance;
}
var Companion_instance_6;
function Companion_getInstance_8() {
  if (Companion_instance_6 === VOID)
    new Companion_6();
  return Companion_instance_6;
}
function init_org_jbox2d_common_Color3f(_this__u8e3s4) {
  Companion_getInstance_8();
  _this__u8e3s4.eim_1 = 0;
  _this__u8e3s4.fim_1 = 0;
  _this__u8e3s4.gim_1 = 0;
}
var Companion_instance_7;
function Companion_getInstance_9() {
  return Companion_instance_7;
}
function init_org_jbox2d_common_Mat22(_this__u8e3s4) {
}
var Companion_instance_8;
function Companion_getInstance_10() {
  if (Companion_instance_8 === VOID)
    new Companion_8();
  return Companion_instance_8;
}
function init_org_jbox2d_common_Mat33(_this__u8e3s4) {
  Companion_getInstance_10();
}
var Companion_instance_9;
function Companion_getInstance_11() {
  if (Companion_instance_9 === VOID)
    new Companion_9();
  return Companion_instance_9;
}
var Companion_instance_10;
function Companion_getInstance_12() {
  return Companion_instance_10;
}
function init_org_jbox2d_common_Rot(_this__u8e3s4) {
  _this__u8e3s4.mie_1 = 0;
  _this__u8e3s4.nie_1 = 0;
}
function get__FAST_ABS() {
  _init_properties_Settings_kt__323xz7();
  return _FAST_ABS;
}
var _FAST_ABS;
function get__FAST_FLOOR() {
  _init_properties_Settings_kt__323xz7();
  return _FAST_FLOOR;
}
var _FAST_FLOOR;
var _FAST_CEIL;
function get__FAST_ROUND() {
  _init_properties_Settings_kt__323xz7();
  return _FAST_ROUND;
}
var _FAST_ROUND;
var _FAST_ATAN2;
var _FAST_POW;
function get__CONTACT_STACK_INIT_SIZE() {
  _init_properties_Settings_kt__323xz7();
  return _CONTACT_STACK_INIT_SIZE;
}
var _CONTACT_STACK_INIT_SIZE;
function get__SINCOS_LUT_ENABLED() {
  _init_properties_Settings_kt__323xz7();
  return _SINCOS_LUT_ENABLED;
}
var _SINCOS_LUT_ENABLED;
function get__SINCOS_LUT_LERP() {
  _init_properties_Settings_kt__323xz7();
  return _SINCOS_LUT_LERP;
}
var _SINCOS_LUT_LERP;
function get__maxManifoldPoints() {
  _init_properties_Settings_kt__323xz7();
  return _maxManifoldPoints;
}
var _maxManifoldPoints;
function get__maxPolygonVertices() {
  _init_properties_Settings_kt__323xz7();
  return _maxPolygonVertices;
}
var _maxPolygonVertices;
function get__aabbExtension() {
  _init_properties_Settings_kt__323xz7();
  return _aabbExtension;
}
var _aabbExtension;
function get__aabbMultiplier() {
  _init_properties_Settings_kt__323xz7();
  return _aabbMultiplier;
}
var _aabbMultiplier;
function get__linearSlop() {
  _init_properties_Settings_kt__323xz7();
  return _linearSlop;
}
var _linearSlop;
function get__angularSlop() {
  _init_properties_Settings_kt__323xz7();
  return _angularSlop;
}
var _angularSlop;
function get__polygonRadius() {
  _init_properties_Settings_kt__323xz7();
  return _polygonRadius;
}
var _polygonRadius;
function get__maxSubSteps() {
  _init_properties_Settings_kt__323xz7();
  return _maxSubSteps;
}
var _maxSubSteps;
function get__maxTOIContacts() {
  _init_properties_Settings_kt__323xz7();
  return _maxTOIContacts;
}
var _maxTOIContacts;
function get__velocityThreshold() {
  _init_properties_Settings_kt__323xz7();
  return _velocityThreshold;
}
var _velocityThreshold;
function get__maxLinearCorrection() {
  _init_properties_Settings_kt__323xz7();
  return _maxLinearCorrection;
}
var _maxLinearCorrection;
var _maxAngularCorrection;
function get__maxTranslation() {
  _init_properties_Settings_kt__323xz7();
  return _maxTranslation;
}
var _maxTranslation;
function get__maxTranslationSquared() {
  _init_properties_Settings_kt__323xz7();
  return _maxTranslationSquared;
}
var _maxTranslationSquared;
function get__maxRotation() {
  _init_properties_Settings_kt__323xz7();
  return _maxRotation;
}
var _maxRotation;
function get__maxRotationSquared() {
  _init_properties_Settings_kt__323xz7();
  return _maxRotationSquared;
}
var _maxRotationSquared;
function get__baumgarte() {
  _init_properties_Settings_kt__323xz7();
  return _baumgarte;
}
var _baumgarte;
function get__toiBaugarte() {
  _init_properties_Settings_kt__323xz7();
  return _toiBaugarte;
}
var _toiBaugarte;
function get__timeToSleep() {
  _init_properties_Settings_kt__323xz7();
  return _timeToSleep;
}
var _timeToSleep;
function get__linearSleepTolerance() {
  _init_properties_Settings_kt__323xz7();
  return _linearSleepTolerance;
}
var _linearSleepTolerance;
function get__angularSleepTolerance() {
  _init_properties_Settings_kt__323xz7();
  return _angularSleepTolerance;
}
var _angularSleepTolerance;
var Settings_instance;
function Settings_getInstance() {
  if (Settings_instance === VOID)
    new Settings();
  return Settings_instance;
}
var properties_initialized_Settings_kt_3nqv97;
function _init_properties_Settings_kt__323xz7() {
  if (!properties_initialized_Settings_kt_3nqv97) {
    properties_initialized_Settings_kt_3nqv97 = true;
    _FAST_ABS = true;
    _FAST_FLOOR = true;
    _FAST_CEIL = true;
    _FAST_ROUND = false;
    _FAST_ATAN2 = true;
    _FAST_POW = true;
    _CONTACT_STACK_INIT_SIZE = 10;
    _SINCOS_LUT_ENABLED = true;
    _SINCOS_LUT_LERP = false;
    _maxManifoldPoints = 2;
    _maxPolygonVertices = 8;
    _aabbExtension = 0.1;
    _aabbMultiplier = 2.0;
    _linearSlop = 0.005;
    _angularSlop = 2.0 / 180.0 * Settings_getInstance().ki7_1;
    _polygonRadius = 2.0 * get__linearSlop();
    _maxSubSteps = 8;
    _maxTOIContacts = 32;
    _velocityThreshold = 1.0;
    _maxLinearCorrection = 0.2;
    _maxAngularCorrection = 8.0 / 180.0 * Settings_getInstance().ki7_1;
    _maxTranslation = 2.0;
    _maxTranslationSquared = get__maxTranslation() * get__maxTranslation();
    _maxRotation = 0.5 * Settings_getInstance().ki7_1;
    _maxRotationSquared = get__maxRotation() * get__maxRotation();
    _baumgarte = 0.2;
    _toiBaugarte = 0.75;
    _timeToSleep = 0.5;
    _linearSleepTolerance = 0.01;
    _angularSleepTolerance = 2.0 / 180.0 * Settings_getInstance().ki7_1;
  }
}
var Companion_instance_11;
function Companion_getInstance_13() {
  return Companion_instance_11;
}
function init_org_jbox2d_common_Transform(_this__u8e3s4) {
}
var Companion_instance_12;
function Companion_getInstance_14() {
  return Companion_instance_12;
}
var Companion_instance_13;
function Companion_getInstance_15() {
  return Companion_instance_13;
}
var Companion_instance_14;
function Companion_getInstance_16() {
  return Companion_instance_14;
}
var BodyType_STATIC_instance;
var BodyType_KINEMATIC_instance;
var BodyType_DYNAMIC_instance;
var Companion_instance_15;
function Companion_getInstance_17() {
  BodyType_initEntries();
  if (Companion_instance_15 === VOID)
    new Companion_15();
  return Companion_instance_15;
}
function values_0() {
  return [BodyType_STATIC_getInstance(), BodyType_KINEMATIC_getInstance(), BodyType_DYNAMIC_getInstance()];
}
var BodyType_entriesInitialized;
function BodyType_initEntries() {
  if (BodyType_entriesInitialized)
    return Unit_instance;
  BodyType_entriesInitialized = true;
  BodyType_STATIC_instance = new BodyType('STATIC', 0);
  BodyType_KINEMATIC_instance = new BodyType('KINEMATIC', 1);
  BodyType_DYNAMIC_instance = new BodyType('DYNAMIC', 2);
  Companion_getInstance_17();
}
function BodyType_STATIC_getInstance() {
  BodyType_initEntries();
  return BodyType_STATIC_instance;
}
function BodyType_KINEMATIC_getInstance() {
  BodyType_initEntries();
  return BodyType_KINEMATIC_instance;
}
function BodyType_DYNAMIC_getInstance() {
  BodyType_initEntries();
  return BodyType_DYNAMIC_instance;
}
var Companion_instance_16;
function Companion_getInstance_18() {
  if (Companion_instance_16 === VOID)
    new Companion_16();
  return Companion_instance_16;
}
function addType($this, creator, type1, type2) {
  var register = new ContactRegister();
  register.eiz_1 = creator;
  register.fiz_1 = true;
  $this.si2_1[type1.v3_1][type2.v3_1] = register;
  if (!(type1 === type2)) {
    var register2 = new ContactRegister();
    register2.eiz_1 = creator;
    register2.fiz_1 = false;
    $this.si2_1[type2.v3_1][type1.v3_1] = register2;
  }
}
function initializeRegisters($this) {
  addType($this, $this.vi1_1.giz(), ShapeType_CIRCLE_getInstance(), ShapeType_CIRCLE_getInstance());
  addType($this, $this.vi1_1.hiz(), ShapeType_POLYGON_getInstance(), ShapeType_CIRCLE_getInstance());
  addType($this, $this.vi1_1.iiz(), ShapeType_POLYGON_getInstance(), ShapeType_POLYGON_getInstance());
  addType($this, $this.vi1_1.jiz(), ShapeType_EDGE_getInstance(), ShapeType_CIRCLE_getInstance());
  addType($this, $this.vi1_1.kiz(), ShapeType_EDGE_getInstance(), ShapeType_POLYGON_getInstance());
  addType($this, $this.vi1_1.liz(), ShapeType_CHAIN_getInstance(), ShapeType_CIRCLE_getInstance());
  addType($this, $this.vi1_1.miz(), ShapeType_CHAIN_getInstance(), ShapeType_POLYGON_getInstance());
}
function solve($this, step) {
  $this.qi2_1.eix_1.ciz();
  $this.qi2_1.fix_1.ciz();
  $this.qi2_1.gix_1.ciz();
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var b = $this.ci2_1;
  while (!(b == null)) {
    ensureNotNull(b).mi4_1.pid(ensureNotNull(b).li4_1);
    b = ensureNotNull(b).ti4_1;
  }
  $this.ei3_1.miv($this.ei2_1, $this.bi2_1.wiq_1, $this.fi2_1, $this.bi2_1.yiq_1);
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var b_0 = $this.ci2_1;
  while (!(b_0 == null)) {
    ensureNotNull(b_0).ji4_1 = ensureNotNull(b_0).ji4_1 & ~Companion_instance_14.nip_1;
    b_0 = ensureNotNull(b_0).ti4_1;
  }
  var c = $this.bi2_1.viq_1;
  while (!(c == null)) {
    c.xis_1 = c.xis_1 & ~Companion_instance_18.yit_1;
    c = c.zis_1;
  }
  var j = $this.di2_1;
  while (!(j == null)) {
    j.lis_1 = false;
    j = j.gis_1;
  }
  var stackSize = $this.ei2_1;
  if ($this.fi3_1.length < stackSize) {
    var tmp = $this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.fi3_1 = fillArrayVal(Array(stackSize), null);
  }
  var seed = $this.ci2_1;
  $l$loop_1: while (!(seed == null)) {
    if ((seed.ji4_1 & Companion_instance_14.nip_1) === Companion_instance_14.nip_1) {
      seed = seed.ti4_1;
      continue $l$loop_1;
    }
    if (!seed.siq() || !seed.d15()) {
      seed = seed.ti4_1;
      continue $l$loop_1;
    }
    if (seed.b2n() === BodyType_STATIC_getInstance()) {
      seed = seed.ti4_1;
      continue $l$loop_1;
    }
    $this.ei3_1.o3();
    var stackCount = 0;
    var tmp_0 = $this.fi3_1;
    var _unary__edvuaz = stackCount;
    stackCount = _unary__edvuaz + 1 | 0;
    tmp_0[_unary__edvuaz] = seed;
    seed.ji4_1 = seed.ji4_1 | Companion_instance_14.nip_1;
    $l$loop_2: while (stackCount > 0) {
      var tmp_1 = $this.fi3_1;
      stackCount = stackCount - 1 | 0;
      var b_1 = ensureNotNull(tmp_1[stackCount]);
      // Inline function 'org.jbox2d.internal.assert' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp_2;
      if (!b_1.d15()) {
        // Inline function 'kotlin.check.<anonymous>' call
        var message = 'Check failed.';
        throw IllegalStateException.m5(toString(message));
      }
      $this.ei3_1.xix(b_1);
      b_1.oi5(true);
      if (b_1.b2n() === BodyType_STATIC_getInstance()) {
        continue $l$loop_2;
      }
      var ce = b_1.xi4_1;
      $l$loop_6: while (!(ce == null)) {
        var contact = ce.bir_1;
        if ((ensureNotNull(contact).xis_1 & Companion_instance_18.yit_1) === Companion_instance_18.yit_1) {
          ce = ce.dir_1;
          continue $l$loop_6;
        }
        if (!contact.niz() || !contact.vit()) {
          ce = ce.dir_1;
          continue $l$loop_6;
        }
        var sensorA = ensureNotNull(contact.cit_1).xi6_1;
        var sensorB = ensureNotNull(contact.dit_1).xi6_1;
        if (sensorA || sensorB) {
          ce = ce.dir_1;
          continue $l$loop_6;
        }
        $this.ei3_1.yix(contact);
        contact.xis_1 = contact.xis_1 | Companion_instance_18.yit_1;
        var other = ce.air_1;
        if ((ensureNotNull(other).ji4_1 & Companion_instance_14.nip_1) === Companion_instance_14.nip_1) {
          ce = ce.dir_1;
          continue $l$loop_6;
        }
        // Inline function 'org.jbox2d.internal.assert' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_3;
        if (!(stackCount < stackSize)) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_0 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_0));
        }
        var tmp_4 = $this.fi3_1;
        var _unary__edvuaz_0 = stackCount;
        stackCount = _unary__edvuaz_0 + 1 | 0;
        tmp_4[_unary__edvuaz_0] = other;
        other.ji4_1 = other.ji4_1 | Companion_instance_14.nip_1;
        ce = ce.dir_1;
      }
      var je = b_1.wi4_1;
      $l$loop_9: while (!(je == null)) {
        if (ensureNotNull(je.zir_1).lis_1) {
          je = je.bis_1;
          continue $l$loop_9;
        }
        var other_0 = je.yir_1;
        if (!ensureNotNull(other_0).d15()) {
          je = je.bis_1;
          continue $l$loop_9;
        }
        $this.ei3_1.zix(ensureNotNull(je.zir_1));
        ensureNotNull(je.zir_1).lis_1 = true;
        if ((other_0.ji4_1 & Companion_instance_14.nip_1) === Companion_instance_14.nip_1) {
          je = je.bis_1;
          continue $l$loop_9;
        }
        // Inline function 'org.jbox2d.internal.assert' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_5;
        if (!(stackCount < stackSize)) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_1 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_1));
        }
        var tmp_6 = $this.fi3_1;
        var _unary__edvuaz_1 = stackCount;
        stackCount = _unary__edvuaz_1 + 1 | 0;
        tmp_6[_unary__edvuaz_1] = other_0;
        other_0.ji4_1 = other_0.ji4_1 | Companion_instance_14.nip_1;
        je = je.bis_1;
      }
    }
    $this.ei3_1.niv($this.qi2_1, step, $this.gi2_1, $this.hi2_1);
    var inductionVariable = 0;
    var last = $this.ei3_1.ziu_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var b_2 = ensureNotNull($this.ei3_1.uiu_1)[i];
        if (b_2.b2n() === BodyType_STATIC_getInstance()) {
          b_2.ji4_1 = b_2.ji4_1 & ~Companion_instance_14.nip_1;
        }
      }
       while (inductionVariable < last);
    seed = seed.ti4_1;
  }
  $this.qi2_1.eix_1.diz();
  $this.qi2_1.fix_1.diz();
  $this.qi2_1.gix_1.diz();
  $this.gi3_1.z33();
  var b_3 = $this.ci2_1;
  $l$loop_11: while (!(b_3 == null)) {
    if ((ensureNotNull(b_3).ji4_1 & Companion_instance_14.nip_1) === 0) {
      b_3 = ensureNotNull(b_3).kiq();
      continue $l$loop_11;
    }
    if (ensureNotNull(b_3).b2n() === BodyType_STATIC_getInstance()) {
      b_3 = ensureNotNull(b_3).kiq();
      continue $l$loop_11;
    }
    ensureNotNull(b_3).vir();
    b_3 = ensureNotNull(b_3).kiq();
  }
  $this.bi2_1.tit();
  $this.qi2_1.hix_1.xiy($this.gi3_1.fip());
}
function solveTOI($this, step) {
  var island = $this.hi3_1;
  island.miv(imul(2, Settings_getInstance().rio()), Settings_getInstance().rio(), 0, $this.bi2_1.yiq_1);
  if ($this.pi2_1) {
    var b = $this.ci2_1;
    while (!(b == null)) {
      b.ji4_1 = b.ji4_1 & ~Companion_instance_14.nip_1;
      b.ni4_1.tii_1 = 0.0;
      b = b.ti4_1;
    }
    var c = $this.bi2_1.viq_1;
    while (!(c == null)) {
      c.xis_1 = c.xis_1 & ~(Companion_instance_18.diu_1 | Companion_instance_18.yit_1);
      c.hit_1 = 0.0;
      c.iit_1 = 1.0;
      c = c.zis_1;
    }
  }
  $l$loop_15: while (true) {
    var minContact = null;
    var minAlpha = 1.0;
    var c_0 = $this.bi2_1.viq_1;
    $l$loop_3: while (!(c_0 == null)) {
      if (!c_0.niz()) {
        c_0 = c_0.zis_1;
        continue $l$loop_3;
      }
      if (c_0.hit_1 > Settings_getInstance().qio()) {
        c_0 = c_0.zis_1;
        continue $l$loop_3;
      }
      var alpha = 1.0;
      if (!((c_0.xis_1 & Companion_instance_18.diu_1) === 0)) {
        alpha = c_0.iit_1;
      } else {
        var fA = c_0.nit();
        var fB = c_0.oit();
        if (ensureNotNull(fA).sit() || ensureNotNull(fB).sit()) {
          c_0 = c_0.zis_1;
          continue $l$loop_3;
        }
        var bA = fA.vis();
        var bB = fB.vis();
        var typeA = ensureNotNull(bA).ii4_1;
        var typeB = ensureNotNull(bB).ii4_1;
        // Inline function 'org.jbox2d.internal.assert' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp;
        if (!(typeA === BodyType_DYNAMIC_getInstance() || typeB === BodyType_DYNAMIC_getInstance())) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message = 'Check failed.';
          throw IllegalStateException.m5(toString(message));
        }
        var activeA = bA.siq() && !(typeA === BodyType_STATIC_getInstance());
        var activeB = bB.siq() && !(typeB === BodyType_STATIC_getInstance());
        if (!activeA && !activeB) {
          c_0 = c_0.zis_1;
          continue $l$loop_3;
        }
        var collideA = bA.riq() || !(typeA === BodyType_DYNAMIC_getInstance());
        var collideB = bB.riq() || !(typeB === BodyType_DYNAMIC_getInstance());
        if (!collideA && !collideB) {
          c_0 = c_0.zis_1;
          continue $l$loop_3;
        }
        var alpha0 = bA.ni4_1.tii_1;
        if (bA.ni4_1.tii_1 < bB.ni4_1.tii_1) {
          alpha0 = bB.ni4_1.tii_1;
          bA.ni4_1.dip(alpha0);
        } else if (bB.ni4_1.tii_1 < bA.ni4_1.tii_1) {
          alpha0 = bA.ni4_1.tii_1;
          bB.ni4_1.dip(alpha0);
        }
        // Inline function 'org.jbox2d.internal.assert' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp_0;
        if (!(alpha0 < 1.0)) {
          // Inline function 'kotlin.check.<anonymous>' call
          var message_0 = 'Check failed.';
          throw IllegalStateException.m5(toString(message_0));
        }
        var indexA = c_0.pit();
        var indexB = c_0.qit();
        var input = $this.ii3_1;
        input.oih_1.oid(ensureNotNull(fA.giu()), indexA);
        input.pih_1.oid(ensureNotNull(fB.giu()), indexB);
        input.qih_1.uii(bA.ni4_1);
        input.rih_1.uii(bB.ni4_1);
        input.sih_1 = 1.0;
        $this.vi1_1.oiz().nii($this.ji3_1, input);
        var beta = $this.ji3_1.uih_1;
        if ($this.ji3_1.tih_1 === TOIOutputState_TOUCHING_getInstance()) {
          alpha = Companion_getInstance_11().bic(alpha0 + (1.0 - alpha0) * beta, 1.0);
        } else {
          alpha = 1.0;
        }
        c_0.iit_1 = alpha;
        c_0.xis_1 = c_0.xis_1 | Companion_instance_18.diu_1;
      }
      if (alpha < minAlpha) {
        minContact = c_0;
        minAlpha = alpha;
      }
      c_0 = c_0.zis_1;
    }
    if (minContact == null || 1.0 - 10.0 * Settings_getInstance().ji7_1 < minAlpha) {
      $this.pi2_1 = true;
      break $l$loop_15;
    }
    var fA_0 = minContact.nit();
    var fB_0 = minContact.oit();
    var bA_0 = ensureNotNull(fA_0).vis();
    var bB_0 = ensureNotNull(fB_0).vis();
    $this.mi3_1.uii(ensureNotNull(bA_0).ni4_1);
    $this.ni3_1.uii(ensureNotNull(bB_0).ni4_1);
    bA_0.dip(minAlpha);
    bB_0.dip(minAlpha);
    minContact.eiu($this.bi2_1.yiq_1);
    minContact.xis_1 = minContact.xis_1 & ~Companion_instance_18.diu_1;
    var _receiver__tnumb7 = minContact;
    _receiver__tnumb7.hit_1 = _receiver__tnumb7.hit_1 + 1;
    if (!minContact.niz() || !minContact.vit()) {
      minContact.piz(false);
      bA_0.ni4_1.uii($this.mi3_1);
      bB_0.ni4_1.uii($this.ni3_1);
      bA_0.wir();
      bB_0.wir();
      continue $l$loop_15;
    }
    bA_0.oi5(true);
    bB_0.oi5(true);
    island.o3();
    island.xix(bA_0);
    island.xix(bB_0);
    island.yix(minContact);
    bA_0.ji4_1 = bA_0.ji4_1 | Companion_instance_14.nip_1;
    bB_0.ji4_1 = bB_0.ji4_1 | Companion_instance_14.nip_1;
    minContact.xis_1 = minContact.xis_1 | Companion_instance_18.yit_1;
    $this.li3_1[0] = bA_0;
    $this.li3_1[1] = bB_0;
    var inductionVariable = 0;
    if (inductionVariable <= 1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var body = ensureNotNull($this.li3_1[i]);
        if (body.ii4_1 === BodyType_DYNAMIC_getInstance()) {
          var ce = body.xi4_1;
          $l$loop_13: while (!(ce == null) && island.ziu_1 !== island.civ_1 && island.biv_1 !== island.div_1) {
            var contact = ce.bir_1;
            if (!((ensureNotNull(contact).xis_1 & Companion_instance_18.yit_1) === 0)) {
              ce = ce.dir_1;
              continue $l$loop_13;
            }
            var other = ce.air_1;
            if (ensureNotNull(other).ii4_1 === BodyType_DYNAMIC_getInstance() && !body.riq() && !ensureNotNull(other).riq()) {
              ce = ce.dir_1;
              continue $l$loop_13;
            }
            var sensorA = ensureNotNull(contact.cit_1).xi6_1;
            var sensorB = ensureNotNull(contact.dit_1).xi6_1;
            if (sensorA || sensorB) {
              ce = ce.dir_1;
              continue $l$loop_13;
            }
            $this.mi3_1.uii(ensureNotNull(other).ni4_1);
            if ((other.ji4_1 & Companion_instance_14.nip_1) === 0) {
              other.dip(minAlpha);
            }
            contact.eiu($this.bi2_1.yiq_1);
            if (!contact.niz()) {
              other.ni4_1.uii($this.mi3_1);
              other.wir();
              ce = ce.dir_1;
              continue $l$loop_13;
            }
            if (!contact.vit()) {
              other.ni4_1.uii($this.mi3_1);
              other.wir();
              ce = ce.dir_1;
              continue $l$loop_13;
            }
            contact.xis_1 = contact.xis_1 | Companion_instance_18.yit_1;
            island.yix(contact);
            if (!((other.ji4_1 & Companion_instance_14.nip_1) === 0)) {
              ce = ce.dir_1;
              continue $l$loop_13;
            }
            other.ji4_1 = other.ji4_1 | Companion_instance_14.nip_1;
            if (!(other.ii4_1 === BodyType_STATIC_getInstance())) {
              other.oi5(true);
            }
            island.xix(other);
            ce = ce.dir_1;
          }
        }
      }
       while (inductionVariable <= 1);
    $this.ki3_1.oiv_1 = (1.0 - minAlpha) * step.oiv_1;
    $this.ki3_1.piv_1 = 1.0 / $this.ki3_1.oiv_1;
    $this.ki3_1.qiv_1 = 1.0;
    $this.ki3_1.siv_1 = 20;
    $this.ki3_1.riv_1 = step.riv_1;
    $this.ki3_1.tiv_1 = false;
    island.vix($this.ki3_1, bA_0.ki4_1, bB_0.ki4_1);
    var inductionVariable_0 = 0;
    var last = island.ziu_1;
    if (inductionVariable_0 < last)
      $l$loop_14: do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var body_0 = ensureNotNull(island.uiu_1)[i_0];
        body_0.ji4_1 = body_0.ji4_1 & ~Companion_instance_14.nip_1;
        if (!(body_0.ii4_1 === BodyType_DYNAMIC_getInstance())) {
          continue $l$loop_14;
        }
        body_0.vir();
        var ce_0 = body_0.xi4_1;
        while (!(ce_0 == null)) {
          ensureNotNull(ce_0.bir_1).xis_1 = ensureNotNull(ce_0.bir_1).xis_1 & ~(Companion_instance_18.diu_1 | Companion_instance_18.yit_1);
          ce_0 = ce_0.dir_1;
        }
      }
       while (inductionVariable_0 < last);
    $this.bi2_1.tit();
    if ($this.oi2_1) {
      $this.pi2_1 = false;
      break $l$loop_15;
    }
  }
}
var Companion_instance_17;
function Companion_getInstance_19() {
  return Companion_instance_17;
}
var Companion_instance_18;
function Companion_getInstance_20() {
  return Companion_instance_18;
}
function solveVelocityConstraints2($this, vc, mA, mB, iA, iB, normal, normalx, normaly) {
  var cp1 = vc.aiy_1[0];
  var cp2 = vc.aiy_1[1];
  var cp1rA = cp1.piy_1;
  var cp1rB = cp1.qiy_1;
  var cp2rA = cp2.piy_1;
  var cp2rB = cp2.qiy_1;
  var ax = cp1.riy_1;
  var ay = cp2.riy_1;
  var dv1x = -$this.uiw_1 * cp1rB.bi4_1 + ensureNotNull($this.siw_1).ai4_1 - ensureNotNull($this.riw_1).ai4_1 + $this.tiw_1 * cp1rA.bi4_1;
  var dv1y = $this.uiw_1 * cp1rB.ai4_1 + ensureNotNull($this.siw_1).bi4_1 - ensureNotNull($this.riw_1).bi4_1 - $this.tiw_1 * cp1rA.ai4_1;
  var dv2x = -$this.uiw_1 * cp2rB.bi4_1 + ensureNotNull($this.siw_1).ai4_1 - ensureNotNull($this.riw_1).ai4_1 + $this.tiw_1 * cp2rA.bi4_1;
  var dv2y = $this.uiw_1 * cp2rB.ai4_1 + ensureNotNull($this.siw_1).bi4_1 - ensureNotNull($this.riw_1).bi4_1 - $this.tiw_1 * cp2rA.ai4_1;
  var vn1 = dv1x * normalx + dv1y * normaly;
  var vn2 = dv2x * normalx + dv2y * normaly;
  var bx = vn1 - cp1.viy_1;
  var by = vn2 - cp2.viy_1;
  var R = vc.diy_1;
  bx = bx - (R.hin_1.ai4_1 * ax + R.iin_1.ai4_1 * ay);
  by = by - (R.hin_1.bi4_1 * ax + R.iin_1.bi4_1 * ay);
  var R1 = vc.ciy_1;
  var xx = R1.hin_1.ai4_1 * bx + R1.iin_1.ai4_1 * by;
  var xy = R1.hin_1.bi4_1 * bx + R1.iin_1.bi4_1 * by;
  xx = xx * -1.0;
  xy = xy * -1.0;
  if (xx >= 0.0 && xy >= 0.0) {
    solveVelocityConstraints2a($this, mA, mB, iA, iB, normal, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy);
  } else {
    xx = -cp1.tiy_1 * bx;
    xy = 0.0;
    vn1 = 0.0;
    vn2 = vc.diy_1.hin_1.bi4_1 * xx + by;
    if (xx >= 0.0 && vn2 >= 0.0) {
      solveVelocityConstraints2b($this, mA, mB, iA, iB, normal, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy);
    } else {
      xx = 0.0;
      xy = -cp2.tiy_1 * by;
      vn1 = vc.diy_1.iin_1.ai4_1 * xy + bx;
      vn2 = 0.0;
      if (xy >= 0.0 && vn1 >= 0.0) {
        solveVelocityConstraints2c($this, mA, mB, iA, iB, normal, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy);
      } else {
        xx = 0.0;
        xy = 0.0;
        vn1 = bx;
        vn2 = by;
        if (vn1 >= 0.0 && vn2 >= 0.0) {
          solveVelocityConstraints2d($this, mA, mB, iA, iB, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy);
        }
      }
    }
  }
}
function solveVelocityConstraints2d($this, mA, mB, iA, iB, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy) {
  var dx = xx - ax;
  var dy = xy - ay;
  var P1x = normalx * dx;
  var P1y = normaly * dx;
  var P2x = normalx * dy;
  var P2y = normaly * dy;
  var _receiver__tnumb7 = ensureNotNull($this.riw_1);
  _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - mA * (P1x + P2x);
  var _receiver__tnumb7_0 = ensureNotNull($this.riw_1);
  _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - mA * (P1y + P2y);
  var _receiver__tnumb7_1 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_1.ai4_1 = _receiver__tnumb7_1.ai4_1 + mB * (P1x + P2x);
  var _receiver__tnumb7_2 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_2.bi4_1 = _receiver__tnumb7_2.bi4_1 + mB * (P1y + P2y);
  $this.tiw_1 = $this.tiw_1 - iA * (cp1rA.ai4_1 * P1y - cp1rA.bi4_1 * P1x + (cp2rA.ai4_1 * P2y - cp2rA.bi4_1 * P2x));
  $this.uiw_1 = $this.uiw_1 + iB * (cp1rB.ai4_1 * P1y - cp1rB.bi4_1 * P1x + (cp2rB.ai4_1 * P2y - cp2rB.bi4_1 * P2x));
  cp1.riy_1 = xx;
  cp2.riy_1 = xy;
}
function solveVelocityConstraints2c($this, mA, mB, iA, iB, normal, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy) {
  var vn2;
  var dx = xx - ax;
  var dy = xy - ay;
  var P1x = normalx * dx;
  var P1y = normaly * dx;
  var P2x = normalx * dy;
  var P2y = normaly * dy;
  var _receiver__tnumb7 = ensureNotNull($this.riw_1);
  _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - mA * (P1x + P2x);
  var _receiver__tnumb7_0 = ensureNotNull($this.riw_1);
  _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - mA * (P1y + P2y);
  var _receiver__tnumb7_1 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_1.ai4_1 = _receiver__tnumb7_1.ai4_1 + mB * (P1x + P2x);
  var _receiver__tnumb7_2 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_2.bi4_1 = _receiver__tnumb7_2.bi4_1 + mB * (P1y + P2y);
  $this.tiw_1 = $this.tiw_1 - iA * (cp1rA.ai4_1 * P1y - cp1rA.bi4_1 * P1x + (cp2rA.ai4_1 * P2y - cp2rA.bi4_1 * P2x));
  $this.uiw_1 = $this.uiw_1 + iB * (cp1rB.ai4_1 * P1y - cp1rB.bi4_1 * P1x + (cp2rB.ai4_1 * P2y - cp2rB.bi4_1 * P2x));
  cp1.riy_1 = xx;
  cp2.riy_1 = xy;
  if (Companion_instance_19.uj5_1) {
    var dv2 = ensureNotNull($this.siw_1).kip(Companion_instance_12.hip($this.uiw_1, cp2rB).wia(ensureNotNull($this.riw_1)).wia(Companion_instance_12.hip($this.tiw_1, cp2rA)));
    vn2 = Companion_instance_12.yia(dv2, normal);
  }
}
function solveVelocityConstraints2b($this, mA, mB, iA, iB, normal, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy) {
  var vn1;
  var dx = xx - ax;
  var dy = xy - ay;
  var P1x = normalx * dx;
  var P1y = normaly * dx;
  var P2x = normalx * dy;
  var P2y = normaly * dy;
  var _receiver__tnumb7 = ensureNotNull($this.riw_1);
  _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - mA * (P1x + P2x);
  var _receiver__tnumb7_0 = ensureNotNull($this.riw_1);
  _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - mA * (P1y + P2y);
  var _receiver__tnumb7_1 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_1.ai4_1 = _receiver__tnumb7_1.ai4_1 + mB * (P1x + P2x);
  var _receiver__tnumb7_2 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_2.bi4_1 = _receiver__tnumb7_2.bi4_1 + mB * (P1y + P2y);
  $this.tiw_1 = $this.tiw_1 - iA * (cp1rA.ai4_1 * P1y - cp1rA.bi4_1 * P1x + (cp2rA.ai4_1 * P2y - cp2rA.bi4_1 * P2x));
  $this.uiw_1 = $this.uiw_1 + iB * (cp1rB.ai4_1 * P1y - cp1rB.bi4_1 * P1x + (cp2rB.ai4_1 * P2y - cp2rB.bi4_1 * P2x));
  cp1.riy_1 = xx;
  cp2.riy_1 = xy;
  if (Companion_instance_19.uj5_1) {
    var dv1 = ensureNotNull($this.siw_1).kip(Companion_instance_12.hip($this.uiw_1, cp1rB).wia(ensureNotNull($this.riw_1)).wia(Companion_instance_12.hip($this.tiw_1, cp1rA)));
    vn1 = Companion_instance_12.yia(dv1, normal);
  }
}
function solveVelocityConstraints2a($this, mA, mB, iA, iB, normal, normalx, normaly, cp1, cp2, cp1rA, cp1rB, cp2rA, cp2rB, ax, ay, xx, xy) {
  var vn1;
  var vn2;
  var dx = xx - ax;
  var dy = xy - ay;
  var P1x = dx * normalx;
  var P1y = dx * normaly;
  var P2x = dy * normalx;
  var P2y = dy * normaly;
  var _receiver__tnumb7 = ensureNotNull($this.riw_1);
  _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - mA * (P1x + P2x);
  var _receiver__tnumb7_0 = ensureNotNull($this.riw_1);
  _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - mA * (P1y + P2y);
  var _receiver__tnumb7_1 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_1.ai4_1 = _receiver__tnumb7_1.ai4_1 + mB * (P1x + P2x);
  var _receiver__tnumb7_2 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_2.bi4_1 = _receiver__tnumb7_2.bi4_1 + mB * (P1y + P2y);
  $this.tiw_1 = $this.tiw_1 - iA * (cp1rA.ai4_1 * P1y - cp1rA.bi4_1 * P1x + (cp2rA.ai4_1 * P2y - cp2rA.bi4_1 * P2x));
  $this.uiw_1 = $this.uiw_1 + iB * (cp1rB.ai4_1 * P1y - cp1rB.bi4_1 * P1x + (cp2rB.ai4_1 * P2y - cp2rB.bi4_1 * P2x));
  cp1.riy_1 = xx;
  cp2.riy_1 = xy;
  if (Companion_instance_19.uj5_1) {
    var dv1 = ensureNotNull($this.siw_1).kip(Companion_instance_12.hip($this.uiw_1, cp1rB).wia(ensureNotNull($this.riw_1)).wia(Companion_instance_12.hip($this.tiw_1, cp1rA)));
    var dv2 = ensureNotNull($this.siw_1).kip(Companion_instance_12.hip($this.uiw_1, cp2rB).wia(ensureNotNull($this.riw_1)).wia(Companion_instance_12.hip($this.tiw_1, cp2rA)));
    vn1 = Companion_instance_12.yia(dv1, normal);
    vn2 = Companion_instance_12.yia(dv2, normal);
  }
}
function solveVelocityConstraints1($this, point, mA, mB, iA, iB, normalx, normaly) {
  var dvx = -$this.uiw_1 * point.qiy_1.bi4_1 + ensureNotNull($this.siw_1).ai4_1 - ensureNotNull($this.riw_1).ai4_1 + $this.tiw_1 * point.piy_1.bi4_1;
  var dvy = $this.uiw_1 * point.qiy_1.ai4_1 + ensureNotNull($this.siw_1).bi4_1 - ensureNotNull($this.riw_1).bi4_1 - $this.tiw_1 * point.piy_1.ai4_1;
  var vn = dvx * normalx + dvy * normaly;
  var lambda = -point.tiy_1 * (vn - point.viy_1);
  var a = point.riy_1 + lambda;
  var newImpulse = a > 0.0 ? a : 0.0;
  lambda = newImpulse - point.riy_1;
  point.riy_1 = newImpulse;
  var Px = normalx * lambda;
  var Py = normaly * lambda;
  var _receiver__tnumb7 = ensureNotNull($this.riw_1);
  _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - Px * mA;
  var _receiver__tnumb7_0 = ensureNotNull($this.riw_1);
  _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - Py * mA;
  $this.tiw_1 = $this.tiw_1 - iA * (point.piy_1.ai4_1 * Py - point.piy_1.bi4_1 * Px);
  var _receiver__tnumb7_1 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_1.ai4_1 = _receiver__tnumb7_1.ai4_1 + Px * mB;
  var _receiver__tnumb7_2 = ensureNotNull($this.siw_1);
  _receiver__tnumb7_2.bi4_1 = _receiver__tnumb7_2.bi4_1 + Py * mB;
  $this.uiw_1 = $this.uiw_1 + iB * (point.qiy_1.ai4_1 * Py - point.qiy_1.bi4_1 * Px);
}
function solveVelocityConstraints0($this, vc, mA, mB, iA, iB, pointCount, tangentx, tangenty, friction) {
  var inductionVariable = 0;
  if (inductionVariable < pointCount)
    do {
      var j = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var vcp = vc.aiy_1[j];
      var a = vcp.piy_1;
      var dvx = -$this.uiw_1 * vcp.qiy_1.bi4_1 + ensureNotNull($this.siw_1).ai4_1 - ensureNotNull($this.riw_1).ai4_1 + $this.tiw_1 * a.bi4_1;
      var dvy = $this.uiw_1 * vcp.qiy_1.ai4_1 + ensureNotNull($this.siw_1).bi4_1 - ensureNotNull($this.riw_1).bi4_1 - $this.tiw_1 * a.ai4_1;
      var vt = dvx * tangentx + dvy * tangenty - vc.miy_1;
      var lambda = vcp.uiy_1 * -vt;
      var maxFriction = friction * vcp.riy_1;
      var newImpulse = Companion_getInstance_11().hio(vcp.siy_1 + lambda, -maxFriction, maxFriction);
      lambda = newImpulse - vcp.siy_1;
      vcp.siy_1 = newImpulse;
      var Px = tangentx * lambda;
      var Py = tangenty * lambda;
      var _receiver__tnumb7 = ensureNotNull($this.riw_1);
      _receiver__tnumb7.ai4_1 = _receiver__tnumb7.ai4_1 - Px * mA;
      var _receiver__tnumb7_0 = ensureNotNull($this.riw_1);
      _receiver__tnumb7_0.bi4_1 = _receiver__tnumb7_0.bi4_1 - Py * mA;
      $this.tiw_1 = $this.tiw_1 - iA * (vcp.piy_1.ai4_1 * Py - vcp.piy_1.bi4_1 * Px);
      var _receiver__tnumb7_1 = ensureNotNull($this.siw_1);
      _receiver__tnumb7_1.ai4_1 = _receiver__tnumb7_1.ai4_1 + Px * mB;
      var _receiver__tnumb7_2 = ensureNotNull($this.siw_1);
      _receiver__tnumb7_2.bi4_1 = _receiver__tnumb7_2.bi4_1 + Py * mB;
      $this.uiw_1 = $this.uiw_1 + iB * (vcp.qiy_1.ai4_1 * Py - vcp.qiy_1.bi4_1 * Px);
    }
     while (inductionVariable < pointCount);
}
var Companion_instance_19;
function Companion_getInstance_21() {
  return Companion_instance_19;
}
var Companion_instance_20;
function Companion_getInstance_22() {
  return Companion_instance_20;
}
function Arrays_sort(array, fromIndex, toIndex) {
  var sorted = sortedArray(copyOfRange(array, fromIndex, toIndex));
  // Inline function 'org.jbox2d.internal.arraycopy' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = 0 + (toIndex - fromIndex | 0) | 0;
  arrayCopy(sorted, array, fromIndex, 0, endIndex);
}
function Arrays_sort_0(array, fromIndex, toIndex) {
  var sorted = sortedArray_0(copyOfRange_0(array, fromIndex, toIndex));
  // Inline function 'org.jbox2d.internal.arraycopy' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = 0 + (toIndex - fromIndex | 0) | 0;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = sorted;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  arrayCopy(tmp, array, fromIndex, 0, endIndex);
}
function init_org_jbox2d_particle_ParticleColor(_this__u8e3s4) {
  _this__u8e3s4.yj8_1 = 0;
  _this__u8e3s4.zj8_1 = 0;
  _this__u8e3s4.aj9_1 = 0;
  _this__u8e3s4.bj9_1 = 0;
}
var ParticleGroupType_instance;
function ParticleGroupType_getInstance() {
  return ParticleGroupType_instance;
}
function ParticleSystem$UpdateBodyContactsCallback$reportFixture$lambda() {
  return new ParticleBodyContact();
}
function lowerBound($this, ray, length, tag) {
  var length_0 = length;
  var left = 0;
  var step;
  var curr;
  while (length_0 > 0) {
    step = length_0 / 2 | 0;
    curr = left + step | 0;
    if (ray[curr].sj9_1.g2(tag) < 0) {
      left = curr + 1 | 0;
      length_0 = length_0 - (step + 1 | 0) | 0;
    } else {
      length_0 = step;
    }
  }
  return left;
}
function upperBound($this, ray, length, tag) {
  var length_0 = length;
  var left = 0;
  var step;
  var curr;
  while (length_0 > 0) {
    step = length_0 / 2 | 0;
    curr = left + step | 0;
    if (ray[curr].sj9_1.g2(tag) <= 0) {
      left = curr + 1 | 0;
      length_0 = length_0 - (step + 1 | 0) | 0;
    } else {
      length_0 = step;
    }
  }
  return left;
}
var Test_instance;
function Test_getInstance() {
  return Test_instance;
}
var Companion_instance_21;
function Companion_getInstance_23() {
  if (Companion_instance_21 === VOID)
    new Companion_21();
  return Companion_instance_21;
}
function ParticleSystem$m_positionBuffer$lambda() {
  return new Vec2();
}
function ParticleSystem$m_velocityBuffer$lambda() {
  return new Vec2();
}
function ParticleSystem$m_colorBuffer$lambda() {
  return ParticleColor.cj9();
}
function ParticleSystem$m_userDataBuffer$lambda() {
  return new Object();
}
function ParticleSystem$addContact$lambda() {
  return new ParticleContact();
}
function ParticleSystem$solveTensile$lambda() {
  return new Vec2();
}
function ParticleSystem$solveColorMixing$lambda() {
  return ParticleColor.cj9();
}
var ParticleType_instance;
function ParticleType_getInstance() {
  return ParticleType_instance;
}
function DefaultWorldPool$vecs$lambda() {
  return new Vec2();
}
function DefaultWorldPool$vec3s$lambda() {
  return Vec3.pin();
}
function DefaultWorldPool$mats$lambda() {
  return Mat22.jin();
}
function DefaultWorldPool$mat33s$lambda() {
  return Mat33.uin();
}
function DefaultWorldPool$aabbs$lambda() {
  return AABB.ai8();
}
function DefaultWorldPool$rots$lambda() {
  return Rot.lio();
}
function DefaultWorldPool$pcstack$lambda(this$0) {
  return function () {
    return new PolygonContact(this$0.eje_1);
  };
}
function DefaultWorldPool$pcstack$lambda_0(it) {
  // Inline function 'kotlin.arrayOfNulls' call
  return fillArrayVal(Array(it), null);
}
function DefaultWorldPool$ccstack$lambda(this$0) {
  return function () {
    return new CircleContact(this$0.eje_1);
  };
}
function DefaultWorldPool$ccstack$lambda_0(it) {
  // Inline function 'kotlin.arrayOfNulls' call
  return fillArrayVal(Array(it), null);
}
function DefaultWorldPool$cpstack$lambda(this$0) {
  return function () {
    return new PolygonAndCircleContact(this$0.eje_1);
  };
}
function DefaultWorldPool$cpstack$lambda_0(it) {
  // Inline function 'kotlin.arrayOfNulls' call
  return fillArrayVal(Array(it), null);
}
function DefaultWorldPool$ecstack$lambda(this$0) {
  return function () {
    return new EdgeAndCircleContact(this$0.eje_1);
  };
}
function DefaultWorldPool$ecstack$lambda_0(it) {
  // Inline function 'kotlin.arrayOfNulls' call
  return fillArrayVal(Array(it), null);
}
function DefaultWorldPool$epstack$lambda(this$0) {
  return function () {
    return new EdgeAndPolygonContact(this$0.eje_1);
  };
}
function DefaultWorldPool$epstack$lambda_0(it) {
  // Inline function 'kotlin.arrayOfNulls' call
  return fillArrayVal(Array(it), null);
}
function DefaultWorldPool$chcstack$lambda(this$0) {
  return function () {
    return new ChainAndCircleContact(this$0.eje_1);
  };
}
function DefaultWorldPool$chcstack$lambda_0(it) {
  // Inline function 'kotlin.arrayOfNulls' call
  return fillArrayVal(Array(it), null);
}
function DefaultWorldPool$chpstack$lambda(this$0) {
  return function () {
    return new ChainAndPolygonContact(this$0.eje_1);
  };
}
function DefaultWorldPool$chpstack$lambda_0(it) {
  // Inline function 'kotlin.arrayOfNulls' call
  return fillArrayVal(Array(it), null);
}
function ensureInit($this) {
  if ($this.rjf_1 == null) {
    extendStack($this, $this.qjf_1);
  }
}
function extendStack($this, argSize) {
  var newStack = $this.pjf(argSize);
  if (!($this.rjf_1 == null)) {
    // Inline function 'org.jbox2d.internal.arraycopy' call
    var src = ensureNotNull($this.rjf_1);
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = 0 + $this.tjf_1 | 0;
    arrayCopy(src, newStack, 0, 0, endIndex);
  }
  var inductionVariable = 0;
  var last = newStack.length - 1 | 0;
  if (inductionVariable <= last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      newStack[i] = $this.ijf();
    }
     while (inductionVariable <= last);
  $this.rjf_1 = newStack;
  $this.tjf_1 = newStack.length;
}
function _get_pool__dc4pf3($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.zje_1;
  pool$factory();
  return this_0.b2();
}
function OrderedStack$pool$delegate$lambda(this$0) {
  return function () {
    var tmp = 0;
    var tmp_0 = this$0.yje_1;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(tmp_0), null);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var tmp_3 = this$0.ijf();
      tmp_1[tmp_2] = !(tmp_3 == null) ? tmp_3 : THROW_CCE();
      tmp = tmp + 1 | 0;
    }
    return tmp_1;
  };
}
function pool$factory() {
  return getPropertyCallableRef('pool', 1, KProperty1, function (receiver) {
    return _get_pool__dc4pf3(receiver);
  }, null);
}
//region block: post-declaration
initMetadataForClass(Key, 'Key', Key);
initMetadataForObject(Key_0, 'Key');
initMetadataForClass(Box2dWorldComponent, 'Box2dWorldComponent');
initMetadataForClass(ContactFilter, 'ContactFilter', ContactFilter);
initMetadataForClass(ContactImpulse, 'ContactImpulse', ContactImpulse);
initMetadataForCompanion(Companion);
initMetadataForClass(AABB, 'AABB', AABB.ai8);
initMetadataForClass(Type, 'Type');
initMetadataForClass(EdgeResults, 'EdgeResults', EdgeResults);
initMetadataForClass(ClipVertex, 'ClipVertex', ClipVertex);
initMetadataForClass(EPAxis, 'EPAxis', EPAxis);
initMetadataForClass(TempPolygon, 'TempPolygon', TempPolygon);
initMetadataForClass(ReferenceFace, 'ReferenceFace', ReferenceFace);
initMetadataForClass(EPCollider, 'EPCollider', EPCollider);
initMetadataForCompanion(Companion_0);
initMetadataForClass(Collision, 'Collision');
initMetadataForClass(Type_0, 'Type');
initMetadataForClass(ContactID, 'ContactID', ContactID.ki8, VOID, [Comparable]);
initMetadataForClass(SimplexVertex, 'SimplexVertex');
initMetadataForClass(SimplexCache, 'SimplexCache', SimplexCache);
initMetadataForClass(Simplex, 'Simplex');
initMetadataForClass(DistanceProxy, 'DistanceProxy', DistanceProxy);
initMetadataForCompanion(Companion_1);
initMetadataForClass(Stats, 'Stats', Stats);
initMetadataForClass(Distance, 'Distance', Distance);
initMetadataForClass(DistanceInput, 'DistanceInput', DistanceInput);
initMetadataForClass(DistanceOutput, 'DistanceOutput', DistanceOutput);
initMetadataForClass(ManifoldType, 'ManifoldType');
initMetadataForClass(Manifold, 'Manifold', Manifold.fih);
initMetadataForClass(ManifoldPoint, 'ManifoldPoint', ManifoldPoint.gih);
initMetadataForClass(RayCastInput, 'RayCastInput', RayCastInput);
initMetadataForClass(RayCastOutput, 'RayCastOutput', RayCastOutput);
initMetadataForClass(TOIInput, 'TOIInput', TOIInput);
initMetadataForClass(TOIOutputState, 'TOIOutputState');
initMetadataForClass(TOIOutput, 'TOIOutput', TOIOutput);
initMetadataForCompanion(Companion_2);
initMetadataForClass(Stats_0, 'Stats', Stats_0);
initMetadataForClass(TimeOfImpact, 'TimeOfImpact');
initMetadataForClass(SeparationFunction, 'SeparationFunction', SeparationFunction);
initMetadataForClass(Type_1, 'Type');
initMetadataForClass(WorldManifold, 'WorldManifold', WorldManifold);
initMetadataForCompanion(Companion_3);
initMetadataForClass(DefaultBroadPhaseBuffer, 'DefaultBroadPhaseBuffer');
initMetadataForCompanion(Companion_4);
initMetadataForClass(DynamicTree, 'DynamicTree', DynamicTree);
initMetadataForClass(DynamicTreeNode, 'DynamicTreeNode');
initMetadataForClass(Shape, 'Shape');
initMetadataForClass(ChainShape, 'ChainShape');
initMetadataForClass(CircleShape, 'CircleShape', CircleShape.lim);
initMetadataForClass(EdgeShape, 'EdgeShape', EdgeShape.xim);
initMetadataForClass(MassData, 'MassData', MassData.yim);
initMetadataForCompanion(Companion_5);
initMetadataForClass(PolygonShape, 'PolygonShape', PolygonShape.ji6);
initMetadataForClass(ShapeType, 'ShapeType');
initMetadataForObject(BufferUtils, 'BufferUtils');
initMetadataForCompanion(Companion_6);
initMetadataForClass(Color3f, 'Color3f', Color3f.him);
initMetadataForCompanion(Companion_7);
initMetadataForClass(Mat22, 'Mat22', Mat22.jin);
initMetadataForCompanion(Companion_8);
initMetadataForClass(Mat33, 'Mat33', Mat33.uin);
initMetadataForCompanion(Companion_9);
initMetadataForCompanion(Companion_10);
initMetadataForClass(Rot, 'Rot', Rot.lio);
initMetadataForObject(Settings, 'Settings');
initMetadataForClass(Sweep, 'Sweep', Sweep);
initMetadataForClass(Timer, 'Timer', Timer);
initMetadataForCompanion(Companion_11);
initMetadataForClass(Transform, 'Transform', Transform.iia);
initMetadataForCompanion(Companion_12);
initMetadataForClass(Vec2, 'Vec2', Vec2);
initMetadataForCompanion(Companion_13);
initMetadataForClass(Vec3, 'Vec3', Vec3.pin);
initMetadataForClass(ViewInfo, 'ViewInfo', ViewInfo);
initMetadataForCompanion(Companion_14);
initMetadataForClass(Body, 'Body');
initMetadataForClass(BodyDef, 'BodyDef', BodyDef);
initMetadataForCompanion(Companion_15);
initMetadataForClass(BodyType, 'BodyType');
initMetadataForClass(ContactManager, 'ContactManager');
initMetadataForClass(Filter, 'Filter', Filter);
initMetadataForClass(Fixture, 'Fixture', Fixture);
initMetadataForClass(FixtureDef, 'FixtureDef', FixtureDef);
initMetadataForClass(FixtureProxy, 'FixtureProxy', FixtureProxy);
initMetadataForClass(Island, 'Island', Island);
initMetadataForClass(ProfileEntry, 'ProfileEntry', ProfileEntry);
initMetadataForCompanion(Companion_16);
initMetadataForClass(Profile, 'Profile', Profile);
initMetadataForClass(SolverData, 'SolverData', SolverData);
initMetadataForClass(TimeStep, 'TimeStep', TimeStep);
initMetadataForCompanion(Companion_17);
initMetadataForClass(World, 'World', World.li6);
initMetadataForClass(WorldQueryWrapper, 'WorldQueryWrapper', WorldQueryWrapper);
initMetadataForClass(WorldRayCastWrapper, 'WorldRayCastWrapper', WorldRayCastWrapper);
initMetadataForClass(Contact, 'Contact');
initMetadataForClass(ChainAndCircleContact, 'ChainAndCircleContact');
initMetadataForClass(ChainAndPolygonContact, 'ChainAndPolygonContact');
initMetadataForClass(CircleContact, 'CircleContact');
initMetadataForCompanion(Companion_18);
initMetadataForClass(ContactEdge, 'ContactEdge', ContactEdge);
initMetadataForClass(ContactPositionConstraint, 'ContactPositionConstraint', ContactPositionConstraint);
initMetadataForClass(ContactRegister, 'ContactRegister', ContactRegister);
initMetadataForClass(ContactSolverDef, 'ContactSolverDef', ContactSolverDef);
initMetadataForCompanion(Companion_19);
initMetadataForClass(ContactSolver, 'ContactSolver', ContactSolver);
initMetadataForClass(VelocityConstraintPoint, 'VelocityConstraintPoint', VelocityConstraintPoint);
initMetadataForClass(ContactVelocityConstraint, 'ContactVelocityConstraint', ContactVelocityConstraint);
initMetadataForClass(EdgeAndCircleContact, 'EdgeAndCircleContact');
initMetadataForClass(EdgeAndPolygonContact, 'EdgeAndPolygonContact');
initMetadataForClass(PolygonAndCircleContact, 'PolygonAndCircleContact');
initMetadataForClass(PolygonContact, 'PolygonContact');
initMetadataForClass(Position, 'Position', Position);
initMetadataForClass(PositionSolverManifold, 'PositionSolverManifold', PositionSolverManifold);
initMetadataForClass(Velocity, 'Velocity', Velocity);
initMetadataForCompanion(Companion_20);
initMetadataForClass(ParticleBodyContact, 'ParticleBodyContact', ParticleBodyContact);
initMetadataForClass(ParticleColor, 'ParticleColor', ParticleColor.cj9);
initMetadataForClass(ParticleContact, 'ParticleContact', ParticleContact);
initMetadataForClass(ParticleDef, 'ParticleDef', ParticleDef);
initMetadataForObject(ParticleGroupType, 'ParticleGroupType');
initMetadataForClass(NewIndices, 'NewIndices', NewIndices);
initMetadataForClass(ParticleBuffer, 'ParticleBuffer');
initMetadataForClass(ParticleBufferInt, 'ParticleBufferInt', ParticleBufferInt);
initMetadataForClass(CreateParticleGroupCallback, 'CreateParticleGroupCallback', CreateParticleGroupCallback);
initMetadataForClass(DestroyParticlesInShapeCallback, 'DestroyParticlesInShapeCallback', DestroyParticlesInShapeCallback);
initMetadataForClass(UpdateBodyContactsCallback, 'UpdateBodyContactsCallback', UpdateBodyContactsCallback);
initMetadataForClass(SolveCollisionCallback, 'SolveCollisionCallback', SolveCollisionCallback);
initMetadataForObject(Test, 'Test');
initMetadataForCompanion(Companion_21);
initMetadataForClass(ParticleSystem, 'ParticleSystem');
initMetadataForObject(ParticleType, 'ParticleType');
initMetadataForClass(Vec2ArrayPool, 'Vec2ArrayPool', Vec2ArrayPool);
initMetadataForClass(DefaultWorldPool, 'DefaultWorldPool');
initMetadataForClass(OrderedStack, 'OrderedStack');
initMetadataForClass(LambdaOrderedStack, 'LambdaOrderedStack');
initMetadataForClass(MutableStack, 'MutableStack');
initMetadataForClass(ContactMutableStack, 'ContactMutableStack');
initMetadataForClass(Mixin, 'Mixin', Mixin);
//endregion
//region block: init
Companion_instance = new Companion();
Companion_instance_0 = new Companion_0();
Companion_instance_1 = new Companion_1();
Companion_instance_2 = new Companion_2();
Companion_instance_3 = new Companion_3();
Companion_instance_4 = new Companion_4();
Companion_instance_5 = new Companion_5();
BufferUtils_instance = new BufferUtils();
Companion_instance_7 = new Companion_7();
Companion_instance_10 = new Companion_10();
Companion_instance_11 = new Companion_11();
Companion_instance_12 = new Companion_12();
Companion_instance_13 = new Companion_13();
Companion_instance_14 = new Companion_14();
Companion_instance_17 = new Companion_17();
Companion_instance_18 = new Companion_18();
Companion_instance_19 = new Companion_19();
Companion_instance_20 = new Companion_20();
ParticleGroupType_instance = new ParticleGroupType();
Test_instance = new Test();
ParticleType_instance = new ParticleType();
//endregion
//region block: exports
export {
  BodyType_DYNAMIC_getInstance as BodyType_DYNAMIC_getInstance2kq43ngiumdiy,
  BodyType_STATIC_getInstance as BodyType_STATIC_getInstance28op0hzskw7ek,
  BoxShape as BoxShape3f6a119xanuci,
  set_body as set_bodyvp9llzt3kbjv,
  get_nearestBox2dWorld as get_nearestBox2dWorld47kjmo2at5qk,
  set_view as set_view3vgcwd4b67w0s,
  CircleShape as CircleShape15ov0bod3acmc,
  BodyDef as BodyDef1xn75220z5hnw,
  FixtureDef as FixtureDeft7xk00u87x3d,
};
//endregion
