import {
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  protoOf180f3jzyo7rfj as protoOf,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  VOID7hggqo3abtya as VOID,
  toString30pk9tzaqopn as toString,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  hashCodeq5arwsb9dgti as hashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  equals2au1ep9vhcato as equals,
  Unit_instance104q5opgivhr8 as Unit_instance,
  createThis2j2avj17cvnv2 as createThis,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  toString1pkumu07cwy4m as toString_0,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  LazyThreadSafetyMode_PUBLICATION_getInstance3hlj875zwihx0 as LazyThreadSafetyMode_PUBLICATION_getInstance,
  lazy1261dae0bgscp as lazy,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  Enum3alwj03lh1n41 as Enum,
  getKClass1s3j9wy1cofik as getKClass,
  arrayOf1akklvh2at202 as arrayOf,
  createKType1lgox3mzhchp5 as createKType,
  isInterface3d6p8outrmvmk as isInterface,
  toDoubleOrNullkxwozihadygj as toDoubleOrNull,
  toMapcf6xfku344cz as toMap,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  toList3jhuyej2anx2q as toList,
  ArrayList3it5z8td81qkl as ArrayList,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  lazy2hsh8ze7j6ikd as lazy_0,
  until1jbpn0z3f8lbg as until,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  to2cs3ny02qtbcb as to,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  emptyList1g2z5xcrvp2zy as emptyList,
  asReversed308kw52j6ls1u as asReversed,
  KtMap140uvy3s5zad8 as KtMap,
  copyOf3rutauicler23 as copyOf,
  plus310ted5e4i90h as plus,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  numberToDouble210hrknaofnhf as numberToDouble,
  toList383f556t1dixk as toList_0,
  zipfdxxupzuj2p9 as zip,
} from './kotlin-kotlin-stdlib.mjs';
import {
  PluginGeneratedSerialDescriptorqdzeg5asqhfg as PluginGeneratedSerialDescriptor,
  UnknownFieldExceptiona60e3a6v1xqo as UnknownFieldException,
  StringSerializer_getInstance1k1oz5j50sfik as StringSerializer_getInstance,
  get_nullable197rfua9r7fsz as get_nullable,
  IntSerializer_getInstance9scrtcljaiv6 as IntSerializer_getInstance,
  typeParametersSerializers2likxjr48tr7y as typeParametersSerializers,
  GeneratedSerializer1f7t7hssdd2ws as GeneratedSerializer,
  throwMissingFieldException2cmke0v3ynf14 as throwMissingFieldException,
  DoubleSerializer_getInstance2avi1jm48x8le as DoubleSerializer_getInstance,
  ArrayListSerializer7k5wnrulb3y6 as ArrayListSerializer,
  LinkedHashSetSerializer3ncla559t2lx7 as LinkedHashSetSerializer,
  IntArraySerializer_getInstance23lwf0o9n75js as IntArraySerializer_getInstance,
  BooleanSerializer_getInstancet3wtk7fl7i4f as BooleanSerializer_getInstance,
  createSimpleEnumSerializer2guioz11kk1m0 as createSimpleEnumSerializer,
  STRING_getInstance2gbamclnmtzqa as STRING_getInstance,
  PrimitiveSerialDescriptor3egfp53lutxj2 as PrimitiveSerialDescriptor,
  KSerializerzf77vz1967fq as KSerializer,
  SerializerFactory1qv9hivitncuv as SerializerFactory,
  serializer1i4e9ym37oxmo as serializer,
} from './kotlinx-serialization-kotlinx-serialization-core.mjs';
import {
  JsonElementSerializer_getInstance1rgo1giz8g15y as JsonElementSerializer_getInstance,
  JsonArraySerializer_getInstance29kwliignms5j as JsonArraySerializer_getInstance,
  JsonObjectSerializer_getInstance197qdg6ernwdp as JsonObjectSerializer_getInstance,
  Jsonsmkyu9xjl7fv as Json,
  get_jsonPrimitivez17tyd5rw1ql as get_jsonPrimitive,
  JsonPrimitive3ttzjh2ft5dnx as JsonPrimitive,
  JsonObjectee06ihoeeiqj as JsonObject,
  JsonArray2urf8ey7u44sd as JsonArray,
  JsonNull2liwjj96vm0w2 as JsonNull,
} from './kotlinx-serialization-kotlinx-serialization-json.mjs';
import {
  Mixin3lmkm5hum2vdj as Mixin,
  getExtraTyped8zexqxz5xxli as getExtraTyped,
  setExtra314k0z6q35eku as setExtra,
  PropertyThis2r0hbk1cs09wj as PropertyThis,
} from './korlibs-korlibs-datastructure-core.mjs';
import { _Dyn___init__impl__6cuxxc3ue03b1bho0av as _Dyn___init__impl__6cuxxc } from './korlibs-korlibs-dyn.mjs';
import {
  Anchor2D3ucppq7m9n3z8 as Anchor2D,
  Vector2I2k5512l4eeahb as Vector2I,
  RectangleInt3lmu13ytuf5lk as RectangleInt,
  Size2Dum5ayuyhhau7 as Size2D,
  Companion_getInstance21plr7f541fx9 as Companion_getInstance,
  toDouble2d46ijanub8on as toDouble,
  Vector2D378fe5rov8fgw as Vector2D,
} from './korlibs-korlibs-math-vector.mjs';
import { unexpected1jwjs94qam4x9 as unexpected } from './korlibs-korlibs-platform.mjs';
import {
  Bitmap321klycakvwgb5w as Bitmap32,
  Companion_getInstancenwobv30yoie as Companion_getInstance_0,
  slice2pfxfvku0ybgy as slice,
  TileSetTileInfo253o60hp1pekl as TileSetTileInfo,
  Colors_getInstance1qvds1isfbag2 as Colors_getInstance,
  readBitmap1dn2o52mvbpv4 as readBitmap,
  Companion_getInstance1y19lmoptxnwi as Companion_getInstance_1,
  _Tile___get_data__impl__sfu1ss2s3x8eomzpvh5 as _Tile___get_data__impl__sfu1ss,
  TileMapRepeat_NONE_getInstance1rwcpdxdrcp50 as TileMapRepeat_NONE_getInstance,
  sliceWithSize2okpd9nq8v6n3 as sliceWithSize,
  extractrn4rijx1x4kx as extract,
} from './korlibs-korlibs-image.mjs';
import {
  Container7486fjmip554 as Container,
  SolidRect131p7hyzlx7j8 as SolidRect,
  addTo25e29vq105prw as addTo,
  TileMapunot5gnjvpqy as TileMap,
  alphaher384qrj9q4 as alpha,
  Nearest_getInstance2ablj0bsr7f3q as Nearest_getInstance,
  get_filterScaleuvlwz3cded4n as get_filterScale,
  Companion_instance3f2pfd31j919l as Companion_instance,
  set_filter36wgvpm5cukpc as set_filter,
  set_filterScale3iptc9lhu4vp1 as set_filterScale,
  Image1b2d7ghyim7p5 as Image,
  size1ac0fubkxl72w as size,
  Anchorable2p2wd2c4mob8i as Anchorable,
  anchor3c5itxst996yy as anchor,
  xyssniz4rympby as xy,
  zIndex304xgfufia3na as zIndex,
} from './korge-root-korge.mjs';
import {
  linkedHashMapOf2w2uhgrq4khrk as linkedHashMapOf,
  IntArray23680o40e0fgt8 as IntArray2,
  StackedIntArray21gpxcd3ph9cq2 as StackedIntArray2,
} from './korlibs-korlibs-datastructure.mjs';
import { hasBitSet141smdymg86x0 as hasBitSet } from './korlibs-korlibs-math-core.mjs';
import { RectSlicec5msorw5q4el as RectSlice } from './korlibs-korlibs-math.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Companion {}
class $serializer {
  constructor() {
    $serializer_instance = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.FieldInstance', this, 6);
    tmp0_serialDesc.fjp('__identifier', false);
    tmp0_serialDesc.fjp('__tile', true);
    tmp0_serialDesc.fjp('__type', false);
    tmp0_serialDesc.fjp('__value', false);
    tmp0_serialDesc.fjp('defUid', false);
    tmp0_serialDesc.fjp('realEditorValues', false);
    this.zk5_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.zk5_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = 0;
    var tmp9_local5 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.djk(tmp0_desc, 1, $serializer_getInstance_4(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.zjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.ujj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp10_input.bjk(tmp0_desc, 5, JsonArraySerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.djk(tmp0_desc, 1, $serializer_getInstance_4(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.zjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.djk(tmp0_desc, 3, JsonElementSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.ujj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp10_input.bjk(tmp0_desc, 5, JsonArraySerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return FieldInstance.gk6(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.zk5_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), get_nullable($serializer_getInstance_4()), StringSerializer_getInstance(), get_nullable(JsonElementSerializer_getInstance()), IntSerializer_getInstance(), JsonArraySerializer_getInstance()];
  }
}
class FieldInstance {
  toString() {
    return 'FieldInstance(identifier=' + this.ak6_1 + ', tile=' + toString(this.bk6_1) + ', type=' + this.ck6_1 + ', value=' + toString(this.dk6_1) + ', defUid=' + this.ek6_1 + ', realEditorValues=' + this.fk6_1.toString() + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.ak6_1);
    result = imul(result, 31) + (this.bk6_1 == null ? 0 : this.bk6_1.hashCode()) | 0;
    result = imul(result, 31) + getStringHashCode(this.ck6_1) | 0;
    result = imul(result, 31) + (this.dk6_1 == null ? 0 : hashCode(this.dk6_1)) | 0;
    result = imul(result, 31) + this.ek6_1 | 0;
    result = imul(result, 31) + this.fk6_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FieldInstance))
      return false;
    var tmp0_other_with_cast = other instanceof FieldInstance ? other : THROW_CCE();
    if (!(this.ak6_1 === tmp0_other_with_cast.ak6_1))
      return false;
    if (!equals(this.bk6_1, tmp0_other_with_cast.bk6_1))
      return false;
    if (!(this.ck6_1 === tmp0_other_with_cast.ck6_1))
      return false;
    if (!equals(this.dk6_1, tmp0_other_with_cast.dk6_1))
      return false;
    if (!(this.ek6_1 === tmp0_other_with_cast.ek6_1))
      return false;
    if (!this.fk6_1.equals(tmp0_other_with_cast.fk6_1))
      return false;
    return true;
  }
  static gk6(seen0, identifier, tile, type, value, defUid, realEditorValues, serializationConstructorMarker) {
    if (!(61 === (61 & seen0))) {
      throwMissingFieldException(seen0, 61, $serializer_getInstance().zk5_1);
    }
    var $this = createThis(this);
    $this.ak6_1 = identifier;
    if (0 === (seen0 & 2))
      $this.bk6_1 = null;
    else
      $this.bk6_1 = tile;
    $this.ck6_1 = type;
    $this.dk6_1 = value;
    $this.ek6_1 = defUid;
    $this.fk6_1 = realEditorValues;
    return $this;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_1 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.hk6_1 = [null, null, new ArrayListSerializer(DoubleSerializer_getInstance()), null, new LinkedHashSetSerializer(StringSerializer_getInstance()), null, null, new ArrayListSerializer($serializer_getInstance()), null, null, null, null];
  }
}
class $serializer_0 {
  constructor() {
    $serializer_instance_0 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.EntityInstance', this, 12);
    tmp0_serialDesc.fjp('__grid', false);
    tmp0_serialDesc.fjp('__identifier', false);
    tmp0_serialDesc.fjp('__pivot', false);
    tmp0_serialDesc.fjp('__smartColor', false);
    tmp0_serialDesc.fjp('__tags', false);
    tmp0_serialDesc.fjp('__tile', true);
    tmp0_serialDesc.fjp('defUid', false);
    tmp0_serialDesc.fjp('fieldInstances', false);
    tmp0_serialDesc.fjp('height', false);
    tmp0_serialDesc.fjp('iid', false);
    tmp0_serialDesc.fjp('px', false);
    tmp0_serialDesc.fjp('width', false);
    this.ik6_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ik6_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = 0;
    var tmp11_local7 = null;
    var tmp12_local8 = 0;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = 0;
    var tmp16_input = decoder.pjj(tmp0_desc);
    var tmp17_cached = Companion_getInstance_3().hk6_1;
    if (tmp16_input.fjk()) {
      tmp4_local0 = tmp16_input.bjk(tmp0_desc, 0, IntArraySerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp16_input.zjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp16_input.bjk(tmp0_desc, 2, tmp17_cached[2], tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp16_input.zjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp16_input.bjk(tmp0_desc, 4, tmp17_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp16_input.djk(tmp0_desc, 5, $serializer_getInstance_4(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp16_input.ujj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp16_input.bjk(tmp0_desc, 7, tmp17_cached[7], tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp16_input.ujj(tmp0_desc, 8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp16_input.zjj(tmp0_desc, 9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp16_input.bjk(tmp0_desc, 10, IntArraySerializer_getInstance(), tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp16_input.ujj(tmp0_desc, 11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp16_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp16_input.bjk(tmp0_desc, 0, IntArraySerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp16_input.zjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp16_input.bjk(tmp0_desc, 2, tmp17_cached[2], tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp16_input.zjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp16_input.bjk(tmp0_desc, 4, tmp17_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp16_input.djk(tmp0_desc, 5, $serializer_getInstance_4(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp16_input.ujj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp16_input.bjk(tmp0_desc, 7, tmp17_cached[7], tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp16_input.ujj(tmp0_desc, 8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp16_input.zjj(tmp0_desc, 9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp16_input.bjk(tmp0_desc, 10, IntArraySerializer_getInstance(), tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp16_input.ujj(tmp0_desc, 11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp16_input.qjj(tmp0_desc);
    return EntityInstance.wk6(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, null);
  }
  ujf() {
    return this.ik6_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_3().hk6_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntArraySerializer_getInstance(), StringSerializer_getInstance(), tmp0_cached[2], StringSerializer_getInstance(), tmp0_cached[4], get_nullable($serializer_getInstance_4()), IntSerializer_getInstance(), tmp0_cached[7], IntSerializer_getInstance(), StringSerializer_getInstance(), IntArraySerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class EntityInstance {
  n13(_set____db54di) {
    this.jk6_1.n13(_set____db54di);
  }
  o13() {
    return this.jk6_1.o13();
  }
  toString() {
    return 'EntityInstance(grid=' + toString_0(this.kk6_1) + ', identifier=' + this.lk6_1 + ', pivot=' + toString_0(this.mk6_1) + ', smartColor=' + this.nk6_1 + ', tags=' + toString_0(this.ok6_1) + ', tile=' + toString(this.pk6_1) + ', defUid=' + this.qk6_1 + ', fieldInstances=' + toString_0(this.rk6_1) + ', height=' + this.sk6_1 + ', iid=' + this.tk6_1 + ', px=' + toString_0(this.uk6_1) + ', width=' + this.vk6_1 + ')';
  }
  hashCode() {
    var result = hashCode(this.kk6_1);
    result = imul(result, 31) + getStringHashCode(this.lk6_1) | 0;
    result = imul(result, 31) + hashCode(this.mk6_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.nk6_1) | 0;
    result = imul(result, 31) + hashCode(this.ok6_1) | 0;
    result = imul(result, 31) + (this.pk6_1 == null ? 0 : this.pk6_1.hashCode()) | 0;
    result = imul(result, 31) + this.qk6_1 | 0;
    result = imul(result, 31) + hashCode(this.rk6_1) | 0;
    result = imul(result, 31) + this.sk6_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.tk6_1) | 0;
    result = imul(result, 31) + hashCode(this.uk6_1) | 0;
    result = imul(result, 31) + this.vk6_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityInstance))
      return false;
    var tmp0_other_with_cast = other instanceof EntityInstance ? other : THROW_CCE();
    if (!equals(this.kk6_1, tmp0_other_with_cast.kk6_1))
      return false;
    if (!(this.lk6_1 === tmp0_other_with_cast.lk6_1))
      return false;
    if (!equals(this.mk6_1, tmp0_other_with_cast.mk6_1))
      return false;
    if (!(this.nk6_1 === tmp0_other_with_cast.nk6_1))
      return false;
    if (!equals(this.ok6_1, tmp0_other_with_cast.ok6_1))
      return false;
    if (!equals(this.pk6_1, tmp0_other_with_cast.pk6_1))
      return false;
    if (!(this.qk6_1 === tmp0_other_with_cast.qk6_1))
      return false;
    if (!equals(this.rk6_1, tmp0_other_with_cast.rk6_1))
      return false;
    if (!(this.sk6_1 === tmp0_other_with_cast.sk6_1))
      return false;
    if (!(this.tk6_1 === tmp0_other_with_cast.tk6_1))
      return false;
    if (!equals(this.uk6_1, tmp0_other_with_cast.uk6_1))
      return false;
    if (!(this.vk6_1 === tmp0_other_with_cast.vk6_1))
      return false;
    return true;
  }
  static wk6(seen0, grid, identifier, pivot, smartColor, tags, tile, defUid, fieldInstances, height, iid, px, width, serializationConstructorMarker) {
    Companion_getInstance_3();
    if (!(4063 === (4063 & seen0))) {
      throwMissingFieldException(seen0, 4063, $serializer_getInstance_0().ik6_1);
    }
    var $this = createThis(this);
    $this.kk6_1 = grid;
    $this.lk6_1 = identifier;
    $this.mk6_1 = pivot;
    $this.nk6_1 = smartColor;
    $this.ok6_1 = tags;
    if (0 === (seen0 & 32))
      $this.pk6_1 = null;
    else
      $this.pk6_1 = tile;
    $this.qk6_1 = defUid;
    $this.rk6_1 = fieldInstances;
    $this.sk6_1 = height;
    $this.tk6_1 = iid;
    $this.uk6_1 = px;
    $this.vk6_1 = width;
    $this.jk6_1 = new Mixin();
    return $this;
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_2 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.bk7_1 = [null, new ArrayListSerializer(StringSerializer_getInstance()), Companion_getInstance_8().ijz(), new ArrayListSerializer(StringSerializer_getInstance()), null, null, null, null, null, null, null, null, Companion_getInstance_9().ijz(), Companion_getInstance_10().ijz(), null, null, null, null, null, null, null, null, Companion_getInstance_11().ijz(), null, null, null, null];
  }
}
class $serializer_1 {
  constructor() {
    $serializer_instance_1 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.FieldDefinition', this, 27);
    tmp0_serialDesc.fjp('__type', false);
    tmp0_serialDesc.fjp('acceptFileTypes', true);
    tmp0_serialDesc.fjp('allowedRefs', false);
    tmp0_serialDesc.fjp('allowedRefTags', false);
    tmp0_serialDesc.fjp('allowOutOfLevelRef', false);
    tmp0_serialDesc.fjp('arrayMaxLength', true);
    tmp0_serialDesc.fjp('arrayMinLength', true);
    tmp0_serialDesc.fjp('autoChainRef', false);
    tmp0_serialDesc.fjp('canBeNull', false);
    tmp0_serialDesc.fjp('defaultOverride', true);
    tmp0_serialDesc.fjp('editorAlwaysShow', false);
    tmp0_serialDesc.fjp('editorCutLongValues', false);
    tmp0_serialDesc.fjp('editorDisplayMode', false);
    tmp0_serialDesc.fjp('editorDisplayPos', false);
    tmp0_serialDesc.fjp('editorTextPrefix', true);
    tmp0_serialDesc.fjp('editorTextSuffix', true);
    tmp0_serialDesc.fjp('identifier', false);
    tmp0_serialDesc.fjp('isArray', false);
    tmp0_serialDesc.fjp('max', true);
    tmp0_serialDesc.fjp('min', true);
    tmp0_serialDesc.fjp('regex', true);
    tmp0_serialDesc.fjp('symmetricalRef', false);
    tmp0_serialDesc.fjp('textLanguageMode', true);
    tmp0_serialDesc.fjp('tilesetUid', true);
    tmp0_serialDesc.fjp('type', false);
    tmp0_serialDesc.fjp('uid', false);
    tmp0_serialDesc.fjp('useForSmartColor', false);
    this.ck7_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ck7_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = false;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = false;
    var tmp12_local8 = false;
    var tmp13_local9 = null;
    var tmp14_local10 = false;
    var tmp15_local11 = false;
    var tmp16_local12 = null;
    var tmp17_local13 = null;
    var tmp18_local14 = null;
    var tmp19_local15 = null;
    var tmp20_local16 = null;
    var tmp21_local17 = false;
    var tmp22_local18 = null;
    var tmp23_local19 = null;
    var tmp24_local20 = null;
    var tmp25_local21 = false;
    var tmp26_local22 = null;
    var tmp27_local23 = null;
    var tmp28_local24 = null;
    var tmp29_local25 = 0;
    var tmp30_local26 = false;
    var tmp31_input = decoder.pjj(tmp0_desc);
    var tmp32_cached = Companion_getInstance_4().bk7_1;
    if (tmp31_input.fjk()) {
      tmp4_local0 = tmp31_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp31_input.djk(tmp0_desc, 1, tmp32_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp31_input.bjk(tmp0_desc, 2, tmp32_cached[2], tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp31_input.bjk(tmp0_desc, 3, tmp32_cached[3], tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp31_input.rjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp31_input.djk(tmp0_desc, 5, IntSerializer_getInstance(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp31_input.djk(tmp0_desc, 6, IntSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp31_input.rjj(tmp0_desc, 7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp31_input.rjj(tmp0_desc, 8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp31_input.djk(tmp0_desc, 9, JsonObjectSerializer_getInstance(), tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp31_input.rjj(tmp0_desc, 10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp31_input.rjj(tmp0_desc, 11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp31_input.bjk(tmp0_desc, 12, tmp32_cached[12], tmp16_local12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp31_input.bjk(tmp0_desc, 13, tmp32_cached[13], tmp17_local13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp31_input.djk(tmp0_desc, 14, StringSerializer_getInstance(), tmp18_local14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp31_input.djk(tmp0_desc, 15, StringSerializer_getInstance(), tmp19_local15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp31_input.zjj(tmp0_desc, 16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp31_input.rjj(tmp0_desc, 17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp31_input.djk(tmp0_desc, 18, DoubleSerializer_getInstance(), tmp22_local18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp31_input.djk(tmp0_desc, 19, DoubleSerializer_getInstance(), tmp23_local19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp31_input.djk(tmp0_desc, 20, StringSerializer_getInstance(), tmp24_local20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
      tmp25_local21 = tmp31_input.rjj(tmp0_desc, 21);
      tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
      tmp26_local22 = tmp31_input.djk(tmp0_desc, 22, tmp32_cached[22], tmp26_local22);
      tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
      tmp27_local23 = tmp31_input.djk(tmp0_desc, 23, IntSerializer_getInstance(), tmp27_local23);
      tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
      tmp28_local24 = tmp31_input.zjj(tmp0_desc, 24);
      tmp3_bitMask0 = tmp3_bitMask0 | 16777216;
      tmp29_local25 = tmp31_input.ujj(tmp0_desc, 25);
      tmp3_bitMask0 = tmp3_bitMask0 | 33554432;
      tmp30_local26 = tmp31_input.rjj(tmp0_desc, 26);
      tmp3_bitMask0 = tmp3_bitMask0 | 67108864;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp31_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp31_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp31_input.djk(tmp0_desc, 1, tmp32_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp31_input.bjk(tmp0_desc, 2, tmp32_cached[2], tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp31_input.bjk(tmp0_desc, 3, tmp32_cached[3], tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp31_input.rjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp31_input.djk(tmp0_desc, 5, IntSerializer_getInstance(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp31_input.djk(tmp0_desc, 6, IntSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp31_input.rjj(tmp0_desc, 7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp31_input.rjj(tmp0_desc, 8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp31_input.djk(tmp0_desc, 9, JsonObjectSerializer_getInstance(), tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp31_input.rjj(tmp0_desc, 10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp31_input.rjj(tmp0_desc, 11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp31_input.bjk(tmp0_desc, 12, tmp32_cached[12], tmp16_local12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp31_input.bjk(tmp0_desc, 13, tmp32_cached[13], tmp17_local13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp31_input.djk(tmp0_desc, 14, StringSerializer_getInstance(), tmp18_local14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp31_input.djk(tmp0_desc, 15, StringSerializer_getInstance(), tmp19_local15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp31_input.zjj(tmp0_desc, 16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp31_input.rjj(tmp0_desc, 17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp31_input.djk(tmp0_desc, 18, DoubleSerializer_getInstance(), tmp22_local18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp31_input.djk(tmp0_desc, 19, DoubleSerializer_getInstance(), tmp23_local19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp31_input.djk(tmp0_desc, 20, StringSerializer_getInstance(), tmp24_local20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          case 21:
            tmp25_local21 = tmp31_input.rjj(tmp0_desc, 21);
            tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
            break;
          case 22:
            tmp26_local22 = tmp31_input.djk(tmp0_desc, 22, tmp32_cached[22], tmp26_local22);
            tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
            break;
          case 23:
            tmp27_local23 = tmp31_input.djk(tmp0_desc, 23, IntSerializer_getInstance(), tmp27_local23);
            tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
            break;
          case 24:
            tmp28_local24 = tmp31_input.zjj(tmp0_desc, 24);
            tmp3_bitMask0 = tmp3_bitMask0 | 16777216;
            break;
          case 25:
            tmp29_local25 = tmp31_input.ujj(tmp0_desc, 25);
            tmp3_bitMask0 = tmp3_bitMask0 | 33554432;
            break;
          case 26:
            tmp30_local26 = tmp31_input.rjj(tmp0_desc, 26);
            tmp3_bitMask0 = tmp3_bitMask0 | 67108864;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp31_input.qjj(tmp0_desc);
    return FieldDefinition.ek8(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, tmp25_local21, tmp26_local22, tmp27_local23, tmp28_local24, tmp29_local25, tmp30_local26, null);
  }
  ujf() {
    return this.ck7_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_4().bk7_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), get_nullable(tmp0_cached[1]), tmp0_cached[2], tmp0_cached[3], BooleanSerializer_getInstance(), get_nullable(IntSerializer_getInstance()), get_nullable(IntSerializer_getInstance()), BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), get_nullable(JsonObjectSerializer_getInstance()), BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), tmp0_cached[12], tmp0_cached[13], get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), StringSerializer_getInstance(), BooleanSerializer_getInstance(), get_nullable(DoubleSerializer_getInstance()), get_nullable(DoubleSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), BooleanSerializer_getInstance(), get_nullable(tmp0_cached[22]), get_nullable(IntSerializer_getInstance()), StringSerializer_getInstance(), IntSerializer_getInstance(), BooleanSerializer_getInstance()];
  }
}
class FieldDefinition {
  toString() {
    return 'FieldDefinition(type=' + this.dk7_1 + ', acceptFileTypes=' + toString(this.ek7_1) + ', allowedRefs=' + this.fk7_1.toString() + ', allowedRefTags=' + toString_0(this.gk7_1) + ', allowOutOfLevelRef=' + this.hk7_1 + ', arrayMaxLength=' + this.ik7_1 + ', arrayMinLength=' + this.jk7_1 + ', autoChainRef=' + this.kk7_1 + ', canBeNull=' + this.lk7_1 + ', defaultOverride=' + toString(this.mk7_1) + ', editorAlwaysShow=' + this.nk7_1 + ', editorCutLongValues=' + this.ok7_1 + ', editorDisplayMode=' + this.pk7_1.toString() + ', editorDisplayPos=' + this.qk7_1.toString() + ', editorTextPrefix=' + this.rk7_1 + ', editorTextSuffix=' + this.sk7_1 + ', identifier=' + this.tk7_1 + ', isArray=' + this.uk7_1 + ', max=' + this.vk7_1 + ', min=' + this.wk7_1 + ', regex=' + this.xk7_1 + ', symmetricalRef=' + this.yk7_1 + ', textLanguageMode=' + toString(this.zk7_1) + ', tilesetUid=' + this.ak8_1 + ', fieldDefinitionType=' + this.bk8_1 + ', uid=' + this.ck8_1 + ', useForSmartColor=' + this.dk8_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.dk7_1);
    result = imul(result, 31) + (this.ek7_1 == null ? 0 : hashCode(this.ek7_1)) | 0;
    result = imul(result, 31) + this.fk7_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.gk7_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.hk7_1) | 0;
    result = imul(result, 31) + (this.ik7_1 == null ? 0 : this.ik7_1) | 0;
    result = imul(result, 31) + (this.jk7_1 == null ? 0 : this.jk7_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.kk7_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.lk7_1) | 0;
    result = imul(result, 31) + (this.mk7_1 == null ? 0 : this.mk7_1.hashCode()) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.nk7_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.ok7_1) | 0;
    result = imul(result, 31) + this.pk7_1.hashCode() | 0;
    result = imul(result, 31) + this.qk7_1.hashCode() | 0;
    result = imul(result, 31) + (this.rk7_1 == null ? 0 : getStringHashCode(this.rk7_1)) | 0;
    result = imul(result, 31) + (this.sk7_1 == null ? 0 : getStringHashCode(this.sk7_1)) | 0;
    result = imul(result, 31) + getStringHashCode(this.tk7_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.uk7_1) | 0;
    result = imul(result, 31) + (this.vk7_1 == null ? 0 : getNumberHashCode(this.vk7_1)) | 0;
    result = imul(result, 31) + (this.wk7_1 == null ? 0 : getNumberHashCode(this.wk7_1)) | 0;
    result = imul(result, 31) + (this.xk7_1 == null ? 0 : getStringHashCode(this.xk7_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.yk7_1) | 0;
    result = imul(result, 31) + (this.zk7_1 == null ? 0 : this.zk7_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ak8_1 == null ? 0 : this.ak8_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.bk8_1) | 0;
    result = imul(result, 31) + this.ck8_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.dk8_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FieldDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof FieldDefinition ? other : THROW_CCE();
    if (!(this.dk7_1 === tmp0_other_with_cast.dk7_1))
      return false;
    if (!equals(this.ek7_1, tmp0_other_with_cast.ek7_1))
      return false;
    if (!this.fk7_1.equals(tmp0_other_with_cast.fk7_1))
      return false;
    if (!equals(this.gk7_1, tmp0_other_with_cast.gk7_1))
      return false;
    if (!(this.hk7_1 === tmp0_other_with_cast.hk7_1))
      return false;
    if (!(this.ik7_1 == tmp0_other_with_cast.ik7_1))
      return false;
    if (!(this.jk7_1 == tmp0_other_with_cast.jk7_1))
      return false;
    if (!(this.kk7_1 === tmp0_other_with_cast.kk7_1))
      return false;
    if (!(this.lk7_1 === tmp0_other_with_cast.lk7_1))
      return false;
    if (!equals(this.mk7_1, tmp0_other_with_cast.mk7_1))
      return false;
    if (!(this.nk7_1 === tmp0_other_with_cast.nk7_1))
      return false;
    if (!(this.ok7_1 === tmp0_other_with_cast.ok7_1))
      return false;
    if (!this.pk7_1.equals(tmp0_other_with_cast.pk7_1))
      return false;
    if (!this.qk7_1.equals(tmp0_other_with_cast.qk7_1))
      return false;
    if (!(this.rk7_1 == tmp0_other_with_cast.rk7_1))
      return false;
    if (!(this.sk7_1 == tmp0_other_with_cast.sk7_1))
      return false;
    if (!(this.tk7_1 === tmp0_other_with_cast.tk7_1))
      return false;
    if (!(this.uk7_1 === tmp0_other_with_cast.uk7_1))
      return false;
    if (!equals(this.vk7_1, tmp0_other_with_cast.vk7_1))
      return false;
    if (!equals(this.wk7_1, tmp0_other_with_cast.wk7_1))
      return false;
    if (!(this.xk7_1 == tmp0_other_with_cast.xk7_1))
      return false;
    if (!(this.yk7_1 === tmp0_other_with_cast.yk7_1))
      return false;
    if (!equals(this.zk7_1, tmp0_other_with_cast.zk7_1))
      return false;
    if (!(this.ak8_1 == tmp0_other_with_cast.ak8_1))
      return false;
    if (!(this.bk8_1 === tmp0_other_with_cast.bk8_1))
      return false;
    if (!(this.ck8_1 === tmp0_other_with_cast.ck8_1))
      return false;
    if (!(this.dk8_1 === tmp0_other_with_cast.dk8_1))
      return false;
    return true;
  }
  static ek8(seen0, type, acceptFileTypes, allowedRefs, allowedRefTags, allowOutOfLevelRef, arrayMaxLength, arrayMinLength, autoChainRef, canBeNull, defaultOverride, editorAlwaysShow, editorCutLongValues, editorDisplayMode, editorDisplayPos, editorTextPrefix, editorTextSuffix, identifier, isArray, max, min, regex, symmetricalRef, textLanguageMode, tilesetUid, fieldDefinitionType, uid, useForSmartColor, serializationConstructorMarker) {
    Companion_getInstance_4();
    if (!(119750045 === (119750045 & seen0))) {
      throwMissingFieldException(seen0, 119750045, $serializer_getInstance_1().ck7_1);
    }
    var $this = createThis(this);
    $this.dk7_1 = type;
    if (0 === (seen0 & 2))
      $this.ek7_1 = null;
    else
      $this.ek7_1 = acceptFileTypes;
    $this.fk7_1 = allowedRefs;
    $this.gk7_1 = allowedRefTags;
    $this.hk7_1 = allowOutOfLevelRef;
    if (0 === (seen0 & 32))
      $this.ik7_1 = null;
    else
      $this.ik7_1 = arrayMaxLength;
    if (0 === (seen0 & 64))
      $this.jk7_1 = null;
    else
      $this.jk7_1 = arrayMinLength;
    $this.kk7_1 = autoChainRef;
    $this.lk7_1 = canBeNull;
    if (0 === (seen0 & 512))
      $this.mk7_1 = null;
    else
      $this.mk7_1 = defaultOverride;
    $this.nk7_1 = editorAlwaysShow;
    $this.ok7_1 = editorCutLongValues;
    $this.pk7_1 = editorDisplayMode;
    $this.qk7_1 = editorDisplayPos;
    if (0 === (seen0 & 16384))
      $this.rk7_1 = null;
    else
      $this.rk7_1 = editorTextPrefix;
    if (0 === (seen0 & 32768))
      $this.sk7_1 = null;
    else
      $this.sk7_1 = editorTextSuffix;
    $this.tk7_1 = identifier;
    $this.uk7_1 = isArray;
    if (0 === (seen0 & 262144))
      $this.vk7_1 = null;
    else
      $this.vk7_1 = max;
    if (0 === (seen0 & 524288))
      $this.wk7_1 = null;
    else
      $this.wk7_1 = min;
    if (0 === (seen0 & 1048576))
      $this.xk7_1 = null;
    else
      $this.xk7_1 = regex;
    $this.yk7_1 = symmetricalRef;
    if (0 === (seen0 & 4194304))
      $this.zk7_1 = null;
    else
      $this.zk7_1 = textLanguageMode;
    if (0 === (seen0 & 8388608))
      $this.ak8_1 = null;
    else
      $this.ak8_1 = tilesetUid;
    $this.bk8_1 = fieldDefinitionType;
    $this.ck8_1 = uid;
    $this.dk8_1 = useForSmartColor;
    return $this;
  }
}
class Companion_2 {
  constructor() {
    Companion_instance_3 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.jk8_1 = [null, new ArrayListSerializer($serializer_getInstance_1()), null, null, null, null, null, Companion_getInstance_12().ijz(), Companion_getInstance_13().ijz(), null, null, null, null, null, Companion_getInstance_14().ijz(), null, null, null, new ArrayListSerializer(StringSerializer_getInstance()), null, null, Companion_getInstance_15().ijz(), null, null, null];
  }
}
class $serializer_2 {
  constructor() {
    $serializer_instance_2 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.EntityDefinition', this, 25);
    tmp0_serialDesc.fjp('color', false);
    tmp0_serialDesc.fjp('fieldDefs', false);
    tmp0_serialDesc.fjp('fillOpacity', false);
    tmp0_serialDesc.fjp('height', false);
    tmp0_serialDesc.fjp('hollow', false);
    tmp0_serialDesc.fjp('identifier', false);
    tmp0_serialDesc.fjp('keepAspectRatio', false);
    tmp0_serialDesc.fjp('limitBehavior', false);
    tmp0_serialDesc.fjp('limitScope', false);
    tmp0_serialDesc.fjp('lineOpacity', false);
    tmp0_serialDesc.fjp('maxCount', false);
    tmp0_serialDesc.fjp('nineSliceBorders', false);
    tmp0_serialDesc.fjp('pivotX', false);
    tmp0_serialDesc.fjp('pivotY', false);
    tmp0_serialDesc.fjp('renderMode', false);
    tmp0_serialDesc.fjp('resizableX', false);
    tmp0_serialDesc.fjp('resizableY', false);
    tmp0_serialDesc.fjp('showName', false);
    tmp0_serialDesc.fjp('tags', false);
    tmp0_serialDesc.fjp('tileOpacity', false);
    tmp0_serialDesc.fjp('tileRect', true);
    tmp0_serialDesc.fjp('tileRenderMode', false);
    tmp0_serialDesc.fjp('tilesetId', true);
    tmp0_serialDesc.fjp('uid', false);
    tmp0_serialDesc.fjp('width', false);
    this.kk8_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.kk8_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = 0.0;
    var tmp7_local3 = 0;
    var tmp8_local4 = false;
    var tmp9_local5 = null;
    var tmp10_local6 = false;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = 0.0;
    var tmp14_local10 = 0;
    var tmp15_local11 = null;
    var tmp16_local12 = 0.0;
    var tmp17_local13 = 0.0;
    var tmp18_local14 = null;
    var tmp19_local15 = false;
    var tmp20_local16 = false;
    var tmp21_local17 = false;
    var tmp22_local18 = null;
    var tmp23_local19 = 0.0;
    var tmp24_local20 = null;
    var tmp25_local21 = null;
    var tmp26_local22 = null;
    var tmp27_local23 = 0;
    var tmp28_local24 = 0;
    var tmp29_input = decoder.pjj(tmp0_desc);
    var tmp30_cached = Companion_getInstance_5().jk8_1;
    if (tmp29_input.fjk()) {
      tmp4_local0 = tmp29_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp29_input.bjk(tmp0_desc, 1, tmp30_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp29_input.xjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp29_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp29_input.rjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp29_input.zjj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp29_input.rjj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp29_input.bjk(tmp0_desc, 7, tmp30_cached[7], tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp29_input.bjk(tmp0_desc, 8, tmp30_cached[8], tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp29_input.xjj(tmp0_desc, 9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp29_input.ujj(tmp0_desc, 10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp29_input.bjk(tmp0_desc, 11, IntArraySerializer_getInstance(), tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp29_input.xjj(tmp0_desc, 12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp29_input.xjj(tmp0_desc, 13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp29_input.bjk(tmp0_desc, 14, tmp30_cached[14], tmp18_local14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp29_input.rjj(tmp0_desc, 15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp29_input.rjj(tmp0_desc, 16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp29_input.rjj(tmp0_desc, 17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp29_input.bjk(tmp0_desc, 18, tmp30_cached[18], tmp22_local18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp29_input.xjj(tmp0_desc, 19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp29_input.djk(tmp0_desc, 20, $serializer_getInstance_4(), tmp24_local20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
      tmp25_local21 = tmp29_input.bjk(tmp0_desc, 21, tmp30_cached[21], tmp25_local21);
      tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
      tmp26_local22 = tmp29_input.djk(tmp0_desc, 22, IntSerializer_getInstance(), tmp26_local22);
      tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
      tmp27_local23 = tmp29_input.ujj(tmp0_desc, 23);
      tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
      tmp28_local24 = tmp29_input.ujj(tmp0_desc, 24);
      tmp3_bitMask0 = tmp3_bitMask0 | 16777216;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp29_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp29_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp29_input.bjk(tmp0_desc, 1, tmp30_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp29_input.xjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp29_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp29_input.rjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp29_input.zjj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp29_input.rjj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp29_input.bjk(tmp0_desc, 7, tmp30_cached[7], tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp29_input.bjk(tmp0_desc, 8, tmp30_cached[8], tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp29_input.xjj(tmp0_desc, 9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp29_input.ujj(tmp0_desc, 10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp29_input.bjk(tmp0_desc, 11, IntArraySerializer_getInstance(), tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp29_input.xjj(tmp0_desc, 12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp29_input.xjj(tmp0_desc, 13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp29_input.bjk(tmp0_desc, 14, tmp30_cached[14], tmp18_local14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp29_input.rjj(tmp0_desc, 15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp29_input.rjj(tmp0_desc, 16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp29_input.rjj(tmp0_desc, 17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp29_input.bjk(tmp0_desc, 18, tmp30_cached[18], tmp22_local18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp29_input.xjj(tmp0_desc, 19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp29_input.djk(tmp0_desc, 20, $serializer_getInstance_4(), tmp24_local20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          case 21:
            tmp25_local21 = tmp29_input.bjk(tmp0_desc, 21, tmp30_cached[21], tmp25_local21);
            tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
            break;
          case 22:
            tmp26_local22 = tmp29_input.djk(tmp0_desc, 22, IntSerializer_getInstance(), tmp26_local22);
            tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
            break;
          case 23:
            tmp27_local23 = tmp29_input.ujj(tmp0_desc, 23);
            tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
            break;
          case 24:
            tmp28_local24 = tmp29_input.ujj(tmp0_desc, 24);
            tmp3_bitMask0 = tmp3_bitMask0 | 16777216;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp29_input.qjj(tmp0_desc);
    return EntityDefinition.lk9(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, tmp25_local21, tmp26_local22, tmp27_local23, tmp28_local24, null);
  }
  ujf() {
    return this.kk8_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_5().jk8_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), tmp0_cached[1], DoubleSerializer_getInstance(), IntSerializer_getInstance(), BooleanSerializer_getInstance(), StringSerializer_getInstance(), BooleanSerializer_getInstance(), tmp0_cached[7], tmp0_cached[8], DoubleSerializer_getInstance(), IntSerializer_getInstance(), IntArraySerializer_getInstance(), DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), tmp0_cached[14], BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), tmp0_cached[18], DoubleSerializer_getInstance(), get_nullable($serializer_getInstance_4()), tmp0_cached[21], get_nullable(IntSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class EntityDefinition {
  n13(_set____db54di) {
    this.lk8_1.n13(_set____db54di);
  }
  o13() {
    return this.lk8_1.o13();
  }
  toString() {
    return 'EntityDefinition(color=' + this.mk8_1 + ', fieldDefs=' + toString_0(this.nk8_1) + ', fillOpacity=' + this.ok8_1 + ', height=' + this.pk8_1 + ', hollow=' + this.qk8_1 + ', identifier=' + this.rk8_1 + ', keepAspectRatio=' + this.sk8_1 + ', limitBehavior=' + this.tk8_1.toString() + ', limitScope=' + this.uk8_1.toString() + ', lineOpacity=' + this.vk8_1 + ', maxCount=' + this.wk8_1 + ', nineSliceBorders=' + toString_0(this.xk8_1) + ', pivotX=' + this.yk8_1 + ', pivotY=' + this.zk8_1 + ', renderMode=' + this.ak9_1.toString() + ', resizableX=' + this.bk9_1 + ', resizableY=' + this.ck9_1 + ', showName=' + this.dk9_1 + ', tags=' + toString_0(this.ek9_1) + ', tileOpacity=' + this.fk9_1 + ', tileRect=' + toString(this.gk9_1) + ', tileRenderMode=' + this.hk9_1.toString() + ', tilesetID=' + this.ik9_1 + ', uid=' + this.jk9_1 + ', width=' + this.kk9_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.mk8_1);
    result = imul(result, 31) + hashCode(this.nk8_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ok8_1) | 0;
    result = imul(result, 31) + this.pk8_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.qk8_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.rk8_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.sk8_1) | 0;
    result = imul(result, 31) + this.tk8_1.hashCode() | 0;
    result = imul(result, 31) + this.uk8_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.vk8_1) | 0;
    result = imul(result, 31) + this.wk8_1 | 0;
    result = imul(result, 31) + hashCode(this.xk8_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.yk8_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.zk8_1) | 0;
    result = imul(result, 31) + this.ak9_1.hashCode() | 0;
    result = imul(result, 31) + getBooleanHashCode(this.bk9_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.ck9_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.dk9_1) | 0;
    result = imul(result, 31) + hashCode(this.ek9_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.fk9_1) | 0;
    result = imul(result, 31) + (this.gk9_1 == null ? 0 : this.gk9_1.hashCode()) | 0;
    result = imul(result, 31) + this.hk9_1.hashCode() | 0;
    result = imul(result, 31) + (this.ik9_1 == null ? 0 : this.ik9_1) | 0;
    result = imul(result, 31) + this.jk9_1 | 0;
    result = imul(result, 31) + this.kk9_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof EntityDefinition ? other : THROW_CCE();
    if (!(this.mk8_1 === tmp0_other_with_cast.mk8_1))
      return false;
    if (!equals(this.nk8_1, tmp0_other_with_cast.nk8_1))
      return false;
    if (!equals(this.ok8_1, tmp0_other_with_cast.ok8_1))
      return false;
    if (!(this.pk8_1 === tmp0_other_with_cast.pk8_1))
      return false;
    if (!(this.qk8_1 === tmp0_other_with_cast.qk8_1))
      return false;
    if (!(this.rk8_1 === tmp0_other_with_cast.rk8_1))
      return false;
    if (!(this.sk8_1 === tmp0_other_with_cast.sk8_1))
      return false;
    if (!this.tk8_1.equals(tmp0_other_with_cast.tk8_1))
      return false;
    if (!this.uk8_1.equals(tmp0_other_with_cast.uk8_1))
      return false;
    if (!equals(this.vk8_1, tmp0_other_with_cast.vk8_1))
      return false;
    if (!(this.wk8_1 === tmp0_other_with_cast.wk8_1))
      return false;
    if (!equals(this.xk8_1, tmp0_other_with_cast.xk8_1))
      return false;
    if (!equals(this.yk8_1, tmp0_other_with_cast.yk8_1))
      return false;
    if (!equals(this.zk8_1, tmp0_other_with_cast.zk8_1))
      return false;
    if (!this.ak9_1.equals(tmp0_other_with_cast.ak9_1))
      return false;
    if (!(this.bk9_1 === tmp0_other_with_cast.bk9_1))
      return false;
    if (!(this.ck9_1 === tmp0_other_with_cast.ck9_1))
      return false;
    if (!(this.dk9_1 === tmp0_other_with_cast.dk9_1))
      return false;
    if (!equals(this.ek9_1, tmp0_other_with_cast.ek9_1))
      return false;
    if (!equals(this.fk9_1, tmp0_other_with_cast.fk9_1))
      return false;
    if (!equals(this.gk9_1, tmp0_other_with_cast.gk9_1))
      return false;
    if (!this.hk9_1.equals(tmp0_other_with_cast.hk9_1))
      return false;
    if (!(this.ik9_1 == tmp0_other_with_cast.ik9_1))
      return false;
    if (!(this.jk9_1 === tmp0_other_with_cast.jk9_1))
      return false;
    if (!(this.kk9_1 === tmp0_other_with_cast.kk9_1))
      return false;
    return true;
  }
  static lk9(seen0, color, fieldDefs, fillOpacity, height, hollow, identifier, keepAspectRatio, limitBehavior, limitScope, lineOpacity, maxCount, nineSliceBorders, pivotX, pivotY, renderMode, resizableX, resizableY, showName, tags, tileOpacity, tileRect, tileRenderMode, tilesetID, uid, width, serializationConstructorMarker) {
    Companion_getInstance_5();
    if (!(28311551 === (28311551 & seen0))) {
      throwMissingFieldException(seen0, 28311551, $serializer_getInstance_2().kk8_1);
    }
    var $this = createThis(this);
    $this.mk8_1 = color;
    $this.nk8_1 = fieldDefs;
    $this.ok8_1 = fillOpacity;
    $this.pk8_1 = height;
    $this.qk8_1 = hollow;
    $this.rk8_1 = identifier;
    $this.sk8_1 = keepAspectRatio;
    $this.tk8_1 = limitBehavior;
    $this.uk8_1 = limitScope;
    $this.vk8_1 = lineOpacity;
    $this.wk8_1 = maxCount;
    $this.xk8_1 = nineSliceBorders;
    $this.yk8_1 = pivotX;
    $this.zk8_1 = pivotY;
    $this.ak9_1 = renderMode;
    $this.bk9_1 = resizableX;
    $this.ck9_1 = resizableY;
    $this.dk9_1 = showName;
    $this.ek9_1 = tags;
    $this.fk9_1 = tileOpacity;
    if (0 === (seen0 & 1048576))
      $this.gk9_1 = null;
    else
      $this.gk9_1 = tileRect;
    $this.hk9_1 = tileRenderMode;
    if (0 === (seen0 & 4194304))
      $this.ik9_1 = null;
    else
      $this.ik9_1 = tilesetID;
    $this.jk9_1 = uid;
    $this.kk9_1 = width;
    $this.lk8_1 = new Mixin();
    return $this;
  }
}
class Companion_3 {
  constructor() {
    Companion_instance_4 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.mk9_1 = [new ArrayListSerializer($serializer_getInstance_2()), new ArrayListSerializer($serializer_getInstance_5()), new ArrayListSerializer($serializer_getInstance_5()), new ArrayListSerializer($serializer_getInstance_6()), new ArrayListSerializer($serializer_getInstance_1()), new ArrayListSerializer($serializer_getInstance_7())];
  }
}
class $serializer_3 {
  constructor() {
    $serializer_instance_3 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.Definitions', this, 6);
    tmp0_serialDesc.fjp('entities', false);
    tmp0_serialDesc.fjp('enums', false);
    tmp0_serialDesc.fjp('externalEnums', false);
    tmp0_serialDesc.fjp('layers', false);
    tmp0_serialDesc.fjp('levelFields', false);
    tmp0_serialDesc.fjp('tilesets', false);
    this.nk9_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.nk9_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_input = decoder.pjj(tmp0_desc);
    var tmp11_cached = Companion_getInstance_6().mk9_1;
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.bjk(tmp0_desc, 0, tmp11_cached[0], tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.bjk(tmp0_desc, 1, tmp11_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.bjk(tmp0_desc, 2, tmp11_cached[2], tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.bjk(tmp0_desc, 3, tmp11_cached[3], tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.bjk(tmp0_desc, 4, tmp11_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp10_input.bjk(tmp0_desc, 5, tmp11_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.bjk(tmp0_desc, 0, tmp11_cached[0], tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.bjk(tmp0_desc, 1, tmp11_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.bjk(tmp0_desc, 2, tmp11_cached[2], tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.bjk(tmp0_desc, 3, tmp11_cached[3], tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.bjk(tmp0_desc, 4, tmp11_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp10_input.bjk(tmp0_desc, 5, tmp11_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return Definitions.vk9(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.nk9_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_6().mk9_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [tmp0_cached[0], tmp0_cached[1], tmp0_cached[2], tmp0_cached[3], tmp0_cached[4], tmp0_cached[5]];
  }
}
class Definitions {
  n13(_set____db54di) {
    this.ok9_1.n13(_set____db54di);
  }
  o13() {
    return this.ok9_1.o13();
  }
  toString() {
    return 'Definitions(entities=' + toString_0(this.pk9_1) + ', enums=' + toString_0(this.qk9_1) + ', externalEnums=' + toString_0(this.rk9_1) + ', layers=' + toString_0(this.sk9_1) + ', levelFields=' + toString_0(this.tk9_1) + ', tilesets=' + toString_0(this.uk9_1) + ')';
  }
  hashCode() {
    var result = hashCode(this.pk9_1);
    result = imul(result, 31) + hashCode(this.qk9_1) | 0;
    result = imul(result, 31) + hashCode(this.rk9_1) | 0;
    result = imul(result, 31) + hashCode(this.sk9_1) | 0;
    result = imul(result, 31) + hashCode(this.tk9_1) | 0;
    result = imul(result, 31) + hashCode(this.uk9_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Definitions))
      return false;
    var tmp0_other_with_cast = other instanceof Definitions ? other : THROW_CCE();
    if (!equals(this.pk9_1, tmp0_other_with_cast.pk9_1))
      return false;
    if (!equals(this.qk9_1, tmp0_other_with_cast.qk9_1))
      return false;
    if (!equals(this.rk9_1, tmp0_other_with_cast.rk9_1))
      return false;
    if (!equals(this.sk9_1, tmp0_other_with_cast.sk9_1))
      return false;
    if (!equals(this.tk9_1, tmp0_other_with_cast.tk9_1))
      return false;
    if (!equals(this.uk9_1, tmp0_other_with_cast.uk9_1))
      return false;
    return true;
  }
  static vk9(seen0, entities, enums, externalEnums, layers, levelFields, tilesets, serializationConstructorMarker) {
    Companion_getInstance_6();
    if (!(63 === (63 & seen0))) {
      throwMissingFieldException(seen0, 63, $serializer_getInstance_3().nk9_1);
    }
    var $this = createThis(this);
    $this.pk9_1 = entities;
    $this.qk9_1 = enums;
    $this.rk9_1 = externalEnums;
    $this.sk9_1 = layers;
    $this.tk9_1 = levelFields;
    $this.uk9_1 = tilesets;
    $this.ok9_1 = new Mixin();
    return $this;
  }
}
class Companion_4 {}
class $serializer_4 {
  constructor() {
    $serializer_instance_4 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.TilesetRectangle', this, 5);
    tmp0_serialDesc.fjp('h', false);
    tmp0_serialDesc.fjp('tilesetUid', false);
    tmp0_serialDesc.fjp('w', false);
    tmp0_serialDesc.fjp('x', false);
    tmp0_serialDesc.fjp('y', false);
    this.wk9_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.wk9_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = 0;
    var tmp8_local4 = 0;
    var tmp9_input = decoder.pjj(tmp0_desc);
    if (tmp9_input.fjk()) {
      tmp4_local0 = tmp9_input.ujj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp9_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp9_input.ujj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp9_input.ujj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp9_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp9_input.ujj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp9_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp9_input.ujj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp9_input.ujj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp9_input.qjj(tmp0_desc);
    return TilesetRectangle.cka(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.wk9_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class TilesetRectangle {
  toString() {
    return 'TilesetRectangle(h=' + this.xk9_1 + ', tilesetUid=' + this.yk9_1 + ', w=' + this.zk9_1 + ', x=' + this.aka_1 + ', y=' + this.bka_1 + ')';
  }
  hashCode() {
    var result = this.xk9_1;
    result = imul(result, 31) + this.yk9_1 | 0;
    result = imul(result, 31) + this.zk9_1 | 0;
    result = imul(result, 31) + this.aka_1 | 0;
    result = imul(result, 31) + this.bka_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof TilesetRectangle))
      return false;
    var tmp0_other_with_cast = other instanceof TilesetRectangle ? other : THROW_CCE();
    if (!(this.xk9_1 === tmp0_other_with_cast.xk9_1))
      return false;
    if (!(this.yk9_1 === tmp0_other_with_cast.yk9_1))
      return false;
    if (!(this.zk9_1 === tmp0_other_with_cast.zk9_1))
      return false;
    if (!(this.aka_1 === tmp0_other_with_cast.aka_1))
      return false;
    if (!(this.bka_1 === tmp0_other_with_cast.bka_1))
      return false;
    return true;
  }
  static cka(seen0, h, tilesetUid, w, x, y, serializationConstructorMarker) {
    if (!(31 === (31 & seen0))) {
      throwMissingFieldException(seen0, 31, $serializer_getInstance_4().wk9_1);
    }
    var $this = createThis(this);
    $this.xk9_1 = h;
    $this.yk9_1 = tilesetUid;
    $this.zk9_1 = w;
    $this.aka_1 = x;
    $this.bka_1 = y;
    return $this;
  }
}
class Companion_5 {
  constructor() {
    Companion_instance_6 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.xk6_1 = lazy(tmp_0, AllowedRefs$Companion$_anonymous__v2lmwp);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.AllowedRefs', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Any':
        tmp = AllowedRefs_Any_getInstance();
        break;
      case 'OnlySame':
        tmp = AllowedRefs_OnlySame_getInstance();
        break;
      case 'OnlyTags':
        tmp = AllowedRefs_OnlyTags_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('AllowedRefs could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class AllowedRefs extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.fka_1 = value;
  }
}
class Companion_6 {
  constructor() {
    Companion_instance_7 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.yk6_1 = lazy(tmp_0, EditorDisplayMode$Companion$_anonymous__77qsc7);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.EditorDisplayMode', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'ArrayCountNoLabel':
        tmp = EditorDisplayMode_ArrayCountNoLabel_getInstance();
        break;
      case 'ArrayCountWithLabel':
        tmp = EditorDisplayMode_ArrayCountWithLabel_getInstance();
        break;
      case 'EntityTile':
        tmp = EditorDisplayMode_EntityTile_getInstance();
        break;
      case 'Hidden':
        tmp = EditorDisplayMode_Hidden_getInstance();
        break;
      case 'NameAndValue':
        tmp = EditorDisplayMode_NameAndValue_getInstance();
        break;
      case 'PointPath':
        tmp = EditorDisplayMode_PointPath_getInstance();
        break;
      case 'PointPathLoop':
        tmp = EditorDisplayMode_PointPathLoop_getInstance();
        break;
      case 'PointStar':
        tmp = EditorDisplayMode_PointStar_getInstance();
        break;
      case 'Points':
        tmp = EditorDisplayMode_Points_getInstance();
        break;
      case 'RadiusGrid':
        tmp = EditorDisplayMode_RadiusGrid_getInstance();
        break;
      case 'RadiusPx':
        tmp = EditorDisplayMode_RadiusPx_getInstance();
        break;
      case 'RefLinkBetweenCenters':
        tmp = EditorDisplayMode_RefLinkBetweenCenters_getInstance();
        break;
      case 'RefLinkBetweenPivots':
        tmp = EditorDisplayMode_RefLinkBetweenPivots_getInstance();
        break;
      case 'ValueOnly':
        tmp = EditorDisplayMode_ValueOnly_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('EditorDisplayMode could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_0(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class EditorDisplayMode extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.ika_1 = value;
  }
}
class Companion_7 {
  constructor() {
    Companion_instance_8 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.zk6_1 = lazy(tmp_0, EditorDisplayPos$Companion$_anonymous__f71kr4);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.EditorDisplayPos', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Above':
        tmp = EditorDisplayPos_Above_getInstance();
        break;
      case 'Beneath':
        tmp = EditorDisplayPos_Beneath_getInstance();
        break;
      case 'Center':
        tmp = EditorDisplayPos_Center_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('EditorDisplayPos could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_1(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class EditorDisplayPos extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.lka_1 = value;
  }
}
class Companion_8 {
  constructor() {
    Companion_instance_9 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.ak7_1 = lazy(tmp_0, TextLanguageMode$Companion$_anonymous__u336u1);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.TextLanguageMode', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'LangC':
        tmp = TextLanguageMode_LangC_getInstance();
        break;
      case 'LangHaxe':
        tmp = TextLanguageMode_LangHaxe_getInstance();
        break;
      case 'LangJS':
        tmp = TextLanguageMode_LangJS_getInstance();
        break;
      case 'LangJson':
        tmp = TextLanguageMode_LangJSON_getInstance();
        break;
      case 'LangLog':
        tmp = TextLanguageMode_LangLog_getInstance();
        break;
      case 'LangLua':
        tmp = TextLanguageMode_LangLua_getInstance();
        break;
      case 'LangMarkdown':
        tmp = TextLanguageMode_LangMarkdown_getInstance();
        break;
      case 'LangPython':
        tmp = TextLanguageMode_LangPython_getInstance();
        break;
      case 'LangRuby':
        tmp = TextLanguageMode_LangRuby_getInstance();
        break;
      case 'LangXml':
        tmp = TextLanguageMode_LangXML_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('TextLanguageMode could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_2(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class TextLanguageMode extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.oka_1 = value;
  }
}
class Companion_9 {
  constructor() {
    Companion_instance_10 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.fk8_1 = lazy(tmp_0, LimitBehavior$Companion$_anonymous__hsp10i);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.LimitBehavior', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'DiscardOldOnes':
        tmp = LimitBehavior_DiscardOldOnes_getInstance();
        break;
      case 'MoveLastOne':
        tmp = LimitBehavior_MoveLastOne_getInstance();
        break;
      case 'PreventAdding':
        tmp = LimitBehavior_PreventAdding_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('LimitBehavior could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_3(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class LimitBehavior extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.rka_1 = value;
  }
}
class Companion_10 {
  constructor() {
    Companion_instance_11 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.gk8_1 = lazy(tmp_0, LimitScope$Companion$_anonymous__ndoxsa);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.LimitScope', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'PerLayer':
        tmp = LimitScope_PerLayer_getInstance();
        break;
      case 'PerLevel':
        tmp = LimitScope_PerLevel_getInstance();
        break;
      case 'PerWorld':
        tmp = LimitScope_PerWorld_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('LimitScope could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_4(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class LimitScope extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.uka_1 = value;
  }
}
class Companion_11 {
  constructor() {
    Companion_instance_12 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.hk8_1 = lazy(tmp_0, RenderMode$Companion$_anonymous__ho71fu);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.RenderMode', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Cross':
        tmp = RenderMode_Cross_getInstance();
        break;
      case 'Ellipse':
        tmp = RenderMode_Ellipse_getInstance();
        break;
      case 'Rectangle':
        tmp = RenderMode_Rectangle_getInstance();
        break;
      case 'Tile':
        tmp = RenderMode_Tile_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('RenderMode could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_5(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class RenderMode extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.xka_1 = value;
  }
}
class Companion_12 {
  constructor() {
    Companion_instance_13 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.ik8_1 = lazy(tmp_0, TileRenderMode$Companion$_anonymous__shhaw);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.TileRenderMode', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Cover':
        tmp = TileRenderMode_Cover_getInstance();
        break;
      case 'FitInside':
        tmp = TileRenderMode_FitInside_getInstance();
        break;
      case 'FullSizeCropped':
        tmp = TileRenderMode_FullSizeCropped_getInstance();
        break;
      case 'FullSizeUncropped':
        tmp = TileRenderMode_FullSizeUncropped_getInstance();
        break;
      case 'NineSlice':
        tmp = TileRenderMode_NineSlice_getInstance();
        break;
      case 'Repeat':
        tmp = TileRenderMode_Repeat_getInstance();
        break;
      case 'Stretch':
        tmp = TileRenderMode_Stretch_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('TileRenderMode could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_6(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class TileRenderMode extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.akb_1 = value;
  }
}
class Companion_13 {
  constructor() {
    Companion_instance_14 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.bkb_1 = [null, null, null, null, new ArrayListSerializer(StringSerializer_getInstance()), null, new ArrayListSerializer($serializer_getInstance_8())];
  }
}
class $serializer_5 {
  constructor() {
    $serializer_instance_5 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.EnumDefinition', this, 7);
    tmp0_serialDesc.fjp('externalFileChecksum', true);
    tmp0_serialDesc.fjp('externalRelPath', true);
    tmp0_serialDesc.fjp('iconTilesetUid', true);
    tmp0_serialDesc.fjp('identifier', false);
    tmp0_serialDesc.fjp('tags', false);
    tmp0_serialDesc.fjp('uid', false);
    tmp0_serialDesc.fjp('values', false);
    this.ckb_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ckb_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = 0;
    var tmp10_local6 = null;
    var tmp11_input = decoder.pjj(tmp0_desc);
    var tmp12_cached = Companion_getInstance_16().bkb_1;
    if (tmp11_input.fjk()) {
      tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.djk(tmp0_desc, 2, IntSerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.zjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.bjk(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.bjk(tmp0_desc, 6, tmp12_cached[6], tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.djk(tmp0_desc, 0, StringSerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.djk(tmp0_desc, 2, IntSerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.zjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.bjk(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.bjk(tmp0_desc, 6, tmp12_cached[6], tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp11_input.qjj(tmp0_desc);
    return EnumDefinition.kkb(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, null);
  }
  ujf() {
    return this.ckb_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_16().bkb_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), get_nullable(IntSerializer_getInstance()), StringSerializer_getInstance(), tmp0_cached[4], IntSerializer_getInstance(), tmp0_cached[6]];
  }
}
class EnumDefinition {
  toString() {
    return 'EnumDefinition(externalFileChecksum=' + this.dkb_1 + ', externalRelPath=' + this.ekb_1 + ', iconTilesetUid=' + this.fkb_1 + ', identifier=' + this.gkb_1 + ', tags=' + toString_0(this.hkb_1) + ', uid=' + this.ikb_1 + ', values=' + toString_0(this.jkb_1) + ')';
  }
  hashCode() {
    var result = this.dkb_1 == null ? 0 : getStringHashCode(this.dkb_1);
    result = imul(result, 31) + (this.ekb_1 == null ? 0 : getStringHashCode(this.ekb_1)) | 0;
    result = imul(result, 31) + (this.fkb_1 == null ? 0 : this.fkb_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.gkb_1) | 0;
    result = imul(result, 31) + hashCode(this.hkb_1) | 0;
    result = imul(result, 31) + this.ikb_1 | 0;
    result = imul(result, 31) + hashCode(this.jkb_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof EnumDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof EnumDefinition ? other : THROW_CCE();
    if (!(this.dkb_1 == tmp0_other_with_cast.dkb_1))
      return false;
    if (!(this.ekb_1 == tmp0_other_with_cast.ekb_1))
      return false;
    if (!(this.fkb_1 == tmp0_other_with_cast.fkb_1))
      return false;
    if (!(this.gkb_1 === tmp0_other_with_cast.gkb_1))
      return false;
    if (!equals(this.hkb_1, tmp0_other_with_cast.hkb_1))
      return false;
    if (!(this.ikb_1 === tmp0_other_with_cast.ikb_1))
      return false;
    if (!equals(this.jkb_1, tmp0_other_with_cast.jkb_1))
      return false;
    return true;
  }
  static kkb(seen0, externalFileChecksum, externalRelPath, iconTilesetUid, identifier, tags, uid, values, serializationConstructorMarker) {
    Companion_getInstance_16();
    if (!(120 === (120 & seen0))) {
      throwMissingFieldException(seen0, 120, $serializer_getInstance_5().ckb_1);
    }
    var $this = createThis(this);
    if (0 === (seen0 & 1))
      $this.dkb_1 = null;
    else
      $this.dkb_1 = externalFileChecksum;
    if (0 === (seen0 & 2))
      $this.ekb_1 = null;
    else
      $this.ekb_1 = externalRelPath;
    if (0 === (seen0 & 4))
      $this.fkb_1 = null;
    else
      $this.fkb_1 = iconTilesetUid;
    $this.gkb_1 = identifier;
    $this.hkb_1 = tags;
    $this.ikb_1 = uid;
    $this.jkb_1 = values;
    return $this;
  }
}
class Companion_14 {
  constructor() {
    Companion_instance_15 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.mkb_1 = [null, new ArrayListSerializer($serializer_getInstance_9()), null, null, new ArrayListSerializer(StringSerializer_getInstance()), null, null, null, null, null, null, null, new ArrayListSerializer($serializer_getInstance_10()), null, null, null, null, null, new ArrayListSerializer(StringSerializer_getInstance()), null, null, null, Companion_getInstance_22().ijz(), null];
  }
}
class $serializer_6 {
  constructor() {
    $serializer_instance_6 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.LayerDefinition', this, 24);
    tmp0_serialDesc.fjp('__type', false);
    tmp0_serialDesc.fjp('autoRuleGroups', false);
    tmp0_serialDesc.fjp('autoSourceLayerDefUid', true);
    tmp0_serialDesc.fjp('displayOpacity', false);
    tmp0_serialDesc.fjp('excludedTags', false);
    tmp0_serialDesc.fjp('gridSize', false);
    tmp0_serialDesc.fjp('guideGridHei', false);
    tmp0_serialDesc.fjp('guideGridWid', false);
    tmp0_serialDesc.fjp('hideFieldsWhenInactive', false);
    tmp0_serialDesc.fjp('hideInList', false);
    tmp0_serialDesc.fjp('identifier', false);
    tmp0_serialDesc.fjp('inactiveOpacity', false);
    tmp0_serialDesc.fjp('intGridValues', false);
    tmp0_serialDesc.fjp('parallaxFactorX', false);
    tmp0_serialDesc.fjp('parallaxFactorY', false);
    tmp0_serialDesc.fjp('parallaxScaling', false);
    tmp0_serialDesc.fjp('pxOffsetX', false);
    tmp0_serialDesc.fjp('pxOffsetY', false);
    tmp0_serialDesc.fjp('requiredTags', false);
    tmp0_serialDesc.fjp('tilePivotX', false);
    tmp0_serialDesc.fjp('tilePivotY', false);
    tmp0_serialDesc.fjp('tilesetDefUid', true);
    tmp0_serialDesc.fjp('type', false);
    tmp0_serialDesc.fjp('uid', false);
    this.nkb_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.nkb_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = 0.0;
    var tmp8_local4 = null;
    var tmp9_local5 = 0;
    var tmp10_local6 = 0;
    var tmp11_local7 = 0;
    var tmp12_local8 = false;
    var tmp13_local9 = false;
    var tmp14_local10 = null;
    var tmp15_local11 = 0.0;
    var tmp16_local12 = null;
    var tmp17_local13 = 0.0;
    var tmp18_local14 = 0.0;
    var tmp19_local15 = false;
    var tmp20_local16 = 0;
    var tmp21_local17 = 0;
    var tmp22_local18 = null;
    var tmp23_local19 = 0.0;
    var tmp24_local20 = 0.0;
    var tmp25_local21 = null;
    var tmp26_local22 = null;
    var tmp27_local23 = 0;
    var tmp28_input = decoder.pjj(tmp0_desc);
    var tmp29_cached = Companion_getInstance_17().mkb_1;
    if (tmp28_input.fjk()) {
      tmp4_local0 = tmp28_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp28_input.bjk(tmp0_desc, 1, tmp29_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp28_input.djk(tmp0_desc, 2, IntSerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp28_input.xjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp28_input.bjk(tmp0_desc, 4, tmp29_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp28_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp28_input.ujj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp28_input.ujj(tmp0_desc, 7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp28_input.rjj(tmp0_desc, 8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp28_input.rjj(tmp0_desc, 9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp28_input.zjj(tmp0_desc, 10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp28_input.xjj(tmp0_desc, 11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp28_input.bjk(tmp0_desc, 12, tmp29_cached[12], tmp16_local12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp28_input.xjj(tmp0_desc, 13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp28_input.xjj(tmp0_desc, 14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp28_input.rjj(tmp0_desc, 15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp28_input.ujj(tmp0_desc, 16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp28_input.ujj(tmp0_desc, 17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp28_input.bjk(tmp0_desc, 18, tmp29_cached[18], tmp22_local18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp28_input.xjj(tmp0_desc, 19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp28_input.xjj(tmp0_desc, 20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
      tmp25_local21 = tmp28_input.djk(tmp0_desc, 21, IntSerializer_getInstance(), tmp25_local21);
      tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
      tmp26_local22 = tmp28_input.bjk(tmp0_desc, 22, tmp29_cached[22], tmp26_local22);
      tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
      tmp27_local23 = tmp28_input.ujj(tmp0_desc, 23);
      tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp28_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp28_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp28_input.bjk(tmp0_desc, 1, tmp29_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp28_input.djk(tmp0_desc, 2, IntSerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp28_input.xjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp28_input.bjk(tmp0_desc, 4, tmp29_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp28_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp28_input.ujj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp28_input.ujj(tmp0_desc, 7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp28_input.rjj(tmp0_desc, 8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp28_input.rjj(tmp0_desc, 9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp28_input.zjj(tmp0_desc, 10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp28_input.xjj(tmp0_desc, 11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp28_input.bjk(tmp0_desc, 12, tmp29_cached[12], tmp16_local12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp28_input.xjj(tmp0_desc, 13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp28_input.xjj(tmp0_desc, 14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp28_input.rjj(tmp0_desc, 15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp28_input.ujj(tmp0_desc, 16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp28_input.ujj(tmp0_desc, 17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp28_input.bjk(tmp0_desc, 18, tmp29_cached[18], tmp22_local18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp28_input.xjj(tmp0_desc, 19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp28_input.xjj(tmp0_desc, 20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          case 21:
            tmp25_local21 = tmp28_input.djk(tmp0_desc, 21, IntSerializer_getInstance(), tmp25_local21);
            tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
            break;
          case 22:
            tmp26_local22 = tmp28_input.bjk(tmp0_desc, 22, tmp29_cached[22], tmp26_local22);
            tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
            break;
          case 23:
            tmp27_local23 = tmp28_input.ujj(tmp0_desc, 23);
            tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp28_input.qjj(tmp0_desc);
    return LayerDefinition.mkc(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, tmp25_local21, tmp26_local22, tmp27_local23, null);
  }
  ujf() {
    return this.nkb_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_17().mkb_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), tmp0_cached[1], get_nullable(IntSerializer_getInstance()), DoubleSerializer_getInstance(), tmp0_cached[4], IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), StringSerializer_getInstance(), DoubleSerializer_getInstance(), tmp0_cached[12], DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), BooleanSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), tmp0_cached[18], DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), get_nullable(IntSerializer_getInstance()), tmp0_cached[22], IntSerializer_getInstance()];
  }
}
class LayerDefinition {
  toString() {
    return 'LayerDefinition(type=' + this.okb_1 + ', autoRuleGroups=' + toString_0(this.pkb_1) + ', autoSourceLayerDefUid=' + this.qkb_1 + ', displayOpacity=' + this.rkb_1 + ', excludedTags=' + toString_0(this.skb_1) + ', gridSize=' + this.tkb_1 + ', guideGridHei=' + this.ukb_1 + ', guideGridWid=' + this.vkb_1 + ', hideFieldsWhenInactive=' + this.wkb_1 + ', hideInList=' + this.xkb_1 + ', identifier=' + this.ykb_1 + ', inactiveOpacity=' + this.zkb_1 + ', intGridValues=' + toString_0(this.akc_1) + ', parallaxFactorX=' + this.bkc_1 + ', parallaxFactorY=' + this.ckc_1 + ', parallaxScaling=' + this.dkc_1 + ', pxOffsetX=' + this.ekc_1 + ', pxOffsetY=' + this.fkc_1 + ', requiredTags=' + toString_0(this.gkc_1) + ', tilePivotX=' + this.hkc_1 + ', tilePivotY=' + this.ikc_1 + ', tilesetDefUid=' + this.jkc_1 + ', layerDefinitionType=' + this.kkc_1.toString() + ', uid=' + this.lkc_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.okb_1);
    result = imul(result, 31) + hashCode(this.pkb_1) | 0;
    result = imul(result, 31) + (this.qkb_1 == null ? 0 : this.qkb_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.rkb_1) | 0;
    result = imul(result, 31) + hashCode(this.skb_1) | 0;
    result = imul(result, 31) + this.tkb_1 | 0;
    result = imul(result, 31) + this.ukb_1 | 0;
    result = imul(result, 31) + this.vkb_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.wkb_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.xkb_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.ykb_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.zkb_1) | 0;
    result = imul(result, 31) + hashCode(this.akc_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.bkc_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ckc_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.dkc_1) | 0;
    result = imul(result, 31) + this.ekc_1 | 0;
    result = imul(result, 31) + this.fkc_1 | 0;
    result = imul(result, 31) + hashCode(this.gkc_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.hkc_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ikc_1) | 0;
    result = imul(result, 31) + (this.jkc_1 == null ? 0 : this.jkc_1) | 0;
    result = imul(result, 31) + this.kkc_1.hashCode() | 0;
    result = imul(result, 31) + this.lkc_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof LayerDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof LayerDefinition ? other : THROW_CCE();
    if (!(this.okb_1 === tmp0_other_with_cast.okb_1))
      return false;
    if (!equals(this.pkb_1, tmp0_other_with_cast.pkb_1))
      return false;
    if (!(this.qkb_1 == tmp0_other_with_cast.qkb_1))
      return false;
    if (!equals(this.rkb_1, tmp0_other_with_cast.rkb_1))
      return false;
    if (!equals(this.skb_1, tmp0_other_with_cast.skb_1))
      return false;
    if (!(this.tkb_1 === tmp0_other_with_cast.tkb_1))
      return false;
    if (!(this.ukb_1 === tmp0_other_with_cast.ukb_1))
      return false;
    if (!(this.vkb_1 === tmp0_other_with_cast.vkb_1))
      return false;
    if (!(this.wkb_1 === tmp0_other_with_cast.wkb_1))
      return false;
    if (!(this.xkb_1 === tmp0_other_with_cast.xkb_1))
      return false;
    if (!(this.ykb_1 === tmp0_other_with_cast.ykb_1))
      return false;
    if (!equals(this.zkb_1, tmp0_other_with_cast.zkb_1))
      return false;
    if (!equals(this.akc_1, tmp0_other_with_cast.akc_1))
      return false;
    if (!equals(this.bkc_1, tmp0_other_with_cast.bkc_1))
      return false;
    if (!equals(this.ckc_1, tmp0_other_with_cast.ckc_1))
      return false;
    if (!(this.dkc_1 === tmp0_other_with_cast.dkc_1))
      return false;
    if (!(this.ekc_1 === tmp0_other_with_cast.ekc_1))
      return false;
    if (!(this.fkc_1 === tmp0_other_with_cast.fkc_1))
      return false;
    if (!equals(this.gkc_1, tmp0_other_with_cast.gkc_1))
      return false;
    if (!equals(this.hkc_1, tmp0_other_with_cast.hkc_1))
      return false;
    if (!equals(this.ikc_1, tmp0_other_with_cast.ikc_1))
      return false;
    if (!(this.jkc_1 == tmp0_other_with_cast.jkc_1))
      return false;
    if (!this.kkc_1.equals(tmp0_other_with_cast.kkc_1))
      return false;
    if (!(this.lkc_1 === tmp0_other_with_cast.lkc_1))
      return false;
    return true;
  }
  static mkc(seen0, type, autoRuleGroups, autoSourceLayerDefUid, displayOpacity, excludedTags, gridSize, guideGridHei, guideGridWid, hideFieldsWhenInactive, hideInList, identifier, inactiveOpacity, intGridValues, parallaxFactorX, parallaxFactorY, parallaxScaling, pxOffsetX, pxOffsetY, requiredTags, tilePivotX, tilePivotY, tilesetDefUid, layerDefinitionType, uid, serializationConstructorMarker) {
    Companion_getInstance_17();
    if (!(14680059 === (14680059 & seen0))) {
      throwMissingFieldException(seen0, 14680059, $serializer_getInstance_6().nkb_1);
    }
    var $this = createThis(this);
    $this.okb_1 = type;
    $this.pkb_1 = autoRuleGroups;
    if (0 === (seen0 & 4))
      $this.qkb_1 = null;
    else
      $this.qkb_1 = autoSourceLayerDefUid;
    $this.rkb_1 = displayOpacity;
    $this.skb_1 = excludedTags;
    $this.tkb_1 = gridSize;
    $this.ukb_1 = guideGridHei;
    $this.vkb_1 = guideGridWid;
    $this.wkb_1 = hideFieldsWhenInactive;
    $this.xkb_1 = hideInList;
    $this.ykb_1 = identifier;
    $this.zkb_1 = inactiveOpacity;
    $this.akc_1 = intGridValues;
    $this.bkc_1 = parallaxFactorX;
    $this.ckc_1 = parallaxFactorY;
    $this.dkc_1 = parallaxScaling;
    $this.ekc_1 = pxOffsetX;
    $this.fkc_1 = pxOffsetY;
    $this.gkc_1 = requiredTags;
    $this.hkc_1 = tilePivotX;
    $this.ikc_1 = tilePivotY;
    if (0 === (seen0 & 2097152))
      $this.jkc_1 = null;
    else
      $this.jkc_1 = tilesetDefUid;
    $this.kkc_1 = layerDefinitionType;
    $this.lkc_1 = uid;
    return $this;
  }
}
class Companion_15 {
  constructor() {
    Companion_instance_16 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.okc_1 = [null, null, null, new ArrayListSerializer($serializer_getInstance_11()), Companion_getInstance_24().ijz(), new ArrayListSerializer($serializer_getInstance_12()), null, null, null, null, null, null, null, new ArrayListSerializer(StringSerializer_getInstance()), null, null, null];
  }
}
class $serializer_7 {
  constructor() {
    $serializer_instance_7 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.TilesetDefinition', this, 17);
    tmp0_serialDesc.fjp('__cHei', false);
    tmp0_serialDesc.fjp('__cWid', false);
    tmp0_serialDesc.fjp('cachedPixelData', true);
    tmp0_serialDesc.fjp('customData', false);
    tmp0_serialDesc.fjp('embedAtlas', true);
    tmp0_serialDesc.fjp('enumTags', false);
    tmp0_serialDesc.fjp('identifier', false);
    tmp0_serialDesc.fjp('padding', false);
    tmp0_serialDesc.fjp('pxHei', false);
    tmp0_serialDesc.fjp('pxWid', false);
    tmp0_serialDesc.fjp('relPath', true);
    tmp0_serialDesc.fjp('savedSelections', false);
    tmp0_serialDesc.fjp('spacing', false);
    tmp0_serialDesc.fjp('tags', false);
    tmp0_serialDesc.fjp('tagsSourceEnumUid', true);
    tmp0_serialDesc.fjp('tileGridSize', false);
    tmp0_serialDesc.fjp('uid', false);
    this.pkc_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.pkc_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = 0;
    var tmp12_local8 = 0;
    var tmp13_local9 = 0;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp16_local12 = 0;
    var tmp17_local13 = null;
    var tmp18_local14 = null;
    var tmp19_local15 = 0;
    var tmp20_local16 = 0;
    var tmp21_input = decoder.pjj(tmp0_desc);
    var tmp22_cached = Companion_getInstance_18().okc_1;
    if (tmp21_input.fjk()) {
      tmp4_local0 = tmp21_input.ujj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp21_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp21_input.djk(tmp0_desc, 2, JsonObjectSerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp21_input.bjk(tmp0_desc, 3, tmp22_cached[3], tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp21_input.djk(tmp0_desc, 4, tmp22_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp21_input.bjk(tmp0_desc, 5, tmp22_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp21_input.zjj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp21_input.ujj(tmp0_desc, 7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp21_input.ujj(tmp0_desc, 8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp21_input.ujj(tmp0_desc, 9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp21_input.djk(tmp0_desc, 10, StringSerializer_getInstance(), tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp21_input.bjk(tmp0_desc, 11, JsonArraySerializer_getInstance(), tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp21_input.ujj(tmp0_desc, 12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp21_input.bjk(tmp0_desc, 13, tmp22_cached[13], tmp17_local13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp21_input.djk(tmp0_desc, 14, IntSerializer_getInstance(), tmp18_local14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp21_input.ujj(tmp0_desc, 15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp21_input.ujj(tmp0_desc, 16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp21_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp21_input.ujj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp21_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp21_input.djk(tmp0_desc, 2, JsonObjectSerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp21_input.bjk(tmp0_desc, 3, tmp22_cached[3], tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp21_input.djk(tmp0_desc, 4, tmp22_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp21_input.bjk(tmp0_desc, 5, tmp22_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp21_input.zjj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp21_input.ujj(tmp0_desc, 7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp21_input.ujj(tmp0_desc, 8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp21_input.ujj(tmp0_desc, 9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp21_input.djk(tmp0_desc, 10, StringSerializer_getInstance(), tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp21_input.bjk(tmp0_desc, 11, JsonArraySerializer_getInstance(), tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp21_input.ujj(tmp0_desc, 12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp21_input.bjk(tmp0_desc, 13, tmp22_cached[13], tmp17_local13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp21_input.djk(tmp0_desc, 14, IntSerializer_getInstance(), tmp18_local14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp21_input.ujj(tmp0_desc, 15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp21_input.ujj(tmp0_desc, 16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp21_input.qjj(tmp0_desc);
    return TilesetDefinition.hkd(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, null);
  }
  ujf() {
    return this.pkc_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_18().okc_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(JsonObjectSerializer_getInstance()), tmp0_cached[3], get_nullable(tmp0_cached[4]), tmp0_cached[5], StringSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(StringSerializer_getInstance()), JsonArraySerializer_getInstance(), IntSerializer_getInstance(), tmp0_cached[13], get_nullable(IntSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class TilesetDefinition {
  toString() {
    return 'TilesetDefinition(cHei=' + this.qkc_1 + ', cWid=' + this.rkc_1 + ', cachedPixelData=' + toString(this.skc_1) + ', customData=' + toString_0(this.tkc_1) + ', embedAtlas=' + toString(this.ukc_1) + ', enumTags=' + toString_0(this.vkc_1) + ', identifier=' + this.wkc_1 + ', padding=' + this.xkc_1 + ', pxHei=' + this.ykc_1 + ', pxWid=' + this.zkc_1 + ', relPath=' + this.akd_1 + ', savedSelections=' + this.bkd_1.toString() + ', spacing=' + this.ckd_1 + ', tags=' + toString_0(this.dkd_1) + ', tagsSourceEnumUid=' + this.ekd_1 + ', tileGridSize=' + this.fkd_1 + ', uid=' + this.gkd_1 + ')';
  }
  hashCode() {
    var result = this.qkc_1;
    result = imul(result, 31) + this.rkc_1 | 0;
    result = imul(result, 31) + (this.skc_1 == null ? 0 : this.skc_1.hashCode()) | 0;
    result = imul(result, 31) + hashCode(this.tkc_1) | 0;
    result = imul(result, 31) + (this.ukc_1 == null ? 0 : this.ukc_1.hashCode()) | 0;
    result = imul(result, 31) + hashCode(this.vkc_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.wkc_1) | 0;
    result = imul(result, 31) + this.xkc_1 | 0;
    result = imul(result, 31) + this.ykc_1 | 0;
    result = imul(result, 31) + this.zkc_1 | 0;
    result = imul(result, 31) + (this.akd_1 == null ? 0 : getStringHashCode(this.akd_1)) | 0;
    result = imul(result, 31) + this.bkd_1.hashCode() | 0;
    result = imul(result, 31) + this.ckd_1 | 0;
    result = imul(result, 31) + hashCode(this.dkd_1) | 0;
    result = imul(result, 31) + (this.ekd_1 == null ? 0 : this.ekd_1) | 0;
    result = imul(result, 31) + this.fkd_1 | 0;
    result = imul(result, 31) + this.gkd_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof TilesetDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof TilesetDefinition ? other : THROW_CCE();
    if (!(this.qkc_1 === tmp0_other_with_cast.qkc_1))
      return false;
    if (!(this.rkc_1 === tmp0_other_with_cast.rkc_1))
      return false;
    if (!equals(this.skc_1, tmp0_other_with_cast.skc_1))
      return false;
    if (!equals(this.tkc_1, tmp0_other_with_cast.tkc_1))
      return false;
    if (!equals(this.ukc_1, tmp0_other_with_cast.ukc_1))
      return false;
    if (!equals(this.vkc_1, tmp0_other_with_cast.vkc_1))
      return false;
    if (!(this.wkc_1 === tmp0_other_with_cast.wkc_1))
      return false;
    if (!(this.xkc_1 === tmp0_other_with_cast.xkc_1))
      return false;
    if (!(this.ykc_1 === tmp0_other_with_cast.ykc_1))
      return false;
    if (!(this.zkc_1 === tmp0_other_with_cast.zkc_1))
      return false;
    if (!(this.akd_1 == tmp0_other_with_cast.akd_1))
      return false;
    if (!this.bkd_1.equals(tmp0_other_with_cast.bkd_1))
      return false;
    if (!(this.ckd_1 === tmp0_other_with_cast.ckd_1))
      return false;
    if (!equals(this.dkd_1, tmp0_other_with_cast.dkd_1))
      return false;
    if (!(this.ekd_1 == tmp0_other_with_cast.ekd_1))
      return false;
    if (!(this.fkd_1 === tmp0_other_with_cast.fkd_1))
      return false;
    if (!(this.gkd_1 === tmp0_other_with_cast.gkd_1))
      return false;
    return true;
  }
  static hkd(seen0, cHei, cWid, cachedPixelData, customData, embedAtlas, enumTags, identifier, padding, pxHei, pxWid, relPath, savedSelections, spacing, tags, tagsSourceEnumUid, tileGridSize, uid, serializationConstructorMarker) {
    Companion_getInstance_18();
    if (!(113643 === (113643 & seen0))) {
      throwMissingFieldException(seen0, 113643, $serializer_getInstance_7().pkc_1);
    }
    var $this = createThis(this);
    $this.qkc_1 = cHei;
    $this.rkc_1 = cWid;
    if (0 === (seen0 & 4))
      $this.skc_1 = null;
    else
      $this.skc_1 = cachedPixelData;
    $this.tkc_1 = customData;
    if (0 === (seen0 & 16))
      $this.ukc_1 = null;
    else
      $this.ukc_1 = embedAtlas;
    $this.vkc_1 = enumTags;
    $this.wkc_1 = identifier;
    $this.xkc_1 = padding;
    $this.ykc_1 = pxHei;
    $this.zkc_1 = pxWid;
    if (0 === (seen0 & 1024))
      $this.akd_1 = null;
    else
      $this.akd_1 = relPath;
    $this.bkd_1 = savedSelections;
    $this.ckd_1 = spacing;
    $this.dkd_1 = tags;
    if (0 === (seen0 & 16384))
      $this.ekd_1 = null;
    else
      $this.ekd_1 = tagsSourceEnumUid;
    $this.fkd_1 = tileGridSize;
    $this.gkd_1 = uid;
    return $this;
  }
}
class Companion_16 {}
class $serializer_8 {
  constructor() {
    $serializer_instance_8 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.EnumValueDefinition', this, 4);
    tmp0_serialDesc.fjp('__tileSrcRect', true);
    tmp0_serialDesc.fjp('color', false);
    tmp0_serialDesc.fjp('id', false);
    tmp0_serialDesc.fjp('tileId', true);
    this.ikd_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ikd_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_input = decoder.pjj(tmp0_desc);
    if (tmp8_input.fjk()) {
      tmp4_local0 = tmp8_input.djk(tmp0_desc, 0, IntArraySerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp8_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp8_input.zjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp8_input.djk(tmp0_desc, 3, IntSerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp8_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp8_input.djk(tmp0_desc, 0, IntArraySerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp8_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp8_input.zjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp8_input.djk(tmp0_desc, 3, IntSerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp8_input.qjj(tmp0_desc);
    return EnumValueDefinition.nkd(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, null);
  }
  ujf() {
    return this.ikd_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable(IntArraySerializer_getInstance()), IntSerializer_getInstance(), StringSerializer_getInstance(), get_nullable(IntSerializer_getInstance())];
  }
}
class EnumValueDefinition {
  toString() {
    return 'EnumValueDefinition(tileSrcRect=' + toString_0(this.jkd_1) + ', color=' + this.kkd_1 + ', id=' + this.lkd_1 + ', tileID=' + this.mkd_1 + ')';
  }
  hashCode() {
    var result = this.jkd_1 == null ? 0 : hashCode(this.jkd_1);
    result = imul(result, 31) + this.kkd_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.lkd_1) | 0;
    result = imul(result, 31) + (this.mkd_1 == null ? 0 : this.mkd_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof EnumValueDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof EnumValueDefinition ? other : THROW_CCE();
    if (!equals(this.jkd_1, tmp0_other_with_cast.jkd_1))
      return false;
    if (!(this.kkd_1 === tmp0_other_with_cast.kkd_1))
      return false;
    if (!(this.lkd_1 === tmp0_other_with_cast.lkd_1))
      return false;
    if (!(this.mkd_1 == tmp0_other_with_cast.mkd_1))
      return false;
    return true;
  }
  static nkd(seen0, tileSrcRect, color, id, tileID, serializationConstructorMarker) {
    if (!(6 === (6 & seen0))) {
      throwMissingFieldException(seen0, 6, $serializer_getInstance_8().ikd_1);
    }
    var $this = createThis(this);
    if (0 === (seen0 & 1))
      $this.jkd_1 = null;
    else
      $this.jkd_1 = tileSrcRect;
    $this.kkd_1 = color;
    $this.lkd_1 = id;
    if (0 === (seen0 & 8))
      $this.mkd_1 = null;
    else
      $this.mkd_1 = tileID;
    return $this;
  }
}
class Companion_17 {
  constructor() {
    Companion_instance_18 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.okd_1 = [null, null, null, null, new ArrayListSerializer($serializer_getInstance_13()), null];
  }
}
class $serializer_9 {
  constructor() {
    $serializer_instance_9 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.AutoLayerRuleGroup', this, 6);
    tmp0_serialDesc.fjp('active', false);
    tmp0_serialDesc.fjp('collapsed', true);
    tmp0_serialDesc.fjp('isOptional', false);
    tmp0_serialDesc.fjp('name', false);
    tmp0_serialDesc.fjp('rules', false);
    tmp0_serialDesc.fjp('uid', false);
    this.pkd_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.pkd_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = false;
    var tmp5_local1 = null;
    var tmp6_local2 = false;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = 0;
    var tmp10_input = decoder.pjj(tmp0_desc);
    var tmp11_cached = Companion_getInstance_20().okd_1;
    if (tmp10_input.fjk()) {
      tmp4_local0 = tmp10_input.rjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp10_input.djk(tmp0_desc, 1, BooleanSerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp10_input.rjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp10_input.zjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp10_input.bjk(tmp0_desc, 4, tmp11_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp10_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp10_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp10_input.rjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp10_input.djk(tmp0_desc, 1, BooleanSerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp10_input.rjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp10_input.zjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp10_input.bjk(tmp0_desc, 4, tmp11_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp10_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp10_input.qjj(tmp0_desc);
    return AutoLayerRuleGroup.wkd(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, null);
  }
  ujf() {
    return this.pkd_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_20().okd_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [BooleanSerializer_getInstance(), get_nullable(BooleanSerializer_getInstance()), BooleanSerializer_getInstance(), StringSerializer_getInstance(), tmp0_cached[4], IntSerializer_getInstance()];
  }
}
class AutoLayerRuleGroup {
  toString() {
    return 'AutoLayerRuleGroup(active=' + this.qkd_1 + ', collapsed=' + this.rkd_1 + ', isOptional=' + this.skd_1 + ', name=' + this.tkd_1 + ', rules=' + toString_0(this.ukd_1) + ', uid=' + this.vkd_1 + ')';
  }
  hashCode() {
    var result = getBooleanHashCode(this.qkd_1);
    result = imul(result, 31) + (this.rkd_1 == null ? 0 : getBooleanHashCode(this.rkd_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.skd_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.tkd_1) | 0;
    result = imul(result, 31) + hashCode(this.ukd_1) | 0;
    result = imul(result, 31) + this.vkd_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof AutoLayerRuleGroup))
      return false;
    var tmp0_other_with_cast = other instanceof AutoLayerRuleGroup ? other : THROW_CCE();
    if (!(this.qkd_1 === tmp0_other_with_cast.qkd_1))
      return false;
    if (!(this.rkd_1 == tmp0_other_with_cast.rkd_1))
      return false;
    if (!(this.skd_1 === tmp0_other_with_cast.skd_1))
      return false;
    if (!(this.tkd_1 === tmp0_other_with_cast.tkd_1))
      return false;
    if (!equals(this.ukd_1, tmp0_other_with_cast.ukd_1))
      return false;
    if (!(this.vkd_1 === tmp0_other_with_cast.vkd_1))
      return false;
    return true;
  }
  static wkd(seen0, active, collapsed, isOptional, name, rules, uid, serializationConstructorMarker) {
    Companion_getInstance_20();
    if (!(61 === (61 & seen0))) {
      throwMissingFieldException(seen0, 61, $serializer_getInstance_9().pkd_1);
    }
    var $this = createThis(this);
    $this.qkd_1 = active;
    if (0 === (seen0 & 2))
      $this.rkd_1 = null;
    else
      $this.rkd_1 = collapsed;
    $this.skd_1 = isOptional;
    $this.tkd_1 = name;
    $this.ukd_1 = rules;
    $this.vkd_1 = uid;
    return $this;
  }
}
class Companion_18 {}
class $serializer_10 {
  constructor() {
    $serializer_instance_10 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.IntGridValueDefinition', this, 3);
    tmp0_serialDesc.fjp('color', false);
    tmp0_serialDesc.fjp('identifier', true);
    tmp0_serialDesc.fjp('value', false);
    this.xkd_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.xkd_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = 0;
    var tmp7_input = decoder.pjj(tmp0_desc);
    if (tmp7_input.fjk()) {
      tmp4_local0 = tmp7_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp7_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp7_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp7_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp7_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp7_input.djk(tmp0_desc, 1, StringSerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp7_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp7_input.qjj(tmp0_desc);
    return IntGridValueDefinition.bke(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, null);
  }
  ujf() {
    return this.xkd_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), get_nullable(StringSerializer_getInstance()), IntSerializer_getInstance()];
  }
}
class IntGridValueDefinition {
  toString() {
    return 'IntGridValueDefinition(color=' + this.ykd_1 + ', identifier=' + this.zkd_1 + ', value=' + this.ake_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.ykd_1);
    result = imul(result, 31) + (this.zkd_1 == null ? 0 : getStringHashCode(this.zkd_1)) | 0;
    result = imul(result, 31) + this.ake_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof IntGridValueDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof IntGridValueDefinition ? other : THROW_CCE();
    if (!(this.ykd_1 === tmp0_other_with_cast.ykd_1))
      return false;
    if (!(this.zkd_1 == tmp0_other_with_cast.zkd_1))
      return false;
    if (!(this.ake_1 === tmp0_other_with_cast.ake_1))
      return false;
    return true;
  }
  static bke(seen0, color, identifier, value, serializationConstructorMarker) {
    if (!(5 === (5 & seen0))) {
      throwMissingFieldException(seen0, 5, $serializer_getInstance_10().xkd_1);
    }
    var $this = createThis(this);
    $this.ykd_1 = color;
    if (0 === (seen0 & 2))
      $this.zkd_1 = null;
    else
      $this.zkd_1 = identifier;
    $this.ake_1 = value;
    return $this;
  }
}
class Companion_19 {
  constructor() {
    Companion_instance_20 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.lkb_1 = lazy(tmp_0, Type$Companion$_anonymous__vg0awb);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.Type', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'AutoLayer':
        tmp = Type_AutoLayer_getInstance();
        break;
      case 'Entities':
        tmp = Type_Entities_getInstance();
        break;
      case 'IntGrid':
        tmp = Type_IntGrid_getInstance();
        break;
      case 'Tiles':
        tmp = Type_Tiles_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('Type could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_7(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class Type extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.eke_1 = value;
  }
}
class Companion_20 {}
class $serializer_11 {
  constructor() {
    $serializer_instance_11 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.TileCustomMetadata', this, 2);
    tmp0_serialDesc.fjp('data', false);
    tmp0_serialDesc.fjp('tileId', false);
    this.fke_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.fke_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_input = decoder.pjj(tmp0_desc);
    if (tmp6_input.fjk()) {
      tmp4_local0 = tmp6_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp6_input.qjj(tmp0_desc);
    return TileCustomMetadata.ike(tmp3_bitMask0, tmp4_local0, tmp5_local1, null);
  }
  ujf() {
    return this.fke_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class TileCustomMetadata {
  toString() {
    return 'TileCustomMetadata(data=' + this.gke_1 + ', tileID=' + this.hke_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.gke_1);
    result = imul(result, 31) + this.hke_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof TileCustomMetadata))
      return false;
    var tmp0_other_with_cast = other instanceof TileCustomMetadata ? other : THROW_CCE();
    if (!(this.gke_1 === tmp0_other_with_cast.gke_1))
      return false;
    if (!(this.hke_1 === tmp0_other_with_cast.hke_1))
      return false;
    return true;
  }
  static ike(seen0, data, tileID, serializationConstructorMarker) {
    if (!(3 === (3 & seen0))) {
      throwMissingFieldException(seen0, 3, $serializer_getInstance_11().fke_1);
    }
    var $this = createThis(this);
    $this.gke_1 = data;
    $this.hke_1 = tileID;
    return $this;
  }
}
class Companion_21 {
  constructor() {
    Companion_instance_22 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.nkc_1 = lazy(tmp_0, EmbedAtlas$Companion$_anonymous__2y90yr);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.EmbedAtlas', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    if (value === 'LdtkIcons') {
      tmp = EmbedAtlas_LdtkIcons_getInstance();
    } else {
      throw IllegalArgumentException.l('EmbedAtlas could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_8(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class EmbedAtlas extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.lke_1 = value;
  }
}
class Companion_22 {}
class $serializer_12 {
  constructor() {
    $serializer_instance_12 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.EnumTagValue', this, 2);
    tmp0_serialDesc.fjp('enumValueId', false);
    tmp0_serialDesc.fjp('tileIds', false);
    this.mke_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.mke_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_input = decoder.pjj(tmp0_desc);
    if (tmp6_input.fjk()) {
      tmp4_local0 = tmp6_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.bjk(tmp0_desc, 1, IntArraySerializer_getInstance(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.bjk(tmp0_desc, 1, IntArraySerializer_getInstance(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp6_input.qjj(tmp0_desc);
    return EnumTagValue.pke(tmp3_bitMask0, tmp4_local0, tmp5_local1, null);
  }
  ujf() {
    return this.mke_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), IntArraySerializer_getInstance()];
  }
}
class EnumTagValue {
  toString() {
    return 'EnumTagValue(enumValueID=' + this.nke_1 + ', tileIDS=' + toString_0(this.oke_1) + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.nke_1);
    result = imul(result, 31) + hashCode(this.oke_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof EnumTagValue))
      return false;
    var tmp0_other_with_cast = other instanceof EnumTagValue ? other : THROW_CCE();
    if (!(this.nke_1 === tmp0_other_with_cast.nke_1))
      return false;
    if (!equals(this.oke_1, tmp0_other_with_cast.oke_1))
      return false;
    return true;
  }
  static pke(seen0, enumValueID, tileIDS, serializationConstructorMarker) {
    if (!(3 === (3 & seen0))) {
      throwMissingFieldException(seen0, 3, $serializer_getInstance_12().mke_1);
    }
    var $this = createThis(this);
    $this.nke_1 = enumValueID;
    $this.oke_1 = tileIDS;
    return $this;
  }
}
class Companion_23 {
  constructor() {
    Companion_instance_24 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.ske_1 = [null, null, null, Companion_getInstance_27().ijz(), null, null, null, null, null, null, null, null, null, null, null, null, Companion_getInstance_28().ijz(), null, null, null, null, null];
  }
}
class $serializer_13 {
  constructor() {
    $serializer_instance_13 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.AutoLayerRuleDefinition', this, 22);
    tmp0_serialDesc.fjp('active', false);
    tmp0_serialDesc.fjp('breakOnMatch', false);
    tmp0_serialDesc.fjp('chance', false);
    tmp0_serialDesc.fjp('checker', false);
    tmp0_serialDesc.fjp('flipX', false);
    tmp0_serialDesc.fjp('flipY', false);
    tmp0_serialDesc.fjp('outOfBoundsValue', true);
    tmp0_serialDesc.fjp('pattern', false);
    tmp0_serialDesc.fjp('perlinActive', false);
    tmp0_serialDesc.fjp('perlinOctaves', false);
    tmp0_serialDesc.fjp('perlinScale', false);
    tmp0_serialDesc.fjp('perlinSeed', false);
    tmp0_serialDesc.fjp('pivotX', false);
    tmp0_serialDesc.fjp('pivotY', false);
    tmp0_serialDesc.fjp('size', false);
    tmp0_serialDesc.fjp('tileIds', false);
    tmp0_serialDesc.fjp('tileMode', false);
    tmp0_serialDesc.fjp('uid', false);
    tmp0_serialDesc.fjp('xModulo', false);
    tmp0_serialDesc.fjp('xOffset', false);
    tmp0_serialDesc.fjp('yModulo', false);
    tmp0_serialDesc.fjp('yOffset', false);
    this.tke_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.tke_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = false;
    var tmp5_local1 = false;
    var tmp6_local2 = 0.0;
    var tmp7_local3 = null;
    var tmp8_local4 = false;
    var tmp9_local5 = false;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = false;
    var tmp13_local9 = 0.0;
    var tmp14_local10 = 0.0;
    var tmp15_local11 = 0.0;
    var tmp16_local12 = 0.0;
    var tmp17_local13 = 0.0;
    var tmp18_local14 = 0;
    var tmp19_local15 = null;
    var tmp20_local16 = null;
    var tmp21_local17 = 0;
    var tmp22_local18 = 0;
    var tmp23_local19 = 0;
    var tmp24_local20 = 0;
    var tmp25_local21 = 0;
    var tmp26_input = decoder.pjj(tmp0_desc);
    var tmp27_cached = Companion_getInstance_26().ske_1;
    if (tmp26_input.fjk()) {
      tmp4_local0 = tmp26_input.rjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp26_input.rjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp26_input.xjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp26_input.bjk(tmp0_desc, 3, tmp27_cached[3], tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp26_input.rjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp26_input.rjj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp26_input.djk(tmp0_desc, 6, IntSerializer_getInstance(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp26_input.bjk(tmp0_desc, 7, IntArraySerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp26_input.rjj(tmp0_desc, 8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp26_input.xjj(tmp0_desc, 9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp26_input.xjj(tmp0_desc, 10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp26_input.xjj(tmp0_desc, 11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp26_input.xjj(tmp0_desc, 12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp26_input.xjj(tmp0_desc, 13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp26_input.ujj(tmp0_desc, 14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp26_input.bjk(tmp0_desc, 15, IntArraySerializer_getInstance(), tmp19_local15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp26_input.bjk(tmp0_desc, 16, tmp27_cached[16], tmp20_local16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp26_input.ujj(tmp0_desc, 17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp26_input.ujj(tmp0_desc, 18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp26_input.ujj(tmp0_desc, 19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp26_input.ujj(tmp0_desc, 20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
      tmp25_local21 = tmp26_input.ujj(tmp0_desc, 21);
      tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp26_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp26_input.rjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp26_input.rjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp26_input.xjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp26_input.bjk(tmp0_desc, 3, tmp27_cached[3], tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp26_input.rjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp26_input.rjj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp26_input.djk(tmp0_desc, 6, IntSerializer_getInstance(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp26_input.bjk(tmp0_desc, 7, IntArraySerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp26_input.rjj(tmp0_desc, 8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp26_input.xjj(tmp0_desc, 9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp26_input.xjj(tmp0_desc, 10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp26_input.xjj(tmp0_desc, 11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp26_input.xjj(tmp0_desc, 12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp26_input.xjj(tmp0_desc, 13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp26_input.ujj(tmp0_desc, 14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp26_input.bjk(tmp0_desc, 15, IntArraySerializer_getInstance(), tmp19_local15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp26_input.bjk(tmp0_desc, 16, tmp27_cached[16], tmp20_local16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp26_input.ujj(tmp0_desc, 17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp26_input.ujj(tmp0_desc, 18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp26_input.ujj(tmp0_desc, 19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp26_input.ujj(tmp0_desc, 20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          case 21:
            tmp25_local21 = tmp26_input.ujj(tmp0_desc, 21);
            tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp26_input.qjj(tmp0_desc);
    return AutoLayerRuleDefinition.qkf(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, tmp25_local21, null);
  }
  ujf() {
    return this.tke_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_26().ske_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), DoubleSerializer_getInstance(), tmp0_cached[3], BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), get_nullable(IntSerializer_getInstance()), IntArraySerializer_getInstance(), BooleanSerializer_getInstance(), DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), IntSerializer_getInstance(), IntArraySerializer_getInstance(), tmp0_cached[16], IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class AutoLayerRuleDefinition {
  toString() {
    return 'AutoLayerRuleDefinition(active=' + this.uke_1 + ', breakOnMatch=' + this.vke_1 + ', chance=' + this.wke_1 + ', checker=' + this.xke_1.toString() + ', flipX=' + this.yke_1 + ', flipY=' + this.zke_1 + ', outOfBoundsValue=' + this.akf_1 + ', pattern=' + toString_0(this.bkf_1) + ', perlinActive=' + this.ckf_1 + ', perlinOctaves=' + this.dkf_1 + ', perlinScale=' + this.ekf_1 + ', perlinSeed=' + this.fkf_1 + ', pivotX=' + this.gkf_1 + ', pivotY=' + this.hkf_1 + ', size=' + this.ikf_1 + ', tileIDS=' + toString_0(this.jkf_1) + ', tileMode=' + this.kkf_1.toString() + ', uid=' + this.lkf_1 + ', xModulo=' + this.mkf_1 + ', xOffset=' + this.nkf_1 + ', yModulo=' + this.okf_1 + ', yOffset=' + this.pkf_1 + ')';
  }
  hashCode() {
    var result = getBooleanHashCode(this.uke_1);
    result = imul(result, 31) + getBooleanHashCode(this.vke_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.wke_1) | 0;
    result = imul(result, 31) + this.xke_1.hashCode() | 0;
    result = imul(result, 31) + getBooleanHashCode(this.yke_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.zke_1) | 0;
    result = imul(result, 31) + (this.akf_1 == null ? 0 : this.akf_1) | 0;
    result = imul(result, 31) + hashCode(this.bkf_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.ckf_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.dkf_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ekf_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.fkf_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.gkf_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.hkf_1) | 0;
    result = imul(result, 31) + this.ikf_1 | 0;
    result = imul(result, 31) + hashCode(this.jkf_1) | 0;
    result = imul(result, 31) + this.kkf_1.hashCode() | 0;
    result = imul(result, 31) + this.lkf_1 | 0;
    result = imul(result, 31) + this.mkf_1 | 0;
    result = imul(result, 31) + this.nkf_1 | 0;
    result = imul(result, 31) + this.okf_1 | 0;
    result = imul(result, 31) + this.pkf_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof AutoLayerRuleDefinition))
      return false;
    var tmp0_other_with_cast = other instanceof AutoLayerRuleDefinition ? other : THROW_CCE();
    if (!(this.uke_1 === tmp0_other_with_cast.uke_1))
      return false;
    if (!(this.vke_1 === tmp0_other_with_cast.vke_1))
      return false;
    if (!equals(this.wke_1, tmp0_other_with_cast.wke_1))
      return false;
    if (!this.xke_1.equals(tmp0_other_with_cast.xke_1))
      return false;
    if (!(this.yke_1 === tmp0_other_with_cast.yke_1))
      return false;
    if (!(this.zke_1 === tmp0_other_with_cast.zke_1))
      return false;
    if (!(this.akf_1 == tmp0_other_with_cast.akf_1))
      return false;
    if (!equals(this.bkf_1, tmp0_other_with_cast.bkf_1))
      return false;
    if (!(this.ckf_1 === tmp0_other_with_cast.ckf_1))
      return false;
    if (!equals(this.dkf_1, tmp0_other_with_cast.dkf_1))
      return false;
    if (!equals(this.ekf_1, tmp0_other_with_cast.ekf_1))
      return false;
    if (!equals(this.fkf_1, tmp0_other_with_cast.fkf_1))
      return false;
    if (!equals(this.gkf_1, tmp0_other_with_cast.gkf_1))
      return false;
    if (!equals(this.hkf_1, tmp0_other_with_cast.hkf_1))
      return false;
    if (!(this.ikf_1 === tmp0_other_with_cast.ikf_1))
      return false;
    if (!equals(this.jkf_1, tmp0_other_with_cast.jkf_1))
      return false;
    if (!this.kkf_1.equals(tmp0_other_with_cast.kkf_1))
      return false;
    if (!(this.lkf_1 === tmp0_other_with_cast.lkf_1))
      return false;
    if (!(this.mkf_1 === tmp0_other_with_cast.mkf_1))
      return false;
    if (!(this.nkf_1 === tmp0_other_with_cast.nkf_1))
      return false;
    if (!(this.okf_1 === tmp0_other_with_cast.okf_1))
      return false;
    if (!(this.pkf_1 === tmp0_other_with_cast.pkf_1))
      return false;
    return true;
  }
  static qkf(seen0, active, breakOnMatch, chance, checker, flipX, flipY, outOfBoundsValue, pattern, perlinActive, perlinOctaves, perlinScale, perlinSeed, pivotX, pivotY, size, tileIDS, tileMode, uid, xModulo, xOffset, yModulo, yOffset, serializationConstructorMarker) {
    Companion_getInstance_26();
    if (!(4194239 === (4194239 & seen0))) {
      throwMissingFieldException(seen0, 4194239, $serializer_getInstance_13().tke_1);
    }
    var $this = createThis(this);
    $this.uke_1 = active;
    $this.vke_1 = breakOnMatch;
    $this.wke_1 = chance;
    $this.xke_1 = checker;
    $this.yke_1 = flipX;
    $this.zke_1 = flipY;
    if (0 === (seen0 & 64))
      $this.akf_1 = null;
    else
      $this.akf_1 = outOfBoundsValue;
    $this.bkf_1 = pattern;
    $this.ckf_1 = perlinActive;
    $this.dkf_1 = perlinOctaves;
    $this.ekf_1 = perlinScale;
    $this.fkf_1 = perlinSeed;
    $this.gkf_1 = pivotX;
    $this.hkf_1 = pivotY;
    $this.ikf_1 = size;
    $this.jkf_1 = tileIDS;
    $this.kkf_1 = tileMode;
    $this.lkf_1 = uid;
    $this.mkf_1 = xModulo;
    $this.nkf_1 = xOffset;
    $this.okf_1 = yModulo;
    $this.pkf_1 = yOffset;
    return $this;
  }
}
class Companion_24 {
  constructor() {
    Companion_instance_25 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.qke_1 = lazy(tmp_0, Checker$Companion$_anonymous__y7s1qy);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.Checker', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Horizontal':
        tmp = Checker_Horizontal_getInstance();
        break;
      case 'None':
        tmp = Checker_None_getInstance();
        break;
      case 'Vertical':
        tmp = Checker_Vertical_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('Checker could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_9(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class Checker extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.tkf_1 = value;
  }
}
class Companion_25 {
  constructor() {
    Companion_instance_26 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.rke_1 = lazy(tmp_0, TileMode$Companion$_anonymous__ez765a);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.TileMode', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Single':
        tmp = TileMode_Single_getInstance();
        break;
      case 'Stamp':
        tmp = TileMode_Stamp_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('TileMode could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_10(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class TileMode extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.wkf_1 = value;
  }
}
class Companion_26 {
  constructor() {
    Companion_instance_27 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.xkf_1 = [null, null, null, null, null, null, null, null, null, null, new ArrayListSerializer($serializer_getInstance_17()), new ArrayListSerializer($serializer_getInstance_0()), new ArrayListSerializer($serializer_getInstance_17()), null, null, null, null, null, null, null, null, null, null];
  }
}
class $serializer_14 {
  constructor() {
    $serializer_instance_14 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.LayerInstance', this, 23);
    tmp0_serialDesc.fjp('__cHei', false);
    tmp0_serialDesc.fjp('__cWid', false);
    tmp0_serialDesc.fjp('__gridSize', false);
    tmp0_serialDesc.fjp('__identifier', false);
    tmp0_serialDesc.fjp('__opacity', false);
    tmp0_serialDesc.fjp('__pxTotalOffsetX', false);
    tmp0_serialDesc.fjp('__pxTotalOffsetY', false);
    tmp0_serialDesc.fjp('__tilesetDefUid', true);
    tmp0_serialDesc.fjp('__tilesetRelPath', true);
    tmp0_serialDesc.fjp('__type', false);
    tmp0_serialDesc.fjp('autoLayerTiles', false);
    tmp0_serialDesc.fjp('entityInstances', false);
    tmp0_serialDesc.fjp('gridTiles', false);
    tmp0_serialDesc.fjp('iid', false);
    tmp0_serialDesc.fjp('intGridCsv', false);
    tmp0_serialDesc.fjp('layerDefUid', false);
    tmp0_serialDesc.fjp('levelId', false);
    tmp0_serialDesc.fjp('optionalRules', false);
    tmp0_serialDesc.fjp('overrideTilesetUid', true);
    tmp0_serialDesc.fjp('pxOffsetX', false);
    tmp0_serialDesc.fjp('pxOffsetY', false);
    tmp0_serialDesc.fjp('seed', false);
    tmp0_serialDesc.fjp('visible', false);
    this.ykf_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ykf_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = 0;
    var tmp6_local2 = 0;
    var tmp7_local3 = null;
    var tmp8_local4 = 0.0;
    var tmp9_local5 = 0;
    var tmp10_local6 = 0;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp16_local12 = null;
    var tmp17_local13 = null;
    var tmp18_local14 = null;
    var tmp19_local15 = 0;
    var tmp20_local16 = 0;
    var tmp21_local17 = null;
    var tmp22_local18 = null;
    var tmp23_local19 = 0;
    var tmp24_local20 = 0;
    var tmp25_local21 = 0;
    var tmp26_local22 = false;
    var tmp27_input = decoder.pjj(tmp0_desc);
    var tmp28_cached = Companion_getInstance_29().xkf_1;
    if (tmp27_input.fjk()) {
      tmp4_local0 = tmp27_input.ujj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp27_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp27_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp27_input.zjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp27_input.xjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp27_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp27_input.ujj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp27_input.djk(tmp0_desc, 7, IntSerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp27_input.djk(tmp0_desc, 8, StringSerializer_getInstance(), tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp27_input.zjj(tmp0_desc, 9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp27_input.bjk(tmp0_desc, 10, tmp28_cached[10], tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp27_input.bjk(tmp0_desc, 11, tmp28_cached[11], tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp27_input.bjk(tmp0_desc, 12, tmp28_cached[12], tmp16_local12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp27_input.zjj(tmp0_desc, 13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp27_input.bjk(tmp0_desc, 14, IntArraySerializer_getInstance(), tmp18_local14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp27_input.ujj(tmp0_desc, 15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp27_input.ujj(tmp0_desc, 16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp27_input.bjk(tmp0_desc, 17, IntArraySerializer_getInstance(), tmp21_local17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp27_input.djk(tmp0_desc, 18, IntSerializer_getInstance(), tmp22_local18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp27_input.ujj(tmp0_desc, 19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp27_input.ujj(tmp0_desc, 20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
      tmp25_local21 = tmp27_input.ujj(tmp0_desc, 21);
      tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
      tmp26_local22 = tmp27_input.rjj(tmp0_desc, 22);
      tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp27_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp27_input.ujj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp27_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp27_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp27_input.zjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp27_input.xjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp27_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp27_input.ujj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp27_input.djk(tmp0_desc, 7, IntSerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp27_input.djk(tmp0_desc, 8, StringSerializer_getInstance(), tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp27_input.zjj(tmp0_desc, 9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp27_input.bjk(tmp0_desc, 10, tmp28_cached[10], tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp27_input.bjk(tmp0_desc, 11, tmp28_cached[11], tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp27_input.bjk(tmp0_desc, 12, tmp28_cached[12], tmp16_local12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp27_input.zjj(tmp0_desc, 13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp27_input.bjk(tmp0_desc, 14, IntArraySerializer_getInstance(), tmp18_local14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp27_input.ujj(tmp0_desc, 15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp27_input.ujj(tmp0_desc, 16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp27_input.bjk(tmp0_desc, 17, IntArraySerializer_getInstance(), tmp21_local17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp27_input.djk(tmp0_desc, 18, IntSerializer_getInstance(), tmp22_local18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp27_input.ujj(tmp0_desc, 19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp27_input.ujj(tmp0_desc, 20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          case 21:
            tmp25_local21 = tmp27_input.ujj(tmp0_desc, 21);
            tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
            break;
          case 22:
            tmp26_local22 = tmp27_input.rjj(tmp0_desc, 22);
            tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp27_input.qjj(tmp0_desc);
    return LayerInstance.wkg(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, tmp25_local21, tmp26_local22, null);
  }
  ujf() {
    return this.ykf_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_29().xkf_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), StringSerializer_getInstance(), DoubleSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(IntSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), StringSerializer_getInstance(), tmp0_cached[10], tmp0_cached[11], tmp0_cached[12], StringSerializer_getInstance(), IntArraySerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntArraySerializer_getInstance(), get_nullable(IntSerializer_getInstance()), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), BooleanSerializer_getInstance()];
  }
}
class LayerInstance {
  toString() {
    return 'LayerInstance(cHei=' + this.zkf_1 + ', cWid=' + this.akg_1 + ', gridSize=' + this.bkg_1 + ', identifier=' + this.ckg_1 + ', opacity=' + this.dkg_1 + ', pxTotalOffsetX=' + this.ekg_1 + ', pxTotalOffsetY=' + this.fkg_1 + ', tilesetDefUid=' + this.gkg_1 + ', tilesetRelPath=' + this.hkg_1 + ', type=' + this.ikg_1 + ', autoLayerTiles=' + toString_0(this.jkg_1) + ', entityInstances=' + toString_0(this.kkg_1) + ', gridTiles=' + toString_0(this.lkg_1) + ', iid=' + this.mkg_1 + ', intGridCSV=' + toString_0(this.nkg_1) + ', layerDefUid=' + this.okg_1 + ', levelID=' + this.pkg_1 + ', optionalRules=' + toString_0(this.qkg_1) + ', overrideTilesetUid=' + this.rkg_1 + ', pxOffsetX=' + this.skg_1 + ', pxOffsetY=' + this.tkg_1 + ', seed=' + this.ukg_1 + ', visible=' + this.vkg_1 + ')';
  }
  hashCode() {
    var result = this.zkf_1;
    result = imul(result, 31) + this.akg_1 | 0;
    result = imul(result, 31) + this.bkg_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.ckg_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.dkg_1) | 0;
    result = imul(result, 31) + this.ekg_1 | 0;
    result = imul(result, 31) + this.fkg_1 | 0;
    result = imul(result, 31) + (this.gkg_1 == null ? 0 : this.gkg_1) | 0;
    result = imul(result, 31) + (this.hkg_1 == null ? 0 : getStringHashCode(this.hkg_1)) | 0;
    result = imul(result, 31) + getStringHashCode(this.ikg_1) | 0;
    result = imul(result, 31) + hashCode(this.jkg_1) | 0;
    result = imul(result, 31) + hashCode(this.kkg_1) | 0;
    result = imul(result, 31) + hashCode(this.lkg_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.mkg_1) | 0;
    result = imul(result, 31) + hashCode(this.nkg_1) | 0;
    result = imul(result, 31) + this.okg_1 | 0;
    result = imul(result, 31) + this.pkg_1 | 0;
    result = imul(result, 31) + hashCode(this.qkg_1) | 0;
    result = imul(result, 31) + (this.rkg_1 == null ? 0 : this.rkg_1) | 0;
    result = imul(result, 31) + this.skg_1 | 0;
    result = imul(result, 31) + this.tkg_1 | 0;
    result = imul(result, 31) + this.ukg_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.vkg_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof LayerInstance))
      return false;
    var tmp0_other_with_cast = other instanceof LayerInstance ? other : THROW_CCE();
    if (!(this.zkf_1 === tmp0_other_with_cast.zkf_1))
      return false;
    if (!(this.akg_1 === tmp0_other_with_cast.akg_1))
      return false;
    if (!(this.bkg_1 === tmp0_other_with_cast.bkg_1))
      return false;
    if (!(this.ckg_1 === tmp0_other_with_cast.ckg_1))
      return false;
    if (!equals(this.dkg_1, tmp0_other_with_cast.dkg_1))
      return false;
    if (!(this.ekg_1 === tmp0_other_with_cast.ekg_1))
      return false;
    if (!(this.fkg_1 === tmp0_other_with_cast.fkg_1))
      return false;
    if (!(this.gkg_1 == tmp0_other_with_cast.gkg_1))
      return false;
    if (!(this.hkg_1 == tmp0_other_with_cast.hkg_1))
      return false;
    if (!(this.ikg_1 === tmp0_other_with_cast.ikg_1))
      return false;
    if (!equals(this.jkg_1, tmp0_other_with_cast.jkg_1))
      return false;
    if (!equals(this.kkg_1, tmp0_other_with_cast.kkg_1))
      return false;
    if (!equals(this.lkg_1, tmp0_other_with_cast.lkg_1))
      return false;
    if (!(this.mkg_1 === tmp0_other_with_cast.mkg_1))
      return false;
    if (!equals(this.nkg_1, tmp0_other_with_cast.nkg_1))
      return false;
    if (!(this.okg_1 === tmp0_other_with_cast.okg_1))
      return false;
    if (!(this.pkg_1 === tmp0_other_with_cast.pkg_1))
      return false;
    if (!equals(this.qkg_1, tmp0_other_with_cast.qkg_1))
      return false;
    if (!(this.rkg_1 == tmp0_other_with_cast.rkg_1))
      return false;
    if (!(this.skg_1 === tmp0_other_with_cast.skg_1))
      return false;
    if (!(this.tkg_1 === tmp0_other_with_cast.tkg_1))
      return false;
    if (!(this.ukg_1 === tmp0_other_with_cast.ukg_1))
      return false;
    if (!(this.vkg_1 === tmp0_other_with_cast.vkg_1))
      return false;
    return true;
  }
  static wkg(seen0, cHei, cWid, gridSize, identifier, opacity, pxTotalOffsetX, pxTotalOffsetY, tilesetDefUid, tilesetRelPath, type, autoLayerTiles, entityInstances, gridTiles, iid, intGridCSV, layerDefUid, levelID, optionalRules, overrideTilesetUid, pxOffsetX, pxOffsetY, seed, visible, serializationConstructorMarker) {
    Companion_getInstance_29();
    if (!(8126079 === (8126079 & seen0))) {
      throwMissingFieldException(seen0, 8126079, $serializer_getInstance_14().ykf_1);
    }
    var $this = createThis(this);
    $this.zkf_1 = cHei;
    $this.akg_1 = cWid;
    $this.bkg_1 = gridSize;
    $this.ckg_1 = identifier;
    $this.dkg_1 = opacity;
    $this.ekg_1 = pxTotalOffsetX;
    $this.fkg_1 = pxTotalOffsetY;
    if (0 === (seen0 & 128))
      $this.gkg_1 = null;
    else
      $this.gkg_1 = tilesetDefUid;
    if (0 === (seen0 & 256))
      $this.hkg_1 = null;
    else
      $this.hkg_1 = tilesetRelPath;
    $this.ikg_1 = type;
    $this.jkg_1 = autoLayerTiles;
    $this.kkg_1 = entityInstances;
    $this.lkg_1 = gridTiles;
    $this.mkg_1 = iid;
    $this.nkg_1 = intGridCSV;
    $this.okg_1 = layerDefUid;
    $this.pkg_1 = levelID;
    $this.qkg_1 = optionalRules;
    if (0 === (seen0 & 262144))
      $this.rkg_1 = null;
    else
      $this.rkg_1 = overrideTilesetUid;
    $this.skg_1 = pxOffsetX;
    $this.tkg_1 = pxOffsetY;
    $this.ukg_1 = seed;
    $this.vkg_1 = visible;
    return $this;
  }
}
class Companion_27 {
  constructor() {
    Companion_instance_28 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.bkh_1 = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, new ArrayListSerializer(Companion_getInstance_34().ijz()), Companion_getInstance_35().ijz(), Companion_getInstance_36().ijz(), null, null, new ArrayListSerializer($serializer_getInstance_16()), null, null, null, null, null, null, null, Companion_getInstance_37().ijz(), new ArrayListSerializer($serializer_getInstance_19())];
  }
  dab(jsonString) {
    // Inline function 'kotlinx.serialization.json.Json.decodeFromString' call
    var this_0 = Json(VOID, LDTKJson$Companion$load$lambda);
    // Inline function 'kotlinx.serialization.serializer' call
    var this_1 = this_0.ejk();
    // Inline function 'kotlinx.serialization.internal.cast' call
    var this_2 = serializer(this_1, createKType(getKClass(LDTKJson), arrayOf([]), false));
    var tmp$ret$1 = isInterface(this_2, KSerializer) ? this_2 : THROW_CCE();
    return this_0.zjw(tmp$ret$1, jsonString);
  }
}
class $serializer_15 {
  constructor() {
    $serializer_instance_15 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.LDTKJson', this, 29);
    tmp0_serialDesc.fjp('__FORCED_REFS', true);
    tmp0_serialDesc.fjp('appBuildId', false);
    tmp0_serialDesc.fjp('backupLimit', false);
    tmp0_serialDesc.fjp('backupOnSave', false);
    tmp0_serialDesc.fjp('bgColor', false);
    tmp0_serialDesc.fjp('defaultGridSize', false);
    tmp0_serialDesc.fjp('defaultLevelBgColor', false);
    tmp0_serialDesc.fjp('defaultLevelHeight', true);
    tmp0_serialDesc.fjp('defaultLevelWidth', true);
    tmp0_serialDesc.fjp('defaultPivotX', false);
    tmp0_serialDesc.fjp('defaultPivotY', false);
    tmp0_serialDesc.fjp('defs', false);
    tmp0_serialDesc.fjp('exportTiled', false);
    tmp0_serialDesc.fjp('externalLevels', false);
    tmp0_serialDesc.fjp('flags', false);
    tmp0_serialDesc.fjp('identifierStyle', false);
    tmp0_serialDesc.fjp('imageExportMode', false);
    tmp0_serialDesc.fjp('jsonVersion', false);
    tmp0_serialDesc.fjp('levelNamePattern', false);
    tmp0_serialDesc.fjp('levels', false);
    tmp0_serialDesc.fjp('minifyJson', false);
    tmp0_serialDesc.fjp('nextUid', false);
    tmp0_serialDesc.fjp('pngFilePattern', true);
    tmp0_serialDesc.fjp('simplifiedExport', false);
    tmp0_serialDesc.fjp('tutorialDesc', true);
    tmp0_serialDesc.fjp('worldGridHeight', true);
    tmp0_serialDesc.fjp('worldGridWidth', true);
    tmp0_serialDesc.fjp('worldLayout', true);
    tmp0_serialDesc.fjp('worlds', false);
    this.ckh_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.ckh_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0.0;
    var tmp6_local2 = 0;
    var tmp7_local3 = false;
    var tmp8_local4 = null;
    var tmp9_local5 = 0;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = 0.0;
    var tmp14_local10 = 0.0;
    var tmp15_local11 = null;
    var tmp16_local12 = false;
    var tmp17_local13 = false;
    var tmp18_local14 = null;
    var tmp19_local15 = null;
    var tmp20_local16 = null;
    var tmp21_local17 = null;
    var tmp22_local18 = null;
    var tmp23_local19 = null;
    var tmp24_local20 = false;
    var tmp25_local21 = 0;
    var tmp26_local22 = null;
    var tmp27_local23 = false;
    var tmp28_local24 = null;
    var tmp29_local25 = null;
    var tmp30_local26 = null;
    var tmp31_local27 = null;
    var tmp32_local28 = null;
    var tmp33_input = decoder.pjj(tmp0_desc);
    var tmp34_cached = Companion_getInstance_30().bkh_1;
    if (tmp33_input.fjk()) {
      tmp4_local0 = tmp33_input.djk(tmp0_desc, 0, $serializer_getInstance_18(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp33_input.xjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp33_input.ujj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp33_input.rjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp33_input.zjj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp33_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp33_input.zjj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp33_input.djk(tmp0_desc, 7, IntSerializer_getInstance(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp33_input.djk(tmp0_desc, 8, IntSerializer_getInstance(), tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp33_input.xjj(tmp0_desc, 9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp33_input.xjj(tmp0_desc, 10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp33_input.bjk(tmp0_desc, 11, $serializer_getInstance_3(), tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp33_input.rjj(tmp0_desc, 12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp33_input.rjj(tmp0_desc, 13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp33_input.bjk(tmp0_desc, 14, tmp34_cached[14], tmp18_local14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp33_input.bjk(tmp0_desc, 15, tmp34_cached[15], tmp19_local15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp33_input.bjk(tmp0_desc, 16, tmp34_cached[16], tmp20_local16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp33_input.zjj(tmp0_desc, 17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp33_input.zjj(tmp0_desc, 18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp33_input.bjk(tmp0_desc, 19, tmp34_cached[19], tmp23_local19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp33_input.rjj(tmp0_desc, 20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
      tmp25_local21 = tmp33_input.ujj(tmp0_desc, 21);
      tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
      tmp26_local22 = tmp33_input.djk(tmp0_desc, 22, StringSerializer_getInstance(), tmp26_local22);
      tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
      tmp27_local23 = tmp33_input.rjj(tmp0_desc, 23);
      tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
      tmp28_local24 = tmp33_input.djk(tmp0_desc, 24, StringSerializer_getInstance(), tmp28_local24);
      tmp3_bitMask0 = tmp3_bitMask0 | 16777216;
      tmp29_local25 = tmp33_input.djk(tmp0_desc, 25, IntSerializer_getInstance(), tmp29_local25);
      tmp3_bitMask0 = tmp3_bitMask0 | 33554432;
      tmp30_local26 = tmp33_input.djk(tmp0_desc, 26, IntSerializer_getInstance(), tmp30_local26);
      tmp3_bitMask0 = tmp3_bitMask0 | 67108864;
      tmp31_local27 = tmp33_input.djk(tmp0_desc, 27, tmp34_cached[27], tmp31_local27);
      tmp3_bitMask0 = tmp3_bitMask0 | 134217728;
      tmp32_local28 = tmp33_input.bjk(tmp0_desc, 28, tmp34_cached[28], tmp32_local28);
      tmp3_bitMask0 = tmp3_bitMask0 | 268435456;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp33_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp33_input.djk(tmp0_desc, 0, $serializer_getInstance_18(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp33_input.xjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp33_input.ujj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp33_input.rjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp33_input.zjj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp33_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp33_input.zjj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp33_input.djk(tmp0_desc, 7, IntSerializer_getInstance(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp33_input.djk(tmp0_desc, 8, IntSerializer_getInstance(), tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp33_input.xjj(tmp0_desc, 9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp33_input.xjj(tmp0_desc, 10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp33_input.bjk(tmp0_desc, 11, $serializer_getInstance_3(), tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp33_input.rjj(tmp0_desc, 12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp33_input.rjj(tmp0_desc, 13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp33_input.bjk(tmp0_desc, 14, tmp34_cached[14], tmp18_local14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp33_input.bjk(tmp0_desc, 15, tmp34_cached[15], tmp19_local15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp33_input.bjk(tmp0_desc, 16, tmp34_cached[16], tmp20_local16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp33_input.zjj(tmp0_desc, 17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp33_input.zjj(tmp0_desc, 18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp33_input.bjk(tmp0_desc, 19, tmp34_cached[19], tmp23_local19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp33_input.rjj(tmp0_desc, 20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          case 21:
            tmp25_local21 = tmp33_input.ujj(tmp0_desc, 21);
            tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
            break;
          case 22:
            tmp26_local22 = tmp33_input.djk(tmp0_desc, 22, StringSerializer_getInstance(), tmp26_local22);
            tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
            break;
          case 23:
            tmp27_local23 = tmp33_input.rjj(tmp0_desc, 23);
            tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
            break;
          case 24:
            tmp28_local24 = tmp33_input.djk(tmp0_desc, 24, StringSerializer_getInstance(), tmp28_local24);
            tmp3_bitMask0 = tmp3_bitMask0 | 16777216;
            break;
          case 25:
            tmp29_local25 = tmp33_input.djk(tmp0_desc, 25, IntSerializer_getInstance(), tmp29_local25);
            tmp3_bitMask0 = tmp3_bitMask0 | 33554432;
            break;
          case 26:
            tmp30_local26 = tmp33_input.djk(tmp0_desc, 26, IntSerializer_getInstance(), tmp30_local26);
            tmp3_bitMask0 = tmp3_bitMask0 | 67108864;
            break;
          case 27:
            tmp31_local27 = tmp33_input.djk(tmp0_desc, 27, tmp34_cached[27], tmp31_local27);
            tmp3_bitMask0 = tmp3_bitMask0 | 134217728;
            break;
          case 28:
            tmp32_local28 = tmp33_input.bjk(tmp0_desc, 28, tmp34_cached[28], tmp32_local28);
            tmp3_bitMask0 = tmp3_bitMask0 | 268435456;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp33_input.qjj(tmp0_desc);
    return LDTKJson.gki(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, tmp25_local21, tmp26_local22, tmp27_local23, tmp28_local24, tmp29_local25, tmp30_local26, tmp31_local27, tmp32_local28, null);
  }
  ujf() {
    return this.ckh_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_30().bkh_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable($serializer_getInstance_18()), DoubleSerializer_getInstance(), IntSerializer_getInstance(), BooleanSerializer_getInstance(), StringSerializer_getInstance(), IntSerializer_getInstance(), StringSerializer_getInstance(), get_nullable(IntSerializer_getInstance()), get_nullable(IntSerializer_getInstance()), DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), $serializer_getInstance_3(), BooleanSerializer_getInstance(), BooleanSerializer_getInstance(), tmp0_cached[14], tmp0_cached[15], tmp0_cached[16], StringSerializer_getInstance(), StringSerializer_getInstance(), tmp0_cached[19], BooleanSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(StringSerializer_getInstance()), BooleanSerializer_getInstance(), get_nullable(StringSerializer_getInstance()), get_nullable(IntSerializer_getInstance()), get_nullable(IntSerializer_getInstance()), get_nullable(tmp0_cached[27]), tmp0_cached[28]];
  }
}
class LDTKJson {
  toString() {
    return 'LDTKJson(forcedRefs=' + toString(this.dkh_1) + ', appBuildID=' + this.ekh_1 + ', backupLimit=' + this.fkh_1 + ', backupOnSave=' + this.gkh_1 + ', bgColor=' + this.hkh_1 + ', defaultGridSize=' + this.ikh_1 + ', defaultLevelBgColor=' + this.jkh_1 + ', defaultLevelHeight=' + this.kkh_1 + ', defaultLevelWidth=' + this.lkh_1 + ', defaultPivotX=' + this.mkh_1 + ', defaultPivotY=' + this.nkh_1 + ', defs=' + this.okh_1.toString() + ', exportTiled=' + this.pkh_1 + ', externalLevels=' + this.qkh_1 + ', flags=' + toString_0(this.rkh_1) + ', identifierStyle=' + this.skh_1.toString() + ', imageExportMode=' + this.tkh_1.toString() + ', jsonVersion=' + this.ukh_1 + ', levelNamePattern=' + this.vkh_1 + ', levels=' + toString_0(this.wkh_1) + ', minifyJSON=' + this.xkh_1 + ', nextUid=' + this.ykh_1 + ', pngFilePattern=' + this.zkh_1 + ', simplifiedExport=' + this.aki_1 + ', tutorialDesc=' + this.bki_1 + ', worldGridHeight=' + this.cki_1 + ', worldGridWidth=' + this.dki_1 + ', worldLayout=' + toString(this.eki_1) + ', worlds=' + toString_0(this.fki_1) + ')';
  }
  hashCode() {
    var result = this.dkh_1 == null ? 0 : this.dkh_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.ekh_1) | 0;
    result = imul(result, 31) + this.fkh_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.gkh_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.hkh_1) | 0;
    result = imul(result, 31) + this.ikh_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.jkh_1) | 0;
    result = imul(result, 31) + (this.kkh_1 == null ? 0 : this.kkh_1) | 0;
    result = imul(result, 31) + (this.lkh_1 == null ? 0 : this.lkh_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.mkh_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.nkh_1) | 0;
    result = imul(result, 31) + this.okh_1.hashCode() | 0;
    result = imul(result, 31) + getBooleanHashCode(this.pkh_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.qkh_1) | 0;
    result = imul(result, 31) + hashCode(this.rkh_1) | 0;
    result = imul(result, 31) + this.skh_1.hashCode() | 0;
    result = imul(result, 31) + this.tkh_1.hashCode() | 0;
    result = imul(result, 31) + getStringHashCode(this.ukh_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.vkh_1) | 0;
    result = imul(result, 31) + hashCode(this.wkh_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.xkh_1) | 0;
    result = imul(result, 31) + this.ykh_1 | 0;
    result = imul(result, 31) + (this.zkh_1 == null ? 0 : getStringHashCode(this.zkh_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.aki_1) | 0;
    result = imul(result, 31) + (this.bki_1 == null ? 0 : getStringHashCode(this.bki_1)) | 0;
    result = imul(result, 31) + (this.cki_1 == null ? 0 : this.cki_1) | 0;
    result = imul(result, 31) + (this.dki_1 == null ? 0 : this.dki_1) | 0;
    result = imul(result, 31) + (this.eki_1 == null ? 0 : this.eki_1.hashCode()) | 0;
    result = imul(result, 31) + hashCode(this.fki_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof LDTKJson))
      return false;
    var tmp0_other_with_cast = other instanceof LDTKJson ? other : THROW_CCE();
    if (!equals(this.dkh_1, tmp0_other_with_cast.dkh_1))
      return false;
    if (!equals(this.ekh_1, tmp0_other_with_cast.ekh_1))
      return false;
    if (!(this.fkh_1 === tmp0_other_with_cast.fkh_1))
      return false;
    if (!(this.gkh_1 === tmp0_other_with_cast.gkh_1))
      return false;
    if (!(this.hkh_1 === tmp0_other_with_cast.hkh_1))
      return false;
    if (!(this.ikh_1 === tmp0_other_with_cast.ikh_1))
      return false;
    if (!(this.jkh_1 === tmp0_other_with_cast.jkh_1))
      return false;
    if (!(this.kkh_1 == tmp0_other_with_cast.kkh_1))
      return false;
    if (!(this.lkh_1 == tmp0_other_with_cast.lkh_1))
      return false;
    if (!equals(this.mkh_1, tmp0_other_with_cast.mkh_1))
      return false;
    if (!equals(this.nkh_1, tmp0_other_with_cast.nkh_1))
      return false;
    if (!this.okh_1.equals(tmp0_other_with_cast.okh_1))
      return false;
    if (!(this.pkh_1 === tmp0_other_with_cast.pkh_1))
      return false;
    if (!(this.qkh_1 === tmp0_other_with_cast.qkh_1))
      return false;
    if (!equals(this.rkh_1, tmp0_other_with_cast.rkh_1))
      return false;
    if (!this.skh_1.equals(tmp0_other_with_cast.skh_1))
      return false;
    if (!this.tkh_1.equals(tmp0_other_with_cast.tkh_1))
      return false;
    if (!(this.ukh_1 === tmp0_other_with_cast.ukh_1))
      return false;
    if (!(this.vkh_1 === tmp0_other_with_cast.vkh_1))
      return false;
    if (!equals(this.wkh_1, tmp0_other_with_cast.wkh_1))
      return false;
    if (!(this.xkh_1 === tmp0_other_with_cast.xkh_1))
      return false;
    if (!(this.ykh_1 === tmp0_other_with_cast.ykh_1))
      return false;
    if (!(this.zkh_1 == tmp0_other_with_cast.zkh_1))
      return false;
    if (!(this.aki_1 === tmp0_other_with_cast.aki_1))
      return false;
    if (!(this.bki_1 == tmp0_other_with_cast.bki_1))
      return false;
    if (!(this.cki_1 == tmp0_other_with_cast.cki_1))
      return false;
    if (!(this.dki_1 == tmp0_other_with_cast.dki_1))
      return false;
    if (!equals(this.eki_1, tmp0_other_with_cast.eki_1))
      return false;
    if (!equals(this.fki_1, tmp0_other_with_cast.fki_1))
      return false;
    return true;
  }
  static gki(seen0, forcedRefs, appBuildID, backupLimit, backupOnSave, bgColor, defaultGridSize, defaultLevelBgColor, defaultLevelHeight, defaultLevelWidth, defaultPivotX, defaultPivotY, defs, exportTiled, externalLevels, flags, identifierStyle, imageExportMode, jsonVersion, levelNamePattern, levels, minifyJSON, nextUid, pngFilePattern, simplifiedExport, tutorialDesc, worldGridHeight, worldGridWidth, worldLayout, worlds, serializationConstructorMarker) {
    Companion_getInstance_30();
    if (!(281017982 === (281017982 & seen0))) {
      throwMissingFieldException(seen0, 281017982, $serializer_getInstance_15().ckh_1);
    }
    var $this = createThis(this);
    if (0 === (seen0 & 1))
      $this.dkh_1 = null;
    else
      $this.dkh_1 = forcedRefs;
    $this.ekh_1 = appBuildID;
    $this.fkh_1 = backupLimit;
    $this.gkh_1 = backupOnSave;
    $this.hkh_1 = bgColor;
    $this.ikh_1 = defaultGridSize;
    $this.jkh_1 = defaultLevelBgColor;
    if (0 === (seen0 & 128))
      $this.kkh_1 = null;
    else
      $this.kkh_1 = defaultLevelHeight;
    if (0 === (seen0 & 256))
      $this.lkh_1 = null;
    else
      $this.lkh_1 = defaultLevelWidth;
    $this.mkh_1 = defaultPivotX;
    $this.nkh_1 = defaultPivotY;
    $this.okh_1 = defs;
    $this.pkh_1 = exportTiled;
    $this.qkh_1 = externalLevels;
    $this.rkh_1 = flags;
    $this.skh_1 = identifierStyle;
    $this.tkh_1 = imageExportMode;
    $this.ukh_1 = jsonVersion;
    $this.vkh_1 = levelNamePattern;
    $this.wkh_1 = levels;
    $this.xkh_1 = minifyJSON;
    $this.ykh_1 = nextUid;
    if (0 === (seen0 & 4194304))
      $this.zkh_1 = null;
    else
      $this.zkh_1 = pngFilePattern;
    $this.aki_1 = simplifiedExport;
    if (0 === (seen0 & 16777216))
      $this.bki_1 = null;
    else
      $this.bki_1 = tutorialDesc;
    if (0 === (seen0 & 33554432))
      $this.cki_1 = null;
    else
      $this.cki_1 = worldGridHeight;
    if (0 === (seen0 & 67108864))
      $this.dki_1 = null;
    else
      $this.dki_1 = worldGridWidth;
    if (0 === (seen0 & 134217728))
      $this.eki_1 = null;
    else
      $this.eki_1 = worldLayout;
    $this.fki_1 = worlds;
    return $this;
  }
}
class Companion_28 {
  constructor() {
    Companion_instance_29 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.iki_1 = [null, null, new ArrayListSerializer($serializer_getInstance_21()), null, null, null, null, Companion_getInstance_41().ijz(), null, null, new ArrayListSerializer($serializer_getInstance()), null, null, new ArrayListSerializer($serializer_getInstance_14()), null, null, null, null, null, null, null];
  }
}
class $serializer_16 {
  constructor() {
    $serializer_instance_16 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.Level', this, 21);
    tmp0_serialDesc.fjp('__bgColor', false);
    tmp0_serialDesc.fjp('__bgPos', true);
    tmp0_serialDesc.fjp('__neighbours', false);
    tmp0_serialDesc.fjp('__smartColor', false);
    tmp0_serialDesc.fjp('bgColor', true);
    tmp0_serialDesc.fjp('bgPivotX', false);
    tmp0_serialDesc.fjp('bgPivotY', false);
    tmp0_serialDesc.fjp('bgPos', true);
    tmp0_serialDesc.fjp('bgRelPath', true);
    tmp0_serialDesc.fjp('externalRelPath', true);
    tmp0_serialDesc.fjp('fieldInstances', false);
    tmp0_serialDesc.fjp('identifier', false);
    tmp0_serialDesc.fjp('iid', false);
    tmp0_serialDesc.fjp('layerInstances', true);
    tmp0_serialDesc.fjp('pxHei', false);
    tmp0_serialDesc.fjp('pxWid', false);
    tmp0_serialDesc.fjp('uid', false);
    tmp0_serialDesc.fjp('useAutoIdentifier', false);
    tmp0_serialDesc.fjp('worldDepth', false);
    tmp0_serialDesc.fjp('worldX', false);
    tmp0_serialDesc.fjp('worldY', false);
    this.jki_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.jki_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = 0.0;
    var tmp10_local6 = 0.0;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp16_local12 = null;
    var tmp17_local13 = null;
    var tmp18_local14 = 0;
    var tmp19_local15 = 0;
    var tmp20_local16 = 0;
    var tmp21_local17 = false;
    var tmp22_local18 = 0;
    var tmp23_local19 = 0;
    var tmp24_local20 = 0;
    var tmp25_input = decoder.pjj(tmp0_desc);
    var tmp26_cached = Companion_getInstance_31().iki_1;
    if (tmp25_input.fjk()) {
      tmp4_local0 = tmp25_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp25_input.djk(tmp0_desc, 1, $serializer_getInstance_20(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp25_input.bjk(tmp0_desc, 2, tmp26_cached[2], tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp25_input.zjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp25_input.djk(tmp0_desc, 4, StringSerializer_getInstance(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp25_input.xjj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp25_input.xjj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp25_input.djk(tmp0_desc, 7, tmp26_cached[7], tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp25_input.djk(tmp0_desc, 8, StringSerializer_getInstance(), tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp25_input.djk(tmp0_desc, 9, StringSerializer_getInstance(), tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp25_input.bjk(tmp0_desc, 10, tmp26_cached[10], tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp25_input.zjj(tmp0_desc, 11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp25_input.zjj(tmp0_desc, 12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp25_input.djk(tmp0_desc, 13, tmp26_cached[13], tmp17_local13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp25_input.ujj(tmp0_desc, 14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp25_input.ujj(tmp0_desc, 15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp25_input.ujj(tmp0_desc, 16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp25_input.rjj(tmp0_desc, 17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp25_input.ujj(tmp0_desc, 18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp25_input.ujj(tmp0_desc, 19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp25_input.ujj(tmp0_desc, 20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp25_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp25_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp25_input.djk(tmp0_desc, 1, $serializer_getInstance_20(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp25_input.bjk(tmp0_desc, 2, tmp26_cached[2], tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp25_input.zjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp25_input.djk(tmp0_desc, 4, StringSerializer_getInstance(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp25_input.xjj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp25_input.xjj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp25_input.djk(tmp0_desc, 7, tmp26_cached[7], tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp25_input.djk(tmp0_desc, 8, StringSerializer_getInstance(), tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp25_input.djk(tmp0_desc, 9, StringSerializer_getInstance(), tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp25_input.bjk(tmp0_desc, 10, tmp26_cached[10], tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp25_input.zjj(tmp0_desc, 11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp25_input.zjj(tmp0_desc, 12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp25_input.djk(tmp0_desc, 13, tmp26_cached[13], tmp17_local13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp25_input.ujj(tmp0_desc, 14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp25_input.ujj(tmp0_desc, 15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp25_input.ujj(tmp0_desc, 16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp25_input.rjj(tmp0_desc, 17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp25_input.ujj(tmp0_desc, 18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp25_input.ujj(tmp0_desc, 19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp25_input.ujj(tmp0_desc, 20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp25_input.qjj(tmp0_desc);
    return Level.fkj(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, null);
  }
  ujf() {
    return this.jki_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_31().iki_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), get_nullable($serializer_getInstance_20()), tmp0_cached[2], StringSerializer_getInstance(), get_nullable(StringSerializer_getInstance()), DoubleSerializer_getInstance(), DoubleSerializer_getInstance(), get_nullable(tmp0_cached[7]), get_nullable(StringSerializer_getInstance()), get_nullable(StringSerializer_getInstance()), tmp0_cached[10], StringSerializer_getInstance(), StringSerializer_getInstance(), get_nullable(tmp0_cached[13]), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), BooleanSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class Level {
  toString() {
    return 'Level(bgColor=' + this.kki_1 + ', bgPos=' + toString(this.lki_1) + ', neighbours=' + toString_0(this.mki_1) + ', smartColor=' + this.nki_1 + ', levelBgColor=' + this.oki_1 + ', bgPivotX=' + this.pki_1 + ', bgPivotY=' + this.qki_1 + ', levelBgPos=' + toString(this.rki_1) + ', bgRelPath=' + this.ski_1 + ', externalRelPath=' + this.tki_1 + ', fieldInstances=' + toString_0(this.uki_1) + ', identifier=' + this.vki_1 + ', iid=' + this.wki_1 + ', layerInstances=' + toString(this.xki_1) + ', pxHei=' + this.yki_1 + ', pxWid=' + this.zki_1 + ', uid=' + this.akj_1 + ', useAutoIdentifier=' + this.bkj_1 + ', worldDepth=' + this.ckj_1 + ', worldX=' + this.dkj_1 + ', worldY=' + this.ekj_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.kki_1);
    result = imul(result, 31) + (this.lki_1 == null ? 0 : this.lki_1.hashCode()) | 0;
    result = imul(result, 31) + hashCode(this.mki_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.nki_1) | 0;
    result = imul(result, 31) + (this.oki_1 == null ? 0 : getStringHashCode(this.oki_1)) | 0;
    result = imul(result, 31) + getNumberHashCode(this.pki_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.qki_1) | 0;
    result = imul(result, 31) + (this.rki_1 == null ? 0 : this.rki_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ski_1 == null ? 0 : getStringHashCode(this.ski_1)) | 0;
    result = imul(result, 31) + (this.tki_1 == null ? 0 : getStringHashCode(this.tki_1)) | 0;
    result = imul(result, 31) + hashCode(this.uki_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.vki_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.wki_1) | 0;
    result = imul(result, 31) + (this.xki_1 == null ? 0 : hashCode(this.xki_1)) | 0;
    result = imul(result, 31) + this.yki_1 | 0;
    result = imul(result, 31) + this.zki_1 | 0;
    result = imul(result, 31) + this.akj_1 | 0;
    result = imul(result, 31) + getBooleanHashCode(this.bkj_1) | 0;
    result = imul(result, 31) + this.ckj_1 | 0;
    result = imul(result, 31) + this.dkj_1 | 0;
    result = imul(result, 31) + this.ekj_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Level))
      return false;
    var tmp0_other_with_cast = other instanceof Level ? other : THROW_CCE();
    if (!(this.kki_1 === tmp0_other_with_cast.kki_1))
      return false;
    if (!equals(this.lki_1, tmp0_other_with_cast.lki_1))
      return false;
    if (!equals(this.mki_1, tmp0_other_with_cast.mki_1))
      return false;
    if (!(this.nki_1 === tmp0_other_with_cast.nki_1))
      return false;
    if (!(this.oki_1 == tmp0_other_with_cast.oki_1))
      return false;
    if (!equals(this.pki_1, tmp0_other_with_cast.pki_1))
      return false;
    if (!equals(this.qki_1, tmp0_other_with_cast.qki_1))
      return false;
    if (!equals(this.rki_1, tmp0_other_with_cast.rki_1))
      return false;
    if (!(this.ski_1 == tmp0_other_with_cast.ski_1))
      return false;
    if (!(this.tki_1 == tmp0_other_with_cast.tki_1))
      return false;
    if (!equals(this.uki_1, tmp0_other_with_cast.uki_1))
      return false;
    if (!(this.vki_1 === tmp0_other_with_cast.vki_1))
      return false;
    if (!(this.wki_1 === tmp0_other_with_cast.wki_1))
      return false;
    if (!equals(this.xki_1, tmp0_other_with_cast.xki_1))
      return false;
    if (!(this.yki_1 === tmp0_other_with_cast.yki_1))
      return false;
    if (!(this.zki_1 === tmp0_other_with_cast.zki_1))
      return false;
    if (!(this.akj_1 === tmp0_other_with_cast.akj_1))
      return false;
    if (!(this.bkj_1 === tmp0_other_with_cast.bkj_1))
      return false;
    if (!(this.ckj_1 === tmp0_other_with_cast.ckj_1))
      return false;
    if (!(this.dkj_1 === tmp0_other_with_cast.dkj_1))
      return false;
    if (!(this.ekj_1 === tmp0_other_with_cast.ekj_1))
      return false;
    return true;
  }
  static fkj(seen0, bgColor, bgPos, neighbours, smartColor, levelBgColor, bgPivotX, bgPivotY, levelBgPos, bgRelPath, externalRelPath, fieldInstances, identifier, iid, layerInstances, pxHei, pxWid, uid, useAutoIdentifier, worldDepth, worldX, worldY, serializationConstructorMarker) {
    Companion_getInstance_31();
    if (!(2088045 === (2088045 & seen0))) {
      throwMissingFieldException(seen0, 2088045, $serializer_getInstance_16().jki_1);
    }
    var $this = createThis(this);
    $this.kki_1 = bgColor;
    if (0 === (seen0 & 2))
      $this.lki_1 = null;
    else
      $this.lki_1 = bgPos;
    $this.mki_1 = neighbours;
    $this.nki_1 = smartColor;
    if (0 === (seen0 & 16))
      $this.oki_1 = null;
    else
      $this.oki_1 = levelBgColor;
    $this.pki_1 = bgPivotX;
    $this.qki_1 = bgPivotY;
    if (0 === (seen0 & 128))
      $this.rki_1 = null;
    else
      $this.rki_1 = levelBgPos;
    if (0 === (seen0 & 256))
      $this.ski_1 = null;
    else
      $this.ski_1 = bgRelPath;
    if (0 === (seen0 & 512))
      $this.tki_1 = null;
    else
      $this.tki_1 = externalRelPath;
    $this.uki_1 = fieldInstances;
    $this.vki_1 = identifier;
    $this.wki_1 = iid;
    if (0 === (seen0 & 8192))
      $this.xki_1 = null;
    else
      $this.xki_1 = layerInstances;
    $this.yki_1 = pxHei;
    $this.zki_1 = pxWid;
    $this.akj_1 = uid;
    $this.bkj_1 = useAutoIdentifier;
    $this.ckj_1 = worldDepth;
    $this.dkj_1 = worldX;
    $this.ekj_1 = worldY;
    return $this;
  }
}
class Companion_29 {}
class $serializer_17 {
  constructor() {
    $serializer_instance_17 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.TileInstance', this, 5);
    tmp0_serialDesc.fjp('d', false);
    tmp0_serialDesc.fjp('f', false);
    tmp0_serialDesc.fjp('px', false);
    tmp0_serialDesc.fjp('src', false);
    tmp0_serialDesc.fjp('t', false);
    this.gkj_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.gkj_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = 0;
    var tmp9_input = decoder.pjj(tmp0_desc);
    if (tmp9_input.fjk()) {
      tmp4_local0 = tmp9_input.bjk(tmp0_desc, 0, IntArraySerializer_getInstance(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp9_input.bjk(tmp0_desc, 2, IntArraySerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp9_input.bjk(tmp0_desc, 3, IntArraySerializer_getInstance(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp9_input.ujj(tmp0_desc, 4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp9_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp9_input.bjk(tmp0_desc, 0, IntArraySerializer_getInstance(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp9_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp9_input.bjk(tmp0_desc, 2, IntArraySerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp9_input.bjk(tmp0_desc, 3, IntArraySerializer_getInstance(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp9_input.ujj(tmp0_desc, 4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp9_input.qjj(tmp0_desc);
    return TileInstance.mkj(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, null);
  }
  ujf() {
    return this.gkj_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntArraySerializer_getInstance(), IntSerializer_getInstance(), IntArraySerializer_getInstance(), IntArraySerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class TileInstance {
  toString() {
    return 'TileInstance(d=' + toString_0(this.hkj_1) + ', f=' + this.ikj_1 + ', px=' + toString_0(this.jkj_1) + ', src=' + toString_0(this.kkj_1) + ', t=' + this.lkj_1 + ')';
  }
  hashCode() {
    var result = hashCode(this.hkj_1);
    result = imul(result, 31) + this.ikj_1 | 0;
    result = imul(result, 31) + hashCode(this.jkj_1) | 0;
    result = imul(result, 31) + hashCode(this.kkj_1) | 0;
    result = imul(result, 31) + this.lkj_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof TileInstance))
      return false;
    var tmp0_other_with_cast = other instanceof TileInstance ? other : THROW_CCE();
    if (!equals(this.hkj_1, tmp0_other_with_cast.hkj_1))
      return false;
    if (!(this.ikj_1 === tmp0_other_with_cast.ikj_1))
      return false;
    if (!equals(this.jkj_1, tmp0_other_with_cast.jkj_1))
      return false;
    if (!equals(this.kkj_1, tmp0_other_with_cast.kkj_1))
      return false;
    if (!(this.lkj_1 === tmp0_other_with_cast.lkj_1))
      return false;
    return true;
  }
  static mkj(seen0, d, f, px, src, t, serializationConstructorMarker) {
    if (!(31 === (31 & seen0))) {
      throwMissingFieldException(seen0, 31, $serializer_getInstance_17().gkj_1);
    }
    var $this = createThis(this);
    $this.hkj_1 = d;
    $this.ikj_1 = f;
    $this.jkj_1 = px;
    $this.kkj_1 = src;
    $this.lkj_1 = t;
    return $this;
  }
}
class Companion_30 {}
class $serializer_18 {
  constructor() {
    $serializer_instance_18 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.ForcedRefs', this, 24);
    tmp0_serialDesc.fjp('AutoLayerRuleGroup', true);
    tmp0_serialDesc.fjp('AutoRuleDef', true);
    tmp0_serialDesc.fjp('Definitions', true);
    tmp0_serialDesc.fjp('EntityDef', true);
    tmp0_serialDesc.fjp('EntityInstance', true);
    tmp0_serialDesc.fjp('EntityReferenceInfos', true);
    tmp0_serialDesc.fjp('EnumDef', true);
    tmp0_serialDesc.fjp('EnumDefValues', true);
    tmp0_serialDesc.fjp('EnumTagValue', true);
    tmp0_serialDesc.fjp('FieldDef', true);
    tmp0_serialDesc.fjp('FieldInstance', true);
    tmp0_serialDesc.fjp('GridPoint', true);
    tmp0_serialDesc.fjp('IntGridValueDef', true);
    tmp0_serialDesc.fjp('IntGridValueInstance', true);
    tmp0_serialDesc.fjp('LayerDef', true);
    tmp0_serialDesc.fjp('LayerInstance', true);
    tmp0_serialDesc.fjp('Level', true);
    tmp0_serialDesc.fjp('LevelBgPosInfos', true);
    tmp0_serialDesc.fjp('NeighbourLevel', true);
    tmp0_serialDesc.fjp('Tile', true);
    tmp0_serialDesc.fjp('TileCustomMetadata', true);
    tmp0_serialDesc.fjp('TilesetDef', true);
    tmp0_serialDesc.fjp('TilesetRect', true);
    tmp0_serialDesc.fjp('World', true);
    this.nkj_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.nkj_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp16_local12 = null;
    var tmp17_local13 = null;
    var tmp18_local14 = null;
    var tmp19_local15 = null;
    var tmp20_local16 = null;
    var tmp21_local17 = null;
    var tmp22_local18 = null;
    var tmp23_local19 = null;
    var tmp24_local20 = null;
    var tmp25_local21 = null;
    var tmp26_local22 = null;
    var tmp27_local23 = null;
    var tmp28_input = decoder.pjj(tmp0_desc);
    if (tmp28_input.fjk()) {
      tmp4_local0 = tmp28_input.djk(tmp0_desc, 0, $serializer_getInstance_9(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp28_input.djk(tmp0_desc, 1, $serializer_getInstance_13(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp28_input.djk(tmp0_desc, 2, $serializer_getInstance_3(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp28_input.djk(tmp0_desc, 3, $serializer_getInstance_2(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp28_input.djk(tmp0_desc, 4, $serializer_getInstance_0(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp28_input.djk(tmp0_desc, 5, $serializer_getInstance_22(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp28_input.djk(tmp0_desc, 6, $serializer_getInstance_5(), tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp28_input.djk(tmp0_desc, 7, $serializer_getInstance_8(), tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp28_input.djk(tmp0_desc, 8, $serializer_getInstance_12(), tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp28_input.djk(tmp0_desc, 9, $serializer_getInstance_1(), tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp28_input.djk(tmp0_desc, 10, $serializer_getInstance(), tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp28_input.djk(tmp0_desc, 11, $serializer_getInstance_23(), tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
      tmp16_local12 = tmp28_input.djk(tmp0_desc, 12, $serializer_getInstance_10(), tmp16_local12);
      tmp3_bitMask0 = tmp3_bitMask0 | 4096;
      tmp17_local13 = tmp28_input.djk(tmp0_desc, 13, $serializer_getInstance_24(), tmp17_local13);
      tmp3_bitMask0 = tmp3_bitMask0 | 8192;
      tmp18_local14 = tmp28_input.djk(tmp0_desc, 14, $serializer_getInstance_6(), tmp18_local14);
      tmp3_bitMask0 = tmp3_bitMask0 | 16384;
      tmp19_local15 = tmp28_input.djk(tmp0_desc, 15, $serializer_getInstance_14(), tmp19_local15);
      tmp3_bitMask0 = tmp3_bitMask0 | 32768;
      tmp20_local16 = tmp28_input.djk(tmp0_desc, 16, $serializer_getInstance_16(), tmp20_local16);
      tmp3_bitMask0 = tmp3_bitMask0 | 65536;
      tmp21_local17 = tmp28_input.djk(tmp0_desc, 17, $serializer_getInstance_20(), tmp21_local17);
      tmp3_bitMask0 = tmp3_bitMask0 | 131072;
      tmp22_local18 = tmp28_input.djk(tmp0_desc, 18, $serializer_getInstance_21(), tmp22_local18);
      tmp3_bitMask0 = tmp3_bitMask0 | 262144;
      tmp23_local19 = tmp28_input.djk(tmp0_desc, 19, $serializer_getInstance_17(), tmp23_local19);
      tmp3_bitMask0 = tmp3_bitMask0 | 524288;
      tmp24_local20 = tmp28_input.djk(tmp0_desc, 20, $serializer_getInstance_11(), tmp24_local20);
      tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
      tmp25_local21 = tmp28_input.djk(tmp0_desc, 21, $serializer_getInstance_7(), tmp25_local21);
      tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
      tmp26_local22 = tmp28_input.djk(tmp0_desc, 22, $serializer_getInstance_4(), tmp26_local22);
      tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
      tmp27_local23 = tmp28_input.djk(tmp0_desc, 23, $serializer_getInstance_19(), tmp27_local23);
      tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp28_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp28_input.djk(tmp0_desc, 0, $serializer_getInstance_9(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp28_input.djk(tmp0_desc, 1, $serializer_getInstance_13(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp28_input.djk(tmp0_desc, 2, $serializer_getInstance_3(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp28_input.djk(tmp0_desc, 3, $serializer_getInstance_2(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp28_input.djk(tmp0_desc, 4, $serializer_getInstance_0(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp28_input.djk(tmp0_desc, 5, $serializer_getInstance_22(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp28_input.djk(tmp0_desc, 6, $serializer_getInstance_5(), tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp28_input.djk(tmp0_desc, 7, $serializer_getInstance_8(), tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp28_input.djk(tmp0_desc, 8, $serializer_getInstance_12(), tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp28_input.djk(tmp0_desc, 9, $serializer_getInstance_1(), tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp28_input.djk(tmp0_desc, 10, $serializer_getInstance(), tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp28_input.djk(tmp0_desc, 11, $serializer_getInstance_23(), tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          case 12:
            tmp16_local12 = tmp28_input.djk(tmp0_desc, 12, $serializer_getInstance_10(), tmp16_local12);
            tmp3_bitMask0 = tmp3_bitMask0 | 4096;
            break;
          case 13:
            tmp17_local13 = tmp28_input.djk(tmp0_desc, 13, $serializer_getInstance_24(), tmp17_local13);
            tmp3_bitMask0 = tmp3_bitMask0 | 8192;
            break;
          case 14:
            tmp18_local14 = tmp28_input.djk(tmp0_desc, 14, $serializer_getInstance_6(), tmp18_local14);
            tmp3_bitMask0 = tmp3_bitMask0 | 16384;
            break;
          case 15:
            tmp19_local15 = tmp28_input.djk(tmp0_desc, 15, $serializer_getInstance_14(), tmp19_local15);
            tmp3_bitMask0 = tmp3_bitMask0 | 32768;
            break;
          case 16:
            tmp20_local16 = tmp28_input.djk(tmp0_desc, 16, $serializer_getInstance_16(), tmp20_local16);
            tmp3_bitMask0 = tmp3_bitMask0 | 65536;
            break;
          case 17:
            tmp21_local17 = tmp28_input.djk(tmp0_desc, 17, $serializer_getInstance_20(), tmp21_local17);
            tmp3_bitMask0 = tmp3_bitMask0 | 131072;
            break;
          case 18:
            tmp22_local18 = tmp28_input.djk(tmp0_desc, 18, $serializer_getInstance_21(), tmp22_local18);
            tmp3_bitMask0 = tmp3_bitMask0 | 262144;
            break;
          case 19:
            tmp23_local19 = tmp28_input.djk(tmp0_desc, 19, $serializer_getInstance_17(), tmp23_local19);
            tmp3_bitMask0 = tmp3_bitMask0 | 524288;
            break;
          case 20:
            tmp24_local20 = tmp28_input.djk(tmp0_desc, 20, $serializer_getInstance_11(), tmp24_local20);
            tmp3_bitMask0 = tmp3_bitMask0 | 1048576;
            break;
          case 21:
            tmp25_local21 = tmp28_input.djk(tmp0_desc, 21, $serializer_getInstance_7(), tmp25_local21);
            tmp3_bitMask0 = tmp3_bitMask0 | 2097152;
            break;
          case 22:
            tmp26_local22 = tmp28_input.djk(tmp0_desc, 22, $serializer_getInstance_4(), tmp26_local22);
            tmp3_bitMask0 = tmp3_bitMask0 | 4194304;
            break;
          case 23:
            tmp27_local23 = tmp28_input.djk(tmp0_desc, 23, $serializer_getInstance_19(), tmp27_local23);
            tmp3_bitMask0 = tmp3_bitMask0 | 8388608;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp28_input.qjj(tmp0_desc);
    return ForcedRefs.mkk(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, tmp16_local12, tmp17_local13, tmp18_local14, tmp19_local15, tmp20_local16, tmp21_local17, tmp22_local18, tmp23_local19, tmp24_local20, tmp25_local21, tmp26_local22, tmp27_local23, null);
  }
  ujf() {
    return this.nkj_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [get_nullable($serializer_getInstance_9()), get_nullable($serializer_getInstance_13()), get_nullable($serializer_getInstance_3()), get_nullable($serializer_getInstance_2()), get_nullable($serializer_getInstance_0()), get_nullable($serializer_getInstance_22()), get_nullable($serializer_getInstance_5()), get_nullable($serializer_getInstance_8()), get_nullable($serializer_getInstance_12()), get_nullable($serializer_getInstance_1()), get_nullable($serializer_getInstance()), get_nullable($serializer_getInstance_23()), get_nullable($serializer_getInstance_10()), get_nullable($serializer_getInstance_24()), get_nullable($serializer_getInstance_6()), get_nullable($serializer_getInstance_14()), get_nullable($serializer_getInstance_16()), get_nullable($serializer_getInstance_20()), get_nullable($serializer_getInstance_21()), get_nullable($serializer_getInstance_17()), get_nullable($serializer_getInstance_11()), get_nullable($serializer_getInstance_7()), get_nullable($serializer_getInstance_4()), get_nullable($serializer_getInstance_19())];
  }
}
class ForcedRefs {
  constructor(autoLayerRuleGroup, autoRuleDef, definitions, entityDef, entityInstance, entityReferenceInfos, enumDef, enumDefValues, enumTagValue, fieldDef, fieldInstance, gridPoint, intGridValueDef, intGridValueInstance, layerDef, layerInstance, level, levelBgPosInfos, neighbourLevel, tile, tileCustomMetadata, tilesetDef, tilesetRect, world) {
    autoLayerRuleGroup = autoLayerRuleGroup === VOID ? null : autoLayerRuleGroup;
    autoRuleDef = autoRuleDef === VOID ? null : autoRuleDef;
    definitions = definitions === VOID ? null : definitions;
    entityDef = entityDef === VOID ? null : entityDef;
    entityInstance = entityInstance === VOID ? null : entityInstance;
    entityReferenceInfos = entityReferenceInfos === VOID ? null : entityReferenceInfos;
    enumDef = enumDef === VOID ? null : enumDef;
    enumDefValues = enumDefValues === VOID ? null : enumDefValues;
    enumTagValue = enumTagValue === VOID ? null : enumTagValue;
    fieldDef = fieldDef === VOID ? null : fieldDef;
    fieldInstance = fieldInstance === VOID ? null : fieldInstance;
    gridPoint = gridPoint === VOID ? null : gridPoint;
    intGridValueDef = intGridValueDef === VOID ? null : intGridValueDef;
    intGridValueInstance = intGridValueInstance === VOID ? null : intGridValueInstance;
    layerDef = layerDef === VOID ? null : layerDef;
    layerInstance = layerInstance === VOID ? null : layerInstance;
    level = level === VOID ? null : level;
    levelBgPosInfos = levelBgPosInfos === VOID ? null : levelBgPosInfos;
    neighbourLevel = neighbourLevel === VOID ? null : neighbourLevel;
    tile = tile === VOID ? null : tile;
    tileCustomMetadata = tileCustomMetadata === VOID ? null : tileCustomMetadata;
    tilesetDef = tilesetDef === VOID ? null : tilesetDef;
    tilesetRect = tilesetRect === VOID ? null : tilesetRect;
    world = world === VOID ? null : world;
    this.okj_1 = autoLayerRuleGroup;
    this.pkj_1 = autoRuleDef;
    this.qkj_1 = definitions;
    this.rkj_1 = entityDef;
    this.skj_1 = entityInstance;
    this.tkj_1 = entityReferenceInfos;
    this.ukj_1 = enumDef;
    this.vkj_1 = enumDefValues;
    this.wkj_1 = enumTagValue;
    this.xkj_1 = fieldDef;
    this.ykj_1 = fieldInstance;
    this.zkj_1 = gridPoint;
    this.akk_1 = intGridValueDef;
    this.bkk_1 = intGridValueInstance;
    this.ckk_1 = layerDef;
    this.dkk_1 = layerInstance;
    this.ekk_1 = level;
    this.fkk_1 = levelBgPosInfos;
    this.gkk_1 = neighbourLevel;
    this.hkk_1 = tile;
    this.ikk_1 = tileCustomMetadata;
    this.jkk_1 = tilesetDef;
    this.kkk_1 = tilesetRect;
    this.lkk_1 = world;
  }
  toString() {
    return 'ForcedRefs(autoLayerRuleGroup=' + toString(this.okj_1) + ', autoRuleDef=' + toString(this.pkj_1) + ', definitions=' + toString(this.qkj_1) + ', entityDef=' + toString(this.rkj_1) + ', entityInstance=' + toString(this.skj_1) + ', entityReferenceInfos=' + toString(this.tkj_1) + ', enumDef=' + toString(this.ukj_1) + ', enumDefValues=' + toString(this.vkj_1) + ', enumTagValue=' + toString(this.wkj_1) + ', fieldDef=' + toString(this.xkj_1) + ', fieldInstance=' + toString(this.ykj_1) + ', gridPoint=' + toString(this.zkj_1) + ', intGridValueDef=' + toString(this.akk_1) + ', intGridValueInstance=' + toString(this.bkk_1) + ', layerDef=' + toString(this.ckk_1) + ', layerInstance=' + toString(this.dkk_1) + ', level=' + toString(this.ekk_1) + ', levelBgPosInfos=' + toString(this.fkk_1) + ', neighbourLevel=' + toString(this.gkk_1) + ', tile=' + toString(this.hkk_1) + ', tileCustomMetadata=' + toString(this.ikk_1) + ', tilesetDef=' + toString(this.jkk_1) + ', tilesetRect=' + toString(this.kkk_1) + ', world=' + toString(this.lkk_1) + ')';
  }
  hashCode() {
    var result = this.okj_1 == null ? 0 : this.okj_1.hashCode();
    result = imul(result, 31) + (this.pkj_1 == null ? 0 : this.pkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.qkj_1 == null ? 0 : this.qkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.rkj_1 == null ? 0 : this.rkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.skj_1 == null ? 0 : this.skj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.tkj_1 == null ? 0 : this.tkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ukj_1 == null ? 0 : this.ukj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.vkj_1 == null ? 0 : this.vkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.wkj_1 == null ? 0 : this.wkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.xkj_1 == null ? 0 : this.xkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ykj_1 == null ? 0 : this.ykj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.zkj_1 == null ? 0 : this.zkj_1.hashCode()) | 0;
    result = imul(result, 31) + (this.akk_1 == null ? 0 : this.akk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.bkk_1 == null ? 0 : this.bkk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ckk_1 == null ? 0 : this.ckk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.dkk_1 == null ? 0 : this.dkk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ekk_1 == null ? 0 : this.ekk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.fkk_1 == null ? 0 : this.fkk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.gkk_1 == null ? 0 : this.gkk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.hkk_1 == null ? 0 : this.hkk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.ikk_1 == null ? 0 : this.ikk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.jkk_1 == null ? 0 : this.jkk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.kkk_1 == null ? 0 : this.kkk_1.hashCode()) | 0;
    result = imul(result, 31) + (this.lkk_1 == null ? 0 : this.lkk_1.hashCode()) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ForcedRefs))
      return false;
    var tmp0_other_with_cast = other instanceof ForcedRefs ? other : THROW_CCE();
    if (!equals(this.okj_1, tmp0_other_with_cast.okj_1))
      return false;
    if (!equals(this.pkj_1, tmp0_other_with_cast.pkj_1))
      return false;
    if (!equals(this.qkj_1, tmp0_other_with_cast.qkj_1))
      return false;
    if (!equals(this.rkj_1, tmp0_other_with_cast.rkj_1))
      return false;
    if (!equals(this.skj_1, tmp0_other_with_cast.skj_1))
      return false;
    if (!equals(this.tkj_1, tmp0_other_with_cast.tkj_1))
      return false;
    if (!equals(this.ukj_1, tmp0_other_with_cast.ukj_1))
      return false;
    if (!equals(this.vkj_1, tmp0_other_with_cast.vkj_1))
      return false;
    if (!equals(this.wkj_1, tmp0_other_with_cast.wkj_1))
      return false;
    if (!equals(this.xkj_1, tmp0_other_with_cast.xkj_1))
      return false;
    if (!equals(this.ykj_1, tmp0_other_with_cast.ykj_1))
      return false;
    if (!equals(this.zkj_1, tmp0_other_with_cast.zkj_1))
      return false;
    if (!equals(this.akk_1, tmp0_other_with_cast.akk_1))
      return false;
    if (!equals(this.bkk_1, tmp0_other_with_cast.bkk_1))
      return false;
    if (!equals(this.ckk_1, tmp0_other_with_cast.ckk_1))
      return false;
    if (!equals(this.dkk_1, tmp0_other_with_cast.dkk_1))
      return false;
    if (!equals(this.ekk_1, tmp0_other_with_cast.ekk_1))
      return false;
    if (!equals(this.fkk_1, tmp0_other_with_cast.fkk_1))
      return false;
    if (!equals(this.gkk_1, tmp0_other_with_cast.gkk_1))
      return false;
    if (!equals(this.hkk_1, tmp0_other_with_cast.hkk_1))
      return false;
    if (!equals(this.ikk_1, tmp0_other_with_cast.ikk_1))
      return false;
    if (!equals(this.jkk_1, tmp0_other_with_cast.jkk_1))
      return false;
    if (!equals(this.kkk_1, tmp0_other_with_cast.kkk_1))
      return false;
    if (!equals(this.lkk_1, tmp0_other_with_cast.lkk_1))
      return false;
    return true;
  }
  static mkk(seen0, autoLayerRuleGroup, autoRuleDef, definitions, entityDef, entityInstance, entityReferenceInfos, enumDef, enumDefValues, enumTagValue, fieldDef, fieldInstance, gridPoint, intGridValueDef, intGridValueInstance, layerDef, layerInstance, level, levelBgPosInfos, neighbourLevel, tile, tileCustomMetadata, tilesetDef, tilesetRect, world, serializationConstructorMarker) {
    if (!(0 === (0 & seen0))) {
      throwMissingFieldException(seen0, 0, $serializer_getInstance_18().nkj_1);
    }
    var $this = createThis(this);
    if (0 === (seen0 & 1))
      $this.okj_1 = null;
    else
      $this.okj_1 = autoLayerRuleGroup;
    if (0 === (seen0 & 2))
      $this.pkj_1 = null;
    else
      $this.pkj_1 = autoRuleDef;
    if (0 === (seen0 & 4))
      $this.qkj_1 = null;
    else
      $this.qkj_1 = definitions;
    if (0 === (seen0 & 8))
      $this.rkj_1 = null;
    else
      $this.rkj_1 = entityDef;
    if (0 === (seen0 & 16))
      $this.skj_1 = null;
    else
      $this.skj_1 = entityInstance;
    if (0 === (seen0 & 32))
      $this.tkj_1 = null;
    else
      $this.tkj_1 = entityReferenceInfos;
    if (0 === (seen0 & 64))
      $this.ukj_1 = null;
    else
      $this.ukj_1 = enumDef;
    if (0 === (seen0 & 128))
      $this.vkj_1 = null;
    else
      $this.vkj_1 = enumDefValues;
    if (0 === (seen0 & 256))
      $this.wkj_1 = null;
    else
      $this.wkj_1 = enumTagValue;
    if (0 === (seen0 & 512))
      $this.xkj_1 = null;
    else
      $this.xkj_1 = fieldDef;
    if (0 === (seen0 & 1024))
      $this.ykj_1 = null;
    else
      $this.ykj_1 = fieldInstance;
    if (0 === (seen0 & 2048))
      $this.zkj_1 = null;
    else
      $this.zkj_1 = gridPoint;
    if (0 === (seen0 & 4096))
      $this.akk_1 = null;
    else
      $this.akk_1 = intGridValueDef;
    if (0 === (seen0 & 8192))
      $this.bkk_1 = null;
    else
      $this.bkk_1 = intGridValueInstance;
    if (0 === (seen0 & 16384))
      $this.ckk_1 = null;
    else
      $this.ckk_1 = layerDef;
    if (0 === (seen0 & 32768))
      $this.dkk_1 = null;
    else
      $this.dkk_1 = layerInstance;
    if (0 === (seen0 & 65536))
      $this.ekk_1 = null;
    else
      $this.ekk_1 = level;
    if (0 === (seen0 & 131072))
      $this.fkk_1 = null;
    else
      $this.fkk_1 = levelBgPosInfos;
    if (0 === (seen0 & 262144))
      $this.gkk_1 = null;
    else
      $this.gkk_1 = neighbourLevel;
    if (0 === (seen0 & 524288))
      $this.hkk_1 = null;
    else
      $this.hkk_1 = tile;
    if (0 === (seen0 & 1048576))
      $this.ikk_1 = null;
    else
      $this.ikk_1 = tileCustomMetadata;
    if (0 === (seen0 & 2097152))
      $this.jkk_1 = null;
    else
      $this.jkk_1 = tilesetDef;
    if (0 === (seen0 & 4194304))
      $this.kkk_1 = null;
    else
      $this.kkk_1 = tilesetRect;
    if (0 === (seen0 & 8388608))
      $this.lkk_1 = null;
    else
      $this.lkk_1 = world;
    return $this;
  }
}
class Companion_31 {
  constructor() {
    Companion_instance_32 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.xkg_1 = lazy(tmp_0, Flag$Companion$_anonymous__vfldct);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.Flag', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'DiscardPreCsvIntGrid':
        tmp = Flag_DiscardPreCSVIntGrid_getInstance();
        break;
      case 'ExportPreCsvIntGridFormat':
        tmp = Flag_ExportPreCSVIntGridFormat_getInstance();
        break;
      case 'IgnoreBackupSuggest':
        tmp = Flag_IgnoreBackupSuggest_getInstance();
        break;
      case 'MultiWorlds':
        tmp = Flag_MultiWorlds_getInstance();
        break;
      case 'PrependIndexToLevelFileNames':
        tmp = Flag_PrependIndexToLevelFileNames_getInstance();
        break;
      case 'UseMultilinesType':
        tmp = Flag_UseMultilinesType_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('Flag could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_11(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class Flag extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.pkk_1 = value;
  }
}
class Companion_32 {
  constructor() {
    Companion_instance_33 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.ykg_1 = lazy(tmp_0, IdentifierStyle$Companion$_anonymous__e7aoxl);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.IdentifierStyle', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Capitalize':
        tmp = IdentifierStyle_Capitalize_getInstance();
        break;
      case 'Free':
        tmp = IdentifierStyle_Free_getInstance();
        break;
      case 'Lowercase':
        tmp = IdentifierStyle_Lowercase_getInstance();
        break;
      case 'Uppercase':
        tmp = IdentifierStyle_Uppercase_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('IdentifierStyle could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_12(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class IdentifierStyle extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.skk_1 = value;
  }
}
class Companion_33 {
  constructor() {
    Companion_instance_34 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.zkg_1 = lazy(tmp_0, ImageExportMode$Companion$_anonymous__oljsbh);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.ImageExportMode', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'LayersAndLevels':
        tmp = ImageExportMode_LayersAndLevels_getInstance();
        break;
      case 'None':
        tmp = ImageExportMode_None_getInstance();
        break;
      case 'OneImagePerLayer':
        tmp = ImageExportMode_OneImagePerLayer_getInstance();
        break;
      case 'OneImagePerLevel':
        tmp = ImageExportMode_OneImagePerLevel_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('ImageExportMode could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_13(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class ImageExportMode extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.vkk_1 = value;
  }
}
class Companion_34 {
  constructor() {
    Companion_instance_35 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.akh_1 = lazy(tmp_0, WorldLayout$Companion$_anonymous__u3nwqb);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.WorldLayout', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Free':
        tmp = WorldLayout_Free_getInstance();
        break;
      case 'GridVania':
        tmp = WorldLayout_GridVania_getInstance();
        break;
      case 'LinearHorizontal':
        tmp = WorldLayout_LinearHorizontal_getInstance();
        break;
      case 'LinearVertical':
        tmp = WorldLayout_LinearVertical_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('WorldLayout could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_14(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class WorldLayout extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.ykk_1 = value;
  }
}
class Companion_35 {
  constructor() {
    Companion_instance_36 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.zkk_1 = [null, null, null, null, new ArrayListSerializer($serializer_getInstance_16()), null, null, Companion_getInstance_37().ijz()];
  }
}
class $serializer_19 {
  constructor() {
    $serializer_instance_19 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.World', this, 8);
    tmp0_serialDesc.fjp('defaultLevelHeight', false);
    tmp0_serialDesc.fjp('defaultLevelWidth', false);
    tmp0_serialDesc.fjp('identifier', false);
    tmp0_serialDesc.fjp('iid', false);
    tmp0_serialDesc.fjp('levels', false);
    tmp0_serialDesc.fjp('worldGridHeight', false);
    tmp0_serialDesc.fjp('worldGridWidth', false);
    tmp0_serialDesc.fjp('worldLayout', true);
    this.akl_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.akl_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = 0;
    var tmp10_local6 = 0;
    var tmp11_local7 = null;
    var tmp12_input = decoder.pjj(tmp0_desc);
    var tmp13_cached = Companion_getInstance_38().zkk_1;
    if (tmp12_input.fjk()) {
      tmp4_local0 = tmp12_input.ujj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp12_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp12_input.zjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp12_input.zjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp12_input.bjk(tmp0_desc, 4, tmp13_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp12_input.ujj(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp12_input.ujj(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, tmp13_cached[7], tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp12_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp12_input.ujj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp12_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp12_input.zjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp12_input.zjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp12_input.bjk(tmp0_desc, 4, tmp13_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp12_input.ujj(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp12_input.ujj(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp12_input.djk(tmp0_desc, 7, tmp13_cached[7], tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp12_input.qjj(tmp0_desc);
    return World.jkl(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, null);
  }
  ujf() {
    return this.akl_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_38().zkk_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntSerializer_getInstance(), IntSerializer_getInstance(), StringSerializer_getInstance(), StringSerializer_getInstance(), tmp0_cached[4], IntSerializer_getInstance(), IntSerializer_getInstance(), get_nullable(tmp0_cached[7])];
  }
}
class World {
  toString() {
    return 'World(defaultLevelHeight=' + this.bkl_1 + ', defaultLevelWidth=' + this.ckl_1 + ', identifier=' + this.dkl_1 + ', iid=' + this.ekl_1 + ', levels=' + toString_0(this.fkl_1) + ', worldGridHeight=' + this.gkl_1 + ', worldGridWidth=' + this.hkl_1 + ', worldLayout=' + toString(this.ikl_1) + ')';
  }
  hashCode() {
    var result = this.bkl_1;
    result = imul(result, 31) + this.ckl_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.dkl_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.ekl_1) | 0;
    result = imul(result, 31) + hashCode(this.fkl_1) | 0;
    result = imul(result, 31) + this.gkl_1 | 0;
    result = imul(result, 31) + this.hkl_1 | 0;
    result = imul(result, 31) + (this.ikl_1 == null ? 0 : this.ikl_1.hashCode()) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof World))
      return false;
    var tmp0_other_with_cast = other instanceof World ? other : THROW_CCE();
    if (!(this.bkl_1 === tmp0_other_with_cast.bkl_1))
      return false;
    if (!(this.ckl_1 === tmp0_other_with_cast.ckl_1))
      return false;
    if (!(this.dkl_1 === tmp0_other_with_cast.dkl_1))
      return false;
    if (!(this.ekl_1 === tmp0_other_with_cast.ekl_1))
      return false;
    if (!equals(this.fkl_1, tmp0_other_with_cast.fkl_1))
      return false;
    if (!(this.gkl_1 === tmp0_other_with_cast.gkl_1))
      return false;
    if (!(this.hkl_1 === tmp0_other_with_cast.hkl_1))
      return false;
    if (!equals(this.ikl_1, tmp0_other_with_cast.ikl_1))
      return false;
    return true;
  }
  static jkl(seen0, defaultLevelHeight, defaultLevelWidth, identifier, iid, levels, worldGridHeight, worldGridWidth, worldLayout, serializationConstructorMarker) {
    Companion_getInstance_38();
    if (!(127 === (127 & seen0))) {
      throwMissingFieldException(seen0, 127, $serializer_getInstance_19().akl_1);
    }
    var $this = createThis(this);
    $this.bkl_1 = defaultLevelHeight;
    $this.ckl_1 = defaultLevelWidth;
    $this.dkl_1 = identifier;
    $this.ekl_1 = iid;
    $this.fkl_1 = levels;
    $this.gkl_1 = worldGridHeight;
    $this.hkl_1 = worldGridWidth;
    if (0 === (seen0 & 128))
      $this.ikl_1 = null;
    else
      $this.ikl_1 = worldLayout;
    return $this;
  }
}
class Companion_36 {
  constructor() {
    Companion_instance_37 = this;
    var tmp = this;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp.kkl_1 = [new ArrayListSerializer(DoubleSerializer_getInstance()), new ArrayListSerializer(DoubleSerializer_getInstance()), null];
  }
}
class $serializer_20 {
  constructor() {
    $serializer_instance_20 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.LevelBackgroundPosition', this, 3);
    tmp0_serialDesc.fjp('cropRect', false);
    tmp0_serialDesc.fjp('scale', false);
    tmp0_serialDesc.fjp('topLeftPx', false);
    this.lkl_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.lkl_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_input = decoder.pjj(tmp0_desc);
    var tmp8_cached = Companion_getInstance_39().kkl_1;
    if (tmp7_input.fjk()) {
      tmp4_local0 = tmp7_input.bjk(tmp0_desc, 0, tmp8_cached[0], tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp7_input.bjk(tmp0_desc, 1, tmp8_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp7_input.bjk(tmp0_desc, 2, IntArraySerializer_getInstance(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp7_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp7_input.bjk(tmp0_desc, 0, tmp8_cached[0], tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp7_input.bjk(tmp0_desc, 1, tmp8_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp7_input.bjk(tmp0_desc, 2, IntArraySerializer_getInstance(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp7_input.qjj(tmp0_desc);
    return LevelBackgroundPosition.pkl(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, null);
  }
  ujf() {
    return this.lkl_1;
  }
  ujp() {
    var tmp0_cached = Companion_getInstance_39().kkl_1;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [tmp0_cached[0], tmp0_cached[1], IntArraySerializer_getInstance()];
  }
}
class LevelBackgroundPosition {
  toString() {
    return 'LevelBackgroundPosition(cropRect=' + toString_0(this.mkl_1) + ', scale=' + toString_0(this.nkl_1) + ', topLeftPx=' + toString_0(this.okl_1) + ')';
  }
  hashCode() {
    var result = hashCode(this.mkl_1);
    result = imul(result, 31) + hashCode(this.nkl_1) | 0;
    result = imul(result, 31) + hashCode(this.okl_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof LevelBackgroundPosition))
      return false;
    var tmp0_other_with_cast = other instanceof LevelBackgroundPosition ? other : THROW_CCE();
    if (!equals(this.mkl_1, tmp0_other_with_cast.mkl_1))
      return false;
    if (!equals(this.nkl_1, tmp0_other_with_cast.nkl_1))
      return false;
    if (!equals(this.okl_1, tmp0_other_with_cast.okl_1))
      return false;
    return true;
  }
  static pkl(seen0, cropRect, scale, topLeftPx, serializationConstructorMarker) {
    Companion_getInstance_39();
    if (!(7 === (7 & seen0))) {
      throwMissingFieldException(seen0, 7, $serializer_getInstance_20().lkl_1);
    }
    var $this = createThis(this);
    $this.mkl_1 = cropRect;
    $this.nkl_1 = scale;
    $this.okl_1 = topLeftPx;
    return $this;
  }
}
class Companion_37 {}
class $serializer_21 {
  constructor() {
    $serializer_instance_21 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.NeighbourLevel', this, 2);
    tmp0_serialDesc.fjp('dir', false);
    tmp0_serialDesc.fjp('levelIid', false);
    this.qkl_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.qkl_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_input = decoder.pjj(tmp0_desc);
    if (tmp6_input.fjk()) {
      tmp4_local0 = tmp6_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.zjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.zjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp6_input.qjj(tmp0_desc);
    return NeighbourLevel.tkl(tmp3_bitMask0, tmp4_local0, tmp5_local1, null);
  }
  ujf() {
    return this.qkl_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), StringSerializer_getInstance()];
  }
}
class NeighbourLevel {
  toString() {
    return 'NeighbourLevel(dir=' + this.rkl_1 + ', levelIid=' + this.skl_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.rkl_1);
    result = imul(result, 31) + getStringHashCode(this.skl_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof NeighbourLevel))
      return false;
    var tmp0_other_with_cast = other instanceof NeighbourLevel ? other : THROW_CCE();
    if (!(this.rkl_1 === tmp0_other_with_cast.rkl_1))
      return false;
    if (!(this.skl_1 === tmp0_other_with_cast.skl_1))
      return false;
    return true;
  }
  static tkl(seen0, dir, levelIid, serializationConstructorMarker) {
    if (!(3 === (3 & seen0))) {
      throwMissingFieldException(seen0, 3, $serializer_getInstance_21().qkl_1);
    }
    var $this = createThis(this);
    $this.rkl_1 = dir;
    $this.skl_1 = levelIid;
    return $this;
  }
}
class Companion_38 {
  constructor() {
    Companion_instance_39 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.hki_1 = lazy(tmp_0, BgPos$Companion$_anonymous__iejcu8);
  }
  ujf() {
    return PrimitiveSerialDescriptor('com.soywiz.korge.ldtk.BgPos', STRING_getInstance());
  }
  vjf(decoder) {
    var value = decoder.kjj();
    var tmp;
    switch (value) {
      case 'Contain':
        tmp = BgPos_Contain_getInstance();
        break;
      case 'Cover':
        tmp = BgPos_Cover_getInstance();
        break;
      case 'CoverDirty':
        tmp = BgPos_CoverDirty_getInstance();
        break;
      case 'Unscaled':
        tmp = BgPos_Unscaled_getInstance();
        break;
      default:
        throw IllegalArgumentException.l('BgPos could not parse: ' + value);
    }
    return tmp;
  }
  ijz() {
    return _get_$cachedSerializer__te6jhj_15(this);
  }
  fjq(typeParamsSerializers) {
    return this.ijz();
  }
}
class BgPos extends Enum {
  constructor(name, ordinal, value) {
    super(name, ordinal);
    this.wkl_1 = value;
  }
}
class Companion_39 {}
class $serializer_22 {
  constructor() {
    $serializer_instance_22 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.FieldInstanceEntityReference', this, 4);
    tmp0_serialDesc.fjp('entityIid', false);
    tmp0_serialDesc.fjp('layerIid', false);
    tmp0_serialDesc.fjp('levelIid', false);
    tmp0_serialDesc.fjp('worldIid', false);
    this.xkl_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.xkl_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_input = decoder.pjj(tmp0_desc);
    if (tmp8_input.fjk()) {
      tmp4_local0 = tmp8_input.zjj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp8_input.zjj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp8_input.zjj(tmp0_desc, 2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp8_input.zjj(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp8_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp8_input.zjj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp8_input.zjj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp8_input.zjj(tmp0_desc, 2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp8_input.zjj(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp8_input.qjj(tmp0_desc);
    return FieldInstanceEntityReference.ckm(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, tmp7_local3, null);
  }
  ujf() {
    return this.xkl_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [StringSerializer_getInstance(), StringSerializer_getInstance(), StringSerializer_getInstance(), StringSerializer_getInstance()];
  }
}
class FieldInstanceEntityReference {
  toString() {
    return 'FieldInstanceEntityReference(entityIid=' + this.ykl_1 + ', layerIid=' + this.zkl_1 + ', levelIid=' + this.akm_1 + ', worldIid=' + this.bkm_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.ykl_1);
    result = imul(result, 31) + getStringHashCode(this.zkl_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.akm_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.bkm_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FieldInstanceEntityReference))
      return false;
    var tmp0_other_with_cast = other instanceof FieldInstanceEntityReference ? other : THROW_CCE();
    if (!(this.ykl_1 === tmp0_other_with_cast.ykl_1))
      return false;
    if (!(this.zkl_1 === tmp0_other_with_cast.zkl_1))
      return false;
    if (!(this.akm_1 === tmp0_other_with_cast.akm_1))
      return false;
    if (!(this.bkm_1 === tmp0_other_with_cast.bkm_1))
      return false;
    return true;
  }
  static ckm(seen0, entityIid, layerIid, levelIid, worldIid, serializationConstructorMarker) {
    if (!(15 === (15 & seen0))) {
      throwMissingFieldException(seen0, 15, $serializer_getInstance_22().xkl_1);
    }
    var $this = createThis(this);
    $this.ykl_1 = entityIid;
    $this.zkl_1 = layerIid;
    $this.akm_1 = levelIid;
    $this.bkm_1 = worldIid;
    return $this;
  }
}
class Companion_40 {}
class $serializer_23 {
  constructor() {
    $serializer_instance_23 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.FieldInstanceGridPoint', this, 2);
    tmp0_serialDesc.fjp('cx', false);
    tmp0_serialDesc.fjp('cy', false);
    this.dkm_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.dkm_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = 0;
    var tmp6_input = decoder.pjj(tmp0_desc);
    if (tmp6_input.fjk()) {
      tmp4_local0 = tmp6_input.ujj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.ujj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp6_input.qjj(tmp0_desc);
    return FieldInstanceGridPoint.gkm(tmp3_bitMask0, tmp4_local0, tmp5_local1, null);
  }
  ujf() {
    return this.dkm_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class FieldInstanceGridPoint {
  toString() {
    return 'FieldInstanceGridPoint(cx=' + this.ekm_1 + ', cy=' + this.fkm_1 + ')';
  }
  hashCode() {
    var result = this.ekm_1;
    result = imul(result, 31) + this.fkm_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FieldInstanceGridPoint))
      return false;
    var tmp0_other_with_cast = other instanceof FieldInstanceGridPoint ? other : THROW_CCE();
    if (!(this.ekm_1 === tmp0_other_with_cast.ekm_1))
      return false;
    if (!(this.fkm_1 === tmp0_other_with_cast.fkm_1))
      return false;
    return true;
  }
  static gkm(seen0, cx, cy, serializationConstructorMarker) {
    if (!(3 === (3 & seen0))) {
      throwMissingFieldException(seen0, 3, $serializer_getInstance_23().dkm_1);
    }
    var $this = createThis(this);
    $this.ekm_1 = cx;
    $this.fkm_1 = cy;
    return $this;
  }
}
class Companion_41 {}
class $serializer_24 {
  constructor() {
    $serializer_instance_24 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('korlibs.korge.ldtk.IntGridValueInstance', this, 2);
    tmp0_serialDesc.fjp('coordId', false);
    tmp0_serialDesc.fjp('v', false);
    this.hkm_1 = tmp0_serialDesc;
  }
  vjf(decoder) {
    var tmp0_desc = this.hkm_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = 0;
    var tmp6_input = decoder.pjj(tmp0_desc);
    if (tmp6_input.fjk()) {
      tmp4_local0 = tmp6_input.ujj(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.ujj(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.gjk(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.ujj(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.ujj(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException.ajh(tmp2_index);
        }
      }
    tmp6_input.qjj(tmp0_desc);
    return IntGridValueInstance.kkm(tmp3_bitMask0, tmp4_local0, tmp5_local1, null);
  }
  ujf() {
    return this.hkm_1;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [IntSerializer_getInstance(), IntSerializer_getInstance()];
  }
}
class IntGridValueInstance {
  toString() {
    return 'IntGridValueInstance(coordID=' + this.ikm_1 + ', v=' + this.jkm_1 + ')';
  }
  hashCode() {
    var result = this.ikm_1;
    result = imul(result, 31) + this.jkm_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof IntGridValueInstance))
      return false;
    var tmp0_other_with_cast = other instanceof IntGridValueInstance ? other : THROW_CCE();
    if (!(this.ikm_1 === tmp0_other_with_cast.ikm_1))
      return false;
    if (!(this.jkm_1 === tmp0_other_with_cast.jkm_1))
      return false;
    return true;
  }
  static kkm(seen0, coordID, v, serializationConstructorMarker) {
    if (!(3 === (3 & seen0))) {
      throwMissingFieldException(seen0, 3, $serializer_getInstance_24().hkm_1);
    }
    var $this = createThis(this);
    $this.ikm_1 = coordID;
    $this.jkm_1 = v;
    return $this;
  }
}
class FieldInfo {
  constructor(def, instance) {
    this.lkm_1 = def;
    this.mkm_1 = instance;
    this.nkm_1 = this.lkm_1.tk7_1;
  }
  b2() {
    return toKotlinTypes(this.okm());
  }
  pkm() {
    return _Dyn___init__impl__6cuxxc(this.b2());
  }
  okm() {
    return this.mkm_1.dk6_1;
  }
  qkm() {
    var tmp0_safe_receiver = this.okm();
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_jsonPrimitive(tmp0_safe_receiver);
    return tmp1_safe_receiver == null ? null : tmp1_safe_receiver.fjz();
  }
  toString() {
    return 'FieldInfo(def=' + this.lkm_1.toString() + ', instance=' + this.mkm_1.toString() + ')';
  }
  hashCode() {
    var result = this.lkm_1.hashCode();
    result = imul(result, 31) + this.mkm_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof FieldInfo))
      return false;
    var tmp0_other_with_cast = other instanceof FieldInfo ? other : THROW_CCE();
    if (!this.lkm_1.equals(tmp0_other_with_cast.lkm_1))
      return false;
    if (!this.mkm_1.equals(tmp0_other_with_cast.mkm_1))
      return false;
    return true;
  }
}
class LDTKWorld {
  constructor(ldtk, tilesetDefsById, tilesetIsExtruded) {
    this.rkm_1 = ldtk;
    this.skm_1 = tilesetDefsById;
    this.tkm_1 = tilesetIsExtruded;
    var tmp = this;
    tmp.ukm_1 = lazy_0(LDTKWorld$levels$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.vkm_1 = lazy_0(LDTKWorld$levelsByName$delegate$lambda(this));
    var tmp_1 = this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = this.rkm_1.okh_1.sk9_1;
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.wc(capacity);
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKWorld.layersDefsById.<anonymous>' call
      var tmp$ret$0 = element.lkc_1;
      destination.r3(tmp$ret$0, element);
    }
    tmp_1.wkm_1 = destination;
    this.xkm_1 = Bitmap32.c4p(imul(this.rkm_1.ikh_1 + 4 | 0, 16), this.rkm_1.ikh_1);
    var tmp_2 = this;
    var tmp_3 = Companion_getInstance_0();
    // Inline function 'kotlin.collections.map' call
    var this_1 = until(0, 16);
    // Inline function 'kotlin.collections.mapTo' call
    var destination_0 = ArrayList.w(collectionSizeOrDefault(this_1, 10));
    var tmp0_iterator_0 = this_1.f1();
    while (tmp0_iterator_0.g1()) {
      var item = tmp0_iterator_0.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKWorld.intsTileSet.<anonymous>' call
      var tmp$ret$3 = new TileSetTileInfo(item, slice(this.xkm_1, RectangleInt.d2u(imul(this.rkm_1.ikh_1 + 4 | 0, item), 0, this.rkm_1.ikh_1, this.rkm_1.ikh_1)));
      destination_0.f(tmp$ret$3);
    }
    tmp_2.ykm_1 = tmp_3.k71(destination_0);
    var _iterator__ex2g4s = this.rkm_1.okh_1.sk9_1.f1();
    while (_iterator__ex2g4s.g1()) {
      var layer = _iterator__ex2g4s.h1();
      var _iterator__ex2g4s_0 = layer.akc_1.f1();
      while (_iterator__ex2g4s_0.g1()) {
        var value = _iterator__ex2g4s_0.h1();
        this.xkm_1.c50(Colors_getInstance().r59(value.ykd_1), imul(this.rkm_1.ikh_1 + 4 | 0, value.ake_1));
      }
    }
  }
  zkm() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ukm_1;
    levels$factory();
    return this_0.b2();
  }
  ekn() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.vkm_1;
    levelsByName$factory();
    return this_0.b2();
  }
}
class LDTKLevel {
  constructor(world, level) {
    this.akn_1 = world;
    this.bkn_1 = level;
    var tmp = this;
    tmp.ckn_1 = lazy_0(LDTKLevel$layers$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.dkn_1 = lazy_0(LDTKLevel$layersByName$delegate$lambda(this));
  }
  ikn() {
    return this.akn_1.rkm_1;
  }
  fkn() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ckn_1;
    layers$factory();
    return this_0.b2();
  }
  jkn() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.dkn_1;
    layersByName$factory();
    return this_0.b2();
  }
}
class LDTKLayer {
  constructor(level, layer) {
    this.gkn_1 = level;
    this.hkn_1 = layer;
  }
  zi3() {
    return this.gkn_1.akn_1;
  }
}
class LDTKLevelView extends Container {
  static zkp(level, showCollisions) {
    showCollisions = showCollisions === VOID ? false : showCollisions;
    var $this = this.vbw();
    $this.skp_1 = level;
    $this.tkp_1 = showCollisions;
    var tmp = $this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.ukp_1 = ArrayList.m1();
    $this.vkp_1 = linkedHashMapOf([]);
    var tmp_0 = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.solidRect' call
    var width = _get_blevel__xqeqs7($this).zki_1;
    var height = _get_blevel__xqeqs7($this).yki_1;
    var tmp_1 = Colors_getInstance();
    var tmp0_elvis_lhs = _get_blevel__xqeqs7($this).oki_1;
    var color = tmp_1.r59(tmp0_elvis_lhs == null ? _get_ldtk__d9qlvg($this).hkh_1 : tmp0_elvis_lhs);
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = SolidRect.ddj(Size2D.f2j(width, height), color);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ldtk.view.LDTKLevelView.bgLayer.<anonymous>' call
    this_1.aba_1 = 'background';
    tmp_0.wkp_1 = this_1;
    var tmp_2 = $this;
    // Inline function 'kotlin.collections.map' call
    var this_2 = asReversed($this.skp_1.fkn());
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_2, 10));
    var tmp0_iterator = this_2.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKLevelView.layerViews.<anonymous>' call
      var tmp$ret$6 = addTo(LDTKLayerView.sks(item, $this.tkp_1), $this);
      destination.f(tmp$ret$6);
    }
    tmp_2.xkp_1 = destination;
    var tmp_3 = $this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_3 = $this.xkp_1;
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_3, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination_0 = LinkedHashMap.wc(capacity);
    var tmp0_iterator_0 = this_3.f1();
    while (tmp0_iterator_0.g1()) {
      var element = tmp0_iterator_0.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKLevelView.layerViewsByName.<anonymous>' call
      var tmp$ret$9 = element.lks_1.ckg_1;
      destination_0.r3(tmp$ret$9, element);
    }
    tmp_3.ykp_1 = destination_0;
    return $this;
  }
}
class LDTKLayerView extends Container {
  static sks(llayer, showCollisions) {
    showCollisions = showCollisions === VOID ? false : showCollisions;
    var $this = this.vbw();
    $this.iks_1 = llayer;
    $this.jks_1 = showCollisions;
    $this.kks_1 = $this.iks_1.zi3();
    $this.lks_1 = $this.iks_1.hkn_1;
    $this.mks_1 = $this.kks_1.wkm_1.k3($this.lks_1.okg_1);
    var tmp = $this;
    // Inline function 'kotlin.collections.get' call
    var this_0 = $this.kks_1.skm_1;
    var key = $this.lks_1.gkg_1;
    tmp.nks_1 = (isInterface(this_0, KtMap) ? this_0 : THROW_CCE()).k3(key);
    $this.oks_1 = new IntArray2($this.lks_1.akg_1, $this.lks_1.zkf_1, copyOf($this.lks_1.nkg_1, imul($this.lks_1.akg_1, $this.lks_1.zkf_1)));
    $this.pks_1 = new StackedIntArray2($this.lks_1.akg_1, $this.lks_1.zkf_1, -1);
    $this.aba_1 = $this.lks_1.ckg_1;
    $this.tks();
    var tmp_0 = $this;
    // Inline function 'kotlin.collections.map' call
    var this_1 = $this.lks_1.kkg_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_1, 10));
    var tmp0_iterator = this_1.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKLayerView.entities.<anonymous>' call
      var tmp$ret$1 = addTo(LDTKEntityView.nkv(item, $this.iks_1), $this);
      destination.f(tmp$ret$1);
    }
    tmp_0.qks_1 = destination;
    var tmp_1 = $this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_2 = $this.qks_1;
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_2, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination_0 = LinkedHashMap.wc(capacity);
    var tmp0_iterator_0 = this_2.f1();
    while (tmp0_iterator_0.g1()) {
      var element = tmp0_iterator_0.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKLayerView.entitiesByIID.<anonymous>' call
      var tmp$ret$4 = element.ckv_1.tk6_1;
      destination_0.r3(tmp$ret$4, element);
    }
    tmp_1.rks_1 = destination_0;
    return $this;
  }
  tks() {
    if (!(this.nks_1 == null) && !(this.mks_1 == null)) {
      var tileset = this.nks_1.okv_1;
      var gridSize = tileset.fkd_1;
      var _iterator__ex2g4s = plus(this.lks_1.jkg_1, this.lks_1.lkg_1).f1();
      while (_iterator__ex2g4s.g1()) {
        var tile = _iterator__ex2g4s.h1();
        var _destruct__k2r9zo = tile.jkj_1;
        // Inline function 'kotlin.collections.component1' call
        var px = _destruct__k2r9zo[0];
        // Inline function 'kotlin.collections.component2' call
        var py = _destruct__k2r9zo[1];
        var _destruct__k2r9zo_0 = tile.kkj_1;
        // Inline function 'kotlin.collections.component1' call
        var tileX = _destruct__k2r9zo_0[0];
        // Inline function 'kotlin.collections.component2' call
        var tileY = _destruct__k2r9zo_0[1];
        var x = px / gridSize | 0;
        var y = py / gridSize | 0;
        var dx = px % gridSize | 0;
        var dy = py % gridSize | 0;
        var tx = tileX / gridSize | 0;
        var ty = tileY / gridSize | 0;
        var cellsTilesPerRow = tileset.zkc_1 / gridSize | 0;
        var tileId = imul(ty, cellsTilesPerRow) + tx | 0;
        var flipX = hasBitSet(tile.ikj_1, 0);
        var flipY = hasBitSet(tile.ikj_1, 1);
        this.pks_1.v3b(x, y, _Tile___get_data__impl__sfu1ss(Companion_getInstance_1().p70(tileId, dx, dy, flipX, flipY)));
      }
      if (!(this.nks_1.pkv_1 == null)) {
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.also' call
        // Inline function 'korlibs.korge.view.alpha' call
        // Inline function 'korlibs.korge.view.tiles.tileMap' call
        var map = this.pks_1;
        var tileset_0 = ensureNotNull(this.nks_1.pkv_1);
        var repeatX = TileMapRepeat_NONE_getInstance();
        var tileSize = tileset_0.c72();
        // Inline function 'korlibs.korge.view.addTo' call
        var this_0 = TileMap.qhr(map, tileset_0, false, tileSize).shr(repeatX, repeatX);
        // Inline function 'kotlin.apply' call
        var this_1 = addTo(this_0, this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.view.tiles.tileMap.<anonymous>' call
        var alpha_0 = this.mks_1.rkb_1;
        var this_2 = alpha(this_1, numberToDouble(alpha_0));
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.ldtk.view.LDTKLayerView.addTiles.<anonymous>' call
        if (!this.kks_1.tkm_1) {
          // Inline function 'korlibs.korge.view.filter.filters' call
          var filters = [Nearest_getInstance()];
          var filterScale = get_filterScale(this_2);
          // Inline function 'korlibs.korge.view.filter.filters' call
          var filters_0 = toList_0(filters);
          var filterScale_0 = numberToDouble(filterScale);
          set_filter(this_2, Companion_instance.dhg(null, filters_0));
          set_filterScale(this_2, numberToDouble(filterScale_0));
        }
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.ldtk.view.LDTKLayerView.addTiles.<anonymous>' call
        this_2.fhr_1 = 1;
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.also' call
        // Inline function 'korlibs.korge.view.visible' call
        // Inline function 'korlibs.korge.view.tiles.tileMap' call
        var map_0 = this.oks_1;
        var tileset_1 = this.kks_1.ykm_1;
        var repeatX_0 = TileMapRepeat_NONE_getInstance();
        var tileSize_0 = tileset_1.c72();
        // Inline function 'korlibs.korge.view.addTo' call
        var this_3 = TileMap.rhr(map_0, tileset_1, false, tileSize_0).shr(repeatX_0, repeatX_0);
        // Inline function 'kotlin.apply' call
        var this_4 = addTo(this_3, this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.view.tiles.tileMap.<anonymous>' call
        var visible = this.jks_1;
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.view.visible.<anonymous>' call
        this_4.car(visible);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.ldtk.view.LDTKLayerView.addTiles.<anonymous>' call
        if (!this.kks_1.tkm_1) {
          // Inline function 'korlibs.korge.view.filter.filters' call
          var filters_1 = [Nearest_getInstance()];
          var filterScale_1 = get_filterScale(this_4);
          // Inline function 'korlibs.korge.view.filter.filters' call
          var filters_2 = toList_0(filters_1);
          var filterScale_2 = numberToDouble(filterScale_1);
          set_filter(this_4, Companion_instance.dhg(null, filters_2));
          set_filterScale(this_4, numberToDouble(filterScale_2));
        }
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.ldtk.view.LDTKLayerView.addTiles.<anonymous>' call
        this_4.fhr_1 = 1;
      }
    }
  }
}
class LDTKEntityView extends Container {
  static nkv(entity, llayer) {
    var $this = this.vbw();
    $this.ckv_1 = entity;
    $this.dkv_1 = llayer;
    $this.ekv_1 = ensureNotNull(get_entitiesByUid($this.dkv_1.zi3().rkm_1.okh_1).k3($this.ckv_1.qk6_1));
    var tmp = $this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = zip($this.ekv_1.nk8_1, $this.ckv_1.rk6_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKEntityView.fields.<anonymous>' call
      var tmp$ret$0 = new FieldInfo(item.im_1, item.jm_1);
      destination.f(tmp$ret$0);
    }
    tmp.fkv_1 = destination;
    var tmp_0 = $this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_1 = $this.fkv_1;
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_1, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination_0 = LinkedHashMap.wc(capacity);
    var tmp0_iterator_0 = this_1.f1();
    while (tmp0_iterator_0.g1()) {
      var element = tmp0_iterator_0.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKEntityView.fieldsByName.<anonymous>' call
      var tmp$ret$3 = element.nkm_1;
      destination_0.r3(tmp$ret$3, element);
    }
    tmp_0.gkv_1 = destination_0;
    $this.aba_1 = $this.ckv_1.lk6_1;
    $this.hkv_1 = get_pivotAnchor($this.ckv_1);
    $this.ikv_1 = $this.dkv_1.hkn_1.bkg_1;
    $this.jkv_1 = $this.ckv_1.pk6_1;
    var tmp_1 = $this;
    // Inline function 'kotlin.collections.get' call
    var this_2 = $this.dkv_1.zi3().skm_1;
    var tmp3_safe_receiver = $this.jkv_1;
    var key = tmp3_safe_receiver == null ? null : tmp3_safe_receiver.yk9_1;
    tmp_1.kkv_1 = (isInterface(this_2, KtMap) ? this_2 : THROW_CCE()).k3(key);
    var tmp_2 = $this;
    var tmp4_safe_receiver = $this.kkv_1;
    tmp_2.lkv_1 = tmp4_safe_receiver == null ? null : tmp4_safe_receiver.qkv_1;
    var tmp_3 = $this;
    var tmp_4;
    if (!($this.jkv_1 == null)) {
      // Inline function 'kotlin.also' call
      // Inline function 'korlibs.korge.view.image' call
      var texture = sliceWithSize(ensureNotNull(ensureNotNull($this.kkv_1).qkv_1).o33(), $this.jkv_1.aka_1, $this.jkv_1.bka_1, $this.jkv_1.zk9_1, $this.jkv_1.xk9_1);
      var anchor_0 = Companion_getInstance().p2g_1;
      // Inline function 'korlibs.korge.view.addTo' call
      var this_3 = Image.rd8(texture, anchor_0);
      // Inline function 'kotlin.apply' call
      var this_4 = addTo(this_3, $this);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.image.<anonymous>' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ldtk.view.LDTKEntityView.view.<anonymous>' call
      this_4.zga_1 = false;
      tmp_4 = this_4;
    } else {
      // Inline function 'korlibs.korge.view.solidRect' call
      var width = $this.ckv_1.vk6_1;
      var height = $this.ckv_1.sk6_1;
      var color = Colors_getInstance().s59($this.ckv_1.nk6_1, Colors_getInstance().i4t_1);
      // Inline function 'korlibs.korge.view.addTo' call
      var this_5 = SolidRect.ddj(Size2D.f2j(width, height), color);
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
      tmp_4 = addTo(this_5, $this);
    }
    tmp_3.mkv_1 = tmp_4;
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var this_6 = toDouble(get_gridPos($this.ckv_1));
    var scale = $this.ikv_1;
    var this_7 = Vector2D.k2h(this_6.i2h_1 * scale, this_6.j2h_1 * scale);
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var this_8 = $this.hkv_1.h2h();
    var scale_0 = $this.ikv_1;
    var that = Vector2D.k2h(this_8.i2h_1 * scale_0, this_8.j2h_1 * scale_0);
    var pos = Vector2D.k2h(this_7.i2h_1 + that.i2h_1, this_7.j2h_1 + that.j2h_1);
    // Inline function 'korlibs.korge.view.size' call
    var this_9 = $this.mkv_1;
    var width_0 = $this.ckv_1.vk6_1;
    var height_0 = $this.ckv_1.sk6_1;
    size(this_9, numberToDouble(width_0), numberToDouble(height_0));
    var tmp_5 = $this.mkv_1;
    var tmp5_safe_receiver = isInterface(tmp_5, Anchorable) ? tmp_5 : null;
    if (tmp5_safe_receiver == null)
      null;
    else
      anchor(tmp5_safe_receiver, $this.hkv_1);
    xy($this, pos);
    zIndex($this, pos.j2h_1);
    return $this;
  }
  rkv(view) {
    this.yca(this.mkv_1, view);
    this.mkv_1 = view;
  }
}
class ExtTileset {
  constructor(def, tileset, unextrudedTileSet) {
    this.okv_1 = def;
    this.pkv_1 = tileset;
    this.qkv_1 = unextrudedTileSet;
  }
}
//endregion
var Companion_instance_0;
function Companion_getInstance_2() {
  return Companion_instance_0;
}
var $serializer_instance;
function $serializer_getInstance() {
  if ($serializer_instance === VOID)
    new $serializer();
  return $serializer_instance;
}
var Companion_instance_1;
function Companion_getInstance_3() {
  if (Companion_instance_1 === VOID)
    new Companion_0();
  return Companion_instance_1;
}
var $serializer_instance_0;
function $serializer_getInstance_0() {
  if ($serializer_instance_0 === VOID)
    new $serializer_0();
  return $serializer_instance_0;
}
var Companion_instance_2;
function Companion_getInstance_4() {
  if (Companion_instance_2 === VOID)
    new Companion_1();
  return Companion_instance_2;
}
var $serializer_instance_1;
function $serializer_getInstance_1() {
  if ($serializer_instance_1 === VOID)
    new $serializer_1();
  return $serializer_instance_1;
}
var Companion_instance_3;
function Companion_getInstance_5() {
  if (Companion_instance_3 === VOID)
    new Companion_2();
  return Companion_instance_3;
}
var $serializer_instance_2;
function $serializer_getInstance_2() {
  if ($serializer_instance_2 === VOID)
    new $serializer_2();
  return $serializer_instance_2;
}
var Companion_instance_4;
function Companion_getInstance_6() {
  if (Companion_instance_4 === VOID)
    new Companion_3();
  return Companion_instance_4;
}
var $serializer_instance_3;
function $serializer_getInstance_3() {
  if ($serializer_instance_3 === VOID)
    new $serializer_3();
  return $serializer_instance_3;
}
var Companion_instance_5;
function Companion_getInstance_7() {
  return Companion_instance_5;
}
var $serializer_instance_4;
function $serializer_getInstance_4() {
  if ($serializer_instance_4 === VOID)
    new $serializer_4();
  return $serializer_instance_4;
}
function _get_$cachedSerializer__te6jhj($this) {
  return $this.xk6_1.b2();
}
function AllowedRefs$Companion$_anonymous__v2lmwp() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.AllowedRefs', values());
}
var AllowedRefs_Any_instance;
var AllowedRefs_OnlySame_instance;
var AllowedRefs_OnlyTags_instance;
var Companion_instance_6;
function Companion_getInstance_8() {
  AllowedRefs_initEntries();
  if (Companion_instance_6 === VOID)
    new Companion_5();
  return Companion_instance_6;
}
function values() {
  return [AllowedRefs_Any_getInstance(), AllowedRefs_OnlySame_getInstance(), AllowedRefs_OnlyTags_getInstance()];
}
var AllowedRefs_entriesInitialized;
function AllowedRefs_initEntries() {
  if (AllowedRefs_entriesInitialized)
    return Unit_instance;
  AllowedRefs_entriesInitialized = true;
  AllowedRefs_Any_instance = new AllowedRefs('Any', 0, 'Any');
  AllowedRefs_OnlySame_instance = new AllowedRefs('OnlySame', 1, 'OnlySame');
  AllowedRefs_OnlyTags_instance = new AllowedRefs('OnlyTags', 2, 'OnlyTags');
  Companion_getInstance_8();
}
function _get_$cachedSerializer__te6jhj_0($this) {
  return $this.yk6_1.b2();
}
function EditorDisplayMode$Companion$_anonymous__77qsc7() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.EditorDisplayMode', values_0());
}
var EditorDisplayMode_ArrayCountNoLabel_instance;
var EditorDisplayMode_ArrayCountWithLabel_instance;
var EditorDisplayMode_EntityTile_instance;
var EditorDisplayMode_Hidden_instance;
var EditorDisplayMode_NameAndValue_instance;
var EditorDisplayMode_PointPath_instance;
var EditorDisplayMode_PointPathLoop_instance;
var EditorDisplayMode_PointStar_instance;
var EditorDisplayMode_Points_instance;
var EditorDisplayMode_RadiusGrid_instance;
var EditorDisplayMode_RadiusPx_instance;
var EditorDisplayMode_RefLinkBetweenCenters_instance;
var EditorDisplayMode_RefLinkBetweenPivots_instance;
var EditorDisplayMode_ValueOnly_instance;
var Companion_instance_7;
function Companion_getInstance_9() {
  EditorDisplayMode_initEntries();
  if (Companion_instance_7 === VOID)
    new Companion_6();
  return Companion_instance_7;
}
function values_0() {
  return [EditorDisplayMode_ArrayCountNoLabel_getInstance(), EditorDisplayMode_ArrayCountWithLabel_getInstance(), EditorDisplayMode_EntityTile_getInstance(), EditorDisplayMode_Hidden_getInstance(), EditorDisplayMode_NameAndValue_getInstance(), EditorDisplayMode_PointPath_getInstance(), EditorDisplayMode_PointPathLoop_getInstance(), EditorDisplayMode_PointStar_getInstance(), EditorDisplayMode_Points_getInstance(), EditorDisplayMode_RadiusGrid_getInstance(), EditorDisplayMode_RadiusPx_getInstance(), EditorDisplayMode_RefLinkBetweenCenters_getInstance(), EditorDisplayMode_RefLinkBetweenPivots_getInstance(), EditorDisplayMode_ValueOnly_getInstance()];
}
var EditorDisplayMode_entriesInitialized;
function EditorDisplayMode_initEntries() {
  if (EditorDisplayMode_entriesInitialized)
    return Unit_instance;
  EditorDisplayMode_entriesInitialized = true;
  EditorDisplayMode_ArrayCountNoLabel_instance = new EditorDisplayMode('ArrayCountNoLabel', 0, 'ArrayCountNoLabel');
  EditorDisplayMode_ArrayCountWithLabel_instance = new EditorDisplayMode('ArrayCountWithLabel', 1, 'ArrayCountWithLabel');
  EditorDisplayMode_EntityTile_instance = new EditorDisplayMode('EntityTile', 2, 'EntityTile');
  EditorDisplayMode_Hidden_instance = new EditorDisplayMode('Hidden', 3, 'Hidden');
  EditorDisplayMode_NameAndValue_instance = new EditorDisplayMode('NameAndValue', 4, 'NameAndValue');
  EditorDisplayMode_PointPath_instance = new EditorDisplayMode('PointPath', 5, 'PointPath');
  EditorDisplayMode_PointPathLoop_instance = new EditorDisplayMode('PointPathLoop', 6, 'PointPathLoop');
  EditorDisplayMode_PointStar_instance = new EditorDisplayMode('PointStar', 7, 'PointStar');
  EditorDisplayMode_Points_instance = new EditorDisplayMode('Points', 8, 'Points');
  EditorDisplayMode_RadiusGrid_instance = new EditorDisplayMode('RadiusGrid', 9, 'RadiusGrid');
  EditorDisplayMode_RadiusPx_instance = new EditorDisplayMode('RadiusPx', 10, 'RadiusPx');
  EditorDisplayMode_RefLinkBetweenCenters_instance = new EditorDisplayMode('RefLinkBetweenCenters', 11, 'RefLinkBetweenCenters');
  EditorDisplayMode_RefLinkBetweenPivots_instance = new EditorDisplayMode('RefLinkBetweenPivots', 12, 'RefLinkBetweenPivots');
  EditorDisplayMode_ValueOnly_instance = new EditorDisplayMode('ValueOnly', 13, 'ValueOnly');
  Companion_getInstance_9();
}
function _get_$cachedSerializer__te6jhj_1($this) {
  return $this.zk6_1.b2();
}
function EditorDisplayPos$Companion$_anonymous__f71kr4() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.EditorDisplayPos', values_1());
}
var EditorDisplayPos_Above_instance;
var EditorDisplayPos_Beneath_instance;
var EditorDisplayPos_Center_instance;
var Companion_instance_8;
function Companion_getInstance_10() {
  EditorDisplayPos_initEntries();
  if (Companion_instance_8 === VOID)
    new Companion_7();
  return Companion_instance_8;
}
function values_1() {
  return [EditorDisplayPos_Above_getInstance(), EditorDisplayPos_Beneath_getInstance(), EditorDisplayPos_Center_getInstance()];
}
var EditorDisplayPos_entriesInitialized;
function EditorDisplayPos_initEntries() {
  if (EditorDisplayPos_entriesInitialized)
    return Unit_instance;
  EditorDisplayPos_entriesInitialized = true;
  EditorDisplayPos_Above_instance = new EditorDisplayPos('Above', 0, 'Above');
  EditorDisplayPos_Beneath_instance = new EditorDisplayPos('Beneath', 1, 'Beneath');
  EditorDisplayPos_Center_instance = new EditorDisplayPos('Center', 2, 'Center');
  Companion_getInstance_10();
}
function _get_$cachedSerializer__te6jhj_2($this) {
  return $this.ak7_1.b2();
}
function TextLanguageMode$Companion$_anonymous__u336u1() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.TextLanguageMode', values_2());
}
var TextLanguageMode_LangC_instance;
var TextLanguageMode_LangHaxe_instance;
var TextLanguageMode_LangJS_instance;
var TextLanguageMode_LangJSON_instance;
var TextLanguageMode_LangLog_instance;
var TextLanguageMode_LangLua_instance;
var TextLanguageMode_LangMarkdown_instance;
var TextLanguageMode_LangPython_instance;
var TextLanguageMode_LangRuby_instance;
var TextLanguageMode_LangXML_instance;
var Companion_instance_9;
function Companion_getInstance_11() {
  TextLanguageMode_initEntries();
  if (Companion_instance_9 === VOID)
    new Companion_8();
  return Companion_instance_9;
}
function values_2() {
  return [TextLanguageMode_LangC_getInstance(), TextLanguageMode_LangHaxe_getInstance(), TextLanguageMode_LangJS_getInstance(), TextLanguageMode_LangJSON_getInstance(), TextLanguageMode_LangLog_getInstance(), TextLanguageMode_LangLua_getInstance(), TextLanguageMode_LangMarkdown_getInstance(), TextLanguageMode_LangPython_getInstance(), TextLanguageMode_LangRuby_getInstance(), TextLanguageMode_LangXML_getInstance()];
}
var TextLanguageMode_entriesInitialized;
function TextLanguageMode_initEntries() {
  if (TextLanguageMode_entriesInitialized)
    return Unit_instance;
  TextLanguageMode_entriesInitialized = true;
  TextLanguageMode_LangC_instance = new TextLanguageMode('LangC', 0, 'LangC');
  TextLanguageMode_LangHaxe_instance = new TextLanguageMode('LangHaxe', 1, 'LangHaxe');
  TextLanguageMode_LangJS_instance = new TextLanguageMode('LangJS', 2, 'LangJS');
  TextLanguageMode_LangJSON_instance = new TextLanguageMode('LangJSON', 3, 'LangJson');
  TextLanguageMode_LangLog_instance = new TextLanguageMode('LangLog', 4, 'LangLog');
  TextLanguageMode_LangLua_instance = new TextLanguageMode('LangLua', 5, 'LangLua');
  TextLanguageMode_LangMarkdown_instance = new TextLanguageMode('LangMarkdown', 6, 'LangMarkdown');
  TextLanguageMode_LangPython_instance = new TextLanguageMode('LangPython', 7, 'LangPython');
  TextLanguageMode_LangRuby_instance = new TextLanguageMode('LangRuby', 8, 'LangRuby');
  TextLanguageMode_LangXML_instance = new TextLanguageMode('LangXML', 9, 'LangXml');
  Companion_getInstance_11();
}
function _get_$cachedSerializer__te6jhj_3($this) {
  return $this.fk8_1.b2();
}
function LimitBehavior$Companion$_anonymous__hsp10i() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.LimitBehavior', values_3());
}
var LimitBehavior_DiscardOldOnes_instance;
var LimitBehavior_MoveLastOne_instance;
var LimitBehavior_PreventAdding_instance;
var Companion_instance_10;
function Companion_getInstance_12() {
  LimitBehavior_initEntries();
  if (Companion_instance_10 === VOID)
    new Companion_9();
  return Companion_instance_10;
}
function values_3() {
  return [LimitBehavior_DiscardOldOnes_getInstance(), LimitBehavior_MoveLastOne_getInstance(), LimitBehavior_PreventAdding_getInstance()];
}
var LimitBehavior_entriesInitialized;
function LimitBehavior_initEntries() {
  if (LimitBehavior_entriesInitialized)
    return Unit_instance;
  LimitBehavior_entriesInitialized = true;
  LimitBehavior_DiscardOldOnes_instance = new LimitBehavior('DiscardOldOnes', 0, 'DiscardOldOnes');
  LimitBehavior_MoveLastOne_instance = new LimitBehavior('MoveLastOne', 1, 'MoveLastOne');
  LimitBehavior_PreventAdding_instance = new LimitBehavior('PreventAdding', 2, 'PreventAdding');
  Companion_getInstance_12();
}
function _get_$cachedSerializer__te6jhj_4($this) {
  return $this.gk8_1.b2();
}
function LimitScope$Companion$_anonymous__ndoxsa() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.LimitScope', values_4());
}
var LimitScope_PerLayer_instance;
var LimitScope_PerLevel_instance;
var LimitScope_PerWorld_instance;
var Companion_instance_11;
function Companion_getInstance_13() {
  LimitScope_initEntries();
  if (Companion_instance_11 === VOID)
    new Companion_10();
  return Companion_instance_11;
}
function values_4() {
  return [LimitScope_PerLayer_getInstance(), LimitScope_PerLevel_getInstance(), LimitScope_PerWorld_getInstance()];
}
var LimitScope_entriesInitialized;
function LimitScope_initEntries() {
  if (LimitScope_entriesInitialized)
    return Unit_instance;
  LimitScope_entriesInitialized = true;
  LimitScope_PerLayer_instance = new LimitScope('PerLayer', 0, 'PerLayer');
  LimitScope_PerLevel_instance = new LimitScope('PerLevel', 1, 'PerLevel');
  LimitScope_PerWorld_instance = new LimitScope('PerWorld', 2, 'PerWorld');
  Companion_getInstance_13();
}
function _get_$cachedSerializer__te6jhj_5($this) {
  return $this.hk8_1.b2();
}
function RenderMode$Companion$_anonymous__ho71fu() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.RenderMode', values_5());
}
var RenderMode_Cross_instance;
var RenderMode_Ellipse_instance;
var RenderMode_Rectangle_instance;
var RenderMode_Tile_instance;
var Companion_instance_12;
function Companion_getInstance_14() {
  RenderMode_initEntries();
  if (Companion_instance_12 === VOID)
    new Companion_11();
  return Companion_instance_12;
}
function values_5() {
  return [RenderMode_Cross_getInstance(), RenderMode_Ellipse_getInstance(), RenderMode_Rectangle_getInstance(), RenderMode_Tile_getInstance()];
}
var RenderMode_entriesInitialized;
function RenderMode_initEntries() {
  if (RenderMode_entriesInitialized)
    return Unit_instance;
  RenderMode_entriesInitialized = true;
  RenderMode_Cross_instance = new RenderMode('Cross', 0, 'Cross');
  RenderMode_Ellipse_instance = new RenderMode('Ellipse', 1, 'Ellipse');
  RenderMode_Rectangle_instance = new RenderMode('Rectangle', 2, 'Rectangle');
  RenderMode_Tile_instance = new RenderMode('Tile', 3, 'Tile');
  Companion_getInstance_14();
}
function _get_$cachedSerializer__te6jhj_6($this) {
  return $this.ik8_1.b2();
}
function TileRenderMode$Companion$_anonymous__shhaw() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.TileRenderMode', values_6());
}
var TileRenderMode_Cover_instance;
var TileRenderMode_FitInside_instance;
var TileRenderMode_FullSizeCropped_instance;
var TileRenderMode_FullSizeUncropped_instance;
var TileRenderMode_NineSlice_instance;
var TileRenderMode_Repeat_instance;
var TileRenderMode_Stretch_instance;
var Companion_instance_13;
function Companion_getInstance_15() {
  TileRenderMode_initEntries();
  if (Companion_instance_13 === VOID)
    new Companion_12();
  return Companion_instance_13;
}
function values_6() {
  return [TileRenderMode_Cover_getInstance(), TileRenderMode_FitInside_getInstance(), TileRenderMode_FullSizeCropped_getInstance(), TileRenderMode_FullSizeUncropped_getInstance(), TileRenderMode_NineSlice_getInstance(), TileRenderMode_Repeat_getInstance(), TileRenderMode_Stretch_getInstance()];
}
var TileRenderMode_entriesInitialized;
function TileRenderMode_initEntries() {
  if (TileRenderMode_entriesInitialized)
    return Unit_instance;
  TileRenderMode_entriesInitialized = true;
  TileRenderMode_Cover_instance = new TileRenderMode('Cover', 0, 'Cover');
  TileRenderMode_FitInside_instance = new TileRenderMode('FitInside', 1, 'FitInside');
  TileRenderMode_FullSizeCropped_instance = new TileRenderMode('FullSizeCropped', 2, 'FullSizeCropped');
  TileRenderMode_FullSizeUncropped_instance = new TileRenderMode('FullSizeUncropped', 3, 'FullSizeUncropped');
  TileRenderMode_NineSlice_instance = new TileRenderMode('NineSlice', 4, 'NineSlice');
  TileRenderMode_Repeat_instance = new TileRenderMode('Repeat', 5, 'Repeat');
  TileRenderMode_Stretch_instance = new TileRenderMode('Stretch', 6, 'Stretch');
  Companion_getInstance_15();
}
var Companion_instance_14;
function Companion_getInstance_16() {
  if (Companion_instance_14 === VOID)
    new Companion_13();
  return Companion_instance_14;
}
var $serializer_instance_5;
function $serializer_getInstance_5() {
  if ($serializer_instance_5 === VOID)
    new $serializer_5();
  return $serializer_instance_5;
}
var Companion_instance_15;
function Companion_getInstance_17() {
  if (Companion_instance_15 === VOID)
    new Companion_14();
  return Companion_instance_15;
}
var $serializer_instance_6;
function $serializer_getInstance_6() {
  if ($serializer_instance_6 === VOID)
    new $serializer_6();
  return $serializer_instance_6;
}
var Companion_instance_16;
function Companion_getInstance_18() {
  if (Companion_instance_16 === VOID)
    new Companion_15();
  return Companion_instance_16;
}
var $serializer_instance_7;
function $serializer_getInstance_7() {
  if ($serializer_instance_7 === VOID)
    new $serializer_7();
  return $serializer_instance_7;
}
var Companion_instance_17;
function Companion_getInstance_19() {
  return Companion_instance_17;
}
var $serializer_instance_8;
function $serializer_getInstance_8() {
  if ($serializer_instance_8 === VOID)
    new $serializer_8();
  return $serializer_instance_8;
}
var Companion_instance_18;
function Companion_getInstance_20() {
  if (Companion_instance_18 === VOID)
    new Companion_17();
  return Companion_instance_18;
}
var $serializer_instance_9;
function $serializer_getInstance_9() {
  if ($serializer_instance_9 === VOID)
    new $serializer_9();
  return $serializer_instance_9;
}
var Companion_instance_19;
function Companion_getInstance_21() {
  return Companion_instance_19;
}
var $serializer_instance_10;
function $serializer_getInstance_10() {
  if ($serializer_instance_10 === VOID)
    new $serializer_10();
  return $serializer_instance_10;
}
function _get_$cachedSerializer__te6jhj_7($this) {
  return $this.lkb_1.b2();
}
function Type$Companion$_anonymous__vg0awb() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.Type', values_7());
}
var Type_AutoLayer_instance;
var Type_Entities_instance;
var Type_IntGrid_instance;
var Type_Tiles_instance;
var Companion_instance_20;
function Companion_getInstance_22() {
  Type_initEntries();
  if (Companion_instance_20 === VOID)
    new Companion_19();
  return Companion_instance_20;
}
function values_7() {
  return [Type_AutoLayer_getInstance(), Type_Entities_getInstance(), Type_IntGrid_getInstance(), Type_Tiles_getInstance()];
}
var Type_entriesInitialized;
function Type_initEntries() {
  if (Type_entriesInitialized)
    return Unit_instance;
  Type_entriesInitialized = true;
  Type_AutoLayer_instance = new Type('AutoLayer', 0, 'AutoLayer');
  Type_Entities_instance = new Type('Entities', 1, 'Entities');
  Type_IntGrid_instance = new Type('IntGrid', 2, 'IntGrid');
  Type_Tiles_instance = new Type('Tiles', 3, 'Tiles');
  Companion_getInstance_22();
}
var Companion_instance_21;
function Companion_getInstance_23() {
  return Companion_instance_21;
}
var $serializer_instance_11;
function $serializer_getInstance_11() {
  if ($serializer_instance_11 === VOID)
    new $serializer_11();
  return $serializer_instance_11;
}
function _get_$cachedSerializer__te6jhj_8($this) {
  return $this.nkc_1.b2();
}
function EmbedAtlas$Companion$_anonymous__2y90yr() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.EmbedAtlas', values_8());
}
var EmbedAtlas_LdtkIcons_instance;
var Companion_instance_22;
function Companion_getInstance_24() {
  EmbedAtlas_initEntries();
  if (Companion_instance_22 === VOID)
    new Companion_21();
  return Companion_instance_22;
}
function values_8() {
  return [EmbedAtlas_LdtkIcons_getInstance()];
}
var EmbedAtlas_entriesInitialized;
function EmbedAtlas_initEntries() {
  if (EmbedAtlas_entriesInitialized)
    return Unit_instance;
  EmbedAtlas_entriesInitialized = true;
  EmbedAtlas_LdtkIcons_instance = new EmbedAtlas('LdtkIcons', 0, 'LdtkIcons');
  Companion_getInstance_24();
}
var Companion_instance_23;
function Companion_getInstance_25() {
  return Companion_instance_23;
}
var $serializer_instance_12;
function $serializer_getInstance_12() {
  if ($serializer_instance_12 === VOID)
    new $serializer_12();
  return $serializer_instance_12;
}
var Companion_instance_24;
function Companion_getInstance_26() {
  if (Companion_instance_24 === VOID)
    new Companion_23();
  return Companion_instance_24;
}
var $serializer_instance_13;
function $serializer_getInstance_13() {
  if ($serializer_instance_13 === VOID)
    new $serializer_13();
  return $serializer_instance_13;
}
function _get_$cachedSerializer__te6jhj_9($this) {
  return $this.qke_1.b2();
}
function Checker$Companion$_anonymous__y7s1qy() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.Checker', values_9());
}
var Checker_Horizontal_instance;
var Checker_None_instance;
var Checker_Vertical_instance;
var Companion_instance_25;
function Companion_getInstance_27() {
  Checker_initEntries();
  if (Companion_instance_25 === VOID)
    new Companion_24();
  return Companion_instance_25;
}
function values_9() {
  return [Checker_Horizontal_getInstance(), Checker_None_getInstance(), Checker_Vertical_getInstance()];
}
var Checker_entriesInitialized;
function Checker_initEntries() {
  if (Checker_entriesInitialized)
    return Unit_instance;
  Checker_entriesInitialized = true;
  Checker_Horizontal_instance = new Checker('Horizontal', 0, 'Horizontal');
  Checker_None_instance = new Checker('None', 1, 'None');
  Checker_Vertical_instance = new Checker('Vertical', 2, 'Vertical');
  Companion_getInstance_27();
}
function _get_$cachedSerializer__te6jhj_10($this) {
  return $this.rke_1.b2();
}
function TileMode$Companion$_anonymous__ez765a() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.TileMode', values_10());
}
var TileMode_Single_instance;
var TileMode_Stamp_instance;
var Companion_instance_26;
function Companion_getInstance_28() {
  TileMode_initEntries();
  if (Companion_instance_26 === VOID)
    new Companion_25();
  return Companion_instance_26;
}
function values_10() {
  return [TileMode_Single_getInstance(), TileMode_Stamp_getInstance()];
}
var TileMode_entriesInitialized;
function TileMode_initEntries() {
  if (TileMode_entriesInitialized)
    return Unit_instance;
  TileMode_entriesInitialized = true;
  TileMode_Single_instance = new TileMode('Single', 0, 'Single');
  TileMode_Stamp_instance = new TileMode('Stamp', 1, 'Stamp');
  Companion_getInstance_28();
}
var Companion_instance_27;
function Companion_getInstance_29() {
  if (Companion_instance_27 === VOID)
    new Companion_26();
  return Companion_instance_27;
}
var $serializer_instance_14;
function $serializer_getInstance_14() {
  if ($serializer_instance_14 === VOID)
    new $serializer_14();
  return $serializer_instance_14;
}
function LDTKJson$Companion$load$lambda($this$Json) {
  $this$Json.pjx_1 = true;
  return Unit_instance;
}
var Companion_instance_28;
function Companion_getInstance_30() {
  if (Companion_instance_28 === VOID)
    new Companion_27();
  return Companion_instance_28;
}
var $serializer_instance_15;
function $serializer_getInstance_15() {
  if ($serializer_instance_15 === VOID)
    new $serializer_15();
  return $serializer_instance_15;
}
var Companion_instance_29;
function Companion_getInstance_31() {
  if (Companion_instance_29 === VOID)
    new Companion_28();
  return Companion_instance_29;
}
var $serializer_instance_16;
function $serializer_getInstance_16() {
  if ($serializer_instance_16 === VOID)
    new $serializer_16();
  return $serializer_instance_16;
}
var Companion_instance_30;
function Companion_getInstance_32() {
  return Companion_instance_30;
}
var $serializer_instance_17;
function $serializer_getInstance_17() {
  if ($serializer_instance_17 === VOID)
    new $serializer_17();
  return $serializer_instance_17;
}
var Companion_instance_31;
function Companion_getInstance_33() {
  return Companion_instance_31;
}
var $serializer_instance_18;
function $serializer_getInstance_18() {
  if ($serializer_instance_18 === VOID)
    new $serializer_18();
  return $serializer_instance_18;
}
function _get_$cachedSerializer__te6jhj_11($this) {
  return $this.xkg_1.b2();
}
function Flag$Companion$_anonymous__vfldct() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.Flag', values_11());
}
var Flag_DiscardPreCSVIntGrid_instance;
var Flag_ExportPreCSVIntGridFormat_instance;
var Flag_IgnoreBackupSuggest_instance;
var Flag_MultiWorlds_instance;
var Flag_PrependIndexToLevelFileNames_instance;
var Flag_UseMultilinesType_instance;
var Companion_instance_32;
function Companion_getInstance_34() {
  Flag_initEntries();
  if (Companion_instance_32 === VOID)
    new Companion_31();
  return Companion_instance_32;
}
function values_11() {
  return [Flag_DiscardPreCSVIntGrid_getInstance(), Flag_ExportPreCSVIntGridFormat_getInstance(), Flag_IgnoreBackupSuggest_getInstance(), Flag_MultiWorlds_getInstance(), Flag_PrependIndexToLevelFileNames_getInstance(), Flag_UseMultilinesType_getInstance()];
}
var Flag_entriesInitialized;
function Flag_initEntries() {
  if (Flag_entriesInitialized)
    return Unit_instance;
  Flag_entriesInitialized = true;
  Flag_DiscardPreCSVIntGrid_instance = new Flag('DiscardPreCSVIntGrid', 0, 'DiscardPreCsvIntGrid');
  Flag_ExportPreCSVIntGridFormat_instance = new Flag('ExportPreCSVIntGridFormat', 1, 'ExportPreCsvIntGridFormat');
  Flag_IgnoreBackupSuggest_instance = new Flag('IgnoreBackupSuggest', 2, 'IgnoreBackupSuggest');
  Flag_MultiWorlds_instance = new Flag('MultiWorlds', 3, 'MultiWorlds');
  Flag_PrependIndexToLevelFileNames_instance = new Flag('PrependIndexToLevelFileNames', 4, 'PrependIndexToLevelFileNames');
  Flag_UseMultilinesType_instance = new Flag('UseMultilinesType', 5, 'UseMultilinesType');
  Companion_getInstance_34();
}
function _get_$cachedSerializer__te6jhj_12($this) {
  return $this.ykg_1.b2();
}
function IdentifierStyle$Companion$_anonymous__e7aoxl() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.IdentifierStyle', values_12());
}
var IdentifierStyle_Capitalize_instance;
var IdentifierStyle_Free_instance;
var IdentifierStyle_Lowercase_instance;
var IdentifierStyle_Uppercase_instance;
var Companion_instance_33;
function Companion_getInstance_35() {
  IdentifierStyle_initEntries();
  if (Companion_instance_33 === VOID)
    new Companion_32();
  return Companion_instance_33;
}
function values_12() {
  return [IdentifierStyle_Capitalize_getInstance(), IdentifierStyle_Free_getInstance(), IdentifierStyle_Lowercase_getInstance(), IdentifierStyle_Uppercase_getInstance()];
}
var IdentifierStyle_entriesInitialized;
function IdentifierStyle_initEntries() {
  if (IdentifierStyle_entriesInitialized)
    return Unit_instance;
  IdentifierStyle_entriesInitialized = true;
  IdentifierStyle_Capitalize_instance = new IdentifierStyle('Capitalize', 0, 'Capitalize');
  IdentifierStyle_Free_instance = new IdentifierStyle('Free', 1, 'Free');
  IdentifierStyle_Lowercase_instance = new IdentifierStyle('Lowercase', 2, 'Lowercase');
  IdentifierStyle_Uppercase_instance = new IdentifierStyle('Uppercase', 3, 'Uppercase');
  Companion_getInstance_35();
}
function _get_$cachedSerializer__te6jhj_13($this) {
  return $this.zkg_1.b2();
}
function ImageExportMode$Companion$_anonymous__oljsbh() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.ImageExportMode', values_13());
}
var ImageExportMode_LayersAndLevels_instance;
var ImageExportMode_None_instance;
var ImageExportMode_OneImagePerLayer_instance;
var ImageExportMode_OneImagePerLevel_instance;
var Companion_instance_34;
function Companion_getInstance_36() {
  ImageExportMode_initEntries();
  if (Companion_instance_34 === VOID)
    new Companion_33();
  return Companion_instance_34;
}
function values_13() {
  return [ImageExportMode_LayersAndLevels_getInstance(), ImageExportMode_None_getInstance(), ImageExportMode_OneImagePerLayer_getInstance(), ImageExportMode_OneImagePerLevel_getInstance()];
}
var ImageExportMode_entriesInitialized;
function ImageExportMode_initEntries() {
  if (ImageExportMode_entriesInitialized)
    return Unit_instance;
  ImageExportMode_entriesInitialized = true;
  ImageExportMode_LayersAndLevels_instance = new ImageExportMode('LayersAndLevels', 0, 'LayersAndLevels');
  ImageExportMode_None_instance = new ImageExportMode('None', 1, 'None');
  ImageExportMode_OneImagePerLayer_instance = new ImageExportMode('OneImagePerLayer', 2, 'OneImagePerLayer');
  ImageExportMode_OneImagePerLevel_instance = new ImageExportMode('OneImagePerLevel', 3, 'OneImagePerLevel');
  Companion_getInstance_36();
}
function _get_$cachedSerializer__te6jhj_14($this) {
  return $this.akh_1.b2();
}
function WorldLayout$Companion$_anonymous__u3nwqb() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.WorldLayout', values_14());
}
var WorldLayout_Free_instance;
var WorldLayout_GridVania_instance;
var WorldLayout_LinearHorizontal_instance;
var WorldLayout_LinearVertical_instance;
var Companion_instance_35;
function Companion_getInstance_37() {
  WorldLayout_initEntries();
  if (Companion_instance_35 === VOID)
    new Companion_34();
  return Companion_instance_35;
}
function values_14() {
  return [WorldLayout_Free_getInstance(), WorldLayout_GridVania_getInstance(), WorldLayout_LinearHorizontal_getInstance(), WorldLayout_LinearVertical_getInstance()];
}
var WorldLayout_entriesInitialized;
function WorldLayout_initEntries() {
  if (WorldLayout_entriesInitialized)
    return Unit_instance;
  WorldLayout_entriesInitialized = true;
  WorldLayout_Free_instance = new WorldLayout('Free', 0, 'Free');
  WorldLayout_GridVania_instance = new WorldLayout('GridVania', 1, 'GridVania');
  WorldLayout_LinearHorizontal_instance = new WorldLayout('LinearHorizontal', 2, 'LinearHorizontal');
  WorldLayout_LinearVertical_instance = new WorldLayout('LinearVertical', 3, 'LinearVertical');
  Companion_getInstance_37();
}
var Companion_instance_36;
function Companion_getInstance_38() {
  if (Companion_instance_36 === VOID)
    new Companion_35();
  return Companion_instance_36;
}
var $serializer_instance_19;
function $serializer_getInstance_19() {
  if ($serializer_instance_19 === VOID)
    new $serializer_19();
  return $serializer_instance_19;
}
var Companion_instance_37;
function Companion_getInstance_39() {
  if (Companion_instance_37 === VOID)
    new Companion_36();
  return Companion_instance_37;
}
var $serializer_instance_20;
function $serializer_getInstance_20() {
  if ($serializer_instance_20 === VOID)
    new $serializer_20();
  return $serializer_instance_20;
}
var Companion_instance_38;
function Companion_getInstance_40() {
  return Companion_instance_38;
}
var $serializer_instance_21;
function $serializer_getInstance_21() {
  if ($serializer_instance_21 === VOID)
    new $serializer_21();
  return $serializer_instance_21;
}
function _get_$cachedSerializer__te6jhj_15($this) {
  return $this.hki_1.b2();
}
function BgPos$Companion$_anonymous__iejcu8() {
  return createSimpleEnumSerializer('korlibs.korge.ldtk.BgPos', values_15());
}
var BgPos_Contain_instance;
var BgPos_Cover_instance;
var BgPos_CoverDirty_instance;
var BgPos_Unscaled_instance;
var Companion_instance_39;
function Companion_getInstance_41() {
  BgPos_initEntries();
  if (Companion_instance_39 === VOID)
    new Companion_38();
  return Companion_instance_39;
}
function values_15() {
  return [BgPos_Contain_getInstance(), BgPos_Cover_getInstance(), BgPos_CoverDirty_getInstance(), BgPos_Unscaled_getInstance()];
}
var BgPos_entriesInitialized;
function BgPos_initEntries() {
  if (BgPos_entriesInitialized)
    return Unit_instance;
  BgPos_entriesInitialized = true;
  BgPos_Contain_instance = new BgPos('Contain', 0, 'Contain');
  BgPos_Cover_instance = new BgPos('Cover', 1, 'Cover');
  BgPos_CoverDirty_instance = new BgPos('CoverDirty', 2, 'CoverDirty');
  BgPos_Unscaled_instance = new BgPos('Unscaled', 3, 'Unscaled');
  Companion_getInstance_41();
}
var Companion_instance_40;
function Companion_getInstance_42() {
  return Companion_instance_40;
}
var $serializer_instance_22;
function $serializer_getInstance_22() {
  if ($serializer_instance_22 === VOID)
    new $serializer_22();
  return $serializer_instance_22;
}
var Companion_instance_41;
function Companion_getInstance_43() {
  return Companion_instance_41;
}
var $serializer_instance_23;
function $serializer_getInstance_23() {
  if ($serializer_instance_23 === VOID)
    new $serializer_23();
  return $serializer_instance_23;
}
var Companion_instance_42;
function Companion_getInstance_44() {
  return Companion_instance_42;
}
var $serializer_instance_24;
function $serializer_getInstance_24() {
  if ($serializer_instance_24 === VOID)
    new $serializer_24();
  return $serializer_instance_24;
}
function AllowedRefs_Any_getInstance() {
  AllowedRefs_initEntries();
  return AllowedRefs_Any_instance;
}
function AllowedRefs_OnlySame_getInstance() {
  AllowedRefs_initEntries();
  return AllowedRefs_OnlySame_instance;
}
function AllowedRefs_OnlyTags_getInstance() {
  AllowedRefs_initEntries();
  return AllowedRefs_OnlyTags_instance;
}
function EditorDisplayMode_ArrayCountNoLabel_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_ArrayCountNoLabel_instance;
}
function EditorDisplayMode_ArrayCountWithLabel_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_ArrayCountWithLabel_instance;
}
function EditorDisplayMode_EntityTile_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_EntityTile_instance;
}
function EditorDisplayMode_Hidden_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_Hidden_instance;
}
function EditorDisplayMode_NameAndValue_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_NameAndValue_instance;
}
function EditorDisplayMode_PointPath_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_PointPath_instance;
}
function EditorDisplayMode_PointPathLoop_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_PointPathLoop_instance;
}
function EditorDisplayMode_PointStar_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_PointStar_instance;
}
function EditorDisplayMode_Points_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_Points_instance;
}
function EditorDisplayMode_RadiusGrid_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_RadiusGrid_instance;
}
function EditorDisplayMode_RadiusPx_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_RadiusPx_instance;
}
function EditorDisplayMode_RefLinkBetweenCenters_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_RefLinkBetweenCenters_instance;
}
function EditorDisplayMode_RefLinkBetweenPivots_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_RefLinkBetweenPivots_instance;
}
function EditorDisplayMode_ValueOnly_getInstance() {
  EditorDisplayMode_initEntries();
  return EditorDisplayMode_ValueOnly_instance;
}
function EditorDisplayPos_Above_getInstance() {
  EditorDisplayPos_initEntries();
  return EditorDisplayPos_Above_instance;
}
function EditorDisplayPos_Beneath_getInstance() {
  EditorDisplayPos_initEntries();
  return EditorDisplayPos_Beneath_instance;
}
function EditorDisplayPos_Center_getInstance() {
  EditorDisplayPos_initEntries();
  return EditorDisplayPos_Center_instance;
}
function TextLanguageMode_LangC_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangC_instance;
}
function TextLanguageMode_LangHaxe_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangHaxe_instance;
}
function TextLanguageMode_LangJS_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangJS_instance;
}
function TextLanguageMode_LangJSON_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangJSON_instance;
}
function TextLanguageMode_LangLog_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangLog_instance;
}
function TextLanguageMode_LangLua_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangLua_instance;
}
function TextLanguageMode_LangMarkdown_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangMarkdown_instance;
}
function TextLanguageMode_LangPython_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangPython_instance;
}
function TextLanguageMode_LangRuby_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangRuby_instance;
}
function TextLanguageMode_LangXML_getInstance() {
  TextLanguageMode_initEntries();
  return TextLanguageMode_LangXML_instance;
}
function LimitBehavior_DiscardOldOnes_getInstance() {
  LimitBehavior_initEntries();
  return LimitBehavior_DiscardOldOnes_instance;
}
function LimitBehavior_MoveLastOne_getInstance() {
  LimitBehavior_initEntries();
  return LimitBehavior_MoveLastOne_instance;
}
function LimitBehavior_PreventAdding_getInstance() {
  LimitBehavior_initEntries();
  return LimitBehavior_PreventAdding_instance;
}
function LimitScope_PerLayer_getInstance() {
  LimitScope_initEntries();
  return LimitScope_PerLayer_instance;
}
function LimitScope_PerLevel_getInstance() {
  LimitScope_initEntries();
  return LimitScope_PerLevel_instance;
}
function LimitScope_PerWorld_getInstance() {
  LimitScope_initEntries();
  return LimitScope_PerWorld_instance;
}
function RenderMode_Cross_getInstance() {
  RenderMode_initEntries();
  return RenderMode_Cross_instance;
}
function RenderMode_Ellipse_getInstance() {
  RenderMode_initEntries();
  return RenderMode_Ellipse_instance;
}
function RenderMode_Rectangle_getInstance() {
  RenderMode_initEntries();
  return RenderMode_Rectangle_instance;
}
function RenderMode_Tile_getInstance() {
  RenderMode_initEntries();
  return RenderMode_Tile_instance;
}
function TileRenderMode_Cover_getInstance() {
  TileRenderMode_initEntries();
  return TileRenderMode_Cover_instance;
}
function TileRenderMode_FitInside_getInstance() {
  TileRenderMode_initEntries();
  return TileRenderMode_FitInside_instance;
}
function TileRenderMode_FullSizeCropped_getInstance() {
  TileRenderMode_initEntries();
  return TileRenderMode_FullSizeCropped_instance;
}
function TileRenderMode_FullSizeUncropped_getInstance() {
  TileRenderMode_initEntries();
  return TileRenderMode_FullSizeUncropped_instance;
}
function TileRenderMode_NineSlice_getInstance() {
  TileRenderMode_initEntries();
  return TileRenderMode_NineSlice_instance;
}
function TileRenderMode_Repeat_getInstance() {
  TileRenderMode_initEntries();
  return TileRenderMode_Repeat_instance;
}
function TileRenderMode_Stretch_getInstance() {
  TileRenderMode_initEntries();
  return TileRenderMode_Stretch_instance;
}
function Type_AutoLayer_getInstance() {
  Type_initEntries();
  return Type_AutoLayer_instance;
}
function Type_Entities_getInstance() {
  Type_initEntries();
  return Type_Entities_instance;
}
function Type_IntGrid_getInstance() {
  Type_initEntries();
  return Type_IntGrid_instance;
}
function Type_Tiles_getInstance() {
  Type_initEntries();
  return Type_Tiles_instance;
}
function EmbedAtlas_LdtkIcons_getInstance() {
  EmbedAtlas_initEntries();
  return EmbedAtlas_LdtkIcons_instance;
}
function Checker_Horizontal_getInstance() {
  Checker_initEntries();
  return Checker_Horizontal_instance;
}
function Checker_None_getInstance() {
  Checker_initEntries();
  return Checker_None_instance;
}
function Checker_Vertical_getInstance() {
  Checker_initEntries();
  return Checker_Vertical_instance;
}
function TileMode_Single_getInstance() {
  TileMode_initEntries();
  return TileMode_Single_instance;
}
function TileMode_Stamp_getInstance() {
  TileMode_initEntries();
  return TileMode_Stamp_instance;
}
function Flag_DiscardPreCSVIntGrid_getInstance() {
  Flag_initEntries();
  return Flag_DiscardPreCSVIntGrid_instance;
}
function Flag_ExportPreCSVIntGridFormat_getInstance() {
  Flag_initEntries();
  return Flag_ExportPreCSVIntGridFormat_instance;
}
function Flag_IgnoreBackupSuggest_getInstance() {
  Flag_initEntries();
  return Flag_IgnoreBackupSuggest_instance;
}
function Flag_MultiWorlds_getInstance() {
  Flag_initEntries();
  return Flag_MultiWorlds_instance;
}
function Flag_PrependIndexToLevelFileNames_getInstance() {
  Flag_initEntries();
  return Flag_PrependIndexToLevelFileNames_instance;
}
function Flag_UseMultilinesType_getInstance() {
  Flag_initEntries();
  return Flag_UseMultilinesType_instance;
}
function IdentifierStyle_Capitalize_getInstance() {
  IdentifierStyle_initEntries();
  return IdentifierStyle_Capitalize_instance;
}
function IdentifierStyle_Free_getInstance() {
  IdentifierStyle_initEntries();
  return IdentifierStyle_Free_instance;
}
function IdentifierStyle_Lowercase_getInstance() {
  IdentifierStyle_initEntries();
  return IdentifierStyle_Lowercase_instance;
}
function IdentifierStyle_Uppercase_getInstance() {
  IdentifierStyle_initEntries();
  return IdentifierStyle_Uppercase_instance;
}
function ImageExportMode_LayersAndLevels_getInstance() {
  ImageExportMode_initEntries();
  return ImageExportMode_LayersAndLevels_instance;
}
function ImageExportMode_None_getInstance() {
  ImageExportMode_initEntries();
  return ImageExportMode_None_instance;
}
function ImageExportMode_OneImagePerLayer_getInstance() {
  ImageExportMode_initEntries();
  return ImageExportMode_OneImagePerLayer_instance;
}
function ImageExportMode_OneImagePerLevel_getInstance() {
  ImageExportMode_initEntries();
  return ImageExportMode_OneImagePerLevel_instance;
}
function WorldLayout_Free_getInstance() {
  WorldLayout_initEntries();
  return WorldLayout_Free_instance;
}
function WorldLayout_GridVania_getInstance() {
  WorldLayout_initEntries();
  return WorldLayout_GridVania_instance;
}
function WorldLayout_LinearHorizontal_getInstance() {
  WorldLayout_initEntries();
  return WorldLayout_LinearHorizontal_instance;
}
function WorldLayout_LinearVertical_getInstance() {
  WorldLayout_initEntries();
  return WorldLayout_LinearVertical_instance;
}
function BgPos_Contain_getInstance() {
  BgPos_initEntries();
  return BgPos_Contain_instance;
}
function BgPos_Cover_getInstance() {
  BgPos_initEntries();
  return BgPos_Cover_instance;
}
function BgPos_CoverDirty_getInstance() {
  BgPos_initEntries();
  return BgPos_CoverDirty_instance;
}
function BgPos_Unscaled_getInstance() {
  BgPos_initEntries();
  return BgPos_Unscaled_instance;
}
function get_fieldInstancesByName(_this__u8e3s4) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = fieldInstancesByName$delegate;
    var property = fieldInstancesByName$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var fieldInstancesByName$delegate;
var fieldDefsByName$delegate;
function get_entitiesByUid(_this__u8e3s4) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
    var this_0 = entitiesByUid$delegate;
    var property = entitiesByUid$factory();
    var tmp0_elvis_lhs = this_0.r13_1;
    var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    if (res == null) {
      var r = this_0.s13_1(_this__u8e3s4);
      // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
      var tmp0_elvis_lhs_0 = this_0.r13_1;
      setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var entitiesByUid$delegate;
function get_valueDyn(_this__u8e3s4) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  return _Dyn___init__impl__6cuxxc(toKotlinTypes(_this__u8e3s4 == null ? null : _this__u8e3s4.dk6_1));
}
function get_pivotAnchor(_this__u8e3s4) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  return Anchor2D.g2h(_this__u8e3s4.mk6_1.i1(0), _this__u8e3s4.mk6_1.i1(1));
}
function get_gridPos(_this__u8e3s4) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  return Vector2I.v2u(_this__u8e3s4.kk6_1[0], _this__u8e3s4.kk6_1[1]);
}
function toKotlinTypes(_this__u8e3s4) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  var tmp;
  if (_this__u8e3s4 == null) {
    tmp = null;
  } else {
    if (_this__u8e3s4 instanceof JsonNull) {
      tmp = null;
    } else {
      if (_this__u8e3s4 instanceof JsonArray) {
        // Inline function 'kotlin.collections.map' call
        var this_0 = toList(_this__u8e3s4);
        // Inline function 'kotlin.collections.mapTo' call
        var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
        var tmp0_iterator = this_0.f1();
        while (tmp0_iterator.g1()) {
          var item = tmp0_iterator.h1();
          // Inline function 'korlibs.korge.ldtk.toKotlinTypes.<anonymous>' call
          var tmp$ret$0 = toKotlinTypes(item);
          destination.f(tmp$ret$0);
        }
        tmp = destination;
      } else {
        if (_this__u8e3s4 instanceof JsonObject) {
          // Inline function 'kotlin.collections.mapValues' call
          var this_1 = toMap(_this__u8e3s4);
          // Inline function 'kotlin.collections.mapValuesTo' call
          var destination_0 = LinkedHashMap.wc(mapCapacity(this_1.j1()));
          // Inline function 'kotlin.collections.associateByTo' call
          var tmp0_iterator_0 = this_1.z1().f1();
          while (tmp0_iterator_0.g1()) {
            var element = tmp0_iterator_0.h1();
            // Inline function 'kotlin.collections.mapValuesTo.<anonymous>' call
            var tmp_0 = element.a2();
            // Inline function 'korlibs.korge.ldtk.toKotlinTypes.<anonymous>' call
            var tmp$ret$4 = toKotlinTypes(element.b2());
            destination_0.r3(tmp_0, tmp$ret$4);
          }
          tmp = destination_0;
        } else {
          if (_this__u8e3s4 instanceof JsonPrimitive) {
            var content = _this__u8e3s4.fjz();
            var tmp_1;
            if (_this__u8e3s4.ejz()) {
              tmp_1 = content;
            } else if (content === 'undefined') {
              tmp_1 = null;
            } else if (content === 'true') {
              tmp_1 = true;
            } else if (content === 'false') {
              tmp_1 = false;
            } else {
              var tmp0_elvis_lhs = toDoubleOrNull(content);
              tmp_1 = tmp0_elvis_lhs == null ? NaN : tmp0_elvis_lhs;
            }
            tmp = tmp_1;
          } else {
            unexpected("Don't know how to parse " + toString(_this__u8e3s4));
          }
        }
      }
    }
  }
  return tmp;
}
function fieldInstancesByName$delegate$lambda($this$PropertyThis) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  // Inline function 'kotlin.collections.associateBy' call
  var this_0 = $this$PropertyThis.rk6_1;
  var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
  // Inline function 'kotlin.collections.associateByTo' call
  var destination = LinkedHashMap.wc(capacity);
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'korlibs.korge.ldtk.fieldInstancesByName$delegate.<anonymous>.<anonymous>' call
    var tmp$ret$0 = element.ak6_1;
    destination.r3(tmp$ret$0, element);
  }
  return destination;
}
function fieldDefsByName$delegate$lambda($this$PropertyThis) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  // Inline function 'kotlin.collections.associateBy' call
  var this_0 = $this$PropertyThis.nk8_1;
  var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
  // Inline function 'kotlin.collections.associateByTo' call
  var destination = LinkedHashMap.wc(capacity);
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'korlibs.korge.ldtk.fieldDefsByName$delegate.<anonymous>.<anonymous>' call
    var tmp$ret$0 = element.tk7_1;
    destination.r3(tmp$ret$0, element);
  }
  return destination;
}
function entitiesByUid$delegate$lambda($this$PropertyThis) {
  _init_properties_LDTKJsonExt_kt__q9kc60();
  // Inline function 'kotlin.collections.associateBy' call
  var this_0 = $this$PropertyThis.pk9_1;
  var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
  // Inline function 'kotlin.collections.associateByTo' call
  var destination = LinkedHashMap.wc(capacity);
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'korlibs.korge.ldtk.entitiesByUid$delegate.<anonymous>.<anonymous>' call
    var tmp$ret$0 = element.jk9_1;
    destination.r3(tmp$ret$0, element);
  }
  return destination;
}
function fieldInstancesByName$factory() {
  return getPropertyCallableRef('fieldInstancesByName', 1, KProperty1, function (receiver) {
    return get_fieldInstancesByName(receiver);
  }, null);
}
function entitiesByUid$factory() {
  return getPropertyCallableRef('entitiesByUid', 1, KProperty1, function (receiver) {
    return get_entitiesByUid(receiver);
  }, null);
}
var properties_initialized_LDTKJsonExt_kt_byyf7e;
function _init_properties_LDTKJsonExt_kt__q9kc60() {
  if (!properties_initialized_LDTKJsonExt_kt_byyf7e) {
    properties_initialized_LDTKJsonExt_kt_byyf7e = true;
    fieldInstancesByName$delegate = new PropertyThis(VOID, fieldInstancesByName$delegate$lambda);
    fieldDefsByName$delegate = new PropertyThis(VOID, fieldDefsByName$delegate$lambda);
    entitiesByUid$delegate = new PropertyThis(VOID, entitiesByUid$delegate$lambda);
  }
}
function LDTKWorld$levels$delegate$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.collections.map' call
    var this_0 = this$0.rkm_1.wkh_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKWorld.levels$delegate.<anonymous>.<anonymous>' call
      var tmp$ret$0 = new LDTKLevel(this$0, item);
      destination.f(tmp$ret$0);
    }
    return destination;
  };
}
function LDTKWorld$levelsByName$delegate$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = this$0.zkm();
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.wc(capacity);
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKWorld.levelsByName$delegate.<anonymous>.<anonymous>' call
      var tmp$ret$0 = element.bkn_1.vki_1;
      destination.r3(tmp$ret$0, element);
    }
    return destination;
  };
}
function *_generator_readLDTKWorld__pombw9(_this__u8e3s4, extrude_0, $completion) {
  extrude_0 = extrude_0 === VOID ? true : extrude_0;
  var file = _this__u8e3s4;
  var tmp = file.y22(VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var json = tmp;
  var ldtk = Companion_getInstance_30().dab(json);
  // Inline function 'kotlin.collections.associate' call
  var this_0 = ldtk.okh_1.uk9_1;
  var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
  // Inline function 'kotlin.collections.associateTo' call
  var destination = LinkedHashMap.wc(capacity);
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'kotlin.collections.plusAssign' call
    // Inline function 'korlibs.korge.ldtk.view.readLDTKWorld.<anonymous>' call
    var tmp7_safe_receiver = element.akd_1;
    var tmp_0;
    if (tmp7_safe_receiver == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ldtk.view.readLDTKWorld.<anonymous>.<anonymous>' call
      var tmp_1 = readBitmap(file.w15().bj(tmp7_safe_receiver), VOID, $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      var bmp = tmp_1;
      tmp_0 = extrude_0 ? bmp.o4q() : bmp;
    }
    var bitmap = tmp_0;
    var tmp_2;
    if (bitmap == null) {
      tmp_2 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ldtk.view.readLDTKWorld.<anonymous>.<anonymous>' call
      tmp_2 = Companion_getInstance_0().r71(slice(bitmap), element.fkd_1, element.fkd_1);
    }
    var unextrudedTileSet = tmp_2;
    var tmp_3;
    if (unextrudedTileSet == null) {
      tmp_3 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.ldtk.view.readLDTKWorld.<anonymous>.<anonymous>' call
      tmp_3 = extrude_0 ? extrude(unextrudedTileSet, 2) : unextrudedTileSet;
    }
    var tileSet = tmp_3;
    var pair = to(element.gkd_1, new ExtTileset(element, tileSet, unextrudedTileSet));
    destination.r3(pair.im_1, pair.jm_1);
  }
  var tilesetDefsById = destination;
  return new LDTKWorld(ldtk, tilesetDefsById, extrude_0);
}
function readLDTKWorld(_this__u8e3s4, extrude, $completion) {
  extrude = extrude === VOID ? true : extrude;
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readLDTKWorld__pombw9(_this__u8e3s4, extrude, $completion), $completion);
}
function LDTKLevel$layers$delegate$lambda(this$0) {
  return function () {
    var tmp6_safe_receiver = this$0.bkn_1.xki_1;
    var tmp;
    if (tmp6_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList.w(collectionSizeOrDefault(tmp6_safe_receiver, 10));
      var tmp0_iterator = tmp6_safe_receiver.f1();
      while (tmp0_iterator.g1()) {
        var item = tmp0_iterator.h1();
        // Inline function 'korlibs.korge.ldtk.view.LDTKLevel.layers$delegate.<anonymous>.<anonymous>' call
        var tmp$ret$0 = new LDTKLayer(this$0, item);
        destination.f(tmp$ret$0);
      }
      tmp = destination;
    }
    var tmp0_elvis_lhs = tmp;
    return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
  };
}
function LDTKLevel$layersByName$delegate$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = this$0.fkn();
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.wc(capacity);
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.korge.ldtk.view.LDTKLevel.layersByName$delegate.<anonymous>.<anonymous>' call
      var tmp$ret$0 = element.hkn_1.ckg_1;
      destination.r3(tmp$ret$0, element);
    }
    return destination;
  };
}
function _get_ldtk__d9qlvg($this) {
  return $this.skp_1.ikn();
}
function _get_blevel__xqeqs7($this) {
  return $this.skp_1.bkn_1;
}
function extrude(_this__u8e3s4, border, mipmaps) {
  border = border === VOID ? 1 : border;
  mipmaps = mipmaps === VOID ? false : mipmaps;
  // Inline function 'kotlin.collections.map' call
  var this_0 = _this__u8e3s4.d72();
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(this_0.length);
  var inductionVariable = 0;
  var last = this_0.length;
  while (inductionVariable < last) {
    var item = this_0[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'korlibs.korge.ldtk.view.extrude.<anonymous>' call
    var tmp$ret$0 = extract(item instanceof RectSlice ? item : THROW_CCE()).o4q();
    destination.f(tmp$ret$0);
  }
  var bitmaps = destination;
  return Companion_getInstance_0().s71(_this__u8e3s4.w71_1, _this__u8e3s4.x71_1, bitmaps, border, mipmaps);
}
function levels$factory() {
  return getPropertyCallableRef('levels', 1, KProperty1, function (receiver) {
    return receiver.zkm();
  }, null);
}
function levelsByName$factory() {
  return getPropertyCallableRef('levelsByName', 1, KProperty1, function (receiver) {
    return receiver.ekn();
  }, null);
}
function layers$factory() {
  return getPropertyCallableRef('layers', 1, KProperty1, function (receiver) {
    return receiver.fkn();
  }, null);
}
function layersByName$factory() {
  return getPropertyCallableRef('layersByName', 1, KProperty1, function (receiver) {
    return receiver.jkn();
  }, null);
}
//region block: post-declaration
initMetadataForCompanion(Companion);
protoOf($serializer).vjp = typeParametersSerializers;
initMetadataForObject($serializer, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(FieldInstance, 'FieldInstance', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance});
initMetadataForCompanion(Companion_0);
protoOf($serializer_0).vjp = typeParametersSerializers;
initMetadataForObject($serializer_0, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(EntityInstance, 'EntityInstance', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_0});
initMetadataForCompanion(Companion_1);
protoOf($serializer_1).vjp = typeParametersSerializers;
initMetadataForObject($serializer_1, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(FieldDefinition, 'FieldDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_1});
initMetadataForCompanion(Companion_2);
protoOf($serializer_2).vjp = typeParametersSerializers;
initMetadataForObject($serializer_2, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(EntityDefinition, 'EntityDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_2});
initMetadataForCompanion(Companion_3);
protoOf($serializer_3).vjp = typeParametersSerializers;
initMetadataForObject($serializer_3, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(Definitions, 'Definitions', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_3});
initMetadataForCompanion(Companion_4);
protoOf($serializer_4).vjp = typeParametersSerializers;
initMetadataForObject($serializer_4, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(TilesetRectangle, 'TilesetRectangle', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_4});
initMetadataForCompanion(Companion_5, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(AllowedRefs, 'AllowedRefs', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_8});
initMetadataForCompanion(Companion_6, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(EditorDisplayMode, 'EditorDisplayMode', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_9});
initMetadataForCompanion(Companion_7, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(EditorDisplayPos, 'EditorDisplayPos', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_10});
initMetadataForCompanion(Companion_8, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(TextLanguageMode, 'TextLanguageMode', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_11});
initMetadataForCompanion(Companion_9, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(LimitBehavior, 'LimitBehavior', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_12});
initMetadataForCompanion(Companion_10, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(LimitScope, 'LimitScope', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_13});
initMetadataForCompanion(Companion_11, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(RenderMode, 'RenderMode', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_14});
initMetadataForCompanion(Companion_12, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(TileRenderMode, 'TileRenderMode', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_15});
initMetadataForCompanion(Companion_13);
protoOf($serializer_5).vjp = typeParametersSerializers;
initMetadataForObject($serializer_5, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(EnumDefinition, 'EnumDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_5});
initMetadataForCompanion(Companion_14);
protoOf($serializer_6).vjp = typeParametersSerializers;
initMetadataForObject($serializer_6, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(LayerDefinition, 'LayerDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_6});
initMetadataForCompanion(Companion_15);
protoOf($serializer_7).vjp = typeParametersSerializers;
initMetadataForObject($serializer_7, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(TilesetDefinition, 'TilesetDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_7});
initMetadataForCompanion(Companion_16);
protoOf($serializer_8).vjp = typeParametersSerializers;
initMetadataForObject($serializer_8, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(EnumValueDefinition, 'EnumValueDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_8});
initMetadataForCompanion(Companion_17);
protoOf($serializer_9).vjp = typeParametersSerializers;
initMetadataForObject($serializer_9, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(AutoLayerRuleGroup, 'AutoLayerRuleGroup', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_9});
initMetadataForCompanion(Companion_18);
protoOf($serializer_10).vjp = typeParametersSerializers;
initMetadataForObject($serializer_10, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(IntGridValueDefinition, 'IntGridValueDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_10});
initMetadataForCompanion(Companion_19, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(Type, 'Type', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_22});
initMetadataForCompanion(Companion_20);
protoOf($serializer_11).vjp = typeParametersSerializers;
initMetadataForObject($serializer_11, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(TileCustomMetadata, 'TileCustomMetadata', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_11});
initMetadataForCompanion(Companion_21, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(EmbedAtlas, 'EmbedAtlas', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_24});
initMetadataForCompanion(Companion_22);
protoOf($serializer_12).vjp = typeParametersSerializers;
initMetadataForObject($serializer_12, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(EnumTagValue, 'EnumTagValue', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_12});
initMetadataForCompanion(Companion_23);
protoOf($serializer_13).vjp = typeParametersSerializers;
initMetadataForObject($serializer_13, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(AutoLayerRuleDefinition, 'AutoLayerRuleDefinition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_13});
initMetadataForCompanion(Companion_24, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(Checker, 'Checker', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_27});
initMetadataForCompanion(Companion_25, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(TileMode, 'TileMode', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_28});
initMetadataForCompanion(Companion_26);
protoOf($serializer_14).vjp = typeParametersSerializers;
initMetadataForObject($serializer_14, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(LayerInstance, 'LayerInstance', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_14});
initMetadataForCompanion(Companion_27);
protoOf($serializer_15).vjp = typeParametersSerializers;
initMetadataForObject($serializer_15, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(LDTKJson, 'LDTKJson', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_15});
initMetadataForCompanion(Companion_28);
protoOf($serializer_16).vjp = typeParametersSerializers;
initMetadataForObject($serializer_16, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(Level, 'Level', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_16});
initMetadataForCompanion(Companion_29);
protoOf($serializer_17).vjp = typeParametersSerializers;
initMetadataForObject($serializer_17, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(TileInstance, 'TileInstance', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_17});
initMetadataForCompanion(Companion_30);
protoOf($serializer_18).vjp = typeParametersSerializers;
initMetadataForObject($serializer_18, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(ForcedRefs, 'ForcedRefs', ForcedRefs, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_18});
initMetadataForCompanion(Companion_31, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(Flag, 'Flag', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_34});
initMetadataForCompanion(Companion_32, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(IdentifierStyle, 'IdentifierStyle', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_35});
initMetadataForCompanion(Companion_33, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(ImageExportMode, 'ImageExportMode', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_36});
initMetadataForCompanion(Companion_34, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(WorldLayout, 'WorldLayout', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_37});
initMetadataForCompanion(Companion_35);
protoOf($serializer_19).vjp = typeParametersSerializers;
initMetadataForObject($serializer_19, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(World, 'World', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_19});
initMetadataForCompanion(Companion_36);
protoOf($serializer_20).vjp = typeParametersSerializers;
initMetadataForObject($serializer_20, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(LevelBackgroundPosition, 'LevelBackgroundPosition', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_20});
initMetadataForCompanion(Companion_37);
protoOf($serializer_21).vjp = typeParametersSerializers;
initMetadataForObject($serializer_21, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(NeighbourLevel, 'NeighbourLevel', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_21});
initMetadataForCompanion(Companion_38, VOID, [KSerializer, SerializerFactory]);
initMetadataForClass(BgPos, 'BgPos', VOID, VOID, VOID, VOID, VOID, {0: Companion_getInstance_41});
initMetadataForCompanion(Companion_39);
protoOf($serializer_22).vjp = typeParametersSerializers;
initMetadataForObject($serializer_22, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(FieldInstanceEntityReference, 'FieldInstanceEntityReference', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_22});
initMetadataForCompanion(Companion_40);
protoOf($serializer_23).vjp = typeParametersSerializers;
initMetadataForObject($serializer_23, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(FieldInstanceGridPoint, 'FieldInstanceGridPoint', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_23});
initMetadataForCompanion(Companion_41);
protoOf($serializer_24).vjp = typeParametersSerializers;
initMetadataForObject($serializer_24, '$serializer', VOID, VOID, [GeneratedSerializer]);
initMetadataForClass(IntGridValueInstance, 'IntGridValueInstance', VOID, VOID, VOID, VOID, VOID, {0: $serializer_getInstance_24});
initMetadataForClass(FieldInfo, 'FieldInfo');
initMetadataForClass(LDTKWorld, 'LDTKWorld');
initMetadataForClass(LDTKLevel, 'LDTKLevel');
initMetadataForClass(LDTKLayer, 'LDTKLayer');
initMetadataForClass(LDTKLevelView, 'LDTKLevelView');
initMetadataForClass(LDTKLayerView, 'LDTKLayerView');
initMetadataForClass(LDTKEntityView, 'LDTKEntityView');
initMetadataForClass(ExtTileset, 'ExtTileset');
//endregion
//region block: init
Companion_instance_0 = new Companion();
Companion_instance_5 = new Companion_4();
Companion_instance_17 = new Companion_16();
Companion_instance_19 = new Companion_18();
Companion_instance_21 = new Companion_20();
Companion_instance_23 = new Companion_22();
Companion_instance_30 = new Companion_29();
Companion_instance_31 = new Companion_30();
Companion_instance_38 = new Companion_37();
Companion_instance_40 = new Companion_39();
Companion_instance_41 = new Companion_40();
Companion_instance_42 = new Companion_41();
//endregion
//region block: exports
export {
  readLDTKWorld as readLDTKWorldoxv5q0mconb9,
  LDTKEntityView as LDTKEntityView1tvqcq5wilt12,
  LDTKLevelView as LDTKLevelView17570hdupn1fv,
  get_fieldInstancesByName as get_fieldInstancesByName39gyux4395th3,
  get_valueDyn as get_valueDyn2yc4p070m9qf2,
};
//endregion
