import {
  atomic$boolean$1iggki4z65a2h as atomic$boolean$1,
  get_atomicfu$reentrantLock3o926yhldwf1 as get_atomicfu$reentrantLock,
  atomic$long$129k9zwo6n9ogd as atomic$long$1,
  atomic$int$11d5swdyn6j0pu as atomic$int$1,
} from './kotlinx-atomicfu.mjs';
import {
  Long2qws0ah9gnpki as Long,
  Unit_instance104q5opgivhr8 as Unit_instance,
  UnsupportedOperationException2tkumpmhredt3 as UnsupportedOperationException,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  VOID7hggqo3abtya as VOID,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  DurationUnit_SECONDS_getInstance3jias9ne5z4er as DurationUnit_SECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  CancellationException3b36o9qz53rgr as CancellationException,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isInterface3d6p8outrmvmk as isInterface,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  AutoCloseable1l5p57f9lp7kv as AutoCloseable,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  get6d5x931vk0s as get,
  protoOf180f3jzyo7rfj as protoOf,
  fold36i9psb7d5v48 as fold,
  minusKeyyqanvso9aovh as minusKey,
  plusolev77jfy5r9 as plus,
  Element2gr7ezmxqaln7 as Element,
  Companion_instance3fplhgf4ipvld as Companion_instance,
  _Result___init__impl__xyqfz831xktsyjq1qrq as _Result___init__impl__xyqfz8,
  intercepted2ogpsikxxj4u0 as intercepted,
  ArrayList3it5z8td81qkl as ArrayList,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  startCoroutineUninterceptedOrReturnGeneratorVersion1r5yf56916wr4 as startCoroutineUninterceptedOrReturnGeneratorVersion,
  createFailure8paxfkfa5dc7 as createFailure,
  AbstractCoroutineContextElement2rpehg0hv5szw as AbstractCoroutineContextElement,
  Key_instance2d3ch37kcwr5h as Key_instance,
  getxe4seun860fg as get_0,
  minusKey2uxs00uz5ceqp as minusKey_0,
  releaseInterceptedContinuation17jnf0xkuoovi as releaseInterceptedContinuation,
  ContinuationInterceptor2624y0vaqwxwf as ContinuationInterceptor,
  Result__exceptionOrNull_impl_p6xea91phgcskd46fkx as Result__exceptionOrNull_impl_p6xea9,
  throwOnFailure24snjmtlqgzo8 as throwOnFailure,
  _Result___get_value__impl__bjfvqg1uwg4i093qaqh as _Result___get_value__impl__bjfvqg,
  printStackTrace18lnx7a39cni as printStackTrace,
  Continuation1aa2oekvx7jm7 as Continuation,
  println2shhhgwwt4c61 as println,
  lazy2hsh8ze7j6ikd as lazy,
  EmptyCoroutineContext_getInstance2bxophqwsfm9n as EmptyCoroutineContext_getInstance,
} from './kotlin-kotlin-stdlib.mjs';
import {
  CoroutineScopelux7s7zphw7e as CoroutineScope,
  launch1c91vkjzdi9sd as launch,
  delay1a7xkalnslpld as delay,
  CoroutineScopefcb5f5dwqcas as CoroutineScope_0,
  CancellableContinuationImpl1cx201opicavg as CancellableContinuationImpl,
  invokeOnTimeout3llts2igdsa63 as invokeOnTimeout,
  Delay9umexudtwyie as Delay,
  CoroutineDispatcherizb7p9bijexj as CoroutineDispatcher,
  Dispatchers_getInstance1nk2l7rcqz5wi as Dispatchers_getInstance,
} from './kotlinx-coroutines-core.mjs';
import { FastArrayListw5fr1wlp4slq as FastArrayList } from './korlibs-korlibs-datastructure-core.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class BaseLock {}
class Lock extends BaseLock {
  constructor() {
    super();
    this.s1w_1 = atomic$boolean$1(false);
    var tmp = this;
    // Inline function 'kotlinx.atomicfu.locks.reentrantLock' call
    tmp.t1w_1 = get_atomicfu$reentrantLock();
    this.u1w_1 = atomic$long$1(new Long(0, 0));
    this.v1w_1 = atomic$int$1(0);
  }
  w1w() {
    // Inline function 'kotlinx.atomicfu.locks.ReentrantLock.lock' call
    this.t1w_1;
    this.v1w_1.atomicfu$incrementAndGet();
    this.u1w_1.kotlinx$atomicfu$value = Companion_getInstance_1().y1w();
  }
  z1w() {
    // Inline function 'kotlinx.atomicfu.locks.ReentrantLock.unlock' call
    this.t1w_1;
    this.v1w_1.atomicfu$decrementAndGet();
  }
  a1x(unit) {
    if (!Companion_instance_0.b1x())
      throw UnsupportedOperationException.u8();
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.v1w_1.kotlinx$atomicfu$value > 0)) {
      // Inline function 'korlibs.concurrent.lock.Lock.notify.<anonymous>' call
      var message = 'Must wait inside a synchronization block';
      throw IllegalStateException.m5(toString(message));
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!this.u1w_1.kotlinx$atomicfu$value.equals(Companion_getInstance_1().y1w())) {
      // Inline function 'korlibs.concurrent.lock.Lock.notify.<anonymous>' call
      var message_0 = 'Must lock the notify thread';
      throw IllegalStateException.m5(toString(message_0));
    }
    this.s1w_1.kotlinx$atomicfu$value = true;
  }
  c1x(unit, $super) {
    unit = unit === VOID ? Unit_instance : unit;
    var tmp;
    if ($super === VOID) {
      this.a1x(unit);
      tmp = Unit_instance;
    } else {
      var tmp_0 = $super.a1x;
      tmp = tmp_0.call(this, Unit_instance);
    }
    return tmp;
  }
}
class NonRecursiveLock extends BaseLock {
  constructor() {
    super();
    this.d1x_1 = atomic$int$1(0);
  }
  w1w() {
    if (Companion_getInstance_1().b1x()) {
      // Inline function 'korlibs.concurrent.thread.Companion.spinWhile' call
      Companion_getInstance_1();
      $l$loop: while (true) {
        // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.lock.<anonymous>' call
        if (!!this.d1x_1.atomicfu$compareAndSet(0, 1)) {
          break $l$loop;
        }
      }
    }
  }
  z1w() {
    if (Companion_getInstance_1().b1x()) {
      // Inline function 'korlibs.concurrent.thread.Companion.spinWhile' call
      Companion_getInstance_1();
      $l$loop: while (true) {
        // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.unlock.<anonymous>' call
        if (!!this.d1x_1.atomicfu$compareAndSet(1, 0)) {
          break $l$loop;
        }
      }
    }
  }
}
class Companion {
  b1x() {
    return Companion_getInstance_1().b1x();
  }
}
class onCancel$slambda {
  constructor($running, $block) {
    this.e1x_1 = $running;
    this.f1x_1 = $block;
  }
  o1s($this$launch, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$launch, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class _no_name_provided__qut3iv {
  constructor($running) {
    this.g1x_1 = $running;
  }
  u6() {
    this.g1x_1._v = false;
    return Unit_instance;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_1 = this;
    this.h1x_1 = new PreferSyncIo(true);
    this.i1x_1 = new PreferSyncIo(false);
  }
  j1x(preferSyncIo) {
    return preferSyncIo === true ? this.h1x_1 : this.i1x_1;
  }
}
class PreferSyncIo {
  constructor(preferSyncIo) {
    Companion_getInstance_0();
    this.k1x_1 = preferSyncIo;
  }
  a2() {
    return Companion_getInstance_0();
  }
  toString() {
    return 'PreferSyncIo(preferSyncIo=' + this.k1x_1 + ')';
  }
  hashCode() {
    return getBooleanHashCode(this.k1x_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof PreferSyncIo))
      return false;
    var tmp0_other_with_cast = other instanceof PreferSyncIo ? other : THROW_CCE();
    if (!(this.k1x_1 === tmp0_other_with_cast.k1x_1))
      return false;
    return true;
  }
}
class BaseSignal {
  constructor(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = BaseSignal$_init_$lambda_tuu04y;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    this.q1x_1 = onRegister;
    this.r1x_1 = FastArrayList.g14();
    this.s1x_1 = FastArrayList.g14();
    this.t1x_1 = 0;
  }
  c1y() {
    return this.r1x_1.j1();
  }
  d1y() {
    return this.c1y() > 0;
  }
  u1x(once, handler) {
    this.q1x_1();
    var node = new Node(this, once, handler);
    this.r1x_1.f(node);
    return node;
  }
}
class Signal extends BaseSignal {
  constructor(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = Signal$_init_$lambda_6vimhp;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    super(onRegister);
  }
  p1x(handler) {
    return this.u1x(true, handler);
  }
  v1x(handler) {
    return this.u1x(false, handler);
  }
  w1x(handler) {
    return this.v1x(handler);
  }
  x1x(value) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks' call
      if (this.r1x_1.a1()) {
        tmp$ret$0 = Unit_instance;
        break $l$block;
      }
      try {
        this.t1x_1 = this.t1x_1 + 1 | 0;
        // Inline function 'korlibs.io.async.fastIterateRemove' call
        var this_0 = this.r1x_1;
        var n = 0;
        var m = 0;
        while (n < this_0.j1()) {
          if (m >= 0 && !(m === n)) {
            this_0.p3(m, this_0.i1(n));
          }
          // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks.<anonymous>' call
          var node = this_0.i1(n);
          var remove = node.y1x_1;
          // Inline function 'korlibs.io.async.Signal.invoke.<anonymous>' call
          node.z1x_1(value);
          if (remove) {
            m = m - 1 | 0;
          }
          n = n + 1 | 0;
          m = m + 1 | 0;
        }
        while (this_0.j1() > m) {
          this_0.q3(this_0.j1() - 1 | 0);
        }
      }finally {
        this.t1x_1 = this.t1x_1 - 1 | 0;
        // Inline function 'kotlin.collections.isNotEmpty' call
        if (!this.s1x_1.a1()) {
          // Inline function 'korlibs.io.async.fastIterateRemove' call
          var this_1 = this.s1x_1;
          var n_0 = 0;
          var m_0 = 0;
          while (n_0 < this_1.j1()) {
            if (m_0 >= 0 && !(m_0 === n_0)) {
              this_1.p3(m_0, this_1.i1(n_0));
            }
            // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks.<anonymous>' call
            var it = this_1.i1(n_0);
            this.r1x_1.n3(it);
            if (true) {
              m_0 = m_0 - 1 | 0;
            }
            n_0 = n_0 + 1 | 0;
            m_0 = m_0 + 1 | 0;
          }
          while (this_1.j1() > m_0) {
            this_1.q3(this_1.j1() - 1 | 0);
          }
        }
      }
    }
    return tmp$ret$0;
  }
  b1y($completion) {
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
    cancellable.u19();
    // Inline function 'korlibs.io.async.Signal.waitOneBase.<anonymous>' call
    var close = {_v: null};
    close._v = this.p1x(Signal$waitOneBase$lambda(close, cancellable));
    cancellable.c18(Signal$waitOneBase$lambda_0(close));
    return cancellable.g17();
  }
}
class AsyncSignal extends BaseSignal {
  constructor(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = AsyncSignal$_init_$lambda_rsv6fb;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    super(onRegister);
  }
  i1y(handler) {
    return this.u1x(false, handler);
  }
  j1y(handler) {
    return this.i1y(handler);
  }
  o1l(value, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_0(this, value, $completion), $completion);
  }
}
class Node {
  constructor($outer, once, item) {
    this.a1y_1 = $outer;
    this.y1x_1 = once;
    this.z1x_1 = item;
  }
  u6() {
    if (this.a1y_1.t1x_1 > 0) {
      this.a1y_1.s1x_1.f(this);
    } else {
      this.a1y_1.r1x_1.n3(this);
    }
  }
}
class BaseSignal2 {
  constructor(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = BaseSignal2$_init_$lambda_4fc7ce;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    this.p1y_1 = onRegister;
    this.q1y_1 = ArrayList.m1();
    this.r1y_1 = ArrayList.m1();
    this.s1y_1 = 0;
  }
  t1y(once, handler) {
    this.p1y_1();
    var node = new Node_0(this, once, handler);
    this.q1y_1.f(node);
    return node;
  }
}
class Signal2 extends BaseSignal2 {
  constructor(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = Signal2$_init_$lambda_6met8f;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    super(onRegister);
  }
  o1y(handler) {
    return this.t1y(false, handler);
  }
  u1y(handler) {
    return this.o1y(handler);
  }
  v1y(value1, value2) {
    try {
      this.s1y_1 = this.s1y_1 + 1 | 0;
      // Inline function 'korlibs.io.async.fastIterateRemove' call
      var this_0 = this.q1y_1;
      var n = 0;
      var m = 0;
      while (n < this_0.j1()) {
        if (m >= 0 && !(m === n)) {
          this_0.p3(m, this_0.i1(n));
        }
        // Inline function 'korlibs.io.async.BaseSignal2.iterateCallbacks.<anonymous>' call
        var node = this_0.i1(n);
        var remove = node.w1y_1;
        // Inline function 'korlibs.io.async.Signal2.invoke.<anonymous>' call
        node.x1y_1(value1, value2);
        if (remove) {
          m = m - 1 | 0;
        }
        n = n + 1 | 0;
        m = m + 1 | 0;
      }
      while (this_0.j1() > m) {
        this_0.q3(this_0.j1() - 1 | 0);
      }
    }finally {
      this.s1y_1 = this.s1y_1 - 1 | 0;
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!this.r1y_1.a1()) {
        // Inline function 'korlibs.io.async.fastIterateRemove' call
        var this_1 = this.r1y_1;
        var n_0 = 0;
        var m_0 = 0;
        while (n_0 < this_1.j1()) {
          if (m_0 >= 0 && !(m_0 === n_0)) {
            this_1.p3(m_0, this_1.i1(n_0));
          }
          // Inline function 'korlibs.io.async.BaseSignal2.iterateCallbacks.<anonymous>' call
          var it = this_1.i1(n_0);
          this.q1y_1.n3(it);
          if (true) {
            m_0 = m_0 - 1 | 0;
          }
          n_0 = n_0 + 1 | 0;
          m_0 = m_0 + 1 | 0;
        }
        while (this_1.j1() > m_0) {
          this_1.q3(this_1.j1() - 1 | 0);
        }
      }
    }
    return Unit_instance;
  }
}
class Node_0 {
  constructor($outer, once, item) {
    this.y1y_1 = $outer;
    this.w1y_1 = once;
    this.x1y_1 = item;
  }
  u6() {
    if (this.y1y_1.s1y_1 > 0) {
      this.y1y_1.r1y_1.f(this);
    } else {
      this.y1y_1.q1y_1.n3(this);
    }
  }
}
class runBlockingNoSuspensions$1$context$1 extends AbstractCoroutineContextElement {
  static c1z($suspendCount, $box) {
    if ($box === VOID)
      $box = {};
    $box.b1z_1 = $suspendCount;
    var $this = this.co(Key_instance, $box);
    $this.a1z_1 = Key_instance;
    return $this;
  }
  a2() {
    return this.a1z_1;
  }
  ud(continuation) {
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.async.<no name provided>.interceptContinuation.<anonymous>' call
    var _unary__edvuaz = this.b1z_1._v;
    this.b1z_1._v = _unary__edvuaz + 1 | 0;
    return continuation;
  }
  d1c(timeMillis, continuation) {
    // Inline function 'kotlin.coroutines.resume' call
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    continuation.id(tmp$ret$0);
    return Unit_instance;
  }
}
class runBlockingNoSuspensions$1 {
  constructor($suspendCount, $resultEx, $completed, $rresult) {
    this.e1z_1 = $resultEx;
    this.f1z_1 = $completed;
    this.g1z_1 = $rresult;
    var tmp = this;
    tmp.d1z_1 = runBlockingNoSuspensions$1$context$1.c1z($suspendCount);
  }
  gd() {
    return this.d1z_1;
  }
  h1z(result) {
    var exception = Result__exceptionOrNull_impl_p6xea9(result);
    if (!(exception == null)) {
      this.e1z_1._v = exception;
      this.f1z_1._v = true;
      printStackTrace(exception);
    } else {
      // Inline function 'kotlin.getOrThrow' call
      throwOnFailure(result);
      var tmp = _Result___get_value__impl__bjfvqg(result);
      var tmp0_elvis_lhs = (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        _get_unitInstance__yoa8mq(this);
        var tmp_1 = Unit_instance;
        var tmp_2;
        if (!(Unit_instance == null)) {
          tmp_2 = Unit_instance;
        } else {
          tmp_2 = THROW_CCE();
        }
        tmp_0 = tmp_2;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var rvalue = tmp_0;
      this.g1z_1._v = rvalue;
      this.f1z_1._v = true;
    }
  }
  id(result) {
    return this.h1z(result);
  }
}
class createRedirectedDispatcher$1 extends CoroutineDispatcher {
  static l1z($parent, $name, $box) {
    if ($box === VOID)
      $box = {};
    $box.j1z_1 = $parent;
    $box.k1z_1 = $name;
    return this.t1b($box);
  }
  u6() {
    return Unit_instance;
  }
  v1b(context, block) {
    return this.j1z_1.v1b(context, block);
  }
  u1b(context) {
    return this.j1z_1.u1b(context);
  }
  toString() {
    return 'Dispatcher-' + this.k1z_1;
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_2 = this;
    var tmp = this;
    tmp.x1w_1 = lazy(NativeThread$Companion$warnSleep$delegate$lambda);
  }
  b1x() {
    return false;
  }
  y1w() {
    return new Long(1, 0);
  }
}
//endregion
var Companion_instance_0;
function Companion_getInstance() {
  return Companion_instance_0;
}
function onCancel(_this__u8e3s4, block) {
  var running = {_v: true};
  var tmp = CoroutineScope(_this__u8e3s4);
  launch(tmp, VOID, VOID, onCancel$slambda_0(running, block));
  // Inline function 'kotlin.AutoCloseable' call
  return new _no_name_provided__qut3iv(running);
}
function *_generator_invoke__zhh2q8($this, $this$launch, $completion) {
  try {
    while ($this.e1x_1._v) {
      // Inline function 'korlibs.time.seconds' call
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$1 = toDuration(1, DurationUnit_SECONDS_getInstance());
      var tmp = delay(tmp$ret$1, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
    }
  } catch ($p) {
    if ($p instanceof CancellationException) {
      var e = $p;
      if ($this.e1x_1._v)
        $this.f1x_1();
    } else {
      throw $p;
    }
  }
  return Unit_instance;
}
function onCancel$slambda_0($running, $block) {
  var i = new onCancel$slambda($running, $block);
  var l = function ($this$launch, $completion) {
    return i.o1s($this$launch, $completion);
  };
  l.$arity = 1;
  return l;
}
var Companion_instance_1;
function Companion_getInstance_0() {
  if (Companion_instance_1 === VOID)
    new Companion_0();
  return Companion_instance_1;
}
function Signal$_init_$lambda_6vimhp() {
  return Unit_instance;
}
function Signal$waitOneBase$lambda($close, $c) {
  return function (it) {
    var tmp5_safe_receiver = $close._v;
    if (tmp5_safe_receiver == null)
      null;
    else {
      tmp5_safe_receiver.u6();
    }
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = $c;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(it);
    this_0.id(tmp$ret$0);
    return Unit_instance;
  };
}
function Signal$waitOneBase$lambda_0($close) {
  return function (it) {
    var tmp6_safe_receiver = $close._v;
    if (tmp6_safe_receiver == null)
      null;
    else {
      tmp6_safe_receiver.u6();
    }
    return Unit_instance;
  };
}
function invoke(_this__u8e3s4) {
  return _this__u8e3s4.x1x(Unit_instance);
}
function waitOne(_this__u8e3s4, $completion) {
  return _this__u8e3s4.b1y($completion);
}
function *_generator_invoke__zhh2q8_0($this, value, $completion) {
  $l$block: {
    // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks' call
    if ($this.r1x_1.a1()) {
      break $l$block;
    }
    try {
      $this.t1x_1 = $this.t1x_1 + 1 | 0;
      // Inline function 'korlibs.io.async.fastIterateRemove' call
      var this_0 = $this.r1x_1;
      var n = 0;
      var m = 0;
      while (n < this_0.j1()) {
        if (m >= 0 && !(m === n)) {
          this_0.p3(m, this_0.i1(n));
        }
        // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks.<anonymous>' call
        var node = this_0.i1(n);
        var remove = node.y1x_1;
        // Inline function 'korlibs.io.async.AsyncSignal.invoke.<anonymous>' call
        var tmp = node.z1x_1(value, $completion);
        if (tmp === get_COROUTINE_SUSPENDED())
          tmp = yield tmp;
        if (remove) {
          m = m - 1 | 0;
        }
        n = n + 1 | 0;
        m = m + 1 | 0;
      }
      while (this_0.j1() > m) {
        this_0.q3(this_0.j1() - 1 | 0);
      }
    }finally {
      $this.t1x_1 = $this.t1x_1 - 1 | 0;
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!$this.s1x_1.a1()) {
        // Inline function 'korlibs.io.async.fastIterateRemove' call
        var this_1 = $this.s1x_1;
        var n_0 = 0;
        var m_0 = 0;
        while (n_0 < this_1.j1()) {
          if (m_0 >= 0 && !(m_0 === n_0)) {
            this_1.p3(m_0, this_1.i1(n_0));
          }
          // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks.<anonymous>' call
          var it = this_1.i1(n_0);
          $this.r1x_1.n3(it);
          if (true) {
            m_0 = m_0 - 1 | 0;
          }
          n_0 = n_0 + 1 | 0;
          m_0 = m_0 + 1 | 0;
        }
        while (this_1.j1() > m_0) {
          this_1.q3(this_1.j1() - 1 | 0);
        }
      }
    }
  }
  return Unit_instance;
}
function AsyncSignal$_init_$lambda_rsv6fb() {
  return Unit_instance;
}
function BaseSignal$_init_$lambda_tuu04y() {
  return Unit_instance;
}
function Signal2$_init_$lambda_6met8f() {
  return Unit_instance;
}
function BaseSignal2$_init_$lambda_4fc7ce() {
  return Unit_instance;
}
function runBlockingNoSuspensions(callback) {
  var completed = {_v: false};
  var rresult = {_v: null};
  var resultEx = {_v: null};
  var suspendCount = {_v: 0};
  startCoroutineUndispatched(callback, new runBlockingNoSuspensions$1(suspendCount, resultEx, completed, rresult));
  if (!completed._v)
    throw IllegalStateException.m5('runBlockingNoSuspensions was not completed synchronously! suspendCount=' + suspendCount._v);
  if (!(resultEx._v == null))
    throw ensureNotNull(resultEx._v);
  var tmp;
  if (rresult._v == null) {
    throwUninitializedPropertyAccessException('rresult');
  } else {
    tmp = rresult._v;
  }
  return tmp;
}
function startCoroutineUndispatched(_this__u8e3s4, completion) {
  $l$block: {
    // Inline function 'korlibs.io.async.startDirect' call
    var tmp;
    try {
      // Inline function 'korlibs.io.async.startCoroutineUndispatched.<anonymous>' call
      // Inline function 'korlibs.io.async.withCoroutineContext' call
      completion.gd();
      // Inline function 'korlibs.io.async.startCoroutineUndispatched.<anonymous>.<anonymous>' call
      tmp = startCoroutineUninterceptedOrReturnGeneratorVersion(_this__u8e3s4, completion);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        // Inline function 'kotlin.coroutines.resumeWithException' call
        // Inline function 'kotlin.Companion.failure' call
        var tmp$ret$3 = _Result___init__impl__xyqfz8(createFailure(e));
        completion.id(tmp$ret$3);
        break $l$block;
      } else {
        throw $p;
      }
    }
    var value = tmp;
    if (!(value === get_COROUTINE_SUSPENDED())) {
      // Inline function 'kotlin.coroutines.resume' call
      // Inline function 'kotlin.Companion.success' call
      var value_0 = (value == null ? true : !(value == null)) ? value : THROW_CCE();
      var tmp$ret$6 = _Result___init__impl__xyqfz8(value_0);
      completion.id(tmp$ret$6);
    }
  }
}
function _get_unitInstance__yoa8mq($this) {
  return Unit_instance;
}
function createSingleThreadedDispatcher(_this__u8e3s4, name) {
  return _createFixedThreadDispatcher(_this__u8e3s4, name, 1);
}
function createRedirectedDispatcher(_this__u8e3s4, name, parent) {
  return createRedirectedDispatcher$1.l1z(parent, name);
}
function NativeThread$Companion$warnSleep$delegate$lambda() {
  println('!!! Sync sleeping on JS');
  return Unit_instance;
}
var Companion_instance_2;
function Companion_getInstance_1() {
  if (Companion_instance_2 === VOID)
    new Companion_1();
  return Companion_instance_2;
}
function runBlockingNoJs(context, block) {
  context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
  throw UnsupportedOperationException.ua('Calling runBlockingNoJs on JavaScript');
}
function _createFixedThreadDispatcher(_this__u8e3s4, name, threadCount) {
  threadCount = threadCount === VOID ? 1 : threadCount;
  return createRedirectedDispatcher(_this__u8e3s4, name, Dispatchers_getInstance().c1c());
}
//region block: post-declaration
initMetadataForClass(BaseLock, 'BaseLock');
initMetadataForClass(Lock, 'Lock', Lock);
initMetadataForClass(NonRecursiveLock, 'NonRecursiveLock', NonRecursiveLock);
initMetadataForCompanion(Companion);
initMetadataForLambda(onCancel$slambda, VOID, VOID, [1]);
initMetadataForClass(_no_name_provided__qut3iv, VOID, VOID, VOID, [AutoCloseable]);
initMetadataForCompanion(Companion_0);
protoOf(PreferSyncIo).td = get;
protoOf(PreferSyncIo).wn = fold;
protoOf(PreferSyncIo).vn = minusKey;
protoOf(PreferSyncIo).xn = plus;
initMetadataForClass(PreferSyncIo, 'PreferSyncIo', VOID, VOID, [Element]);
initMetadataForClass(BaseSignal, 'BaseSignal', VOID, VOID, VOID, [0]);
initMetadataForClass(Signal, 'Signal', Signal, VOID, VOID, [0]);
initMetadataForClass(AsyncSignal, 'AsyncSignal', AsyncSignal, VOID, VOID, [1, 0]);
initMetadataForClass(Node, 'Node', VOID, VOID, [AutoCloseable]);
initMetadataForClass(BaseSignal2, 'BaseSignal2', VOID, VOID, VOID, [0]);
initMetadataForClass(Signal2, 'Signal2', Signal2, VOID, VOID, [0]);
initMetadataForClass(Node_0, 'Node', VOID, VOID, [AutoCloseable]);
protoOf(runBlockingNoSuspensions$1$context$1).td = get_0;
protoOf(runBlockingNoSuspensions$1$context$1).vn = minusKey_0;
protoOf(runBlockingNoSuspensions$1$context$1).vd = releaseInterceptedContinuation;
protoOf(runBlockingNoSuspensions$1$context$1).e1c = invokeOnTimeout;
initMetadataForClass(runBlockingNoSuspensions$1$context$1, VOID, VOID, VOID, [AbstractCoroutineContextElement, ContinuationInterceptor, Delay], [1]);
initMetadataForClass(runBlockingNoSuspensions$1, VOID, VOID, VOID, [Continuation]);
initMetadataForClass(createRedirectedDispatcher$1, VOID, VOID, VOID, [CoroutineDispatcher, AutoCloseable]);
initMetadataForCompanion(Companion_1);
//endregion
//region block: init
Companion_instance_0 = new Companion();
//endregion
//region block: exports
export {
  waitOne as waitOne1uk08owur305i,
  Companion_getInstance_1 as Companion_getInstance3854vxk6bqvpd,
  Companion_getInstance_0 as Companion_getInstance15h5aaybcsdep,
  Lock as Lock5n7hh3dgcidl,
  NonRecursiveLock as NonRecursiveLock2vf7fkhp857q,
  AsyncSignal as AsyncSignal3p186s2w7f15b,
  Signal2 as Signal22rzcw7xf4vkv7,
  Signal as Signal3nnez3346azzf,
  invoke as invoke2sf88d1xeuyqh,
  onCancel as onCancelfhzfwe2m4dn1,
  runBlockingNoJs as runBlockingNoJs1ylyxdz3tnq2x,
  runBlockingNoSuspensions as runBlockingNoSuspensions25hsuevf2mle,
  createSingleThreadedDispatcher as createSingleThreadedDispatcher1tpb6ticji881,
};
//endregion
