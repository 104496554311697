import {
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  VOID7hggqo3abtya as VOID,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class Adler32 {
  constructor() {
    this.du_1 = 65521;
    this.eu_1 = 1;
  }
  fu(old, data, offset, len) {
    var s1 = (old >>> 0 | 0) & 65535;
    var s2 = (old >>> 16 | 0) & 65535;
    var inductionVariable = offset;
    var last = offset + len | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        s1 = (s1 + (data[n] & 255) | 0) % 65521 | 0;
        s2 = (s2 + s1 | 0) % 65521 | 0;
      }
       while (inductionVariable < last);
    return s2 << 16 | s1;
  }
}
class CRC32 {
  constructor() {
    CRC32_instance = this;
    this.gu_1 = 0;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = new Int32Array(256);
    while (tmp_0 < 256) {
      var tmp_2 = tmp_0;
      var c = tmp_2;
      var inductionVariable = 0;
      if (inductionVariable < 8)
        do {
          var k = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          c = !((c & 1) === 0) ? -306674912 ^ (c >>> 1 | 0) : c >>> 1 | 0;
        }
         while (inductionVariable < 8);
      tmp_1[tmp_2] = c;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.hu_1 = tmp_1;
  }
  fu(old, data, offset, len) {
    var c = ~old;
    var table = this.hu_1;
    var inductionVariable = offset;
    var last = offset + len | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        c = table[(c ^ data[n] & 255) & 255] ^ (c >>> 8 | 0);
      }
       while (inductionVariable < last);
    return ~c;
  }
}
//endregion
var Adler32_instance;
function Adler32_getInstance() {
  return Adler32_instance;
}
var CRC32_instance;
function CRC32_getInstance() {
  if (CRC32_instance === VOID)
    new CRC32();
  return CRC32_instance;
}
//region block: post-declaration
initMetadataForObject(Adler32, 'Adler32');
initMetadataForObject(CRC32, 'CRC32');
//endregion
//region block: init
Adler32_instance = new Adler32();
//endregion
//region block: exports
export {
  Adler32_instance as Adler32_instance34zzfqagdwr1t,
  CRC32_getInstance as CRC32_getInstance3miqiylzjuhxd,
};
//endregion
