import { insert2fz5125p2hdyd as insert } from './korlibs-korlibs-math-core.mjs';
import {
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  VOID7hggqo3abtya as VOID,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  listOfvhqybd2zx248 as listOf,
  listOf1jh22dvmctj1r as listOf_0,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  Unit_instance104q5opgivhr8 as Unit_instance,
  createThis2j2avj17cvnv2 as createThis,
  equals2au1ep9vhcato as equals,
  NoSuchElementException679xzhnp5bpj as NoSuchElementException,
  compareTo3ankvs086tmwq as compareTo,
  lazy2hsh8ze7j6ikd as lazy,
  toList383f556t1dixk as toList,
  coerceIn10f36k81le1mm as coerceIn,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Companion_getInstance1y19lmoptxnwi as Companion_getInstance,
  flippedX1d8oy0yl9d737 as flippedX,
  flippedY21xqtg7yj14hj as flippedY,
  rotatedRight1d92miv84rwna as rotatedRight,
  Tile__toString_impl_iy9thpd5dj258mzgi6 as Tile__toString_impl_iy9thp,
  Tile__hashCode_impl_ubobok3tqsleujf9ax8 as Tile__hashCode_impl_ubobok,
  _Tile___get_isValid__impl__nlo03e2mhmmy4urkdxd as _Tile___get_isValid__impl__nlo03e,
} from './korlibs-korlibs-image.mjs';
import { RectangleInt3lmu13ytuf5lk as RectangleInt } from './korlibs-korlibs-math-vector.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Companion {
  cpx(left, up, right, down) {
    return insert(insert(insert(insert(0, left, 0), up, 1), right, 2), down, 3);
  }
}
class SimpleTileSpec {
  constructor(left, up, right, down) {
    left = left === VOID ? false : left;
    up = up === VOID ? false : up;
    right = right === VOID ? false : right;
    down = down === VOID ? false : down;
    this.dpx_1 = left;
    this.epx_1 = up;
    this.fpx_1 = right;
    this.gpx_1 = down;
    this.hpx_1 = Companion_instance.cpx(this.dpx_1, this.epx_1, this.fpx_1, this.gpx_1);
  }
  toString() {
    return 'SimpleTileSpec(left=' + this.dpx_1 + ', up=' + this.epx_1 + ', right=' + this.fpx_1 + ', down=' + this.gpx_1 + ')';
  }
  hashCode() {
    var result = getBooleanHashCode(this.dpx_1);
    result = imul(result, 31) + getBooleanHashCode(this.epx_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.fpx_1) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.gpx_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof SimpleTileSpec))
      return false;
    var tmp0_other_with_cast = other instanceof SimpleTileSpec ? other : THROW_CCE();
    if (!(this.dpx_1 === tmp0_other_with_cast.dpx_1))
      return false;
    if (!(this.epx_1 === tmp0_other_with_cast.epx_1))
      return false;
    if (!(this.fpx_1 === tmp0_other_with_cast.fpx_1))
      return false;
    if (!(this.gpx_1 === tmp0_other_with_cast.gpx_1))
      return false;
    return true;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    this.ipx_1 = listOf(false);
    this.jpx_1 = listOf_0([false, true]);
  }
}
class SimpleTileProvider {
  constructor(value) {
    Companion_getInstance_1();
    this.kpx_1 = value;
    this.lpx_1 = 1;
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.mpx_1 = fillArrayVal(Array(16), null);
  }
  npx() {
    return this.lpx_1;
  }
  opx(rule, registerFlipX, registerFlipY, registerRotated) {
    var _iterator__ex2g4s = (registerFlipX ? Companion_getInstance_1().jpx_1 : Companion_getInstance_1().ipx_1).f1();
    while (_iterator__ex2g4s.g1()) {
      var fx = _iterator__ex2g4s.h1();
      var _iterator__ex2g4s_0 = (registerFlipY ? Companion_getInstance_1().jpx_1 : Companion_getInstance_1().ipx_1).f1();
      while (_iterator__ex2g4s_0.g1()) {
        var fy = _iterator__ex2g4s_0.h1();
        var _iterator__ex2g4s_1 = (registerRotated ? Companion_getInstance_1().jpx_1 : Companion_getInstance_1().ipx_1).f1();
        while (_iterator__ex2g4s_1.g1()) {
          var rot = _iterator__ex2g4s_1.h1();
          var r = rule;
          if (rot)
            r = r.rpx();
          if (fx)
            r = r.spx();
          if (fy)
            r = r.tpx();
          var bits = r.qpx_1.hpx_1;
          if (this.mpx_1[bits] == null) {
            this.mpx_1[bits] = r;
          }
        }
      }
    }
  }
  upx(rule, registerFlipX, registerFlipY, registerRotated, $super) {
    registerFlipX = registerFlipX === VOID ? true : registerFlipX;
    registerFlipY = registerFlipY === VOID ? true : registerFlipY;
    registerRotated = registerRotated === VOID ? true : registerRotated;
    var tmp;
    if ($super === VOID) {
      this.opx(rule, registerFlipX, registerFlipY, registerRotated);
      tmp = Unit_instance;
    } else {
      tmp = $super.opx.call(this, rule, registerFlipX, registerFlipY, registerRotated);
    }
    return tmp;
  }
  vpx(spec) {
    var tmp5_safe_receiver = this.mpx_1[spec.hpx_1];
    if (tmp5_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      return tmp5_safe_receiver.ppx_1;
    }
    return Companion_getInstance().l70_1;
  }
  wpx(ints, x, y) {
    if (!(this.xpx(ints, x, y) === this.kpx_1))
      return Companion_getInstance().l70_1;
    var left = this.xpx(ints, x - 1 | 0, y) === this.kpx_1;
    var right = this.xpx(ints, x + 1 | 0, y) === this.kpx_1;
    var up = this.xpx(ints, x, y - 1 | 0) === this.kpx_1;
    var down = this.xpx(ints, x, y + 1 | 0) === this.kpx_1;
    return this.vpx(new SimpleTileSpec(left, up, right, down));
  }
  xpx(_this__u8e3s4, x, y) {
    return _this__u8e3s4.v2y(x, y) ? _this__u8e3s4.z2k(x, y) : 0;
  }
}
class SimpleRule {
  static ypx(tile, spec) {
    var $this = createThis(this);
    $this.ppx_1 = tile;
    $this.qpx_1 = spec;
    return $this;
  }
  p2i() {
    return this.qpx_1.dpx_1;
  }
  q2i() {
    return this.qpx_1.fpx_1;
  }
  zpx() {
    return this.qpx_1.epx_1;
  }
  apy() {
    return this.qpx_1.gpx_1;
  }
  static bpy(tile, left, up, right, down) {
    left = left === VOID ? false : left;
    up = up === VOID ? false : up;
    right = right === VOID ? false : right;
    down = down === VOID ? false : down;
    return this.ypx(tile, new SimpleTileSpec(left, up, right, down));
  }
  spx() {
    return SimpleRule.bpy(flippedX(this.ppx_1), this.q2i(), this.zpx(), this.p2i(), this.apy());
  }
  tpx() {
    return SimpleRule.bpy(flippedY(this.ppx_1), this.p2i(), this.apy(), this.q2i(), this.zpx());
  }
  rpx() {
    var tmp0_tile = rotatedRight(this.ppx_1);
    var tmp1_up = this.p2i();
    var tmp2_right = this.zpx();
    var tmp3_down = this.q2i();
    var tmp4_left = this.apy();
    return SimpleRule.bpy(tmp0_tile, tmp4_left, tmp1_up, tmp2_right, tmp3_down);
  }
  toString() {
    return 'SimpleRule(tile=' + Tile__toString_impl_iy9thp(this.ppx_1) + ', spec=' + this.qpx_1.toString() + ')';
  }
  hashCode() {
    var result = Tile__hashCode_impl_ubobok(this.ppx_1);
    result = imul(result, 31) + this.qpx_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof SimpleRule))
      return false;
    var tmp0_other_with_cast = other instanceof SimpleRule ? other : THROW_CCE();
    if (!equals(this.ppx_1, tmp0_other_with_cast.ppx_1))
      return false;
    if (!this.qpx_1.equals(tmp0_other_with_cast.qpx_1))
      return false;
    return true;
  }
}
class CombinedRuleMatcher {
  static epy(rules) {
    var $this = createThis(this);
    $this.cpy_1 = rules;
    var tmp = $this;
    tmp.dpy_1 = lazy(CombinedRuleMatcher$maxDist$delegate$lambda($this));
    return $this;
  }
  static fpy(rules) {
    return this.epy(toList(rules));
  }
  npx() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.dpy_1;
    maxDist$factory();
    return this_0.b2();
  }
  wpx(ints, x, y) {
    var _iterator__ex2g4s = this.cpy_1.f1();
    while (_iterator__ex2g4s.g1()) {
      var rule = _iterator__ex2g4s.h1();
      var tile = rule.wpx(ints, x, y);
      if (_Tile___get_isValid__impl__nlo03e(tile))
        return tile;
    }
    return Companion_getInstance().l70_1;
  }
}
//endregion
var Companion_instance;
function Companion_getInstance_0() {
  return Companion_instance;
}
var Companion_instance_0;
function Companion_getInstance_1() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function CombinedRuleMatcher$maxDist$delegate$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.collections.maxOf' call
    var iterator = this$0.cpy_1.f1();
    if (!iterator.g1())
      throw NoSuchElementException.c7();
    // Inline function 'korlibs.image.tiles.CombinedRuleMatcher.maxDist$delegate.<anonymous>.<anonymous>' call
    var maxValue = iterator.h1().npx();
    while (iterator.g1()) {
      // Inline function 'korlibs.image.tiles.CombinedRuleMatcher.maxDist$delegate.<anonymous>.<anonymous>' call
      var v = iterator.h1().npx();
      if (compareTo(maxValue, v) < 0) {
        maxValue = v;
      }
    }
    return maxValue;
  };
}
function IntGridToTileGrid(ints, rules, tiles, updated) {
  updated = updated === VOID ? RectangleInt.d2u(0, 0, ints.j2y(), ints.k2y()) : updated;
  var l = coerceIn(updated.p2i() - rules.npx() | 0, 0, ints.j2y());
  var r = coerceIn(updated.q2i() + rules.npx() | 0, 0, ints.j2y());
  var t = coerceIn(updated.r2i() - rules.npx() | 0, 0, ints.k2y());
  var b = coerceIn(updated.s2i() + rules.npx() | 0, 0, ints.k2y());
  var inductionVariable = t;
  if (inductionVariable < b)
    do {
      var y = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var inductionVariable_0 = l;
      if (inductionVariable_0 < r)
        do {
          var x = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          tiles.a71(x, y, rules.wpx(ints, x, y));
        }
         while (inductionVariable_0 < r);
    }
     while (inductionVariable < b);
}
function maxDist$factory() {
  return getPropertyCallableRef('maxDist', 1, KProperty1, function (receiver) {
    return receiver.npx();
  }, null);
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForClass(SimpleTileSpec, 'SimpleTileSpec', SimpleTileSpec);
initMetadataForCompanion(Companion_0);
initMetadataForClass(SimpleTileProvider, 'SimpleTileProvider');
initMetadataForClass(SimpleRule, 'SimpleRule');
initMetadataForClass(CombinedRuleMatcher, 'CombinedRuleMatcher');
//endregion
//region block: init
Companion_instance = new Companion();
//endregion
//region block: exports
export {
  CombinedRuleMatcher as CombinedRuleMatcher108ngn6lxba4t,
  IntGridToTileGrid as IntGridToTileGrid3mza00h4urmkn,
  SimpleRule as SimpleRule2asrxy1xgzifr,
  SimpleTileProvider as SimpleTileProvider1hi5ozcewbzq,
  SimpleTileSpec as SimpleTileSpecgm148xmxr6nq,
};
//endregion
