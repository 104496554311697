import {
  VOID7hggqo3abtya as VOID,
  protoOf180f3jzyo7rfj as protoOf,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  toString1pkumu07cwy4m as toString,
  hashCodeq5arwsb9dgti as hashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  equals2au1ep9vhcato as equals,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  Unit_instance104q5opgivhr8 as Unit_instance,
  numberToDouble210hrknaofnhf as numberToDouble,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  Long2qws0ah9gnpki as Long,
  Randomei1bbeye8rr8 as Random,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  println2shhhgwwt4c61 as println,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  DurationUnit_MILLISECONDS_getInstance15owevua4zjxe as DurationUnit_MILLISECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  listOf1jh22dvmctj1r as listOf,
  Random2w0u2xak2xaqi as Random_0,
  random234odtwlu7lq2 as random,
  KtMutableMap1kqeifoi36kpz as KtMutableMap,
  isInterface3d6p8outrmvmk as isInterface,
  DurationUnit_SECONDS_getInstance3jias9ne5z4er as DurationUnit_SECONDS_getInstance,
  getKClass1s3j9wy1cofik as getKClass,
  KClass1cc9rfeybg8hs as KClass,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  emptyMapr06gerzljqtm as emptyMap,
  toList383f556t1dixk as toList,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  NoSuchElementException679xzhnp5bpj as NoSuchElementException,
  listOfvhqybd2zx248 as listOf_0,
  ArrayList3it5z8td81qkl as ArrayList,
  filterNotNull3qfgcwmxhwfxe as filterNotNull,
  compareTo3ankvs086tmwq as compareTo,
  until1jbpn0z3f8lbg as until,
  lastOrNull1aq5oz189qoe1 as lastOrNull,
  addAll1k27qatfgp3k5 as addAll,
  plus310ted5e4i90h as plus,
  minOrNull2eu8keq9f1w4i as minOrNull,
  toString30pk9tzaqopn as toString_0,
  KtMap140uvy3s5zad8 as KtMap,
  isIntArrayeijsubfngq38 as isIntArray,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  NotImplementedErrorfzlkpv14xxr8 as NotImplementedError,
  Duration__minus_impl_q5cfm7ntz6q4kzlr5l as Duration__minus_impl_q5cfm7,
  Duration__compareTo_impl_pchp0f1z7ae3ty3s6c1 as Duration__compareTo_impl_pchp0f,
  KMutableProperty11e8g1gb0ecb9j as KMutableProperty1,
  KMutableProperty025txtn5b59pq1 as KMutableProperty0,
  removeAll3o43e67jmwdpc as removeAll,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  copyToArray2j022khrow2yi as copyToArray,
  arrayConcat3qsij7vh68m69 as arrayConcat,
  getOrNull1go7ef9ldk0df as getOrNull,
  Duration5ynfiptaqcrg as Duration,
  to2cs3ny02qtbcb as to,
  firstOrNull1982767dljvdy as firstOrNull,
  first58ocm7j58k3q as first,
  Monotonic_instance19wu6xfdyzm85 as Monotonic_instance,
  ValueTimeMark__elapsedNow_impl_eonqvs2ntz2k8jdtq63 as ValueTimeMark__elapsedNow_impl_eonqvs,
  TimedValuew9j01dao9jci as TimedValue,
  Duration__toString_impl_8d916b1f2vqqjkgwgjr as Duration__toString_impl_8d916b,
  first28gmhyvs4kf06 as first_0,
  toList3jhuyej2anx2q as toList_0,
  get_EmptyContinuationn1rwa6yr6j5w as get_EmptyContinuation,
  startCoroutineUninterceptedOrReturnGeneratorVersion1r5yf56916wr4 as startCoroutineUninterceptedOrReturnGeneratorVersion,
  last1vo29oleiqj36 as last,
  plus20p0vtfmu0596 as plus_0,
  takeLasttm5u17ojwaaq as takeLast,
  Enum3alwj03lh1n41 as Enum,
  initMetadataForFunctionReferencen3g5fpj34t8u as initMetadataForFunctionReference,
  lazy2hsh8ze7j6ikd as lazy,
  createThis2j2avj17cvnv2 as createThis,
  setPropertiesToThrowableInstance1w2jjvl9y77yc as setPropertiesToThrowableInstance,
  captureStack1fzi4aczwc4hg as captureStack,
  KProperty02ce7r476m8633 as KProperty0,
  THROW_ISE25y4rao9gcv5s as THROW_ISE,
  getLocalDelegateReferencenlta2y7wt3po as getLocalDelegateReference,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  charArrayOf27f4r3dozbrk1 as charArrayOf,
  trim6jpmwojjgb3l as trim,
} from './kotlin-kotlin-stdlib.mjs';
import {
  readTtfFont31q01sbixp9wj as readTtfFont,
  ASE_getInstance1eqtiryc16lrg as ASE_getInstance,
  toPropsuld7zaezrb2a as toProps,
  readImageDataContainerm9mp5km2d0ql as readImageDataContainer,
  Colors_getInstance1qvds1isfbag2 as Colors_getInstance,
  readBitmapSlice1mxfjm5uys58e as readBitmapSlice,
  Companion_getInstance29pn999oa011x as Companion_getInstance_0,
  readBitmap1dn2o52mvbpv4 as readBitmap,
  slice2pfxfvku0ybgy as slice,
  RGBA34obx2i62wcl3 as RGBA,
  sliceWithSize2okpd9nq8v6n3 as sliceWithSize,
  get_DefaultTtfFontddovg6zy24ck as get_DefaultTtfFont,
  Companion_getInstance1p5o3grjloafn as Companion_getInstance_1,
  BitmapEffect3nrrxebx6tit3 as BitmapEffect,
  Companion_instancevwyx2uq9kvqe as Companion_instance,
  Companion_getInstance2fl1toxz5lf8a as Companion_getInstance_2,
  get_DefaultStringTextRendererdyzggul2pyry as get_DefaultStringTextRenderer,
  MutableAtlas41tbjanvwcb0 as MutableAtlas,
  get_lazyBitmapSDF1i790bqc57mil as get_lazyBitmapSDF,
  EmptyShape_instance7pcf1jiur1zr as EmptyShape_instance,
  ShapeBuilderk05da5wk16e9 as ShapeBuilder,
  RGBA__withAd_impl_cralaopec5nyxs57ng as RGBA__withAd_impl_cralao,
  Bitmaps_instancebct336c8d0m7 as Bitmaps_instance,
  get_Bitmaps_transparent3nm2857y3u3hn as get_Bitmaps_transparent,
  Direction_FORWARD_getInstance2z9se4ftbc6nn as Direction_FORWARD_getInstance,
  TileMapData285v3okd3krdh as TileMapData,
  Companion_getInstancenwobv30yoie as Companion_getInstance_3,
  Direction_REVERSE_getInstance345lw1ugqe83y as Direction_REVERSE_getInstance,
  Direction_ONCE_REVERSE_getInstance2zwiu4ujrmbas as Direction_ONCE_REVERSE_getInstance,
  get_DefaultTtfFontAsBitmap1hzwi8e4truhb as get_DefaultTtfFontAsBitmap,
  SVG3gleh7ai3c141 as SVG,
  scaled3gdp9stc6gl4n as scaled,
  Tilejvhqhoacncec as Tile,
  readAtlas2ph83xfha5q5t as readAtlas,
  ShapeRasterizerMethod_NONE_getInstancewicc6udl8ru7 as ShapeRasterizerMethod_NONE_getInstance,
} from './korlibs-korlibs-image.mjs';
import {
  get_resourcesVfs3qrvup7jpy9uc as get_resourcesVfs,
  launchImmediatelylnv8lnistpdy as launchImmediately,
  Resources1ysf2j4wd5yk7 as Resources,
  launchImmediately11za8mkid9pxu as launchImmediately_0,
} from './korlibs-korlibs-io.mjs';
import {
  SolidRect131p7hyzlx7j8 as SolidRect,
  addTo25e29vq105prw as addTo,
  anchor3c5itxst996yy as anchor,
  UIImage3935n4wnarhtx as UIImage,
  xy1gb51vhy014mx as xy,
  UITooltipContainer1enlpvyskzqjr as UITooltipContainer,
  UIDirection_ROW_getInstancemp081o8fljhz as UIDirection_ROW_getInstance,
  UIGridFill2fuun2uv06ma4 as UIGridFill,
  UIButtonToggleableGroup1ec0hg22gg0yq as UIButtonToggleableGroup,
  Companion_getInstance31zn3b6amcjag as Companion_getInstance_4,
  UIButton259u54yn72ttw as UIButton,
  tooltip2lix13cilv7ve as tooltip,
  get_mouse1jstwhfaumelj as get_mouse,
  UIVerticalStack2gichhwk4pg3x as UIVerticalStack,
  UIHorizontalStack2hv4gvky7dghi as UIHorizontalStack,
  MouseEvents19mapgq8ez76g as MouseEvents,
  size1ac0fubkxl72w as size,
  positionu6441b7hkb4l as position,
  rotation29g98bv3f78wn as rotation,
  Ellipse3mlmmuhtwwzwf as Ellipse,
  Circlepba9jsmewo4k as Circle,
  FixedSizeContainersre0rworj71w as FixedSizeContainer,
  FSprites2ca4s38v9nhd8 as FSprites,
  _FSprite___get_index__impl__7khb8f3srrtt23r5dj1 as _FSprite___get_index__impl__7khb8f,
  Companion_instancecqwbvq0sb4tp as Companion_instance_0,
  Text3kimhvgfxflj3 as Text,
  addUpdater1la23qj9g2d42 as addUpdater,
  _FSprite___init__impl__y4bm26o3ksm0zh85u1 as _FSprite___init__impl__y4bm26,
  SceneContainer99ijjqni12hp as SceneContainer,
  Companion_getInstancezug9z9lglek as Companion_getInstance_5,
  Camera3eju605c3layp as Camera,
  GraphicsRenderer_SYSTEM_getInstance1vcvjxz5tx3ul as GraphicsRenderer_SYSTEM_getInstance,
  Graphics2q0lx93a2j4b2 as Graphics,
  BlurFilter1o7ok9bw0jdji as BlurFilter,
  get_filterScaleuvlwz3cded4n as get_filterScale,
  Companion_instance3f2pfd31j919l as Companion_instance_1,
  set_filter36wgvpm5cukpc as set_filter,
  set_filterScale3iptc9lhu4vp1 as set_filterScale,
  addUpdater2sjoslzga432q as addUpdater_0,
  get_simpleAnimator3dm3towjhldr6 as get_simpleAnimator,
  Animator3ve4r56t61w8x as Animator,
  tween3cqjzsn3zwtgf as tween,
  Image1b2d7ghyim7p5 as Image,
  Container7486fjmip554 as Container,
  Anchorable2p2wd2c4mob8i as Anchorable,
  SmoothedBmpSlice3gb2g5l2kdzel as SmoothedBmpSlice,
  TileMapunot5gnjvpqy as TileMap,
  View11ybmmoof9vue as View,
  beforeRenderiy81t6fb11po as beforeRender,
  afterRenderjbmwx3695z3h as afterRender,
  ViewRenderPhase1ybhofrqiytg2 as ViewRenderPhase,
  tweenAsync2qo1zo5xt537f as tweenAsync,
  GraphicsRenderer_GPU_getInstance2y97iviksol8a as GraphicsRenderer_GPU_getInstance,
  makeFocusablebj3kuvwsemya as makeFocusable,
  Scenezu810y12qpqp as Scene,
  zIndex304xgfufia3na as zIndex,
  WaveFilter3180y9l8huonp as WaveFilter,
  scalea9fc0mbrr7ub as scale,
  Companion_getInstance3v280k3k1v32j as Companion_getInstance_6,
  TransitionFilter3bnqs9bq9zqka as TransitionFilter,
  PageFilter2zor0zqtd57qt as PageFilter,
  Companion_getInstancebcwpyc7v6z0k as Companion_getInstance_7,
  Convolute3Filter5g83e8d3dtvp as Convolute3Filter,
  SwizzleColorsFilter1v3oimf3q32jo as SwizzleColorsFilter,
  animatexev04pctltcp as animate,
  block1zs9asix98nq4 as block,
  wait7ky6hfhpq4xi as wait,
  touchiy6gmy0gyfbe as touch,
  addFixedUpdaterkdt88bj87xku as addFixedUpdater,
  tween3jbh5j0ixzsj7 as tween_0,
  scaleRecognizerbwriock1g9at as scaleRecognizer,
  rotationRecognizer1cyl287fv0vf7 as rotationRecognizer,
  Companion_getInstance19ucmifvc1zkc as Companion_getInstance_8,
  UISlider5m4dwgr7ecf8 as UISlider,
  get_gestures13dhx4eoxy4m7 as get_gestures,
  get_keysmipauarvwdlb as get_keys,
  onMouseDrag20om3ch85u2pf as onMouseDrag,
  GestureEvents2xgmdbiz01aec as GestureEvents,
  Korge11eut3mr7473l as Korge,
  VectorImage34eszej2kvy5t as VectorImage,
  DropshadowFilter3mmze965vo4ad as DropshadowFilter,
  set_cursor2bma4bfi3ugur as set_cursor,
  get_AlphaTransition23l7sd982osvm as get_AlphaTransition,
  withEasingtnol1eyyo9sf as withEasing,
  views17oaxjs1xed1j as views,
  findFirstAscendante8nli1jx4t64 as findFirstAscendant,
  get_UI_DEFAULT_SIZE1tys63379ijr3 as get_UI_DEFAULT_SIZE,
  UIComboBoxr3ju7l8oh2k6 as UIComboBox,
  Stage1ggtrzujnnkl0 as Stage,
  alphaher384qrj9q4 as alpha,
  PixelatedSceneoudxoze5iszc as PixelatedScene,
  intervalfu9yhuucbcwn as interval,
  centerOnStage1tf2oda60yrak as centerOnStage,
  animator38vbqz0qhvd6m as animator,
  show2aeumyubbk4hr as show,
  moveBy2jlfhnymnp65t as moveBy,
  hide1jf8h1e875esy as hide,
  delay1gbwd28dgkhwf as delay,
  get_centeredilxldnbf7ma6 as get_centered,
  sizeScaled36d00h3dw190k as sizeScaled,
  values367gftm1eq5yt as values,
  clicked3gw2g8098k3q5 as clicked,
  onDropFile1vcbx8hsm6l1h as onDropFile,
  size3ul8wgwwt88hf as size_0,
  onStageResized1gaool2kxfglm as onStageResized,
  UIText20upwp95popg2 as UIText,
  getItemY38ekgpm3oa8ep as getItemY,
  Provider12q7o5zi8ker6 as Provider,
  UIWindowfz5bs45h8v81 as UIWindow,
  get_styles1wpy8tgbxrx7h as get_styles,
  set_textFontzxrbrksrw2rf as set_textFont,
  UIVerticalList8l3cvb5wtmnl as UIVerticalList,
  tweenNoWaitv4vl4ybj7v8x as tweenNoWait,
  Companion_getInstance1rvgf02fkp37z as Companion_getInstance_9,
  set_textColor1p99dtdbjl3ci as set_textColor,
  UIEditableNumberPropsList9iwm3l1q1tjs as UIEditableNumberPropsList,
  UIPropertyNumberRowlpofk3dkngtp as UIPropertyNumberRow,
  UIPropertyRow4cwplox1mm4b as UIPropertyRow,
  UICheckBox1yp8vj5k61hy as UICheckBox,
} from './korge-root-korge.mjs';
import {
  Size2Dum5ayuyhhau7 as Size2D,
  Companion_getInstance21plr7f541fx9 as Companion_getInstance_10,
  Companion_getInstance19z3k1g06rka6 as Companion_getInstance_11,
  Companion_getInstance2ngycsg9kr2pn as Companion_getInstance_12,
  Anchor2D3ucppq7m9n3z8 as Anchor2D,
  interpolateAngle3ga2apfmi4ucr as interpolateAngle,
  get_degrees3czcd4ojw4i as get_degrees,
  Companion_getInstancewvzae8n0fprj as Companion_getInstance_13,
  Vector2D378fe5rov8fgw as Vector2D,
  div919pico60rxu as div,
  toIntu0rc68akxu7a as toInt,
  Ray2D32ioa0bhx6kzt as Ray2D,
  Companion_getInstance1ygiwm9bgjia3 as Companion_getInstance_14,
  Angle__times_impl_v3s9y22g57d1ddcd60g as Angle__times_impl_v3s9y2,
  Angle2itorpob18n08 as Angle,
  Companion_getInstanceuv1p1c5gwy11 as Companion_getInstance_15,
  Angle__cosine_impl_bgvzunl3ekczcg3dy9 as Angle__cosine_impl_bgvzun,
  Angle__sine_impl_3l11xvmzbai5p75zu2 as Angle__sine_impl_3l11xv,
  _Angle___get_ratio__impl__ap3on42zzwqtrc65vpb as _Angle___get_ratio__impl__ap3on4,
  _Angle___init__impl__g23q1m1vusxs8sthxgw as _Angle___init__impl__g23q1m,
  Angle__minus_impl_xeau7k30vsnq3biqxde as Angle__minus_impl_xeau7k,
  _Angle___get_absoluteValue__impl__4qoakv3gpdj6wpeuir3 as _Angle___get_absoluteValue__impl__4qoakv,
  get_degrees1wvulgxbo1fy6 as get_degrees_0,
  Angle__compareTo_impl_a0hfdsdv09zqotoc4l as Angle__compareTo_impl_a0hfds,
  MarginInt3jiec8xsxkfd as MarginInt,
  Vector2I2k5512l4eeahb as Vector2I,
  timesatqrmkwmiu2z as times,
  Angle__plus_impl_26v37k2akkjkyjv8qeu as Angle__plus_impl_26v37k,
  Companion_getInstance2175iaprojxbf as Companion_getInstance_16,
  Companion_getInstance145etojj24o8w as Companion_getInstance_17,
  Vector3F3dkkvai0hm6xy as Vector3F,
  Angle__unaryMinus_impl_7901frdiuz4dirxfxh as Angle__unaryMinus_impl_7901fr,
  Angle__unaryPlus_impl_eyi5pl3cj7mhzkfb01q as Angle__unaryPlus_impl_eyi5pl,
  _Angle___get_radians__impl__n00yt523wqxa1jdhpa1 as _Angle___get_radians__impl__n00yt5,
  Companion_getInstancew949xxob44bw as Companion_getInstance_18,
  RectangleInt3lmu13ytuf5lk as RectangleInt,
  applyScaleMode1soflu9k24j5z as applyScaleMode,
  _Angle___get_degrees__impl__qg56vw1zqnfrqkp8did as _Angle___get_degrees__impl__qg56vw,
} from './korlibs-korlibs-math-vector.mjs';
import {
  toRatio1hooqit5gulhr as toRatio,
  isAlmostEquals2e7wogp48cvmj as isAlmostEquals,
  interpolate2g7wzskc23f1q as interpolate,
  Ratio__times_impl_ucdh7y1eb1bj32n3p46 as Ratio__times_impl_ucdh7y,
  normalizeAlmostZeronwhxiyvg9dek as normalizeAlmostZero,
  isAlmostZero1ou51u04focf8 as isAlmostZero,
  umod164hvijxhfi8p as umod,
  Companion_getInstancech3bi94cc2ym as Companion_getInstance_19,
  Ratio136tf4z2t0p2d as Ratio,
  Companion_getInstance1kb6pmvl5oz92 as Companion_getInstance_20,
  interpolate35pbs3ac913y4 as interpolate_0,
} from './korlibs-korlibs-math-core.mjs';
import {
  get28v0piq7i0vt as get,
  toRectangle3rq2j924qufrq as toRectangle,
  BVH2D1qwjbargslfq8 as BVH2D,
  toMatrix42ci5k1es0x1v1 as toMatrix4,
  Companion_instance2fenvsumzjhz2 as Companion_instance_2,
  get_ALL2kw5hdxomut5y as get_ALL,
  cubic3uw6rftl1cilc as cubic,
  get_EASE_IN_OUT1plt1ext4tf71 as get_EASE_IN_OUT,
  get_EASE_IN_OUT_QUAD21w3u6e1aoexb as get_EASE_IN_OUT_QUAD,
  get_EASE_OUT38lbythvs83tj as get_EASE_OUT,
  splitInRows1cyiez6sz0yy1 as splitInRows,
  getff2jwkktgke3 as get_0,
} from './korlibs-korlibs-math.mjs';
import {
  BodyType_DYNAMIC_getInstance2kq43ngiumdiy as BodyType_DYNAMIC_getInstance,
  get_nearestBox2dWorld47kjmo2at5qk as get_nearestBox2dWorld,
  BodyDef1xn75220z5hnw as BodyDef,
  FixtureDeft7xk00u87x3d as FixtureDef,
  BoxShape3f6a119xanuci as BoxShape,
  CircleShape15ov0bod3acmc as CircleShape,
  set_view3vgcwd4b67w0s as set_view,
  set_bodyvp9llzt3kbjv as set_body,
  BodyType_STATIC_getInstance28op0hzskw7ek as BodyType_STATIC_getInstance,
} from './show.korge.org-korge-box2d.mjs';
import {
  Buffer25z94vsj0kb1s as Buffer,
  get_f3218rglfhpgbhzp as get_f32,
  Float32Buffer__set_impl_34sznevuviq8wx4pjc as Float32Buffer__set_impl_34szne,
  Float32Buffer__get_impl_659lhu25y6ts3w0gs8j as Float32Buffer__get_impl_659lhu,
  arrayfill2iqmyqqqfwuaf as arrayfill,
  arraycopy3uxsqjlixmnz3 as arraycopy,
  arrayequal25g4om83vd8w1 as arrayequal,
} from './korlibs-korlibs-memory.mjs';
import { Companion_getInstance3854vxk6bqvpd as Companion_getInstance_21 } from './korlibs-korlibs-concurrent.mjs';
import {
  delay1a7xkalnslpld as delay_0,
  await2pwm8za8tntlx as await_0,
} from './kotlinx-coroutines-core.mjs';
import {
  setExtra314k0z6q35eku as setExtra,
  hasExtra1969mplo13qgb as hasExtra,
  fastArrayListOf1rdodhrpn0q8y as fastArrayListOf,
  Property1m5vulkepgwzd as Property,
  Mixin3lmkm5hum2vdj as Mixin,
} from './korlibs-korlibs-datastructure-core.mjs';
import { Level_INFO_getInstance2ziz2o3m3loni as Level_INFO_getInstance } from './korlibs-korlibs-logger.mjs';
import {
  alert2d79t85yuh93y as alert,
  Key_LEFT_getInstance4xv6s4l9ffcr as Key_LEFT_getInstance,
  Key_RIGHT_getInstancevkjx63tsbibt as Key_RIGHT_getInstance,
  Key_UP_getInstanceb8rctup40hnz as Key_UP_getInstance,
  Key_DOWN_getInstance3uv13du3770bq as Key_DOWN_getInstance,
  GameButton_LX_getInstance3lu9212pwqcbw as GameButton_LX_getInstance,
  GameButton_LY_getInstance2eqq84hvfc09s as GameButton_LY_getInstance,
  Key_SPACE_getInstance13zcep8fbcon1 as Key_SPACE_getInstance,
  GameButton_BUTTON_SOUTH_getInstancefuuqbnftxu5s as GameButton_BUTTON_SOUTH_getInstance,
  Companion_getInstance77k1lbkmp5wu as Companion_getInstance_22,
  GameButton_BUTTON_NORTH_getInstance6rl4ta8izocg as GameButton_BUTTON_NORTH_getInstance,
  Key_Z_getInstance3takq9lezu1le as Key_Z_getInstance,
  GameButton_BUTTON_WEST_getInstance9mbjk7favy3t as GameButton_BUTTON_WEST_getInstance,
  V22su20mpwty0v0 as V2,
  _interpolated0idy8l9id9j as _interpolate,
  ProjViewUB_getInstance24kbqbtea649t as ProjViewUB_getInstance,
  AGScissor1lpa42gfyr1p2 as AGScissor,
  DefaultShaders_getInstance11xvl6rr8iso as DefaultShaders_getInstance,
  Companion_getInstance21hbm5vhzozgd as Companion_getInstance_23,
  AGTextureUnitInfoArray__get_impl_xhvpef3h2w6c3rmad6f as AGTextureUnitInfoArray__get_impl_xhvpef,
  Texture2obedkmljq67n as Texture,
  _interpolateDuration2bmwsmq7hgv6e as _interpolateDuration,
  _interpolateRatio2cj6ldbl0g6ae as _interpolateRatio,
  _interpolateAngle308nqn33qn9pa as _interpolateAngle,
  FileFilter2m8aa16dn76e3 as FileFilter,
  openFileDialog3eegkhbnqs6u1 as openFileDialog,
  Companion_getInstance35vn8xpzue606 as Companion_getInstance_24,
  Companion_getInstance1r9b3td34f16o as Companion_getInstance_25,
  Quality_PERFORMANCE_getInstance2im0li1v0jac6 as Quality_PERFORMANCE_getInstance,
  Cursor_HAND_getInstance1a8elkut4sr6s as Cursor_HAND_getInstance,
  toggleFullScreent48jynguinig as toggleFullScreen,
  TypedEvent2sht3ekuftqke as TypedEvent,
  easing3hki2a7unu7kd as easing,
  KeyEvent3kdv2rq8w0dew as KeyEvent,
  Type_DROP_getInstance37jdln1dn5lup as Type_DROP_getInstance,
  jsObject3lpbwuxli49jf as jsObject,
} from './korge-root-korge-core.mjs';
import {
  readLDTKWorldoxv5q0mconb9 as readLDTKWorld,
  get_fieldInstancesByName39gyux4395th3 as get_fieldInstancesByName,
  get_valueDyn2yc4p070m9qf2 as get_valueDyn,
  LDTKLevelView17570hdupn1fv as LDTKLevelView,
  LDTKEntityView1tvqcq5wilt12 as LDTKEntityView,
} from './show.korge.org-korge-ldtk.mjs';
import {
  _Dyn___get_str__impl__dup4cs3qkjliiqi5l9f as _Dyn___get_str__impl__dup4cs,
  _Dyn___get_list__impl__qpwjpy1x88d8kx8tg as _Dyn___get_list__impl__qpwjp,
} from './korlibs-korlibs-dyn.mjs';
import {
  VirtualStickConfig3mkwzgqv6aq3s as VirtualStickConfig,
  VirtualButtonConfig5thqhp2dciu2 as VirtualButtonConfig,
  virtualController2sr4e2nu9cp6s as virtualController,
} from './show.korge.org-korge-virtualcontroller.mjs';
import {
  get_hz3a5o5age7b808 as get_hz,
  get_timesPerSecond1juuclsmjle05 as get_timesPerSecond,
} from './korlibs-korlibs-time.mjs';
import {
  Deque18dk36ds2yxk9 as Deque,
  getCyclicOrNull12fem85bj0m7u as getCyclicOrNull,
  Companion_instance102wguxec40nc as Companion_instance_3,
  Companion_instance1nrsidgsshcc7 as Companion_instance_4,
  IntArray23680o40e0fgt8 as IntArray2,
  FastStringMap1l1uzdhsfbatm as FastStringMap,
  observe9z1mw8x3w951 as observe,
  Observable19v74jqcsmm4f as Observable,
} from './korlibs-korlibs-datastructure.mjs';
import {
  get_fast37cu8s8qwnuyi as get_fast,
  get_secondsjblpw5qd4dzj as get_seconds,
} from './korlibs-korlibs-time-core.mjs';
import {
  get_portableSimpleName6o7s8nc63i85 as get_portableSimpleName,
  Companion_getInstance4j5fpzk5rb8p as Companion_getInstance_26,
} from './korlibs-korlibs-platform.mjs';
import {
  Views3D1w8x3h72cqm4w as Views3D,
  Stage3DView1a3043g6rm78u as Stage3DView,
  Perspective2erinj849np0h as Perspective,
  axisLiness355wubr1txv as axisLines,
  readGLTF23pahjsw3lsrt0 as readGLTF2,
  GLTF2View27rsli4nemyyr as GLTF2View,
  gltf2View2k8otfc3r0nfv as gltf2View,
  position3v5ajcdva76eg as position_0,
  addUpdater6igk22c2qvsi as addUpdater_1,
  removeFromParent134qrksv252z5 as removeFromParent,
  addTo1qty7sfviegib as addTo_0,
  set_position24hx6qehuduzm as set_position,
  container3D1e7tsx3qrm1iz as container3D,
  position11uq2wot88tb2 as position_1,
  Polyline3D641dlria96z3 as Polyline3D,
  RenderContext3D3g9j9sw87j4iw as RenderContext3D,
  Companion_instance2neo4ifwb1va8 as Companion_instance_5,
  LightColor39trwa64rf4qa as LightColor,
  PBRMaterial3D2rz1eq17882bs as PBRMaterial3D,
} from './show.korge.org-korge-k3d.mjs';
import {
  SimpleTileSpecgm148xmxr6nq as SimpleTileSpec,
  SimpleTileProvider1hi5ozcewbzq as SimpleTileProvider,
  SimpleRule2asrxy1xgzifr as SimpleRule,
  CombinedRuleMatcher108ngn6lxba4t as CombinedRuleMatcher,
  IntGridToTileGrid3mza00h4urmkn as IntGridToTileGrid,
} from './show.korge.org-korma-tile-matching.mjs';
import {
  readSkeletonBinary3bx8lidxm67wk as readSkeletonBinary,
  Skeleton1jajq7hvqgg3p as Skeleton,
  AnimationStateDatar5ny3zfqlh85 as AnimationStateData,
  AnimationState2vlachi2cbl4b as AnimationState,
  SkeletonView14qrut8pgmv4h as SkeletonView,
} from './show.korge.org-korge-spine.mjs';
import {
  readSWF2zr5p57ipe5bt as readSWF,
  SWFExportConfig2djnbvfub7nz1 as SWFExportConfig,
} from './show.korge.org-korge-swf.mjs';
import {
  removeClass3jnpygopqxh7s as removeClass,
  addClass1ihnmxbr5xjr2 as addClass,
} from './kotlin_org_jetbrains_kotlin_kotlin_dom_api_compat.mjs';
//region block: imports
var imul = Math.imul;
var sign = Math.sign;
var hypot = Math.hypot;
//endregion
//region block: pre-declaration
class KR {}
function get_fonts() {
  return KRFonts_instance;
}
function get_gfx() {
  return KRGfx_instance;
}
class KR_0 {}
class KRFonts {
  ipy() {
    return _TypedVfsFileTTF___init__impl__pvwvu0(get_resourcesVfs().bj('fonts/PublicPixel.ttf'));
  }
}
class KRGfx {
  jpy() {
    return _TypedAse___init__impl__e1aaeg(get_resourcesVfs().bj('gfx/wizard_f.ase'));
  }
}
class AseGfxWizardFAse {
  constructor(data) {
    this.kpy_1 = data;
  }
  toString() {
    return AseGfxWizardFAse__toString_impl_66bk1v(this.kpy_1);
  }
  hashCode() {
    return AseGfxWizardFAse__hashCode_impl_fkucr0(this.kpy_1);
  }
  equals(other) {
    return AseGfxWizardFAse__equals_impl_5zim34(this.kpy_1, other);
  }
}
class MainUIImageTester$main$slambda {
  constructor($image, $anchor) {
    this.hpz_1 = $image;
    this.ipz_1 = $anchor;
  }
  tbo(it, $completion) {
    this.hpz_1.hf4(this.ipz_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda {
  constructor($handler, $it) {
    this.lpy_1 = $handler;
    this.mpy_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_0 {
  constructor($image) {
    this.jpz_1 = $image;
  }
  tbo(it, $completion) {
    this.jpz_1.gf4(Companion_getInstance_11().m2v_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_0 {
  constructor($handler, $it) {
    this.npy_1 = $handler;
    this.opy_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_0(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_1 {
  constructor($image) {
    this.kpz_1 = $image;
  }
  tbo(it, $completion) {
    this.kpz_1.gf4(Companion_getInstance_11().q2v());
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_1 {
  constructor($handler, $it) {
    this.ppy_1 = $handler;
    this.qpy_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_1(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_2 {
  constructor($image) {
    this.lpz_1 = $image;
  }
  tbo(it, $completion) {
    this.lpz_1.gf4(Companion_getInstance_11().o2v_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_2 {
  constructor($handler, $it) {
    this.rpy_1 = $handler;
    this.spy_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_2(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_3 {
  constructor($image) {
    this.mpz_1 = $image;
  }
  tbo(it, $completion) {
    this.mpz_1.gf4(Companion_getInstance_11().p2v_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_3 {
  constructor($handler, $it) {
    this.tpy_1 = $handler;
    this.upy_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_3(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_4 {
  constructor($image) {
    this.npz_1 = $image;
  }
  tbo(it, $completion) {
    // Inline function 'korlibs.korge.view.size' call
    var this_0 = this.npz_1;
    size(this_0, numberToDouble(300), numberToDouble(300));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_4 {
  constructor($handler, $it) {
    this.vpy_1 = $handler;
    this.wpy_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_4(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_5 {
  constructor($image) {
    this.opz_1 = $image;
  }
  tbo(it, $completion) {
    // Inline function 'korlibs.korge.view.size' call
    var this_0 = this.opz_1;
    size(this_0, numberToDouble(300), numberToDouble(170));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_5 {
  constructor($handler, $it) {
    this.xpy_1 = $handler;
    this.ypy_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_5(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_6 {
  constructor($image) {
    this.ppz_1 = $image;
  }
  tbo(it, $completion) {
    // Inline function 'korlibs.korge.view.size' call
    var this_0 = this.ppz_1;
    size(this_0, numberToDouble(170), numberToDouble(300));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_6 {
  constructor($handler, $it) {
    this.zpy_1 = $handler;
    this.apz_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_6(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_7 {
  constructor($image, $korimPng) {
    this.qpz_1 = $image;
    this.rpz_1 = $korimPng;
  }
  tbo(it, $completion) {
    this.qpz_1.ff4(this.rpz_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_7 {
  constructor($handler, $it) {
    this.bpz_1 = $handler;
    this.cpz_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_7(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_8 {
  constructor($image, $bunnysPng) {
    this.spz_1 = $image;
    this.tpz_1 = $bunnysPng;
  }
  tbo(it, $completion) {
    this.spz_1.ff4(this.tpz_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_8 {
  constructor($handler, $it) {
    this.dpz_1 = $handler;
    this.epz_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_8(this, $completion), $completion);
  }
}
class MainUIImageTester$main$slambda_9 {
  constructor($image, $vampireAse) {
    this.upz_1 = $image;
    this.vpz_1 = $vampireAse;
  }
  tbo(it, $completion) {
    this.upz_1.ff4(this.vpz_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class MainUIImageTester$main$lambda$slambda_9 {
  constructor($handler, $it) {
    this.fpz_1 = $handler;
    this.gpz_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_9(this, $completion), $completion);
  }
}
class ShowScene extends Scene {
  constructor() {
    super();
    this.dq1_1 = null;
  }
  eq1(_this__u8e3s4) {
    this.fq1();
    var tmp = this;
    // Inline function 'korlibs.korge.view.zIndex' call
    // Inline function 'korlibs.korge.view.graphics' call
    var renderer = GraphicsRenderer_SYSTEM_getInstance();
    // Inline function 'kotlin.also' call
    var this_0 = addTo(Graphics.zh3(EmptyShape_instance, renderer), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var result;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.apply' call
    var this_1 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    // Inline function 'extension.ShowScene.showSpinner.<anonymous>' call
    // Inline function 'korlibs.image.vector.Context2d.stroke' call
    var paint = new RGBA(Colors_getInstance().g4r_1);
    var lineCap = this_1.p5u();
    var lineJoin = this_1.q5u();
    var miterLimit = this_1.r5u();
    var lineDash = this_1.s5u();
    var lineDashOffset = this_1.t5u();
    if (true) {
      this_1.b5t();
    }
    // Inline function 'extension.ShowScene.showSpinner.<anonymous>.<anonymous>' call
    this_1.s4a(Vector2D.k2l(0, 0), 48.0, get_degrees(0), get_degrees(60));
    this_1.s4a(Vector2D.k2l(0, 0), 48.0, get_degrees(180), get_degrees(240));
    // Inline function 'korlibs.image.vector.Context2d.keep' call
    this_1.u5u();
    try {
      // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
      this_1.v5u(numberToDouble(6.0));
      this_1.w5u(lineCap);
      this_1.x5u(lineJoin);
      this_1.y5u(numberToDouble(miterLimit));
      this_1.z5u(lineDash);
      this_1.a5v(numberToDouble(lineDashOffset));
      this_1.b5v(paint);
    }finally {
      this_1.c5v();
    }
    result = Unit_instance;
    var tmp$ret$1 = this_1.p5r();
    this_0.xh3(tmp$ret$1);
    this_0.fh4();
    var this_2 = position(this_0, _this__u8e3s4.j2y() * 0.5, _this__u8e3s4.k2y() * 0.5);
    tmp.dq1_1 = zIndex(this_2, numberToDouble(99999));
    var tmp_0 = ensureNotNull(this.dq1_1);
    addUpdater(tmp_0, ShowScene$showSpinner$lambda);
  }
  fq1() {
    if (!(this.dq1_1 == null)) {
      var tmp53_safe_receiver = this.dq1_1;
      if (tmp53_safe_receiver == null)
        null;
      else {
        tmp53_safe_receiver.qbh();
      }
      this.dq1_1 = null;
    }
  }
}
class AutoShowScene extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl_1(_this__u8e3s4, this, $completion), $completion);
  }
}
class MainUIImageTester extends AutoShowScene {
  hq0(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_main__qlaff5(_this__u8e3s4, this, $completion), $completion);
  }
}
class SimpleBox2dScene$sceneMain$slambda {
  constructor($$this$fixedSizeContainer, this$0) {
    this.lq1_1 = $$this$fixedSizeContainer;
    this.mq1_1 = this$0;
  }
  tbo(it, $completion) {
    var pos = it.ibq();
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture' call
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.solidRect' call
    var this_0 = this.lq1_1;
    var color = Colors_getInstance().i4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = SolidRect.ddj(Size2D.f2j(50, 50), color);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    var this_3 = rotation(position(this_2, pos.i2h_1, pos.j2h_1), get_1(this.mq1_1.yq1_1, this.mq1_1, get_degrees(0), get_degrees(90)));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'box2d.SimpleBox2dScene.sceneMain.<anonymous>.<anonymous>.<anonymous>' call
    this_3.m59(get(this.mq1_1.yq1_1, new RGBA(Colors_getInstance().i4r_1), new RGBA(Colors_getInstance().w4v_1)).v59_1);
    var type = BodyType_DYNAMIC_getInstance();
    var view = this_3;
    // Inline function 'korlibs.korge.box2d.createBody' call
    var tmp = null == null ? get_nearestBox2dWorld(this_3) : null;
    // Inline function 'kotlin.apply' call
    var this_4 = new BodyDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    this_4.vip_1 = type;
    this_4.pis(this_3.bcc());
    this_4.aiq_1 = numberToDouble(0.0);
    this_4.xip_1.ci4(this_3.n4f(), this_3.p4f());
    this_4.zip_1.ci4(numberToDouble(0.0), numberToDouble(0.0));
    this_4.biq_1 = numberToDouble(0.0);
    this_4.ciq_1 = numberToDouble(0.0);
    this_4.iiq_1 = numberToDouble(1.0);
    this_4.diq_1 = true;
    this_4.fiq_1 = false;
    this_4.giq_1 = false;
    this_4.eiq_1 = true;
    this_4.hiq_1 = true;
    var body = tmp.uiz(this_4);
    var world = body.ei4_1;
    // Inline function 'korlibs.korge.box2d.fixture' call
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.fixture.<anonymous>' call
    // Inline function 'kotlin.apply' call
    var this_5 = new FixtureDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    var tmp_0 = this_5;
    var tmp_1;
    if (null == null) {
      var tmp_2;
      if (view instanceof Circle) {
        tmp_2 = CircleShape.nim(view.xcv() / world.wi1_1);
      } else {
        var tmp_3;
        if (view instanceof Ellipse) {
          tmp_3 = view.egq();
        } else {
          tmp_3 = false;
        }
        if (tmp_3) {
          tmp_2 = CircleShape.nim(view.xcv().d2j_1 / world.wi1_1);
        } else {
          tmp_2 = BoxShape(this_3.wbp().n2t(world.wi1_1));
        }
      }
      tmp_1 = tmp_2;
    } else {
      tmp_1 = null;
    }
    tmp_0.liu_1 = tmp_1;
    this_5.qiu_1 = false;
    this_5.niu_1 = numberToDouble(0.2);
    this_5.oiu_1 = numberToDouble(0.2);
    this_5.piu_1 = numberToDouble(1.0);
    body.gir(this_5);
    set_view(body, this_3);
    set_body(this_3, body);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class SimpleBox2dScene$sceneMain$lambda$slambda {
  constructor($handler, $it) {
    this.gq1_1 = $handler;
    this.hq1_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_10(this, $completion), $completion);
  }
}
class SimpleBox2dScene$sceneMain$slambda_0 {
  constructor(this$0) {
    this.iq1_1 = this$0;
  }
  tbo(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_11(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class SimpleBox2dScene$sceneMain$lambda$slambda_0 {
  constructor($handler, $it) {
    this.jq1_1 = $handler;
    this.kq1_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_12(this, $completion), $completion);
  }
}
class SimpleBox2dScene extends ShowScene {
  constructor() {
    super();
    this.yq1_1 = Random(new Long(0, 0));
  }
  mbz(_this__u8e3s4, $completion) {
    var stage = ensureNotNull(_this__u8e3s4.pbj());
    // Inline function 'korlibs.korge.view.fixedSizeContainer' call
    var size = Size2D.k2t(stage.j2y(), stage.k2y());
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = FixedSizeContainer.sfh(size, false);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'box2d.SimpleBox2dScene.sceneMain.<anonymous>' call
    // Inline function 'korlibs.korge.view.position' call
    // Inline function 'korlibs.korge.view.solidRect' call
    var color = Colors_getInstance().i4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_2 = SolidRect.ddj(Size2D.f2j(50, 50), color);
    // Inline function 'kotlin.apply' call
    var this_3 = addTo(this_2, this_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    var tmp$ret$4 = xy(this_3, numberToDouble(400), numberToDouble(50));
    var tmp0_$receiver = rotation(tmp$ret$4, get_degrees(30));
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture' call
    var type = BodyType_DYNAMIC_getInstance();
    var view = tmp0_$receiver;
    // Inline function 'korlibs.korge.box2d.createBody' call
    var tmp = null == null ? get_nearestBox2dWorld(tmp0_$receiver) : null;
    // Inline function 'kotlin.apply' call
    var this_4 = new BodyDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    this_4.vip_1 = type;
    this_4.pis(tmp0_$receiver.bcc());
    this_4.aiq_1 = numberToDouble(0.0);
    this_4.xip_1.ci4(tmp0_$receiver.n4f(), tmp0_$receiver.p4f());
    this_4.zip_1.ci4(numberToDouble(0.0), numberToDouble(0.0));
    this_4.biq_1 = numberToDouble(0.0);
    this_4.ciq_1 = numberToDouble(0.0);
    this_4.iiq_1 = numberToDouble(1.0);
    this_4.diq_1 = true;
    this_4.fiq_1 = false;
    this_4.giq_1 = false;
    this_4.eiq_1 = true;
    this_4.hiq_1 = true;
    var body = tmp.uiz(this_4);
    var world = body.ei4_1;
    // Inline function 'korlibs.korge.box2d.fixture' call
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.fixture.<anonymous>' call
    // Inline function 'kotlin.apply' call
    var this_5 = new FixtureDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    var tmp_0 = this_5;
    var tmp_1;
    if (null == null) {
      var tmp_2;
      if (view instanceof Circle) {
        tmp_2 = CircleShape.nim(view.xcv() / world.wi1_1);
      } else {
        var tmp_3;
        if (view instanceof Ellipse) {
          tmp_3 = view.egq();
        } else {
          tmp_3 = false;
        }
        if (tmp_3) {
          tmp_2 = CircleShape.nim(view.xcv().d2j_1 / world.wi1_1);
        } else {
          tmp_2 = BoxShape(tmp0_$receiver.wbp().n2t(world.wi1_1));
        }
      }
      tmp_1 = tmp_2;
    } else {
      tmp_1 = null;
    }
    tmp_0.liu_1 = tmp_1;
    this_5.qiu_1 = false;
    this_5.niu_1 = numberToDouble(0.01);
    this_5.oiu_1 = numberToDouble(0.2);
    this_5.piu_1 = numberToDouble(2);
    body.gir(this_5);
    set_view(body, tmp0_$receiver);
    set_body(tmp0_$receiver, body);
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture' call
    // Inline function 'korlibs.korge.view.position' call
    // Inline function 'korlibs.korge.view.solidRect' call
    var color_0 = Colors_getInstance().i4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_6 = SolidRect.ddj(Size2D.f2j(50, 50), color_0);
    // Inline function 'kotlin.apply' call
    var this_7 = addTo(this_6, this_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    var this_8 = xy(this_7, numberToDouble(300), numberToDouble(100));
    var type_0 = BodyType_DYNAMIC_getInstance();
    var view_0 = this_8;
    // Inline function 'korlibs.korge.box2d.createBody' call
    var tmp_4 = null == null ? get_nearestBox2dWorld(this_8) : null;
    // Inline function 'kotlin.apply' call
    var this_9 = new BodyDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    this_9.vip_1 = type_0;
    this_9.pis(this_8.bcc());
    this_9.aiq_1 = numberToDouble(0.0);
    this_9.xip_1.ci4(this_8.n4f(), this_8.p4f());
    this_9.zip_1.ci4(numberToDouble(0.0), numberToDouble(0.0));
    this_9.biq_1 = numberToDouble(0.0);
    this_9.ciq_1 = numberToDouble(0.0);
    this_9.iiq_1 = numberToDouble(1.0);
    this_9.diq_1 = true;
    this_9.fiq_1 = false;
    this_9.giq_1 = false;
    this_9.eiq_1 = true;
    this_9.hiq_1 = true;
    var body_0 = tmp_4.uiz(this_9);
    var world_0 = body_0.ei4_1;
    // Inline function 'korlibs.korge.box2d.fixture' call
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.fixture.<anonymous>' call
    // Inline function 'kotlin.apply' call
    var this_10 = new FixtureDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    var tmp_5 = this_10;
    var tmp_6;
    if (null == null) {
      var tmp_7;
      if (view_0 instanceof Circle) {
        tmp_7 = CircleShape.nim(view_0.xcv() / world_0.wi1_1);
      } else {
        var tmp_8;
        if (view_0 instanceof Ellipse) {
          tmp_8 = view_0.egq();
        } else {
          tmp_8 = false;
        }
        if (tmp_8) {
          tmp_7 = CircleShape.nim(view_0.xcv().d2j_1 / world_0.wi1_1);
        } else {
          tmp_7 = BoxShape(this_8.wbp().n2t(world_0.wi1_1));
        }
      }
      tmp_6 = tmp_7;
    } else {
      tmp_6 = null;
    }
    tmp_5.liu_1 = tmp_6;
    this_10.qiu_1 = false;
    this_10.niu_1 = numberToDouble(0.2);
    this_10.oiu_1 = numberToDouble(0.2);
    this_10.piu_1 = numberToDouble(1.0);
    body_0.gir(this_10);
    set_view(body_0, this_8);
    set_body(this_8, body_0);
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture' call
    // Inline function 'korlibs.korge.view.position' call
    // Inline function 'korlibs.korge.view.solidRect' call
    var color_1 = Colors_getInstance().i4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_11 = SolidRect.ddj(Size2D.f2j(50, 50), color_1);
    // Inline function 'kotlin.apply' call
    var this_12 = addTo(this_11, this_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    var tmp$ret$26 = xy(this_12, numberToDouble(450), numberToDouble(100));
    var this_13 = rotation(tmp$ret$26, get_degrees(15));
    var type_1 = BodyType_DYNAMIC_getInstance();
    var view_1 = this_13;
    // Inline function 'korlibs.korge.box2d.createBody' call
    var tmp_9 = null == null ? get_nearestBox2dWorld(this_13) : null;
    // Inline function 'kotlin.apply' call
    var this_14 = new BodyDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    this_14.vip_1 = type_1;
    this_14.pis(this_13.bcc());
    this_14.aiq_1 = numberToDouble(0.0);
    this_14.xip_1.ci4(this_13.n4f(), this_13.p4f());
    this_14.zip_1.ci4(numberToDouble(0.0), numberToDouble(0.0));
    this_14.biq_1 = numberToDouble(0.0);
    this_14.ciq_1 = numberToDouble(0.0);
    this_14.iiq_1 = numberToDouble(1.0);
    this_14.diq_1 = true;
    this_14.fiq_1 = false;
    this_14.giq_1 = false;
    this_14.eiq_1 = true;
    this_14.hiq_1 = true;
    var body_1 = tmp_9.uiz(this_14);
    var world_1 = body_1.ei4_1;
    // Inline function 'korlibs.korge.box2d.fixture' call
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.fixture.<anonymous>' call
    // Inline function 'kotlin.apply' call
    var this_15 = new FixtureDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    var tmp_10 = this_15;
    var tmp_11;
    if (null == null) {
      var tmp_12;
      if (view_1 instanceof Circle) {
        tmp_12 = CircleShape.nim(view_1.xcv() / world_1.wi1_1);
      } else {
        var tmp_13;
        if (view_1 instanceof Ellipse) {
          tmp_13 = view_1.egq();
        } else {
          tmp_13 = false;
        }
        if (tmp_13) {
          tmp_12 = CircleShape.nim(view_1.xcv().d2j_1 / world_1.wi1_1);
        } else {
          tmp_12 = BoxShape(this_13.wbp().n2t(world_1.wi1_1));
        }
      }
      tmp_11 = tmp_12;
    } else {
      tmp_11 = null;
    }
    tmp_10.liu_1 = tmp_11;
    this_15.qiu_1 = false;
    this_15.niu_1 = numberToDouble(0.2);
    this_15.oiu_1 = numberToDouble(0.2);
    this_15.piu_1 = numberToDouble(1.0);
    body_1.gir(this_15);
    set_view(body_1, this_13);
    set_body(this_13, body_1);
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture' call
    // Inline function 'korlibs.korge.view.position' call
    // Inline function 'korlibs.korge.view.solidRect' call
    var color_2 = Colors_getInstance().g4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_16 = SolidRect.ddj(Size2D.f2j(600, 100), color_2);
    // Inline function 'kotlin.apply' call
    var this_17 = addTo(this_16, this_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    var this_18 = xy(this_17, numberToDouble(100), numberToDouble(500));
    var type_2 = BodyType_STATIC_getInstance();
    var view_2 = this_18;
    // Inline function 'korlibs.korge.box2d.createBody' call
    var tmp_14 = null == null ? get_nearestBox2dWorld(this_18) : null;
    // Inline function 'kotlin.apply' call
    var this_19 = new BodyDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    this_19.vip_1 = type_2;
    this_19.pis(this_18.bcc());
    this_19.aiq_1 = numberToDouble(0.0);
    this_19.xip_1.ci4(this_18.n4f(), this_18.p4f());
    this_19.zip_1.ci4(numberToDouble(0.0), numberToDouble(0.0));
    this_19.biq_1 = numberToDouble(0.0);
    this_19.ciq_1 = numberToDouble(0.0);
    this_19.iiq_1 = numberToDouble(1.0);
    this_19.diq_1 = true;
    this_19.fiq_1 = false;
    this_19.giq_1 = false;
    this_19.eiq_1 = true;
    this_19.hiq_1 = true;
    var body_2 = tmp_14.uiz(this_19);
    var world_2 = body_2.ei4_1;
    // Inline function 'korlibs.korge.box2d.fixture' call
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.fixture.<anonymous>' call
    // Inline function 'kotlin.apply' call
    var this_20 = new FixtureDef();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.box2d.registerBodyWithFixture.<anonymous>' call
    var tmp_15 = this_20;
    var tmp_16;
    if (null == null) {
      var tmp_17;
      if (view_2 instanceof Circle) {
        tmp_17 = CircleShape.nim(view_2.xcv() / world_2.wi1_1);
      } else {
        var tmp_18;
        if (view_2 instanceof Ellipse) {
          tmp_18 = view_2.egq();
        } else {
          tmp_18 = false;
        }
        if (tmp_18) {
          tmp_17 = CircleShape.nim(view_2.xcv().d2j_1 / world_2.wi1_1);
        } else {
          tmp_17 = BoxShape(this_18.wbp().n2t(world_2.wi1_1));
        }
      }
      tmp_16 = tmp_17;
    } else {
      tmp_16 = null;
    }
    tmp_15.liu_1 = tmp_16;
    this_20.qiu_1 = false;
    this_20.niu_1 = numberToDouble(0.2);
    this_20.oiu_1 = numberToDouble(0.2);
    this_20.piu_1 = numberToDouble(1.0);
    body_2.gir(this_20);
    set_view(body_2, this_18);
    set_body(this_18, body_2);
    // Inline function 'korlibs.korge.input.onClick' call
    var handler = SimpleBox2dScene$sceneMain$slambda_1(this_1, this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop = click$factory_10();
    var tmp73_safe_receiver = this_1 == null ? null : get_mouse(this_1);
    if (tmp73_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_19 = prop.get(tmp73_safe_receiver);
      tmp_19.v1x(SimpleBox2dScene$sceneMain$lambda(tmp73_safe_receiver, handler));
    }
    // Inline function 'korlibs.korge.input.onClick' call
    // Inline function 'korlibs.korge.ui.uiButton' call
    var size_0 = Companion_getInstance_4().fcs_1;
    // Inline function 'kotlin.apply' call
    var this_21 = addTo(UIButton.sd2(size_0, 'Reset', null), this_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
    var this_22 = position(this_21, stage.j2y() - 128.0, 0.0);
    var handler_0 = SimpleBox2dScene$sceneMain$slambda_2(this);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop_0 = click$factory_11();
    var tmp73_safe_receiver_0 = this_22 == null ? null : get_mouse(this_22);
    if (tmp73_safe_receiver_0 == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_20 = prop_0.get(tmp73_safe_receiver_0);
      tmp_20.v1x(SimpleBox2dScene$sceneMain$lambda_0(tmp73_safe_receiver_0, handler_0));
    }
  }
}
class BunnyContainer extends FSprites {
  constructor(maxSize) {
    super(maxSize);
    this.jq2_1 = get_f32(Buffer.t10(imul(imul(maxSize, 4), 2)));
  }
  kq2(_this__u8e3s4, value) {
    Float32Buffer__set_impl_34szne(this.jq2_1, imul(_FSprite___get_index__impl__7khb8f(_this__u8e3s4), 2) + 0 | 0, value);
  }
  lq2(_this__u8e3s4) {
    return Float32Buffer__get_impl_659lhu(this.jq2_1, imul(_FSprite___get_index__impl__7khb8f(_this__u8e3s4), 2) + 0 | 0);
  }
  mq2(_this__u8e3s4, value) {
    Float32Buffer__set_impl_34szne(this.jq2_1, imul(_FSprite___get_index__impl__7khb8f(_this__u8e3s4), 2) + 1 | 0, value);
  }
  nq2(_this__u8e3s4) {
    return Float32Buffer__get_impl_659lhu(this.jq2_1, imul(_FSprite___get_index__impl__7khb8f(_this__u8e3s4), 2) + 1 | 0);
  }
}
class BunnymarkScene extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl(_this__u8e3s4, this, $completion), $completion);
  }
}
class DungeonScene$sceneMain$slambda {
  constructor(this$0) {
    this.zq2_1 = this$0;
  }
  tbo(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_13(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class DungeonScene$sceneMain$lambda$slambda {
  constructor($handler, $it) {
    this.aq3_1 = $handler;
    this.bq3_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_14(this, $completion), $completion);
  }
}
class DungeonScene$sceneMain$_anonymous_$onAnyButton$slambda_aqssrx {
  constructor($this$0, $items) {
    this.cq3_1 = $this$0;
    this.dq3_1 = $items;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_15(this, $completion), $completion);
  }
}
class DungeonScene extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl_0(_this__u8e3s4, this, $completion), $completion);
  }
}
class ImageDataView2 extends Container {
  static uq5(data, animation, playing, smoothing) {
    data = data === VOID ? null : data;
    animation = animation === VOID ? null : animation;
    playing = playing === VOID ? false : playing;
    smoothing = smoothing === VOID ? true : smoothing;
    var $this = this.vbw();
    $this.oq5_1 = $this.pq9();
    $this.pq5_1 = anchorPixel$factory($this.oq6());
    $this.qq5_1 = anchor$factory($this.oq6());
    $this.rq5_1 = true;
    $this.sq5_1 = data;
    $this.tq5_1 = animation;
    updatedDataAnimation($this);
    if (playing) {
      $this.i85();
    } else {
      $this.m7w();
    }
    $this.ch4(smoothing);
    return $this;
  }
  pq9() {
    // Inline function 'dungeon.imageAnimationView2' call
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = ImageAnimationView2.qq9(null, null, ImageDataView2$createAnimationView$lambda);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'dungeon.imageAnimationView2.<anonymous>' call
    return addTo(this_0, this);
  }
  oq6() {
    return this.oq5_1;
  }
  rq9(_set____db54di) {
    var this_0 = this.pq5_1;
    anchorPixel$factory_0();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  sq9() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.pq5_1;
    anchorPixel$factory_1();
    return this_0.get();
  }
  tf4(_set____db54di) {
    var this_0 = this.qq5_1;
    anchor$factory_0();
    this_0.set(_set____db54di);
    return Unit_instance;
  }
  uf4() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.qq5_1;
    anchor$factory_1();
    return this_0.get();
  }
  ch4(value) {
    if (!(this.rq5_1 === value)) {
      this.rq5_1 = value;
      this.oq6().ch4(value);
    }
  }
  vq5(value) {
    if (!(this.tq5_1 === value)) {
      this.tq5_1 = value;
      updatedDataAnimation(this);
    }
  }
  i85() {
    this.oq6().i85();
  }
  m7w() {
    this.oq6().m7w();
  }
}
class ImageAnimationView2 extends Container {
  static qq9(animation, direction, createImage) {
    animation = animation === VOID ? null : animation;
    direction = direction === VOID ? null : direction;
    var $this = this.vbw();
    $this.xq8_1 = createImage;
    $this.yq8_1 = 1;
    $this.zq8_1 = null;
    $this.aq9_1 = null;
    $this.bq9_1 = null;
    $this.cq9_1 = animation;
    $this.dq9_1 = direction;
    var tmp = $this;
    // Inline function 'korlibs.korge.view.container' call
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = Container.vbw(false);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp.eq9_1 = addTo(this_0, $this);
    $this.fq9_1 = fastArrayListOf([]);
    $this.gq9_1 = FastStringMap();
    var tmp_0 = $this;
    // Inline function 'korlibs.time.milliseconds' call
    // Inline function 'kotlin.time.Companion.milliseconds' call
    Companion_getInstance();
    tmp_0.hq9_1 = toDuration(0, DurationUnit_MILLISECONDS_getInstance());
    $this.iq9_1 = 0;
    $this.jq9_1 = 0;
    $this.kq9_1 = 1;
    $this.lq9_1 = Companion_getInstance_15().a2i_1;
    $this.mq9_1 = true;
    $this.nq9_1 = true;
    didSetAnimation($this);
    addUpdater($this, ImageAnimationView2$lambda);
    return $this;
  }
  tq9() {
    return TileMap.nhr();
  }
  oq9(value) {
    if (!(this.cq9_1 === value)) {
      this.cq9_1 = value;
      didSetAnimation(this);
    }
  }
  rq9(value) {
    this.lq9_1 = value;
    // Inline function 'korlibs.math.geom.Vector2D.unaryMinus' call
    var tmp$ret$0 = Vector2D.k2h(-value.i2h_1, -value.j2h_1);
    this.eq9_1.s5w(tmp$ret$0);
  }
  sq9() {
    return this.lq9_1;
  }
  tf4(value) {
    this.rq9(Vector2D.k2h(value.y2g_1 * this.j2y(), value.z2g_1 * this.k2y()));
  }
  uf4() {
    return Anchor2D.g2h(this.sq9().i2h_1 / this.j2y(), this.sq9().j2h_1 / this.k2y());
  }
  ch4(value) {
    if (!(this.mq9_1 === value)) {
      this.mq9_1 = value;
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var array = this.fq9_1.e14_1;
      var n = 0;
      while (n < array.length) {
        // Inline function 'dungeon.ImageAnimationView2.<set-smoothing>.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'korlibs.datastructure.get' call
        var _unary__edvuaz = n;
        n = _unary__edvuaz + 1 | 0;
        // Inline function 'kotlin.js.asDynamic' call
        // Inline function 'kotlin.js.asDynamic' call
        var it = array[_unary__edvuaz];
        if (isInterface(it, SmoothedBmpSlice)) {
          it.ch4(value);
        }
      }
    }
  }
  i85() {
    this.nq9_1 = true;
  }
  m7w() {
    this.nq9_1 = false;
  }
}
class BvhWorld {
  constructor(baseView) {
    this.eq3_1 = baseView;
    this.fq3_1 = new BVH2D();
  }
  cq6() {
    return this.fq3_1.e40(this.fq3_1.a40());
  }
  sgh(view) {
    // Inline function 'kotlin.also' call
    var this_0 = new BvhEntity(this, view);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'dungeon.BvhWorld.add.<anonymous>' call
    this_0.dpl();
    return this_0;
  }
  ubw(view) {
    this.sgh(view);
  }
}
class BvhEntity {
  constructor(world, view) {
    this.wq5_1 = world;
    this.xq5_1 = view;
  }
  dpl() {
    var rect = this.xq5_1.ycd(this.wq5_1.eq3_1);
    var pos = rect.e2u(Companion_getInstance_10().w2g_1);
    var tmp = Companion_getInstance_13();
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var that = Vector2D.k2l(8, 16);
    var tmp$ret$0 = Vector2D.k2h(pos.i2h_1 - that.i2h_1, pos.j2h_1 - that.j2h_1);
    this.wq5_1.fq3_1.f40(tmp.g2j(tmp$ret$0, Size2D.f2j(16, 16)), this);
  }
}
class Companion {
  constructor() {
    this.uq9_1 = -100;
  }
}
class ViewRenderPhaseMask {
  constructor(mask, maskFiltering) {
    this.vq9_1 = mask;
    this.wq9_1 = maskFiltering;
  }
  ih8() {
    return -100;
  }
  lh8(view, ctx) {
    // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
    // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
    var this_0 = ctx.ka7_1;
    var this_1 = this_0.ta7_1;
    if (!(this_1.oa7_1 === this_0)) {
      this_1.oa7_1 = this_0;
      this_1.ya8();
    }
    var maskBounds = this.vq9_1.wbp();
    var boundsWidth = numberToInt(maskBounds.k2i_1);
    var boundsHeight = numberToInt(maskBounds.l2i_1);
    var rb = ctx.kai(boundsWidth, boundsHeight, false, true, 1);
    var tmp;
    try {
      var rb_0 = ctx.kai(boundsWidth, boundsHeight, false, true, 1);
      var tmp_0;
      try {
        // Inline function 'korlibs.korge.render.BatchBuilder2D.setViewMatrixTemp' call
        var matrix = this.vq9_1.tcc();
        var this_2 = this_0.ta7_1;
        this_2.ya8();
        var temp4 = this_2.za6_1;
        var temp2d = this_2.aa7_1;
        this_2.aa7_1 = matrix;
        this_2.za6_1 = toMatrix4(matrix);
        $l$block: {
          // Inline function 'korlibs.graphics.shader.UniformBlockBuffer.push' call
          var this_3 = this_2.y8r(ProjViewUB_getInstance());
          var _unary__edvuaz = this_3.n8t();
          this_3.v9h(_unary__edvuaz + 1 | 0);
          this_3.w9h(this_3.n8t() + 1 | 0);
          var blockSize = this_3.r8r_1;
          var index0 = imul(this_3.n8t() - 1 | 0, blockSize);
          var index1 = imul(this_3.n8t(), blockSize);
          var texBlockSize = this_3.o8r_1.s8z().j1();
          var texIndex0 = imul(this_3.n8t() - 1 | 0, texBlockSize);
          var texIndex1 = imul(this_3.n8t(), texBlockSize);
          if (this_3.n8t() > 0) {
            arraycopy(this_3.t8r_1, index0, this_3.t8r_1, index1, blockSize);
          } else {
            arrayfill(this_3.t8r_1, 0, 0, blockSize);
          }
          // Inline function 'korlibs.korge.render.RenderContext.setViewMatrixTemp.<anonymous>' call
          var $this$push = this_3.o8r_1;
          this_3.u8r_1.m9i($this$push.r8z(), this_2.za6_1);
          if (true && this_3.n8t() >= 1) {
            var equals = arrayequal(this_3.t8r_1, index0, this_3.t8r_1, index1, blockSize);
            if (equals) {
              var _unary__edvuaz_0 = this_3.n8t();
              this_3.v9h(_unary__edvuaz_0 - 1 | 0);
              break $l$block;
            }
          }
        }
        var tmp_1;
        try {
          // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer' call
          // Inline function 'korlibs.korge.render.RenderContext.setFrameBufferTemporally' call
          ctx.mai(rb);
          try {
            // Inline function 'korlibs.korge.render.RenderContext.renderToFrameBuffer.<anonymous>' call
            // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
            // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
            var this_4 = ctx.ka7_1;
            var this_5 = this_4.ta7_1;
            if (!(this_5.oa7_1 === this_4)) {
              this_5.oa7_1 = this_4;
              this_5.ya8();
            }
            $l$block_0: {
              // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
              var scissor = AGScissor.p8v(0, 0, rb.a8k_1, rb.b8k_1);
              var temp = this_4.ra8_1;
              this_4.eaf();
              this_4.ra8_1 = scissor;
              try {
                if (true) {
                  ctx.eai(Colors_getInstance().l4r_1);
                }
                // Inline function 'dungeon.ViewRenderPhaseMask.render.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                ctx.eai(Colors_getInstance().l4r_1);
                var oldVisible = this.vq9_1.qcc();
                try {
                  this.vq9_1.car(true);
                  this.vq9_1.ccd(ctx);
                }finally {
                  this.vq9_1.car(oldVisible);
                }
                break $l$block_0;
              }finally {
                this_4.eaf();
                this_4.ra8_1 = temp;
              }
            }
          }finally {
            ctx.nai();
          }
          ctx.mai(rb_0);
          var tmp_2;
          try {
            // Inline function 'korlibs.korge.render.RenderContext.useBatcher' call
            // Inline function 'korlibs.korge.render.BatchBuilder2D.use' call
            var this_6 = ctx.ka7_1;
            var this_7 = this_6.ta7_1;
            if (!(this_7.oa7_1 === this_6)) {
              this_7.oa7_1 = this_6;
              this_7.ya8();
            }
            $l$block_1: {
              // Inline function 'korlibs.korge.render.BatchBuilder2D.scissor' call
              var scissor_0 = AGScissor.p8v(0, 0, rb_0.a8k_1, rb_0.b8k_1);
              var temp_0 = this_6.ra8_1;
              this_6.eaf();
              this_6.ra8_1 = scissor_0;
              try {
                if (true) {
                  ctx.eai(Colors_getInstance().l4r_1);
                }
                ctx.eai(Colors_getInstance().l4r_1);
                view.dcd(ctx);
                break $l$block_1;
              }finally {
                this_6.eaf();
                this_6.ra8_1 = temp_0;
              }
            }
            tmp_2 = Unit_instance;
          }finally {
            ctx.nai();
          }
          tmp_1 = tmp_2;
        }finally {
          this_2.ya8();
          this_2.za6_1 = temp4;
          this_2.aa7_1 = temp2d;
          this_2.y8r(ProjViewUB_getInstance()).x9h();
        }
        $l$block_2: {
          // Inline function 'korlibs.korge.render.BatchBuilder2D.temporalTextureUnit' call
          var sampler1 = DefaultShaders_getInstance().p92();
          var tex1 = rb_0.n8p();
          var sampler2 = DefaultShaders_getInstance().r92();
          var tex2 = rb.n8p();
          var info = Companion_getInstance_23().y8o_1;
          var oldTex1 = this_0.ta7_1.va6_1.c8o_1[sampler1.s8o_1];
          var oldInfo1 = AGTextureUnitInfoArray__get_impl_xhvpef(this_0.ta7_1.va6_1.d8o_1, sampler1.s8o_1);
          var oldTex2 = this_0.ta7_1.va6_1.c8o_1[sampler2.s8o_1];
          var oldInfo2 = AGTextureUnitInfoArray__get_impl_xhvpef(this_0.ta7_1.va6_1.d8o_1, sampler2.s8o_1);
          this_0.ta7_1.va6_1.z8o(sampler1, tex1, info);
          this_0.ta7_1.va6_1.z8o(sampler2, tex2, info);
          try {
            var tmp48_tex = Texture(rb_0);
            var tmp49_m = this.vq9_1.ybp();
            var tmp50_program = DefaultShaders_getInstance().a8y_1;
            var tmp51_filtering = this.wq9_1;
            this_0.aa9(tmp48_tex, VOID, VOID, VOID, VOID, tmp49_m, tmp51_filtering, VOID, VOID, tmp50_program);
            break $l$block_2;
          }finally {
            this_0.gae();
            this_0.ta7_1.va6_1.z8o(sampler2, oldTex2, oldInfo2);
            this_0.ta7_1.va6_1.z8o(sampler1, oldTex1, oldInfo1);
          }
        }
        tmp_0 = Unit_instance;
      }finally {
        ctx.lai(rb_0);
      }
      tmp = tmp_0;
    }finally {
      ctx.lai(rb);
    }
    view.dcd(ctx);
  }
}
class RayResult {
  constructor(ray, point, normal) {
    this.yq5_1 = new Mixin();
    this.zq5_1 = ray;
    this.aq6_1 = point;
    this.bq6_1 = normal;
  }
  n13(_set____db54di) {
    this.yq5_1.n13(_set____db54di);
  }
  o13() {
    return this.yq5_1.o13();
  }
  toString() {
    return 'RayResult(ray=' + this.zq5_1.toString() + ', point=' + this.aq6_1.toString() + ', normal=' + this.bq6_1.toString() + ')';
  }
  hashCode() {
    var result = this.zq5_1.hashCode();
    result = imul(result, 31) + this.aq6_1.hashCode() | 0;
    result = imul(result, 31) + this.bq6_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof RayResult))
      return false;
    var tmp0_other_with_cast = other instanceof RayResult ? other : THROW_CCE();
    if (!this.zq5_1.equals(tmp0_other_with_cast.zq5_1))
      return false;
    if (!this.aq6_1.equals(tmp0_other_with_cast.aq6_1))
      return false;
    if (!this.bq6_1.equals(tmp0_other_with_cast.bq6_1))
      return false;
    return true;
  }
}
class EasingsScene$sceneMain$renderEasing$slambda {
  constructor($bg) {
    this.gqa_1 = $bg;
  }
  tbo(it, $completion) {
    this.gqa_1.lcq(RGBA__withAd_impl_cralao(Colors_getInstance().h4r_1, 1.0));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class EasingsScene$sceneMain$renderEasing$lambda$slambda {
  constructor($handler, $it) {
    this.xq9_1 = $handler;
    this.yq9_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_16(this, $completion), $completion);
  }
}
class EasingsScene$sceneMain$renderEasing$slambda_0 {
  constructor($bg) {
    this.hqa_1 = $bg;
  }
  tbo(it, $completion) {
    this.hqa_1.lcq(RGBA__withAd_impl_cralao(Colors_getInstance().h4r_1, 0.2));
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class EasingsScene$sceneMain$renderEasing$lambda$slambda_0 {
  constructor($handler, $it) {
    this.zq9_1 = $handler;
    this.aqa_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_17(this, $completion), $completion);
  }
}
class EasingsScene$sceneMain$renderEasing$slambda_1 {
  constructor($ballTween, $ball, $easing) {
    this.bqa_1 = $ballTween;
    this.cqa_1 = $ball;
    this.dqa_1 = $easing;
  }
  tbo(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_18(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class EasingsScene$sceneMain$renderEasing$lambda$slambda_1 {
  constructor($handler, $it) {
    this.eqa_1 = $handler;
    this.fqa_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_19(this, $completion), $completion);
  }
}
class EasingsScene extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    var ballTween = {_v: null};
    // Inline function 'korlibs.korge.view.xy' call
    // Inline function 'korlibs.korge.view.circle' call
    var fill = new RGBA(Colors_getInstance().w4v_1);
    var stroke = new RGBA(Colors_getInstance().g4r_1);
    var renderer = GraphicsRenderer_GPU_getInstance();
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = Circle.hgh(numberToDouble(64.0), fill, stroke, numberToDouble(0.0), true, renderer);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.circle.<anonymous>' call
    var ball = xy(this_1, numberToDouble(64), numberToDouble(64));
    // Inline function 'kotlin.collections.toTypedArray' call
    var this_2 = get_ALL(Companion_getInstance_19()).m3();
    var tmp$ret$5 = copyToArray(this_2);
    var easings = listOf(arrayConcat([tmp$ret$5, [cubic(Companion_getInstance_19(), 0.86, 0.13, 0.22, 0.84)]]));
    // Inline function 'korlibs.korge.view.container' call
    // Inline function 'korlibs.korge.view.addTo' call
    var this_3 = Container.vbw(false);
    // Inline function 'kotlin.apply' call
    var this_4 = addTo(this_3, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'easings.EasingsScene.sceneMain.<anonymous>' call
    this_4.ocb(0.9);
    var mn = 0;
    var inductionVariable = 0;
    if (inductionVariable < 4)
      do {
        var my = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < 8)
          $l$loop: do {
            var mx = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var _unary__edvuaz = mn;
            mn = _unary__edvuaz + 1 | 0;
            var tmp0_elvis_lhs = getOrNull(easings, _unary__edvuaz);
            var tmp;
            if (tmp0_elvis_lhs == null) {
              continue $l$loop;
            } else {
              tmp = tmp0_elvis_lhs;
            }
            var easing = tmp;
            // Inline function 'korlibs.korge.view.xy' call
            var this_5 = sceneMain$renderEasing(ballTween, ball, easing);
            var x = 50 + imul(mx, 100) | 0;
            var y = 300 + imul(my, 100) | 0;
            var tmp$ret$6 = xy(this_5, numberToDouble(x), numberToDouble(y));
            addTo(tmp$ret$6, this_4);
          }
           while (inductionVariable_0 < 8);
      }
       while (inductionVariable < 4);
  }
}
class Ext {
  tqa() {
  }
  uqa(stage) {
  }
  vqa(event, handler) {
  }
  wqa(event, detail) {
  }
  xqa() {
    return null;
  }
}
class SceneInfo {
  constructor(title, group, path, clazz, gen) {
    this.yqa_1 = title;
    this.zqa_1 = group;
    this.aqb_1 = path;
    this.bqb_1 = clazz;
    this.cqb_1 = gen;
  }
  dqb() {
    return get_portableSimpleName(this.bqb_1);
  }
  toString() {
    return this.yqa_1;
  }
}
class FiltersScene extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl_2(_this__u8e3s4, this, $completion), $completion);
  }
}
class GesturesScene extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl_3(_this__u8e3s4, this, $completion), $completion);
  }
}
class K3DScene$main$slambda {
  constructor(this$0, $koralView, $$this$scene3D) {
    this.aqc_1 = this$0;
    this.bqc_1 = $koralView;
    this.cqc_1 = $$this$scene3D;
  }
  tbo(it, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_20(this, it, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class K3DScene$main$lambda$slambda {
  constructor($handler, $it) {
    this.dqc_1 = $handler;
    this.eqc_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_21(this, $completion), $completion);
  }
}
class K3DScene$main$lambda$slambda_0 {
  constructor($it, $koralView, $$this$scene3D) {
    this.fqc_1 = $it;
    this.gqc_1 = $koralView;
    this.hqc_1 = $$this$scene3D;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_22(this, $completion), $completion);
  }
}
class K3DScene$main$slambda_0 {
  constructor($cameraDistance, $centerPointAxisLines, $centerPoint, $$this$scene3D, $rotationY, $rotationX) {
    this.zqc_1 = $cameraDistance;
    this.aqd_1 = $centerPointAxisLines;
    this.bqd_1 = $centerPoint;
    this.cqd_1 = $$this$scene3D;
    this.dqd_1 = $rotationY;
    this.eqd_1 = $rotationX;
  }
  fqd(it, $completion) {
    this.zqc_1._v = this.zqc_1._v - it.a8e() * 2;
    main$_anonymous_$updateOrbitCamera_1ds9jw(this.aqd_1, this.bqd_1, this.cqd_1, this.zqc_1, this.dqd_1, this.eqd_1);
  }
  qe(p1, $completion) {
    return this.fqd(p1 instanceof GestureEvents ? p1 : THROW_CCE(), $completion);
  }
}
class K3DScene$main$lambda$slambda_1 {
  constructor($handler, $it) {
    this.iqc_1 = $handler;
    this.jqc_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_23(this, $completion), $completion);
  }
}
class K3DScene$main$slambda_1 {
  constructor($centerPoint, $centerPointAxisLines, $$this$scene3D, $cameraDistance, $rotationY, $rotationX) {
    this.gqd_1 = $centerPoint;
    this.hqd_1 = $centerPointAxisLines;
    this.iqd_1 = $$this$scene3D;
    this.jqd_1 = $cameraDistance;
    this.kqd_1 = $rotationY;
    this.lqd_1 = $rotationX;
  }
  tbo(it, $completion) {
    this.gqd_1._v = this.gqd_1._v.p2x(Vector3F.n2x(it.mbq() * 0.25, -it.nbq() * 0.25, 0.0));
    main$_anonymous_$updateOrbitCamera_1ds9jw(this.hqd_1, this.gqd_1, this.iqd_1, this.jqd_1, this.kqd_1, this.lqd_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class K3DScene$main$lambda$slambda_2 {
  constructor($handler, $it) {
    this.kqc_1 = $handler;
    this.lqc_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_24(this, $completion), $completion);
  }
}
class K3DScene extends AutoShowScene {
  yqc() {
    var tmp = this.xqc_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('dropFileRect');
    }
  }
  hq0(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_main__qlaff5_0(_this__u8e3s4, this, $completion), $completion);
  }
}
class main$slambda$lambda$slambda {
  constructor($$this$run, $it) {
    this.mqd_1 = $$this$run;
    this.nqd_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_25(this, $completion), $completion);
  }
}
class main$slambda$lambda$slambda_0 {
  constructor($this_Korge, $detail) {
    this.oqd_1 = $this_Korge;
    this.pqd_1 = $detail;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_26(this, $completion), $completion);
  }
}
class main$slambda$lambda$slambda_1 {
  constructor($this_Korge) {
    this.qqd_1 = $this_Korge;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_27(this, $completion), $completion);
  }
}
class main$slambda {
  tb3($this$Korge, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_28(this, $this$Korge, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.tb3(p1 instanceof Stage ? p1 : THROW_CCE(), $completion);
  }
}
class addFullScreenButton$slambda {
  constructor($$this$mouse) {
    this.rqd_1 = $$this$mouse;
  }
  tbo(it, $completion) {
    toggleFullScreen(this.rqd_1.vbk().eb7_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class addFullScreenButton$lambda$slambda {
  constructor($handler, $it) {
    this.sqd_1 = $handler;
    this.tqd_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_29(this, $completion), $completion);
  }
}
class Scene1 extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl_4(_this__u8e3s4, this, $completion), $completion);
  }
}
class Scene2$sceneMain$slambda {
  constructor($$this$image) {
    this.jqe_1 = $$this$image;
  }
  tbo(it, $completion) {
    this.jqe_1.kbj(1.0);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class Scene2$sceneMain$lambda$slambda {
  constructor($handler, $it) {
    this.fqe_1 = $handler;
    this.gqe_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_30(this, $completion), $completion);
  }
}
class Scene2$sceneMain$slambda_0 {
  constructor($$this$image) {
    this.kqe_1 = $$this$image;
  }
  tbo(it, $completion) {
    this.kqe_1.kbj(0.5);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class Scene2$sceneMain$lambda$slambda_0 {
  constructor($handler, $it) {
    this.hqe_1 = $handler;
    this.iqe_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_31(this, $completion), $completion);
  }
}
class Scene2 extends ShowScene {
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl_5(_this__u8e3s4, this, $completion), $completion);
  }
}
class GameInfo {
  constructor(score, hiScore) {
    score = score === VOID ? 0 : score;
    hiScore = hiScore === VOID ? 0 : hiScore;
    this.wqe_1 = score;
    this.xqe_1 = hiScore;
  }
  yqe(increment) {
    var newScore = this.wqe_1 + increment | 0;
    // Inline function 'kotlin.comparisons.maxOf' call
    var a = this.xqe_1;
    var tmp$ret$0 = Math.max(a, newScore);
    return new GameInfo(newScore, tmp$ret$0);
  }
  zqe(score, hiScore) {
    return new GameInfo(score, hiScore);
  }
  aqf(score, hiScore, $super) {
    score = score === VOID ? this.wqe_1 : score;
    hiScore = hiScore === VOID ? this.xqe_1 : hiScore;
    return $super === VOID ? this.zqe(score, hiScore) : $super.zqe.call(this, score, hiScore);
  }
  toString() {
    return 'GameInfo(score=' + this.wqe_1 + ', hiScore=' + this.xqe_1 + ')';
  }
  hashCode() {
    var result = this.wqe_1;
    result = imul(result, 31) + this.xqe_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof GameInfo))
      return false;
    var tmp0_other_with_cast = other instanceof GameInfo ? other : THROW_CCE();
    if (!(this.wqe_1 === tmp0_other_with_cast.wqe_1))
      return false;
    if (!(this.xqe_1 === tmp0_other_with_cast.xqe_1))
      return false;
    return true;
  }
}
class Snake {
  constructor(list, length) {
    this.bqf_1 = list;
    this.cqf_1 = length;
  }
  dqf() {
    return last(this.bqf_1);
  }
  eqf(dir) {
    return new Snake(takeLast(plus_0(this.bqf_1, last(this.bqf_1).f2y(dir.hqf_1)), this.cqf_1), this.cqf_1);
  }
  jqf(map, intMap) {
    var iterator = this.bqf_1.f1();
    var index = 0;
    while (iterator.g1()) {
      var index_0 = index;
      index = index + 1 | 0;
      var point = iterator.h1();
      intMap.q2y(point, get_EMPTY());
      if (map.v2y(point.s2u_1, point.t2u_1)) {
        while (map.y3a(point.s2u_1, point.t2u_1) > 0) {
          map.z3a(point.s2u_1, point.t2u_1);
        }
      }
    }
  }
  kqf(map, intMap) {
    var _iterator__ex2g4s = this.bqf_1.f1();
    while (_iterator__ex2g4s.g1()) {
      var point = _iterator__ex2g4s.h1();
      intMap.q2y(point, get_SNAKE());
    }
    var iterator = this.bqf_1.f1();
    var index = 0;
    while (iterator.g1()) {
      var index_0 = index;
      index = index + 1 | 0;
      var point_0 = iterator.h1();
      var isHead = index_0 === (this.bqf_1.j1() - 1 | 0);
      // Inline function 'kotlin.collections.getOrElse' call
      var this_0 = this.bqf_1;
      var index_1 = index_0 - 1 | 0;
      // Inline function 'kotlin.contracts.contract' call
      var tmp;
      if (0 <= index_1 ? index_1 < this_0.j1() : false) {
        tmp = this_0.i1(index_1);
      } else {
        // Inline function 'snake.model.Snake.render.<anonymous>' call
        tmp = point_0;
      }
      var prevPoint = tmp;
      // Inline function 'kotlin.collections.getOrElse' call
      var this_1 = this.bqf_1;
      var index_2 = index_0 + 1 | 0;
      // Inline function 'kotlin.contracts.contract' call
      var tmp_0;
      if (0 <= index_2 ? index_2 < this_1.j1() : false) {
        tmp_0 = this_1.i1(index_2);
      } else {
        // Inline function 'snake.model.Snake.render.<anonymous>' call
        tmp_0 = point_0;
      }
      var nextPoint = tmp_0;
      var dir = Companion_instance_7.lqf(prevPoint, point_0);
      var ndir = Companion_instance_7.lqf(point_0, nextPoint);
      var tile = isHead ? SnakeHeadProvider_getInstance().vpx(new SimpleTileSpec(dir.equals(SnakeDirection_LEFT_getInstance()), dir.equals(SnakeDirection_UP_getInstance()), dir.equals(SnakeDirection_RIGHT_getInstance()), dir.equals(SnakeDirection_DOWN_getInstance()))) : SnakeProvider_getInstance().vpx(new SimpleTileSpec(dir.equals(SnakeDirection_RIGHT_getInstance()) || ndir.equals(SnakeDirection_LEFT_getInstance()), dir.equals(SnakeDirection_DOWN_getInstance()) || ndir.equals(SnakeDirection_UP_getInstance()), dir.equals(SnakeDirection_LEFT_getInstance()) || ndir.equals(SnakeDirection_RIGHT_getInstance()), dir.equals(SnakeDirection_UP_getInstance()) || ndir.equals(SnakeDirection_DOWN_getInstance())));
      map.f71(point_0.s2u_1, point_0.t2u_1, tile);
    }
  }
  oqf(list, length) {
    return new Snake(list, length);
  }
  pqf(list, length, $super) {
    list = list === VOID ? this.bqf_1 : list;
    length = length === VOID ? this.cqf_1 : length;
    return $super === VOID ? this.oqf(list, length) : $super.oqf.call(this, list, length);
  }
  toString() {
    return 'Snake(list=' + toString(this.bqf_1) + ', length=' + this.cqf_1 + ')';
  }
  hashCode() {
    var result = hashCode(this.bqf_1);
    result = imul(result, 31) + this.cqf_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Snake))
      return false;
    var tmp0_other_with_cast = other instanceof Snake ? other : THROW_CCE();
    if (!equals(this.bqf_1, tmp0_other_with_cast.bqf_1))
      return false;
    if (!(this.cqf_1 === tmp0_other_with_cast.cqf_1))
      return false;
    return true;
  }
}
class Companion_0 {
  lqf(old, new_0) {
    return new_0.s2u_1 > old.s2u_1 ? SnakeDirection_RIGHT_getInstance() : new_0.s2u_1 < old.s2u_1 ? SnakeDirection_LEFT_getInstance() : new_0.t2u_1 > old.t2u_1 ? SnakeDirection_DOWN_getInstance() : new_0.t2u_1 < old.t2u_1 ? SnakeDirection_UP_getInstance() : SnakeDirection_NONE_getInstance();
  }
}
class SnakeDirection extends Enum {
  constructor(name, ordinal, delta, isHorizontal) {
    super(name, ordinal);
    this.hqf_1 = delta;
    this.iqf_1 = isHorizontal;
  }
}
class AppleProvider {
  constructor() {
    AppleProvider_instance = this;
    var tmp = this;
    // Inline function 'kotlin.also' call
    var this_0 = new SimpleTileProvider(APPLE);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'snake.model.AppleProvider.$$delegate_0.<anonymous>' call
    this_0.upx(SimpleRule.bpy(Tile(12)));
    tmp.qqf_1 = this_0;
  }
  wpx(ints, x, y) {
    return this.qqf_1.wpx(ints, x, y);
  }
  npx() {
    return this.qqf_1.lpx_1;
  }
}
class WallProvider {
  constructor() {
    WallProvider_instance = this;
    var tmp = this;
    // Inline function 'kotlin.also' call
    var this_0 = new SimpleTileProvider(WALL);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'snake.model.WallProvider.$$delegate_0.<anonymous>' call
    this_0.upx(SimpleRule.bpy(Tile(16)));
    this_0.upx(SimpleRule.bpy(Tile(17), VOID, VOID, true));
    this_0.upx(SimpleRule.bpy(Tile(18), true, VOID, true));
    this_0.upx(SimpleRule.bpy(Tile(19), true, VOID, VOID, true));
    this_0.upx(SimpleRule.bpy(Tile(20), true, true, VOID, true));
    var tmp65_tile = Tile(21);
    this_0.upx(SimpleRule.bpy(tmp65_tile, true, true, true, true));
    tmp.rqf_1 = this_0;
  }
  wpx(ints, x, y) {
    return this.rqf_1.wpx(ints, x, y);
  }
  npx() {
    return this.rqf_1.lpx_1;
  }
}
class SnakeProvider {
  constructor() {
    SnakeProvider_instance = this;
    var tmp = this;
    // Inline function 'kotlin.also' call
    var this_0 = new SimpleTileProvider(SNAKE);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'snake.model.SnakeProvider.$$delegate_0.<anonymous>' call
    this_0.upx(SimpleRule.bpy(Tile(0)));
    this_0.upx(SimpleRule.bpy(Tile(1), VOID, VOID, true));
    this_0.upx(SimpleRule.bpy(Tile(2), true, VOID, true));
    this_0.upx(SimpleRule.bpy(Tile(3), true, VOID, VOID, true));
    tmp.mqf_1 = this_0;
  }
  vpx(spec) {
    return this.mqf_1.vpx(spec);
  }
  wpx(ints, x, y) {
    return this.mqf_1.wpx(ints, x, y);
  }
  npx() {
    return this.mqf_1.lpx_1;
  }
}
class SnakeHeadProvider {
  constructor() {
    SnakeHeadProvider_instance = this;
    var tmp = this;
    // Inline function 'kotlin.also' call
    var this_0 = new SimpleTileProvider(SNAKE);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'snake.model.SnakeHeadProvider.$$delegate_0.<anonymous>' call
    this_0.upx(SimpleRule.bpy(Tile(0)));
    this_0.upx(SimpleRule.bpy(Tile(4), VOID, VOID, true));
    tmp.nqf_1 = this_0;
  }
  vpx(spec) {
    return this.nqf_1.vpx(spec);
  }
  wpx(ints, x, y) {
    return this.nqf_1.wpx(ints, x, y);
  }
  npx() {
    return this.nqf_1.lpx_1;
  }
}
class Companion_1 {}
class GameInfoUpdatedEvent extends TypedEvent {
  constructor(gameInfo) {
    super(Companion_instance_8);
    this.vqf_1 = gameInfo;
  }
}
class Companion_2 {}
class GameStartEvent extends TypedEvent {
  constructor() {
    super(Companion_instance_9);
  }
}
class IngameScene$ingame$ref {
  constructor($boundThis) {
    this.wqf_1 = $boundThis;
  }
  xqf(p0, $completion) {
    return this.wqf_1.aqh(p0, $completion);
  }
  qe(p1, $completion) {
    return this.xqf(p1 instanceof Container ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$ingame$ref_0 {
  constructor($boundThis) {
    this.iqh_1 = $boundThis;
  }
  xqf(p0, $completion) {
    return this.iqh_1.aqh(p0, $completion);
  }
  qe(p1, $completion) {
    return this.xqf(p1 instanceof Container ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$ingame$slambda {
  constructor($direction) {
    this.kqh_1 = $direction;
  }
  sbl(it, $completion) {
    this.kqh_1._v = SnakeDirection_UP_getInstance();
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$ingame$slambda_0 {
  constructor($direction) {
    this.lqh_1 = $direction;
  }
  sbl(it, $completion) {
    this.lqh_1._v = SnakeDirection_DOWN_getInstance();
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$ingame$slambda_1 {
  constructor($direction) {
    this.mqh_1 = $direction;
  }
  sbl(it, $completion) {
    this.mqh_1._v = SnakeDirection_LEFT_getInstance();
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$ingame$slambda_2 {
  constructor($direction) {
    this.nqh_1 = $direction;
  }
  sbl(it, $completion) {
    this.nqh_1._v = SnakeDirection_RIGHT_getInstance();
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$ingame$slambda_3 {
  constructor($view) {
    this.oqh_1 = $view;
  }
  sbl(it, $completion) {
    this.oqh_1.xb9_1 = this.oqh_1.xb9_1 === 0.0 ? 1.0 : 0.0;
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$gameOver$slambda {
  constructor(this$0) {
    this.pqh_1 = this$0;
  }
  sbl(it, $completion) {
    this.pqh_1.qqh(IngameScene$ingame$ref_1(this.pqh_1));
  }
  qe(p1, $completion) {
    return this.sbl(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  }
}
class IngameScene$gameOver$ref {
  constructor($boundThis) {
    this.rqh_1 = $boundThis;
  }
  xqf(p0, $completion) {
    return this.rqh_1.sqh(p0, $completion);
  }
  qe(p1, $completion) {
    return this.xqf(p1 instanceof Container ? p1 : THROW_CCE(), $completion);
  }
}
class StateScene {}
function runStates(_this__u8e3s4, startingFunc, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_runStates__uxyoex_0(_this__u8e3s4, this, startingFunc, $completion), $completion);
}
function change(func) {
  this.tqh(func);
}
function frame(_this__u8e3s4, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_frame__oz9fc7_0(_this__u8e3s4, this, $completion), $completion);
}
class IngameScene extends PixelatedScene {
  constructor() {
    super(512, 512, VOID, VOID, true);
    this.nqg_1 = new Mixin_0();
    var tmp = this;
    tmp.pqg_1 = lazy(IngameScene$tiles$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.qqg_1 = lazy(IngameScene$tileSet$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.rqg_1 = lazy(IngameScene$tileMap$delegate$lambda(this));
    var tmp_2 = this;
    tmp_2.sqg_1 = lazy(IngameScene$tileMapRules$delegate$lambda);
    var tmp_3 = this;
    tmp_3.tqg_1 = lazy(IngameScene$intMap$delegate$lambda(this));
    this.uqg_1 = false;
    this.vqg_1 = new GameInfo(0);
    this.wqg_1 = new Snake(listOf([Vector2I.v2u(5, 5), Vector2I.v2u(6, 5)]), 4);
    var tmp_4 = this;
    tmp_4.xqg_1 = new Observable(this.vqg_1, VOID, IngameScene$gameInfo$delegate$lambda(this));
    this.yqg_1 = this.wqg_1;
    this.zqg_1 = Random_0(0);
  }
  bqh(_this__u8e3s4, startingFunc, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_runStates__uxyoex(_this__u8e3s4, this, startingFunc, $completion), $completion);
  }
  qqh(func) {
    this.nqg_1.qqh(func);
  }
  cqh(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_frame__oz9fc7(_this__u8e3s4, this, $completion), $completion);
  }
  tqh(_set____db54di) {
    this.nqg_1.uqh_1 = _set____db54di;
  }
  vqh() {
    return this.nqg_1.uqh_1;
  }
  eqh() {
    var tmp = this.oqg_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('tilesBmp');
    }
  }
  fqh() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.pqg_1;
    tiles$factory();
    return this_0.b2();
  }
  gqh() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.qqg_1;
    tileSet$factory();
    return this_0.b2();
  }
  dqh() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.rqg_1;
    tileMap$factory();
    return this_0.b2();
  }
  hqh() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.sqg_1;
    tileMapRules$factory();
    return this_0.b2();
  }
  wqh() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.tqg_1;
    intMap$factory();
    return this_0.b2();
  }
  xqh(_set____db54di) {
    return this.xqg_1.i3e(this, gameInfo$factory(), _set____db54di);
  }
  yqh() {
    return this.xqg_1.h3e(this, gameInfo$factory_0());
  }
  lbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneInit__5zuhe0(_this__u8e3s4, this, $completion), $completion);
  }
  mbz(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_sceneMain__5xvlzl_6(_this__u8e3s4, this, $completion), $completion);
  }
  aqh(view, $completion) {
    this.wqh().r2y(RectangleInt.d2u(0, 0, this.wqh().j2y(), this.wqh().k2y()), get_EMPTY());
    this.wqh().r2y(RectangleInt.d2u(0, 0, this.wqh().j2y(), 1), get_WALL());
    this.wqh().r2y(RectangleInt.d2u(0, this.wqh().k2y() - 1 | 0, this.wqh().j2y(), 1), get_WALL());
    this.wqh().r2y(RectangleInt.d2u(0, 0, 1, this.wqh().k2y()), get_WALL());
    this.wqh().r2y(RectangleInt.d2u(this.wqh().j2y() - 1 | 0, 0, 1, this.wqh().k2y()), get_WALL());
    this.wqh().r2y(RectangleInt.d2u(8, 8, 3, 3), get_WALL());
    this.wqh().r2y(RectangleInt.d2u(16, 20, 4, 10), get_WALL());
    this.uqg_1 = false;
    this.xqh(this.yqh().aqf(0));
    this.yqg_1 = this.wqg_1;
    this.zqh();
    this.aqi();
    this.ybw().v8a(new GameStartEvent());
    this.wqh().p2y(4, 3, get_WALL());
    this.wqh().p2y(4, 4, get_WALL());
    this.wqh().p2y(3, 4, get_WALL());
    this.wqh().p2y(4, 5, get_WALL());
    var direction = {_v: SnakeDirection_RIGHT_getInstance()};
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance();
    var tmp = toDuration(0.1, DurationUnit_SECONDS_getInstance());
    interval(view, tmp, IngameScene$ingame$lambda(this, direction));
    // Inline function 'korlibs.korge.input.keys' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'snake.scene.IngameScene.ingame.<anonymous>' call
    var $this$keys = get_keys(view);
    var tmp_0 = Key_UP_getInstance();
    $this$keys.ybl(tmp_0, IngameScene$ingame$slambda_4(direction));
    var tmp_1 = Key_DOWN_getInstance();
    $this$keys.ybl(tmp_1, IngameScene$ingame$slambda_5(direction));
    var tmp_2 = Key_LEFT_getInstance();
    $this$keys.ybl(tmp_2, IngameScene$ingame$slambda_6(direction));
    var tmp_3 = Key_RIGHT_getInstance();
    $this$keys.ybl(tmp_3, IngameScene$ingame$slambda_7(direction));
    var tmp_4 = Key_SPACE_getInstance();
    $this$keys.ybl(tmp_4, IngameScene$ingame$slambda_8(view));
  }
  sqh(view, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_gameOver__jcjkm6(this, view, $completion), $completion);
  }
  aqi() {
    this.yqg_1.kqf(this.dqh().ohq_1, this.wqh());
  }
  zqh() {
    while (true) {
      // Inline function 'korlibs.math.geom.Companion.invoke' call
      Companion_getInstance_13();
      var width = this.wqh().j2y() - 0.5;
      var height = this.wqh().k2y() - 0.5;
      var tmp = Companion_getInstance_13();
      // Inline function 'korlibs.math.geom.Companion.invoke' call
      Companion_getInstance_15();
      var tmp_0 = Vector2D.k2h(numberToDouble(0), numberToDouble(0));
      // Inline function 'korlibs.math.geom.Companion.invoke' call
      Companion_getInstance_18();
      var tmp$ret$1 = Size2D.k2t(numberToDouble(width), numberToDouble(height));
      var tmp$ret$2 = tmp.g2j(tmp_0, tmp$ret$1);
      var point = get_0(this.zqg_1, tmp$ret$2);
      var ipoint = toInt(point);
      if (this.wqh().c2z(ipoint) === get_EMPTY()) {
        this.wqh().q2y(ipoint, get_APPLE());
        return Unit_instance;
      }
    }
  }
  jqh(dir) {
    var oldSnake = this.yqg_1;
    this.yqg_1 = this.yqg_1.eqf(dir);
    var headValue = this.wqh().c2z(this.yqg_1.dqf());
    var isApple = headValue === get_APPLE();
    if (isApple) {
      this.yqg_1 = this.yqg_1.pqf(VOID, this.yqg_1.cqf_1 + 1 | 0);
      this.xqh(this.yqh().yqe(1));
    } else if (!(headValue === get_EMPTY())) {
      this.qqh(IngameScene$gameOver$ref_0(this));
      return Unit_instance;
    }
    oldSnake.jqf(this.dqh().ohq_1, this.wqh());
    this.yqg_1.kqf(this.dqh().ohq_1, this.wqh());
    if (isApple) {
      this.zqh();
    }
  }
}
class OverlayScene extends Scene {
  mbz(_this__u8e3s4, $completion) {
    // Inline function 'korlibs.korge.view.xy' call
    // Inline function 'korlibs.korge.view.text' call
    var textSize = Companion_instance_0.rfm_1;
    var color = Colors_getInstance().g4r_1;
    var font = get_DefaultTtfFontAsBitmap();
    var alignment = Companion_getInstance_2().h5s_1;
    var renderer = get_DefaultStringTextRenderer();
    var autoScaling = Companion_instance_0.sfm_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = Text.tfm('', numberToDouble(textSize), color, font, alignment, renderer, autoScaling, null, null);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    var scoreText = xy(this_1, numberToDouble(8), numberToDouble(8));
    var current = {_v: new GameInfo()};
    var hiScoreBeatShown = {_v: false};
    var tmp = Companion_instance_9;
    _this__u8e3s4.k8a(tmp, OverlayScene$sceneMain$lambda(hiScoreBeatShown));
    var tmp_0 = Companion_instance_8;
    _this__u8e3s4.k8a(tmp_0, OverlayScene$sceneMain$lambda_0(current, scoreText, hiScoreBeatShown, this));
  }
}
class SnakeScene extends AutoShowScene {
  hq0(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_main__qlaff5_2(_this__u8e3s4, this, $completion), $completion);
  }
}
class Mixin_0 {
  constructor() {
    this.uqh_1 = null;
  }
  tqh(_set____db54di) {
    this.uqh_1 = _set____db54di;
  }
  vqh() {
    return this.uqh_1;
  }
}
class ChangeSceneException extends Error {
  static yqi(func) {
    var $this = createThis(this);
    setPropertiesToThrowableInstance($this);
    captureStack($this, $this.xqi_1);
    $this.wqi_1 = func;
    return $this;
  }
}
class SpineScene extends AutoShowScene {
  hq0(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_main__qlaff5_3(_this__u8e3s4, this, $completion), $completion);
  }
}
class SWFScene$main$lambda$slambda {
  constructor(this$0, $extraSwfContainer, $this_main) {
    this.kqj_1 = this$0;
    this.lqj_1 = $extraSwfContainer;
    this.mqj_1 = $this_main;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_32(this, $completion), $completion);
  }
}
class SWFScene$main$loadSwf$slambda {
  constructor($extraSwfContainer, $files, this$0, $this_main) {
    this.nqj_1 = $extraSwfContainer;
    this.oqj_1 = $files;
    this.pqj_1 = this$0;
    this.qqj_1 = $this_main;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_33(this, $completion), $completion);
  }
}
class SWFScene extends AutoShowScene {
  constructor() {
    super();
    this.cqk_1 = ShapeRasterizerMethod_NONE_getInstance();
    this.dqk_1 = GraphicsRenderer_SYSTEM_getInstance();
    this.eqk_1 = new SWFExportConfig(VOID, VOID, VOID, this.cqk_1, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, false, this.dqk_1);
  }
  hq0(_this__u8e3s4, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_main__qlaff5_4(_this__u8e3s4, this, $completion), $completion);
  }
}
class DockingComponent {
  constructor(view, anchor, scaleMode, offset, hook) {
    scaleMode = scaleMode === VOID ? Companion_getInstance_11().p2v_1 : scaleMode;
    offset = offset === VOID ? Vector2D.i2l() : offset;
    this.fqk_1 = view;
    this.gqk_1 = anchor;
    this.hqk_1 = scaleMode;
    this.iqk_1 = offset;
    this.jqk_1 = hook;
    this.kqk_1 = Size2D.k2t(this.fqk_1.j2y(), this.fqk_1.k2y());
    this.lqk_1 = Size2D.f2j(0, 0);
    this.mqk_1 = Size2D.f2j(0, 0);
    onStageResized(this.fqk_1, VOID, DockingComponent$lambda(this));
  }
}
class SimpleUIScene$sceneMain$slambda {
  constructor(this$0, $container) {
    this.rqk_1 = this$0;
    this.sqk_1 = $container;
  }
  tbo(it, $completion) {
    this.rqk_1.gql(this.sqk_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class SimpleUIScene$sceneMain$lambda$slambda {
  constructor($handler, $it) {
    this.nqk_1 = $handler;
    this.oqk_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_34(this, $completion), $completion);
  }
}
class SimpleUIScene$sceneMain$slambda_0 {
  constructor(this$0, $container) {
    this.hql_1 = this$0;
    this.iql_1 = $container;
  }
  tbo(it, $completion) {
    this.hql_1.jql(this.iql_1);
  }
  qe(p1, $completion) {
    return this.tbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  }
}
class SimpleUIScene$sceneMain$lambda$slambda_0 {
  constructor($handler, $it) {
    this.pqk_1 = $handler;
    this.qqk_1 = $it;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_35(this, $completion), $completion);
  }
}
class SimpleUIScene$openLazyLongListWindow$window$1$1 {
  constructor() {
    this.kql_1 = 1000;
    this.lql_1 = 20.0;
  }
  pdx() {
    return this.kql_1;
  }
  qdx() {
    return this.lql_1;
  }
  tdt(index) {
    return this.lql_1;
  }
  rdx(index, vlist) {
    return UIText.lf0(' HELLO WORLD ' + index);
  }
}
class SimpleUIScene extends ShowScene {
  p5w() {
    var tmp = this.eql_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('font');
    }
  }
  mql() {
    var tmp = this.fql_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('solidRect');
    }
  }
  mbz(_this__u8e3s4, $completion) {
    var container = _this__u8e3s4;
    var tmp = this;
    // Inline function 'korlibs.image.font.toBitmapFont' call
    var this_0 = get_DefaultTtfFont();
    var chars = Companion_getInstance_1().p5o_1;
    var fontName = this_0.w3();
    var paint = new RGBA(Colors_getInstance().g4r_1);
    tmp.eql_1 = Companion_instance.l5k(this_0, numberToDouble(16.0), chars, fontName, paint, true, null);
    var tmp_0 = this;
    // Inline function 'korlibs.korge.view.solidRect' call
    var color = Colors_getInstance().r59('#700ec7');
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = SolidRect.ddj(Size2D.f2j(100, 100), color);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp_0.fql_1 = get_centered(position(this_2, 200.0, 100.0));
    // Inline function 'korlibs.korge.ui.uiVerticalStack' call
    // Inline function 'kotlin.apply' call
    var this_3 = addTo(UIVerticalStack.eee(150.0, 0.0, true), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'ui.SimpleUIScene.sceneMain.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiButton' call
    var label = 'Open Window List';
    var size = Companion_getInstance_4().fcs_1;
    // Inline function 'kotlin.apply' call
    var this_4 = addTo(UIButton.sd2(size, label, null), this_3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'ui.SimpleUIScene.sceneMain.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.korge.input.onClick' call
    var handler = SimpleUIScene$sceneMain$slambda_1(this, container);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop = click$factory_16();
    var tmp73_safe_receiver = this_4 == null ? null : get_mouse(this_4);
    if (tmp73_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_1 = prop.get(tmp73_safe_receiver);
      tmp_1.v1x(SimpleUIScene$sceneMain$lambda(tmp73_safe_receiver, handler));
    }
    // Inline function 'korlibs.korge.ui.uiButton' call
    var label_0 = 'Open Properties';
    var size_0 = Companion_getInstance_4().fcs_1;
    // Inline function 'kotlin.apply' call
    var this_5 = addTo(UIButton.sd2(size_0, label_0, null), this_3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'ui.SimpleUIScene.sceneMain.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.korge.input.onClick' call
    var handler_0 = SimpleUIScene$sceneMain$slambda_2(this, container);
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var prop_0 = click$factory_17();
    var tmp73_safe_receiver_0 = this_5 == null ? null : get_mouse(this_5);
    if (tmp73_safe_receiver_0 == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_2 = prop_0.get(tmp73_safe_receiver_0);
      tmp_2.v1x(SimpleUIScene$sceneMain$lambda_0(tmp73_safe_receiver_0, handler_0));
    }
    position(this_3, 0.0, 32.0);
    // Inline function 'korlibs.korge.ui.uiWindow' call
    var title = 'Scrollable';
    var size_1 = Size2D.f2j(256, 256);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.apply' call
    var this_6 = addTo(UIWindow.ig5(title, size_1), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiWindow.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiWindow.<anonymous>' call
    // Inline function 'ui.SimpleUIScene.sceneMain.<anonymous>' call
    var $this$uiWindow = this_6.xg4_1.qdw_1;
    var inductionVariable = 0;
    if (inductionVariable < 10)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.korge.view.position' call
        // Inline function 'korlibs.korge.ui.uiButton' call
        var label_1 = 'Hello ' + n;
        var size_2 = Companion_getInstance_4().fcs_1;
        // Inline function 'kotlin.apply' call
        var this_7 = addTo(UIButton.sd2(size_2, label_1, null), $this$uiWindow);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
        var x = imul(n, 32);
        var y = imul(n, 32);
        xy(this_7, numberToDouble(x), numberToDouble(y));
      }
       while (inductionVariable < 10);
    centerOnStage(this_6);
  }
  gql(_this__u8e3s4) {
    // Inline function 'korlibs.korge.ui.uiWindow' call
    var title = 'Lazy long list';
    var size = Size2D.f2j(256, 256);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.apply' call
    var this_0 = addTo(UIWindow.ig5(title, size), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiWindow.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiWindow.<anonymous>' call
    // Inline function 'ui.SimpleUIScene.openLazyLongListWindow.<anonymous>' call
    var $this$uiWindow = this_0.xg4_1.qdw_1;
    set_textFont(get_styles($this$uiWindow), this.p5w());
    // Inline function 'korlibs.korge.ui.uiVerticalList' call
    var provider = new SimpleUIScene$openLazyLongListWindow$window$1$1();
    // Inline function 'kotlin.also' call
    addTo(UIVerticalList.le1(provider, numberToDouble(256.0)), $this$uiWindow);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiVerticalList.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiVerticalList.<anonymous>' call
    var window_0 = centerOnStage(this_0);
    // Inline function 'korlibs.korge.tween.get' call
    var this_1 = x$factory_0(window_0);
    var end = window_0.n4f();
    var tmp = new V2(this_1, 0.0, end, _interpolate$ref_6(), true);
    // Inline function 'korlibs.korge.tween.get' call
    var this_2 = y$factory(window_0);
    var end_0 = window_0.p4f();
    var tmp_0 = new V2(this_2, 0.0, end_0, _interpolate$ref_7(), true);
    // Inline function 'korlibs.korge.tween.get' call
    var this_3 = width$factory(window_0);
    var end_1 = window_0.j2y();
    var tmp_1 = new V2(this_3, 0.0, end_1, _interpolate$ref_8(), true);
    // Inline function 'korlibs.korge.tween.get' call
    var this_4 = height$factory(window_0);
    var end_2 = window_0.k2y();
    var tmp_2 = new V2(this_4, 0.0, end_2, _interpolate$ref_9(), true);
    // Inline function 'korlibs.korge.tween.get' call
    var this_5 = alpha$factory_2(window_0);
    var tmp_3 = [tmp, tmp_0, tmp_1, tmp_2, new V2(this_5, 0.3, 1.0, _interpolate$ref_10(), true)];
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$12 = toDuration(0.3, DurationUnit_SECONDS_getInstance());
    tweenNoWait(_this__u8e3s4, tmp_3, tmp$ret$12);
  }
  jql(_this__u8e3s4) {
    // Inline function 'korlibs.korge.ui.uiWindow' call
    var title = 'Properties';
    var size = Size2D.f2j(400, 300);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.apply' call
    var this_0 = addTo(UIWindow.ig5(title, size), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiWindow.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiWindow.<anonymous>' call
    // Inline function 'ui.SimpleUIScene.openPropertiesWindow.<anonymous>' call
    var $this$uiWindow = this_0.xg4_1.qdw_1;
    this_0.xg4_1.gdx_1 = false;
    this_0.xg4_1.zdw_1 = 0.0;
    // Inline function 'korlibs.korge.ui.uiVerticalStack' call
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(UIVerticalStack.eee(300.0, 0.0, true), $this$uiWindow);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'ui.SimpleUIScene.openPropertiesWindow.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiText' call
    var text = 'Properties';
    var size_0 = Companion_getInstance_9().vfd_1;
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(UIText.lf0(text, size_0), this_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiText.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'ui.SimpleUIScene.openPropertiesWindow.<anonymous>.<anonymous>.<anonymous>' call
    set_textColor(get_styles(this_2), Colors_getInstance().i4r_1);
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow' call
    var propsList = UIEditableNumberPropsList([alpha$factory_3(this.mql())]).slice();
    var size_1 = Size2D.f2j(128, 20);
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.korge.view.append' call
    var view = UIPropertyNumberRow.vfa('Alpha', propsList.slice(), size_1);
    this_1.oca(view);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow' call
    var title_0 = 'Position';
    var propsList_0 = UIEditableNumberPropsList([x$factory_1(this.mql()), y$factory_0(this.mql())], -1024.0, +1024.0, VOID, false).slice();
    var size_2 = Size2D.f2j(128, 20);
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.korge.view.append' call
    var view_0 = UIPropertyNumberRow.vfa(title_0, propsList_0.slice(), size_2);
    this_1.oca(view_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow' call
    var propsList_1 = UIEditableNumberPropsList([width$factory_0(this.mql()), height$factory_0(this.mql())], -1024.0, +1024.0, VOID, false).slice();
    var size_3 = Size2D.f2j(128, 20);
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.korge.view.append' call
    var view_1 = UIPropertyNumberRow.vfa('Size', propsList_1.slice(), size_3);
    this_1.oca(view_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow' call
    var propsList_2 = UIEditableNumberPropsList([scaleX$factory(this.mql()), scaleY$factory(this.mql())], -1.0, +1.0, VOID, false).slice();
    var size_4 = Size2D.f2j(128, 20);
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.korge.view.append' call
    var view_2 = UIPropertyNumberRow.vfa('Scale', propsList_2.slice(), size_4);
    this_1.oca(view_2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow' call
    var title_1 = 'Rotation';
    var propsList_3 = UIEditableNumberPropsList([rotationDeg$factory(this.mql())], -360.0, +360.0, VOID, true).slice();
    var size_5 = Size2D.f2j(128, 20);
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.korge.view.append' call
    var view_3 = UIPropertyNumberRow.vfa(title_1, propsList_3.slice(), size_5);
    this_1.oca(view_3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow.<anonymous>' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow' call
    var propsList_4 = UIEditableNumberPropsList([skewXDeg$factory(this.mql()), skewYDeg$factory(this.mql())], -360.0, +360.0, VOID, true).slice();
    var size_6 = Size2D.f2j(128, 20);
    // Inline function 'kotlin.apply' call
    // Inline function 'korlibs.korge.view.append' call
    var view_4 = UIPropertyNumberRow.vfa('Skew', propsList_4.slice(), size_6);
    this_1.oca(view_4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiPropertyNumberRow.<anonymous>' call
    var skewProp = view_4;
    // Inline function 'korlibs.korge.view.append' call
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.append' call
    var view_5 = UIPropertyRow.tfd('Visible');
    this_1.oca(view_5);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'ui.SimpleUIScene.openPropertiesWindow.<anonymous>.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.korge.view.append' call
    var this_3 = view_5.ufd();
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiCheckBox' call
    var checked = this.mql().qcc();
    var size_7 = get_UI_DEFAULT_SIZE();
    // Inline function 'kotlin.apply' call
    var this_4 = addTo(UICheckBox.sdm(size_7, checked, ''), view_5);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiCheckBox.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'ui.SimpleUIScene.openPropertiesWindow.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    this_4.kdd_1.w1x(SimpleUIScene$openPropertiesWindow$lambda(this));
    this_3.oca(this_4);
    centerOnStage(this_0);
  }
}
class ext$o$init$lambda$slambda {
  constructor($sceneInfo, $editor) {
    this.nql_1 = $sceneInfo;
    this.oql_1 = $editor;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_36(this, $completion), $completion);
  }
}
class ext$1 extends Ext {
  constructor() {
    super();
    var tmp = this;
    tmp.pql_1 = lazy(ext$o$canvasQuery$delegate$lambda);
  }
  qql() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.pql_1;
    canvasQuery$factory();
    return this_0.b2();
  }
  tqa() {
    // Inline function 'kotlin.js.asDynamic' call
    var dwindow = window;
    if (!(this.qql() == null)) {
      dwindow.korgwCanvasQuery = '#mycustomcanvas';
    }
  }
  uqa(stage) {
    var registeredScenes = get_registeredScenes(stage);
    var sceneTree = document.querySelector('#scene_tree');
    if (!(sceneTree == null)) {
      sceneTree.textContent = '';
      // Inline function 'kotlin.collections.iterator' call
      // Inline function 'kotlin.collections.groupBy' call
      // Inline function 'kotlin.collections.groupByTo' call
      var this_0 = registeredScenes.m3();
      var destination = LinkedHashMap.vc();
      var tmp0_iterator = this_0.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'extension.<no name provided>.init.<anonymous>' call
        var key = element.zqa_1;
        // Inline function 'kotlin.collections.getOrPut' call
        var value = destination.k3(key);
        var tmp;
        if (value == null) {
          // Inline function 'kotlin.collections.groupByTo.<anonymous>' call
          var answer = ArrayList.m1();
          destination.r3(key, answer);
          tmp = answer;
        } else {
          tmp = value;
        }
        var list = tmp;
        list.f(element);
      }
      var _iterator__ex2g4s = destination.z1().f1();
      while (_iterator__ex2g4s.g1()) {
        var entry = _iterator__ex2g4s.h1();
        var group = entry.a2();
        var groupDiv = document.createElement('h2');
        var groupDivTree = document.createElement('div');
        groupDiv.textContent = group;
        sceneTree.appendChild(groupDiv);
        sceneTree.appendChild(groupDivTree);
        var _iterator__ex2g4s_0 = entry.b2().f1();
        while (_iterator__ex2g4s_0.g1()) {
          var scene = _iterator__ex2g4s_0.h1();
          var className = scene.dqb();
          var title = scene.yqa_1;
          var path = scene.aqb_1;
          // Inline function 'kotlin.js.unsafeCast' call
          // Inline function 'kotlin.js.asDynamic' call
          var elementNode = document.createElement('a');
          elementNode.id = 'scene-' + className;
          elementNode.textContent = title;
          elementNode.href = '#' + className;
          groupDivTree.appendChild(elementNode);
        }
      }
    }
    var editor$delegate = lazy(ext$o$init$lambda);
    this.vqa('changedScene', ext$o$init$lambda_0(registeredScenes, stage, editor$delegate));
  }
  vqa(event, handler) {
    var tmp = window;
    tmp.addEventListener(event, ext$o$registerEvent$lambda(handler));
  }
  wqa(event, detail) {
    var tmp = window;
    // Inline function 'org.w3c.dom.CustomEventInit' call
    var o = {};
    o['detail'] = detail;
    o['bubbles'] = false;
    o['cancelable'] = false;
    o['composed'] = false;
    tmp.dispatchEvent(new CustomEvent(event, o));
  }
  xqa() {
    var tmp2_safe_receiver = document.location;
    var tmp3_safe_receiver = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.hash;
    return tmp3_safe_receiver == null ? null : trim(tmp3_safe_receiver, charArrayOf([_Char___init__impl__6a9atx(35)]));
  }
}
//endregion
function _TypedVfsFileTTF___init__impl__pvwvu0(__file) {
  return __file;
}
function _TypedVfsFileTTF___get___file__impl__1z4zrp($this) {
  return $this;
}
function TypedVfsFileTTF__read_impl_q2ozy2($this, $completion) {
  return readTtfFont(_TypedVfsFileTTF___get___file__impl__1z4zrp($this), VOID, VOID, $completion);
}
var KR_instance;
function KR_getInstance() {
  return KR_instance;
}
var KRFonts_instance;
function KRFonts_getInstance() {
  return KRFonts_instance;
}
var KRGfx_instance;
function KRGfx_getInstance() {
  return KRGfx_instance;
}
function _TypedAse___init__impl__e1aaeg(__file) {
  return __file;
}
function _TypedAse___get___file__impl__ehcig5($this) {
  return $this;
}
function *_generator_read__qih2oe($this, atlas, $completion) {
  var tmp = readImageDataContainer(_TypedAse___get___file__impl__ehcig5($this), toProps(ASE_getInstance()), atlas, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return new AseGfxWizardFAse(_AseGfxWizardFAse___init__impl__8sg2n7(tmp));
}
function TypedAse__read_impl_3z26oi($this, atlas, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe($this, atlas, $completion), $completion);
}
function _AseGfxWizardFAse___init__impl__8sg2n7(data) {
  return data;
}
function _AseGfxWizardFAse___get_data__impl__a08yes($this) {
  return $this;
}
function AseGfxWizardFAse__toString_impl_66bk1v($this) {
  return 'AseGfxWizardFAse(data=' + toString($this) + ')';
}
function AseGfxWizardFAse__hashCode_impl_fkucr0($this) {
  return hashCode($this);
}
function AseGfxWizardFAse__equals_impl_5zim34($this, other) {
  if (!(other instanceof AseGfxWizardFAse))
    return false;
  var tmp0_other_with_cast = other instanceof AseGfxWizardFAse ? other.kpy_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function *_generator_invoke__zhh2q8($this, $completion) {
  var tmp = $this.lpy_1($this.mpy_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_0($this, $completion) {
  var tmp = $this.npy_1($this.opy_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_1($this, $completion) {
  var tmp = $this.ppy_1($this.qpy_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_2($this, $completion) {
  var tmp = $this.rpy_1($this.spy_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_3($this, $completion) {
  var tmp = $this.tpy_1($this.upy_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_4($this, $completion) {
  var tmp = $this.vpy_1($this.wpy_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_5($this, $completion) {
  var tmp = $this.xpy_1($this.ypy_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_6($this, $completion) {
  var tmp = $this.zpy_1($this.apz_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_7($this, $completion) {
  var tmp = $this.bpz_1($this.cpz_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_8($this, $completion) {
  var tmp = $this.dpz_1($this.epz_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_9($this, $completion) {
  var tmp = $this.fpz_1($this.gpz_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_main__qlaff5(_this__u8e3s4, $this, $completion) {
  // Inline function 'korlibs.korge.view.solidRect' call
  var color = Colors_getInstance().i4r_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = SolidRect.ddj(Size2D.f2j(10, 10), color);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  dockedTo(anchor(this_1, Companion_getInstance_10().p2g_1), Companion_getInstance_10().p2g_1);
  // Inline function 'korlibs.korge.view.solidRect' call
  var color_0 = Colors_getInstance().j4r_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_2 = SolidRect.ddj(Size2D.f2j(10, 10), color_0);
  // Inline function 'kotlin.apply' call
  var this_3 = addTo(this_2, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  dockedTo(anchor(this_3, Companion_getInstance_10().r2g_1), Companion_getInstance_10().r2g_1);
  // Inline function 'korlibs.korge.view.solidRect' call
  var color_1 = Colors_getInstance().k4r_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_4 = SolidRect.ddj(Size2D.f2j(10, 10), color_1);
  // Inline function 'kotlin.apply' call
  var this_5 = addTo(this_4, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  dockedTo(anchor(this_5, Companion_getInstance_10().x2g_1), Companion_getInstance_10().x2g_1);
  // Inline function 'korlibs.korge.view.solidRect' call
  var color_2 = Colors_getInstance().w4v_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_6 = SolidRect.ddj(Size2D.f2j(10, 10), color_2);
  // Inline function 'kotlin.apply' call
  var this_7 = addTo(this_6, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  dockedTo(anchor(this_7, Companion_getInstance_10().v2g_1), Companion_getInstance_10().v2g_1);
  var tmp = readBitmapSlice(get_resourcesVfs().bj('korim.png'), VOID, VOID, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var korimPng = tmp;
  var tmp_0 = readBitmapSlice(get_resourcesVfs().bj('bunnys.png'), VOID, VOID, VOID, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var bunnysPng = tmp_0;
  var tmp_1 = readBitmap(get_resourcesVfs().bj('vampire.ase'), toProps(ASE_getInstance(), Companion_getInstance_0().t4m_1), $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var vampireAse = slice(tmp_1);
  // Inline function 'korlibs.korge.view.xy' call
  // Inline function 'korlibs.korge.ui.uiImage' call
  var size = Size2D.f2j(300, 170);
  var scaleMode = Companion_getInstance_11().m2v_1;
  var contentAnchor = Companion_getInstance_10().t2g_1;
  // Inline function 'kotlin.apply' call
  var this_8 = addTo(UIImage.ef4(size, korimPng, scaleMode, contentAnchor), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiImage.<anonymous>' call
  var image = xy(this_8, numberToDouble(200), numberToDouble(200));
  image.yf3_1 = Colors_getInstance().r59('#17334f');
  // Inline function 'korlibs.korge.ui.uiTooltipContainer' call
  // Inline function 'kotlin.also' call
  var this_9 = addTo(UITooltipContainer.efs(), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiTooltipContainer.<anonymous>' call
  // Inline function 'anchorscale.MainUIImageTester.main.<anonymous>' call
  var $this$uiTooltipContainer = this_9.xfr_1;
  // Inline function 'korlibs.korge.ui.uiGridFill' call
  var size_0 = Size2D.f2j(100, 100);
  var spacing = Companion_getInstance_12().d2w_1;
  var direction = UIDirection_ROW_getInstance();
  // Inline function 'kotlin.apply' call
  var this_10 = addTo(UIGridFill.te7(size_0, 3, 3, spacing, direction), $this$uiTooltipContainer);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'anchorscale.MainUIImageTester.main.<anonymous>.<anonymous>' call
  var group = new UIButtonToggleableGroup();
  var inductionVariable = 0;
  if (inductionVariable < 3)
    do {
      var y = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var inductionVariable_0 = 0;
      if (inductionVariable_0 < 3)
        do {
          var x = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          // Inline function 'korlibs.korge.ui.uiButton' call
          var size_1 = Companion_getInstance_4().fcs_1;
          // Inline function 'kotlin.apply' call
          var this_11 = addTo(UIButton.sd2(size_1, 'X', null), this_10);
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'anchorscale.MainUIImageTester.main.<anonymous>.<anonymous>.<anonymous>' call
          var anchor_0 = Anchor2D.g2h(x * 0.5, y * 0.5);
          tooltip(this_11, this_9, anchor_0.o2h());
          // Inline function 'korlibs.korge.ui.group' call
          var pressed = x === 1 && y === 1;
          group.bda(this_11, pressed);
          // Inline function 'korlibs.korge.input.onClick' call
          var handler = MainUIImageTester$main$slambda_10(image, anchor_0);
          // Inline function 'korlibs.korge.input.doMouseEvent' call
          var prop = click$factory();
          var tmp73_safe_receiver = this_11 == null ? null : get_mouse(this_11);
          if (tmp73_safe_receiver == null)
            null;
          else {
            // Inline function 'kotlin.let' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
            var tmp_2 = prop.get(tmp73_safe_receiver);
            tmp_2.v1x(MainUIImageTester$main$lambda(tmp73_safe_receiver, handler));
          }
        }
         while (inductionVariable_0 < 3);
    }
     while (inductionVariable < 3);
  // Inline function 'korlibs.korge.ui.uiVerticalStack' call
  // Inline function 'kotlin.apply' call
  var this_12 = addTo(UIVerticalStack.eee(null, 0.0, true), $this$uiTooltipContainer);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'anchorscale.MainUIImageTester.main.<anonymous>.<anonymous>' call
  xy(this_12, 200.0, 0.0);
  // Inline function 'korlibs.korge.ui.uiHorizontalStack' call
  // Inline function 'kotlin.apply' call
  var this_13 = addTo(UIHorizontalStack.dek(null == null ? null : numberToDouble(null), numberToDouble(0.0), true), this_12);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'anchorscale.MainUIImageTester.main.<anonymous>.<anonymous>.<anonymous>' call
  var group_0 = new UIButtonToggleableGroup();
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_2 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_14 = addTo(UIButton.sd2(size_2, 'COVER', null), this_13);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_15 = group_0.bda(this_14, true);
  var handler_0 = MainUIImageTester$main$slambda_11(image);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_0 = click$factory_0();
  var tmp73_safe_receiver_0 = this_15 == null ? null : get_mouse(this_15);
  if (tmp73_safe_receiver_0 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_3 = prop_0.get(tmp73_safe_receiver_0);
    tmp_3.v1x(MainUIImageTester$main$lambda_0(tmp73_safe_receiver_0, handler_0));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_3 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_16 = addTo(UIButton.sd2(size_3, 'FIT', null), this_13);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_17 = group_0.bda(this_16, false);
  var handler_1 = MainUIImageTester$main$slambda_12(image);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_1 = click$factory_1();
  var tmp73_safe_receiver_1 = this_17 == null ? null : get_mouse(this_17);
  if (tmp73_safe_receiver_1 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_4 = prop_1.get(tmp73_safe_receiver_1);
    tmp_4.v1x(MainUIImageTester$main$lambda_1(tmp73_safe_receiver_1, handler_1));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_4 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_18 = addTo(UIButton.sd2(size_4, 'EXACT', null), this_13);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_19 = group_0.bda(this_18, false);
  var handler_2 = MainUIImageTester$main$slambda_13(image);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_2 = click$factory_2();
  var tmp73_safe_receiver_2 = this_19 == null ? null : get_mouse(this_19);
  if (tmp73_safe_receiver_2 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_5 = prop_2.get(tmp73_safe_receiver_2);
    tmp_5.v1x(MainUIImageTester$main$lambda_2(tmp73_safe_receiver_2, handler_2));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var label = 'NO_SCALE';
  var size_5 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_20 = addTo(UIButton.sd2(size_5, label, null), this_13);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_21 = group_0.bda(this_20, false);
  var handler_3 = MainUIImageTester$main$slambda_14(image);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_3 = click$factory_3();
  var tmp73_safe_receiver_3 = this_21 == null ? null : get_mouse(this_21);
  if (tmp73_safe_receiver_3 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_6 = prop_3.get(tmp73_safe_receiver_3);
    tmp_6.v1x(MainUIImageTester$main$lambda_3(tmp73_safe_receiver_3, handler_3));
  }
  // Inline function 'korlibs.korge.ui.uiHorizontalStack' call
  // Inline function 'kotlin.apply' call
  var this_22 = addTo(UIHorizontalStack.dek(null == null ? null : numberToDouble(null), numberToDouble(0.0), true), this_12);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'anchorscale.MainUIImageTester.main.<anonymous>.<anonymous>.<anonymous>' call
  var group_1 = new UIButtonToggleableGroup();
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_6 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_23 = addTo(UIButton.sd2(size_6, 'SQUARE', null), this_22);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_24 = group_1.bda(this_23, false);
  var handler_4 = MainUIImageTester$main$slambda_15(image);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_4 = click$factory_4();
  var tmp73_safe_receiver_4 = this_24 == null ? null : get_mouse(this_24);
  if (tmp73_safe_receiver_4 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_7 = prop_4.get(tmp73_safe_receiver_4);
    tmp_7.v1x(MainUIImageTester$main$lambda_4(tmp73_safe_receiver_4, handler_4));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_7 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_25 = addTo(UIButton.sd2(size_7, 'HRECT', null), this_22);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_26 = group_1.bda(this_25, true);
  var handler_5 = MainUIImageTester$main$slambda_16(image);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_5 = click$factory_5();
  var tmp73_safe_receiver_5 = this_26 == null ? null : get_mouse(this_26);
  if (tmp73_safe_receiver_5 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_8 = prop_5.get(tmp73_safe_receiver_5);
    tmp_8.v1x(MainUIImageTester$main$lambda_5(tmp73_safe_receiver_5, handler_5));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_8 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_27 = addTo(UIButton.sd2(size_8, 'VRECT', null), this_22);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_28 = group_1.bda(this_27, false);
  var handler_6 = MainUIImageTester$main$slambda_17(image);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_6 = click$factory_6();
  var tmp73_safe_receiver_6 = this_28 == null ? null : get_mouse(this_28);
  if (tmp73_safe_receiver_6 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_9 = prop_6.get(tmp73_safe_receiver_6);
    tmp_9.v1x(MainUIImageTester$main$lambda_6(tmp73_safe_receiver_6, handler_6));
  }
  // Inline function 'korlibs.korge.ui.uiHorizontalStack' call
  // Inline function 'kotlin.apply' call
  var this_29 = addTo(UIHorizontalStack.dek(null == null ? null : numberToDouble(null), numberToDouble(0.0), true), this_12);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'anchorscale.MainUIImageTester.main.<anonymous>.<anonymous>.<anonymous>' call
  var group_2 = new UIButtonToggleableGroup();
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var label_0 = 'korim.png';
  var size_9 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_30 = addTo(UIButton.sd2(size_9, label_0, null), this_29);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_31 = group_2.bda(this_30, true);
  var handler_7 = MainUIImageTester$main$slambda_18(image, korimPng);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_7 = click$factory_7();
  var tmp73_safe_receiver_7 = this_31 == null ? null : get_mouse(this_31);
  if (tmp73_safe_receiver_7 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_10 = prop_7.get(tmp73_safe_receiver_7);
    tmp_10.v1x(MainUIImageTester$main$lambda_7(tmp73_safe_receiver_7, handler_7));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var label_1 = 'bunnys.png';
  var size_10 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_32 = addTo(UIButton.sd2(size_10, label_1, null), this_29);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_33 = group_2.bda(this_32, false);
  var handler_8 = MainUIImageTester$main$slambda_19(image, bunnysPng);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_8 = click$factory_8();
  var tmp73_safe_receiver_8 = this_33 == null ? null : get_mouse(this_33);
  if (tmp73_safe_receiver_8 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_11 = prop_8.get(tmp73_safe_receiver_8);
    tmp_11.v1x(MainUIImageTester$main$lambda_8(tmp73_safe_receiver_8, handler_8));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  // Inline function 'korlibs.korge.ui.group' call
  // Inline function 'korlibs.korge.ui.uiButton' call
  var label_2 = 'vampire.ase';
  var size_11 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_34 = addTo(UIButton.sd2(size_11, label_2, null), this_29);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
  var this_35 = group_2.bda(this_34, false);
  var handler_9 = MainUIImageTester$main$slambda_20(image, vampireAse);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_9 = click$factory_9();
  var tmp73_safe_receiver_9 = this_35 == null ? null : get_mouse(this_35);
  if (tmp73_safe_receiver_9 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_12 = prop_9.get(tmp73_safe_receiver_9);
    tmp_12.v1x(MainUIImageTester$main$lambda_9(tmp73_safe_receiver_9, handler_9));
  }
  return Unit_instance;
}
function MainUIImageTester$main$slambda_10($image, $anchor) {
  var i = new MainUIImageTester$main$slambda($image, $anchor);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_10($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_10($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_11($image) {
  var i = new MainUIImageTester$main$slambda_0($image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_11($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_0($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_11($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_12($image) {
  var i = new MainUIImageTester$main$slambda_1($image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_12($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_1($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_1($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_12($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_13($image) {
  var i = new MainUIImageTester$main$slambda_2($image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_13($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_2($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_2($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_13($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_14($image) {
  var i = new MainUIImageTester$main$slambda_3($image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_14($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_3($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_3($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_14($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_15($image) {
  var i = new MainUIImageTester$main$slambda_4($image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_15($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_4($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_4($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_15($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_16($image) {
  var i = new MainUIImageTester$main$slambda_5($image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_16($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_5($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_5($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_16($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_17($image) {
  var i = new MainUIImageTester$main$slambda_6($image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_17($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_6($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_6($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_17($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_18($image, $korimPng) {
  var i = new MainUIImageTester$main$slambda_7($image, $korimPng);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_18($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_7($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_7($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_18($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_19($image, $bunnysPng) {
  var i = new MainUIImageTester$main$slambda_8($image, $bunnysPng);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_19($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_8($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_8($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_19($handler, it));
    return Unit_instance;
  };
}
function MainUIImageTester$main$slambda_20($image, $vampireAse) {
  var i = new MainUIImageTester$main$slambda_9($image, $vampireAse);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function MainUIImageTester$main$lambda$slambda_20($handler, $it) {
  var i = new MainUIImageTester$main$lambda$slambda_9($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function MainUIImageTester$main$lambda_9($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, MainUIImageTester$main$lambda$slambda_20($handler, it));
    return Unit_instance;
  };
}
function click$factory() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_0() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_1() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_2() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_3() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_4() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_5() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_6() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_7() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_8() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_9() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function *_generator_invoke__zhh2q8_10($this, $completion) {
  var tmp = $this.gq1_1($this.hq1_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_11($this, it, $completion) {
  var tmp = $this.iq1_1.kbu().mbe(getKClassFromExpression($this.iq1_1), [], VOID, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_12($this, $completion) {
  var tmp = $this.jq1_1($this.kq1_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function nextDoubleInclusive(_this__u8e3s4, $this) {
  return _this__u8e3s4.t1(16777217) / 16777216;
}
function get_1(_this__u8e3s4, $this, l, r) {
  return interpolateAngle(toRatio(nextDoubleInclusive(_this__u8e3s4, $this)), l, r);
}
function SimpleBox2dScene$sceneMain$slambda_1($$this$fixedSizeContainer, this$0) {
  var i = new SimpleBox2dScene$sceneMain$slambda($$this$fixedSizeContainer, this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function SimpleBox2dScene$sceneMain$lambda$slambda_1($handler, $it) {
  var i = new SimpleBox2dScene$sceneMain$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SimpleBox2dScene$sceneMain$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, SimpleBox2dScene$sceneMain$lambda$slambda_1($handler, it));
    return Unit_instance;
  };
}
function SimpleBox2dScene$sceneMain$slambda_2(this$0) {
  var i = new SimpleBox2dScene$sceneMain$slambda_0(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function SimpleBox2dScene$sceneMain$lambda$slambda_2($handler, $it) {
  var i = new SimpleBox2dScene$sceneMain$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SimpleBox2dScene$sceneMain$lambda_0($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, SimpleBox2dScene$sceneMain$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function click$factory_10() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_11() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function *_generator_sceneMain__5xvlzl(_this__u8e3s4, $this, $completion) {
  println('currentThreadId=' + Companion_getInstance_21().y1w().toString());
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp$ret$1 = toDuration(1, DurationUnit_MILLISECONDS_getInstance());
  var tmp = delay_0(tmp$ret$1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  println('currentThreadId=' + Companion_getInstance_21().y1w().toString());
  println('ag.graphicExtensions=' + toString($this.saq().p8k()));
  println('ag.isFloatTextureSupported=' + $this.saq().t8k());
  println('ag.isInstancedSupported=' + $this.saq().q8k());
  var tmp_0 = readBitmap(get_resourcesVfs().bj('bunnys.png'), VOID, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var wabbitTexture = tmp_0;
  var bunny1 = sliceWithSize(wabbitTexture, 2, 47, 26, 37);
  var bunny2 = sliceWithSize(wabbitTexture, 2, 86, 26, 37);
  var bunny3 = sliceWithSize(wabbitTexture, 2, 125, 26, 37);
  var bunny4 = sliceWithSize(wabbitTexture, 2, 164, 26, 37);
  var bunny5 = sliceWithSize(wabbitTexture, 2, 2, 26, 37);
  var startBunnyCount = 2;
  var bunnyTextures = listOf([bunny1, bunny2, bunny3, bunny4, bunny5]);
  var currentTexture = {_v: bunny1};
  var bunnys = new BunnyContainer(200000);
  _this__u8e3s4.oca(bunnys.whe(wabbitTexture));
  var tmp2_$receiver = get_DefaultTtfFont();
  var tmp3_chars = Companion_getInstance_1().k5o_1.r5o(Companion_getInstance_1().j5o_1).r5o(Companion_getInstance_1().l5o_1).r5o(Companion_getInstance_1().m5o_1).r5o(Companion_getInstance_1().i5o_1);
  // Inline function 'korlibs.image.font.toBitmapFont' call
  var effect = new BitmapEffect(VOID, 1, 1, 1);
  var fontName = tmp2_$receiver.w3();
  var paint = new RGBA(Colors_getInstance().g4r_1);
  var font = Companion_instance.l5k(tmp2_$receiver, numberToDouble(16.0), tmp3_chars, fontName, paint, true, effect);
  // Inline function 'korlibs.korge.view.text' call
  var alignment = Companion_getInstance_2().h5s_1;
  var color = Colors_getInstance().g4r_1;
  var renderer = get_DefaultStringTextRenderer();
  var autoScaling = Companion_instance_0.sfm_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Text.tfm('', numberToDouble(16.0), color, font, alignment, renderer, autoScaling, null, null);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.text.<anonymous>' call
  var bunnyCountText = position(this_1, 32.0, 32.0);
  var random = Random_0(0);
  sceneMain$addBunny(bunnys, random, currentTexture, bunnyCountText, startBunnyCount);
  var maxX = $this.vbk().eb8_1;
  var minX = 0.0;
  var maxY = $this.vbk().fb8_1;
  var minY = 0.0;
  var gravity = 0.5;
  // Inline function 'korlibs.korge.input.mouse' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  get_mouse(_this__u8e3s4).fbi_1.w1x(BunnymarkScene$sceneMain$lambda(currentTexture, bunnyTextures, random));
  addUpdater(_this__u8e3s4, BunnymarkScene$sceneMain$lambda_0($this, bunnys, gravity, maxX, minX, maxY, random, minY, currentTexture, bunnyCountText));
  return Unit_instance;
}
function sceneMain$addBunny(bunnys, random, currentTexture, bunnyCountText, count) {
  var inductionVariable = 0;
  // Inline function 'kotlin.math.min' call
  var b = bunnys.oy();
  var last = Math.min(count, b);
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'bunnymark.BunnymarkScene.sceneMain.addBunny.<anonymous>' call
      var bunny = bunnys.lhe();
      bunnys.kq2(bunny, random.b5() * 1);
      bunnys.mq2(bunny, random.b5() * 1 - 5);
      bunnys.she(bunny, 0.5, 1.0);
      bunnys.vhe(bunny, currentTexture._v);
      bunnys.uhe(bunny, 0.5 + random.b5() * 0.5);
      bunnys.jhe(bunny, random.b5() - 0.5);
    }
     while (inductionVariable < last);
  bunnyCountText.bcg('(WIP) KorGE Bunnymark. Bunnies: ' + bunnys.rhd_1);
}
function BunnymarkScene$sceneMain$lambda($currentTexture, $bunnyTextures, $random) {
  return function (it) {
    $currentTexture._v = random($bunnyTextures, $random);
    return Unit_instance;
  };
}
function BunnymarkScene$sceneMain$lambda_0(this$0, $bunnys, $gravity, $maxX, $minX, $maxY, $random, $minY, $currentTexture, $bunnyCountText) {
  return function ($this$addUpdater, it) {
    var tmp;
    if (!(this$0.vbk().bb7_1.baa_1 === 0)) {
      var tmp_0;
      if ($bunnys.rhd_1 < ($bunnys.qhd_1 / 2 | 0)) {
        sceneMain$addBunny($bunnys, $random, $currentTexture, $bunnyCountText, 500);
        tmp_0 = Unit_instance;
      } else if ($bunnys.rhd_1 < ($bunnys.qhd_1 - 1000 | 0)) {
        sceneMain$addBunny($bunnys, $random, $currentTexture, $bunnyCountText, 1000);
        tmp_0 = Unit_instance;
      }
      tmp = tmp_0;
    }
    var this_0 = $bunnys;
    var m = 0;
    var inductionVariable = 0;
    var last = this_0.rhd_1;
    var tmp_1;
    if (inductionVariable < last) {
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'bunnymark.BunnymarkScene.sceneMain.<anonymous>.<anonymous>' call
        var bunny = _FSprite___init__impl__y4bm26(m);
        this_0.fhe(bunny, this_0.nhe(bunny) + this_0.lq2(bunny));
        this_0.ghe(bunny, this_0.ohe(bunny) + this_0.nq2(bunny));
        this_0.mq2(bunny, this_0.nq2(bunny) + $gravity);
        if (this_0.nhe(bunny) > $maxX) {
          this_0.kq2(bunny, this_0.lq2(bunny) * -1);
          this_0.fhe(bunny, $maxX);
        } else if (this_0.nhe(bunny) < $minX) {
          this_0.kq2(bunny, this_0.lq2(bunny) * -1);
          this_0.fhe(bunny, $minX);
        }
        if (this_0.ohe(bunny) > $maxY) {
          this_0.mq2(bunny, this_0.nq2(bunny) * -0.85);
          this_0.ghe(bunny, $maxY);
          this_0.jhe(bunny, ($random.b5() - 0.5) * 0.2);
          if ($random.b5() > 0.5) {
            this_0.mq2(bunny, this_0.nq2(bunny) - $random.b5() * 6);
          }
        } else if (this_0.ohe(bunny) < $minY) {
          this_0.mq2(bunny, 0.0);
          this_0.ghe(bunny, $minY);
        }
        m = m + 8 | 0;
      }
       while (inductionVariable < last);
      tmp_1 = Unit_instance;
    }
    return Unit_instance;
  };
}
function set_view_0(_this__u8e3s4, _set____db54di) {
  _init_properties_DungeonScene_kt__hmbzam();
  var this_0 = view$delegate;
  var property = view$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_view(_this__u8e3s4) {
  _init_properties_DungeonScene_kt__hmbzam();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = view$delegate;
    var property = view$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var view$delegate;
function set_blockedResults(_this__u8e3s4, _set____db54di) {
  _init_properties_DungeonScene_kt__hmbzam();
  var this_0 = blockedResults$delegate;
  var property = blockedResults$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_blockedResults(_this__u8e3s4) {
  _init_properties_DungeonScene_kt__hmbzam();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = blockedResults$delegate;
    var property = blockedResults$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var blockedResults$delegate;
function *_generator_invoke__zhh2q8_13($this, it, $completion) {
  // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
  var this_0 = $this.zq2_1.kbu();
  var injects = [];
  // Inline function 'korlibs.time.seconds' call
  // Inline function 'korlibs.time.seconds' call
  var time = toDuration(0, DurationUnit_SECONDS_getInstance());
  var transition = this_0.gbe_1;
  // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
  var clazz = getKClass(DungeonScene);
  var injects_0 = injects.slice();
  var sceneInjector = this_0.fbe_1.ab7_1.q87().o87(getKClass(SceneContainer), this_0).o87(getKClass(Resources), new Resources(this_0.c15(), this_0.fbe_1.hbf().d3o_1, this_0.fbe_1.hbf()));
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n = 0;
  while (n < injects_0.length) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var inject = injects_0[_unary__edvuaz];
    var tmp = getKClassFromExpression(inject);
    sceneInjector.o87(isInterface(tmp, KClass) ? tmp : THROW_CCE(), inject);
  }
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>.<anonymous>.<anonymous>' call
  var newScene = new DungeonScene();
  // Inline function 'korlibs.logger.Logger.info' call
  var this_1 = Companion_getInstance_5().ec1_1;
  var level = Level_INFO_getInstance();
  var tmp_0;
  if (this_1.u3g(level)) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var tmp$ret$3 = 'Changing scene to... ' + toString(clazz) + ' ... ' + toString(newScene);
    this_1.v3g(level, tmp$ret$3);
    tmp_0 = Unit_instance;
  }
  if (true) {
    newScene.z87(sceneInjector);
    var tmp_1 = getKClassFromExpression(newScene);
    var tmp_2 = isInterface(tmp_1, KClass) ? tmp_1 : THROW_CCE();
    this_0.fbe_1.ab7_1.s87(tmp_2, DungeonScene$sceneMain$slambda$lambda(sceneInjector));
  }
  var tmp_3 = this_0.gc1(newScene, time, transition, $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  return Unit_instance;
}
function DungeonScene$sceneMain$slambda$lambda($sceneInjector) {
  return function ($this$mapPrototype) {
    // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>.<anonymous>.<anonymous>' call
    return new DungeonScene();
  };
}
function *_generator_invoke__zhh2q8_14($this, $completion) {
  var tmp = $this.aq3_1($this.bq3_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_15($this, $completion) {
  var tmp = alert($this.cq3_1.xbw(), 'Found ' + toString($this.dq3_1), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_sceneMain__5xvlzl_0(_this__u8e3s4, $this, $completion) {
  $this.eq1(_this__u8e3s4);
  var atlas = MutableAtlas.l4q();
  var tmp = TypedVfsFileTTF__read_impl_q2ozy2(KR_instance.gpy().ipy(), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var font = get_lazyBitmapSDF(tmp);
  var tmp_0 = TypedAse__read_impl_3z26oi(KR_instance.hpy().jpy(), atlas, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var wizardFemale = tmp_0.kpy_1;
  var tmp_1 = readImageDataContainer(get_resourcesVfs().bj('gfx/cleric_f.ase'), toProps(ASE_getInstance()), atlas, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var clericFemale = tmp_1;
  var tmp_2 = readImageDataContainer(get_resourcesVfs().bj('gfx/minotaur.ase'), toProps(ASE_getInstance()), atlas, $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  var minotaur = tmp_2;
  var tmp_3 = readLDTKWorld(get_resourcesVfs().bj('gfx/dungeon_tilesmap_calciumtrice.ldtk'), VOID, $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  var ldtk = tmp_3;
  var level = ensureNotNull(ldtk.ekn().k3('Level_0'));
  var tileEntities = ensureNotNull(ldtk.ekn().k3('TILES')).jkn().k3('Entities');
  var tmp8_safe_receiver = tileEntities == null ? null : tileEntities.hkn_1;
  var tmp9_safe_receiver = tmp8_safe_receiver == null ? null : tmp8_safe_receiver.kkg_1;
  var tmp_4;
  if (tmp9_safe_receiver == null) {
    tmp_4 = null;
  } else {
    // Inline function 'kotlin.collections.associateBy' call
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(tmp9_safe_receiver, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.wc(capacity);
    var tmp0_iterator = tmp9_safe_receiver.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
      var tmp$ret$0 = _Dyn___get_str__impl__dup4cs(get_valueDyn(get_fieldInstancesByName(element).k3('Name')));
      destination.r3(tmp$ret$0, element);
    }
    tmp_4 = destination;
  }
  var tmp0_elvis_lhs = tmp_4;
  var tileEntitiesByName = tmp0_elvis_lhs == null ? emptyMap() : tmp0_elvis_lhs;
  var ClosedChest = tileEntitiesByName.k3('ClosedChest');
  var OpenedChest = tileEntitiesByName.k3('OpenedChest');
  println('tileEntitiesByName=' + toString(tileEntitiesByName));
  var showAnnotations = {_v: false};
  var levelView = null;
  var annotations = {_v: null};
  var annotations2 = null;
  var highlight = {_v: null};
  // Inline function 'korlibs.korge.view.camera' call
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Camera.fge();
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
  levelView = addTo(LDTKLevelView.zkp(level), this_1);
  // Inline function 'korlibs.korge.view.filter.filters' call
  // Inline function 'korlibs.korge.view.graphics' call
  var renderer = GraphicsRenderer_SYSTEM_getInstance();
  // Inline function 'kotlin.also' call
  var this_2 = addTo(Graphics.zh3(EmptyShape_instance, renderer), this_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape' call
  var result;
  // Inline function 'korlibs.image.vector.buildShape' call
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>.<anonymous>' call
  result = Unit_instance;
  var tmp$ret$5 = (new ShapeBuilder(null, null)).p5r();
  this_2.xh3(tmp$ret$5);
  this_2.fh4();
  // Inline function 'kotlin.also' call
  var this_3 = BlurFilter.chf(2.0);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>.<anonymous>' call
  this_3.fhf(false);
  var filters = [this_3];
  var filterScale = get_filterScale(this_2);
  // Inline function 'korlibs.korge.view.filter.filters' call
  var filters_0 = toList(filters);
  var filterScale_0 = numberToDouble(filterScale);
  set_filter(this_2, Companion_instance_1.dhg(null, filters_0));
  set_filterScale(this_2, numberToDouble(filterScale_0));
  highlight._v = this_2;
  // Inline function 'korlibs.korge.view.graphics' call
  var renderer_0 = GraphicsRenderer_SYSTEM_getInstance();
  // Inline function 'kotlin.also' call
  var this_4 = addTo(Graphics.zh3(EmptyShape_instance, renderer_0), this_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape' call
  var result_0;
  // Inline function 'korlibs.image.vector.buildShape' call
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>.<anonymous>' call
  result_0 = Unit_instance;
  var tmp$ret$14 = (new ShapeBuilder(null, null)).p5r();
  this_4.xh3(tmp$ret$14);
  this_4.fh4();
  annotations._v = this_4;
  this_1.hge(Companion_getInstance_13().e2t(0.0, 0.0, 800.0, 600.0).e2r(0.5));
  var camera = this_1;
  var tmp_5;
  if (levelView == null) {
    throwUninitializedPropertyAccessException('levelView');
  } else {
    tmp_5 = levelView;
  }
  var tmp_6 = tmp_5;
  var tmp_7;
  if (highlight._v == null) {
    throwUninitializedPropertyAccessException('highlight');
  } else {
    tmp_7 = highlight._v;
  }
  mask2(tmp_6, tmp_7, false);
  var tmp_8;
  if (highlight._v == null) {
    throwUninitializedPropertyAccessException('highlight');
  } else {
    tmp_8 = highlight._v;
  }
  tmp_8.car(false);
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_5 = addTo(UIButton.sd2(size, 'Reload', null), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
  // Inline function 'korlibs.korge.input.onClick' call
  var handler = DungeonScene$sceneMain$slambda_0($this);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop = click$factory_12();
  var tmp73_safe_receiver = this_5 == null ? null : get_mouse(this_5);
  if (tmp73_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_9 = prop.get(tmp73_safe_receiver);
    tmp_9.v1x(DungeonScene$sceneMain$lambda(tmp73_safe_receiver, handler));
  }
  var entitiesBvh = new BvhWorld(camera);
  addUpdater(_this__u8e3s4, DungeonScene$sceneMain$lambda_0(entitiesBvh));
  // Inline function 'korlibs.korge.view.xy' call
  // Inline function 'korlibs.korge.view.text' call
  var textSize = Companion_instance_0.rfm_1;
  var color = Colors_getInstance().g4r_1;
  var alignment = Companion_getInstance_2().h5s_1;
  var renderer_1 = get_DefaultStringTextRenderer();
  var autoScaling = Companion_instance_0.sfm_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_6 = Text.tfm('', numberToDouble(textSize), color, font, alignment, renderer_1, autoScaling, null, null);
  // Inline function 'kotlin.apply' call
  var this_7 = addTo(this_6, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.text.<anonymous>' call
  var textInfo = xy(this_7, numberToDouble(120), numberToDouble(8));
  var tmp_10;
  if (levelView == null) {
    throwUninitializedPropertyAccessException('levelView');
  } else {
    tmp_10 = levelView;
  }
  println(tmp_10.ykp_1.l3());
  var tmp_11;
  if (levelView == null) {
    throwUninitializedPropertyAccessException('levelView');
  } else {
    tmp_11 = levelView;
  }
  var grid = ensureNotNull(tmp_11.ykp_1.k3('Kind')).oks_1;
  var tmp_12;
  if (levelView == null) {
    throwUninitializedPropertyAccessException('levelView');
  } else {
    tmp_12 = levelView;
  }
  var entities = ensureNotNull(tmp_12.ykp_1.k3('Entities')).qks_1;
  var _iterator__ex2g4s = entities.f1();
  while (_iterator__ex2g4s.g1()) {
    var entity = _iterator__ex2g4s.h1();
    entitiesBvh.ubw(entity);
  }
  // Inline function 'kotlin.apply' call
  var tmp$ret$33;
  $l$block: {
    // Inline function 'kotlin.collections.first' call
    var tmp0_iterator_0 = entities.f1();
    while (tmp0_iterator_0.g1()) {
      var element_0 = tmp0_iterator_0.h1();
      // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
      var tmp11_safe_receiver = element_0.gkv_1.k3('Name');
      if ((tmp11_safe_receiver == null ? null : tmp11_safe_receiver.qkm()) === 'Cleric') {
        tmp$ret$33 = element_0;
        break $l$block;
      }
    }
    throw NoSuchElementException.p('Collection contains no element matching the predicate.');
  }
  var this_8 = tmp$ret$33;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
  // Inline function 'kotlin.also' call
  var this_9 = ImageDataView2.uq5(clericFemale.a6m_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>.<anonymous>' call
  this_9.ch4(false);
  this_9.vq5('idle');
  anchorPixel(this_9, Vector2D.k2h(this_9.j2y() * 0.5, this_9.k2y()));
  this_9.i85();
  this_8.rkv(this_9);
  var player = this_8;
  // Inline function 'kotlin.apply' call
  var tmp$ret$37;
  $l$block_0: {
    // Inline function 'kotlin.collections.first' call
    var tmp0_iterator_1 = entities.f1();
    while (tmp0_iterator_1.g1()) {
      var element_1 = tmp0_iterator_1.h1();
      // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
      var tmp12_safe_receiver = element_1.gkv_1.k3('Name');
      if ((tmp12_safe_receiver == null ? null : tmp12_safe_receiver.qkm()) === 'Mage') {
        tmp$ret$37 = element_1;
        break $l$block_0;
      }
    }
    throw NoSuchElementException.p('Collection contains no element matching the predicate.');
  }
  var this_10 = tmp$ret$37;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
  // Inline function 'kotlin.also' call
  var this_11 = ImageDataView2.uq5(_AseGfxWizardFAse___get_data__impl__a08yes(wizardFemale).a6m_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>.<anonymous>' call
  this_11.ch4(false);
  this_11.vq5('idle');
  anchor(this_11, Companion_getInstance_10().w2g_1);
  this_11.i85();
  this_10.rkv(this_11);
  var mage = this_10;
  var tmp$ret$41;
  $l$block_1: {
    // Inline function 'kotlin.collections.first' call
    var tmp0_iterator_2 = entities.f1();
    while (tmp0_iterator_2.g1()) {
      var element_2 = tmp0_iterator_2.h1();
      // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
      var tmp13_safe_receiver = element_2.gkv_1.k3('Name');
      if ((tmp13_safe_receiver == null ? null : tmp13_safe_receiver.qkm()) === 'Minotaur') {
        tmp$ret$41 = element_2;
        break $l$block_1;
      }
    }
    throw NoSuchElementException.p('Collection contains no element matching the predicate.');
  }
  var tmp_13 = tmp$ret$41;
  // Inline function 'kotlin.also' call
  var this_12 = ImageDataView2.uq5(minotaur.a6m_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
  this_12.ch4(false);
  this_12.vq5('idle');
  anchor(this_12, Companion_getInstance_10().w2g_1);
  this_12.i85();
  tmp_13.rkv(this_12);
  var virtualController_0 = virtualController(_this__u8e3s4, listOf_0(new VirtualStickConfig(Key_LEFT_getInstance(), Key_RIGHT_getInstance(), Key_UP_getInstance(), Key_DOWN_getInstance(), GameButton_LX_getInstance(), GameButton_LY_getInstance(), Companion_getInstance_10().v2g_1)), listOf([new VirtualButtonConfig(Key_SPACE_getInstance(), GameButton_BUTTON_SOUTH_getInstance(), Companion_getInstance_10().x2g_1), new VirtualButtonConfig(Companion_getInstance_22().a8i_1, GameButton_BUTTON_NORTH_getInstance(), Companion_getInstance_10().x2g_1, Vector2D.u2n(0.0, -100.0)), new VirtualButtonConfig(Key_Z_getInstance(), GameButton_BUTTON_WEST_getInstance(), Companion_getInstance_10().x2g_1, Vector2D.u2n(0.0, -200.0))]));
  var lastInteractiveView = {_v: null};
  var playerDirection = {_v: Vector2D.k2h(1.0, 0.0)};
  var gridSize = Size2D.f2j(16, 16);
  var playerState = {_v: ''};
  var tmp_14 = get_hz(60);
  addUpdater_0(_this__u8e3s4, tmp_14, VOID, DungeonScene$sceneMain$lambda_1(virtualController_0, player, playerDirection, playerState, lastInteractiveView, grid, gridSize, entitiesBvh, entities, textInfo, highlight, annotations, showAnnotations));
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>' call
  var tmp_15 = GameButton_BUTTON_WEST_getInstance();
  virtualController_0.hhv(tmp_15, DungeonScene$sceneMain$lambda_2(showAnnotations));
  var tmp_16 = GameButton_BUTTON_SOUTH_getInstance();
  virtualController_0.hhv(tmp_16, DungeonScene$sceneMain$lambda_3(player, playerState, OpenedChest, $this, playerDirection, grid, gridSize, entitiesBvh));
  var tmp_17 = GameButton_BUTTON_NORTH_getInstance();
  virtualController_0.hhv(tmp_17, DungeonScene$sceneMain$lambda_4(player, playerState, OpenedChest, $this, playerDirection, grid, gridSize, entitiesBvh));
  $this.fq1();
  return Unit_instance;
}
function sceneMain$check(_this__u8e3s4, it) {
  if (!_this__u8e3s4.v2y(it.s2u_1, it.t2u_1))
    return true;
  var v = _this__u8e3s4.u2y(it.s2u_1, it.t2u_1);
  return !(v === 1) && !(v === 3);
}
function sceneMain$hitTest(entitiesBvh, player, grid, gridSize, pos) {
  var tmp = Companion_getInstance_13();
  // Inline function 'korlibs.math.geom.Vector2D.minus' call
  var that = Vector2D.k2l(1, 1);
  var tmp_0 = Vector2D.k2h(pos.i2h_1 - that.i2h_1, pos.j2h_1 - that.j2h_1);
  // Inline function 'korlibs.math.geom.Vector2D.plus' call
  var that_0 = Vector2D.k2l(1, 1);
  var tmp$ret$1 = Vector2D.k2h(pos.i2h_1 + that_0.i2h_1, pos.j2h_1 + that_0.j2h_1);
  var _iterator__ex2g4s = entitiesBvh.fq3_1.e40(tmp.c2j(tmp_0, tmp$ret$1)).f1();
  $l$loop_1: while (_iterator__ex2g4s.g1()) {
    var result = _iterator__ex2g4s.h1();
    var tmp14_safe_receiver = result.n3c_1;
    var tmp0_elvis_lhs = tmp14_safe_receiver == null ? null : tmp14_safe_receiver.xq5_1;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      continue $l$loop_1;
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    var view = tmp_1;
    if (equals(view, player))
      continue $l$loop_1;
    var entityView = view instanceof LDTKEntityView ? view : null;
    var tmp16_safe_receiver = entityView == null ? null : entityView.gkv_1;
    var doBlock = tmp16_safe_receiver == null ? null : tmp16_safe_receiver.k3('Collides');
    if ((doBlock == null ? null : doBlock.qkm()) === 'false')
      continue $l$loop_1;
    return true;
  }
  return sceneMain$check(grid, toInt(div(pos, gridSize)));
}
function sceneMain$doRay(grid, gridSize, entitiesBvh, player, pos, dir, property) {
  var dir_0 = Vector2D.k2h(isAlmostEquals(dir.i2h_1, 0.0) ? 1.0E-5 : dir.i2h_1, isAlmostEquals(dir.j2h_1, 0.0) ? 1.0E-5 : dir.j2h_1);
  var ray = Ray2D.p2s(pos, dir_0);
  // Inline function 'kotlin.collections.arrayListOf' call
  var outResults = ArrayList.m1();
  // Inline function 'kotlin.collections.arrayListOf' call
  var blockedResults = ArrayList.m1();
  // Inline function 'kotlin.collections.plusAssign' call
  var tmp18_safe_receiver = raycast(grid, ray, gridSize, VOID, DungeonScene$sceneMain$doRay$lambda);
  var tmp;
  if (tmp18_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'dungeon.DungeonScene.sceneMain.doRay.<anonymous>' call
    set_view_0(tmp18_safe_receiver, null);
    tmp = tmp18_safe_receiver;
  }
  var element = tmp;
  outResults.f(element);
  var _iterator__ex2g4s = entitiesBvh.fq3_1.c40(ray).f1();
  $l$loop_0: while (_iterator__ex2g4s.g1()) {
    var result = _iterator__ex2g4s.h1();
    var tmp19_safe_receiver = result.h3c_1.n3c_1;
    var view = tmp19_safe_receiver == null ? null : tmp19_safe_receiver.xq5_1;
    if (equals(view, player))
      continue $l$loop_0;
    var rect = toRectangle(result.h3c_1.k3c_1);
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    var this_0 = ray.l2s_1;
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var this_1 = ray.m2s_1.q2s();
    var scale = result.g3c_1;
    var that = Vector2D.k2h(this_1.i2h_1 * scale, this_1.j2h_1 * scale);
    var intersectionPos = Vector2D.k2h(this_0.i2h_1 + that.i2h_1, this_0.j2h_1 + that.j2h_1);
    var normalX = intersectionPos.i2h_1 <= rect.p2i() + 0.5 ? -1.0 : intersectionPos.i2h_1 >= rect.q2i() - 0.5 ? +1.0 : 0.0;
    var normalY = intersectionPos.j2h_1 <= rect.r2i() + 0.5 ? -1.0 : intersectionPos.j2h_1 >= rect.s2i() - 0.5 ? +1.0 : 0.0;
    var tmp20_safe_receiver = new RayResult(ray, intersectionPos, Vector2D.u2n(normalX, normalY));
    var tmp_0;
    if (tmp20_safe_receiver == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'dungeon.DungeonScene.sceneMain.doRay.<anonymous>' call
      set_view_0(tmp20_safe_receiver, view);
      tmp_0 = tmp20_safe_receiver;
    }
    var rayResult = tmp_0;
    var entityView = view instanceof LDTKEntityView ? view : null;
    var tmp22_safe_receiver = entityView == null ? null : entityView.gkv_1;
    var doBlock = tmp22_safe_receiver == null ? null : tmp22_safe_receiver.k3(property);
    var tmp_1;
    if (!(rayResult == null)) {
      tmp_1 = (doBlock == null ? null : doBlock.qkm()) === 'false';
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      // Inline function 'kotlin.collections.plusAssign' call
      blockedResults.f(rayResult);
      continue $l$loop_0;
    }
    // Inline function 'kotlin.collections.plusAssign' call
    outResults.f(rayResult);
  }
  var tmp$ret$6;
  $l$block_0: {
    // Inline function 'kotlin.collections.minByOrNull' call
    var iterator = filterNotNull(outResults).f1();
    if (!iterator.g1()) {
      tmp$ret$6 = null;
      break $l$block_0;
    }
    var minElem = iterator.h1();
    if (!iterator.g1()) {
      tmp$ret$6 = minElem;
      break $l$block_0;
    }
    // Inline function 'dungeon.DungeonScene.sceneMain.doRay.<anonymous>' call
    var minValue = minElem.aq6_1.s2w(pos);
    do {
      var e = iterator.h1();
      // Inline function 'dungeon.DungeonScene.sceneMain.doRay.<anonymous>' call
      var v = e.aq6_1.s2w(pos);
      if (compareTo(minValue, v) > 0) {
        minElem = e;
        minValue = v;
      }
    }
     while (iterator.g1());
    tmp$ret$6 = minElem;
  }
  var tmp24_safe_receiver = tmp$ret$6;
  var tmp_2;
  if (tmp24_safe_receiver == null) {
    tmp_2 = null;
  } else {
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'dungeon.DungeonScene.sceneMain.doRay.<anonymous>' call
    var dist = tmp24_safe_receiver.aq6_1.s2w(pos);
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.collections.filterTo' call
    var destination = ArrayList.m1();
    var tmp0_iterator = blockedResults.f1();
    while (tmp0_iterator.g1()) {
      var element_0 = tmp0_iterator.h1();
      // Inline function 'dungeon.DungeonScene.sceneMain.doRay.<anonymous>.<anonymous>' call
      if (ensureNotNull(element_0).aq6_1.s2w(pos) < dist) {
        destination.f(element_0);
      }
    }
    set_blockedResults(tmp24_safe_receiver, destination);
    tmp_2 = tmp24_safe_receiver;
  }
  return tmp_2;
}
function sceneMain$getInteractiveView(player, playerDirection, grid, gridSize, entitiesBvh) {
  var tmp0_elvis_lhs = sceneMain$doRay(grid, gridSize, entitiesBvh, player, player.lz(), playerDirection._v, 'Collides');
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var results = tmp;
  if (results.aq6_1.s2w(player.lz()) >= 16.0)
    return null;
  return get_view(results);
}
function sceneMain$updateRay(entities, player, textInfo, highlight, annotations, showAnnotations, entitiesBvh, grid, gridSize, pos) {
  var ANGLES_COUNT = 64;
  // Inline function 'kotlin.collections.map' call
  var this_0 = until(0, ANGLES_COUNT);
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>' call
    var tmp$ret$0 = new Angle(Angle__times_impl_v3s9y2(Companion_getInstance_14().v2h_1, item / ANGLES_COUNT));
    destination.f(tmp$ret$0);
  }
  var angles = destination;
  // Inline function 'kotlin.collections.arrayListOf' call
  var results = ArrayList.m1();
  // Inline function 'kotlin.collections.arrayListOf' call
  var results2 = ArrayList.m1();
  var anglesDeque = Deque(angles);
  $l$loop_1: while (true) {
    // Inline function 'kotlin.collections.isNotEmpty' call
    if (!!anglesDeque.a1()) {
      break $l$loop_1;
    }
    var angle = anglesDeque.nl().p2h_1;
    var last = lastOrNull(results);
    // Inline function 'korlibs.math.geom.Companion.polar' call
    // Inline function 'korlibs.math.geom.Companion.polar' call
    var up = Companion_getInstance_15().e2i_1;
    var tmp$ret$7 = Vector2D.k2h(0.0 + Angle__cosine_impl_bgvzun(angle, up) * 1.0, 0.0 + Angle__sine_impl_3l11xv(angle, up) * 1.0);
    var tmp0_elvis_lhs = sceneMain$doRay(grid, gridSize, entitiesBvh, player, pos, tmp$ret$7, 'Occludes');
    var tmp;
    if (tmp0_elvis_lhs == null) {
      continue $l$loop_1;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var current = tmp;
    var tmp26_safe_receiver = current == null ? null : get_blockedResults(current);
    if (tmp26_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var elements = filterNotNull(tmp26_safe_receiver);
      addAll(results2, elements);
    }
    if (!(last == null) && (last.aq6_1.s2w(current.aq6_1) >= 16 || !last.bq6_1.equals(current.bq6_1))) {
      var lastAngle = last.zq5_1.m2s_1.o2s();
      var currentAngle = current.zq5_1.m2s_1.o2s();
      if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(Angle__minus_impl_xeau7k(lastAngle, currentAngle)), get_degrees_0(0.25)) >= 0) {
        anglesDeque.r34(new Angle(angle));
        // Inline function 'korlibs.math.geom.Companion.fromRatio' call
        Companion_getInstance_14();
        // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
        var ratio = interpolate(toRatio(0.5), _Angle___get_ratio__impl__ap3on4(lastAngle), _Angle___get_ratio__impl__ap3on4(currentAngle));
        var tmp$ret$9 = Ratio__times_impl_ucdh7y(ratio, 6.283185307179586);
        var tmp$ret$10 = _Angle___init__impl__g23q1m(tmp$ret$9);
        anglesDeque.r34(new Angle(tmp$ret$10));
        continue $l$loop_1;
      }
    }
    // Inline function 'kotlin.collections.plusAssign' call
    results.f(current);
  }
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n = 0;
  while (n < entities.j1()) {
    // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var entity = entities.i1(_unary__edvuaz);
    if (entity.ckv_1.ok6_1.f3('hide_on_fog')) {
      // Inline function 'korlibs.korge.animate.Animator.sequence' call
      var this_1 = get_simpleAnimator(entity).ybg();
      var defaultTime = this_1.ibh();
      var defaultSpeed = this_1.abg_1;
      var easing = this_1.bbg_1;
      var startImmediately = this_1.hbg_1;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.also' call
      var tmp10_root = this_1.ybf_1;
      var tmp11_fastDefaultTime = get_fast(defaultTime);
      var tmp12_level = this_1.gbg_1 + 1 | 0;
      var this_2 = new Animator(tmp10_root, tmp11_fastDefaultTime, defaultSpeed, easing, false, false, this_1, VOID, tmp12_level, startImmediately);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
      // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
      // Inline function 'korlibs.korge.tween.get' call
      var this_3 = alpha$factory(entity);
      var end = !equals(entity, player) ? 0.1 : 1.0;
      var tmp_0 = this_3.get();
      var tmp_1 = [new V2(this_3, tmp_0, end, _interpolate$ref(), false)];
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$12 = toDuration(0.25, DurationUnit_SECONDS_getInstance());
      tween(this_2, tmp_1, tmp$ret$12);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
      this_1.kbh(this_2.obg_1);
    }
  }
  var _iterator__ex2g4s = plus(results, results2).f1();
  $l$loop_2: while (_iterator__ex2g4s.g1()) {
    var result = _iterator__ex2g4s.h1();
    var tmp1_elvis_lhs = get_view(result);
    var tmp_2;
    if (tmp1_elvis_lhs == null) {
      continue $l$loop_2;
    } else {
      tmp_2 = tmp1_elvis_lhs;
    }
    var view = tmp_2;
    if (!(view.ncc() === 1.0)) {
      // Inline function 'korlibs.korge.animate.Animator.sequence' call
      var this_4 = get_simpleAnimator(view).ybg();
      var defaultTime_0 = this_4.ibh();
      var defaultSpeed_0 = this_4.abg_1;
      var easing_0 = this_4.bbg_1;
      var startImmediately_0 = this_4.hbg_1;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.also' call
      var tmp10_root_0 = this_4.ybf_1;
      var tmp11_fastDefaultTime_0 = get_fast(defaultTime_0);
      var tmp12_level_0 = this_4.gbg_1 + 1 | 0;
      var this_5 = new Animator(tmp10_root_0, tmp11_fastDefaultTime_0, defaultSpeed_0, easing_0, false, false, this_4, VOID, tmp12_level_0, startImmediately_0);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
      // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>' call
      // Inline function 'korlibs.korge.tween.get' call
      var this_6 = alpha$factory_0(view);
      var tmp_3 = this_6.get();
      var tmp_4 = [new V2(this_6, tmp_3, 1.0, _interpolate$ref_0(), false)];
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$17 = toDuration(0.25, DurationUnit_SECONDS_getInstance());
      tween(this_5, tmp_4, tmp$ret$17);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
      this_4.kbh(this_5.obg_1);
    }
  }
  textInfo.bcg('Rays: ' + results.j1());
  // Inline function 'korlibs.korge.view.Graphics.updateShape' call
  var tmp_5;
  if (highlight._v == null) {
    throwUninitializedPropertyAccessException('highlight');
  } else {
    tmp_5 = highlight._v;
  }
  var this_7 = tmp_5;
  var result_0;
  // Inline function 'korlibs.image.vector.buildShape' call
  // Inline function 'kotlin.apply' call
  var this_8 = new ShapeBuilder(null, null);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
  // Inline function 'korlibs.image.vector.Context2d.fill' call
  var paint = new RGBA(Colors_getInstance().r59('#FFFFFF55'));
  if (true) {
    this_8.b5t();
  }
  // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
  this_8.a4b(0, 0, 600, 500);
  this_8.x76(paint, null);
  // Inline function 'korlibs.image.vector.Context2d.fill' call
  var paint_0 = new RGBA(Colors_getInstance().g4r_1);
  if (true) {
    this_8.b5t();
  }
  // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
  var first = true;
  var _iterator__ex2g4s_0 = results.f1();
  while (_iterator__ex2g4s_0.g1()) {
    var result_1 = _iterator__ex2g4s_0.h1();
    if (first) {
      first = false;
      this_8.e3y(result_1.aq6_1);
    } else {
      this_8.g3y(result_1.aq6_1);
    }
  }
  this_8.u6();
  this_8.x76(paint_0, null);
  var paint_1 = new RGBA(Colors_getInstance().g4r_1);
  if (true) {
    this_8.b5t();
  }
  // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
  var _iterator__ex2g4s_1 = results.f1();
  $l$loop_3: while (_iterator__ex2g4s_1.g1()) {
    var result_2 = _iterator__ex2g4s_1.h1();
    var tmp0_elvis_lhs_0 = get_view(result_2);
    var tmp_6;
    if (tmp0_elvis_lhs_0 == null) {
      continue $l$loop_3;
    } else {
      tmp_6 = tmp0_elvis_lhs_0;
    }
    var view_0 = tmp_6;
    var tmp_7;
    if (highlight._v == null) {
      throwUninitializedPropertyAccessException('highlight');
    } else {
      tmp_7 = highlight._v;
    }
    this_8.n4a(view_0.ycd(tmp_7).f2u(MarginInt.g2m(-2)));
  }
  this_8.x76(paint_1, null);
  result_0 = Unit_instance;
  var tmp$ret$22 = this_8.p5r();
  this_7.xh3(tmp$ret$22);
  // Inline function 'korlibs.korge.view.Graphics.updateShape' call
  var tmp_8;
  if (annotations._v == null) {
    throwUninitializedPropertyAccessException('annotations');
  } else {
    tmp_8 = annotations._v;
  }
  var this_9 = tmp_8;
  var result_3;
  // Inline function 'korlibs.image.vector.buildShape' call
  // Inline function 'kotlin.apply' call
  var this_10 = new ShapeBuilder(null, null);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
  if (showAnnotations._v) {
    var _iterator__ex2g4s_2 = results.f1();
    while (_iterator__ex2g4s_2.g1()) {
      var result_4 = _iterator__ex2g4s_2.h1();
      // Inline function 'korlibs.image.vector.Context2d.fill' call
      var paint_2 = new RGBA(Colors_getInstance().i4r_1);
      if (true) {
        this_10.b5t();
      }
      // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
      this_10.m3s(result_4.aq6_1, 2.0);
      this_10.x76(paint_2, null);
    }
    var _iterator__ex2g4s_3 = results.f1();
    while (_iterator__ex2g4s_3.g1()) {
      var result_5 = _iterator__ex2g4s_3.h1();
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var paint_3 = new RGBA(Colors_getInstance().j4r_1);
      var lineWidth = this_10.o5u();
      var lineCap = this_10.p5u();
      var lineJoin = this_10.q5u();
      var miterLimit = this_10.r5u();
      var lineDash = this_10.s5u();
      var lineDashOffset = this_10.t5u();
      if (true) {
        this_10.b5t();
      }
      // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
      // Inline function 'korlibs.math.geom.Vector2D.plus' call
      var this_11 = result_5.aq6_1;
      // Inline function 'korlibs.math.geom.Vector2D.times' call
      var this_12 = result_5.bq6_1;
      var that = Vector2D.k2h(this_12.i2h_1 * 4.0, this_12.j2h_1 * 4.0);
      var tmp$ret$25 = Vector2D.k2h(this_11.i2h_1 + that.i2h_1, this_11.j2h_1 + that.j2h_1);
      this_10.l4a(result_5.aq6_1, tmp$ret$25);
      // Inline function 'korlibs.image.vector.Context2d.keep' call
      this_10.u5u();
      try {
        // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
        this_10.v5u(numberToDouble(lineWidth));
        this_10.w5u(lineCap);
        this_10.x5u(lineJoin);
        this_10.y5u(numberToDouble(miterLimit));
        this_10.z5u(lineDash);
        this_10.a5v(numberToDouble(lineDashOffset));
        this_10.b5v(paint_3);
      }finally {
        this_10.c5v();
      }
      // Inline function 'korlibs.math.geom.Vector2D.minus' call
      var this_13 = result_5.aq6_1;
      var newVec = Vector2D.k2h(this_13.i2h_1 - pos.i2h_1, this_13.j2h_1 - pos.j2h_1).a2x(result_5.bq6_1).q2s();
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var paint_4 = new RGBA(Colors_getInstance().w4w_1);
      var lineWidth_0 = this_10.o5u();
      var lineCap_0 = this_10.p5u();
      var lineJoin_0 = this_10.q5u();
      var miterLimit_0 = this_10.r5u();
      var lineDash_0 = this_10.s5u();
      var lineDashOffset_0 = this_10.t5u();
      if (true) {
        this_10.b5t();
      }
      // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
      // Inline function 'korlibs.math.geom.Vector2D.plus' call
      var this_14 = result_5.aq6_1;
      // Inline function 'korlibs.math.geom.Vector2D.times' call
      var that_0 = Vector2D.k2h(newVec.i2h_1 * 4.0, newVec.j2h_1 * 4.0);
      var tmp$ret$28 = Vector2D.k2h(this_14.i2h_1 + that_0.i2h_1, this_14.j2h_1 + that_0.j2h_1);
      this_10.l4a(result_5.aq6_1, tmp$ret$28);
      // Inline function 'korlibs.image.vector.Context2d.keep' call
      this_10.u5u();
      try {
        // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
        this_10.v5u(numberToDouble(lineWidth_0));
        this_10.w5u(lineCap_0);
        this_10.x5u(lineJoin_0);
        this_10.y5u(numberToDouble(miterLimit_0));
        this_10.z5u(lineDash_0);
        this_10.a5v(numberToDouble(lineDashOffset_0));
        this_10.b5v(paint_4);
      }finally {
        this_10.c5v();
      }
    }
  }
  if (showAnnotations._v) {
    var _iterator__ex2g4s_4 = entitiesBvh.cq6().f1();
    while (_iterator__ex2g4s_4.g1()) {
      var entity_0 = _iterator__ex2g4s_4.h1();
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var paint_5 = new RGBA(RGBA__withAd_impl_cralao(Colors_getInstance().w4v_1, 0.1));
      var lineWidth_1 = this_10.o5u();
      var lineCap_1 = this_10.p5u();
      var lineJoin_1 = this_10.q5u();
      var miterLimit_1 = this_10.r5u();
      var lineDash_1 = this_10.s5u();
      var lineDashOffset_1 = this_10.t5u();
      if (true) {
        this_10.b5t();
      }
      // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>.<anonymous>' call
      this_10.n4a(toRectangle(entity_0.k3c_1));
      // Inline function 'korlibs.image.vector.Context2d.keep' call
      this_10.u5u();
      try {
        // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
        this_10.v5u(numberToDouble(lineWidth_1));
        this_10.w5u(lineCap_1);
        this_10.x5u(lineJoin_1);
        this_10.y5u(numberToDouble(miterLimit_1));
        this_10.z5u(lineDash_1);
        this_10.a5v(numberToDouble(lineDashOffset_1));
        this_10.b5v(paint_5);
      }finally {
        this_10.c5v();
      }
    }
  }
  result_3 = Unit_instance;
  var tmp$ret$30 = this_10.p5r();
  this_9.xh3(tmp$ret$30);
  // Inline function 'kotlin.collections.map' call
  // Inline function 'kotlin.collections.mapTo' call
  var destination_0 = ArrayList.w(collectionSizeOrDefault(results, 10));
  var tmp0_iterator_0 = results.f1();
  while (tmp0_iterator_0.g1()) {
    var item_0 = tmp0_iterator_0.h1();
    // Inline function 'dungeon.DungeonScene.sceneMain.updateRay.<anonymous>' call
    var tmp$ret$32 = item_0.aq6_1.s2w(pos);
    destination_0.f(tmp$ret$32);
  }
  var tmp2_elvis_lhs = minOrNull(destination_0);
  return tmp2_elvis_lhs == null ? 0.0 : tmp2_elvis_lhs;
}
function sceneMain$_anonymous_$onAnyButton_xxvbs9(player, playerDirection, grid, gridSize, entitiesBvh, OpenedChest, this$0) {
  var tmp0_elvis_lhs = sceneMain$getInteractiveView(player, playerDirection, grid, gridSize, entitiesBvh);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var view = tmp;
  var tmp1_elvis_lhs = view instanceof LDTKEntityView ? view : null;
  var tmp_0;
  if (tmp1_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp_0 = tmp1_elvis_lhs;
  }
  var entityView = tmp_0;
  var tmp29_safe_receiver = entityView == null ? null : entityView.gkv_1;
  var tmp2_elvis_lhs = tmp29_safe_receiver == null ? null : tmp29_safe_receiver.k3('Items');
  var tmp_1;
  if (tmp2_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp_1 = tmp2_elvis_lhs;
  }
  var doBlock = tmp_1;
  // Inline function 'kotlin.collections.map' call
  var this_0 = _Dyn___get_list__impl__qpwjp(doBlock.pkm());
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>$onAnyButton.<anonymous>' call
    var it = item.e3h_1;
    var tmp$ret$0 = _Dyn___get_str__impl__dup4cs(it);
    destination.f(tmp$ret$0);
  }
  var items = destination;
  var tile = ensureNotNull(ensureNotNull(OpenedChest).pk6_1);
  // Inline function 'kotlin.also' call
  var this_1 = Image.rd8(sliceWithSize(ensureNotNull(ensureNotNull(ensureNotNull(entityView).kkv_1).qkv_1).o33(), tile.aka_1, tile.bka_1, tile.zk9_1, tile.xk9_1));
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'dungeon.DungeonScene.sceneMain.<anonymous>$onAnyButton.<anonymous>' call
  this_1.zga_1 = false;
  anchor(this_1, entityView.hkv_1);
  entityView.rkv(this_1);
  launchImmediately_0(this$0, DungeonScene$sceneMain$_anonymous_$onAnyButton$slambda_aqssrx_0(this$0, items));
  println('INTERACTED WITH: ' + view.toString() + (' :: ' + toString(getKClassFromExpression(ensureNotNull(doBlock.b2()))) + ', ' + toString_0(doBlock.b2())));
}
function DungeonScene$sceneMain$slambda_0(this$0) {
  var i = new DungeonScene$sceneMain$slambda(this$0);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function DungeonScene$sceneMain$lambda$slambda_0($handler, $it) {
  var i = new DungeonScene$sceneMain$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function DungeonScene$sceneMain$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, DungeonScene$sceneMain$lambda$slambda_0($handler, it));
    return Unit_instance;
  };
}
function DungeonScene$sceneMain$lambda_0($entitiesBvh) {
  return function ($this$addUpdater, it) {
    var _iterator__ex2g4s = $entitiesBvh.cq6().f1();
    while (_iterator__ex2g4s.g1()) {
      var entity = _iterator__ex2g4s.h1();
      var tmp10_safe_receiver = entity.n3c_1;
      if (tmp10_safe_receiver == null)
        null;
      else {
        tmp10_safe_receiver.dpl();
      }
    }
    return Unit_instance;
  };
}
function DungeonScene$sceneMain$lambda_1($virtualController, $player, $playerDirection, $playerState, $lastInteractiveView, $grid, $gridSize, $entitiesBvh, $entities, $textInfo, $highlight, $annotations, $showAnnotations) {
  return function ($this$addUpdater, it) {
    var dx = $virtualController.dhv();
    var dy = $virtualController.ehv();
    var tmp = $player.mkv_1;
    var playerView = tmp instanceof ImageDataView2 ? tmp : THROW_CCE();
    var tmp_0;
    if (!isAlmostZero(dx) || !isAlmostZero(dy)) {
      // Inline function 'kotlin.math.sign' call
      var this_0 = normalizeAlmostZero(dx);
      var tmp_1 = sign(this_0);
      // Inline function 'kotlin.math.sign' call
      var this_1 = normalizeAlmostZero(dy);
      var tmp$ret$1 = sign(this_1);
      $playerDirection._v = Vector2D.u2n(tmp_1, tmp$ret$1);
      tmp_0 = Unit_instance;
    }
    var tmp_2;
    if (dx === 0.0 && dy === 0.0) {
      playerView.vq5(!($playerState._v === '') ? $playerState._v : 'idle');
      tmp_2 = Unit_instance;
    } else {
      $playerState._v = '';
      playerView.vq5('walk');
      playerView.qcb($playerDirection._v.i2h_1 < 0 ? -1.0 : +1.0);
      tmp_2 = Unit_instance;
    }
    var speed = 1.5;
    var newDir = Vector2D.k2h(dx * speed, dy * speed);
    var oldPos = $player.lz();
    var moveRay = sceneMain$doRay($grid, $gridSize, $entitiesBvh, $player, oldPos, newDir, 'Collides');
    var tmp_3;
    if (!(moveRay == null) && moveRay.aq6_1.s2w(oldPos) < 6.0) {
      var res = newDir.a2x(moveRay.bq6_1);
      var tmp_4;
      if (!(moveRay.bq6_1.j2h_1 === 0.0)) {
        tmp_4 = Vector2D.k2h(res.i2h_1, 0.0);
      } else {
        tmp_4 = Vector2D.k2h(0.0, res.j2h_1);
      }
      tmp_3 = tmp_4;
    } else {
      tmp_3 = newDir;
    }
    var finalDir = tmp_3;
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    var newPos = Vector2D.k2h(oldPos.i2h_1 + finalDir.i2h_1, oldPos.j2h_1 + finalDir.j2h_1);
    if (!sceneMain$hitTest($entitiesBvh, $player, $grid, $gridSize, newPos) || sceneMain$hitTest($entitiesBvh, $player, $grid, $gridSize, oldPos)) {
      $player.s5w(newPos);
      $player.mcb($player.p4f());
      sceneMain$updateRay($entities, $player, $textInfo, $highlight, $annotations, $showAnnotations, $entitiesBvh, $grid, $gridSize, oldPos);
    } else {
      println('TODO!!. Check why is this happening. Operation could have lead to stuck: oldPos=' + oldPos.toString() + ' -> newPos=' + newPos.toString() + ', finalDir=' + finalDir.toString() + ', moveRay=' + toString_0(moveRay));
    }
    var tmp27_safe_receiver = $lastInteractiveView._v;
    if (tmp27_safe_receiver == null)
      null;
    else {
      tmp27_safe_receiver.m59(Colors_getInstance().g4r_1);
    }
    var interactiveView = sceneMain$getInteractiveView($player, $playerDirection, $grid, $gridSize, $entitiesBvh);
    var tmp_5;
    if (!(interactiveView == null)) {
      interactiveView.m59(Colors_getInstance().r59('#ffbec3'));
      $lastInteractiveView._v = interactiveView;
      tmp_5 = Unit_instance;
    } else {
      tmp_5 = Unit_instance;
    }
    return Unit_instance;
  };
}
function DungeonScene$sceneMain$lambda_2($showAnnotations) {
  return function () {
    $showAnnotations._v = !$showAnnotations._v;
    return Unit_instance;
  };
}
function DungeonScene$sceneMain$lambda_3($player, $playerState, $OpenedChest, this$0, $playerDirection, $grid, $gridSize, $entitiesBvh) {
  return function () {
    var tmp = $player.mkv_1;
    var playerView = tmp instanceof ImageDataView2 ? tmp : THROW_CCE();
    $playerState._v = 'attack';
    sceneMain$_anonymous_$onAnyButton_xxvbs9($player, $playerDirection, $grid, $gridSize, $entitiesBvh, $OpenedChest, this$0);
    return Unit_instance;
  };
}
function DungeonScene$sceneMain$lambda_4($player, $playerState, $OpenedChest, this$0, $playerDirection, $grid, $gridSize, $entitiesBvh) {
  return function () {
    var tmp = $player.mkv_1;
    var playerView = tmp instanceof ImageDataView2 ? tmp : THROW_CCE();
    $playerState._v = 'gesture';
    sceneMain$_anonymous_$onAnyButton_xxvbs9($player, $playerDirection, $grid, $gridSize, $entitiesBvh, $OpenedChest, this$0);
    return Unit_instance;
  };
}
function DungeonScene$sceneMain$doRay$lambda($this$raycast, it) {
  return sceneMain$check($this$raycast, it);
}
function _interpolate$ref() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_0() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function DungeonScene$sceneMain$_anonymous_$onAnyButton$slambda_aqssrx_0($this$0, $items) {
  var i = new DungeonScene$sceneMain$_anonymous_$onAnyButton$slambda_aqssrx($this$0, $items);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function updatedDataAnimation($this) {
  var tmp = $this.oq6();
  var tmp_0;
  if (!($this.tq5_1 == null)) {
    var tmp32_safe_receiver = $this.sq5_1;
    var tmp33_safe_receiver = tmp32_safe_receiver == null ? null : tmp32_safe_receiver.u6l_1;
    var tmp_1;
    if (tmp33_safe_receiver == null) {
      tmp_1 = null;
    } else {
      // Inline function 'kotlin.collections.get' call
      var key = $this.tq5_1;
      tmp_1 = (isInterface(tmp33_safe_receiver, KtMap) ? tmp33_safe_receiver : THROW_CCE()).k3(key);
    }
    tmp_0 = tmp_1;
  } else {
    var tmp34_safe_receiver = $this.sq5_1;
    tmp_0 = tmp34_safe_receiver == null ? null : tmp34_safe_receiver.t6l_1;
  }
  tmp.oq9(tmp_0);
}
function ImageDataView2$createAnimationView$lambda() {
  // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
  var tmp$ret$0 = get_Bitmaps_transparent();
  return Image.rd8(tmp$ret$0);
}
function anchorPixel(_this__u8e3s4, point) {
  _init_properties_DungeonScene_kt__hmbzam();
  _this__u8e3s4.rq9(point);
  return _this__u8e3s4;
}
function _get_computedDirection__2w15cx($this) {
  var tmp0_elvis_lhs = $this.dq9_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var tmp35_safe_receiver = $this.cq9_1;
    tmp = tmp35_safe_receiver == null ? null : tmp35_safe_receiver.c6l_1;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var tmp1_elvis_lhs = tmp;
  return tmp1_elvis_lhs == null ? Direction_FORWARD_getInstance() : tmp1_elvis_lhs;
}
function setFrame($this, frameIndex) {
  $this.iq9_1 = frameIndex;
  var tmp;
  var tmp38_safe_receiver = $this.cq9_1;
  var tmp39_safe_receiver = tmp38_safe_receiver == null ? null : tmp38_safe_receiver.b6l_1;
  var tmp_0;
  if (tmp39_safe_receiver == null) {
    tmp_0 = null;
  } else {
    // Inline function 'kotlin.collections.isNotEmpty' call
    tmp_0 = !tmp39_safe_receiver.a1();
  }
  if (tmp_0 === true) {
    var tmp36_safe_receiver = $this.cq9_1;
    var tmp37_safe_receiver = tmp36_safe_receiver == null ? null : tmp36_safe_receiver.b6l_1;
    tmp = tmp37_safe_receiver == null ? null : getCyclicOrNull(tmp37_safe_receiver, frameIndex);
  } else {
    tmp = null;
  }
  var frame = tmp;
  if (!(frame == null)) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = frame.t6j_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'dungeon.ImageAnimationView2.setFrame.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var it = this_0.i1(_unary__edvuaz);
      var image = $this.fq9_1.i1(it.x6j_1.n6j_1);
      if (it.x6j_1.p6j_1.v3_1 === 0) {
        (isInterface(image, SmoothedBmpSlice) ? image : THROW_CCE()).lfy(it.g6k_1);
      } else {
        if (!(image instanceof TileMap))
          THROW_CCE();
        var tilemap = it.c6k_1;
        if (tilemap == null) {
          var tmp_1 = Companion_instance_3;
          // Inline function 'korlibs.datastructure.Companion.invoke' call
          var tmp_2 = 0;
          var tmp_3 = imul(1, 1);
          var tmp_4 = new Int32Array(tmp_3);
          while (tmp_2 < tmp_3) {
            tmp_4[tmp_2] = 0;
            tmp_2 = tmp_2 + 1 | 0;
          }
          var tmp_5 = tmp_4;
          var tmp$ret$2 = new IntArray2(1, 1, isIntArray(tmp_5) ? tmp_5 : THROW_CCE());
          image.ohr(tmp_1.t3b([tmp$ret$2]));
          image.phr(Companion_getInstance_3().s70_1);
        } else {
          image.ohq_1 = TileMapData.b71(tilemap.u70_1);
          var tmp0_elvis_lhs = tilemap.v70_1;
          image.phr(tmp0_elvis_lhs == null ? Companion_getInstance_3().s70_1 : tmp0_elvis_lhs);
        }
      }
      // Inline function 'korlibs.korge.view.xy' call
      var x = it.y6j_1;
      var y = it.z6j_1;
      xy(image, numberToDouble(x), numberToDouble(y));
    }
    $this.hq9_1 = frame.e6n();
    var tmp_6 = $this;
    var tmp_7;
    switch (_get_computedDirection__2w15cx($this).v3_1) {
      case 2:
        tmp_7 = 1;
        break;
      case 3:
        tmp_7 = -1;
        break;
      case 4:
        var tmp_8;
        var containsUpper = $this.yq8_1;
        var containsArg = frameIndex + $this.kq9_1 | 0;
        if (!(0 <= containsArg ? containsArg < containsUpper : false)) {
          tmp_8 = -$this.kq9_1 | 0;
        } else {
          tmp_8 = $this.kq9_1;
        }

        tmp_7 = tmp_8;
        break;
      case 0:
        tmp_7 = frameIndex < ($this.yq8_1 - 1 | 0) ? 1 : 0;
        break;
      case 1:
        tmp_7 = frameIndex === 0 ? 0 : -1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    tmp_6.kq9_1 = tmp_7;
    $this.jq9_1 = umod(frameIndex + $this.kq9_1 | 0, $this.yq8_1);
  } else {
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = $this.fq9_1.e14_1;
    var n_0 = 0;
    while (n_0 < array.length) {
      // Inline function 'dungeon.ImageAnimationView2.setFrame.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.get' call
      var _unary__edvuaz_0 = n_0;
      n_0 = _unary__edvuaz_0 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it_0 = array[_unary__edvuaz_0];
      if (isInterface(it_0, SmoothedBmpSlice)) {
        // Inline function 'korlibs.image.bitmap.Bitmaps.transparent' call
        var tmp$ret$11 = get_Bitmaps_transparent();
        it_0.lfy(tmp$ret$11);
      }
    }
  }
}
function setFirstFrame($this) {
  if (_get_computedDirection__2w15cx($this).equals(Direction_REVERSE_getInstance()) || _get_computedDirection__2w15cx($this).equals(Direction_ONCE_REVERSE_getInstance())) {
    setFrame($this, $this.yq8_1 - 1 | 0);
  } else {
    setFrame($this, 0);
  }
}
function didSetAnimation($this) {
  var tmp = $this;
  var tmp42_safe_receiver = $this.cq9_1;
  var tmp43_safe_receiver = tmp42_safe_receiver == null ? null : tmp42_safe_receiver.b6l_1;
  var tmp0_elvis_lhs = tmp43_safe_receiver == null ? null : tmp43_safe_receiver.j1();
  tmp.yq8_1 = tmp0_elvis_lhs == null ? 1 : tmp0_elvis_lhs;
  var _iterator__ex2g4s = $this.fq9_1.f1();
  while (_iterator__ex2g4s.g1()) {
    var layer = _iterator__ex2g4s.h1();
    if (layer instanceof TileMap) {
      var tmp44_safe_receiver = $this.bq9_1;
      if (tmp44_safe_receiver == null)
        null;
      else
        tmp44_safe_receiver(layer);
    } else {
      var tmp45_safe_receiver = $this.aq9_1;
      if (tmp45_safe_receiver == null)
        null;
      else {
        tmp45_safe_receiver(isInterface(layer, SmoothedBmpSlice) ? layer : THROW_CCE());
      }
    }
  }
  $this.fq9_1.o3();
  $this.eq9_1.nca();
  $this.kq9_1 = 1;
  var animation = $this.cq9_1;
  if (!(animation == null)) {
    var _iterator__ex2g4s_0 = animation.e6l_1.f1();
    while (_iterator__ex2g4s_0.g1()) {
      var layer_0 = _iterator__ex2g4s_0.h1();
      var tmp_0;
      switch (layer_0.p6j_1.v3_1) {
        case 0:
          // Inline function 'kotlin.also' call

          var this_0 = $this.xq8_1();
          // Inline function 'kotlin.contracts.contract' call

          // Inline function 'dungeon.ImageAnimationView2.didSetAnimation.<anonymous>' call

          this_0.ch4($this.mq9_1);
          var tmp_1 = this_0;
          tmp_0 = tmp_1 instanceof View ? tmp_1 : THROW_CCE();
          break;
        case 2:
          tmp_0 = $this.tq9();
          break;
        case 1:
          throw NotImplementedError.oe();
        default:
          noWhenBranchMatchedException();
          break;
      }
      var image = tmp_0;
      $this.fq9_1.f(image);
      // Inline function 'korlibs.datastructure.set' call
      var this_1 = $this.gq9_1;
      var tmp1_elvis_lhs = layer_0.o6j_1;
      var key = tmp1_elvis_lhs == null ? 'default' : tmp1_elvis_lhs;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.js.asDynamic' call
      this_1.set(key, image);
      $this.eq9_1.oca(image instanceof View ? image : THROW_CCE());
    }
  }
  setFirstFrame($this);
}
function ImageAnimationView2$lambda($this$addUpdater, it) {
  if ($this$addUpdater.nq9_1) {
    $this$addUpdater.hq9_1 = Duration__minus_impl_q5cfm7($this$addUpdater.hq9_1, it.hk_1);
    var tmp = $this$addUpdater.hq9_1;
    // Inline function 'korlibs.time.milliseconds' call
    var tmp$ret$0 = toDuration(0.0, DurationUnit_MILLISECONDS_getInstance());
    if (Duration__compareTo_impl_pchp0f(tmp, tmp$ret$0) <= 0) {
      setFrame($this$addUpdater, $this$addUpdater.jq9_1);
      if ($this$addUpdater.kq9_1 === 0) {
        $this$addUpdater.nq9_1 = false;
        var tmp47_safe_receiver = $this$addUpdater.zq8_1;
        if (tmp47_safe_receiver == null)
          null;
        else
          tmp47_safe_receiver();
      }
    }
  }
  return Unit_instance;
}
function view$delegate$lambda() {
  _init_properties_DungeonScene_kt__hmbzam();
  return null;
}
function blockedResults$delegate$lambda() {
  _init_properties_DungeonScene_kt__hmbzam();
  return null;
}
function view$factory() {
  return getPropertyCallableRef('view', 1, KMutableProperty1, function (receiver) {
    return get_view(receiver);
  }, function (receiver, value) {
    return set_view_0(receiver, value);
  });
}
function view$factory_0() {
  return getPropertyCallableRef('view', 1, KMutableProperty1, function (receiver) {
    return get_view(receiver);
  }, function (receiver, value) {
    return set_view_0(receiver, value);
  });
}
function blockedResults$factory() {
  return getPropertyCallableRef('blockedResults', 1, KMutableProperty1, function (receiver) {
    return get_blockedResults(receiver);
  }, function (receiver, value) {
    return set_blockedResults(receiver, value);
  });
}
function blockedResults$factory_0() {
  return getPropertyCallableRef('blockedResults', 1, KMutableProperty1, function (receiver) {
    return get_blockedResults(receiver);
  }, function (receiver, value) {
    return set_blockedResults(receiver, value);
  });
}
function click$factory_12() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function alpha$factory($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function alpha$factory_0($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function anchorPixel$factory($b0) {
  return getPropertyCallableRef('anchorPixel', 0, KMutableProperty0, function () {
    return $b0.sq9();
  }, function (value) {
    return $b0.rq9(value);
  });
}
function anchor$factory($b0) {
  return getPropertyCallableRef('anchor', 0, KMutableProperty0, function () {
    return $b0.uf4();
  }, function (value) {
    return $b0.tf4(value);
  });
}
function anchorPixel$factory_0() {
  return getPropertyCallableRef('anchorPixel', 1, KMutableProperty1, function (receiver) {
    return receiver.sq9();
  }, function (receiver, value) {
    return receiver.rq9(value);
  });
}
function anchorPixel$factory_1() {
  return getPropertyCallableRef('anchorPixel', 1, KMutableProperty1, function (receiver) {
    return receiver.sq9();
  }, function (receiver, value) {
    return receiver.rq9(value);
  });
}
function anchor$factory_0() {
  return getPropertyCallableRef('anchor', 1, KMutableProperty1, function (receiver) {
    return receiver.uf4();
  }, function (receiver, value) {
    return receiver.tf4(value);
  });
}
function anchor$factory_1() {
  return getPropertyCallableRef('anchor', 1, KMutableProperty1, function (receiver) {
    return receiver.uf4();
  }, function (receiver, value) {
    return receiver.tf4(value);
  });
}
var properties_initialized_DungeonScene_kt_m5puk4;
function _init_properties_DungeonScene_kt__hmbzam() {
  if (!properties_initialized_DungeonScene_kt_m5puk4) {
    properties_initialized_DungeonScene_kt_m5puk4 = true;
    view$delegate = new Property(VOID, view$delegate$lambda);
    blockedResults$delegate = new Property(VOID, blockedResults$delegate$lambda);
  }
}
function set___mask(_this__u8e3s4, _set____db54di) {
  _init_properties_maskEx_kt__8wvpfl();
  var this_0 = __mask$delegate;
  var property = __mask$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get___mask(_this__u8e3s4) {
  _init_properties_maskEx_kt__8wvpfl();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = __mask$delegate;
    var property = __mask$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var __mask$delegate;
function set___maskFiltering(_this__u8e3s4, _set____db54di) {
  _init_properties_maskEx_kt__8wvpfl();
  var this_0 = __maskFiltering$delegate;
  var property = __maskFiltering$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get___maskFiltering(_this__u8e3s4) {
  _init_properties_maskEx_kt__8wvpfl();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = __maskFiltering$delegate;
    var property = __maskFiltering$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var __maskFiltering$delegate;
function mask2(_this__u8e3s4, mask, filtering) {
  filtering = filtering === VOID ? true : filtering;
  _init_properties_maskEx_kt__8wvpfl();
  set_mask2(_this__u8e3s4, mask);
  set_mask2Filtering(_this__u8e3s4, filtering);
  return _this__u8e3s4;
}
function set_mask2Filtering(_this__u8e3s4, value) {
  _init_properties_maskEx_kt__8wvpfl();
  set___maskFiltering(_this__u8e3s4, value);
  updatedMask(_this__u8e3s4);
}
function set_mask2(_this__u8e3s4, value) {
  _init_properties_maskEx_kt__8wvpfl();
  set___mask(_this__u8e3s4, value);
  updatedMask(_this__u8e3s4);
}
function updatedMask(_this__u8e3s4) {
  _init_properties_maskEx_kt__8wvpfl();
  var value = get___mask(_this__u8e3s4);
  // Inline function 'korlibs.korge.view.View.removeRenderPhaseOfType' call
  var tmp356_safe_receiver = _this__u8e3s4.hbb_1;
  if (tmp356_safe_receiver == null)
    null;
  else {
    removeAll(tmp356_safe_receiver, updatedMask$lambda);
  }
  if (!(value == null)) {
    _this__u8e3s4.bcd(new ViewRenderPhaseMask(value, get___maskFiltering(_this__u8e3s4)));
  }
}
var Companion_instance_6;
function Companion_getInstance_27() {
  return Companion_instance_6;
}
function __mask$delegate$lambda() {
  _init_properties_maskEx_kt__8wvpfl();
  return null;
}
function __maskFiltering$delegate$lambda() {
  _init_properties_maskEx_kt__8wvpfl();
  return true;
}
function updatedMask$lambda(it) {
  _init_properties_maskEx_kt__8wvpfl();
  return it instanceof ViewRenderPhaseMask;
}
function __mask$factory() {
  return getPropertyCallableRef('__mask', 1, KMutableProperty1, function (receiver) {
    return get___mask(receiver);
  }, function (receiver, value) {
    return set___mask(receiver, value);
  });
}
function __mask$factory_0() {
  return getPropertyCallableRef('__mask', 1, KMutableProperty1, function (receiver) {
    return get___mask(receiver);
  }, function (receiver, value) {
    return set___mask(receiver, value);
  });
}
function __maskFiltering$factory() {
  return getPropertyCallableRef('__maskFiltering', 1, KMutableProperty1, function (receiver) {
    return get___maskFiltering(receiver);
  }, function (receiver, value) {
    return set___maskFiltering(receiver, value);
  });
}
function __maskFiltering$factory_0() {
  return getPropertyCallableRef('__maskFiltering', 1, KMutableProperty1, function (receiver) {
    return get___maskFiltering(receiver);
  }, function (receiver, value) {
    return set___maskFiltering(receiver, value);
  });
}
var properties_initialized_maskEx_kt_1r664f;
function _init_properties_maskEx_kt__8wvpfl() {
  if (!properties_initialized_maskEx_kt_1r664f) {
    properties_initialized_maskEx_kt_1r664f = true;
    // Inline function 'korlibs.datastructure.extraProperty' call
    var default_0 = __mask$delegate$lambda;
    __mask$delegate = new Property(null, default_0);
    // Inline function 'korlibs.datastructure.extraProperty' call
    var default_1 = __maskFiltering$delegate$lambda;
    __maskFiltering$delegate = new Property(null, default_1);
  }
}
function sq(v) {
  return v * v;
}
function signumNonZero(v) {
  return v < 0 ? -1.0 : +1.0;
}
function firstCollisionInTileMap(_this__u8e3s4, cellSize, maxTiles, collides) {
  cellSize = cellSize === VOID ? Size2D.f2j(1, 1) : cellSize;
  maxTiles = maxTiles === VOID ? 10 : maxTiles;
  var ray = _this__u8e3s4;
  var rayStart = div(_this__u8e3s4.l2s_1, cellSize);
  var rayDir = ray.m2s_1.q2s();
  // Inline function 'kotlin.math.sqrt' call
  var x = 1.0 + sq(rayDir.j2h_1 / rayDir.i2h_1);
  var tmp = Math.sqrt(x);
  // Inline function 'kotlin.math.sqrt' call
  var x_0 = 1.0 + sq(rayDir.i2h_1 / rayDir.j2h_1);
  var tmp$ret$1 = Math.sqrt(x_0);
  var rayUnitStepSize = Vector2D.k2h(tmp, tmp$ret$1);
  var mapCheckX = numberToInt(rayStart.i2h_1);
  var mapCheckY = numberToInt(rayStart.j2h_1);
  var stepX = numberToInt(signumNonZero(rayDir.i2h_1));
  var stepY = numberToInt(signumNonZero(rayDir.j2h_1));
  var rayLength1Dx = rayDir.i2h_1 < 0 ? (rayStart.i2h_1 - mapCheckX) * rayUnitStepSize.i2h_1 : ((mapCheckX + 1 | 0) - rayStart.i2h_1) * rayUnitStepSize.i2h_1;
  var rayLength1Dy = rayDir.j2h_1 < 0 ? (rayStart.j2h_1 - mapCheckY) * rayUnitStepSize.j2h_1 : ((mapCheckY + 1 | 0) - rayStart.j2h_1) * rayUnitStepSize.j2h_1;
  var bTileFound = false;
  // Inline function 'kotlin.math.hypot' call
  var x_1 = cellSize.d2j_1;
  var y = cellSize.e2j_1;
  var fMaxDistance = hypot(x_1, y) * maxTiles;
  var fDistance = 0.0;
  var dx = 0;
  $l$loop: while (fDistance < fMaxDistance) {
    if (rayLength1Dx < rayLength1Dy) {
      mapCheckX = mapCheckX + stepX | 0;
      fDistance = rayLength1Dx;
      rayLength1Dx = rayLength1Dx + rayUnitStepSize.i2h_1;
      dx = 0;
    } else {
      mapCheckY = mapCheckY + stepY | 0;
      fDistance = rayLength1Dy;
      rayLength1Dy = rayLength1Dy + rayUnitStepSize.j2h_1;
      dx = 1;
    }
    if (collides(Vector2I.v2u(mapCheckX, mapCheckY))) {
      bTileFound = true;
      break $l$loop;
    }
  }
  if (bTileFound) {
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale = fDistance;
    var that = Vector2D.k2h(rayDir.i2h_1 * scale, rayDir.j2h_1 * scale);
    var tmp$ret$4 = Vector2D.k2h(rayStart.i2h_1 + that.i2h_1, rayStart.j2h_1 + that.j2h_1);
    var tmp_0 = times(tmp$ret$4, cellSize);
    var tmp_1;
    if (dx === 0) {
      var tmp_2 = -1.0;
      // Inline function 'kotlin.math.sign' call
      var this_0 = rayDir.i2h_1;
      var tmp$ret$5 = sign(this_0);
      tmp_1 = Vector2D.k2h(tmp_2 * tmp$ret$5, 0.0);
    } else {
      var tmp_3 = -1.0;
      // Inline function 'kotlin.math.sign' call
      var this_1 = rayDir.j2h_1;
      var tmp$ret$6 = sign(this_1);
      tmp_1 = Vector2D.k2h(0.0, tmp_3 * tmp$ret$6);
    }
    return new RayResult(_this__u8e3s4, tmp_0, tmp_1);
  }
  return null;
}
function raycast(_this__u8e3s4, ray, cellSize, maxTiles, collides) {
  cellSize = cellSize === VOID ? Size2D.f2t(1.0, 1.0) : cellSize;
  maxTiles = maxTiles === VOID ? 10 : maxTiles;
  return firstCollisionInTileMap(ray, cellSize, maxTiles, raycast$lambda(collides, _this__u8e3s4));
}
function raycast$lambda($collides, $this_raycast) {
  return function (pos) {
    return $collides($this_raycast, pos);
  };
}
function *_generator_invoke__zhh2q8_16($this, $completion) {
  var tmp = $this.xq9_1($this.yq9_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_17($this, $completion) {
  var tmp = $this.zq9_1($this.aqa_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_18($this, it, $completion) {
  var tmp52_safe_receiver = $this.bqa_1._v;
  if (tmp52_safe_receiver == null)
    null;
  else {
    tmp52_safe_receiver.l16();
  }
  // Inline function 'korlibs.korge.tween.get' call
  var this_0 = x$factory($this.cqa_1);
  var end = 64.0 + 512.0;
  var tmp$ret$0 = new V2(this_0, 64.0, end, _interpolate$ref_1(), true);
  var tmp = tweenAsync($this.cqa_1, [tmp$ret$0], VOID, $this.dqa_1, VOID, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  $this.bqa_1._v = tmp;
  return Unit_instance;
}
function _interpolate$ref_1() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function *_generator_invoke__zhh2q8_19($this, $completion) {
  var tmp = $this.eqa_1($this.fqa_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function sceneMain$renderEasing(ballTween, ball, easing) {
  // Inline function 'kotlin.apply' call
  var this_0 = Container.vbw();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'easings.EasingsScene.sceneMain.renderEasing.<anonymous>' call
  // Inline function 'korlibs.korge.view.solidRect' call
  var color = RGBA__withAd_impl_cralao(Colors_getInstance().h4r_1, 0.2);
  // Inline function 'korlibs.korge.view.addTo' call
  var this_1 = SolidRect.ddj(Size2D.f2j(64, -64), color);
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  var bg = addTo(this_1, this_0);
  // Inline function 'korlibs.korge.view.graphics' call
  var renderer = GraphicsRenderer_SYSTEM_getInstance();
  // Inline function 'kotlin.also' call
  var this_2 = addTo(Graphics.zh3(EmptyShape_instance, renderer), this_0);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape' call
  var result;
  // Inline function 'korlibs.image.vector.buildShape' call
  // Inline function 'kotlin.apply' call
  var this_3 = new ShapeBuilder(null, null);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
  // Inline function 'easings.EasingsScene.sceneMain.renderEasing.<anonymous>.<anonymous>' call
  $l$block: {
    // Inline function 'korlibs.image.vector.Context2d.stroke' call
    var paint = new RGBA(Colors_getInstance().i4r_1);
    var info = Companion_instance_2.o4c(4.0);
    if (paint == null || info == null) {
      break $l$block;
    }
    // Inline function 'korlibs.image.vector.Context2d.stroke' call
    var lineWidth = info.n3s_1;
    var lineCap = info.q3s_1;
    var lineJoin = info.s3s_1;
    var miterLimit = info.t3s_1;
    var lineDash = info.u3s_1;
    var lineDashOffset = info.v3s_1;
    if (true) {
      this_3.b5t();
    }
    // Inline function 'easings.EasingsScene.sceneMain.renderEasing.<anonymous>.<anonymous>.<anonymous>' call
    this_3.l4a(Vector2D.k2l(0, 0), Vector2D.k2h(0.0, -64.0));
    this_3.l4a(Vector2D.k2l(0, 0), Vector2D.k2h(64.0, 0.0));
    // Inline function 'korlibs.image.vector.Context2d.keep' call
    this_3.u5u();
    try {
      // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
      this_3.v5u(numberToDouble(lineWidth));
      this_3.w5u(lineCap);
      this_3.x5u(lineJoin);
      this_3.y5u(numberToDouble(miterLimit));
      this_3.z5u(lineDash);
      this_3.a5v(numberToDouble(lineDashOffset));
      this_3.b5v(paint);
    }finally {
      this_3.c5v();
    }
  }
  $l$block_0: {
    // Inline function 'korlibs.image.vector.Context2d.stroke' call
    var paint_0 = new RGBA(Colors_getInstance().g4r_1);
    var info_0 = Companion_instance_2.o4c(2.0);
    if (paint_0 == null || info_0 == null) {
      break $l$block_0;
    }
    // Inline function 'korlibs.image.vector.Context2d.stroke' call
    var lineWidth_0 = info_0.n3s_1;
    var lineCap_0 = info_0.q3s_1;
    var lineJoin_0 = info_0.s3s_1;
    var miterLimit_0 = info_0.t3s_1;
    var lineDash_0 = info_0.u3s_1;
    var lineDashOffset_0 = info_0.v3s_1;
    if (true) {
      this_3.b5t();
    }
    // Inline function 'easings.EasingsScene.sceneMain.renderEasing.<anonymous>.<anonymous>.<anonymous>' call
    var first = true;
    var overflow = 0;
    var inductionVariable = -overflow | 0;
    var last = 64 + overflow | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio = n / 64.0;
        var x = n;
        var y = easing.yz(ratio) * 64;
        if (first) {
          first = false;
          this_3.b48(x, -y);
        } else {
          this_3.c48(x, -y);
        }
      }
       while (!(n === last));
    // Inline function 'korlibs.image.vector.Context2d.keep' call
    this_3.u5u();
    try {
      // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
      this_3.v5u(numberToDouble(lineWidth_0));
      this_3.w5u(lineCap_0);
      this_3.x5u(lineJoin_0);
      this_3.y5u(numberToDouble(miterLimit_0));
      this_3.z5u(lineDash_0);
      this_3.a5v(numberToDouble(lineDashOffset_0));
      this_3.b5v(paint_0);
    }finally {
      this_3.c5v();
    }
  }
  result = Unit_instance;
  var tmp$ret$7 = this_3.p5r();
  this_2.xh3(tmp$ret$7);
  this_2.fh4();
  addTo(this_2, this_0);
  var textSize = 10.0;
  // Inline function 'korlibs.korge.view.text' call
  var text = toString(easing);
  var color_0 = Colors_getInstance().g4r_1;
  var font = get_DefaultTtfFontAsBitmap();
  var alignment = Companion_getInstance_2().h5s_1;
  var renderer_0 = get_DefaultStringTextRenderer();
  var autoScaling = Companion_instance_0.sfm_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_4 = Text.tfm(text, numberToDouble(textSize), color_0, font, alignment, renderer_0, autoScaling, null, null);
  // Inline function 'kotlin.apply' call
  var this_5 = addTo(this_4, this_0);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.text.<anonymous>' call
  xy(this_5, 0.0, textSize);
  // Inline function 'korlibs.korge.input.onOver' call
  var handler = EasingsScene$sceneMain$renderEasing$slambda_2(bg);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop = over$factory();
  var tmp73_safe_receiver = this_0 == null ? null : get_mouse(this_0);
  if (tmp73_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp = prop.get(tmp73_safe_receiver);
    tmp.v1x(EasingsScene$sceneMain$renderEasing$lambda(tmp73_safe_receiver, handler));
  }
  // Inline function 'korlibs.korge.input.onOut' call
  var handler_0 = EasingsScene$sceneMain$renderEasing$slambda_3(bg);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_0 = out$factory();
  var tmp73_safe_receiver_0 = this_0 == null ? null : get_mouse(this_0);
  if (tmp73_safe_receiver_0 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_0 = prop_0.get(tmp73_safe_receiver_0);
    tmp_0.v1x(EasingsScene$sceneMain$renderEasing$lambda_0(tmp73_safe_receiver_0, handler_0));
  }
  // Inline function 'korlibs.korge.input.onClick' call
  var handler_1 = EasingsScene$sceneMain$renderEasing$slambda_4(ballTween, ball, easing);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_1 = click$factory_13();
  var tmp73_safe_receiver_1 = this_0 == null ? null : get_mouse(this_0);
  if (tmp73_safe_receiver_1 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_1 = prop_1.get(tmp73_safe_receiver_1);
    tmp_1.v1x(EasingsScene$sceneMain$renderEasing$lambda_1(tmp73_safe_receiver_1, handler_1));
  }
  return this_0;
}
function EasingsScene$sceneMain$renderEasing$slambda_2($bg) {
  var i = new EasingsScene$sceneMain$renderEasing$slambda($bg);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function EasingsScene$sceneMain$renderEasing$lambda$slambda_2($handler, $it) {
  var i = new EasingsScene$sceneMain$renderEasing$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function EasingsScene$sceneMain$renderEasing$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, EasingsScene$sceneMain$renderEasing$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function EasingsScene$sceneMain$renderEasing$slambda_3($bg) {
  var i = new EasingsScene$sceneMain$renderEasing$slambda_0($bg);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function EasingsScene$sceneMain$renderEasing$lambda$slambda_3($handler, $it) {
  var i = new EasingsScene$sceneMain$renderEasing$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function EasingsScene$sceneMain$renderEasing$lambda_0($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, EasingsScene$sceneMain$renderEasing$lambda$slambda_3($handler, it));
    return Unit_instance;
  };
}
function EasingsScene$sceneMain$renderEasing$slambda_4($ballTween, $ball, $easing) {
  var i = new EasingsScene$sceneMain$renderEasing$slambda_1($ballTween, $ball, $easing);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function EasingsScene$sceneMain$renderEasing$lambda$slambda_4($handler, $it) {
  var i = new EasingsScene$sceneMain$renderEasing$lambda$slambda_1($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function EasingsScene$sceneMain$renderEasing$lambda_1($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, EasingsScene$sceneMain$renderEasing$lambda$slambda_4($handler, it));
    return Unit_instance;
  };
}
function over$factory() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
function out$factory() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
function click$factory_13() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function x$factory($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function get_registeredScenes(_this__u8e3s4) {
  _init_properties_Ext_kt__73a38v();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = registeredScenes$delegate;
    var property = registeredScenes$factory();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var registeredScenes$delegate;
function register(_this__u8e3s4, sceneInfos) {
  _init_properties_Ext_kt__73a38v();
  var inductionVariable = 0;
  var last = sceneInfos.length;
  while (inductionVariable < last) {
    var sceneInfo = sceneInfos[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    var tmp = _this__u8e3s4.jbu();
    var tmp_0 = sceneInfo.bqb_1;
    var tmp_1 = isInterface(tmp_0, KClass) ? tmp_0 : THROW_CCE();
    tmp.s87(tmp_1, register$lambda(sceneInfo));
    // Inline function 'kotlin.collections.set' call
    var this_0 = get_registeredScenes(_this__u8e3s4);
    var key = sceneInfo.dqb();
    this_0.r3(key, sceneInfo);
  }
}
function *_generator_sceneMain__5xvlzl_1(_this__u8e3s4, $this, $completion) {
  makeFocusable(_this__u8e3s4, VOID, AutoShowScene$sceneMain$lambda);
  $this.eq1(_this__u8e3s4);
  try {
    var tmp = $this.hq0(_this__u8e3s4, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }finally {
    $this.fq1();
  }
  return Unit_instance;
}
function AutoShowScene$sceneMain$lambda(it) {
  return Unit_instance;
}
function ShowScene$showSpinner$lambda($this$addUpdater, it) {
  $this$addUpdater.acc(Angle__plus_impl_26v37k($this$addUpdater.bcc(), get_degrees_0(get_seconds(it.hk_1) * 100)));
  return Unit_instance;
}
function registeredScenes$delegate$lambda() {
  _init_properties_Ext_kt__73a38v();
  return LinkedHashMap.vc();
}
function register$lambda($sceneInfo) {
  return function ($this$mapPrototype) {
    return $sceneInfo.cqb_1($this$mapPrototype);
  };
}
function registeredScenes$factory() {
  return getPropertyCallableRef('registeredScenes', 1, KProperty1, function (receiver) {
    return get_registeredScenes(receiver);
  }, null);
}
var properties_initialized_Ext_kt_mb8swf;
function _init_properties_Ext_kt__73a38v() {
  if (!properties_initialized_Ext_kt_mb8swf) {
    properties_initialized_Ext_kt_mb8swf = true;
    // Inline function 'korlibs.datastructure.extraProperty' call
    var default_0 = registeredScenes$delegate$lambda;
    registeredScenes$delegate = new Property(null, default_0);
  }
}
function *_generator_sceneMain__5xvlzl_2(_this__u8e3s4, $this, $completion) {
  var tmp = readBitmap(get_resourcesVfs().bj('korge.png'), VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var bitmap = tmp;
  var wave = new WaveFilter(Vector2D.k2l(20, 16), Vector2D.k2l(256, 256));
  // Inline function 'korlibs.korge.view.image' call
  var anchor = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Image.ph4(bitmap, anchor);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'filters.FiltersScene.sceneMain.<anonymous>' call
  scale(this_1, 0.5);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_1, numberToDouble(0), numberToDouble(0));
  set_filter(this_1, wave);
  var blur = BlurFilter.chf();
  // Inline function 'korlibs.korge.view.image' call
  var anchor_0 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_2 = Image.ph4(bitmap, anchor_0);
  // Inline function 'kotlin.apply' call
  var this_3 = addTo(this_2, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'filters.FiltersScene.sceneMain.<anonymous>' call
  scale(this_3, 0.5);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_3, numberToDouble(256), numberToDouble(0));
  set_filter(this_3, blur);
  var color = new TransitionFilter(Companion_getInstance_6().chm(), false, VOID, VOID, true);
  // Inline function 'korlibs.korge.view.image' call
  var anchor_1 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_4 = Image.ph4(bitmap, anchor_1);
  // Inline function 'kotlin.apply' call
  var this_5 = addTo(this_4, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'filters.FiltersScene.sceneMain.<anonymous>' call
  scale(this_5, 0.5);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_5, numberToDouble(512), numberToDouble(0));
  set_filter(this_5, color);
  var page = new PageFilter();
  // Inline function 'korlibs.korge.view.image' call
  var anchor_2 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_6 = Image.ph4(bitmap, anchor_2);
  // Inline function 'kotlin.apply' call
  var this_7 = addTo(this_6, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'filters.FiltersScene.sceneMain.<anonymous>' call
  scale(this_7, 0.5);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_7, numberToDouble(0), numberToDouble(256));
  set_filter(this_7, page);
  // Inline function 'korlibs.korge.view.image' call
  var anchor_3 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_8 = Image.ph4(bitmap, anchor_3);
  // Inline function 'kotlin.apply' call
  var this_9 = addTo(this_8, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'filters.FiltersScene.sceneMain.<anonymous>' call
  scale(this_9, 0.5);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_9, numberToDouble(256), numberToDouble(256));
  set_filter(this_9, new Convolute3Filter(Companion_getInstance_7().khh_1));
  var conImg = this_9;
  var swizzle = new SwizzleColorsFilter();
  // Inline function 'korlibs.korge.view.image' call
  var anchor_4 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_10 = Image.ph4(bitmap, anchor_4);
  // Inline function 'kotlin.apply' call
  var this_11 = addTo(this_10, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'filters.FiltersScene.sceneMain.<anonymous>' call
  scale(this_11, 0.5);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_11, numberToDouble(512), numberToDouble(256));
  set_filter(this_11, swizzle);
  var tmp_0 = animate(_this__u8e3s4, VOID, VOID, VOID, true, VOID, VOID, VOID, FiltersScene$sceneMain$lambda(wave, blur, color, page, conImg, swizzle), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function _interpolateDuration$ref() {
  var l = function (p0, p1, p2) {
    return new Duration(_interpolateDuration(p0.e10_1, p1.hk_1, p2.hk_1));
  };
  l.callableName = '_interpolateDuration';
  return l;
}
function _interpolateDuration$ref_0() {
  var l = function (p0, p1, p2) {
    return new Duration(_interpolateDuration(p0.e10_1, p1.hk_1, p2.hk_1));
  };
  l.callableName = '_interpolateDuration';
  return l;
}
function _interpolate$ref_2() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_3() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolateRatio$ref() {
  var l = function (p0, p1, p2) {
    return new Ratio(_interpolateRatio(p0.e10_1, p1.e10_1, p2.e10_1));
  };
  l.callableName = '_interpolateRatio';
  return l;
}
function _interpolateRatio$ref_0() {
  var l = function (p0, p1, p2) {
    return new Ratio(_interpolateRatio(p0.e10_1, p1.e10_1, p2.e10_1));
  };
  l.callableName = '_interpolateRatio';
  return l;
}
function _interpolateRatio$ref_1() {
  var l = function (p0, p1, p2) {
    return new Ratio(_interpolateRatio(p0.e10_1, p1.e10_1, p2.e10_1));
  };
  l.callableName = '_interpolateRatio';
  return l;
}
function _interpolateRatio$ref_2() {
  var l = function (p0, p1, p2) {
    return new Ratio(_interpolateRatio(p0.e10_1, p1.e10_1, p2.e10_1));
  };
  l.callableName = '_interpolateRatio';
  return l;
}
function FiltersScene$sceneMain$lambda$lambda($conImg) {
  return function () {
    set_filter($conImg, new Convolute3Filter(Companion_getInstance_7().khh_1));
    return Unit_instance;
  };
}
function FiltersScene$sceneMain$lambda$lambda_0($conImg) {
  return function () {
    set_filter($conImg, new Convolute3Filter(Companion_getInstance_7().ihh_1));
    return Unit_instance;
  };
}
function FiltersScene$sceneMain$lambda$lambda_1($swizzle, $it) {
  return function () {
    $swizzle.thl($it);
    return Unit_instance;
  };
}
function FiltersScene$sceneMain$lambda($wave, $blur, $color, $page, $conImg, $swizzle) {
  return function ($this$animate) {
    // Inline function 'korlibs.korge.animate.Animator.sequence' call
    var defaultTime = $this$animate.ibh();
    var defaultSpeed = $this$animate.abg_1;
    var easing = $this$animate.bbg_1;
    var startImmediately = $this$animate.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp10_root = $this$animate.ybf_1;
    var tmp11_fastDefaultTime = get_fast(defaultTime);
    var tmp12_level = $this$animate.gbg_1 + 1 | 0;
    var this_0 = new Animator(tmp10_root, tmp11_fastDefaultTime, defaultSpeed, easing, false, true, $this$animate, VOID, tmp12_level, startImmediately);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    // Inline function 'filters.FiltersScene.sceneMain.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.korge.tween.get' call
    var this_1 = time$factory($wave);
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var end = toDuration(1, DurationUnit_SECONDS_getInstance());
    var tmp = this_1.get();
    var tmp_0 = [new V2(this_1, tmp, new Duration(end), _interpolateDuration$ref(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$4 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_0, tmp_0, tmp$ret$4, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'korlibs.korge.tween.get' call
    var this_2 = time$factory_0($wave);
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var end_0 = toDuration(0, DurationUnit_SECONDS_getInstance());
    var tmp_1 = this_2.get();
    var tmp_2 = [new V2(this_2, tmp_1, new Duration(end_0), _interpolateDuration$ref_0(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$9 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_0, tmp_2, tmp$ret$9, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    $this$animate.kbh(this_0.obg_1);
    // Inline function 'korlibs.korge.animate.Animator.sequence' call
    var defaultTime_0 = $this$animate.ibh();
    var defaultSpeed_0 = $this$animate.abg_1;
    var easing_0 = $this$animate.bbg_1;
    var startImmediately_0 = $this$animate.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp10_root_0 = $this$animate.ybf_1;
    var tmp11_fastDefaultTime_0 = get_fast(defaultTime_0);
    var tmp12_level_0 = $this$animate.gbg_1 + 1 | 0;
    var this_3 = new Animator(tmp10_root_0, tmp11_fastDefaultTime_0, defaultSpeed_0, easing_0, false, true, $this$animate, VOID, tmp12_level_0, startImmediately_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    // Inline function 'filters.FiltersScene.sceneMain.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.korge.tween.get' call
    // Inline function 'korlibs.korge.tween.get' call
    var this_4 = radius$factory($blur);
    var tmp_3 = this_4.get();
    var tmp_4 = [new V2(this_4, tmp_3, 16, _interpolate$ref_2(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$16 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_3, tmp_4, tmp$ret$16, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'korlibs.korge.tween.get' call
    // Inline function 'korlibs.korge.tween.get' call
    var this_5 = radius$factory_0($blur);
    var tmp_5 = this_5.get();
    var tmp_6 = [new V2(this_5, tmp_5, 0, _interpolate$ref_3(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$20 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_3, tmp_6, tmp$ret$20, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    $this$animate.kbh(this_3.obg_1);
    // Inline function 'korlibs.korge.animate.Animator.sequence' call
    var defaultTime_1 = $this$animate.ibh();
    var defaultSpeed_1 = $this$animate.abg_1;
    var easing_1 = $this$animate.bbg_1;
    var startImmediately_1 = $this$animate.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp10_root_1 = $this$animate.ybf_1;
    var tmp11_fastDefaultTime_1 = get_fast(defaultTime_1);
    var tmp12_level_1 = $this$animate.gbg_1 + 1 | 0;
    var this_6 = new Animator(tmp10_root_1, tmp11_fastDefaultTime_1, defaultSpeed_1, easing_1, false, true, $this$animate, VOID, tmp12_level_1, startImmediately_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    // Inline function 'filters.FiltersScene.sceneMain.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.korge.tween.get' call
    var this_7 = ratio$factory($color);
    var end_1 = Companion_getInstance_20().f10_1;
    var tmp_7 = this_7.get();
    var tmp_8 = [new V2(this_7, tmp_7, new Ratio(end_1), _interpolateRatio$ref(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$26 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_6, tmp_8, tmp$ret$26, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'korlibs.korge.tween.get' call
    var this_8 = ratio$factory_0($color);
    var end_2 = Companion_getInstance_20().i10_1;
    var tmp_9 = this_8.get();
    var tmp_10 = [new V2(this_8, tmp_9, new Ratio(end_2), _interpolateRatio$ref_0(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$29 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_6, tmp_10, tmp$ret$29, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    $this$animate.kbh(this_6.obg_1);
    // Inline function 'korlibs.korge.animate.Animator.sequence' call
    var defaultTime_2 = $this$animate.ibh();
    var defaultSpeed_2 = $this$animate.abg_1;
    var easing_2 = $this$animate.bbg_1;
    var startImmediately_2 = $this$animate.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp10_root_2 = $this$animate.ybf_1;
    var tmp11_fastDefaultTime_2 = get_fast(defaultTime_2);
    var tmp12_level_2 = $this$animate.gbg_1 + 1 | 0;
    var this_9 = new Animator(tmp10_root_2, tmp11_fastDefaultTime_2, defaultSpeed_2, easing_2, false, true, $this$animate, VOID, tmp12_level_2, startImmediately_2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    // Inline function 'filters.FiltersScene.sceneMain.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.korge.tween.get' call
    var this_10 = hratio$factory($page);
    var end_3 = Companion_getInstance_20().f10_1;
    var tmp_11 = this_10.get();
    var tmp_12 = [new V2(this_10, tmp_11, new Ratio(end_3), _interpolateRatio$ref_1(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$35 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_9, tmp_12, tmp$ret$35, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'korlibs.korge.tween.get' call
    var this_11 = hratio$factory_0($page);
    var end_4 = Companion_getInstance_20().i10_1;
    var tmp_13 = this_11.get();
    var tmp_14 = [new V2(this_11, tmp_13, new Ratio(end_4), _interpolateRatio$ref_2(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$38 = toDuration(1, DurationUnit_SECONDS_getInstance());
    tween(this_9, tmp_14, tmp$ret$38, get_EASE_IN_OUT(Companion_getInstance_19()));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    $this$animate.kbh(this_9.obg_1);
    // Inline function 'korlibs.korge.animate.Animator.sequence' call
    var defaultTime_3 = $this$animate.ibh();
    var defaultSpeed_3 = $this$animate.abg_1;
    var easing_3 = $this$animate.bbg_1;
    var startImmediately_3 = $this$animate.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp10_root_3 = $this$animate.ybf_1;
    var tmp11_fastDefaultTime_3 = get_fast(defaultTime_3);
    var tmp12_level_3 = $this$animate.gbg_1 + 1 | 0;
    var this_12 = new Animator(tmp10_root_3, tmp11_fastDefaultTime_3, defaultSpeed_3, easing_3, false, true, $this$animate, VOID, tmp12_level_3, startImmediately_3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    // Inline function 'filters.FiltersScene.sceneMain.<anonymous>.<anonymous>' call
    block(this_12, VOID, FiltersScene$sceneMain$lambda$lambda($conImg));
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$43 = toDuration(1, DurationUnit_SECONDS_getInstance());
    wait(this_12, tmp$ret$43);
    block(this_12, VOID, FiltersScene$sceneMain$lambda$lambda_0($conImg));
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$45 = toDuration(1, DurationUnit_SECONDS_getInstance());
    wait(this_12, tmp$ret$45);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    $this$animate.kbh(this_12.obg_1);
    // Inline function 'korlibs.korge.animate.Animator.sequence' call
    var defaultTime_4 = $this$animate.ibh();
    var defaultSpeed_4 = $this$animate.abg_1;
    var easing_4 = $this$animate.bbg_1;
    var startImmediately_4 = $this$animate.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp10_root_4 = $this$animate.ybf_1;
    var tmp11_fastDefaultTime_4 = get_fast(defaultTime_4);
    var tmp12_level_4 = $this$animate.gbg_1 + 1 | 0;
    var this_13 = new Animator(tmp10_root_4, tmp11_fastDefaultTime_4, defaultSpeed_4, easing_4, false, true, $this$animate, VOID, tmp12_level_4, startImmediately_4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    // Inline function 'filters.FiltersScene.sceneMain.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var indexedObject = ['rgga', 'bgga', 'bgba', 'grba', 'gbba', 'gbga', 'bbga'];
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'filters.FiltersScene.sceneMain.<anonymous>.<anonymous>.<anonymous>' call
      block(this_13, VOID, FiltersScene$sceneMain$lambda$lambda_1($swizzle, element));
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$52 = toDuration(0.5, DurationUnit_SECONDS_getInstance());
      wait(this_13, tmp$ret$52);
    }
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.sequence.<anonymous>' call
    $this$animate.kbh(this_13.obg_1);
    return Unit_instance;
  };
}
function time$factory($b0) {
  return getPropertyCallableRef('time', 0, KMutableProperty0, function () {
    return new Duration($b0.a6n());
  }, function (value) {
    return $b0.iho(value.hk_1);
  });
}
function time$factory_0($b0) {
  return getPropertyCallableRef('time', 0, KMutableProperty0, function () {
    return new Duration($b0.a6n());
  }, function (value) {
    return $b0.iho(value.hk_1);
  });
}
function radius$factory($b0) {
  return getPropertyCallableRef('radius', 0, KMutableProperty0, function () {
    return $b0.bhf_1;
  }, function (value) {
    return $b0.ud8(value);
  });
}
function radius$factory_0($b0) {
  return getPropertyCallableRef('radius', 0, KMutableProperty0, function () {
    return $b0.bhf_1;
  }, function (value) {
    return $b0.ud8(value);
  });
}
function ratio$factory($b0) {
  return getPropertyCallableRef('ratio', 0, KMutableProperty0, function () {
    return new Ratio($b0.dhn_1);
  }, function (value) {
    $b0.dhn_1 = value.e10_1;
    return Unit_instance;
  });
}
function ratio$factory_0($b0) {
  return getPropertyCallableRef('ratio', 0, KMutableProperty0, function () {
    return new Ratio($b0.dhn_1);
  }, function (value) {
    $b0.dhn_1 = value.e10_1;
    return Unit_instance;
  });
}
function hratio$factory($b0) {
  return getPropertyCallableRef('hratio', 0, KMutableProperty0, function () {
    return new Ratio($b0.rhk_1);
  }, function (value) {
    $b0.rhk_1 = value.e10_1;
    return Unit_instance;
  });
}
function hratio$factory_0($b0) {
  return getPropertyCallableRef('hratio', 0, KMutableProperty0, function () {
    return new Ratio($b0.rhk_1);
  }, function (value) {
    $b0.rhk_1 = value.e10_1;
    return Unit_instance;
  });
}
function *_generator_sceneMain__5xvlzl_3(_this__u8e3s4, $this, $completion) {
  var minDegrees = get_degrees(-16);
  var maxDegrees = get_degrees(16);
  var image = {_v: null};
  // Inline function 'korlibs.korge.view.container' call
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Container.vbw(false);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'gestures.GesturesScene.sceneMain.<anonymous>' call
  // Inline function 'korlibs.korge.view.position' call
  xy(this_1, numberToDouble(256), numberToDouble(256));
  // Inline function 'korlibs.korge.view.image' call
  var tmp = readBitmap(get_resourcesVfs().bj('korge.png'), VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var texture = tmp;
  var anchor_0 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_2 = Image.ph4(texture, anchor_0);
  // Inline function 'kotlin.apply' call
  var this_3 = addTo(this_2, this_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'gestures.GesturesScene.sceneMain.<anonymous>.<anonymous>' call
  this_3.acc(maxDegrees);
  // Inline function 'korlibs.korge.view.anchor' call
  anchor(this_3, Anchor2D.g2h(numberToDouble(0.5), numberToDouble(0.5)));
  scale(this_3, 0.8);
  image._v = this_3;
  var container = this_1;
  // Inline function 'korlibs.korge.view.text' call
  var text = 'Zoom and rotate with two fingers';
  var textSize = Companion_instance_0.rfm_1;
  var color = Colors_getInstance().g4r_1;
  var font = get_DefaultTtfFontAsBitmap();
  var alignment = Companion_getInstance_2().h5s_1;
  var renderer = get_DefaultStringTextRenderer();
  var autoScaling = Companion_instance_0.sfm_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_4 = Text.tfm(text, numberToDouble(textSize), color, font, alignment, renderer, autoScaling, null, null);
  // Inline function 'kotlin.apply' call
  addTo(this_4, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.text.<anonymous>' call
  touch(_this__u8e3s4, GesturesScene$sceneMain$lambda(image, container));
  // Inline function 'korlibs.korge.input.mouse' call
  var tmp_0;
  if (image._v == null) {
    throwUninitializedPropertyAccessException('image');
  } else {
    tmp_0 = image._v;
  }
  var this_5 = tmp_0;
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  get_mouse(this_5).zbh_1.w1x(GesturesScene$sceneMain$lambda_0(image));
  var tmp_1 = get_timesPerSecond(2);
  addFixedUpdater(_this__u8e3s4, tmp_1, VOID, VOID, GesturesScene$sceneMain$lambda_1($this));
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_6 = addTo(UIButton.sd2(size, '1', null), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'gestures.GesturesScene.sceneMain.<anonymous>' call
  // Inline function 'korlibs.korge.view.position' call
  xy(this_6, numberToDouble(10), numberToDouble(380));
  this_6.ucv_1.w1x(GesturesScene$sceneMain$lambda_2);
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_0 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_7 = addTo(UIButton.sd2(size_0, '2', null), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'gestures.GesturesScene.sceneMain.<anonymous>' call
  // Inline function 'korlibs.korge.view.position' call
  xy(this_7, numberToDouble(150), numberToDouble(380));
  this_7.ucv_1.w1x(GesturesScene$sceneMain$lambda_3);
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_1 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_8 = addTo(UIButton.sd2(size_1, '3', null), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'gestures.GesturesScene.sceneMain.<anonymous>' call
  // Inline function 'korlibs.korge.view.position' call
  xy(this_8, numberToDouble(300), numberToDouble(380));
  this_8.ucv_1.w1x(GesturesScene$sceneMain$lambda_4);
  while (true) {
    var tmp_2;
    if (image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp_2 = image._v;
    }
    var tmp_3 = tmp_2;
    // Inline function 'korlibs.korge.tween.get' call
    var tmp_4;
    if (image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp_4 = image._v;
    }
    var this_9 = rotation$factory(tmp_4);
    var tmp_5 = this_9.get();
    var tmp_6 = [new V2(this_9, tmp_5, new Angle(minDegrees), _interpolateAngle$ref(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$25 = toDuration(1, DurationUnit_SECONDS_getInstance());
    var tmp_7 = tween_0(tmp_3, tmp_6, tmp$ret$25, get_EASE_IN_OUT(Companion_getInstance_19()), VOID, VOID, VOID, VOID, $completion);
    if (tmp_7 === get_COROUTINE_SUSPENDED())
      tmp_7 = yield tmp_7;
    var tmp_8;
    if (image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp_8 = image._v;
    }
    var tmp_9 = tmp_8;
    // Inline function 'korlibs.korge.tween.get' call
    var tmp_10;
    if (image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp_10 = image._v;
    }
    var this_10 = rotation$factory_0(tmp_10);
    var tmp_11 = this_10.get();
    var tmp_12 = [new V2(this_10, tmp_11, new Angle(maxDegrees), _interpolateAngle$ref_0(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$28 = toDuration(1, DurationUnit_SECONDS_getInstance());
    var tmp_13 = tween_0(tmp_9, tmp_12, tmp$ret$28, get_EASE_IN_OUT(Companion_getInstance_19()), VOID, VOID, VOID, VOID, $completion);
    if (tmp_13 === get_COROUTINE_SUSPENDED())
      tmp_13 = yield tmp_13;
  }
  return Unit_instance;
}
function GesturesScene$sceneMain$lambda$lambda($startImageRatio, $image) {
  return function ($this$scaleRecognizer) {
    var tmp;
    if ($image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp = $image._v;
    }
    $startImageRatio._v = tmp.pcb();
    return Unit_instance;
  };
}
function GesturesScene$sceneMain$lambda$lambda_0($image, $startImageRatio) {
  return function ($this$scaleRecognizer, it) {
    var tmp;
    if ($image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp = $image._v;
    }
    tmp.ocb($startImageRatio._v * $this$scaleRecognizer.gbs());
    return Unit_instance;
  };
}
function GesturesScene$sceneMain$lambda$lambda_1($startRotation, $container) {
  return function ($this$rotationRecognizer) {
    $startRotation._v = new Angle($container.bcc());
    return Unit_instance;
  };
}
function GesturesScene$sceneMain$lambda$lambda_2($container, $startRotation) {
  return function ($this$rotationRecognizer, it) {
    $container.acc(Angle__plus_impl_26v37k($startRotation._v.p2h_1, $this$rotationRecognizer.lbs()));
    return Unit_instance;
  };
}
function GesturesScene$sceneMain$lambda($image, $container) {
  return function ($this$touch) {
    var startImageRatio = {_v: 1.0};
    var startRotation = {_v: new Angle(get_degrees(0))};
    var tmp = GesturesScene$sceneMain$lambda$lambda(startImageRatio, $image);
    scaleRecognizer($this$touch, tmp, VOID, GesturesScene$sceneMain$lambda$lambda_0($image, startImageRatio));
    var tmp_0 = GesturesScene$sceneMain$lambda$lambda_1(startRotation, $container);
    rotationRecognizer($this$touch, tmp_0, VOID, GesturesScene$sceneMain$lambda$lambda_2($container, startRotation));
    return Unit_instance;
  };
}
function GesturesScene$sceneMain$lambda_0($image) {
  return function (it) {
    var tmp;
    if ($image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp = $image._v;
    }
    var tmp_0 = tmp;
    var tmp_1;
    var tmp_2;
    if ($image._v == null) {
      throwUninitializedPropertyAccessException('image');
    } else {
      tmp_2 = $image._v;
    }
    if (tmp_2.ncc() > 0.5) {
      tmp_1 = 0.5;
    } else {
      tmp_1 = 1.0;
    }
    tmp_0.kbj(tmp_1);
    return Unit_instance;
  };
}
function GesturesScene$sceneMain$lambda_1(this$0) {
  return function ($this$addFixedUpdater) {
    println(this$0.vbk().bb7_1.j8f());
    return Unit_instance;
  };
}
function GesturesScene$sceneMain$lambda_2(it) {
  println('TAPPED ON 1');
  return Unit_instance;
}
function GesturesScene$sceneMain$lambda_3(it) {
  println('TAPPED ON 2');
  return Unit_instance;
}
function GesturesScene$sceneMain$lambda_4(it) {
  println('TAPPED ON 3');
  return Unit_instance;
}
function _interpolateAngle$ref() {
  var l = function (p0, p1, p2) {
    return new Angle(_interpolateAngle(p0.e10_1, p1.p2h_1, p2.p2h_1));
  };
  l.callableName = '_interpolateAngle';
  return l;
}
function _interpolateAngle$ref_0() {
  var l = function (p0, p1, p2) {
    return new Angle(_interpolateAngle(p0.e10_1, p1.p2h_1, p2.p2h_1));
  };
  l.callableName = '_interpolateAngle';
  return l;
}
function rotation$factory($b0) {
  return getPropertyCallableRef('rotation', 0, KMutableProperty0, function () {
    return new Angle($b0.bcc());
  }, function (value) {
    return $b0.acc(value.p2h_1);
  });
}
function rotation$factory_0($b0) {
  return getPropertyCallableRef('rotation', 0, KMutableProperty0, function () {
    return new Angle($b0.bcc());
  }, function (value) {
    return $b0.acc(value.p2h_1);
  });
}
function *_generator_invoke__zhh2q8_20($this, it, $completion) {
  var tmp = openFileDialog($this.aqc_1.xbw(), FileFilter.mam([to('GLTF2', listOf(['*.glb', '*.gltf', '*.gltf2']))]), VOID, VOID, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp54_safe_receiver = tmp;
  var tmp55_safe_receiver = tmp54_safe_receiver == null ? null : firstOrNull(tmp54_safe_receiver);
  if (tmp55_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0 = main$_anonymous_$loadFile_idp4tg($this.bqc_1, $this.cqc_1, tmp55_safe_receiver, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
  }
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_21($this, $completion) {
  var tmp = $this.dqc_1($this.eqc_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_22($this, $completion) {
  var tmp57_safe_receiver = $this.fqc_1.g8e_1;
  var tmp58_safe_receiver = tmp57_safe_receiver == null ? null : firstOrNull(tmp57_safe_receiver);
  if (tmp58_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp = main$_anonymous_$loadFile_idp4tg($this.gqc_1, $this.hqc_1, tmp58_safe_receiver, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  }
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_23($this, $completion) {
  var tmp = $this.iqc_1($this.jqc_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_24($this, $completion) {
  var tmp = $this.kqc_1($this.lqc_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_main__qlaff5_0(_this__u8e3s4, $this, $completion) {
  var rotationY = {_v: new Angle(get_degrees(0))};
  var rotationX = {_v: new Angle(Companion_getInstance_14().s2h_1)};
  var cameraDistance = {_v: 10.0};
  var centerPoint = {_v: Companion_getInstance_16().c2x_1};
  // Inline function 'korlibs.korge3d.scene3D' call
  var views = new Views3D(ensureNotNull(_this__u8e3s4.pbj()).vbk(), _this__u8e3s4);
  var view = Stage3DView.kl5(views, _this__u8e3s4);
  addTo(view, _this__u8e3s4);
  // Inline function 'kotlin.apply' call
  var this_0 = view.il5_1;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'k3d.K3DScene.main.<anonymous>' call
  this_0.el6_1 = Perspective.ikx();
  axisLines(this_0, VOID, 4.0);
  var centerPointAxisLines = axisLines2(this_0, VOID, 0.2);
  var quat = Companion_getInstance_17().a2s_1;
  // Inline function 'korlibs.korge.view.scale' call
  // Inline function 'korlibs.korge.view.xy' call
  // Inline function 'kotlin.also' call
  // Inline function 'korlibs.korge.ui.uiSlider' call
  var size = Companion_getInstance_8().hfi_1;
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(UISlider.dfm(1.0, 0.0, 1.0, 0.0125, null, size), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.ui.uiSlider.<anonymous>' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'k3d.K3DScene.main.<anonymous>.<anonymous>' call
  this_1.jfl_1.w1x(K3DScene$main$lambda(this_0, this_1));
  var this_2 = xy(this_1, numberToDouble(200), numberToDouble(30));
  var slider2 = scale(this_2, numberToDouble(1), numberToDouble(1));
  var tmp = readGLTF2(get_resourcesVfs().bj('k3d/Koral.glb'), VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var koral = tmp;
  var tmp_0 = readGLTF2(get_resourcesVfs().bj('k3d/Walking0.glb'), VOID, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var walking0Skin = first(GLTF2View.elq(tmp_0, false).ylq());
  var tmp_1 = readGLTF2(get_resourcesVfs().bj('k3d/Walking1.glb'), VOID, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var walking1Skin = first(GLTF2View.elq(tmp_1, false).ylq());
  var tmp_2 = readGLTF2(get_resourcesVfs().bj('k3d/SlowRun.glb'), VOID, $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  var slowRunSkin = first(GLTF2View.elq(tmp_2, false).ylq());
  var tmp_3 = readGLTF2(get_resourcesVfs().bj('k3d/FastRun.glb'), VOID, $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  var fastRunSkin = first(GLTF2View.elq(tmp_3, false).ylq());
  var tmp_4 = readGLTF2(get_resourcesVfs().bj('k3d/HipHopDancing.glb'), VOID, $completion);
  if (tmp_4 === get_COROUTINE_SUSPENDED())
    tmp_4 = yield tmp_4;
  var hipHopDancingSkin = first(GLTF2View.elq(tmp_4, false).ylq());
  var koralView = {_v: position_0(gltf2View(this_0, koral, false), -1, 0, 0)};
  addUpdater_1(this_0, K3DScene$main$lambda_0(walking0Skin, walking1Skin, slowRunSkin, fastRunSkin, hipHopDancingSkin, koralView, slider2));
  var tmp_5 = readGLTF2(get_resourcesVfs().bj('k3d/Gest.glb'), VOID, $completion);
  if (tmp_5 === get_COROUTINE_SUSPENDED())
    tmp_5 = yield tmp_5;
  position_0(gltf2View(this_0, tmp_5), 1, 0, 0);
  // Inline function 'korlibs.korge.ui.uiButton' call
  var size_0 = Companion_getInstance_4().fcs_1;
  // Inline function 'kotlin.apply' call
  var this_3 = addTo(UIButton.sd2(size_0, 'Load...', null), _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'k3d.K3DScene.main.<anonymous>.<anonymous>' call
  // Inline function 'korlibs.korge.input.onClick' call
  var handler = K3DScene$main$slambda_2($this, koralView, this_0);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop = click$factory_14();
  var tmp73_safe_receiver = this_3 == null ? null : get_mouse(this_3);
  if (tmp73_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_6 = prop.get(tmp73_safe_receiver);
    tmp_6.v1x(K3DScene$main$lambda_1(tmp73_safe_receiver, handler));
  }
  var tmp_7 = Companion_getInstance_24().c8e_1.slice();
  this_0.w8a(tmp_7, K3DScene$main$lambda_2($this, koralView, this_0));
  var tmp_8 = this_0;
  var tmp59_safe_receiver = firstOrNull(koralView._v.olp_1.nlg_1);
  var tmp60_safe_receiver = tmp59_safe_receiver == null ? null : tmp59_safe_receiver.eln_1;
  var tmp0_elvis_lhs = tmp60_safe_receiver == null ? null : tmp60_safe_receiver.zlm();
  tmp_8.el6_1 = tmp0_elvis_lhs == null ? Perspective.ikx() : tmp0_elvis_lhs;
  // Inline function 'korlibs.korge.input.onMagnify' call
  var handler_0 = K3DScene$main$slambda_3(cameraDistance, centerPointAxisLines, centerPoint, this_0, rotationY, rotationX);
  // Inline function 'korlibs.korge.input.doGestureEvent' call
  var prop_0 = magnify$factory();
  var tmp35_safe_receiver = _this__u8e3s4 == null ? null : get_gestures(_this__u8e3s4);
  if (tmp35_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doGestureEvent.<anonymous>' call
    var tmp_9 = prop_0.get(tmp35_safe_receiver);
    tmp_9.v1x(K3DScene$main$lambda_3(tmp35_safe_receiver, handler_0));
  }
  // Inline function 'korlibs.korge.input.onScroll' call
  var handler_1 = K3DScene$main$slambda_4(centerPoint, centerPointAxisLines, this_0, cameraDistance, rotationY, rotationX);
  // Inline function 'korlibs.korge.input.doMouseEvent' call
  var prop_1 = scroll$factory();
  var tmp73_safe_receiver_0 = _this__u8e3s4 == null ? null : get_mouse(_this__u8e3s4);
  if (tmp73_safe_receiver_0 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
    var tmp_10 = prop_1.get(tmp73_safe_receiver_0);
    tmp_10.v1x(K3DScene$main$lambda_4(tmp73_safe_receiver_0, handler_1));
  }
  main$_anonymous_$updateOrbitCamera_1ds9jw(centerPointAxisLines, centerPoint, this_0, cameraDistance, rotationY, rotationX);
  // Inline function 'korlibs.korge.input.keys' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'k3d.K3DScene.main.<anonymous>.<anonymous>' call
  var $this$keys = get_keys(_this__u8e3s4);
  var tmp_11 = Key_LEFT_getInstance();
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp_12 = toDuration(4, DurationUnit_MILLISECONDS_getInstance());
  $this$keys.vbl(tmp_11, tmp_12, K3DScene$main$lambda_5(rotationY, rotationX, centerPointAxisLines, centerPoint, this_0, cameraDistance));
  var tmp_13 = Key_RIGHT_getInstance();
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp_14 = toDuration(4, DurationUnit_MILLISECONDS_getInstance());
  $this$keys.vbl(tmp_13, tmp_14, K3DScene$main$lambda_6(rotationY, rotationX, centerPointAxisLines, centerPoint, this_0, cameraDistance));
  var tmp_15 = Key_UP_getInstance();
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp_16 = toDuration(4, DurationUnit_MILLISECONDS_getInstance());
  $this$keys.vbl(tmp_15, tmp_16, K3DScene$main$lambda_7(rotationY, rotationX, centerPointAxisLines, centerPoint, this_0, cameraDistance));
  var tmp_17 = Key_DOWN_getInstance();
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp_18 = toDuration(4, DurationUnit_MILLISECONDS_getInstance());
  $this$keys.vbl(tmp_17, tmp_18, K3DScene$main$lambda_8(rotationY, rotationX, centerPointAxisLines, centerPoint, this_0, cameraDistance));
  // Inline function 'korlibs.korge.view.xy' call
  // Inline function 'korlibs.korge.view.solidRect' call
  var color = Colors_getInstance().l4r_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_4 = SolidRect.ddj(Size2D.f2j(2000, 1000), color);
  // Inline function 'kotlin.apply' call
  var this_5 = addTo(this_4, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  var tmp_19 = xy(this_5, numberToDouble(0), numberToDouble(100));
  onMouseDrag(tmp_19, VOID, VOID, K3DScene$main$lambda_9(rotationY, rotationX, centerPointAxisLines, centerPoint, this_0, cameraDistance));
  return Unit_instance;
}
function *_generator_main$_anonymous_$loadFile__on9f4(koralView, $this_scene3D, file, $completion) {
  // Inline function 'korlibs.time.measureTimeWithResult' call
  // Inline function 'kotlin.time.measureTimedValue' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.time.measureTimedValue' call
  // Inline function 'kotlin.contracts.contract' call
  var mark = Monotonic_instance.bk();
  // Inline function 'k3d.K3DScene.main.<anonymous>$loadFile.<anonymous>' call
  var tmp = readGLTF2(file, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var result = tmp;
  var _destruct__k2r9zo = new TimedValue(result, ValueTimeMark__elapsedNow_impl_eonqvs(mark));
  var it = _destruct__k2r9zo.km();
  var time = _destruct__k2r9zo.ur();
  println('Loaded GLTF2 in ' + Duration__toString_impl_8d916b(time) + '...');
  removeFromParent(koralView._v);
  koralView._v = addTo_0(GLTF2View.elq(it), $this_scene3D);
  return Unit_instance;
}
function main$_anonymous_$loadFile_idp4tg(koralView, $this_scene3D, file, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_main$_anonymous_$loadFile__on9f4(koralView, $this_scene3D, file, $completion), $completion);
}
function main$_anonymous_$updateOrbitCamera_1ds9jw(centerPointAxisLines, centerPoint, $this_scene3D, cameraDistance, rotationY, rotationX) {
  set_position(centerPointAxisLines, centerPoint._v);
  orbitAround($this_scene3D.el6_1, centerPoint._v, cameraDistance._v, rotationY._v.p2h_1, rotationX._v.p2h_1);
}
function main$_anonymous_$rotate_36wbgj(rotationY, rotationX, centerPointAxisLines, centerPoint, $this_scene3D, cameraDistance, deltaY, deltaX) {
  rotationY._v = new Angle(Angle__plus_impl_26v37k(rotationY._v.p2h_1, deltaY));
  rotationX._v = new Angle(adjustOrbitElevation(Angle__plus_impl_26v37k(rotationX._v.p2h_1, deltaX)));
  main$_anonymous_$updateOrbitCamera_1ds9jw(centerPointAxisLines, centerPoint, $this_scene3D, cameraDistance, rotationY, rotationX);
}
function K3DScene$main$lambda($$this$scene3D, $slider) {
  return function (it) {
    ensureNotNull($$this$scene3D.dl0()).bl6_1 = $slider.nfl_1;
    return Unit_instance;
  };
}
function K3DScene$main$lambda_0($walking0Skin, $walking1Skin, $slowRunSkin, $fastRunSkin, $hipHopDancingSkin, $koralView, $slider2) {
  return function ($this$addUpdater, it) {
    $walking0Skin.xlo_1.dlq(it.hk_1);
    $walking1Skin.xlo_1.dlq(it.hk_1);
    $slowRunSkin.xlo_1.dlq(it.hk_1);
    $fastRunSkin.xlo_1.dlq(it.hk_1);
    $hipHopDancingSkin.xlo_1.dlq(it.hk_1);
    first($koralView._v.ylq()).blq($walking0Skin, $hipHopDancingSkin, $slider2.nfl_1);
    return Unit_instance;
  };
}
function K3DScene$main$slambda_2(this$0, $koralView, $$this$scene3D) {
  var i = new K3DScene$main$slambda(this$0, $koralView, $$this$scene3D);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function K3DScene$main$lambda$slambda_3($handler, $it) {
  var i = new K3DScene$main$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function K3DScene$main$lambda_1($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, K3DScene$main$lambda$slambda_3($handler, it));
    return Unit_instance;
  };
}
function K3DScene$main$lambda$slambda_4($it, $koralView, $$this$scene3D) {
  var i = new K3DScene$main$lambda$slambda_0($it, $koralView, $$this$scene3D);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function K3DScene$main$lambda_2(this$0, $koralView, $$this$scene3D) {
  return function (it) {
    switch (it.f8e_1.v3_1) {
      case 0:
        this$0.yqc().car(true);
        break;
      case 1:
        this$0.yqc().car(false);
        break;
      case 2:
        launchImmediately_0(this$0, K3DScene$main$lambda$slambda_4(it, $koralView, $$this$scene3D));
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return Unit_instance;
  };
}
function K3DScene$main$slambda_3($cameraDistance, $centerPointAxisLines, $centerPoint, $$this$scene3D, $rotationY, $rotationX) {
  var i = new K3DScene$main$slambda_0($cameraDistance, $centerPointAxisLines, $centerPoint, $$this$scene3D, $rotationY, $rotationX);
  var l = function (it, $completion) {
    return i.fqd(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function K3DScene$main$lambda$slambda_5($handler, $it) {
  var i = new K3DScene$main$lambda$slambda_1($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function K3DScene$main$lambda_3($gestures, $handler) {
  return function (it) {
    var tmp = $gestures.vbk().yb6_1;
    launchImmediately(tmp, K3DScene$main$lambda$slambda_5($handler, it));
    return Unit_instance;
  };
}
function K3DScene$main$slambda_4($centerPoint, $centerPointAxisLines, $$this$scene3D, $cameraDistance, $rotationY, $rotationX) {
  var i = new K3DScene$main$slambda_1($centerPoint, $centerPointAxisLines, $$this$scene3D, $cameraDistance, $rotationY, $rotationX);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function K3DScene$main$lambda$slambda_6($handler, $it) {
  var i = new K3DScene$main$lambda$slambda_2($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function K3DScene$main$lambda_4($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, K3DScene$main$lambda$slambda_6($handler, it));
    return Unit_instance;
  };
}
function K3DScene$main$lambda_5($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance) {
  return function (it) {
    main$_anonymous_$rotate_36wbgj($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance, Angle__unaryMinus_impl_7901fr(get_degrees(1)), get_degrees(0));
    return Unit_instance;
  };
}
function K3DScene$main$lambda_6($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance) {
  return function (it) {
    main$_anonymous_$rotate_36wbgj($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance, Angle__unaryPlus_impl_eyi5pl(get_degrees(1)), get_degrees(0));
    return Unit_instance;
  };
}
function K3DScene$main$lambda_7($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance) {
  return function (it) {
    main$_anonymous_$rotate_36wbgj($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance, get_degrees(0), Angle__unaryPlus_impl_eyi5pl(get_degrees(1)));
    return Unit_instance;
  };
}
function K3DScene$main$lambda_8($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance) {
  return function (it) {
    main$_anonymous_$rotate_36wbgj($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance, get_degrees(0), Angle__unaryMinus_impl_7901fr(get_degrees(1)));
    return Unit_instance;
  };
}
function K3DScene$main$lambda_9($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance) {
  return function ($this$onMouseDrag, it) {
    main$_anonymous_$rotate_36wbgj($rotationY, $rotationX, $centerPointAxisLines, $centerPoint, $$this$scene3D, $cameraDistance, get_degrees_0(it.rbm_1), get_degrees_0(it.sbm_1));
    return Unit_instance;
  };
}
function positionLookingAt(_this__u8e3s4, p, t) {
  _this__u8e3s4.zkv_1.jkz(p.k2x_1, p.l2x_1, p.m2x_1, t.k2x_1, t.l2x_1, t.m2x_1);
  _this__u8e3s4.hbh();
  return _this__u8e3s4;
}
function clamp(_this__u8e3s4, min, max) {
  // Inline function 'korlibs.math.geom.min' call
  // Inline function 'korlibs.math.geom.max' call
  // Inline function 'kotlin.math.max' call
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var a = _Angle___get_radians__impl__n00yt5(_this__u8e3s4);
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var b = _Angle___get_radians__impl__n00yt5(min);
  var tmp$ret$2 = Math.max(a, b);
  // Inline function 'kotlin.math.min' call
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var this_0 = _Angle___init__impl__g23q1m(tmp$ret$2);
  var a_0 = _Angle___get_radians__impl__n00yt5(this_0);
  // Inline function 'korlibs.math.geom.Angle.internal' call
  var b_0 = _Angle___get_radians__impl__n00yt5(max);
  var tmp$ret$6 = Math.min(a_0, b_0);
  return _Angle___init__impl__g23q1m(tmp$ret$6);
}
function adjustOrbitElevation(angle) {
  return clamp(angle, Angle__plus_impl_26v37k(Companion_getInstance_14().r2h_1, get_degrees_0(0.01)), Angle__minus_impl_xeau7k(Companion_getInstance_14().t2h_1, get_degrees_0(0.01)));
}
function orbitAround(_this__u8e3s4, t, distance, azimuth, elevation) {
  var r = distance;
  var theta = azimuth;
  var phi = adjustOrbitElevation(elevation);
  // Inline function 'korlibs.math.geom.sin' call
  var up = Companion_getInstance_15().e2i_1;
  var sinPhi = Angle__sine_impl_3l11xv(phi, up);
  var tmp = r * sinPhi;
  // Inline function 'korlibs.math.geom.sin' call
  var up_0 = Companion_getInstance_15().e2i_1;
  var tmp_0 = tmp * Angle__sine_impl_3l11xv(theta, up_0);
  // Inline function 'korlibs.math.geom.cos' call
  var up_1 = Companion_getInstance_15().e2i_1;
  var tmp_1 = r * Angle__cosine_impl_bgvzun(phi, up_1);
  var tmp_2 = r * sinPhi;
  // Inline function 'korlibs.math.geom.cos' call
  var up_2 = Companion_getInstance_15().e2i_1;
  var tmp$ret$3 = Angle__cosine_impl_bgvzun(theta, up_2);
  var p = Vector3F.o2x(tmp_0, tmp_1, tmp_2 * tmp$ret$3);
  return positionLookingAt(_this__u8e3s4, t.p2x(p), t);
}
function axisLines2(_this__u8e3s4, basePosition, length, lengthWhiteScale) {
  basePosition = basePosition === VOID ? Companion_getInstance_16().c2x_1 : basePosition;
  length = length === VOID ? 10.0 : length;
  lengthWhiteScale = lengthWhiteScale === VOID ? 0.25 : lengthWhiteScale;
  var ll = length;
  var l2 = length * lengthWhiteScale;
  return container3D(_this__u8e3s4, axisLines2$lambda(basePosition, ll, l2));
}
function axisLines2$lambda$lambda($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines2$lambda$lambda_0($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines2$lambda$lambda_1($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines2$lambda$lambda_2($ctx) {
  return function ($this$MeshBuilder3D) {
    var s = 1;
    var this_0 = $ctx.zlu_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var polyline = this_0.i1(_unary__edvuaz);
      // Inline function 'korlibs.math.geom.fastForEachGeneric' call
      var inductionVariable = 0;
      var last = polyline.j1();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>.<anonymous>.<anonymous>' call
          $this$MeshBuilder3D.jl2(Vector3F.o2x(polyline.z2k(n_0, 0), polyline.z2k(n_0, 1), polyline.z2k(n_0, 2)));
        }
         while (inductionVariable < last);
      var inductionVariable_0 = 0;
      var last_0 = polyline.j1() - 1 | 0;
      if (inductionVariable_0 < last_0)
        do {
          var n_1 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          $this$MeshBuilder3D.kl2((s + n_1 | 0) - 1 | 0);
          $this$MeshBuilder3D.kl2(s + n_1 | 0);
        }
         while (inductionVariable_0 < last_0);
      s = s + polyline.j1() | 0;
    }
    return Unit_instance;
  };
}
function axisLines2$lambda($basePosition, $ll, $l2) {
  return function ($this$container3D) {
    position_1($this$container3D, $basePosition);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color = Colors_getInstance().r59('#e20050');
    // Inline function 'kotlin.also' call
    var this_0 = Polyline3D.rlv(color);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_1 = new RenderContext3D();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'k3d.axisLines2.<anonymous>.<anonymous>' call
    this_1.slv(Vector3F.n2x(-$ll, 0.0, 0.0));
    this_1.tlv(Vector3F.n2x($ll, 0.0, 0.0));
    var ctx = this_1;
    var tmp = this_0;
    // Inline function 'kotlin.also' call
    var tmp_0 = Companion_instance_5;
    var tmp_1 = Companion_getInstance_25().k8u_1;
    var this_2 = tmp_0.ul0(tmp_1, axisLines2$lambda$lambda(ctx));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_2.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_0.qlv_1));
    tmp.wla_1 = this_2;
    addTo_0(this_0, $this$container3D);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color_0 = Colors_getInstance().z4u_1;
    // Inline function 'kotlin.also' call
    var this_3 = Polyline3D.rlv(color_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_4 = new RenderContext3D();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'k3d.axisLines2.<anonymous>.<anonymous>' call
    this_4.slv(Companion_getInstance_16().j2x_1.e2r($ll));
    this_4.tlv(Companion_getInstance_16().i2x_1.e2r($ll));
    var ctx_0 = this_4;
    var tmp_2 = this_3;
    // Inline function 'kotlin.also' call
    var tmp_3 = Companion_instance_5;
    var tmp_4 = Companion_getInstance_25().k8u_1;
    var this_5 = tmp_3.ul0(tmp_4, axisLines2$lambda$lambda_0(ctx_0));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_5.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_3.qlv_1));
    tmp_2.wla_1 = this_5;
    addTo_0(this_3, $this$container3D);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color_1 = Colors_getInstance().r59('#8cb04d');
    // Inline function 'kotlin.also' call
    var this_6 = Polyline3D.rlv(color_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_7 = new RenderContext3D();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'k3d.axisLines2.<anonymous>.<anonymous>' call
    this_7.slv(Vector3F.n2x(0.0, 0.0, -$ll));
    this_7.tlv(Vector3F.n2x(0.0, 0.0, $ll));
    var ctx_1 = this_7;
    var tmp_5 = this_6;
    // Inline function 'kotlin.also' call
    var tmp_6 = Companion_instance_5;
    var tmp_7 = Companion_getInstance_25().k8u_1;
    var this_8 = tmp_6.ul0(tmp_7, axisLines2$lambda$lambda_1(ctx_1));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_8.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_6.qlv_1));
    tmp_5.wla_1 = this_8;
    addTo_0(this_6, $this$container3D);
    // Inline function 'korlibs.korge3d.shape.polyline3D' call
    var color_2 = Colors_getInstance().g4r_1;
    // Inline function 'kotlin.also' call
    var this_9 = Polyline3D.rlv(color_2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.polyline3D.<anonymous>' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape' call
    // Inline function 'kotlin.also' call
    var this_10 = new RenderContext3D();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    // Inline function 'k3d.axisLines2.<anonymous>.<anonymous>' call
    this_10.slv(Vector3F.n2x(0.0, 0.0, 0.0));
    this_10.tlv(Vector3F.n2x($l2, 0.0, 0.0));
    this_10.slv(Vector3F.n2x(0.0, 0.0, 0.0));
    this_10.tlv(Vector3F.n2x(0.0, $l2, 0.0));
    this_10.slv(Vector3F.n2x(0.0, 0.0, 0.0));
    this_10.tlv(Vector3F.n2x(0.0, 0.0, $l2));
    var ctx_2 = this_10;
    var tmp_8 = this_9;
    // Inline function 'kotlin.also' call
    var tmp_9 = Companion_instance_5;
    var tmp_10 = Companion_getInstance_25().k8u_1;
    var this_11 = tmp_9.ul0(tmp_10, axisLines2$lambda$lambda_2(ctx_2));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge3d.shape.Polyline3D.updateShape.<anonymous>' call
    this_11.pl0_1 = new PBRMaterial3D(VOID, VOID, new LightColor(this_9.qlv_1));
    tmp_8.wla_1 = this_11;
    addTo_0(this_9, $this$container3D);
    return Unit_instance;
  };
}
function click$factory_14() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function magnify$factory() {
  return getPropertyCallableRef('magnify', 1, KProperty1, function (receiver) {
    return receiver.qbk_1;
  }, null);
}
function scroll$factory() {
  return getPropertyCallableRef('scroll', 1, KProperty1, function (receiver) {
    return receiver.obi_1;
  }, null);
}
function set_mainSceneContainer(_this__u8e3s4, _set____db54di) {
  _init_properties_main_kt__ykjpl3();
  var this_0 = mainSceneContainer$delegate;
  var property = mainSceneContainer$factory();
  var tmp0_elvis_lhs = this_0.p13_1;
  setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs, _set____db54di);
  return Unit_instance;
}
function get_mainSceneContainer(_this__u8e3s4) {
  _init_properties_main_kt__ykjpl3();
  var tmp$ret$0;
  $l$block: {
    // Inline function 'korlibs.datastructure.Property.getValue' call
    var this_0 = mainSceneContainer$delegate;
    var property = mainSceneContainer$factory_0();
    var tmp = _this__u8e3s4.o13();
    var tmp0_safe_receiver = (!(tmp == null) ? isInterface(tmp, KtMutableMap) : false) ? tmp : null;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs = this_0.p13_1;
      tmp_0 = tmp0_safe_receiver.k3(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
    }
    var res = tmp_0;
    if (res == null) {
      var r = this_0.q13_1();
      var tmp_1;
      if (!(r == null)) {
        tmp_1 = true;
      } else {
        var tmp1_elvis_lhs = this_0.p13_1;
        tmp_1 = hasExtra(_this__u8e3s4, tmp1_elvis_lhs == null ? property.callableName : tmp1_elvis_lhs);
      }
      if (tmp_1) {
        // Inline function 'korlibs.datastructure.Property.setValue' call
        var tmp0_elvis_lhs_0 = this_0.p13_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
      }
      tmp$ret$0 = r;
      break $l$block;
    }
    tmp$ret$0 = res;
  }
  return tmp$ret$0;
}
var mainSceneContainer$delegate;
function *_generator_main__qlaff5_1($completion) {
  get_ext().tqa();
  var tmp62_virtualSize = Size2D.f2j(800, 600);
  var tmp63_backgroundColor = Colors_getInstance().r59('#2b2b2b');
  var tmp64_quality = Quality_PERFORMANCE_getInstance();
  var tmp = Korge(VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, tmp62_virtualSize, VOID, VOID, VOID, VOID, 'KorGE Web Samples', VOID, tmp63_backgroundColor, tmp64_quality, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, main$slambda_0(), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function main($completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_main__qlaff5_1($completion), $completion);
}
function addFullScreenButton(_this__u8e3s4) {
  _init_properties_main_kt__ykjpl3();
  // Inline function 'korlibs.korge.view.vectorImage' call
  var shape = scaled(SVG.l7g('<?xml version="1.0" encoding="iso-8859-1"?>\n<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n<svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n\t viewBox="0 0 512 512" xml:space="preserve">\n<g style=\'fill:white\'>\n\t<path d="M192,64H32C14.328,64,0,78.328,0,96v96c0,17.672,14.328,32,32,32s32-14.328,32-32v-64h128c17.672,0,32-14.328,32-32 S209.672,64,192,64z"/>\n\t<path d="M480,64H320c-17.672,0-32,14.328-32,32s14.328,32,32,32h128v64c0,17.672,14.328,32,32,32s32-14.328,32-32V96 C512,78.328,497.672,64,480,64z"/>\n\t<path d="M480,288c-17.672,0-32,14.328-32,32v64H320c-17.672,0-32,14.328-32,32s14.328,32,32,32h160c17.672,0,32-14.328,32-32v-96 C512,302.328,497.672,288,480,288z"/>\n\t<path d="M192,384H64v-64c0-17.672-14.328-32-32-32S0,302.328,0,320v96c0,17.672,14.328,32,32,32h160c17.672,0,32-14.328,32-32 S209.672,384,192,384z"/>\n<\/g>\n<\/svg>'), 0.1);
  // Inline function 'kotlin.apply' call
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = VectorImage.fh8(shape, true);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'addFullScreenButton.<anonymous>' call
  // Inline function 'korlibs.korge.view.filter.filters' call
  var filters = [new DropshadowFilter()];
  var filterScale = get_filterScale(this_1);
  // Inline function 'korlibs.korge.view.filter.filters' call
  var filters_0 = toList(filters);
  var filterScale_0 = numberToDouble(filterScale);
  set_filter(this_1, Companion_instance_1.dhg(null, filters_0));
  set_filterScale(this_1, numberToDouble(filterScale_0));
  anchor(this_1, Companion_getInstance_10().x2g_1);
  // Inline function 'korlibs.korge.view.xy' call
  xy(this_1, numberToDouble(800), numberToDouble(600));
  set_cursor(this_1, Cursor_HAND_getInstance());
  // Inline function 'korlibs.korge.input.mouse' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var $this$mouse = get_mouse(this_1);
  // Inline function 'korlibs.korge.input.MouseEvents.onClick' call
  var handler = addFullScreenButton$slambda_0($this$mouse);
  // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
  var tmp = click$factory_15().get($this$mouse);
  tmp.v1x(addFullScreenButton$lambda($this$mouse, handler));
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.vectorImage.<anonymous>' call
  this_1.vg8();
}
function *_generator_changeToSceneDefault__oe2ebs(stage, $completion) {
  var tmp = changeToScene(stage, get_ext().xqa(), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function changeToSceneDefault(stage, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_changeToSceneDefault__oe2ebs(stage, $completion), $completion);
}
function *_generator_changeToScene__lgjkcz(stage, sceneName, $completion) {
  var registeredScenes = get_registeredScenes(stage);
  var realSceneName = sceneName == null ? first_0(registeredScenes.l3()) : sceneName;
  var tmp1_elvis_lhs = registeredScenes.k3(realSceneName);
  var sceneInfo = tmp1_elvis_lhs == null ? first_0(registeredScenes.m3()) : tmp1_elvis_lhs;
  var tmp = ensureNotNull(get_mainSceneContainer(stage)).mbe(sceneInfo.bqb_1, [], VOID, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  get_ext().wqa('changedScene', sceneInfo.dqb());
  return Unit_instance;
}
function changeToScene(stage, sceneName, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_changeToScene__lgjkcz(stage, sceneName, $completion), $completion);
}
function mainSceneContainer$delegate$lambda() {
  _init_properties_main_kt__ykjpl3();
  return null;
}
function *_generator_invoke__zhh2q8_25($this, $completion) {
  var tmp = $this.mqd_1.pbj();
  var tmp61_safe_receiver = $this.nqd_1.idx();
  var tmp_0 = changeToScene(tmp, tmp61_safe_receiver == null ? null : tmp61_safe_receiver.dqb(), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_26($this, $completion) {
  var tmp = changeToScene($this.oqd_1.pbj(), toString_0($this.pqd_1), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_27($this, $completion) {
  var tmp = changeToSceneDefault($this.qqd_1.pbj(), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_28($this, $this$Korge, $completion) {
  var GROUP_BASICS = 'Basics';
  var GROUP_ADVANCED = 'Advanced';
  var GROUP_PHYSICS = 'Physics';
  var GROUP_SKELETON = 'Skeleton';
  var GROUP_INPUT = 'Input';
  var GROUP_PERFORMANCE = 'Performance';
  var GROUP_UI = 'UI';
  var GROUP_3D = '3D';
  var GROUP_SWF = 'SWF';
  var GROUP_TILEMAP = 'TileMaps';
  // Inline function 'extension.SceneInfo' call
  var title = 'Rotating Image';
  var srcPath = 'scene1/Scene1.kt';
  var gen = main$slambda$lambda;
  var tmp = new SceneInfo(title, GROUP_BASICS, srcPath, getKClass(Scene1), gen);
  // Inline function 'extension.SceneInfo' call
  var srcPath_0 = 'scene2/Scene2.kt';
  var gen_0 = main$slambda$lambda_0;
  var tmp_0 = new SceneInfo('Tinting', GROUP_BASICS, srcPath_0, getKClass(Scene2), gen_0);
  // Inline function 'extension.SceneInfo' call
  var srcPath_1 = 'easings/EasingsScene.kt';
  var gen_1 = main$slambda$lambda_1;
  var tmp_1 = new SceneInfo('Easing', GROUP_BASICS, srcPath_1, getKClass(EasingsScene), gen_1);
  // Inline function 'extension.SceneInfo' call
  var title_0 = 'Anchor/Scale';
  var srcPath_2 = 'anchorscale/MainUIImageTester.kt';
  var gen_2 = main$slambda$lambda_2;
  var tmp_2 = new SceneInfo(title_0, GROUP_BASICS, srcPath_2, getKClass(MainUIImageTester), gen_2);
  // Inline function 'extension.SceneInfo' call
  var srcPath_3 = 'filters/FiltersScene.kt';
  var gen_3 = main$slambda$lambda_3;
  var tmp_3 = new SceneInfo('Filters', GROUP_ADVANCED, srcPath_3, getKClass(FiltersScene), gen_3);
  // Inline function 'extension.SceneInfo' call
  var srcPath_4 = 'k3d/K3DScene.kt';
  var gen_4 = main$slambda$lambda_4;
  var tmp_4 = new SceneInfo('3D', GROUP_3D, srcPath_4, getKClass(K3DScene), gen_4);
  // Inline function 'extension.SceneInfo' call
  var srcPath_5 = 'swf/SWFScene.kt';
  var gen_5 = main$slambda$lambda_5;
  var tmp_5 = new SceneInfo('SWF', GROUP_SWF, srcPath_5, getKClass(SWFScene), gen_5);
  // Inline function 'extension.SceneInfo' call
  var title_1 = 'Simple Box2d';
  var srcPath_6 = 'box2d/SimpleBox2dScene.kt';
  var gen_6 = main$slambda$lambda_6;
  var tmp_6 = new SceneInfo(title_1, GROUP_PHYSICS, srcPath_6, getKClass(SimpleBox2dScene), gen_6);
  // Inline function 'extension.SceneInfo' call
  var srcPath_7 = 'spine/SpineScene.kt';
  var gen_7 = main$slambda$lambda_7;
  var tmp_7 = new SceneInfo('Spine', GROUP_SKELETON, srcPath_7, getKClass(SpineScene), gen_7);
  // Inline function 'extension.SceneInfo' call
  var title_2 = 'Gestures';
  var srcPath_8 = 'gestures/GesturesScene.kt';
  var gen_8 = main$slambda$lambda_8;
  var tmp_8 = new SceneInfo(title_2, GROUP_INPUT, srcPath_8, getKClass(GesturesScene), gen_8);
  // Inline function 'extension.SceneInfo' call
  var title_3 = 'Bunnymark';
  var srcPath_9 = 'bunnymark/BunnymarkScene.kt';
  var gen_9 = main$slambda$lambda_9;
  var tmp_9 = new SceneInfo(title_3, GROUP_PERFORMANCE, srcPath_9, getKClass(BunnymarkScene), gen_9);
  // Inline function 'extension.SceneInfo' call
  var srcPath_10 = 'ui/SimpleUIScene.kt';
  var gen_10 = main$slambda$lambda_10;
  var tmp_10 = new SceneInfo('UI', GROUP_UI, srcPath_10, getKClass(SimpleUIScene), gen_10);
  // Inline function 'extension.SceneInfo' call
  var title_4 = 'Dungeon Explorer';
  var srcPath_11 = 'dungeon/DungeonScene.kt';
  var gen_11 = main$slambda$lambda_11;
  var tmp_11 = new SceneInfo(title_4, GROUP_TILEMAP, srcPath_11, getKClass(DungeonScene), gen_11);
  // Inline function 'extension.SceneInfo' call
  var srcPath_12 = 'snake/scene/IngameScene.kt';
  var gen_12 = main$slambda$lambda_12;
  var tmp$ret$13 = new SceneInfo('Snake', GROUP_TILEMAP, srcPath_12, getKClass(SnakeScene), gen_12);
  register($this$Korge, [tmp, tmp_0, tmp_1, tmp_2, tmp_3, tmp_4, tmp_5, tmp_6, tmp_7, tmp_8, tmp_9, tmp_10, tmp_11, tmp$ret$13]);
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'main.<anonymous>.<anonymous>' call
  // Inline function 'korlibs.korge.scene.sceneContainer' call
  var defaultTransition = withEasing(get_AlphaTransition(), get_EASE_IN_OUT_QUAD(Companion_getInstance_19()));
  var name = 'sceneContainer';
  var size = Companion_getInstance_18().t2v_1;
  // Inline function 'korlibs.korge.scene.sceneContainer' call
  var tmp_12 = views($completion);
  if (tmp_12 === get_COROUTINE_SUSPENDED())
    tmp_12 = yield tmp_12;
  var views_0 = tmp_12;
  var width = size.km();
  var height = size.lm();
  var rwidth = width;
  var rheight = height;
  if (width === 0.0 && height === 0.0) {
    var tmp_13 = findFirstAscendant($this$Korge, main$slambda$lambda_13);
    var base = (tmp_13 == null ? true : tmp_13 instanceof FixedSizeContainer) ? tmp_13 : null;
    var tmp0_elvis_lhs = base == null ? null : base.j2y();
    rwidth = tmp0_elvis_lhs == null ? views_0.kb8_1.j2y() : tmp0_elvis_lhs;
    var tmp1_elvis_lhs = base == null ? null : base.k2y();
    rheight = tmp1_elvis_lhs == null ? views_0.kb8_1.k2y() : tmp1_elvis_lhs;
  }
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = SceneContainer.lbe(views_0, defaultTransition, name, Size2D.k2t(rwidth, rheight));
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, $this$Korge);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.sceneContainer.<anonymous>' call
  set_mainSceneContainer($this$Korge, this_1);
  if (!Companion_getInstance_26().xw()) {
    // Inline function 'korlibs.korge.ui.uiComboBox' call
    var items = toList_0(get_registeredScenes($this$Korge).m3());
    var size_0 = get_UI_DEFAULT_SIZE();
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiComboBox.<anonymous>' call
    var tmp_14 = addTo(UIComboBox.mdy(size_0, 0, items), $this$Korge).tdp_1;
    tmp_14.w1x(main$slambda$lambda_14($this$Korge));
  }
  get_ext().uqa($this$Korge);
  var tmp_15 = get_ext();
  tmp_15.vqa('changeScene', main$slambda$lambda_15($this$Korge));
  var tmp_16 = get_ext();
  tmp_16.vqa('hashchange', main$slambda$lambda_16($this$Korge));
  var tmp_17 = changeToSceneDefault($this$Korge.pbj(), $completion);
  if (tmp_17 === get_COROUTINE_SUSPENDED())
    tmp_17 = yield tmp_17;
  addFullScreenButton($this$Korge);
  return Unit_instance;
}
function main$slambda$lambda($this$SceneInfo) {
  return new Scene1();
}
function main$slambda$lambda_0($this$SceneInfo) {
  return new Scene2();
}
function main$slambda$lambda_1($this$SceneInfo) {
  return new EasingsScene();
}
function main$slambda$lambda_2($this$SceneInfo) {
  return new MainUIImageTester();
}
function main$slambda$lambda_3($this$SceneInfo) {
  return new FiltersScene();
}
function main$slambda$lambda_4($this$SceneInfo) {
  return new K3DScene();
}
function main$slambda$lambda_5($this$SceneInfo) {
  return new SWFScene();
}
function main$slambda$lambda_6($this$SceneInfo) {
  return new SimpleBox2dScene();
}
function main$slambda$lambda_7($this$SceneInfo) {
  return new SpineScene();
}
function main$slambda$lambda_8($this$SceneInfo) {
  return new GesturesScene();
}
function main$slambda$lambda_9($this$SceneInfo) {
  return new BunnymarkScene();
}
function main$slambda$lambda_10($this$SceneInfo) {
  return new SimpleUIScene();
}
function main$slambda$lambda_11($this$SceneInfo) {
  return new DungeonScene();
}
function main$slambda$lambda_12($this$SceneInfo) {
  return new SnakeScene();
}
function main$slambda$lambda_13(it) {
  return it instanceof FixedSizeContainer;
}
function main$slambda$lambda$slambda_2($$this$run, $it) {
  var i = new main$slambda$lambda$slambda($$this$run, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function main$slambda$lambda_14($$this$run) {
  return function (it) {
    launchImmediately_0($$this$run, main$slambda$lambda$slambda_2($$this$run, it));
    return Unit_instance;
  };
}
function main$slambda$lambda$slambda_3($this_Korge, $detail) {
  var i = new main$slambda$lambda$slambda_0($this_Korge, $detail);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function main$slambda$lambda_15($this_Korge) {
  return function (detail) {
    launchImmediately_0($this_Korge, main$slambda$lambda$slambda_3($this_Korge, detail));
    return Unit_instance;
  };
}
function main$slambda$lambda$slambda_4($this_Korge) {
  var i = new main$slambda$lambda$slambda_1($this_Korge);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function main$slambda$lambda_16($this_Korge) {
  return function (detail) {
    launchImmediately_0($this_Korge, main$slambda$lambda$slambda_4($this_Korge));
    return Unit_instance;
  };
}
function main$slambda_0() {
  var i = new main$slambda();
  var l = function ($this$Korge, $completion) {
    return i.tb3($this$Korge, $completion);
  };
  l.$arity = 1;
  return l;
}
function addFullScreenButton$slambda_0($$this$mouse) {
  var i = new addFullScreenButton$slambda($$this$mouse);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_29($this, $completion) {
  var tmp = $this.sqd_1($this.tqd_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function addFullScreenButton$lambda$slambda_0($handler, $it) {
  var i = new addFullScreenButton$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function addFullScreenButton$lambda($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately(tmp, addFullScreenButton$lambda$slambda_0($handler, it));
    return Unit_instance;
  };
}
function mainSceneContainer$factory() {
  return getPropertyCallableRef('mainSceneContainer', 1, KMutableProperty1, function (receiver) {
    return get_mainSceneContainer(receiver);
  }, function (receiver, value) {
    return set_mainSceneContainer(receiver, value);
  });
}
function mainSceneContainer$factory_0() {
  return getPropertyCallableRef('mainSceneContainer', 1, KMutableProperty1, function (receiver) {
    return get_mainSceneContainer(receiver);
  }, function (receiver, value) {
    return set_mainSceneContainer(receiver, value);
  });
}
function click$factory_15() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
var properties_initialized_main_kt_ask6ij;
function _init_properties_main_kt__ykjpl3() {
  if (!properties_initialized_main_kt_ask6ij) {
    properties_initialized_main_kt_ask6ij = true;
    // Inline function 'korlibs.datastructure.extraProperty' call
    var default_0 = mainSceneContainer$delegate$lambda;
    mainSceneContainer$delegate = new Property(null, default_0);
  }
}
function mainWrapper() {
  startCoroutineUninterceptedOrReturnGeneratorVersion(main, get_EmptyContinuation());
}
function *_generator_sceneMain__5xvlzl_4(_this__u8e3s4, $this, $completion) {
  var minDegrees = get_degrees(-16);
  var maxDegrees = get_degrees(16);
  // Inline function 'korlibs.korge.view.image' call
  var tmp = readBitmap(get_resourcesVfs().bj('korge.png'), VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var texture = tmp;
  var anchor_0 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Image.ph4(texture, anchor_0);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'scene1.Scene1.sceneMain.<anonymous>' call
  this_1.acc(maxDegrees);
  // Inline function 'korlibs.korge.view.anchor' call
  anchor(this_1, Anchor2D.g2h(numberToDouble(0.5), numberToDouble(0.5)));
  scale(this_1, 0.8);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_1, numberToDouble(400), numberToDouble(300));
  var image = this_1;
  while (true) {
    // Inline function 'korlibs.korge.tween.get' call
    var this_2 = rotation$factory_1(image);
    var tmp_0 = this_2.get();
    var tmp_1 = [new V2(this_2, tmp_0, new Angle(minDegrees), _interpolateAngle$ref_1(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$7 = toDuration(1, DurationUnit_SECONDS_getInstance());
    var tmp_2 = tween_0(image, tmp_1, tmp$ret$7, get_EASE_IN_OUT(Companion_getInstance_19()), VOID, VOID, VOID, VOID, $completion);
    if (tmp_2 === get_COROUTINE_SUSPENDED())
      tmp_2 = yield tmp_2;
    // Inline function 'korlibs.korge.tween.get' call
    var this_3 = rotation$factory_2(image);
    var tmp_3 = this_3.get();
    var tmp_4 = [new V2(this_3, tmp_3, new Angle(maxDegrees), _interpolateAngle$ref_2(), false)];
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    var tmp$ret$10 = toDuration(1, DurationUnit_SECONDS_getInstance());
    var tmp_5 = tween_0(image, tmp_4, tmp$ret$10, get_EASE_IN_OUT(Companion_getInstance_19()), VOID, VOID, VOID, VOID, $completion);
    if (tmp_5 === get_COROUTINE_SUSPENDED())
      tmp_5 = yield tmp_5;
  }
  return Unit_instance;
}
function _interpolateAngle$ref_1() {
  var l = function (p0, p1, p2) {
    return new Angle(_interpolateAngle(p0.e10_1, p1.p2h_1, p2.p2h_1));
  };
  l.callableName = '_interpolateAngle';
  return l;
}
function _interpolateAngle$ref_2() {
  var l = function (p0, p1, p2) {
    return new Angle(_interpolateAngle(p0.e10_1, p1.p2h_1, p2.p2h_1));
  };
  l.callableName = '_interpolateAngle';
  return l;
}
function rotation$factory_1($b0) {
  return getPropertyCallableRef('rotation', 0, KMutableProperty0, function () {
    return new Angle($b0.bcc());
  }, function (value) {
    return $b0.acc(value.p2h_1);
  });
}
function rotation$factory_2($b0) {
  return getPropertyCallableRef('rotation', 0, KMutableProperty0, function () {
    return new Angle($b0.bcc());
  }, function (value) {
    return $b0.acc(value.p2h_1);
  });
}
function *_generator_invoke__zhh2q8_30($this, $completion) {
  var tmp = $this.fqe_1($this.gqe_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_31($this, $completion) {
  var tmp = $this.hqe_1($this.iqe_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_sceneMain__5xvlzl_5(_this__u8e3s4, $this, $completion) {
  // Inline function 'korlibs.korge.view.image' call
  var tmp = readBitmap(get_resourcesVfs().bj('korge.png'), VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var texture = tmp;
  var anchor_0 = Companion_getInstance_10().p2g_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Image.ph4(texture, anchor_0);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'scene2.Scene2.sceneMain.<anonymous>' call
  this_1.m59(Colors_getInstance().i4r_1);
  this_1.acc(get_degrees(0));
  // Inline function 'korlibs.korge.view.anchor' call
  anchor(this_1, Anchor2D.g2h(numberToDouble(0.5), numberToDouble(0.5)));
  scale(this_1, 0.8);
  // Inline function 'korlibs.korge.view.position' call
  xy(this_1, numberToDouble(400), numberToDouble(300));
  this_1.kbj(0.5);
  // Inline function 'korlibs.korge.input.mouse' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var $this$mouse = get_mouse(this_1);
  // Inline function 'korlibs.korge.input.MouseEvents.onOver' call
  var handler = Scene2$sceneMain$slambda_1(this_1);
  // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
  var tmp_0 = over$factory_0().get($this$mouse);
  tmp_0.v1x(Scene2$sceneMain$lambda($this$mouse, handler));
  // Inline function 'korlibs.korge.input.MouseEvents.onOut' call
  var handler_0 = Scene2$sceneMain$slambda_2(this_1);
  // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
  var tmp_1 = out$factory_0().get($this$mouse);
  tmp_1.v1x(Scene2$sceneMain$lambda_0($this$mouse, handler_0));
  return Unit_instance;
}
function Scene2$sceneMain$slambda_1($$this$image) {
  var i = new Scene2$sceneMain$slambda($$this$image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function Scene2$sceneMain$lambda$slambda_1($handler, $it) {
  var i = new Scene2$sceneMain$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function Scene2$sceneMain$lambda($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately(tmp, Scene2$sceneMain$lambda$slambda_1($handler, it));
    return Unit_instance;
  };
}
function Scene2$sceneMain$slambda_2($$this$image) {
  var i = new Scene2$sceneMain$slambda_0($$this$image);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function Scene2$sceneMain$lambda$slambda_2($handler, $it) {
  var i = new Scene2$sceneMain$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function Scene2$sceneMain$lambda_0($this, $handler) {
  return function (it) {
    var tmp = $this.wbo();
    launchImmediately(tmp, Scene2$sceneMain$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function over$factory_0() {
  return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
    return receiver.abi_1;
  }, null);
}
function out$factory_0() {
  return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
    return receiver.bbi_1;
  }, null);
}
var SnakeDirection_UP_instance;
var SnakeDirection_DOWN_instance;
var SnakeDirection_LEFT_instance;
var SnakeDirection_RIGHT_instance;
var SnakeDirection_NONE_instance;
var Companion_instance_7;
function Companion_getInstance_28() {
  return Companion_instance_7;
}
var SnakeDirection_entriesInitialized;
function SnakeDirection_initEntries() {
  if (SnakeDirection_entriesInitialized)
    return Unit_instance;
  SnakeDirection_entriesInitialized = true;
  SnakeDirection_UP_instance = new SnakeDirection('UP', 0, Vector2I.v2u(0, -1), false);
  SnakeDirection_DOWN_instance = new SnakeDirection('DOWN', 1, Vector2I.v2u(0, 1), false);
  SnakeDirection_LEFT_instance = new SnakeDirection('LEFT', 2, Vector2I.v2u(-1, 0), true);
  SnakeDirection_RIGHT_instance = new SnakeDirection('RIGHT', 3, Vector2I.v2u(1, 0), true);
  SnakeDirection_NONE_instance = new SnakeDirection('NONE', 4, Vector2I.v2u(0, 0), null);
}
function SnakeDirection_UP_getInstance() {
  SnakeDirection_initEntries();
  return SnakeDirection_UP_instance;
}
function SnakeDirection_DOWN_getInstance() {
  SnakeDirection_initEntries();
  return SnakeDirection_DOWN_instance;
}
function SnakeDirection_LEFT_getInstance() {
  SnakeDirection_initEntries();
  return SnakeDirection_LEFT_instance;
}
function SnakeDirection_RIGHT_getInstance() {
  SnakeDirection_initEntries();
  return SnakeDirection_RIGHT_instance;
}
function SnakeDirection_NONE_getInstance() {
  SnakeDirection_initEntries();
  return SnakeDirection_NONE_instance;
}
function get_EMPTY() {
  return EMPTY;
}
var EMPTY;
function get_APPLE() {
  return APPLE;
}
var APPLE;
function get_WALL() {
  return WALL;
}
var WALL;
function get_SNAKE() {
  return SNAKE;
}
var SNAKE;
var AppleProvider_instance;
function AppleProvider_getInstance() {
  if (AppleProvider_instance === VOID)
    new AppleProvider();
  return AppleProvider_instance;
}
var WallProvider_instance;
function WallProvider_getInstance() {
  if (WallProvider_instance === VOID)
    new WallProvider();
  return WallProvider_instance;
}
var SnakeProvider_instance;
function SnakeProvider_getInstance() {
  if (SnakeProvider_instance === VOID)
    new SnakeProvider();
  return SnakeProvider_instance;
}
var SnakeHeadProvider_instance;
function SnakeHeadProvider_getInstance() {
  if (SnakeHeadProvider_instance === VOID)
    new SnakeHeadProvider();
  return SnakeHeadProvider_instance;
}
var Companion_instance_8;
function Companion_getInstance_29() {
  return Companion_instance_8;
}
var Companion_instance_9;
function Companion_getInstance_30() {
  return Companion_instance_9;
}
function IngameScene$ingame$ref_1($boundThis) {
  var i = new IngameScene$ingame$ref($boundThis);
  var l = function (p0, $completion) {
    return i.xqf(p0, $completion);
  };
  l.callableName = 'ingame';
  l.$arity = 1;
  return l;
}
function *_generator_runStates__uxyoex(_this__u8e3s4, $this, startingFunc, $completion) {
  var tmp = $this.nqg_1.bqh(_this__u8e3s4, startingFunc, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_frame__oz9fc7(_this__u8e3s4, $this, $completion) {
  var tmp = $this.nqg_1.cqh(_this__u8e3s4, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_sceneInit__5zuhe0(_this__u8e3s4, $this, $completion) {
  var tmp = $this;
  var tmp_0 = readBitmap(get_resourcesVfs().bj('gfx/tiles.ase'), ASE_getInstance(), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  tmp.oqg_1 = tmp_0.q4p();
  $this.dqh();
  // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
  // Inline function 'korlibs.korge.scene.sceneContainer' call
  var defaultTransition = withEasing(get_AlphaTransition(), get_EASE_IN_OUT_QUAD(Companion_getInstance_19()));
  var name = 'sceneContainer';
  var size = Companion_getInstance_18().t2v_1;
  // Inline function 'korlibs.korge.scene.sceneContainer' call
  var tmp_1 = views($completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var views_0 = tmp_1;
  var width = size.km();
  var height = size.lm();
  var rwidth = width;
  var rheight = height;
  if (width === 0.0 && height === 0.0) {
    var tmp_2 = findFirstAscendant(_this__u8e3s4, IngameScene$sceneInit$lambda);
    var base = (tmp_2 == null ? true : tmp_2 instanceof FixedSizeContainer) ? tmp_2 : null;
    var tmp0_elvis_lhs = base == null ? null : base.j2y();
    rwidth = tmp0_elvis_lhs == null ? views_0.kb8_1.j2y() : tmp0_elvis_lhs;
    var tmp1_elvis_lhs = base == null ? null : base.k2y();
    rheight = tmp1_elvis_lhs == null ? views_0.kb8_1.k2y() : tmp1_elvis_lhs;
  }
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = SceneContainer.lbe(views_0, defaultTransition, name, Size2D.k2t(rwidth, rheight));
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.sceneContainer.<anonymous>' call
  var injects = [];
  // Inline function 'korlibs.time.seconds' call
  // Inline function 'korlibs.time.seconds' call
  var time = toDuration(0, DurationUnit_SECONDS_getInstance());
  var transition = this_1.gbe_1;
  // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
  var clazz = getKClass(OverlayScene);
  var injects_0 = injects.slice();
  var sceneInjector = this_1.fbe_1.ab7_1.q87().o87(getKClass(SceneContainer), this_1).o87(getKClass(Resources), new Resources(this_1.c15(), this_1.fbe_1.hbf().d3o_1, this_1.fbe_1.hbf()));
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n = 0;
  while (n < injects_0.length) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var inject = injects_0[_unary__edvuaz];
    var tmp_3 = getKClassFromExpression(inject);
    sceneInjector.o87(isInterface(tmp_3, KClass) ? tmp_3 : THROW_CCE(), inject);
  }
  // Inline function 'snake.scene.IngameScene.sceneInit.<anonymous>' call
  var newScene = new OverlayScene();
  // Inline function 'korlibs.logger.Logger.info' call
  var this_2 = Companion_getInstance_5().ec1_1;
  var level = Level_INFO_getInstance();
  var tmp_4;
  if (this_2.u3g(level)) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var tmp$ret$8 = 'Changing scene to... ' + toString(clazz) + ' ... ' + toString(newScene);
    this_2.v3g(level, tmp$ret$8);
    tmp_4 = Unit_instance;
  }
  if (true) {
    newScene.z87(sceneInjector);
    var tmp_5 = getKClassFromExpression(newScene);
    var tmp_6 = isInterface(tmp_5, KClass) ? tmp_5 : THROW_CCE();
    this_1.fbe_1.ab7_1.s87(tmp_6, IngameScene$sceneInit$lambda_0(sceneInjector));
  }
  var tmp_7 = this_1.gc1(newScene, time, transition, $completion);
  if (tmp_7 === get_COROUTINE_SUSPENDED())
    tmp_7 = yield tmp_7;
  return Unit_instance;
}
function *_generator_sceneMain__5xvlzl_6(_this__u8e3s4, $this, $completion) {
  var tmp = $this.bqh(_this__u8e3s4, IngameScene$ingame$ref_2($this), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_gameOver__jcjkm6($this, view, $completion) {
  // Inline function 'kotlin.with' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.alpha' call
  // Inline function 'korlibs.korge.view.solidRect' call
  var width = view.j2y();
  var height = view.k2y();
  var color = Colors_getInstance().h4r_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = SolidRect.ddj(Size2D.k2t(width, height), color);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, view);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  var background = alpha(this_1, numberToDouble(0.0));
  // Inline function 'korlibs.korge.view.text' call
  var text = 'GAME OVER';
  var alignment = Companion_getInstance_2().q5s_1;
  var color_0 = Colors_getInstance().g4r_1;
  var font = get_DefaultTtfFontAsBitmap();
  var renderer = get_DefaultStringTextRenderer();
  var autoScaling = Companion_instance_0.sfm_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_2 = Text.tfm(text, numberToDouble(1.0), color_0, font, alignment, renderer, autoScaling, null, null);
  // Inline function 'kotlin.apply' call
  var this_3 = addTo(this_2, view);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.text.<anonymous>' call
  var text_0 = xy(this_3, view.j2y() * 0.5, view.k2y() * 0.5);
  view.xb9_1 = 0.0;
  // Inline function 'korlibs.korge.input.keys' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'snake.scene.IngameScene.gameOver.<anonymous>.<anonymous>' call
  get_keys(view).xbl(IngameScene$gameOver$slambda_0($this));
  // Inline function 'korlibs.korge.tween.easeOut' call
  // Inline function 'korlibs.korge.tween.get' call
  var this_4 = textSize$factory(text_0);
  var tmp = this_4.get();
  var this_5 = new V2(this_4, tmp, 64.0, _interpolate$ref_4(), false);
  var tmp_0 = easing(this_5, get_EASE_OUT(Companion_getInstance_19()));
  // Inline function 'korlibs.korge.tween.get' call
  var this_6 = alpha$factory_1(background);
  var tmp_1 = this_6.get();
  var tmp_2 = [tmp_0, new V2(this_6, tmp_1, 0.5, _interpolate$ref_5(), false)];
  // Inline function 'kotlin.time.Companion.seconds' call
  Companion_getInstance();
  var tmp$ret$15 = toDuration(0.5, DurationUnit_SECONDS_getInstance());
  var tmp_3 = tween_0(view, tmp_2, tmp$ret$15, VOID, VOID, VOID, VOID, VOID, $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  return Unit_instance;
}
function IngameScene$tiles$delegate$lambda(this$0) {
  return function () {
    return splitInRows(slice(this$0.eqh()), 16, 16);
  };
}
function IngameScene$tileSet$delegate$lambda(this$0) {
  return function () {
    return Companion_getInstance_3().u71(16, 16, this$0.fqh(), 0);
  };
}
function IngameScene$tileMap$delegate$lambda(this$0) {
  return function () {
    // Inline function 'korlibs.korge.view.tiles.tileMap' call
    var this_0 = this$0.ybw();
    var map = TileMapData.z70(32, 32, this$0.gqh());
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = TileMap.nhr(map, map.v70_1, true, map.v70_1.c72()).shr(map.w70_1, map.x70_1);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.tiles.tileMap.<anonymous>' call
    return addTo(this_1, this_0);
  };
}
function IngameScene$tileMapRules$delegate$lambda() {
  return CombinedRuleMatcher.fpy([WallProvider_getInstance(), AppleProvider_getInstance()]);
}
function IngameScene$intMap$delegate$lambda$lambda(this$0) {
  return function ($this$observe, it) {
    var tmp = $this$observe.o33();
    IntGridToTileGrid(tmp instanceof IntArray2 ? tmp : THROW_CCE(), this$0.hqh(), this$0.dqh().ohq_1, it);
    return Unit_instance;
  };
}
function IngameScene$intMap$delegate$lambda(this$0) {
  return function () {
    // Inline function 'korlibs.datastructure.Companion.invoke' call
    var width = this$0.dqh().ohq_1.j2y();
    var height = this$0.dqh().ohq_1.k2y();
    var fill = get_EMPTY();
    var tmp = 0;
    var tmp_0 = imul(width, height);
    var tmp_1 = new Int32Array(tmp_0);
    while (tmp < tmp_0) {
      tmp_1[tmp] = fill;
      tmp = tmp + 1 | 0;
    }
    var tmp_2 = tmp_1;
    var tmp_3 = new IntArray2(width, height, isIntArray(tmp_2) ? tmp_2 : THROW_CCE());
    return observe(tmp_3, IngameScene$intMap$delegate$lambda$lambda(this$0));
  };
}
function IngameScene$gameInfo$delegate$lambda(this$0) {
  return function (it) {
    this$0.ybw().v8a(new GameInfoUpdatedEvent(it));
    return Unit_instance;
  };
}
function IngameScene$sceneInit$lambda(it) {
  return it instanceof FixedSizeContainer;
}
function IngameScene$sceneInit$lambda_0($sceneInjector) {
  return function ($this$mapPrototype) {
    // Inline function 'snake.scene.IngameScene.sceneInit.<anonymous>' call
    return new OverlayScene();
  };
}
function IngameScene$ingame$ref_2($boundThis) {
  var i = new IngameScene$ingame$ref_0($boundThis);
  var l = function (p0, $completion) {
    return i.xqf(p0, $completion);
  };
  l.callableName = 'ingame';
  l.$arity = 1;
  return l;
}
function IngameScene$ingame$lambda(this$0, $direction) {
  return function () {
    this$0.jqh($direction._v);
    return Unit_instance;
  };
}
function IngameScene$ingame$slambda_4($direction) {
  var i = new IngameScene$ingame$slambda($direction);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function IngameScene$ingame$slambda_5($direction) {
  var i = new IngameScene$ingame$slambda_0($direction);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function IngameScene$ingame$slambda_6($direction) {
  var i = new IngameScene$ingame$slambda_1($direction);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function IngameScene$ingame$slambda_7($direction) {
  var i = new IngameScene$ingame$slambda_2($direction);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function IngameScene$ingame$slambda_8($view) {
  var i = new IngameScene$ingame$slambda_3($view);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function IngameScene$gameOver$slambda_0(this$0) {
  var i = new IngameScene$gameOver$slambda(this$0);
  var l = function (it, $completion) {
    return i.sbl(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function _interpolate$ref_4() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_5() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function IngameScene$gameOver$ref_0($boundThis) {
  var i = new IngameScene$gameOver$ref($boundThis);
  var l = function (p0, $completion) {
    return i.xqf(p0, $completion);
  };
  l.callableName = 'gameOver';
  l.$arity = 1;
  return l;
}
function tiles$factory() {
  return getPropertyCallableRef('tiles', 1, KProperty1, function (receiver) {
    return receiver.fqh();
  }, null);
}
function tileSet$factory() {
  return getPropertyCallableRef('tileSet', 1, KProperty1, function (receiver) {
    return receiver.gqh();
  }, null);
}
function tileMap$factory() {
  return getPropertyCallableRef('tileMap', 1, KProperty1, function (receiver) {
    return receiver.dqh();
  }, null);
}
function tileMapRules$factory() {
  return getPropertyCallableRef('tileMapRules', 1, KProperty1, function (receiver) {
    return receiver.hqh();
  }, null);
}
function intMap$factory() {
  return getPropertyCallableRef('intMap', 1, KProperty1, function (receiver) {
    return receiver.wqh();
  }, null);
}
function gameInfo$factory() {
  return getPropertyCallableRef('gameInfo', 1, KMutableProperty1, function (receiver) {
    return receiver.yqh();
  }, function (receiver, value) {
    return receiver.xqh(value);
  });
}
function gameInfo$factory_0() {
  return getPropertyCallableRef('gameInfo', 1, KMutableProperty1, function (receiver) {
    return receiver.yqh();
  }, function (receiver, value) {
    return receiver.xqh(value);
  });
}
function textSize$factory($b0) {
  return getPropertyCallableRef('textSize', 0, KMutableProperty0, function () {
    return $b0.icj_1;
  }, function (value) {
    return $b0.xcp(value);
  });
}
function alpha$factory_1($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function sceneMain$updateGameInfo(scoreText, hiScoreBeatShown, this$0, old, new_0) {
  scoreText.bcg('Score: ' + new_0.wqe_1 + ', HI-Score: ' + new_0.xqe_1);
  if (new_0.xqe_1 > old.xqe_1 && !hiScoreBeatShown._v) {
    hiScoreBeatShown._v = true;
    // Inline function 'korlibs.korge.view.alpha' call
    // Inline function 'korlibs.korge.view.text' call
    var this_0 = this$0.ybw();
    var text = 'Hi-Score beaten';
    var color = Colors_getInstance().g4r_1;
    var font = get_DefaultTtfFontAsBitmap();
    var alignment = Companion_getInstance_2().h5s_1;
    var renderer = get_DefaultStringTextRenderer();
    var autoScaling = Companion_instance_0.sfm_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = Text.tfm(text, numberToDouble(32.0), color, font, alignment, renderer, autoScaling, null, null);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    var this_3 = centerOnStage(this_2);
    var text_0 = alpha(this_3, numberToDouble(0.0));
    var tmp = this$0.ybw();
    animator(tmp, VOID, VOID, VOID, VOID, VOID, VOID, OverlayScene$sceneMain$updateGameInfo$lambda(text_0));
  }
}
function OverlayScene$sceneMain$lambda($hiScoreBeatShown) {
  return function (it) {
    $hiScoreBeatShown._v = false;
    return Unit_instance;
  };
}
function OverlayScene$sceneMain$lambda_0($current, $scoreText, $hiScoreBeatShown, this$0) {
  return function (it) {
    sceneMain$updateGameInfo($scoreText, $hiScoreBeatShown, this$0, $current._v, it.vqf_1);
    $current._v = it.vqf_1;
    return Unit_instance;
  };
}
function OverlayScene$sceneMain$updateGameInfo$lambda$lambda($text) {
  return function () {
    $text.qbh();
    return Unit_instance;
  };
}
function OverlayScene$sceneMain$updateGameInfo$lambda($text) {
  return function ($this$animator) {
    // Inline function 'korlibs.korge.animate.Animator.parallel' call
    var time = $this$animator.ibh();
    var speed = $this$animator.abg_1;
    var easing = $this$animator.bbg_1;
    var startImmediately = $this$animator.hbg_1;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.also' call
    var tmp7_root = $this$animator.ybf_1;
    var tmp8_fastDefaultTime = get_fast(time);
    var tmp9_level = $this$animator.gbg_1 + 1 | 0;
    var this_0 = new Animator(tmp7_root, tmp8_fastDefaultTime, speed, easing, true, false, $this$animator, VOID, tmp9_level, startImmediately);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.parallel.<anonymous>' call
    // Inline function 'snake.scene.OverlayScene.sceneMain.updateGameInfo.<anonymous>.<anonymous>' call
    show(this_0, $text);
    moveBy(this_0, $text, 0.0, 32.0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.animate.Animator.parallel.<anonymous>' call
    $this$animator.kbh(this_0.obg_1);
    hide($this$animator, $text);
    block($this$animator, VOID, OverlayScene$sceneMain$updateGameInfo$lambda$lambda($text));
    return Unit_instance;
  };
}
function *_generator_main__qlaff5_2(_this__u8e3s4, $this, $completion) {
  // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
  // Inline function 'korlibs.korge.scene.sceneContainer' call
  var defaultTransition = withEasing(get_AlphaTransition(), get_EASE_IN_OUT_QUAD(Companion_getInstance_19()));
  var name = 'sceneContainer';
  var size = Companion_getInstance_18().t2v_1;
  // Inline function 'korlibs.korge.scene.sceneContainer' call
  var tmp = views($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var views_0 = tmp;
  var width = size.km();
  var height = size.lm();
  var rwidth = width;
  var rheight = height;
  if (width === 0.0 && height === 0.0) {
    var tmp_0 = findFirstAscendant(_this__u8e3s4, SnakeScene$main$lambda);
    var base = (tmp_0 == null ? true : tmp_0 instanceof FixedSizeContainer) ? tmp_0 : null;
    var tmp0_elvis_lhs = base == null ? null : base.j2y();
    rwidth = tmp0_elvis_lhs == null ? views_0.kb8_1.j2y() : tmp0_elvis_lhs;
    var tmp1_elvis_lhs = base == null ? null : base.k2y();
    rheight = tmp1_elvis_lhs == null ? views_0.kb8_1.k2y() : tmp1_elvis_lhs;
  }
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = SceneContainer.lbe(views_0, defaultTransition, name, Size2D.k2t(rwidth, rheight));
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.scene.sceneContainer.<anonymous>' call
  var injects = [];
  // Inline function 'korlibs.time.seconds' call
  // Inline function 'korlibs.time.seconds' call
  var time = toDuration(0, DurationUnit_SECONDS_getInstance());
  var transition = this_1.gbe_1;
  // Inline function 'korlibs.korge.scene.SceneContainer.changeTo' call
  var clazz = getKClass(IngameScene);
  var injects_0 = injects.slice();
  var sceneInjector = this_1.fbe_1.ab7_1.q87().o87(getKClass(SceneContainer), this_1).o87(getKClass(Resources), new Resources(this_1.c15(), this_1.fbe_1.hbf().d3o_1, this_1.fbe_1.hbf()));
  // Inline function 'korlibs.datastructure.iterators.fastForEach' call
  var n = 0;
  while (n < injects_0.length) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var _unary__edvuaz = n;
    n = _unary__edvuaz + 1 | 0;
    var inject = injects_0[_unary__edvuaz];
    var tmp_1 = getKClassFromExpression(inject);
    sceneInjector.o87(isInterface(tmp_1, KClass) ? tmp_1 : THROW_CCE(), inject);
  }
  // Inline function 'snake.scene.SnakeScene.main.<anonymous>' call
  var newScene = new IngameScene();
  // Inline function 'korlibs.logger.Logger.info' call
  var this_2 = Companion_getInstance_5().ec1_1;
  var level = Level_INFO_getInstance();
  var tmp_2;
  if (this_2.u3g(level)) {
    // Inline function 'korlibs.korge.scene.SceneContainer.changeTo.<anonymous>' call
    var tmp$ret$8 = 'Changing scene to... ' + toString(clazz) + ' ... ' + toString(newScene);
    this_2.v3g(level, tmp$ret$8);
    tmp_2 = Unit_instance;
  }
  if (true) {
    newScene.z87(sceneInjector);
    var tmp_3 = getKClassFromExpression(newScene);
    var tmp_4 = isInterface(tmp_3, KClass) ? tmp_3 : THROW_CCE();
    this_1.fbe_1.ab7_1.s87(tmp_4, SnakeScene$main$lambda_0(sceneInjector));
  }
  var tmp_5 = this_1.gc1(newScene, time, transition, $completion);
  if (tmp_5 === get_COROUTINE_SUSPENDED())
    tmp_5 = yield tmp_5;
  return Unit_instance;
}
function SnakeScene$main$lambda(it) {
  return it instanceof FixedSizeContainer;
}
function SnakeScene$main$lambda_0($sceneInjector) {
  return function ($this$mapPrototype) {
    // Inline function 'snake.scene.SnakeScene.main.<anonymous>' call
    return new IngameScene();
  };
}
function *_generator_runStates__uxyoex_0(_this__u8e3s4, $this, startingFunc, $completion) {
  var func = startingFunc;
  while (true) {
    // Inline function 'korlibs.korge.view.fixedSizeContainer' call
    var size = _this__u8e3s4.j1();
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = FixedSizeContainer.sfh(size, false);
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fixedSizeContainer.<anonymous>' call
    var stateView = addTo(this_0, _this__u8e3s4);
    try {
      var tmp = func(stateView, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
      while (true) {
        var tmp_0 = $this.cqh(_this__u8e3s4, $completion);
        if (tmp_0 === get_COROUTINE_SUSPENDED())
          tmp_0 = yield tmp_0;
      }
    } catch ($p) {
      if ($p instanceof ChangeSceneException) {
        var e = $p;
        func = e.wqi_1;
      } else {
        throw $p;
      }
    }
    finally {
      stateView.qbh();
    }
  }
  return Unit_instance;
}
function *_generator_frame__oz9fc7_0(_this__u8e3s4, $this, $completion) {
  var newState = $this.vqh();
  if (!(newState == null)) {
    $this.tqh(null);
    throw ChangeSceneException.yqi(newState);
  }
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp$ret$1 = toDuration(16, DurationUnit_MILLISECONDS_getInstance());
  var tmp = delay(_this__u8e3s4, tmp$ret$1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_main__qlaff5_3(_this__u8e3s4, $this, $completion) {
  var baseVfs = get_resourcesVfs().bj('spine/spineboy');
  var tmp = readAtlas(baseVfs.bj('spineboy-pma.atlas'), Companion_getInstance_0().s4m_1.f6m(VOID, VOID, VOID, VOID, true), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var atlas = tmp;
  var tmp_0 = readSkeletonBinary(baseVfs.bj('spineboy-pro.skel'), atlas, 0.4, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var skeletonData = tmp_0;
  var skeleton = Skeleton.mpn(skeletonData);
  var stateData = new AnimationStateData(skeletonData);
  stateData.ypk('run', 'jump', 0.2);
  stateData.ypk('jump', 'run', 0.2);
  var state = AnimationState.tpj(stateData);
  state.qpi_1 = 1.1;
  state.xpj(0, 'run', true);
  state.tpk(0, 'jump', false, 2.0);
  state.tpk(0, 'run', true, 0.0);
  state.upj(1.0 / 60.0);
  state.vpj(skeleton);
  skeleton.qpn();
  // Inline function 'korlibs.korge.view.container' call
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Container.vbw(false);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'spine.SpineScene.main.<anonymous>' call
  this_1.xb9_1 = 1.0;
  scale(this_1, 2.0);
  position(this_1, $this.vbk().eb8_1 * 0.5, $this.vbk().fb8_1 * 0.9);
  // Inline function 'com.esotericsoftware.spine.korge.skeletonView' call
  // Inline function 'korlibs.korge.view.addTo' call
  var this_2 = SkeletonView.jpw(skeleton, state);
  // Inline function 'kotlin.apply' call
  addTo(this_2, this_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'com.esotericsoftware.spine.korge.skeletonView.<anonymous>' call
  // Inline function 'korlibs.korge.view.solidRect' call
  var color = Colors_getInstance().i4r_1;
  // Inline function 'korlibs.korge.view.addTo' call
  var this_3 = SolidRect.ddj(Size2D.k2t(10.0, 10.0), color);
  // Inline function 'kotlin.apply' call
  var this_4 = addTo(this_3, this_1);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
  get_centered(this_4);
  this_1.hbq(false);
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_32($this, $completion) {
  var tmp = openFileDialog($this.kqj_1.xbw(), FileFilter.mam([to('SWF files', listOf_0('*.swf'))]), false, false, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var files = tmp;
  main$loadSwf($this.kqj_1, $this.lqj_1, $this.mqj_1, files);
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_33($this, $completion) {
  $this.nqj_1.nca();
  var _iterator__ex2g4s = $this.oqj_1.f1();
  while (_iterator__ex2g4s.g1()) {
    var file = _iterator__ex2g4s.h1();
    var tmp = readSWF(file, $this.pqj_1.vbk(), $this.pqj_1.eqk_1, false, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var swf = tmp;
    swf.foj_1 = $this.pqj_1.dqk_1;
    var timeline = swf.aow();
    $this.nqj_1.ubw(timeline);
    var realBounds = applyScaleMode(Companion_getInstance_13().d2t(0, 0, swf.coj_1, swf.doj_1), $this.qqj_1.wbp(), Companion_getInstance_11().q2v(), Companion_getInstance_10().f2h());
    sizeScaled(xy(timeline, realBounds.i2i_1, realBounds.j2i_1), Size2D.k2t(realBounds.k2i_1, realBounds.l2i_1));
    // Inline function 'korlibs.korge.ui.uiHorizontalStack' call
    var this_0 = $this.nqj_1;
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(UIHorizontalStack.dek(null == null ? null : numberToDouble(null), numberToDouble(0.0), true), this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'swf.SWFScene.main.loadSwf.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiComboBox' call
    var items = timeline.uoj();
    var size = get_UI_DEFAULT_SIZE();
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiComboBox.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'swf.SWFScene.main.loadSwf.<anonymous>.<anonymous>.<anonymous>' call
    addTo(UIComboBox.mdy(size, 0, items), this_1).tdp_1.w1x(SWFScene$main$loadSwf$slambda$lambda(timeline));
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.ui.uiComboBox' call
    var items_0 = toList(values());
    var size_0 = get_UI_DEFAULT_SIZE();
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(UIComboBox.mdy(size_0, 0, items_0), this_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiComboBox.<anonymous>' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'swf.SWFScene.main.loadSwf.<anonymous>.<anonymous>.<anonymous>' call
    this_2.pe1($this.pqj_1.dqk_1);
    this_2.tdp_1.w1x(SWFScene$main$loadSwf$slambda$lambda_0($this.pqj_1, swf));
  }
  return Unit_instance;
}
function SWFScene$main$loadSwf$slambda$lambda($timeline) {
  return function (it) {
    var tmp66_safe_receiver = it.idx();
    if (tmp66_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      $timeline.yoj(tmp66_safe_receiver);
    }
    return Unit_instance;
  };
}
function SWFScene$main$loadSwf$slambda$lambda_0(this$0, $swf) {
  return function (it) {
    var tmp67_safe_receiver = it.idx();
    if (tmp67_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      this$0.dqk_1 = tmp67_safe_receiver;
      $swf.foj_1 = tmp67_safe_receiver;
    }
    return Unit_instance;
  };
}
function *_generator_main__qlaff5_4(_this__u8e3s4, $this, $completion) {
  // Inline function 'korlibs.korge.view.container' call
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Container.vbw(false);
  // Inline function 'kotlin.apply' call
  var this_1 = addTo(this_0, _this__u8e3s4);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'swf.SWFScene.main.<anonymous>' call
  var tmp = readSWF(get_resourcesVfs().bj('swf/morph.swf'), $this.vbk(), $this.eqk_1, false, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  this_1.ubw(tmp.aow());
  var tmp_0 = readSWF(get_resourcesVfs().bj('swf/dog.swf'), $this.vbk(), $this.eqk_1, false, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  this_1.ubw(tmp_0.aow());
  // Inline function 'korlibs.korge.view.position' call
  var tmp_1 = readSWF(get_resourcesVfs().bj('swf/test1.swf'), $this.vbk(), $this.eqk_1, false, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var this_2 = tmp_1.aow();
  var tmp$ret$0 = xy(this_2, numberToDouble(400), numberToDouble(0));
  this_1.ubw(tmp$ret$0);
  var tmp_2 = readSWF(get_resourcesVfs().bj('swf/demo3.swf'), $this.vbk(), $this.eqk_1, false, $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  this_1.ubw(tmp_2.aow());
  var extraSwfContainer = this_1;
  // Inline function 'korlibs.korge.view.xy' call
  var this_3 = UIButton.sd2(VOID, 'Load or drag SWF...');
  var tmp_3 = xy(this_3, numberToDouble(510), numberToDouble(0));
  _this__u8e3s4.ubw(clicked(tmp_3, SWFScene$main$lambda($this, extraSwfContainer, _this__u8e3s4)));
  onDropFile(_this__u8e3s4, SWFScene$main$lambda_0($this, extraSwfContainer, _this__u8e3s4));
  return Unit_instance;
}
function main$loadSwf(this$0, extraSwfContainer, $this_main, files) {
  if (files.a1())
    return Unit_instance;
  launchImmediately_0(this$0, SWFScene$main$loadSwf$slambda_0(extraSwfContainer, files, this$0, $this_main));
}
function SWFScene$main$lambda$slambda_0(this$0, $extraSwfContainer, $this_main) {
  var i = new SWFScene$main$lambda$slambda(this$0, $extraSwfContainer, $this_main);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SWFScene$main$lambda(this$0, $extraSwfContainer, $this_main) {
  return function (it) {
    launchImmediately_0(this$0, SWFScene$main$lambda$slambda_0(this$0, $extraSwfContainer, $this_main));
    return Unit_instance;
  };
}
function SWFScene$main$lambda_0(this$0, $extraSwfContainer, $this_main) {
  return function (it) {
    println('DropFileEvent: ' + it.toString());
    var tmp;
    if (it.f8e_1.equals(Type_DROP_getInstance())) {
      var tmp0_elvis_lhs = it.g8e_1;
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        return Unit_instance;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var files = tmp_0;
      main$loadSwf(this$0, $extraSwfContainer, $this_main, files);
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function SWFScene$main$loadSwf$slambda_0($extraSwfContainer, $files, this$0, $this_main) {
  var i = new SWFScene$main$loadSwf$slambda($extraSwfContainer, $files, this$0, $this_main);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function dockedTo(_this__u8e3s4, anchor, scaleMode, offset, hook) {
  scaleMode = scaleMode === VOID ? Companion_getInstance_11().p2v_1 : scaleMode;
  offset = offset === VOID ? Vector2D.i2l() : offset;
  var tmp;
  if (hook === VOID) {
    tmp = dockedTo$lambda;
  } else {
    tmp = hook;
  }
  hook = tmp;
  new DockingComponent(_this__u8e3s4, anchor, scaleMode, offset, hook);
  return _this__u8e3s4;
}
function DockingComponent$lambda(this$0) {
  return function ($this$onStageResized, width, height) {
    position(this$0.fqk_1, interpolate_0(this$0.gqk_1.l2h(), $this$onStageResized.lb7_1.mag(), $this$onStageResized.lb7_1.oag()) + this$0.iqk_1.i2h_1, interpolate_0(this$0.gqk_1.m2h(), $this$onStageResized.lb7_1.nag(), $this$onStageResized.lb7_1.pag()) + this$0.iqk_1.j2h_1);
    var tmp;
    if (!equals($this$onStageResized.hb8_1, Companion_getInstance_11().p2v_1)) {
      this$0.lqk_1 = Size2D.f2j($this$onStageResized.lb7_1.kag(), $this$onStageResized.lb7_1.lag());
      var size = $this$onStageResized.hb8_1.o2u(this$0.kqk_1, this$0.lqk_1);
      this$0.mqk_1 = size;
      size_0(this$0.fqk_1, size);
      tmp = Unit_instance;
    }
    this$0.fqk_1.cca();
    var tmp68_safe_receiver = this$0.fqk_1.w15();
    if (tmp68_safe_receiver == null)
      null;
    else {
      tmp68_safe_receiver.cca();
    }
    this$0.jqk_1(this$0.fqk_1);
    return Unit_instance;
  };
}
function dockedTo$lambda(it) {
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_34($this, $completion) {
  var tmp = $this.nqk_1($this.oqk_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_invoke__zhh2q8_35($this, $completion) {
  var tmp = $this.pqk_1($this.qqk_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function SimpleUIScene$sceneMain$slambda_1(this$0, $container) {
  var i = new SimpleUIScene$sceneMain$slambda(this$0, $container);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function SimpleUIScene$sceneMain$lambda$slambda_1($handler, $it) {
  var i = new SimpleUIScene$sceneMain$lambda$slambda($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SimpleUIScene$sceneMain$lambda($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, SimpleUIScene$sceneMain$lambda$slambda_1($handler, it));
    return Unit_instance;
  };
}
function SimpleUIScene$sceneMain$slambda_2(this$0, $container) {
  var i = new SimpleUIScene$sceneMain$slambda_0(this$0, $container);
  var l = function (it, $completion) {
    return i.tbo(it, $completion);
  };
  l.$arity = 1;
  return l;
}
function SimpleUIScene$sceneMain$lambda$slambda_2($handler, $it) {
  var i = new SimpleUIScene$sceneMain$lambda$slambda_0($handler, $it);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function SimpleUIScene$sceneMain$lambda_0($mouse, $handler) {
  return function (it) {
    var tmp = $mouse.wbo();
    launchImmediately(tmp, SimpleUIScene$sceneMain$lambda$slambda_2($handler, it));
    return Unit_instance;
  };
}
function _interpolate$ref_6() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_7() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_8() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_9() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function _interpolate$ref_10() {
  var l = function (p0, p1, p2) {
    return _interpolate(p0.e10_1, p1, p2);
  };
  l.callableName = '_interpolate';
  return l;
}
function SimpleUIScene$openPropertiesWindow$lambda(this$0) {
  return function (it) {
    this$0.mql().car(it.hdj());
    return Unit_instance;
  };
}
function set_rotationDeg(_this__u8e3s4, value) {
  _this__u8e3s4.acc(get_degrees_0(value));
}
function get_rotationDeg(_this__u8e3s4) {
  return _Angle___get_degrees__impl__qg56vw(_this__u8e3s4.bcc());
}
function set_skewXDeg(_this__u8e3s4, value) {
  _this__u8e3s4.wcb(get_degrees_0(value));
}
function get_skewXDeg(_this__u8e3s4) {
  return _Angle___get_degrees__impl__qg56vw(_this__u8e3s4.xcb());
}
function set_skewYDeg(_this__u8e3s4, value) {
  _this__u8e3s4.ycb(get_degrees_0(value));
}
function get_skewYDeg(_this__u8e3s4) {
  return _Angle___get_degrees__impl__qg56vw(_this__u8e3s4.zcb());
}
function click$factory_16() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function click$factory_17() {
  return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
    return receiver.zbh_1;
  }, null);
}
function x$factory_0($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function y$factory($b0) {
  return getPropertyCallableRef('y', 0, KMutableProperty0, function () {
    return $b0.p4f();
  }, function (value) {
    return $b0.sbh(value);
  });
}
function width$factory($b0) {
  return getPropertyCallableRef('width', 0, KMutableProperty0, function () {
    return $b0.j2y();
  }, function (value) {
    return $b0.fek(value);
  });
}
function height$factory($b0) {
  return getPropertyCallableRef('height', 0, KMutableProperty0, function () {
    return $b0.k2y();
  }, function (value) {
    return $b0.gek(value);
  });
}
function alpha$factory_2($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function alpha$factory_3($b0) {
  return getPropertyCallableRef('alpha', 0, KMutableProperty0, function () {
    return $b0.ncc();
  }, function (value) {
    return $b0.kbj(value);
  });
}
function x$factory_1($b0) {
  return getPropertyCallableRef('x', 0, KMutableProperty0, function () {
    return $b0.n4f();
  }, function (value) {
    return $b0.rbh(value);
  });
}
function y$factory_0($b0) {
  return getPropertyCallableRef('y', 0, KMutableProperty0, function () {
    return $b0.p4f();
  }, function (value) {
    return $b0.sbh(value);
  });
}
function width$factory_0($b0) {
  return getPropertyCallableRef('width', 0, KMutableProperty0, function () {
    return $b0.j2y();
  }, function (value) {
    return $b0.fek(value);
  });
}
function height$factory_0($b0) {
  return getPropertyCallableRef('height', 0, KMutableProperty0, function () {
    return $b0.k2y();
  }, function (value) {
    return $b0.gek(value);
  });
}
function scaleX$factory($b0) {
  return getPropertyCallableRef('scaleX', 0, KMutableProperty0, function () {
    return $b0.rcb();
  }, function (value) {
    return $b0.qcb(value);
  });
}
function scaleY$factory($b0) {
  return getPropertyCallableRef('scaleY', 0, KMutableProperty0, function () {
    return $b0.tcb();
  }, function (value) {
    return $b0.scb(value);
  });
}
function rotationDeg$factory($b0) {
  return getPropertyCallableRef('rotationDeg', 0, KMutableProperty0, function () {
    return get_rotationDeg($b0);
  }, function (value) {
    return set_rotationDeg($b0, value);
  });
}
function skewXDeg$factory($b0) {
  return getPropertyCallableRef('skewXDeg', 0, KMutableProperty0, function () {
    return get_skewXDeg($b0);
  }, function (value) {
    return set_skewXDeg($b0, value);
  });
}
function skewYDeg$factory($b0) {
  return getPropertyCallableRef('skewYDeg', 0, KMutableProperty0, function () {
    return get_skewYDeg($b0);
  }, function (value) {
    return set_skewYDeg($b0, value);
  });
}
function get_ext() {
  _init_properties_JsExt_kt__ta98ii();
  return ext;
}
var ext;
function toList_1(_this__u8e3s4) {
  _init_properties_JsExt_kt__ta98ii();
  // Inline function 'kotlin.collections.map' call
  var this_0 = until(0, _this__u8e3s4.length);
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'extension.toList.<anonymous>' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'org.w3c.dom.get' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$4 = _this__u8e3s4[item];
    destination.f(tmp$ret$4);
  }
  return destination;
}
function *_generator_invoke__zhh2q8_36($this, $completion) {
  // Inline function 'korlibs.time.milliseconds' call
  // Inline function 'kotlin.time.Companion.milliseconds' call
  Companion_getInstance();
  var tmp$ret$1 = toDuration(150, DurationUnit_MILLISECONDS_getInstance());
  var tmp = delay_0(tmp$ret$1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0;
  try {
    var tmp_1 = window;
    var tmp_2 = 'https://raw.githubusercontent.com/korlibs/show.korge.org/main/src/commonMain/kotlin/' + $this.nql_1.aqb_1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2 = jsObject([to('importance', 'low')]);
    var tmp_3 = await_0(tmp_1.fetch(tmp_2, tmp$ret$2), $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    var tmp_4 = await_0(tmp_3.text(), $completion);
    if (tmp_4 === get_COROUTINE_SUSPENDED())
      tmp_4 = yield tmp_4;
    tmp_0 = tmp_4;
  } catch ($p) {
    var tmp_5;
    if ($p instanceof Error) {
      var e = $p;
      var tmp0_safe_receiver = e.message;
      var tmp0_elvis_lhs = tmp0_safe_receiver == null ? null : toString(tmp0_safe_receiver);
      tmp_5 = tmp0_elvis_lhs == null ? 'Error' : tmp0_elvis_lhs;
    } else {
      throw $p;
    }
    tmp_0 = tmp_5;
  }
  var content = tmp_0;
  $this.oql_1.setValue(content, 1);
  return Unit_instance;
}
function init$lambda($editor$delegate) {
  // Inline function 'kotlin.getValue' call
  getLocalDelegateReference('editor', KProperty0, false, function () {
    return THROW_ISE();
  });
  return $editor$delegate.b2();
}
function ext$o$canvasQuery$delegate$lambda() {
  return document.querySelector('#mycustomcanvas');
}
function ext$o$init$lambda() {
  var tmp;
  // Inline function 'kotlin.js.asDynamic' call
  if (window.ace) {
    tmp = ace.edit('editor');
  } else {
    tmp = null;
  }
  return tmp;
}
function ext$o$init$lambda$slambda_0($sceneInfo, $editor) {
  var i = new ext$o$init$lambda$slambda($sceneInfo, $editor);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function ext$o$init$lambda_0($registeredScenes, $stage, $editor$delegate) {
  return function (detail) {
    var className = toString_0(detail);
    var sceneId = 'scene-' + className;
    var sceneInfo = $registeredScenes.k3(className);
    var editor = init$lambda($editor$delegate);
    var tmp;
    if (!(sceneInfo == null) && !(editor == null)) {
      try {
        editor.setTheme('ace/theme/monokai');
        editor.session.setMode('ace/mode/kotlin');
        editor.setReadOnly(true);
        var tmp_0 = $stage.vbk().yb6_1;
        launchImmediately(tmp_0, ext$o$init$lambda$slambda_0(sceneInfo, editor));
      } catch ($p) {
        var e = $p;
        console.error(e);
      }
      tmp = Unit_instance;
    }
    var _iterator__ex2g4s = toList_1(document.querySelectorAll('a.active')).f1();
    while (_iterator__ex2g4s.g1()) {
      var active = _iterator__ex2g4s.h1();
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      removeClass(active, ['active']);
    }
    var tmp1_safe_receiver = document.querySelector('#' + sceneId);
    if (tmp1_safe_receiver == null)
      null;
    else
      addClass(tmp1_safe_receiver, ['active']);
    return Unit_instance;
  };
}
function ext$o$registerEvent$lambda($handler) {
  return function (ev) {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    $handler(ev.detail);
    return Unit_instance;
  };
}
function canvasQuery$factory() {
  return getPropertyCallableRef('canvasQuery', 1, KProperty1, function (receiver) {
    return receiver.qql();
  }, null);
}
var properties_initialized_JsExt_kt_ulb9zc;
function _init_properties_JsExt_kt__ta98ii() {
  if (!properties_initialized_JsExt_kt_ulb9zc) {
    properties_initialized_JsExt_kt_ulb9zc = true;
    ext = new ext$1();
  }
}
//region block: post-declaration
initMetadataForInterface(KR, 'KR');
protoOf(KR_0).gpy = get_fonts;
protoOf(KR_0).hpy = get_gfx;
initMetadataForObject(KR_0, 'KR', VOID, VOID, [KR]);
initMetadataForObject(KRFonts, 'KRFonts');
initMetadataForObject(KRGfx, 'KRGfx');
initMetadataForClass(AseGfxWizardFAse, 'AseGfxWizardFAse');
initMetadataForLambda(MainUIImageTester$main$slambda, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_2, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_2, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_3, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_3, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_4, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_4, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_5, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_5, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_6, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_6, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_7, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_7, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_8, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_8, VOID, VOID, [0]);
initMetadataForLambda(MainUIImageTester$main$slambda_9, VOID, VOID, [1]);
initMetadataForLambda(MainUIImageTester$main$lambda$slambda_9, VOID, VOID, [0]);
initMetadataForClass(ShowScene, 'ShowScene', VOID, VOID, VOID, [0]);
initMetadataForClass(AutoShowScene, 'AutoShowScene', VOID, VOID, VOID, [0]);
initMetadataForClass(MainUIImageTester, 'MainUIImageTester', MainUIImageTester, VOID, VOID, [0]);
initMetadataForLambda(SimpleBox2dScene$sceneMain$slambda, VOID, VOID, [1]);
initMetadataForLambda(SimpleBox2dScene$sceneMain$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(SimpleBox2dScene$sceneMain$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(SimpleBox2dScene$sceneMain$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForClass(SimpleBox2dScene, 'SimpleBox2dScene', SimpleBox2dScene, VOID, VOID, [0]);
initMetadataForClass(BunnyContainer, 'BunnyContainer');
initMetadataForClass(BunnymarkScene, 'BunnymarkScene', BunnymarkScene, VOID, VOID, [0]);
initMetadataForLambda(DungeonScene$sceneMain$slambda, VOID, VOID, [1]);
initMetadataForLambda(DungeonScene$sceneMain$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(DungeonScene$sceneMain$_anonymous_$onAnyButton$slambda_aqssrx, VOID, VOID, [0]);
initMetadataForClass(DungeonScene, 'DungeonScene', DungeonScene, VOID, VOID, [0]);
initMetadataForClass(ImageDataView2, 'ImageDataView2', ImageDataView2.uq5, VOID, [Container, Anchorable]);
initMetadataForClass(ImageAnimationView2, 'ImageAnimationView2', VOID, VOID, [Container, Anchorable]);
initMetadataForClass(BvhWorld, 'BvhWorld');
initMetadataForClass(BvhEntity, 'BvhEntity');
initMetadataForCompanion(Companion);
protoOf(ViewRenderPhaseMask).jh8 = beforeRender;
protoOf(ViewRenderPhaseMask).kh8 = afterRender;
initMetadataForClass(ViewRenderPhaseMask, 'ViewRenderPhaseMask', VOID, VOID, [ViewRenderPhase]);
initMetadataForClass(RayResult, 'RayResult');
initMetadataForLambda(EasingsScene$sceneMain$renderEasing$slambda, VOID, VOID, [1]);
initMetadataForLambda(EasingsScene$sceneMain$renderEasing$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(EasingsScene$sceneMain$renderEasing$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(EasingsScene$sceneMain$renderEasing$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(EasingsScene$sceneMain$renderEasing$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(EasingsScene$sceneMain$renderEasing$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForClass(EasingsScene, 'EasingsScene', EasingsScene, VOID, VOID, [0]);
initMetadataForClass(Ext, 'Ext', Ext);
initMetadataForClass(SceneInfo, 'SceneInfo');
initMetadataForClass(FiltersScene, 'FiltersScene', FiltersScene, VOID, VOID, [0]);
initMetadataForClass(GesturesScene, 'GesturesScene', GesturesScene, VOID, VOID, [0]);
initMetadataForLambda(K3DScene$main$slambda, VOID, VOID, [1]);
initMetadataForLambda(K3DScene$main$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(K3DScene$main$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(K3DScene$main$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(K3DScene$main$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForLambda(K3DScene$main$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(K3DScene$main$lambda$slambda_2, VOID, VOID, [0]);
initMetadataForClass(K3DScene, 'K3DScene', K3DScene, VOID, VOID, [0, 3]);
initMetadataForLambda(main$slambda$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(main$slambda$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForLambda(main$slambda$lambda$slambda_1, VOID, VOID, [0]);
initMetadataForLambda(main$slambda, VOID, VOID, [1]);
initMetadataForLambda(addFullScreenButton$slambda, VOID, VOID, [1]);
initMetadataForLambda(addFullScreenButton$lambda$slambda, VOID, VOID, [0]);
initMetadataForClass(Scene1, 'Scene1', Scene1, VOID, VOID, [0]);
initMetadataForLambda(Scene2$sceneMain$slambda, VOID, VOID, [1]);
initMetadataForLambda(Scene2$sceneMain$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(Scene2$sceneMain$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(Scene2$sceneMain$lambda$slambda_0, VOID, VOID, [0]);
initMetadataForClass(Scene2, 'Scene2', Scene2, VOID, VOID, [0]);
initMetadataForClass(GameInfo, 'GameInfo', GameInfo);
initMetadataForClass(Snake, 'Snake');
initMetadataForCompanion(Companion_0);
initMetadataForClass(SnakeDirection, 'SnakeDirection');
initMetadataForObject(AppleProvider, 'AppleProvider');
initMetadataForObject(WallProvider, 'WallProvider');
initMetadataForObject(SnakeProvider, 'SnakeProvider');
initMetadataForObject(SnakeHeadProvider, 'SnakeHeadProvider');
initMetadataForCompanion(Companion_1);
initMetadataForClass(GameInfoUpdatedEvent, 'GameInfoUpdatedEvent');
initMetadataForCompanion(Companion_2);
initMetadataForClass(GameStartEvent, 'GameStartEvent', GameStartEvent);
initMetadataForFunctionReference(IngameScene$ingame$ref, VOID, VOID, [1]);
initMetadataForFunctionReference(IngameScene$ingame$ref_0, VOID, VOID, [1]);
initMetadataForLambda(IngameScene$ingame$slambda, VOID, VOID, [1]);
initMetadataForLambda(IngameScene$ingame$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(IngameScene$ingame$slambda_1, VOID, VOID, [1]);
initMetadataForLambda(IngameScene$ingame$slambda_2, VOID, VOID, [1]);
initMetadataForLambda(IngameScene$ingame$slambda_3, VOID, VOID, [1]);
initMetadataForLambda(IngameScene$gameOver$slambda, VOID, VOID, [1]);
initMetadataForFunctionReference(IngameScene$gameOver$ref, VOID, VOID, [1]);
initMetadataForInterface(StateScene, 'StateScene', VOID, VOID, VOID, [1, 0]);
initMetadataForClass(IngameScene, 'IngameScene', IngameScene, VOID, [PixelatedScene, StateScene], [1, 0]);
initMetadataForClass(OverlayScene, 'OverlayScene', OverlayScene, VOID, VOID, [0]);
initMetadataForClass(SnakeScene, 'SnakeScene', SnakeScene, VOID, VOID, [0]);
protoOf(Mixin_0).bqh = runStates;
protoOf(Mixin_0).qqh = change;
protoOf(Mixin_0).cqh = frame;
initMetadataForClass(Mixin_0, 'Mixin', Mixin_0, VOID, [StateScene], [1, 0]);
initMetadataForClass(ChangeSceneException, 'ChangeSceneException');
initMetadataForClass(SpineScene, 'SpineScene', SpineScene, VOID, VOID, [0]);
initMetadataForLambda(SWFScene$main$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(SWFScene$main$loadSwf$slambda, VOID, VOID, [0]);
initMetadataForClass(SWFScene, 'SWFScene', SWFScene, VOID, VOID, [0]);
initMetadataForClass(DockingComponent, 'DockingComponent');
initMetadataForLambda(SimpleUIScene$sceneMain$slambda, VOID, VOID, [1]);
initMetadataForLambda(SimpleUIScene$sceneMain$lambda$slambda, VOID, VOID, [0]);
initMetadataForLambda(SimpleUIScene$sceneMain$slambda_0, VOID, VOID, [1]);
initMetadataForLambda(SimpleUIScene$sceneMain$lambda$slambda_0, VOID, VOID, [0]);
protoOf(SimpleUIScene$openLazyLongListWindow$window$1$1).sdt = getItemY;
initMetadataForClass(SimpleUIScene$openLazyLongListWindow$window$1$1, VOID, VOID, VOID, [Provider]);
initMetadataForClass(SimpleUIScene, 'SimpleUIScene', SimpleUIScene, VOID, VOID, [0]);
initMetadataForLambda(ext$o$init$lambda$slambda, VOID, VOID, [0]);
initMetadataForClass(ext$1);
//endregion
//region block: init
KR_instance = new KR_0();
KRFonts_instance = new KRFonts();
KRGfx_instance = new KRGfx();
Companion_instance_6 = new Companion();
Companion_instance_7 = new Companion_0();
EMPTY = 0;
APPLE = 1;
WALL = 2;
SNAKE = 3;
Companion_instance_8 = new Companion_1();
Companion_instance_9 = new Companion_2();
//endregion
mainWrapper();
