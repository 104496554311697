import {
  arrayCopytctsywo3h7gj as arrayCopy,
  Unit_instance104q5opgivhr8 as Unit_instance,
  toByte4i43936u611k as toByte,
  _UByte___get_data__impl__jof9qr9d98aajdmpjx as _UByte___get_data__impl__jof9qr,
  fill3lmv1pckd4inv as fill,
  VOID7hggqo3abtya as VOID,
  toString1pkumu07cwy4m as toString,
  hashCodeq5arwsb9dgti as hashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  equals2au1ep9vhcato as equals,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  toShort36kaw0zjdq3ex as toShort,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  protoOf180f3jzyo7rfj as protoOf,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  toLongw1zpgk99d84b as toLong,
  Long2qws0ah9gnpki as Long,
  copyOfRange3alro60z4hhf8 as copyOfRange,
  charArray2ujmm1qusno00 as charArray,
  numberToChar93r9buh19yek as numberToChar,
  println2shhhgwwt4c61 as println,
  ArrayDeque2dzc9uld4xi7n as ArrayDeque,
  IndexOutOfBoundsException1qfr429iumro0 as IndexOutOfBoundsException,
  numberToLong1a4cndvg6c52s as numberToLong,
  roundToInt1ue8x8yshtznx as roundToInt,
  createThis2j2avj17cvnv2 as createThis,
} from './kotlin-kotlin-stdlib.mjs';
import {
  extractBytedbxg7rz8vq1s as extractByte,
  signExtend10m51xy6jou1t as signExtend,
  ilog23dbvpk38jf7rc as ilog2,
} from './korlibs-korlibs-math-core.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class UByteArrayInt {
  constructor(data) {
    this.n10_1 = data;
  }
  toString() {
    return UByteArrayInt__toString_impl_f8qdsi(this.n10_1);
  }
  hashCode() {
    return UByteArrayInt__hashCode_impl_y17rdr(this.n10_1);
  }
  equals(other) {
    return UByteArrayInt__equals_impl_xlvwxn(this.n10_1, other);
  }
}
class Companion {
  constructor() {
    this.d11_1 = 1;
  }
}
class TypedBuffer {}
function get_size() {
  return this.j11().y10() / this.k11() | 0;
}
class Int8Buffer {
  constructor(buffer) {
    this.g11_1 = buffer;
  }
  j11() {
    return _Int8Buffer___get_buffer__impl__q0gulb(this.g11_1);
  }
  k11() {
    return _Int8Buffer___get_elementSizeInBytes__impl__nl6z14(this.g11_1);
  }
  toString() {
    return Int8Buffer__toString_impl_ji6w3c(this.g11_1);
  }
  hashCode() {
    return Int8Buffer__hashCode_impl_28z0pj(this.g11_1);
  }
  equals(other) {
    return Int8Buffer__equals_impl_sjl4qt(this.g11_1, other);
  }
}
class Companion_0 {
  constructor() {
    this.p11_1 = 2;
  }
}
class Uint16Buffer {
  constructor(buffer) {
    this.q11_1 = buffer;
  }
  j11() {
    return _Uint16Buffer___get_buffer__impl__rei8ax(this.q11_1);
  }
  k11() {
    return _Uint16Buffer___get_elementSizeInBytes__impl__tcik7m(this.q11_1);
  }
  toString() {
    return Uint16Buffer__toString_impl_rxffha(this.q11_1);
  }
  hashCode() {
    return Uint16Buffer__hashCode_impl_lcipoz(this.q11_1);
  }
  equals(other) {
    return Uint16Buffer__equals_impl_u4cl2n(this.q11_1, other);
  }
}
class SimpleBytesDeque {
  constructor(initialBits, allowGrow) {
    initialBits = initialBits === VOID ? 10 : initialBits;
    allowGrow = allowGrow === VOID ? true : allowGrow;
    this.b12_1 = initialBits;
    this.c12_1 = allowGrow;
    this.d12_1 = new SimpleRingBuffer(this.b12_1);
    this.e12_1 = new Int8Array(1024);
    this.f12_1 = new Long(0, 0);
    this.g12_1 = new Long(0, 0);
  }
  i12() {
    return this.d12_1.a12_1;
  }
  j12(buffer, offset, size) {
    ensureWrite(this, size);
    var out = this.d12_1.j12(buffer, offset, size);
    if (out > 0) {
      var tmp = this;
      // Inline function 'kotlin.Long.plus' call
      tmp.f12_1 = this.f12_1.c4(toLong(out));
    }
    return out;
  }
  k12(buffer, offset, size) {
    var out = this.d12_1.k12(buffer, offset, size);
    if (out > 0) {
      var tmp = this;
      // Inline function 'kotlin.Long.plus' call
      tmp.g12_1 = this.g12_1.c4(toLong(out));
    }
    return out;
  }
  hashCode() {
    return this.d12_1.l12();
  }
  equals(other) {
    var tmp;
    if (other instanceof SimpleBytesDeque) {
      tmp = this.d12_1.equals(other.d12_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
}
class SimpleChunkedByteDeque {
  constructor() {
    this.m12_1 = ArrayDeque.jl();
    this.n12_1 = 0;
    this.o12_1 = 0;
  }
  p12(data, offset, size) {
    writeFullNoCopy(this, copyOfRange(data, offset, offset + size | 0));
  }
  k12(data, offset, size) {
    if (offset < 0 || (offset + size | 0) > data.length)
      throw IndexOutOfBoundsException.ze();
    var coffset = offset;
    var pending = size;
    var readTotal = 0;
    $l$loop_0: while (pending > 0) {
      // Inline function 'korlibs.memory.SimpleChunkedByteDeque.lock' call
      // Inline function 'korlibs.memory.SimpleChunkedByteDeque.read.<anonymous>' call
      var tmp;
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!this.m12_1.a1()) {
        tmp = this.m12_1.kl();
      } else {
        tmp = null;
      }
      var tmp0_elvis_lhs = tmp;
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        break $l$loop_0;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var chunk = tmp_0;
      var availableInChunk = chunk.length - this.n12_1 | 0;
      // Inline function 'kotlin.math.min' call
      var b = pending;
      var toCopy = Math.min(availableInChunk, b);
      if (toCopy <= 0)
        break $l$loop_0;
      arraycopy(chunk, this.n12_1, data, coffset, toCopy);
      coffset = coffset + toCopy | 0;
      pending = pending - toCopy | 0;
      readTotal = readTotal + toCopy | 0;
      consumeChunkSize(chunk, this, toCopy);
    }
    return readTotal;
  }
}
class SimpleRingBuffer {
  constructor(bits) {
    this.t11_1 = bits;
    this.u11_1 = 1 << this.t11_1;
    this.v11_1 = this.u11_1 - 1 | 0;
    this.w11_1 = new Int8Array(this.u11_1);
    this.x11_1 = 0;
    this.y11_1 = 0;
    this.z11_1 = this.u11_1;
    this.a12_1 = 0;
  }
  q12() {
    return this.w11_1;
  }
  r12() {
    return this.x11_1 & this.v11_1;
  }
  s12() {
    return this.y11_1 & this.v11_1;
  }
  t12(count) {
    if (count < 0 || count > this.z11_1) {
      // Inline function 'kotlin.error' call
      var message = 'Try to write more than available';
      throw IllegalStateException.m5(toString(message));
    }
    this.y11_1 = this.y11_1 + count | 0;
    this.a12_1 = this.a12_1 + count | 0;
    this.z11_1 = this.z11_1 - count | 0;
  }
  u12(count) {
    if (count < 0 || count > this.a12_1) {
      // Inline function 'kotlin.error' call
      var message = 'Try to write more than available';
      throw IllegalStateException.m5(toString(message));
    }
    this.x11_1 = this.x11_1 + count | 0;
    this.a12_1 = this.a12_1 - count | 0;
    this.z11_1 = this.z11_1 + count | 0;
  }
  v12() {
    // Inline function 'kotlin.math.min' call
    var a = this.a12_1;
    var b = this.u11_1 - (this.x11_1 & this.v11_1) | 0;
    return Math.min(a, b);
  }
  w12() {
    // Inline function 'kotlin.math.min' call
    var a = this.z11_1;
    var b = this.u11_1 - (this.y11_1 & this.v11_1) | 0;
    return Math.min(a, b);
  }
  h12(consume) {
    while (consume.a12_1 > 0) {
      // Inline function 'kotlin.math.min' call
      var a = consume.v12();
      var b = this.w12();
      var copySize = Math.min(a, b);
      arraycopy(consume.q12(), consume.r12(), this.q12(), this.s12(), copySize);
      consume.u12(copySize);
      this.t12(copySize);
    }
  }
  j12(data, offset, size) {
    // Inline function 'kotlin.math.min' call
    var a = this.z11_1;
    var remaining = Math.min(a, size);
    var coffset = offset;
    var totalWrite = 0;
    $l$loop: while (remaining > 0) {
      // Inline function 'kotlin.math.min' call
      var a_0 = remaining;
      var b = this.w12();
      var chunkSize = Math.min(a_0, b);
      if (chunkSize <= 0)
        break $l$loop;
      arraycopy(data, coffset, this.w11_1, this.s12(), chunkSize);
      this.t12(chunkSize);
      coffset = coffset + chunkSize | 0;
      remaining = remaining - chunkSize | 0;
      totalWrite = totalWrite + chunkSize | 0;
    }
    return totalWrite;
  }
  k12(data, offset, size) {
    return this.oz(this.x12(data, offset, size));
  }
  oz(size) {
    // Inline function 'kotlin.math.min' call
    var a = this.a12_1;
    var toRead = Math.min(a, size);
    this.x11_1 = (this.x11_1 + toRead | 0) & this.v11_1;
    this.z11_1 = this.z11_1 + toRead | 0;
    this.a12_1 = this.a12_1 - toRead | 0;
    return toRead;
  }
  x12(data, offset, size) {
    // Inline function 'kotlin.math.min' call
    var a = this.a12_1;
    var toRead = Math.min(a, size);
    var readCount = 0;
    var buffer = this.w11_1;
    var mask = this.v11_1;
    var coffset = offset;
    var lReadPos = this.x11_1;
    $l$loop: while (true) {
      // Inline function 'kotlin.math.min' call
      var a_0 = toRead;
      var b = this.v12();
      var toReadChunk = Math.min(a_0, b);
      if (toReadChunk <= 0)
        break $l$loop;
      arraycopy(buffer, lReadPos & mask, data, coffset, toReadChunk);
      toRead = toRead - toReadChunk | 0;
      coffset = coffset + toReadChunk | 0;
      lReadPos = lReadPos + toReadChunk | 0;
      readCount = readCount + toReadChunk | 0;
    }
    return readCount;
  }
  y12() {
    if (this.a12_1 <= 0)
      return -1;
    var out = this.w11_1[this.x11_1] & 255;
    this.x11_1 = (this.x11_1 + 1 | 0) & this.v11_1;
    this.a12_1 = this.a12_1 - 1 | 0;
    this.z11_1 = this.z11_1 + 1 | 0;
    return out;
  }
  z12(offset) {
    return this.w11_1[(this.x11_1 + offset | 0) & this.v11_1];
  }
  equals(other) {
    var tmp;
    var tmp_0;
    if (other instanceof SimpleRingBuffer) {
      tmp_0 = this.a12_1 === other.a12_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.memory.SimpleRingBuffer.equaler' call
        var count = this.a12_1;
        var inductionVariable = 0;
        if (inductionVariable < count)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.memory.SimpleRingBuffer.equals.<anonymous>' call
            if (!(this.z12(n) === other.z12(n))) {
              tmp$ret$1 = false;
              break $l$block;
            }
          }
           while (inductionVariable < count);
        tmp$ret$1 = true;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    return this.l12();
  }
  l12() {
    // Inline function 'korlibs.memory.SimpleRingBuffer.hashCoder' call
    var count = this.a12_1;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.memory.SimpleRingBuffer.contentHashCode.<anonymous>' call
        out = tmp + this.z12(n) | 0;
      }
       while (inductionVariable < count);
    return out;
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_1 = this;
    this.a13_1 = _ByteUnits___init__impl__x795am(0.0);
  }
  b13(bytes) {
    return _ByteUnits___init__impl__x795am(bytes);
  }
  c13(bytes) {
    return _ByteUnits___init__impl__x795am(bytes.t4());
  }
}
class Companion_2 {
  p10(src, srcPosBytes, dst, dstPosBytes, sizeInBytes) {
    dst.d13(dstPosBytes, sizeInBytes).set(src.d13(srcPosBytes, sizeInBytes), 0);
  }
  q10(src, srcPosBytes, dst, dstPosBytes, sizeInBytes) {
    return equalsCommon_0(this, src, srcPosBytes, dst, dstPosBytes, sizeInBytes, false);
  }
}
class Buffer {
  static e13(dataView) {
    var $this = createThis(this);
    $this.r10_1 = dataView;
    return $this;
  }
  static t10(size, direct) {
    direct = direct === VOID ? false : direct;
    return this.e13(new DataView(new ArrayBuffer(checkNBufferSize(size))));
  }
  static f13(array, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? array.length - offset | 0 : size;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = checkNBufferWrap(array, offset, size);
    return this.e13(new DataView(tmp$ret$1.buffer, offset, size));
  }
  g13() {
    return this.r10_1.byteOffset;
  }
  y10() {
    return this.r10_1.byteLength;
  }
  s11(start, end) {
    return Buffer.e13(new DataView(get_buffer(this), this.g13() + start | 0, end - start | 0));
  }
  d13(offset, size) {
    return new Uint8Array(get_buffer(this), this.r10_1.byteOffset + offset | 0, size);
  }
  s10(bufferOffset, array, arrayOffset, len, toArray) {
    var bufferSlice = new Int8Array(this.r10_1.buffer, this.r10_1.byteOffset + bufferOffset | 0, len);
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var arraySlice = array.subarray(arrayOffset, arrayOffset + len | 0);
    if (toArray) {
      arraySlice.set(bufferSlice, 0);
    } else {
      bufferSlice.set(arraySlice, 0);
    }
  }
  e11(byteOffset) {
    return this.r10_1.getInt8(byteOffset);
  }
  m11(byteOffset) {
    return this.r10_1.getInt16(byteOffset, true);
  }
  a11(byteOffset) {
    return this.r10_1.getInt32(byteOffset, true);
  }
  r11(byteOffset) {
    var v0 = toLong(this.a11(byteOffset)).n4(new Long(-1, 0));
    var v1 = toLong(this.a11(byteOffset + 4 | 0)).n4(new Long(-1, 0));
    return get_isLittleEndian() ? v1.k4(32).o4(v0) : v0.k4(32).o4(v1);
  }
  v10(byteOffset) {
    return this.r10_1.getFloat32(byteOffset, true);
  }
  l11(byteOffset) {
    return this.r10_1.getInt16(byteOffset, false);
  }
  b11(byteOffset) {
    return this.r10_1.getInt32(byteOffset, false);
  }
  u10(byteOffset) {
    return this.r10_1.getFloat32(byteOffset, false);
  }
  f11(byteOffset, value) {
    return this.r10_1.setInt8(byteOffset, value);
  }
  o11(byteOffset, value) {
    return this.r10_1.setInt16(byteOffset, value, true);
  }
  z10(byteOffset, value) {
    return this.r10_1.setInt32(byteOffset, value, true);
  }
  x10(byteOffset, value) {
    return this.r10_1.setFloat32(byteOffset, value, true);
  }
  n11(byteOffset, value) {
    return this.r10_1.setInt16(byteOffset, value, false);
  }
  c11(byteOffset, value) {
    return this.r10_1.setInt32(byteOffset, value, false);
  }
  w10(byteOffset, value) {
    return this.r10_1.setFloat32(byteOffset, value, false);
  }
  hashCode() {
    return hashCodeCommon(Companion_instance_2, this);
  }
  equals(other) {
    return equalsCommon(Companion_instance_2, this, other);
  }
  toString() {
    return NBuffer_toString(this);
  }
}
//endregion
function arraycopy(src, srcPos, dst, dstPos, size) {
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = srcPos + size | 0;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = src;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
}
function arraycopy_0(src, srcPos, dst, dstPos, size) {
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = srcPos + size | 0;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = src;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
}
function _UByteArrayInt___init__impl__xif7bu(data) {
  return data;
}
function _UByteArrayInt___get_data__impl__l2b913($this) {
  return $this;
}
function _UByteArrayInt___get_bytes__impl__pj0ilc($this) {
  return _UByteArrayInt___get_data__impl__l2b913($this);
}
function _UByteArrayInt___init__impl__xif7bu_0(size) {
  return _UByteArrayInt___init__impl__xif7bu(new Int8Array(size));
}
function _UByteArrayInt___init__impl__xif7bu_1(size, gen) {
  var tmp = 0;
  var tmp_0 = new Int8Array(size);
  while (tmp < size) {
    var tmp_1 = tmp;
    tmp_0[tmp_1] = toByte(gen(tmp_1));
    tmp = tmp + 1 | 0;
  }
  return _UByteArrayInt___init__impl__xif7bu(tmp_0);
}
function _UByteArrayInt___get_size__impl__bcjnte($this) {
  return _UByteArrayInt___get_data__impl__l2b913($this).length;
}
function UByteArrayInt__get_impl_px6q8a($this, index) {
  return _UByteArrayInt___get_data__impl__l2b913($this)[index] & 255;
}
function UByteArrayInt__set_impl_gn4532($this, index, value) {
  _UByteArrayInt___get_data__impl__l2b913($this)[index] = toByte(value);
}
function UByteArrayInt__set_impl_gn4532_0($this, index, value) {
  var tmp = _UByteArrayInt___get_data__impl__l2b913($this);
  // Inline function 'kotlin.UByte.toByte' call
  tmp[index] = _UByte___get_data__impl__jof9qr(value);
}
function UByteArrayInt__fill_impl_7vnhx3($this, value, fromIndex, toIndex) {
  return fill(_UByteArrayInt___get_data__impl__l2b913($this), toByte(value), fromIndex, toIndex);
}
function UByteArrayInt__fill$default_impl_mdydxi($this, value, fromIndex, toIndex, $super) {
  fromIndex = fromIndex === VOID ? 0 : fromIndex;
  toIndex = toIndex === VOID ? _UByteArrayInt___get_size__impl__bcjnte($this) : toIndex;
  var tmp;
  if ($super === VOID) {
    UByteArrayInt__fill_impl_7vnhx3($this, value, fromIndex, toIndex);
    tmp = Unit_instance;
  } else {
    var tmp_0 = $super;
    tmp = (tmp_0 == null ? null : new UByteArrayInt(tmp_0)).o10.call(new UByteArrayInt($this), value, fromIndex, toIndex);
  }
  return tmp;
}
function UByteArrayInt__toString_impl_f8qdsi($this) {
  return 'UByteArrayInt(data=' + toString($this) + ')';
}
function UByteArrayInt__hashCode_impl_y17rdr($this) {
  return hashCode($this);
}
function UByteArrayInt__equals_impl_xlvwxn($this, other) {
  if (!(other instanceof UByteArrayInt))
    return false;
  var tmp0_other_with_cast = other instanceof UByteArrayInt ? other.n10_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function arraycopy_1(src, srcPos, dst, dstPos, size) {
  return arraycopy(_UByteArrayInt___get_data__impl__l2b913(src), srcPos, _UByteArrayInt___get_data__impl__l2b913(dst), dstPos, size);
}
function asByteArray(_this__u8e3s4) {
  return _UByteArrayInt___get_data__impl__l2b913(_this__u8e3s4);
}
function _UShortArrayInt___init__impl__kdofb8(data) {
  return data;
}
function _UShortArrayInt___get_data__impl__l4stnv($this) {
  return $this;
}
function _UShortArrayInt___init__impl__kdofb8_0(size) {
  return _UShortArrayInt___init__impl__kdofb8(new Int16Array(size));
}
function UShortArrayInt__get_impl_ciz20k($this, index) {
  return _UShortArrayInt___get_data__impl__l4stnv($this)[index] & 65535;
}
function UShortArrayInt__set_impl_38wgvc($this, index, value) {
  _UShortArrayInt___get_data__impl__l4stnv($this)[index] = toShort(value);
}
function indexOf(_this__u8e3s4, sub, starting) {
  starting = starting === VOID ? 0 : starting;
  var tmp$ret$1;
  $l$block: {
    // Inline function 'korlibs.memory.array_indexOf' call
    var selfSize = _this__u8e3s4.length;
    var subSize = sub.length;
    var inductionVariable = starting;
    var last = selfSize - subSize | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var eq = 0;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < subSize)
          $l$loop: do {
            var m = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            // Inline function 'korlibs.memory.indexOf.<anonymous>' call
            if (!(_this__u8e3s4[n + m | 0] === sub[m])) {
              break $l$loop;
            }
            eq = eq + 1 | 0;
          }
           while (inductionVariable_0 < subSize);
        if (eq === subSize) {
          tmp$ret$1 = n;
          break $l$block;
        }
      }
       while (inductionVariable < last);
    tmp$ret$1 = -1;
  }
  return tmp$ret$1;
}
function arraycopy_2(src, srcPos, dst, dstPos, size) {
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = srcPos + size | 0;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = src;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
}
function getSampled(_this__u8e3s4, index) {
  var tmp$ret$1;
  $l$block: {
    // Inline function 'korlibs.memory.getSampledGeneric' call
    var index0 = numberToInt(index);
    // Inline function 'korlibs.memory.getSampled.<anonymous>' call
    var v0 = _this__u8e3s4[index0];
    if (index0 === index) {
      tmp$ret$1 = v0;
      break $l$block;
    }
    // Inline function 'korlibs.memory.getSampled.<anonymous>' call
    var v1 = _this__u8e3s4[index0 + 1 | 0];
    var ratio = index % 1.0;
    // Inline function 'korlibs.memory.getSampled.<anonymous>' call
    var o0 = v0 * (1.0 - ratio);
    // Inline function 'korlibs.memory.getSampled.<anonymous>' call
    var o1 = v1 * ratio;
    // Inline function 'korlibs.memory.getSampled.<anonymous>' call
    var it = o0 + o1;
    tmp$ret$1 = toShort(numberToInt(it));
  }
  return tmp$ret$1;
}
function arraycopy_3(src, srcPos, dst, dstPos, size) {
  Companion_instance_2.p10(src, srcPos, dst, dstPos, size);
}
function arrayfill(array, value, start, end) {
  start = start === VOID ? 0 : start;
  end = end === VOID ? get_size_0(array) : end;
  var inductionVariable = start;
  if (inductionVariable < end)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      setUInt8(array, n, value);
    }
     while (inductionVariable < end);
}
function arrayequal(src, srcPos, dst, dstPos, size) {
  return Companion_instance_2.q10(src, srcPos, dst, dstPos, size);
}
function arraycopy_4(src, srcPos, dst, dstPos, size) {
  return arraycopy_3(_Int32Buffer___get_buffer__impl__dnrimm(src), imul(srcPos, 4), _Int32Buffer___get_buffer__impl__dnrimm(dst), imul(dstPos, 4), imul(size, 4));
}
function arraycopy_5(src, srcPos, dst, dstPos, size) {
  Uint16Buffer__setArray_impl_x4uzt(dst, dstPos, src, srcPos, size);
}
function asUShortArrayInt(_this__u8e3s4) {
  return _UShortArrayInt___init__impl__kdofb8(_this__u8e3s4);
}
function arrayadd(array, value, start, end) {
  start = start === VOID ? 0 : start;
  end = end === VOID ? (new Uint16Buffer(array)).j1() : end;
  var inductionVariable = start;
  if (inductionVariable < end)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      Uint16Buffer__set_impl_g0kliu(array, n, Uint16Buffer__get_impl_6qi0dm(array, n) + value | 0);
    }
     while (inductionVariable < end);
}
function arrayfill_0(array, value, start, end) {
  start = start === VOID ? 0 : start;
  end = end === VOID ? array.length : end;
  return fill(array, value, start, end);
}
function arraycopy_6(src, srcPos, dst, dstPos, size) {
  dst.s10(dstPos, src, srcPos, size, false);
}
function arraycopy_7(src, srcPos, dst, dstPos, size) {
  src.s10(srcPos, dst, dstPos, size, true);
}
function arraycopy_8(src, srcPos, dst, dstPos, size) {
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = srcPos + size | 0;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = src;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
}
function arraycopy_9(src, srcPos, dst, dstPos, size) {
  Int8Buffer__setArray_impl_oimleb(dst, dstPos, src, srcPos, size);
}
function arraycopy_10(src, srcPos, dst, dstPos, size) {
  Float32Buffer__setArray_impl_eosj51(dst, dstPos, src, srcPos, size);
}
function arraycopy_11(src, srcPos, dst, dstPos, size) {
  Int32Buffer__setArray_impl_eeeiw8(dst, dstPos, src, srcPos, size);
}
function arraycopy_12(src, srcPos, dst, dstPos, size) {
  if (src === dst) {
    // Inline function 'kotlin.error' call
    var message = 'Not supporting the same array';
    throw IllegalStateException.m5(toString(message));
  }
  var inductionVariable = 0;
  if (inductionVariable < size)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      dst.p3(dstPos + n | 0, src.i1(srcPos));
    }
     while (inductionVariable < size);
}
function _Float32Buffer___init__impl__403k2m(buffer) {
  return buffer;
}
function _Float32Buffer___get_buffer__impl__x5nu9x($this) {
  return $this;
}
function _Float32Buffer___init__impl__403k2m_0(data, offset, size) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? data.length - offset | 0 : size;
  // Inline function 'kotlin.also' call
  var this_0 = Buffer.t10(imul(size, 4));
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.Float32Buffer.<init>.<anonymous>' call
  setArray(this_0, 0, data, offset, size);
  return _Float32Buffer___init__impl__403k2m(this_0);
}
function Float32Buffer__get_impl_659lhu($this, index) {
  // Inline function 'korlibs.memory.getF32' call
  var this_0 = _Float32Buffer___get_buffer__impl__x5nu9x($this);
  var byteOffset = imul(index, 4);
  return true ? this_0.v10(byteOffset) : this_0.u10(byteOffset);
}
function Float32Buffer__set_impl_34szne($this, index, value) {
  // Inline function 'korlibs.memory.setF32' call
  var this_0 = _Float32Buffer___get_buffer__impl__x5nu9x($this);
  var byteOffset = imul(index, 4);
  var tmp;
  if (true) {
    this_0.x10(byteOffset, value);
    tmp = Unit_instance;
  } else {
    this_0.w10(byteOffset, value);
    tmp = Unit_instance;
  }
  return tmp;
}
function Float32Buffer__setArray_impl_eosj51($this, index, inp, offset, size) {
  return setArray(_Float32Buffer___get_buffer__impl__x5nu9x($this), imul(index, 4), inp, offset, size);
}
function asFloat32(_this__u8e3s4) {
  return _Float32Buffer___init__impl__403k2m(_this__u8e3s4);
}
function copyOf(_this__u8e3s4, newSize) {
  var out = Buffer.t10(newSize);
  // Inline function 'kotlin.math.min' call
  var a = _this__u8e3s4.y10();
  var tmp$ret$0 = Math.min(a, newSize);
  arraycopy_3(_this__u8e3s4, 0, out, 0, tmp$ret$0);
  return out;
}
function setInt32(_this__u8e3s4, index, value) {
  return _this__u8e3s4.z10(imul(index, 4), value);
}
function getInt32(_this__u8e3s4, index) {
  return _this__u8e3s4.a11(imul(index, 4));
}
function allocDirect(_this__u8e3s4, size) {
  return Buffer.t10(size, true);
}
function setFloat32(_this__u8e3s4, index, value) {
  return _this__u8e3s4.x10(imul(index, 4), value);
}
function allocNoDirect(_this__u8e3s4, size) {
  return Buffer.t10(size, false);
}
function _Int32Buffer___init__impl__o98lxn(buffer) {
  return buffer;
}
function _Int32Buffer___get_buffer__impl__dnrimm($this) {
  return $this;
}
function Int32Buffer__get_impl_48ysyj($this, index) {
  // Inline function 'korlibs.memory.getS32' call
  var this_0 = _Int32Buffer___get_buffer__impl__dnrimm($this);
  var byteOffset = imul(index, 4);
  return true ? this_0.a11(byteOffset) : this_0.b11(byteOffset);
}
function Int32Buffer__set_impl_dj1e3r($this, index, value) {
  // Inline function 'korlibs.memory.set32' call
  var this_0 = _Int32Buffer___get_buffer__impl__dnrimm($this);
  var byteOffset = imul(index, 4);
  var tmp;
  if (true) {
    this_0.z10(byteOffset, value);
    tmp = Unit_instance;
  } else {
    this_0.c11(byteOffset, value);
    tmp = Unit_instance;
  }
  return tmp;
}
function Int32Buffer__setArray_impl_eeeiw8($this, index, inp, offset, size) {
  return setArray_0(_Int32Buffer___get_buffer__impl__dnrimm($this), imul(index, 4), inp, offset, size);
}
function get_i32(_this__u8e3s4) {
  return asInt32(_this__u8e3s4);
}
function get_size_0(_this__u8e3s4) {
  return _this__u8e3s4.y10();
}
function _Int8Buffer___init__impl__e20lvc(buffer) {
  return buffer;
}
function _Int8Buffer___get_buffer__impl__q0gulb($this) {
  return $this;
}
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
function _Int8Buffer___init__impl__e20lvc_0(data, offset, size) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? data.length - offset | 0 : size;
  // Inline function 'kotlin.also' call
  var this_0 = Buffer.t10(imul(size, 1));
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.Int8Buffer.<init>.<anonymous>' call
  setArray_1(this_0, 0, data, offset, size);
  return _Int8Buffer___init__impl__e20lvc(this_0);
}
function _Int8Buffer___get_elementSizeInBytes__impl__nl6z14($this) {
  return 1;
}
function Int8Buffer__get_impl_amhyyo($this, index) {
  return _Int8Buffer___get_buffer__impl__q0gulb($this).e11(index);
}
function Int8Buffer__set_impl_jwkk3w($this, index, value) {
  return _Int8Buffer___get_buffer__impl__q0gulb($this).f11(index, value);
}
function Int8Buffer__getArray_impl_xpl541($this, index, out, offset, size) {
  return getS8Array(_Int8Buffer___get_buffer__impl__q0gulb($this), imul(index, 1), out, offset, size);
}
function Int8Buffer__getArray$default_impl_hlbc10($this, index, out, offset, size, $super) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? out.length - offset | 0 : size;
  var tmp;
  if ($super === VOID) {
    tmp = Int8Buffer__getArray_impl_xpl541($this, index, out, offset, size);
  } else {
    var tmp_0 = $super;
    tmp = (tmp_0 == null ? null : new Int8Buffer(tmp_0)).h11.call(new Int8Buffer($this), index, out, offset, size);
  }
  return tmp;
}
function Int8Buffer__getArray_impl_xpl541_0($this, index, size) {
  return Int8Buffer__getArray$default_impl_hlbc10($this, index, new Int8Array(size));
}
function Int8Buffer__getArray$default_impl_hlbc10_0($this, index, size, $super) {
  index = index === VOID ? 0 : index;
  size = size === VOID ? (new Int8Buffer($this)).j1() - index | 0 : size;
  var tmp;
  if ($super === VOID) {
    tmp = Int8Buffer__getArray_impl_xpl541_0($this, index, size);
  } else {
    var tmp_0 = $super;
    tmp = (tmp_0 == null ? null : new Int8Buffer(tmp_0)).i11.call(new Int8Buffer($this), index, size);
  }
  return tmp;
}
function Int8Buffer__setArray_impl_oimleb($this, index, inp, offset, size) {
  return setArray_1(_Int8Buffer___get_buffer__impl__q0gulb($this), imul(index, 1), inp, offset, size);
}
function Int8Buffer__toString_impl_ji6w3c($this) {
  return 'Int8Buffer(buffer=' + $this.toString() + ')';
}
function Int8Buffer__hashCode_impl_28z0pj($this) {
  return $this.hashCode();
}
function Int8Buffer__equals_impl_sjl4qt($this, other) {
  if (!(other instanceof Int8Buffer))
    return false;
  var tmp0_other_with_cast = other instanceof Int8Buffer ? other.g11_1 : THROW_CCE();
  if (!$this.equals(tmp0_other_with_cast))
    return false;
  return true;
}
function _Int16Buffer___init__impl__dw7hol(buffer) {
  return buffer;
}
function _Int16Buffer___get_buffer__impl__eo253o($this) {
  return $this;
}
function Int16Buffer__get_impl_nt0z85($this, index) {
  // Inline function 'korlibs.memory.getS16' call
  var this_0 = _Int16Buffer___get_buffer__impl__eo253o($this);
  var byteOffset = imul(index, 2);
  return true ? this_0.m11(byteOffset) : this_0.l11(byteOffset);
}
function Int16Buffer__set_impl_x33kdd($this, index, value) {
  // Inline function 'korlibs.memory.set16' call
  var this_0 = _Int16Buffer___get_buffer__impl__eo253o($this);
  var byteOffset = imul(index, 2);
  var tmp;
  if (true) {
    this_0.o11(byteOffset, value);
    tmp = Unit_instance;
  } else {
    this_0.n11(byteOffset, value);
    tmp = Unit_instance;
  }
  return tmp;
}
function sliceWithSize(_this__u8e3s4, start, size) {
  return sliceBuffer(_this__u8e3s4, start, start + size | 0);
}
function clone(_this__u8e3s4, direct) {
  direct = direct === VOID ? false : direct;
  var out = Buffer.t10(get_size_0(_this__u8e3s4), direct);
  arraycopy_3(_this__u8e3s4, 0, out, 0, get_size_0(_this__u8e3s4));
  return out;
}
function _Uint16Buffer___init__impl__4401ya(buffer) {
  return buffer;
}
function _Uint16Buffer___get_buffer__impl__rei8ax($this) {
  return $this;
}
var Companion_instance_0;
function Companion_getInstance_0() {
  return Companion_instance_0;
}
function _Uint16Buffer___get_elementSizeInBytes__impl__tcik7m($this) {
  return 2;
}
function Uint16Buffer__get_impl_6qi0dm($this, index) {
  // Inline function 'korlibs.memory.getU16' call
  var this_0 = _Uint16Buffer___get_buffer__impl__rei8ax($this);
  var byteOffset = imul(index, 2);
  return true ? getU16LE(this_0, byteOffset) : getU16BE(this_0, byteOffset);
}
function Uint16Buffer__set_impl_g0kliu($this, index, value) {
  // Inline function 'korlibs.memory.set16' call
  var this_0 = _Uint16Buffer___get_buffer__impl__rei8ax($this);
  var byteOffset = imul(index, 2);
  var value_0 = toShort(value);
  var tmp;
  if (true) {
    this_0.o11(byteOffset, value_0);
    tmp = Unit_instance;
  } else {
    this_0.n11(byteOffset, value_0);
    tmp = Unit_instance;
  }
  return tmp;
}
function Uint16Buffer__setArray_impl_x4uzt($this, index, inp, offset, size) {
  return setArray_2(_Uint16Buffer___get_buffer__impl__rei8ax($this), imul(index, 2), _UShortArrayInt___get_data__impl__l4stnv(inp), offset, size);
}
function Uint16Buffer__toString_impl_rxffha($this) {
  return 'Uint16Buffer(buffer=' + $this.toString() + ')';
}
function Uint16Buffer__hashCode_impl_lcipoz($this) {
  return $this.hashCode();
}
function Uint16Buffer__equals_impl_u4cl2n($this, other) {
  if (!(other instanceof Uint16Buffer))
    return false;
  var tmp0_other_with_cast = other instanceof Uint16Buffer ? other.q11_1 : THROW_CCE();
  if (!$this.equals(tmp0_other_with_cast))
    return false;
  return true;
}
function get_u16(_this__u8e3s4) {
  return asUInt16(_this__u8e3s4);
}
function get_f32(_this__u8e3s4) {
  return asFloat32(_this__u8e3s4);
}
function checkNBufferSize(size) {
  if (size < 0)
    throw IllegalArgumentException.l('invalid size ' + size);
  return size;
}
function checkNBufferWrap(array, offset, size) {
  var end = offset + size | 0;
  if (size < 0 || !(0 <= offset ? offset <= array.length : false) || !(0 <= end ? end <= array.length : false)) {
    throw IllegalArgumentException.l('invalid arguments offset=' + offset + ', size=' + size + ' for array.size=' + array.length);
  }
  return array;
}
function hashCodeCommon(_this__u8e3s4, buffer) {
  var h = 1;
  var len = buffer.y10();
  var inductionVariable = 0;
  var last = len / 4 | 0;
  if (inductionVariable < last)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      h = imul(31, h) + buffer.a11(imul(n, 4)) | 0;
    }
     while (inductionVariable < last);
  var offset = imul(len / 4 | 0, 4);
  var inductionVariable_0 = 0;
  var last_0 = len % 4 | 0;
  if (inductionVariable_0 < last_0)
    do {
      var n_0 = inductionVariable_0;
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      h = imul(31, h) + buffer.e11(offset + n_0 | 0) | 0;
    }
     while (inductionVariable_0 < last_0);
  return h;
}
function equalsCommon(_this__u8e3s4, that, other) {
  var tmp;
  if (!(other instanceof Buffer)) {
    tmp = true;
  } else {
    tmp = !(get_size_0(that) === get_size_0(other));
  }
  if (tmp)
    return false;
  return Companion_instance_2.q10(that, 0, other, 0, that.y10());
}
function NBuffer_toString(buffer) {
  return 'Buffer(size=' + get_size_0(buffer) + ')';
}
function equalsCommon_0(_this__u8e3s4, src, srcPosBytes, dst, dstPosBytes, sizeInBytes, use64) {
  use64 = use64 === VOID ? true : use64;
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  if (!((srcPosBytes + sizeInBytes | 0) <= src.y10())) {
    // Inline function 'kotlin.check.<anonymous>' call
    var message = 'Check failed.';
    throw IllegalStateException.m5(toString(message));
  }
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  if (!((dstPosBytes + sizeInBytes | 0) <= dst.y10())) {
    // Inline function 'kotlin.check.<anonymous>' call
    var message_0 = 'Check failed.';
    throw IllegalStateException.m5(toString(message_0));
  }
  var offset = 0;
  var remaining = sizeInBytes;
  if (use64) {
    var WORD = 8;
    var words = remaining / WORD | 0;
    remaining = remaining % WORD | 0;
    var inductionVariable = 0;
    if (inductionVariable < words)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v0 = src.r11((srcPosBytes + offset | 0) + imul(n, WORD) | 0);
        var v1 = dst.r11((dstPosBytes + offset | 0) + imul(n, WORD) | 0);
        if (!v0.equals(v1)) {
          return false;
        }
      }
       while (inductionVariable < words);
    offset = offset + imul(words, WORD) | 0;
  }
  var WORD_0 = 4;
  var words_0 = remaining / WORD_0 | 0;
  remaining = remaining % WORD_0 | 0;
  var inductionVariable_0 = 0;
  if (inductionVariable_0 < words_0)
    do {
      var n_0 = inductionVariable_0;
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      var v0_0 = src.a11((srcPosBytes + offset | 0) + imul(n_0, WORD_0) | 0);
      var v1_0 = dst.a11((dstPosBytes + offset | 0) + imul(n_0, WORD_0) | 0);
      if (!(v0_0 === v1_0)) {
        return false;
      }
    }
     while (inductionVariable_0 < words_0);
  offset = offset + imul(words_0, WORD_0) | 0;
  var inductionVariable_1 = 0;
  var last = remaining;
  if (inductionVariable_1 < last)
    do {
      var n_1 = inductionVariable_1;
      inductionVariable_1 = inductionVariable_1 + 1 | 0;
      var v0_1 = src.e11((srcPosBytes + offset | 0) + n_1 | 0);
      var v1_1 = dst.e11((dstPosBytes + offset | 0) + n_1 | 0);
      if (!(v0_1 === v1_1)) {
        return false;
      }
    }
     while (inductionVariable_1 < last);
  return true;
}
function setUInt8(_this__u8e3s4, index, value) {
  return _this__u8e3s4.f11(index, toByte(value));
}
function setArray(_this__u8e3s4, byteOffset, data, start, size, littleEndian) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  littleEndian = littleEndian === VOID ? true : littleEndian;
  var tmp;
  if (littleEndian) {
    setArrayLE(_this__u8e3s4, byteOffset, data, start, size);
    tmp = Unit_instance;
  } else {
    setArrayBE(_this__u8e3s4, byteOffset, data, start, size);
    tmp = Unit_instance;
  }
  return tmp;
}
function sliceBuffer(_this__u8e3s4, start, end) {
  start = start === VOID ? 0 : start;
  end = end === VOID ? _this__u8e3s4.y10() : end;
  if (start > end || !(0 <= start ? start <= _this__u8e3s4.y10() : false) || !(0 <= end ? end <= _this__u8e3s4.y10() : false)) {
    throw IllegalArgumentException.l('invalid slice start:' + start + ', end:' + end + ' not in 0..' + _this__u8e3s4.y10());
  }
  return _this__u8e3s4.s11(start, end);
}
function setArray_0(_this__u8e3s4, byteOffset, data, start, size, littleEndian) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  littleEndian = littleEndian === VOID ? true : littleEndian;
  var tmp;
  if (littleEndian) {
    setArrayLE_0(_this__u8e3s4, byteOffset, data, start, size);
    tmp = Unit_instance;
  } else {
    setArrayBE_0(_this__u8e3s4, byteOffset, data, start, size);
    tmp = Unit_instance;
  }
  return tmp;
}
function asInt32(_this__u8e3s4) {
  return _Int32Buffer___init__impl__o98lxn(_this__u8e3s4);
}
function setArray_1(_this__u8e3s4, byteOffset, data, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  arraycopy_6(data, start, _this__u8e3s4, byteOffset, size);
}
function getS8Array(_this__u8e3s4, byteOffset, out, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? out.length - start | 0 : size;
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.getS8Array.<anonymous>' call
  arraycopy_7(_this__u8e3s4, byteOffset, out, start, size);
  return out;
}
function setArray_2(_this__u8e3s4, byteOffset, data, start, size, littleEndian) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  littleEndian = littleEndian === VOID ? true : littleEndian;
  var tmp;
  if (littleEndian) {
    setArrayLE_1(_this__u8e3s4, byteOffset, data, start, size);
    tmp = Unit_instance;
  } else {
    setArrayBE_1(_this__u8e3s4, byteOffset, data, start, size);
    tmp = Unit_instance;
  }
  return tmp;
}
function asUInt16(_this__u8e3s4) {
  return _Uint16Buffer___init__impl__4401ya(_this__u8e3s4);
}
function setArrayLE(_this__u8e3s4, byteOffset, data, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  var inductionVariable = 0;
  var tmp;
  if (inductionVariable < size) {
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.setArrayLE.<anonymous>' call
      var offset = byteOffset + imul(n, 4) | 0;
      var index = start + n | 0;
      _this__u8e3s4.x10(offset, data[index]);
    }
     while (inductionVariable < size);
    tmp = Unit_instance;
  }
  return tmp;
}
function setArrayBE(_this__u8e3s4, byteOffset, data, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  var inductionVariable = 0;
  var tmp;
  if (inductionVariable < size) {
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.setArrayBE.<anonymous>' call
      var offset = byteOffset + imul(n, 4) | 0;
      var index = start + n | 0;
      _this__u8e3s4.w10(offset, data[index]);
    }
     while (inductionVariable < size);
    tmp = Unit_instance;
  }
  return tmp;
}
function setArrayLE_0(_this__u8e3s4, byteOffset, data, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  var inductionVariable = 0;
  var tmp;
  if (inductionVariable < size) {
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.setArrayLE.<anonymous>' call
      var offset = byteOffset + imul(n, 4) | 0;
      var index = start + n | 0;
      _this__u8e3s4.z10(offset, data[index]);
    }
     while (inductionVariable < size);
    tmp = Unit_instance;
  }
  return tmp;
}
function setArrayBE_0(_this__u8e3s4, byteOffset, data, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  var inductionVariable = 0;
  var tmp;
  if (inductionVariable < size) {
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.setArrayBE.<anonymous>' call
      var offset = byteOffset + imul(n, 4) | 0;
      var index = start + n | 0;
      _this__u8e3s4.c11(offset, data[index]);
    }
     while (inductionVariable < size);
    tmp = Unit_instance;
  }
  return tmp;
}
function setArrayLE_1(_this__u8e3s4, byteOffset, data, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  var inductionVariable = 0;
  var tmp;
  if (inductionVariable < size) {
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.setArrayLE.<anonymous>' call
      var offset = byteOffset + imul(n, 2) | 0;
      var index = start + n | 0;
      _this__u8e3s4.o11(offset, data[index]);
    }
     while (inductionVariable < size);
    tmp = Unit_instance;
  }
  return tmp;
}
function setArrayBE_1(_this__u8e3s4, byteOffset, data, start, size) {
  start = start === VOID ? 0 : start;
  size = size === VOID ? data.length - start | 0 : size;
  var inductionVariable = 0;
  var tmp;
  if (inductionVariable < size) {
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.setArrayBE.<anonymous>' call
      var offset = byteOffset + imul(n, 2) | 0;
      var index = start + n | 0;
      _this__u8e3s4.n11(offset, data[index]);
    }
     while (inductionVariable < size);
    tmp = Unit_instance;
  }
  return tmp;
}
function getU16LE(_this__u8e3s4, byteOffset) {
  // Inline function 'korlibs.math.unsigned' call
  return _this__u8e3s4.m11(byteOffset) & 65535;
}
function getU16BE(_this__u8e3s4, byteOffset) {
  // Inline function 'korlibs.math.unsigned' call
  return _this__u8e3s4.l11(byteOffset) & 65535;
}
function get_i8(_this__u8e3s4) {
  return asInt8(_this__u8e3s4);
}
function setInt8(_this__u8e3s4, index, value) {
  return _this__u8e3s4.o11(index, toShort(value));
}
function getInt8(_this__u8e3s4, index) {
  return _this__u8e3s4.e11(index);
}
function get_i16(_this__u8e3s4) {
  return asInt16(_this__u8e3s4);
}
function setArrayFloat32(_this__u8e3s4, index, inp, offset, size) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? inp.length - offset | 0 : size;
  return setArray(_this__u8e3s4, imul(index, 4), inp, offset, size);
}
function setArrayInt16(_this__u8e3s4, index, inp, offset, size) {
  offset = offset === VOID ? 0 : offset;
  size = size === VOID ? inp.length - offset | 0 : size;
  return setArray_2(_this__u8e3s4, imul(index, 2), inp, offset, size);
}
function asInt8(_this__u8e3s4) {
  return _Int8Buffer___init__impl__e20lvc(_this__u8e3s4);
}
function asInt16(_this__u8e3s4) {
  return _Int16Buffer___init__impl__dw7hol(_this__u8e3s4);
}
function getU16LE_0(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get16LE' call
  return u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 8;
}
function getS32LE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get32LE' call
  return u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 2 | 0) << 16 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 3 | 0) << 24;
}
function getS32BE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get32BE' call
  return u8$accessor$sdhsi0(_this__u8e3s4, offset + 3 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 2 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 16 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 24;
}
function getU16BE_0(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get16BE' call
  return u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 8;
}
function getU24LE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get24LE' call
  return u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 2 | 0) << 16;
}
function getU24BE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get24BE' call
  return u8$accessor$sdhsi0(_this__u8e3s4, offset + 2 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 16;
}
function set16LE(_this__u8e3s4, offset, value) {
  _this__u8e3s4[offset + 0 | 0] = extractByte(value, 0);
  _this__u8e3s4[offset + 1 | 0] = extractByte(value, 8);
}
function set32LE(_this__u8e3s4, offset, value) {
  _this__u8e3s4[offset + 0 | 0] = extractByte(value, 0);
  _this__u8e3s4[offset + 1 | 0] = extractByte(value, 8);
  _this__u8e3s4[offset + 2 | 0] = extractByte(value, 16);
  _this__u8e3s4[offset + 3 | 0] = extractByte(value, 24);
}
function getU16(_this__u8e3s4, offset, littleEndian) {
  return littleEndian ? getU16LE_0(_this__u8e3s4, offset) : getU16BE_0(_this__u8e3s4, offset);
}
function getS32(_this__u8e3s4, offset, littleEndian) {
  return littleEndian ? getS32LE(_this__u8e3s4, offset) : getS32BE(_this__u8e3s4, offset);
}
function getU8(_this__u8e3s4, offset) {
  return _this__u8e3s4[offset] & 255;
}
function getS16LE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get16LE' call
  var tmp$ret$0 = u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 8;
  return signExtend(tmp$ret$0, 16);
}
function getS24LE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get24LE' call
  var tmp$ret$0 = u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 2 | 0) << 16;
  return signExtend(tmp$ret$0, 24);
}
function u8(_this__u8e3s4, offset) {
  return _this__u8e3s4[offset] & 255;
}
function setArrayLE_2(_this__u8e3s4, offset, array) {
  var size = array.length;
  var inductionVariable = 0;
  var tmp;
  if (inductionVariable < size) {
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.setArrayLE.<anonymous>' call
      var p = offset + imul(n, 2) | 0;
      set16LE(_this__u8e3s4, p, array[n]);
    }
     while (inductionVariable < size);
    tmp = Unit_instance;
  }
  return tmp;
}
function getU32LE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.math.unsigned' call
  // Inline function 'korlibs.memory.get32LE' call
  var this_0 = u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 2 | 0) << 16 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 3 | 0) << 24;
  return toLong(this_0).n4(new Long(-1, 0));
}
function getS8(_this__u8e3s4, offset) {
  return _this__u8e3s4[offset];
}
function getS16BE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get16BE' call
  var tmp$ret$0 = u8$accessor$sdhsi0(_this__u8e3s4, offset + 1 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset + 0 | 0) << 8;
  return signExtend(tmp$ret$0, 16);
}
function getS64BE(_this__u8e3s4, offset) {
  // Inline function 'korlibs.memory.get64BE' call
  // Inline function 'korlibs.math.unsigned' call
  // Inline function 'korlibs.memory.get32BE' call
  var offset_0 = offset + 4 | 0;
  var this_0 = u8$accessor$sdhsi0(_this__u8e3s4, offset_0 + 3 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset_0 + 2 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset_0 + 1 | 0) << 16 | u8$accessor$sdhsi0(_this__u8e3s4, offset_0 + 0 | 0) << 24;
  var tmp = toLong(this_0).n4(new Long(-1, 0)).k4(0);
  // Inline function 'korlibs.math.unsigned' call
  // Inline function 'korlibs.memory.get32BE' call
  var offset_1 = offset + 0 | 0;
  var this_1 = u8$accessor$sdhsi0(_this__u8e3s4, offset_1 + 3 | 0) << 0 | u8$accessor$sdhsi0(_this__u8e3s4, offset_1 + 2 | 0) << 8 | u8$accessor$sdhsi0(_this__u8e3s4, offset_1 + 1 | 0) << 16 | u8$accessor$sdhsi0(_this__u8e3s4, offset_1 + 0 | 0) << 24;
  var tmp$ret$3 = toLong(this_1).n4(new Long(-1, 0));
  return tmp.o4(tmp$ret$3.k4(32));
}
function getS8Array_0(_this__u8e3s4, offset, count) {
  return copyOfRange(_this__u8e3s4, offset, offset + count | 0);
}
function getS16ArrayBE(_this__u8e3s4, offset, count) {
  // Inline function 'korlibs.memory.getTypedArray' call
  var array = new Int16Array(count);
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.getS16ArrayBE.<anonymous>' call
      var pos = offset + imul(n, 2) | 0;
      array[n] = toShort(getS16BE(_this__u8e3s4, pos));
    }
     while (inductionVariable < count);
  return array;
}
function getU16ArrayBE(_this__u8e3s4, offset, count) {
  // Inline function 'korlibs.memory.getTypedArray' call
  var array = charArray(count);
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.getU16ArrayBE.<anonymous>' call
      var pos = offset + imul(n, 2) | 0;
      array[n] = numberToChar(getS16BE(_this__u8e3s4, pos));
    }
     while (inductionVariable < count);
  return array;
}
function getS32ArrayLE(_this__u8e3s4, offset, count) {
  // Inline function 'korlibs.memory.getTypedArray' call
  var array = new Int32Array(count);
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.getS32ArrayLE.<anonymous>' call
      var pos = offset + imul(n, 4) | 0;
      array[n] = getS32LE(_this__u8e3s4, pos);
    }
     while (inductionVariable < count);
  return array;
}
function getS32ArrayBE(_this__u8e3s4, offset, count) {
  // Inline function 'korlibs.memory.getTypedArray' call
  var array = new Int32Array(count);
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
  var inductionVariable = 0;
  if (inductionVariable < count)
    do {
      var n = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.memory.getS32ArrayBE.<anonymous>' call
      var pos = offset + imul(n, 4) | 0;
      array[n] = getS32BE(_this__u8e3s4, pos);
    }
     while (inductionVariable < count);
  return array;
}
function getS32Array(_this__u8e3s4, offset, count, littleEndian) {
  return littleEndian ? getS32ArrayLE(_this__u8e3s4, offset, count) : getS32ArrayBE(_this__u8e3s4, offset, count);
}
function u8$accessor$sdhsi0(_this__u8e3s4, offset) {
  return u8(_this__u8e3s4, offset);
}
function ensureWrite($this, count) {
  if (count <= $this.d12_1.z11_1)
    return Unit_instance;
  if (!$this.c12_1) {
    var message = "Can't grow ByteArrayDeque. Need to write " + count + ', but only ' + $this.d12_1.z11_1 + ' is available';
    println('ERROR: ' + message);
    // Inline function 'kotlin.error' call
    throw IllegalStateException.m5(toString(message));
  }
  var minNewSize = $this.d12_1.a12_1 + count | 0;
  var tmp = $this;
  // Inline function 'kotlin.also' call
  var this_0 = new SimpleRingBuffer(ilog2(minNewSize) + 2 | 0);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.memory.SimpleBytesDeque.ensureWrite.<anonymous>' call
  this_0.h12($this.d12_1);
  tmp.d12_1 = this_0;
}
function writeFullNoCopy($this, data) {
  // Inline function 'korlibs.memory.SimpleChunkedByteDeque.lock' call
  $this.m12_1.f(data);
  $this.o12_1 = $this.o12_1 + data.length | 0;
}
function consumeChunkSize(_this__u8e3s4, $this, size) {
  var chunk = _this__u8e3s4;
  // Inline function 'korlibs.memory.SimpleChunkedByteDeque.lock' call
  $this.n12_1 = $this.n12_1 + size | 0;
  $this.o12_1 = $this.o12_1 - size | 0;
  if ($this.n12_1 >= chunk.length) {
    $this.m12_1.nl();
    $this.n12_1 = 0;
  }
}
function _ByteUnits___init__impl__x795am(bytes) {
  return bytes;
}
function _ByteUnits___get_bytes__impl__leukoc($this) {
  return $this;
}
function _ByteUnits___get_bytesLong__impl__1lc1w0($this) {
  return numberToLong(_ByteUnits___get_bytes__impl__leukoc($this));
}
function _ByteUnits___get_kiloBytes__impl__jnym6r($this) {
  return _ByteUnits___get_bytes__impl__leukoc($this) / 1024.0;
}
function _ByteUnits___get_megaBytes__impl__qjjio2($this) {
  return _ByteUnits___get_bytes__impl__leukoc($this) / (1024.0 * 1024.0);
}
function _ByteUnits___get_gigaBytes__impl__i7s8s8($this) {
  return _ByteUnits___get_bytes__impl__leukoc($this) / (1024.0 * 1024.0 * 1024.0);
}
var Companion_instance_1;
function Companion_getInstance_1() {
  if (Companion_instance_1 === VOID)
    new Companion_1();
  return Companion_instance_1;
}
function roundToDigits(_this__u8e3s4, $this, digits) {
  // Inline function 'kotlin.math.pow' call
  var num = Math.pow(10.0, digits);
  return roundToInt(_this__u8e3s4 * num) / num;
}
function ByteUnits__toString_impl_ox2ku2($this) {
  return _ByteUnits___get_gigaBytes__impl__i7s8s8($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_gigaBytes__impl__i7s8s8($this), $this, 1) + ' GB' : _ByteUnits___get_megaBytes__impl__qjjio2($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_megaBytes__impl__qjjio2($this), $this, 1) + ' MB' : _ByteUnits___get_kiloBytes__impl__jnym6r($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_kiloBytes__impl__jnym6r($this), $this, 1) + ' KB' : _ByteUnits___get_bytesLong__impl__1lc1w0($this).toString() + ' B';
}
function get_isLittleEndian() {
  _init_properties_Buffer_js_kt__30np2z();
  return isLittleEndian;
}
var isLittleEndian;
function asInt8Array(_this__u8e3s4) {
  _init_properties_Buffer_js_kt__30np2z();
  return new Int8Array(_this__u8e3s4);
}
function asInt32Array(_this__u8e3s4) {
  _init_properties_Buffer_js_kt__30np2z();
  return new Int32Array(_this__u8e3s4);
}
var Companion_instance_2;
function Companion_getInstance_2() {
  return Companion_instance_2;
}
function get_buffer(_this__u8e3s4) {
  _init_properties_Buffer_js_kt__30np2z();
  return _this__u8e3s4.r10_1.buffer;
}
function get_arrayUByte(_this__u8e3s4) {
  _init_properties_Buffer_js_kt__30np2z();
  return new Uint8Array(get_buffer(_this__u8e3s4), _this__u8e3s4.g13(), _this__u8e3s4.y10());
}
function get_arrayInt(_this__u8e3s4) {
  _init_properties_Buffer_js_kt__30np2z();
  return new Int32Array(get_buffer(_this__u8e3s4), _this__u8e3s4.g13(), _this__u8e3s4.y10() / 4 | 0);
}
function get_arrayFloat(_this__u8e3s4) {
  _init_properties_Buffer_js_kt__30np2z();
  return new Float32Array(get_buffer(_this__u8e3s4), _this__u8e3s4.g13(), _this__u8e3s4.y10() / 4 | 0);
}
var properties_initialized_Buffer_js_kt_i0s0qv;
function _init_properties_Buffer_js_kt__30np2z() {
  if (!properties_initialized_Buffer_js_kt_i0s0qv) {
    properties_initialized_Buffer_js_kt_i0s0qv = true;
    // Inline function 'org.khronos.webgl.get' call
    // Inline function 'kotlin.also' call
    var this_0 = new Uint32Array(1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.isLittleEndian.<anonymous>' call
    // Inline function 'org.khronos.webgl.set' call
    // Inline function 'kotlin.js.asDynamic' call
    this_0[0] = 287454020;
    // Inline function 'kotlin.js.asDynamic' call
    isLittleEndian = (new Uint8Array(this_0.buffer))[0] === 68;
  }
}
//region block: post-declaration
initMetadataForClass(UByteArrayInt, 'UByteArrayInt');
initMetadataForCompanion(Companion);
initMetadataForInterface(TypedBuffer, 'TypedBuffer');
protoOf(Int8Buffer).j1 = get_size;
initMetadataForClass(Int8Buffer, 'Int8Buffer', VOID, VOID, [TypedBuffer]);
initMetadataForCompanion(Companion_0);
protoOf(Uint16Buffer).j1 = get_size;
initMetadataForClass(Uint16Buffer, 'Uint16Buffer', VOID, VOID, [TypedBuffer]);
initMetadataForClass(SimpleBytesDeque, 'SimpleBytesDeque', SimpleBytesDeque);
initMetadataForClass(SimpleChunkedByteDeque, 'SimpleChunkedByteDeque', SimpleChunkedByteDeque);
initMetadataForClass(SimpleRingBuffer, 'SimpleRingBuffer');
initMetadataForCompanion(Companion_1);
initMetadataForCompanion(Companion_2);
initMetadataForClass(Buffer, 'Buffer');
//endregion
//region block: init
Companion_instance = new Companion();
Companion_instance_0 = new Companion_0();
Companion_instance_2 = new Companion_2();
//endregion
//region block: exports
export {
  Int8Buffer__getArray$default_impl_hlbc10_0 as Int8Buffer__getArray$default_impl_hlbc10e8bl8n5jkqf0,
  UByteArrayInt__fill$default_impl_mdydxi as UByteArrayInt__fill$default_impl_mdydxi110rk9jgqoolh,
  ByteUnits__toString_impl_ox2ku2 as ByteUnits__toString_impl_ox2ku23u8mrfdqfevi9,
  _Float32Buffer___init__impl__403k2m_0 as _Float32Buffer___init__impl__403k2m37epj48967hb0,
  _Float32Buffer___get_buffer__impl__x5nu9x as _Float32Buffer___get_buffer__impl__x5nu9x3g4pb23dnjw1t,
  Float32Buffer__get_impl_659lhu as Float32Buffer__get_impl_659lhu25y6ts3w0gs8j,
  Float32Buffer__set_impl_34szne as Float32Buffer__set_impl_34sznevuviq8wx4pjc,
  Int16Buffer__get_impl_nt0z85 as Int16Buffer__get_impl_nt0z853qm50aljuefot,
  Int16Buffer__set_impl_x33kdd as Int16Buffer__set_impl_x33kdd2g8e6x8n4uak0,
  Int32Buffer__get_impl_48ysyj as Int32Buffer__get_impl_48ysyj21kw6kt95s8lz,
  Int32Buffer__set_impl_dj1e3r as Int32Buffer__set_impl_dj1e3r3rgggtatuzfx6,
  _Int8Buffer___init__impl__e20lvc_0 as _Int8Buffer___init__impl__e20lvc25rd50uuh5h3r,
  _Int8Buffer___get_buffer__impl__q0gulb as _Int8Buffer___get_buffer__impl__q0gulb1rowdgq1svh9p,
  Int8Buffer__get_impl_amhyyo as Int8Buffer__get_impl_amhyyo5htdu7x32gnh,
  Int8Buffer__set_impl_jwkk3w as Int8Buffer__set_impl_jwkk3w1mx9n6g4w36y3,
  _UByteArrayInt___init__impl__xif7bu as _UByteArrayInt___init__impl__xif7bu3pdjaoxauimyc,
  _UByteArrayInt___init__impl__xif7bu_0 as _UByteArrayInt___init__impl__xif7bu2cberlddfaqn1,
  _UByteArrayInt___init__impl__xif7bu_1 as _UByteArrayInt___init__impl__xif7bu25jm4ej5ne9qt,
  _UByteArrayInt___get_bytes__impl__pj0ilc as _UByteArrayInt___get_bytes__impl__pj0ilc35thhu7ilpebp,
  UByteArrayInt__get_impl_px6q8a as UByteArrayInt__get_impl_px6q8a12ofefwxigfcv,
  UByteArrayInt__set_impl_gn4532 as UByteArrayInt__set_impl_gn45322e8gdcoopwt46,
  UByteArrayInt__set_impl_gn4532_0 as UByteArrayInt__set_impl_gn45321uqwn40obuupa,
  _UByteArrayInt___get_size__impl__bcjnte as _UByteArrayInt___get_size__impl__bcjnte3rwa3i1eytgix,
  _UShortArrayInt___init__impl__kdofb8_0 as _UShortArrayInt___init__impl__kdofb82ugyoyyu34uy3,
  UShortArrayInt__get_impl_ciz20k as UShortArrayInt__get_impl_ciz20k1fu51oh2yw4qc,
  UShortArrayInt__set_impl_38wgvc as UShortArrayInt__set_impl_38wgvc2x2oeh0jr5fvg,
  Uint16Buffer__get_impl_6qi0dm as Uint16Buffer__get_impl_6qi0dm2c05p5vb6bqo5,
  Uint16Buffer__set_impl_g0kliu as Uint16Buffer__set_impl_g0kliu1yamftxrkicc6,
  Companion_getInstance_1 as Companion_getInstancehxhdjetxjiew,
  Companion_instance_2 as Companion_instanceimfvy8b5dxd9,
  Buffer as Buffer25z94vsj0kb1s,
  SimpleBytesDeque as SimpleBytesDeque3uob1f4dosqej,
  SimpleChunkedByteDeque as SimpleChunkedByteDeque3cr64mogfywgm,
  SimpleRingBuffer as SimpleRingBuffer1a4k3g2va6i8y,
  UByteArrayInt as UByteArrayInt247ue4nx0pkns,
  allocDirect as allocDirecttfrgytwudkpf,
  allocNoDirect as allocNoDirect3dykranmovqsj,
  get_arrayFloat as get_arrayFloat1r0vk858s9phl,
  get_arrayInt as get_arrayInt3v8kuk47rixtz,
  get_arrayUByte as get_arrayUByte9swx7ogt2hf2,
  arrayadd as arrayadd3erpr00t9k8j,
  arraycopy as arraycopy3czrd9re36wzt,
  arraycopy_0 as arraycopy14jict0dth60o,
  arraycopy_2 as arraycopy52dxe7vhzf14,
  arraycopy_9 as arraycopyphzg5lpa6gvz,
  arraycopy_4 as arraycopyqmyo4vjwforw,
  arraycopy_12 as arraycopy3n7dsu1uob56f,
  arraycopy_11 as arraycopy1hsh296f5d0xg,
  arraycopy_1 as arraycopy3n78tfzvkl4kg,
  arraycopy_10 as arraycopy3fi6tr79d693r,
  arraycopy_3 as arraycopy3uxsqjlixmnz3,
  arraycopy_5 as arraycopy28e1us87mwypg,
  arraycopy_8 as arraycopy9a0yzbjqwnvw,
  arrayequal as arrayequal25g4om83vd8w1,
  arrayfill_0 as arrayfill1njpugsfcm512,
  arrayfill as arrayfill2iqmyqqqfwuaf,
  asByteArray as asByteArray1mw554k0vpuh5,
  asInt32Array as asInt32Arrayf0ml2lp92o3m,
  asInt8Array as asInt8Array769i4qnpoc13,
  asUShortArrayInt as asUShortArrayInt2gfk0h9rafip5,
  clone as clonelcrqoxe4ne5i,
  copyOf as copyOfnqvdm0sc8lk,
  get_f32 as get_f3218rglfhpgbhzp,
  getInt32 as getInt32gmg3zyw00v1v,
  getInt8 as getInt837es7ejbt6cz0,
  getS16ArrayBE as getS16ArrayBE3bj6gsad0tz3k,
  getS16BE as getS16BE6c2odqehs5au,
  getS16LE as getS16LE39jsuv9u9gi4y,
  getS24LE as getS24LE1b229asvs5i3l,
  getS32ArrayBE as getS32ArrayBE30ehj217529qp,
  getS32ArrayLE as getS32ArrayLE2u84ix7qnexrb,
  getS32Array as getS32Array39c2ui7l3wxdu,
  getS32BE as getS32BE5aphh7sm8f2q,
  getS32LE as getS32LE1pxm9wohin3g8,
  getS32 as getS3225c4j90s7n16a,
  getS64BE as getS64BE1b3e93qe1xjxx,
  getS8Array_0 as getS8Array1clq6jd2z5jh9,
  getS8 as getS83kqgdtkseh4fp,
  getSampled as getSampled29kg0naf1rerj,
  getU16ArrayBE as getU16ArrayBE1cpolqhg4wxps,
  getU16BE_0 as getU16BE2rszf3doox5o,
  getU16LE_0 as getU16LE2cizldw8qp53x,
  getU16 as getU1616fejrhhqwfv2,
  getU24BE as getU24BE36pml41y2814b,
  getU24LE as getU24LEi3x1z5ajbdbv,
  getU32LE as getU32LE1yo0ft9yyv0wa,
  getU8 as getU83agrw8yj0shar,
  get_i16 as get_i1618yeitcooc2c6,
  get_i32 as get_i323p4hlz6j3xys8,
  get_i8 as get_i83qhdj74rhdyeg,
  indexOf as indexOf1wbggs5i7ilsw,
  set16LE as set16LEde5pevq9ub88,
  set32LE as set32LE38ba9cwhp6kc,
  setArrayFloat32 as setArrayFloat322635lx2p290a1,
  setArrayInt16 as setArrayInt163i1kdo3ludp9x,
  setArrayLE_2 as setArrayLE1gvgooi4u5zru,
  setFloat32 as setFloat323e0ukqyly1ufv,
  setInt32 as setInt32178h074gei71f,
  setInt8 as setInt83254513lyzytp,
  get_size_0 as get_size13scmefs6mbfn,
  sliceBuffer as sliceBufferpiate0wictoq,
  sliceWithSize as sliceWithSize2i7i6olf26byj,
  get_u16 as get_u163bxpdbbave1v5,
};
//endregion
