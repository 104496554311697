import {
  toString1pkumu07cwy4m as toString,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isComparableviyv6ctzm76u as isComparable,
  isNumberiramasdbon0i as isNumber,
  numberToDouble210hrknaofnhf as numberToDouble,
  compareTo3ankvs086tmwq as compareTo,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  ArrayList3it5z8td81qkl as ArrayList,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  Unit_instance104q5opgivhr8 as Unit_instance,
  toList3jhuyej2anx2q as toList,
  emptyList1g2z5xcrvp2zy as emptyList,
  toList2zksu85ukrmi as toList_0,
  KtMap140uvy3s5zad8 as KtMap,
  isInterface3d6p8outrmvmk as isInterface,
  toListcthpck5qntdg as toList_1,
  isCharSequence1ju9jr1w86plq as isCharSequence,
  Iterable1y3qizuf89iou as Iterable,
  toString30pk9tzaqopn as toString_0,
  KtMutableList1beimitadwkna as KtMutableList,
  KtMutableMap1kqeifoi36kpz as KtMutableMap,
  getOrNull1go7ef9ldk0df as getOrNull,
  KtList3hktaavzmj137 as KtList,
  NullPointerException3mu0rhxjjitqq as NullPointerException,
  listOfvhqybd2zx248 as listOf,
  toIntOrNull3w2d066r9pvwm as toIntOrNull,
  toDoubleOrNullkxwozihadygj as toDoubleOrNull,
  joinToString1cxrrlmo0chqs as joinToString,
  VOID7hggqo3abtya as VOID,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  numberToLong1a4cndvg6c52s as numberToLong,
  equals2au1ep9vhcato as equals,
  Long2qws0ah9gnpki as Long,
  hashCodeq5arwsb9dgti as hashCode,
  Comparable198qfk8pnblz0 as Comparable,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  toIntOrNull1j8dcc6to13o4 as toIntOrNull_0,
  toLongOrNull12l1gfztrukv1 as toLongOrNull,
  StringBuildermazzzhj6kkai as StringBuilder,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class Companion {
  f3h() {
    return get_dyn(get_dynApi().g3h());
  }
}
class Dyn {
  constructor(value) {
    this.e3h_1 = value;
  }
  m3h(other) {
    return Dyn__compareTo_impl_7znl52(this.e3h_1, other);
  }
  d(other) {
    return Dyn__compareTo_impl_7znl52_0(this, other);
  }
  toString() {
    return Dyn__toString_impl_9l0v3s(this.e3h_1);
  }
  hashCode() {
    return Dyn__hashCode_impl_vc6rwn(this.e3h_1);
  }
  equals(other) {
    return Dyn__equals_impl_sp125n(this.e3h_1, other);
  }
}
class DynApi {}
function get_global() {
  return Unit_instance;
}
class DynamicInternal {
  g3h() {
    return globalThis;
  }
  i3h(instance, key) {
    // Inline function 'kotlin.js.asDynamic' call
    return instance[key];
  }
  h3h(instance, key, value) {
    // Inline function 'kotlin.js.asDynamic' call
    instance[key] = value;
  }
}
//endregion
function _Dyn___init__impl__6cuxxc(value) {
  return value;
}
function _Dyn___get_value__impl__bemnlg($this) {
  return $this;
}
function _Dyn___get_isNotNull__impl__di7my3($this) {
  return !(_Dyn___get_value__impl__bemnlg($this) == null);
}
function Dyn__toComparable_impl_pfql3z($this) {
  var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (tmp0_subject == null) {
    tmp = isComparable(0) ? 0 : THROW_CCE();
  } else {
    if (!(tmp0_subject == null) ? isComparable(tmp0_subject) : false) {
      var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
      tmp = isComparable(tmp_0) ? tmp_0 : THROW_CCE();
    } else {
      var tmp_1 = toString(_Dyn___get_value__impl__bemnlg($this));
      tmp = isComparable(tmp_1) ? tmp_1 : THROW_CCE();
    }
  }
  return tmp;
}
function Dyn__compareTo_impl_7znl52($this, other) {
  var l = $this;
  var r = other;
  var tmp;
  var tmp_0 = _Dyn___get_value__impl__bemnlg(l);
  if (isNumber(tmp_0)) {
    var tmp_1 = _Dyn___get_value__impl__bemnlg(r);
    tmp = isNumber(tmp_1);
  } else {
    tmp = false;
  }
  if (tmp) {
    return compareTo(numberToDouble(_Dyn___get_value__impl__bemnlg(l)), numberToDouble(_Dyn___get_value__impl__bemnlg(r)));
  }
  var lc = Dyn__toComparable_impl_pfql3z(l);
  var rc = Dyn__toComparable_impl_pfql3z(r);
  return getKClassFromExpression(lc).yf(rc) ? compareTo(lc, rc) : -1;
}
function Dyn__compareTo_impl_7znl52_0($this, other) {
  return Dyn__compareTo_impl_7znl52($this.e3h_1, other instanceof Dyn ? other.e3h_1 : THROW_CCE());
}
function Dyn__toString_impl_9l0v3s($this) {
  return toString_1($this, _Dyn___get_value__impl__bemnlg($this));
}
function Dyn__toStringOrNull_impl_7e6cgy($this) {
  var tmp;
  if (_Dyn___get_isNotNull__impl__di7my3($this)) {
    tmp = Dyn__toString_impl_9l0v3s($this);
  } else {
    tmp = null;
  }
  return tmp;
}
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
function Dyn__toList_impl_pxssgr($this) {
  // Inline function 'kotlin.collections.map' call
  var this_0 = Dyn__toListAny_impl_f7wxv7($this);
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'korlibs.io.dynamic.Dyn.toList.<anonymous>' call
    var tmp$ret$0 = new Dyn(get_dyn(item));
    destination.f(tmp$ret$0);
  }
  return destination;
}
function Dyn__toListAny_impl_f7wxv7($this) {
  return toList(Dyn__toIterableAny_impl_fib3cp($this));
}
function Dyn__toIterableAny_impl_fib3cp($this) {
  var tmp3_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (tmp3_subject == null) {
    // Inline function 'kotlin.collections.listOf' call
    tmp = emptyList();
  } else {
    if (!(tmp3_subject == null) ? isInterface(tmp3_subject, Iterable) : false) {
      tmp = _Dyn___get_value__impl__bemnlg($this);
    } else {
      if (!(tmp3_subject == null) ? isCharSequence(tmp3_subject) : false) {
        tmp = toList_1(_Dyn___get_value__impl__bemnlg($this));
      } else {
        if (!(tmp3_subject == null) ? isInterface(tmp3_subject, KtMap) : false) {
          tmp = toList_0(_Dyn___get_value__impl__bemnlg($this));
        } else {
          // Inline function 'kotlin.collections.listOf' call
          tmp = emptyList();
        }
      }
    }
  }
  return tmp;
}
function Dyn__set_impl_2ozvx8($this, key, value) {
  var tmp7_subject = _Dyn___get_value__impl__bemnlg($this);
  if (!(tmp7_subject == null) ? isInterface(tmp7_subject, KtMutableMap) : false) {
    // Inline function 'kotlin.collections.set' call
    var tmp = _Dyn___get_value__impl__bemnlg($this);
    (isInterface(tmp, KtMutableMap) ? tmp : THROW_CCE()).r3(key, value);
  } else {
    if (!(tmp7_subject == null) ? isInterface(tmp7_subject, KtMutableList) : false) {
      var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
      (isInterface(tmp_0, KtMutableList) ? tmp_0 : THROW_CCE()).p3(Dyn__toInt_impl_nu3u6q(get_dyn(key)), value);
    } else {
      get_dynApi().h3h(_Dyn___get_value__impl__bemnlg($this), toString_0(key), value);
    }
  }
}
function Dyn__get_impl_6l2p80($this, key) {
  return Dyn__get_impl_6l2p80_0($this, _Dyn___get_value__impl__bemnlg(key));
}
function Dyn__get_impl_6l2p80_0($this, key) {
  return _getOrThrow($this, key, false);
}
function _getOrThrow($this, key, doThrow) {
  var tmp8_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (tmp8_subject == null) {
    var tmp_0;
    if (doThrow) {
      throw NullPointerException.tf("Trying to access '" + toString_0(key) + "'");
    } else {
      tmp_0 = get_dyn(null);
    }
    tmp = tmp_0;
  } else {
    if (!(tmp8_subject == null) ? isInterface(tmp8_subject, KtMap) : false) {
      var tmp_1 = _Dyn___get_value__impl__bemnlg($this);
      tmp = get_dyn((isInterface(tmp_1, KtMap) ? tmp_1 : THROW_CCE()).k3(key));
    } else {
      if (!(tmp8_subject == null) ? isInterface(tmp8_subject, KtList) : false) {
        tmp = get_dyn(getOrNull(_Dyn___get_value__impl__bemnlg($this), Dyn__toInt_impl_nu3u6q(get_dyn(key))));
      } else {
        tmp = get_dyn(get_dynApi().i3h(_Dyn___get_value__impl__bemnlg($this), toString_0(key)));
      }
    }
  }
  return tmp;
}
function _Dyn___get_orNull__impl__ox3tzj($this) {
  var tmp11_safe_receiver = _Dyn___get_value__impl__bemnlg($this);
  return tmp11_safe_receiver == null ? null : new Dyn(get_dyn(tmp11_safe_receiver));
}
function _Dyn___get_listAny__impl__vrilgp($this) {
  var tmp;
  if (_Dyn___get_value__impl__bemnlg($this) == null) {
    // Inline function 'kotlin.collections.listOf' call
    tmp = emptyList();
  } else {
    var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
    if (!(tmp_0 == null) ? isInterface(tmp_0, KtList) : false) {
      tmp = _Dyn___get_value__impl__bemnlg($this);
    } else {
      var tmp_1 = _Dyn___get_value__impl__bemnlg($this);
      if (!(tmp_1 == null) ? isInterface(tmp_1, Iterable) : false) {
        tmp = toList(_Dyn___get_value__impl__bemnlg($this));
      } else {
        tmp = listOf(_Dyn___get_value__impl__bemnlg($this));
      }
    }
  }
  return tmp;
}
function _Dyn___get_keysAny__impl__whx5cj($this) {
  var tmp;
  var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
  if (!(tmp_0 == null) ? isInterface(tmp_0, KtMap) : false) {
    tmp = toList(_Dyn___get_value__impl__bemnlg($this).l3());
  } else {
    // Inline function 'kotlin.collections.listOf' call
    tmp = emptyList();
  }
  return tmp;
}
function _Dyn___get_list__impl__qpwjp($this) {
  // Inline function 'kotlin.collections.map' call
  var this_0 = _Dyn___get_listAny__impl__vrilgp($this);
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'korlibs.io.dynamic.Dyn.<get-list>.<anonymous>' call
    var tmp$ret$0 = new Dyn(get_dyn(item));
    destination.f(tmp$ret$0);
  }
  return destination;
}
function _Dyn___get_keys__impl__o4xmor($this) {
  // Inline function 'kotlin.collections.map' call
  var this_0 = _Dyn___get_keysAny__impl__whx5cj($this);
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'korlibs.io.dynamic.Dyn.<get-keys>.<anonymous>' call
    var tmp$ret$0 = new Dyn(get_dyn(item));
    destination.f(tmp$ret$0);
  }
  return destination;
}
function Dyn__toNumber_impl_rlhtn4($this, $receiver) {
  var tmp = toIntOrNull($receiver);
  var tmp0_elvis_lhs = (tmp == null ? true : isNumber(tmp)) ? tmp : null;
  var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? toDoubleOrNull($receiver) : tmp0_elvis_lhs;
  return tmp1_elvis_lhs == null ? NaN : tmp1_elvis_lhs;
}
function Dyn__toNumber_impl_rlhtn4_0($this) {
  var tmp15_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (tmp15_subject == null) {
    tmp = 0;
  } else {
    if (isNumber(tmp15_subject)) {
      tmp = _Dyn___get_value__impl__bemnlg($this);
    } else {
      if (!(tmp15_subject == null) ? typeof tmp15_subject === 'boolean' : false) {
        tmp = _Dyn___get_value__impl__bemnlg($this) ? 1 : 0;
      } else {
        if (!(tmp15_subject == null) ? typeof tmp15_subject === 'string' : false) {
          var tmp0_elvis_lhs = toIntSafe(_Dyn___get_value__impl__bemnlg($this));
          var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? toDoubleSafe(_Dyn___get_value__impl__bemnlg($this)) : tmp0_elvis_lhs;
          tmp = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
        } else {
          tmp = Dyn__toNumber_impl_rlhtn4($this, toString(_Dyn___get_value__impl__bemnlg($this)));
        }
      }
    }
  }
  return tmp;
}
function toString_1($this, value) {
  var tmp;
  if (value == null) {
    tmp = '';
  } else {
    if (!(value == null) ? typeof value === 'string' : false) {
      tmp = value;
    } else {
      if (!(value == null) ? typeof value === 'number' : false) {
        var tmp_0;
        if (value === numberToInt(value)) {
          tmp_0 = numberToInt(value).toString();
        } else {
          tmp_0 = value.toString();
        }
        tmp = tmp_0;
      } else {
        if (!(value == null) ? isInterface(value, Iterable) : false) {
          tmp = '[' + joinToString(value, ', ', VOID, VOID, VOID, VOID, Dyn$toString$lambda($this)) + ']';
        } else {
          if (!(value == null) ? isInterface(value, KtMap) : false) {
            // Inline function 'kotlin.collections.map' call
            // Inline function 'kotlin.collections.mapTo' call
            var destination = ArrayList.w(value.j1());
            // Inline function 'kotlin.collections.iterator' call
            var tmp0_iterator = value.z1().f1();
            while (tmp0_iterator.g1()) {
              var item = tmp0_iterator.h1();
              // Inline function 'korlibs.io.dynamic.Dyn.toString.<anonymous>' call
              var tmp$ret$1 = quote(toString_1($this, item.a2())) + ': ' + toString_1($this, item.b2());
              destination.f(tmp$ret$1);
            }
            tmp = '{' + joinToString(destination, ', ') + '}';
          } else {
            tmp = toString(value);
          }
        }
      }
    }
  }
  return tmp;
}
function Dyn__toInt_impl_nu3u6q($this) {
  return numberToInt(Dyn__toNumber_impl_rlhtn4_0($this));
}
function Dyn__toLong_impl_mq8zcz($this) {
  return numberToLong(Dyn__toNumber_impl_rlhtn4_0($this));
}
function Dyn__toDouble_impl_bcyrzs($this) {
  return numberToDouble(Dyn__toNumber_impl_rlhtn4_0($this));
}
function Dyn__toBoolOrNull_impl_2dk92d($this) {
  var tmp17_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (!(tmp17_subject == null) ? typeof tmp17_subject === 'boolean' : false) {
    tmp = _Dyn___get_value__impl__bemnlg($this);
  } else {
    if (!(tmp17_subject == null) ? typeof tmp17_subject === 'string' : false) {
      tmp = equals(_Dyn___get_value__impl__bemnlg($this), '1') || equals(_Dyn___get_value__impl__bemnlg($this), 'true') || equals(_Dyn___get_value__impl__bemnlg($this), 'on');
    } else {
      if (isNumber(tmp17_subject)) {
        tmp = !(Dyn__toInt_impl_nu3u6q($this) === 0);
      } else {
        tmp = null;
      }
    }
  }
  return tmp;
}
function Dyn__toLongOrNull_impl_ws7y8j($this) {
  var tmp19_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (isNumber(tmp19_subject)) {
    tmp = Dyn__toLong_impl_mq8zcz($this);
  } else {
    if (!(tmp19_subject == null) ? typeof tmp19_subject === 'string' : false) {
      tmp = toLongSafe(_Dyn___get_value__impl__bemnlg($this));
    } else {
      tmp = null;
    }
  }
  return tmp;
}
function Dyn__toIntDefault_impl_x52w9z($this, default_0) {
  var tmp21_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (isNumber(tmp21_subject)) {
    tmp = Dyn__toInt_impl_nu3u6q($this);
  } else {
    if (!(tmp21_subject == null) ? typeof tmp21_subject === 'string' : false) {
      var tmp0_elvis_lhs = toIntSafe(_Dyn___get_value__impl__bemnlg($this), 10);
      tmp = tmp0_elvis_lhs == null ? default_0 : tmp0_elvis_lhs;
    } else {
      tmp = default_0;
    }
  }
  return tmp;
}
function Dyn__toIntDefault$default_impl_hr0tbe($this, default_0, $super) {
  default_0 = default_0 === VOID ? 0 : default_0;
  return $super === VOID ? Dyn__toIntDefault_impl_x52w9z($this, default_0) : $super.j3h.call(new Dyn($this), default_0);
}
function Dyn__toLongDefault_impl_auunl0($this, default_0) {
  var tmp22_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (isNumber(tmp22_subject)) {
    tmp = Dyn__toLong_impl_mq8zcz($this);
  } else {
    if (!(tmp22_subject == null) ? typeof tmp22_subject === 'string' : false) {
      var tmp0_elvis_lhs = toLongSafe(_Dyn___get_value__impl__bemnlg($this), 10);
      tmp = tmp0_elvis_lhs == null ? default_0 : tmp0_elvis_lhs;
    } else {
      tmp = default_0;
    }
  }
  return tmp;
}
function Dyn__toLongDefault$default_impl_w5acm9($this, default_0, $super) {
  default_0 = default_0 === VOID ? new Long(0, 0) : default_0;
  return $super === VOID ? Dyn__toLongDefault_impl_auunl0($this, default_0) : $super.k3h.call(new Dyn($this), default_0);
}
function Dyn__toDoubleDefault_impl_fspogf($this, default_0) {
  var tmp24_subject = _Dyn___get_value__impl__bemnlg($this);
  var tmp;
  if (isNumber(tmp24_subject)) {
    tmp = Dyn__toDouble_impl_bcyrzs($this);
  } else {
    if (!(tmp24_subject == null) ? typeof tmp24_subject === 'string' : false) {
      tmp = Dyn__toDouble_impl_bcyrzs($this);
    } else {
      tmp = default_0;
    }
  }
  return tmp;
}
function Dyn__toDoubleDefault$default_impl_jxe8i4($this, default_0, $super) {
  default_0 = default_0 === VOID ? 0.0 : default_0;
  return $super === VOID ? Dyn__toDoubleDefault_impl_fspogf($this, default_0) : $super.l3h.call(new Dyn($this), default_0);
}
function _Dyn___get_str__impl__dup4cs($this) {
  return Dyn__toString_impl_9l0v3s($this);
}
function _Dyn___get_int__impl__sh8lhm($this) {
  return Dyn__toIntDefault$default_impl_hr0tbe($this);
}
function _Dyn___get_bool__impl__nv3iun($this) {
  var tmp0_elvis_lhs = Dyn__toBoolOrNull_impl_2dk92d($this);
  return tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs;
}
function _Dyn___get_double__impl__jlvfa0($this) {
  return Dyn__toDoubleDefault$default_impl_jxe8i4($this);
}
function _Dyn___get_long__impl__wdxl7n($this) {
  return Dyn__toLongDefault$default_impl_w5acm9($this);
}
function Dyn__hashCode_impl_vc6rwn($this) {
  return $this == null ? 0 : hashCode($this);
}
function Dyn__equals_impl_sp125n($this, other) {
  if (!(other instanceof Dyn))
    return false;
  var tmp0_other_with_cast = other instanceof Dyn ? other.e3h_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function Dyn$toString$lambda(this$0) {
  return function (it) {
    return toString_1(this$0, it);
  };
}
function get_dyn(_this__u8e3s4) {
  return _Dyn___init__impl__6cuxxc(_this__u8e3s4);
}
function toIntSafe(_this__u8e3s4, radix) {
  radix = radix === VOID ? 10 : radix;
  return toIntOrNull_0(_this__u8e3s4, radix);
}
function toDoubleSafe(_this__u8e3s4) {
  return toDoubleOrNull(_this__u8e3s4);
}
function quote(_this__u8e3s4) {
  return '"' + escape(_this__u8e3s4) + '"';
}
function toLongSafe(_this__u8e3s4, radix) {
  radix = radix === VOID ? 10 : radix;
  return toLongOrNull(_this__u8e3s4, radix);
}
function escape(_this__u8e3s4) {
  // Inline function 'kotlin.text.buildString' call
  var capacity = _this__u8e3s4.length;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.nc(capacity);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.dynamic.escape.<anonymous>' call
  var inductionVariable = 0;
  var last = _this__u8e3s4.length;
  while (inductionVariable < last) {
    var c = charSequenceGet(_this__u8e3s4, inductionVariable);
    inductionVariable = inductionVariable + 1 | 0;
    if (c === _Char___init__impl__6a9atx(10))
      this_0.ic('\\n');
    else if (c === _Char___init__impl__6a9atx(13))
      this_0.ic('\\r');
    else if (c === _Char___init__impl__6a9atx(9))
      this_0.ic('\\t');
    else if (c === _Char___init__impl__6a9atx(92))
      this_0.ic('\\\\');
    else
      this_0.jc(c);
  }
  return this_0.toString();
}
function get_dynApi() {
  _init_properties_DynApi_kt__g2fyyp();
  return dynApi;
}
var dynApi;
var properties_initialized_DynApi_kt_kcoydf;
function _init_properties_DynApi_kt__g2fyyp() {
  if (!properties_initialized_DynApi_kt_kcoydf) {
    properties_initialized_DynApi_kt_kcoydf = true;
    dynApi = DynamicInternal_instance;
  }
}
var DynamicInternal_instance;
function DynamicInternal_getInstance() {
  return DynamicInternal_instance;
}
//region block: post-declaration
initMetadataForCompanion(Companion);
initMetadataForClass(Dyn, 'Dyn', VOID, VOID, [Comparable], [2, 1]);
initMetadataForInterface(DynApi, 'DynApi', VOID, VOID, VOID, [2, 3]);
initMetadataForObject(DynamicInternal, 'DynamicInternal', VOID, VOID, [DynApi], [2, 3]);
//endregion
//region block: init
Companion_instance = new Companion();
DynamicInternal_instance = new DynamicInternal();
//endregion
//region block: exports
export {
  _Dyn___init__impl__6cuxxc as _Dyn___init__impl__6cuxxc3ue03b1bho0av,
  _Dyn___get_bool__impl__nv3iun as _Dyn___get_bool__impl__nv3iun3b5fbq0u806ya,
  _Dyn___get_double__impl__jlvfa0 as _Dyn___get_double__impl__jlvfa039ekym31xde58,
  Dyn__get_impl_6l2p80_0 as Dyn__get_impl_6l2p806an9lmzdx97q,
  Dyn__get_impl_6l2p80 as Dyn__get_impl_6l2p8012yk0oj6467m4,
  _Dyn___get_int__impl__sh8lhm as _Dyn___get_int__impl__sh8lhm1cuta1m3oa6bm,
  _Dyn___get_keys__impl__o4xmor as _Dyn___get_keys__impl__o4xmorpcvsvkc7z1k1,
  _Dyn___get_list__impl__qpwjp as _Dyn___get_list__impl__qpwjpy1x88d8kx8tg,
  _Dyn___get_long__impl__wdxl7n as _Dyn___get_long__impl__wdxl7n30zdp3a58g184,
  _Dyn___get_orNull__impl__ox3tzj as _Dyn___get_orNull__impl__ox3tzj2fenqk0jjmmjz,
  Dyn__set_impl_2ozvx8 as Dyn__set_impl_2ozvx82r2n5zj0vrm3z,
  _Dyn___get_str__impl__dup4cs as _Dyn___get_str__impl__dup4cs3qkjliiqi5l9f,
  Dyn__toDoubleDefault_impl_fspogf as Dyn__toDoubleDefault_impl_fspogf18eiqqlf0n9f2,
  Dyn__toList_impl_pxssgr as Dyn__toList_impl_pxssgr1ih96kqmm5wba,
  Dyn__toLongOrNull_impl_ws7y8j as Dyn__toLongOrNull_impl_ws7y8juw8f44nyez8r,
  Dyn__toStringOrNull_impl_7e6cgy as Dyn__toStringOrNull_impl_7e6cgy1o7rt6j433mfo,
  Dyn__toString_impl_9l0v3s as Dyn__toString_impl_9l0v3s1r5ef9jhsnww1,
  _Dyn___get_value__impl__bemnlg as _Dyn___get_value__impl__bemnlg289snf7781s03,
  Companion_instance as Companion_instance3c5rz779n238s,
  Dyn as Dyng396lmez04lc,
  get_dyn as get_dynw5edstkdie96,
};
//endregion
