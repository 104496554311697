import {
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  VOID7hggqo3abtya as VOID,
  toString1pkumu07cwy4m as toString,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  hashCodeq5arwsb9dgti as hashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  equals2au1ep9vhcato as equals,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  equals2v6cggk171b6e as equals_0,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  Unit_instance104q5opgivhr8 as Unit_instance,
  ArrayList3it5z8td81qkl as ArrayList,
  to2cs3ny02qtbcb as to,
  toMutableList20rdgwi7d3cwi as toMutableList,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
} from './kotlin-kotlin-stdlib.mjs';
import { get_jsGlobalThis3odmbn60utci as get_jsGlobalThis } from './korlibs-korlibs-platform.mjs';
import {
  readAllc0x1zv8qqb58 as readAll,
  openAsync37anb1e84urmo as openAsync,
} from './korlibs-korlibs-io-stream.mjs';
import { await2pwm8za8tntlx as await_0 } from './kotlinx-coroutines-core.mjs';
import { asInt8Array769i4qnpoc13 as asInt8Array } from './korlibs-korlibs-memory.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    Companion_instance = this;
    this.w3g_1 = get_defaultHttpFetch();
  }
  x3g(method, host, port, path, secure, headers, body, $completion) {
    return this.w3g_1.x3g(method, host, port, path, secure, headers, body, $completion);
  }
}
class HttpFetchResult {
  constructor(status, statusText, headers, bodyRaw) {
    this.y3g_1 = status;
    this.z3g_1 = statusText;
    this.a3h_1 = headers;
    this.b3h_1 = bodyRaw;
  }
  toString() {
    return 'HttpFetchResult(status=' + this.y3g_1 + ', statusText=' + this.z3g_1 + ', headers=' + toString(this.a3h_1) + ', bodyRaw=' + toString(this.b3h_1) + ')';
  }
  hashCode() {
    var result = this.y3g_1;
    result = imul(result, 31) + getStringHashCode(this.z3g_1) | 0;
    result = imul(result, 31) + hashCode(this.a3h_1) | 0;
    result = imul(result, 31) + hashCode(this.b3h_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof HttpFetchResult))
      return false;
    var tmp0_other_with_cast = other instanceof HttpFetchResult ? other : THROW_CCE();
    if (!(this.y3g_1 === tmp0_other_with_cast.y3g_1))
      return false;
    if (!(this.z3g_1 === tmp0_other_with_cast.z3g_1))
      return false;
    if (!equals(this.a3h_1, tmp0_other_with_cast.a3h_1))
      return false;
    if (!equals(this.b3h_1, tmp0_other_with_cast.b3h_1))
      return false;
    return true;
  }
}
class defaultHttpFetch$1 {
  x3g(method, host, port, path, secure, headers, body, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_fetch__osg116(this, method, host, port, path, secure, headers, body, $completion), $completion);
  }
}
//endregion
var Companion_instance;
function Companion_getInstance() {
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
function get_defaultHttpFetch() {
  _init_properties_HttpRawFetch_js_kt__twfcvf();
  return defaultHttpFetch;
}
var defaultHttpFetch;
function *_generator_fetch__osg116($this, method, host, port, path, secure, headers, body, $completion) {
  var scheme = secure ? 'https' : 'http';
  var headersJsObj = {};
  var _iterator__ex2g4s = headers.f1();
  $l$loop_0: while (_iterator__ex2g4s.g1()) {
    var _destruct__k2r9zo = _iterator__ex2g4s.h1();
    var key = _destruct__k2r9zo.km();
    var value = _destruct__k2r9zo.lm();
    if (equals_0(key, 'connection', true))
      continue $l$loop_0;
    if (equals_0(key, 'content-length', true))
      continue $l$loop_0;
    headersJsObj[key] = value;
  }
  var url = scheme + '://' + host + ':' + port + path;
  var tmp = get_jsGlobalThis();
  // Inline function 'org.w3c.fetch.RequestInit' call
  var tmp_0;
  if (body == null) {
    tmp_0 = null;
  } else {
    var tmp_1 = readAll(body, $completion);
    if (tmp_1 === get_COROUTINE_SUSPENDED())
      tmp_1 = yield tmp_1;
    tmp_0 = tmp_1;
  }
  var body_0 = tmp_0;
  var referrer = undefined;
  var referrerPolicy = undefined;
  var mode = undefined;
  var credentials = undefined;
  var cache = undefined;
  var redirect = undefined;
  var integrity = undefined;
  var keepalive = undefined;
  var window_0 = undefined;
  var o = {};
  o['method'] = method;
  o['headers'] = headersJsObj;
  o['body'] = body_0;
  o['referrer'] = referrer;
  o['referrerPolicy'] = referrerPolicy;
  o['mode'] = mode;
  o['credentials'] = credentials;
  o['cache'] = cache;
  o['redirect'] = redirect;
  o['integrity'] = integrity;
  o['keepalive'] = keepalive;
  o['window'] = window_0;
  var tmp_2 = await_0(tmp.fetch(url, o), $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  var result = tmp_2;
  // Inline function 'kotlin.js.unsafeCast' call
  var tmp_3 = await_0(result.arrayBuffer(), $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  // Inline function 'kotlin.js.asDynamic' call
  var body_1 = asInt8Array(tmp_3);
  var rheaders = result.headers;
  // Inline function 'kotlin.js.unsafeCast' call
  var tmp_4 = Array;
  // Inline function 'kotlin.js.asDynamic' call
  // Inline function 'kotlin.js.asDynamic' call
  var keys = tmp_4.from(rheaders.keys());
  // Inline function 'kotlin.collections.filter' call
  // Inline function 'kotlin.collections.map' call
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(keys.length);
  var inductionVariable = 0;
  var last = keys.length;
  while (inductionVariable < last) {
    var item = keys[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'korlibs.io.http.core.<no name provided>.fetch.<anonymous>' call
    var tmp$ret$6 = to(item, rheaders.get(item));
    destination.f(tmp$ret$6);
  }
  // Inline function 'kotlin.collections.filterTo' call
  var this_0 = toMutableList(destination);
  var destination_0 = ArrayList.m1();
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'korlibs.io.http.core.<no name provided>.fetch.<anonymous>' call
    if (!equals_0(element.im_1, 'content-encoding', true) && !equals_0(element.im_1, 'transfer-encoding', true)) {
      destination_0.f(element);
    }
  }
  var outHeaders = destination_0;
  var tmp_5 = result.status;
  var tmp_6 = result.statusText;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  return new HttpFetchResult(tmp_5, tmp_6, outHeaders, openAsync(body_1));
}
var properties_initialized_HttpRawFetch_js_kt_h8nx03;
function _init_properties_HttpRawFetch_js_kt__twfcvf() {
  if (!properties_initialized_HttpRawFetch_js_kt_h8nx03) {
    properties_initialized_HttpRawFetch_js_kt_h8nx03 = true;
    defaultHttpFetch = new defaultHttpFetch$1();
  }
}
//region block: post-declaration
initMetadataForCompanion(Companion, VOID, VOID, [7]);
initMetadataForClass(HttpFetchResult, 'HttpFetchResult');
initMetadataForClass(defaultHttpFetch$1, VOID, VOID, VOID, VOID, [7]);
//endregion
//region block: exports
export {
  Companion_getInstance as Companion_getInstanceac8g91hxvmd4,
};
//endregion
