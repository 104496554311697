import {
  toSet1glep2u1u9tcb as toSet,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isCharSequence1ju9jr1w86plq as isCharSequence,
  trim11nh7r46at6sx as trim,
  toString1pkumu07cwy4m as toString,
  Regexxgw0gjiagf4z as Regex,
  ArrayList3it5z8td81qkl as ArrayList,
  Unit_instance104q5opgivhr8 as Unit_instance,
  joinToString1cxrrlmo0chqs as joinToString,
  StringBuildermazzzhj6kkai as StringBuilder,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  joinTo3lkanfaxbzac2 as joinTo,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
//endregion
function removeClass(_this__u8e3s4, cssClasses) {
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.collections.any' call
    var inductionVariable = 0;
    var last = cssClasses.length;
    while (inductionVariable < last) {
      var element = cssClasses[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlinx.dom.removeClass.<anonymous>' call
      if (hasClass(_this__u8e3s4, element)) {
        tmp$ret$1 = true;
        break $l$block;
      }
    }
    tmp$ret$1 = false;
  }
  if (tmp$ret$1) {
    var toBeRemoved = toSet(cssClasses);
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.text.split' call
    // Inline function 'kotlin.text.trim' call
    var this_0 = _this__u8e3s4.className;
    var this_1 = toString(trim(isCharSequence(this_0) ? this_0 : THROW_CCE()));
    // Inline function 'kotlin.text.toRegex' call
    // Inline function 'kotlin.collections.filterTo' call
    var this_2 = Regex.ni('\\s+').vi(this_1, 0);
    var destination = ArrayList.m1();
    var tmp0_iterator = this_2.f1();
    while (tmp0_iterator.g1()) {
      var element_0 = tmp0_iterator.h1();
      // Inline function 'kotlinx.dom.removeClass.<anonymous>' call
      if (!toBeRemoved.f3(element_0)) {
        destination.f(element_0);
      }
    }
    _this__u8e3s4.className = joinToString(destination, ' ');
    return true;
  }
  return false;
}
function addClass(_this__u8e3s4, cssClasses) {
  // Inline function 'kotlin.collections.filterNot' call
  // Inline function 'kotlin.collections.filterNotTo' call
  var destination = ArrayList.m1();
  var inductionVariable = 0;
  var last = cssClasses.length;
  while (inductionVariable < last) {
    var element = cssClasses[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'kotlinx.dom.addClass.<anonymous>' call
    if (!hasClass(_this__u8e3s4, element)) {
      destination.f(element);
    }
  }
  var missingClasses = destination;
  // Inline function 'kotlin.collections.isNotEmpty' call
  if (!missingClasses.a1()) {
    // Inline function 'kotlin.text.trim' call
    var this_0 = _this__u8e3s4.className;
    var presentClasses = toString(trim(isCharSequence(this_0) ? this_0 : THROW_CCE()));
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_1 = StringBuilder.h();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.dom.addClass.<anonymous>' call
    this_1.ic(presentClasses);
    // Inline function 'kotlin.text.isEmpty' call
    if (!(charSequenceLength(presentClasses) === 0)) {
      this_1.ic(' ');
    }
    joinTo(missingClasses, this_1, ' ');
    _this__u8e3s4.className = this_1.toString();
    return true;
  }
  return false;
}
function hasClass(_this__u8e3s4, cssClass) {
  // Inline function 'kotlin.text.matches' call
  var this_0 = _this__u8e3s4.className;
  // Inline function 'kotlin.text.toRegex' call
  var this_1 = '(^|.*\\s+)' + cssClass + '($|\\s+.*)';
  return Regex.ni(this_1).oi(this_0);
}
//region block: exports
export {
  addClass as addClass1ihnmxbr5xjr2,
  removeClass as removeClass3jnpygopqxh7s,
};
//endregion
