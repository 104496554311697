import {
  GameButton_LX_getInstance3lu9212pwqcbw as GameButton_LX_getInstance,
  GamepadInfoyijqqtg064su as GamepadInfo,
  GameButton_LY_getInstance2eqq84hvfc09s as GameButton_LY_getInstance,
  Companion_getInstance31f0y2ylke4bl as Companion_getInstance,
  Key_LEFT_getInstance4xv6s4l9ffcr as Key_LEFT_getInstance,
  Key_RIGHT_getInstancevkjx63tsbibt as Key_RIGHT_getInstance,
  Key_UP_getInstanceb8rctup40hnz as Key_UP_getInstance,
  Key_DOWN_getInstance3uv13du3770bq as Key_DOWN_getInstance,
  Key_SPACE_getInstance13zcep8fbcon1 as Key_SPACE_getInstance,
  GameButton_BUTTON_SOUTH_getInstancefuuqbnftxu5s as GameButton_BUTTON_SOUTH_getInstance,
} from './korge-root-korge-core.mjs';
import {
  VOID7hggqo3abtya as VOID,
  getNumberHashCode2l4nbdcihl25f as getNumberHashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  equals2au1ep9vhcato as equals,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  Unit_instance104q5opgivhr8 as Unit_instance,
  listOfvhqybd2zx248 as listOf,
  numberToDouble210hrknaofnhf as numberToDouble,
  ArrayList3it5z8td81qkl as ArrayList,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  protoOf180f3jzyo7rfj as protoOf,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  KMutableProperty025txtn5b59pq1 as KMutableProperty0,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
} from './kotlin-kotlin-stdlib.mjs';
import { Signal3nnez3346azzf as Signal } from './korlibs-korlibs-concurrent.mjs';
import {
  Container7486fjmip554 as Container,
  addTo25e29vq105prw as addTo,
  get_gamepad1qh8ubuabluby as get_gamepad,
  xyssniz4rympby as xy,
  addFixedUpdaterkdt88bj87xku as addFixedUpdater,
  FastEllipse3srb40xmwqack as FastEllipse,
  colorMulerj70rh5yczt as colorMul,
  anchor3c5itxst996yy as anchor,
  singleTouchbklee9j9g3fy as singleTouch,
} from './korge-root-korge.mjs';
import {
  Companion_getInstancewvzae8n0fprj as Companion_getInstance_0,
  Companion_getInstance21plr7f541fx9 as Companion_getInstance_1,
  Companion_getInstanceuv1p1c5gwy11 as Companion_getInstance_2,
  Vector2D378fe5rov8fgw as Vector2D,
  Size2Dum5ayuyhhau7 as Size2D,
  Angle__cosine_impl_bgvzunl3ekczcg3dy9 as Angle__cosine_impl_bgvzun,
  Angle__sine_impl_3l11xvmzbai5p75zu2 as Angle__sine_impl_3l11xv,
} from './korlibs-korlibs-math-vector.mjs';
import { get_hz3a5o5age7b808 as get_hz } from './korlibs-korlibs-time.mjs';
import { Colors_getInstance1qvds1isfbag2 as Colors_getInstance } from './korlibs-korlibs-image.mjs';
import {
  clamp011ml7wx1d2b7sp as clamp01,
  clampdicp8njiht45 as clamp,
  normalizeAlmostZeronwhxiyvg9dek as normalizeAlmostZero,
} from './korlibs-korlibs-math-core.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class ChangedEvent {
  constructor(button, old, new_0) {
    button = button === VOID ? GameButton_LX_getInstance() : button;
    old = old === VOID ? 0.0 : old;
    new_0 = new_0 === VOID ? 0.0 : new_0;
    this.thu_1 = button;
    this.uhu_1 = old;
    this.vhu_1 = new_0;
  }
  whu() {
    return !(this.uhu_1 === 0.0);
  }
  xhu() {
    return !(this.vhu_1 === 0.0);
  }
  toString() {
    return 'ChangedEvent(button=' + this.thu_1.toString() + ', old=' + this.uhu_1 + ', new=' + this.vhu_1 + ')';
  }
  hashCode() {
    var result = this.thu_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.uhu_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.vhu_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ChangedEvent))
      return false;
    var tmp0_other_with_cast = other instanceof ChangedEvent ? other : THROW_CCE();
    if (!this.thu_1.equals(tmp0_other_with_cast.thu_1))
      return false;
    if (!equals(this.uhu_1, tmp0_other_with_cast.uhu_1))
      return false;
    if (!equals(this.vhu_1, tmp0_other_with_cast.vhu_1))
      return false;
    return true;
  }
}
class VirtualController {
  constructor(container) {
    this.yhu_1 = container;
    this.zhu_1 = new GamepadInfo();
    this.ahv_1 = new GamepadInfo();
    this.bhv_1 = new ChangedEvent();
    this.chv_1 = new Signal();
  }
  dhv() {
    return this.j8h(GameButton_LX_getInstance());
  }
  ehv() {
    return this.j8h(GameButton_LY_getInstance());
  }
  j8h(button) {
    return this.ahv_1.q8g_1[button.m8h()];
  }
  fhv(button, value) {
    this.ahv_1.q8g_1[button.m8h()] = value;
  }
  ghv(button, block) {
    return this.chv_1.v1x(VirtualController$changed$lambda(button, block));
  }
  hhv(button, block) {
    return this.ghv(button, VirtualController$down$lambda(block));
  }
  ihv() {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = Companion_getInstance().t8h_1;
    var n = 0;
    while (n < this_0.length) {
      // Inline function 'korlibs.korge.virtualcontroller.VirtualController.updated.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var it = this_0[_unary__edvuaz];
      var old = this.zhu_1.j8h(it);
      var new_0 = this.ahv_1.j8h(it);
      if (!(old === new_0)) {
        this.bhv_1.thu_1 = it;
        this.bhv_1.uhu_1 = old;
        this.bhv_1.vhu_1 = new_0;
        this.chv_1.x1x(this.bhv_1);
      }
    }
    this.zhu_1.v8g(this.ahv_1);
  }
}
class Companion {
  constructor() {
    Companion_instance = this;
    this.jhv_1 = new VirtualStickConfig(Key_LEFT_getInstance(), Key_RIGHT_getInstance(), Key_UP_getInstance(), Key_DOWN_getInstance(), GameButton_LX_getInstance(), GameButton_LY_getInstance(), Companion_getInstance_1().v2g_1);
  }
}
class VirtualConfig {}
function computePos(rect) {
  // Inline function 'korlibs.math.geom.Vector2D.plus' call
  var this_0 = rect.e2u(this.uf4());
  var that = this.yi0();
  return Vector2D.k2h(this_0.i2h_1 + that.i2h_1, this_0.j2h_1 + that.j2h_1);
}
class VirtualStickConfig {
  constructor(left, right, up, down, lx, ly, anchor, offset) {
    Companion_getInstance_3();
    offset = offset === VOID ? Companion_getInstance_2().a2i_1 : offset;
    this.zhx_1 = left;
    this.ahy_1 = right;
    this.bhy_1 = up;
    this.chy_1 = down;
    this.dhy_1 = lx;
    this.ehy_1 = ly;
    this.fhy_1 = anchor;
    this.ghy_1 = offset;
  }
  uf4() {
    return this.fhy_1;
  }
  yi0() {
    return this.ghy_1;
  }
  toString() {
    return 'VirtualStickConfig(left=' + this.zhx_1.toString() + ', right=' + this.ahy_1.toString() + ', up=' + this.bhy_1.toString() + ', down=' + this.chy_1.toString() + ', lx=' + this.dhy_1.toString() + ', ly=' + this.ehy_1.toString() + ', anchor=' + this.fhy_1.toString() + ', offset=' + this.ghy_1.toString() + ')';
  }
  hashCode() {
    var result = this.zhx_1.hashCode();
    result = imul(result, 31) + this.ahy_1.hashCode() | 0;
    result = imul(result, 31) + this.bhy_1.hashCode() | 0;
    result = imul(result, 31) + this.chy_1.hashCode() | 0;
    result = imul(result, 31) + this.dhy_1.hashCode() | 0;
    result = imul(result, 31) + this.ehy_1.hashCode() | 0;
    result = imul(result, 31) + this.fhy_1.hashCode() | 0;
    result = imul(result, 31) + this.ghy_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof VirtualStickConfig))
      return false;
    var tmp0_other_with_cast = other instanceof VirtualStickConfig ? other : THROW_CCE();
    if (!this.zhx_1.equals(tmp0_other_with_cast.zhx_1))
      return false;
    if (!this.ahy_1.equals(tmp0_other_with_cast.ahy_1))
      return false;
    if (!this.bhy_1.equals(tmp0_other_with_cast.bhy_1))
      return false;
    if (!this.chy_1.equals(tmp0_other_with_cast.chy_1))
      return false;
    if (!this.dhy_1.equals(tmp0_other_with_cast.dhy_1))
      return false;
    if (!this.ehy_1.equals(tmp0_other_with_cast.ehy_1))
      return false;
    if (!this.fhy_1.equals(tmp0_other_with_cast.fhy_1))
      return false;
    if (!this.ghy_1.equals(tmp0_other_with_cast.ghy_1))
      return false;
    return true;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    this.khv_1 = new VirtualButtonConfig(Key_SPACE_getInstance(), GameButton_BUTTON_SOUTH_getInstance(), Companion_getInstance_1().x2g_1);
  }
}
class VirtualButtonConfig {
  constructor(key, button, anchor, offset) {
    Companion_getInstance_4();
    offset = offset === VOID ? Companion_getInstance_2().a2i_1 : offset;
    this.ui0_1 = key;
    this.vi0_1 = button;
    this.wi0_1 = anchor;
    this.xi0_1 = offset;
  }
  uf4() {
    return this.wi0_1;
  }
  yi0() {
    return this.xi0_1;
  }
  toString() {
    return 'VirtualButtonConfig(key=' + this.ui0_1.toString() + ', button=' + this.vi0_1.toString() + ', anchor=' + this.wi0_1.toString() + ', offset=' + this.xi0_1.toString() + ')';
  }
  hashCode() {
    var result = this.ui0_1.hashCode();
    result = imul(result, 31) + this.vi0_1.hashCode() | 0;
    result = imul(result, 31) + this.wi0_1.hashCode() | 0;
    result = imul(result, 31) + this.xi0_1.hashCode() | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof VirtualButtonConfig))
      return false;
    var tmp0_other_with_cast = other instanceof VirtualButtonConfig ? other : THROW_CCE();
    if (!this.ui0_1.equals(tmp0_other_with_cast.ui0_1))
      return false;
    if (!this.vi0_1.equals(tmp0_other_with_cast.vi0_1))
      return false;
    if (!this.wi0_1.equals(tmp0_other_with_cast.wi0_1))
      return false;
    if (!this.xi0_1.equals(tmp0_other_with_cast.xi0_1))
      return false;
    return true;
  }
}
class VirtualStickView extends Container {
  static xhx(controller, config, radius) {
    radius = radius === VOID ? 64.0 : radius;
    var $this = this.vbw();
    $this.thx_1 = controller;
    $this.uhx_1 = config;
    var tmp = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.fastEllipse' call
    var size = Size2D.k2t(radius * 1.5, radius * 1.5);
    var color = Colors_getInstance().g4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = colorMul(FastEllipse.qgs(size), color);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fastEllipse.<anonymous>' call
    var this_2 = anchor(this_1, Companion_getInstance_1().f2h());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.virtualcontroller.VirtualStickView.circleOut.<anonymous>' call
    this_2.kbj(0.5);
    tmp.vhx_1 = this_2;
    var tmp_0 = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.fastEllipse' call
    var size_0 = Size2D.k2t(radius, radius);
    var color_0 = Colors_getInstance().g4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_3 = colorMul(FastEllipse.qgs(size_0), color_0);
    // Inline function 'kotlin.apply' call
    var this_4 = addTo(this_3, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fastEllipse.<anonymous>' call
    var this_5 = anchor(this_4, Companion_getInstance_1().f2h());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.virtualcontroller.VirtualStickView.circle.<anonymous>' call
    this_5.kbj(0.75);
    tmp_0.whx_1 = this_5;
    singleTouch($this, VOID, VOID, VirtualStickView$lambda($this));
    return $this;
  }
  xcv() {
    return this.whx_1.xcv().d2j_1;
  }
  ai1(pos) {
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale = this.xcv();
    var tmp$ret$0 = Vector2D.k2h(pos.i2h_1 * scale, pos.j2h_1 * scale);
    this.zi0(tmp$ret$0);
  }
  zi0(pos) {
    // Inline function 'korlibs.math.geom.Companion.polar' call
    var this_0 = Companion_getInstance_2();
    var angle = pos.o2s();
    // Inline function 'korlibs.math.geom.Companion.polar' call
    var up = this_0.e2i_1;
    var polar = Vector2D.k2h(0.0 + Angle__cosine_impl_bgvzun(angle, up) * 1.0, 0.0 + Angle__sine_impl_3l11xv(angle, up) * 1.0);
    var magnitudeScale = clamp01(pos.w2w() / this.xcv());
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scaledPolar = Vector2D.k2h(polar.i2h_1 * magnitudeScale, polar.j2h_1 * magnitudeScale);
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale = this.xcv();
    var tmp$ret$3 = Vector2D.k2h(scaledPolar.i2h_1 * scale, scaledPolar.j2h_1 * scale);
    this.whx_1.s5w(tmp$ret$3);
    this.thx_1.fhv(this.uhx_1.dhy_1, scaledPolar.i2h_1);
    this.thx_1.fhv(this.uhx_1.ehy_1, scaledPolar.j2h_1);
  }
}
class VirtualButtonView extends Container {
  static ti0(controller, config, radius) {
    radius = radius === VOID ? 64.0 : radius;
    var $this = this.vbw();
    $this.pi0_1 = controller;
    $this.qi0_1 = config;
    var tmp = $this;
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.korge.view.fastEllipse' call
    var size = Size2D.k2t(radius, radius);
    var color = Colors_getInstance().g4r_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = colorMul(FastEllipse.qgs(size), color);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, $this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.fastEllipse.<anonymous>' call
    var this_2 = anchor(this_1, Companion_getInstance_1().f2h());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.virtualcontroller.VirtualButtonView.circle.<anonymous>' call
    this_2.kbj(0.8);
    tmp.ri0_1 = this_2;
    $this.si0_1 = radiusAvg$factory($this.ri0_1);
    singleTouch($this, VOID, VOID, VirtualButtonView$lambda($this));
    return $this;
  }
  bi1(pressed) {
    // Inline function 'korlibs.math.toInt' call
    var tmp$ret$0 = pressed ? 1 : 0;
    this.pi0_1.fhv(this.qi0_1.vi0_1, tmp$ret$0);
    this.ri0_1.kbj(pressed ? 1.0 : 0.8);
  }
}
class KeyboardStickController {
  constructor(config, virtualStickView) {
    this.ci1_1 = config;
    this.di1_1 = virtualStickView;
    this.ei1_1 = 0.0;
    this.fi1_1 = 0.0;
    this.gi1_1 = false;
  }
  hi1(view, controller) {
    var tmp6_safe_receiver = view == null ? null : view.pbj();
    var tmp7_safe_receiver = tmp6_safe_receiver == null ? null : tmp6_safe_receiver.lbj();
    var tmp0_elvis_lhs = tmp7_safe_receiver == null ? null : tmp7_safe_receiver.faa_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var keys = tmp;
    var keyPressed = keys.ma9(this.ci1_1.zhx_1) || keys.ma9(this.ci1_1.ahy_1) || keys.ma9(this.ci1_1.bhy_1) || keys.ma9(this.ci1_1.chy_1);
    if (keyPressed) {
      this.gi1_1 = true;
    }
    if (keys.ma9(this.ci1_1.zhx_1))
      this.ei1_1 = this.ei1_1 - 0.125;
    else if (keys.ma9(this.ci1_1.ahy_1))
      this.ei1_1 = this.ei1_1 + 0.125;
    else
      this.ei1_1 = this.ei1_1 / 2.0;
    if (keys.ma9(this.ci1_1.bhy_1))
      this.fi1_1 = this.fi1_1 - 0.125;
    else if (keys.ma9(this.ci1_1.chy_1))
      this.fi1_1 = this.fi1_1 + 0.125;
    else
      this.fi1_1 = this.fi1_1 / 2.0;
    this.ei1_1 = normalizeAlmostZero(clamp(this.ei1_1, -1.0, +1.0));
    this.fi1_1 = normalizeAlmostZero(clamp(this.fi1_1, -1.0, +1.0));
    if (this.gi1_1) {
      controller.fhv(this.ci1_1.dhy_1, this.ei1_1);
      controller.fhv(this.ci1_1.ehy_1, this.fi1_1);
      var tmp8_safe_receiver = this.di1_1;
      if (tmp8_safe_receiver == null)
        null;
      else {
        tmp8_safe_receiver.ai1(Vector2D.u2n(this.ei1_1, this.fi1_1));
      }
    }
    if (this.gi1_1 && this.ei1_1 === 0.0 && this.fi1_1 === 0.0 && !keyPressed) {
      this.gi1_1 = false;
    }
  }
}
class KeyboardButtonController {
  constructor(config, virtualButtonView) {
    this.ii1_1 = config;
    this.ji1_1 = virtualButtonView;
    this.ki1_1 = false;
  }
  hi1(view, controller) {
    var tmp2_safe_receiver = view.pbj();
    var tmp3_safe_receiver = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.lbj();
    var tmp0_elvis_lhs = tmp3_safe_receiver == null ? null : tmp3_safe_receiver.faa_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var keys = tmp;
    var keyPressed = keys.ma9(this.ii1_1.ui0_1);
    if (keyPressed) {
      this.ki1_1 = true;
    }
    if (this.ki1_1) {
      // Inline function 'korlibs.math.toInt' call
      var tmp$ret$0 = keyPressed ? 1 : 0;
      controller.fhv(this.ii1_1.vi0_1, tmp$ret$0);
      var tmp4_safe_receiver = this.ji1_1;
      if (tmp4_safe_receiver == null)
        null;
      else {
        tmp4_safe_receiver.bi1(keyPressed);
      }
    }
    if (!keyPressed) {
      this.ki1_1 = false;
    }
  }
}
//endregion
function VirtualController$changed$lambda($button, $block) {
  return function (it) {
    if (it.thu_1.equals($button))
      $block(it);
    return Unit_instance;
  };
}
function VirtualController$down$lambda($block) {
  return function (it) {
    if (!it.whu() && it.xhu())
      $block();
    return Unit_instance;
  };
}
function virtualController(_this__u8e3s4, sticks, buttons, buttonRadius, boundsRadiusScale) {
  sticks = sticks === VOID ? listOf(Companion_getInstance_3().jhv_1) : sticks;
  buttons = buttons === VOID ? listOf(Companion_getInstance_4().khv_1) : buttons;
  buttonRadius = buttonRadius === VOID ? 92 : buttonRadius;
  boundsRadiusScale = boundsRadiusScale === VOID ? 1.25 : boundsRadiusScale;
  // Inline function 'korlibs.korge.view.container' call
  // Inline function 'korlibs.korge.view.addTo' call
  var this_0 = Container.vbw(false);
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.korge.view.container.<anonymous>' call
  var container = addTo(this_0, _this__u8e3s4);
  var controller = new VirtualController(container);
  var boundsRadiusScaled = numberToDouble(buttonRadius) * numberToDouble(boundsRadiusScale);
  var rect = Companion_getInstance_0().g2t(boundsRadiusScaled, boundsRadiusScaled, _this__u8e3s4.j2y() - boundsRadiusScaled, _this__u8e3s4.k2y() - boundsRadiusScaled);
  // Inline function 'kotlin.collections.arrayListOf' call
  var virtualStickViews = ArrayList.m1();
  // Inline function 'kotlin.collections.arrayListOf' call
  var virtualButtonViews = ArrayList.m1();
  // Inline function 'kotlin.collections.arrayListOf' call
  var keyStickControllers = ArrayList.m1();
  // Inline function 'kotlin.collections.arrayListOf' call
  var keyButtonControllers = ArrayList.m1();
  // Inline function 'kotlin.collections.mutableMapOf' call
  var stickByButton = LinkedHashMap.vc();
  // Inline function 'kotlin.collections.mutableMapOf' call
  var buttonByButton = LinkedHashMap.vc();
  // Inline function 'korlibs.korge.input.gamepad' call
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var $this$gamepad = get_gamepad(_this__u8e3s4);
  var lastGamepadInfo = new GamepadInfo();
  $this$gamepad.nbk(virtualController$lambda(lastGamepadInfo, stickByButton, buttonByButton));
  var _iterator__ex2g4s = sticks.f1();
  while (_iterator__ex2g4s.g1()) {
    var stick = _iterator__ex2g4s.h1();
    var virtualStickView = xy(VirtualStickView.xhx(controller, stick, numberToDouble(buttonRadius)), stick.yhx(rect));
    // Inline function 'kotlin.collections.set' call
    var key = stick.dhy_1;
    stickByButton.r3(key, virtualStickView);
    // Inline function 'kotlin.collections.set' call
    var key_0 = stick.ehy_1;
    stickByButton.r3(key_0, virtualStickView);
    // Inline function 'kotlin.collections.plusAssign' call
    virtualStickViews.f(virtualStickView);
    container.ubw(virtualStickView);
    // Inline function 'kotlin.collections.plusAssign' call
    var element = new KeyboardStickController(stick, virtualStickView);
    keyStickControllers.f(element);
  }
  var _iterator__ex2g4s_0 = buttons.f1();
  while (_iterator__ex2g4s_0.g1()) {
    var button = _iterator__ex2g4s_0.h1();
    var virtualButtonView = xy(VirtualButtonView.ti0(controller, button, numberToDouble(buttonRadius)), button.yhx(rect));
    // Inline function 'kotlin.collections.plusAssign' call
    virtualButtonViews.f(virtualButtonView);
    container.ubw(virtualButtonView);
    // Inline function 'kotlin.collections.set' call
    var key_1 = button.vi0_1;
    buttonByButton.r3(key_1, virtualButtonView);
    // Inline function 'kotlin.collections.plusAssign' call
    var element_0 = new KeyboardButtonController(button, virtualButtonView);
    keyButtonControllers.f(element_0);
  }
  var tmp = get_hz(60);
  addFixedUpdater(_this__u8e3s4, tmp, VOID, VOID, virtualController$lambda_0(boundsRadiusScaled, keyStickControllers, controller, keyButtonControllers, virtualStickViews, virtualButtonViews));
  return controller;
}
var Companion_instance;
function Companion_getInstance_3() {
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
var Companion_instance_0;
function Companion_getInstance_4() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function VirtualStickView$lambda$lambda(it) {
  return Unit_instance;
}
function VirtualStickView$lambda$lambda_0(this$0) {
  return function (it) {
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var this_0 = it.tbq_1;
    var that = it.ubq_1;
    var tmp$ret$0 = Vector2D.k2h(this_0.i2h_1 - that.i2h_1, this_0.j2h_1 - that.j2h_1);
    this$0.zi0(tmp$ret$0);
    return Unit_instance;
  };
}
function VirtualStickView$lambda$lambda_1(this$0) {
  return function (it) {
    this$0.zi0(Vector2D.k2l(0, 0));
    return Unit_instance;
  };
}
function VirtualStickView$lambda(this$0) {
  return function ($this$singleTouch, it) {
    $this$singleTouch.rbr_1.w1x(VirtualStickView$lambda$lambda);
    $this$singleTouch.ubr_1.w1x(VirtualStickView$lambda$lambda_0(this$0));
    $this$singleTouch.wbr_1.w1x(VirtualStickView$lambda$lambda_1(this$0));
    return Unit_instance;
  };
}
function VirtualButtonView$lambda$lambda(this$0) {
  return function (it) {
    this$0.bi1(true);
    return Unit_instance;
  };
}
function VirtualButtonView$lambda$lambda_0(this$0) {
  return function (it) {
    this$0.bi1(false);
    return Unit_instance;
  };
}
function VirtualButtonView$lambda(this$0) {
  return function ($this$singleTouch, it) {
    $this$singleTouch.rbr_1.w1x(VirtualButtonView$lambda$lambda(this$0));
    $this$singleTouch.wbr_1.w1x(VirtualButtonView$lambda$lambda_0(this$0));
    return Unit_instance;
  };
}
function virtualController$lambda($lastGamepadInfo, $stickByButton, $buttonByButton) {
  return function (it) {
    var indexedObject = Companion_getInstance().t8h_1;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var button = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      if (!(it.j8h(button) === $lastGamepadInfo.j8h(button))) {
        var tmp0_safe_receiver = $stickByButton.k3(button);
        if (tmp0_safe_receiver == null)
          null;
        else {
          // Inline function 'kotlin.let' call
          // Inline function 'kotlin.contracts.contract' call
          tmp0_safe_receiver.ai1(Vector2D.u2n(it.j8h(tmp0_safe_receiver.uhx_1.dhy_1), -it.j8h(tmp0_safe_receiver.uhx_1.ehy_1)));
        }
        var tmp1_safe_receiver = $buttonByButton.k3(button);
        if (tmp1_safe_receiver == null)
          null;
        else {
          tmp1_safe_receiver.bi1(!(it.j8h(button) === 0.0));
        }
      }
    }
    $lastGamepadInfo.v8g(it);
    return Unit_instance;
  };
}
function virtualController$lambda_0($boundsRadiusScaled, $keyStickControllers, $controller, $keyButtonControllers, $virtualStickViews, $virtualButtonViews) {
  return function ($this$addFixedUpdater) {
    var rect = Companion_getInstance_0().g2t($boundsRadiusScaled, $boundsRadiusScaled, $this$addFixedUpdater.j2y() - $boundsRadiusScaled, $this$addFixedUpdater.k2y() - $boundsRadiusScaled);
    var this_0 = $keyStickControllers;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.korge.virtualcontroller.virtualController.<anonymous>.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      this_0.i1(_unary__edvuaz).hi1($this$addFixedUpdater, $controller);
    }
    var this_1 = $keyButtonControllers;
    var n_0 = 0;
    while (n_0 < this_1.j1()) {
      // Inline function 'korlibs.korge.virtualcontroller.virtualController.<anonymous>.<anonymous>' call
      var _unary__edvuaz_0 = n_0;
      n_0 = _unary__edvuaz_0 + 1 | 0;
      this_1.i1(_unary__edvuaz_0).hi1($this$addFixedUpdater, $controller);
    }
    $controller.ihv();
    var this_2 = $virtualStickViews;
    var n_1 = 0;
    while (n_1 < this_2.j1()) {
      // Inline function 'korlibs.korge.virtualcontroller.virtualController.<anonymous>.<anonymous>' call
      var _unary__edvuaz_1 = n_1;
      n_1 = _unary__edvuaz_1 + 1 | 0;
      var it = this_2.i1(_unary__edvuaz_1);
      it.s5w(it.uhx_1.yhx(rect));
    }
    var this_3 = $virtualButtonViews;
    var n_2 = 0;
    while (n_2 < this_3.j1()) {
      // Inline function 'korlibs.korge.virtualcontroller.virtualController.<anonymous>.<anonymous>' call
      var _unary__edvuaz_2 = n_2;
      n_2 = _unary__edvuaz_2 + 1 | 0;
      var it_0 = this_3.i1(_unary__edvuaz_2);
      it_0.s5w(it_0.qi0_1.yhx(rect));
    }
    return Unit_instance;
  };
}
function radiusAvg$factory($b0) {
  return getPropertyCallableRef('radiusAvg', 0, KMutableProperty0, function () {
    return $b0.egv();
  }, function (value) {
    return $b0.dgv(value);
  });
}
//region block: post-declaration
initMetadataForClass(ChangedEvent, 'ChangedEvent', ChangedEvent);
initMetadataForClass(VirtualController, 'VirtualController');
initMetadataForCompanion(Companion);
initMetadataForInterface(VirtualConfig, 'VirtualConfig');
protoOf(VirtualStickConfig).yhx = computePos;
initMetadataForClass(VirtualStickConfig, 'VirtualStickConfig', VOID, VOID, [VirtualConfig]);
initMetadataForCompanion(Companion_0);
protoOf(VirtualButtonConfig).yhx = computePos;
initMetadataForClass(VirtualButtonConfig, 'VirtualButtonConfig', VOID, VOID, [VirtualConfig]);
initMetadataForClass(VirtualStickView, 'VirtualStickView');
initMetadataForClass(VirtualButtonView, 'VirtualButtonView');
initMetadataForClass(KeyboardStickController, 'KeyboardStickController');
initMetadataForClass(KeyboardButtonController, 'KeyboardButtonController');
//endregion
//region block: exports
export {
  VirtualButtonConfig as VirtualButtonConfig5thqhp2dciu2,
  VirtualStickConfig as VirtualStickConfig3mkwzgqv6aq3s,
  virtualController as virtualController2sr4e2nu9cp6s,
};
//endregion
