import {
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  VOID7hggqo3abtya as VOID,
  StringCompanionObject_instance2odz3s3zbrixa as StringCompanionObject_instance,
  Unit_instance104q5opgivhr8 as Unit_instance,
  emptyList1g2z5xcrvp2zy as emptyList,
  LazyThreadSafetyMode_PUBLICATION_getInstance3hlj875zwihx0 as LazyThreadSafetyMode_PUBLICATION_getInstance,
  lazy1261dae0bgscp as lazy,
  asList2ho2pewtsfvv as asList,
  toString1pkumu07cwy4m as toString,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  captureStack1fzi4aczwc4hg as captureStack,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  ArrayList3it5z8td81qkl as ArrayList,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  KClass1cc9rfeybg8hs as KClass,
  isInterface3d6p8outrmvmk as isInterface,
  Triple1vhi3d0dgpnjb as Triple,
  getKClass1s3j9wy1cofik as getKClass,
  Paire9pteg33gng7 as Pair,
  Entry2xmjmyutzoq3p as Entry,
  KtMap140uvy3s5zad8 as KtMap,
  KtMutableMap1kqeifoi36kpz as KtMutableMap,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  HashMap1a0ld5kgwhmhv as HashMap,
  KtSetjrjc7fhfd6b9 as KtSet,
  KtMutableSetwuwn7k5m570a as KtMutableSet,
  LinkedHashSet2tkztfx86kyx2 as LinkedHashSet,
  HashSet2dzve9y63nf0v as HashSet,
  Collection1k04j3hzsbod0 as Collection,
  KtList3hktaavzmj137 as KtList,
  KtMutableList1beimitadwkna as KtMutableList,
  copyToArray2j022khrow2yi as copyToArray,
  _Result___get_value__impl__bjfvqg1uwg4i093qaqh as _Result___get_value__impl__bjfvqg,
  _Result___get_isFailure__impl__jpiriv1ffgeoyqwuzmr as _Result___get_isFailure__impl__jpiriv,
  Result3t1vadv16kmzk as Result,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  equals2au1ep9vhcato as equals,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  Iterable1y3qizuf89iou as Iterable,
  isBlank1dvkhjjvox3p0 as isBlank,
  toList383f556t1dixk as toList,
  toHashSet1qrcsl3g8ugc8 as toHashSet,
  toBooleanArray2u3qw7fjwsmuh as toBooleanArray,
  withIndex3s8q7w1g0hyfn as withIndex,
  to2cs3ny02qtbcb as to,
  toMap1vec9topfei08 as toMap,
  lazy2hsh8ze7j6ikd as lazy_0,
  contentEqualsaf55p28mnw74 as contentEquals,
  until1jbpn0z3f8lbg as until,
  joinToString1cxrrlmo0chqs as joinToString,
  protoOf180f3jzyo7rfj as protoOf,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  Long2qws0ah9gnpki as Long,
  Char19o2r8palgjof as Char,
  createThis2j2avj17cvnv2 as createThis,
  Companion_getInstance2b73c6hwbaiuw as Companion_getInstance,
  Duration5ynfiptaqcrg as Duration,
  toIntOrNull3w2d066r9pvwm as toIntOrNull,
  hashCodeq5arwsb9dgti as hashCode,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  isArray1hxjqtqy632bc as isArray,
  step18s9qzr5xwxat as step,
  getValue48kllevslyh6 as getValue,
  longArray288a0fctlmjmj as longArray,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  get_lastIndex1y2f6o9u8hnf7 as get_lastIndex,
  countTrailingZeroBits1k55x07cygoff as countTrailingZeroBits,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  toString30pk9tzaqopn as toString_0,
  KTypeParameter1s8efufd4mbj5 as KTypeParameter,
  contentHashCode2i020q5tbeh2s as contentHashCode,
  booleanArray2jdug9b51huk7 as booleanArray,
  emptyMapr06gerzljqtm as emptyMap,
  Companion_getInstance2g172z58li19e as Companion_getInstance_0,
  isCharArray21auq5hbrg68m as isCharArray,
  charArray2ujmm1qusno00 as charArray,
  DoubleCompanionObject_instance2yqrcskeqd1tm as DoubleCompanionObject_instance,
  isDoubleArray1wyh4nyf7pjxn as isDoubleArray,
  FloatCompanionObject_instance29smzmmxq0czz as FloatCompanionObject_instance,
  isFloatArrayjjscnqphw92j as isFloatArray,
  Companion_getInstance1pxg306pnafvz as Companion_getInstance_1,
  isLongArray2fdt3z7yu3ef as isLongArray,
  Companion_getInstance1poxudqc1ru3p as Companion_getInstance_2,
  ULongArray3nd0d80mdwjj8 as ULongArray,
  _ULongArray___init__impl__twm1l3dtyhn7dp1s1o as _ULongArray___init__impl__twm1l3,
  _ULong___init__impl__c78o9k3sq3egh1wtk64 as _ULong___init__impl__c78o9k,
  IntCompanionObject_instance2nvyd29rdzxbs as IntCompanionObject_instance,
  isIntArrayeijsubfngq38 as isIntArray,
  Companion_getInstance1z323tr26bmxd as Companion_getInstance_3,
  UIntArrayrp6cv44n5v4y as UIntArray,
  _UIntArray___init__impl__ghjpc61vzqu2w8hzlmo as _UIntArray___init__impl__ghjpc6,
  _UInt___init__impl__l7qpdl2soskspn8hzmt as _UInt___init__impl__l7qpdl,
  ShortCompanionObject_instanceyg0ko6hbt9iy as ShortCompanionObject_instance,
  isShortArraywz30zxwtqi8h as isShortArray,
  Companion_getInstanceojp2cj59jqir as Companion_getInstance_4,
  UShortArray11avpmknxdgvv as UShortArray,
  _UShortArray___init__impl__9b26ef1qu918hp04ryp as _UShortArray___init__impl__9b26ef,
  _UShort___init__impl__jigrne3hvr35xwb2tau as _UShort___init__impl__jigrne,
  ByteCompanionObject_instancerp27gpfua1xf as ByteCompanionObject_instance,
  isByteArray4nnzfn1x4o3w as isByteArray,
  Companion_getInstance374brtr06v94p as Companion_getInstance_5,
  UByteArray2qu4d6gwssdf9 as UByteArray,
  _UByteArray___init__impl__ip4y9ngbudn9tzlaub as _UByteArray___init__impl__ip4y9n,
  _UByte___init__impl__g9hnc41eipfvebfo5lc as _UByte___init__impl__g9hnc4,
  BooleanCompanionObject_instance3nvf6tg77gv83 as BooleanCompanionObject_instance,
  isBooleanArray35llghle4c6w1 as isBooleanArray,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  copyOf2p23ljc5f5ea3 as copyOf,
  copyOfgossjg6lh6js as copyOf_0,
  copyOfq9pcgcgbldck as copyOf_1,
  copyOf9mbsebmgnw4t as copyOf_2,
  _ULongArray___get_size__impl__ju6dtr2lupre3hp1i1j as _ULongArray___get_size__impl__ju6dtr,
  _ULongArray___get_storage__impl__28e64j2vtqnej9gwyzk as _ULongArray___get_storage__impl__28e64j,
  _ULongArray___init__impl__twm1l3295zpff12hmq5 as _ULongArray___init__impl__twm1l3_0,
  ULongArray__set_impl_z19mvh2aq1j5sku1dmt as ULongArray__set_impl_z19mvh,
  copyOf3rutauicler23 as copyOf_3,
  _UIntArray___get_size__impl__r6l8ci1bugw7olx20y1 as _UIntArray___get_size__impl__r6l8ci,
  _UIntArray___get_storage__impl__92a0v0xnuzo89qkbti as _UIntArray___get_storage__impl__92a0v0,
  _UIntArray___init__impl__ghjpc637jks44g8hkay as _UIntArray___init__impl__ghjpc6_0,
  UIntArray__set_impl_7f2zu2251w30muf87a3 as UIntArray__set_impl_7f2zu2,
  copyOf39s58md6y6rn6 as copyOf_4,
  _UShortArray___get_size__impl__jqto1b23l9nxz4w47e3 as _UShortArray___get_size__impl__jqto1b,
  _UShortArray___get_storage__impl__t2jpv51ia7tudnx2pys as _UShortArray___get_storage__impl__t2jpv5,
  _UShortArray___init__impl__9b26ef2dcolj1kwzob7 as _UShortArray___init__impl__9b26ef_0,
  UShortArray__set_impl_6d8whp278fyjknsyrb1 as UShortArray__set_impl_6d8whp,
  copyOfwy6h3t5vzqpl as copyOf_5,
  _UByteArray___get_size__impl__h6pkdv2rtsewp499qpr as _UByteArray___get_size__impl__h6pkdv,
  _UByteArray___get_storage__impl__d4kctt3s3xst15ytm22 as _UByteArray___get_storage__impl__d4kctt,
  _UByteArray___init__impl__ip4y9nsjfjryamagcs as _UByteArray___init__impl__ip4y9n_0,
  UByteArray__set_impl_jvcicn3nfp6z28mafdl as UByteArray__set_impl_jvcicn,
  copyOf37mht4mx7mjgh as copyOf_6,
  trimIndent1qytc1wvt8suh as trimIndent,
  equals2v6cggk171b6e as equals_0,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  toString26mv3gzfo7fn as toString_1,
  titlecase36e9fbud5gg4t as titlecase,
  isLowerCase16nv9n55l9laa as isLowerCase,
  PrimitiveClasses_getInstance28ukyr6i8fs0q as PrimitiveClasses_getInstance,
  ULong3f9k7s38t3rfp as ULong,
  UInt1hthisrv6cndi as UInt,
  UShort26xnqty60t7le as UShort,
  UBytep4j7r1t64gz1 as UByte,
  Unitkvevlwgzwiuc as Unit,
  mapOf1xd03cq9cnmy8 as mapOf,
  lastOrNull1aq5oz189qoe1 as lastOrNull,
  get_lastIndex1yw0x4k50k51w as get_lastIndex_0,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  get_js1ale1wr4fbvs0 as get_js,
  findAssociatedObject1kb88g16k1goa as findAssociatedObject,
  IndexOutOfBoundsException1qfr429iumro0 as IndexOutOfBoundsException,
  get_indicesc04v40g017hw as get_indices,
  get_indices377latqcai313 as get_indices_0,
  Companion_instance3fplhgf4ipvld as Companion_instance,
  _Result___init__impl__xyqfz831xktsyjq1qrq as _Result___init__impl__xyqfz8,
  createFailure8paxfkfa5dc7 as createFailure,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class DeserializationStrategy {}
class KSerializer {}
class AbstractPolymorphicSerializer {
  static jjg() {
    return createThis(this);
  }
  vjf(decoder) {
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var descriptor = this.ujf();
    var composite = decoder.pjj(descriptor);
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>' call
      var klassName = null;
      var value = null;
      if (composite.fjk()) {
        tmp$ret$0 = decodeSequentially_0(this, composite);
        break $l$block;
      }
      mainLoop: while (true) {
        var index = composite.gjk(this.ujf());
        switch (index) {
          case -1:
            break mainLoop;
          case 0:
            klassName = composite.zjj(this.ujf(), index);
            break;
          case 1:
            var tmp$ret$2;
            $l$block_0: {
              // Inline function 'kotlin.requireNotNull' call
              var value_0 = klassName;
              // Inline function 'kotlin.contracts.contract' call
              if (value_0 == null) {
                // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>.<anonymous>' call
                var message = 'Cannot read polymorphic value before its type token';
                throw IllegalArgumentException.l(toString(message));
              } else {
                tmp$ret$2 = value_0;
                break $l$block_0;
              }
            }

            klassName = tmp$ret$2;
            var serializer = findPolymorphicSerializer(this, composite, klassName);
            value = composite.cjk(this.ujf(), index, serializer);
            break;
          default:
            var tmp0_elvis_lhs = klassName;
            throw SerializationException.sjg('Invalid index in polymorphic deserialization of ' + (tmp0_elvis_lhs == null ? 'unknown class' : tmp0_elvis_lhs) + ('\n Expected 0, 1 or DECODE_DONE(-1), but found ' + index));
        }
      }
      var tmp$ret$4;
      $l$block_1: {
        // Inline function 'kotlin.requireNotNull' call
        var value_1 = value;
        // Inline function 'kotlin.contracts.contract' call
        if (value_1 == null) {
          // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>.<anonymous>' call
          var message_0 = 'Polymorphic value has not been read for class ' + klassName;
          throw IllegalArgumentException.l(toString(message_0));
        } else {
          tmp$ret$4 = value_1;
          break $l$block_1;
        }
      }
      var tmp = tmp$ret$4;
      tmp$ret$0 = !(tmp == null) ? tmp : THROW_CCE();
    }
    var result = tmp$ret$0;
    composite.qjj(descriptor);
    return result;
  }
  mjg(decoder, klassName) {
    return decoder.ejk().ijk(this.kjg(), klassName);
  }
}
class PolymorphicSerializer extends AbstractPolymorphicSerializer {
  static ijg(baseClass) {
    var $this = this.jjg();
    $this.fjg_1 = baseClass;
    $this.gjg_1 = emptyList();
    var tmp = $this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.hjg_1 = lazy(tmp_0, PolymorphicSerializer$descriptor$delegate$lambda($this));
    return $this;
  }
  kjg() {
    return this.fjg_1;
  }
  static ljg(baseClass, classAnnotations) {
    var $this = this.ijg(baseClass);
    $this.gjg_1 = asList(classAnnotations);
    return $this;
  }
  ujf() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.hjg_1;
    descriptor$factory();
    return this_0.b2();
  }
  toString() {
    return 'kotlinx.serialization.PolymorphicSerializer(baseClass: ' + toString(this.fjg_1) + ')';
  }
}
class SerializationException extends IllegalArgumentException {
  static rjg() {
    var $this = this.ve();
    init_kotlinx_serialization_SerializationException($this);
    return $this;
  }
  static sjg(message) {
    var $this = this.l(message);
    init_kotlinx_serialization_SerializationException($this);
    return $this;
  }
  static tjg(message, cause) {
    var $this = this.xe(message, cause);
    init_kotlinx_serialization_SerializationException($this);
    return $this;
  }
}
class UnknownFieldException extends SerializationException {
  static zjg(message) {
    var $this = this.sjg(message);
    captureStack($this, $this.yjg_1);
    return $this;
  }
  static ajh(index) {
    return this.zjg('An unknown field for index ' + index);
  }
}
class MissingFieldException extends SerializationException {
  static hjh(missingFields, message, cause) {
    var $this = this.tjg(message, cause);
    captureStack($this, $this.gjh_1);
    $this.fjh_1 = missingFields;
    return $this;
  }
  static ijh(missingFields, serialName) {
    return this.hjh(missingFields, missingFields.j1() === 1 ? "Field '" + missingFields.i1(0) + "' is required for type with serial name '" + serialName + "', but it was missing" : 'Fields ' + toString(missingFields) + " are required for type with serial name '" + serialName + "', but they were missing", null);
  }
}
class SerialDescriptor {}
function get_isNullable() {
  return false;
}
function get_isInline() {
  return false;
}
function get_annotations() {
  return emptyList();
}
class ContextDescriptor {
  constructor(original, kClass) {
    this.pjh_1 = original;
    this.qjh_1 = kClass;
    this.rjh_1 = this.pjh_1.sjh() + '<' + this.qjh_1.xf() + '>';
  }
  tjh(index) {
    return this.pjh_1.tjh(index);
  }
  ujh(name) {
    return this.pjh_1.ujh(name);
  }
  vjh(index) {
    return this.pjh_1.vjh(index);
  }
  wjh(index) {
    return this.pjh_1.wjh(index);
  }
  xjh(index) {
    return this.pjh_1.xjh(index);
  }
  yjh() {
    return this.pjh_1.yjh();
  }
  ojh() {
    return this.pjh_1.ojh();
  }
  zjh() {
    return this.pjh_1.zjh();
  }
  aji() {
    return this.pjh_1.aji();
  }
  bji() {
    return this.pjh_1.bji();
  }
  sjh() {
    return this.rjh_1;
  }
  equals(other) {
    var tmp0_elvis_lhs = other instanceof ContextDescriptor ? other : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var another = tmp;
    return equals(this.pjh_1, another.pjh_1) && another.qjh_1.equals(this.qjh_1);
  }
  hashCode() {
    var result = this.qjh_1.hashCode();
    result = imul(31, result) + getStringHashCode(this.rjh_1) | 0;
    return result;
  }
  toString() {
    return 'ContextDescriptor(kClass: ' + toString(this.qjh_1) + ', original: ' + toString(this.pjh_1) + ')';
  }
}
class elementDescriptors$1$1 {
  constructor($this_elementDescriptors) {
    this.gji_1 = $this_elementDescriptors;
    this.fji_1 = $this_elementDescriptors.aji();
  }
  g1() {
    return this.fji_1 > 0;
  }
  h1() {
    var tmp = this.gji_1.aji();
    var _unary__edvuaz = this.fji_1;
    this.fji_1 = _unary__edvuaz - 1 | 0;
    return this.gji_1.wjh(tmp - _unary__edvuaz | 0);
  }
}
class _no_name_provided__qut3iv {
  constructor($this_elementDescriptors) {
    this.hji_1 = $this_elementDescriptors;
  }
  f1() {
    // Inline function 'kotlinx.serialization.descriptors.<get-elementDescriptors>.<anonymous>' call
    return new elementDescriptors$1$1(this.hji_1);
  }
}
class elementNames$1$1 {
  constructor($this_elementNames) {
    this.jji_1 = $this_elementNames;
    this.iji_1 = $this_elementNames.aji();
  }
  g1() {
    return this.iji_1 > 0;
  }
  h1() {
    var tmp = this.jji_1.aji();
    var _unary__edvuaz = this.iji_1;
    this.iji_1 = _unary__edvuaz - 1 | 0;
    return this.jji_1.tjh(tmp - _unary__edvuaz | 0);
  }
}
class _no_name_provided__qut3iv_0 {
  constructor($this_elementNames) {
    this.kji_1 = $this_elementNames;
  }
  f1() {
    // Inline function 'kotlinx.serialization.descriptors.<get-elementNames>.<anonymous>' call
    return new elementNames$1$1(this.kji_1);
  }
}
class ClassSerialDescriptorBuilder {
  constructor(serialName) {
    this.wjf_1 = serialName;
    this.xjf_1 = false;
    this.yjf_1 = emptyList();
    this.zjf_1 = ArrayList.m1();
    this.ajg_1 = HashSet.xa();
    this.bjg_1 = ArrayList.m1();
    this.cjg_1 = ArrayList.m1();
    this.djg_1 = ArrayList.m1();
  }
  lji(elementName, descriptor, annotations, isOptional) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!this.ajg_1.f(elementName)) {
      // Inline function 'kotlinx.serialization.descriptors.ClassSerialDescriptorBuilder.element.<anonymous>' call
      var message = "Element with name '" + elementName + "' is already registered in " + this.wjf_1;
      throw IllegalArgumentException.l(toString(message));
    }
    // Inline function 'kotlin.collections.plusAssign' call
    this.zjf_1.f(elementName);
    // Inline function 'kotlin.collections.plusAssign' call
    this.bjg_1.f(descriptor);
    // Inline function 'kotlin.collections.plusAssign' call
    this.cjg_1.f(annotations);
    // Inline function 'kotlin.collections.plusAssign' call
    this.djg_1.f(isOptional);
  }
  ejg(elementName, descriptor, annotations, isOptional, $super) {
    annotations = annotations === VOID ? emptyList() : annotations;
    isOptional = isOptional === VOID ? false : isOptional;
    var tmp;
    if ($super === VOID) {
      this.lji(elementName, descriptor, annotations, isOptional);
      tmp = Unit_instance;
    } else {
      tmp = $super.lji.call(this, elementName, descriptor, annotations, isOptional);
    }
    return tmp;
  }
}
class CachedNames {}
class SerialDescriptorImpl {
  constructor(serialName, kind, elementsCount, typeParameters, builder) {
    this.mji_1 = serialName;
    this.nji_1 = kind;
    this.oji_1 = elementsCount;
    this.pji_1 = builder.yjf_1;
    this.qji_1 = toHashSet(builder.zjf_1);
    var tmp = this;
    // Inline function 'kotlin.collections.toTypedArray' call
    var this_0 = builder.zjf_1;
    tmp.rji_1 = copyToArray(this_0);
    this.sji_1 = compactArray(builder.bjg_1);
    var tmp_0 = this;
    // Inline function 'kotlin.collections.toTypedArray' call
    var this_1 = builder.cjg_1;
    tmp_0.tji_1 = copyToArray(this_1);
    this.uji_1 = toBooleanArray(builder.djg_1);
    var tmp_1 = this;
    // Inline function 'kotlin.collections.map' call
    var this_2 = withIndex(this.rji_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_2, 10));
    var tmp0_iterator = this_2.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'kotlinx.serialization.descriptors.SerialDescriptorImpl.name2Index.<anonymous>' call
      var tmp$ret$2 = to(item.am_1, item.zl_1);
      destination.f(tmp$ret$2);
    }
    tmp_1.vji_1 = toMap(destination);
    this.wji_1 = compactArray(typeParameters);
    var tmp_2 = this;
    tmp_2.xji_1 = lazy_0(SerialDescriptorImpl$_hashCode$delegate$lambda(this));
  }
  sjh() {
    return this.mji_1;
  }
  yjh() {
    return this.nji_1;
  }
  aji() {
    return this.oji_1;
  }
  bji() {
    return this.pji_1;
  }
  yji() {
    return this.qji_1;
  }
  tjh(index) {
    return getChecked(this.rji_1, index);
  }
  ujh(name) {
    var tmp0_elvis_lhs = this.vji_1.k3(name);
    return tmp0_elvis_lhs == null ? -3 : tmp0_elvis_lhs;
  }
  vjh(index) {
    return getChecked(this.tji_1, index);
  }
  wjh(index) {
    return getChecked(this.sji_1, index);
  }
  xjh(index) {
    return getChecked_0(this.uji_1, index);
  }
  equals(other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof SerialDescriptorImpl)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.sjh() === other.sjh())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      // Inline function 'kotlinx.serialization.descriptors.SerialDescriptorImpl.equals.<anonymous>' call
      if (!contentEquals(this.wji_1, other.wji_1)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.aji() === other.aji())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.aji();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.wjh(index).sjh() === other.wjh(index).sjh())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.wjh(index).yjh(), other.wjh(index).yjh())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
  hashCode() {
    return _get__hashCode__tgwhef(this);
  }
  toString() {
    var tmp = until(0, this.oji_1);
    var tmp_0 = this.mji_1 + '(';
    return joinToString(tmp, ', ', tmp_0, ')', VOID, VOID, SerialDescriptorImpl$toString$lambda(this));
  }
}
class SerialKind {
  toString() {
    return ensureNotNull(getKClassFromExpression(this).xf());
  }
  hashCode() {
    return getStringHashCode(this.toString());
  }
}
class ENUM extends SerialKind {
  constructor() {
    ENUM_instance = null;
    super();
    ENUM_instance = this;
  }
}
class CONTEXTUAL extends SerialKind {
  constructor() {
    CONTEXTUAL_instance = null;
    super();
    CONTEXTUAL_instance = this;
  }
}
class PolymorphicKind extends SerialKind {}
class SEALED extends PolymorphicKind {
  constructor() {
    SEALED_instance = null;
    super();
    SEALED_instance = this;
  }
}
class OPEN extends PolymorphicKind {
  constructor() {
    OPEN_instance = null;
    super();
    OPEN_instance = this;
  }
}
class PrimitiveKind extends SerialKind {}
class BOOLEAN extends PrimitiveKind {
  constructor() {
    BOOLEAN_instance = null;
    super();
    BOOLEAN_instance = this;
  }
}
class BYTE extends PrimitiveKind {
  constructor() {
    BYTE_instance = null;
    super();
    BYTE_instance = this;
  }
}
class CHAR extends PrimitiveKind {
  constructor() {
    CHAR_instance = null;
    super();
    CHAR_instance = this;
  }
}
class SHORT extends PrimitiveKind {
  constructor() {
    SHORT_instance = null;
    super();
    SHORT_instance = this;
  }
}
class INT extends PrimitiveKind {
  constructor() {
    INT_instance = null;
    super();
    INT_instance = this;
  }
}
class LONG extends PrimitiveKind {
  constructor() {
    LONG_instance = null;
    super();
    LONG_instance = this;
  }
}
class FLOAT extends PrimitiveKind {
  constructor() {
    FLOAT_instance = null;
    super();
    FLOAT_instance = this;
  }
}
class DOUBLE extends PrimitiveKind {
  constructor() {
    DOUBLE_instance = null;
    super();
    DOUBLE_instance = this;
  }
}
class STRING extends PrimitiveKind {
  constructor() {
    STRING_instance = null;
    super();
    STRING_instance = this;
  }
}
class StructureKind extends SerialKind {}
class CLASS extends StructureKind {
  constructor() {
    CLASS_instance = null;
    super();
    CLASS_instance = this;
  }
}
class LIST extends StructureKind {
  constructor() {
    LIST_instance = null;
    super();
    LIST_instance = this;
  }
}
class MAP extends StructureKind {
  constructor() {
    MAP_instance = null;
    super();
    MAP_instance = this;
  }
}
class OBJECT extends StructureKind {
  constructor() {
    OBJECT_instance = null;
    super();
    OBJECT_instance = this;
  }
}
class Decoder {}
function decodeSerializableValue(deserializer) {
  return deserializer.vjf(this);
}
class CompositeDecoder {}
function decodeSequentially() {
  return false;
}
function decodeCollectionSize(descriptor) {
  return -1;
}
function decodeSerializableElement$default(descriptor, index, deserializer, previousValue, $super) {
  previousValue = previousValue === VOID ? null : previousValue;
  return $super === VOID ? this.bjk(descriptor, index, deserializer, previousValue) : $super.bjk.call(this, descriptor, index, deserializer, previousValue);
}
class AbstractDecoder {
  zji() {
    throw SerializationException.sjg(toString(getKClassFromExpression(this)) + " can't retrieve untyped values");
  }
  ajj() {
    return true;
  }
  bjj() {
    return null;
  }
  cjj() {
    var tmp = this.zji();
    return typeof tmp === 'boolean' ? tmp : THROW_CCE();
  }
  djj() {
    var tmp = this.zji();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  ejj() {
    var tmp = this.zji();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  fjj() {
    var tmp = this.zji();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  gjj() {
    var tmp = this.zji();
    return tmp instanceof Long ? tmp : THROW_CCE();
  }
  hjj() {
    var tmp = this.zji();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  ijj() {
    var tmp = this.zji();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  jjj() {
    var tmp = this.zji();
    return tmp instanceof Char ? tmp.t2_1 : THROW_CCE();
  }
  kjj() {
    var tmp = this.zji();
    return typeof tmp === 'string' ? tmp : THROW_CCE();
  }
  ljj(enumDescriptor) {
    var tmp = this.zji();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  mjj(descriptor) {
    return this;
  }
  njj(deserializer, previousValue) {
    return this.ojj(deserializer);
  }
  pjj(descriptor) {
    return this;
  }
  qjj(descriptor) {
  }
  rjj(descriptor, index) {
    return this.cjj();
  }
  sjj(descriptor, index) {
    return this.djj();
  }
  tjj(descriptor, index) {
    return this.ejj();
  }
  ujj(descriptor, index) {
    return this.fjj();
  }
  vjj(descriptor, index) {
    return this.gjj();
  }
  wjj(descriptor, index) {
    return this.hjj();
  }
  xjj(descriptor, index) {
    return this.ijj();
  }
  yjj(descriptor, index) {
    return this.jjj();
  }
  zjj(descriptor, index) {
    return this.kjj();
  }
  ajk(descriptor, index) {
    return this.mjj(descriptor.wjh(index));
  }
  bjk(descriptor, index, deserializer, previousValue) {
    return this.njj(deserializer, previousValue);
  }
  djk(descriptor, index, deserializer, previousValue) {
    // Inline function 'kotlinx.serialization.encoding.decodeIfNullable' call
    var isNullabilitySupported = deserializer.ujf().ojh();
    var tmp;
    if (isNullabilitySupported || this.ajj()) {
      // Inline function 'kotlinx.serialization.encoding.AbstractDecoder.decodeNullableSerializableElement.<anonymous>' call
      tmp = this.njj(deserializer, previousValue);
    } else {
      tmp = this.bjj();
    }
    return tmp;
  }
}
class NothingSerializer {
  constructor() {
    NothingSerializer_instance = this;
    this.jjk_1 = NothingSerialDescriptor_getInstance();
  }
  ujf() {
    return this.jjk_1;
  }
  vjf(decoder) {
    throw SerializationException.sjg("'kotlin.Nothing' does not have instances");
  }
}
class DurationSerializer {
  constructor() {
    DurationSerializer_instance = this;
    this.kjk_1 = new PrimitiveSerialDescriptor('kotlin.time.Duration', STRING_getInstance());
  }
  ujf() {
    return this.kjk_1;
  }
  ljk(decoder) {
    return Companion_getInstance().nr(decoder.kjj());
  }
  vjf(decoder) {
    return new Duration(this.ljk(decoder));
  }
}
class ListLikeDescriptor {
  constructor(elementDescriptor) {
    this.ojk_1 = elementDescriptor;
    this.pjk_1 = 1;
  }
  yjh() {
    return LIST_getInstance();
  }
  aji() {
    return this.pjk_1;
  }
  tjh(index) {
    return index.toString();
  }
  ujh(name) {
    var tmp0_elvis_lhs = toIntOrNull(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException.l(name + ' is not a valid list index');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  xjh(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.isElementOptional.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.sjh() + ' expects only non-negative indices';
      throw IllegalArgumentException.l(toString(message));
    }
    return false;
  }
  vjh(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.getElementAnnotations.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.sjh() + ' expects only non-negative indices';
      throw IllegalArgumentException.l(toString(message));
    }
    return emptyList();
  }
  wjh(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.getElementDescriptor.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.sjh() + ' expects only non-negative indices';
      throw IllegalArgumentException.l(toString(message));
    }
    return this.ojk_1;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof ListLikeDescriptor))
      return false;
    if (equals(this.ojk_1, other.ojk_1) && this.sjh() === other.sjh())
      return true;
    return false;
  }
  hashCode() {
    return imul(hashCode(this.ojk_1), 31) + getStringHashCode(this.sjh()) | 0;
  }
  toString() {
    return this.sjh() + '(' + toString(this.ojk_1) + ')';
  }
}
class ArrayListClassDesc extends ListLikeDescriptor {
  sjh() {
    return 'kotlin.collections.ArrayList';
  }
}
class HashSetClassDesc extends ListLikeDescriptor {
  sjh() {
    return 'kotlin.collections.HashSet';
  }
}
class LinkedHashSetClassDesc extends ListLikeDescriptor {
  sjh() {
    return 'kotlin.collections.LinkedHashSet';
  }
}
class MapLikeDescriptor {
  constructor(serialName, keyDescriptor, valueDescriptor) {
    this.ujk_1 = serialName;
    this.vjk_1 = keyDescriptor;
    this.wjk_1 = valueDescriptor;
    this.xjk_1 = 2;
  }
  sjh() {
    return this.ujk_1;
  }
  yjh() {
    return MAP_getInstance();
  }
  aji() {
    return this.xjk_1;
  }
  tjh(index) {
    return index.toString();
  }
  ujh(name) {
    var tmp0_elvis_lhs = toIntOrNull(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException.l(name + ' is not a valid map index');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  xjh(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.isElementOptional.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.sjh() + ' expects only non-negative indices';
      throw IllegalArgumentException.l(toString(message));
    }
    return false;
  }
  vjh(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.getElementAnnotations.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.sjh() + ' expects only non-negative indices';
      throw IllegalArgumentException.l(toString(message));
    }
    return emptyList();
  }
  wjh(index) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.getElementDescriptor.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.sjh() + ' expects only non-negative indices';
      throw IllegalArgumentException.l(toString(message));
    }
    var tmp;
    switch (index % 2 | 0) {
      case 0:
        tmp = this.vjk_1;
        break;
      case 1:
        tmp = this.wjk_1;
        break;
      default:
        var message_0 = 'Unreached';
        throw IllegalStateException.m5(toString(message_0));
    }
    return tmp;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof MapLikeDescriptor))
      return false;
    if (!(this.sjh() === other.sjh()))
      return false;
    if (!equals(this.vjk_1, other.vjk_1))
      return false;
    if (!equals(this.wjk_1, other.wjk_1))
      return false;
    return true;
  }
  hashCode() {
    var result = getStringHashCode(this.sjh());
    result = imul(31, result) + hashCode(this.vjk_1) | 0;
    result = imul(31, result) + hashCode(this.wjk_1) | 0;
    return result;
  }
  toString() {
    return this.sjh() + '(' + toString(this.vjk_1) + ', ' + toString(this.wjk_1) + ')';
  }
}
class HashMapClassDesc extends MapLikeDescriptor {
  constructor(keyDesc, valueDesc) {
    super('kotlin.collections.HashMap', keyDesc, valueDesc);
  }
}
class LinkedHashMapClassDesc extends MapLikeDescriptor {
  constructor(keyDesc, valueDesc) {
    super('kotlin.collections.LinkedHashMap', keyDesc, valueDesc);
  }
}
class ArrayClassDesc extends ListLikeDescriptor {
  sjh() {
    return 'kotlin.Array';
  }
}
class PrimitiveArrayDescriptor extends ListLikeDescriptor {
  constructor(primitive) {
    super(primitive);
    this.cjl_1 = primitive.sjh() + 'Array';
  }
  sjh() {
    return this.cjl_1;
  }
}
class AbstractCollectionSerializer {
  ujl(decoder, previous) {
    var tmp0_elvis_lhs = previous == null ? null : this.ljl(previous);
    var builder = tmp0_elvis_lhs == null ? this.fjl() : tmp0_elvis_lhs;
    var startIndex = this.hjl(builder);
    var compositeDecoder = decoder.pjj(this.ujf());
    if (compositeDecoder.fjk()) {
      this.rjl(compositeDecoder, builder, startIndex, readSize(this, compositeDecoder, builder));
    } else {
      $l$loop: while (true) {
        var index = compositeDecoder.gjk(this.ujf());
        if (index === -1)
          break $l$loop;
        this.tjl(compositeDecoder, startIndex + index | 0, builder);
      }
    }
    compositeDecoder.qjj(this.ujf());
    return this.jjl(builder);
  }
  vjf(decoder) {
    return this.ujl(decoder, null);
  }
  tjl(decoder, index, builder, checkIndex, $super) {
    checkIndex = checkIndex === VOID ? true : checkIndex;
    var tmp;
    if ($super === VOID) {
      this.sjl(decoder, index, builder, checkIndex);
      tmp = Unit_instance;
    } else {
      tmp = $super.sjl.call(this, decoder, index, builder, checkIndex);
    }
    return tmp;
  }
}
class CollectionLikeSerializer extends AbstractCollectionSerializer {
  constructor(elementSerializer) {
    super();
    this.qjl_1 = elementSerializer;
  }
  rjl(decoder, builder, startIndex, size) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(size >= 0)) {
      // Inline function 'kotlinx.serialization.internal.CollectionLikeSerializer.readAll.<anonymous>' call
      var message = 'Size must be known in advance when using READ_ALL';
      throw IllegalArgumentException.l(toString(message));
    }
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.sjl(decoder, startIndex + index | 0, builder, false);
      }
       while (inductionVariable < size);
  }
  sjl(decoder, index, builder, checkIndex) {
    this.pjl(builder, index, decoder.cjk(this.ujf(), index, this.qjl_1));
  }
}
class CollectionSerializer extends CollectionLikeSerializer {}
class ArrayListSerializer extends CollectionSerializer {
  constructor(element) {
    super(element);
    this.ejl_1 = new ArrayListClassDesc(element.ujf());
  }
  ujf() {
    return this.ejl_1;
  }
  fjl() {
    // Inline function 'kotlin.collections.arrayListOf' call
    return ArrayList.m1();
  }
  gjl(_this__u8e3s4) {
    return _this__u8e3s4.j1();
  }
  hjl(_this__u8e3s4) {
    return this.gjl(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  }
  ijl(_this__u8e3s4) {
    return _this__u8e3s4;
  }
  jjl(_this__u8e3s4) {
    return this.ijl(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  }
  kjl(_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? ArrayList.v(_this__u8e3s4) : tmp0_elvis_lhs;
  }
  ljl(_this__u8e3s4) {
    return this.kjl((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtList) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  mjl(_this__u8e3s4, size) {
    return _this__u8e3s4.p8(size);
  }
  njl(_this__u8e3s4, size) {
    return this.mjl(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE(), size);
  }
  ojl(_this__u8e3s4, index, element) {
    _this__u8e3s4.r7(index, element);
  }
  pjl(_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE();
    return this.ojl(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
}
class HashSetSerializer extends CollectionSerializer {
  constructor(eSerializer) {
    super(eSerializer);
    this.wjl_1 = new HashSetClassDesc(eSerializer.ujf());
  }
  ujf() {
    return this.wjl_1;
  }
  fjl() {
    return HashSet.xa();
  }
  xjl(_this__u8e3s4) {
    return _this__u8e3s4.j1();
  }
  hjl(_this__u8e3s4) {
    return this.xjl(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE());
  }
  yjl(_this__u8e3s4) {
    return _this__u8e3s4;
  }
  jjl(_this__u8e3s4) {
    return this.yjl(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE());
  }
  zjl(_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? HashSet.ya(_this__u8e3s4) : tmp0_elvis_lhs;
  }
  ljl(_this__u8e3s4) {
    return this.zjl((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtSet) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  ajm(_this__u8e3s4, size) {
  }
  njl(_this__u8e3s4, size) {
    return this.ajm(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE(), size);
  }
  bjm(_this__u8e3s4, index, element) {
    _this__u8e3s4.f(element);
  }
  pjl(_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE();
    return this.bjm(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
}
class LinkedHashSetSerializer extends CollectionSerializer {
  constructor(eSerializer) {
    super(eSerializer);
    this.djm_1 = new LinkedHashSetClassDesc(eSerializer.ujf());
  }
  ujf() {
    return this.djm_1;
  }
  fjl() {
    // Inline function 'kotlin.collections.linkedSetOf' call
    return LinkedHashSet.l1();
  }
  ejm(_this__u8e3s4) {
    return _this__u8e3s4.j1();
  }
  hjl(_this__u8e3s4) {
    return this.ejm(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE());
  }
  fjm(_this__u8e3s4) {
    return _this__u8e3s4;
  }
  jjl(_this__u8e3s4) {
    return this.fjm(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE());
  }
  zjl(_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? LinkedHashSet.o1(_this__u8e3s4) : tmp0_elvis_lhs;
  }
  ljl(_this__u8e3s4) {
    return this.zjl((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtSet) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  gjm(_this__u8e3s4, size) {
  }
  njl(_this__u8e3s4, size) {
    return this.gjm(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE(), size);
  }
  hjm(_this__u8e3s4, index, element) {
    _this__u8e3s4.f(element);
  }
  pjl(_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE();
    return this.hjm(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
}
class MapLikeSerializer extends AbstractCollectionSerializer {
  constructor(keySerializer, valueSerializer) {
    super();
    this.pjm_1 = keySerializer;
    this.qjm_1 = valueSerializer;
  }
  rjm(decoder, builder, startIndex, size) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(size >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readAll.<anonymous>' call
      var message = 'Size must be known in advance when using READ_ALL';
      throw IllegalArgumentException.l(toString(message));
    }
    var progression = step(until(0, imul(size, 2)), 2);
    var inductionVariable = progression.k2_1;
    var last = progression.l2_1;
    var step_0 = progression.m2_1;
    if (step_0 > 0 && inductionVariable <= last || (step_0 < 0 && last <= inductionVariable))
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        this.sjm(decoder, startIndex + index | 0, builder, false);
      }
       while (!(index === last));
  }
  rjl(decoder, builder, startIndex, size) {
    return this.rjm(decoder, (!(builder == null) ? isInterface(builder, KtMutableMap) : false) ? builder : THROW_CCE(), startIndex, size);
  }
  sjm(decoder, index, builder, checkIndex) {
    var key = decoder.cjk(this.ujf(), index, this.pjm_1);
    var tmp;
    if (checkIndex) {
      // Inline function 'kotlin.also' call
      var this_0 = decoder.gjk(this.ujf());
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readElement.<anonymous>' call
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(this_0 === (index + 1 | 0))) {
        // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readElement.<anonymous>.<anonymous>' call
        var message = 'Value must follow key in a map, index for key: ' + index + ', returned index for value: ' + this_0;
        throw IllegalArgumentException.l(toString(message));
      }
      tmp = this_0;
    } else {
      tmp = index + 1 | 0;
    }
    var vIndex = tmp;
    var tmp_0;
    var tmp_1;
    if (builder.i3(key)) {
      var tmp_2 = this.qjm_1.ujf().yjh();
      tmp_1 = !(tmp_2 instanceof PrimitiveKind);
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = decoder.bjk(this.ujf(), vIndex, this.qjm_1, getValue(builder, key));
    } else {
      tmp_0 = decoder.cjk(this.ujf(), vIndex, this.qjm_1);
    }
    var value = tmp_0;
    // Inline function 'kotlin.collections.set' call
    builder.r3(key, value);
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.sjm(decoder, index, (!(builder == null) ? isInterface(builder, KtMutableMap) : false) ? builder : THROW_CCE(), checkIndex);
  }
}
class HashMapSerializer extends MapLikeSerializer {
  constructor(kSerializer, vSerializer) {
    super(kSerializer, vSerializer);
    this.kjm_1 = new HashMapClassDesc(kSerializer.ujf(), vSerializer.ujf());
  }
  ujf() {
    return this.kjm_1;
  }
  fjl() {
    return HashMap.c9();
  }
  ljm(_this__u8e3s4) {
    return imul(_this__u8e3s4.j1(), 2);
  }
  hjl(_this__u8e3s4) {
    return this.ljm(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE());
  }
  mjm(_this__u8e3s4) {
    return _this__u8e3s4;
  }
  jjl(_this__u8e3s4) {
    return this.mjm(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE());
  }
  njm(_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? HashMap.r9(_this__u8e3s4) : tmp0_elvis_lhs;
  }
  ljl(_this__u8e3s4) {
    return this.njm((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  ojm(_this__u8e3s4, size) {
  }
  njl(_this__u8e3s4, size) {
    return this.ojm(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE(), size);
  }
}
class LinkedHashMapSerializer extends MapLikeSerializer {
  constructor(kSerializer, vSerializer) {
    super(kSerializer, vSerializer);
    this.vjm_1 = new LinkedHashMapClassDesc(kSerializer.ujf(), vSerializer.ujf());
  }
  ujf() {
    return this.vjm_1;
  }
  fjl() {
    return LinkedHashMap.vc();
  }
  wjm(_this__u8e3s4) {
    return imul(_this__u8e3s4.j1(), 2);
  }
  hjl(_this__u8e3s4) {
    return this.wjm(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE());
  }
  xjm(_this__u8e3s4) {
    return _this__u8e3s4;
  }
  jjl(_this__u8e3s4) {
    return this.xjm(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE());
  }
  njm(_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? LinkedHashMap.xc(_this__u8e3s4) : tmp0_elvis_lhs;
  }
  ljl(_this__u8e3s4) {
    return this.njm((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  yjm(_this__u8e3s4, size) {
  }
  njl(_this__u8e3s4, size) {
    return this.yjm(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE(), size);
  }
}
class ReferenceArraySerializer extends CollectionLikeSerializer {
  constructor(kClass, eSerializer) {
    super(eSerializer);
    this.ajn_1 = kClass;
    this.bjn_1 = new ArrayClassDesc(eSerializer.ujf());
  }
  ujf() {
    return this.bjn_1;
  }
  fjl() {
    // Inline function 'kotlin.collections.arrayListOf' call
    return ArrayList.m1();
  }
  cjn(_this__u8e3s4) {
    return _this__u8e3s4.j1();
  }
  hjl(_this__u8e3s4) {
    return this.cjn(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  }
  djn(_this__u8e3s4) {
    return toNativeArrayImpl(_this__u8e3s4, this.ajn_1);
  }
  jjl(_this__u8e3s4) {
    return this.djn(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  }
  ejn(_this__u8e3s4) {
    return ArrayList.v(asList(_this__u8e3s4));
  }
  ljl(_this__u8e3s4) {
    return this.ejn((!(_this__u8e3s4 == null) ? isArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  fjn(_this__u8e3s4, size) {
    return _this__u8e3s4.p8(size);
  }
  njl(_this__u8e3s4, size) {
    return this.fjn(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE(), size);
  }
  gjn(_this__u8e3s4, index, element) {
    _this__u8e3s4.r7(index, element);
  }
  pjl(_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE();
    return this.gjn(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
}
class PrimitiveArraySerializer extends CollectionLikeSerializer {
  constructor(primitiveSerializer) {
    super(primitiveSerializer);
    this.ijn_1 = new PrimitiveArrayDescriptor(primitiveSerializer.ujf());
  }
  ujf() {
    return this.ijn_1;
  }
  jjn(_this__u8e3s4) {
    return _this__u8e3s4.kjn();
  }
  hjl(_this__u8e3s4) {
    return this.jjn(_this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE());
  }
  ljn(_this__u8e3s4) {
    return _this__u8e3s4.mjn();
  }
  jjl(_this__u8e3s4) {
    return this.ljn(_this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE());
  }
  njn(_this__u8e3s4, size) {
    return _this__u8e3s4.ojn(size);
  }
  njl(_this__u8e3s4, size) {
    return this.njn(_this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE(), size);
  }
  pjn(_this__u8e3s4, index, element) {
    var message = 'This method lead to boxing and must not be used, use Builder.append instead';
    throw IllegalStateException.m5(toString(message));
  }
  pjl(_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE();
    return this.pjn(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  fjl() {
    return this.ljl(this.qjn());
  }
  vjf(decoder) {
    return this.ujl(decoder, null);
  }
}
class PrimitiveArrayBuilder {
  sjn(requiredCapacity, $super) {
    requiredCapacity = requiredCapacity === VOID ? this.kjn() + 1 | 0 : requiredCapacity;
    var tmp;
    if ($super === VOID) {
      this.ojn(requiredCapacity);
      tmp = Unit_instance;
    } else {
      tmp = $super.ojn.call(this, requiredCapacity);
    }
    return tmp;
  }
}
class Companion {
  constructor() {
    Companion_instance_0 = this;
    this.tjn_1 = longArray(0);
  }
}
class ElementMarker {
  constructor(descriptor, readIfAbsent) {
    Companion_getInstance_6();
    this.ujn_1 = descriptor;
    this.vjn_1 = readIfAbsent;
    var elementsCount = this.ujn_1.aji();
    if (elementsCount <= 64) {
      var tmp = this;
      var tmp_0;
      if (elementsCount === 64) {
        tmp_0 = new Long(0, 0);
      } else {
        tmp_0 = (new Long(-1, -1)).k4(elementsCount);
      }
      tmp.wjn_1 = tmp_0;
      this.xjn_1 = Companion_getInstance_6().tjn_1;
    } else {
      this.wjn_1 = new Long(0, 0);
      this.xjn_1 = prepareHighMarksArray(this, elementsCount);
    }
  }
  yjn(index) {
    if (index < 64) {
      this.wjn_1 = this.wjn_1.o4((new Long(1, 0)).k4(index));
    } else {
      markHigh(this, index);
    }
  }
  zjn() {
    var elementsCount = this.ujn_1.aji();
    while (!this.wjn_1.equals(new Long(-1, -1))) {
      var index = countTrailingZeroBits(this.wjn_1.j4());
      this.wjn_1 = this.wjn_1.o4((new Long(1, 0)).k4(index));
      if (this.vjn_1(this.ujn_1, index)) {
        return index;
      }
    }
    if (elementsCount > 64) {
      return nextUnmarkedHighIndex(this);
    }
    return -1;
  }
}
class EnumSerializer {
  constructor(serialName, values) {
    this.ajo_1 = values;
    this.bjo_1 = null;
    var tmp = this;
    tmp.cjo_1 = lazy_0(EnumSerializer$descriptor$delegate$lambda(this, serialName));
  }
  ujf() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.cjo_1;
    descriptor$factory_0();
    return this_0.b2();
  }
  vjf(decoder) {
    var index = decoder.ljj(this.ujf());
    if (!(0 <= index ? index <= (this.ajo_1.length - 1 | 0) : false)) {
      throw SerializationException.sjg('' + index + ' is not among valid ' + this.ujf().sjh() + ' enum values, ' + ('values size is ' + this.ajo_1.length));
    }
    return this.ajo_1[index];
  }
  toString() {
    return 'kotlinx.serialization.internal.EnumSerializer<' + this.ujf().sjh() + '>';
  }
}
class PluginGeneratedSerialDescriptor {
  constructor(serialName, generatedSerializer, elementsCount) {
    generatedSerializer = generatedSerializer === VOID ? null : generatedSerializer;
    this.djo_1 = serialName;
    this.ejo_1 = generatedSerializer;
    this.fjo_1 = elementsCount;
    this.gjo_1 = -1;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = this.fjo_1;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = '[UNINITIALIZED]';
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.hjo_1 = tmp_2;
    var tmp_3 = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var size = this.fjo_1;
    tmp_3.ijo_1 = fillArrayVal(Array(size), null);
    this.jjo_1 = null;
    this.kjo_1 = booleanArray(this.fjo_1);
    this.ljo_1 = emptyMap();
    var tmp_4 = this;
    var tmp_5 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_4.mjo_1 = lazy(tmp_5, PluginGeneratedSerialDescriptor$childSerializers$delegate$lambda(this));
    var tmp_6 = this;
    var tmp_7 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_6.njo_1 = lazy(tmp_7, PluginGeneratedSerialDescriptor$typeParameterDescriptors$delegate$lambda(this));
    var tmp_8 = this;
    var tmp_9 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_8.ojo_1 = lazy(tmp_9, PluginGeneratedSerialDescriptor$_hashCode$delegate$lambda(this));
  }
  sjh() {
    return this.djo_1;
  }
  aji() {
    return this.fjo_1;
  }
  yjh() {
    return CLASS_getInstance();
  }
  bji() {
    var tmp0_elvis_lhs = this.jjo_1;
    return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
  }
  yji() {
    return this.ljo_1.l3();
  }
  ejp() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.njo_1;
    typeParameterDescriptors$factory();
    return this_0.b2();
  }
  fjp(name, isOptional) {
    this.gjo_1 = this.gjo_1 + 1 | 0;
    this.hjo_1[this.gjo_1] = name;
    this.kjo_1[this.gjo_1] = isOptional;
    this.ijo_1[this.gjo_1] = null;
    if (this.gjo_1 === (this.fjo_1 - 1 | 0)) {
      this.ljo_1 = buildIndices(this);
    }
  }
  pjo(name, isOptional, $super) {
    isOptional = isOptional === VOID ? false : isOptional;
    var tmp;
    if ($super === VOID) {
      this.fjp(name, isOptional);
      tmp = Unit_instance;
    } else {
      tmp = $super.fjp.call(this, name, isOptional);
    }
    return tmp;
  }
  wjh(index) {
    return getChecked(_get_childSerializers__7vnyfa(this), index).ujf();
  }
  xjh(index) {
    return getChecked_0(this.kjo_1, index);
  }
  vjh(index) {
    var tmp0_elvis_lhs = getChecked(this.ijo_1, index);
    return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
  }
  tjh(index) {
    return getChecked(this.hjo_1, index);
  }
  ujh(name) {
    var tmp0_elvis_lhs = this.ljo_1.k3(name);
    return tmp0_elvis_lhs == null ? -3 : tmp0_elvis_lhs;
  }
  equals(other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof PluginGeneratedSerialDescriptor)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.sjh() === other.sjh())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      // Inline function 'kotlinx.serialization.internal.PluginGeneratedSerialDescriptor.equals.<anonymous>' call
      if (!contentEquals(this.ejp(), other.ejp())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.aji() === other.aji())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.aji();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.wjh(index).sjh() === other.wjh(index).sjh())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.wjh(index).yjh(), other.wjh(index).yjh())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
  hashCode() {
    return _get__hashCode__tgwhef_0(this);
  }
  toString() {
    var tmp = until(0, this.fjo_1);
    var tmp_0 = this.sjh() + '(';
    return joinToString(tmp, ', ', tmp_0, ')', VOID, VOID, PluginGeneratedSerialDescriptor$toString$lambda(this));
  }
}
class EnumDescriptor extends PluginGeneratedSerialDescriptor {
  constructor(name, elementsCount) {
    super(name, VOID, elementsCount);
    this.cjp_1 = ENUM_getInstance();
    var tmp = this;
    tmp.djp_1 = lazy_0(EnumDescriptor$elementDescriptors$delegate$lambda(elementsCount, name, this));
  }
  yjh() {
    return this.cjp_1;
  }
  wjh(index) {
    return getChecked(_get_elementDescriptors__y23q9p(this), index);
  }
  equals(other) {
    if (this === other)
      return true;
    if (other == null)
      return false;
    if (!(!(other == null) ? isInterface(other, SerialDescriptor) : false))
      return false;
    if (!(other.yjh() === ENUM_getInstance()))
      return false;
    if (!(this.sjh() === other.sjh()))
      return false;
    if (!equals(cachedSerialNames(this), cachedSerialNames(other)))
      return false;
    return true;
  }
  toString() {
    return joinToString(get_elementNames(this), ', ', this.sjh() + '(', ')');
  }
  hashCode() {
    var result = getStringHashCode(this.sjh());
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
    // Inline function 'kotlin.collections.fold' call
    var accumulator = 1;
    var tmp0_iterator = get_elementNames(this).f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
      var hash = accumulator;
      var tmp = imul(31, hash);
      // Inline function 'kotlin.hashCode' call
      // Inline function 'kotlinx.serialization.internal.EnumDescriptor.hashCode.<anonymous>' call
      var tmp1_elvis_lhs = element == null ? null : hashCode(element);
      accumulator = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
    }
    var elementsHashCode = accumulator;
    result = imul(31, result) + elementsHashCode | 0;
    return result;
  }
}
class InlineClassDescriptor extends PluginGeneratedSerialDescriptor {
  constructor(name, generatedSerializer) {
    super(name, generatedSerializer, 1);
    this.sjp_1 = true;
  }
  zjh() {
    return this.sjp_1;
  }
  hashCode() {
    return imul(super.hashCode(), 31);
  }
  equals(other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof InlineClassDescriptor)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.sjh() === other.sjh())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      // Inline function 'kotlinx.serialization.internal.InlineClassDescriptor.equals.<anonymous>' call
      if (!(other.sjp_1 && contentEquals(this.ejp(), other.ejp()))) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.aji() === other.aji())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.aji();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.wjh(index).sjh() === other.wjh(index).sjh())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.wjh(index).yjh(), other.wjh(index).yjh())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
}
class GeneratedSerializer {}
function typeParametersSerializers() {
  return get_EMPTY_SERIALIZER_ARRAY();
}
class InlinePrimitiveDescriptor$1 {
  constructor($primitiveSerializer) {
    this.tjp_1 = $primitiveSerializer;
  }
  ujp() {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [this.tjp_1];
  }
  ujf() {
    var message = 'unsupported';
    throw IllegalStateException.m5(toString(message));
  }
  vjf(decoder) {
    // Inline function 'kotlin.error' call
    var message = 'unsupported';
    throw IllegalStateException.m5(toString(message));
  }
}
class NothingSerialDescriptor {
  constructor() {
    NothingSerialDescriptor_instance = this;
    this.wjp_1 = OBJECT_getInstance();
    this.xjp_1 = 'kotlin.Nothing';
  }
  yjh() {
    return this.wjp_1;
  }
  sjh() {
    return this.xjp_1;
  }
  aji() {
    return 0;
  }
  tjh(index) {
    error(this);
  }
  ujh(name) {
    error(this);
  }
  xjh(index) {
    error(this);
  }
  wjh(index) {
    error(this);
  }
  vjh(index) {
    error(this);
  }
  toString() {
    return 'NothingSerialDescriptor';
  }
  equals(other) {
    return this === other;
  }
  hashCode() {
    return getStringHashCode(this.xjp_1) + imul(31, this.wjp_1.hashCode()) | 0;
  }
}
class NullableSerializer {
  constructor(serializer) {
    this.yjp_1 = serializer;
    this.zjp_1 = new SerialDescriptorForNullable(this.yjp_1.ujf());
  }
  ujf() {
    return this.zjp_1;
  }
  vjf(decoder) {
    return decoder.ajj() ? decoder.ojj(this.yjp_1) : decoder.bjj();
  }
  equals(other) {
    if (this === other)
      return true;
    if (other == null || !getKClassFromExpression(this).equals(getKClassFromExpression(other)))
      return false;
    if (!(other instanceof NullableSerializer))
      THROW_CCE();
    if (!equals(this.yjp_1, other.yjp_1))
      return false;
    return true;
  }
  hashCode() {
    return hashCode(this.yjp_1);
  }
}
class SerialDescriptorForNullable {
  constructor(original) {
    this.cji_1 = original;
    this.dji_1 = this.cji_1.sjh() + '?';
    this.eji_1 = cachedSerialNames(this.cji_1);
  }
  tjh(index) {
    return this.cji_1.tjh(index);
  }
  ujh(name) {
    return this.cji_1.ujh(name);
  }
  vjh(index) {
    return this.cji_1.vjh(index);
  }
  wjh(index) {
    return this.cji_1.wjh(index);
  }
  xjh(index) {
    return this.cji_1.xjh(index);
  }
  yjh() {
    return this.cji_1.yjh();
  }
  zjh() {
    return this.cji_1.zjh();
  }
  aji() {
    return this.cji_1.aji();
  }
  bji() {
    return this.cji_1.bji();
  }
  sjh() {
    return this.dji_1;
  }
  yji() {
    return this.eji_1;
  }
  ojh() {
    return true;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof SerialDescriptorForNullable))
      return false;
    if (!equals(this.cji_1, other.cji_1))
      return false;
    return true;
  }
  toString() {
    return toString(this.cji_1) + '?';
  }
  hashCode() {
    return imul(hashCode(this.cji_1), 31);
  }
}
class ObjectSerializer {
  constructor(serialName, objectInstance) {
    this.ajq_1 = objectInstance;
    this.bjq_1 = emptyList();
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.cjq_1 = lazy(tmp_0, ObjectSerializer$descriptor$delegate$lambda(serialName, this));
  }
  ujf() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.cjq_1;
    descriptor$factory_1();
    return this_0.b2();
  }
  vjf(decoder) {
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var descriptor = this.ujf();
    var composite = decoder.pjj(descriptor);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.internal.ObjectSerializer.deserialize.<anonymous>' call
      if (composite.fjk()) {
        tmp$ret$0 = Unit_instance;
        break $l$block_0;
      }
      var index = composite.gjk(this.ujf());
      if (index === -1) {
        tmp$ret$0 = Unit_instance;
        break $l$block_0;
      } else
        throw SerializationException.sjg('Unexpected index ' + index);
    }
    var result = tmp$ret$0;
    composite.qjj(descriptor);
    return this.ajq_1;
  }
}
class SerializerFactory {}
class CharArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    CharArraySerializer_instance = null;
    super(serializer_1(Companion_getInstance_0()));
    CharArraySerializer_instance = this;
  }
  ijq(_this__u8e3s4) {
    return new CharArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.ijq((!(_this__u8e3s4 == null) ? isCharArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return charArray(0);
  }
  jjq(decoder, index, builder, checkIndex) {
    builder.mjq(decoder.yjj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.jjq(decoder, index, builder instanceof CharArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.jjq(decoder, index, builder instanceof CharArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class DoubleArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    DoubleArraySerializer_instance = null;
    super(serializer_2(DoubleCompanionObject_instance));
    DoubleArraySerializer_instance = this;
  }
  pjq(_this__u8e3s4) {
    return new DoubleArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.pjq((!(_this__u8e3s4 == null) ? isDoubleArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return new Float64Array(0);
  }
  qjq(decoder, index, builder, checkIndex) {
    builder.tjq(decoder.xjj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.qjq(decoder, index, builder instanceof DoubleArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.qjq(decoder, index, builder instanceof DoubleArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class FloatArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    FloatArraySerializer_instance = null;
    super(serializer_3(FloatCompanionObject_instance));
    FloatArraySerializer_instance = this;
  }
  wjq(_this__u8e3s4) {
    return new FloatArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.wjq((!(_this__u8e3s4 == null) ? isFloatArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return new Float32Array(0);
  }
  xjq(decoder, index, builder, checkIndex) {
    builder.ajr(decoder.wjj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.xjq(decoder, index, builder instanceof FloatArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.xjq(decoder, index, builder instanceof FloatArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class LongArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    LongArraySerializer_instance = null;
    super(serializer_4(Companion_getInstance_1()));
    LongArraySerializer_instance = this;
  }
  djr(_this__u8e3s4) {
    return new LongArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.djr((!(_this__u8e3s4 == null) ? isLongArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return longArray(0);
  }
  ejr(decoder, index, builder, checkIndex) {
    builder.hjr(decoder.vjj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.ejr(decoder, index, builder instanceof LongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.ejr(decoder, index, builder instanceof LongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class ULongArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    ULongArraySerializer_instance = null;
    super(serializer_5(Companion_getInstance_2()));
    ULongArraySerializer_instance = this;
  }
  kjr(_this__u8e3s4) {
    return new ULongArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.kjr(_this__u8e3s4 instanceof ULongArray ? _this__u8e3s4.f5_1 : THROW_CCE());
  }
  ljr() {
    return _ULongArray___init__impl__twm1l3(0);
  }
  qjn() {
    return new ULongArray(this.ljr());
  }
  mjr(decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toULong' call
    var this_0 = decoder.ajk(this.ijn_1, index).gjj();
    var tmp$ret$0 = _ULong___init__impl__c78o9k(this_0);
    builder.pjr(tmp$ret$0);
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.mjr(decoder, index, builder instanceof ULongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.mjr(decoder, index, builder instanceof ULongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class IntArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    IntArraySerializer_instance = null;
    super(serializer_6(IntCompanionObject_instance));
    IntArraySerializer_instance = this;
  }
  sjr(_this__u8e3s4) {
    return new IntArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.sjr((!(_this__u8e3s4 == null) ? isIntArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return new Int32Array(0);
  }
  tjr(decoder, index, builder, checkIndex) {
    builder.wjr(decoder.ujj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.tjr(decoder, index, builder instanceof IntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.tjr(decoder, index, builder instanceof IntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class UIntArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    UIntArraySerializer_instance = null;
    super(serializer_7(Companion_getInstance_3()));
    UIntArraySerializer_instance = this;
  }
  zjr(_this__u8e3s4) {
    return new UIntArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.zjr(_this__u8e3s4 instanceof UIntArray ? _this__u8e3s4.g5_1 : THROW_CCE());
  }
  ajs() {
    return _UIntArray___init__impl__ghjpc6(0);
  }
  qjn() {
    return new UIntArray(this.ajs());
  }
  bjs(decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toUInt' call
    var this_0 = decoder.ajk(this.ijn_1, index).fjj();
    var tmp$ret$0 = _UInt___init__impl__l7qpdl(this_0);
    builder.ejs(tmp$ret$0);
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.bjs(decoder, index, builder instanceof UIntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.bjs(decoder, index, builder instanceof UIntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class ShortArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    ShortArraySerializer_instance = null;
    super(serializer_8(ShortCompanionObject_instance));
    ShortArraySerializer_instance = this;
  }
  hjs(_this__u8e3s4) {
    return new ShortArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.hjs((!(_this__u8e3s4 == null) ? isShortArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return new Int16Array(0);
  }
  ijs(decoder, index, builder, checkIndex) {
    builder.ljs(decoder.tjj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.ijs(decoder, index, builder instanceof ShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.ijs(decoder, index, builder instanceof ShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class UShortArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    UShortArraySerializer_instance = null;
    super(serializer_9(Companion_getInstance_4()));
    UShortArraySerializer_instance = this;
  }
  ojs(_this__u8e3s4) {
    return new UShortArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.ojs(_this__u8e3s4 instanceof UShortArray ? _this__u8e3s4.h5_1 : THROW_CCE());
  }
  pjs() {
    return _UShortArray___init__impl__9b26ef(0);
  }
  qjn() {
    return new UShortArray(this.pjs());
  }
  qjs(decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toUShort' call
    var this_0 = decoder.ajk(this.ijn_1, index).ejj();
    var tmp$ret$0 = _UShort___init__impl__jigrne(this_0);
    builder.tjs(tmp$ret$0);
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.qjs(decoder, index, builder instanceof UShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.qjs(decoder, index, builder instanceof UShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class ByteArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    ByteArraySerializer_instance = null;
    super(serializer_10(ByteCompanionObject_instance));
    ByteArraySerializer_instance = this;
  }
  wjs(_this__u8e3s4) {
    return new ByteArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.wjs((!(_this__u8e3s4 == null) ? isByteArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return new Int8Array(0);
  }
  xjs(decoder, index, builder, checkIndex) {
    builder.ajt(decoder.sjj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.xjs(decoder, index, builder instanceof ByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.xjs(decoder, index, builder instanceof ByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class UByteArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    UByteArraySerializer_instance = null;
    super(serializer_11(Companion_getInstance_5()));
    UByteArraySerializer_instance = this;
  }
  djt(_this__u8e3s4) {
    return new UByteArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.djt(_this__u8e3s4 instanceof UByteArray ? _this__u8e3s4.i5_1 : THROW_CCE());
  }
  ejt() {
    return _UByteArray___init__impl__ip4y9n(0);
  }
  qjn() {
    return new UByteArray(this.ejt());
  }
  fjt(decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toUByte' call
    var this_0 = decoder.ajk(this.ijn_1, index).djj();
    var tmp$ret$0 = _UByte___init__impl__g9hnc4(this_0);
    builder.ijt(tmp$ret$0);
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.fjt(decoder, index, builder instanceof UByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.fjt(decoder, index, builder instanceof UByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class BooleanArraySerializer extends PrimitiveArraySerializer {
  constructor() {
    BooleanArraySerializer_instance = null;
    super(serializer_12(BooleanCompanionObject_instance));
    BooleanArraySerializer_instance = this;
  }
  ljt(_this__u8e3s4) {
    return new BooleanArrayBuilder(_this__u8e3s4);
  }
  ljl(_this__u8e3s4) {
    return this.ljt((!(_this__u8e3s4 == null) ? isBooleanArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  }
  qjn() {
    return booleanArray(0);
  }
  mjt(decoder, index, builder, checkIndex) {
    builder.pjt(decoder.rjj(this.ijn_1, index));
  }
  sjl(decoder, index, builder, checkIndex) {
    return this.mjt(decoder, index, builder instanceof BooleanArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
  rjn(decoder, index, builder, checkIndex) {
    return this.mjt(decoder, index, builder instanceof BooleanArrayBuilder ? builder : THROW_CCE(), checkIndex);
  }
}
class CharArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.kjq_1 = bufferWithData;
    this.ljq_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.ljq_1;
  }
  ojn(requiredCapacity) {
    if (this.kjq_1.length < requiredCapacity)
      this.kjq_1 = copyOf(this.kjq_1, coerceAtLeast(requiredCapacity, imul(this.kjq_1.length, 2)));
  }
  mjq(c) {
    this.sjn();
    var tmp = this.kjq_1;
    var _unary__edvuaz = this.ljq_1;
    this.ljq_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf(this.kjq_1, this.ljq_1);
  }
}
class DoubleArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.rjq_1 = bufferWithData;
    this.sjq_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.sjq_1;
  }
  ojn(requiredCapacity) {
    if (this.rjq_1.length < requiredCapacity)
      this.rjq_1 = copyOf_0(this.rjq_1, coerceAtLeast(requiredCapacity, imul(this.rjq_1.length, 2)));
  }
  tjq(c) {
    this.sjn();
    var tmp = this.rjq_1;
    var _unary__edvuaz = this.sjq_1;
    this.sjq_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf_0(this.rjq_1, this.sjq_1);
  }
}
class FloatArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.yjq_1 = bufferWithData;
    this.zjq_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.zjq_1;
  }
  ojn(requiredCapacity) {
    if (this.yjq_1.length < requiredCapacity)
      this.yjq_1 = copyOf_1(this.yjq_1, coerceAtLeast(requiredCapacity, imul(this.yjq_1.length, 2)));
  }
  ajr(c) {
    this.sjn();
    var tmp = this.yjq_1;
    var _unary__edvuaz = this.zjq_1;
    this.zjq_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf_1(this.yjq_1, this.zjq_1);
  }
}
class LongArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.fjr_1 = bufferWithData;
    this.gjr_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.gjr_1;
  }
  ojn(requiredCapacity) {
    if (this.fjr_1.length < requiredCapacity)
      this.fjr_1 = copyOf_2(this.fjr_1, coerceAtLeast(requiredCapacity, imul(this.fjr_1.length, 2)));
  }
  hjr(c) {
    this.sjn();
    var tmp = this.fjr_1;
    var _unary__edvuaz = this.gjr_1;
    this.gjr_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf_2(this.fjr_1, this.gjr_1);
  }
}
class ULongArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.njr_1 = bufferWithData;
    this.ojr_1 = _ULongArray___get_size__impl__ju6dtr(bufferWithData);
    this.ojn(10);
  }
  kjn() {
    return this.ojr_1;
  }
  ojn(requiredCapacity) {
    if (_ULongArray___get_size__impl__ju6dtr(this.njr_1) < requiredCapacity) {
      var tmp = this;
      // Inline function 'kotlin.collections.copyOf' call
      var this_0 = this.njr_1;
      var newSize = coerceAtLeast(requiredCapacity, imul(_ULongArray___get_size__impl__ju6dtr(this.njr_1), 2));
      tmp.njr_1 = _ULongArray___init__impl__twm1l3_0(copyOf_2(_ULongArray___get_storage__impl__28e64j(this_0), newSize));
    }
  }
  pjr(c) {
    this.sjn();
    var tmp = this.njr_1;
    var _unary__edvuaz = this.ojr_1;
    this.ojr_1 = _unary__edvuaz + 1 | 0;
    ULongArray__set_impl_z19mvh(tmp, _unary__edvuaz, c);
  }
  qjt() {
    // Inline function 'kotlin.collections.copyOf' call
    var this_0 = this.njr_1;
    var newSize = this.ojr_1;
    return _ULongArray___init__impl__twm1l3_0(copyOf_2(_ULongArray___get_storage__impl__28e64j(this_0), newSize));
  }
  mjn() {
    return new ULongArray(this.qjt());
  }
}
class IntArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.ujr_1 = bufferWithData;
    this.vjr_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.vjr_1;
  }
  ojn(requiredCapacity) {
    if (this.ujr_1.length < requiredCapacity)
      this.ujr_1 = copyOf_3(this.ujr_1, coerceAtLeast(requiredCapacity, imul(this.ujr_1.length, 2)));
  }
  wjr(c) {
    this.sjn();
    var tmp = this.ujr_1;
    var _unary__edvuaz = this.vjr_1;
    this.vjr_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf_3(this.ujr_1, this.vjr_1);
  }
}
class UIntArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.cjs_1 = bufferWithData;
    this.djs_1 = _UIntArray___get_size__impl__r6l8ci(bufferWithData);
    this.ojn(10);
  }
  kjn() {
    return this.djs_1;
  }
  ojn(requiredCapacity) {
    if (_UIntArray___get_size__impl__r6l8ci(this.cjs_1) < requiredCapacity) {
      var tmp = this;
      // Inline function 'kotlin.collections.copyOf' call
      var this_0 = this.cjs_1;
      var newSize = coerceAtLeast(requiredCapacity, imul(_UIntArray___get_size__impl__r6l8ci(this.cjs_1), 2));
      tmp.cjs_1 = _UIntArray___init__impl__ghjpc6_0(copyOf_3(_UIntArray___get_storage__impl__92a0v0(this_0), newSize));
    }
  }
  ejs(c) {
    this.sjn();
    var tmp = this.cjs_1;
    var _unary__edvuaz = this.djs_1;
    this.djs_1 = _unary__edvuaz + 1 | 0;
    UIntArray__set_impl_7f2zu2(tmp, _unary__edvuaz, c);
  }
  rjt() {
    // Inline function 'kotlin.collections.copyOf' call
    var this_0 = this.cjs_1;
    var newSize = this.djs_1;
    return _UIntArray___init__impl__ghjpc6_0(copyOf_3(_UIntArray___get_storage__impl__92a0v0(this_0), newSize));
  }
  mjn() {
    return new UIntArray(this.rjt());
  }
}
class ShortArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.jjs_1 = bufferWithData;
    this.kjs_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.kjs_1;
  }
  ojn(requiredCapacity) {
    if (this.jjs_1.length < requiredCapacity)
      this.jjs_1 = copyOf_4(this.jjs_1, coerceAtLeast(requiredCapacity, imul(this.jjs_1.length, 2)));
  }
  ljs(c) {
    this.sjn();
    var tmp = this.jjs_1;
    var _unary__edvuaz = this.kjs_1;
    this.kjs_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf_4(this.jjs_1, this.kjs_1);
  }
}
class UShortArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.rjs_1 = bufferWithData;
    this.sjs_1 = _UShortArray___get_size__impl__jqto1b(bufferWithData);
    this.ojn(10);
  }
  kjn() {
    return this.sjs_1;
  }
  ojn(requiredCapacity) {
    if (_UShortArray___get_size__impl__jqto1b(this.rjs_1) < requiredCapacity) {
      var tmp = this;
      // Inline function 'kotlin.collections.copyOf' call
      var this_0 = this.rjs_1;
      var newSize = coerceAtLeast(requiredCapacity, imul(_UShortArray___get_size__impl__jqto1b(this.rjs_1), 2));
      tmp.rjs_1 = _UShortArray___init__impl__9b26ef_0(copyOf_4(_UShortArray___get_storage__impl__t2jpv5(this_0), newSize));
    }
  }
  tjs(c) {
    this.sjn();
    var tmp = this.rjs_1;
    var _unary__edvuaz = this.sjs_1;
    this.sjs_1 = _unary__edvuaz + 1 | 0;
    UShortArray__set_impl_6d8whp(tmp, _unary__edvuaz, c);
  }
  sjt() {
    // Inline function 'kotlin.collections.copyOf' call
    var this_0 = this.rjs_1;
    var newSize = this.sjs_1;
    return _UShortArray___init__impl__9b26ef_0(copyOf_4(_UShortArray___get_storage__impl__t2jpv5(this_0), newSize));
  }
  mjn() {
    return new UShortArray(this.sjt());
  }
}
class ByteArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.yjs_1 = bufferWithData;
    this.zjs_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.zjs_1;
  }
  ojn(requiredCapacity) {
    if (this.yjs_1.length < requiredCapacity)
      this.yjs_1 = copyOf_5(this.yjs_1, coerceAtLeast(requiredCapacity, imul(this.yjs_1.length, 2)));
  }
  ajt(c) {
    this.sjn();
    var tmp = this.yjs_1;
    var _unary__edvuaz = this.zjs_1;
    this.zjs_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf_5(this.yjs_1, this.zjs_1);
  }
}
class UByteArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.gjt_1 = bufferWithData;
    this.hjt_1 = _UByteArray___get_size__impl__h6pkdv(bufferWithData);
    this.ojn(10);
  }
  kjn() {
    return this.hjt_1;
  }
  ojn(requiredCapacity) {
    if (_UByteArray___get_size__impl__h6pkdv(this.gjt_1) < requiredCapacity) {
      var tmp = this;
      // Inline function 'kotlin.collections.copyOf' call
      var this_0 = this.gjt_1;
      var newSize = coerceAtLeast(requiredCapacity, imul(_UByteArray___get_size__impl__h6pkdv(this.gjt_1), 2));
      tmp.gjt_1 = _UByteArray___init__impl__ip4y9n_0(copyOf_5(_UByteArray___get_storage__impl__d4kctt(this_0), newSize));
    }
  }
  ijt(c) {
    this.sjn();
    var tmp = this.gjt_1;
    var _unary__edvuaz = this.hjt_1;
    this.hjt_1 = _unary__edvuaz + 1 | 0;
    UByteArray__set_impl_jvcicn(tmp, _unary__edvuaz, c);
  }
  tjt() {
    // Inline function 'kotlin.collections.copyOf' call
    var this_0 = this.gjt_1;
    var newSize = this.hjt_1;
    return _UByteArray___init__impl__ip4y9n_0(copyOf_5(_UByteArray___get_storage__impl__d4kctt(this_0), newSize));
  }
  mjn() {
    return new UByteArray(this.tjt());
  }
}
class BooleanArrayBuilder extends PrimitiveArrayBuilder {
  constructor(bufferWithData) {
    super();
    this.njt_1 = bufferWithData;
    this.ojt_1 = bufferWithData.length;
    this.ojn(10);
  }
  kjn() {
    return this.ojt_1;
  }
  ojn(requiredCapacity) {
    if (this.njt_1.length < requiredCapacity)
      this.njt_1 = copyOf_6(this.njt_1, coerceAtLeast(requiredCapacity, imul(this.njt_1.length, 2)));
  }
  pjt(c) {
    this.sjn();
    var tmp = this.njt_1;
    var _unary__edvuaz = this.ojt_1;
    this.ojt_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = c;
  }
  mjn() {
    return copyOf_6(this.njt_1, this.ojt_1);
  }
}
class StringSerializer {
  constructor() {
    StringSerializer_instance = this;
    this.ujt_1 = new PrimitiveSerialDescriptor('kotlin.String', STRING_getInstance());
  }
  ujf() {
    return this.ujt_1;
  }
  vjf(decoder) {
    return decoder.kjj();
  }
}
class CharSerializer {
  constructor() {
    CharSerializer_instance = this;
    this.vjt_1 = new PrimitiveSerialDescriptor('kotlin.Char', CHAR_getInstance());
  }
  ujf() {
    return this.vjt_1;
  }
  wjt(decoder) {
    return decoder.jjj();
  }
  vjf(decoder) {
    return new Char(this.wjt(decoder));
  }
}
class DoubleSerializer {
  constructor() {
    DoubleSerializer_instance = this;
    this.xjt_1 = new PrimitiveSerialDescriptor('kotlin.Double', DOUBLE_getInstance());
  }
  ujf() {
    return this.xjt_1;
  }
  vjf(decoder) {
    return decoder.ijj();
  }
}
class FloatSerializer {
  constructor() {
    FloatSerializer_instance = this;
    this.yjt_1 = new PrimitiveSerialDescriptor('kotlin.Float', FLOAT_getInstance());
  }
  ujf() {
    return this.yjt_1;
  }
  vjf(decoder) {
    return decoder.hjj();
  }
}
class LongSerializer {
  constructor() {
    LongSerializer_instance = this;
    this.zjt_1 = new PrimitiveSerialDescriptor('kotlin.Long', LONG_getInstance());
  }
  ujf() {
    return this.zjt_1;
  }
  vjf(decoder) {
    return decoder.gjj();
  }
}
class IntSerializer {
  constructor() {
    IntSerializer_instance = this;
    this.aju_1 = new PrimitiveSerialDescriptor('kotlin.Int', INT_getInstance());
  }
  ujf() {
    return this.aju_1;
  }
  vjf(decoder) {
    return decoder.fjj();
  }
}
class ShortSerializer {
  constructor() {
    ShortSerializer_instance = this;
    this.bju_1 = new PrimitiveSerialDescriptor('kotlin.Short', SHORT_getInstance());
  }
  ujf() {
    return this.bju_1;
  }
  vjf(decoder) {
    return decoder.ejj();
  }
}
class ByteSerializer {
  constructor() {
    ByteSerializer_instance = this;
    this.cju_1 = new PrimitiveSerialDescriptor('kotlin.Byte', BYTE_getInstance());
  }
  ujf() {
    return this.cju_1;
  }
  vjf(decoder) {
    return decoder.djj();
  }
}
class BooleanSerializer {
  constructor() {
    BooleanSerializer_instance = this;
    this.dju_1 = new PrimitiveSerialDescriptor('kotlin.Boolean', BOOLEAN_getInstance());
  }
  ujf() {
    return this.dju_1;
  }
  vjf(decoder) {
    return decoder.cjj();
  }
}
class UnitSerializer {
  constructor() {
    UnitSerializer_instance = this;
    this.eju_1 = new ObjectSerializer('kotlin.Unit', Unit_instance);
  }
  fju(decoder) {
    this.eju_1.vjf(decoder);
  }
  vjf(decoder) {
    this.fju(decoder);
    return Unit_instance;
  }
  ujf() {
    return this.eju_1.ujf();
  }
}
class PrimitiveSerialDescriptor {
  constructor(serialName, kind) {
    this.gju_1 = serialName;
    this.hju_1 = kind;
  }
  sjh() {
    return this.gju_1;
  }
  yjh() {
    return this.hju_1;
  }
  aji() {
    return 0;
  }
  tjh(index) {
    error_0(this);
  }
  ujh(name) {
    error_0(this);
  }
  xjh(index) {
    error_0(this);
  }
  wjh(index) {
    error_0(this);
  }
  vjh(index) {
    error_0(this);
  }
  toString() {
    return 'PrimitiveDescriptor(' + this.gju_1 + ')';
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof PrimitiveSerialDescriptor))
      return false;
    if (this.gju_1 === other.gju_1 && equals(this.hju_1, other.hju_1))
      return true;
    return false;
  }
  hashCode() {
    return getStringHashCode(this.gju_1) + imul(31, this.hju_1.hashCode()) | 0;
  }
}
class TaggedDecoder {
  constructor() {
    var tmp = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.nju_1 = ArrayList.m1();
    this.oju_1 = false;
  }
  ejk() {
    return EmptySerializersModule_0();
  }
  sju(tag) {
    throw SerializationException.sjg(toString(getKClassFromExpression(this)) + " can't retrieve untyped values");
  }
  tju(tag) {
    return true;
  }
  uju(tag) {
    var tmp = this.sju(tag);
    return typeof tmp === 'boolean' ? tmp : THROW_CCE();
  }
  vju(tag) {
    var tmp = this.sju(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  wju(tag) {
    var tmp = this.sju(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  xju(tag) {
    var tmp = this.sju(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  yju(tag) {
    var tmp = this.sju(tag);
    return tmp instanceof Long ? tmp : THROW_CCE();
  }
  zju(tag) {
    var tmp = this.sju(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  ajv(tag) {
    var tmp = this.sju(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  bjv(tag) {
    var tmp = this.sju(tag);
    return tmp instanceof Char ? tmp.t2_1 : THROW_CCE();
  }
  cjv(tag) {
    var tmp = this.sju(tag);
    return typeof tmp === 'string' ? tmp : THROW_CCE();
  }
  djv(tag, enumDescriptor) {
    var tmp = this.sju(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  ejv(tag, inlineDescriptor) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.serialization.internal.TaggedDecoder.decodeTaggedInline.<anonymous>' call
    this.fjv(tag);
    return this;
  }
  njj(deserializer, previousValue) {
    return this.ojj(deserializer);
  }
  mjj(descriptor) {
    return this.ejv(this.gjv(), descriptor);
  }
  ajj() {
    var tmp0_elvis_lhs = this.pju();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var currentTag = tmp;
    return this.tju(currentTag);
  }
  bjj() {
    return null;
  }
  cjj() {
    return this.uju(this.gjv());
  }
  djj() {
    return this.vju(this.gjv());
  }
  ejj() {
    return this.wju(this.gjv());
  }
  fjj() {
    return this.xju(this.gjv());
  }
  gjj() {
    return this.yju(this.gjv());
  }
  hjj() {
    return this.zju(this.gjv());
  }
  ijj() {
    return this.ajv(this.gjv());
  }
  jjj() {
    return this.bjv(this.gjv());
  }
  kjj() {
    return this.cjv(this.gjv());
  }
  ljj(enumDescriptor) {
    return this.djv(this.gjv(), enumDescriptor);
  }
  pjj(descriptor) {
    return this;
  }
  qjj(descriptor) {
  }
  rjj(descriptor, index) {
    return this.uju(this.kju(descriptor, index));
  }
  sjj(descriptor, index) {
    return this.vju(this.kju(descriptor, index));
  }
  tjj(descriptor, index) {
    return this.wju(this.kju(descriptor, index));
  }
  ujj(descriptor, index) {
    return this.xju(this.kju(descriptor, index));
  }
  vjj(descriptor, index) {
    return this.yju(this.kju(descriptor, index));
  }
  wjj(descriptor, index) {
    return this.zju(this.kju(descriptor, index));
  }
  xjj(descriptor, index) {
    return this.ajv(this.kju(descriptor, index));
  }
  yjj(descriptor, index) {
    return this.bjv(this.kju(descriptor, index));
  }
  zjj(descriptor, index) {
    return this.cjv(this.kju(descriptor, index));
  }
  ajk(descriptor, index) {
    return this.ejv(this.kju(descriptor, index), descriptor.wjh(index));
  }
  bjk(descriptor, index, deserializer, previousValue) {
    var tmp = this.kju(descriptor, index);
    return tagBlock(this, tmp, TaggedDecoder$decodeSerializableElement$lambda(this, deserializer, previousValue));
  }
  djk(descriptor, index, deserializer, previousValue) {
    var tmp = this.kju(descriptor, index);
    return tagBlock(this, tmp, TaggedDecoder$decodeNullableSerializableElement$lambda(this, deserializer, previousValue));
  }
  pju() {
    return lastOrNull(this.nju_1);
  }
  fjv(name) {
    this.nju_1.f(name);
  }
  gjv() {
    var r = this.nju_1.q3(get_lastIndex_0(this.nju_1));
    this.oju_1 = true;
    return r;
  }
}
class NamedValueDecoder extends TaggedDecoder {
  kju(_this__u8e3s4, index) {
    return this.mju(this.lju(_this__u8e3s4, index));
  }
  mju(nestedName) {
    var tmp0_elvis_lhs = this.pju();
    return this.qju(tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs, nestedName);
  }
  lju(descriptor, index) {
    return descriptor.tjh(index);
  }
  qju(parentName, childName) {
    var tmp;
    // Inline function 'kotlin.text.isEmpty' call
    if (charSequenceLength(parentName) === 0) {
      tmp = childName;
    } else {
      tmp = parentName + '.' + childName;
    }
    return tmp;
  }
  rju() {
    return this.nju_1.a1() ? '$' : joinToString(this.nju_1, '.', '$.');
  }
}
class MapEntry {
  constructor(key, value) {
    this.hjv_1 = key;
    this.ijv_1 = value;
  }
  a2() {
    return this.hjv_1;
  }
  b2() {
    return this.ijv_1;
  }
  toString() {
    return 'MapEntry(key=' + toString_0(this.hjv_1) + ', value=' + toString_0(this.ijv_1) + ')';
  }
  hashCode() {
    var result = this.hjv_1 == null ? 0 : hashCode(this.hjv_1);
    result = imul(result, 31) + (this.ijv_1 == null ? 0 : hashCode(this.ijv_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof MapEntry))
      return false;
    var tmp0_other_with_cast = other instanceof MapEntry ? other : THROW_CCE();
    if (!equals(this.hjv_1, tmp0_other_with_cast.hjv_1))
      return false;
    if (!equals(this.ijv_1, tmp0_other_with_cast.ijv_1))
      return false;
    return true;
  }
}
class KeyValueSerializer {
  constructor(keySerializer, valueSerializer) {
    this.njv_1 = keySerializer;
    this.ojv_1 = valueSerializer;
  }
  vjf(decoder) {
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var descriptor = this.ujf();
    var composite = decoder.pjj(descriptor);
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlinx.serialization.internal.KeyValueSerializer.deserialize.<anonymous>' call
      if (composite.fjk()) {
        var key = composite.cjk(this.ujf(), 0, this.njv_1);
        var value = composite.cjk(this.ujf(), 1, this.ojv_1);
        tmp$ret$0 = this.mjv(key, value);
        break $l$block;
      }
      var key_0 = get_NULL();
      var value_0 = get_NULL();
      mainLoop: while (true) {
        var idx = composite.gjk(this.ujf());
        switch (idx) {
          case -1:
            break mainLoop;
          case 0:
            key_0 = composite.cjk(this.ujf(), 0, this.njv_1);
            break;
          case 1:
            value_0 = composite.cjk(this.ujf(), 1, this.ojv_1);
            break;
          default:
            throw SerializationException.sjg('Invalid index: ' + idx);
        }
      }
      if (key_0 === get_NULL())
        throw SerializationException.sjg("Element 'key' is missing");
      if (value_0 === get_NULL())
        throw SerializationException.sjg("Element 'value' is missing");
      var tmp = (key_0 == null ? true : !(key_0 == null)) ? key_0 : THROW_CCE();
      tmp$ret$0 = this.mjv(tmp, (value_0 == null ? true : !(value_0 == null)) ? value_0 : THROW_CCE());
    }
    var result = tmp$ret$0;
    composite.qjj(descriptor);
    return result;
  }
}
class MapEntrySerializer extends KeyValueSerializer {
  constructor(keySerializer, valueSerializer) {
    super(keySerializer, valueSerializer);
    var tmp = this;
    var tmp_0 = MAP_getInstance();
    tmp.ljv_1 = buildSerialDescriptor('kotlin.collections.Map.Entry', tmp_0, [], MapEntrySerializer$descriptor$lambda(keySerializer, valueSerializer));
  }
  ujf() {
    return this.ljv_1;
  }
  mjv(key, value) {
    return new MapEntry(key, value);
  }
}
class PairSerializer extends KeyValueSerializer {
  constructor(keySerializer, valueSerializer) {
    super(keySerializer, valueSerializer);
    var tmp = this;
    tmp.rjv_1 = buildClassSerialDescriptor('kotlin.Pair', [], PairSerializer$descriptor$lambda(keySerializer, valueSerializer));
  }
  ujf() {
    return this.rjv_1;
  }
  mjv(key, value) {
    return to(key, value);
  }
}
class TripleSerializer {
  constructor(aSerializer, bSerializer, cSerializer) {
    this.sjv_1 = aSerializer;
    this.tjv_1 = bSerializer;
    this.ujv_1 = cSerializer;
    var tmp = this;
    tmp.vjv_1 = buildClassSerialDescriptor('kotlin.Triple', [], TripleSerializer$descriptor$lambda(this));
  }
  ujf() {
    return this.vjv_1;
  }
  vjf(decoder) {
    var composite = decoder.pjj(this.vjv_1);
    if (composite.fjk()) {
      return decodeSequentially_1(this, composite);
    }
    return decodeStructure(this, composite);
  }
}
class ULongSerializer {
  constructor() {
    ULongSerializer_instance = this;
    this.wjv_1 = InlinePrimitiveDescriptor('kotlin.ULong', serializer_4(Companion_getInstance_1()));
  }
  ujf() {
    return this.wjv_1;
  }
  xjv(decoder) {
    // Inline function 'kotlin.toULong' call
    var this_0 = decoder.mjj(this.wjv_1).gjj();
    return _ULong___init__impl__c78o9k(this_0);
  }
  vjf(decoder) {
    return new ULong(this.xjv(decoder));
  }
}
class UIntSerializer {
  constructor() {
    UIntSerializer_instance = this;
    this.yjv_1 = InlinePrimitiveDescriptor('kotlin.UInt', serializer_6(IntCompanionObject_instance));
  }
  ujf() {
    return this.yjv_1;
  }
  zjv(decoder) {
    // Inline function 'kotlin.toUInt' call
    var this_0 = decoder.mjj(this.yjv_1).fjj();
    return _UInt___init__impl__l7qpdl(this_0);
  }
  vjf(decoder) {
    return new UInt(this.zjv(decoder));
  }
}
class UShortSerializer {
  constructor() {
    UShortSerializer_instance = this;
    this.ajw_1 = InlinePrimitiveDescriptor('kotlin.UShort', serializer_8(ShortCompanionObject_instance));
  }
  ujf() {
    return this.ajw_1;
  }
  bjw(decoder) {
    // Inline function 'kotlin.toUShort' call
    var this_0 = decoder.mjj(this.ajw_1).ejj();
    return _UShort___init__impl__jigrne(this_0);
  }
  vjf(decoder) {
    return new UShort(this.bjw(decoder));
  }
}
class UByteSerializer {
  constructor() {
    UByteSerializer_instance = this;
    this.cjw_1 = InlinePrimitiveDescriptor('kotlin.UByte', serializer_10(ByteCompanionObject_instance));
  }
  ujf() {
    return this.cjw_1;
  }
  djw(decoder) {
    // Inline function 'kotlin.toUByte' call
    var this_0 = decoder.mjj(this.cjw_1).djj();
    return _UByte___init__impl__g9hnc4(this_0);
  }
  vjf(decoder) {
    return new UByte(this.djw(decoder));
  }
}
class SerializersModule {
  kjh(kClass, typeArgumentsSerializers, $super) {
    typeArgumentsSerializers = typeArgumentsSerializers === VOID ? emptyList() : typeArgumentsSerializers;
    return $super === VOID ? this.ljh(kClass, typeArgumentsSerializers) : $super.ljh.call(this, kClass, typeArgumentsSerializers);
  }
}
class SerialModuleImpl extends SerializersModule {
  constructor(class2ContextualFactory, polyBase2Serializers, polyBase2DefaultSerializerProvider, polyBase2NamedSerializers, polyBase2DefaultDeserializerProvider, hasInterfaceContextualSerializers) {
    super();
    this.fjw_1 = class2ContextualFactory;
    this.gjw_1 = polyBase2Serializers;
    this.hjw_1 = polyBase2DefaultSerializerProvider;
    this.ijw_1 = polyBase2NamedSerializers;
    this.jjw_1 = polyBase2DefaultDeserializerProvider;
    this.kjw_1 = hasInterfaceContextualSerializers;
  }
  jjh() {
    return this.kjw_1;
  }
  ijk(baseClass, serializedClassName) {
    var tmp30_safe_receiver = this.ijw_1.k3(baseClass);
    var tmp;
    if (tmp30_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.collections.get' call
      tmp = (isInterface(tmp30_safe_receiver, KtMap) ? tmp30_safe_receiver : THROW_CCE()).k3(serializedClassName);
    }
    var tmp_0 = tmp;
    var registered = (!(tmp_0 == null) ? isInterface(tmp_0, KSerializer) : false) ? tmp_0 : null;
    if (!(registered == null))
      return registered;
    var tmp_1 = this.jjw_1.k3(baseClass);
    var tmp31_safe_receiver = (!(tmp_1 == null) ? typeof tmp_1 === 'function' : false) ? tmp_1 : null;
    return tmp31_safe_receiver == null ? null : tmp31_safe_receiver(serializedClassName);
  }
  ljh(kClass, typeArgumentsSerializers) {
    var tmp32_safe_receiver = this.fjw_1.k3(kClass);
    var tmp = tmp32_safe_receiver == null ? null : tmp32_safe_receiver.ljw(typeArgumentsSerializers);
    return (tmp == null ? true : isInterface(tmp, KSerializer)) ? tmp : null;
  }
  ejw(collector) {
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = this.fjw_1.z1().f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var kclass = element.a2();
      // Inline function 'kotlin.collections.component2' call
      var serial = element.b2();
      if (serial instanceof Argless) {
        var tmp = isInterface(kclass, KClass) ? kclass : THROW_CCE();
        var tmp_0 = serial.ojw_1;
        collector.pjw(tmp, isInterface(tmp_0, KSerializer) ? tmp_0 : THROW_CCE());
      } else {
        if (serial instanceof WithTypeArguments) {
          collector.njw(kclass, serial.mjw_1);
        } else {
          noWhenBranchMatchedException();
        }
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator_0 = this.gjw_1.z1().f1();
    while (tmp0_iterator_0.g1()) {
      var element_0 = tmp0_iterator_0.h1();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var baseClass = element_0.a2();
      // Inline function 'kotlin.collections.component2' call
      var classMap = element_0.b2();
      // Inline function 'kotlin.collections.forEach' call
      // Inline function 'kotlin.collections.iterator' call
      var tmp0_iterator_1 = classMap.z1().f1();
      while (tmp0_iterator_1.g1()) {
        var element_1 = tmp0_iterator_1.h1();
        // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.collections.component1' call
        var actualClass = element_1.a2();
        // Inline function 'kotlin.collections.component2' call
        var serializer = element_1.b2();
        var tmp_1 = isInterface(baseClass, KClass) ? baseClass : THROW_CCE();
        var tmp_2 = isInterface(actualClass, KClass) ? actualClass : THROW_CCE();
        // Inline function 'kotlinx.serialization.internal.cast' call
        var tmp$ret$9 = isInterface(serializer, KSerializer) ? serializer : THROW_CCE();
        collector.qjw(tmp_1, tmp_2, tmp$ret$9);
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator_2 = this.hjw_1.z1().f1();
    while (tmp0_iterator_2.g1()) {
      var element_2 = tmp0_iterator_2.h1();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var baseClass_0 = element_2.a2();
      // Inline function 'kotlin.collections.component2' call
      var provider = element_2.b2();
      var tmp_3 = isInterface(baseClass_0, KClass) ? baseClass_0 : THROW_CCE();
      collector.rjw(tmp_3, typeof provider === 'function' ? provider : THROW_CCE());
    }
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator_3 = this.jjw_1.z1().f1();
    while (tmp0_iterator_3.g1()) {
      var element_3 = tmp0_iterator_3.h1();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var baseClass_1 = element_3.a2();
      // Inline function 'kotlin.collections.component2' call
      var provider_0 = element_3.b2();
      var tmp_4 = isInterface(baseClass_1, KClass) ? baseClass_1 : THROW_CCE();
      collector.sjw(tmp_4, typeof provider_0 === 'function' ? provider_0 : THROW_CCE());
    }
  }
}
class ContextualProvider {}
class Argless extends ContextualProvider {}
class WithTypeArguments extends ContextualProvider {}
class SerializersModuleCollector {}
function contextual(kClass, serializer) {
  return this.njw(kClass, SerializersModuleCollector$contextual$lambda(serializer));
}
class SerializableWith {
  constructor(serializer) {
    this.tjw_1 = serializer;
  }
  equals(other) {
    if (!(other instanceof SerializableWith))
      return false;
    var tmp0_other_with_cast = other instanceof SerializableWith ? other : THROW_CCE();
    if (!this.tjw_1.equals(tmp0_other_with_cast.tjw_1))
      return false;
    return true;
  }
  hashCode() {
    return imul(getStringHashCode('serializer'), 127) ^ this.tjw_1.hashCode();
  }
  toString() {
    return '@kotlinx.serialization.SerializableWith(serializer=' + toString(this.tjw_1) + ')';
  }
}
class createCache$1 {
  constructor($factory) {
    this.ujw_1 = $factory;
  }
  mjh(key) {
    return this.ujw_1(key);
  }
}
class createParametrizedCache$1 {
  constructor($factory) {
    this.vjw_1 = $factory;
  }
  njh(key, types) {
    // Inline function 'kotlin.runCatching' call
    var tmp;
    try {
      // Inline function 'kotlin.Companion.success' call
      // Inline function 'kotlinx.serialization.internal.<no name provided>.get.<anonymous>' call
      var value = this.vjw_1(key, types);
      tmp = _Result___init__impl__xyqfz8(value);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        // Inline function 'kotlin.Companion.failure' call
        tmp_0 = _Result___init__impl__xyqfz8(createFailure(e));
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
}
//endregion
function PolymorphicSerializer$descriptor$delegate$lambda$lambda(this$0) {
  return function ($this$buildSerialDescriptor) {
    $this$buildSerialDescriptor.ejg('type', serializer_0(StringCompanionObject_instance).ujf());
    $this$buildSerialDescriptor.ejg('value', buildSerialDescriptor('kotlinx.serialization.Polymorphic<' + this$0.fjg_1.xf() + '>', CONTEXTUAL_getInstance(), []));
    $this$buildSerialDescriptor.yjf_1 = this$0.gjg_1;
    return Unit_instance;
  };
}
function PolymorphicSerializer$descriptor$delegate$lambda(this$0) {
  return function () {
    var tmp = OPEN_getInstance();
    return withContext(buildSerialDescriptor('kotlinx.serialization.Polymorphic', tmp, [], PolymorphicSerializer$descriptor$delegate$lambda$lambda(this$0)), this$0.fjg_1);
  };
}
function findPolymorphicSerializer(_this__u8e3s4, decoder, klassName) {
  var tmp0_elvis_lhs = _this__u8e3s4.mjg(decoder, klassName);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    throwSubtypeNotRegistered(klassName, _this__u8e3s4.kjg());
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function descriptor$factory() {
  return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
    return receiver.ujf();
  }, null);
}
function init_kotlinx_serialization_SerializationException(_this__u8e3s4) {
  captureStack(_this__u8e3s4, _this__u8e3s4.qjg_1);
}
function serializerOrNull(_this__u8e3s4) {
  var tmp0_elvis_lhs = compiledSerializerImpl(_this__u8e3s4);
  return tmp0_elvis_lhs == null ? builtinSerializerOrNull(_this__u8e3s4) : tmp0_elvis_lhs;
}
function serializersForParameters(_this__u8e3s4, typeArguments, failOnMissingTypeArgSerializer) {
  var tmp;
  if (failOnMissingTypeArgSerializer) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(typeArguments, 10));
    var tmp0_iterator = typeArguments.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'kotlinx.serialization.serializersForParameters.<anonymous>' call
      var tmp$ret$0 = serializer(_this__u8e3s4, item);
      destination.f(tmp$ret$0);
    }
    tmp = destination;
  } else {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination_0 = ArrayList.w(collectionSizeOrDefault(typeArguments, 10));
    var tmp0_iterator_0 = typeArguments.f1();
    while (tmp0_iterator_0.g1()) {
      var item_0 = tmp0_iterator_0.h1();
      // Inline function 'kotlinx.serialization.serializersForParameters.<anonymous>' call
      var tmp0_elvis_lhs = serializerOrNull_0(_this__u8e3s4, item_0);
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        return null;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var tmp$ret$3 = tmp_0;
      destination_0.f(tmp$ret$3);
    }
    tmp = destination_0;
  }
  var serializers = tmp;
  return serializers;
}
function parametrizedSerializerOrNull(_this__u8e3s4, serializers, elementClassifierIfArray) {
  var tmp0_elvis_lhs = builtinParametrizedSerializer(_this__u8e3s4, serializers, elementClassifierIfArray);
  return tmp0_elvis_lhs == null ? compiledParametrizedSerializer(_this__u8e3s4, serializers) : tmp0_elvis_lhs;
}
function serializer(_this__u8e3s4, type) {
  var tmp0_elvis_lhs = serializerByKTypeImpl(_this__u8e3s4, type, true);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    platformSpecificSerializerNotRegistered(kclass(type));
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function serializerOrNull_0(_this__u8e3s4, type) {
  return serializerByKTypeImpl(_this__u8e3s4, type, false);
}
function builtinParametrizedSerializer(_this__u8e3s4, serializers, elementClassifierIfArray) {
  var tmp;
  if (_this__u8e3s4.equals(getKClass(Collection)) || _this__u8e3s4.equals(getKClass(KtList)) || (_this__u8e3s4.equals(getKClass(KtMutableList)) || _this__u8e3s4.equals(getKClass(ArrayList)))) {
    tmp = new ArrayListSerializer(serializers.i1(0));
  } else if (_this__u8e3s4.equals(getKClass(HashSet))) {
    tmp = new HashSetSerializer(serializers.i1(0));
  } else if (_this__u8e3s4.equals(getKClass(KtSet)) || (_this__u8e3s4.equals(getKClass(KtMutableSet)) || _this__u8e3s4.equals(getKClass(LinkedHashSet)))) {
    tmp = new LinkedHashSetSerializer(serializers.i1(0));
  } else if (_this__u8e3s4.equals(getKClass(HashMap))) {
    tmp = new HashMapSerializer(serializers.i1(0), serializers.i1(1));
  } else if (_this__u8e3s4.equals(getKClass(KtMap)) || (_this__u8e3s4.equals(getKClass(KtMutableMap)) || _this__u8e3s4.equals(getKClass(LinkedHashMap)))) {
    tmp = new LinkedHashMapSerializer(serializers.i1(0), serializers.i1(1));
  } else if (_this__u8e3s4.equals(getKClass(Entry))) {
    tmp = MapEntrySerializer_0(serializers.i1(0), serializers.i1(1));
  } else if (_this__u8e3s4.equals(getKClass(Pair))) {
    tmp = PairSerializer_0(serializers.i1(0), serializers.i1(1));
  } else if (_this__u8e3s4.equals(getKClass(Triple))) {
    tmp = TripleSerializer_0(serializers.i1(0), serializers.i1(1), serializers.i1(2));
  } else {
    var tmp_0;
    if (isReferenceArray(_this__u8e3s4)) {
      var tmp_1 = elementClassifierIfArray();
      tmp_0 = ArraySerializer((!(tmp_1 == null) ? isInterface(tmp_1, KClass) : false) ? tmp_1 : THROW_CCE(), serializers.i1(0));
    } else {
      tmp_0 = null;
    }
    tmp = tmp_0;
  }
  return tmp;
}
function compiledParametrizedSerializer(_this__u8e3s4, serializers) {
  // Inline function 'kotlin.collections.toTypedArray' call
  var tmp$ret$0 = copyToArray(serializers);
  return constructSerializerForGivenTypeArgs(_this__u8e3s4, tmp$ret$0.slice());
}
function serializerByKTypeImpl(_this__u8e3s4, type, failOnMissingTypeArgSerializer) {
  var rootClass = kclass(type);
  var isNullable = type.lg();
  // Inline function 'kotlin.collections.map' call
  var this_0 = type.kg();
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
  var tmp0_iterator = this_0.f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    var tmp$ret$0 = typeOrThrow(item);
    destination.f(tmp$ret$0);
  }
  var typeArguments = destination;
  var tmp;
  if (typeArguments.a1()) {
    var tmp_0;
    if (isInterface_0(rootClass) && !(_this__u8e3s4.kjh(rootClass) == null)) {
      tmp_0 = null;
    } else {
      tmp_0 = findCachedSerializer(rootClass, isNullable);
    }
    tmp = tmp_0;
  } else {
    var tmp_1;
    if (_this__u8e3s4.jjh()) {
      tmp_1 = null;
    } else {
      // Inline function 'kotlin.Result.getOrNull' call
      var this_1 = findParametrizedCachedSerializer(rootClass, typeArguments, isNullable);
      var tmp_2;
      if (_Result___get_isFailure__impl__jpiriv(this_1)) {
        tmp_2 = null;
      } else {
        var tmp_3 = _Result___get_value__impl__bjfvqg(this_1);
        tmp_2 = (tmp_3 == null ? true : !(tmp_3 == null)) ? tmp_3 : THROW_CCE();
      }
      tmp_1 = tmp_2;
    }
    tmp = tmp_1;
  }
  var cachedSerializer = tmp;
  if (!(cachedSerializer == null))
    return cachedSerializer;
  var tmp_4;
  if (typeArguments.a1()) {
    var tmp0_elvis_lhs = serializerOrNull(rootClass);
    var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? _this__u8e3s4.kjh(rootClass) : tmp0_elvis_lhs;
    var tmp_5;
    if (tmp1_elvis_lhs == null) {
      // Inline function 'kotlinx.serialization.polymorphicIfInterface' call
      tmp_5 = isInterface_0(rootClass) ? PolymorphicSerializer.ijg(rootClass) : null;
    } else {
      tmp_5 = tmp1_elvis_lhs;
    }
    tmp_4 = tmp_5;
  } else {
    var tmp2_elvis_lhs = serializersForParameters(_this__u8e3s4, typeArguments, failOnMissingTypeArgSerializer);
    var tmp_6;
    if (tmp2_elvis_lhs == null) {
      return null;
    } else {
      tmp_6 = tmp2_elvis_lhs;
    }
    var serializers = tmp_6;
    var tmp3_elvis_lhs = parametrizedSerializerOrNull(rootClass, serializers, serializerByKTypeImpl$lambda(typeArguments));
    var tmp4_elvis_lhs = tmp3_elvis_lhs == null ? _this__u8e3s4.ljh(rootClass, serializers) : tmp3_elvis_lhs;
    var tmp_7;
    if (tmp4_elvis_lhs == null) {
      // Inline function 'kotlinx.serialization.polymorphicIfInterface' call
      tmp_7 = isInterface_0(rootClass) ? PolymorphicSerializer.ijg(rootClass) : null;
    } else {
      tmp_7 = tmp4_elvis_lhs;
    }
    tmp_4 = tmp_7;
  }
  var contextualSerializer = tmp_4;
  var tmp_8;
  if (contextualSerializer == null) {
    tmp_8 = null;
  } else {
    // Inline function 'kotlinx.serialization.internal.cast' call
    tmp_8 = isInterface(contextualSerializer, KSerializer) ? contextualSerializer : THROW_CCE();
  }
  var tmp4_safe_receiver = tmp_8;
  return tmp4_safe_receiver == null ? null : nullable(tmp4_safe_receiver, isNullable);
}
function nullable(_this__u8e3s4, shouldBeNullable) {
  if (shouldBeNullable)
    return get_nullable(_this__u8e3s4);
  return isInterface(_this__u8e3s4, KSerializer) ? _this__u8e3s4 : THROW_CCE();
}
function serializerByKTypeImpl$lambda($typeArguments) {
  return function () {
    return $typeArguments.i1(0).jg();
  };
}
function get_SERIALIZERS_CACHE() {
  _init_properties_SerializersCache_kt__hgwi2p();
  return SERIALIZERS_CACHE;
}
var SERIALIZERS_CACHE;
function get_SERIALIZERS_CACHE_NULLABLE() {
  _init_properties_SerializersCache_kt__hgwi2p();
  return SERIALIZERS_CACHE_NULLABLE;
}
var SERIALIZERS_CACHE_NULLABLE;
function get_PARAMETRIZED_SERIALIZERS_CACHE() {
  _init_properties_SerializersCache_kt__hgwi2p();
  return PARAMETRIZED_SERIALIZERS_CACHE;
}
var PARAMETRIZED_SERIALIZERS_CACHE;
function get_PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE() {
  _init_properties_SerializersCache_kt__hgwi2p();
  return PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE;
}
var PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE;
function findCachedSerializer(clazz, isNullable) {
  _init_properties_SerializersCache_kt__hgwi2p();
  var tmp;
  if (!isNullable) {
    var tmp12_safe_receiver = get_SERIALIZERS_CACHE().mjh(clazz);
    var tmp_0;
    if (tmp12_safe_receiver == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp_0 = isInterface(tmp12_safe_receiver, KSerializer) ? tmp12_safe_receiver : THROW_CCE();
    }
    tmp = tmp_0;
  } else {
    tmp = get_SERIALIZERS_CACHE_NULLABLE().mjh(clazz);
  }
  return tmp;
}
function findParametrizedCachedSerializer(clazz, types, isNullable) {
  _init_properties_SerializersCache_kt__hgwi2p();
  var tmp;
  if (!isNullable) {
    var tmp_0 = get_PARAMETRIZED_SERIALIZERS_CACHE().njh(clazz, types);
    tmp = new Result(tmp_0) instanceof Result ? tmp_0 : THROW_CCE();
  } else {
    tmp = get_PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE().njh(clazz, types);
  }
  return tmp;
}
function SERIALIZERS_CACHE$lambda(it) {
  _init_properties_SerializersCache_kt__hgwi2p();
  var tmp0_elvis_lhs = serializerOrNull(it);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    // Inline function 'kotlinx.serialization.polymorphicIfInterface' call
    tmp = isInterface_0(it) ? PolymorphicSerializer.ijg(it) : null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function SERIALIZERS_CACHE_NULLABLE$lambda(it) {
  _init_properties_SerializersCache_kt__hgwi2p();
  var tmp0_elvis_lhs = serializerOrNull(it);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    // Inline function 'kotlinx.serialization.polymorphicIfInterface' call
    tmp = isInterface_0(it) ? PolymorphicSerializer.ijg(it) : null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var tmp8_safe_receiver = tmp;
  var tmp9_safe_receiver = tmp8_safe_receiver == null ? null : get_nullable(tmp8_safe_receiver);
  var tmp_0;
  if (tmp9_safe_receiver == null) {
    tmp_0 = null;
  } else {
    // Inline function 'kotlinx.serialization.internal.cast' call
    tmp_0 = isInterface(tmp9_safe_receiver, KSerializer) ? tmp9_safe_receiver : THROW_CCE();
  }
  return tmp_0;
}
function PARAMETRIZED_SERIALIZERS_CACHE$lambda(clazz, types) {
  _init_properties_SerializersCache_kt__hgwi2p();
  var serializers = ensureNotNull(serializersForParameters(EmptySerializersModule_0(), types, true));
  return parametrizedSerializerOrNull(clazz, serializers, PARAMETRIZED_SERIALIZERS_CACHE$lambda$lambda(types));
}
function PARAMETRIZED_SERIALIZERS_CACHE$lambda$lambda($types) {
  return function () {
    return $types.i1(0).jg();
  };
}
function PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda(clazz, types) {
  _init_properties_SerializersCache_kt__hgwi2p();
  var serializers = ensureNotNull(serializersForParameters(EmptySerializersModule_0(), types, true));
  var tmp10_safe_receiver = parametrizedSerializerOrNull(clazz, serializers, PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda$lambda(types));
  var tmp11_safe_receiver = tmp10_safe_receiver == null ? null : get_nullable(tmp10_safe_receiver);
  var tmp;
  if (tmp11_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlinx.serialization.internal.cast' call
    tmp = isInterface(tmp11_safe_receiver, KSerializer) ? tmp11_safe_receiver : THROW_CCE();
  }
  return tmp;
}
function PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda$lambda($types) {
  return function () {
    return $types.i1(0).jg();
  };
}
var properties_initialized_SerializersCache_kt_q8kf25;
function _init_properties_SerializersCache_kt__hgwi2p() {
  if (!properties_initialized_SerializersCache_kt_q8kf25) {
    properties_initialized_SerializersCache_kt_q8kf25 = true;
    SERIALIZERS_CACHE = createCache(SERIALIZERS_CACHE$lambda);
    SERIALIZERS_CACHE_NULLABLE = createCache(SERIALIZERS_CACHE_NULLABLE$lambda);
    PARAMETRIZED_SERIALIZERS_CACHE = createParametrizedCache(PARAMETRIZED_SERIALIZERS_CACHE$lambda);
    PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE = createParametrizedCache(PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda);
  }
}
function get_nullable(_this__u8e3s4) {
  var tmp;
  if (_this__u8e3s4.ujf().ojh()) {
    tmp = isInterface(_this__u8e3s4, KSerializer) ? _this__u8e3s4 : THROW_CCE();
  } else {
    tmp = new NullableSerializer(_this__u8e3s4);
  }
  return tmp;
}
function serializer_0(_this__u8e3s4) {
  return StringSerializer_getInstance();
}
function serializer_1(_this__u8e3s4) {
  return CharSerializer_getInstance();
}
function CharArraySerializer_0() {
  return CharArraySerializer_getInstance();
}
function serializer_2(_this__u8e3s4) {
  return DoubleSerializer_getInstance();
}
function DoubleArraySerializer_0() {
  return DoubleArraySerializer_getInstance();
}
function serializer_3(_this__u8e3s4) {
  return FloatSerializer_getInstance();
}
function FloatArraySerializer_0() {
  return FloatArraySerializer_getInstance();
}
function serializer_4(_this__u8e3s4) {
  return LongSerializer_getInstance();
}
function LongArraySerializer_0() {
  return LongArraySerializer_getInstance();
}
function serializer_5(_this__u8e3s4) {
  return ULongSerializer_getInstance();
}
function ULongArraySerializer_0() {
  return ULongArraySerializer_getInstance();
}
function serializer_6(_this__u8e3s4) {
  return IntSerializer_getInstance();
}
function IntArraySerializer_0() {
  return IntArraySerializer_getInstance();
}
function serializer_7(_this__u8e3s4) {
  return UIntSerializer_getInstance();
}
function UIntArraySerializer_0() {
  return UIntArraySerializer_getInstance();
}
function serializer_8(_this__u8e3s4) {
  return ShortSerializer_getInstance();
}
function ShortArraySerializer_0() {
  return ShortArraySerializer_getInstance();
}
function serializer_9(_this__u8e3s4) {
  return UShortSerializer_getInstance();
}
function UShortArraySerializer_0() {
  return UShortArraySerializer_getInstance();
}
function serializer_10(_this__u8e3s4) {
  return ByteSerializer_getInstance();
}
function ByteArraySerializer_0() {
  return ByteArraySerializer_getInstance();
}
function serializer_11(_this__u8e3s4) {
  return UByteSerializer_getInstance();
}
function UByteArraySerializer_0() {
  return UByteArraySerializer_getInstance();
}
function serializer_12(_this__u8e3s4) {
  return BooleanSerializer_getInstance();
}
function BooleanArraySerializer_0() {
  return BooleanArraySerializer_getInstance();
}
function serializer_13(_this__u8e3s4) {
  return UnitSerializer_getInstance();
}
function NothingSerializer_0() {
  return NothingSerializer_getInstance();
}
function serializer_14(_this__u8e3s4) {
  return DurationSerializer_getInstance();
}
function MapEntrySerializer_0(keySerializer, valueSerializer) {
  return new MapEntrySerializer(keySerializer, valueSerializer);
}
function PairSerializer_0(keySerializer, valueSerializer) {
  return new PairSerializer(keySerializer, valueSerializer);
}
function TripleSerializer_0(aSerializer, bSerializer, cSerializer) {
  return new TripleSerializer(aSerializer, bSerializer, cSerializer);
}
function ArraySerializer(kClass, elementSerializer) {
  return new ReferenceArraySerializer(kClass, elementSerializer);
}
function ListSerializer(elementSerializer) {
  return new ArrayListSerializer(elementSerializer);
}
function MapSerializer(keySerializer, valueSerializer) {
  return new LinkedHashMapSerializer(keySerializer, valueSerializer);
}
function withContext(_this__u8e3s4, context) {
  return new ContextDescriptor(_this__u8e3s4, context);
}
function getContextualDescriptor(_this__u8e3s4, descriptor) {
  var tmp14_safe_receiver = get_capturedKClass(descriptor);
  var tmp;
  if (tmp14_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.serialization.descriptors.getContextualDescriptor.<anonymous>' call
    var tmp15_safe_receiver = _this__u8e3s4.kjh(tmp14_safe_receiver);
    tmp = tmp15_safe_receiver == null ? null : tmp15_safe_receiver.ujf();
  }
  return tmp;
}
function get_capturedKClass(_this__u8e3s4) {
  var tmp;
  if (_this__u8e3s4 instanceof ContextDescriptor) {
    tmp = _this__u8e3s4.qjh_1;
  } else {
    if (_this__u8e3s4 instanceof SerialDescriptorForNullable) {
      tmp = get_capturedKClass(_this__u8e3s4.cji_1);
    } else {
      tmp = null;
    }
  }
  return tmp;
}
function get_elementDescriptors(_this__u8e3s4) {
  // Inline function 'kotlin.collections.Iterable' call
  return new _no_name_provided__qut3iv(_this__u8e3s4);
}
function get_elementNames(_this__u8e3s4) {
  // Inline function 'kotlin.collections.Iterable' call
  return new _no_name_provided__qut3iv_0(_this__u8e3s4);
}
function buildSerialDescriptor(serialName, kind, typeParameters, builder) {
  var tmp;
  if (builder === VOID) {
    tmp = buildSerialDescriptor$lambda;
  } else {
    tmp = builder;
  }
  builder = tmp;
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.text.isNotBlank' call
  // Inline function 'kotlin.contracts.contract' call
  if (!!isBlank(serialName)) {
    // Inline function 'kotlinx.serialization.descriptors.buildSerialDescriptor.<anonymous>' call
    var message = 'Blank serial names are prohibited';
    throw IllegalArgumentException.l(toString(message));
  }
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!!equals(kind, CLASS_getInstance())) {
    // Inline function 'kotlinx.serialization.descriptors.buildSerialDescriptor.<anonymous>' call
    var message_0 = "For StructureKind.CLASS please use 'buildClassSerialDescriptor' instead";
    throw IllegalArgumentException.l(toString(message_0));
  }
  var sdBuilder = new ClassSerialDescriptorBuilder(serialName);
  builder(sdBuilder);
  return new SerialDescriptorImpl(serialName, kind, sdBuilder.zjf_1.j1(), toList(typeParameters), sdBuilder);
}
function _get__hashCode__tgwhef($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.xji_1;
  _hashCode$factory();
  return this_0.b2();
}
function SerialDescriptorImpl$_hashCode$delegate$lambda(this$0) {
  return function () {
    return hashCodeImpl(this$0, this$0.wji_1);
  };
}
function SerialDescriptorImpl$toString$lambda(this$0) {
  return function (it) {
    return this$0.tjh(it) + ': ' + this$0.wjh(it).sjh();
  };
}
function buildClassSerialDescriptor(serialName, typeParameters, builderAction) {
  var tmp;
  if (builderAction === VOID) {
    tmp = buildClassSerialDescriptor$lambda;
  } else {
    tmp = builderAction;
  }
  builderAction = tmp;
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.text.isNotBlank' call
  // Inline function 'kotlin.contracts.contract' call
  if (!!isBlank(serialName)) {
    // Inline function 'kotlinx.serialization.descriptors.buildClassSerialDescriptor.<anonymous>' call
    var message = 'Blank serial names are prohibited';
    throw IllegalArgumentException.l(toString(message));
  }
  var sdBuilder = new ClassSerialDescriptorBuilder(serialName);
  builderAction(sdBuilder);
  return new SerialDescriptorImpl(serialName, CLASS_getInstance(), sdBuilder.zjf_1.j1(), toList(typeParameters), sdBuilder);
}
function PrimitiveSerialDescriptor_0(serialName, kind) {
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.text.isNotBlank' call
  // Inline function 'kotlin.contracts.contract' call
  if (!!isBlank(serialName)) {
    // Inline function 'kotlinx.serialization.descriptors.PrimitiveSerialDescriptor.<anonymous>' call
    var message = 'Blank serial names are prohibited';
    throw IllegalArgumentException.l(toString(message));
  }
  return PrimitiveDescriptorSafe(serialName, kind);
}
function buildSerialDescriptor$lambda(_this__u8e3s4) {
  return Unit_instance;
}
function buildClassSerialDescriptor$lambda(_this__u8e3s4) {
  return Unit_instance;
}
function _hashCode$factory() {
  return getPropertyCallableRef('_hashCode', 1, KProperty1, function (receiver) {
    return _get__hashCode__tgwhef(receiver);
  }, null);
}
var ENUM_instance;
function ENUM_getInstance() {
  if (ENUM_instance === VOID)
    new ENUM();
  return ENUM_instance;
}
var CONTEXTUAL_instance;
function CONTEXTUAL_getInstance() {
  if (CONTEXTUAL_instance === VOID)
    new CONTEXTUAL();
  return CONTEXTUAL_instance;
}
var SEALED_instance;
function SEALED_getInstance() {
  if (SEALED_instance === VOID)
    new SEALED();
  return SEALED_instance;
}
var OPEN_instance;
function OPEN_getInstance() {
  if (OPEN_instance === VOID)
    new OPEN();
  return OPEN_instance;
}
var BOOLEAN_instance;
function BOOLEAN_getInstance() {
  if (BOOLEAN_instance === VOID)
    new BOOLEAN();
  return BOOLEAN_instance;
}
var BYTE_instance;
function BYTE_getInstance() {
  if (BYTE_instance === VOID)
    new BYTE();
  return BYTE_instance;
}
var CHAR_instance;
function CHAR_getInstance() {
  if (CHAR_instance === VOID)
    new CHAR();
  return CHAR_instance;
}
var SHORT_instance;
function SHORT_getInstance() {
  if (SHORT_instance === VOID)
    new SHORT();
  return SHORT_instance;
}
var INT_instance;
function INT_getInstance() {
  if (INT_instance === VOID)
    new INT();
  return INT_instance;
}
var LONG_instance;
function LONG_getInstance() {
  if (LONG_instance === VOID)
    new LONG();
  return LONG_instance;
}
var FLOAT_instance;
function FLOAT_getInstance() {
  if (FLOAT_instance === VOID)
    new FLOAT();
  return FLOAT_instance;
}
var DOUBLE_instance;
function DOUBLE_getInstance() {
  if (DOUBLE_instance === VOID)
    new DOUBLE();
  return DOUBLE_instance;
}
var STRING_instance;
function STRING_getInstance() {
  if (STRING_instance === VOID)
    new STRING();
  return STRING_instance;
}
var CLASS_instance;
function CLASS_getInstance() {
  if (CLASS_instance === VOID)
    new CLASS();
  return CLASS_instance;
}
var LIST_instance;
function LIST_getInstance() {
  if (LIST_instance === VOID)
    new LIST();
  return LIST_instance;
}
var MAP_instance;
function MAP_getInstance() {
  if (MAP_instance === VOID)
    new MAP();
  return MAP_instance;
}
var OBJECT_instance;
function OBJECT_getInstance() {
  if (OBJECT_instance === VOID)
    new OBJECT();
  return OBJECT_instance;
}
function decodeSequentially_0($this, compositeDecoder) {
  var klassName = compositeDecoder.zjj($this.ujf(), 0);
  var serializer = findPolymorphicSerializer($this, compositeDecoder, klassName);
  return compositeDecoder.cjk($this.ujf(), 1, serializer);
}
function throwSubtypeNotRegistered(subClassName, baseClass) {
  var scope = "in the polymorphic scope of '" + baseClass.xf() + "'";
  throw SerializationException.sjg(subClassName == null ? 'Class discriminator was missing and no default serializers were registered ' + scope + '.' : "Serializer for subclass '" + subClassName + "' is not found " + scope + '.\n' + ("Check if class with serial name '" + subClassName + "' exists and serializer is registered in a corresponding SerializersModule.\n") + ("To be registered automatically, class '" + subClassName + "' has to be '@Serializable', and the base class '" + baseClass.xf() + "' has to be sealed and '@Serializable'."));
}
var NothingSerializer_instance;
function NothingSerializer_getInstance() {
  if (NothingSerializer_instance === VOID)
    new NothingSerializer();
  return NothingSerializer_instance;
}
var DurationSerializer_instance;
function DurationSerializer_getInstance() {
  if (DurationSerializer_instance === VOID)
    new DurationSerializer();
  return DurationSerializer_instance;
}
function readSize($this, decoder, builder) {
  var size = decoder.hjk($this.ujf());
  $this.njl(builder, size);
  return size;
}
var Companion_instance_0;
function Companion_getInstance_6() {
  if (Companion_instance_0 === VOID)
    new Companion();
  return Companion_instance_0;
}
function prepareHighMarksArray($this, elementsCount) {
  var slotsCount = (elementsCount - 1 | 0) >>> 6 | 0;
  var elementsInLastSlot = elementsCount & 63;
  var highMarks = longArray(slotsCount);
  if (!(elementsInLastSlot === 0)) {
    highMarks[get_lastIndex(highMarks)] = (new Long(-1, -1)).k4(elementsCount);
  }
  return highMarks;
}
function markHigh($this, index) {
  var slot = (index >>> 6 | 0) - 1 | 0;
  var offsetInSlot = index & 63;
  $this.xjn_1[slot] = $this.xjn_1[slot].o4((new Long(1, 0)).k4(offsetInSlot));
}
function nextUnmarkedHighIndex($this) {
  var inductionVariable = 0;
  var last = $this.xjn_1.length - 1 | 0;
  if (inductionVariable <= last)
    do {
      var slot = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      var slotOffset = imul(slot + 1 | 0, 64);
      var slotMarks = $this.xjn_1[slot];
      while (!slotMarks.equals(new Long(-1, -1))) {
        var indexInSlot = countTrailingZeroBits(slotMarks.j4());
        slotMarks = slotMarks.o4((new Long(1, 0)).k4(indexInSlot));
        var index = slotOffset + indexInSlot | 0;
        if ($this.vjn_1($this.ujn_1, index)) {
          $this.xjn_1[slot] = slotMarks;
          return index;
        }
      }
      $this.xjn_1[slot] = slotMarks;
    }
     while (inductionVariable <= last);
  return -1;
}
function createSimpleEnumSerializer(serialName, values) {
  return new EnumSerializer(serialName, values);
}
function createUnmarkedDescriptor($this, serialName) {
  var d = new EnumDescriptor(serialName, $this.ajo_1.length);
  // Inline function 'kotlin.collections.forEach' call
  var indexedObject = $this.ajo_1;
  var inductionVariable = 0;
  var last = indexedObject.length;
  while (inductionVariable < last) {
    var element = indexedObject[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'kotlinx.serialization.internal.EnumSerializer.createUnmarkedDescriptor.<anonymous>' call
    d.pjo(element.u3_1);
  }
  return d;
}
function EnumSerializer$descriptor$delegate$lambda(this$0, $serialName) {
  return function () {
    var tmp0_elvis_lhs = this$0.bjo_1;
    return tmp0_elvis_lhs == null ? createUnmarkedDescriptor(this$0, $serialName) : tmp0_elvis_lhs;
  };
}
function _get_elementDescriptors__y23q9p($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.djp_1;
  elementDescriptors$factory();
  return this_0.b2();
}
function EnumDescriptor$elementDescriptors$delegate$lambda($elementsCount, $name, this$0) {
  return function () {
    var tmp = 0;
    var tmp_0 = $elementsCount;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(tmp_0), null);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      tmp_1[tmp_2] = buildSerialDescriptor($name + '.' + this$0.tjh(tmp_2), OBJECT_getInstance(), []);
      tmp = tmp + 1 | 0;
    }
    return tmp_1;
  };
}
function descriptor$factory_0() {
  return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
    return receiver.ujf();
  }, null);
}
function elementDescriptors$factory() {
  return getPropertyCallableRef('elementDescriptors', 1, KProperty1, function (receiver) {
    return _get_elementDescriptors__y23q9p(receiver);
  }, null);
}
function InlinePrimitiveDescriptor(name, primitiveSerializer) {
  return new InlineClassDescriptor(name, new InlinePrimitiveDescriptor$1(primitiveSerializer));
}
function jsonCachedSerialNames(_this__u8e3s4) {
  return cachedSerialNames(_this__u8e3s4);
}
function error($this) {
  throw IllegalStateException.m5('Descriptor for type `kotlin.Nothing` does not have elements');
}
var NothingSerialDescriptor_instance;
function NothingSerialDescriptor_getInstance() {
  if (NothingSerialDescriptor_instance === VOID)
    new NothingSerialDescriptor();
  return NothingSerialDescriptor_instance;
}
function ObjectSerializer$descriptor$delegate$lambda$lambda(this$0) {
  return function ($this$buildSerialDescriptor) {
    $this$buildSerialDescriptor.yjf_1 = this$0.bjq_1;
    return Unit_instance;
  };
}
function ObjectSerializer$descriptor$delegate$lambda($serialName, this$0) {
  return function () {
    var tmp = OBJECT_getInstance();
    return buildSerialDescriptor($serialName, tmp, [], ObjectSerializer$descriptor$delegate$lambda$lambda(this$0));
  };
}
function descriptor$factory_1() {
  return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
    return receiver.ujf();
  }, null);
}
function get_EMPTY_DESCRIPTOR_ARRAY() {
  _init_properties_Platform_common_kt__3qzecs();
  return EMPTY_DESCRIPTOR_ARRAY;
}
var EMPTY_DESCRIPTOR_ARRAY;
function cachedSerialNames(_this__u8e3s4) {
  _init_properties_Platform_common_kt__3qzecs();
  if (isInterface(_this__u8e3s4, CachedNames))
    return _this__u8e3s4.yji();
  var result = HashSet.s1(_this__u8e3s4.aji());
  var inductionVariable = 0;
  var last = _this__u8e3s4.aji();
  if (inductionVariable < last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.collections.plusAssign' call
      var element = _this__u8e3s4.tjh(i);
      result.f(element);
    }
     while (inductionVariable < last);
  return result;
}
function kclass(_this__u8e3s4) {
  _init_properties_Platform_common_kt__3qzecs();
  var t = _this__u8e3s4.jg();
  var tmp;
  if (!(t == null) ? isInterface(t, KClass) : false) {
    tmp = t;
  } else {
    if (!(t == null) ? isInterface(t, KTypeParameter) : false) {
      throw IllegalArgumentException.l('Captured type parameter ' + toString(t) + ' from generic non-reified function. ' + ('Such functionality cannot be supported because ' + toString(t) + ' is erased, either specify serializer explicitly or make ') + ('calling function inline with reified ' + toString(t) + '.'));
    } else {
      throw IllegalArgumentException.l('Only KClass supported as classifier, got ' + toString_0(t));
    }
  }
  var tmp_0 = tmp;
  return isInterface(tmp_0, KClass) ? tmp_0 : THROW_CCE();
}
function typeOrThrow(_this__u8e3s4) {
  _init_properties_Platform_common_kt__3qzecs();
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.requireNotNull' call
    var value = _this__u8e3s4.ejq_1;
    // Inline function 'kotlin.contracts.contract' call
    if (value == null) {
      // Inline function 'kotlinx.serialization.internal.typeOrThrow.<anonymous>' call
      var message = 'Star projections in type arguments are not allowed, but had ' + toString_0(_this__u8e3s4.ejq_1);
      throw IllegalArgumentException.l(toString(message));
    } else {
      tmp$ret$1 = value;
      break $l$block;
    }
  }
  return tmp$ret$1;
}
function notRegisteredMessage(_this__u8e3s4) {
  _init_properties_Platform_common_kt__3qzecs();
  var tmp0_elvis_lhs = _this__u8e3s4.xf();
  return notRegisteredMessage_0(tmp0_elvis_lhs == null ? '<local class name not available>' : tmp0_elvis_lhs);
}
function compactArray(_this__u8e3s4) {
  _init_properties_Platform_common_kt__3qzecs();
  // Inline function 'kotlin.takeUnless' call
  // Inline function 'kotlin.contracts.contract' call
  var tmp;
  // Inline function 'kotlinx.serialization.internal.compactArray.<anonymous>' call
  // Inline function 'kotlin.collections.isNullOrEmpty' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(_this__u8e3s4 == null || _this__u8e3s4.a1())) {
    tmp = _this__u8e3s4;
  } else {
    tmp = null;
  }
  var tmp24_safe_receiver = tmp;
  var tmp_0;
  if (tmp24_safe_receiver == null) {
    tmp_0 = null;
  } else {
    // Inline function 'kotlin.collections.toTypedArray' call
    tmp_0 = copyToArray(tmp24_safe_receiver);
  }
  var tmp0_elvis_lhs = tmp_0;
  return tmp0_elvis_lhs == null ? get_EMPTY_DESCRIPTOR_ARRAY() : tmp0_elvis_lhs;
}
function notRegisteredMessage_0(className) {
  _init_properties_Platform_common_kt__3qzecs();
  return "Serializer for class '" + className + "' is not found.\n" + "Please ensure that class is marked as '@Serializable' and that the serialization compiler plugin is applied.\n";
}
var properties_initialized_Platform_common_kt_i7q4ty;
function _init_properties_Platform_common_kt__3qzecs() {
  if (!properties_initialized_Platform_common_kt_i7q4ty) {
    properties_initialized_Platform_common_kt_i7q4ty = true;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    EMPTY_DESCRIPTOR_ARRAY = [];
  }
}
function throwMissingFieldException(seen, goldenMask, descriptor) {
  // Inline function 'kotlin.collections.mutableListOf' call
  var missingFields = ArrayList.m1();
  var missingFieldsBits = goldenMask & ~seen;
  var inductionVariable = 0;
  if (inductionVariable < 32)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      if (!((missingFieldsBits & 1) === 0)) {
        // Inline function 'kotlin.collections.plusAssign' call
        var element = descriptor.tjh(i);
        missingFields.f(element);
      }
      missingFieldsBits = missingFieldsBits >>> 1 | 0;
    }
     while (inductionVariable < 32);
  throw MissingFieldException.ijh(missingFields, descriptor.sjh());
}
function hashCodeImpl(_this__u8e3s4, typeParams) {
  var result = getStringHashCode(_this__u8e3s4.sjh());
  result = imul(31, result) + contentHashCode(typeParams) | 0;
  var elementDescriptors = get_elementDescriptors(_this__u8e3s4);
  // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
  // Inline function 'kotlin.collections.fold' call
  var accumulator = 1;
  var tmp0_iterator = elementDescriptors.f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
    var hash = accumulator;
    var tmp = imul(31, hash);
    // Inline function 'kotlin.hashCode' call
    // Inline function 'kotlinx.serialization.internal.hashCodeImpl.<anonymous>' call
    var tmp0_safe_receiver = element.sjh();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    accumulator = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
  }
  var namesHash = accumulator;
  // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
  // Inline function 'kotlin.collections.fold' call
  var accumulator_0 = 1;
  var tmp0_iterator_0 = elementDescriptors.f1();
  while (tmp0_iterator_0.g1()) {
    var element_0 = tmp0_iterator_0.h1();
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
    var hash_0 = accumulator_0;
    var tmp_0 = imul(31, hash_0);
    // Inline function 'kotlin.hashCode' call
    // Inline function 'kotlinx.serialization.internal.hashCodeImpl.<anonymous>' call
    var tmp0_safe_receiver_0 = element_0.yjh();
    var tmp1_elvis_lhs_0 = tmp0_safe_receiver_0 == null ? null : hashCode(tmp0_safe_receiver_0);
    accumulator_0 = tmp_0 + (tmp1_elvis_lhs_0 == null ? 0 : tmp1_elvis_lhs_0) | 0;
  }
  var kindHash = accumulator_0;
  result = imul(31, result) + namesHash | 0;
  result = imul(31, result) + kindHash | 0;
  return result;
}
function _get_childSerializers__7vnyfa($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.mjo_1;
  childSerializers$factory();
  return this_0.b2();
}
function _get__hashCode__tgwhef_0($this) {
  // Inline function 'kotlin.getValue' call
  var this_0 = $this.ojo_1;
  _hashCode$factory_0();
  return this_0.b2();
}
function buildIndices($this) {
  var indices = HashMap.c9();
  var inductionVariable = 0;
  var last = $this.hjo_1.length - 1 | 0;
  if (inductionVariable <= last)
    do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.collections.set' call
      var key = $this.hjo_1[i];
      indices.r3(key, i);
    }
     while (inductionVariable <= last);
  return indices;
}
function PluginGeneratedSerialDescriptor$childSerializers$delegate$lambda(this$0) {
  return function () {
    var tmp25_safe_receiver = this$0.ejo_1;
    var tmp0_elvis_lhs = tmp25_safe_receiver == null ? null : tmp25_safe_receiver.ujp();
    return tmp0_elvis_lhs == null ? get_EMPTY_SERIALIZER_ARRAY() : tmp0_elvis_lhs;
  };
}
function PluginGeneratedSerialDescriptor$typeParameterDescriptors$delegate$lambda(this$0) {
  return function () {
    var tmp26_safe_receiver = this$0.ejo_1;
    var tmp27_safe_receiver = tmp26_safe_receiver == null ? null : tmp26_safe_receiver.vjp();
    var tmp;
    if (tmp27_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList.w(tmp27_safe_receiver.length);
      var inductionVariable = 0;
      var last = tmp27_safe_receiver.length;
      while (inductionVariable < last) {
        var item = tmp27_safe_receiver[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.serialization.internal.PluginGeneratedSerialDescriptor.typeParameterDescriptors$delegate.<anonymous>.<anonymous>' call
        var tmp$ret$0 = item.ujf();
        destination.f(tmp$ret$0);
      }
      tmp = destination;
    }
    return compactArray(tmp);
  };
}
function PluginGeneratedSerialDescriptor$_hashCode$delegate$lambda(this$0) {
  return function () {
    return hashCodeImpl(this$0, this$0.ejp());
  };
}
function PluginGeneratedSerialDescriptor$toString$lambda(this$0) {
  return function (i) {
    return this$0.tjh(i) + ': ' + this$0.wjh(i).sjh();
  };
}
function childSerializers$factory() {
  return getPropertyCallableRef('childSerializers', 1, KProperty1, function (receiver) {
    return _get_childSerializers__7vnyfa(receiver);
  }, null);
}
function typeParameterDescriptors$factory() {
  return getPropertyCallableRef('typeParameterDescriptors', 1, KProperty1, function (receiver) {
    return receiver.ejp();
  }, null);
}
function _hashCode$factory_0() {
  return getPropertyCallableRef('_hashCode', 1, KProperty1, function (receiver) {
    return _get__hashCode__tgwhef_0(receiver);
  }, null);
}
function get_EMPTY_SERIALIZER_ARRAY() {
  _init_properties_PluginHelperInterfaces_kt__xgvzfp();
  return EMPTY_SERIALIZER_ARRAY;
}
var EMPTY_SERIALIZER_ARRAY;
var properties_initialized_PluginHelperInterfaces_kt_ap8in1;
function _init_properties_PluginHelperInterfaces_kt__xgvzfp() {
  if (!properties_initialized_PluginHelperInterfaces_kt_ap8in1) {
    properties_initialized_PluginHelperInterfaces_kt_ap8in1 = true;
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    EMPTY_SERIALIZER_ARRAY = [];
  }
}
var CharArraySerializer_instance;
function CharArraySerializer_getInstance() {
  if (CharArraySerializer_instance === VOID)
    new CharArraySerializer();
  return CharArraySerializer_instance;
}
var DoubleArraySerializer_instance;
function DoubleArraySerializer_getInstance() {
  if (DoubleArraySerializer_instance === VOID)
    new DoubleArraySerializer();
  return DoubleArraySerializer_instance;
}
var FloatArraySerializer_instance;
function FloatArraySerializer_getInstance() {
  if (FloatArraySerializer_instance === VOID)
    new FloatArraySerializer();
  return FloatArraySerializer_instance;
}
var LongArraySerializer_instance;
function LongArraySerializer_getInstance() {
  if (LongArraySerializer_instance === VOID)
    new LongArraySerializer();
  return LongArraySerializer_instance;
}
var ULongArraySerializer_instance;
function ULongArraySerializer_getInstance() {
  if (ULongArraySerializer_instance === VOID)
    new ULongArraySerializer();
  return ULongArraySerializer_instance;
}
var IntArraySerializer_instance;
function IntArraySerializer_getInstance() {
  if (IntArraySerializer_instance === VOID)
    new IntArraySerializer();
  return IntArraySerializer_instance;
}
var UIntArraySerializer_instance;
function UIntArraySerializer_getInstance() {
  if (UIntArraySerializer_instance === VOID)
    new UIntArraySerializer();
  return UIntArraySerializer_instance;
}
var ShortArraySerializer_instance;
function ShortArraySerializer_getInstance() {
  if (ShortArraySerializer_instance === VOID)
    new ShortArraySerializer();
  return ShortArraySerializer_instance;
}
var UShortArraySerializer_instance;
function UShortArraySerializer_getInstance() {
  if (UShortArraySerializer_instance === VOID)
    new UShortArraySerializer();
  return UShortArraySerializer_instance;
}
var ByteArraySerializer_instance;
function ByteArraySerializer_getInstance() {
  if (ByteArraySerializer_instance === VOID)
    new ByteArraySerializer();
  return ByteArraySerializer_instance;
}
var UByteArraySerializer_instance;
function UByteArraySerializer_getInstance() {
  if (UByteArraySerializer_instance === VOID)
    new UByteArraySerializer();
  return UByteArraySerializer_instance;
}
var BooleanArraySerializer_instance;
function BooleanArraySerializer_getInstance() {
  if (BooleanArraySerializer_instance === VOID)
    new BooleanArraySerializer();
  return BooleanArraySerializer_instance;
}
function get_BUILTIN_SERIALIZERS() {
  _init_properties_Primitives_kt__k0eto4();
  return BUILTIN_SERIALIZERS;
}
var BUILTIN_SERIALIZERS;
function builtinSerializerOrNull(_this__u8e3s4) {
  _init_properties_Primitives_kt__k0eto4();
  var tmp = get_BUILTIN_SERIALIZERS().k3(_this__u8e3s4);
  return (tmp == null ? true : isInterface(tmp, KSerializer)) ? tmp : THROW_CCE();
}
var StringSerializer_instance;
function StringSerializer_getInstance() {
  if (StringSerializer_instance === VOID)
    new StringSerializer();
  return StringSerializer_instance;
}
var CharSerializer_instance;
function CharSerializer_getInstance() {
  if (CharSerializer_instance === VOID)
    new CharSerializer();
  return CharSerializer_instance;
}
var DoubleSerializer_instance;
function DoubleSerializer_getInstance() {
  if (DoubleSerializer_instance === VOID)
    new DoubleSerializer();
  return DoubleSerializer_instance;
}
var FloatSerializer_instance;
function FloatSerializer_getInstance() {
  if (FloatSerializer_instance === VOID)
    new FloatSerializer();
  return FloatSerializer_instance;
}
var LongSerializer_instance;
function LongSerializer_getInstance() {
  if (LongSerializer_instance === VOID)
    new LongSerializer();
  return LongSerializer_instance;
}
var IntSerializer_instance;
function IntSerializer_getInstance() {
  if (IntSerializer_instance === VOID)
    new IntSerializer();
  return IntSerializer_instance;
}
var ShortSerializer_instance;
function ShortSerializer_getInstance() {
  if (ShortSerializer_instance === VOID)
    new ShortSerializer();
  return ShortSerializer_instance;
}
var ByteSerializer_instance;
function ByteSerializer_getInstance() {
  if (ByteSerializer_instance === VOID)
    new ByteSerializer();
  return ByteSerializer_instance;
}
var BooleanSerializer_instance;
function BooleanSerializer_getInstance() {
  if (BooleanSerializer_instance === VOID)
    new BooleanSerializer();
  return BooleanSerializer_instance;
}
var UnitSerializer_instance;
function UnitSerializer_getInstance() {
  if (UnitSerializer_instance === VOID)
    new UnitSerializer();
  return UnitSerializer_instance;
}
function error_0($this) {
  throw IllegalStateException.m5('Primitive descriptor does not have elements');
}
function PrimitiveDescriptorSafe(serialName, kind) {
  _init_properties_Primitives_kt__k0eto4();
  checkName(serialName);
  return new PrimitiveSerialDescriptor(serialName, kind);
}
function checkName(serialName) {
  _init_properties_Primitives_kt__k0eto4();
  var keys = get_BUILTIN_SERIALIZERS().l3();
  var _iterator__ex2g4s = keys.f1();
  while (_iterator__ex2g4s.g1()) {
    var primitive = _iterator__ex2g4s.h1();
    var simpleName = capitalize(ensureNotNull(primitive.xf()));
    var qualifiedName = 'kotlin.' + simpleName;
    if (equals_0(serialName, qualifiedName, true) || equals_0(serialName, simpleName, true)) {
      throw IllegalArgumentException.l(trimIndent('\n                The name of serial descriptor should uniquely identify associated serializer.\n                For serial name ' + serialName + ' there already exist ' + capitalize(simpleName) + 'Serializer.\n                Please refer to SerialDescriptor documentation for additional information.\n            '));
    }
  }
}
function capitalize(_this__u8e3s4) {
  _init_properties_Primitives_kt__k0eto4();
  // Inline function 'kotlin.text.replaceFirstChar' call
  var tmp;
  // Inline function 'kotlin.text.isNotEmpty' call
  if (charSequenceLength(_this__u8e3s4) > 0) {
    // Inline function 'kotlinx.serialization.internal.capitalize.<anonymous>' call
    var it = charSequenceGet(_this__u8e3s4, 0);
    var tmp$ret$1 = isLowerCase(it) ? titlecase(it) : toString_1(it);
    var tmp_0 = toString(tmp$ret$1);
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    tmp = tmp_0 + _this__u8e3s4.substring(1);
  } else {
    tmp = _this__u8e3s4;
  }
  return tmp;
}
var properties_initialized_Primitives_kt_6dpii6;
function _init_properties_Primitives_kt__k0eto4() {
  if (!properties_initialized_Primitives_kt_6dpii6) {
    properties_initialized_Primitives_kt_6dpii6 = true;
    BUILTIN_SERIALIZERS = mapOf([to(PrimitiveClasses_getInstance().wg(), serializer_0(StringCompanionObject_instance)), to(getKClass(Char), serializer_1(Companion_getInstance_0())), to(PrimitiveClasses_getInstance().zg(), CharArraySerializer_0()), to(PrimitiveClasses_getInstance().ug(), serializer_2(DoubleCompanionObject_instance)), to(PrimitiveClasses_getInstance().fh(), DoubleArraySerializer_0()), to(PrimitiveClasses_getInstance().tg(), serializer_3(FloatCompanionObject_instance)), to(PrimitiveClasses_getInstance().eh(), FloatArraySerializer_0()), to(getKClass(Long), serializer_4(Companion_getInstance_1())), to(PrimitiveClasses_getInstance().dh(), LongArraySerializer_0()), to(getKClass(ULong), serializer_5(Companion_getInstance_2())), to(getKClass(ULongArray), ULongArraySerializer_0()), to(PrimitiveClasses_getInstance().sg(), serializer_6(IntCompanionObject_instance)), to(PrimitiveClasses_getInstance().ch(), IntArraySerializer_0()), to(getKClass(UInt), serializer_7(Companion_getInstance_3())), to(getKClass(UIntArray), UIntArraySerializer_0()), to(PrimitiveClasses_getInstance().rg(), serializer_8(ShortCompanionObject_instance)), to(PrimitiveClasses_getInstance().bh(), ShortArraySerializer_0()), to(getKClass(UShort), serializer_9(Companion_getInstance_4())), to(getKClass(UShortArray), UShortArraySerializer_0()), to(PrimitiveClasses_getInstance().qg(), serializer_10(ByteCompanionObject_instance)), to(PrimitiveClasses_getInstance().ah(), ByteArraySerializer_0()), to(getKClass(UByte), serializer_11(Companion_getInstance_5())), to(getKClass(UByteArray), UByteArraySerializer_0()), to(PrimitiveClasses_getInstance().pg(), serializer_12(BooleanCompanionObject_instance)), to(PrimitiveClasses_getInstance().yg(), BooleanArraySerializer_0()), to(getKClass(Unit), serializer_13(Unit_instance)), to(PrimitiveClasses_getInstance().og(), NothingSerializer_0()), to(getKClass(Duration), serializer_14(Companion_getInstance()))]);
  }
}
function tagBlock($this, tag, block) {
  $this.fjv(tag);
  var r = block();
  if (!$this.oju_1) {
    $this.gjv();
  }
  $this.oju_1 = false;
  return r;
}
function TaggedDecoder$decodeSerializableElement$lambda(this$0, $deserializer, $previousValue) {
  return function () {
    return this$0.njj($deserializer, $previousValue);
  };
}
function TaggedDecoder$decodeNullableSerializableElement$lambda(this$0, $deserializer, $previousValue) {
  return function () {
    // Inline function 'kotlinx.serialization.encoding.decodeIfNullable' call
    var this_0 = this$0;
    var isNullabilitySupported = $deserializer.ujf().ojh();
    var tmp;
    if (isNullabilitySupported || this_0.ajj()) {
      // Inline function 'kotlinx.serialization.internal.TaggedDecoder.decodeNullableSerializableElement.<anonymous>.<anonymous>' call
      tmp = this$0.njj($deserializer, $previousValue);
    } else {
      tmp = this_0.bjj();
    }
    return tmp;
  };
}
function get_NULL() {
  _init_properties_Tuples_kt__dz0qyd();
  return NULL;
}
var NULL;
function MapEntrySerializer$descriptor$lambda($keySerializer, $valueSerializer) {
  return function ($this$buildSerialDescriptor) {
    $this$buildSerialDescriptor.ejg('key', $keySerializer.ujf());
    $this$buildSerialDescriptor.ejg('value', $valueSerializer.ujf());
    return Unit_instance;
  };
}
function PairSerializer$descriptor$lambda($keySerializer, $valueSerializer) {
  return function ($this$buildClassSerialDescriptor) {
    $this$buildClassSerialDescriptor.ejg('first', $keySerializer.ujf());
    $this$buildClassSerialDescriptor.ejg('second', $valueSerializer.ujf());
    return Unit_instance;
  };
}
function decodeSequentially_1($this, composite) {
  var a = composite.cjk($this.vjv_1, 0, $this.sjv_1);
  var b = composite.cjk($this.vjv_1, 1, $this.tjv_1);
  var c = composite.cjk($this.vjv_1, 2, $this.ujv_1);
  composite.qjj($this.vjv_1);
  return new Triple(a, b, c);
}
function decodeStructure($this, composite) {
  var a = get_NULL();
  var b = get_NULL();
  var c = get_NULL();
  mainLoop: while (true) {
    var index = composite.gjk($this.vjv_1);
    switch (index) {
      case -1:
        break mainLoop;
      case 0:
        a = composite.cjk($this.vjv_1, 0, $this.sjv_1);
        break;
      case 1:
        b = composite.cjk($this.vjv_1, 1, $this.tjv_1);
        break;
      case 2:
        c = composite.cjk($this.vjv_1, 2, $this.ujv_1);
        break;
      default:
        throw SerializationException.sjg('Unexpected index ' + index);
    }
  }
  composite.qjj($this.vjv_1);
  if (a === get_NULL())
    throw SerializationException.sjg("Element 'first' is missing");
  if (b === get_NULL())
    throw SerializationException.sjg("Element 'second' is missing");
  if (c === get_NULL())
    throw SerializationException.sjg("Element 'third' is missing");
  var tmp = (a == null ? true : !(a == null)) ? a : THROW_CCE();
  var tmp_0 = (b == null ? true : !(b == null)) ? b : THROW_CCE();
  return new Triple(tmp, tmp_0, (c == null ? true : !(c == null)) ? c : THROW_CCE());
}
function TripleSerializer$descriptor$lambda(this$0) {
  return function ($this$buildClassSerialDescriptor) {
    $this$buildClassSerialDescriptor.ejg('first', this$0.sjv_1.ujf());
    $this$buildClassSerialDescriptor.ejg('second', this$0.tjv_1.ujf());
    $this$buildClassSerialDescriptor.ejg('third', this$0.ujv_1.ujf());
    return Unit_instance;
  };
}
var properties_initialized_Tuples_kt_3vs7ar;
function _init_properties_Tuples_kt__dz0qyd() {
  if (!properties_initialized_Tuples_kt_3vs7ar) {
    properties_initialized_Tuples_kt_3vs7ar = true;
    NULL = new Object();
  }
}
var ULongSerializer_instance;
function ULongSerializer_getInstance() {
  if (ULongSerializer_instance === VOID)
    new ULongSerializer();
  return ULongSerializer_instance;
}
var UIntSerializer_instance;
function UIntSerializer_getInstance() {
  if (UIntSerializer_instance === VOID)
    new UIntSerializer();
  return UIntSerializer_instance;
}
var UShortSerializer_instance;
function UShortSerializer_getInstance() {
  if (UShortSerializer_instance === VOID)
    new UShortSerializer();
  return UShortSerializer_instance;
}
var UByteSerializer_instance;
function UByteSerializer_getInstance() {
  if (UByteSerializer_instance === VOID)
    new UByteSerializer();
  return UByteSerializer_instance;
}
function get_EmptySerializersModuleLegacyJs() {
  _init_properties_SerializersModule_kt__u78ha3();
  return EmptySerializersModule;
}
var EmptySerializersModule;
var properties_initialized_SerializersModule_kt_fjigjn;
function _init_properties_SerializersModule_kt__u78ha3() {
  if (!properties_initialized_SerializersModule_kt_fjigjn) {
    properties_initialized_SerializersModule_kt_fjigjn = true;
    EmptySerializersModule = new SerialModuleImpl(emptyMap(), emptyMap(), emptyMap(), emptyMap(), emptyMap(), false);
  }
}
function EmptySerializersModule_0() {
  return get_EmptySerializersModuleLegacyJs();
}
function SerializersModuleCollector$contextual$lambda($serializer) {
  return function (it) {
    return $serializer;
  };
}
function createCache(factory) {
  return new createCache$1(factory);
}
function createParametrizedCache(factory) {
  return new createParametrizedCache$1(factory);
}
function isInterface_0(_this__u8e3s4) {
  return get_isInterface(_this__u8e3s4);
}
function get_isInterface(_this__u8e3s4) {
  if (_this__u8e3s4 === PrimitiveClasses_getInstance().og())
    return false;
  // Inline function 'kotlin.js.asDynamic' call
  var tmp1_safe_receiver = get_js(_this__u8e3s4).$metadata$;
  return (tmp1_safe_receiver == null ? null : tmp1_safe_receiver.kind) == 'interface';
}
function compiledSerializerImpl(_this__u8e3s4) {
  var tmp0_elvis_lhs = constructSerializerForGivenTypeArgs(_this__u8e3s4, []);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var tmp_0;
    if (_this__u8e3s4 === PrimitiveClasses_getInstance().og()) {
      tmp_0 = NothingSerializer_getInstance();
    } else {
      // Inline function 'kotlin.js.asDynamic' call
      var tmp0_safe_receiver = get_js(_this__u8e3s4).Companion;
      tmp_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.serializer();
    }
    var tmp_1 = tmp_0;
    tmp = (!(tmp_1 == null) ? isInterface(tmp_1, KSerializer) : false) ? tmp_1 : null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function platformSpecificSerializerNotRegistered(_this__u8e3s4) {
  throw SerializationException.sjg(notRegisteredMessage(_this__u8e3s4) + 'To get enum serializer on Kotlin/JS, it should be annotated with @Serializable annotation.');
}
function isReferenceArray(rootClass) {
  return rootClass.equals(PrimitiveClasses_getInstance().vg());
}
function constructSerializerForGivenTypeArgs(_this__u8e3s4, args) {
  var tmp;
  try {
    // Inline function 'kotlin.reflect.findAssociatedObject' call
    var assocObject = findAssociatedObject(_this__u8e3s4, getKClass(SerializableWith));
    var tmp_0;
    if (!(assocObject == null) ? isInterface(assocObject, KSerializer) : false) {
      tmp_0 = isInterface(assocObject, KSerializer) ? assocObject : THROW_CCE();
    } else {
      if (!(assocObject == null) ? isInterface(assocObject, SerializerFactory) : false) {
        var tmp_1 = assocObject.fjq(args.slice());
        tmp_0 = isInterface(tmp_1, KSerializer) ? tmp_1 : THROW_CCE();
      } else {
        tmp_0 = null;
      }
    }
    tmp = tmp_0;
  } catch ($p) {
    var tmp_2;
    var e = $p;
    tmp_2 = null;
    tmp = tmp_2;
  }
  return tmp;
}
function toNativeArrayImpl(_this__u8e3s4, eClass) {
  // Inline function 'kotlin.collections.toTypedArray' call
  return copyToArray(_this__u8e3s4);
}
function getChecked(_this__u8e3s4, index) {
  if (!(0 <= index ? index <= (_this__u8e3s4.length - 1 | 0) : false))
    throw IndexOutOfBoundsException.x1('Index ' + index + ' out of bounds ' + get_indices(_this__u8e3s4).toString());
  return _this__u8e3s4[index];
}
function getChecked_0(_this__u8e3s4, index) {
  if (!(0 <= index ? index <= (_this__u8e3s4.length - 1 | 0) : false))
    throw IndexOutOfBoundsException.x1('Index ' + index + ' out of bounds ' + get_indices_0(_this__u8e3s4).toString());
  return _this__u8e3s4[index];
}
//region block: post-declaration
initMetadataForInterface(DeserializationStrategy, 'DeserializationStrategy');
initMetadataForInterface(KSerializer, 'KSerializer', VOID, VOID, [DeserializationStrategy]);
initMetadataForClass(AbstractPolymorphicSerializer, 'AbstractPolymorphicSerializer', VOID, VOID, [KSerializer]);
initMetadataForClass(PolymorphicSerializer, 'PolymorphicSerializer');
initMetadataForClass(SerializationException, 'SerializationException', SerializationException.rjg);
initMetadataForClass(UnknownFieldException, 'UnknownFieldException');
initMetadataForClass(MissingFieldException, 'MissingFieldException');
initMetadataForInterface(SerialDescriptor, 'SerialDescriptor');
initMetadataForClass(ContextDescriptor, 'ContextDescriptor', VOID, VOID, [SerialDescriptor]);
initMetadataForClass(elementDescriptors$1$1);
initMetadataForClass(_no_name_provided__qut3iv, VOID, VOID, VOID, [Iterable]);
initMetadataForClass(elementNames$1$1);
initMetadataForClass(_no_name_provided__qut3iv_0, VOID, VOID, VOID, [Iterable]);
initMetadataForClass(ClassSerialDescriptorBuilder, 'ClassSerialDescriptorBuilder');
initMetadataForInterface(CachedNames, 'CachedNames');
protoOf(SerialDescriptorImpl).ojh = get_isNullable;
protoOf(SerialDescriptorImpl).zjh = get_isInline;
initMetadataForClass(SerialDescriptorImpl, 'SerialDescriptorImpl', VOID, VOID, [SerialDescriptor, CachedNames]);
initMetadataForClass(SerialKind, 'SerialKind');
initMetadataForObject(ENUM, 'ENUM');
initMetadataForObject(CONTEXTUAL, 'CONTEXTUAL');
initMetadataForClass(PolymorphicKind, 'PolymorphicKind');
initMetadataForObject(SEALED, 'SEALED');
initMetadataForObject(OPEN, 'OPEN');
initMetadataForClass(PrimitiveKind, 'PrimitiveKind');
initMetadataForObject(BOOLEAN, 'BOOLEAN');
initMetadataForObject(BYTE, 'BYTE');
initMetadataForObject(CHAR, 'CHAR');
initMetadataForObject(SHORT, 'SHORT');
initMetadataForObject(INT, 'INT');
initMetadataForObject(LONG, 'LONG');
initMetadataForObject(FLOAT, 'FLOAT');
initMetadataForObject(DOUBLE, 'DOUBLE');
initMetadataForObject(STRING, 'STRING');
initMetadataForClass(StructureKind, 'StructureKind');
initMetadataForObject(CLASS, 'CLASS');
initMetadataForObject(LIST, 'LIST');
initMetadataForObject(MAP, 'MAP');
initMetadataForObject(OBJECT, 'OBJECT');
initMetadataForInterface(Decoder, 'Decoder');
initMetadataForInterface(CompositeDecoder, 'CompositeDecoder');
protoOf(AbstractDecoder).cjk = decodeSerializableElement$default;
protoOf(AbstractDecoder).ojj = decodeSerializableValue;
protoOf(AbstractDecoder).fjk = decodeSequentially;
protoOf(AbstractDecoder).hjk = decodeCollectionSize;
initMetadataForClass(AbstractDecoder, 'AbstractDecoder', VOID, VOID, [Decoder, CompositeDecoder]);
initMetadataForObject(NothingSerializer, 'NothingSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(DurationSerializer, 'DurationSerializer', VOID, VOID, [KSerializer]);
protoOf(ListLikeDescriptor).ojh = get_isNullable;
protoOf(ListLikeDescriptor).zjh = get_isInline;
protoOf(ListLikeDescriptor).bji = get_annotations;
initMetadataForClass(ListLikeDescriptor, 'ListLikeDescriptor', VOID, VOID, [SerialDescriptor]);
initMetadataForClass(ArrayListClassDesc, 'ArrayListClassDesc');
initMetadataForClass(HashSetClassDesc, 'HashSetClassDesc');
initMetadataForClass(LinkedHashSetClassDesc, 'LinkedHashSetClassDesc');
protoOf(MapLikeDescriptor).ojh = get_isNullable;
protoOf(MapLikeDescriptor).zjh = get_isInline;
protoOf(MapLikeDescriptor).bji = get_annotations;
initMetadataForClass(MapLikeDescriptor, 'MapLikeDescriptor', VOID, VOID, [SerialDescriptor]);
initMetadataForClass(HashMapClassDesc, 'HashMapClassDesc');
initMetadataForClass(LinkedHashMapClassDesc, 'LinkedHashMapClassDesc');
initMetadataForClass(ArrayClassDesc, 'ArrayClassDesc');
initMetadataForClass(PrimitiveArrayDescriptor, 'PrimitiveArrayDescriptor');
initMetadataForClass(AbstractCollectionSerializer, 'AbstractCollectionSerializer', VOID, VOID, [KSerializer]);
initMetadataForClass(CollectionLikeSerializer, 'CollectionLikeSerializer');
initMetadataForClass(CollectionSerializer, 'CollectionSerializer');
initMetadataForClass(ArrayListSerializer, 'ArrayListSerializer');
initMetadataForClass(HashSetSerializer, 'HashSetSerializer');
initMetadataForClass(LinkedHashSetSerializer, 'LinkedHashSetSerializer');
initMetadataForClass(MapLikeSerializer, 'MapLikeSerializer');
initMetadataForClass(HashMapSerializer, 'HashMapSerializer');
initMetadataForClass(LinkedHashMapSerializer, 'LinkedHashMapSerializer');
initMetadataForClass(ReferenceArraySerializer, 'ReferenceArraySerializer');
initMetadataForClass(PrimitiveArraySerializer, 'PrimitiveArraySerializer');
initMetadataForClass(PrimitiveArrayBuilder, 'PrimitiveArrayBuilder');
initMetadataForCompanion(Companion);
initMetadataForClass(ElementMarker, 'ElementMarker');
initMetadataForClass(EnumSerializer, 'EnumSerializer', VOID, VOID, [KSerializer]);
protoOf(PluginGeneratedSerialDescriptor).ojh = get_isNullable;
protoOf(PluginGeneratedSerialDescriptor).zjh = get_isInline;
initMetadataForClass(PluginGeneratedSerialDescriptor, 'PluginGeneratedSerialDescriptor', VOID, VOID, [SerialDescriptor, CachedNames]);
initMetadataForClass(EnumDescriptor, 'EnumDescriptor');
initMetadataForClass(InlineClassDescriptor, 'InlineClassDescriptor');
initMetadataForInterface(GeneratedSerializer, 'GeneratedSerializer', VOID, VOID, [KSerializer]);
protoOf(InlinePrimitiveDescriptor$1).vjp = typeParametersSerializers;
initMetadataForClass(InlinePrimitiveDescriptor$1, VOID, VOID, VOID, [GeneratedSerializer]);
protoOf(NothingSerialDescriptor).ojh = get_isNullable;
protoOf(NothingSerialDescriptor).zjh = get_isInline;
protoOf(NothingSerialDescriptor).bji = get_annotations;
initMetadataForObject(NothingSerialDescriptor, 'NothingSerialDescriptor', VOID, VOID, [SerialDescriptor]);
initMetadataForClass(NullableSerializer, 'NullableSerializer', VOID, VOID, [KSerializer]);
initMetadataForClass(SerialDescriptorForNullable, 'SerialDescriptorForNullable', VOID, VOID, [SerialDescriptor, CachedNames]);
initMetadataForClass(ObjectSerializer, 'ObjectSerializer', VOID, VOID, [KSerializer]);
initMetadataForInterface(SerializerFactory, 'SerializerFactory');
initMetadataForObject(CharArraySerializer, 'CharArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(DoubleArraySerializer, 'DoubleArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(FloatArraySerializer, 'FloatArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(LongArraySerializer, 'LongArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(ULongArraySerializer, 'ULongArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(IntArraySerializer, 'IntArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(UIntArraySerializer, 'UIntArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(ShortArraySerializer, 'ShortArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(UShortArraySerializer, 'UShortArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(ByteArraySerializer, 'ByteArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(UByteArraySerializer, 'UByteArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForObject(BooleanArraySerializer, 'BooleanArraySerializer', VOID, VOID, [KSerializer, PrimitiveArraySerializer]);
initMetadataForClass(CharArrayBuilder, 'CharArrayBuilder');
initMetadataForClass(DoubleArrayBuilder, 'DoubleArrayBuilder');
initMetadataForClass(FloatArrayBuilder, 'FloatArrayBuilder');
initMetadataForClass(LongArrayBuilder, 'LongArrayBuilder');
initMetadataForClass(ULongArrayBuilder, 'ULongArrayBuilder');
initMetadataForClass(IntArrayBuilder, 'IntArrayBuilder');
initMetadataForClass(UIntArrayBuilder, 'UIntArrayBuilder');
initMetadataForClass(ShortArrayBuilder, 'ShortArrayBuilder');
initMetadataForClass(UShortArrayBuilder, 'UShortArrayBuilder');
initMetadataForClass(ByteArrayBuilder, 'ByteArrayBuilder');
initMetadataForClass(UByteArrayBuilder, 'UByteArrayBuilder');
initMetadataForClass(BooleanArrayBuilder, 'BooleanArrayBuilder');
initMetadataForObject(StringSerializer, 'StringSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(CharSerializer, 'CharSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(DoubleSerializer, 'DoubleSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(FloatSerializer, 'FloatSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(LongSerializer, 'LongSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(IntSerializer, 'IntSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(ShortSerializer, 'ShortSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(ByteSerializer, 'ByteSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(BooleanSerializer, 'BooleanSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(UnitSerializer, 'UnitSerializer', VOID, VOID, [KSerializer]);
protoOf(PrimitiveSerialDescriptor).ojh = get_isNullable;
protoOf(PrimitiveSerialDescriptor).zjh = get_isInline;
protoOf(PrimitiveSerialDescriptor).bji = get_annotations;
initMetadataForClass(PrimitiveSerialDescriptor, 'PrimitiveSerialDescriptor', VOID, VOID, [SerialDescriptor]);
protoOf(TaggedDecoder).cjk = decodeSerializableElement$default;
protoOf(TaggedDecoder).ojj = decodeSerializableValue;
protoOf(TaggedDecoder).fjk = decodeSequentially;
protoOf(TaggedDecoder).hjk = decodeCollectionSize;
initMetadataForClass(TaggedDecoder, 'TaggedDecoder', VOID, VOID, [Decoder, CompositeDecoder]);
initMetadataForClass(NamedValueDecoder, 'NamedValueDecoder');
initMetadataForClass(MapEntry, 'MapEntry', VOID, VOID, [Entry]);
initMetadataForClass(KeyValueSerializer, 'KeyValueSerializer', VOID, VOID, [KSerializer]);
initMetadataForClass(MapEntrySerializer, 'MapEntrySerializer');
initMetadataForClass(PairSerializer, 'PairSerializer');
initMetadataForClass(TripleSerializer, 'TripleSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(ULongSerializer, 'ULongSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(UIntSerializer, 'UIntSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(UShortSerializer, 'UShortSerializer', VOID, VOID, [KSerializer]);
initMetadataForObject(UByteSerializer, 'UByteSerializer', VOID, VOID, [KSerializer]);
initMetadataForClass(SerializersModule, 'SerializersModule');
initMetadataForClass(SerialModuleImpl, 'SerialModuleImpl');
initMetadataForClass(ContextualProvider, 'ContextualProvider');
initMetadataForClass(Argless, 'Argless');
initMetadataForClass(WithTypeArguments, 'WithTypeArguments');
initMetadataForInterface(SerializersModuleCollector, 'SerializersModuleCollector');
initMetadataForClass(SerializableWith, 'SerializableWith', VOID, VOID, VOID, VOID, 0);
initMetadataForClass(createCache$1);
initMetadataForClass(createParametrizedCache$1);
//endregion
//region block: exports
export {
  SEALED_getInstance as SEALED_getInstance15u0wl5f5h4qk,
  STRING_getInstance as STRING_getInstance2gbamclnmtzqa,
  CONTEXTUAL_getInstance as CONTEXTUAL_getInstance40twmib99zqv,
  ENUM_getInstance as ENUM_getInstance3doea03ve3rgg,
  CLASS_getInstance as CLASS_getInstance1ss90e870vddp,
  LIST_getInstance as LIST_getInstance3iji3zetpdcxm,
  MAP_getInstance as MAP_getInstance2l4ulrz8ljw5i,
  BooleanSerializer_getInstance as BooleanSerializer_getInstancet3wtk7fl7i4f,
  DoubleSerializer_getInstance as DoubleSerializer_getInstance2avi1jm48x8le,
  FloatArraySerializer_getInstance as FloatArraySerializer_getInstance6swinqh9bas6,
  FloatSerializer_getInstance as FloatSerializer_getInstance1vxxfqtcay8g1,
  IntArraySerializer_getInstance as IntArraySerializer_getInstance23lwf0o9n75js,
  IntSerializer_getInstance as IntSerializer_getInstance9scrtcljaiv6,
  StringSerializer_getInstance as StringSerializer_getInstance1k1oz5j50sfik,
  ListSerializer as ListSerializer1hxuk9dx5n9du,
  MapSerializer as MapSerializer11kmegt3g5c1g,
  get_nullable as get_nullable197rfua9r7fsz,
  serializer_0 as serializer1x79l67jvwntn,
  serializer_9 as serializer1q7c5q67ysppr,
  serializer_7 as serializer3ikrxnm8b29d6,
  serializer_11 as serializer36584sjyg5661,
  serializer_5 as serializer2lw83vwvpnyms,
  PolymorphicKind as PolymorphicKindla9gurooefwb,
  PrimitiveKind as PrimitiveKindndgbuh6is7ze,
  PrimitiveSerialDescriptor_0 as PrimitiveSerialDescriptor3egfp53lutxj2,
  get_annotations as get_annotationshjxdbdcl8kmv,
  get_isInline as get_isInline5x26qrhi9qs6,
  get_isNullable as get_isNullable36pbikm8xb7bz,
  SerialDescriptor as SerialDescriptor2pelqekb5ic3a,
  ENUM as ENUMlmq49cvwy4ow,
  buildSerialDescriptor as buildSerialDescriptor2873qmkp8r2ib,
  getContextualDescriptor as getContextualDescriptor2n1gf3b895yb8,
  AbstractDecoder as AbstractDecoder35guh02ubh2hm,
  CompositeDecoder as CompositeDecoder2tzm7wpwkr0og,
  Decoder as Decoder23nde051s631g,
  AbstractPolymorphicSerializer as AbstractPolymorphicSerializer1ccxwp48nfy58,
  ArrayListSerializer as ArrayListSerializer7k5wnrulb3y6,
  ElementMarker as ElementMarker33ojvsajwmzts,
  typeParametersSerializers as typeParametersSerializers2likxjr48tr7y,
  GeneratedSerializer as GeneratedSerializer1f7t7hssdd2ws,
  InlineClassDescriptor as InlineClassDescriptor2odlvyasjrmr0,
  InlinePrimitiveDescriptor as InlinePrimitiveDescriptor3i6ccn1a4fw94,
  LinkedHashMapSerializer as LinkedHashMapSerializermaoj2nyji7op,
  LinkedHashSetSerializer as LinkedHashSetSerializer3ncla559t2lx7,
  NamedValueDecoder as NamedValueDecoderzk26ztf92xbq,
  PluginGeneratedSerialDescriptor as PluginGeneratedSerialDescriptorqdzeg5asqhfg,
  SerializerFactory as SerializerFactory1qv9hivitncuv,
  createSimpleEnumSerializer as createSimpleEnumSerializer2guioz11kk1m0,
  jsonCachedSerialNames as jsonCachedSerialNameslxufy2gu43jt,
  throwMissingFieldException as throwMissingFieldException2cmke0v3ynf14,
  EmptySerializersModule_0 as EmptySerializersModule991ju6pz9b79,
  contextual as contextual3hpp1gupsu4al,
  SerializersModuleCollector as SerializersModuleCollector3dddz14wd7brg,
  DeserializationStrategy as DeserializationStrategy1z3z5pj9f7zc8,
  KSerializer as KSerializerzf77vz1967fq,
  MissingFieldException as MissingFieldException24tqif29emcmi,
  PolymorphicSerializer as PolymorphicSerializer3p3fzpdobi8hh,
  SerializationException as SerializationExceptioneqrdve3ts2n9,
  UnknownFieldException as UnknownFieldExceptiona60e3a6v1xqo,
  findPolymorphicSerializer as findPolymorphicSerializer1nm87hvemahcj,
  serializer as serializer1i4e9ym37oxmo,
};
//endregion
