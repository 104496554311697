import {
  CoroutineScopelux7s7zphw7e as CoroutineScope,
  CoroutineStart_UNDISPATCHED_getInstance1s89xhsoy2cne as CoroutineStart_UNDISPATCHED_getInstance,
  launch1c91vkjzdi9sd as launch,
  CompletableDeferred2lnqvsbvx74d3 as CompletableDeferred,
  asyncz02dsa2nb2zt as async,
  CoroutineScopefcb5f5dwqcas as CoroutineScope_0,
  completeWith2hvdlbg1q1mlx as completeWith,
  withContext2i47m7ae4v1sd as withContext,
  asFlow3ngsnn5xpz8pw as asFlow,
  delayop3h8lf643wg as delay,
  await2pwm8za8tntlx as await_0,
  FlowCollector26clgpmzihvke as FlowCollector,
  flow3tazazxj2t7g4 as flow,
  Deferred6wjx2us769ee as Deferred,
} from './kotlinx-coroutines-core.mjs';
import {
  startCoroutine1brije5juseye as startCoroutine,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  Unit_instance104q5opgivhr8 as Unit_instance,
  printStackTrace18lnx7a39cni as printStackTrace,
  CancellationException3b36o9qz53rgr as CancellationException,
  suspendOrReturn39deou0cu44h3 as suspendOrReturn,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isInterface3d6p8outrmvmk as isInterface,
  initMetadataForLambda3af3he42mmnh as initMetadataForLambda,
  VOID7hggqo3abtya as VOID,
  Continuation1aa2oekvx7jm7 as Continuation,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  Result__exceptionOrNull_impl_p6xea91phgcskd46fkx as Result__exceptionOrNull_impl_p6xea9,
  _Result___get_isFailure__impl__jpiriv1ffgeoyqwuzmr as _Result___get_isFailure__impl__jpiriv,
  KProperty02ce7r476m8633 as KProperty0,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  DurationUnit_SECONDS_getInstance3jias9ne5z4er as DurationUnit_SECONDS_getInstance,
  toDuration28gf6ughsr3vf as toDuration,
  lazy2hsh8ze7j6ikd as lazy,
  protoOf180f3jzyo7rfj as protoOf,
  toLongw1zpgk99d84b as toLong,
  Long2qws0ah9gnpki as Long,
  ArrayList3it5z8td81qkl as ArrayList,
  emptyMapr06gerzljqtm as emptyMap,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  endsWith3cq61xxngobwh as endsWith,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  charArrayOf27f4r3dozbrk1 as charArrayOf,
  trimEndvvzjdhan75g as trimEnd,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  KtList3hktaavzmj137 as KtList,
  toString1pkumu07cwy4m as toString,
  NotImplementedErrorfzlkpv14xxr8 as NotImplementedError,
  removeAll3o43e67jmwdpc as removeAll,
  sortWith4fnm6b3vw03s as sortWith,
  Comparator2b3maoeh98xtg as Comparator,
  compareValues1n2ayl87ihzfk as compareValues,
  to2cs3ny02qtbcb as to,
  startsWith26w8qjqapeeq6 as startsWith,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  until32cjhie5unyfs as until,
  toString30pk9tzaqopn as toString_0,
  RuntimeException1r3t0zl97011n as RuntimeException,
  toLongOrNullutqivezb0wx1 as toLongOrNull,
  Exceptiondt2hlxn7j7vw as Exception,
  stackTraceToString2670q6lbhdojj as stackTraceToString,
  listOfvhqybd2zx248 as listOf,
  listOf1jh22dvmctj1r as listOf_0,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  Regexxgw0gjiagf4z as Regex,
  substringBefore3n7kj60w69hju as substringBefore,
  dropLastlqc2oyv04br0 as dropLast,
  split2bvyvnrlcifjv as split,
  getOrNull1go7ef9ldk0df as getOrNull,
  substringAfter1hku067gwr5ve as substringAfter,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  toIntOrNull3w2d066r9pvwm as toIntOrNull,
  substringBeforekje8w2lxhyb6 as substringBefore_0,
  contains3ue2qo8xhmpf1 as contains,
  isBlank1dvkhjjvox3p0 as isBlank,
  substringBeforeLastqh7oeuvefdek as substringBeforeLast,
  trimStart1mkod6gyztuyy as trimStart,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  Char__toInt_impl_vasixd1g1ubcha2b2yr as Char__toInt_impl_vasixd,
  toByte4i43936u611k as toByte,
  toInt5qdj874w69jh as toInt,
  StringBuildermazzzhj6kkai as StringBuilder,
  getBooleanHashCode1bbj3u6b3v0a7 as getBooleanHashCode,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  Char19o2r8palgjof as Char,
  split3d3yeauc4rm2n as split_0,
  toMutableList20rdgwi7d3cwi as toMutableList,
  toString26mv3gzfo7fn as toString_1,
  joinToString1cxrrlmo0chqs as joinToString,
  KProperty1ca4yb4wlo496 as KProperty1,
  toMap1vec9topfei08 as toMap,
  Enum3alwj03lh1n41 as Enum,
  captureStack1fzi4aczwc4hg as captureStack,
  createThis2j2avj17cvnv2 as createThis,
  toList383f556t1dixk as toList,
  equals2v6cggk171b6e as equals,
  first58ocm7j58k3q as first,
  sortedWith2csnbbb21k0lg as sortedWith,
  toSet2orjxp16sotqu as toSet,
  toList3jhuyej2anx2q as toList_0,
  plus310ted5e4i90h as plus,
  hashCodeq5arwsb9dgti as hashCode,
  equals2au1ep9vhcato as equals_0,
  Iterable1y3qizuf89iou as Iterable,
  isCharSequence1ju9jr1w86plq as isCharSequence,
  trim11nh7r46at6sx as trim,
  Collection1k04j3hzsbod0 as Collection,
  linkedMapOf1is69t4zkcfr3 as linkedMapOf,
  initMetadataForObject1cxne3s9w65el as initMetadataForObject,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toIntOrNull1j8dcc6to13o4 as toIntOrNull_0,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  Companion_getInstance3kme2txpd8mob as Companion_getInstance,
  charArray2ujmm1qusno00 as charArray,
  StringCompanionObject_instance2odz3s3zbrixa as StringCompanionObject_instance,
  println2shhhgwwt4c61 as println,
  Companion_instance3fplhgf4ipvld as Companion_instance,
  _Result___init__impl__xyqfz831xktsyjq1qrq as _Result___init__impl__xyqfz8,
  createFailure8paxfkfa5dc7 as createFailure,
  emptyList1g2z5xcrvp2zy as emptyList,
  EmptyCoroutineContext_getInstance2bxophqwsfm9n as EmptyCoroutineContext_getInstance,
  numberToLong1a4cndvg6c52s as numberToLong,
  newThrowablezl37abp36p5f as newThrowable,
  numberToInt1ygmcfwhs2fkq as numberToInt,
  isByteArray4nnzfn1x4o3w as isByteArray,
  drop258un2a8hqa2a as drop,
  lastOrNull1aq5oz189qoe1 as lastOrNull,
  lines3g90sq0zeq43v as lines,
  trimEnd17pt8cbotbalj as trimEnd_0,
  substringAfterLast3r0t0my8cpqhk as substringAfterLast,
  firstOrNull1982767dljvdy as firstOrNull,
  contains2el4s70rdq4ld as contains_0,
  arrayListOf1fz8nib0ncbow as arrayListOf,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Companion_instance21jklwk36y3dn as Companion_instance_0,
  FileNotFoundException2tz8ve019qipn as FileNotFoundException,
  Companion_getInstance4j5fpzk5rb8p as Companion_getInstance_0,
  unsupported198cz39rr1ztw as unsupported,
  invalidOp1stebxh2m3n07 as invalidOp,
  MalformedInputException12cxjt7zq8psv as MalformedInputException,
  ByteArrayBuilderyrflhqjz64ju as ByteArrayBuilder,
  IOException33c9ezfvq1zr9 as IOException,
  jsObjectr1r5ao37ef4f as jsObject,
  get_Symbol_asyncIterator2fclltohy3z7y as get_Symbol_asyncIterator,
  get_jsGlobalThis3odmbn60utci as get_jsGlobalThis,
  jsNewco39te1yl3up as jsNew,
} from './korlibs-korlibs-platform.mjs';
import {
  Level_ERROR_getInstance3sgixyayx9gy9 as Level_ERROR_getInstance,
  Companion_getInstance1lqql7kjbk545 as Companion_getInstance_1,
  Console_getInstance25u15nzhjnxu2 as Console_getInstance,
  Level_INFO_getInstance2ziz2o3m3loni as Level_INFO_getInstance,
} from './korlibs-korlibs-logger.mjs';
import {
  Proxy2ri7nr2ijtx4g as Proxy,
  VfsOpenMode_READ_getInstance21wyhaci8ji56 as VfsOpenMode_READ_getInstance,
  VfsCachedStatContext2gte5m09f8ox9 as VfsCachedStatContext,
  _PathInfo___init__impl__vpw96a3g5f537ih73ch as _PathInfo___init__impl__vpw96a,
  normalize34ppus0f5oo02 as normalize,
  get_baseName7iz3sifr1imo as get_baseName,
  get_parent1emraos91g459 as get_parent,
  _PathInfo___get_fullPath__impl__21fx6xt8vitrwzrja3 as _PathInfo___get_fullPath__impl__21fx6x,
  get_baseName2yf63efofm8te as get_baseName_0,
  get_pathInfo26tycr2r540oz as get_pathInfo,
  Companion_instance18o5i5v0om74a as Companion_instance_1,
  UrlVfs292f1sja450ff as UrlVfs,
  Vfs3mgmizq3oi7gy as Vfs,
  VfsFile5y6qnpu2qri3 as VfsFile,
} from './korlibs-korlibs-io-vfs.mjs';
import {
  openAsync37anb1e84urmo as openAsync,
  toAsyncStream3ow20wz4trxzb as toAsyncStream,
  buffered29ldm4q6ez2xm as buffered,
  AsyncStreamBaseze8sfl15nwf0 as AsyncStreamBase,
  readAllc0x1zv8qqb58 as readAll,
  AsyncStream2lau9k1bvym4m as AsyncStream,
  asyncStreamWriter29xletsdir5u as asyncStreamWriter,
  readLine2x7tamdjml2pc as readLine,
  readBytesExactclsk6o5m4pur as readBytesExact,
  AsyncOutputStreamkoco1dsjo5gm as AsyncOutputStream,
} from './korlibs-korlibs-io-stream.mjs';
import { Companion_getInstance1ijxhf2kvgag6 as Companion_getInstance_2 } from './korlibs-korlibs-serialization.mjs';
import {
  get_dynw5edstkdie96 as get_dyn,
  _Dyn___get_value__impl__bemnlg289snf7781s03 as _Dyn___get_value__impl__bemnlg,
  _Dyn___get_keys__impl__o4xmorpcvsvkc7z1k1 as _Dyn___get_keys__impl__o4xmor,
  _Dyn___get_str__impl__dup4cs3qkjliiqi5l9f as _Dyn___get_str__impl__dup4cs,
  Dyn__get_impl_6l2p8012yk0oj6467m4 as Dyn__get_impl_6l2p80,
  Dyn__get_impl_6l2p806an9lmzdx97q as Dyn__get_impl_6l2p80_0,
  Dyn__toLongOrNull_impl_ws7y8juw8f44nyez8r as Dyn__toLongOrNull_impl_ws7y8j,
  _Dyn___get_list__impl__qpwjpy1x88d8kx8tg as _Dyn___get_list__impl__qpwjp,
  _Dyn___get_bool__impl__nv3iun3b5fbq0u806ya as _Dyn___get_bool__impl__nv3iun,
  _Dyn___get_long__impl__wdxl7n30zdp3a58g184 as _Dyn___get_long__impl__wdxl7n,
} from './korlibs-korlibs-dyn.mjs';
import {
  Companion_getInstance3moaeo8p2fvjj as Companion_getInstance_3,
  _FastDuration___get_milliseconds__impl__ffr17uhz706a3hbxx1 as _FastDuration___get_milliseconds__impl__ffr17u,
} from './korlibs-korlibs-time-core.mjs';
import {
  FastStringMap1l1uzdhsfbatm as FastStringMap,
  linkedHashMapOf2w2uhgrq4khrk as linkedHashMapOf,
} from './korlibs-korlibs-datastructure.mjs';
import {
  substringAfterOrNull6pqoc8ojvu1x as substringAfterOrNull,
  substringBeforeOrNullyyd9hl5du3pj as substringBeforeOrNull,
  substr1q77u5lo4evr6 as substr,
  toString2x583ovdqfjci as toString_2,
  get_UTF82geyfhi1l22xl as get_UTF8,
  INDENTS_getInstance1gue9qeyuufz7 as INDENTS_getInstance,
  substringEqualse7vi4930fev5 as substringEquals,
  skip$default8gbsogngr8nx as skip$default,
  toStringContext20qw1vqodfj0k as toStringContext,
  SimpleStrReader35udnj8g5ojo6 as SimpleStrReader,
} from './korlibs-korlibs-string.mjs';
import {
  Companion_getInstance1ybjf5xfi0it8 as Companion_getInstance_4,
  uncompressed2homtbws7svq9 as uncompressed,
  get_Deflate2a0lyute7oxjd as get_Deflate,
} from './korlibs-korlibs-compression.mjs';
import { Companion_getInstanceac8g91hxvmd4 as Companion_getInstance_5 } from './korlibs-korlibs-io-network-core.mjs';
import { arraycopy3czrd9re36wzt as arraycopy } from './korlibs-korlibs-memory.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class _launch$slambda {
  constructor($callback) {
    this.n3h_1 = $callback;
  }
  o1s($this$launch, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8(this, $this$launch, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class launchUnscopedAndWait$1 {
  constructor($this_launchUnscopedAndWait, $deferred) {
    this.p3h_1 = $deferred;
    this.o3h_1 = $this_launchUnscopedAndWait;
  }
  gd() {
    return this.o3h_1;
  }
  id(result) {
    completeWith(this.p3h_1, result);
  }
}
class launchUnscoped$1 {
  constructor($this_launchUnscoped) {
    this.q3h_1 = $this_launchUnscoped;
  }
  gd() {
    return this.q3h_1;
  }
  zm(result) {
    if (_Result___get_isFailure__impl__jpiriv(result)) {
      var tmp0_safe_receiver = Result__exceptionOrNull_impl_p6xea9(result);
      if (tmp0_safe_receiver == null)
        null;
      else {
        printStackTrace(tmp0_safe_receiver);
      }
    }
  }
  id(result) {
    return this.zm(result);
  }
}
class _async$slambda {
  constructor($callback) {
    this.r3h_1 = $callback;
  }
  o1s($this$async, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_0(this, $this$async, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class CatalogVfs$open$slambda {
  constructor(this$0, $path, $mode) {
    this.s3h_1 = this$0;
    this.t3h_1 = $path;
    this.u3h_1 = $mode;
  }
  o1s($this$withContext, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_1(this, $this$withContext, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.o1s((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class CatalogVfs$open$2 extends AsyncStreamBase {
  static f3i(this$0, $base, $maxSize, $box) {
    if ($box === VOID)
      $box = {};
    $box.v3h_1 = this$0;
    $box.w3h_1 = $base;
    $box.x3h_1 = $maxSize;
    return this.v1s($box);
  }
  toString() {
    return 'CatalogVfs@' + this.v3h_1.z3h_1.toString() + ':' + toString(this.w3h_1);
  }
  w1s(position, buffer, offset, len, $completion) {
    // Inline function 'kotlin.comparisons.minOf' call
    var a = toLong(len);
    var b = this.x3h_1._v.h2(position);
    var rlen = (a.g2(b) <= 0 ? a : b).q2();
    return this.w3h_1.w1s(position, buffer, offset, rlen, $completion);
  }
  x1s(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l(this, position, buffer, offset, len, $completion), $completion);
  }
  y1s($completion) {
    return this.x3h_1._v;
  }
  f1q($completion) {
    return this.w3h_1.f1q($completion);
  }
}
class CatalogVfs extends Proxy {
  constructor(parent) {
    super();
    this.z3h_1 = parent;
    this.a3i_1 = new Long(2097152, 0);
    this.b3i_1 = Companion_getInstance_1().ez('CatalogVfs');
    this.c3i_1 = LinkedHashMap.vc();
    this.d3i_1 = FastStringMap();
  }
  b22(path, range, $completion) {
    return super.b22(path, range, $completion);
  }
  z21(path, mode, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_open__qjxcea(this, path, mode, $completion), $completion);
  }
  p21(path, $completion) {
    return this.z3h_1.bj(path);
  }
  d22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_stat__qhnowc(this, path, $completion), $completion);
  }
  e3i(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_statOrNull__vj5496(this, path, $completion), $completion);
  }
  a22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_listFlow__eqlpjw(this, path, $completion), $completion);
  }
  h3i(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_cachedListSimpleStats__uxxayh(this, path, $completion), $completion);
  }
  g3i(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_cachedListSimpleStatsOrNull__bbx84j(this, path, $completion), $completion);
  }
  i3i(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_listSimpleStatsOrNull__lelcpx(this, path, $completion), $completion);
  }
}
class StandardVfs {
  j3i() {
    throw NotImplementedError.oe();
  }
}
class sam$kotlin_Comparator$0 {
  constructor(function_0) {
    this.n3i_1 = function_0;
  }
  rj(a, b) {
    return this.n3i_1(a, b);
  }
  compare(a, b) {
    return this.rj(a, b);
  }
}
class MountableVfs extends Proxy {
  constructor(closeMounts) {
    super();
    this.l3i_1 = closeMounts;
    this.m3i_1 = ArrayList.m1();
  }
  f1q($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_close__nh2uv0(this, $completion), $completion);
  }
  o3i(folder, file) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.std.MountableVfs.mount.<anonymous>' call
    unmountInternal(this, folder);
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.m3i_1;
    var element = to(normalize(get_pathInfo(folder)), file);
    this_0.f(element);
    resort(this);
    return this;
  }
  p21(path, $completion) {
    var rpath = normalize(get_pathInfo(path));
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.m3i_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.io.file.std.MountableVfs.access.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var destruct = this_0.i1(_unary__edvuaz);
      var base = destruct.km();
      var file = destruct.lm();
      if (startsWith(rpath, base)) {
        // Inline function 'kotlin.text.substring' call
        var startIndex = base.length;
        // Inline function 'kotlin.js.asDynamic' call
        var tmp$ret$1 = rpath.substring(startIndex);
        return file.bj(tmp$ret$1);
      }
    }
    throw FileNotFoundException.hx(path);
  }
  toString() {
    return 'MountableVfs';
  }
}
class StandardPathsBase {}
function appPreferencesFolder(appId) {
  return Companion_getInstance_0().mw() ? '/Users/' + Companion_instance_0.bj('USER') + '/Library/Preferences/' + appId : Companion_getInstance_0().jw() ? '' + Companion_instance_0.bj('APPDATA') + '/' + appId : '' + Companion_instance_0.bj('HOME') + '/.config/' + appId;
}
class HttpHeaders {}
class FinalUrlVfs$open$2 extends AsyncStreamBase {
  static x3j(this$0, $path, $mode, $fullUrl, $stat, $box) {
    if ($box === VOID)
      $box = {};
    $box.e3j_1 = this$0;
    $box.f3j_1 = $path;
    $box.g3j_1 = $mode;
    $box.h3j_1 = $fullUrl;
    $box.i3j_1 = $stat;
    return this.v1s($box);
  }
  toString() {
    return 'UrlVfs:' + this.e3j_1.k24_1 + ':' + this.f3j_1 + ':' + this.g3j_1.toString();
  }
  w1s(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe(this, position, buffer, offset, len, $completion), $completion);
  }
  z1s($completion) {
    return this.i3j_1.l20_1.g2(new Long(0, 0)) >= 0;
  }
  y1s($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_getLength__lvva94(this, $completion), $completion);
  }
}
class FinalUrlVfs extends UrlVfs {
  constructor(url, dummy, client, failFromStatus) {
    client = client === VOID ? createHttpClient() : client;
    failFromStatus = failFromStatus === VOID ? true : failFromStatus;
    super(url, dummy, failFromStatus);
    this.t3j_1 = dummy;
    this.u3j_1 = client;
    this.v3j_1 = url;
  }
  b21() {
    return this.v3j_1;
  }
  z21(path, mode, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_open__qjxcea_0(this, path, mode, $completion), $completion);
  }
  b22(path, range, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readRange__lxd64t(this, path, range, $completion), $completion);
  }
  c22(path, content, attributes, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_put__cb93wb(this, path, content, attributes, $completion), $completion);
  }
  d22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_stat__qhnowc_0(this, path, $completion), $completion);
  }
  a22(path, $completion) {
    unsupported();
  }
  toString() {
    return 'UrlVfs';
  }
}
class Companion {
  constructor() {
    Companion_instance_2 = this;
    this.n3k_1 = new MimeType('application/octet-stream', listOf('bin'));
    this.o3k_1 = new MimeType('application/json', listOf('json'));
    this.p3k_1 = new MimeType('image/png', listOf('png'));
    this.q3k_1 = new MimeType('image/jpeg', listOf_0(['jpg', 'jpeg']));
    this.r3k_1 = new MimeType('image/gif', listOf('gif'));
    this.s3k_1 = new MimeType('text/html', listOf_0(['htm', 'html']));
    this.t3k_1 = new MimeType('text/plain', listOf_0(['txt', 'text']));
    this.u3k_1 = new MimeType('text/css', listOf('css'));
    this.v3k_1 = new MimeType('application/javascript', listOf('js'));
    this.f3l([this.n3k_1, this.o3k_1, this.p3k_1, this.q3k_1, this.r3k_1, this.s3k_1, this.t3k_1, this.u3k_1, this.v3k_1]);
  }
  g3l(mimeType) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = mimeType.b3l_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.io.net.Companion.register.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var ext = this_0.i1(_unary__edvuaz);
      // Inline function 'kotlin.collections.set' call
      get_MimeType_byExtensions().r3(ext, mimeType);
    }
  }
  f3l(mimeTypes) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < mimeTypes.length) {
      // Inline function 'korlibs.io.net.Companion.register.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var mt = mimeTypes[_unary__edvuaz];
      Companion_getInstance_6().g3l(mt);
    }
  }
}
class MimeType {
  constructor(mime, exts) {
    Companion_getInstance_6();
    this.a3l_1 = mime;
    this.b3l_1 = exts;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_3 = this;
    this.d3l_1 = 0;
    this.e3l_1 = Regex.ni('^([a-zA-Z0-9+.-]+)(?::([a-zA-Z]+))?:');
  }
  h3l(scheme) {
    switch (scheme) {
      case 'ftp':
        return 21;
      case 'ftps':
        return 990;
      case 'http':
      case 'ws':
        return 80;
      case 'https':
      case 'wss':
        return 443;
      default:
        return -1;
    }
  }
  i3l(scheme, subScheme, userInfo, host, path, query, fragment, opaque, port) {
    var tmp;
    if (scheme == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.text.lowercase' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = scheme.toLowerCase();
    }
    var tmp_0 = tmp;
    var tmp_1;
    if (subScheme == null) {
      tmp_1 = null;
    } else {
      // Inline function 'kotlin.text.lowercase' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp_1 = subScheme.toLowerCase();
    }
    return new URL_0(opaque, tmp_0, tmp_1, userInfo, host, path, query, fragment, port);
  }
  j3l(scheme, subScheme, userInfo, host, path, query, fragment, opaque, port, $super) {
    scheme = scheme === VOID ? null : scheme;
    subScheme = subScheme === VOID ? null : subScheme;
    userInfo = userInfo === VOID ? null : userInfo;
    host = host === VOID ? null : host;
    path = path === VOID ? '' : path;
    query = query === VOID ? null : query;
    fragment = fragment === VOID ? null : fragment;
    opaque = opaque === VOID ? false : opaque;
    port = port === VOID ? this.d3l_1 : port;
    return $super === VOID ? this.i3l(scheme, subScheme, userInfo, host, path, query, fragment, opaque, port) : $super.i3l.call(this, scheme, subScheme, userInfo, host, path, query, fragment, opaque, port);
  }
  ez(url) {
    var r = new StrReader(url);
    var schemeColon = r.p3l(this.e3l_1);
    var tmp;
    if (!(schemeColon == null)) {
      var isHierarchical = !(r.q3l('//') == null);
      var nonScheme = r.r3l();
      var schemeParts = split(dropLast(schemeColon, 1), [':']);
      var scheme = schemeParts.i1(0);
      var subScheme = getOrNull(schemeParts, 1);
      var nonFragment = substringBefore(nonScheme, _Char___init__impl__6a9atx(35));
      var fragment = substringAfterOrNull(nonScheme, _Char___init__impl__6a9atx(35));
      var nonQuery = substringBefore(nonFragment, _Char___init__impl__6a9atx(63));
      var query = substringAfterOrNull(nonFragment, _Char___init__impl__6a9atx(63));
      var authority = substringBefore(nonQuery, _Char___init__impl__6a9atx(47));
      var path = substringAfterOrNull(nonQuery, _Char___init__impl__6a9atx(47));
      var hostWithPort = substringAfter(authority, _Char___init__impl__6a9atx(64));
      var userInfo = substringBeforeOrNull(authority, _Char___init__impl__6a9atx(64));
      var host = substringBefore(hostWithPort, _Char___init__impl__6a9atx(58));
      var port = substringAfterOrNull(hostWithPort, _Char___init__impl__6a9atx(58));
      var tmp34_opaque = !isHierarchical;
      // Inline function 'kotlin.takeIf' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp_0;
      // Inline function 'korlibs.io.net.Companion.invoke.<anonymous>' call
      // Inline function 'kotlin.text.isNotEmpty' call
      if (charSequenceLength(host) > 0) {
        tmp_0 = host;
      } else {
        tmp_0 = null;
      }
      var tmp35_host = tmp_0;
      var tmp36_path = !(path == null) ? '/' + path : '';
      var tmp0_elvis_lhs = port == null ? null : toIntOrNull(port);
      var tmp37_port = tmp0_elvis_lhs == null ? this.d3l_1 : tmp0_elvis_lhs;
      tmp = this.i3l(scheme, subScheme, userInfo, tmp35_host, tmp36_path, query, fragment, tmp34_opaque, tmp37_port);
    } else {
      var nonFragment_0 = substringBefore(url, _Char___init__impl__6a9atx(35));
      var fragment_0 = substringAfterOrNull(url, _Char___init__impl__6a9atx(35));
      var path_0 = substringBefore(nonFragment_0, _Char___init__impl__6a9atx(63));
      var query_0 = substringAfterOrNull(nonFragment_0, _Char___init__impl__6a9atx(63));
      tmp = this.j3l(null, null, null, null, path_0, query_0, fragment_0, false);
    }
    return tmp;
  }
  s3l(url) {
    return !((new StrReader(url)).p3l(this.e3l_1) == null);
  }
  t3l(base, access) {
    var refinedAccess = startsWith(access, '//') && contains(base, ':') ? substringBefore_0(base, ':') + ':' + access : access;
    if (this.s3l(refinedAccess))
      return refinedAccess;
    if (isBlank(base)) {
      throw MalformedInputException.vu('The base URL should not be empty, or the access URL must be absolute.');
    }
    if (!this.s3l(base)) {
      throw MalformedInputException.vu('At least one of the base URL or access URL must be absolute.');
    }
    var tmp;
    // Inline function 'kotlin.text.isEmpty' call
    if (charSequenceLength(refinedAccess) === 0) {
      tmp = base;
    } else {
      if (startsWith(refinedAccess, '/')) {
        tmp = Companion_getInstance_7().ez(base).c3j(VOID, VOID, VOID, VOID, VOID, normalizeUrl(refinedAccess), null).d3j();
      } else {
        // Inline function 'kotlin.run' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.io.net.Companion.resolve.<anonymous>' call
        var $this$run = Companion_getInstance_7().ez(base);
        var tmp_0;
        if (startsWith(refinedAccess, '?') || startsWith(refinedAccess, '#')) {
          tmp_0 = $this$run.v3i_1 + refinedAccess;
        } else {
          tmp_0 = substringBeforeLast($this$run.v3i_1, _Char___init__impl__6a9atx(47)) + '/' + refinedAccess;
        }
        var refinedPath = tmp_0;
        tmp = $this$run.c3j(VOID, VOID, VOID, VOID, VOID, '/' + trimStart(normalizeUrl(refinedPath), charArrayOf([_Char___init__impl__6a9atx(47)])), null).d3j();
      }
    }
    return tmp;
  }
  u3l(s, charset, formUrlEncoded) {
    var bos = ByteArrayBuilder.mv();
    var len = s.length;
    var n = 0;
    while (n < len) {
      var c = charSequenceGet(s, n);
      if (c === _Char___init__impl__6a9atx(37)) {
        // Inline function 'kotlin.also' call
        // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
        var v = toByte(toInt(substr(s, n + 1 | 0, 2), 16));
        bos.qv(v);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.io.net.Companion.decodeComponent.<anonymous>' call
        n = n + 2 | 0;
      } else if (c === _Char___init__impl__6a9atx(43)) {
        // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
        var v_0 = formUrlEncoded ? 32 : 43;
        bos.qv(v_0);
      } else {
        // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
        // Inline function 'kotlin.code' call
        var tmp$ret$3 = Char__toInt_impl_vasixd(c);
        var v_1 = toByte(tmp$ret$3);
        bos.qv(v_1);
      }
      n = n + 1 | 0;
    }
    return toString_2(bos.rv(), charset);
  }
  v3l(s, charset, formUrlEncoded, $super) {
    charset = charset === VOID ? get_UTF8() : charset;
    formUrlEncoded = formUrlEncoded === VOID ? false : formUrlEncoded;
    return $super === VOID ? this.u3l(s, charset, formUrlEncoded) : $super.u3l.call(this, s, charset, formUrlEncoded);
  }
}
class URL_0 {
  constructor(isOpaque, scheme, subScheme, userInfo, host, path, query, fragment, defaultPort) {
    Companion_getInstance_7();
    this.q3i_1 = isOpaque;
    this.r3i_1 = scheme;
    this.s3i_1 = subScheme;
    this.t3i_1 = userInfo;
    this.u3i_1 = host;
    this.v3i_1 = path;
    this.w3i_1 = query;
    this.x3i_1 = fragment;
    this.y3i_1 = defaultPort;
    var tmp = this;
    tmp.z3i_1 = lazy(URL$fullUrl$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.a3j_1 = lazy(URL$fullUrlWithoutScheme$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.b3j_1 = lazy(URL$pathWithQuery$delegate$lambda(this));
  }
  x3l() {
    return this.r3i_1 === 'https' || this.r3i_1 === 'wss' || this.r3i_1 === 'ftps';
  }
  y3l() {
    return Companion_getInstance_7().h3l(this.r3i_1);
  }
  z3l() {
    return this.y3i_1 === Companion_getInstance_7().d3l_1 ? this.y3l() : this.y3i_1;
  }
  d3j() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.z3i_1;
    fullUrl$factory();
    return this_0.b2();
  }
  a3m(includeScheme, out) {
    if (includeScheme && !(this.r3i_1 == null)) {
      out.ic('' + this.r3i_1 + ':');
      if (!(this.s3i_1 == null)) {
        out.ic('' + this.s3i_1 + ':');
      }
      if (!this.q3i_1) {
        out.ic('//');
      }
    }
    if (!(this.t3i_1 == null)) {
      out.ic('' + this.t3i_1 + '@');
    }
    if (!(this.u3i_1 == null)) {
      out.ic(this.u3i_1);
    }
    if (!(this.z3l() === Companion_getInstance_7().d3l_1) && !(this.z3l() === this.y3l())) {
      out.jc(_Char___init__impl__6a9atx(58)).oh(this.z3l());
    }
    out.ic(this.v3i_1);
    if (!(this.w3i_1 == null)) {
      out.ic('?' + this.w3i_1);
    }
    if (!(this.x3i_1 == null)) {
      out.ic('#' + this.x3i_1);
    }
    return out;
  }
  w3l(includeScheme, out, $super) {
    includeScheme = includeScheme === VOID ? true : includeScheme;
    out = out === VOID ? StringBuilder.h() : out;
    return $super === VOID ? this.a3m(includeScheme, out) : $super.a3m.call(this, includeScheme, out);
  }
  toString() {
    return this.d3j();
  }
  b3m(isOpaque, scheme, subScheme, userInfo, host, path, query, fragment, defaultPort) {
    return new URL_0(isOpaque, scheme, subScheme, userInfo, host, path, query, fragment, defaultPort);
  }
  c3j(isOpaque, scheme, subScheme, userInfo, host, path, query, fragment, defaultPort, $super) {
    isOpaque = isOpaque === VOID ? this.q3i_1 : isOpaque;
    scheme = scheme === VOID ? this.r3i_1 : scheme;
    subScheme = subScheme === VOID ? this.s3i_1 : subScheme;
    userInfo = userInfo === VOID ? this.t3i_1 : userInfo;
    host = host === VOID ? this.u3i_1 : host;
    path = path === VOID ? this.v3i_1 : path;
    query = query === VOID ? this.w3i_1 : query;
    fragment = fragment === VOID ? this.x3i_1 : fragment;
    defaultPort = defaultPort === VOID ? this.y3i_1 : defaultPort;
    return $super === VOID ? this.b3m(isOpaque, scheme, subScheme, userInfo, host, path, query, fragment, defaultPort) : $super.b3m.call(this, isOpaque, scheme, subScheme, userInfo, host, path, query, fragment, defaultPort);
  }
  hashCode() {
    var result = getBooleanHashCode(this.q3i_1);
    result = imul(result, 31) + (this.r3i_1 == null ? 0 : getStringHashCode(this.r3i_1)) | 0;
    result = imul(result, 31) + (this.s3i_1 == null ? 0 : getStringHashCode(this.s3i_1)) | 0;
    result = imul(result, 31) + (this.t3i_1 == null ? 0 : getStringHashCode(this.t3i_1)) | 0;
    result = imul(result, 31) + (this.u3i_1 == null ? 0 : getStringHashCode(this.u3i_1)) | 0;
    result = imul(result, 31) + getStringHashCode(this.v3i_1) | 0;
    result = imul(result, 31) + (this.w3i_1 == null ? 0 : getStringHashCode(this.w3i_1)) | 0;
    result = imul(result, 31) + (this.x3i_1 == null ? 0 : getStringHashCode(this.x3i_1)) | 0;
    result = imul(result, 31) + this.y3i_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof URL_0))
      return false;
    var tmp0_other_with_cast = other instanceof URL_0 ? other : THROW_CCE();
    if (!(this.q3i_1 === tmp0_other_with_cast.q3i_1))
      return false;
    if (!(this.r3i_1 == tmp0_other_with_cast.r3i_1))
      return false;
    if (!(this.s3i_1 == tmp0_other_with_cast.s3i_1))
      return false;
    if (!(this.t3i_1 == tmp0_other_with_cast.t3i_1))
      return false;
    if (!(this.u3i_1 == tmp0_other_with_cast.u3i_1))
      return false;
    if (!(this.v3i_1 === tmp0_other_with_cast.v3i_1))
      return false;
    if (!(this.w3i_1 == tmp0_other_with_cast.w3i_1))
      return false;
    if (!(this.x3i_1 == tmp0_other_with_cast.x3i_1))
      return false;
    if (!(this.y3i_1 === tmp0_other_with_cast.y3i_1))
      return false;
    return true;
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_4 = this;
    this.y3j_1 = Methods_OPTIONS_getInstance();
    this.z3j_1 = Methods_GET_getInstance();
    this.a3k_1 = Methods_HEAD_getInstance();
    this.b3k_1 = Methods_POST_getInstance();
    this.c3k_1 = Methods_PUT_getInstance();
    this.d3k_1 = Methods_DELETE_getInstance();
    this.e3k_1 = Methods_TRACE_getInstance();
    this.f3k_1 = Methods_CONNECT_getInstance();
    this.g3k_1 = Methods_PATCH_getInstance();
    this.h3k_1 = listOf_0([this.y3j_1, this.z3j_1, this.a3k_1, this.b3k_1, this.c3k_1, this.d3k_1, this.e3k_1, this.f3k_1, this.g3k_1]);
    var tmp = this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = this.h3k_1;
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.io.net.http.Companion.valuesMap.<anonymous>' call
      var tmp$ret$0 = to(item.u3_1, item);
      destination.f(tmp$ret$0);
    }
    tmp.i3k_1 = toMap(destination);
  }
}
class Companion_2 {}
class Companion_3 {
  constructor() {
    this.y3k_1 = 'Content-Length';
    this.z3k_1 = 'Content-Type';
  }
}
class sam$kotlin_Comparator$0_0 {
  constructor(function_0) {
    this.c3m_1 = function_0;
  }
  rj(a, b) {
    return this.c3m_1(a, b);
  }
  compare(a, b) {
    return this.rj(a, b);
  }
}
class Methods extends Enum {}
class HttpException extends IOException {
  static k3m(statusCode, msg, statusText, headers) {
    msg = msg === VOID ? 'Error' + statusCode : msg;
    var tmp;
    if (statusText === VOID) {
      var tmp0_elvis_lhs = HttpStatusMessage_getInstance().l3m_1.k3(statusCode);
      tmp = tmp0_elvis_lhs == null ? 'Error' + statusCode : tmp0_elvis_lhs;
    } else {
      tmp = statusText;
    }
    statusText = tmp;
    headers = headers === VOID ? Headers.x3k([]) : headers;
    var $this = this.kx('' + statusCode + ' ' + statusText + ' - ' + msg);
    captureStack($this, $this.j3m_1);
    $this.f3m_1 = statusCode;
    $this.g3m_1 = msg;
    $this.h3m_1 = statusText;
    $this.i3m_1 = headers;
    return $this;
  }
}
class Headers {
  static m3m(items) {
    var $this = createThis(this);
    $this.j3k_1 = items;
    return $this;
  }
  static x3k(items) {
    return this.m3m(toList(items));
  }
  static k3k(map) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(map.j1());
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = map.z1().f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.io.net.http.Headers.<init>.<anonymous>' call
      var tmp$ret$1 = to(item.a2(), item.b2());
      destination.f(tmp$ret$1);
    }
    return this.m3m(destination);
  }
  f1() {
    return this.j3k_1.f1();
  }
  bj(key) {
    return this.n3m(key);
  }
  n3m(key) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator = this.j3k_1.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.io.net.http.Headers.getFirst.<anonymous>' call
        if (equals(element.im_1, key, true)) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    var tmp41_safe_receiver = tmp$ret$1;
    return tmp41_safe_receiver == null ? null : tmp41_safe_receiver.jm_1;
  }
  o3m() {
    // Inline function 'kotlin.collections.sortedBy' call
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.groupBy' call
    // Inline function 'kotlin.collections.groupByTo' call
    var this_0 = this.j3k_1;
    var destination = LinkedHashMap.vc();
    var tmp0_iterator = this_0.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
      // Inline function 'kotlin.text.toLowerCase' call
      // Inline function 'kotlin.js.asDynamic' call
      var key = element.im_1.toLowerCase();
      // Inline function 'kotlin.collections.getOrPut' call
      var value = destination.k3(key);
      var tmp;
      if (value == null) {
        // Inline function 'kotlin.collections.groupByTo.<anonymous>' call
        var answer = ArrayList.m1();
        destination.r3(key, answer);
        tmp = answer;
      } else {
        tmp = value;
      }
      var list = tmp;
      list.f(element);
    }
    // Inline function 'kotlin.collections.mapTo' call
    var destination_0 = ArrayList.w(destination.j1());
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator_0 = destination.z1().f1();
    while (tmp0_iterator_0.g1()) {
      var item = tmp0_iterator_0.h1();
      // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
      var tmp_0 = first(item.b2()).im_1;
      // Inline function 'kotlin.collections.map' call
      var this_1 = item.b2();
      // Inline function 'kotlin.collections.mapTo' call
      var destination_1 = ArrayList.w(collectionSizeOrDefault(this_1, 10));
      var tmp0_iterator_1 = this_1.f1();
      while (tmp0_iterator_1.g1()) {
        var item_0 = tmp0_iterator_1.h1();
        // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>.<anonymous>' call
        var tmp$ret$8 = item_0.jm_1;
        destination_1.f(tmp$ret$8);
      }
      var tmp$ret$11 = to(tmp_0, destination_1);
      destination_0.f(tmp$ret$11);
    }
    // Inline function 'kotlin.comparisons.compareBy' call
    var tmp_1 = Http$Headers$toListGrouped$lambda;
    var tmp$ret$14 = new sam$kotlin_Comparator$0_0(tmp_1);
    return sortedWith(destination_0, tmp$ret$14);
  }
  p3m(newHeaders) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(newHeaders, 10));
    var tmp0_iterator = newHeaders.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.io.net.http.Headers.withReplaceHeaders.<anonymous>' call
      // Inline function 'kotlin.text.toLowerCase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$2 = item.im_1.toLowerCase();
      destination.f(tmp$ret$2);
    }
    var replaceKeys = toSet(destination);
    // Inline function 'kotlin.collections.filter' call
    // Inline function 'kotlin.collections.filterTo' call
    var this_0 = this.j3k_1;
    var destination_0 = ArrayList.m1();
    var tmp0_iterator_0 = this_0.f1();
    while (tmp0_iterator_0.g1()) {
      var element = tmp0_iterator_0.h1();
      // Inline function 'korlibs.io.net.http.Headers.withReplaceHeaders.<anonymous>' call
      // Inline function 'kotlin.text.toLowerCase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$6 = element.im_1.toLowerCase();
      if (!replaceKeys.f3(tmp$ret$6)) {
        destination_0.f(element);
      }
    }
    return Headers.m3m(plus(destination_0, toList_0(newHeaders)));
  }
  c3l(newHeaders) {
    return this.p3m(toList(newHeaders));
  }
  q3m(newHeaders) {
    return this.p3m(toList_0(newHeaders));
  }
  toString() {
    return 'Headers(' + joinToString(this.o3m(), ', ') + ')';
  }
  hashCode() {
    return hashCode(this.j3k_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Headers))
      return false;
    var tmp0_other_with_cast = other instanceof Headers ? other : THROW_CCE();
    if (!equals_0(this.j3k_1, tmp0_other_with_cast.j3k_1))
      return false;
    return true;
  }
}
class Companion_4 {
  r3m(status, statusText, headers, rawContent, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_2(this, status, statusText, headers, rawContent, $completion), $completion);
  }
}
class Companion_5 {
  constructor() {
    Companion_instance_8 = this;
    this.u3m_1 = new RequestConfig();
  }
}
class Response {
  constructor(status, statusText, headers, rawContent, content) {
    this.j3j_1 = status;
    this.k3j_1 = statusText;
    this.l3j_1 = headers;
    this.m3j_1 = rawContent;
    this.n3j_1 = content;
    this.o3j_1 = this.j3j_1 < 400;
    var tmp = this;
    tmp.p3j_1 = lazy(HttpClient$Response$responseCharset$delegate$lambda);
  }
  s3m($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readAllBytes__ja4yyw(this, $completion), $completion);
  }
  v3m() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.p3j_1;
    responseCharset$factory();
    return this_0.b2();
  }
  w3m(charset, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_readAllString__7ikxrc(this, charset, $completion), $completion);
  }
  t3m(charset, $completion, $super) {
    charset = charset === VOID ? this.v3m() : charset;
    return $super === VOID ? this.w3m(charset, $completion) : $super.w3m.call(this, charset, $completion);
  }
  m3k($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_checkErrors__u7iznj(this, $completion), $completion);
  }
  x3m(content) {
    return new CompletedResponse(this.j3j_1, this.k3j_1, this.l3j_1, content);
  }
  toString() {
    return 'Response(status=' + this.j3j_1 + ', statusText=' + this.k3j_1 + ', headers=' + this.l3j_1.toString() + ', rawContent=' + toString(this.m3j_1) + ', content=' + toString(this.n3j_1) + ')';
  }
  hashCode() {
    var result = this.j3j_1;
    result = imul(result, 31) + getStringHashCode(this.k3j_1) | 0;
    result = imul(result, 31) + this.l3j_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.m3j_1) | 0;
    result = imul(result, 31) + hashCode(this.n3j_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Response))
      return false;
    var tmp0_other_with_cast = other instanceof Response ? other : THROW_CCE();
    if (!(this.j3j_1 === tmp0_other_with_cast.j3j_1))
      return false;
    if (!(this.k3j_1 === tmp0_other_with_cast.k3j_1))
      return false;
    if (!this.l3j_1.equals(tmp0_other_with_cast.l3j_1))
      return false;
    if (!equals_0(this.m3j_1, tmp0_other_with_cast.m3j_1))
      return false;
    if (!equals_0(this.n3j_1, tmp0_other_with_cast.n3j_1))
      return false;
    return true;
  }
}
class CompletedResponse {
  constructor(status, statusText, headers, content) {
    this.y3m_1 = status;
    this.z3m_1 = statusText;
    this.a3n_1 = headers;
    this.b3n_1 = content;
    this.c3n_1 = this.y3m_1 < 400;
  }
  toString() {
    return 'CompletedResponse(status=' + this.y3m_1 + ', statusText=' + this.z3m_1 + ', headers=' + this.a3n_1.toString() + ', content=' + toString_0(this.b3n_1) + ')';
  }
  hashCode() {
    var result = this.y3m_1;
    result = imul(result, 31) + getStringHashCode(this.z3m_1) | 0;
    result = imul(result, 31) + this.a3n_1.hashCode() | 0;
    result = imul(result, 31) + (this.b3n_1 == null ? 0 : hashCode(this.b3n_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedResponse))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedResponse ? other : THROW_CCE();
    if (!(this.y3m_1 === tmp0_other_with_cast.y3m_1))
      return false;
    if (!(this.z3m_1 === tmp0_other_with_cast.z3m_1))
      return false;
    if (!this.a3n_1.equals(tmp0_other_with_cast.a3n_1))
      return false;
    if (!equals_0(this.b3n_1, tmp0_other_with_cast.b3n_1))
      return false;
    return true;
  }
}
class RequestConfig {
  constructor(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser) {
    Companion_getInstance_12();
    followRedirects = followRedirects === VOID ? true : followRedirects;
    throwErrors = throwErrors === VOID ? false : throwErrors;
    maxRedirects = maxRedirects === VOID ? 10 : maxRedirects;
    referer = referer === VOID ? null : referer;
    simulateBrowser = simulateBrowser === VOID ? false : simulateBrowser;
    this.d3n_1 = followRedirects;
    this.e3n_1 = throwErrors;
    this.f3n_1 = maxRedirects;
    this.g3n_1 = referer;
    this.h3n_1 = simulateBrowser;
  }
  i3n(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser) {
    return new RequestConfig(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser);
  }
  j3n(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser, $super) {
    followRedirects = followRedirects === VOID ? this.d3n_1 : followRedirects;
    throwErrors = throwErrors === VOID ? this.e3n_1 : throwErrors;
    maxRedirects = maxRedirects === VOID ? this.f3n_1 : maxRedirects;
    referer = referer === VOID ? this.g3n_1 : referer;
    simulateBrowser = simulateBrowser === VOID ? this.h3n_1 : simulateBrowser;
    return $super === VOID ? this.i3n(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser) : $super.i3n.call(this, followRedirects, throwErrors, maxRedirects, referer, simulateBrowser);
  }
  toString() {
    return 'RequestConfig(followRedirects=' + this.d3n_1 + ', throwErrors=' + this.e3n_1 + ', maxRedirects=' + this.f3n_1 + ', referer=' + this.g3n_1 + ', simulateBrowser=' + this.h3n_1 + ')';
  }
  hashCode() {
    var result = getBooleanHashCode(this.d3n_1);
    result = imul(result, 31) + getBooleanHashCode(this.e3n_1) | 0;
    result = imul(result, 31) + this.f3n_1 | 0;
    result = imul(result, 31) + (this.g3n_1 == null ? 0 : getStringHashCode(this.g3n_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.h3n_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof RequestConfig))
      return false;
    var tmp0_other_with_cast = other instanceof RequestConfig ? other : THROW_CCE();
    if (!(this.d3n_1 === tmp0_other_with_cast.d3n_1))
      return false;
    if (!(this.e3n_1 === tmp0_other_with_cast.e3n_1))
      return false;
    if (!(this.f3n_1 === tmp0_other_with_cast.f3n_1))
      return false;
    if (!(this.g3n_1 == tmp0_other_with_cast.g3n_1))
      return false;
    if (!(this.h3n_1 === tmp0_other_with_cast.h3n_1))
      return false;
    return true;
  }
}
class HttpClient {}
function request(method, url, headers, content, config, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_request__hjt9xh(this, method, url, headers, content, config, $completion), $completion);
}
function request$default(method, url, headers, content, config, $completion, $super) {
  headers = headers === VOID ? Headers.x3k([]) : headers;
  content = content === VOID ? null : content;
  config = config === VOID ? Companion_getInstance_12().u3m_1 : config;
  return $super === VOID ? this.s3n(method, url, headers, content, config, $completion) : request(method, url, headers, content, config, $completion);
}
function requestAsBytes(method, url, headers, content, config, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_requestAsBytes__v4smbm(this, method, url, headers, content, config, $completion), $completion);
}
function requestAsBytes$default(method, url, headers, content, config, $completion, $super) {
  headers = headers === VOID ? Headers.x3k([]) : headers;
  content = content === VOID ? null : content;
  config = config === VOID ? new RequestConfig() : config;
  return $super === VOID ? this.u3n(method, url, headers, content, config, $completion) : requestAsBytes(method, url, headers, content, config, $completion);
}
function readBytes(url, config, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_readBytes__epy2u7(this, url, config, $completion), $completion);
}
function readBytes$default(url, config, $completion, $super) {
  config = config === VOID ? new RequestConfig() : config;
  return $super === VOID ? this.v3n(url, config, $completion) : readBytes(url, config, $completion);
}
class Companion_6 {
  constructor() {
    Companion_instance_9 = this;
    this.k3n_1 = createHttpClientFromFetch$accessor$36sicd();
    this.l3n_1 = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.81 Safari/537.36';
    this.m3n_1 = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8';
    this.n3n_1 = 'en-us';
    this.o3n_1 = 'gzip, deflate';
    this.p3n_1 = 'Close';
  }
  r3n(method, url, headers, content, $completion) {
    return this.k3n_1.r3n(method, url, headers, content, $completion);
  }
  s3n(method, url, headers, content, config, $completion) {
    return this.k3n_1.s3n(method, url, headers, content, config, $completion);
  }
  u3n(method, url, headers, content, config, $completion) {
    return this.k3n_1.u3n(method, url, headers, content, config, $completion);
  }
  v3n(url, config, $completion) {
    return this.k3n_1.v3n(url, config, $completion);
  }
  q3n(headers, host) {
    var out = Headers.x3k([to('User-Agent', this.l3n_1), to('Accept', this.m3n_1), to('Accept-Language', this.n3n_1), to('Accept-Encoding', this.o3n_1), to('Connection', this.p3n_1)]).q3m(headers);
    return !(host == null) ? out.c3l([to('Host', host)]) : out;
  }
}
class HttpStatusMessage {
  constructor() {
    HttpStatusMessage_instance = this;
    this.l3m_1 = linkedMapOf([to(100, 'Continue'), to(101, 'Switching Protocols'), to(200, 'OK'), to(201, 'Created'), to(202, 'Accepted'), to(203, 'Non-Authoritative Information'), to(204, 'No Content'), to(205, 'Reset Content'), to(206, 'Partial Content'), to(300, 'Multiple Choices'), to(301, 'Moved Permanently'), to(302, 'Found'), to(303, 'See Other'), to(304, 'Not Modified'), to(305, 'Use Proxy'), to(307, 'Temporary Redirect'), to(400, 'Bad Request'), to(401, 'Unauthorized'), to(402, 'Payment Required'), to(403, 'Forbidden'), to(404, 'Not Found'), to(405, 'Method Not Allowed'), to(406, 'Not Acceptable'), to(407, 'Proxy Authentication Required'), to(408, 'Request Timeout'), to(409, 'Conflict'), to(410, 'Gone'), to(411, 'Length Required'), to(412, 'Precondition Failed'), to(413, 'Request Entity Too Large'), to(414, 'Request-URI Too Long'), to(415, 'Unsupported Media Type'), to(416, 'Requested Range Not Satisfiable'), to(417, 'Expectation Failed'), to(418, "I'm a teapot"), to(422, 'Unprocessable Entity (WebDAV - RFC 4918)'), to(423, 'Locked (WebDAV - RFC 4918)'), to(424, 'Failed Dependency (WebDAV) (RFC 4918)'), to(425, 'Unassigned'), to(426, 'Upgrade Required (RFC 7231)'), to(428, 'Precondition Required'), to(429, 'Too Many Requests'), to(431, 'Request Header Fileds Too Large)'), to(449, 'Error449'), to(451, 'Unavailable for Legal Reasons'), to(500, 'Internal Server Error'), to(501, 'Not Implemented'), to(502, 'Bad Gateway'), to(503, 'Service Unavailable'), to(504, 'Gateway Timeout'), to(505, 'HTTP Version Not Supported'), to(506, 'Variant Also Negotiates (RFC 2295)'), to(507, 'Insufficient Storage (WebDAV - RFC 4918)'), to(508, 'Loop Detected (WebDAV)'), to(509, 'Bandwidth Limit Exceeded'), to(510, 'Not Extended (RFC 2774)'), to(511, 'Network Authentication Required')]);
  }
}
class ProxiedHttpFactory {
  constructor(parent) {
    this.x3n_1 = parent;
    this.y3n_1 = parent;
  }
  w3n() {
    return this.x3n_1.w3n();
  }
}
class withTransferEncoding$slambda {
  constructor($input) {
    this.z3n_1 = $input;
  }
  u2b(out, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_3(this, out, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.u2b((!(p1 == null) ? isInterface(p1, AsyncOutputStream) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class createHttpClientFromFetch$1 {
  constructor($fetch) {
    this.b3o_1 = $fetch;
    this.a3o_1 = false;
  }
  r3n(method, url, headers, content, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_requestInternal__facax4(this, method, url, headers, content, $completion), $completion);
  }
}
class Resources {
  constructor(coroutineContext, root, parent) {
    root = root === VOID ? get_resourcesVfs() : root;
    parent = parent === VOID ? null : parent;
    this.c3o_1 = coroutineContext;
    this.d3o_1 = root;
    this.e3o_1 = parent;
    this.f3o_1 = LinkedHashMap.vc();
  }
}
class Text {}
class Marker {}
class Line {
  constructor(str) {
    this.h3o_1 = str;
  }
  g3o() {
    return this.h3o_1;
  }
  toString() {
    return 'Line(str=' + this.h3o_1 + ')';
  }
  hashCode() {
    return getStringHashCode(this.h3o_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Line))
      return false;
    var tmp0_other_with_cast = other instanceof Line ? other : THROW_CCE();
    if (!(this.h3o_1 === tmp0_other_with_cast.h3o_1))
      return false;
    return true;
  }
}
class LineDeferred {}
class EmptyLineOnce {}
class Indent {}
class Unindent {}
class INDENTS {
  i1(index) {
    return INDENTS_getInstance().i1(index);
  }
}
class Companion_7 {
  constructor() {
    Companion_instance_10 = this;
    var tmp = this;
    // Inline function 'korlibs.io.util.Companion.gen' call
    Companion_getInstance_14();
    var indenter = new Indenter();
    // Inline function 'korlibs.io.util.Companion.EMPTY.<anonymous>' call
    tmp.i3o_1 = indenter;
  }
  j3o(init) {
    // Inline function 'korlibs.io.util.Companion.gen' call
    var indenter = new Indenter();
    init(indenter);
    return indenter;
  }
}
class IndenterEvaluator {
  constructor(markHandler, indentEmptyLines, doIndent) {
    this.k3o_1 = markHandler;
    this.l3o_1 = indentEmptyLines;
    this.m3o_1 = doIndent;
    this.n3o_1 = StringBuilder.h();
    this.o3o_1 = 0;
    this.p3o_1 = true;
    this.q3o_1 = 0;
    this.r3o_1 = false;
  }
  s3o(str) {
    return this.n3o_1.ic(str);
  }
  t3o(str) {
    return this.n3o_1.jc(str);
  }
  u3o() {
    if (this.m3o_1) {
      this.t3o(_Char___init__impl__6a9atx(10));
    }
    this.o3o_1 = this.o3o_1 + 1 | 0;
    this.p3o_1 = true;
  }
  v3o(actions) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < actions.j1()) {
      // Inline function 'korlibs.io.util.IndenterEvaluator.eval.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var action = actions.i1(_unary__edvuaz);
      if (isInterface(action, Text)) {
        if (this.p3o_1) {
          var tmp;
          if (!this.l3o_1) {
            // Inline function 'kotlin.text.isEmpty' call
            var this_0 = action.g3o();
            tmp = charSequenceLength(this_0) === 0;
          } else {
            tmp = false;
          }
          if (tmp) {
            this.u3o();
          } else {
            if (this.m3o_1)
              this.s3o(INDENTS_instance.i1(this.q3o_1));
            else
              this.s3o(' ');
          }
        }
        this.s3o(action.g3o());
        if (action instanceof Line) {
          var tmp_0 = this;
          var tmp_1 = this.o3o_1;
          // Inline function 'kotlin.text.count' call
          var count = 0;
          var indexedObject = action.h3o_1;
          var inductionVariable = 0;
          while (inductionVariable < charSequenceLength(indexedObject)) {
            var element = charSequenceGet(indexedObject, inductionVariable);
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.io.util.IndenterEvaluator.eval.<anonymous>.<anonymous>' call
            if (element === _Char___init__impl__6a9atx(10)) {
              count = count + 1 | 0;
            }
          }
          tmp_0.o3o_1 = tmp_1 + count | 0;
          this.u3o();
        } else {
          this.p3o_1 = false;
        }
        this.r3o_1 = true;
      } else {
        if (action instanceof LineDeferred) {
          this.v3o(action.a3p_1().x3o_1);
        } else {
          if (equals_0(action, Indent_instance) || equals_0(action, Unindent_instance)) {
            this.r3o_1 = false;
            this.q3o_1 = this.q3o_1 + (equals_0(action, Indent_instance) ? 1 : -1) | 0;
          } else {
            if (equals_0(action, EmptyLineOnce_instance)) {
              if (this.r3o_1) {
                this.u3o();
                this.r3o_1 = false;
              }
            } else {
              if (action instanceof Marker) {
                var tmp89_safe_receiver = this.k3o_1;
                if (tmp89_safe_receiver == null)
                  null;
                else
                  tmp89_safe_receiver(this.n3o_1, this.o3o_1, action.w3o_1);
              }
            }
          }
        }
      }
    }
  }
}
class Indenter {
  constructor(actions) {
    Companion_getInstance_14();
    var tmp;
    if (actions === VOID) {
      // Inline function 'kotlin.collections.arrayListOf' call
      tmp = ArrayList.m1();
    } else {
      tmp = actions;
    }
    actions = tmp;
    this.x3o_1 = actions;
    this.y3o_1 = true;
    this.z3o_1 = '';
  }
  b3p(indenter) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.Indenter.line.<anonymous>' call
    this.x3o_1.k1(indenter.x3o_1);
    return this;
  }
  c3p(str) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.Indenter.line.<anonymous>' call
    this.x3o_1.f(new Line(str));
    return this;
  }
  d3p() {
    this.x3o_1.f(Indent_instance);
  }
  e3p() {
    this.x3o_1.f(Unindent_instance);
  }
  f3p(markHandler, doIndent) {
    // Inline function 'kotlin.apply' call
    var this_0 = new IndenterEvaluator(markHandler, this.y3o_1, doIndent);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.Indenter.toString.<anonymous>' call
    this_0.v3o(this.x3o_1);
    return this_0.n3o_1.toString();
  }
  toString() {
    return this.f3p(null, true);
  }
}
class Once {
  constructor() {
    this.g3p_1 = false;
  }
}
class AsyncOnce$invoke$slambda {
  constructor($callback) {
    this.h3p_1 = $callback;
  }
  pe($completion) {
    return this.h3p_1($completion);
  }
}
class AsyncOnce {
  constructor() {
    this.i3p_1 = null;
  }
  j3p(callback, $completion) {
    if (this.i3p_1 == null) {
      var tmp = this;
      // Inline function 'kotlin.js.getCoroutineContext' call
      var tmp_0 = $completion.gd();
      tmp.i3p_1 = asyncImmediately(tmp_0, AsyncOnce$invoke$slambda_0(callback));
    }
    return ensureNotNull(this.i3p_1).v17($completion);
  }
}
class Companion_8 {}
class BaseStrReader {
  uy() {
    return !this.ty();
  }
  jz() {
    return this.m3p(0);
  }
  kz() {
    var out = this.jz();
    this.oz(1);
    return out;
  }
  q3l(lit, consume, $super) {
    consume = consume === VOID ? true : consume;
    return $super === VOID ? this.l3p(lit, consume) : $super.l3p.call(this, lit, consume);
  }
  k3p(count) {
    // Inline function 'kotlin.apply' call
    var this_0 = this.z12(count);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.BaseStrReader.read.<anonymous>' call
    this.oz(count);
    return this_0;
  }
}
class StrReader extends BaseStrReader {
  constructor(str, file, pos) {
    file = file === VOID ? 'file' : file;
    pos = pos === VOID ? 0 : pos;
    super();
    this.k3l_1 = str;
    this.l3l_1 = file;
    this.m3l_1 = pos;
    this.n3l_1 = charArray(this.k3l_1.length);
    this.o3l_1 = this.k3l_1.length;
  }
  lz() {
    return this.m3l_1;
  }
  oy() {
    return this.o3l_1 - this.m3l_1 | 0;
  }
  ty() {
    return this.m3l_1 >= this.k3l_1.length;
  }
  z12(count) {
    return substr_0(this, this.m3l_1, count);
  }
  jz() {
    return this.uy() ? charSequenceGet(this.k3l_1, this.m3l_1) : _Char___init__impl__6a9atx(0);
  }
  kz() {
    return this.uy() ? charSequenceGet(this.k3l_1, posSkip(this, 1)) : _Char___init__impl__6a9atx(0);
  }
  r3l() {
    return this.k3p(this.oy());
  }
  oz(count) {
    this.m3l_1 = this.m3l_1 + count | 0;
    return this;
  }
  l3p(lit, consume) {
    if (!substringEquals(StringCompanionObject_instance, this.k3l_1, this.m3l_1, lit, 0, lit.length))
      return null;
    if (consume)
      this.m3l_1 = this.m3l_1 + lit.length | 0;
    return lit;
  }
  p3l(v) {
    // Inline function 'kotlin.text.substring' call
    var this_0 = this.k3l_1;
    var startIndex = this.m3l_1;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = this_0.substring(startIndex);
    var tmp0_elvis_lhs = v.pi(tmp$ret$1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var result = tmp;
    var m = ensureNotNull(result.aj().i1(0)).wi_1;
    this.m3l_1 = this.m3l_1 + m.length | 0;
    return m;
  }
  m3p(offset) {
    // Inline function 'kotlin.text.getOrElse' call
    var this_0 = this.k3l_1;
    var index = this.m3l_1 + offset | 0;
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (0 <= index ? index <= (charSequenceLength(this_0) - 1 | 0) : false) {
      tmp = charSequenceGet(this_0, index);
    } else {
      // Inline function 'korlibs.io.util.StrReader.peekOffset.<anonymous>' call
      tmp = _Char___init__impl__6a9atx(0);
    }
    return tmp;
  }
}
class DemoWorkerTask$execute$slambda {
  n3p(params, $completion) {
    println('TEST!!!!! ' + toString(params));
    return 11;
  }
  qe(p1, $completion) {
    return this.n3p((!(p1 == null) ? isInterface(p1, KtList) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class WorkerTask {
  constructor() {
    this.u3p_1 = null;
    this.v3p_1 = false;
    var tmp = this;
    // Inline function 'kotlin.collections.listOf' call
    tmp.w3p_1 = emptyList();
    this.x3p_1 = null;
    this.y3p_1 = false;
  }
  a3q(_set____db54di) {
    this.w3p_1 = _set____db54di;
  }
  b3q() {
    if (!this.y3p_1) {
      // Inline function 'kotlin.error' call
      var message = 'Must wrap function around runSuspend';
      throw IllegalStateException.m5(toString(message));
    }
    return this.w3p_1;
  }
  c3q(_set____db54di) {
    this.x3p_1 = _set____db54di;
  }
  d3q() {
    return this.x3p_1;
  }
  z3p(block) {
    this.y3p_1 = true;
    try {
      if (this.v3p_1) {
        this.u3p_1 = currentStackTrace();
        return Unit_instance;
      }
      var deferred = CompletableDeferred();
      var tmp = EmptyCoroutineContext_getInstance();
      launchImmediately(tmp, WorkerTask$runSuspend$slambda_0(deferred, this, block));
      this.x3p_1 = deferred;
    }finally {
      this.y3p_1 = false;
    }
  }
  t3p() {
    return Unit_instance;
  }
  e3q() {
    this.v3p_1 = true;
    try {
      this.t3p();
      return ensureNotNull(this.u3p_1);
    }finally {
      this.v3p_1 = false;
    }
  }
}
class DemoWorkerTask extends WorkerTask {
  t3p() {
    return this.z3p(DemoWorkerTask$execute$slambda_0());
  }
}
class WorkerTask$runSuspend$slambda {
  constructor($deferred, this$0, $block) {
    this.f3q_1 = $deferred;
    this.g3q_1 = this$0;
    this.h3q_1 = $block;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_4(this, $completion), $completion);
  }
}
class _WorkerImpl {
  e28() {
    return Unit_instance;
  }
  i3q() {
    return false;
  }
}
class Companion_9 {}
class BlobAsyncBaseStream extends AsyncStreamBase {
  static k3q(blob) {
    var $this = this.v1s();
    $this.j3q_1 = blob;
    return $this;
  }
  f1q($completion) {
  }
  w1s(position, buffer, offset, len, $completion) {
    var deferred = CompletableDeferred();
    var reader = new FileReader();
    reader.onload = BlobAsyncBaseStream$read$lambda(reader, len, buffer, offset, deferred);
    reader.onerror = BlobAsyncBaseStream$read$lambda_0(deferred, reader);
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = this.j3q_1;
    reader.readAsArrayBuffer(tmp$ret$1.slice(position.t4(), position.t4() + len));
    return deferred.v17($completion);
  }
  y1s($completion) {
    return numberToLong(this.j3q_1.size);
  }
}
class toVfs$1 extends Vfs {
  constructor($file, $box) {
    if ($box === VOID)
      $box = {};
    $box.m3q_1 = $file;
    super($box);
    this.l3q_1 = $file.name;
  }
  b21() {
    return this.l3q_1;
  }
  z21(path, mode, $completion) {
    return openAsync_0(this.m3q_1);
  }
  a22(path, $completion) {
    var tmp;
    switch (path) {
      case '/':
      case '':
        tmp = asFlow(listOf(this.bj(this.m3q_1.name)));
        break;
      default:
        // Inline function 'kotlin.collections.listOf' call

        var tmp$ret$0 = emptyList();
        tmp = asFlow(tmp$ret$0);
        break;
    }
    return tmp;
  }
}
class standardVfs$1 extends StandardVfs {
  constructor() {
    super();
    var tmp = this;
    tmp.q3q_1 = lazy(standardVfs$o$resourcesVfs$delegate$lambda);
    var tmp_0 = this;
    tmp_0.r3q_1 = lazy(standardVfs$o$rootLocalVfs$delegate$lambda);
  }
  j3i() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.q3q_1;
    resourcesVfs$factory();
    return this_0.b2();
  }
}
class StandardPaths {}
class httpFactory$2$1 {
  w3n() {
    return get_jsRuntime().s3q();
  }
}
class Companion_10 {
  constructor() {
    Companion_instance_13 = this;
    this.t3q_1 = Companion_getInstance_1().ez('JsRuntime');
  }
}
class JsRuntime {
  constructor() {
    Companion_getInstance_17();
    this.n3q_1 = 'unknown';
    this.o3q_1 = get_isDenoJs() ? 'deno.js' : get_isWeb() ? 'web.js' : get_isNodeJs() ? 'node.js' : get_isWorker() ? 'worker.js' : get_isShell() ? 'shell.js' : 'js';
  }
  p3q(path) {
    throw NotImplementedError.oe();
  }
  s3q() {
    throw NotImplementedError.oe();
  }
}
class JsRuntimeBrowser extends JsRuntime {
  constructor() {
    JsRuntimeBrowser_instance = null;
    super();
    JsRuntimeBrowser_instance = this;
    var tmp = this;
    // Inline function 'kotlin.js.unsafeCast' call
    tmp.w3q_1 = navigator.platform;
    var tmp_0 = this;
    tmp_0.x3q_1 = lazy(JsRuntimeBrowser$href$delegate$lambda);
    var tmp_1 = this;
    tmp_1.y3q_1 = lazy(JsRuntimeBrowser$baseUrl$delegate$lambda);
  }
  z3q() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.x3q_1;
    href$factory();
    return this_0.b2();
  }
  a3r() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.y3q_1;
    baseUrl$factory();
    return this_0.b2();
  }
  b3r() {
    return this.a3r();
  }
  p3q(path) {
    // Inline function 'kotlin.also' call
    // Inline function 'korlibs.io.file.std.withCatalogJail' call
    var this_0 = UrlVfs_2(this.b3r()).bj(path);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.std.withCatalogJail.<anonymous>' call
    var this_1 = (new CatalogVfs(this_0.c23())).f22().f22();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.runtime.JsRuntimeBrowser.openVfs.<anonymous>' call
    // Inline function 'korlibs.logger.Logger.info' call
    var this_2 = Companion_getInstance_17().t3q_1;
    var level = Level_INFO_getInstance();
    var tmp;
    if (this_2.u3g(level)) {
      // Inline function 'korlibs.io.runtime.JsRuntimeBrowser.openVfs.<anonymous>.<anonymous>' call
      var tmp$ret$3 = 'BROWSER openVfs: currentDir=' + JsRuntimeBrowser_getInstance().b3r() + ', path=' + path + ', urlVfs=' + this_1.toString();
      this_2.v3g(level, tmp$ret$3);
      tmp = Unit_instance;
    }
    return this_1;
  }
  s3q() {
    return Companion_getInstance_13();
  }
}
class JsRuntimeDeno extends JsRuntime {
  constructor() {
    JsRuntimeDeno_instance = null;
    super();
    JsRuntimeDeno_instance = this;
  }
  b3r() {
    try {
      var str = (new URL('.', Deno.mainModule)).pathname;
      var tmp;
      if (Companion_getInstance_0().jw()) {
        // Inline function 'kotlin.text.substring' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp = str.substring(1);
      } else {
        tmp = str;
      }
      return tmp;
    } catch ($p) {
      var e = $p;
      return '.';
    }
  }
  p3q(path) {
    return (new DenoLocalVfs()).bj(path === '.' ? this.b3r() : path);
  }
}
class DenoLocalVfs$listFlow$o$collect$slambda {
  constructor($$this$unsafeFlow, this$0, $path) {
    this.e3r_1 = $$this$unsafeFlow;
    this.f3r_1 = this$0;
    this.g3r_1 = $path;
  }
  k3r(value, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_5(this, value, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.k3r((p1 == null ? true : !(p1 == null)) ? p1 : THROW_CCE(), $completion);
  }
}
class sam$kotlinx_coroutines_flow_FlowCollector$0 {
  constructor(function_0) {
    this.n3r_1 = function_0;
  }
  g1l(value, $completion) {
    return this.n3r_1(value, $completion);
  }
}
class _no_name_provided__qut3iv {
  constructor($this, this$0, $path) {
    this.h3r_1 = $this;
    this.i3r_1 = this$0;
    this.j3r_1 = $path;
  }
  h1l(collector, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_collect__dlomyy(this, collector, $completion), $completion);
  }
  e1l(collector, $completion) {
    return this.h1l(collector, $completion);
  }
}
class DenoLocalVfs extends Vfs {
  z21(path, mode, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_open__qjxcea_1(this, path, mode, $completion), $completion);
  }
  a22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_listFlow__eqlpjw_0(this, path, $completion), $completion);
  }
  d22(path, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_stat__qhnowc_1(this, path, $completion), $completion);
  }
}
class DenoAsyncStreamBase extends AsyncStreamBase {
  static m3r(file) {
    var $this = this.v1s();
    $this.l3r_1 = file;
    return $this;
  }
  w1s(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_read__qih2oe_0(this, position, buffer, offset, len, $completion), $completion);
  }
  x1s(position, buffer, offset, len, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_write__x1694l_0(this, position, buffer, offset, len, $completion), $completion);
  }
  y1s($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_getLength__lvva94_0(this, $completion), $completion);
  }
  f1q($completion) {
    this.l3r_1.close();
  }
}
class toFlow$slambda {
  constructor($this_toFlow) {
    this.o3r_1 = $this_toFlow;
  }
  p3r($this$flow, $completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_6(this, $this$flow, $completion), $completion);
  }
  qe(p1, $completion) {
    return this.p3r((!(p1 == null) ? isInterface(p1, FlowCollector) : false) ? p1 : THROW_CCE(), $completion);
  }
}
class workerImpl$o$insideWorker$lambda$lambda$slambda {
  constructor($workerTask, $id) {
    this.q3r_1 = $workerTask;
    this.r3r_1 = $id;
  }
  pe($completion) {
    return suspendOrReturn(/*#__NOINLINE__*/_generator_invoke__zhh2q8_7(this, $completion), $completion);
  }
}
class workerImpl$1 extends _WorkerImpl {
  constructor() {
    super();
    this.s3r_1 = 0;
  }
  t3r() {
    return !(get_workerUrl() == null);
  }
  i3q() {
    var tmp;
    if (get_isDenoJs()) {
      tmp = Deno.mainModule;
    } else {
      var tmp5_safe_receiver = lastOrNull(Companion_instance_14.w3r().v3r_1);
      tmp = tmp5_safe_receiver == null ? null : tmp5_safe_receiver.y3r_1;
    }
    set_workerUrl(tmp);
    if (get_workerUrl() == null) {
      println('workerUrl = null, STACKTRACE:' + stackTraceToString(Exception.te()));
    }
    if (get_isWorker_0() && this.t3r()) {
      // Inline function 'kotlin.js.asDynamic' call
      get_jsGlobalThis().onmessage = workerImpl$o$insideWorker$lambda;
    }
    return get_isWorker_0();
  }
}
class Entry {
  constructor(method, file, line, column) {
    column = column === VOID ? -1 : column;
    this.x3r_1 = method;
    this.y3r_1 = file;
    this.z3r_1 = line;
    this.a3s_1 = column;
  }
  toString() {
    return 'Entry(method=' + this.x3r_1 + ', file=' + this.y3r_1 + ', line=' + this.z3r_1 + ', column=' + this.a3s_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.x3r_1);
    result = imul(result, 31) + getStringHashCode(this.y3r_1) | 0;
    result = imul(result, 31) + this.z3r_1 | 0;
    result = imul(result, 31) + this.a3s_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Entry))
      return false;
    var tmp0_other_with_cast = other instanceof Entry ? other : THROW_CCE();
    if (!(this.x3r_1 === tmp0_other_with_cast.x3r_1))
      return false;
    if (!(this.y3r_1 === tmp0_other_with_cast.y3r_1))
      return false;
    if (!(this.z3r_1 === tmp0_other_with_cast.z3r_1))
      return false;
    if (!(this.a3s_1 === tmp0_other_with_cast.a3s_1))
      return false;
    return true;
  }
}
class Companion_11 {
  b3s(stack, message) {
    // Inline function 'kotlin.collections.arrayListOf' call
    var entries = ArrayList.m1();
    // Inline function 'kotlin.collections.arrayListOf' call
    var messageLines = ArrayList.m1();
    var isChrome = false;
    var iterator = lines(stack).f1();
    var index = 0;
    $l$loop: while (iterator.g1()) {
      var index_0 = index;
      index = index + 1 | 0;
      var strLine = iterator.h1();
      // Inline function 'kotlin.text.trimEnd' call
      var strLine_0 = toString(trimEnd_0(isCharSequence(strLine) ? strLine : THROW_CCE()));
      var tmp;
      // Inline function 'kotlin.text.isEmpty' call
      if (charSequenceLength(strLine_0) === 0) {
        tmp = !isChrome;
      } else {
        tmp = false;
      }
      if (tmp)
        continue $l$loop;
      if ((startsWith(strLine_0, 'Error: ') || strLine_0 === 'Error') && index_0 === 0) {
        isChrome = true;
        var tmp_0 = messageLines;
        // Inline function 'kotlin.text.substring' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp$ret$5 = strLine_0.substring(7);
        tmp_0.f(tmp$ret$5);
      } else if (startsWith(strLine_0, '    at ')) {
        // Inline function 'kotlin.text.substring' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp$ret$7 = strLine_0.substring(7);
        var part = trimEnd(tmp$ret$7, charArrayOf([_Char___init__impl__6a9atx(41)]));
        var column = substringAfterLast(part, _Char___init__impl__6a9atx(58));
        var part0 = substringBeforeLast(part, _Char___init__impl__6a9atx(58));
        var line = substringAfterLast(part0, _Char___init__impl__6a9atx(58));
        var part1 = substringBeforeLast(part0, _Char___init__impl__6a9atx(58));
        var tmp_1;
        if (contains_0(part1, _Char___init__impl__6a9atx(40))) {
          // Inline function 'kotlin.collections.map' call
          var this_0 = split(part1, ['(']);
          // Inline function 'kotlin.collections.mapTo' call
          var destination = ArrayList.w(collectionSizeOrDefault(this_0, 10));
          var tmp0_iterator = this_0.f1();
          while (tmp0_iterator.g1()) {
            var item = tmp0_iterator.h1();
            // Inline function 'korlibs.js.Companion.parse.<anonymous>' call
            // Inline function 'kotlin.text.trim' call
            var tmp$ret$9 = toString(trim(isCharSequence(item) ? item : THROW_CCE()));
            destination.f(tmp$ret$9);
          }
          tmp_1 = destination;
        } else {
          // Inline function 'kotlin.text.trim' call
          var tmp$ret$12 = toString(trim(isCharSequence(part1) ? part1 : THROW_CCE()));
          tmp_1 = listOf_0(['', tmp$ret$12]);
        }
        var _destruct__k2r9zo = tmp_1;
        // Inline function 'kotlin.collections.component1' call
        var method = _destruct__k2r9zo.i1(0);
        // Inline function 'kotlin.collections.component2' call
        var file = _destruct__k2r9zo.i1(1);
        // Inline function 'kotlin.collections.plusAssign' call
        var tmp0_elvis_lhs = toIntOrNull(line);
        var tmp_2 = tmp0_elvis_lhs == null ? -1 : tmp0_elvis_lhs;
        var tmp1_elvis_lhs = toIntOrNull(column);
        var element = new Entry(method, file, tmp_2, tmp1_elvis_lhs == null ? -1 : tmp1_elvis_lhs);
        entries.f(element);
      } else if (isChrome) {
        messageLines.f(strLine_0);
      } else {
        var cline = strLine_0;
        // Inline function 'kotlin.collections.arrayListOf' call
        var numParts = ArrayList.m1();
        var inductionVariable = 0;
        if (inductionVariable < 2)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp_3 = Regex.ni('^(.*):(\\d+)$');
            var tmp_4 = cline;
            cline = tmp_3.ti(tmp_4, JSStackTrace$Companion$parse$lambda(numParts));
          }
           while (inductionVariable < 2);
        var file_0 = substringAfterLast(cline, _Char___init__impl__6a9atx(64));
        var method_0 = substringBeforeLast(cline, _Char___init__impl__6a9atx(64));
        var tmp2_elvis_lhs = firstOrNull(numParts);
        var line_0 = tmp2_elvis_lhs == null ? -1 : tmp2_elvis_lhs;
        var tmp_5;
        if (numParts.j1() >= 2) {
          var tmp3_elvis_lhs = lastOrNull(numParts);
          tmp_5 = tmp3_elvis_lhs == null ? -1 : tmp3_elvis_lhs;
        } else {
          tmp_5 = -1;
        }
        var column_0 = tmp_5;
        // Inline function 'kotlin.collections.plusAssign' call
        var element_0 = new Entry(method_0, file_0, line_0, column_0);
        entries.f(element_0);
      }
    }
    if (!isChrome) {
      messageLines = arrayListOf([message == null ? '' : message]);
    }
    if (entries.a1()) {
      entries.f(new Entry('<unknown>', '<unknown>', -1));
    }
    return new JSStackTrace(joinToString(messageLines, '\n'), entries);
  }
  c3s(e) {
    return this.b3s(stackTraceToString(e), e.message);
  }
  w3r() {
    return this.c3s(Exception.f6(''));
  }
}
class JSStackTrace {
  constructor(message, entries) {
    this.u3r_1 = message;
    this.v3r_1 = entries;
  }
  toString() {
    return 'JSStackTrace(message=' + this.u3r_1 + ', entries=' + toString(this.v3r_1) + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.u3r_1);
    result = imul(result, 31) + hashCode(this.v3r_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof JSStackTrace))
      return false;
    var tmp0_other_with_cast = other instanceof JSStackTrace ? other : THROW_CCE();
    if (!(this.u3r_1 === tmp0_other_with_cast.u3r_1))
      return false;
    if (!equals_0(this.v3r_1, tmp0_other_with_cast.v3r_1))
      return false;
    return true;
  }
}
//endregion
function get_logger() {
  _init_properties_AsyncExt_kt__ahev1h();
  return logger;
}
var logger;
var DEFAULT_SUSPEND_TEST_TIMEOUT;
var DEFAULT_TEST_SYNC_IO;
function get_DEBUG_ASYNC_LAUNCH_ERRORS() {
  _init_properties_AsyncExt_kt__ahev1h();
  // Inline function 'kotlin.getValue' call
  var this_0 = DEBUG_ASYNC_LAUNCH_ERRORS$delegate;
  DEBUG_ASYNC_LAUNCH_ERRORS$factory();
  return this_0.b2();
}
var DEBUG_ASYNC_LAUNCH_ERRORS$delegate;
function launchImmediately(context, callback) {
  _init_properties_AsyncExt_kt__ahev1h();
  return launchImmediately_0(CoroutineScope(context), callback);
}
function launchImmediately_0(_this__u8e3s4, callback) {
  _init_properties_AsyncExt_kt__ahev1h();
  return _launch(_this__u8e3s4, CoroutineStart_UNDISPATCHED_getInstance(), callback);
}
function _launch(_this__u8e3s4, start, callback) {
  _init_properties_AsyncExt_kt__ahev1h();
  var tmp = _this__u8e3s4.c15();
  return launch(_this__u8e3s4, tmp, start, _launch$slambda_0(callback));
}
function launchUnscopedAndWait(_this__u8e3s4, block, $completion) {
  var deferred = CompletableDeferred();
  startCoroutine(block, new launchUnscopedAndWait$1(_this__u8e3s4, deferred));
  return deferred.v17($completion);
}
function launchUnscoped(_this__u8e3s4, block) {
  _init_properties_AsyncExt_kt__ahev1h();
  return launchUnscoped_0(_this__u8e3s4.c15(), block);
}
function launchUnscoped_0(_this__u8e3s4, block) {
  _init_properties_AsyncExt_kt__ahev1h();
  startCoroutine(block, new launchUnscoped$1(_this__u8e3s4));
}
function asyncImmediately(context, callback) {
  _init_properties_AsyncExt_kt__ahev1h();
  return asyncImmediately_0(CoroutineScope(context), callback);
}
function asyncImmediately_0(_this__u8e3s4, callback) {
  _init_properties_AsyncExt_kt__ahev1h();
  return _async(_this__u8e3s4, CoroutineStart_UNDISPATCHED_getInstance(), callback);
}
function _async(_this__u8e3s4, start, callback) {
  _init_properties_AsyncExt_kt__ahev1h();
  var tmp = _this__u8e3s4.c15();
  return async(_this__u8e3s4, tmp, start, _async$slambda_0(callback));
}
function DEBUG_ASYNC_LAUNCH_ERRORS$delegate$lambda() {
  _init_properties_AsyncExt_kt__ahev1h();
  return Companion_instance_0.bj('DEBUG_ASYNC_LAUNCH_ERRORS') === 'true';
}
function *_generator_invoke__zhh2q8($this, $this$launch, $completion) {
  try {
    var tmp = $this.n3h_1($completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
  } catch ($p) {
    if ($p instanceof CancellationException) {
      var e = $p;
      throw e;
    } else {
      if ($p instanceof Error) {
        var e_0 = $p;
        if (get_DEBUG_ASYNC_LAUNCH_ERRORS()) {
          // Inline function 'korlibs.logger.Logger.error' call
          var this_0 = get_logger();
          var level = Level_ERROR_getInstance();
          var tmp_0;
          if (this_0.u3g(level)) {
            // Inline function 'korlibs.io.async._launch.<anonymous>.<anonymous>' call
            var tmp$ret$0 = 'CoroutineScope._launch.catch:';
            this_0.v3g(level, tmp$ret$0);
            tmp_0 = Unit_instance;
          }
          printStackTrace(e_0);
        }
        throw e_0;
      } else {
        throw $p;
      }
    }
  }
  return Unit_instance;
}
function _launch$slambda_0($callback) {
  var i = new _launch$slambda($callback);
  var l = function ($this$launch, $completion) {
    return i.o1s($this$launch, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_0($this, $this$async, $completion) {
  var tmp;
  try {
    var tmp_0 = $this.r3h_1($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      if (e instanceof CancellationException)
        throw e;
      if (get_DEBUG_ASYNC_LAUNCH_ERRORS()) {
        // Inline function 'korlibs.logger.Logger.error' call
        var this_0 = get_logger();
        var level = Level_ERROR_getInstance();
        var tmp_2;
        if (this_0.u3g(level)) {
          // Inline function 'korlibs.io.async._async.<anonymous>.<anonymous>' call
          var tmp$ret$0 = 'CoroutineScope._async.catch:';
          this_0.v3g(level, tmp$ret$0);
          tmp_2 = Unit_instance;
        }
        printStackTrace(e);
      }
      throw e;
    } else {
      throw $p;
    }
  }
  return tmp;
}
function _async$slambda_0($callback) {
  var i = new _async$slambda($callback);
  var l = function ($this$async, $completion) {
    return i.o1s($this$async, $completion);
  };
  l.$arity = 1;
  return l;
}
function DEBUG_ASYNC_LAUNCH_ERRORS$factory() {
  return getPropertyCallableRef('DEBUG_ASYNC_LAUNCH_ERRORS', 0, KProperty0, function () {
    return get_DEBUG_ASYNC_LAUNCH_ERRORS();
  }, null);
}
var properties_initialized_AsyncExt_kt_7k2grr;
function _init_properties_AsyncExt_kt__ahev1h() {
  if (!properties_initialized_AsyncExt_kt_7k2grr) {
    properties_initialized_AsyncExt_kt_7k2grr = true;
    logger = Companion_getInstance_1().ez('AsyncExt');
    // Inline function 'korlibs.time.seconds' call
    // Inline function 'korlibs.time.seconds' call
    DEFAULT_SUSPEND_TEST_TIMEOUT = toDuration(20, DurationUnit_SECONDS_getInstance());
    DEFAULT_TEST_SYNC_IO = false;
    DEBUG_ASYNC_LAUNCH_ERRORS$delegate = lazy(DEBUG_ASYNC_LAUNCH_ERRORS$delegate$lambda);
  }
}
function *_generator_invoke__zhh2q8_1($this, $this$withContext, $completion) {
  var tmp = protoOf(Proxy).z21.call($this.s3h_1, $this.t3h_1, $this.u3h_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return tmp.s1t_1;
}
function *_generator_write__x1694l($this, position, buffer, offset, len, $completion) {
  var tmp = $this.w3h_1.x1s(position, buffer, offset, len, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  // Inline function 'kotlin.comparisons.maxOf' call
  var a = $this.x3h_1._v;
  // Inline function 'kotlin.Long.plus' call
  var b = position.c4(toLong(len));
  var tmp$ret$1 = a.g2(b) >= 0 ? a : b;
  $this.x3h_1._v = tmp$ret$1;
  return Unit_instance;
}
function *_generator_open__qjxcea($this, path, mode, $completion) {
  var tmp = $this.e3i(path, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var fstat = tmp;
  if (!(fstat == null) && mode.equals(VfsOpenMode_READ_getInstance()) && fstat.l20_1.g2($this.a3i_1) <= 0) {
    var tmp_0 = $this.b22(path, get_LONG_ZERO_TO_MAX_RANGE(), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    return openAsync(tmp_0);
  }
  var tmp_1 = new VfsCachedStatContext(fstat);
  var tmp_2 = withContext(tmp_1, CatalogVfs$open$slambda_0($this, path, mode), $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  var base = tmp_2;
  var tmp_3;
  if ((fstat == null ? null : fstat.j20_1) === true) {
    tmp_3 = fstat.l20_1;
  } else {
    var tmp_4 = base.y1s($completion);
    if (tmp_4 === get_COROUTINE_SUSPENDED())
      tmp_4 = yield tmp_4;
    tmp_3 = tmp_4;
  }
  var maxSize = {_v: tmp_3};
  return buffered(toAsyncStream(CatalogVfs$open$2.f3i($this, base, maxSize)));
}
function *_generator_stat__qhnowc($this, path, $completion) {
  var tmp = $this.e3i(path, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp0_elvis_lhs = tmp;
  return tmp0_elvis_lhs == null ? $this.j22(normalize(_PathInfo___init__impl__vpw96a(path)), VOID, true) : tmp0_elvis_lhs;
}
function *_generator_statOrNull__vj5496($this, path, $completion) {
  var normalizedPath = normalize(_PathInfo___init__impl__vpw96a(path));
  if (normalizedPath === '/' || normalizedPath === '') {
    return $this.h22('/', true, new Long(0, 0), VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, true);
  }
  var baseName = get_baseName(_PathInfo___init__impl__vpw96a(normalizedPath));
  var tmp = $this.g3i(_PathInfo___get_fullPath__impl__21fx6x(get_parent(_PathInfo___init__impl__vpw96a(normalizedPath))), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp0_elvis_lhs = tmp;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    // Inline function 'kotlin.collections.getOrPut' call
    var this_0 = $this.c3i_1;
    var value = this_0.k3(path);
    var tmp_1;
    if (value == null) {
      // Inline function 'korlibs.io.file.std.CatalogVfs.statOrNull.<anonymous>' call
      var tmp_2 = $this.z3h_1.bj(path).v21($completion);
      if (tmp_2 === get_COROUTINE_SUSPENDED())
        tmp_2 = yield tmp_2;
      var answer = tmp_2;
      this_0.r3(path, answer);
      tmp_1 = answer;
    } else {
      tmp_1 = value;
    }
    return tmp_1;
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  var info = tmp_0;
  var tmp1_elvis_lhs = info.k3(baseName);
  return tmp1_elvis_lhs == null ? $this.j22(normalizedPath, VOID, true) : tmp1_elvis_lhs;
}
function *_generator_listFlow__eqlpjw($this, path, $completion) {
  // Inline function 'kotlin.collections.map' call
  var tmp = $this.h3i(path, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var this_0 = tmp;
  // Inline function 'kotlin.collections.mapTo' call
  var destination = ArrayList.w(this_0.j1());
  // Inline function 'kotlin.collections.iterator' call
  var tmp0_iterator = this_0.z1().f1();
  while (tmp0_iterator.g1()) {
    var item = tmp0_iterator.h1();
    // Inline function 'korlibs.io.file.std.CatalogVfs.listFlow.<anonymous>' call
    var tmp$ret$1 = item.b2().y20();
    destination.f(tmp$ret$1);
  }
  return asFlow(destination);
}
function *_generator_cachedListSimpleStats__uxxayh($this, path, $completion) {
  var tmp = $this.g3i(path, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp0_elvis_lhs = tmp;
  return tmp0_elvis_lhs == null ? emptyMap() : tmp0_elvis_lhs;
}
function *_generator_cachedListSimpleStatsOrNull__bbx84j($this, path, $completion) {
  var key = normalize(_PathInfo___init__impl__vpw96a(path));
  var tmp$ret$2;
  $l$block: {
    // Inline function 'korlibs.datastructure.getOrPut' call
    var this_0 = $this.d3i_1;
    // Inline function 'korlibs.datastructure.get' call
    // Inline function 'kotlin.js.asDynamic' call
    var res = this_0.get(key);
    if (!(res == null)) {
      tmp$ret$2 = res;
      break $l$block;
    }
    // Inline function 'korlibs.io.file.std.CatalogVfs.cachedListSimpleStatsOrNull.<anonymous>' call
    var tmp = $this.i3i(key, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var out = tmp;
    // Inline function 'korlibs.datastructure.set' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.js.asDynamic' call
    this_0.set(key, out);
    tmp$ret$2 = out;
  }
  return tmp$ret$2;
}
function *_generator_listSimpleStatsOrNull__lelcpx($this, path, $completion) {
  var tmp;
  try {
    var tmp_0 = $this.z3h_1.bj(path).bj('$catalog.json').y22(VOID, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      if (!(e instanceof FileNotFoundException)) {
        // Inline function 'korlibs.logger.Logger.error' call
        var this_0 = $this.b3i_1;
        var level = Level_ERROR_getInstance();
        var tmp_2;
        if (this_0.u3g(level)) {
          // Inline function 'korlibs.io.file.std.CatalogVfs.listSimpleStatsOrNull.<anonymous>' call
          this_0.v3g(level, e);
          tmp_2 = Unit_instance;
        }
      }
      return null;
    } else {
      throw $p;
    }
  }
  var catalogJsonString = tmp;
  var data = get_dyn(Companion_getInstance_2().i2d(catalogJsonString));
  var tmp11_subject = _Dyn___get_value__impl__bemnlg(data);
  if (!(tmp11_subject == null) ? isInterface(tmp11_subject, KtList) : false) {
    // Inline function 'kotlin.collections.associateBy' call
    // Inline function 'kotlin.collections.map' call
    var this_1 = _Dyn___get_list__impl__qpwjp(data);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList.w(collectionSizeOrDefault(this_1, 10));
    var tmp0_iterator = this_1.f1();
    while (tmp0_iterator.g1()) {
      var item = tmp0_iterator.h1();
      // Inline function 'korlibs.io.file.std.CatalogVfs.listSimpleStatsOrNull.<anonymous>' call
      var it = item.e3h_1;
      var localName = get_baseName(_PathInfo___init__impl__vpw96a(_Dyn___get_str__impl__dup4cs(Dyn__get_impl_6l2p80_0(it, 'name'))));
      var tmp$ret$2 = $this.h22(path + '/' + localName, _Dyn___get_bool__impl__nv3iun(Dyn__get_impl_6l2p80_0(it, 'isDirectory')), _Dyn___get_long__impl__wdxl7n(Dyn__get_impl_6l2p80_0(it, 'size')), VOID, VOID, VOID, VOID, VOID, Companion_getInstance_3().f1w(_Dyn___get_long__impl__wdxl7n(Dyn__get_impl_6l2p80_0(it, 'createTime'))), Companion_getInstance_3().f1w(_Dyn___get_long__impl__wdxl7n(Dyn__get_impl_6l2p80_0(it, 'modifiedTime'))), VOID, VOID, VOID, true);
      destination.f(tmp$ret$2);
    }
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(destination, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination_0 = LinkedHashMap.wc(capacity);
    var tmp0_iterator_0 = destination.f1();
    while (tmp0_iterator_0.g1()) {
      var element = tmp0_iterator_0.h1();
      // Inline function 'korlibs.io.file.std.CatalogVfs.listSimpleStatsOrNull.<anonymous>' call
      var tmp$ret$5 = get_baseName_0(element);
      destination_0.r3(tmp$ret$5, element);
    }
    return destination_0;
  } else {
    var out = LinkedHashMap.vc();
    var _iterator__ex2g4s = _Dyn___get_keys__impl__o4xmor(data).f1();
    while (_iterator__ex2g4s.g1()) {
      var key = _iterator__ex2g4s.h1().e3h_1;
      var fileName = _Dyn___get_str__impl__dup4cs(key);
      var info = Dyn__get_impl_6l2p80(data, key);
      var tmp0_elvis_lhs = Dyn__toLongOrNull_impl_ws7y8j(Dyn__get_impl_6l2p80_0(info, 0));
      var size = tmp0_elvis_lhs == null ? new Long(0, 0) : tmp0_elvis_lhs;
      var tmp1_elvis_lhs = Dyn__toLongOrNull_impl_ws7y8j(Dyn__get_impl_6l2p80_0(info, 1));
      var creationTime = tmp1_elvis_lhs == null ? new Long(0, 0) : tmp1_elvis_lhs;
      var tmp2_elvis_lhs = Dyn__toLongOrNull_impl_ws7y8j(Dyn__get_impl_6l2p80_0(info, 2));
      var modifiedTime = tmp2_elvis_lhs == null ? creationTime : tmp2_elvis_lhs;
      var isDirectory = endsWith(fileName, '/');
      var baseName = trimEnd(fileName, charArrayOf([_Char___init__impl__6a9atx(47)]));
      // Inline function 'kotlin.collections.set' call
      var value = $this.h22(path + '/' + baseName, isDirectory, size, VOID, VOID, VOID, VOID, VOID, Companion_getInstance_3().f1w(creationTime), Companion_getInstance_3().f1w(modifiedTime), VOID, VOID, VOID, true);
      out.r3(baseName, value);
    }
    return out;
  }
}
function CatalogVfs$open$slambda_0(this$0, $path, $mode) {
  var i = new CatalogVfs$open$slambda(this$0, $path, $mode);
  var l = function ($this$withContext, $completion) {
    return i.o1s($this$withContext, $completion);
  };
  l.$arity = 1;
  return l;
}
var resourcesVfsDebug;
function get_resourcesVfs() {
  return get_standardVfs().j3i();
}
function get_localCurrentDirVfs() {
  return get_applicationVfs();
}
function *_generator_close__nh2uv0($this, $completion) {
  if ($this.l3i_1) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = $this.m3i_1;
    var n = 0;
    while (n < this_0.j1()) {
      // Inline function 'korlibs.io.file.std.MountableVfs.close.<anonymous>' call
      var _unary__edvuaz = n;
      n = _unary__edvuaz + 1 | 0;
      var tmp = this_0.i1(_unary__edvuaz).jm_1.s1z_1.f1q($completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
    }
  }
  return Unit_instance;
}
function unmountInternal($this, folder) {
  removeAll($this.m3i_1, MountableVfs$unmountInternal$lambda(folder));
}
function resort($this) {
  // Inline function 'kotlin.collections.sortByDescending' call
  var this_0 = $this.m3i_1;
  if (this_0.j1() > 1) {
    // Inline function 'kotlin.comparisons.compareByDescending' call
    var tmp = MountableVfs$resort$lambda;
    var tmp$ret$0 = new sam$kotlin_Comparator$0(tmp);
    sortWith(this_0, tmp$ret$0);
  }
}
function MountableVfs$unmountInternal$lambda($folder) {
  return function (it) {
    return it.im_1 === normalize(get_pathInfo($folder));
  };
}
function MountableVfs$resort$lambda(a, b) {
  // Inline function 'kotlin.comparisons.compareValuesBy' call
  // Inline function 'korlibs.io.file.std.MountableVfs.resort.<anonymous>' call
  var tmp = b.im_1.length;
  // Inline function 'korlibs.io.file.std.MountableVfs.resort.<anonymous>' call
  var tmp$ret$1 = a.im_1.length;
  return compareValues(tmp, tmp$ret$1);
}
function UrlVfs_0(url, client, failFromStatus) {
  client = client === VOID ? createHttpClient() : client;
  failFromStatus = failFromStatus === VOID ? true : failFromStatus;
  return UrlVfs_1(url.c3j(VOID, VOID, VOID, VOID, VOID, '', null).d3j(), Unit_instance, client, failFromStatus).bj(url.v3i_1);
}
function *_generator_read__qih2oe($this, position, buffer, offset, len, $completion) {
  if (len === 0)
    return 0;
  // Inline function 'kotlin.Long.plus' call
  var tmp$ret$0 = position.c4(toLong(len));
  var tmp = _readRangeBase($this.e3j_1, $this.h3j_1, until(position, tmp$ret$0), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var res = tmp;
  var s = res.n3j_1;
  var coffset = offset;
  var pending = len;
  var totalRead = 0;
  $l$loop: while (pending > 0) {
    var tmp_0 = s.e1q(buffer, coffset, pending, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    var read = tmp_0;
    if (read < 0 && totalRead === 0)
      return read;
    if (read <= 0)
      break $l$loop;
    pending = pending - read | 0;
    totalRead = totalRead + read | 0;
    coffset = coffset + read | 0;
  }
  return totalRead;
}
function *_generator_getLength__lvva94($this, $completion) {
  var tmp;
  var tmp_0 = $this.z1s($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  if (tmp_0) {
    tmp = $this.i3j_1.l20_1;
  } else {
    unsupported();
  }
  return tmp;
}
function *_generator_open__qjxcea_0($this, path, mode, $completion) {
  try {
    var fullUrl = $this.n24(path);
    if (startsWith(fullUrl, 'file:')) {
      var tmp = $this.u3j_1.w3j(fullUrl, VOID, $completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
      return openAsync(tmp);
    }
    // Inline function 'kotlin.js.getCoroutineContext' call
    var tmp21_safe_receiver = $completion.gd().td(Companion_instance_1);
    var tmp0_elvis_lhs = tmp21_safe_receiver == null ? null : tmp21_safe_receiver.e21_1;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var tmp_1 = $this.d22(path, $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      tmp_0 = tmp_1;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var stat = tmp_0;
    var tmp_2 = stat.u20_1;
    var response = tmp_2 instanceof Response ? tmp_2 : null;
    if (!stat.j20_1) {
      throw FileNotFoundException.hx('Unexistant ' + fullUrl + ' : ' + toString_0(response));
    }
    return buffered(toAsyncStream(FinalUrlVfs$open$2.x3j($this, path, mode, fullUrl, stat)));
  } catch ($p) {
    if ($p instanceof RuntimeException) {
      var e = $p;
      var tmp1_elvis_lhs = e.message;
      throw FileNotFoundException.hx(tmp1_elvis_lhs == null ? 'error' : tmp1_elvis_lhs);
    } else {
      throw $p;
    }
  }
}
function *_generator__readRangeBase__z0wzdd($this, fullUrl, range, $completion) {
  // Inline function 'kotlin.also' call
  var tmp = $this.u3j_1.l3k(Companion_getInstance_8().z3j_1, fullUrl, Headers.k3k(range.equals(get_LONG_ZERO_TO_MAX_RANGE()) ? LinkedHashMap.vc() : linkedHashMapOf([to('range', 'bytes=' + range.b1().toString() + '-' + range.c1().toString())])), VOID, VOID, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var this_0 = tmp;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.file.std.FinalUrlVfs._readRangeBase.<anonymous>' call
  if ($this.l24_1) {
    if (this_0.j3j_1 === 404)
      throw FileNotFoundException.hx(fullUrl + ' not found');
    var tmp_0 = this_0.m3k($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
  }
  return this_0;
}
function _readRangeBase($this, fullUrl, range, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator__readRangeBase__z0wzdd($this, fullUrl, range, $completion), $completion);
}
function *_generator_readRange__lxd64t($this, path, range, $completion) {
  var tmp = _readRangeBase($this, $this.n24(path), range, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = readAll(tmp.n3j_1, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return tmp_0;
}
function *_generator_put__cb93wb($this, path, content, attributes, $completion) {
  if (!(content instanceof AsyncStream)) {
    invalidOp('UrlVfs.put requires content to be AsyncStream');
  }
  // Inline function 'korlibs.io.file.Vfs.get' call
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var tmp0_iterator = attributes.f1();
    while (tmp0_iterator.g1()) {
      var element = tmp0_iterator.h1();
      // Inline function 'korlibs.io.file.Vfs.get.<anonymous>' call
      if (element instanceof HttpHeaders) {
        tmp$ret$1 = element;
        break $l$block;
      }
    }
    tmp$ret$1 = null;
  }
  var tmp = tmp$ret$1;
  var headers = (tmp == null ? true : tmp instanceof HttpHeaders) ? tmp : THROW_CCE();
  // Inline function 'korlibs.io.file.Vfs.get' call
  var tmp$ret$4;
  $l$block_0: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var tmp0_iterator_0 = attributes.f1();
    while (tmp0_iterator_0.g1()) {
      var element_0 = tmp0_iterator_0.h1();
      // Inline function 'korlibs.io.file.Vfs.get.<anonymous>' call
      if (element_0 instanceof MimeType) {
        tmp$ret$4 = element_0;
        break $l$block_0;
      }
    }
    tmp$ret$4 = null;
  }
  var tmp_0 = tmp$ret$4;
  var tmp0_elvis_lhs = (tmp_0 == null ? true : tmp_0 instanceof MimeType) ? tmp_0 : THROW_CCE();
  var mimeType = tmp0_elvis_lhs == null ? Companion_getInstance_6().o3k_1 : tmp0_elvis_lhs;
  var tmp1_elvis_lhs = headers == null ? null : headers.w3k_1;
  var hheaders = tmp1_elvis_lhs == null ? Headers.x3k([]) : tmp1_elvis_lhs;
  var tmp_1 = content.y1s($completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var contentLength = tmp_1;
  var tmp_2 = $this.u3j_1.l3k(Companion_getInstance_8().c3k_1, $this.n24(path), hheaders.c3l([to(Companion_instance_6.y3k_1, contentLength.toString()), to(Companion_instance_6.z3k_1, mimeType.a3l_1)]), content, VOID, $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  var tmp_3 = content.y1s($completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  return tmp_3;
}
function *_generator_stat__qhnowc_0($this, path, $completion) {
  var fullUrl = $this.n24(path);
  var tmp;
  if (startsWith(fullUrl, 'file:')) {
    var tmp_0;
    try {
      var tmp_1 = $this.u3j_1.w3j(fullUrl, VOID, $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      var size = toLong(tmp_1.length);
      tmp_0 = $this.h22(path, false, size, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, null);
    } catch ($p) {
      var tmp_2;
      if ($p instanceof Error) {
        var e = $p;
        printStackTrace(e);
        tmp_2 = $this.j22(path);
      } else {
        throw $p;
      }
      tmp_0 = tmp_2;
    }
    tmp = tmp_0;
  } else {
    var tmp_3 = $this.u3j_1.l3k(Companion_getInstance_8().a3k_1, fullUrl, VOID, VOID, VOID, $completion);
    if (tmp_3 === get_COROUTINE_SUSPENDED())
      tmp_3 = yield tmp_3;
    var result = tmp_3;
    var tmp_4;
    if (result.o3j_1) {
      var tmp23_safe_receiver = result.l3j_1.bj(Companion_instance_6.y3k_1);
      var tmp0_elvis_lhs = tmp23_safe_receiver == null ? null : toLongOrNull(tmp23_safe_receiver);
      tmp_4 = $this.h22(path, false, tmp0_elvis_lhs == null ? new Long(-1, -1) : tmp0_elvis_lhs, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, result);
    } else {
      tmp_4 = $this.j22(path, result);
    }
    tmp = tmp_4;
  }
  return tmp;
}
function UrlVfs_1(url, dummy, client, failFromStatus) {
  client = client === VOID ? createHttpClient() : client;
  failFromStatus = failFromStatus === VOID ? true : failFromStatus;
  return new FinalUrlVfs(url, dummy, client, failFromStatus);
}
function UrlVfs_2(url, client, failFromStatus) {
  client = client === VOID ? createHttpClient() : client;
  failFromStatus = failFromStatus === VOID ? true : failFromStatus;
  return UrlVfs_0(Companion_getInstance_7().ez(url), client, failFromStatus);
}
function currentStackTrace(msg) {
  msg = msg === VOID ? 'printStackTrace' : msg;
  return stackTraceToString(Exception.f6(msg));
}
function printStackTrace_0(msg) {
  msg = msg === VOID ? 'printStackTrace' : msg;
  Console_getInstance().b3g([currentStackTrace(msg)]);
}
function get_MimeType_byExtensions() {
  _init_properties_MimeType_kt__q8p0lu();
  // Inline function 'kotlin.getValue' call
  var this_0 = MimeType_byExtensions$delegate;
  MimeType_byExtensions$factory();
  return this_0.b2();
}
var MimeType_byExtensions$delegate;
var Companion_instance_2;
function Companion_getInstance_6() {
  if (Companion_instance_2 === VOID)
    new Companion();
  return Companion_instance_2;
}
function MimeType_byExtensions$delegate$lambda() {
  _init_properties_MimeType_kt__q8p0lu();
  return LinkedHashMap.vc();
}
function MimeType_byExtensions$factory() {
  return getPropertyCallableRef('MimeType_byExtensions', 0, KProperty0, function () {
    return get_MimeType_byExtensions();
  }, null);
}
var properties_initialized_MimeType_kt_1v95zk;
function _init_properties_MimeType_kt__q8p0lu() {
  if (!properties_initialized_MimeType_kt_1v95zk) {
    properties_initialized_MimeType_kt_1v95zk = true;
    MimeType_byExtensions$delegate = lazy(MimeType_byExtensions$delegate$lambda);
  }
}
var Companion_instance_3;
function Companion_getInstance_7() {
  if (Companion_instance_3 === VOID)
    new Companion_0();
  return Companion_instance_3;
}
function URL$fullUrl$delegate$lambda(this$0) {
  return function () {
    return this$0.w3l().toString();
  };
}
function URL$fullUrlWithoutScheme$delegate$lambda(this$0) {
  return function () {
    return this$0.w3l(false).toString();
  };
}
function URL$pathWithQuery$delegate$lambda(this$0) {
  return function () {
    var tmp;
    if (!(this$0.w3i_1 == null)) {
      tmp = this$0.v3i_1 + '?' + this$0.w3i_1;
    } else {
      tmp = this$0.v3i_1;
    }
    return tmp;
  };
}
function normalizeUrl(_this__u8e3s4) {
  // Inline function 'kotlin.text.find' call
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.text.firstOrNull' call
    var inductionVariable = 0;
    while (inductionVariable < charSequenceLength(_this__u8e3s4)) {
      var element = charSequenceGet(_this__u8e3s4, inductionVariable);
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.io.net.normalizeUrl.<anonymous>' call
      if (element === _Char___init__impl__6a9atx(63) || element === _Char___init__impl__6a9atx(35)) {
        tmp$ret$1 = element;
        break $l$block;
      }
    }
    tmp$ret$1 = null;
  }
  var tmp0_elvis_lhs = tmp$ret$1;
  var tmp;
  var tmp_0 = tmp0_elvis_lhs;
  if ((tmp_0 == null ? null : new Char(tmp_0)) == null) {
    tmp = _Char___init__impl__6a9atx(63);
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var paramFlag = tmp;
  var pathParts = toMutableList(split_0(_this__u8e3s4, charArrayOf([paramFlag])));
  pathParts.p3(0, normalize(get_pathInfo(pathParts.i1(0)), false));
  return joinToString(pathParts, toString_1(paramFlag));
}
function fullUrl$factory() {
  return getPropertyCallableRef('fullUrl', 1, KProperty1, function (receiver) {
    return receiver.d3j();
  }, null);
}
var Methods_ALL_instance;
var Methods_OPTIONS_instance;
var Methods_GET_instance;
var Methods_HEAD_instance;
var Methods_POST_instance;
var Methods_PUT_instance;
var Methods_DELETE_instance;
var Methods_TRACE_instance;
var Methods_CONNECT_instance;
var Methods_PATCH_instance;
var Methods_entriesInitialized;
function Methods_initEntries() {
  if (Methods_entriesInitialized)
    return Unit_instance;
  Methods_entriesInitialized = true;
  Methods_ALL_instance = new Methods('ALL', 0);
  Methods_OPTIONS_instance = new Methods('OPTIONS', 1);
  Methods_GET_instance = new Methods('GET', 2);
  Methods_HEAD_instance = new Methods('HEAD', 3);
  Methods_POST_instance = new Methods('POST', 4);
  Methods_PUT_instance = new Methods('PUT', 5);
  Methods_DELETE_instance = new Methods('DELETE', 6);
  Methods_TRACE_instance = new Methods('TRACE', 7);
  Methods_CONNECT_instance = new Methods('CONNECT', 8);
  Methods_PATCH_instance = new Methods('PATCH', 9);
}
var Companion_instance_4;
function Companion_getInstance_8() {
  if (Companion_instance_4 === VOID)
    new Companion_1();
  return Companion_instance_4;
}
var Companion_instance_5;
function Companion_getInstance_9() {
  return Companion_instance_5;
}
var Companion_instance_6;
function Companion_getInstance_10() {
  return Companion_instance_6;
}
function Http$Headers$toListGrouped$lambda(a, b) {
  // Inline function 'kotlin.comparisons.compareValuesBy' call
  // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
  // Inline function 'kotlin.text.toLowerCase' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = a.im_1.toLowerCase();
  // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
  // Inline function 'kotlin.text.toLowerCase' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$5 = b.im_1.toLowerCase();
  return compareValues(tmp, tmp$ret$5);
}
function Methods_OPTIONS_getInstance() {
  Methods_initEntries();
  return Methods_OPTIONS_instance;
}
function Methods_GET_getInstance() {
  Methods_initEntries();
  return Methods_GET_instance;
}
function Methods_HEAD_getInstance() {
  Methods_initEntries();
  return Methods_HEAD_instance;
}
function Methods_POST_getInstance() {
  Methods_initEntries();
  return Methods_POST_instance;
}
function Methods_PUT_getInstance() {
  Methods_initEntries();
  return Methods_PUT_instance;
}
function Methods_DELETE_getInstance() {
  Methods_initEntries();
  return Methods_DELETE_instance;
}
function Methods_TRACE_getInstance() {
  Methods_initEntries();
  return Methods_TRACE_instance;
}
function Methods_CONNECT_getInstance() {
  Methods_initEntries();
  return Methods_CONNECT_instance;
}
function Methods_PATCH_getInstance() {
  Methods_initEntries();
  return Methods_PATCH_instance;
}
function get__defaultHttpFactory() {
  _init_properties_HttpClient_kt__huy6s3();
  // Inline function 'kotlin.getValue' call
  var this_0 = _defaultHttpFactory$delegate;
  _defaultHttpFactory$factory();
  return this_0.b2();
}
var _defaultHttpFactory$delegate;
function *_generator_invoke__zhh2q8_2($this, status, statusText, headers, rawContent, $completion) {
  // Inline function 'kotlin.text.trim' call
  // Inline function 'kotlin.text.lowercase' call
  var tmp0_elvis_lhs = headers.bj('Transfer-Encoding');
  // Inline function 'kotlin.js.asDynamic' call
  var this_0 = (tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs).toLowerCase();
  var transferEncoding = toString(trim(isCharSequence(this_0) ? this_0 : THROW_CCE()));
  // Inline function 'kotlin.text.trim' call
  // Inline function 'kotlin.text.lowercase' call
  var tmp1_elvis_lhs = headers.bj('Content-Encoding');
  // Inline function 'kotlin.js.asDynamic' call
  var this_1 = (tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs).toLowerCase();
  var contentEncoding = toString(trim(isCharSequence(this_1) ? this_1 : THROW_CCE()));
  var tmp = withTransferEncoding(rawContent, transferEncoding, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var tmp_0 = withContentEncoding(tmp, contentEncoding, $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return new Response(status, statusText, headers, rawContent, tmp_0);
}
var Companion_instance_7;
function Companion_getInstance_11() {
  return Companion_instance_7;
}
function *_generator_readAllBytes__ja4yyw($this, $completion) {
  var tmp = readAll($this.n3j_1, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var allContent = tmp;
  return allContent;
}
function *_generator_readAllString__7ikxrc($this, charset, $completion) {
  var tmp = $this.s3m($completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var bytes = tmp;
  return toString_2(bytes, charset);
}
function *_generator_checkErrors__u7iznj($this, $completion) {
  // Inline function 'kotlin.apply' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.net.http.Response.checkErrors.<anonymous>' call
  if (!$this.o3j_1) {
    var tmp = $this.t3m(VOID, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    throw HttpException.k3m($this.j3j_1, tmp, $this.k3j_1);
  }
  return $this;
}
function HttpClient$Response$responseCharset$delegate$lambda() {
  return get_UTF8();
}
var Companion_instance_8;
function Companion_getInstance_12() {
  if (Companion_instance_8 === VOID)
    new Companion_5();
  return Companion_instance_8;
}
function mergeUrls($this, base, append) {
  return Companion_getInstance_7().t3l(base, append);
}
function *_generator_request__hjt9xh($this, method, url, headers, content, config, $completion) {
  var tmp;
  if (content == null) {
    tmp = null;
  } else {
    var tmp_0 = content.y1s($completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    tmp = tmp_0;
  }
  var tmp0_elvis_lhs = tmp;
  var contentLength = tmp0_elvis_lhs == null ? new Long(0, 0) : tmp0_elvis_lhs;
  var actualHeaders = headers;
  var tmp_1;
  if (!(content == null)) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp_2;
      if (isInterface(headers, Collection)) {
        tmp_2 = headers.a1();
      } else {
        tmp_2 = false;
      }
      if (tmp_2) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = headers.f1();
      while (tmp0_iterator.g1()) {
        var element = tmp0_iterator.h1();
        // Inline function 'korlibs.io.net.http.HttpClient.request.<anonymous>' call
        if (equals(element.im_1, Companion_instance_6.y3k_1, true)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    tmp_1 = !tmp$ret$0;
  } else {
    tmp_1 = false;
  }
  if (tmp_1) {
    actualHeaders = actualHeaders.c3l([to(Companion_instance_6.y3k_1, contentLength.toString())]);
  }
  if (config.h3n_1) {
    actualHeaders = Companion_getInstance_13().q3n(actualHeaders, null);
  }
  // Inline function 'kotlin.apply' call
  var tmp_3 = $this.r3n(method, url, actualHeaders, content, $completion);
  if (tmp_3 === get_COROUTINE_SUSPENDED())
    tmp_3 = yield tmp_3;
  var this_0 = tmp_3;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.net.http.HttpClient.request.<anonymous>' call
  if (config.e3n_1) {
    var tmp_4 = this_0.m3k($completion);
    if (tmp_4 === get_COROUTINE_SUSPENDED())
      tmp_4 = yield tmp_4;
  }
  var response = this_0;
  if (config.d3n_1 && config.f3n_1 >= 0) {
    var redirectLocation = response.l3j_1.bj('location');
    if (!(redirectLocation == null)) {
      var tmp_5 = $this.s3n(method, mergeUrls($this, url, redirectLocation), headers.c3l([to('Referer', url)]), content, config.j3n(VOID, VOID, config.f3n_1 - 1 | 0), $completion);
      if (tmp_5 === get_COROUTINE_SUSPENDED())
        tmp_5 = yield tmp_5;
      return tmp_5;
    }
  }
  return response;
}
function *_generator_requestAsBytes__v4smbm($this, method, url, headers, content, config, $completion) {
  var tmp = $this.s3n(method, url, headers, content, config, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var res = tmp;
  var tmp_0 = res.s3m($completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return res.x3m(tmp_0);
}
function *_generator_readBytes__epy2u7($this, url, config, $completion) {
  var tmp = $this.t3n(Companion_getInstance_8().z3j_1, url, VOID, VOID, config.j3n(VOID, true), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return tmp.b3n_1;
}
var Companion_instance_9;
function Companion_getInstance_13() {
  if (Companion_instance_9 === VOID)
    new Companion_6();
  return Companion_instance_9;
}
function createHttpClient() {
  _init_properties_HttpClient_kt__huy6s3();
  return get_defaultHttpFactory().w3n();
}
var HttpStatusMessage_instance;
function HttpStatusMessage_getInstance() {
  if (HttpStatusMessage_instance === VOID)
    new HttpStatusMessage();
  return HttpStatusMessage_instance;
}
function *_generator_withContentEncoding__x3c14e(_this__u8e3s4, contentEncoding, $completion) {
  var input = _this__u8e3s4;
  var tmp;
  switch (contentEncoding) {
    case '':
    case 'plain':
      tmp = input;
      break;
    case 'gzip':
      var tmp_0 = uncompressed(input, Companion_getInstance_4(), VOID, $completion);
      if (tmp_0 === get_COROUTINE_SUSPENDED())
        tmp_0 = yield tmp_0;
      tmp = tmp_0;
      break;
    case 'deflate':
      var tmp_1 = uncompressed(input, get_Deflate(), VOID, $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      tmp = tmp_1;
      break;
    default:
      var message = "Unsupported Content-Encoding '" + contentEncoding + "'";
      throw IllegalStateException.m5(toString(message));
  }
  return tmp;
}
function withContentEncoding(_this__u8e3s4, contentEncoding, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_withContentEncoding__x3c14e(_this__u8e3s4, contentEncoding, $completion), $completion);
}
function *_generator_withTransferEncoding__5yv4cc(_this__u8e3s4, transferEncoding, $completion) {
  var input = _this__u8e3s4;
  var tmp;
  switch (transferEncoding) {
    case '':
    case 'identity':
      tmp = input;
      break;
    case 'chunked':
      var tmp_0 = asyncStreamWriter(VOID, 'chunked', VOID, withTransferEncoding$slambda_0(input), $completion);
      if (tmp_0 === get_COROUTINE_SUSPENDED())
        tmp_0 = yield tmp_0;
      tmp = tmp_0;
      break;
    default:
      var message = "Unsupported Transfer-Encoding '" + transferEncoding + "'";
      throw IllegalStateException.m5(toString(message));
  }
  return tmp;
}
function withTransferEncoding(_this__u8e3s4, transferEncoding, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_withTransferEncoding__5yv4cc(_this__u8e3s4, transferEncoding, $completion), $completion);
}
function createHttpClientFromFetch(fetch) {
  fetch = fetch === VOID ? Companion_getInstance_5() : fetch;
  _init_properties_HttpClient_kt__huy6s3();
  return new createHttpClientFromFetch$1(fetch);
}
function get_defaultHttpFactory() {
  _init_properties_HttpClient_kt__huy6s3();
  return get__defaultHttpFactory();
}
function createHttpClientFromFetch$accessor$36sicd(fetch) {
  fetch = fetch === VOID ? Companion_getInstance_5() : fetch;
  _init_properties_HttpClient_kt__huy6s3();
  return createHttpClientFromFetch(fetch);
}
function _defaultHttpFactory$delegate$lambda() {
  _init_properties_HttpClient_kt__huy6s3();
  return new ProxiedHttpFactory(get_httpFactory());
}
function *_generator_invoke__zhh2q8_3($this, out, $completion) {
  do {
    // Inline function 'kotlin.text.trim' call
    var tmp = readLine($this.z3n_1, VOID, VOID, VOID, 16, VOID, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var this_0 = tmp;
    var nbytesString = toString(trim(isCharSequence(this_0) ? this_0 : THROW_CCE()));
    var tmp0_elvis_lhs = toIntOrNull_0(nbytesString, 16);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var message = "Can't convert '" + nbytesString + "' into int for chunked encoding";
      throw IllegalStateException.m5(toString(message));
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var nbytes = tmp_0;
    if (nbytes > 0) {
      var tmp_1 = readBytesExact($this.z3n_1, nbytes, $completion);
      if (tmp_1 === get_COROUTINE_SUSPENDED())
        tmp_1 = yield tmp_1;
      var bytes = tmp_1;
      var tmp_2 = out.d1q(bytes, VOID, VOID, $completion);
      if (tmp_2 === get_COROUTINE_SUSPENDED())
        tmp_2 = yield tmp_2;
      var tmp_3 = readLine($this.z3n_1, VOID, VOID, VOID, 2, VOID, $completion);
      if (tmp_3 === get_COROUTINE_SUSPENDED())
        tmp_3 = yield tmp_3;
    }
  }
   while (nbytes > 0);
  return Unit_instance;
}
function withTransferEncoding$slambda_0($input) {
  var i = new withTransferEncoding$slambda($input);
  var l = function (out, $completion) {
    return i.u2b(out, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_requestInternal__facax4($this, method, url, headers, content, $completion) {
  var url_0 = Companion_getInstance_7().ez(url);
  var tmp = method.w3();
  var tmp0_elvis_lhs = url_0.u3i_1;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    var message = 'Missing host';
    throw IllegalStateException.m5(toString(message));
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  var tmp_1 = $this.b3o_1.x3g(tmp, tmp_0, url_0.z3l(), url_0.v3i_1, url_0.x3l(), toList_0(headers), content, $completion);
  if (tmp_1 === get_COROUTINE_SUSPENDED())
    tmp_1 = yield tmp_1;
  var result = tmp_1;
  var tmp_2 = Companion_instance_7.r3m(result.y3g_1, result.z3g_1, Headers.m3m(result.a3h_1), result.b3h_1, $completion);
  if (tmp_2 === get_COROUTINE_SUSPENDED())
    tmp_2 = yield tmp_2;
  return tmp_2;
}
function _defaultHttpFactory$factory() {
  return getPropertyCallableRef('_defaultHttpFactory', 0, KProperty0, function () {
    return get__defaultHttpFactory();
  }, null);
}
function responseCharset$factory() {
  return getPropertyCallableRef('responseCharset', 1, KProperty1, function (receiver) {
    return receiver.v3m();
  }, null);
}
var properties_initialized_HttpClient_kt_t7kzvv;
function _init_properties_HttpClient_kt__huy6s3() {
  if (!properties_initialized_HttpClient_kt_t7kzvv) {
    properties_initialized_HttpClient_kt_t7kzvv = true;
    _defaultHttpFactory$delegate = lazy(_defaultHttpFactory$delegate$lambda);
  }
}
var EmptyLineOnce_instance;
function EmptyLineOnce_getInstance() {
  return EmptyLineOnce_instance;
}
var Indent_instance;
function Indent_getInstance() {
  return Indent_instance;
}
var Unindent_instance;
function Unindent_getInstance() {
  return Unindent_instance;
}
var INDENTS_instance;
function INDENTS_getInstance_0() {
  return INDENTS_instance;
}
var Companion_instance_10;
function Companion_getInstance_14() {
  if (Companion_instance_10 === VOID)
    new Companion_7();
  return Companion_instance_10;
}
function AsyncOnce$invoke$slambda_0($callback) {
  var i = new AsyncOnce$invoke$slambda($callback);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function get_LONG_ZERO_TO_MAX_RANGE() {
  _init_properties_RangeExt_kt__vz0y4k();
  return LONG_ZERO_TO_MAX_RANGE;
}
var LONG_ZERO_TO_MAX_RANGE;
var properties_initialized_RangeExt_kt_dqkq5i;
function _init_properties_RangeExt_kt__vz0y4k() {
  if (!properties_initialized_RangeExt_kt_dqkq5i) {
    properties_initialized_RangeExt_kt_dqkq5i = true;
    LONG_ZERO_TO_MAX_RANGE = (new Long(0, 0)).j2(new Long(-1, 2147483647));
  }
}
function get_length(_this__u8e3s4) {
  return (_this__u8e3s4.l2_1 - _this__u8e3s4.k2_1 | 0) + 1 | 0;
}
function fromGlob(_this__u8e3s4, glob) {
  // Inline function 'kotlin.text.buildString' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.h();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'korlibs.io.util.fromGlob.<anonymous>' call
  this_0.ic('^');
  var inductionVariable = 0;
  var last = glob.length;
  while (inductionVariable < last) {
    var c = charSequenceGet(glob, inductionVariable);
    inductionVariable = inductionVariable + 1 | 0;
    if (c === _Char___init__impl__6a9atx(42))
      this_0.ic('.*');
    else if (c === _Char___init__impl__6a9atx(63))
      this_0.ic('.?');
    else if (c === _Char___init__impl__6a9atx(46))
      this_0.ic('\\.');
    else {
      if (isSpecial(Companion_getInstance(), c)) {
        this_0.jc(_Char___init__impl__6a9atx(92));
      }
      this_0.jc(c);
    }
  }
  this_0.ic('$');
  var tmp$ret$1 = this_0.toString();
  return Regex.ni(tmp$ret$1);
}
function isSpecial(_this__u8e3s4, c) {
  return c === _Char___init__impl__6a9atx(46) || (c === _Char___init__impl__6a9atx(63) || c === _Char___init__impl__6a9atx(42)) || (c === _Char___init__impl__6a9atx(43) || c === _Char___init__impl__6a9atx(94) || (c === _Char___init__impl__6a9atx(92) || c === _Char___init__impl__6a9atx(36))) || (c === _Char___init__impl__6a9atx(91) || c === _Char___init__impl__6a9atx(93) || (c === _Char___init__impl__6a9atx(40) || c === _Char___init__impl__6a9atx(41)) || (c === _Char___init__impl__6a9atx(123) || c === _Char___init__impl__6a9atx(125) || (c === _Char___init__impl__6a9atx(124) || c === _Char___init__impl__6a9atx(45)))) ? true : false;
}
var Companion_instance_11;
function Companion_getInstance_15() {
  return Companion_instance_11;
}
function posSkip($this, count) {
  var out = $this.m3l_1;
  $this.m3l_1 = $this.m3l_1 + count | 0;
  return out;
}
function substr_0($this, pos, length) {
  // Inline function 'kotlin.text.substring' call
  var this_0 = $this.k3l_1;
  // Inline function 'kotlin.math.min' call
  var b = $this.o3l_1;
  var startIndex = Math.min(pos, b);
  // Inline function 'kotlin.math.min' call
  var a = pos + length | 0;
  var b_0 = $this.o3l_1;
  var endIndex = Math.min(a, b_0);
  // Inline function 'kotlin.js.asDynamic' call
  return this_0.substring(startIndex, endIndex);
}
function get_DEBUG_WORKER() {
  _init_properties_Worker_kt__eidt7m();
  return DEBUG_WORKER;
}
var DEBUG_WORKER;
function DemoWorkerTask$execute$slambda_0() {
  var i = new DemoWorkerTask$execute$slambda();
  var l = function (params, $completion) {
    return i.n3p(params, $completion);
  };
  l.$arity = 1;
  return l;
}
function *_generator_invoke__zhh2q8_4($this, $completion) {
  // Inline function 'kotlin.runCatching' call
  var this_0 = $this.g3q_1;
  var tmp;
  try {
    // Inline function 'kotlin.Companion.success' call
    // Inline function 'korlibs.io.worker.WorkerTask.runSuspend.<anonymous>.<anonymous>' call
    var tmp_0 = $this.h3q_1(this_0.b3q(), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    var value = tmp_0;
    tmp = _Result___init__impl__xyqfz8(value);
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      // Inline function 'kotlin.Companion.failure' call
      tmp_1 = _Result___init__impl__xyqfz8(createFailure(e));
    } else {
      throw $p;
    }
    tmp = tmp_1;
  }
  var tmp$ret$3 = tmp;
  completeWith($this.f3q_1, tmp$ret$3);
  return Unit_instance;
}
function WorkerTask$runSuspend$slambda_0($deferred, this$0, $block) {
  var i = new WorkerTask$runSuspend$slambda($deferred, this$0, $block);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
var Companion_instance_12;
function Companion_getInstance_16() {
  return Companion_instance_12;
}
var properties_initialized_Worker_kt_gr88g0;
function _init_properties_Worker_kt__eidt7m() {
  if (!properties_initialized_Worker_kt_gr88g0) {
    properties_initialized_Worker_kt_gr88g0 = true;
    DEBUG_WORKER = Companion_instance_0.bj('DEBUG_WORKER') === 'true';
  }
}
function *_generator_delay__nu7n9t(time, $completion) {
  var tmp = delay(numberToLong(_FastDuration___get_milliseconds__impl__ffr17u(time)), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function delay_0(time, $completion) {
  return suspendOrReturn(/*#__NOINLINE__*/_generator_delay__nu7n9t(time, $completion), $completion);
}
function get_jsGlobalDynamic() {
  _init_properties_KorioNativeJs_kt__151su();
  return jsGlobalDynamic;
}
var jsGlobalDynamic;
var jsGlobal;
function get_isDenoJs() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.getValue' call
  var this_0 = isDenoJs$delegate;
  isDenoJs$factory();
  return this_0.b2();
}
var isDenoJs$delegate;
function get_isWeb() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.getValue' call
  var this_0 = isWeb$delegate;
  isWeb$factory();
  return this_0.b2();
}
var isWeb$delegate;
function get_isWorker() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.getValue' call
  var this_0 = isWorker$delegate;
  isWorker$factory();
  return this_0.b2();
}
var isWorker$delegate;
function get_isNodeJs() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.getValue' call
  var this_0 = isNodeJs$delegate;
  isNodeJs$factory();
  return this_0.b2();
}
var isNodeJs$delegate;
function set__jsRuntime(_set____db54di) {
  _init_properties_KorioNativeJs_kt__151su();
  _jsRuntime = _set____db54di;
}
function get__jsRuntime() {
  _init_properties_KorioNativeJs_kt__151su();
  return _jsRuntime;
}
var _jsRuntime;
function get_jsRuntime() {
  _init_properties_KorioNativeJs_kt__151su();
  var tmp0_elvis_lhs = get__jsRuntime();
  set__jsRuntime(tmp0_elvis_lhs == null ? get_isDenoJs() ? JsRuntimeDeno_getInstance() : JsRuntimeBrowser_getInstance() : tmp0_elvis_lhs);
  return ensureNotNull(get__jsRuntime());
}
function get_isShell() {
  _init_properties_KorioNativeJs_kt__151su();
  return !get_isWeb() && !get_isNodeJs() && !get_isWorker();
}
function isDenoJs$delegate$lambda() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof Deno === 'object' && Deno.statSync;
}
function isWeb$delegate$lambda() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof window === 'object';
}
function isWorker$delegate$lambda() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof importScripts === 'function';
}
function isNodeJs$delegate$lambda() {
  _init_properties_KorioNativeJs_kt__151su();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof process !== 'undefined' && process.release && process.release.name.search(/node|io.js/) !== -1;
}
function isDenoJs$factory() {
  return getPropertyCallableRef('isDenoJs', 0, KProperty0, function () {
    return get_isDenoJs();
  }, null);
}
function isWeb$factory() {
  return getPropertyCallableRef('isWeb', 0, KProperty0, function () {
    return get_isWeb();
  }, null);
}
function isWorker$factory() {
  return getPropertyCallableRef('isWorker', 0, KProperty0, function () {
    return get_isWorker();
  }, null);
}
function isNodeJs$factory() {
  return getPropertyCallableRef('isNodeJs', 0, KProperty0, function () {
    return get_isNodeJs();
  }, null);
}
var properties_initialized_KorioNativeJs_kt_y4p8os;
function _init_properties_KorioNativeJs_kt__151su() {
  if (!properties_initialized_KorioNativeJs_kt_y4p8os) {
    properties_initialized_KorioNativeJs_kt_y4p8os = true;
    jsGlobalDynamic = typeof globalThis !== 'undefined' ? globalThis : typeof global !== 'undefined' ? global : self;
    jsGlobal = get_jsGlobalDynamic();
    isDenoJs$delegate = lazy(isDenoJs$delegate$lambda);
    isWeb$delegate = lazy(isWeb$delegate$lambda);
    isWorker$delegate = lazy(isWorker$delegate$lambda);
    isNodeJs$delegate = lazy(isNodeJs$delegate$lambda);
    _jsRuntime = null;
  }
}
function toVfs(_this__u8e3s4) {
  var file = _this__u8e3s4;
  return (new toVfs$1(file)).bj(file.name);
}
function openAsync_0(_this__u8e3s4) {
  return buffered(toAsyncStream(BlobAsyncBaseStream.k3q(_this__u8e3s4)), 65536, 16);
}
function BlobAsyncBaseStream$read$lambda($reader, $len, $buffer, $offset, $deferred) {
  return function (it) {
    // Inline function 'kotlin.js.unsafeCast' call
    var ab = $reader.result;
    // Inline function 'kotlin.math.min' call
    var a = ab.byteLength;
    var b = $len;
    var minLen = Math.min(a, b);
    arraycopy(toByteArray(new Int8Array(ab)), 0, $buffer, $offset, minLen);
    return $deferred.m1b(minLen);
  };
}
function BlobAsyncBaseStream$read$lambda_0($deferred, $reader) {
  return function (it) {
    return $deferred.l1b(newThrowable('' + $reader.error));
  };
}
function get_standardVfs() {
  _init_properties_LocalVfsJs_kt__kywvdr();
  return standardVfs;
}
var standardVfs;
function get_applicationVfs() {
  _init_properties_LocalVfsJs_kt__kywvdr();
  // Inline function 'kotlin.getValue' call
  var this_0 = applicationVfs$delegate;
  applicationVfs$factory();
  return this_0.b2();
}
var applicationVfs$delegate;
var applicationDataVfsOrNull;
var cacheVfsOrNull;
var externalStorageVfs$delegate;
var userHomeVfs$delegate;
var tempVfsOrNull;
function localVfs(path, async) {
  async = async === VOID ? true : async;
  _init_properties_LocalVfsJs_kt__kywvdr();
  return get_jsRuntime().p3q(path);
}
function standardVfs$o$resourcesVfs$delegate$lambda() {
  return get_applicationVfs().c23();
}
function standardVfs$o$rootLocalVfs$delegate$lambda() {
  return localVfs('.');
}
function applicationVfs$delegate$lambda() {
  _init_properties_LocalVfsJs_kt__kywvdr();
  return localVfs('.');
}
function externalStorageVfs$delegate$lambda() {
  _init_properties_LocalVfsJs_kt__kywvdr();
  return localVfs('.');
}
function userHomeVfs$delegate$lambda() {
  _init_properties_LocalVfsJs_kt__kywvdr();
  return localVfs('.');
}
function applicationVfs$factory() {
  return getPropertyCallableRef('applicationVfs', 0, KProperty0, function () {
    return get_applicationVfs();
  }, null);
}
function resourcesVfs$factory() {
  return getPropertyCallableRef('resourcesVfs', 1, KProperty1, function (receiver) {
    return receiver.j3i();
  }, null);
}
var properties_initialized_LocalVfsJs_kt_51m659;
function _init_properties_LocalVfsJs_kt__kywvdr() {
  if (!properties_initialized_LocalVfsJs_kt_51m659) {
    properties_initialized_LocalVfsJs_kt_51m659 = true;
    standardVfs = new standardVfs$1();
    applicationVfs$delegate = lazy(applicationVfs$delegate$lambda);
    applicationDataVfsOrNull = null;
    cacheVfsOrNull = null;
    externalStorageVfs$delegate = lazy(externalStorageVfs$delegate$lambda);
    userHomeVfs$delegate = lazy(userHomeVfs$delegate$lambda);
    tempVfsOrNull = null;
  }
}
var StandardPaths_instance;
function StandardPaths_getInstance() {
  return StandardPaths_instance;
}
function get_httpFactory() {
  _init_properties_HttpFactoryJs_kt__a3ro2v();
  // Inline function 'kotlin.getValue' call
  var this_0 = httpFactory$delegate;
  httpFactory$factory();
  return this_0.b2();
}
var httpFactory$delegate;
function httpFactory$delegate$lambda() {
  _init_properties_HttpFactoryJs_kt__a3ro2v();
  return new httpFactory$2$1();
}
function httpFactory$factory() {
  return getPropertyCallableRef('httpFactory', 0, KProperty0, function () {
    return get_httpFactory();
  }, null);
}
var properties_initialized_HttpFactoryJs_kt_on1x9h;
function _init_properties_HttpFactoryJs_kt__a3ro2v() {
  if (!properties_initialized_HttpFactoryJs_kt_on1x9h) {
    properties_initialized_HttpFactoryJs_kt_on1x9h = true;
    httpFactory$delegate = lazy(httpFactory$delegate$lambda);
  }
}
var Companion_instance_13;
function Companion_getInstance_17() {
  if (Companion_instance_13 === VOID)
    new Companion_10();
  return Companion_instance_13;
}
function JsRuntimeBrowser$href$delegate$lambda() {
  var tmp1_safe_receiver = document.location;
  var tmp0_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.href;
  return tmp0_elvis_lhs == null ? '.' : tmp0_elvis_lhs;
}
function JsRuntimeBrowser$baseUrl$delegate$lambda() {
  return endsWith(JsRuntimeBrowser_getInstance().z3q(), '/') ? JsRuntimeBrowser_getInstance().z3q() : substringBeforeLast(JsRuntimeBrowser_getInstance().z3q(), _Char___init__impl__6a9atx(47));
}
var JsRuntimeBrowser_instance;
function JsRuntimeBrowser_getInstance() {
  if (JsRuntimeBrowser_instance === VOID)
    new JsRuntimeBrowser();
  return JsRuntimeBrowser_instance;
}
function href$factory() {
  return getPropertyCallableRef('href', 1, KProperty1, function (receiver) {
    return receiver.z3q();
  }, null);
}
function baseUrl$factory() {
  return getPropertyCallableRef('baseUrl', 1, KProperty1, function (receiver) {
    return receiver.a3r();
  }, null);
}
var JsRuntimeDeno_instance;
function JsRuntimeDeno_getInstance() {
  if (JsRuntimeDeno_instance === VOID)
    new JsRuntimeDeno();
  return JsRuntimeDeno_instance;
}
function *_generator_invoke__zhh2q8_5($this, value, $completion) {
  // Inline function 'kotlinx.coroutines.flow.map.<anonymous>' call
  var $this$transform = $this.e3r_1;
  // Inline function 'korlibs.io.runtime.DenoLocalVfs.listFlow.<anonymous>' call
  var tmp$ret$0 = new VfsFile($this.f3r_1, $this.g3r_1 + '/' + value.name);
  var tmp = $this$transform.g1l(tmp$ret$0, $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return Unit_instance;
}
function *_generator_collect__dlomyy($this, collector, $completion) {
  // Inline function 'kotlinx.coroutines.flow.unsafeTransform.<anonymous>' call
  var tmp = DenoLocalVfs$listFlow$o$collect$slambda_0(collector, $this.i3r_1, $this.j3r_1);
  var tmp_0 = $this.h3r_1.e1l(new sam$kotlinx_coroutines_flow_FlowCollector$0(tmp), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function DenoLocalVfs$listFlow$o$collect$slambda_0($$this$unsafeFlow, this$0, $path) {
  var i = new DenoLocalVfs$listFlow$o$collect$slambda($$this$unsafeFlow, this$0, $path);
  var l = function (value, $completion) {
    return i.k3r(value, $completion);
  };
  l.$arity = 1;
  return l;
}
function getFullPath($this, path) {
  return normalize(get_pathInfo(path));
}
function *_generator_open__qjxcea_1($this, path, mode, $completion) {
  var options = jsObject([to('read', mode.f20_1), to('write', mode.c20_1), to('append', mode.g20_1), to('truncate', mode.e20_1), to('create', mode.c20_1), to('createNew', mode.d20_1), to('mode', toInt('666', 8))]);
  var tmp = await_0(Deno.open(getFullPath($this, path), options), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  var file = tmp;
  return toAsyncStream(DenoAsyncStreamBase.m3r(file));
}
function *_generator_listFlow__eqlpjw_0($this, path, $completion) {
  // Inline function 'kotlinx.coroutines.flow.map' call
  var tmp = toFlow(Deno.readDir(getFullPath($this, path)), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  // Inline function 'kotlinx.coroutines.flow.unsafeTransform' call
  var this_0 = tmp;
  // Inline function 'kotlinx.coroutines.flow.internal.unsafeFlow' call
  return new _no_name_provided__qut3iv(this_0, $this, path);
}
function *_generator_stat__qhnowc_1($this, path, $completion) {
  var tmp;
  try {
    // Inline function 'kotlin.let' call
    var tmp_0 = await_0(Deno.stat(getFullPath($this, path)), $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.runtime.DenoLocalVfs.stat.<anonymous>' call
    var it = tmp_0;
    var tmp_1 = it.isDirectory;
    var tmp_2 = numberToLong(it.size);
    var tmp_3 = numberToLong(it.dev);
    var tmp2_safe_receiver = it.ino;
    var tmp0_elvis_lhs = tmp2_safe_receiver == null ? null : numberToLong(tmp2_safe_receiver);
    var tmp_4 = tmp0_elvis_lhs == null ? new Long(-1, -1) : tmp0_elvis_lhs;
    var tmp3_safe_receiver = it.mode;
    var tmp1_elvis_lhs = tmp3_safe_receiver == null ? null : numberToInt(tmp3_safe_receiver);
    tmp = $this.h22(path, tmp_1, tmp_2, tmp_3, tmp_4, tmp1_elvis_lhs == null ? toInt('777', 8) : tmp1_elvis_lhs);
  } catch ($p) {
    var tmp_5;
    if ($p instanceof Error) {
      var e = $p;
      tmp_5 = $this.j22(path);
    } else {
      throw $p;
    }
    tmp = tmp_5;
  }
  return tmp;
}
function *_generator_read__qih2oe_0($this, position, buffer, offset, len, $completion) {
  var tmp = await_0($this.l3r_1.seek(position.t4(), Deno.SeekMode.Start), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp_0 = await_0($this.l3r_1.read(new Uint8Array(buffer.buffer, offset, len)), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  var read = tmp_0;
  var tmp0_elvis_lhs = read == null ? null : numberToInt(read);
  return tmp0_elvis_lhs == null ? -1 : tmp0_elvis_lhs;
}
function *_generator_write__x1694l_0($this, position, buffer, offset, len, $completion) {
  var tmp = await_0($this.l3r_1.seek(position.t4(), Deno.SeekMode.Start), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp_0 = await_0($this.l3r_1.write(new Uint8Array(buffer.buffer, offset, len)), $completion);
  if (tmp_0 === get_COROUTINE_SUSPENDED())
    tmp_0 = yield tmp_0;
  return Unit_instance;
}
function *_generator_getLength__lvva94_0($this, $completion) {
  var tmp = await_0($this.l3r_1.stat(), $completion);
  if (tmp === get_COROUTINE_SUSPENDED())
    tmp = yield tmp;
  return numberToLong(tmp.size);
}
function toFlow(_this__u8e3s4, $completion) {
  return flow(toFlow$slambda_0(_this__u8e3s4));
}
function *_generator_invoke__zhh2q8_6($this, $this$flow, $completion) {
  // Inline function 'kotlin.js.asDynamic' call
  var iterator = $this.o3r_1[get_Symbol_asyncIterator()];
  var gen = iterator.call($this$flow);
  $l$loop: while (true) {
    // Inline function 'kotlin.js.unsafeCast' call
    var prom = gen.next();
    var tmp = await_0(prom, $completion);
    if (tmp === get_COROUTINE_SUSPENDED())
      tmp = yield tmp;
    var value = tmp;
    if (value.done)
      break $l$loop;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$3 = value.value;
    var tmp_0 = $this$flow.g1l(tmp$ret$3, $completion);
    if (tmp_0 === get_COROUTINE_SUSPENDED())
      tmp_0 = yield tmp_0;
  }
  return Unit_instance;
}
function toFlow$slambda_0($this_toFlow) {
  var i = new toFlow$slambda($this_toFlow);
  var l = function ($this$flow, $completion) {
    return i.p3r($this$flow, $completion);
  };
  l.$arity = 1;
  return l;
}
function toByteArray(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  var tout = _this__u8e3s4;
  var tmp;
  if (!(tout == null) ? isByteArray(tout) : false) {
    // Inline function 'kotlin.js.unsafeCast' call
    tmp = tout;
  } else {
    var out = new Int8Array(_this__u8e3s4.length);
    var inductionVariable = 0;
    var last = out.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'org.khronos.webgl.get' call
        // Inline function 'kotlin.js.asDynamic' call
        out[n] = _this__u8e3s4[n];
      }
       while (inductionVariable <= last);
    tmp = out;
  }
  return tmp;
}
function set_workerUrl(_set____db54di) {
  _init_properties_Worker_js_kt__6mcbpj();
  workerUrl = _set____db54di;
}
function get_workerUrl() {
  _init_properties_Worker_js_kt__6mcbpj();
  return workerUrl;
}
var workerUrl;
function get_workerImpl() {
  _init_properties_Worker_js_kt__6mcbpj();
  return workerImpl;
}
var workerImpl;
function get_isWorker_0() {
  _init_properties_Worker_js_kt__6mcbpj();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof DedicatedWorkerGlobalScope === 'function';
}
function *_generator_invoke__zhh2q8_7($this, $completion) {
  try {
    $this.q3r_1.t3p();
    var result = $this.q3r_1.x3p_1;
    if (!(result == null) ? isInterface(result, Deferred) : false) {
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$0 = get_jsGlobalThis();
      // Inline function 'kotlin.arrayOf' call
      var tmp = result.v17($completion);
      if (tmp === get_COROUTINE_SUSPENDED())
        tmp = yield tmp;
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$3 = [$this.r3r_1, 'ok', tmp];
      tmp$ret$0.postMessage(tmp$ret$3);
    } else {
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$4 = get_jsGlobalThis();
      // Inline function 'kotlin.arrayOf' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$7 = [$this.r3r_1, 'ok', result];
      tmp$ret$4.postMessage(tmp$ret$7);
    }
  } catch ($p) {
    var e = $p;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$8 = get_jsGlobalThis();
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$11 = [$this.r3r_1, 'error', e.stack];
    tmp$ret$8.postMessage(tmp$ret$11);
  }
  return Unit_instance;
}
function workerImpl$o$insideWorker$lambda(evt) {
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var input = evt.data;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var id = input[0];
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp0_elvis_lhs = input[1];
  var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? get_workerUrl() : tmp0_elvis_lhs;
  var moduleUrl = tmp1_elvis_lhs == null ? '.' : tmp1_elvis_lhs;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var clazzName = input[2];
  var params = drop(input, 3);
  var tmp = import(moduleUrl);
  var tmp_0 = tmp instanceof Promise ? tmp : THROW_CCE();
  var imported = tmp_0.then(workerImpl$o$insideWorker$lambda$lambda(clazzName, params, id));
  return Unit_instance;
}
function workerImpl$o$insideWorker$lambda$lambda$slambda_0($workerTask, $id) {
  var i = new workerImpl$o$insideWorker$lambda$lambda$slambda($workerTask, $id);
  var l = function ($completion) {
    return i.pe($completion);
  };
  l.$arity = 0;
  return l;
}
function workerImpl$o$insideWorker$lambda$lambda($clazzName, $params, $id) {
  return function (imported) {
    // Inline function 'kotlin.js.asDynamic' call
    var taskClass = imported[$clazzName];
    // Inline function 'kotlin.js.unsafeCast' call
    var workerTask = jsNew(taskClass);
    workerTask.w3p_1 = toList_0($params);
    var tmp = EmptyCoroutineContext_getInstance();
    return launchImmediately(tmp, workerImpl$o$insideWorker$lambda$lambda$slambda_0(workerTask, $id));
  };
}
var properties_initialized_Worker_js_kt_gnq77b;
function _init_properties_Worker_js_kt__6mcbpj() {
  if (!properties_initialized_Worker_js_kt_gnq77b) {
    properties_initialized_Worker_js_kt_gnq77b = true;
    var tmp0_elvis_lhs = globalThis.location;
    workerUrl = tmp0_elvis_lhs == null ? get_isDenoJs() ? Deno.mainModule : '.' : tmp0_elvis_lhs;
    workerImpl = new workerImpl$1();
  }
}
function JSStackTrace$Companion$parse$lambda($numParts) {
  return function (it) {
    var tmp0_elvis_lhs = toIntOrNull(it.pj().i1(2));
    $numParts.r7(0, tmp0_elvis_lhs == null ? -1 : tmp0_elvis_lhs);
    return it.pj().i1(1);
  };
}
var Companion_instance_14;
function Companion_getInstance_18() {
  return Companion_instance_14;
}
//region block: post-declaration
initMetadataForLambda(_launch$slambda, VOID, VOID, [1]);
initMetadataForClass(launchUnscopedAndWait$1, VOID, VOID, VOID, [Continuation]);
initMetadataForClass(launchUnscoped$1, VOID, VOID, VOID, [Continuation]);
initMetadataForLambda(_async$slambda, VOID, VOID, [1]);
initMetadataForLambda(CatalogVfs$open$slambda, VOID, VOID, [1]);
initMetadataForClass(CatalogVfs$open$2, VOID, VOID, VOID, VOID, [4, 1, 0]);
initMetadataForClass(CatalogVfs, 'CatalogVfs', VOID, VOID, VOID, [2, 1, 0, 4, 3]);
initMetadataForClass(StandardVfs, 'StandardVfs', StandardVfs);
initMetadataForClass(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', VOID, VOID, [Comparator]);
initMetadataForClass(MountableVfs, 'MountableVfs', VOID, VOID, VOID, [0, 1, 4, 2, 3]);
initMetadataForInterface(StandardPathsBase, 'StandardPathsBase');
initMetadataForClass(HttpHeaders, 'HttpHeaders');
initMetadataForClass(FinalUrlVfs$open$2, VOID, VOID, VOID, VOID, [4, 0, 1]);
initMetadataForClass(FinalUrlVfs, 'FinalUrlVfs', VOID, VOID, VOID, [2, 1, 3, 0, 4]);
initMetadataForCompanion(Companion);
initMetadataForClass(MimeType, 'MimeType');
initMetadataForCompanion(Companion_0);
initMetadataForClass(URL_0, 'URL');
initMetadataForCompanion(Companion_1);
initMetadataForCompanion(Companion_2);
initMetadataForCompanion(Companion_3);
initMetadataForClass(sam$kotlin_Comparator$0_0, 'sam$kotlin_Comparator$0', VOID, VOID, [Comparator]);
initMetadataForClass(Methods, 'Methods');
initMetadataForClass(HttpException, 'HttpException');
initMetadataForClass(Headers, 'Headers', VOID, VOID, [Iterable]);
initMetadataForCompanion(Companion_4, VOID, VOID, [4]);
initMetadataForCompanion(Companion_5);
initMetadataForClass(Response, 'Response', VOID, VOID, VOID, [0, 1]);
initMetadataForClass(CompletedResponse, 'CompletedResponse');
initMetadataForClass(RequestConfig, 'RequestConfig', RequestConfig);
initMetadataForInterface(HttpClient, 'HttpClient', VOID, VOID, VOID, [4, 5, 2]);
protoOf(Companion_6).l3k = request$default;
protoOf(Companion_6).t3n = requestAsBytes$default;
protoOf(Companion_6).w3j = readBytes$default;
initMetadataForCompanion(Companion_6, VOID, [HttpClient], [4, 5, 2]);
initMetadataForObject(HttpStatusMessage, 'HttpStatusMessage');
initMetadataForClass(ProxiedHttpFactory, 'ProxiedHttpFactory');
initMetadataForLambda(withTransferEncoding$slambda, VOID, VOID, [1]);
protoOf(createHttpClientFromFetch$1).s3n = request;
protoOf(createHttpClientFromFetch$1).l3k = request$default;
protoOf(createHttpClientFromFetch$1).u3n = requestAsBytes;
protoOf(createHttpClientFromFetch$1).t3n = requestAsBytes$default;
protoOf(createHttpClientFromFetch$1).v3n = readBytes;
protoOf(createHttpClientFromFetch$1).w3j = readBytes$default;
initMetadataForClass(createHttpClientFromFetch$1, VOID, VOID, VOID, [HttpClient], [4, 5, 2]);
initMetadataForClass(Resources, 'Resources');
initMetadataForInterface(Text, 'Text');
initMetadataForClass(Marker, 'Marker');
initMetadataForClass(Line, 'Line', VOID, VOID, [Text]);
initMetadataForClass(LineDeferred, 'LineDeferred');
initMetadataForObject(EmptyLineOnce, 'EmptyLineOnce');
initMetadataForObject(Indent, 'Indent');
initMetadataForObject(Unindent, 'Unindent');
initMetadataForObject(INDENTS, 'INDENTS');
initMetadataForCompanion(Companion_7);
initMetadataForClass(IndenterEvaluator, 'IndenterEvaluator');
initMetadataForClass(Indenter, 'Indenter', Indenter);
initMetadataForClass(Once, 'Once', Once);
initMetadataForLambda(AsyncOnce$invoke$slambda, VOID, VOID, [0]);
initMetadataForClass(AsyncOnce, 'AsyncOnce', AsyncOnce, VOID, VOID, [1]);
initMetadataForCompanion(Companion_8);
protoOf(BaseStrReader).pz = skip$default;
protoOf(BaseStrReader).qz = toStringContext;
initMetadataForClass(BaseStrReader, 'BaseStrReader', VOID, VOID, [SimpleStrReader]);
initMetadataForClass(StrReader, 'StrReader');
initMetadataForLambda(DemoWorkerTask$execute$slambda, VOID, VOID, [1]);
initMetadataForClass(WorkerTask, 'WorkerTask', WorkerTask);
initMetadataForClass(DemoWorkerTask, 'DemoWorkerTask', DemoWorkerTask);
initMetadataForLambda(WorkerTask$runSuspend$slambda, VOID, VOID, [0]);
initMetadataForClass(_WorkerImpl, '_WorkerImpl', _WorkerImpl, VOID, VOID, [4]);
initMetadataForCompanion(Companion_9);
initMetadataForClass(BlobAsyncBaseStream, 'BlobAsyncBaseStream', VOID, VOID, VOID, [0, 4, 1]);
initMetadataForClass(toVfs$1, VOID, VOID, VOID, VOID, [2, 1, 0, 4, 3]);
initMetadataForClass(standardVfs$1);
protoOf(StandardPaths).p3i = appPreferencesFolder;
initMetadataForObject(StandardPaths, 'StandardPaths', VOID, VOID, [StandardPathsBase]);
initMetadataForClass(httpFactory$2$1);
initMetadataForCompanion(Companion_10);
initMetadataForClass(JsRuntime, 'JsRuntime', VOID, VOID, VOID, [1, 4]);
initMetadataForObject(JsRuntimeBrowser, 'JsRuntimeBrowser', VOID, VOID, VOID, [1, 4]);
initMetadataForObject(JsRuntimeDeno, 'JsRuntimeDeno', VOID, VOID, VOID, [1, 4]);
initMetadataForLambda(DenoLocalVfs$listFlow$o$collect$slambda, VOID, VOID, [1]);
initMetadataForClass(sam$kotlinx_coroutines_flow_FlowCollector$0, 'sam$kotlinx_coroutines_flow_FlowCollector$0', VOID, VOID, [FlowCollector], [1]);
initMetadataForClass(_no_name_provided__qut3iv, VOID, VOID, VOID, VOID, [1]);
initMetadataForClass(DenoLocalVfs, 'DenoLocalVfs', DenoLocalVfs, VOID, VOID, [2, 1, 0, 4, 3]);
initMetadataForClass(DenoAsyncStreamBase, 'DenoAsyncStreamBase', VOID, VOID, VOID, [4, 1, 0]);
initMetadataForLambda(toFlow$slambda, VOID, VOID, [1]);
initMetadataForLambda(workerImpl$o$insideWorker$lambda$lambda$slambda, VOID, VOID, [0]);
initMetadataForClass(workerImpl$1, VOID, VOID, VOID, VOID, [4]);
initMetadataForClass(Entry, 'Entry');
initMetadataForCompanion(Companion_11);
initMetadataForClass(JSStackTrace, 'JSStackTrace');
//endregion
//region block: init
resourcesVfsDebug = false;
Companion_instance_5 = new Companion_2();
Companion_instance_6 = new Companion_3();
Companion_instance_7 = new Companion_4();
EmptyLineOnce_instance = new EmptyLineOnce();
Indent_instance = new Indent();
Unindent_instance = new Unindent();
INDENTS_instance = new INDENTS();
Companion_instance_11 = new Companion_8();
Companion_instance_12 = new Companion_9();
StandardPaths_instance = new StandardPaths();
Companion_instance_14 = new Companion_11();
//endregion
//region block: exports
export {
  DemoWorkerTask as DemoWorkerTask,
};
export {
  launchUnscopedAndWait as launchUnscopedAndWait6v8yfjb8olf5,
  delay_0 as delay37k8qmbtkbicq,
  StandardPaths_instance as StandardPaths_instance3ckwvxznkwud8,
  Companion_getInstance_7 as Companion_getInstance2o41ajwjrmgx,
  Companion_getInstance_14 as Companion_getInstance3mi66xu3smuhs,
  Companion_instance_12 as Companion_instance1fqld9q4wfq20,
  asyncImmediately as asyncImmediatelyxg0gfwuwfdn8,
  launchImmediately as launchImmediatelylnv8lnistpdy,
  launchImmediately_0 as launchImmediately11za8mkid9pxu,
  launchUnscoped as launchUnscopedpyoa01nqmf1z,
  launchUnscoped_0 as launchUnscoped2lqe6wwpvfo83,
  MountableVfs as MountableVfs3mmbka3zq5l6i,
  get_localCurrentDirVfs as get_localCurrentDirVfs2lka1gsv26an5,
  localVfs as localVfs1bmv3sf89y0wp,
  get_resourcesVfs as get_resourcesVfs3qrvup7jpy9uc,
  toVfs as toVfsr7ir4p66g1my,
  printStackTrace_0 as printStackTrace297j5gt7hfz21,
  Resources as Resources1ysf2j4wd5yk7,
  AsyncOnce as AsyncOnce2iqg7qjwjlt3t,
  Indenter as Indenterdf10c5coibyi,
  Once as Once1q3lxlgflq3fq,
  fromGlob as fromGlobop2d8fuuz6ri,
  get_length as get_length100spxmnpj7nb,
  get_DEBUG_WORKER as get_DEBUG_WORKER2r7zp31jch3ip,
  get_workerImpl as get_workerImpl14jb6v5ie8q11,
};
//endregion
