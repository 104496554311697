import {
  CancellationException3b36o9qz53rgr as CancellationException,
  AutoCloseable1l5p57f9lp7kv as AutoCloseable,
  initMetadataForInterface1egvbzx539z91 as initMetadataForInterface,
  VOID7hggqo3abtya as VOID,
  initMetadataForCompanion1wyw17z38v6ac as initMetadataForCompanion,
  ArrayList3it5z8td81qkl as ArrayList,
  Unit_instance104q5opgivhr8 as Unit_instance,
  initMetadataForClassbxx6q50dy2s7 as initMetadataForClass,
  Regexxgw0gjiagf4z as Regex,
  _Char___init__impl__6a9atx35f3p1971c8nj as _Char___init__impl__6a9atx,
  charArrayOf27f4r3dozbrk1 as charArrayOf,
  trim6jpmwojjgb3l as trim,
  UnsupportedOperationException2tkumpmhredt3 as UnsupportedOperationException,
  Exceptiondt2hlxn7j7vw as Exception,
  captureStack1fzi4aczwc4hg as captureStack,
  RuntimeException1r3t0zl97011n as RuntimeException,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  copyOfwy6h3t5vzqpl as copyOf,
  arrayCopytctsywo3h7gj as arrayCopy,
  createThis2j2avj17cvnv2 as createThis,
  fill3lmv1pckd4inv as fill,
  Enum3alwj03lh1n41 as Enum,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  lazy2hsh8ze7j6ikd as lazy,
  protoOf180f3jzyo7rfj as protoOf,
  KProperty1ca4yb4wlo496 as KProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  printStackTrace18lnx7a39cni as printStackTrace,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  to2cs3ny02qtbcb as to,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isArray1hxjqtqy632bc as isArray,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  trimStart1mkod6gyztuyy as trimStart,
  split2bvyvnrlcifjv as split,
  getOrNull1go7ef9ldk0df as getOrNull,
  KProperty02ce7r476m8633 as KProperty0,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class CloseableCancellable {}
function cancel(e) {
  return this.u6();
}
class Companion {}
class CancellableGroup {
  constructor() {
    var tmp = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.ju_1 = ArrayList.m1();
  }
  ku(c) {
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.ju_1;
    var element = cancellable(c);
    this_0.f(element);
  }
  u6() {
    this.iu(CancellationException.ge());
  }
  iu(e) {
    cancel_1(this.ju_1, e);
  }
}
class sam$korlibs_io_lang_Cancellable$0 {
  constructor(function_0) {
    this.lu_1 = function_0;
  }
  iu(e) {
    return this.lu_1(e);
  }
}
class AutoCloseable$1 {
  constructor($block) {
    this.mu_1 = $block;
  }
  u6() {
    return this.mu_1();
  }
}
class Companion_0 {
  bj(key) {
    var tmp1_safe_receiver = customEnvironments;
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.text.uppercase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$1 = key.toUpperCase();
      tmp = tmp1_safe_receiver.k3(tmp$ret$1);
    }
    var tmp0_elvis_lhs = tmp;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var tmp_1 = Companion_getInstance_4().pu();
      // Inline function 'kotlin.text.uppercase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$3 = key.toUpperCase();
      tmp_0 = tmp_1.k3(tmp$ret$3);
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    return tmp_0;
  }
}
class MalformedInputException extends Exception {
  static vu(msg) {
    var $this = this.f6(msg);
    captureStack($this, $this.uu_1);
    return $this;
  }
}
class InvalidOperationException extends Exception {
  static su(str) {
    str = str === VOID ? 'Invalid Operation' : str;
    var $this = this.f6(str);
    captureStack($this, $this.ru_1);
    return $this;
  }
}
class ReservedException extends Exception {
  static yu(str) {
    str = str === VOID ? 'Reserved' : str;
    var $this = this.f6(str);
    captureStack($this, $this.xu_1);
    return $this;
  }
}
class UnreachableException extends Exception {
  static bv(str) {
    str = str === VOID ? 'Unreachable' : str;
    var $this = this.f6(str);
    captureStack($this, $this.av_1);
    return $this;
  }
}
class OutOfBoundsException extends Exception {
  static ev(index, str) {
    index = index === VOID ? -1 : index;
    str = str === VOID ? 'Out Of Bounds' : str;
    var $this = this.f6(str);
    captureStack($this, $this.dv_1);
    return $this;
  }
}
class UnexpectedException extends Exception {
  static hv(str) {
    str = str === VOID ? 'Unexpected' : str;
    var $this = this.f6(str);
    captureStack($this, $this.gv_1);
    return $this;
  }
}
class ByteArrayBuilder {
  static lv(data, size, allowGrow) {
    size = size === VOID ? data.length : size;
    allowGrow = allowGrow === VOID ? true : allowGrow;
    var $this = createThis(this);
    $this.iv_1 = data;
    $this.jv_1 = allowGrow;
    $this.kv_1 = size;
    return $this;
  }
  static mv(initialCapacity) {
    initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
    return this.lv(new Int8Array(initialCapacity), 0);
  }
  nv(value) {
    var oldPosition = this.kv_1;
    var newPosition = value;
    ensure(this, newPosition);
    this.kv_1 = newPosition;
    if (newPosition > oldPosition) {
      fill(this.iv_1, 0, oldPosition, newPosition);
    }
  }
  j1() {
    return this.kv_1;
  }
  ov(array, offset, len) {
    ensureCount(this, len);
    arraycopy(this, array, offset, this.iv_1, this.kv_1, len);
    this.kv_1 = this.kv_1 + len | 0;
  }
  pv(array, offset, len, $super) {
    offset = offset === VOID ? 0 : offset;
    len = len === VOID ? array.length - offset | 0 : len;
    var tmp;
    if ($super === VOID) {
      this.ov(array, offset, len);
      tmp = Unit_instance;
    } else {
      tmp = $super.ov.call(this, array, offset, len);
    }
    return tmp;
  }
  qv(v) {
    ensure(this, this.kv_1 + 1 | 0);
    var tmp = this.iv_1;
    var _unary__edvuaz = this.kv_1;
    this.kv_1 = _unary__edvuaz + 1 | 0;
    tmp[_unary__edvuaz] = v;
  }
  rv() {
    return copyOf(this.iv_1, this.kv_1);
  }
}
class Companion_1 {
  sv() {
    return get_currentArch();
  }
}
class Arch extends Enum {
  constructor(name, ordinal, bits, isArm, isX86OrX64, isMips, isWasm, isPowerPC) {
    isArm = isArm === VOID ? false : isArm;
    isX86OrX64 = isX86OrX64 === VOID ? false : isX86OrX64;
    isMips = isMips === VOID ? false : isMips;
    isWasm = isWasm === VOID ? false : isWasm;
    isPowerPC = isPowerPC === VOID ? false : isPowerPC;
    super(name, ordinal);
    this.vv_1 = bits;
    this.wv_1 = isArm;
    this.xv_1 = isX86OrX64;
    this.yv_1 = isMips;
    this.zv_1 = isWasm;
    this.aw_1 = isPowerPC;
  }
  bw() {
    return this.vv_1 === 32;
  }
}
class Companion_2 {
  constructor() {
    Companion_instance_2 = this;
    this.cw_1 = get_currentIsLittleEndian() ? Endian_LITTLE_ENDIAN_getInstance() : Endian_BIG_ENDIAN_getInstance();
  }
}
class Endian extends Enum {
  fw() {
    return this.equals(Endian_LITTLE_ENDIAN_getInstance());
  }
}
class Companion_3 {
  constructor() {
    Companion_instance_3 = this;
    this.gw_1 = values();
  }
  sv() {
    return get_currentOs();
  }
}
class Os extends Enum {
  jw() {
    return this.equals(Os_WINDOWS_getInstance());
  }
  kw() {
    return this.equals(Os_ANDROID_getInstance());
  }
  lw() {
    return this.equals(Os_LINUX_getInstance());
  }
  mw() {
    return this.equals(Os_MACOSX_getInstance());
  }
  nw() {
    return this.equals(Os_IOS_getInstance());
  }
  ow() {
    return this.equals(Os_TVOS_getInstance());
  }
  pw() {
    return this.nw() || this.ow();
  }
  qw() {
    return this.kw() || this.pw();
  }
  rw() {
    return this.mw() || this.pw();
  }
}
class Platform {}
function get_isWindows() {
  return this.uw().jw();
}
function get_isLinux() {
  return this.uw().lw();
}
function get_isMac() {
  return this.uw().mw();
}
function get_isJs() {
  return this.vw().xw();
}
function get_isNative() {
  return this.vw().yw();
}
function get_isJsNodeJs() {
  return this.ww() === 'js-node' || this.ww() === 'wasm-node';
}
function get_isJsBrowser() {
  return this.ww() === 'js-web' || this.ww() === 'wasm-web';
}
class Companion_4 {
  constructor() {
    Companion_instance_4 = this;
    var tmp = this;
    tmp.nu_1 = lazy(Platform$Companion$envs$delegate$lambda);
    var tmp_0 = this;
    tmp_0.ou_1 = lazy(Platform$Companion$envsUC$delegate$lambda);
  }
  tw() {
    return Companion_instance_1.sv();
  }
  uw() {
    return Companion_getInstance_3().sv();
  }
  vw() {
    return Companion_instance_5.sv();
  }
  ww() {
    return get_currentRawPlatformName();
  }
  sw() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.nu_1;
    envs$factory();
    return this_0.b2();
  }
  pu() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.ou_1;
    envsUC$factory();
    return this_0.b2();
  }
}
class Companion_5 {
  sv() {
    return get_currentRuntime();
  }
}
class Runtime extends Enum {
  xw() {
    return this.equals(Runtime_JS_getInstance());
  }
  dx() {
    return this.equals(Runtime_JVM_getInstance());
  }
  yw() {
    return this.equals(Runtime_NATIVE_getInstance());
  }
}
class IOException extends Exception {
  static kx(msg) {
    var $this = this.f6(msg);
    captureStack($this, $this.jx_1);
    return $this;
  }
}
class FileNotFoundException extends IOException {
  static hx(msg) {
    var $this = this.kx(msg);
    captureStack($this, $this.gx_1);
    return $this;
  }
}
class EOFException extends IOException {
  static ox(msg) {
    var $this = this.kx(msg);
    captureStack($this, $this.nx_1);
    return $this;
  }
}
//endregion
function Closeable(block) {
  return AutoCloseable_0(block);
}
function cancel_0(_this__u8e3s4) {
  return _this__u8e3s4.iu(CancellationException.he(''));
}
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
function AutoCloseable_0(block) {
  return new AutoCloseable$1(block);
}
function cancellable(_this__u8e3s4) {
  var tmp = cancellable$lambda(_this__u8e3s4);
  return new sam$korlibs_io_lang_Cancellable$0(tmp);
}
function cancel_1(_this__u8e3s4, e) {
  e = e === VOID ? CancellationException.he('') : e;
  // Inline function 'kotlin.run' call
  // Inline function 'kotlin.contracts.contract' call
  var _iterator__ex2g4s = _this__u8e3s4.f1();
  while (_iterator__ex2g4s.g1()) {
    var c = _iterator__ex2g4s.h1();
    c.iu(e);
  }
  return Unit_instance;
}
function cancellable$lambda($this_cancellable) {
  return function (it) {
    $this_cancellable.u6();
    return Unit_instance;
  };
}
var customEnvironments;
var Companion_instance_0;
function Companion_getInstance_0() {
  return Companion_instance_0;
}
function expand(_this__u8e3s4, str) {
  // Inline function 'kotlin.text.replace' call
  var regex = Regex.ni('(~|%(\\w+)%)');
  var transform = expand$lambda(_this__u8e3s4);
  return regex.ti(str, transform);
}
function expand$lambda($this_expand) {
  return function (it) {
    var key = trim(it.b2(), charArrayOf([_Char___init__impl__6a9atx(37)]));
    var tmp;
    if (key === '~') {
      var tmp_0;
      if (!($this_expand.bj('HOMEDRIVE') == null) && !($this_expand.bj('HOMEPATH') == null)) {
        tmp_0 = '' + $this_expand.bj('HOMEDRIVE') + $this_expand.bj('HOMEPATH');
      } else {
        var tmp0_elvis_lhs = $this_expand.bj('HOMEPATH');
        var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? $this_expand.bj('HOME') : tmp0_elvis_lhs;
        var tmp2_elvis_lhs = tmp1_elvis_lhs == null ? $this_expand.bj('TEMP') : tmp1_elvis_lhs;
        var tmp3_elvis_lhs = tmp2_elvis_lhs == null ? $this_expand.bj('TMP') : tmp2_elvis_lhs;
        tmp_0 = tmp3_elvis_lhs == null ? '/tmp' : tmp3_elvis_lhs;
      }
      tmp = tmp_0;
    } else {
      tmp = $this_expand.bj(key);
    }
    var tmp4_elvis_lhs = tmp;
    return tmp4_elvis_lhs == null ? '' : tmp4_elvis_lhs;
  };
}
function unsupported(msg) {
  msg = msg === VOID ? 'unsupported' : msg;
  throw UnsupportedOperationException.ua(msg);
}
function invalidOp(msg) {
  throw InvalidOperationException.su(msg);
}
function get_invalidOp() {
  throw InvalidOperationException.su();
}
function reserved(msg) {
  throw ReservedException.yu(msg);
}
function get_reserved() {
  throw ReservedException.yu();
}
function get_unreachable() {
  throw UnreachableException.bv();
}
function unexpected(msg) {
  throw UnexpectedException.hv(msg);
}
function ensure($this, expected) {
  if ($this.iv_1.length < expected) {
    if (!$this.jv_1)
      throw RuntimeException.db('ByteArrayBuffer configured to not grow!');
    var newSize = imul($this.iv_1.length + 7 | 0, 5);
    var realNewSize = newSize < 0 ? 1073741823 : newSize;
    if (newSize < 0 && expected > realNewSize) {
      // Inline function 'kotlin.error' call
      var message = "ByteArrayBuffer can't grow that much";
      throw IllegalStateException.m5(toString(message));
    }
    var tmp = $this;
    var tmp_0 = $this.iv_1;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$0 = Math.max(expected, realNewSize);
    tmp.iv_1 = copyOf(tmp_0, tmp$ret$0);
  }
}
function ensureCount($this, count) {
  ensure($this, $this.kv_1 + count | 0);
}
function arraycopy($this, src, srcPos, dst, dstPos, size) {
  // Inline function 'kotlin.collections.copyInto' call
  var endIndex = srcPos + size | 0;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = src;
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
}
var Arch_UNKNOWN_instance;
var Arch_X86_instance;
var Arch_X64_instance;
var Arch_ARM32_instance;
var Arch_ARM64_instance;
var Arch_MIPS32_instance;
var Arch_MIPSEL32_instance;
var Arch_MIPS64_instance;
var Arch_MIPSEL64_instance;
var Arch_WASM32_instance;
var Arch_POWERPC64_instance;
var Companion_instance_1;
function Companion_getInstance_1() {
  return Companion_instance_1;
}
var Arch_entriesInitialized;
function Arch_initEntries() {
  if (Arch_entriesInitialized)
    return Unit_instance;
  Arch_entriesInitialized = true;
  Arch_UNKNOWN_instance = new Arch('UNKNOWN', 0, -1);
  Arch_X86_instance = new Arch('X86', 1, 32, VOID, true);
  Arch_X64_instance = new Arch('X64', 2, 64, VOID, true);
  Arch_ARM32_instance = new Arch('ARM32', 3, 32, true);
  Arch_ARM64_instance = new Arch('ARM64', 4, 64, true);
  Arch_MIPS32_instance = new Arch('MIPS32', 5, 32, VOID, VOID, true);
  Arch_MIPSEL32_instance = new Arch('MIPSEL32', 6, 32, VOID, VOID, true);
  Arch_MIPS64_instance = new Arch('MIPS64', 7, 64, VOID, VOID, true);
  Arch_MIPSEL64_instance = new Arch('MIPSEL64', 8, 64, VOID, VOID, true);
  Arch_WASM32_instance = new Arch('WASM32', 9, 32, VOID, VOID, VOID, true);
  Arch_POWERPC64_instance = new Arch('POWERPC64', 10, 64, VOID, VOID, VOID, VOID, true);
}
function Arch_UNKNOWN_getInstance() {
  Arch_initEntries();
  return Arch_UNKNOWN_instance;
}
var Endian_LITTLE_ENDIAN_instance;
var Endian_BIG_ENDIAN_instance;
var Companion_instance_2;
function Companion_getInstance_2() {
  Endian_initEntries();
  if (Companion_instance_2 === VOID)
    new Companion_2();
  return Companion_instance_2;
}
var Endian_entriesInitialized;
function Endian_initEntries() {
  if (Endian_entriesInitialized)
    return Unit_instance;
  Endian_entriesInitialized = true;
  Endian_LITTLE_ENDIAN_instance = new Endian('LITTLE_ENDIAN', 0);
  Endian_BIG_ENDIAN_instance = new Endian('BIG_ENDIAN', 1);
  Companion_getInstance_2();
}
function Endian_LITTLE_ENDIAN_getInstance() {
  Endian_initEntries();
  return Endian_LITTLE_ENDIAN_instance;
}
function Endian_BIG_ENDIAN_getInstance() {
  Endian_initEntries();
  return Endian_BIG_ENDIAN_instance;
}
var Os_UNKNOWN_instance;
var Os_MACOSX_instance;
var Os_IOS_instance;
var Os_LINUX_instance;
var Os_WINDOWS_instance;
var Os_ANDROID_instance;
var Os_WASM_instance;
var Os_TVOS_instance;
var Os_WATCHOS_instance;
var Companion_instance_3;
function Companion_getInstance_3() {
  Os_initEntries();
  if (Companion_instance_3 === VOID)
    new Companion_3();
  return Companion_instance_3;
}
function values() {
  return [Os_UNKNOWN_getInstance(), Os_MACOSX_getInstance(), Os_IOS_getInstance(), Os_LINUX_getInstance(), Os_WINDOWS_getInstance(), Os_ANDROID_getInstance(), Os_WASM_getInstance(), Os_TVOS_getInstance(), Os_WATCHOS_getInstance()];
}
var Os_entriesInitialized;
function Os_initEntries() {
  if (Os_entriesInitialized)
    return Unit_instance;
  Os_entriesInitialized = true;
  Os_UNKNOWN_instance = new Os('UNKNOWN', 0);
  Os_MACOSX_instance = new Os('MACOSX', 1);
  Os_IOS_instance = new Os('IOS', 2);
  Os_LINUX_instance = new Os('LINUX', 3);
  Os_WINDOWS_instance = new Os('WINDOWS', 4);
  Os_ANDROID_instance = new Os('ANDROID', 5);
  Os_WASM_instance = new Os('WASM', 6);
  Os_TVOS_instance = new Os('TVOS', 7);
  Os_WATCHOS_instance = new Os('WATCHOS', 8);
  Companion_getInstance_3();
}
function Os_UNKNOWN_getInstance() {
  Os_initEntries();
  return Os_UNKNOWN_instance;
}
function Os_MACOSX_getInstance() {
  Os_initEntries();
  return Os_MACOSX_instance;
}
function Os_IOS_getInstance() {
  Os_initEntries();
  return Os_IOS_instance;
}
function Os_LINUX_getInstance() {
  Os_initEntries();
  return Os_LINUX_instance;
}
function Os_WINDOWS_getInstance() {
  Os_initEntries();
  return Os_WINDOWS_instance;
}
function Os_ANDROID_getInstance() {
  Os_initEntries();
  return Os_ANDROID_instance;
}
function Os_WASM_getInstance() {
  Os_initEntries();
  return Os_WASM_instance;
}
function Os_TVOS_getInstance() {
  Os_initEntries();
  return Os_TVOS_instance;
}
function Os_WATCHOS_getInstance() {
  Os_initEntries();
  return Os_WATCHOS_instance;
}
function Platform$Companion$envs$delegate$lambda() {
  return get_envs();
}
function Platform$Companion$envsUC$delegate$lambda() {
  // Inline function 'kotlin.collections.mapKeys' call
  var this_0 = Companion_getInstance_4().sw();
  // Inline function 'kotlin.collections.mapKeysTo' call
  var destination = LinkedHashMap.wc(mapCapacity(this_0.j1()));
  // Inline function 'kotlin.collections.associateByTo' call
  var tmp0_iterator = this_0.z1().f1();
  while (tmp0_iterator.g1()) {
    var element = tmp0_iterator.h1();
    // Inline function 'korlibs.platform.Companion.envsUC$delegate.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.text.uppercase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = element.a2().toUpperCase();
    // Inline function 'kotlin.collections.mapKeysTo.<anonymous>' call
    var tmp$ret$3 = element.b2();
    destination.r3(tmp, tmp$ret$3);
  }
  return destination;
}
var Companion_instance_4;
function Companion_getInstance_4() {
  if (Companion_instance_4 === VOID)
    new Companion_4();
  return Companion_instance_4;
}
function envs$factory() {
  return getPropertyCallableRef('envs', 1, KProperty1, function (receiver) {
    return receiver.sw();
  }, null);
}
function envsUC$factory() {
  return getPropertyCallableRef('envsUC', 1, KProperty1, function (receiver) {
    return receiver.pu();
  }, null);
}
var Runtime_JS_instance;
var Runtime_JVM_instance;
var Runtime_ANDROID_instance;
var Runtime_NATIVE_instance;
var Runtime_WASM_instance;
var Companion_instance_5;
function Companion_getInstance_5() {
  return Companion_instance_5;
}
var Runtime_entriesInitialized;
function Runtime_initEntries() {
  if (Runtime_entriesInitialized)
    return Unit_instance;
  Runtime_entriesInitialized = true;
  Runtime_JS_instance = new Runtime('JS', 0);
  Runtime_JVM_instance = new Runtime('JVM', 1);
  Runtime_ANDROID_instance = new Runtime('ANDROID', 2);
  Runtime_NATIVE_instance = new Runtime('NATIVE', 3);
  Runtime_WASM_instance = new Runtime('WASM', 4);
}
function Runtime_JS_getInstance() {
  Runtime_initEntries();
  return Runtime_JS_instance;
}
function Runtime_JVM_getInstance() {
  Runtime_initEntries();
  return Runtime_JVM_instance;
}
function Runtime_NATIVE_getInstance() {
  Runtime_initEntries();
  return Runtime_NATIVE_instance;
}
function get_portableSimpleName(_this__u8e3s4) {
  var tmp0_elvis_lhs = _this__u8e3s4.xf();
  return tmp0_elvis_lhs == null ? 'unknown' : tmp0_elvis_lhs;
}
function get_Symbol_asyncIterator() {
  return Symbol.asyncIterator;
}
function jsObject(pairs) {
  var out = jsEmptyObj();
  var inductionVariable = 0;
  var last = pairs.length;
  while (inductionVariable < last) {
    var pair = pairs[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    out[pair.im_1] = pair.jm_1;
  }
  return out;
}
function jsNew(clazz) {
  return (new clazz())();
}
function jsEmptyObj() {
  return {};
}
function get_isDenoJs() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.getValue' call
  var this_0 = isDenoJs$delegate;
  isDenoJs$factory();
  return this_0.b2();
}
var isDenoJs$delegate;
function get_isWeb() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.getValue' call
  var this_0 = isWeb$delegate;
  isWeb$factory();
  return this_0.b2();
}
var isWeb$delegate;
function get_isWorker() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.getValue' call
  var this_0 = isWorker$delegate;
  isWorker$factory();
  return this_0.b2();
}
var isWorker$delegate;
function get_isNodeJs() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.getValue' call
  var this_0 = isNodeJs$delegate;
  isNodeJs$factory();
  return this_0.b2();
}
var isNodeJs$delegate;
function get_currentArch() {
  _init_properties_Current_js_kt__w11d1e();
  return currentArch;
}
var currentArch;
function get_currentRuntime() {
  _init_properties_Current_js_kt__w11d1e();
  return currentRuntime;
}
var currentRuntime;
var currentIsDebug;
function get_currentIsLittleEndian() {
  _init_properties_Current_js_kt__w11d1e();
  return currentIsLittleEndian;
}
var currentIsLittleEndian;
var multithreadedSharedHeap;
function get_currentRawPlatformName() {
  _init_properties_Current_js_kt__w11d1e();
  return currentRawPlatformName;
}
var currentRawPlatformName;
var currentRawOsName;
function get_envs() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.getValue' call
  var this_0 = envs$delegate;
  envs$factory_0();
  return this_0.b2();
}
var envs$delegate;
function get_isShell() {
  _init_properties_Current_js_kt__w11d1e();
  return !get_isWeb() && !get_isNodeJs() && !get_isWorker();
}
function get_process() {
  _init_properties_Current_js_kt__w11d1e();
  if (typeof process === 'undefined') {
    try {
      // Inline function 'kotlin.error' call
      var message = "Not in NodeJS. Can't access process";
      throw IllegalStateException.m5(toString(message));
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        printStackTrace(e);
        throw e;
      } else {
        throw $p;
      }
    }
  }
  return process;
}
function jsObjectToMap(obj) {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.collections.associate' call
  var this_0 = jsObjectKeysArray(obj);
  var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
  // Inline function 'kotlin.collections.associateTo' call
  var destination = LinkedHashMap.wc(capacity);
  var inductionVariable = 0;
  var last = this_0.length;
  while (inductionVariable < last) {
    var element = this_0[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'kotlin.collections.plusAssign' call
    // Inline function 'korlibs.platform.jsObjectToMap.<anonymous>' call
    var pair = to(element, obj[element]);
    destination.r3(pair.im_1, pair.jm_1);
  }
  return destination;
}
function jsObjectKeysArray(obj) {
  _init_properties_Current_js_kt__w11d1e();
  var tmp = jsToArray(jsObjectKeys(obj));
  return isArray(tmp) ? tmp : THROW_CCE();
}
function jsToArray(obj) {
  _init_properties_Current_js_kt__w11d1e();
  var tmp = 0;
  var tmp_0 = obj.length;
  // Inline function 'kotlin.arrayOfNulls' call
  var tmp_1 = fillArrayVal(Array(tmp_0), null);
  while (tmp < tmp_0) {
    var tmp_2 = tmp;
    tmp_1[tmp_2] = obj[tmp_2];
    tmp = tmp + 1 | 0;
  }
  return tmp_1;
}
function jsObjectKeys(obj) {
  _init_properties_Current_js_kt__w11d1e();
  return Object.keys(obj);
}
function get_jsGlobalThis() {
  _init_properties_Current_js_kt__w11d1e();
  return globalThis;
}
function get_currentOs() {
  _init_properties_Current_js_kt__w11d1e();
  var tmp;
  if (get_isDenoJs()) {
    switch (Deno.build.os) {
      case 'darwin':
        tmp = Os_MACOSX_getInstance();
        break;
      case 'linux':
        tmp = Os_LINUX_getInstance();
        break;
      case 'windows':
        tmp = Os_WINDOWS_getInstance();
        break;
      default:
        tmp = Os_UNKNOWN_getInstance();
        break;
    }
  } else {
    tmp = Os_UNKNOWN_getInstance();
  }
  return tmp;
}
function isDenoJs$delegate$lambda() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof Deno === 'object' && Deno.statSync;
}
function isWeb$delegate$lambda() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof window === 'object';
}
function isWorker$delegate$lambda() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof importScripts === 'function';
}
function isNodeJs$delegate$lambda() {
  _init_properties_Current_js_kt__w11d1e();
  // Inline function 'kotlin.js.unsafeCast' call
  return typeof process !== 'undefined' && process.release && process.release.name.search(/node|io.js/) !== -1;
}
function envs$delegate$lambda() {
  _init_properties_Current_js_kt__w11d1e();
  var tmp;
  if (get_isDenoJs()) {
    tmp = jsObjectToMap(Deno.env.toObject());
  } else if (get_isNodeJs()) {
    tmp = jsObjectToMap(get_process().env);
  } else {
    // Inline function 'kotlin.also' call
    var this_0 = LinkedHashMap.vc();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.platform.envs$delegate.<anonymous>.<anonymous>' call
    var tmp1_safe_receiver = document.location;
    var tmp0_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.search;
    var hash = trimStart(tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs, charArrayOf([_Char___init__impl__6a9atx(63)]));
    var _iterator__ex2g4s = split(hash, ['&']).f1();
    while (_iterator__ex2g4s.g1()) {
      var part = _iterator__ex2g4s.h1();
      var parts = split(part, ['=']);
      var key = decodeURIComponent(parts.i1(0));
      var tmp1_elvis_lhs = getOrNull(parts, 1);
      var value = decodeURIComponent(tmp1_elvis_lhs == null ? key : tmp1_elvis_lhs);
      // Inline function 'kotlin.collections.set' call
      this_0.r3(key, value);
    }
    tmp = this_0;
  }
  return tmp;
}
function isDenoJs$factory() {
  return getPropertyCallableRef('isDenoJs', 0, KProperty0, function () {
    return get_isDenoJs();
  }, null);
}
function isWeb$factory() {
  return getPropertyCallableRef('isWeb', 0, KProperty0, function () {
    return get_isWeb();
  }, null);
}
function isWorker$factory() {
  return getPropertyCallableRef('isWorker', 0, KProperty0, function () {
    return get_isWorker();
  }, null);
}
function isNodeJs$factory() {
  return getPropertyCallableRef('isNodeJs', 0, KProperty0, function () {
    return get_isNodeJs();
  }, null);
}
function envs$factory_0() {
  return getPropertyCallableRef('envs', 0, KProperty0, function () {
    return get_envs();
  }, null);
}
var properties_initialized_Current_js_kt_4h6d9c;
function _init_properties_Current_js_kt__w11d1e() {
  if (!properties_initialized_Current_js_kt_4h6d9c) {
    properties_initialized_Current_js_kt_4h6d9c = true;
    isDenoJs$delegate = lazy(isDenoJs$delegate$lambda);
    isWeb$delegate = lazy(isWeb$delegate$lambda);
    isWorker$delegate = lazy(isWorker$delegate$lambda);
    isNodeJs$delegate = lazy(isNodeJs$delegate$lambda);
    currentArch = Arch_UNKNOWN_getInstance();
    currentRuntime = Runtime_JS_getInstance();
    currentIsDebug = false;
    // Inline function 'org.khronos.webgl.get' call
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$2 = [287454020];
    // Inline function 'kotlin.js.asDynamic' call
    currentIsLittleEndian = (new Uint8Array((new Uint32Array(tmp$ret$2)).buffer))[0] === 68;
    multithreadedSharedHeap = false;
    currentRawPlatformName = get_isDenoJs() ? 'js-deno' : get_isWeb() ? 'js-web' : get_isNodeJs() ? 'js-node' : get_isWorker() ? 'js-worker' : get_isShell() ? 'js-shell' : 'js';
    var tmp;
    if (get_isDenoJs()) {
      tmp = 'deno';
    } else if (get_isWeb() || get_isWorker()) {
      // Inline function 'kotlin.js.unsafeCast' call
      tmp = navigator.userAgent;
    } else {
      // Inline function 'kotlin.js.unsafeCast' call
      tmp = get_process().platform;
    }
    currentRawOsName = tmp;
    envs$delegate = lazy(envs$delegate$lambda);
  }
}
//region block: post-declaration
initMetadataForInterface(CloseableCancellable, 'CloseableCancellable', VOID, VOID, [AutoCloseable]);
initMetadataForCompanion(Companion);
initMetadataForClass(CancellableGroup, 'CancellableGroup', CancellableGroup, VOID, [CloseableCancellable]);
initMetadataForClass(sam$korlibs_io_lang_Cancellable$0, 'sam$korlibs_io_lang_Cancellable$0');
initMetadataForClass(AutoCloseable$1, VOID, VOID, VOID, [AutoCloseable]);
initMetadataForCompanion(Companion_0);
initMetadataForClass(MalformedInputException, 'MalformedInputException');
initMetadataForClass(InvalidOperationException, 'InvalidOperationException', InvalidOperationException.su);
initMetadataForClass(ReservedException, 'ReservedException', ReservedException.yu);
initMetadataForClass(UnreachableException, 'UnreachableException', UnreachableException.bv);
initMetadataForClass(OutOfBoundsException, 'OutOfBoundsException', OutOfBoundsException.ev);
initMetadataForClass(UnexpectedException, 'UnexpectedException', UnexpectedException.hv);
initMetadataForClass(ByteArrayBuilder, 'ByteArrayBuilder', ByteArrayBuilder.mv);
initMetadataForCompanion(Companion_1);
initMetadataForClass(Arch, 'Arch');
initMetadataForCompanion(Companion_2);
initMetadataForClass(Endian, 'Endian');
initMetadataForCompanion(Companion_3);
initMetadataForClass(Os, 'Os');
initMetadataForInterface(Platform, 'Platform');
protoOf(Companion_4).jw = get_isWindows;
protoOf(Companion_4).lw = get_isLinux;
protoOf(Companion_4).mw = get_isMac;
protoOf(Companion_4).xw = get_isJs;
protoOf(Companion_4).yw = get_isNative;
protoOf(Companion_4).zw = get_isJsNodeJs;
protoOf(Companion_4).ax = get_isJsBrowser;
initMetadataForCompanion(Companion_4, VOID, [Platform]);
initMetadataForCompanion(Companion_5);
initMetadataForClass(Runtime, 'Runtime');
initMetadataForClass(IOException, 'IOException');
initMetadataForClass(FileNotFoundException, 'FileNotFoundException');
initMetadataForClass(EOFException, 'EOFException');
//endregion
//region block: init
Companion_instance = new Companion();
customEnvironments = null;
Companion_instance_0 = new Companion_0();
Companion_instance_1 = new Companion_1();
Companion_instance_5 = new Companion_5();
//endregion
//region block: exports
export {
  Endian_BIG_ENDIAN_getInstance as Endian_BIG_ENDIAN_getInstancel551vnoq34sw,
  Endian_LITTLE_ENDIAN_getInstance as Endian_LITTLE_ENDIAN_getInstancelbfxpgu7xewo,
  Os_UNKNOWN_getInstance as Os_UNKNOWN_getInstance1flk2wnh3ycyv,
  Companion_instance_0 as Companion_instance21jklwk36y3dn,
  Companion_getInstance_3 as Companion_getInstance1x6v9sy9zfof9,
  Companion_getInstance_4 as Companion_getInstance4j5fpzk5rb8p,
  CancellableGroup as CancellableGroup3e8z70ga3djg,
  cancel as cancel1amaozv14ic7s,
  CloseableCancellable as CloseableCancellable3mxnqaxyfppmn,
  Closeable as Closeable2m9yv0b0gma2l,
  EOFException as EOFExceptiona2wwqnj55hh5,
  FileNotFoundException as FileNotFoundException2tz8ve019qipn,
  IOException as IOException33c9ezfvq1zr9,
  MalformedInputException as MalformedInputException12cxjt7zq8psv,
  OutOfBoundsException as OutOfBoundsException1k0x58dcca6fx,
  cancel_0 as cancel1n0qjc93h2v6w,
  expand as expand3u1pyjx0md5pc,
  get_invalidOp as get_invalidOp31x7cztl0dv9m,
  invalidOp as invalidOp1stebxh2m3n07,
  get_portableSimpleName as get_portableSimpleName6o7s8nc63i85,
  get_reserved as get_reserved2d9gxjta2y60d,
  reserved as reserved38luap2kxmc9d,
  unexpected as unexpected1jwjs94qam4x9,
  get_unreachable as get_unreachable2fi7msfg0vgzd,
  unsupported as unsupported198cz39rr1ztw,
  get_Symbol_asyncIterator as get_Symbol_asyncIterator2fclltohy3z7y,
  jsNew as jsNewco39te1yl3up,
  jsObject as jsObjectr1r5ao37ef4f,
  ByteArrayBuilder as ByteArrayBuilderyrflhqjz64ju,
  get_jsGlobalThis as get_jsGlobalThis3odmbn60utci,
};
//endregion
